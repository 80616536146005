import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SOPackagetListingComp from '../../../../components/customer/package/Listing';
import SOPackagetPackageComp from '../../../../components/customer/package/Listing/packageFilter';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchAllPackage, getSOPackageCount, resetPaginatedData, updateUserStatus, fetchAllPackageByFilter, getAllPackageCountByFilter, fetchAllQualityChecklists } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack, fetchUserPreferences, saveUserPrefrences, getQualityChecklistSettings, fetchProjects } from '../../../../actions/commonActions';
import { updatePackageQualityCheck, getSOPackageList } from '../../../modal/modalBody/customer/PackageInformation/action';
import QualityCheckDrawer from '../../../drawer/qualityCheck';
import { fetchCustomers } from '../../Listing/action';
import { fetchAllStatus } from '../../../modal/modalBody/common/UserStatus/action'
import { FormattedMessage } from 'react-intl';


import * as find from 'lodash.find';

class SOPaymentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      txPreferences: { txType: "package", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['packageNumber', 'customerName', 'qtyPacked', 'packageDate'],
      txAvailableColumn: {
        soNumber: true,
        customerName: true,
        qtyPacked: false,
        status: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        referenceNumber: true,
        packageDate: true,
        qualityChecklistStatus: true,
        userStatus: true,
        project: true,
      },
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.sales.salesPackages' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
          name: <FormattedMessage id='common.sales' defaultMessage='' />,
      }, {
          name: <FormattedMessage id='package.text' defaultMessage='' />,
      },   
   ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
      userId: (this.props.userInfo || {}).userId,
      packageStatus: 'all',
      transactionName: 'Package',
      isCompact: 1
    };
    fetchPaginationDataIfNeeded('fetchAllPackage', 'packageList', this.props, payload);
    //fetchDataIfNeeded('getSOPackageCount', 'packageCount', this.props, payload);
    this.props.getSOPackageCount(payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    fetchDataIfNeeded('getQualityChecklistSettings', 'qualityChecklistSetting', this.props, payload);
    this.props.fetchCustomers(payload);
    this.props.fetchAllStatus(payload);
    this.props.fetchProjects(payload);
    this.props.fetchAllQualityChecklists(payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "package", templateName: "standard" }) || this.state.txPreferences;
      if (txPreferences && txPreferences.tablePreference) {
        let txColumns = ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [];
        txColumns = txColumns.length ? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isPrefernceFethced: true,
          isColumnFitTable,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }else{
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        })
      }
    }

  }

  render() {
    return (<div>
      {this.state.qualityCheckDrawerVisible && <QualityCheckDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      {this.state.packageFilterDrawerVisible && <SOPackagetPackageComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      <SOPackagetListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div> 
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    packageList: state.package.packageList,
    packageCount: state.package.packageCount,
    allPreferences: state.common.allPreferences,
    qualityChecklistSetting: state.settings.qualityChecklistSetting,
    customers: state.customer.customers,
    userStatus: state.common.userStatus,
    projectList: state.common.projectList,
    allQualityChecksName: state.common.allQualityChecksName,
    listLoading:state.common.listLoading,
    headerLoading: state.common.headerLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllPackage,
  getSOPackageCount,
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
  fetchUserPreferences,
  saveUserPrefrences, 
  updatePackageQualityCheck,
  getSOPackageList,
  getQualityChecklistSettings,
  updateUserStatus,
  fetchCustomers,
  fetchAllPackageByFilter,
  getAllPackageCountByFilter,
  fetchAllStatus,
  fetchProjects,
  fetchAllQualityChecklists,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SOPaymentListing);
