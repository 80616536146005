import { FINANCE_ACTION_LIST } from '../static/constants';
const initialState = {
  matchedTransactions: [],
  bankTransactions: [],
  transactionsInAlpide: {
    1: []
  },
  transactionCountInAlpide: 0,
  findTransactions: [],
  findTransactionCount: 0,
  unassociateTransactions: [],
  uploadStatementDrafts: {
    1: []
  },
  uploadStatementDraftCount: 0,

  hiddenTransactionList: {
    1: []
  },
  hiddenTransactionCount: 0,

  dayBookList: {
    1: []
  },
  dayBookCount: 0,
  cashBookList: {
    1: []
  },
  cashBookCount: 0,
  bankBookList: {
    1: []
  },
  bankBookCount: 0,
  journalReportList: {
    1: []
  },
  journalReportCount: 0,
}

const FinanceReducer = (state = initialState, action) => {
  switch (action.type) {

    case FINANCE_ACTION_LIST.MATCHED_TX_LIST:
      return { ...state, matchedTransactions: action.data };
    case FINANCE_ACTION_LIST.RESET_MATCHED_TX_LIST:
      return { ...state, matchedTransactions: initialState.matchedTransactions };
    case FINANCE_ACTION_LIST.BANK_TRANSACTIONS:
      return { ...state, bankTransactions: action.data };
    case FINANCE_ACTION_LIST.RESET_BANK_TRANSACTIONS:
      return { ...state, bankTransactions: initialState.bankTransactions };
    case FINANCE_ACTION_LIST.TRANSACTION_IN_ALPIDE:
      return {
        ...state,
        transactionsInAlpide: {
          ...state.transactionsInAlpide,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case FINANCE_ACTION_LIST.RESET_TRANSACTION_IN_ALPIDE:
      return { ...state, transactionsInAlpide: initialState.transactionsInAlpide };
    case FINANCE_ACTION_LIST.FIND_TX_LIST:
      return { ...state, findTransactions: action.data };
    case FINANCE_ACTION_LIST.RESET_FIND_TX_LIST:
      return { ...state, findTransactions: initialState.findTransactions };
    case FINANCE_ACTION_LIST.UNASSOCIATE_TX_LIST:
      return { ...state, unassociateTransactions: action.data };
    case FINANCE_ACTION_LIST.RESET_UNASSOCIATE_TX_LIST:
      return { ...state, unassociateTransactions: initialState.unassociateTransactions };
    case FINANCE_ACTION_LIST.FIND_TX_COUNT:
      return { ...state, findTransactionCount: action.data };
    case FINANCE_ACTION_LIST.TRANSACTION_COUNT_IN_ALPIDE:
      return { ...state, transactionCountInAlpide: action.data };
    case FINANCE_ACTION_LIST.STATEMENT_UPLOAD_DRAFT_LIST:
      return {
        ...state,
        uploadStatementDrafts: {
          ...state.uploadStatementDrafts,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case FINANCE_ACTION_LIST.STATEMENT_UPLOAD_DRAFT_COUNT:
      return { ...state, uploadStatementDraftCount: action.data };
    case FINANCE_ACTION_LIST.RESET_STATEMENT_UPLOAD_DRAFT:
      return { ...state, uploadStatementDrafts: initialState.uploadStatementDrafts };

    case FINANCE_ACTION_LIST.HIDDEN_TRANSACTION_LIST:
      return {
        ...state,
        hiddenTransactionList: {
          ...state.hiddenTransactionList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case FINANCE_ACTION_LIST.hiddenTransactionCount:
      return { ...state, hiddenTransactionCount: action.data };
    case FINANCE_ACTION_LIST.RESET_HIDDEN_TRANSACTION_LIST:
      return { ...state, hiddenTransactionList: initialState.hiddenTransactionList };
    case FINANCE_ACTION_LIST.DAY_BOOK_LIST:
      return {
        ...state,
        dayBookList: {
          ...state.dayBookList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case FINANCE_ACTION_LIST.DAYBOOK_COUNT:
      return { ...state, dayBookCount: action.data };
    case FINANCE_ACTION_LIST.DAY_BOOK_LIST_PAGE_RESET:
      return { ...state, dayBookList: initialState.dayBookList };

    case FINANCE_ACTION_LIST.CASH_BOOK_LIST:
      return {
        ...state,
        cashBookList: {
          ...state.cashBookList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case FINANCE_ACTION_LIST.CASH_BOOK_COUNT:
      return { ...state, cashBookCount: action.data };
    case FINANCE_ACTION_LIST.CASH_BOOK_LIST_PAGE_RESET:
      return { ...state, cashBookList: initialState.cashBookList };

      case FINANCE_ACTION_LIST.BANK_BOOK_LIST:
        return {
          ...state,
          bankBookList: {
            ...state.bankBookList,
            [action.data.pageNo || 1]: action.data.list,
          },
        };
      case FINANCE_ACTION_LIST.BANK_BOOK_COUNT:
        return { ...state, bankBookCount: action.data };
      case FINANCE_ACTION_LIST.BANK_BOOK_LIST_PAGE_RESET:
        return { ...state, bankBookList: initialState.bankBookList };
      case FINANCE_ACTION_LIST.JOURNAL_REPORTS_LIST:
          return {
            ...state,
            journalReportList: {
              ...state.journalReportList,
              [action.data.pageNo || 1]: action.data.list,
            },
          };
        case FINANCE_ACTION_LIST.JOURNAL_REPORTS_COUNT:
          return { ...state, journalReportCount: action.data };
        case FINANCE_ACTION_LIST.JOURNAL_REPORTS_LIST_PAGE_RESET:
          return { ...state, journalReportList: initialState.journalReportList };  
    default:
      return state;
  }
};

export default FinanceReducer;