import { STOCK_SUMMARY_ACTION_LIST } from '../static/constants';
const initialState = {
  stockSummaryList: {
    1: []
  },
  stockSummaryCount: 0,
  
}

const AgedStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_SUMMARY_ACTION_LIST.STOCK_SUMMARY_LIST:
      return {
        ...state,
        stockSummaryList: {
          ...state.stockSummaryList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case STOCK_SUMMARY_ACTION_LIST.STOCK_SUMMARY_COUNT:
      return { ...state, stockSummaryCount: action.data };
    case STOCK_SUMMARY_ACTION_LIST.STOCK_SUMMARY_PAGE_RESET:
      return { ...state, stockSummaryList: initialState.stockSummaryList };

    default:
      return state;
  }
};

export default AgedStockReducer;