import React, { Fragment } from "react";
import { Skeleton, Input } from 'antd';
import * as debounce from 'lodash.debounce';
// import NewLedgerComponent from '../../../containers/modal/modalBody/finance/LedgerAccount';
import { injectIntl ,FormattedMessage } from 'react-intl';
// import { CustomAgGrid } from "../../general/CustomAgGrid";
// import { MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../static/constants';
import {SortAscendingOutlined, SortDescendingOutlined, LockOutlined, DeleteOutlined } from '@ant-design/icons';
import { sortTable } from '../../../utils';

const { Search } = Input;

let gridApi = {};


// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

// const onFirstDataRendered = (params) => {
//     params.api.sizeColumnsToFit();
// }
// const getRowHeight = (params) => {
//     return 30;
// }

const LedgerAccountGroupComp = (props) => {
    const { allLedgerAccountGroups ,dir=0} = props;

    // const onGridReady = (params) => {
    //     gridApi = params.api;
    // }

    // const getActionPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedAction = {
    //         create: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.LEDGER_ACCOUNTS, PERMISSION_VALUES.CREATE)
    //     };
    //     Object.keys(permittedAction).forEach((operation) => {
    //         if (permittedAction[operation] === false) {
    //             delete permittedAction[operation];
    //         }
    //     })
    //     return permittedAction;
    // }

    // const permittedActions = getActionPermissionData();
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.ledgerAccGrp',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Ledger Account Group</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "ledgerAccountGroupName",
    //         resizable: true,
    //         rowDrag: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.category',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Category</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "categoryName",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.categoryGrp',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Category Group</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "categoryGroupName",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.inbuilt',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Inbuilt</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "inBuilt",
    //         valueFormatter: (data) => {
    //             data.value = data.value ? 'Yes' : 'No';
    //             return data.value;
    //         },
    //         resizable: true,
    //     }
    // ]

    const onSearchedInputChange = debounce((searchedText, props) => {
        handleSearchedText(searchedText, props)
    }, 2000);

    const handleSearchedText = (searchedText, props) => {
        gridApi.setQuickFilter(searchedText || '');
    }

      const sortColumn = (e) => {
        sortTable(document.getElementById("ledger-account-group"), e.target.cellIndex, 1 - dir);
        props.updateState({ dir: 1 - dir })
      }

    return (
      <Fragment>
        <Skeleton
          loading={props.showAllLedgerAccountGroups}
          active
          paragraph={{ rows: 12 }}
        >
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="heading.finance.ledgerAccountsGroup.table.heading"
                  defaultMessage=""
                />
              </div>
              {props.pageSize ? (
                <>
                  <div className="vertical-sep" />
                  <div>{props.pageSize}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              <Search
                placeholder="Search"
                value={props.searchedText}
                onChange={(e) => {
                  props.updateState({ searchedText: e.target.value });
                  onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  props.updateState({ searchedText });
                  onSearchedInputChange(searchedText, props);
                }}
              />
            </div>
          </div>

          {/* <div className="agGridWrapper">
            <div className="ag-theme-balham" style={agGridStyle}>
              <CustomAgGrid
                columnDefs={columnDefs}
                rowData={allLedgerAccountGroups || []}
                rowDragManaged={true}
                onGridReady={onGridReady}
                domLayout={"autoHeight"}
                animateRows={true}
                onGridSizeChanged={onFirstDataRendered}
                getRowHeight={getRowHeight}
              />
            </div>
          </div> */}
          <div className="table-container">
            <table id="ledger-account-group">
              <thead>
                <tr>
                    <th ><FormattedMessage id="serial.no" defaultMessage="" /></th>
                    <th onClick={sortColumn}><FormattedMessage id="tooltip.ledgerAccGrp" defaultMessage=""/>{dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="tooltip.category" defaultMessage=""/>{dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="tooltip.categoryGrp" defaultMessage=""/>{dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                    <th onClick={sortColumn}><FormattedMessage id="tooltip.inbuilt" defaultMessage=""/></th>
                    <th>
                     <FormattedMessage
                       id="common.listing.header.action"
                       defaultMessage=" "
                     />
                   </th>
                </tr>
              </thead>
              <tbody>
                {allLedgerAccountGroups && allLedgerAccountGroups.map((rowData,index)=>{
                    return(
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>{rowData.ledgerAccountGroupName}</td>
                            <td>{rowData.categoryName}</td>
                            <td>{rowData.categoryGroupName}</td>
                            <td>{rowData.inBuilt ? 'Yes': 'No'}</td>
                            <td style={{ paddingLeft: "50px" }}>
                           <div>
                             {rowData.inBuilt ? (
                               <span
                                 style={{ fontSize: "20px" }}
                                 title={props.intl.formatMessage({
                                   id: "cant.delete.systemGenerated",
                                 })}
                               >
                                 <LockOutlined />
                               </span>
                             ) : (
                               <span
                                 style={{ fontSize: "20px" }}
                                 title={props.intl.formatMessage({
                                   id: "modal.txDetails.common.delete",
                                 })}
                                 onClick={() => {
                                   props.showModal({
                                     modalBody: (
                                       <FormattedMessage
                                         id="common.delete.confirmation"
                                         defaultMessage=""
                                       />
                                     ),
                                     handleSubmit: () => {
                                       props.deleteLedgerAccount(
                                         rowData,
                                         props
                                       );
                                     },
                                   });
                                 }}
                               >
                                 <DeleteOutlined />
                               </span>
                             )}
                           </div>
                         </td>
                        </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
            <div className="f-left"></div>
            <div className="f-right">
            
            </div>
         </div>
      </Fragment>
    );
};

export default injectIntl(LedgerAccountGroupComp);