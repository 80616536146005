import { DASHBOARD_ACTION_LIST } from '../static/constants';
const initialState = {
    salesData: [],
    purchaseData: [],
    topPurchaseSupplier: [],
    topSalesCustomer: [],
    topPaymentSupplier: [],
    topPaymentCustomer: [],
    fastestMovingProducts: [],
    customerUnpaidInvoices: {
        0: []
    },
    supplierUnpaidInvoices: {
        0: []
    },
}

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_ACTION_LIST.SALES_DATA_LIST:
            return { ...state, salesData: action.data };
        case DASHBOARD_ACTION_LIST.PURCHASE_DATA_LIST:
            return { ...state, purchaseData: action.data };

        case DASHBOARD_ACTION_LIST.TOP_PURCHASE_SUPPLIER:
            return { ...state, topPurchaseSupplier: action.data };


        case DASHBOARD_ACTION_LIST.TOP_SALES_CUSTOMER:
            return { ...state, topSalesCustomer: action.data };


        case DASHBOARD_ACTION_LIST.TOP_PAYMENT_CUSTOMER:
            return { ...state, topPaymentCustomer: action.data };


        case DASHBOARD_ACTION_LIST.TOP_PAYMENT_SUPPLIER:
            return { ...state, topPaymentSupplier: action.data };


        case DASHBOARD_ACTION_LIST.FASTEST_MOVING_PRODUCTS:
            return { ...state, fastestMovingProducts: action.data };

        case DASHBOARD_ACTION_LIST.CUSTOMER_UNPAID_INVOICES:
            return {
                ...state,
                customerUnpaidInvoices: {
                    ...state.customerUnpaidInvoices,
                    [action.data.pageNo || 0]: action.data.list
                }
            };

        case DASHBOARD_ACTION_LIST.SUPPLIER_UNPAID_INVOICES:
            return {
                ...state,
                supplierUnpaidInvoices: {
                    ...state.supplierUnpaidInvoices,
                    [action.data.pageNo || 0]: action.data.list
                }
            };
            
        default:
            return state;
    }
};

export default DashboardReducer;