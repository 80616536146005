  import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDataIfNeeded } from '../../../utils';
import QualityCheckAddDrawerComp from '../../../components/drawer/qualityCheckAdd';
import QualityCheckDrawerComp from '../../../components/drawer/qualityCheck';
import { showModal, hideModal } from './../../../actions/commonActions';
import { downloadFileFromBucket } from './../../../actions/downloadFileAction';

import { fetchAllQualityChecklists, addQualityChecklists, deleteQualityChecklists, deleteQualityChecklistFromSO } from './action';

class QualityCheckDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qualityCheckList: props.qualityCheckList || [{}],
      qualityCheckAction: props.qualityCheckAction , 
    }
  }
  

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.customerId, soMasterId: this.props.soMasterId };
    fetchDataIfNeeded('fetchAllQualityChecklists', 'allQualityChecksName', this.props, payload, true);
  }

  render() {
    return <div>
      {this.state.qualityCheckAction === 'add' && <QualityCheckAddDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />}
      {this.state.qualityCheckAction === 'check' && <QualityCheckDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />}
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    allQualityChecksName: state.common.allQualityChecksName,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal, 
  hideModal,
  fetchAllQualityChecklists, 
  addQualityChecklists, 
  deleteQualityChecklists,
  deleteQualityChecklistFromSO,
  downloadFileFromBucket
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QualityCheckDrawer);
