import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveFees } from "./action";
import TermAndFeeDrawer from "../../../../components/drawer/membership/termAndFee";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { getFees, getFeeCount } from '../../../membership/termAndFee/action';

class TermAndFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.termAndFeeData,
      stmtFreq: (props.termAndFeeData && props.termAndFeeData.frequency) ? (props.termAndFeeData.frequency === 'Daily' ? 'Day' : (props.termAndFeeData.frequency).split('ly')[0]) : '',
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    // const companyInfo = this.props.companyInfo || {};
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    // };
  }

  render() {
    return (
      <TermAndFeeDrawer
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></TermAndFeeDrawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveFees,
      getFees,
      getFeeCount,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TermAndFee);
