import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import {  showToasterMessage } from '../../../../../utils'
import { CustomButton } from '../../../../general/CustomButton';

class NewTaxComponent extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const {formData} = this.props.data;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content create-new-control pl30 pr60 pb20">
            {/* <Form.Item>
                {getFieldDecorator('taxSingleRateId', {
                        initialValue: formData.taxSingleRateId || null,
                    })}
            </Form.Item> */}
            <Form.Item label={<FormattedMessage id='modal.common.newTax.taxName' defaultMessage='' />} colon={false} labelAlign={'left'}>
              {getFieldDecorator('taxName', {
                rules: [
                  {
                    required: true,
                    message: this.props.intl.formatMessage({ id: 'common.name.required'} ),
                    initialValue: this.props.taxName || ''
                  }
                ],
                initialValue: formData.taxName || null,
              })(
                  <Input placeholder={this.props.intl.formatMessage({ id: 'modal.common.newTax.taxName', defaultMessage: '' } )} />
              )}
            </Form.Item>

            {/* <Form.Item label='Tax Type' colon={false}>
              {getFieldDecorator('taxType', {
                initialValue: this.props.taxType || ''
              })
                (<DropdownRef
                  items={(this.props.companyInfo || {}).countryName === 'India' ? TAX_TYPE_OPTIONS_FOR_INDIA : TAX_TYPE_OPTIONS_FOR_NON_INDIA}
                  onSelect={(selectedValue) => {
                    this.props.form.setFieldsValue({ 'taxType': selectedValue });
                  }}
                />)}
            </Form.Item> */}

            <Form.Item label={<FormattedMessage id='modal.common.newTax.taxRate' defaultMessage='' />} colon={false} labelAlign={'left'}>
              {getFieldDecorator('taxPercent', {
                rules: [
                  {
                    required: true,
                    message: this.props.intl.formatMessage({ id: 'taxRate.required'} ),
                  }
                  // {
                  //   pattern: '^[1-9][0-9]?$|^100$',
                  //   message: <FormattedMessage id='taxRate.validation' defaultMessage='' />,
                  // },
                ],
                initialValue: formData.taxPercent || null,
              })
                (
                  <span className="tax-rate-input">
                    <Input type='number' defaultValue={formData.taxPercent} placeholder={this.props.intl.formatMessage({ id: 'modal.common.newTax.taxRate', defaultMessage: '' } )}/>
                    <span>%</span>
                  </span>
                )}
            </Form.Item>
          </div>

          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.newTax.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal() }}
              />
              <CustomButton
                intlId={'modal.newTax.button.createTax'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (values.taxPercent < 0 || values.taxPercent > 100) {
          return showToasterMessage({ messageType: 'error', description: this.props.intl.formatMessage({ id: 'taxRate.validation' } ) });
        }
        const tabObj = this.props.data.formData || {};
        const payload = {
          taxSingleRateId: tabObj.taxSingleRateId || null,
          taxName: values.taxName,
          taxType: values.taxType,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          taxPercent: values.taxPercent,
          version: tabObj.version || null,
          //dateCreated: getCurrentDateForBackend(new Date())
        }
        if(this.props.companyInfo && (this.props.companyInfo || {}).countryName !== 'India'){
          payload.taxType = 'VAT';
        }

        const modalData = {
          modalBody: payload.taxSingleRateId ? 'Are you sure you want to update tax ?' : 'Are you sure you want to create tax ?',
          handleSubmit: () => {
            this.props.saveSingleTaxRate(payload, this.props);
            this.props.hideModal();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'New_tax_component' })(injectIntl(NewTaxComponent));
