import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDataIfNeeded } from "../../../../utils";
import CompanyHierarchyDrawerComp from "../../../../components/drawer/company/CompanyHierarchy";
import {
  fetchSalutation,
  showModal,
  hideModal,
} from "./../../../../actions/commonActions";
import { fetchCompanyHierarchy } from "./../../../company/CompanyHierarchy/action";

import {
  createCompanyHierarchy,
  fetchParentCompanyHierarchiesByLevel,
  resetParentCompanyHierarchyByLevel,
} from "./action";

class companyHierarchyDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRootHierarchy: (props.hierarchyData || {}).isRootHierarchy === 1,
      hierarchyName: (props.hierarchyData || {}).hierarchyName,
      hierarchyDescription: (props.hierarchyData || {}).hierarchyDescription,
      hierarchyLevel: (props.hierarchyData || {}).hierarchyLevel,
      hierarchyParentId: (props.hierarchyData || {}).hierarchyParentId,
      hierarchyParentName: (props.hierarchyData || {}).hierarchyParentName,
      hierarchyCode: (props.hierarchyData || {}).hierarchyCode,
    };
  }

  initCalls() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId };
    // fetchDataIfNeeded("fetchSalutation", "salutations", this.props, payload);

    if (this.props.hierarchyData) {
      this.props.fetchParentCompanyHierarchiesByLevel({
        relationshipId: (this.props.companyInfo || {}).relationshipId,
        hierarchyLevel: this.props.hierarchyData?.hierarchyLevel,
      });
    }
  }

  render() {
    return (
      <div>
        <CompanyHierarchyDrawerComp
          initCalls={() => {
            this.initCalls();
          }}
          {...this.props}
          {...this.state}
          updateDrawerState={(data) => {
            this.setState(data);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    salutations: state.common.salutations,
    companyHierarchyList: state.hierarchy.parentHierarchyListByLevel,
    companyInfo: state.common.companyInfo,
    drawerLoading: state.common.drawerLoading,

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSalutation,
      showModal,
      fetchParentCompanyHierarchiesByLevel,
      resetParentCompanyHierarchyByLevel,
      createCompanyHierarchy,
      fetchCompanyHierarchy,
      hideModal,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(companyHierarchyDrawer);
