import React, { Fragment } from "react";
import axios from "axios";
import config from "../../../config/environmentConfig";
import {
  COMMON_ACTIONS,
  SETTING_ACTION_LIST,
  STUDENT_REGISTRATION_ACTION_LIST,
} from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";
import * as find from "lodash.find";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const fetchRelationshipDataByRID = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/getRelationshipByRID?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        const { data } = res;
        dispatch({ type: SETTING_ACTION_LIST.RELATIONSHIP_DATA, data });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};

export const fetchRelationshipByDomainName = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    // return axios
    // .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getRelationshipId?name=${payload.domainName}`)
    // .then(res => {
    // const { data } = res
    const load = {
      relationshipId: payload.relationshipId,
      registrationFormSettingId: payload.registrationFormSettingId,
    };
    props.fetchRegistrationFormSetting(load);
    props.fetchCountries(load);
    props.fetchPaymentTerms(load);
    props.fetchRelationshipDataByRID(load);
    // props.getTotalStudentcCount(load);
    // props.getTotalStudentcCountByGrade(load);
    // })
    // .catch((err) => {
    //   ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
    //   if (!err.__isRetryRequest) {
    //     showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
    //   } else {
    //     err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
    //   }
    // })
  };
};

export const fetchStudentFatherEmail = (payload, props) => {
  // let FY = getFinancialYear(payload.companyInfo);
  // let url = payload.type === 'fatherEmail' ? `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByFatherEmail?fatherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}` :
  //   `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByMotherEmail?motherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}`;
  let url =
    payload.type === "fatherEmail"
      ? `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByFatherEmail?fatherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}`
      : `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByMotherEmail?motherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}`;
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(url)
      .then((res) => {
        if (res?.data?.customerId) {
          let orgRegistration = res.data.orgRegistrationList[0] || {};
          let contact = res.data.boContactList[0] || {};
          let location = res.data.boLocationList[0] || {};
          props.showModal({
            maskClosable: true,
            isClosable: false,
            keyboard: false,
            modalBody: (
              <Fragment>
                <div className="warning-message">
                  <FormattedMessage
                    id="registration.form.email.alreadyExists"
                    defaultMessage=""
                  />
                </div>
                <br />
                <div
                  className="button-action"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderTop: "1px solid #e5e5e5",
                    paddingTop: "15px",
                  }}
                >
                  <Button
                    className="ant-btn-default"
                    onClick={() => {
                      if (payload.type === "fatherEmail") {
                        props.updateState({
                          fatherEmail: "",
                        });
                      } else {
                        props.updateState({
                          motherEmail: "",
                        });
                      }

                      props.hideModal();
                    }}
                  >
                    {" "}
                    <FormattedMessage id="no" defaultMessage="" />
                  </Button>
                  <Button
                    className="ant-btn-primary"
                    onClick={() => {
                      let studentList = [];
                      if (
                        orgRegistration.studentList &&
                        orgRegistration.studentList.length
                      ) {
                        studentList = (orgRegistration.studentList || []).map(
                          (e) => {
                            const childContact =
                              find(contact.boContactChildList || [], {
                                contactChildId: e.contactChildId,
                              }) || {};
                            e.firstName = childContact.firstName;
                            e.lastName = childContact.lastName;
                            e.gender = childContact.gender;
                            e.allergies = childContact.allergies;
                            e.dob = childContact.dob
                              ? new Date(childContact.dob)
                              : "";
                            // e.grade = e.grade;
                            e.gradefee = e.courseFee
                              ? Number(e.courseFee)
                              : null;
                            return e;
                            //e.gradefee = (props.formSetting || {}).gradeFees[e.grade];
                          }
                        );
                      } else if (
                        contact.boContactChildList &&
                        contact.boContactChildList.length
                      ) {
                        studentList = (contact.boContactChildList || []).map(
                          (childContact) => {
                            childContact.dob = childContact.dob
                              ? new Date(childContact.dob)
                              : "";
                            return childContact;
                          }
                        );
                      }

                      props.updateState({
                        ...orgRegistration,
                        familyDoctorPhone: orgRegistration.familyDoctorPhNumber,
                        primaryContact: contact.primaryContactForInvoicing,
                        customerId: res.data.customerId,
                        contactId: contact.contactId,
                        contactVersion: contact.version,
                        fatherFirstName: contact.firstName,
                        fatherLastName: contact.lastName,
                        fatherMobile: contact.cellPhone,
                        fatherEmail: contact.emailAddress,
                        motherFirstName: contact.motherFirstName,
                        motherLastName: contact.motherLastName,
                        motherMobile: contact.motherMobile,
                        motherEmail: contact.motherEmail,

                        fatherDOB: contact.dateOfBirth
                          ? new Date(contact.dateOfBirth)
                          : "",
                        motherDOB: contact.motherDateOfBirth
                          ? new Date(contact.motherDateOfBirth)
                          : "",

                        studentList: studentList,

                        streetAddress1: location.streetAddress1,
                        streetAddress2: location.streetAddress2,
                        cityName: location.cityName,
                        countryId: location.countryId,
                        countryName: location.countryName,
                        stateName: location.stateName,
                        zipCode: location.zipCode,
                        locationId: location.locationId,
                        locationVersion: location.version,
                      });
                      props.hideModal();
                    }}
                  >
                    {" "}
                    <FormattedMessage id="yes" defaultMessage="" />{" "}
                  </Button>
                </div>
              </Fragment>
            ),
            hideFooter: true,
          });
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            ((err.response || {}).data || {}).message,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const fetchRegistrationFormSetting = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    dispatch({ type: SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/getFormSetting?relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`)
      .then((res) => {
        const { data } = res;
        dispatch({ type: SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING, data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_DETAIL_LOADER });
      });
  };
};

export const saveRegistration = (requestObj, props) => {
  let formData = new FormData();
  formData.append("customer", JSON.stringify(requestObj, true));
  formData.append('bucketName' ,props.companyInfo?.bucketName)

  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/saveOrUpdateCustomer`,
        formData,
        { headers: { "content-type": "multipart/form-data" } }
      )
      .then((res) => {
        if (
          requestObj.orgRegistrationList[0] &&
          requestObj.orgRegistrationList[0].amount
        ) {
          //window.location = (res.data || {}).redirectUrl+`&callback_uri=${window.location.origin}/app/register&formId=${(props.formSetting) || {}.registrationFormSettingId}`;
        }

        if (props && props.updateState) {
          props.updateState({
            isFormSaved: true,
          });
        }
        //showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'common.saved.success'] })
      })
      .catch((err) => {
        showToasterMessage({
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};

export const saveRegistrationFound = (customerPayload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/saveRegistration`, customerPayload)
      .then((res) => {
        let responseData= res.data;
        dispatch({ type: SETTING_ACTION_LIST.REGISTRATION_FORM_DATA, data:responseData });
        props.updateState({status: 'success', responseData});

        let orgRegList = responseData.orgRegistrationList || [];

        // fetch fees proforma for payment
        props.fetchOrgInvoiceProforma({
          relationshipId: props.companyInfo?.relationshipId,
          orgRegistrationId: orgRegList?.length ? orgRegList[orgRegList?.length ? orgRegList?.length - 1 : 0]?.orgRegistrationId : 0
        })
      })
      .catch((err) => {
        props.updateState({status: 'error'});
        showToasterMessage({
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      });
  };
};

export const getTotalStudentcCount = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/student/countStudent?relationshipId=${payload.relationshipId}&formId=${payload.registrationFormSettingId}`
      )
      .then((res) => {
        const { data } = res;
        dispatch({
          type: STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT,
          data,
        });
      })
      .catch((err) => {

        if (!err.__isRetryRequest) {
        } else {
          err.actionToCall =
            STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT;
        }
      });
  };
};

export const getTotalStudentcCountByGrade = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/student/countStudentByGrade?relationshipId=${payload.relationshipId}&formId=${payload.registrationFormSettingId}`
      )
      .then((res) => {
        const { data } = res;
        dispatch({
          type: STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE,
          data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall =
            STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE;
        }
      });
  };
};

export const sendOtpToUser = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_user/sendOtpForReg`,
        payload
      )
      .then((res) => {
        const { data } = res;
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.OTP_SENT_RES, data });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  };
};

export const verifyOtp = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_user/verifyOtpForReg`,
        payload
      )
      .then((res) => {
        const { data } = res;
        dispatch({
          type: STUDENT_REGISTRATION_ACTION_LIST.OTP_VERIFY_RES,
          data,
        });
        showToasterMessage({
          messageType: "success",
          description: "OTP Verified Successfully!",
        });
      })
      .catch((err) => {
        props.updateState({ incorrectOtp: true });
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  };
};

export const getRegistrationDataByEmail = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByEmail?email=${payload.email}&relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`
      )
      .then((res) => {
        const { data } = res;
        dispatch({
          type: STUDENT_REGISTRATION_ACTION_LIST.ORG_REGISTRATION_DATA,
          data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            ((err.response || {}).data || {}).message,
        });
      });
  };
};


export const fetchOrgInvoiceProforma = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getRegistrationInvoiceProforma?relationshipId=${payload.relationshipId}&orgRegistrationId=${payload.orgRegistrationId}`)
      .then((res) => {
        const { data } = res;
        if(payload.getRegisteredProforma){
          dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.EXISTING_ORG_REGISTRATION_INVOICE_PROFORMA, data });
        }else{
          dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.ORG_REGISTRATION_INVOICE_PROFORMA, data });
        }
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_DETAIL_LOADER });
      });
  };
};


export const resetOrgInvoiceProforma = () => {
  return (dispatch) => {
    dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.ORG_REGISTRATION_INVOICE_PROFORMA, data: {} });
  };
};


export const getRegStudentLimitCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/student/countStudentsByOrg?relationshipId=${payload.relationshipId}&formSettingId=${payload.formsettingId || 0}&classId=${payload.classId}`)
      .then((res) => {
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.STUDENT_LIMIT_COUNT, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  };
};