import React, { Component, Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { AgGridReact } from 'ag-grid-react';
import { Row, Col, Button, DatePicker } from 'antd';
import { FilePdfOutlined, MailOutlined, PrinterOutlined, FilterOutlined } from '@ant-design/icons';

import { getFinancialYear, showToasterMessage, fixedDecimalNumber, isValidDateRange, getMomentDateForUIReadOnly, getMomentDateForUI, formatDateFilterForBackend, exportExcel } from '../../../../../../utils';
import { CONSTANTS, TRANSACTION_TYPES } from '../../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../../static/cssClassConstants';
import { Dropdown } from '../../../../../general/Dropdown';
import * as find from 'lodash.find';
import { Menu } from 'antd';
import { FileExcelOutlined, DownloadOutlined } from '@ant-design/icons';
import DropdownAction from "antd/lib/dropdown";

class PartLedgerComponent extends Component {
    constructor(props) {
        super(props);
        const finacialYear = getFinancialYear(this.props.companyInfo);
        this.state = {
            ledgerStartDate: getMomentDateForUI({ date: new Date(finacialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT }),
            ledgerEndDate: getMomentDateForUI({ date: new Date(), format: CONSTANTS.BACKEND_FORMAT }),
        }
    }

    render() {


        const format = CONSTANTS.DISPLAY_DATE_FORMAT;
        const relationshipAddress = (this.props.companyInfo.boLocationList || []).find(x => x.isDefault && x.locationType === "RelationshipBillingAddress") || {};
        const finacialYear = getFinancialYear(this.props.companyInfo);
        const fyStartDate = finacialYear.fyStartDate;
        var totalDebit = 0;
        var totalCredit = 0;
        this.props.supplierLedger && this.props.supplierLedger.forEach((row, index) => {
            if (row.txType === "Payment") {
                if (row.isMultiCurrency) {
                    totalCredit += (Number(row.txAmount || 0)) * Number(row.exchangeRate || 1);
                } else {
                    totalCredit += Number(row.txAmount || 0);
                }
            } else if (row.txType === "Invoice" || row.txType === "Expense" || row.txType === "Debit Memo") {
                if (row.isMultiCurrency) {
                    totalDebit += (Number(row.txAmount || 0)) * Number(row.exchangeRate || 1);
                } else {
                    totalDebit += Number(row.txAmount || 0);
                }
            }
        });

        const getPayloadForPdf = () => {
            let FY = getFinancialYear(this.props.companyInfo);
            return {
                fileName: 'Supplier Ledger Report.pdf',
                transactionName: 'supplierLedgerReport',
                supplierObj: this.props.supplierObj,
                companyInfo: this.props.companyInfo,
                relationshipAddress: relationshipAddress,
                ledgerData: this.props.supplierLedger,
                fyStartDate: FY.fyStartDate,
                fyEndDate: FY.fyEndDate,
                totalDebit: totalDebit,
                totalCredit: totalCredit
            }
        }

        const printPdf = () => {
            let payload = getPayloadForPdf();
            this.props.printPDF(payload);
        }

        const openEmailDrawer = (props) => {
            let payload = getPayloadForPdf();
            payload.fileDataReqeired = true;
            payload.supplierId = (this.props.supplierObj || {}).supplierId;

            this.props.updateState({
                emailDrawerVisible: true,
                txData: payload,
                type: TRANSACTION_TYPES.SUPPLIER_PARTY_LEDGER,
            });
        };


        const downloadPDf = () => {
            let payload = getPayloadForPdf();
            this.props.generatePDF(payload);
        }

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.date' defaultMessage="" />;
                },
                field: "txDate",
                cellRendererFramework: (link) => <div>{getMomentDateForUIReadOnly({ date: new Date(link.value), format })}</div>,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.transaction' defaultMessage="" />;
                },
                field: "txType"
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.transactionNumber' defaultMessage="" />;
                },
                field: "txNumber"
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.debit' defaultMessage="" />;
                },
                field: "totalDebit",
                cellRendererFramework: (params) =>
                    <div>{
                        params.data.isMultiCurrency ? <div>{(params.data.txType === "Invoice" || params.data.txType === "Expense") ? `${params.data.foreignCurrency} ${fixedDecimalNumber(params.data.txAmount)} * ${fixedDecimalNumber(params.data.exchangeRate || 1)} ER = ${this.props.companyInfo.currencyCode} ${fixedDecimalNumber(params.data.txAmount * params.data.exchangeRate)}` : '0.00'}</div> :
                            <div>{((params.data.txType === "Invoice" || params.data.txType === "Expense") ? params.data.txAmount : 0).toFixed(2)}</div>
                    } </div>,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.credit' defaultMessage="" />;
                },
                field: "totalCredit",
                cellRendererFramework: (params) => {
                    return <div>{
                        params.data.isMultiCurrency ? <div>{(params.data.txType === "Payment" || params.data.txType === "Credit Memo") ? `${params.data.foreignCurrency} ${fixedDecimalNumber(params.data.txAmount)} * ${fixedDecimalNumber(params.data.exchangeRate || 1)} ER = ${this.props.companyInfo.currencyCode} ${fixedDecimalNumber(params.data.txAmount * params.data.exchangeRate)}` : '0.00'}</div> :
                            <div>{((params.data.txType === "Payment" || params.data.txType === "Credit Memo") ? params.data.txAmount : 0).toFixed(2)}</div>
                    } </div>
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN

            }
        ];
        const downloadMenu = (data) => {
            return <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Download as
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item key="1" value="excel" onClick={() => {
                    const ledgerList = [];
                    this.props.supplierLedger.forEach((obj) => {
                        ledgerList.push({
                            "Date": getMomentDateForUIReadOnly({ date: new Date(obj.txDate), format }),
                            "Transaction": obj.txType || '',
                            "Transaction #": obj.txNumber || '',
                            "Debit": Number((obj.txType === "Invoice" || obj.txType === "Expense") ? obj.txAmount : 0).toFixed(2),
                            "Credit": Number((obj.txType === "Payment" || obj.txType === "Credit Memo") ? obj.txAmount : 0).toFixed(2),
                        });
                    });
                    ledgerList.push({
                        "Date": '',
                        "Transaction": '',
                        "Transaction #": 'Total',
                        "Debit": Number(totalDebit).toFixed(2),
                        "Credit": Number(totalCredit).toFixed(2),
                    });
                    if (this.props.supplierLedger && this.props.supplierLedger.length) {
                        exportExcel(ledgerList, this.props.supplierName || "Supplier-Party-Ledger");
                    } else {
                        showToasterMessage({
                            messageType: "error",
                            description:
                                "No record selected. Please select a party",
                        });
                    }
                }}>
                    <FileExcelOutlined />
                    Excel File
                </Menu.Item>
            </Menu>;
        };


        return (
            <div>
                <Fragment>

                    <div className='agGridWrapper'>

                        <div className="view-container-actions" style={{ backgroundColor: "#ffffff", border: "none", justifyContent: "end" }}>

                            <div className="right-actions">
                              
                                <Dropdown
                                 
                                    items={this.props.suppliers[1] || []}
                                    valueKeyName='supplierStoreName'
                                    optionKeyName='supplierId'
                                    value={(this.props.supplierObj || {}).supplierStoreName}
                                    placeholder='Select supplier'
                                    onSelect={
                                        (supplierId) => {
                                            const supplier = find(this.props.suppliers[1], { supplierId: Number(supplierId || 0) }) || {};
                                            this.props.updateState({
                                                supplierObj: supplier,
                                                supplierName: supplier.supplierStoreName,
                                            });
                                            const payload = {
                                                companyInfo: this.props.companyInfo,
                                                supplierId: supplier.supplierId,
                                                relationshipId: this.props.companyInfo.relationshipId,
                                                startDate: formatDateFilterForBackend(this.state.ledgerStartDate, CONSTANTS.FILTER_DATE_TYPE.START),
                                                endDate: formatDateFilterForBackend(this.state.ledgerEndDate, CONSTANTS.FILTER_DATE_TYPE.END),
                                            };
                                            this.props.exportLedgerPdf(payload, this.props);
                                        }}
                                />

                                {/* <FormattedMessage id='modal.reports.partyLedger.startDate.label' defaultMessage="" /> */}
                                <DatePicker onChange={(dateObj) => {
                                    if (dateObj) {
                                        this.setState({
                                            ledgerStartDate: dateObj.format(CONSTANTS.BACKEND_FORMAT)
                                        });
                                    } else {
                                        this.setState({
                                            ledgerStartDate: undefined
                                        });
                                    }
                                }} format={format}
                                    key={`${this.state.ledgerStartDate ? getMomentDateForUI({ date: new Date(this.state.ledgerStartDate), format }) : getMomentDateForUI({ date: new Date(fyStartDate), format })}`}
                                    defaultValue={this.state.ledgerStartDate ? getMomentDateForUI({ date: new Date(this.state.ledgerStartDate), format }) : getMomentDateForUI({ date: new Date(fyStartDate), format })}
                                />

                                {/* <FormattedMessage id='modal.reports.partyLedger.endDate.label' defaultMessage="" /> */}
                                <DatePicker onChange={(dateObj) => {
                                    if (dateObj) {
                                        this.setState({
                                            ledgerEndDate: dateObj.format(CONSTANTS.BACKEND_FORMAT)
                                        });
                                    } else {
                                        this.setState({
                                            ledgerEndDate: undefined
                                        });
                                    }
                                }} format={format}
                                    key={`${this.state.ledgerEndDate ? getMomentDateForUI({ date: new Date(this.state.ledgerEndDate), format }) : getMomentDateForUI({ date: new Date(), format })}`}
                                    defaultValue={this.state.ledgerEndDate ? getMomentDateForUI({ date: new Date(this.state.ledgerEndDate), format }) : getMomentDateForUI({ date: new Date(), format })}
                                />


                                <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
                                    <span>
                                        <DownloadOutlined />&nbsp;
                                        {/* <FormattedMessage
                                            id="common.action.dropdown.downloadAs"
                                            defaultMessage=""
                                        /> */}
                                        Download
                                    </span>
                                </DropdownAction>



                                <Button className="ant-btn ant-btn-default vab" style={{ height: '38px' }} onClick={() => {
                                    if (!this.state.ledgerStartDate || !this.state.ledgerEndDate) {
                                        showToasterMessage({
                                            description: 'Please select date range.',
                                            messageType: 'error'
                                        })
                                    } else if (!isValidDateRange(this.state.ledgerStartDate, this.state.ledgerEndDate)) {
                                        showToasterMessage({
                                            description: 'Please select valid date range',
                                            messageType: 'error'
                                        })
                                    } else if (!this.props.supplierObj || !this.props.supplierObj.supplierId) {
                                        showToasterMessage({
                                            description: 'Please select supplier',
                                            messageType: 'error'
                                        })
                                    } else {
                                        const companyInfo = this.props.companyInfo || {};
                                        this.props.exportLedgerPdf({
                                            startDate: formatDateFilterForBackend(this.state.ledgerStartDate, CONSTANTS.FILTER_DATE_TYPE.START),
                                            endDate: formatDateFilterForBackend(this.state.ledgerEndDate, CONSTANTS.FILTER_DATE_TYPE.END),
                                            supplierId: (this.props.supplierObj || {}).supplierId,
                                            relationshipId: companyInfo.relationshipId,
                                            pageNumber: 1,
                                            pageSize: 25
                                        }, this.props);
                                    }
                                }}>
                                    {/* <FormattedMessage id='modal.reports.partyLedger.submit.label' defaultMessage="Submit" /> */}
                                    {<FilterOutlined />}
                                </Button>
                                {(this.props.customerLedger && this.props.customerLedger.length) ?
                                    <div className="small-btn-group pl20">
                                        <button className="ant-btn ant-btn-default vab  mt20 " onClick={openEmailDrawer}>{<MailOutlined />}</button >
                                        <button className="ant-btn ant-btn-default vab  mt20 " onClick={downloadPDf}>{<FilePdfOutlined />}</button>
                                        <button className="ant-btn ant-btn-default vab  mt20 " onClick={printPdf}>{<PrinterOutlined />}</button>
                                    </div> : ''
                                }
                            </div>
                        </div>




                        <div className="ag-theme-balham customer-details-table" >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={this.props.supplierLedger || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onColumnResized={(params) => {
                                    params.api.resetRowHeights();
                                }}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                onGridSizeChanged={(params) => {
                                    params.api.sizeColumnsToFit();
                                }}
                            >
                            </AgGridReact>
                            <br></br>
                            {(this.props.supplierLedger && this.props.supplierLedger.length) ?
                                <div>
                                    <Row type="flex" >

                                        <Col offset={12} span={2} style={{ textAlign: "right" }}>
                                            <FormattedMessage id='modal.reports.partyLedger.total.label' defaultMessage="" />
                                        </Col>
                                        <Col span={5} style={{ textAlign: "right" }}>
                                            {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(totalDebit || 0)}
                                        </Col>
                                        <Col span={5} style={{ textAlign: "right", paddingRight: '10px' }}>
                                            {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(totalCredit || 0)}
                                        </Col>
                                    </Row>
                                    <br />
                                    {totalDebit > totalCredit ?
                                        <Row type="flex">
                                            <Col offset={10} span={4} style={{ textAlign: "right" }}>
                                                <FormattedMessage id='modal.reports.partyLedger.closingAmount.dr.label' defaultMessage="" />
                                            </Col>
                                            <Col span={5} style={{ textAlign: "right" }}>
                                                {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(totalDebit - totalCredit)}
                                            </Col>
                                        </Row> : ''
                                    }
                                    {totalDebit < totalCredit ?
                                        <Row type="flex">
                                            <Col offset={10} span={4} style={{ textAlign: "right" }}>
                                                <FormattedMessage id='modal.reports.partyLedger.closingAmount.cr.label' defaultMessage="" />
                                            </Col>
                                            <Col span={5} offset={5} style={{ textAlign: "right" }}>
                                                {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(totalCredit - totalDebit)}
                                            </Col>
                                        </Row> : ''
                                    }
                                </div> : ''
                            }
                        </div >
                    </div>
                </Fragment>
                {(this.props.supplierLedger && this.props.supplierLedger.length) ?

                    <div className="">
                        {/* <Row type="flex">
                        <Col offset={12} span={2}>
                            <FormattedMessage id='modal.reports.partyLedger.total.label' defaultMessage="" />
                        </Col>
                        <Col span={5}>
                             {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(totalDebit || 0)}
                        </Col>
                        <Col span={5}>
                             {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(totalCredit || 0)}
                        </Col>
                    </Row>
                    <br />
                    <Row type="flex">
                        <Col offset={10} span={4}>
                            <FormattedMessage id='modal.reports.partyLedger.closingAmount.label' defaultMessage="" />
                        </Col>
                        <Col span={5}>
                             {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(totalDebit || 0)}
                        </Col>
                    </Row> */}
                        {/* <Row type="flex" className="action-button-group">
                        <Col span={3}>
                            <Button className="ant-btn-primary" onClick={() => {
                                let FY = getFinancialYear(this.props.companyInfo);
                                var data = {
                                    fileName: 'Supplier Ledger Report.pdf',
                                    transactionName: 'supplierLedgerReport',
                                    supplierObj: this.props.supplierObj,
                                    companyInfo: this.props.companyInfo,
                                    relationshipAddress: relationshipAddress,
                                    ledgerData: this.props.supplierLedger,
                                    fyStartDate: FY.fyStartDate,
                                    fyEndDate: FY.fyEndDate,
                                    totalDebit: totalDebit,
                                    totalCredit: totalCredit
                                }
                                // if (payload.filterStartDate && payload.filterEndDate) {
                                //   data.filterStartDate = payload.filterStartDate;
                                //   data.filterEndDate = payload.filterEndDate;
                                // }
                                this.props.generatePDF(data);

                            }}><FormattedMessage id='modal.reports.partyLedger.download.label' defaultMessage="" /></Button>
                        </Col>

                        <Col span={2} offset={19}>
                            <Button className="grey-button" onClick={() => {
                                this.props.popModalFromStack();
                            }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                        </Col>
                    </Row> */}
                    </div> : ''
                }
            </div>
        )
    }
}

export default injectIntl(PartLedgerComponent);
