import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StudentRegistrationListingComp from '../../../../components/customer/studentRegistration/Listing';
import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from '../../../../utils';
import { fetchStudentRegistration, getStudentRegistrationCount, resetPaginatedData, deleteRegistration, deleteStudent,
 getTotalParentsCount, getTotalStudentsCount, getFormNames, getRegistrationDataForExcel, updateRegistrationUserStatus, updateStudentUserStatus } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../actions/commonActions';
import { fetchCustomers, getCustomerCount } from '../../Listing/action';
import { getRegisterParentsData, resetRegisterParentsData } from '../../../modal/modalBody/customer/TotalParents/action';
import { FormattedMessage } from 'react-intl';
//import * as find from 'lodash.find';

class StudentRegistrationListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      activeTab: 'Registrations', 
      activeTabKey: "1"
      // txPreferences: { txType: "customerPayment", templateName: "standard" },
      // txColumns: [],
      // txColumnSetting: {},
      // txMandatoryColumns: [ 'Xero', 'paymentNumber', 'paymentAmount', 'paymentDate'],
      // txAvailableColumn: {
      //   isXeroUploaded: true,
      //   soNumber: true,
      //   paymentNumber: true,
      //   invoiceNumber: true,
      //   customerName: true,
      //   customerPONumber: true,
      //   customerRFQNumber: true,
      //   customerInquiryNumber: true,
      //   paymentAmount: true,
      //  // status: true,
      //   paymentDate: true,
      //   quotationNumber: true,
      //   // rfqNumber: true,,
      //   project: true,
      //   description: true,
      // },
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.sales.registeredMembers' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
        {
            name: <FormattedMessage id='sidebar.menuItem.sales' defaultMessage='' />,
        }, 
        {
            name: <FormattedMessage id='studentRegistration.text' defaultMessage='' />,
        }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25
    };
    fetchPaginationDataIfNeeded('fetchStudentRegistration', 'studentRegistrationList', this.props, payload);
    fetchDataIfNeeded('getStudentRegistrationCount', 'studentRegistrationCount', this.props, payload);
    this.props.getTotalParentsCount(payload); 
    this.props.getTotalStudentsCount(payload);
    this.props.getFormNames(payload);
  }

  // componentWillReceiveProps(props) {
  //   if (props.allPreferences && props.allPreferences.length) {
  //     let txPreferences = find(props.allPreferences, { txType: "customerPayment", templateName: "standard" }) || this.state.txPreferences;
  //     if (txPreferences) {
  //       let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
  //       txColumns = txColumns.length ? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
  //       const txColumnSetting = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
  //       const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
  //       let gridPreference = txPreferences.gridPreference
  //       this.setState({
  //         gridPreference,
  //         isColumnFitTable,
  //         preferenceId: txPreferences.preferenceId,
  //         txColumns,
  //         txColumnSetting,
  //         txPreferences
  //       });
  //     }
  //     else {
  //       this.setState({
  //         txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
  //       });
  //     }
  //   }

  // }

  componentWillReceiveProps(props) {
    if (props.studentRegistrationList && props.studentRegistrationList[this.state.pageNumber] && props.studentRegistrationList[this.state.pageNumber].length) {
      this.setState({
        selectedFormId: props.studentRegistrationList[1][0].registrationFormSettingId,
      });
    }
  }

  render() {
    return (
      <StudentRegistrationListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    studentRegistrationList: state.studentRegistration.studentRegistrationList,
    studentRegistrationCount: state.studentRegistration.studentRegistrationCount,
    isLoading: state.common.isLoading,
    totalParentsCount: state.studentRegistration.totalParentsCount,
    totalStudentCount: state.studentRegistration.totalStudentCount,
    allForms: state.studentRegistration.allForms,
    registerParentsData: state.studentRegistration.registerParentsData,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
  fetchStudentRegistration, 
  getStudentRegistrationCount,
  deleteRegistration,
  fetchCustomers, 
  getCustomerCount,
  deleteStudent,
  getTotalParentsCount, 
  getTotalStudentsCount,
  getFormNames,
  getRegistrationDataForExcel,
  getRegisterParentsData, 
  resetRegisterParentsData,
  updateRegistrationUserStatus, 
  updateStudentUserStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentRegistrationListing);
