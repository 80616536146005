import axios from "axios";
import config from "../../../../config/environmentConfig";
//import { MEMBERSHIP_ACTION_LIST } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const saveTimeTable = (payload, props) => {
  return (dispatch) => {
    return axios
      .post( `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/timetable/saveOrUpdate`, payload)
      .then((response) => {

        showToasterMessage({
            messageType: "success",
            description: "Created Successfully!",
        });
        
        props.updateHeaderState({
          schoolDrawers: {
            ...props.schoolDrawers,
            addTimeTableDrawerVisible: false
          }
        });
        })
      .catch((err) => {
        
        showToasterMessage({
        messageType: "error",
        description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
        
      });
  };
};


