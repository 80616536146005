import React, { useState } from "react";
import { Empty, Timeline } from "antd";
import { getLocalDateFromUtcStr } from "../../../../utils";
// import { ICONS } from "../../../../static/constants";

const Activities = (props) => {
  const {customerActivityTrackList, leadActivityTrackList, companyInfo, leadData={}} = props;
  const [viewLeadAct, setViewLeadAct] = useState(false);
  const [actLimit, setActLimit] = useState(10);

  const regex = /^\s*$/;
  const viewLeadHistory = ()=>{
    const payload = {
      relationshipId: companyInfo.relationshipId,
      crmLeadId: props.customer.leadId,
    }
    props.fetchLeadActivityTrack(payload);
    setViewLeadAct(true);
  }
  return (
    <>
      <Timeline
        className="act-timeline"
        pending={
          (customerActivityTrackList || []).length > actLimit ? (
            <div
              className="cursor-pointer pt5"
              onClick={() => setActLimit(actLimit + 10)}
            >
              Show 1-{(customerActivityTrackList || []).length > (actLimit + 10) ? actLimit + 10 : (customerActivityTrackList || []).length} of{" "}
              {(customerActivityTrackList || []).length}
            </div>
          ) : (
            ""
          )
        }
        pendingDot={<i class="fi fi-rr-circle-ellipsis" />}
      >
        {viewLeadAct && (leadActivityTrackList || []).length ? (
          leadActivityTrackList.map((item, i) => {
            return (
              <Timeline.Item key={"leadAct" + i}>
                <div className="light-txt">
                  {item.dateCreated
                    ? getLocalDateFromUtcStr({
                        date: item.dateCreated,
                        format: "hh:mm A, DD MMMM YYYY",
                      })
                    : "-"}
                </div>
                <div className="b-text">
                  <i className="fi fi-rr-id-card-clip-alt" /> - {""}
                </div>
                <div className="light-txt">{item.changeDescription || "-"}</div>
              </Timeline.Item>
            );
          })
        ) : props.customer?.leadId ? (
          <div className="cursor-pointer pb20 pl25" onClick={viewLeadHistory}>
            View Lead History
          </div>
        ) : (
          ""
        )}

        {(customerActivityTrackList || []).length ? (
          customerActivityTrackList.slice(0, actLimit).map((item, i) => {
            return (
              <Timeline.Item key={`act` + i}>
                {item.activityDate ? (
                  <div className="light-txt">
                    {getLocalDateFromUtcStr({
                      date: item.activityDate,
                      format: "hh:mm A, DD MMMM YYYY",
                    })}
                  </div>
                ) : (
                  ""
                )}
                <div className="b-text">{item.activityTitle || "-"}</div>
                <div className="light-txt">
                  {item.userType === "admin" ? (
                    <i className={"fi fi-rr-user-gear"} />
                  ) : (
                    <i className={"fi fi-rr-id-card-clip-alt"} />
                  )}{" "}
                  - {!regex.test(item.userName) ? item.userName : item.userType}
                </div>
                <div className="light-txt">{item.description || ""}</div>
              </Timeline.Item>
            );
          })
        ) : (
          <Empty />
        )}
      </Timeline>
    </>
  );
};
export default Activities;
