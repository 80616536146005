import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClientOnboardingListingComp from '../../../components/B2B/ClientOnboarding';
// import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
import {fetchClientOnboardingData, downloadClientOnboardingData} from './actions'
import {showModal, pushModalToStack} from '../../../actions/commonActions'


class ClientOnboardingListing extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    
    this.props.fetchClientOnboardingData();
    // const companyInfo = this.props.companyInfo || {};
    // const urlAction = new URLSearchParams(this.props.location.search).get('action') || '';
    // const urlSourceSite = new URLSearchParams(this.props.location.search).get('sourceSite') || '';
    // const payload = {
    //   urlAction,
    //   urlSourceSite
    // }
    // if(payload.urlSourceSite === 'alpide' && payload.urlAction === 'downloadCsv'){
    //   // this.props.downloadClientOnboardingData(payload);
    // }
  }

  render() {
    return (
      <ClientOnboardingListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    clientOnboardingList : state.settings.clientOnboardingList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchClientOnboardingData,
    downloadClientOnboardingData,
    showModal,
    pushModalToStack,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ClientOnboardingListing);
