import React, { Fragment, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row, Button, Divider,Skeleton,Input } from "antd";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import AddLandedCost from "../../../../containers/modal/modalBody/supplier/AddLandedCost";
import { TextBox } from "../../../general/TextBox";
import ReactHtmlParser from 'react-html-parser';
const {TextArea} = Input

const ProductDetail = (props) => {
  const { lcItemList, lcSetupList = [] } = props;

  const calculateTotalLc = () => {
    let totalLcCost = 0;
    for (let i = 0; i < lcSetupList.length; i++) {
      totalLcCost = totalLcCost + Number(lcSetupList[i].amount || 0)
    }
    return totalLcCost;
  }

  const calcTotalDistro = () => {
    const totalDistros = { totalQty: 0, totalRate: 0, totalWeight: 0, totalVolume: 0, totalEqual: 0 };
    for (let i = 0; i < lcItemList?.length; i++) {
      const item = lcItemList[i];
      totalDistros.totalQty += (item.quantity || 0);
      totalDistros.totalRate += (item.anItemPurchasePrice || 0);
      totalDistros.totalWeight += (item.weight || 0);
      totalDistros.totalVolume += (item.volume || 0);
      totalDistros.totalEqual += (item.totalEqual || 0);
    }
    return totalDistros;
  }

  const calcTotalLcForEachDistro = () => {
    const eachDistroLcAmt = { qtyAmt: 0, rateAmt: 0, weightAmt: 0, volumeAmt: 0, equalAmt: 0 };

    for (let i = 0; i < lcSetupList.length; i++) {
      const item = lcSetupList[i];
      switch (item.distributionBy) {
        case 'quantity': {
          eachDistroLcAmt.qtyAmt += item.amount;
          break;
        }
        case 'rate': {
          eachDistroLcAmt.rateAmt += item.amount;
          break;
        }
        case 'weight': {
          eachDistroLcAmt.weightAmt += item.amount;
          break;
        }
        case 'volume': {
          eachDistroLcAmt.volumeAmt += item.amount;
          break;
        }
        case 'equal': {
          eachDistroLcAmt.equalAmt += item.amount;
          break;
        }
        default: {
        }
      }
    }

    return eachDistroLcAmt;
  }

  const calculateLcForEachItem = (props) => {
    const totalDistrosInList = calcTotalDistro();
    const distroAmtObj = calcTotalLcForEachDistro();
    const tempList = [];
    for (let i = 0; i < lcItemList?.length; i++) {
      let rowItem = lcItemList[i];
      rowItem.landedCost = ((rowItem.quantity || 0) * ((distroAmtObj.qtyAmt || 0) / (totalDistrosInList.totalQty || 1)))
        + ((rowItem.anItemPurchasePrice || 0) * ((distroAmtObj.rateAmt || 0) / (totalDistrosInList.totalRate || 1)))
        + ((rowItem.weight || 0) * ((distroAmtObj.weightAmt || 0) / (totalDistrosInList.totalWeight || 1)))
        + ((rowItem.volume || 0) * ((distroAmtObj.volumeAmt || 0) / (totalDistrosInList.totalVolume || 1)))
        + ((rowItem.equal || 0) * ((distroAmtObj.equalAmt || 0) / (totalDistrosInList.totalEqual || 1)))

      tempList.push(rowItem);
    }
    
     props.updateState({lcItemList: tempList})
  }

  useEffect(() => {calculateLcForEachItem(props)}, [props.lcSetupList]);

  const createLandedCost = () => {
    // validations
    if(!props.selectedSupplierId || !props.selectedTxn || !props.selectedTxnId){
      props.updateState({submittedLc: true});
      // props.showToasterMessage({
      //   // messageType: 'error',
      //   description: 'Complete Supplier Information is missing!'
      // })
      return
    }
    const modalData = {
      modalBody: 'Are you sure you want to create Landed Cost?',
      handleSubmit: () => {
        props.hideModal();
        const payload = { props: props }
        props.createLandedCost(payload);
      }
    };
    props.showModal(modalData);
  }

  const addToLcItemList = (idx, obj) =>{
    let tempList = props.lcItemList;
    let tempObj = tempList[idx];
    tempObj = {...tempObj, ...obj};
    tempList[idx] = tempObj;
    props.updateState({
      lcItemList: tempList
    })
  }


  return (
    <Fragment>
          <Skeleton loading={props.listLoading} active paragraph={{ rows: 7 }}>

      <div className="product-table mb10">
        <table>
          <thead>
            <tr>
              <th width='6%'>#</th>
              <th>Product</th>
              <th width='20%'>Description</th>
              <th>QTY</th>
              <th>Purchase Rate</th>
              <th>Exchange Rate</th>
              <th>Rate</th>
              <th>Landed Cost</th>
              <th>Actual Rate</th>
              <th width='6%'>Action</th>
            </tr>
          </thead>
          <tbody>
            {(lcItemList || [])?.length ? lcItemList.map((item, i) => {
              return <tr key={'lc' + 1}>
                <td width={'5%'}>{i+1}</td>
                <td>
                  {item.itemName}
                </td>
                <td>
                  <TextArea 
                    placeholder="Description"
                    style={{resize:"none"}}
                    value={ReactHtmlParser(item.description || '')}
                    onChange={(e)=>{
                      addToLcItemList(i, {description: e.target.value})
                    }}
                  />
                  {/* {ReactHtmlParser(item.description || '-')} */}
                </td>
                <td>
                  <TextBox
                    style={{width: '120px'}}
                    type='number'
                    value={item.quantity}
                    placeholder={'0'}
                    onChange={(e)=>{
                      addToLcItemList(i, {quantity: e.target.value})
                    }}
                  />
                </td>
                <td>
                  <TextBox
                    style={{width: '120px'}}
                    type='number'
                    value={(item.anItemPurchasePrice || '')}
                    placeholder='0.00'
                    onChange={(e) => {
                      addToLcItemList(i, {anItemPurchasePrice: Number(e.target.value)})
                    }}
                  />
                </td>
                <td>
                  { (lcSetupList || []).length ? props.lcSetupList[0].exchangeRate : '-' }
                </td>
                <td>
                  {Number(item.anItemPurchasePrice || 0) * Number((lcSetupList || []).length ? props.lcSetupList[0].exchangeRate : 1)}
                </td>
                <td>
                  {Number(item.landedCost).toFixed(2)}
                </td>
                <td>  
                  <TextBox
                    style={{width: '120px'}}
                    value={(((Number(item.anItemPurchasePrice || 0) * Number((lcSetupList || []).length ? props.lcSetupList[0].exchangeRate : 1)) * item.quantity) || 0).toFixed(2)}
                    disabled={true}
                  />
                </td>
                <td>
                  <i className="fi fi-rr-trash pointer" onClick={() => {
                    // let pList = supplierInvoiceDetailsList;
                    // pList.splice(i, 1);
                    // props.updateState({
                    //   invoicesDetails: pList
                    // })
                  }}></i>
                </td>
              </tr>
            })
              :
              ''
            }
          </tbody>
        </table>
      </div>

      {/* <div style={{ display: "flex", gap: "10px" }}>
        <Button className="grey-button"
          onClick={()=>{
            props.updateState({
              invoicesDetails : [...invoicesDetails, {}]
            })
          }}
        >
          <FormattedMessage
            id="customer.salesInvoice.form.button.addLine"
            defaultMessage=""
          />
        </Button>

        <Button className="grey-button"
          onClick={()=>{
            const modalData = {
              modalBody: 'Are you sure want to clear all fields?',
              handleSubmit: () => {
                props.updateState({
                  invoicesDetails: [{}]
                })
                props.hideModal();
              },
            };
            props.showModal(modalData);
          }}
        >
          <FormattedMessage
            id="customer.salesInvoice.form.button.clearAllLines"
            defaultMessage=""
          />
        </Button>
      </div> */}
      <div className="upload-btn-so"></div>
      <Divider style={{ margin: "10px 0px" }} />
      <Row style={{ height: "425px" }}>
        <Col span={12}>
          <div className="remarks-title">
            <FormattedMessage
              id="customer.salesInvoice.form.internalRemarks.label"
              defaultMessage=""
            />
          </div>
          <CKEditor type="inline" className="description-textarea" />
        </Col>
        <Col span={7} offset={5}>
          <div className="product-amount-values">
            <Row>
              <Col span={12}>
                <span className="title">
                  <FormattedMessage
                    id="customer.salesOrder.form.totalAmount.label"
                    defaultMessage=""
                  />
                </span>
              </Col>
              <Col span={12}>
                {
                  (props.lcSetupList || []).length ? <>
                    <span>{calculateTotalLc().toFixed(2)}</span>
                    <Button
                      type="primary"
                      className="ml10"
                      onClick={() => {
                        const tempObj = props.lcSetupList[0];
                        const editPayload = {
                          exchangeRate: tempObj.exchangeRate,
                          countryObj: {
                                currencyIcon: tempObj?.foreignCurrencyIcon,
                                currencyName: tempObj?.foreignCurrency,
                                currencyCode: tempObj?.currencyCode,
                                countryName: tempObj?.countryName,
                              }
                        }
                        props.pushModalToStack({
                          modalBody: <AddLandedCost {...props} {...editPayload} calculateLcForEachItem={calculateLcForEachItem} />,
                          width: "100%",
                          hideTitle: true,
                          hideFooter: true,
                          wrapClassName: "back-blur",
                          keyboard: false,
                          alertCancel: true,
                          calculateLcForEachItem:calculateLcForEachItem,
                          onCancel: () => {
                            props.showModal({
                              modalBody: 'All changes will be erased. Are you sure want to close?',
                              footer:{submit:{intlId:"button.close.label"}},
                              handleSubmit: () => {
                                props.popModalFromStack();
                              }
                            })
                          }
                        });
                      }}
                    >
                      Edit Landed cost
                    </Button>
                  </>
                    :

                    <Button
                      type="primary"
                      onClick={() => {
                        props.pushModalToStack({
                          modalBody: <AddLandedCost {...props} />,
                          width: "100%",
                          hideTitle: true,
                          hideFooter: true,
                          wrapClassName: "back-blur",
                          keyboard: false,
                          alertCancel: true,
                          onCancel: () => {
                            props.showModal({
                              modalBody: 'All changes will be erased. Are you sure want to close?',
                              footer:{submit:{intlId:"button.close.label"}},
                              handleSubmit: () => {
                                props.popModalFromStack();
                              }
                            })
                          }
                        });
                      }}
                    >
                      Add Landed cost
                    </Button>
                }
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="footer">
        <div className="left-fItems">
          <Button
            className="grey-button"
            onClick={() => {
              props.showModal({
                modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                footer:{submit:{intlId:"button.close.label"}},
                handleSubmit: () => {
                  props.popModalFromStack();
                }
              })
            }}
          >
            <FormattedMessage id="button.cancel.label" defaultMessage="" />
          </Button>
        </div>
        <div className="right-fItems">
          {/* <Button>
            <FormattedMessage id="button.draft.label" defaultMessage="" />
          </Button> */}

          <Button className="ant-btn-primary" onClick={() => { createLandedCost()}}>
            <FormattedMessage id="button.save.label" defaultMessage="" />
          </Button>
        </div>
      </div>
      </Skeleton>
    </Fragment>
  );
};

export default ProductDetail;
