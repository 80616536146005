import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col } from 'antd';
import { CustomButton } from '../../../../general/CustomButton';
import { TextBox } from '../../../../general/TextBox';
import { Dropdown } from '../../../../general/Dropdown';
import { ErrorMsg } from '../../../../general/ErrorMessage';
import { MODAL_TYPE } from '../../../../../static/constants';
import * as find from 'lodash.find';

function TeamDrawer(props) {

  const { goal, teamName } = props;

  const closeDrawer = () => {
    props.updateHeaderState({
      fundraising: {
        addTeamDrawerVisible: false,
        teamMaster: null
      }
    });
  }


  const handleSubmit = (props) => {
    props.updateState({
      submittedOnce: true
    });

    if (props.contactId && props.teamName && props.goal ) {
      const lead = find(props.teamLeadList, { contactId: Number(props.contactId) });
      lead.isTeamLead = 1;
      lead.fundraiserGoal= props.fundraiserGoal || 0;
      const payload = {
        relationshipId: (props.companyInfo || {}).relationshipId,
        teamName: props.teamName,
        fundraisingCampaignId: (props.campaignData || {}).fundraisingCampaignId,
        teamGoal: props.goal,
        fundraiserGoal: props.fundraiserGoal || 0,
        teamMemberList: [lead],
        teamMasterId: props.teamMasterId,
        version: props.version
      }

      const modalBody = <span>
        <FormattedMessage
          id='save.confirmation'
          defaultMessage=''
        />
      </span>;
      const modalData = {
        modalBody,
        handleSubmit: () => {
          props.saveTeamMaster(payload, props);
        },
      };
      props.showModal(modalData);
    }

  }

  const AddNewContact = (data) => {
    let formData = {
        isFundraiser: true,
        teamMasterId: data.teamMasterId,
        funcToCall: () => {
            props.getAllTeamLead({
                relationshipId: (props.companyInfo || {}).relationshipId, pageNumber: 0,
                pageSize: 100,
            });
        }
    };
    const payloadData = {
        title: <div>
            <FormattedMessage id='addItem.text.createFundraiser' defaultMessage='' />
            <div style={{ 'font-size': '12px' }}> {(data.firstName || "") + " " + (data.lastName || "")}</div>
        </div>,
        formData,
        hideFooter: true,
        modalData: {
            modalType: MODAL_TYPE.CONTACT,
            data: { formData },

        },
        handleSubmit: (formData = {}) => {
            props.hideModal(data);

        }
    };
    props.showModal(payloadData);
}

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-title">

            <FormattedMessage id='fundraising.campaign.addTeam.drawerName' defaultMessage=' ' />
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-drawer"
        visible={props.addTeamDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
        footer={<>
        <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.submit'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </>}
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='fundraising.campaign.teamDrawer.field.teamName' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12}>
              <TextBox
                type='text'
                className={(props.submittedOnce && !props.teamName) ? 'input-text-error' : ''}
                value={teamName}
                onChange={(e) => {
                  props.updateState({
                    teamName: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'fundraising.campaign.teamDrawer.field.teamName', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.teamName }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>

          <br />
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='teamGoal.text' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12} className="input-currency-control">
              <span className="currency-tag" style={{ left: '10px' }}>
                {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
              </span>
              <TextBox
                type='number'
                className={(props.submittedOnce && (props.goal <= 0 || !props.goal)) ? 'input-text-error' : ''}
                value={goal || null}
                onChange={(e) => {
                  props.updateState({
                    goal: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'teamGoal.text', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.goal }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <br />
          
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <label>
                <FormattedMessage id='fundraising.campaign.teamDrawer.field.teamLead' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <Dropdown
                items={props.teamLeadList || []}
                className={(props.submittedOnce && (!props.contactId)) ? 'input-text-error' : ''}
                valueKeyName='firstName'
                optionKeyName='contactId'
                lastNameKey='lastName'
                value={props.contactId}
                onSearch={(searchText) => {
                  props.getAllTeamLead({ relationshipId: props.companyInfo.relationshipId, pageNumber: 0, pageSize: 20, searchName: searchText });
                }}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'fundraising.campaign.teamDrawer.field.teamLead',
                    defaultMessage: ''
                  })
                }
                onSelect={(optionValue, option) => {
                    props.updateState({
                      contactId: option.value
                    });
                  }}
                  canAddNew={true}
                  onAddNewClick={(payload) => {
                    AddNewContact(props);
                  }}  
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.contactId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          
          <br />
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='captainGoal.text' defaultMessage='' />
              </span>
              {/* <span className="required">*</span> */}
            </Col>
            <Col span={12} className="input-currency-control">
              <span className="currency-tag" style={{ left: '10px' }}>
                {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
              </span>
              <TextBox
                type='number'
                // className={(props.submittedOnce && (props.fundraiserGoal <= 0 || !props.fundraiserGoal)) ? 'input-text-error' : ''}
                value={props.fundraiserGoal || null}
                onChange={(e) => {
                  props.updateState({
                    fundraiserGoal: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'captainGoal.text', defaultMessage: '' })}
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || props.fundraiserGoal }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>
          </Row>

        </Form>

        
      </Drawer>
    </Fragment>
  );
}

export default injectIntl(TeamDrawer);
