import React from "react";
import { Row, Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
import { showToasterMessage } from '../../../../../utils';
import { TextBox } from "../../../../general/TextBox";


const OnlineThemes = (props) => {
  //  const onlineThemes = props.companyInfoData.onlineThemes || {};

    const updateBusinessData = (props) => {
        if (props.companyInfoData.onlineThemes.textColor && props.companyInfoData.onlineThemes.bgColor && props.companyInfoData.onlineThemes.textColor === props.companyInfoData.onlineThemes.bgColor) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'sameColor.validation' })
            });
        }
        const modalData = {
            modalBody: 'Are you sure you want to save?',
            handleSubmit: () => {
                // props.updateState({
                //     companyInfoData: {
                //         ...props.companyInfoData, onlineThemes: JSON.stringify(props.onlineThemes)
                //     }
                // })
                props.updateBusinessData(props);
            }
        };
        props.pushModalToStack(modalData);
    }

    return (
        <div className="popup-tabs">

            <div className="company-info-about" style={{position:'relative'}}>

                <Row>
                    <Col span={6} >
                        <span > <FormattedMessage id='formHeading.textColor' /> </span>
                    </Col>
                    <Col span={1}>
                        <ColorPicker
                            enableAlpha={false}
                            color={props.companyInfoData.onlineThemes.textColor || 'fff'}
                            onChange={(obj) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        onlineThemes: {
                                            ...props.companyInfoData.onlineThemes, textColor: obj.color
                                        }
                                    },
                                });
                            }}
                            mode="RGB"
                        />
                    </Col>

                    <Col span={9}  style={{ background: props.companyInfoData.onlineThemes.bgColor || '#06033A', color: props.companyInfoData.onlineThemes.textColor || '#fff', paddingLeft: '20px', paddingRight: '20px', height: '40px' }}>
                            <div style={{ fontSize: '15px', textAlign:'center', padding:'7px 0px 0px' }}> {props.companyInfoData.storeName} </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={6} >
                        <span > <FormattedMessage id='formHeading.bgColor' /> </span>
                    </Col>
                    <Col span={1}>
                        <ColorPicker
                            enableAlpha={false}
                            color={props.companyInfoData.onlineThemes.bgColor || '#06033A'}
                            onChange={(obj) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        onlineThemes: {
                                            ...props.companyInfoData.onlineThemes, bgColor: obj.color
                                        }
                                    }
                                });
                            }}
                            mode="RGB"
                        />
                    </Col>
                    {/* <Col span={1}>
                        <div style={{ 'font-size': '18px', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                            updateBusinessData(props);
                        }}><button className="save-but" style={{ color: '#107360', 'cursor': 'pointer' }}><i className="fa fa-floppy-o"></i></button> </div>
                    </Col> */}
                </Row>
                <Row>
                    <Col span={6} >
                        <span > <FormattedMessage id='facebookLink.text' /> </span>
                    </Col>
                    <Col span={10}>
                    <TextBox
                            maxLength={75}
                            countStyle={{top:"10px"}}
                            className="textbox-pd"
                            value={props.companyInfoData.onlineThemes.facebookLink} onChange={(e) => {
                                 props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        onlineThemes: {
                                            ...props.companyInfoData.onlineThemes, facebookLink: e.target.value
                                        }
                                    }
                                });
                            }} />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <span > <FormattedMessage id='instagramLink.text' /> </span>
                    </Col>
                    <Col span={10}>
                    <TextBox
                            maxLength={75}
                            countStyle={{top:"10px"}}
                            className="textbox-pd"
                            value={props.companyInfoData.onlineThemes.instagramLink} onChange={(e) => {
                                 props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        onlineThemes: {
                                            ...props.companyInfoData.onlineThemes, instagramLink: e.target.value
                                        }
                                    }
                                });
                            }} />
                    </Col>
                </Row>
                <Row>
                    <Col span={6} >
                        <span > <FormattedMessage id='youtubeLink.text' /> </span>
                    </Col>
                    <Col span={10}>
                    <TextBox
                            maxLength={75}
                            countStyle={{top:"10px"}}
                            className="textbox-pd"
                            value={props.companyInfoData.onlineThemes.youtubeLink} onChange={(e) => {
                                 props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        onlineThemes: {
                                            ...props.companyInfoData.onlineThemes, youtubeLink: e.target.value
                                        }
                                    }
                                });
                            }} />
                    </Col>
                </Row> 
                <Row>
                    <Col span={6} >
                        <span > <FormattedMessage id='twitterLink.text' /> </span>
                    </Col>
                    <Col span={10}>
                    <TextBox
                            maxLength={75}
                            countStyle={{top:"10px"}}
                            className="textbox-pd"
                            value={props.companyInfoData.onlineThemes.twitterLink} onChange={(e) => {
                                 props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        onlineThemes: {
                                            ...props.companyInfoData.onlineThemes, twitterLink: e.target.value
                                        }
                                    }
                                });
                            }} />
                    </Col>
                </Row> 
                 <Row>
                    <Col span={6} >
                        <span>{props.companyInfoData.storeName} <FormattedMessage id='link.text' /> </span>
                    </Col>
                    <Col span={10}>
                    <TextBox
                            maxLength={75}
                            countStyle={{top:"10px"}}
                            className="textbox-pd"
                            placeholder={props.intl.formatMessage({ id: 'organizationWebsiteUrl.placeholder' })}
                            value={props.companyInfoData.onlineThemes.link} onChange={(e) => {
                                 props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        onlineThemes: {
                                            ...props.companyInfoData.onlineThemes, link: e.target.value
                                        }
                                    }
                                });
                            }} />
                    </Col>
                </Row>
                <Row>
                    <Col span={6} >
                        <span > <FormattedMessage id='footerMessage.text' /> </span>
                    </Col>
                    <Col span={10}>
                        <TextBox
                            maxLength={75}
                            countStyle={{top:"10px"}}
                            className="textbox-pd"
                            placeholder={'All Rights Reserved - ' + props.companyInfoData.storeName }
                            value={props.companyInfoData.onlineThemes.footerMessage} onChange={(e) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        onlineThemes: {
                                            ...props.companyInfoData.onlineThemes, footerMessage: e.target.value
                                        }
                                    }
                                });
                            }} />
                    </Col>
                </Row>
                
                </div>
<div className="popup-btn">
                <div></div>
                        <Button className="ant-btn ant-btn-primary update-button" onClick={() => {
                            updateBusinessData(props);
                        }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                    
                </div>
        </div>
    )
};

export default OnlineThemes;