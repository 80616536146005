import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../actions/commonActions';
import ProcessPaymentComp from '../../components/orderPreview/ProcessPayment';
import { getPayOnlineUrl,getAuthNetPaymentUrl } from '../InvoicePreview/action';
import { fetchSODetail } from '../modal/modalBody/customer/SODetail/action';

class ProcessPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      neverExpires: 1
    }
  }

  componentDidMount() {
    // const linkProps = queryString.parse(this.props.location.search) || {}
    // const payload = {
    //   webInvoiceId: this.props.webInvoiceId || linkProps.webInvoiceId,
    // };
    //this.props.getInvoiceDetail(payload); 
  }

  render() {
    return (
      <Fragment>
        <ProcessPaymentComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSODetail,
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  getPayOnlineUrl,
  getAuthNetPaymentUrl
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProcessPayment);
