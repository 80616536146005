import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreditMemoListingComp from '../../../../components/customer/creditMemo/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchCreditMemos, getCreditMemoCount, resetPaginatedData, deleteCreditMemo } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack, fetchUserPreferences, saveUserPrefrences, deleteAuditTrail, createAuditTrail } from '../../../../actions/commonActions';
import * as find from 'lodash.find';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
import { FormattedMessage } from 'react-intl';


class CreditMemoListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      txPreferences: { txType: "creditMemo", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['creditMemoNumber', 'customerName', 'creditMemoDate', 'creditMemoTotalAmount'],
      txAvailableColumn: {
        creditMemoNumber: true,
        customerName: true,
        creditMemoDate: true,
        creditMemoTotalAmount: true,
        status: true,
      },
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.sales.creditMemo' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
        {
            name: <FormattedMessage id='sidebar.menuItem.sales' defaultMessage='' />,
        }, 
        {
            name: <FormattedMessage id='sidebar.menuItem.creditMemo' defaultMessage='' />,
        }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25
    };
    fetchPaginationDataIfNeeded('fetchCreditMemos', 'creditMemo', this.props, payload);
    fetchDataIfNeeded('getCreditMemoCount', 'creditMemoCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "creditMemo", templateName: "standard" }) || this.state.txPreferences;
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <CreditMemoListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    creditMemoList: state.creditMemo.creditMemoList,
    creditMemoCount: state.creditMemo.creditMemoCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    headerLoading: state.common.headerLoading,
    listLoading: state.common.listLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCreditMemos,
  getCreditMemoCount,
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
  createAuditTrail,
  deleteAuditTrail,
  deleteCreditMemo,
  fetchUserPreferences,
  saveUserPrefrences
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreditMemoListing);
