import React, { Component } from 'react';
import LeadTransferComp from "../../../components/leads/LeadTansfer";
import { pushModalToStack, popModalFromStack, showModal, hideModal, fetchAllEmployees, fetchProjects } from "../../../actions/commonActions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllLeadList, getAllLeadListCount, deleteLead, saveOrUpdateNotes, getAllLeadNotes, changeLeadStatus, saveOrUpdateReminder,
   changeCrmLeadEmpAssignedList, getAllLeadReminder, saveOrUpdateLeadStatus, deleteCrmNotes, deleteLeadReminder,bulkTransfer  } from './action';
import { getAllLeadStatus, getAllLeadSources} from '../../modal/modalBody/leads/createLead/action';
import { fetchCrmLeadFormList } from '../../../containers/leads/LeadForm/action';
import AllLeadsFilter from '../../../components/leads/AllLeads/AllLeadsFilter';
import TransferLeadsDrawer from '../../../components/leads/LeadTansfer/transferDrawer';
import { getTeam } from '../../company/EmployeeMeta/Team/action';
import { getTeamMembersByTeamName } from '../../modal/modalBody/leads/createLead/action';
class TransferLeads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      pageSize: 25,
      errMsg: false
    }

  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    }

    this.props.fetchAllLeadList(payload);
    this.props.getAllLeadListCount(payload);
    this.props.fetchAllEmployees(payload);
    this.props.getAllLeadNotes(payload);
    this.props.getAllLeadStatus(payload);
    this.props.getAllLeadReminder(payload);
    this.props.fetchCrmLeadFormList(payload);
    this.props.fetchProjects(payload);
    this.props.getAllLeadSources(payload);
    this.props.getTeam(payload);
  }


  render() {
    return <>
      <LeadTransferComp {...this.props} {...this.state}  updateState={(data) => { this.setState(data) }}/>
      {this.state?.allLeadsFilterDrawerVisible && <AllLeadsFilter {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />}
      {this.state?.allLeadsTransferDrawerVisible && <TransferLeadsDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />}
    </>   
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    allLeadList: state.crm.allLeadList,
    allLeadListCount: state.crm.allLeadListCount,
    leadAssignList: state.common.employees,
    allLeadNotesList: state.crm.crmLeadNotesList,
    allLeadReminderList: state.crm.crmLeadReminderList,
    crmLeadStatusList: state.crm.crmLeadStatusList,
    crmLeadFormList: state.crm.crmLeadFormList,
    projectList: state.common.projectList,
    crmLeadSourceList: state.crm.crmLeadSourceList,
    employeeByTeamName:state.crm.employeeByTeamName,
    teamList: state.employee.teamList

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  fetchAllLeadList,
  getAllLeadListCount,
  deleteLead,
  fetchAllEmployees,
  saveOrUpdateNotes,
  getAllLeadNotes,
  getAllLeadStatus, 
  changeLeadStatus,
  saveOrUpdateReminder,
  changeCrmLeadEmpAssignedList,
  getAllLeadReminder,
  saveOrUpdateLeadStatus,
  fetchCrmLeadFormList,
  deleteCrmNotes,
  deleteLeadReminder,
  fetchProjects,
  getAllLeadSources,getTeam,getTeamMembersByTeamName,bulkTransfer
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransferLeads);