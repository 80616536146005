import React from "react";
import { ICONS } from "../../../../../../static/constants";
import { capitalizeFirstLetter } from "../../../../../../utils";

const Contact = (props) => {

    const {studentData,primaryContact} = props; 

    return (
        <div className="contact-info">
           
            
            <ul className="primary-info">
                <div className="b-text">Primary</div>

                <li className="p-info">
                    <i className="fi fi-rs-circle-user mr5" />
                    {primaryContact.firstName ? capitalizeFirstLetter(primaryContact.firstName) : <div className="empty-data-box" />}
                </li>
                <li className="p-info">
                    <i class={`${ICONS.MAIL} mr5`} />
                    {primaryContact.emailAddress ? primaryContact.emailAddress : <div className="empty-data-box" />}
                </li>
                <li className="p-info">
                    <i className={`${ICONS.CALL} mr5`} /> 
                    {primaryContact.cellPhone ? primaryContact.cellPhone : <div className="empty-data-box" />}
                </li>

            </ul>


            <ul className="primary-info">
                <div className="b-text">Self</div>

                <li className="p-info">
                    <i className="fi fi-rs-circle-user mr5" />
                    {studentData.studentName ? studentData.studentName : <div className="empty-data-box" />}
                </li>
                <li className="p-info">
                    <i className= {`${ICONS.MAIL} mr5`} />
                    {studentData.studentEmail ? studentData.studentEmail : <div className="empty-data-box" />}
                </li>
                <li className="p-info">
                    <i className={`${ICONS.CALL} mr5`} />
                    {studentData.studentMobileNo ? studentData.studentMobileNo : <div className="empty-data-box" />}
                </li>

            </ul>
    
            
            {/* <div>
                <ul className="primary-info">
                    <div className="b-text">Father</div>

                    <li className="p-info">
                        <div className='icon-user'>
                        <i className="fi fi-rs-circle-user"></i>
                        </div>
                        Naresh Batra</li>
                    <li className="p-info">
                    <div className='icon-user'>
                    <i class="fi fi-rr-envelope"></i>
                        </div>

                        narsh.batra@gmail.com</li>
                    <li className="p-info">
                    <div className='icon-user'>
                    <i class="fi fi-rr-phone-call"></i> 
 
                        </div>
                        +91 99106 86611</li>
                    <li className="p-info">
                    <div className='icon-user'>
                    <i class="fi fi-brands-whatsapp"></i>
                        </div>
                        +91 99106 86611</li>

                </ul>
            </div> */}

        </div>
    )
}
export default Contact;

