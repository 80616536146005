import React from "react";
import DayBook from "../../../components/finance/DayBook";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchDayBookList, getDayBookCount, resetPaginatedData } from "./action";
import { pushModalToStack , popModalFromStack, showModal, hideModal} from "../../../actions/commonActions";
import { formatDateForBackend} from "../../../utils";

 class DayBookListComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      pageSize: 200,
      isStudentLoaded: false,
      formNameMap: {},
      customerInfo: props.customerInfo,
     
    };
  }

  
  componentDidMount() {

    const companyInfo = this.props.companyInfo || {};

    let dateObj = new Date();
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 200,
      filterStartDate: formatDateForBackend(dateObj.setHours(0, 0, 0, 0)),
      filterEndDate: formatDateForBackend(dateObj.setHours(23, 59, 59, 999))
    };
    this.props.fetchDayBookList(payload, companyInfo);
    this.props.getDayBookCount(payload, companyInfo)
  }

  render() {
    return (
      <>
        <DayBook
          {...this.props}
          {...this.state}
           updateState={(data) => {
            this.setState(data);
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      isLoading: state.common.isLoading,
      permissions: state.auth.permissions,
      crmLeadFormList: state.crm.crmLeadFormList,
      crmLeadFormListCount: state.crm.crmLeadFormListCount,
      dayBookList : state.finance.dayBookList,
      dayBookCount :  state.finance.dayBookCount, 
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDayBookList,
    getDayBookCount,
    pushModalToStack,
    popModalFromStack,
    showModal, 
    hideModal,
    resetPaginatedData
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DayBookListComp);
