import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Skeleton, Checkbox, Menu, Input,Empty } from "antd";
import { getMomentDateForUIReadOnly } from "../../../utils";
import { CONSTANTS, ICONS } from "../../../static/constants";
import DropdownAction from "antd/lib/dropdown";
import PageHeader from "../../Common/pageHeader"
const { Search } = Input;

// const format = CONSTANTS.DISPLAY_DATE_FORMAT;
// const { RangePicker } = DatePicker;
// const { Panel } = Collapse
const LandedCostListingComp = (props) => {
  const {
    landedCostSetupList,
    pageNumber,
  } = props;

  const breadCrumbList = [
    {
      name: "Purchase",
    },
    {
      name: "Landed Cost Setup",
    },
  ];
  // const newLandedCost = (e) => {
  //   if (e) {
  //     props.updateHeaderState({
  //       landedCost: {
  //         ...props,
  //         expenseName: e.expenseType,
  //         ledgerAccountIdCredit: e.ledgerAccountIdCredit,
  //         ledgerAccountCredit: e.ledgerNameCredit,
  //         ledgerAccountIdDebit: e.ledgerAccountIdDebit,
  //         ledgerAccountDebit: e.ledgerNameDebit,
  //         lcExpenseTypeId: e.lcExpenseTypeId,
  //         lcVersion: e.version,
  //         isEditLc: true,
  //         landedCostDrawer: true,
  //       },
  //     });
  //   } else {
  //     props.updateHeaderState({
  //       landedCost: {
  //         ...props,      
  //         landedCostDrawer: true,
  //       },
  //     });
  //   }
  // };
  const newLandedCost = () => {
    props.updateHeaderState({
      landedCost: {
        ...props,
        landedCostDrawer: true,
      },
    });
  };
  const editLandedCost = (e) => {
    props.updateHeaderState({
      landedCost: {
        ...props,
        landedCostDrawer: true,
        isUpdate: true,
        expenseName: e.expenseType,
        ledgerAccountIdCredit: e.ledgerAccountIdCredit,
        ledgerAccountCredit: e.ledgerNameCredit,
        ledgerAccountIdDebit: e.ledgerAccountIdDebit,
        ledgerAccountDebit: e.ledgerNameDebit,
        lcExpenseTypeId: e.lcExpenseTypeId,
        lcVersion: e.version,
        isEditLc: true,       
      },
    });
  };

  
  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            editLandedCost(e);
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            props.showModal({
              title: "Confirmation",
              modalBody: "Are you sure want to delete?",
              handleSubmit: () => {
                props.deleteLandedCostSetup(e, props);
              },
            });
          }}
        >
          <i className="fi fi-rr-trash"></i>&nbsp; Delete
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Fragment>

      <PageHeader {...props}
        pageName="sidebar.menuItem.landedCostSetup"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={newLandedCost}
      />
      <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="purchase.landindCostSetup.table.heading"
                  defaultMessage=""
                />
              </div>          
                  <div className="vertical-sep" />
                  <div>{props.landedCostSetupList[pageNumber].length}</div>            
            </div>
            <div className="right-actions">
              <Search placeholder="Enter to search" />
            </div>
          </div>
          <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>

          <div className="table-container no-pagination">
            <table>
              <thead>
                <tr>
                  <th>
                    <Checkbox />
                  </th>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  {/* <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th> */}
                  <th>
                    <FormattedMessage
                      id="purchase.landindCost.table.heading.expenseName"
                      defaultMessage=""
                    />
                  </th>
                  {/* <th><FormattedMessage id='purchase.landindCost.table.heading.createdDate' defaultMessage=''/></th> */}
                  <th>Date Created</th>
                  <th width="6%" className='text-center'>
                    <FormattedMessage
                      id="purchase.landindCost.table.heading.action"
                      defaultMessage=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(landedCostSetupList[pageNumber] || []).length ? 
                  landedCostSetupList[pageNumber].map((rowData, i) => {
                    return (
                      <tr key={i + 1}>
                        <td>
                          <Checkbox />
                        </td>
                        <td>{i + 1}</td>
                        {/* <td>{rowData.lcExpenseTypeId}</td> */}
                        <td>{rowData.expenseType}</td>
                        <td>
                          {rowData.dateCreated &&
                            getMomentDateForUIReadOnly({
                              date: rowData.dateCreated,
                              format: CONSTANTS.TABLE_DATE_FORMAT,
                            })}
                        </td>
                        <td className='text-center'>
                          <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                          >
                            <i className="fi fi-rr-menu-dots-vertical pointer"></i>
                          </DropdownAction>
                        </td>
                      </tr>
                    );
                  })
                :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            {/* <Pagination
                size="small"
                total={purchaseInvoiceCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber, pageSize, props);
                }}
              /> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(LandedCostListingComp);
