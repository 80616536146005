import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import {  SETTING_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchRegistrationFormSettingList= (payload) => {
    return dispatch => {
        return axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/getAllForms?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`)
          .then(res => {
            const { data } = res
            dispatch({ type: SETTING_ACTION_LIST.REGISTRATION_FORM_LIST, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = SETTING_ACTION_LIST.REGISTRATION_FORM_LIST;
            }
          })
      }
  }


  export const deleteRegistrationForm= (payload, props) => {
    return dispatch => {
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/delete`,payload)
          .then(res => {
            showToasterMessage({messageType: 'success',  description: lang[res.data.message || 'common.delete.success'] });
            props.fetchRegistrationFormSettingList({...payload, pageNumber: 0, pageSize:100});
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = SETTING_ACTION_LIST.REGISTRATION_FORM_LIST;
            }
          })
      }
  }