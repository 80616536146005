import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AllowanceDrawer from '../../../../../components/drawer/company/PayrollManagement/Allowance';
import { createAllowance } from '../../../../company/PayrollManagement/Allowance/action';
import { showModal, hideModal } from '../../../../../actions/commonActions';


class AllowanceDrawerCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(this.props.allowanceData || {})

        };
      }


    render() {
        return <AllowanceDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        drawerLoading: state.common.drawerLoading,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createAllowance,
    showModal,
    hideModal, 
    // pushModalToStack,
    // popModalFromStack,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllowanceDrawerCon);