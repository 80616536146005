import React from 'react';
//import { FilePdfOutlined, MailOutlined } from '@ant-design/icons';
import {Row, Col, Button,Skeleton} from 'antd'
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS, TRANSACTION_TYPES } from '../../../../../static/constants';
import { fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../../../utils';
import { FormattedMessage } from 'react-intl';

const SupplierPaymentDetails =  (props) =>{
    const { paymentDetail } = props;
    const getPayloadForPdf = () => {
        return {
            fileName : (paymentDetail.paymentNumber || 'Supplier Payment')+'.pdf',
            transactionName: 'supplierPayment',
            paymentDetail: paymentDetail,
            supplierId: paymentDetail.supplierId,
            companyInfo: props.companyInfo
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }
    
    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired= true;
        payload.supplierPaymentId = paymentDetail.supplierPaymentId;
        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.SUPPLIER_PAYMENT,
        });
    };
    return (
        <div className="payment-details-modal">

            <div className="modal-dialog">
                <div className="modal-content">
                    <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>
                    <div className="inner-content prl30">

                        <div className="read-only-content">
                            {paymentDetail.supplierName ?
                                <Row>
                                    <Col span={10} className="text-right title">
                                        <FormattedMessage id='modal.txDetails.payment.supplierName' defaultMessage='' />
                                    </Col>

                                    <Col span={14}>
                                        {paymentDetail.supplierName}
                                    </Col>
                                </Row> : ''
                            }
                            {paymentDetail.CustomerId ?
                                <Row>
                                    <Col span={10} className="text-right title">
                                        <FormattedMessage id='modal.customer.payInvoice.customerName' defaultMessage='' />
                                    </Col>

                                    <Col span={14}>
                                        {paymentDetail.customerName || ''}
                                    </Col>
                                </Row> : ''
                            }

                            <Row className="pt10">
                                <Col span={10} className="text-right title">
                                <FormattedMessage id='modal.txDetails.payment.paymentNumber' defaultMessage='' /> 
                                </Col>

                                <Col span={14}>
                                    {paymentDetail.paymentNumber}
                                </Col>
                            </Row>
                            {paymentDetail.txNumber ?
                                <Row className="pt10">
                                    <Col span={10} className="text-right title">
                                        <FormattedMessage id='transactionNumber.text' defaultMessage='' />
                                    </Col>

                                    <Col span={14}>
                                        {paymentDetail.txNumber}
                                    </Col>
                                </Row> : ''
                            }

                            <Row className="pt10">
                                <Col span={10} className="text-right title">
                                <FormattedMessage id='modal.txDetails.payment.paymentDate' defaultMessage='' /> 
                                </Col>

                                <Col span={14}>
                                    {(paymentDetail.paymentDate && getMomentDateForUIReadOnly({date: paymentDetail.paymentDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                </Col>
                            </Row>

                            {paymentDetail.paymentModeName && <Row className="pt10">
                                <Col span={10} className="text-right title">
                                    <FormattedMessage id='modal.txDetails.payment.paymentMode' defaultMessage='' />
                                </Col>

                                <Col span={14}>
                                    {paymentDetail.paymentModeName}
                                </Col>
                            </Row>
                            }

                            <Row className="pt10">
                                <Col span={10} className="text-right title">
                                <FormattedMessage id='modal.txDetails.payment.paymentAmount' defaultMessage='' />
                                </Col>

                                <Col span={14}>
                                    {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}/> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(paymentDetail.paymentAmount || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Col>
                            </Row>

                            {paymentDetail.invoiceAmount ?
                                <Row className="pt10">
                                    <Col span={10} className="text-right title">
                                        <FormattedMessage id='modal.txDetails.payment.invoiceAmount' defaultMessage='' />
                                    </Col>

                                    <Col span={14}>
                                        {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(paymentDetail.invoiceAmount || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </Col>
                                </Row> : ''
                            }
                            {paymentDetail.reference &&
                                <Row className="pt10">
                                    <Col span={10} className="text-right title">
                                    <FormattedMessage id='modal.txDetails.payment.refNumber' defaultMessage='' />
                                    </Col>

                                    <Col span={14}>
                                        {paymentDetail.reference}
                                    </Col>
                                </Row>
                            }

                            {paymentDetail.remarks &&
                            <Row className="pt10">
                                <Col span={10} className="text-right title">
                                    <FormattedMessage id='modal.txDetails.payment.remarks' defaultMessage='' />
                                </Col>

                                <Col span={14}>
                                    {ReactHtmlParser(paymentDetail.remarks)}
                                </Col>
                            </Row>
                            }
                            { paymentDetail.projectMasterId &&
                            <Row className="pt10">
                                <Col span={10} className="text-right title">
                                <FormattedMessage id='modal.txDetails.payment.project' defaultMessage='' />
                                </Col>

                                <Col span={14}>
                                    {(paymentDetail.projectMasterId +'-'+paymentDetail.projectName)}
                                </Col>
                            </Row>
                            }
                        </div>
                    </div>

                    <div className="button-group-action payment-details-action">
                        <Button
                            type="primary"
                            onClick={() => {
                                openEmailDrawer(props);
                            }}
                        >
                            <FormattedMessage id='modal.txDetails.common.email' defaultMessage='' />
                        </Button>

                        <Button
                            type="primary"
                            onClick={() => {
                                generatePDF();
                            }}
                        >
                            <FormattedMessage id='modal.txDetails.common.download' defaultMessage='' />
                        </Button>


                        <Button onClick={() => {
                            props.pushModalToStack({
                                modalBody: <FormattedMessage id="common.delete.confirmation"/>,
                                handleSubmit: () => {
                                    props.deletePayment(paymentDetail, props);
                                    props.hideModal();
                                }
                            })
                        }}
                            type="default"
                        >
                            <FormattedMessage id='delete.text' defaultMessage='' />
                        </Button>
                    </div>
                    </Skeleton>
                </div>
            </div>
            </div>
    );

}

export default SupplierPaymentDetails;
