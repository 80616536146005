import React from 'react';
import { FormattedMessage } from 'react-intl';
import PageBreadcrumb from './../PageBreadcrumb';
import './reports.scss';
import AgedDebtors from '../../containers/modal/modalBody/reports/AgedDebtors';
import AgedCreditors from '../../containers/modal/modalBody/reports/AgedCreditors';
import CustomerPartyLedger from '../../containers/modal/modalBody/reports/customer/partyLedger';
import SupplierPartyLedger from '../../containers/modal/modalBody/reports/supplier/partyLedger';
import { CONSTANTS } from '../../static/constants';
import { getMomentDateForUIReadOnly } from '../../utils';
import { FileZipTwoTone, ContainerTwoTone } from '@ant-design/icons';
const breadCrumbList = [
    {
        name: 'Dashboard',
        link: 'dashboard'
    }, {
        name: 'Reports',
    }
];



const Reports = (props) => {

    const openAgedDebtorsTemplate = (data) => {
        const modalData = {
            title: <div>
                <div className='ant-modal-title'><FormattedMessage id='reports.title.agingOfAccountReceivable' defaultMessage='' /></div>
                <div className='modal-sub-title'> {props.companyInfo.storeName || ''}</div>
                <div className='modal-sub-title'><FormattedMessage id='asOn.text' defaultMessage='' /> {getMomentDateForUIReadOnly({date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT_FY})}</div>
            </div>,
            modalBody: <AgedDebtors {...props} />,
            width: 1100,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const openAgedCreditorsTemplate = (data) => {
        const modalData = {
            title: <div>
                <div className='ant-modal-title'><FormattedMessage id='reports.title.agingOfAccountPayable' defaultMessage='' /></div>
                <div className='modal-sub-title'> {props.companyInfo.storeName || ''}</div>
                <div className='modal-sub-title'><FormattedMessage id='asOn.text' defaultMessage='' /> {getMomentDateForUIReadOnly({date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT_FY})}</div>
            </div>,
            modalBody: <AgedCreditors {...props} />,
            width: 1100,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const openCustomerPartyTemplate = (data) => {
        const modalData = {
            title: <div>
                <div className='ant-modal-title'><FormattedMessage id='reports.title.customerPartyLedger' defaultMessage='' /></div>
                <div className='modal-sub-title'> {props.companyInfo.storeName || ''}</div>
                <div className='modal-sub-title'><FormattedMessage id='asOn.text' defaultMessage='' /> {getMomentDateForUIReadOnly({date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT_FY})}</div>
            </div>,
            modalBody: <CustomerPartyLedger {...props} />,
            width: 1300,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const openSupplierPartyTemplate = (data) => {
        const modalData = {
            title: <div>
                <div className='ant-modal-title'><FormattedMessage id='reports.title.supplierPartyLedger' defaultMessage='' /></div>
                <div className='modal-sub-title'> {props.companyInfo.storeName || ''}</div>
                <div className='modal-sub-title'><FormattedMessage id='asOn.text' defaultMessage='' /> {getMomentDateForUIReadOnly({date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT_FY})}</div>
            </div>,
            modalBody: <SupplierPartyLedger {...props} />,
            width: 1300,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    return (
        <>
    
            <div className="view-top-bar">
                <div className="top-view-left">
                    <div className="page-heading">
                    Reports
                    </div>
                    <div className="vertical-sep" />
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div>
             
            </div>

            <div className="main-box">
                <div className='customer-box'>
                    <div className="title"><FormattedMessage id='reports.title.customer' defaultMessage='' /></div>
                    <div className="report-box">
                        {/* <div className="action-box">
                            <div className="pb5">
                                <span className="link">
                                <FormattedMessage id='reports.customerByPayment.label' defaultMessage='' />
                                </span>
                            </div>
                            <div><FormattedMessage id='reports.customerByPayment.message' defaultMessage='' /></div>
                        </div> */}

                        <div className="report-body" onClick={() => openAgedDebtorsTemplate(props)}>
                            <div className="pb5">
                                <span className="report-title" onClick={() => openAgedDebtorsTemplate(props)}>
                                    <span className='title-icon'><FileZipTwoTone /></span><FormattedMessage id='reports.agedDebtors.label' defaultMessage='' />
                                </span>
                            </div>
                            <div className='report-sub-title'>
                                <FormattedMessage id='reports.agedDebtors.message' defaultMessage='' />
                            </div>
                        </div>

                        <div className="report-body" onClick={() => openCustomerPartyTemplate(props)}>
                            <div className="pb5">
                                <span className="report-title" onClick={() => openCustomerPartyTemplate(props)}>
                                    <span className='title-icon'><ContainerTwoTone /></span><FormattedMessage id='reports.partyLedger.label' defaultMessage='' />
                                </span>
                            </div>
                            <div className='report-sub-title'> <FormattedMessage id='reports.partyLedger.message' defaultMessage='' /></div>
                        </div>

                    </div>
                </div>
                <div className='customer-box'>
                    <div className="title"><FormattedMessage id='reports.title.supplier' defaultMessage='' /></div>

                    <div className="report-box">
                        {/* <div className="action-box">
                            <div className="pb5">
                                <span className="link">
                                <FormattedMessage id='reports.supplierByPayment.label' defaultMessage='' />
                                </span>
                            </div>
                            <div><FormattedMessage id='reports.supplierByPayment.message' defaultMessage='' /></div>
                        </div> */}

                        <div className="report-body" onClick={() => openAgedCreditorsTemplate(props)}>
                            <div className="pb5">
                                <span className="report-title" onClick={() => openAgedCreditorsTemplate(props)}>
                                    <span className='title-icon'><FileZipTwoTone /></span><FormattedMessage id='reports.agedCreditors.label' defaultMessage='' />
                                </span>
                            </div>
                            <div className='report-sub-title'><FormattedMessage id='reports.agedCreditors.message' defaultMessage='' /></div>
                        </div>

                        <div className="report-body" onClick={() => openSupplierPartyTemplate(props)}>
                            <div className="pb5">
                                <span className="report-title" onClick={() => openSupplierPartyTemplate(props)}>
                                    <span className='title-icon'><ContainerTwoTone /></span><FormattedMessage id='reports.partyLedger.label' defaultMessage='' />
                                </span>
                            </div>
                            <div className='report-sub-title'> <FormattedMessage id='reports.partyLedger.message' defaultMessage='' /></div>
                        </div>

                    </div>
                </div>

                
            </div>


            {/* <div className="white-box">
                <h2 className="title"><FormattedMessage id='reports.title.salesReports.label' defaultMessage='' /></h2>

                <div className="report-actions">
                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                            <FormattedMessage id='reports.salesByCustomer.label' defaultMessage='' />
                            </span>
                        </div>
                        <div><FormattedMessage id='reports.salesByCustomer.message' defaultMessage='' /></div>
                    </div>

                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                            <FormattedMessage id='reports.salesByProduct.label' defaultMessage='' />
                            </span>
                        </div>
                        <div><FormattedMessage id='reports.salesByProduct.message' defaultMessage='' /></div>
                    </div>
                </div>

                <div className="report-actions pt20">
                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                            <FormattedMessage id='reports.salesOrderByFulfillment.label' defaultMessage='' />
                            </span>
                        </div>
                        <div><FormattedMessage id='reports.salesOrderByFulfillment.message' defaultMessage='' /></div>
                    </div>
                </div>
            </div>

            <div className="white-box">
                <h2 className="title"><FormattedMessage id='reports.title.purchaseReports.label' defaultMessage='' /></h2>

                <div className="report-actions">
                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                            <FormattedMessage id='reports.purchaseBySupplier.label' defaultMessage='' />
                            </span>
                        </div>
                        <div><FormattedMessage id='reports.purchaseBySupplier.message' defaultMessage='' /></div>
                    </div>

                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                            <FormattedMessage id='reports.purchaseByFulfillment.label' defaultMessage='' />
                            </span>
                        </div>
                        <div><FormattedMessage id='reports.purchaseByFulfillment.message' defaultMessage='' /></div>
                    </div>
                </div>
            </div>

            <div className="white-box">
                <h2 className="title"><FormattedMessage id='reports.title.inventoryReports.label' defaultMessage='' /></h2>

                <div className="report-actions">
                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                            <FormattedMessage id='reports.agedInventory.label' defaultMessage='' />
                            </span>
                        </div>
                        <div><FormattedMessage id='reports.agedInventory.message' defaultMessage='' /></div>
                    </div>

                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                                <FormattedMessage id='reports.inventoryValuation.label' defaultMessage='' />
                            </span>
                        </div>
                        <div><FormattedMessage id='reports.inventoryValuation.message' defaultMessage='' /></div>
                    </div>
                </div>

                <div className="report-actions pt20">
                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                                <FormattedMessage id='reports.lowStockReport.label' defaultMessage='' />
                            </span>
                        </div>
                        <div>
                        <FormattedMessage id='reports.lowStockReport.message' defaultMessage='' />
                        </div>
                    </div>

                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                            <FormattedMessage id='reports.fastestSellingProduct.label' defaultMessage='' />
                            </span>
                        </div>
                        <div>
                        <FormattedMessage id='reports.fastestSellingProduct.message' defaultMessage='' />
                            
                        </div>
                    </div>
                </div>

                <div className="report-actions pt20">
                    <div className="action-type">
                        <div className="pb5">
                            <span className="link">
                            <FormattedMessage id='reports.outOfStockReport.label' defaultMessage='' />
                            </span>
                        </div>
                        <div>
                        <FormattedMessage id='reports.outOfStockReport.message' defaultMessage='' />
                        </div>
                    </div>
                </div>
            </div>
        */}
        </> 
    )
};

export default Reports;