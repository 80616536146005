import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
import {
  sortTable,
} from "../../../utils";
// import AssignClassToTeacher from "../../../containers/modal/modalBody/schoolManagement/AssignClassToTeacher";
import {  Menu, Pagination, Switch } from "antd";
import {
  MoreOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";

const dummyData = {
  0: [
    {
      id: 1,
      s_no: "1",
      sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },
    {
      id: 2,
      s_no: "2",
      sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },

    {
      id: 3,
      s_no: "3",
      sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },

    {
      id: 4,
      s_no: "4",
      sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },

    {
      id: 5,
      s_no: "5",
      sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },

    {
      id: 6,
      s_no: "6",
      sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },

    {
      id: 7,
      s_no: "7",
        sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },

    {
      id: 8,
      s_no: "8",
      sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },
  ],

  1: [
    {
      id: 1,
      s_no: "9",
      sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },
    {
      id: 2,
      s_no: "10",
      sub_source_name: "Facebook",
      sub_source_code: "#234",
      date_created: "03 May 2023",
      status: "status",
      action: "-",
    },
  ],
};

const LeadSubSourceList = (props) => {
  const {
    updateState = "false",
    companyInfo,
    userInfo,
    studentCount,
    pageNumber = 0,
    dir=0,
  } = props;

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },

    {
      name: <FormattedMessage id="sidebar.menuItems.LeadForm" defaultMessage="" />,
    },

    {
      name: <FormattedMessage id="sidebar.menuItems.FormMeta" defaultMessage="" />,
    },

    {
      name: <FormattedMessage id="sidebar.menuItems.leadSubSource" defaultMessage="" />,
    },

  ];


  const sortColumn = (e) => {
    //sortTable(document.getElementById('invoiceTable'), e.target.parentElement.cellIndex, 1 - dir);
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("student-table"), cellIndex, 1 - dir);
    // updateState({ dir: 1 - dir })
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: pageNumber,
    //   pageSize: pageSize,
    // };
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 200,
      formId: (props.selectedForm || {}).registrationFormSettingId || 0,
    };
    props.getRegisteredMemberByFormId(payload);
    props.getRegisteredMemberCount(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: pageNumber,
    //   pageSize: pageSize,
    // };
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 200,
      formId: (props.selectedForm || {}).registrationFormSettingId || 0,
    };

    props.resetPaginatedData();
    //props.getTeacher(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getRegisteredMemberByFormId(payload);
    props.getRegisteredMemberCount(payload);
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item key="2" value="update" onClick={() => {
                        props.updateState({
                            leadSubSourceCreateEditDrawer: true,
                            leadSubSourceEditDrawerValue : "edit",
                            leadSubSourceEditData : item,
                        })
                    }}>
          <EditOutlined /> Edit
        </Menu.Item>
      </Menu>
    );
  };

  // const itemSelection = (item) => {
  //   let selectItem = find(selectedItems, {
  //     schoolStudentId: Number(item.schoolStudentId),
  //   });
  //   let updatedItems = [];
  //   if (selectItem) {
  //     updatedItems = selectedItems.filter(
  //       (obj) => obj.schoolStudentId != item.schoolStudentId
  //     );
  //   } else {
  //     updatedItems = JSON.parse(JSON.stringify(selectedItems));
  //     updatedItems.push(item);
  //   }
  //   props.updateState({ selectedItems: updatedItems });
  // };

  // const selectAll = (context) => {
  //   if (!context) {
  //     selectedItems.splice(0, selectedItems.length);
  //     props.updateState({ selectedItems: selectedItems });
  //     return;
  //   }
  //   for (let i = 0; i < studentList[pageNumber].length; i++) {
  //     let item = studentList[pageNumber][i];
  //     let selectItem = find(selectedItems, {
  //       schoolStudentId: Number(item.schoolStudentId),
  //     });
  //     if (!selectItem) {
  //       selectedItems.push(item);
  //     }
  //   }
  //   props.updateState({ selectedItems: selectedItems });
  // };

  // const isCheckedAll = () => {
  //   if (
  //     !props.studentList ||
  //     !props.studentList[pageNumber] ||
  //     props.studentList[pageNumber].length === 0
  //   ) {
  //     return false;
  //   }
  //   for (let i = 0; i < props.studentList[pageNumber].length; i++) {
  //     let usr = props.studentList[pageNumber][i];
  //     let selectItem = find(selectedItems, {
  //       schoolStudentId: Number(usr.schoolStudentId),
  //     });
  //     if (!selectItem) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  // const checkMenu = (e) => {
  //   return (
  //     <Menu className="row-action-dropdown">
  //       <Menu.Item key="0" value="title">
  //         Actions
  //       </Menu.Item>

  //       <Menu.Divider />

  //       <Menu.Item
  //         key="1"
  //         onClick={() => {
  //           selectAll(true);
  //         }}
  //       >
  //         <CheckCircleOutlined />
  //         Select all
  //       </Menu.Item>

  //       <Menu.Item
  //         key="2"
  //         onClick={() => {
  //           selectAll(false);
  //         }}
  //       >
  //         <CloseCircleOutlined />
  //         Unselect all
  //       </Menu.Item>

  //       <Menu.Item
  //         key="3"
  //         onClick={() => {
  //           const studentList = [];
  //           selectedItems.forEach((obj) => {
  //             studentList.push({
  //               schoolStudentId: obj.schoolStudentId,
  //               relationshipId: obj.relationshipId,
  //               subcourseId: obj.subcourseId,
  //               courseId: obj.courseId,
  //               orgRegistrationId: obj.orgRegistrationId,
  //               customerId: obj.customerId,
  //               contactId: obj.contactId || 0,
  //               firstName: obj.firstName,
  //               lastName: obj.lastName,
  //             });
  //           });
  //           if (studentList.length) {
  //             props.updateState({
  //               emailDetailDrawerVisible: true,
  //               isSendBulkInvoice: true,
  //               isBulkInvoice: true,
  //               selectedStudentList: studentList,
  //               docName: "students",
  //             });
  //             // props.pushModalToStack(modalData);
  //           } else {
  //             showToasterMessage({
  //               messageType: "error",
  //               description: props.intl.formatMessage({
  //                 id: "common.record.notSelected.proceeding",
  //               }),
  //             });
  //           }
  //         }}
  //       >
  //         <MailOutlined />
  //         Send Email
  //       </Menu.Item>
  //     </Menu>
  //   );
  // };

  // const handleChange = (value) => {
  // };


  // const handleClick = (e) => {
  //   props.updateState({
  //     activeRowData: e,
  //   });
  //   props.updateHeaderState({
  //     activeRowData: e,
  //   });
  // };

  return (
    <Fragment>
      <div className="lead-list">
        <div className="lead-list-page">
          <div className="view-top-bar">
            <div className="top-view-left">
              <div className="page-heading">
                <FormattedMessage
                  id="sidebar.menuItems.LeadForm"
                  defaultMessage=""
                />
              </div>
              <div className="vertical-sep" />
              <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="top-view-right">
              <button className="create-action-btn" onClick={() => {
                        props.updateState({
                            leadSubSourceCreateEditDrawer: true
                        })
                    }}>
                <PlusOutlined />
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
          </div>
          <div className="view-container">
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="leads.leadForm.formMeta.leadSubSource.table.heading"
                    defaultMessage=""
                  />
                </div>
              </div>

              <div className="right-actions"></div>
            </div>
            <div className="table-container">
              <table id="student-table">
                <thead>
                  <tr>
                    {
                      <th onClick={sortColumn}>
                        S.No. <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    }
                    {
                      <th onClick={sortColumn}>
                       Sub Source Name <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    }
                    <th onClick={sortColumn}>
                     Sub Source Code <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      Date Created<i class="fi fi-rr-sort-alt"></i>
                    </th>

                    <th onClick={sortColumn}>
                      Status <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      Action<i class="fi fi-rr-sort-alt"></i>
                    </th>

                    {/* <th onClick={sortColumn}>Father Info <i class="fi fi-rr-sort-alt"></i></th> */}
                    {/* <th onClick={sortColumn}>Mother Info <i class="fi fi-rr-sort-alt"></i></th> */}
                    {/* { <th onClick={sortColumn}>{getUserDefinedName("school.student.profile.list",props)} <i class="fi fi-rr-sort-alt"></i></th>} */}
                    {/* <th onClick={sortColumn}>Allergies <i class="fi fi-rr-sort-alt"></i></th> */}
                    {/* { <th onClick={sortColumn}>Broadcast Group <i class="fi fi-rr-sort-alt"></i></th>} */}
                    {/* {<th onClick={sortColumn}>Fees <i class="fi fi-rr-sort-alt"></i></th>} */}
                    {/* { <th onClick={sortColumn}>Payment <i class="fi fi-rr-sort-alt"></i></th>} */}
                    {/* { <th onClick={sortColumn}>Created By <i class="fi fi-rr-sort-alt"></i></th>} */}
                    {/* {<th onClick={sortColumn}>Date Created <i class="fi fi-rr-sort-alt"></i></th>} */}
                    {/* <th onClick={sortColumn} style={{width: '145px'}}>Student DOB</th> */}
                    {/* <th onClick={sortColumn}>Grade</th> */}
                    {/* {<th onClick={sortColumn}>Member</th>} */}
                    {/* <th onClick={sortColumn}>Emergency Contact</th> */}
                    {/* {<th onClick={sortColumn}>Date Created</th>} */}

                    {/* {<th onClick={sortColumn}>Invoiced?</th>}
                  {<th>Q&A</th>}
                  {<th>Finanial Assistance</th>}
                  {<th onClick={sortColumn}>Teacher?</th>}
                  {<th onClick={sortColumn}>Volunteer?</th>} */}
                    {/* {<th onClick={sortColumn}>Status <i class="fi fi-rr-sort-alt"></i></th>} */}
                    {/* { <th width='5%'><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>} */}
                  </tr>
                </thead>
                <tbody>
                  {(dummyData[pageNumber] || []).map((e, i) => {
                    //  return (registationObj.studentList || []).map((e, j) => {
                    return (
                      <tr key={"std" + e.id}>
                        <td>{e.s_no || "-"}</td>
                        <td>{e.sub_source_name || "-"}</td>
                        <td>{e.sub_source_code || "-"}</td>
                        <td>{e.date_created || "-"}</td>
                        <td>
                          <div className="status-toggle">
                            <Switch defaultChecked />
                          </div>
                        </td>
                        <td>
                          <div className="action-icon">
                            <DropdownAction
                              overlay={actionMenu(e)}
                              trigger={["click"]}
                            >
                              <MoreOutlined />
                            </DropdownAction>
                          </div>
                        </td>
                      </tr>
                    );
                    // })
                  })}
                </tbody>
              </table>
            </div>
            <div className="footer">
              <div className="f-left"></div>
              <div className="f-right">
                <Pagination
                  size="small"
                  total={studentCount}
                  showTotal={showTotal}
                  defaultPageSize={props.pageSize}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100, 200]}
                  // showQuickJumper
                  onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                  }}
                  onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(LeadSubSourceList);
