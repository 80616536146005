import React from "react";
import { injectIntl } from "react-intl";
import "./index.scss";
import {
  Row,
  Col,
  Divider,
} from "antd";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import { LIST_CONSTANTS } from "../../../static/constants";
import { ErrorMsg } from "../../general/ErrorMessage";


const PrimaryDetails = (props) => {
  const {
    intl,formSetting
  } = props;

  const gridColStyle = {
    xs: {
      span: 24,
    },
    lg: {
      span: 12,
    },
  };

  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={24} style={{ display: "flex" }}>
          <span className="bold-title mr5">Father Info</span>
        </Col>
        <Col {...gridColStyle}>
          <div className="i-label">
            Full Name
            {formSetting.fatherInfoRequired === 1 || props.primaryContact === 'father' ?<span className="required">*</span> :""}
          </div>
          <TextBox
            disabled={props.isfatherNameDisabled}
            placeholder={intl.formatMessage({
              id: "contact.fullName.placeholder",
              defaultMessage: "",
            })}
            type="text"
            value={props.fatherName}
            onChange={(e) => {
              props.updateState({ fatherName: e.target.value });
            }}
          />
          {formSetting.fatherInfoRequired === 1 || props.primaryContact === 'father' ?
          <ErrorMsg
            validator={() => {
              return (
                !props.submitClicked || props.fatherName
                // props.fatherName?.split(" ").length >= 2
                 
              );
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          /> 
          : "" } 
        </Col>

        <Col {...gridColStyle}>         
          <div className="i-label">
            Email{formSetting.fatherInfoRequired === 1 || props.primaryContact === 'father' ?<span className="required">*</span> :""}
          </div>
          <TextBox
            disabled={props.primaryContact === 'father' || props.isFatherEmailDisabled}
            placeholder="Enter email"
            type="email"
            maxLength={45}
            value={props.primaryContact === 'father' ? props.primaryEmail : props.fatherEmail}
            onChange={(e) => {
              props.updateState({ fatherEmail: e.target.value });
            }}
          />
          {formSetting.fatherInfoRequired === 1 || props.primaryContact === 'father' ?
          <ErrorMsg
            validator={() => {
              return !props.submitClicked || props.primaryContact === 'father' ? props.primaryEmail : props.fatherEmail;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
          : "" }
        </Col>

        <Col {...gridColStyle}>
          <div className="i-label">
            Mobile Number{formSetting.fatherInfoRequired === 1 || props.primaryContact === 'father' ? <span className="required">*</span> :""}
          </div>
          <TextBox
            disabled={props.isFatherMobileNumberDisabled}
            prefix={ props.companyInfo.countryCallingCode ? `+ ${props.companyInfo.countryCallingCode}` : '-' }
            placeholder="Enter Number"
            type="text"
            maxLength={10}
            countStyle={{top: '35px'}}
            value={props.fatherMobileNumber}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                props.updateState({
                  fatherMobileNumber: e.target.value,
                });
              } else if (!props.fatherMobileNumber) {
                props.updateState({ fatherMobileNumber: "" });
              }
            }}
          />
          {formSetting.fatherInfoRequired === 1 || props.primaryContact === 'father' ?
          <ErrorMsg
            validator={() => {
              return !props.submitClicked || props.fatherMobileNumber;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
          : "" }
        </Col>
        <Col span={24} style={{ display: "flex" }}>
          <span className="bold-title mr5">Mother Info</span>
        </Col>
        <Col {...gridColStyle}>
          <div className="i-label">
            Full Name{formSetting.motherInfoRequired === 1 ||  props.primaryContact === 'mother' ?<span className="required">*</span> :""}
          </div>
          <TextBox
            disabled={props.isMotherNameDisabled}
            placeholder={intl.formatMessage({
              id: "contact.fullName.placeholder",
              defaultMessage: "",
            })}
            type="text"
            value={props.motherName}
            onChange={(e) => {
              props.updateState({ motherName: e.target.value });
            }}
          />
          {formSetting.motherInfoRequired === 1 ||  props.primaryContact === 'mother'? 
          <ErrorMsg
            validator={() => {
              return (
                !props.submitClicked ||
                // props.motherName?.split(" ").length >= 2
                props.motherName
              );
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
          : "" }
        </Col>

        <Col {...gridColStyle}>
          <div className="i-label">
            Email{formSetting.motherInfoRequired === 1 ||  props.primaryContact === 'mother' ?<span className="required">*</span> :""}
          </div>
          <TextBox
            placeholder="Enter email"
            disabled={props.primaryContact === 'mother' || props.isMotherEmailDisabled}
            type="email"
            maxLength={45}
            value={props.primaryContact === 'mother' ? props.primaryEmail : props.motherEmail}

            onChange={(e) => {
              props.updateState({ motherEmail: e.target.value });
            }}
          />
          {formSetting.motherInfoRequired === 1 || props.primaryContact === 'mother' ?
          <ErrorMsg
            validator={() => {
              return !props.submitClicked || props.motherEmail;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          /> 
          : "" }
        </Col>

        <Col {...gridColStyle}>
          <div className="i-label">
            Mobile Number{formSetting.motherInfoRequired === 1 ||  props.primaryContact === 'mother'?<span className="required">*</span> :""}
          </div>
          <TextBox
            disabled={props.isMotherMobileNumberDisabled}
            prefix={ props.companyInfo.countryCallingCode ? `+ ${props.companyInfo.countryCallingCode}` : '-' }
            placeholder="Enter Number"
            type="text"
            maxLength={10}
            countStyle={{top: '35px'}}
            value={props.motherMobileNumber}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                props.updateState({
                  motherMobileNumber: e.target.value,
                });
              } else if (!props.motherMobileNumber) {
                props.updateState({ motherMobileNumber: "" });
              }
            }}
          />
          {formSetting.motherInfoRequired === 1 || props.primaryContact === 'mother'? 
          <ErrorMsg
            validator={() => {
              return !props.submitClicked || props.motherMobileNumber;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
          : "" }
        </Col>

        <Divider />
      </Row>
    </>
  );
};

export default injectIntl(PrimaryDetails);
