import React, {useState} from "react";
// import '../index.scss';
import { Empty, Timeline } from "antd";
import { getLocalDateFromUtcStr } from "../../../../../utils";

const Activities = (props) => {
  const {leadActivityTrackList, leadAssignList=[]} = props;
  const [actLimit, setActLimit] = useState(1);

  const getEmpName=(empId)=>{
    const emp = leadAssignList.find(obj=>obj.relationshipEmployeeId === empId) || {}
    return `${emp.salutationName || ''} ${emp.firstName || ''} ${emp.lastName || ''}`
  }

  return (
    <>
      {(leadActivityTrackList || []).length ?
        <Timeline 
          className="act-timeline"
          pending={
            (leadActivityTrackList || []).length > actLimit ? (
              <div
                className="cursor-pointer pt5"
                onClick={() => setActLimit(actLimit + 10)}
              >
                Show 1-{(leadActivityTrackList || []).length > (actLimit + 10) ? actLimit + 10 : (leadActivityTrackList || []).length} of{" "}
                {(leadActivityTrackList || []).length}
              </div>
            ) : (
              ""
            )
          }
          pendingDot={<i class="fi fi-rr-circle-ellipsis" />}
        >
          {leadActivityTrackList.slice(0, actLimit).map((item, i)=>{
            return  <Timeline.Item key={'leadAct'+ i}>
              <div className="light-txt">{item.dateCreated ? getLocalDateFromUtcStr({date: item.dateCreated, format: 'hh:mm A, DD MMMM YYYY'}):'-'}</div>
              <div className="b-text"><i className="fi fi-rr-id-card-clip-alt" /> - {getEmpName(item.createdByEmpId)}</div>
              <div className="light-txt">{item.changeDescription || '-'}</div>
            </Timeline.Item>
          })}
        </Timeline>
        :
        <Empty />
      }
    </>
  );
};
export default Activities;
