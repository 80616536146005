import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button } from "antd";
import userIcon from "./../../assets/images/avatar.png";
import userProfile from "./../../assets/images/user.png";
import signatureIcon from "./../../assets/images/signature.png";
import imageIcon from "./../../assets/images/imageIcon.png";
import { CONSTANTS } from "../../static/constants";

const onCustomRequest = (file) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      file.onSuccess(null);
    }, 100);
  });
};

export const CustomUploader = (props) => {
  const [disabled, setDisabled] = useState(props.disabled || false);
  const onChange = ({ file, fileList }) => {

    if (file.status !== "uploading") {
      let fileSize =
        file.status === "removed" ? 0 : (file.originFileObj || {}).size || 0;
      fileList.forEach((file) => {
        fileSize = fileSize + file.size / 1024 / 1024;
      });
      if (fileSize > 4) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
      if (props.onChange) {
        props.onChange(fileList);

      } else if (props.onDocChange) {
        props.onDocChange(file);
      }
    }
  };

  if (props.type === CONSTANTS.TYPE_IMAGE) {
    return (
      <Upload
        {...props}
        onChange={onChange}
        customRequest={onCustomRequest}
        multiple={props.multiple === false ? false : true}
        maxCount={props.maxCount || 10}
        accept={props.accept}
      >
        {props.showNameIcon ? 
          <div className="upload-profile-name">
            <div className="user_name">
              {props.iconName || 'AT'}
            </div>
            <span className="upload_btn">
              <i className="fa fa-camera" />
            </span>
          </div>
          :
          <div className="upload-profile-pic">
            <img
              src={props.imgUrl}
              alt="user"
              onError={(e) => {
                e.target.src = props.isSignature
                  ? signatureIcon
                  : props.isProfile
                  ? userProfile
                  : props.isImage
                  ? imageIcon
                  : userIcon;
              }}
              className="mb15 user-img"
            />
            <span className="camera-icon">
              <i className="fa fa-camera" />
            </span>
            {props.hideMaxCount ? 
              ''
              :
              <span>{props.maxCount ? `max(${props.maxCount})` : ""}</span>
            }
          </div>
        }
      </Upload>
    );
  } else {
    return (
      <Upload
        openFileDialogOnClick={!disabled}
        defaultFileList={props.defaultFileList || []}
        onChange={onChange}
        onDownload={props.onDownload}
        showUploadList={{
          showDownloadIcon: !props.hideDownloadIcon,
        }}
        customRequest={onCustomRequest}
        {...props}
        listType={"text"}
        multiple={props.multiple === false ? false : true}
        maxCount={props.maxCount || 10}
        accept={props.accept}
      >
        <Button
          ref={props.reference}
          className={props.hideButton ? "hide" : ""}
        >
          <UploadOutlined /> {props.buttonName || "Upload File"}
        </Button>
        <div className="upload-notes">Max files size 50mb</div>
      </Upload>
    );
  }
};
