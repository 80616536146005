import React, { Fragment, Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Button, DatePicker, Tooltip } from "antd";
import {
  getCurrentDateForBackend,
  getMomentDateForUI,
} from "./../../../../utils";
import { DropdownRef } from "./../../../general/Dropdown";
import * as find from "lodash.find";
import { ICONS } from "../../../../static/constants";
import { CustomButton } from "../../../general/CustomButton";
// import { TextArea } from "../../../general/TextAreaComponent";

const { TextArea } = Input;

class LogCommunicationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,

    };
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formData } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      <Fragment>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          hideRequiredMark={true}
        >
          <div className="modal-content">
            <Form.Item
              label={
                <Tooltip
                  placement="right"
                  title={this.props.intl.formatMessage({
                    id: "tooltip.communicationType",
                    defaultMessage: "",
                  })}
                >

                  <FormattedMessage
                    id="communicationType.text"
                    defaultMessage=""
                  />
                  <span className="required">*</span>

                  <i className={`${ICONS.HELP} cursor-pointer ml5`} />

                </Tooltip>
              }
              colon={false}
            >
              {getFieldDecorator("mode", {
                initialValue: formData.communicationMode || "",
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="communicationMode.required.message"
                        defaultMessage=""
                      />
                    ),
                  },
                ],
              })(<Input 
                placeholder={this.props.intl.formatMessage({
                  id: "modal.common.enter.placeholder",
                  defaultMessage: "",
                })}
              
              />)}
              {/* <div className="short-info">
                Communication mode could be Email, Courier, Fax etc
              </div> */}
            </Form.Item>


            <Form.Item
              label={
                <Tooltip
                  placement="right"
                  title={this.props.intl.formatMessage({
                    id: "tooltip.typeOfCommunication",
                    defaultMessage: "",
                  })}
                >
                  <FormattedMessage
                    id="communicationTo.text"
                    defaultMessage=""
                  />
                  <i className={`${ICONS.HELP} cursor-pointer ml5`} />

                </Tooltip>
              }
              colon={false}
            >
              {getFieldDecorator("communicatedTo", {
                initialValue: formData.communiatedTo || "",
              })(<Input 
                placeholder={this.props.intl.formatMessage({
                  id: "modal.common.enter.placeholder",
                  defaultMessage: "",
                })}
              
              />)}
            </Form.Item>
            <Form.Item
              label={this.props.intl.formatMessage({
                id: "communicatedBy.text",
                defaultMessage: "",
              })}
              colon={false}
            >
              {getFieldDecorator("communicatedByUserId", {
                initialValue: formData.relationshipEmployeeId || "",
              })(
                <DropdownRef
                  items={this.props.allEmployee || []}
                  optionKeyName="relationshipEmployeeId"
                  placeholder={this.props.intl.formatMessage({
                    id: "common.select.placeholder",
                    defaultMessage: "",
                  })}
                  valueKeyName="fullName"
                  onSelect={(selectedValue, option) => {
                    this.props.form.setFieldsValue({
                      communicatedByUserId: selectedValue,
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.form.setFieldsValue({
                      communicatedByUserId: undefined,
                    });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item
              label={this.props.intl.formatMessage({
                id: "communicationDate.text",
                defaultMessage: "",
              })}
              colon={false}
            >
              {getFieldDecorator("communicationDate", {
                initialValue: formData.communicationDate
                  ? getMomentDateForUI({ date: new Date(formData.communicationDate) })
                  : getMomentDateForUI({date:new Date()}),
              })(<DatePicker />)}
            </Form.Item>
          </div>
          <Form.Item
            label={
              <Tooltip
                placement="right"
                title={this.props.intl.formatMessage({
                  id: "tooltip.briefDescription",
                  defaultMessage: "",
                })}
              >

                <FormattedMessage
                  id="common.description"
                  defaultMessage=""
                />
                <i className={`${ICONS.HELP} cursor-pointer ml5`} />

              </Tooltip>
            }
            colon={false}
          >
            {getFieldDecorator("description", {
              initialValue: formData.description || "",
            })(
              <TextArea 
              placeholder="Enter"
              style={{width:"300px",resize:"none",height:"100px"}}         
              maxLength={250} 
              onChange={(e) => {
                this.setState({ description: e.target.value });
              }}
              onFocus={() => {
                this.setState({ isFocus: true });
              }}
              onBlur={() => {
                this.setState({ isFocus: false });
              }}                    
              />           
            )}
            {/* <div className="short-info">
                Brief description about communication
              </div> */}
          </Form.Item>
          {this.state.isFocus ? (
              <div style={{ marginTop:"-35px",zIndex:"auto",position:"absolute" ,marginLeft:"70%"}}>
                {(this.state.description || "").length
                  ? this.state.description.length +
                    " / " +
                    250 +
                    " characters"
                  : ""}
              </div>
            ) : null}
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <Button style={{ backgroundColor: "#fff" }}
              type="default"
              onClick={() => {
                this.props.popModalFromStack();
              }}
            >
              <FormattedMessage id="button.cancel.label" defaultMessage="" />
            </Button>
            <Button type="primary" htmlType="submit">
              <FormattedMessage id="button.save.label" defaultMessage="" />
            </Button>

          </Form.Item>
        </Form>
      </Fragment>
    );
  }


  handleSubmit = (e) => {
    e.preventDefault();
    const { formData } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let employee = {};
        if (values.communicatedByUserId) {
          employee =
            find(this.props.allEmployee || [], {
              relationshipEmployeeId: values.communicatedByUserId,
            }) || {};
        }
        const payload = {
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          communicationMode: values.mode,
          description: values.description,
          relationshipEmployeeId: values.communicatedByUserId,
          empId: values.communicatedByUserId,
          relationshipEmployeeName: employee.fullName,
          origination: "Manual",
          refDocId: "",
          communiatedTo: values.communicatedTo,
          communicationDate: getCurrentDateForBackend(values.communicationDate),
          dateCreated: getCurrentDateForBackend(new Date()),
          userCreated: this.props.userInfo.firstName,
          customerName: this.props.customerName,
          customerId: this.props.customerId,
          supplierName: "",
          customerCommunicationTxId:
            (formData || {}).customerCommunicationTxId || 0,
          version: (formData || {}).version || 0,
        };

        const modalData = {
          modalBody: Object.keys(formData).length ? (
            <FormattedMessage
              id="condfirmation.update.communication"
              defaultMessage=""
            />
          ) : (
            <FormattedMessage
              id="Are.you.sure.communication"
              defaultMessage=""
            />
          ),
          handleSubmit: () => {
            this.props.createUpdateCommunication(payload);
            this.props.popModalFromStack();
          },
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: "Log_activity" })(
  injectIntl(LogCommunicationComponent)
);
