import React, { Fragment } from "react";
import { Row, Col, Button } from "antd";
import { FormattedMessage } from "react-intl";
import {
  capitalizeFirstLetter,
  fixedDecimalAndFormateNumber,
  getMomentDateForUIReadOnly,
} from "../../../../../../utils";
import { CONSTANTS } from "../../../../../../static/constants";
import { Color } from "ag-grid-community";
import OpenOrders from "../../../../../../containers/modal/modalBody/customer/OpenOrders";
import PaidInvoices from "../../../../../../containers/modal/modalBody/customer/PaidInvoices";
import UnpaidInvoices from "../../../../../../containers/modal/modalBody/customer/UnpaidInvoices";
import { FilePdfOutlined, PrinterOutlined, } from '@ant-design/icons';
import find from "lodash.find";


const Information = (props) => {
  const { paidInvoiceList,unpaidInvoiceList ,openOrderList,primaryContact} = props
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;
  const billingAddress =
    find(props.boLocationList, {
      locationType: CONSTANTS.BILLING_ADDRESS,
    }) || {};
  const relationshipAddress =
    find(props.companyInfo.boLocationList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};

  // const primaryContact =
  //   find(props.boContactList, {
  //     contactType: CONSTANTS.CONTACT_TYPE_CUSTOMER
  //   }) || {};
  const getAddressInfoFragment = (obj) => {
    return (
      <>
        {obj?.streetAddress1 ? (
          <span className="billing-address">{obj?.streetAddress1}</span>
        ) : (
          ""
        )}
        {obj?.streetAddress2 ? (
          <span className="billing-address"> {obj?.streetAddress2}</span>
        ) : (
          ""
        )}
        {obj?.cityName ? (
          <div className="billing-address">
            {`${obj?.cityName || ""}${obj?.stateName ? ", " + obj?.stateName : ""
              }${obj?.zipCode ? ", " + obj?.zipCode : ""}`}
          </div>
        ) : (
          ""
        )}
        {obj?.countryName ? (
          <div
            className="billing-address"
            style={{ textTransform: "uppercase" }}
          >
            {`${capitalizeFirstLetter(obj?.countryName) || ""}`}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const getContactInfoFragment = (obj) => {
    return (
      <>
        {/* <div >{obj?.firstName || "" + " " + obj?.lastName || ""}</div>
       <div >{obj?.emailAddress || ""}</div>
       <div >{obj?.cellPhone || ""}</div> */}
        {obj?.firstName ? (
          <div >{obj.firstName + " " + obj.lastName}</div>
        ) : (
          ""
        )}
        {obj?.emailAddress ? (
          <div > {obj.emailAddress}</div>
        ) : (
          ""
        )}
        {obj?.cellPhone ? (
          <div > {obj.cellPhone}</div>
        ) : (
          ""
        )}

      </>
    );
  };
  const getPayloadForPaidPdf = () => {
    return {
      fileName: (props.companyName) +" " + 'Paid-Invoice.pdf',
      transactionName: 'paidInvoice',
      companyInfo: props.companyInfo,
      billingAddress: billingAddress,
      primaryContact: primaryContact,
      paidInvoiceList: JSON.stringify(paidInvoiceList),
      relationshipAddress: relationshipAddress,
      paidInvoicesTotalAmount: props.paidInvoicesTotalAmount,
      companyName:props.companyName

     
    }
  }

  const generatePaidPDF = () => {
    let payload = getPayloadForPaidPdf();
    props.generatePDF(payload);
  }

  const getPayloadForUnPaidPdf = () => {
    return {
      fileName: (props.companyName) +" " + 'Unpaid-Invoice.pdf',
      transactionName: 'unpaidInvoice',
      companyInfo: props.companyInfo,
      billingAddress: billingAddress,
      primaryContact: primaryContact,
      unpaidInvoiceList: JSON.stringify(unpaidInvoiceList),
      relationshipAddress: relationshipAddress,
      unpaidInvoicesTotalAmount: props.unpaidInvoicesTotalAmount,
      companyName:props.companyName

     
    }
  }

  const generateUnpaidPDF = () => {
    let payload = getPayloadForUnPaidPdf();
    props.generatePDF(payload);
  }
  const getPayloadForOpenOrderPdf = () => {
    return {
      fileName: (props.companyName)+" " + 'Open-Order.pdf',
      transactionName: 'openOrder',
      companyInfo: props.companyInfo,
      billingAddress: billingAddress,
      primaryContact: primaryContact,
      openOrderList: JSON.stringify(openOrderList),
      relationshipAddress: relationshipAddress,
      openedOrdersTotalAmount: props.openedOrdersTotalAmount,
      companyName:props.companyName
     
    }
  }

  const generateOpenOrderPDF = () => {
    let payload = getPayloadForOpenOrderPdf();
    props.generatePDF(payload);
  }

  const printPdf = (val) => {
    let payload;
     switch (val) {
       case "openOrder":
         payload = getPayloadForOpenOrderPdf();
         break;
       case "paid":
         payload = getPayloadForPaidPdf();
         break;
       case "unPaid":
         payload = getPayloadForUnPaidPdf();
         break;
     }
     props.printPDF(payload)
   }
  return (
    <div className="details">
      <Row gutter={[16, 24]}>
        <Col span={12}>
          <div className="b-text">Total Orders</div>
          <div className="light-txt">{props.customerTotalOrderCount || 0}</div>
        </Col>
        <Col span={12}>
          <div className="b-text">Open Sales Order</div>
          <div className="light-txt">
            {props.openedOrdersTotalAmount ? (
              props.companyInfo.currencyIcon ? (
                <i className={props.companyInfo.currencyIcon}></i>
              ) : (
                props.companyInfo.currencyCode + " "
              )
            ) : (
              ""
            )}
            {(props.openedOrdersTotalAmount || "") &&
              fixedDecimalAndFormateNumber(props.openedOrdersTotalAmount)}{" "}


            {props.openedOrdersCount &&
              props.openedOrdersCount === 1
              ? "Order "
              : props.openedOrdersCount === 0
                ? ""
                : " "}
            <div style={{ borderBottom: "none" }}>

              <span
                onClick={() => {
                  if (props.openedOrdersCount !== 0) {
                    props.pushModalToStack({
                      title: (
                        <Fragment>
                          <div>
                            <div className="ant-modal-title">
                              Open Orders
                            </div>

                            <div className="ant-modal-subtitle extra-sm">
                              {props.companyName}
                            </div>
                            <div className="modal-info">

                              <div className="w-200">{getAddressInfoFragment(props.primaryAddress)}</div>
                              <div className="w-200">{getContactInfoFragment(props.primaryContact)}</div>
                              <div className="modal-btn">
                                <Button
                                  title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                  icon={<FilePdfOutlined />}
                                  onClick={() => {
                                    generateOpenOrderPDF();
                                  }}
                                >
                                </Button>
                                <Button
                                  title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                  icon={<PrinterOutlined />}
                                  onClick={() => {
                                    printPdf("openOrder");
                                  }}
                                >
                                </Button>
                              </div>
                            </div>
                          </div>

                        </Fragment>
                      ),
                      modalBody: (
                        <OpenOrders
                          {...props}
                          customerData={props}
                        />
                      ),
                      width: 1000,
                      hideFooter: true,
                      wrapClassName: "tiles-modal",
                    });
                  }
                }}
                className={
                  props.openedOrdersCount
                    ? "link-text"
                    : <div className="empty-data"></div>

                }
              >
                ({props.openedOrdersCount})
              </span>
            </div>
            {/* ({props.openedOrdersCount})  */}
          </div>
        </Col>
        <Col span={12}>
          <div className="b-text">Paid Balance</div>
          <div className="light-txt">
            {props.paidInvoicesTotalAmount ? (
              props.companyInfo.currencyIcon ? (
                <i className={props.companyInfo.currencyIcon}></i>
              ) : (
                props.companyInfo.currencyCode + " "
              )
            ) : (
              ""
            )}
            {(props.totalPaymentAmount || "") &&
              fixedDecimalAndFormateNumber(props.totalPaymentAmount)}{" "}
            {/* ({props.paidInvoicesCount || 0}) */}
            {props.paidInvoicesCount &&
              props.paidInvoicesCount === 1
              ? "Invoice "
              : props.paidInvoicesCount === 0
                ? ""
                : ""}
            <span
              onClick={() => {
                if (props.paidInvoicesCount !== 0) {
                  props.pushModalToStack({
                    title: (
                      <Fragment>
                        <div>
                          <div className="ant-modal-title">
                            Paid Invoices
                          </div>
                          <div className="ant-modal-subtitle extra-sm">
                            {props.companyName}
                          </div>

                          <div className="modal-info">

                            <div className="w-200">{getAddressInfoFragment(props.primaryAddress)}</div>
                            <div className="w-200">{getContactInfoFragment(props.primaryContact)}</div>
                            <div className="modal-btn">
                              <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                  generatePaidPDF();
                                }}
                              >
                              </Button>
                              <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                icon={<PrinterOutlined />}
                                onClick={() => {
                                  printPdf("paid");
                                }}
                              >
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ),
                    modalBody: (
                      <PaidInvoices
                        customerData={props}
                        {...props}
                      />
                    ),
                    width: 1000,
                    hideFooter: true,
                    wrapClassName:
                      "tiles-modal paid-invoice",
                  });
                }
              }}
              className={
                props.paidInvoicesCount
                  ? "link-text"
                  : <div className="empty-data"></div>

              }
            >
              {" "}
              ({props.paidInvoicesCount})
            </span>
          </div>
        </Col>
        <Col span={12}>
          <div className="b-text">Unpaid Balance</div>
          <div className="light-txt">
            {props.unpaidInvoicesTotalAmount ? (
              props.companyInfo.currencyIcon ? (
                <i className={props.companyInfo.currencyIcon}></i>
              ) : (
                props.companyInfo.currencyCode + " "
              )
            ) : (
              ""
            )}
            {(props.totalAmountDue || "") &&
              fixedDecimalAndFormateNumber(
                props.totalAmountDue
              )}{" "}
            {/* ({props.unpaidInvoicesCount || 0}) */}
            {props.unpaidInvoicesCount &&
              props.unpaidInvoicesCount === 1
              ? "Invoice "
              : props.unpaidInvoicesCount === 0
                ? ""
                : ""}
            <span
              onClick={() => {
                if (props.unpaidInvoicesCount !== 0) {

                  props.pushModalToStack({
                    title: (
                      <Fragment>
                        <div>

                          <div className="ant-modal-title">
                            Unpaid Invoices
                          </div>
                          <div className="ant-modal-subtitle extra-sm">
                            {props.companyName}
                          </div>
                          <div className="modal-info">

                            <div className="w-200">{getAddressInfoFragment(props.primaryAddress)}</div>
                            <div className="w-200">{getContactInfoFragment(props.primaryContact)}</div>
                            <div className="modal-btn">
                              <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                  generateUnpaidPDF();
                                }}
                              >
                              </Button>
                              <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                icon={<PrinterOutlined />}
                                onClick={() => {
                                  printPdf("unPaid");
                                }}
                              >
                              </Button>
                            </div>
                          </div>

                        </div>
                      </Fragment>
                    ),
                    modalBody: (
                      <UnpaidInvoices
                        customerData={props}
                        {...props}
                      />
                    ),
                    width: 1000,
                    hideFooter: true,
                    wrapClassName: "tiles-modal",
                  });
                }
              }}
              className={
                props.unpaidInvoicesCount
                  ? "link-text"
                  : <div className="empty-data"></div>

              }
            >
              {" "}
              ({props.unpaidInvoicesCount})
            </span>
          </div>
        </Col>
      </Row>

      <hr style={{ marginLeft: "-20px" }} />

      <div className="info-details">
        <Row gutter={[16, 22]}>
          {props.customerType === 'business' ?
            <Col span={12}>
              <div className="b-text">Business Type</div>
              <div className="light-txt">
                {" "}
                {props.businessType ? (
                  <FormattedMessage id={props.businessType} />
                ) : (
                  <div className="empty-data-box"></div>
                )}
              </div>
            </Col> : <span style={{ display: "none" }}></span>}

          <Col span={12}>
            <div className="b-text"> Customer Source</div>
            {/* <div className="light-txt">
              {props.sourceName ? (
                <FormattedMessage id={props.sourceName} />
              ) : (
                "-"
              )}
            </div> */}
            {/* <div className="light-txt">
              {props.sourceId ? (
                props.sources.filter((item) => item.sourceId === props.sourceId)[0]?.customerSourceName
              ) : (
                <div className="empty-data-box"></div>
              )}
            </div> */}
            <div className="light-txt">

              {props.sources.find((item) => item.customerSourceId === props.sourceId)?.customerSourceName || <div className="empty-data-box"></div>}
            </div>
          </Col>
          {props.customerType === 'business' ?
            <Col span={12}>
              <div className="b-text"> Industry</div>
              <div className="light-txt">
                {props.industryName ? (
                  <FormattedMessage id={props.industryName} />
                ) : (
                  <div className="empty-data-box"></div>
                )}
              </div>
            </Col> : <span style={{ display: "none" }}></span>}
          {props.customerType === 'business' ?
            <Col span={12}>
              <div className="b-text"> Located in SEZ </div>
              <div className="light-txt">
                {" "}
                {props.locatedInSez === 1 ? "Yes" : "No"}
              </div>

            </Col> : <span style={{ display: "none" }}></span>}
          <Col span={12}>
            <div className="b-text"> Payment Term</div>
            <div className="light-txt">
              {" "}
              {props.paymentTermName ? (
                <FormattedMessage id={props.paymentTermName} />
              ) : (
                <div className="empty-data-box"></div>
              )}
            </div>
          </Col>
          {props.customerType === 'business' ?
            <Col span={12}>
              <div className="b-text">Website Url</div>
              <div className="light-txt">
                {" "}
                {props.webSite ? <FormattedMessage id={props.webSite} /> : <div className="empty-data-box"></div>}
              </div>
            </Col> : <span style={{ display: "none" }}></span>}
          {props.customerType === 'business' ?
            <Col span={12}>
              <div className="b-text">Rate Your Company</div>
              <div className="light-txt">
                {" "}
                {props.starRating ? (
                  <FormattedMessage id={props.starRating} />
                ) : (
                  <div className="empty-data-box"></div>
                )}
              </div>
            </Col> : <span style={{ display: "none" }}></span>}
          {props.customerType === 'business' ?
            <Col span={12}>
              <div className="b-text">Relationship Since</div>
              <div className="light-txt">
                {" "}
                {props.relationshipSince
                  ? getMomentDateForUIReadOnly({
                    date: new Date(props.relationshipSince),
                    format,
                  })
                  : <div className="empty-data-box"></div>}
              </div>
            </Col> : <span style={{ display: "none" }}></span>}
          {props.customerType === 'business' ?
            <Col span={12}>
              <div className="b-text">Total Balance Due</div>
              <div className="light-txt">
                {" "}
                <strong>
                  {props.companyInfo.currencyIcon ? (
                    <i className={props.companyInfo.currencyIcon}></i>
                  ) : (
                    props.companyInfo.currencyCode + " "
                  )}
                </strong>
                {Number(props.totalAmountDue || 0).toFixed(2)}
              </div>
            </Col> : <span style={{ display: "none" }}></span>}
          {props.customerType === 'business' ?
            <Col span={12}>
              <div className="b-text">Available Credit</div>
              <div className="light-txt">
                {" "}
                <strong>
                  {props.companyInfo.currencyIcon ? (
                    <i className={props.companyInfo.currencyIcon}></i>
                  ) : (
                    props.companyInfo.currencyCode + " "
                  )}
                </strong>
                {Number(props.availableCredit || 0).toFixed(2)}
              </div>
            </Col> : <span style={{ display: "none" }}></span>}
          <Col span={12}>
            <div className="b-text"> Fax No.</div>
            <div className="light-txt">
              {/* {props.faxNumber ? (
                <FormattedMessage id={props.faxNumber} />
              ) : (
                "-"
              )} */}
              {props.boContactList?.map(obj => obj.faxNumber || <div className="empty-data-box"></div>)}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Information;
