import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RFQListingComponent from '../../../../components/supplier/rfq/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchAllRFQ, getRFQCount, resetPaginatedData, updateRfqStatus, deleteRFQ, deleteChildRFQ, fetchAllRFQDrafts, getRFQDraftCount, fetchAllRFQByFilter, getRFQCountByFilter } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchUserPreferences, saveUserPrefrences, deleteAuditTrail,  createAuditTrail, fetchAllEmployees, fetchProjects } from '../../../../actions/commonActions';
import { fetchAllRFQStatus, addRFQStatus, deleteRFQStatus } from '../../../modal/modalBody/supplier/AddUpdateBiddingSupplier/action';
import { CONSTANTS_TRANSACTIONS } from '../../../../static/constants';
import * as find from 'lodash.find';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
import { FormattedMessage } from 'react-intl';
import RFQFiltersComp from '../../../../components/supplier/rfq/Listing/RFQListFilters';
class RFQListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      expandedRowKeys: [],
      isDataRendered: false,
      txPreferences: { txType: "rfq", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['rfqNumber', 'rfqDate', 'rfqBiddingSuppliersList'],
      txAvailableColumn: {
        rfqNumber: true,
        rfqExpirationDate: true,
        rfqDate: true,
        rfqBiddingSuppliersList: true,
       // customerName: true,
        customerRFQNumber: true,
        orderPriority: true,
        customerPONumber: true,
        customerInquiryNumber: true,
        salesPersonFullName: true,
        purchaseRequestNumber: true,
        requestingDepartment: true,
        projectName: true,
        purchaseRequestDate: true,
        prExpirationDate: true,
        refNumber: true,
        remarksInternal: true,
        remarksSupplier: true,
        //purchaseType: true,
        // status: true,
        totalQuantityOnPO: true,
        userStatus: true,
        // project: true,
        invStatus: true,
      },
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.purchase.rfq' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
        {
            name: <FormattedMessage id='sidebar.menuItem.purchase' defaultMessage='' />,
        }, 
        {
            name: <FormattedMessage id='sidebar.menuItem.RFQ' defaultMessage='' />,
        }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
      transactionName: CONSTANTS_TRANSACTIONS.RFQ,
    };
    fetchPaginationDataIfNeeded('fetchAllRFQ', 'rfqList', this.props, payload);
    fetchDataIfNeeded('fetchAllRFQStatus', 'rfqStatusList', this.props, payload, true);
    fetchDataIfNeeded('getRFQCount', 'rfqCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    this.props.fetchAllEmployees(payload);
    this.props.fetchProjects(payload);
    this.props.fetchAllRFQStatus(payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "rfq", templateName: "standard" });
      if (txPreferences) {
        let txColumns = ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [];
        txColumns = txColumns.length? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        const txColumnSetting = ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <>
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.rfqFilterDrawerVisible && <RFQFiltersComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <RFQListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    rfqList: state.rfq.rfqList,
    rfqCount: state.rfq.rfqCount,
    rfqStatusList: state.rfq.rfqStatusList,
    isLoading: state.common.isLoading,
    rfqDraftList: state.rfq.rfqDraftList,
    rfqDraftCount: state.rfq.rfqDraftCount,
    permissions: state.auth.permissions,
    allPreferences: state.common.allPreferences,
    salesPersonList: state.common.employees,
    projectList: state.common.projectList,
    userStatus: state.common.userStatus,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllRFQ,
  getRFQCount,
  resetPaginatedData,
  fetchAllRFQStatus,
  addRFQStatus,
  deleteRFQStatus,
  deleteRFQ,
  showModal,
  updateRfqStatus,
  hideModal,
  fetchAllRFQDrafts, 
  getRFQDraftCount,
  createAuditTrail,
  deleteAuditTrail, 
  pushModalToStack,
  popModalFromStack,
  fetchUserPreferences,
  saveUserPrefrences,
  deleteChildRFQ,
  fetchAllEmployees,
  fetchAllRFQByFilter,
  getRFQCountByFilter,
  fetchProjects,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RFQListing);
