import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LogCommunication from '../../../../components/supplier/Listing/LogCommunication';
import { pushModalToStack, popModalFromStack, fetchAllEmployees } from './../../../../actions/commonActions';
import { createUpdateCommunication } from './action';
import { fetchDataIfNeeded } from '../../../../utils';

class LogCommunicationContainer extends Component {
  constructor(props){
    super(props);
    this.state ={
      communicationData: props.communicationData || {}
    }
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      supplierId: this.props.supplierId
    };
    fetchDataIfNeeded('fetchAllEmployees', 'allEmployee', this.props, payload);
  }

  render() {
    return (
      <LogCommunication {...this.props} {...this.state}  updateState={(data) => { this.setState(data)}} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    allEmployee: state.common.employees,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllEmployees,
  createUpdateCommunication,
  pushModalToStack,
  popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LogCommunicationContainer);
