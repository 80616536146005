import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomerDetails from '../../../components/customer/details/index';
import { pushModalToStack, popModalFromStack, showModal, hideModal, addActivityStatus, deleteActivityStatus,
   addActivityPriorities, deleteActivityPriorities, addActivityTypes, deleteActivityTypes, 
  fetchAllEmployees, fetchCustomerDetailsByCustomerId, deleteCustomerTaxIdentifications,  } from './../../../actions/commonActions';
import {
  fetchCustomerCreditMemoList, fetchCustomerCreditMemoListCount,
  fetchCustomerInvoiceList, fetchCustomerInvoiceCount, resetActivities, deleteActivity,
  fetchCustomerPaymentList, fetchCustomerPaymentListCount, exportTxPdf, exportLedgerPdf,
  fetchCustomerSalesOrderTransactions, fetchCustomerSalesOrderTransactionCount,
  fetchCustomerTransactions, fetchCustomerTransactionCount, resetTransactionListData,
  resetSalesOrderTransactionsListData, resetInvoiceTransactionListData, resetCommunications,
  resetPaymentTransactionListData, resetCreditMemoTransactionListData, createUpdateTask,
  fetchCustomerCommunications, fetchCustomerActivities, fetchCustomerAddresses, fetchCustomerToDoTasks,
  fetchCustomerRfqTransactions, fetchCustomerRfqCount, resetcustomerRfqListData, fetchCustomerSqTransactions, 
  fetchCustomerSqCount, resetcustomerSqListData, fetchCustomerLedgerTxSummaryList, getCustomerPurchaseItems, 
  fetchCustomerPurchaseItemsCount, getCustomerActivitiesTrack, deleteCustomerAddress,
} from './action';
import { deleteContact, fetchAllContacts } from './../Listing/ShowConact/action';
import {
   fetchAllActivityPriorities, fetchAllActivityStatus, fetchAllActivityTypes, createUpdateActivity
} from '../Listing/LogActivity/action';
import { createUpdateCommunication, deleteCommunication } from '../Listing/LogCommunication/action';
import { generatePDF} from './../../../actions/downloadFileAction';
import { getAvailableBalance } from '../../modal/modalBody/customer/SalesInvoiceDetail/action';
import CommunicationReviewComp from '../../drawer/communication';
import CustomerTaskDrawer from '../../../containers/drawer/customer/taskDrawer';
import { fetchLeadActivityTrack } from '../../modal/modalBody/leads/profile/action';
import { getTotalOrdersCount,
  getTotalSalesOrdersCount, getTotalUnpaidBalance, getTotalSalesRevenue, } from '../Listing/action';
import { deleteAddress } from '../Listing/ShowAddress/action';


class CustomerDetailContainer extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {}
    this.state = {
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false,
      ...linkProps
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {}
    const payload = {
      customerId: this.props.customerId || linkProps.customerId,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100,
      primaryAddress:{},
      primaryContact:{}
    };
    this.props.resetCommunications();
    this.props.resetActivities();
    //fetchPaginationDataIfNeeded('fetchCustomerTransactions', 'transactionsList', this.props, payload);
    //fetchDataIfNeeded('fetchCustomerTransactionCount', 'transactionsCount', this.props, payload);
    //this.props.resetcustomerSqListData();
    //fetchPaginationDataIfNeeded('fetchCustomerSqTransactions', 'customerSqList', this.props, payload);
    //fetchDataIfNeeded('fetchCustomerSqCount', 'customerSqCount', this.props, payload);
    // fetchDataIfNeeded('fetchCustomerAddresses', 'addressList', this.props, payload);
    this.props.getAvailableBalance(payload);
    this.props.fetchCustomerDetailsByCustomerId(payload);
    this.props.getCustomerActivitiesTrack({...payload, pageSize: 1000});
    if(this.props?.customer?.leadId){
      this.props.fetchLeadActivityTrack({...payload, pageSize: 1000, crmLeadId: this.props.customer.leadId || ''});
    }
    // this.props.getCustomerCount(payload); 
    this.props.getTotalOrdersCount(payload);
    this.props.getTotalSalesOrdersCount(payload);
    this.props.getTotalUnpaidBalance(payload);
    this.props.getTotalSalesRevenue(payload);
  }
  componentWillReceiveProps(props) {
    // if (props.customer && props.customer.customerId) {
    //   this.setState({
    //     customer: props.customer,
    //   })
    // }
    if (props.customer?.customerId) {
      this.setState({
        customer: props.customer,
        primaryContact: (props.customer.boContactList || []).find(
          (obj) => obj.isPrimaryContact === 1),
          primaryAddress: (props.customer.boLocationList || []).find(
            (obj) => obj.isDefault === 1 
          ),
      })
    }
  }
  render() {
    const linkProps = (this.props.location && this.props.location.state) || {}
    return (
      <>
        {this.state.customerTaskDrawerVisible && <CustomerTaskDrawer  {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.communicationReviewDrawerVisible && <CommunicationReviewComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <CustomerDetails
          {...this.props}
          {...this.state}
          updateState={(data) => { this.setState(data) }}
          payload={{
            customerId: this.props.customerId || linkProps.customerId,
            relationshipId: (this.props.companyInfo || {}).relationshipId
          }}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    transactionsList: state.customer.transactionsList,
    customerRfqList: state.customer.customerRfqList,
    customerRfqCount: state.customer.customerRfqCount,
    customerSqList: state.customer.customerSqList,
    customerSqCount: state.customer.customerSqCount,
    salesOrderTransactionsList: state.customer.salesOrderTransactionsList,
    invoiceList: state.customer.invoiceList,
    creditMemoList: state.customer.creditMemoList,
    paymentList: state.customer.paymentList,
    paymentListCount: state.customer.paymentListCount,
    creditMemoListCount: state.customer.creditMemoListCount,
    invoiceListCount: state.customer.invoiceListCount,
    salesOrderTransactionsCount: state.customer.salesOrderTransactionsCount,
    transactionsCount: state.customer.transactionsCount,
    customerTaskList: state.customer.activitiesList,
    addressList: state.customer.addressList,
    // taskList: state.customer.taskList,
    commuincationList: state.customer.commuincationList,
    contacts: state.customer.contacts,
    users: state.customer.users,
    activityPriorities: state.customer.activityPriorities,
    activityStatus: state.customer.activityStatus,
    activityTypes: state.customer.activityTypes,
    allEmployee: state.common.employees,
    availableCredits: state.salesInvoice.availableCredits,
    permissions: state.auth.permissions,
    customer: state.common.customerDetail,
    customerInvoiceLedgerSummaryList : state.customer.customerInvoiceLedgerSummaryList,
    customerPurchaseItems: state.customer.customerPurchaseItems,
    customerPurchaseItemsCount: state.customer.customerPurchaseItemsCount,
    customerActivityTrackList: state.customer.customerActivityTrackList,
    leadActivityTrackList: state.crm.leadActivityTrackList,
    headerLoading: state.common.headerLoading,
    listLoading: state.common.listLoading,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createUpdateTask,
  exportTxPdf,
  exportLedgerPdf,
  fetchCustomerCreditMemoList,
  fetchCustomerCreditMemoListCount,
  fetchCustomerInvoiceList,
  fetchCustomerInvoiceCount,
  fetchCustomerPaymentList,
  fetchCustomerPaymentListCount,
  fetchCustomerSalesOrderTransactions,
  fetchCustomerSalesOrderTransactionCount,
  fetchCustomerTransactions,
  fetchCustomerTransactionCount,
  fetchAllContacts,
  fetchCustomerToDoTasks,
  fetchCustomerAddresses,
  fetchCustomerActivities,
  fetchCustomerCommunications,
  pushModalToStack,
  popModalFromStack,
  resetCommunications,
  resetActivities,
  deleteActivity,
  resetTransactionListData,
  resetSalesOrderTransactionsListData,
  resetInvoiceTransactionListData,
  resetPaymentTransactionListData,
  resetCreditMemoTransactionListData,
  fetchCustomerRfqTransactions, 
  fetchCustomerRfqCount,
  resetcustomerRfqListData,
  fetchCustomerSqTransactions, 
  fetchCustomerSqCount,
  resetcustomerSqListData,
  showModal, 
  hideModal,
  fetchAllActivityPriorities, 
  fetchAllActivityStatus, 
  fetchAllActivityTypes, 
  createUpdateActivity,
  addActivityStatus, 
  deleteActivityStatus, 
  addActivityPriorities, 
  deleteActivityPriorities, 
  addActivityTypes, 
  deleteActivityTypes,
  createUpdateCommunication, 
  deleteCommunication,
  fetchAllEmployees,
  generatePDF,
  getAvailableBalance,
  fetchCustomerDetailsByCustomerId,
  deleteCustomerTaxIdentifications,
  fetchCustomerLedgerTxSummaryList,
  getCustomerPurchaseItems,
  fetchCustomerPurchaseItemsCount,
  getCustomerActivitiesTrack,
  fetchLeadActivityTrack,
  getTotalOrdersCount,
  getTotalSalesOrdersCount,
  getTotalUnpaidBalance,
  getTotalSalesRevenue,
  deleteContact,
  deleteAddress,
  deleteCustomerAddress
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailContainer);
