import { COMPANY_ACTION_LIST } from "../static/constants";

const initialState = {
    departmentList: {
        1: []
    },
    departmentListCount: 0,
    jobRoleList: {
        1: []
    },
    jobRoleListCount: 0,
    designationList: {
        1: []
    },
    designationListCount: 0,
    employeeTypeList: {
        1: []
    },
    employeeTypeCount: 0,
    teamList: {
        1: []
    },
    teamCount: 0,
    workShift: {
        1: []
    },
    workShiftCount: 0,
    allAllowance: {
        1: []
    },
    allowanceCount: 0,
    deductionList: {
        1: []
    },
    deductionListCount: 0,
    allowanceSearchList:[],
    branchList: {
        1: []
    },
    branchCount: 0,
    employeesCount: 0,
    employeeList: {
        1: []
    },
    employeeDetail:{}
}

const EmployeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_ACTION_LIST.DEPARTMENT_LIST:
            return {
                ...state,
                departmentList: {
                    ...state.departmentList,
                    [action.data.pageNo || 1]: action.data.list

                }
            }
        case COMPANY_ACTION_LIST.DEPARTMENT_COUNT:
            return { ...state, departmentListCount: action.data };
        case COMPANY_ACTION_LIST.JOB_ROLE_LIST:
            return {
                ...state,
                jobRoleList: {
                    ...state.jobRoleList,
                    [action.data.pageNo || 1]: action.data.list

                }
            }
        case COMPANY_ACTION_LIST.JOB_ROLE_LIST_COUNT:
            return { ...state, jobRoleListCount: action.data };
        case COMPANY_ACTION_LIST.JOB_ROLE_LIST_RESET:
            return { ...state, jobRoleList: initialState.jobRoleList };
        case COMPANY_ACTION_LIST.DESIGNATION_LIST_PAGE:
            return {
                ...state,
                designationList: {
                    ...state.designationList,
                    [action.data.pageNo || 1]: action.data.list

                }
            }
        case COMPANY_ACTION_LIST.DESIGNATION_LIST_PAGE_COUNT:
            return { ...state, designationListCount: action.data };
        case COMPANY_ACTION_LIST.EMPLOYEE_TYPE_LIST:
            return {
                ...state,
                employeeTypeList: {
                    ...state.employeeTypeList,
                    [action.data.pageNo || 1]: action.data.list

                }
            }
        case COMPANY_ACTION_LIST.EMPLOYEE_TYPE_COUNT:
            return { ...state, employeeTypeCount: action.data };
        case COMPANY_ACTION_LIST.TEAM_LIST_PAGE:
            return {
                ...state,
                teamList: {
                    ...state.teamList,
                    [action.data.pageNo || 1]: action.data.list

                }
            }
        case COMPANY_ACTION_LIST.TEAM_LIST_PAGE_COUNT:
            return { ...state, teamCount: action.data };
        case COMPANY_ACTION_LIST.WORKSHIFT_LIST_PAGE:
            return {
                ...state,
                workShift: {
                    ...state.workShift,
                    [action.data.pageNo || 1]: action.data.list

                }
            }
        case COMPANY_ACTION_LIST.WORKSHIFT_LIST_PAGE_COUNT:
            return { ...state, workShiftCount: action.data };
        case COMPANY_ACTION_LIST.ALLOWANCE_LIST_PAGE:
            return {
                ...state,
                allAllowance: {
                    ...state.allAllowance,
                    [action.data.pageNo || 1]: action.data.list

                }
            }
        case COMPANY_ACTION_LIST.ALLOWANCE_LIST_PAGE_COUNT:
            return { ...state, allowanceCount: action.data };
            case COMPANY_ACTION_LIST.ALLOWANCE_SEARCH_PAGE:
                return { ...state, allowanceSearchList: action.data };
        case COMPANY_ACTION_LIST.DEDUCTION_LIST_PAGE:
            return {
                ...state,
                deductionList: {
                    ...state.deductionList,
                    [action.data.pageNo || 1]: action.data.list

                }
            }
        case COMPANY_ACTION_LIST.DEDUCTION_LIST_PAGE_COUNT:
            return { ...state, deductionListCount: action.data };

        case COMPANY_ACTION_LIST.BRANCH_LIST:
            return {
                ...state,
                branchList: {
                    ...state.branchList,
                    [action.data.pageNo || 1]: action.data.list
                }
            }
        case COMPANY_ACTION_LIST.BRANCH_COUNT:
            return { ...state, branchCount: action.data };

        case COMPANY_ACTION_LIST.EMPLOYEES_LIST:
            return {
                ...state,
                employeeList: {
                    ...state.employeeList,
                    [action.data.pageNo || 1]: action.data.list
                }
            }
        case COMPANY_ACTION_LIST.EMPLOYEE_COUNT:
            return { ...state, employeesCount: action.data };
            case COMPANY_ACTION_LIST.EMPLOYEE_DETAIL:
                return { ...state, employeeDetail: action.data };     
        default:
            return state;
    }
}

export default EmployeeReducer;