import React, { Component, Fragment } from "react";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, Skeleton } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import * as find from "lodash.find";
import { getMomentDateForUIReadOnly } from "../../../../utils";
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../static/constants";
import { getLabelName } from "../../../modal/modalBody/customerInfo";

class rfqDetails extends Component {
  constructor(props) {
    super(props);
    this.companyInfo = this.props.companyInfo || {};
  }

  handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = this.props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <FormattedMessage id="addItem.text.project" defaultMessage="Project" />
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewEmployeeAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.employee" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EMPLOYEE,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };

    switch (modalType) {
      case MODAL_TYPE.DEPARTMENT: {
        addFunc = props.addDepartment;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType =
          CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST];
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = (
          <FormattedMessage id="addItem.text.department" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = (
          <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.DEPARTMENT: {
        deleteFunc = props.deleteDepartment;
        break;
      }
      case MODAL_TYPE.EMPLOYEE: {
        deleteFunc = props.deleteEmployee;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        deleteFunc = props.deleteProject;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
        props.hideModal();
        if (
          modalType === MODAL_TYPE.DOCUMENT_NAME &&
          payload.id === this.props.documentNameId
        ) {
          const selectedObj =
            find(this.props.documentList || [], {
              txType:
                CONSTANTS_TRANSACTIONS_KEY[
                  CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST
                ],
              isDefault: 1,
            }) || {};
          this.props.updateState({
            documentNameId: selectedObj.documentNameId,
            docName: selectedObj.docName,
          });
        }
      },
    };
    showModal(modalData);
  };

  getTaxIdentificationsFragment = (taxIdentifications) => {
    if (!taxIdentifications) return <Fragment />;
    let taxIdentificationsList = [];
    if (taxIdentifications) {
      taxIdentificationsList = JSON.parse(taxIdentifications);
    }
    const fragmentObj = (
      <Fragment>
        {taxIdentificationsList.map((obj, i) => {
          return (
            <div className="billing-address">
              {obj.taxIdentificationType || ""} - {obj.taxIdentificationNumber}
            </div>
          );
        })}
      </Fragment>
    );

    return fragmentObj;
  };

  getAddressInfoFragment = (obj = {}, additionInfo = {}) => {
    const fragmentObj = (
      <Fragment>
        {obj.streetAddress1 ? (
          <div className="billing-address">{obj.streetAddress1}</div>
        ) : (
          ""
        )}
        {obj.streetAddress2 ? (
          <div className="billing-address">{obj.streetAddress2}</div>
        ) : (
          ""
        )}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""} ${
              obj.stateName ? "," + obj.stateName : ""
            } ${obj.zipCode ? "," + obj.zipCode : ""}`}
          </div>
        ) : (
          ""
        )}
        {/* {
                additionInfo.firstName
                    ? <div className='billing-address'>
                        {contactName}
                    </div> :
                    ''
            } */}
        {additionInfo.workPhone ? (
          <div className="billing-address">
            <FormattedMessage id="phone" defaultMessage="" /> -{" "}
            {additionInfo.workPhone}
          </div>
        ) : (
          ""
        )}
        {additionInfo.email ? (
          <div className="billing-address">
            <FormattedMessage id="email" defaultMessage="" /> -{" "}
            {additionInfo.email}
          </div>
        ) : (
          ""
        )}
        {additionInfo.gstNumber ? (
          <div className="billing-address">
            <FormattedMessage id="gstIn" defaultMessage="" /> -{" "}
            {additionInfo.gstNumber}
          </div>
        ) : (
          ""
        )}
        {additionInfo.panNumber ? (
          <div className="billing-address">
            <FormattedMessage id="pan" defaultMessage="" /> -{" "}
            {additionInfo.panNumber}
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );

    return fragmentObj;
  };

  getContactNameFragment = (additionInfo) => {
    if (!additionInfo) return <Fragment />;
    let contactName = "";
    if (additionInfo.salutationName)
      contactName += additionInfo.salutationName + " ";
    if (additionInfo.firstName) contactName += additionInfo.firstName;
    if (additionInfo.middleName) contactName += " " + additionInfo.middleName;
    if (additionInfo.lastName) contactName += " " + additionInfo.lastName;
    const fragmentObj = (
      <Fragment>
        {contactName && contactName.length ? (
          <div className="billing-address">{contactName}</div>
        ) : (
          ""
        )}
      </Fragment>
    );
    return fragmentObj;
  };

  render() {
    return (
      <div>
        <div className="inner-content">
          <div
            style={{
              color: "#000",
              display: "inline-block",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            {this.props.oldRfqDetailData.documentName || "RFQ"}
          </div>

          <Row>
            <Col span={16}>
              <Skeleton loading={this.props.listLoading} paragraph={{ rows: 3}} active>
                <div className="read-only-content pt20">
                  <div
                    className="billing-address"
                    style={{ fontWeight: "bolder" }}
                  >
                    {this.getContactNameFragment(
                      this.props.oldRfqDetailData.additionalInfoList
                        ? this.props.oldRfqDetailData.additionalInfoList[0] ||
                            {}
                        : {}
                    )}
                    {this.props.biddingSupplier?.supplierName}
                  </div>
                  <div>
                    {this.getAddressInfoFragment(
                      this.props.supplierAddress,
                      this.props.oldRfqDetailData.additionalInfoList
                        ? this.props.oldRfqDetailData.additionalInfoList[0] ||
                            {}
                        : {}
                    )}
                    {this.getTaxIdentificationsFragment(
                      (
                        (this.props.oldRfqDetailData.additionalInfoList ||
                          [])[0] || {}
                      ).taxIdentifications
                    )}
                  </div>
                </div>
              </Skeleton>
            </Col>

            <Col span={4}>
              <Skeleton loading={this.props.listLoading} paragraph={{ rows: 3}} active>
                <div className="read-only-content pt20">
                  <div className="b-text">
                    <FormattedMessage
                      id="common.rfqNumber"
                      defaultMessage=""
                    />
                  </div>
                  <div className="pb5">
                    {this.props.oldRfqDetailData.rfqNumber}
                  </div>

                  <div className="b-text">
                    <FormattedMessage
                      id="modal.txDetails.rfq.rfqDate"
                      defaultMessage=""
                    />
                  </div>
                  <div className="pb5">
                    {this.props.oldRfqDetailData.rfqDate &&
                      getMomentDateForUIReadOnly({
                        date: this.props.oldRfqDetailData.rfqDate,
                        format: CONSTANTS.DISPLAY_DATE_FORMAT,
                      })
                    }
                  </div>

                  <div className="b-text">
                    <FormattedMessage
                      id="modal.txDetails.rfq.bidClosingDate"
                      defaultMessage=""
                    />
                  </div>
                  <div className="pb5">
                    {this.props.oldRfqDetailData.rfqExpirationDate &&
                      getMomentDateForUIReadOnly({
                        date: this.props.oldRfqDetailData.rfqExpirationDate,
                        format: CONSTANTS.DISPLAY_DATE_FORMAT,
                      })
                    }
                  </div>
                </div>
              </Skeleton>
            </Col>

            <Col span={4}>
              <Skeleton loading={this.props.listLoading} paragraph={{ rows: 3}} active>
                <div className="d-inline-flex w-100">
                  <div className="read-only-content pt20 pl10">
                    <div className="lh-adjust pb5 billing-address">
                      <b> {this.props.companyInfo.storeName}</b>
                    </div>
                    <div>
                      {this.getAddressInfoFragment(
                        this.props.relationshipBillingAddress,
                        this.props.companyInfo
                      )}
                      <div className="billing-address">
                        {this.getTaxIdentificationsFragment(
                          (
                            (this.props.oldRfqDetailData.additionalInfoList ||
                              [])[0] || {}
                          ).relationshipTaxIdentifications
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Skeleton>
            </Col>
          </Row>
        </div>
        
        <div className="modal-table-container vertical-space">
          <Skeleton loading={this.props.listLoading} paragraph={{ rows: 2}} active>
            <table>
              <thead>
                <tr>
                  {this.props.oldRfqDetailData.customerRFQNumber && (
                    <th>
                      <FormattedMessage
                        id="modal.txDetails.rfq.customerRFQNumber"
                        defaultMessage=""
                      />
                    </th>
                  )}
                  <th>
                    <FormattedMessage
                      id="modal.txDetails.common.refNumber"
                      defaultMessage=""
                    />
                  </th>
                  {/* <th><FormattedMessage id='common.salesPerson' defaultMessage='' /></th> */}
                  <th>
                    <FormattedMessage
                      id="modal.txDetails.common.priority"
                      defaultMessage=""
                    />
                  </th>
                  {/* <th><FormattedMessage id='modal.txDetails.common.project' defaultMessage='' /></th> */}
                  {/* <th>Requesting Department</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {this.props.oldRfqDetailData.customerRFQNumber && (
                    <td>
                      {this.props.oldRfqDetailData.customerRFQNumber || "-"}
                    </td>
                  )}
                  <td>{this.props.oldRfqDetailData.refNumber || "-"}</td>
                  {/* <td>{this.props.oldRfqDetailData.salesPersonFullName || '-'}</td> */}
                  <td>{this.props.oldRfqDetailData.orderPriority || "-"}</td>
                  {/* <td>{this.props.oldRfqDetailData.projectNumber ? (this.props.oldRfqDetailData.projectNumber + ' (' + this.props.oldRfqDetailData.projectName + ')') : '-'}</td> */}
                  {/* <td>{rfqData.requestingDepartment || '-'}</td> */}
                </tr>
              </tbody>
            </table>
          </Skeleton>
        </div>
      </div>
    );
  }
}

export default injectIntl(rfqDetails);
