import React from "react";
import { Tabs } from 'antd';
import PurchaseRequest from "./purchaseRequest";
import PurchaseRequestDraft from "./purchaseRequestDraft";
import { FormattedMessage } from 'react-intl';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import StandardPurchaseRequest from "../../../../containers/supplier/purchaseRequest/StandardPurchaseRequest";
import PageHeader from "../../../Common/pageHeader"

const { TabPane } = Tabs;

function callback(key, props) {
    const payload = { companyInfo: props.companyInfo, relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize };
    if (key === '1') {
        props.updateState({ activeTab: 'Purchase Request', activeTabKey: "1" });
    } else if (key === '2') {
        props.updateState({ activeTab: 'Draft', activeTabKey: "2" });
        fetchDataIfNeeded('getPurchaseRequestDraftCount', 'purchaseRequestDraftCount', props, payload);
        fetchPaginationDataIfNeeded('fetchPurchaseRequestDrafts', 'purchaseRequestDraftList', props, payload);
    }
}





const PurchaseRequestListingComp = (props) => {
    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.purchase' defaultMessage='' /> ,
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.purchaseRequest' defaultMessage='' />,
        }
    ];
    const createPurchaseRo = ()=>{
        props.pushModalToStack({
            modalBody: <StandardPurchaseRequest {...props} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            keyboard: false,
            wrapClassName: 'new-transaction-wrapper',
            onCancel: () => {
                props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    props.popModalFromStack();
                  }
                })
              }
        })
    }
    return (
        <>
            <PageHeader {...props}
                pageName="heading.purchase.purchaseRequest"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={createPurchaseRo}
            />
            <div className="view-container">
                <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { callback(key, props); }} type="line">
                    <TabPane tab="Purchase Request" key="1">
                        <PurchaseRequest {...props} />
                    </TabPane>
                    <TabPane tab="Draft" key="2">
                        <PurchaseRequestDraft {...props} />
                    </TabPane>
                </Tabs>
            </div>
        </>
    )
};

export default PurchaseRequestListingComp;
