import axios from "axios";
import config from "../../../config/environmentConfig";
import { MEMBERSHIP_ACTION_LIST, COMMON_ACTIONS } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const getMembershipOptionListing = (payload) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
                }/membershipOption/getMembershipOption?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
                }&pageNumber=${(payload.pageNumber || 1) - 1}`
            )
            .then((res) => {
                const data = {
                    pageNo: payload.pageNumber,
                    list: res.data
                }
                dispatch({
                    type: MEMBERSHIP_ACTION_LIST.MEMBERSHIP_OPTION_LIST,
                    data: data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const getMembershipOptionCount = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/membershipOption/countMembershipOption?relationshipId=${payload.relationshipId}`
            )
            .then((res) => {
                dispatch({
                    type: MEMBERSHIP_ACTION_LIST.MEMBERSHIP_OPTION_COUNT,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const deleteMembershipOption = (payload, props) => {
    return dispatch => {
        return axios
            .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/membershipOption/delete`, payload)
            .then(res => {
                // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                props.getMembershipOptionListing && props.getMembershipOptionListing(props);
                props.getMembershipOptionCount && props.getMembershipOptionCount(props);
                showToasterMessage({ messageType: 'success', description: 'Membership Option successfully Deleted' });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
                }
            })
    }
}

export const resetPaginatedData = () => {
    return (dispatch) => {
        dispatch({ type: MEMBERSHIP_ACTION_LIST.MEMBERSHIP_OPTION_LIST_PAGE_RESET });
    };
};

export const getAllFees = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
                }/fees/getFees?relationshipId=${payload.relationshipId}&pageSize=1000&pageNumber=0`
            )
            .then((res) => {
                dispatch({
                    type: MEMBERSHIP_ACTION_LIST.ALL_TERM_AND_FEES,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

