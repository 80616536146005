import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LockScreenComponent from "../../../../components/modal/modalBody/LockScreen";
import { verifyCredentials } from "./action";

class LockScreen extends Component {
    render() {
        return (
            <LockScreenComponent {...this.props} updateState={(data) => { this.setState(data) }}/>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    verifyCredentials
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LockScreen);
