import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createTaxIdentification = (payload, formData) => {
  let url = '/relationshipTaxIdentification/createRelationshipTaxIdentification';
  if (payload.customerId) {
    url = '/customerTaxIdentification/createCustomerTaxIdentification';
  } else if (payload.supplierId) {
    url = '/supplierTaxIdentification/createSupplierTaxIdentification';
  }

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${url}`, payload)
      .then(res => {
        if (!payload.customerId && !payload.supplierId) {
          dispatch({ type: COMMON_ACTIONS.REL_TAX_IDENTIFICATION_ADDITION, data: res.data })
        }
        else if (formData.callback) {
          formData.callback(res.data)
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          //err.actionToCall = COMMON_ACTIONS.REL_TAX_IDENTIFICATION_ADDITION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const checktaxIdentificationNumber  = (payload,props,type,callback) =>{

  let url = '/relationshipTaxIdentification/checktaxIdentificationNumber';
  if (type === "customer") {
    url = '/customerTaxIdentification/checktaxIdentificationNumber';
  } else if (type === "supplier") {
    url = '/supplierTaxIdentification/checktaxIdentificationNumber';
  }
  const val =  [payload.taxIdentificationNumber]
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${url}?relationshipId=${payload.relationshipId}&taxIdentificationNumber=${val}`)
      .then(res => {
        const { data } = res
        if(data){
          callback(data)
          showToasterMessage({messageType: 'error',  description: ' Tax-Identification Number Exists!' })
        }else{
          callback(data)
        }
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: 'Some error occurred' })
        }
      })
  }
}