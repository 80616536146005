import React from "react";
// import LeadSubSourceList from "../../../components/leads/LeadSubSourceList";
// import LeadSubSourceDrawer from "../../../components/leads/LeadSubSourceList/leadSubSourceDrawer";
import MyLeadList from "../../../components/leads/MyLeads";
// import LeadStageDrawer from "../../../components/leads/MyLeads/leadStageDrawer";
import { pushModalToStack, popModalFromStack, showModal, hideModal } from "../../../actions/commonActions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllLeadList, changeLeadStatus,  } from '../AllLeads/action';
import { getAllLeadStatus} from '../../modal/modalBody/leads/createLead/action';

class MyLeadListComp extends React.Component {
  // constructor(props) {
  //   super(props);

  // }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    }

    this.props.fetchAllLeadList(payload);
    this.props.getAllLeadStatus(payload);
  }
  
  render() {
    return (
      <>
        <MyLeadList
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />

       {/* {this.state.leadStageCreateEditDrawer && <LeadStageDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />} */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    allLeadList: state.crm.allLeadList,
    crmLeadStatusList: state.crm.crmLeadStatusList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  fetchAllLeadList,
  getAllLeadStatus,
  changeLeadStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyLeadListComp);