import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InboundDeliveryInformationComp from '../../../../../components/modal/modalBody/supplier/InboundDeliveryInformation';
import { fetchInboundDeliveryByPOMasterId } from './action';
import { fetchDataIfNeeded } from  '../../../../../utils';

class InboundDeliveryInformation extends Component {
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            supplierId: this.props.poData.supplierId,
            poMasterId: this.props.poData.poMasterId,
            relationshipId: companyInfo.relationshipId

        }
        fetchDataIfNeeded('fetchInboundDeliveryByPOMasterId', 'poInboundDeliveryList', this.props, payload, true);
    }

    render() {
        return <InboundDeliveryInformationComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        poInboundDeliveryList: state.purchaseOrder.poInboundDeliveryList
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchInboundDeliveryByPOMasterId
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InboundDeliveryInformation);