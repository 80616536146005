import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TotalStudentsComponent from '../../../../../components/modal/modalBody/customer/TotalStudents';
import { getTotalStudentcCountByGrade,  } from '../../settings/RegistrationFormSettingPreview/action';

class StudentDetail extends Component {

    constructor(props){
        super();
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, registrationFormSettingId: this.props.registrationFormSettingId };
        this.props.getTotalStudentcCountByGrade(payload);
    }

    render() {
        return <div>
            <TotalStudentsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        totalStudentCountByGrade: state.studentRegistration.totalStudentCountByGrade,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getTotalStudentcCountByGrade
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetail);