import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip } from 'antd';
import { fetchPaginationDataIfNeeded, fixedDecimalAndFormateNumber, getMomentDateForUIReadOnly} from '../../../../utils'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import BusinessExpenseDetail from '../../../../containers/modal/modalBody/finance/BusinessExpense';
import BusinessExpense from '../../../../containers/finance/BusinessExpense/Create';

import PageBreadcrumb from '../../../PageBreadcrumb';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import { CONSTANTS, ICONS } from '../../../../static/constants';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const BusinessExpensesListingComp = (props) => {
    const { businessExpensesList, updateState, companyInfo, businessExpensesCount, pageNumber, pageSize } = props;
    // const primaryPerm = permissions.primary;

    // const canCreate = checkACLPermission(primaryPerm, MODULE_CODED_VALUES.BUSINESS_EXPENSE, PERMISSION_VALUES.CREATE);

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchBusinessExpenses', 'businessExpensesList', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        payload.pageNumber = 0;
        props.fetchBusinessExpenses(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: 0 })
    }

    // const getRowHeight = (params) => {
    //     let height = 28;
    //     return height;
    // }

    const openExpenseDetails = (data) => {
        const expensePayload = {
            expenseMasterId: data.expenseMasterId
        }
        props.pushModalToStack({
            modalBody: <BusinessExpenseDetail {...props} expensePayload={expensePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }
    const createExpense = () => {
        props.pushModalToStack({
            modalBody: <BusinessExpense {...props} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            keyboard: false ,
            wrapClassName: 'new-transaction-wrapper'
        })
    }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.businessExpense.listing.header.expenseNo' defaultMessage='' />;
    //         },
    //         field: "expenseNumber",
    //         rowDrag: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openExpenseDetails(link.data);
    //         }}>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='supplier.businessExpense.listing.header.expenseAmount' defaultMessage='' /></div>;
    //         },
    //         field: "totalAmount",
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         cellRendererFramework: (link) => <div>
    //         {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalAndFormateNumber(link.data.totalAmount)}
    //     </div>,
    //     },
        
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.businessExpense.listing.header.expenseDate' defaultMessage='' />;
    //         },
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //             <Tooltip placement="topLeft" title={(link.data.expenseDate && getMomentDateForUIReadOnly({date: link.data.expenseDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
    //             {getMomentDateForUIReadOnly({date: new Date(link.data.expenseDate), format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.creditMemo.listing.header.systemStatus' defaultMessage='' />;
    //         },
    //         field: "status",
    //         colId: "status_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.businessExpense.listing.header.refNumber' defaultMessage='' />;
    //         },
    //         field: "refNumber",
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.project' defaultMessage='' />;
    //         },
    //         field: "project",
    //         colId: "project_1",
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' +link.data.projectName }</span> : ''}
    //             </div>
    //         },
    //         resizable: true
    //     },
        
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='supplier.debitMemo.listing.header.status' defaultMessage='' />;
    //     //     },
    //     //     field: "status",
    //     //     cellRendererFramework: (link) => {
    //     //         return <div><span className={
    //     //             [
    //     //                 "status-code",
    //     //                 link.data.status.toLowerCase()
    //     //             ].join(' ')
    //     //         }>{
    //     //                 link.data.status
    //     //             }</span></div>
    //     //     },
    //     //     resizable: true
    //     // }
    // ];

    // const onFirstDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // }

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    const breadCrumbList = [
         {
            name: <FormattedMessage id='expense.text' defaultMessage='' />,
        }, {
            name: <FormattedMessage id='list.text' defaultMessage='' />
        }
    ];
    
    return (
        <Fragment>
            <div className='view-top-bar'>
            <div className='top-view-left'>
            <div className="page-heading">
                <FormattedMessage id='heading.finance.businessExpense' defaultMessage='' />
            </div>
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className='top-view-right'>

             
                                        <button className='create-action-btn' onClick={
                                            () => {
                                                // props.history.push("/admin/business-expense-create");
                                                createExpense();
                                            }
                                        }>
                                            <i className={ICONS.ADD} /> <FormattedMessage id='button.create.label' defaultMessage='' />
                                        </button>
            
            </div>
            </div>
            <div className='view-container'>
                <Skeleton loading={props.isLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id='heading.finance.businessExpense.table.heading' defaultMessage='' />
                        </div>
                        {
                            props.pageSize ? <>
                            <div className="vertical-sep" />
                            <div>{props.pageSize}</div>
                            </>:''
                        }
                    </div>
                    <div className="right-actions">
                    </div>
                </div>
                    <div className='table-container' >

                        <table>
                            <thead>
                                <tr>
                                    <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                                    <th><FormattedMessage id='supplier.businessExpense.listing.header.expenseNo' defaultMessage='' /></th>
                                    <th><FormattedMessage id='supplier.businessExpense.listing.header.expenseAmount' defaultMessage='' /></th>
                                    <th><FormattedMessage id='supplier.businessExpense.listing.header.expenseDate' defaultMessage='' /></th>
                                    <th><FormattedMessage id='customer.creditMemo.listing.header.systemStatus' defaultMessage='' /></th>
                                    <th><FormattedMessage id='supplier.businessExpense.listing.header.refNumber' defaultMessage='' /></th>
                                    <th><FormattedMessage id='common.project' defaultMessage='' /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {businessExpensesList && businessExpensesList[pageNumber]?businessExpensesList[pageNumber].map((rowData,index)=>(
                                    <tr key={index}>
                                        <td>
                                            {Number(((pageNumber) * pageSize) + index + 1)}
                                        </td>
                                        <td>
                                    <div className="cursor-pointer" onClick={() => {
                                        openExpenseDetails(rowData)
                                    }}>{rowData.expenseNumber}</div>
                                </td>
                                <td>
                                    
                                {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalAndFormateNumber(rowData.totalAmount)}
                                </td>
                                <td>
                                    <div>
                                    <Tooltip placement="topLeft" title={(rowData.expenseDate && getMomentDateForUIReadOnly({date: rowData.expenseDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
                {getMomentDateForUIReadOnly({date: new Date(rowData.expenseDate), format})}
                </Tooltip>
                                    </div>
                                </td>
                                <td>
                                    {rowData.status ?  <span className={["status-code", rowData.status.toLowerCase()].join(' ')}><FormattedMessage id={rowData.status} defaultMessage={rowData.status} /></span> : ''}
                                </td>
                                <td>
                                    {rowData.refNumber?rowData.refNumber:'-'}
                                </td>
                                <td>
                                    <div>{rowData.expenseMasterId ? <span>{rowData.expenseMasterId + '-' +rowData.projectName }</span> : ''}</div>
                                </td>

                                    </tr>
                                )):''}
                            </tbody>
                        </table>
                    </div>

                    
                    
                    {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={businessExpensesList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onColumnResized ={(params)=>{
                                    params.api.resetRowHeights();
                                }}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                                onGridSizeChanged={onFirstDataRendered}
                            >
                            </AgGridReact>
                        </div>
                    </div> */}
                </Skeleton>
                <div className="footer">
                <div className="f-left"></div>
                   <div className="f-right">
                <Pagination
                    size="small"
                    total={businessExpensesCount}
                    showTotal={showTotal}
                    defaultPageSize={100} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    // showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber, pageSize, props)
                    }}
                />
                </div>
                </div>
            </div>
        </Fragment>
    );
};

export default injectIntl(BusinessExpensesListingComp);