import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaidInvoicesComponent from '../../../../../components/modal/modalBody/supplier/PaidInvoices';
import { resetPaginatedData, fetchPaidInvoicesCount, fetchPaidInvoices } from './action';
import { popModalFromStack, pushModalToStack } from './../../../../../actions/commonActions';

class PaidInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
            pageNumber: 1,
            searchedText: ''
        };
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            supplierId: this.props.supplierData ? this.props.supplierData.supplierId : "",
            isForSupplier: this.props.supplierData ? true : false, 
            pageNumber: 1,
            pageSize: 25
        };
        // resetPaginatedData();
        // fetchPaginationDataIfNeeded('fetchPaidInvoices', 'paidInvoiceList', this.props, payload, true);
        // fetchDataIfNeeded('fetchPaidInvoicesCount', 'paidInvoiceListCount', this.props, payload, true);
        this.props.fetchPaidInvoices(payload, {companyInfo});
        this.props.fetchPaidInvoicesCount(payload, {companyInfo});
        
    }

    render() {
        return <PaidInvoicesComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        paidInvoiceList: state.supplier.paidInvoiceList,
        paidInvoiceListCount: state.supplier.paidInvoiceListCount,
        modalLoading: state.common.modalLoading,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetPaginatedData,
    fetchPaidInvoicesCount,
    fetchPaidInvoices,
    popModalFromStack,
    pushModalToStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaidInvoices);