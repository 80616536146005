import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Popover, Menu, Pagination, Row, Col, Button } from 'antd';
import { InsertRowRightOutlined, MoreOutlined, UserOutlined } from '@ant-design/icons';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { getMomentDateForUIReadOnly, sortTable } from '../../../utils';
import find from 'lodash.find';
import { CONSTANTS, ICONS } from '../../../static/constants';
import { TextBox } from '../../general/TextBox';
import { Dropdown } from '../../general/Dropdown';
import { CustomButton } from '../../general/CustomButton';


const AttendanceIp = (props) => {

  const breadCrumbList = [
    {
      name: "Dashboard",
    },
    {
      name: <FormattedMessage id='sidebar.menuItems.employee' />,
    },
    {
      name: "Manage Ip Address",
    }
  ];
  const stickyStyle = {
    position: 'sticky',
    left: '0px',
    backgroundColor: '#ffffff',
    zIndex: 1
  }


  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {

          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>

        {e.isAccountHolder ? (
          <Menu.Item
            key="2"

          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        ) : (
          ""
        )}


      </Menu>
    );
  };

  const getDateList = (dateObj) => {
    const date = dateObj ? new Date(dateObj) : new Date();
    let dateList = [];
    let maxDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    for (let i = 1; i <= maxDate; i++) {
      dateList.push(i);
    }
    return dateList;
  };

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItems.attendanceIp"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div>

      <div className="view-container">
        <Skeleton
          loading={props.isLoading && !props.company?.employeeDrawerVisible}
        >
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="company.attendanceManagement.attendanceIp.table.heading"
                  defaultMessage=""
                />
              </div>
              {[]?.length ? (
                <>
                  <div className="vertical-sep" />
                  <div>{[]?.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">


            </div>
          </div>

          <div className='new-container'>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className='common-label'>Employee Self Attendance Status
                <span className="required">*</span>
                </div>
                <Dropdown
                  style={{ width: "30%" }}
                  placeholder="Yes user can give attendance"
                />
              </Col>

              <Col span={24}>
                <div className='common-label'>Should Check White Listed IP Address
                <span className="required">*</span>
                </div>
                <Dropdown
                  style={{ width: "30%" }}
                  placeholder="Yes user can give attendance by whitelisted APP"
                />
              </Col>
              <Col span={24}>
                <div className='common-label'>IP Address
                <span className="required">*</span>
                </div>

                <TextBox
                  style={{ width: "30%" }}

                  placeholder="121.12.123.98"

                />
              </Col>
              <Col span={24}>


                <Button className='drawer-btn' style={{ width: "12%" }}>Add More IP Addreess</Button>
              </Col>
              <Col span={24} >
                <div className='submit-btn'>
                <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
                
            }
            
            }
            key={1}
          />
                <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            // onClick={closeDrawer}
          />
       
                  {/* <Button>
                    <FormattedMessage id="button.save.label" />
                  </Button>
                  <Button>
                    <FormattedMessage id="button.cancel.label" />
                  </Button> */}
                </div>
              </Col>
            </Row>

          </div>




          {/* <div className="footer">
              <div className="f-left"></div>
              <div className="f-right">
                <Pagination
                  size="small"
                  total={30}
                  showTotal={showTotal}
                  defaultPageSize={props.pageSize}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100, 200]}
                  // showQuickJumper
                  onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                  }}
                  onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                  }}
                />
              </div>
            </div> */}
        </Skeleton>
      </div>
    </>
  );
};




export default injectIntl(AttendanceIp);
