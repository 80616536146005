import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { popModalFromStack } from './../../../../../actions/commonActions';
import TotalMailComponent from '../../../../../components/modal/modalBody/EmailMarketing/TotalMailCampaign';
import { fetchTotalMailCampaign } from './action';

class TotalMailCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pageSize: 25,
          pageNumber: 1,
     
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
           
        }
 this.props.fetchTotalMailCampaign(payload)

    }

    render() {
        return <TotalMailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
      totalMailCampaignDetails: state.communicationReducer.totalMailCampaignDetails,

    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchTotalMailCampaign,
    popModalFromStack,
   
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TotalMailCon);