import React from "react";
import { Skeleton, Calendar, Empty } from "antd";
import { formatDateForBackend, } from "../../../../../../utils";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";


const AttendenceComp = (props) => {

    const { studentAttendance = {} } = props;

    const attendenceCount = (attendanceType) => {
      const statusList = Object.values(studentAttendance || {}) || [];
      let count = 0;
      if(statusList.length){
  
        statusList.forEach(status => {
          switch (status) {
            case attendanceType:
              count++;
              break;
            default:
              break;
          }
        });
      }
      return count;
    }
    
    const presentCount = attendenceCount("Present");
    const absentCount = attendenceCount("Absent");
    const leaveCount = attendenceCount("Leave");
    const holidayCount = attendenceCount("Holiday");
  
    const chartState= {
      series: [presentCount, absentCount, leaveCount, holidayCount],
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: 'gradient',
        },
        legend: {
          formatter: function (val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
          },
          position:'bottom',
          horizontalAlign: 'left',
        },
        title: {
          text: 'Attendance Summary',
          align: 'center',  
          margin: 40, 
        },
        labels: ['Present', 'Absent', 'Leave', 'Holiday'],
        colors: ['#18CB8A', '#F85353', '#ffaa00', '#6385FF'], 
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  
    const onDateChange = (pDate) =>{
      let y = pDate.format('YYYY');
      let m = pDate.format('MM');
      props.updateState({currentMonth: m})
  
      const payload = {
        relationshipId: props.companyInfo?.relationshipId,
        classId: 0,
        startDate: formatDateForBackend(new Date(y, m, 1)),
        endDate: formatDateForBackend(new Date(y, m + 1, 0)),
        schoolStudentIdList: [Number(props.activeAttendKey)]
      };
  
      props.getSchoolAttendanceByClassId(payload);
    }
  
    const getAttendaceStatus = (date) =>{
      switch(studentAttendance[date]){
        case "Present":
          return <div style={{color: '#18CB8A'}}>P</div>
        case "Absent":
          return <div style={{color: '#F85353'}}>A</div>
        case "Leave":
          return <div style={{color: '#ffaa00'}}>L</div>
        case "Holiday":
          return <div style={{color: '#6385FF'}}>H</div>
        default:
          return <div style={{color: 'transparent'}}>T</div>
      }
    }


    return (<>
        {Object.keys(studentAttendance).length ? 
            <Skeleton loading={props.isLoading}>
              <div className="attendance_view">
                <div className="div_p1">
                  <Calendar 
                    dateFullCellRender={(e)=>{
                      return <div className="p5" style={{backgroundColor: e.format('dddd') === 'Sunday' || e.format('dddd') === 'Saturday' ? 'aliceblue':''}}>
                      <div>{e.date()}</div>
                      {/* render attendance status for current month only */}
                      {props.currentMonth === e.month() ? 
                          <div style={{color : studentAttendance[e.date()] ? '':'transparent'}}>{getAttendaceStatus(e.date()) || 'x'}</div>
                          :
                          <div>{getAttendaceStatus(100) || 'x'}</div>
                      }
                      </div>
                    }}
                    onSelect={()=>{
                    }}
                    onPanelChange={onDateChange}
                  />
                </div>
                <div className="div_p2">
                  <ReactApexChart options={chartState.options} series={chartState.series} type="pie" width={480} />
                  <div className="label">
                    <div className="attendence-label blue">{((presentCount/(presentCount+absentCount+leaveCount))*100).toFixed(2)}% Attendance</div>
                    <div className={`attendence-label ${studentAttendance[Number(moment().format('DD'))]}`}>{studentAttendance[Number(moment().format('DD'))]} Today</div>
                  </div>
                </div>
              </div>
            </Skeleton>
            :
            <Empty className="mt20"/>
        }
    </>)
}


export default AttendenceComp;

