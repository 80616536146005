import axios from 'axios';
import config from '../../../../config/environmentConfig';
// import cloneDeep from 'lodash/cloneDeep';
import { COMMON_ACTIONS } from '../../../../static/constants';
import {  showToasterMessage } from '../../../../utils';

const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

  
export const saveLandedCost = (props, payload) => {
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lcExpenseType/createExpenseType`,
          payload
        )
        .then((response) => {
          if (props) {
            props.updateHeaderState({
                landedCost: {
                  landedCostDrawer: false
              }
            });
            showToasterMessage({
              messageType: 'success',
              description: lang[((response || {}).data || {}).message] || "Saved Successfully",
            })
            props.fetchLandedCostSetupList(payload);
          }
        })
        .catch((err) => {
          if (!err.__isRetryRequest) {
            showToasterMessage({
              messageType: "error",
              description:
               
                "Some error occurred",
            });
          }
        });
    };
  };