import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Tooltip, Skeleton } from 'antd';

//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { getUserDefinedName } from '../../../../utils';
import { ICONS } from '../../../../static/constants';


function SchoolDrawer(props) {

  const { classList } = props;

  const closeDrawer = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addSectionDrawerVisible: false,
        sectionData: {}
      }
    })
  }

  const getDefaultSectionId = () =>{
    // this is to rename default section when user creates first section
    const cList = classList[1];
    let classSectionList = cList.find(obj=>obj.classId === props.classId).classSectionList;

    if((classSectionList || []).length && classSectionList[0]?.className === classSectionList[0]?.sectionName){
      return classSectionList[0].classSectionId;
    }
    return 0;
  }
 
  const handleSubmit = (props) => {
    if (props.classId && props.sectionName && props.sectionCode) {
      const payload = {
        classId: props.classId,
        className: props.className,
        sectionName: props.sectionName,
        sectionCode: props.sectionCode,
        sectionCapacity: props.sectionCapacity,
        relationshipId: (props.companyInfo || {}).relationshipId,
        createdByEmpId: (props.userInfo || {}).userId,
        classSectionId: props.sectionData?.classSectionId || getDefaultSectionId(),
        version: props.version,
      };

      props.pushModalToStack({
        modalBody: <FormattedMessage id={props.sectionData ? 'schoolManagement.school.confirmation.edit' : 'schoolManagement.school.confirmation.create'} defaultMessage='' />,
        handleSubmit: () => {
          props.saveSection(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      });
    }
  }

  return (
    <Drawer
      title={getUserDefinedName(props.sectionData?'schoolManagement.section.edit':'schoolManagement.section.new' ,props)}
      width={720}
      keyboard={false}
      zIndex={1}
      destroyOnClose={true}
      onClose={closeDrawer}
      maskClosable={false}
      className="custom-drawer"
      visible={props.schoolDrawers.addSectionDrawerVisible}
      afterVisibleChange={
        (visible) => {
          if (visible) {
            //props.initCalls();
          }
        }
      }
      footer={<>
        <CustomButton
          intlId='confirmation.cancel'
          defaultMessage=''
          type='default'
          key={0}
          style={{ marginRight: 8 }}
          onClick={closeDrawer}
        />
        <CustomButton
          intlId='confirmation.submit'
          defaultMessage=''
          htmlType='submit'
          onClick={() => handleSubmit(props)}
          key={1}
        />
      </>}
    >
        <Skeleton loading={props.listLoading} paragraph={{ rows: 20 }} active>

      <Row gutter={[16, 16]}>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('schoolManagement.class.name', props)}
            <span className="required">*</span>
          </div>
          <Dropdown
            items={ classList[1] || []}
            valueKeyName="className"
            optionKeyName="classId"
            value={props.className || null}
            placeholder={getUserDefinedName('schoolManagement.class.name', props)}
            onSelect={(optionValue, option) => {
              props.updateState({
                className: option.name,
                classId: optionValue,
              });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submittedOnce || !!props.classId }}
            message={props.intl.formatMessage(
              {
                id: 'requiredField.missing.msg',
                defaultMessage: ''
              })
            } 
          />
        </Col>

        <Col span={12}>
         
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('schoolManagement.class.section', props)}
            <span className="required">*</span>
          </div>
          <TextBox
            type='text'
            value={props.sectionName || null}
            onChange={(e) => {
              props.updateState({
                sectionName: e.target.value,
              });
            }}
            placeholder={getUserDefinedName('schoolManagement.class.section', props)}
          />
          <ErrorMsg
            validator={() => { return !props.submittedOnce || !!props.sectionName }}
            message={props.intl.formatMessage(
              {
                id: 'requiredField.missing.msg',
                defaultMessage: ''
              })
            } 
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('schoolManagement.section.code', props)}
            <span className="required">*</span>
          </div>
          <TextBox
            type='text'
            value={props.sectionCode || null}
            onChange={(e) => {
              props.updateState({
                sectionCode: e.target.value,
              });
            }}
            placeholder={getUserDefinedName('schoolManagement.section.code', props)}
          />
          <ErrorMsg
            validator={() => { return !props.submittedOnce || !!props.sectionCode }}
            message={props.intl.formatMessage(
              {
                id: 'requiredField.missing.msg',
                defaultMessage: ''
              })
            } 
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('sms.section.classCapacity', props)}
            <Tooltip
              placement="top"
              title={getUserDefinedName("sms.class.section.classCapacity.help", props)}
            >
              <i className={`cursor-pointer ml5 ${ICONS.HELP} `} />
            </Tooltip>
          </div>
          <TextBox 
            disabled={true}
            value={classList[1].find(obj => obj.classId === props.classId)?.classCapacity || '0'}
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('sms.section.sectionCapacity', props)}
            {classList[1].find(obj => obj.classId === props.classId)?.classCapacity > 0 ? <span className="required">*</span> : ''}
          </div>
       
          <TextBox
            type='text'
            value={props.sectionCapacity || null}
            onChange={(e) => {
              props.updateState({
                sectionCapacity: e.target.value,
              });
            }}
            placeholder={getUserDefinedName('sms.section.sectionCapacity', props)}
          />
           
          <ErrorMsg
            validator={() => { return !props.submittedOnce || !!props.sectionCapacity }}
            message={props.intl.formatMessage(
              {
                id: 'requiredField.missing.msg',
                defaultMessage: ''
              })
            } 
          />
        </Col>

      </Row>
      </Skeleton>
    </Drawer>
  );
}

export default injectIntl(SchoolDrawer);
