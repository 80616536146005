import { COMMON_ACTIONS, LOCAL_STORAGE_KEYS } from "../static/constants";
import { addToLocalStorage, fetchFromLocalStorage } from "../utils";
const initialState = {
  isLoading: false,
  isEmailSpinner: false,
  xeroConfigData: {},
  jobTitleList: [],
  countries: [],
  states: [],
  boRelationshipAddress: [],
  paymentTerms: [],
  industries: [],
  salutations: [],
  sources: [],
  recentTxList: [],
  businessTypes: [],
  priceList: [],
  companyEmpList: [],
  bankInfo: [],
  userInfo: {},
  companyInfo: {},
  uomList: [],
  expenseTypes: [],
  taxes: [],
  projectList: [],
  supplierSources: [],
  paymentModeList: [],
  cashEquivalentLedgers: [],
  supplierDetail: {},
  customerDetail: {},
  customerDetailUpdate: {},
  departmentList: [],
  employees: [],
  emailAddressList: [],
  allPreferences: [],
  allShippingInstruction: [],
  allTaxIdentificatinType: [],
  allRelationshipTaxIdentificatins: [],
  allQualityChecksName: [],
  incoterms: [],
  userStatus: [],
  paypalConfigData: {},
  paypalDirectConfigData: {},
  allPaymentGatewayConfig: [],
  payOnlineUrl: "",
  stripeConfigData: {},
  cardConnectConfigData: {},
  webInvoiceData: {},
  recurringInvoiceData: {},
  webInvoiceShortUrl: "",
  selectedProductList: [],
  subdomain: {},
  isSubdomainExist: false,
  expenseLedgerList: [],
  isAlifApp: false,
  companyAdditionalInfo: {},
  xeroContactList: [],
  xeroLedgerList: [],
  xeroTaxList: [],
  jobRoleList: [],
  schoolSessionList: [],
  defaultSchoolForm: 0,
  empTypeList: [],
  assignmentGroupList: [],
  educationList: [],
  store_values: [],
  supplierImportHeader: [],
  customerImportHeader: [],
  headerValues: [],
  headerMandatoryValues: [],
  productImportHeader: [],
  contactEducationList: [],
  showSkeleton: false,
  listLoading: 0,
  headerLoading: false,
  drawerLoading: false,
  modalLoading: false,
  modal1Loading: false,
  modal2Loading: false,
  modal3Loading: false,
  createLoading: false,
  saveLoading: false,
  pricingPlans:[],
  userLocation: {},
  plansLoading: false,
  detailLoading: 0,
  variantImage:{}, 
  isTaxesLoaded: false,
  planIndustries: [],
  alpideProducts: [],
  subscriptionPlans: [],
  subcriptionAddons: [],
  isProductLoaded:false,
  razorpayPaymentOrderDetail: {},
  subscribedProduct:[]
};

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMON_ACTIONS.SHOW_LOADER:
      return { ...state, isLoading: true };
    case COMMON_ACTIONS.HIDE_LOADER:
      return { ...state, isLoading: false };
    case COMMON_ACTIONS.SHOW_SKELETON:
      return { ...state, showSkeleton: true };
    case COMMON_ACTIONS.HIDE_SKELETON:
      return { ...state, showSkeleton: false };
    case COMMON_ACTIONS.SHOW_LIST_LOADER:
      return { ...state, listLoading: state.listLoading + 1 };
    case COMMON_ACTIONS.HIDE_LIST_LOADER:
      return { ...state, listLoading: state.listLoading === 0 ? 0 : state.listLoading - 1 };
    case COMMON_ACTIONS.SHOW_HEADER_LOADER:
      return { ...state, headerLoading: true };
    case COMMON_ACTIONS.HIDE_HEADER_LOADER:
      return { ...state, headerLoading: false };
    case COMMON_ACTIONS.SHOW_DRAWER_LOADER:
      return { ...state, drawerLoading: true };
    case COMMON_ACTIONS.HIDE_DRAWER_LOADER:
      return { ...state, drawerLoading: false };
    case COMMON_ACTIONS.SHOW_MODAL_LOADER:
      return { ...state, modalLoading: true };
    case COMMON_ACTIONS.HIDE_MODAL_LOADER:
      return { ...state, modalLoading: false };
    case COMMON_ACTIONS.SHOW_MODAL1_LOADER:
      return { ...state, modal1Loading: true };
    case COMMON_ACTIONS.HIDE_MODAL1_LOADER:
      return { ...state, modal1Loading: false };
    case COMMON_ACTIONS.SHOW_MODAL2_LOADER:
      return { ...state, modal2Loading: true };
    case COMMON_ACTIONS.HIDE_MODAL2_LOADER:
      return { ...state, modal2Loading: false };
    case COMMON_ACTIONS.SHOW_MODAL3_LOADER:
      return { ...state, modal3Loading: true };
    case COMMON_ACTIONS.HIDE_MODAL3_LOADER:
      return { ...state, modal3Loading: false };
    case COMMON_ACTIONS.SHOW_CREATE_LOADER:
      return { ...state, modalLoading: true };
    case COMMON_ACTIONS.HIDE_CREATE_LOADER:
      return { ...state, modalLoading: false };
    case COMMON_ACTIONS.SHOW_SAVE_LOADER:
      return { ...state, saveLoading: true }
    case COMMON_ACTIONS.HIDE_SAVE_LOADER:
      return { ...state, saveLoading: false };
    case COMMON_ACTIONS.SHOW_DETAIL_LOADER:
      return { ...state, detailLoading: state.detailLoading + 1 }
    case COMMON_ACTIONS.HIDE_DETAIL_LOADER:
      return { ...state, detailLoading: state.detailLoading === 0 ? 0 : state.detailLoading - 1 };
    case COMMON_ACTIONS.SHOW_LOADER_EMAIL:
      return { ...state, isEmailSpinner: true };
    case COMMON_ACTIONS.HIDE_LOADER_EMAIL:
      return { ...state, isEmailSpinner: false };
    case COMMON_ACTIONS.PAYMENT_LIST:
      return { ...state, paymentTerms: action.data };
    case COMMON_ACTIONS.XERO_CONFIG_RELATIONSHIP_DATA:
      return { ...state, xeroConfigData: action.data };
    case COMMON_ACTIONS.PAYMENT_LIST_ADDITION:
      return { ...state, paymentTerms: [action.data, ...state.paymentTerms] };
    case COMMON_ACTIONS.JOB_TITLE_LIST:
      return { ...state, jobTitleList: action.data };
    case COMMON_ACTIONS.JOB_TITLE_LIST_ADDITION:
      return { ...state, jobTitleList: [action.data, ...state.jobTitleList] };
    case COMMON_ACTIONS.JOB_TITLE_LIST_DELETION:
      return {
        ...state,
        jobTitleList: state.jobTitleList.filter(
          (x) => x.jobTitleId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.RECENT_TX_LIST:
      return { ...state, recentTxList: action.data };
    case COMMON_ACTIONS.PAYMENT_LIST_DELETION:
      return {
        ...state,
        paymentTerms: state.paymentTerms.filter(
          (x) => x.paymentTermId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.INDUSTRY_LIST:
      return { ...state, industries: action.data };
    case COMMON_ACTIONS.INDUSTRY_LIST_ADDITION:
      return { ...state, industries: [action.data, ...state.industries] };
    case COMMON_ACTIONS.INDUSTRY_LIST_DELETION:
      return {
        ...state,
        industries: state.industries.filter(
          (x) => x.businessIndustryId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.SALUTATION_LIST:
      return { ...state, salutations: action.data };
    case COMMON_ACTIONS.SALUTATION_LIST_ADDITION:
      return { ...state, salutations: [action.data, ...state.salutations] };
    case COMMON_ACTIONS.SALUTATION_LIST_DELETION:
      return {
        ...state,
        salutations: state.salutations.filter(
          (x) => x.salutationId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.COUNTRIES_LIST:
      return { ...state, countries: action.data };
    case COMMON_ACTIONS.STATES_LIST:
      return { ...state, states: action.data };
    case COMMON_ACTIONS.SOURCE_LIST:
      return { ...state, sources: action.data };
    case COMMON_ACTIONS.SOURCE_LIST_ADDITION:
      return { ...state, sources: [action.data, ...state.sources] };
    case COMMON_ACTIONS.SOURCE_LIST_DELETION:
      return {
        ...state,
        sources: state.sources.filter(
          (x) => x.customerSourceId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.BUSINESS_TYPE_LIST:
      return { ...state, businessTypes: action.data };
    case COMMON_ACTIONS.BUSINESS_TYPE_LIST_ADDITION:
      return { ...state, businessTypes: [action.data, ...state.businessTypes] };
    case COMMON_ACTIONS.BUSINESS_TYPE_LIST_DELETION:
      return {
        ...state,
        businessTypes: state.businessTypes.filter(
          (x) => x.businessTypeId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.PRICE_LIST:
      return { ...state, priceList: action.data };
    case COMMON_ACTIONS.PRICE_LIST_ADDITION:
      return { ...state, priceList: [action.data, ...state.priceList] };
    case COMMON_ACTIONS.PRICE_LIST_DELETION:
      return {
        ...state,
        priceList: state.priceList.filter(
          (x) => x.priceListId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO:
      return { ...state, companyInfo: action.data };
    case COMMON_ACTIONS.LOGGEDIN_USER_INFO:
      return { ...state, userInfo: action.data };
    case COMMON_ACTIONS.LOGGEDIN_USER_BANK_INFO:
      return { ...state, bankInfo: action.data };
    case COMMON_ACTIONS.LOGGEDIN_COMPANY_EMP_LIST:
      return { ...state, companyEmpList: action.data };
    case COMMON_ACTIONS.UOM_LIST:
      return { ...state, uomList: action.data };
    case COMMON_ACTIONS.EMAIL_ADDRESS_LIST:
      return { ...state, emailAddressList: action.data };
    case COMMON_ACTIONS.UOM_LIST_ADDITION:
      return { ...state, uomList: [action.data, ...state.uomList] };
    case COMMON_ACTIONS.UOM_LIST_DELETION:
      return {
        ...state,
        uomList: state.uomList.filter((x) => x.uomId !== action.data.id),
      };
    case COMMON_ACTIONS.TAX_LIST:
      return { ...state, taxes: (action.data || []).length ? action.data : [], isTaxesLoaded: true };
    case COMMON_ACTIONS.TAX_LIST_ADDITION:
      return { ...state, taxes: [action.data, ...state.taxes] };
    case COMMON_ACTIONS.TAX_LIST_DELETION:
      return {
        ...state,
        taxes: state.taxes.filter((x) => x.taxSingleRateId !== action.data.id),
      };
    case COMMON_ACTIONS.EXPENSE_LIST:
      return { ...state, expenseTypes: action.data };
    case COMMON_ACTIONS.EXPENSE_LIST_ADDITION:
      return { ...state, expenseTypes: [action.data, ...state.expenseTypes] };
    case COMMON_ACTIONS.EXPENSE_LIST_DELETION:
      return {
        ...state,
        expenseTypes: state.expenseTypes.filter(
          (x) => x.lkExpenseTypeId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.PROJECT_LIST:
      return { ...state, projectList: action.data };
    case COMMON_ACTIONS.PROJECT_LIST_ADDITION:
      return { ...state, projectList: [action.data, ...state.projectList] };
    case COMMON_ACTIONS.PROJECT_LIST_DELETION:
      return {
        ...state,
        projectList: state.projectList.filter(
          (x) => x.projectMasterId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.DOCUMENT_LIST:
      return { ...state, documentList: action.data };
    case COMMON_ACTIONS.DOCUMENT_LIST_ADDITION:
      return { ...state, documentList: [action.data, ...state.documentList] };
    case COMMON_ACTIONS.DOCUMENT_LIST_DELETION:
      return {
        ...state,
        documentList: state.documentList.filter(
          (x) => x.documentNameId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.SUPPLIER_SOURCE_LIST:
      return { ...state, supplierSources: action.data };
    case COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_ADDITION:
      return {
        ...state,
        supplierSources: [action.data, ...state.supplierSources],
      };
    case COMMON_ACTIONS.SUPPLIER_SOURCE_LIST_DELETION:
      return {
        ...state,
        supplierSources: state.supplierSources.filter(
          (x) => x.supplierSourceId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.LOG_OUT:
      return {};
    case COMMON_ACTIONS.PAYMENT_MODE_LIST:
      return { ...state, paymentModeList: action.data };
    case COMMON_ACTIONS.CASH_EQUIVALENT_LEDGER:
      return { ...state, cashEquivalentLedgers: action.data };
    case COMMON_ACTIONS.SUPPLIER_DETAIL:
      return { ...state, supplierDetail: action.data };
    case COMMON_ACTIONS.SUPPLIER_DETAIL_RESET:
      return { ...state, supplierDetail: {} };
    case COMMON_ACTIONS.CUSTOMER_DETAIL_UPDATE:
      return { ...state, customerDetailUpdate: action.data };
    case COMMON_ACTIONS.CUSTOMER_DETAIL_UPDATE_RESET:
      return { ...state, customerDetailUpdate: {} };
    case COMMON_ACTIONS.CUSTOMER_DETAIL:
      return { ...state, customerDetail: action.data };
    case COMMON_ACTIONS.CUSTOMER_DETAIL_RESET:
      return { ...state, customerDetail: {} };
    case COMMON_ACTIONS.DEPARTMENT_LIST:
      return { ...state, departmentList: action.data };
    case COMMON_ACTIONS.DEPARTMENT_LIST_ADDITION:
      return {
        ...state,
        departmentList: [action.data, ...state.departmentList],
      };
    case COMMON_ACTIONS.DEPARTMENT_LIST_DELETION:
      return {
        ...state,
        departmentList: state.departmentList.filter(
          (x) => x.departmentId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.EMPLOYEES_LIST:
      return { ...state, employees: action.data };
    case COMMON_ACTIONS.STORE_SETTING_VALUES:
      return { ...state, store_values: action.data };
    case COMMON_ACTIONS.EMPLOYEES_LIST_ADDITION:
      return { ...state, employees: [action.data, ...state.employees] };
    case COMMON_ACTIONS.EMPLOYEES_LIST_DELETION:
      return {
        ...state,
        employees: state.employees.filter(
          (x) => x.relationshipEmployeeId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.BO_LOCATION_ADDITION: {
      let boLocationList = Object.assign(
        [],
        state.companyInfo.boLocationList || []
      );
      boLocationList = boLocationList.filter(
        (x) => x.locationId !== action.data.locationId
      );
      boLocationList.push(action.data);
      const entityData = fetchFromLocalStorage(
        LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA
      )
        ? JSON.parse(
          fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)
        )
        : {};
      entityData.defaultCompany = {
        ...state.companyInfo,
        boLocationList: boLocationList,
      };
      addToLocalStorage(
        LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA,
        JSON.stringify(entityData)
      );
      return { ...state, companyInfo: entityData.defaultCompany };
    }
    case COMMON_ACTIONS.BO_RELATIONSHIP_ADDRESS:
      const entityData = fetchFromLocalStorage(
        LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA
      )
        ? JSON.parse(
          fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)
        )
        : {};
      let boLocationList = Object.assign(
        [],
        state.companyInfo.boLocationList || []
      );
      boLocationList = boLocationList.filter(
        (x) =>
          x.locationType !== "RelationshipShippingAddress" &&
          x.locationType !== "RelationshipBillingAddress"
      );
      boLocationList = boLocationList.concat(action.data);
      entityData.defaultCompany = {
        ...state.companyInfo,
        boLocationList: boLocationList,
      };
      addToLocalStorage(
        LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA,
        JSON.stringify(entityData)
      );
      return { ...state, boRelationshipAddress: action.data };
    case COMMON_ACTIONS.BO_RELATIONSHIP_ADDRESS_RESET:
      return { ...state, boRelationshipAddress: [] };
    case COMMON_ACTIONS.ADDRESS_DELETION:
    case COMMON_ACTIONS.BO_LOCATION_DELETION: {
      let boLocationList = Object.assign(
        [],
        state.companyInfo.boLocationList || []
      );
      boLocationList = boLocationList.filter(
        (x) => x.locationId !== action.data.id
      );
      const entityData = fetchFromLocalStorage(
        LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA
      )
        ? JSON.parse(
          fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)
        )
        : {};
      entityData.defaultCompany = {
        ...state.companyInfo,
        boLocationList: boLocationList,
      };
      addToLocalStorage(
        LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA,
        JSON.stringify(entityData)
      );
      return { ...state, companyInfo: entityData.defaultCompany };
    }
    case COMMON_ACTIONS.USER_PREFERENCES: {
      return {
        ...state,
        allPreferences: (action.data || []).length ? action.data : [],
      };
    }
    case COMMON_ACTIONS.USER_PREFERENCES_FAIL: {
      return { ...state, allPreferences: [{}] };
    }
    case COMMON_ACTIONS.SHIPPING_INSTRUCTION_LIST:
      return { ...state, allShippingInstruction: action.data };
    case COMMON_ACTIONS.SHIPPING_INSTRUCTION_ADDITION:
      return {
        ...state,
        allShippingInstruction: [action.data, ...state.allShippingInstruction],
      };
    case COMMON_ACTIONS.SHIPPING_INSTRUCTION_DELETION:
      return {
        ...state,
        allShippingInstruction: state.allShippingInstruction.filter(
          (x) => x.shippingInstructionId !== action.data.id
        ),
      };

    case COMMON_ACTIONS.TAX_IDENTIFICATION_LIST:
      return { ...state, allTaxIdentificatinType: action.data };
    case COMMON_ACTIONS.TAX_IDENTIFICATION_ADDITION:
      return {
        ...state,
        allTaxIdentificatinType: [
          action.data,
          ...state.allTaxIdentificatinType,
        ],
      };
    case COMMON_ACTIONS.TAX_IDENTIFICATION_DELETION:
      return {
        ...state,
        allTaxIdentificatinType: state.allTaxIdentificatinType.filter(
          (x) => x.taxIdentificationId !== action.data.id
        ),
      };

    case COMMON_ACTIONS.REL_TAX_IDENTIFICATION_LIST:
      return { ...state, allRelationshipTaxIdentificatins: action.data };
    case COMMON_ACTIONS.REL_TAX_IDENTIFICATION_ADDITION:
      return {
        ...state,
        allRelationshipTaxIdentificatins: [
          action.data,
          ...state.allRelationshipTaxIdentificatins,
        ],
      };
    case COMMON_ACTIONS.REL_TAX_IDENTIFICATION_DELETION:
      return {
        ...state,
        allRelationshipTaxIdentificatins:
          state.allRelationshipTaxIdentificatins.filter(
            (x) => x.relationshipTaxIdentificationId !== action.data.id
          ),
      };

    case COMMON_ACTIONS.QUALITY_CHECK_NAME:
      return { ...state, allQualityChecksName: action.data };
    case COMMON_ACTIONS.QUALITY_CHECK_NAME_ADDITION:
      return {
        ...state,
        allQualityChecksName: [action.data, ...state.allQualityChecksName],
      };
    case COMMON_ACTIONS.QUALITY_CHECK_NAME_DELETION:
      return {
        ...state,
        allQualityChecksName: state.allQualityChecksName.filter(
          (x) => x.qualityChecklistId !== action.data.id
        ),
      };

    case COMMON_ACTIONS.INCOTERM_LIST:
      return { ...state, incoterms: action.data };
    case COMMON_ACTIONS.INCOTERM_LIST_ADDITION:
      return { ...state, incoterms: [action.data, ...state.incoterms] };
    case COMMON_ACTIONS.INCOTERM_LIST_DELETION:
      return {
        ...state,
        incoterms: state.incoterms.filter(
          (x) => x.inctermId !== action.data.id
        ),
      };

    case COMMON_ACTIONS.USER_STATUS_LIST:
      return { ...state, userStatus: action.data };
    case COMMON_ACTIONS.USER_STATUS_LIST_ADDITION:
      return { ...state, userStatus: [action.data, ...state.userStatus] };
    case COMMON_ACTIONS.USER_STATUS_LIST_RESET:
      return { ...state, userStatus: initialState.userStatus };
    case COMMON_ACTIONS.PAYPAL_CONFIG_RELATIONSHIP_DATA:
      return { ...state, paypalConfigData: action.data };
    case COMMON_ACTIONS.STRIPE_CONFIG_RELATIONSHIP_DATA:
      return { ...state, stripeConfigData: action.data };

    case COMMON_ACTIONS.CARD_CONNECT_CONFIG_RELATIONSHIP_DATA:
      return { ...state, cardConnectConfigData: action.data };
    case COMMON_ACTIONS.PAYPAL_DIRECT_CONFIG_RELATIONSHIP_DATA:
      return { ...state, paypalDirectConfigData: action.data };
    case COMMON_ACTIONS.ALL_PAYMENT_GATEWAY_CONFIG:
      return { ...state, allPaymentGatewayConfig: action.data };
    case COMMON_ACTIONS.PAY_ONLINE_URL:
      return { ...state, payOnlineUrl: action.data };
    case COMMON_ACTIONS.RESET_PAY_ONLINE_URL:
      return { ...state, payOnlineUrl: initialState.payOnlineUrl };
    case COMMON_ACTIONS.WEB_INVOICE_DATA:
      return { ...state, webInvoiceData: action.data };
    case COMMON_ACTIONS.RECURRING_INVOICE_DATA:
      return { ...state, recurringInvoiceData: action.data };
    case COMMON_ACTIONS.WEB_INVOICE_SHORT_URL:
      return { ...state, webInvoiceShortUrl: action.data };
    case COMMON_ACTIONS.RESET_WEB_INVOICE_SHORT_URL:
      return { ...state, webInvoiceShortUrl: initialState.webInvoiceShortUrl };
    case COMMON_ACTIONS.SELECTED_PRODUCT_LIST:
      return { ...state, selectedProductList: action.data };
    case COMMON_ACTIONS.SUBDOMAIN:
      return { ...state, subdomain: action.data };
    case COMMON_ACTIONS.IS_SUBDAMIAN_EXIST:
      return { ...state, isSubdomainExist: action.data };
    case COMMON_ACTIONS.EXPENSE_LEDGER_LIST:
      return { ...state, expenseLedgerList: action.data };
    case COMMON_ACTIONS.IS_ALIF_APP:
      return { ...state, isAlifApp: action.data };
    case COMMON_ACTIONS.CLIENT_REL_ADDITIONAL_INFO:
      return { ...state, companyAdditionalInfo: action.data };
    case COMMON_ACTIONS.XERO_CONTACT_LIST:
      return { ...state, xeroContactList: action.data };
    case COMMON_ACTIONS.XERO_LEDGER_LIST:
      return { ...state, xeroLedgerList: action.data };
    case COMMON_ACTIONS.XERO_TAX_LIST:
      return { ...state, xeroTaxList: action.data };
    case COMMON_ACTIONS.JOB_ROLES_LIST:
      return { ...state, jobRoleList: action.data };
    case COMMON_ACTIONS.JOB_ROLES_LIST_ADDITION:
      return { ...state, jobRoleList: [action.data, ...state.jobRoleList] };
    case COMMON_ACTIONS.JOB_ROLES_LIST_DELETION:
      return {
        ...state,
        jobRoleList: state.jobRoleList.filter(
          (x) => x.jobRoleId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.SCHOOL_SESSION_LIST:
      return { ...state, schoolSessionList: action.data };
    case COMMON_ACTIONS.SCHOOL_SESSION_LIST_ADDITION:
      return {
        ...state,
        schoolSessionList: [action.data, ...state.schoolSessionList],
      };
    case COMMON_ACTIONS.SCHOOL_SESSION_LIST_DELETION:
      return {
        ...state,
        schoolSessionList: state.schoolSessionList.filter(
          (x) => x.schoolSessionId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.DEFAULT_SCHOOL_FORM: {
      const entityData = fetchFromLocalStorage(
        LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA
      )
        ? JSON.parse(
          fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)
        )
        : {};
      entityData.userInfo = {
        ...entityData.userInfo,
        formId: action.data,
      };
      addToLocalStorage(
        LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA,
        JSON.stringify(entityData)
      );
      addToLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_SCHOOL_FORM, action.data);
      return { ...state, defaultSchoolForm: action.data };
    }

    case COMMON_ACTIONS.EMP_TYPE_LIST:
      return { ...state, empTypeList: action.data };
    case COMMON_ACTIONS.EMP_TYPE_LIST_ADDITION:
      return { ...state, empTypeList: [action.data, ...state.empTypeList] };
    // case COMMON_ACTIONS.EMPLOYEES_LIST_DELETION:
    //   return { ...state, empTypeList: state.empTypeList.filter(x => x.empTypeId !== action.data.id) }
    case COMMON_ACTIONS.ASSIGNMENTS_GROUP_LIST:
      return { ...state, assignmentGroupList: action.data };
    case COMMON_ACTIONS.ASSIGNMENTS_GROUP_ADDITION:
      return {
        ...state,
        assignmentGroupList: [action.data, ...state.assignmentGroupList],
      };
    case COMMON_ACTIONS.ASSIGNMENTS_GROUP_DELETION:
      return {
        ...state,
        assignmentGroupList: state.assignmentGroupList.filter(
          (x) => x.assignmentGroupId !== action.data.id
        ),
      };
    case COMMON_ACTIONS.STUDENT_EDUCATION_LIST:
      return { ...state, educationList: action.data };
    case COMMON_ACTIONS.SUPPLIER_IMPORT_HEADER:
      return { ...state, supplierImportHeader: action.data,headerValues: action.data?.map((item) => {
        return item.columnName
      }), headerMandatoryValues: action.data?.map((item) => {
        if (item.mandatory) {
          return true
        } else {
          return false
        }
      }) };
    case COMMON_ACTIONS.CUSTOMER_IMPORT_HEADER:
      return {
        ...state, customerImportHeader: action.data, headerValues: action.data?.map((item) => {
          return item.columnName
        }), headerMandatoryValues: action.data?.map((item) => {
          if (item.mandatory) {
            return true
          } else {
            return false
          }
        })
      };
    case COMMON_ACTIONS.PRODUCT_IMPORT_HEADER:
      return { ...state, productImportHeader: action.data, headerValues: action.data?.map((item) => {
        return item.columnName
      }), headerMandatoryValues: action.data?.map((item) => {
        if (item.mandatory) {
          return true
        } else {
          return false
        }
      })};
    case COMMON_ACTIONS.CONTACT_EDUCATION_LIST:
      return { ...state, contactEducationList: action.data };
    case COMMON_ACTIONS.PRICING_PLANS_LIST:
      return { ...state, pricingPlans: action.data, plansLoading: false };
    case COMMON_ACTIONS.USER_LOCATION:
      return { ...state, userLocation: action.data };
    case COMMON_ACTIONS.VARIANT_IMAGE_UPLOAD:
      return { ...state, variantImage: action.data };
    case COMMON_ACTIONS.PLAN_INDUSTRY_LIST:
      return { ...state, planIndustries: action.data };
    case COMMON_ACTIONS.SHOW_PLAN_LOADER:
      return { ...state, planLoading: true };
    case COMMON_ACTIONS.HIDE_PLAN_LOADER:
      return { ...state, planLoading: false };
    case COMMON_ACTIONS.ALPIDE_PRODUCT_LIST:
      return { ...state, alpideProducts: action.data };
    case COMMON_ACTIONS.SUBCRIPTION_PLAN_LIST:
      return { ...state, subscriptionPlans: action.data };
    case COMMON_ACTIONS.SUBCRIPTION_ADDON_LIST:
      return { ...state, subscriptionAddons: action.data };
      
      case COMMON_ACTIONS.PRODUCT_LIST:
        return { ...state, product: (action.data || []).length ? action.data : [], isProductLoaded: true };
      case COMMON_ACTIONS.RAZORPAY_PAYMENT_ORDER:
        return { ...state, razorpayPaymentOrderDetail: action.data };
        case COMMON_ACTIONS.SUBSCRIBED_PRODUCT:
          return { ...state, subscribedProduct: action.data };
        
    default:
      return state;
  }
};

export default CommonReducer;
