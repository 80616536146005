import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PODetailsComponent from '../../../../../components/modal/modalBody/supplier/PODetail';
import { fetchPurchasedOrderDetail, approvePO, uploadStamp, deleteStamp } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchSupplierDetailsBySupplierId } from '../../../../../actions/commonActions';
import { fetchPurchaseOrders, getPurchaseOrdersCount, deletePurchaseOrder, updateUserStatus } from '../../../../supplier/purchaseOrder/Listing/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';
import { deleteAttectedFile } from '../../../../../actions/commonActions';
import { deleteDocument } from '../../../../supplier/purchaseOrder/StandardPurchaseOrder/action';
class PurchaseOrderDetail extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, supplierId: this.props.poPayload.supplierId, poMasterId: this.props.poPayload.poMasterId };
        fetchDataIfNeeded('fetchPurchasedOrderDetail', 'poData', this.props, payload, true);
        fetchDataIfNeeded('fetchSupplierDetailsBySupplierId', 'supplierData', this.props, payload, true);

    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <PODetailsComponent {...this.props} {...this.state} updateModalState={(data) => { this.setState(data) }} updateState={(data) => { this.setState(data) }}/>
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        poData: state.purchaseOrder.purchaseOrderDetail,
        supplierData:  state.common.supplierDetail,
        userInfo: state.common.userInfo,
        detailLoading: state.common.detailLoading
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPurchasedOrderDetail,
    fetchSupplierDetailsBySupplierId,
    downloadFileFromBucket,
    generatePDF, printPDF,
    approvePO,
    fetchPurchaseOrders, 
    getPurchaseOrdersCount,
    uploadStamp, 
    deleteStamp,
    deletePurchaseOrder,
    updateUserStatus,
    deleteAttectedFile,
    deleteDocument
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderDetail);