import React from "react";
import { Row, Col } from 'antd';
import { getAgeFromDOB } from "../../../../../utils";

const LeadProperties = (props) => {
    const {leadData} = props;
    return(
        <div className='details'>

        <Row gutter={[16, 8]}>
            <Col span={12}>
                Form Name
                <div className="light-txt">{leadData.formName}</div>
            </Col>

            <Col span={12}>
                Lead Source
                <div className="light-txt">{leadData.leadSourceName}</div>
            </Col>

            <Col span={12}>
                Lead Status
                <div className="light-txt">{leadData.statusName}</div>
            </Col>

            <Col span={12}>
                Lead Age
                <div className="light-txt">{leadData.dateCreated ? getAgeFromDOB(leadData.dateCreated) : '-'}</div>
            </Col>
        </Row>
    </div>    )
}
export default LeadProperties;

