import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InvoicesComponent from '../../../../../components/modal/modalBody/customer/AllInvoicesByLedger';
import { fetchSalesInvoicesByFilter, getSalesInvoicesCountByFilter,  resetPaginatedData } from './action';
import { popModalFromStack, showModal, hideModal, pushModalToStack } from './../../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from '../../../../../utils';

class AllInvoicesByLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 100,
            pageNumber: 1,
            searchedText: ''
        };
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            customerId: this.props.customerId,
            pageNumber: 1,
            pageSize: 100,
            filters: {
                customerId: this.props.customerId,
                ledgerAccountId: this.props.ledgerAccountId
            }
        };
        resetPaginatedData();
        fetchPaginationDataIfNeeded('fetchSalesInvoicesByFilter', 'salesInvoiceList', this.props, payload, true);
        fetchDataIfNeeded('getSalesInvoicesCountByFilter', 'salesInvoiceCount', this.props, payload, true);
    }

    render() {
        return <InvoicesComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        salesInvoiceList: state.salesInvoice.salesInvoiceList,
        salesInvoiceCount: state.salesInvoice.salesInvoiceCount,
        permissions: state.auth.permissions,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    popModalFromStack,
    pushModalToStack,
    showModal,
    hideModal,
    fetchSalesInvoicesByFilter,
    getSalesInvoicesCountByFilter,
    resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllInvoicesByLedger);