import React from 'react';
import { injectIntl } from 'react-intl';
import * as find from 'lodash.find';
import { Row, Col } from 'antd';
import { TextBox } from '../../../../general/TextBox';
import { CustomButton } from '../../../../general/CustomButton';
import { Dropdown } from '../../../../general/Dropdown';
import { ErrorMsg } from '../../../../general/ErrorMessage';
import ReserveLedgerAccounts from '../../../../../containers/modal/modalBody/finance/LedgerAccount/ReservedLedger';
import { showToasterMessage } from '../../../../../utils';



const CategoryComponent = (props) => {
const rowgutter =[24,16];
    return (
        <div>
            <Row gutter={rowgutter}>
                <Col span={12}>
                <div className="i-lable">Category
                    <span className="required">*</span></div>
                <TextBox
                    type='text'
                    value={props.categoryName}
                    onChange={(e) => {
                        props.updateState({
                            categoryName: e.target.value
                        });
                    }}
                    onBlur ={()=>{
                        const callBack = (value)=>{
                            props.updateState({
                              isDuplicateName:value
                            })
                          }
                          let needToCheck = true
        
                          if(props.isUpdate){
                            needToCheck = props.preItemName == props.categoryName ? false :true
                          }
                          if(needToCheck){
                            props.isDuplicateCategory({categoryName:props.categoryName,relationshipId:props.companyInfo.relationshipId},callBack,props)
                          }
                    }}
                    placeholder={'Enter category Name'}
                />
                <ErrorMsg
                    validator={() => { return !props.submittedOnceCategory || !!props.categoryName }}
                    message={"Category name is required"} />
                </Col>
                <Col span={12}>
                <div className="i-lable">
                    Description
                </div>
                <TextBox
                    type='text'
                    value={props.categoryDescription}
                    onChange={(e) => {
                        props.updateState({
                            categoryDescription: e.target.value
                        });
                    }}
                    placeholder={'Enter Description'}
                />
                </Col>
                <Col span={12}>
                    <div className="i-lable">
                    Starting Account No.
                        <span className="required">*</span>
                    </div>
                    <TextBox
                        type='number'
                        value={props.startingAccountNumber}
                        onChange={(e) => {
                            props.updateState({
                                startingAccountNumber: e.target.value
                            });
                        }}
                        placeholder={'Enter Starting Number'}
                    />
                    <div className="cursor-pointer" onClick={
                        () => {
                            const modalData = {
                                modalBody: <ReserveLedgerAccounts {...props} />,
                                title: <div>
                                    <div className="ant-modal-title">Reserved Account Numbers </div>
                                    <div className="ant-modal-subtitle extra-sm"> This table lists down all reserved account numbers</div>
                                </div>,
                                width: 800,
                                handleSubmit: () => {
                                    props.popModalFromStack();
                                    props.createCategory(props);
                                }
                            }
                            props.pushModalToStack(modalData);
                        }
                    }>Show reserved account numbers</div>
                    <ErrorMsg
                        validator={() => { return !props.submittedOnceCategory || !!props.startingAccountNumber }}
                        message={"Starting number is required"} />
                </Col>
                <Col span={12}>
                    <div className="i-lable">
                    Ending Account No.
                        <span className="required">*</span>
                    </div>
                    <TextBox
                        type='number'
                        value={props.endingAccountNumber}
                        onChange={(e) => {
                            props.updateState({
                                endingAccountNumber: e.target.value
                            });
                        }}
                        placeholder={'Enter Ending Account Number'}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submittedOnceCategory || !!props.endingAccountNumber }}
                        message={"Ending number is required"} />
                </Col>
                <Col span={12}>
                    <div className="i-lable">
                    Category Group
                            <span className="required">*</span>
                    </div>
                        <Dropdown
                            items={props.allCategoryGroup || []}
                            valueKeyName='categoryGroupName'
                            optionKeyName='chartOfAccountCategoryGroupId'
                            value={props.chartOfAccountCategoryGroupId}
                            placeholder={'Select Category Group'}
                            onSelect={
                                (chartOfAccountCategoryGroupId) => {
                                    const selectedLedger = find(props.allCategoryGroup, { chartOfAccountCategoryGroupId });
                                    props.updateState({
                                        chartOfAccountCategoryGroupId,
                                        categoryGroupName: selectedLedger.categoryGroupName
                                    });
                                }
                            }
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnceCategory || !!props.chartOfAccountCategoryGroupId }}
                            message={"Category group is required"} />
                    </Col>
            </Row>
               
            <br />
            <br />
            <br />
            <hr />
            <br />
            <div key={0} className='customModal-button-group'>
                <CustomButton
                    intlId={'confirmation.cancel'}
                    defaultMessage={''}
                    type={'default'}
                    key={1}
                    onClick={() => {
                        props.hideModal();
                    }}
                />
                <CustomButton
                    intlId={'button.create.label'}
                    defaultMessage={''}
                    htmlType='submit'
                    key={2}
                    onClick={() => {

                        if (props.categoryName && props.startingAccountNumber && props.endingAccountNumber
                            && props.chartOfAccountCategoryGroupId) {
                            const modalData = {
                                modalBody: "Are you sure you want to create category ?",
                                handleSubmit: () => {
                                    if(!props.isDuplicateName){

                                        props.hideModal();
                                        props.popModalFromStack();
                                        props.createCategory(props);
                                    }else{
                                        showToasterMessage({description:"Duplicate Category Name"})
                                    }
                                }
                            };
                            props.pushModalToStack(modalData);
                        } else {
                            props.updateState({ submittedOnceCategory: true });
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default injectIntl(CategoryComponent);
