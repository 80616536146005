import React, { Fragment } from "react";
import LoadingIcon from "../../../../assets/images/loading.gif";
import { injectIntl } from "react-intl";
const Header = (props) => {
  return (
    <Fragment>
      <div className="left-itms">
        <ul>
          <li className="title">Stock Transfer</li>
          <li className="sep" />
          <li>
            Transfer # &nbsp; - &nbsp;{" "}
            {true ? (
              <span>
                {`ST# - ${
                  props.stockTransferList[props.pageNumber]?.length + 1
                }`}
              </span>
            ) : (
              //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
              <img src={LoadingIcon} alt="Invoice" style={{ height: "50px" }} />
            )}
          </li>
        </ul>
      </div>
      <div className="right-itms"></div>
    </Fragment>
  );
};
const getFormatedNextTxNumber = (props) => {
  // const { customer } = props;
  // if (!customer || !customer.customerId) {
  //   return <span>{props.nextTxNumber}</span>;
  // }
  // return <span>{props.nextTxNumber.replace('SO-00', 'SO-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
  return (
    <span>{`ST # -${
      props.stockTransferList[props.pageNumber]?.length + 1
    }`}</span>
  );
};

export default injectIntl(Header);
