import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SALES_COMPLAINT_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage, getCurrentDateForBackend } from '../../../utils';
import { fetchSalesComplaints, getSalesComplaintCount, resetPaginatedData,fetchComplaints,getComplaintsCount } from '../../customer/salesComplaint/Listing/action';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSalesComplaintTypes = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesComplaintType/getAllSalesComplaintTypes?relationshipId=${payload.relationshipId}&pageSize=100&pageNumber=0`)
      .then(res => {
        dispatch({ type: SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_TYPE_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = COMMON_ACTIONS.SALES_COMPLAINT_TYPE_LIST;
        }
      })
  }
}

export const deleteSalesComplaintType = (payload) => {
  const requestObj = {
    complaintTypeId: payload.id,
    relationshipId: payload.relationshipId,
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesComplaintType/deleteSalesComplaintType`, requestObj)
      .then(res => {
        
        dispatch({ type: SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_TYPE_LIST_DELETION, data: { id: payload.id } })
        if (payload.props) {
          payload.props.fetchSalesComplaintTypes({
            relationshipId: payload.relationshipId,
          });
        }        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_TYPE_LIST_DELETION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const addSalesComplaintType = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    comlaintType: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesComplaintType/createSalesComplaintType`, requestObj)
      .then(res => {
        dispatch({ type: SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_TYPE_LIST_ADDITION, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_TYPE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const createOrUpdateComplaint = (payload, props) => {
  let formData = new FormData();
  if (payload.fileList && Array.isArray(payload.fileList) && payload.fileList.length) {
    payload.fileList.forEach(fileObj => {
      const file = new Blob([fileObj.originFileObj], { type: fileObj.type });
      formData.append('files', file, fileObj.name);
    })
  }
  delete payload.fileList;
  formData.append('customerSalesComplaint', JSON.stringify(payload, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesComplaint/saveOrUpdateSalesComplaint`, formData,{
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        let data = {
          relationshipId: props.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        }
        resetPaginatedData()(dispatch);
        if(props.activeTabKey === "2"){
          props.fetchComplaints(data,{activeTabKey:"2"});   
          props.getComplaintsCount(data,{activeTabKey:"2"});

        }
        else{
          props.fetchComplaints(data,{activeTabKey:"1"});   
          props.getComplaintsCount(data,{activeTabKey:"1"});
        }
        props.updateDrawerState({ salesComplaintDrawerVisible: false, salesComplaint: { salesComplaintDrawerVisible: false, isclone: true } });
        
        if(props.isUpdate) {
           showToasterMessage({ messageType: "success", description: "Compliant Edited successfully" })
        }
        else{

          showToasterMessage({ messageType: "success", description: "Compliant created successfully" });
        }
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
     props.salesComplaintDrawerVisible &&   props.updateDrawerState({ salesComplaintDrawerVisible: false });
        // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: SALES_COMPLAINT_ACTION_LIST.NEXT_COMPLAINT_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesComplaint/getNextSalesComplaintNumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SALES_COMPLAINT_ACTION_LIST.NEXT_COMPLAINT_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SALES_COMPLAINT_ACTION_LIST.NEXT_COMPLAINT_NUMBER;
        }
      })
  }
}