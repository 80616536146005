import React from "react";
import { Tabs } from 'antd';
import PurchaseOrder from "./purchaseOrder";
import PurchaseOrderDraft from "./purchaseOrderDraft";
import {  FormattedMessage } from 'react-intl';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import StandartPurchaseOrder from "../../../../containers/supplier/purchaseOrder/StandardPurchaseOrder";
import PageHeader from "../../../Common/pageHeader"
const { TabPane } = Tabs;

function callback(key, props) {
    const payload = { companyInfo: props.companyInfo, relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize };
    if (key === '1') {
        props.updateState({activeTab: 'Purchase Order', activeTabKey: "1"});
    } else if (key === '2') {
        props.updateState({activeTab: 'Draft', activeTabKey: "2"});
        fetchDataIfNeeded('getPurchaseOrderDraftCount', 'purchaseOrderDraftCount', props, payload);
        fetchPaginationDataIfNeeded('fetchPurchaseOrderDrafts', 'purchaseOrderDraftList', props, payload);
    } 
}

const PurchaseOrderListingComp = (props) => {
    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        {
            name: <FormattedMessage id='sidebar.menuItem.purchase' defaultMessage='' />,
        }, 
        {
            //name: props.activeTab || 'Purchase Order',
            name:<FormattedMessage id='sidebar.menuItem.purchaseOrder' defaultMessage='' /> ,
        }
    ];
    const openNewPo = () => {
        props.pushModalToStack({
            modalBody: <StandartPurchaseOrder {...props} type = 'new'/>,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            keyboard: false,
            wrapClassName: 'new-transaction-wrapper',
            onCancel: () => {
                props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    props.popModalFromStack();
                  }
                })
              }
        })
    }
    return (
        <>
            <PageHeader {...props}
                pageName="heading.purchase.purchaseOrder"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={openNewPo}
            />
        {/* <div className="customer-details-container"> */}
            {/* <div className="pb10">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}
            <div className="view-container">

            
                    <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { callback(key, props); }} type="line">
                        <TabPane tab={<FormattedMessage id='sidebar.menuItem.purchaseOrder' defaultMessage='' />} key="1">
                            <PurchaseOrder {...props} />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id='draft.text' defaultMessage='' />} key="2">
                            <PurchaseOrderDraft {...props} />
                        </TabPane>
                    </Tabs>
              

            </div>
        {/* </div> */}
        </>
    )
};

export default PurchaseOrderListingComp;
