import { BUSINESS_EXPENSE_ACTION_LIST, EXPENSE_ACTION_LIST } from '../static/constants';
const initialState = {
  businessExpensesList: {
    1: []
  },
  businessExpensesCount: 0,
  businessExpenseDetail: {},
  nextTxNumber: '',
  totalRelationshipExpense: 0
}

const BusinessExpenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_LIST:
      return {
        ...state,
        businessExpensesList: {
          ...state.businessExpensesList,
          [action.data.pageNo || 0]: action.data.list
        }
      };
    case BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_COUNT:
      return { ...state, businessExpensesCount: action.data };
    case BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_LIST_PAGE_RESET:
      return { ...state, businessExpensesList: initialState.businessExpensesList };
    case BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_DETAIL:
      return { ...state, businessExpenseDetail: action.data };
    case BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_DETAIL_RESET:
      return { ...state, businessExpenseDetail: initialState.businessExpenseDetail };
    case BUSINESS_EXPENSE_ACTION_LIST.NEXT_EXPENSE_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case BUSINESS_EXPENSE_ACTION_LIST.NEXT_EXPENSE_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case EXPENSE_ACTION_LIST.RELATIONSHIP_EXPENSE:
      return { ...state, totalRelationshipExpense: action.data };
    default:
      return state;
  }
};

export default BusinessExpenseReducer;