import { Collapse, Radio, Space } from "antd";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

const { Panel } = Collapse;

const LoginWorkFlowComp = (props) => {

    const options = [
        { value: 'Option 01', labelId: 'eStore.storeSettings.login.option1.label', recommended: true },
        { value: 'Option 02', labelId: 'eStore.storeSettings.login.option2.label' },
        { value: 'Option 03', labelId: 'eStore.storeSettings.login.option3.label' }
    ];

    return (
        <div className="store-model-container cus-space">
            <div className="heading">
                <FormattedMessage id="eStore.storeSettings.login.label" defaultMessage="" />
            </div>
            <FormattedMessage id="eStore.storeSettings.login.subheading" defaultMessage="" />
            <div className="mt20 custom-header">
                <Radio.Group
                    className="radio-grp"
                    onChange={(e) => {
                        props.updateState({
                            accordianOpen: e.target.value,
                        });
                    }}
                    value={props.accordianOpen}
                >
                    <Collapse
                        accordion
                        expandIconPosition="right"
                        onChange={(e) => props.updateState({ accordianOpen: e })}
                        className="custom-collapse"
                    >
                        {options.map(option => (
                            <Panel
                                header={
                                    <div className={`option-box ${option.recommended ? 'jus-content' : ''}`}>
                                        <div className="d-flex" style={{ gap: "10px" }}>
                                            <Radio value={option.value} className="radio-btn"></Radio>
                                            <div className="box-content">
                                                <div className="heading">{option.value}</div>
                                                <FormattedMessage id={option.labelId} defaultMessage="" />
                                            </div>
                                        </div>
                                        {option.recommended && <div className="recomm-label">Recommended</div>}
                                    </div>
                                }
                                key={option.value}
                                className="custom-panel"
                            >
                                <div className="selected-box">

                                    <div className="ml35">

                                        <div className="b-text font-14">
                                            <FormattedMessage id="eStore.storeSettings.login.verified" />
                                        </div>
                                        <Radio.Group
                                            className="radio-btn f-row"
                                            onChange={(e) => {
                                                props.updateState({
                                                    option: e.target.value,
                                                });
                                            }}
                                            value={props.option}
                                        >
                                            <Radio value={1} className="f-item-center">On Every Login</Radio>
                                            <Radio value={2} className="f-item-center">Only First Login</Radio>
                                        </Radio.Group>
                                        {props.option ? (
                                            <>
                                                <div className="b-text font-14 mt20">
                                                    <FormattedMessage id="eStore.storeSettings.login.attempts" />
                                                </div>
                                                <Radio.Group
                                                    className="radio-btn f-row"
                                                    onChange={(e) => {
                                                        props.updateState({
                                                            attemptOption: e.target.value,
                                                        });
                                                    }}
                                                    value={props.attemptOption}
                                                >
                                                    <Radio value={1} className="f-item-center">Only 6 times</Radio>
                                                    <Radio value={2} className="f-item-center">Infinity</Radio>
                                                </Radio.Group>
                                                <div className="mt20">Customer will be blocked for next 24 hrs.</div>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="ref-box"></div>
                                </div>
                            </Panel>
                        ))}
                    </Collapse>
                </Radio.Group>
            </div>

        </div>
    );
};

export default injectIntl(LoginWorkFlowComp);