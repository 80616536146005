import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, TRANSACTION_TYPES } from '../../../static/constants';
import {  showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');
//const bitlyAccessTokan = '8fa99e8caa1113c4125b3b7c1ed55db5a5b2eb76'; 

export const sendEmail = (props, formData) => {

  if (formData) {
    var payload = {};

    if (props.supplierId) {
      payload.supplierId = props.supplierId;
      payload.supplierStoreName = props.supplierStoreName;
    } else {
      payload.customerId = props.customerId;
      payload.customerName = props.customerName;
    }

   // payload.type = emailType;

    switch (props.type) {
      case TRANSACTION_TYPES.SALES_ORDER : {
        payload.salesOrderMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Sales Order';
        break;
      }
      case TRANSACTION_TYPES.SALES_INVOICE : {
        payload.invoiceMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Sales Invoice';
        break;
      }
      case TRANSACTION_TYPES.PROFORMA_INVOICE : {
        payload.invoiceProformaId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Sales Invoice';
        break;
      }
      case TRANSACTION_TYPES.PURCHASE_ORDER: {
        payload.poMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Purchase Order';
        break;
      }

      case TRANSACTION_TYPES.PURCHASE_INVOICE: {
        payload.purchaseInvoiceMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Purchase Invoice';
        break;
      }
      case 'Expense': {
        payload.customerExpenseId = props.txId;
        break;
      }
      case 'expense': {
        payload.supplierExpenseMasterId = props.txId;
        break;
      }
      case TRANSACTION_TYPES.CREDIT_MEMO: {
        payload.creditMemoMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Credit Memo';
        break;
      }
      case TRANSACTION_TYPES.DEBIT_MEMO: {
        payload.debitMemoMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Debit Memo';
        break;
      }
      case TRANSACTION_TYPES.CUSTOMER_PAYMENT: {
        payload.customerPaymentId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Sales Payment';
        break;
      }

      case TRANSACTION_TYPES.SUPPLIER_PAYMENT: {
        payload.supplierPaymentId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Purchase Payment';
        break;
      }

      case 'Customer PaymentTerm': {
        payload.customerId = props.txId;
        break;
      }

      case 'Customer Contact': {
        payload.customerId = props.txId;
        break;
      }

      case 'Supplier Contact': {
        payload.supplierId = props.txId;
        break;
      }

      case 'Supplier Address': {
        payload.supplierId = props.txId;
        break;
      }

      case 'Customer Address': {
        payload.customerId = props.txId;
        break;
      }
      case TRANSACTION_TYPES.SALES_QUOTE: {
        payload.salesQuotationMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Sales Quote';
        break;
      }
      case 'Business Expense': {
        payload.expenseMasterId = props.txId;
        break;
      }
      case TRANSACTION_TYPES.PURCHASE_REQUEST: {
        payload.purchaseRequestMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Purchase Request';
        break;
      }
      case TRANSACTION_TYPES.RFQ: {
        payload.rfqMasterId = props.txId;
        payload.rfqBiddingSuppliersId = props.txOption && props.txOption.rfqBiddingSuppliersId;
        payload.fileAPI = 'email';
        formData.docType = 'RFQ';
        break;
      }
      case TRANSACTION_TYPES.INBOUND_DELIVERY: {
        payload.inboundDeliveryMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Inbound Delivery';
        break;
      }
      case TRANSACTION_TYPES.INQUIRY: {
        payload.inquiryMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Inquiry';
        break;
      }
      case TRANSACTION_TYPES.SHIPMENT: {
        payload.shipmentMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Shipment';
        break;
      }
      case TRANSACTION_TYPES.SO_PACKAGE: {
        payload.shipmentMasterId = props.txId;
        payload.fileAPI = 'email';
        formData.docType = 'Packaging';
        break;
      }
      case TRANSACTION_TYPES.CUSTOMER_PARTY_LEDGER: {
        payload.fileAPI = 'email';
        payload.docType = 'Customer Party Ledger';
        break;
      }
      case TRANSACTION_TYPES.SUPPLIER_PARTY_LEDGER: {
        payload.fileAPI = 'email';
        payload.docType = 'Supplier Party Ledger';
        break;
      }
      case TRANSACTION_TYPES.BUSINESS_EXPENSE: {
        payload.fileAPI = 'email';
        payload.docType = 'relationship-expense';
        break;
      }
      default: {
        payload.fileAPI = 'email';
        break;
      }
    }

    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailManager/${payload.fileAPI}`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
          dispatch({ type: COMMON_ACTIONS.RESET_PAY_ONLINE_URL });
          props.updateState({ emailDrawerVisible: false });
          showToasterMessage({ messageType: 'success', description: 'Mail sent successfully.' })
        })
        .catch(err => {

          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
    }
  }
}

export const loadEmailData = (props) => {
  
  let dataPayload = { relationshipId: (props.companyInfo || {}).relationshipId, docName: props.type };
  if (props.type) {
    //let emailType = "";
    switch (props.type) {
      case TRANSACTION_TYPES.SALES_ORDER: {
        dataPayload.docName = 'Sales Order';
        //emailType = "emailSalesOrderPdf";
        break;
      }

      case TRANSACTION_TYPES.PURCHASE_ORDER: {
        dataPayload.docName = 'Purchase Order';
        //emailType = "emailPurchaseOrderPdf";
        break;
      }
      case TRANSACTION_TYPES.SALES_INVOICE: {
        dataPayload.docName = 'Sales Invoice';
        //emailType = "emailSalesInvoicePdf";
        break;
      }
      case TRANSACTION_TYPES.PURCHASE_INVOICE: {
        dataPayload.docName = 'Purchase Invoice';
        //emailType = "emailPurchaseInvoicePdf";
        break;
      }
      case 'Expense': {
        dataPayload.docName = 'Expense';
        //emailType = "emailCustomerExpensePdf";
        break;
      }

      case 'expense': {
        dataPayload.docName = 'Expense';
        //emailType = "emailSupplierExpensePdf";
        break;
      }
      case TRANSACTION_TYPES.CREDIT_MEMO: {
        dataPayload.docName = 'CreditMemo';
        //emailType = "emailCreditMemoPdf";
        break;
      }
      case TRANSACTION_TYPES.DEBIT_MEMO: {
        dataPayload.docName = 'DebitMemo';
        //emailType = "emailDebitMemoPdf";
        break;
      }
      case TRANSACTION_TYPES.CUSTOMER_PAYMENT: {
        dataPayload.docName = 'Sales Payment';
        //emailType = "emailCustomerPaymentPdf";
        break;
      }
      case TRANSACTION_TYPES.SUPPLIER_PAYMENT: {
        dataPayload.docName = 'Purchase Payment';
        //emailType = "emailSupplierPaymentPdf";
        break;
      }

      case 'Customer Address': {
        dataPayload.docName = 'Customer Address';
        //emailType = "emailAddressPdf";
        break;
      }

      case 'Customer Contact': {
        dataPayload.docName = 'Customer Contact';
        //emailType = "emailContactPdf";
        break;
      }

      case 'Supplier Contact': {
        dataPayload.docName = 'Supplier Contact';
        //emailType = "emailContactPdf";
        break;
      }

      case 'Supplier Address': {
        dataPayload.docName = 'Supplier Address';
        //emailType = "emailAddressPdf";
        break;
      }

      case 'Customer PaymentTerm': {
        dataPayload.docName = 'Customer PaymentTerm';
        //emailType = "emailPaymentTermPdf";
        break;
      }
      case TRANSACTION_TYPES.SALES_QUOTE: {
        dataPayload.docName = 'Sales Quotation';
        //emailType = "emailSalesQuotePdf";
        break;
      }
      case 'Business Expense': {
        dataPayload.docName = 'Business Expense';
        //emailType = "emailBusinessExpensePdf";
        break;
      }
      case TRANSACTION_TYPES.PURCHASE_REQUEST: {
        dataPayload.docName = 'Purchase Request';
        //emailType = "emailSupplierPRPdf";
        break;
      }
      case TRANSACTION_TYPES.RFQ: {
        dataPayload.docName = 'RFQ';
        //emailType = "emailSupplierRFQPdf";
        break;
      }
      case TRANSACTION_TYPES.INQUIRY: {
        dataPayload.docName = 'Inquiry';
        //emailType = "emailSupplierRFQPdf";
        break;
      }
      case TRANSACTION_TYPES.SHIPMENT: {
        dataPayload.docName = 'Shipment';
        break;
      }
      default: {
        break;
      }
    }

    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailTemplate/getEmailTemplate?docName=${dataPayload.docName}&relationshipId=${dataPayload.relationshipId}`)
        .then(res => {
          if (res.data) {
            props.updateState({ subject: res.data.subject, emailBody: res.data.content + (props.templateContent || '') });
          }
          // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        })
        .catch(err => {
          // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL});
        })
    }
  }
}

export const fetchEmailAddress = (payload, ) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllEmailAddresses?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || 0}&supplierId=${payload.supplierId || 0}&targetId=0&leadId=0`)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
          dispatch({ type: COMMON_ACTIONS.EMAIL_ADDRESS_LIST, data: res.data });
        })
        .catch(err => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        })
  }
}

export const getPayOnlineUrl = (payload, props) => {
  let baseUrl = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/`;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PAYMENT_GATEWAY_BASE_URL}${config.rootContextPaymentGateway}/paymentGateway/getPaymentUrl?relationshipId=${(props.companyInfo || {}).relationshipId}`, {...payload.salesInvoiceDetail, baseUrl })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: COMMON_ACTIONS.PAY_ONLINE_URL, data: res.data });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

// export const getInvoiceShortLink = (payload) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.RESET_WEB_INVOICE_SHORT_URL });
//     let uiBase = config.UI_BASE_URL === 'http://localhost:3000/app/' ? 'http://127.0.0.1:3000/app/' : config.UI_BASE_URL;
//     return axios
//       .post(`https://api-ssl.bitly.com/v4/shorten`, { "long_url": uiBase + "/invoice?webInvoiceId=" + payload.webInvoiceId },
//         {
//           headers: {
//             'Authorization': 'Bearer ' + bitlyAccessTokan,
//             'Content-Type': 'application/json'
//           }
//         })
//       .then(res => {
//         dispatch({ type: COMMON_ACTIONS.WEB_INVOICE_SHORT_URL, data: (res.data || []).link });
//       })
//       .catch(err => {
//         dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
//       })
//   }
// }
// export const resetInvoiceShortLink = () => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.RESET_WEB_INVOICE_SHORT_URL });
//   }
// }

export const getFromEmail = (props, cb) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailManager/getFromEmail?relationshipId=${(props.companyInfo || {}).relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        //dispatch({ type: COMMON_ACTIONS.EMAIL_ADDRESS_LIST, data: res.data });
        if (res.data) {
          props.updateState({ senderList: res.data });
          cb && cb(res.data);
        }
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
      })
}
}