import React, { useState } from 'react';
import './index.scss';
import { Tabs} from 'antd';
import { capitalizeFirstLetter, getCurrentDateForBackend, getInitialsFromString, getMomentDateForUIReadOnly, getAWSFileURL, base64Toblob} from '../../../../../utils';
// import Activity from './MainComponent/Activity';
import Contact from './SideComponent/Contact';
import Info from './SideComponent/Info';
// import Attendence from './SideComponent/Attendence';
import AttendenceComp from './MainComponent/AttendenceComp';
import Assignment from './MainComponent/Assignment';
import Fees from './MainComponent/Fees';
import Documents from './MainComponent/Documents';
import Academics from './MainComponent/Academics';
import ContactDetails from './MainComponent/ContactDetails';
import IdCard from './MainComponent/Idcard';
import { CustomUploader } from '../../../../general/CustomUploader';
import { CONSTANTS } from '../../../../../static/constants';
import { ImageCropper } from '../../../../general/ImageCropper';
import config from "../../../../../config/environmentConfig";

const { TabPane } = Tabs;

const StudentProfileComp = (props) => {
    const { studentData } = props;
    const onTabChange = (key) =>{
        const currentDate = new Date();
        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            studentId: Number(props.studentData.schoolStudentId || 209),
            startDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
            endDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)),
            classId: props.studentData.classId,
            courseId: props.courseId || 0,
            contactId: (studentData.boContacts || []).length ? studentData.boContacts[0].contactId : 0,
            customerId: studentData?.customerId,
            orgRegistrationId: props.studentData?.orgRegistrationId || 0,

          }

        switch(key){
            case 'activity': return
            
            case 'attendance': {
                props.getSchoolAttendanceByStudentId(payload);
            }
                break;
            case 'assignment': {
                props.getSchoolAssignment(payload);
            }
                break;
            case 'academics': {
                props.getSchoolCourseById(payload);
            }
                break;
            case 'contact_details' : {
                props.getContactEducation(payload);
            }
            case 'fees' : {
                props.fetchOrgInvoiceProforma(payload);
            }
                break;

            default: return
        }
    }


    const customProps = {
        type: CONSTANTS.TYPE_IMAGE,
        imgUrl: getAWSFileURL("", config.BUCKET_NAME.BO_RELATIONSHIP) || "",
        height: 120,
        isSignature: false,
        isImage: true,
        width: 120,
        name: "file",
        action: null,
        buttonName: "Upload Image",
        multiple: false,
        accept: ".jpg, .jpeg, .png",
        maxCount: 1,
        onChange: (fileList) => {
          if (fileList[0].originFileObj) {
            var reader = new FileReader();
    
            reader.onload = function (e) {
              props.showModal({
                title: 'Crop Image',
                modalBody: (
                  <ImageCropper
                    {...props}
                    handleSubmit={(newUrl) => {
                    //   props.updateDrawerState({
                    //     fileToUpload: base64Toblob(newUrl),
                    //   });
                        props.updateStudentProfilePic({
                            relationshipId: props.companyInfo.relationshipId,
                            file: base64Toblob(newUrl),
                            studentData: studentData
                        })
                        
                    }}
                    url={e.target.result}
                  />
                ),
                width: 600,
                hideFooter: true,
                wrapClassName: "Upload-profile-modal",
                type: CONSTANTS.TYPE_IMAGE,
                multiple: false,
              });
            };
            reader.readAsDataURL(fileList[0].originFileObj); // convert to base64 string
          }
        },
      };
      
    return (
        <div className='profile-container'>

            <div className='sidenavv'>
                <div className='sidenav-top'>
                    <div className='top-view'>

                        <div className='view1' style={{gap: '0px'}}>
                            <CustomUploader 
                                {...customProps}
                                type={CONSTANTS.TYPE_IMAGE} 
                                multiple={false} 
                                maxCount={1}
                                showMaxCount={false}
                                isProfile={true}
                                hideMaxCount={true}
                                showNameIcon={!studentData.logoAwsObjectKeys} 
                                // iconName={ getInitialsFromString(studentData.studentName || 'User') }
                                className='ml15'
                                showUploadList={false}
                                iconName={ studentData.logoAwsObjectKey ?  <img style={{borderRadius:"50%"}} src={
                                    getAWSFileURL(studentData.logoAwsObjectKey ,props.companyInfo.bucketName , "student")
                                  } height={'40px'} width={'40px'} /> :
                                  getInitialsFromString(studentData.studentName || 'User')
                                }
                            />
                            <div>
                                <div className='student-name'>{capitalizeFirstLetter(studentData.studentName || '-')}</div>
                                <div className='student-idd'>ID - {studentData.schoolStudentId || '-'}</div>
                            </div>
                        </div>

                        <div className={`status-code line-view ${studentData.studentStatus?.toLowerCase()} ml60 mt5`}>{studentData.studentStatus}</div>

                        <div className='view2 ml45'>
                            <div className='status-code line-view'>
                                <i className="fi fi-rr-user mr5" />
                                {studentData.gender}
                            </div>
                            {studentData.dateOfBirth ? 
                            <div className='status-code line-view'>
                                <i className="fi fi-rr-calendar-day mr5" />
                                 {getMomentDateForUIReadOnly({date: studentData.dateOfBirth}) }
                            </div>
                            : "" }
                        </div>
                    </div>
                </div>
                <div className='sidenav-bottom'>
                    <div className='tabs-info'>
                        <Tabs 
                            type="line" 
                            className='custom-tab-st'
                            animated={false}
                        >
                            <TabPane tab="Info" key="1">
                                <div className='scroll-button'>
                                    <Info {...props} />
                                </div>
                            </TabPane>

                            <TabPane tab="Contact" key="2">
                                <div className='scroll-button'>
                                    <Contact {...props}/>
                                </div>
                            </TabPane>
                            {/* <TabPane tab="Attendence" key="3">
                                <div className='scroll-button'>
                                    <Attendence />
                                </div>
                            </TabPane> */}
                        </Tabs>
                    </div>
                </div>
            </div>

            <div className='student-main-body'>
                <Tabs 
                    type="line"
                    animated={false}
                    onChange={onTabChange}
                >
                    {/* <TabPane tab="Activity" key="activity" className='tabspane'>
                        <Activity {...props} />
                    </TabPane> */}

                    <TabPane tab="Attendance" key="attendance" className='tabspane'>
                        <AttendenceComp {...props} />
                    </TabPane>

                    <TabPane tab="Academics" key="academics" className='tabspane'>
                        <Academics  {...props} />
                    </TabPane>

                    <TabPane tab="Assignment" key="assignment" className='tabspane'>
                        <Assignment {...props} />
                    </TabPane>

                    <TabPane tab="Fees" key="fees" className='tabspane'>  
                        <Fees {...props} />     
                    </TabPane>

                    {/* <TabPane tab="Payment History" key="paymentHistory" className='tabspane'>
                        <PaymentHistory {...props} />
                    </TabPane> */}

                    <TabPane tab="Contacts" key="contact_details" className='tabspane'>
                        <ContactDetails  {...props} />
                    </TabPane>

                    {/* <TabPane tab="Documents" key="documents" className='tabspane'>
                        <Documents  {...props} />
                    </TabPane> */}

                    <TabPane tab="Id Card" key="id_card" className='tabspane'>
                        <IdCard  {...props} />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );

}


export default StudentProfileComp;