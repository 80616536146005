import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowContact from '../../../../components/supplier/Listing/ShowContact';
import { fetchSalutation, showModal, hideModal, popModalFromStack, pushModalToStack } from './../../../../actions/commonActions';
import { fetchAllContacts, deleteContact, createUpdateContact, addContactRow } from './action';
import { fetchDataIfNeeded } from '../../../../utils';

class ShowContactContainer extends Component {
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      supplierId: this.props.supplierId
    };
    fetchDataIfNeeded('fetchSalutation', 'salutations', this.props, payload);
    this.props.fetchAllContacts(payload);
  }

  render() {
    return (
      <ShowContact {...this.props} updateState={(data) => { this.setState(data)}}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    salutations: state.common.salutations,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    contacts: state.supplier.contacts,
    listLoading:state.common.listLoading

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSalutation,
  fetchAllContacts,
  deleteContact,
  createUpdateContact,
  addContactRow,
  popModalFromStack,
  pushModalToStack,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowContactContainer);
