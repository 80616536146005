import React from "react";
// import { showToasterMessage } from "../../utils";

export const AWSFileTable = (props) => {
  return (
    <div style={{ width: "100%" }}>
      {props.docDetailList && props.docDetailList.length ? (
        <div className="remarks-title upoad-file-table">
          <div className="modal-table-container">
            <table className="transaction-details-table">
              <thead>
                <tr>
                  <th width="10%">#</th>
                  <th>File Name</th>
                  {/* <th>Type</th> */}
                  <th>Size</th>
                  {!props.hideDownloadButton ? <th width="10%"></th> : ""}
                </tr>
              </thead>
              <tbody>
                {props.docDetailList.map((e, index) => {
                  let _size = e.fileSize;
                  let fSExt = ["Bytes", "KB", "MB", "GB"],
                    i = 0;
                  while (_size > 900) {
                    _size /= 1024;
                    i++;
                  }
                  let exactSize =
                    Math.round(_size * 100) / 100 + " " + fSExt[i];
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <span
                          className={"cursor-pointer"}
                          onClick={() => {
                            props.downloadFileFromBucket({
                              bucketName: props.companyInfo?.bucketName,
                              awsKeyName: e.awsKeyName,
                              folderName: e.folderName,
                              relationshipId: props.relationshipId,
                              fileName: e.fileName,
                            });
                          }}
                        >
                          {e.fileName} &nbsp;
                        </span>
                      </td>
                      {/* <td>{e.type}</td> */}
                      <td>{exactSize}</td>
                      {!props.hideDownloadButton ? (
                        <td className="cursor-pointer">
                          <i
                            className="fa fa-trash"
                            onClick={() => {
                              props.showModal({
                                modalBody:
                                  "Are you sure you want to delete this file?",
                                handleSubmit: () => {
                                  props.hideModal();
                                  props.deleteDocument(e, props);
                                },
                              });
                            }}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  );
};
