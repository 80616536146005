import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ApplySalesCreditComponent from '../../../../../components/modal/modalBody/customer/ApplySalesCredit';
import { fetchAvailableCreditList, resetAvailableCreditList, applyCredits } from './action';
import { pushModalToStack, popModalFromStack, showModal, hideModal } from './../../../../../actions/commonActions';
//import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from  '../../../../../utils';
import { fetchSalesInvoices } from '../../../../customer/invoice/Listing/action';

class ApplySalesCredit extends Component {
    constructor(props) {
        super(props);
        this.state = {
          searchedText: ''
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.invoiceData.customerId, invoiceMasterId: this.props.invoiceData.invoiceMasterId };
        
        this.props.resetAvailableCreditList();
        this.props.fetchAvailableCreditList(payload);
        
    }

    render() {
        return <ApplySalesCreditComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        userInfo: state.common.userInfo,
        availableCreditList: state.salesInvoice.availableCreditList
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  fetchAvailableCreditList,
  resetAvailableCreditList,
  applyCredits,
  fetchSalesInvoices
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApplySalesCredit);