import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Checkbox } from 'antd';
import './general.scss';
import { GRID_PREFERENCE_CONSTANTS } from '../../static/agGridConstants';

export function AddAndRemoveColumn(props) {
    const { txAvailableColumn = {}, txColumns = [], txMandatoryColumns = [] } = props;
    const [product, updateProduct] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) > -1);
    const [quantity, updateQuantity] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.QUANTITY) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.QUANTITY) > -1);
    const [quantityPacked] = useState(txColumns.indexOf('quantityPacked') > -1 || txMandatoryColumns.indexOf('quantityPacked') > -1);
    const [productImage, updateProductImage] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT_IMAGE) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT_IMAGE) > -1);

    const [customerInquiryNumber, updateCustomerInquiryNumber] = useState(txColumns.indexOf('customerInquiryNumber') > -1 || txMandatoryColumns.indexOf('customerInquiryNumber') > -1);
    const [customerRFQNumber, , updateCustomerRFQNumber] = useState(txColumns.indexOf('customerRFQNumber') > -1 || txMandatoryColumns.indexOf('customerRFQNumber') > -1);
    const [customerPONumber, updateCustomerPONumber] = useState(txColumns.indexOf('customerPONumber') > -1 || txMandatoryColumns.indexOf('customerPONumber') > -1);

    const [qtyToPack] = useState(txColumns.indexOf('qtyToPack') > -1 || txMandatoryColumns.indexOf('qtyToPack') > -1);
    const [amount, updateAmount] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.AMOUNT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.AMOUNT) > -1);
    const [rate, updateRate] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.RATE) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.RATE) > -1);
    const [description, updateDescription] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1);
    const [tax, updateTax] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) > -1);
    const [uom, updateUom] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) > -1);
    const [basePrice, updateBasePrice] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) > -1);
    const [specialDiscount, updateSpecialDiscount] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) > -1);
    const [discount, updateDiscount] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) > -1);
    const [discountAmount, updateDiscountAmount] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT_AMOUNT_FIELD) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT_AMOUNT_FIELD) > -1);
    const [hsn, updateHsn] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) > -1);
    const [comment, updateComment] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) > -1);
    const [stockNumber, updateStockNumber] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) > -1);
    const [partNumber, updatePartNumber] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) > -1);
    const [materialNumber, updateMaterialNumber] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) > -1);
    const [origin, updateOrigin] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) > -1);
    const [hsCode, updateHSCode] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) > -1);
    const [itemName, updateItemName] = useState(txColumns.indexOf('itemName') > -1 || txMandatoryColumns.indexOf('itemName') > -1);
    const [variants, updateVariants] = useState(txColumns.indexOf('variants') > -1 || txMandatoryColumns.indexOf('variants') > -1);
    const [valuation] = useState(txColumns.indexOf('valuation') > -1 || txMandatoryColumns.indexOf('valuation') > -1);
    const [sku, updateSku] = useState(txColumns.indexOf('sku') > -1 || txMandatoryColumns.indexOf('sku') > -1);
    const [categoryName, updateCategoryName] = useState(txColumns.indexOf('categoryName') > -1 || txMandatoryColumns.indexOf('categoryName') > -1);
    const [hsnCodeRate, updateHsnCodeRate] = useState(txColumns.indexOf('hsnCodeRate') > -1 || txMandatoryColumns.indexOf('hsnCodeRate') > -1);
    const [qtyCurrentStock, updateqQtyCurrentStock] = useState(txColumns.indexOf('qtyCurrentStock') > -1 || txMandatoryColumns.indexOf('qtyCurrentStock') > -1);
    const [warehouseName, updateWarehouseName] = useState(txColumns.indexOf('warehouseName') > -1 || txMandatoryColumns.indexOf('warehouseName') > -1);
    const [packageNumber, updatePackageNumber] = useState(txColumns.indexOf('packageNumber') > -1 || txMandatoryColumns.indexOf('packageNumber') > -1);
    const [salesOrderNumber, updateSalesOrderNumber] = useState(txColumns.indexOf('salesOrderNumber') > -1 || txMandatoryColumns.indexOf('salesOrderNumber') > -1);
    const [shipmentNumber, updateShipmentNumber] = useState(txColumns.indexOf('shipmentNumber') > -1 || txMandatoryColumns.indexOf('shipmentNumber') > -1);
    const [firstName, updateFirstName] = useState(txColumns.indexOf('firstName') > -1 || txMandatoryColumns.indexOf('firstName') > -1);
    const [lastName, updateLastName] = useState(txColumns.indexOf('lastName') > -1 || txMandatoryColumns.indexOf('lastName') > -1);
    const [jobTitleName, updateJobTitleName] = useState(txColumns.indexOf('jobTitleName') > -1 || txMandatoryColumns.indexOf('jobTitleName') > -1);
    const [managerName, updateManagerName] = useState(txColumns.indexOf('managerName') > -1 || txMandatoryColumns.indexOf('managerName') > -1);
    const [hierarchyName, updateHierarchyName] = useState(txColumns.indexOf('hierarchyName') > -1 || txMandatoryColumns.indexOf('hierarchyName') > -1);
    const [hierarchyLevel, updateHierarchyLevel] = useState(txColumns.indexOf('hierarchyLevel') > -1 || txMandatoryColumns.indexOf('hierarchyLevel') > -1);
    const [workPhone, updateWorkPhone] = useState(txColumns.indexOf('workPhone') > -1 || txMandatoryColumns.indexOf('workPhone') > -1);
    const [departmentName, updateDepartmentName] = useState(txColumns.indexOf('departmentName') > -1 || txMandatoryColumns.indexOf('departmentName') > -1);
    const [brandName, updateBrandName] = useState(txColumns.indexOf('brandName') > -1 || txMandatoryColumns.indexOf('brandName') > -1);
    const [upc, updateUPC] = useState(txColumns.indexOf('upc') > -1 || txMandatoryColumns.indexOf('upc') > -1);
    const [isbn, updateISBN] = useState(txColumns.indexOf('isbn') > -1 || txMandatoryColumns.indexOf('isbn') > -1);
    const [ean, updateEAN] = useState(txColumns.indexOf('ean') > -1 || txMandatoryColumns.indexOf('ean') > -1);
    const [manufacturerName, updateManufacturerName] = useState(txColumns.indexOf('manufacturerName') > -1 || txMandatoryColumns.indexOf('manufacturerName') > -1);
    const [ledgerAccount, updateLedgerAccount] = useState(txColumns.indexOf('ledgerAccount') > -1 || txMandatoryColumns.indexOf('ledgerAccount') > -1);
   

    const savePreferences = () => {
        let columnList = [];
        if (product) columnList.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        if (productImage) columnList.push(GRID_PREFERENCE_CONSTANTS.PRODUCT_IMAGE);
        if (itemName) columnList.push('itemName');
        if (quantity) columnList.push(GRID_PREFERENCE_CONSTANTS.QUANTITY);
        if (rate) columnList.push(GRID_PREFERENCE_CONSTANTS.RATE);
        if (amount) columnList.push(GRID_PREFERENCE_CONSTANTS.AMOUNT);
        if (basePrice) columnList.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        if (description) columnList.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        if (uom) columnList.push(GRID_PREFERENCE_CONSTANTS.UOM);
        if (tax) columnList.push(GRID_PREFERENCE_CONSTANTS.TAX);
        if (specialDiscount) columnList.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        if (discount) columnList.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        if (discountAmount) columnList.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT_AMOUNT_FIELD);
        if (hsn) columnList.push(GRID_PREFERENCE_CONSTANTS.HSN);
        if (comment) columnList.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        if (stockNumber) columnList.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        if (partNumber) columnList.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        if (origin) columnList.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        if (hsCode) columnList.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        if (warehouseName) columnList.push('warehouseName');
        if (packageNumber) columnList.push('packageNumber');
        if (salesOrderNumber) columnList.push('salesOrderNumber');
        if (shipmentNumber) columnList.push('shipmentNumber');
        if (variants) columnList.push('variants');
        if (valuation) columnList.push('valuation');
        if (sku) columnList.push('sku');
        if (categoryName) columnList.push('categoryName');
        if (qtyCurrentStock) columnList.push('qtyCurrentStock');
        if (customerInquiryNumber) columnList.push('customerInquiryNumber');
        if (customerRFQNumber) columnList.push('customerRFQNumber');
        if (customerPONumber) columnList.push('customerPONumber');
        if (firstName) columnList.push('firstName');
        if (lastName) columnList.push('lastName');
        if (jobTitleName) columnList.push('jobTitleName');
        if (managerName) columnList.push('managerName');
        if (hierarchyName) columnList.push('hierarchyName');
        if (hierarchyLevel) columnList.push('hierarchyLevel');
        if (workPhone) columnList.push('workPhone');
        if (departmentName) columnList.push('departmentName');
        if (materialNumber) columnList.push(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER); 
        if (brandName) columnList.push('brandName');
        if (upc) columnList.push('upc');
        if (isbn) columnList.push('isbn');
        if (ean) columnList.push('ean');
        if (manufacturerName) columnList.push('manufacturerName');
        if (ledgerAccount) columnList.push('ledgerAccount');
        

        let data = props.txPreferences || {};
        data.relationshipId = (props.companyInfo || {}).relationshipId;
        data.clientUserAccountId = (props.userInfo || {}).userId;
        if (data.gridPreference) {
            let obj = JSON.parse(data.gridPreference);
            obj.columnList = columnList;
            data.gridPreference = JSON.stringify(obj);
        } else {
            data.gridPreference = JSON.stringify({ columnList: columnList, columnSetting: props.txColumnSetting });
        }
        props.updateState({ txColumns: columnList, tableDataReRendered: true });
        data.preferenceId = props.preferenceId;
        data.tablePreference = props.tablePreference;
        props.saveUserPrefrences(data);


    }
    return (
        <><div className="add-remove-column">
            {txAvailableColumn.itemName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('itemName') > -1}
                        onChange={(e) => {
                            updateItemName(e.target.checked);
                        } }
                        checked={itemName}> <FormattedMessage id='package.productDetail.product' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.product && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) > -1}
                    checked={product} onChange={(e) => {
                        updateProduct(e.target.checked);
                    } }> <FormattedMessage id='package.productDetail.product' defaultMessage='' /></Checkbox>
            </div>}
            {txAvailableColumn.productImage && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT_IMAGE) > -1}
                    checked={productImage} onChange={(e) => {
                        updateProductImage(e.target.checked);
                    } }> <FormattedMessage id='productImage.text' defaultMessage='' /></Checkbox>
            </div>}
            {txAvailableColumn.quantity && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.QUANTITY) > -1}
                    checked={quantity} onChange={(e) => {
                        updateQuantity(e.target.checked);
                    } }> <FormattedMessage id='product.detail.tab.outboundInventory.quantity' defaultMessage='' /></Checkbox>
            </div>}
            {txAvailableColumn.rate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.RATE) > -1}
                    checked={rate} onChange={(e) => {
                        updateRate(e.target.checked);
                    } }> <FormattedMessage id='customer.salesOrder.form.gridHeader.rate' defaultMessage='' /></Checkbox>
            </div>}
            {txAvailableColumn.amount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.AMOUNT) > -1}
                    checked={amount} onChange={(e) => {
                        updateAmount(e.target.checked);
                    } }> <FormattedMessage id='customer.salesQuote.listing.header.amount' defaultMessage='' /></Checkbox>
            </div>}
            {txAvailableColumn.description && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1}
                    checked={description} onChange={(e) => {
                        updateDescription(e.target.checked);
                    } }> <FormattedMessage id='package.productDetail.description' defaultMessage='' /></Checkbox>
            </div>}
            {txAvailableColumn.uom &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) > -1}
                        checked={uom} onChange={(e) => {
                            updateUom(e.target.checked);
                        } }> <FormattedMessage id='package.productDetail.uom' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.hsn &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) > -1}
                        checked={hsn} onChange={(e) => {
                            updateHsn(e.target.checked);
                        } }> <FormattedMessage id='customer.creditMemo.form.gridHeader.hsn' defaultMessage='' />
                    </Checkbox>
                </div>}
            {txAvailableColumn.specialDiscount &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) > -1}
                        checked={specialDiscount} onChange={(e) => {
                            updateSpecialDiscount(e.target.checked);
                        } }> <FormattedMessage id='Special.Discount.(%)' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.basePrice && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) > -1}
                    checked={basePrice} onChange={(e) => {
                        updateBasePrice(e.target.checked);
                    } }> <FormattedMessage id='Base-Rate' defaultMessage='' /></Checkbox>
            </div>}
            {txAvailableColumn.discount &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) > -1}
                        checked={discount} onChange={(e) => {
                            updateDiscount(e.target.checked);
                        } }> <FormattedMessage id='customer.creditMemo.form.gridHeader.discount' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.discountAmount &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT_AMOUNT_FIELD) > -1}
                        checked={discountAmount} onChange={(e) => {
                            updateDiscountAmount(e.target.checked);
                        } }> <FormattedMessage id='customer.salesInvoice.form.gridHeader.discountAmt' defaultMessage='' /> ({(props.companyInfo || {}).currencyIcon ? <i className={(props.companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode}) </Checkbox>
                </div>}
            {txAvailableColumn.tax &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) > -1}
                        checked={tax} onChange={(e) => {
                            updateTax(e.target.checked);
                        } }> <FormattedMessage id='supplier.debitMemo.form.gridHeader.tax' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.comment &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) > -1}
                        checked={comment} onChange={(e) => {
                            updateComment(e.target.checked);
                        } }> <FormattedMessage id='drawer.inventory.tabNames.warehouse' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.stockNumber &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) > -1}
                        checked={stockNumber} onChange={(e) => {
                            updateStockNumber(e.target.checked);
                        } }> <FormattedMessage id='drawer.inventory.customerStockNo' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.partNumber &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) > -1}
                        checked={partNumber} onChange={(e) => {
                            updatePartNumber(e.target.checked);
                        } }> <FormattedMessage id='drawer.inventory.partNo' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.materialNumber &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) > -1}
                        checked={materialNumber} onChange={(e) => {
                            updateMaterialNumber(e.target.checked);
                        } }> <FormattedMessage id='common.materialNumber' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.origin &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) > -1}
                        checked={origin} onChange={(e) => {
                            updateOrigin(e.target.checked);
                        } }> <FormattedMessage id='customer.salesQuote.form.gridHeader.origin' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.hsCode &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) > -1}
                        checked={hsCode} onChange={(e) => {
                            updateHSCode(e.target.checked);
                        } }> <FormattedMessage id='customer.salesInvoice.form.gridHeader.hsCode' defaultMessage='' /></Checkbox>
                </div>}

            {/* {txAvailableColumn.itemName &&
        <div className="mb5">
            <Checkbox disabled={txMandatoryColumns.indexOf('itemName') > -1}
                checked={itemName} onChange={(e) => {
                    updateItemName(e.target.checked);
                }}> Item Name</Checkbox>
        </div>
    } */}

            {txAvailableColumn.variants &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('variants') > -1}
                        checked={variants} onChange={(e) => {
                            updateVariants(e.target.checked);
                        } }> <FormattedMessage id='Variants' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.valuation &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('valuation') > -1}
                        checked={valuation} onChange={(e) => {
                        } }> <FormattedMessage id='product.listing.header.valuation' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.sku &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('sku') > -1}
                        checked={sku} onChange={(e) => {
                            updateSku(e.target.checked);
                        } }> <FormattedMessage id='drawer.inventory.sku' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.categoryName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('categoryName') > -1}
                        checked={categoryName} onChange={(e) => {
                            updateCategoryName(e.target.checked);
                        } }> <FormattedMessage id='inventory.extras.category.listing.header.categoryName' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.hsnCodeRate &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('hsnCodeRate') > -1}
                        checked={hsnCodeRate} onChange={(e) => {
                            updateHsnCodeRate(e.target.checked);
                        } }> <FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.qtyCurrentStock &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('qtyCurrentStock') > -1}
                        checked={qtyCurrentStock} onChange={(e) => {
                            updateqQtyCurrentStock(e.target.checked);
                        } }> <FormattedMessage id='QTY.Current.Stock' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.warehouseName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('warehouseName') > -1}
                        checked={warehouseName} onChange={(e) => {
                            updateWarehouseName(e.target.checked);
                        } }> <FormattedMessage id='addItem.text.warehouse' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.packageNumber &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('packageNumber') > -1}
                        checked={packageNumber} onChange={(e) => {
                            updatePackageNumber(e.target.checked);
                        } }> <FormattedMessage id='customer.package.listing.header.packageNo' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.salesOrderNumber &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('salesOrderNumber') > -1}
                        checked={salesOrderNumber} onChange={(e) => {
                            updateSalesOrderNumber(e.target.checked);
                        } }> <FormattedMessage id='customer.listing.totalOrder.header.soNumber' defaultMessage='' /></Checkbox>
                </div>}


            {txAvailableColumn.shipmentNumber &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('shipmentNumber') > -1}
                        checked={shipmentNumber} onChange={(e) => {
                            updateShipmentNumber(e.target.checked);
                        } }> <FormattedMessage id='customer.shipment.listing.header.shipmentNo' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.firstName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('firstName') > -1}
                        checked={firstName} onChange={(e) => {
                            updateFirstName(e.target.checked);
                        } }> <FormattedMessage id='drawer.customer.contact.firstName.placeholder' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.lastName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('lastName') > -1}
                        checked={lastName} onChange={(e) => {
                            updateLastName(e.target.checked);
                        } }> <FormattedMessage id='drawer.customer.contact.lastName.placeholder' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.jobTitleName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('jobTitleName') > -1}
                        checked={jobTitleName} onChange={(e) => {
                            updateJobTitleName(e.target.checked);
                        } }> <FormattedMessage id='Job.Title.Name' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.managerName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('managerName') > -1}
                        checked={managerName} onChange={(e) => {
                            updateManagerName(e.target.checked);
                        } }> <FormattedMessage id='Manager.Name' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.hierarchyName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('hierarchyName') > -1}
                        checked={hierarchyName} onChange={(e) => {
                            updateHierarchyName(e.target.checked);
                        } }> <FormattedMessage id='company.employee.hierarchyName' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.hierarchyLevel &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('hierarchyLevel') > -1}
                        checked={hierarchyLevel} onChange={(e) => {
                            updateHierarchyLevel(e.target.checked);
                        } }> <FormattedMessage id='company.employee.hierarchyLevel' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.workPhone &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('workPhone') > -1}
                        checked={workPhone} onChange={(e) => {
                            updateWorkPhone(e.target.checked);
                        } }> <FormattedMessage id='contact.workPhone' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.departmentName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('departmentName') > -1}
                        checked={departmentName} onChange={(e) => {
                            updateDepartmentName(e.target.checked);
                        } }> <FormattedMessage id='modal.common.project.departmentName' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.quantityPacked &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('quantityPacked') > -1}
                        checked={quantityPacked}> <FormattedMessage id='Quantity.Packed' defaultMessage='' /></Checkbox>
                </div>}

            {txAvailableColumn.customerInquiryNumber &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateCustomerInquiryNumber(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('customerInquiryNumber') > -1}
                        checked={customerInquiryNumber}> <FormattedMessage id='common.customer.inquiryNumber' defaultMessage='' /></Checkbox>
                </div>}


            {txAvailableColumn.customerRFQNumber &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateCustomerRFQNumber(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('customerRFQNumber') > -1}
                        checked={customerRFQNumber}> <FormattedMessage id='customerRfqNumber.text' defaultMessage='' /></Checkbox>
                </div>}



            {txAvailableColumn.customerPONumber &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateCustomerPONumber(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('customerPONumber') > -1}
                        checked={customerPONumber}> <FormattedMessage id='customerPONumber.text' defaultMessage='' /></Checkbox>
                </div>}



            {txAvailableColumn.qtyToPack &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('qtyToPack') > -1}
                        checked={qtyToPack}><FormattedMessage id='package.productDetail.qtyToPack' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.brandName &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateBrandName(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('brandName') > -1}
                        checked={brandName}> <FormattedMessage id='inventory.extras.brand.listing.header.brandName' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.upc &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateUPC(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('upc') > -1}
                        checked={upc}> <FormattedMessage id='drawer.inventory.upc' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.isbn &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateISBN(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('isbn') > -1}
                        checked={isbn}> <FormattedMessage id='drawer.inventory.isbn' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.ean &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateEAN(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('ean') > -1}
                        checked={ean}> <FormattedMessage id='drawer.inventory.ean' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.manufacturerName &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateManufacturerName(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('manufacturerName') > -1}
                        checked={manufacturerName}> <FormattedMessage id='inventory.extras.manufacturer.listing.header.manufacturerName' defaultMessage='' /></Checkbox>
                </div>}
            {txAvailableColumn.ledgerAccount &&
                <div className="mb5">
                    <Checkbox onChange={(e) => {
                        updateLedgerAccount(e.target.checked);
                    } } disabled={txMandatoryColumns.indexOf('ledgerAccount') > -1}
                        checked={ledgerAccount}> <FormattedMessage id='header.menuItem.ledgerAccount.label' defaultMessage='' /></Checkbox>
                </div>}


        </div><div className="button-action">
                <Button type="primary" onClick={() => {
                    savePreferences();
                    props.updateState({ showColPop: false });
                } }><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
            </div></>

    );

}

