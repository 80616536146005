import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssignSectionToStudentComponent from '../../../../../components/modal/modalBody/schoolManagement/AssignSectionToStudent';
import { saveOrUpdateStudentClassRef } from './action';
import { getClassList } from '../../../../schoolManagement/class/action';
// import * as find from 'lodash.find';

class AssignClassToStudent extends Component {
    constructor(props) {
        super(props);
        let selectedClasses = [];
        (props.studentDetail.schoolStudentClassRefList || []).forEach(function (classObj) {
            selectedClasses.push(classObj.classId);
        });
        this.state = {
            courseTermSubjects: {},
            selectedClasses
        }
    }
    componentWillMount() {

        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 500,relationshipEmployeeId: userInfo.relationshipEmployeeId,formId: 0 };
        this.props.getClassList(payload);
        // this.props.fetchCourseSubjectList(payload);
    }

    render() {
        return <AssignSectionToStudentComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        classList: state.school.classList,
        userInfo: state.common.userInfo,
        defaultSchoolForm: 0,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveOrUpdateStudentClassRef,
    getClassList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssignClassToStudent);