import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DepartmentComp from '../../../../components/company/EmployeeMeta/Department';
import DepartmentDrawerCon from '../../../drawer/company/EmployeeMeta/Department';
import { getDepartment, getTotalDepartmentCount, createDepartment, deletedepartment, setDepartmentStatus } from './action';
import { pushModalToStack, showModal, hideModal } from '../../../../actions/commonActions';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';

class DepartmentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      status: 1
    }
  };


  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,

    }

    // fetchPaginationDataIfNeeded('getDepartment', 'departmentList', this.props, payload, true);
    // fetchDataIfNeeded('getTotalDepartmentCount', 'departmentListCount', this.props, payload);
    this.props.getDepartment(payload);
    this.props.getTotalDepartmentCount(payload);
  }



  render() {
    return <>
      <DepartmentComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      {this.state.departmentDrawer && <DepartmentDrawerCon {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
    </>;
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    listLoading: state.common.listLoading,
    drawerLoading: state.common.drawerLoading,
    headerLoading: state.common.headerLoading,
    departmentList: state.employee.departmentList,
    departmentListCount: state.employee.departmentListCount,
    userInfo: state.common.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  getDepartment,
  createDepartment,
  getTotalDepartmentCount,
  pushModalToStack,
  deletedepartment,
  setDepartmentStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentContainer);
