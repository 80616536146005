import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FamilyListingComp from '../../../components/schoolManagement/familyListing/index';
import {
  fetchCustomers, getCustomerCount, resetPaginatedData, getTotalOrdersCount,
  getTotalSalesOrdersCount, getTotalUnpaidBalance, getTotalSalesRevenue,
  exportExcel, exportPdf, deleteCustomers
} from './action'
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchUserPreferences, saveUserPrefrences } from '../../../actions/commonActions';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
import { generatePDF } from '../../../actions/downloadFileAction';
import { FormattedMessage } from 'react-intl';
import find from 'lodash.find';

class FamilyListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      txPreferences: { txType: "customer", templateName: "listing" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['companyName', 'contact', 'totalAmountDue', 'openedOrdersCount', 'unpaidInvoicesCount'],
      txAvailableColumn: {
        companyName: true,
        contact: true,
        totalAmountDue: true,
        openedOrdersCount: true,
        paidInvoicesCount: true,
        paymentTermName: true,
        unpaidInvoicesCount: true
      },
      searchedText: '',
      isDataRendered: false
    };
  }



  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.schoolManagement.families' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
        name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
      },
      {
          name: <FormattedMessage id='sidebar.menuItem.parent' defaultMessage='' />,
      }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 200
    };
    fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, payload, true);
    fetchDataIfNeeded('getCustomerCount', 'customerCount', this.props, payload);
    // fetchDataIfNeeded('getTotalOrdersCount', 'customerOpenOrderCount', this.props, payload);
    // fetchDataIfNeeded('getTotalSalesOrdersCount', 'customerOpenSOCount', this.props, payload);
    // fetchDataIfNeeded('getTotalUnpaidBalance', 'customerUnpaidBalance', this.props, payload);
    // fetchDataIfNeeded('getTotalSalesRevenue', 'customerSalesRevenue', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "customer", templateName: "listing" });
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns : (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }
  }

  render() {
    return (
      <FamilyListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.school.familyList,
    customerCount: state.school.familyCount,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    customerOpenOrderCount: state.customer.customerOpenOrderCount,
    customerOpenSOCount: state.customer.customerOpenSOCount,
    customerUnpaidBalance: state.customer.customerUnpaidBalance,
    customerSalesRevenue: state.customer.customerSalesRevenue,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    customerLedger: state.customer.customerLedger,
    permissions: state.auth.permissions
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCustomers,
  getCustomerCount,
  showModal,
  hideModal,
  fetchUserPreferences,
  pushModalToStack,
  popModalFromStack,
  resetPaginatedData,
  deleteCustomers,
  exportPdf,
  exportExcel,
  getTotalOrdersCount,
  getTotalSalesOrdersCount,
  getTotalUnpaidBalance,
  getTotalSalesRevenue,
  saveUserPrefrences,
  generatePDF
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FamilyListing);
