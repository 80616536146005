import React, {Fragment } from "react";
//import { Form } from '@ant-design/compatible';
import { Row, Col } from "antd";
import '@ant-design/compatible/assets/index.css';
import config from '../../../config/environmentConfig';
// import {  getMomentDateForUIReadOnly } from '../../../utils';
import { CONSTANTS } from '../../../static/constants';
import { LogoComponent } from '../../general/LogoComponent';

// const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const DonationComponent = (props) => {
    const { donationSettings = {} } = props;
    // const contentStyle = {
    //     color: '#fff',
    //     lineHeight: '160px',
    //     textAlign: 'center',
    //     background: '#364d79',
               
    //   };
      const getAddressInfoFragment = (obj={}, additionInfo) => {
        const fragmentObj = <Fragment>
            {
                obj.streetAddress1
                    ? <span className='billing-address'>{obj.streetAddress1 + ' '}
                    </span>
                    : ''
            }
            {
                obj.streetAddress2
                    ? <span className='billing-address'> {obj.streetAddress2}
                    </span>
                    : ''
            }
            {
                obj.cityName
                    ? <div className='billing-address'>
                        {
                            <span> {` ${obj.cityName || ''} ${obj.stateName ? ', ' + obj.stateName : ''} ${obj.zipCode ? ', ' + obj.zipCode : ''}`}</span>
                        }
                    </div>
                    : ''
            }
            {
                additionInfo.phoneNumber
                    ? <div className='billing-address'>
                        M - {additionInfo.phoneNumber}
                    </div> :
                    ''
            }
            {
                additionInfo.faxNumber
                    ? <div className='billing-address'>
                        Fax - {additionInfo.faxNumber}
                    </div> :
                    ''
            }
            {
                additionInfo.website
                    ? <div className='billing-address'>
                        Website - {additionInfo.website}
                    </div> :
                    ''
            }
        </Fragment>
        return fragmentObj;
    }

    // const insertNumber = (props, val) => {
    //     if (props.amount) {
    //         let amount = props.amount.slice(0, props.amountCursor) + val + props.amount.slice(props.amountCursor);
    //         props.updateState({ amount: amount, amountCursor: props.amountCursor +val.length })
    //     } else {
    //         props.updateState({ amount: val, amountCursor: props.amountCursor + val.length })
    //     }
    // }

    // const proccessPayment = () => {
        
    //     let payload = {
    //         subscriptionName: props.selectedProject.projectName,
    //         projectMasterId: props.selectedProject.projectMasterId,
    //         projectName: props.selectedProject.projectName,
    //         startDate: getMomentDateForUIReadOnly({ date: props.paymentStartDate || new Date, format: CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT }),
    //         totalOccurrences: props.neverExpires ? 9999 : props.noOfPayment,
    //         alpidePaymentGatewayId: props.selectedProject.paymentGatewayId
    //     }

    //     if (props.duration === 'weekly') {
    //         payload.intervalLength = 7;
    //         payload.unit = 'days';
    //       } else if (props.duration === 'monthly') {
    //         payload.intervalLength = 1;
    //         payload.unit = 'months';
    //       } else if (props.duration === 'yearly') {
    //         payload.intervalLength = 12;
    //         payload.unit = 'months';
    //       }
    //     props.getAuthNetPaymentUrl(props, payload)
       
    // }

    return (
        // <div className='main-container '>
            <div className=' '>
            <div className="iner-head" style={{ background: '#06033a', color: '#fff' }}>
                <Row gutter={24} style={{ background: props.onlineThemes.bgColor || '#06033A', color: props.onlineThemes.textColor || '#FFFFFF', padding: '5px', marginLeft: '0px', marginRight: '0px', marginBottom: '15px' }}className='ltiuy'>
                    <Col span={2} className='litlyu'></Col>
                    <Col span={2}>
                        <div className="brand-logo" style={{ marginTop: '10px' }}>
                            {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                        </div>
                    </Col>
                    <Col span={12} style={{ alignSelf: 'center' }}>
                        <div className='logosizef'> {props.companyInfo.storeName}</div>
                    </Col>
                    <Col span={8} style={{ alignSelf: 'center' }} className='newaddr'>
                        {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)}
                    </Col>
                </Row>
            </div>
            <Row style={{ textAlign: 'left' }}>
                <Col className="dark-inss" span={24}>

                    <div className="new-contaner1">



                <span className="medl-hedl">
                            Where do you want to donate?
                        </span>
                    <ul>
                        
                        {
                            (Object.values(donationSettings.selectedProjects || {}) || []).map((project, i) => {
                                return (
                                    <li key={i+1} className={project.projectId === props.selectedProject?.projectId ? 'active-prjectm' : ''}   style={{ fontSize: '18px' }} onClick={() => {
                                        props.updateState({ selectedProject: project });
                                        Object.assign(document.createElement('a'), {
                                            target: '_self',
                                            href: `${config.UI_BASE_URL}payment-setup?t=${props.relationshipId}_${project.paymentGatewayId || 0}_5_${project.projectMasterId}_${Math.floor((Math.random() * 10000) + 1)}`,
                                          }).click();
                                    }}>
                                        {/* <Radio checked={project.projectId === props.selectedProject?.projectId} /> */}
                                        {project.displayName || ''}
                                        {/* <Divider></Divider> */}
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <div className="clearfix"></div>
                    </div>

                  
                </Col>
                {
                // (props.selectedProject && props.selectedProject.projectId) ? 
//                 <Col span={9} className="calco">
//                     <Row>
//                         <Col span={24} className="top-headel">
                            
//                           <span>  Select an Amount to Donate</span>
                                
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col span={2}></Col>
//                         <Col span={5}  className='culb'>
//                             <Button className={props.amount === '10' ? 'ant-btn-primary' : 'ant-btn-default'}  onClick={() => {
//                                 props.updateState({ amount: '10', amountCursor: 2 });
//                             }}><span className='doloers'>$</span> 10</Button>
//                         </Col>
//                         <Col span={5} className='culb'>
//                             <Button className={props.amount === '25' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
//                                props.updateState({ amount: '25', amountCursor: 2 });
//                             }}><span className='doloers'>$</span> 25</Button>
//                         </Col>
//                         <Col span={5} className='culb'>
//                             <Button className={props.amount === '50' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
//                                 props.updateState({ amount: '50', amountCursor: 3 });
//                             }}> <span className='doloers'>$</span> 50</Button>
//                         </Col>
//                         <Col span={5} className='culb'>
//                             <Button className={props.amount === '75' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
//                                 props.updateState({ amount: '75', amountCursor: 3 });
//                             }}><span className='doloers'>$</span> 75</Button>
//                         </Col>
//                         <Col span={2}></Col>

//                         <Col span={2}></Col>
//                         <Col span={5}  className='culb'>
//                             <Button className={props.amount === '100' ? 'ant-btn-primary' : 'ant-btn-default'}  onClick={() => {
//                                 props.updateState({ amount: '100', amountCursor: 2 });
//                             }}><span className='doloers'>$</span> 100</Button>
//                         </Col>
//                         <Col span={5} className='culb'>
//                             <Button className={props.amount === '150' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
//                                props.updateState({ amount: '150', amountCursor: 2 });
//                             }}><span className='doloers'>$</span> 150</Button>
//                         </Col>
//                         <Col span={5} className='culb'>
//                             <Button className={props.amount === '200' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
//                                 props.updateState({ amount: '200', amountCursor: 3 });
//                             }}> <span className='doloers'>$</span> 200</Button>
//                         </Col>
//                         <Col span={5} className='culb'>
//                             <Button className={props.amount === '500' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
//                                 props.updateState({ amount: '500', amountCursor: 3 });
//                             }}><span className='doloers'>$</span> 500</Button>
//                         </Col>
//                         <Col span={2}></Col>
//                     </Row>
//                     <Row>
//                         <Col span={24} className="top-heade">
//                             {/* or enter your amount */}
                           
// Enter Custom Amount
//                     </Col>
//                     </Row>

//                     <Row>
//                         <Col span={3}></Col>
//                             <Col span={18} >
//                                 <Input
//                                     className='mart'
//                                     value={props.amount}
//                                     onClick={(e) => {
//                                         props.updateState({ amountCursor: e.target.selectionStart });
//                                     }}
//                                     onChange={(e) => {
//                                         const { value } = e.target;
//                                         const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
//                                         if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
//                                             props.updateState({
//                                                 amount: value,
//                                                 amountCursor: e.target.selectionStart,
//                                             });
//                                         }
//                                     }}
//                                 ></Input>
//                         </Col>
//                     </Row>

//                     {/* <Row>
//                         <Col span={3}></Col>
//                             <Col span={18}>
//                                 <Row>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '1');
//                                         //  props.updateState({ amount: (props.amount + '1') });
//                                     }}>1</span></Col>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '2');
//                                         // props.updateState({ amount: (props.amount + '2') });
//                                     }}>2</span></Col>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '3');
//                                     }}>3</span></Col>
//                                 </Row>
//                                 <Row>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '4');
//                                     }}>4</span></Col>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '5');
//                                     }}>5</span></Col>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '6');
//                                     }}>6</span></Col>
//                                 </Row>
//                                 <Row>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '7');
//                                     }}>7</span></Col>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '8');
//                                     }}>8</span></Col>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '9');
//                                     }}>9</span></Col>
//                                 </Row>
//                                 <Row>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         if (props.amount && props.amount.indexOf('.') < 0) {
//                                             let amount = props.amount.slice(0, props.amountCursor) + '.' + props.amount.slice(props.amountCursor);
//                                             props.updateState({ amount: amount, amountCursor: props.amountCursor + 1 })
//                                         } else if (!props.amount) {
//                                             props.updateState({ amount: '0.', amountCursor: props.amountCursor + 2 })
//                                         }
//                                     }}><b>.</b></span></Col>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         insertNumber(props, '0');
//                                     }}>0</span></Col>
//                                     <Col span={8} className='cul-text'><span onClick={() => {
//                                         let num = props.amount;
//                                         props.updateState({ amount: num.substring(0, num.length - 1) });
//                                     }}>
//                                         <div className="back-arow"></div>
//                                     </span></Col>
//                                 </Row>
//                             </Col>
//                     </Row> */}

//                     <Row>
//                         <Col span={2}></Col>
//                         <Col span={19}>
//                             <Row>
//                                 <Col span={7} className='culbb'>
//                                     <Button className={props.duration === 'oneTime' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
//                                         props.updateState({ duration: 'oneTime' });
//                                     }}>One-Time</Button>
//                                 </Col>

//                                 <Col span={5} className='culbb'>
//                                     <Button className={props.duration === 'daily' ? 'ant-btn-primary' : 'ant-btn-default'}  onClick={() => {
//                                         props.updateState({ duration: 'daily' });
//                                     }}>Daily</Button>
//                                 </Col>
//                                 <Col span={6} className='culbb'>
//                                     <Button className={props.duration === 'weekly' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
//                                         props.updateState({ duration: 'weekly' });
//                                     }}>Weekly</Button>
//                                 </Col>
//                                 <Col span={6} className='culbb'>
//                                     <Button className={props.duration === 'monthly' ? 'ant-btn-primary' : 'ant-btn-default'} onClick={() => {
//                                         props.updateState({ duration: 'monthly' });
//                                     }}> Monthly
//                                     </Button>
//                                 </Col>
//                                 </Row>
//                                 {props.duration && props.duration !== 'oneTime' ?
//                                     <Row>
//                                         <Col span={12} className="noty">
//                                             No. of Payment(s)
//                                 </Col>
//                                         <Col span={12} className="new-ched">
//                                             {!props.neverExpires && <div>
//                                                 <InputNumber className=' bity' style={{
//                                                     width: '150', background: 'initial', fontSize: '16px', borderBottom: 'solid 1px #cecece',
//                                                     marginBottom: '15px'
//                                                 }} value={props.noOfPayment} placeholder="" min={1} onChange={(value) => {
//                                                     props.updateState({ noOfPayment: value })
//                                                 }} /></div>
//                                             }
//                                             <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
//                                                 props.updateState({
//                                                     neverExpires: e.target.checked ? 1 : 0,
//                                                 })
//                                             }} /> Never Expires
//                                 </Col>
//                                         <Col span={12} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
//                                             Next Payment Date
//                                 </Col>
//                                         <Col span={12} className="new-ched">
//                                             <DatePicker
//                                                 allowClear={false}
//                                                 format={format}
//                                                 style={{ width: 180 }}
//                                                 // disabledDate={invalidEndDate}
//                                                 key={moment(new Date(), format)}
//                                                 defaultValue={props.paymentStartDate ? moment(new Date(props.paymentStartDate), format) : null}
//                                                 onChange={(selectedDate) => {
//                                                     props.updateState({
//                                                         paymentStartDate: selectedDate
//                                                     })
//                                                 }} />
//                                         </Col>

//                                         <Col span={7} style={{ fontSize: '15px', fontWeight: '400', padding: '0px 10px 20px 20px ' }}>
//                                             Name
//                                         </Col>
//                                         <Col span={8} className="new-ched">
//                                             <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px'}} value={props.firstName} placeholder="First Name" onChange={(e) => {
//                                                 props.updateState({ firstName: e.target.value })
//                                             }} />
//                                         </Col>
//                                         <Col span={1}></Col>
//                                         <Col span={8} className="new-ched">
//                                             <Input style={{ width: '150', background: 'initial', borderBottom: 'solid 1px #cecece', marginBottom: '15px'
//                                             }} value={props.lastName} placeholder="Last Name" onChange={(e) => {
//                                                 props.updateState({ lastName: e.target.value })
//                                             }} />
//                                         </Col>
//                                     </Row> : ''}
//                             </Col>
//                         </Row>

//                         <Row>
//                             <Col span={3}></Col>
//                             <Col span={18} className='culbs'>
//                                 <Button onClick={() => {
//                                      if (!props.amount || !Number(props.amount)) {
//                                         return showToasterMessage({ description: 'Please enter amount' });
//                                     }
//                                     if (!props.duration) {
//                                         return showToasterMessage("Please select a payment duration");
//                                     }
//                                     if (!props.neverExpires && !props.noOfPayment) {
//                                         return showToasterMessage({ description: 'Please enter No. of Payment' });
//                                     }
//                                     if (props.duration && props.duration !== 'oneTime' && (!props.firstName || !props.lastName)) {
//                                         return showToasterMessage({ description: "Please enter first name or last name"});
//                                     }
//                                     if (props.source === 'kiosk') {
//                                         const modalData = {
//                                             title: <React.Fragment>
//                                                 <div className='ant-modal-title' style={{ textAlign: 'center' }}>
//                                                     {props.selectedProject.displayName || 'Donate'}
//                                                 </div>
//                                             </React.Fragment>,
//                                             modalBody: <CardSwipe {...props} />,
//                                             width: 600,
//                                             hideFooter: true
//                                         };
//                                         props.pushModalToStack(modalData);
//                                     } else {
//                                         proccessPayment();
//                                     }
//                                 }}>Proceed to Pay ${props.amount || 0}</Button>
//                             </Col>
//                         </Row>
//                 </Col>
//                 :
//                     <Col span={12}>
//                         <Carousel autoplay>
//                             <div><img src={img2} alt="" style={contentStyle}/></div>
//                             <div><img src={img3} alt="" style={contentStyle} /> </div>
//                             <div><img src={img7} alt="" style={contentStyle} /> </div>
//                             <div><img src={img8} alt="" style={contentStyle} /> </div>
//                             <div><img src={img9} alt="" style={contentStyle} /> </div>
//                             <div><img src={img10} alt="" style={contentStyle} /> </div>
//                             <div><img src={img11} alt="" style={contentStyle} /> </div>
//                             <div><img src={img12} alt="" style={contentStyle} /> </div>
//                             <div><img src={img13} alt="" style={contentStyle} /> </div>
//                             <div><img src={img16} alt="" style={contentStyle} /> </div>
//                         </Carousel>
//                     </Col> 
 }
            </Row>
            <div className='clearfix'></div>
             <div className='pay-fot'>
                <Row gutter={24} className='pay-footer-box'>
                    <Col span={9}><span style={{ float: 'left', paddingTop: '5px' }}>{props.onlineThemes.footerMessage || 'All Rights Reserved - ' + props.companyInfo.storeName} </span> </Col>
                    <Col span={6} style={{ textAlign: 'center', color: '#333' }}>
                        {props.onlineThemes.facebookLink ? <i className="fa fa-facebook" style={{ margin: '0px 4px 0px 5px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.facebookLink) { return '' }
                                let url = props.onlineThemes.facebookLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}
                        {props.onlineThemes.instagramLink ? <i className="fa fa-instagram" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.instagramLink) { return '' }
                                let url = props.onlineThemes.instagramLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}
                        {props.onlineThemes.youtubeLink ? <i className="fa fa-youtube-play" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.youtubeLink) { return '' }
                                let url = props.onlineThemes.youtubeLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}
                        {props.onlineThemes.twitterLink ? <i className="fa fa-twitter" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.twitterLink) { return '' }
                                let url = props.onlineThemes.twitterLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}
                        {props.onlineThemes.link ? <i className="fa fa-link" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.link) { return '' }
                                let url = props.onlineThemes.link;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}
                    </Col>
                    <Col span={9}>

                        <span style={{ float: 'right', paddingTop: '5px' }}>Powered by <a href="http://alifapp.com/" target={'_blank'} >alifapp.com</a></span>
                    </Col>
                </Row>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div className='clearfix'></div>
        </div>
    )

};

export default DonationComponent;
