import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Skeleton, Pagination, Table, Input, Popconfirm } from 'antd';
import { fetchPaginationDataIfNeeded, checkACLPermission, checkSecondaryPermission, showToasterMessage } from '../../../../utils';
import {
    MODULE_CODED_VALUES, PERMISSION_VALUES, SECONDARY_PERM_KEYS, TRANSACTION_TYPES, MODAL_TYPE
} from '../../../../static/constants';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import PageBreadcrumb from '../../../PageBreadcrumb';
import './index.scss';
import cloneDeep from 'lodash/cloneDeep';
import * as debounce from 'lodash.debounce';
import { Dropdown } from '../../../general/Dropdown';
const { Search } = Input;

const EditableContext = React.createContext();

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <Input type="number" />;
        }
        return <Input />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [{ required: false }],
                            initialValue: record[dataIndex],
                            valuePropName: 'value',
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                        children
                    )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

const onSearchedInputChange = debounce((searchedText, props) => {
    handleSearchedText(searchedText, props)
}, 500);

const handleSearchedText = (searchedText, props) => {
    const payload = {
        relationshipId: props.companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: props.pageSize,
        searchedText: searchedText,
        warehouseMasterId: props.warehouseMasterId || 0
    };
    if (searchedText.length) {
        if (searchedText.length >= 3 && !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())) {
            props.resetPaginatedData();
            props.getProductCount(payload);
            props.fetchProducts(payload);
        }
    } else {
        props.resetPaginatedData();
        props.getProductCount(payload);
        props.fetchProducts(payload);
    }
}

const ProductPriceListingComp = (props) => {
    const { permissions, updateState, searchedText, companyInfo, warehouseMasterId } = props;
    const secondaryPermissions = permissions.secondary;

    const getVariantName = (data) => {
        return <div>
            {data.attributeValue1 || ''} {data.attributeValue2 ? ' | ' + data.attributeValue2 : ''} {data.attributeValue3 ? ' | ' + data.attributeValue3 : ''}
        </div>
    }

    const _getDataList = (dataList) => {
        dataList && dataList.forEach((dt, dtIndex) => {
            if (dt.hasVariant && dt.inventoryItemVariantList && dt.inventoryItemVariantList.length)
                dt.children = dt.inventoryItemVariantList;
                dt.itemNameWithVariant = dt.itemName;

            dt.children && dt.children.forEach((dt2, dt2Index) => {
                dt2.itemName = getVariantName(dt2);
                dt2.itemNameWithVariant =  dt.itemName +  '(' + (dt2.attributeValue1 || '') +  (dt2.attributeValue2 ? ' | ' + dt2.attributeValue2 : '') +  (dt2.attributeValue3 ? ' | ' + dt2.attributeValue3 : '') + ')';
                dt2.key = dtIndex + '' + dt2Index;
            })

            if (!dt.hasVariant && dt.inventoryItemVariantList && dt.inventoryItemVariantList.length) {
                dt.purchasedPrice = dt.inventoryItemVariantList[0].purchasedPrice;
                dt.wholesalePrice = dt.inventoryItemVariantList[0].wholesalePrice;
                dt.retailPrice = dt.inventoryItemVariantList[0].retailPrice;
                dt.variantId = dt.inventoryItemVariantList[0].variantId;
                dt.isPriceUpdatePendingApproval = dt.inventoryItemVariantList[0].isPriceUpdatePendingApproval;
                dt.priceUpdateApproverEmpId = dt.inventoryItemVariantList[0].priceUpdateApproverEmpId; 
                dt.b2cSellingPrice = dt.inventoryItemVariantList[0].b2cSellingPrice;
            }

        });

        return dataList || [];
    }

    let products = _getDataList(cloneDeep(props.products[props.pageNumber]));
    products = products.map((contactObj, index) => {
        contactObj.key = index+1;
        return contactObj;
    });
    const productCount = props.productCount;

    const getActionPermissionData = () => {
        const primaryPerm = permissions.primary || [];
        const permittedAction = {
            update: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PRODUCT_PRICE, PERMISSION_VALUES.UPDATE),
            create: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PRODUCT_PRICE, PERMISSION_VALUES.CREATE)
        };
        Object.keys(permittedAction).forEach((operation) => {
            if (permittedAction[operation] === false) {
                delete permittedAction[operation];
            }
        })
        return permittedAction;
    }

    const permittedAction = getActionPermissionData();
    let columnDefs = [
        {
            title: <FormattedMessage id='inventory.warehouse.stockAdjustment.listing.header.product' defaultMessage='' />,
            dataIndex: "itemName",
            key: "itemName",
            width: 400,
            className: 'leftAlignCell'
        },
        {
            title: <FormattedMessage id='inventory.warehouse.stockAdjustment.listing.header.sku' defaultMessage='' />,
            dataIndex: "sku",
            key: "sku",
            width: 215,
            className: 'leftAlignCell'
        },
        // {
        //     title: <FormattedMessage id='inventory.warehouse.productPrice.listing.header.itemCode' defaultMessage='' />,
        //     dataIndex: "sitemCodeku",
        //     key: "itemCode"
        // },
        // {
        //     title: <FormattedMessage id='inventory.warehouse.productPrice.listing.header.description' defaultMessage='' />,
        //     dataIndex: "description",
        //     key: "description"
        // },
        {
            title: <FormattedMessage id='inventory.warehouse.stockAdjustment.listing.header.category' defaultMessage='' />,
            dataIndex: "categoryName",
            key: "categoryName"
        },
        // {
        //     title: <FormattedMessage id='drawer.inventory.purchasedPrice' defaultMessage='' />,
        //     dataIndex: "purchasedPrice",
        //     key: "purchasedPrice",
        //     secondaryPermKey: SECONDARY_PERM_KEYS.PRODUCT_PRICE_LIST_PURCHASE_PRICE,
        //     editable: true,
        //     render: (purchasedPrice, row) => <div className="price-input pull-right">
        //         {purchasedPrice ? purchasedPrice.toFixed(2) : '0.00'}
        //     </div>,
        //     className: 'editable-cell'
        // },
        // {
        //     title: <FormattedMessage id='Wholesale Price' defaultMessage='' />,
        //     dataIndex: "wholesalePrice",
        //     key: "wholesalePrice",
        //     secondaryPermKey: SECONDARY_PERM_KEYS.PRODUCT_PRICE_LIST_WHOLESALE_PRICE,
        //     editable: true,
        //     render: (wholesalePrice, row) => <div className="price-input pull-right">
        //         {wholesalePrice ? wholesalePrice.toFixed(2) : '0.00'}
        //     </div>,
        //     className: 'editable-cell'
        // },
        {
            title: <FormattedMessage id='b2bSellingPrice.text' defaultMessage='' />,
            dataIndex: 'b2cSellingPrice',
            key: 'b2cSellingPrice',
            secondaryPermKey: SECONDARY_PERM_KEYS.PRODUCT_PRICE_LIST_B2B_SELLING_PRICE,
            editable: true,
            render: (b2cSellingPrice, row) => {
              return  row.hasVariant ? <div> </div>:
            <div className="price-input pull-right">
                {b2cSellingPrice ? b2cSellingPrice.toFixed(2) : '0.00'}
            </div>}
            ,
            className: 'editable-cell'
        }
    ]

    columnDefs = columnDefs.filter(obj => {
        return !obj.secondaryPermKey || checkSecondaryPermission(secondaryPermissions, obj.secondaryPermKey)
    });

    if (permittedAction.update) {
        columnDefs.push({
            title: '',
            render: (text, record) => {
                const { editingKey } = props;
                const editable = isEditing(record);
                return editable ? (
                    <span className="table-cell-action">
                        <EditableContext.Consumer>
                            {form => (
                                <Link
                                    onClick={() => save(form, record)}
                                    style={{ marginRight: 8 }}
                                >
                                    <i className="fa fa-save" />
                                </Link>
                            )}
                        </EditableContext.Consumer>
                        <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record.key)}>
                            <Link>
                                <i className="fa fa-ban" />
                            </Link>
                        </Popconfirm>
                    </span>
                ) : (
                        record.hasVariant ? <div></div>
                            : <div className="table-cell-action">
                                {record.isPriceUpdatePendingApproval ?
                                    <div>
                                        {(props.userInfo || {}).relationshipEmployeeId !== record.priceUpdateApproverEmpId ?
                                            <div>
                                                <Link disabled={editingKey !== ''} onClick={() => {
                                                    approve(record);
                                                }}>
                                                    <i title='Approved' className="fa fa-thumbs-up" />
                                                </Link>
                                                <Link disabled={editingKey !== ''} onClick={() => {
                                                    handleDelegateApprover(record);
                                                }}>
                                                    <i title='Delegate Approver' className="fa fa-share" />
                                                </Link>
                                                <Link disabled={editingKey !== ''} onClick={() => {
                                                    handleRejectApproval(record);
                                                }}>
                                                    <i title='Reject Approval' className="fa fa-thumbs-down" />
                                                </Link>
                                            </div> : ''
                                        }
                                    </div>
                                    : <span style={{cursor: 'pointer'}} disabled={editingKey !== ''} onClick={() => {
                                        edit(record.key || record.sNo);
                                    }}>
                                        <i title='Edit' className="fa fa-edit" />
                                    </span>
                                }
                            </div>
                    );
            },
            width: 150
        });
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, warehouseMasterId: warehouseMasterId };
        fetchPaginationDataIfNeeded('fetchProducts', 'products', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, warehouseMasterId: warehouseMasterId };
        props.resetPaginatedData();
        props.fetchProducts(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };
    const isEditing = record => record.key === props.editingKey;

    const cancel = () => {
        updateState({ editingKey: '' });
    };

    const save = (form, data) => {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            updateProduct(props, data, row, cancel);
        });
    }

    const edit = (key) => {
        updateState({ editingKey: key });
    }

    const approve = (record) => {
        const modalBody = 'Are you sure you want to approve?';
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.approvePriceChange({
                    relationshipId: record.relationshipId,
                    variantId: record.variantId,
                    itemId: record.itemId,
                    approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                }, props);
            },
        };
        props.showModal(modalData);
    }

    const handleDelegateApprover = (record) => {
        const formData = {
            relationshipId: record.relationshipId,
            variantId: record.variantId,
            itemId: record.itemId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            txType: TRANSACTION_TYPES.PRICE_CHANGE
        };
        const { showModal } = props;
        const data = {
            title: 'Delegate Approver',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.DELEGATE_APPROVER,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const handleRejectApproval = (record) => {
        const modalBody = 'Are you sure you want to reject?';
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.rejectPriceChange({
                    relationshipId: record.relationshipId,
                    variantId: record.variantId,
                    itemId: record.itemId,
                    approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                }, props)
            },
        };
        props.showModal(modalData);
    }

    const components = {
        body: {
            cell: EditableCell,
        },
    };

    const columns = columnDefs.map((col, index) => {
        col.key = index;
        if (!col.editable) {
            return col;
        }
        let inputType;
        if (col.dataIndex === 'b2cSellingPrice') {
            inputType = 'number'
        } else {
            inputType = 'text';
        }
        return {
            ...col,
            onCell: record => {
                const cellProps = {
                    record,
                    inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }
                return cellProps;
            },
        };
    });

    const breadCrumbList = [
        {
          name: 'Onine Store',
        },
        {
          name: 'Product Price',
        },
      ];

    return (
        <Fragment>
            <div className="page-heading">
                <FormattedMessage id='inventory.productPrice.listing.header.productPrice' defaultMessage='' />
            </div>
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
            <div className='view-container'>
                {/* <div className="page-title">
                    <FormattedMessage id='inventory.productPrice.listing.header.productPrice' defaultMessage='' />
                </div> */}
                <Skeleton loading={props.isLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    <Search
                        placeholder="Enter 3 characters product name"
                        value={searchedText}
                        onChange={e => {
                            updateState({ searchedText: e.target.value, pageNumber: 1 });
                            onSearchedInputChange(e.target.value, props);
                        }}
                        onSearch={(searchedText) => {
                            updateState({ searchedText, pageNumber: 1 });
                            onSearchedInputChange(searchedText, props);
                            handleSearchedText(searchedText, props);
                        }}
                        style={{ width: 330, height: 36 }}
                    />

                    <Dropdown
                        className='ml10'
                        style={{ width: 320 }}
                        placeholder={props.intl.formatMessage({ id: 'drawer.inventory.warehouse.placeholder', defaultMessage: '' })}
                        items={props.allWarehouses}
                        valueKeyName='warehouseName'
                        optionKeyName='warehouseName'
                        value={props.warehouseName}
                        onSelect={(optionValue, elem) => {
                            let obj = props.allWarehouses.find(warehouse => (warehouse.warehouseName === optionValue));
                            props.updateState({
                                warehouseName: optionValue,
                                warehouseMasterId: obj.warehouseMasterId,
                            });
                            const payload = { relationshipId: companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize, warehouseMasterId: obj.warehouseMasterId };
                            props.resetPaginatedData();
                            props.fetchProducts(payload);
                        }}

                    />

                    <div className='agGridWrapper d-flex w-100 pt15'>
                        <div className="ag-theme-balham nested-table product-price-table" style={agGridStyle} >
                            <EditableContext.Provider value={props.form}>
                                <Table
                                    components={components}
                                    bordered
                                    columns={columns}
                                    rowClassName="editable-row custom-design"
                                    dataSource={products || []}
                                    pagination={false} />
                            </EditableContext.Provider>
                        </div>
                    </div>
                </Skeleton>
                <Pagination
                    size="small"
                    total={productCount}
                    showTotal={showTotal}
                    defaultPageSize={100} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber, pageSize, props)
                    }}
                />
            </div>
        </Fragment>

    );
};

// const updateProduct = (props, data, record, cancelEditing) => {
//     let product = props.products[props.pageNumber].find(item => (item.itemId === data.itemId)) || {};
//     let variantList = [];
//     if (product.hasVariant) {
//         variantList = product.inventoryItemVariantList.map((variant, index) => {
//             if (variant.variantId === data.variantId) {
//                 variant.retailPrice = Number(record.retailPrice || 0);
//                 variant.purchasedPrice = Number(record.purchasedPrice || 0);
//                 variant.wholesalePrice = Number(record.wholesalePrice || 0);
//             }
//             return variant;
//         });
//     } else {
//         let variant = product.inventoryItemVariantList[0] || {};
//         variant.retailPrice = Number(record.retailPrice || 0);
//         variant.purchasedPrice = Number(record.purchasedPrice || 0);
//         variant.wholesalePrice = Number(record.wholesalePrice || 0);
//         variantList.push(variant);
//     }
//     product.inventoryItemVariantList = variantList || [];

//     const modalData = {
//         modalBody: <FormattedMessage
//             id='updateProduct'
//             defaultMessage=''
//         />,
//         handleSubmit: () => {
//             props.updateInventoryItem(product);
//             props.hideModal();
//             cancelEditing();
//         }
//     };
//     props.showModal(modalData);
// }

const updateProduct = (props, data, record, cancelEditing) => {
    let product = props.products[props.pageNumber].find(item => (item.itemId === data.itemId)) || {};
    if (!record || !record.b2cSellingPrice || record.b2cSellingPrice === '0') {
        return showToasterMessage({
            messageType: 'error', description: props.intl.formatMessage({ id: 'amount.is.required' })
        });
    }
    let payload = {
        b2cSellingPrice: Number(record.b2cSellingPrice || 0),
        // purchasedPrice: Number(record.purchasedPrice || 0),
        // wholesalePrice: Number(record.wholesalePrice || 0),
        itemId: data.itemId,
        itemName: data.itemNameWithVariant,
        updatedByEmpId: ((props.companyInfo || {}).relationshipEmployees || {}).relationshipEmployeeId,
        relationshipId: data.relationshipId,
    };
    if (data.variantId) {
        payload.variantId = data.variantId;
    }
    else {
        let variant = product.inventoryItemVariantList[0] || {};
        payload.variantId = variant.variantId;
    }

    const modalData = {
        modalBody: <FormattedMessage
            id='addUpdateData'
            defaultMessage=''
        />,
        handleSubmit: () => {
            props.updateInventoryItem(payload, props);
            props.hideModal();
            cancelEditing();
        }
    };
    props.showModal(modalData);
}
export default Form.create()(injectIntl(ProductPriceListingComp));
