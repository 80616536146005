import React , {Fragment} from 'react';
import { FormattedMessage } from 'react-intl';
import {Row, Col} from 'antd'
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS } from '../../../../../static/constants';
import { base64Toblob, getAWSFileURL, fixedDecimalNumber,  getMomentDateForUIReadOnly } from '../../../../../utils';
import {ImageCropper} from '../../../../../components/general/ImageCropper';
import {CustomUploader} from "../../../../../components/general/CustomUploader";
import { LogoComponent } from '../../../../general/LogoComponent';
// import {AWSFileTable} from '../../../../general/AWSFileTable';

const Template2 =  (props) =>{
    const { salesInvoiceDetail, companyInfo } = props;
    const { customerInvoiceDetailsList } = salesInvoiceDetail;
    const relationshipAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (salesInvoiceDetail.placeOfSupply && relationshipAddress.stateName === salesInvoiceDetail.placeOfSupply) || false;
    let itemLevelTaxes = [];
    if (customerInvoiceDetailsList) {
        itemLevelTaxes = calculateTaxRateList(customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true,
        comment: true
    };
    if (salesInvoiceDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, salesInvoiceDetail, props);
    }

    const customProps = {
        type: CONSTANTS.TYPE_IMAGE,
        imgUrl: getAWSFileURL((salesInvoiceDetail || {}).stampAwsKey, config.BUCKET_NAME.BO_CUSTOMER_INVOICE) || '',
        isSignature: true,
        height: 60,
        width: 60,
        name: 'file',
        action: null,
        buttonName: 'Upload Customer PO',
        onChange: (file) => {
            if (file[0]?.originFileObj) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    props.showModal({
                        title: <Fragment>
                            <div className='ant-modal-title'>Crop Image</div>
                        </Fragment>,
                        //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                        modalBody: <ImageCropper onClick={(e) => {
                        }} handleSubmit={(newUrl) => {
                            props.uploadStamp({
                                customerId: salesInvoiceDetail.customerId,
                                invoiceProformaId: salesInvoiceDetail.invoiceProformaId,
                                rid: salesInvoiceDetail.relationshipId,
                                objectKey: salesInvoiceDetail.stampAwsKey,
                                bucketName: props.companyInfo?.bucketName,
                                file: base64Toblob(newUrl)
                            })
                        }}{...props} url={e.target.result}/>,
                        width: 800,
                        hideFooter: true,
                        wrapClassName: 'Upload-profile-modal'
                    })
                }

                reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
            }
        }
    }


    return (
        <div className={"custom-modal show"}>
        <i className="close-icon" onClick={() =>{props.popModalFromStack();}}>
                <svg width="17" height="17">
                    <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                          d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"/>
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content full-width  vertical-view-data">
                    {/* <div className="modal-header align-left">
                    { salesInvoiceDetail.documentName || 'Sales Invoice'}
                    </div>
                    <div className="inner-content">
                        <Row>
                            <Col span={6}>
                                <div className="d-inline-flex w-100">
                                    <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/bo-relationship/'+(props.companyInfo || {}).logoAwsObjectKey}/>
                                    <div className="read-only-content pl10">
                                        <div className="title lh-adjust pb5">
                                            {props.companyInfo.storeName}
                                        </div>
                                        <div>
                                            {getAddressInfoFragment(relationshipAddress, companyInfo )}
                                            {getTaxIdentificationsFragment( ((salesInvoiceDetail.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications ) }
                                        </div>
                                       
                                    </div>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div className="read-only-content">
                                    <div className="title lh-adjust pb5">
                                        Bill to
                                    </div>
                                    <div className="title">
                                        {getContactNameFragment((salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {}))}
                                        {salesInvoiceDetail.customerName}
                                    </div>
                                    {getAddressInfoFragment(billingAddress, (salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {}) )}
                                    {getTaxIdentificationsFragment( ((salesInvoiceDetail.additionalInfoList || [])[0] || {}).taxIdentifications ) }
                                    
                                </div>
                            </Col>

                            <Col span={6}>
                                <div className="read-only-content">
                                    <div className="title lh-adjust pb5">
                                        Ship to
                                    </div>
                                    <div className="title">
                                        {salesInvoiceDetail.customerName}
                                    </div>
                                    <div>
                                        {getAddressInfoFragment(shippingAddress, {})}
                                    </div>
                                    
                                </div>
                            </Col>

                            <Col span={6}>
                                <div className="read-only-content">
                                    <Row>
                                        <Col span={10} className="title text-right">
                                            Invoice No.
                                        </Col>
                                        <Col span={14}>
                                            {salesInvoiceDetail.invoiceNumber}
                                        </Col>
                                    </Row>

                                    {salesInvoiceDetail.soNumber &&
                                        <Row>
                                            <Col span={10} className="title text-right">
                                                SO No.
                                            </Col>
                                            <Col span={14}>
                                                {salesInvoiceDetail.soNumber}
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col span={10} className="title text-right">
                                            Invoice Date
                                        </Col>
                                        <Col span={14}>
                                            {salesInvoiceDetail.invoiceDate && moment(salesInvoiceDetail.invoiceDate).format(CONSTANTS.DISPLAY_DATE_FORMAT)}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={10} className="title text-right">
                                            Due Date
                                        </Col>
                                        <Col span={14}>
                                            { salesInvoiceDetail.invoiceDueDate && moment(salesInvoiceDetail.invoiceDueDate).format(CONSTANTS.DISPLAY_DATE_FORMAT)}
                                        </Col>
                                    </Row>

                                    {
                                        !!salesInvoiceDetail.isMultiCurrency &&
                                        <Row>
                                            <Col span={10} className="title text-right">
                                                Foreign Currency
                                            </Col>
                                            <Col span={14}>
                                                <abbr>{salesInvoiceDetail.foreignCurrency}</abbr>
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        !!salesInvoiceDetail.isMultiCurrency &&
                                        <Row>
                                            <Col span={10} className="title text-right">
                                                Exchange Rate
                                            </Col>
                                            <Col span={14}>
                                                <abbr>{salesInvoiceDetail.exchangeRate}</abbr>
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        salesInvoiceDetail.projectNumber &&
                                        <Row>
                                            <Col span={10} className="title text-right">
                                                Project
                                            </Col>
                                            <Col span={14}>
                                                <abbr>{salesInvoiceDetail.projectNumber}({salesInvoiceDetail.projectName})</abbr>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </Col>
                        </Row> */}

            <div className="modal-header">

            </div>
            <div id="so-readonly" className="inner-content">
                <div className="brand-logo">
                <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/>
                    {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()}/> */}
                </div>
                <div className="doc-title">
                    {/* { salesInvoiceDetail.documentName || 'Proforma Invoice'} */}
                    <FormattedMessage id='common.proformaInvoice' defaultMessage='' />
                </div>
                <Row>
                    <Col span={16}>
                        <div className="read-only-content pt20">
                            <div className="title lh-adjust underline-text pb5">
                            <FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' />
                            </div>
                            <div className="title">
                                {getContactNameFragment((salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {}))}
                                {salesInvoiceDetail.customerName}
                            </div>
                            <div>
                                {getAddressInfoFragment(billingAddress, (salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] ||{} : {}) )}
                                {getTaxIdentificationsFragment( ((salesInvoiceDetail.additionalInfoList || [])[0] || {}).taxIdentifications ) }
                            </div>
                        </div>
                       {shippingAddress.streetAddress1 ? <div className="read-only-content pt10">
                            <div className="title lh-adjust underline-text pb5">
                            <FormattedMessage id='modal.txDetails.common.shipTo' defaultMessage='' />
                            </div>
                            <div className="title">
                                {salesInvoiceDetail.customerName}
                            </div>
                            <div>
                                {getAddressInfoFragment(shippingAddress, {})}
                            </div>
                        </div> :''}
                    </Col>
                    <Col span={4}>
                        <div className="read-only-content pt20">
                            <div className="pb5">
                                <div className="title">
                                <FormattedMessage id='common.proformaInvoice' defaultMessage='' />
                                </div>

                                <div>
                                    PI-{salesInvoiceDetail.invoiceProformaId}
                                </div>
                            </div>

                            {
                                salesInvoiceDetail.soNumber &&
                                <div className="pb5">
                                    <div className="title">
                                    <FormattedMessage id='modal.txDetails.salesOrder.soNumber' defaultMessage='' />
                                    </div>
                                    <div>
                                        <abbr>{salesInvoiceDetail.soNumber}</abbr>
                                    </div>
                                </div>
                            }

                            <div className="pb5">
                                <div className="title">
                                {/* <FormattedMessage id='common.invoiceDate' defaultMessage='' /> */}
                                <FormattedMessage id='common.dateCreated' defaultMessage='' />
                                </div>

                                <div>
                                    {(salesInvoiceDetail.dateCreated && getMomentDateForUIReadOnly({date: salesInvoiceDetail.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                </div>
                            </div>

                            {
                                salesInvoiceDetail.expiryDate &&
                                    <div className="pb5">
                                        <div className="title">
                                        <FormattedMessage id='common.dueDate' defaultMessage='' />
                                        </div>

                                        <div>
                                            {(salesInvoiceDetail.expiryDate && getMomentDateForUIReadOnly({date: salesInvoiceDetail.expiryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                        </div>
                                    </div>
                            }
                             <div className="pb5">
                                        <div className="title">
                                            <FormattedMessage id='customer.salesInvoice.listing.header.systemStatus' />
                                        </div>
                                        <div>
                                            <FormattedMessage id={salesInvoiceDetail.paymentStatus || "Unpaid"} />
                                        </div>
                                    </div>

                            {
                                !!salesInvoiceDetail.isMultiCurrency &&
                                <div className="pb5">
                                    <div className="title">
                                    <FormattedMessage id='modal.txDetails.common.foreignCurrency' defaultMessage='' />
                                    </div>

                                    <div>
                                        {salesInvoiceDetail.foreignCurrency}
                                    </div>
                                </div>
                            }

                            {
                                !!salesInvoiceDetail.isMultiCurrency &&
                                <div className="pb5">
                                    <div className="title">
                                    <FormattedMessage id='modal.txDetails.common.exchangeRate' defaultMessage='' />
                                    </div>

                                    <div>
                                        {salesInvoiceDetail.exchangeRate}
                                    </div>
                                </div>
                            }
                            {
                                !!salesInvoiceDetail.projectMasterId &&
                                <div className="pb5">
                                    <div className="title">
                                    <FormattedMessage id='modal.txDetails.common.project' defaultMessage='' />
                                    </div>
                                    <div>
                                        {salesInvoiceDetail.projectMasterId}-{salesInvoiceDetail.projectName}
                                    </div>
                                </div>
                            }
                            
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="d-inline-flex w-100">
                            <div className="read-only-content pt20 pl10">
                                <div className="title lh-adjust pb5">
                                    {props.companyInfo.storeName}
                                </div>
                                <div>
                                    {getAddressInfoFragment(relationshipAddress, companyInfo )}
                                    <div>
                                        {getTaxIdentificationsFragment( ((salesInvoiceDetail.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications ) }
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </Col>
                </Row>

                        <div className="modal-table-container vertical-space">
                            <table>
                                <thead>
                                    <tr>
                                    {props.companyInfo.countryName === 'India' && <th><FormattedMessage id='modal.txDetails.common.placeOfSupply' defaultMessage='' /></th>}
                                        <th><FormattedMessage id='common.customer.poNumber' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.txDetails.payment.refNumber' defaultMessage='' /></th>
                                        <th><FormattedMessage id='modal.txDetails.common.paymentTerm' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {props.companyInfo.countryName === 'India' && <td>{salesInvoiceDetail.placeOfSupply || '-'}</td>}
                                        <td>{salesInvoiceDetail.customerPONumber || '-'}</td>
                                        <td>{salesInvoiceDetail.referenceNumber || '-'}</td>
                                        <td>{salesInvoiceDetail.paymentTermName || ''}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-table-container mb15">
                            <table className="transaction-details-table">
                                <thead>
                                    <tr>
                                        <th className="cell-size-50">#</th>
                                        {(!isColumnEmpty.product || !isColumnEmpty.description) && <th>
                                            {!isColumnEmpty.product && <span> <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                            {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                            {!isColumnEmpty.description && <span> <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                        </th>}
                                        {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                                        {!isColumnEmpty.qty && <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                                        {!isColumnEmpty.specialDiscount && <th className="text-center"><FormattedMessage id='modal.txDetails.common.basePrice' defaultMessage='' /></th>}
                                        {!isColumnEmpty.specialDiscount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.specialDiscount' defaultMessage='' /></th>}
                                        {!isColumnEmpty.rate && <th className="text-center"><FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' /></th>}
                                        {!isColumnEmpty.hsn && <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                        {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                        {!isColumnEmpty.tax && <th className="text-right"><FormattedMessage id='modal.txDetails.common.tax' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.comment && <th>Warehouse</th>} */}
                                        <th className="text-right"><FormattedMessage id='modal.txDetails.common.netAmount' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(customerInvoiceDetailsList || []).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="cell-size-50">{i+1}</td>
                                            {(!isColumnEmpty.product || !isColumnEmpty.description)&& <td>
                                                <strong>{getVariantFragment(item)}</strong>
                                                {!isColumnEmpty.description && <div className="description-cell">
                                                    {/* <pre>{ReactHtmlParser(item.description || '-')}</pre> */}
                                                    {getDescriptionFragment(salesInvoiceDetail, item, i+1)}
                                                </div>}
                                            </td>}
                                            {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                                            {!isColumnEmpty.qty && <td className="text-center">{item.quantityToInvoice + ( item.uomName ? ' '+item.uomName : '')}</td>}
                                            {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                                            {!isColumnEmpty.specialDiscount && <td className="text-center">{fixedDecimalNumber(item.basePrice || 0 ) || '-'}</td>}
                                            {!isColumnEmpty.specialDiscount && <td className="text-right">{getSpecialDiscount(item, 'amountPercent')}</td>}
                                            {!isColumnEmpty.rate && <td className="text-center">{fixedDecimalNumber(item.anItemSalePrice || 0 ) || '-'}</td>}
                                            {!isColumnEmpty.hsn && <td className="text-center">{item.hsnCode || '-'}</td>}
                                            {!isColumnEmpty.discount &&  <td className="text-right">{getTxTypeData(item, 'discount', 'amount')} {getTxTypeData(item, 'discount', 'amountPercent')}</td>}
                                            {!isColumnEmpty.tax && <td className="text-right">{getTotalTaxAmount(item)} {getTotalTaxPercent(item, 'amountPercent')}</td>}
                                            {/* {!isColumnEmpty.comment && <td className="description-cell">
                                                    <pre>{ReactHtmlParser(item.comment || '-')}</pre>
                                                </td>} */}
                                            <td className="text-right">{getNetAmount(item) || '-'}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>

                        <Row>
                            <Col span={9} className="read-only-content">

                            {salesInvoiceDetail.docDetailList && salesInvoiceDetail.docDetailList.length ? <Row>
                                    <Col span={24} className="title">
                                    <FormattedMessage id='modal.txDetails.common.attachments' defaultMessage='' />
                                    </Col>
                                    <Col span={12}>
                                        {
                                            salesInvoiceDetail.docDetailList.map((doc) => {
                                                return <span className={"cursor-pointer"} onClick={()=>{
                                                    props.downloadFileFromBucket({
                                                        bucketName: doc.bucketName,
                                                        awsKeyName: doc.awsKeyName,
                                                        fileName: doc.fileName
                                                    })
                                                }}>{doc.fileName} &nbsp;</span>
                                            })
                                        }
                                    </Col>
                                </Row> : null}
                                {salesInvoiceDetail.remarksInternal &&
                                    <Row>
                                        <Col span={7} className="title">
                                        <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage=''/>
                                        </Col>
                                        <Col span={17}>
                                            {ReactHtmlParser(salesInvoiceDetail.remarksInternal)}
                                        </Col>
                                    </Row>
                                }

                                {salesInvoiceDetail.remarksCustomer &&
                                    <Row className="pt5">
                                        <Col span={7} className="title">
                                        <FormattedMessage id='modal.txDetails.common.customerRemarks' defaultMessage='' />
                                        </Col>
                                        <Col span={17}>
                                            {ReactHtmlParser(salesInvoiceDetail.remarksCustomer)}
                                        </Col>
                                    </Row>
                                }
                            </Col>

                            <Col offset={7} span={8}>
                                <div className="bill-summary mt5">
                                    <Row>
                                        <Col span={12}>
                                            <div className="right-txt">
                                            <FormattedMessage id='modal.txDetails.common.subTotal' defaultMessage='' />
                                            </div>
                                        </Col>

                                        <Col span={12} className="text-right ">
                                            {salesInvoiceDetail.isMultiCurrency ?
                                                <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                            }
                                            {getSubTotalOrTaxOrDiscountAmount(salesInvoiceDetail, "SubTotal")}
                                        </Col>
                                    </Row>

                                    <div className="pt10">
                                        <Row>
                                            <Col span={12}>
                                                <div className="right-txt">
                                                <FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' />
                                                </div>
                                            </Col>

                                            <Col span={12} className="text-right ">
                                                {salesInvoiceDetail.isMultiCurrency ?
                                                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                }
                                                {getSubTotalOrTaxOrDiscountAmount(salesInvoiceDetail, "Discount")}
                                            </Col>
                                        </Row>
                                    </div>
                                    { getTxTypeData(salesInvoiceDetail, 'expense', 'amount') ?
                                        <div className="pt10">
                                            <Row>
                                                <Col span={12}>
                                                    <div className="right-txt">
                                                    <FormattedMessage id='modal.txDetails.common.expense' defaultMessage='' /> 
                                                </div>
                                                </Col>

                                                <Col span={12} className="text-right ">
                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                    }
                                                    {getTxTypeData(salesInvoiceDetail, 'expense', 'amount')}
                                                </Col>
                                            </Row>
                                        </div> : ''
                                    }

                                    {
                                        (itemLevelTaxes && itemLevelTaxes.length !== 0) && itemLevelTaxes.map((taxObj, i) => {

                                            return (
                                                props.companyInfo.countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={12}>
                                                                <div className="right-txt">CGST @ {taxObj.taxPercent / 2}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                            </Col>
                                                            <Col span={12} className="text-right ">
                                                                {salesInvoiceDetail.isMultiCurrency ?
                                                                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                }
                                                                {fixedDecimalNumber(taxObj.taxAmount / 2)}
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="pt10">
                                                        <Row>
                                                            <Col span={12}>
                                                                <div className="right-txt">SGST @ {taxObj.taxPercent / 2}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                            </Col>
                                                            <Col span={12} className="text-right ">
                                                                {salesInvoiceDetail.isMultiCurrency ?
                                                                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                }
                                                                {fixedDecimalNumber(taxObj.taxAmount / 2)}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div> : <div key={i}>
                                                        <div className="pt10">
                                                            <Row>
                                                                <Col span={12}>
                                                                    <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                </Col>
                                                                <Col span={12} className="text-right ">
                                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                    }
                                                                    {fixedDecimalNumber(taxObj.taxAmount)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                ) :
                                                    <div key={i}>
                                                        <div className="pt10">
                                                            <Row>
                                                                <Col span={12}>
                                                                    <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                </Col>
                                                                <Col span={12} className="text-right ">
                                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                                    }
                                                                    {fixedDecimalNumber(taxObj.taxAmount)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    }

                                    <div className="pt10">
                                        <Row>
                                            <Col span={12}>
                                                <div className="right-txt bold">
                                                <FormattedMessage id='modal.txDetails.common.grandTotal' defaultMessage='' /> 
                                                </div>
                                            </Col>

                                            <Col span={12} className="text-right ">
                                                {salesInvoiceDetail.isMultiCurrency ?
                                                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency} </span>
                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} </span>
                                                }
                                                {salesInvoiceDetail.invoiceTotalAmount && fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount)}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="upload-link new-pic" title={props.intl.formatMessage({ id: 'uploadSignature.tooltip' })} style={{width:"50px", height:"50px"}}>
                                    <CustomUploader {...customProps} />
                                    {salesInvoiceDetail.stampAwsKey && <div className="cursor-pointer" onClick={() => {
                                        props.deleteStamp({
                                            customerId: salesInvoiceDetail.customerId,
                                            invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                            relationshipId: salesInvoiceDetail.relationshipId,
                                            objectKey: salesInvoiceDetail.stampAwsKey,
                                            folderName: salesInvoiceDetail?.folderName,
                                            bucketName: props?.customerInfo?.bucketName,
                                        }, props)
                                    }}>  <FormattedMessage id='modal.txDetails.common.deleteStamp' defaultMessage='' /> </div>}
                                </div>
                            </Col>
                        </Row>

                        <div className="contact-details">
                            {salesInvoiceDetail.footer &&
                            <Fragment>
                                {ReactHtmlParser(salesInvoiceDetail.footer)}
                            </Fragment>
                            }
                        </div>
                    </div>

                </div>
            </div>
            </div>
    );

}

const getSpecialDiscount = (row, toReturn) => {
    if (!row) return;
    let specialDiscount = row.basePrice - row.anItemSalePrice;
    if (specialDiscount && row.basePrice) {
      if (toReturn === 'amountPercent') {
        let percent = fixedDecimalNumber((specialDiscount * 100)/row.basePrice);
        let amount = fixedDecimalNumber(specialDiscount)
        return amount +" ("+ percent+")%";
      } else if (toReturn === 'amount') {
        return fixedDecimalNumber(specialDiscount)
      }

    } else if (!row && toReturn === 'amount') {
      return 0;
    }
    else {
      return "-";
    }
  }

const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
    let total = 0;
    data && data.customerInvoiceDetailsList && data.customerInvoiceDetailsList.forEach(function (d) {
        if (type === 'SubTotal') {
            total = total + d.anItemSalePrice * d.quantityToInvoice;
        }
        if (type === 'Discount') {
            let discountObject = (d.customerInvoiceCOATxList || []).find(tx => (tx.txType === 'discount'));
            if (discountObject) {
                total = total + parseFloat(discountObject.amount);
            }
        }
    });

    if (type === 'Discount' && data && data.customerInvoiceCOATxList) {
        let discount = data.customerInvoiceCOATxList.find(tx => (tx.txType === 'discount' && tx.accountingEntry === 'credit'));
        if (discount) {
            total = total + parseFloat(discount.amount);
        }
    }
    return fixedDecimalNumber(total);
}

const getTxTypeData = (row, type, toReturn) => {
  if(!row || !row.customerInvoiceCOATxList) return;
  let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type));
  if(data){
    if(data[toReturn] && toReturn === 'amountPercent'){
      if(type === 'VAT'){
        return "VAT("+data[toReturn]+"%)";
      }
      return "("+data[toReturn]+"%)";
    }else if(data[toReturn] && toReturn === 'amount'){
      return fixedDecimalNumber(data[toReturn]);
    }else{
      return ""
    }

  }else if(!data && toReturn === 'amount'){
    return "";
  }
  else{
    return "-";
  }
}
const getTotalTaxAmount = (row) => {
  let tax = getTxTypeData(row, 'tax', 'amount', true) || 0;
  let totalTax = Number(tax);
  return totalTax;

}

const getTotalTaxPercent = (row, toReturn) => {
    let tax1 = (row.customerInvoiceCOATxList || []).find(tx => (tx.txType === 'tax')) || {};
    let totalPercent = Number(tax1.amountPercent || 0);
    if (toReturn === 'amountPercent') {
        return totalPercent ? ('(' + totalPercent + "%)") : '';
    }
    else {
        return totalPercent;
    }
}

const getNetAmount = (row) => {
  let tax = getTotalTaxAmount(row) || 0;
  let discount = getTxTypeData(row, 'discount', 'amount', true) || 0;
  let netAmountItem = (Number(row.quantityToInvoice * row.anItemSalePrice)+Number(tax) - Number(discount));
  return fixedDecimalNumber(netAmountItem);

}

const calculateTaxRateList = (customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship) =>{
  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let itemLevelTaxes = []

  customerInvoiceDetailsList.forEach(function (rowdata, index) {
      let totalAmountOfColumn = 0;
      if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
          let taxObj = {
              subtotal: 0,
              taxPercent: 0,
              taxAmount: 0
          }
          totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
          let discount = getTxTypeData(rowdata, 'discount', 'amount');
          if (discount) {
              totalAmountOfColumn = totalAmountOfColumn - discount;
              totalDiscount = totalDiscount + discount;
          }

          let tax1 = (rowdata.customerInvoiceCOATxList || []).find(tx => (tx.txType === 'tax')) || {};
          taxObj.taxPercent = tax1.amountPercent;
          taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount))
          totalTax = totalTax + tax1.amount;
          taxObj.taxName = tax1.taxName;
        //  let tax2 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'sgst'));
         // let tax3 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'igst'));
         // let tax4 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'vat'));
        //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
        //       taxObj.taxPercent = tax1.amountPercent + tax2.amountPercent;
        //       taxObj.taxAmount = tax1.amount + tax2.amount;
        //       totalTax = totalTax + taxObj.taxAmount;
        //   }
        //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
        //       taxObj.taxPercent = tax3.amountPercent;
        //       taxObj.taxAmount = tax3.amount;
        //       totalTax = totalTax + tax3.amount;
        //   }
        //   else if (tax4) {
        //       taxObj.taxPercent = tax4.amountPercent;
        //       taxObj.taxAmount = tax4.amount;
        //       totalTax = totalTax + tax4.amount;
        //   }
          if (taxObj.taxPercent) {
              taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
              const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
              if (availableTaxObj) {
                  itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                      if (ele.taxPercent === taxObj.taxPercent) {
                          ele.subtotal = ele.subtotal + taxObj.subtotal;
                          ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                      }
                      return true;
                  });
              } else {
                taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0))
                  itemLevelTaxes.push(taxObj);
              }
          }
          subtotal = subtotal + totalAmountOfColumn;
      }
  });
  return itemLevelTaxes;
}

const updateColumnEmptyCheck = (isColumnEmpty, invoiceData, props) => {
  if (!invoiceData) return isColumnEmpty;
  for (let i in invoiceData.customerInvoiceDetailsList) {
    let row = invoiceData.customerInvoiceDetailsList[i];
    if (row.itemName) { isColumnEmpty.product = false; };
    if (row.serviceName) { isColumnEmpty.service = false; };
    if (row.itemVariantName) { isColumnEmpty.variant = false; }
    if (row.description) { isColumnEmpty.description = false; }
    if (row.quantityToInvoice) { isColumnEmpty.qty = false; }
    if (row.uomName) { isColumnEmpty.uomName = false; }
    if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
    if (row.specialDiscount){isColumnEmpty.specialDiscount = false;}
    if (row.hsnCode && props.companyInfo.countryName === 'India' ) { isColumnEmpty.hsn = false; }
    if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
    if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
    //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
    //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
    //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
    if(row.documentDetails && row.documentDetails.length) {isColumnEmpty.documentDetails = false;}
    if (row.comment) { isColumnEmpty.comment = false; }
  }
  return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
  if (!row) return true;
  let data = (row.customerInvoiceCOATxList || []).find(tx => (tx.txType === type));
  if (data && data.amount) {
    return false;
  }
  else {
    return true;
  }
}

  const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <div className='billing-address'>{obj.streetAddress1}
                </div>
                : ''
        }
        {
            obj.streetAddress2
                ? <div className='billing-address'>{obj.streetAddress2}
                </div>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                     <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                     <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}

    </Fragment>

    return fragmentObj;
  }

  const getTaxIdentificationsFragment = (taxIdentifications) => {
    if (!taxIdentifications) return <Fragment />;
    let taxIdentificationsList = []
    if (taxIdentifications) {
        taxIdentificationsList = JSON.parse(taxIdentifications);
    }
    const fragmentObj = <Fragment>
        {taxIdentificationsList.map((obj, i) => {
            return <div className='billing-address'>
                {obj.taxIdentificationType || ''} - {obj.taxIdentificationNumber}
            </div>
        })}
    </Fragment>

    return fragmentObj;
}

  const getContactNameFragment = (additionInfo) => {
    if (!additionInfo) return <Fragment />;
    let contactName = "";
    if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
    if (additionInfo.firstName) contactName += additionInfo.firstName;
    if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
    if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
    const fragmentObj = <Fragment>
        {
            (contactName && contactName.length)
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

const getVariantFragment = (item) => {
    let itemName = null;
    if (item.itemName) {
        itemName = item.itemName;
    }
    if (item.itemVariantName) {
        itemName = itemName + ' (' + item.itemVariantName + ')'
    }
    return itemName ? itemName : '';
}

const getDescriptionFragment = (salesInvoiceDetail, item, index) => {
    if (!item) return <Fragment />;
    const fragmentObj = <Fragment>
        {
            item.materialNumber
                ? <div>
                    <span className="title"> Material # : </span>{item.materialNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.stockNumber
                ? <div>
                    <span className="title"> Stock # : </span>{item.stockNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.partNumber
                ? <div>
                    <span className="title"> Part # : </span>{item.partNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.description
                ? <div>
                    {ReactHtmlParser(item.description || '')}
                </div> :
                ''
        }
         {
            item.origin
                ? <div>
                    <br></br>
                    <span className="title"> Origin : </span>{item.origin}
                </div> :
                ''
        }
        {
            item.hsCode
                ? <div>
                    <span className="title"> HS Code : </span>{item.hsCode}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
         {
            item.comment
                ? <div>
                    <span className="title"> Warehouse Name : </span>{item.comment}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            (salesInvoiceDetail.customerPONumber)
                ? <div> (
                    {salesInvoiceDetail.customerPONumber ? <span className="title">PO {salesInvoiceDetail.customerPONumber} - </span> : ''}
                    {index ? <span className="title"> Line {index}</span> : ''}
                )
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

export default Template2;
