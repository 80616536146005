import React from "react";
import { Form, Col, Row, Tooltip,Skeleton } from "antd";
import "@ant-design/compatible/assets/index.css";
import { injectIntl, FormattedMessage } from "react-intl";
import { TextBox } from "../../../general/TextBox";
import { Dropdown } from "../../../general/Dropdown";
import { CustomButton } from "../../../general/CustomButton";
import { ErrorMsg } from "../../../general/ErrorMessage";

const CashSaleInvoice = (props) => {
  const {
    intl,
    invoiceData,
    paymentModeDetails,
    paymentModeName,
    paymentModeList,
    cashEquivalentLedgers,
    updateModalState,
  } = props;
 
  return (
    <div className="header-action-container">
        <Skeleton loading={props.detailLoading} active paragraph={{ rows: 12 }}>

      <Form>
        <div
          className="information"
          style={{ maxHeight: "510px", height: "510px" }}
        >
          <div className="pl30">
            
            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.paymentMode"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <Dropdown
                  items={paymentModeList || []}
                  className={
                    props.submittedOnce && !props.paymentModeId
                      ? "input-text-error"
                      : ""
                  }
                  valueKeyName="paymentModeName"
                  optionKeyName="paymentModeId"
                  value={
                    paymentModeList.length
                      ? props.paymentModeName || props.paymentModeId
                      : undefined
                  }
                  placeholder={intl.formatMessage({
                    id: "modal.customer.payInvoice.paymentMode.placeholder",
                    defaultMessage: "",
                  })}
                  onSelect={(optionValue) => {
                    let selectedPaymentMode =
                      (paymentModeList || []).find(
                        (pay) => pay.paymentModeId === optionValue
                      ) || {};
                    updateModalState({
                      paymentModeId: optionValue,
                      paymentModeName: selectedPaymentMode.paymentModeName,
                      chartOfAccountDetailsId:
                        selectedPaymentMode.paymentModeName === "Cash"
                          ? props.cashInHandLedger.ledgerAccountId
                          : 0,
                      ledgerAccountName:
                        selectedPaymentMode.paymentModeName === "Cash"
                          ? props.cashInHandLedger.txType
                          : "",
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    updateModalState({
                      paymentModeId: undefined,
                      paymentModeName: undefined,
                    });
                  }}
                />
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || !!props.paymentModeId;
                  }}
                  message={
                    <FormattedMessage
                      id="modal.payInvoice.paymentModeValidation"
                      defaultMessage=""
                    />
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.paymentModeDetails"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
                <TextBox
                  type="text"
                  value={paymentModeDetails}
                  onChange={(e) => {
                    updateModalState({
                      paymentModeDetails: e.target.value,
                    });
                  }}
                  placeholder={intl.formatMessage({
                    id: "modal.customer.payInvoice.paymentModeDetails.placeholder",
                    defaultMessage: "",
                  })}
                />
              </Col>
            </Row>
            {paymentModeName && paymentModeName === "Cash" && (
              <Row>
                <Col span={6}>
                  <FormattedMessage id="cashAccount.text" defaultMessage="" />
                </Col>
                <Col span={16}>
                  <TextBox
                    type="text"
                    value={props.ledgerAccountName}
                    disabled={true}
                  />
                </Col>
              </Row>
            )}

            {paymentModeName && paymentModeName !== "Cash" && (
              <Row>
                <Col span={6}>
                  <FormattedMessage
                    id="modal.customer.payInvoice.bankAccount"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="top"
                    title={props.intl.formatMessage({
                      id: "cashEquivalents.ledgers",
                    })}
                    trigger="click"
                  >
                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                  </Tooltip>
                </Col>
                <Col span={16}>
                
                  <Dropdown
                    style={{ width: "94%" }}
                    className={
                      props.submittedOnce && !props.chartOfAccountDetailsId
                        ? "input-text-error"
                        : ""
                    }
                    items={cashEquivalentLedgers || []}
                    valueKeyName="ledgerAccountName"
                    optionKeyName="chartOfAccountDetailsId"
                    value={
                      cashEquivalentLedgers.length
                        ? props.ledgerAccountName ||
                          props.chartOfAccountDetailsId
                        : undefined
                    }
                    placeholder={intl.formatMessage({
                      id: "modal.customer.payInvoice.bankAccount.placeholder",
                      defaultMessage: "",
                    })}
                    onSelect={(optionValue) => {
                      let selectedAcc =
                        (cashEquivalentLedgers || []).find(
                          (acc) => acc.chartOfAccountDetailsId === optionValue
                        ) || {};
                      updateModalState({
                        chartOfAccountDetailsId: optionValue,
                        ledgerAccountName: selectedAcc.ledgerAccountName,
                      });
                    }}
                    allowClear={true}
                    onClear={() => {
                      updateModalState({
                        chartOfAccountDetailsId: undefined,
                        ledgerAccountName: undefined,
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return (
                        !props.submittedOnce || !!props.chartOfAccountDetailsId
                      );
                    }}
                    message={
                      <FormattedMessage
                        id="modal.payInvoice.ledgerValidation"
                        defaultMessage=""
                      />
                    }
                  />
                </Col>
              </Row>
            )}
           

            <Row>
              <Col span={6} className="detail-column">
                <div>
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.refNumber.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={16}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "supplier.purchaseRequest.form.refNumber.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  value={props.reference}
                  onChange={(e) => {
                    updateModalState({
                      reference: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <FormattedMessage
                  id="modal.customer.payInvoice.remarks"
                  defaultMessage=""
                />
              </Col>
              <Col span={16}>
              <TextBox
                  placeholder={'Enter Remark'}
                  type="text"
                  value={props.remarks}
                  onChange={(e) => {
                    updateModalState({
                      remarks: e.target.value,
                    });
                  }}
                />
              </Col>
              {/* <Col span={16}>
                {props.isRemarksSet && (
                  <CKEditor
                    type="inline"
                    className="description-input additional-padding"
                    key={`${"remarks"}`}
                    data={remarks}
                    editor={props.editor}
                    onInit={(editor) => {
                      updateModalState({
                        editor,
                      });
                    }}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      updateModalState({
                        remarks: data,
                      });
                    }}
                    config={CUSTOM_CONFIGURATION}
                  />
                )}
              </Col> */}
            </Row>
            {invoiceData && invoiceData.projectNumber && (
              <Row>
                <Col span={6}>
                  <FormattedMessage
                    id="modal.customer.payInvoice.project"
                    defaultMessage=""
                  />
                </Col>
                <Col span={16}>
                  <span>
                    {invoiceData.projectNumber +
                      " (" +
                      invoiceData.projectName +
                      ")"}
                  </span>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Form>
      <div className="form-footer">
        <CustomButton
          intlId="confirmation.cancel"
          defaultMessage=""
          type="default"
          key={0}
          style={{ marginRight: 8 }}
          onClick={() => {
            props.pushModalToStack({
              modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
              footer:{submit:{intlId:"button.close.label"}},
              handleSubmit: () => {
                props.hideModal();
              }
            })
          }}
        />
        <CustomButton
          intlId="confirmation.submit"
          defaultMessage=""
          htmlType="submit"
          key={1}
          onClick={() => {
            props.updateModalState({ submittedOnce: true });
            const paymentDetailsVal = {
              paymentModeName: props.paymentModeName,
              paymentModeId: props.paymentModeId,
              remarks: props.remarks,
              paymentModeDetail: props.paymentModeDetails,
              ledgerAccountName: props.ledgerAccountName,
              ledgerAccountId: props.chartOfAccountDetailsId,
              reference: props.reference,
            }
              props.updateState({
                paymentDetails : paymentDetailsVal,
                })
          
            // createCustomerPayment(props);
            props.popModalFromStack();
            // if (
            //   Number(props.amount) >
            //   Number(props.invoiceAmountDue || 0).toFixed(2)
            // ) {
            //   return;
            // }
            // if (
            //   !props.customerName ||
            //   !props.paymentDate ||
            //   !props.invoiceNumber ||
            //   !props.paymentModeId ||
            //   !props.chartOfAccountDetailsId ||
            //   Number(props.amount) <= 0
            // ) {
            //   return;
            // }

            // const modalData = {
            //   modalBody: (
            //     <FormattedMessage
            //       id="modal.customer.payInvoice.saveConfirmation"
            //       defaultMessage=""
            //     />
            //   ),
            //   handleSubmit: () => {
            //     const paymentDetailsVal = {
            //       paymentModeName: props.paymentModeName,
            //       paymentModeId: props.paymentModeId,
            //       remarks: props.remarks,
            //       paymentModeDetail: props.paymentModeDetails,
            //       ledgerAccountName: props.ledgerAccountName,
            //       ledgerAccountId: props.chartOfAccountDetailsId,
            //       reference: props.reference,
            //     }
            //       props.updateState({
            //         paymentDetails : paymentDetailsVal,
            //         })
              
            //     // createCustomerPayment(props);
            //     props.popModalFromStack();
            //   },
            // };
            // props.pushModalToStack(modalData);
          }}
        />
      </div>
      </Skeleton>
    </div>
  );
};

const resetInvoiceDetail = (props) => {
  props.updateModalState({
    invoiceMasterId: undefined,
    invoiceNumber: undefined,
    invoiceAmount: 0,
    amount: 0,
    invoiceAmountDue: 0,
    invoiceData: undefined,
    salesQuotationMasterId: undefined,
    quotationNumber: undefined,
    rfqNumber: undefined,
    rfqMasterId: undefined,
    reference: undefined,
  });
};

const getFormatedNextTxNumber = (props) => {
  // const { customerId } = props;
  // if (!customerId) {
  //     return <span>{props.nextTxNumber}</span>;
  // }
  // return <span>{props.nextTxNumber.replace('PAY-00', 'PAY-' + (customerId < 10 ? ('0' + customerId) : customerId))}</span>
  return <span>{props.nextTxNumber}</span>;
};

export default injectIntl(CashSaleInvoice);
