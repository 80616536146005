import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Skeleton } from "antd";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import ReactHtmlParser from "react-html-parser";
import config from "../../../../../config/environmentConfig";
import { CONSTANTS, LEDGER_TYPE } from "../../../../../static/constants";
import {
  base64Toblob,
  getAWSFileURL,
  fixedDecimalNumber,
  getMomentDateForUIReadOnly,
  numberToWordsWithDecimals,
} from "../../../../../utils";
import { ImageCropper } from "../../../../../components/general/ImageCropper";
import { CustomUploader } from "../../../../../components/general/CustomUploader";
// import { LogoComponent } from '../../../../general/LogoComponent';
import { AWSFileTable } from "../../../../general/AWSFileTable";

const PurchaseInvoiceDetails = (props) => {
  const { invoiceData, companyInfo } = props;
  const { supplierInvoiceDetailsList } = invoiceData;
  const relationshipAddress =
    find(invoiceData.boLocationPurchaseInvoiceList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};
  const billingAddress =
    find(invoiceData.boLocationPurchaseInvoiceList, {
      locationType: CONSTANTS.BILLING_ADDRESS,
    }) || {};
  const relShippingAddress =
    find(invoiceData.boLocationPurchaseInvoiceList, {
      locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
    }) || {};
  let isPlaceOfSupplySameAsRelationship = false;
  isPlaceOfSupplySameAsRelationship =
    (invoiceData.placeOfSupply &&
      billingAddress.stateName === invoiceData.placeOfSupply) ||
    false;
  let itemLevelTaxes = [];
  let itemLevelTaxeByHsn = []
  let uomList = []
  if (supplierInvoiceDetailsList) {

    itemLevelTaxes = calculateTaxRateList(
      supplierInvoiceDetailsList,
      isPlaceOfSupplySameAsRelationship, invoiceData
    );
    itemLevelTaxeByHsn = calculateTaxRateListbyHsn(supplierInvoiceDetailsList, isPlaceOfSupplySameAsRelationship, invoiceData,props)
    uomList = calculateUomData(supplierInvoiceDetailsList, isPlaceOfSupplySameAsRelationship)
  }
  let isColumnEmpty = {
    product: true,
    service: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
    rate: true,
    hsn: true,
    discount: true,
    tax: true,
    specialDiscount: true,
    documentDetails: true,
  };
  if (invoiceData) {
    isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, invoiceData, props);
  }

  const customProps = {
    type: CONSTANTS.TYPE_IMAGE,
    imgUrl:
      getAWSFileURL(
        (invoiceData || {}).stampAwsKey,
        config.BUCKET_NAME.BO_SUPPLIER_INVOICE
      ) || "",
    isSignature: true,
    height: 60,
    width: 60,
    name: "file",
    action: null,
    buttonName: "Upload Customer PO",
    onChange: (file) => {
      if (file[0]?.originFileObj) {
        var reader = new FileReader();

        reader.onload = function (e) {
          props.showModal({
            title: (
              <Fragment>
                <div className="ant-modal-title">Crop Image</div>
              </Fragment>
            ),
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                onClick={(e) => { }}
                handleSubmit={(newUrl) => {
                  props.uploadStamp({
                    supplierId: invoiceData.supplierId,
                    invoiceMasterId: invoiceData.invoiceMasterId,
                    rid: invoiceData.relationshipId,
                    objectKey: invoiceData.stampAwsKey,
                    bucketName: props.companyInfo?.bucketName,
                    file: base64Toblob(newUrl),
                  });
                }}
                {...props}
                url={e.target.result}
              />
            ),
            width: 800,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
          });
        };

        reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
      }
    },
  };
  const [showComp, setShowComp] = useState(false);
  useEffect(() => {
    const delay = setTimeout(() => {
      setShowComp(true);
    }, 400);
    return () => clearTimeout(delay);
  }, []);

  return (
    <div className={"custom-modal show"}>
      <i className="close-icon">
        <svg
          width="17"
          height="17"
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          <title>
            <FormattedMessage id="button.close.label" defaultMessage="" />
          </title>
          <path
            fill="#67757d"
            fillRule="evenodd"
            d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"
          />
        </svg>
      </i>
      <div className="modal-dialog txn-modal-dialog">
        <div className="modal-content full-width vertical-view-data">
          {/* <div className="modal-header">
                    { invoiceData.documentName || 'Purchase Invoice'}
                    </div>
                    <div className="inner-content">
                        <Row>
                            <Col span={8}>
                                <div className="d-inline-flex w-100">
                                    <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/bo-relationship/'+(props.companyInfo || {}).logoAwsObjectKey}/>
                                    <div className="read-only-content pl10">
                                        <div className="title lh-adjust pb5">
                                            {props.companyInfo.storeName}
                                        </div>
                                        <div>{getAddressInfoFragment(relationshipAddress, companyInfo)}</div>
                                        {getTaxIdentificationsFragment( ((invoiceData.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications ) }
                                        
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="read-only-content">
                                    <div className="title">
                                        {getContactNameFragment((invoiceData.additionalInfoList ? invoiceData.additionalInfoList[0] || {} : {}))}
                                        {invoiceData.supplierName}
                                    </div>
                                   <div> { getAddressInfoFragment(billingAddress, (invoiceData.additionalInfoList ? invoiceData.additionalInfoList[0] ||{} : {}) )}</div>
                                   {getTaxIdentificationsFragment( ((invoiceData.additionalInfoList || [])[0] || {}).taxIdentifications ) }

                                </div>
                            </Col>

                            <Col span={6} offset={2} className="read-only-content">
                                <Row>
                                    <Col span={12} className="text-right">
                                        <span className="title">Invoice No.</span>
                                    </Col>
                                    <Col span={12}>
                                        {invoiceData.invoiceNumber}
                                    </Col>
                                </Row>

                                {
                                    invoiceData.supplierPoNumber &&
                                    <Row>
                                        <Col span={12} className="text-right">
                                            <span className="title">PO No.</span>
                                        </Col>
                                        <Col>
                                            {invoiceData.supplierPoNumber}
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col span={12} className="text-right">
                                        <span className="title">
                                            Invoice Date
                                        </span>
                                    </Col>
                                    <Col span={12}>
                                        {(invoiceData.invoiceDate  && moment(invoiceData.invoiceDate).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12} className="text-right">
                                        <span className="title">Due Date</span>
                                    </Col>
                                    <Col span={12}>
                                        {(invoiceData.invoiceDueDate  && moment(invoiceData.invoiceDueDate).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
                                    </Col>
                                </Row>

                                {
                                    invoiceData.isMultiCurrency ?
                                        <Row>
                                            <Col span={12} className="text-right">
                                                <span className="title">Foreign Currency </span>
                                            </Col>
                                            <Col span={12}>
                                                {invoiceData.foreignCurrency}
                                            </Col>
                                        </Row> : ''
                                }
                                {!!invoiceData.isMultiCurrency &&
                                    <Row>
                                        <Col span={12} className="text-right">
                                            <span className="title">Exchange Rate </span>
                                        </Col>
                                        <Col span={12}>
                                            {invoiceData.exchangeRate}
                                        </Col>
                                    </Row>
                                }
                                {
                                    invoiceData.isDropShipping ?
                                        <Row>
                                            <Col span={12} className="text-right">
                                                <span className="title">Dropship Order? </span>
                                            </Col>
                                            <Col span={12}>
                                                Yes
                                            </Col>
                                        </Row> : ''
                                }
                                {
                                    invoiceData.projectNumber &&
                                    <Row>
                                        <Col span={12} className="text-right">
                                            <span className="title">Project</span>
                                        </Col>
                                        <Col span={12}>
                                            {invoiceData.projectNumber +' ('+invoiceData.projectName+ ')'}
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row> */}

          <div className="modal-header"></div>
          {showComp ?

            <div id="so-readonly" className="inner-content">
              <div className="brand-logo">
                {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()}/> */}
              </div>
              <div className="doc-title">
                {invoiceData.documentName || "Purchase Invoice"}
              </div>
              <Row>
                <Col span={16}>
                  <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                    <div className="read-only-content pt20">
                      <div className="title lh-adjust underline-text pb5">
                        <FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' />
                      </div>
                      <div className="title">{props.companyInfo.storeName}</div>
                      <div>
                        {/* {getAddressInfoFragment(billingAddress, (invoiceData.additionalInfoList ? invoiceData.additionalInfoList[0] ||{} : {}) )}
                    {getTaxIdentificationsFragment( ((invoiceData.additionalInfoList || [])[0] || {}).taxIdentifications ) } */}
                        {getAddressInfoFragment(relationshipAddress, companyInfo)}
                        <div> {invoiceData.additionalInfoList
                          ? (invoiceData.additionalInfoList[0] || {})?.relationshipPhoneNumber
                          : ""}</div>
                        <div>
                          {getTaxIdentificationsFragment(
                            ((invoiceData.additionalInfoList || [])[0] || {})
                              .relationshipTaxIdentifications
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="read-only-content pt10">
                      <div className="title lh-adjust underline-text pb5">
                        <FormattedMessage
                          id="modal.txDetails.common.shipTo"
                          defaultMessage=""
                        />
                      </div>
                      {/* <div className="title">
                    {invoiceData.supplierName}
                </div> */}
                      <div>{getAddressInfoFragment(relShippingAddress, {})}</div>
                    </div>
                  </Skeleton>
                </Col>
                <Col span={4}>
                  <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                    <div className="read-only-content pt20">
                      <div className="pb5">
                        <div className="title">
                          <FormattedMessage
                            id="modal.txDetails.purchaseInvoice.invoiceNumber"
                            defaultMessage=""
                          />
                        </div>

                        <div>{invoiceData.invoiceNumber}</div>
                      </div>

                      {invoiceData.supplierInvoiceNumber && (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.purchaseOrder.supplierInvoiceNumber"
                              defaultMessage=""
                            />
                          </div>

                          <div>{invoiceData.supplierInvoiceNumber}</div>
                        </div>
                      )}
                      {invoiceData.supplierPoNumber && (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.purchaseOrder.poNumber"
                              defaultMessage=""
                            />
                          </div>

                          <div>{invoiceData.supplierPoNumber}</div>
                        </div>
                      )}
                      <div className="pb5">
                        <div className="title">
                          <FormattedMessage
                            id="modal.txDetails.purchaseInvoice.invoiceDate"
                            defaultMessage=""
                          />
                        </div>

                        <div>
                          {invoiceData.invoiceDate &&
                            getMomentDateForUIReadOnly({
                              date: invoiceData.invoiceDate,
                              format: CONSTANTS.DISPLAY_DATE_FORMAT,
                            })}
                        </div>
                      </div>
                      {invoiceData.invoiceDueDate && (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.purchaseInvoice.dueDate"
                              defaultMessage=""
                            />
                          </div>

                          <div>
                            {invoiceData.invoiceDueDate &&
                              getMomentDateForUIReadOnly({
                                date: invoiceData.invoiceDueDate,
                                format: CONSTANTS.DISPLAY_DATE_FORMAT,
                              })}
                          </div>
                        </div>
                      )}
                      {invoiceData.customerId ? (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="common.customer.text"
                              defaultMessage=""
                            />
                          </div>
                          <div>
                            {invoiceData.customerId ? invoiceData.customerName : ""}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {!!invoiceData.isMultiCurrency ? (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.common.foreignCurrency"
                              defaultMessage=""
                            />
                          </div>

                          <div>{invoiceData.foreignCurrency}</div>
                        </div>
                      ) : (
                        ""
                      )}

                      {!!invoiceData.isMultiCurrency ? (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.common.exchangeRate"
                              defaultMessage=""
                            />
                          </div>

                          <div>{invoiceData.exchangeRate}</div>
                        </div>
                      ) : (
                        ""
                      )}
                      {invoiceData.projectMasterId ? (
                        <div className="pb5">
                          <div className="title">
                            <FormattedMessage
                              id="modal.txDetails.common.project"
                              defaultMessage=""
                            />
                          </div>
                          <div>
                            <abbr>
                              {/* {invoiceData.projectMasterId}- */}
                              {invoiceData.projectName}
                            </abbr>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Skeleton>
                </Col>
                <Col span={4}>
                  <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                    <div className="d-inline-flex w-100">
                      <div className="read-only-content pt20 pl10">
                        <div className=" lh-adjust pb5">
                          <div>
                            <div>
                              <div className="title">
                                {invoiceData.supplierName}
                              </div>
                              <div>
                                {getAddressInfoFragment(
                                  billingAddress,
                                  invoiceData.additionalInfoList
                                    ? invoiceData.additionalInfoList[0] || {}
                                    : {}
                                )}
                                <div> {invoiceData.additionalInfoList
                                  ? (invoiceData.additionalInfoList[0] || {})?.supplierEmail
                                  : ""}</div>
                                <div>
                                  {invoiceData.additionalInfoList
                                    ? (invoiceData.additionalInfoList[0] || {})?.supplierPhone
                                    : ""}
                                </div>
                                {getTaxIdentificationsFragment(
                                  ((invoiceData.additionalInfoList || [])[0] || {})
                                    .taxIdentifications
                                )}
                              </div>



                            </div>

                          </div>
                          <div className="pb5">
                            {getContactNameFragment(
                              invoiceData.additionalInfoList
                                ? invoiceData.additionalInfoList[0] || {}
                                : {}
                            )}
                          </div>

                        </div>

                      </div>
                    </div>
                  </Skeleton>
                </Col>
              </Row>
              <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

                <div className="modal-table-container vertical-space">
                  <table>
                    <thead>
                      <tr>
                        {invoiceData.supplierQuoteNumber && (
                          <th>
                            <FormattedMessage
                              id="modal.txDetails.purchaseOrder.supplierQuoteNumber"
                              defaultMessage=""
                            />
                          </th>
                        )}
                        <th>
                          <FormattedMessage
                            id="modal.txDetails.common.refNumber"
                            defaultMessage=""
                          />
                        </th>
                        {props.companyInfo.countryName === "India" && (
                          <th>
                            <FormattedMessage
                              id="modal.txDetails.common.placeOfSupply"
                              defaultMessage=""
                            />
                          </th>
                        )}
                        {/* <th>Shipping Address</th> */}
                        <th>
                          <FormattedMessage
                            id="modal.txDetails.common.paymentTerm"
                            defaultMessage=""
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {invoiceData.supplierQuoteNumber && (
                          <th>{invoiceData.supplierQuoteNumber || "-"}</th>
                        )}
                        <td>{invoiceData.referenceNumber || "-"}</td>
                        {props.companyInfo.countryName === "India" && (
                          <td>{invoiceData.placeOfSupply || "-"}</td>
                        )}
                        {/* <td>{relShippingAddress ?
                                         getAddressInfoFragment(relShippingAddress, {} )
                                            : '-'}
                                        </td> */}
                        <td>{invoiceData.paymentTermName || "-"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="modal-table-container mb15">
                  <table className="transaction-details-table">
                    <thead>
                      <tr>
                        <th className="cell-size-50">#</th>
                        {(!isColumnEmpty.product || !isColumnEmpty.description) && (
                          <th>
                            {!isColumnEmpty.product && (
                              <span>
                                {" "}
                                <FormattedMessage
                                  id="modal.txDetails.common.product"
                                  defaultMessage=""
                                />
                              </span>
                            )}
                            {!isColumnEmpty.product &&
                              !isColumnEmpty.description && <span> & </span>}
                            {!isColumnEmpty.description && (
                              <span>
                                {" "}
                                <FormattedMessage
                                  id="modal.txDetails.common.description"
                                  defaultMessage=""
                                />
                              </span>
                            )}
                          </th>
                        )}
                        {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                        {!isColumnEmpty.qty && (
                          <th className="text-center">
                            <FormattedMessage
                              id="modal.txDetails.common.qty"
                              defaultMessage=""
                            />
                          </th>
                        )}
                        {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                        {!isColumnEmpty.specialDiscount && (
                          <th className="text-right">
                            <FormattedMessage
                              id="modal.txDetails.common.basePrice"
                              defaultMessage=""
                            />
                          </th>
                        )}
                        {!isColumnEmpty.specialDiscount && (
                          <th className="text-right">
                            <FormattedMessage
                              id="modal.txDetails.common.specialDiscount"
                              defaultMessage=""
                            />
                          </th>
                        )}
                        {!isColumnEmpty.rate && (
                          <th className="text-center">
                            <FormattedMessage
                              id="modal.txDetails.common.rate"
                              defaultMessage=""
                            />
                          </th>
                        )}
                        {!isColumnEmpty.hsn && (
                          <th className="text-right">
                            <FormattedMessage
                              id="modal.txDetails.common.hsn"
                              defaultMessage=""
                            />
                          </th>
                        )}
                        {!isColumnEmpty.discount && (
                          <th className="text-right">
                            <FormattedMessage
                              id="modal.txDetails.common.discount"
                              defaultMessage=""
                            />
                          </th>
                        )}
                        {!isColumnEmpty.tax && (
                          <th className="text-right">
                            <FormattedMessage
                              id="modal.txDetails.common.tax"
                              defaultMessage=""
                            />
                          </th>
                        )}
                        {<th className="text-right">Taxable Amount</th>}

                        <th className="text-right">
                          <FormattedMessage
                            id="modal.txDetails.common.netAmount"
                            defaultMessage=""
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(supplierInvoiceDetailsList || []).sort((a, b) => Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td className="cell-size-50">{i + 1}</td>
                            {(!isColumnEmpty.product ||
                              !isColumnEmpty.description) && (
                                <td>
                                  <strong>{getVariantFragment(item)}</strong>
                                  {!isColumnEmpty.description && (
                                    <div className="description-cell">
                                      <pre>
                                        {ReactHtmlParser(item.description || "-")}
                                      </pre>
                                    </div>
                                  )}
                                </td>
                              )}
                            {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                            {!isColumnEmpty.qty && <td className="text-center">{fixedDecimalNumber(item.quantity || 0)}   {(item.uomName ? ' ' + item.uomName : '')}</td>}

                            {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                            {!isColumnEmpty.specialDiscount && (
                              <td className="text-right">
                                {fixedDecimalNumber(item.basePrice || 0) || "-"}
                              </td>
                            )}
                            {!isColumnEmpty.specialDiscount && (
                              <td className="text-right">
                                {getSpecialDiscount(item, "amountPercent")}
                              </td>
                            )}
                            {!isColumnEmpty.rate && (
                              <td className="text-center">
                                {fixedDecimalNumber(item.itemPurchasedPrice || 0) ||
                                  "-"}
                              </td>
                            )}
                            {!isColumnEmpty.hsn && (
                              <td className="text-right">{item.hsnCode || "-"}</td>
                            )}
                            {!isColumnEmpty.discount && (
                              <td className="text-right">
                                {getDiscountAmount(item, "amount")}{" "}
                                {getDiscountAmount(item, "amountPercent")}
                              </td>
                            )}
                            {!isColumnEmpty.tax && (
                              <td className="text-right">
                                {getTotalTaxAmount(item, isPlaceOfSupplySameAsRelationship)}{" "}
                                {getTotalTaxPercent(item, "amountPercent", isPlaceOfSupplySameAsRelationship)}
                              </td>
                            )}
                            {<td className="text-right">{getNetAmount(item, isPlaceOfSupplySameAsRelationship, true)}</td>}

                            <td className="text-right">
                              {getNetAmount(item, isPlaceOfSupplySameAsRelationship) || "-"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>


                <Row>

                  <Col span={9}>

                    <Row>
                      <Col span={24}>
                        {getUomFragment(uomList, isPlaceOfSupplySameAsRelationship)}
                      </Col>
                    </Row>
                    {invoiceData.docDetailList &&
                      invoiceData.docDetailList.length ? (
                      <Row>
                        <Col span={24} className="title">
                          <FormattedMessage
                            id="modal.txDetails.common.attachments"
                            defaultMessage=""
                          />
                        </Col>
                        <Col span={12}>
                          <AWSFileTable
                            {...props}
                            docDetailList={invoiceData.docDetailList}
                            fetchTxDetail={(data) => {
                              props.fetchPurchasedInvoiceDetail({
                                supplierId: invoiceData.supplierId,
                                invoiceMasterId: invoiceData.invoiceMasterId,
                                relationshipId: invoiceData.relationshipId,
                              });
                            }}
                          />
                          {/* {
                                            invoiceData.docDetailList.map((doc) => {
                                                return <span className={"cursor-pointer"} onClick={()=>{
                                                    props.downloadFileFromBucket({
                                                        bucketName: doc.bucketName,
                                                        awsKeyName: doc.awsKeyName,
                                                        fileName: doc.fileName
                                                    })
                                                }}>{doc.fileName} &nbsp;</span>
                                            })
                                        } */}
                        </Col>
                      </Row>
                    ) : null}
                    <br />

                    {invoiceData.remarksInternal && (
                      <><Row className="read-only-content">
                        <Col span={7} className="title">
                          <FormattedMessage
                            id="modal.txDetails.common.internalRemarks"
                            defaultMessage="" />
                        </Col>
                      </Row><Row gutter={[16, 16]}>

                          <Col span={17}>
                            {ReactHtmlParser(invoiceData.remarksInternal)}
                          </Col>
                        </Row></>
                    )}

                    {invoiceData.remarksSupplier && (
                      <><Row className="read-only-content">
                        <Col span={7} className="title">
                          <FormattedMessage
                            id="modal.txDetails.common.supplierRemarks"
                            defaultMessage="" />
                        </Col>
                      </Row><Col span={17}>
                          {ReactHtmlParser(invoiceData.remarksSupplier)}
                        </Col></>
                    )}
                  </Col>
                  <Col offset={7} span={8}>
                    <div className="bill-summary mt5">
                      <Row>
                        <Col span={12}>
                          <div className="right-txt">
                            <FormattedMessage
                              id="modal.txDetails.common.subTotal"
                              defaultMessage=""
                            />
                          </div>
                        </Col>

                        <Col span={12} className="text-right ">
                          {invoiceData.isMultiCurrency ? (
                            <span>
                              {" "}
                              {invoiceData.foreignCurrencyIcon ? (
                                <i className={invoiceData.foreignCurrencyIcon}></i>
                              ) : (
                                invoiceData.foreignCurrency
                              )}{" "}
                            </span>
                          ) : (
                            <span>
                              {" "}
                              {props.companyInfo.currencyIcon ? (
                                <i className={props.companyInfo.currencyIcon}></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}{" "}
                            </span>
                          )}{" "}
                          {getSubTotalOrTaxOrDiscountAmount(
                            invoiceData,
                            "SubTotal"
                          )}
                        </Col>
                      </Row>
                      {invoiceData.supplierInvoiceCOATxList &&
                        invoiceData.supplierInvoiceCOATxList.length &&
                        filter(invoiceData.supplierInvoiceCOATxList || [], {
                          txType: "discount",
                          accountingEntry: "debit",
                        }).map((discountObject, i) => {
                          return (
                            <div key={"desc" + i}>
                              <Row className="pt10">
                                <Col span={14}>
                                  <div className="right-txt">
                                    <FormattedMessage
                                      id="modal.txDetails.common.discount"
                                      defaultMessage=""
                                    />
                                    (
                                    {Number(discountObject.amountPercent).toFixed(
                                      2
                                    )}
                                    %)
                                  </div>
                                </Col>
                                <Col span={10} className="text-right">
                                  {invoiceData.isMultiCurrency ? (
                                    <span>
                                      {" "}
                                      {invoiceData.foreignCurrencyIcon ? (
                                        <i
                                          className={
                                            invoiceData.foreignCurrencyIcon
                                          }
                                        ></i>
                                      ) : (
                                        invoiceData.foreignCurrency
                                      )}{" "}
                                    </span>
                                  ) : (
                                    <span>
                                      {" "}
                                      {props.companyInfo.currencyIcon ? (
                                        <i
                                          className={props.companyInfo.currencyIcon}
                                        ></i>
                                      ) : (
                                        props.companyInfo.currencyCode + " "
                                      )}{" "}
                                    </span>
                                  )}
                                  {fixedDecimalNumber(discountObject.amount)}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                    {getTxTypeData(invoiceData, 'discount', 'amount')  ?
                      <div className="pt10">
                        <Row>
                          <Col span={14}>
                            <div className="right-txt">
                              <FormattedMessage
                                id="totalDiscount.text"
                                defaultMessage=""
                              />
                            </div>
                          </Col>

                          <Col span={10} className="text-right ">
                            {invoiceData.isMultiCurrency ? (
                              <span>
                                {" "}
                                {invoiceData.foreignCurrencyIcon ? (
                                  <i
                                    className={invoiceData.foreignCurrencyIcon}
                                  ></i>
                                ) : (
                                  invoiceData.foreignCurrency
                                )}{" "}
                              </span>
                            ) : (
                              <span>
                                {" "}
                                {props.companyInfo.currencyIcon ? (
                                  <i className={props.companyInfo.currencyIcon}></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}{" "}
                              </span>
                            )}{" "}
                            {getSubTotalOrTaxOrDiscountAmount(
                              invoiceData,
                              "Discount"
                            )}
                          </Col>
                        </Row>
                      </div>
                      :""}
                      {invoiceData.supplierInvoiceCOATxList &&
                        invoiceData.supplierInvoiceCOATxList.length &&
                        filter(invoiceData.supplierInvoiceCOATxList || [], {
                          txType: "expense",
                          accountingEntry: "debit",
                        }).map((expenseObject, i) => {
                          return (
                            <div key={"exp" + i}>
                              <Row className="pt10">
                                <Col span={14}>
                                  <div className="right-txt">
                                    <FormattedMessage
                                      id="modal.txDetails.common.expense"
                                      defaultMessage=""
                                    />
                                    ({expenseObject.ledgerAccountName || ""})
                                  </div>
                                </Col>
                                <Col span={10} className="text-right">
                                  {invoiceData.isMultiCurrency ? (
                                    <span>
                                      {" "}
                                      {invoiceData.foreignCurrencyIcon ? (
                                        <i
                                          className={
                                            invoiceData.foreignCurrencyIcon
                                          }
                                        ></i>
                                      ) : (
                                        invoiceData.foreignCurrency
                                      )}{" "}
                                    </span>
                                  ) : (
                                    <span>
                                      {" "}
                                      {props.companyInfo.currencyIcon ? (
                                        <i
                                          className={props.companyInfo.currencyIcon}
                                        ></i>
                                      ) : (
                                        props.companyInfo.currencyCode + " "
                                      )}{" "}
                                    </span>
                                  )}
                                  {fixedDecimalNumber(expenseObject.amount)}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      {getTxTypeData(invoiceData, "expense", "amount") ? (
                        <div className="pt10">
                          <Row>
                            <Col span={14}>
                              <div className="right-txt">
                                <FormattedMessage
                                  id="total.text"
                                  defaultMessage=""
                                />{" "}
                                <FormattedMessage
                                  id="modal.txDetails.common.expense"
                                  defaultMessage=""
                                />
                              </div>
                            </Col>

                            <Col span={10} className="text-right">
                              {invoiceData.isMultiCurrency ? (
                                <span>
                                  {" "}
                                  {invoiceData.foreignCurrencyIcon ? (
                                    <i
                                      className={invoiceData.foreignCurrencyIcon}
                                    ></i>
                                  ) : (
                                    invoiceData.foreignCurrency
                                  )}{" "}
                                </span>
                              ) : (
                                <span>
                                  {" "}
                                  {props.companyInfo.currencyIcon ? (
                                    <i
                                      className={props.companyInfo.currencyIcon}
                                    ></i>
                                  ) : (
                                    props.companyInfo.currencyCode + " "
                                  )}{" "}
                                </span>
                              )}
                              {/* {getTxTypeData(invoiceData, 'expense', 'amount')} */}
                              {getSubTotalOrTaxOrDiscountAmount(
                                invoiceData,
                                "expense"
                              )}
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        ""
                      )}
                      {itemLevelTaxes &&
                        itemLevelTaxes.length !== 0 &&
                        itemLevelTaxes.map((taxObj, i) => {
                          return props.companyInfo.countryName === "India" ? (
                            isPlaceOfSupplySameAsRelationship ? (
                              <div key={i}>
                                <div className="pt10">
                                  <Row>
                                    <Col span={14}>

                                      <div className="right-txt">
                                        CGST @ {taxObj.taxPercent}% on{" "}
                                        {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                      </div>
                                    </Col>
                                    <Col span={10} className="text-right">
                                      {invoiceData.isMultiCurrency ? (
                                        <span>
                                          {" "}
                                          {invoiceData.foreignCurrencyIcon ? (
                                            <i
                                              className={
                                                invoiceData.foreignCurrencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            invoiceData.foreignCurrency
                                          )}{" "}
                                        </span>
                                      ) : (
                                        <span>
                                          {" "}
                                          {props.companyInfo.currencyIcon ? (
                                            <i
                                              className={
                                                props.companyInfo.currencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            props.companyInfo.currencyCode + " "
                                          )}{" "}
                                        </span>
                                      )}
                                      {fixedDecimalNumber(taxObj.taxAmount)}
                                    </Col>
                                  </Row>
                                </div>

                                <div className="pt10">
                                  <Row>
                                    <Col span={14}>

                                      <div className="right-txt">
                                        SGST @ {taxObj.taxPercent}% on{" "}
                                        {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                      </div>
                                    </Col>
                                    <Col span={10} className="text-right">
                                      {invoiceData.isMultiCurrency ? (
                                        <span>
                                          {" "}
                                          {invoiceData.foreignCurrencyIcon ? (
                                            <i
                                              className={
                                                invoiceData.foreignCurrencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            invoiceData.foreignCurrency
                                          )}{" "}
                                        </span>
                                      ) : (
                                        <span>
                                          {" "}
                                          {props.companyInfo.currencyIcon ? (
                                            <i
                                              className={
                                                props.companyInfo.currencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            props.companyInfo.currencyCode + " "
                                          )}{" "}
                                        </span>
                                      )}
                                      {fixedDecimalNumber(taxObj.taxAmount)}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ) : (
                              <div key={i}>
                                <div className="pt10">
                                  <Row>
                                    <Col span={14}>
                                      {/* <div></div> */}
                                      <div className="right-txt">
                                        {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                                        {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                      </div>
                                    </Col>
                                    <Col span={10} className="text-right">
                                      {invoiceData.isMultiCurrency ? (
                                        <span>
                                          {" "}
                                          {invoiceData.foreignCurrencyIcon ? (
                                            <i
                                              className={
                                                invoiceData.foreignCurrencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            invoiceData.foreignCurrency
                                          )}{" "}
                                        </span>
                                      ) : (
                                        <span>
                                          {" "}
                                          {props.companyInfo.currencyIcon ? (
                                            <i
                                              className={
                                                props.companyInfo.currencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            props.companyInfo.currencyCode + " "
                                          )}{" "}
                                        </span>
                                      )}
                                      {fixedDecimalNumber(taxObj.taxAmount)}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            )
                          ) : (
                            <div key={i}>
                              <Row className="pt10">
                                <Col span={14}>
                                  <div className="right-txt">
                                    {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                                    {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                  </div>
                                </Col>
                                <Col span={10} className="text-right ">
                                  {invoiceData.isMultiCurrency ? (
                                    <span>
                                      {" "}
                                      {invoiceData.foreignCurrencyIcon ? (
                                        <i
                                          className={
                                            invoiceData.foreignCurrencyIcon
                                          }
                                        ></i>
                                      ) : (
                                        invoiceData.foreignCurrency
                                      )}{" "}
                                    </span>
                                  ) : (
                                    <span>
                                      {" "}
                                      {props.companyInfo.currencyIcon ? (
                                        <i
                                          className={props.companyInfo.currencyIcon}
                                        ></i>
                                      ) : (
                                        props.companyInfo.currencyCode + " "
                                      )}{" "}
                                    </span>
                                  )}
                                  {fixedDecimalNumber(taxObj.taxAmount)}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      {getTxTypeData(invoiceData, LEDGER_TYPE.TYPE_ROUNDING_OFF, 'amount') ?
                        <div>
                          <Row className="pt10">
                            <Col span={14}>
                              <div className="right-txt">
                                <FormattedMessage
                                  id="common.salesRoundingOff.label"
                                  defaultMessage=""
                                />
                              </div>
                            </Col>
                            <Col span={10} className="text-right">
                              {invoiceData.isMultiCurrency ? (
                                <span>
                                  {" "}
                                  {invoiceData.foreignCurrencyIcon ? (
                                    <i
                                      className={invoiceData.foreignCurrencyIcon}
                                    ></i>
                                  ) : (
                                    invoiceData.foreignCurrency
                                  )}{" "}
                                </span>
                              ) : (
                                <span>
                                  {" "}
                                  {props.companyInfo.currencyIcon ? (
                                    <i className={props.companyInfo.currencyIcon}></i>
                                  ) : (
                                    props.companyInfo.currencyCode + " "
                                  )}{" "}
                                </span>
                              )}{" "}
                              {getTxTypeData(invoiceData, LEDGER_TYPE.TYPE_ROUNDING_OFF, 'amount')}
                            </Col>
                          </Row>
                        </div> : ''
                      }
                      <div>
                        <Row className="pt10">
                          <Col span={14}>
                            <div className="right-txt bold">
                              <FormattedMessage
                                id="modal.txDetails.common.grandTotal"
                                defaultMessage=""
                              />
                            </div>
                          </Col>

                          <Col span={10} className="text-right">
                            {invoiceData.isMultiCurrency ? (
                              <span>
                                {" "}
                                {invoiceData.foreignCurrencyIcon ? (
                                  <i
                                    className={invoiceData.foreignCurrencyIcon}
                                  ></i>
                                ) : (
                                  invoiceData.foreignCurrency
                                )}{" "}
                              </span>
                            ) : (
                              <span>
                                {" "}
                                {props.companyInfo.currencyIcon ? (
                                  <i className={props.companyInfo.currencyIcon}></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}{" "}
                              </span>
                            )}{" "}
                            {(invoiceData.invoiceTotalAmount &&
                              fixedDecimalNumber(invoiceData.invoiceTotalAmount)) ||
                              ""}
                          </Col>
                          <Col span={24} className="">
                            {invoiceData.isMultiCurrency ?
                              <span> {invoiceData.foreignCurrency + ": "} </span>
                              : <span> {props.companyInfo.currencyCode + ": "} </span>
                            }    {(invoiceData.invoiceTotalAmount && numberToWordsWithDecimals(fixedDecimalNumber(invoiceData.invoiceTotalAmount), props.companyInfo.currencyCode === "INR" ? "Paise" : "Cent")) || ''}
                          </Col>

                        </Row>
                      </div>

                      <Row>
                        <Col span={24}>
                          {props.companyInfo.countryName === 'India' ? getHsnFragment(itemLevelTaxeByHsn, isPlaceOfSupplySameAsRelationship) : ''}
                        </Col>
                      </Row>


                    </div>

                    <div
                      className="upload-link new-pic"
                      title={props.intl.formatMessage({
                        id: "uploadSignature.tooltip",
                      })}
                      style={{ width: "50px", height: "50px" }}
                    >
                      <CustomUploader {...customProps} />
                      {invoiceData.stampAwsKey && (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            props.deleteStamp(
                              {
                                supplierId: invoiceData.supplierId,
                                invoiceMasterId: invoiceData.invoiceMasterId,
                                relationshipId: invoiceData.relationshipId,
                                objectKey: invoiceData.stampAwsKey,
                                folderName: invoiceData?.folderName,
                                bucketName: props?.customerInfo?.bucketName,
                              },
                              props
                            );
                          }}
                        >
                          {" "}
                          <FormattedMessage
                            id="modal.txDetails.common.deleteStamp"
                            defaultMessage=""
                          />{" "}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <div className="contact-details">
                  {invoiceData.footer && (
                    <Fragment>{ReactHtmlParser(invoiceData.footer)}</Fragment>
                  )}
                </div>
              </Skeleton>
            </div>
            : ""}
        </div>
      </div>
    </div>
  );
};

const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
  let total = 0;
  data &&
    data.supplierInvoiceDetailsList &&
    data.supplierInvoiceDetailsList.forEach(function (d) {
      if (type === "SubTotal") {
        total = total + d.itemPurchasedPrice * d.quantity;
      }
      if (type === "Discount") {
        // let discountObject = d.supplierInvoiceCOATxList.find(
        //   (tx) => tx.txType === "discount"
        // );
        let discountObj = d.discountAmount
        if (discountObj) {
          total = total + parseFloat(discountObj);
        }
      }
    });
  if (type === "Discount") {
    data &&
      data.supplierInvoiceCOATxList &&
      data.supplierInvoiceCOATxList.forEach(function (discountObject) {
        if (
          discountObject.txType === "discount" &&
          discountObject.accountingEntry === "debit"
        ) {
          total = total + parseFloat(discountObject.amount);
        }
      });
  }
  if (type === "expense") {
    data &&
      data.supplierInvoiceCOATxList &&
      data.supplierInvoiceCOATxList.forEach(function (discountObject) {
        if (
          discountObject.txType === "expense" &&
          discountObject.accountingEntry === "debit"
        ) {
          total = total + parseFloat(discountObject.amount);
        }
      });
  }
  return fixedDecimalNumber(total);
};


const getDiscountAmount = (row, toReturn) => {
  if (!row || !row.discountAmount) return;
  if (row?.discountAmount) {
    return toReturn === "amount" ? fixedDecimalNumber(row?.discountAmount) : "(" + row?.discountPercentage + "%)"
  }
};
const getTxTypeData = (row, type, toReturn) => {
  if (!row || !row.supplierInvoiceCOATxList) return;
  let data = row.supplierInvoiceCOATxList.find((tx) => tx.txType === type);
  if (data) {
    if (data[toReturn] && toReturn === "amountPercent") {
      if (type === "VAT") {
        return "VAT(" + data[toReturn] + "%)";
      }
      return "(" + data[toReturn] + "%)";
    } else if (data[toReturn] && toReturn === "amount") {
      if (type === "expense" && data.taxRate) {
        return fixedDecimalNumber(
          Number(data[toReturn]) + Number(data[toReturn]) * data.taxRate * 0.01
        );
      } else return fixedDecimalNumber(Number(data[toReturn]));
    } else {
      return "";
    }
  } else if (!data && toReturn === "amount") {
    return "";
  } else {
    return "-";
  }
};


const getTotalTaxAmount = (row, isPlaceOfSupplySameAsRelationship) => {
  let tax1 = getTxTypeData(row, "tax", "amount", true) || 0;
  //let tax2 = getTxTypeData(row, 'sgst', 'amount', true) || 0;
  //let tax3 = getTxTypeData(row, 'igst', 'amount', true) || 0;
  //let tax4 = getTxTypeData(row, 'vat', 'amount', true) || 0;
  let totalTax = Number(tax1);
  return isPlaceOfSupplySameAsRelationship ? totalTax * 2 : totalTax;
};
const getHsnFragment = (itemLevelTaxes, isPlaceOfSupplySameAsRelationship) => {
  const hsnData = itemLevelTaxes?.filter(item => item.hsnCode > 0)

  const fragmentObj = isPlaceOfSupplySameAsRelationship ?
    <Fragment>
      <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid gray', textAlign: 'center' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid gray' }}>HSN</th>
            <th style={{ border: '1px solid gray' }}>SGST</th>
            <th style={{ border: '1px solid gray' }}>CGST</th>
            <th style={{ border: '1px solid gray' }}>Amount</th>
          </tr>
        </thead>
        <tbody>

          {hsnData?.map((itemLevelTaxe, index) => {
            return (
              <tr>
                <td style={{ border: '1px solid gray' }}>{itemLevelTaxe.hsnCode}</td>
                <td style={{ border: '1px solid gray' }}>
                  {itemLevelTaxe.taxPercent}% on {fixedDecimalNumber(itemLevelTaxe.subtotal)} = {fixedDecimalNumber(itemLevelTaxe.taxAmount)}
                </td>
                <td style={{ border: '1px solid gray' }}>
                  {itemLevelTaxe.taxPercent}% on {fixedDecimalNumber(itemLevelTaxe.subtotal)} = {fixedDecimalNumber(itemLevelTaxe.taxAmount)}
                </td>
                <td style={{ border: '1px solid gray' }}>
                  {fixedDecimalNumber(itemLevelTaxe.taxAmount * 2)}
                </td>
              </tr>
            )
          })}

        </tbody>
      </table>
    </Fragment> :

    <Fragment>

      <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid gray', textAlign: 'center' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid gray' }}>HSN</th>
            <th style={{ border: '1px solid gray' }}>IGST</th>
            <th style={{ border: '1px solid gray' }}>Amount</th>
          </tr>
        </thead>
        <tbody>

          {hsnData?.map((itemLevelTaxe, index) => {
            return (
              <tr>
                <td style={{ border: '1px solid gray' }}>{itemLevelTaxe.hsnCode}</td>
                <td style={{ border: '1px solid gray' }}>
                  {itemLevelTaxe.taxPercent}% on {fixedDecimalNumber(itemLevelTaxe.subtotal)} = {fixedDecimalNumber(itemLevelTaxe.taxAmount)}
                </td>
                <td style={{ border: '1px solid gray' }}>
                  {fixedDecimalNumber(itemLevelTaxe.taxAmount)}
                </td>
              </tr>
            )
          })}

        </tbody>
      </table>
    </Fragment>

  return hsnData.length ? fragmentObj : <div></div>;
}
const getUomFragment = (uomList, isPlaceOfSupplySameAsRelationship) => {
  const uomData = uomList?.filter(item => item.uomName?.length)

  const fragmentObj =
    <Fragment>
      <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid gray', textAlign: 'center' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid gray' }}>UOM</th>
            <th style={{ border: '1px solid gray' }}>Quantity</th>

          </tr>
        </thead>
        <tbody>

          {uomData?.map((uomObj, index) => {
            return (
              <tr>
                <td style={{ border: '1px solid gray' }}>{uomObj.uomName}</td>
                <td style={{ border: '1px solid gray' }}>{uomObj.uomQty}</td>
              </tr>
            )
          })}

        </tbody>
      </table>
    </Fragment>

  return uomData.length ? fragmentObj : <div></div>;
}
const getTotalTaxPercent = (row, toReturn, isPlaceOfSupplySameAsRelationship) => {
  let tax1 =
    row.supplierInvoiceCOATxList.find((tx) => tx.txType === "tax") || {};
  //let tax2 = row.supplierInvoiceCOATxList.find(tx => (tx.txType === 'sgst')) || {};
  // let tax3 = row.supplierInvoiceCOATxList.find(tx => (tx.txType === 'igst')) || {};
  // let tax4 = row.supplierInvoiceCOATxList.find(tx => (tx.txType === 'vat')) || {};
  let totalPercent = Number(tax1.amountPercent || 0);
  if (toReturn === "amountPercent") {
    return isPlaceOfSupplySameAsRelationship ? totalPercent ? "(" + totalPercent * 2 + "%)" : "" : totalPercent ? "(" + totalPercent + "%)" : "";
  } else {
    return isPlaceOfSupplySameAsRelationship ? totalPercent * 2 : totalPercent;
  }
};

const getNetAmount = (row, isPlaceOfSupplySameAsRelationship, withoutTax) => {
  let tax = getTotalTaxAmount(row) || 0;
  let taxValue = isPlaceOfSupplySameAsRelationship ? tax * 2 : tax
  let discount = getDiscountAmount(row, 'amount', true) || 0;
  let netAmountItemwithoutTax = (Number(row.quantity * row.itemPurchasedPrice) - Number(discount));

  let netAmountItem = (Number(row.quantity * row.itemPurchasedPrice) + Number(taxValue) - Number(discount));
  if (withoutTax) {
    return fixedDecimalNumber(netAmountItemwithoutTax)
  }
  return fixedDecimalNumber(netAmountItem);

}

const calculateTaxRateList = (
  supplierInvoiceDetailsList,
  isPlaceOfSupplySameAsRelationship, invoiceData
) => {
  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let itemLevelTaxes = [];

  supplierInvoiceDetailsList.forEach(function (rowdata, index) {
    let totalAmountOfColumn = 0;
    if (rowdata.quantity && rowdata.itemPurchasedPrice) {
      let taxObj = {
        subtotal: 0,
        taxPercent: 0,
        taxAmount: 0,
      };
      totalAmountOfColumn = rowdata.quantity * rowdata.itemPurchasedPrice;
      let discount = getDiscountAmount(rowdata, "amount");
      if (discount) {
        totalAmountOfColumn = totalAmountOfColumn - discount;
        totalDiscount = totalDiscount + discount;
      }

      let tax1 =
        rowdata.supplierInvoiceCOATxList.find((tx) => tx.txType === "tax") ||
        {};
      taxObj.taxPercent = Number(tax1.amountPercent);
      taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount));
      totalTax = totalTax + taxObj.taxAmount;
      taxObj.taxName = tax1.taxName;
      //   let tax2 = rowdata.supplierInvoiceCOATxList.find(tx => (tx.txType === 'sgst'));
      //   let tax3 = rowdata.supplierInvoiceCOATxList.find(tx => (tx.txType === 'igst'));
      //   let tax4 = rowdata.supplierInvoiceCOATxList.find(tx => (tx.txType === 'vat'));
      //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
      //       taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
      //       taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
      //       totalTax = totalTax + taxObj.taxAmount;
      //   }
      //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
      //       taxObj.taxPercent = Number(tax3.amountPercent);
      //       taxObj.taxAmount = Number(tax3.amount);
      //       totalTax = totalTax + taxObj.taxAmount;
      //   }
      //   else if (tax4) {
      //     taxObj.taxPercent = Number(tax4.amountPercent);
      //     taxObj.taxAmount = Number(tax4.amount);
      //     totalTax = totalTax + taxObj.taxAmount;
      // }
      if (taxObj.taxPercent) {
        taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
        const availableTaxObj = find(itemLevelTaxes, {
          taxPercent: taxObj.taxPercent,
        });

        if (availableTaxObj) {
          itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
            if (ele.taxPercent === taxObj.taxPercent) {
              // if (ele.hsnCode === taxObj.hsnCode) {
              ele.subtotal = ele.subtotal + taxObj.subtotal;
              ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
            }
            return true;
          });
        }
        else {
          taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
          itemLevelTaxes.push(taxObj);
        }
      }
      subtotal = subtotal + totalAmountOfColumn;
    }
  });

  let dataAfterExpenseTax = calculteExpenseTax(itemLevelTaxes, invoiceData,isPlaceOfSupplySameAsRelationship)

  return dataAfterExpenseTax;
};

const calculteExpenseTax = (itemLevelTaxes, invoiceData,isPlaceOfSupplySameAsRelationship) => {
  let finalVal = [];

  let expenseTax = invoiceData?.supplierInvoiceCOATxList?.filter(
    (item) => item.txType === "expense" && item.accountingEntry === "credit"
  );

  let v = expenseTax?.forEach((rowdata) => {
    if (rowdata.taxId > 0) {
      let taxObj = {
        subtotal: rowdata.amount,
        taxPercent:isPlaceOfSupplySameAsRelationship?rowdata.taxId/2: rowdata.taxId,
        taxAmount: ((isPlaceOfSupplySameAsRelationship?rowdata.taxId/2: rowdata.taxId) * rowdata.amount) / 100,
        taxName : rowdata.taxName
      };

      let existingTax = itemLevelTaxes.find(
        (tax) => tax.taxPercent === (isPlaceOfSupplySameAsRelationship?rowdata.taxId/2: rowdata.taxId)
      );

      if (existingTax) {
        existingTax.subtotal += taxObj.subtotal;
        existingTax.taxAmount += taxObj.taxAmount;
      } else {
        itemLevelTaxes.push(taxObj);
      }
    }
  });

  return itemLevelTaxes;
};

const calculateTaxRateListbyHsn = (
  supplierInvoiceDetailsList,
  isPlaceOfSupplySameAsRelationship, invoiceData,props
) => {
  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let itemLevelTaxes = [];

  supplierInvoiceDetailsList.forEach(function (rowdata, index) {
    let totalAmountOfColumn = 0;
    if (rowdata.quantity && rowdata.itemPurchasedPrice) {
      let taxObj = {
        subtotal: 0,
        taxPercent: 0,
        taxAmount: 0,
        hsnCode: null
      };
      totalAmountOfColumn = rowdata.quantity * rowdata.itemPurchasedPrice;
      let discount = getDiscountAmount(rowdata, "amount");
      if (discount) {
        totalAmountOfColumn = totalAmountOfColumn - discount;
        totalDiscount = totalDiscount + discount;
      }

      let tax1 =
        rowdata.supplierInvoiceCOATxList.find((tx) => tx.txType === "tax") ||
        {};
      taxObj.taxPercent = Number(tax1.amountPercent);
      taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount));
      totalTax = totalTax + taxObj.taxAmount;
      taxObj.taxName = tax1.taxName;
      taxObj.hsnCode = Number(rowdata.hsnCode)

      if (taxObj.taxPercent) {
        taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
        const availableTaxObj = find(itemLevelTaxes, {
          taxPercent: taxObj.taxPercent,
        });
        const availableHsnObj = find(itemLevelTaxes, {
          hsnCode: taxObj.hsnCode,
        });
        if (availableTaxObj && availableHsnObj) {
          itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
            // if (ele.taxPercent === taxObj.taxPercent && ele.hsnCode === taxObj.hsnCode) {
            if (ele.hsnCode === taxObj.hsnCode) {
              ele.subtotal = ele.subtotal + taxObj.subtotal;
              ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
            }
            return true;
          });
        }
        else {
          taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
          itemLevelTaxes.push(taxObj);
        }
      }
      subtotal = subtotal + totalAmountOfColumn;
    }
  });
  let dataAfterExpenseTax = calculteExpenseTaxByHsn(itemLevelTaxes, invoiceData,props,isPlaceOfSupplySameAsRelationship)
  return dataAfterExpenseTax;
};
const calculteExpenseTaxByHsn = (itemLevelTaxes, invoiceData,props,isPlaceOfSupplySameAsRelationship) => {
  let finalVal = [];

  let expenseTax = invoiceData?.supplierInvoiceCOATxList?.filter(
    (item) => item.txType === "expense" && item.accountingEntry === "credit"
  );
  let v = expenseTax?.forEach((rowdata) => {
    if (rowdata.taxId > 0) {
      let taxObj = {
        subtotal: rowdata.amount,
        taxPercent:isPlaceOfSupplySameAsRelationship ? (rowdata.taxId/2) : rowdata.taxId,
        taxAmount: ((isPlaceOfSupplySameAsRelationship ? (rowdata.taxId/2) : rowdata.taxId) * rowdata.amount) / 100,
        taxName : rowdata.taxName
      };
      let obj = Number((find(props.allGstRates, { financeGstGoodsRates: rowdata.taxSingleRate }) || {})?.hsnCode || null);
      let existingTax = itemLevelTaxes.find(
        (tax) => tax.hsnCode === obj
      );
      taxObj.hsnCode = obj?obj:null;
      if (existingTax) {
        existingTax.subtotal += taxObj.subtotal;
        existingTax.taxAmount += taxObj.taxAmount;
      } else {
        itemLevelTaxes.push(taxObj);
      }
    }
  });

  return itemLevelTaxes;
};
const calculateUomData = (
  supplierInvoiceDetailsList,
  isPlaceOfSupplySameAsRelationship
) => {

  let itemLevelUom = [];
  supplierInvoiceDetailsList.forEach(function (rowdata, index) {
    if (rowdata.quantity && rowdata.uomName) {
      let uomObj = {
        uomName: null,
        uomQty: null,
      };

      uomObj.uomName = rowdata.uomName;
      uomObj.uomQty = rowdata.quantity
      if (uomObj.uomName) {
        const availableUomObj = find(itemLevelUom, {
          uomName: uomObj.uomName,
        });
        if (availableUomObj) {
          itemLevelUom = filter(itemLevelUom, (ele) => {
            if (ele.uomName === uomObj.uomName) {
              ele.uomQty = ele.uomQty + uomObj.uomQty;
            }
            return true;
          });
        }
        else {
          itemLevelUom.push(uomObj);
        }
      }
    }
  });

  return itemLevelUom;
};
const updateColumnEmptyCheck = (isColumnEmpty, invoiceData, props) => {
  if (!invoiceData) return isColumnEmpty;
  for (let i in invoiceData.supplierInvoiceDetailsList) {
    let row = invoiceData.supplierInvoiceDetailsList[i];
    if (row.itemName) {
      isColumnEmpty.product = false;
    }
    if (row.serviceName) {
      isColumnEmpty.service = false;
    }
    if (row.itemVariantName) {
      isColumnEmpty.variant = false;
    }
    if (row.description) {
      isColumnEmpty.description = false;
    }
    if (row.quantity) {
      isColumnEmpty.qty = false;
    }
    if (row.uomName) {
      isColumnEmpty.uomName = false;
    }
    if (row.itemPurchasedPrice) {
      isColumnEmpty.rate = false;
    }
    if (row.specialDiscount) {
      isColumnEmpty.specialDiscount = false;
    }
    if (row.hsnCode && props.companyInfo.countryName === "India") {
      isColumnEmpty.hsn = false;
    }
    if (!isTxTypeEmpty(row, "discount")) {
      isColumnEmpty.discount = false;
    }
    if (!isTxTypeEmpty(row, "tax")) {
      isColumnEmpty.tax = false;
    }
    //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
    // if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
    // if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
    if (row.documentDetails && row.documentDetails.length) {
      isColumnEmpty.documentDetails = false;
    }
  }
  return isColumnEmpty;
};

const isTxTypeEmpty = (row, type) => {
  if (!row) return true;

  let data = row.supplierInvoiceCOATxList.find((tx) => tx.txType === type);
  if (type === "tax") {

    if (data && data.amount) {
      return false
    } else {
      return true
    }
  }
  if (row && row.discountPercentage) {
    return false;
  } else {
    return true;
  }
};

const getSpecialDiscount = (row, toReturn) => {
  if (!row) return;
  let specialDiscount = row.basePrice - row.itemPurchasedPrice;
  let percent,
    amount = 0;
  if (specialDiscount && row.basePrice) {
    if (toReturn === "amountPercent") {
      percent = fixedDecimalNumber((specialDiscount * 100) / row.basePrice);
      amount = fixedDecimalNumber(specialDiscount);
      return amount + " (" + percent + ")%";
    } else if (toReturn === "amount") {
      return fixedDecimalNumber(specialDiscount);
    }
  } else if (!row && toReturn === "amount") {
    return 0;
  } else {
    return "-";
  }
};
const getAddressInfoFragment = (obj, additionInfo) => {
  const fragmentObj = (
    <Fragment>
      <div className="billing-address first-caps">
        {obj.streetAddress1 || obj.streetAddress2 ? (

          <div>
            {obj.streetAddress1} {obj.streetAddress2}{", "}
          </div>

        ) : ""}
        {obj.cityName || obj.stateName || obj.zipCode ? (

          <div>
            {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
          </div>

        ) : ""}
        {obj.countryName ? (
          <div>{obj.countryName}</div>
        ) : ""}
      </div>
      {/* {
            additionInfo.relationshipPhoneNumber
                ? <div className='billing-address'>
                   {additionInfo.relationshipPhoneNumber}
                </div> :
                ''
        } */}
      {additionInfo.workPhone ? (
        <div className="billing-address">
          <FormattedMessage id="phone" defaultMessage="" /> -{" "}
          {additionInfo.workPhone}
        </div>
      ) : (
        ""
      )}
      {additionInfo.email ? (
        <div className="billing-address">
          <FormattedMessage id="email" defaultMessage="" /> -{" "}
          {additionInfo.email}
        </div>
      ) : (
        ""
      )}
      {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}
    </Fragment>
  );
  return fragmentObj;
};

const getTaxIdentificationsFragment = (taxIdentifications) => {
  if (!taxIdentifications) return <Fragment />;
  let taxIdentificationsList = [];
  if (taxIdentifications) {
    taxIdentificationsList = JSON.parse(taxIdentifications);
  }
  const fragmentObj = (
    <Fragment>
      {taxIdentificationsList.map((obj, i) => {
        return (
          <div className="billing-address">
            {obj.taxIdentificationType || ""} - {obj.taxIdentificationNumber}
          </div>
        );
      })}
    </Fragment>
  );

  return fragmentObj;
};

const getContactNameFragment = (additionInfo) => {
  if (!additionInfo) return <Fragment />;
  let contactName = "";
  if (additionInfo.salutationName)
    contactName += additionInfo.salutationName + " ";
  if (additionInfo.firstName) contactName += additionInfo.firstName;
  if (additionInfo.middleName) contactName += " " + additionInfo.middleName;
  if (additionInfo.lastName) contactName += " " + additionInfo.lastName;
  const fragmentObj = (
    <Fragment>
      {contactName && contactName.length ? (
        <div className="title">{contactName}</div>
      ) : (
        ""
      )}
      <div>
        {additionInfo?.emailAddress || ''}
      </div>
      <div>
        {additionInfo?.workPhone || additionInfo?.cellPhone || ''}
      </div>
    </Fragment>
  );
  return fragmentObj;
};

const getVariantFragment = (item) => {
  let itemName = null;
  if (item.itemName) {
    itemName = item.itemName;
  }
  if (item.itemVariantName) {
    itemName = itemName + " (" + item.itemVariantName + ")";
  }
  return itemName ? itemName : "";
};
export default PurchaseInvoiceDetails;