import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';

import MultiCurrencyPurchaseInvoice from '../../../../components/supplier/invoice/MultiCurrencyPurchaseInvoice';
import LedgerDrawer from '../../../../containers/drawer/ledgers/PurchaseInvoiceLedger';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import {
  fetchPriceList, fetchPaymentTerms, addPaymentTerm, deletePaymentTerm,
  fetchProjects, fetchStates, fetchTaxes,
  fetchUOM, addUOM, deleteUOM, fetchExpenseTypes, addExpenseTypes, deleteExpenseTypes, fetchProducts,
  showModal, hideModal, pushModalToStack, popModalFromStack,
  deleteProject, deleteAddress, deleteTax, deletePriceList,
  fetchDocuments, addDocumentName, deleteDocumentName, deleteContact, fetchCountries,
  fetchUserPreferences, saveUserPrefrences, fetchRelationshipTaxIdentifications, deleteRelationshipTaxIdentifications, deleteSupplierTaxIdentifications,
  fetchSupplierSummary, fetchSupplierDetailsBySupplierId, resetSupplierDetail,getRelationshipAddress
} from '../../../../actions/commonActions'
import { createPurchaseInvoice, fetchPurchasedOrderForInvoice, resetPoInvoiceData, getNextTxNumber, createPurchaseInvoiceDraft, fetchIDForInvoice, resetIDInvoiceData } from './action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { LEDGER_ACTION_LIST, CONSTANTS, LEDGER_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY } from '../../../../static/constants';
import { GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import PurchaseInvoiceDetail from '../../../modal/modalBody/supplier/PurchaseInvoiceDetail';
import { deleteSuppliers } from '../../Listing/action';
import { fetchAddresses } from '../../Listing/ShowAddress/action';
import { fetchAllContacts } from '../../Listing/ShowConact/action';

class PurchaseInvoice extends Component {
  constructor(props) {
    super(props);
    const defaultPurchaseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE }) || {};
    const defaultTaxLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_TAX }) || {};
    const defaultDiscountLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_DISCOUNT }) || {};
    const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.SUPPLIER_EXPENSE }) || {};
    const defaultCreditorLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CREDITORS }) || {};
    const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const allRelationshipShippingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS }) || [];
    const relationshipBillingAddress = allRelationshipBillingAddress.find(x => x.isDefault) || {};
    const relationshipShippingAddress = allRelationshipShippingAddress.find(x => x.isDefault) || {};
    const placeOfSupply = (relationshipBillingAddress || {}).stateName;
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      isbankReco: linkProps.isbankReco,
      bankRecoBalance: linkProps.bankRecoBalance,
      bankTxData: linkProps.bankTxData,
      stateSupplierId: linkProps.supplierId,
      pageNumber: linkProps.pageNumber || 1,
      supplier: undefined,
      priceListName: undefined,
      pageSize: 2000,
      allRelationshipBillingAddress,
      allRelationshipShippingAddress,
      relationshipBillingAddress,
      relationshipShippingAddress,
      placeOfSupply,
      invoiceDate: new Date(),
      invoiceDueDate: new Date(),
      ledgerDrawerVisible: false,
      purchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      purchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      discountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      taxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      purchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "purchaseInvoice", templateName: "multicurrency" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === 'India',
        basePrice: true,
        specialDiscount: true,
        discount: true,
        //tax: true
      },
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      poMasterId: linkProps.poMasterId,
      supplierId: linkProps.supplierId,
      userId: (this.props.userInfo || {}).userId,
      inboundDeliveryMasterId: linkProps.inboundDeliveryMasterId,
      pageNumber: 1,
      pageSize: 2000
    };
    this.props.resetPoInvoiceData();
    this.props.resetIDInvoiceData();
    payload.poMasterId && fetchDataIfNeeded('fetchPurchasedOrderForInvoice', 'poInvoiceData', this.props, payload);
    payload.inboundDeliveryMasterId && fetchDataIfNeeded('fetchIDForInvoice', 'idInvoiceData', this.props, payload);
    this.props.resetSupplierDetail();
    payload.supplierId && this.props.fetchSupplierDetailsBySupplierId(payload);
    fetchPaginationDataIfNeeded('fetchSupplierSummary', 'supplierSummary', this.props, payload, true);
    fetchDataIfNeeded('fetchPriceList', 'priceList', this.props, payload);
    fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
    fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
    fetchDataIfNeeded('fetchStates', 'states', this.props, 1);
    fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
    fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
    fetchDataIfNeeded('fetchExpenseTypes', 'expenseTypes', this.props, payload);
    fetchDataIfNeeded('fetchDocuments', 'documentList', this.props, payload);
    if (!linkProps.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
    fetchDataIfNeeded('fetchCountries', 'countries', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    this.props.fetchRemarksTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Purchase Invoice' });
    fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
  }

  componentWillReceiveProps(props) {

    if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
      const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
      let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
        return obj.relationshipTaxIdentificationId;
      })
      this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
    }

    if (props.documentList && props.documentList.length && !this.props.docName) {
      props.documentList.forEach((document) => {

        if (document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE] && document.isDefault) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName
          })
        }
      })

    }
    if (!this.state.remarksCustomer && props.remarksData && props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ remarksCustomer: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500)
    }
    const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const allRelationshipShippingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS }) || [];
    this.setState({
      allRelationshipBillingAddress,
      allRelationshipShippingAddress,
    });


    if (props.taxes && props.taxes.length && props.idInvoiceData && props.idInvoiceData.inboundDeliveryMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
      }
      props.idInvoiceData.inboundDeliveryDetailsList.map((ele) => {
        const tax = (find(ele.supplierIDCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX;
        }) || {});

        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};


        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName ? ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "") : '',
          productObj: ele.itemName ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku } : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.qtyReceived,
          discount: (find(ele.supplierIDCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax: (tax.amountPercent || 0),
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          taxName: appliedTax.taxName,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemPurchasePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          inboundDeliveryNumber: props.idInvoiceData.inboundDeliveryNumber,
          inboundDeliveryMasterId: props.idInvoiceData.inboundDeliveryMasterId,
          parentDetailsId: ele.parentDetailsId
        })
      });
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      const rootDiscount = (find((props.idInvoiceData.supplierIDCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {})
      const expenseAmount = (find((props.idInvoiceData.supplierIDCOATxList || []), { txType: LEDGER_TYPE.TYPE_EXPENSE }) || {}).amount || 0;
      const selectedContact = (props.idInvoiceData.deliveryAdditionalInfoList || [])[0] || {};
      const supplier = this.state.supplier || {}
      let selectedSupplierTaxIdentifications = []
      if (selectedContact.taxIdentifications) {
        let taxIdentificationsList = JSON.parse(selectedContact.taxIdentifications);
        selectedSupplierTaxIdentifications = taxIdentificationsList.map((obj, i) => {
          return obj.supplierTaxIdentificationId
        });
      }

      this.setState({
        version: props.idInvoiceData.version,
        isIDConversion: true,
        exchangeRate: props.idInvoiceData.exchangeRate,
        currencyCode: props.idInvoiceData.foreignCurrency,
        selectedSupplierTaxIdentifications,
        supplier: { ...supplier, id: props.idInvoiceData.supplierId, supplierId: props.idInvoiceData.supplierId, name: props.idInvoiceData.supplierName, gstNumber: props.idInvoiceData.gstNumber, panNumber: props.idInvoiceData.panNumber },
        relationshipBillingAddress: find(props.idInvoiceData.boLocationInboundDeliveryList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }),
        supplierBillingAddress: find(props.idInvoiceData.boLocationInboundDeliveryList, { locationType: CONSTANTS.BILLING_ADDRESS }),
        selectedContact: (props.idInvoiceData.deliveryAdditionalInfoList || [])[0] || {},
        customerPaymentTerm: props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: props.idInvoiceData.paymentTermId }) || {} : {},
        poMasterId: props.idInvoiceData.poMasterId,
        purchaseOrderId: props.idInvoiceData.poId,
        supplierPo: props.idInvoiceData.poNumber,
        reference: props.idInvoiceData.reference,
        projectMasterId: props.idInvoiceData.projectMasterId,
        projectName: props.idInvoiceData.projectName,
        projectNumber: props.idInvoiceData.projectNumber,
        customerPo: props.idInvoiceData.customerPONumber,
        placeOfSupply: props.idInvoiceData.placeOfSupply,
        supplierName: props.idInvoiceData.supplierName,
        supplierRemarks: props.idInvoiceData.remarksSupplier,
        internalRemarks: props.idInvoiceData.remarksInternal,
        footer: props.idInvoiceData.footer,
        invoiceDetailsList: detailList,
        supplierPaymentTerm: { paymentTermId: props.idInvoiceData.paymentTermId, termName: props.idInvoiceData.paymentTermName },
        isRootDiscount: rootDiscount.amountPercent ? true : false,
        rootDiscountPercent: rootDiscount.amountPercent || 0,
        expenseId: props.idInvoiceData.expenseId,
        rootDiscountAmount: Number(rootDiscount.amount || 0),
        expenseAmount: expenseAmount,
        isRootExpense: expenseAmount ? true : false,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        rfqNumber: props.idInvoiceData.rfqNumber,
        rfqMasterId: props.idInvoiceData.rfqMasterId,
        inboundDeliveryNumber: props.idInvoiceData.inboundDeliveryNumber,
        inboundDeliveryMasterId: props.idInvoiceData.inboundDeliveryMasterId,
        supplierQuoteNumber: props.idInvoiceData.supplierQuoteNumber
      });

      props.resetIDInvoiceData();
    }


    if (props.taxes && props.taxes.length && props.poInvoiceData && props.poInvoiceData.poMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
      }
      props.poInvoiceData.supplierPurchaseOrderDetailList.map((ele) => {
        const tax = (find(ele.supplierPOCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX_IGST || ele.txType === LEDGER_TYPE.TYPE_TAX_CGST || ele.txType === LEDGER_TYPE.TYPE_TAX_SGST || ele.txType === LEDGER_TYPE.TYPE_TAX_VAT
        }) || {});

        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName ? ele.itemName + ((ele.itemVariantName && (" (" + ele.itemVariantName + ") ")) || "") : '',
          productObj: { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku },
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantityToInvoice,
          discount: (find(ele.supplierPOCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax: (tax.amountPercent || 0) * (tax.txType === LEDGER_TYPE.TYPE_TAX_IGST || tax.txType === LEDGER_TYPE.TYPE_TAX_VAT ? 1 : 2),
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemPurchasePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount

        })
      });

      const rootDiscount = (find((props.poInvoiceData.supplierPOCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {})
      const expenseAmount = (find((props.poInvoiceData.supplierPOCOATxList || []), { txType: LEDGER_TYPE.TYPE_EXPENSE }) || {}).amount || 0;

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      this.setState({
        isPoConversionToInvoice: true,
        currencyCode: props.poInvoiceData.foreignCurrency,
        version: props.poInvoiceData.version,
        currencyIcon: props.poInvoiceData.foreignCurrencyIcon,
        exchangeRate: props.poInvoiceData.exchangeRate,
        supplier: { id: props.poInvoiceData.supplierId, supplierId: props.poInvoiceData.supplierId, name: props.poInvoiceData.supplierName, gstNumber: props.poInvoiceData.gstNumber, panNumber: props.poInvoiceData.panNumber },
        relationshipBillingAddress: find(props.poInvoiceData.boLocationPOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }),
        supplierBillingAddress: find(props.poInvoiceData.boLocationPOList, { locationType: CONSTANTS.BILLING_ADDRESS }),
        selectedContact: (props.poInvoiceData.additionalInfoList || [])[0] || {},
        customerPaymentTerm: props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: props.poInvoiceData.paymentTermId }) || {} : {},
        poMasterId: props.poInvoiceData.poMasterId,
        purchaseOrderId: props.poInvoiceData.poId,
        supplierPo: props.poInvoiceData.poNumber,
        reference: props.poInvoiceData.reference,
        projectMasterId: props.poInvoiceData.projectMasterId,
        projectName: props.poInvoiceData.projectName,
        projectNumber: props.poInvoiceData.projectNumber,
        customerPo: props.poInvoiceData.customerPONumber,
        placeOfSupply: props.poInvoiceData.placeOfSupply,
        supplierName: props.poInvoiceData.supplierName,
        supplierRemarks: props.poInvoiceData.remarksSupplier,
        internalRemarks: props.poInvoiceData.remarksInternal,
        footer: props.poInvoiceData.footer,
        invoiceDetailsList: detailList,
        supplierPaymentTerm: { paymentTermId: props.poInvoiceData.paymentTermId, termName: props.poInvoiceData.paymentTermName },
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount.amountPercent || 0,
        expenseId: props.poInvoiceData.expenseId,
        rootDiscountAmount: Number(rootDiscount.amount || 0),
        expenseAmount: expenseAmount,
        isRootExpense: expenseAmount ? true : false,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        rfqNumber: props.poInvoiceData.rfqNumber,
        rfqMasterId: props.poInvoiceData.rfqMasterId,
        supplierQuoteNumber: props.poInvoiceData.supplierQuoteNumber
      });

      props.resetPoInvoiceData();
    }

    if (this.state.stateSupplierId && props.supplierDetail && props.supplierDetail.supplierId && props.paymentTerms && props.paymentTerms.length) {
      const supplierObj = props.supplierDetail || {};
      const supplierPaymentTerm = find(props.paymentTerms, { paymentTermId: supplierObj.paymentTermId });
      const allSupplierBillingAddress = filter(supplierObj.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS });
      const defaultBillingAddress = find(allSupplierBillingAddress, { isDefault: 1 });
      const selectedContact = (supplierObj.boContactList || []).find(x => x.isPrimaryContact) || {};
      const selectedList = (supplierObj && filter(supplierObj.supplierTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
      let selectedSupplierTaxIdentifications = selectedList.map(obj => {
        return obj.supplierTaxIdentificationId;
      })
      this.setState({
        stateSupplierId: null,
        supplierAddresses: (supplierObj.boLocationList || []).filter(x => x.locationType === CONSTANTS.TYPE_SHIPPING_ADDRESS) || [],
        supplier: { id: supplierObj.supplierId, supplierId: supplierObj.supplierId, name: supplierObj.supplierStoreName, gstNumber: supplierObj.gstNumber, panNumber: supplierObj.panNumber },
        supplierPaymentTerm,
        boContactList: supplierObj.boContactList,
        supplierBillingAddress: defaultBillingAddress,
        selectedSupplierTaxIdentifications,
        selectedContact,
        allBillingAddress: allSupplierBillingAddress
        
      });
    }
    if (props.addresses) {
      if (props.addresses.BillingAddress && props.addresses.BillingAddress.length) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress
        });
      }
    }
    if (props.supplierContactList && props.supplierContactList.length) {
        this.setState({
          boContactList: props.supplierContactList
        });
    }

    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "purchaseInvoice", templateName: "multicurrency" }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns : (this.state.txMandatoryColumns || []).concat('product', 'description')
        const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        })
      }
    }
  }

  openInvoiceDetails = (data) => {
    const invoicePayload = {
      supplierId: data.supplierId,
      invoiceMasterId: data.invoiceMasterId
    }
    this.props.pushModalToStack({
      modalBody: <PurchaseInvoiceDetail {...this.props} invoicePayload={invoicePayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 2000,
      updateAllOptions,
      updateFetching
    };
    this.props.fetchProducts(payload)
  }

  render() {
    return (
      <Fragment>
        <LedgerDrawer {...this.state} updateState={(data) => { this.setState(data) }} />
        <MultiCurrencyPurchaseInvoice
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching)
          }}
          {...this.props}
          {...this.state}
          openInvoiceDetails={this.openInvoiceDetails}
          updateState={(data) => { this.setState(data) }}
        />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    //suppliers: state.supplier.suppliers,
    priceList: state.common.priceList,
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    supplierAddresses: state.supplier.supplierAddresses,
    paymentTerms: state.common.paymentTerms,
    states: state.common.states,
    projectList: state.common.projectList,
    uomList: state.common.uomList,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    products: state.salesInvoice.products,
    poInvoiceData: state.purchaseInvoice.poInvoiceData,
    documentList: state.common.documentList,
    nextTxNumber: state.purchaseInvoice.nextTxNumber,
    countries: state.common.countries,
    allPreferences: state.common.allPreferences,
    remarksData: state.settings.remarksData,
    idInvoiceData: state.purchaseInvoice.idInvoiceData,
    permissions: state.auth.permissions,
    allRelationshipTaxIdentificatins: state.common.allRelationshipTaxIdentificatins,
    supplierSummary: state.supplier.supplierSummary,
    supplierDetail: state.common.supplierDetail,
    addresses: state.supplier.addresses,
    supplierContactList: state.supplier.contacts
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPriceList,
  fetchProjects,
  fetchPaymentTerms,
  addPaymentTerm,
  deletePaymentTerm,
  fetchStates,
  fetchProducts,
  fetchTaxes,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  showModal,
  hideModal,
  createPurchaseInvoice,
  pushModalToStack,
  popModalFromStack,
  deleteSuppliers,
  deleteProject,
  deleteAddress,
  deleteTax,
  deletePriceList,
  fetchPurchasedOrderForInvoice,
  resetPoInvoiceData,
  fetchDocuments,
  addDocumentName,
  fetchCountries,
  deleteDocumentName,
  getNextTxNumber,
  deleteContact,
  resetRemarksData,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchRemarksTemplate,
  fetchRelationshipTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  deleteSupplierTaxIdentifications,
  createPurchaseInvoiceDraft,
  fetchIDForInvoice,
  resetIDInvoiceData,
  fetchSupplierSummary, 
  fetchSupplierDetailsBySupplierId, 
  resetSupplierDetail,
  fetchAddresses,
  fetchAllContacts,
  getRelationshipAddress
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoice);
