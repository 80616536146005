import React, { Component } from 'react';
// import config from '../../../../config/environmentConfig';
import { FormattedMessage } from 'react-intl';
import { Input, Button } from 'antd';
import { showToasterMessage } from '../../../../utils';

export default class ShareTvURL extends Component {

  constructor(props) {
    super(props);
    this.state = {
      previewUrl: (props.subdomainName || window.location.origin) + '/app/campaign-streaming?t=' + props.campaignRowData.relationshipId + '_' + props.campaignRowData.fundraisingCampaignId + '_' + Math.floor((Math.random() * 10000) + 1)
    }
  }

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
    showToasterMessage({
      messageType: 'success', description: this.props.intl.formatMessage(
        {
          id: 'copyToClipboard.text',
          defaultMessage: ''
        }
      )
    });
  }

  render() {
    return (
      <div>
        <div> <FormattedMessage id='shareTvUrl.link.description' /></div>
        <br />
        <div style={{display: 'inline'}}>
          <Input style={{ width: '80%' }}
            ref={(textarea) => this.textArea = textarea}
            value={this.state.previewUrl}
          />
          <Button title='Copy Link' type="default" style={{ paddingLeft: '5px', paddingRight: '5px' }} onClick={() => this.copyCodeToClipboard()}>
            <i class="fa fa-clone"></i>
          </Button>
        </div>
        
        <br />
        <br />
        <div className="align-footer" style={{ borderTop: '1px solid #e5e5e5', textAlign: 'center' }}>
          <Button style={{ marginTop: '10px' }} type="primary" onClick={() => {
            //window.location = this.state.previewUrl;
            // window.open(this.state.previewUrl, '_blank');
            Object.assign(document.createElement('a'), {
              target: '_blank',
              href: this.state.previewUrl,
            }).click();
          }}>
            <FormattedMessage id='preview.text' />
          </Button>
        </div>
      </div>
    )
  }
}
