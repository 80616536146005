import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Tooltip, Checkbox, Divider, Skeleton } from 'antd';
import { ICONS, MODAL_TYPE } from '../../../../static/constants';
//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
// import RegistrationFormSetting from '../../../../containers/modal/modalBody/settings/RegistrationFormSetting';
import { getUserDefinedName } from '../../../../utils';
import { MultipleSelectDropdown } from '../../../general/MultipleSelectDropdown';


function SchoolDrawer(props) {
  const [value, setValue] = useState(false);
  const {addSectionList = [{}]} =props
  const closeDrawer = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addClassDrawerVisible: false,
        classData: {},
      },
    });
  };

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getModalTitle(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };
    switch (modalType) {
      case MODAL_TYPE.CLASS_SCHEDULE: {
        addFunc = props.addClassSchedule;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({
          ...payload,
          createdByEmpId: props.userInfo.relationshipEmployeeId,
        });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const getModalTitle = (modalType) => {
    let title;
    switch (modalType) {
      case MODAL_TYPE.CLASS_SCHEDULE: {
        title = <FormattedMessage id="classSchedule.text" defaultMessage="" />;
        break;
      }

      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(modalType),
        formData,
        modalData: { modalType, data: payload },
        width: "35%",
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;
    let callback = function (data) {};
    switch (modalType) {
      case MODAL_TYPE.CLASS_SCHEDULE: {
        deleteFunc = props.deleteClassSchedule;
        break;
      }

      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          callback,
        });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const getSubjectListForBackend = (selectedSubjects)=>{
    let subjects = []
    if(selectedSubjects && selectedSubjects.length){
      props.selectedSubjects.forEach((num)=>{
        subjects.push({relationshipId: props.companyInfo.relationshipId, subjectId: num})
      })
    }
    return subjects;
  }

  const addToSectionList = (key, val, index) =>{
    let tempArr = addSectionList;
    let tempObj = tempArr[index];
    tempObj[key] = val;
    tempArr[index] = tempObj;
    props.updateState({
      addSectionList: tempArr
    })
  }

  // const validSectionList = () =>{
  //   let res = true;
   
  //   for(let item of addSectionList){
  //     if(!item.sectionName || !item.sectionCode){
  //       res = false;
  //     }
  //   }
  //   return res
  // }

  const validSectionList = (index) => {
    const item = addSectionList[index];
    return item && item.sectionName && item.sectionCode;  
  }
  

  const handleSubmit = (props) => {
    const sectionValidations = addSectionList.every((_, i) => validSectionList(i));

    if ( props.className && props.classCode && props.departmentId && props.courseId && props.courseTermId && props.selectedSubjects?.length && (!props.enableSections || sectionValidations 
    ) ) {
      const payload = {
        relationshipId: (props.companyInfo || {}).relationshipId,
        createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
        classId: props.classId || 0,
        className: props.className,
        classCode: props.classCode,
        departmentId: props.departmentId,
        courseId: props.courseId,
        courseTermId: props.courseTermId,
        subjectList: getSubjectListForBackend(props.selectedSubjects),
        classCapacity: props.enableSections ? addSectionList.reduce((sum, obj) => sum + Number(obj?.sectionCapacity), 0) : props.classCapacity,
        classSectionList: props.enableSections ? (addSectionList || []).map(obj => ({ ...obj, classId: props.classId, className: props.className, createdByEmpId: (props.userInfo || {}).userId, relationshipId: (props.companyInfo || {}).relationshipId })) : [],
        relationshipEmployeeId: props.relationshipEmployeeId,
        version: props.version,
        
      };

      props.pushModalToStack({
        modalBody: <FormattedMessage id={ props.classData ? 'schoolManagement.school.confirmation.edit' : 'schoolManagement.school.confirmation.create' }defaultMessage="" />,
        handleSubmit: () => {
          props.saveClass(payload, props);
        },
      });
    } else {
      props.updateState({
        submittedOnce: true,
      });
    }
  };

  const newCourse = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addCourseDrawerVisible: true,
      },
    });
  };

  const newDepartment = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addDepartmentDrawerVisible: true,
        userInfo: props.userInfo,
      },
    });
  };


  return (
    <Drawer
      title={getUserDefinedName(
              props.classData
                ? "schoolManagement.class.edit"
                : "schoolManagement.class.new"
            , props)}
      width={720}
      keyboard={false}
      zIndex={1}
      destroyOnClose={true}
      onClose={closeDrawer}
      maskClosable={false}
      className="custom-drawer"
      visible={props.schoolDrawers?.addClassDrawerVisible}
      afterVisibleChange={(visible) => {
        if (visible) {
          //props.initCalls();
        }
      }}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => handleSubmit(props)}
            key={1}
            />
        </>
      }
    >
        <Skeleton loading={props.listLoading} paragraph={{ rows: 20 }} active>

      <Row gutter={[16, 16]}>

        <Col span={12}>
          <div className="i-label">
            {getUserDefinedName('schoolManagement.class.name', props)}
            <span className="required">*</span>
          </div>
          <div>
            <TextBox
              type="text"
              className="textbox-pd"
              value={props.className || null}
              maxLength={50}
              countStyle={{top:"40px"}}
              onChange={(e) => {
                props.updateState({
                  className: e.target.value,
                });
              }}
              placeholder={getUserDefinedName('schoolManagement.class.name', props)}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.className;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </div>
        </Col>

        <Col span={12}>
          <div className="i-label">
            {getUserDefinedName('schoolManagement.class.code', props)}
            <span className="required">*</span>
          </div>
          <div>
            <TextBox
              type="text"
              className="textbox-pd"
              maxLength={20}
              countStyle={{top:"40px"}}
              value={props.classCode || null}
              onChange={(e) => {
                props.updateState({
                  classCode: e.target.value,
                });
              }}
              placeholder={getUserDefinedName('schoolManagement.class.code', props)}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.classCode;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </div>
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('department.text', props)}
            <span className="required">*</span>
          </div>
          <Dropdown
            items={props.departmentList[1] || []}
            valueKeyName="deptName"
            optionKeyName="deptId"
            value={props.departmentId || null}
            placeholder={getUserDefinedName('department.text', props)}
            onSelect={(optionValue) => {
              props.updateState({
                departmentId: optionValue,
                courseId: 0,
                courseCode:'',
                selectedSubjects: [],
                courseTermId: 0
              });
            }}
            canAddNew={true}
            onAddNewClick={() => {
              newDepartment();
            }}
          />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.departmentId;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('schoolManagement.class.course', props)}
            <span className="required">*</span>
          </div>

          <Dropdown
            disabled={!props.departmentId}  
            items={props.courseList[1].filter(obj => obj.deptId === props.departmentId) || []}
            valueKeyName="courseName"
            optionKeyName="courseId"
            value={props.courseId || null}
            placeholder={getUserDefinedName('schoolManagement.class.course', props)}
            onSelect={(optionValue, option) => {
              props.updateState({
                courseCode: option.name,
                courseId: optionValue,
                courseTermId: 0,
                selectedSubjects: [],
              });
            }}
            canAddNew={true}
            onAddNewClick={() => {
              newCourse();
            }}
          />

          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || !!props.courseId;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('schoolManagement.class.term', props)}
            <span className="required">*</span>
          </div>
          <Dropdown
            disabled={!props.courseId}  
            items={props.courseTermList[1]}
            valueKeyName="termName"
            optionKeyName="courseTermId"
            value={props.courseTermId || ''}
            placeholder={getUserDefinedName('schoolManagement.class.term', props)}
            onSelect={(optionValue, option) => {
              props.updateState({
                courseTermId: optionValue
              });
            }}
            // canAddNew={true}
            // onAddNewClick={() => {
            //   newCourse();
            // }}
          />

          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || !!props.courseTermId;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('schoolManagement.class.subject', props)}
            <span className="required">*</span>
          </div>
          <MultipleSelectDropdown
            items={ ((props.courseList[1].find(obj=>obj.courseId === props.courseId ) || {}).subjectList || []).filter(obj=>obj.courseTermId === props.courseTermId) }
            placeholder='Select Subjects'
            mode='multiple'
            valueKeyName='subjectName'
            selectedValue={ (props.selectedSubjects || [])}
            optionKeyName='subjectId'
            disabled = {!((props.courseList[1].find(obj=>obj.courseId === props.courseId ) || {}).subjectList || []).length}
            onChange={(val) => {
              props.updateState({
                selectedSubjects: val
              });
            }}
          />

          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || !!props.selectedSubjects?.length;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={12}>
          <div className='i-label'>
            {getUserDefinedName('Create class sections', props)}
            <Tooltip placement="right" title={<FormattedMessage id='sms.class.classCapacity.help' defaultMessage='' />}>
              <i className={`${ICONS.HELP} cursor-pointer ml5`} />
            </Tooltip>
          </div>
          <Checkbox 
            checked={(props.enableSections === 1)} 
            disabled={!props.classId && !props.className}
            onChange={(e) => {
              props.updateState({
                enableSections: e.target.checked ? 1 : 0,
                
              })
              if(props.enableSections === 0){
                props.updateState({
                  addSectionList:[{}]
                  
                })
              }
            }} 
          />
        </Col>

        {!props.enableSections ? 
          <Col span={12}>
            <div className='i-label'>
              <FormattedMessage
                id="schoolManagement.class.capacity"
                defaultMessage=""
              />
                <Tooltip placement="right" title={<FormattedMessage id='sms.class.classCapacity.help' defaultMessage='' />}>
                  <i className={`${ICONS.HELP} cursor-pointer ml5`} />
                </Tooltip>
            </div>
            <div>
              <TextBox
                type="text"
               
                maxLength={3}
                countStyle={{top:"40px"}}
                value={props.classCapacity || ''}
                onChange={(e) => {
                  const inputString = e.target.value || ''
                 
                  const pattern = /\d+/g;
                  const matches = inputString.match(pattern);
                  props.updateState({
                    classCapacity: Number((matches || []).length ? matches[0] : 0),
                  });
                }}
                placeholder={props.intl.formatMessage({
                  id: "schoolManagement.class.capacity",
                })}
              />
               <ErrorMsg
            validator={() => {
              return !props.submittedOnce ||!!(props.classCapacity);
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
          
            </div>
          </Col> 
          :
          <Col span={12}></Col>
        }

        {
          props.enableSections ? <>
            <Divider />
            <Col span={24}>
              <Row key={'sectionslist'} gutter={[16, 16]} style={{borderBottom: '1px solid #e7e7e7'}}>
                <Col span={2} className='b-text'>S.No</Col>
                <Col span={8} className='b-text'>{getUserDefinedName('Section Name', props)}</Col>
                <Col span={6} className='b-text'>{getUserDefinedName('Section Code', props)}</Col>
                <Col span={6} className='b-text'>{getUserDefinedName('Student Limit', props)}</Col>
                <Col span={2} className='b-text'>Action</Col>
              </Row>
            </Col>

            {(addSectionList || []).map((item, i)=>{
              return <Row key={'sectons'+i} gutter={[16, 16]}>
                <Col span={2}  className='create-class-section'>{i+1}</Col>

                <Col span={8}>
                  <TextBox
                    type="text"
                    className="textbox-pd"
                    value={item.sectionName || null}
                    maxLength={20}
                    countStyle={{top:"20px"}}
                    onChange={(e) => {
                      addToSectionList('sectionName', e.target.value, i);
                    }}
                    placeholder={getUserDefinedName('schoolManagement.section.name', props)}
                  />
                </Col>

                <Col span={6}>
                  <TextBox
                    type="text"
                    className="textbox-pd"
                    value={item.sectionCode || ''}
                    maxLength={20}
                    countStyle={{top:"20px"}}
                    onChange={(e) => {
                      addToSectionList('sectionCode', e.target.value, i);
                    }}
                    placeholder={getUserDefinedName('schoolManagement.section.code', props)}
                  />
                </Col>

                <Col span={6}>
                  <TextBox
                    type="text"
                    maxLength={3}
                    countStyle={{display:"none"}}
                    value={item.sectionCapacity || ''}
                    onChange={(e) => {                    
                        addToSectionList('sectionCapacity', Number(e.target.value), i);                      
                    }}
                    placeholder={getUserDefinedName('sms.section.sectionCapacity', props)}
                  />
                </Col>

                <Col span={2}  className='create-class-section'>
              
           {
            i!==0 ?( <i 
              className={ICONS.DELETE_P}
              onClick={()=>{
                let temp = addSectionList;
                temp.splice(i, 1);
                props.updateState({
                  addSectionList: temp
                })
              }}
            />):""
           }  

                  {
                    (addSectionList || []).length === i+1 ? 
                      <i 
                        className={`${ICONS.ADD_P} ml5`}
                        onClick={()=>{
                          props.updateState({
                            addSectionList: [...(addSectionList || []), {}]
                          })
                        }}
                      />
                    :
                    ''
                  }
              
                 
                </Col>

                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || validSectionList(i);
                  }}
                  message={'Some Field are missing in sections'}
                />


              </Row>
            })}

          </>
            :
          ""
        }

      </Row>
        </Skeleton>
    </Drawer>
  );
}

export default injectIntl(SchoolDrawer);
