import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HsnComponent from '../../../components/finance/Hsn';
import { fetchTaxes, deleteTax, showModal, hideModal } from './../../../actions/commonActions';
import { saveSingleTaxRate } from '../../modal/modalBody/common/NewTax/action';
import { getAllGstRates } from '../../drawer/inventory/action';

class HSNComp extends Component {

    constructor(props) {
        super();
        this.state = {
            searchedText: ''
        }
    }

    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: false
        });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            type: 'GOODS',
        };
        this.props.getAllGstRates(payload);
    }



    render() {
        return <HsnComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        taxes: state.common.taxes,
        allGstRates: state.inventory.allGstRates,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTaxes,
    deleteTax,
    saveSingleTaxRate,
    showModal, hideModal,
    getAllGstRates
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HSNComp);
