import React, { Fragment } from "react";
import { Row, Col, Button, Divider } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import * as filter from 'lodash.filter';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { AG_GRID_CONSTANTS } from '../../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { MODAL_TYPE } from '../../../../../static/constants';
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
import { showToasterMessage } from '../../../../../utils';
import BusinessExpenseDetail from '../../../../../containers/modal/modalBody/finance/BusinessExpense';

class CustomerProductDetails extends React.Component {

    constructor(props) {
        super(props);
        // const expenseLedger = [];
        // (props.allLedgerAccounts || []).forEach((e)=>{
        //     if(e.ledgerAccountGroupName === 'Other Expenses'){
        //         expenseLedger.push(e);
        //     }
        // })
        
        this.state = {
            total: 0,
        }
    }

    calculateSubtotal = () => {
        let total = 0;

        this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
            let stringifiedRowData = JSON.stringify(rowNode.data);
            const rowdata = JSON.parse(stringifiedRowData);
            if (rowdata.expenseAmount) {
                total = total + Number(rowdata.expenseAmount)
            }
        });

        this.setState({
            total: Number(total || 0).toFixed(2)
        });
    }

    componentDidMount() {
        setTimeout(() => {
            this.calculateSubtotal();
            this.props.updateState({
                isRemarksSet: true
            })
        }, 3500)
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        let addFunc;
        let payload = {
            text: text,
            relationshipId: companyInfo.relationshipId,
        };

        switch (modalType) {
            case MODAL_TYPE.EXPENSE_TYPE: {
                addFunc = props.addExpenseTypes;
                break;
            }

            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc(payload)
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.EXPENSE_TYPE: {
                title = <FormattedMessage id='addItem.text.expenseType' defaultMessage='' />;
                break;
            }

            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.EXPENSE_TYPE: {
                deleteFunc = props.deleteExpenseTypes;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }

    render() {
        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='expenseType.text' defaultMessage="" />;
                },
                field: "ledgerAccountName",
                editable: true,
                sortable: true,
                cellEditor: 'customDropDownEditor',
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: filter((this.props.allLedgerAccounts || []), (ledger) => { return ledger.ledgerAccountGroupName === 'Other Expenses'; }),
                        optionKeyName: 'chartOfAccountDetailsId',
                        valueKeyName: 'ledgerAccountName',
                        // canAddNew: true,
                        // canDelete: true,
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                        },
                        onSelect: (selectedValue, option) => {
                            obj.node.data.chartOfAccountDetailsId = Number(option.key);
                            obj.node.data.ledgerAccountName = selectedValue;
                            obj.node.data.ledgerAccountDetailsId = 103;
                            obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.setDataValue("ledgerName", "Other Creditors");
                            obj.node.data.ledgerName = "Other Creditors";
                        },
                    }
                },
                cellRendererFramework: (params) => {
                return <div>{params.data.ledgerAccountName}</div>
                },
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.description'
                        defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
                cellClass: " ag-cell-description",
                editable: true,
                sortable: true,
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.description || "",
                        isEditorLoaded: this.props.isEditorLoaded,
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value;
                            params.node.data.isEditorLoaded = true;
                            this.props.updateState({
                                isEditorLoaded: true
                            })
                        }
                    }
                },
                cellRendererFramework: (params) => {
                    return ReactHtmlParser(params.data.description)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > 30) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${30}px`;
                        }
                        params.node.setRowHeight(30);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='ledger.text' defaultMessage="" />;
                },
                field: "ledgerName",
                editable: true,
                sortable: true,
                cellEditor: 'customDropDownEditor',
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: filter((this.props.allLedgerAccounts || []), (ledger) => { return ledger.categoryName === 'Current Liabilities'; }),
                        optionKeyName: 'chartOfAccountDetailsId',
                        valueKeyName: 'ledgerAccountName',
                        // canAddNew: true,
                        // canDelete: true,
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                        },
                        onSelect: (selectedValue, option) => {
                            obj.node.data.ledgerAccountDetailsId = Number(option.key);
                            obj.node.data.ledgerName = selectedValue;
                            obj.node.setDataValue(obj.colDef.field, selectedValue);
                        },
                    }
                },
                cellRendererFramework: (params) => {
                return <div>{params.data.ledgerName}</div>
                },
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='amountTaxInclusive.text' defaultMessage="" />;
                },
                field: "expenseAmount",
                editable: true,
                sortable: true,
                cellEditor: 'customNumberEditor',
                cellEditorParams: () => {
                    return {
                        lastColumnCell: false
                    }
                },
                valueGetter: (params) => {
                    this.calculateSubtotal();
                    return params.data.expenseAmount;
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.action'
                        defaultMessage='' />;
                },
                field: 'action', 
                lockPosition: true,
                pinned: 'right',
                cellRenderer: 'customActionEditor',
                suppressNavigable: true,
                cellRendererParams: (params) => {
                    return {
                        onClick: () => {
                            this.gridApi.updateRowData({ remove: [this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                            if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                            this.calculateSubtotal();
                        },
                    }
                }
            }
        ];

        const openExpenseDetails = (data) => {
            const expensePayload = {
                expenseMasterId: data.expenseMasterId
            }
            this.props.pushModalToStack({
                modalBody: <BusinessExpenseDetail {...this.props} expensePayload={expensePayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
        }

        const createRelationshipExpense = () => {

            let isAmountMissing = false;
            let isExpenseTypeMissing = false;


            if(this.props.isbankReco && this.props.bankRecoBalance && this.props.bankRecoBalance !== Number(this.state.total) ){
                return showToasterMessage({
                    messageType: 'error', description: this.props.intl.formatMessage({id: 'bankReco.createExpense.validation', defaultMessage: '' })
                });
            }

            

            this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
                let { data } = rowNode;
                if (!data.ledgerAccountName) {
                    isExpenseTypeMissing = true;
                } else if (!data.expenseAmount) {
                    isAmountMissing = true;
                }
            });

      
            if (isExpenseTypeMissing) {
                return showToasterMessage({
                    messageType: 'error', description: 'Please select expense type'
                });
            }

            if (isAmountMissing) {
                return showToasterMessage({
                    messageType: 'error', description: 'Please enter expense amount'
                });
            }
            
            const modalData = {
                modalBody: `Are you sure you want to create relationship expense ?`,
                handleSubmit: () => {
                    const payload = {
                        props: this.props,
                        gridObject: this.gridApi,
                        state: this.state,
                        //creditLedger
                    }
                    this.props.createRelationshipExpense(payload, openExpenseDetails);
                    this.props.hideModal();
                }
            };
            this.props.showModal(modalData);


        }

        return (
            <Fragment>
                <div className="transaction-table">
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        rowData={this.props.relationshipExpenseDetails || [{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100%',
                            marginBottom: '10px'
                        }} />
                </div>

                <div style={{display:'flex',gap:'10px'}}>

                        <Button className="grey-button" onClick={
                            () => {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                        }><FormattedMessage id='customer.salesInvoice.form.button.addLine'
                            defaultMessage='' /></Button>
                        <Button className="grey-button" onClick={
                            () => {
                                let gridApi = this.gridApi;
                                let self = this;
                                this.gridApi.forEachNode(function (rowNode, index) {
                                    gridApi.updateRowData({ remove: [rowNode.data] });
                                    if (gridApi.rowRenderer.lastRenderedRow < 0) {
                                        gridApi.updateRowData({ add: [{}] });
                                    }
                                    self.calculateSubtotal();
                                })
                            }
                        }><FormattedMessage id='customer.salesInvoice.form.button.clearAllLines'
                            defaultMessage='' /></Button>
                </div>
                <Divider  style={{margin: '10px 0px'}}/>
                <Row>
                    <Col span={12}>
                        <div className="remarks-title">
                        </div>
                        <div className="remarks-title">
                        <FormattedMessage id='remarks.text' defaultMessage="" />
                        </div>

                        <textarea value={this.props.remarksInternal || ''} className="description-textarea" rows={4}
                            onChange={(e) => {
                                this.props.updateState({
                                    remarksInternal: e.target.value
                                });
                            }} placeholder={this.props.intl.formatMessage(
                                {
                                    id: 'remarks.placeholder',
                                    defaultMessage: ''
                                })
                            }></textarea>
                    </Col>

                    <Col span={10} offset={2}>

                        <div className="product-amount-details">
                            <div className="amount-summary">
                                <Row>
                                    <Col span={15}>
                                        <span className="title"><FormattedMessage
                                            id='customer.salesInvoice.form.totalAmount.label'
                                            defaultMessage='' /></span>
                                    </Col>
                                    <Col span={9}>
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {this.state.total}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="footer">
                        <div className="left-fItems">
                        <Button className="grey-button" onClick={() => {
                            // this.props.history.goBack();
                            this.props.popModalFromStack()
                        }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                     
                           </div>
                        <div className="right-fItems">
                        <Button className="ant-btn-primary" onClick={() => {
                            createRelationshipExpense();
                        }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                    
                        </div>
                    </div>
               
            </Fragment>
        )
    }
};

export default injectIntl(CustomerProductDetails);