import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const saveOrUpdateFormPermission = (requestObj, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSettingPermissions/save`,
        requestObj,
      )
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Saved successfully.',
          messageType: 'success'
        });
        if (props.getAllStudentForm) {
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            relationshipEmployeeId: props.userInfo.relationshipEmployeeId,
            pageNumber: 1,
            pageSize: 100,
          };

          props.getAllStudentForm(payload);
        }
      })
      .catch(err => {
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}