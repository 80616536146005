import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import PageBreadcrumb from "../../PageBreadcrumb";
import './../finance.scss';
import { CONSTANTS } from "../../../static/constants";
import BalanceSheetNotes from '../../../containers/modal/modalBody/finance/balanceSheet/BalanceSheetNotes';
import { getFinancialYear, getMomentDateForUIReadOnly, getPreviousYearDate } from "../../../utils";
import { FormattedMessage } from "react-intl";

const BalanceSheet = (props) => {
    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.accounting' defaultMessage=' ' />,
        }, {
            name: <FormattedMessage id='breadcrum.finance.balanceSheet.text' defaultMessage='' />
        }
    ];

    const { allLedgerAccounts, companyInfo, allPrevFYLedgerAccounts } = props;
    const financialYear = getFinancialYear(companyInfo);

    const getAmountByLedgerGroup = (groupName, category, type, toReduceType) => {
        let randomTotalAmount = 0;

        for (let i = 0; i < allLedgerAccounts.length; i++) {

            if (allLedgerAccounts[i].ledgerAccountGroupName === groupName && allLedgerAccounts[i].categoryName === category && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) !== toReduceType) {
                randomTotalAmount = (randomTotalAmount + (allLedgerAccounts[i].amountDifference || 0));
            } else if (allLedgerAccounts[i].ledgerAccountGroupName === groupName && allLedgerAccounts[i].categoryName === category && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === toReduceType) {
                
                randomTotalAmount = (randomTotalAmount - (allLedgerAccounts[i].amountDifference || 0));
            }
        }

        

        return parseFloat((randomTotalAmount || 0).toFixed(2))

    }

    const getAmountByLedgerGroupPrev = (groupName, category, type, toReduceType) => {
        let randomTotalAmount = 0;

        for (let i = 0; i < allPrevFYLedgerAccounts.length; i++) {

            if (allPrevFYLedgerAccounts[i].ledgerAccountGroupName === groupName && allPrevFYLedgerAccounts[i].categoryName === category && ((allPrevFYLedgerAccounts[i].nature && allPrevFYLedgerAccounts[i].nature.toUpperCase()) || allPrevFYLedgerAccounts[i].accountingEntry.toUpperCase()) !== toReduceType) {
                randomTotalAmount = (randomTotalAmount + (allPrevFYLedgerAccounts[i].amountDifference || 0));
            } else if (allPrevFYLedgerAccounts[i].ledgerAccountGroupName === groupName && allPrevFYLedgerAccounts[i].categoryName === category && ((allPrevFYLedgerAccounts[i].nature && allPrevFYLedgerAccounts[i].nature.toUpperCase()) || allPrevFYLedgerAccounts[i].accountingEntry.toUpperCase()) === toReduceType) {
                
                randomTotalAmount = (randomTotalAmount - (allPrevFYLedgerAccounts[i].amountDifference || 0));
            }
        }

        

        return parseFloat((randomTotalAmount || 0).toFixed(2))

    }


    const getAmountByLedgerCategoryGroup = (groupName, category, type, toReduceType) =>{
        let randomTotalAmount = 0;
        
        for(var i=0;i < allLedgerAccounts.length;i++){
            if( allLedgerAccounts[i].categoryGroupName === groupName &&   allLedgerAccounts[i].categoryName === category && (( allLedgerAccounts[i].nature &&  allLedgerAccounts[i].nature.toUpperCase()) ||  allLedgerAccounts[i].accountingEntry.toUpperCase()) !== toReduceType){
                randomTotalAmount = randomTotalAmount+ allLedgerAccounts[i].amountDifference;
                
            }else if( allLedgerAccounts[i].categoryGroupName === groupName &&   allLedgerAccounts[i].categoryName === category && (( allLedgerAccounts[i].nature &&  allLedgerAccounts[i].nature.toUpperCase()) ||  allLedgerAccounts[i].accountingEntry.toUpperCase()) === toReduceType){
                randomTotalAmount = randomTotalAmount- allLedgerAccounts[i].amountDifference;
            }
        }

         return parseFloat((randomTotalAmount || 0).toFixed(2));
        
    }

    // const getTotalForLedger = (type) => {
    //     let randomTotalAmount = 0;
    //     switch (type) {
    //         case "Expenses": {
    //             for (var i = 0; i < allLedgerAccounts.length; i++) {
    //                 if ((allLedgerAccounts[i].categoryName === "Cost of Materials" || allLedgerAccounts[i].categoryName === "Employee Benefit Expense" || allLedgerAccounts[i].categoryName === "Other Expenses" || allLedgerAccounts[i].categoryName === "Taxes" || allLedgerAccounts[i].categoryName === "Finance Cost") && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
    //                     randomTotalAmount = (randomTotalAmount + (allLedgerAccounts[i].amountDifference || 0));
    //                 } else if ((allLedgerAccounts[i].categoryName === "Cost of Materials" || allLedgerAccounts[i].categoryName === "Employee Benefit Expense" || allLedgerAccounts[i].categoryName === "Other Expenses" || allLedgerAccounts[i].categoryName === "Taxes" || allLedgerAccounts[i].categoryName === "Finance Cost") && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
    //                     randomTotalAmount = (randomTotalAmount - (allLedgerAccounts[i].amountDifference || 0));
    //                 }
    //             }
    //             break;
    //         }
    //         case "Income": {
    //             for (var j = 0; j < allLedgerAccounts.length; j++) {
    //                 if ((allLedgerAccounts[j].categoryName === "Revenue from Operations" || allLedgerAccounts[j].categoryName === "Other Income") && ((allLedgerAccounts[j].nature && allLedgerAccounts[j].nature.toUpperCase()) || allLedgerAccounts[j].accountingEntry.toUpperCase()) === "CREDIT") {
    //                     randomTotalAmount = (randomTotalAmount + (allLedgerAccounts[j].amountDifference || 0));

    //                 } else if ((allLedgerAccounts[j].categoryName === "Revenue from Operations" || allLedgerAccounts[j].categoryName === "Other Income") && ((allLedgerAccounts[j].nature && allLedgerAccounts[j].nature.toUpperCase()) || allLedgerAccounts[j].accountingEntry.toUpperCase()) === "DEBIT") {
    //                     randomTotalAmount = (randomTotalAmount - (allLedgerAccounts[j].amountDifference || 0));
    //                 }
    //             }
    //             break;
    //         }
    //         default: {
    //             break;
    //         }
    //     }
    //     return parseFloat((randomTotalAmount || 0).toFixed(2))
    // }

    const setAppropriateAccountData = () => {
        let amount = Number(((getAmountByLedgerCategoryGroup("Income", "Revenue from Operations", "amount", 'DEBIT') + getAmountByLedgerCategoryGroup("Income", "Other Income", "amount", 'DEBIT'))-(getAmountByLedgerCategoryGroup("Expenses", "Cost of Materials", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Purchases", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Employee Benefit Expense", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Finance Cost", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Depreciation & Amortisation", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Other Expenses", "amount", 'CREDIT'))) || 0);
        (allLedgerAccounts || []).forEach(function (data) {
            if (data.ledgerAccountName === 'Profit & Loss Appropriation') {
                if (amount < 0) {
                    data.debitAmount = amount;
                } else {
                    data.creditAmount = amount
                }
                data.amountDifference = amount;
            }
        });
    }

    setAppropriateAccountData();



    const getTotal = (type) => {
        let randomTotalAmount = 0;
        switch (type) {
            case "Equity and Liabilities": {
                for (let i = 0; i < allLedgerAccounts.length; i++) {
                    if (allLedgerAccounts[i].ledgerAccountName !== "Profit & Loss Appropriation" && (allLedgerAccounts[i].categoryName === "Shareholder Funds" || allLedgerAccounts[i].categoryName === "Non Current Liabilities" || allLedgerAccounts[i].categoryName === "Current Liabilities") && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
                        randomTotalAmount = (randomTotalAmount + (allLedgerAccounts[i].amountDifference || 0));
                    } else if (allLedgerAccounts[i].ledgerAccountName !== "Profit & Loss Appropriation" && (allLedgerAccounts[i].categoryName === "Shareholder Funds" || allLedgerAccounts[i].categoryName === "Non Current Liabilities" || allLedgerAccounts[i].categoryName === "Current Liabilities") && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
                        randomTotalAmount = (randomTotalAmount - (allLedgerAccounts[i].amountDifference || 0));
                    }
                    
                    if(allLedgerAccounts[i].ledgerAccountName === "Profit & Loss Appropriation"){
                        randomTotalAmount = randomTotalAmount+ allLedgerAccounts[i].amountDifference;
                    }
                }
                break;
            }
            case "Assets": {
                for (let i = 0; i < allLedgerAccounts.length; i++) {
                    if ((allLedgerAccounts[i].categoryGroupName === "Asset" || allLedgerAccounts[i].categoryName === "Current Assets") && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
                        randomTotalAmount = (randomTotalAmount + (allLedgerAccounts[i].amountDifference || 0));
                    } else if ((allLedgerAccounts[i].categoryGroupName === "Asset" || allLedgerAccounts[i].categoryName === "Current Assets") && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
                        randomTotalAmount = (randomTotalAmount - (allLedgerAccounts[i].amountDifference || 0));
                        
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        return parseFloat((randomTotalAmount || 0).toFixed(2))
    }

    const getTotalPrev = (type) => {
        let randomTotalAmount = 0;
        switch (type) {
            case "Equity and Liabilities": {
                for (let i = 0; i < allPrevFYLedgerAccounts.length; i++) {
                    if (allPrevFYLedgerAccounts[i].ledgerAccountName !== "Profit & Loss Appropriation" && (allPrevFYLedgerAccounts[i].categoryName === "Shareholder Funds" || allPrevFYLedgerAccounts[i].categoryName === "Non Current Liabilities" || allPrevFYLedgerAccounts[i].categoryName === "Current Liabilities") && ((allPrevFYLedgerAccounts[i].nature && allPrevFYLedgerAccounts[i].nature.toUpperCase()) || allPrevFYLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
                        randomTotalAmount = (randomTotalAmount + (allPrevFYLedgerAccounts[i].amountDifference || 0));
                    } else if (allPrevFYLedgerAccounts[i].ledgerAccountName !== "Profit & Loss Appropriation" && (allPrevFYLedgerAccounts[i].categoryName === "Shareholder Funds" || allPrevFYLedgerAccounts[i].categoryName === "Non Current Liabilities" || allPrevFYLedgerAccounts[i].categoryName === "Current Liabilities") && ((allPrevFYLedgerAccounts[i].nature && allPrevFYLedgerAccounts[i].nature.toUpperCase()) || allPrevFYLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
                        randomTotalAmount = (randomTotalAmount - (allPrevFYLedgerAccounts[i].amountDifference || 0));
                    }
                    
                    if(allPrevFYLedgerAccounts[i].ledgerAccountName === "Profit & Loss Appropriation"){
                        randomTotalAmount = randomTotalAmount+ allPrevFYLedgerAccounts[i].amountDifference;
                    }
                }
                break;
            }
            case "Assets": {
                for (let i = 0; i < allPrevFYLedgerAccounts.length; i++) {
                    if ((allPrevFYLedgerAccounts[i].categoryGroupName === "Asset" || allPrevFYLedgerAccounts[i].categoryName === "Current Assets") && ((allPrevFYLedgerAccounts[i].nature && allPrevFYLedgerAccounts[i].nature.toUpperCase()) || allPrevFYLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
                        randomTotalAmount = (randomTotalAmount + (allPrevFYLedgerAccounts[i].amountDifference || 0));
                    } else if ((allPrevFYLedgerAccounts[i].categoryGroupName === "Asset" || allPrevFYLedgerAccounts[i].categoryName === "Current Assets") && ((allPrevFYLedgerAccounts[i].nature && allPrevFYLedgerAccounts[i].nature.toUpperCase()) || allPrevFYLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
                        randomTotalAmount = (randomTotalAmount - (allPrevFYLedgerAccounts[i].amountDifference || 0));
                        
                    }
                }
                break;
            }
            default: {
                break;
            }
        }

        return parseFloat((randomTotalAmount || 0).toFixed(2))
    }

    const _openBalancesheetNotes = (tabType, notesName, number, toReduceType, heading) => {
        const payload = {};
        payload.tabType =  tabType;
        payload.keyName = 'ledgerAccountGroupName';
        payload.notesNumber = number;
        payload.notesName = notesName;
        payload.toReduceType = toReduceType;
        props.showModal(
            {
                title: <Fragment>
                    <div className='ant-modal-title'>
                        ({number}) {heading || tabType}
                    </div>
                </Fragment>,
                modalBody: <BalanceSheetNotes {...props} {...payload} />,
                width: 600,
                hideFooter: true
            }
        );
    }

    return (
        <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="heading.finance.balanceSheet"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="top-view-right"> 
            </div>
          
        </div>
            <div className="view-container">
            <div className="view-container-actions">
                    <div className="left-actions">
                    <div className="table-heading">
                        {(companyInfo || {}).storeName}<br/>
                        <div className="light-txt">
                        <FormattedMessage id='finance.balanceSheetAsOn.text' defaultMessage='' /> {((financialYear || {}).fyEndDate && getMomentDateForUIReadOnly({date: financialYear.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}))}
                        </div>
                    </div>

                    </div>
                    <div className="right-actions">
                    </div>
                </div>

                {/* <div className="page-title pb10">
                    <div className="bold-text">
                        {companyInfo.storeName}
                    </div>
                    <div>
                        <FormattedMessage id='finance.balanceSheetAsOn.text' defaultMessage='' /> {(financialYear.fyEndDate && getMomentDateForUIReadOnly({date: financialYear.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}))}
                    </div>
                </div> */}
                <div style={{height: 'calc(100% - 50px)'}} className="table-container" >
                    <table className="finance-table">
           

                    {/* <thead>
                        <tr>
                            <th colSpan="5">
                                <div className="bold-text">
                                    {companyInfo.storeName}
                                </div>
                                <div>
                                    <FormattedMessage id='finance.balanceSheetAsOn.text' defaultMessage='' /> {(financialYear.fyEndDate && getMomentDateForUIReadOnly({date: financialYear.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}))}
                                </div>
                            </th>
                        </tr>
                    </thead> */}
                    <thead>
                        <tr>
                            <th colSpan="2">
                                <FormattedMessage id='particulars.text' defaultMessage='    ' />
                            </th>
                            <th>
                                <FormattedMessage id='notes.text' defaultMessage='        ' />
                            </th>
                            <th>
                                <FormattedMessage id='asOn.text' defaultMessage='' /> {(financialYear.fyEndDate && getMomentDateForUIReadOnly({date: financialYear.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}))}
                            </th>
                            <th>
                            <FormattedMessage id='asOn.text' defaultMessage='' /> {(financialYear.fyEndDate && getMomentDateForUIReadOnly({date: getPreviousYearDate(financialYear.fyEndDate), format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}))}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="bold-text">
                                <FormattedMessage id='a.text' defaultMessage=' ' /> 
                            </td>
                            <td className="bold-text">
                                <FormattedMessage id='equityAndLiabilities.text' defaultMessage=' ' />
                            </td>

                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td className="bold-text">1</td>
                            <td className="bold-text"><FormattedMessage id='shareHolderFund.text' defaultMessage=" "/> </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td>(a)</td>
                            <td><FormattedMessage id='shareCapital.text' defaultMessage=" " /></td>
                            <td><Link onClick={() => {
                                _openBalancesheetNotes('Equity Share Capital' , 'Share Capital', 1, 'DEBIT')
                            }}>1</Link></td>
                            <td>{getAmountByLedgerGroup("Equity Share Capital", "Shareholder Funds", "amount", 'DEBIT') || 0.00}</td>
                            <td>{getAmountByLedgerGroupPrev("Equity Share Capital", "Shareholder Funds", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td>(b)</td>
                            <td><FormattedMessage id='reserveAndSurplus.text' defaultMessage=" " /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Reserves & Surplus', 'Reserves & Surplus', 2, 'DEBIT')
                            }}>2</Link></td>
                            <td>{getAmountByLedgerGroup('Reserves & Surplus', "Shareholder Funds", "amount", 'DEBIT') || 0.00}</td>
                            <td>{getAmountByLedgerGroupPrev('Reserves & Surplus', "Shareholder Funds", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td>(c)</td>
                            <td><FormattedMessage id='moneyRecievedShareWarrants.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Money Received against Share Warrants', 'Money Received against Share Warrants', 3, 'DEBIT')
                            }}>3</Link></td>
                            <td>{getAmountByLedgerGroup("Money Received against Share Warrants", "Shareholder Funds", "amount", 'DEBIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Money Received against Share Warrants", "Shareholder Funds", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td className="bold-text">2</td>
                            <td className="bold-text"><FormattedMessage id='nonCurrentLibilities.text' defaultMessage="" /></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(a).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='longTermBorrow.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Long Term Borrowings', 'Long Term Borrowings', 4, 'DEBIT')
                            }}>4</Link></td>
                            <td>{getAmountByLedgerGroup("Long Term Borrowings", "Non Current Liabilities", "amount", 'DEBIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Long Term Borrowings", "Non Current Liabilities", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(b).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='defferedLiablities.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Deferred Tax Liability', 'Deferred Tax Liability', 5, 'DEBIT')
                            }}>5</Link></td>
                            <td>{getAmountByLedgerGroup("Deferred Tax Liability", "Non Current Liabilities", "amount", 'DEBIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Deferred Tax Liability", "Non Current Liabilities", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td>(c)</td>
                            <td><FormattedMessage id='otherLongTermLiabilities.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Other Long term Liabilities', 'Other Long Term Liabilities', 6, 'DEBIT')
                            }}>6</Link></td>
                            <td>{getAmountByLedgerGroup("Other Long term Liabilities", "Non Current Liabilities", "amount", 'DEBIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Other Long term Liabilities", "Non Current Liabilities", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td>(d)</td>
                            <td><FormattedMessage id='longTermProvision.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Long term provisions', 'Long term provisions', 7, 'DEBIT')
                            }}>7</Link></td>
                            <td>{getAmountByLedgerGroup("Long term provisions", "Non Current Liabilities", "amount", 'DEBIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Long term provisions", "Non Current Liabilities", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td className="bold-text">3</td>
                            <td className="bold-text"><FormattedMessage id='currentLibilities.text' defaultMessage="" /></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td>(a)</td>
                            <td><FormattedMessage id='shortTermBorrowing.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Short term borrowings', 'Short term borrowings' , 8, 'DEBIT')
                            }}>8</Link></td>
                            <td>{getAmountByLedgerGroup("Short term borrowings", "Current Liabilities", "amount", 'DEBIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Short term borrowings", "Current Liabilities", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(b).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='tradePayable.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Trade Payables', 'Trade Payables', 9, 'DEBIT')
                            }}>9</Link></td>
                            <td>{getAmountByLedgerGroup("Trade Payables", "Current Liabilities", "amount", 'DEBIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Trade Payables", "Current Liabilities", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(c).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='otherCurrentLiabilities.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Other Current Liabilities', 'Other Current Liabilities', 10, 'DEBIT')
                            }}>10</Link></td>
                            <td>{getAmountByLedgerGroup("Other Current Liabilities", "Current Liabilities", "amount", 'DEBIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Other Current Liabilities", "Current Liabilities", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(d).text' defaultMessage="" /></td>
                            <td>Short Term Provisions</td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Short term provisions', 'Short term provision', 11, 'DEBIT')
                            }}>11</Link></td>
                            <td>{getAmountByLedgerGroup("Short term provisions", "Current Liabilities", "amount", 'DEBIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Short term provisions", "Current Liabilities", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td className="bold-text text-right">Total</td>
                            <td>&nbsp;</td>
                            <td>
                                <span className="bold-text">{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>{getTotal('Equity and Liabilities')}
                        </td>
                            <td>
                            <span className="bold-text">{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>{getTotalPrev('Equity and Liabilities')}
                            </td>
                        </tr>
 
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td className="bold-text">
                                <FormattedMessage id='b.text' defaultMessage="" />
                            </td>
                            <td className="bold-text">
                                <FormattedMessage id='assets.text' defaultMessage=" " />
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td className="bold-text">1</td>
                            <td className="bold-text"><FormattedMessage id='noncurrentAssets.text' defaultMessage="" /></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td>(a)</td>
                            <td><FormattedMessage id='fixedAssets.text' defaultMessage=" " /></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td><FormattedMessage id='tangibleAssets.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Fixed Asset - Tangible',' Tangible Asset', 12, 'CREDIT')
                            }}>12</Link></td>
                            <td>{getAmountByLedgerGroup("Fixed Asset - Tangible", "Non - Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Fixed Asset - Tangible", "Non - Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td><FormattedMessage id='intangibleAssets.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Fixed Asset - Intangible', 'Intangible Asset', 13, 'CREDIT')
                            }}>13</Link></td>
                            <td>{getAmountByLedgerGroup("Fixed Asset - Intangible", "Non - Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Fixed Asset - Intangible", "Non - Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(b).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='nonCurrentInvestMents.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Investments', 'Non Current Investments', 14, 'CREDIT', 'Non Current Investments')
                            }}>14</Link></td>
                            <td>{getAmountByLedgerGroup("Investments", "Non - Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Investments", "Non - Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(c).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='longTermLoansAndAdvance.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Long Term Loans & Advances', 'Long Term Loans &amp; Advances', 15 , 'CREDIT')
                            }}>15</Link></td>
                            <td>{getAmountByLedgerGroup("Long Term Loans & Advances", "Non - Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Equity Share Capital", "Shareholder Funds", "amount", 'DEBIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(d).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='otherNonCurrentAssets.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Other Non Current Assets', 'Other Non Current Assets', 16, 'CREDIT')
                            }}>16</Link></td>
                            <td>{getAmountByLedgerGroup("Other Non Current Assets", "Non - Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Other Non Current Assets", "Non - Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td className="bold-text">2</td>
                            <td className="bold-text"><FormattedMessage id='currentAssets.text' defaultMessage="" /></td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(a).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='currentInvestment.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Current Investments', 'Current Investments', 17, 'CREDIT')
                            }}>17</Link></td>
                            <td>{getAmountByLedgerGroup("Current Investments", "Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Current Investments", "Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(b).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='inventories.text' defaultMessage="" /></td>
                            <td><Link onClick={()=>{
                                _openBalancesheetNotes('Inventory', 'Inventories', 18, 'CREDIT')
                            }}>18</Link></td>
                            <td>{getAmountByLedgerGroup("Inventory", "Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Inventory", "Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(c).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='tradeRecievable.text' defaultMessage="" /></td>
                            <td><Link onClick={
                                ()=>{
                                    _openBalancesheetNotes('Trade Receivables', 'Trade Receivables', 19, 'CREDIT')
                                }
                            }>19</Link></td>
                            <td>{getAmountByLedgerGroup("Trade Receivables", "Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Trade Receivables", "Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(d).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='cashEquivalent.text' defaultMessage="" /></td>
                            <td><Link onClick={
                                ()=>{
                                    _openBalancesheetNotes('Cash & Cash Equivalents', 'Cash & Cash Equivalents', 20, 'CREDIT')
                                }
                            }>20</Link></td>
                            <td>
                                {getAmountByLedgerGroup("Cash & Cash Equivalents", "Current Assets", "amount", 'CREDIT')}
                            </td>
                            <td>{getAmountByLedgerGroupPrev("Cash & Cash Equivalents", "Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(e).text' defaultMessage="" /></td>
                            <td><FormattedMessage id='shortTermAdvance.text' defaultMessage="" /></td>
                            <td><Link onClick={
                                ()=>{
                                    _openBalancesheetNotes('Short Term Loans & Advances', 'Short Term Loans & Advances', 21, 'CREDIT')
                                }
                            }>21</Link></td>
                            <td>{getAmountByLedgerGroup("Short Term Loans & Advances", "Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Short Term Loans & Advances", "Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>

                        <tr>
                            <td><FormattedMessage id='(f).text' defaultMessage="" /></td>
                            <td>Other Current Assets</td>
                            <td><Link onClick={
                                ()=>{
                                    _openBalancesheetNotes('Other Current Assets', 'Other Current Assets', 22, 'CREDIT')
                                }
                            }>22</Link></td>
                            <td>{getAmountByLedgerGroup("Other Current Assets", "Current Assets", "amount", 'CREDIT')}</td>
                            <td>{getAmountByLedgerGroupPrev("Other Current Assets", "Current Assets", "amount", 'CREDIT') || 0.00}</td>
                        </tr>


                        <tr>
                            <td>&nbsp;</td>
                            <td className="bold-text"><FormattedMessage id='total.text' defaultMessage="" /></td>
                            <td>&nbsp;</td>
                            <td>
                                <span className="bold-text">{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>{getTotal('Assets')}
                            </td>
                            <td>
                              <span className="bold-text">{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>{getTotalPrev('Assets')}
                              </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </>
    )
};

export default BalanceSheet;