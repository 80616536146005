import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import moment from 'moment-timezone';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, DatePicker, Select, Checkbox, Tooltip, InputNumber } from 'antd';
import { formatDateForBackend, validateEmail } from '../../../../utils';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { TextArea } from '../../../general/TextAreaComponent';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { CONSTANTS } from '../../../../static/constants';

function PledgeDrawer(props) {
  const { Option } = Select;

  const { allCampaignList, paymentModeList, amount, paymentModeId, campaignId } = props;

  const format = CONSTANTS.DISPLAY_DATE_FORMAT;

  const closeDrawer = () => {
    props.updateHeaderState({
      fundraising: {
        addPledgeDrawerVisible: false
      }
    })
  }

  // const newCustomer = () => {
  //   props.updateHeaderState({
  //     customer: {
  //       ...props.customer,
  //       customerDrawerVisible: true,
  //     },
  //   });
  // };

  const handleSubmit = (props) => {
    if (props.firstName && props.amount && props.emailAddress && validateEmail(props.emailAddress) && props.campaignId && props.period && (props.period === 'oneTimePayment' || (props.period !== 'oneTimePayment' && (props.neverExpires || props.totalOccurrences)))) {
      const payload = {
        //donorId: props.donorId,
        // donorFullName: props.donorFullName,
        firstName: props.firstName,
        lastName: props.lastName,
        emailAddress: props.emailAddress,
        cellPhone: props.cellPhone ? (props.companyInfo.countryCallingCode ? '+'+props.companyInfo.countryCallingCode : '') + props.cellPhone  : '',
        amount: props.amount,
        paymentModeId: props.paymentModeId,
        paymentModeDetails: props.paymentModeDetails,
        startDate: formatDateForBackend(props.startDate),
        campaignId: props.campaignId,
        status: props.status,
        neverExpires: props.neverExpires,
        totalOccurrences: props.neverExpires ? 9999 : props.totalOccurrences,
        relationshipId: props.relationshipId,
        pledgeId: props.pledgeId,
        version: props.version,
        dateCreated: props.dateCreated,
        remarks: props.remarks,
        isUpdate: props.pledgeId ? 1 : 0,
        source: props.source
      };

      if (props.period === 'weekly') {
        payload.isWeekly = 1;
      } else if (props.period === 'monthly') {
        payload.isMonthly = 1;
      } else if (props.period === 'yearly') {
        payload.isYearly = 1;
      }

      if (props.period === 'oneTimePayment') {
        payload.startDate = null;
        payload.isRecurring = 0;
        payload.isWeekly = 0;
        payload.isMonthly = 0;
        payload.isYearly = 0;
      } else {
        payload.isRecurring = 1;
      }

      props.pushModalToStack({
        modalBody: props.pledgeId ? <FormattedMessage id='commons.edit.popup.confimation.msg' defaultMessage='' /> : <FormattedMessage id='fundraising.pledge.addDrawer.confitmation' defaultMessage='' />,
        handleSubmit: () => {
          props.saveFundraisingPledge(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-title">
            <FormattedMessage id='fundraising.pledge.addDrawer.drawerName' defaultMessage=' ' />
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-drawer"
        visible={props.addPledgeDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
        footer={<>
          <CustomButton
             intlId='confirmation.cancel'
             defaultMessage=''
             type='default'
             key={0}
             style={{ marginRight: 8 }}
             onClick={closeDrawer}
           />
           <CustomButton
             intlId='confirmation.submit'
             defaultMessage=''
             htmlType='submit'
             onClick={() => handleSubmit(props)}
             key={122}
           />
         </>}
      >
        <Form layout="vertical" hideRequiredMark>

          {/* <Row gutter={16}>
            <Col  span={12}>
              <label>
                <FormattedMessage id='fundraising.pledge.addDrawer.field.donar' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <Dropdown
                className={(props.submittedOnce && !props.donorId) ? 'input-text-error' : ''}
                items={customers[1]}
                canAddNew={true}
                onAddNewClick={(payload) => {
                  newCustomer();
                }}
                valueKeyName='companyName'
                optionKeyName='customerId'
                value={donorId}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'fundraising.pledge.addDrawer.field.donar',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      donorId: option.value,
                      donorFullName: option.name,
                    })
                  }
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.donorId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row> */}
          <Row gutter={[16, 4]}>
            <Col  span={12}>
              <span className="">
                <FormattedMessage id='contact.firstName' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col  span={12}>
              <span className="">
                <FormattedMessage id='contact.lastName' defaultMessage='' />
              </span>
              {/* <span className="required">*</span> */}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                type='text'
                maxLength={25}
                className={(props.submittedOnce && !props.firstName) ? 'input-text-error' : ''}
                value={props.firstName}
                onChange={(e) => {
                  props.updateState({
                    firstName: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'contact.firstName.placeholder', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.firstName }}
                message={props.intl.formatMessage(
                  {
                    id: 'contact.firstName.message',
                    defaultMessage: ''
                  })
                } />
            </Col>
            <Col span={12}>
              <TextBox
                type='text'
                // className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                value={props.lastName}
                maxLength={25}
                onChange={(e) => {
                  props.updateState({
                    lastName: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'contact.lastName.placeholder', defaultMessage: '' })}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <span className="">
                <FormattedMessage id='email' defaultMessage='' />
              </span>
               <span className="required">*</span> 
            </Col>
            <Col  span={12}>
              <span className="">
                <FormattedMessage id='signup.phoneNumber.text' defaultMessage='' />
              </span>
              {/* <span className="required">*</span> */}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                type='text'
                maxLength={254}
               className={(props.submittedOnce && !props.emailAddress) ? 'input-text-error' : ''}
                value={props.emailAddress}
                onChange={(e) => {
                  props.updateState({
                    emailAddress: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'email', defaultMessage: '' })}
              />
            </Col>
            <Col span={12} className="input-currency-control">
              <span className="currency-tag" >
                {props.companyInfo.countryCallingCode ?  '+' + props.companyInfo.countryCallingCode : " "}
              </span>

              <TextBox
                type='number'
                // className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                value={props.cellPhone}
                maxLength={10}
                onChange={(e) => {
                  props.updateState({
                    cellPhone: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'signup.phoneNumber.text', defaultMessage: '' })}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col  span={12}>
              <span className="">
                <FormattedMessage id='fundraising.pledge.addDrawer.field.amount' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col  span={12}>
              <label>
                <FormattedMessage id='fundraising.pledge.addDrawer.field.paymentMode' defaultMessage='' /></label>
              {/* <span className="required">*</span> */}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12} className="input-currency-control">
              <span className="currency-tag">
                {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
              </span>
              <TextBox
                type='number'
                className={(props.submittedOnce && (props.amount <= 0 || !props.amount)) ? 'input-text-error' : ''}
                value={amount || null}
                onChange={(e) => {
                  props.updateState({
                    amount: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'fundraising.pledge.addDrawer.field.amount', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.amount }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
            <Col span={12}>
              <Dropdown
                // className={(props.submittedOnce && !props.paymentMode) ? 'input-text-error' : ''}
                items={paymentModeList}
                valueKeyName='paymentModeName'
                optionKeyName='paymentModeId'
                value={paymentModeId}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'fundraising.pledge.addDrawer.field.paymentMode',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      paymentModeId: option.value,
                      paymentMode: option.name,
                    })
                  }
                }
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || props.campaignId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>
          </Row>

          {/* <Row gutter={16}>
            <Col  span={12}>
              <span className="">
                <FormattedMessage id='fundraising.pledge.addDrawer.field.paymentModeDetails' defaultMessage='' />
              </span>
            </Col>
            <Col span={12}>

              <TextBox
                type='text'
                value={paymentModeDetails}
                onChange={(e) => {
                  props.updateState({
                    paymentModeDetails: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'fundraising.pledge.addDrawer.field.paymentModeDetails', defaultMessage: '' })}
              />
              
            </Col>
          </Row>

          <br /> */}

          {/* <Row gutter={16}>
            <Col  span={12}>
              <label>
                <FormattedMessage id='fundraising.pledge.addDrawer.field.startDate' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <DatePicker
                allowClear={false}
                format={format}
                key='startDate'
                defaultValue={getMomentDateForUI({ date: startDate, format })}
                onChange={(selectedDate) => {
                  props.updateState({
                    startDate: selectedDate
                  })
                  // this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                }}
                placeholder={props.intl.formatMessage({ id: 'fundraising.pledge.addDrawer.field.startDate', defaultMessage: '' })}
              // size={size}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.startDate }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <br /> */}

          {/* <Row gutter={16}>
            <Col  span={12}>
              <label>
                <FormattedMessage id='fundraising.pledge.addDrawer.field.status' defaultMessage='' /></label>
            </Col>
            <Col span={12}>
              <Dropdown
                items={[{ val: 'Active', displayName: 'Active' }, { val: 'Inactive', displayName: 'Inactive' }]}
                valueKeyName='displayName'
                optionKeyName='val'
                value={status}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'fundraising.pledge.addDrawer.field.status',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      status: option.value
                    })
                  }
                }
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || props.status }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> 
            </Col>
          </Row> */}
        {/*   <br /> */}

          <Row gutter={[16, 4]}>
            <Col span={12}>
              <label>
                <FormattedMessage id='fundraising.pledge.addDrawer.field.fundraisingCampaign' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={8}>
              <FormattedMessage id='makeRecurring.text' defaultMessage='' />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                className={(props.submittedOnce && !props.campaignId) ? 'input-text-error' : ''}
                items={allCampaignList}
                valueKeyName='campaignName'
                optionKeyName='fundraisingCampaignId'
                value={campaignId}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'fundraising.pledge.addDrawer.field.fundraisingCampaign',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      campaignId: option.value
                    })
                  }
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.campaignId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
            <Col span={12}>
              <Select onChange={(value) => {
                props.updateState({
                  period: value,
                })
              }}
                className={(props.submittedOnce && !props.period) ? 'input-text-error' : ''}
                // style={{ width: 320 }}
                placeholder="Please select"
                optionFilterProp="children"
                value={props.period}>
                <Option value="oneTimePayment"><FormattedMessage id='oneTimePayment.text' /></Option>
                {/* <Option value="daily"><FormattedMessage id='recurringInvoice.setup.daily' /></Option> */}
                <Option value="weekly"><FormattedMessage id='recurringInvoice.setup.weekly' /></Option>
                <Option value="monthly"><FormattedMessage id='recurringInvoice.setup.monthly' /></Option>
                <Option value="yearly"><FormattedMessage id='recurringInvoice.setup.yearly' /></Option>
              </Select>
              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.period }}
                message={<FormattedMessage id='addItem.validation' values={{ value: 'This Field' }} />}
              />
            </Col>
          </Row>
          {(props.period && props.period !== "oneTimePayment") ?<>
            <Row gutter={[16, 4]}>
              <Col span={12}>
                <Tooltip placement="top" title={props.intl.formatMessage({ id: 'paymentStartDate.helpMessage' })}>
                  <span className="tooltip-title">
                    <FormattedMessage id='paymentStartDate.text' defaultMessage='' />
                    <span className="required">*</span>
                  </span>
                </Tooltip>
              </Col>
             <Col span={12}>
                <FormattedMessage id='noOfPayment.text' defaultMessage='' />
                <span className="required">*</span>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col span={12}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  // style={{ width: 320 }}
                  // disabledDate={invalidEndDate}
                  key='{moment(new Date(), format)}'
                  defaultValue={props.startDate ? moment(new Date(props.startDate), format) : null}
                  onChange={(selectedDate) => {
                    props.updateState({
                      startDate: selectedDate
                    })
                  }} />
                <ErrorMsg
                  validator={() => { return !props.submittedOnce || props.startDate }}
                  message={props.intl.formatMessage(
                    {
                      id: 'requiredField.missing.msg',
                      defaultMessage: ''
                    })
                  } />
              </Col>
              <Col span={12}>
                  {!props.neverExpires && <div style={{ paddingBottom: '10px' }}>
                    <InputNumber style={{ width: 320 }} value={props.totalOccurrences} placeholder="" min={0} onChange={(value) => {
                      props.updateState({ totalOccurrences: value })
                    }} />
                    <ErrorMsg
                      validator={() => { return !props.submittedOnce || props.totalOccurrences }}
                      message={props.intl.formatMessage(
                        {
                          id: 'requiredField.missing.msg',
                          defaultMessage: ''
                        })
                      } /></div>
                  }
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
                props.updateState({
                  neverExpires: e.target.checked ? 1 : 0,
                })
               }} /> <FormattedMessage id='recurringInvoice.setup.neverExpires' defaultMessage='' />
              </Col>
            </Row>
            </>: ''
          }
          <Row gutter={[16, 4]}>
            <Col span={24}>
              <span className="">
                <FormattedMessage id='remarks.text' defaultMessage='' />
              </span>
              {/* <span className="required">*</span> */}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextArea
                type='text'
                style = {{width: '100%', minHeight: '100px', padding: '5px'}}
                // className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                value={props.remarks}
                onChange={(e) => {
                  props.updateState({
                    remarks: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'remarks.placeholder', defaultMessage: '' })}
              />
            </Col>
          </Row>

        </Form>

        {/* <div className='form-footer'>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.submit'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </div> */}
      </Drawer >
    </Fragment >
  );
}

export default injectIntl(PledgeDrawer);
