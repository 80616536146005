import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import PageBreadcrumb from "../../PageBreadcrumb";
import { getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import { Menu, Switch, Button } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { ICONS } from "../../../static/constants";

const LeadSubSourceList = (props) => {
  const { dir = 0 } = props;

  // const breadCrumbList = [
  //   {
  //     name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
  //   },
  //   {
  //     name: <FormattedMessage id="heading.lead.leadForm" defaultMessage="" />,
  //   },
  //   {
  //     name: (
  //       <FormattedMessage id="sidebar.menuItems.FormMeta" defaultMessage="" />
  //     ),
  //   },
  //   {
  //     name: <FormattedMessage id="lead.leadSource.label" defaultMessage="" />,
  //   },
  // ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("leadSource-table"), cellIndex, 1 - dir);
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              leadSourceListDrawer: true,
              ...item,
            });
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>

        <Menu.Item
          key="2"
          value="delete"
          onClick={() => {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteCrmLeadSource(item, props);
              },
            });
          }}
        >
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item>
      </Menu>
    );
  };

  return (
    // <div className="lead-list">

    <div className="view-container">
      <div className="view-container-actions">
        <div className="left-actions">
          <div className="table-heading">
            <FormattedMessage
              id="leads.leadForm.formMeta.leadSource.table.heading"
              defaultMessage=""
            />
          </div>
        </div>

        <div className="right-actions">
          <Button
            style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
            className="create-action-btn"
            onClick={() => {
              // handleAddUpdateActivity({});
              props.updateState({ leadSourceListDrawer: true });
            }}
          >
            <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
            <FormattedMessage id="button.create.label" defaultMessage="" />
          </Button>
        </div>
      </div>
      <div className="table-container" style={{ height: "calc(100% - 59px)" }}>
        <table id="leadSource-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th onClick={sortColumn}>
                Source Name <i className={ICONS.SORT}></i>
              </th>
              {/* <th onClick={sortColumn}>
                      Source Code <i className={ICONS.SORT}></i>
                    </th> */}
              <th onClick={sortColumn}>
                Date Created <i className={ICONS.SORT}></i>
              </th>

              <th onClick={sortColumn}>
                Status <i className={ICONS.SORT}></i>
              </th>
              <th width="6%">Action</th>
            </tr>
          </thead>

          <tbody>
            {(props.crmLeadSourceList || []).map((rowData, i) => {
              return (
                <tr key={"crm" + rowData.crmLeadSourceId}>
                  <td>{i + 1}</td>
                  <td>{rowData.leadSourceName || "-"}</td>
                  {/* <td>{e.source_code || "-"}</td> */}
                  <td>
                    {rowData.dateCreated
                      ? getMomentDateForUIReadOnly({
                          date: rowData.dateCreated,
                        })
                      : "-"}
                  </td>
                  <td>
                    <div className="status-toggle">
                      <Switch
                        checked={rowData.isActive === 1}
                        onClick={(checked, e) => {
                          // props.updateState({
                          //   isActive: checked ? 1 : 0,
                          // });
                          props.createLeadSource(
                            { ...rowData, isActive: checked ? 1 : 0 },
                            props
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td style={{ paddingLeft: "50px" }}>
                    <DropdownAction
                      overlay={actionMenu(rowData)}
                      trigger={["click"]}
                    >
                      <i className={ICONS.MORE} />
                    </DropdownAction>
                  </td>
                </tr>
              );
              // })
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default injectIntl(LeadSubSourceList);
