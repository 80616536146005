import React from 'react';
// import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
// import { Button, Row } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
// import * as find from 'lodash.find';
// import * as filter from 'lodash.filter';
// import { addDaysToDate } from '../../../../utils';
// import { Dropdown } from '../../../general/Dropdown';
//import { DropdownPriceList } from '../../../general/MarkupDropdown';
//import { CONSTANTS } from '../../../../static/constants';
import LoadingIcon from '../../../../assets/images/loading.gif';

const HeaderAction = (props) => {
    // const [templateDropdown, updateTemplateDropdown] = useState(false);
    // const {
    //     intl,
    //     suppliers,
    //     pageNumber,
    //     supplier,
    //     updateState,
    //     //priceListName,
    //     //priceList,
    //    // showModal,
    //    // companyInfo
    // } = props;

    // const handleNewPriceListAddition = (props, payload) => {
    //     const formData = payload.formData;
    //     formData.relationshipId = companyInfo.relationshipId;
    //     const data = {
    //         title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
    //         formData,
    //         hideFooter: true,
    //         modalData: {
    //             modalType: MODAL_TYPE.PRICE_LIST,
    //             data: payload,

    //         },
    //         handleSubmit: (formData = {}) => {
    //             data.formData.submittedOnce = true;
    //             showModal(data);
    //         }
    //     };
    //     showModal(data);
    // }

    // const deleteClickHandler = (props, modalType, payload) => {
    //     const modalBody =
    //         <FormattedMessage
    //             id='deleteItem.confirmation'
    //             defaultMessage={`A}
    //             values={{ value: payload.text }}
    //         />;

    //     let deleteFunc;

    //     switch (modalType) {
    //         case MODAL_TYPE.PRICE_LIST: {
    //             deleteFunc = props.deletePriceList;
    //             break;
    //         }
    //         default: {
    //             deleteFunc = () => { }
    //         }
    //     }
    //     const modalData = {
    //         modalBody,
    //         handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
    //     };
    //     showModal(modalData);
    // }

    return (
        <>
        <div className='left-itms'>
      <ul>
        <li className='title'>
        Goods Receipt Note
        </li>
        <li className='sep' />
        <li>
          <FormattedMessage id='supplier.purchaseinvoice.form.idNumber.label' defaultMessage='' /> &nbsp; - &nbsp;
          {props.nextTxNumber ?
            <span>{getFormatedNextTxNumber(props)}</span>
            :
          <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'margin-top': '-25px', 'margin-bottom': '-25px' }} />
         }
        </li>
      </ul>
     </div>
        <div className="right-itms">
            <Form.Item>
                {/* <DropdownPriceList
                    items={priceList}
                    valueKeyName='priceListName'
                    optionKeyName='priceListId'
                    value={priceListName}
                    canAddNew={true}
                    canDelete={true}
                    allowClear={true}
                    onClear={() => {
                        updateState({ priceListName: undefined })
                    }}
                    deleteClickHandler={(payload) => {
                        deleteClickHandler(props, MODAL_TYPE.PRICE_LIST, payload);
                    }}
                    onAddNewClick={(payload) => {
                        payload.formData = {
                            priceListName: payload.textEntered,
                            submittedOnce: false
                        }
                        handleNewPriceListAddition(props, payload, MODAL_TYPE.PRICE_LIST)
                    }}
                    placeholder={intl.formatMessage({
                        id: 'supplier.purchaseOrder.form.priceListDropdown.placeholder', defaultMessage: ''
                    })}
                    onSelect={
                        (priceListName) => {
                            updateState({ priceListName });
                        }
                    }
                /> */}

                {/* <FormattedMessage id='supplier.purchaseOrder.form.supplierName.label' defaultMessage='' /> */}
                {/* <Dropdown
                    items={suppliers[pageNumber] || []}
                    valueKeyName='supplierStoreName'
                    optionKeyName='supplierId'
                    value={(supplier || {}).id}
                    placeholder={intl.formatMessage({
                        id: 'supplier.purchaseOrder.form.supplierName.placeholder', defaultMessage: ''
                    })}
                    canAddNew={true}
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            boContactList: undefined,
                            selectedContact: undefined,
                            supplierAddresses: undefined,
                            supplier: undefined,
                            supplierPaymentTerm: undefined,
                            supplierBillingAddress: undefined,
                            poDueDate: undefined
                        })
                    }}
                    onSearch= {(searchedText)=>{
                        props.fetchSuppliers({
                            searchedText: searchedText,
                            pageNumber: 1,
                            hideSpinner: true,
                            pageSize: 100,
                            relationshipId: (props.companyInfo || {}).relationshipId
                        })
                    }}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                        props.deleteSuppliers({ groupSupplierIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                    }}
                    onAddNewClick={(payload) => {
                        props.updateHeaderState({
                            supplier: {
                                supplierDrawerVisible: true,
                                supplierName: payload.textEntered,
                            }
                        })
                    }}
                    onSelect={
                        (supplierId, optionObj) => {
                            const supplierObj = find(suppliers[pageNumber], { supplierId: Number(supplierId || 0) }) || {};
                            const supplierPaymentTerm = find(props.paymentTerms, { paymentTermId: supplierObj.paymentTermId }) || {};
                            const allSupplierBillingAddress = filter(supplierObj.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS });
                            const defaultBillingAddress = find(allSupplierBillingAddress, { isDefault: 1 });
                            const poDueDate = addDaysToDate((props.poDate || new Date()), supplierPaymentTerm.numberOfDays);
                            const supplierContactObj = (supplierObj.boContactList || []).find(x => x.isPrimaryContact) || {};
                            updateState({
                                boContactList: supplierObj.boContactList,
                                selectedContact: supplierContactObj,
                                supplierAddresses: (supplierObj.boLocationList || []).filter(x => x.locationType === CONSTANTS.TYPE_SHIPPING_ADDRESS) || [],
                                supplier: { id: supplierId, supplierId: supplierId, name: optionObj.props.name, gstNumber: supplierObj.gstNumber, panNumber: supplierObj.panNumber },
                                supplierPaymentTerm,
                                supplierBillingAddress: defaultBillingAddress,
                                poDueDate: poDueDate
                            });
                        }
                    }
                /> */}
                {/* <Button onClick={() => {
                    props.updateState({
                        ledgerDrawerVisible: true
                    })
                }} >
                    <i className='fa fa-book fa-lg'> </i>
                </Button> */}

                {/* <div className="currency-converter-modal">
                    <span className="ant-btn ant-btn-icon-only icon" onClick={() => updateTemplateDropdown(!templateDropdown)}>
                        <i className="fa fa-exchange" />
                    </span>

                    {templateDropdown &&
                        <div className="content">
                            <Row className="mb10">
                                <Link onClick={()=>{
                                    updateTemplateDropdown(false)
                                }}
                                to="/admin/po-create"
                                > <FormattedMessage id='common.standard.template' defaultMessage='' />  </Link>
                            </Row>

                            <Row>
                                <Link onClick={()=>{
                                    updateTemplateDropdown(false)
                                }}
                                to="/admin/po-create-multi-currency"
                                ><FormattedMessage id='common.Multi.currency.template' defaultMessage='' /> </Link>
                            </Row>
                        </div>
                    }
                </div> */}
                {/* <div className="space-lr">
                    <Button icon={<SwapOutlined />} />
                </div> */}
            </Form.Item>
        </div>
        </>
    );
};
const getFormatedNextTxNumber = (props) => {
    // const { supplier } = props;
    // if (!supplier || !supplier.supplierId) {
    //     return <span>{props.nextTxNumber}</span>;
    // }
    // return <span>{props.nextTxNumber.replace('ID-00', 'ID-' + (supplier.supplierId < 10 ? ('0' + supplier.supplierId) : supplier.supplierId))}</span>
    return <span>{props.nextTxNumber}</span>;
}

export default injectIntl(HeaderAction);
