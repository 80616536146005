import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PageBreadcrumb from "../../../PageBreadcrumb";
import { Row, Col, Form, Input, DatePicker, Checkbox, } from 'antd';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { CONSTANTS, MODAL_TYPE } from '../../../../static/constants';
// import { CustomButton } from '../../../general/CustomButton';
import { validateEmail, getCurrentDateForBackend, showToasterMessage } from '../../../../utils';
//import * as find from 'lodash.find';
import moment from 'moment-timezone';
import { ErrorMsg } from '../../../general/ErrorMessage';
// import { LogoComponent } from '../../../general/LogoComponent';
import TicketS from '../../../../assets/images/tickets.png';
import * as find from 'lodash.find';

const { TextArea } = Input;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const SponsorshipCreateComponent = (props) => {
  const breadCrumbList = [

    {
      name: <FormattedMessage id='fundRaising.text' defaultMessage=' ' />,
    },
    {
      name: <FormattedMessage id='sponsorship.text' defaultMessage=' ' />,
    }
  ];

  const handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,

      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }

  return (
    <div className="new-invoice-modal">
      <div className="invoice-header">
        <PageBreadcrumb breadCrumbList={breadCrumbList} />
      </div>

      <div> <Form layout="vertical" hideRequiredMark>
        <div className='white-box-sprter'>
          <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px', marginBottom: '20px' }}>
            <h2> <b> Sponsorship Information</b></h2>
          </div>


          <Row gutter={16}>

            <Col className="text-right" span={8}>
              <span className="">
                <FormattedMessage id='sponsorship.listing.name' defaultMessage='' />
              </span>
              <span className="required">*</span>
            </Col>
            <Col span={12}>

              <TextBox
                type='text'
                className={(props.submittedOnce && !props.name) ? 'input-text-error' : ''}
                value={props.name}
                onChange={(e) => {
                  props.updateState({
                    name: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'sponsorship.listing.name', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.name }}
                message={props.intl.formatMessage(
                  {
                    id: 'contact.firstName.message',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <br />
          <Row gutter={16}>

            <Col className="text-right" span={8}>
              <span className="">
                <FormattedMessage id='finance.JournalDetail.description' defaultMessage='' />
              </span>
              {/* <span className="required">*</span> */}
            </Col>
            <Col span={12}>

              <TextArea
                type='text'
                style={{ width: '100%' }}
                // className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                value={props.descriptionMaster}
                onChange={(e) => {
                  props.updateState({
                    descriptionMaster: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'finance.JournalDetail.description', defaultMessage: '' })}
              />
            </Col>
          </Row>
          <br />

          {/* <Row gutter={16}>
            <Col className="text-right" span={8}>
              <span className="">
                <FormattedMessage id='sponsorship.listing.host' defaultMessage='' />
              </span>
            </Col>
            <Col span={12}>

              <TextBox
                type='text'
                className={(props.submittedOnce && !props.host) ? 'input-text-error' : ''}
                value={props.host}
                onChange={(e) => {
                  props.updateState({
                    host: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'sponsorship.listing.host', defaultMessage: '' })}
              />
            </Col>
          </Row>
          <br /> */}

          <Row gutter={16}>

            <Col className="text-right" span={8}>
              <label>
                <FormattedMessage id='sponsorship.listing.location' /></label>
              {/* <span className="required">*</span> */}
            </Col>
            <Col span={12}>
              <Dropdown
                className={(props.submittedOnce && !props.locationId) ? 'input-text-error' : ''}
                items={props.companyInfo.boLocationList || []}
                valueKeyName='streetAddress1'
                addressLineKey1='streetAddress2'
                addressLineKey2='cityName'
                addressLineKey3='stateName'
                addressLineKey4='zipCode'
                optionClassAddress='custom-address-dropdown'
                optionKeyName='locationId'
                value={props.locationId}
                isFavouriteKeyName='isDefault'
                placeholder={props.intl.formatMessage(
                  {
                    id: 'sponsorship.listing.location',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      locationId: option.value
                    })
                  }
                }
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || props.campaignId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>

          </Row>
          <br />

          <Row gutter={16}>

            <Col span={8} className="text-right">
              <FormattedMessage id='startDateAndTime.text' defaultMessage='' />
            </Col>
            <Col span={6}>
              <DatePicker
                allowClear={false}
                format={format}
                // style={{ width: 250 }}
                // disabledDate={invalidEndDate}
                key={'saleStartDate'}
                defaultValue={props.saleStartDate ? moment(new Date(props.saleStartDate), format) : null}
                onChange={(selectedDate) => {
                  props.updateState({
                    saleStartDate: selectedDate
                  })
                }} />
            </Col>
            <Col span={6}>
              <Checkbox checked={(props.isDisplayStartDate === 1)} onChange={(e) => {
                props.updateState({
                  isDisplayStartDate: e.target.checked ? 1 : 0,
                })
              }} /> <FormattedMessage id='display.startDateAndTime.text' defaultMessage='' />
            </Col>
            {/* <Col span={6}>
              <TimePicker use12Hours defaultValue={moment(props.eventTime || '7:00 am', 'h:mm a')} format="h:mm a" onChange={(selectedTime, timeString) => {

              }} /> 
            </Col>*/}
          </Row>
          <br />
          <Row gutter={16}>

            <Col span={8} className="text-right">
              <FormattedMessage id='endDateAndTime.text' defaultMessage='' />
            </Col>
            <Col span={6}>
              <DatePicker
                allowClear={false}
                format={format}
                // style={{ width: 250 }}
                // disabledDate={invalidEndDate}
                key={'endDateAndTime'}
                defaultValue={props.saleEndDate ? moment(new Date(props.saleEndDate), format) : null}
                onChange={(selectedDate) => {
                  props.updateState({
                    saleEndDate: selectedDate
                  })
                }} />
            </Col>
            <Col span={6}>
              <Checkbox checked={(props.isDisplayEndDate === 1)} onChange={(e) => {
                props.updateState({
                  isDisplayEndDate: e.target.checked ? 1 : 0,
                })
              }} /> <FormattedMessage id='display.endDateAndTime.text' defaultMessage='' />
            </Col>
          </Row>

          <br />
          <br />
          <Row gutter={16}>
            <Col className="text-right" span={8}>
              <FormattedMessage id='project.label' /><span className="required">*</span>
            </Col>
            <Col span={12} >
              <Dropdown
                items={props.projectList}
                valueKeyName='projectName'
                optionKeyName='projectMasterId'
                className={(props.submittedOnce && !props.projectMasterId) ? 'input-text-error' : ''}
                value={props.projectMasterId}
                placeholder={props.intl.formatMessage({ id: 'project.label' })}
                onSelect={(optionValue, option) => {
                  let selectedProject = find(props.projectList || [], { projectMasterId: option.value }) || {};
                  props.updateState({
                    selectedProject: selectedProject,
                    projectMasterId: option.value
                  })
                }}
                canAddNew={true}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    projectName: payload.projectName,
                  }
                  handleNewProjectAddition(props, payload, MODAL_TYPE.PROJECT)
                }}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.projectMasterId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col className="text-right" span={8}>
              <FormattedMessage id='header.menuItem.ledgerAccount.label' /><span className="required">*</span>
            </Col>
            <Col span={12} >
              <Dropdown
                items={props.allLedgerAccounts}
                className={(props.submittedOnce && !props.chartOfAccountDetailsId) ? 'input-text-error' : ''}
                valueKeyName='ledgerAccountName'
                optionKeyName='chartOfAccountDetailsId'
                value={props.chartOfAccountDetailsId}
                placeholder={props.intl.formatMessage({ id: 'header.menuItem.ledgerAccount.label' })}
                onSelect={(optionValue, option) => {
                  let selectedLedger = find(props.allLedgerAccounts || [], { chartOfAccountDetailsId: option.value }) || {};
                  props.updateState({
                    selectedLedger: selectedLedger,
                    chartOfAccountDetailsId: option.value
                  });
                }}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.chartOfAccountDetailsId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col className="text-right" span={8}>
              <FormattedMessage id='paymentGateway.available' /><span className="required">*</span>
            </Col>
            <Col span={12} >
              <Dropdown
                items={props.availablePaymentGateways}
                className={(props.submittedOnce && !props.alpidePaymentGatewayId) ? 'input-text-error' : ''}
                valueKeyName='userDefinedGatewayName'
                optionKeyName='alpidePaymentGatewayId'
                value={props.alpidePaymentGatewayId}
                placeholder={props.intl.formatMessage({ id: 'paymentGateway.available' })}
                onSelect={(optionValue, option) => {
                  let selectedGateway = find(props.availablePaymentGateways || [], { alpidePaymentGatewayId: option.value }) || {};
                  props.updateState({
                    selectedGateway: selectedGateway,
                    alpidePaymentGatewayId: option.value
                  });
                }}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.alpidePaymentGatewayId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>

        </div>
        <div>
        {(!props.ticketList  || !props.ticketList.length) ?
          <div className='white-box-sprter'>
            <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px', marginBottom: '20px' }}>
              <img src={TicketS} alt='' />
              <h2> <b> Lets create tickets</b></h2>
              <div style={{ padding: '20px',  }}>Create a group if you want to sell multiple ticket types that share<br />
                the same features. E.g. members and non members, premium
                and regular etc </div>
              <button style={{cursor:'pointer'}} className='ant-btn ant-btn-default p5' onClick={() => {
                props.updateState({
                  addTicketDrawerDrawerVisible: true,
                  ticketEditIndex: -1
                })
              }}>Add Ticket</button>
            </div>
          </div> : ''
}
          {(props.ticketList && props.ticketList.length > 0) ?
          <div className='white-box-sprter'>
              <div className='new-table-camp' style={{ textAlign: 'center' }}>
                <table style={{ width: '60%', marginLeft: '300px' }}>
                  <tbody>
                    <tr>
                      <th><FormattedMessage id="serial.no" defaultMessage="" /></th>
                      <th><FormattedMessage id="ticketName.text" defaultMessage="" /></th>
                      <th> <FormattedMessage id='ticketDescription.text' defaultMessage='' /></th>
                      <th><FormattedMessage id='ticketQtyAvailable.text' defaultMessage='' /></th>
                      <th><FormattedMessage id="ticketPrice.text" defaultMessage="" /></th>
                      <th width={'10%'}> <FormattedMessage id="common.listing.header.action" defaultMessage="" /> </th>
                    </tr>
                    {(props.ticketList || []).map((e, i) => {
                      return <tr key={i}>
                        <td>{i + 1}</td>
                        <td> {e.ticketName || ''} </td>
                        <td>{e.description || ''}</td>
                        <td>{e.qtyMax || ''}</td>
                        <td> {e.price || 0}</td>
                        <td>
                          <div>
                            <span style={{ marginLeft: '5px' }} className="cursor-pointer">
                              <i className='fa fa-pencil' style={{ marginRight: '10px' }} onClick={() => {
                                props.updateState({
                                  ...e,
                                  addTicketDrawerDrawerVisible: true,
                                  ticketEditIndex : i,
                                })
                              }} />
                              <i className='fa fa-trash' title={props.intl.formatMessage({ id: 'commons.listing.delete.tooltip.msg' })} onClick={
                                () => {
                                  let ticketList = props.ticketList;
                                  ticketList.splice(i, 1);
                                  props.updateState({
                                    ticketList
                                  })
                                }} />
                            </span>
                          </div>
                        </td>
                      </tr>
                    })}
                    <tr>
                      <td></td>
                    </tr>
                   
                  </tbody>
                </table>
              </div>
<div style={{width:'60%', margin:'0px 0px 0px 300px'}}>
              <button style={{cursor:'pointer', }} className='ant-btn ant-btn-default p5' onClick={() => {
                props.updateState({
                  addTicketDrawerDrawerVisible: true,
                  ticketEditIndex: -1
                })
              }}>Add Ticket</button>
              </div>
           
          </div> : ''
           }
        </div>


        <div className='white-box-sprter'>
          <Row>
            <Col span={11}></Col>
            <Col span={2}><button className='ant-btn ant-btn-default' onClick={() => {
              props.history.push('/admin/sponsorship');
            }}>Cancel</button></Col>
            <Col span={2}> <button className='ant-btn ant-btn-primary' onClick={() => {
              props.updateState({
                submittedOnce: true,
                invalidEmailClass: !validateEmail(props.emailAddress) ? 'input-text-error' : '',

              });

              if (!props.name || !props.projectMasterId || !props.chartOfAccountDetailsId || !props.alpidePaymentGatewayId) {
                return showToasterMessage({
                  messageType: 'error', description: props.intl.formatMessage({ id: 'common.missing.requiredField.message' })
                });
              }
              if (!props.ticketList  || !props.ticketList.length) {
                return showToasterMessage({
                  messageType: 'error', description: props.intl.formatMessage({ id: 'ticketNotAdded.errorMessage' })
                });;
              }
              // if (props.phoneNumber && props.phoneNumber.length > 0 && props.phoneNumber.length < 10) {
              //   return showToasterMessage({
              //     messageType: 'error', description: props.intl.formatMessage({ id: 'phoneNumber.incorrect.message' })
              //   });;
              // }

              const modalData = {
                title: <FormattedMessage id='signup.confirmation.title' defaultMessage='' />,
                modalBody: <FormattedMessage id='save.confirmation' defaultMessage='' />,
                handleSubmit: () => {
                  let payload = {
                    giveSponsorshipMasterId: props.giveSponsorshipMasterId,
                    relationshipId: props.relationshipId,
                    name: props.name,
                   // host: props.host,
                    description: props.descriptionMaster,
                    locationId: props.locationId,
                    saleStartDate: getCurrentDateForBackend(props.saleStartDate || new Date()),
                    saleEndDate: getCurrentDateForBackend(props.saleEndDate || new Date()),
                    isDisplayStartDate: props.isDisplayStartDate,
                    isDisplayEndDate: props.isDisplayEndDate,
                    ticketItemDetailsList: props.ticketList || [],
                    projectMasterId: props.projectMasterId,
                    chartOfAccountDetailsId: props.chartOfAccountDetailsId,
                    alpidePaymentGatewayId: props.alpidePaymentGatewayId,
                  }
    
                  props.saveOrUpdateSponsorship(payload, props)
                }
              };
              props.showModal(modalData);


              
            }}>Submit</button></Col>
          </Row>

        </div>
      </Form>
      </div>

    </div>
  );
}


export default injectIntl(SponsorshipCreateComponent);
