import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Progress } from 'antd';
import { injectIntl } from 'react-intl';
import { CONSTANTS } from '../../../static/constants';
//import * as find from 'lodash.find';
import config from '../../../config/environmentConfig';
import { LogoComponent } from '../../general/LogoComponent';
import DonatePic from '../../../assets/images/donation-pic.png';
import ReceivedIcon from '../../../assets/images/received-icon.png';
import TotalProject from '../../../assets/images/total-project.png';
import PledgeReceived from '../../../assets/images/pledge-received.png';
import BalanceIcons from '../../../assets/images/balance-icons.png';
import PeopleJustpledgedIcon from '../../../assets/images/people-justpledged-icon.png';

// const carouselSettings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     autoplay: true,
//     effect: 'fade',
//     ssr: true
// };

// const getAddressInfoFragment = (obj, additionInfo) => {

//     const fragmentObj = <Fragment>
//         {
//             obj.streetAddress1
//                 ? <span className='billing-address'>{obj.streetAddress1}
//                 </span>
//                 : ''
//         }
//         {
//             obj.streetAddress2
//                 ? <span className='billing-address'> {obj.streetAddress2}
//                 </span>
//                 : ''
//         }
//         {
//             obj.cityName
//                 ? <div className='billing-address'>
//                     {
//                         `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
//                     }
//                 </div>
//                 : ''
//         }
//         {
//             obj.countryName
//                 ? <div className='billing-address'>
//                     {
//                         `${obj.countryName || ''}`
//                     }
//                 </div>
//                 : ''
//         }
//         {
//             additionInfo.workPhone
//                 ? <div className='billing-address'>
//                     <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
//                 </div> :
//                 ''
//         }
//         {
//             additionInfo.email
//                 ? <div className='billing-address'>
//                     <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
//                 </div> :
//                 ''
//         }
//     </Fragment>
//     return fragmentObj;
// }


function StreamingComponent(props) {
    const {selectedCampaign, isShowTimer} = props;

    return (
        <div className='rem-bgs' style={{fontSize:'18px'}} >
         <div> 
              <Row gutter={24} style={{ color: '#000', padding: '5px 5px 10px 5px', marginLeft: '0px', marginRight: '0px', background:'#fff', marginBottom: '10px',borderBottom:'solid 1px #e4e4e4' }} className='ltiuy'>
                 
                <Col span={4}>
                    <div className="brand-logo" style={{ marginTop: '10px' }}>
                        {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                    </div>
                </Col> 
                <Col span={16} style={{ textAlign: 'center', color:'#7aa355' }}>
                    <div className='logosizef' style={{color:'#001e57', fontSize:'40px', paddingTop:'20px', fontWeight:'900'}}> {selectedCampaign.campaignName} </div>
                </Col>
                <Col span={4} style={{ textAlign: 'center' ,fontSize:'24px', paddingTop:'28px', fontWeight:'500'}} className='newaddr'>
                    {/* {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)} */}
                    {props.companyInfo.storeName}
                </Col> 
            </Row>  
           
             <div className="rais-prog" style={{ extAlign:'center' }}>
                            <br />
                            {/* <span style={{ fontSize: '30px' }}> $<b>{Math.round(props.selectedCampaign.goal)}</b></span> <span style={{}}> raised of ${Math.round(props.selectedCampaign.totalReceived)} Goal</span> */}
                            
                            <Row>
                                <Col span={6}></Col>
                                <Col span={6} className="greenbar"> 
                                {/* <Progress  type="circle"
                                percent={(props.selectedCampaign.totalReceived / props.selectedCampaign.goal) * 100}
                                showInfo={false}
                            /> */}
                            <Progress type="circle" percent={((props.selectedCampaign.totalReceived / props.selectedCampaign.goal) * 100).toFixed(0)} />
                          <br/> 
                        
                          <span style={{position:'relative', fontSize:'24px', top:'15px', color:'#538234'}}><span style={{fontSize:'42px', fontWeight:'600'}}>${(selectedCampaign.totalReceived || 0).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> Received</span>
                            </Col>
                                <Col span={6} className="bluebar">
                                    {/* <Progress  type="circle"
                                percent={((props.selectedCampaign.totalReceived + (props.selectedCampaign.pledgeMade - props.selectedCampaign.totalPledgeReceived)) / props.selectedCampaign.goal) * 100}
                                showInfo={false}
                            /> */}
                            <Progress type="circle" percent={((props.selectedCampaign.pledgeMade / props.selectedCampaign.goal) * 100).toFixed(0)} />
                            <br/>

                            <span style={{position:'relative', fontSize:'24px', top:'15px', color:'#001e57'}}><span style={{fontSize:'42px', fontWeight:'600'}}>${(props.selectedCampaign.pledgeMade || 0).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> Pledged</span>
                            </Col>
                                <Col span={6}></Col>
                            </Row>
                             
                            

                        </div>
    
    <div className="clearfix"></div>

{/* <Row>
    <Col span={24} style={{ padding: '30px 0px 30px 0px', textAlign: 'center' }}>
        <Row >
        <Col span={6}></Col>
            <Col span={6}>
                <div className="cirt">
                    <span style={{ color: '#128371', fontSize: '44px' }}> 15+</span>
                    <br />
                    Total Donors
                </div>
            </Col>
            <Col span={6}>
                <div className="cirt">
                    <span style={{ color: '#128371', fontSize: '44px' }}> 20+</span>
                    <br />
                    Total Pledgees
                </div>
            </Col>

            <Col span={6}></Col>
        </Row>


    </Col>

    </Row> */}
          
  
               

            
           
            <div style={{ clear: 'both' }}></div>
            
            </div>
            <div className='time-but '>
                {isShowTimer ? <div className='timer-box'>
                    <h3>Fundraising ends in</h3>
                    <div className='timer-box1'>

                        <div className='timers'>{props.timer.month} <span>{props.timer.month > 1 ? 'Months' :'Month'}</span></div>
                        <div className='timers'>{props.timer.days} <span>{props.timer.days > 1 ? 'Days' :'Day'}</span></div>
                        <div className='timers'>{props.timer.hours} <span>{props.timer.hours > 1 ? 'Hours' :'Hour'}</span></div>
                        <div className='timers'>{props.timer.minutes} <span>{props.timer.minutes > 1 ? 'Mins' :'Min'}</span></div>
                        <div className='timers'>{props.timer.seconds} <span>{props.timer.seconds > 1 ? 'Secs' :'Sec'}</span></div>
                    </div>
                </div>
                    :
                    <div className='timer-box '>
                        <div className=''>
                            <h3>Fundraising Goal</h3>
                            <div className='timer-box1'>
                                <div className='timers'>${(props.selectedCampaign.goal || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
           <div style={{ position:'fixed', bottom:'0px', borderTop:'solid 1px #757575', background:'#fff', height:'90px', width:'100%'}}>
           {/* <div className='timer-box'> 
           <h3>Countdown</h3>
           <div className='timer-box1'>
               <div className='timers'>01 <span>Day</span></div>
               <div className='timers'>01 <span>Hour </span></div>
               <div className='timers'>02 <span>Min  </span></div>
               <div className='timers'>52 <span>Sec   </span></div>
               </div>
           </div> */}
                {/* <Carousel {...carouselSettings} > */}
                {isShowTimer ?
                    <div className='cunc'>
                        <div className='cunca'>
                            <Row style={{ textAlign: 'conter' }}>

                                <Col span={8} className="org-bg">
                                    <div className='whit-cire'>
                                        <img src={DonatePic} alt="true" />
                                    </div>
                                    <span style={{ position: 'relative', top: '12px' }}> {props.selectedCampaign.totalDonors || 0} People Donated </span>


                                </Col>
                                <Col span={8} className="gree-bg">
                                    <div className='whit-cire'><img src={ReceivedIcon} alt="true" /></div>
                                    <span style={{ position: 'relative', top: '12px' }}> ${(selectedCampaign.totalReceived || 0).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Total Received  </span>


                                </Col>
                                <Col span={8} className="blue-bg">
                                    <div className='whit-cire'><img src={TotalProject} alt="true" /></div>
                                    <span style={{ position: 'relative', top: '12px' }}> ${((selectedCampaign.totalReceived + (selectedCampaign.pledgeMade - selectedCampaign.totalPledgeReceived)) || 0).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Projected Total </span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    :
                    <div className='cunc'>
                        <div className='cunca'>
                            <Row style={{ textAlign: 'conter' }}>

                                <Col span={8} className="gree-bg">
                                    <div className='whit-cire'>
                                        <img src={PeopleJustpledgedIcon} alt="true" />
                                    </div>
                                    <span style={{ position: 'relative', top: '12px' }}> {props.selectedCampaign.pledgeCount || 0} People Pledged</span>


                                </Col>
                                <Col span={8} className="blue-bg">
                                    <div className='whit-cire'><img src={PledgeReceived} alt="true" /></div>
                                    <span style={{ position: 'relative', top: '12px' }}> ${(selectedCampaign.totalPledgeReceived || 0).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Pledge Received</span>


                                </Col>
                                <Col span={8} className=" org-bg">
                                    <div className='whit-cire'><img src={BalanceIcons} alt="true" /></div>
                                    <span style={{ position: 'relative', top: '12px' }}>${((selectedCampaign.pledgeMade - selectedCampaign.totalPledgeReceived) || 0).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Outstanding Pledge </span>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                    {/* <div className='cunc'>
             <div className='cunca'>
             <div style={{ margin: '0px 25% 20px' }}>
                                                 <div style={{ padding: '10px 0px', textAlign: 'center', fontSize: '34px', fontWeight:'400', color:'#000' }}>  Pledge Details  </div>
                                                 <Row className="drtys" style={{textAlign: 'left'}}>
                                                     <Col span={4}></Col>
                                                     <Col span={10}><span style={{ float: 'left',  height: '35px', width: '35px',  textAlign: 'left', lineHeight: '35px', color: '#000', margin: '5px 5px 5px 0px' }}>  <i className="fa fa-heart"></i></span><span style={{ padding: '5px 0px 0px 5px', display: 'block' }}> Pledge Made </span></Col>
                                                     <Col span={8} style={{ paddingTop: '10px' }}> <i className="fa fa-usd"></i>{props.selectedCampaign.pledgeMade || 0}</Col>
                                                 </Row>
         
                                                 <Row className="drtys" style={{textAlign: 'left'}}>
                                                 <Col span={4}></Col>
                                                     <Col span={10}> <span style={{ float: 'left',  height: '35px', width: '35px',  textAlign: 'left', lineHeight: '35px', color: '#000', margin: '5px 5px 5px 0px' }}> <i className="fa fa-money"></i></span> <span style={{ padding: '5px 0px 0px 5px', display: 'block' }}>Pledge Received </span></Col>
                                                     <Col span={8} style={{ paddingTop: '10px' }}> <i className="fa fa-usd"></i>{props.selectedCampaign.totalPledgeReceived || 0}</Col>
                                                 </Row>
         
                                                 <Row className="drtys" style={{textAlign: 'left'}}>
                                                 <Col span={4}></Col>
                                                     <Col span={10}><span style={{ float: 'left',  height: '35px', width: '35px',  textAlign: 'center', lineHeight: '35px', color: '#000', margin: '5px 5px 5px 0px' }}>  <i className="fa fa-circle-o-notch"></i></span> <span style={{ padding: '5px 0px 0px 5px', display: 'block' }}>Outstanding Pledge</span></Col>
                                                     <Col span={8} style={{ paddingTop: '10px' }}> <i className="fa fa-usd"></i>{(props.selectedCampaign.pledgeMade - props.selectedCampaign.totalPledgeReceived) || 0}</Col>
                                                 </Row>
                                             </div>
             </div >
             </div>
             */}
                {/* </Carousel> */}
            </div>
        </div>
    );

}

export default injectIntl(StreamingComponent);
