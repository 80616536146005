import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LandedCostPageListingComponent from '../../../components/supplier/landedCost';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../actions/commonActions';
import  { fetchLandedCostList, deleteLandedCostItems } from './action'; 


class LandedCostPageListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25
    };

    this.props.fetchLandedCostList(payload);
  }

  componentWillReceiveProps(props) {


  }

  render() {
    return (
      <LandedCostPageListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    permissions: state.auth.permissions,
    landedCostList: state.supplier.landedCostList,
    listLoading:state.common.listLoading,
    headerLoading:state.common.headerLoading

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  pushModalToStack,
  hideModal,
  popModalFromStack,
  fetchLandedCostList,
  deleteLandedCostItems,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LandedCostPageListing);
