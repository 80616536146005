import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, Slider } from 'antd';
import 'material-ui-rc-color-picker/assets/index.css';
// import { Dropdown } from '../../general/Dropdown';
// import * as find from 'lodash.find';
// import config from '../../../config/environmentConfig';
import ColorPicker from 'material-ui-rc-color-picker';

function ImageProps(props) {
    let barRef = document.getElementById('progress_bar_' + props.childId) || {};
    let barfillRef = document.getElementById('progress_bar_fill_' + props.childId) || {};
    let barUnfillRef = document.getElementById('progress_bar_unfill_' + props.childId) || {};

    let barInfo = document.getElementById('bar_info_' + props.childId) || {};
    const [barWidth, updateWidth] = useState((barRef.offsetWidth * 100)/750);
    const [barHeigth, updateHeigth] = useState(barRef.offsetHeight);
    // const [buttonWidth, updateWidth] = useState(buttonRef.offsetWidth);
   // const [buttonHref, updateHref] = useState(buttonRef.href);

    return (
        <Fragment>
            <div>

                <Row className="but-buttons">
                    <Col span={5}> <FormattedMessage id='package.packageDetail.width' /></Col>
                    <Col span={19}>
                        {/* <Slider defaultValue={15} tooltipVisible /> */}
                        <Slider min={1} value={barWidth} max={95} onChange={(value) => {
                            updateWidth(value);
                            barRef.style.float = 'left';
                            barRef.style.width = value + "%";
                            barInfo.style.width = value + "%";
                        }} />
                    </Col>
                </Row>


                <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='buttonHeight.height' /></Col>
                    <Col span={19}>

                        {/* <Slider defaultValue={15} tooltipVisible /> */}
                        <Slider value={barHeigth} min={10} max={50} onChange={(value) => {
                            barRef.style.lineHeight = value + "px";
                            barRef.style.height = value + "px";
                            
                            barfillRef.style.height = value + "px";
                            barUnfillRef.style.height = value + "px";
                            updateHeigth(value);
                        }} />
                    </Col>


                </Row>
                {/* <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='buttonCorner.text' />
                    </Col>
                    <Col span={24} >
                        <Button title='Square' onClick={() => {
                            barRef.style.borderRadius = "0px";
                        }} style={{ borderRadius: '4px 0px 0px 4px', position: 'relative' }}>
                            <div className="left-roud"></div>
                        </Button>
                        <Button title='Round' onClick={() => {
                            barRef.style.borderRadius = "4px";
                        }} style={{ borderRadius: '0px', position: 'relative' }}>
                            <div className="mr-roud"></div>
                        </Button>
                        <Button title='Extra Round' onClick={() => {

                            barRef.style.borderRadius = "20px"
                        }} style={{ borderRadius: '0px 4px 4px 0px', position: 'relative' }}>
                            <div className="right-roud"></div>
                        </Button>
                    </Col>
                </Row>
                <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='buttonCorners.text' /></Col>
                    <Col span={19}>
                        <Slider defaultValue={10} min={0} max={50} onChange={(value) => {
                            barRef.style.borderRadius = value + "%"
                        }} />
                    </Col>
                </Row> */}

                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='imgPosition.text' />
                    </Col>
                    <Col span={24} >
                        <Button title='Left' onClick={() => {
                            barRef.style.marginLeft = "10px";
                            barRef.style.float = 'left';
                            barInfo.style.marginLeft = "10px";
                            barInfo.style.float = 'left';
                        }} style={{ borderRadius: '4px 0px 0px 4px', position: 'relative' }}>

                            <div className="leftd-roud1"></div>
                        </Button>
                        <Button title='Middle' onClick={() => {
                            barRef.style.float = 'left';
                            barRef.style.marginLeft = ((95-barWidth)/2)+"%";
                            barInfo.style.float = 'left';
                            barInfo.style.marginLeft = ((95-barWidth)/2)+"%";
                            
                        }} style={{ borderRadius: ' 0px ', position: 'relative' }} >
                            <div className="mrd-roud1"></div>
                        </Button>
                        <Button title='Right' onClick={() => {
                            barRef.style.marginLeft = "10px";
                            barRef.style.float = 'right';
                            barInfo.style.marginLeft = "10px";
                            barInfo.style.float = 'right';
                        }} style={{ borderRadius: '0px 4px 4px 0px', position: 'relative' }} >
                            <div className="rigt-roud1"></div>
                        </Button>
                    </Col>
                </Row>

                <Row className="but-buttons">
                    <Col span={8}><FormattedMessage id='imgPosition.text' /></Col>
                    <Col span={16}>
                        <Slider min={1} max={95} onChange={(value) => {
                            barRef.style.marginLeft = (barWidth + value) > 95 ? (95 - barWidth) + "%" : value + "%";
                            barInfo.style.marginLeft = (barWidth + value) > 95 ? (95 - barWidth) + "%" : value + "%";
                        }} />
                    </Col>
                </Row>
                {/* <Row className="but-buttons">
                    <Col span={8} style={{ paddingTop: '4px' }}>
                        <FormattedMessage id='fundraising.pledge.listing.header.campaign' defaultMessage='' /><span className="required">*</span>
                    </Col>
                    <Col span={16} >
                        <Dropdown
                            items={props.allCampaignList}
                            valueKeyName='campaignName'
                            optionKeyName='fundraisingCampaignId'
                            value={props.campaignId}
                            placeholder={props.intl.formatMessage({ id: 'fundraising.pledge.listing.header.campaign' })}
                            onSelect={(optionValue, option) => {
                                let selectedCampaign = find(props.allCampaignList || [], { fundraisingCampaignId: option.value }) || {};
                                props.updateState({
                                    selectedCampaign: selectedCampaign,
                                    campaignId: option.value,
                                    alpidePaymentGatewayId: selectedCampaign.alpidePaymentGatewayId,
                                    projectMasterId: selectedCampaign.projectMasterId,
                                    chartOfAccountDetailsId: selectedCampaign.chartOfAccountDetailsId,
                                })
                                    let amtRef = document.getElementById('amount_' + props.childId) || {};
                                    amtRef.innerHTML = "<b>$" + selectedCampaign.goal + "</b>";
                                    let percentRef = document.getElementById('amount_percent_' + props.childId) || {};
                                    percentRef.innerHTML = "<b>" + Number((selectedCampaign.totalReceived *100)/selectedCampaign.goal).toFixed(1)  + "%</b>";
                                    barRef.value = Number((selectedCampaign.totalReceived *100)/selectedCampaign.goal).toFixed(1);
                                    document.getElementById("button_child_4").href = `${window.location.origin}/app/payment-setup?t=${props.companyInfo.relationshipId}_${selectedCampaign.alpidePaymentGatewayId || 0}_4_${selectedCampaign.projectMasterId || 0}_${selectedCampaign.chartOfAccountDetailsId || 0}`;
                                }}
                        />
                    </Col>
                </Row> */}

                {/* <Row className="but-buttons">
                    <Col span={8} style={{ paddingTop: '4px' }}>
                        <FormattedMessage id='project.label' /><span className="required">*</span>
                    </Col>
                    <Col span={16} >
                        <Dropdown
                            items={props.projectList}
                            valueKeyName='projectName'
                            optionKeyName='projectMasterId'
                            value={props.projectMasterId}
                            placeholder={props.intl.formatMessage({ id: 'project.label' })}
                            onSelect={(optionValue, option) => {
                                let selectedProject = find(props.projectList || [], { projectMasterId: option.value }) || {};
                                    props.updateState({
                                        selectedProject: selectedProject,
                                        projectMasterId: option.value
                                    })
                                    document.getElementById("button_child_4").href = `${config.UI_BASE_URL}payment-setup?t=${props.companyInfo.relationshipId}_${props.alpidePaymentGatewayId || 0}_4_${props.projectMasterId || 0}_${props.chartOfAccountDetailsId || 0}`;
                                  }}
                        />
                    </Col>
                </Row>

                <Row className="but-buttons">
                    <Col span={8} style={{ paddingTop: '4px' }}>
                        <FormattedMessage id='header.menuItem.ledgerAccount.label' /><span className="required">*</span>
                    </Col>
                    <Col span={16} >
                        <Dropdown
                            items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={props.chartOfAccountDetailsId}
                            placeholder={props.intl.formatMessage({ id: 'header.menuItem.ledgerAccount.label' })}
                            onSelect={(optionValue, option) => {
                                let selectedLedger = find(props.allLedgerAccounts || [], { chartOfAccountDetailsId: option.value }) || {};
                                    props.updateState({
                                        selectedLedger: selectedLedger,
                                        chartOfAccountDetailsId: option.value
                                    });
                                    document.getElementById("button_child_4").href = `${config.UI_BASE_URL}payment-setup?t=${props.companyInfo.relationshipId}_${props.alpidePaymentGatewayId || 0}_4_${props.projectMasterId || 0}_${props.chartOfAccountDetailsId || 0}`;
                                  }}
                        />
                    </Col>
                </Row>

                <Row className="but-buttons">
                    <Col span={8} style={{ paddingTop: '4px' }}>
                        <FormattedMessage id='paymentGateway.available' /><span className="required">*</span>
                    </Col>
                    <Col span={16} >
                        <Dropdown
                            items={props.availablePaymentGateways}
                            valueKeyName='userDefinedGatewayName'
                            optionKeyName='alpidePaymentGatewayId'
                            value={props.alpidePaymentGatewayId}
                            placeholder={props.intl.formatMessage({ id: 'paymentGateway.available' })}
                            onSelect={(optionValue, option) => {
                                let selectedGateway = find(props.availablePaymentGateways || [], { alpidePaymentGatewayId: option.value }) || {};
                                props.updateState({
                                    selectedGateway: selectedGateway,
                                    alpidePaymentGatewayId: option.value
                                });
                                document.getElementById("button_child_4").href = `${config.UI_BASE_URL}payment-setup?t=${props.companyInfo.relationshipId}_${option.value || 0}_4_${props.projectMasterId || 0}_${props.chartOfAccountDetailsId || 0}`;
                            }}
                        />
                    </Col>
                </Row> */}

                <Row className="but-buttons">
                    <Col span={7} style={{ paddingTop: '2px', paddingRight: '4px' }}>
                        <FormattedMessage id='buttonBackgraound.text' />
                    </Col>
                    <Col span={17} >
                        <ColorPicker
                         placement='bottomLeft'
                            enableAlpha={false}
                            color={barUnfillRef.style.background || '#cccccc'}
                            onChange={(obj) => {
                                props.updateState({ bgColor: obj.color });
                                barUnfillRef.style.background = obj.color;
                            }}
                            mode="RGB"
                        />
                    </Col>


                </Row>

                <Row className="but-buttons">
                    <Col span={7} style={{ paddingTop: '2px', paddingRight: '4px' }}>
                        <FormattedMessage id='buttonForegraound.text' />
                    </Col>
                    <Col span={17} >
                        <ColorPicker
                         placement='bottomLeft'
                            enableAlpha={false}
                            color={barfillRef.style.background || '#525156'}
                            onChange={(obj) => {
                                props.updateState({ foregraound: obj.color });
                                barfillRef.style.background = obj.color;
                            }}
                            mode="RGB"
                        />
                    </Col>


                </Row>


            </div>
        </Fragment>
    );
}


export default injectIntl(ImageProps);
