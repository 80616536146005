import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JobRoleDrawerComp from '../../../../../components/drawer/company/EmployeeMeta/JobRole';
import { hideModal, popModalFromStack, pushModalToStack, showModal } from '../../../../../actions/commonActions';
import { createJobRole } from '../../../../company/EmployeeMeta/JobRole/action';

class JobRoleDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(this.props.jobRoleItem || {})

        };
      }

  

    render() {
        return <JobRoleDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        drawerLoading: state.common.drawerLoading,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal, 
    pushModalToStack,
    popModalFromStack,
    createJobRole

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JobRoleDrawer);