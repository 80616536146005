import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegistrationFormSettingComponent from '../../../../../components/modal/modalBody/membership/RegistrationFormSetting';
import { pushModalToStack, popModalFromStack, fetchPaymentTerms, fetchProjects,
fetchSchoolSession, addSchoolSession, deleteSchoolSession,
fetchDocuments, addDocumentName, deleteDocumentName, } from '../../../../../actions/commonActions';
import { saveRegistrationFormSetting, fetchRegistrationFormSetting, resetRegistrationFormSetting, fetchAvailablePaymentGateway } from './action';
//import { fetchRegistrationFormSettingList } from '../RegisterFormList/action';
import EmailComponent from '../../../../../containers/drawer/recurringInvoiceEmail';
import { getMembershipOptionListing } from '../../../../membership/membershipOption/action';
import { getAllMembershipForm } from '../../../../membership/membershipFormList/action';
import { CONSTANTS, LEDGER_ACTION_LIST } from '../../../../../static/constants';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { fetchAllLedgerAccounts } from '../../../../finance/LedgerAccounts/action';
import { getAllDG } from'../../../../communication/distribution-group/action';
import { getSchoolCourse } from '../../../../schoolManagement/course/action';
import { fetchEmailTemplate } from '../../settings/EmailTemplate/action';
import { getMomentDateForUIReadOnly } from '../../../../../utils';
import { fetchAllUsers } from '../../../../settings/userManagement/action';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class RegistrationFormSetting extends Component {
    constructor(props) {
        super(props);
        const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES }) || {};
        const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
        this.state = {
            ledgerAccountIdInvoice: defaultSalesLedger.ledgerAccountId,
            questionList: [],
            // fatherFirstNameDisplay: 0,
            // fatherFirstNameRequired: 0,
            isFormActive: 1,
            primaryContactForInvoicing : props.registrationType === 'student' ? 1 : 0,
            membershipOptions: [],
            relationshipBillingAddress,
            formHeading: '',
            registrationType: props.registrationType || 'membership',
            firstNameDisplay: props.registrationType === 'membership' ? 1 : 0,
            lastNameDisplay: props.registrationType === 'membership' ? 1 : 0,
            firstNameRequired: props.registrationType === 'membership' ? 1 : 0,
            lastNameRequired: props.registrationType === 'membership' ? 1 : 0,
            collectStudentInfo: (props.registrationType === 'student' || props.registrationType === 'membership') ? 1 : 0,
            fatherFirstNameDisplay: props.registrationType === 'student' ? 1 : 0,
            fatherFirstNameRequired: props.registrationType === 'student' ? 1 : 0,
            fatherLastNameDisplay: props.registrationType === 'student' ? 1 : 0,
            fatherLastNameRequired: props.registrationType === 'student' ? 1 : 0,
            fatherEmailDisplay: props.registrationType === 'student' ? 1 : 0,
            fatherEmailRequired: props.registrationType === 'student' ? 1 : 0,

            motherFirstNameDisplay: props.registrationType === 'student' ? 1 : 0,
            motherFirstNameRequired: props.registrationType === 'student' ? 1 : 0,
            motherLastNameDisplay: props.registrationType === 'student' ? 1 : 0,
            motherLastNameRequired: props.registrationType === 'student' ? 1 : 0,
            motherEmailDisplay: props.registrationType === 'student' ? 1 : 0,
            motherEmailRequired: props.registrationType === 'student' ? 1 : 0,
        }
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        this.props.registrationFormSettingId && this.props.fetchRegistrationFormSetting({ relationshipId: companyInfo.relationshipId, registrationFormSettingId: this.props.registrationFormSettingId });
        this.props.fetchPaymentTerms({ relationshipId: companyInfo.relationshipId });
        this.props.fetchAvailablePaymentGateway({ relationshipId: companyInfo.relationshipId });
        this.props.fetchProjects({ relationshipId: companyInfo.relationshipId });
        this.props.getMembershipOptionListing({ relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 500 });
        this.props.fetchAllLedgerAccounts({ relationshipId: companyInfo.relationshipId });
        this.props.getAllDG({ relationshipId: companyInfo.relationshipId });
        this.props.registrationType === 'student' && this.props.getSchoolCourse({ relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 500 });    
        
        this.props.fetchEmailTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Recurring Sales Invoice' });
        this.props.fetchSchoolSession({ relationshipId: companyInfo.relationshipId });
        this.props.fetchDocuments({ relationshipId: companyInfo.relationshipId });
        this.props.fetchAllUsers({relationshipId: companyInfo.relationshipId});
    }

    componentWillReceiveProps(props) {
        if (props.registrationFormSetting && props.registrationFormSetting.registrationFormSettingId && props.paymentTerms && props.paymentTerms.length) {
            let formSetting = JSON.parse(props.registrationFormSetting.formSetting);
            let gradeValidations = formSetting?.gradeValidations || {};
            let gradeFees = formSetting?.gradeFees || {};
            let isGradeDisplayed = formSetting?.isGradeDisplayed || {};
            const paymentTerm = props.paymentTerms.length ? props.paymentTerms.find(ind => (ind.paymentTermId === formSetting?.paymentTermId)) || {} : {};
            let membershipOptions = (formSetting.selectedMembershipOptions || []).map(obj => {
                return obj.membershipOptionId;
            })
            let gradeDG = JSON.parse(props.registrationFormSetting.gradeDG || '{}');
            this.setState({
                ...(formSetting || {}),
                membershipOptions,
                formHeading: props.registrationFormSetting.programName || 'My Awesome Title',
                registrationFormSettingId: props.registrationFormSetting.registrationFormSettingId,
                version: props.registrationFormSetting.version,
                createdByEmpId: props.registrationFormSetting.createdByEmpId || 0,
                paymentTerm,
                grade1MaxStudent: gradeValidations['1'],
                grade2MaxStudent: gradeValidations['2'],
                grade3MaxStudent: gradeValidations['3'],
                grade4MaxStudent: gradeValidations['4'],
                grade5MaxStudent: gradeValidations['5'],
                grade6MaxStudent: gradeValidations['6'],
                grade7MaxStudent: gradeValidations['7'],
                grade8MaxStudent: gradeValidations['8'],
                grade9MaxStudent: gradeValidations['9'],
                grade10MaxStudent: gradeValidations['10'],
                grade11MaxStudent: gradeValidations['11'],
                grade12MaxStudent: gradeValidations['12'],
                
                grade1Fee: gradeFees['1'],
                grade2Fee: gradeFees['2'],
                grade3Fee: gradeFees['3'],
                grade4Fee: gradeFees['4'],
                grade5Fee: gradeFees['5'],
                grade6Fee: gradeFees['6'],
                grade7Fee: gradeFees['7'],
                grade8Fee: gradeFees['8'],
                grade9Fee: gradeFees['9'],
                grade10Fee: gradeFees['10'],
                grade11Fee: gradeFees['11'],
                grade12Fee: gradeFees['12'],

                idGrade1Display: isGradeDisplayed['1'],
                idGrade2Display: isGradeDisplayed['2'],
                idGrade3Display: isGradeDisplayed['3'],
                idGrade4Display: isGradeDisplayed['4'],
                idGrade5Display: isGradeDisplayed['5'],
                idGrade6Display: isGradeDisplayed['6'],
                idGrade7Display: isGradeDisplayed['7'],
                idGrade8Display: isGradeDisplayed['8'],
                idGrade9Display: isGradeDisplayed['9'],
                idGrade10Display: isGradeDisplayed['10'],
                idGrade11Display: isGradeDisplayed['11'],
                idGrade12Display: isGradeDisplayed['12'],

                grade1DGList: gradeDG['1'],
                grade2DGList: gradeDG['2'],
                grade3DGList: gradeDG['3'],
                grade4DGList: gradeDG['4'],
                grade5DGList: gradeDG['5'],
                grade6DGList: gradeDG['6'],
                grade7DGList: gradeDG['7'],
                grade8DGList: gradeDG['8'],
                grade9DGList: gradeDG['9'],
                grade10DGList: gradeDG['10'],
                grade11DGList: gradeDG['11'],
                grade12DGList: gradeDG['12'],

                successMessage: props.registrationFormSetting.successMessage,

                isSendEmail: props.registrationFormSetting.emailMessage ? 1 : 0,
                isFormSetting: 1,
                emailMessage: props.registrationFormSetting.emailMessage,
                emailSubject: props.registrationFormSetting.emailSubject,
                emailDetails: props.registrationFormSetting.emailMessage ? { message: props.registrationFormSetting.emailMessage, subject: props.registrationFormSetting.emailSubject} : null,
                projectName: props.registrationFormSetting.projectName,
                projectMasterId: props.registrationFormSetting.projectMasterId,
                ledgerAccountIdPayment: props.registrationFormSetting.ledgerAccountIdPayment,
                isFormActive: props.registrationFormSetting.isFormActive,
                registrationType: props.registrationFormSetting.registrationType || 'membership',
                collectStudentInfo: (props.registrationFormSetting.registrationType === 'student' || props.registrationFormSetting.registrationType === 'membership') ? 1 : 0,
                isGenerateInvoice: props.registrationFormSetting.isGenerateInvoice ? 1 : 0,
                isAllowParentPaymentPlan: props.registrationFormSetting.isAllowParentPaymentPlan ? 1: 0,
                invoiceCycle: props.registrationFormSetting.isGenerateRecurringInvoice === 1 ?  'monthly' : ( props.registrationFormSetting.isGenerateInvoice === 1 ? 'oneTime': ''),
                recurringInvoiceSettings: props.registrationFormSetting.recurringInvoiceSettings ? JSON.parse(props.registrationFormSetting.recurringInvoiceSettings) : null,
                invoiceItemDescription: props.registrationFormSetting.invoiceItemDescription,
                ledgerAccountIdInvoice: props.registrationFormSetting.ledgerAccountIdInvoice,
                invoiceItem: props.registrationFormSetting.onetimeInvoiceItem ? JSON.parse(props.registrationFormSetting.onetimeInvoiceItem) : null,
                paymentPlan: props.registrationFormSetting.paymentPlan ? JSON.parse(props.registrationFormSetting.paymentPlan) : null,
                courseId: props.registrationFormSetting.courseId, 
                reminderList: props.registrationFormSetting.invoiceReminder ? JSON.parse(props.registrationFormSetting.invoiceReminder) : [],
                subcourseAndFeeList: props.registrationFormSetting.subcourse ? JSON.parse(props.registrationFormSetting.subcourse) : [],
                selectedPaymentGateway: formSetting.paymentGateway,
                selectedSessionName: formSetting.sessionStart ? getMomentDateForUIReadOnly({ date: formSetting.sessionStart, format}) + ' - ' + getMomentDateForUIReadOnly({ date: formSetting.sessionEnd, format }) : null,
                isPublishParentPortal: props.registrationFormSetting.isPublishParentPortal ? 1: 0,
                discountRuleList: props.registrationFormSetting.invoiceDiscountRule ? JSON.parse(props.registrationFormSetting.invoiceDiscountRule) : [],
                userPermissions: props.registrationFormSetting.orgRegFormSettingPermissionList ? props.registrationFormSetting.orgRegFormSettingPermissionList.map(({ relEmployeeId }) => relEmployeeId) : [],
                schoolSessionId: props.registrationFormSetting.schoolSessionId,
                orgRegFormSettingPermissionList: props.registrationFormSetting.orgRegFormSettingPermissionList
            })
            this.props.resetRegistrationFormSetting();
        };
        if (!(this.state.paymentTerm && this.state.paymentTerm.termName) && props.paymentTerms && props.paymentTerms.length) {
            let paymentTerm = (this.props.paymentTerms.find(ind => (ind.termName === 'NET 7'))) || {};
            this.setState({ paymentTerm });
        }
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const onlineThemes = JSON.parse(props.companyInfo.onlineThemes || '{}');
            this.setState({
                textColor: onlineThemes.textColor || '#ffffff',
                bgColor: onlineThemes.bgColor || '#31c0be',
            })
        }
    }

    render() {
        return <div>
            {this.state.emailDetailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <RegistrationFormSettingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        registrationFormSetting: state.settings.registrationFormSetting,
        paymentTerms: state.common.paymentTerms,
        availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
        projectList: state.common.projectList,
        membershipOptionList: state.membershipReducer.membershipOptionList,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        dgList: state.communicationReducer.dgList,
        courseList: state.school.courseList,
        templateData: state.settings.templateData,
        schoolSessionList: state.common.schoolSessionList,
        documentList: state.common.documentList,
        allCompanyUsers: state.settings.allCompanyUsers,
        defaultSchoolForm: state.common.defaultSchoolForm,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveRegistrationFormSetting,
    fetchRegistrationFormSetting,
    resetRegistrationFormSetting,
    pushModalToStack,
   // fetchRegistrationFormSettingList,
    popModalFromStack,
    fetchPaymentTerms,
    fetchAvailablePaymentGateway,
    fetchProjects,
    getMembershipOptionListing,
    getAllMembershipForm,
    fetchAllLedgerAccounts,
    getAllDG, 
    getSchoolCourse,
    fetchEmailTemplate,
    fetchDocuments, addDocumentName, deleteDocumentName,
    fetchSchoolSession, addSchoolSession, deleteSchoolSession,
    fetchAllUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormSetting);