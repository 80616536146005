import React from 'react';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button } from 'antd';
import { injectIntl,FormattedMessage } from 'react-intl';
import { Dropdown } from '../../../general/Dropdown';
import LoadingIcon from '../../../../assets/images/loading.gif';

const HeaderAction = (props) => {
  const {
    intl,
    customers,
    pageNumber,
    customer,
    updateState,
  } = props;
  const getFormatedNextTxNumber = (props) => {
    // const { customer } = props;
    // if (!customer || !customer.customerId) {
    //     return <span>{props.nextTxNumber}</span>;
    // }
    // return <span>{props.nextTxNumber.replace('CM-00', 'CM-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
    return <span>{props.nextTxNumber}</span>;
}
  return (

    <>
    <div className='left-itms'>
      <ul>
        <li className='title'>
          <FormattedMessage id='sidebar.menuItem.creditMemo' defaultMessage='' />
        </li>
        <li className='sep' />
        <li>
          <FormattedMessage id='customer.creditMemo.form.creditMemoNumber.label' defaultMessage='' /> &nbsp; - &nbsp;
          {
          props.nextTxNumber ?
          <span>{getFormatedNextTxNumber(props)}</span>
            :
          <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'margin-top': '-25px', 'margin-bottom': '-25px' }} />
          }
        </li>
      </ul>
     </div>
      <div className='right-itms'>

        {/* <Dropdown
          style={{ width: 150 }}
          items={priceList}
          valueKeyName='priceListName'
          optionKeyName='priceListId'
          value={priceListName}
          canAddNew={true}
          onAddNewClick={(payload) => {
            //handleNewItemAddition(props, payload, MODAL_TYPE.CUSTOMER_TYPE)
          }}
          placeholder={intl.formatMessage({
            id: 'customer.creditMemo.form.priceList.placeholder', defaultMessage: ''
          })}
          onSelect={
            (priceListName) => {
              updateState({ priceListName });
            }
          }
        /> */}

        <Dropdown
          style={{ width: "200px" }}
          items={customers[pageNumber] || []}
          valueKeyName='companyName'
          optionKeyName='customerId'
          value={(customer || {}).companyName}
          onSearch={(searchedText) => {
            props.fetchCustomers({
              isCompact: true,
              searchedText: searchedText,
              pageNumber: 1,
              hideSpinner: true,
              pageSize: 100,
              relationshipId: (props.companyInfo || {}).relationshipId
            })
          }}
          placeholder={intl.formatMessage({
            id: 'customer.creditMemo.form.customer.placeholder', defaultMessage: ''
          })}
          canAddNew={true}
          canDelete={true}
          deleteClickHandler={(payload) => {
            props.deleteCustomers({ groupCustomerIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
          }}
          onAddNewClick={(payload) => {
            props.updateHeaderState({
              customer: {
                customerDrawerVisible: true,
                customerName: payload.textEntered,
              }
            })
          }}
          onSelect={
            (customerId) => {
              props.fetchCustomerDetailsByCustomerId({
                customerId,
                relationshipId: (props.companyInfo || {}).relationshipId
              });
            }
          }
          allowClear={true}
          onClear={() => {
            updateState({
              customer: undefined,
              customerName: undefined,
              paymentTermName: undefined,
              paymentTermId: undefined,
              relationshipBillingAddresses: undefined,
              relationshipBillingAddress: undefined,
              allBillingAddress: undefined,
              billingAddress: undefined,
              selectedContact: undefined
            })
          }}
        />

        <Button style={{height:'38px'}} onClick={() => {
          props.updateState({
            ledgerDrawerVisible: true
          })
        }} >
          <i className='fa fa-book fa-lg'> </i>
        </Button>
        <Button onClick={() => {
            props.updateDrawerState({
                settingSalesDrawerVisible: true
            })
        }} >
            <i class="fa fa-cog" aria-hidden="true"></i>
        </Button>
        {/* <div className="space-lr">
          <Button icon={<SwapOutlined />} />
        </div> */}
      </div>
    </>
  );
};

export default injectIntl(HeaderAction);