import React, { useState } from "react";
import PageBreadcrumb from "../../PageBreadcrumb";
import "../leads.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import LeadSourceListComp from "../../../containers/leads/LeadSourceList";
import LeadStageList from "../../../containers/leads/LeadStageList";
import { ICONS } from "../../../static/constants";

const FormMeta = (props) => {
  const breadCrumbList = [
    {
      name: <FormattedMessage id="breadcrum.dashboard" defaultMessage="" />,
    },

    {
      name: <FormattedMessage id="sidebar.menuItem.settings" defaultMessage="" />,
    },

    {
      name: (
        <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />
      ),
    },
  ];

  const [componentToDisplay, setcomponentToDisplay] = useState("");


  // const handleSourceList = () => {
  //   props.history.push("/admin/leads/lead-meta/lead-source-list");
  // };


  // const handleLeadStageList = () => {
  //   props.history.push("/admin/leads/lead-meta/lead-stage-list");
  // };

  return (
    <div className="lead-meta" style={{backgroundColor:"#ffffff"}}>

      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="heading.lead.leadForm"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div>

      <div className="profile-container">
        <div className="sidenav">
          <div className="settings-body" style={{paddingTop:"0px"}}>

            <div className="settings-body-child">
              <div className="child-body">


                <div className={`lead-tile ${componentToDisplay==='Lead Source' ? 'lead-back' : ''} ${componentToDisplay==='' ? 'lead-back' : ''}`} 
      
                onClick={()=>setcomponentToDisplay('Lead Source')}>

                  <div className="tile-icon1">
                    <i class="fi fi-rs-globe" style={{fontSize:"24px"}}></i>
                  </div>
                  <div className="tile-title1 b-text">
                    <FormattedMessage
                      id="lead.leadSource.label"
                      defaultMessage=""
                    />
                  </div>

                  <div className="tile-description1">
                    You are powered with creating multiple users for your business.
                  </div>
                </div>
                <div className={`lead-tile ${componentToDisplay==='Lead Stage' ? 'lead-back' : ''}`} 
       
                onClick={()=>setcomponentToDisplay('Lead Stage')}>

                  <div className="tile-icon1" >

                 <i className={ICONS.FILTER} style={{fontSize:"24px"}} /> &nbsp;
                  </div>
                  <div className="tile-title1 b-text">

                    <FormattedMessage id="lead.leadStage.label" defaultMessage="" />
                  </div>
                  <div className="tile-description1">
                    You are powered with creating multiple users for your business.
                  </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div className="main-body" style={{padding:"20px"}}>
          
            {componentToDisplay ==='Lead Stage' ? <LeadStageList /> : <LeadSourceListComp />}
            {/* {componentToDisplay ==='Lead Source' ? <LeadSourceListComp /> : <LeadStageList />} */}

        </div>
      </div>

    </div>
  );

};

export default injectIntl(FormMeta);