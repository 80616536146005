import React, { useState } from "react";
import { Button, Col, TimePicker, Row } from "antd";
import {
    getCurrentDateForBackend,
    getLocalDateFromUtcStr
} from "../../../utils";
import { Dropdown } from "../../general/Dropdown";
import { TextBox } from "../../general/TextBox";
import moment from "moment-timezone";

const EditAttendanceComponent = (props) => {

    const { empData, attendanceData, setEditPop } = props;

    const [editedAt, setEditedAt] = useState({
        checkinTime: attendanceData.checkinDate ? moment.utc(attendanceData.checkinDate).local() : '',
        checkoutTime: attendanceData.checkoutDate ? moment.utc(attendanceData.checkoutDate).local() : '',
        atStatus: attendanceData.status,
    });


    const handleSubmit = () => {

        let attendanceList = [{
            relationshipEmployeeId: empData.employeeId,
            attendanceStatus: editedAt.atStatus || "Present",
        }];

        const payload = {
            relationshipId: props.relationshipId,
            attendanceList: attendanceList,
            checkinDate: getCurrentDateForBackend(
                editedAt.checkinTime || new Date()
            ),
            checkoutDate: getCurrentDateForBackend(
                editedAt.checkoutTime || new Date()
            ),
            createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
        };
        props.saveAttendance(payload, props);
        
    };

  return (
    <div style={{ padding: '10px 10px 10px 0'}}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="i-label">Name</div>
          <TextBox disabled= {true} value={empData.employeeFullName || ''} />
        </Col>

        <Col span={12}>
          <div className="i-label">Date</div>
          <TextBox disabled= {true} value={attendanceData.checkinDate ? getLocalDateFromUtcStr({ date: attendanceData.checkinDate }) : '-'} />
        </Col>

        <Col span={12}>
          <div className="i-label">Clockin Time</div>
          <TimePicker
            format={"HH:mm:ss"}
            value={editedAt.checkinTime}
            onChange={(selectedTime, timeString) => {
              setEditedAt({ ...editedAt, checkinTime: selectedTime });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Clockout Time</div>
          <TimePicker
            format={"HH:mm:ss"}
            value={editedAt.checkoutTime}
            onChange={(selectedTime, timeString) => {
              setEditedAt({ ...editedAt, checkoutTime: selectedTime });
            }}
          />
        </Col>

        <Col span={24}>
          <div className="i-label">Status</div>
          <Dropdown
            showSearch={false}
            items={["Present", "Absent"]}
            value={editedAt.atStatus}
            onSelect={(selectedValue) => {
              setEditedAt({ ...editedAt, atStatus: selectedValue });
            }}
          />
        </Col>
      </Row>

      <div className="pop_footer">

        <Button
            onClick={() => {
                setEditPop(false);
            }}
        >
            Cancel
        </Button>
        <Button
            type="primary"
            onClick={() => {
                handleSubmit();
                setEditPop(false);
            }}
        >
            Submit
        </Button>

      </div>

    </div>
  );
};

export default EditAttendanceComponent;