import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../static/constants';
import {  showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const SaveCrmLeadFormSetting = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadForm/saveOrUpdate`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Form Created Successfully.',
            messageType: 'success'
          })
          props.popModalFromStack();
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25,
          }
          props.fetchCrmLeadFormList(payload);
          props.getCrmLeadFormListCount(payload);
        })
        .catch((err = {}) => {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
        })
    }
}

export const fetchCrmLeadFormList = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios 
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadForm/getAllLeadFormByPage?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 100}&pageNumber=${payload.pageNumber - 1 || 0 }`)
        .then(res => {
          const data = {
            list: res.data,
            pageNo: res.pageNo,
          }
          dispatch({ type: CRM_ACTION_LIST.CRM_FORM_LIST, data });
        })
        .catch((err) => {
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = CRM_ACTION_LIST.CRM_FORM_LIST;
          }
        })
    }
}

export const getCrmLeadFormListCount = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios 
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadForm/getLeadFormsCount?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 100}&pageNumber=${payload.pageNumber - 1 || 0 }`)
        .then(res => {
            const data = res.data
            dispatch({ type: CRM_ACTION_LIST.CRM_FORM_LIST_COUNT, data });
        })
        .catch((err) => {
            if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            } else {
            err.actionToCall = CRM_ACTION_LIST.CRM_FORM_LIST_COUNT;
            }
        })
    }
}

export const deleteCrmLeadFormListItem = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios 
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadForm/delete`, payload)
        .then(res => {
            showToasterMessage({
                description: 'Deleted Successfully!',
                messageType: 'success'
            })

            const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25,
            }
            props.fetchCrmLeadFormList(payload);
            props.getCrmLeadFormListCount(payload);
        })
        .catch((err) => {
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = CRM_ACTION_LIST.CRM_FORM_LIST;
          }
        })
    }
}