import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailCampaignComp from '../../../components/emailMarketing/emailCampaigns';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { fetchCampaign, getCampaignCount, refreshCampaignEmailData, fetchgAggregatedReportByRid, fetchCampaignEmailStatistics} from './action';
import { getCurrentDateForBackend } from '../../../utils';
import moment from 'moment-timezone';

class EmailCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 25,
            emailStatsLoaded: false,
            loadingCampaignIds: []
        }
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,

        };
        this.props.fetchCampaign(payload);
        this.props.getCampaignCount(payload);


    }

    componentWillReceiveProps(props){

        if(props.campaignList[1]?.length && !this.state.emailStatsLoaded){
            let arr = []
            for(let item of props.campaignList[1]){
                this.props.fetchCampaignEmailStatistics({
                    campaignId: item.marketingCampaignId,
                    startDate: getCurrentDateForBackend(moment.utc(new Date()).subtract(30, 'days').toISOString()),
                    endDate: getCurrentDateForBackend(moment.utc(new Date()).toISOString())
                }, {...props, ...this.props, ...this.state});
                
                arr.push(item.marketingCampaignId);
            }
            this.setState({emailStatsLoaded: true})
        }

    }

    render() {
        return <EmailCampaignComp {...this.props} {...this.state} updateState = {(data)=>{this.setState(data)}}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        permissions: state.auth.permissions,
        campaignList: state.communicationReducer.campaignList,
        campaignCount: state.communicationReducer.campaignCount,
        listLoading: state.common.listLoading,
        userInfo: state.common.userInfo,
        campaignEmailMetrics: state.communicationReducer.campaignEmailMetrics,
        aggregateReport: state.communicationReducer.aggregateReport,
        campaignEmailStatistics: state.communicationReducer.campaignEmailStatistics,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    fetchCampaign,
    getCampaignCount,
    refreshCampaignEmailData,
    fetchCampaignEmailStatistics,
    fetchgAggregatedReportByRid

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmailCampaign);
