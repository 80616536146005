import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
//import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Checkbox } from 'antd';
import { fetchPaginationDataIfNeeded, showToasterMessage } from '../../../utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import * as find from 'lodash.find';


const PublishedCategoryComp = (props) => {
   // const [isAllChecked, toggleAllChecked] = useState(false);
    const {  publishedCategories, updateState, companyInfo, publishedCategoriesCount, pageNumber, selectedItems=[] } = props;

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchPublishedCategory', 'publishedCategories', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPublishedProductsPage();
        props.fetchPublishedCategory(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    const itemSelection = (item) => {
        let selectItem = find(selectedItems, { inventoryItemCategoryId: Number(item.inventoryItemCategoryId) });
        let updatedItems = [];
        if (selectItem) {
            updatedItems = selectedItems.filter(obj => obj.inventoryItemCategoryId !== item.inventoryItemCategoryId);
        } else {
            updatedItems = JSON.parse(JSON.stringify(selectedItems));
            updatedItems.push(item);
        }
        props.updateState({ selectedItems: updatedItems });
    }

    const selectAll = (context) => {
        if (!context) {
            selectedItems.splice(0, selectedItems.length);
            props.updateState({ selectedItems: selectedItems });
            return;
        }
        for (let i = 0; i < publishedCategories[pageNumber].length; i++) {
            let item = publishedCategories[pageNumber];
            let selectItem = find(selectedItems, { inventoryItemCategoryId: Number(item.inventoryItemCategoryId) });
            if (!selectItem) {
                selectedItems.push(item);
            }
        }
    }

    const isCheckedAll = () => {
        if (!publishedCategories || !publishedCategories[pageNumber] || publishedCategories[pageNumber].length === 0) {
            return false;
        }
        for (let i = 0; i < publishedCategories[pageNumber].length; i++) {
            let usr = publishedCategories[pageNumber][i];
            let selectItem = find(selectedItems, { inventoryItemCategoryId: Number(usr.inventoryItemCategoryId) });
            if (!selectItem) {
                return false;
            }
        }
        return true;
    }

    return (
        <Fragment>
            <div className='view-container mt0'>
                <Skeleton loading={props.isLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    <div className="table-action-container">
                        <div className="d-inline-flex">
                            <button className="all-checkbox-control">
                                <Checkbox
                                    checked={isCheckedAll()}
                                    onChange={(e) => {
                                        selectAll(e.target.checked);
                                       
                                    }}
                                />
                            </button>

                            <button className="all-checkbox-control cursor-pointer" onClick={() => {
                                if (selectedItems.length > 0) {
                                    const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                                    const modalData = {
                                        modalBody,
                                        handleSubmit: () => {
                                            props.updatePublisedCategory({
                                                relationshipId: props.companyInfo.relationshipId,
                                                inventoryItemCategoryIds: (selectedItems || []).map(itemObj => itemObj.inventoryItemCategoryId),
                                                isPublisedItem: 0,
                                                pageNumber: props.pageNumber,
                                                pageSize: props.pageSize
                                            })
                                            props.hideModal();
                                            selectAll(false);
                                        },
                                    };
                                    props.showModal(modalData);
                                } else {
                                    return showToasterMessage({
                                        description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding', defaultMessage: '' }),
                                        messageType: 'error'
                                    })
                                }
                            }}>
                               <i className="fa fa-trash" />
                            </button>
                        </div>
                    </div>

                    <div className='agGridWrapper'>
                      
                        <div className='new-tablesc'>
                        <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th> <Checkbox
                                            onChange={(e) => {
                                                selectAll(e.target.checked);
                                            }}
                                            checked={isCheckedAll()}
                                        />
                                        </th>

                                        <th><FormattedMessage id='inventory.extras.category.listing.header.categoryName' /></th>
                                        <th><FormattedMessage id='inventory.extras.category.listing.header.totalProducts' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {publishedCategories && publishedCategories[pageNumber] ?
                                        (publishedCategories[pageNumber] || []).map((item, i) => {
                                            return (<tr key={i}>
                                                <td>
                                                    <Checkbox
                                                        onClick={() => {
                                                            itemSelection(item);
                                                        }}
                                                        checked={find(selectedItems, { inventoryItemCategoryId: Number(item.inventoryItemCategoryId) }) ? true : false}
                                                    />
                                                </td>
                                                <td> <div>{item.categoryName}</div> </td>
                                                <td>{item.totalProducts}</td>

                                            </tr>)
                                        })

                                        : 'No Data'}
                                </tbody>
                            </table>
                            </div>
                    </div>
                </Skeleton>
                <Pagination
                    size="small"
                    total={publishedCategoriesCount}
                    showTotal={showTotal}
                    defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber, pageSize, props)
                    }}
                />
            </div>
        </Fragment>
    );
};

export default injectIntl(PublishedCategoryComp);
