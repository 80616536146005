import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SystemStatusCompo from '../../../../../components/modal/modalBody/common/SystemStatus';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
// import { createStatus, fetchAllStatus, deleteStatus, resetUserStatusList } from './action';

class SystemStatusContainer extends Component {
  componentDidMount() {
    // const companyInfo = this.props.companyInfo || {};
    // const payload = {
    //   relationshipId: companyInfo.relationshipId,
    //   transactionName: this.props.transactionName,
    // };
    // this.props.resetUserStatusList();
   // fetchDataIfNeeded('fetchAllStatus', 'userStatus', this.props, payload);
  }

  render() {
    return (
      <SystemStatusCompo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    // userStatus: state.common.userStatus,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SystemStatusContainer);
