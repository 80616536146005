import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import ProductCardComp from "../../../components/B2B/ProductCard/index";
import { fetchItem } from './action';
import { fetchInventoryItemDetails } from '../../product/detail/action';
class ProductCard extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(this.props.location.search) || {};
        this.state = {
            value :linkProps||{},
            status: linkProps.status,
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(this.props.location.search) || {};
        const payload = { relationshipId: linkProps.relationshipId, itemId: linkProps.productId };
        this.props.fetchInventoryItemDetails(payload)
    }

    componentWillReceiveProps(props) {
        if(Object.keys(props.productDetails)?.length){
            const val = props.productDetails?.inventoryItemVariantList

            this.setState({
                item:val?.filter((item)=>item.variantId === Number(this.state.value?.productVarieantId))?val?.filter((item)=>item.variantId === Number(this.state.value?.productVarieantId))[0]:[]
            })
        }
       
          
    }
    render() {
        window.document.title= this.props?.companyInfo?.storeName || '';
        return <>
           
                <ProductCardComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
             
            
        </> 
    }
}
const mapStateToProps = (state) => {
    return {
       
    productDetails: state.product.productDetails,

       
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchItem,
    fetchInventoryItemDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
