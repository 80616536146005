import React from "react";
import { Tabs } from "antd";
import SalesInvoice from "./salesInvoice";
import SalesInvoiceDraft from "./salesInvoiceDraft";
import RecurringInvoice from "./recurringInvoice";
import Einvoice from "./einvoice";
import InvoiceReminder from "./invoiceReminder";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import ProformaInvoice from "./proformaInvoice";
import { FormattedMessage } from "react-intl";
import StandardSalesInvoice from "../../../../containers/customer/invoice/StandardSalesInvoice/index";
import FeesManagement from "./feesManagement";
import PageHeader from "../../../Common/pageHeader";
import EWayBillListingComp from "./eWayBill";
const { TabPane } = Tabs;

const SalesQuoteListingComp = (props) => {
  const breadCrumbList = [
    {
      name: <FormattedMessage id="common.sales" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="common.salesInvoice" defaultMessage="" />,
    },
  ];

  const breadCrumbList2 = [
    {
      name: <FormattedMessage id="common.fees" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="common.feesInvoice" defaultMessage="" />,
    },
  ];

  function onTabChange(key, props) {

    const payload = {
      companyInfo: props.companyInfo,
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: props.pageNumber,
      pageSize: props.pageSize,
    };
  
  
    switch (key) {
  
      case "feeManagement":
        props.updateState({ activeTab: "Fees Management", activeTabKey: key });
        break;
  
      case "feeInvoice":
        props.updateState({ activeTab: "Sales Invoice", activeTabKey: key });
        break;
      case "EInvoice":
          props.updateState({ activeTab: "e-Invoice", activeTabKey: key });
          break;
          case "EWayBill":
            props.updateState({ activeTab: "e-Way Bill", activeTabKey: key });
            break;
  
      case "draft":
        props.updateState({ activeTab: "Draft", activeTabKey: key });
        fetchDataIfNeeded(
          "getSalesInvoiceDraftCount",
          "salesInvoiceDraftCount",
          props,
          payload
        );
        fetchPaginationDataIfNeeded(
          "fetchSalesInvoiceDrafts",
          "salesInvoiceDraftList",
          props,
          payload
        );
        break;
      case "proforma":
        props.updateState({ activeTab: "Proforma", activeTabKey: key });
        break;
      case "recurringInvoice":
        props.updateState({ activeTab: "Recurring Invoice", activeTabKey: key });
        fetchPaginationDataIfNeeded(
          "fetchRecurringInvoices",
          "recurringInvoiceList",
          props,
          payload
        );
        break;
      case "reminders":
        props.updateState({ activeTab: "Reminders", activeTabKey: key });
        fetchPaginationDataIfNeeded(
          "fetchInvoicesReminder",
          "invoiceReminderList",
          props,
          payload
        );
        break;
      default:
        // handle default case if needed
        break;
    }
  }

  
  const openNewInvoice = () => {
    props.pushModalToStack({
        modalBody: <StandardSalesInvoice {...props} />,
        width: '100%',
        hideTitle: true,
        hideFooter: true, keyboard:false,
        wrapClassName: 'new-transaction-wrapper',
        onCancel: () => {
          props.showModal({
            modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
            footer:{submit:{intlId:"button.close.label"}},
            handleSubmit: () => {
              props.popModalFromStack();
            }
          })
        }
    })
}

  return (
    <>
    
      <PageHeader {...props}
        pageName={(props.isSchoolUser) ? 'heading.fees.feesInvoice' : "heading.sales.salesInvoice"}
        breadCrumbList={(props.isSchoolUser) ? breadCrumbList2 : breadCrumbList}
        canCreate={true}
        onCreate={openNewInvoice}
      />
      <div className="view-container">
        <Tabs
          activeKey={props.activeTabKey }
          onChange={(key) => {
            onTabChange(key, props);
          }}
          type="line"
        >
          
          {/* {
            props.isSchoolUser ? 
              <TabPane tab={'Fees Managment'} key="feeManagement">
                <FeesManagement {...props} />
              </TabPane>
              :
              ""
          } */}

          <TabPane tab={(props.isSchoolUser) ? 'Fees Invoice' : 'Sales Invoice'} key="feeInvoice">
            <SalesInvoice {...props} />
          </TabPane>
             {
              props.companyInfo?.countryName === "India" ? (<TabPane tab={ 'e-Invoice'} key="EInvoice">
              <Einvoice {...props} />
            </TabPane>):""
             }
               {
              props.companyInfo?.countryName === "India" ? ( <TabPane tab={ 'e-Way Bill'} key="EWayBill">
              <EWayBillListingComp {...props} />
            </TabPane>):""
             }
        

          <TabPane tab="Draft" key="draft">
            <SalesInvoiceDraft {...props} />
          </TabPane>


          <TabPane tab="Proforma" key="proforma">
            <ProformaInvoice {...props} />
          </TabPane>

          <TabPane tab="Recurring Invoice" key="recurringInvoice">
            <RecurringInvoice {...props} />
          </TabPane>

          <TabPane tab="Reminders" key="reminders">
            <InvoiceReminder {...props} />
          </TabPane>

        </Tabs>
      </div>
    </>
  );
};

export default SalesQuoteListingComp;
