import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { CustomButton } from '../../../../general/CustomButton';
import { clearLocalStorage } from '../../../../../utils';

let timeInterval;
class SessionTimeoutComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isBlink: true,
            minCounter: 2,
            secondCounter: 0
        }
        timeInterval = setInterval(() => {
            if (!this.state.secondCounter && !this.state.minCounter) {
                clearLocalStorage();
                this.props.history.push("/logout");
                this.props.hideModal();
                clearInterval(timeInterval);
                window.location.reload();
            }
            else if (!this.state.secondCounter) {
                this.setState({
                    minCounter: this.state.minCounter - 1,
                    secondCounter: 59,
                    isBlink: !this.state.isBlink
                });
            } else if (this.state.secondCounter || this.state.minCounter) {
                this.setState({
                    minCounter: this.state.minCounter,
                    secondCounter: this.state.secondCounter - 1,
                    isBlink: !this.state.isBlink
                });
            }
        }, 1000)

    }



    render() {
       

        return <div> It has been over 10 minutes without activity. For security reason
                    and protection of your data, your session is about to expire in <br />
            <br /><h2><i className={'fa fa-clock-o'}></i> {this.state.minCounter}{ this.state.isBlink ?  ":" : " "}{this.state.secondCounter} </h2>
            To continue your session, select <strong>Stay Signed In</strong> or click <strong>Log Off</strong> to end your session now.
            <br />
            <br />
            <div key={0} className='customModal-button-group'>
                <CustomButton
                    intlId={'confirmation.signout'}
                    defaultMessage={''}
                    type={'default'}
                    key={1}
                    onClick={() => {
                        clearLocalStorage();
                        this.props.history.push("/logout");
                        this.props.hideModal();
                        clearInterval(timeInterval);
                    }}
                />
                <CustomButton
                    intlId={'confirmation.staySignedIn'}
                    defaultMessage={''}
                    htmlType='submit'
                    key={2}
                    onClick={() => {
                        this.props.hideModal();
                        clearInterval(timeInterval);
                    }}
                />
            </div>
        </div>
    }
}

export default injectIntl(SessionTimeoutComponent);
