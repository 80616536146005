import React, { useState, Fragment } from "react";
import { Form } from '@ant-design/compatible';
import { injectIntl, FormattedMessage } from 'react-intl';
import './index.scss';
import { Row, Col, Radio, Checkbox, Button, DatePicker, Tabs, Select } from "antd";
import { TextBox } from "../../../../general/TextBox";
import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import { TextArea } from "../../../../general/TextAreaComponent";
// import { CustomButton } from '../../../../general/CustomButton';
import { Dropdown } from '../../../../general/Dropdown';
import { getMomentDateForUIReadOnly, getCurrentDateForBackend, showToasterMessage } from '../../../../../utils';
import { CONSTANTS } from '../../../../../static/constants';
import ReactHtmlParser from 'react-html-parser';
import { LogoComponent } from '../../../../general/LogoComponent';
import config from '../../../../../config/environmentConfig';
import TablatLogo from "../../../../../assets/images/tablet-icons.png";
import TablatVLogo from "../../../../../assets/images/tablet-iconv.png";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { Option } = Select;

const { TabPane } = Tabs;
const RegistrationFormSettingPreview = (props) => {
    const { intl, formSetting = {}, companyInfo } = props;
    const [gridApi, updateGridApi] = useState({});
    const onGridReady = (params) => {
        updateGridApi(params.api);
        params.api.sizeColumnsToFit();
    }
    const getAddressInfoFragment = (obj, additionInfo) => {

        const fragmentObj = <Fragment>
            {
                obj.streetAddress1
                    ? <span className='billing-address'>{obj.streetAddress1}
                    </span>
                    : ''
            }
            {
                obj.streetAddress2
                    ? <span className='billing-address'> {obj.streetAddress2}
                    </span>
                    : ''
            }
            {
                obj.cityName
                    ? <div className='billing-address'>
                        {
                            `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                        }
                    </div>
                    : ''
            }
            {
                additionInfo.workPhone
                    ? <div className='billing-address'>
                        <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                    </div> :
                    ''
            }
            {
                additionInfo.email
                    ? <div className='billing-address'>
                        <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                    </div> :
                    ''
            }
        </Fragment>
        return fragmentObj;
    }
    const save = (e) => {
        e.preventDefault();
        let isRequiredFiledMissing = false;
        let isRequiredQuestionMissing = false;
        let studentList = []
        gridApi.forEachNode((rowNode, index) => {
            let { data } = rowNode;
            if (!data.firstName || !data.lastName || !data.grade || !data.dob || !data.allergies) {
                isRequiredFiledMissing = true;
            } else {
                studentList.push({
                    relationshipId: props.relationshipId,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    gender: data.gender,
                    grade: data.grade,
                    allergies: data.allergies,
                    version: data.version,
                    studentId: data.studentId,
                    studentRegistrationId: data.studentRegistrationId,
                    dob: data.dob ? getMomentDateForUIReadOnly({ date: data.dob, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''

                });
            }
        })

        if (!studentList.length) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'no.student.message' })
            });
        }

        if (isRequiredFiledMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'student.missing.details.message' })
            });
        }

        (props.formSetting.questionList || []).forEach((ques) => {
            if (ques.isRequired && (!ques.answers || !ques.answers.length)) {
                isRequiredQuestionMissing = true;
            }
        })

        if (isRequiredQuestionMissing) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'required.question.missing' })
            });
        }

        if (props.addressRequired && (!props.streetAddress1 || !props.cityName || !props.stateName || !props.zipCode)) {
            return showToasterMessage({
                messageType: 'error', description: props.intl.formatMessage({ id: 'address.missing.message' })
            });
        }

        const modalData = {
            modalBody: <FormattedMessage id='save.confirmation' />,
            handleSubmit: () => {
                let contactList = [];
                let boLocationList = [];
                if (props.fatherFirstName) {
                    contactList.push({
                        firstName: props.fatherFirstName,
                        lastName: props.fatherLastName,
                        workPhone: props.fatherMobile,
                        emailAddress: props.fatherEmail,
                        relationshipId: props.relationshipId,
                        dateCreated: getCurrentDateForBackend(new Date()),
                        gender: 'Male',
                        contactType: "Customer",
                    });
                }
                if (props.motherFirstName) {
                    contactList.push({
                        firstName: props.motherFirstName,
                        lastName: props.motherLastName,
                        workPhone: props.motherMobile,
                        emailAddress: props.motherEmail,
                        relationshipId: props.relationshipId,
                        dateCreated: getCurrentDateForBackend(new Date()),
                        gender: 'Female',
                        contactType: "Customer",
                    });
                }

                if (props.streetAddress1 && props.cityName && props.stateName && props.zipCode) {
                    boLocationList.push({
                        streetAddress1: props.streetAddress1,
                        streetAddress2: props.streetAddress2,
                        cityName: props.cityName,
                        countryId: props.countryId,
                        countryName: props.countryName,
                        stateName: props.stateName,
                        zipCode: props.zipCode,
                        locationType: CONSTANTS.BILLING_ADDRESS,
                        relationshipId: props.relationshipId,
                        version: props.addressVersion
                    })
                }

                let registrationData = {
                    relationshipId: props.relationshipId,
                    fatherFirstName: props.fatherFirstName,
                    fatherLastName: props.fatherLastName,
                    fatherEmail: props.fatherEmail,
                    fatherMobile: props.fatherMobile,

                    motherFirstName: props.motherFirstName,
                    motherLastName: props.motherLastName,
                    motherEmail: props.motherEmail,
                    motherMobile: props.motherMobile,

                    emergencyContactName: props.emergencyContactName,
                    emergencyPhone: props.emergencyPhone,
                    studentList: studentList,
                    questionList: JSON.stringify(props.formSetting.questionList),
                    studentRegistrationId: props.studentRegistrationId,
                    version: props.registrationVersion,
                    customerId: props.customerId,
                    registrationFormSettingId: props.registrationFormSettingId
                }

                let customerPayload = {
                    companyName: props.fatherFirstName + (props.fatherLastName ? ' ' + props.fatherLastName : ''),
                    boContactList: contactList,
                    boLocationList: boLocationList,
                    relationshipId: props.relationshipId,
                    customerType: 'business',
                    orgRegistrationList: [registrationData],
                    paymentTermId: formSetting.paymentTermId,
                    customerId: props.customerId,
                    version: props.version,
                }

                props.saveRegistration(customerPayload, props);
                props.popModalFromStack(modalData);
            }
        };
        props.pushModalToStack(modalData);
    }
    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='signup.firstname.placeholder' /><span className="required">*</span></span>;
            },
            field: "firstName",
            resizable: true,
            editable: true,
            cellEditor: 'customTextEditor',
            suppressMovable: true,
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
        },
        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='signup.lastname.placeholder' /><span className="required">*</span></span>;
            },
            field: "lastName",
            resizable: true,
            editable: true,
            cellEditor: 'customTextEditor',
            suppressMovable: true,
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
        },
        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='grade.text' /><span className="required">*</span></span>;
            },
            field: "grade",
            resizable: true,
            editable: true,
            suppressMovable: true,
            width: 100,
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
            cellEditor: 'customDropDownEditor',
            cellEditorParams: (obj) => {
                return {
                    lastColumnCell: false,
                    items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    onSelect: (selectedValue) => {
                        //obj.node.setDataValue(obj.colDef.field, selectedValue);
                        if (formSetting.gradeLevelValidation && Number((formSetting.gradeValidations || {})[selectedValue] || 0) <= (props.totalStudentCountByGrade || {})[selectedValue]) {
                            return showToasterMessage({
                                messageType: 'error', description: props.intl.formatMessage({ id: 'no.more.seat.available.inGrade.message', values: { value: selectedValue } })
                            });
                        }

                    },
                    allowClear: true,
                    onClear: () => {
                        //obj.node.setDataValue(obj.colDef.field, undefined);
                    },
                }
            },
            valueGetter: (obj) => {
                if (formSetting.gradeLevelValidation && Number((formSetting.gradeValidations || {})[obj.data.grade] || 0) <= (props.totalStudentCountByGrade || {})[obj.data.grade]) {
                    return ""
                }
                let totalfee = 0;
                obj.api.forEachNode((rowNode, index) => {
                    const { data } = rowNode;
                    if (data.grade) {
                        totalfee += Number((formSetting.gradeFees || {})[data.grade] || 0);
                    }
                });
                props.updateState({ totalfee })
                return obj.data.grade;
            }
        },
        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='dob.text' /><span className="required">*</span></span>;
            },
            field: "dob",
            resizable: true,
            editable: true,
            width: 150,
            cellEditor: 'customDatePicker',
            cellEditorParams: (obj) => {
                return { disabledDate: (date) => (date && date.valueOf() > Date.now()), }
            },
            cellRendererFramework: (params) => {
                return <span>{params.data.dob ? getMomentDateForUIReadOnly({ date: params.data.dob, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</span>
            },
        },

        {
            headerComponentFramework: () => {
                return <span><FormattedMessage id='allergies.header.text' /><span className="required">*</span></span>;
            },
            field: "allergies",
            resizable: true,
            editable: true,
            cellEditor: 'customTextEditor',
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='gender.text' />;
            },
            field: "gender",
            resizable: true,
            editable: true,
            width: 100,
            suppressMovable: true,
            cellEditor: 'customDropDownEditor',
            suppressKeyboardEvent: (params) => {
                const KEY_ENTER = 13;
                const keyCode = params.event.keyCode;
                const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                return gridShouldDoNothing;
            },
            cellEditorParams: (obj) => {
                return {
                    lastColumnCell: false,
                    items: ['Male', 'Female', 'Rather Not Say'],
                    onSelect: (selectedValue) => {
                        //obj.node.setDataValue(obj.colDef.field, selectedValue);
                    },
                    allowClear: true,
                    onClear: () => {
                        //obj.node.setDataValue(obj.colDef.field, undefined);
                    },
                }
            }
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='customer.listing.header.action' />;
            },
            width: 70,
            field: "action",
            resizable: true,
            cellRenderer: 'customActionEditor',
            suppressNavigable: true,
            cellRendererParams: (params) => {
                return {
                    onClick: () => {
                        params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                        if (params.api.rowRenderer.lastRenderedRow < 0) {
                            params.api.updateRowData({ add: [{}] });
                        }
                    },
                }
            }

        }
    ]
    return (
        <div>
        <div>
        <div className="tab-but-center">
            <Tabs defaultActiveKey="1" centered>
    <TabPane tab={
        <span>
         <img src={TablatLogo} alt="" title="Vertical Layout"/>
          
        </span>
      } key="1">
    <div  className="tablate-shapesr">
                        <div className="tablate-previewsr">
                            <div className="regis-form">
                    <Row gutter={24} style={{ background: formSetting.bgColor, color: formSetting.textColor, padding: '5px' }}>
                        <Col span={2}></Col>
                        <Col span={2}>
                            <div className="brand-logosr">
                                <LogoComponent style={{ width: '64px !important', height: '64px !important' }} src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} />
                                {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey}/> */}
                            </div>
                        </Col>
                        <Col span={12} style={{ alignSelf: 'center' }}>
                            <div className="regis-font" > {companyInfo.storeName} </div>
                        </Col>
                        <Col span={8} style={{ alignSelf: 'center' }}>
                            {getAddressInfoFragment(props.relationshipBillingAddress, companyInfo)}
                        </Col>
                    </Row>
                    </div>
                    <div className='main-container' style={{ paddingBottom: '80px' }}>

                        <div className="modal-header titr" style={{ height: 'auto' }}><div>{formSetting.formHeading || 'Registration'}</div>
                            {formSetting.subHeading ? <div style={{ fontSize: '12px', textAlign: 'center' }}>{formSetting.subHeading}</div> : ''}
                        </div>
                        <Form layout="vertical" onSubmit={save} >
                        <Row gutter={16}>
                            {formSetting.formDescription ?
                                <Col span={24}>
                                    {formSetting.formDescription ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>{ReactHtmlParser(formSetting.formDescription)}</div> : ''}
                                </Col> : ''}
                        </Row>
                        <Row gutter={22}>
                                
                                    {formSetting.membershipTypeDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='membershipType.text' /> {formSetting.membershipTypeRequired ? <span className="required">*</span> : ''} </span>}>
                                        <Dropdown
                                                items={['New' , 'Renew']}
                                                required={formSetting.membershipTypeRequired}
                                                placeholder={props.intl.formatMessage({ id: 'gender.text' })}
                                                onSelect={(selectedValue) => {
                                                    props.updateState({ membershipType: selectedValue });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                </Row>
                            <Row gutter={16}>
                                {formSetting.fatherFirstNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='register.fatherFirstName' />{formSetting.fatherFirstNameRequired ? <span className="required">*</span> : ''}</span>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                                )}
                                                type='text'
                                                maxLength={25}
                                                value={props.fatherFirstName}
                                                onChange={(e) => {
                                                    props.updateState({ fatherFirstName: e.target.value });
                                                }}
                                                required={formSetting.fatherFirstNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.fatherLastNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='register.fatherLastName' /> {formSetting.fatherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                                )}
                                                type='text'
                                                required={formSetting.fatherLastNameRequired}
                                                maxLength={25}
                                                value={props.fatherLastName}
                                                onChange={(e) => {
                                                    props.updateState({ fatherLastName: e.target.value });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.fatherMobileDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='father.mobile' /> {formSetting.fatherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Enter mobile number"
                                                type='text'
                                                //value={contact.lastName}
                                                required={formSetting.fatherMobileRequired}
                                                maxLength={15}
                                                value={props.fatherMobile}
                                                onChange={(e) => {
                                                    props.updateState({ fatherMobile: e.target.value });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.fatherEmailDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='father.email' /> {formSetting.fatherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Enter email'
                                                type='text'
                                                maxLength={45}
                                                value={props.fatherEmail}
                                                onChange={(e) => {
                                                    props.updateState({ fatherEmail: e.target.value });
                                                }}
                                                required={formSetting.fatherEmailRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.motherFirstNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.fName' /> {formSetting.motherFirstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                                )}
                                                type='text'
                                                maxLength={25}
                                                value={props.motherFirstName}
                                                onChange={(e) => {
                                                    props.updateState({ motherFirstName: e.target.value });
                                                }}
                                                required={formSetting.motherFirstNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.motherLastNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.lName' /> {formSetting.motherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                                )}
                                                type='text'
                                                maxLength={25}
                                                value={props.motherLastName}
                                                onChange={(e) => {
                                                    props.updateState({ motherLastName: e.target.value });
                                                }}
                                                required={formSetting.motherLastNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>


                            <Row gutter={16}>
                                {formSetting.motherMobileDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.mobile' /> {formSetting.motherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Enter mobile number"
                                                type='text'
                                                maxLength={15}
                                                value={props.motherMobile}
                                                onChange={(e) => {
                                                    props.updateState({ motherMobile: e.target.value });
                                                }}
                                                required={formSetting.motherMobileRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.motherEmailDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.email' /> {formSetting.motherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Enter email'
                                                type='text'
                                                maxLength={45}
                                                value={props.motherEmail}
                                                onChange={(e) => {
                                                    props.updateState({ motherEmail: e.target.value });
                                                }}
                                                required={formSetting.motherEmailRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                    </Row>

              

<Row gutter={16}>
                                    {formSetting.primaryContactForInvoicing ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='primaryContactForInvoicing.text' /><span className="required">*</span> </span>}>
                                            <Dropdown
                                                items={[{ name: 'Father', id: 'father' }, { name: 'Mother', id: 'mother' }]}
                                                placeholder={props.intl.formatMessage({ id: 'primaryContactForInvoicing.text' })}
                                                valueKeyName='name'
                                                optionKeyName='id'
                                                value={props.primaryContact}
                                                onSelect={
                                                    (optionValue) => {
                                                        props.updateState({ primaryContact: optionValue });
                                                    }
                                                }
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                          

                            <Row gutter={16}>
                                {formSetting.firstNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='contact.firstName' /> {formSetting.firstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="First Name"
                                                type='text'
                                                maxLength={45}
                                                value={props.firstName}
                                                onChange={(e) => {
                                                    props.updateState({ firstName: e.target.value });
                                                }}
                                                required={formSetting.firstNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.lastNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='contact.lastName' /> {formSetting.lastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Last Name'
                                                type='text'
                                                maxLength={15}
                                                value={props.lastName}
                                                onChange={(e) => {
                                                    props.updateState({ lastName: e.target.value });
                                                }}
                                                required={formSetting.lastNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.phoneDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='company.employee.phone' /> {formSetting.phoneRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Phone Number"
                                                type='text'
                                                maxLength={45}
                                                value={props.phoneNumber}
                                                onChange={(e) => {
                                                    props.updateState({ phoneNumber: e.target.value });
                                                }}
                                                required={formSetting.phoneRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.genderDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='gender.text' /> {formSetting.genderRequired ? <span className="required">*</span> : ''}</span>}>
                                            <Dropdown
                                                items={['Male', 'Female', 'Rather Not Say']}
                                                placeholder={props.intl.formatMessage({ id: 'gender.text' })}
                                                onSelect={(selectedValue) => {
                                                    props.updateState({ gender: selectedValue });
                                                }}
                                                required={formSetting.genderRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                    {formSetting.emailDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='email' /> {formSetting.emailRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Enter email'
                                                type='email'
                                                maxLength={45}
                                                value={props.email}
                                                onChange={(e) => {
                                                    props.updateState({ email: e.target.value });
                                                }}
                                                required={formSetting.emailRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                    {formSetting.maritalStatusDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='maritalStatus.text' /> {formSetting.maritalStatusRequired ? <span className="required">*</span> : ''}</span>}>
                                            <Dropdown
                                                items={['Single', 'Married', 'Divorced', 'Widowed']}
                                                placeholder={props.intl.formatMessage({ id: 'maritalStatus.text' })}
                                                onSelect={(selectedValue) => {
                                                    props.updateState({ maritalStatus: selectedValue });
                                                }}
                                                required={formSetting.maritalStatusRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>

                                
                                {formSetting.dobDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='dob.text' /> {formSetting.dobRequired ? <span className="required">*</span> : ''}</span>}>
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                key='dob'
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        dob: selectedDate
                                                    })
                                                }}
                                                size={0}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                    {formSetting.nationalityDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='nationality.text' /> </span>}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'nationality.text', defaultMessage: '' }
                                                )}
                                                type='text'
                                                required={formSetting.nationalityRequired}
                                                maxLength={25}
                                                value={props.nationality}
                                                onChange={(e) => {
                                                    props.updateState({ nationality: e.target.value });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>
                            
                <Row gutter={16}>
                {formSetting.spouseFirstNameDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.fName' /> {formSetting.spouseFirstNameRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder={intl.formatMessage(
                                    { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                                maxLength={25}
                                value={props.spouseFirstName}
                                onChange={(e) => {
                                  props.updateState({ spouseFirstName: e.target.value });
                                }}
                            required={formSetting.spouseFirstNameRequired}
                            />
                        </Form.Item>
                    </Col> : ''}
                    {formSetting.spouseLastNameDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.lName' /> {formSetting.spouseLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder={intl.formatMessage(
                                    { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                                maxLength={25}
                                value={props.spouseLastName}
                                onChange={(e) => {
                                  props.updateState({ spouseLastName: e.target.value });
                                }}
                            required={formSetting.spouseLastNameRequired}
                            />
                        </Form.Item>
                    </Col> : ''}
                    {formSetting.spouseMobileDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.mobile' /> {formSetting.spouseMobileRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder="Enter mobile number"
                                type='text'
                                maxLength={15}
                                value={props.spouseMobile}
                                onChange={(e) => {
                                  props.updateState({ spouseMobile: e.target.value });
                                }}
                            required={formSetting.spouseMobileRequired}
                            />
                        </Form.Item>
                    </Col>: ''}
                    {formSetting.spouseEmailDisplay ?
                    <Col span={12}>
                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouse.email' /> {formSetting.spouseEmailRequired ? <span className="required">*</span> : ''}</span>}>
                        <TextBox className='new-fed1'
                                placeholder='Enter email'
                                type='text'
                                maxLength={45}
                                value={props.spouseEmail}
                                onChange={(e) => {
                                  props.updateState({ spouseEmail: e.target.value });
                                }}
                            required={formSetting.spouseEmailRequired}
                            />
                        </Form.Item>
                    </Col>: ''}
                                {formSetting.spouseDOBDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='spouseDOB.text' /> {formSetting.spouseDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                key='spouseDOB'
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        spouseDOB: selectedDate
                                                    })
                                                }}
                                                size={0}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                </Row>

                            <Row gutter={16}>

                                {formSetting.emailDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='email' /> {formSetting.emailRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Enter email'
                                                type='email'
                                                maxLength={45}
                                                value={props.email}
                                                onChange={(e) => {
                                                    props.updateState({ email: e.target.value });
                                                }}
                                                required={formSetting.emailRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.dobDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='dob.text' /> {formSetting.dobRequired ? <span className="required">*</span> : ''}</span>}>
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                key='dob'
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        dob: selectedDate
                                                    })
                                                }}
                                                size={0}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>

                                {formSetting.fatherDOBDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='fatherDOB.text' /> {formSetting.fatherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                key='fatherDOB'
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        fatherDOB: selectedDate
                                                    })
                                                }}
                                                size={0}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.motherDOBDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='motherDOB.text' /> {formSetting.motherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                key='motherDOB'
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        motherDOB: selectedDate
                                                    })
                                                }}
                                                size={0}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>
                            <Row gutter={16}>
                                {formSetting.familyDoctorNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='familyDoctorName.text' /> {formSetting.familyDoctorNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Family Doctor Name"
                                                type='text'
                                                maxLength={45}
                                                value={props.familyDoctorName}
                                                onChange={(e) => {
                                                    props.updateState({ familyDoctorName: e.target.value });
                                                }}
                                                required={formSetting.familyDoctorNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.familyDoctorPhoneDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='familyDoctorPhone.text' /> {formSetting.familyDoctorPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Family Doctor Name'
                                                type='text'
                                                maxLength={15}
                                                value={props.familyDoctorPhone}
                                                onChange={(e) => {
                                                    props.updateState({ familyDoctorPhone: e.target.value });
                                                }}
                                                required={formSetting.familyDoctorPhoneRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.emergencyContactDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='setting.student.registration.form.emergencyContactName' /> {formSetting.emergencyContactRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Enter Number"
                                                type='text'
                                                maxLength={45}
                                                value={props.emergencyContactName}
                                                onChange={(e) => {
                                                    props.updateState({ emergencyContactName: e.target.value });
                                                }}
                                                required={formSetting.emergencyContactRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.emergencyPhoneDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='setting.student.registration.form.emergencyPhone' /> {formSetting.emergencyPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Enter Number'
                                                type='text'
                                                maxLength={15}
                                                value={props.emergencyPhone}
                                                onChange={(e) => {
                                                    props.updateState({ emergencyPhone: e.target.value });
                                                }}
                                                required={formSetting.emergencyPhoneRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.addressDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='signup.tab.address' /> {formSetting.addressRequired ? <span className="required">*</span> : ''}</span>}>
                                            <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                                <Col span={12}>
                                                    <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetName.placeholder' })}
                                                        type='text'
                                                        maxLength={45}
                                                        value={props.streetAddress1}
                                                        onChange={(e) => {
                                                            props.updateState({ streetAddress1: e.target.value });
                                                        }}
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetContd.placeholder' })}
                                                        type='text'
                                                        maxLength={45}
                                                        value={props.streetAddress2}
                                                        onChange={(e) => {
                                                            props.updateState({ streetAddress2: e.target.value });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                                <Col span={12}>
                                                    <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.city.placeholder' })}
                                                        type='text'
                                                        maxLength={45}
                                                        value={props.cityName}
                                                        onChange={(e) => {
                                                            props.updateState({ cityName: e.target.value });
                                                        }}
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <Dropdown
                                                        items={props.countries || []}
                                                        optionKeyName='countryId'
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.country.placeholder' })}
                                                        valueKeyName='countryName'
                                                        value={props.countryId}
                                                        onSelect={(selectedValue, option) => {
                                                            props.updateState({ countryId: selectedValue, countryName: option.name });
                                                            props.fetchStates(selectedValue)
                                                        }}
                                                        required={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                                <Col span={12}>
                                                    {(props.countryName === 'India' || props.countryName === 'United States' || props.countryName === 'United States (USA)') ?
                                                        <Dropdown
                                                            items={props.states || []}
                                                            optionKeyName='stateId'
                                                            placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                            valueKeyName='stateName'
                                                            onSelect={(selectedValue, option) => {
                                                                props.updateState({ stateName: option.name });
                                                            }}
                                                            required={true}
                                                        /> : <TextBox
                                                            placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                            type='text'
                                                            maxLength={45}
                                                            value={props.stateName}
                                                            onChange={(e) => {
                                                                props.updateState({ stateName: e.target.value });
                                                            }}
                                                            required={true}
                                                        />}
                                                </Col>
                                                <Col span={12}>
                                                    <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.zipCode.placeholder' })}
                                                        type='text'
                                                        maxLength={15}
                                                        value={props.zipCode}
                                                        onChange={(e) => {
                                                            props.updateState({ zipCode: e.target.value });
                                                        }}
                                                        required={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>
                                            <Row gutter={16}>

                                                {(formSetting.selectedMembershipOptions && formSetting.selectedMembershipOptions.length) ?
                                                    <Col span={12}>
                                                        <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='sidebar.menuItem.membershipOption' /> {formSetting.membershipOptionRequired ? <span className="required">*</span> : ''}</span>}>
                                                            <Radio.Group value={props.membershipOption} onChange={(e) => {
                                                                props.updateState({ membershipOption: e.target.value });
                                                            }} >
                                                                {
                                                                    (formSetting.selectedMembershipOptions || []).map((membershipOption, i) => {
                                                                        return (
                                                                            <div><Radio value={membershipOption.membershipOptionId}>{membershipOption.name} - {`$${membershipOption.termAndFees.fee}/${membershipOption.termAndFees.frequency} for ${membershipOption.termAndFees.duration} ${membershipOption.termAndFees.period}${membershipOption.termAndFees.type ? " - " + membershipOption.termAndFees.type : ''}`}</Radio></div>
                                                                        )
                                                                    })
                                                                }
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col> : ''}
                                            </Row>
                            {formSetting.collectStudentInfo ? <div>
                                <b className='label-emergency'><FormattedMessage id='studentDetail.text' /></b>
                                <Row gutter={16}>

                                    <Col span={24}>
                                        <div className='grid-container'>
                                            <CustomAgGrid
                                                columnDefs={columnDefs}
                                                rowData={props.studentList || []}
                                                //editType='fullRow'
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={(params) => {
                                                    params.api.sizeColumnsToFit();

                                                }}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}

                                                onGridReady={onGridReady}
                                            >
                                            </CustomAgGrid>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Button className="grey-button ant-btn ant-btn-default" onClick={
                                            () => {
                                                let newRowIndex = -1;
                                                gridApi.forEachNode((rowNode, index) => {
                                                    newRowIndex = index;
                                                });
                                                if (formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount + newRowIndex + 1)) {
                                                    return showToasterMessage({
                                                        messageType: 'error', description: props.intl.formatMessage({ id: 'no.more.seat.available.message' })
                                                    });
                                                }
                                                gridApi.stopEditing();
                                                gridApi.applyTransaction({ add: [{}] });
                                                gridApi.startEditingCell({
                                                    rowIndex: newRowIndex + 1,
                                                    colKey: "firstName"
                                                });
                                                gridApi.refreshCells({ force: true });
                                            }}><FormattedMessage id='add.student' defaultMessage='' /></Button>
                                    </Col>
                                </Row>

                                <br />
                            </div> : ''}
                            <Row gutter={16}>
                                {(props.isAllowParentToPaymentPlan && props.paymentPlan && props.paymentPlan.length) ?
                                    <Col span={12}>

                                        <Form.Item className='label-emergency  new-fed'>
                                            <span><FormattedMessage id='paymentType.ask.message' /> </span>
                                            <Radio.Group onChange={(e) => {
                                                props.updateState({ paymentType: e.target.value });
                                            }} value={props.paymentType} >
                                            <div> <Radio value='oneTime'> <FormattedMessage id='oneTimePayment.text' /></Radio></div>
                                            <div> <Radio value='installment'><FormattedMessage id='installment.text' /></Radio></div>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>
                            <Row gutter={16}>

                                {(props.paymentType && props.paymentType === 'installment') ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='paymentPlan.text' /> </span>}>

                                            <Select style={{ width: '100%' }} onChange={(val) => {
                                                props.updateState({ selectedPlan: val });
                                            }}>
                                                {
                                                    (props.paymentPlan || []).map((plan, i) => {
                                                        return (
                                                            <Option key={i} value={plan.period}>{plan.totalInstallment || 0} Installments {plan.period}</Option>
                                                        )
                                                    })
                                                }


                                            </Select>

                                            {/* <Radio.Group value={props.selectedPlan} onChange={(e) => {
                                                props.updateState({ selectedPlan: e.target.value });
                                            }} >
                                                {
                                                    (props.paymentPlan || []).map((plan, i) => {
                                                        return (
                                                            <div key={i}><Radio value={plan.period}>{plan.totalInstallment || 0} Installments {plan.period} </Radio></div>
                                                        )
                                                    })
                                                }
                                            </Radio.Group> */}
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            {
                                (formSetting.questionList || []).map((queObj, i) => {

                                    return (
                                        <div className="mb10 p10">
                                            <Row className="mb10">
                                                <Col span={20}><b>{queObj.questionText} {queObj.isRequired ? <span className="required">*</span> : ''}</b></Col>
                                            </Row>
                                            <Row>
                                                {(queObj.optionList && queObj.optionList.length) ?
                                                    <div>
                                                        {queObj.answerType === 'multiChoice' ?
                                                            <Checkbox.Group value={queObj.answers} onChange={(answers) => {
                                                                queObj.answers = answers;
                                                                let questionList = formSetting.questionList;
                                                                questionList.splice(i, 1, queObj);
                                                                formSetting.questionList = questionList;
                                                                props.updateState({ formSetting });
                                                            }}
                                                            >
                                                                {(queObj.optionList || []).map((optObj, j) => {
                                                                    return (<div><Checkbox value={optObj.optText}>{optObj.optText}</Checkbox></div>)
                                                                })
                                                                }

                                                            </Checkbox.Group> :
                                                            <Radio.Group value={queObj.answers} onChange={(e) => {
                                                                queObj.answers = e.target.value;
                                                                let questionList = formSetting.questionList;
                                                                questionList.splice(i, 1, queObj);
                                                                formSetting.questionList = questionList;
                                                                props.updateState({ formSetting });
                                                            }} >
                                                                {(queObj.optionList || []).map((optObj, k) => {
                                                                    return (<div>{optObj.optText ? <Radio value={optObj.optText}>{optObj.optText}</Radio> : ''}</div>)
                                                                })
                                                                }

                                                            </Radio.Group>
                                                        }
                                                    </div> : ''
                                                }
                                            </Row>
                                            {queObj.answerType === 'shortAnswer' ?
                                                <Row>
                                                    <Col span={20}>
                                                        <TextArea
                                                            style={{ width: '100%' }}
                                                            placeholder='Enter here'
                                                            type='text'
                                                            value={queObj.answers}
                                                            onChange={(e) => {
                                                                queObj.answers = e.target.value;
                                                                let questionList = formSetting.questionList;
                                                                questionList.splice(i, 1, queObj);
                                                                formSetting.questionList = questionList;
                                                                props.updateState({ formSetting });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row> : ''
                                            }
                                        </div>
                                    )
                                })
                            }
                            <br /><br /><br /><br />

                        </Form>

                    </div>
                    </div>
                    </div>
    </TabPane>
    <TabPane tab={
        <span>
         <img src={TablatVLogo} alt="" title="Horizontal Layout"/>
          
        </span>
      } key="2">
     <div  className="tablatev-shapesr">
                        <div className="tablatev-previewsr">
                            <div className="regis-form">
                    <Row gutter={24} style={{  background: formSetting.bgColor || '#31c0be', color: formSetting.textColor || '#ffffff', padding: '5px' }}>
                        <Col span={2}></Col>
                        <Col span={4}>
                            <div className="brand-logosr">
                                <LogoComponent style={{ width: '64px !important', height: '64px !important' }} src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} />
                                {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey}/> */}
                            </div>
                        </Col>
                        <Col span={12} style={{ alignSelf: 'center' }}>
                            <div style={{ fontSize: '22px' }}> {companyInfo.storeName} </div>
                        </Col>
                        <Col span={6} style={{ alignSelf: 'center' }}>
                            {getAddressInfoFragment(props.relationshipBillingAddress, companyInfo)}
                        </Col>
                    </Row>
                    </div>
                    <div className='main-container' >

                        <div className="modal-header titr" style={{ height: 'auto' }}><div>{formSetting.formHeading || 'Registration'}</div>
                            {formSetting.subHeading ? <div style={{ fontSize: '12px', textAlign: 'center' }}>{formSetting.subHeading}</div> : ''}
                        </div>
                        <Form layout="vertical" onSubmit={save} >
                        <Row gutter={16}>
                            {formSetting.formDescription ?
                                <Col span={24}>
                                    {formSetting.formDescription ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>{ReactHtmlParser(formSetting.formDescription)}</div> : ''}
                                </Col> : ''}
                        </Row>
                            <Row gutter={16}>
                                {formSetting.fatherFirstNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='register.fatherFirstName' />{formSetting.fatherFirstNameRequired ? <span className="required">*</span> : ''}</span>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                                )}
                                                type='text'
                                                maxLength={25}
                                                value={props.fatherFirstName}
                                                onChange={(e) => {
                                                    props.updateState({ fatherFirstName: e.target.value });
                                                }}
                                                required={formSetting.fatherFirstNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.fatherLastNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='register.fatherLastName' /> {formSetting.fatherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                                )}
                                                type='text'
                                                required={formSetting.fatherLastNameRequired}
                                                maxLength={25}
                                                value={props.fatherLastName}
                                                onChange={(e) => {
                                                    props.updateState({ fatherLastName: e.target.value });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.fatherMobileDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='father.mobile' /> {formSetting.fatherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Enter mobile number"
                                                type='text'
                                                //value={contact.lastName}
                                                required={formSetting.fatherMobileRequired}
                                                maxLength={15}
                                                value={props.fatherMobile}
                                                onChange={(e) => {
                                                    props.updateState({ fatherMobile: e.target.value });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.fatherEmailDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='father.email' /> {formSetting.fatherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Enter email'
                                                type='text'
                                                maxLength={45}
                                                value={props.fatherEmail}
                                                onChange={(e) => {
                                                    props.updateState({ fatherEmail: e.target.value });
                                                }}
                                                required={formSetting.fatherEmailRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.motherFirstNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.fName' /> {formSetting.motherFirstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                                )}
                                                type='text'
                                                maxLength={25}
                                                value={props.motherFirstName}
                                                onChange={(e) => {
                                                    props.updateState({ motherFirstName: e.target.value });
                                                }}
                                                required={formSetting.motherFirstNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.motherLastNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.lName' /> {formSetting.motherLastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder={intl.formatMessage(
                                                    { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                                )}
                                                type='text'
                                                maxLength={25}
                                                value={props.motherLastName}
                                                onChange={(e) => {
                                                    props.updateState({ motherLastName: e.target.value });
                                                }}
                                                required={formSetting.motherLastNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>


                            <Row gutter={16}>
                                {formSetting.motherMobileDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.mobile' /> {formSetting.motherMobileRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Enter mobile number"
                                                type='text'
                                                maxLength={15}
                                                value={props.motherMobile}
                                                onChange={(e) => {
                                                    props.updateState({ motherMobile: e.target.value });
                                                }}
                                                required={formSetting.motherMobileRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.motherEmailDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='mother.email' /> {formSetting.motherEmailRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Enter email'
                                                type='text'
                                                maxLength={45}
                                                value={props.motherEmail}
                                                onChange={(e) => {
                                                    props.updateState({ motherEmail: e.target.value });
                                                }}
                                                required={formSetting.motherEmailRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.emergencyContactDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='setting.student.registration.form.emergencyContactName' /> {formSetting.emergencyContactRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Enter Number"
                                                type='text'
                                                maxLength={45}
                                                value={props.emergencyContactName}
                                                onChange={(e) => {
                                                    props.updateState({ emergencyContactName: e.target.value });
                                                }}
                                                required={formSetting.emergencyContactRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.emergencyPhoneDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='setting.student.registration.form.emergencyPhone' /> {formSetting.emergencyPhoneRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Enter Number'
                                                type='text'
                                                maxLength={15}
                                                value={props.emergencyPhone}
                                                onChange={(e) => {
                                                    props.updateState({ emergencyPhone: e.target.value });
                                                }}
                                                required={formSetting.emergencyPhoneRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.firstNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='contact.firstName' /> {formSetting.firstNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="First Name"
                                                type='text'
                                                maxLength={45}
                                                value={props.firstName}
                                                onChange={(e) => {
                                                    props.updateState({ firstName: e.target.value });
                                                }}
                                                required={formSetting.firstNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.lastNameDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='contact.lastName' /> {formSetting.lastNameRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder='Last Name'
                                                type='text'
                                                maxLength={15}
                                                value={props.lastName}
                                                onChange={(e) => {
                                                    props.updateState({ lastName: e.target.value });
                                                }}
                                                required={formSetting.lastNameRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.phoneDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='company.employee.phone' /> {formSetting.phoneRequired ? <span className="required">*</span> : ''}</span>}>
                                            <TextBox className='new-fed1'
                                                placeholder="Phone Number"
                                                type='text'
                                                maxLength={45}
                                                value={props.phoneNumber}
                                                onChange={(e) => {
                                                    props.updateState({ phoneNumber: e.target.value });
                                                }}
                                                required={formSetting.phoneRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.genderDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='gender.text' /> {formSetting.genderRequired ? <span className="required">*</span> : ''}</span>}>
                                            <Dropdown
                                                items={['Male', 'Female', 'Rather Not Say']}
                                                placeholder={props.intl.formatMessage({ id: 'gender.text' })}
                                                onSelect={(selectedValue) => {
                                                    props.updateState({ gender: selectedValue });
                                                }}
                                                required={formSetting.genderRequired}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>


                            <Row gutter={16}>

                                {formSetting.fatherDOBDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency  new-fed' label={<span><FormattedMessage id='fatherDOB.text' /> {formSetting.fatherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                key='fatherDOB'
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        fatherDOB: selectedDate
                                                    })
                                                }}
                                                size={0}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                                {formSetting.motherDOBDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='motherDOB.text' /> {formSetting.motherDOBRequired ? <span className="required">*</span> : ''}</span>}>
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                key='motherDOB'
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        motherDOB: selectedDate
                                                    })
                                                }}
                                                size={0}
                                            />
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16}>
                                {formSetting.addressDisplay ?
                                    <Col span={12}>
                                        <Form.Item className='label-emergency new-fed' label={<span><FormattedMessage id='signup.tab.address' /> {formSetting.addressRequired ? <span className="required">*</span> : ''}</span>}>
                                            <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                                <Col span={12}>
                                                    <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetName.placeholder' })}
                                                        type='text'
                                                        maxLength={45}
                                                        value={props.streetAddress1}
                                                        onChange={(e) => {
                                                            props.updateState({ streetAddress1: e.target.value });
                                                        }}
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.streetContd.placeholder' })}
                                                        type='text'
                                                        maxLength={45}
                                                        value={props.streetAddress2}
                                                        onChange={(e) => {
                                                            props.updateState({ streetAddress2: e.target.value });
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                                <Col span={12}>
                                                    <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.city.placeholder' })}
                                                        type='text'
                                                        maxLength={45}
                                                        value={props.cityName}
                                                        onChange={(e) => {
                                                            props.updateState({ cityName: e.target.value });
                                                        }}
                                                        required={true}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <Dropdown
                                                        items={props.countries || []}
                                                        optionKeyName='countryId'
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.country.placeholder' })}
                                                        valueKeyName='countryName'
                                                        value={props.countryId}
                                                        onSelect={(selectedValue, option) => {
                                                            props.updateState({ countryId: selectedValue, countryName: option.name });
                                                            props.fetchStates(selectedValue)
                                                        }}
                                                        required={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row gutter={24} className='new-fed1' style={{ marginBottom: '15px' }}>
                                                <Col span={12}>
                                                    {(props.countryName === 'India' || props.countryName === 'United States' || props.countryName === 'United States (USA)') ?
                                                        <Dropdown
                                                            items={props.states || []}
                                                            optionKeyName='stateId'
                                                            placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                            valueKeyName='stateName'
                                                            onSelect={(selectedValue, option) => {
                                                                props.updateState({ stateName: option.name });
                                                            }}
                                                            required={true}
                                                        /> : <TextBox
                                                            placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.state.placeholder' })}
                                                            type='text'
                                                            maxLength={45}
                                                            value={props.stateName}
                                                            onChange={(e) => {
                                                                props.updateState({ stateName: e.target.value });
                                                            }}
                                                            required={true}
                                                        />}
                                                </Col>
                                                <Col span={12}>
                                                    <TextBox
                                                        placeholder={props.intl.formatMessage({ id: 'drawer.customer.address.zipCode.placeholder' })}
                                                        type='text'
                                                        maxLength={15}
                                                        value={props.zipCode}
                                                        onChange={(e) => {
                                                            props.updateState({ zipCode: e.target.value });
                                                        }}
                                                        required={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col> : ''}
                            </Row>
                            {formSetting.collectStudentInfo ? <div>
                                <b className='label-emergency'><FormattedMessage id='studentDetail.text' /></b>
                                <Row gutter={16}>

                                    <Col span={24}>
                                        <div className='grid-container'>
                                            <CustomAgGrid
                                                columnDefs={columnDefs}
                                                rowData={props.studentList || []}
                                                //editType='fullRow'
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={(params) => {
                                                    params.api.sizeColumnsToFit();

                                                }}
                                                defaultColDef={{
                                                    flex: 1,
                                                    autoHeight: true,
                                                    wrapText: true,
                                                }}

                                                onGridReady={onGridReady}
                                            >
                                            </CustomAgGrid>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Button className="grey-button ant-btn ant-btn-default" onClick={
                                            () => {
                                                let newRowIndex = -1;
                                                gridApi.forEachNode((rowNode, index) => {
                                                    newRowIndex = index;
                                                });
                                                if (formSetting.schoolLevelValidation && Number(formSetting.maxStudent || 0) <= (props.totalStudentCount + newRowIndex + 1)) {
                                                    return showToasterMessage({
                                                        messageType: 'error', description: props.intl.formatMessage({ id: 'no.more.seat.available.message' })
                                                    });
                                                }
                                                gridApi.stopEditing();
                                                gridApi.applyTransaction({ add: [{}] });
                                                gridApi.startEditingCell({
                                                    rowIndex: newRowIndex + 1,
                                                    colKey: "firstName"
                                                });
                                                gridApi.refreshCells({ force: true });
                                            }}><FormattedMessage id='add.student' defaultMessage='' /></Button>
                                    </Col>
                                </Row>

                                <br />
                            </div> : ''}

                            {
                                (formSetting.questionList || []).map((queObj, i) => {

                                    return (
                                        <div style={{ marginBottom: '10px', padding: '10px 10px 10px 10px' }}>
                                            <Row style={{ marginBottom: '10px' }}>
                                                <Col span={20}><b>{queObj.questionText} {queObj.isRequired ? <span className="required">*</span> : ''}</b></Col>
                                            </Row>
                                            <Row>
                                                {(queObj.optionList && queObj.optionList.length) ?
                                                    <div>
                                                        {queObj.answerType === 'multiChoice' ?
                                                            <Checkbox.Group value={queObj.answers} onChange={(answers) => {
                                                                queObj.answers = answers;
                                                                let questionList = formSetting.questionList;
                                                                questionList.splice(i, 1, queObj);
                                                                formSetting.questionList = questionList;
                                                                props.updateState({ formSetting });
                                                            }}
                                                            >
                                                                {(queObj.optionList || []).map((optObj, j) => {
                                                                    return (<div><Checkbox value={optObj.optText}>{optObj.optText}</Checkbox></div>)
                                                                })
                                                                }

                                                            </Checkbox.Group> :
                                                            <Radio.Group value={queObj.answers} onChange={(e) => {
                                                                queObj.answers = e.target.value;
                                                                let questionList = formSetting.questionList;
                                                                questionList.splice(i, 1, queObj);
                                                                formSetting.questionList = questionList;
                                                                props.updateState({ formSetting });
                                                            }} >
                                                                {(queObj.optionList || []).map((optObj, k) => {
                                                                    return (<div>{optObj.optText ? <Radio value={optObj.optText}>{optObj.optText}</Radio> : ''}</div>)
                                                                })
                                                                }

                                                            </Radio.Group>
                                                        }
                                                    </div> : ''
                                                }
                                            </Row>
                                            {queObj.answerType === 'shortAnswer' ?
                                                <Row>
                                                    <Col span={20}>
                                                        <TextArea
                                                            style={{ width: '100%' }}
                                                            placeholder='Enter here'
                                                            type='text'
                                                            value={queObj.answers}
                                                            onChange={(e) => {
                                                                queObj.answers = e.target.value;
                                                                let questionList = formSetting.questionList;
                                                                questionList.splice(i, 1, queObj);
                                                                formSetting.questionList = questionList;
                                                                props.updateState({ formSetting });
                                                            }}
                                                        />
                                                    </Col>
                                                </Row> : ''
                                            }
                                        </div>
                                    )
                                })
                            }
                            <br /><br /><br /><br />

                        </Form>

                    </div>
                    </div>
                    </div>
    </TabPane>
   
  </Tabs>

                    
                </div>
            </div></div>
    )
};

export default injectIntl(RegistrationFormSettingPreview);
