import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AttendanceComp from "../../../../components/drawer/schoolManagement/attendance";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { saveSchool } from '../../../schoolManagement/school/action';
import { getClassList } from "../../../schoolManagement/class/action";
import {saveAttendance } from './action';
import { getRegisteredMemberByClass } from '../../../schoolManagement/student/action';
import {  getSchoolAttendanceByClassId } from '../../../schoolManagement/attendence/action';

class SchoolDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: props.className,
      classId: props.classId,
      classSectionId: props.classSectionId
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 1000,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      formId: this.props.defaultSchoolForm,
      classId: this.props.classId
    };
    if(this.props.classId){
      this.props.getClassList(payload);
      this.props.getRegisteredMemberByClass(payload);
    }
  }


  render() {
    return (
      <AttendanceComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.common.userInfo,
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    classList: state.school.classList,
    studentList: state.school.studentList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getClassList,
      saveSchool,
      pushModalToStack,
      popModalFromStack,
      saveAttendance,
      getRegisteredMemberByClass,
      getSchoolAttendanceByClassId
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDrawer);
