import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { SMS_ACTION_LIST
  , COMMON_ACTIONS } from "../../../../../static/constants";
import { showToasterMessage } from "../../../../../utils";
const { lang } = require("../../../../../translations/" +(sessionStorage.getItem("language") || "en") +".js");

export const getTimeTableByClassSection = (payload) => {
    return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/timetable/getTimeTableByClass?relationshipId=${payload.relationshipId}&classId=${payload.classId}&sectionId=${payload.sectionId}`)
        .then((res) => {
          dispatch({
            type: SMS_ACTION_LIST.TIME_TABLE_LIST,
            data: res.data,
          });
        })
        .catch((err) => {
            showToasterMessage({
                messageType: "error",
                description:
                lang[((err.response || {}).data || {}).message] ||
                "Some error occurred",
            });
        });
    };
  };