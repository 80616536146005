import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CategoryListingComp from '../../../../../components/inventory/extras/category/Listing';
import { fetchDataIfNeeded } from '../../../../../utils';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../../actions/commonActions';
import { fetchCategory, addCategory, deleteCategory } from '../../../../drawer/inventory/action';
import { updateCategory } from './action';

class CategoryListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
    fetchDataIfNeeded('fetchCategory', 'productCategories', this.props, payload);
  }

  render() {
    
    return (
      <>
      
      <CategoryListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      {/* < this.state.studentListFilterDrawerVisible && Category {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> */}

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    productCategories: state.inventory.productCategories,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCategory,
  updateCategory,
  addCategory,
  deleteCategory,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListing);
