import axios from "axios";
import config from "../../../config/environmentConfig";
import { MEMBERSHIP_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const fetchOrgMembershipList = (payload) => {
  return (dispatch) => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgMembership/getMembers?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}`)
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: MEMBERSHIP_ACTION_LIST.ORG_MEMBERSHIP_LIST, data});
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getOrgMembershipCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgMembership/countMembers?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: MEMBERSHIP_ACTION_LIST.ORG_MEMBERSHIP_COUNT, data: res.data
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteOrgMembership= (payload, props) => {
  return dispatch => {
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgMembership/delete`,payload)
        .then(res => {
          showToasterMessage({messageType: 'success',  description: lang[res.data.message || 'common.delete.success'] });
          props.fetchOrgMembershipList({...payload, pageNumber: 0, pageSize:100});
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = MEMBERSHIP_ACTION_LIST.ORG_MEMBERSHIP_LIST;
          }
        })
    }
}
