import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BrodcastEmailDrawer from '../../../../../components/drawer/communication/account/brodcast-email';
import { brodcastTextEmail } from "./action";
import { getAllDG } from "../../../../communication/distribution-group/action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../../../actions/commonActions';
import { fetchDataIfNeeded } from "../../../../../utils";
import { Select } from 'antd';
import { getSubAccount } from '../../../../communication/account/action';
const { Option } = Select;

class BrodcastEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charCount: 0,
      message: props.previewUrl,
      to: [],
      dgNameOptionList: [],
      dgIds: [],
      totalCost: 0,
      totalSelectedRecipients: 0,
      selectedDGIds: [],
      selectedContacts: [],
      invalidContacts: [],
      templateContent: props.templateContent,
      previewOnlineUrl: props.previewOnlineUrl
    }
    let CKEDITOR = window.CKEDITOR;
    if (CKEDITOR) {
        CKEDITOR.on('instanceReady', function (ev) {
            document.getElementById(ev.editor.id + '_top').style.display = 'block';
            let edi = document.getElementById('cke_'+ ev.editor.name);
            if (edi) { edi.style.width = '';}
            ev.editor.on('focus', function (e) {
              document.getElementById(ev.editor.id + '_top').style.display = 'block';
             
          });
          ev.editor.on('blur', function (e) {
              let edi = document.getElementById(ev.editor.id + '_top');
              if (edi) { edi.style.display = 'block'; }
          });
        });
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
    };
    this.props.getSubAccount(payload);
   fetchDataIfNeeded("getAllDG", "dgList", this.props, payload);
  }

  componentWillReceiveProps(props) {
    if (props.dgList && props.dgList.length > 0 && this.state.dgNameOptionList.length === 0) {
      let name = [];
      let ids = [];
      for (let i = 0; i < props.dgList.length; i++) {
        ids.push(props.dgList[i].distributionGroupId);
        name.push(<Option value={props.dgList[i].distributionGroupId}>{props.dgList[i].dgName}</Option>);
      }
      this.setState({ dgNameOptionList: name, dgIds: ids });
    }
  }

  render() {
    return <BrodcastEmailDrawer
      {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}
      updateHeaderState={(data) => {
        this.props.updateState(data);
      }}
    >
    </BrodcastEmailDrawer>
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    dgList: state.communicationReducer.dgList,
    subAccount: state.communicationReducer.subAccount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllDG,
      brodcastTextEmail,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getSubAccount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BrodcastEmail);
