import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, TRANSACTION_TYPES } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const delegateApprover = (payload) => {
  let url = '/salesOrder/delegateApprover';
  switch(payload.txType){
    case TRANSACTION_TYPES.SALES_QUOTE: {
      url = '/salesQuotation/delegateApprover';
      break;
    }
    case TRANSACTION_TYPES.SALES_ORDER: {
      url = '/salesOrder/delegateApprover';
      break;
    }
    case TRANSACTION_TYPES.SALES_INVOICE: {
      url = '/customerInvoice/delegateApprover';
      break;
    }
    case TRANSACTION_TYPES.PURCHASE_REQUEST: {
      url = '/purchaseRequestRest/delegateApprover';
      break;
    }
    case TRANSACTION_TYPES.RFQ: {
      url = '/rfqRest/delegateApprover';
      break;
    }
    case TRANSACTION_TYPES.PURCHASE_ORDER: {
      url = '/purchaseOrder/delegateApprover';
      break;
    }
    case TRANSACTION_TYPES.PURCHASE_INVOICE: {
      url = '/supplierInvoice/delegateApprover';
      break;
    }
    case TRANSACTION_TYPES.PRICE_CHANGE: {
      url = '/inventoryItem/delegatePriceChangeApprover';
      break;
    }
    case TRANSACTION_TYPES.STOCK_CHANGE: {
      url = '/inventoryItem/delegateStockAdjustmentApprover';
      break;
    }
    default: {
      break;
    }
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${url}`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: res.data || 'Delegate request completed succcessfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}