import React, { Fragment, useState, forwardRef } from "react";
import { FormattedMessage } from 'react-intl';
import * as debounce from 'lodash.debounce';
import { Select, Divider } from "antd";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import * as getValue from 'lodash.get';
import './general.scss';
import { PERMISSION_VALUES, TX_STATUS, CONSTANTS } from '../../static/constants';
import { checkACLPermission, fixedDecimalNumber, getLocalDateFromUtcStr } from '../../utils';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const { Option } = Select;

export const Dropdown = (props) => {
    const [fetching, updateFetching] = useState(props.onSearch ? true : false);

    const getSortItems = (items, direction="asc", sortKey)=>{
        let sortedItems;

        if(!items.length){
            return items;
        }
        
        if (typeof items[0] === 'string') {
            sortedItems = items.slice().sort();
        } 
        else if(typeof items[0] === 'object' && sortKey) {
            sortedItems = items.slice().sort((a, b) => {
                return direction.toLocaleLowerCase() ==='asc' ? a[sortKey].localeCompare(b[sortKey]): b[sortKey].localeCompare(a[sortKey]) ;
            });
        }

        return sortedItems;
    }

    const items = props.sortItems ? getSortItems((props.items || []), props.sortDirection, (props.sortKey || props.valueKeyName)) : (props.items || []);
    let canAddNew = props.canAddNew || false;
    let canDelete = props.canDelete || false;
    let isSelectAll = props.isSelectAll || false;
    const permissions = props.permissions || {};
    const primaryPerm = permissions.primary;
    const operations = props.operations;
    const moduleName = props.moduleName;
    if (canAddNew && Array.isArray(operations) && operations.indexOf(PERMISSION_VALUES.CREATE) > -1) {
        canAddNew = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.CREATE);
    }
    if (canDelete && Array.isArray(operations) && operations.indexOf(PERMISSION_VALUES.DELETE) > -1) {
        canDelete = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.DELETE);
    }
    const deleteClickHandler = props.deleteClickHandler || (() => {
    });
    const [newTextObj, setNewTextObj] = useState({ textEntered: '', existsInData: false });

    setTimeout(() => {
        updateFetching(false);
    }, 20000);

    return (
        <Select
            getPopupContainer={trigger => trigger.parentNode}
            mode={props.mode || 'default'}
            showSearch={props.showSearch === false ? false : true}
            className={props.className || ' custom-dropdown-width cell-dropdown'}
            dropdownClassName={props.dropdownCustomStyle}
            onDeselect={props.onDeSelect ? props.onDeSelect : () => {
            }}
            onFocus={props.onFocus}
            placeholder={props.placeholder}
            clearIcon={<i className="fa fa-times-circle" onClick={props.onClear}/>}
            allowClear={props.allowClear}
            disabled={props.disabled}
            style={props.style}
            value={props.value || props.defaultValue}
            optionFilterProp='name'
            notFoundContent={fetching ? <Loading3QuartersOutlined className="spinnerIcon" spin /> : "No record(s) found"}
            onSelect={props.onSelect}
            onChange={props.onChange}
            onClick = {props.onClick}          
            open = {props.open}
            onSearch={(searchedText) => {
                updateFetching(true)
                props.onSearch ? debounce((searchedText) => {
                    props.onSearch(searchedText)
                }, 2000)(searchedText) : (searchedText => {
                    setTimeout(() => {
                        const searchedResults = items.filter(
                            x => (x[props.valueKeyName] || '').toString().toLowerCase().indexOf(searchedText.toString().toLowerCase()) > -1
                        );
                        if (!searchedResults.length) {
                            setNewTextObj({ textEntered: searchedText, existsInData: false });
                        } else {
                            setNewTextObj({ textEntered: searchedText, existsInData: true });
                        }
                    }, 2000)
                })(searchedText)
            }}
            dropdownRender={dropdown => {
                return (
                    <Fragment>
                        {
                            canAddNew ?
                                <Fragment>
                                    <div
                                        style={{ padding: "4px 8px", cursor: "pointer" }}
                                        onMouseDown={e => e.preventDefault()}
                                        onClick={() => {
                                            props.onAddNewClick(newTextObj)
                                        }}
                                        className='add-new'
                                    >
                                        <i className="fa fa-plus" />
                                        <FormattedMessage id='add' defaultMessage='' />
                                        {
                                            newTextObj.existsInData || !newTextObj.textEntered
                                                ? <FormattedMessage id='new' defaultMessage='' />
                                                : ` ${newTextObj.textEntered}`
                                        }
                                    </div>
                                    <Divider style={{ margin: "1px 0" }} />
                                </Fragment>
                                : ''
                        }
                        {
                            isSelectAll ?
                                <>
                                    <div
                                        style={{ padding: "4px 8px", cursor: "pointer" }}
                                        onMouseDown={e => e.preventDefault()}
                                        onClick={() => {
                                            props.selectAllClick()
                                        }}
                                        className='select-all'
                                    >
                                        <i className="fi fi-br-list-check mr5" /> Select All
                                    </div>
                                    <Divider style={{ margin: "1px 0" }} />
                                </>
                                : ''
                        }
                        {dropdown}
                    </Fragment>
                )
            }}
        > 
            {
                items.map && items.map((item) => {
                    const value = props.valueKeyName ? getValue(item, props.valueKeyName, '') : item;
                    const isFavourite = props.isFavouriteKeyName ? getValue(item, props.isFavouriteKeyName, '') : '';
                    const value2 = props.valueKeyName2 ? getValue(item, props.valueKeyName2, '') : '';
                    const lastNameKey = props.lastNameKey ? getValue(item, props.lastNameKey, '') : '';
                    const value3 = props.valueKeyName3 ? getValue(item, props.valueKeyName3, '') : '';
                    const value4 = props.valueKeyName4 ? getValue(item, props.valueKeyName4, '') : '';
                    const value5 = props.valueKeyName5 ? getValue(item, props.valueKeyName5, '') : '';
                    // const date1 = props.dateKeyName1 ? getMomentDateForUIReadOnly({date: new Date(getValue(item, props.dateKeyName1, format))}) : '';
                    // const date2 = props.dateKeyName2 ? getMomentDateForUIReadOnly({date: new Date(getValue(item, props.dateKeyName2, null)), format}) : '';
                    const date1 = props.dateKeyName1 ? getLocalDateFromUtcStr({date: getValue(item, props.dateKeyName1, format), format}) : '';
                    const date2 = props.dateKeyName1 ? getLocalDateFromUtcStr({date: getValue(item, props.dateKeyName2, format), format}) : '';
                    const addressLine1 = props.addressLineKey1 ? getValue(item, props.addressLineKey1, '') : '';
                    const addressLine2 = props.addressLineKey2 ? getValue(item, props.addressLineKey2, '') : '';
                    const addressLine3 = props.addressLineKey3 ? getValue(item, props.addressLineKey3, '') : '';
                    const addressLine4 = props.addressLineKey4 ? getValue(item, props.addressLineKey4, '') : '';
                    const hierarchyName = props.hierarchyNameKey ? getValue(item, props.hierarchyNameKey, '') : '';
                    const hierarchyLevel = props.hierarchyLevelKey ? getValue(item, props.hierarchyLevelKey, '') : '';
                    const salesOrderAmount = props.salesOrderAmountKey ? getValue(item, props.salesOrderAmountKey, '') : '';
                    //hierarchy code display in same row of with level
                    const hierarchyCode = props.hierarchyCodeKey ? getValue(item, props.hierarchyCodeKey, '') : '';
                    //hierarchy code display in new row
                    const hierarchyCodeLine = props.hierarchyCodeLineKey ? getValue(item, props.hierarchyCodeLineKey, '') : '';
                    const manager = props.managerKey ? getValue(item, props.managerKey, '') : '';
                    const amountValue = props.amountKey ? getValue(item, props.amountKey, '') : '';
                    const currencyCode = props.currencyCodeKey ? getValue(item, props.currencyCodeKey, '') || '' : '';
                    const customerName = props.customerName ? getValue(item, props.customerName, '') || '' : '';
                    const isLock = props.isLockKeyName ? getValue(item, props.isLockKeyName, '') : '';

                    const key = typeof item === 'object' ? getValue(item, props.optionKeyName, '') : item;
                    const fatherEmail = props.fatherEmailKey ? getValue(item, props.fatherEmailKey, '') : '';
                    const motherEmail = props.motherEmailKey ? getValue(item, props.motherEmailKey, '') : '';
                    const statusColor = props.statusColorKey ? getValue(item, props.statusColorKey, '') : '';
                    const projectId = props.projectIdKey ? getValue(item, props.projectIdKey, '') : '';
                    
                    return (
                        <Option 
                            key={key} 
                            name={value + (value2 ?   ' '+value2 : "")}
                            value={key} 
                            className={props.optionClassAddress + " " + props.optionClass || ' custom-dropdown-option'}>
                            <span className={props.optionClass || ' custom-dropdown-option pl5'} >
                            {statusColor ? <span style={{background:statusColor,color:statusColor, borderRadius: '2px', marginRight: '5px'}}>{'A'}</span> : ''}
                                {projectId ? ` ${projectId}-` : ''}{value}
                                {lastNameKey ? ` ${lastNameKey}` : ''}
                                {value2 ? ` (${value2})` : ''}
                                {value3 ? ` (Bal. ${props.currencyCode + " "} ${fixedDecimalNumber(value3)})` : ''}
                                {value4 ? ` ${" " + value4} ` : ''}
                                {value5 ? `${" " + value5}` : ''}
                                {amountValue ? ` (Bal. ${currencyCode + " "} ${fixedDecimalNumber(amountValue)})` : ''}
                                {date1 ? ` (${date1})` : ''}
                                
                                {salesOrderAmount ? <br /> : ""}
                                {salesOrderAmount ? ` Amt: ${currencyCode + " "} ${fixedDecimalNumber(salesOrderAmount)}` : ''}
                                {addressLine1 ? <br /> : ""}
                                {addressLine1 ? `${" " + addressLine1}` : ''}
                                {addressLine2 ? <br /> : ""}
                                {addressLine2 ? `${" " + addressLine2}` : ''}
                                {addressLine3 ? <br /> : ""}
                                {addressLine3 ? `${" " + addressLine3}` : ''}
                                {addressLine4 ? `${",  " + addressLine4}` : ''}
                                {date2 ? ` (${date2})` : ''}
                                {hierarchyName ? <br /> : ""}
                                {hierarchyName  ? ` Hierarchy Name: ${hierarchyName}` : ''}
                                {(hierarchyLevel || hierarchyCode)? <br /> : ""}
                                {(hierarchyLevel || hierarchyCode)?  (hierarchyLevel ? ` Hierarchy Level: ${hierarchyLevel}` : '')+ (hierarchyCode ? ` Hierarchy code: ${hierarchyCode}` : '') : ''}
                                {hierarchyCodeLine ? <br /> : ""}
                                {hierarchyCodeLine ?  ` Hierarchy Code: ${hierarchyCodeLine}` : ''}
                                {manager ? <br /> : ""}
                                {manager ?  ` Manager: ${manager}` : ''}
                                {customerName ? <br /> : ""}
                                {customerName  ? `${customerName}` : ''}
                                {fatherEmail ? <br /> : ""}
                                {fatherEmail  ? `Father Email ${fatherEmail}` : ''}
                                {motherEmail ? <br /> : ""}
                                {motherEmail  ? `Mother Email ${motherEmail}` : ''}
                               
                            </span>
                            {
                                canDelete ?
                                    (value === TX_STATUS.OPEN ? <i className='fa fa-lock option-delete-icon' style={{ float: 'right', marginTop: 5 }} />
                                        :
                                        isLock ?
                                            <i className='fa fa-lock option-delete-icon' style={{ float: 'right', marginTop: 5 }} /> :
                                            <i
                                                className='fa fa-trash option-delete-icon'
                                                onClick={e => {
                                                    const payload = {
                                                        id: key,
                                                        text: value,
                                                    };
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    deleteClickHandler(payload);
                                                }}
                                                style={{ float: 'right', marginTop: 5 }}
                                            />)
                                    : ''
                            }

                            {
                                isFavourite ? <span className="favourite-mark"><i className="fa fa-star"/></span> : ''
                            }
                        </Option>
                    )
                })
            }
        </Select>
    );
}

export const DropdownRef = forwardRef(Dropdown);
