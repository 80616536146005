export const EMAIL_CUSTOM_CONFIGURATION = {
    baseFloatZIndex: 1000001,
    extraPlugins: 'wordcount,notification,attachment,confighelper',
    toolbar: [
        { name: 'document', groups: ['mode', 'document', 'doctools'], items: ['Preview'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', 'Link'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker'], items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
        
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-'] },
        { name: 'insert', items: ['Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Attachment'] },
        '/',
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
        { name: 'others', items: ['-'] },
        { name: 'about', items: ['About'] }
    ],
    wordcount: {

        // Whether or not you want to show the Paragraphs Count
        showParagraphs: true,

        // Whether or not you want to show the Word Count
        //showWordCount: true,

        // Whether or not you want to show the Char Count
        showCharCount: true,

        // Whether or not you want to count Spaces as Chars
        countSpacesAsChars: false,

        // Whether or not to include Html chars in the Char Count
        countHTML: true,

        // Whether or not to include Line Breaks in the Char Count
        countLineBreaks: false,

        // Maximum allowed Word Count, -1 is default for unlimited
        maxWordCount: -1,

        // Maximum allowed Char Count, -1 is default for unlimited
        maxCharCount: -1,

        // Maximum allowed Paragraphs Count, -1 is default for unlimited
        maxParagraphs: -1,

        // How long to show the 'paste' warning, 0 is default for not auto-closing the notification
        pasteWarningDuration: 0,


        // Add filter to add or remove element before counting (see CKEDITOR.htmlParser.filter), Default value : null (no filter)
        // filter: new CKEDITOR.htmlParser.filter({
        //     elements: {
        //         div: function( element ) {
        //             if(element.attributes.class == 'mediaembed') {
        //                 return false;
        //             }
        //         }
        //     }
        // })
    }
};

export const CUSTOM_CONFIGURATION_COMMENT = {
    baseFloatZIndex: 1000001,
    extraPlugins: 'wordcount,notification,attachment,confighelper',
    toolbar: [
        { name: 'document', groups: ['mode', 'document', 'doctools'], items: ['Preview'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker'], items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
        '/'

    ],
    wordcount: {

        // Whether or not you want to show the Paragraphs Count
        showParagraphs: true,

        // Whether or not you want to show the Word Count
        //showWordCount: true,

        // Whether or not you want to show the Char Count
        showCharCount: true,

        // Whether or not you want to count Spaces as Chars
        countSpacesAsChars: false,

        // Whether or not to include Html chars in the Char Count
        countHTML: true,

        // Whether or not to include Line Breaks in the Char Count
        countLineBreaks: false,

        // Maximum allowed Word Count, -1 is default for unlimited
        maxWordCount: -1,

        // Maximum allowed Char Count, -1 is default for unlimited
        maxCharCount: -1,

        // Maximum allowed Paragraphs Count, -1 is default for unlimited
        maxParagraphs: -1,

        // How long to show the 'paste' warning, 0 is default for not auto-closing the notification
        pasteWarningDuration: 0,


        // Add filter to add or remove element before counting (see CKEDITOR.htmlParser.filter), Default value : null (no filter)
        // filter: new CKEDITOR.htmlParser.filter({
        //     elements: {
        //         div: function( element ) {
        //             if(element.attributes.class == 'mediaembed') {
        //                 return false;
        //             }
        //         }
        //     }
        // })
    }
};




export const CUSTOM_CONFIGURATION = {
    baseFloatZIndex: 1000001,
    extraPlugins: 'wordcount,notification,attachment,confighelper',
    toolbar: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
      //  { name: 'clipboard', groups: ['clipboard'], items: ['Cut', 'Copy', 'Paste'] },
        //{ name: 'editing', items: [ 'Scayt'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
        
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align',], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
        { name: 'insert', items: ['Table', 'HorizontalRule', 'SpecialChar',] },
        '/',
        { name: 'styles', items: [  'Font', 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'paragraph', groups: ['align', 'bidi'], items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Blockquote', 'Maximize', 'Link'] },
        '/',
    ],
    wordcount: {
    }
};



export const CUSTOM_CONFIGURATION_FOOTER = {
    baseFloatZIndex: 1000001,
    extraPlugins: 'wordcount,notification,attachment,confighelper',
    toolbar: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
      //  { name: 'clipboard', groups: ['clipboard'], items: ['Cut', 'Copy', 'Paste'] },
        //{ name: 'editing', items: [ 'Scayt'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align',], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
        { name: 'insert', items: ['Table', 'HorizontalRule', 'SpecialChar',] },
        { name: 'styles', items: [  'Font', 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'paragraph', groups: ['align', 'bidi'], items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Blockquote'] },
        '/',
    ],
    wordcount: {

        // Whether or not you want to show the Paragraphs Count
        showParagraphs: true,

        // Whether or not you want to show the Word Count
        //showWordCount: true,

        // Whether or not you want to show the Char Count
        showCharCount: true,

        // Whether or not you want to count Spaces as Chars
        countSpacesAsChars: false,

        // Whether or not to include Html chars in the Char Count
        countHTML: true,

        // Whether or not to include Line Breaks in the Char Count
        countLineBreaks: false,

        // Maximum allowed Word Count, -1 is default for unlimited
        maxWordCount: -1,

        // Maximum allowed Char Count, -1 is default for unlimited
        maxCharCount: -1,

        // Maximum allowed Paragraphs Count, -1 is default for unlimited
        maxParagraphs: -1,

        // How long to show the 'paste' warning, 0 is default for not auto-closing the notification
        pasteWarningDuration: 0,


        // Add filter to add or remove element before counting (see CKEDITOR.htmlParser.filter), Default value : null (no filter)
        // filter: new CKEDITOR.htmlParser.filter({
        //     elements: {
        //         div: function( element ) {
        //             if(element.attributes.class == 'mediaembed') {
        //                 return false;
        //             }
        //         }
        //     }
        // })
    }
};






export const CUSTOM_DESCRIPTION_CONFIGURATION = {
    baseFloatZIndex: 1000001,
    toolbar: [
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline'] },
        { name: 'colors', items: ['TextColor', 'BGColor', 'FontSize'] },
    ],
};

export const CUSTOM_SUBJECT_CONFIGURATION = {
    baseFloatZIndex: 1000001,
    toolbar: [ ],
};



export const CUSTOM_CONFIGURATION_FULL_PAGE = {
    baseFloatZIndex: 1000001,
    extraPlugins: 'wordcount,notification,attachment',
   // codeSnippet_theme: 'monokai_sublime',
    toolbar: [
        { name: 'document', groups: ['mode', 'document', 'doctools'], items: ['Preview'] },
        { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo'] },
        { name: 'editing', groups: ['find', 'selection', 'spellchecker'], items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-'] },
        { name: 'insert', items: ['Image','Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Attachment'] },
        '/',
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['Maximize', 'ShowBlocks'] },
        { name: 'others', items: ['-'] },
        { name: 'about', items: ['About'] },
        { name: 'document', items: ['Source'] },
    ],
    wordcount: {
        showParagraphs: true,
        showCharCount: true,
        countSpacesAsChars: false,
        countHTML: true,
        countLineBreaks: false,
        maxWordCount: 50,
        maxCharCount: 10000,
        maxParagraphs: -1,
        pasteWarningDuration: 0,
    }
};

export const FULL_CONFIGURATION = {
    toolbar: ['undo',
        'redo',
        'bold',
        'italic',
        'blockQuote',
        'ckfinder',
        'imageTextAlternative',
        'imageUpload',
        'heading',
        'imageStyle:full',
        'imageStyle:side',
        'link',
        'numberedList',
        'bulletedList',
        'mediaEmbed',
        'insertTable',
        'tableColumn',
        'tableRow',
        'mergeTableCells']
};

export const CUSTOM_CONFIGURATION_FORM = {
    baseFloatZIndex: 1000001,
    extraPlugins: 'wordcount,notification,attachment,confighelper,link',
    toolbar: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline'] },
        { name: 'styles', items: [ 'Font', 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'paragraph', groups: ['align', 'bidi'], items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Maximize','Link'] },
    ],
    wordcount: {
    }
};

export const CUSTOM_CONFIGURATION_FORM2 = {
    baseFloatZIndex: 1000001,
    extraPlugins: 'wordcount,notification,attachment,confighelper,link',
    toolbar: [
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline'] },
        { name: 'styles', items: ['FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'paragraph', groups: ['align', 'bidi'], items: [ 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Maximize','Link'] },
    ],
    wordcount: {
    }
};

export const EMAIL_CUSTOM_CONFIGURATION2 = {
    baseFloatZIndex: 1000001,
    extraPlugins: 'wordcount,notification,attachment,confighelper,link',
    toolbar: [
        { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'CopyFormatting', 'RemoveFormat'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-'] },
        '/',
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['Maximize', 'Link'] },
    ],
    wordcount: {

        // Whether or not you want to show the Paragraphs Count
        showParagraphs: true,

        // Whether or not you want to show the Word Count
        //showWordCount: true,

        // Whether or not you want to show the Char Count
        showCharCount: true,

        // Whether or not you want to count Spaces as Chars
        countSpacesAsChars: false,

        // Whether or not to include Html chars in the Char Count
        countHTML: true,

        // Whether or not to include Line Breaks in the Char Count
        countLineBreaks: false,

        // Maximum allowed Word Count, -1 is default for unlimited
        maxWordCount: -1,

        // Maximum allowed Char Count, -1 is default for unlimited
        maxCharCount: -1,

        // Maximum allowed Paragraphs Count, -1 is default for unlimited
        maxParagraphs: -1,

        // How long to show the 'paste' warning, 0 is default for not auto-closing the notification
        pasteWarningDuration: 0,


        // Add filter to add or remove element before counting (see CKEDITOR.htmlParser.filter), Default value : null (no filter)
        // filter: new CKEDITOR.htmlParser.filter({
        //     elements: {
        //         div: function( element ) {
        //             if(element.attributes.class == 'mediaembed') {
        //                 return false;
        //             }
        //         }
        //     }
        // })
    }
};


export const EMAIL_BODY_CONFIGURATION = {
    baseFloatZIndex: 1000001,
    extraPlugins: 'wordcount,notification,attachment,confighelper,link',
    toolbar: [
        { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo'] },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'CopyFormatting', 'RemoveFormat'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-'] },
        '/',
        { name: 'styles', items: [ 'FontSize'] },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        { name: 'tools', items: ['Maximize', 'Link'] },
    ],
    wordcount: {

        // Whether or not you want to show the Paragraphs Count
        showParagraphs: true,

        // Whether or not you want to show the Word Count
        //showWordCount: true,

        // Whether or not you want to show the Char Count
        showCharCount: true,

        // Whether or not you want to count Spaces as Chars
        countSpacesAsChars: false,

        // Whether or not to include Html chars in the Char Count
        countHTML: true,

        // Whether or not to include Line Breaks in the Char Count
        countLineBreaks: false,

        // Maximum allowed Word Count, -1 is default for unlimited
        maxWordCount: -1,

        // Maximum allowed Char Count, -1 is default for unlimited
        maxCharCount: -1,

        // Maximum allowed Paragraphs Count, -1 is default for unlimited
        maxParagraphs: -1,

        // How long to show the 'paste' warning, 0 is default for not auto-closing the notification
        pasteWarningDuration: 0,


        // Add filter to add or remove element before counting (see CKEDITOR.htmlParser.filter), Default value : null (no filter)
        // filter: new CKEDITOR.htmlParser.filter({
        //     elements: {
        //         div: function( element ) {
        //             if(element.attributes.class == 'mediaembed') {
        //                 return false;
        //             }
        //         }
        //     }
        // })
    }
};
