import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { injectIntl } from 'react-intl';
import "./productCard.scss"
import { getAWSFileURL } from '../../../utils';
import config from "../../../config/environmentConfig"
function ProductCard(props) {
  const { productDetails,item } = props
  const imageObj = item?.inventoryItemVariantImageList?.filter((item)=>item.isFeatureImage === 1)
  const imageUrl = getAWSFileURL(imageObj?.length ? imageObj[0].image:null, config.BUCKET_NAME.BO_PRODUCT_IMAGE)
  return (
    <div className='mainCard-body'>
      <div className='center-div'>
        <div className='img-container'><img src={imageUrl} alt="" /></div>
        <div className='details-container'>

          <h2>Product Name - {productDetails.itemName}</h2>
          <div>

            SKU No. - {productDetails.sku}
          </div>
          <br />

          {productDetails.currentStock ?<div>Stock Count - {productDetails.currentStock}</div>:""}
          {productDetails.warehouseName ?<div>Warehouse - {productDetails.warehouseName}</div>:""}
          {/* <div>Store</div> */}
          {productDetails.brandName ?<div>Brand Name - {productDetails.brandName}</div>:""}
          {props.item?.attributeValue1 && props.item?.attributeName1 === "Colour" ? <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}><span>{props.item?.attributeName1}</span> - <div style={{ backgroundColor: `${props.item?.attributeValue1}`, height: "12px", width: "16px", borderRadius: "4px" }}></div></div> : <div>{props.item?.attributeName1} - {props.item?.attributeValue1}</div>}
          {props.item?.attributeValue2 && props.item?.attributeName2 === "Colour" ? <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}><span>{props.item?.attributeName2}</span> - <div style={{ backgroundColor: `${props.item?.attributeValue2}`, height: "12px", width: "16px", borderRadius: "4px" }}></div></div> : <div>{props.item?.attributeName2} - {props.item?.attributeValue2}</div>}
          {props.item?.attributeValue3 && props.item?.attributeName3 === "Colour" ? <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}><span>{props.item?.attributeName3}</span> - <div style={{ backgroundColor: `${props.item?.attributeValue3}`, height: "12px", width: "16px", borderRadius: "4px" }}></div></div> : <div>{props.item?.attributeName3} - {props.item?.attributeValue3}</div>}







        {/* <div className=''>xyz.com@hai</div> */}
        </div>
      </div>
    </div>
  );

}

export default injectIntl(ProductCard);
