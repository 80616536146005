import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { TAXES_SETTINGS_ACTION_LIST } from '../../../../static/constants';
//import { showToasterMessage } from '../../../../utils';
// const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getAllMarketplaceStateTaxRate = (payload) => {
    return dispatch => {
        return axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/marketplaceStateTaxRate/getAll?relationshipId=${payload.relationshipId}&marketplaceTaxCountryId=${payload.marketplaceTaxCountryId}`)
            .then(res => {
                const data = {
                    marketplaceTaxCountryId: payload.marketplaceTaxCountryId,
                    list: res.data
                }
                dispatch({ type: TAXES_SETTINGS_ACTION_LIST.MARKETPLACE_COUNTRY_STATES, data: data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}

export const saveUpdateMarketplaceStateTax = (payload, props) => {

    return dispatch => {
        // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/marketplaceStateTaxRate/saveOrUpdate`, payload)
            .then(res => {
                props.getAllMarketplaceStateTaxRate(props);
            })
            .catch((err = {}) => {

            })
    }
}

export const deleteMarketplaceStateTaxRate = (payload, props) => {

    return dispatch => {
        // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/marketplaceStateTaxRate/delete`, payload)
            .then(res => {
                props.getAllMarketplaceStateTaxRate(props);
            })
            .catch((err = {}) => {

            })
    }
}

export const getCountryStates = (payload) => {
    return dispatch => {
        return axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/states/getStates?countryId=${payload.countryId}`)
            .then(res => {
                const data = {
                    countryId: payload.countryId,
                    list: res.data
                }
                dispatch({ type: TAXES_SETTINGS_ACTION_LIST.COUNTRY_STATES, data: data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch(err => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
    }
}