import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupplierExpenseListingComponent from '../../../../components/supplier/expense/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchSupplierExpenses, getSupplierExpensesCount, resetPaginatedData } from './action';

import { showModal, pushModalToStack, hideModal, popModalFromStack} from '../../../../actions/commonActions';

class SupplierExpenseListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pageSize: 100,
          pageNumber: 1,
          isDataRendered: false
        };
    }

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true
        });
        const companyInfo = this.props.companyInfo || {};
        const payload = {
          relationshipId: companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 100
        };
        fetchPaginationDataIfNeeded('fetchSupplierExpenses', 'supplierExpenseList', this.props, payload);
        fetchDataIfNeeded('getSupplierExpensesCount', 'supplierExpenseCount', this.props, payload);
      }

    render() {
        return (
            <SupplierExpenseListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}/>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      supplierExpenseList:state.supplierExpense.supplierExpenseList,
      supplierExpenseCount:state.supplierExpense.supplierExpenseCount,
      isLoading: state.common.isLoading  
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSupplierExpenses,
    getSupplierExpensesCount,
    resetPaginatedData, 
    showModal,
    popModalFromStack,
    pushModalToStack,
    hideModal,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierExpenseListing);
