import React, { Fragment } from "react";
import { Skeleton, Tooltip,  Dropdown, Menu, Row, Col } from 'antd';
import { CONSTANTS } from '../../../static/constants';
import { injectIntl, FormattedMessage } from 'react-intl';
import PageBreadcrumb from "../../PageBreadcrumb";
import { CustomAgGrid } from "../../general/CustomAgGrid";
import { getMomentDateForUIReadOnly } from "../../../utils";



const agGridStyle = {
    height: '100%',
    width: '100%'
};

const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
}
const getRowHeight = (params) => {
    return 30;
}

const CostCenterComp = (props) => {
    const {  companyInfo } = props;
    const allCostCenters = (companyInfo || {}).costCenterList;

    const menu = (
        <Menu>
            <Menu.Item key="upload_customer">
                <i className="fa fa-file-pdf-o" />
                &nbsp;Download PDF
            </Menu.Item>
        </Menu>
    );

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <Tooltip placement="top" title={props.intl.formatMessage(
                    {
                      id: 'costCenterName.text',
                      defaultMessage: ''
                    }
                  )}>
                    <div className='table-header-column-container'>
                        <span className='table-header-column'>
                        <FormattedMessage id='costCenterName.text' defaultMessage=' ' />
                            </span>
                    </div>
                </Tooltip>;
            },
            field: "costCenterName",
            resizable: true,
            rowDrag: true
        },
        {
            headerComponentFramework: () => {
                return <Tooltip placement="top" title={props.intl.formatMessage(
                    {
                      id: 'tooltip.fyEndDate',
                      defaultMessage: ''
                    }
                  )}>
                    <div className='table-header-column-container'>
                        <span className='table-header-column'> <FormattedMessage id='costCenterNumber.text' defaultMessage=' ' /></span>
                    </div>
                </Tooltip>;
            },
            field: "costCenterNumber"
        },
        {
            headerComponentFramework: () => {
                return <Tooltip placement="top" title={props.intl.formatMessage(
                    {
                      id: 'tooltip.department',
                      defaultMessage: ''
                    }
                  )}>
                    <div className='table-header-column-container'>
                        <span className='table-header-column'> <FormattedMessage id='department.text' defaultMessage='' /></span>
                    </div>
                </Tooltip>;
            },
            field: "department",
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <Tooltip placement="top" title={props.intl.formatMessage(
                    {
                      id: 'common.dateCreated',
                      defaultMessage: ''
                    }
                  )}>
                    <div className='table-header-column-container'>
                        <span className='table-header-column'><FormattedMessage id='common.dateCreated' defaultMessage='' /></span>
                    </div>
                </Tooltip>;
            },
            field: "dateCreated",
            resizable: true,
            valueFormatter: (data) => {
                data.value = data.value ? getMomentDateForUIReadOnly({date: data.value, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : '';
                return data.value;
            }
        }
    ]

    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        {
            name: <FormattedMessage id='finance.text' defaultMessage='' />
        }, {
            name: <FormattedMessage id='financeYear.text' defaultMessage='' />
        }
    ];


    return (

        <div className="ledger-details-container">
            <div className="ptb10">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="ledger-details">
                <Fragment>
                    <div className='ledger-account-container'>
                        <Skeleton loading={props.isLoading}
                            active
                            paragraph={{ rows: 12 }}
                        >

                            <Row className="search-container" type='flex' justify='center'>
                            <Col className='align-end' span={24}>
                            <Dropdown overlay={menu}>
                                <div className="action-dropdown-btn">
                                    <button>
                                        <i className="fa fa-user" />New Cost Center
                                    <i className="fa fa-angle-down ml5" />
                                    </button>
                                </div>
                            </Dropdown>
                        </Col>
                            </Row>
                            <div className='agGridWrapper'>
                                <div className="ag-theme-balham" style={agGridStyle} >
                                    <CustomAgGrid
                                        columnDefs={columnDefs}
                                        rowData={allCostCenters || []}
                                        rowDragManaged={true}
                                        domLayout={"autoHeight"}
                                        animateRows={true}
                                        onGridReady={onFirstDataRendered}
                                        getRowHeight={getRowHeight}
                                    />
                                </div>
                            </div>
                        </Skeleton>
                    </div>
                </Fragment>

            </div>
        </div>

    )
};

export default injectIntl(CostCenterComp);