import React from 'react';
import HeaderAction from './HeaderAction'
import './../../../../assets/stylesheets/common/invoice.scss';
// import PageBreadcrumb from "../../../PageBreadcrumb";
import DebitMemoDetails from "./DebitMemoDetails";
import ProductDetails from "./ProductDetails";
import { Divider } from 'antd';
// import { FormattedMessage } from 'react-intl';
const PurchaseInvoiceComponent = (props) => {


  // const breadCrumbList = [
  //   {
  //     name: <FormattedMessage id='common.purchase' defaultMessage='' /> ,
  // },
  // {
  //     name: <FormattedMessage id='sidebar.menuItem.debitMemo' defaultMessage='' />,
  // }
  // ];
  
  return (
    <>
    <div className="txn-header">
            <HeaderAction {...props} />
        </div>

       <div className='txn-body'>

        <div className="txn-details-container">
        <DebitMemoDetails {...props} />
        </div>

        <Divider style={{margin: '0 0 10px 0px'}}/>

        <div className="product-details-container">
          <ProductDetails {...props} />
        </div>
       </div>
     

    </>
  );
}


export default PurchaseInvoiceComponent;