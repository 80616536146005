import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, PACKAGE_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAllPackage = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  let uri = null;
  switch (payload.packageStatus) {
    case 'all': {
      uri = 'getAllPackages';
      break;
    }
    case 'Not Shipped': {
      uri = 'getNotShippedPackages';
      break;
    }
    case 'Shipped': {
      uri = 'getShippedPackages';
      break;
    }
    case 'Delivered': {
      uri = 'getDeliveredPackages';
      break;
    }
    default:
      break;
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    dispatch({ type: PACKAGE_ACTION_LIST.PACKAGE_LIST_PAGE_RESET });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrderPackage/${uri || 'getAllPackages'}?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PACKAGE_ACTION_LIST.PACKAGE_LIST, data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PACKAGE_ACTION_LIST.PACKAGE_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}
export const getSOPackageCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  let uri = null;
  switch (payload.packageStatus) {
    case 'all': {
      uri = 'countAllPackages';
      break;
    }
    case 'Not Shipped': {
      uri = 'countNotShippedPackages';
      break;
    }
    case 'Shipped': {
      uri = 'countShippedPackages';
      break;
    }
    case 'Delivered': {
      uri = 'countDeliveredPackages';
      break;
    }
    default:
      break;
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrderPackage/${uri || 'countAllPackages'}?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: PACKAGE_ACTION_LIST.PACKAGE_COUNT, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
        } else {
          err.actionToCall = PACKAGE_ACTION_LIST.PACKAGE_COUNT;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: PACKAGE_ACTION_LIST.PACKAGE_LIST_PAGE_RESET });
  }
}


export const deletePackage = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrderPackage/deletePackage`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.getSOPackageCount(props);
        props.fetchAllPackage(props);
        props.fetchSalesOrders && props.fetchSalesOrders(props);
        showToasterMessage({ messageType: 'success', description: (res.data || {}).message || 'Package has been deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
      })
  }
}

export const updateUserStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrderPackage/updateUserStatus`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'User status updated' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchAllPackage(props);
        props.fetchSOPackegeDetail && props.fetchSOPackegeDetail(payload);  
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}


export const fetchAllPackageByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrderPackage/getSalesPackageSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PACKAGE_ACTION_LIST.PACKAGE_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PACKAGE_ACTION_LIST.PACKAGE_LIST;
          // err.data = {
          //   pageNo: payload.pageNumber,
          //   list: []
          // };
        }
      })
  }
}

export const getAllPackageCountByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrderPackage/countSalesPackageSummary?relationshipId=${payload.relationshipId}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: PACKAGE_ACTION_LIST.PACKAGE_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PACKAGE_ACTION_LIST.SALES_ORDER_COUNT;
        }
      })
  }
}

export const fetchAllQualityChecklists = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/qualityChecklist/getAllQualityChecklists?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.QUALITY_CHECK_NAME, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMMON_ACTIONS.QUALITY_CHECK_NAME;
        }
      })
  }
}