import React, { Fragment, useState } from 'react';
//import { DownloadOutlined } from '@ant-design/icons';
//import config from '../../../../../config/environmentConfig';
import { FormattedMessage } from 'react-intl';
//import ReactFileReader from 'react-file-reader'
// import { CSVLink } from "react-csv";
import { Button, DatePicker, Row, Col } from 'antd';
import { getMomentDateForUI, getMomentDateForUIReadOnly } from '../../../../../utils';
import { CONSTANTS } from '../../../../../static/constants';
//import BankStatement from '../../../../../assets/sampleFiles/bank_statement.csv';
//import BankStatementSample from '../../../../../assets/sampleFiles/bank_statement_sample.csv';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const format2 = CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT;
const ImportStatementAuthrizedComponent = (props) => {
    const [startDate, updateStartDate] = useState(new Date());
    const [endDate, updateEndDate] = useState(new Date());

    const disabledDate = (current) => {
        return current && current < startDate.endOf('day');
      }
      
    return (
        <div style={{ textAlign: 'center' }}>
            <Fragment>
                <div style={{ height: '440px' }}>
                    <br />
                    <div>
                        <FormattedMessage id="importAuthrizeStatement.message" />
                    </div>
                    <br />
                    <div>

                        <Row>
                            <Col offset={8} span={2}>
                                <b style={{ margin: '0px 0px 5px -100px' }}><FormattedMessage id="modal.common.project.startDate" /></b>
                            </Col>
                            <Col span={8}>
                                <DatePicker
                                    allowClear={false}
                                    format={format}
                                    key={`startDate`}
                                    defaultValue={startDate ? getMomentDateForUI({ date: startDate, format }) : getMomentDateForUI({ date: new Date(), format })}
                                    onChange={(selectedDate) => {
                                        updateStartDate(selectedDate)
                                    }}
                                // size={size}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col offset={8} span={2}>
                                <b style={{ margin: '0px 0px 5px -100px' }}><FormattedMessage id="modal.common.project.endDate" /></b>
                            </Col>
                            <Col span={8}>
                                <DatePicker
                                    allowClear={false}
                                    format={format}
                                    key={`endDate`}
                                    disabledDate={disabledDate}
                                    defaultValue={endDate ? getMomentDateForUI({ date: new Date(endDate), format }) : getMomentDateForUI({ date: new Date(), format })}
                                    onChange={(selectedDate) => {
                                        updateEndDate(selectedDate);
                                    }}
                                // size={size}
                                />
                            </Col>
                        </Row>
                    </div>


                </div>
            </Fragment>
            <div className="ant-modal-footer">
                <Button type="primary" onClick={() => {
                    props.downloadStatementFromAuthorize({
                        startDate: getMomentDateForUIReadOnly({ date: startDate, format: format2 }),
                        endDate: getMomentDateForUIReadOnly({ date: new Date(endDate), format: format2 }),
                        relationshipId: props.companyInfo.relationshipId,
                        alpidePaymentGatewayId: props.authrizeNetAcc?.alpidePaymentGatewayId
                    }, props);
                    props.hideModal();
                }}><FormattedMessage id="importStatement.text" /></Button>
            </div>
        </div>

    );

}
export default ImportStatementAuthrizedComponent;
