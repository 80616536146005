import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Button } from 'antd';
import { Dropdown } from '../../../../general/Dropdown';
import './index.scss';
import {getUserDefinedName, showToasterMessage} from '../../../../../utils';


const AssignSectionToStudent = (props) => {

    const { regStudentsToEnroll } = props;

    return (
        <>
            <div className='table-container' style={{height: '500px'}}>
                <table>
                    <thead>
                        <tr>
                            <th width='6%'>Reg. ID</th>
                            <th>Registration Form Name</th>
                            <th>Student Name</th>
                            <th>{getUserDefinedName('Class', props)}</th>
                            {/* <th>Fee</th> */}
                            <th>Enrolment Form</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (regStudentsToEnroll || []).length ? (regStudentsToEnroll || []).map((item, i)=>{
                                return <tr key={i}>
                                            <td>
                                                {item.schoolStudentId}
                                            </td>
                                            <td>
                                                {item.formName}
                                            </td>
                                            <td>
                                                {`${item.firstName} ${item.lastName}`}
                                            </td>
                                            <td>
                                                {item.className}
                                            </td>
                                            {/* <td>
                                                {'-'}
                                            </td> */}
                                            <td>
                                                <Dropdown
                                                    items={props.enrollmentForms || []}
                                                    style={{width: '180px'}}
                                                    placeholder={'Select Form'}
                                                    className={ props.submittedEnroll && !item.enrollFormId ? 'select-error' : '' }
                                                    optionKeyName='registrationFormSettingId'
                                                    valueKeyName='programName'
                                                    value={item.enrollFormId}
                                                    onSelect={(selectedValue, options) => {
                                                        const tempList = regStudentsToEnroll;
                                                        tempList[i] = {...item, enrollFormId: selectedValue, enrolmentFormName: options.name, studentStatus: "Enrolled"};
                                                        props.updateModalState({regStudentsToEnroll : tempList})
                                                    }}
                                                />
                                            </td>
                                        </tr>
                            })
                            :
                            <div>
                                No students selected to enroll.
                            </div>
                        }
                    </tbody>
                </table>
            </div>

            <hr className="mt10"></hr>

            <Row justify='space-between' className='mt10'>
                <Button onClick={()=>{
                    props.popModalFromStack();
                }}>
                    Cancel
                </Button>

                <Button type="primary" onClick={() => {
                    props.updateModalState({submittedEnroll: true});
                    for(const stuObj of regStudentsToEnroll){
                        if(!stuObj.enrollFormId || !stuObj.enrolmentFormName){
                            return showToasterMessage({
                                messageType: 'error', description: 'Please select form for all selected students'
                            });
                        }
                    }

                    const modalData = {
                        modalBody: 'Are you sure you want to submit?',
                        handleSubmit: () => {
                            props.saveOrUpdateStudentEnrollment({
                                relationshipId: props.companyInfo.relationshipId,
                                studentsToEnroll: regStudentsToEnroll
                            });
                            props.popModalFromStack();
                        }};
                        props.pushModalToStack(modalData);
                }}>
                    <FormattedMessage id='button.submit.label' defaultMessage='' />
                </Button>
            </Row>
        </>
    );

}


export default AssignSectionToStudent;
