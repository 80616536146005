import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import CreatePledgeComp from '../../../components/B2B/CreatePledge';
import TransactionFileUploadComp from '../../../components/B2B/TransactionFileUpload';
import { fetchCustomers } from '../TransactionCustomers/action'
import { fetchTransactionDataByRID, verifyCredentials, UploadFiles, getTransactionCountByRID, getTransactionByCustomerId, getTransactionCountByCustomerId, fetchSuppliers  } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../static/constants';
// import { getAllFundraisingCampaign } from '../../drawer/fundraising/pledges/action';
// import { fetchNewsLetter } from '../NewLetterPreview/action';
// import { fetchDataIfNeeded } from "../../../utils";

class TransactionFileUpload extends Component {
  constructor(props) {
    super(props);
    // const linkProps = queryString.parse(this.props.location.search) || {};
    // let ids = (linkProps.t || '').split("_"); 
    // let teamIds = (linkProps.f || '').split("_");
    // let phoneNumber = linkProps.ph || '';
    this.state = {
      ...props,
      activePage : "login",
      relationshipBillingAddress: {},
      neverExpires: 1,
      txnType : '',
      pageNumber: 1,
      pageSize: 10,
      // relationshipId: Number(ids[0]),
      // sourceId: Number(ids[1]),
      // pledgeId: Number(ids[2]),
      // fundraisingCampaignId: Number(ids[3]),
      // campaignId: Number(ids[3]),
      // amount: Number(ids[4]),
      // period: 'oneTimePayment',
      // phoneNumber: phoneNumber,
      // teamMasterId: teamIds[0],
      // teamMemberId: teamIds[1],
      // onlineThemes: {}
    }
  }

  componentDidMount() {
    // const linkProps = queryString.parse(this.props.location.search) || {};
    // let ids = (linkProps.t || '').split("_");
                                                           
    // this.props.fetchTransactionDataByRID(payload, this.props);
    // fetchDataIfNeeded("getAllSalesOrders", "allSalesOrders", this.props, payload);
    // fetchDataIfNeeded("fetchPaymentModes", "paymentModeList", this.props, payload);
   // payload.templateId && this.props.fetchNewsLetter(payload, this.props);
   
  }

  componentWillReceiveProps(props) {
  
    if (props.companyInfo && props.companyInfo.relationshipId) {
      const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
      // this.setState({
      //   relationshipBillingAddress,
      //   companyInfo: props.companyInfo
      // })
      const onlineThemes = JSON.parse( props.companyInfo.onlineThemes || '{}');
      this.setState({
        onlineThemes,
        relationshipBillingAddress,
        companyInfo: props.companyInfo,
      })
    }
    // if (props.allCampaignList || props.paymentModeList) {
    //   this.setState({
    //     allCampaignList: props.allCampaignList,
    //     paymentModeList: props.paymentModeList,
    //   })
    // }
    // if (this.state.fundraisingCampaignId && props.allCampaignList && props.allCampaignList.length) {
    //   const selectedCampain = find(props.allCampaignList, { fundraisingCampaignId: this.state.fundraisingCampaignId });
    //   this.setState({
    //     selectedCampain
    //   })
    // }
    // if (!this.state.paymentModeId && props.paymentModeList && props.paymentModeList.length) {
    //   const selectedPaymentMode = find(props.paymentModeList, { paymentModeName: "Card Payment" }) || {};
    //   this.setState({
    //     paymentModeId: selectedPaymentMode.paymentModeId,
    //     paymentMode: selectedPaymentMode.paymentModeName
    //   })
    // }
    // if (props.newsLetterTemplate || !this.state.newsLetterTemplate) {
    //   this.setState({
    //     newsLetterTemplate: props.newsLetterTemplate,
    //   })
    // }
  }

  render() {

    return (
      // <CreatePledgeComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    <>
    
      <TransactionFileUploadComp  {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}/>
    </>

      
    );
  }

}
//mapping data from reducers to prop
const mapStateToProps = (state) => {
  return {
    companyInfo: state.settings.relationshipData, 
    allCampaignList: state.fundraisingReducer.allCampaignList,
    paymentModeList: state.common.paymentModeList,
    newsLetterTemplate: state.membershipReducer.newsLetterTemplate,
    transactionList: state.fileUpload.transactionList,
    transactionCount: state.fileUpload.transactionCount,
    txnCustomers: state.fileUpload.customersList,
    txnSuppliers: state.fileUpload.supplierList
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  fetchTransactionDataByRID,
  getTransactionCountByRID,
  verifyCredentials,
  UploadFiles,
  getTransactionByCustomerId,
  getTransactionCountByCustomerId,
  fetchCustomers,
  fetchSuppliers,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransactionFileUpload);
