import { TX_FILE_UPLOAD_ACTION } from '../static/constants';
const initialState = {
  transactionList: {
    1: []
  },
  transactionCount: 0,
  customersList: {
    1: []
  },
  supplierList: {
    1: []
  },
}

const FileUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case TX_FILE_UPLOAD_ACTION.TRANSACTION_LIST:
      return {
        ...state,
        transactionList: {
          ...state.transactionList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case TX_FILE_UPLOAD_ACTION.TRANSACTION_COUNT:
      return { ...state, transactionCount: action.data };

    // case TX_FILE_UPLOAD_ACTION.TRANSACTION_LIST_CID:
    //   return {
    //     ...state,
    //     transactionList: {
    //       ...state.transactionList,
    //       [action.data.pageNo || 0]: action.data.list
    //     }
    //   };

      // case TX_FILE_UPLOAD_ACTION.TRANSACTION_COUNT_CID:
      //   return { ...state, transactionCount: action.data };

    case TX_FILE_UPLOAD_ACTION.TRANSACTION_CUSTOMERS:
      return {
        ...state,
        customersList: {
          ...state.customersList,
          [action.data.pageNo || 0]: action.data.list
        }
      };

    case TX_FILE_UPLOAD_ACTION.TRANSACTION_SUPPLIER_LIST:
      return {
        ...state,
        supplierList: {
          ...state.supplierList,
          [action.data.pageNo || 0]: action.data.list
        }
      };

    default:
      return state;
  }
};

export default FileUploadReducer;