import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NotFound from '../../../components/Common/NotFound';
import { uploadGSTR1FilingDetails } from '../../modal/modalBody/finance/gstrDetails/action';
import { showModal, pushModalToStack, hideModal, popModalFromStack , saveUserPrefrences} from '../../../actions/commonActions';
import GSTFilling from '../../../components/finance/GSTFilling';
import { fetchGSTFilingDeatils ,fetchValidatedGstDetails ,fetchGstSummaryDetails ,discardSummaryDraft ,createSummaryDraft ,saveDataToGSTIN ,saveToGstinStatus} from './action';
import EInvoiceFilterComp from '../../../components/finance/GSTFilling/gstFillingfilter';
import { deleteValidatedInvoice , deleteInvoiceList } from '../../modal/modalBody/finance/gstrDetails/action';
import { getMomentDateForUI } from '../../../utils';
import { fetchSalesInvoicesByFilter } from '../../modal/modalBody/customer/AllInvoicesByLedger/action';
class EInvoiceGstr1FillingComp extends Component {

    constructor(props){
        super();
        this.state={
            searchedText: '',
            pageNumber: 1,
            loading: false,
            txColumns: [],
            pageSize: 25,
            activeTabKey: "invoiceNotFiled",
            txMandatoryColumns: ['eWayBillNo','invoiceDueDate','paymentTermName','invoiceDueAmount','customerName','status', 'InvoiceNumber','isXeroUploaded','invoiceTotalAmount', 'irnNumber','invoiceDate', 'InvoiceType', 'eInvoiceType','invoiceNumber' ],
      txAvailableColumn: {
        eInvoiceType: true,
        InvoiceType: true,
        invoiceDate: true,
        irnNumber: true,
        isXeroUploaded:true,

        customerName: true,
        invoiceTotalAmount: true,
       status: true,
       invoiceDueDate:true,
       paymentTermName:true,
       invoiceDueAmount:true,
       invoiceNumber:true,
    
       
       
        eWayBillNo: true,

      },
       selectedDate: new Date(),
      
       createSummaryBtn: false,
       taxPeriod: "Monthly",
       filterPeriod: "",
       quarterPeriod: "",
       quarterYear: "",
       showTaxPeriod:""
        }
    }

    componentDidMount() {

        
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId ,pageNumber: 1 , pageSize: 25 , filters:{monthly: getMomentDateForUI({ date: new Date(), format:"DD-MM-YYYY" })}};
       
        this.props.fetchGSTFilingDeatils(payload);
       
        // this.props.fetchGstSummaryDetails();
        
    }
     componentWillReceiveProps(props){
       if(!this.state.loading && this.state.selectedDate){
        const companyInfo = this.props.companyInfo || {};
        const payload1 ={
            date: this.state.selectedDate,
          }
          if(this.state.activeTabKey === "summary"){
            this.props.fetchGstSummaryDetails(payload1);
          }
          else if(this.state.activeTabKey === "validated"){
            this.props.fetchValidatedGstDetails(payload1);
          }
           else{
            const payload = { relationshipId: companyInfo.relationshipId ,pageNumber: 1 , pageSize: 25 , filters:{monthly: getMomentDateForUI({ date: this.state.selectedDate, format:"DD-MM-YYYY" })}};
            this.props.fetchGSTFilingDeatils(payload);
           }
          this.setState({loading: true})
       }
           
       
     }
    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: false
        });
    }


    render() {
        return (
            <div>
                {
                    this.props.companyInfo?.countryName === "India" ? (
                    <div>
                          {
                            this.state.salesEInvoiceFilterDrawerVisible && <EInvoiceFilterComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
                    <GSTFilling {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}/>
                    </div>  
                    ): (<div ><NotFound/></div>)
                }
        
            </div>
        )
       
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        
        eInvoiceListing: state.gstFilling.eInvoiceListing,
        validateInvoiceListing: state.gstFilling.validateInvoiceListing,
        summaryInvoiceListing: state.gstFilling.summaryInvoiceListing,
        listLoading: state.common.listLoading,
        headerLoading: state.common.headerLoading,
        saveLoading: state.common.saveLoading,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
 
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    fetchGSTFilingDeatils,
    saveUserPrefrences,
    uploadGSTR1FilingDetails,
    fetchValidatedGstDetails,
    fetchGstSummaryDetails,
    discardSummaryDraft,
    deleteValidatedInvoice,
    createSummaryDraft,
    saveDataToGSTIN,
    saveToGstinStatus,
    deleteInvoiceList,
    fetchSalesInvoicesByFilter,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EInvoiceGstr1FillingComp);
