import React from "react";
import { Button, Modal, Row, Col, Divider, Radio, Input, Checkbox, Select } from "antd";
// import Dropdown from "../components/Dropdown";
import "../../leads.scss";
import { FormattedMessage } from "react-intl";
import { TextBox } from "../../../general/TextBox";
import { CRM_COMPONENT_TYPES } from "../../../../static/constants";
import { Dropdown } from "../../../general/Dropdown";

const PreviewForm = (props) => {
  const {
    visible,
    onClose,
    formComponents,
    formName,
  } = props;

  const column1 = formComponents.filter((_, index) => index % 2 === 0);
  const column2 = formComponents.filter((_, index) => index % 2 !== 0);

  const getCompoForShow =(dataList) =>  {return (dataList || []).map((component, index) => (
    <div key={index} style={{ marginTop: "5px" }}>
      <label>
        {component.label}
      </label>
      {component.isRequired ? (
        <span className="required">*</span>
      ) : (
        ""
      )}
      {component.type === CRM_COMPONENT_TYPES.INPUT_TEXT && (
        <div className="input-margin">
          <Input type="text" placeholder={component.placeholder} />
        </div>
      )}
      {component.type === CRM_COMPONENT_TYPES.DROPDOWN && (
        <div className="input-margin">
          <Dropdown
            options={component.options || []}
            placeholder={component.placeholder}
          />
          {/* <Select
            className='lead-dropdown-style' 
            placeholder={component.placeholder || 'Placeholder'}
            // options={(component.options || [{value: 'Option 1'}]).map(obj=>obj.value)}
          >
            {(component?.options || []).map((option, index) => (
              <option key={index}>
                {option.value}
              </option>
            ))}
          </Select> */}
        </div>
      )}
      {component.type === CRM_COMPONENT_TYPES.MULTILINEINPUT && (
        <div className="input-margin">
            <textarea
              placeholder={component.placeholder || ''}
              style={{
                width: "100%",
                height: "32px",
                borderColor: "#d9d9d9",
                borderRadius : "2px", 
                padding :  '0 10px'
              }}
            />
        </div>
      )}
      {component.type === CRM_COMPONENT_TYPES.INPUT_NUMBER && (
        <div className="input-margin">
          <Input type="number" placeholder={component.placeholder || ''} />
        </div>
      )}
      {component.type === CRM_COMPONENT_TYPES.CHECKBOX && (
        <div className="input-margin">
          <Checkbox.Group
            options={(component.options || [{value: 'Option 1'}]).map(obj=>obj.value)}
          />
        </div>
      )}
      {/* {component.type === CRM_COMPONENT_TYPES.FILE_ATTACHMENT && (
        <div className="input-margin">
          <FileAttachment />
        </div>
      )} */}
      {component.type === CRM_COMPONENT_TYPES.MULTIPLE_CHOICE && (
        <div className="input-margin">
          <Radio.Group>
            {(component.options || [{value: 'option 1'}]).map((option, index) => (
              <Radio key={index}>
                {option.value}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      )}
    </div>
  ))}

  return (
    <div className="modal-container">
      <Modal
        width={"80%"}
        title="Form Preview"
        visible={visible}
        onCancel={onClose}
        footer={null}
        style={{ position: "relative" }}
        wrapClassName="back-blur"
      >
        <div className="preview-show-form">

          <Row gutter={[40, 15]}>
            <Col span={24}>
              <div className="preview-head">
                Form Name<span className="required">*</span>
              </div>
              <div>{formName || "-"}</div>
            </Col>
            <Divider style={{ margin: "0px" }} />
            <Col span={24}>
              <div className="preview-head">Lead Details(Mandatory)</div>
            </Col>
            <Col span={12}>
              <div className="i-label">
                Full Name<span className="required">*</span>
              </div>
              <TextBox placeholder="Full Name" className="input-field" />
            </Col>

            <Col span={12}>
              <div className="i-label">
                Email<span className="required">*</span>
              </div>
              <TextBox placeholder="Email" className="input-field" />
            </Col>

            <Col span={12}>
              <div className="i-label">
                Mobile No.<span className="required">*</span>
              </div>
              <TextBox placeholder="Mobile No." type='number' maxLength={10} className="input-field" />
            </Col>
          </Row>

          <Divider style={{ margin: "0px" }} />

          <Row gutter={[40, 15]}>
            <Col span={24}>
              <div className="preview-head">Lead Additional Details</div>
            </Col>
            <Col span={12}>
              {getCompoForShow(column1)}
            </Col>
            <Col span={12}>
              {getCompoForShow(column2)}
            </Col>
          </Row>
        </div>
        <div className="model-footer">
          <div className="model-button-wraper">
            {/* <Button className="ant-btn-primary">
              <FormattedMessage id="lead.save.button" defaultMessage="" />
            </Button> */}
            <Button onClick={onClose}>
              <FormattedMessage id="lead.close.button" defaultMessage="" />
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PreviewForm;
