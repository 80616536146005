import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Menu ,Empty} from "antd";
import { CONSTANTS,ICONS } from "../../../../../static/constants";
import { getMomentDateForUIReadOnly } from "../../../../../utils";
import DropdownAction from "antd/lib/dropdown";

const Tasks = (props) => {
  const { updateModalState, leadTasksList } = props;

  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">

        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="1" value="edit" onClick={()=>{
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>

        <Menu.Item key="2" onClick={()=>
          {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteSection(e, props);
              }
            })
          }
        }>
          <i className={ICONS.DELETE}/>
          Delete
        </Menu.Item>

      </Menu >
    )
  };


  return (
    <div className="view-container">
      <div className="view-container-actions">
        <div className="left-actions">
          <div className="table-heading">Task List</div>
        </div>
        <div className="right-actions">
          <button
            style={{
              backgroundColor: "#1cb961",
              color: "#ffffff",
              fontSize: "14px",
            }}
            onClick={() => {
              updateModalState({
                leadTaskDrawerVisible: true
              })
            }}
          >
            <i className={`${ICONS.ADD} mr5`} /> Create
          </button>
        </div>
      </div>

      <div className="table-container">
        <table id="tasks-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="serial.no" defaultMessage="" />
              </th>
              <th>Ref #</th>
              <th>Description</th>
              <th>Priority</th>
              <th>Due date</th>
              <th>Closed Date</th>
              <th>Date Created</th>
              <th>Created By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              (leadTasksList || []).length ? leadTasksList.map((rowData, i) => {
                return (
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{rowData.refNumber}</td>
                    <td>{rowData.taskDescription}</td>
                    <td>{rowData.priority}</td>
                    <td className="one-line-view">
                      {rowData.taskDueDate &&
                        getMomentDateForUIReadOnly({
                          date: rowData.taskDueDate,
                          format: CONSTANTS.DISPLAY_DATE_FORMAT,
                        })
                      }
                    </td>
                    <td className="one-line-view">
                      {rowData.taskClosedDate &&
                        getMomentDateForUIReadOnly({
                          date: rowData.taskClosedDate,
                          format: CONSTANTS.DISPLAY_DATE_FORMAT,
                        })
                      }
                    </td>
                    <td className="one-line-view">
                      {rowData.dateCreated &&
                        getMomentDateForUIReadOnly({
                          date: rowData.dateCreated,
                          format: CONSTANTS.DISPLAY_DATE_FORMAT,
                        })
                      }
                    </td>
                    <td className="one-line-view">
                      {rowData.userCreatedName || '-'}
                    </td>
                    <td style={{ width: "6%", paddingLeft: "50px" }}>
                      <div className="action-icon">
                        <DropdownAction
                          overlay={actionMenu(rowData)}
                          trigger={["click"]}
                        >
                          <i className={ICONS.MORE} />
                        </DropdownAction>
                      </div>
                    </td>
                  </tr>
                );
              })
              : (
                <tr key="empty-data">
                  <td colSpan={"100%"} style={{borderBottom:"none"}}>
                    <Empty />
                  </td>
                </tr>
              )
            }
            
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default injectIntl(Tasks);
