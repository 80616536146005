import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
// import { DatePicker, Checkbox } from 'antd';
import { TextBox } from '../../../../general/TextBox';
import { getMomentDateForUI } from '../../../../../utils'
// import { DropdownRef } from '../../../../general/Dropdown';
// import { CONSTANTS } from '../../../../../static/constants';
import { CustomButton } from '../../../../general/CustomButton';

class ProjectComponent extends Component {
  constructor(props) {
    super(props);
    let data = props.data || {}
    this.state = {
      projectName: data.projectName,
      projectMasterId: data.projectMasterId,
      isNeverExpired: data.isNeverExpired,
      description: data.description,
      projectStartDate: data.startDate,
      projectEndDate: data.endDate,
      departmentName: data.departmentName,
      projectOwner: data.projectOwner,
      startDate: getMomentDateForUI({ date: new Date(), format: props.format }),
      endDate: getMomentDateForUI({ date: new Date(), format: props.format }),
      version: data.version
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    // const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      <Fragment>
        <Form labelAlign='left'  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
            <Form.Item  label={<FormattedMessage id='modal.common.project.projectName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('projectName', {
                initialValue: this.state.projectName || undefined,
                rules: [
                  {
                    required: true,
                    message: 'Project Name is required.',
                  }
                ],
              })(
                <div>
                  <TextBox maxLength={30} placeholder={this.props.intl.formatMessage({ id: 'modal.common.project.projectName', defaultMessage: '' })} />

                </div>
              )}
            </Form.Item>
          <div className="modal-content create-project-control">
            {/* <Form.Item label={<FormattedMessage id='modal.common.project.description' defaultMessage='' />} colon={false}>
              {getFieldDecorator('description', {
                initialValue: this.state.description || ''
              })
                (<TextBox maxLength={45} placeholder={this.props.intl.formatMessage({ id: 'modal.common.project.description', defaultMessage: '' })} />)}
            </Form.Item>

            <Form.Item label={<FormattedMessage id='modal.common.project.departmentName' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('departmentName', {
                initialValue: this.state.departmentName || ''
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='modal.common.project.departmentName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('departmentId', {
                initialValue: this.state.departmentId || ''
              })
                (<DropdownRef
                  items={this.props.departments || []}
                  optionKeyName='departmentId'
                  valueKeyName='departmentName'
                  onSelect={(selectedValue, option) => {
                    this.props.form.setFieldsValue({ 'departmentId': selectedValue });
                    this.props.form.setFieldsValue({ 'departmentName': option.props.name });
                  }}
                />)}
            </Form.Item>

            <Form.Item label={<FormattedMessage id='modal.common.project.projectOwner' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('projectOwner', {
                initialValue: this.state.projectOwner || ''
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='modal.common.project.projectOwner' defaultMessage='' />} colon={false}>
              {getFieldDecorator('relationshipEmployeeId', {
                initialValue: this.state.relationshipEmployeeId || ''
              })
                (<DropdownRef
                  items={this.props.employees || []}
                  optionKeyName='relationshipEmployeeId'
                  valueKeyName='fullName'
                  onSelect={(selectedValue, option) => {
                    this.props.form.setFieldsValue({ 'relationshipEmployeeId': selectedValue });
                    this.props.form.setFieldsValue({ 'projectOwner': option.props.name });
                  }}
                />)}
            </Form.Item>

            <Form.Item label={<FormattedMessage id='modal.common.project.startDate' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('startDate', {
                initialValue: this.state.startDate || undefined
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='modal.common.project.startDate' defaultMessage='' />} colon={false}>
              {getFieldDecorator('startDate', {
                initialValue: this.state.startDate || undefined
              })
                (<DatePicker
                  format={format}
                  // defaultValue={moment(new Date(), format)}
                  disabled={this.state.isNeverExpired}
                  onChange={(selectedDate) => {
                    this.props.form.setFieldsValue({ 'startDate': getMomentDateForUI({ date: selectedDate, format }) });
                    this.setState({ 'startDate': getMomentDateForUI({ date: selectedDate, format }) });
                  }}
                />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='modal.common.project.endDate' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('endDate', {
                initialValue: this.state.endDate || undefined
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='modal.common.project.endDate' defaultMessage='' />} colon={false}>
              {getFieldDecorator('endDate', {
                initialValue: this.state.endDate || undefined
              })
                (<DatePicker
                  format={format}
                  disabled={this.state.isNeverExpired}
                  onChange={(selectedDate) => {
                    this.props.form.setFieldsValue({ 'endDate': getMomentDateForUI({ date: selectedDate, format }) });
                    this.setState({ 'endDate': getMomentDateForUI({ date: selectedDate, format }) });
                  }}
                />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='recurringInvoice.setup.neverExpires' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('neverExpire', {
                initialValue: this.state.isNeverExpired || undefined
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='recurringInvoice.setup.neverExpires' defaultMessage='' />} colon={false}>
              {getFieldDecorator('neverExpire', {
                initialValue: this.state.isNeverExpired || undefined,
              })
                (<Checkbox className='login-form-checkbox' checked={this.state.isNeverExpired} onChange={(e) => {
                  this.setState({ isNeverExpired: e.target.checked ? 1 : 0 })
                }}></Checkbox>)}
            </Form.Item> */}
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.project.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal() }}
              />
              <CustomButton
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          projectName: values.projectName,
          description: values.description,
         // projectStartDate: values.isNeverExpired ? null : formatDateFilterForBackend(values.startDate),
         // projectEndDate: values.isNeverExpired ? null : formatDateFilterForBackend(values.endDate, CONSTANTS.FILTER_DATE_TYPE.END),
          departmentName: values.departmentName,
          projectOwner: values.projectOwner,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          isNeverExpired: this.state.isNeverExpired ? 1 : 0,
          projectMasterId: this.state.projectMasterId,
          version: this.state.version
        }

        const modalData = {
          modalBody: 'Are you sure you want to Submit ?',
          handleSubmit: () => {
            this.props.createOrUpdateProject(payload, this.props);
            this.props.hideModal();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

  export default Form.create({ name: 'Project_component' })(injectIntl(ProjectComponent));
