import React, {useState, useEffect} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { Alert, Button, Spin } from 'antd';
import config from '../../../../config/environmentConfig';
import './stripe.scss';
import { showToasterMessage } from '../../../../utils';

const stripePromise = loadStripe('pk_test_51IdxxgL81zohSYdnTW0V88mWnfCIhqk8pgZdYgD9S2Xbut00JH58Ac7QHce64AWM0GUbKrcvlv6G5M3BHCp6dOGJ00Ja0AiJgF');

const StripePayments = (props) => {

  const {customerDetail={}, proformaDetails={}} = props;

  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    setLoading(true);
    fetch(`${config.API_BASE_URL}/${config.rootContextStripe}/payment/createPaymentIntent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "stripeToken": "tok_visa",
        "userName": customerDetail?.customerId || 0,
        "amount": Number(proformaDetails?.invoiceData?.invoiceTotalAmount || 0)*100 || 0,
        "success": true,
        "message": "Payment processed successfully",
        "chargeId": "ch_1GqIC8AbKedJlZ",
        "currency": customerDetail?.currencyCode || "USD",
        "paymentMethod": "card",
        "additionalInfo": {
          "receiptEmail": customerDetail?.boContactList?.find(obj=>obj.isPrimaryContact === 1)?.emailAddress || "",
          "description": `Payment for proformaid: ${proformaDetails.invoiceProformaId}`
        },
        "metadata": {
          "actionType": "proforma_to_invoice",
          "relationshipId": `${customerDetail.relationshipId || 0}`,
          "customerId": `${customerDetail.customerId || 0}`,
          "proformaId": `${proformaDetails.invoiceProformaId || 0}`
        }
      }),
      redirect: "follow"
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setLoading(false);
      })
      .catch((err)=>{
        setLoading(false);
        showToasterMessage({
          description:
            err?.message || "Some error occurred",
        });
      });
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };


  return (
    <div>
      {clientSecret ? 
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} {...props}/>
        </Elements>
        :
        (isLoading ? 
          <div className="loader"><Spin size="large" spinning={true} tip={'Please Wait...'} /></div>
          :
          <Alert
            message="Some error occured!!"
            description={<>
              <Button size="small" type="primary" onClick={()=>{window.location.reload()}}>
                Refresh
              </Button>
            </>}
            type="error"
            showIcon
          />
        )
      }
    </div>
  );
};

export default StripePayments;