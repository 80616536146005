import { AGED_STOCK_ACTION_LIST } from '../static/constants';
const initialState = {
  agedStockList: {
    1: []
  },
  agedStockCount: 0,
  
}

const AgedStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGED_STOCK_ACTION_LIST.AGED_STOCK_LIST:
      return {
        ...state,
        agedStockList: {
          ...state.agedStockList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case AGED_STOCK_ACTION_LIST.AGED_STOCK_COUNT:
      return { ...state, agedStockCount: action.data };
    case AGED_STOCK_ACTION_LIST.AGED_STOCK_PAGE_RESET:
      return { ...state, agedStockList: initialState.agedStockList };

    default:
      return state;
  }
};

export default AgedStockReducer;