import React, { useEffect, useState } from "react";
import "../../../../eStore/storeSettings/store-settings.scss";
import { Button, Col, Row, Tabs, Radio, Switch } from "antd";
import { MODAL_TYPE } from "../../../../../static/constants";
import { Dropdown } from "../../../../general/Dropdown";
import { CONSTANTS, ICONS  } from "../../../../../static/constants";
import { TextBox } from "../../../../general/TextBox";
import FixedCharge from "./fixedCharge";
import VariableCharge from "./variableCharge";
import  TimeModal  from "../Delivery/timeModal";
import { FormattedMessage } from "react-intl";
function renderContent(props) {
  if (props.deliveryValue?.isFixed && props.deliveryValue?.byCurrency) {
    return <FixedCharge {...props} />;
  } else {
    return <VariableCharge {...props} />;
  }
}

const DeliveryComp = (props) => {
  const versionValue = props.storeValues?.version;
  
  const onChange = (e) => {
    const val = props.deliveryValue;
    let tempFilter = val;
    tempFilter.isFixed = tempFilter.isFixed === 1 ? 0 : 1;

    if (tempFilter?.isFixed) {
      tempFilter.chargesInfo[0].id = 11;
      tempFilter.chargesInfo[1].id = 12;
      tempFilter.chargesInfo[0].starting = 0;
      tempFilter.chargesInfo[1].starting = 0
      tempFilter.chargesInfo.slice(0, 2);
    } else {
      props.updateState({fixedError:''})
      tempFilter.chargesInfo[0].id = 1;
      tempFilter.chargesInfo[1].id = 2;
      tempFilter.chargesInfo[0].starting = 0;
      tempFilter.chargesInfo[1].starting = 500;
    }
    props.updateState({ deliveryValue: tempFilter });
  };
  const onChange1 = (e) => {
    

    const val = props.deliveryValue;
    let tempFilter = val;
    tempFilter.byCurrency = tempFilter.byCurrency === 1 ? 0 : 1;
    props.updateState({ deliveryValue: tempFilter });
  };
 
  function checkConditions(array) {
      
    for (let i = 0; i < array.length; i++) {
      const currentObj = array[i];
      
      // Check if the "amount" property is greater than 0
      const amounterror = !currentObj.amount && currentObj.amount !== isNaN();
      const startingError = i !== 0 && !currentObj.starting && currentObj.starting !== isNaN()
      const endingError = i !== array.length - 1 && !currentObj.ending && currentObj.ending !== isNaN()
      if (amounterror||startingError||endingError) {
        let arr = props.variableError;
       if(!(arr.includes(i))) arr.push(i)
        props.updateState({variableError:arr ,variableErrorMessage:'This field is required'})
       
      }

      // Check if "starting" value is greater than the "ending" value of the previous object
      // if (i > 0 && (!(currentObj.starting >= array[i - 1].ending) )) {
      //       if(currentObj.starting>= currentObj.ending){
      //         props.updateState({
      //           errorEnding:i,
      //         })
      //       }
      //     let arr = props.variableError2;
      //   arr.push(i)
      //   props.updateState({variableError2:arr ,variableErrorMessage2:'Invalid value'})
     
       
      // }
    }
    for (let i = 0; i < array.length; i++) {
      const currentObj = array[i];
      
      if(currentObj.starting>= currentObj.ending){
        props.updateState({
          errorEnding:i,
        })
      }
      // Check if "starting" value is greater than the "ending" value of the previous object
      if (i > 0 && !(currentObj.starting >= array[i - 1].ending)) {
       
          let arr = props.variableError2;
        arr.push(i)
        props.updateState({variableError2:arr ,variableErrorMessage2:'Invalid value'})
     
       
      }
    }
    // All conditions passed
    // return true;
  }
  const HandleClick = () => {
   
   
    const payload = {
      relationshipId: props.relationshipId,
      deliveryChargesInfo: props.deliveryValue,
      version: versionValue,
    };
    const modalData ={
      modalBody: (
        <FormattedMessage
          id="customer.salesQuote.edit.form.confirmation"
          defaultMessage=""
        />
      ),
      handleSubmit: () => {
        props.hideModal()
        if (props.deliveryValue?.isFixed) {
          if (props.deliveryValue?.chargesInfo[0].starting || (props.deliveryValue?.chargesInfo[1].starting && props.deliveryValue?.chargesInfo[1].starting >props.deliveryValue?.chargesInfo[0].starting)) {
          
            props.postDeliveryCharges(payload, props);
          } else {
            props.updateState({ fixedError: "This field is required" });
          }
        } else {
        
          const priceRange = props.deliveryValue?.chargesInfo;
          const conditionsPassed = checkConditions(priceRange);
          if (!(props.variableError.length) && !(props.variableError2.length)) {
            props.postDeliveryCharges(payload, props);
          } 
          
        }
       
      }
     }
     props.showModal(modalData)
   

   
  };
  
  return (
    <div className="store-model-container cus-space">
      <div className="store-main-container">
        <div className="model-head ">
          <Row>
            <Col span={23}>
            <p className="heading">Delivery Charge</p>
          <p className="sub-heading">
          Flexibility to set rates based on payment methods.
          </p>
            </Col>
            <Col span={1}>
           
                  <Switch
                    checked={props.deliveryValue?.isDeliveryCharge}
                    onClick={(checked, e) => {
                      const val = props.deliveryValue;
                      let tempFilter = val;
                      tempFilter.isDeliveryCharge =
                        tempFilter.isDeliveryCharge === 1 ? 0 : 1;
                      props.updateState({ deliveryValue: tempFilter });
                    }}
                  />
                
            </Col>
          </Row>
         
        </div>
        <div  className="model-container">    
          {
           props.deliveryValue?.isDeliveryCharge ? <>
             <Row style={{ marginTop: "10px" }} gutter={[16,8]}>
            <Col span={24}>
              <Radio.Group
                onChange={onChange1}
                value={
                  props.deliveryValue?.isDeliveryCharge &&
                  (props.deliveryValue.byCurrency ? 1 : 2)
                }
              >
                <Row>
                  <Col span={12}>
                  <Radio
                  value={1}
                  disabled={
                    props.deliveryValue?.isDeliveryCharge ? false : true
                  }
                >
                  By Currency
                </Radio>
                </Col>
                  <Col span={12}>
                    <Radio
                  value={2}
                  disabled={
                    props.deliveryValue?.isDeliveryCharge ? false : true
                  }
                >
                  By Weight
                </Radio>
                </Col>
                </Row>
                
              
              </Radio.Group>
            </Col>
          </Row>
           <Row>
            <Col span={24}> 
            <Radio.Group
              onChange={onChange}
              style={{ padding: "20px 0px" }}
              value={
                props.deliveryValue?.isDeliveryCharge &&
                (props.deliveryValue.isFixed ? 1 : 2)
              }
            >
              <Row>
                 <Col span={12}>   <Radio
                value={2}
                disabled={props.deliveryValue?.isDeliveryCharge ? false : true}
              >
                {" "}
                Variable{" "}
              </Radio>
              
              </Col>
              {
  props.deliveryValue?.byCurrency ? <Col span={12}>  <Radio
  value={1}
  disabled={props.deliveryValue?.isDeliveryCharge ? false : true}
>
  Fixed{" "}
</Radio></Col>  :''
              }
              </Row>
             
           
           
            </Radio.Group>
            </Col>
          </Row>
         
          <div>{renderContent(props)}</div>
          <div>
         <Row>
            <Col span={24}>
              <div className="robots">
                <div>
                  <div className="i-label">
                    <b>Average delivery time</b>
                  </div>
                  <div>
                    <p>Manage expectations with clear, consistent timelines. This is shown on checkout page.</p>
                  </div>
                </div>
               
              </div>
            </Col>
          </Row>
          <Row >
              <Col span={12} >
                <Dropdown
                  span={12}
                  placeholder='Enter the average delivery time'
                  items={props.deliveryValue?.deliveryTime}
                  valueKeyName="time"
                  valueKeyName2="unit"
                  optionKeyName="id"
                  canAddNew={true}
                  value={props?.deliveryValue?.selectedDeliveryTime?.time+" "+props?.deliveryValue?.selectedDeliveryTime?.unit}
                  canDelete={true}
                  onAddNewClick={() => {
                    const modalData = {
                      title: 'Add Delivery Time',
                      modalBody:<TimeModal {...props}/> ,
                      
                     hideFooter:true,
                    };
                    props.pushModalToStack(modalData);
                  }}
                  onSelect={(optionValue, elem) => {
                    const doc =(props.deliveryValue?.deliveryTime||[]).find((item) => item.id === optionValue)
                     const val = props.deliveryValue;
                      let tempFilter = val;
                    //  const value = elem.name.split(" ");
                    //  const obj = { time: Number(value[0]) , unit: value[1]}

                     tempFilter.selectedDeliveryTime = doc;
                  
                   
                     props.updateState({ deliveryValue: tempFilter });
                   
                  }}
                  deleteClickHandler={(payload) => {
                    const modalData = {
                      modalBody: "Are you sure you want to delete delivey time?",
                      handleSubmit: () => {


                         const val = props.deliveryValue;
            let tempFilter = val;
            let arr = tempFilter?.deliveryTime || [];
            let filterArr = arr.filter(item => item.id !== payload.id)
            tempFilter.deliveryTime = filterArr;
            props.updateState({ deliveryValue: tempFilter });
                    
                        
                      },
                      
                    };
                    props.pushModalToStack(modalData);
                  }}
                  // allowClear={true}
                  // onClear={() => {
                  //   props.updateState({
                  //     brandName: undefined,
                  //     brandId: undefined,
                  //   });
                  // }}
                />
              </Col>

              
            </Row>
         </div>
           </> :''
          }
        
  
          
       
         
        
        </div>
      </div>
  {
     props.deliveryValue?.isDeliveryCharge ?  <div className="footer-btn">
     <Button type="primary" onClick={() => HandleClick()}>
       Submit
     </Button>
   </div> :''
  }
     
    </div>
  );
};



export default DeliveryComp;
