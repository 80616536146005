import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { AgGridReact } from "ag-grid-react";
import { Pagination, Tooltip } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from "../../../utils";
import { CONSTANTS } from '../../../static/constants';
// import checkIcon from '../../../assets/images/check.png';
// import crossIcon from '../../../assets/images/cross.png';
import PageBreadcrumb from "../../PageBreadcrumb";


const TermAndFee = (props) => {
  const { termFeeList, updateState, companyInfo, termFeeCount, pageNumber } =
    props;

  const agGridStyle = {
    height: "100%",
    width: "100%",
  };


  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.membership' defaultMessage='' />


    }, {
      name: <FormattedMessage id='sidebar.menuItem.termAndFee' defaultMessage='' />,
    }
  ];
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getFees", "termFeeList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getFees(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const deleteHandle = (termAndFee) => {
    const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteTermAndFee(termAndFee, props);
      },
    };
    props.showModal(modalData);
  }

  const columnDefs = [
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="membership.termAndFee.listing.header.type"
            defaultMessage=""
          />
        );
      },
      cellRendererFramework: (link) => {
        return link.value ? link.value : "-"
      },
      field: "type",
      resizable: true,
      minWidth: 110,
      maxWidth: 400,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="membership.termAndFee.listing.header.fee"
            defaultMessage=""
          />
        );
      },
      cellRendererFramework: (link) => {
        return link.value && link.value !== '-' ? <span> {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
      },
      field: "fee",
      minWidth: 70,
      maxWidth: 300,
      suppressMovable: true,
      type: 'rightAligned'
    },
    {
      headerComponentFramework: () => {
        return (
          <div>
            <FormattedMessage
              id="membership.termAndFee.listing.header.frequency"
              defaultMessage=""
            />{" "}
          </div>
        );
      },
      field: "frequency",
      resizable: true,
      minWidth: 110,
      maxWidth: 300,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="membership.termAndFee.listing.header.duration"
            defaultMessage=""
          />
        );
      },
      cellRendererFramework: (params) => {
        return (params.data.duration && params.data.duration > 0 && params.data.period) ? `${params.data.duration} ${params.data.period}` : '-';
      },
      field: "duration",
      resizable: true,
      minWidth: 124,
      maxWidth: 300,
    },
    // {
    //   headerComponentFramework: () => {
    //     return (
    //       <FormattedMessage
    //         id="membership.termAndFee.listing.header.period"
    //         defaultMessage=""
    //       />
    //     );
    //   },
    //   field: "period",
    //   minWidth: 70,
    //   maxWidth: 300,
    // },
    // {
    //   headerComponentFramework: () => {
    //     return (
    //       <FormattedMessage
    //         id="membership.termAndFee.listing.header.lifetime"
    //         defaultMessage=""
    //       />
    //     );
    //   },
    //   cellRendererFramework: (link) => {
    //     return link.value ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;

    //   },
    //   field: "isLifetime",
    //   minWidth: 122,
    //   maxWidth: 300,
    // },
    {
      headerComponentFramework: () => {
        return <FormattedMessage id='common.dateCreated' defaultMessage='' />;
      },
      field: "dateCreated",
      minWidth: 122,
      maxWidth: 300,
      suppressMovable: true,
      // hide: _isColumnToHide('salesOrderDate'),
      colId: "salesOrderDate_1",
      cellRendererFramework: (link) => {
        return <div>
          <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
            {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
          </Tooltip>
        </div>
      },
      resizable: true
    },
    {
      field: "action",
      headerComponentFramework: () => {
        return <div className="cursor-pointer">

        </div>;
      },
      colId: "action_1",
      pinned: "right",
      lockPosition: true,
      cellRendererFramework: (params) => {
        return <div>
          <span className="cursor-pointer" title={props.intl.formatMessage(
            {
              id: 'commons.listing.edit.tooltip.msg',
              defaultMessage: ''
            })
          } onClick={
            () => {
              props.updateHeaderState({
                membership: {
                  ...props.membership,
                  termAndFeeData: { ...params.data },
                  addTermAndFeeDrawerVisible: true,
                  openTermAndFeeDrawerFromListing: true,
                }
              })
            }}>
            <i className='fa fa-pencil' />
          </span>

          <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage(
            {
              id: 'commons.listing.delete.tooltip.msg',
              defaultMessage: ''
            })
          } onClick={
            () => {
              deleteHandle(params.data);
            }}>
            <i className='fa fa-trash' />
          </span>

        </div>;
      },
      minWidth: 70,
      maxWidth: 70,
      suppressMovable: true,
    }
  ];

  const newTermAndFee = () => {
    props.updateHeaderState({
      membership: {
        ...props.membership,
        addTermAndFeeDrawerVisible: true,
        openTermAndFeeDrawerFromListing: true,
      },
    });
  };

  return (
    <Fragment>
      <div className="pb10">
        <PageBreadcrumb breadCrumbList={breadCrumbList} />
      </div>
      <div className="view-container sales-order-details mt0">

        <div className='team-and-free'>
          <div className="right-align pb10">
            <div className="action-dropdown-btn">
              <button onClick={() => {
                newTermAndFee();
              }}>
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
          </div>
          <div className="agGridWrapper">
            <div className="ag-theme-balham" style={agGridStyle}>
              <AgGridReact
                // onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={{
                  flex: 1,
                  autoHeight: true,
                  wrapText: true,
                }}
                rowData={termFeeList[pageNumber] || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                //getRowHeight={getRowHeight}
                suppressDragLeaveHidesColumns={true}
                animateRows={true}
              // onModelUpdated={onModelUpdated}
              // onColumnResized={onColumnResized}
              // onColumnVisible={(params) => {
              //   params.api.resetRowHeights();
              // }}
              // onDragStopped={() => {
              //   onColumnMoved({}, true);
              // }}
              //onColumnMoved={onColumnMoved}
              ></AgGridReact>
            </div>
          </div>
          <Pagination
            size="small"
            total={termFeeCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(TermAndFee);
