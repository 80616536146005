import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const updateOrCreateDocName = (payload, props) => {
    //payload.dateCreated = getCurrentDateForBackend(new Date());
    payload.isDefault = payload.isDefault ? 1 : 0;
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/documentName/createDocumentName`, payload)
        .then(res => {
          //dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST_ADDITION, data: res.data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchDocuments && props.fetchDocuments({relationshipId : payload.relationshipId})
          showToasterMessage({
            description: lang[(res || {}).message || 'common.saved.success'],
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
            err.actionToCall = COMMON_ACTIONS.DOCUMENT_LIST_ADDITION;
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }