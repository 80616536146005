import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import "@ant-design/compatible/assets/index.css";
import { Drawer, Row, Col, DatePicker, Select, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CustomButton } from "../../../general/CustomButton";
import { Dropdown } from "../../../general/Dropdown";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { CONSTANTS, LIST_CONSTANTS } from "../../../../static/constants";
import {
  getMomentDateForUI,
  getCurrentDateForBackend,
  getInitialsFromString,
  getUserDefinedName,
} from "../../../../utils";
import find from "lodash.find";

const format = CONSTANTS.TABLE_DATE_FORMAT;

function SchoolDrawer(props) {
  const { attendList = [] } = props;
  
  const handleStudentSelect = (selectedStudentIds) => {
    let selectedStudent = (props.studentList[1] || []).filter(obj => obj.classSectionId === props.classSectionId)
    props.updateState({
      attendList: (selectedStudent || []).filter((obj) =>
        selectedStudentIds.includes(obj.schoolStudentId)
      ),
  
    });
  };

  // const { type } = props;
  //  const [gridApi, updateGridApi] = useState({});
  //   const onGridReady = (params) => {
  //       updateGridApi(params.api);
  //       params.api.sizeColumnsToFit();
  //   }

  const closeDrawer = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addAttendenceDrawerVisible: false,
      },
    });
  };

  const handleSubmit = (props) => {
    let attendanceList = [];
    for (const sItem of props.attendList || []) {
      attendanceList.push({
        studentId: sItem.schoolStudentId,
        studentFirstName: sItem.firstName,
        studentLastName: sItem.lastName,
        attendanceStatus: sItem.status || "Present",
        rollNo:sItem.rollNo
      });
    }

    const selectedClass =
      find(props.classList[1] || [], { classId: props.classId }) || {};
    const payload = {
      relationshipId: props.relationshipId,
      attendanceList: attendanceList,
      classId: props.classId,
      classSectionId: props.classSectionId || 0,
      subjectId: props.subjectId || 0,
      attendanceDate: getCurrentDateForBackend(
        props.attendanceDate || new Date()
      ),
      createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
      formId: selectedClass.formId,
    };

    props.pushModalToStack({
      modalBody: <FormattedMessage id="save.confirmation" defaultMessage="" />,
      handleSubmit: () => {
        props.saveAttendance(payload, props);
      },
    });
  };


  const handleSelectAll = () => {
    let selectedStudent = (props.studentList[1] || []).filter(obj => obj.classSectionId === props.classSectionId)
    if (selectedStudent.length === (props.attendList || []).length) {
      props.updateState({ attendList: [] });
      return;
    }
    const tempIdList = (props.attendList || []).map(
      (obj) => obj.schoolStudentId
    );
    let selectedStudentList = [];
    for (let student of selectedStudent) {
      if ( !(tempIdList.includes(student.schoolStudentId)) ){
        selectedStudentList.push(student);
      }
    }
    props.updateState({
      attendList: (props.attendList || []).concat(selectedStudentList),
    });
  };

  const dropdownRender = (menu) => {
    let studentListForRender = [];

    if(props.classSectionId){
      studentListForRender = (props.studentList[1] || []).filter(obj => obj.classSectionId === props.classSectionId) || [];
    }
    // else{
    //   studentListForRender = props.studentList[1];
    // }

    return (
      <div className="custom-dropdown-menu">
        {
          studentListForRender.length ? 
            <Checkbox
              checked={
                studentListForRender.length &&
                studentListForRender.length === (props.attendList || []).length
              }
              onChange={handleSelectAll}
              style={{ padding: "10px 16px" }}
            >
              <b>Select All</b>
            </Checkbox>
            :
            <div className="m10">
              {props.classId ? 'No students in selected class' : 'Please select class first'}
            </div>
        }

        <Checkbox.Group
          value={(props.attendList || []).map((obj) => obj.schoolStudentId)}
          onChange={handleStudentSelect}
          className={"mb10"}
        >
          {(studentListForRender || []).map((student) => (
            <Checkbox
              key={`std` + student.schoolStudentId}
              value={student.schoolStudentId}
            >
              {student.logoAwsObjectKey ? (
                <img
                  src={student.logoAwsObjectKey}
                  alt=""
                  style={{ width: "24px", marginRight: "8px" }}
                />
              ) : (
                <div className="user-icon">
                  {getInitialsFromString(
                    student.firstName
                      ? student.firstName + (student.lastName || "")
                      : "User"
                  )}
                </div>
              )}
              <div className="custom-dropdown-detail ml10">
                <div style={{ fontWeight: "700" }}>
                  {`${student.firstName || ""} ${student.lastName || ""}`}
                </div>
                <div className="light-font" style={{ paddingLeft: "8px" }}>
                  {student.classsName || ""}
                </div>
              </div>
            </Checkbox>
          ))}
        </Checkbox.Group>
      </div>
    );
  };

  return (
    <Drawer
      title={<FormattedMessage id="sidebar.menuItem.attendance" defaultMessage=" " />}
      width={720}
      keyboard={false}
      zIndex={1}
      destroyOnClose={true}
      onClose={closeDrawer}
      maskClosable={false}
      className="custom-drawer"
      visible={props.schoolDrawers.addAttendenceDrawerVisible}
      afterVisibleChange={(visible) => {
        if (visible) {
          //props.initCalls();
        }
      }}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </>
      }
    >
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <div className="i-label">
            {getUserDefinedName("schoolManagement.class.name", props)}
          </div>
          <Dropdown
            items={props.classList[1] || []}
            valueKeyName="className"
            optionKeyName="classId"
            valueKeyName2="courseName"
            value={props.className}
            placeholder={getUserDefinedName(
              "schoolManagement.class.name",
              props
            )}
            onSelect={(optionValue, option) => {
              const payload = {
                relationshipId: props.companyInfo.relationshipId,
                pageNumber: 1,
                pageSize: 1000,
                classId: optionValue,
              };
              props.getRegisteredMemberByClass(payload);
              props.updateState({
                className: option.name,
                classId: optionValue,
              });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">
            {getUserDefinedName("schoolManagement.section.name", props)}
          </div>
          <Dropdown
            items={
              props.classList[1].find((obj) => obj.classId === props.classId)
                ?.classSectionList || []
            }
            valueKeyName="sectionName"
            optionKeyName="classSectionId"
            value={props.classSectionId}
            placeholder={props.classId ? getUserDefinedName(
              "schoolManagement.section.name",
              props
            ) : 'All Sections'}
            onSelect={(optionValue, option) => {
              props.updateState({
                classSectionId: optionValue
              });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Select Students</div>
          <Select
            mode="multiple"
            value={(props.attendList || []).map((obj) => `${obj.firstName} ${obj.lastName}`)}
            placeholder="Select Students"
            optionLabelProp="label"
            dropdownRender={dropdownRender}
            maxTagCount="responsive"
            maxTagTextLength={10}
            maxTagPlaceholder={'...'}
            tagRender={(props) => {
              const { label, closable } = props;
              return (
                <span style={{ display: 'inline-block', marginRight: '6px' }}>
                  <span>{label}</span>
                  {closable && (
                    <span style={{ marginLeft: '2px', cursor: 'pointer' }}>
                      ,
                    </span>
                  )}
                </span>
              );
            }}
          />
        
          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || (props.attendList || []).length;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="common.date.text" defaultMessage="" />
          </div>
          <DatePicker
            allowClear={false}
            format={format}
            key="attendanceDate"
            defaultValue={getMomentDateForUI({ date: new Date(), format })}
            value={props.attendanceDate}
            onChange={(selectedDate) => {
              props.updateState({
                attendanceDate: selectedDate,
              });
            }}
            size={0}
          />
        </Col>

        <Col span={24}>
          <div className="i--label">Reason note for mannual entry</div>

          <TextArea
            onChange={(e) => {
              props.updateState({
                reasoneEntry: e.target.value,
              });
            }}
          />
        </Col>
      </Row>

      <Col span={24} className="table-col-drawer">
        <table>
          <thead>
            <tr>
              <th>Student Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {attendList?.length ? (
              attendList.map((rowData, i) => {
                return (
                  <tr key={i + "tb"}>
                    <td>
                      <div style={{ display: "flex" }}>
                        {rowData.logoAwsObjectKey ? (
                          <img
                            src={rowData.logoAwsObjectKey}
                            style={{ width: "24px", marginRight: "8px" }}
                            alt=""
                          />
                        ) : (
                          <div className="user-icon">
                            {getInitialsFromString(
                              rowData.firstName
                                ? rowData.firstName + rowData.lastName
                                : "U"
                            )}
                          </div>
                        )}
                        <div className="custom-dropdown-detail ml10">
                          <div>
                            {`${rowData.firstName} ${rowData.lastName}`}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <Dropdown
                        items={LIST_CONSTANTS.ATTENDANCE_TYPE_LIST}
                        value={rowData.status || "Present"}
                        onSelect={(selectedValue, optionObj) => {
                          let tempList = props.attendList;
                          tempList[i] = {
                            ...tempList[i],
                            status: selectedValue || "Present",
                          };
                          props.updateState({
                            attendList: tempList,
                          });
                        }}
                      />
                    </td>
                  </tr>
                );
              })
            ) : 
              <div className="mt10">
                Please select students to mark attendance.
              </div>
            }
          </tbody>
        </table>
      </Col>
    </Drawer>
  );
}

export default injectIntl(SchoolDrawer);
