import React from "react";
import './index.scss';
import { Button, Result } from "antd";
import { getCurrencySymbol } from "../../../utils";
import ReactHtmlParser from "react-html-parser";
import QRCode from 'qrcode.react';


const RegistrationResult = (props) =>{
    const {registrationInvoiceProforma = []} = props;
    const amountToPay = (registrationInvoiceProforma || []).length ? JSON.parse((registrationInvoiceProforma || []).sort((a,b)=> a.expiryDate - b.expiryDate)[0]?.invoiceData || '{}')?.invoiceTotalAmount : 0;
    const registrationNumber = registrationInvoiceProforma[0]?.orgRegistrationId ? 
                                    `${props.companyInfo?.relationshipId?.toString().padStart(4, '0')}${new Date().getFullYear()}${props.registrationFormSetting?.registrationFormSettingId?.toString().padStart(4, '0')}${registrationInvoiceProforma[0]?.orgRegistrationId?.toString().padStart(5, '0')}`
                                    :
                                    <span className="light-txt">~invalid registration~</span>;

    const getRegistrationSuccessComp = ()=>{
        return <div className="reg_details">
            {registrationInvoiceProforma?.length ? <><QRCode value={`${window.location.href?.split("/register")[0]}/proforma-payment?${encodeURIComponent(`rid=${props.relationshipId}&proformaId=${registrationInvoiceProforma[0]?.invoiceProformaId || 0}&customerId=${props.customerId || 0}`)}`} renderAs="canvas" />
            <div className="detail_row mt20">
                <span className="label">Registration Number</span>
                <span className="value">{registrationNumber || '-'}</span>
            </div>
          

            <div className="detail_row">
                <span className="label">Name</span>
                <span className="value">{getPrimaryContactName(props.responseData?.boContactList) || ""}</span>
            </div>

            <div className="detail_row">
                <span className="label">Payment Due</span>
                <span className="value">{getCurrencySymbol(props.companyInfo?.currencyCode)}{(amountToPay || 0).toFixed(2)}</span>
            </div>
            </>
            :
            ''
            }
        </div>
    }

    const getPrimaryContactName = (contactList = []) =>{
        let primaryContact = contactList.find(obj => obj.isPrimaryContact) || contactList[0];
        if(primaryContact){
            primaryContact = contactList[0];
            return primaryContact.firstName + " " + ((primaryContact.lastName !== null || primaryContact.lastName !== '') ? primaryContact.lastName : "");
        }
        return '-';
    }


    return <>
            <div className="reg_result_container">
                {
                    props.status === "success" ?
                        <Result
                            status="success"
                            title={props.successMessage ? ReactHtmlParser(props.successMessage) : props.intl.formatMessage({ id: "registration.completion.success"})}
                            subTitle={getRegistrationSuccessComp()}
                            extra={registrationInvoiceProforma?.length ? [
                                <Button 
                                    type="primary" 
                                    key="register" 
                                    disabled={!registrationInvoiceProforma[0]?.orgRegistrationId}
                                    onClick={()=>{
                                        if(registrationInvoiceProforma?.length){
                                            props.history.push(`/proforma-payment?${encodeURIComponent(`rid=${props.relationshipId}&proformaId=${registrationInvoiceProforma[0].invoiceProformaId || 0}&customerId=${props.customerId || 0}`)}`)
                                        }
                                    }}
                                    style={{height: '38px'}}
                                >
                                    <span style={{fontSize: '16px'}}>Proceed to Pay</span>
                                </Button>,
                            ]: ''}
                        />
                    :
                    props.status === "error" ? 
                        <Result
                            status="error"
                            title={"Form Submission Failed"}
                            subTitle={"You can fill this form again and submit"}
                            extra={[
                                <Button 
                                    type="primary" 
                                    key="register" 
                                    onClick={()=>{
                                        window.location.reload();
                                    }}
                                    style={{height: '38px'}}
                                >
                                    <span style={{fontSize: '16px'}}>Try Again</span>
                                </Button>,
                            ]}
                        />
                    :
                    props.status === "inactive" ?
                        <Result
                            status="403"
                            title={props.formSetting.inactiveMessage ? ReactHtmlParser(props.formSetting.inactiveMessage) : "Registration Closed"}
                            subTitle="This form is no more active. Please contact admin."
                            extra={<Button type="primary"
                            onClick={()=>{
                                window.close();
                            }}
                            >Back Home</Button>}
                        />
                    :
                    ''
                }
            </div>
        </>
    // <div className="pay_container"> 
    //             {props.paymentSucceeded ?
    //                 <Result
    //                     status="success"
    //                     title="Payment Sucsess"
    //                     subTitle={getPaymentSuccessScreen()}
    //                     extra={[
    //                         <Button key="close" onClick={()=>{
    //                             window.close();
    //                         }}>
    //                             Close
    //                         </Button>,
    //                         <Button type="primary" key="register" onClick={()=>{
    //                             // window.location.reload();
    //                             window.print();
    //                         }}>
    //                             Print
    //                         </Button>,
    //                     ]}
    //                 />
    //                 :   
    //                 <>
    //                     <div className="pay_head">
    //                         <i className={ICONS.LEFT_ARROW_P} onClick={()=>{
    //                             props.showModal({
    //                                 modalBody: "Are you sure you want to cancel transaction?",
    //                                 handleSubmit: () => {
    //                                     props.updateState({startPayment: false});
    //                                     window.location.reload();
    //                                 }
    //                                 })
    //                         }} /> Review and Pay
    //                     </div>
    //                     <div className="pay_details_m">   
    //                         <div className="pay_details_l">
    //                             <div className="student_d">
    //                                 <div className="b-text">Student Info</div>
    //                                 <Row gutter={[8, 16]}>
    //                                     <Col span={12}>
    //                                         <div className="b-text">Student Name</div>
    //                                         {props.primaryContactName||''}
    //                                     </Col>

    //                                     <Col span={12}>
    //                                         <div className="b-text">Class Name</div>
    //                                         {student.className}
    //                                     </Col>
    //                                 </Row>
    //                             </div>

    //                             <div className="pay_info">
    //                                 <div className="pay_info_head">
    //                                     <div className="b-text">Payment Method</div>
    //                                     <div className="light-txt">Choose how you'd like to pay for Fees</div>
    //                                 </div>

    //                                 <div className="pay-tabs">
    //                                     <Tabs
    //                                         animated={{ inkBar: true, tabPane: false }}
    //                                     >
    //                                         <TabPane tab={"Credit/Debit Card"} key='credit/debitCard'>
    //                                             {iFrameLoading ? <div className="loader"><Spin size="large" spinning={iFrameLoading || true} tip={'Please Wait...'} /></div> : ''}
    //                                             <form name="tokenform" id="tokenform">
    //                                                 <iframe 
    //                                                     title=" " 
    //                                                     onLoad={()=>setIframeLoader(false)}
    //                                                     id="tokenframe" 
    //                                                     name="tokenframe" 
    //                                                     src={`${config.CC_TOKENIZER_URL}?useexpiry=true&usecvv=true&cardlabel=Card Number*&expirylabel=Expiration Date*&cvvlabel=CVV*
    //                                                         &placeholder=${encodeURIComponent("16 Digit Card Number")}&placeholdermonth=MM&placeholderyear=YYYY&placeholdercvv=${encodeURIComponent("3 Digit CVV Code")}
    //                                                         &cardnumbernumericonly=true&cardinputmaxlength=16&cvvnumericonly=true&cvvnumbermaxlength=3&fullmobilekeyboard=true
    //                                                         &usemonthnames=true
    //                                                         &invalidcreditcardevent=true&invalidcvvevent=true&tokenizewheninactive=true
    //                                                         &formatinput=true
    //                                                         &autofocus=true
    //                                                         &css=
    //                                                             ${encodeURIComponent(`
    //                                                                 .error{
    //                                                                     color:%20red;
    //                                                                 }
                                                                    
    //                                                                 label{ 
    //                                                                     font-family: Segoe UI, Droid Sans, Tahoma, Arial, sans-serif; 
    //                                                                     font-size:12px; 
    //                                                                     float:left; 
    //                                                                     display:flex; 
    //                                                                     text-align: left; 
    //                                                                     margin-top: 5px;
    //                                                                 } 
    //                                                                 input{
    //                                                                     border: 1px solid #e7e7e7;
    //                                                                     height: 38px;
    //                                                                     display:inline-block;
    //                                                                     color: rgba(0,0,0,0.65);
    //                                                                 }
    //                                                                 input:focus,
    //                                                                 input:active {
    //                                                                     border: 1px solid #0075FF;
    //                                                                     border-color: #0075FF;
    //                                                                 }

    //                                                                 select {
    //                                                                     height: 38px;
    //                                                                     margin: 5px 0px 5px;
    //                                                                     border: solid 1px #e7e7e7;
    //                                                                     border-radius: 2px;
    //                                                                     background-color: transparent;
    //                                                                     color: rgba(0,0,0,0.65);
    //                                                                 }
    //                                                                 select:focus, 
    //                                                                 select:active {
    //                                                                     border: 1px solid #0075FF;
    //                                                                 }

    //                                                                 #cccardlabel, #ccexpirylabel, #cccvvlabel{
    //                                                                     color: rgba(0, 0, 0, 0.65);
    //                                                                 }

    //                                                                 #ccnumfield{
    //                                                                     width:100%;
    //                                                                     padding: 8px;
    //                                                                     margin: 5px 0 5px 0;
    //                                                                     -webkit-box-sizing: border-box;
    //                                                                     -moz-box-sizing: border-box;
    //                                                                     box-sizing: border-box;
    //                                                                 }
                                                                    
    //                                                                 #ccexpirymonth{
    //                                                                     width:30%;
    //                                                                 } 
    //                                                                 #ccexpiryyear{
    //                                                                     width:30%;
    //                                                                 }
    //                                                                 #cccvvfield{
    //                                                                     width:120px;
    //                                                                     padding: 8px;
    //                                                                     margin: 5px 0 5px 0;
    //                                                                     -webkit-box-sizing: border-box;
    //                                                                     -moz-box-sizing: border-box;
    //                                                                     box-sizing: border-box;
    //                                                                 } 
    //                                                                 #tokenform br{display:none;}`
    //                                                             )}
    //                                                         `}
    //                                                     scrolling="no"
    //                                                     width="100%" 
    //                                                     height="260px" 
    //                                                     frameBorder="0"
    //                                                 />
    //                                                 <input type="hidden" name="mytoken" id="mytoken" />
    //                                             </form>

                                        
    //                                         </TabPane>

    //                                         {/* <TabPane tab="UPI" key="upi">
    //                                             By UPI (PhonePe/GPay/PayTm)
    //                                         </TabPane>

    //                                         <TabPane tab="Internet Banking" key="internet_banking">
    //                                             Internet Banking
    //                                         </TabPane>

    //                                         <TabPane tab="Cash" key="cash">
    //                                             By Cash
    //                                         </TabPane> */}
    //                                     </Tabs>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                         <div className="pay_details_r">
    //                             <div className="pay_details_inner">
    //                                 <div className="inner_head">
    //                                     <img src={FeesIcon} alt="fees" height="50px" width="50px" /> <span className="b-text" style={{fontSize: '16px'}}>Fee Details</span>
    //                                 </div>
    //                                 <div className="inner_details">
    //                                     <Row gutter={[8, 16]}>
    //                                         {feeStructList.length ? feeStructList.map((item, i)=>{
    //                                             return <>
    //                                                 <Col span={20}>{item.feeType}</Col>
    //                                                 <Col span={4}>{item.amount}</Col>
    //                                             </>
    //                                         })
    //                                         :
    //                                         ''
    //                                         }
    //                                     </Row>
    //                                     <div className="total_bar">
    //                                         <Row>
    //                                             <Col span={20}>
    //                                                 <div className="b-text">Total</div>
    //                                             </Col>
    //                                             <Col span={4}>
    //                                             {(feeStructList || [])
    //                                                 .reduce(
    //                                                     (amt, feeObj) => amt + feeObj.amount, 0
    //                                                 )
    //                                             }
    //                                             </Col>
    //                                             <Col span={24}>
    //                                                 <Button
    //                                                     // disabled={props.paymentBtnClicked}
    //                                                     type="primary"
    //                                                     style={{width: '100%'}}
    //                                                     onClick={()=>{
    //                                                         submitPay();
    //                                                     }}
    //                                                 >
    //                                                     Pay Now 
    //                                                 </Button>
    //                                             </Col>
    //                                         </Row>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </> 
    //             }           
    //         </div>
}

export default RegistrationResult;