import React from 'react';
import { FormattedMessage } from "react-intl";
import { Col, DatePicker, Row, Skeleton ,Tooltip} from "antd";
import { Dropdown, DropdownRef } from "../../../../general/Dropdown"
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { CONSTANTS, LIST_CONSTANTS,ICONS } from '../../../../../static/constants';
import moment from 'moment-timezone';
import { getMomentDateForUI } from '../../../../../utils';

function Personal(props) {
  const { setEmpInfo, empInfo } = props;
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;
  return <>
    <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="i-label">First Name
            <span className="required">*</span>
          </div>
          <TextBox
            placeholder="First Name"
            type="text"
            value={empInfo.firstName}
            onChange={(e) => {
              setEmpInfo({
                ...empInfo, firstName: e.target.value, fullName: (e.target.value || "") +
                  " " +
                  (empInfo.lastName || "")
              })

            }}
          />
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.firstName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Last Name
            <span className="required">*</span>
          </div>
          <TextBox
            placeholder="Last Name"
            type="text"
            maxLength={20}
            value={empInfo.lastName}
            onChange={(e) => {
              setEmpInfo({ ...empInfo, lastName: e.target.value })
            }}
          />
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.lastName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Email
            <span className="required">*</span>
          </div>
          <TextBox
            placeholder="abc@gmail.com"
            type="text"
            maxLength={50}
            value={empInfo.emailAddress}
            onChange={(e) => {
              setEmpInfo({ ...empInfo, emailAddress: e.target.value })
            }}
          />
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.emailAddress }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        
        <Col span={12}>
          <div className="i-label">Phone Number
            <span className="required">*</span>
          </div>
          <div className='d-flex'>
            <DropdownRef
              style={{ width: "20%" }}
              items={
                (props.countries || []).filter((el) => {
                  return el.countryCallingCode != null;
                }) || []
              }
              
              optionKeyName="countryId"
              valueKeyName="countryCallingCode"
              defaultValue={empInfo.countryCallingCode}
              className="currency-tags"
              onSelect={(selectedValue, option) => {
                const selectedCountry = props.countries.find((el) => el.countryId === selectedValue);

                if (selectedCountry) {
                  setEmpInfo({ ...empInfo, countryCallingCode: selectedCountry.countryCallingCode })
                }

              }}
            />
            <TextBox
              placeholder="Phone Number"
              className="textbox-pd"
              type="text"
              maxLength={10}
              value={empInfo.cellNumber}
              onChange={(e) => {
                setEmpInfo({ ...empInfo, cellNumber: e.target.value })
              }}
            />
          </div>
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.cellNumber }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Date of Birth <span>
          <Tooltip
                      trigger="click"
                      placement="top"
                      title={props.intl.formatMessage({
                        id: "tooltip.employeeAge",
                        defaultMessage: "",
                      })}
                   
                    >
          <i className={`${ICONS.HELP} cursor-pointer`} />
          </Tooltip>
            </span></div>
          <DatePicker
            allowClear={false}     
            defaultValue={(moment().subtract(14, 'years'))} 
            onChange={(selectedDate) => {
              setEmpInfo({ ...empInfo, dob: selectedDate })
            }}
           
            disabledDate={(current) =>
              current && (current.isAfter(moment().endOf('day')) || current.isAfter(moment().subtract(14, 'years').startOf('day')))         
          }
          
          
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Gender<span className="required">*</span></div>
          <Dropdown
            items={LIST_CONSTANTS.GENDER_LIST}
            value={empInfo.gender}
            placeholder={'Select Gender'}
            onChange={(val) => {
              setEmpInfo({ ...empInfo, gender: val })
            }}
          />

          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.gender }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Religion</div>
          <Dropdown
            items={LIST_CONSTANTS.RELIGION_LIST}
            value={empInfo.religion}
            placeholder={'Select Religion'}
            onChange={(val) => {
              setEmpInfo({ ...empInfo, religion: val })
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Marital Status</div>
          <Dropdown
            items={LIST_CONSTANTS.MARITAL_STATUS_LIST}
            value={empInfo.maritalStatus}
            placeholder={'Select Marital Status'}
            onChange={(val) => {
              setEmpInfo({ ...empInfo, maritalStatus: val });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Emergency Contact Name<span className="required">*</span></div>
          <TextBox
            placeholder="Emergency Contact Name"
            type="text"
            value={empInfo.emergencyContactName}
            onChange={(e) => {
              setEmpInfo({ ...empInfo, emergencyContactName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.emergencyContactName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Emergency Contact Number<span className="required">*</span></div>
          <TextBox
            placeholder="Emergency Contact Number"
            type="text"
            maxLength={10}
            value={empInfo.emergencyContactNumber}
            onChange={(e) => {
              setEmpInfo({ ...empInfo, emergencyContactNumber: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.emergencyContactNumber }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Address 1<span className="required">*</span></div>
          <TextBox
            placeholder="Address 1"
            type="text"
            value={empInfo.location?.streetAddress1}
            onChange={(e) => {
              setEmpInfo({
                ...empInfo, location: {
                  ...(empInfo?.location || {}),
                  streetAddress1: e.target.value
                }
              });
            }}
          />
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.location?.streetAddress1 }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />

        </Col>
        <Col span={12}>
          <div className="i-label">Address 2</div>
          <TextBox
            placeholder="Address 2"
            type="text"
            value={empInfo.location?.streetAddress2}
            onChange={(e) => {
              setEmpInfo({
                ...empInfo,
                location: {
                  ...(empInfo?.location || {}),
                  streetAddress2: e.target.value
                }
              });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">City<span className="required">*</span></div>
          <TextBox
            placeholder="City"
            type="text"
            maxLength={20}
            value={empInfo.location?.cityName}
            onChange={(e) => {
              setEmpInfo({
                ...empInfo,
                location: {
                  ...(empInfo?.location || {}),
                  cityName: e.target.value
                }
              });
            }}
          />
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.location?.cityName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">ZIP/Postal Code<span className="required">*</span></div>
          <TextBox
            placeholder="ZIP/Postal Code"
            type="text"
            maxLength={20}
            countStyle={{ top: '40px' }}
            value={empInfo.location?.zipCode}
            onChange={(e) => {
              setEmpInfo({
                ...empInfo,
                location: {
                  ...(empInfo?.location || {}),
                  zipCode: e.target.value
                }
              });
            }}
          />
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.location?.zipCode }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Country<span className="required">*</span></div>
          <Dropdown
            items={props.countries}
            value={empInfo.location?.countryName}
            valueKeyName='countryName'
            optionKeyName='countryId'
            placeholder="Select Country"
            onSelect={(optionValue, option) => {
              setEmpInfo({ ...empInfo, location: { ...empInfo.location, countryId: option.value, countryName: option.name, stateId: undefined, stateName: undefined } });
              if (option.name === "United States (USA)" || option.name === 'India') {
                props.fetchStates(option.value);
              }
            }}
          />
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.location?.countryName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">State/Province/Region<span className="required">*</span></div>
          {empInfo.location?.countryName === "United States (USA)" || empInfo.location?.countryName === 'India' ?
            <Dropdown
              items={props.states}
              value={empInfo.location?.stateName}
              valueKeyName='stateName'
              optionKeyName='stateId'
              placeholder="Select State"
              onSelect={(optionValue, option) => {
                setEmpInfo({ ...empInfo, location: { ...empInfo.location, stateId: option.value, stateName: option.name } });
              }}
            />
            :
            <TextBox
              placeholder="State Name"
              type="text"
              value={empInfo.location?.stateName}
              onChange={(e) => {
                setEmpInfo({ ...empInfo, location: { ...empInfo.location, stateName: e.target.value } });
              }}
            />
          }
          <ErrorMsg
            validator={() => { return !empInfo.submitted || empInfo.location?.stateName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
      </Row>
    </Skeleton>
  </>
}
export default Personal;