import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EnrollStudentComponent from '../../../../../components/modal/modalBody/schoolManagement/EnrollStudent';
import {saveOrUpdateStudentEnrollment} from './action';
import { getAllStudentForm } from '../../../../Student/studentFormList/action';
import { TEXT_CONSTANTS } from '../../../../../static/constants';

// import * as find from 'lodash.find';

class EnrollStudent extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }
    componentWillMount() {

        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 500,relationshipEmployeeId: userInfo.relationshipEmployeeId,formId: 0 };
        this.props.getAllStudentForm(payload);
    }

    render() {
        return <EnrollStudentComponent {...this.props} {...this.state} updateModalState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        classList: state.school.classList,
        userInfo: state.common.userInfo,
        enrollmentForms: (state.membershipReducer.studentFormList || []).filter(obj => obj.formType === TEXT_CONSTANTS.ENROLEMENT_FORM),
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveOrUpdateStudentEnrollment,
    getAllStudentForm,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps) (EnrollStudent);