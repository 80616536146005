import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailDrawer from '../../../components/drawer/feeReminderEmail/EmailDrawer';
import { fetchEmailAddress, loadEmailData } from './action';
import { generatePDF } from './../../../actions/downloadFileAction';

class recurringInvoiceEmail extends Component {

  constructor(props){
    super(props);
    let data = {}
    if (props.isFormSetting && props.feeReminderEmailDetails) {
      data = props.feeReminderEmailDetails
    } else {
      data = {};
    }
    this.state = {
      sender: data.sender,
      sendMeACopy: data.sendMeACopy,
      ccRecievers: data.cc || [],
      bccRecievers: data.bcc || [],
      emailBody: data.message,
      subject: data.subject,
      receiverItems: [],
      receivers: data.to || [],
      ccVisible: (data.cc && data.cc.length) ? true: false,
      bccVisible: (data.bcc && data.bcc.length) ? true: false,
      isSendInvoicePdf: data.isSendInvoicePdf || 0,
      updateReminder: props.updateReminder,
      isBulkInvoice: props.isBulkInvoice || false,
      docName: props.docName || 'Sales Invoice'
    }
  }
  
  componentDidMount() {
    !this.props.emailDetails && this.props.loadEmailData(this);
  }

  initCalls() {
    this.props.fetchEmailAddress({
      customerId: (this.props || {}).customerId,
      relationshipId: (this.props.companyInfo || {}).relationshipId
    })
    
  }

  componentWillReceiveProps(props) {
    if(props.emailAddressList && props.emailAddressList.length){
      this.setState({
        receiverItems:  props.emailAddressList,
        ccRecievers:  props.emailAddressList,
        bccRecievers:  props.emailAddressList
      })
    }
  }


  render() {
    return <div>
      <EmailDrawer initCalls={() => {
        this.initCalls();
      }} {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isEmailSpinner: state.common.isEmailSpinner,
    emailAddressList: state.common.emailAddressList
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchEmailAddress,
  generatePDF,
  loadEmailData
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(recurringInvoiceEmail);
