import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ShipmentInformationComponent from '../../../../../components/modal/modalBody/customer/ShipmentInformation';
import { fetchSalesOrderShipment, updateActualDeliveryDate, markAsDelivered, updateInventory } from './action';
import { fetchDataIfNeeded } from  '../../../../../utils';
import {getQualityChecklistSettings } from '../../../../../actions/commonActions';
import { fetchSODetail } from '../SODetail/action';
class ShipmentInformation extends Component {
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            customerId: this.props.soData.customerId,
            salesOrderMasterId: this.props.soData.salesOrderMasterId,
            relationshipId: companyInfo.relationshipId

        }
        fetchDataIfNeeded('fetchSalesOrderShipment', 'soShipmentList', this.props, payload, true);
        fetchDataIfNeeded('getQualityChecklistSettings', 'qualityChecklistSetting', this.props, payload);
        this.props.fetchSODetail(payload)
    }

    render() {
        return <ShipmentInformationComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return { 
        soShipmentList: state.so.soShipmentList,
        soDetail: state.so.soDetail,
        qualityChecklistSetting: state.settings.qualityChecklistSetting
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSalesOrderShipment,
    updateActualDeliveryDate, 
    markAsDelivered,
    updateInventory,
    getQualityChecklistSettings,
    fetchSODetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentInformation);