import React, {useState} from 'react';
import './general.scss';
export function TextArea(props) {
  let className = props.className;

  if (props.value && props.validator && !props.validator(props.value)) {
    className = props.invalidClassName || 'input-text-error text-red';
  }
  let [isFocus, setIsFocus] = useState(false)
  return (
   <div>
      <textarea
      placeholder={props.placeholder}
      maxLength={props.maxLength}
      rows={props.rows}
      className={className}
      value={props.value}
      disabled={props.disabled}
      onChange={props.onChange}
      style={{...props.style ,width:"100%",resize:"none",position:"relative",padding:"10px 10px"}}
      onFocus={() => {
        setIsFocus(true);
      }}
      onBlur={()=>{
        setIsFocus(false);
      }}
    />
      {isFocus ? (
        <div
          style={{
            right: "20px",
            top: "auto",
            textAlign: "right",
            position: "absolute",
            ...props.countStyle,
          }}
        >
          {props.maxLength && (props.value || "").length
            ? props.value.length + " / " + props.maxLength + " chars"
            : ""}
        </div>
      ) : null}
     </div>
  )
}