import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Pagination, Skeleton, Dropdown, Menu, Empty } from "antd";
import { fetchPaginationDataIfNeeded, getUserDefinedName, sortTable } from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
// import { MODAL_TYPE } from '../../../static/constants'
// import { getLabelName } from '../../modal/modalBody/customerInfo';
import { MoreOutlined } from '@ant-design/icons';
import { ICONS } from "../../../static/constants";
// import checkIcon from '../../../assets/images/check.png';
// import crossIcon from '../../../assets/images/cross.png';


const CourseTerm = (props) => {
  const { courseTermList, updateState, companyInfo, courseTermCount, pageNumber, dir=0 } = props;
  let termList = courseTermList[pageNumber] || [];
  termList.sort((a, b)=> a.dateCreated < b.dateCreated ? 1 : -1);
  

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.curriculum' defaultMessage='' />,
    },
    {
      name: getUserDefinedName('sidebar.menuItem.courseTerm',props),
    }
  ];
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getCourseTermList", "courseTermList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getCourseTermList(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };


//   const addItemConfirmationModal = (props, text, modalType) => {
//     const { showModal, companyInfo } = props;
//     const labelName = getLabelName(modalType);
//     const modalBody = <span>
//         <FormattedMessage
//             id='addItem.confirmation'
//             values={{ value: `${text} to ` }}
//             defaultMessage=''
//         />
//         {labelName} ?
//     </span>;


//     const modalData = {
//         modalBody,
//         handleSubmit: () => {
//           props.saveCourseTerm({ 
//             termName: text, 
//             relationshipId: companyInfo.relationshipId,
//             createdByEmpId: (props.userInfo || {}).userId,
//            }, props);
//             props.hideModal();
//         },
//     };
//     showModal(modalData);
// }


// const newCourseTerm = () => {
//     const formData = {textBoxValue: '', maxLength: 75};
//     let payload = {formData: formData }
//     const { showModal } = props;

//         const data = {
//             title: <FormattedMessage id='courseTerm.text' />,
//             formData,
//             modalData: { modalType: MODAL_TYPE.COURSE_TERM, data: payload },
//             handleSubmit: (formData = {}) => {
//                 if (formData.textBoxValue && formData.textBoxValue.length) {
//                     addItemConfirmationModal(props, formData.textBoxValue, MODAL_TYPE.COURSE_TERM);
//                 } else {
//                     data.formData.submittedOnce = true;
//                     showModal(data);
//                 }
//             }
//         };
//         showModal(data);
// }

const newCourseTerm = (termData) => {
  props.updateHeaderState({
    schoolDrawers: {
      ...props.schoolDrawers,
      addCourseTermDrawerVisible: true,
    },
    getCourseTermList: props.getCourseTermList,
    termData: termData
  });
};

const actionMenu = (e) => {
  return (
      <Menu className="row-action-dropdown">

        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="1" value="edit" onClick={()=>{
          newCourseTerm(e);
          }}
        >
          <i className={ICONS.EDIT}/>
          Edit
        </Menu.Item>

        <Menu.Item key="2" onClick={()=>
          {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteCourseTerm(e, props);
              }
            })
          }
        }>
          <i className={ICONS.DELETE}/>
          Delete
        </Menu.Item>

      </Menu >
  )
};

const sortColumn = (e) => {
  sortTable(document.getElementById('courseTerm-table'), e.target.cellIndex, 1 - dir);
  updateState({ dir: 1 - dir })
}
  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='sidebar.menuItem.curriculum' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button className="create-action-btn" onClick={()=>{
            newCourseTerm();
          }}>
              <i className={ICONS.ADD}/> <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>
      <div className="view-container">
          <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} active>

            <div className="left-actions">
              <div className="table-heading">
                {getUserDefinedName('schoolManagement.courseTerm.table.heading',props)}
              </div>
              <div className="vertical-sep" />
              {
                courseTermCount ? 
                <>
               
                  <div>{courseTermCount}</div>
                  </>
                :0
              }
            </div>
            <div className="right-actions">
            </div>
          </Skeleton>
          </div>
          <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>

          <div className="table-container">
            <table id="courseTerm-table">
              <thead>
                <tr>
                  <th width='6%' onClick={sortColumn}><FormattedMessage id="serial.no"/></th>
                  <th onClick={sortColumn}>{getUserDefinedName('sidebar.menuItem.term',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>{getUserDefinedName('Term Code',props)} <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}><FormattedMessage id="schoolManagement.courseTerm.certificateAwarded" defaultMessage="" /> <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}><FormattedMessage id="schoolManagement.courseTerm.certificateName" defaultMessage="" /> <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}><FormattedMessage id="schoolManagement.courseTerm.certificateMessage" defaultMessage="" /> <i class="fi fi-rr-sort-alt"></i></th>
                  <th width='6%' className="text-center" ><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
                </tr>
              </thead>
              <tbody>
                {
                  (termList || [])?.length ? ( (termList || []).map((e, i) => {
                    return <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{e.termName||<div className="empty-data-box"/>}</td>
                      <td>{e.termCode||<div className="empty-data-box"/>}</td>
                      <td>{e.isCertificateAwarded ? "Yes" : "No"}</td>
                      <td>{e.certificateName?e.certificateName:<div className="empty-data-box"/>}</td>
                      <td>{e.certificateMessage?e.certificateMessage:<div className="empty-data-box"/>}</td>
                      <td className="text-center">
                        <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                          <i className={ICONS.MORE} />
                        </Dropdown>
                      </td>
                    </tr>
                  })):(<tr key="empty">
                    <td colSpan={"100%"}>
                      <Empty />
                    </td>
                  </tr>)
                 
                }
              </tbody>
            </table>
          </div>
          
        </Skeleton>

        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={courseTermCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </Skeleton>
        </div>
      </div>
    </>
  );
};

export default injectIntl(CourseTerm);
