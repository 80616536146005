/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Input, Button, Checkbox } from 'antd';
import { Row, Col } from 'antd';
import PageBreadcrumb from "../../../PageBreadcrumb";
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { ErrorMsg } from '../../../general/ErrorMessage';
import upsIcon from '../../../../assets/images/ups-logo.png';
import uspsIcon from '../../../../assets/images/usps-logo.png';
import fedexIcon from '../../../../assets/images/fedex-logo.png';

const ShippingRuleComponent = (props) => {
    const { shippingChoices,
        countryShippingRule,
        marketplaceShippingCountryId
    } = props;

    const breadCrumbList = [
        {
            name: 'Settings',
            link: '/admin/show-settings'
        },
        {
            name: 'Shipping',
            link: '/admin/settings/shipping'

        },
        {
            name: 'Shipping-Rule'
        }
    ];

    const validateFreeShippingRule = () => {
        if (
            !countryShippingRule[marketplaceShippingCountryId].shippingNameFreeShipping
            || (countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFreeShipping && !countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFreeShipping)
        ) {
            return false;
        }
        return true;
    }

    const validateFlatRateShippingRule = () => {
        if (
            !countryShippingRule[marketplaceShippingCountryId].shippingNameFlatShipping
            || !countryShippingRule[marketplaceShippingCountryId].flatRate
            || (countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFlatShipping && !countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFlatShipping)
            || (countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceFlatRate && !countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceFlatRate)
            || (countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeFlatRate && !countryShippingRule[marketplaceShippingCountryId].extraShippingFeeFlatRate)
        ) {
            return false;
        }
        return true;
    }

    const validateRateByPriceShippingRule = () => {
        if (
            !countryShippingRule[marketplaceShippingCountryId].shippingNameRateByPrice
            || (countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByPrice && !countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByPrice)
            || (countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceRateByPrice && !countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceRateByPrice)
            || (countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByPrice && !countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByPrice)
        ) {
            return false;
        }

        for (let i = 0; i < countryShippingRule[marketplaceShippingCountryId].priceRange.length; i++) {
            let obj = countryShippingRule[marketplaceShippingCountryId].priceRange[i];
            if (!obj.startPrice || !obj.endPrice || !obj.rate) {
                return false;
            }
        }
        return true;
    }

    const validateRateByWeightShippingRule = () => {
        if (
            !countryShippingRule[marketplaceShippingCountryId].shippingNameRateByWeight
            || (countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByWeight && !countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByWeight)
            || (countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingWeight && !countryShippingRule[marketplaceShippingCountryId].offerFreeShippingWeight)
            || (countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByWeight && !countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByWeight)
        ) {
            return false;
        }

        for (let i = 0; i < countryShippingRule[marketplaceShippingCountryId].weightRange.length; i++) {
            let obj = countryShippingRule[marketplaceShippingCountryId].weightRange[i];
            if (!obj.startWeight || !obj.endWeight || !obj.rate) {
                return false;
            }
        }
        return true;
    }

    const validateCarrierRateShippingRule = () => {
        for (let i = 0; i < countryShippingRule[marketplaceShippingCountryId].carrierService.length; i++) {
            let service = countryShippingRule[marketplaceShippingCountryId].carrierService[i];
            if (service.displayAtCheckout && (!service.displayServiceName || !service.displayServiceDescription || !service.price || !service.weight)) {
                return false;
            }
        }
        return true;
    }

    const validateShippingRule = () => {
        if (countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 1) {
            return validateFreeShippingRule();
        }

        if (countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 2) {
            return validateFlatRateShippingRule();
        }

        if (countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 3) {
            return validateRateByWeightShippingRule();
        }

        if (countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 4) {
            return validateRateByPriceShippingRule();
        }

        if (countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 5) {
            return validateCarrierRateShippingRule();
        }
    }

    const handleSubmit = (props) => {
        if (validateShippingRule()) {
            const payload = {
                shippingNameFreeShipping: countryShippingRule[marketplaceShippingCountryId].shippingNameFreeShipping,
                shippingNameFlatShipping: countryShippingRule[marketplaceShippingCountryId].shippingNameFlatShipping,
                shippingNameRateByWeight: countryShippingRule[marketplaceShippingCountryId].shippingNameRateByWeight,
                shippingNameRateByPrice: countryShippingRule[marketplaceShippingCountryId].shippingNameRateByPrice,

                estimatedDeliveryFreeShipping: countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFreeShipping,
                estimatedDeliveryFlatShipping: countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFlatShipping,
                estimatedDeliveryRateByWeight: countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByWeight,
                estimatedDeliveryRateByPrice: countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByPrice,

                isShowEstimatedDeliveryFreeShipping: countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFreeShipping ? 1 : 0,
                isShowEstimatedDeliveryFlatShipping: countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFlatShipping ? 1 : 0,
                isShowEstimatedDeliveryRateByWeight: countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByWeight ? 1 : 0,
                isShowEstimatedDeliveryRateByPrice: countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByPrice ? 1 : 0,

                flatRate: countryShippingRule[marketplaceShippingCountryId].flatRate,

                isOfferFreeShippingPriceFlatRate: countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceFlatRate ? 1 : 0,
                isOfferFreeShippingWeight: countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingWeight ? 1 : 0,
                isOfferFreeShippingPriceRateByPrice: countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceRateByPrice ? 1 : 0,

                offerFreeShippingPriceFlatRate: countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceFlatRate,
                offerFreeShippingWeight: countryShippingRule[marketplaceShippingCountryId].offerFreeShippingWeight,
                offerFreeShippingPriceRateByPrice: countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceRateByPrice,


                isExtraShippingFeeFlatRate: countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeFlatRate ? 1 : 0,
                isExtraShippingFeeRateByWeight: countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByWeight ? 1 : 0,
                isExtraShippingFeeRateByPrice: countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByPrice ? 1 : 0,

                extraShippingFeeFlatRate: countryShippingRule[marketplaceShippingCountryId].extraShippingFeeFlatRate,
                extraShippingFeeRateByWeight: countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByWeight,
                extraShippingFeeRateByPrice: countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByPrice,

                priceRange: JSON.stringify(countryShippingRule[marketplaceShippingCountryId].priceRange),
                weightRange: JSON.stringify(countryShippingRule[marketplaceShippingCountryId].weightRange),
                carrierService: JSON.stringify(countryShippingRule[marketplaceShippingCountryId].carrierService),

                marketplaceShippingRuleId: countryShippingRule[marketplaceShippingCountryId].marketplaceShippingRuleId,
                marketplaceShippingChoiceId: countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId,
                marketplaceShippingCountryId: marketplaceShippingCountryId,
                relationshipId: props.relationshipId,
                version: countryShippingRule[marketplaceShippingCountryId].version,
                dateCreated: countryShippingRule[marketplaceShippingCountryId].dateCreated,
                createdByEmpId: countryShippingRule[marketplaceShippingCountryId].createdByEmpId
            };

            props.pushModalToStack({
                modalBody: countryShippingRule[marketplaceShippingCountryId].marketplaceShippingRuleId ? <FormattedMessage id='commons.edit.popup.confimation.msg' defaultMessage='' /> : <FormattedMessage id='commons.create.popup.confimation.msg' defaultMessage='' />,
                handleSubmit: () => {
                    props.saveUpdateShippingRule(payload, props);
                }
            });
        } else {
            props.updateState({
                submittedOnce: true,
            })
        }
    }

    return (
        <Fragment>
            {shippingChoices && shippingChoices.length > 0 && countryShippingRule && countryShippingRule[marketplaceShippingCountryId] ?
                <div className="ledger-details-container">
                    <div className="ptb10">
                        <PageBreadcrumb breadCrumbList={breadCrumbList} />
                    </div>

                    <div className="view-container sales-order-details mt0">
                        <div className='team-and-free'>
                            <Row>
                                {shippingChoices.length > 0 ? shippingChoices.map((obj) =>

                                    <Col key={obj.marketplaceShippingChoiceId} className=" new-check" span={6}>
                                        <input className="checkbox-tools" type="Radio" name="tools"
                                            onChange={() => {
                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                countryShipRule.marketplaceShippingChoiceId = obj.marketplaceShippingChoiceId;
                                                props.updateState({
                                                    countryShippingRule: {
                                                        ...countryShippingRule,
                                                        marketplaceShippingCountryId: countryShipRule
                                                    },
                                                    submittedOnce: false,
                                                    // marketplaceShippingChoiceId: obj.marketplaceShippingChoiceId,
                                                });
                                            }}
                                            id={obj.marketplaceShippingChoiceId}
                                            checked={obj.marketplaceShippingChoiceId === countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId}
                                        />
                                        <label className="for-checkbox-tools" htmlFor={obj.marketplaceShippingChoiceId}>
                                            {obj.marketplaceShippingChoiceId === 1 ? <div className='free-shop cltys'></div> : ''}
                                            {obj.marketplaceShippingChoiceId === 2 ? <div className='flat-rate cltys'></div> : ''}
                                            {obj.marketplaceShippingChoiceId === 3 ? <div className='rate-weight cltys'></div> : ''}
                                            {obj.marketplaceShippingChoiceId === 4 ? <div className='rate-price cltys'></div> : ''}
                                            {obj.marketplaceShippingChoiceId === 5 ? <div className='carrier-rate cltys'></div> : ''}
                                            {obj.marketplaceShippingChoiceId === 6 ? <div className='pickup cltys'></div> : ''}
                                            {obj.marketplaceShippingChoiceId === 7 ? <div className='local-delivery cltys'></div> : ''}

                                            {obj.choiceName} <br />
                                            <span>{obj.choiceDescription} </span>
                                        </label>
                                    </Col>

                                ) : ''}
                            </Row>
                        </div>
                        <div className='ship-line-color'></div>
                        {
                            countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 1 ?
                                <div className=" " style={{ padding: '15px', borderTop: 'solid 0px #ddd' }}>

                                    <h2>
                                        <FormattedMessage id='marketplaceConfiguration.shippingRule.form.name.freeShipping' defaultMessage='' />
                                    </h2>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col className="text-right" span={5}>
                                            <span className="">
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.checkoutShippingName.level' defaultMessage='' /></span><span className="required">
                                                *
                                            </span>
                                        </Col>
                                        <Col span={6}>
                                            <TextBox
                                                minLength={1}
                                                maxLength={40}
                                                type='text'
                                                className={(props.submittedOnce && !countryShippingRule[marketplaceShippingCountryId].shippingNameFreeShipping) ? 'input-text-error' : ''}
                                                value={countryShippingRule[marketplaceShippingCountryId].shippingNameFreeShipping}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.shippingNameFreeShipping = e.target.value;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'marketplaceConfiguration.shippingRule.form.field.checkoutShippingName.placeholder', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].shippingNameFreeShipping }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>

                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5} className="text-right">
                                            <span className="">
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.level' defaultMessage='' /></span><span className="required">
                                            </span>
                                        </Col>

                                        <Col span={6} className='input-currency-control ltiy'>
                                            <span className="currency-tag" style={{ paddingTop: '3px' }}>
                                                <Checkbox
                                                    checked={countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFreeShipping}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.isShowEstimatedDeliveryFreeShipping = e.target.checked;
                                                        props.updateState({
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}></Checkbox>
                                            </span>

                                            <TextBox
                                                minLength={1}
                                                maxLength={40}
                                                type='text'
                                                className={(props.submittedOnce && countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFreeShipping && !countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFreeShipping) ? 'input-text-error' : ''}
                                                value={countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFreeShipping}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.estimatedDeliveryFreeShipping = e.target.value;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.placeholder', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || !countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFreeShipping || countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFreeShipping }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>
                                </div> : ''
                        }

                        {
                            countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 2 ?
                                <div className=" " style={{ padding: '15px' }}>
                                    <h2><FormattedMessage id='marketplaceConfiguration.shippingRule.form.name.flatRate' defaultMessage='' /></h2>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5} className='text-right'>
                                            <span className="">
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.checkoutShippingName.level' defaultMessage='' /></span><span className="required">
                                                *
                                            </span>
                                        </Col>
                                        <Col span={6}>
                                            <TextBox
                                                minLength={1}
                                                maxLength={40}
                                                type='text'
                                                className={(props.submittedOnce && !countryShippingRule[marketplaceShippingCountryId].shippingNameFlatShipping) ? 'input-text-error' : ''}
                                                value={countryShippingRule[marketplaceShippingCountryId].shippingNameFlatShipping}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.shippingNameFlatShipping = e.target.value;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'marketplaceConfiguration.shippingRule.form.field.checkoutShippingName.placeholder', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].shippingNameFlatShipping }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5} className='text-right'>
                                            <span className="">
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.level' defaultMessage='' /></span><span className="required">
                                            </span>
                                        </Col>
                                        <Col span={6} className='input-currency-control ltiy'>
                                            <span className="currency-tag" style={{ paddingTop: '3px' }}>
                                                <Checkbox
                                                    checked={countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFlatShipping}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.isShowEstimatedDeliveryFlatShipping = e.target.checked;
                                                        props.updateState({
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}></Checkbox>
                                            </span>
                                            <TextBox
                                                minLength={1}
                                                maxLength={40}
                                                type='text'
                                                className={(props.submittedOnce && countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFlatShipping && !countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFlatShipping) ? 'input-text-error' : ''}
                                                value={countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFlatShipping}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.estimatedDeliveryFlatShipping = e.target.value;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.placeholder', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || !countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryFlatShipping || countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryFlatShipping }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5} className='text-right'>
                                            <label>
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.rate.level' defaultMessage='' />
                                            </label>
                                        </Col>
                                        <Col span={6} className="input-currency-control">
                                            <span className="currency-tag">
                                                <i className="fa fa-dollar"></i>
                                            </span>
                                            <TextBox
                                                className={(props.submittedOnce && (countryShippingRule[marketplaceShippingCountryId].flatRate <= 0 || !countryShippingRule[marketplaceShippingCountryId].flatRate)) ? 'input-text-error' : ''}
                                                type='number'
                                                value={countryShippingRule[marketplaceShippingCountryId].flatRate || null}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.flatRate = e.target.value;
                                                    props.updateState({
                                                        //flatRate: e.target.value,
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage(
                                                    {
                                                        id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                        defaultMessage: ''
                                                    })
                                                }
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].flatRate > 0 }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>

                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5}></Col>
                                        <Col span={13} >
                                            <Checkbox
                                                checked={countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceFlatRate}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.isOfferFreeShippingPriceFlatRate = e.target.checked;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }} >
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.offerFreeShipping.level' defaultMessage='' />
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    {countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceFlatRate || countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceFlatRate === 1 ?
                                        <Row style={{ paddingBottom: '15px' }}>
                                            <Col span={5}></Col>
                                            <Col span={2} className="input-currency-control">
                                                <span className="currency-tag">
                                                    <i className="fa fa-dollar"></i>
                                                </span>
                                                <TextBox
                                                    className={(props.submittedOnce && (countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceFlatRate <= 0 || !countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceFlatRate)) ? 'input-text-error' : ''}
                                                    type='number'
                                                    value={countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceFlatRate || null}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.offerFreeShippingPriceFlatRate = e.target.value;
                                                        props.updateState({
                                                            //offerFreeShippingPrice: e.target.value,
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}
                                                    placeholder={props.intl.formatMessage(
                                                        {
                                                            id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                            defaultMessage: ''
                                                        })
                                                    }
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceFlatRate > 0 }}
                                                    message={props.intl.formatMessage(
                                                        {
                                                            id: 'requiredField.missing.msg',
                                                            defaultMessage: ''
                                                        })
                                                    } />
                                            </Col>
                                            <Col span={17}></Col>
                                        </Row> : ''}

                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5}></Col>
                                        <Col span={5}>
                                            <Checkbox
                                                checked={countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeFlatRate}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.isExtraShippingFeeFlatRate = e.target.checked;
                                                    props.updateState({
                                                        //isExtraShippingFee: e.target.checked,
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }} >
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.addHandlingFee.level' defaultMessage='' />
                                            </Checkbox>
                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                        </Col>
                                    </Row>
                                    {countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeFlatRate || countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeFlatRate === 1 ?
                                        <Row style={{ paddingBottom: '15px' }}>
                                            <Col span={5}></Col>
                                            <Col span={2} className="input-currency-control">
                                                <span className="currency-tag">
                                                    <i className="fa fa-dollar"></i>
                                                </span>
                                                <TextBox
                                                    className={(props.submittedOnce && (countryShippingRule[marketplaceShippingCountryId].extraShippingFeeFlatRate <= 0 || !countryShippingRule[marketplaceShippingCountryId].extraShippingFeeFlatRate)) ? 'input-text-error' : ''}
                                                    type='number'
                                                    value={countryShippingRule[marketplaceShippingCountryId].extraShippingFeeFlatRate || null}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.extraShippingFeeFlatRate = e.target.value;
                                                        props.updateState({
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}
                                                    placeholder={props.intl.formatMessage(
                                                        {
                                                            id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                            defaultMessage: ''
                                                        })
                                                    }
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].extraShippingFeeFlatRate > 0 }}
                                                    message={props.intl.formatMessage(
                                                        {
                                                            id: 'requiredField.missing.msg',
                                                            defaultMessage: ''
                                                        })
                                                    } />
                                            </Col>
                                        </Row> : ''}
                                </div> : ''

                        }


                        {
                            countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 3 ?
                                <div className=" " style={{ padding: '15px' }}>
                                    <h2>
                                        <FormattedMessage id='marketplaceConfiguration.shippingRule.form.name.rateByWeight' defaultMessage='' />
                                    </h2>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5} className='text-right'>
                                            <label>Shipping Name to displayed  at Checkout <span className="required">
                                                *
                                            </span></label>
                                        </Col>
                                        <Col span={6}>
                                            <TextBox
                                                minLength={1}
                                                maxLength={40}
                                                type='text'
                                                className={(props.submittedOnce && !countryShippingRule[marketplaceShippingCountryId].shippingNameRateByWeight) ? 'input-text-error' : ''}
                                                value={countryShippingRule[marketplaceShippingCountryId].shippingNameRateByWeight}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.shippingNameRateByWeight = e.target.value;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'marketplaceConfiguration.shippingRule.form.field.checkoutShippingName.placeholder', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].shippingNameRateByWeight }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>

                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5} className='text-right'>
                                            <span className="">
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.level' defaultMessage='' /></span><span className="required">
                                            </span>
                                        </Col>
                                        <Col span={6} className='input-currency-control ltiy'>
                                            <span className="currency-tag" style={{ paddingTop: '3px' }}>
                                                <Checkbox
                                                    checked={countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByWeight}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.isShowEstimatedDeliveryRateByWeight = e.target.checked;
                                                        props.updateState({
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}></Checkbox>
                                            </span>
                                            <TextBox
                                                minLength={1}
                                                maxLength={40}
                                                type='text'
                                                className={(props.submittedOnce && countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByWeight && !countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByWeight) ? 'input-text-error' : ''}
                                                value={countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByWeight}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.estimatedDeliveryRateByWeight = e.target.value;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.placeholder', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || !countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByWeight || countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByWeight }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={5}></Col>
                                        <Col span={6}>
                                            <h3>
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.weightRange' defaultMessage='' />
                                                <i className="fa fa-info-circle"></i>
                                            </h3>
                                        </Col>
                                        <Col span={4}><h3>
                                            <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.rate.placeholder' defaultMessage='' />
                                            <i className="fa fa-info-circle"></i></h3> </Col>
                                        <Col span={1}></Col>
                                    </Row>
                                    {
                                        countryShippingRule[marketplaceShippingCountryId].weightRange.length > 0 ? countryShippingRule[marketplaceShippingCountryId].weightRange.map((obj, k) =>
                                            <Row key={k}>
                                                <Col span={5}></Col>
                                                <Col span={6}> <Input.Group size="large">
                                                    <Row className='new-heigt' style={{ paddingBottom: '15px', paddingRight: '20px' }}>
                                                        <Col span={12} style={{ position: 'relative', paddingRight:'10px' }}>
                                                            <TextBox
                                                                className={(props.submittedOnce && (obj.startWeight <= 0 || !obj.startWeight)) ? 'input-text-error' : ''}
                                                                type='number'
                                                                value={obj.startWeight || null}
                                                                onChange={(e) => {
                                                                    obj.startWeight = e.target.value;
                                                                    countryShippingRule[marketplaceShippingCountryId].weightRange[k] = obj;
                                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                    //countryShipRule.weightRange = weightRange;
                                                                    props.updateState({
                                                                        //weightRange: weightRange,
                                                                        countryShippingRule: {
                                                                            ...countryShippingRule,
                                                                            marketplaceShippingCountryId: countryShipRule
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder={props.intl.formatMessage(
                                                                    {
                                                                        id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                                        defaultMessage: ''
                                                                    })
                                                                }
                                                            />
                                                            <ErrorMsg
                                                                validator={() => { return !props.submittedOnce || obj.startWeight > 0 }}
                                                                message={props.intl.formatMessage(
                                                                    {
                                                                        id: 'requiredField.missing.msg',
                                                                        defaultMessage: ''
                                                                    })
                                                                } />
                                                            <div style={{ position: 'absolute', top: '0px', right: '10px' }}> <Button>LB</Button></div>
                                                        </Col>
                                                        <Col span={12} style={{ position: 'relative', paddingRight:'10px' }}>
                                                            <TextBox
                                                                className={(props.submittedOnce && (obj.endWeight <= 0 || !obj.endWeight)) ? 'input-text-error' : ''}
                                                                type='number'
                                                                value={obj.endWeight || null}
                                                                onChange={(e) => {
                                                                    obj.endWeight = e.target.value;
                                                                    countryShippingRule[marketplaceShippingCountryId].weightRange[k] = obj;
                                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                    //countryShipRule.weightRange = weightRange;
                                                                    props.updateState({
                                                                        //weightRange: weightRange,
                                                                        countryShippingRule: {
                                                                            ...countryShippingRule,
                                                                            marketplaceShippingCountryId: countryShipRule
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder={props.intl.formatMessage(
                                                                    {
                                                                        id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                                        defaultMessage: ''
                                                                    })
                                                                }
                                                            />
                                                            <ErrorMsg
                                                                validator={() => { return !props.submittedOnce || obj.endWeight > 0 }}
                                                                message={props.intl.formatMessage(
                                                                    {
                                                                        id: 'requiredField.missing.msg',
                                                                        defaultMessage: ''
                                                                    })
                                                                } />
                                                            <div style={{ position: 'absolute', top: '0px', right: '10px' }}> <Button>LB</Button></div>
                                                        </Col>
                                                    </Row>
                                                </Input.Group>
                                                </Col>
                                                <Col span={2} className="input-currency-control">
                                                    <Row>
                                                        <span className="currency-tag"><i className="fa fa-dollar"></i> </span>
                                                        <TextBox
                                                            className={(props.submittedOnce && (obj.rate <= 0 || !obj.rate)) ? 'input-text-error' : ''}
                                                            type='number'
                                                            value={obj.rate || null}
                                                            onChange={(e) => {
                                                                obj.rate = e.target.value;
                                                                countryShippingRule[marketplaceShippingCountryId].weightRange[k] = obj;
                                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                //countryShipRule.weightRange = weightRange;
                                                                props.updateState({
                                                                    //weightRange: weightRange,
                                                                    countryShippingRule: {
                                                                        ...countryShippingRule,
                                                                        marketplaceShippingCountryId: countryShipRule
                                                                    },
                                                                });
                                                            }}
                                                            placeholder={props.intl.formatMessage(
                                                                {
                                                                    id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                                    defaultMessage: ''
                                                                })
                                                            }
                                                        />
                                                        <ErrorMsg
                                                            validator={() => { return !props.submittedOnce || obj.rate > 0 }}
                                                            message={props.intl.formatMessage(
                                                                {
                                                                    id: 'requiredField.missing.msg',
                                                                    defaultMessage: ''
                                                                })
                                                            } />
                                                    </Row>
                                                </Col>

                                                {
                                                    k > 1 ?
                                                        <Col span={2} className='cicon-hov' style={{ textAlign: 'center', padding: '0px 0px 0px', fontSize: '18px' }}>
                                                            <i className="fa fa-trash" style={{ cursor: 'pointer' }} onClick={() => {
                                                                countryShippingRule[marketplaceShippingCountryId].weightRange.splice(k, 1);
                                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                //countryShipRule.weightRange = weightRange;
                                                                props.updateState({
                                                                    //weightRange: weightRange,
                                                                    countryShippingRule: {
                                                                        ...countryShippingRule,
                                                                        marketplaceShippingCountryId: countryShipRule
                                                                    },
                                                                });
                                                            }}></i></Col> : ''
                                                }

                                            </Row>
                                        ) : ''
                                    }

                                    <Row style={{ paddingBottom: '15px', color: '#0000FF', cursor: 'pointer' }}>
                                        <Col span={5}></Col>
                                        <Col span={3} onClick={() => {
                                            let w = { startWeight: 0, endWeight: 0, rate: 0 };
                                            countryShippingRule[marketplaceShippingCountryId].weightRange.push(w);
                                            let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                            //countryShipRule.weightRange = weightRange;
                                            props.updateState({
                                                //weightRange: weightRange,
                                                countryShippingRule: {
                                                    ...countryShippingRule,
                                                    marketplaceShippingCountryId: countryShipRule
                                                },
                                            });
                                        }}> + <FormattedMessage style={{ fontSize: '13px' }} id='marketplaceConfiguration.shippingRule.form.field.addRange' defaultMessage='' />
                                        </Col>
                                    </Row>

                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5}></Col>
                                        <Col span={13} >
                                            <Checkbox
                                                checked={countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingWeight}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.isOfferFreeShippingWeight = e.target.checked;
                                                    props.updateState({
                                                        //isOfferFreeShippingWeight: e.target.checked,
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }} >
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.offerFreeShipping.level' defaultMessage='' />
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    {countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingWeight || countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingWeight === 1 ?
                                        <Row style={{ paddingBottom: '15px' }}>
                                            <Col span={5}></Col>
                                            <Col span={2} >
                                                <TextBox
                                                    className={(props.submittedOnce && (countryShippingRule[marketplaceShippingCountryId].offerFreeShippingWeight <= 0 || !countryShippingRule[marketplaceShippingCountryId].offerFreeShippingWeight)) ? 'input-text-error' : ''}
                                                    type='number'
                                                    value={countryShippingRule[marketplaceShippingCountryId].offerFreeShippingWeight || null}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.offerFreeShippingWeight = e.target.value;
                                                        props.updateState({
                                                            //offerFreeShippingWeight: e.target.value,
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}
                                                    placeholder={props.intl.formatMessage(
                                                        {
                                                            id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                            defaultMessage: ''
                                                        })
                                                    }
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].offerFreeShippingWeight > 0 }}
                                                    message={props.intl.formatMessage(
                                                        {
                                                            id: 'requiredField.missing.msg',
                                                            defaultMessage: ''
                                                        })
                                                    } />
                                            </Col>
                                            <Col span={17}></Col>
                                        </Row> : ''}

                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5}></Col>
                                        <Col span={5}>
                                            <Checkbox
                                                checked={countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByWeight}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.isExtraShippingFeeRateByWeight = e.target.checked;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }} >
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.addHandlingFee.level' defaultMessage='' />
                                            </Checkbox>
                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                        </Col>
                                    </Row>
                                    {countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByWeight || countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByWeight === 1 ?
                                        <Row style={{ paddingBottom: '15px' }}>
                                            <Col span={5}></Col>
                                            <Col span={2} className="input-currency-control">
                                                <span className="currency-tag">
                                                    <i className="fa fa-dollar"></i>
                                                </span>
                                                <TextBox
                                                    className={(props.submittedOnce && (countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByWeight <= 0 || !countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByWeight)) ? 'input-text-error' : ''}
                                                    type='number'
                                                    value={countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByWeight || null}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.extraShippingFeeRateByWeight = e.target.value;
                                                        props.updateState({
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}
                                                    placeholder={props.intl.formatMessage(
                                                        {
                                                            id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                            defaultMessage: ''
                                                        })
                                                    }
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByWeight > 0 }}
                                                    message={props.intl.formatMessage(
                                                        {
                                                            id: 'requiredField.missing.msg',
                                                            defaultMessage: ''
                                                        })
                                                    } />
                                            </Col>
                                        </Row> : ''}

                                </div> : ''
                        }

                        {
                            countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 4 ?
                                <div className=" " style={{ padding: '15px' }}>
                                    <h2>
                                        <FormattedMessage id='marketplaceConfiguration.shippingRule.form.name.rateByPrice' defaultMessage='' />
                                    </h2>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5} className='text-right'>
                                            <span className="">
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.checkoutShippingName.level' defaultMessage='' /></span><span className="required">
                                                *
                                            </span>
                                        </Col>
                                        <Col span={6}>
                                            <TextBox
                                                minLength={1}
                                                maxLength={40}
                                                type='text'
                                                className={(props.submittedOnce && !countryShippingRule[marketplaceShippingCountryId].shippingNameRateByPrice) ? 'input-text-error' : ''}
                                                value={countryShippingRule[marketplaceShippingCountryId].shippingNameRateByPrice}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.shippingNameRateByPrice = e.target.value;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'marketplaceConfiguration.shippingRule.form.field.checkoutShippingName.placeholder', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].shippingNameRateByPrice }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>
                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5} className='text-right'>
                                            <span className="">
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.level' defaultMessage='' /></span><span className="required">
                                            </span>
                                        </Col>
                                        <Col span={6} className='input-currency-control ltiy'>
                                            <span className="currency-tag" style={{ paddingTop: '3px' }}>
                                                <Checkbox
                                                    checked={countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByPrice}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.isShowEstimatedDeliveryRateByPrice = e.target.checked;
                                                        props.updateState({
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}></Checkbox>
                                            </span>

                                            <TextBox
                                                minLength={1}
                                                maxLength={40}
                                                type='text'
                                                className={(props.submittedOnce && countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByPrice && !countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByPrice) ? 'input-text-error' : ''}
                                                value={countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByPrice}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.estimatedDeliveryRateByPrice = e.target.value;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.placeholder', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || !countryShippingRule[marketplaceShippingCountryId].isShowEstimatedDeliveryRateByPrice || countryShippingRule[marketplaceShippingCountryId].estimatedDeliveryRateByPrice }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={5}></Col>
                                        <Col span={6}><h3>
                                            <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.priceRange' defaultMessage='' />
                                            <i className="fa fa-info-circle"></i></h3></Col>
                                        <Col span={4}><h3>
                                            <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.rate.placeholder' defaultMessage='' />
                                            <i className="fa fa-info-circle"></i></h3> </Col>

                                        <Col span={1}></Col>
                                    </Row>
                                    {
                                        countryShippingRule[marketplaceShippingCountryId].priceRange.length > 0 ? countryShippingRule[marketplaceShippingCountryId].priceRange.map((obj, index) =>
                                            <Row key={index}>
                                                <Col span={5}></Col>
                                                <Col span={6}> <Input.Group size="large">
                                                    <Row className='new-heigt' style={{ paddingBottom: '15px', paddingRight: '20px' }}>
                                                        <Col span={12} style={{ position: 'relative', paddingRight:'30px' }} className="input-currency-control">
                                                            <span className="currency-tag"><i className="fa fa-dollar"></i> </span>
                                                            <TextBox
                                                                className={(props.submittedOnce && (obj.startPrice <= 0 || !obj.startPrice)) ? 'input-text-error' : ''}
                                                                type='number'
                                                                value={obj.startPrice || null}
                                                                onChange={(e) => {
                                                                    obj.startPrice = e.target.value;
                                                                    countryShippingRule[marketplaceShippingCountryId].priceRange[index] = obj;
                                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                    props.updateState({
                                                                        countryShippingRule: {
                                                                            ...countryShippingRule,
                                                                            marketplaceShippingCountryId: countryShipRule
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder={props.intl.formatMessage(
                                                                    {
                                                                        id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                                        defaultMessage: ''
                                                                    })
                                                                }
                                                            />
                                                            <ErrorMsg
                                                                validator={() => { return !props.submittedOnce || obj.startPrice > 0 }}
                                                                message={props.intl.formatMessage(
                                                                    {
                                                                        id: 'requiredField.missing.msg',
                                                                        defaultMessage: ''
                                                                    })
                                                                } />
                                                            {/* <div style={{ position: 'absolute', top: '0px', right: '0px' }}> <Button>$</Button></div> */}
                                                        </Col>
                                                        <Col span={12} style={{ position: 'relative',paddingRight:'30px'  }} className="input-currency-control">
                                                            <span className="currency-tag"><i className="fa fa-dollar"></i> </span>
                                                            <TextBox
                                                                className={(props.submittedOnce && (obj.endPrice <= 0 || !obj.endPrice)) ? 'input-text-error' : ''}
                                                                type='number'
                                                                value={obj.endPrice || null}
                                                                onChange={(e) => {
                                                                    obj.endPrice = e.target.value;
                                                                    countryShippingRule[marketplaceShippingCountryId].priceRange[index] = obj;
                                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                    //countryShipRule.priceRange = priceRange;
                                                                    props.updateState({
                                                                        //priceRange: priceRange,
                                                                        countryShippingRule: {
                                                                            ...countryShippingRule,
                                                                            marketplaceShippingCountryId: countryShipRule
                                                                        },
                                                                    });
                                                                }}
                                                                placeholder={props.intl.formatMessage(
                                                                    {
                                                                        id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                                        defaultMessage: ''
                                                                    })
                                                                }
                                                            />
                                                            <ErrorMsg
                                                                validator={() => { return !props.submittedOnce || obj.endPrice > 0 }}
                                                                message={props.intl.formatMessage(
                                                                    {
                                                                        id: 'requiredField.missing.msg',
                                                                        defaultMessage: ''
                                                                    })
                                                                } />
                                                            {/* <div style={{ position: 'absolute', top: '0px', right: '0px' }}> <Button>$</Button></div> */}
                                                        </Col>
                                                    </Row>
                                                </Input.Group>
                                                </Col>
                                                <Col span={2} className="input-currency-control">
                                                    <Row>
                                                        <span className="currency-tag"><i className="fa fa-dollar"></i> </span>
                                                        <TextBox
                                                            className={(props.submittedOnce && (obj.rate <= 0 || !obj.rate)) ? 'input-text-error' : ''}
                                                            type='number'
                                                            value={obj.rate || null}
                                                            onChange={(e) => {
                                                                obj.rate = e.target.value;
                                                                countryShippingRule[marketplaceShippingCountryId].priceRange[index] = obj;
                                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                //countryShipRule.priceRange = priceRange;
                                                                props.updateState({
                                                                    //priceRange: priceRange,
                                                                    countryShippingRule: {
                                                                        ...countryShippingRule,
                                                                        marketplaceShippingCountryId: countryShipRule
                                                                    },
                                                                });
                                                            }}
                                                            placeholder={props.intl.formatMessage(
                                                                {
                                                                    id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                                    defaultMessage: ''
                                                                })
                                                            }
                                                        />
                                                        <ErrorMsg
                                                            validator={() => { return !props.submittedOnce || obj.rate > 0 }}
                                                            message={props.intl.formatMessage(
                                                                {
                                                                    id: 'requiredField.missing.msg',
                                                                    defaultMessage: ''
                                                                })
                                                            } />
                                                    </Row>
                                                </Col>
                                                {
                                                    index > 1 ?
                                                        <Col span={2} className='cicon-hov' style={{ textAlign: 'center', padding: '0px 0px 0px', fontSize: '18px' }}>
                                                            <i className="fa fa-trash" style={{ cursor: 'pointer' }} onClick={() => {
                                                                countryShippingRule[marketplaceShippingCountryId].priceRange.splice(index, 1);
                                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                props.updateState({
                                                                    countryShippingRule: {
                                                                        ...countryShippingRule,
                                                                        marketplaceShippingCountryId: countryShipRule
                                                                    },
                                                                });
                                                            }}></i></Col> : ''
                                                }
                                            </Row>) : ''
                                    }

                                    <Row style={{ paddingBottom: '15px', color: '#0000FF', cursor: 'pointer' }}>
                                        <Col span={5}></Col>
                                        <Col span={3} onClick={() => {
                                            let d = { startPrice: 0, endPrice: 0, rate: 0 };
                                            countryShippingRule[marketplaceShippingCountryId].priceRange.push(d);
                                            let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                            //countryShipRule.priceRange = priceRange;
                                            props.updateState({
                                                //priceRange: priceRange,
                                                countryShippingRule: {
                                                    ...countryShippingRule,
                                                    marketplaceShippingCountryId: countryShipRule
                                                },
                                            });
                                        }}>
                                            +<FormattedMessage style={{ fontSize: '13px' }} id='marketplaceConfiguration.shippingRule.form.field.addRange' defaultMessage='' />
                                        </Col>
                                    </Row>

                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5}></Col>
                                        <Col span={13} >
                                            <Checkbox checked={countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceRateByPrice}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.isOfferFreeShippingPriceRateByPrice = e.target.checked;
                                                    props.updateState({
                                                        //isOfferFreeShippingPrice: e.target.checked,
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }} >
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.offerFreeShipping.level' defaultMessage='' />
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    {countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceRateByPrice || countryShippingRule[marketplaceShippingCountryId].isOfferFreeShippingPriceRateByPrice === 1 ?
                                        <Row style={{ paddingBottom: '15px' }}>
                                            <Col span={5}></Col>
                                            <Col span={2} className="input-currency-control">
                                                <span className="currency-tag">
                                                    <i className="fa fa-dollar"></i>
                                                </span>
                                                <TextBox
                                                    className={(props.submittedOnce && (countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceRateByPrice <= 0 || !countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceRateByPrice)) ? 'input-text-error' : ''}
                                                    type='number'
                                                    value={countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceRateByPrice || null}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.offerFreeShippingPriceRateByPrice = e.target.value;
                                                        props.updateState({
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}
                                                    placeholder={props.intl.formatMessage(
                                                        {
                                                            id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                            defaultMessage: ''
                                                        })
                                                    }
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].offerFreeShippingPriceRateByPrice > 0 }}
                                                    message={props.intl.formatMessage(
                                                        {
                                                            id: 'requiredField.missing.msg',
                                                            defaultMessage: ''
                                                        })
                                                    } />
                                            </Col>
                                            <Col span={17}></Col>
                                        </Row> : ''}

                                    <Row style={{ paddingBottom: '15px' }}>
                                        <Col span={5}></Col>
                                        <Col span={5}>
                                            <Checkbox
                                                checked={countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByPrice}
                                                onChange={(e) => {
                                                    let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                    countryShipRule.isExtraShippingFeeRateByPrice = e.target.checked;
                                                    props.updateState({
                                                        countryShippingRule: {
                                                            ...countryShippingRule,
                                                            marketplaceShippingCountryId: countryShipRule
                                                        },
                                                    });
                                                }} >
                                                <FormattedMessage id='marketplaceConfiguration.shippingRule.form.field.addHandlingFee.level' defaultMessage='' />
                                            </Checkbox>
                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                        </Col>
                                    </Row>
                                    {countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByPrice || countryShippingRule[marketplaceShippingCountryId].isExtraShippingFeeRateByPrice === 1 ?

                                        <Row style={{ paddingBottom: '15px' }}>
                                            <Col span={5}></Col>
                                            <Col span={2} className="input-currency-control">
                                                <span className="currency-tag">
                                                    <i className="fa fa-dollar"></i>
                                                </span>
                                                <TextBox
                                                    className={(props.submittedOnce && (countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByPrice <= 0 || !countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByPrice)) ? 'input-text-error' : ''}
                                                    type='number'
                                                    value={countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByPrice || null}
                                                    onChange={(e) => {
                                                        let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                        countryShipRule.extraShippingFeeRateByPrice = e.target.value;
                                                        props.updateState({
                                                            countryShippingRule: {
                                                                ...countryShippingRule,
                                                                marketplaceShippingCountryId: countryShipRule
                                                            },
                                                        });
                                                    }}
                                                    placeholder={props.intl.formatMessage(
                                                        {
                                                            id: 'marketplaceConfiguration.shippingRule.form.field.rate.placeholder',
                                                            defaultMessage: ''
                                                        })
                                                    }
                                                />
                                                <ErrorMsg
                                                    validator={() => { return !props.submittedOnce || countryShippingRule[marketplaceShippingCountryId].extraShippingFeeRateByPrice > 0 }}
                                                    message={props.intl.formatMessage(
                                                        {
                                                            id: 'requiredField.missing.msg',
                                                            defaultMessage: ''
                                                        })
                                                    } />
                                            </Col>
                                        </Row> : ''}
                                </div> : ''
                        }

                        {
                            countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId === 5 ?
                                <div className='team-and-free'>
                                    <h2>Carrier by Rate</h2>
                                    <Row>
                                        <Col span={24}>
                                            <div className='new-tables'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width:'4%'}}>Carrier </th>
                                                            <th style={{width:'25%'}}>Service Name</th>
                                                            <th style={{width:'5%'}}> Display at checkout?</th>
                                                            <th style={{width:'50%'}}>Display Name</th>
                                                            <th style={{width:'7%'}}>Rate</th>
                                                            <th style={{width:'7%'}}>Max Weight </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {countryShippingRule[marketplaceShippingCountryId].carrierService && countryShippingRule[marketplaceShippingCountryId].carrierService.length > 0 ?
                                                            countryShippingRule[marketplaceShippingCountryId].carrierService.map((ser, i) => {
                                                                return (<tr key={i}>
                                                                    <td>
                                                                        <div className='shp-box'>
                                                                            {ser.carrierName === 'UPS' ? <img src={upsIcon} alt='' /> : ''}
                                                                            {ser.carrierName === 'UsPS' ? <img src={uspsIcon} alt='' /> : ''}
                                                                            {ser.carrierName === 'FedEx' ? <img src={fedexIcon} alt='' /> : ''}

                                                                            {ser.carrierName}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <h4>{ser.serviceName}</h4>
                                                                        {ser.serviceDescription}
                                                                    </td>
                                                                    <td style={{textAlign:'center'}}>
                                                                        <Checkbox
                                                                            checked={ser.displayAtCheckout}
                                                                            onChange={(e) => {
                                                                                countryShippingRule[marketplaceShippingCountryId].carrierService[i].displayAtCheckout = e.target.checked ? 1 : 0;
                                                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                                //countryShipRule.carrierService = carrierService;
                                                                                props.updateState({
                                                                                    //carrierService: carrierService,
                                                                                    countryShippingRule: {
                                                                                        ...countryShippingRule,
                                                                                        marketplaceShippingCountryId: countryShipRule
                                                                                    },
                                                                                });
                                                                            }}
                                                                        // checked={ser.displayAtCheckout || false}
                                                                        >
                                                                        </Checkbox>
                                                                    </td>
                                                                    <td>
                                                                        <TextBox
                                                                            className={(props.submittedOnce && !ser.displayServiceName && ser.displayAtCheckout) ? 'input-text-error' : ''}
                                                                            type='text'
                                                                            value={ser.displayServiceName || null}
                                                                            onChange={(e) => {
                                                                                countryShippingRule[marketplaceShippingCountryId].carrierService[i].displayServiceName = e.target.value;
                                                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                                //countryShipRule.carrierService = carrierService;
                                                                                props.updateState({
                                                                                    //carrierService: carrierService,
                                                                                    countryShippingRule: {
                                                                                        ...countryShippingRule,
                                                                                        marketplaceShippingCountryId: countryShipRule
                                                                                    },
                                                                                });
                                                                            }}

                                                                        />
                                                                        <ErrorMsg
                                                                            validator={() => { return !props.submittedOnce || ser.displayServiceName || !ser.displayAtCheckout }}
                                                                            message={props.intl.formatMessage(
                                                                                {
                                                                                    id: 'requiredField.missing.msg',
                                                                                    defaultMessage: ''
                                                                                })
                                                                            } />
                                                                        <div style={{ height: '3px', width: '100%' }}></div>
                                                                        <TextBox
                                                                            className={(props.submittedOnce && !ser.displayServiceDescription && ser.displayAtCheckout) ? 'input-text-error' : ''}
                                                                            type='text'
                                                                            value={ser.displayServiceDescription || null}
                                                                            onChange={(e) => {
                                                                                countryShippingRule[marketplaceShippingCountryId].carrierService[i].displayServiceDescription = e.target.value;
                                                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                                //countryShipRule.carrierService = carrierService;
                                                                                props.updateState({
                                                                                    //carrierService: carrierService,
                                                                                    countryShippingRule: {
                                                                                        ...countryShippingRule,
                                                                                        marketplaceShippingCountryId: countryShipRule
                                                                                    },
                                                                                });
                                                                            }}

                                                                        />
                                                                        <ErrorMsg
                                                                            validator={() => { return !props.submittedOnce || ser.displayServiceDescription || !ser.displayAtCheckout }}
                                                                            message={props.intl.formatMessage(
                                                                                {
                                                                                    id: 'requiredField.missing.msg',
                                                                                    defaultMessage: ''
                                                                                })
                                                                            } />
                                                                    </td>
                                                                    <td>
                                                                        <TextBox
                                                                            className={(props.submittedOnce && ser.displayAtCheckout && (ser.price <= 0 || !ser.price)) ? 'input-text-error' : ''}
                                                                            type='number'
                                                                            value={ser.price || null}
                                                                            onChange={(e) => {
                                                                                countryShippingRule[marketplaceShippingCountryId].carrierService[i].price = e.target.value;
                                                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                                //countryShipRule.carrierService = carrierService;
                                                                                props.updateState({
                                                                                    //carrierService: carrierService,
                                                                                    countryShippingRule: {
                                                                                        ...countryShippingRule,
                                                                                        marketplaceShippingCountryId: countryShipRule
                                                                                    },
                                                                                });
                                                                            }}

                                                                        />
                                                                        <ErrorMsg
                                                                            validator={() => { return !props.submittedOnce || ser.price || !ser.displayAtCheckout }}
                                                                            message={props.intl.formatMessage(
                                                                                {
                                                                                    id: 'requiredField.missing.msg',
                                                                                    defaultMessage: ''
                                                                                })
                                                                            } />

                                                                    </td>
                                                                    <td>
                                                                        <TextBox
                                                                            className={(props.submittedOnce && ser.displayAtCheckout && (ser.weight <= 0 || !ser.weight)) ? 'input-text-error' : ''}
                                                                            type='number'
                                                                            value={ser.weight || null}
                                                                            onChange={(e) => {
                                                                                countryShippingRule[marketplaceShippingCountryId].carrierService[i].weight = e.target.value;
                                                                                let countryShipRule = countryShippingRule[marketplaceShippingCountryId];
                                                                                //countryShipRule.carrierService = carrierService;
                                                                                props.updateState({
                                                                                    //carrierService: carrierService,
                                                                                    countryShippingRule: {
                                                                                        ...countryShippingRule,
                                                                                        marketplaceShippingCountryId: countryShipRule
                                                                                    },
                                                                                });
                                                                            }}

                                                                        />
                                                                        <ErrorMsg
                                                                            validator={() => { return !props.submittedOnce || ser.weight || !ser.displayAtCheckout }}
                                                                            message={props.intl.formatMessage(
                                                                                {
                                                                                    id: 'requiredField.missing.msg',
                                                                                    defaultMessage: ''
                                                                                })
                                                                            } />
                                                                    </td>
                                                                </tr>)
                                                            })

                                                            : ''
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </div> : ''
                        }

                        {countryShippingRule[marketplaceShippingCountryId].marketplaceShippingChoiceId ?
                            <Row style={{ padding: '15px' }}>
                                <Col span={5}></Col>
                                <Col span={6}>
                                    <CustomButton
                                        intlId='confirmation.submit'
                                        defaultMessage=''
                                        htmlType='submit'
                                        onClick={() => handleSubmit(props)}
                                        key={1}
                                    />
                                </Col>
                            </Row>
                            : <h3>Shipping Rule is not configured</h3>}
                    </div>


                </div>
                : ''}
        </Fragment >
    );
};


export default injectIntl(ShippingRuleComponent);
