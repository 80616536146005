import { PURCHASE_ORDER_ACTION_LIST } from '../static/constants';
const initialState = {
  purchaseOrderList: {
    1: []
  },
  purchaseOrderCount: 0,
  purchaseOrderDetail: {},
  rfqPoData: {},
  inboundDeliveryData: {},
  idPoData: {},
  poInboundDeliveryList: [],
  purchaseOrderDraftList: {
    1: []
  },
  purchaseOrderDraftCount: 0,
}

const PurchaseOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_LIST:
      return {
        ...state,
        purchaseOrderList: {
          ...state.purchaseOrderList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_COUNT:
      return { ...state, purchaseOrderCount: action.data };
    case PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_LIST_PAGE_RESET:
      return { ...state, purchaseOrderList: initialState.purchaseOrderList };
    case PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_DETAIL:
      return { ...state, purchaseOrderDetail: action.data };
    case PURCHASE_ORDER_ACTION_LIST.RESET_PO_DETAIL:
      return { ...state, purchaseOrderDetail: initialState.purchaseOrderDetail };
    case PURCHASE_ORDER_ACTION_LIST.RESET_RFQ_PO_DATA:
      return { ...state, rfqPoData: initialState.rfqPoData };
    case PURCHASE_ORDER_ACTION_LIST.RFQ_PO_DATA:
      return { ...state, rfqPoData: action.data };
    case PURCHASE_ORDER_ACTION_LIST.NEXT_PO_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case PURCHASE_ORDER_ACTION_LIST.NEXT_PO_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case PURCHASE_ORDER_ACTION_LIST.PO_INBOUND_DELIVERY_LIST:
      return { ...state, poInboundDeliveryList: action.data };
    case PURCHASE_ORDER_ACTION_LIST.PO_INBOUND_DELIVERY_LIST_RESET:
      return { ...state, poInboundDeliveryList: initialState.poInboundDeliveryList };
    case PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_DRAFT_LIST:
      return {
        ...state,
        purchaseOrderDraftList: {
          ...state.purchaseOrderDraftList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_DRAFT_COUNT:
      return { ...state, purchaseOrderDraftCount: action.data };
    case PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_DRAFT_LIST_PAGE_RESET:
      return { ...state, purchaseOrderDraftList: initialState.purchaseOrderDraftList };
    default:
      return state;
  }
};

export default PurchaseOrderReducer;