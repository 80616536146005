import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col, Button, Progress } from "antd";
import { fixedDecimalNumber } from "../../../../utils";
import NewsLetterShare from '../../../modal/modalBody/communication/NewsLetterShare';
import CaptainIcon from '../../../../assets/images/captain.png';


const CampaignDetail = (props) => {
    const data = props.teamMasterList;
    const campaignData = props.campaignData;
    const activeCampaignAnalytics = props.activeCampaignAnalytics;

    const shareTemplate = (memberData) => {
        const modalData = {
            modalBody: <NewsLetterShare templateObj={props.newsLetterTemplate} memberData={memberData} {...props} updateState={(data) => { props.updateState(data) }} style={{ overflow: 'auto' }} />,
            hideFooter: true,
            maskClosable: true,
            hideCloseIcon: true,
            hideTitle: true,
            wrapClassName: 'pay-invoice-modals',
            width: 600,
        };
        props.pushModalToStack(modalData);
    }

    const runCampaign = (memberData) => {
        props.history.push({
            pathname: '/admin/fundraising-t',
            state: {
                templateType: 'fundraising',
                fundraisingCampaignId: campaignData.fundraisingCampaignId,
                alpidePaymentGatewayId: campaignData.alpidePaymentGatewayId,
                projectMasterId: campaignData.projectMasterId,
                chartOfAccountDetailsId: campaignData.chartOfAccountDetailsId,
                teamMasterId: memberData.teamMasterId,
                teamMemberId: memberData.teamMemberId
            }
        })

    }

    const openAddTeamToCampaignDrawer = (teamMaster) => {
        props.updateHeaderState({
            fundraising: {
                addTeamDrawerVisible: true,
                campaignData,
                getTeamMaster: props.getTeamMaster,
                teamMaster
            },
        });
    };

    const openAddTeamToFundraiserDrawer = (data) => {
        props.updateHeaderState({
            fundraising: {
                addFundraiserDrawerVisible: true,
                teamData: data,
                getTeamMaster: props.getTeamMaster
            },
        });
    };


    return (
        <Fragment>
            <div className="view-container sales-order-details mt5">
                <div className='team-and-free'>
                    {
                        activeCampaignAnalytics && <Row  >
                            
                            <Col span={20}>
                            
                            <Row className="compain-conterf">
                            <Col span={7}>
                                <div className="compan-boxanew">
                                    <div className="com-bocsli bott-border">
                                        <h3 >    {activeCampaignAnalytics.campaignName}</h3>
                                    </div>
                                    <i className="fa fa-bullhorn" ></i>
                                    <div className=" top-border">
                                        <h3 style={{ padding: '3px 5px' }}> <span className="goal-text"> Goal</span>   ${activeCampaignAnalytics.goal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </h3>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col span={1} >
                                <div className="compain-boxer">
                                    <div className="compn-line"></div>
                                </div>
                            </Col> */}
                            <Col span={16} >

                                <div className="compain-boxer">

                                    <Row>
                                        <Col span={12}> <div className="compan-text">
                                            Goal ${activeCampaignAnalytics.goal.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>
                                        </Col>

                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <div className="compan-text" style={{ marginRight: '10px' }}> Received ${(activeCampaignAnalytics.totalReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </div>
                                        </Col>

                                    </Row>

                                    <Progress
                                        percent={(((activeCampaignAnalytics.totalReceived + (activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived)) / activeCampaignAnalytics.goal) * 100).toFixed(0)}
                                        // percent={(75 / activeCampaignAnalytics.goal) * 100}
                                        successPercent={(activeCampaignAnalytics.totalReceived / activeCampaignAnalytics.goal) * 100}
                                        //successPercent={75 - 40}
                                        showInfo={false}
                                    //trailColor={"white"}
                                    />
                                    <Row>

                                        <Col span={11}>
                                            <div className="compan-text">
                                                <div className="compan-cirles"><span></span>Total Receiced <div style={{ float: 'right', paddingRight: '20px', borderRight: '0px solid #bababa' }}>${(activeCampaignAnalytics.totalReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({((activeCampaignAnalytics.totalReceived / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%) </div></div>
                                                <div className="compan-cirles"><span style={{ background: '#98d9eb' }}></span> Projected Total <div style={{ float: 'right', paddingRight: '20px', borderRight: '0px solid #bababa' }}> ${(activeCampaignAnalytics.totalReceived + (activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}  ({(((activeCampaignAnalytics.totalReceived + (activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived)) / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%) </div></div>
                                                {/* <div className="compan-cirles"><span></span>Total Donar {activeCampaignAnalytics.totalDonors}</div> */}
                                            </div>
                                        </Col>
                                        <Col span={2}></Col>
                                        <Col span={11} style={{ paddingLeft: '20px' }}>
                                            <div className="compan-text">
                                                <div className="compan-cirles" ><span style={{ background: 'initial', border: 'solid 1px #ccc' }}></span> Pledge Made  <div style={{ float: 'right' }}>${(activeCampaignAnalytics.pledgeMade).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({((activeCampaignAnalytics.pledgeMade / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%)</div> </div>
                                                <div className="compan-cirles"><span style={{ background: 'none', border: 'solid 1px #ccc' }}></span> Pledge Recieved <div style={{ float: 'right' }}> ${(activeCampaignAnalytics.totalPledgeReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({((activeCampaignAnalytics.totalPledgeReceived / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%)</div> </div>
                                                <div className="compan-cirles"><span style={{ background: 'none', border: 'solid 1px #ccc' }}></span>  Outstanding Pledge <div style={{ float: 'right' }}>${(activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ({activeCampaignAnalytics.pledgeMade ? (((activeCampaignAnalytics.pledgeMade - activeCampaignAnalytics.totalPledgeReceived) / activeCampaignAnalytics.pledgeMade) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}%)</div> </div>
                                                {/* <div className="compan-cirles"><span></span> Pledge Count {activeCampaignAnalytics.pledgeCount} </div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            </Row>
                            </Col>
                            <Col span={4}>
                            <Button style={{ float: 'right', position:'relative', top:'90px' }} className="ant-btn-primary" onClick={() => {
                                openAddTeamToCampaignDrawer();
                            }}>Add Team to Campaign</Button>

                            </Col>
                            {/* <Col span={3}>
                                <div className="compn-lines">
                                    <div className="compn-lines2">
                                        {((activeCampaignAnalytics.totalReceived / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}% <span style={{ marginLeft: '10px' }}>Received</span>
                                        <br />
                                        <span>{(((activeCampaignAnalytics.goal - activeCampaignAnalytics.totalReceived) / activeCampaignAnalytics.goal) * 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}% <span style={{ marginLeft: '10px' }}>Remaining </span></span>
                                    </div>
                                </div>
                            </Col> */}

                        </Row>
                    }
                    <br />
                    <Row className="">
                        {/* <Col span={11}>
                            <p>Goal      : {campaignData.goal}</p>
                            <p>Starts On : {campaignData.startDate && getMomentDateForUIReadOnly(campaignData.startDate)}</p>
                            <p>Ends On   : {campaignData.endDate && getMomentDateForUIReadOnly(campaignData.endDate)}</p>

                        </Col> */}
                        {/* <Col span={9}>
                            <b>{campaignData.campaignName}</b>
                        </Col> */}
                        {/* <Col span={4}>
                            <Button style={{ float: 'right' }} className="ant-btn-primary" onClick={() => {
                                openAddTeamToCampaignDrawer();
                            }}>Add Team to Campaign</Button>
                        </Col> */}
                    </Row>
                </div>
                <hr />
                
                {data.map((e, i) => {
                   
                    return   <div key={e.teamMasterId} className='new-table-camp leftright'> <div className='team-and-free'>
                        <br />
                        <Row>
                            <Col span={12} style={{ background: '#f1f1f1', padding: '10px', borderRadius: '4px' }}>
                                <Row>
                                    <Col span={11}>  <h4 style={{ margin: '0px' }}> Team - {e.teamName}</h4>
                                       
                                    </Col>
                                    <Col span={11} className="compainbar"><span style={{ float: 'left' }} > <b style={{ fontSize: '24px' }}> ${e.teamRaised || 0}</b> raised</span>
                                    <span style={{ float: 'right', position: 'relative', top: '14px' }}> of  ${e.teamGoal || 0}  Goal </span>

                                        <br /> <Progress percent={(((e.teamRaised || 0) / (e.teamGoal || 0)) * 100).toFixed(0)} /></Col>
                                </Row>
                            </Col>


                        
                            
                            <Col span={10}></Col>
                           
                            <Col span={1} style={{padding:'23px 0px 0px'}}> <Button onClick={() => {
                                openAddTeamToCampaignDrawer(e);
                            }} className="ant-btn ant-btn-default" title="Edit Team" style={{ marginLeft: '5px', float: 'right' }}><i className="fa fa-pencil" ></i></Button>
                            </Col>
                            
                            <Col span={1} style={{padding:'23px 0px 0px'}}> <Button onClick={() => {
                                const modalBody = <span>
                                    <FormattedMessage
                                        id='commons.delete.popup.confimation.msg'
                                        defaultMessage=''
                                    />
                                </span>;
                                const modalData = {
                                    modalBody,
                                    handleSubmit: () => {
                                        props.deleteTeamMaster(e, props)
                                    },
                                };
                                props.showModal(modalData);
                            }} className="ant-btn ant-btn-default" title="Delete Team" style={{ marginLeft: '5px', float: 'right' }}><i className="fa fa-trash" ></i></Button></Col>


                        </Row>
                        <br />
                        <table key={i} style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                                    <th width="15%">Fundraiser</th>
                                    <th width="10%">Goal</th>
                                    <th width="7%">Donor</th>
                                    <th width="9%">Donation Received ($)</th>
                                    <th>Pledge</th>
                                    <th width="12%">Pledge ($)</th>
                                    <th width="5%">Pledge </th>
                                    <th  width="15%">Pledge Received ($)</th>
                                    <th>Actions</th>
                                </tr>
                                {e.teamMemberList.map((member, j) => {
                                    return <tr key={j + '' + i}>
                                        <td>{j + 1}</td>
                                        <td>
                                            {(member.firstName || "") + " " + (member.lastName || "")}
                                            {member.isTeamLead ? <span className="ml5"><img title={ 'I am the Captain of the team '+ e.teamName} style={{ height: '25px' }} src={CaptainIcon} alt='' /></span> : ''}
                                        </td>
                                        <td style={{textAlign: 'end'}}>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{fixedDecimalNumber(member.fundraiserGoal)}</td>
                                        <td>{member.fundraiserDonorCount}</td>
                                        <td style={{textAlign: 'end'}}>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{fixedDecimalNumber(member.fundraiserDonationReceievd)}</td>
                                        <td>{member.fundraiserPledgeCount}</td>
                                        <td style={{textAlign: 'end'}}>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{fixedDecimalNumber(member.fundraiserPledgeMade)}</td>
                                        <td>{member.fundraiserPledgeReceievedCount}</td>
                                        <td style={{textAlign: 'end'}}>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{fixedDecimalNumber(member.fundraiserPledgeReceieved)}</td>
                                        <td><div className="cursor-pointer" >
                                            {campaignData.formBuilderId ?
                                                <span onClick={() => {
                                                    shareTemplate(member);
                                                }}>Share Page </span>
                                                :
                                                <span className="cursor-pointer" onClick={() => {
                                                    runCampaign(member);
                                                }}>Run Campaign </span>
                                            }
                                         {member.isTeamLead ? '' : <span> | <i className="fa fa-trash" onClick={() => {
                                                const modalBody = <span>
                                                    <FormattedMessage
                                                        id='commons.delete.popup.confimation.msg'
                                                        defaultMessage=''
                                                    />
                                                </span>;
                                                const modalData = {
                                                    modalBody,
                                                    handleSubmit: () => {
                                                        props.deleteTeamMember(member, props);
                                                    },
                                                };
                                                props.showModal(modalData);

                                            }}></i></span>
                                            }
                                            </div></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <Row style={{padding:'23px 0px 0px'}}>
                                <Button style={{ float: 'right' }} className="ant-btn-primary" onClick={() => {
                                    openAddTeamToFundraiserDrawer(e);
                                }}>Add Fundraiser</Button>

                            </Row>
                    </div>
                    </div>
                })}

</div>
            
        </Fragment>
    );
};

export default injectIntl(CampaignDetail);
