import { Col, DatePicker, Drawer, Row,Input,Skeleton } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown"
import { getCurrencySymbol } from "../../../../utils";

const HourlyPayDrawer = (props) => {
  const closeDrawer = () => {
    props.updateState({
      hourlyPayDrawer: false,
    });
  };

  return (
    <Drawer
      title={<FormattedMessage id="drawer.leaveMangement.titleName.payGrade" />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.hourlyPayDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {

            }

            }
            key={1}
          />
        </>
      }

    >
        <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="i-label">Pay Grade Name
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Pay Grade Name"
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.leadSourceName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Rate
            <span className="required">*</span>

          </div>
          <Input
            placeholder="Enter Rate"
            addonBefore={getCurrencySymbol(props.companyInfo.currencyCode)}
            type="number"
            style={{ height: '38px' }}

          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.leadSourceName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>


      </Row>
      </Skeleton>
    </Drawer>
  );
};

export default HourlyPayDrawer;
