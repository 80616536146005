import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ForgotPassword from '../../components/forgotpassword/Forgotpassword';
import { sendResetMail } from './action';

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailClass: '',
      emailInvalidClass: '',
    }
  }

  render() {
    return <ForgotPassword {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
  }

}

const mapDispatchToProps = (dispatch) => bindActionCreators({ sendResetMail }, dispatch);

export default connect(null, mapDispatchToProps)(Forgot);
