import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Radio, Skeleton } from 'antd';

//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
// import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { getUserDefinedName } from '../../../../utils';
function SubjectDrawer(props) {


  const closeDrawer = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addSubjectDrawerVisible: false,
        subjectData: {}
      }
    })
  }

  const handleSubmit = (props) => {
    if (props.subjectCode && props.subjectName && props.subjectType) {
      const payload = {
        name: props.subjectName,
        subjectCode: props.subjectCode,
        subjectType: props.subjectType,
        relationshipId: (props.companyInfo || {}).relationshipId,
        createdByEmpId: (props.userInfo || {}).userId,
        subjectId: props.subjectId,
        subjectCredit: props.subjectCredit,
        version: props.version,
      };

      props.pushModalToStack({
        modalBody: <FormattedMessage id={props.subjectData?'schoolManagement.school.confirmation.edit':'schoolManagement.school.confirmation.create'} defaultMessage='' /> ,
        handleSubmit: () => {
          props.saveSubject(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  return (
    <Fragment>
      <Drawer
        title={getUserDefinedName(props.subjectData
                  ? "schoolManagement.subject.edit"
                  : "schoolManagement.subject.new", props)}
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-drawer"
        visible={props.schoolDrawers.addSubjectDrawerVisible}
        afterVisibleChange={(visible) => {
          if (visible) {
            //props.initCalls();
          }
        }}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />
            <CustomButton
              intlId="confirmation.submit"
              defaultMessage=""
              htmlType="submit"
              onClick={() => handleSubmit(props)}
              key={1}
            />
          </>
        }
      >
        <Row gutter={[16, 16]}>

          <Col span={24}>
            <div className='i-label'>{getUserDefinedName('schoolManagement.subject.name', props)}<span className="required">*</span></div>
            <TextBox
              type="text"
              maxLength={100}
              countStyle={{top:"40px"}}
              value={props.subjectName || null}
              onChange={(e) => {
                props.updateState({
                  subjectName: e.target.value,
                });
              }}
              placeholder={getUserDefinedName('schoolManagement.subject.name', props)}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.subjectName;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>

          <Col span={12}>
            <div className='i-label'>{getUserDefinedName('schoolManagement.subject.code', props)}<span className="required">*</span></div>
            <TextBox
              type="text"
              value={props.subjectCode ||  null}
              maxLength={20}
              countStyle={{top:"40px"}}
              onChange={(e) => {
                props.updateState({
                  subjectCode: e.target.value,
                });
              }}
              placeholder={getUserDefinedName('schoolManagement.subject.code', props)}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.subjectCode;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>

          <Col span={12}>
            <div className='i-label'>{getUserDefinedName('schoolManagement.subject.credit', props)}</div>
            <TextBox
              type="text"
              value={props.subjectCredit || ''}
              onChange={(e) => {
                props.updateState({
                  subjectCredit: e.target.value,
                });
              }}
              placeholder={getUserDefinedName('schoolManagement.subject.credit', props)}
            />
            {/* <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.subjectCredit;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            /> */}
          </Col>

          <Col span={12}>
            <div className='i-label'>{getUserDefinedName('schoolManagement.subject.type', props)}
            <span className="required">*</span>
            </div>
            <Radio.Group style={{height: '38px'}}
              onChange={(e) => {
                props.updateState({ subjectType: e.target.value });
              }}
              value={props.subjectType}
            >
              <Radio value="core">
                {" "}
                <FormattedMessage id="core.text" />
              </Radio>
              <Radio value="elective">
                <FormattedMessage id="elective.text" />
              </Radio>
            </Radio.Group>
            <br />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.subjectType;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>
        </Row>
      </Drawer>
    </Fragment>
  );
}

export default injectIntl(SubjectDrawer);
