export const PLACEHOLDER_CONSTANTS = {
    BALANCE: '~balance~',
    DUE_DATE: '~dueDate~',
    INVOICE_DATE: '~invoiceDate~',
    INVOICE_NUMBER: '~invoiceNumber~',
    INVOICE_URL: '~invoiceUrl~',
    PROJECT_NAME: '~projectName~',
    PAYMENT_LINK: '~paymentLink~',

    QUOTE_BALANCE: '~salesQuoteTotalAmount~',
    BID_CLOSING_DATE: '~salesQuoteExpiryDate~',
    QUOTE_DATE: '~salesQuoteDate~',
    QUOTE_NUMBER: '~quotationNumber~',

    SO_BALANCE: '~salesOrderTotalAmount~',
    SO_DELIVERY_DATE: '~deliveryDate~',
    SO_DATE: '~salesOrderDate~',
    SO_NUMBER: '~soNumber~',

    SALES_PAYMENT_BALANCE: '~paymentAmount~',
    SALES_PAYMENT_DATE: '~paymentDate~',
    SALES_PAYMENT_NUMBER: '~paymentNumber~',

    CM_BALANCE: '~creditMemoTotalAmount~',
    CM_EXPIRY_DATE: '~creditMemoExpDate~',
    CM_DATE: '~creditMemoDate~',
    CM_NUMBER: '~creditMemoNumber~',

    INQUIRY_DATE: '~inquiryDate~',
    INQUIRY_EXPIRY_DATE: '~inquiryExpirationDate~',
    INQUIRY_NUMBER: '~inquiryNumber~',

    PI_BALANCE: '~invoiceTotalAmount~',
    //PROJECT_NAME: '~projectName~',

    PO_BALANCE: '~totalPOAmount~',
    PO_DUE_DATE: '~poDueDate~',
    PO_DATE: '~poDate~',
    PO_NUMBER: '~poNumber~',

    PURCHASE_PAYMENT_BALANCE: '~paymentAmount~',
    PURCHASE_PAYMENT_DATE: '~paymentDate~',
    PURCHASE_PAYMENT_NUMBER: '~paymentNumber~',

    DM_BALANCE: '~debitMemoTotalAmount~',
    DM_EXPIRY_DATE: '~debitMemoExpDate~',
    DM_DATE: '~debitMemoDate~',
    DM_NUMBER: '~debitMemoNumber~',

    RFQ_DATE: '~rfqDate~',
    RFQ_EXPIRY_DATE: '~rfqExpirationDate~',
    RFQ_NUMBER: '~rfqNumber~',

    PR_DATE: '~purchaseRequestDate~',
    PR_EXPIRY_DATE: '~prExpirationDate~',
    PR_NUMBER: '~purchaseRequestNumber~',

    SUPPLIER_NAME: '~supplierName~',
    CUSTOMER_NAME: '~customerName~',
    SALUTATION: '~salutation~',
    CUSTOMER_BALANCE: '~customerBalance~',
    FIRST_NAME: '~firstName~',
    LAST_NAME: '~lastName~',
    DEPARTMENT: '~department~',
    DESIGNATION: '~designation~',
    EMAIL: '~email~',

    STORE_NAME: '~storeName~',
    USER: '~user~',
    ORG_EMAIL: '~orgEmail~',
    ORG_PHONE: '~orgPhone~',
    ORG_FAX: '~orgFax~',
    WEBSITE: '~website~',

    DONOR_NAME: '~donorName~',
    MOBILE_NUMBER: '~mobileNumber~',
    PLEDGE_AMOUNT: '~pledgeAmount~',
    PLEDGE_DATE: '~pledgeDate~',
    PLEDGE_START_DATE: '~pledgeStartDate~',
    TOTAl_OCCURRENCES: '~totalOccurrences~',
    REMARKS: '~remarks~',
    CAMPAIGN_NAME: '~campaignName~',

    STUDENT_NAME: '~studentName~', 
    TEACHER_NAME:'~teacheName~', 
    CLASS_NAME:'~className~',
    CLASS_SCHEDULE:'~classSchedule~',
    DUE_AMOUNT:'~balance~',
    PARENT_NAME:'~parentName~',
    PAYMENT_URL: '~invoiceUrl~',
    ASSIGNMENT_NAME: '~assignmentName~',
    ASSIGNMENT_DUE_DATE: '~assignmentDueDate~',

    SHIPMENT_NUMBER: '~shipmmentNumber~',
    SHIPMENT_DATE: '~shipmentDate~',
    ORDER_DATE: '~orderDate~',
    ORDER_NUMBER:'~orderNumber~',

    OOC_ORDER_DATE: '~soDate~',
    OOC_ORDER_NUMBER: '~orderNumber~',
    OOC_ORDER_AMOUNT: '~orderAmount~',
    TRACKING_NUMBER: '~trackingNumber~',
    CARRIER_NAME: '~carrierName~',
    DELIVERY_DATE: '~deliveryDate~',
    ONLINE_ORDER_NUMBER: '~onlineOrderNumber~',
    COMPANY_LOGO: '~companyLogo~',
    USER_NAME:'~userName~',
    PASSWORD:'~password~',
    SCHOOL_NAME:'~schoolName~',
    FATHER_NAME:'~fatherName~',
    MOTHER_NAME:'~motherName~',
    TOTAL_FEE_AMOUNT:'~totalFeeAmount~',
    INSTALLMENT_FEE_AMOUNT:'~installmentFeeAmount~',
    REGISTRATION_NUMBER:'~registrationNumber~',
    DUE_DATE:'~dueDate~',
    PORTAL_URL:'~portalUrl~',
    PAYMENT_URL:'~paymentUrl~'






}