import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
import { CONSTANTS_TRANSACTIONS } from "../../../static/constants";
import { Tabs } from "antd";
import SODetail from "../../../containers/modal/modalBody/customer/SODetail";
import SalesInvoiceDetails from "../../../containers/modal/modalBody/customer/SalesInvoiceDetail";
import PurchaseInvoiceDetail from "../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail";
import CreditMemoDetail from "../../../containers/modal/modalBody/customer/CreditMemoDetail";
import CustomerPaymentDetails from "../../../containers/modal/modalBody/customer/PaymentDetail";
import PODetail from "../../../containers/modal/modalBody/supplier/PurchaseOrderDetail";
import DebitMemoDetail from "../../../containers/modal/modalBody/supplier/DebitMemoDetail";
import BusinessExpenseDetail from "../../../containers/modal/modalBody/finance/BusinessExpense";
import JournalDetails from "../../../containers/modal/modalBody/finance/JournalDetail";
import SupplierPaymentDetails from "../../../containers/modal/modalBody/supplier/PaymentDetail";
import SalesInvoice from "../../../components/customer/invoice/Listing/salesInvoice";
import PurchaseInvoice from "../../../components/supplier/invoice/Listing/purchaseInvoice";

const { TabPane } = Tabs;
const BankBook = (props) => {
  const { bankBookList, pageNumber, bankBookCount, showTotal, companyInfo } =
    props;
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.finance" defaultMessage="" />
      ),
    },

    {
      name: (
        <FormattedMessage id="sidebar.menuItem.billwiseDetail" defaultMessage="" />
      ),
    },
  ];
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.getBankBookCount(payload, companyInfo);
    props.fetchBankBookList(payload, companyInfo);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: pageSize,
    };

    props.updateState({ pageSize: pageSize, pageNumber: 1 });
    props.resetPaginatedData();
    props.fetchBankBookList(payload, companyInfo);
  };

  const halndleOpenDetails = (data) => {
    switch (data.transactionName) {
      case "Sales Invoice":
        const invoicePayload = {
          customerId: data.customerId,
          invoiceMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <SalesInvoiceDetails {...props} invoicePayload={invoicePayload} />
          ),
          width: "100%",
          hideFooter: true,
          keyboard: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Sales Order":
        const soPayload = {
          customerId: data.customerId,
          salesOrderMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: <SODetail {...props} soPayload={soPayload} />,
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Sales Payment":
        const paymentPayload = {
          customerId: data.customerId,
          customerPaymentId: data.txMasterId,
        };
        const modalData = {
          title: "Sales Payment",
          modalBody: <CustomerPaymentDetails paymentPayload={paymentPayload} />,
          width: 500,
          hideFooter: true,
        };
        props.showModal(modalData);
        break;

      case "Credit Memo":
        const CreditMemoload = {
          customerId: data.customerId,
          creditMemoMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <CreditMemoDetail {...props} creditMemoPayload={CreditMemoload} />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Purchase Order":
        const poPayload = {
          supplierId: data.supplierId,
          poMasterId: data.txMasterId,
        };

        props.pushModalToStack({
          modalBody: <PODetail {...props} poPayload={poPayload} />,
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Purchase Invoice":
        const PurchasePayload = {
          supplierId: data.supplierId,
          invoiceMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <PurchaseInvoiceDetail
              {...props}
              invoicePayload={PurchasePayload}
            />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Debit Memo":
        const debitMemoPayload = {
          supplierId: data.supplierId,
          debitMemoMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <DebitMemoDetail {...props} debitMemoPayload={debitMemoPayload} />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Purchase Payment":
        const SupplierPaymentPayload = {
          supplierId: data.supplierId,
          supplierPaymentId: data.txMasterId,
        };
        const modalDataSupplier = {
            title: <div className='ant-modal-title'>Purchase Payment</div>,
            modalBody: <SupplierPaymentDetails paymentPayload={SupplierPaymentPayload} />,
            width: 500,
            hideFooter: true
        };
        props.showModal(modalDataSupplier);
        break;

      case "Business Expense":
        const expensePayload = {
          expenseMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <BusinessExpenseDetail {...props} expensePayload={expensePayload} />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Journal":
        const journalPayload = {
          journalId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <JournalDetails {...props} journalPayload={journalPayload} />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      default:
      // Default case code block.
    }
  };

function callback(key, props) {
  const payload = { companyInfo: props.companyInfo, relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize };
  if (key === '1') {
      props.updateState({activeTab: 'Sales Invoice', activeTabKey: "1"});
  } else if (key === '2') {
      props.updateState({activeTab: 'Purchase Invoice', activeTabKey: "2"});
    //  fetchDataIfNeeded('getPurchaseInvoiceDraftCount', 'purchaseInvoiceDraftCount', props, payload);
    //  fetchPaginationDataIfNeeded('fetchPurchaseInvoiceDrafts', 'purchaseInvoiceDraftList', props, payload);
    const companyInfo = props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
      transactionName: 'Purchase Invoice'
    };
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', props, payload);
    fetchPaginationDataIfNeeded('fetchPurchaseInvoices', 'purchaseInvoiceList', props, payload);
    fetchDataIfNeeded('getPurchaseInvoicesCount', 'purchaseInvoiceCount', props, payload);
  } 
}

  return (
    <Fragment>
      <div className="dayBook-container">
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="sidebar.menuItem.billwiseDetail"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right"></div>
        </div>

        <div className="view-container">
          {/* <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                Bill Wise Detail List
              </div>
              <div className="vertical-sep" />
              <div>{bankBookCount ? bankBookCount : ""}</div>
            </div>
            <div className="right-actions"></div>
          </div> */}
          <div className="view-container">
            <Tabs
              activeKey={props.activeTabKey || "1"}
              onChange={(key) => {
                callback(key, props);
              }}
              type="line"
            >
              <TabPane tab={(props.isSchoolUser) ? 'Fees Invoice' : 'Sales Invoice'} key="1">
                <SalesInvoice {...props} />
              </TabPane>
              <TabPane tab={<FormattedMessage id='sidebar.menuItem.purchaseInvoice' defaultMessage='' />} key="2">
                <PurchaseInvoice {...props} />
              </TabPane>
            </Tabs>
      </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BankBook;
