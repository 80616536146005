import React, { Fragment, useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Dropdown } from "../../../general/Dropdown";
import { ErrorMsg } from "../../../general/ErrorMessage";
import "@ant-design/compatible/assets/index.css";
import './index.scss'
import {Drawer,Button,Col,Row,Tabs} from "antd";
import Emails from "./emails";
import Sms from "./sms";
import Whatsapp from "./whatsapp";



const { TabPane } = Tabs;


function CommunicationTemplateDrawer(props) {
  const closeDrawer = () => {
    props.updateDrawerState({
      communicationTemplateDrawerVisiable: false
    })
  }

  const transactionMode = [
    { trnMode: "Option 1", referCode: "OPT1" },
    { trnMode: "Option 2", referCode: "OPT2" },
    { trnMode: "Option 3", referCode: "OPT3" },
  ];


      return (
        <Fragment>
           
          <Drawer title={<FormattedMessage id='modal.heading.communicationTemplate.edit-template' defaultMessage='' />}
            width={720}
            destroyOnClose={true}
            onClose={closeDrawer}
            keyboard={false}
            maskClosable={false}
            className="custom-drawer"
            afterVisibleChange={() => {
            }}
            visible={props.communicationTemplateDrawerVisiable}
            footer={
              <>
                <Button onClick={() => {closeDrawer()}}>Cancel</Button>
                <Button type="primary" onClick={() => {}}>Update</Button>
              </>
            }>

            <Row gutter={[16, 8]}>
              <Col span={24} className="i-label">
                <FormattedMessage
                  id="drawer.labelName.communicationTemplate.module"
                  defaultMessage=""
                />
                <span className="required">*</span>
                
              </Col>
              <Col span={24}>
                <Dropdown
                      items={transactionMode || []}
                      valueKeyName="trnMode"
                      optionKeyName="referCode"
                      value={(props.transactionMode || {}).trnMode}
                      placeholder={"Select Transactional Module"}
                      // onSelect={(referCode) => {
                      //   const doc =
                      //     find(transactionMode, { referCode: referCode }) || {};
                      //   props.updateState({
                      //     transactionMode: doc,
                      //   });
                      // }}
                    />
                  {/* <ErrorMsg
                    message={"Transaction Mode is required"}
                  /> */}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Row gutter={[16, 8]}>
                <Col span={24} className="i-label">
                  <FormattedMessage
                    id="drawer.labelName.communicationTemplate.transactions"
                    defaultMessage=""
                  />
                  <span className="required">*</span>
                  
                </Col>
                <Col span={24}>
                  <Dropdown
                        items={transactionMode || []}
                        valueKeyName="trnMode"
                        optionKeyName="referCode"
                        value={(props.transactionMode || {}).trnMode}
                        placeholder={"Select Sub Module"}
                        // onSelect={(referCode) => {
                        //   const doc =
                        //     find(transactionMode, { referCode: referCode }) || {};
                        //   props.updateState({
                        //     transactionMode: doc,
                        //   });
                        // }}
                      />
                    {/* <ErrorMsg
                      message={"Transaction Mode is required"}
                    /> */}
                </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={[16, 8]}>
                  <Col span={24} className="i-label">
                    <FormattedMessage
                      id="drawer.labelName.communicationTemplate.event"
                      defaultMessage=""
                    />
                    <span className="required">*</span>
                    
                  </Col>
                  <Col span={24}>
                    <Dropdown
                          items={transactionMode || []}
                          valueKeyName="trnMode"
                          optionKeyName="referCode"
                          value={(props.transactionMode || {}).trnMode}
                          placeholder={"Select Sub Module"}
                          // onSelect={(referCode) => {
                          //   const doc =
                          //     find(transactionMode, { referCode: referCode }) || {};
                          //   props.updateState({
                          //     transactionMode: doc,
                          //   });
                          // }}
                        />
                      {/* <ErrorMsg
                        message={"Transaction Mode is required"}
                      /> */}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Tabs type="line"
                activeKey={props.activeTabKey || "1"}

                onChange={(key) => {
                
                  props.updateState({ activeTabKey: key });
                }}
              >
         <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="drawer.tabName.communicationTemplate.email"
            defaultMessage=""
          />}
          key="1"
        >
         <Emails {...props}/>
         
        </TabPane>
        <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="drawer.tabName.communicationTemplate.sms"
            defaultMessage=""
          />}
          key="2"
        >
          {/* <div className="mt20"> */}
          <Sms {...props}/>
        </TabPane>
        <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="drawer.tabName.communicationTemplate.whatsapp"
            defaultMessage=""
          />}
          key="3"
        >
          <Whatsapp {...props}/>
         
        </TabPane>
        </Tabs>
          </Drawer>
          
        </Fragment>
      );
}

export default injectIntl(CommunicationTemplateDrawer);