import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
let config = {
    apiKey: "AIzaSyA9fOzmSl22NJ2_X4JRj7430Kqk208hke8",
    authDomain: "alpide-campaign.firebaseapp.com",
    databaseURL: "https://alpide-campaign-default-rtdb.firebaseio.com",
    projectId: "alpide-campaign",
    storageBucket: "alpide-campaign.appspot.com",
    messagingSenderId: "123953145574",
    appId: "1:123953145574:web:4e089495664f32c8ddbd27",
    measurementId: "G-P7NFGBDQ4L"

};
firebase.initializeApp(config);
export default firebase.default.firestore();