import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip, Empty } from 'antd';
import moment from 'moment-timezone';
import { fetchPaginationDataIfNeeded } from '../../../../utils'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants'
import {
    CONSTANTS
} from '../../../../static/constants';
import StandardPurchaseInvoice from "../../../../containers/supplier/invoice/StandardPurchaseInvoice"
import SupplierDetails from '../../../../containers/supplier/detail'

const PurchaseInvoiceDraftListing = (props) => {
    const { purchaseInvoiceDraftList, updateState, companyInfo, purchaseInvoiceDraftCount, pageNumber } = props;
    // const getRowHeight = (params) => {
    //     let height = 35;
    //     return height;
    // };


    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchPurchaseInvoiceDrafts', 'purchaseInvoiceDraftList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetDraftPaginatedData();
        props.fetchPurchaseInvoiceDrafts(payload);
        // props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    //const permittedModules = getActionMenuPermissionData();
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' />;
    //         },
    //         field: "supplierInvoiceDraftId",

    //         cellRendererFramework: (param) => <div className="cursor-pointer" >
    //             <Link
    //                 to={
    //                     {
    //                         pathname: '/admin/purchase-invoice',
    //                         state: {
    //                             supplierId: param.data.supplierId,
    //                             supplierInvoiceData: param.data.supplierInvoiceData,
    //                             supplierInvoiceDraftId: param.data.supplierInvoiceDraftId,
    //                             clone: true
    //                         }
    //                     }}
    //             >Draft-{param.value}</Link>
    //         </div>,
    //         rowDrag: true,
    //         resizable: true,
    //         width: 100,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.supplier' defaultMessage='' />;
    //         },
    //         field: "supplierName",
    //         resizable: true,
    //         width: 100,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" >
    //             <Link
    //                 to={
    //                     {
    //                         pathname: 'suppliers/details',
    //                         state: {
    //                             supplierId: link.data.supplierInvoiceData.supplierId,
    //                             supplierName: link.data.supplierInvoiceData.supplierName,
    //                         }
    //                     }}
    //                 className='company-name'
    //             >
    //                 {link.data.supplierInvoiceData.supplierName}
    //             </Link>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.salesOrder.listing.header.amount' default='Amount' />  </div>;
    //         },
    //         cellRendererFramework: (link) => {
    //             return link.data.supplierInvoiceData.invoiceTotalAmount && link.data.supplierInvoiceData.invoiceTotalAmount !== '-' ? <span> {link.data.supplierInvoiceData.foreignCurrencyIcon ? <i className={link.data.supplierInvoiceData.foreignCurrencyIcon}></i> : (link.data.supplierInvoiceData.foreignCurrency ? <span>{link.data.supplierInvoiceData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{ Number(link.data.supplierInvoiceData.invoiceTotalAmount || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
    //         },
    //         field: "invoiceTotalAmount",
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         width: 100,
    //     },


    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' />;
    //         },
    //         field: "invoiceDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.data.supplierInvoiceData.invoiceDate && moment(link.data.supplierInvoiceData.invoiceDate).format(CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT))}>
    //                     {(link.data.supplierInvoiceData.invoiceDate && moment(link.data.supplierInvoiceData.invoiceDate).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //         width: 100,
    //     },

    // ]

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };


    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     params.api.sizeColumnsToFit();
    // }


    return (
        <Fragment>

            <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage
                                id='purchase.ivoice.draft.table.heading'
                                defaultMessage=""
                            />
                        </div>

                        <div className="vertical-sep" />
                        <div>{props.purchaseInvoiceDraftCount}</div>

                    </div>
                    <div className="right-actions">

                    </div>
                </Skeleton>
            </div>
            {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            {props.txColumns.length ? <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={purchaseInvoiceDraftList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                suppressDragLeaveHidesColumns={true} 
                                animateRows={true}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                            >
                            </AgGridReact>: null}
                        </div>
                    </div> */}
            <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>

                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th><FormattedMessage id='serial.no' defaultMessage='' /></th>
                                <th><FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' /></th>
                                <th><FormattedMessage id='common.supplier' defaultMessage='' /></th>
                                <th><FormattedMessage id='customer.salesOrder.listing.header.amount' defaultMessage='' /></th>
                                <th><FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(purchaseInvoiceDraftList[pageNumber] || []).length ? purchaseInvoiceDraftList[pageNumber].sort((a, b) => a.dateCreated < b.dateCreated ? 1 : -1).map((rowData, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        {/* <td> <span className='child-sr'>{index+1 + '.' + Number(index+1)}</span> &nbsp;</td> */}
                                        <td><div className="cursor-pointer" >
                                            <div className='cusor-pointer'
                                                onClick={() => {
                                                    props.pushModalToStack({
                                                        modalBody: <StandardPurchaseInvoice {...props} supplierId={rowData.supplierId} supplierInvoiceData={rowData.supplierInvoiceData} supplierInvoiceDraftId={rowData.supplierInvoiceDraftId} clone={true} />,
                                                        width: '100%',
                                                        hideTitle: true,
                                                        hideFooter: true,
                                                        wrapClassName: 'new-transaction-wrapper'
                                                    })
                                                }}
                                            // to={
                                            //     {
                                            //         pathname: '/admin/purchase-invoice',
                                            //         state: {
                                            //             supplierId: rowData.supplierId,
                                            //             supplierInvoiceData: rowData.supplierInvoiceData,
                                            //             supplierInvoiceDraftId: rowData.supplierInvoiceDraftId,
                                            //             clone: true
                                            //         }
                                            //     }
                                            // }
                                            >Draft-{rowData.supplierInvoiceDraftId}</div>
                                        </div></td>
                                        <td><div className="cursor-pointer" >
                                            <div
                                                onClick={() => {
                                                    props.pushModalToStack({

                                                        modalBody: <SupplierDetails {...props}
                                                            supplierId={rowData.supplierId}
                                                            supplierName={rowData.supplierName}
                                                        />,
                                                        width: '100%',
                                                        hideTitle: true,
                                                        hideFooter: true,
                                                        wrapClassName: "new-transaction-wrapper",
                                                    })
                                                }}
                                                // to={
                                                //     {
                                                //         pathname: 'suppliers/details',
                                                //         state: {
                                                //             supplierId: rowData.supplierInvoiceData.supplierId,
                                                //             supplierName: rowData.supplierInvoiceData.supplierName,
                                                //         }
                                                //     }}
                                                className='company-name'
                                            >
                                                {rowData.supplierInvoiceData.supplierName}
                                            </div>
                                        </div></td>
                                        <td>{rowData.supplierInvoiceData.invoiceTotalAmount && rowData.supplierInvoiceData.invoiceTotalAmount !== '-' ?
                                         <span> {rowData.supplierInvoiceData.foreignCurrencyIcon ? <i className={rowData.supplierInvoiceData.foreignCurrencyIcon}></i> : (rowData.supplierInvoiceData.foreignCurrency ? <span>{rowData.supplierInvoiceData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{Number(rowData.supplierInvoiceData.invoiceTotalAmount || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : <div className="empty-data"></div>}</td>
                                        <td><div>
                                            <Tooltip placement="topLeft" title={(rowData.supplierInvoiceData.invoiceDate && moment(rowData.supplierInvoiceData.invoiceDate).format(CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT))}>
                                                {(rowData.supplierInvoiceData.invoiceDate && moment(rowData.supplierInvoiceData.invoiceDate).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
                                            </Tooltip>
                                        </div></td>
                                    </tr>
                                )
                            }) :
                                <tr key="empty-data">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Skeleton>
            <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="f-left"></div>

                    <div className="f-right">

                        <Pagination
                            size="small"
                            total={purchaseInvoiceDraftCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuic kJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props)
                            }}
                        />
                    </div>
                </Skeleton>
            </div>
        </Fragment>
    );
}

export default injectIntl(PurchaseInvoiceDraftListing);
