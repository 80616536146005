import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Menu, Input, Button, Pagination, Popover } from 'antd';
import PageBreadcrumb from "../../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { capitalizeFirstLetter, fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, sortTable } from '../../../../utils';
import { CONSTANTS, ICONS } from '../../../../static/constants';
import { DownloadOutlined, InsertRowRightOutlined } from "@ant-design/icons";
import { Dropdown } from '../../../general/Dropdown';
import EmployeeProfile from '../../../../containers/modal/modalBody/company/EmployeeProfile';
import debounce from 'lodash.debounce';
import { AddAndRemoveColumn } from '../../../general/AddAndRemoveEmployeeListing';


const { Search } = Input;

const EmployeeComp = (props) => {
  const { employeeList, pageNumber, employeesCount, pageSize, companyInfo } = props;
  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItems.employeeManagement' />,
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.employee' defaultMessage='' />,
    }
  ];

  const employeeProfile = (data) => {
    props.pushModalToStack({
      modalBody: <EmployeeProfile  {...data} />,
      width: '100%',
      hideTitle: true,
      hideFooter: true,
      wrapClassName: 'new-transaction-wrapper'
    })
  }
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      })
      +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded('fetchAllEmployeesByPage', 'employeeList', props, payload);

  };
  const handlePageSizeChange = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    // props.resetPaginatedData();
    props.fetchAllEmployeesByPage(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };
  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              employeeDrawer: true,
              empInfo: e,

            })
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>

        {e.isAccountHolder ? (
          <Menu.Item
            key="2"

          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
        ) : (
          ""
        )}

      </Menu>
    );
  };
  const onSearchedInputChange = debounce((searchedText, props) => {
    handleSearchedText(searchedText, props);
  }, 100);

  const handleSearchedText = (searchedText, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: props.pageSize,
      searchedText: searchedText,
      companyInfo: props.companyInfo,
    };
    if (searchedText.length) {
      if (searchedText.length >= 3) {
        // props.resetPaginatedData();
        props.getAllEmployeesCount(payload);
        props.fetchAllEmployeesByPage(payload);
      }
    } else {
      // props.resetPaginatedData();
      props.getAllEmployeesCount(payload);
      props.fetchAllEmployeesByPage(payload);
    }
  };
  const _isColumnToHide = (columnName) => {
    return (props.txColumns?.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
  }

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.employee"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button
            className="create-action-btn"
            onClick={() => {
              props.updateState({ employeeDrawer: true, empInfo: {} });
            }}
          >
            <i className={ICONS.ADD} />{" "}
            <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>

      <div className="view-container">
        <div className="view-container-actions">
          <Skeleton
            loading={props.headerLoading}
            paragraph={false}
            rows={1}
            active
          >
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="company.employeeMangement.employee.table.heading"
                  defaultMessage=""
                />
              </div>
              {employeesCount ? (
                <>
                  <div className="vertical-sep" />
                  <div>{employeesCount}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              <button onClick={() => {
                props.updateState({
                  employeeFilterDrawerVisible: true
                })
              }}>
                <i className={ICONS.FILTER} /> &nbsp; <FormattedMessage id='filter.text' defaultMessage='' />
              </button>
              <Search
                placeholder="Search Employee"
                value={props.searchedText}
                onChange={(e) => {
                  props.updateState({ searchedText: e.target.value, pageNumber: 1 });
                  onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  props.updateState({ searchedText, pageNumber: 1 });
                  onSearchedInputChange(searchedText, props);
                  handleSearchedText(searchedText, props);
                }}
              />


              <DropdownAction
                //  overlay={downloadMenu()}
                trigger={["click"]}
              >
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>
              <Popover
                content={<AddAndRemoveColumn {...props} />}
                title={<div className="text-center">Add/Remove</div>}
                placement="left"
                trigger="click"
              >
                <button>
                  <InsertRowRightOutlined className="mr5" /> Columns
                </button>
              </Popover>
            </div>
          </Skeleton>
        </div>

        <div className="table-container">
          <Skeleton loading={props.listLoading} paragraph={{ rows: 18 }} active>
            <table id="employee-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage=" " />
                  </th>
                  {!_isColumnToHide("employeeId") ? (

                    <th>
                      Employee Id
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("employeeName") ? (

                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.employee"
                        defaultMessage=" "
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("contact") ? (

                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.contact"
                        defaultMessage=" "
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("department") ? (

                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.department"
                        defaultMessage=" "
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("designation") ? (

                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.designation"
                        defaultMessage=" "
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("jobRole") ? (

                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.jobRole"
                        defaultMessage=" "
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("team") ? (

                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.team"
                        defaultMessage=" "
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("employeeType") ? (

                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.employeeType"
                        defaultMessage=" "
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("hierarchy") ? (

                    <th>
                      <FormattedMessage
                        id="company.employeeMangement.hierarchy"
                        defaultMessage=" "
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {/* <th>
                    <FormattedMessage
                      id="company.employeeMangement.payGradeName"
                      defaultMessage=" "
                    />
                  </th> */}
                  <th>
                    Date Created
                  </th>

                  {/* <th>
                    <FormattedMessage
                      id="company.employeeMangement.status"
                      defaultMessage=" "
                    />
                  </th> */}

                  <th className="text-center">
                    <FormattedMessage
                      id="company.employeeMangement.action"
                      defaultMessage=" "
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                {(employeeList[pageNumber] || [])?.length
                  ? employeeList[pageNumber].map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>
                        {!_isColumnToHide("employeeId") ? (

                          <td>
                            {rowData.employeeCode ? rowData.employeeCode : <div className="empty-data"></div>}
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("employeeName") ? (

                          <td>
                            <div className='cursor-pointer one-line-view' onClick={() => { employeeProfile(rowData) }}>
                              {capitalizeFirstLetter(rowData.fullName)}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {/* <td>{rowData.empType || <div className="empty-data"></div>}</td> */}
                        {!_isColumnToHide("contact") ? (
                          <td>
                            {rowData.cellNumber ? <div><i className={ICONS.CALL} /> {rowData.cellNumber}</div> : <div className="empty-data-box"></div>}
                            {rowData.emailAddress ? <div><i className={ICONS.MAIL} /> {rowData.emailAddress}</div> : <div className="empty-data-box"></div>}
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("department") ? (
                          <td>{rowData.departmentName || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("designation") ? (
                          <td>{rowData.designationName || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("jobRole") ? (
                          <td>{rowData.jobRoleName || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("team") ? (
                          <td>{rowData.teamName || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                         {!_isColumnToHide("employeeType") ? (
                          <td>{rowData.empType || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                         {!_isColumnToHide("hierarchy") ? (
                          <td>{rowData.hierarchyName || <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {/* <td>{rowData.hierarchyCode || <div className="empty-data"></div>}</td> */}

                        <td>
                          {rowData.dateCreated
                            ? getMomentDateForUIReadOnly({
                              date: rowData.dateCreated,
                              format: CONSTANTS.TABLE_DATE_FORMAT,
                            })
                            : "-"}
                        </td>

                        <td className='text-center'>
                          <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE_P} />
                          </DropdownAction>
                        </td>
                      </tr>
                    );
                  })
                  : ""}
              </tbody>
            </table>
          </Skeleton>
        </div>

        <div className="footer">
          <Skeleton
            loading={props.headerLoading}
            paragraph={false}
            rows={1}
            active
          >
            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={employeesCount}
                showTotal={showTotal}
                defaultPageSize={pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                onChange={(pageNumber, pageSize) => {

                  loadPageData(pageNumber, pageSize);
                  props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </Skeleton>
        </div>
      </div>
    </>
  );
};




export default injectIntl(EmployeeComp);
