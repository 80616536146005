import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
//import { Link } from 'react-router-dom';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Input,Tooltip } from 'antd';
// import { MODAL_TYPE } from '../../../static/constants';
import { CustomButton } from '../../general/CustomButton';
// import { CustomAgGrid } from '../../general/CustomAgGrid';
// import { UploadQuality } from '../../general/UploadQuality';
import { showToasterMessage } from '../../../utils';
//import { Dropdown } from '../../general/Dropdown';

//function QualityCheckDrawer(props) 
class AddTicketDrawer extends React.Component {
    render() {
        // const self = this;
        const closeDrawer = () => {
            this.props.updateState({
                addTicketDrawerDrawerVisible: false
            })
        }

        const handleAddTicket = () => {
            if (!this.props.ticketName || !this.props.price) {
                return showToasterMessage({
                    messageType: 'error', description: this.props.intl.formatMessage({ id: 'common.missing.requiredField.message' })
                });
            }
            let ticketList = this.props.ticketList || [];
            let ticketObj = {
                giveTicketItemDetailsId: this.props.giveTicketItemDetailsId,
                ticketName: this.props.ticketName,
                description: this.props.description,
                qtyMax: Number(this.props.qtyMax),
                price: Number(this.props.price)
            }
            if (this.props.ticketEditIndex > -1) {
                ticketList[this.props.ticketEditIndex] = ticketObj;
            }
            else {
                ticketList.push(ticketObj);
            }
            this.props.updateState({
                ticketList: ticketList,
                ticketName: '',
                description: '',
                qtyMax: 0,
                price: 0,
                ticketEditIndex: 0,
                giveTicketItemDetailsId: 0
            });
            closeDrawer();
        }

        return (
            <Fragment>

                <Drawer
                    title={<div className = 'drawer-title'>Ticket Information</div>}
                    width={720}
                    keyboard={false}
                    zIndex={1}
                    maskClosable={false}
                    destroyOnClose={true}
                    onClose={closeDrawer}
                    className="custom-supplier-drawer"
                    visible={this.props.addTicketDrawerDrawerVisible}
                >

                    <div>
                        <Row className="pb10">
                            <Col span={6} className="title text-right">
                                <FormattedMessage id='ticketName.text' /><span className="required">*</span>
                            </Col>
                            <Col span={14}>
                                <Input style={{ width: '100%' }} value={this.props.ticketName} placeholder="" onChange={(e) => {
                                    this.props.updateState({ ticketName: e.target.value });
                                }}
                                    className={(this.props.submittedOnce && !this.props.ticketName) ? 'input-text-error' : ''}
                                />
                            </Col>
                        </Row>

                        <Row className="pb10">
                            <Col span={6} className="title text-right">
                                <FormattedMessage id='ticketDescription.text' />
                                <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'ticketDescription.helpMessag' })} trigger="click">
                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                </Tooltip>
                            </Col>
                            <Col span={14}>
                                <Input style={{ width: '100%' }} value={this.props.description} placeholder="" onChange={(e) => {
                                    this.props.updateState({ description: e.target.value });
                                }}
                                />
                            </Col>
                        </Row>

                        <Row className="pb10">
                            <Col span={6} className="title text-right">
                                <FormattedMessage id='ticketQtyAvailable.text' />
                            </Col>
                            <Col span={14}>
                                <Input style={{ width: '100%' }} type='number' min={0} value={this.props.qtyMax} placeholder="" onChange={(e) => {
                                    if (e.target.value >= 0) { this.props.updateState({ qtyMax: e.target.value }); }
                                    else { this.props.updateState({ qtyMax: 0 }); }
                                }}
                                />
                            </Col>
                        </Row>

                        <Row className="pb10">
                            <Col span={6} className="title text-right">
                                <FormattedMessage id='ticketPrice.text' /><span className="required">*</span>
                            </Col>
                            <Col span={14}>
                                <div className='input-currency-control '> <span className="currency-tag" style={{ left: '0px', top: '0px' }}><i className="fa fa-dollar"></i></span>
                                    <Input style={{ width: '90%' }} type='number' min={0} value={this.props.price} placeholder="" onChange={(e) => {
                                        if (e.target.value >= 0) { this.props.updateState({ price: e.target.value }); }
                                        else { this.props.updateState({ price: 0 }); }
                                    }}
                                        className={(this.props.submittedOnce && !this.props.price) ? 'input-text-error' : ''} />

                                </div>
                            </Col>
                        </Row>
                       
                    </div>

                    <div className='form-footer'>
                        <CustomButton
                            intlId='confirmation.cancel'
                            defaultMessage=''
                            type='default'
                            key={0}
                            style={{ marginRight: 8 }}
                            onClick={closeDrawer}
                        />
                        <CustomButton
                            onClick={() => {
                                handleAddTicket();
                            }}
                            type='primary'
                            htmlType='submit'
                            intlId='addTicket.text'
                            defaultMessage=''
                        />
                    </div>
                </Drawer>
            </Fragment>
        );
    }
}

export default injectIntl(AddTicketDrawer);
