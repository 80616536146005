import React, { Component } from "react";
import { Steps, Button, Col, Row, Divider, Empty, Skeleton } from 'antd';
import './style.scss';
import CarriarsPrice from "./CarriarsPrice";
import { FormattedMessage, } from 'react-intl';
import { CONSTANTS } from '../../../../../static/constants';
import {
    USPSLOGO,
    CareemLogo,
    UpsLogo,
    FedexLogo,
    ImagePlaceholder,
    AustraliaPostLogo,
    CanadaPostLogo,
    ChinaPostLogo,
    DeutschePost,
    DhlLogo,
    JapanPostLogo,
    RoyalMailLogo,
    Accurate,
    Amazon,
    Apc,
    Asendia,
    BetterTrucks,
    Canpar,
    Cdl,
    Chronopost,
    CirroEcom,
    CloudSort,
    LSO,
    CourierExpress,
    CourierPlease,
    DaiPost,
    DeliverIt,
    DhlEcom,
    DhlExpress,
    DpdLogo,
    EpostGlobal,
    Estafeta,
    DhlPacket,
    EvriLogo,
    Fastway,
    FirstChoice,
    FirstMile,
    Flexport,
    GIO,
    GSO,
    Hailify,
    InterlinkExpress,
    JetLogo,
    JitsuLogo,
    JpPost,
    KuronekoYamato,
    LaPoste,
    Lasership,
    LoomisExpress,
    Maergo,
    Newgistics,
    Omniparcel,
    Ontrac,
    Optima,
    OsmWorldwide,
    Pandion,
    ParcelForce,
    Purolator,
    RoyalMail,
    Sendle,
    Passport,
    Postnl,
    TforceLogistics,
    SfExpress,
    Smartkargo,
    Speedee,
    SwyftLogo,
    TollLogo,
    UdsLogo,
    UpsIParcel,
    UpsMailInnovations,
    Ups,
    Veho,
    Yanwen
} from '../../../../../assets/images';
import { fixedDecimalAndFormateNumber, getCurrencySymbol, getMomentDateForUIReadOnly, showToasterMessage, } from '../../../../../utils';
import * as find from 'lodash.find';
// import SoDetails from "../../../../customer/salesOrder/MultiCurrencySalesOrder/SoDetails";

class GenerateShippingLabelEasyPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: this.props.data?.boughtShipment && this.props.data?.boughtShipment.purchasedShipmnetEasyPostId ? 1 : 0,
            selectedCarrierItem: {},
            txData: this.props.data.txData || {},
        };
    }
    next() {
        const current = this.state.current + 1;
        this.setState({ current });
    }
    prev() {
        const current = this.state.current - 1;
        this.setState({ current });
    }


    getCarriarPrice(props) {
        let shipmentData = props.data?.txData || {};
        const relationshipAddress = find(shipmentData.boLocationShipmentList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
        const shippingAddress = find(shipmentData.boLocationShipmentList || [], { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};

        let payload = {
            carrierCodes: props.selectedCarriarCodes || [],
            // weight: {
            //     value: shipmentData.weight,
            //     units: shipmentData.measurementUnit
            // },
            fromPostalCode: relationshipAddress.zipCode,
            toState: shippingAddress.stateName,
            toCountry: shippingAddress.countryName,
            toPostalCode: shippingAddress.zipCode,
            toCity: shippingAddress.cityName,
            weight: shipmentData.weight,
            measurementUnit: shipmentData.measurementUnit,
            length: shipmentData.length,
            width: shipmentData.width,
            height: shipmentData.height,
            dimensionUnit: shipmentData.dimensionUnit
        }
        props.getCarriersPrice(payload);
    }



    generateLabel(props) {
        let shipmentData = props.data?.txData || {};
        const relationshipAddress = find(shipmentData.boLocationShipmentList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
        const shippingAddress = find(shipmentData.boLocationShipmentList || [], { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
        const contact = (shipmentData.additionalInfoList || [])[0] || {};
        let payload = {
            carrierCode: props.selectedPriceCarriarCode,
            serviceCode: props.selectedServiceCode,
            packageCode: '',
            weight: {
                value: shipmentData.weight,
                units: shipmentData.measurementUnit
            },
            shipFrom: {
                name: contact.firstName,
                company: props.companyInfo?.storeName,
                phone: contact.cellPhone,
                email: contact.emailAddress,
                street1: relationshipAddress.streetAddress1,
                street2: relationshipAddress.streetAddress2,
                city: relationshipAddress.cityName,
                state: relationshipAddress.stateName,
                postalCode: relationshipAddress.zipCode,
                country: relationshipAddress.countryName
            },
            shipTo: {
                name: contact.firstName,
                company: props.companyInfo?.storeName,
                phone: contact.cellPhone,
                email: contact.emailAddress,
                street1: shippingAddress.streetAddress1,
                street2: shippingAddress.streetAddress2,
                city: shippingAddress.cityName,
                state: shippingAddress.stateName,
                postalCode: shippingAddress.zipCode,
                country: shippingAddress.countryName
            }
        }
        props.createLabel(payload);
    }

    getIconForCarrier = (carrierName) => {
        const imgStyle = { maxWidth: 100, maxHeight: 40 }
        const cName = (carrierName || '').toLowerCase().replace(/\s/g, "");
        switch (true) {
            case cName.includes('usps'):
                return <img src={USPSLOGO} alt="source-logo" style={imgStyle} />
            case cName.includes('careem'):
                return <img src={CareemLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('fedex'):
                return <img src={FedexLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('ups'):
                return <img src={UpsLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('australiapost'):
                return <img src={AustraliaPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('canadapost'):
                return <img src={CanadaPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('chinapost'):
                return <img src={ChinaPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('deutschepost'):
                return <img src={DeutschePost} alt="source-logo" style={imgStyle} />
            case cName.includes('dhl'):
                return <img src={DhlLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('japanpost'):
                return <img src={JapanPostLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('royalmail'):
                return <img src={RoyalMailLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('accurate'):
                return <img src={Accurate} alt="source-logo" style={imgStyle} />
            case cName.includes('amazon'):
                return <img src={Amazon} alt="source-logo" style={imgStyle} />
            case cName.includes('apc'):
                return <img src={Apc} alt="source-logo" style={imgStyle} />
            case cName.includes('asendiausa'):
                return <img src={Asendia} alt="source-logo" style={imgStyle} />
            case cName.includes('bettertrucks'):
                return <img src={BetterTrucks} alt="source-logo" style={imgStyle} />
            case cName.includes('canpar'):
                return <img src={Canpar} alt="source-logo" style={imgStyle} />
            case cName.includes('cdl'):
                return <img src={Cdl} alt="source-logo" style={imgStyle} />
            case cName.includes('chronopost'):
                return <img src={Chronopost} alt="source-logo" style={imgStyle} />
            case cName.includes('cirroecom'):
                return <img src={CirroEcom} alt="source-logo" style={imgStyle} />
            case cName.includes('cloudsort'):
                return <img src={CloudSort} alt="source-logo" style={imgStyle} />
            case cName.includes('lso'):
                return <img src={LSO} alt="source-logo" style={imgStyle} />
            case cName.includes('courierexpress'):
                return <img src={CourierExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('courierplease'):
                return <img src={CourierPlease} alt="source-logo" style={imgStyle} />
            case cName.includes('daipost'):
                return <img src={DaiPost} alt="source-logo" style={imgStyle} />
            case cName.includes('deliverit'):
                return <img src={DeliverIt} alt="source-logo" style={imgStyle} />
            case cName.includes('dhlecom'):
                return <img src={DhlEcom} alt="source-logo" style={imgStyle} />
            case cName.includes('dhlexpress'):
                return <img src={DhlExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('dhlpacket'):
                return <img src={DhlPacket} alt="source-logo" style={imgStyle} />
            case cName.includes('dpdlogo'):
                return <img src={DpdLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('epostglobal'):
                return <img src={EpostGlobal} alt="source-logo" style={imgStyle} />
            case cName.includes('estafeta'):
                return <img src={Estafeta} alt="source-logo" style={imgStyle} />
            case cName.includes('evrilogo'):
                return <img src={EvriLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('fastway'):
                return <img src={Fastway} alt="source-logo" style={imgStyle} />
            case cName.includes('firstchoice'):
                return <img src={FirstChoice} alt="source-logo" style={imgStyle} />
            case cName.includes('firstmile'):
                return <img src={FirstMile} alt="source-logo" style={imgStyle} />
            case cName.includes('flexport'):
                return <img src={Flexport} alt="source-logo" style={imgStyle} />
            case cName.includes('gio'):
                return <img src={GIO} alt="source-logo" style={imgStyle} />
            case cName.includes('gso'):
                return <img src={GSO} alt="source-logo" style={imgStyle} />
            case cName.includes('hailify'):
                return <img src={Hailify} alt="source-logo" style={imgStyle} />
            case cName.includes('interlinkexpress'):
                return <img src={InterlinkExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('jetlogo'):
                return <img src={JetLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('jitsulogo'):
                return <img src={JitsuLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('jppost'):
                return <img src={JpPost} alt="source-logo" style={imgStyle} />
            case cName.includes('kuronekoyamato'):
                return <img src={KuronekoYamato} alt="source-logo" style={imgStyle} />
            case cName.includes('laposte'):
                return <img src={LaPoste} alt="source-logo" style={imgStyle} />
            case cName.includes('lasership'):
                return <img src={Lasership} alt="source-logo" style={imgStyle} />
            case cName.includes('loomisexpress'):
                return <img src={LoomisExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('maergo'):
                return <img src={Maergo} alt="source-logo" style={imgStyle} />
            case cName.includes('newgistics'):
                return <img src={Newgistics} alt="source-logo" style={imgStyle} />
            case cName.includes('omniparcel'):
                return <img src={Omniparcel} alt="source-logo" style={imgStyle} />
            case cName.includes('ontrac'):
                return <img src={Ontrac} alt="source-logo" style={imgStyle} />
            case cName.includes('pptima'):
                return <img src={Optima} alt="source-logo" style={imgStyle} />
            case cName.includes('osmworldwide'):
                return <img src={OsmWorldwide} alt="source-logo" style={imgStyle} />
            case cName.includes('pandion'):
                return <img src={Pandion} alt="source-logo" style={imgStyle} />
            case cName.includes('parcelforce'):
                return <img src={ParcelForce} alt="source-logo" style={imgStyle} />
            case cName.includes('passport'):
                return <img src={Passport} alt="source-logo" style={imgStyle} />
            case cName.includes('postnl'):
                return <img src={Postnl} alt="source-logo" style={imgStyle} />
            case cName.includes('purolator'):
                return <img src={Purolator} alt="source-logo" style={imgStyle} />
            case cName.includes('royalmail'):
                return <img src={RoyalMail} alt="source-logo" style={imgStyle} />
            case cName.includes('sendle'):
                return <img src={Sendle} alt="source-logo" style={imgStyle} />
            case cName.includes('tforcelogistics'):
                return <img src={TforceLogistics} alt="source-logo" style={imgStyle} />
            case cName.includes('sfexpress'):
                return <img src={SfExpress} alt="source-logo" style={imgStyle} />
            case cName.includes('smartkargo'):
                return <img src={Smartkargo} alt="source-logo" style={imgStyle} />
            case cName.includes('speedee'):
                return <img src={Speedee} alt="source-logo" style={imgStyle} />
            case cName.includes('swyftlogo'):
                return <img src={SwyftLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('tolllogo'):
                return <img src={TollLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('udslogo'):
                return <img src={UdsLogo} alt="source-logo" style={imgStyle} />
            case cName.includes('upsiparcel'):
                return <img src={UpsIParcel} alt="source-logo" style={imgStyle} />
            case cName.includes('upsmailinnovations'):
                return <img src={UpsMailInnovations} alt="source-logo" style={imgStyle} />
            case cName.includes('ups'):
                return <img src={Ups} alt="source-logo" style={imgStyle} />
            case cName.includes('veho'):
                return <img src={Veho} alt="source-logo" style={imgStyle} />
            case cName.includes('yanwen'):
                return <img src={Yanwen} alt="source-logo" style={imgStyle} />
            default:
                return <span style={{ fontSize: '18px', fontWeight: 700 }}>{carrierName}</span>
        }
    }

    
    render() {
        const { current, txData } = this.state;
        
        const { carrierDetails } = this.props;

        const  {rates=[], messages=[], toAddress={}, fromAddress={}} = carrierDetails || {}
        
        const getShippingAddress = (obj={}) => {
    
            return <>
                {
                    obj.street1
                        ? <span className='billing-address'>{obj.street1}
                        </span>
                        : ''
                }
                {
                    obj.street2
                        ? <span className='billing-address'> {obj.street2}
                        </span>
                        : ''
                }
                {
                    obj.city
                        ? <div className='billing-address'>
                            {
                                `${obj.city || ''}${obj.state ? `,  ${obj.state}` : ''}${obj.zip ? `, ${obj.zip}` : ''}`
                            }
                        </div>
                        : ''
                }
                {
                    obj.country
                        ? <div className='billing-address'>
                            {
                                `${obj.country || ''}`
                            }
                        </div>
                        : ''
                }
            </>
        }

        const Step = Steps.Step;
        const steps = [

            {
                title: <FormattedMessage id='carriarsPrice.text' />,
                content: CarriarsPrice(this.props),
                key: 1
            },
            {
                title: <FormattedMessage id='downloadShippingLabel.text' />,
                content: <div></div>,
                key: 2
            }
        ];

        function addSpacesToCamelCase(str) {
            return str.replace(/([a-z])([A-Z])/g, '$1 $2');
        }

        return (<>
            <Row>
                <Col span={8}>
                    <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 2 }} active>
                        <Row>
                            <Col span={12}>
                                Customer Name
                            </Col>
                            <Col span={12}>
                                {txData.customerName || '-'}
                            </Col>

                            <Col span={12}>
                                Shipment Size
                            </Col>
                            <Col span={12}>
                                {txData?.length && txData?.width && txData?.height ? `${txData?.length} x ${txData?.width} x ${txData?.height}` : '-'} {txData?.dimensionUnit ? txData.dimensionUnit : 'Inches'}
                            </Col>

                            <Col span={12}>
                                Shipment Weight
                            </Col>
                            <Col span={12}>
                                {txData.weight || 0} {txData.weightUnit || 'Ounces'}
                            </Col>
                        </Row>
                    </Skeleton>
                </Col>

                <Col span={8}>
                    <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 2 }} active>
                        <Row>
                            <Col span={12}>
                                Order Number
                            </Col>
                            <Col span={12}>
                                {txData.shipmentNumber || '-'}
                            </Col>

                            <Col span={12}>
                                Order Date
                            </Col>
                            <Col span={12}>
                                {txData?.dateCreated ? getMomentDateForUIReadOnly(txData.dateCreated) : "-"}
                            </Col>

                            <Col span={12}>
                                Order Amount
                            </Col>
                            <Col span={12}>
                                {getCurrencySymbol(this.props?.companyInfo?.currencyCode)}{fixedDecimalAndFormateNumber(this.props.soDetail?.salesOrderTotalAmount || 0)}
                            </Col>
                        </Row>
                    </Skeleton>
                </Col>

                <Col span={8}>
                    <Skeleton loading={this.props.detailLoading || this.props.isLoadingCarriarList} paragraph={{ rows: 2 }} active>
                        <Row gutter={[0, 8]}>
                            <Col span={12}>
                                Ship from
                            </Col>
                            <Col span={12} style={{lineHeight: '1.3em'}}>
                                {getShippingAddress(this.state.current === 1 ? this.props.buyShipmentDetails?.fromAddress : fromAddress)}
                            </Col>
                            <Col span={12}>
                               Ship to
                            </Col>
                            <Col span={12} style={{lineHeight: '1.3em'}}>
                                {getShippingAddress(this.state.current === 1 ? this.props.buyShipmentDetails?.toAddress : toAddress)}
                            </Col>
                        </Row>
                    </Skeleton>
                </Col>
            </Row>

            <Divider className="mt10 mb20" />

            <Steps current={current}>
                {steps.map(item => <Step key={item.key} title={item.title} />)}
            </Steps>

            {
                this.state.current === 0 ?
                    <Skeleton loading={this.props.isLoadingCarriarList} paragraph={{ rows: 5 }} active>
                        <table style={{ width: '100%' }} className='mt20'>
                            <thead style={{ borderBottom: '1px solid gray' }}>
                                <tr>
                                    <th style={{ width: '50px' }}></th>
                                    <th>Carrier</th>
                                    <th>Service</th>
                                    <th>Delivery Date</th>
                                    <th>Delivery in (days)</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rates?.length ?
                                        rates.map((item, i) => {
                                            return <tr key={'carr' + i} style={{ height: '40px' }}>
                                                <td>
                                                    <input type="radio" id='regular' name="optradio" onClick={() => {
                                                        this.setState({ selectedCarrierItem: item });
                                                    }} />
                                                </td>
                                                <td>{this.getIconForCarrier(item.carrier)}</td>
                                                <td>{addSpacesToCamelCase(item.service || '')}</td>
                                                <td><div className="empty-data" /></td>
                                                <td>{item.deliveryDays}</td>
                                                <td>{getCurrencySymbol(item.currency)}{item.listRate}</td>
                                            </tr>
                                        })
                                        :
                                        <tr key="empty-data-box">
                                            <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                                <Empty />
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>

                        <div className="required mt20">
                            {
                                (messages || []).length ?
                                    <>
                                        {rates?.length ? 'Some carriers' : 'Carriers'} rates may have not been provided due to following reasons:
                                        <ul>
                                            {messages.map((item, i) => { return <li key={'ca' + i}><b>{item.carrier}</b> - {item.message}</li> })}
                                        </ul>
                                    </>
                                    :
                                    ""
                            }

                        </div>
                    </Skeleton>
                    : ''
            }

            {
                this.state.current === 1 ? <div className='mt20 text-center'>
                    <img src={this.props.buyShipmentDetails?.postageLabel?.labelUrl} alt='Shipping Label' style={{ height: '300px', width: '200px' }}></img><br />
                    <a
                        href={this.props.buyShipmentDetails?.tracker?.publicUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Track your shipment
                    </a>
                </div>
                    : ''
            }

            {/* {
                    this.state.current === 2 ? <div className='mt80 mb90 text-center'>
                        <LoadingOutlined />
                        <h3>Downloading shipment label....</h3>
                    </div>
                    : ''
                    
                }
                {
                    this.state.current === 2 ? setTimeout(()=>{
                        this.next()
                    }, 5000) :''
                } */}


            <div className="fot-fix" style={{ maxWidth: '100%' }}>
                {
                    this.state.current === 0 ? <div className="text-center">
                        <Button
                            type="primary"
                            onClick={() => {
                                if (!(Object.keys(this.state?.selectedCarrierItem || {}).length)) {
                                    return showToasterMessage({ description: 'Please select carrier service' });
                                } else {
                                    this.next();
                                    const payload = {
                                        customerId: txData.customerId || '',
                                        relationshipId: txData.relationshipId || '',
                                        shipmentMasterId: txData.shipmentMasterId || '',
                                        rateId: this.state.selectedCarrierItem.id || '',
                                        shipmentId: this.state.selectedCarrierItem.shipmentId || '',
                                        carrier: this.state.selectedCarrierItem.carrier,
                                        service: this.state.selectedCarrierItem.service,
                                        rateId: this.state.selectedCarrierItem.id,
                                        deliveryDays: this.state.selectedCarrierItem.deliveryDays
                                    }
                                    this.props.buyShipment(payload, this.props)
                                }

                            }}
                        >
                            <FormattedMessage id='shippingLabel.buyShipping.button' defaultMessage=' ' />{this.state.selectedCarrierItem.listRate ? `($${this.state.selectedCarrierItem.listRate})` : ''}
                        </Button>
                    </div>
                        : ''
                }
                {
                    this.state.current === 1 ? <div className='text-center'>
                        <Button type="primary" className="ml10" onClick={() => {
                            window.open(this.props.buyShipmentDetails?.postageLabel?.labelUrl, '_blank');
                            //  downloadFileFromPublicUrl(this.props.buyShipmentDetails?.postageLabel?.labelUrl, txData.shipmentNumber);
                            // this.next();
                        }}>
                            <FormattedMessage id='shippingLabel.downloadShippingLabel.button' defaultMessage=' ' />
                        </Button>
                    </div>
                        : ''
                }
                {
                    this.state.current === 2 ? <div className='text-center'>
                        <Button className="ml10" onClick={() => {
                            this.props.popModalFromStack();
                        }}>
                            <FormattedMessage id='button.close.label' defaultMessage=' ' />
                        </Button>
                    </div>
                        : ''
                }
                {/* {
                        this.state.current === 2 ? <div className='text-center'>
                            <Button type="primary" loading className="ml20">
                                Downloading ...
                            </Button>
                        </div>
                        : ''
                    }
                    {
                        this.state.current === 3 ? <div className='text-center'>
                            <Button type="primary" className="ml10" onClick={()=>{
                                this.props.popModalFromStack();
                            }}>
                                Finish
                            </Button>
                        </div>
                        : ''
                    } */}

                {/* {
                        this.state.current > 0
                        &&
                        <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                            <FormattedMessage id='company.companySetup.button.previous' defaultMessage=' ' />
                        </Button>
                    }
                    {
                        this.state.current < steps.length - 1
                        &&
                        <Button type="primary" className="ml10" onClick={() => {
                            if (!this.props.selectedCarriarCodes || this.props.selectedCarriarCodes.length === 0) {
                                return showToasterMessage({ description: 'Please Select Carriars' });
                            } else {
                                this.next();
                                this.getCarriarPrice(this.props);
                            }
                        }
                        }>
                            {this.state.current === 0 ?
                                <FormattedMessage id='getRate.text' defaultMessage=' ' />
                                :
                                <FormattedMessage id='buyLabel.text' defaultMessage=' ' />
                            }
                        </Button>
                    }
                    {
                        this.state.current === steps.length - 1
                        &&
                        <Button type="primary" className="ml10" onClick={() => {
                            if (!this.props.selectedPriceCarriarCode || this.props.selectedPriceCarriarCode.length === 0) {
                                return showToasterMessage({ description: 'Please Select Carriars price' });
                            } else {
                                this.generateLabel(this.props);
                            }
                        }}>
                            <FormattedMessage id='company.companySetup.button.done' defaultMessage=' ' />
                        </Button>
                    } */}
            </div>

            <div className="inner-wrapper" style={{ paddingBottom: '40px' }} />
        </>
        );
    }
}

export default GenerateShippingLabelEasyPost;
