import React, { Fragment,  } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Pagination, Popover, Tooltip,Empty } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, } from '../../../../utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import './index.scss';
//import PageBreadcrumb from '../../../PageBreadcrumb';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import PODetail from '../../../../containers/modal/modalBody/supplier/PurchaseOrderDetail';
import RFQDetail from '../../../../containers/modal/modalBody/supplier/RFQDetail';
import RFQSplitDetail from '../../../../containers/modal/modalBody/supplier/RFQSplitDetail';
import PRDetail from '../../../../containers/modal/modalBody/supplier/PRDetail';
import InboundDeliveryInformation from '../../../../containers/modal/modalBody/supplier/InboundDeliveryInformation';
import { CONSTANTS } from '../../../../static/constants';
import { AddAndRemoveSupplierListingColumn } from "../../../general/AddAndRemoveSupplierListingColumn";
// import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
// import checkIcon from '../../../../assets/images/check.png';
// import crossIcon from '../../../../assets/images/cross.png';
// import find from 'lodash.find';
// import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
// import { TextBox } from '../../../general/TextBox';
// import { Dropdown } from '../../../general/Dropdown';
import { FilterOutlined, InsertRowRightOutlined } from '@ant-design/icons';
import SupplierDetails from '../../../../containers/supplier/detail'


const format = CONSTANTS.DISPLAY_DATE_FORMAT;
// const { RangePicker } = DatePicker;
// const { Panel } = Collapse;

const PurchaseOrderListingComp = (props) => {
    const { purchaseOrderList, updateState, companyInfo, purchaseOrderCount, pageNumber,pageSize } = props;
    // const [collapseActiveKey, setCollapseActiveKey] = useState('');

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchPurchaseOrders', 'purchaseOrderList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchPurchaseOrders(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }
    // const getRowHeight = (params) => {
    //     let height = 28;
    //     return height;
    // }

    const openPODetails = (data) => {
        const poPayload = {
            supplierId: data.supplierId,
            poMasterId: data.poMasterId
        }

        props.pushModalToStack({
            modalBody: <PODetail {...props} poPayload={poPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const openRFQDetails = (data) => {
        const rfqPayload = {
            rfqMasterId: data.rfqMasterId,
            rfqChildMasterId: data.rfqChildMasterId
        }
        props.pushModalToStack({
            modalBody: data.rfqChildMasterId ? <RFQSplitDetail {...props} rfqPayload={rfqPayload} /> : <RFQDetail {...props} rfqPayload={rfqPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail',
        })
    }

    const openPRDetails = (data) => {
    
        const prPayload = {
            supplierId: data.supplierId,
            prMasterId: data.purchaseRequestMasterId
        }
        props.pushModalToStack({
            modalBody: <PRDetail {...props} prPayload={prPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const getRecievedQuantity = (data) => {
        return  <div className="cursor-pointer circles-ling">
            {( data.statusInvoice === 'fulfilled')? <i className="fa fa-circle"></i>  : (data.statusInvoice === 'partially.fulfilled' ? <i className="fa fa-adjust"></i> : <i className="fa fa-circle-o" ></i> )}
            </div>;
            // <div>
            // <Progress className={data.totalQtyReceived >= data.totalQtyOrdered ? "complete" : "incomplete"} format={() => {
            //     return `${data.totalQtyReceived}/${data.totalQtyOrdered}`
            // }} percent={(data.totalQtyReceived / data.totalQtyOrdered) * 100} /></div>;
    }

    const getInboundDeliveryQuantity = (data) => {
        return <div className="cursor-pointer circles-ling" onClick={() => {
            if (data.status === 'Pending Approval' || data.status === 'Rejected') return;
            props.showModal(
                {
                    title: <div  style={{display : "flex", flexDirection  : "column"}}>
                        <div className='ant-modal-title'>Goods Receipt Note</div>
                        <div className="ant-modal-subtitle extra-sm">{data.supplierName}</div>
                    </div>,
                    modalBody: <InboundDeliveryInformation poData={data} {...props} />,
                    width: 900,
                    hideFooter: true
                }
            );
        }}> 
        {( data.statusInboundDelivery === 'received')? <i className="fa fa-circle"></i>  : (data.statusInboundDelivery === 'partially.received' ? <i className="fa fa-adjust"></i> : <i className="fa fa-circle-o" ></i> )}
        </div>;
    }

    const _isColumnToHide = (columnName) => {
      return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
  }

    // const saveColumnIndex = (columnState) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data);
    // }

    // const saveColumnWidth = (columnState, width) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data, props);
    // }

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
    //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
    //     event.api.resetRowHeights();
    //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
    //         event.api.resetRowHeights();
    //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
    //             props.gridApi.sizeColumnsToFit();
    //             setTimeout(() => {
    //                 const columnArray = event.columnApi.getColumnState();
    //                 saveColumnWidth(columnArray);
    //             }, 1000);
    //         } else {
    //             saveColumnWidth(event.columnApi.getColumnState());
    //         }
    //     }
    // }



    // const getActionMenuPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedModules = {
    //         firstSection: {
    //             updatePurchaseOrder: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_ORDER, PERMISSION_VALUES.UPDATE),
    //             clonePurchaseOrder: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_ORDER, PERMISSION_VALUES.CREATE),
    //             deletePurchaseOrder: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PURCHASE_ORDER, PERMISSION_VALUES.DELETE)
    //         }
    //     };
    //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
    //         if (permittedModules.firstSection[moduleName] === false) {
    //             delete permittedModules.firstSection[moduleName];
    //         }
    //     })
    //     return permittedModules;
    // }


    // const openAuditDrawer = (props, data) => {
    //     props.updateState({
    //         title: <div className="drawer-title">
    //             Audit Trail
    //         </div>,
    //         auditDrawerVisible: true,
    //         deletApiUrl: 'deleteAuditTrailPurchaseOrder',
    //         masterKey: 'poMasterId',
    //         masterKeyValue: data.poMasterId,
    //         apiUrl: 'saveAuditTrailPurchaseOrder',
    //         supplierId: data.supplierId,
    //         trailList: data.auditTrailPurchaseOrderList || [],
    //         updateTrailListInTransaction: (list) =>{
    //             data.auditTrailPurchaseOrderList = list;
    //         } 
    //     });
    // }

    // const addItemConfirmationModal = (props, text, poData) => {
    //     const { showModal, companyInfo } = props;
    //     const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
    //     const modalBody = <span>
    //         <FormattedMessage
    //             id='setItem.confirmation'
    //             defaultMessage={``}
    //         />
    //         {labelName} ?
    //   </span>;

    //     let payload = {
    //         userStatus: text,
    //         relationshipId: companyInfo.relationshipId,
    //         poMasterId: poData.poMasterId,
    //         supplierId: poData.supplierId
    //     };

    //     const modalData = {
    //         modalBody,
    //         handleSubmit: () => {
    //             props.updateUserStatus(payload, props);
    //             props.hideModal();
    //             props.popModalFromStack();
    //         },
    //     };
    //     showModal(modalData);
    // }



    // const updateUserStatus = (props, poData) => {
    //     let payload = { formData: {} };
    //     const formData = payload.formData;
    //     const { pushModalToStack } = props;

    //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
    //         addItemConfirmationModal(props, formData.textBoxValue, poData);
    //     } else {
    //         const data = {
    //             title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
    //             formData,
    //             hideFooter: true,
    //             modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.PURCHASE_ORDER },
    //             handleSubmit: (formData = {}) => {
    //                 if (formData.textBoxValue && formData.textBoxValue.length) {
    //                     addItemConfirmationModal(props, formData.textBoxValue, poData);
    //                 } else {
    //                     data.formData.submittedOnce = true;
    //                     pushModalToStack(data);
    //                 }
    //             }
    //         };
    //         pushModalToStack(data);
    //     }
    // }


    // const actionMenu = (param) => (
    //     <Menu className="cell-action-dropdown">
    //         {/* <Menu.Item key="1" onClick={() => {
    //             openAuditDrawer(props, param.data);
    //         }}>
    //             <i className="fa fa-history" />
    //                  View Audit Trail
    //                 </Menu.Item> */}
    //         {
    //             permittedModules.firstSection.updatePurchaseOrder
    //                 ? (
    //                     <Menu.Item key="1">
    //                         <i className="fa fa-pencil" />
    //                         <Link
    //                             to={
    //                                 {
    //                                     pathname: '/admin/po-update',
    //                                     state: {
    //                                         supplierId: param.data.supplierId,
    //                                         poMasterId: param.data.poMasterId,
    //                                         update: true
    //                                     }
    //                                 }}
    //                         >Update Purchase Order</Link>
    //                     </Menu.Item>
    //                 )
    //                 : <Fragment />
    //         }


    //         {
    //             permittedModules.firstSection.clonePurchaseOrder
    //                 ? (
    //                     <Menu.Item key="2">
    //                         <i className="fa fa-clone" />
    //                         <Link
    //                             to={
    //                                 {
    //                                     pathname: '/admin/po-create',
    //                                     state: {
    //                                         supplierId: param.data.supplierId,
    //                                         poMasterId: param.data.poMasterId,
    //                                         clone: true
    //                                     }
    //                                 }}
    //                         >Clone Purchase Order</Link>
    //                     </Menu.Item>
    //                 )
    //                 : <Fragment />
    //         }

    //         {permittedModules.deletePurchaseOrder && <Menu.Item key="3">
    //             <i className="fa fa-trash" />
    //             <Link onClick={() => {
    //                 props.showModal({
    //                     modalBody: "Are you sure you want to delete this transaction?",
    //                     handleSubmit: () => {
    //                         props.deletePurchaseOrder({
    //                             supplierId: param.data.supplierId,
    //                             poMasterId: param.data.poMasterId,
    //                             relationshipId: param.data.relationshipId
    //                         }, props)
    //                     }
    //                 })

    //             }}>Delete Purchase Order</Link>
    //         </Menu.Item>}
    //         <Menu.Item key="4" onClick={() => {
    //                 updateUserStatus(props, param.data);
    //             }}>
    //                 <i className="fa fa-pencil" />
    //                  Update User Status
    //                 </Menu.Item>
    //     </Menu>
    // );
    // const permittedModules = getActionMenuPermissionData();
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.poNo' defaultMessage='' />;
    //         },
    //         colId: 'poNumber_1',
    //         field: "poNumber",
    //         hide: _isColumnToHide('poNumber'),
    //         suppressMovable: true,
    //         minWidth: 80,
    //         maxWidth: 400,
    //         rowDrag: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openPODetails(link.data)
    //         }}>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.supplier' defaultMessage='' />;
    //         },
    //         field: "supplierName",
    //         colId: "supplierName_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         hide: _isColumnToHide('supplierName'),
    //         //width: (props.txColumnSetting['supplierName'] || {}).width,
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             setTimeout(() => {
    //                 if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
    //                     params.eParentOfValue.style.height = 'inherit';
    //                     params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
    //                     params.node.data.maxHeight = params.reactContainer.scrollHeight;
    //                     params.node.setRowHeight(params.reactContainer.scrollHeight);
    //                     params.api && params.api.onRowHeightChanged();
    //                 }
    //             }, 200)
    //             return <div className="cursor-pointer" >
    //                 <div onClick={() => {
    //                     props.history.push('suppliers/details', {
    //                         supplierId: params.data.supplierId,
    //                         supplierName: params.data.supplierStoreName,
    //                         // supplier: data
    //                     })
    //                 }}>{params.value}</div>
    //             </div>
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='supplier.purchaseOrder.listing.header.amount' defaultMessage='' /></div>;
    //         },
    //         field: "totalPOAmount",
    //         colId: "totalPOAmount_1",
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         hide: _isColumnToHide('totalPOAmount'),
    //         //width: (props.txColumnSetting['totalPOAmount'] || {}).width,
    //         cellRendererFramework: (link) => {
    //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value} </span> : "-"
    //         },
    //         valueGetter: (link) => {
    //             // return link.data.totalPOAmount ? Number(link.data.totalPOAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-";
    //             return link.data.totalPOAmount ? fixedDecimalAndFormateNumber(Number(link.data.totalPOAmount)) : "-";
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.totalPOAmount' defaultMessage='' />;
    //         },
    //         field: "reference",
    //         colId: "reference_1",
    //         hide: _isColumnToHide('reference'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.project' defaultMessage='' />;
    //         },
    //         field: "project",
    //         colId: "project_1",
    //         hide: _isColumnToHide('project'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             setTimeout(() => {
    //                 if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
    //                     params.eParentOfValue.style.height = 'inherit';
    //                     params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
    //                     params.node.data.maxHeight = params.reactContainer.scrollHeight;
    //                     params.node.setRowHeight(params.reactContainer.scrollHeight);
    //                     params.api && params.api.onRowHeightChanged();
    //                 }
    //             }, 200)
    //             return <div>
    //                 {params.data.projectNumber ? params.data.projectNumber + ' (' + params.data.projectName + ')' : ''}
    //             </div>
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.rfqConversion' defaultMessage='' />;
    //         },
    //         field: "isRFQCconversion",
    //         colId: "isRFQCconversion_1",
    //         cellRendererFramework: (params) => {
    //             return params.node.data.isRFQCconversion ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;
    //         },
    //         hide: _isColumnToHide('isRFQCconversion'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.rfqNumber' defaultMessage='' />;
    //         },
    //         field: "rfqNumber",
    //         colId: "rfqNumber_1",
    //         hide: _isColumnToHide('rfqNumber'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openRFQDetails(link.data)
    //         }}>{link.value}</div>,
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.rfq.listing.header.prNumber' defaultMessage='' />;
    //         },
    //         field: "purchaseRequestNumber",
    //         colId: "purchaseRequestNumber_1",
    //         hide: _isColumnToHide('purchaseRequestNumber'),
    //         suppressMovable: true,
    //         minWidth: 140,
    //         maxWidth: 400,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openPRDetails(link.data)
    //         }}>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.expectedDeliveryDate' defaultMessage='' />;
    //         },
    //         field: "expectedDeliveryDate",
    //         colId: "expectedDeliveryDate_1",
    //         hide: _isColumnToHide('expectedDeliveryDate'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //              <Tooltip placement="topLeft" title={(link.data.expectedDeliveryDate && getMomentDateForUIReadOnly({date: link.data.expectedDeliveryDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {link.data.expectedDeliveryDate && getMomentDateForUIReadOnly({date: link.data.expectedDeliveryDate, format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.date' defaultMessage='' />;
    //         },
    //         field: "poDate",
    //         colId: "poDate_1",
    //         hide: _isColumnToHide('poDate'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //              <Tooltip placement="topLeft" title={(link.data.expectedDeliveryDate && getMomentDateForUIReadOnly({date: link.data.expectedDeliveryDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {getMomentDateForUIReadOnly({date: link.data.poDate, format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.paymentTerm' defaultMessage='' />;
    //         },
    //         field: "paymentTermName",
    //         colId: "paymentTermName_1",
    //         hide: _isColumnToHide('paymentTermName'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "userStatus",
    //         hide: _isColumnToHide('userStatus'),
    //         colId: "userStatus_1",
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                  {link.value? <span className="status-code" style={{background: link.data.userStatusColor}}>{link.value || ''}</span>: ''}
    //             </div>
    //         },
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.systemStatus' defaultMessage='' />;
    //         },
    //         cellRendererFramework: (params) => {
    //             return (params.value === 'Rejected' && params.data.rejectionReason) ?
    //                 <div>
    //                     <Tooltip placement="topLeft" title={params.data.rejectionReason}>
    //                         <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span>
    //                     </Tooltip>
    //                 </div> :
    //                 <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
    //         },
    //         field: "status",
    //         colId: "status_1",
    //         hide: _isColumnToHide('status'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     },
     
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.qtyInvoiced' defaultMessage='' />;
    //         },
    //         cellRendererFramework: function (param) {
    //             return getRecievedQuantity(param.data);
    //         },
    //         field: "totalQtyReceived",
    //         colId: "totalQtyReceived_1",
    //         hide: _isColumnToHide('totalQtyReceived'),
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellClass: 'progressbar-cell'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='supplier.purchaseOrder.listing.header.qtyRecieved' defaultMessage='' />;
    //         },
    //         cellRendererFramework: function (param) {
    //             return getInboundDeliveryQuantity(param.data);
    //         },
    //         field: "totalQtyOnId",
    //         colId: "totalQtyOnId_1",
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         cellClass: 'progressbar-cell'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return "Customer Inquiry #";
    //         },
    //         field: 'customerInquiryNumber',
    //         hide: _isColumnToHide('customerInquiryNumber'),
    //         colId: "customerInquiryNumber_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.customerInquiryNumber || '-'}
    //         </div>,
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return "Customer RFQ #";
    //         },
    //         field: 'customerRFQNumber',
    //         hide: _isColumnToHide('customerRFQNumber'),
    //         colId: "customerRFQNumber_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.customerRFQNumber || '-'}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return "Customer PO #";
    //         },
    //         field: 'customerPONumber',
    //         hide: _isColumnToHide('customerPONumber'),
    //         colId: "customerPONumber_1",
    //         resizable: true,
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.customerPONumber || '-'}
    //         </div>,
    //     },
        
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <div className="cursor-pointer">
    //     //             <Popover
    //     //                 content={<AddAndRemoveSupplierListingColumn {...props} />}
    //     //                 title={<div className="text-center">Add/Remove </div>}
    //     //                 placement="left"
    //     //                 trigger="click"
    //     //             >
    //     //                 <i className='fa fa-bars' />
    //     //             </Popover>
    //     //         </div>;
    //     //     },
    //     //     cellRendererFramework: (param) => {
    //     //         return <Dropdown overlay={actionMenu(param)} trigger={['click']}>
    //     //             <Button className="action-btn small">
    //     //                 <i className="fa fa-ellipsis-v" />
    //     //             </Button>
    //     //         </Dropdown>
    //     //     },
    //     //     field: "action",
    //     //     colId: "action_1",
    //     //     pinned: 'right',
    //     //     lockPosition: true,
    //     //     minWidth: 52,
    //     //     maxWidth: 52,
    //     //     suppressMovable: true,
    //     // }
    // ];

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const breadCrumbList = [
    //     {
    //         name: 'Dashboard',
    //         link: 'dashboard'
    //     },
    //     {
    //         name: 'Supplier',
    //         link: 'suppliers'
    //     }, {
    //         name: 'Purchase Order',
    //     }, {
    //         name: 'List'
    //     }
    // ];

    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     onDataRendered(params);
    // }

    // const onModelUpdated = (params) => {
    //     if ((purchaseOrderList[pageNumber] || []).length && !props.isRowHeightSet) {
    //         setTimeout(() => {
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //         //params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.PO_LISTING

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     }
    // }

    // const onDataRendered = (params) => {
    //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //     if (Object.keys(txColumnSetting).length) {
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.PO_LISTING

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.width = 52;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     } else {
    //         params.api.sizeColumnsToFit();
    //     }
    // }

    return (
      <Fragment>
        {/* <div className="invoice-header">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}

        {/* <Collapse activeKey={collapseActiveKey} onChange  = {()=>{
                    setCollapseActiveKey(collapseActiveKey ? '' : '1')
                }}>
                    <Panel header="Apply Filter" key="1"  style={{ width : '100%' }}>
                    <div className='qute-sect1'>
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '160px', marginRight: '5px', float:'left' }}
                            className='drop-fileds1'
                            items={props.suppliers[pageNumber] || []}
                            valueKeyName='supplierStoreName'
                            optionKeyName='supplierId'
                            value={props.selectedSupplierName}
                            onSearch={(searchedText) => {
                                props.fetchSuppliers({
                                    isCompact: true,
                                    searchedText: searchedText,
                                    pageNumber: 1, 
                                    hideSpinner: true,
                                    pageSize: 100,
                                    relationshipId: (props.companyInfo || {}).relationshipId
                                })
                            }}
                            placeholder={props.intl.formatMessage({
                                id: 'supplier.filter.name.placeholder', defaultMessage: ''
                            })}
                            onSelect={
                                (supplierId, option) => {
                                    updateState({
                                        selectedSupplierId: option.value,
                                        selectedSupplierName: option.name,
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    selectedSupplierId: undefined,
                                    selectedSupplierName: undefined,
                                })
                            }}
                        />
                        <TextBox 
                            // style={{ marginTop: '-0px', width: '140px', marginRight: '5px', float:'left'}}
                            className='drop-fileds'
                            placeholder='PO #'
                            type='text'
                            maxLength={20}
                            value={props.poNum}
                            onChange={(e) => {
                                props.updateState({ poNum: e.target.value });
                            }}
                        />
                        <RangePicker
                            // style={{ marginTop: '-0px', width: '300px', marginRight: '5px', float:'left' }}
                            className='drop-fileds2'
                            allowEmpty={true}
                            format={format}
                            placeholder={['PO start date', 'PO end date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        />
                        <div className='solpty' style={{float:'left', marginTop:'0px'}}>
                            <i className={props.companyInfo.currencyIcon}></i> 
                            <TextBox 
                                // style={{ marginTop: '-20px', width: '200px', marginRight: '0px'}}
                                className='certso'
                                placeholder={props.intl.formatMessage({
                                    id: 'supplier.filter.amount.placeholder', defaultMessage: ''
                                })}
                                type='Number'
                                // maxLength={12}
                                value={props.selectedAmount}
                                onChange={(e) => {
                                    props.updateState({ selectedAmount: e.target.value });
                                }}
                            />
                        </div>

                        <Select
                            // style={{ marginTop: '-0px', width: '60px', marginRight: '5px', marginLeft:'-9px', float:'left'}}
                            className='drop-fileds3'
                            options={[{ label: '<', value: '<' }, { label: '>', value: '>' }, { label: '=', value: '=' }]}
                            value={props.selectedAmountTerm}
                            placeholder= '='
                            defaultValue= '='
                            onSelect={(value) => {
                                updateState({ selectedAmountTerm: value });
                            }}
                        />
                        <div className='cler'></div>
                        </div>
                        <div className='cler'></div>
                        
                        <div className='qute-sect1'>
                        <Dropdown
                            items={props.userStatus || []}
                            valueKeyName='statusName'
                            optionKeyName='poStatusId'
                            statusColorKey='statusColor'
                            // style={{ marginTop: '-20px', width: '150px', marginRight: '5px' ,float:'left' }}
                            className='drop-fileds1'
                            value={props.selectedUserStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.status' })}
                            onSelect={(selectedValue, option) => {
                                updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                            }}
                        />
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' ,float:'left' }}
                            className='drop-fileds1'
                            items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partially.fulfilled' }, { name: 'Fullfilled', value: 'fulfilled' }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                            onSelect={(status, option) => {
                                updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                            }}
                        />

                        <TextBox 
                            // style={{ marginTop: '-0px', width: '140px', marginRight: '5px',float:'left'}}
                            className='drop-fileds1'
                            placeholder={props.intl.formatMessage({
                                id: 'supplier.filter.refNum.placeholder', defaultMessage: ''
                            })}
                            type='text'
                            maxLength={20}
                            value={props.selectedRefNum}
                            onChange={(e) => {
                                props.updateState({ selectedRefNum: e.target.value });
                            }}
                        />

                        <Dropdown
                            // style={{ marginTop: '-20px', width: '160px', marginRight: '5px',float:'left' }}
                            className='drop-fileds1'
                            items={props.projectList}
                            valueKeyName='projectName'
                            optionKeyName='projectMasterId'
                            value={props.selectedProjectName}
                            placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                            }}
                        />
                        <div className='cler'></div>
</div>
<div className='qute-sect1'>
                        <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            if(!props.selectedSupplierName && !props.poNum && !props.startDate && !props.endDate && !(props.selectedAmount && props.selectedAmountTerm) && !props.selectedUserStatus && !props.selectedStatus && !props.selectedRefNum && !props.selectedProjectMasterId ){
                                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                            }
                            const payload = {
                                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                filters: {
                                    supplierId: props.selectedSupplierId || 0,
                                    poNumber: props.poNum || null,
                                    startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                                    endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                                    amount: props.selectedAmount || 0,
                                    amountSymbol: props.selectedAmount ? props.selectedAmountTerm : null,
                                    userStatus: props.selectedUserStatusName,
                                    status: props.selectedStatus,
                                    reference: props.selectedRefNum,
                                    projectMasterId: props.selectedProjectMasterId,
                                }
                            };
                            props.fetchPurchaseOrdersByFilter(payload);
                            props.getPurchaseOrdersCountByFilter(payload);
                        }}>
                            {<FilterOutlined />} Apply Filter
                        </button>
                        <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            props.updateState({
                                selectedSupplierName: null, selectedSupplierId: null,
                                poNum: null,
                                startDate: null, endDate: null,
                                selectedAmount: 0, selectedAmountTerm:null,
                                selectedUserStatus: null, selectedUserStatusName: null,
                                selectedStatus: null, selectedStatusName: null,
                                selectedRefNum: null,
                                selectedProjectMasterId: null, selectedProjectName: null
                            });
                            const payload = {
                                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                filters: {}
                            };
                            props.fetchPurchaseOrders(payload);
                            props.getPurchaseOrdersCount(payload);
                            }}>
                                {<UndoOutlined />} Clear Filter
                        </button>
                        </div>
                    </Panel>
                </Collapse> */}

          <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} active>

            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="purchase.purchaseOrder.table.heading"
                  defaultMessage=""
                />
              </div>
              
                  <div className="vertical-sep" />
                  <div>{props.purchaseOrderCount}</div>
               
            </div>
            <div className="right-actions">
              <button
                className="action-btn"
                onClick={() => {
                  updateState({
                    purchaseOrderFilterDrawerVisible: true,
                  });
                }}
              >
                <FilterOutlined /> &nbsp;
                <FormattedMessage id="filter.text" defaultMessage="" />
              </button>
                <Popover
                  content={<AddAndRemoveSupplierListingColumn {...props} />}
                  title={
                    <div className="text-center">
                      <FormattedMessage
                        id="common.addRemove"
                        defaultMessage=""
                      />{" "}
                    </div>
                  }
                  placement="leftTop"
                  trigger="click"
                  arrow="right"
                  // visible = {props.showColPop}
                >
                              <button onClick={()=>{props.updateState({showColPop: !props.showColPop})}}>
                                <InsertRowRightOutlined className='mr5'/> Columns
                            </button>

                </Popover>
            </div>
            </Skeleton>
          </div>
          {/* <div className='agGridWrapper'>
                    {props.txColumns.length ? <div className="ag-theme-balham" style={agGridStyle} >
                        <div className="cursor-pointer">
                                <span className="top-button">
                                    <Popover
                                        content={<AddAndRemoveSupplierListingColumn {...props} />}
                                        title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                                        placement="left"
                                        trigger="click"
                                    >
                                        <i className='fa fa-bars' />
                                    </Popover>
                                </span>
                                <div className="clearfix"></div>
                            </div>
                             <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={purchaseOrderList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                animateRows={true}
                                //getRowHeight={getRowHeight}
                                onModelUpdated={onModelUpdated}
                                onColumnResized={onColumnResized}
                                onDragStopped={() => {
                                    onColumnMoved({}, true);
                                }}
                                //onColumnMoved={onColumnMoved}
                            >
                            </AgGridReact>
                        </div> : null}
                    </div> */}

          <div className="table-container">
         <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  {!_isColumnToHide("poNumber") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.poNo"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("supplierName") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.supplier"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("totalPOAmount") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.amount"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("reference") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.totalPOAmount"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {/* {!_isColumnToHide('isRFQCconversion') ? <th><FormattedMessage id='supplier.purchaseOrder.listing.header.rfqConversion' defaultMessage='' /></th>: ''} */}
                  {!_isColumnToHide("rfqNumber") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.rfqNumber"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("purchaseRequestNumber") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.rfq.listing.header.prNumber"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("expectedDeliveryDate") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.expectedDeliveryDate"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("poDate") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.date"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("paymentTermName") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.paymentTerm"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("userStatus") ? (
                    <th>
                      <FormattedMessage id="common.status" defaultMessage="" />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("status") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.systemStatus"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("totalQtyReceived") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.qtyInvoiced"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  <th>
                    <FormattedMessage
                      id="supplier.purchaseOrder.listing.header.qtyRecieved"
                      defaultMessage=""
                    />
                  </th>
                  {!_isColumnToHide("customerInquiryNumber") ? (
                    <th>
                      <FormattedMessage
                        id="Customer Inquiry #"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("customerRFQNumber") ? (
                    <th>
                      <FormattedMessage id="Customer RFQ #" defaultMessage="" />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("customerPONumber") ? (
                    <th>
                      <FormattedMessage id="Customer PO #" defaultMessage="" />
                    </th>
                  ) : (
                    ""
                  )}
                  {!_isColumnToHide("project") ? (
                    <th>
                      <FormattedMessage id="common.project" defaultMessage="" />
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {purchaseOrderList[pageNumber] && (purchaseOrderList[pageNumber] || []).length ?
                  purchaseOrderList[pageNumber].map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td>{(pageNumber-1)*pageSize+(index+1)}</td>
                        {!_isColumnToHide("poNumber") ? (
                          <td className='one-line-view'>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                openPODetails(rowData);
                              }}
                            >
                              {rowData.poNumber}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("supplierName") ? (
                          <td className='one-line-view'>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                props.pushModalToStack({  
                                 
                                  modalBody: <SupplierDetails {...props}
                                  supplierId= {rowData.supplierId}
                                  supplierName={rowData.supplierName}
                              />,
                              width: '100%',
                              hideTitle: true,
                              hideFooter: true,
                              wrapClassName: "new-transaction-wrapper",
                              })
                             }}
                              // onClick={() => {
                              //   props.history.push("suppliers/details", {
                              //     supplierId: rowData.supplierId,
                              //     supplierName: rowData.supplierName,
                              //     // supplier: data
                              //   });
                              // }}
                            >
                              {rowData.supplierName}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("totalPOAmount") ? (
                          <td className='one-line-view'>
                            {rowData.totalPOAmount &&
                            rowData.totalPOAmount !== "-" ? (
                              <span>
                                {" "}
                                {rowData.foreignCurrencyIcon ? (
                                  <i
                                    className={rowData.foreignCurrencyIcon}
                                  ></i>
                                ) : rowData.foreignCurrency ? (
                                  <span>{rowData.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {rowData.totalPOAmount}{" "}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("reference") ? (
                          <td>{rowData.reference ? rowData.reference : <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("rfqNumber") ? (
                          <td>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                if(rowData.rfqNumber !== null){

                                  openRFQDetails(rowData);
                                }
                              }}
                            >
                              {rowData.rfqNumber ? rowData.rfqNumber : <div className="empty-data"></div>}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("purchaseRequestNumber") ? (
                          <td>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                openPRDetails(rowData);
                              }}
                            >
                              {rowData.purchaseRequestNumber ? rowData.purchaseRequestNumber : <div className="empty-data"></div>}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("expectedDeliveryDate") ? (
                          <td>
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={
                                  rowData.expectedDeliveryDate &&
                                  getMomentDateForUIReadOnly({
                                    date: rowData.expectedDeliveryDate,
                                    format:
                                      CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                  })
                                }
                              >
                                {rowData.expectedDeliveryDate &&
                                  getMomentDateForUIReadOnly({
                                    date: rowData.expectedDeliveryDate,
                                    format,
                                  })}
                              </Tooltip>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("poDate") ? (
                          <td>
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={
                                  rowData.expectedDeliveryDate &&
                                  getMomentDateForUIReadOnly({
                                    date: rowData.expectedDeliveryDate,
                                    format:
                                      CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                  })
                                }
                              >
                                {getMomentDateForUIReadOnly({
                                  date: rowData.poDate,
                                  format,
                                })}
                              </Tooltip>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("paymentTermName") ? (
                          <td>{rowData.paymentTermName}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("userStatus") ? (
                          <td>
                            <div>
                              {rowData.userStatus ? (
                                <span
                                  className="status-code"
                                  style={{
                                    background: rowData.userStatusColor,
                                  }}
                                >
                                  {rowData.userStatus || ""}
                                </span>
                              ) : (
                                <div className="empty-data"></div>
                              )}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("status") ? (
                          <td className='one-line-view'>
                            {rowData.status === "Rejected" &&
                            rowData.rejectionReason ? (
                              <div>
                                <Tooltip
                                  placement="topLeft"
                                  title={rowData.rejectionReason}
                                >
                                  <span
                                    className={[
                                      "status-code",
                                      rowData.status.toLowerCase(),
                                    ].join(" ")}
                                  >
                                    <FormattedMessage
                                      id={rowData.status}
                                      defaultMessage={rowData.status}
                                    />
                                  </span>
                                </Tooltip>
                              </div>
                            ) : (
                              <div>
                                <span
                                  className={[
                                    "status-code",
                                    rowData.status.toLowerCase(),
                                  ].join(" ")}
                                >
                                  <FormattedMessage
                                    id={rowData.status}
                                    defaultMessage={rowData.status}
                                  />
                                </span>
                              </div>
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("totalQtyReceived") ? (
                          <td>{getRecievedQuantity(rowData)}</td>
                        ) : (
                          ""
                        )}
                        <td>{getInboundDeliveryQuantity(rowData)}</td>
                        {!_isColumnToHide("customerInquiryNumber") ? (
                          <td>
                            <div>{rowData.customerInquiryNumber || <div className="empty-data"></div>}</div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("customerRFQNumber") ? (
                          <td>
                            <div>{rowData.customerRFQNumber || <div className="empty-data"></div>}</div>
                          </td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("customerPONumber") ? (
                          <td>
                            <div>{rowData.customerPONumber || <div className="empty-data"></div>}</div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("project") ? (
                          <td>
                            <div>
                              {rowData.projectName || <div className="empty-data"></div>}                               
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })
                :
                <tr key="empty-data">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
              </tbody>
            </table>
        </Skeleton>
          </div>
        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={purchaseOrderCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
          </Skeleton>
        </div>
      </Fragment>
    );
};

export default injectIntl(PurchaseOrderListingComp);
