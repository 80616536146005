import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import MembersComp from "../../../components/membership/members";
//import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { fetchOrgMembershipList, getOrgMembershipCount, deleteOrgMembership } from "./action";
import { showModal, hideModal, pushModalToStack } from './../../../actions/commonActions';
import OrgMembershipComp from "../../../components/membership/orgMembership";

class OrgMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    };
    this.props.fetchOrgMembershipList(payload);
    this.props.getOrgMembershipCount(payload);
  }

  render() {
    return (
      <OrgMembershipComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></OrgMembershipComp>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    orgMembershipList: state.membershipReducer.orgMembershipList,
    orgMembershipCount: state.membershipReducer.orgMembershipCount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOrgMembershipList,
      getOrgMembershipCount,
      deleteOrgMembership,
      showModal,
      hideModal,
      pushModalToStack,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OrgMembership);
