import { INVENTORY_VALUATION_ACTION_LIST } from '../static/constants';
const initialState = {
  allInventoryValuationList: {},
  monthlyInventoryValuationList: {},
  dailyInventoryValuationList: {},
}

const InventoryValuationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_VALUATION_ACTION_LIST.ALL_INVENTORY_VALUATION_LIST:
      return { ...state, allInventoryValuationList: action.data };
    case INVENTORY_VALUATION_ACTION_LIST.DAILY_INVENTORY_VALUATION_LIST:
      return { ...state, dailyInventoryValuationList: action.data };
    case INVENTORY_VALUATION_ACTION_LIST.MONTHLY_INVENTORY_VALUATION_LIST:
      return { ...state, monthlyInventoryValuationList: action.data };
    default:
      return state;
  }
};

export default InventoryValuationReducer;