import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {  showToasterMessage } from '../../../../../utils'
import { DropdownRef } from '../../../../general/Dropdown';
import { CustomButton } from '../../../../general/CustomButton';
import * as find from 'lodash.find';
import { TRANSACTION_TYPES } from '../../../../../static/constants';

class DeligateApproverComponent extends Component {

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    let currentApprover = null;
    if(this.props.data && this.props.data.approverEmpId && this.props.allEmployee && this.props.allEmployee.length){
       currentApprover = find(this.props.allEmployee, { relationshipEmployeeId: this.props.data.approverEmpId });
    }

    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content employee-modal-control">
            {currentApprover &&
              <Form.Item label={(<span><FormattedMessage id='modal.common.deligateApporver.currentApprover' defaultMessage='' /></span>)} colon={false}>
                {currentApprover.fullName || ''}
              </Form.Item>
            }
          <Form.Item label={(<span><FormattedMessage id='modal.common.deligateApporver.deligateTo' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('deligateTo', {
                rules: [
                  { required: true,
                    message: 'Deligate To is required.',
                    initialValue: this.props.deligateTo || ''
                  }
                ],
              })(<DropdownRef
                items={this.props.allEmployee || []}
                valueKeyName='fullName'
                optionKeyName='relationshipEmployeeId'
                hierarchyNameKey='hierarchyName'
                hierarchyLevelKey='hierarchyLevel'
                hierarchyCodeKey='hierarchyCode'
                onSelect={(selectedValue, option) => {
                  this.props.form.setFieldsValue({ 'deligateTo': option.name });
                  let deligateTo = find(this.props.allEmployee, { relationshipEmployeeId: selectedValue });
                  this.setState({deligateTo: deligateTo});
                }}
              />)}
            </Form.Item>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal();}}
              />
              <CustomButton
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.deligateTo.relationshipEmployeeId === this.props.data.approverEmpId) {
      return showToasterMessage({
        messageType: 'error', description: 'Delegate approver can not be the current approver'
      });
    }
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...this.props.data,
          approverEmpId: this.state.deligateTo.relationshipEmployeeId,
          createdByEmpId: this.props.userInfo && this.props.userInfo.relationshipEmployeeId,
          //dateCreated: getCurrentDateForBackend(new Date()),
        }
        if (this.props.data.txType === TRANSACTION_TYPES.PRICE_CHANGE) {
          payload.priceUpdateApproverEmpId = this.state.deligateTo.relationshipEmployeeId;
          payload.priceUpdateRequestedByEmpId = this.props.userInfo && this.props.userInfo.relationshipEmployeeId;
        } else if (this.props.data.txType === TRANSACTION_TYPES.STOCK_CHANGE) {
          payload.stockUpdateApproverEmpId = this.state.deligateTo.relationshipEmployeeId;
          payload.stockUpdateRequestedByEmpId = this.props.userInfo && this.props.userInfo.relationshipEmployeeId;
        }
        const modalData = {
          modalBody: 'Are you sure you want to deligate?',
          handleSubmit: () => {
             this.props.delegateApprover(payload);
             this.props.handleSubmit && this.props.handleSubmit();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Deligate_approver _component' })(injectIntl(DeligateApproverComponent));
