import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, ECOMMERCE_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchCategory = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/getInventoryItemCategory?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.ALL_CATEGORY_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}


export const getCategoryCount = (payload, history) => {
  // const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/countAllInventoryCategory?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.ALL_CATEGORY_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.ALL_CATEGORY_COUNT;
        }
      })
  }
}

export const fetchPublishedCategory = (payload, history) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/getInventoryCategory?relationshipId=${payload.relationshipId}&isPublished=1&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}${searchedTextQuery}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.PUBLISHED_CATEGORY_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.PUBLISHED_CATEGORY_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const getPublishedCategoryCount = (payload, history) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/countInventoryCategory?relationshipId=${payload.relationshipId}&isPublished=1&startDate=2019-04-01%2000:00:00.0&endDate=2020-03-31%2023:59:59.0${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.PUBLISHED_CATEGORY_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.PUBLISHED_CATEGORY_COUNT;
        }
      })
  }
}

export const resetPublishedCategoryPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.PUBLISHED_CATEGORY_LIST_PAGE_RESET });
  }
}

export const fetchUnpublishedCategory = (payload, history) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/getUnpublishedCategory?relationshipId=${payload.relationshipId}&isPublished=0&warehouseMasterId=0&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}${searchedTextQuery}`
      )
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: ECOMMERCE_ACTION_LIST.UNPUBLISHED_CATEGORY_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = ECOMMERCE_ACTION_LIST.UNPUBLISHED_CATEGORY_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      })
  }
}

export const getUnpublishedCategoryCount = (payload, history) => {
  const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemCategory/countInventoryCategory?relationshipId=${payload.relationshipId}&isPublished=0&startDate=2019-04-01%2000:00:00.0&endDate=2020-03-31%2023:59:59.0${searchedTextQuery}`)
      .then(res => {
        dispatch({ type: ECOMMERCE_ACTION_LIST.UNPUBLISHED_CATEGORY_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = ECOMMERCE_ACTION_LIST.UNPUBLISHED_CATEGORY_COUNT;
        }
      })
  }
}

export const resetUnpublishedCategoryPage = () => {
  return dispatch => {
    dispatch({ type: ECOMMERCE_ACTION_LIST.UNPUBLISHED_CATEGORY_LIST_PAGE_RESET });
  }
}


export const updatePublisedCategory= (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/eCommerce/publishCategory`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Save successfully.',
            messageType: 'success'
          });
          props.fetchCategory && props.fetchCategory(props);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
}