import React from "react";
import LeadSourceList from "../../../components/leads/LeadSourceList";
import LeadSourceDrawer from "../../drawer/leads/leadSourceList";
import { getAllLeadSources } from "../../modal/modalBody/leads/createLead/action";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createLeadSource } from "../../drawer/leads/leadSourceList/action";
import { deleteCrmLeadSource } from "./action";
import { showModal, hideModal } from "../../../actions/commonActions";

class LeadSourceListComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 0,
      pageSize: 100,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100,
    }
    this.props.getAllLeadSources(payload);
  }

  render() {
    return (
      <>
        <LeadSourceList
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />

       {this.state.leadSourceListDrawer && <LeadSourceDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      crmLeadSourceList: state.crm.crmLeadSourceList
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllLeadSources,
  createLeadSource,
  deleteCrmLeadSource,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadSourceListComp);
