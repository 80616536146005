import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { getMomentDateForUIReadOnly } from "../../utils";
import { CONSTANTS } from '../../static/constants';
import PageBreadcrumb from "../PageBreadcrumb";
import KioskLogo from "../../assets/images/kiosk-logo.png"

const Kiosk = (props) => {
    const { kioskList } = props;

    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.kiosk' defaultMessage='' />
        }
    ];

    return (
        <Fragment>
            <div className="pb10">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="view-container sales-order-details mt0">
                <div className='team-and-free'>
                    <div className="kiosk-fonts">
                       
                    {kioskList && kioskList.length > 0 ?
                        <div>
                            <h2>You have {kioskList.length} Kiosks installed</h2>
                            {
                                kioskList.map((kiosk, i) => {
                                    
                                    return( <ul><div key={i}>
                                      <li><span><img src={KioskLogo} alt=""/> </span>  
                                      {/* {i + 1} -  */}
                                      
                                      Kiosk Model : {kiosk.kioskModel}, size : {kiosk.screenSize} was installed on {getMomentDateForUIReadOnly({ date: kiosk.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT })} at this loaction {kiosk.kioskLocation}<br />
                                        Contact Person : {kiosk.contactPerson}<br />
                                        Warranty : 3 Years<br />
                                        Warranty Country : USA<br />
                                        Kiosk Support : (+1) 800-549-8078
                                        </li>
                                    </div> </ul>)
                                })
                            }
                        </div>
                        : ''}
                        </div>
                </div>
            </div>
        </Fragment>
    );
};

export default injectIntl(Kiosk);
