import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST } from '../../../static/constants'
import { exportToSheet, showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchClientOnboardingData = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientOnboarding/getAll`)
      .then(res => {
        const { data } = res
        dispatch({ type: SETTING_ACTION_LIST.CLIENT_ONBOARDING_DATA, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.CLIENT_ONBOARDING_DATA;
        }
      })
  }
}

export const downloadClientOnboardingData = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientOnboarding/getAllBySourceSite?sourceSite=${payload.urlSourceSite}`)
      .then(res => {
        const { data } = res
        if((data || []).length){

          exportToSheet(data, payload.fileName || 'client_Onboard_Data', 'csv', payload.fileHeader || {});
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        }
        else{
          showToasterMessage({messageType: 'error', description: 'No data recieved'});
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        }
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.CLIENT_ONBOARDING_DATA;
        }
      })
  }
}

