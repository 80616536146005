import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetPasswordMail } from './action';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';
// import { sendResetMail } from './action';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailClass: '',
      emailInvalidClass: '',
    }
  }

  render() {
    return <ResetPasswordForm {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
  }

}

const mapDispatchToProps = (dispatch) => bindActionCreators({resetPasswordMail  }, dispatch);

export default connect(null, mapDispatchToProps)(ResetPassword);
