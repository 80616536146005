import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchRegistrationFormSetting = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      dispatch({ type: SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/getFormSetting?relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING;
          }
        })
    }
}