import React, { Fragment, Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Checkbox, Tooltip } from "antd";
import { validateEmail, getCurrentDateForBackend } from "../../../../../utils";
import { DropdownRef } from "../../../../general/Dropdown";
import { MODAL_TYPE, CONSTANTS, ICONS } from "../../../../../static/constants";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import * as find from "lodash.find";

class ContactComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleName: (((props.data || {}).formData || {}))
        .salutationName,
      salutationId: (((props.data || {}).formData || {}))
        .salutationId,
      firstName: (((props.data || {}).formData || {}))
        .firstName,
      contactId: (((props.data || {}).formData || {}))
        .contactId,
      middleName: (((props.data || {}).formData || {}))
        .middleName,
      lastName: (((props.data || {}).formData || {}))
        .lastName,
      preferCallName: (((props.data || {}).formData || {}))
        .preferCallName,
      workPhone: (((props.data || {}).formData || {}))
        .workPhone,
      cellPhone: (((props.data || {}).formData || {}))
        .cellPhone,
      faxNumber: (((props.data || {}).formData || {}))
        .faxNumber,
      email: (((props.data || {}).formData || {}))
        .emailAddress,
      jobTitle: (((props.data || {}).formData || {}))
        .jobTitle,
      department: (((props.data || {}).formData || {}))
        .department,
      isPrimaryContact: (((props.data || {}).formData || {}))
        .isPrimaryContact
        ? true
        : false,
      dateCreated: (((props.data || {}).formData || {}))
        .dateCreated,
      contactType: (((props.data || {}).formData || {}))
        .contactType,
      isDGContact: ((props.data || {}).formData || {}).isDGContact || false,
      countryCallingCode: (props.companyInfo || {}).countryCallingCode
        ? "+" + (props.companyInfo || {}).countryCallingCode
        : "",
      isTeacher: ((props.data || {}).formData || {}).isTeacher,
    };

  }

  addItemConfirmationModal = (props, text, modalType) => {
    const { companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        addFunc = props.addSalutation;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.popModalFromStack();
      },
    };
    props.pushModalToStack(modalData);
  };

  getModalTitle = (modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        title = (
          <FormattedMessage id="addItem.text.salutation" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    // const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            props.popModalFromStack();
            props.pushModalToStack(data);
          }
        },
      };
      props.pushModalToStack(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        deleteFunc = props.deleteSalutation;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    props.pushModalToStack(modalData);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { intl } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    return (
      <Fragment>
        <Form
          labelAlign="left"
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          hideRequiredMark={true}
        >
          <div className="modal-content employee-modal-control contflt">
            {!this.state.isTeacher ? (
              <Form.Item
                label={
                  <><span>
                    <FormattedMessage id="contact.primary" defaultMessage="" />
                  </span>
                  <Tooltip
                    placement="bottom"
                    title={<FormattedMessage
                      id="contact.primary.message"
                      defaultMessage="" />}
                  >
                      {/* <span className="tooltip-title"> */}



                      <i className={`${ICONS.HELP} cursor-pointer ml5`} />

                    </Tooltip></>
                  
                }
                colon={false}
              >
                {getFieldDecorator("isDefault", {
                  initialValue: (this.state || {}).isPrimaryContact || 0,
                })(
                  <Checkbox
                    checked={(this.state || {}).isPrimaryContact ? true : false}
                    disabled={
                      (
                        ((this.props.data || {}).formData || {}).contactData ||
                        {}
                      ).isPrimaryContact
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      this.setState({
                        isPrimaryContact: e.target.checked ? 1 : 0,
                      });
                    }}
                    className="login-form-checkbox"
                  ></Checkbox>
                )}
              </Form.Item>
            ) : (
              ""
            )}
            {/* <Form.Item label={<FormattedMessage id='contact.title' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('titleName', {
                initialValue: this.state.titleName || ''
              })
                (<span />)}
            </Form.Item> */}
            <Form.Item
              label={<FormattedMessage id="contact.title" defaultMessage="" />}
              colon={false}
            >
              {getFieldDecorator("titleName", {
                initialValue: this.state.titleName || "",
              })(
                <DropdownRef
                  items={this.props.salutations || []}
                  optionKeyName="salutationId"
                  valueKeyName="titleName"
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.SALUTATION,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.SALUTATION
                    );
                  }}
                  onSelect={(selectedValue, option) => {
                    this.setState({
                      titleName: option.name,
                    });
                    this.props.form.setFieldsValue({
                      salutationId: selectedValue,
                    });
                    this.props.form.setFieldsValue({ titleName: option.name });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item
              label={
                <span>
                  <FormattedMessage id="contact.firstName" defaultMessage="" />
                  <span className="required">*</span>{" "}
                </span>
              }
              colon={false}
            >
              {/* <Form.Item label={(<span><FormattedMessage id='contact.firstName' defaultMessage='' /></span>)} colon={false}> */}
              {getFieldDecorator("firstName", {
                initialValue: this.state.firstName || "",
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="contact.firstName.message" />
                    ),
                  },
                ],
              })(
                <TextBox
                  maxLength={25}
                  placeholder={this.props.intl.formatMessage({
                    id: "contact.firstName.placeholder",
                    defaultMessage: "",
                  })}
                />
              )}
            </Form.Item>
            {/* <Form.Item label={<FormattedMessage id='contact.middleName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('middleName', {
                initialValue: this.state.middleName || ''
              })
                (<TextBox maxLength={25} placeholder={this.props.intl.formatMessage({ id: 'contact.middleName.placeholder', defaultMessage: '' } )} />)}
            </Form.Item> */}
            <Form.Item
              label={
                <FormattedMessage id="contact.lastName" defaultMessage="" />
              }
              colon={false}
            >
              {getFieldDecorator("lastName", {
                initialValue: this.state.lastName || "",
              })(
                <TextBox
                  maxLength={25}
                  placeholder={this.props.intl.formatMessage({
                    id: "contact.lastName.placeholder",
                    defaultMessage: "",
                  })}
                />
              )}
            </Form.Item>
            {/* <Form.Item label={<FormattedMessage id='contact.callName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('preferCallName', {
                initialValue: this.props.preferCallName || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'contact.callName.placeholder', defaultMessage: '' } )} />)}
            </Form.Item> */}

            {/* <Form.Item
              label={
                <FormattedMessage
                  id="countryCallingCode.text"
                  defaultMessage=""
                />
              }
              colon={false}
            >
              {getFieldDecorator("countryCallingCode", {
                initialValue: this.state.countryCallingCode || "",
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="countryCallingCode.validationMessage" />
                    ),
                  },
                ],
              })(
                <DropdownRef
                  items={
                    (this.props.countries || []).filter((el) => {
                      return el.countryCallingCode != null;
                    }) || []
                  }
                  optionKeyName="countryId"
                  valueKeyName="countryCallingCode"
                  valueKeyName2="countryName"
                  className="currency-tags"
                  onSelect={(selectedValue, option) => {
                    const selectedCountry = find(this.props.countries, {
                      countryId: selectedValue,
                    });
                    this.setState({
                      countryCallingCode: selectedCountry.countryCallingCode,
                    });
                    this.props.form.setFieldsValue({
                      countryId: selectedValue,
                    });
                    this.props.form.setFieldsValue({
                      countryCallingCode: selectedCountry.countryCallingCode,
                    });
                  }}
                />
              )}
            </Form.Item> */}
            <Form.Item
              label={
                <span>
                  <FormattedMessage id="contact.mobile" defaultMessage="" />
                  {/* <span className="input-currency-control ltiy">
                    {" "}
                    <span
                      className="currency-tag"
                      style={{
                        left: "119px",
                        top: "-10px",
                        height: "33px",
                        lineHeight: "30px",
                      }}
                    >
                      {this.state.countryCallingCode
                        ? this.state.countryCallingCode
                        : " "}
                    </span>
                  </span> */}
                </span>
              }
              
              
              colon={false}
            >
               <div style={{ display: "flex" }}>
              {getFieldDecorator("countryCallingCode", {
                  initialValue: this.props.formData?.countryCallingCode || this.state.countryCallingCode || "",
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="countryCallingCode.validationMessage" />
                      ),
                    },
                  ],
                })(
                  <DropdownRef
                  style={{ width: "21%" }}
                    items={
                      (this.props.countries || []).filter((el) => {
                        return el.countryCallingCode != null;
                      }) || []
                    }
                    optionKeyName="countryId"
                    valueKeyName="countryCallingCode"
                    // valueKeyName2="countryName"
                    className="currency-tags"
                    onSelect={(selectedValue, option) => {
                      const selectedCountry = find(this.props.countries, {
                        countryId: selectedValue,
                      });
                      this.props.updateState({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                      this.props.form.setFieldsValue({
                        countryId: selectedValue,
                      });
                      this.props.form.setFieldsValue({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                    }}
                  />
                )}
              {getFieldDecorator("cellPhone", {
                initialValue: this.state.cellPhone || "",
                rules: [
                  {
                    pattern: /^[0-9\b]+$/,
                    message: "Please enter number only",
                  },
                ],
              })(
                <TextBox
                  maxLength={10}
                  // className="nert"
                  className="textbox-pd"
                  placeholder={this.props.intl.formatMessage({
                    id: "contact.mobile.placeholder",
                    defaultMessage: "",
                  })}
                />
              )}
              </div>
            </Form.Item>

            <Form.Item
              label={
                <span>
                  {" "}
                  <FormattedMessage id="contact.workPhone" defaultMessage="" />
                  {/* <span className="input-currency-control ltiy">
                    {" "}
                    <span
                      className="currency-tag"
                      style={{
                        left: "91px",
                        top: "-10px",
                        height: "33px",
                        lineHeight: "30px",
                      }}
                    >
                      {this.state.countryCallingCode
                        ? this.state.countryCallingCode
                        : " "}
                    </span>{" "}
                  </span>{" "} */}
                </span>
              }
              colon={false}
            >
               <div style={{ display: "flex" }}>

               {getFieldDecorator("countryCallingCode", {
                  initialValue: this.props.formData?.countryCallingCode || this.state.countryCallingCode || "",
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="countryCallingCode.validationMessage" />
                      ),
                    },
                  ],
                })(
                  <DropdownRef
                  style={{ width: "21%" }}
                    items={
                      (this.props.countries || []).filter((el) => {
                        return el.countryCallingCode != null;
                      }) || []
                    }
                    optionKeyName="countryId"
                    valueKeyName="countryCallingCode"
                    // valueKeyName2="countryName"
                    className="currency-tags"
                    onSelect={(selectedValue, option) => {
                      const selectedCountry = find(this.props.countries, {
                        countryId: selectedValue,
                      });
                      this.props.updateState({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                      this.props.form.setFieldsValue({
                        countryId: selectedValue,
                      });
                      this.props.form.setFieldsValue({
                        countryCallingCode:
                          selectedCountry.countryCallingCode,
                      });
                    }}
                  />
                )}
              {getFieldDecorator("workPhone", {
                initialValue: this.state.workPhone || "",
                rules: [
                  {
                    pattern: /^[0-9\b]+$/,
                    message: "Please enter number only",
                  },
                ],
              })(
                <TextBox
                  maxLength={10}
                  // className="nert"
                  className="textbox-pd"
                  placeholder={this.props.intl.formatMessage({
                    id: "contact.workPhone",
                    defaultMessage: "",
                  })}
                />
              )}
              </div>
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id="contact.faxNumber" defaultMessage="" />
              }
              colon={false}
            >
              {getFieldDecorator("faxNumber", {
                initialValue: this.state.faxNumber || "",
              })(
                <TextBox
                  maxLength={15}
                  placeholder={this.props.intl.formatMessage({
                    id: "contact.faxNumber",
                    defaultMessage: "",
                  })}
                />
              )}
            </Form.Item>
            <Form.Item
              label={
                <span>
                  <FormattedMessage id="contact.email" defaultMessage="" />
                  <span className="required">*</span>{" "}
                </span>
              }
              colon={false}
            >
              {getFieldDecorator("emailAddress", {
                initialValue: this.state.email || "",
              })(
                <TextBox
                  maxLength={50}
                  countStyle={{top:"8px"}}
                  // className={(this.state || {}).invalidEmailClass} 
                  className={((this.state || {}).invalidEmailClass || '') + ' textbox-pd'}

                  placeholder={this.props.intl.formatMessage({
                    id: "contact.email",
                    defaultMessage: "",
                  })}
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                      invalidEmailClass: validateEmail(e.target.value)
                        ? ""
                        : "input-text-error",
                    });
                  }}
                />
              )}
              <ErrorMsg
                validator={() => {
                  return (
                    !(this.state || {}).submittedOnce ||
                    !!(this.state || {}).email
                  );
                }}
                message={intl.formatMessage({
                  id: "signup.validation.email",
                  defaultMessage: "",
                })}
              />
            </Form.Item>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className="customModal-button-group">
              <CustomButton
                intlId={"modal.contact.button.cancel"}
                defaultMessage={""}
                type={"default"}
                key={1}
                onClick={() => {
                  this.props.popModalFromStack();
                  this.props.hideModal();
                }}
              />
              <CustomButton
                intlId={"confirmation.submit"}
                defaultMessage={""}
                htmlType="submit"
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment>
    );
  }

  handleSubmit = (e) => {
   
    e.preventDefault();
    const { formData } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({
        submittedOnce: true,
        invalidEmailClass: validateEmail(
          values.emailAddress || this.state.emailAddress
        )
          ? ""
          : "input-text-error",
      });
      if (!err && validateEmail(values.emailAddress)) {
        const payload = {
          salutationName: values.titleName,
          salutationId: values.salutationId,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          preferCallName: values.preferCallName,
          workPhone: values.workPhone ? values.workPhone : null,
          contactId: this.state.contactId,
          cellPhone: values.cellPhone ? values.cellPhone : null,
          faxNumber: values.faxNumber,
          emailAddress: values.emailAddress,
          jobTitle: values.jobTitle,
          department: values.department,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          dateCreated:
            this.state.dateCreated || getCurrentDateForBackend(new Date()),
          isPrimaryContact:
            values.isPrimaryContact || this.state.isPrimaryContact ? 1 : 0,
          countryCallingCode: values.countryCallingCode,
          contactType: this.state.contactType,
        };
        if (
          this.props.data &&
          ((this.props.data.formData && this.props.data.formData.customerId) ||
            this.props.data.isDGContact)
        ) {
          payload.customerId = (this.props.data.formData || {}).customerId;
          payload.contactType = CONSTANTS.CONTACT_TYPE_CUSTOMER;
        }
        if (
          this.props.data &&
          this.props.data.formData &&
          this.props.data.formData.supplierId
        ) {
          payload.supplierId = this.props.data.formData.supplierId;
          payload.contactType = CONSTANTS.CONTACT_TYPE_SUPPLIER;
        }
        if (
          this.props.data &&
          this.props.data.formData &&
          this.props.data.formData.isFundraiser
        ) {
          payload.contactType = CONSTANTS.CONTACT_TYPE_FUNDRAISER;
          payload.teamMasterId = this.props.data.formData.teamMasterId;
        }
        if (
          this.props.data &&
          this.props.data.formData &&
          this.props.data.formData.isTeacher
        ) {
          payload.role = "teacher";
        }
        const modalData = {
          modalBody: this.state.firstName ? (
            "Are you sure you want to Save ?"
          ) : (
            "Are you sure you want to Submit ?"
          ),
          // modalBody: this.props.data ? "Are you sure you want to Save ?":"Are you sure you want to Submit ?",
          handleSubmit: () => {
            this.props.createContact(payload, (this.props.data || {}).formData);
            this.props.handleSubmit && this.props.handleSubmit();
            // this.props.popModalFromStack();
          },
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: "Contact_component" })(
  injectIntl(ContactComponent)
);
