import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    fetchPaymentTerms, addPaymentTerm, deletePaymentTerm,
    fetchBusinessTypes, addBusinessType, deleteBusinessType,
    fetchIndustries, addIndustry, deleteIndustry,
    fetchSources, addCustomerSource, deleteCustomerSource, hideModal
} from '../../../../../actions/commonActions';
import ImportStudentsComp from '../../../../../components/modal/modalBody/schoolManagement/ImportStudents';


// import { importCustomer, downloadExelFile, getCustomerImportHeader } from './action';


class ImportStudents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            isuploded: false,
            headerMandatoryValues:[],
            headerValues:[]
        };

    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId };
    }

    componentWillReceiveProps(){

        // if(this.props.importCustomerHeader.length ){
        //     let headerValues = this.props.importCustomerHeader?.map((item)=>{
        //                return item.columnName
        //     })
        //     let headerMandatoryValues = this.props.importCustomerHeader?.map((item)=>{
        //         if(item.mandatory){
        //             return true

        //         }else{
        //              return false
        //         }
        //     })
        //     this.setState({
        //         headerValues:headerValues,
        //         headerMandatoryValues:headerMandatoryValues
        //       });
        // }
    }

    render() {
        return <ImportStudentsComp {...this.props} {...this.state} updateModalState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        sources: state.common.sources,
        businessTypes: state.common.businessTypes,
        paymentTerms: state.common.paymentTerms,
        industries: state.common.industries,
        companyInfo: state.common.companyInfo,
        importCustomerHeader: state.common.customerImportHeader,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSources,
    addCustomerSource,
    deleteCustomerSource,
    fetchPaymentTerms,
    addPaymentTerm,
    deletePaymentTerm,
    fetchIndustries,
    addIndustry,
    deleteIndustry,
    fetchBusinessTypes,
    addBusinessType,
    // importCustomer,
    deleteBusinessType,
    hideModal,
    // getCustomerImportHeader, downloadExelFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportStudents);
