import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreateAssignmentComponent from '../../../../../components/modal/modalBody/schoolManagement/CreateAssignment';
import {
    fetchPaymentTerms, addPaymentTerm, deletePaymentTerm,
    fetchBusinessTypes, addBusinessType, deleteBusinessType,
    fetchIndustries, addIndustry, deleteIndustry,
    fetchSources, addCustomerSource, deleteCustomerSource, hideModal,
    fetchAssignmentGroup, addAssignmentGroup, deleteAssignmentGroup
} from '../../../../../actions/commonActions';

import { getAllStudentForm } from '../../../../Student/studentFormList/action';
import { getClassList } from '../../../../schoolManagement/class/action';
import { getSchoolCourse } from '../../../../schoolManagement/course/action';
// import {
//     fetchCustomers
// } from '../../../../../containers/customer/Listing/action';
import { createAssignment } from './action';


class CreateAssignment extends Component {
    constructor(props) {
        super(props);
        let assignment = props.assignment || {};
        this.state = {
            current: 0,
            pageNumber: 1,
            pageSize: 1000,
            
            // classData: props.classData || {classId : assignment.classId, className: assignment.className},
            classData: (props.classList[1] || []).find(item => item.classId === assignment.classId),
            assignmentId: assignment.assignmentId,
            assignmentName: assignment.assignmentName,
            assignmentDescription: assignment.assignmentDescription,
            dueDate: assignment.dueDate ? new Date(assignment.dueDate) : null,
            startDate: assignment.startDate ? new Date(assignment.startDate) : null,
            endDate: assignment.endDate ? new Date(assignment.endDate) : null,
            classId: assignment.classId,
            className: assignment.className,
            classSectionId: assignment.classSectionId,

            assignmentGroupId: assignment.assignmentGroupId,
            // teacherId: props.teacherId || 0,
            //isCountInFinalGrade: props.assignmentGroupId,
            numberOfAttempts: assignment.numberOfAttempts,
            maxAttempts: assignment.maxAttempts,
            // scoreType: props.assignmentGroupId,
            assignmentGrade: assignment.gradingSystem,
            assignmentPoints: assignment.assignmentGradePoint || '',
            questionList: JSON.parse(assignment.questionList || '[]'),
            isAttachementRequiredInResponse: assignment.isAttachementRequiredInResponse,
            docDetailList: assignment.docDetailList || [],
            scheduling: assignment.scheduling, 
            assignmentResponseList: assignment.assignmentResponseList || [],
            sectionName:assignment.sectionName,
            subjectName:assignment.subjectName,


        };
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            relationshipEmployeeId: userInfo.relationshipEmployeeId,
            pageNumber: 1,
            pageSize: 1000,
            formId: this.props.defaultSchoolForm
        };
        this.props.fetchAssignmentGroup(payload);
        this.props.getClassList(payload);
    }

    render() {
        return <CreateAssignmentComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        userInfo: state.common.userInfo,
        sources: state.common.sources,
        defaultSchoolForm: state.common.defaultSchoolForm,
        businessTypes: state.common.businessTypes,
        paymentTerms: state.common.paymentTerms,
        industries: state.common.industries,
        companyInfo: state.common.companyInfo,
        studentFormList: state.membershipReducer.studentFormList,
        courseList: state.school.courseList,
        assignmentGroupList: state.common.assignmentGroupList,
        classList: state.school.classList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSources,
    addCustomerSource,
    deleteCustomerSource,
    fetchPaymentTerms,
    addPaymentTerm,
    deletePaymentTerm,
    fetchIndustries,
    addIndustry,
    deleteIndustry,
    fetchBusinessTypes,
    addBusinessType,
    createAssignment,
    deleteBusinessType,
    hideModal,
    getAllStudentForm,
    getSchoolCourse,
    getClassList,
    fetchAssignmentGroup, addAssignmentGroup, deleteAssignmentGroup
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateAssignment);
