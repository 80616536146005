import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchOutOfStockItems, resetPaginatedData } from './action';
import { popModalFromStack } from './../../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded } from '../../../../../utils';
import OutOfStockComponent from '../../../../../components/modal/modalBody/product/OutOfStock';

class OutOfStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 100,
            pageNumber: 1,
            searchedText: ''
        };
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
       
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 100,
          
        }
        fetchPaginationDataIfNeeded('fetchOutOfStockItems', 'outOfStockList', this.props, payload, true);

    }

    render() {
        return <OutOfStockComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        outOfStockList: state.product.outOfStockList
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchOutOfStockItems,
    resetPaginatedData,
    popModalFromStack,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OutOfStock);