import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Popover, Menu, Pagination } from 'antd';
import { InsertRowRightOutlined,MoreOutlined,UserOutlined } from '@ant-design/icons';

import { AddAndRemoveColumn } from '../../general/AddAndRemoveEmployeeListing';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { getMomentDateForUIReadOnly, sortTable } from '../../../utils';
import ManageUser from '../../../containers/modal/modalBody/settings/ManageUser';
import find from 'lodash.find';
import { CONSTANTS, ICONS } from '../../../static/constants';



const EmployeeListingComp = (props) => {

    const {dir=0, updateState, allEmployee, companyInfo } = props;

    const _isColumnToHide = (columnName) => {
        return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }
    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        // fetchPaginationDataIfNeeded('fetchSalesOrders', 'soList', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        // props.resetPaginatedData();
        // props.fetchSalesOrders(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }
    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };
    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };


    // cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //     openSoDetails(link.data)
    // }}>{link.value}</div>,

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return  <FormattedMessage id='company.employee.firstName' defaultMessage=' ' />;
    //         },
    //         field: "firstName",
    //         colId: "firstName_1",
    //         hide: _isColumnToHide("firstName"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (params) => {
    //             return <div>{params.data.firstName +(params.data.lastName ? ' '+params.data.lastName : '')}</div>
    //         }
    //     },

    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='company.employee.lastName' defaultMessage='' />;
    //     //     },
    //     //     field: "lastName",
    //     //     colId: "lastName_1",
    //     //     hide: _isColumnToHide("lastName"),
    //     //     resizable: true,
    //     //     minWidth: 70,
    //     //     maxWidth: 400,
    //     //     suppressMovable: true,
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.employee.type' defaultMessage='' />;
    //         },
    //         field: "empType",
    //         colId: "empType_1",
    //         hide: _isColumnToHide("empType"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },
        
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.employee.hierarchyName' defaultMessage=' ' />;
    //         },
    //         field: "hierarchyName",
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.employee.hierarchyCode' defaultMessage=' ' />;
    //         },
    //         field: "hierarchyCode",
    //         resizable: true
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.employee.jobTitle' defaultMessage='' /> ;
    //         },
    //         field: "jobTitleName",
    //         colId: "jobTitleName_1",
    //         hide: _isColumnToHide("jobTitleName"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.employee.jobRole' defaultMessage='' /> ;
    //         },
    //         field: "jobRoleName",
    //         colId: "jobRoleName_1",
    //         hide: _isColumnToHide("jobRoleName"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return  <FormattedMessage id='company.employee.manager' defaultMessage='' /> ;
    //         },
    //         field: "managerName",
    //         colId: "managerName_1",
    //         hide: _isColumnToHide("managerName"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <span title={<FormattedMessage id='company.employee.companyHierarchy' defaultMessage='' />}> <FormattedMessage id='company.employee.companyHierarchy' defaultMessage='' /></span>;
    //         },
    //         field: "hierarchyName",
    //         colId: "hierarchyName_1",
    //         hide: _isColumnToHide("hierarchyName"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <span title={<FormattedMessage id='company.employee.hierarchyLevel' defaultMessage='' />}><FormattedMessage id='company.employee.hierarchyLevel' defaultMessage='' /></span>;
    //         },
    //         field: "hierarchyLevel",
    //         colId: "hierarchyLevel_1",
    //         hide: _isColumnToHide("hierarchyLevel"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.employee.phone' defaultMessage='' />;
    //         },
    //         field: "workPhone",
    //         colId: "workPhone_1",
    //         hide: _isColumnToHide("workPhone"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.employee.email' defaultMessage='' />;
    //         },
    //         field: "emailAddress",
    //         colId: "emailAddress_1",
    //         hide: _isColumnToHide("emailAddress"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.employee.department' defaultMessage='' />;
    //         },
    //         field: "departmentName",
    //         colId: "departmentName_1",
    //         hide: _isColumnToHide("departmentName"),
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },
    //     {
    //         field: "action",
    //         headerComponentFramework: () => {
    //             return <div className="cursor-pointer">
    //                 <Popover
    //                     content={<AddAndRemoveColumn {...props} />}
    //                     title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
    //                     placement="left"
    //                     trigger="click"
    //                 >
    //                     <i className='fa fa-bars' />
    //                 </Popover>
    //             </div>;
    //         },
    //         colId: "action_1",
    //         pinned: "right",
    //         lockPosition: true,
    //         cellRendererFramework: (params) => {
    //             return <div>
    //                 <span className="cursor-pointer" title={<FormattedMessage id='common.edit.employee' defaultMessage='' />} onClick={
    //                     () => {
    //                         props.updateHeaderState({
    //                             company: {
    //                                 ...props.company,
    //                                 employeeData: { ...params.data },
    //                                 employeeDrawerVisible: true,
    //                             }
    //                         })
    //                     }}>
    //                     <i className='fa fa-pencil' />
    //                 </span>
    //                 {!params.data.isAccountHolder ?
    //                     <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={<FormattedMessage id='common.delete.employee' defaultMessage='' />} onClick={
    //                         () => {
    //                             deleteHandle(params.data);
    //                         }}>
    //                         <i className='fa fa-trash' />
    //                     </span> : ''
    //                 }
    //             </div>;
    //         },
    //         minWidth: 70,
    //         maxWidth: 70,
    //         suppressMovable: true,
    //     }
    // ];


    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });

    //     onDataRendered(params);
    // }

    // const saveColumnIndex = (columnState) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.gridPreference = props.gridPreference;
    //     data.preferenceId = props.preferenceId;
    //     props.saveUserPrefrences(data);
    // }

    // const saveColumnWidth = (columnState, width) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data, props);
    // }

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
    //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
    //     event.api.resetRowHeights();
    //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
    //         event.api.resetRowHeights();
    //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
    //             props.gridApi.sizeColumnsToFit();
    //             setTimeout(() => {
    //                 const columnArray = event.columnApi.getColumnState();
    //                 saveColumnWidth(columnArray);
    //             }, 1000);
    //         } else {
    //             saveColumnWidth(event.columnApi.getColumnState());
    //         }
    //     }
    // }

    // const onModelUpdated = (params) => {
    //     if((props.allEmployee || []).length &&  !props.isRowHeightSet){
    //         setTimeout(()=>{
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //         //params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         const txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     }
    // }

    // const onDataRendered = (params) => {
    //     const txColumnSetting = props.txColumnSetting;
    //     if (Object.keys(txColumnSetting).length) {
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.width = 52;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     } else {
    //         params.api.sizeColumnsToFit();
    //     }
    // }


    const deleteHandle = (employee) => {
        const modalBody = <FormattedMessage id='modalBody.deleteEmployee' defaultMessage='' />;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.deleteEmployee({
                    relationshipId: employee.relationshipId,
                    id: employee.relationshipEmployeeId,
                    employee: employee
                })
            },
        };
        props.showModal(modalData);
    }
    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.teamName' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.employee' defaultMessage='' />,
        }
    ];

    const sortColumn = (e) => {
        sortTable(document.getElementById('employee-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
              props.updateHeaderState({
                company: {
                  ...props.company,
                  employeeData: { ...e },
                  employeeDrawerVisible: true,
                },
              });
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

          {e.isAccountHolder ? (
            <Menu.Item
              key="2"
              onClick={() => {
                deleteHandle(e);
              }}
            >
              <i className={ICONS.DELETE} />
              Delete
            </Menu.Item>
          ) : (
            ""
          )}

          {(
            find(props.allCompanyUsers, {
              relationshipEmployeeId: e.relationshipEmployeeId,
            }) || {}
          ).relationshipEmployeeId === e.relationshipEmployeeId ? (
            <Menu.Item
              key="4"
              value="createUser"
              onClick={() => {
                props.showModal({
                  title: (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5 style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        <FormattedMessage
                          id="setting.userManagement.updatePermissions.label"
                          defaultMessage=""
                        />
                      </h5>
                      <h7>
                        {e.firstName || ""} {e.lastName ? e.lastName : ""}
                        <br />
                        {e.email || ""}{" "}
                      </h7>
                    </div>
                  ),
                  modalBody: (
                    <ManageUser
                      toDisable={e.userType === "admin"}
                      {...props}
                      userData={
                        find(props.allCompanyUsers, {
                          relationshipEmployeeId: e.relationshipEmployeeId,
                        }) || {}
                      }
                    />
                  ),
                  width: 1000,
                  hideFooter: true,
                  wrapClassName: "manage-user-modal",
                });
              }}
            >
              <UserOutlined />
              Edit User
            </Menu.Item>
          ) : (
            <Menu.Item
              key="5"
              value="createUser"
              onClick={() => {
                props.showModal({
                  title: (
                    <FormattedMessage
                      id="setting.userManagement.addUser.label"
                      defaultMessage=""
                    />
                  ),
                  modalBody: <ManageUser {...props} selectedEmployee={e} />,
                  width: 1000,
                  hideFooter: true,
                  wrapClassName: "manage-user-modal",
                });
              }}
            >
              <UserOutlined />
              Create User
            </Menu.Item>
          )}
        </Menu>
      );
    };

    // const onSearchedInputChange = debounce((searchedText, props) => {
    //     handleSearchedText(searchedText, props)
    // }, 500);

    // const handleSearchedText = (searchedText, props) => {
    //     const payload = {
    //         relationshipId: props.companyInfo.relationshipId,
    //         pageNumber: 1,
    //         pageSize: props.pageSize,
    //         userId: (props.userInfo || {}).userId,
    //         searchedText: searchedText
    //     };
    //     if (searchedText.length) {
    //         if (searchedText.length >= 3 && !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())) {
    //             props.fetchAllEmployees(payload)
    //         }
    //     } else {
    //         props.fetchAllEmployees(payload)
    //     }
    // }


    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="sidebar.menuItem.employee"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={() => {
                props.updateHeaderState({
                  company: {
                    ...props.company,
                    employeeDrawerVisible: true,
                  },
                });
              }}
            >
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>

        <div className="view-container">
          <Skeleton
            loading={props.isLoading && !props.company?.employeeDrawerVisible}
          >
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="organization.employee.table.heading"
                    defaultMessage=""
                  />
                </div>
                {allEmployee?.length ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{allEmployee?.length}</div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="right-actions">
                <Popover
                  // overlayClassName='popoverStyle'
                  content={<AddAndRemoveColumn {...props} />}
                  title={
                    <div className="text-center">
                      <FormattedMessage
                        id="common.addRemove"
                        defaultMessage=""
                      />{" "}
                    </div>
                  }
                  placement="leftTop"
                  trigger="click"
                  arrow="right"
                  visible={props.showColPop}
                >
                  <button
                    onClick={() => {
                      props.updateState({ showColPop: !props.showColPop });
                    }}
                  >
                    <InsertRowRightOutlined className='mr5'/>
                   Columns
                  </button>
                </Popover>
              </div>
            </div>

            {/* <div className="view-container-actions">
                <div className="d-inline-flex" style={{ width: 330, height: 36 }}>
                    <Search
                        placeholder={props.intl.formatMessage(
                            {
                                id: 'company.employee.name.placeholder',
                                defaultMessage: ''
                            })
                        }
                        value={props.searchedText}
                        onChange={e => {
                            props.updateState({ searchedText: e.target.value, pageNumber: 1 });
                            onSearchedInputChange(e.target.value, props);
                        }}
                        onSearch={(searchedText) => {
                            props.updateState({ searchedText, pageNumber: 1 });
                            onSearchedInputChange(searchedText, props);
                            handleSearchedText(searchedText, props);
                        }}
                        style={{ width: 330, height: 36 }}
                    />
                </div> 
            </div> */}

            <div className="table-container">
              <table id="employee-table">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="serial.no" defaultMessage=" " />
                    </th>

                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="company.employee.employeeId"
                        defaultMessage=" "
                      />{" "}
                      <i class="fi fi-rr-sort-alt"></i>
                    </th>

                    {!_isColumnToHide("firstName") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.employeeName"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}
                    {!_isColumnToHide("empType") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.type"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="company.employee.hierarchyName"
                        defaultMessage=" "
                      />{" "}
                      <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="company.employee.hierarchyCode"
                        defaultMessage=" "
                      />{" "}
                      <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    {!_isColumnToHide("jobTitleName") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.jobTitle"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}
                    {!_isColumnToHide("jobRoleName") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.jobRole"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}
                    {!_isColumnToHide("managerName") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.manager"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}
                    {!_isColumnToHide("hierarchyName") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.companyHierarchy"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}
                    {!_isColumnToHide("hierarchyLevel") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.hierarchyLevel"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}
                    {!_isColumnToHide("workPhone") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.phone"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}
                    {!_isColumnToHide("emailAddress") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.email"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}
                    {!_isColumnToHide("departmentName") ? (
                      <th onClick={sortColumn}>
                        <FormattedMessage
                          id="company.employee.department"
                          defaultMessage=" "
                        />{" "}
                        <i class="fi fi-rr-sort-alt"></i>
                      </th>
                    ) : (
                      ""
                    )}

                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="company.employee.dateCreated"
                        defaultMessage=" "
                      />{" "}
                      <i class="fi fi-rr-sort-alt"></i>
                    </th>

                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {(allEmployee || []).length ?
                    allEmployee.map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {rowData.employeeCode ? rowData.employeeCode : "-"}
                          </td>

                          {!_isColumnToHide("firstName") ? (
                            <td>
                              {rowData.firstName +
                                (rowData.lastName
                                  ? " " + rowData.lastName
                                  : "")}
                            </td>
                          ) : (
                            ""
                          )}
                          {!_isColumnToHide("empType") ? (
                            <td>{rowData.empType}</td>
                          ) : (
                            ""
                          )}
                          <td>{rowData.hierarchyName}</td>
                          <td>{rowData.hierarchyCode}</td>
                          {!_isColumnToHide("jobTitleName") ? (
                            <td>{rowData.jobTitleName}</td>
                          ) : (
                            ""
                          )}
                          {!_isColumnToHide("jobRoleName") ? (
                            <td>{rowData.jobRoleName}</td>
                          ) : (
                            ""
                          )}
                          {!_isColumnToHide("managerName") ? (
                            <td>{rowData.managerName}</td>
                          ) : (
                            ""
                          )}
                          {!_isColumnToHide("hierarchyName") ? (
                            <td>{rowData.hierarchyName}</td>
                          ) : (
                            ""
                          )}
                          {!_isColumnToHide("hierarchyLevel") ? (
                            <td>{rowData.hierarchyLevel}</td>
                          ) : (
                            ""
                          )}
                          {!_isColumnToHide("workPhone") ? (
                            <td>{rowData.workPhone}</td>
                          ) : (
                            ""
                          )}
                          {!_isColumnToHide("emailAddress") ? (
                            <td>{rowData.emailAddress}</td>
                          ) : (
                            ""
                          )}
                          {!_isColumnToHide("departmentName") ? (
                            <td>{rowData.departmentName}</td>
                          ) : (
                            ""
                          )}

                          <td>
                            {rowData.dateCreated
                              ? getMomentDateForUIReadOnly({
                                  date: rowData.dateCreated,
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })
                              : "-"}
                          </td>

                          <td>
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </DropdownAction>
                          </td>
                        </tr>
                      );
                    })
                  :"No Data"
                  }
                </tbody>
              </table>
            </div>

            <div className="footer">
              <div className="f-left"></div>
              <div className="f-right">
                <Pagination
                  size="small"
                  total={30}
                  showTotal={showTotal}
                  defaultPageSize={props.pageSize}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100, 200]}
                  // showQuickJumper
                  onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                  }}
                  onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                  }}
                />
              </div>
            </div>
          </Skeleton>
        </div>
      </>
    );
};




export default injectIntl(EmployeeListingComp);
