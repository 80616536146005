import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AmazonListComponent from '../../../../../components/modal/modalBody/common/amazon';
import {uploadInAmazon} from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, getAllMatchingInventoryItems, resetMatchingInventoryItems } from '../../../../../actions/commonActions';


class AmazonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    this.props.resetMatchingInventoryItems();
    this.props.getAllMatchingInventoryItems({ relationshipId: companyInfo.relationshipId, itemIds: this.props.inventoryList });
  }

  render() {
    return (
      <AmazonListComponent {...this.props} {...this.state}  updateState={(data) => { this.setState(data) }}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    selectedProductList: state.common.selectedProductList
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllMatchingInventoryItems, 
  resetMatchingInventoryItems,
  uploadInAmazon,
  showModal, hideModal, pushModalToStack, popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AmazonList);
