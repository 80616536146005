import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CustomButton } from '../../general/CustomButton';
import { Input } from 'antd';

class CardConnectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };


    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content employee-modal-control">

          <Form.Item label={(<span><FormattedMessage id='payment.gateway.name' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('userDefinedGatewayName', {
                rules: [
                  {
                    required: true,
                    message: 'Payment Gateway name',
                    initialValue: this.props.userDefinedGatewayName || ''
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'payment.gateway.name', defaultMessage: '' })} maxLength={150}
                onChange={e => {
                  this.setState({ userDefinedGatewayName: e.target.value });
                }}
              />)}
            </Form.Item>

          <Form.Item label={(<span><FormattedMessage id='stripe.publicKey.text' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('publicKey', {
                rules: [
                  {
                    required: true,
                    message: 'public key is required.',
                    initialValue: this.props.merchantId || ''
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'stripe.publicKey.text', defaultMessage: '' })} maxLength={150}
                onChange={e => {
                  this.setState({ publicKey: e.target.value });
                }}
              />)}
            </Form.Item>

            <Form.Item label={(<span><FormattedMessage id='stripe.secretKey.text' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('secretKey', {
                rules: [
                  {
                    required: true,
                    message: 'Secret key is required.',
                    initialValue: this.props.secretKey || '',
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'stripe.secretKey.text', defaultMessage: '' })} maxLength={150}
                onChange={e => {
                  this.setState({ secretKey: e.target.value });
                }} />)}
            </Form.Item>
            <div className='mb10' style={{ textAlign: 'right' }}>
              <a rel="noopener noreferrer" href="https://support.stripe.com/questions/locate-api-keys-in-the-dashboard" target="_blank" >How to get credentials?</a>
            </div>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.popModalFromStack(); }}
              />
              <CustomButton
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...this.props.data,
          publicKey: values.publicKey,
          secretKey: values.secretKey,
          userDefinedGatewayName: values.userDefinedGatewayName,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          paymentGatewayName: 'Stripe'
        }

        const modalData = {
          modalBody: <FormattedMessage id='save.confirmation' defaultMessage='' />,
          handleSubmit: () => {
            this.props.saveStripeConfiguration(payload, (this.props.data || {}).formData);
            this.props.handleSubmit && this.props.handleSubmit();
            this.props.popModalFromStack();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Card_Connect_Component' })(injectIntl(CardConnectComponent));
