import { MEMBERSHIP_ACTION_LIST } from "../static/constants";

const initialState = {
  termFeeList: {
    1: [],
  },
  allTermAndFee: [],
  termFeeCount: 0,
  membershipOptionList: {
    1: [],
  },
  membershipOptionCount: 0,
  membershipFormList: [],
  newsLetterTemplate: {},
  studentFormList:[],
  orgMembershipList: {
    1: [],
  },
  orgMembershipCount: 0,
  dgDetails:{}
};

const MembershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEMBERSHIP_ACTION_LIST.TERM_AND_FEES_LIST:
      return {
        ...state,
        termFeeList: {
          ...state.termFeeList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case MEMBERSHIP_ACTION_LIST.TERM_AND_FEES_COUNT:
      return { ...state, termFeeCount: action.data };
    case MEMBERSHIP_ACTION_LIST.TERM_AND_FEE_LIST_PAGE_RESET:
      return { ...state, termFeeList: initialState.termFeeList };
    case MEMBERSHIP_ACTION_LIST.ALL_TERM_AND_FEES:
      return { ...state, allTermAndFee: action.data };

    case MEMBERSHIP_ACTION_LIST.MEMBERSHIP_OPTION_LIST:
      return {
        ...state,
        membershipOptionList: {
          ...state.membershipOptionList,
          [action.data.pageNo || 1]: action.data.list,
        },
      };
    case MEMBERSHIP_ACTION_LIST.MEMBERSHIP_OPTION_COUNT:
      return { ...state, membershipOptionCount: action.data };
    case MEMBERSHIP_ACTION_LIST.MEMBERSHIP_OPTION_LIST_PAGE_RESET:
      return { ...state, membershipOptionList: initialState.membershipOptionList };
    case MEMBERSHIP_ACTION_LIST.MEMBERSHIP_FORM_LIST:
      return { ...state, membershipFormList: action.data };
    case MEMBERSHIP_ACTION_LIST.NEWS_LETTER_TEMPLATE:
      return { ...state, newsLetterTemplate: action.data };
    case MEMBERSHIP_ACTION_LIST.STUDENT_FORM_LIST:
      return { ...state, studentFormList: action.data };
    case MEMBERSHIP_ACTION_LIST.ORG_MEMBERSHIP_LIST:
      return {
        ...state,
        orgMembershipList: {
          ...state.orgMembershipList,
          [action.data.pageNo || 1]: action.data.list,
        }
       };
    case MEMBERSHIP_ACTION_LIST.ORG_MEMBERSHIP_COUNT:
      return { ...state, orgMembershipCount: action.data };

    case MEMBERSHIP_ACTION_LIST.DG_DETAILS:
      return { ...state, dgDetails: action.data };
    default:
      return state;
  }
};

export default MembershipReducer;
