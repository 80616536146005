import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CourseComp from "../../../../components/drawer/schoolManagement/course";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { saveSchoolCourse, getSchoolCourse } from '../../../schoolManagement/course/action';
import { getDepartmentList } from '../../../schoolManagement/department/action';
import { getCourseTermList } from '../../../schoolManagement/courseTerm/action';
import { getSubject } from '../../../schoolManagement/subject/action';

class SchoolDrawer extends Component {
 
  constructor(props) {
    super(props);
    let courseData = props.courseData || {};
    let prerequisiteCourseIds= [];
    let courseTermIds = [];
    let termSubjects = {};
    (courseData.prerequisiteCourseRefList || []).forEach(function (prc) {
      prerequisiteCourseIds.push(prc.prerequisiteCourseId);
    });

    (courseData.subjectList || []).forEach(function (sub) {
     if(courseTermIds.indexOf(sub.courseTermId) === -1){ courseTermIds.push(sub.courseTermId)};
      let targetKeys = (termSubjects[sub.courseTermId] || {}).targetKeys || [];
      targetKeys.push(sub.subjectId);
      termSubjects[sub.courseTermId] = {
        ...termSubjects[sub.courseTermId],
        targetKeys: targetKeys
      }
    });
    
    this.state = {
      termSubjects: termSubjects,
      courseCode: courseData.courseCode,
      courseName: courseData.courseName,
      selectedDeptId: courseData.deptId,
      courseId: courseData.courseId,
      version: courseData.version,
      prerequisiteCourseRefList: prerequisiteCourseIds,
      courseTermIds: courseTermIds,
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 1000,
    };
    this.props.getCourseTermList(payload, this.props);
    this.props.getDepartmentList(payload, this.props);
    this.props.getSubject(payload, this.props);
    this.props.getSchoolCourse(payload, this.props);
  }

  componentWillReceiveProps(props) {
    if (props.subjectList && props.subjectList[1] && props.subjectList[1].length && !this.state.isInit) {
      let termSubjects = this.state.termSubjects || {};
      let subjectList = [];
      (props.subjectList[1] || []).forEach(function (sub) {
        subjectList.push({
          key: (sub.subjectId),
          title: sub.name,
          subjectCode: sub.subjectCode,
        });
      });

      (this.state.courseTermIds || []).forEach(function (courseTermId) {
        termSubjects[courseTermId] = {
          ...termSubjects[courseTermId],
          mockData: subjectList,
        }
      });
      this.setState({ termSubjects, isInit: true })
    }
  }

  render() {
    return (
      <CourseComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    courseTermList: state.school.courseTermList,
    departmentList: state.school.departmentList,
    subjectList: state.school.subjectList,
    courseList: state.school.courseList,
    listLoading: state.common.listLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveSchoolCourse,
      pushModalToStack,
      popModalFromStack,
      getCourseTermList,
      getDepartmentList,
      getSchoolCourse,
      getSubject
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDrawer);
