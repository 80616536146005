import { Divider } from 'antd'
import React, { Fragment } from 'react'
import Header from "./Header"
import StockTransitDetail from "./LandedCostDetail"
import ProductDetails from "./ProductDetail"
const index = (props) => {
    return (
        <Fragment>
        <div className="txn-header">
          <Header {...props} />
        </div>
        <div className="txn-body">
          <div className="txn-details-container">
            <StockTransitDetail
              paymentTerms={props.paymentTerms}
              {...props}
            />
          </div>
          <Divider style={{ margin: "0 0 10px 0px" }} />
          <div className="product-details-container">
            <ProductDetails {...props} />
          </div>
        </div>
      </Fragment>
      );
}

export default index
