import React, { Fragment} from "react";
import {  FormattedMessage } from "react-intl";
// import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";
import ReactHtmlParser from 'react-html-parser';
import { fixedDecimalNumber, getUserDefinedName, numberToWordsWithDecimals } from "../../../utils";
import { ErrorMsg } from "../../general/ErrorMessage";
// import ProductDetailsTab from "./ProductDetailsTab";

import { CONSTANTS } from "../../../static/constants";

import "@ant-design/compatible/assets/index.css";

import { Col, Row,  } from "antd";
import find from "lodash.find";

const ItemDetails = (props) => {

  const {salesInvoiceDetail} = props;
  const {customerInvoiceDetailsList} = salesInvoiceDetail;
  const relationshipAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
  let isPlaceOfSupplySameAsRelationship = false
  isPlaceOfSupplySameAsRelationship = (salesInvoiceDetail.placeOfSupply && relationshipAddress.stateName === salesInvoiceDetail.placeOfSupply) || false;
  let isColumnEmpty = {
    product: true,
    service: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
    rate: true,
    hsn: true,
    discount: true,
    tax: true,
    specialDiscount: true,
    documentDetails: true,
    comment: true,
    studentName: true,
    studentGrade: true,
    studentClassName: true,
};
  return (
    <div>
   

<div className="modal-table-container mb15">
                            <table className="transaction-details-table">
                                <thead>
                                    <tr>
                                        <th className="cell-size-50">#</th>
                                        {!isColumnEmpty.studentName && <th className="text-center"><FormattedMessage id='student.text' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.studentGrade && <th className="text-center"><FormattedMessage id='grade.text' defaultMessage='' /></th>} */}
                                        {!isColumnEmpty.studentClassName && <th className="text-center">{getUserDefinedName('Class', props)}</th>}
                                        { <th>
                                            { <span>  <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                            {/* { <span> & </span>}
                                            { <span>  <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>} */}
                                        </th>}
                                        {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                                        { <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                                        {/* { <th className="text-center"><FormattedMessage id='modal.txDetails.common.basePrice' defaultMessage='' /></th>} */}
                                        {/* { <th className="text-right"><FormattedMessage id='modal.txDetails.common.specialDiscount' defaultMessage='' /></th>} */}
                                        { <th className="text-end">{salesInvoiceDetail.templateCode === 'student' ? <FormattedMessage id='fee.text' /> : <FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' />}</th>}
                                        { <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                        { <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                        {/* { <th className="text-right"><FormattedMessage id='discountType.text' defaultMessage='' /></th>} */}
                                        { <th className="text-right"><FormattedMessage id='modal.txDetails.common.tax' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.comment && <th>Warehouse</th>} */}
                                        {<th className="text-end">Taxable Amount</th>}
                                        <th className="text-end"><FormattedMessage id='modal.txDetails.common.netAmount' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(customerInvoiceDetailsList || []).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="cell-size-50">{i + 1}</td>
                                                {!isColumnEmpty.studentName && <td className="text-center">{item.studentName || '-'}</td>}
                                                {/* {!isColumnEmpty.studentGrade && <td className="text-center">{item.studentGrade  || '-'}</td>} */}
                                                {!isColumnEmpty.studentClassName && <td className="text-center">{item.studentClassName || '-'}</td>}
                                                { <td>
                                                    <strong>{getVariantFragment(item)}</strong>
                                                    {<div className="description-cell">
                                                        {/* <pre>{ReactHtmlParser(item.description || '-')}</pre> */}
                                                        {getDescriptionFragment(salesInvoiceDetail, item, i + 1)}
                                                    </div>}
                                                </td>}
                                                {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                                                { <td className="text-center">{item.quantityToInvoice + (item.uomName ? ' ' + item.uomName : '')}</td>}
                                                {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                                                {/* { <td className="text-center">{fixedDecimalNumber(item.basePrice || 0) || '-'}</td>} */}
                                                {/* { <td className="text-right">{getSpecialDiscount(item, 'amountPercent')}</td>} */}
                                                { <td className="text-end">{fixedDecimalNumber(item.anItemSalePrice || 0) || '-'}</td>}
                                                { <td className="text-center">{item.hsnCode || '-'}</td>}
                                                {<td className="text-right">{getDiscountAmount(item, 'amount')} {getDiscountAmount(item, 'amountPercent')}</td>}
                                                {/* { <td className="text-right">{getDiscountType(item, 'discount')}</td>} */}
                                                { <td className="text-right">{getTotalTaxAmount(item, isPlaceOfSupplySameAsRelationship)} {getTotalTaxPercent(item, 'amountPercent', isPlaceOfSupplySameAsRelationship)}</td>}
                                                {/* {!isColumnEmpty.comment && <td className="description-cell">
                                                    <pre>{ReactHtmlParser(item.comment || '-')}</pre>
                                                </td>} */}
                                            {<td className="text-end">{getNetAmount(item,isPlaceOfSupplySameAsRelationship,true)}</td>}
                                                <td className="text-end">{getNetAmount(item, isPlaceOfSupplySameAsRelationship) || '-'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <Col span={24} className="">
                                {salesInvoiceDetail.isMultiCurrency ?
                                    <span> {salesInvoiceDetail.foreignCurrency + ": "} </span>
                                    : <span> {props.companyInfo.currencyCode + ": "} </span>
                                }    {(salesInvoiceDetail.invoiceTotalAmount) && numberToWordsWithDecimals(fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount)) || ''}
                            </Col>
                        </div>

                        <div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Final Invoice Value" defaultMessage="" />
              <span className="required">*</span>
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="Total Assessable/ Taxable value of all items"
                defaultMessage=""
              />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Final Invoice Value"}
                type="text"
                value={props.finalInvoiceValue}
                onChange={(e) => {
                  props.updateState({
                    finalInvoiceValue: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.finalInvoiceValue;
                }}
                message={"Invoice Value is required"}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"Taxable Value"}
                type="text"
                maxLength={20}
                value={props.totalTaxableValue}
                onChange={(e) => {
                  props.updateState({
                    totalTaxableValue: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.totalTaxableValue;
                }}
                message={"Taxable Value is required"}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="Total IGST value of all items"
                defaultMessage=""
              />
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="Total CGST value of all items"
                defaultMessage=""
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Total IGST"}
                type="text"
                value={props.totalIGST}
                onChange={(e) => {
                  props.updateState({
                    totalIGST: e.target.value,
                  });
                }}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"Total CGST"}
                type="text"
                maxLength={20}
                value={props.totalCGST}
                onChange={(e) => {
                  props.updateState({
                    totalCGST: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="Total SGST value of all items"
                defaultMessage=""
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Total SGST"}
                type="text"
                value={props.totalSGST}
                onChange={(e) => {
                  props.updateState({
                    totalSGST: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const getNetAmount = (row,isPlaceOfSupplySameAsRelationship,withoutTax) => {
  let tax = getTotalTaxAmount(row) || 0;
  let taxValue = isPlaceOfSupplySameAsRelationship ? tax*2:tax
  let discount = getDiscountAmount(row,'amount') || 0;
  let netAmountItemwithoutTax = (Number(row.quantityToInvoice * row.anItemSalePrice) - Number(discount));

  let netAmountItem = (Number(row.quantityToInvoice * row.anItemSalePrice)+Number(taxValue) - Number(discount));
  if(withoutTax){
    return fixedDecimalNumber(netAmountItemwithoutTax)
  }else{
      return fixedDecimalNumber(netAmountItem);
  }

}
const getDiscountAmount = (row, toReturn) => {
  if (!row || !row.discountAmount) return;
  if (row?.discountAmount) {
    return  toReturn === "amount"? row?.discountAmount :  "(" + row?.discountPercentage + "%)"
    }
};
const getTotalTaxPercent = (row, toReturn, isPlaceOfSupplySameAsRelationship) => {
  let tax1 = row.customerInvoiceCOATxList.find(tx => (tx.txType === 'tax')) || {};
  let totalPercent = Number(tax1.amountPercent || 0);
  if (toReturn === 'amountPercent') {
      return isPlaceOfSupplySameAsRelationship ? totalPercent ? ('(' + totalPercent * 2 + "%)") : '' : totalPercent ? ('(' + totalPercent + "%)") : '';
  }
  else {
      return isPlaceOfSupplySameAsRelationship ? totalPercent : totalPercent;
  }
}

const getTxTypeData = (row, type, toReturn) => {
  if (!row || !row.customerInvoiceCOATxList) return;
  let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type));
  if (data) {
      if (data[toReturn] && toReturn === 'amountPercent') {
          if (type === 'VAT') {
              return "VAT(" + data[toReturn] + "%)";
          }
          return "(" + data[toReturn] + "%)";
      } else if (data[toReturn] && toReturn === 'amount') {
          return fixedDecimalNumber(data[toReturn]);
      } else {
          return ""
      }

  } else if (!data && toReturn === 'amount') {
      return "";
  }
  else {
      return "-";
  }
}

const getTotalTaxAmount = (row, isPlaceOfSupplySameAsRelationship) => {
  let tax = getTxTypeData(row, 'tax', 'amount', true) || 0;
  let totalTax = Number(tax);
  if (isPlaceOfSupplySameAsRelationship) {

      return totalTax * 2;
  } else {
      return totalTax
  }

}
const getVariantFragment = (item) => {
  let itemName = null;
  if (item.itemName) {
      itemName = item.itemName;
  }
  if (item.itemVariantName) {
      itemName = itemName + ' (' + item.itemVariantName + ')'
  }
  return itemName ? itemName : '';
}

const getDescriptionFragment = (salesInvoiceDetail, item, index) => {
  if (!item) return <Fragment />;
  const fragmentObj = <Fragment>
      {
          item.materialNumber
              ? <div>
                  <span className="title"> Material # : </span>{item.materialNumber}
                  <br></br>
                  <br></br>
              </div> :
              ''
      }
      {
          item.stockNumber
              ? <div>
                  <span className="title"> Stock # : </span>{item.stockNumber}
                  <br></br>
                  <br></br>
              </div> :
              ''
      }
      {
          item.partNumber
              ? <div>
                  <span className="title"> Part # : </span>{item.partNumber}
                  <br></br>
                  <br></br>
              </div> :
              ''
      }
      {
          item.description
              ? <div>
                  {ReactHtmlParser(item.description || '')}
              </div> :
              ''
      }
      {
          item.origin
              ? <div>
                  <br></br>
                  <span className="title"> Origin : </span>{item.origin}
              </div> :
              ''
      }
      {
          item.hsCode
              ? <div>
                  <span className="title"> HS Code : </span>{item.hsCode}
                  <br></br>
                  <br></br>
              </div> :
              ''
      }
      {
          item.warehouse
              ? <div>
                  <span className="title"> Warehouse Name : </span> <span style={{ 'display': 'inline-block' }}>{ReactHtmlParser(item.warehouse || '')} </span>
                  <br></br>
                  <br></br>
              </div> :
              ''
      }
      {
          (salesInvoiceDetail.customerPONumber)
              ? <div> (
                  {salesInvoiceDetail.customerPONumber ? <span className="title">PO {salesInvoiceDetail.customerPONumber} - </span> : ''}
                  {index ? <span className="title"> Line {index}</span> : ''}
                  )
              </div> :
              ''
      }
  </Fragment>
  return fragmentObj;
}
const getSpecialDiscount = (row, toReturn) => {
  if (!row) return;
  let specialDiscount = row.basePrice - row.anItemSalePrice;
  if (specialDiscount && row.basePrice) {
      if (toReturn === 'amountPercent') {
          let percent = fixedDecimalNumber((specialDiscount * 100) / row.basePrice);
          let amount = fixedDecimalNumber(specialDiscount)
          return amount + " (" + percent + ")%";
      } else if (toReturn === 'amount') {
          return fixedDecimalNumber(specialDiscount)
      }

  } else if (!row && toReturn === 'amount') {
      return 0;
  }
  else {
      return "-";
  }
}
const getDiscountType = (row, type) => {
  if (!row || !row.customerInvoiceCOATxList) return;
  let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type)) || {};
  if (!data || !data.discountType) {
      return "-";
  }
  else {
      return data.discountType;
  }
}
export default ItemDetails;
