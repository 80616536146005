import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchEmailAddress = (payload,) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
    dispatch({ type: COMMON_ACTIONS.EMAIL_ADDRESS_LIST, data: [] });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllEmailAddresses?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || 0}&supplierId=${payload.supplierId || 0}&targetId=0&leadId=0`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        dispatch({ type: COMMON_ACTIONS.EMAIL_ADDRESS_LIST, data: res.data });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
      })
  }
}

export const loadEmailData = (payload) => {
  let dataPayload = { relationshipId: (payload.props.companyInfo || {}).relationshipId };
  dataPayload.docName = payload.state.docName || 'Sales Invoice';

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailTemplate/getEmailTemplate?docName=${dataPayload.docName}&relationshipId=${dataPayload.relationshipId}`)
      .then(res => {
        if (res.data) {
          payload.setState({ subject: res.data.subject, emailBody: res.data.content, isEmailContentLoaded: true });
        }
        // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
      })
      .catch(err => {
        // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
      })
      .finally(() => {
        payload.setState({ isEmailContentLoaded: true });
      });
  }
}

export const sendBulkEmail = (payload, props, state) => {
  // let formData = new FormData();
  // formData.append('payload', JSON.stringify(payload));

  // if (state.attachments && Array.isArray(state.attachments.fileList) && state.attachments.fileList.length) {
  //   state.attachments.fileList.forEach(fileObj => {
  //     const file = new Blob([fileObj.originFileObj], { type: fileObj.type });
  //     formData.append('files', file, fileObj.name);
  //   })
  // }
  return (dispatch) => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
      return axios
      // .post(`${config.API_BASE_URL}${config.API_VERSION}alpide/emailManager/emailInvoice`,
      //   formData,
      //   { headers: { 'Content-Type': 'multipart/form-data' } }
      // )
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/alpide/emailManager/emailInvoice`,
        payload
    )
      .then((response) => {
        if (props) {
          //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          // props.updateHeaderState({
          //     communication: {
          //       emailDetailDrawerVisible: false,
          //         message: '',
          //         previewUrl: '',
          //         selectedInvoiceList: [],
          //         selectedStudentList: [],
          //     },
          //     selectedInvoiceList: [],
          //     selectedStudentList: [],
          //     emailDetailDrawerVisible: false,
          // });
          props.updateState({
            emailDetailDrawerVisible: false,
            selectedInvoiceList: [],
            selectedStudentList: [],
          })
          // props.showModal({
          //   title : props.intl.formatMessage({ id: 'invoice.student.bulkEmail.title' }),
          //   modalBody: props.intl.formatMessage({ id : 'invoice.student.bulkEmail.msg' }),
          //   hideFooter: true,
          //  })
        }
        // showToasterMessage({ messageType: 'success', description: 'Message has been sent successfully' });
      })
      .catch((err) => {

        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const sendEmailByStatus = (payload, props, state) => {
  // let formData = new FormData();
  // formData.append('payload', JSON.stringify(payload));
 
  // if (state.attachments && Array.isArray(state.attachments.fileList) && state.attachments.fileList.length) {
  //   state.attachments.fileList.forEach(fileObj => {
  //     const file = new Blob([fileObj.originFileObj], { type: fileObj.type });
  //     formData.append('files', file, fileObj.name);
  //   })
  // }
  return (dispatch) => {
    return axios
    //   .post(`${config.API_BASE_URL}${config.API_VERSION}alpide/emailManager/email`,
    //   formData,
    //   { headers: { 'Content-Type': 'multipart/form-data' } }
    // )
    .post(
      `${config.API_BASE_URL}${config.API_VERSION}/alpide/emailManager/email`,
      payload
  )
      .then((response) => {
        if (props) {
          // props.updateHeaderState({
          //     communication: {
          //       emailDetailDrawerVisible: false,
          //         message: '',
          //         previewUrl: '',
          //         selectedInvoiceList: [],
          //         selectedStudentList: [],
          //     },
          //     selectedInvoiceList: [],
          //     selectedStudentList: [],
          //     emailDetailDrawerVisible: false,
          // });
          props.updateState({
            emailDetailDrawerVisible: false,
            selectedInvoiceList: [],
            selectedStudentList: [],
          })
        }
        showToasterMessage({ messageType: 'success', description: 'Message has been sent successfully' });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const sendAssignmentEmail = (payload, props, state) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/alpide/emailManager/emailAssignment`,
        payload
      )
      .then((response) => {
        if (props) {
          props.updateState({
            emailDetailDrawerVisible: false,
          })
        }
        showToasterMessage({ messageType: 'success', description: 'Mail has been sent successfully' });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const getStudentByClassId = (payload, ref) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentClassRef/getStudentByClassId?classId=${payload.classId}&relationshipId=${payload.relationshipId}&orgRegistrationId=${payload.orgRegistrationId}`)
      .then(res => {
        if (res.data) {
          ref.setState({ asmStudentList: res.data});
        }
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
      })
  }
}