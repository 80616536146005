import React, {useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Row, Col} from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
// import { Checkbox } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextBox } from '../general/TextBox';
import { CustomButton } from '../general/CustomButton';
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'
import { STORAGE_CONSTANTS } from './../../static/constants';
import { ErrorMsg } from '../general/ErrorMessage';
import { validateEmail, addToLocalMemory, deleteFromLocalMemory } from '../../utils';
import './login.scss';
import config from '../../config/environmentConfig';

function LoginComponent(props) {
  const {
    intl, updateState,
    username, usernameClass, usernameInvalidClass,
    password, passwordClass
  } = props;

  const [passType, setPassType] = useState('password');
  // const [showDetail,setShowDetail] = useState(true);

  // const handleToggle = () => {
  //   setShowDetail(!showDetail);
  // };
  

  return (
    <div className='login-wrapper'>
      <div className="login-inner-wrapper">
        <div className='login-panel'>
          {/* <div className="left-text">
            <h1><FormattedMessage id='1' defaultMessage='' />
            <FormattedMessage id='login.text2' defaultMessage='' /></h1>
            <p><FormattedMessage id='login.text3' defaultMessage='' />
            </p>
          </div> */}
          <Form
              layout='vertical'
              className='login-form'
              onSubmit={(e) => { e.preventDefault(); handleLogIn(props); }}
          >
            <h4>
              <FormattedMessage id='signin.text' defaultMessage='' />
            </h4>
            <div className='sub-heading'><FormattedMessage id='signin.subheading.text' defaultMessage='' /></div>

            <Row className='input-label pt25 pb25'>
                
                <Col span={24}>
                  <TextBox
                      placeholder={intl.formatMessage(
                          { id: 'login.email.placeholder', defaultMessage: '' }
                      )}
                      type='text'
                      value={username}
                      className={usernameClass || 'login-form-input'}
                      invalidClassName={usernameInvalidClass || 'login-form-input-error'}
                      validator={(val) => { return validateEmail(val); }}
                      height = '20px'
                      onChange={(e) => {
                        updateState({
                          username: e.target.value,
                          usernameClass: 'login-form-input'
                        });
                      }}
                  />
                  
                <ErrorMsg
                  validator={() => { return !props.submittedOnce  || !username || validateEmail(username) }}
                  message={<FormattedMessage id='login.email.invalidEmail' defaultMessage='' />}
                />
                <ErrorMsg
                  validator={() => { return !props.submittedOnce  || !!username }}
                  message={<FormattedMessage id='login.email.requiredEmail' defaultMessage='' />}
                />
                </Col>
            </Row>
            <Row className='pb25'>

              <Col span={24}>
                <TextBox
                    placeholder={intl.formatMessage(
                        { id: 'login.password.placeholder', defaultMessage: '' }
                    )}
                    type={passType}
                    className={passwordClass}
                    value={password}
                    validator={(val) => { return !!val }}
                    onChange={(e) => {
                      updateState({
                        password: e.target.value,
                        passwordClass: 'login-form-input'
                      });
                    }}
                />
                <span className='password-icon' onClick={()=>{setPassType(passType === 'text' ? 'password' : 'text')}}>
                  {passType === 'password' ? <EyeInvisibleOutlined /> : <EyeOutlined /> }
                </span>
                
                <ErrorMsg
                  validator={() => { return !props.submittedOnce  || !!password }}
                  message={<FormattedMessage id='login.email.requiredPassword' defaultMessage='' />}
                />
              </Col>
            </Row>
            <Row className="caps-lock-msg-container">
              <Col span={12} className="caps-lock-msg">
              <ReactIsCapsLockActive>
                  {active => active ? <span>
                    <i className="fa fa-warning"/>
                    <FormattedMessage id='login.capsLockIsOn' defaultMessage='' />
                  </span>: ""}
                </ReactIsCapsLockActive>
              </Col>
           
            {/* <Form.Item className="remember-checkbox">
              <Checkbox
                  className='login-form-checkbox'
                  checked={!!rememberMe}
                  onClick={() => { updateState({ rememberMe: !rememberMe }); }}
              >
                <FormattedMessage id='login.remember' defaultMessage='' />
                <Tooltip overlayClassName="remember-me-tooltip" placement="top" title="When you select the Remember Username checkbox, we’ll remember your username so that when you come back, you’ll have to enter only your password to sign in again.">
                  <i className="fa fa-question-circle pl5"/>
                </Tooltip>
              </Checkbox>
            </Form.Item> */}
            {/* <Form.Item>
                <Col span={24}>
                  <div className="link-url  " style={{ fontSize:'12px', textAlign:'right', marginTop:'-5px', paddingBottom:'0px'}}>
                    <a href='/app/forgotpassword'>
                      <FormattedMessage id='login.forgot' defaultMessage='' />
                    </a>
                  </div>
                </Col>
            </Form.Item> */}

            </Row>
            <Row>
            </Row>
            <Col span={24}>
              <CustomButton
                type='primary'
                htmlType='submit'
                className='login-form-submit'
                intlId='login.submit'
                defaultMessage=''
                loading={props.isLoading}
                disabled={props.isLoading}
              />
            </Col>

            {/* <Col span={24}  className='pt10'>
                By Signing in, you agree to our <a href='https://alpide.com/privacy.html' target='_blank'  style={{color:'#067bd4', cursor:'pointer' }}> Terms  & Conditions </a>  
                 and acknowledge the <a href='https://alpide.com/privacy.html'  style={{color:'#067bd4', cursor:'pointer'}}>privacy policy </a>

            </Col> */}
            <div className="link-url text-center pt15">
              <a href='/app/forgotpassword'>
                <FormattedMessage id='login.forgot' defaultMessage='' />
              </a>
            </div>
          </Form>

          <div className='no-account-signup'>Don't have an account? <span><a className='link-url' href={config.UI_BASE_URL === 'https://uat.alpide.com/app/' ? 'https://uat.alpide.com/price-table.html' : config.UI_BASE_URL === 'https://alpide.com/app/' ? 'https://alpide.com/price-table.html' : '/app/signup'} rel="noopener noreferrer">Sign Up Now</a></span></div>
        </div>
      <div className='text-center' style={{fontSize: '12px'}}>© 2023, {window.location.host}. All Rights Reseved.</div>
      </div>
    </div>
  );
}

const handleLogIn = (payload) => {
  payload.updateState({submittedOnce: true});
  if (payload.username && payload.password && validateEmail(payload.username)) {
    if (payload.rememberMe) {
      addToLocalMemory(STORAGE_CONSTANTS.LOGIN_EMAIL, payload.username);
      addToLocalMemory(STORAGE_CONSTANTS.LOGIN_PASSWORD, payload.password);
      addToLocalMemory(STORAGE_CONSTANTS.REMEMBER_ME, payload.password);
    } else {
      deleteFromLocalMemory(STORAGE_CONSTANTS.LOGIN_EMAIL);
      deleteFromLocalMemory(STORAGE_CONSTANTS.LOGIN_PASSWORD);
      deleteFromLocalMemory(STORAGE_CONSTANTS.REMEMBER_ME);
    }
    payload.verifyCredentials({ email: payload.username, password: payload.password }, payload.history, payload);
  } else {
    if (!validateEmail(payload.username)) {
      payload.updateState({
        usernameClass: 'login-form-input-error input-error',
        usernameInvalidClass: 'login-form-input-error input-error'
      });
    } else {
      payload.updateState({ usernameClass: 'login-form-input' });
    }
    if (!payload.password) {
      payload.updateState({ passwordClass: 'input-error' });
    }
  }
}

export default injectIntl(LoginComponent);
