import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GradeComp from "../../../components/schoolManagement/grade";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getGrade, getGradeCount, saveGrade, deleteGrade, resetPaginatedData } from "./action";
import { showModal, hideModal } from './../../../actions/commonActions';

class School extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded("getGrade", "gradeList", this.props, payload);
    fetchDataIfNeeded("getGradeCount", "gradeCount", this.props, payload);
  }

  render() {
    return (
      <GradeComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></GradeComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    gradeList: state.school.gradeList,
    gradeCount: state.school.gradeCount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGrade, 
      getGradeCount, 
      deleteGrade,
      saveGrade,
      resetPaginatedData,
      showModal,
      hideModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(School);
