import axios from 'axios';
import { COMMON_ACTIONS } from "../../../../../static/constants";
import config from '../../../../../config/environmentConfig';



export const getStudentsEducation = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/education/getByCustomerId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: COMMON_ACTIONS.STUDENT_EDUCATION_LIST, data });
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = COMMON_ACTIONS.STUDENT_EDUCATION_LIST;
          }
        })
    }
  }
