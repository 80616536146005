import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Button, Col } from "antd";
import "./index.scss";
import { getUserDefinedName } from "../../../../../utils";
import { TextBox } from "../../../../general/TextBox";

const AssignRollNoComp = (props) => {
  const {
    studentList = []
  } = props;

  const handleSubmit = () =>{
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      studentList: studentList.map(obj=>{return {schoolStudentId: obj.schoolStudentId, rollNo: Number(obj.rollNo)}})
    }
    props.saveOrUpdateRollNo(payload, props);
  }



  return (
    <>
      <Row gutter={24} style={{borderBottom: '1px solid #e7e7e7', marginBottom: '10px'}}>
        <Col span={8}>
          <div className="b-text">Student</div>
        </Col>
        <Col span={8}>
          <div className="b-text">{getUserDefinedName('Class', props)}</div>
        </Col>
        <Col span={8}>
          <div className="b-text">Roll No.</div>
        </Col>
      </Row>
        {studentList.map((student, i)=>{
          return <Row gutter={[16, 8]} key={i}>
            <Col span={8}>
              {student.studentName}
            </Col>
            <Col span={8}>
              {student.className || '-'}
            </Col>
            <Col span={8}>
              <TextBox
                type='number'
                placeholder={"Enter Roll No."}
                value={student.rollNo || ''}
                onChange={(e) => {
                  let studentObj = {...student, rollNo: e.target.value};
                  let tempList = studentList;
                  tempList[i] = studentObj;
                  props.updateModalState({studentList: tempList})
                }}
              />
            </Col>
          </Row>
        })}
      <hr className="mt10" style={{marginLeft: '-25px', marginRight: '-25px'}}></hr>
      <Row justify="space-between" className="mt10">
        <Button
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={() => {
            handleSubmit()
          }}
        >
          <FormattedMessage id="button.submit.label" defaultMessage="" />
        </Button>
      </Row>
    </>
  );
};

export default AssignRollNoComp;
