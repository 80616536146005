import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";

import { ErrorMsg } from "../../general/ErrorMessage";
// import ProductDetailsTab from "./ProductDetailsTab";



import "@ant-design/compatible/assets/index.css";

import { Col, Row} from "antd";
import { checkCodeRanges } from "../../general/stateCode";
import { Dropdown } from "../../general/Dropdown";
import find from "lodash.find";
import { MODAL_TYPE } from "../../../static/constants";

const SupplierDetails = (props) => {

  

  return (
    <div>
      <div>
        <div>
          <Row gutter={[16, 8]}>
          <Col span={12} className="drawer-label">
              <FormattedMessage id="Legal Name" defaultMessage="" />
              <span className="required">*</span>
            </Col>
            <Col span={12} className="drawer-label">
              <FormattedMessage id={`My GSTIN (${props.companyInfo?.storeName})`} defaultMessage="" />
              <span className="required">*</span>
            </Col>

           
          </Row>

          <Row gutter={[16, 16]}>
          <Col span={12}>
              <TextBox
                placeholder={"Name"}
                type="text"
                maxLength={20}
                value={props.supplierName}
                onChange={(e) => {
                  props.updateState({
                    supplierName: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.supplierName;
                }}
                message={"Required Field"}
              />
            </Col>
            <Col span={12}>
            <Dropdown
                
                items={props.userGstVal || []}
                valueKeyName="taxIdentificationNumber"
                optionKeyName="taxIdentificationNumber"
                value={(props.supplierGstin || {}).taxIdentificationNumber}
                placeholder={"GSTIN of User"}
                onSelect={(e) => {
                  const doc = find(props.userGstVal, { taxIdentificationNumber: e }) || {};
                  props.updateState({
                    supplierGstin: doc,
                  });
                }}
                canAddNew={true}
                onAddNewClick={(payload) => {
                  
                  const formData = {
                    
                  };
                  const { showModal, companyInfo } = props;
                  formData.relationshipId = companyInfo.relationshipId;
                  formData.isGstin = true;
                  formData.callback = function (taxIdentification) {
                    props.addRelationshipTaxIdentification(formData);
                    return;
                  };
                  const data = {
                    title: (
                      <FormattedMessage id="addItem.text.taxIdentification" />
                    ),
                    formData,
                    hideFooter: true,
                    modalData: {
                      modalType: MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                      data: { formData },
                    },
                    handleSubmit: (formData = {}) => {
                      data.formData.submittedOnce = true;
                      props.hideModal(data);
                    },
                  };
                  showModal(data);
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.supplierGstin;
                }}
                message={"Required Field"}
              />
            </Col>

         
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Address 1" defaultMessage="" />
              <span className="required">*</span>
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage id="Location" defaultMessage="" />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Address"}
                type="text"
                value={props.supplierAddress}
                onChange={(e) => {
                  props.updateState({
                    supplierAddress: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.supplierAddress;
                }}
                message={" Address is required"}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"Location"}
                type="text"
                maxLength={20}
                value={props.supplierLocation}
                onChange={(e) => {
                  props.updateState({
                    supplierLocation: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.supplierLocation;
                }}
                message={" Location is required"}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id=" State code" defaultMessage="" />
              <span className="required">*</span>
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage id=" Pincode" defaultMessage="" />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"State Code"}
                type="text"
                value={props.supplierStateCode}
                onChange={(e) => {
                  props.updateState({
                    supplierStateCode: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.supplierStateCode;
                }}
                message={"State Code is required"}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"PinCode"}
                type="text"
                maxLength={6}
                minLength={6}
                value={props.supplierPincode}
                onChange={(e) => {
                  props.updateState({
                    supplierPincode: e.target.value,
                  });
                }}
              />
              {

                (props.supplierGstin?.taxIdentificationNumber && props.supplierPincode && checkCodeRanges(props.supplierGstin?.taxIdentificationNumber ,props.supplierPincode ,false)) ? "":  ((props.supplierGstin?.taxIdentificationNumber &&props.supplierPincode) ? <span className="error"> Invalid Pincode</span>:"")
                } 
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.supplierPincode;
                }}
                message={"Pincode is required"}
              />
            </Col>
          </Row>
        </div>
      </div>

      {props.optionalFields ? (
        <div className="mt20">
          <div className="drawer-heading">Optional Details</div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage id="Trade Name" defaultMessage="" />
              </Col>

              <Col span={12} className="drawer-label">
                <FormattedMessage id=" Address 2" defaultMessage="" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  maxLength={20}
                  placeholder={"Trade Name"}
                  type="text"
                  value={props.supplierTradeName}
                  onChange={(e) => {
                    props.updateState({
                      supplierTradeName: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col span={12}>
                <TextBox
                  placeholder={"Address 2"}
                  type="text"
                  maxLength={20}
                  value={props.supplierAddress2}
                  onChange={(e) => {
                    props.updateState({
                      supplierAddress2: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage id="District" defaultMessage="" />
              </Col>

              <Col span={12} className="drawer-label">
                <FormattedMessage id=" Phone No." defaultMessage="" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  placeholder={"District"}
                  type="text"
                  maxLength={20}
                  value={props.supplierDistrict}
                  onChange={(e) => {
                    props.updateState({
                      supplierDistrict: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col span={12}>
                <TextBox
                  placeholder={"Phone No."}
                  type="text"
                  maxLength={20}
                  value={props.supplierPhone}
                  onChange={(e) => {
                    props.updateState({
                      supplierPhone: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage id="Email" defaultMessage="" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  maxLength={20}
                  placeholder={" Email"}
                  type="text"
                  value={props.supplierEmail}
                  onChange={(e) => {
                    props.updateState({
                      supplierEmail: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default injectIntl(SupplierDetails);
