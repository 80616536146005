import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TasksDrawerCompo from '../../../../components/drawer/customer/details/tasksDrawer';
import { showModal, hideModal, pushModalToStack, popModalFromStack,  addActivityStatus, deleteActivityStatus,
    addActivityPriorities, deleteActivityPriorities, addActivityTypes, deleteActivityTypes, fetchAllEmployees } from '../../../../actions/commonActions';
 import {
    fetchAllActivityPriorities, fetchAllActivityStatus, fetchAllActivityTypes, createUpdateActivity
 } from './action';

class CustomerTaskDrawer extends Component {
    constructor(props){
      super(props);
      this.state ={
        activityData: props.activityData || {}
      }
    }
    componentDidMount() {
      const companyInfo = this.props.companyInfo || {};
      const payload = {
        relationshipId: companyInfo.relationshipId,
        customerId: this.props.customerId
      };
      this.props.fetchAllEmployees(payload);
      this.props.fetchAllActivityPriorities(payload);
      this.props.fetchAllActivityStatus(payload);
      this.props.fetchAllActivityTypes(payload);
    }
  
    render() {
      return (
        <TasksDrawerCompo {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data)}}/>
      )
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      activityPriorities: state.customer.activityPriorities,
      activityStatus: state.customer.activityStatus,
      activityTypes: state.customer.activityTypes,
      allEmployee: state.common.employees,
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllActivityPriorities,
    fetchAllActivityStatus,
    fetchAllActivityTypes,
    createUpdateActivity,
    pushModalToStack,
    popModalFromStack,
    showModal,
    hideModal,
    addActivityStatus, 
    deleteActivityStatus, 
    addActivityPriorities, 
    deleteActivityPriorities, 
    addActivityTypes, 
    deleteActivityTypes,
    fetchAllEmployees
  }, dispatch);
  
  export default connect(mapStateToProps, mapDispatchToProps)(CustomerTaskDrawer);
  