import React from "react";
import { Row, Col } from 'antd'
import { Dropdown } from "../../general/Dropdown";
import * as find from 'lodash.find';
import { FormattedMessage, injectIntl } from "react-intl";


const LedgerAccounts = (props) => {
    return (
        <div className="step-details-box ledger-form">
            <div className="heading">
                <FormattedMessage id='company.companySetup.ledgerAccounts.title' defaultMessage=' ' />
                <div className="modal-sub-header">
                    <FormattedMessage id='company.companySetup.ledgerAccounts.defaultLedger' defaultMessage=' ' />
                </div>
            </div>

            <div className="step-form-body">
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.sales' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.salesLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const salesLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    salesLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.salesLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.purchase.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.purchaseLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const purchaseLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    purchaseLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.purchaseLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>


                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.debtors.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.debtorsLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const debtorsLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    debtorsLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.debtorsLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.creditors.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.creditorsLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const creditorsLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    creditorsLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.creditorsLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label"> 
                            <FormattedMessage id='company.companySetup.ledgerAccounts.customerDiscount.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.customerDiscountLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const customerDiscountLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    customerDiscountLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.customerDiscountLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.salesDiscount.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.supplierDiscountLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const supplierDiscountLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    supplierDiscountLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.supplierDiscountLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label"> 
                            <FormattedMessage id='company.companySetup.ledgerAccounts.salesOutputTax.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.salesOutputTaxLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const salesOutputTaxLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    salesOutputTaxLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.salesoutputTaxLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.purchaseInputTax.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.purchaseInputTaxLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const purchaseInputTaxLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    purchaseInputTaxLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.purchaseInputTaxLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label"> 
                            <FormattedMessage id='company.companySetup.ledgerAccounts.creditMemo.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.creditMemoLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const creditMemoLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    creditMemoLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.creditMemoTaxLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.debitMemo.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.debitMemoLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const debitMemoLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    debitMemoLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.debitMemoLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.customerExpense.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.customerExpenseLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const customerExpenseLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    customerExpenseLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.customerExpenseLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.supplierExpense.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.supplierExpenseLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const supplierExpenseLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    supplierExpenseLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.supplierExpenseLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.paymentRecievedLedger.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.paymentRecieveLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const paymentRecieveLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    paymentRecieveLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.paymentRecieveLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.paymentMadeLedger.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.paymentMadeLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const paymentMadeLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    paymentMadeLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.paymentMadeLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>


                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.roundingOffLedger.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.roundingOffLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const roundingOffLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    roundingOffLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.roundingOffLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <div className="i-label">
                            <FormattedMessage id='company.companySetup.ledgerAccounts.couponLedger.title' defaultMessage='' />
                        </div>
                        <Dropdown items={props.allLedgerAccounts}
                            valueKeyName='ledgerAccountName'
                            optionKeyName='chartOfAccountDetailsId'
                            value={(props.couponLedgerAccount || {}).ledgerAccountName || ''}
                            onSelect={(optionKey) => {
                                const couponLedgerAccount = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                props.updateState({
                                    couponLedgerAccount
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.ledgerAccounts.couponLedger.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>

                </Row>

            </div>
        </div>
    )
};

export default injectIntl(LedgerAccounts);