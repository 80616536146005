import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveFundraisingCampaign, fetchRegistrationFormSettingList, doesCampaignCodeExist, getNextTxNumber } from "./action";
import FundraisingCampaignDrawer from "../../../../components/drawer/fundraising/campaign";
import { pushModalToStack, popModalFromStack, showModal, hideModal, fetchProjects } from './../../../../actions/commonActions';
import { getFundraisingCampaign, getFundraisingCampaignCount } from '../../../fundraising/campaign/action';
import { fetchAvailablePaymentGateway } from '../../../integrations/Payment/action';
import { fetchAllLedgerAccounts } from '../../../finance/LedgerAccounts/action';

class FundraisingCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.campaignRowData,
      isActive: !props.campaignRowData ? 'Active' : (props.campaignRowData.isActive ? 'Active' : 'Inactive'),
      startDate: (props.campaignRowData && props.campaignRowData.startDate) ? new Date(props.campaignRowData.startDate) : new Date(),
      endDate: (props.campaignRowData && props.campaignRowData.endDate) ? new Date(props.campaignRowData.endDate) : new Date(),
      fundraisingCampaignId: (props.campaignRowData || {}).fundraisingCampaignId,
      teamMasterList: (props.campaignRowData || {}).teamMasterList
    }
  }

  componentDidMount() {
     const companyInfo = this.props.companyInfo || {};
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: 1,
    //   pageSize: 1000,
    // };
   // fetchPaginationDataIfNeeded("fetchRegistrationFormSettingList", "registrationFormList", this.props, { relationshipId: this.props.companyInfo.relationshipId, pageNumber: 0, pageSize: 100 });
  
   this.props.fetchAvailablePaymentGateway({ relationshipId: companyInfo.relationshipId });
   this.props.fetchProjects({ relationshipId: companyInfo.relationshipId });
   this.props.fetchAllLedgerAccounts({ relationshipId: companyInfo.relationshipId });
   (!this.props.campaignRowData || !this.props.campaignRowData.fundraisingCampaignId) && this.props.getNextTxNumber({ relationshipId: companyInfo.relationshipId });
  }
 

  render() {
    return (
      <FundraisingCampaignDrawer
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></FundraisingCampaignDrawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    registrationFormList: state.settings.registrationFormList,
    availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
    projectList: state.common.projectList,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    campaignCode: state.fundraisingReducer.nextCampaignCode
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveFundraisingCampaign,
      getFundraisingCampaign,
      getFundraisingCampaignCount,
      fetchRegistrationFormSettingList,
      pushModalToStack,
      popModalFromStack,
      fetchProjects, 
      fetchAllLedgerAccounts,
      fetchAvailablePaymentGateway,
      doesCampaignCodeExist,
      getNextTxNumber, 
      showModal, 
      hideModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FundraisingCampaign);
