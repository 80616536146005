import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { RFQ_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchSupplierRFQDetail = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqRest/getRFQsByRFQId?rfqMasterId=${payload.rfqMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`
      )
      .then((res) => {
        const { data } = res;
        dispatch({ type: RFQ_ACTION_LIST.RFQ_DETAIL, data });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      })
      .finally(() => {
        dispatch({ type: COMMON_ACTIONS.HIDE_DETAIL_LOADER });
      });
  };
};

  export const approveRFQ = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqRest/approveRFQ`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          dispatch({ type: RFQ_ACTION_LIST.RFQ_LIST_PAGE_RESET });
          props.fetchAllRFQ({ ...props, relationshipId: payload.relationshipId });
          showToasterMessage({
            description: (res || {}).message || 'Approved successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }