import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DonationPaymentListingComp from '../../../components/fundraising/Donation';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
import { fetchDonationPayments, getDonationPaymentsCount, resetPaginatedData } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../actions/commonActions';
import { FormattedMessage } from 'react-intl';


class DonationListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.fundRaising.campaignPayments' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
        name: <FormattedMessage id='sidebar.menuItem.fundraising' defaultMessage='' />
      },
      {
        name: <FormattedMessage id='sidebar.menuItem.donations' defaultMessage='' />,
      }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25
    };
    fetchPaginationDataIfNeeded('fetchDonationPayments', 'donationPaymentList', this.props, payload);
    fetchDataIfNeeded('getDonationPaymentsCount', 'donationPaymentCount', this.props, payload);
  }

  componentWillReceiveProps(props) {

  }

  render() {
    return (
      <DonationPaymentListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    donationPaymentList: state.fundraisingReducer.donationPaymentList,
    donationPaymentCount: state.fundraisingReducer.donationPaymentCount,
    isLoading: state.common.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDonationPayments, 
  getDonationPaymentsCount,
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DonationListing);
