import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Drawer, Row ,Input,Skeleton} from "antd";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown"
import { getCurrencySymbol } from "../../../../utils";
import { EMPLOYEE_META_CONSTANTS } from "../../../../static/constants";

const AllowanceDrawer = (props) => {
  const { pageNumber } = props;
  const closeDrawer = () => {
    props.updateState({
      allowanceDrawer: false,
    });
  };

  return (
    <Drawer
      title={<FormattedMessage id="drawer.leaveMangement.titleName.allowance" />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.allowanceDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              props.updateDrawerState({ submitted: true })

              const payload = {
                relationshipId: props.companyInfo.relationshipId,
                allowanceId: props.allowanceId,
                allowanceName: props.allowanceName,
                allowanceType: props.allowanceType,
                allowanceRate: props.allowanceRate,
                limitAmount: props.limitAmount,
                createdByUserId: props.createdByUserId,
                updatedByUserId: props.updatedByUserId,
                version: 0

              }

              props.showModal({
                modalBody: "Are you sure want to Submit?",
                handleSubmit: () => {
                  props.createAllowance(payload, props)
                  closeDrawer();
                },
              });
            }
            }


            key={1}
          />
        </>
      }
    >
            <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="i-label">Name
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Allowance Name"
            type="text"
            maxLength={30}
            value={props.allowanceName}
            onChange={(e) => {
              props.updateDrawerState({ allowanceName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.allowanceName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label"><FormattedMessage id="company.payrollManagement.allowanceType" />
            <span className="required">*</span>
          </div>
          <Dropdown
            items = { EMPLOYEE_META_CONSTANTS.ALLOWANCE_TYPES}
            placeholder="Select"
            value={props.allowanceType}
            onSelect={(option) => {
              props.updateDrawerState({
                allowanceType: option,
              });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.allowanceType }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          {/* <div className="i-label"><FormattedMessage id="company.payrollManagement.percentage"/> */}
          <div className="i-label">Allowance Rate
            <span className="required">*</span>
          </div>

          <Input
            // addonBefore={getCurrencySymbol(props.companyInfo.currencyCode)}
            addonBefore={props.allowanceType === "Percentage" ? '%' : getCurrencySymbol(props.companyInfo.currencyCode)}

            style={{ height: '38px' }}
            placeholder={props.allowanceType === "Percentage" ? 'Enter Percentage' : 'Enter Price'}
            type="text"
            maxLength={20}
            value={props.allowanceRate}
            onChange={(e) => {
              props.updateDrawerState({ allowanceRate: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.allowanceRate }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label"><FormattedMessage id="company.payrollManagement.limit" /></div>
          <TextBox
            placeholder={props.intl.formatMessage(
              { id: 'company.payrollManagement.limit', defaultMessage: '' }
            )}
            type="number"
            disabled={props.allowanceType === 'Fixed'}
            maxLength={30}
            value={props.limitAmount}
            onChange={(e) => {
              props.updateDrawerState({ limitAmount: e.target.value });
            }}
          />

        </Col>

      </Row>
      </Skeleton>
    </Drawer>
  );
};

export default injectIntl(AllowanceDrawer);
