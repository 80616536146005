/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Skeleton, Menu, Pagination } from 'antd';
// import { AgGridReact } from 'ag-grid-react';
// import checkIcon from '../../../assets/images/check.png';
// import crossIcon from '../../../assets/images/cross.png';
import PageBreadcrumb from "../../PageBreadcrumb";
import { MoreOutlined } from '@ant-design/icons';
import { sortTable } from '../../../utils';
import DropdownAction from "antd/lib/dropdown";
import { ICONS } from '../../../static/constants';


const CompanyHierarchyListingComp = (props) => {
    
    const { companyHierarchyList, dir=0, updateState ,companyInfo} = props;

    const loadPageData = (pageNumber, pageSize) => {
      const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
      // fetchPaginationDataIfNeeded('fetchSalesOrders', 'soList', props, payload);
  }
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
      const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
      props.resetPaginatedData();
      // props.fetchSalesOrders(payload);
      props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  }
  const showTotal = (total) => {
      return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
  };
//    const onFirstDataRendered = (params) => {
//         params.api.sizeColumnsToFit();
//     }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return  <FormattedMessage id='company.hierarchy.hierarchyName' defaultMessage=' ' />;
    //         },
    //         field: "hierarchyName",
    //         resizable: true
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.hierarchy.hierarchyLevel' defaultMessage=''  />;
    //         },
    //         field: "hierarchyLevel",
    //         resizable: true
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.hierarchy.hierarchyCode' defaultMessage=' ' />;
    //         },
    //         field: "hierarchyCode",
    //         resizable: true
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.hierarchy.description' defaultMessage='' />;
    //         },
    //         field: "hierarchyDescription",
    //         resizable: true
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.hierarchy.rootHierarchy' defaultMessage='' />;
    //         },
    //         field: "isRootHierarchy",
    //         cellRendererFramework: (params) =>{
    //             return params.node.data.isRootHierarchy ? <img src={checkIcon}  style={{ 'height': '15px', 'width': '15px' }} alt="Invoice"/> : <img src={crossIcon}  style={{ 'height': '10px', 'width': '10px' }} alt="Invoice"/>;
    //         },
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.hierarchy.action' defaultMessage='' />;
    //         },
    //         field: "action",
    //         pinned: "right",
    //         lockPosition:true,
    //         cellRendererFramework: (params) => {
    //             return <div>
    //             <span className="cursor-pointer" title={<FormattedMessage id='common.edit.hierarchy' defaultMessage='' />} onClick={
    //                 () => {
    //                     props.updateHeaderState({
    //                         company: {
    //                             ...props.company,
    //                             hierarchyData: {...params.data},
    //                             hierarchyDrawerVisible: true,
    //                         }
    //                     })
    //                 }}>
    //                 <i className='fa fa-pencil' />
    //             </span>
    //                 <span style={{ marginLeft: '12px' }} className="cursor-pointer" title={<FormattedMessage id='common.delete.hierarchy' defaultMessage='' />} onClick={
    //                     () => {
    //                         deleteHandle(params.data);
    //                     }}>
    //                     <i className='fa fa-trash' />
    //                 </span>
    //             </div>;
    //         },
    //         width: 70,
    //     }
    // ];

    const deleteHandle = (hierarchy) => {
        const modalBody =  <FormattedMessage id='modalBody.deleteHierarchy' defaultMessage='' />;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.deleteHierarchy({
                    relationshipId: hierarchy.relationshipId,
                    id: hierarchy.hierarchyId
                })
            },
        };
        props.showModal(modalData);
    }

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };
    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.teamName' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.orgHierarchy' defaultMessage='' />,
        }
    ];
    const actionMenu = (e) => {
        return (
          <Menu className="row-action-dropdown">
            <Menu.Item key="0" value="title">
              Actions
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item
              key="1"
              value="edit"
              onClick={() => {
                props.updateHeaderState({
                  company: {
                    ...props.company,
                    hierarchyData: { ...e },
                    hierarchyDrawerVisible: true,
                  },
                });
              }}
            >
              <i className={ICONS.EDIT} />
              Edit
            </Menu.Item>

            <Menu.Item
              key="2"
              onClick={() => {
                deleteHandle(e);
              }}
            >
              <i className={ICONS.DELETE} />
              Delete
            </Menu.Item>
          </Menu>
        );
      };
    const sortColumn = (e) => {
        sortTable(document.getElementById('project-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
      }

    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="heading.organization.orgHeirarchy"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={() => {
                props.updateHeaderState({
                  company: {
                    ...props.company,
                    hierarchyDrawerVisible: true,
                  },
                });
              }}
            >
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>

        <div className="view-container">
          <Skeleton loading={props.isLoading}>
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="organization.orgHierarchy.table.heading"
                    defaultMessage=""
                  />
                </div>
                {/* {pageSize ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{pageSize}</div>
                  </>
                ) : (
                  ""
                )} */}
              </div>
              <div className="right-actions"></div>
            </div>
            <div className="table-container">
              <table id="hierarchy-table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="company.hierarchy.hierarchyName"
                        defaultMessage=" "
                      />
                      <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="company.hierarchy.hierarchyLevel"
                        defaultMessage=""
                      />
                      <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="company.hierarchy.hierarchyCode"
                        defaultMessage=" "
                      />
                      <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="company.hierarchy.description"
                        defaultMessage=""
                      />
                      <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage
                        id="company.hierarchy.rootHierarchy"
                        defaultMessage=""
                      />
                      <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th className='text-center'>
                      <FormattedMessage
                        id="company.hierarchy.action"
                        defaultMessage=""
                      />
                    
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companyHierarchyList &&
                    companyHierarchyList.sort((a,b)=>a.rowDatahierarchyId < b.rowDatahierarchyId ? 1:-1).map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{rowData.hierarchyName}</td>
                          <td>{rowData.hierarchyLevel}</td>
                          <td>{rowData.hierarchyCode}</td>
                          <td>{rowData.hierarchyDescription}</td>
                          <td>{rowData.isRootHierarchy}</td>
                          <td className='text-center'>
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <MoreOutlined />
                            </DropdownAction>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="footer">
                <div className="f-left"></div>
                <div className="f-right">
                    <Pagination
                        size="small"
                        total={1}
                        showTotal={showTotal}
                        defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber || 1, pageSize, props)
                        }}
                    />
                </div>
            </div>

            {/* <div className="agGridWrapper">
              <div
                className="ag-theme-balham customer-details-table"
                style={agGridStyle}
              >
                <AgGridReact
                  columnDefs={columnDefs}
                  defaultColDef={{
                    flex: 1,
                    autoHeight: true,
                    wrapText: true,
                  }}
                  onColumnResized={(params) => {
                    params.api.resetRowHeights();
                  }}
                  rowData={companyHierarchyList || []}
                  rowDragManaged={true}
                  domLayout={"autoHeight"}
                  animateRows={true}
                  onGridSizeChanged={onFirstDataRendered}
                ></AgGridReact>
              </div>
            </div> */}
          </Skeleton>
        </div>
      </>
    );
};



export default injectIntl(CompanyHierarchyListingComp);
