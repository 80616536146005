import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../static/constants'
import { showToasterMessage } from '../../utils';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const resetPasswordMail = (payload ,setSentDone) => {
    const val = {
        "token" : payload.token,
        "newPassword" : payload.newPassword
    }
    const formData =  JSON.stringify(val||'{}')
    
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/resetPassword`,  formData, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }})
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        setSentDone(true)
        showToasterMessage({
          messageType: 'success',
          description: (res || {}).message || 'Password updated successfully'
        })
        
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}