import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as find from "lodash.find";
import * as filter from "lodash.filter";

import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
  addDaysToDate,
} from "../../../../utils";
import PerformaInvoiceComponent from "../../../../components/customer/invoice/StandardPerformaInvoice";
import LedgerDrawer from "../../../drawer/ledgers/SalesInvoiceLedger";
import {
  fetchProducts,
  fetchPaymentTerms,
  fetchPriceList,
  fetchStates,
  fetchProjects,
  fetchTaxes,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchCustomerDetailsByCustomerId,
  addPaymentTerm,
  deletePaymentTerm,
  deleteProject,
  deleteAddress,
  deleteTax,
  deletePriceList,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchCountries,
  fetchRelationshipTaxIdentifications,
  deleteCustomerTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  resetCustomerDetail,
  fetchAddresses,
  getRelationshipAddress,
  resetAddresses,
} from "../../../../actions/commonActions";
import {
  createPerformaInvoice,
  fetchSoDataForInvoice,
  resetSoInvoiceData,
  getNextTxNumber,
  fetchAllUnfulfilledSalesOrderByCustomerId,
  resetUnfulfilledSalesOrder,
  fetchShipmentForInvoice,
  resetShipmentForInvoice,
  createSalesInvoiceDraft,
} from "./action";
import {
  LEDGER_ACTION_LIST,
  CONSTANTS,
  LEDGER_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../static/constants";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../static/agGridConstants";
import SalesInvoiceDetails from "../../../modal/modalBody/customer/SalesInvoiceProformaDetail";
import { fetchCustomers, deleteCustomers } from "../../Listing/action";
import {
  fetchRemarksTemplate,
  resetRemarksData,
} from "../../../modal/modalBody/settings/RemarksTemplate/action";
import TransportDataDrawer from "../../../drawer/transportData/index";
import { fetchAllContacts } from "../../Listing/ShowConact/action";

class SalesInvoice extends Component {
  constructor(props) {
    super(props);
    const defaultSalesLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.SALES,
      }) || {};
    const defaultExpenseLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE,
      }) || {};
    const defaultSalesOutputLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX,
      }) || {};
    const defaultSalesDiscountLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.SALES_DISCOUNT,
      }) || {};
    const defaultDebtorsLedger =
      find(props.companyInfo.defaultCoaLedgerAccountsList, {
        txType: LEDGER_ACTION_LIST.DEBTORS,
      }) || {};
    const companyInfo = props.companyInfo;
    const allRelationshipBillingAddress =
      filter(companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const relationshipBillingAddress = find(allRelationshipBillingAddress, {
      isDefault: 1,
    });
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      invoiceDraftId: props.invoiceDraftId || linkProps.invoiceDraftId || 0,
      invoiceProformaId:
        props.invoiceProformaId || linkProps.invoiceProformaId || 0,
      invoiceDraftData: props.invoiceData || linkProps.invoiceData || {},
      customer: props.customer || linkProps.customer,
      customerName: (props.customer || {}).companyName,
      stateCustomerId: props.customerId,
      pageNumber: props.pageNumber || 1,
      pageSize: 100,
      cols: 20,
      searchedText: "",
      isSoConversionToInvoice: false,
      ledgerDrawerVisible: false,
      invoiceDate: new Date(),
      salesLedgerId: defaultSalesLedger.ledgerAccountId,
      salesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      expenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      expenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      taxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
      discountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      allRelationshipBillingAddress,
      relationshipBillingAddress,
      priceTypeToApply:
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0],
      customerShippingAddress: undefined,
      customerBillingAddress: undefined,
      customerPaymentTerm: undefined,
      customerRef: undefined,
      customerPo: undefined,
      placeOfSupply: undefined,
      customerRemarks: undefined,
      internalRemarks: undefined,
      footer: undefined,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "proforma", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.TAX,
        GRID_PREFERENCE_CONSTANTS.DISCOUNT,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
        "sno",
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === "India",
        basePrice: true,
        specialDiscount: true,
        discount: true,
        tax: true,
        comment: true,
        stockNumber: true,
        partNumber: true,
        origin: true,
        hsCode: true,
        isProformaConversion: true,
      },
      selectedSalesOrder: [],
      customerSalesOrderDetailsList: [{}],
    };
  }

  componentWillReceiveProps(props) {
    if (props.addresses) {
      if (
        props.addresses.BillingAddress &&
        props.addresses.BillingAddress.length
      ) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress,
        });
      }
      if (
        props.addresses.ShippingAddress &&
        props.addresses.ShippingAddress.length
      ) {
        this.setState({
          allShippingAddress: props.addresses.ShippingAddress,
        });
      }
    }

    if (props.customerDetail && props.customerDetail.customerId) {
      const customer = props.customerDetail;
      const allShippingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.SHIPPING_ADDRESS,
          })) ||
        [];
      const defaultShippingAddress =
        find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          })) ||
        [];
      const defaultBillingAddress =
        find(allBillingAddress, { isDefault: 1 }) || {};
      const customerPaymentTerm = props.paymentTerms.length
        ? find(props.paymentTerms, {
            paymentTermId: (customer && customer.paymentTermId) || 0,
          }) || {}
        : {};
      let numberOfDays = customerPaymentTerm.numberOfDays;
      const selectedContact =
        (customer.boContactList || []).find((x) => x.isPrimaryContact) || {};
      let selectedCustomerTaxIdentifications = [];
      const selectedList =
        (customer &&
          filter(customer.customerTaxIdentificationsList, {
            populateOnTransaction: 1,
          })) ||
        [];
      selectedCustomerTaxIdentifications = selectedList.map((obj) => {
        return obj.customerTaxIdentificationId;
      });
      this.setState({
        customer,
        customerName: customer.companyName,
        customerPaymentTerm,
        customerShippingAddress: defaultShippingAddress,
        customerBillingAddress: defaultBillingAddress,
        placeOfSupply:
          defaultShippingAddress.stateName || defaultBillingAddress.stateName,
        invoiceDueDate: numberOfDays
          ? addDaysToDate(props.invoiceDate || new Date(), numberOfDays)
          : null,
        selectedContact,
        allShippingAddress,
        allBillingAddress,
        selectedCustomerTaxIdentifications: selectedCustomerTaxIdentifications,
        selectedSalesOrder: props.preservedSalesOrder || [],
        preservedSalesOrder: [],
        selectedSalesOrderIds: [],
        isShipmentConversion: false,
      });
      props.resetCustomerDetail();
    }

    if (
      !this.state.selectedRelationshipTaxIdentifications &&
      props.allRelationshipTaxIdentificatins &&
      props.allRelationshipTaxIdentificatins.length
    ) {
      const selectedList =
        (props.allRelationshipTaxIdentificatins &&
          filter(props.allRelationshipTaxIdentificatins, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedRelationshipTaxIdentifications = selectedList.map((obj) => {
        return obj.relationshipTaxIdentificationId;
      });
      this.setState({
        selectedRelationshipTaxIdentifications:
          selectedRelationshipTaxIdentifications,
      });
    }

    if (
      props.documentList &&
      props.documentList.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
            CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }
    // if (!this.state.customerRemarks && props.remarksData && props.remarksData.isFetched && !this.state.isRemarksSet) {
    //     this.setState({ customerRemarks: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
    //     setTimeout(() => {
    //         props.resetRemarksData();
    //     }, 500)
    // }
    if (
      props.taxes &&
      props.taxes.length &&
      props.soInvoiceData &&
      props.soInvoiceData.salesOrderMasterId
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.comment
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      };
      props.soInvoiceData.customerSalesOrderDetailsList.map((ele) => {
        const tax =
          find(ele.customerSalesOrderCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};
        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          salesOrderMasterId: ele.salesOrderMasterId,
          soNumber: ele.soNumber,
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantityToInvoice,
          discount: (
            find(ele.customerSalesOrderCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax: tax.amountPercent || 0,
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.comment,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          parentDetailsId: ele.salesOrderDetailsId,
        });
      });

      const rootDiscount =
        (
          find(props.soInvoiceData.customerSalesOrderCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
          }) || {}
        ).amountPercent || 0;
      const expenseAmount =
        (
          find(props.soInvoiceData.customerSalesOrderCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      let selectedContact =
        (props.soInvoiceData.additionalInfoList || [])[0] || {};
      delete selectedContact.additionalInfoId;
      const selectedSalesOrder = [
        { ...props.soInvoiceData, customerSalesOrderDetailsList: detailList },
      ];
      //const forceHideTxColumn = [];
      // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
      //     //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      // }
      // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
      //     //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      // }
      this.setState({
        //isSoConversionToInvoice: true,
        version: props.soInvoiceData.version,
        salesOrderMasterId: props.soInvoiceData.salesOrderMasterId,
        soNumber: props.soInvoiceData.soNumber,
        relationshipBillingAddress: find(props.soInvoiceData.boLocationSOList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }),
        customerShippingAddress: find(props.soInvoiceData.boLocationSOList, {
          locationType: CONSTANTS.SHIPPING_ADDRESS,
        }),
        customerBillingAddress: find(props.soInvoiceData.boLocationSOList, {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }),
        customerPaymentTerm:
          props.paymentTerms.length && props.soInvoiceData.paymentTermId
            ? find(props.paymentTerms, {
                paymentTermId: props.soInvoiceData.paymentTermId,
              }) || {}
            : this.state.customerPaymentTerm,
        selectedContact: selectedContact,
        customerRef: props.soInvoiceData.referenceNumber,
        customerPo: props.soInvoiceData.customerPONumber,
        placeOfSupply: props.soInvoiceData.placeOfSupply,
        customerName: props.soInvoiceData.customerName,
        customer: {
          ...this.state.customer,
          companyName: props.soInvoiceData.customerName,
          customerId: props.soInvoiceData.customerId,
        },
        customerRemarks: props.soInvoiceData.remarksCustomer,
        internalRemarks: props.soInvoiceData.remarksInternal,
        footer: props.soInvoiceData.footer,
        customerSalesOrderDetailsList: detailList,
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount,
        expenseId: props.soInvoiceData.expenseId,
        rootDiscountAmount:
          (
            find(props.soInvoiceData.customerSalesOrderCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amount || 0,
        expenseAmount: expenseAmount,
        projectName: props.soInvoiceData.projectName,
        projectMasterId: props.soInvoiceData.projectMasterId,
        projectNumber: props.soInvoiceData.projectNumber,
        isRootExpense: expenseAmount ? true : false,
        //inheritedTxColumn: inheritedTxColumn,
        //forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        salesQuotationMasterId: props.soInvoiceData.salesQuotationMasterId,
        quotationNumber: props.soInvoiceData.quotationNumber,
        rfqNumber: props.soInvoiceData.rfqNumber,
        rfqMasterId: props.soInvoiceData.rfqMasterId,
        //selectedSalesOrderIds: [props.soInvoiceData.salesOrderMasterId]
      });

      selectedSalesOrder.forEach((soData) => {
        const rootDiscount =
          (
            find(soData.customerSalesOrderCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent || 0;
        const expenseAmount =
          (
            find(soData.customerSalesOrderCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_EXPENSE,
            }) || {}
          ).amount || 0;

        const payload = {};

        payload["expenseAmount" + soData.salesOrderMasterId] = expenseAmount;
        payload["expenseId" + soData.salesOrderMasterId] = soData.expenseId;
        payload["isRootExpense" + soData.salesOrderMasterId] = expenseAmount
          ? true
          : false;
        payload["rootDiscountPercent" + soData.salesOrderMasterId] =
          rootDiscount;
        payload["isRootDiscount" + soData.salesOrderMasterId] = rootDiscount
          ? true
          : false;
        this.setState(payload);
      });

      props.resetSoInvoiceData();
    }
    if (
      props.taxes &&
      props.taxes.length &&
      (this.state.invoiceDraftId || this.state.invoiceProformaId) &&
      this.state.invoiceDraftData
    ) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
            0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (
          inheritedTxColumn.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0 &&
          item.specialDiscount
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 &&
          (
            find(item.customerInvoiceCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 &&
          taxPercent
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 &&
          item.comment
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) <
            0 &&
          item.stocknumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) <
            0 &&
          item.partNumber
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 &&
          item.origin
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 &&
          item.hsCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      };
      this.state.invoiceDraftData.customerInvoiceDetailsList.map((ele) => {
        const tax =
          find(ele.customerInvoiceCOATxList, (ele) => {
            return ele.txType === LEDGER_TYPE.TYPE_TAX;
          }) || {};
        const appliedTax =
          find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          salesOrderMasterId: ele.salesOrderMasterId,
          soNumber: ele.soNumber,
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantityToInvoice,
          discount: (
            find(ele.customerInvoiceCOATxList, {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent,
          tax: tax.amountPercent || 0,
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          quantityOrdered: ele.quantity,
          quantityInvoiced: ele.quantityInvoiced,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.comment,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          parentDetailsId: ele.salesOrderDetailsId,
        });
      });

      const rootDiscount =
        (
          find(this.state.invoiceDraftData.customerInvoiceCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_DISCOUNT,
          }) || {}
        ).amountPercent || 0;
      const expenseAmount =
        (
          find(this.state.invoiceDraftData.customerInvoiceCOATxList || [], {
            txType: LEDGER_TYPE.TYPE_EXPENSE,
          }) || {}
        ).amount || 0;
      let selectedContact =
        (this.state.invoiceDraftData.additionalInfoList || [])[0] || {};
      delete selectedContact.additionalInfoId;
      const selectedSalesOrder = [
        {
          ...this.state.invoiceDraftData,
          customerSalesOrderDetailsList: detailList,
        },
      ];
      // const forceHideTxColumn = [];
      // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
      //     //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      // }
      // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
      //     //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      // }
      this.setState({
        isSoConversionToInvoice: true,
        //salesOrderMasterId: this.state.invoiceDraftData.salesOrderMasterId,
        soNumber: this.state.invoiceDraftData.soNumber,
        invoiceProformaId: this.state.invoiceDraftData.invoiceProformaId,
        relationshipBillingAddress: find(
          this.state.invoiceDraftData.boLocationSalesInvoiceList,
          { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }
        ),
        customerShippingAddress: find(
          this.state.invoiceDraftData.boLocationSalesInvoiceList,
          { locationType: CONSTANTS.SHIPPING_ADDRESS }
        ),
        customerBillingAddress: find(
          this.state.invoiceDraftData.boLocationSalesInvoiceList,
          { locationType: CONSTANTS.BILLING_ADDRESS }
        ),
        customerPaymentTerm:
          props.paymentTerms.length && this.state.invoiceDraftData.paymentTermId
            ? find(props.paymentTerms, {
                paymentTermId: this.state.invoiceDraftData.paymentTermId,
              }) || {}
            : this.state.customerPaymentTerm,
        selectedContact: selectedContact,
        customerRef: this.state.invoiceDraftData.referenceNumber,
        customerPo: this.state.invoiceDraftData.customerPONumber,
        placeOfSupply: this.state.invoiceDraftData.placeOfSupply,
        customerName: this.state.invoiceDraftData.customerName,
        customer: {
          ...this.state.customer,
          companyName: this.state.invoiceDraftData.customerName,
          customerId: this.state.invoiceDraftData.customerId,
        },
        customerRemarks: this.state.invoiceDraftData.remarksCustomer,
        internalRemarks: this.state.invoiceDraftData.remarksInternal,
        footer: this.state.invoiceDraftData.footer,
        // customerSalesOrderDetailsList: detailList,
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount,
        expenseId: this.state.invoiceDraftData.expenseId,
        rootDiscountAmount:
          (
            find(this.state.invoiceDraftData.customerInvoiceCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amount || 0,
        expenseAmount: expenseAmount,
        projectName: this.state.invoiceDraftData.projectName,
        projectMasterId: this.state.invoiceDraftData.projectMasterId,
        projectNumber: this.state.invoiceDraftData.projectNumber,
        isRootExpense: expenseAmount ? true : false,
        //inheritedTxColumn: inheritedTxColumn,
        //forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        salesQuotationMasterId:
          this.state.invoiceDraftData.salesQuotationMasterId,
        quotationNumber: this.state.invoiceDraftData.quotationNumber,
        rfqNumber: this.state.invoiceDraftData.rfqNumber,
        rfqMasterId: this.state.invoiceDraftData.rfqMasterId,
        selectedSalesOrder,
        selectedSalesOrderIds: [this.state.invoiceDraftData.salesOrderMasterId],
      });

      selectedSalesOrder.forEach((soData) => {
        const rootDiscount =
          (
            find(soData.customerInvoiceCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_DISCOUNT,
            }) || {}
          ).amountPercent || 0;
        const expenseAmount =
          (
            find(soData.customerInvoiceCOATxList || [], {
              txType: LEDGER_TYPE.TYPE_EXPENSE,
            }) || {}
          ).amount || 0;

        const payload = {};

        payload["expenseAmount" + soData.salesOrderMasterId] = expenseAmount;
        payload["expenseId" + soData.salesOrderMasterId] = soData.expenseId;
        payload["isRootExpense" + soData.salesOrderMasterId] = expenseAmount
          ? true
          : false;
        payload["rootDiscountPercent" + soData.salesOrderMasterId] =
          rootDiscount;
        payload["isRootDiscount" + soData.salesOrderMasterId] = rootDiscount
          ? true
          : false;
        this.setState(payload);
      });

      //  props.resetSoInvoiceData();
    }
    if (
      this.state.stateCustomerId &&
      props.customers[this.state.pageNumber] &&
      props.customers[this.state.pageNumber].length &&
      props.paymentTerms &&
      props.paymentTerms.length
    ) {
      const customer =
        find(props.customers[this.state.pageNumber], {
          customerId: Number(this.state.stateCustomerId || 0),
        }) || {};
      const allShippingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.SHIPPING_ADDRESS,
          })) ||
        [];
      const defaultShippingAddress =
        find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          })) ||
        [];
      const defaultBillingAddress =
        find(allBillingAddress, { isDefault: 1 }) || {};
      const customerPaymentTerm = props.paymentTerms.length
        ? find(props.paymentTerms, {
            paymentTermId: (customer && customer.paymentTermId) || 0,
          }) || {}
        : {};
      let numberOfDays = customerPaymentTerm.numberOfDays;
      const selectedList =
        (customer &&
          filter(customer.customerTaxIdentificationsList, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedCustomerTaxIdentifications = selectedList.map((obj) => {
        return obj.customerTaxIdentificationId;
      });
      this.setState({
        stateCustomerId: null,
        customer,
        customerName: customer.companyName,
        customerPaymentTerm,
        customerShippingAddress:
          this.state.customerShippingAddress || defaultShippingAddress,
        customerBillingAddress:
          this.state.customerBillingAddress || defaultBillingAddress,
        placeOfSupply: defaultBillingAddress && defaultBillingAddress.stateName,
        invoiceDueDate: numberOfDays
          ? addDaysToDate(props.invoiceDate || new Date(), numberOfDays)
          : null,
        selectedCustomerTaxIdentifications,
        boContactList: customer.boContactList || [],
      });
    }

    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, {
        txType: "proforma",
        templateName: "standard",
      });
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }

    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress,
      });
    } else {
      const allRelationshipBillingAddress =
        filter(props.companyInfo.boLocationList, {
          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || [];
      this.setState({
        allRelationshipBillingAddress,
      });
    }
    if (props.customerContactList && props.customerContactList.length) {
      this.setState({
        boContactList: props.customerContactList,
      });
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      customerId: this.props.customerId || linkProps.customerId,
      soMasterId: this.props.soMasterId || linkProps.soMasterId,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 100,
    };
    this.props.resetSoInvoiceData();
    this.props.resetShipmentForInvoice();
    this.props.resetAddresses();
    payload.soMasterId &&
      fetchDataIfNeeded(
        "fetchSoDataForInvoice",
        "soInvoiceData",
        this.props,
        payload,
        true
      );
    payload.customerId &&
      this.props.fetchAddresses({
        ...payload,
        locationType: CONSTANTS.SHIPPING_ADDRESS,
      });
    payload.customerId &&
      this.props.fetchAddresses({
        ...payload,
        locationType: CONSTANTS.BILLING_ADDRESS,
      });
    payload.customerId &&
      fetchDataIfNeeded(
        "fetchCustomerDetailsByCustomerId",
        "customerDetail",
        this.props,
        payload,
        true
      );
    payload.customerId && this.props.fetchAllContacts(payload);
    fetchDataIfNeeded("fetchPaymentTerms", "paymentTerms", this.props, payload);
    fetchPaginationDataIfNeeded(
      "fetchCustomers",
      "customers",
      this.props,
      { ...payload, isCompact: true },
      true
    );
    fetchDataIfNeeded("fetchPriceList", "priceList", this.props, payload);
    fetchDataIfNeeded("fetchStates", "states", this.props, 1);
    fetchDataIfNeeded("fetchProjects", "projectList", this.props, payload);
    fetchDataIfNeeded("fetchProducts", "products", this.props, payload);
    fetchDataIfNeeded("fetchTaxes", "taxes", this.props, payload);
    fetchDataIfNeeded("fetchUOM", "uomList", this.props, payload);
    fetchDataIfNeeded("fetchExpenseTypes", "expenseTypes", this.props, payload);
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
    fetchDataIfNeeded("fetchCountries", "countries", this.props, payload);
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    if (payload.customerId) {
      this.props.fetchAllUnfulfilledSalesOrderByCustomerId(payload);
    }
    this.props.fetchRemarksTemplate({
      relationshipId: companyInfo.relationshipId,
      docName: "Sales Invoice",
    });
    fetchDataIfNeeded(
      "fetchRelationshipTaxIdentifications",
      "allRelationshipTaxIdentificatins",
      this.props,
      payload
    );
  }

  openSalesInvoiceDetails = (data) => {
    this.props.pushModalToStack({
      modalBody: (
        <SalesInvoiceDetails {...this.props} proformaInvoiceData={data} />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  render() {
    return (
      <div className="new-invoice-modal">
        <LedgerDrawer
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
        {this.state.transportDataDrawerVisible && (
          <TransportDataDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        <PerformaInvoiceComponent
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openSalesInvoiceDetails={this.openSalesInvoiceDetails}
          updateState={(data) => {
            this.setState(data);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentTerms: state.common.paymentTerms,
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    priceList: state.common.priceList,
    states: state.common.states,
    products: state.salesInvoice.products,
    projectList: state.common.projectList,
    uomList: state.common.uomList,
    soInvoiceData: state.so.soInvoiceData,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    customerDetail: state.common.customerDetail,
    documentList: state.common.documentList,
    nextTxNumber: state.salesInvoice.nextTxNumber,
    allPreferences: state.common.allPreferences,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    countries: state.common.countries,
    soUnfulfilledList: state.so.soUnfulfilledList,
    soShipmentList: state.so.shipmentListForInvoice,
    addresses: state.customer.addresses,
    boRelationshipAddress: state.common.boRelationshipAddress,
    allRelationshipTaxIdentificatins:
      state.common.allRelationshipTaxIdentificatins,
    customerContactList: state.customer.contacts,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCustomerDetailsByCustomerId,
      createPerformaInvoice,
      resetSoInvoiceData,
      fetchSoDataForInvoice,
      fetchPaymentTerms,
      fetchCustomers,
      fetchPriceList,
      fetchStates,
      fetchProjects,
      fetchProducts,
      fetchTaxes,
      fetchUOM,
      addUOM,
      deleteUOM,
      fetchExpenseTypes,
      addExpenseTypes,
      deleteExpenseTypes,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      deleteCustomers,
      addPaymentTerm,
      deletePaymentTerm,
      deleteProject,
      deleteAddress,
      deleteTax,
      deletePriceList,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      getNextTxNumber,
      deleteContact,
      fetchUserPreferences,
      saveUserPrefrences,
      fetchRemarksTemplate,
      resetRemarksData,
      fetchCountries,
      fetchAllUnfulfilledSalesOrderByCustomerId,
      resetUnfulfilledSalesOrder,
      fetchShipmentForInvoice,
      resetShipmentForInvoice,
      fetchRelationshipTaxIdentifications,
      deleteCustomerTaxIdentifications,
      deleteRelationshipTaxIdentifications,
      createSalesInvoiceDraft,
      fetchAddresses,
      getRelationshipAddress,
      resetCustomerDetail,
      resetAddresses,
      fetchAllContacts,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoice);
