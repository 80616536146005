import React from "react";
import BankBook from "../../../components/finance/BankBook";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchBankBookList, getBankBookCount, resetPaginatedData} from "./action";
import { pushModalToStack , popModalFromStack, showModal, hideModal} from "../../../actions/commonActions";

 class BankBookListComp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
    };
  }

  
  componentDidMount() {

    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 200
    };
    this.props.fetchBankBookList(payload, companyInfo);
    this.props.getBankBookCount(payload, companyInfo)
  }

  render() {
    return (
      <>
        <BankBook
          {...this.props}
          {...this.state}
           updateState={(data) => {
            this.setState(data);
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      isLoading: state.common.isLoading,
      permissions: state.auth.permissions,
      bankBookList : state.finance.bankBookList,
      bankBookCount :  state.finance.bankBookCount, 
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchBankBookList, getBankBookCount, resetPaginatedData,
    pushModalToStack,
    popModalFromStack,
    showModal, 
    hideModal
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BankBookListComp);
