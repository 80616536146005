import React from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'antd';
import { TextBox } from '../../../../general/TextBox';
import * as find from 'lodash.find';
import { CustomButton } from '../../../../general/CustomButton';
import { Dropdown } from '../../../../general/Dropdown';
import { ErrorMsg } from '../../../../general/ErrorMessage';
import { showToasterMessage } from '../../../../../utils';

const LedgerAccountGroupComponent = (props) => {
    const rowgutter =[24,16]
    return (
        <div>
            <Row gutter={rowgutter}>
                <Col span={12}>
                        Ledger Account Group
                        <span className="required">*</span>
             
                    <TextBox
                        type='text'
                        value={props.ledgerAccountGroupName}
                        onChange={(e) => {
                            props.updateState({
                                ledgerAccountGroupName: e.target.value
                            });
                        }}
                        onBlur ={()=>{
                            const callBack = (value)=>{
                                props.updateState({
                                  isDuplicateName:value
                                })
                              }
                              let needToCheck = true
            
                              if(props.isUpdate){
                                needToCheck = props.preItemName == props.categoryName ? false :true
                              }
                              if(needToCheck){
                                props.isDuplicateLedgerAccountGroupName({ledgerAccountGroupName:props.ledgerAccountGroupName,relationshipId:props.companyInfo.relationshipId},callBack,props)
                              }
                        }}
                        placeholder={'Enter Ledger Name'}
                    />
                     <ErrorMsg
                      validator={() => { return !props.submittedOnceLedger || !!props.ledgerAccountGroupName  }}
                      message={ "Ledger account group is required"}/>
                </Col>

                <Col span={12}>
                    <div> Description</div>
                    <TextBox
                        type='text'
                        value={props.ledgerAccountGroupDescription}
                        onChange={(e) => {
                            props.updateState({
                                ledgerAccountGroupDescription: e.target.value
                            });
                        }}
                        placeholder={'Enter Description'}
                    />
                </Col>
                   
                <Col span={12}>
                    <div>
                        Category
                        <span className="required">*</span>
                    </div>
                        <Dropdown
                            items={props.allCategories || []}
                            valueKeyName='categoryName'
                            optionKeyName='chartOfAccountCategoryId'
                            value={props.chartOfAccountCategoryId}
                            placeholder={'Select Category'}
                            onSelect={
                                (chartOfAccountCategoryId) => {
                                    const selectedLedger = find(props.allCategories, {chartOfAccountCategoryId});
                                props.updateState({
                                    chartOfAccountCategoryId,
                                    categoryName: selectedLedger.categoryName,
                                    coaCategoryGroupId: selectedLedger.coaCategoryGroupId
                                });
                                }
                            }
                        />
                            Ledger Account Group will be the member of selected Category
                    <div>
                        <ErrorMsg
                        validator={() => { return !props.submittedOnceLedger || !!props.chartOfAccountCategoryId  }}
                        message={ "Category is required"}/>
                    </div>
                </Col>
      

            </Row>
            <br />
            <br />
            <br />

         
            <div key={0} className='customModal-button-group'>
                <CustomButton
                    intlId={'confirmation.cancel'}
                    defaultMessage={''}
                    type={'default'}
                    key={1}
                    onClick={() => {
                        props.hideModal();
                      }}
                />
                <CustomButton
                    intlId={'button.create.label'}
                    defaultMessage={''}
                    htmlType='submit'
                    key={2}
                    onClick={() => {
                        if(props.ledgerAccountGroupName && props.chartOfAccountCategoryId){
                            const modalData = {
                                modalBody: "Are you sure you want to create new ledger account ?",
                                handleSubmit: () => {
                                    if(!props.isDuplicateName){

                                        props.hideModal();
                                        props.popModalFromStack();
                                        props.createLedgerAccountGroup(props);
                                    }else{
                                        showToasterMessage({description:"Duplicate ledger account group name"})
                                    }
                                }
                            };
                            props.pushModalToStack(modalData);
                        }else{
                            props.updateState({
                                submittedOnceLedger: true
                            })
                        }
                      }}
                />
            </div>
        </div>
    );
};

export default injectIntl(LedgerAccountGroupComponent);
