import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS } from '../../static/constants';
import { getMomentDateForUIReadOnly, getUserDefinedName, showToasterMessage } from '../../utils';
import './signup.scss';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';
// import config from '../../config/environmentConfig';
// import { LogoComponent } from '../../components/general/LogoComponent';
import ProccessPaymnet from '../../containers/InvoicePreview/ProccessPayment';
import ProccessCardConnectPayment from '../../containers/InvoicePreview/ProccessCardConnectPayment';

const fixedDecimalNumber = (number) => {
    return (Number(number || 0)).toFixed(CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
}


function InvoicePreview(props) {
    const { salesInvoiceDetail, companyInfo = {} } = props;
    const { customerInvoiceDetailsList = [] } = salesInvoiceDetail;
    const relationshipAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (salesInvoiceDetail.placeOfSupply && relationshipAddress.stateName === salesInvoiceDetail.placeOfSupply) || false;
    let itemLevelTaxes = [];
    if (customerInvoiceDetailsList) {
        itemLevelTaxes = calculateTaxRateList(customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true,
        comment: true,
        studentClassName: true,
    };
    if (salesInvoiceDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, salesInvoiceDetail, props);
    }

    // const stripePromise = loadStripe('pk_test_51IdxxgL81zohSYdnzxti12XGAz7IO8blRbZJW1EQspQpX4Nlpot6XxbriMY0H1Z7B77wIlTpg1AkBU1QdONX2ZXN00VIcdz8uV', {
    //     stripeAccount: 'acct_1Iw6UHSDjcDwMQOr'
    //   });


    //   function App() {
    //     return (
    //         <Elements stripe={stripePromise}>
    //             <CheckoutForm />
    //         </Elements>
    //     );
    //   };

    const proccessPayment = () => {
        if (salesInvoiceDetail && salesInvoiceDetail.additionalInfoList && salesInvoiceDetail.additionalInfoList[0] && salesInvoiceDetail.additionalInfoList[0].paymentGatewayId) {
            switch (salesInvoiceDetail.additionalInfoList[0].paymentGatewayName) {
                case 'paypal': {
                    props.getPayOnlineUrl(salesInvoiceDetail);
                    break;
                }
                case 'stripe': {
                    break;
                }
                case 'Authorize.net': {
                    props.pushModalToStack({
                        // title: <div>
                        //     <FormattedMessage id='shareInvoice.text' defaultMessage='' />
                        //     <div style={{ 'fontSize': '12px' }}> {salesInvoiceDetail.customerName || ''}</div>
                        // </div>,
                        modalBody: <ProccessPaymnet {...props} />,
                        width: '50%',
                        hideFooter: true,
                        wrapClassName: 'paylist'
                    })
                    //props.getAuthNetPaymentUrl(salesInvoiceDetail);
                    break;
                }
                case 'CardPointe': {
                    props.pushModalToStack({
                        // title: <div className='mb25'>
                        // </div>,
                        hideTitle: true,
                        modalBody: <ProccessCardConnectPayment {...props} />,
                        width: '65%',
                        hideFooter: true,
                        wrapClassName: 'pop-bgsm'
                    })
                    //props.getAuthNetPaymentUrl(salesInvoiceDetail);
                    break;
                }
                default: {
                    showToasterMessage({ description: props.intl.formatMessage({ id: 'onlinePayment.not.available.message' }) });
                    break;
                }
            }
        } else {
            showToasterMessage({ description: props.intl.formatMessage({ id: 'onlinePayment.not.available.message' }) });
        }
    }


    return (
        <div className={"custom-modal show"} style={{paddingBottom:'100px'}}>
            <div className="modal-dialog">
                <div className="modal-content full-width  vertical-view-data">
                {!props.status ?
                        <div className="button-group-action header-control pay-btn">
                            <div>
                                <Row>
                                    <Col span={8}>
                                        {salesInvoiceDetail.status === 'paid' ?
                                            <Button type="" style={{color: '#fff', backgroundColor: '#1890ff', borderColor: '#1890ff'}}>
                                               <span> <FormattedMessage id='tabs.Invoice' /> <FormattedMessage id='paid' /></span>
                                            </Button> : ''}
                                        {salesInvoiceDetail.status === 'void' ?
                                            <Button type="" style={{color: '#fff', backgroundColor: '#6a7fb0', borderColor: '#6a7fb0'}}>
                                            <span><FormattedMessage id='tabs.Invoice' /> <FormattedMessage id='void' /> </span>
                                            </Button> : ''}
                                        {(salesInvoiceDetail.status !== "paid" && salesInvoiceDetail.status !== 'void') ? <Button type="primary" onClick={() => { proccessPayment() }}>
                                            <FormattedMessage id='pay.text' /> <span className='pl5'>- {salesInvoiceDetail.isMultiCurrency ?
                                                <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> :""} </span>
                                                : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : ""}</span>
                                            }{fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount - (salesInvoiceDetail.totalPaymentReceived || 0))}
                                            </span>
                                        </Button> : ''}
                                    </Col>
                                </Row>
                            </div>
                        </div> : ''}
                    {(props.status === 'success' || props.status === 'hold') ? <b><div className="mt10" style={{ fontSize: '20px', textAlign: 'center' }}>Your Receipt</div></b> : ''}
                        <br/>
                        <br/>
                    {(props.status === 'success' || props.status === 'hold')  && <div>
                        <b><div style={{ fontSize: '16px', textAlign: 'center' }}>{props.txStatusMessage || props.intl.formatMessage({ id: 'payOnline.success' })} </div></b>

                        <div className='mt30' style={{display: 'flex', justifyContent: 'center', }}>
                            <Row gutter={[16, 8]} style={{width: '50%',}}>
                                <Col span={10} offset={6}>
                                            <FormattedMessage id='modal.common.priceList.name' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                {salesInvoiceDetail.customerName}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='modal.customer.payInvoice.invoiceNumber' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    {salesInvoiceDetail.invoiceNumber}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='drawer.invoice.invoiceDate' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    { salesInvoiceDetail.invoiceDate && getMomentDateForUIReadOnly({date: salesInvoiceDetail.invoiceDate, format: CONSTANTS.DISPLAY_DATE_FORMAT})}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='modal.txDetails.payment.invoiceAmount' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    {salesInvoiceDetail.isMultiCurrency ?
                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency+ " "}</span>
                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                    } {fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount -(salesInvoiceDetail.totalPaymentReceived || 0))}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='paymentMethod.text' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    <FormattedMessage id='creditCard.text' />
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='modal.txDetails.payment.paymentDate' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                { getMomentDateForUIReadOnly({date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT})}
                                </Col>

                                <Col span={10} offset={6}>
                                    <FormattedMessage id='expense.Transaction.number' defaultMessage='' />
                                </Col>
                                <Col span={8}>
                                    {props.transactionId}
                                </Col>
                            </Row>
                        </div>
                        <b> <div style={{ fontSize: '14px', textAlign: 'center', marginTop: '20px' }}><FormattedMessage id='email.confirmation.sent.message' /> </div></b>
                    </div>
                    }
                    {props.status === 'failed' && <div style={{ fontSize: '16px', textAlign: 'center', color: 'red' }}><b><div>{props.intl.formatMessage({ id: 'payOnline.failed' })}</div><div>{props.txStatusMessage }</div> </b></div>}
                    {props.status === 'cancelled' && <div style={{ fontSize: '16px', textAlign: 'center', color: 'red' }}><b>{props.intl.formatMessage({ id: 'transaciton.canceled.message' })} </b></div>}
                    {!props.status ?
                        <div id="so-readonly" className="inner-content">
                            {/* <div className="brand-logo">
                                {(props.companyInfo || {}).logoAwsObjectKey &&
                                <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/>
                                }
                            </div> */}
                            <div className="doc-title">
                                {salesInvoiceDetail.documentName || 'Sales Invoice'}
                            </div>
                            <Row>
                                <Col span={16}>
                                    <div className="read-only-content pt20">
                                        <div className="title lh-adjust underline-text pb5">
                                            <FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' />
                                        </div>
                                        <div className="title">
                                            {getContactNameFragment((salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {}))}
                                            {salesInvoiceDetail.customerName}
                                        </div>
                                        <div>
                                            {getAddressInfoFragment(billingAddress, (salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {}))}
                                            {getTaxIdentificationsFragment(((salesInvoiceDetail.additionalInfoList || [])[0] || {}).taxIdentifications)}
                                        </div>
                                    </div>
                                    {shippingAddress.streetAddress1 ? <div className="read-only-content pt10">
                                        <div className="title lh-adjust underline-text pb5">
                                            <FormattedMessage id='modal.txDetails.common.shipTo' defaultMessage='' />
                                        </div>
                                        <div className="title">
                                            {salesInvoiceDetail.customerName}
                                        </div>
                                        <div>
                                            {getAddressInfoFragment(shippingAddress, {})}
                                        </div>
                                    </div> : ''}
                                </Col>
                                <Col span={4}>
                                    <div className="d-inline-flex w-100">
                                        <div className="read-only-content pt20 pl10">
                                        <div className="title lh-adjust underline-text pb5">
                                           From
                                        </div>
                                            <div className="title lh-adjust pb5">
                                                {props.companyInfo.storeName}
                                            </div>
                                            <div>
                                                {getAddressInfoFragment(relationshipAddress, companyInfo)}
                                                <div>
                                                    {getTaxIdentificationsFragment(((salesInvoiceDetail.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications)}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <div className="read-only-content pt20">
                                        <div className="pb5">
                                            <div className="title">
                                                <FormattedMessage id='common.invoiceNumber' defaultMessage='' />
                                            </div>

                                            <div>
                                                {salesInvoiceDetail.invoiceNumber}
                                            </div>
                                        </div>

                                        {
                                            salesInvoiceDetail.soNumber &&
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='modal.txDetails.salesOrder.soNumber' defaultMessage='' />
                                                </div>
                                                <div>
                                                    <abbr>{salesInvoiceDetail.soNumber}</abbr>
                                                </div>
                                            </div>
                                        }

                                        <div className="pb5">
                                            <div className="title">
                                                <FormattedMessage id='common.invoiceDate' defaultMessage='' />
                                            </div>

                                            <div>
                                                {(salesInvoiceDetail.invoiceDate && getMomentDateForUIReadOnly({ date: salesInvoiceDetail.invoiceDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                            </div>
                                        </div>

                                        {
                                            salesInvoiceDetail.invoiceDueDate &&
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='common.dueDate' defaultMessage='' />
                                                </div>

                                                <div>
                                                    {(salesInvoiceDetail.invoiceDueDate && getMomentDateForUIReadOnly({ date: salesInvoiceDetail.invoiceDueDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                </div>
                                            </div>
                                        }

                                        {
                                            !!salesInvoiceDetail.isMultiCurrency &&
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='modal.txDetails.common.foreignCurrency' defaultMessage='' />
                                                </div>

                                                <div>
                                                    {salesInvoiceDetail.foreignCurrency}
                                                </div>
                                            </div>
                                        }

                                        {
                                            !!salesInvoiceDetail.isMultiCurrency &&
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='modal.txDetails.common.exchangeRate' defaultMessage='' />
                                                </div>

                                                <div>
                                                    {salesInvoiceDetail.exchangeRate}
                                                </div>
                                            </div>
                                        }
                                        {
                                            salesInvoiceDetail.projectNumber &&
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='modal.txDetails.common.project' defaultMessage='' />
                                                </div>
                                                <div>
                                                    <abbr>{salesInvoiceDetail.projectNumber}({salesInvoiceDetail.projectName})</abbr>
                                                </div>
                                            </div>
                                        }
                                        {
                                            (props.companyInfo.countryName !== 'India' && !salesInvoiceDetail.customerPONumber && !salesInvoiceDetail.referenceNumber && salesInvoiceDetail.paymentTermName) &&
                                            <div className="pb5">
                                                <div className="title">
                                                    <FormattedMessage id='modal.txDetails.common.paymentTerm' defaultMessage='' />
                                                </div>
                                                <div>
                                                    <abbr>{salesInvoiceDetail.paymentTermName}</abbr>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </Col>
                                
                            </Row>
                            {(props.companyInfo.countryName === 'India' || salesInvoiceDetail.customerPONumber || salesInvoiceDetail.referenceNumber) &&
                                <div className="modal-table-container vertical-space">
                                    <table>
                                        <thead>
                                            <tr>
                                                {props.companyInfo.countryName === 'India' && <th><FormattedMessage id='modal.txDetails.common.placeOfSupply' defaultMessage='' /></th>}
                                                {salesInvoiceDetail.customerPONumber && <th><FormattedMessage id='common.customer.poNumber' defaultMessage='' /></th>}
                                                {salesInvoiceDetail.referenceNumber && <th><FormattedMessage id='modal.txDetails.common.refNumber' defaultMessage='' /></th>}
                                                <th><FormattedMessage id='modal.txDetails.common.paymentTerm' defaultMessage='' /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {props.companyInfo.countryName === 'India' && <td>{salesInvoiceDetail.placeOfSupply || '-'}</td>}
                                                {salesInvoiceDetail.customerPONumber && <td>{salesInvoiceDetail.customerPONumber || '-'}</td>}
                                                {salesInvoiceDetail.referenceNumber && <td>{salesInvoiceDetail.referenceNumber || '-'}</td>}
                                                <td>{salesInvoiceDetail.paymentTermName || ''}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }

                            <div className="modal-table-container mb15">
                                <table className="transaction-details-table">
                                    <thead>
                                        <tr>
                                            <th className="cell-size-50">S.No.</th>
                                            {/* {!isColumnEmpty.studentName && <th className="text-center"><FormattedMessage id='student.text' defaultMessage='' /></th>} */}
                                            {/* {!isColumnEmpty.studentGrade && <th className="text-center"><FormattedMessage id='grade.text' defaultMessage='' /></th>} */}
                                            {!isColumnEmpty.studentClassName && <th className="text-center">{getUserDefinedName('Class', props)}</th>}
                                            {(!isColumnEmpty.product || !isColumnEmpty.description) && <th>
                                                {!isColumnEmpty.product && <span>  <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                                {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                                {!isColumnEmpty.description && <span>  <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                            </th>}
                                            {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                                            {(!isColumnEmpty.qty && isColumnEmpty.studentName) && <th className="text-center"><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                            {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                                            {!isColumnEmpty.specialDiscount && <th className="text-center"><FormattedMessage id='modal.txDetails.common.basePrice' defaultMessage='' /></th>}
                                            {!isColumnEmpty.specialDiscount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.specialDiscount' defaultMessage='' /></th>}
                                            {!isColumnEmpty.rate && <th className="text-center">{salesInvoiceDetail.templateCode === 'student' ? <FormattedMessage id='fee.text' /> : <FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' />}</th>}
                                            {!isColumnEmpty.hsn && <th className="text-center"><FormattedMessage id='modal.txDetails.common.hsn' defaultMessage='' /></th>}
                                            {!isColumnEmpty.discount && <th className="text-right"><FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' /></th>}
                                            {!isColumnEmpty.tax && <th className="text-right"><FormattedMessage id='modal.txDetails.common.tax' defaultMessage='' /></th>}
                                            {/* {!isColumnEmpty.comment && <th>Warehouse</th>} */}
                                            <th className="text-right"><FormattedMessage id='modal.txDetails.common.netAmount' defaultMessage='' /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(customerInvoiceDetailsList || []).map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="cell-size-50">{i + 1}</td>
                                                    {/* {!isColumnEmpty.studentName && <td className="text-center">{item.studentName || '-'}</td>} */}
                                                    {/* {!isColumnEmpty.studentGrade && <td className="text-center">{item.studentGrade || '-'}</td>} */}
                                                    {!isColumnEmpty.studentClassName && <td className="text-center">{item.studentClassName || '-'}</td>}
                                                    {(!isColumnEmpty.product || !isColumnEmpty.description) && <td>
                                                        <strong>{getVariantFragment(item)}</strong>
                                                        {!isColumnEmpty.description && <div className="description-cell">
                                                            {/* <pre>{ReactHtmlParser(item.description || '-')}</pre> */}
                                                            {getDescriptionFragment(salesInvoiceDetail, item, i + 1)}
                                                        </div>}
                                                    </td>}
                                                    {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                                                    {(!isColumnEmpty.qty && isColumnEmpty.studentName) && <td className="text-center">{item.quantityToInvoice + (item.uomName ? ' ' + item.uomName : '')}</td>}
                                                    {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                                                    {!isColumnEmpty.specialDiscount && <td className="text-center">{fixedDecimalNumber(item.basePrice || 0) || '-'}</td>}
                                                    {!isColumnEmpty.specialDiscount && <td className="text-right">{getSpecialDiscount(item, 'amountPercent')}</td>}
                                                    {!isColumnEmpty.rate && <td className="text-center">{fixedDecimalNumber(item.anItemSalePrice || 0) || '-'}</td>}
                                                    {!isColumnEmpty.hsn && <td className="text-center">{item.hsnCode || '-'}</td>}
                                                    {!isColumnEmpty.discount && <td className="text-right">{getTxTypeData(item, 'discount', 'amount')} {getTxTypeData(item, 'discount', 'amountPercent')}</td>}
                                                    {!isColumnEmpty.tax && <td className="text-right">{getTotalTaxAmount(item)} {getTotalTaxPercent(item, 'amountPercent')}</td>}
                                                    {/* {!isColumnEmpty.comment && <td className="description-cell">
                                                    <pre>{ReactHtmlParser(item.comment || '-')}</pre>
                                                </td>} */}
                                                    <td className="text-right">{getNetAmount(item) || '-'}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <Row>
                                <Col span={9} className="read-only-content">

                                    {salesInvoiceDetail.docDetailList && salesInvoiceDetail.docDetailList.length ? <Row>
                                        <Col span={24} className="title">
                                            <FormattedMessage id='modal.txDetails.common.attachments' defaultMessage='' />
                                        </Col>
                                        <Col span={12}>
                                            {
                                                salesInvoiceDetail.docDetailList.map((doc) => {
                                                    return <span className={"cursor-pointer"} onClick={() => {
                                                        props.downloadFileFromBucket({
                                                            bucketName: doc.bucketName,
                                                            awsKeyName: doc.awsKeyName,
                                                            fileName: doc.fileName
                                                        })
                                                    }}>{doc.fileName} &nbsp;</span>
                                                })
                                            }
                                        </Col>
                                    </Row> : null}
                                    {(salesInvoiceDetail.additionalInfoList && salesInvoiceDetail.additionalInfoList.length && salesInvoiceDetail.additionalInfoList[0].bankDetails) &&
                                        <Row>
                                            <Col span={7} className="title">
                                                <FormattedMessage id='customer.salesInvoice.form.bankDetails.label' defaultMessage='' />
                                            </Col>
                                            <Col span={17}>
                                                {getBankDetailsFragment(((salesInvoiceDetail.additionalInfoList || [])[0] || {}).bankDetails)}
                                            </Col>
                                        </Row>
                                    }
                                    {salesInvoiceDetail.remarksInternal &&
                                        <Row>
                                            <Col span={7} className="title">
                                                <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                            </Col>
                                            <Col span={17}>
                                                {ReactHtmlParser(salesInvoiceDetail.remarksInternal)}
                                            </Col>
                                        </Row>
                                    }

                                    {/* {salesInvoiceDetail.remarksCustomer &&
                                        <Row className="pt5">
                                            <Col span={7} className="title">
                                                <FormattedMessage id='modal.txDetails.common.customerRemarks' defaultMessage='' />
                                            </Col>
                                            <Col span={17}>
                                                {ReactHtmlParser(salesInvoiceDetail.remarksCustomer)}
                                            </Col>
                                        </Row>
                                    } */}
                                </Col>

                                <Col offset={8} span={7}>
                                    <div className="bill-summary mt5">
                                        <Row>
                                            <Col span={14}>
                                                <div className="right-txt">
                                                    <FormattedMessage id='modal.txDetails.common.subTotal' defaultMessage='' />
                                                </div>
                                            </Col>

                                            <Col span={10} className="text-right pr50">
                                                {salesInvoiceDetail.isMultiCurrency ?
                                                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "}</span>
                                                    : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                                }
                                                {getSubTotalOrTaxOrDiscountAmount(salesInvoiceDetail, "SubTotal")}
                                            </Col>
                                        </Row>
                                        {Number(getSubTotalOrTaxOrDiscountAmount(salesInvoiceDetail, "Discount")) ?
                                            <div className="pt10">
                                                <Row>
                                                    <Col span={14}>
                                                        <div className="right-txt">
                                                            <FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' />
                                                        </div>
                                                    </Col>

                                                    <Col span={10} className="text-right pr50">
                                                        {salesInvoiceDetail.isMultiCurrency ?
                                                            <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "}</span>
                                                            : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                                        }
                                                        {getSubTotalOrTaxOrDiscountAmount(salesInvoiceDetail, "Discount")}
                                                    </Col>
                                                </Row>
                                            </div> : ''}
                                        {getTxTypeData(salesInvoiceDetail, 'expense', 'amount') ?
                                            <div className="pt10">
                                                <Row>
                                                    <Col span={14}>
                                                        <div className="right-txt">
                                                            <FormattedMessage id='modal.txDetails.common.expense' defaultMessage='' />
                                                        </div>
                                                    </Col>

                                                    <Col span={10} className="text-right pr50">
                                                        {salesInvoiceDetail.isMultiCurrency ?
                                                            <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "}</span>
                                                            : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                                        }
                                                        {getTxTypeData(salesInvoiceDetail, 'expense', 'amount')}
                                                    </Col>
                                                </Row>
                                            </div> : ''
                                        }

                                        {
                                            (itemLevelTaxes && itemLevelTaxes.length !== 0) && itemLevelTaxes.map((taxObj, i) => {

                                                return (
                                                    props.companyInfo.countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                        <div className="pt10">
                                                            <Row>
                                                                <Col span={14}>
                                                                    <div className="right-txt">CGST @ {taxObj.taxPercent / 2}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                </Col>
                                                                <Col span={10} className="text-right pr50">
                                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "}</span>
                                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                                                    }
                                                                    {fixedDecimalNumber(taxObj.taxAmount / 2)}
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div className="pt10">
                                                            <Row>
                                                                <Col span={14}>
                                                                    <div className="right-txt">SGST @ {taxObj.taxPercent / 2}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                </Col>
                                                                <Col span={10} className="text-right pr50">
                                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "}</span>
                                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                                                    }
                                                                    {fixedDecimalNumber(taxObj.taxAmount / 2)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div> : <div key={i}>
                                                            <div className="pt10">
                                                                <Row>
                                                                    <Col span={14}>
                                                                        <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                    </Col>
                                                                    <Col span={10} className="text-right pr50">
                                                                        {salesInvoiceDetail.isMultiCurrency ?
                                                                            <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "}</span>
                                                                            : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                                                        }
                                                                        {fixedDecimalNumber(taxObj.taxAmount)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    ) :
                                                        <div key={i}>
                                                            <div className="pt10">
                                                                <Row>
                                                                    <Col span={14}>
                                                                        <div className="right-txt">{taxObj.taxName} @ {taxObj.taxPercent}% on {fixedDecimalNumber(taxObj.subtotal)} </div>
                                                                    </Col>
                                                                    <Col span={10} className="text-right pr50">
                                                                        {salesInvoiceDetail.isMultiCurrency ?
                                                                            <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "}</span>
                                                                            : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                                                        }
                                                                        {fixedDecimalNumber(taxObj.taxAmount)}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                )
                                            })
                                        }

                                        <div className="pt10">
                                            <Row>
                                                <Col span={14}>
                                                    <div className="right-txt bold">
                                                        <FormattedMessage id='modal.txDetails.common.grandTotal' defaultMessage='' />
                                                    </div>
                                                </Col>

                                                <Col span={10} className="text-right pr50">
                                                    {salesInvoiceDetail.isMultiCurrency ?
                                                        <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "}</span>
                                                        : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                                    }
                                                    {salesInvoiceDetail.invoiceTotalAmount && fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount)}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    {/* <div className="upload-link" title={props.intl.formatMessage({ id: 'uploadSignature.tooltip' })}>
                                    <CustomUploader {...customProps} />
                                    {salesInvoiceDetail.stampAwsKey && <div className="cursor-pointer" onClick={() => {
                                        props.deleteStamp({
                                            customerId: salesInvoiceDetail.customerId,
                                            invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                            relationshipId: salesInvoiceDetail.relationshipId,
                                            objectKey: salesInvoiceDetail.stampAwsKey
                                        }, props)
                                    }}> <FormattedMessage id='modal.txDetails.common.deleteStamp' defaultMessage='' /> </div>}
                                </div> */}
                                </Col>
                            </Row>

                            <div className="contact-details foots">
                                {salesInvoiceDetail.footer &&
                                    <Fragment>
                                        {ReactHtmlParser(salesInvoiceDetail.footer)}
                                    </Fragment>
                                }
                            </div>
                        </div> : ''
                    }
                </div>
            </div>
        </div>
    );

}

const getSpecialDiscount = (row, toReturn) => {
    if (!row) return;
    let specialDiscount = row.basePrice - row.anItemSalePrice;
    if (specialDiscount && row.basePrice) {
        if (toReturn === 'amountPercent') {
            let percent = fixedDecimalNumber((specialDiscount * 100) / row.basePrice);
            let amount = fixedDecimalNumber(specialDiscount)
            return amount + " (" + percent + ")%";
        } else if (toReturn === 'amount') {
            return fixedDecimalNumber(specialDiscount)
        }

    } else if (!row && toReturn === 'amount') {
        return 0;
    }
    else {
        return "-";
    }
}

const getSubTotalOrTaxOrDiscountAmount = (data, type) => {
    let total = 0;
    data && data.customerInvoiceDetailsList && data.customerInvoiceDetailsList.forEach(function (d) {
        if (type === 'SubTotal') {
            total = total + d.anItemSalePrice * d.quantityToInvoice;
        }
        if (type === 'Discount') {
            let discountObject = d.customerInvoiceCOATxList.find(tx => (tx.txType === 'discount'));
            if (discountObject) {
                total = total + parseFloat(discountObject.amount);
            }
        }
    });

    if (type === 'Discount' && data && data.customerInvoiceCOATxList) {
        let discount = data.customerInvoiceCOATxList.find(tx => (tx.txType === 'discount' && tx.accountingEntry === 'credit'));
        if (discount) {
            total = total + parseFloat(discount.amount);
        }
    }
    return fixedDecimalNumber(total);
}

const getTxTypeData = (row, type, toReturn) => {
    if (!row || !row.customerInvoiceCOATxList) return;
    let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + data[toReturn] + "%)";
            }
            return "(" + data[toReturn] + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return fixedDecimalNumber(data[toReturn]);
        } else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}
const getTotalTaxAmount = (row) => {
    let tax = getTxTypeData(row, 'tax', 'amount', true) || 0;
    let totalTax = Number(tax);
    return totalTax;

}

const getTotalTaxPercent = (row, toReturn) => {
    let tax1 = row.customerInvoiceCOATxList.find(tx => (tx.txType === 'tax')) || {};
    let totalPercent = Number(tax1.amountPercent || 0);
    if (toReturn === 'amountPercent') {
        return totalPercent ? ('(' + totalPercent + "%)") : '';
    }
    else {
        return totalPercent;
    }
}

const getNetAmount = (row) => {
    let tax = getTotalTaxAmount(row) || 0;
    let discount = getTxTypeData(row, 'discount', 'amount', true) || 0;
    let netAmountItem = (Number(row.quantityToInvoice * row.anItemSalePrice) + Number(tax) - Number(discount));
    return fixedDecimalNumber(netAmountItem);

}

const calculateTaxRateList = (customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerInvoiceDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0
            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getTxTypeData(rowdata, 'discount', 'amount');
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'tax')) || {};
            taxObj.taxPercent = tax1.amountPercent;
            taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount))
            totalTax = totalTax + tax1.amount;
            taxObj.taxName = tax1.taxName;
            //  let tax2 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'sgst'));
            // let tax3 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'igst'));
            // let tax4 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'vat'));
            //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
            //       taxObj.taxPercent = tax1.amountPercent + tax2.amountPercent;
            //       taxObj.taxAmount = tax1.amount + tax2.amount;
            //       totalTax = totalTax + taxObj.taxAmount;
            //   }
            //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
            //       taxObj.taxPercent = tax3.amountPercent;
            //       taxObj.taxAmount = tax3.amount;
            //       totalTax = totalTax + tax3.amount;
            //   }
            //   else if (tax4) {
            //       taxObj.taxPercent = tax4.amountPercent;
            //       taxObj.taxAmount = tax4.amount;
            //       totalTax = totalTax + tax4.amount;
            //   }
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                if (availableTaxObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                    taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0))
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });
    return itemLevelTaxes;
}

const updateColumnEmptyCheck = (isColumnEmpty, invoiceData, props) => {
    if (!invoiceData) return isColumnEmpty;
    for (let i in invoiceData.customerInvoiceDetailsList) {
        let row = invoiceData.customerInvoiceDetailsList[i];
        if (row.studentName) { isColumnEmpty.studentName = false; };
        // if (row.studentGrade) { isColumnEmpty.studentGrade = false; };
        if (row.studentClassName) { isColumnEmpty.studentClassName = false; };
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantityToInvoice) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }
        if (row.comment) { isColumnEmpty.comment = false; }
    }
    return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
    if (!row) return true;
    let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type));
    if (data && data.amount) {
        return false;
    }
    else {
        return true;
    }
}

const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <div className='billing-address'>{obj.streetAddress1}
                </div>
                : ''
        }
        {
            obj.streetAddress2
                ? <div className='billing-address'>{obj.streetAddress2}
                </div>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {/* {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                     <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        } */}

    </Fragment>

    return fragmentObj;
}

const getTaxIdentificationsFragment = (taxIdentifications) => {
    if (!taxIdentifications) return <Fragment />;
    let taxIdentificationsList = []
    if (taxIdentifications) {
        taxIdentificationsList = JSON.parse(taxIdentifications);
    }
    const fragmentObj = <Fragment>
        {taxIdentificationsList.map((obj, i) => {
            return <div className='billing-address'>
                {obj.taxIdentificationType || ''} - {obj.taxIdentificationNumber}
            </div>
        })}
    </Fragment>

    return fragmentObj;
}

const getBankDetailsFragment = (bankDetails) => {
    if (!bankDetails) return <Fragment />;
    let bankDetailsList = []
    if (bankDetails) {
        bankDetailsList = JSON.parse(bankDetails);
    }
    const fragmentObj = <Fragment>

        <table style={{ width: "100%" }}>
            <thead>
                <tr>
                    <th>Bank Name</th>
                    <th>Account #</th>
                    <th>IFSC Code</th>
                </tr>
            </thead>
            <tbody>
                {(bankDetailsList || []).map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{item.name}</td>
                            <td>{item.accountNumber}</td>
                            <td>{item.ifscCode}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </Fragment>

    return fragmentObj;
}

const getContactNameFragment = (additionInfo) => {
    if (!additionInfo) return <Fragment />;
    let contactName = "";
    if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
    if (additionInfo.firstName) contactName += additionInfo.firstName;
    if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
    if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
    const fragmentObj = <Fragment>
        {
            (contactName && contactName.length)
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

const getVariantFragment = (item) => {
    let itemName = null;
    if (item.itemName) {
        itemName = item.itemName;
    }
    if (item.itemVariantName) {
        itemName = itemName + ' (' + item.itemVariantName + ')'
    }
    return itemName ? itemName : '';
}

const getDescriptionFragment = (salesInvoiceDetail, item, index) => {
    if (!item) return <Fragment />;
    const fragmentObj = <Fragment>
        {
            item.materialNumber
                ? <div>
                    <span className="title"> Material # : </span>{item.materialNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.stockNumber
                ? <div>
                    <span className="title"> Stock # : </span>{item.stockNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.partNumber
                ? <div>
                    <span className="title"> Part # : </span>{item.partNumber}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.description
                ? <div>
                    {ReactHtmlParser(item.description || '')}
                </div> :
                ''
        }
        {
            item.origin
                ? <div>
                    <br></br>
                    <span className="title"> Origin : </span>{item.origin}
                </div> :
                ''
        }
        {
            item.hsCode
                ? <div>
                    <span className="title"> HS Code : </span>{item.hsCode}
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            item.warehouse
                ? <div>
                    <span className="title"> Warehouse Name : </span> <span style={{ 'display': 'inline-block' }}>{ReactHtmlParser(item.warehouse || '')} </span>
                    <br></br>
                    <br></br>
                </div> :
                ''
        }
        {
            (salesInvoiceDetail.customerPONumber)
                ? <div> (
                    {salesInvoiceDetail.customerPONumber ? <span className="title">PO {salesInvoiceDetail.customerPONumber} - </span> : ''}
                    {index ? <span className="title"> Line {index}</span> : ''}
                )
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

export default injectIntl(InvoicePreview);
