import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Menu, Skeleton, Empty, Pagination } from "antd";
import { CONSTANTS,ICONS } from "../../../../static/constants";
import { getMomentDateForUIReadOnly, getUserDefinedName } from "../../../../utils";
// import DropdownAction from "antd/lib/dropdown";


const Product = (props) => {

  const { pageNumber, pageSize, customerPurchaseItems, customerPurchaseItemsCount, updateState } = props;

  return (
    <>
       {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
          {getUserDefinedName('sidebar.menuItem.class',props)}
          </div>
         
        </div>
        <div className="top-view-right">
          <button className="create-action-btn" onClick={()=>{
            newSection();
          }}>
              <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div>  */}
      
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              {getUserDefinedName("Product")}
            </div>
                {customerPurchaseItemsCount ? 
                  <>
                    <div className="vertical-sep" />
                    <div>{customerPurchaseItemsCount}</div>
                  </>
                : 
                ""
                }
          </div> 
          <div className="right-actions">

          </div>
        </div>

        <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>
          <div className="table-container">
            <table id="customer-product-table">
              <thead>
                <tr>
                  <th><FormattedMessage id="serial.no" defaultMessage=""/></th>
                  <th>Product</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Avg Sales Value</th>
                  <th>Date</th>
                  <th>Purchase Times</th>
                </tr>
              </thead>

              <tbody>
                {
                    (customerPurchaseItems[pageNumber || 1] || []).length ? customerPurchaseItems[pageNumber || 1].map((e, i) => {
                    return <tr key={i}>
                      <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                      <td>{e.productName || '-'}</td>
                      <td>{e.categoryName || "-"}</td>
                      <td>{e.subCategoryName || '-'}</td>
                      <td>{e.avgSalePrice}</td>
                      <td>{ e.lastPurchaseDate ? getMomentDateForUIReadOnly({date: new Date(e.lastPurchaseDate)}) : '-'}</td>
                      <td>{e.totalQuantity}</td>
                    </tr>
                  })
                  :
                  <tr key="empty"style={{textAlign: 'center'}}>
                    <td colSpan={'100%'}>
                      <Empty />
                    </td>
                  </tr>
                }
              </tbody>

            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={customerPurchaseItemsCount}
                defaultPageSize={props.pageSize || 25}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  // loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  // handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </div>
        </div>
    </>
  );
};

export default injectIntl(Product);
