import React from "react";
import { injectIntl,  } from 'react-intl';

import './index.scss';
// import { CONSTANTS } from '../../../static/constants';
import ReactHtmlParser from 'react-html-parser';

const NewsLetterPreview = (props) => {

    setTimeout(function () {
        let rsvpButtonRef = document.getElementById("button_child_4");
        let pladgeButtonRef = document.getElementById("button_child_8");
        if (!props.isURLUpdated && rsvpButtonRef && props.newsLetterTemplate && props.newsLetterTemplate.templateType === 'event') {
            let href = rsvpButtonRef.href + '_' + props.newsLetterTemplate.alpideFormBuilderId;
            rsvpButtonRef.href = href;
            props.updateState({ isURLUpdated: true });
        } else if (!props.isURLUpdated && rsvpButtonRef && props.newsLetterTemplate && props.newsLetterTemplate.templateType === 'fundraising') {
            let href = rsvpButtonRef.href;

            if (href.indexOf('&f=') < 0 && props.teamMasterId) {
                href = href + '&f=' + props.teamMasterId + '_' + props.teamMemberId;
                if (pladgeButtonRef) {
                    let pledgeHref = pladgeButtonRef.href;
                    pledgeHref = pledgeHref + '&f=' + props.teamMasterId + '_' + props.teamMemberId;
                    pladgeButtonRef.href = pledgeHref;
                }

            }
            rsvpButtonRef.href = href;
            props.updateState({ isURLUpdated: true });
        }
    }, 1000);

    return (
        <div style={{ padding: '0px 0px 200px' }} >

            {/* <div className="grey-boxlo template-preview">

                <div className='middle-conter white-bglo'>

                    <div className='menu boxcnt' id="temmplateDiv">
                        {ReactHtmlParser(props.newsLetterTemplate.templateContent)}
                    </div>
                </div>
            </div> */}
            {ReactHtmlParser(props.newsLetterTemplate.templateContent)}
        </div>
    )
};

export default injectIntl(NewsLetterPreview);
