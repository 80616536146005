import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MembershipOptionComp from "../../../components/membership/membershipOption";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getMembershipOptionListing, getMembershipOptionCount, deleteMembershipOption, resetPaginatedData, getAllFees } from "./action";
import { showModal, hideModal } from './../../../actions/commonActions';

class MembershipOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
      allTermAndFeeStatementObj: {}
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded("getMembershipOptionListing", "membershipOptionList", this.props, payload);
    fetchDataIfNeeded("getMembershipOptionCount", "membershipOptionCount", this.props, payload);
    fetchDataIfNeeded("getAllFees", "allTermAndFee", this.props, payload);
  }

  componentWillReceiveProps(props) {
    if (props.allTermAndFee && props.allTermAndFee.length) {
      let feeObj = {};
      for (let i = 0; i < props.allTermAndFee.length; i++) {
        let obj = props.allTermAndFee[i];
        let id = obj.feesId;
        let syntax = `$${obj.fee}/${obj.frequency} for ${obj.duration} ${obj.period} ${obj.type ? '- ' + obj.type : ''}`
        feeObj[id] = syntax;
      }
      this.setState({
        allTermAndFeeStatementObj: feeObj
      });
    }
  }

  render() {
    return <MembershipOptionComp  {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}>

    </MembershipOptionComp>;
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    membershipOptionList: state.membershipReducer.membershipOptionList,
    membershipOptionCount: state.membershipReducer.membershipOptionCount,
    allTermAndFee: state.membershipReducer.allTermAndFee,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMembershipOptionListing,
      getMembershipOptionCount,
      deleteMembershipOption,
      resetPaginatedData,
      getAllFees,
      showModal,
      hideModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MembershipOption);
