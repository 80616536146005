import React, { Fragment } from "react";
import { injectIntl } from 'react-intl';
import Textimg from '../../assets/images/text-icon.png';
import Imageimg from '../../assets/images/image-icon.png';
import Spacerimg from '../../assets/images/space-icon.png';
import Dividerimg from '../../assets/images/divider-icon.png';
import DividePic from '../../assets/images/divider/divider-3.jpg';
import Buttonimg from '../../assets/images/button-icon.png';
// import Videoimg from '../../assets/images/video-icon.png';
// import Socialimg from '../../assets/images/link-icon.png';
import ImageIcon from '../../assets/images/add-image.jpg';
import LogosIcon from '../../assets/images/logo-icon.png';
// import SectionIcon from '../../assets/images/section-icon.png';
import FooterICon from '../../assets/images/footer-icon.png';
import SocialMediaIcon from '../../assets/images/social-media-icon.png';
import loadScript from 'load-script';
import { showToasterMessage } from '../../utils';
// import { CONSTANTS } from '../../static/constants';

// import { Row, Col } from 'antd';
// import SectionIcon2 from '../../assets/images/two-icon.png';
// import SectionIcon3 from '../../assets/images/three-icon.png';
// import SectionIcon2s from '../../assets/images/twos-icon.png';
// import SectionIcon2l from '../../assets/images/twol-icon.png';
// import SectionIcon3r from '../../assets/images/threes-icon.png';
// import SectionIcon3l from '../../assets/images/three2-icon.png';
// import ButtonTwoIcon from '../../assets/images/button-two-icon.png';

// const format = CONSTANTS.DISPLAY_DATE_FORMAT;
//import NewsLetterShare from '../modal/modalBody/communication/NewsLetterShare';
const FaceBookIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/facebook.png';
const GooglePlusIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/google-plus.png';
const InstagramIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/instagram.png';
const LinkedinIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/linkedin.png';
const PinterestIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/pinterest.png';
const TumblrIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/tumblr.png';
const TwitterIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/twitter.png';
const VimeoIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/vimeo.png';
const YouTubeIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/youtube.png';

// const FaceBookIcon = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/facebook-white.png';
// const TwitterIcon = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/twitter-white.png';
// const InstagramIcon = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/insta-white.png';
// const LinkedinIcon = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/linked-white.png';
// const PinterestIcon = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/print-white.png';
// const GooglePlusIcon = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/google-plus-white.png';
// const TumblrIcon = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/timber-white.png';
// const VimeoIcon = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/vim-white.png';
// const YouTubeIcon = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/youtube-white.png';



function TopLinks(props) {
    if (!('CKEDITOR' in window)) {
        loadScript('https://cdn.ckeditor.com/4.14.0/full/ckeditor.js', err => {
            if (err) {
            } else {
            }
          });
    }

    // const addSections = (ratio, childId) =>{
    //    let ratioList = ratio.split(":");

    // //    for (let i = 0; i < ratioList.length; i++) {
    // //     let sectionElement = document.createElement("div");
    // //     sectionElement.id = childId+'_section_'+(i+1);
    // //     sectionElement.innerHTML = 'Section'+(i+1);
    // //     sectionElement.style.float = 'left';
    // //     sectionElement.style.clear = "both";
    // //     sectionElement.style.cursor = "pointer";
    // //     sectionElement.style.display = "inline";
    // //     sectionElement.style.border = "#ddd 1px solid";
    // //     sectionElement.style.width = ratioList[i]+"%";

    // //     document.getElementById(childId).appendChild(sectionElement);
    // //   } 
    //     switch (ratio) {
    //         case '50:50': {
            

    //             if (props.allChildIds.indexOf(childId) < 0) {
    //                 let allChildIds = props.allChildIds;
    //                 allChildIds.push(childId);
    //                 allChildIds.push('delete_child_4');
    //                 props.updateState(allChildIds);
    //             }
    //             let sectionObj = document.getElementById(childId);
    //             let divObj1 = document.createElement("span");
    //             divObj1.id = "section1_"+childId; 
    //             divObj1.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "section1_"+childId, elementType: 'Button' }) };
    //             divObj1.className = "mdilebutton alltime inty-but";
    //             let button1 = document.createElement("a");

    //             button1.id = "button_section1_"+childId;
    //             button1.innerHTML = 'Button';
    //             button1.style.width = "150px";
    //             button1.style.height = "50px";
    //             button1.style.background = "#525156";
    //             button1.style.textAlign = "center";
    //             button1.style.color = "#fff";
    //             button1.style.display = "inline-block";
    //             button1.style.lineHeight = "45px";
    //             button1.style.margin = "10px auto 10px 155px";
    //             button1.style.clear = "both";
    //             // mainCanvasDiv.style.cursor = "pointer";
    //             button1.style.border = "solid 1px #525156";
    //             button1.style.borderRadius = "4px";
    //             button1.className = "disabled-link";
                
    //             let divObj2 = document.createElement("span");
    //             divObj2.id = "section2_"+childId; 
    //             divObj2.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "section2_"+childId, elementType: 'Button' }) };
    //             divObj2.className = "mdilebutton alltime inty-but";

    //             let button2 = document.createElement("a");
    //             button2.id = "button_section2_"+childId;
    //             button2.innerHTML = 'Button';
    //             button2.style.width = "150px";
    //             button2.style.height = "50px";
    //             button2.style.background = "#525156";
    //             button2.style.textAlign = "center";
    //             button2.style.color = "#fff";
    //             button2.style.lineHeight = "45px";
    //             button2.style.display = "inline-block";
    //             button2.style.margin = "10px auto 10px 50px";
    //             button2.style.clear = "both";
    //             // mainCanvasDiv.style.cursor = "pointer";
    //             button2.style.border = "solid 1px #525156";
    //             button2.style.borderRadius = "4px";
    //             button2.className = "disabled-link";
               
        
    //             //mainCanvasDiv.appendChild(deleteButton);
    //             divObj1.appendChild(button1);
    //             divObj2.appendChild(button2);
    //             sectionObj.appendChild(divObj1);
    //             sectionObj.appendChild(divObj2);
    //             break;
    //         }
    //     }
    // }

    // const addSectionHandle = (childId) => {
    //     const modalBody = <Row>
    //         <Col span={12}>
    //             <div className='diver-box' onClick={() => {
    //                 addSections('50:50', childId);
    //                 props.hideModal();
    //             }}>
    //                 <img src={ButtonTwoIcon} alt='' />
    //             </div>
    //         </Col>
    //         <Col span={12}>
    //             <div className='diver-box' onClick={() => {
    //                 addSections('33:34:33', childId);
    //                 props.hideModal();
    //             }}>
    //                 <img src={SectionIcon3} alt='' />
    //             </div>
    //         </Col>
    //         <Col span={12}>
    //             <div className='diver-box' onClick={() => {
    //                 addSections('35:65', childId);
    //                 props.hideModal();
    //             }}>
    //                 <img src={SectionIcon2s} alt='' />
    //             </div>
    //         </Col>
    //         <Col span={12}>
    //             <div className='diver-box' onClick={() => {
    //                 addSections('25:25:50', childId);
    //                 props.hideModal();
    //             }}>
    //                 <img src={SectionIcon3r} alt='' />
    //             </div>
    //         </Col>

    //         <Col span={12}>
    //             <div className='diver-box' onClick={() => {
    //                 addSections('65:35', childId);
    //                 props.hideModal();
    //             }}>
    //                 <img src={SectionIcon2l} alt='' />
    //             </div>
    //         </Col>
    //         <Col span={12}>
    //             <div className='diver-box' onClick={() => {
    //                 addSections('50:25:25', childId);
    //                 props.hideModal();
    //             }}>
    //                 <img src={SectionIcon3l} alt='' />
    //             </div>
    //         </Col>
    //     </Row>;
    //     const modalData = {
    //         modalBody,
    //         title: 'Select a Section',
    //         hideFooter: true,
    //         handleSubmit: () => {
              
    //         },
    //     };
    //     props.showModal(modalData);
    // }
   
    return (
        <Fragment>
            <div className='menum'>
                
                <ul>
                    <li onClick={() => {
                        props.updateState({ isNewsLetterPropsUpdaterVisible: false, isNewsLetterThemeUpdaterVisible: false });
                        let childIndex = props.childIndex || 1;
                        if (props.allChildIds.indexOf("child_" + childIndex) < 0) {
                            let allChildIds = props.allChildIds;
                            allChildIds.push("child_" + childIndex);
                            allChildIds.push("delete_child_" + childIndex);
                            props.updateState(allChildIds);
                        }
                        props.updateState({ childIndex: childIndex + 1 })
                        let mainCanvasDiv = document.createElement("div");
                        // mainCanvasDiv.innerHTML="<img class='' id="+'child_'+childIndex+">"
                        mainCanvasDiv.type = "file";
                        mainCanvasDiv.id = "child_" + childIndex;
                        mainCanvasDiv.className = " toplogo alltime";
                        mainCanvasDiv.style.width = "680px";
                        mainCanvasDiv.style.minHeight = "40px";
                        // mainCanvasDiv.style.border = "#ddd 1px solid";
                        mainCanvasDiv.style.textAlign = "center";
                        mainCanvasDiv.style.padding = "25px 0px 25px";
                        mainCanvasDiv.style.borderRadius = "5px";
                        mainCanvasDiv.style.margin = "10px auto 10px";
                        mainCanvasDiv.style.display = "inline-block";
                        mainCanvasDiv.draggable = true;
                        mainCanvasDiv.ondragstart = window.drag;
                        mainCanvasDiv.class = "drag";
                       mainCanvasDiv.style.cursor = "pointer";
                        let img = document.createElement('img');
                        img.id = "dummy_child_" + childIndex;
                        img.src = props.logoUrl || ImageIcon;
                        img.style.width= "100px";  
                     //    img.style.height= "150px";
                        img.className= "logotop";
                        mainCanvasDiv.appendChild(img);

                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box fa fa-trash";
                        deleteButton.style.display = "none";
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            setTimeout(function () {comp.parentNode.removeChild(comp);},100);
                        };
                        mainCanvasDiv.appendChild(deleteButton);
                        mainCanvasDiv.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: comp ? true : false, childId: "child_" + childIndex, elementType: 'Logo' })
                        };
                        document.getElementById('temmplateDiv').prepend(mainCanvasDiv);
                        window.scrollTo(0,0)

                    }}>
                        <div className="menu-img">
                            <img src={LogosIcon} alt="" style={{ width: '44px', position: 'relative', top: '4px' }} />
                        </div>
                        <span style={{ color: '#fff' }}> Logo </span>
                    </li>
                    <li onClick={() => {
                        props.updateState({ isNewsLetterPropsUpdaterVisible: false, isNewsLetterThemeUpdaterVisible: false  });
                        let childIndex = props.childIndex || 1;
                        if (props.allChildIds.indexOf("child_" + childIndex) < 0) {
                            let allChildIds = props.allChildIds;
                            allChildIds.push("child_" + childIndex);
                            allChildIds.push("delete_child_" + childIndex);
                            allChildIds.push("up_child_"+ childIndex);
                            allChildIds.push("down_child_"+ childIndex);
                            props.updateState(allChildIds);
                        }
                        props.updateState({ childIndex: childIndex + 1 });
                        let divObj = document.createElement("div");
                        let actionDiv = document.createElement("div");
                        actionDiv.className = "actiontop ";
                        actionDiv.id = "action_child_" + childIndex;

                        divObj.id = "child_" + childIndex;
                        divObj.className = "alltime textres";
                        divObj.draggable = true;
                        divObj.ondragstart = window.drag;
                        divObj.class = "drag";

                        let mainCanvasDiv = document.createElement("textarea");
                        mainCanvasDiv.id = "editor_child_" + childIndex;
                        mainCanvasDiv.value= `Please enter your text here`;
       

                      
                        divObj.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false, childId: "child_" + childIndex, elementType: 'Text' }) };
                        

                        let CKEDITOR = window.CKEDITOR;
                        CKEDITOR.on('instanceReady', function (ev) {
                            document.getElementById(ev.editor.id + '_top').style.display = 'none';
                            ev.editor.on('focus', function (e) {
                                document.getElementById(ev.editor.id + '_top').style.display = 'block';
                                let activeChild = document.getElementById("child_" + childIndex);
                                if (activeChild) { activeChild.click(); }
                            });
                            ev.editor.on('blur', function (e) {
                                let edi = document.getElementById(ev.editor.id + '_top');
                                if (edi) { edi.style.display = 'none'; }
                            });
                        });
                        CKEDITOR.config.toolbar_Basic = [['Bold', 'Italic', 'Underline', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', '-', 'Undo', 'Redo', '-', 'Font', 'FontSize', 'TextColor', 'BGColor', 'Link']];
                        CKEDITOR.config.toolbar = 'Basic';
                        CKEDITOR.config.width = 680;
                        CKEDITOR.config.marginLeft = 10;
                        CKEDITOR.config.marginTop = 10;
                        CKEDITOR.config.marginBottom = 10;
                        CKEDITOR.config.removePlugins = 'elementspath';
                        CKEDITOR.config.autoGrow_minHeight = 100;
                        CKEDITOR.config.extraPlugins = 'autogrow';
                        CKEDITOR.config.autoGrow_onStartup = true;
                        CKEDITOR.config.autoGrow_bottomSpace = 10;

                       
                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box delet-icon";
                        deleteButton.style.display = "none";
                        deleteButton.title = 'Delete';
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            setTimeout(function () {comp.parentNode.removeChild(comp);},100);
                        };
                        divObj.appendChild(mainCanvasDiv);
                        actionDiv.appendChild(deleteButton);
                        let moveUpButton = document.createElement('i');
                        moveUpButton.className = "right-delate-box uparrow";
                        moveUpButton.style.display = "none";
                        moveUpButton.title = "Move up";
                        moveUpButton.id = "up_child_" + childIndex;
                        moveUpButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex - 1];
                                if (beforeNode) {
                                    comp.parentNode.insertBefore(comp, beforeNode);
                                    var hEd = window.CKEDITOR.instances["editor_child_" + childIndex];
                                    if (hEd) { hEd.destroy(); }
                                    window.CKEDITOR.replace("editor_child_" + childIndex);
                                }
                            }

                        };
                        actionDiv.appendChild(moveUpButton);
                        let moveDownButton = document.createElement('i');
                        moveDownButton.className = "right-delate-box downarrow";
                        moveDownButton.style.display = "none";
                        moveDownButton.title = "Move down";
                        moveDownButton.id = "down_child_" + childIndex;
                        moveDownButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex + 2];
                                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                                else { comp.parentNode.appendChild(comp); }
                                var hEd = window.CKEDITOR.instances["editor_child_" + childIndex];
                                if (hEd) { hEd.destroy();}
                                window.CKEDITOR.replace("editor_child_" + childIndex);
                            }
                        };
                        actionDiv.appendChild(moveDownButton);
                        
                        divObj.appendChild(actionDiv);
                        document.getElementById('temmplateDiv').appendChild(divObj);
                        CKEDITOR.replace("editor_child_" + childIndex);
                        divObj.scrollIntoView();
                    }}>
                        <div className="menu-img">
                            <img src={Textimg} alt="" /> </div>
                        Text
                    </li>
                    <li onClick={() => {
                        props.updateState({ isNewsLetterPropsUpdaterVisible: false, isNewsLetterThemeUpdaterVisible: false  });
                        let childIndex = props.childIndex || 1;
                        if (props.allChildIds.indexOf("child_" + childIndex) < 0) {
                            let allChildIds = props.allChildIds;
                            allChildIds.push("child_" + childIndex);
                            allChildIds.push("delete_child_" + childIndex);
                            allChildIds.push("up_child_"+ childIndex);
                            allChildIds.push("down_child_"+ childIndex);
                            props.updateState(allChildIds);
                        }
                        props.updateState({ childIndex: childIndex + 1 })
                        let mainCanvasDiv = document.createElement("div");
                        let actionDiv = document.createElement("div");
                        actionDiv.className = "actiontop ";

                        // mainCanvasDiv.innerHTML="<img class='' id="+'child_'+childIndex+">"
                        mainCanvasDiv.type = "file";
                        mainCanvasDiv.id = "child_" + childIndex;
                        mainCanvasDiv.className = "mobpic alltime"
                        mainCanvasDiv.style.width = "680px";
                        mainCanvasDiv.style.minHeight = "40px";
                        // mainCanvasDiv.style.border = "#ddd 1px solid";
                        mainCanvasDiv.style.textAlign = "center";
                        mainCanvasDiv.style.padding = "25px 0px 25px";
                        mainCanvasDiv.style.borderRadius = "5px";
                        mainCanvasDiv.style.background = "#eaf7ff";
                        mainCanvasDiv.style.margin = "20px auto 20px";
                        mainCanvasDiv.style.display = "inline-block";
                        mainCanvasDiv.draggable = true;
                        mainCanvasDiv.ondragstart = window.drag;
                        mainCanvasDiv.class = "drag";
                        mainCanvasDiv.style.cursor = "pointer";
                        let img = document.createElement('img');
                        img.id = "dummy_child_" + childIndex;
                        img.src = ImageIcon;
                        mainCanvasDiv.appendChild(img);

                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box delet-icon";
                        deleteButton.style.display = "none";
                        deleteButton.title ="Delete"
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            setTimeout(function () {comp.parentNode.removeChild(comp);},100);
                        };
                        actionDiv.appendChild(deleteButton);
                        let moveUpButton = document.createElement('i');
                        moveUpButton.className = "right-delate-box uparrow";
                         moveUpButton.style.display = "none";
                         moveUpButton.title ="Move up";
                        moveUpButton.id = "up_child_" + childIndex;
                        moveUpButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex - 1];
                                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                            }

                        };
                        actionDiv.appendChild(moveUpButton);
                        let moveDownButton = document.createElement('i');
                        moveDownButton.className = "right-delate-box downarrow";
                        moveDownButton.style.display = "none";
                        moveDownButton.title ="Move down"
                        moveDownButton.id = "down_child_" + childIndex;
                        moveDownButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex + 2];
                                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                                else { comp.parentNode.appendChild(comp); }
                            }
                        };
                        actionDiv.appendChild(moveDownButton);
                        mainCanvasDiv.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: comp ? true : false, childId: "child_" + childIndex, elementType: 'Image' })
                        };
                        document.getElementById('temmplateDiv').appendChild(mainCanvasDiv);
                        mainCanvasDiv.appendChild(actionDiv);
                        mainCanvasDiv.scrollIntoView();
                    }}>
                        <div className="menu-img">
                            <img src={Imageimg} alt="" />
                        </div>
                        Image
                    </li>
                    <li onClick={() => {
                        props.updateState({ isNewsLetterPropsUpdaterVisible: false, isNewsLetterThemeUpdaterVisible: false  });
                        let childIndex = props.childIndex || 1;
                        if (props.allChildIds.indexOf("child_" + childIndex) < 0) {
                            let allChildIds = props.allChildIds;
                            allChildIds.push("child_" + childIndex);
                            allChildIds.push("delete_child_" + childIndex);
                            allChildIds.push("up_child_"+ childIndex);
                            allChildIds.push("down_child_"+ childIndex);
                            props.updateState(allChildIds);
                        }
                        props.updateState({ childIndex: childIndex + 1 })
                        let mainCanvasDiv = document.createElement("div");
                        let actionDiv = document.createElement("div");
                        actionDiv.className = "actiontop ";

                        mainCanvasDiv.id = "child_" + childIndex;
                        mainCanvasDiv.className = "alltime";
                        mainCanvasDiv.style.width = "680px";
                        mainCanvasDiv.style.height = "20px";
                        mainCanvasDiv.style.cursor = "pointer"
                        // mainCanvasDiv.style.border = "#ddd 5px dotted";
                        mainCanvasDiv.style.padding = "10px 0px 10px";
                        mainCanvasDiv.style.margin = "20px auto ";
                        mainCanvasDiv.draggable = true;
                        mainCanvasDiv.ondragstart = window.drag;
                        mainCanvasDiv.class = "drag";
                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box delet-icon";
                        deleteButton.title = "Delete";
                        deleteButton.style.display = "none";
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            setTimeout(function () {comp.parentNode.removeChild(comp);},100);
                        };
                        actionDiv.appendChild(deleteButton);
                        let moveUpButton = document.createElement('i');
                        moveUpButton.className = "right-delate-box uparrow";
                        moveUpButton.style.display = "none";
                        moveUpButton.title = "Move up";
                        moveUpButton.id = "up_child_" + childIndex;
                        moveUpButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex - 1];
                                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                            }

                        };
                        actionDiv.appendChild(moveUpButton);
                        let moveDownButton = document.createElement('i');
                        moveDownButton.className = "right-delate-box downarrow";
                        moveDownButton.style.display = "none";
                        moveDownButton.title = "Move down";
                        moveDownButton.id = "down_child_" + childIndex;
                        moveDownButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex + 2];
                                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                                else { comp.parentNode.appendChild(comp); }
                            }
                        };
                        actionDiv.appendChild(moveDownButton);
                        mainCanvasDiv.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false, childId: "child_" + childIndex, elementType: 'Spacer' }) };
                        document.getElementById('temmplateDiv').appendChild(mainCanvasDiv);
                        mainCanvasDiv.appendChild(actionDiv);
                        mainCanvasDiv.scrollIntoView();
                    }}>
                        <div className="menu-img">
                            <img src={Spacerimg} alt="" />
                        </div>
                        Spacer
                    </li>
                    <li onClick={() => {
                        props.updateState({ isNewsLetterPropsUpdaterVisible: false, isNewsLetterThemeUpdaterVisible: false  });
                        let childIndex = props.childIndex || 1;
                        if (props.allChildIds.indexOf("child_" + childIndex) < 0) {
                            let allChildIds = props.allChildIds;
                            allChildIds.push("child_" + childIndex);
                            allChildIds.push("delete_child_" + childIndex);
                            allChildIds.push("up_child_"+ childIndex);
                            allChildIds.push("down_child_"+ childIndex);
                            props.updateState(allChildIds);
                        }
                        props.updateState({ childIndex: childIndex + 1 })
                        let mainCanvasDiv = document.createElement("div");
                        let actionDiv = document.createElement("div");
                        actionDiv.className = "actiontop ";

                        mainCanvasDiv.id = "child_" + childIndex;
                        mainCanvasDiv.className = "alltime divi-pic";
                        mainCanvasDiv.style.width = "680px";
                       // mainCanvasDiv.style.height = "5px";
                        mainCanvasDiv.style.cursor = "pointer"
                        //mainCanvasDiv.style.borderTop = "#ddd 1px solid";
                        mainCanvasDiv.style.padding = "5px auto 5px";
                        mainCanvasDiv.style.margin = "20px auto 20px";
                        mainCanvasDiv.draggable = true;
                        mainCanvasDiv.ondragstart = window.drag;
                        mainCanvasDiv.class = "drag";
                        let img = document.createElement('img');
                        img.id = "dummy_child_" + childIndex;
                        img.src = DividePic;
                        img.style.width = "670px";
                        mainCanvasDiv.appendChild(img);

                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box delet-icon";
                        deleteButton.style.display = "none";
                        deleteButton.title = "Delete";
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            setTimeout(function () {comp.parentNode.removeChild(comp);},100);
                        };
                        actionDiv.appendChild(deleteButton);
                        let moveUpButton = document.createElement('i');
                        moveUpButton.className = "right-delate-box uparrow";
                        moveUpButton.style.display = "none";
                        moveUpButton.title = "Move up";
                        moveUpButton.id = "up_child_" + childIndex;
                        moveUpButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex - 1];
                                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                            }

                        };
                        actionDiv.appendChild(moveUpButton);
                        let moveDownButton = document.createElement('i');
                        moveDownButton.className = "right-delate-box  downarrow";
                        moveDownButton.style.display = "none";
                        moveDownButton.title = "Move down";
                        moveDownButton.id = "down_child_" + childIndex;
                        moveDownButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex + 2];
                                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                                else { comp.parentNode.appendChild(comp); }
                            }
                        };
                        actionDiv.appendChild(moveDownButton);
                        mainCanvasDiv.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_" + childIndex, elementType: 'Divider' }) };
                        document.getElementById('temmplateDiv').appendChild(mainCanvasDiv);
                        mainCanvasDiv.appendChild(actionDiv);
                        mainCanvasDiv.scrollIntoView();
                    }}>
                        <div className="menu-img">
                            <img src={Dividerimg} alt="" />
                        </div>
                        Divider
                    </li>
                    <li onClick={() => {
                        props.updateState({ isNewsLetterPropsUpdaterVisible: false, isNewsLetterThemeUpdaterVisible: false  });
                        let childIndex = props.childIndex || 1;
                        if (props.allChildIds.indexOf("child_" + childIndex) < 0) {
                            let allChildIds = props.allChildIds;
                            allChildIds.push("child_" + childIndex);
                            allChildIds.push("delete_child_" + childIndex);
                            allChildIds.push("up_child_"+ childIndex);
                            allChildIds.push("down_child_"+ childIndex);
                            props.updateState(allChildIds);
                        }
                        props.updateState({ childIndex: childIndex + 1 });
                        let divObj = document.createElement("div");
                        let actionDiv = document.createElement("div");
                        actionDiv.className = "actiontop ";

                        divObj.id = "child_" + childIndex;
                        divObj.className = "mdilebutton alltime";
                        divObj.style.width = "680px";
                        // divObj.style.border = "#ddd 1px solid";
                        divObj.style.textAlign = "center";
                        divObj.style.borderRadius = "5px";
                        divObj.style.margin = "10px auto 10px";
                        divObj.style.display = "inline-block";
                       divObj.style.cursor = "pointer";
                        divObj.draggable = true;
                        divObj.ondragstart = window.drag;
                        divObj.class = "drag";

                        let mainCanvasDiv = document.createElement("a");
                        mainCanvasDiv.id = "button_child_" + childIndex;
                        mainCanvasDiv.innerHTML = 'Button';
                        mainCanvasDiv.style.float = 'left';
                        mainCanvasDiv.style.width = "150px";
                        mainCanvasDiv.style.height = "50px";
                        mainCanvasDiv.style.background = "#525156";
                        mainCanvasDiv.style.textAlign = "center";
                        mainCanvasDiv.style.color = "#fff";
                        mainCanvasDiv.style.lineHeight = "45px";
                        mainCanvasDiv.style.margin = "10px auto 10px 255px";
                        mainCanvasDiv.style.clear = "both";
                        mainCanvasDiv.style.cursor = "pointer";
                        mainCanvasDiv.style.border = "solid 1px #525156";
                        mainCanvasDiv.style.borderRadius = "4px";
                        mainCanvasDiv.className = "disabled-link";
                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box delet-icon";
                        deleteButton.style.display = "none";
                        deleteButton.title = "Delete";
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            setTimeout(function () {comp.parentNode.removeChild(comp);},100);
                        };
                        //mainCanvasDiv.appendChild(deleteButton);
                        divObj.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_" + childIndex, elementType: 'Button' }) };
                        divObj.appendChild(mainCanvasDiv);
                        actionDiv.appendChild(deleteButton);
                        let moveUpButton = document.createElement('i');
                        moveUpButton.className = "right-delate-box uparrow";
                        moveUpButton.style.display = "none";
                        moveUpButton.title = "Move up";
                        moveUpButton.id = "up_child_" + childIndex;
                        moveUpButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex - 1];
                                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                            }

                        };
                        actionDiv.appendChild(moveUpButton);
                        let moveDownButton = document.createElement('i');
                        moveDownButton.className = "right-delate-box downarrow";
                        moveDownButton.style.display = "none";
                        moveDownButton.title = "Move down";
                        moveDownButton.id = "down_child_" + childIndex;
                        moveDownButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                            if (dIndex > -1) {
                                let beforeNode = comp.parentNode.children[dIndex + 2];
                                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                                else { comp.parentNode.appendChild(comp); }
                            }
                        };
                        actionDiv.appendChild(moveDownButton);
                        document.getElementById('temmplateDiv').appendChild(divObj);
                        divObj.appendChild(actionDiv);
                        divObj.scrollIntoView();
                    }}>
                        <div className="menu-img">
                            <img src={Buttonimg} alt="" />
                        </div>
                        Button
                    </li>
                    {/* <li onClick={() => {
                         let childIndex = props.childIndex || 1;
                         props.updateState({ childIndex: childIndex + 1 })
                        let mainCanvasDiv = document.createElement("input");
                        mainCanvasDiv.id = "child_"+childIndex;
                        mainCanvasDiv.style.width = "680px";
                        mainCanvasDiv.style.height = "50px";
                        mainCanvasDiv.style.border = "#ddd 1px solid";
                        mainCanvasDiv.style.padding = "30px";
                        mainCanvasDiv.style.margin = "10px auto 10px";
                        let deleteButton = document.createElement('i');
                        deleteButton.className="right-delate-boxs fa fa-trash";
                        deleteButton.style.display = "none";
                        deleteButton.id = "delete_child_"+childIndex;
                        deleteButton.onclick = function(){
                            let comp = document.getElementById("child_"+childIndex);
                            (props && props.updateState) && props.updateState({isNewsLetterPropsUpdaterVisible: false });
                            comp.parentNode.removeChild(comp);
                        } ;
                        // mainCanvasDiv.appendChild(deleteButton);
                        mainCanvasDiv.onclick = function(){ (props && props.updateState) && props.updateState({isNewsLetterPropsUpdaterVisible: true, childId:"child_"+childIndex, elementType: 'Video' })} ;
                        document.getElementById('temmplateDiv').appendChild(mainCanvasDiv);
                    }}>
                        <div className="menu-img">
                            <img src={Videoimg} alt="" />
                        </div>
                        Video
                    </li> */}
                    <li onClick={() => {
                        props.updateState({ isNewsLetterPropsUpdaterVisible: false, isNewsLetterThemeUpdaterVisible: false });
                        //let childIndex = props.childIndex || 1;
                        let socialLinksComp = document.getElementById("child_socialLinks");
                        if (socialLinksComp) {
                            return showToasterMessage({
                                messageType: 'error', description: props.intl.formatMessage({ id: 'socialLinks.alreadyExists.messege' })
                            });
                        } else {
                            let childIndex = 'socialLinks';
                            if (props.allChildIds.indexOf("child_" + childIndex) < 0) {
                                let allChildIds = props.allChildIds;
                                allChildIds.push("child_" + childIndex);
                                allChildIds.push("delete_child_" + childIndex);
                                allChildIds.push("up_child_" + childIndex);
                                allChildIds.push("down_child_" + childIndex);
                                props.updateState(allChildIds);
                            }
                            // props.updateState({ childIndex: childIndex + 1 })
                            let mainCanvasDiv = document.createElement("div");
                            let actionDiv = document.createElement("div");
                            actionDiv.className = "actiontop ";

                            mainCanvasDiv.id = "child_" + childIndex;
                            mainCanvasDiv.className = "socialbutt alltime";
                            mainCanvasDiv.style.width = "680px";
                            // mainCanvasDiv.style.height = "50px";
                            // mainCanvasDiv.style.border = "#ddd 1px solid";
                            mainCanvasDiv.style.padding = "30px";
                            mainCanvasDiv.style.margin = "20px auto 20px";
                            mainCanvasDiv.style.fontSize = "24px";
                            mainCanvasDiv.draggable = true;
                            mainCanvasDiv.ondragstart = window.drag;
                            mainCanvasDiv.class = "drag";
                            if (props.companyAdditionalInfo && props.companyAdditionalInfo.templateSocialMeidaIcons) {
                                mainCanvasDiv.innerHTML = props.companyAdditionalInfo.templateSocialMeidaIcons;
                            } else {
                            mainCanvasDiv.innerHTML = '<a class="disabled-link" target="_blank" href="#" id=facebook_child_' + childIndex + ' style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;position: relative; vertical-align: middle;"> <img src=' + FaceBookIcon + ' alt="" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%); vertical-align: middle;" /> </a>'
                                + '<a class="disabled-link" target="_blank" href="#" id=twitter_child_' + childIndex + ' style= " cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;position: relative;vertical-align: middle;" ><img src=' + TwitterIcon + ' alt="" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%); vertical-align: middle;" /></a>'
                                + '<a class="disabled-link" target="_blank" href="#" id=instagram_child_' + childIndex + ' style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;position: relative;vertical-align: middle;"><img src=' + InstagramIcon + ' alt="" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%); vertical-align: middle;" /></a>'
                                + '<a class="disabled-link" target="_blank" href="#" id=google-plus_child_' + childIndex + ' style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;position: relative;vertical-align: middle;"><img src=' + GooglePlusIcon + ' alt="" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%); vertical-align: middle;" /></a>'
                                + '<a class="disabled-link" target="_blank" href="#" id=linkedin_child_' + childIndex + ' style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;position: relative;vertical-align: middle;"><img src=' + LinkedinIcon + ' alt="" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%); vertical-align: middle;" /></a>'
                                + '<a class="disabled-link" target="_blank" href="#" id=pinterest_child_' + childIndex + ' style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;position: relative;vertical-align: middle;"><img src=' + PinterestIcon + ' alt="" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%); vertical-align: middle;" /></a>'
                                + '<a class="disabled-link" target="_blank" href="#" id=tumblr_child_' + childIndex + ' style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;position: relative;vertical-align: middle;"><img src=' + TumblrIcon + ' alt="" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%); vertical-align: middle;" /></a>'
                                + '<a class="disabled-link" target="_blank" href="#" id=vimeo_child_' + childIndex + ' style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;position: relative;vertical-align: middle;"><img src=' + VimeoIcon + ' alt=""  style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%); vertical-align: middle;"/></a>'
                                + '<a class="disabled-link" target="_blank" href="#" id=youtube_child_' + childIndex + ' style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;position: relative;vertical-align: middle;"><img src=' + YouTubeIcon + ' alt="" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);vertical-align: middle;" /></a>'

                                ;
                            }
                            let deleteButton = document.createElement('i');
                            deleteButton.className = "right-delate-box delet-icon";
                            deleteButton.style.display = "none";
                            deleteButton.title = "Delete";
                            deleteButton.id = "delete_child_" + childIndex;
                            deleteButton.onclick = function () {
                                let comp = document.getElementById("child_" + childIndex);
                                (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                                setTimeout(function () { comp.parentNode.removeChild(comp); }, 100);
                            };
                            actionDiv.appendChild(deleteButton);
                            let moveUpButton = document.createElement('i');
                            moveUpButton.className = "right-delate-box uparrow";
                            moveUpButton.style.display = "none";
                            moveUpButton.title = "Move up";
                            moveUpButton.id = "up_child_" + childIndex;
                            moveUpButton.onclick = function () {
                                let comp = document.getElementById("child_" + childIndex);
                                let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                                if (dIndex > -1) {
                                    let beforeNode = comp.parentNode.children[dIndex - 1];
                                    if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                                }

                            };
                            actionDiv.appendChild(moveUpButton);
                            let moveDownButton = document.createElement('i');
                            moveDownButton.className = "right-delate-box downarrow";
                            moveDownButton.style.display = "none";
                            moveDownButton.title = "Move down";
                            moveDownButton.id = "down_child_" + childIndex;
                            moveDownButton.onclick = function () {
                                let comp = document.getElementById("child_" + childIndex);
                                let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
                                if (dIndex > -1) {
                                    let beforeNode = comp.parentNode.children[dIndex + 2];
                                    if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                                    else { comp.parentNode.appendChild(comp); }
                                }
                            };
                            actionDiv.appendChild(moveDownButton);
                            mainCanvasDiv.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_" + childIndex, elementType: 'Social Links' }) };
                            document.getElementById('temmplateDiv').appendChild(mainCanvasDiv);
                            mainCanvasDiv.appendChild(actionDiv);
                            mainCanvasDiv.scrollIntoView();
                        }
                    }}>
                        <div className="menu-img">
                            <img src={SocialMediaIcon} alt="" />
                        </div>
                        Social Link
                    </li>

                   {/* <li onClick={() => {
                        let childIndex = props.childIndex || 1;
                        props.updateState({ childIndex: childIndex + 1 })
                        let mainCanvasDiv = document.createElement("div");
                        // mainCanvasDiv.innerHTML="<img class='' id="+'child_'+childIndex+">"
                        mainCanvasDiv.id = "child_" + childIndex;
                        mainCanvasDiv.style.width = "680px";
                        mainCanvasDiv.style.minHeight = "40px";
                        //mainCanvasDiv.style.border = "#ddd 1px solid";
                        mainCanvasDiv.style.textAlign = "center";
                        mainCanvasDiv.style.alignContent = "center";
                        mainCanvasDiv.style.padding = "25px 0px 25px";
                        mainCanvasDiv.style.borderRadius = "5px";
                        // mainCanvasDiv.style.background = "#eaf7ff";
                        mainCanvasDiv.style.margin = "10px auto 10px";
                        mainCanvasDiv.style.display = "inline-block";
                        mainCanvasDiv.style.cursor = "pointer";

                        mainCanvasDiv.innerHTML = '<div id=bar_info_child_' + childIndex + ' style="margin: 0px 15px 0px 15px; width: 95%" >'
                            + '<span id=amount_child_' + childIndex + ' style= "float: left;"><b>$1315.00</b></span>'
                            + '<span id=amount_percent_child_' + childIndex + ' style= "float: right;" > <b>50% </b></span>'
                            + '</div><br/>'

                        let progressBar = document.createElement('progress');
                        progressBar.id = "progress_bar_child_" + childIndex;
                        progressBar.style.margin = "0px 15px 0px 15px";
                        progressBar.style.width = "95%";
                        progressBar.style.height = "15px";
                        progressBar.style.borderRadius = "5px";
                        progressBar.style.border = "#ddd 1px solid";
                        progressBar.style.display = "block";
                        progressBar.value = "50";
                        progressBar.max = "100";
                        progressBar.color = "#4CA185";
                        mainCanvasDiv.appendChild(progressBar);

                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box fa fa-trash";
                        deleteButton.style.display = "none";
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            comp.parentNode.removeChild(comp);
                        };
                        mainCanvasDiv.appendChild(deleteButton);
                        mainCanvasDiv.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: comp ? true : false, childId: "child_" + childIndex, elementType: 'Progress Bar' })
                        };
                        document.getElementById('temmplateDiv').appendChild(mainCanvasDiv);

                    }}>
                        <div className="menu-img">
                            <img src={Dividerimg} alt="" />
                        </div>
                        Progress Bar
                    </li>  
                    <li onClick={() => {
                        let childIndex = props.childIndex || 1;
                        props.updateState({ childIndex: childIndex + 1 });
                        let divObj = document.createElement("div");
                        divObj.id = "child_" + childIndex;
                        divObj.style.width = "680px";
                        // divObj.style.border = "#ddd 1px solid";
                        divObj.style.textAlign = "center";
                        divObj.style.borderRadius = "5px";
                        divObj.style.margin = "10px auto 10px";
                        divObj.style.display = "inline-block";
                        divObj.style.cursor = "pointer";

                        let mainCanvasDiv = document.createElement("a");
                        mainCanvasDiv.id = "link_child_" + childIndex;
                        mainCanvasDiv.innerHTML = 'Click Here';
                        mainCanvasDiv.style.float = 'left';
                        mainCanvasDiv.style.margin = "10px auto 10px 255px";
                        mainCanvasDiv.style.clear = "both";
                        mainCanvasDiv.style.cursor = "pointer";
                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box4 fa fa-trash";
                        deleteButton.style.display = "none";
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            comp.parentNode.removeChild(comp);
                        };
                        //mainCanvasDiv.appendChild(deleteButton);
                        divObj.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_" + childIndex, elementType: 'Link' }) };
                        divObj.appendChild(mainCanvasDiv);
                        divObj.appendChild(deleteButton);
                        document.getElementById('temmplateDiv').appendChild(divObj);
                    }}>
                        <div className="menu-img">
                            <img src={Socialimg} alt="" />
                        </div>
                        Link
                    </li>
 */}

                    {/* <li onClick={() => {
                        let childIndex = props.childIndex || 1;
                        if (props.allChildIds.indexOf("child_" + childIndex) < 0) {
                            let allChildIds = props.allChildIds;
                            allChildIds.push("child_" + childIndex);
                            allChildIds.push("delete_child_" + childIndex);
                            allChildIds.push("up_child_"+ childIndex);
                            allChildIds.push("down_child_"+ childIndex);
                            props.updateState(allChildIds);
                        }
                        props.updateState({ childIndex: childIndex + 1 });
                        let divObj = document.createElement("div");
                        let actionDiv = document.createElement("div");
                        actionDiv.className = "actiontop ";

                        divObj.className = "mdilebutton alltime";
                        divObj.id = "child_" + childIndex;
                        divObj.style.width = "680px";
                        // divObj.style.border = "#ddd 1px solid";
                        divObj.style.textAlign = "center";
                        divObj.style.borderRadius = "5px";
                        divObj.style.margin = "20px auto 20px";
                        divObj.style.display = "flex";
                        divObj.style.cursor = "pointer";
                        divObj.draggable=true;
                        divObj.ondragstart=window.drag;
                        divObj.class="drag";
                        divObj.dragenter=window.dragEnter;
                        divObj.dragover=window.dragOver;
                        divObj.dragleave=window.dragLeave;
                        
                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box delet-icon";
                        deleteButton.style.display = "none";
                        deleteButton.title = "Delete";
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            comp.parentNode.removeChild(comp);
                        };
                        //mainCanvasDiv.appendChild(deleteButton);
                      //  divObj.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_" + childIndex, elementType: 'Section' }) };
                       // divObj.appendChild(mainCanvasDiv);
                       actionDiv.appendChild(deleteButton);
                        document.getElementById('temmplateDiv').appendChild(divObj);
                        divObj.appendChild(actionDiv);
                        addSectionHandle("child_" + childIndex);
                    }}>
                        <div className="menu-img">
                            <img src={SectionIcon} alt="" />
                        </div>
                        Section
                    </li> */}

                    <li onClick={() => {
                        props.updateState({ isNewsLetterPropsUpdaterVisible: false, isNewsLetterThemeUpdaterVisible: false });
                        let footerComp = document.getElementById("child_footer");
                        if (footerComp) {
                            return showToasterMessage({
                                messageType: 'error', description: props.intl.formatMessage({ id: 'footer.alreadyExists.messege' })
                            });
                        } else {
                            let childIndex = 'footer';
                            if (props.allChildIds.indexOf("child_" + childIndex) < 0) {
                                let allChildIds = props.allChildIds;
                                allChildIds.push("child_" + childIndex);
                                allChildIds.push("delete_child_" + childIndex);
                                allChildIds.push("up_child_" + childIndex);
                                allChildIds.push("down_child_" + childIndex);
                                props.updateState(allChildIds);
                            }
                            // props.updateState({ childIndex: childIndex + 1 });
                            let divObj = document.createElement("div");
                            let actionDiv = document.createElement("div");
                            actionDiv.id = "action_child_"+ childIndex;
                            actionDiv.className = "actiontop ";

                            divObj.className = "mdilebutton alltime";
                            divObj.id = "child_" + childIndex;
                            divObj.style.width = "680px";
                            // divObj.style.border = "#ddd 1px solid";
                            divObj.style.textAlign = "center";
                            divObj.style.margin = "20px auto 20px";
                            divObj.style.cursor = "pointer";
                            divObj.draggable = true;
                            divObj.ondragstart = window.drag;
                            divObj.class = "drag";
                            divObj.dragenter = window.dragEnter;
                            divObj.dragover = window.dragOver;
                            divObj.dragleave = window.dragLeave;

                            let deleteButton = document.createElement('i');
                            deleteButton.className = "right-delate-box delet-icon";
                            deleteButton.style.display = "none";
                            deleteButton.title = "Delete";
                            deleteButton.id = "delete_child_" + childIndex;
                            deleteButton.onclick = function () {
                                let comp = document.getElementById("child_" + childIndex);
                                if (comp) {
                                    let allChildIds = props.allChildIds;
                                    allChildIds.splice(allChildIds.indexOf("child_footer"), 1);
                                    allChildIds.splice(allChildIds.indexOf("delete_child_footer"), 1);
                                    allChildIds.splice(allChildIds.indexOf("up_child_footer"), 1);
                                    allChildIds.splice(allChildIds.indexOf("down_child_footer"), 1);
                                    props.updateState(allChildIds);
                                }
                                (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                                setTimeout(function () {
                                    comp.parentNode.removeChild(comp);
                                }, 100);
                            };
                            divObj.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_" + childIndex, elementType: 'Footer' }) };
                            actionDiv.appendChild(deleteButton);
                            if (props.companyAdditionalInfo && props.companyAdditionalInfo.templateFooter) {
                                divObj.innerHTML = props.companyAdditionalInfo.templateFooter;
                                divObj.setAttribute('style', props.companyAdditionalInfo.templateFooterStyle || '');
                                divObj.appendChild(actionDiv);
                            } else {
                                divObj.appendChild(actionDiv);

                                let divAboutUs = document.createElement("div");
                                divAboutUs.id = "aboutus_child_" + childIndex;
                                divAboutUs.innerHTML = `<div id=about_title style="text-align: center; padding: 3px 8px;"> <b> About ${props.companyInfo.storeName || ''} </b></div>
                        <div id=about_desc style= "text-align: center; padding: 3px 8px;"> Please provide your orgnization info</div></br>`;
                                divObj.appendChild(divAboutUs);

                                let divLinks = document.createElement("div");
                                divLinks.id = "links_child_" + childIndex;
                                divLinks.innerHTML = `<div id=link1_message style="text-align: center; display: inline; padding: 3px 8px;">Click here to become member </div><a id=link1_URL href="#">Mecome Member</a></br>`;
                                divObj.appendChild(divLinks);

                                let divCopyright = document.createElement("div");
                                divCopyright.id = "copyright_child_" + childIndex;
                                divCopyright.innerHTML = `<div id=copyright_message style="text-align: center; padding: 3px 8px;"> Copyright @ 2022 All Right Reserved - ${props.companyInfo.storeName || ''} </b></div>
                        <div id=copyright_links style= "text-align: center; padding: 3px 8px;"><a id=term href="#">Term</a> | <a id=privacy href="#">Privacy</a> </div></br>`;
                                divObj.appendChild(divCopyright);

                                let divUnsubscribe = document.createElement("div");
                                divUnsubscribe.id = "unsubscribe_child_" + childIndex;
                                divUnsubscribe.innerHTML = `<div id=unsubscribe_message style="text-align: center; display: inline; padding: 3px 8px;">If you wish to no longer receive emails from ${props.companyInfo.storeName || ''}. Click Here to </div><a id=unsubscribe_URL href="#">Unsubscribe</a></br>`;
                                divObj.appendChild(divUnsubscribe);

                                let divContactUs = document.createElement("div");
                                divContactUs.id = "contactus_child_" + childIndex;
                                divContactUs.innerHTML = `<div id=contactus_title style="text-align: center; padding: 3px 8px;"> <b> Have a question </b></div>
                        <div id=contactus_rel_name style="text-align: center; padding: 3px 8px;"> <b> ${props.companyInfo.storeName || ''}</b></div>
                        <div id=contactus_address style= "text-align: center; padding: 3px 8px;"> Address</div>
                        <div id=contactus_phone style= "text-align: center; padding: 3px 8px;"> Phone</div>
                        <div id=contactus_email style= "text-align: center; padding: 3px 8px;"> Email</div></br>`;
                                divObj.appendChild(divContactUs);
                            }
                            divObj.className = "mdilebutton alltime";
                            document.getElementById('temmplateDiv').appendChild(divObj);
                            divObj.scrollIntoView();
                        }

                    }}>
                        <div className="menu-img">
                            <img src={FooterICon} alt="" />
                        </div>
                        Footer
                    </li>

                    {/* <li onClick={() => {
                        let childIndex = props.childIndex || 1;
                        props.updateState({ childIndex: childIndex + 1 });
                        let divObj = document.createElement("div");
                        let eventData = {
                            evenTitle: 'Its a Big Event',
                            eventDate: new Date(),
                            eventTime: '7:00 pm',
                            street1: '1002 Main Street', 
                            street2: 'Suite 232',
                            cityName: 'Concord', 
                            stateName: 'Concord', 
                            zipCode: '25696'
                        }
                        divObj.innerHTML = `<div style= "text-align: center; padding: 3px 8px;"> <b> ${eventData.evenTitle || ''} </b></div>
                        <div style= "text-align: center; padding: 3px 8px;"> ${eventData.eventDate ? getMomentDateForUIReadOnly({ date: new Date(eventData.eventDate), format }) : ''} ${eventData.eventTime || ''} </div>
                        <div style= "text-align: center; padding: 3px 8px;"> ${eventData.street1 || ''} ${eventData.street2 || ''} </div>
                        <div style= "text-align: center; padding: 3px 8px;"> ${eventData.cityName || ''}${eventData.stateName ? ', ' + eventData.stateName : ''}${eventData.zipCode ? ', ' + eventData.zipCode : ''} </div>
                        `;
                        divObj.id = "child_" + childIndex;
                        divObj.style.width = "680px";
                        // divObj.style.border = "#ddd 1px solid";
                        divObj.style.textAlign = "center";
                        divObj.style.borderRadius = "5px";
                        divObj.style.margin = "10px auto 10px";
                        divObj.style.display = "inline-block";
                        divObj.style.cursor = "pointer";
                        divObj.style.background= "#ED1C24";
                        divObj.className= "event-ups";
                        divObj.style.color= "#FFFFFF";



                        let deleteButton = document.createElement('i');
                        deleteButton.className = "right-delate-box4 fa fa-trash";
                        deleteButton.style.display = "none";
                        deleteButton.id = "delete_child_" + childIndex;
                        deleteButton.onclick = function () {
                            let comp = document.getElementById("child_" + childIndex);
                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                            comp.parentNode.removeChild(comp);
                        };
                        //mainCanvasDiv.appendChild(deleteButton);
                        divObj.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_" + childIndex, elementType: 'Event' }) };
                        divObj.appendChild(deleteButton);
                        document.getElementById('temmplateDiv').appendChild(divObj);
                      
                        props.updateState({eventDetails : {...props.eventDetails, ["child_" + childIndex]: eventData}});
                    }}>
                        <div className="menu-img">
                            <img src={SectionIcon} alt="" />
                        </div>
                        Event
                    </li>   */}
                </ul>

                {/* <div className="share-buts" onClick={() => {
                    const modalData = {
                        // title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                        modalBody: <NewsLetterShare {...props} style={{ overflow: 'auto' }} />,
                        hideFooter: true,
                        maskClosable: true,
                        hideCloseIcon: true,
                        hideTitle: true,
                        wrapClassName: 'pay-invoice-modals',
                                width: 600,
                    };
                    props.pushModalToStack(modalData);
                }}>Share <i className="fa fa-share-alt" ></i>
                    </div> */}
            </div>
            {/* <div className="lines"></div> */}
        </Fragment>
    )

}

export default injectIntl(TopLinks);