import React, { Fragment, useEffect, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";

import { ErrorMsg } from "../../general/ErrorMessage";
// import ProductDetailsTab from "./ProductDetailsTab";

import {  CONSTANTS } from "../../../static/constants";

import "@ant-design/compatible/assets/index.css";


import {
  
  Col,
  Row,
  Input,
  Tabs,

  Select,
 
  DatePicker
} from "antd";
import { getMomentDateForUI } from "../../../utils";
import { Dropdown } from "../../general/Dropdown";
import find from "lodash.find";

const TransportDetails = (props) => {
    const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    const vechileType = [{vehType :"Regular" ,referCode:"R"},{vehType:"Over Dimensional Cargo" ,referCode:"O"}]

  return (
    <div>
      <div>
      <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Transporter Details"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Sub Types of Supply"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Transporter ID'}
type="text"
value={props.transporterID}
onChange={(e) => {
props.updateState({
  transporterID: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<Dropdown
                // style={{ width: "210px" }}
                items={ ["Supply","Import","Export","Job Work","For Own Use","Job work Returns","Sales Return","SKD/CKD","Line Sales","Recipient Not Known","Exhibition or Fair"]}
                // valueKeyName="vehType"  
                // optionKeyName="referCode"
                value={props.subTypeOfSupply}
                placeholder={"Supply Type"}
                onSelect={(val) => {
                  
                  props.updateState({
                    subTypeOfSupply: val,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Other Sub Supply Description"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Mode of Transportation"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Supply Description'}
type="text"
value={props.otherSupplyDesc}
onChange={(e) => {
props.updateState({
  otherSupplyDesc: e.target.value,
});
}}
/>

</Col>
<Col span={12}>
                  <Dropdown
                // style={{ width: "210px" }}
                items={ ["Road" ,"Rail" ,"Air" ,"Ship"]}
                // valueKeyName="docType"
                // optionKeyName="referCode"
                value={props.modeOfTransportation}
                placeholder={"Transport Mode"}
                onSelect={(val) => {
                 
                  props.updateState({
                    modeOfTransportation: val,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
                  </Col>

</Row>
           </div> 
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Type of Vechile"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Distance of transportation (in Kms)"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

{/* <Col span={12}>
<TextBox
maxLength={20}
placeholder={'Type of Vechile'}
type="text"
value={props.vechileType}
onChange={(e) => {
props.updateState({
  vechileType: e.target.value,
});
}}
/>

</Col> */}
<Col span={12}>
                  <Dropdown
                // style={{ width: "210px" }}
                items={ vechileType ||[]}
                valueKeyName="vehType"  
                optionKeyName="referCode"
                value={(props.vechileType || {}).vehType}
                placeholder={"Vechile Type"}
                onSelect={(referCode) => {
                  const doc = find(vechileType, { referCode: referCode }) || {};
                  props.updateState({
                    vechileType: doc,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
              {/* <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.vechileType;
                }}
                message={"Vechile Type is required"}
              /> */}
                  </Col>
<Col span={12}>
<TextBox
placeholder={'Distance of Transportation'}
type="text"
maxLength={20}
value={props.transportationDistance}
onChange={(e) => {
props.updateState({
  transportationDistance: e.target.value,
});
}}
/>

</Col>
</Row>
           </div> 
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Transporter Document No."
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Transporter Document Date"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Transporter Document No.'}
type="text"
value={props.transporterDocumentNo}
onChange={(e) => {
props.updateState({
  transporterDocumentNo: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<DatePicker
                    getPopupContainer={(trigger) => trigger.parentNode}
                    format={format}
                    key={`${props.transporterDocumentDate
                      ? getMomentDateForUI({
                        date: new Date(props.documentDate),
                        format,
                      })
                      : null
                      }`}
                    defaultValue={
                      props.transporterDocumentDate
                        ? getMomentDateForUI({
                          date: new Date(props.documentDate),
                          format,
                        })
                        : null
                    }
                    onChange={(selectedDate) => {
                      props.updateState({
                        transporterDocumentDate: selectedDate,
                      });
                    }}
                    placeholder={'Transporter Document Date'}
                  />

</Col>
</Row>
           </div> 
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Vechile No."
    defaultMessage=""
  />
  

</Col>


</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Vechile No.'}
type="text"
value={props.VechileNo}
onChange={(e) => {
props.updateState({
  VechileNo: e.target.value,
});
}}
/>

</Col>


</Row>
           </div> 
      </div>
      <div>
      {
    props.optionalFields ? (
<div className="mt20">
   <div className="drawer-heading">Optional Details</div>
   <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Reference Invoice Number in case of CKD/SKD"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Reference Invoice date  in case of CKD/SKD"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Reference Invoice Number'}
type="text"
value={props.referenceInvoiceNo2}
onChange={(e) => {
props.updateState({
    referenceInvoiceNo2: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<DatePicker
                    getPopupContainer={(trigger) => trigger.parentNode}
                    format={format}
                    key={`${props.referenceInvoiceDate2
                      ? getMomentDateForUI({
                        date: new Date(props.referenceInvoiceDate2),
                        format,
                      })
                      : null
                      }`}
                    defaultValue={
                      props.referenceInvoiceDate2
                        ? getMomentDateForUI({
                          date: new Date(props.referenceInvoiceDate2),
                          format,
                        })
                        : null
                    }
                    onChange={(selectedDate) => {
                      props.updateState({
                        referenceInvoiceDate2: selectedDate,
                      });
                    }}
                    placeholder={'Reference Invoice Date'}
                  />

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Transporter Name"
    defaultMessage=""
  />
  

</Col>


</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={' Transporter Name'}
type="text"
maxLength={20}
value={props.transporterName}
onChange={(e) => {
props.updateState({
    transporterName: e.target.value,
});
}}
/>

</Col>


</Row>
           </div>
          
   </div>
    ):''
   }
      </div>
    </div>
  )
}

export default TransportDetails