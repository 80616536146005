import React from "react";
import ReactApexChart  from "react-apexcharts"

class CashFlowChart extends React.Component {
    
    render() {
        const series = [{
            name: (this.props.companyInfo || {}).currencyCode + " ",
            data: Object.values(this.props.customerCashflow || {}).map((e) => {
                return Math.round(e);
            })
        }];
        const options = {
          chart: {
            height: 350,
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          markers: {
            size: 3,
            colors: '#000',
            strokeColors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
              size: undefined,
              sizeOffset: 3
            }
          },
          colors: ['#656b79'],
          dataLabels: {
            enabled: false
          },
          stroke: {
              show:true,
            curve: 'straight',
            lineCap: 'round',
            colors: undefined,
            width: 2,
            dashArray: 0,  
          },
          grid: {
            markers: {
                size: .5
              },
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: Object.keys(this.props.salesData || {}),
          }
        }


        return (
            <div id="chart">
            <ReactApexChart options={options} series={series} type="line" height={250} />
          </div>
        );
    }
}

export default CashFlowChart;