import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TeamDrawerComponent from "../../../../../components/drawer/fundraising/campaign/team";
import { pushModalToStack, popModalFromStack, showModal, hideModal } from './../../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded } from "../../../../../utils";
import { getAllTeamLead, saveTeamMaster } from "./action";


class TeamDrawer extends Component {
  constructor(props) {
    super(props);
    let teamMaster = props.teamMaster || {};
    this.state = {
      ...teamMaster,
      contactId: ((teamMaster.teamMemberList || [])[0] || {}).contactId,
      goal: teamMaster.teamGoal
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });

    fetchPaginationDataIfNeeded("getAllTeamLead", "teamLeadList", this.props, { relationshipId: this.props.companyInfo.relationshipId, pageNumber: 0, pageSize: 100 });
  }

  render() {
    return (
      <TeamDrawerComponent
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></TeamDrawerComponent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    teamLeadList: state.fundraisingReducer.teamLeadList
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushModalToStack,
      popModalFromStack,
      getAllTeamLead,
      saveTeamMaster,
      showModal,
      hideModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeamDrawer);
