import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, PURCHASE_ORDER_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchPurchaseOrders = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getAllPurchaseOrders?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_LIST;
          err.data = {
            pageNo: payload.pageNumber || 1,
            list: []
          };
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}
export const getPurchaseOrdersCount = (payload, history) => {
  let FY = getFinancialYear(payload.companyInfo);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getTotalOrderCountByRid?relationshipId=${payload.relationshipId}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
      .then(res => {
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_COUNT;
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  }
}
export const resetPaginatedData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_LIST_PAGE_RESET });
  }
}

export const fetchPurchaseOrderDrafts = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getAllDraftData?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
      .then(res => {
        let list = res.data.map(obj => {
          let rObj = {...obj};
          rObj.purchaseOrderData = JSON.parse(obj.purchaseOrderData);
          return rObj;
       })
        const data = {
          pageNo: payload.pageNumber,
          list: list
        }
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_DRAFT_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_DRAFT_LIST;
          err.data = {
            pageNo: payload.pageNumber,
            list: []
          };
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
  }
}

export const getPurchaseOrderDraftCount = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getDraftCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_DRAFT_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_DRAFT_COUNT;
        }
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_HEADER_LOADER });
      })
  }
}
export const resetDraftPaginatedData = () => {
  return dispatch => {
    dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_DRAFT_LIST_PAGE_RESET });
  }
}

export const deletePurchaseOrder = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/deletePurchaseOrder`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        //showToasterMessage({ messageType: 'success', description: (res.data || {}).message || 'Purchase Order has been deleted successfully' });
        showToasterMessage({messageType: 'success',  description: lang[(res.data || {}).message] || 'Purchase Order has been deleted successfully' });
        props.fetchPurchaseOrders(props);
        props.getPurchaseOrdersCount(props);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
      })
  }
}


export const updateUserStatus = (payload, props) => {
  return dispatch => {

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/updateUserStatus`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'User status updated' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchPurchaseOrders(props);
        props.fetchPurchasedOrderDetail && props.fetchPurchasedOrderDetail(payload); 
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}



export const fetchPurchaseOrdersByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getPOSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_LIST;
          err.data = {
            pageNo: payload.pageNumber || 1,
            list: []
          };
        }
      })
  }
}

export const getPurchaseOrdersCountByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/countPOSummary?relationshipId=${payload.relationshipId}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_COUNT;
        }
      })
  }
}