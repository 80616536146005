import React from "react";
import { Select, Row, Col, DatePicker, Space, Button } from 'antd';
import { Dropdown } from "../../../../general/Dropdown";
import { TextBox } from "../../../../general/TextBox";
import ReactHtmlParser from 'react-html-parser';
import { MODAL_TYPE } from "../../../../../static/constants";
import { FormattedMessage } from "react-intl";
import find from "lodash.find";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
import filter from "lodash.filter";


function CampaignDetails(props) {
    const onSearch = (value) => {
    };
    const { RangePicker } = DatePicker;
    const onChange = (value, dateString) => {
    };
    const onOk = (value) => {
    };

    const handleNewProjectAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PROJECT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }
    const deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {

            case MODAL_TYPE.PROJECT: {
                deleteFunc = props.deleteProject;
                break;
            }


            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({
                    id: payload.id,
                    relationshipId: companyInfo.relationshipId,
                    customerId: (props.customer || {}).customerId,
                });
            },
        };
        showModal(modalData);
    };

    const handleSubmit = async() => {

        props.updateState({ submitted: true });
        if (!props.campaignName || !(props.selectedDg || [])?.length || !props.subject) {
            return;
        }
        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            campaignName: props.campaignName,
            projectName: props.projectName,
            projectId: props.projectMasterId,
            templateId: props.selectedTemplate?.alpideFormBuilderId,
            templateName: props.selectedTemplate?.templateName,
            createdByUserId: props.userInfo?.userId,
            // dgGroupId:(props.selectedDg) || [],
            createdByUserName: props.userInfo?.firstName + " " + props.userInfo?.lastName,
            campaignMarketingId: !props.isReplicate ? props.campaignMarketingId : null,
            emailData: {
                to: props.dgDetails?.length && (props.dgDetails || []).map((val) => {
                    return val.emailAddress
                }) || [],
                cc: [],
                bcc: [],
                subject: props.subject,
                body: props.selectedTemplate?.templateContent
            }
        }

        const modalData = {
            modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />,
            handleSubmit: () => {
                props.hideModal();
                props.createCampaign(payload, props);
            },
        };
        props.showModal(modalData);
    }

    return <>

        <div className="formArea">
            <div className="area-content">


                <div className="buttonArea">

                    <button type="button" class="ant-btn grey-button"
                        onClick={() => {
                            props.showModal({
                                modalBody: ReactHtmlParser((props.selectedTemplate || {})?.templateContent),
                                width: "100%",
                                height: "100%",
                                hideFooter: true,
                                hideTitle: true,
                                wrapClassName: "image-preview",
                            });
                        }}>Preview</button>
                    <button onClick={() => {
                        props.updateState({
                            marketingCampaignDrawer: true
                        })
                    }} type="button" class="ant-btn grey-button" >Send a Test Email</button>

                </div>

                <div style={{ marginTop: "-30px" }}>{ReactHtmlParser((props.selectedTemplate || {})?.templateContent)}</div>

                <div className="pb60" />


            </div>

            <div className="area-content">
                <div style={{ minWidth: "750px", margin: "5%" }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <div className="i-label">Project</div>
                            <Dropdown
                                lastColumnCell={false}
                                items={props.projectList}
                                valueKeyName='projectName'
                                value={props.projectName}
                                optionKeyName='projectMasterId'
                                projectIdKey='projectMasterId'
                                canAddNew={true}
                                onAddNewClick={(payload) => {
                                    payload.formData = {
                                        projectName: payload.projectName,
                                    }
                                    handleNewProjectAddition(props, payload, MODAL_TYPE.PROJECT)
                                }}
                                onSelect={(selectedValue, option) => {
                                    const selectedProject = find(props.projectList, { projectMasterId: Number(option.key) }) || {};
                                    props.updateState({
                                        projectMasterId: selectedProject.projectMasterId,
                                        projectName: selectedProject.projectName,
                                        projectNumber: selectedProject.projectNumber
                                    });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    props.updateState({
                                        projectMasterId: undefined,
                                        projectName: undefined,
                                        projectNumber: undefined
                                    });
                                }}
                            />

                        </Col>
                        <Col span={24}>
                            <div className="i-label">Campaign Name <span>*</span></div>
                            <TextBox
                                value={props.campaignName}
                                onChange={(e) => {
                                    props.updateState({ campaignName: e.target.value });
                                }}
                            />
                            <ErrorMsg
                                validator={() => { return !props.submitted || props.campaignName }}
                                message={<FormattedMessage id='requiredField.missing.msg' />}
                            />
                        </Col>
                        <Col span={24}>
                            <div className="i-label">Subject <span>*</span></div>
                            <TextBox
                                value={props.subject}
                                onChange={(e) => {
                                    props.updateState({ subject: e.target.value });
                                }}
                            />
                            <ErrorMsg
                                validator={() => { return !props.submitted || props.subject }}
                                message={<FormattedMessage id='requiredField.missing.msg' />}
                            />
                        </Col>
                        <Col span={24}>
                            <div className="i-label">To <span>*</span></div>

                            <MultipleSelectDropdown
                                style={{ width: '100%' }}
                                items={props.dgList || []}
                                mode='multiple'
                                onDeSelect={(value) => {
                                    let { selectedDg } = props
                                    selectedDg = filter(selectedDg, (distributionGroupId) => {
                                        return distributionGroupId !== value;
                                    });
                                    props.updateState({ selectedDg });
                                }}
                                valueKeyName='dgName'
                                selectedValue={(props.dgList || []).length ? (props.selectedDg || []) : []}
                                optionKeyName='distributionGroupId'
                                onSelect={(distributionGroupId) => {
                                    let selectedDg = props.selectedDg || [];
                                    if (selectedDg.indexOf(distributionGroupId) === -1) {
                                        selectedDg.push(distributionGroupId);
                                        const payload = {
                                            distributionGroupId,
                                            relationshipId: props.relationshipId
                                        };
                                        props.getDGDetails(payload);
                                    } else {
                                        selectedDg = selectedDg.filter((el) => el !== distributionGroupId);
                                    }
                                    props.updateState({ selectedDg });
                                }}

                            />
                            <ErrorMsg
                                validator={() => { return !props.submitted || props.selectedDg }}
                                message={<FormattedMessage id='requiredField.missing.msg' />}
                            />
                        </Col>
                        {(props.senderEmails || []).length ?
                            <Col span={24}>
                                <div className="i-label">From <span>*</span></div>
                                <Dropdown
                                    items={props.senderEmails || []}
                                />
                            </Col>
                            : ""
                        }
                        <Col span={24}>
                            <div className="i-label">Email Povider </div>
                            <Dropdown
                                items={props.brevoSettings || []}
                                optionKeyName='brevoEmailSettingId'
                                valueKeyName='userDefinedName'
                            />
                        </Col>

                    </Row>
                    <div class="footer">
                        <div class="left-fItems">
                            <Button onClick={() => {

                                // props.updateState({ activeTab: `${Number(props.activeTab || 1) - 1}` });
                                props.updateState({ activeTab: "1" });

                            }}>Back</Button>
                        </div>
                        <div class="right-fItems">
                            <Button type="primary"  onClick={() => {
                                handleSubmit();
                            }}>
                                Send Email
                            </Button>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    </>
}

export default CampaignDetails;