import { Button, Checkbox, Input } from 'antd';
import React, { useState } from 'react';
import ImagePlaceholder from "../../../../../assets/images/img-placeholder-alpide.jpg";
import { FormattedMessage } from "react-intl";
import { getAWSFileURL } from '../../../../../utils';
import config from '../../../../../config/environmentConfig';
const { Search } = Input;

const CategorySelection = (props) => {
  const [searchText, setSearchText] = useState("");
  const [selectCategory, setSelectCategory] = useState(() => {
    const setting = props.returnSet ? props.returnSetting : props.replaceSet? props.replaceSetting:props.cancelSetting;
    const notReturnableCategoryRefs = setting?.notReturnableCategoryRefs || [];
    return notReturnableCategoryRefs.map(item => item.inventoryItemCategoryId);
});



  const handleCategorySelect = () => {

    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      categoryIds: selectCategory || [],
      pageSize: 50,
      pageNumber: 0
    }
    props.getItemByCategory(payload, props);


    const commonSettings = {
      notReturnableCategoryRefs: selectCategory.map((value) => ({
        inventoryItemId: null,
        inventoryItemCategoryId: value,
        relationshipId: props.relationshipId,
      })),
    };
  
    if (props.returnSet) {
       props.updateState({ returnSetting: { ...props.returnSetting, ...commonSettings }, init: true });
    }
     if (props.replaceSet) {
       props.updateState({ replaceSetting: { ...props.replaceSetting, ...commonSettings }, init: true });
    }
    if (props.cancelSet) {
      props.updateState({ cancelSetting: { ...props.cancelSetting, ...commonSettings }, init: true });
   }
    props.popModalFromStack();
  };


  const getsubcategories = (subcategories, depth = 1) => {
    return subcategories.map((childSubcategories, i) => (
      <>
        <div
          key={childSubcategories.inventoryItemCategoryId + i}
          className="radio-button-Wrapper"
          style={{ paddingLeft: `${depth * 12}px` }}
        >
          <Checkbox value={childSubcategories?.inventoryItemCategoryId}>
            <div className="parent-category-sub-wrapper">
              <div className="category-img">
                {childSubcategories.awsKeyName ? <img
                  src={getAWSFileURL(
                    (childSubcategories || {}).awsKeyName,
                    config.BUCKET_NAME.BO_INVENTORY
                  )}
                  alt="U"
                />
                  :
                  <img style={{ height: "30px" }} src={ImagePlaceholder} alt="U" />
                }
              </div>
              <div>
                <h2>{childSubcategories.categoryName}</h2>
                <p>{childSubcategories.totalProducts}</p>
              </div>
            </div>
          </Checkbox>
        </div>
        {(childSubcategories.subcategories || []).length
          ? getsubcategories(childSubcategories.subcategories, depth + 1)
          : ""}
      </>
    ));
  };

  return (
    <>
      <div className="cat-search-wrapper">
        <Search
          placeholder="Search categories or subcategories..."
          value={props.searchCategories}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
      <div className="parent-category-list">
        <Checkbox.Group

          onChange={(e) => {
            setSelectCategory(e)

          }}
          value={selectCategory || []}
          width="100%"
        >
          {(searchText ? props.productCategories.filter((category) => {
            return category.categoryName?.toLowerCase().includes(searchText?.toLowerCase());
          }) : props.productCategories)
            .filter((rowData) => rowData.parentId === 0)

            .map((category) => (
              <>
                <div
                  key={category.inventoryItemCategoryId}
                  className="radio-button-Wrapper"
                >
                  <Checkbox
                    key={category.inventoryItemCategoryId}
                    value={category.inventoryItemCategoryId}

                  >
                    <div className="parent-category-sub-wrapper">
                      <div className="category-img">
                        {category?.category ? <img
                          src={getAWSFileURL(
                            (category || {}).category,
                            config.BUCKET_NAME.BO_INVENTORY
                          )}
                          alt="U"
                        /> :
                          <img style={{ height: "30px" }} src={ImagePlaceholder} alt="U" />}
                      </div>
                      <div className="parent-category-details">
                        <h2>{category.categoryName}</h2>
                        <p>{`${(category.inventoryItemCategoryRefs || 0).length} products`}</p>
                      </div>
                    </div>
                  </Checkbox >
                </div>
                {(category.subcategories || []).length ? getsubcategories(category.subcategories) : ""}
              </>
            ))}
        </Checkbox.Group>
        <div className="custom-footer-parent-category">



          <Button
            type="primary"
            onClick={() => {
              handleCategorySelect();
            }}
          >
            <FormattedMessage id="button.select.label" defaultMessage="" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default CategorySelection