import { Button, Col, Row, Select, Switch, Tooltip } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "../../../../eStore/storeSettings/store-settings.scss";
import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import cardlogo from "../../../../../assets/images/card-logo.jpg";
import { Dropdown } from "../../../../general/Dropdown";
const { Option } = Select;
const PreferencesComp = (props) => {
  const SenderEmail = () => {};

  

  return (
    <div className="store-model-container cus-space">
      <div className="store-main-container">
      <div className="model-head">
        <p className="heading">Auto-accept Orders</p>
      </div>
      <div className="model-container">
      <Form layout="vertical" hideRequiredMark className="pr30">
        <Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Row className="f-item-center">
                  <Col span={12} className="text-right">
                    <FormattedMessage
                      id="modal.eStore.storeSettings.CodOrders"
                      defaultMessage=""
                    />
                    <Tooltip placement="top" arrow={false} title={"Enables offline payments like cash, UPI or other custom methods"}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col span={12}>
                    <Switch
                      checked={
                       props.storeData?.isCod
                      }
                      onClick={(checked, e) => {
                        const val = props.storeData;
                        let tempFilter = val;
                                        tempFilter.isCod = tempFilter.isCod === 1 ? 0 : 1;
                                        props.updateState({val: tempFilter})
                       
                        props.setCODorder({
                          relationshipId: props.relationshipId,
                          isCod: checked ? 1 : 0,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Row className="f-item-center">
                  <Col span={12} className="text-right">
                    <FormattedMessage
                      id="modal.eStore.storeSettings.makeAnEnquiry"
                      defaultMessage=""
                    />
                    <Tooltip placement="top" arrow={false} title={"Enables enquiry on Product or Cart"}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col span={12}>
                    <Switch
                      checked={
                        props.storeData?.isEnquiry
                      }
                      onClick={(checked, e) => {
                        const val = props.storeData;
                        let tempFilter = val;
                                        tempFilter.isEnquiry = tempFilter.isEnquiry === 1 ? 0 : 1;
                                        props.updateState({val: tempFilter})
                        props.setMakeAnEnquiry({
                          relationshipId: props.relationshipId,
                          isCod: checked ? 1 : 0,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Row className="f-item-center">
                  <Col span={12} className="text-right">
                    <FormattedMessage
                      id="modal.eStore.storeSettings.onlineOrders"
                      defaultMessage=""
                    />
                    <Tooltip placement="top" arrow={false} title={"Enables online payments from the selected Payment Gateway"}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col span={12}>
                    <Switch
                      checked={props.storeData?.isOnlineOrders}
                      onClick={(checked, e) => {
                        const val = props.storeData;
                        let tempFilter = val;
                                        tempFilter.isOnlineOrders = tempFilter.isOnlineOrders === 1 ? 0 : 1;
                                        props.updateState({val: tempFilter})
                        props.setOnlineOrder({
                          relationshipId: props.relationshipId,
                          isCod: checked ? 1 : 0,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Row className="f-item-center">
                  <Col span={12} className="text-right">
                    <FormattedMessage
                      id="modal.eStore.storeSettings.CancelOrder"
                      defaultMessage=""
                    />
                    <Tooltip placement="top" arrow={false} title={"Enables cancellation request on successful orders"}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col span={12}>
                    <Switch
                      checked={props.isallowCancelOrder}
                      onClick={(checked, e) => {

                        const val = props.additionalValue;
                        const additionalValues = {
                          ...val ,
                          isallowCancelOrder: checked
                        };
                        
                        props.updateState({isallowCancelOrder: checked })
                                       
                                      
                                        props.updateAdditionalValue({
                                                  additionalValues:JSON.stringify(additionalValues|| {}),
                                                  relationshipId:props.userInfo.relationshipId
                                              },props)
                                             
                       
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
                <Col span={12}>
                {props.storeData?.isOnlineOrders ? (
                      <span>
                        <div className="remarks-title">
                          <FormattedMessage
                            id="select.gateway"
                            defaultMessage=""
                          />
                          <img
                            src={cardlogo}
                            alt=""
                            style={{ marginLeft: "10px" }}
                          />
                        </div>

                        <Select
                          style={{ width: "60%" }}
                          items={props.paymentGatewayName || []}
                          value={props.paymentGatewayName[0]?.paymentGatewayName}
                          onSelect={(selectedId, obj) => {
                            props.updateState({
                              paymentGatewayId: obj.key,
                              paymentGatewayName: obj.value,
                            });
                          }}
                          allowClear={true}
                          // onClear={() => {
                          //   this.props.updateState({
                          //     paymentGatewayId: undefined,
                          //     paymentGatewayName: undefined,
                          //   });
                          // }}
                        >
                          {/* {(props.paymentGatewayName || []).map(
                            (gatway) => {
                              return (
                                <Option
                                  key={gatway.alpidePaymentGatewayId}
                                  value={gatway.paymentGatewayName}
                                >
                                  <FormattedMessage
                                    id={gatway.paymentGatewayName}
                                  />
                                </Option>
                              );
                            }
                          )} */}
                        </Select>

                        <Tooltip
                          placement="top"
                          title={"To add payment gateway, visit Integration -> Payment Page"}
                          trigger="click"
                        >
                          <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                      </span>
                    ) : (
                      ""
                    )}
                </Col>
          </Row>

          {/* <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Row className="f-item-center">
                  <Col span={12} className="text-right">
                    <FormattedMessage
                      id="modal.eStore.storeSettings.wireTransfers"
                      defaultMessage=""
                    />
                    <Tooltip placement="top" arrow={false} title={"tool tip"}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                  <Col span={12}>
                    <Switch
                      checked={props.storeSettings?.isWireTransferCheck === 1}
                      onClick={(checked, e) => {
                        props.updateState({
                          storeSettings: {
                            ...props.storeSettings,
                            isWireTransferCheck: checked ? 1 : 0,
                          },
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row> */}
        </Form.Item>
      </Form>
      </div>
     

      {/* <div className="model-head">
        <p className="heading">Auto-accept orders</p>
        <p className="sub-heading">
          Send order notifications to the customers automatically.
        </p>
        <Row gutter={10} style={{ marginTop: "10px" }}>
          <Col span={24}>
            <Form.Item>
              <Row className="f-item-center">
                <Col span={12} className="text-right">
                  <FormattedMessage
                    id="modal.eStore.storeSettings.senderEmail"
                    defaultMessage=""
                  />
                  <Tooltip placement="top" arrow={false} title={"tool tip"}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <div className="col-right">
                    <p onClick={SenderEmail}>reply@mydukaan.io</p>
                    <RightOutlined />
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              <Row className="f-item-center">
                <Col span={12} className="text-right">
                  <FormattedMessage
                    id="modal.eStore.storeSettings.whatsAppNotifications"
                    defaultMessage=""
                  />
                  <Tooltip placement="top" arrow={false} title={"tool tip"}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Col>
                <Col span={12}>
                  <div className="col-right">
                    <p>None</p> <RightOutlined />
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </div> */}
      </div>
    </div>
  );
};

export default PreferencesComp;
