import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginWorkFlowComp from '../../../../../components/modal/modalBody/eStore/LoginWorkFlow';


class LoginWorkFlow extends Component {
    constructor(props){
        super(props);
        this.state= {
            // loginOption : "Option 01"   ,
            // heading: "Login by Email & Phone"
        }
    }
       
    componentWillMount() {
       
        const companyInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            
        };

    }
    

    componentWillReceiveProps(props){
      
    
      }
    
    render() {
        return <>
            <LoginWorkFlowComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        userInfo: state.common.userInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        storeData:state.common.store_values,
     
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
   
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(LoginWorkFlow);