import React, { useRef, useEffect, Fragment, useState } from 'react';
// import { Link } from 'react-router-dom';
import Resizer from "react-image-file-resizer";
import config from '../../config/environmentConfig';
import { base64Toblob, getAWSFileURL, addToLocalStorage, fetchFromLocalStorage, getMomentDateForUIReadOnly, getInitialsFromString, capitalizeFirstLetter } from '../../utils';
import { logout } from '../../actions/commonActions';
import { ImageCropper } from '../../components/general/ImageCropper';
import { CustomUploader } from "../../components/general/CustomUploader";
import { CONSTANTS, LOCAL_STORAGE_KEYS } from '../../static/constants';
import CompanyInfo from '../../containers/modal/modalBody/settings/CompanyInfo';
import { FormattedMessage } from 'react-intl';
import ChangePassword from '../../containers/modal/modalBody/settings/ChangePassword';
// import * as find from 'lodash.find';
import { Button } from 'antd';
import { useCookies } from 'react-cookie';
// import axios from 'axios';


export const SignoutForm = (props) => {
    let selectedLanguage = fetchFromLocalStorage("language") || "en";
    const [profileIcon, updateProfileIcon] = useState();
    const [, , removeCookies] = useCookies();
    const [loadings, setLoadings] = useState(false);
    
    //const [profileIconUrl, updateProfileIconUrl] = useState();

    const [profilePicVisible, setProfilePicVisible] = useState(true);

    const cropperRef = useRef();
    const { relationshipEmployees } = props.companyInfo || {};
    const profileImgUrl = getAWSFileURL((props.companyInfo || {}).logoAwsObjectKey, config.BUCKET_NAME.BO_RELATIONSHIP);
   
    const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();

        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });

        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);

            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    if (((cropperRef.current &&
                        cropperRef.current.contains(e.target)) || e.target.contains(cropperRef.current)) ||
                        (e.target.classList.value.includes("ant-btn"))
                    ) {
                        return;
                    }
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep

        return innerRef; // return ref; client can omit `useRef`
    }

    const innerRef = useOuterClick(e => {
        props.updateState({
            showUserDropdown: false
        })
    });
    // const getMonthName = function (month) {
    //     switch (month) {
    //         case 1: {
    //             return <span><FormattedMessage id='january' defaultMessage='' />   </span>
    //         }
    //         case 2: {
    //             return <span><FormattedMessage id='february' defaultMessage='' />   </span>
    //         }
    //         case 3: {
    //             return <span><FormattedMessage id='march' defaultMessage='' />   </span>
    //         }
    //         case 4: {
    //             return <span><FormattedMessage id='april' defaultMessage='' />   </span>
    //         }
    //         case 5: {
    //             return <span><FormattedMessage id='may' defaultMessage='' />   </span>
    //         }
    //         case 6: {
    //             return <span><FormattedMessage id='june' defaultMessage='' />   </span>
    //         }
    //         case 7: {
    //             return <span><FormattedMessage id='july' defaultMessage='' />   </span>
    //         }
    //         case 8: {
    //             return <span><FormattedMessage id='august' defaultMessage='' />   </span>
    //         }
    //         case 9: {
    //             return <span><FormattedMessage id='september' defaultMessage='' />   </span>
    //         }
    //         case 10: {
    //             return <span><FormattedMessage id='october' defaultMessage='' />   </span>
    //         }
    //         case 11: {
    //             return <span><FormattedMessage id='november' defaultMessage='' />   </span>
    //         }
    //         case 12: {
    //             return <span><FormattedMessage id='december' defaultMessage='' />   </span>
    //         }
    //         default: return <span>-</span>
    //     }
    // };

    const getFinacialYearFragment = function (companyInfo) {
        const fragmentObj = <Fragment>
            {companyInfo.fyStartDate && companyInfo.fyEndDate ? <div>
                {getMomentDateForUIReadOnly({ date: companyInfo.fyStartDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY })} - {getMomentDateForUIReadOnly({ date: companyInfo.fyEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY })}
            </div>
                : ''
            }
        </Fragment>
        return fragmentObj;
    }

    const customProps = {
        type: CONSTANTS.TYPE_IMAGE,
        imgUrl: props.showUserDropdown ? getAWSFileURL((props.companyInfo || {}).logoAwsObjectKey, config.BUCKET_NAME.BO_RELATIONSHIP) || profileIcon : null,
        height: 30,
        width: 30,
        name: 'file',
        action: null,
        buttonName: <FormattedMessage id='button.uploadCustomerPO.label' defaultMessage='' />,
        multiple: false,
        onChange: (fileList) => {
            let file = {file: fileList[0]}; // some untracked chages detected. recieving file list insteed of file object
            if (file.file.originFileObj) {
                new Promise((resolve) => {
                    Resizer.imageFileResizer(
                        file.file.originFileObj,
                        230,
                        90,
                        "png",
                        100,
                        0,
                        (uri) => {
                            //updateProfileIconUrl(uri);
                            const fileData = base64Toblob(uri);

                            var reader = new FileReader();

                            reader.onload = function (e) {
                                props.showModal({
                                    title: <Fragment>
                                        <div className='ant-modal-title'>Crop Image</div>
                                    </Fragment>,
                                    modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                                    }} handleSubmit={(newUrl) => {
                                        props.uploadImage({
                                            objectKey: props.companyInfo.logoAwsObjectKey,
                                            bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                                            file: base64Toblob(newUrl)
                                        });
                                        updateProfileIcon(newUrl);
                                            
                                    }}{...props} url={e.target.result} />,
                                    width: 800,
                                    hideFooter: true,
                                    wrapClassName: 'Upload-profile-modal'
                                })
                            }
            
                            reader.readAsDataURL(fileData); // convert to base64 string

                        },
                        "base64"
                    );
                });
            }
            //   file.file.originFileObj = file.file.originFileObj || {};
            //   file.file.originFileObj.name = `${file.file.originFileObj.name} (${bytesToSize(file.file.originFileObj.size)})`;
            //   this.setState({
            //     fileToUpload: file.file.originFileObj
            //   })
        }
    }

    const cookieOptions = {
        domain: '.'+(config.ROOT_DOMAIN || 'alpide.com'), // Replace with your desired domain
        path: '/', // Replace with the desired path
        expires: new Date(Date.now() + 86400 * 1000), // Replace with the desired expiration time
      };
    const handleRemoveCookie = () => {
        removeCookies(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, cookieOptions);
        removeCookies(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, cookieOptions);
        removeCookies(LOCAL_STORAGE_KEYS.EXPIRE_TIME, cookieOptions);
      };

    return <div ref={innerRef}>
        <div style={{display: 'flex'}} onClick={() => {
            props.updateState({
                showUserDropdown: !props.showUserDropdown
            });
            const companyInfo = props.companyInfo || {};
            const payloadObj = {
                companyInfo,
                relationshipId: companyInfo.relationshipId,
                pageNumber: 0,
                pageSize: 200,
                relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId
            };
            companyInfo.relationshipId &&  props.getAllStudentForm(payloadObj);
        }}>
            {/* {(relationshipEmployees) ? relationshipEmployees.fullName : ''} */}
            { profilePicVisible ? <div className='top-profile-pic'><img src= {profileImgUrl}  alt='U' onError={()=>{setProfilePicVisible(false)}} /></div> : <div className='user-icon'>{ getInitialsFromString(relationshipEmployees ? relationshipEmployees.fullName : 'U')}</div>}
            <div className='user-detail'>
                <div className='fw-600'>{relationshipEmployees ? capitalizeFirstLetter(relationshipEmployees.firstName) : 'User'}</div>
                <div className='role'>{ props.userInfo?.userType === 'admin' ? 'Administrator' : 'User' }</div>
            </div>
            <div className="right-dropdown">
                <i className="fi fi-rr-angle-small-down"></i>
            </div>
        </div>
        {props.showUserDropdown ? <div className="user-dropdown">
            <div className="user-header">
                {/* <i className="close">
                    <svg viewBox="64 64 896 896"
                        width="1em" height="1em" fill="currentColor"
                        aria-hidden="true">
                        <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
                    </svg>
                </i> */}
                <div>
                    <CustomUploader {...customProps} />
                </div>
                <div className='user-info'>
                    <div className='fw-600'>
                        {props.userInfo.firstName ? props.userInfo.firstName : ''}{props.userInfo.middleName ? " "+props.userInfo.middleName : ''}{props.userInfo.lastName ? " "+props.userInfo.lastName : ''}
                    </div>
                    <div>
                        <span className="link" style={{lineHeight: 1.2}} onClick={() => {
                            props.pushModalToStack({
                                title: <div><FormattedMessage id='setting.companyInformation.label' defaultMessage='' /></div>,
                                modalBody: <CompanyInfo {...props} />,
                                width: 1000,
                                wrapClassName: 'custom-modal-content',
                                hideFooter: true,
                            })
                        }}>
                            {(props.companyInfo || {}).storeName}
                        </span>
                        {/* {(relationshipEmployees) ? relationshipEmployees.fullName : ''} */}
                    </div>
                    <div className='user-email'>
                        {(props.userInfo || {}).email || ""}
                    </div>
                    <div className="link cursor-pointer" onClick={() => {
                        const modalData = {
                            title: <div className='ant-modal-title'><FormattedMessage id='setting.passwordManagement.label' defaultMessage='' /></div>,
                            modalBody: <ChangePassword {...props} />,
                            width: 600,
                            hideFooter: true
                        };
                        props.showModal(modalData);
                    }}>
                        <FormattedMessage id='setting.changePassword.label' defaultMessage='' />
                    </div>
                    {/* <div className="my-account-logout">
                        <span className="link mr20" onClick={() => {
                            props.showModal({
                                title: <div><FormattedMessage id='setting.companyInformation.label' defaultMessage='' /></div>,
                                modalBody: <CompanyInfo {...props} />,
                                width: 1000,
                                wrapClassName: 'custom-modal-content',
                                hideFooter: true,
                            })
                        }}><FormattedMessage id='companyInfo.text' defaultMessage='' /></span>
                        <a href='/app/'>
                            <span className="logout ml20" onClick={() => {
                                logout();
                            }}>
                                <FormattedMessage id='signout.text' defaultMessage='' />
                            </span>
                        </a>
                    </div> */}
                </div>

            </div>
            <div className="organisation-info mt15">
                {/* <div className="custom-row pb20">
                    <div className="title">
                    <FormattedMessage id='lastLogin.text' defaultMessage='' />
                    </div>
                    <div>
                        24th March 2019 11:30 AM
                    </div>
                </div> */}
                <div className="info-row">
                    <div className="title">
                        Country
                    </div>
                    <div>
                        {(props.companyInfo || {}).countryName}
                    </div>
                </div>
                <div className="info-row">
                    <div className="title">
                        <FormattedMessage id='homeCurr.text' defaultMessage='' />
                    </div>
                    <span><i
                        className={(props.companyInfo || {}).currencyIcon} /> ({(props.companyInfo || {}).currencyCode + ""})</span>
                </div>
                {<div className="info-row">
                    <div className="title">
                        <FormattedMessage id='language.text' defaultMessage='' />
                    </div>
                    <select name="language" value={selectedLanguage} onChange={(e) => {
                        addToLocalStorage("language", e.target.value);
                        window.location.reload();
                    }}>
                        <option value="en">English</option>
                        <option value="fr">French</option>
                        <option value="ar">Arabic</option>
                        <option value="de">German</option>
                    </select>
                </div>}
                <div className="info-row">
                    <div className="title">
                        <FormattedMessage id='timezon.text' defaultMessage='' />
                    </div>
                    <div>
                        {(props.companyInfo || {}).timezone}
                    </div>
                </div>
                <div className="info-row">
                    <div className="title">
                        <FormattedMessage id='financialYear.text' defaultMessage='' />
                    </div>
                    <div>
                        {getFinacialYearFragment(props.companyInfo || {})}
                    </div>
                </div>
                {/* <div className="custom-row pb20">
                    <div className="title">
                        Knowledge Base
                    </div>
                    <a
                        href="https://thepromptsolutions.com/businessnotes/knowledgebase/index.html"
                        target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-book"/>
                    </a>
                </div> */}
                {/* <div className="custom-row">
                    <div className="title">
                        <FormattedMessage id='setting.text' defaultMessage='' />
                    </div>
                    <Link to='/admin/show-settings'>
                        <i className="fa fa-cog" />
                    </Link>
                </div> */}
                {/* { (props.studentFormList && props.studentFormList.length) ? <div className="info-row mb15">
                    <div className="title">
                        <FormattedMessage id='programName.text' defaultMessage='' />
                    </div>
                    <select name="programName" style={{width: '175px'}} value={props.defaultSchoolForm} onChange={(e) => {
                        let selectedForm = find(props.studentFormList, { registrationFormSettingId: Number(e.target.value) });
                        props.showModal({
                            modalBody: `Are you sure you want to set ${selectedForm.formShortName} as a default program?`,
                            handleSubmit: () => {
                                props.setDefaultSchoolForm({
                                    relationshipId: props.companyInfo.relationshipId,
                                    formId: selectedForm.registrationFormSettingId,
                                    userId: (props.userInfo || {}).userId,
                                }, props);
                               // addToLocalStorage("defaultProgram", e.target.value);
                            }
                        })
                    }}>
                        {(props.studentFormList || []).map((data, i) => {
                            return <option key={i} value={data.registrationFormSettingId || ""}>{data.formShortName || ""}</option>
                        })
                        }
                    </select>
                </div>:''} */}
                {/* <div className="info-row">
                    <div className="title cursor-pointer" onClick={() => {
                        const modalData = {
                            title: <div className='ant-modal-title'><FormattedMessage id='setting.passwordManagement.label' defaultMessage='' /></div>,
                            modalBody: <ChangePassword {...props} />,
                            width: 600,
                            hideFooter: true
                        };
                        props.showModal(modalData);
                    }}>
                        <FormattedMessage id='setting.changePassword.label' defaultMessage='' />
                    </div>
                </div> */}
                <div className="user-footer">
                    {/* <a href='/app/'> */}
                    <span className="logout">
                        <Button className='logout' loading={loadings} onClick={() => {
                            // window.location.href =  config.WP_DOMAIN+ "/logout.php?redirect_to="+config.UI_BASE_URL;
                            // let popupwin = window.open(`${config.WP_DOMAIN}/logout.php?redirect_to=alpide.com`, "alpide", "titlebar=no, location=no,toolbar=no,width=500,height=300,left=500");
                            // var popupwin = window.open(`${config.WP_DOMAIN}/logout.php?redirect_to=`+config.UI_BASE_URL, 'alpide', 'width=1,height=1,left=-1000,top=-1000,popup=yes,location=no,menubar=no,toolbar=no,status=no,scrollbars=no,resizable=no');
                            // if (popupwin) {
                            //     setTimeout(function () {
                            //         popupwin.close();
                            //         handleRemoveCookie();
                            //         logout();
                            //     }, 2000);
                            // } else {
                            //     handleRemoveCookie();
                            //     logout();
                            // }
                            // window.location.href = config.WP_DOMAIN+"/logout.php?redirect_to=?redirect_to="+config.UI_BASE_URL
                            setLoadings(true)
                            handleRemoveCookie();
                            logout();


                        }}>
                            <FormattedMessage id='signout.text' defaultMessage='' />
                        </Button>
                        </span>
                    {/* </a> */}
                </div>
            </div>
            {/* <div className="user-footer">
                <ul>
                    <li onClick={()=>{ window.open('https://alpide.com/privacy.html')}}><FormattedMessage id='privacyPolicy.text' defaultMessage='' /></li>
                    <li onClick={()=>{ window.open('https://alpide.com/terms.html')}}><FormattedMessage id='termsOfService.text' defaultMessage='' /></li>
                </ul>
            </div> */}
        </div> : null}
    </div>
}
