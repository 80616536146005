import React from "react";
import {Col, Row, Card, Progress, Input,Skeleton,Checkbox,DatePicker,Pagination,} from "antd";
import { Dropdown } from "../general/Dropdown";
import Dragger from 'antd/lib/upload/Dragger';
import {RightOutlined,DownloadOutlined,CloudServerOutlined,DownOutlined,FolderAddOutlined,} from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import "./cloudDrive.scss";
import { CONSTANTS, ICONS } from "../../static/constants";
import PageBreadcrumb from "../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import UnderDev from "../Common/NotFound";
import moment from "moment-timezone";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const CloudStorageDriveComp = (props) => {
  const { Search } = Input;

  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="cloudStorage.pageName.dashboard" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="cloudStorage.pageName.documents" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="cloudStorage.pageName.home" defaultMessage="" />
      ),
    },
  ];

  const propss = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
            // handleFileChange(info?.file?.originFileObj)
        }
        if (status === 'done') {
            // handleFileChange(info?.file?.originFileObj)

        } else if (status === 'error') {
        }
    },
    onDrop(e) {
        // handleFileChange(e.dataTransfer.files)
    },
};
const handleClick= ()=>{
  props.updateState({
    driveOpen:!props.driveOpen
  })
}
  return (
      
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id="cloudStorage.pageName.media" defaultMessage="" />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        {/* <div className="top-view-right">
        </div> */}
      </div>

      <div className="view-container">
        {/* <UnderDev /> */}
        <div className="cloudStorage-Container">
          <Row>
            <Col span={4}>
              <Card className="leftBar">
                <div className="cardHeader">
                  <h2>Header items</h2>
                </div>
                <div className="cardMenuItem">
                  <div className="menu active">
                    <i className={ICONS.FOLDER}/> Documents
                  </div>
                  <div className="menu">
                    <i className={ICONS.FOLDER}/> Images
                  </div>
                  <div className="menu">
                    <i className={ICONS.FOLDER}/> HRMS
                  </div>
                  <div className="menu">
                    <i className={ICONS.FOLDER}/> Others
                  </div>
                  <div className="menu">
                    <i className={ICONS.FOLDER}/> Assignments
                  </div>
                  <div className="menu">
                  <i className={ICONS.DELETE}/> Rubbish bin
                  </div>
                </div>
                <div className="storageSection">
                  <div className="free">Free</div>
                  <div className="storage">Storage</div>
                </div>
                <div className="progressBar">
                  <Progress percent={30} />
                </div>
                <div className="storageCount">7.02 GB (46%) of 15 GB used</div>
              </Card>
            </Col>
            <Col span={20}>
              <Card className="rightBar">
                <div className="cardHeader">
                  <div className="driveName">
                    <h2>My Drive</h2>
                    <button onClick={handleClick}>
                      <DownOutlined />
                    </button>
                    {props.driveOpen?
                      <div className="uploadDrive">
                        <ul>
                          <li><FolderAddOutlined /> <span></span>New Folder</li>
                        </ul>
                      </div>
                    :""}
                  </div>
                  <p>Manage your company information to use the platform</p>
                </div>
                <div className="selectSection">
                  <Dropdown
                   
                    placeholder="Select Type"
                  />
                    <Dropdown
                   
                    placeholder="Select Customer"
                  />
                    <Dropdown
                   
                    placeholder="Select Transaction Type"
                  />
                </div>
                <div className="fileLocation">
                  <ul>
                    <li>Drive</li>
                    <li><RightOutlined /></li>
                    <li>Folder Name 1</li>
                    <li><RightOutlined /></li>
                    <li>Folder Name 2</li>
                  </ul>
                </div>
                <div className="uploadSection">
                  <Dragger {...propss}>
                      <div className="ant-upload-drag-icon"><i className={ICONS.UPLOAD}/></div>
                      <div className="ant-upload-text">Drag the Files in table</div>
                      <div className="ant-upload-hint">Upload File</div>
                  </Dragger>
                </div>
                <div className="uploadTable">
                  <div className="view-container-actions">
                    <div className="left-actions">
                      <Search
                        placeholder="Type anything to search"                       
                      />
                    </div>
                    <div className="right-actions">
                      <div className="storageCount">
                        <CloudServerOutlined />
                        <span>7.02 GB</span>
                      </div>
                      <DatePicker />
                      <button><DownloadOutlined /></button>
                    </div>
                  </div>
                  <Skeleton>
                    <div className="table-container">
                        <table>
                          <thead>
                            <tr>
                              <th>
                                <div className="check-cell">
                                  <Checkbox/>
                                  
                                </div>
                              </th>
                              <th>SL</th>
                              <th>Document Name</th>
                              <th>Document Type</th>
                              <th>File Size</th>
                              <th>Owner</th>
                              <th>Last Modified</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Checkbox/>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                  </Skeleton>
                  <div className="footer">
                    <div className="f-left"></div>
                    <div className="f-right">
                      <Pagination
                        size="small"
                        defaultPageSize={props.pageSize}
                        showSizeChanger
                        pageSizeOptions={[10, 25, 50, 100, 200]}
                        
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
   
};

export default injectIntl(CloudStorageDriveComp);
