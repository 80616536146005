import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssignFormToTeacherComponent from '../../../../../components/modal/modalBody/schoolManagement/AssignFormToTeacher';
import { saveOrUpdateFormPermission } from './action';
import { fetchAllUsers } from '../../../../settings/userManagement/action';
import { fetchRegistrationFormSetting } from '../../settings/RegistrationFormSetting/action';

class AssignFormToTeacher extends Component {

    constructor(props) {
        super(props);  
       
        this.state = {
            registrationFormSettingId: props.registrationFormSettingId,
            courseTermSubjects: {},
            //userPermissions
        }
    }
    componentWillMount() {

        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 500,relationshipEmployeeId: userInfo.relationshipEmployeeId, 
            formId: this.props.defaultSchoolForm};
            
        this.state.registrationFormSettingId && this.props.fetchRegistrationFormSetting({ relationshipId: companyInfo.relationshipId, registrationFormSettingId: this.props.registrationFormSettingId });
        this.props.fetchAllUsers(payload);
        // this.props.fetchCourseSubjectList(payload);
    }
    componentWillReceiveProps(props) {
        if (props.registrationFormData && props.registrationFormData.registrationFormSettingId) {
            let userPermissions = [];
            (props.registrationFormData.orgRegFormSettingPermissionList || []).forEach(function (obj) {
                userPermissions.push(obj.relEmployeeId);
            });
            this.setState({
                userPermissions
            });
        }
    }

    render() {
        return <div>
            <AssignFormToTeacherComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        userInfo: state.common.userInfo,
        classList: state.school.classList,
        defaultSchoolForm: state.common.defaultSchoolForm,
        allCompanyUsers: state.settings.allCompanyUsers,
        registrationFormData: state.settings.registrationFormSetting,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchRegistrationFormSetting,
    saveOrUpdateFormPermission,
    fetchAllUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssignFormToTeacher);