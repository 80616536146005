import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UnpaidInvoicesComponent from '../../../../../components/modal/modalBody/supplier/UnpaidInvoices';
import { fetchUnpaidInvoices, fetchUnpaidInvoicesCount, resetPaginatedData } from './action';
import { popModalFromStack, showModal, hideModal, pushModalToStack } from './../../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from '../../../../../utils';

class UnpaidInvoices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
            pageNumber: 1,
            searchedText: ''
        };
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            supplierId: this.props.supplierData ? this.props.supplierData.supplierId : '',
            pageNumber: 1,
            pageSize: 25
        };
        // resetPaginatedData();
        // fetchPaginationDataIfNeeded('fetchUnpaidInvoices', 'unpaidInvoiceList', this.props, payload, true);
        // fetchDataIfNeeded('fetchUnpaidInvoicesCount', 'unpaidInvoiceListCount', this.props, payload, true);
        this.props.fetchUnpaidInvoices(payload, {companyInfo});
        this.props.fetchUnpaidInvoicesCount(payload, {companyInfo});

    }

    render() {
        return <UnpaidInvoicesComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        unpaidInvoiceList: state.supplier.unpaidInvoiceList,
        unpaidInvoiceListCount: state.supplier.unpaidInvoiceListCount,
        permissions: state.auth.permissions,
        modalLoading: state.common.modalLoading,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUnpaidInvoices,
    popModalFromStack,
    pushModalToStack,
    showModal,
    hideModal,
    fetchUnpaidInvoicesCount,
    resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnpaidInvoices);