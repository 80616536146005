import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BusinessExpenseListingComponent from '../../../../components/finance/BusinessExpense/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchBusinessExpenses, getBusinessExpensesCount, resetPaginatedData } from './action';

import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../actions/commonActions';

class BusinessExpenseListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 100,
      pageNumber: 0,
      isDataRendered: false
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100
    };
    fetchPaginationDataIfNeeded('fetchBusinessExpenses', 'businessExpensesList', this.props, payload);
    fetchDataIfNeeded('getBusinessExpensesCount', 'businessExpensesCount', this.props, payload);
  }

  render() {
    return (
      <BusinessExpenseListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    businessExpensesList: state.businessExpense.businessExpensesList,
    businessExpensesCount: state.businessExpense.businessExpensesCount,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchBusinessExpenses,
  getBusinessExpensesCount,
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BusinessExpenseListing);
