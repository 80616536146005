import { FINANCIAL_YEAR_ACTIONS } from '../static/constants';

const initialState = {
    financialYearList :[],
}

const FinancialYearReducer = (state = initialState, action) => {
  switch (action.type) {

    case FINANCIAL_YEAR_ACTIONS.FINANCIAL_YEAR_LIST:
      return { ...state, financialYearList: action.data };
    case FINANCIAL_YEAR_ACTIONS.UPDATE_FINANCIAL_YEAR:
      return { ...state, financialYearList : state.financialYearList.map(function(financialYearList) { return financialYearList.fyId === action.data.fyId ? action.data : financialYearList; })}   
    case FINANCIAL_YEAR_ACTIONS.FINANCIAL_YEAR_LIST_DELETION:
      return { ...state, financialYearList: state.financialYearList.filter(x => x.fyId !== action.data.id) }
    default:
      return state;
  }
};

export default FinancialYearReducer;