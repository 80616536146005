import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, hideModal, pushModalToStack, popModalFromStack,  addActivityStatus, deleteActivityStatus, addActivityPriorities, deleteActivityPriorities, addActivityTypes, deleteActivityTypes, fetchAllEmployees } from './../../../../actions/commonActions';
import {
  fetchAllActivityPriorities, fetchAllActivityStatus, fetchAllActivityTypes, createUpdateActivity
} from './action';
import { fetchDataIfNeeded } from '../../../../utils';
import SupplierTaskDrawer from '../../../../components/drawer/supplier/detail/taskDrawer';

class LogActivityContainer extends Component {
  constructor(props){
    super(props);
    this.state ={
      activityData: props.activityData || {}
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      supplierId: this.props.supplierId
    };
    
    fetchDataIfNeeded('fetchAllEmployees', 'allEmployee', this.props, payload);
    fetchDataIfNeeded('fetchAllActivityPriorities', 'activityPriorities', this.props, payload);
    fetchDataIfNeeded('fetchAllActivityStatus', 'activityStatus', this.props, payload);
    fetchDataIfNeeded('fetchAllActivityTypes', 'activityTypes', this.props, payload);
  }

  render() {
    return (<>
        {/* <TasksDrawerCompo {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data)}}/> */}

      <SupplierTaskDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data)}} />     
    </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    activityPriorities: state.customer.activityPriorities,
    activityStatus: state.customer.activityStatus,
    activityTypes: state.customer.activityTypes,
    allEmployee: state.common.employees,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllActivityPriorities,
  fetchAllActivityStatus,
  fetchAllActivityTypes,
  createUpdateActivity,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  addActivityStatus, 
  deleteActivityStatus, 
  addActivityPriorities, 
  deleteActivityPriorities, 
  addActivityTypes, 
  deleteActivityTypes,
  fetchAllEmployees
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LogActivityContainer);
