import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PublishedCategoryComp from '../../../components/ecommerce/PublishedCategory';
import { 
  fetchCategory, getCategoryCount,
  fetchPublishedCategory, getPublishedCategoryCount, resetPublishedCategoryPage,
  fetchUnpublishedCategory, getUnpublishedCategoryCount, resetUnpublishedCategoryPage,
  updatePublisedCategory
 } from './action';
 
//import { fetchCategory } from '../../drawer/inventory/action';
import { fetchPaginationDataIfNeeded } from '../../../utils';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';

class PublishedCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 100,
      searchedText: '',
      invoiceReminderList: [],
      selectedItems: []
    };
  }

  componentWillReceiveProps(props) {
    
  }

  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      userId: (this.props.userInfo || {}).userId,
      pageSize: 100
    };

    fetchPaginationDataIfNeeded('fetchCategory', 'productCategories', this.props, payload);
    this.props.getCategoryCount(payload);
  }

  render() {
    return (
      <PublishedCategoryComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: (state.common.companyInfo|| {}).relationshipId,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions,
    
    productCategories: state.ecommerce.allCategories,
    allCategoryCount: state.ecommerce.allCategoryCount,
    publishedCategories: state.ecommerce.publishedCategories,
    publishedCategoryCount: state.ecommerce.publishedCategoryCount,
    unpublishedCategories: state.ecommerce.unpublishedCategories,
    unpublishedCategoryCount: state.ecommerce.unpublishedCategoryCount,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchCategory,
  getCategoryCount,
  fetchPublishedCategory, getPublishedCategoryCount, resetPublishedCategoryPage,
  fetchUnpublishedCategory, getUnpublishedCategoryCount, resetUnpublishedCategoryPage,
  updatePublisedCategory, 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PublishedCategory);
