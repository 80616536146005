import React, { Fragment, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
//import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Tabs } from 'antd';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import PageBreadcrumb from '../../../PageBreadcrumb';
//import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
//import CreditMemoDetail from '../../../../containers/modal/modalBody/customer/CreditMemoDetail';
import { ICONS } from '../../../../static/constants';
// import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import ClosedComplaints from './closedComplaints';
import SalesComplaints from './salesComplaints';
import PageHeader from '../../../Common/pageHeader';

const { TabPane } = Tabs;


const SalesComplaintListingComp = (props) => {
    function callback(key, props) {
        const payload = {
            companyInfo: props.companyInfo,
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: props.pageNumber,
            pageSize: props.pageSize,
        };
        if (key === "1") {
            props.updateState({ activeTab: "Open", activeTabKey: "1" });
            props.fetchComplaints(payload, {activeTabKey:"1"})
            props.getComplaintsCount(payload, {activeTabKey:"1"})

        } else if (key === "2") {
            props.updateState({ activeTab: "Closed", activeTabKey: "2",pageNumber:1,pageSize:25 });
            props.fetchComplaints({...payload,pageNumber:1,pageSize:25}, {activeTabKey:"2"})
            props.getComplaintsCount({...payload,pageNumber:1,pageSize:25}, {activeTabKey:"2"})


        }
    }
    // const getActionMenuPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedModules = {
    //         firstSection: {
    //             updateCreditMemo: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CREDIT_MEMO, PERMISSION_VALUES.UPDATE),
    //             cloneCreditMemo: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CREDIT_MEMO, PERMISSION_VALUES.CREATE)
    //         }
    //     };
    //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
    //         if (permittedModules.firstSection[moduleName] === false) {
    //             delete permittedModules.firstSection[moduleName];
    //         }
    //     })
    //     return permittedModules;
    // }


    // const getRowHeight = (params) => {
    //     let height = 28;
    //     return height;
    // }

    // const openCreditMemoDetails = (data) => {
    //     const creditMemoPayload = {
    //         customerId: data.customerId,
    //         creditMemoMasterId: data.salesComplaintMasterId
    //     }
    //     props.pushModalToStack({
    //         modalBody: <CreditMemoDetail {...props} creditMemoPayload={creditMemoPayload} />,
    //         width: '100%',
    //         hideFooter: true,
    //         wrapClassName: 'modal-custom-detail'
    //     })
    // }

    // const saveColumnIndex = (columnState) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data);
    // }

    // const saveColumnWidth = (columnState, width) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data, props);
    // }

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
    //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
    //     event.api.resetRowHeights();
    //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
    //         //event.api.resetRowHeights();
    //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
    //             props.gridApi.sizeColumnsToFit();
    //             setTimeout(() => {
    //                 const columnArray = event.columnApi.getColumnState();
    //                 saveColumnWidth(columnArray);
    //             }, 1000);
    //         } else {
    //             saveColumnWidth(event.columnApi.getColumnState());
    //         }
    //     }
    // }



    //const permittedModules = getActionMenuPermissionData();


    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesComplaint.listing.header.salesComplaintNumber' defaultMessage='' />;
    //         },
    //         field: "salesComplaintNumber",
    //         hide: _isColumnToHide('salesComplaintNumber'),
    //         colId: "salesComplaintNumber_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         rowDrag: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div onClick={() => {
    //            // openCreditMemoDetails(link.data)
    //         }}>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesComplaint.listing.header.assignedTo' defaultMessage='' />;
    //         },
    //         field: "assignedUserName",
    //         hide: _isColumnToHide('assignedTo'),
    //         colId: "assignedTo_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesComplaint.form.gridHeader.complaintDetails' defaultMessage='' />;
    //         },
    //         field: "complaintDetails",
    //         cellClass: "ag-cell-description",
    //         colId: "complaintDetails_1",
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         hide: _isColumnToHide('complaintDetails'),
    //         cellRendererFramework: (params) => {
    //             return <div> {ReactHtmlParser(params.value) }</div>
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.customer' defaultMessage='' />;
    //         },
    //         field: "customerName",
    //         hide: _isColumnToHide('customerName'),
    //         colId: "customerName_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => {
    //             return <div className="cursor-pointer" >
    //                 <div onClick={() => {
    //                     props.history.push("customers/details", {
    //                         customerId: link.data.customerId,
    //                         customerName: link.data.customerName,
    //                     })
    //                 }}>{link.data.customerName}</div>
    //             </div>
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesComplaint.listing.header.complaintType' defaultMessage='' />;
    //         },
    //         field: "salesComplaintType",
    //         hide: _isColumnToHide('complaintType'),
    //         colId: "complaintType_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (params) => {
    //             return <div> <span>{params.value}</span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.ref' defaultMessage='' />;
    //         },
    //         field: "refNumber",
    //         hide: _isColumnToHide('referenceNumber'),
    //         colId: "referenceNumber_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (params) => {
    //             return <div> <span>{params.value}</span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.dueDate' defaultMessage='' />;
    //         },
    //         field: "dueDate",
    //         hide: _isColumnToHide('dueDate'),
    //         colId: "dueDate_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //              <Tooltip placement="topLeft" title={(link.data.dueDate && getMomentDateForUIReadOnly({date: link.data.dueDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {getMomentDateForUIReadOnly({date: new Date(link.data.dueDate), format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.soNumber' defaultMessage='' />;
    //         },
    //         field: "salesOrderNumber",
    //         colId: "soNumber_1",
    //         hide: _isColumnToHide('soNumber'),
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openSoDetails(link.data)
    //         }}>{link.value}</div>,
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.priority' defaultMessage='' />;
    //         },
    //         field: "priority",
    //         hide: _isColumnToHide('priority'),
    //         colId: "priority_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (params) => {
    //             return <div> <span>{params.value}</span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "statusName",
    //         hide: _isColumnToHide('status'),
    //         colId: "status_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (params) => {
    //             return <div> <span><FormattedMessage id={params.value || 'open'}  /></span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesComplaint.listing.header.complaintClosedDate' defaultMessage='' />;
    //         },
    //         field: "complaintClosedDate",
    //         hide: _isColumnToHide('complaintClosedDate'),
    //         colId: "complaintClosedDate_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //              <Tooltip placement="topLeft" title={(link.data.complaintClosedDate && getMomentDateForUIReadOnly({date: link.data.complaintClosedDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {link.data.complaintClosedDate ? getMomentDateForUIReadOnly({date: new Date(link.data.complaintClosedDate), format}) : ''}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.dateCreated' defaultMessage='' />;
    //         },
    //         field: "dateCreated",
    //         hide: _isColumnToHide('dateCreated'),
    //         colId: "dateCreated_1",
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //              <Tooltip placement="topLeft" title={(link.data.dateCreated && getMomentDateForUIReadOnly({date: link.data.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {getMomentDateForUIReadOnly({date: new Date(link.data.dateCreated), format})}
    //             </Tooltip>
    //         </div>,
    //     }
    // // ];

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    const breadCrumbList = [
        {
            name: <FormattedMessage id='common.sales' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='salesSupport.text' defaultMessage='' />,
        }
    ];

    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     if (props.isColumnFitTable && params.api) {
    //         params.api.sizeColumnsToFit();
    //         props.updateState({
    //             isColumnFitTable: false
    //         })
    //     }
    //     onDataRendered(params);
    // }

    // const onModelUpdated = (params) => {
    //     if((salesComplaintList[pageNumber] || []).length &&  !props.isRowHeightSet){
    //         setTimeout(()=>{
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //         //params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         const txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     }
    // }

    // const onDataRendered = (params) => {
    //     const txColumnSetting = props.txColumnSetting;
    //     if (Object.keys(txColumnSetting).length) {
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.width = 52;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     } else {
    //         params.api.sizeColumnsToFit();
    //     }
    // }


    const newComplaint = () => {
        props.updateHeaderState({
            salesComplaint: {
                ...props.salesComplaint,
                salesComplaintDrawerVisible: true,
            },
        });
    };




    return (
        <Fragment>

            <PageHeader {...props}
                pageName="heading.sales.salesSupport"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={newComplaint}
            />
            <div className='view-container'>
                <Tabs activeKey={props.activeTabKey || "1"}
                    onChange={(key) => {
                        callback(key, props);
                    }}
                    type="line">
                    <TabPane key="1" tab="Open">
                        <SalesComplaints {...props} />
                    </TabPane>
                    <TabPane key="2" tab="Closed">
                        <ClosedComplaints {...props} />
                    </TabPane>
                </Tabs>

            </div>
        </Fragment>
    );
};

export default injectIntl(SalesComplaintListingComp);
