import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShipStationComponent from '../../../components/shipping/shipStation';
import { fetchShipStationConfiguration, disconnectConfiguration, saveShipStationConfiguration } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { getCompanyInfo } from '../../../utils';
import * as find from 'lodash.find';

class ShipStation extends Component {
  constructor(props) {
    super(props);
    //const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 25,
      pageNumber: 1,
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: false
    });
    const companyInfo = this.props.companyInfo || getCompanyInfo() || {};
    const payload = {
      relationshipId: companyInfo.relationshipId
    };
     this.props.fetchShipStationConfiguration(payload);
   
  }

  componentWillReceiveProps(props) {
    if(props.linkedShipStationList && props.linkedShipStationList.length && !this.state.linkedEasyPost){
      let linkedShipStation = find(props.linkedShipStationList, { carrierName: 'shipStation'});
      this.setState({
        linkedShipStation
      });
    }
  }

  render() {
    return (
      <div>
        <ShipStationComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    linkedShipStationList: state.integration.linkedShipStationList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchShipStationConfiguration, disconnectConfiguration, saveShipStationConfiguration,
  showModal, hideModal, pushModalToStack, popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShipStation);
