import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    popModalFromStack, pushModalToStack, showModal, hideModal
} from '../../../../actions/commonActions';
import { addCategory, fetchCategory } from '../../../drawer/inventory/action';
import { downloadFileFromBucket } from '../../../../actions/downloadFileAction';
import { importProducts, fetchTaxjarCategory } from './action';
import CategoryComponet from '../../../../components/drawer/inventory/CategoryDrawer';


class CategoryDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            ...props.categoryData,
            pageSize: 100,
            pageNumber: 1,
        };
    }

    componentDidMount(){
        const companyInfo = this.props.companyInfo || {};
        const payload = {
          relationshipId: companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 100
        };
        this.props.fetchCategory(payload);
        if (companyInfo.countryName === 'United States (USA)') {
            this.props.fetchTaxjarCategory();
        }
    }
    componentWillReceiveProps(props){
        if(this.state.productTaxCode && props.taxjarCategories && props.taxjarCategories.length && !props.productTaxCodeCatName){
            const selectedTaxCodeCategory = props.taxjarCategories.find(
                (category) => category.productTaxCode === this.state.productTaxCode
              );
            
          this.setState({
            productTaxCodeCatName: selectedTaxCodeCategory?.name,
            productTaxCode: selectedTaxCodeCategory?.productTaxCode,
            selectedTaxCodeCategory,
          })
        }
    }
    render() {
        return <CategoryComponet {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}
const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        productCategories: state.inventory.productCategories,
        taxjarCategories: state.inventory.taxjarCategories
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addCategory,
    fetchCategory,
    fetchTaxjarCategory,
    importProducts,
    downloadFileFromBucket,
    popModalFromStack, pushModalToStack, showModal, hideModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDrawer);
