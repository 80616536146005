import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Row, Col, Drawer, DatePicker } from "antd";
import { TextBox } from "../../../../general/TextBox";
import { CustomButton } from "../../../../general/CustomButton";
import { Dropdown } from "../../../../general/Dropdown";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { MODAL_TYPE } from "../../../../../static/constants";
import { TextArea } from "../../../../general/TextAreaComponent";
import { CONSTANTS } from "../../../../../static/constants";
import {
  getLocalDateFromUtcStr,
  getCurrentDateForBackend,
} from "../../../../../utils";
import { Form } from '@ant-design/compatible';
import LogActivity from "../../../../../containers/supplier/Listing/LogActivity";

const supplierTaskDrawer = (props) => {
  const { customerTask = {} } = props;

  const rowgutter = [24, 16];
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getModalTitle("", modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.ACTIVITY_STATUS: {
        addFunc = props.addActivityStatus;
        break;
      }
      case MODAL_TYPE.ACTIVITY_PRIORITY: {
        addFunc = props.addActivityPriorities;
        break;
      }
      case MODAL_TYPE.ACTIVITY_TYPE: {
        addFunc = props.addActivityTypes;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const getModalTitle = (newTextObj, modalType) => {
    let title;
    switch (modalType) {
      case MODAL_TYPE.ACTIVITY_STATUS: {
        title = (
          <FormattedMessage
            id="addItem.text.activityStatus"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.ACTIVITY_PRIORITY: {
        title = (
          <FormattedMessage
            id="addItem.text.activityPriority"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.ACTIVITY_TYPE: {
        title = (
          <FormattedMessage id="addItem.text.activityType" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;
    switch (modalType) {
      case MODAL_TYPE.ACTIVITY_STATUS: {
        deleteFunc = props.deleteActivityStatus;
        break;
      }
      case MODAL_TYPE.ACTIVITY_PRIORITY: {
        deleteFunc = props.deleteActivityPriorities;
        break;
      }
      case MODAL_TYPE.ACTIVITY_TYPE: {
        deleteFunc = props.deleteActivityTypes;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  const handleSubmit = () => {
    props.updateState({
      submittedOnce: true,
    });
    if (!customerTask.activityTypeName || !customerTask.description) {
      return;
    }
    const payload = {
      ...customerTask,
      relationshipId: (props.companyInfo || {}).relationshipId,
      dateCreated: getCurrentDateForBackend(new Date()),
      userCreated: props.userInfo.firstName,
      customerName: props.customer?.companyName,
      customerId: props.customer?.customerId,
      version: (customerTask || {}).version || 0,
      activityDueDate: getCurrentDateForBackend(customerTask.activityDueDate),
      activityClosedDate: getCurrentDateForBackend(
        customerTask.activityClosedDate
      ),
    };
    const modalData = {
      modalBody: customerTask.isUpadte ? (
        <FormattedMessage id="activity.updateConfirmation" defaultMessage="" />
      ) : (
        <FormattedMessage id="activity.confirmation" defaultMessage="" />
      ),
      handleSubmit: () => {
        props.createUpdateActivity(payload);
        props.updateState({
          customerTaskDrawerVisible: false,
        });
      },
    };
    props.pushModalToStack(modalData);
  };

  return (
    <Drawer
      title={"Create Task"}
      keyboard={false}
      className="task-drawer"
      width={720}
      maskClosable={false}
      zIndex={99999}
      destroyOnClose={true}
      onClose={() => {
        props.updateState({
            supplierTaskDrawerVisible: false,
        });
      }}
      visible={props.supplierTaskDrawerVisible}
     
    >
         <LogActivity {...props} />

    </Drawer>
  );
};

export default injectIntl(supplierTaskDrawer)
