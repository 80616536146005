import React, { Fragment } from "react";
import { Row, Col, Button, Checkbox, Input, Popover, Tooltip } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';

import { Dropdown } from "../../../general/Dropdown";
import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { AddAndRemoveColumn } from "../../../general/AddAndRemoveColumn"
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import {
  MODAL_TYPE, MODULE_CODED_VALUES, PERMISSION_VALUES
} from '../../../../static/constants'
import { getLabelName } from '../../../modal/modalBody/customerInfo';
import { showToasterMessage, fixedDecimalAndFormateNumber } from '../../../../utils';
import { CustomTableUpload } from "../../../general/UploadTable";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";

class ProductDetails extends React.Component {

  constructor() {
    super();
    this.state = {
      sectionVisible: {
        discount: false,
        tax: false,
        expense: false
      },
      total: 0,
      subtotal: 0,
      subtotalWithoutDiscount: 0,
      totalDiscount: 0,
      expenseAmount: 0,
      totalTax: 0,
      itemLevelTaxes: [],
      rootDiscountPercent: 0,
      rootDiscountAmount: 0
    }
  }

  calculateSubtotal = () => {
    let total = 0;
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []
    let subtotalWithoutDiscount = 0;
    let rootDiscountAmount = 0;
    let self = this;

    this.gridApi.forEachNode(function (rowNode, index) {
      let stringifiedRowData = JSON.stringify(rowNode.data);
      let totalAmountOfColumn = 0;
      const rowdata = JSON.parse(stringifiedRowData);
      if (rowdata.qty && rowdata.rate) {
        let taxObj = {
          subtotal: 0,
          taxPercent: 0,
          taxAmount: 0
        }
        totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
        subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
        if (rowdata.discount) {
          let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
          totalAmountOfColumn = totalAmountOfColumn - discountAmount;
          totalDiscount = totalDiscount + discountAmount;
        }

        if (self.state.rootDiscountPercent) {
          let itemLevelDiscountAmount = (Number(self.state.rootDiscountPercent) * 0.01) * totalAmountOfColumn;
          totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
          totalDiscount = totalDiscount + itemLevelDiscountAmount
          rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
        }

        if (rowdata.tax) {
          taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
          let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
          totalTax = totalTax + taxAmount;
        }
        if (taxObj.taxPercent) {
          taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
          const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
          if (availableTaxObj) {
            itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
              if (ele.taxPercent === taxObj.taxPercent) {
                ele.subtotal = Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
              }
              return true;
            });
          } else {
            taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
            itemLevelTaxes.push(taxObj);
          }
        }
        subtotal = Number(subtotal) + totalAmountOfColumn;
      }
    });


    total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;

    this.setState({
      total: Number(total || 0).toFixed(2),
      subtotal: Number(subtotal || 0).toFixed(2),
      totalDiscount: Number(totalDiscount || 0).toFixed(2),
      totalTax: Number(totalTax || 0).toFixed(2),
      subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
      itemLevelTaxes,
      rootDiscountAmount
    });
  }


  componentDidMount() {
    setTimeout(() => {
      this.calculateSubtotal();
      this.props.updateState({
        isRemarksSet: true
      })
    }, 3500)
  }

  handleNewTaxAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.NEW_TAX,
        data: payload,

      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      }
    };
    showModal(data);
  }

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = <span>
      <FormattedMessage
        id='addItem.confirmation'
        values={{ value: `${text} to ` }}
        defaultMessage=''
      />
      {labelName} ?
    </span>;

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        addFunc = props.addExpenseTypes;
        break;
      }
      default: {
        addFunc = () => { }
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        this.gridApi && this.gridApi.stopEditing();
        props.hideModal();
      },
    };
    showModal(modalData);
  }

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        title = <FormattedMessage id='addItem.text.expenseType' defaultMessage='' />;
        break;
      }
      default:
        title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
    }
    return title;
  }

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        }
      };
      showModal(data);
    }
  }

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody =
      <FormattedMessage
        id='deleteItem.confirmation'
        defaultMessage=''
        values={{ value: payload.text }}
      />;

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      case MODAL_TYPE.NEW_TAX: {
        deleteFunc = props.deleteTax;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        deleteFunc = props.deleteExpenseTypes;
        break;
      }
      default: {
        deleteFunc = () => { }
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
    };
    showModal(modalData);
  }

  render() {
    const { txColumnSetting } = this.props;
    const self = this;
    const { sectionVisible, itemLevelTaxes } = this.state;
    const isPlaceOfSupplySameAsRelationship = (this.props.relationshipBillingAddress || {}).stateName === this.props.placeOfSupply;
    // let errorKey = '';
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    }

    if (this.gridApi && this.props.priceTypeToApply && this.props.isToPriceTypeToApply) {
      let self = this;
      this.gridApi.forEachNode(function (rowNode, index) {
        if (rowNode.data.product) {
          let rate = self.props.priceTypeToApply === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0] ? Number(rowNode.data.variant.retailPrice || 0).toFixed(2) : Number(rowNode.data.variant.wholesalePrice || 0).toFixed(2);
          rowNode.setData({ ...rowNode.data, rate, baseRate: rate });
        }
      });
      this.props.updateState({
        isToPriceTypeToApply: false
      })
    }

    

    const saveSalesOrder = (props, state, agGridObj) => {
      // let isRequiredFiledMissing = false;
      // this.gridApi.forEachNode((rowNode, index) => {
      //   let { data } = rowNode;
      //   if (index === 0 && ((!data.product && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0)))) {
      //     isRequiredFiledMissing = true;
      //   } else if (!data.product && !data.rate && !data.qty) {
      //     data = {};
      //   } else if ((!data.product && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0))) {
      //     isRequiredFiledMissing = true;
      //   }
      // });

      // if (isRequiredFiledMissing || !(this.props.customer && this.props.customer.customerId)) {
      //   return showToasterMessage({
      //     messageType: 'error', description: 'Please select Customer, Product/Description, Quantity and Rate'
      //   });
      // }
      let errorKey = '';
      if(!(this.props.customer) || !(this.props.customer.customerId)){
          errorKey = 'missingCustomer.errorMsg';
      }else if (!this.props.currencyCode) {
          errorKey = 'missingCurrency.errorMsg';
      }else if (!this.props.exchangeRate) {
          errorKey = 'missingExchangeRate.errorMsg';
      }

      if(errorKey){
          return showToasterMessage({
              messageType: 'error',
              description: this.props.intl.formatMessage({id : errorKey, defaultMessage : ''})
          });
      }
      
      self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
        let { data } = rowNode;
        
        if(index === 0 && (!data.product && !data.description)){
          errorKey = 'missingProduct.errorMsg';
        }else if(index === 0 && !(Number(data.qty || 0))){
          errorKey = 'missingQty.errorMsg';
        }else if(index === 0 && !(Number(data.rate || 0))){
          errorKey = 'missingRate.errorMsg';
        }
      });

      if (errorKey) {
        return showToasterMessage({
          messageType: 'error',
          description: this.props.intl.formatMessage({id : errorKey, defaultMessage : ''})
        });
      }

      const modalData = {
        modalBody: <FormattedMessage
          id='customer.salesOrder.form.confirmation'
          defaultMessage=''
        />,
        handleSubmit: () => {
          const payload = {
            props,
            state,
            fileToUpload: state.fileToUpload,
            agGridObj,
            isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship
          }
          props.createSalesOrder(payload);
          props.hideModal();
        }
      };
      // if (!props.exchangeRate) {
      //   return showToasterMessage({ messageType: 'error', description: "Exchange Rate is required" });
      // }

      // if (!props.currencyCode) {
      //   return showToasterMessage({ messageType: 'error', description: "Currency is required" });
      // }


      props.showModal(modalData);
    }

    const saveColumnIndex = (columnName, index) => {
      if (!columnName || index < 0) return;
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      let columnSetting = this.props.txColumnSetting || {}
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      let fieldSetting = columnSetting[columnName] || {};
      fieldSetting.index = index;
      columnSetting[columnName] = fieldSetting;
      gridPreference.columnSetting = columnSetting;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data);
    }

    const saveColumnWidth = (columnName, width) => {
      if (!columnName || !width) return;
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      let columnSetting = this.props.txColumnSetting || {}
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      let fieldSetting = columnSetting[columnName] || {};
      fieldSetting.width = width;
      columnSetting[columnName] = fieldSetting;
      gridPreference.columnSetting = columnSetting;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data);
    }

    let moveEvent = {};
    const onColumnMoved = (event, isDragStopped) => {
      if (event.column) {
        moveEvent = event;
      }
      if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
        saveColumnIndex(moveEvent.column.colDef.field, moveEvent.toIndex);
      }
    }


    const onColumnResized = (event) => {
      event.api.resetRowHeights();
      if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
        //event.api.resetRowHeights();
        if (this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
          const columnArray = event.columnApi.getColumnState();
          for (let i = (columnArray.length - 1); i >= 0; i--) {
            if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
              columnArray[i].width = columnArray[i].width + (this.gridColumnApi.columnController.scrollWidth - this.gridColumnApi.columnController.bodyWidth);
              break;
            }
          }
          saveColumnWidth(columnArray);
        } else {
          saveColumnWidth(event.columnApi.getColumnState());
        }
      }
    }

    const content = <AddAndRemoveColumn {...this.props} />
    const _isColumnToHide = (columnName) => {
      // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
      //     return true;
      // }
      return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }

    if (this.props.txColumns.length) {
      this.gridApi && this.gridApi.sizeColumnsToFit();
    }
    const columnDefs = [
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.product' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
        resizable: true,
        editable: true,
        sortable: true,
        permissions: this.props.permissions,
        moduleName: MODULE_CODED_VALUES.PRODUCTS,
        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        cellEditor: 'customTreeDropDown',
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (params.reactContainer && params.reactContainer.scrollHeight >= (params.node.data.maxHeight || 30)) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
              this.gridApi && this.gridApi.onRowHeightChanged();
            } else {
              params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
            }
          }, 500)
          return ReactHtmlParser(params.data.product)
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            options: this.props.products,
            onSearch: this.props.onSearch,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.PRODUCTS,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            onAddNewClick: () => {
              self.props.updateHeaderState({
                inventory: {
                  inventoryDrawerVisible: true
                }
              });
            },
            canAddNew: true,
            value: obj.node.data.selectedProductValue || [],
            selectedLabel: obj.node.data.product || "",
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.selectedProductValue = obj.node.selectedProductValue;
              obj.node.data.productObj = null;
              obj.node.data.variant = null;
              const itemId = obj.node.data.selectedProductValue && obj.node.data.selectedProductValue[0];
              if (itemId) {
                const variantId = obj.data.selectedProductValue && obj.data.selectedProductValue.length === 2 ? obj.data.selectedProductValue[1] : 0;
                obj.node.data.productObj = find(this.props.products, { itemId: Number(itemId) }) || {};
                obj.node.data.variant = variantId ? find(obj.node.data.productObj.inventoryItemVariantList, { variantId: Number(variantId) }) : obj.node.data.productObj.inventoryItemVariantList[0];
                if (obj.node.data.productObj.description) {
                  obj.node.data.description = obj.node.data.productObj.description;
                }
              }
              if (this.props.priceTypeToApply && obj.node.data.variant && !this.props.isDataToUpdate) {
                const rate = this.props.priceTypeToApply === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0] ? Number(obj.node.data.variant.retailPrice || 0).toFixed(2) : Number(obj.node.data.variant.wholesalePrice || 0).toFixed(2);
                obj.node.data.baseRate = Number(rate);
                obj.node.data.rate = Number(rate);
                //obj.node.setDataValue('specialDiscount', 0);
                //obj.node.setDataValue('baseRate', Number(rate));
                //obj.node.setDataValue('rate', Number(rate));
                //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                obj.node.data.uom = obj.node.data.variant.uomName;
                // if (obj.node.data.productObj.isPopulateTax) {
                //   const defaultItemHsnAndTax = obj.node.data.productObj.inventoryItemDefaultTaxList[0] || {};
                //   obj.node.data.hsnCode = ((defaultItemHsnAndTax || {}).hsnCode);
                //   const selectedTax = find(this.props.taxes, { taxPercent: ((defaultItemHsnAndTax || {}).igstRate || (defaultItemHsnAndTax || {}).cgstRate || (defaultItemHsnAndTax || {}).globleTaxRate) }) || {};
                //   obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                //   obj.node.data.tax = selectedTax.taxPercent;
                // }
              }
              let isDuplicateItem = false;
              this.gridApi && this.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (data.product === selectedValue && index !== obj.node.rowIndex) {
                  isDuplicateItem = true;
                }
              });
              if (isDuplicateItem) {
                showToasterMessage({
                  messageType: 'warning', description: 'Duplicate product is selected'
                });
              }

              obj.node.data.isDuplicateItem = isDuplicateItem;
            }
          }
        },
        cellClassRules: {
          'ag-grid-cell-warning-boundary': function (obj) {
            let isDuplicateItem = false;
            self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
              const { data } = rowNode;
              if (data.product && data.product === obj.node.data.product && obj.node.rowIndex !== index) {
                isDuplicateItem = true;
              }
            });
            return isDuplicateItem;
          }
        }
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.description' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        cellClass: " ag-cell-description",
        editable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
        cellEditor: 'CKEditorGrid',
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true
              })
            }
          }
        },
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
              params.eParentOfValue.style.height = 'inherit';
              params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 200)
          return ReactHtmlParser(params.data.description)
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (params.event.target.scrollHeight > (params.data.minHeight || 30)) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = 'inherit';
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = 'inherit';
              params.event.path[2].style.height = `${(params.node.data.minHeight || 30)}px`;
            }
            params.node.setRowHeight((params.node.data.minHeight || 30));
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        }
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.qty' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        editable: true,
        sortable: true,
        cellEditor: 'customNumberEditor',
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
            return { 
              decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthQty,
                onChange: (value) => {
                    obj.node.data.qty = value;
                    obj.api.refreshCells();
                }
            }
        }
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.uom' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
        editable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
        cellEditor: 'customDropDownEditor',
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.uomList,
            optionKeyName: 'uomId',
            valueKeyName: 'uomName',
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false
              }
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM)
            },
            onSelect: (selectedValue, option) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.uomName = selectedValue;
              obj.node.data.uomId = option.key;
            },
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
              obj.node.data.uomName = undefined;
              obj.node.data.uomId = undefined;
            },
          }
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },
      {
        headerComponentFramework: () => {
          return <div> <span>MRP</span><Tooltip placement="right" title={this.props.intl.formatMessage(
            {
              id: 'tooltip.originalRate',
              defaultMessage: ''
            })
          } trigger="click">
            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
          </Tooltip></div>;
        },
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.BASE_PRICE),
        field: "baseRate"
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.rate' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
        editable: true,
        sortable: true,
        cellEditor: 'customNumberEditor',
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
            onChange: (value) => {
              obj.node.data.baseRate = value;
              obj.node.data.rate = value;
              obj.api.refreshCells();
            }
          }
        }, valueGetter: (params) => {
          if (params.data.specialDiscount && params.data.rate) {
            params.data.rate = params.data.baseRate - ((params.data.specialDiscount * .01) * params.data.baseRate);
          }
          if (!params.data.specialDiscount && params.data.baseRate) {
            params.data.rate = params.data.baseRate;
          }
          return params.data.rate;
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.specialDiscount' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
        editable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT),
        cellEditor: 'customNumberEditor',
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='customer.salesOrder.form.gridHeader.discount' defaultMessage='' />;
        },
        field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
        editable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT),
        cellEditor: 'customNumberEditor',
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return { 
            decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
            maxValue: 100,
            onChange: (value) => {
              obj.node.data.discount = value;
              obj.api.refreshCells();
            }
          }
        }
      },
      // {
      //   headerComponentFramework: () => {
      //     return <FormattedMessage id='customer.salesQuote.form.gridHeader.tax' defaultMessage='' />;
      //   },
      //   field: 'taxApplied',
      //   editable: true,
      //   sortable: true,
      //   cellEditor: 'customDropDownEditor',
      //   cellEditorParams: (obj) => {
      //     return {
      //       lastColumnCell: true,
      //       items: this.props.taxes,
      //       optionKeyName: 'taxSingleRateId',
      //       canAddNew: true,
      //       canDelete: true,
      //       deleteClickHandler: (payload) => {
      //         this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
      //       },
      //       onAddNewClick: (payload) => {
      //         payload.formData = {
      //           taxName: payload.textEntered,
      //           submittedOnce: false
      //         }
      //         this.handleNewTaxAddition(this.props, payload, MODAL_TYPE.NEW_TAX)
      //       },
      //       valueKeyName: 'taxNameDisplay',
      //       onSelect: (selectedValue, option) => {
      //         const selectedTax = find(this.props.taxes, { taxSingleRateId: Number(option.key) });
      //         obj.node.data.tax = selectedTax.taxPercent;
      //         //obj.node.setDataValue(obj.colDef.field, selectedValue);
      //         obj.node.data.taxId = selectedTax.taxSingleRateId;
      //       },
      //     }
      //   }
      // },
      {
        headerComponentFramework: () => {
          return <div><FormattedMessage id='customer.salesOrder.form.gridHeader.comment' defaultMessage='' />
            <Tooltip placement="top" title={this.props.intl.formatMessage(
              {
                id: 'tooltip.warehouseDetail',
                defaultMessage: ''
              })
            } trigger="click">
              <i className="fa fa-question-circle-o pl5 cursor-pointer" />
            </Tooltip>
          </div>
        },
        field: AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD,
        editable: true,
        sortable: true,
        width: (this.props.txColumnSetting[AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.COMMENT),
        cellEditor: 'CKEditorGrid',
        cellEditorParams: (params) => {
          return {
            value: params.node.data.comment || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true
              })
            }
          }
        },
        cellRendererFramework: (params) => {
          return ReactHtmlParser(params.data.comment)
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (params.event.target.scrollHeight > 30) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = 'inherit';
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = 'inherit';
              params.event.path[2].style.height = `${30}px`;
            }
            params.node.setRowHeight(30);
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        }
      },
      {
        headerComponentFramework: () => {
          return <div><FormattedMessage id='customer.salesOrder.form.gridHeader.partNumber' defaultMessage='' />
          </div>
        },
        field: AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD,
        editable: true,
        sortable: true,
        width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PART_NUMBER),
        cellEditor: 'customTextEditor'
      },

      {
        headerComponentFramework: () => {
          return <div><FormattedMessage id='customer.salesOrder.form.gridHeader.stockNumber' defaultMessage='' />
          </div>
        },
        field: AG_GRID_CONSTANTS.COMMON.STOCK_NUMBER_FIELD,
        editable: true,
        sortable: true,
        width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.STOCK_NUMBER_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER),
        cellEditor: 'customTextEditor'
      },
      {
        headerComponentFramework: () => {
          return <div><FormattedMessage id='customer.salesOrder.form.gridHeader.origin' defaultMessage='' />
          </div>
        },
        field: AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD,
        editable: true,
        sortable: true,
        width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.ORIGIN),
        cellEditor: 'customDropDownEditor',
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.countries,
            optionKeyName: 'countryId',
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            valueKeyName: 'countryName',
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.origin = selectedValue;
            },
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
              obj.node.data.origin = undefined;
            },
          }
        },
      },
      {
        headerComponentFramework: () => {
          return <div><FormattedMessage id='customer.salesOrder.form.gridHeader.hsCode' defaultMessage='' />
          </div>
        },
        field: AG_GRID_CONSTANTS.COMMON.HS_CODE_FIELD,
        editable: true,
        sortable: true,
        width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.HS_CODE_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HS_CODE),
        cellEditor: 'customTextEditor',
        
      },
      {
        headerComponentFramework: () => {
          return `Amount`
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
        //pinned: 'right',
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount = ((params.data.discount * 0.01) * totalAmountOfColumn);
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            if (params.data.tax) {
              totalAmountOfColumn = ((params.data.tax * 0.01) * totalAmountOfColumn) + totalAmountOfColumn;
            }
            params.data.amount = totalAmountOfColumn ? totalAmountOfColumn.toFixed((this.props.companyInfo || {}).decimalDigitsLengthPrice || 0) : "";
            this.calculateSubtotal();
          }
          return totalAmountOfColumn ? fixedDecimalAndFormateNumber(totalAmountOfColumn) : "";
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN

      },
      {
        headerComponentFramework: () => {
          return <div className="cursor-pointer">
            <Popover
              content={content}
              title={<div className="text-center">Add/Remove </div>}
              placement="left"
              trigger="click"
            >
              <i className='fa fa-bars' />
            </Popover>
          </div>;
        },
        width: 70,
        field: 'action', lockPosition: true,
        pinned: 'right',
        cellRenderer: 'customActionEditor',
        suppressNavigable: true,
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              this.gridApi.updateRowData({ remove: [this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
              if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                this.gridApi.updateRowData({ add: [{}] });
              }
              this.calculateSubtotal();
            },
          }
        }
      }
    ];


    if (self.gridColumnApi && self.props.txColumnSetting) {
      (Object.keys(self.props.txColumnSetting)).forEach(function (key) {
        if (self && self.props.txColumnSetting[key].index) {
          self.gridColumnApi.moveColumn(key, self.props.txColumnSetting[key].index);
        }
      })
    }


    const getRowHeight = (params) => {
      const rowHeight = params.node.data.scrollHeight ? (Number(params.node.data.scrollHeight.split("px")[0]) - 30) : 30;
      return rowHeight < 30 ? 30 : rowHeight;
    }


    return (
      <Fragment>
        <div className="transaction-table">
          <CustomAgGrid
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            onColumnResized={onColumnResized}
            getRowHeight={getRowHeight}
            onDragStopped={() => {
              onColumnMoved({}, true);
            }}
            //onColumnMoved={onColumnMoved}
            rowData={this.props.customerSalesOrderDetailsList || [{}]}
            gridStyle={{
              width: '100%',
              height: '100%',
              marginBottom: '10px'
            }} />
        </div>

        <div style={{display: 'flex', gap: '10px'}}>

            <Button className="grey-button" onClick={
              () => {
                this.gridApi.updateRowData({ add: [{}] });
              }
            }><FormattedMessage id='customer.salesOrder.form.button.addLine' defaultMessage='' /></Button>
            <Button className="grey-button" onClick={
              () => {
                this.props.showModal({
                  modalBody: "Are you sure you want to clear?",
                  handleSubmit: () => {
                    let gridApi = this.gridApi;
                    let self = this;
                    this.gridApi.forEachNode(function (rowNode, index) {
                      gridApi.updateRowData({ remove: [rowNode.data] });
                      if (gridApi.rowRenderer.lastRenderedRow < 0) {
                        gridApi.updateRowData({ add: [{}] });
                      }
                      self.calculateSubtotal();
                    })
                  }
                })
              }
            }><FormattedMessage id='customer.salesOrder.form.button.clearAllLines' defaultMessage='' /></Button>
        </div>
              
        <div className="upload-btn-so">

            <CustomTableUpload fileList={this.props.fileList} onChange={
              (fileList) => {
                this.props.updateState({
                  fileList
                })
              }
            } />
        </div>

        <Row>
          <Col span={12}>
            <div>
              <div className="remarks-title">
                <FormattedMessage id='customer.salesOrder.form.internalRemarks.label' defaultMessage='' />
              </div>

              <CKEditor
                type="inline"
                className="description-textarea"
                key={`${"remarksInternal"}`}
                data={this.props.remarksInternal}
                onInit={editor => {
                  this.editor = editor;
                }}
                onChange={(event) => {
                  const data = event.editor.getData();
                  this.props.updateState({ remarksInternal: data });
                }}
                config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter internal remarks' }}
              />
            </div>

            <div className="remarks-title">
              <FormattedMessage id='customer.salesOrder.form.customerRemarks.label' defaultMessage='' />
            </div>

            {/* <TextArea maxLength={500}
              className="description-textarea"
              rows={4}
              placeholder={intl.formatMessage(
                { id: 'customer.salesOrder.form.customerRemarks.placeholder', defaultMessage: '' }
              )}
              value={this.props.remarksCustomer}
              onChange={(e) => { this.props.updateState({ remarksCustomer: e.target.value }); }}
            /> */}

            <CKEditor
              type="inline"
              className="description-textarea"
              key={`${"remarksCustomer"}`}
              data={this.props.remarksCustomer}
              onInit={editor => {
                this.editor = editor;
              }}
              onChange={(event) => {
                const data = event.editor.getData();
                this.props.updateState({ remarksCustomer: data });
              }}
              config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter customer remarks' }}
            />
          </Col>

          <Col span={11} offset={1}>

            <Col offset={8}>
            {<Checkbox onChange={() => {
                this.setState({ sectionVisible: { ...sectionVisible, discount: !sectionVisible.discount } })
              }}> <FormattedMessage id='customer.salesOrder.form.checkbox.discount' defaultMessage='' /></Checkbox>}
              
            </Col>
            {
                  sectionVisible.discount
                    ?
                    <Fragment>
                     

                      <Col span={8} offset={8}>
                        <div className="input-group">
                          <div className="input-label-control">
                            <span className="mark">%</span>
                            <Input
                              type='number'
                              placeholder="0.00"
                              value={this.state.rootDiscountPercent || ""}
                              onChange={(e) => {
                                this.setState({
                                  rootDiscountPercent: e.target.value
                                });
                              }}
                              onBlur={(e) => {
                                if (this.state.subtotal) {
                                  this.calculateSubtotal();
                                }
                              }
                              }
                            />
                          </div>
                          <div className="input-label-control">
                            <span className="mark">{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;  </span>
                            <Input
                              disabled
                              placeholder="0.00"
                              type='number'
                              value={this.state.rootDiscountAmount || ""}
                              onChange={(e) => {
                                this.setState({
                                  rootDiscountAmount: Number(e.target.value)
                                });
                              }}
                              onBlur={(e) => {
                                if (this.state.subtotal) {
                                  this.calculateSubtotal();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Fragment>
                    : ''
                }
            <div className="checkbox-control-group">
              
              {false && <Checkbox onChange={() => {
                this.setState({ sectionVisible: { ...sectionVisible, tax: !sectionVisible.tax } })
              }}><FormattedMessage id='customer.salesOrder.form.checkbox.tax' defaultMessage='' /></Checkbox>}
              {false && <Checkbox onChange={() => {
                this.setState({ sectionVisible: { ...sectionVisible, expense: !sectionVisible.expense } })
              }}><FormattedMessage id='customer.salesOrder.form.checkbox.expense' defaultMessage='' /></Checkbox>}
            </div>

            <div className="product-amount-details">
              <Row type="flex" justify="end" className="mb20">
                
              </Row>

              {
                sectionVisible.tax
                  ? <Fragment>
                    <Row type="flex" justify="end">
                      <Col span={8}>
                        <FormattedMessage id='customer.salesOrder.form.taxName.label' defaultMessage='' />
                      </Col>

                      <Col span={16}>
                        <Dropdown
                          items={this.props.taxes}
                          value={this.props.taxName}
                          onSelect={(selectedTax) => {
                            this.props.updateState({
                              taxName: selectedTax
                            })
                          }}
                          valueKeyName='description'
                          optionKeyName='taxSingleRateId'
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              taxName: undefined
                            });
                            this.calculateSubtotal();
                          }}
                        />
                      </Col>
                    </Row>

                    <Row type="flex" justify="end">
                      <Col span={8}>
                        <span className="label"><FormattedMessage id='customer.salesOrder.form.tax.label' defaultMessage='' /></span>
                      </Col>

                      <Col span={16}>
                        <div className="input-group">
                          <div className="input-label-control">
                            <span className="mark">%</span>
                            <Input placeholder="0.00" />
                          </div>
                          <div className="input-label-control">
                            <span className="mark">{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;  </span>
                            <Input placeholder="0.00" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                  : ''
              }

              {
                sectionVisible.expense
                  ? <Fragment>
                    <Row type="flex" className='amount-summary' justify="end">
                      <Col span={8}>
                        <FormattedMessage id='customer.salesOrder.form.totalExpense.label' defaultMessage='' />
                      </Col>

                      <Col span={16}>
                        <Dropdown
                          items={this.props.expenseTypes}
                          valueKeyName='expenseType'
                          value={this.props.expenseType}
                          canAddNew={true}
                          canDelete={true}
                          deleteClickHandler={(payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                          }}
                          onAddNewClick={(payload) => {
                            payload.formData = {
                              textBoxValue: payload.textEntered,
                              submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                          }}
                          onSelect={(expenseType, option) => {
                            this.props.updateState({
                              expenseType,
                              expenseId: option.key
                            })
                          }}
                          optionKeyName='lkExpenseTypeId'
                          allowClear={true}
                          onClear={() => {
                            this.props.updateState({
                              expenseType: undefined,
                              expenseId: undefined
                            })
                          }}
                        />
                      </Col>
                    </Row>

                    <Row type="flex" justify="end">
                      <Col span={8}>
                        <span className="label"><FormattedMessage id='customer.salesOrder.form.totalExpenseAmount.label' defaultMessage='' /></span>
                      </Col>

                      <Col span={16}>
                        <div className="input-group">
                          <div className="input-label-control">
                            <span className="mark">{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;  </span>
                            <Input
                              placeholder="0.00"
                              type='number'
                              onChange={(e) => {
                                this.setState({
                                  expenseAmount: Number(e.target.value)
                                }, () => {
                                  if (this.state.subtotal) {
                                    this.calculateSubtotal();
                                  }
                                });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                  : ''
              }

              <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title"><FormattedMessage id='customer.salesOrder.form.subTotal.label' defaultMessage='' /></span>
                  </Col>
                  <Col span={9}>
                    {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;
                    {fixedDecimalAndFormateNumber(this.state.subtotalWithoutDiscount)}
                  </Col>
                </Row>
              </div>

              {this.state.totalDiscount === '0.00' || this.state.totalDiscount === 0 ? null : <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title"><FormattedMessage id='customer.salesOrder.form.totalDiscount.label' defaultMessage='' /></span>
                  </Col>
                  <Col span={9}>
                    -{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;
                    {fixedDecimalAndFormateNumber(this.state.totalDiscount)}
                  </Col>
                </Row>
              </div>}

              {/* {
                                false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                                    <div className="w-50">
                                        <span className="title">Tax</span>
                                    </div>
                                    <div className="w-50-default">
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode }
                                        {this.state.totalTax}
                                    </div>
                                </div>
                            } */}

              {
                itemLevelTaxes.length === 0 ? null : itemLevelTaxes.map((taxObj, i) => {
                  if (isPlaceOfSupplySameAsRelationship) {

                  }
                  return (
                    (this.props.companyInfo || {}).countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                      <div className="amount-summary">
                        <Row>
                          <Col span={15}>
                            <span
                              className="title">CGST @ {this.props.update || this.props.clone ?taxObj.taxPercent: taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                          </Col>
                          <Col span={9}>
                            {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;
                                    {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                                    {(this.props.update || this.props.clone ?Number(taxObj.taxAmount || 0): Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                          </Col>
                        </Row>
                      </div>

                      <div className="amount-summary">
                        <Row>
                          <Col span={15}>
                            <span
                              className="title">SGST @ {this.props.update || this.props.clone ?taxObj.taxPercent: taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                          </Col>
                          <Col span={9}>
                            {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;
                                    {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                                    {(this.props.update || this.props.clone ?Number(taxObj.taxAmount || 0): Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                          </Col>
                        </Row>
                      </div>
                    </div> : <div key={i}>
                        <div className="amount-summary">
                          <Row>
                            <Col span={15}>
                              <span
                                className="title">IGST @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                            </Col>
                            <Col span={9}>
                              {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;
                                    {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      </div>) :
                      <div key={i}>
                        <div className="amount-summary">
                          <Row>
                            <Col span={15}>
                              <span
                                className="title">VAT @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                            </Col>
                            <Col span={9}>
                              {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;
                                {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      </div>
                  )
                })
              }

              <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title"><FormattedMessage id='customer.salesOrder.form.totalAmount.label' defaultMessage='' /></span>
                  </Col>
                  <Col span={9}>
                    {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}  &nbsp;
                    {fixedDecimalAndFormateNumber(this.state.total)}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>

        {/* <TextArea maxLength={500}
          className="add-footer-textarea"
          rows={1}
          placeholder="Add Footer Here"
          onChange={(e) => { this.props.updateState({ footer: e.target.value }); }}
          value={this.props.footer}
        /> */}
        <CKEditor
          type="inline"
          className="add-footer-textarea"
          key={`${"footer"}`}
          data={this.props.footer}
          onInit={editor => {
            this.editor = editor;
          }}
          onChange={(event) => {
            const data = event.editor.getData();
            this.props.updateState({ footer: data });
          }}
          config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Add Footer Here' }}
        />
        <div className="footer">
          <div className="left-fItems">
          <Button className="grey-button" onClick={() => {
              this.props.history.goBack();
            }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
          </div>

          <div className="right-fItems">
          <Button className="ant-btn-primary"
              onClick={() => {
                saveSalesOrder(this.props, this.state, this.gridApi);
              }}
            ><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
           
          </div>
        </div>
      </Fragment>
    );
  }
};



export default injectIntl(ProductDetails);
