import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import  ShippingComponent from '../../../components/integrations/Shipping'
import { fetchShipStationConfiguration, disconnectConfiguration, saveShipStationConfiguration  } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
// import queryString from 'query-string';
 import { getCompanyInfo } from '../../../utils';
class Shipping extends Component {

   
    
    componentDidMount() {
         const companyInfo = this.props.companyInfo || getCompanyInfo() || {};
        const payload = {
          relationshipId: companyInfo.relationshipId
        };
         this.props.fetchShipStationConfiguration(payload);
      }

    render() {
        return <ShippingComponent {...this.props}/>
    }

}

const mapStateToProps = (state) => {
    return {
        xeroConfigData: state.common.xeroConfigData,
        companyInfo: state.common.companyInfo,
        linkedShipStation: state.integration.linkedShipStationList[0] || {},
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getCompanyInfo,
    fetchShipStationConfiguration,
    disconnectConfiguration,
    saveShipStationConfiguration,
    showModal, hideModal, pushModalToStack, popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Shipping);
