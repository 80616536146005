import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SUPPLIER_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAllContacts = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=100&pageNumber=0`)
      .then(res => {
        dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_CONTACTS, data: res.data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_CONTACTS;
        }
      })
  }
}

export const deleteContact = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/deleteContact`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Contact deleted successfully' });
        axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=100&pageNumber=0`)
        .then(res => {
          dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_CONTACTS, data: res.data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
      })
      .catch((err) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const createUpdateContact = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/createContact`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Contact saved successfully' });
        if(payload.callback){
          payload.callback(res.data)
        }
        axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=100&pageNumber=0`)
          .then(res => {
            dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_CONTACTS, data: res.data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllContacts?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}&pageSize=100&pageNumber=0`)
            .then(res => {
              dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_CONTACTS, data: res.data });
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
        }
      })
  }
}

export const addContactRow = (key) => {
  return dispatch => {
    dispatch({ type: SUPPLIER_ACTION_LIST.SUPPLIER_ADD_CONTACTS, data: key });
  }
}
