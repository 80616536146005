import React, { Fragment, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button} from 'antd';
import { CustomButton } from '../../../../general/CustomButton';
import { MODAL_ACTIONS, MODAL_TYPE, CONSTANTS_TRANSACTIONS } from '../../../../../static/constants';
import { Dropdown } from '../../../../general/Dropdown';
import * as find from "lodash.find";
import { ErrorMsg } from '../../../../general/ErrorMessage';

const UserStatusCompo = (props) => {
  const { formData={}, updateFormData, data={} } = props;
  //const labelName = getLabelName(modalType);
  formData.textBoxValue = data.txData ? data.txData.userStatus : formData.textBoxValue;
  const [formDataObj, setFormDataObj] = useState(formData);
  const dispatch = useDispatch();

  const addItemConfirmationModal = (props, text, color, modalType) => {
    const { showModal, companyInfo } = props;
    // const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
    const modalBody = <span>
      <FormattedMessage id='schoolManagement.school.confirmation.create'/>
    </span>;

    let payload = {
      statusName: text,
      statusColor: color,
      relationshipId: companyInfo.relationshipId,
      transactionName: props.transactionName
    }

    const modalData = {
      modalBody,
      hideFooter: false,
      handleSubmit: () => {
         props.createStatus(payload);
         props.hideModal();
    },
    };
    showModal(modalData);
  }


  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
      addItemConfirmationModal(props, formData.textBoxValue, formData.statusColor, modalType);
    } else {
      const data = {
        title: <FormattedMessage id='addItem.text.createStatus' defaultMessage='' />,
        formData,
        // hideFooter: props.transactionName === CONSTANTS_TRANSACTIONS.STUDENT? true: false,
        hideFooter: true,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, formData.statusColor, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        }
      };
      showModal(data);
    }
  }

  const deleteClickHandler = (props, payload) => {
    const { showModal } = props;
    const modalBody =
      <FormattedMessage
        id='deleteItem.confirmation'
        defaultMessage={``}
       
      />;



    const modalData = {
      modalBody,
      handleSubmit: () => {
        const selectedObj = find((props.userStatus || []), { statusName: payload.text}) || {};
        //selectedObj.transactionName = props.transactionName;
        props.deleteStatus(selectedObj, props)
        props.hideModal();
      },
    };
    showModal(modalData);
  }

  const getStatusIdKey = (transactionName) => {
    let keyId = 'registrationStatusId';
    switch (transactionName) {
      case CONSTANTS_TRANSACTIONS.INQUIRY: {
        keyId = 'inquiryStatusId';
        break;
      }
      case CONSTANTS_TRANSACTIONS.SALES_QUOTE: {
        keyId = 'salesQuoteStatusId';
        break;
      }
      case CONSTANTS_TRANSACTIONS.SALES_ORDER: {
        keyId = 'soStatusId';
        break;
      }
      case CONSTANTS_TRANSACTIONS.SALES_INVOICE: {
        keyId = 'salesInvoiceStatusId';
        break;
      }
      case CONSTANTS_TRANSACTIONS.SHIPMENT: {
        keyId = 'shipmentStatusId';
        break;
      }
      case CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST: {
        keyId = 'prStatusId';
        break;
      }
      case CONSTANTS_TRANSACTIONS.PURCHASE_ORDER: {
        keyId = 'poStatusId';
        break;
      }
      case CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY: {
        keyId = 'inboundDeliveryStatusId';
        break;
      }
      case CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE: {
        keyId = 'purchaseInvoiceStatusId';
        break;
      }
      case CONSTANTS_TRANSACTIONS.PACKAGE: {
        keyId = 'packageStatusId';
        break;
      }
      default:
        break;
    }
    return keyId;
  };
 
  return (
    <Fragment>
      <Form
        layout='vertical'
        className='login-form'
        onSubmit={(e) => {
          e.preventDefault();
          if ( formDataObj.textBoxValue && (formDataObj.textBoxValue||'').length && formDataObj.statusColor) {
            e.preventDefault();
            props.handleSubmit(formDataObj);
          } else {
            setFormDataObj({ submittedOnce: true, textBoxValue: formDataObj.textBoxValue, statusColor: formDataObj.statusColor });
          }
        }}
      >
         <div className="create-new-control pb10" style={{height:"106px"}}>
        <Row gutter={[16, 24]}>
          <Col span={6} className='padding-top-7'>
            <span className="text-input-label">
            <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />
              <span className="required">*</span>
            </span>
          </Col>
        
          <Col span={18} className='userStatus'>
            <Dropdown
              items={props.userStatus || []}
              valueKeyName='statusName'
              statusColorKey='statusColor'
              placeholder ='Select'
              optionKeyName={getStatusIdKey(props.transactionName)}
              value={formDataObj.textBoxValue}
              style={{ width: '100%'}}
              canAddNew={props.transactionName === CONSTANTS_TRANSACTIONS.STUDENT ? true: true}
              canDelete={props.transactionName === CONSTANTS_TRANSACTIONS.STUDENT ? false: true}
              onAddNewClick={(payload) => {
              setFormDataObj({ ...formDataObj, textBoxValue: "" });
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false,
                  maxLength: 25
                }
                handleNewItemAddition(props, payload, props.transactionName === CONSTANTS_TRANSACTIONS.STUDENT ? MODAL_TYPE.NEW_STATUS : MODAL_TYPE.STATUS_NAME);
              }}
              deleteClickHandler={(payload) => {
                deleteClickHandler(props, payload);
              }}
              onSelect={(selectedValue, option) => {
                let selectedObj = find((props.userStatus || []), { [getStatusIdKey(props.transactionName)]: option.value }) || {};
                updateFormData && updateFormData({ ...formDataObj, textBoxValue: ((option || {}).props || {}).name, statusColor: selectedObj.statusColor });
                setFormDataObj({ ...formDataObj, textBoxValue: ((option || {}).props || {}).name, statusColor: selectedObj.statusColor });
                dispatch({
                  type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                  data: { formData: { textBoxValue: ((option || {}).props || {}).name, optionId: ((option || {}).props || {}).key, statusColor: selectedObj.statusColor } }
                })
              }}
            />
            <ErrorMsg
            validator={() => { return !formDataObj.submittedOnce || !!formDataObj.textBoxValue }}
            message={<FormattedMessage
              id='requiredField.missing.msg'
              defaultMessage=''
              values={{ value: 'Status Name' }}
            />}
          />
          </Col>
        </Row>
     </div>
        
        <Row className='modal-footer'>
          <Col span={21}>
            
            <Button
             
              key={1}
              onClick={() => { dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL });}}
            >
              Cancel
              </Button>
            </Col>
              <Col span={3}>
            <CustomButton className="right-pull"
              intlId={'button.save.label'}
              defaultMessage={''}
              htmlType='submit'
              key={2}
            />
          </Col>
        </Row>
      </Form>
    </Fragment>
  )

  
}
export default (injectIntl(UserStatusCompo));