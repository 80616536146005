import React from "react";
import { FormattedMessage } from "react-intl";
import { getMomentDateForUIReadOnly } from "../../../../../../utils";
import { CONSTANTS ,ICONS} from "../../../../../../static/constants";

function EducationListing(props){
return (
    <div className="view-container">

    <div className="view-container-actions">
        <div className="left-actions">
            <div className="table-heading">
                <FormattedMessage
                    id="company.employeeMangement.employee.education.table.heading"
                    defaultMessage=""
                />
            </div>
            {[]?.length ? (
                <>
                    <div className="vertical-sep" />
                    <div>{[]?.length}</div>
                </>
            ) : (
                ""
            )}
        </div>
        <div className="right-actions">
              <button
                style={{
                  backgroundColor: "#1cb961",
                  color: "#ffffff",
                  fontSize: "14px",
                }}
                className="create-action-btn"

                onClick={() => {
                props.updateState({ educationDrawer: true, educationData: {} });

                }}
              >
                <i className={`${ICONS.ADD} mr5`} />
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
    </div>



    <div className="table-container">
        <table id="employee-table">
            <thead>
                <tr>
                    <th>
                        <FormattedMessage id="serial.no" defaultMessage=" " />
                    </th>

                    <th>Name</th>
                    <th>Type</th>
                    <th>Start Date</th>
                    <th>End  Date</th>
                    <th>Grade</th>
                </tr>
            </thead>
            <tbody>
                {([]).map((rowData, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>

                            <td>
                                {rowData.employeeCode ? rowData.employeeCode : "-"}
                            </td>

                            <td>
                                {rowData.firstName +
                                    (rowData.lastName
                                        ? " " + rowData.lastName
                                        : "")}
                            </td>

                            <td>{rowData.empType}</td>

                            <td>{rowData.hierarchyName}</td>
                            <td>{rowData.hierarchyCode}</td>
                            <td>{rowData.jobTitleName}</td>
                            <td>
                                {rowData.dateCreated
                                    ? getMomentDateForUIReadOnly({
                                        date: rowData.dateCreated,
                                        format: CONSTANTS.TABLE_DATE_FORMAT,
                                    })
                                    : "-"}
                            </td>


                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>



</div>
)
}
export default EducationListing