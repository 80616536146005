import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getSubscriptionPayments = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/alpidepay/subscriptionPayment/getAllSubscriptionPayment?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.SUBSCRIPTION_PAYMENT_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getSubscriptionPaymentCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/alpidepay/subscriptionPayment/countAllSubscriptionPayment?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.SUBSCRIPTION_PAYMENT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.SUBSCRIPTION_PAYMENT_LIST_PAGE_RESET });
  };
};
