import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip, Menu } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from '../../../../utils'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import { CONSTANTS } from '../../../../static/constants';
import DropdownAction from "antd/lib/dropdown";
import { MoreOutlined, StopOutlined,} from '@ant-design/icons';
// import { Dropdown } from '../../../general/Dropdown';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const CustomerSubscriptionListingComp = (props) => {
    const { customerSubscriptionList, updateState, companyInfo, customerSubscriptionCount, pageNumber } = props;

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchCustomerSubscriptions', 'customerSubscriptionList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetSubscriptionsPaginatedData();
        props.fetchCustomerSubscriptions(payload);
       props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    const cancelSubscription = (param) => {
        props.showModal({
            modalBody: "Are you sure? You want to cancel recurring payment",
            handleSubmit: () => {
                props.cancelSubscription({
                   // refreshToken: (props.availablePaymentGatewaysAuthNet || {}).refreshToken,
                    subscriptionId: param.data.customerSubscriptionId,
                    relationshipId: companyInfo.relationshipId
                })
            }
        })
    };

    // const actionMenu = (param) => {
    //     return (
    //         <Menu className="cell-action-dropdown">
    //             <Menu.Item key="0" value="view" onClick={() => {
    //                 //openStudentRegistartionDetails(param.data);
    //             }}>
    //                 <i className="fa fa-trash-o" />
    //                 <FormattedMessage id='cancelSubscription.text' />
    //             </Menu.Item>
    //         </Menu >
    //     )
    // };
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.customerPayment.listing.header.customer' defaultMessage='' />;
    //         },
    //         field: "customerName",
    //         //hide: _isColumnToHide('customerName'),
    //         colId: 'customerName_1',
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <div>{link.data.customerName}</div>
    //             </div>
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='programName.text' defaultMessage='' />;
    //         },
    //         field: "formName",
    //         //hide: _isColumnToHide('formName'),
    //         colId: 'formName_1',
    //         minWidth: 150,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='supplier.detail.transaction.gridHeader.amount' defaultMessage='' />  </div>;
    //         },
    //         field: "amount",
    //         colId: 'amount_1',
    //         //hide: _isColumnToHide('amount'),
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => {
    //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
    //         },
    //         valueFormatter: (link) => {
    //             return link.data.amount ? Number(link.data.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='modal.common.project.startDate' defaultMessage='' />;
    //         },
    //         field: "subscriptionStartDate",
    //         //hide: _isColumnToHide('paymentDate'),
    //         colId: 'subscriptionStartDate_1',
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //              <Tooltip placement="topLeft" title={(link.data.subscriptionStartDate && getMomentDateForUIReadOnly({date: link.data.subscriptionStartDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {getMomentDateForUIReadOnly({date: new Date(link.data.subscriptionStartDate), format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='noOfPayment.text' defaultMessage='' />;
    //         },
    //         field: "noOfPayments",
    //        // hide: _isColumnToHide('invoiceNumber'),
    //         colId: 'noOfPayments_1',
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>{link.value === 9999 ? <FormattedMessage id='recurringInvoice.setup.neverExpires'/> : link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='paymentGateway.available' defaultMessage='' />;
    //         },
    //         field: "paymentGatewayName",
    //         //hide: _isColumnToHide('project'),
    //         colId: "paymentGatewayName_1",
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => {
    //             return <div> {link.value}</div>
    //         },
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.dateCreated' />;
    //         },
    //         field: "dateCreated",
    //         //hide: _isColumnToHide('paymentDate'),
    //         colId: 'dateCreated_1',
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //              <Tooltip placement="topLeft" title={(link.data.dateCreated && getMomentDateForUIReadOnly({date: link.data.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
    //             {getMomentDateForUIReadOnly({date: new Date(link.data.dateCreated), format})}
    //             </Tooltip>
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className="cursor-pointer">

    //             </div>;
    //         },
    //         cellRendererFramework: (link) => {
    //             return <Button className="action-btn small" title="Cancel Recurring Payments" onClick={() => {
    //                 cancelSubscription(link);
    //             }}>
    //                 <i className="fa fa-ban" />
    //             </Button>
    //         },
    //         resizable: true,
    //         pinned: 'right',
    //         field: 'action',
    //         colId: "action_1",
    //         minWidth: 52,
    //         maxWidth: 52,
    //         suppressMovable: true,
    //         cellClass: 'lastActionCell'
    //     }
    // ];

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const breadCrumbList = [
    //     // {
    //     //     name: 'Dashboard',
    //     //     link: 'dashboard'
    //     // },
    //     // {
    //     //     name: 'Customer',
    //     //     link: 'customers'
    //     // },
    //     {
    //         name: 'Sales',
    //     },
    //     {
    //         name: 'Payments',
    //     }
    // ];

    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     if (props.isColumnFitTable && params.api) {
    //         params.api.sizeColumnsToFit();
    //         props.updateState({
    //             isColumnFitTable: false
    //         })
    //     }
    //     onDataRendered(params);
    // }

    // const onModelUpdated = (params) => {
    //     if ((customerSubscriptionList[pageNumber] || []).length && !props.isRowHeightSet) {
    //         setTimeout(() => {
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
       
    // }

    // const onDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // }


    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
    
              <Menu.Item key="0" value="title">
                Actions
              </Menu.Item>
    
              <Menu.Divider />
    
              <Menu.Item key="1" onClick={()=>
                {
                    cancelSubscription(e);
                }
              }>
                <StopOutlined />
                Delete
              </Menu.Item>
    
            </Menu >
        )
      };



    return (
        <Fragment>
            <Skeleton loading={props.isLoading}
                active
                paragraph={{ rows: 12 }}
            >
                {/* <div className='agGridWrapper'>
                    <div className="ag-theme-balham" style={agGridStyle} >
                        
                        <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            rowData={customerSubscriptionList[pageNumber] || []}
                            rowDragManaged={true}
                            // onGridReady ={(params) => {
                            //     gridApi = params.api;
                            // }}
                            domLayout={"autoHeight"}
                            defaultColDef={{
                                flex: 1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            animateRows={true}
                            //getRowHeight={getRowHeight}
                            onModelUpdated={onModelUpdated}
                            // onColumnResized={onColumnResized}
                            // onDragStopped={() => {
                            //     onColumnMoved({}, true);
                            // }}
                            //onColumnMoved={onColumnMoved}
                        >
                        </AgGridReact>
                    </div>
                </div> */}

                <div className='table-container'>
                    <table id='recurring-payments'>
                        <thead>
                            <tr>
                                <th>S. No</th>
                                <th><FormattedMessage id='customer.customerPayment.listing.header.customer' defaultMessage='' /></th>
                                <th><FormattedMessage id='programName.text' defaultMessage='' /></th>
                                <th><FormattedMessage id='supplier.detail.transaction.gridHeader.amount' defaultMessage='' /></th>
                                <th><FormattedMessage id='modal.common.project.startDate' defaultMessage='' /></th>
                                <th><FormattedMessage id='noOfPayment.text' defaultMessage='' /></th>
                                <th><FormattedMessage id='paymentGateway.available' defaultMessage='' /></th>
                                <th><FormattedMessage id='common.dateCreated' /></th>
                                <th width='6%'><FormattedMessage id='actions.text' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerSubscriptionList && customerSubscriptionList[pageNumber]
                                ? customerSubscriptionList[pageNumber].map((rowData, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{rowData.customerName}</td>
                                        <td>{rowData.formName}</td>    
                                        <td>{rowData.amount}</td>    
                                        <td>
                                            <Tooltip placement="topLeft" title={(rowData.subscriptionStartDate && getMomentDateForUIReadOnly({date: rowData.subscriptionStartDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
                                                {getMomentDateForUIReadOnly({date: new Date(rowData.subscriptionStartDate), format})}
                                            </Tooltip>
                                        </td>    
                                        <td>
                                            {rowData.noOfPayments === 9999 ? <FormattedMessage id='recurringInvoice.setup.neverExpires'/> : rowData.noOfPayments}
                                        </td>    
                                        <td>{rowData.paymentGatewayName}</td>    
                                        <td>
                                            <Tooltip placement="topLeft" title={(rowData.dateCreated && getMomentDateForUIReadOnly({date: rowData.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}> 
                                                {getMomentDateForUIReadOnly({date: new Date(rowData.dateCreated), format})}
                                            </Tooltip>    
                                        </td>    
                                        <td style={{paddingLeft: '50px'}}>
                                            <DropdownAction overlay={actionMenu(rowData)} trigger={['click']}>
                                                <MoreOutlined />
                                            </DropdownAction>
                                        </td>    
                                    </tr>
                                )) : ''
                            }
                        </tbody>
                    </table>
                </div>
            </Skeleton>
            <div className="footer">
                <div className="f-left"></div>
                <div className="f-right">
                    <Pagination
                        size="small"
                        total={customerSubscriptionCount}
                        showTotal={showTotal}
                        defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber, pageSize, props)
                        }}
                    />
                </div>
            </div>
        </Fragment >
    );
};

export default injectIntl(CustomerSubscriptionListingComp);
