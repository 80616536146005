import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { DropdownGrid } from './DropdownGrid.js'
import { TreeDropDown } from './TreeDropDownGrid'
import { TreeDropDownStock } from './TreeDropDownStockGrid'
import { TextNumberEditorGrid } from './TextNumberEditorGrid'
import { CKEditorGrid } from './CKEditorGrid'
import { TextEditorGrid } from './TextEditorGrid';
import { InputGrid } from './customInputGrid';
import { DatePickerGrid } from './CustomDatePickerGrid';
import { CheckboxGrid } from './CheckBoxEditorGrid';
import { CustomUploaderGrid } from './CustomUploaderGrid';
import { ActionComponent, BiddingSuppliersGridActions, ActionEditAndDeleteComponent, ActionSaveAndDeleteComponent, SplitActionComponent, QualityAction, ActionLock } from './GridActionComponent';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

export const CustomAgGrid = (props) => {

  const { columnDefs, rowData } = props;




  const onCellKeyDown = (e) => {
    e.node.shiftKey = e.event.shiftKey ? true : false;
    e.node.tabKey = e.event.key === 'Tab' ? true : false;
    e.node.enterKey = e.event.key === 'Enter' ? true : false;
    e.node.downKey = e.event.key === 'ArrowDown' ? true : false;
  }

  return (
    <div
      className={props.gridClassName || 'ag-theme-balham'}
      style={props.gridStyle}
    >
      <AgGridReact
        frameworkComponents={{
          customTreeDropDown: TreeDropDown,
          customTreeDropDownStock: TreeDropDownStock,
          customDropDownEditor: DropdownGrid,
          customTextEditor: TextEditorGrid,
          customNumberEditor: TextNumberEditorGrid,
          customActionEditor: ActionComponent,
          CKEditorGrid: CKEditorGrid,
          customInputEditor: InputGrid,
          customCheckboxEditor: CheckboxGrid,
          QualityActionEditor: QualityAction,
          biddingSuppliersActions: BiddingSuppliersGridActions,
          actionEditAndDelete: ActionEditAndDeleteComponent,
          actionSaveAndDelete: ActionSaveAndDeleteComponent,
          splitActionComponent: SplitActionComponent,
          customFileUploader: CustomUploaderGrid,
          customDatePicker: DatePickerGrid,
          ActionLock: ActionLock
        }}
        singleClickEdit={!props.noSingleClickEdit}
        rowDragManaged={true}
        domLayout={"autoHeight"}
        defaultColDef={{...props.defaultColDef, sortable: true}}
        animateRows={true}
        suppressDragLeaveHidesColumns={true}
        getRowHeight={props.getRowHeight}
        onFirstDataRendered={props.onFirstDataRendered}
        onGridSizeChanged={props.onGridSizeChanged}
        onModelUpdated={props.onModelUpdated}
        onColumnResized={props.onColumnResized}
        onDragStopped={props.onDragStopped}
        //onColumnMoved={props.onColumnMoved}
        stopEditingWhenGridLosesFocus={props.disableFocusOnClickOutside || false}
        columnDefs={columnDefs}
        rowSelection={props.rowSelection || ''}
        onGridReady={props.onGridReady}
        onCellKeyDown={props.onCellKeyDown || onCellKeyDown}
        rowData={rowData}
        suppressClickEdit={props.suppressClickEdit || false}
        editType={props.editType}
        enableCellTextSelection={props.disableCellTextSelection || true}>
      </AgGridReact>
    </div >
  );
}
//
