import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SETTING_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchEmailTemplate = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        dispatch({ type: SETTING_ACTION_LIST.RESET_EMAIL_TEMPLATE_DATA });
        return axios
          .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailTemplate/getEmailTemplate?docName=${payload.docName}&relationshipId=${payload.relationshipId}`)
          .then(res => {
            const { data } = res
            dispatch({ type: SETTING_ACTION_LIST.EMAIL_TEMPLATE_DATA, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = SETTING_ACTION_LIST.EMAIL_TEMPLATE_DATA;
            }
          })
      }
  }

export const saveOrUpdateEmailTemplate = (payload, props) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailTemplate/saveOrUpdateEmailTemplate`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: SETTING_ACTION_LIST.EMAIL_TEMPLATE_DATA, data: res.data });
        showToasterMessage({ description: 'Saved Succesfully', messageType: 'success', });
        if (props.isFormSetting) {
          props.popModalFromStack();
        }
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const fetchSenderEmailsFromRID = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailManager/getFromEmail?relationshipId=${payload.relationshipId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: SETTING_ACTION_LIST.SENDER_EMAILS, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = SETTING_ACTION_LIST.SENDER_EMAILS;
          }
        })
    }
}