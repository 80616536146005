import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { CONSTANTS } from '../../../../../static/constants';
import { fixedDecimalAndFormateNumber, getMomentDateForUIReadOnly } from '../../../../../utils';
import CustomerPaymentDetails from "../../../../../containers/modal/modalBody/customer/PaymentDetail";
import CustomerProfile from '../../../../../containers/modal/modalBody/customer/Profile'
import SalesInvoiceDetails from "../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const SalesInvoicePaymentList = (props) => {
    const { salesInvoiceDetail } = props;

    const openPaymentDetails = (data) => {
        const paymentPayload = {
            customerId: data.customerId,
            customerPaymentId: data.customerPaymentId,
        };
        const modalData = {
            title: "Sales Payment",
            modalBody: <CustomerPaymentDetails paymentPayload={paymentPayload} />,
            width: 500,
            hideFooter: true,
        };
        props.showModal(modalData);
    };

    const openSalesInvoiceDetails = (data) => {
        const invoicePayload = {
            customerId: data.customerId,
            invoiceMasterId: data.invoiceMasterId,
        };
        props.pushModalToStack({
            modalBody: (
                <SalesInvoiceDetails {...props} invoicePayload={invoicePayload} />
            ),
            width: "100%",
            hideFooter: true,
            wrapClassName: "modal-custom-detail",
        });
    };
    return (

                    <div className="inner-content"> 
                        <div className="table-container" style={{minHeight: 400}}>
                            <table id="payments-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage
                                                id="customer.customerPayment.listing.header.paymentNo"
                                                defaultMessage=""
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage
                                                id="customer.customerPayment.listing.header.customer"
                                                defaultMessage=""
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage
                                                id="customer.customerPayment.listing.header.invoiceNo"
                                                defaultMessage=""
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage id="common.project" defaultMessage="" />
                                        </th>
                                        <th>
                                            <FormattedMessage
                                                id="customer.customerPayment.listing.header.paymentAmount"
                                                defaultMessage=""
                                            />{" "}
                                        </th>

                                        <th>
                                            <FormattedMessage
                                                id="customer.customerPayment.listing.header.paymentDate"
                                                defaultMessage=""
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage id="modal.customer.payInvoice.paymentMode" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="paymentSource.text" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="transactionId.text" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salesInvoiceDetail.customerPaymentList && salesInvoiceDetail.customerPaymentList.length
                                        ? salesInvoiceDetail.customerPaymentList.map((rowData, index) => (
                                            <tr key={index}>


                                                <td>
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            openPaymentDetails(rowData);
                                                        }}
                                                    >
                                                        {rowData.paymentNumber}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="cursor-pointer">
                                                        <div
                                                            // onClick={() => {
                                                            //   props.history.push("customers/details", {
                                                            //     customerId: rowData.customerId,
                                                            //     customerName: rowData.customerName,
                                                            //   });
                                                            onClick={() => {
                                                                props.pushModalToStack({

                                                                    modalBody: <CustomerProfile {...props}
                                                                        customerId={rowData.customerId}
                                                                    //   customerName={rowData.customerName}
                                                                    //   customerDetails={rowData}
                                                                    />,
                                                                    width: '100%',
                                                                    hideTitle: true,
                                                                    hideFooter: true,
                                                                    wrapClassName: "new-transaction-wrapper",
                                                                })
                                                            }}

                                                        >
                                                            {rowData.customerName}
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            openSalesInvoiceDetails(rowData);
                                                        }}
                                                    >
                                                        {rowData.invoiceNumber}
                                                    </div>
                                                </td>
                                                <td>
                                                    {rowData.projectMasterId ? (
                                                        <span>
                                                            {rowData.projectMasterId +
                                                                "-" +
                                                                rowData.projectName}
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>

                                                <td>
                                                    {rowData.paymentAmount &&
                                                        rowData.paymentAmount !== "-" ? (
                                                        <span>
                                                            {" "}
                                                            {rowData.foreignCurrencyIcon ? (
                                                                <i className={rowData.foreignCurrencyIcon}></i>
                                                            ) : rowData.foreignCurrency ? (
                                                                <span>{rowData.foreignCurrency}</span>
                                                            ) : props.companyInfo.currencyIcon ? (
                                                                <i
                                                                    className={props.companyInfo.currencyIcon}
                                                                ></i>
                                                            ) : (
                                                                props.companyInfo.currencyCode + " "
                                                            )}
                                                            {fixedDecimalAndFormateNumber(
                                                                rowData.paymentAmount
                                                            )}{" "}
                                                        </span>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </td>

                                                <td>
                                                    <div>
                                                        <Tooltip
                                                            placement="topLeft"
                                                            title={
                                                                rowData.paymentDate &&
                                                                getMomentDateForUIReadOnly({
                                                                    date: rowData.paymentDate,
                                                                    format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                                                })
                                                            }
                                                        >
                                                            {getMomentDateForUIReadOnly({
                                                                date: new Date(rowData.paymentDate),
                                                                format,
                                                            })}
                                                        </Tooltip>
                                                    </div>
                                                </td>

                                                <td>{rowData.paymentModeName || ""}</td>

                                                <td>
                                                    {rowData.paymentSource ? (
                                                        <FormattedMessage id={rowData.paymentSource} />
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>

                                                <td>{rowData.transactionId || ""}</td>


                                            </tr>
                                        ))
                                        : ""}
                                </tbody>
                            </table>
                        </div>
                    </div>

            
    );

}





export default SalesInvoicePaymentList;
