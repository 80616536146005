import { Col, DatePicker, Drawer, Row,Skeleton } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown"

const ConfigurationDrawer = (props) => {
  const closeDrawer = () => {
    props.updateState({
        lateConfigurationDrawer: false,
    });
  };

  return (
    <Drawer
    title="Create New"
    width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.lateConfigurationDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
                
            }
            
            }
            key={1}
          />
        </>
      }
    
    >
        <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

      <Row gutter={[16,16]}>
      
        <Col span={12}>
          <div className="i-label">For Days
          <span className="required">*</span>

          </div>
          <TextBox
            placeholder="For Days"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label">Day of Salary Deduction
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Day of Salary Deduction"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label">Status
          <span className="required">*</span>
          
          </div>
         <Dropdown/>
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>

      </Row>
      </Skeleton>
    </Drawer>
  );
};

export default ConfigurationDrawer;
