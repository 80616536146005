import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomerPaymentListingComp from '../../../../components/customer/payment/Listing';
import CustomerPaymentFilterComp from '../../../../components/customer/payment/Listing/paymentFilter';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchCustomerPayments, getCustomerPaymentsCount, resetPaginatedData, postPaymentsToXero, deletePayment, fetchCustomerSubscriptions, getCustomerSubscriptionsCount, resetSubscriptionsPaginatedData, cancelSubscription } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack, fetchUserPreferences, saveUserPrefrences, fetchProjects } from '../../../../actions/commonActions';
import { fetchAvailablePaymentGateway } from '../../../integrations/Payment/action';
import { fetchCustomers } from '../../Listing/action';
import { FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';

class CustomerPaymentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      txPreferences: { txType: "customerPayment", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [ 'Xero', 'paymentNumber', 'paymentAmount', 'paymentDate'],
      txAvailableColumn: {
        isXeroUploaded: true,
        soNumber: true,
        paymentNumber: true,
        invoiceNumber: true,
        customerName: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        paymentAmount: true,
       // status: true,
        paymentDate: true,
        quotationNumber: true,
        // rfqNumber: true,,
        project: true,
        description: true,
        paymentMode: true,
        transactionId: true,
        remarksCustomer: true,
        paymentSource: true,
        isGiftAidIt : (props.companyInfo || {}).countryName === 'United Kingdom (UK)' ? true: false
      },
      selectedItems:[] 
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.sales.salesPayment' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
        {
            name: <FormattedMessage id='sidebar.menuItem.sales' defaultMessage='' />,
        }, 
        {
            name: <FormattedMessage id='sidebar.menuItem.customerPayment' defaultMessage='' />,
        }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25
    };
    this.props.resetSubscriptionsPaginatedData();
   // this.props.fetchAvailablePaymentGateway(payload);
    fetchPaginationDataIfNeeded('fetchCustomerPayments', 'customerPaymentList', this.props, payload);
    fetchDataIfNeeded('getCustomerPaymentsCount', 'customerPaymentCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    fetchDataIfNeeded('getCustomerSubscriptionsCount', 'customerPaymentCount', this.props, payload);
    fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
    fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "customerPayment", templateName: "standard" }) || this.state.txPreferences;
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
       {this.state.salesPaymentFilterDrawerVisible && <CustomerPaymentFilterComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}/>}
        <CustomerPaymentListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    customerPaymentList: state.customerPayment.customerPaymentList,
    customerPaymentCount: state.customerPayment.customerPaymentCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    customerSubscriptionList: state.customerPayment.customerSubscriptionList,
    customerSubscriptionCount: state.customerPayment.customerSubscriptionCount,
    availablePaymentGatewaysAuthNet: state.studentRegistration.availablePaymentGateways,
    customers: state.customer.customers,
    projectList: state.common.projectList,
    isAlifApp: state.common.isAlifApp,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCustomerPayments,
  getCustomerPaymentsCount,
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
  deletePayment,
  postPaymentsToXero,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchCustomerSubscriptions, 
  getCustomerSubscriptionsCount, 
  resetSubscriptionsPaginatedData,
  fetchAvailablePaymentGateway,
  cancelSubscription,
  fetchCustomers,
  fetchProjects
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentListing);
