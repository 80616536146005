import React from "react";
import "./style.scss";
import { Button, Result } from "antd";
import PlanBox from "./planBox";



function PackageComp(props) {

    const { starter = {}, growth = {}, advanced = {} } = props;
    const calculateDiscount = (monthlyAmount, yearlyAmount) => {
        const totalMonthlyPayment = monthlyAmount * 12;
        
        yearlyAmount = Math.min(yearlyAmount, totalMonthlyPayment);
    
        const discount = totalMonthlyPayment - yearlyAmount;
        const discountPercentage = Math.round((discount / totalMonthlyPayment) * 100);
    
        return discountPercentage >= 0 ? discountPercentage : 0;
    };
    
    const discountPercentage = calculateDiscount(props.selectedPlan?.monthPrice, props.selectedPlan?.annualPrice);

    return (
        [...Object.keys(starter), ...Object.keys(growth), ...Object.keys(advanced)].length ?
            <div className="ind_component">

                <div className="headingArea">
                    <div className="planPricingContaner">
                        <div className="price_page_head">
                            <h2>Pick a <span>
                                {/* {props.companyInfo.industryName || "ERP"} */}
                                {(props.alpideProducts || []).find(item => item.alpideProductId === props.alpideProductId)?.alpideProductName || []}
                                </span> Payment Plan</h2>
                            <p>Flexible Payment Plans Tailored for All Businesses</p>
                        </div>
                        <div className="packageTabArea">
                            <div className="active">01. Package</div>
                            <div>02. Add-on</div>
                            <div>03. Confirmation</div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="planPricingContaner">
                        <div className="payOptionArea">
                        {discountPercentage > 0 && 
                            <div className="recommend">
                           Save {calculateDiscount(props.selectedPlan?.monthPrice, props.selectedPlan?.annualPrice)} % 
                            </div>
}
                            <div
                                className={`payOption ${props.priceTerm === 'monthPrice' ? 'active' : ''}`}
                                onClick={() => {
                                    props.updateState({ priceTerm: 'monthPrice' });
                                }}
                            >
                                Pay Monthly
                            </div>
                            <div
                                className={`payOption ${props.priceTerm === 'annualPrice' ? 'active' : ''}`}
                                onClick={() => {
                                    props.updateState({ priceTerm: 'annualPrice' });
                                }}
                            >
                                Pay Yearly
                            </div>
                        </div>
                    </div>
                </div>

                <div className="planPricingContaner">
                    <div className="textArea">
                        {starter.currencyCode ? <p>Prices are in <span>{starter.currencyCode || 'USD'}</span></p> : ''}
                    </div>

                    {/* plan boxes */}
                    <div className="pricingBoxArea">
                        {Object.keys(starter).length ? <PlanBox {...props} data={starter} /> : ''}

                        {Object.keys(growth).length ?
                            <div className="recommend_box">
                                <PlanBox {...props} data={growth} style={{ width: "100%", maxWidth: "unset" }} isRecommeded />
                            </div>
                            :
                            ''
                        }

                        {Object.keys(advanced).length ? <PlanBox {...props} data={advanced} /> : ''}
                    </div>

                    <div className="textArea1">
                        <p>*The above pricing doesn't include setup cost.</p>
                        <p>You may cancel your subscription at any time, but please note that refunds are not provided for partially used subscriptions.</p>
                    </div>
                </div>

                <div className="footerPricing">
                    <div className="planPricingContaner">
                        <div className="footerButton">
                            <Button
                                type="primary"
                                disabled={!props.selectedPlan?.subcriptionPlanCostId}
                                onClick={() => { props.updateState({ activeTab: props.activeTab <= 1 ? props.activeTab + 1 : props.activeTab }) }}
                            >
                                Explore Addon
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
            :
            <Result
                status="404"
                title="No Plan Exists!"
                subTitle="Sorry, its not you, its us, please contact support team on support@alpide.com"
                extra={<Button type="primary">Contact Support</Button>}
            />
    )
}
export default PackageComp