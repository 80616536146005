import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssignmentResponsePreviewComponent from '../../../components/schoolManagement/AssignmentResponsePreview';
import {
    fetchPaymentTerms, addPaymentTerm, deletePaymentTerm,
    fetchBusinessTypes, addBusinessType, deleteBusinessType,
    fetchIndustries, addIndustry, deleteIndustry,
    fetchSources, addCustomerSource, deleteCustomerSource,
    fetchAssignmentGroup, addAssignmentGroup, deleteAssignmentGroup,
    pushModalToStack, popModalFromStack, showModal, hideModal
} from '../../../actions/commonActions';

import { getAllStudentForm } from '../../Student/studentFormList/action';
import { getClass } from '../../schoolManagement/class/action';
import { getSchoolCourse } from '../../schoolManagement/course/action';
import { assignGrade } from './action';
import { getAssignmentData } from '../../modal/modalBody/schoolManagement/CreateAssignment/action';
import * as find from 'lodash.find';
import { downloadFileFromBucket } from '../../../actions/downloadFileAction';


class AssignmentResponsePreview extends Component {
    constructor(props) {
        super(props);
        const linkProps = (this.props.location && this.props.location.state) || {};
        this.state = {
            current: 0,
            pageNumber: 1,
            pageSize: 1000,

            assignmentId: linkProps.assignmentId,
            classId: linkProps.classId,
            studentId: linkProps.studentId,
            assignmentResponseId: linkProps.assignmentResponseId
        };
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            relationshipEmployeeId: userInfo.relationshipEmployeeId,
            pageNumber: 1,
            pageSize: 1000,
            formId: this.props.defaultSchoolForm,
            classId: this.state.classId,
            assignmentId: this.state.assignmentId
        };
        this.props.getAssignmentData(payload);
    }

    componentWillReceiveProps(props) {
        if (!this.state.isLoadData && props.assignmentData && props.assignmentData.assignmentId) {
            const assignmentResponseObj = find(props.assignmentData.assignmentResponseList || [], { assignmentResponseId: this.state.assignmentResponseId }) || {};

            this.setState({
                gradingRemark: assignmentResponseObj.teacherComment,
                assignedGrade: assignmentResponseObj.grade,
                isLoadData: true,
                assignmentResponseObj
            })
        }
    }

    render() {
        return <AssignmentResponsePreviewComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        userInfo: state.common.userInfo,
        sources: state.common.sources,
        businessTypes: state.common.businessTypes,
        paymentTerms: state.common.paymentTerms,
        industries: state.common.industries,
        companyInfo: state.common.companyInfo,
        studentFormList: state.membershipReducer.studentFormList,
        courseList: state.school.courseList,
        assignmentGroupList: state.common.assignmentGroupList,
        assignmentData: state.school.assignmentData
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSources,
    addCustomerSource,
    deleteCustomerSource,
    fetchPaymentTerms,
    addPaymentTerm,
    deletePaymentTerm,
    fetchIndustries,
    addIndustry,
    deleteIndustry,
    fetchBusinessTypes,
    addBusinessType,
    assignGrade,
    deleteBusinessType,
    getAllStudentForm,
    getSchoolCourse,
    getClass,
    fetchAssignmentGroup, addAssignmentGroup, deleteAssignmentGroup,
    getAssignmentData,
    pushModalToStack, popModalFromStack, showModal, hideModal,
    downloadFileFromBucket
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentResponsePreview);
