import React, { Fragment } from 'react';
import './../../../../assets/stylesheets/common/invoice.scss';
import CustomerHeaderAction from "./headerAction";
import CustomerInvoiceDetails from "./invoiceDetails";
import CustomerProductDetails from "./productDetails";
import { Divider } from 'antd';

const StudentInvoiceComponent = (props) => {
    // const breadCrumbList = [
    //     {
    //         name: <FormattedMessage id='common.sales' defaultMessage='' />,
    //     },
    //     {
    //         name: <FormattedMessage id='common.salesInvoice' defaultMessage='' />,
    //     }
    // ];

    return (
        <Fragment>
            <div className="txn-header">
                {/* <PageBreadcrumb breadCrumbList={breadCrumbList} /> */}
                <CustomerHeaderAction {...props} />
            </div>
<div className="txn-body">

            <div className="txn-details-container">
                <CustomerInvoiceDetails paymentTerms={props.paymentTerms} {...props}/>
            </div>
            <Divider style={{margin: '0 0 10px 0px'}}/>
            {<div className="product-details-container">
                <CustomerProductDetails {...props} />
            </div>}
</div>
        </Fragment>
    )
};

export default StudentInvoiceComponent;
