import { Col, DatePicker, Drawer, Row,Skeleton } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown"

const LeaveManagementDrawer = (props) => {
  const closeDrawer = () => {
    props.updateState({
        leaveManagementDrawer: false,
    });
  };

  return (
    <Drawer
    title={<FormattedMessage id="drawer.leaveMangement.titleName.leave"/>}
    width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.leaveManagementDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
                
            }
            
            }
            key={1}
          />
        </>
      }
    
    >
        <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

      <Row gutter={[16,16]}>
        <Col span={12}>
          <div className="i-label">Employee
          <span className="required">*</span>
          
          </div>
          <Dropdown
          placeholder="Select Employee"
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label"><FormattedMessage id="company.leaveMangement.leaveType"/>
          <span className="required">*</span>
          
          </div>
          <Dropdown
          placeholder={<FormattedMessage id="company.leaveMangement.leaveType"/>}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label">From Date
          <span className="required">*</span>
          
          </div>
          <DatePicker/>
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label">To Date
          <span className="required">*</span>
          
          </div>
         <DatePicker />
          
        </Col>
        <Col span={12}>
          <div className="i-label">Total Leaves
          <span className="required">*</span>
          
          </div>
          <TextBox
          disabled
          />
          
        </Col>
        <Col span={12}>
          <div className="i-label">Balance Leaves
          <span className="required">*</span>
          
          </div>
          <TextBox
          disabled
          />
          
        </Col>
        <Col span={12}>
          <div className="i-label">Leave Purpose
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Purpose of Leave"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
       
      </Row>
      </Skeleton>
    </Drawer>
  );
};

export default LeaveManagementDrawer;
