import React from "react";
import { Row, Col, Button } from 'antd';
import shipstationIcon from '../../../assets/images/shipstation-logo.png';
import { FormattedMessage } from "react-intl";
import ShipStationIntegrateComponent from './shipStationIntegrate';
import PageBreadcrumb from "../../PageBreadcrumb";

const ShipStationListingComp = (props) => {
    const breadCrumbList = [
       
        {
            name: <FormattedMessage id='sidebar.menuItem.shipping' defaultMessage='' />
           
        },  {
            name: <FormattedMessage id='shipStation.logo' defaultMessage='' />,
        }
    ];
    return (
        <div className="customer-details-container shadesship">
          <div className="pb10">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="customer-details">
                <div className="prod-ama">
                    <Row >
                        <Col span={2}></Col>
                        <Col span={15}>
                            <h3>The fastest & easiest way to get products to your customers </h3>
                            <div className="line-box"></div>
                            <h4>Organize and streamline your order fulfillment process</h4>
                            <ul>
                                <li><span><i class="fa fa-square" aria-hidden="true"></i></span>Compare rates from top carriers</li>
                                <li><span><i class="fa fa-square" aria-hidden="true"></i></span>
                                ShipStation instantly updates tracking information to the selling channel</li>
                                <li><span><i class="fa fa-square" aria-hidden="true"></i>
                                </span>Cut hours out of your day’s work by automating your shipping and order fulfillment workflow.</li>
                                <li><span><i class="fa fa-square" aria-hidden="true"></i>
                                </span>ShipStation gives you access to the world’s top carriers.</li>
                                <li><span><i class="fa fa-square" aria-hidden="true"></i>
                                </span>Discounted shipping rates from some of the world’s top carriers</li>

                                {/* <Button className="ant-btn cnant-btn ant-btn-primary prod-amab">Start Now</Button> */}

                            </ul>
                            <div className="prod-amab"><a href="https://help.shipstation.com/hc/en-us" target="_blank" rel="noopener noreferrer">A guide to using ShipStation</a></div>
                        </Col>
                        <Col span={6}> <div className="ant-card accounting-market-place text-center integsm ant-card-bordered ">
                            <div className="ant-card-head headr">
                                <div className="ant-card-head-wrapper">
                                    <div className="ant-card-extra">
                                        <img src={shipstationIcon} alt="" style={{ height: "50px" }} />
                                    </div>
                                </div>
                            </div>
                            {(props.linkedShipStation && props.linkedShipStation.alpideCarrierId) ? <div class="cr cr-top cr-left cr-green">Integrated</div> : ''}
                            <div className="ant-card-body">
                                <p> List Product to eBay</p>
                                <p> Create Customer Profiles</p>
                                <p> Create Subscriptions</p>
                                <p> Create Subscriptions</p>

                                <div className="border-footer mt10"></div>
                                {(props.linkedShipStation && props.linkedShipStation.alpideCarrierId) ? <Button className="cnant-btn grey-button" onClick={
                                    () => {
                                        props.disconnectConfiguration(props.linkedShipStation, props);
                                    }
                                }>Disconnect </Button>
                                    :
                                    <Button className="ant-btn cnant-btn ant-btn-primary" onClick={() => {
                                        props.pushModalToStack({
                                            modalBody: <ShipStationIntegrateComponent {...props} />,
                                            title: <FormattedMessage id='shipStation.logo' defaultMessage='' />,
                                            width: '30%',
                                            hideFooter: true,
                                        })
                                    }}>Start Shipping</Button>
                                }
                            </div>
                        </div></Col>
                        <Col span={1}></Col>
                    </Row>



                </div>
                <div className="clearfix"></div>
            </div>

        </div>
    )
};

export default ShipStationListingComp;
