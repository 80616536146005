import React from 'react';
import './general.scss';

export const ErrorMsg = (props) => {
  let className = 'text-red';
  if (props.validator && props.validator()) {
    className += ' hide';
  }
 
  // if (props.message) {
  // } else {
  //   className += ' hide';
  // }

  const errorMsg = props.message || 'This field is required';

  return (
    <span className={className}> {errorMsg}</span>
  )
}