import React, {useRef, useEffect,  useState} from 'react';
import { Link } from 'react-router-dom';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Button,  Row } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
//import * as filter from 'lodash.filter';
import { DropdownPriceList } from '../../../../general/MarkupDropdown';
import { Dropdown } from '../../../../general/Dropdown';
import { CONSTANTS, MODAL_TYPE } from '../../../../../static/constants';
import { AG_GRID_CONSTANTS } from '../../../../../static/agGridConstants';
import LoadingIcon from "../../../../../assets/images/loading.gif";
import MultiCurrencySalesInvoice from '../../../../../containers/customer/invoice/MultiCurrencySalesInvoice';
import StandardSalesInvoice from '../../../../../containers/customer/invoice/StandardSalesInvoice';

//import { addDaysToDate } from '../../../../../utils';

const CustomerHeaderAction = (props) => {
    const [templateDropdown, updateTemplateDropdown] = useState(false);
    const {
        intl,
        customers,
        pageNumber,
        customerName,
        updateState,
        priceListId,
        priceTypeToApply,
        priceList,
        showModal,
        companyInfo
    } = props;

    const handleNewPriceListAddition = (props, payload) => {
        const formData = payload.formData;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PRICE_LIST,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.PRICE_LIST: {
                deleteFunc = props.deletePriceList;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }
        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }

    const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();

        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });

        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);

            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep

        return innerRef; // return ref; client can omit `useRef`
    }

    const innerRef = useOuterClick(e => {
       updateTemplateDropdown(!templateDropdown)
    });

    return (<>
            <div className='left-itms'>
                <ul>
                    <li className='title'>
                        <FormattedMessage id='heading.sales.salesInvoice.create' />
                    </li>
                    <li className='sep' />
                    <li>
                        <FormattedMessage id='customer.salesInvoice.form.invoiceNumber.label' defaultMessage='' /> &nbsp; - &nbsp;
                        {props.nextTxNumber ?
                            <span>{props.nextTxNumber}</span>
                            //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
                            :
                            <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />
                        }
                    </li>
                </ul>
            </div>
            <div className="right-itms">
                {!props.isSoConversionToInvoice && <Checkbox onChange={(e) => {
                    props.updateState({
                        isCashSales: e.target.checked
                    });
                }} />}

                {!props.isSoConversionToInvoice &&
                    <span className='line-view'>
                        <FormattedMessage id='salesInvoice.cashSale.checkbox.text' defaultMessage='' />
                    </span>
                }
                {!props.isSoConversionToInvoice && <DropdownPriceList
                    items={priceList}
                    valueKeyName='priceListName'
                    optionKeyName='priceListId'
                    value={priceListId}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                        deleteClickHandler(props, MODAL_TYPE.PRICE_LIST, payload);
                    }}
                    onAddNewClick={(payload) => {
                        payload.formData = {
                            priceListName: payload.textEntered,
                            submittedOnce: false
                        }
                        handleNewPriceListAddition(props, payload, MODAL_TYPE.PRICE_LIST)
                    }}
                    placeholder={intl.formatMessage({
                        id: 'customer.salesInvoice.form.priceList.placeholder', defaultMessage: ''
                    })}
                    onSelect={
                        (priceListId) => {
                            const selectedPriceList = find(priceList, {priceListId:  priceListId})
                            updateState({
                                priceListName: selectedPriceList.priceListName,
                                priceListId: selectedPriceList.priceListId,
                                priceListPercentage: selectedPriceList.percentage,
                                priceListType: selectedPriceList.type
                            });
                        }
                    }
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            priceListName: undefined,
                            priceListId: undefined,
                            priceListPercentage: undefined,
                            priceListType: undefined,
                        })
                    }}
                />}

                {!props.isSoConversionToInvoice && <Dropdown
                    items={AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST}
                    value={priceTypeToApply || AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0]}
                    onSelect={(priceTypeToApply) => {
                        updateState({
                            priceTypeToApply,
                            isToPriceTypeToApply: true
                        })
                    }}
                    placeholder={intl.formatMessage({
                        id: 'customer.salesInvoice.form.priceType.placeholder', defaultMessage: ''
                    })}
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            priceTypeToApply: undefined,
                            isToPriceTypeToApply: false
                        })
                    }}
                />}

                { !props.isSoConversionToInvoice ?  <Dropdown
                    items={customers[pageNumber] || []}
                    valueKeyName='companyName'
                    optionKeyName='customerId'
                    value={customerName}
                    onSearch= {(searchedText)=>{
                        props.fetchCustomers({
                            isCompact: true,
                            searchedText: searchedText,
                            pageNumber: 1,
                            hideSpinner: true,
                            pageSize: 100,
                            relationshipId: (props.companyInfo || {}).relationshipId
                        })
                    }}
                    placeholder={intl.formatMessage({
                        id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                    })}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                        props.deleteCustomers({ groupCustomerIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                    }}
                    onAddNewClick={(payload) => {
                        props.updateHeaderState({
                            customer: {
                                customerDrawerVisible: true,
                                customerName: payload.textEntered,
                            }
                        })
                    }}
                    onSelect={
                        (customerId) => {
                            props.fetchCustomerDetailsByCustomerId({
                                customerId,
                                relationshipId: (props.companyInfo || {}).relationshipId
                            });
                            props.fetchAddresses({ customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.SHIPPING_ADDRESS });
                            props.fetchAddresses({ customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.BILLING_ADDRESS });
                        //  props.fetchAllUnfulfilledSalesOrderByCustomerId({ relationshipId: (props.companyInfo || {}).relationshipId, customerId: customerId });
                            props.fetchAllContacts({ customerId, relationshipId: (props.companyInfo || {}).relationshipId });
                        }
                    }
                    allowClear={true}
                    onClear={() => {
                        updateState({
                            customer: undefined,
                            customerName: undefined,
                            customerPaymentTerm: undefined,
                            customerShippingAddress: undefined,
                            customerBillingAddress: undefined,
                            placeOfSupply: undefined,
                            invoiceDueDate: undefined,
                            selectedContact: undefined,
                        })
                    }}
                /> : null}
                <Button  onClick={() => {
                    props.updateState({
                        ledgerDrawerVisible: true
                    })
                }} >
                    <i className='fa fa-book fa-lg'> </i>
                </Button>
                <div className="currency-converter-modal">
                    <Button onClick={() => updateTemplateDropdown(!templateDropdown)}>
                        <i className="fa fa-exchange" />
                    </Button>

                    {templateDropdown &&
                        <div ref={innerRef} className="content">
                            <Row className="mb10">
                                <Link onClick={()=>{
                                    props.popModalFromStack();
                                    props.pushModalToStack({
                                        modalBody: <StandardSalesInvoice {...props} />,
                                        width: '100%',
                                        hideTitle: true,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                    updateTemplateDropdown(false)
                                }}
                                // to="/admin/sales-invoice"
                                >
                                    <FormattedMessage id='template.standard.invoice' defaultMessage='' />
                                </Link>
                            </Row>

                            <Row>
                                <Link onClick={()=>{
                                    props.popModalFromStack();
                                    props.pushModalToStack({
                                        modalBody: <MultiCurrencySalesInvoice {...props} />,
                                        width: '100%',
                                        hideTitle: true,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                    updateTemplateDropdown(false)
                                }}
                                // to="/admin/sales-invoice-multi-currency"
                                >
                                    <FormattedMessage id='template.standard.invoice.multicurrency' defaultMessage='' />
                                    </Link>
                            </Row>
                        </div>
                    }
                </div>
                {/* <div className="space-lr">
                    <Button icon={<SwapOutlined />} />
                </div> */}
                
            </div>
    </>
    );
};

export default injectIntl(CustomerHeaderAction);
