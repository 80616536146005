import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, INVENTORY_ACTION_LIST } from '../../../../static/constants';
import { fetchProducts, getProductCount, resetPaginatedData } from '../../../product/Listing/action';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const importProducts = (payload, props) => {
  var formData = new FormData();
  formData.append("file", payload.fileToUpload);
  formData.append("countryName", payload.countryName);
  const startTime = new Date();
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}${config.rootContext}/inventoryExcel/importInventoryFromExcel?relationshipId=${payload.relationshipId}`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: lang[(res.data || {}).message] || "Please reselect the upload file." });
        let data = {
          relationshipId: payload.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
        }

        resetPaginatedData()(dispatch);
        fetchProducts(data)(dispatch);
        getProductCount(data)(dispatch);
        props.popModalFromStack();
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({ messageType: "error", description: lang[((err.response || {}).data || {}).message] || "Please reselect the upload file." });
        } else {
          const errorTime = new Date();
          var dif = startTime.getTime() - errorTime.getTime();

          var Seconds_from_T1_to_T2 = dif / 1000;
          var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);
          
          if (Seconds_Between_Dates > 55){
            showToasterMessage({ messageType: "success", description: lang["productUpload.inprogress.messsage"] || "Product upload in progress you will be notified once the upload is done" });
            
          }else{
            showToasterMessage({ messageType: "error", description: "Please reselect the upload file." });
          }
            
          props.popModalFromStack();
        }

        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}
export const fetchTaxjarCategory = () => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return (
      axios
        .get(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/taxjar/getCategories`
        )
        .then((res) => {
          dispatch({
            type: INVENTORY_ACTION_LIST.TAXJAR_CATEGORY_LIST,
            data: res.data?.categories || [],
          });
        })
        .catch((err) => {
        })
    );
  };
};