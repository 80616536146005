import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, DatePicker, Tooltip } from 'antd';
import { getCurrentDateForBackend, getMomentDateForUI } from './../../../../utils'
import { DropdownRef } from './../../../general/Dropdown';
import { CONSTANTS, MODAL_TYPE } from '../../../../static/constants';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { CustomButton } from '../../../general/CustomButton';
import { hideModal } from '../../../../actions/commonActions';

const { TextArea } = Input;

const addItemConfirmationModal = (props, text, modalType) => {
  const { showModal, companyInfo } = props;
  const labelName = getModalTitle("", modalType);
  const modalBody = <span>
    Are you sure you want to submit ?
    {/* <FormattedMessage
      id='addItem.confirmation'
      values={{ value: `${text} to ` }}
      defaultMessage={` `}
    /> */}

  </span>;

  let addFunc;

  switch (modalType) {
    case MODAL_TYPE.ACTIVITY_STATUS: {
      addFunc = props.addActivityStatus;
      break;
    }
    case MODAL_TYPE.ACTIVITY_PRIORITY: {
      addFunc = props.addActivityPriorities;
      break;
    }
    case MODAL_TYPE.ACTIVITY_TYPE: {
      addFunc = props.addActivityTypes;
      break;
    }
    default: {
      addFunc = () => { }
    }
  }

  const modalData = {
    modalBody,
    handleSubmit: () => {
      addFunc({ text: text, relationshipId: companyInfo.relationshipId })
      props.hideModal();
    },
  };
  showModal(modalData);
};

const getModalTitle = (newTextObj, modalType) => {
  let title;
  switch (modalType) {
    case MODAL_TYPE.ACTIVITY_STATUS: {
      title = "Task Status";
      break;
    }
    case MODAL_TYPE.ACTIVITY_PRIORITY: {
      title = <FormattedMessage id='addItem.text.activityPriority' defaultMessage='' />;
      break;
    }
    case MODAL_TYPE.ACTIVITY_TYPE: {
      title = "Task Type ";
      break;
    }
    default:
      title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
  }
  return title;
};

const handleNewItemAddition = (props, payload, modalType) => {
  const formData = payload.formData;
  const { showModal } = props;

  if (formData?.textBoxValue && formData?.textBoxValue.length && !payload.existsInData) {
    addItemConfirmationModal(props, formData?.textBoxValue, modalType);
  } else {
    const data = {
      title: getModalTitle(payload, modalType),
      formData,
      modalData: { modalType, data: payload },
      handleSubmit: (formData = {}) => {
        if (formData?.textBoxValue && formData?.textBoxValue.length) {
          addItemConfirmationModal(props, formData?.textBoxValue, modalType);
        } else {
          data.formData.submittedOnce = true;
          // showModal(data);
        }
      }
    };
    props.pushModalToStack(data);
  }
};

const deleteClickHandler = (props, modalType, payload) => {
  const { showModal, companyInfo } = props;
  const modalBody =
    <FormattedMessage
      id='deleteItem.confirmation'
      defaultMessage={``}
      values={{ value: payload.text }}
    />;

  let deleteFunc;
  switch (modalType) {
    case MODAL_TYPE.ACTIVITY_STATUS: {
      deleteFunc = props.deleteActivityStatus;
      break;
    }
    case MODAL_TYPE.ACTIVITY_PRIORITY: {
      deleteFunc = props.deleteActivityPriorities;
      break;
    }
    case MODAL_TYPE.ACTIVITY_TYPE: {
      deleteFunc = props.deleteActivityTypes;
      break;
    }
    default: {
      deleteFunc = () => { }
    }
  }

  const modalData = {
    modalBody,
    handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
  };
  showModal(modalData);
};

class LogActivityComponent extends Component {
  render() {
    const { formData } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          {/* <div className="modal-content employee-modal-control form-item-customize" > */}
          <div className="modal-content" >
            <div className="pr20">
              {/* <Form.Item label='Activity Name' colon={false}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Activity name is required.',
                      initialValue: this.props.name || ''
                    }
                  ],
                })(<Input placeholder='Activity name' />)}
              </Form.Item> */}
              <Form.Item label={<FormattedMessage id='supplier.listing.logActivity.activityType.label' defaultMessage='' />} className='hide' colon={false} >
                {getFieldDecorator('typeName', {
                  initialValue: formData?.activityTypeName || '',
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='activity.validation.activityType' defaultMessage='' />,
                    }
                  ],
                })
                  (<span />)}
              </Form.Item>
              <Form.Item label={"Task Type"} colon={false}>
                {getFieldDecorator('typeId', {
                  initialValue: formData?.activityTypeName || '',
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='activity.validation.activityType' defaultMessage='' />,
                    }
                  ],
                })
                  (<DropdownRef
                    items={this.props.activityTypes || []}
                    optionKeyName='activityTypeId'
                    valueKeyName='activityTypeName'
                    onSelect={(selectedValue, option) => {
                      this.props.form.setFieldsValue({ 'typeId': selectedValue });
                      this.props.form.setFieldsValue({ 'typeName': option.props.name });
                    }}
                    placeholder={this.props.intl.formatMessage({
                      id: 'common.select.placeholder', defaultMessage: ''
                    })}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                      deleteClickHandler(this.props, MODAL_TYPE.ACTIVITY_TYPE, payload);
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        textBoxValue: payload.textEntered,
                        submittedOnce: false
                      }
                      handleNewItemAddition(this.props, payload, MODAL_TYPE.ACTIVITY_TYPE)
                    }}
                  />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id='supplier.listing.logActivity.description.label' defaultMessage='' />} colon={false}>
                {getFieldDecorator('description', {
                  initialValue: formData?.description || '',
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id='activity.validation.description' defaultMessage='' />,
                    }
                  ],
                })
                  (<TextArea placeholder={this.props.intl.formatMessage({ id: 'supplier.listing.logActivity.description.placeholder', defaultMessage: '' })} />)}
              </Form.Item>
              <Form.Item label={(
                <span>

                  <FormattedMessage id='supplier.listing.logActivity.refNumber.label' defaultMessage='' />
                  <Tooltip placement="right" title={this.props.intl.formatMessage(
                    {
                      id: 'supplier.listing.logActivity.refNumber.tooltip',
                      defaultMessage: ''
                    }
                  )} trigger="click">
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </span>
                // <Tooltip >
                //   <span className="tooltip-title"><FormattedMessage id='supplier.listing.logActivity.refNumber.label' defaultMessage='' /></span>
                // </Tooltip>
              )} colon={false}>
                {getFieldDecorator('refNumber', {
                  initialValue: formData?.refNumber || ''
                })(<Input placeholder={this.props.intl.formatMessage({ id: 'supplier.listing.logActivity.refNumber.placeholder', defaultMessage: '' })} />)}
              </Form.Item>

              <Form.Item label={<FormattedMessage id='common.status' defaultMessage='' />} className='hide' colon={false}>
                {getFieldDecorator('statusName', {
                  initialValue: formData?.activityStatusName || ''
                })
                  (<span />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id='common.status' defaultMessage='' />} colon={false}>
                {getFieldDecorator('statusId', {
                  initialValue: formData?.activityStatusName || ''
                })
                  (<DropdownRef
                    items={this.props.activityStatus || []}
                    optionKeyName='activityStatusId'
                    valueKeyName='activityStatusName'
                    onSelect={(selectedValue, option) => {
                      this.props.form.setFieldsValue({ 'statusId': selectedValue });
                      this.props.form.setFieldsValue({ 'statusName': option.props.name });
                    }}
                    placeholder={this.props.intl.formatMessage({
                      id: 'common.select.placeholder', defaultMessage: ''
                    })}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                      deleteClickHandler(this.props, MODAL_TYPE.ACTIVITY_STATUS, payload);
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        textBoxValue: payload.textEntered,
                        submittedOnce: false
                      }
                      handleNewItemAddition(this.props, payload, MODAL_TYPE.ACTIVITY_STATUS)
                    }}
                  />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id='supplier.listing.logActivity.priority.label' defaultMessage='' />} className='hide' colon={false} >
                {getFieldDecorator('priorityName', {
                  initialValue: formData?.activityPriorityName || ''
                })
                  (<span />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id='supplier.listing.logActivity.priority.label' defaultMessage='' />} colon={false}>
                {getFieldDecorator('priorityId', {
                  initialValue: formData?.activityPriorityName || ''
                })
                  (<DropdownRef
                    items={this.props.activityPriorities || []}
                    optionKeyName='activityPriorityId'
                    valueKeyName='activityPriorityName'
                    placeholder={this.props.intl.formatMessage({
                      id: 'common.select.placeholder', defaultMessage: ''
                    })}
                    onSelect={(selectedValue, option) => {
                      this.props.form.setFieldsValue({ 'priorityId': selectedValue });
                      this.props.form.setFieldsValue({ 'priorityName': option.props.name });
                    }}
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                      deleteClickHandler(this.props, MODAL_TYPE.ACTIVITY_PRIORITY, payload);
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        textBoxValue: payload.textEntered,
                        submittedOnce: false
                      }
                      handleNewItemAddition(this.props, payload, MODAL_TYPE.ACTIVITY_PRIORITY)
                    }}
                  />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id='supplier.listing.logActivity.assignTo.label' defaultMessage='' />} className='hide' colon={false} >
                {getFieldDecorator('assignedUserName', {
                  initialValue: formData?.assignedUserName || ''
                })
                  (<span />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id='supplier.listing.logActivity.activityDueDate.label' defaultMessage='' />} colon={false}>
                {getFieldDecorator('dueDate', {
                  initialValue: formData?.activityDueDate ? getMomentDateForUI({date:new Date(formData?.activityDueDate)}) : getMomentDateForUI({date:new Date()})
                })(<DatePicker />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id='supplier.listing.logActivity.ActivityClosedDate.label' defaultMessage='' />} colon={false}>
                {getFieldDecorator('activityClosedDate', {
                  initialValue: formData?.activityClosedDate ? getMomentDateForUI({date:new Date(formData?.activityClosedDate)}) : getMomentDateForUI({date:new Date()})
                })(<DatePicker />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id='supplier.listing.logActivity.assignTo.label' defaultMessage='' />} colon={false}>
                {getFieldDecorator('assignedUserId', {
                  initialValue: formData?.assignedUserId || ''
                })
                  (<DropdownRef
                    items={this.props.allEmployee || []}
                    optionKeyName='relationshipEmployeeId'
                    valueKeyName='fullName'
                    placeholder={this.props.intl.formatMessage({
                      id: 'common.select.placeholder', defaultMessage: ''
                    })}
                    onSelect={(selectedValue, option) => {
                      this.props.form.setFieldsValue({ 'assignedUserId': selectedValue });
                      this.props.form.setFieldsValue({ 'assignedUserName': option.name });
                    }}
                  />)}
              </Form.Item>
            </div>
          </div>

          <Form.Item {...tailFormItemLayout} >
            <div className="modal-footer" style={{display:"flex",justifyContent:"space-between"}}>

          
              <Button
                type="default"
                onClick={
                  this.props.supplierTaskDrawerVisible
                    ? () => {
                      this.props.updateParentState({
                        supplierTaskDrawerVisible: false,
                      });
                    }
                    : () => {
                      // this.props.popModalFromStack();
                      this.props.showModal({
                        modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                        footer:{submit:{intlId:"button.close.label"}},
                        handleSubmit: () => {
                          this.props.popModalFromStack();
                        }
                      })
                    }
                }
  
>
    <FormattedMessage id='button.cancel.label' defaultMessage='' />
</Button>


            <Button type="primary" htmlType="submit">
              {/* {formData.activityTypeName ? 'Edit Task' : 'Create Task'} */}
              <FormattedMessage id='button.save.label' defaultMessage='' />

            </Button>
            </div>
          </Form.Item>


          {/* <Form.Item {...tailFormItemLayout} className="modal-footer address-submit-btn" style={{marginBottom:"10px"}}>
            
             <div key={0} className="customModal-button-group">
            <CustomButton
                intlId={"modal.contact.button.cancel"}
                defaultMessage={""}
                type={"default"}
                key={1}
                onClick={() => {
                  // this.props.popModalFromStack();
                  this.props.updateParentState({
                    supplierTaskDrawerVisible: false,
                  });
                }}
              />
          
                   <CustomButton
                intlId={"button.save.label"}
                defaultMessage={""}
                htmlType="submit"
                key={2}
              >{formData?.activityTypeName ? 'Edit Task' : 'Create Task'}</CustomButton>
              </div>
          </Form.Item> */}
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    this.props.updateState({
      submittedOnce: true,
    });
    e.preventDefault();
    const formData = this.props.formData;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          activityDetails: values.name,
          description: values.description,
          refNumber: values.refNumber,
          activityTypeId: values.typeId,
          activityTypeName: values.typeName,
          activityStatusId: values.statusId,
          activityStatusName: values.statusName,
          activityPriorityId: values.priorityId,
          activityPriorityName: values.priorityName,
          assignedUserId: values.assignedUserId,
          assignedUserName: values.assignedUserName,
          activityDueDate: values.dueDate && values.dueDate.format(CONSTANTS.BACKEND_FORMAT),
          activityClosedDate: values.activityClosedDate && values.activityClosedDate.format(CONSTANTS.BACKEND_FORMAT),
          dateCreated: getCurrentDateForBackend(new Date()),
          userCreated: this.props.userInfo.firstName,
          supplierName: this.props.supplierName,
          supplierId: this.props.supplierId,
          activityId: (formData || {}).activityId || 0,
          version: (formData || {}).version || 0,
        }
        // if (payload.activityStatusName === 'Completed') {
        //   payload.activityClosedDate = getCurrentDateForBackend(new Date());
        // } else {
        //   payload.activityClosedDate = "";
        // }
        // const modalData = {
        //   modalBody: formData? Object.keys(formData).length ? <FormattedMessage id='activity.updateConfirmation' defaultMessage='' />: <FormattedMessage id='activity.confirmation' defaultMessage='' />: <FormattedMessage id='activity.confirmation' defaultMessage='' />,
        //   handleSubmit: () => {
        //     this.props.createUpdateActivity(payload);
        //     this.props.updateHeaderState({
        //       supplierTaskDrawerVisible: false,
        //     });

        //   }
        // };
        // this.props.pushModalToStack(modalData);
        const modalData = {
          modalBody: formData ? Object.keys(formData).length ? <FormattedMessage id='activity.updateConfirmation' defaultMessage='' /> : <FormattedMessage id='activity.confirmation' defaultMessage='' /> : <FormattedMessage id='activity.confirmation' defaultMessage='' />,
          // handleSubmit: () => {         
          //   this.props.createUpdateActivity(payload);
          //   this.props.updateParentState({
          //     supplierTaskDrawerVisible: false,
          //   })
          // }
          handleSubmit: () => {
            if (formData?.activityTypeName) {
                this.props.createUpdateActivity(payload);
                this.props.popModalFromStack();
            } else {
                this.props.createUpdateActivity(payload);
                this.props.updateParentState({
                    supplierTaskDrawerVisible: false,
                });
            }
        }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}
// if (formData) {
//   this.props.updateState({
//     supplierTaskDrawerVisible: false,
//   });
// } else {

//   this.props.updateState({
//     supplierTaskDrawerVisible: false
//   })
// }


export default Form.create({ name: 'Log_activity' })(injectIntl(LogActivityComponent));