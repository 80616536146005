import React from "react";
import { CONSTANTS } from '../../../static/constants';
import { FormattedMessage } from 'react-intl';
//import { AG_GRID_CLASS_CONSTANTS } from '../../../static/cssClassConstants';
//import SalesInvoiceDetail from '../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from '../../../utils';
import { Empty, Pagination } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import ShipmentDetail from '../../../containers/modal/modalBody/customer/ShipmentDetail';
    

const SalesTransactions = (props) => {
    const { companyInfo, updateState, pageNumber, pageSize, salesTransactions, salesTransactionsCount } = props;
    const agGridStyle = {
        height: '100%',
        width: '100%'
    };
    
    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, itemId: props.itemId};
        fetchPaginationDataIfNeeded('fetchSalesTransactions', 'salesTransactions', props, payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, itemId: props.itemId};
        props.resetSalesPaginatedData();
        props.fetchSalesTransactions(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }
    
    // const getRowHeight = (params) => {
    //     // let height = 28;
    //     // let calcHeight = 1
    //     // if (params.data.sku) {
    //     //     calcHeight = 2;
    //     // }
    //     return 28;
    // };

    const openDeliveryNotesDetails = (data) => {
        const shipmentPayload = {
            customerId: data.customerId,
            shipmentMasterId: data.shipmentMasterId
        }
        props.pushModalToStack({
            modalBody: <ShipmentDetail {...props} shipmentData={data} shipmentPayload={shipmentPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail',
            handleSubmit: () => {
            }
        });
    }
    
    // const openSalesInvoiceDetails = (data) => {
    //     const invoicePayload = {
    //         customerId: data.customerId,
    //         invoiceMasterId: data.invoiceMasterId
    //     }
    //     props.pushModalToStack({
    //         modalBody: <SalesInvoiceDetail {...props} invoicePayload={invoicePayload} />,
    //         width: '100%',
    //         hideFooter: true,
    //         wrapClassName: 'modal-custom-detail'
    //     })
    // }

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='product.detail.tab.outboundInventory.productName' defaultMessage='' />;
            },
            field: "itemName",
            resizable: true,
            rowDrag: true,
        //     cellRendererFramework: (link) => <div>
        //     <div>{link.data.itemName}</div>
        //     {link.data.sku ? ("SKU : " + link.data.sku) : ''}
        // </div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='product.detail.tab.outboundInventory.variant' defaultMessage='' />;
            },
            resizable: true,
            
            cellRendererFramework: (link) => <div>
                {link.data.attributeValue1 && link.data.attributeValue1} 
                {link.data.attributeValue2 && ( link.data.attributeValue1 ? " | " : '') + (link.data.attributeValue2)} 
                {link.data.attributeValue3 && ( link.data.attributeValue1 || link.data.attributeValue ? " | " : '') + (link.data.attributeValue3)} 
            </div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='product.detail.tab.outboundInventory.sku' defaultMessage='' />;
            },
            field: "sku",
            resizable: true,
            
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='product.detail.tab.outboundInventory.customer' defaultMessage='' />;
            },
            field: "customerName",
            resizable: true,
            
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='modal.txDetails.shipment.shipmentNumber' defaultMessage='' />;
            },
            resizable: true,
            
            cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                openDeliveryNotesDetails(link.data)
            }}>{link.data.shipmentNumber}</div>,
        },
        // {
        //     headerComponentFramework: () => {
        //         return <FormattedMessage id='product.detail.tab.outboundInventory.quantity' defaultMessage='' />;
        //     },
        //     field: "quantityToInvoice",
        //     resizable: true,
        //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        // },
        // {
        //     headerComponentFramework: () => {
        //         return <div><FormattedMessage id='product.detail.tab.outboundInventory.price' defaultMessage='' />   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</div>;
        //     },
        //     resizable: true,
        //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        //     cellRendererFramework: (link) => <div>
        //          {(link.data.anItemSalePrice || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        //     </div>,
        // },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='product.detail.tab.outboundInventory.txDate' defaultMessage='' />;
            },
            resizable: true,
            
            cellRendererFramework: (link) => <div>
            {(link.data.shipmentDate && getMomentDateForUIReadOnly({date: link.data.shipmentDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
        </div>,
        },
    ]
    return (
            // <div className='agGridWrapper mr15'>
            //     <div className="ag-theme-balham" style={agGridStyle} >
            //         <AgGridReact
            //             columnDefs={columnDefs}
            //             rowData={props.salesTransactions[props.pageNumber] || []}
            //             rowDragManaged={true}
            //             domLayout={"autoHeight"}
            //             animateRows={true}
            //             onGridSizeChanged={onFirstDataRendered}
            //             onColumnResized ={(params)=>{
            //                 params.api.resetRowHeights();
            //             }}
            //             defaultColDef={{
            //                 flex:1,
            //                 autoHeight: true,
            //                 wrapText: true,
            //             }}
            //             //getRowHeight={getRowHeight}
            //         >
            //         </AgGridReact>
            
            //     </div>
            // </div>
            <>
                <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">
                            Outbound Inventory List
                        </div>
                        {salesTransactionsCount ? (
                            <>
                                <div className="vertical-sep" />
                                <div>{salesTransactionsCount}</div>
                            </>
                        ):''}
                    </div>
                    <div className="right-actions">
                    </div>
                </div>
                <div className="table-container" style={{height: props.accordianOpen ? 'calc(100vh - 448px)' : 'calc(100vh - 321px)'}}>
                    <table>
                        <thead>
                            <tr>
                                <th width='6%'><FormattedMessage id='serial.no' defaultMessage='' /></th>
                                <th><FormattedMessage id='product.detail.tab.outboundInventory.productName' defaultMessage='' /></th>
                                <th><FormattedMessage id='product.detail.tab.outboundInventory.variant' defaultMessage='' /></th>
                                <th><FormattedMessage id='product.detail.tab.outboundInventory.sku' defaultMessage='' /></th>
                                <th><FormattedMessage id='product.detail.tab.outboundInventory.customer' defaultMessage='' /></th>
                                <th><FormattedMessage id='modal.txDetails.shipment.shipmentNumber' defaultMessage='' /></th>
                                <th><FormattedMessage id='product.detail.tab.outboundInventory.txDate' defaultMessage='' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(salesTransactions[pageNumber || 1] || []).length ? (salesTransactions[pageNumber || 1] || []).map((rowData, i)=>{

                                return <tr key={`st`+i}>
                                    <td>
                                        { (pageNumber-1)*pageSize + i+1 }
                                    </td>
                                    <td>
                                        {rowData.itemName || '-'}
                                    </td>
                                    <td>
                                        {rowData.attributeValue1 && rowData.attributeValue1} 
                                        {rowData.attributeValue2 && ( rowData.attributeValue1 ? " | " : '') + (rowData.attributeValue2)} 
                                        {rowData.attributeValue3 && ( rowData.attributeValue1 || rowData.attributeValue ? " | " : '') + (rowData.attributeValue3)} 
                                    </td>
                                    <td>
                                        {rowData.sku}
                                    </td>
                                    <td>
                                        {rowData.customerName}
                                    </td>
                                    <td>
                                        <div className="cursor-pointer" onClick={() => {
                                            openDeliveryNotesDetails(rowData)
                                        }}>
                                            {rowData.shipmentNumber}
                                        </div>
                                    </td>
                                    <td>
                                        {(rowData.shipmentDate ? getMomentDateForUIReadOnly({date: rowData.shipmentDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}) : '-' )}
                                    </td>
                                    <td></td>
                                </tr>
                            }) 
                                :
                                <tr>
                                    <td colSpan={'100%'}>
                                        <div className="text-center"><Empty /></div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={props.salesTransactionsCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(1, pageSize, props)
                            }}
                        />
                    </div>
                </div>
            </>
    )
};

export default SalesTransactions;