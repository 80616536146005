export const en = {
    'update': 'Update',
    'clone': 'Clone',
    'delete': 'Delete',
    
    'product.listing.buttonMenu.newProducts': 'New Products',
    'product.listing.validation.failed.label': 'Validation Failed',
    'product.listing.validation.failed.message.delete': 'No product selected. Please select product(s) before deleting.',
    'product.listing.validation.failed.message.download': 'No item selected. Please select item before downloading.',
    'product.listing.header.productDetails': 'Product Details',
    'product.listing.header.variant': 'Variant',
    'product.listing.header.sku': 'SKU',
    'product.listing.header.category': 'Category',
    'product.listing.header.hsnCode': 'HSN Code',
    'product.listing.header.currentStock': 'Current Stock',
    'product.listing.header.warehouse': 'Warehouse',
    'product.listing.header.valuation': 'Valuation',
    
    'product.detail.panel.header.label': 'Product Information',
    'product.detail.panel.productName.label': 'Product Name',
    'product.detail.panel.productCategory.label': 'Product Category',
    'product.detail.panel.firstProcurement.label': 'First Procurement',
    'product.detail.panel.manufacturer.label': 'Manufacturer',
    'product.detail.panel.brand.label': 'Brand',
    'product.detail.panel.articleNo.label': 'Article No',
    
    'product.detail.tab.header.outboundInventory': 'Outbound Inventory',
    'product.detail.tab.header.inboundInventory': 'Inbound Inventory',
    'product.detail.tab.inboundInventory.productName': 'Product Name',
    'product.detail.tab.inboundInventory.variant': 'Variant',
    'product.detail.tab.inboundInventory.sku': 'SKU',
    'product.detail.tab.inboundInventory.supplier': 'Supplier',
    'product.detail.tab.inboundInventory.invoiceNumber': 'Invoice #',
    'product.detail.tab.inboundInventory.quantity': 'Quantity',
    'product.detail.tab.inboundInventory.price': 'Price',
    'product.detail.tab.inboundInventory.txDate': 'Tx Date',
    
    'product.detail.tab.outboundInventory.productName': 'Product Name',
    'product.detail.tab.outboundInventory.variant': 'Variant',
    'product.detail.tab.outboundInventory.sku': 'SKU',
    'product.detail.tab.outboundInventory.customer': 'Customer',
    'product.detail.tab.outboundInventory.invoiceNumber': 'Invoice #',
    'product.detail.tab.outboundInventory.quantity': 'Quantity',
    'product.detail.tab.outboundInventory.price': 'Price',
    'product.detail.tab.outboundInventory.txDate': 'Tx Date',
   
    'setting.accountInformation.label': 'Account Information',
    'setting.companyInfo.label': 'Company Info',
    'setting.companyInformation.label': 'Company Information',
    'setting.companyInformation.message': 'Update your company profile, logo, address etc',
    'setting.changePassword.label': 'Change Password',
    'setting.changePassword.message': "We strongly recommend to periodically change your password. If your password were not changed on a regular basis, your familiarity with your password would eventually lead to it's compromise",
    'setting.administration.label': 'Administration',
    'setting.userManagement.label': 'User Management',
    'setting.passwordManagement.label': 'Password Management',
    'setting.documentSequence.label': 'Document Sequence',
    'setting.qualityCheck.label': 'Quality Check',
    'setting.qualityCheckSetting.label': 'Quality Check Setting',
    'setting.userManagement.message': 'You are powered with creating multiple users for your business. You can have granular control what operations user can perform or what data users can see.',
    'setting.documentSequence.message': 'You can override default system generated numbers for your Purchase Order, Sales Order, Invoice etc.',
    'setting.qualityCheck.message': 'You can enable quality check setting.',
    'setting.customizations.label': 'Customizations',
    'setting.emailTemplates.label': 'Email Templates',
    'setting.emailTemplates.message': 'Not satisfied with your current plan. No worries, you can select the plan that best suites your business needs. You can setup billing frequency (Monthly, Quarterly, or Annually) for the subscription.',
    'setting.remarks.label': 'Remarks',
    'setting.remarks.message': 'Remarks that is printed on RFQ, Quotation, Invoice, Sales & Purchase Order and on other transactions.',
    'setting.documentName.label': 'Document Name',
    'setting.documentName.message': 'You can override default system generated document name for your Purchase Order, Sales Order, Invoice etc.',
    'setting.documentApproval.label': 'Document Approval',
    'setting.documentApproval.message': 'You can override default settings for document approval.',
    'setting.enableTestData.message': 'Enable Test Data',
    
    'setting.userManagement.updatePermissions.label': 'Update Permissions',
    'setting.userManagement.revokeAccess.label': 'Revoke Access',
    'setting.userManagement.listing.title': 'Title',
    'setting.userManagement.listing.firstName': 'First Name',
    'setting.userManagement.listing.middleName': 'Middle Name',
    'setting.userManagement.listing.lastName': 'Last Name',
    'setting.userManagement.listing.callName': 'Call Name',
    'setting.userManagement.listing.phone': 'Phone',
    'setting.userManagement.listing.mobile': 'Mobile',
    'setting.userManagement.listing.status': 'Status',
    'setting.userManagement.listing.email': 'Email',
    'setting.userManagement.listing.createdDate': 'Created Date',
    'setting.userManagement.addUser.label': 'Add User',
    'setting.userManagement.addEmployee.label': 'Add New Employee',
   
    'sidebar.salesPayment.popup.title': 'Sales Payment',
    'sidebar.purchasePayment.popup.title': 'Purchase Payment',
    'sidebar.newChartOfAccount.popup.title': 'New Chart of Account',
    
    'reports.title.agingOfAccountReceivable':'Aging of Account Receivable',
    'reports.title.agingOfAccountPayable':'Aging of Account Payable',
    'reports.title.customerPartyLedger':'Customer Party Ledger',
    
    'reports.title.customer':'Customer',
    'reports.customerByPayment.label':'Customer by Payment',
    'reports.customerByPayment.message':'This report lists all customers by payment',
    'reports.agedDebtors.label':'Aged Debtors',
    'reports.agedDebtors.message':'This report lists all customers by the due amount',
    'reports.partyLedger.label':'Party Ledger',
    'reports.partyLedger.message':'Show Party Ledger Report',
    
    'reports.title.supplier':'Supplier',
    'reports.supplierByPayment.label':'Supplier by Payment',
    'reports.supplierByPayment.message':'This report lists all suppliers by payment',
    'reports.agedCreditors.label':'Aged Creditors',
    'reports.agedCreditors.message':'This report lists all suppliers by the due amount',
    
    'reports.title.salesReports.label':'Sales Reports',
    'reports.salesByCustomer.label':'Sales by Customer',
    'reports.salesByCustomer.message':'This report lists customer sales activities',
    'reports.salesByProduct.label':'Sales by Product',
    'reports.salesByProduct.message':'This report lists all sales by the product',
    'reports.salesOrderByFulfillment.label':'Sales Order by Fulfillment',
    'reports.salesOrderByFulfillment.message':'This report lists all orders by their fulfillment status',
    
    'reports.title.purchaseReports.label':'Purchase Reports',
    'reports.purchaseBySupplier.label':'Purchase by Supplier',
    'reports.purchaseBySupplier.message':'This report lists supplier purchase activities',
    'reports.purchaseByFulfillment.label':'Purchase by Fulfillment',
    'reports.purchaseByFulfillment.message':'This report lists all purchases by their fulfillment status',
    
    'reports.title.inventoryReports.label':'Inventory Reports',
    'reports.agedInventory.label':'Aged Inventory',
    'reports.agedInventory.message':'The stock ageing report lists all inventory items that are in the stock based on their incoming date',
    'reports.inventoryValuation.label':'Inventory Valuation',
    'reports.inventoryValuation.message':'The Inventory Valuation Summary report includes the total cost of stock across warehouses',
    'reports.lowStockReport.label':'Low Stock Report',
    'reports.lowStockReport.message':'Low Stock Report lists all items across warehouses which are low on inventory based on minimum stock levels defined against each item',
    'reports.fastestSellingProduct.label':'Fastest Selling Product',
    'reports.fastestSellingProduct.message':'This report lists with all items that are selling fast. This report helps to plan stock purchase in advance',
    'reports.outOfStockReport.label':'Out of Stock Report',
    'reports.outOfStockReport.message':'Out of Stock report lists all items which are not available in stock across warehouses',

    'supplier.listing.actionMenu.title.newActivity': 'New Activity',
    'supplier.listing.actionMenu.newActivity': 'Log Activity',
    'supplier.listing.actionMenu.title.newCommunication': 'New Communication',
    'supplier.listing.actionMenu.newCommunication': 'Log Communication',
    'supplier.listing.actionMenu.downloadLedgerStatement': 'Download Ledger Statement',
    'supplier.listing.actionMenu.title.contact': 'Contact',
    'supplier.listing.actionMenu.contact': 'Show Contact',
    'supplier.listing.actionMenu.title.address': 'Address',
    'supplier.listing.actionMenu.address': 'Show Address',
    'supplier.listing.actionMenu.createPurchaseOrder': 'Create Purchase Order',
    'supplier.listing.actionMenu.bookPurchase': 'Book Purchase',
    'supplier.listing.actionMenu.createDebitMemo': 'Create Debit Memo',
    'supplier.listing.actionMenu.makePayment': 'Make Payment',
    'supplier.listing.actionMenu.makePayment.title': 'Purchase Payment',
    'supplier.listing.actionMenu.editSupplier': 'Edit Supplier',
    'supplier.listing.actionMenu.deleteSupplier': 'Delete Supplier',
    'supplier.listing.validation.failed.label': 'Validation Failed',
    'supplier.listing.validation.failed.message': 'No supplier selected. Please select supplier(s) before deleting',

    'supplier.listing.showContact.gridHeader.primaryContact': 'Primary Contact',
    'supplier.listing.showContact.gridHeader.title': 'Title',
    'supplier.listing.showContact.gridHeader.firstName': 'First Name',
    'supplier.listing.showContact.gridHeader.lastName': 'Last Name',
    'supplier.listing.showContact.gridHeader.callName': 'Call Name',
    'supplier.listing.showContact.gridHeader.workPhone': 'Work Phone',
    'supplier.listing.showContact.gridHeader.mobile': 'Mobile',
    'supplier.listing.showContact.gridHeader.faxNumber': 'Fax Number',
    'supplier.listing.showContact.gridHeader.email': 'Email',

    'supplier.listing.showAddress.gridHeader.primaryAddress': 'Primary Address',
    'supplier.listing.showAddress.gridHeader.streetAddress1': 'Street Address1',
    'supplier.listing.showAddress.gridHeader.streetAddress2': 'Street Address2',
    'supplier.listing.showAddress.gridHeader.city': 'City',
    'supplier.listing.showAddress.gridHeader.country': 'Country',
    'supplier.listing.showAddress.gridHeader.state': 'State',
    'supplier.listing.showAddress.gridHeader.postalCode': 'Zip/Postal',

    'supplier.listing.logCommunication.communicationType.label': 'Communication Type',
    'supplier.listing.logCommunication.communicationType.tooltip': 'Communication Type could be Email, Courier, Fax etc',
    'supplier.listing.logCommunication.communicationType.validationMessage': 'Communication mode is required.',
    'supplier.listing.logCommunication.description.label': 'Description',
    'supplier.listing.logCommunication.description.tooltip': 'Brief description about the communication',
    'supplier.listing.logCommunication.communicatedTo.label': 'Communicated to',
    'supplier.listing.logCommunication.communicatedTo.tooltip': 'It could be email address, fax number, full postal address',
    'supplier.listing.logCommunication.communicatedBy.label': 'Communicated by',

    'supplier.listing.logActivity.activityType.label': 'Activity Type',
    'supplier.listing.logActivity.description.label': 'Description',
    'supplier.listing.logActivity.description.placeholder': 'Brief description about the activity',
    'supplier.listing.logActivity.refNumber.label': 'Ref #',
    'supplier.listing.logActivity.refNumber.tooltip': 'This could be transaction name or transaction number relevant to this activity',
    'supplier.listing.logActivity.refNumber.placeholder': 'Ref Number',
    'supplier.listing.logActivity.status.label': 'Status',
    'supplier.listing.logActivity.priority.label': 'Priority',
    'supplier.listing.logActivity.assignTo.label': 'Assign To',
    'supplier.listing.logActivity.activityDueDate.label': 'Activity Due Date',
    'supplier.listing.logActivity.ActivityClosedDate.label': 'Activity Closed Date',

    'supplier.detail.activity.gridHeader.activityType': 'Activity Type',
    'supplier.detail.activity.gridHeader.description': 'Description',
    'supplier.detail.activity.gridHeader.refNumber': 'Ref #',
    'supplier.detail.activity.gridHeader.status': 'Status',
    'supplier.detail.activity.gridHeader.priority': 'Priority',
    'supplier.detail.activity.gridHeader.assignedTo': 'Assigned To',
    'supplier.detail.activity.gridHeader.dueDate': 'Due Date',
    'supplier.detail.activity.gridHeader.closedDate': 'Closed Date',
    'supplier.detail.activity.gridHeader.dateCreated': 'Date Created',
    'supplier.detail.activity.gridHeader.documents': 'Documents',

    'supplier.detail.communication.gridHeader.type': 'Type',
    'supplier.detail.communication.gridHeader.description': 'Description',
    'supplier.detail.communication.gridHeader.communicatedTo': 'Communicated to',
    'supplier.detail.communication.gridHeader.transaction': 'Transaction',
    'supplier.detail.communication.gridHeader.date': 'Date',
    'supplier.detail.communication.gridHeader.origination': 'Origination',
    'supplier.detail.communication.gridHeader.communicatedBy': 'Communicated by',

    'supplier.detail.transaction.gridHeader.transaction': 'Transaction',
    'supplier.detail.transaction.gridHeader.transactionNumber': 'Transaction Number',
    'supplier.detail.transaction.gridHeader.transactionAmount': 'Transaction Amount',
    'supplier.detail.transaction.gridHeader.transactionDate': 'Transaction Date',
    'supplier.detail.transaction.gridHeader.status': 'Status',
    'supplier.detail.transaction.gridHeader.invoiceNumber': 'Invoice Number',
    'supplier.detail.transaction.gridHeader.poNumber': 'PO Number',
    'supplier.detail.transaction.gridHeader.amount': 'Amount',
    'supplier.detail.transaction.gridHeader.paymentDueDate': 'Payment Due Date',
    'supplier.detail.transaction.gridHeader.paymentTerm': 'Payment Term',
    'supplier.detail.transaction.gridHeader.date': 'Date',
    'supplier.detail.transaction.gridHeader.paymentNumber': 'Payment Number',
    'supplier.detail.transaction.gridHeader.priority': 'Priority',

    'modal.settings.companyInfo.aboutCompany.companySize': 'Company Size',
    'modal.settings.companyInfo.aboutCompany.companySize.placeholder': 'Select Company Size',
    'modal.settings.companyInfo.aboutCompany.industry': 'Industry',
    'modal.settings.companyInfo.aboutCompany.industry.placeholder': 'Select Industry',
    'modal.settings.companyInfo.aboutCompany.inventoryItemQty': 'Inventory Item Qty',
    'modal.settings.companyInfo.aboutCompany.inventoryItemQty.placeholder': 'Select Inventory Item Qty',
    'modal.settings.companyInfo.aboutCompany.averageMonthlyOrders': 'Average Monthly Orders',
    'modal.settings.companyInfo.aboutCompany.averageMonthlyOrders.placeholder': 'Select Average Monthly Orders',
    'modal.settings.companyInfo.aboutCompany.howDidYouHear': 'How Did You Hear?',
    'modal.settings.companyInfo.aboutCompany.howDidYouHear.placeholder': 'Select source of reaching  us',
  
    'modal.settings.companyInfo.bankInfo.bankName': 'Bank Name',
    'modal.settings.companyInfo.bankInfo.accountNumber': 'Account Number',
    'modal.settings.companyInfo.bankInfo.ifsc': 'IFSC',
    'modal.settings.companyInfo.bankInfo.newAccount': 'Add New Bank Account',

    'modal.settings.companyInfo.company.companyName': 'Company Name',
    'modal.settings.companyInfo.company.phoneNumber': 'Phone Number',
    'modal.settings.companyInfo.company.faxNumber': 'Fax Number',
    'modal.settings.companyInfo.company.website': 'Website',
    'modal.settings.companyInfo.company.decimalDigitsLength': 'Decimal Digits Length',

    'modal.settings.companyInfo.costCenter.costCenterName': 'Cost Center Name',
    'modal.settings.companyInfo.costCenter.costCenterNumber': 'Cost Center Number',
    'modal.settings.companyInfo.costCenter.department': 'Department',
    'modal.settings.companyInfo.costCenter.description': 'Description',
    'modal.settings.companyInfo.costCenter.newCostCenter': 'Add New Cost center',

    'modal.settings.companyInfo.taxInfo.taxType': 'Tax Type',
    'modal.settings.companyInfo.taxInfo.number': 'Number',
    'modal.settings.companyInfo.taxInfo.populateOnTransaction': 'Populate on Transaction',
    'modal.settings.companyInfo.taxInfo.populateOnTransaction.tooltip': 'If selected then tax identification will be displayed on pdf document',
    'modal.settings.companyInfo.taxInfo.addLine': 'Add Line',

    'modal.settings.documentApproval.transaction': 'Transaction',

    'modal.settings.quilityCheck.qualityChecklistRequired': 'Quality Checklist Required',
    'modal.settings.quilityCheck.generateShipmentWithoutQuilityCheck': 'Generate Shipment Without Quility Check',

    'modal.settings.documentnumber.transaction': 'Transaction',
    'modal.settings.documentnumber.numberExample': 'Number Example',
    'modal.settings.documentnumber.numberInfo': 'Number Info',
    'modal.settings.documentnumber.nextSequence': 'Next Sequence #',
    'modal.settings.documentnumber.nextSequence.tooltip': 'Your next document will be generated with the sequence you specify',
    'modal.settings.documentnumber.action': 'Action',

    'modal.settings.manageUser.permissions.view': 'View',
    'modal.settings.manageUser.permissions.create': 'Create',
    'modal.settings.manageUser.permissions.update': 'Update',
    'modal.settings.manageUser.permissions.delete': 'Delete',
    'modal.settings.manageUser.permissions.fullControl': 'Full Control',
    'modal.settings.manageUser.employee': 'Employee',
    'modal.settings.manageUser.email': 'Email',
    'modal.settings.manageUser.email.placeholder': 'Required and activation email will be sent',
    'modal.settings.manageUser.notifyUser': 'Notify User',
    'modal.settings.manageUser.password': 'Password',
    'modal.settings.manageUser.password.placeholder': 'Password',
    'modal.settings.manageUser.password.validationMessage': 'Password is required.',
    'modal.settings.manageUser.confirmPassword': 'Confirm Password',
    'modal.settings.manageUser.confirmPassword.placeholder': 'Repeat Password',
    'modal.settings.manageUser.employee.requiredMessage': 'Employee is required.',
    'modal.settings.manageUser.employee.validationMessage': 'Incorrect Email',

    'modal.supplier.applyPurchaseCredit.debitMemoNumber': 'Debit Memo #',
    'modal.supplier.applyPurchaseCredit.debitMemoDate': 'Debit Memo Date',
    'modal.supplier.applyPurchaseCredit.debitAmount': 'Debit Amount',
    'modal.supplier.applyPurchaseCredit.debitsAvailable': 'Debits Available',
    'modal.supplier.applyPurchaseCredit.amountToDebit': 'Amount To Debit',
    'modal.supplier.applyPurchaseCredit.remarks': 'Remarks',
    'modal.supplier.applyPurchaseCredit.invoiceNumber': 'Invoice #',
    'modal.supplier.applyPurchaseCredit.invoiceDate': 'Invoice Date',
    'modal.supplier.applyPurchaseCredit.dueDate': 'Due Date',
    'modal.supplier.applyPurchaseCredit.invoiceAmount': 'Invoice Amount',
    'modal.supplier.applyPurchaseCredit.totalAmountToDebit': 'Amount to Debit',
    'modal.supplier.applyPurchaseCredit.invoiceDue': 'Invoice Due',
    'modal.supplier.applyPurchaseCredit.applyCredits': 'Apply Credits',

    'modal.customer.applySalesCredit.creditMemoNumber': 'Credit Memo #',
    'modal.customer.applySalesCredit.creditMemoDate': 'Credit Memo Date',
    'modal.customer.applySalesCredit.creditAmount': 'Credit Amount',
    'modal.customer.applySalesCredit.creditsAvailable': 'Credits Available',
    'modal.customer.applySalesCredit.amountToCredit': 'Amount To Credit',
    'modal.customer.applySalesCredit.remarks': 'Remarks',
    'modal.customer.applySalesCredit.invoiceNumber': 'Invoice #',
    'modal.customer.applySalesCredit.invoiceDate': 'Invoice Date',
    'modal.customer.applySalesCredit.dueDate': 'Due Date',
    'modal.customer.applySalesCredit.invoiceAmount': 'Invoice Amount',
    'modal.customer.applySalesCredit.totalAmountToCredit': 'Amount to Credit',
    'modal.customer.applySalesCredit.invoiceDue': 'Invoice Due',
    'modal.customer.applySalesCredit.applyCredits': 'Apply Credits',

    'modal.txDetails.common.billTo': 'Bill To:',
    'modal.txDetails.common.shipTo': 'Ship To:',
    'modal.txDetails.common.shippingAddress': 'Shipping Address',
    'modal.txDetails.common.placeOfSupply': 'Place of Supply',
    'modal.txDetails.common.foreignCurrency': 'Foreign Currency',
    'modal.txDetails.common.exchangeRate': 'Exchange Rate',
    'modal.txDetails.common.project': 'Project',
    'modal.txDetails.common.dropshipOrder': 'Dropship Order?',
    'modal.txDetails.common.refNumber': 'Ref #',
    'modal.txDetails.common.salesPerson': 'Sales Person',
    'modal.txDetails.common.paymentTerm': 'Payment Term',
    'modal.txDetails.common.priority': 'Priority',
    'modal.txDetails.common.product': 'Product',
    'modal.txDetails.common.productName': 'Product Name',

    'modal.txDetails.common.description': 'Description',
    'modal.txDetails.common.qty': 'Qty',
    'modal.txDetails.common.uom': 'UOM',
    'modal.txDetails.common.basePrice': 'Base Price',
    'modal.txDetails.common.specialDiscount': 'Special Discount',
    'modal.txDetails.common.rate': 'Rate',
    'modal.txDetails.common.hsn': 'HSN',
    'modal.txDetails.common.sku': 'SKU',
    'modal.txDetails.common.discount': 'Discount',
    'modal.txDetails.common.tax': 'Tax',
    'modal.txDetails.common.netAmount': 'Net Amount',
    'modal.txDetails.common.attachments': 'Attachments',
    'modal.txDetails.common.internalRemarks': 'Internal Remarks',
    'modal.txDetails.common.supplierRemarks': 'Supplier Remarks',
    'modal.txDetails.common.customerRemarks': 'Customer Remarks',
    'modal.txDetails.common.remarks': 'Remarks',
    'modal.txDetails.common.employeeRemarks': 'Employee Remarks',
    'modal.txDetails.common.shippingInstructions': 'Shipping Instructions',
    'modal.txDetails.common.subTotal': 'Sub Total',
    'modal.txDetails.common.expense': 'Expense',
    'modal.txDetails.common.grandTotal': 'Grand Total',
    'modal.txDetails.common.deleteStamp': 'Delete Stamp',
    'modal.txDetails.common.edit': 'Edit',
    'modal.txDetails.common.clone': 'Clone',
    'modal.txDetails.common.delete': 'Delete',
    'modal.txDetails.common.void': 'Void',
    'modal.txDetails.common.delete.confirmation': 'Are you sure you want to delete this transaction?',
    'modal.txDetails.common.email': 'Email',
    'modal.txDetails.common.downloadPDF': 'Download PDF',
    'modal.txDetails.common.download': 'Download',
    'modal.txDetails.common.print': 'Print',
    'modal.txDetails.common.auditTrail': 'Audit Trail',
    'modal.txDetails.common.updateStatus': 'Update Status',
    'modal.txDetails.common.delegateApprover': 'Delegate Approver',
    'modal.txDetails.common.reject': 'Reject',
    'modal.txDetails.common.status': 'Status',
    'modal.txDetails.common.materialNumber': 'Material #',
    'modal.txDetails.common.warehouse': 'Warehouse',
    'modal.txDetails.common.stockNumber': 'Stock #',
    'modal.txDetails.common.partNumber': 'Part #',
    'modal.txDetails.common.hsCode': 'HS Code',

    'modal.txDetails.purchaseOrder.approvePO': 'Approve PO',
    'modal.txDetails.purchaseOrder.convertToID': 'Convert to ID',
    'modal.txDetails.purchaseOrder.convertToInboundDelivery': 'Convert to Inbound Delivery',
    'modal.txDetails.purchaseOrder.poNumber': 'PO #',
    'modal.txDetails.purchaseOrder.poDate': 'PO Date',
    'modal.txDetails.purchaseOrder.poDueDate': 'PO Due Date',
    'modal.txDetails.purchaseOrder.expectedDelivery': 'Expected Delivery',
    'modal.txDetails.purchaseOrder.rfqNumber': 'RFQ #',
    'modal.txDetails.purchaseOrder.supplierQuoteNumber': 'Supplier Quote #',

    'modal.txDetails.purchaseInvoice.payInvoice': 'Pay Invoice',
    'modal.txDetails.purchaseInvoice.approveInvoice': 'Approve Invoice',
    'modal.txDetails.purchaseInvoice.availableDebits': 'Available Debits',
    'modal.txDetails.purchaseInvoice.applyDebits': 'Apply Debits',
    'modal.txDetails.purchaseInvoice.invoiceNumber': 'Invoice #',
    'modal.txDetails.purchaseInvoice.invoiceDate': 'Invoice Date',
    'modal.txDetails.purchaseInvoice.dueDate': 'Due Date',

    'modal.txDetails.purchaseRequest.approvePR': 'Approve PR',
    'modal.txDetails.purchaseRequest.requestor': 'Requestor',
    'modal.txDetails.purchaseRequest.owner': 'Owner',
    'modal.txDetails.purchaseRequest.purchaseRequestNumber': 'PR#',
    'modal.txDetails.purchaseRequest.prDate': 'PR date',
    'modal.txDetails.purchaseRequest.expireDate': 'Expire Date',
    'modal.txDetails.purchaseRequest.reason': 'Reason',
    'modal.txDetails.purchaseRequest.convertTo.rfq': 'RFQ',
    'modal.txDetails.purchaseRequest.convertTo.po': 'Purchase Order',

    'modal.txDetails.debitMemo.debitMemoNumber': 'Debit Memo #',
    'modal.txDetails.debitMemo.debitMemoDate': 'Debit Memo Date',

    'modal.txDetails.inboundDelivery.inboundDeliveryNumber': 'Inbound Delivery #',
    'modal.txDetails.inboundDelivery.poNumber': 'PO #',
    'modal.txDetails.inboundDelivery.inboundDeliveryDate': 'Inbound Delivery Date',
    'modal.txDetails.inboundDelivery.rfqNumber': 'RFQ #',
    'modal.txDetails.inboundDelivery.supplierQuoteNumber': 'Supplier Quote #',
    'modal.txDetails.inboundDelivery.convertToInvoice': 'Convert to Invoice',

    
    'modal.txDetails.rfq.convertToPO': 'Convert to PO',
    'modal.txDetails.rfq.approveRFQ': 'Approve RFQ',
    'modal.txDetails.rfq.rfqNumber': 'RFQ #',
    'modal.txDetails.rfq.rfqDate': 'RFQ Date',
    'modal.txDetails.rfq.bidClosingDate': 'Bid Closing Date',
    'modal.txDetails.rfq.customerRFQNumber': 'Customer RFQ #',
    
    'modal.txDetails.payment.customerName': 'Customer Name',
    'modal.txDetails.payment.supplierName': 'Supplier Name',
    'modal.txDetails.payment.paymentNumber': 'Payment Number',
    'modal.txDetails.payment.invoiceNumber': 'Invoice Number',
    'modal.txDetails.payment.paymentDate': 'Payment Date',
    'modal.txDetails.payment.PaymentMode': 'Payment Mode',
    'modal.txDetails.payment.paymentAmount': 'Payment Amount',
    'modal.txDetails.payment.invoiceAmount': 'Invoice Amount',
    'modal.txDetails.payment.refNumber': 'Ref #',
    'modal.txDetails.payment.remarks': 'Remarks',
    'modal.txDetails.payment.project': 'Project',

    'modal.txDetails.creditMemo.creditMemoNumber': 'Credit Memo #',
    'modal.txDetails.creditMemo.creditMemoDate': 'Credit Memo Date',
    'modal.txDetails.creditMemo.creditMemoExp': 'Credit Memo Exp. Date',

    'modal.txDetails.inquiry.inquiryNumber': 'Inquiry #',
    'modal.txDetails.inquiry.inquiryDate': 'Inquiry Date',
    'modal.txDetails.inquiry.inquiryClosingDate': 'Inquiry Closing Date',
    'modal.txDetails.inquiry.customerInquiryNumber': 'Customer Inquiry #',
    'modal.txDetails.inquiry.convertTo.salesQuote': 'Sales Quote',
    'modal.txDetails.inquiry.convertTo.purchaseRequest': 'Purchase Request',

    'modal.txDetails.package.label': 'Package',
    'modal.txDetails.package.length': 'Length',
    'modal.txDetails.package.width': 'Width',
    'modal.txDetails.package.height': 'Height',
    'modal.txDetails.package.weight': 'Weight',
    'modal.txDetails.package.packageNumber': 'Package #',
    'modal.txDetails.package.packageDate': 'Package Date',
    'modal.txDetails.package.soNumber': 'SO #',
    'modal.txDetails.package.soDate': 'SO Date',
    'modal.txDetails.package.poNumber': 'PO #',
    'modal.txDetails.package.status': 'Status',
    'modal.txDetails.package.deliveryDate': 'Delivery Date',
    'modal.txDetails.package.qtyOrdered': 'Qty Ordered',
    'modal.txDetails.package.qtyPacked': 'Qty Packed',
    'modal.txDetails.package.packageLabel': 'Package Label',
    'modal.txDetails.package.convertToShipment': 'Convert to Shipment',
    'modal.txDetails.package.qualityCheck': 'Quality Check',

    'modal.txDetails.package.labelDownload.itemName': 'Item Name',
    'modal.txDetails.package.labelDownload.poNumber': 'PO #',
    'modal.txDetails.package.labelDownload.poLineNumber': 'PO Line #',
    'modal.txDetails.package.labelDownload.stockNumber': 'Stock #',
    'modal.txDetails.package.labelDownload.qty': 'Quantity',

    'modal.txDetails.salesInvoice.payInvoice': 'Pay Invoice',
    'modal.txDetails.salesInvoice.approveInvoice': 'Approve Invoice',
    'modal.txDetails.salesInvoice.availableDebits': 'Available Debits',
    'modal.txDetails.salesInvoice.applyCredits': 'Apply Credits',
    'modal.txDetails.salesInvoice.invoiceNumber': 'Invoice #',
    'modal.txDetails.salesInvoice.invoiceDate': 'Invoice Date',
    'modal.txDetails.salesInvoice.dueDate': 'Due Date',
    'modal.txDetails.salesInvoice.customerPO': 'Customer PO',
    'modal.txDetails.salesInvoice.bankDetails': 'Bank Details',
    
    'modal.txDetails.proformaInvoice.title': 'Proforma Invoice',
    'modal.txDetails.proformaInvoice.convertToInvoice': 'Convert to Invoice',

    'modal.txDetails.salesQuote.convertToSO': 'Convert to SO',
    'modal.txDetails.salesQuote.approveQuote': 'Approve Quote',
    'modal.txDetails.salesQuote.quoteNumber': 'Quote #',
    'modal.txDetails.salesQuote.quoteDate': 'Quote Date',
    'modal.txDetails.salesQuote.quoteExpiryDate': 'Quote Expiry Date',
    'modal.txDetails.salesQuote.rfqNumber': 'RFQ #',
    'modal.txDetails.salesQuote.customerRFQ': 'Customer RFQ',
    'modal.txDetails.salesQuote.customerRFQNumber': 'Customer RFQ #',
    'modal.txDetails.salesQuote.deliveryMethod': 'Delivery Method',

    'modal.txDetails.salesOrder.soNumber': 'SO #',
    'modal.txDetails.salesOrder.soDate': 'SO Date',
    'modal.txDetails.salesOrder.deliveryDate': 'Delivery Date',
    'modal.txDetails.salesOrder.userStatus': 'User Status',
    'modal.txDetails.salesOrder.convertTo.purchaseRequest': 'Purchase Request',
    'modal.txDetails.salesOrder.convertTo.salesInvoice': 'Sales Invoice',
    'modal.txDetails.salesOrder.convertTo.proformaInvoice': 'Proforma Invoice',
    'modal.txDetails.salesOrder.convertTo.package': 'Package',
    'modal.txDetails.salesOrder.approveSO': 'Approve SO',
    'modal.txDetails.salesOrder.qualityCheck': 'Quality Check',
    'modal.txDetails.salesOrder.soSource': 'SO Source',
    'modal.txDetails.salesOrder.customerPO': 'Customer PO',

    'modal.reports.agedCreditors.supplierName': 'Supplier Name',
    'modal.reports.agedCreditors.invoiceNumber': 'Invoice #',
    'modal.reports.agedCreditors.invoiceDate': 'Invoice Date',
    'modal.reports.agedCreditors.invoiceAmount': 'Invoice Amount',
    'modal.reports.agedCreditors.status': 'Status',

    'modal.reports.agedDebtors.customerName': 'Customer Name',
    'modal.reports.agedDebtors.invoiceNumber': 'Invoice #',
    'modal.reports.agedDebtors.invoiceDate': 'Invoice Date',
    'modal.reports.agedDebtors.invoiceAmount': 'Invoice Amount',
    'modal.reports.agedDebtors.status': 'Status',

    'reports.agedDebtor.header.companyName': 'Company Name',
    'reports.agedDebtor.header.totalReceivable': 'Total Receivable',
    'reports.agedDebtor.header.current': 'Current (Not Yet Due)',
    'reports.agedDebtor.header.pastDue.Last30Days': 'Past Due 1 – 30 Days',
    'reports.agedDebtor.header.pastDue.30To60Days': 'Past Due 31 – 60 Days',
    'reports.agedDebtor.header.pastDue.60To90Days': 'Past Due 61 – 90 Days',
    'reports.agedDebtor.header.pastDue.above90Days': 'Past Due 91+ Days',

    'modal.reports.partyLedger.gridHeader.date': 'Date',
    'modal.reports.partyLedger.gridHeader.transaction': 'Transaction',
    'modal.reports.partyLedger.gridHeader.transactionNumber': 'Transaction Number',
    'modal.reports.partyLedger.gridHeader.debit': 'Debit',
    'modal.reports.partyLedger.gridHeader.credit': 'Credit',
    'modal.reports.partyLedger.startDate.label': 'Start Date',
    'modal.reports.partyLedger.endDate.label': 'End Date',
    'modal.reports.partyLedger.submit.label': 'Submit',
    'modal.reports.partyLedger.total.label': 'Total',
    'modal.reports.partyLedger.closingAmount.label': 'Closing Amount (Dr)',
    'modal.reports.partyLedger.download.label': 'Download',

    'modal.finance.businessExpense.expense.label': 'Expense',
    'modal.finance.businessExpense.expenseNumber': 'Expense Number',
    'modal.finance.businessExpense.expenseDate': 'Expense Date',
    'modal.finance.businessExpense.costCenter': 'Cost Center',
    'modal.finance.businessExpense.refNumber': 'Reference #',
    'modal.finance.businessExpense.header.expenseType': 'Expense Type',
    'modal.finance.businessExpense.header.description': 'Description',
    'modal.finance.businessExpense.header.amount': 'Amount (Tax Inclusive)',
    'modal.finance.businessExpense.remarks': 'Remarks',
    'modal.finance.businessExpense.totalAmount': 'Total Amount',

    'modal.finance.JournalDetail.journal.label': 'Journal',
    'modal.finance.JournalDetail.journalDate': 'Journal Date',
    'modal.finance.JournalDetail.journalNumber': 'Journal Number',
    'modal.finance.JournalDetail.ledgerAccount': 'Ledger Account',
    'modal.finance.JournalDetail.description': 'Description',
    'modal.finance.JournalDetail.debit': 'Debit',
    'modal.finance.JournalDetail.credit': 'Credit',
    'modal.finance.JournalDetail.total': 'Total',
    'modal.finance.JournalDetail.narration': 'Narration',
    
    'modal.certificateOfConformity.title': 'Certificate of Conformity',
    'modal.shippingInvoice.title': 'Shipping Invoice',
    'modal.txDetails.shipment.markAsShipped': 'Mark As Shipped',
    'modal.txDetails.shipment.markAsDelivered': 'Mark As Delivered',
    'modal.txDetails.shipment.shippingInvoice': 'Shipping Invoice',
    'modal.txDetails.shipment.updateDeliveryDate': 'Update Delivery Date',
    'modal.txDetails.shipment.shipmentNumber': 'Shipment #',
    'modal.txDetails.shipment.carrier': 'Carrier',
    'modal.txDetails.shipment.trackingNumber': 'Tracking No.',
    'modal.txDetails.shipment.freightType': 'Freight Type',
    'modal.txDetails.shipment.consignee': 'Consignee',
    'modal.txDetails.shipment.shipmentDate': 'Shipment Date',
    'modal.txDetails.shipment.expectedDel]ivery': 'Expected Del]ivery',
    'modal.txDetails.shipment.poNumber': 'PO #',
    'modal.txDetails.shipment.soNumber': 'SO #',
    'modal.txDetails.shipment.packageNumber': 'Package #',
    'modal.txDetails.shipment.qtyOrdered': 'Qty Ordered',
    'modal.txDetails.shipment.qtyPacked': 'Qty Packed',
    'modal.txDetails.shipment.qtyShipped': 'Qty Shipped',

    'modal.common.deligateApporver.currentApprover': 'Current Approver',
    'modal.common.deligateApporver.deligateTo': 'Deligate To',

    'modal.common.newTax.taxName': 'Tax Name',
    'modal.common.newTax.taxRate': 'Tax Rate',

    'modal.common.priceList.name': 'Name',
    'modal.common.priceList.description': 'Description',
    'modal.common.priceList.percentage': 'Percentage',
    'modal.common.priceList.startDate': 'Start Date',
    'modal.common.priceList.endDate': 'End Date',
    'modal.common.priceList.startDate.tooltip': 'Price list start  date',
    'modal.common.priceList.endDate.tooltip': 'Price list end date',

    'modal.common.project.projectName': 'Project Name',
    'modal.common.project.description': 'Description',
    'modal.common.project.departmentName': 'Department Name',
    'modal.common.project.projectOwner': 'Project Owner',
    'modal.common.project.startDate': 'Start Date',
    'modal.common.project.endDate': 'End Date',

    'modal.common.rejectApproval.reason': 'Reason',
    'modal.common.rejectApproval.reason.placeholder': 'Enter Reason',

    'modal.common.warehouse.warehouseName': 'Warehouse Name',

    'modal.common.taxIdentification.taxType': 'Tax Type',
    'modal.common.taxIdentification.number': 'Number',
    'modal.common.taxIdentification.populateOnTransaction': 'Populate on Transaction',
    'modal.common.taxIdentification.populateOnTransaction.tooltip': 'If selected then tax identification will be displayed on pdf document',

    'modal.common.xero.invoice.itemName': 'Item Name',
    'modal.common.xero.invoice.description': 'Description',
    'modal.common.xero.invoice.rate': 'Rate',
    'modal.common.xero.invoice.quantity': 'Quantity',
    'modal.common.xero.invoice.invoiceNumber': 'Invoice #',
    'modal.common.xero.invoice.supplierName': 'Supplier Name',
    'modal.common.xero.invoice.customerName': 'Customer Name',

    'modal.common.xero.payment.invoiceNumber': 'Invoice #',
    'modal.common.xero.payment.paymentNumber': 'Payment #',
    'modal.common.xero.payment.paymentAmount': 'Payment Amount',

}