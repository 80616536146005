import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPaymentModes, showModal, hideModal, fetchCashEquivalentLedgers, pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { createSupplierPayment, getNextTxNumber } from './action';
import { resetPaginatedData } from '../../../modal/modalBody/supplier/UnpaidInvoices/action';
import { fetchDataIfNeeded } from '../../../../utils';
import PayExpense from '../../../../components/finance/BusinessExpense/PayExpense';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import find from 'lodash.find';
class PayExpenseContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentDate: new Date(),
      expenseMasterId: props.businessExpenseData.expenseMasterId,
      expenseAmount: props.businessExpenseData.expenseTotalAmount || 0,
      expenseNumber: props.businessExpenseData.expenseNumber,
      amount: props.businessExpenseData.expenseTotalAmount || 0,
      expenseAmountDue: props.businessExpenseData.expenseTotalAmount || 0,
      reference: props.businessExpenseData.refNumber,
      cashInHandLedger: find((props.companyInfo || {}).defaultCoaLedgerAccountsList, { txType: "Cash in hand" }) || {},
      creditorsLedger: find((props.companyInfo || {}).defaultCoaLedgerAccountsList, { txType: "Creditors" }) || {}
    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageSize: 100,
      pageNumber: 1
    };
    this.props.resetPaginatedData();
    //payload.supplierId && fetchPaginationDataIfNeeded('fetchUnpaidInvoices', 'unpaidInvoiceList', this.props, payload, true);
   // fetchPaginationDataIfNeeded('fetchSuppliers', 'suppliers', this.props, payload, true);
    fetchDataIfNeeded('fetchPaymentModes', 'paymentModeList', this.props, payload);
    fetchDataIfNeeded('fetchCashEquivalentLedgers','cashEquivalentLedgers', this.props, payload);
    if (!linkProps.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
    this.props.fetchRemarksTemplate({relationshipId: companyInfo.relationshipId, docName: 'Purchase Payment'});
  }
  componentWillReceiveProps(props) {
    if (!this.state.remarks && props.remarksData &&  props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ remarks: props.remarksData.content || '', isRemarksSet: true });
      setTimeout(()=>{
        props.resetRemarksData();
    },500)
    }
  }

  render() {
    return (
      <PayExpense {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    //unpaidInvoiceList: state.supplier.unpaidInvoiceList,
    paymentModeList: state.common.paymentModeList,
    //suppliers: state.supplier.suppliers,
    cashEquivalentLedgers: state.common.cashEquivalentLedgers,
    nextTxNumber: state.supplierPayment.nextTxNumber,
    remarksData: state.settings.remarksData
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetPaginatedData,
  fetchPaymentModes,
  createSupplierPayment,
  fetchCashEquivalentLedgers,
  showModal,
  hideModal,
  getNextTxNumber,
  fetchRemarksTemplate,
  resetRemarksData,
  pushModalToStack,
  popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayExpenseContainer);
