import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IDDetailsComponent from '../../../../../components/modal/modalBody/supplier/InboundDeliveryDetail';
import { fetchInboundryDetailDetail, uploadStamp, deleteStamp } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchSupplierDetailsBySupplierId, deleteAttectedFile } from '../../../../../actions/commonActions';

class InboundDeliveryDetail extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, supplierId: this.props.poPayload.supplierId, inboundDeliveryMasterId: this.props.poPayload.inboundDeliveryMasterId };
        fetchDataIfNeeded('fetchInboundryDetailDetail', 'inboundDeliveryData', this.props, payload, true);
        fetchDataIfNeeded('fetchSupplierDetailsBySupplierId', 'supplierData', this.props, payload, true);

    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <IDDetailsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        inboundDeliveryData: state.inboundDelivery.inboundDeliveryData,
        supplierData: state.common.supplierDetail,
        detailLoading: state.common.detailLoading
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchInboundryDetailDetail,
    fetchSupplierDetailsBySupplierId,
    downloadFileFromBucket,
    generatePDF, printPDF, 
    uploadStamp, 
    deleteStamp, deleteAttectedFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InboundDeliveryDetail);