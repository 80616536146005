import React from "react";
import { ICONS } from "../../../../../static/constants";

const Contact = (props) => {

    const {leadData} = props;

    return (
        <div className="contact-info">
            <div>
                <ul className="primary-info">
                    <div className="b-text">Primary</div>

                    <li className="p-info">
                        <i className="fi fi-rs-circle-user mr5" /> {leadData.fullName || '-'}
                    </li>
                    <li className="p-info">
                        <i class={`${ICONS.MAIL} mr5`} /> {leadData.email || '-'}
                    </li>
                    <li className="p-info">
                        <i class={`${ICONS.CALL} mr5`} /> {leadData.mobileNo || '-'}
                    </li>
                    <li className="p-info">
                        <i class="fi fi-brands-whatsapp mr5" /> {leadData.mobileNo || '-'}
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Contact;

