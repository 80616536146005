import React from 'react';
import { injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Skeleton, Empty, Input } from 'antd';
import './index.scss';
import erpLogo from "../../../assets/images/erpIcon.svg";
import commerceLogo from "../../../assets/images/commerceIcon.svg";
import educationLogo from "../../../assets/images/educationIcon.svg";
import crmLogo from "../../../assets/images/crmIcon.svg";
import hrmsLogo from "../../../assets/images/hrmsIcon.svg";
import { getCurrencySymbol } from '../../../utils';

function AlpideProductsDrawerComp(props) {
    const { subscribedProduct } = props;
    const closeDrawer = () => {
        props.updateHeaderState({
            alpideProductDrawer: false
        })
    }

    function getIconFromName(name) {
        switch (name) {
            case 'Cloud ERP':
                return erpLogo;
            case 'E-Commerce':
                return commerceLogo;
            case 'Education ERP':
                return educationLogo;
            case 'CRM':
                return crmLogo;
            case 'HRMS':
                return hrmsLogo;
            default:
                return erpLogo;
        }
    }

    function getColorFromName(name) {
        switch (name) {
            case 'Cloud ERP':
                return '#0075ff';
            case 'E-Commerce':
                return '#ed4d41';
            case 'Education ERP':
                return '#5555e3';
            case 'CRM':
                return '#bf4cc8';
            case 'HRMS':
                return '#febc2e';
            default:
                return erpLogo;
        }
    }

    function getDescriptionFromName(name) {
        switch (name) {
            case 'Cloud ERP':
                return 'Integrated cloud solution for managing all business processes, from finance to supply chain.';
            case 'E-Commerce':
                return 'Digital platform for businesses to sell products online, manage inventory, and process payments.';
            case 'Education ERP':
                return 'Comprehensive platform for managing academic institutions, including admissions, exams, and student information.';
            case 'CRM':
                return 'Centralized system for managing customer relationships, sales processes, and improving business-client interactions.';
            case 'HRMS':
                return 'Human resource management software for recruitment, payroll, attendance, and employee performance tracking.';
            default:
                return '';
        }
    }



    return (
        <Drawer
            //   title={<>
            //     Add-On Alpide more products in your journey
            //     <div>Select your pick-we've got you covered.</div>
            //   </>}
            className="custom-drawer"
            keyboard={true}
            width={500}
            maskClosable={false}
            zIndex={99999}
            destroyOnClose={true}
            onClose={closeDrawer}
            visible={props.alpideProductDrawer}
            afterVisibleChange={
                (visible) => {
                    if (visible) { }
                }
            }>
            <>
                <div>
                    <div className='product_drawer_head'>
                        Add-On Alpide more products<br /> in your journey
                    </div>
                    <div className='light-txt mb20'>
                        Select your pick-we've got you covered.
                    </div>
                </div>
                <Input
                    placeholder="Search products"
                    className='product_search'
                />
                <Skeleton loading={props.listLoading} paragraph={{ rows: 15 }} active>
                    <div className='alpide_product_container'>
                        {props.alpideProducts?.length ?
                            props.alpideProducts.map((product, idx) => {
                                const isPurchased = subscribedProduct?.find(val=>val.alpideProductId=== product.alpideProductId);

                                return (
                                    <div
                                        className='product_border_card'
                                        key={idx + product.alpideProductId}
                                        onClick={() => {
                                            props.openPricingPlan(product.alpideProductId);
                                        }}
                                    >
                                        <div className='product_card'>
                                            <div>
                                                <img src={getIconFromName(product.alpideProductName)} alt='' height={'40px'} />
                                                <span className='product_name' style={{ color: getColorFromName(product.alpideProductName) }}>{product.alpideProductName}</span>
                                            </div>
                                            <div className='pricing_details'>
                                                {/* <div>Starts at</div>
                                                <div className='item-base'>
                                                    <span 
                                                        className='b-text font-20'
                                                        style={{ color: getColorFromName(product.alpideProductName) }}
                                                     >
                                                        {subscribedProduct.annualPrice || "₹999"}
                                                    </span>
                                                    <span className='font-14' style={{textDecoration:"line-through"}}>
                                                        {getCurrencySymbol(props.companyInfo.currencyCode)}1499
                                                    </span>
                                                    <span style={{color:"#1CB980"}}>60% OFF</span>

                                                </div> */}
                                                <div style={{lineHeight:'1.2em'}} className='mt15'>
                                                    {getDescriptionFromName(product.alpideProductName)}
                                                </div>

                                                {isPurchased ?
                                                    <>
                                                        <div className='already_purchased'></div>
                                                        <div className='label'>
                                                            Already Purchased
                                                        </div>
                                                    </>
                                                    :
                                                    // <>
                                                    //     <div>For {subscribedProduct.quantity} user / Month</div>
                                                    //     <div>(Billed Annually)</div>
                                                    // </>
                                                    ''
                                                }

                                            </div>
                                            
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <Empty
                                description={'We ran into some issue, Please contact support team'}
                            />
                        }
                    </div>
                </Skeleton>
            </>
        </Drawer>
    );
}

export default injectIntl(AlpideProductsDrawerComp);
