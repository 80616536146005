import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerListingComp from "../../../components/customer/listing/index";
import {
  fetchCustomers,
  getCustomerCount,
  resetPaginatedData,
  getTotalOrdersCount,
  getTotalSalesOrdersCount,
  getTotalUnpaidBalance,
  getTotalSalesRevenue,
  exportExcel,
  exportPdf,
  deleteCustomers,updateStatus,getTotalPaidBalance
} from "./action";
import {
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchUserPreferences,
  saveUserPrefrences,
} from "../../../actions/commonActions";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { exportLedgerPdf } from "../detail/action";
import { generatePDF } from "../../../actions/downloadFileAction";
import { FormattedMessage } from "react-intl";
import find from "lodash.find";
import { fetchTotalOrdersCount } from "../../modal/modalBody/customer/TotalOrders/action";
import { fetchOpenOrdersCount } from "../../modal/modalBody/customer/OpenOrders/action";
import { fetchPaidInvoicesCount } from "../../modal/modalBody/customer/PaidInvoices/action";
// import CustomerListComp from '../../../components/customer/CustomerListComp';


class CustomerListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      txPreferences: { txType: "customer", templateName: "listing" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        "companyName",
        "contact",
        "totalAmountDue",
        "openedOrdersCount",
        "unpaidInvoicesCount",
      ],
      txAvailableColumn: {
        customerType: true,
        companyName: true,
        contact: true,
        totalAmountDue: true,
        openedOrdersCount: true,
        paidInvoicesCount: true,
        // paymentTermName: true,
        unpaidInvoicesCount: true,
      },
      searchedText: "",
      isDataRendered: false,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
  
    const payload = {
      customerId: companyInfo.customerId,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25
    };
    // fetchPaginationDataIfNeeded(
    //   "fetchCustomers",
    //   "customers",
    //   this.props,
    //   payload,
    //   true
    // );
    this.props.fetchCustomers({...payload, hideLoad:true})
    fetchDataIfNeeded("getCustomerCount", "customerCount", this.props, payload);
    // fetchDataIfNeeded(
    //   "getTotalOrdersCount",
    //   "customerOpenOrderCount",
    //   this.props,
    //   payload
    // );
    fetchDataIfNeeded(
      "getTotalSalesOrdersCount",
      "customerOpenSOCount",
      this.props,
      payload
    );
    fetchDataIfNeeded(
      "getTotalUnpaidBalance",
      "customerUnpaidBalance",
      this.props,
      payload
    );
    // fetchDataIfNeeded(
    //   "getTotalSalesRevenue",
    //   "customerSalesRevenue",
    //   this.props,
    //   payload
    // );
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    fetchDataIfNeeded(
      "fetchTotalOrdersCount",
      "totalOrderListCount",
      this.props,
      payload
    );
    fetchDataIfNeeded(
      "fetchOpenOrdersCount",
      "openOrderListCount",
      this.props,
      payload
    );
    fetchDataIfNeeded('fetchPaidInvoicesCount', 'paidInvoiceListCount', this.props, payload, true);
    fetchDataIfNeeded(
      "getTotalPaidBalance",
      "customerSalesRevenue",
      this.props,
      payload
    );
        

  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, {
        txType: "customer",
        templateName: "listing",
      });
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference
          ? (JSON.parse(txPreferences.tablePreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
              "product",
              "description"
            );
        const txColumnSetting = txPreferences.tablePreference
          ? (JSON.parse(txPreferences.tablePreference) || {}).columnSetting ||
            {}
          : {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }
  }

  render() {
    return (
      <CustomerListingComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
      // <CustomerListComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    customerCount: state.customer.customerCount,
    customerOpenOrderCount: state.customer.customerOpenOrderCount,
    customerOpenSOCount: state.customer.customerOpenSOCount,
    customerUnpaidBalance: state.customer.customerUnpaidBalance,
    customerSalesRevenue: state.customer.customerSalesRevenue,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    customerLedger: state.customer.customerLedger,
    permissions: state.auth.permissions,
    listLoading: state.common.listLoading,
    headerLoading:state.common.headerLoading,
    totalOrderListCount:state.customer.totalOrderListCount,
    openOrderListCount: state.customer.openOrderListCount,
    paidInvoiceListCount: state.customer.paidInvoiceListCount,
    customerPaidBalance:state.customer.customerPaidBalance


  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCustomers,
      getCustomerCount,
      showModal,
      hideModal,
      fetchUserPreferences,
      pushModalToStack,
      popModalFromStack,
      resetPaginatedData,
      deleteCustomers,
      exportPdf,
      exportExcel,
      // getTotalOrdersCount,
      getTotalSalesOrdersCount,
      getTotalUnpaidBalance,
      getTotalSalesRevenue,
      saveUserPrefrences,
      exportLedgerPdf,
      generatePDF,
      updateStatus,
      fetchTotalOrdersCount,
      fetchOpenOrdersCount,
      fetchPaidInvoicesCount,
      getTotalPaidBalance
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomerListing);
