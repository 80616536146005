import React from "react";
import '../index.scss';
import { Button, Col, Empty, Menu, Row, Tabs } from "antd";
import { capitalizeFirstLetter, getInitialsFromString, getMomentDateForUI, getMomentDateForUIReadOnly, getUserDefinedName } from "../../../../../../utils";
import { ICONS } from "../../../../../../static/constants";
import { FormattedMessage } from "react-intl";
import DropdownAction from "antd/lib/dropdown";


const { TabPane } = Tabs;

const ContactDetails = (props) => {
    const {studentData={}, contactEducationList=[]} = props;

    const contacts = studentData.boContacts || [];
    const onTabChange = (contactId) =>{
        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            contactId: Number(contactId),
            customerId: studentData?.customerId
        }
        props.getContactEducation(payload);
    }

    const getAddressInView = (obj) => {
        return <>
            { obj.streetAddress1 ? <span className='billing-address'>{obj.streetAddress1}</span> : '' }
            { obj.streetAddress2 ? <span className='billing-address'> {obj.streetAddress2}</span> : ''}
            { obj.cityName ? <div className='billing-address'>
                {
                    `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                }
                </div>
                : ''
            }
            { obj.countryName ? <div className='billing-address'>{`${obj.countryName || ''}`}</div>: ''}
        </>
    }

    const openEducationDrawer = (contact, educationData={})=>{
        props.updateModalState({
            educationDrawerVisible: true,
            contact: contact,
            educationData: educationData
        })
    }

    const actionMenu = (contact, rowData) => {
        return (
          <Menu className="row-action-dropdown">
            <Menu.Item key="0" value="title">
              Actions
            </Menu.Item>
    
            <Menu.Divider />
    
            <Menu.Item key="1" value="edit" onClick={() => openEducationDrawer(contact, rowData)}>
              <i className={ICONS.EDIT} /> Edit
            </Menu.Item>
    
            <Menu.Item
              key="2"
              onClick={() => {
                const modalBody = (
                  <FormattedMessage
                    id="commons.delete.popup.confimation.msg"
                    defaultMessage=""
                  />
                );
                const modalData = {
                  modalBody,
                  handleSubmit: () => {
                    props.deleteContactEducation(rowData, props);
                  },
                };
                props.showModal(modalData);
              }}
            >
              <i className={ICONS.DELETE} /> Delete
            </Menu.Item>
          </Menu>
        );
      };
    

    return (
        <Tabs 
            type="line" 
            animated={false}
            onChange = {onTabChange}
            tabPosition={'left'}
            className="contact_tabs"
        >
            {contacts.map((contact, i)=>{
                
                return <TabPane tab={`${contact.firstName} ${contact.lastName || ""}`} key={contact.contactId}>
                    <div className="contact_details">
                        <div className="basic_details">
                            <div className="contact_pic">
                                <div className='name-icon' style={{margin: '0px'}}>
                                    { getInitialsFromString(`${contact.firstName} ${contact.lastName || ""}` || 'User') }
                                </div>
                            </div>
                            <div className="left_detail">
                                <div className="b-text">{capitalizeFirstLetter(`${contact.firstName} ${contact.lastName || ""}`)}</div>
                                <div className="light-txt">{contact.gender || ''}</div>
                            </div>
                           
                            <div className="mid_detail">
                                <i className="fi fi-rs-marker" />
                                <div className="ml10">
                                    {getAddressInView((studentData.boLocations || []).length ? studentData.boLocations[0] : {})}
                                </div> 
                            </div>  
                            

                            <div className="right_detail">
                                <Row gutter={[16, 16]}>
                                {contact.cellPhone &&
                                    <Col span={12} className="d-flex">
                                        <i className="fi fi-rr-phone-flip mr10" />
                                        {contact.cellPhone || <div className="empty-data-box"></div>}
                                    </Col>
            }
                                    
                                    {contact.dateOfBirth &&
                                    <Col span={12} className="d-flex">
                                        <i className="fi fi-rr-calendar-day mr10" />
                                        {contact.dateOfBirth ? getMomentDateForUIReadOnly({date: contact.dateOfBirth}) : <div className="empty-data-box"></div>}
                                    </Col>  
                                   }
                                   
                                   {contact.emailAddress &&                        
                                    <Col span={12} className="d-flex">
                                        <i className={`${ICONS.MAIL} mr10`} />
                                        {contact.emailAddress || <div className="empty-data-box"></div>}
                                    </Col>
            }
                                    <Col span={12}></Col>
                                </Row>
                            </div>
                        </div>

                        <div className="table_head">
                            <span>Education</span>
                            <Button 
                                type="primary"
                                onClick={()=>openEducationDrawer(contact)}
                            >
                                Add Education
                            </Button>
                        </div>
                        <div className="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Institution Type</th>
                                        <th>Institution Name</th>
                                        <th>{getUserDefinedName("Course", props)}</th>
                                        <th>Major</th>
                                        <th>Score</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(contactEducationList || []).length ? contactEducationList.map((rowData, i)=>{
                                        return <tr key={i}>
                                            <td>{i+1}</td>
                                            <td>{rowData.institutionType || '-'}</td>
                                            <td>{rowData.institutionName || '-'}</td>
                                            <td>{rowData.courseName || '-'}</td>
                                            <td>{rowData.major || '-'}</td>
                                            <td>{rowData.score || '-'}</td>
                                            <td className="line-view">{rowData.startDate ? getMomentDateForUIReadOnly({date: rowData.startDate }) : '-'}</td>
                                            <td className="line-view">{rowData.endDate ? getMomentDateForUIReadOnly({date: rowData.endDate }) : '-'}</td>
                                            <td>
                                                <DropdownAction
                                                    overlay={actionMenu(contact, rowData)}
                                                    trigger={["click"]}
                                                >
                                                    <i className={ICONS.MORE} />
                                                </DropdownAction>
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr key="empty-data">
                                        <td colSpan={"100%"} style={{borderBottom:"none"}}>
                                            <Empty />
                                        </td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </TabPane>
            })}

        </Tabs>
    )
}
export default ContactDetails;
