import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
import { getMomentLoclDateForUIReadOnly, showToasterMessage, getMomentDateForUI, getFinancialYear, getMomentDateForUIReadOnly, exportExcel } from "../../../utils";
import { CONSTANTS_TRANSACTIONS, CONSTANTS } from "../../../static/constants";
import { Pagination, Row, Col, DatePicker } from "antd";
import SODetail from "../../../containers/modal/modalBody/customer/SODetail";
import SalesInvoiceDetails from "../../../containers/modal/modalBody/customer/SalesInvoiceDetail";
import PurchaseInvoiceDetail from "../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail";
import CreditMemoDetail from "../../../containers/modal/modalBody/customer/CreditMemoDetail";
import CustomerPaymentDetails from "../../../containers/modal/modalBody/customer/PaymentDetail";
import PODetail from "../../../containers/modal/modalBody/supplier/PurchaseOrderDetail";
import DebitMemoDetail from "../../../containers/modal/modalBody/supplier/DebitMemoDetail";
import BusinessExpenseDetail from "../../../containers/modal/modalBody/finance/BusinessExpense";
import JournalDetails from "../../../containers/modal/modalBody/finance/JournalDetail";
import SupplierPaymentDetails from "../../../containers/modal/modalBody/supplier/PaymentDetail";
import { Menu } from 'antd';
import { FileExcelOutlined, DownloadOutlined, FilterOutlined } from '@ant-design/icons';
import DropdownAction from "antd/lib/dropdown";

const { RangePicker } = DatePicker;
const BankBook = (props) => {
  const { bankBookList, pageNumber, bankBookCount, showTotal, companyInfo } =
    props;
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;
  const finacialYear = getFinancialYear(props.companyInfo);
  const fyStartDate = finacialYear.fyStartDate;
  const fyEndDate = finacialYear.fyEndDate;

  const downloadMenu = (data) => {
    return <Menu className="row-action-dropdown">
      <Menu.Item key="0" value="title">
        Download as
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="1" value="excel" onClick={() => {
        const ledgerList = [];
        (bankBookList[pageNumber || 1] || []).forEach((obj) => {
          ledgerList.push({
            "Date": getMomentDateForUIReadOnly({ date: new Date(obj.txDate), format }),
            "Party Name": obj.partyName || '',
            "Transaction #": obj.txNumber || '',
            "Transaction Name": obj.transactionName || '',
            "Amount": Number(obj.txAmount || 0).toFixed(2),
          });
        });

        if (ledgerList && ledgerList.length) {
          exportExcel(ledgerList, "Cashbook");
        } else {
          showToasterMessage({
            messageType: "error",
            description:
              "No record found",
          });
        }
      }}>
        <FileExcelOutlined />
        Excel File
      </Menu.Item>
    </Menu>;
  };
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.finance" defaultMessage="" />
      ),
    },

    {
      name: (
        <FormattedMessage id="sidebar.menuItem.bankBook" defaultMessage="" />
      ),
    },
  ];
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.getBankBookCount(payload, companyInfo);
    props.fetchBankBookList(payload, companyInfo);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: pageSize,
    };

    props.updateState({ pageSize: pageSize, pageNumber: 1 });
    props.resetPaginatedData();
    props.fetchBankBookList(payload, companyInfo);
  };

  const halndleOpenDetails = (data) => {
    switch (data.transactionName) {
      case "Sales Invoice":
        const invoicePayload = {
          customerId: data.customerId,
          invoiceMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <SalesInvoiceDetails {...props} invoicePayload={invoicePayload} />
          ),
          width: "100%",
          hideFooter: true,
          keyboard: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Sales Order":
        const soPayload = {
          customerId: data.customerId,
          salesOrderMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: <SODetail {...props} soPayload={soPayload} />,
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Sales Payment":
        const paymentPayload = {
          customerId: data.customerId,
          customerPaymentId: data.txMasterId,
        };
        const modalData = {
          title: "Sales Payment",
          modalBody: <CustomerPaymentDetails paymentPayload={paymentPayload} />,
          width: 500,
          hideFooter: true,
        };
        props.showModal(modalData);
        break;

      case "Credit Memo":
        const CreditMemoload = {
          customerId: data.customerId,
          creditMemoMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <CreditMemoDetail {...props} creditMemoPayload={CreditMemoload} />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Purchase Order":
        const poPayload = {
          supplierId: data.supplierId,
          poMasterId: data.txMasterId,
        };

        props.pushModalToStack({
          modalBody: <PODetail {...props} poPayload={poPayload} />,
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Purchase Invoice":
        const PurchasePayload = {
          supplierId: data.supplierId,
          invoiceMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <PurchaseInvoiceDetail
              {...props}
              invoicePayload={PurchasePayload}
            />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Debit Memo":
        const debitMemoPayload = {
          supplierId: data.supplierId,
          debitMemoMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <DebitMemoDetail {...props} debitMemoPayload={debitMemoPayload} />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Purchase Payment":
        const SupplierPaymentPayload = {
          supplierId: data.supplierId,
          supplierPaymentId: data.txMasterId,
        };
        const modalDataSupplier = {
          title: <div className='ant-modal-title'>Purchase Payment</div>,
          modalBody: <SupplierPaymentDetails paymentPayload={SupplierPaymentPayload} />,
          width: 500,
          hideFooter: true
        };
        props.showModal(modalDataSupplier);
        break;

      case "Business Expense":
        const expensePayload = {
          expenseMasterId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <BusinessExpenseDetail {...props} expensePayload={expensePayload} />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      case "Journal":
        const journalPayload = {
          journalId: data.txMasterId,
        };
        props.pushModalToStack({
          modalBody: (
            <JournalDetails {...props} journalPayload={journalPayload} />
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
        break;

      default:
      // Default case code block.
    }
  };

  return (
    <Fragment>
      {/* <div className="dayBook-container"> */}
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="sidebar.menuItem.cashBook"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right"></div>
        </div>

        <div className="view-container">
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="accounting.bankBook.table.heading"
                  defaultMessage=""
                />
              </div>
              <div className="vertical-sep" />
              <div>{bankBookCount ? bankBookCount : ""}</div>
            </div>
            <div className="right-actions">

              {/* <DatePicker onChange={(dateObj) => {
                if (dateObj) {
                  props.updateState({
                    filterStartDate: dateObj.format(CONSTANTS.BACKEND_FORMAT)
                  });
                } else {
                  props.updateState({
                    filterStartDate: undefined
                  });
                }
              }} format={format}
                key={`${props.filterStartDate ? getMomentDateForUI({ date: new Date(props.filterStartDate), format }) : getMomentDateForUI({ date: new Date(fyStartDate), format })}`}
                defaultValue={props.filterStartDate ? getMomentDateForUI({ date: new Date(props.filterStartDate), format }) : getMomentDateForUI({ date: new Date(fyStartDate), format })}
              />

              

              <DatePicker onChange={(dateObj) => {
                if (dateObj) {
                  props.updateState({
                    filterEndDate: dateObj.format(CONSTANTS.BACKEND_FORMAT)
                  });
                } else {
                  props.updateState({
                    filterEndDate: undefined
                  });
                }
              }} format={format}
                key={`${props.filterEndDate ? getMomentDateForUI({ date: new Date(props.filterEndDate), format }) : getMomentDateForUI({ date: new Date(), format })}`}
                defaultValue={props.filterEndDate ? getMomentDateForUI({ date: new Date(props.filterEndDate), format }) : getMomentDateForUI({ date: new Date(), format })}
              /> */}
<RangePicker
                            allowEmpty={true}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        /> 
              <button
                onClick={() => {
                  const companyInfo = props.companyInfo || {};
                  const payload = {
                    relationshipId: companyInfo.relationshipId,
                    pageNumber: 1,
                    pageSize: 200,
                    filterStartDate: props.filterStartDate || fyStartDate,
                    filterEndDate: props.filterEndDate || fyEndDate,
                  };
                  props.fetchBankBookList(payload, companyInfo);
                  props.getBankBookCount(payload, companyInfo)
                }}
              >
                <FilterOutlined /> &nbsp;{" "}
                <FormattedMessage id="filter.text" defaultMessage="" />
              </button>
              <DropdownAction overlay={downloadMenu()} trigger={["click"]} >
                <span style={{width:"153px"}}>
                  <DownloadOutlined />&nbsp;
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>



            </div>
          </div>
          <div className="table-container">
            <table id="student-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      id="accounting.dayBook.date"
                      defaultMessage=""
                    />
                  </th>

                  <th>
                    <FormattedMessage
                      id="accounting.dayBook.transactionName"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="accounting.cashbook.partyName"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="accounting.dayBook.txNumber"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="accounting.dayBook.Amount"
                      defaultMessage=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {bankBookList && bankBookList[pageNumber]
                  ? bankBookList[pageNumber].map((rowData, index) => (
                    <tr key={index}>
                      <td>
                        {rowData.txDate ? (
                          <div>
                            {getMomentLoclDateForUIReadOnly({
                              date: rowData.txDate,
                              format:
                                CONSTANTS_TRANSACTIONS.DISPLAY_TOOLTIP_DATE_FORMA,
                            })}
                          </div>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td>
                        {rowData.transactionName
                          ? rowData.transactionName
                          : "-"}
                      </td>
                      <td>
                        {rowData.partyName
                          ? rowData.partyName
                          : "-"}
                      </td>

                      <td>
                        <div
                          className="table-link-text"
                          onClick={() => halndleOpenDetails(rowData)}
                        >
                          {rowData.txNumber ? rowData.txNumber : "-"}
                        </div>
                      </td>

                      <td>{rowData.txAmount ? rowData.txAmount : "-"}</td>
                    </tr>
                  ))
                  : " No Data"}
              </tbody>
            </table>
          </div>

          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={bankBookCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                current={props.pageNumber}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  props.updateState({
                    pageSize: pageSize,
                    pageNumber: pageNumber,
                  });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </div>
        </div>
      {/* </div> */}
    </Fragment>
  );
};

export default BankBook;
