import React from "react";
import { injectIntl } from "react-intl";
import "./index.scss";
import {
  Row,
  Col,
} from "antd";
import {
  fixedDecimalAndFormateNumber,
  fixedDecimalNumber,
  getCurrencySymbol,
} from "../../../utils";

const FeeDetailsByPlan = (props) =>{
    let studentclassId = props.studentList[0]?.classId;
    let selectedClass = props.classFees.find(obj=>obj.classId === studentclassId) || [];


    let totalInstallment = props.selectedPlan?.totalInstallment;

    const {feeStructList=[]} = selectedClass;
    const oneTimeFeeTotal = feeStructList
      .filter(fee => fee.oneTimeFee)
      .reduce((sum, fee) => sum + fee.amount, 0);

    const recurringFeeTotal = feeStructList
      .filter(fee => !fee.oneTimeFee)
      .reduce((sum, fee) => sum + fee.amount, 0);
    return oneTimeFeeTotal + recurringFeeTotal ? <Row gutter={[16, 4]}>
        <Col offset={12} span={12}>
          Fees Installments
        </Col>
      
        {Array.from({ length: props.selectedPlan.totalInstallment }, (_, index) => (
          <>
            <Col offset={12} span={10} key={index} style={{backgroundColor: '#e7e7e7'}}>
              Installment {index + 1}{index === 0 ? <span className="required">*</span>:''}: 
            </Col>
            <Col span={2} style={{backgroundColor: '#e7e7e7'}}>
              {getCurrencySymbol(props?.companyInfo?.currencyCode)}{index===0 ? 
                // Number(Math.round((oneTimeFeeTotal + recurringFeeTotal)/totalInstallment))
                Number(fixedDecimalNumber(oneTimeFeeTotal + (recurringFeeTotal/totalInstallment)))
                :
                // Number(Math.round(recurringFeeTotal/totalInstallment))
                Number(fixedDecimalNumber(recurringFeeTotal/totalInstallment))
              }
            </Col>
          </>
        ))}
    </Row>
    :
    ''
}
export default injectIntl(FeeDetailsByPlan);