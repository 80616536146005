import React from 'react';
import './../../../../assets/stylesheets/common/invoice.scss';
// import PageBreadcrumb from "../../../PageBreadcrumb";
import HeaderAction from "./HeaderAction";
import QuoteDetails from "./QuoteDetails";
import ProductDetails from "./ProductDetails";
// import { FormattedMessage } from 'react-intl';
import { Divider } from 'antd';

const SalesInvoiceComponent = (props) => {
    // const breadCrumbList = [
    //     {
    //         name: <FormattedMessage id='common.sales' defaultMessage='' />,
    //     },
    //     {
    //         name: <FormattedMessage id='salesQuote.text' defaultMessage='' />,
    //     }
    // ];

    return (

        <>
        
            <div className="txn-header">
                {/* <PageBreadcrumb breadCrumbList={breadCrumbList} /> */}
                <HeaderAction {...props} />
            </div>

            <div className='txn-body'>

                        <div className="txn-details-container">
                            <QuoteDetails paymentTerms={props.paymentTerms} {...props}/>
                        </div>
                        <Divider style={{margin: '0 0 10px 0px'}}/>
                        <div className="product-details-container">
                            <ProductDetails {...props} />
                        </div>
            </div>
        
        </>
    )
};

export default SalesInvoiceComponent;
