import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMPANY_ACTION_LIST, COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createEmployeeType = (payload, props) => {
  return dispatch => {
    // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsEmpType/saveOrUpdate`, payload)
      .then(res => {

        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })

        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getEmployeeType(payload);
        getEmployeeTypeCount(payload);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}


export const getEmployeeType = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsEmpType/getEmpTypeByPage?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}`)

      .then(response => {
        const data = {
          pageNo: payload.pageNumber,
          list: response.data
        }
        dispatch({ type: COMPANY_ACTION_LIST.EMPLOYEE_TYPE_LIST, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });

      })
      .catch(err => {

        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });

      })
  }
}

export const getEmployeeTypeCount = (payload, history) => {
  return dispatch => {
    // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsEmpType/getEmpTypeCount?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMPANY_ACTION_LIST.EMPLOYEE_TYPE_COUNT, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = COMPANY_ACTION_LIST.EMPLOYEE_TYPE_LIST_COUNT;
        }
      })
  }
}
export const deleteEmployeeType = (payload, props) => {
  return dispatch => {
    // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsEmpType/delete`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 0,
          pageSize: 25,
        }
        props.getEmployeeType(payload);
        props.getEmployeeTypeCount(payload);

      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}
export const setEmpTypeStatus = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios.patch(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/hrmsEmpType/setStatus?relationshipId=${payload.relationshipId}&empTypeId=${payload.empTypeId}&status=${(payload.status)}`,)
      .then(response => {
        showToasterMessage({ messageType: 'success', description: `Status updated` });
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 25,
        }
        props.getEmployeeType(payload);
      })
      .catch(err => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}