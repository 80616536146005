import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { showToasterMessage } from '../../../../../utils';

const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const createEmployee = (payload, props) => {
    return (dispatch) => {
      let formData = new FormData();
      if (props.fileToUpload) {
        formData.append("file", props.fileToUpload);
      }
      formData.append("employee", JSON.stringify(payload));

      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relEmployee/createEmployee`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          if (props) {
            props.updateState({
 
                employeeDrawer: false,
      
            });
            // props.fetchAllEmployeesByPage && fetchAllEmployeesByPage({ relationshipId: payload.relationshipId });
             const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25,
          }
          props.fetchAllEmployeesByPage(payload);
          props.getAllEmployeesCount(payload);

          }

          showToasterMessage({
            description: (res || {}).message || 'Created successfully.',
            messageType: 'success'
          })
         })
        .catch((err) => {
            showToasterMessage({
                messageType: "error",
                description:
                lang[((err.response || {}).data || {}).message] ||
                "Some error occurred",
            });
        });
    };
  };