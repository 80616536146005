import React from "react";
import "./style.scss";
import erpLogo from "../../../assets/images/erpIcon2.svg";
import askIcon from "../../../assets/images/askIcon.svg";
import commerceLogo from "../../../assets/images/commerceIcon.svg";
import educationLogo from "../../../assets/images/educationIcon.svg";
import crmLogo from "../../../assets/images/crmIcon.svg";
import hrmsLogo from "../../../assets/images/hrmsIcon.svg";
import checkIcon from "../../../assets/images/checkIcon.svg";
import { Button, Empty, InputNumber } from "antd";
// import { Dropdown } from "../../general/Dropdown";
import { fixedDecimalNumber, getCurrencySymbol } from "../../../utils";
import { ICONS, LIST_CONSTANTS } from "../../../static/constants";


function AddOnsComponent(props) {

    const {selectedPlan={}, addons={}, subscriptionAddons=[]} = props;

    const noOfUser = subscriptionAddons.find(obj=>obj.addonName === "numberOfUsers") || {};
    const cloudStorage = subscriptionAddons.find(obj=>obj.addonName === "cloudStorage") || {};
    const onlineTraining = subscriptionAddons.find(obj=>obj.addonName === "onlineTraining") || {};
    const support = subscriptionAddons.find(obj=>obj.addonName === "support") || {};

    const calulateSubTotal = ()=>{

        const planPrice = selectedPlan[props.priceTerm] || 0;
        const addonPrice = ((addons.users || 0) * (noOfUser.price || 0)) + ((addons.cloudStorage || 0) * (cloudStorage.price || 0)) + ((addons.trainingUsers || 0) * (onlineTraining.price || 0)) + ((addons.support || 0) * (support.price || 0))

        return planPrice+addonPrice;
    }

    function getIconFromName(name) {
        switch (name) {
            case 'Cloud ERP':
                return erpLogo;
            case 'E-Commerce':
                return commerceLogo;
            case 'Education ERP':
                return educationLogo;
            case 'CRM':
                return crmLogo;
            case 'HRMS':
                return hrmsLogo;
            default:
                return erpLogo;
        }
    }

    const productName = (props.alpideProducts || []).find(obj=>obj.alpideProductId === props.alpideProductId)?.alpideProductName || '';

    return (
        <div className="ind_component">
            <div className="headingArea">
                <div className="planPricingContaner">
                    <div className="price_page_head">
                        <h3>Addons to Power Your ERP</h3>
                        <p>Automate business operations, access real-time insights, boost control, ensure data precision, cut costs, and elevate customer service with Alpide ERP.</p>
                    </div>
                    <div className="packageTabArea">
                        <div>01. Package</div>
                        <div className="active">02. Add-on</div>
                        <div>03. Confirmation</div>
                    </div>
                </div>
            </div>

            <div className="addOnPart">
                <div className="addOn">
                    <h4>Add-ons</h4>

                    {!subscriptionAddons?.length ? <Empty description='No Addons provided'/> : ''}

                    {noOfUser.price ? 
                        <div className="countPart">
                            <div className="countHeading">
                                <div>
                                    <div className="headingName">No. Users</div>
                                    <div className="subHeading">
                                        {getCurrencySymbol(noOfUser.currencyCode || '$')}{noOfUser.price}/User/Month
                                    </div>
                                </div>
                                <div className="countPrice">
                                    {getCurrencySymbol(noOfUser.currencyCode || '$')}{noOfUser.price}
                                </div>
                            </div>
                            <div className="countBody">
                                <InputNumber 
                                    placeholder="Enter Users"
                                    value = {addons.users || 0}
                                    onChange={(num)=>{
                                        props.updateState({addons: {...addons, users: typeof num === 'number' ? num : addons.users}});
                                    }}
                                    addonAfter= {'/user'}
                                />
                                <Button
                                    onClick={()=>{
                                        props.updateState({addons : {...addons, users: addons.user > 0 ? addons.user - 1 : 0}});
                                    }}
                                >
                                    <i className={ICONS.MINUS} />
                                </Button>
                                <Button
                                    onClick={()=>{
                                        props.updateState({addons : {...addons, users: (addons.users || 0) + 1}});
                                    }}
                                >
                                    <i className={ICONS.ADD} />
                                </Button>
                            </div>
                        </div>
                        :
                        ''
                    }

                    {cloudStorage.price ?
                        <div className="countPart">
                            <div className="countHeading">
                                <div>
                                    <div className="headingName">Cloud Storage</div>
                                    <div className="subHeading">{getCurrencySymbol(cloudStorage.currencyCode || '$')}{cloudStorage.price}/GB/Month</div>
                                </div>
                                <div className="countPrice">
                                    {getCurrencySymbol(cloudStorage.currencyCode || '$')}{cloudStorage.price}
                                </div>
                            </div>
                            <div className="countBody">
                                {/* <Dropdown
                                    items={LIST_CONSTANTS.CLOUD_STORAGE_LIST}
                                    placeholder="Select Cloud Storage"
                                    value={addons.cloudStorage || ''} 
                                    optionKeyName="value"
                                    valueKeyName="label"
                                    onChange={(val)=>{
                                        props.updateState({addons : {...addons, cloudStorage: val}});
                                    }}
                                /> */}
                                <InputNumber 
                                    placeholder="Enter Cloud Storage Multiple of 5"
                                    value = {addons.cloudStorage || 0}
                                    onChange={(num)=>{
                                        props.updateState({addons: {...addons, cloudStorage: typeof num === 'number' ? num : addons.cloudStorage}});
                                    }}
                                    onBlur={(num)=>{
                                        props.updateState({addons: {...addons, cloudStorage: typeof num === 'number' ? Math.round(num / 5) * 5 : addons.cloudStorage}});
                                    }}
                                    addonAfter= {'/GB'}
                                />
                                <Button
                                    onClick={()=>{
                                        props.updateState({addons : {...addons, cloudStorage: addons.cloudStorage > 4 ? addons.cloudStorage - 5 : 0}});
                                    }}
                                >
                                    <i className={ICONS.MINUS} />
                                </Button>
                                <Button
                                    onClick={()=>{
                                        props.updateState({addons : {...addons, cloudStorage: (addons.cloudStorage || 0) + 5}});
                                    }}
                                >
                                    <i className={ICONS.ADD} />
                                </Button>
                            </div>
                        </div>
                        :
                        ''
                    }

                    {onlineTraining.price ?
                        <div className="countPart">
                            <div className="countHeading">
                                <div>
                                    <div className="headingName">Online Training</div>
                                    <div className="subHeading">
                                        {getCurrencySymbol(onlineTraining.currencyCode || '$')}{onlineTraining.price}/User/Month
                                    </div>
                                </div>
                                <div className="countPrice">
                                    {getCurrencySymbol(onlineTraining.currencyCode || '$')}{onlineTraining.price}
                                </div>
                            </div>
                            <div className="countBody">
                                <InputNumber 
                                    placeholder="Enter Users"
                                    value = {addons.trainingUsers || 0}
                                    onChange={(num)=>{
                                        props.updateState({addons: {...addons, trainingUsers: typeof num === 'number' ? num : addons.trainingUsers}});
                                    }}
                                />
                                <Button
                                    onClick={()=>{
                                        props.updateState({addons : {...addons, trainingUsers: addons.trainingUsers > 0 ? addons.trainingUsers - 1 : 0}});
                                    }}
                                >
                                    <i className={ICONS.MINUS} />
                                </Button>
                                <Button
                                    onClick={()=>{
                                        props.updateState({addons : {...addons, trainingUsers: (addons.trainingUsers || 0) + 1}});
                                    }}
                                >
                                    <i className={ICONS.ADD} />
                                </Button>
                            </div>
                        </div>
                        :
                        ''
                    }

                    {support.price ? 
                        <div className="countPart">
                            <div className="countHeading">
                                <div>
                                    <div className="headingName">Support</div>
                                    <div className="subHeading">{getCurrencySymbol(support.currencyCode || '$')}{support.price}/Month</div>
                                </div>
                                <div className="countPrice">
                                    {getCurrencySymbol(support.currencyCode || '$')}{support.price}
                                </div>
                            </div>
                            <div className="countBody">
                                {/* <Dropdown
                                    items={LIST_CONSTANTS.SUPPORT_MONTHS}
                                    placeholder="Select"
                                    value={addons.support || ''} 
                                    optionKeyName="value"
                                    valueKeyName="label"
                                    onChange={(val)=>{
                                        props.updateState({addons : {...addons, support: val}});
                                    }}
                                /> */}

                                <InputNumber 
                                    placeholder="Enter Months"
                                    value = {addons.support || 0}
                                    onChange={(num)=>{
                                        props.updateState({addons: {...addons, support: typeof num === 'number' ? num : addons.support}});
                                    }}
                                />
                                <Button
                                    onClick={()=>{
                                        props.updateState({addons : {...addons, support: addons.support > 0 ? addons.support - 1 : 0}});
                                    }}
                                >
                                    <i className={ICONS.MINUS} />
                                </Button>
                                <Button
                                    onClick={()=>{
                                        props.updateState({addons : {...addons, support: (addons.support || 0) + 1}});
                                    }}
                                >
                                    <i className={ICONS.ADD} />
                                </Button>
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>



                <div className="summary">
                    <div>
                        <h4>Summary</h4>

                        <div className="summaryPart">
                            <div className="productDetail">
                                <div className="productName">
                                    <img src={getIconFromName(productName)} alt="" height='25px' width='25px' />
                                    <div>
                                        <h5>{productName || "Cloud ERP"} - {selectedPlan.planName}</h5>
                                        {
                                            props.priceTerm === 'annualPrice' ? 
                                                <h6>{getCurrencySymbol(selectedPlan.currencyCode)}{fixedDecimalNumber(selectedPlan.annualPrice/12)}/Organization/Month<br/>(Billed Annually)</h6> 
                                                :
                                                <h6>{getCurrencySymbol(selectedPlan.currencyCode)}{selectedPlan.monthPrice}/Organization/Month<br/>(Billed Monthly)</h6>
                                        }
                                    </div>
                                </div>
                                <div className="productPrice">
                                    <div className="price">{getCurrencySymbol(selectedPlan.currencyCode)}{selectedPlan[props.priceTerm]}</div>
                                    <div className="maxPrice">{selectedPlan.annualMrp}</div>
                                </div>
                            </div>

                            <div className="includeArea">
                                <div className="productName">
                                    <img src={askIcon} alt="" />
                                    <div>
                                        <h5>Whats Included</h5>
                                    </div>
                                </div>
                                <div className="listItem">
                                    <ul>
                                        <li>
                                            <img src={checkIcon} alt="" />
                                            No. of Users {selectedPlan.numberOfUsers || 0}
                                        </li>
                                        <li>
                                            <img src={checkIcon} alt="" />
                                            {selectedPlan.cloudStorage} Cloud Storage
                                        </li>
                                        <li>
                                            <img src={checkIcon} alt="" />
                                            1200 SMS Credit for 12 months
                                        </li>
                                        <li>
                                            <img src={checkIcon} alt="" />
                                            Email + Chat Customer Support
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>

                            <div className="addOnPart">
                                <div className="productName">
                                    <img src={askIcon} alt="" />
                                    <div>
                                        <h5>New Addons</h5>
                                    </div>
                                </div>
                                <div className="listItem">
                                    <ul>
                                        {addons.users ?
                                            <li>
                                                <div className="listName">
                                                    <img src={checkIcon} alt=">" />
                                                    No. of Users {addons.users}
                                                </div>
                                                <div className="price">{getCurrencySymbol(selectedPlan.currencyCode)}{addons.users * noOfUser.price}</div>
                                            </li>
                                            :
                                            ''
                                        }
                                        {addons.cloudStorage ?
                                            <li>
                                                <div className="listName">
                                                    <img src={checkIcon} alt=">" />
                                                    Cloud Storage - {addons.cloudStorage}GB
                                                </div>
                                                <div className="price">{getCurrencySymbol(selectedPlan.currencyCode)}{addons.cloudStorage * cloudStorage.price}</div>
                                            </li>
                                            :
                                            ''
                                        }
                                        {addons.trainingUsers ?
                                            <li>
                                                <div className="listName">
                                                    <img src={checkIcon} alt="?" />
                                                    Online Training - {addons.trainingUsers} Users
                                                </div>
                                                <div className="price">{getCurrencySymbol(selectedPlan.currencyCode)}{ addons.trainingUsers * onlineTraining.price }</div>
                                            </li>
                                            :
                                            ''
                                        }
                                        {addons.support ?
                                            <li>
                                                <div className="listName">
                                                    <img src={checkIcon} alt=">" />
                                                    Support
                                                </div>
                                                <div className="price">{getCurrencySymbol(selectedPlan.currencyCode)}{addons.support * support.price}</div>
                                            </li>
                                            :
                                            ''
                                        }
                                        {!Object.keys(addons)?.length ?
                                            <li>
                                                <div className="listName">
                                                    No add-on selected
                                                </div>
                                            </li>
                                            :
                                            ''
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="totalPricePart">
                        <div className="totalPriceArea">
                            <div>Subtotal</div>
                            <div>{getCurrencySymbol(selectedPlan.currencyCode)}{calulateSubTotal()}</div>
                        </div>
                        <div className="totalPriceArea">
                            <div>Tax (18%)</div>
                            <div>{getCurrencySymbol(selectedPlan.currencyCode)}{fixedDecimalNumber(calulateSubTotal() * 0.18)}</div>
                        </div>
                        <div className="totalPriceArea">
                            <div><span>Total</span></div>
                            <div><span>{getCurrencySymbol(selectedPlan.currencyCode)}{fixedDecimalNumber(calulateSubTotal()*1.18)}</span></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footerPricing">
                <div className="planPricingContaner">
                    <div className="footerButton">
                        <Button className="skip-default mr20" onClick={()=>{props.updateState({activeTab : props.activeTab <= 1 ? props.activeTab+1 : props.activeTab})}}>Skip For Now</Button>
                        <Button type="primary" onClick={()=>{props.updateState({activeTab : props.activeTab <= 1 ? props.activeTab+1 : props.activeTab})}}>Proceed to Checkout</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddOnsComponent