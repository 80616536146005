import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMPANY_ACTION_LIST, COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const getAllBranches = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/warehouseMaster/getAllBranches?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1) - 1}`)

      .then(response => {
        const data = {
          pageNo: payload.pageNumber,
          list: response.data
        }
        dispatch({ type: COMPANY_ACTION_LIST.BRANCH_LIST, data });
      })
      .catch(err => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      })
  }
}

export const countTotalBranch = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/warehouseMaster/countBranches?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: COMPANY_ACTION_LIST.BRANCH_COUNT, data: res.data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      })
  }
}

export const deleteBranch = (payload, props) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextHRMS}/warehouseMaster/deleteWarehouse`, payload)
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
        props.getAllBranches({
          relationshipId: props.companyInfo.relationshipId,
          pageNumber: props.pageNumber || 1,
          pageSize: props.pageSize || 25,
        })
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}
