import React from "react";
import { AgGridReact } from "ag-grid-react";
import { Pagination } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";

import { AG_GRID_CLASS_CONSTANTS } from "../../../../../static/cssClassConstants";
import { fetchPaginationDataIfNeeded } from "../../../../../utils";

const OutOfStockComponent = (props) => {
  const {
    outOfStockList,
    outOfStockCount,
    companyInfo,
    updateState,
    pageNumber,
  } = props;
  
  const agGridStyle = {
    height: "100%",
    width: "100%",
  };

  // const getRowHeight = () => {
  //     return 28;
  // }

  const onFirstDataRendered = (params) => {
    //params.api.sizeColumnsToFit();
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchOutOfStockItems",
      "outOfStockList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchOutOfStockItems(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const columnDefs = [
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="product.listing.outOfStock.header.productDetails"
            defaultMessage=""
          />
        );
      },
      field: "itemName",
      rowDrag: true,
      resizable: true,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="product.listing.outOfStock.header.variant"
            defaultMessage=""
          />
        );
      },
      field: "variantName",
      resizable: true,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="product.listing.outOfStock.header.sku"
            defaultMessage=""
          />
        );
      },
      field: "sku",
      resizable: true,
      cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="product.listing.outOfStock.header.category"
            defaultMessage=""
          />
        );
      },
      field: "categoryName",
      resizable: true,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="product.listing.outOfStock.header.warehouse"
            defaultMessage=""
          />
        );
      },
      field: "warehouseName",
      resizable: true,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="product.listing.outOfStock.header.currentStock"
            defaultMessage=""
          />
        );
      },
      field: "currentStock",
      resizable: true,
    },
  ];

  return (
    <>
      {/* <div className='agGridWrapper'> */}
      {/* <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={outOfStockList[pageNumber] || []}
                    rowDragManaged={true}
                    domLayout={"autoHeight"}
                    animateRows={true}
                    onColumnResized ={(params)=>{
                        params.api.resetRowHeights();
                    }}
                    defaultColDef={{
                        flex:1,
                        autoHeight: true,
                        wrapText: true,
                    }}
                    //getRowHeight={getRowHeight}
                    onGridSizeChanged={onFirstDataRendered}
                >
                </AgGridReact>

            </div> */}

      <div className="table-container-tile">
        <table id="product-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id="product.listing.outOfStock.header.productDetails"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="product.listing.outOfStock.header.variant"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="product.listing.outOfStock.header.sku"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="product.listing.outOfStock.header.category"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="product.listing.outOfStock.header.warehouse"
                  defaultMessage=""
                />
              </th>
              <th width="6%">
                <FormattedMessage
                  id="product.listing.outOfStock.header.currentStock"
                  defaultMessage=""
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {(outOfStockList[pageNumber] || []).length
              ? (outOfStockList[pageNumber] || []).map((rowData, i) => {
                  return (
                    <tr key={i}>
                      <td>{rowData.itemName}</td>
                      <td>{rowData.variantName}</td>
                      <td>{rowData.sku}</td>
                      <td>{rowData.categoryName}</td>
                      <td>{rowData.warehouseName}</td>
                      <td>{rowData.currentStock}</td>
                    </tr>
                  );
                })
              : "No data"}
          </tbody>
        </table>
      </div>
      <Pagination
        size="small"
        total={outOfStockCount}
        showTotal={showTotal}
        defaultPageSize={100}
        showSizeChanger
        pageSizeOptions={[10, 25, 50, 100, 200]}
        // showQuickJumper
        onChange={(pageNumber, pageSize) => {
          loadPageData(pageNumber, pageSize);
          updateState({ pageSize: pageSize, pageNumber: pageNumber });
        }}
        onShowSizeChange={(pageNumber, pageSize) => {
          handlePageSizeChange(pageNumber, pageSize, props);
        }}
      />
    </>
  );
};

export default injectIntl(OutOfStockComponent);
