import { KIOSK_ACTION_LIST } from "../static/constants";

const initialState = {
    kioskList: [],
    
};

const KioskReducer = (state = initialState, action) => {
    switch (action.type) {
        case KIOSK_ACTION_LIST.KIOSK_LIST:
            return {
                ...state,
                kioskList: action.data,
            };
       
        default:
            return state;
    }
};

export default KioskReducer;
