import React from "react";
import "../../../../eStore/storeSettings/store-settings.scss";
import { Col, Row, Switch, Select,Input } from "antd";
import { ICONS } from "../../../../../static/constants";
import { TextBox } from "../../../../general/TextBox";
import { Dropdown } from "../../../../general/Dropdown";
import { getCurrencySymbol } from "../../../../../utils";
import CustomSwitch from "../../../../general/CustomSwitch";
const rowGutter = [12, 12];
const { Option } = Select;

const VariableCharge = (props) => {
  const icon = props.companyInfo;
  const priceRange = props.deliveryValue.chargesInfo;


  const AddRows = () => {
    const val = priceRange;
    const value = val.slice(-1);
    value[0].starting = parseInt(val[val?.length - 2]?.ending) + 500;
    value[0].id = priceRange.length + 1;
    val.pop();
  
    const arr = [
      ...val,
      {
        id: priceRange.length + 1,
        starting: parseInt(priceRange[priceRange.length - 1]?.ending),
        ending: parseInt(priceRange[priceRange.length - 1]?.ending) + 500,
        amount: 0,
      },
      ...value,
    ];
    let tempFilter = props.deliveryValue;
    tempFilter.chargesInfo = arr;
    props.updateState({ deliveryValue: tempFilter });
  };

  const deleteRow = (id) => {
    const filterValue = priceRange.filter((row) => row?.id !== id);

    filterValue[filterValue.length - 1].starting =
      filterValue[filterValue.length - 2].ending;
    let tempFilter = props.deliveryValue;
    tempFilter.chargesInfo = filterValue;
    props.updateState({ deliveryValue: tempFilter });
  };

  let weightSymbol;
  const AddRowsInBetween = (index) => {
    const object = {
      id: index + 1,
      starting: 1000,
      ending: 1500,
      amount: 0,
    };
    const val = priceRange;

    val.splice(index + 1, 0, object);
    for (let i = index + 1; i < val.length; i++) {
      val[i].id++;
      val[i].starting = parseInt(val[i].starting) + 500;
      val[i].ending =  parseInt(val[i].ending) + 500;
    }
    let tempFilter = props.deliveryValue;
    tempFilter.chargesInfo = [...val];
    props.updateState({ deliveryValue: tempFilter });
    //  setPriceRange(priceRange)
  };

  switch (props.deliveryValue?.weightUnit) {
    case "Kilograms":
      weightSymbol = "Kg";
      break;
    case "Grams":
      weightSymbol = "gm";
      break;
    case "Pounds":
      weightSymbol = "lb";
      break;
    case "Ounce":
      weightSymbol = "Oz";
      break;
    default:
  }

  const handleStartChange = (id, value, item) => {
    const val = props.deliveryValue;
    let tempFilter = val;
    const arr = tempFilter.chargesInfo;

    let value1;
    if (item === "starting") {
      value1 = arr.map((item) =>
        item.id === id ? { ...item, starting: value } : item
      );
    }
    if (item === "ending") {
      value1 = arr.map((item) =>
        item.id === id ? { ...item, ending: value } : item
      );
    }
    if (item === "amount") {
      value1 = arr.map((item) =>
        item.id === id ? { ...item, amount: value } : item
      );
    }

    tempFilter.chargesInfo = value1;
    props.updateState({ deliveryValue: tempFilter });
  };

  // const selectAfter = (
  //   <Select defaultValue=".com">
  //     <Option value="Flat">Flat</Option>
  //     <Option value="per weight">per {weightSymbol}</Option>

  //   </Select>
  // );
 
  return (
    <div>
      <Row className="" style={{ padding: "10px 0px" }}>
        <Col span={6}>
          {!props.deliveryValue?.byCurrency ? (
            <Dropdown
              items={["Kilograms", "Grams", "Pounds", "Ounce"]}
              placeholder="Select Weight"
              value={props.deliveryValue?.weightUnit}
              disabled={props.deliveryValue?.isDeliveryCharge ? false : true}
              onSelect={(selectedValue, option) => {
                const val = props.deliveryValue;
                let tempFilter = val;
                tempFilter.weightUnit = selectedValue;
                props.updateState({ deliveryValue: tempFilter });
              }}
              allowClear={true}
              // onClear={() => {
              //   this.props.updateState({
              //     priority: undefined,
              //   });
              // }}
            ></Dropdown>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <div className="variable-charge">
            <Row gutter={[20,5]}>
              <Col span={7}>
                Starting From <span className="required">*</span>
              </Col>
              {"  "}
              <Col span={7}>
                Ending Before <span className="required">*</span>
              </Col>
              {" "}
              <Col span={7}>
                Delivery Charge <span className="required">*</span>
              </Col>
            </Row>

            {priceRange.map((row, i) => (
              <Row gutter={rowGutter} key={i}>
                <Col span={7}>
                 <div>
                 <TextBox
                    placeholder=""
                    type="number"
                    disabled={
                      !props.deliveryValue?.isDeliveryCharge || i === 0
                        ? true
                        : false
                    }
                    prefix={
                      !props.deliveryValue?.byCurrency
                        ? weightSymbol
                        : getCurrencySymbol(icon?.currencyCode)
                    }
                    required={true}
                    value={i === 0 ? 0 : row.starting}
                    onChange={(e) =>
                      handleStartChange(
                        row.id,
                        e.target.value !== isNaN() ? (e.target.value) : 0,
                        "starting"
                      )
                    }
                  />
                  <div style={{color:"red"}}>
                  {i!==0 && ((props.variableError.includes(i) && !row.starting ? props.variableErrorMessage:"") ||(props.variableError2.includes(i) ?props.variableErrorMessage2:""))}
                  </div>
                    
                 </div>
                  
                </Col>
                <Col span={7}>
                <div>
                <TextBox
                    placeholder=""
                    type={(i === priceRange.length - 1)? "text" : "number"}
                    prefix={
                      !props.deliveryValue?.byCurrency
                        ? weightSymbol
                        : getCurrencySymbol(icon?.currencyCode)
                    }
                    disabled={
                      !props.deliveryValue?.isDeliveryCharge ||
                      i === priceRange.length - 1
                        ? true
                        : false
                    }
                    required={true}
                    value={i === priceRange.length - 1 ? "& Above" : row.ending}
                    onChange={(e) =>
                      handleStartChange(
                        row.id,
                       e.target.value !== isNaN() ? (e.target.value) : 0,
                        "ending"
                      )
                    }
                  />
                                    <div style={{color:"red"}}>

                    {i!==priceRange.length-1 && ((props.variableError.includes(i) && !row.ending ? props.variableErrorMessage:"")||(props.errorEnding && props.errorEnding === i ? 'This value should be greater than starting value':''))}
                    </div>
                </div>
                
                </Col>
                <Col span={7}>
                <div>
                <Input
                prefix={getCurrencySymbol(icon?.currencyCode)}
                placeholder={'Amount'}
                style={{ height: '38px' }}
                type="number"
                
                value={row?.amount ? row?.amount : ""}
                    onChange={(e) =>
                      handleStartChange(
                        row.id,
                        e.target.value !== isNaN() ? (e.target.value) : 0,
                        "amount"
                      )
                    }
                // suffix ={
                //   !(props.deliveryValue?.byCurrency) ?   <div className="profit-toggle">
                //   <CustomSwitch
                //     // checked={true}
                //     checkedChildren="elo" 
                //     unCheckedChildren="helllooo"
                //     onClick={(checked, e) => {

                //       props.updateState({
                //         helooo: checked ? 1 : 0,
                //       });
                //     }}
                //   />
                  
                // </div> :''
                
                // }
              />
                {/* <TextBox
                    placeholder="Amount"
                    type="number"
                    prefix={getCurrencySymbol(icon?.currencyCode)}
                    maxLength={100}
                    disabled={
                      props.deliveryValue?.isDeliveryCharge ? false : true
                    }
                    //  addonAfter={selectAfter}
                    required={true}
                    value={row?.amount ? row?.amount : ""}
                    onChange={(e) =>
                      handleStartChange(
                        row.id,
                        e.target.value !== isNaN() ? (e.target.value) : 0,
                        "amount"
                      )
                    }
                  /> */}
                                    <div style={{color:"red"}}>

                    {(props.variableError.includes(i) && !row.amount ? props.variableErrorMessage:"")}
                    </div>
                </div>
                 
                </Col>
              
                {/* {
                !props?.deliveryValue.byCurrency ? <Col span ={2}> <Switch
                checked={props.deliveryValue?.isDeliveryCharge}
                onClick={(checked, e) => {
                  const val = props.deliveryValue;
                  let tempFilter = val;
                  // tempFilter.isDeliveryCharge =
                  //   tempFilter.isDeliveryCharge === 1 ? 0 : 1;
                  props.updateState({ deliveryValue: tempFilter });
                }}
              /></Col>:''
              } */}

                {!(i === 0 || i === priceRange.length - 1) ? (
                  <Col span={2}>
                    <button
                      className="editRow"
                      disabled={
                        props.deliveryValue?.isDeliveryCharge ? false : true
                      }
                      style={{ marginRight: "20px" }}
                      onClick={() => AddRowsInBetween(i)}
                    >
                      {" "}
                      <i className={ICONS.ADD} />
                    </button>
                    <button
                      className="editRow"
                      disabled={
                        props.deliveryValue?.isDeliveryCharge ? false : true
                      }
                      onClick={() => deleteRow(row.id)}
                    >
                      {" "}
                      <i className={ICONS.DELETE} />
                    </button>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            ))}

         
            <button
              className="addRow"
              disabled={props.deliveryValue?.isDeliveryCharge ? false : true}
              onClick={AddRows}
            >
              + ADD ANOTHER PRICE RANGE
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VariableCharge;
