import { INTEGRATION } from '../static/constants';
const initialState = {
  plaidLinkToken: "",
  plaidLinkedAccounts: [],
  linkedMarketplaceList: [],
  linkedShipStationList: [],
  linkedEasyPostList: [],
  brevoSettings:[]
}

const IntegrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTEGRATION.PLAID_LINK_TOKEN:
      return { ...state, plaidLinkToken: action.data };
    case INTEGRATION.PLAID_LINKED_ACCOUNT:
      return { ...state, plaidLinkedAccounts: action.data };
    case INTEGRATION.LINKED_MARKETPLACE_LIST:
      return { ...state, linkedMarketplaceList: action.data };
    case INTEGRATION.RESET_LINKED_MARKETPLACE_LIST:
      return { ...state, linkedMarketplaceList: initialState.linkedMarketplaceList };
    case INTEGRATION.LINKED_SHIP_STATIONS:
      return { ...state, linkedShipStationList: action.data };
      case INTEGRATION.LINKED_EASY_POST:
        return { ...state, linkedEasyPostList: action.data };
        case  INTEGRATION.BREVO_SETTINGS:
          return { ...state, brevoSettings: action.data };
    default:
      return state;
  }
};

export default IntegrationReducer;