import React from "react";
// import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./index.scss";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

// const stripePromise = loadStripe("pk_test_51IuyTySClO343iKMkAuxQR9MT9shvvc1zd0iVaZwKm3cDCsW3rDU1kdSYwm5w4BZeFIjTxPpXTVhRAAKvqULnkp10063BxWsOm", {
//   stripeAccount: 'acct_1IuyTySClO343iKM',
// });
export default function App(props) {
  // const [clientSecret, setClientSecret] = useState('');
  const { stripePromise} = props;
  

  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   fetch("http://localhost:8080//alpide-stripe/stripePayment/stripePayment?relationshipId=54&alpidePaymentGatewayId=48&amount=300", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
  //   })
  //     .then((res) => res.json())
  //     .then((data) => setClientSecret(data.clientSecret));
  // }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#0a3761',
      colorBackground: '#f9f5f5',
      colorText: '#b5b7c9',
    },
  };
  const options = {
    clientSecret: props.clientSecret,
    appearance,
  };
  const InjectedCheckoutForm = () => (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );

  return (
    <div className="App">
      {props.clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <InjectedCheckoutForm />
        </Elements>
      )}
    </div>
  );
}