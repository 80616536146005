import { Col, Drawer, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../general/CustomButton";
import { TextBox } from "../../general/TextBox";

const LeadSubSourceDrawer = (props) => {
  const rowGutter = [24, 16];
  const closeDrawer = () => {
    props.updateState({
      leadSubSourceCreateEditDrawer: false,
      leadSubSourceEditDrawerValue: null,
      leadSubSourceEditData : null
    });
  };


  return (
    <>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage id={props.leadSubSourceEditDrawerValue ? "leads.leadForm.formMeta.leadSubSource.edit.drawer.title.edit" : "leads.leadForm.formMeta.leadSubSource.edit.drawer.title.create"} />
          </div>
        }
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.leadSubSourceCreateEditDrawer}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />

            <CustomButton
              intlId="button.save.label"
              defaultMessage=""
              htmlType="submit"
              onClick={()=>{}}
              key={1}
            />
          </>
        }
      >
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">Sub Source Name</div>
            <TextBox
              placeholder="Sub Source Name"
              type="text"
              maxLength={20}
              value={props.leadSubSourceEditData?.sub_source_name}
              onChange={(e) => {
                props.updateState({ selectedRfqNum: e.target.value });
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Sub Source Code</div>
            <TextBox
              placeholder="Sub Source Code"
              type="text"
              maxLength={20}
              value={props.leadSubSourceEditData?.sub_source_code}
              onChange={(e) => {
                props.updateState({ selectedRfqNum: e.target.value });
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default LeadSubSourceDrawer;
