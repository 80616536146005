/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {  Button } from 'antd';
import ContactIcon from '../../../../assets/images/contact.jpg';
import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { MODAL_TYPE } from "../../../../static/constants";
const EditableContext = React.createContext();

class ShowContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editingKey: '' };
    }

    render() {
        const handleNewContactAddition = (contactData) => {
            const { pushModalToStack, companyInfo } = this.props;
            let payload = {};
            let formData = {
                supplierId: this.props.supplierId,
                contactData
                // pageNumber: pageNumber || 1,
                // relationshipId: companyInfo.relationshipId
            }
            payload.formData = formData;
            const data = {
                title: <FormattedMessage id='addItem.text.contact' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: {
                    modalType: MODAL_TYPE.CONTACT,
                    data: payload,
    
                },
                handleSubmit: (formData = {}) => {
                    data.formData.submittedOnce = true;
                    setTimeout(() => {
                        this.props.fetchAllContacts({relationshipId: companyInfo.relationshipId, supplierId: this.props.supplierId});
                    }, 2000);
                }
            };
            pushModalToStack(data);
        }

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.listing.showContact.gridHeader.primaryContact' defaultMessage='' />;
                },
                field: "isPrimaryContact",
                resizable: true,
                editable: true,
                width: 100,
                cellRendererFramework: function (link) {
                    return <div>
                        {link.value ? "Yes" : "No"}
                    </div>
                },
                cellEditor: 'customCheckboxEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value ? 1 :0);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.listing.showContact.gridHeader.title' defaultMessage='' />;
                },
                field: "salutationName",
                resizable: true,
                editable: true,
                cellEditor: 'customDropDownEditor',
                width: 150,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.salutations,
                        optionKeyName: 'salutationId',
                        valueKeyName: 'titleName',
                        onSelect: (selectedValue) => {
                            obj.node.setDataValue(obj.colDef.field, selectedValue);
                        },
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.listing.showContact.gridHeader.firstName' defaultMessage='' />;
                },
                field: "firstName",
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.listing.showContact.gridHeader.lastName' defaultMessage='' />;
                },
                field: "lastName",
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='supplier.listing.showContact.gridHeader.callName' defaultMessage='' />;
            //     },
            //     field: "preferCallName",
            //     resizable: true,
            //     editable: true,
            //     cellEditor: 'customTextEditor',
            //     suppressKeyboardEvent: (params) => {
            //         const KEY_ENTER = 13;
            //         const keyCode = params.event.keyCode;
            //         if(keyCode === 9){
            //             params.api.tabToNextCell(true);
            //             return true;
            //         }
            //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //         return gridShouldDoNothing;
            //     },
            //     cellEditorParams: (obj) => {
            //         return {
            //             onChange: (value) => {
            //                 obj.node.setDataValue(obj.colDef.field, value);
            //             }
            //         }
            //     }
            // },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.listing.showContact.gridHeader.workPhone' defaultMessage='' />;
                },
                field: "workPhone",
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.listing.showContact.gridHeader.mobile' defaultMessage='' />;
                },
                field: "cellPhone",
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.listing.showContact.gridHeader.faxNumber' defaultMessage='' />;
                },
                field: "faxNumber",
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        params.api.tabToNextCell(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.listing.showContact.gridHeader.email' defaultMessage='' />;
                },
                field: "emailAddress",
                resizable: true,
                editable: true,
                cellEditor: 'customTextEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if(keyCode === 9){
                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                        this.gridApi.refreshCells({ force: true });
                        params.api.stopEditing(true);
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                field: 'action',  lockPosition: true, pinned: 'right',
                width: 180,
                cellRendererFramework: (params) => <div>
                    {(params.data.origination !== 'System') ? <div>
                        <div>
                            {params.node.data.isToEdit &&
                                <div className="table-cell-action">
                                    <div className='cursor-pointer' onClick={() => {
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.relationshipId = (this.props.companyInfo || {}).relationshipId
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        addUpdateContact(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data)
                                        params.api.stopEditing(true);
                                        this.gridApi.refreshCells({ force: true });
                                    }} >
                                        <i className="fa fa-save" />
                                    </div>
                                    <div className='cursor-pointer' onClick={() => {
                                        params.api.stopEditing(true);
                                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                        this.gridApi.refreshCells({ force: true });
                                    }} >
                                        <i className="fa fa-ban" />
                                    </div>
                                </div>
                            }

                            {!params.node.data.isToEdit &&
                                <div className="table-cell-action" >
                                    <span className='cursor-pointer' onClick={() => {
                                        handleNewContactAddition(params.node.data);
                                        // this.gridApi.forEachNode((rowNode, index) => {
                                        //     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                        // });
                                        // this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                                        // this.gridApi.refreshCells({ columns: ["action"], force: true });
                                        // var startEditingParams = {
                                        //     rowIndex: params.node.rowIndex,
                                        //     colKey: params.column.getId(),
                                        // };
                                        // params.api.startEditingCell(startEditingParams);
                                    }}>
                                        <i className="fa fa-edit" />
                                    </span>
                                    <span className='cursor-pointer' onClick={() => {
                                         const modalData = {
                                            modalBody: <FormattedMessage
                                                id='supplier.address.delete.confirmation'
                                                defaultMessage=''
                                            />,
                                            handleSubmit: () => {
                                                deleteContact(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data);
                                            }
                                        };
                                        this.props.pushModalToStack(modalData);
                                    }}>
                                        <i className="fa fa-remove" />
                                    </span>
                                </div>}
                        </div>
                    </div>
                        : <div></div>
                    }
                </div>
            }
        ]

        
        const getRowHeight = (params) => {
            return 30;
        }

        const onGridReady = (params) => {
            params.api.sizeColumnsToFit();
            this.gridApi = params.api;
        }

        const contactData  = this.props.contacts.map((contactObj, index) => {
            return {
                sNo: index + 1,
                contactId: contactObj.contactId,
                version: contactObj.version,
                isPrimaryContact: contactObj.isPrimaryContact,
                salutationName: contactObj.salutationName,
                firstName: contactObj.firstName,
                middleName: contactObj.middleName,
                lastName: contactObj.lastName,
                jobTitle: contactObj.jobTitle,
                department: contactObj.department,
                workPhone: contactObj.workPhone,
                cellPhone: contactObj.cellPhone,
                emailAddress: contactObj.emailAddress,
                preferCallName: contactObj.preferCallName,
                faxNumber: contactObj.faxNumber
            }
        });

        

        return <div>
            {(this.props.contacts && this.props.contacts.length > 0) ?
                <EditableContext.Provider value={this.props.form}>
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        rowData={contactData || []}
                        rowDragManaged={true}
                        disableFocusOnClickOutside={true}
                        suppressClickEdit={true}
                        noSingleClickEdit={true}
                        domLayout={"autoHeight"}
                        animateRows={true}
                        defaultColDef={{
                            flex: 1,
                            autoHeight: true,
                            wrapText: true,
                        }}
                        getRowHeight={getRowHeight}
                        editType={'fullRow'}
                        onGridReady={onGridReady}
                    >
                    </CustomAgGrid>
                    <Button type="default" style={{ marginBottom: 16, marginTop: 20 }}
                        onClick={() => { 
                            // let newRowIndex = -1;
                            // this.gridApi.forEachNode((rowNode, index) =>{
                            //     newRowIndex = index;
                            //     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;                                    
                            // });
                            // this.gridApi.stopEditing();
                            // this.gridApi.updateRowData({ add: [{isToEdit: true}] });
                            // this.gridApi.startEditingCell({
                            //     rowIndex: newRowIndex+1,
                            //     colKey: "isPrimaryContact"
                            // });
                            // this.gridApi.refreshCells({ force: true });
                            handleNewContactAddition();
                        }}>
                       <FormattedMessage id='addRow' defaultMessage='' /> 
                </Button>
                </EditableContext.Provider>
                : <div className="create-data-container contact-first-modal">
                    <h4 className="progressbar-modal-heading"><FormattedMessage id='contact'/> <FormattedMessage id='does.exist' defaultMessage='' />
                    <div className='cursor-pointer' onClick={() => { this.props.addContactRow(0); }} >
                            <span> </span> <FormattedMessage id='clickhere' defaultMessage='' /> <span> </span>
                        </div>
                        <FormattedMessage id='to.create.contact' defaultMessage='' />
            </h4>
                    <img src={ContactIcon} alt="Invoice" height="275" />
                </div>
            }
        </div>
    }
}

const EditableAddressTable = Form.create()(ShowContact);

const deleteContact = (props, record) => {
            const payload = {
                contactId: record.contactId,
                relationshipId: props.companyInfo.relationshipId,
                supplierId: props.supplierId,
                isPrimaryContact: record.isPrimaryContact
            };
            props.deleteContact(payload);
            //props.resetPaginatedData();
            //props.fetchSuppliers({ relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize });
        
}

const addUpdateContact = (props, payload) => {
    payload.supplierId = props.supplierId;
    props.createUpdateContact(payload);
    //props.resetPaginatedData();
    //props.fetchSuppliers({ relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize });
}

export default EditableAddressTable;
