import React, {useState} from "react";
import { Drawer, Input, Row, Col, Button, Checkbox } from "antd";
import { FormattedMessage } from "react-intl";
import { CRM_COMPONENT_TYPES, ICONS } from "../../../static/constants";


const CompEditDrawer = (props) =>{
    
    const { formComponents=[], drawerOpen, setDrawerOpen, selectedComp, setParentFormComp } = props;
    const hoveredId = drawerOpen;
    const [fieldComp, setFieldComp] = useState(selectedComp || {});

    const idx = formComponents.findIndex(obj => obj.id === hoveredId);

    return <Drawer
        title={"Edit Component Properties"}
        placement="right"
        closable={true}
        width={500}
        onClose={() => {setDrawerOpen(0)}}
        visible={ drawerOpen }
        footer={<Row justify="end">
            <Button
                type="primary"
                onClick={() => {
                    let tempArr= formComponents || [];
                    tempArr[idx] = fieldComp;
                    setParentFormComp(tempArr);
                    setDrawerOpen(0);
                }}
            >
                <FormattedMessage id="lead.save.button" defaultMessage="" />
            </Button>
        </Row>}
        >
        <>
            <b>Display Label</b>

            <Input
                placeholder="Edit label..."
                value={fieldComp?.label || ""}
                onChange={(e) => {
                    setFieldComp({...fieldComp, label: e.target.value });
                }}
            />

            {!(fieldComp.type === CRM_COMPONENT_TYPES.MULTIPLE_CHOICE ||
            fieldComp.type === CRM_COMPONENT_TYPES.CHECKBOX) ? <>
                <div className="mt20" />
                <b>Display Placeholder</b>
                <Input
                    placeholder="Edit placeholder..."
                    value={fieldComp?.placeholder || ""}
                    onChange={(e) => {
                        setFieldComp({...fieldComp, placeholder: e.target.value });
                    }}
                />
            </>:''}

            <div className="req-checkbox">
                <Checkbox
                    checked={fieldComp.isRequired || false}
                    onChange={(e) => {
                        setFieldComp({...fieldComp, isRequired: e.target.checked});
                    }}
                >
                    Required
                </Checkbox>
            </div>

            {(fieldComp.type === CRM_COMPONENT_TYPES.DROPDOWN ||
            fieldComp.type === CRM_COMPONENT_TYPES.MULTIPLE_CHOICE ||
            fieldComp.type === CRM_COMPONENT_TYPES.CHECKBOX) ? (
                <div className="add-options">
                    {(fieldComp.options || [{}]).map((item, index) => (
                        <Row gutter={[16, 16]} key={index}>
                            <Col span={11}>
                                <label className="add-option-label">Option Value {index+1}</label>
                                <Input
                                    value={item.value}
                                    onChange={(e) =>{
                                        let tempObj = item || {};
                                        tempObj = {...tempObj, value:e.target.value}
                                        let tempOpt = fieldComp.options || [];
                                        tempOpt[index] = tempObj;
                                        setFieldComp({...fieldComp, options: tempOpt})
                                    }}
                                />
                            </Col>
                            <Col span={11}>
                                <label className="add-option-label">Option Key {index+1}</label>
                                <Input
                                    value={item.key}
                                    onChange={(e) =>{
                                        let tempObj = item || {};
                                        tempObj = {...tempObj, key:e.target.value}
                                        let tempOpt = fieldComp.options || [];
                                        tempOpt[index] = tempObj;
                                        setFieldComp({...fieldComp, options: tempOpt})
                                    }}
                                />
                            </Col>
                            <Col span={2} style={{display:'flex', flexDirection:'row', gap: '10px', alignItems: 'end'}}>
                                <i 
                                    className={`${ICONS.DELETE_P} ${(fieldComp.options || [{}]).length === 1 ? 'hide' : '' }`}
                                    onClick={()=>{
                                        let tempArr = fieldComp.options || [];
                                        tempArr.splice(index, 1);
                                        setFieldComp({...fieldComp, options: tempArr})
                                    }}
                                />
                                <i 
                                    className={`${ICONS.ADD_P} ${(fieldComp.options || [{}]).length === index+1 ? '' : 'hide' }`}
                                    onClick={()=>{
                                        setFieldComp({...fieldComp, options:[...(fieldComp.options || [{}]), {}]})
                                    }}
                                />
                            </Col>
                        </Row>
                    ))}
                </div>
            ):''}
        </>
        
    </Drawer>

}

export default CompEditDrawer;

