import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuditTimeLine from '../../../components/drawer/AuditTimeLine';
import { deleteAuditTrail, createAuditTrail } from '../../../actions/commonActions';

class AuditTimeLineContainer extends Component {
  
  constructor(props) {
    super(props);
    this.state ={

    }
  }

  render() {
    return <div>
      <AuditTimeLine initCalls={() => {
      }} {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    userInfo: state.common.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  deleteAuditTrail, 
  createAuditTrail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AuditTimeLineContainer);
