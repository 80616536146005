import React, { useRef, useState, forwardRef, useImperativeHandle } from "react";
import { InputNumber } from 'antd';

const TextEditorNumber = (props, ref) => {
  const inputRef = useRef();
  let [value, setValue] = useState(props.value);
  let [maxValue, setMaxValue] = useState(props.maxValue)
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: () => {
        inputRef.current.focus();
      },
      isCancelAfterEnd: () => {
        if (props.lastColumnCell && props.node.lastChild && !props.node.shiftKey) {
          props.api.updateRowData({ add: [{}] });
        }
        return false;
      }
    };
  });

  value = value && Number(value || 0).toFixed(props.decimalDigitsLength || 0)

  return (
    <InputNumber
      min ={0}
      ref={inputRef}
      type='number'
      max={maxValue}
      value={value}
      onChange={(newValue) => {
        newValue = Number(newValue || 0).toFixed(props.decimalDigitsLength || 0);
        if(props.onChange){
          if(value !== newValue){
            const newMaxValue = props.getNewMax ?  props.getNewMax(newValue): maxValue;
            setMaxValue(newMaxValue);
            props.onChange(newValue);
          }
          
        }

        setValue(maxValue < newValue ? maxValue : newValue); 
      }}
      style={{ width: "100%" }}
    />);
}

export const TextNumberEditorGrid = forwardRef(TextEditorNumber);