import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssignClassToTeacherComponent from '../../../../../components/modal/modalBody/schoolManagement/AssignClassToTeacher';
import { saveOrUpdateInstructorClassRef } from './action';
import { getClassList } from '../../../../schoolManagement/class/action';

class AssignClassToTeacher extends Component {

    constructor(props) {
        super(props);  
        let selectedClasses = [];
        (props.teacherDetail.instructorClassRefList || []).forEach(function (classObj) {
            selectedClasses.push(classObj.classId);
        });
        this.state = {
            courseTermSubjects: {},
            selectedClasses
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = { 
            relationshipId: companyInfo.relationshipId, 
            pageNumber: 1,
            pageSize: 500,
            relationshipEmployeeId: userInfo.relationshipEmployeeId, 
            formId: this.props.defaultSchoolForm
        };
        this.props.getClassList(payload);
    }

    render() {
        return <AssignClassToTeacherComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        userInfo: state.common.userInfo,
        classList: state.school.classList,
        defaultSchoolForm: state.common.defaultSchoolForm,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveOrUpdateInstructorClassRef,
    getClassList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssignClassToTeacher);