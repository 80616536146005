import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CourseDetailComponent from '../../../../../components/modal/modalBody/schoolManagement/CourseDetail';
import { fetchCourseSubjectList, resetCourseSubjectList } from './action';

class CourseDetail extends Component {

    constructor(props) {
        super();
        this.state = {
            courseTermSubjects: {}
        }
    }
    componentWillMount() {

        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, courseId: this.props.courseDetail.courseId };
        this.props.resetCourseSubjectList(payload);
        this.props.fetchCourseSubjectList(payload);
    }
    componentWillReceiveProps(props) {
        if (props.courseSubjectList && props.courseSubjectList.length) {
            let courseTermSubjects = {};
            props.courseSubjectList.forEach(function (subject) {
                let subjectList = courseTermSubjects[subject.courseTermId] || [];
                subjectList.push(subject);
                courseTermSubjects[subject.courseTermId] = subjectList;
            });

            this.setState({
                courseTermSubjects: courseTermSubjects
            });
            this.props.resetCourseSubjectList();
        }
    }

    render() {
        return <CourseDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        courseSubjectList: state.school.courseSubjectList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCourseSubjectList,
    resetCourseSubjectList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);