import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Empty, Menu, Pagination, Skeleton, Dropdown  } from "antd";
import { getLocalDateFromUtcStr } from "../../../utils";
import { ICONS } from "../../../static/constants";
import RegistrationFormSetting from "../../../containers/modal/modalBody/settings/RegistrationFormSetting";


const FormDraftListComp = (props) => {
    const {schoolFormDraftList, schoolFormDraftCount, pageNumber} = props;

    const handleOpenEdit = (e) =>{
      const modalData = {
        title: (
          <div className="ant-modal-title">
            <FormattedMessage
              id="setting.student.registration.form.label"
              defaultMessage=""
            />
          </div>
        ),
        modalBody: (
          <RegistrationFormSetting
            {...props}
            formDraftData={e}
            registrationFormSettingDraftId={e.registrationFormSettingDraftId}
            style={{ overflow: "auto" }}
          />
        ),
        width: "100%",
        hideFooter: true,
        maskClosable: true,
        hideCloseIcon: true,
        hideTitle: true,
        wrapClassName: "modal-custom-registration",
      };
      props.pushModalToStack(modalData);
    }

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
              handleOpenEdit(e)
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>
    
          <Menu.Item
            key="2"
            onClick={() => {
              const modalData = {
                modalBody: (
                  <FormattedMessage
                    id="registration.form.delete.confirmation"
                    defaultMessage=""
                  />
                ),
                handleSubmit: () => {
                  props.deleteRegistrationFormDraft(
                    {
                      relationshipId: props.relationshipId,
                      pageSize: 25,
                      pageNumber: 1,
                      registrationFormSettingDraftId: e.registrationFormSettingDraftId,
                    },
                    props
                  );
                },
              };
              props.pushModalToStack(modalData);
            }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>
   
        </Menu>
      );
    };

  return (
    <>
        <div className="view-container-actions">
            <div className="left-actions">
            <div className="table-heading">
                Form Draft List
            </div>
            {
                schoolFormDraftCount ? <>
                <div className="vertical-sep" />
                <div>{schoolFormDraftCount}</div>
                </>:''
            }
            </div>
            <div className="right-actions"></div>
        </div>
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>
            <div className="table-container">
            <table id="form-draft-table">
                <thead>
                    <tr>
                        <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                        <th>Form Draft Id</th>
                        <th>Form Name</th>
                        <th>Form Type</th>
                        <th>Date Created</th>
                        <th width='6%'><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
                    </tr>
                </thead>
                <tbody>
                { (schoolFormDraftList[pageNumber || 1] || []).length ?
                    schoolFormDraftList[pageNumber || 1].map((rowData, i)=>{
                        return <tr key={'stuDraft'+i}>
                            <td>{i+1}</td>
                            <td>
                              <div className="cursor-pointer" onClick={()=>{handleOpenEdit(rowData)}}>
                                Draft-{rowData.registrationFormSettingDraftId}
                              </div>
                            </td>
                            <td>{JSON.parse(rowData.registrationFormSettingData).formShortName || '-'}</td>
                            <td>{JSON.parse(rowData.registrationFormSettingData).formType || '-'}</td>
                            <td>{rowData.dateCreated ? getLocalDateFromUtcStr({date: rowData.dateCreated}) : '-'}</td>
                            <td style={{paddingLeft: '50px'}}>
                              <Dropdown
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </Dropdown>
                            </td>
                        </tr>
                    })
                    :
                    <tr key="empty-data">
                        <td colSpan={"100%"} style={{borderBottom:"none"}}>
                          <Empty />
                        </td>
                    </tr>
                }
                </tbody>
            </table>
            </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={props.pageSize}
            //   showTotal={props.pageSize}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                // loadPageData(pageNumber, pageSize);
                // updateState({ pageSize: pageSize, pageNumber: pageNumber })
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                // handlePageSizeChange(1, pageSize, props)
              }}
            />
          </div>
        </div>
    </>
  );
};

export default injectIntl(FormDraftListComp);
