import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BusinessExpenseComponent from '../../../../components/finance/BusinessExpense/Create';
import { fetchDataIfNeeded } from '../../../../utils';

import { showModal, hideModal, fetchExpenseTypes, addExpenseTypes, deleteExpenseTypes, deleteProject, fetchProjects, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import { createRelationshipExpense, getNextTxNumber } from  './action';
import { fetchAllLedgerAccounts } from '../../LedgerAccounts/action';
import { fetchBusinessExpenses, getBusinessExpensesCount } from  './../Listing/action';
import { fetchCostCenter } from '../../../../containers/customer/creditMemo/StandardCreditMemo/action';
import find from 'lodash.find';
import { LEDGER_ACTION_LIST, ACCOUNTING_ENTRY_TYPE } from '../../../../static/constants';
import { fetchRelationshipExpenseDetail, resetRelationshipExpenseDetails } from  './../../../modal/modalBody/finance/BusinessExpense/action';
class BusinessExpense extends Component {

  constructor(props){
    super(props);
    const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES }) || {};
    const defaultDebtorsLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEBTORS }) || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      isClone: linkProps.clone,
      isUpdate: linkProps.update,
      isbankReco: linkProps.isbankReco,
      bankRecoBalance: linkProps.bankRecoBalance,
      bankTxData: linkProps.bankTxData,
      expenseDate: new Date(),
      salesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesLedgerId: defaultSalesLedger.ledgerAccountId
    }
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true
    // });
    this.props.resetRelationshipExpenseDetails();
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      expenseMasterId: linkProps.expenseMasterId,
    };
    //fetchDataIfNeeded('fetchCostCenter', 'costCenters', this.props, payload);
    //fetchDataIfNeeded('fetchExpenseTypes', 'expenseTypes', this.props, payload);
    fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
    fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
    payload.expenseMasterId && this.props.fetchRelationshipExpenseDetail(payload);
    if (!linkProps.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
  }

  componentWillReceiveProps(props) {
    const linkProps = (this.props.location && this.props.location.state) || {};
    if (props.businessExpenseData && props.businessExpenseData.expenseMasterId && props.allLedgerAccounts.length) {
      let relationshipExpenseDetails = [];
      props.businessExpenseData.relationshipExpenseDetails.map((ele) => { 
        const relationshipExp = find(ele.relationshipCOATxExpense, {accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT})
        const credLedger = (find(props.allLedgerAccounts, {chartOfAccountDetailsId: relationshipExp.ledgerAccountId})) ||{};
        ele.expenseType = ele.expenseTypeName;
        ele.ledgerAccountName = ele.expenseTypeName;
        ele.ledgerAccountDetailsId = credLedger.chartOfAccountDetailsId;
        ele.ledgerName = credLedger.ledgerAccountName;
        relationshipExpenseDetails.push(ele);
        return ele;
      });
        this.setState({
          expenseMasterId: props.businessExpenseData.expenseMasterId,
          expenseDate: props.businessExpenseData.expenseDate,
          refNumber: props.businessExpenseData.refNumber, 
          relationshipExpenseDetails: relationshipExpenseDetails, 
          projectMasterId: props.businessExpenseData.projectMasterId,
          projectName: props.businessExpenseData.projectName,
          projectNumber: props.businessExpenseData.projectNumber,
          remarksInternal: props.businessExpenseData.remarksInternal,
          version: props.businessExpenseData.version,
          expenseId: props.businessExpenseData.expenseId,
          });
          if (linkProps.update) {
            this.setState({ nextTxNumber: props.businessExpenseData.expenseNumber });
          }
          props.resetRelationshipExpenseDetails();
        }
}

  render() {
    return (
      <BusinessExpenseComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    expenseTypes: state.common.expenseTypes,
    costCenters: state.creditMemo.costCenters,
    projectList: state.common.projectList,
    businessExpenseData: state.businessExpense.businessExpenseDetail,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    nextTxNumber: state.businessExpense.nextTxNumber,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  fetchCostCenter,
  fetchExpenseTypes,
  addExpenseTypes, 
  deleteExpenseTypes,
  createRelationshipExpense,
  fetchBusinessExpenses,
  getBusinessExpensesCount,
  deleteProject,
  fetchProjects,
  fetchAllLedgerAccounts,
  fetchRelationshipExpenseDetail,
  resetRelationshipExpenseDetails,
  pushModalToStack, 
  popModalFromStack,
  getNextTxNumber
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BusinessExpense);
