import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../../utils";
import { COMMON_ACTIONS } from "../../../../../static/constants";

// export const TimeSettings = (payload)=>{



        
//     return (dispatch) => {
//         dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//         return  axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/store-timing`,payload)
//           .then(res => {
//             showToasterMessage({messageType: "success", description: "StoreTiming save Succssfully."});
//             dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           })
//           .catch((err) => {
//             dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           })
//       }

//   }


export const TimeSettings = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/store-timing`,
        {
          relationshipId: payload.relationshipId,
          storeTiming:JSON.stringify(payload.storeTiming),
        }
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "StoreTiming save Succssfully.",
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};
