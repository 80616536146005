import React from "react";
import "../index.scss";
import { Empty } from "antd";
import { getCurrencySymbol, getMomentDateForUIReadOnly } from "../../../../../../utils";


const Fees = (props) => {
  const { allCustomerPayments=[], feesInvoiceProformas = [] } = props;

  return (
    <div className="view-container">
      {/* <div className="view-container-actions">
        <div className="left-actions">
          <div className="row-heading">{getUserDefinedName("Fees")}</div>
          <ul className="fee-details">
            <li>Total: ₹ 24000</li>
            <li>Paid: ₹ 6000</li>
            <li>Due: ₹ 18000</li>
            <li>Concession: ₹ 0</li>
            <li>Fine: ₹ 0</li>
          </ul>

          {classCount ? (
            <>
              <div className="vertical-sep" />
              <div>{classCount}</div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="right-actions">
          <div className="date-picker">
            <DatePicker />
          </div>
          <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
            <span>
              <DownloadOutlined /> &nbsp; Download
            </span>
          </DropdownAction>
        </div>
      </div> */}
      <div className="table-container">
        <table id="class-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Amount </th>
              <th>Last Date </th>
              <th>Payment Source </th>
              <th>Fine</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {feesInvoiceProformas?.length ? feesInvoiceProformas.map((rowData, i)=>{
              return <tr key={i+'fees'}>
                <td>{i+1}</td>
                <td>{getCurrencySymbol(props.companyInfo.currenyCode)}{(JSON.parse(rowData.invoiceData || '{}')?.invoiceTotalAmount || 0).toFixed(2)}</td>
                <td>{rowData.expiryDate ? getMomentDateForUIReadOnly({date: rowData.expiryDate}) : '-'}</td>
                <td>{rowData.paymentSource || '-'}</td>
                <td>0</td>
                <td>
                  {rowData.paymentStatus === 'paid' ? 
                    <div className="status-label open">Paid</div>
                    :
                    <div className="status-label unpaid">Unpaid</div>
                  }
                </td>
              </tr>
            })
            :
            <tr key="empty-data">
              <td colSpan={"100%"} style={{borderBottom:"none"}}>
                <Empty />
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
 
    </div>
  );
};

export default Fees;
