import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Pagination, Skeleton, Menu, Dropdown } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
import adminAccess from '../../../assets/images/school/admin-access.png';
import { CONSTANTS } from '../../../static/constants';
import { MoreOutlined, SortAscendingOutlined, SortDescendingOutlined, RedoOutlined} from '@ant-design/icons'

const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const OnlineUsers = (props) => {
  const { onlineUserList, updateState, companyInfo, onlineUserCount, pageNumber, pageSize, dir=0 } = props;

  const relationshipEmployee = companyInfo.relationshipEmployees || {};
  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='onlineUser.text' defaultMessage='' />,
    }
  ];

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="resendPassword" onClick={()=>{
              props.sendResetMail(e.email, props.history);
            }}
          >
            <RedoOutlined />
            Resend Password
          </Menu.Item>

        </Menu >
    )
  };

  const sortColumn = (e) => {
    sortTable(document.getElementById('portalUsers-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getOnlineUsers", "onlineUserList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getOnlineUsers(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };



  const accessPortal = (e) => {
    props.getAccessToken({
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      clientOnlineUserAccountId: e.clientOnlineUserAccountId
    }, props);
  };

  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='heading.schoolManagement.portalUsers' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div>
      <div className="view-container">
      <Skeleton loading={props.isLoading}>
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='schoolManagement.portalUsers.table.heading' defaultMessage='' />
            </div>
            {
              pageSize ? <>
                <div className="vertical-sep" />
                <div>{pageSize}</div>
              </>:''
            }
          </div>
          <div className="right-actions"></div>
        </div>
        <div className="table-container">
          <table id="portalUsers-table">
            <thead>
              <tr>
                <th onClick={sortColumn}># {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                {(!relationshipEmployee.jobRoleName || relationshipEmployee.jobRoleName === 'School Admin' || relationshipEmployee.jobRoleName === 'Super Admin') ? <th onClick={sortColumn}>Portal {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>: ''}
                <th onClick={sortColumn}>Father Info {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                <th onClick={sortColumn}>Mother Info {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                <th onClick={sortColumn}><FormattedMessage id="common.dateCreated"/> {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                <th width='6%'><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
              </tr>
            </thead>
            <tbody>
              {
                (onlineUserList[pageNumber] || []).map((e, i) => {
                  return <tr key={'usr-'+i}>
                    <td>{i + 1}</td>
                    {(!relationshipEmployee.jobRoleName || relationshipEmployee.jobRoleName === 'School Admin' || relationshipEmployee.jobRoleName === 'Super Admin') ? <td><img src={adminAccess} style={{ 'height': '25px', 'width': '25px' }} className="cursor-pointer" alt="Access Account" onClick={() => { accessPortal(e); }} /></td> : ''}
                    <td>
                      <div>{`${e.fatherFirstName || ''}${e.fatherLastName ? ' ' + e.fatherLastName : ''}`}</div>
                      <div>{e.fatherMobile}</div>
                      <div>{e.email}</div>
                    </td>
                    <td>
                      <div>{`${e.motherFirstName || ''}${e.motherLastName ? ' ' + e.motherLastName : ''}`}</div>
                      <div>{e.motherMobile}</div>
                      <div>{e.motherEmail}</div>
                    </td>
                    <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : ''}</td>
                    <td style={{paddingLeft: '50px'}}>
                      {/* <span className="cursor-pointer" style={{ marginLeft: '10px' }} onClick={() => { props.sendResetMail(e.email, props.history); }}>Resend Password</span> */}
                      <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                        <MoreOutlined />
                      </Dropdown>
                    </td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </Skeleton>
      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={onlineUserCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </div>
        {/* <div className='team-and-free'>
          <div className="right-align pb10">
            <div className="action-dropdown-btn">
              <button onClick={() => {
                newCourse();
              }}>
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
          </div>
          <div className="agGridWrapper">
            <div className='new-table-camp'>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default injectIntl(OnlineUsers);
