import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { STUDENT_REGISTRATION_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getUnpaidCustomersByFormId= (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getUnpaidCustomerByFormIdAndModule?formId=${payload.formId}&relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) -1}&module=school`)
        .then(res => {
          const data = {
            pageNo: payload.pageNumber,
            list: res.data
          }
          dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.UNPAID_CUSTOMERS_BY_FORM_ID, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.UNPAID_CUSTOMERS_BY_FORM_ID;
          }
        })
    }
  }
  
  export const getUnpaidCustomersCount = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/countUnpaidCustomersByModuleAndFormId?formId=${payload.formId}&relationshipId=${payload.relationshipId}&module=school`)
        .then(res => {
          dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.UNPAID_CUSTOMERS_COUNT_BY_FORM_ID, data: res.data  });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.UNPAID_CUSTOMERS_COUNT_BY_FORM_ID;
          }
        })
    }
  }

  export const resetPaginatedData = (payload) => {
    return dispatch => {
      dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.UNPAID_CUSTOMERS_BY_FORM_ID_RESET });
    }
  }