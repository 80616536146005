import React from "react";
import { Row, Col, Button, Pagination, Checkbox } from 'antd';
import envConfig from '../../../config/environmentConfig';
// import AmazonIcon from '../../../assets/images/amazon-logo.png';
import { NoonLogo } from "../../../assets/images";
import { MARKETPLACE_NAME } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import { FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import { fetchPaginationDataIfNeeded } from "../../../utils";
// import AddInventoryItemLisitng from "../../../containers/modal/modalBody/salesChannel/amazon";
// import AmazonList from '../../../containers/modal/modalBody/common/amazon';
import ReactApexChart from "react-apexcharts"

const NoonListingComp = (props) => {

    const { linkedMarketplaceList, inventoryItemsListing, inventoryItemsCount, pageNumber, companyInfo, updateState,
         selectedItems } = props
        const dateList = []
        const dataArr = [];
        Object.values([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]).forEach((e) => {
            dataArr.push(e);
            dateList.push(Math.floor(Math.random() * 200) + 1)
        });
    
    
        const series = [{
            name: 'Qty',
            data: dateList
        }];
        const options = {
            chart: {
             //   height: 250,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                    }
                }
            },
            // colors: colors,
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                 categories: dataArr,
                labels: {
                    style: {
                        //colors: colors,
                        fontSize: '12px'
                    }
                }
            }
        };
    if (!selectedItems) {
        props.updateState({ selectedItems: [] });
    }

    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            }) +
            ` ${total || 0} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        fetchPaginationDataIfNeeded("getInventoryItems", "inventoryItemsListing", props, payload);
    };

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        props.resetPaginatedData();
        fetchPaginationDataIfNeeded("getInventoryItems", "inventoryItemsListing", props, payload);
        updateState({ pageSize: pageSize, pageNumber: pageNumber });
    };

    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.salesChannels' defaultMessage='' />

        },
        {
            name: <FormattedMessage id='noon.logo' defaultMessage='' />,
        }
    ];

    // const dataSource = [
    //     {
    //         key: '1',
    //         ProductsDetails: 'Mike',
    //         age: 32,
    //         address: '10 Downing Street',
    //         Variant: 'Variant',
    //         SKU: 'SKU',
    //         Category: 'Category',
    //         Warehouse: 'Warehouse',
    //         BrandName: 'BrandName',
    //     },
    //     {
    //         key: '2',
    //         ProductsDetails: 'John',
    //         age: 42,
    //         address: '10 Downing Street',
    //         Variant: 'Variant',
    //         SKU: 'SKU',
    //         Category: 'Category',
    //         Warehouse: 'Warehouse',
    //         BrandName: 'BrandName',
    //     },
    // ];

    // const columns = [
    //     {
    //         title: 'Products Details',
    //         dataIndex: 'ProductsDetails',
    //         key: 'ProductsDetails',
    //         Variant: 'ProductsDetails',
    //         SKU: 'ProductsDetails',
    //         Category: 'ProductsDetails',
    //         Warehouse: 'ProductsDetails',
    //         BrandName: 'ProductsDetails',
    //     },
    //     {
    //         title: 'Age',
    //         dataIndex: 'age',
    //         key: 'age',
    //         Variant: 'age',
    //         SKU: 'age',
    //         Category: 'age',
    //         Warehouse: 'age',
    //         BrandName: 'age',
    //     },
    //     {
    //         title: 'Address',
    //         dataIndex: 'address',
    //         key: 'address',
    //         Variant: 'address',
    //         SKU: 'address',
    //         Category: 'address',
    //         Warehouse: 'address',
    //         BrandName: 'address',
    //     },

    //     {
    //         title: 'Variant',
    //         dataIndex: 'Variant',
    //         key: 'Variant',
    //         Variant: 'Variant',
    //         SKU: 'Variant',
    //         Category: 'Variant',
    //         Warehouse: 'Variant',
    //         BrandName: 'Variant',
    //     },

    //     {
    //         title: 'SKU',
    //         dataIndex: 'SKU',
    //         key: 'SKU',
    //         Variant: 'SKU',
    //         SKU: 'SKU',
    //         Category: 'SKU',
    //         Warehouse: 'SKU',
    //         BrandName: 'SKU',
    //     },

    //     {
    //         title: 'Category',
    //         dataIndex: 'Category',
    //         key: 'Category',
    //         Variant: 'Category',
    //         SKU: 'Category',
    //         Category: 'Category',
    //         Warehouse: 'Category',
    //         BrandName: 'Category',
    //     },
    //     {
    //         title: 'Warehouse',
    //         dataIndex: 'Warehouse',
    //         key: 'Warehouse',
    //         Variant: 'Warehouse',
    //         SKU: 'Warehouse',
    //         Category: 'Warehouse',
    //         Warehouse: 'Warehouse',
    //         BrandName: 'Warehouse',
    //     },

    //     {
    //         title: 'BrandName',
    //         dataIndex: 'BrandName',
    //         key: 'BrandName',
    //         Variant: 'BrandName',
    //         SKU: 'BrandName',
    //         Category: 'BrandName',
    //         Warehouse: 'BrandName',
    //         BrandName: 'BrandName',
    //     },
    // ];

    const itemSelection = (item) => {
        let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
        let updatedItems = [];
        if (selectItem) {
            updatedItems = selectedItems.filter(obj => obj.itemId !== item.itemId);
        } else {
            updatedItems = JSON.parse(JSON.stringify(selectedItems));
            updatedItems.push(item);
        }
        props.updateState({ selectedItems: updatedItems });
    }

    const selectAll = (context) => {
        if (!context) {
            selectedItems.splice(0, selectedItems.length);
            props.updateState({ selectedItems: selectedItems });
            return;
        }
        for (let i = 0; i < inventoryItemsListing[pageNumber].length; i++) {
            let item = inventoryItemsListing[pageNumber][i];
            let selectItem = find(selectedItems, { itemId: Number(item.itemId) });
            if (!selectItem) {
                selectedItems.push(item);
            }
        }
    }

    const isCheckedAll = () => {
        if (!inventoryItemsListing || !inventoryItemsListing[pageNumber] || inventoryItemsListing[pageNumber].length === 0) {
            return false;
        }
        for (let i = 0; i < inventoryItemsListing[pageNumber].length; i++) {
            let usr = inventoryItemsListing[pageNumber][i];
            let selectItem = find(selectedItems, { itemId: Number(usr.itemId) });
            if (!selectItem) {
                return false;
            }
        }
        return true;
    }

    return (
        <div className="customer-details-container shadesama">

            <div className="pb10">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            {(!linkedMarketplaceList || linkedMarketplaceList.length === 0 || !find(linkedMarketplaceList, { marketplaceName: 'noon' })) ?
                <div className="customer-details">
                    <div className="prod-ama">
                        <Row >
                            <Col span={2}></Col>
                            <Col span={15}>
                                <h3>Noon gives an international sales channel and reach millions of customers </h3>
                                <div className="line-box"></div>
                                <h4>Noon was created to help businesses, no matter their size - grow</h4>
                                <ul>
                                    <li><span><i class="fa fa-square" aria-hidden="true"></i>

                                    </span>Noon can store, pack and deliver sellers’ products to customers </li>
                                    <li><span><i class="fa fa-square" aria-hidden="true"></i>

                                    </span> Noon look forward to helping you take your venture to the next level  </li>
                                    <li><span><i class="fa fa-square" aria-hidden="true"></i>

                                    </span> Noon works with brands and retailers in three models:Wholesale/direct purchase, Marketplace model + FBN and Marketplace model + back-to-back </li>
                                    <li><span><i class="fa fa-square" aria-hidden="true"></i>

                                    </span> Noon marketing services can help with marketing, advertising, and merchandising </li>

                                    {/* <Button className="ant-btn cnant-btn ant-btn-primary prod-amab">Start Now</Button> */}

                                </ul>
                                <div className="prod-amab"><a href="https://www.great.gov.uk/selling-online-overseas/markets/details/noon/" target="_blank" rel="noopener noreferrer"> Guide to Selling on Noon</a></div>
                            </Col>
                            <Col span={6}> <div className="ant-card accounting-market-place text-center integsm ant-card-bordered ">
                                <div className="ant-card-head headr">
                                    <div className="ant-card-head-wrapper">
                                        <div className="ant-card-extra">
                                            <img src={NoonLogo} alt="" style={{height: '50px', width: '100px'}}/>
                                        </div>
                                    </div>
                                </div>

                                {props.marketplaceConfig && props.marketplaceConfig[MARKETPLACE_NAME.AMAZON] ? <div class="cr cr-top cr-left cr-green">Integrated</div> : ''}
                                <div className="ant-card-body">
                                    <p> List product to Noon</p>
                                    <p> Update price</p>
                                    <p> Sync up quantity</p>
                                    <p> Remove quantity</p>

                                    <div className="border-footer mt10"></div>
                                    {props.marketplaceConfig && props.marketplaceConfig[MARKETPLACE_NAME.AMAZON] ? <Button className="cnant-btn grey-button" onClick={
                                        () => {
                                            props.disconnectConfiguration(props.marketplaceConfig[MARKETPLACE_NAME.AMAZON], props);
                                        }
                                    }>Disconnect </Button> : <Button className="ant-btn cnant-btn ant-btn-primary" onClick={() => {
                                        
                                        window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_amazon/onboarding?callback_uri=${envConfig.CALLBACK_URI_MARKTPLACE_AMAZON}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}${envConfig.rootContext}/`;
                                    }}> Start Selling</Button>
                                    }
                                </div>
                            </div></Col>
                            <Col span={1}></Col>
                        </Row>



                    </div>
                    <div className="clearfix"></div>
                </div>
                : ''
            }



            {(linkedMarketplaceList && linkedMarketplaceList.length > 0 && find(linkedMarketplaceList, { marketplaceName: 'noon' })) ?
                <div style={{ background: '#fff', padding: '15px' }}>
                    <Row >
                        <Col span={20}><h3 style={{ fontSize: '24px', fontWeight: '700' }}>My Noon Store</h3>
                            <Button className="cnant-btn grey-button"
                                onClick={() => {
                                    props.disconnectConfiguration(props.marketplaceConfig[MARKETPLACE_NAME.AMAZON], props);
                                }}>
                                Disconnect
                            </Button>
                        </Col>
                        <Col span={4} style={{ textAlign: 'right' }} >   <img src={NoonLogo} alt="" style={{height: '50px', width: '100px'}} /></Col>
                    </Row>

                    <div className='amazon-box-border'>
                    <Row>
                            <Col span={10} className='text-center'>
                                {/* <h3 className='ama-hed '>Chart</h3><br/> */}
                                <div id="chart">
                                    <ReactApexChart options={options} series={series} type="bar" height={200} />
                                </div>
                             </Col>
                            <Col span={7} className='text-center' style={{ borderLeft: 'solid 1px #d3d3d3' }}>
                                <h3 className='ama-hed' style={{ float: 'left', width: '50%' }}>
                                    {/* {ebayPublishedQty || 43}  */}88
                                    <br />
                                    <span>Published</span>
                                </h3>
                                <h3 className='ama-hed ' style={{ float: 'right', width: '50%' }}>
                                    {/* {ebayUnPublishedQty}  */}22
                                    <br />
                                    <span>Unpublished</span>
                                </h3>
                                <div className="clearfix"></div>
                                <Row style={{ background: '#f7f7f7', margin: '0px 10px', borderRadius: '8px', padding: '10px 0px' }}>
                                    <Col span={8}> Today <br />
                                        12 EA</Col>
                                    <Col span={8} style={{ borderLeft: 'solid 1px #d3d3d3' }}>This Week <br />
                                        54 EA</Col>
                                    <Col span={8} style={{ borderLeft: 'solid 1px #d3d3d3' }}> This Month <br />
                                        520 EA</Col>
                                </Row>
                            </Col>
                            <Col span={7} className='text-center' style={{ borderLeft: 'solid 1px #d3d3d3' }}>
                                <h3 className='ama-hed1'>$ 2454 <span className='cir-der'><i class="fa fa-angle-up" aria-hidden="true"></i>
                                    15%</span><br />
                                    <span>Total  Sales</span>
                                </h3>
                                <div className="clearfix"></div>
                                <Row style={{ background: '#f7f7f7', margin: '0px 0px 0px 10px', borderRadius: '8px', padding: '10px 0px' }}>
                                    <Col span={8}> Today <br />
                                        $2512</Col>
                                    <Col span={8} style={{ borderLeft: 'solid 1px #d3d3d3' }}>This Week <br />
                                        $89825 </Col>
                                    <Col span={8} style={{ borderLeft: 'solid 1px #d3d3d3' }}> This Month <br />
                                        $234567</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    {/* <Row>
                    <Col span={24} style={{ textAlign: 'center', padding: '10px 0px' }}>Show this only when item is selected
                        <br />
                        <i class="fa fa-long-arrow-down" aria-hidden="true"></i>

                    </Col>
                </Row> */}

                    <Row>
                        <Col span={24}>
                            {/* <Table dataSource={dataSource} columns={columns} style={{ width: '100%' }} /> */}
                            <div>
                                {/* <button
                                    className="action-btn"
                                    title={props.intl.formatMessage({ id: 'product.listing.amazon.upload', defaultMessage: '' })}
                                    onClick={() => {
                                        const ids = [];
                                        const data = [];
                                        // props.gridApi.forEachNode(obj => {
                                        //     if (obj.data.isChecked) {
                                        //         data.push(obj.data);
                                        //         ids.push(obj.data.itemId);
                                        //     }
                                        // })
                                        selectedItems.forEach(obj => {
                                            data.push(obj);
                                            ids.push(obj.itemId);
                                        })
                                        if (ids.length) {

                                            const modalData = {
                                                title: <FormattedMessage id='product.listing.amazon' defaultMessage='' />,
                                                modalBody: <AmazonList inventoryList={ids} {...props} />,
                                                hideFooter: true,
                                                width: 1400,
                                                wrapClassName: 'validation-failed-modal'
                                            };
                                            props.pushModalToStack(modalData);
                                        } else {
                                            const modalData = {
                                                title:
                                                    <div className="title">
                                                        <i className="fa fa-ban mr15" />
                                                        <FormattedMessage id='product.listing.validation.failed.label' defaultMessage='' />
                                                    </div>,
                                                modalBody:
                                                    <Fragment>
                                                        <div className="warning-message">
                                                            <FormattedMessage id='product.listing.validation.failed.message.amazon' defaultMessage='' />
                                                        </div>
                                                        <div className="button-action">
                                                            <Button className="ant-btn-default"
                                                                onClick={() => props.popModalFromStack()}><FormattedMessage id='ok' defaultMessage='' /></Button>
                                                        </div>
                                                    </Fragment>,
                                                hideFooter: true,
                                                wrapClassName: 'validation-failed-modal'
                                            };
                                            props.pushModalToStack(modalData);
                                        }
                                    }}
                                >
                                   <img src={NoonLogo} alt="" style={{height: '50px', width: '100px'}}/>
                                </button> */}
                            </div>
                            <div className="new-tables">
                                <table width={100}>
                                    <thead>
                                    <tr>
                                            <th>
                                                <Checkbox
                                                    onChange={(e) => {
                                                        selectAll(e.target.checked);
                                                    }}
                                                    checked={isCheckedAll()}
                                                />
                                            </th>
                                            <th>Product & Desc</th>
                                            <th>Seller SKU</th>
                                            <th>ASIN #</th>
                                            <th>Tax Code</th>
                                            <th>Item Condition</th>
                                            <th>Category</th>
                                            <th>Manufacturer</th>
                                            <th>Brand</th>
                                            <th>Qty</th>
                                            <th>Ebay Selling Price</th>
                                            {/* <th>Fulfillment Channel</th> */}
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {inventoryItemsListing && inventoryItemsListing[pageNumber] ?
                                            inventoryItemsListing[pageNumber].map((item, i) => {
                                                return (<tr key={i}>
                                                    <th>
                                                        <Checkbox
                                                            onClick={() => {
                                                                itemSelection(item);
                                                            }}
                                                            checked={find(selectedItems, { itemId: Number(item.itemId) }) ? true : false}
                                                        />
                                                    </th>
                                                    <th>{item.itemName}</th>
                                                    <th>{item.sku}</th>
                                                    <th>43432{i}</th>
                                                    <th>NA</th>
                                                    <th>{(i%3 === 0) ? 'Good': 'Acceptable'}</th>
                                                    <th>{item.categoryName}</th>
                                                    <th>{item.manufacturerName}</th>
                                                    <th>{item.categoryName}</th>
                                                    <th>{item.qtyOnEbay}</th>
                                                    <th>{item.ebaySellingPrice}</th>
                                                    {/* <th>Fulfillment Channel</th> */}
                                                    {!item.qtyOnEbay || item.qtyOnEbay === 0 ?
                                                        <th><span className='cir-der'>Publish</span></th>
                                                        :
                                                        <th onClick={() => {
                                                            alert("Product successfully unpublished.")
                                                        }}>Unpublish</th>
                                                    }
                                                </tr>)
                                            })

                                            : 'No Data'}
                                    </tbody>
                                </table>

                                <Pagination
                                    size="small"
                                    total={inventoryItemsCount}
                                    showTotal={showTotal}
                                    defaultPageSize={props.pageSize}
                                    showSizeChanger
                                    pageSizeOptions={[10, 25, 50, 100, 200]}
                                    showQuickJumper
                                    onChange={(pageNumber, pageSize) => {
                                        loadPageData(pageNumber, pageSize);
                                        updateState({ pageSize: pageSize, pageNumber: pageNumber });
                                    }}
                                    onShowSizeChange={(pageNumber, pageSize) => {
                                        handlePageSizeChange(pageNumber || 1, pageSize, props);
                                    }}
                                />
                            </div>


                        </Col>
                    </Row>
                </div> : ''
            }

        </div>
    )
};

export default NoonListingComp;