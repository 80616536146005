import React from "react";
import { injectIntl } from "react-intl";
import "./index.scss";
import {
  Row,
  Col,
  Divider,
} from "antd";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import { LIST_CONSTANTS } from "../../../static/constants";
import { ErrorMsg } from "../../general/ErrorMessage";
import ReactHtmlParser from 'react-html-parser';


const PrimaryDetails = (props) => {
  const {
    intl,
  } = props;

  const gridColStyle = {
    xs: {
      span: 24,
    },
    lg: {
      span: 12,
    },
  };

  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={24}>
        {props.formSetting?.formDescription ? 
        <>
        <div>{ReactHtmlParser(props.formSetting?.formDescription)}  </div>
        <Divider />
        </>
        : ""}
        </Col>
        <Col span={24} style={{ display: "flex" }}>
          <span className="bold-title mr5">Primary Contact</span>
          <span
            className="status-label open"
            style={{ width: "80px", padding: "0px 5px !important" }}
          >
            Primary
          </span>
        </Col>

        <Col xs={24} lg={12}>
          <Dropdown
            disabled={props.isSiblingRegistration}
            items={LIST_CONSTANTS.REGISTRATION_RELATIONSHIPS}
            placeholder={props.intl.formatMessage({
              id: "primaryContactForInvoicing.text",
            })}
            valueKeyName="name"
            optionKeyName="id"
            value={props.primaryContact}
            showSearch={false}
            onSelect={(optionValue) => {
              props.updateState({ primaryContact: optionValue });
              if(optionValue === 'father'){
                props.updateState({ fatherEmail: props.primaryEmail, motherEmail: undefined });
              }else{
                props.updateState({ motherEmail: props.primaryEmail, fatherEmail: undefined });
              }
            }}
          />
          <ErrorMsg
            validator={() => {
              return !props.submitClicked || props.primaryContact;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>


        {/* <Col {...gridColStyle}>
          <div className="i-label">
            Full Name<span className="required">*</span>
          </div>
          <TextBox
            placeholder={intl.formatMessage({
              id: "contact.fullName.placeholder",
              defaultMessage: "",
            })}
            type="text"
            value={props.primaryContactName}
            onChange={(e) => {
              props.updateState({ primaryContactName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => {
              return (
                !props.submitClicked ||
                props.primaryContactName?.split(" ").length >= 2
              );
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col {...gridColStyle}>
          <div className="i-label">
            Relationship<span className="required">*</span>
          </div>
          <Dropdown
            items={LIST_CONSTANTS.REGISTRATION_RELATIONSHIPS}
            placeholder={props.intl.formatMessage({
              id: "primaryContactForInvoicing.text",
            })}
            valueKeyName="name"
            optionKeyName="id"
            value={props.primaryContact}
            showSearch={false}
            onSelect={(optionValue) => {
              props.updateState({ primaryContact: optionValue });
            }}
          />
          <ErrorMsg
            validator={() => {
              return !props.submitClicked || props.primaryContact;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col {...gridColStyle}>
          <div className="i-label">
            Email<span className="required">*</span>
          </div>
          <TextBox
            placeholder="Enter email"
            type="email"
            maxLength={45}
            value={props.primaryEmail}
            disabled={true}
            onChange={(e) => {
              props.updateState({ primaryEmail: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => {
              return !props.submitClicked || props.primaryEmail;
            }}
            message={props.intl.formatMessage({
              id: "requiredField.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col {...gridColStyle}>
          <div className="i-label">
            Mobile Number<span className="required">*</span>
          </div>
          <TextBox
            prefix={ props.companyInfo.countryCallingCode ? `+ ${props.companyInfo.countryCallingCode}` : '-' }
            placeholder="Enter Number"
            type="text"
            maxLength={10}
            countStyle={{top: '35px'}}
            value={props.mobileNumber}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                props.updateState({
                  mobileNumber: e.target.value,
                });
              } else if (!props.mobileNumber) {
                props.updateState({ mobileNumber: "" });
              }
            }}
          />
        </Col> 

        <Divider />*/}
      </Row>
    </>
  );
};

export default injectIntl(PrimaryDetails);
