import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { CustomButton } from './CustomButton';
import 'cropperjs/dist/cropper.css';
//import { showToasterMessage } from '../../utils';



export const ImageCropper = (props) => {
  const cropper = useRef();
  const [imageUrl, updateImageUrl] = useState('');
  const [height, updateHeight] = useState('');
  const [width, updateWidth] = useState('');
  const _crop = () => {
    
    cropper.current.getCropBoxData() && updateHeight(parseInt(cropper.current.getData().height));
    cropper.current.getCropBoxData() && updateWidth(parseInt(cropper.current.getData().width) );
    updateImageUrl(cropper.current.getCroppedCanvas().toDataURL());
  }

  return (
    <div ref={props.cropperRef}>
    <h5 className={"note-information"}><i className="fa fa-info-circle" aria-hidden="true"></i><span> Use mouse wheel to zoom in and out the image. {width}px *{height}px </span></h5>
      <Cropper
        ref={cropper}
        src={props.url}
        style={{ height: 300, width: '100%' }}
        // Cropper.js options
        //aspectRatio={16 / 5}
        autoCropArea={0.5}
        guides={false}
        cropBoxResizable={true}
        dragMode={"move"}
        crop={_crop} />

        {/* <h5 className={"note-information"}><i className="fa fa-info-circle" aria-hidden="true"></i><span> Use mouse wheel to zoom in and out the image. {width}px *{height}px </span></h5> */}

        <div className='crop-footer'>
          <CustomButton
              id= "cropper-button"
              intlId='confirmation.cancel'
              defaultMessage=''
              type='default'
              key={0}
              onClick={()=>{
                props.hideModal()
              }}
          />
          <CustomButton
              intlId='confirmation.submit'
              defaultMessage=''
              htmlType='submit'
              onClick={() =>{
                //230 * 90
              //   if(height > 175 || width > 350){
              //     return showToasterMessage({ 
              //       messageType: 'error', 
              //       description: "Please select the dimension with in 350px * 175px"
              //   });
              // }
                props.handleSubmit(imageUrl, width, height);
                // props.popModalFromStack();
                props.hideModal();
              }
              }
              key={1}
          />
            </div>
    </div>
  );
}