import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PurchaseOrderListingComponent from '../../../../components/supplier/purchaseOrder/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchPurchaseOrders, getPurchaseOrdersCount, resetPaginatedData, fetchPurchaseOrderDrafts, getPurchaseOrderDraftCount, resetDraftPaginatedData, deletePurchaseOrder, updateUserStatus, fetchPurchaseOrdersByFilter, getPurchaseOrdersCountByFilter } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchUserPreferences, saveUserPrefrences, deleteAuditTrail,  createAuditTrail, fetchProjects } from '../../../../actions/commonActions';
import { fetchSuppliers } from '../../Listing/action';
import { fetchAllStatus } from '../../../modal/modalBody/common/UserStatus/action';
import * as find from 'lodash.find';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
// import { FormattedMessage } from 'react-intl';
import PurchaseOrderFilterComp from '../../../../components/supplier/purchaseOrder/Listing/purchaseOrderFilters';


class PurchaseOrderListing extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      activeTab: linkProps.activeTab,
      activeTabKey: linkProps.activeTab === "Draft" ? '2' : '1', 
      txPreferences: { txType: "purchaseOrder", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['poNumber', 'supplierName', 'totalPOAmount', 'poDate'],
      txAvailableColumn: {
        poNumber: true,
        supplierName: true,
        totalPOAmount: true,
        reference: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        isRFQCconversion: false,
        rfqNumber: true,
        expectedDeliveryDate: true,
        poDate: true,
        paymentTermName: true,
        status: true,
        totalQtyReceived: true,
        totalQtyOnId: true,
        userStatus: true,
        project: true,
        purchaseRequestNumber: true
      },
      selectedAmountTerm : '=',
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
      transactionName: 'Purchase Order'
    };
    fetchPaginationDataIfNeeded('fetchPurchaseOrders', 'purchaseOrderList', this.props, payload);
    fetchDataIfNeeded('getPurchaseOrdersCount', 'purchaseOrderCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    if (linkProps.activeTab === "Draft") {
      fetchDataIfNeeded('getPurchaseOrderDraftCount', 'purchaseOrderDraftCount', this.props, payload);
      fetchPaginationDataIfNeeded('fetchPurchaseOrderDrafts', 'purchaseOrderDraftList', this.props, payload);
    }
    this.props.fetchSuppliers(payload);
    this.props.fetchProjects(payload);
    this.props.fetchAllStatus(payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "purchaseOrder", templateName: "standard" });
      if (txPreferences && txPreferences.tablePreference) {
        let txColumns = ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [];
        txColumns = txColumns.length ? txColumns: this.state.txMandatoryColumns;
        const txColumnSetting = ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.purchaseOrderFilterDrawerVisible && <PurchaseOrderFilterComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />}
        <PurchaseOrderListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    purchaseOrderList: state.purchaseOrder.purchaseOrderList,
    purchaseOrderCount: state.purchaseOrder.purchaseOrderCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    purchaseOrderDraftList: state.purchaseOrder.purchaseOrderDraftList,
    purchaseOrderDraftCount: state.purchaseOrder.purchaseOrderDraftCount,
    suppliers: state.supplier.suppliers,
    projectList: state.common.projectList,
    userStatus: state.common.userStatus,
    listLoading:state.common.listLoading,
    headerLoading:state.common.headerLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPurchaseOrders,
  getPurchaseOrdersCount,
  resetPaginatedData,
  showModal,
  hideModal,
  pushModalToStack,
  deletePurchaseOrder,
  popModalFromStack,
  fetchUserPreferences,
  createAuditTrail,
  deleteAuditTrail, 
  saveUserPrefrences,
  fetchPurchaseOrderDrafts, 
  getPurchaseOrderDraftCount, 
  resetDraftPaginatedData,
  updateUserStatus,
  fetchSuppliers,
  fetchProjects,
  fetchAllStatus,
  fetchPurchaseOrdersByFilter,
  getPurchaseOrdersCountByFilter,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderListing);
