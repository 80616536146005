import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';

import { fetchDataIfNeeded, fetchPaginationDataIfNeeded, addDaysToDate } from '../../../../utils';
import MultiCurrencySalesInvoice from '../../../../components/customer/invoice/MultiCurrencySalesInvoice';
import LedgerDrawer from '../../../../containers/drawer/ledgers/SalesInvoiceLedger'
import {
    fetchProducts, fetchPaymentTerms, fetchPriceList,
    fetchStates, fetchProjects, fetchTaxes, fetchUOM, addUOM, deleteUOM,
    fetchExpenseTypes, addExpenseTypes, deleteExpenseTypes, showModal, hideModal, pushModalToStack, popModalFromStack,
    fetchCustomerDetailsByCustomerId, addPaymentTerm, deletePaymentTerm,
    deleteProject, deleteAddress, deleteTax, deletePriceList,
    fetchDocuments, addDocumentName, deleteDocumentName, deleteContact, fetchCountries,fetchAddresses,
    fetchUserPreferences, saveUserPrefrences, fetchRelationshipTaxIdentifications, deleteCustomerTaxIdentifications, deleteRelationshipTaxIdentifications, resetCustomerDetail,
    getRelationshipAddress
} from './../../../../actions/commonActions';
import { fetchCustomers } from '../../Listing/action';
import { createSalesInvoice, fetchSoDataForInvoice, resetSoInvoiceData, getNextTxNumber, createSalesInvoiceDraft} from './action';
import { LEDGER_ACTION_LIST, CONSTANTS, LEDGER_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY } from '../../../../static/constants';
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import SalesInvoiceDetails from '../../../modal/modalBody/customer/SalesInvoiceDetail';
import { deleteCustomers } from '../../Listing/action';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import TransportDataDrawer from '../../../../containers/drawer/transportData/index';
import { fetchAllContacts } from '../../Listing/ShowConact/action';

class SalesInvoice extends Component {

    constructor(props) {
        super(props);
        const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES }) || {};
        const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE }) || {};
        const defaultSalesOutputLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX }) || {};
        const defaultSalesDiscountLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_DISCOUNT }) || {};
        const defaultDebtorsLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEBTORS }) || {};
        const companyInfo = props.companyInfo;
        const allRelationshipBillingAddress = filter(companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
        const linkProps = (this.props.location && this.props.location.state) || {};
        this.state = {
            customer: linkProps.customer,
            customerName: (linkProps.customer || {}).companyName,
            stateCustomerId: linkProps.customerId,
            pageNumber: linkProps.pageNumber || 1,
            pageSize: 100,
            cols: 20,
            searchedText: '',
            isSoConversionToInvoice: false,
            ledgerDrawerVisible: false,
            invoiceDate: new Date(),
            salesLedgerId: defaultSalesLedger.ledgerAccountId,
            salesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            expenseLedgerId: defaultExpenseLedger.ledgerAccountId,
            expenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            salesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
            taxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            salesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
            discountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,

            igstSalesLedgerId: defaultSalesLedger.ledgerAccountId,
            igstSalesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            igstExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
            igstExpenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            igstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
            igstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            igstSalesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
            igstDiscountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      
            cgstSalesLedgerId: defaultSalesLedger.ledgerAccountId,
            cgstSalesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            cgstExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
            cgstExpenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            cgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
            cgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            cgstSalesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
            cgstDiscountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      
            sgstSalesLedgerId: defaultSalesLedger.ledgerAccountId,
            sgstSalesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            sgstExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
            sgstExpenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            sgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
            sgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            sgstSalesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
            sgstDiscountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            allRelationshipBillingAddress,
            relationshipBillingAddress,
            priceTypeToApply: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0],
            customerShippingAddress: undefined,
            customerBillingAddress: undefined,
            customerPaymentTerm: undefined,
            customerRef: undefined,
            customerPo: undefined,
            placeOfSupply: undefined,
            customerRemarks: undefined,
            internalRemarks: undefined,
            footer: undefined,
            customerSalesOrderDetailsList: undefined,
            inheritedTxColumn: [],
            txPreferences: { txType: "salesInvoice", templateName: "multicurrency" },
            txColumns: [],
            txColumnSetting: {},
            txMandatoryColumns: [
                GRID_PREFERENCE_CONSTANTS.PRODUCT,
                GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
                GRID_PREFERENCE_CONSTANTS.QUANTITY,
                GRID_PREFERENCE_CONSTANTS.RATE,
                GRID_PREFERENCE_CONSTANTS.AMOUNT,
            ],
            txAvailableColumn: {
                product: true,
                quantity: true,
                rate: true,
                amount: true,
                description: true,
                uom: true,
                hsn: true,
                basePrice: true,
                specialDiscount: true,
                discount: true,
                comment: true,
                stockNumber: true,
                partNumber: true,
                origin: true,
                hsCode: true,
                materialNumber: true
            },
        };
    }

    componentWillReceiveProps(props) {

        if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
            const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
            let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
              return obj.relationshipTaxIdentificationId;
            })
            this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
          }

        if (props.documentList && props.documentList.length && !this.state.docName) {
            props.documentList.forEach((document) => {

                if (document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE] && document.isDefault) {
                    this.setState({
                        documentNameId: document.documentNameId,
                        docName: document.docName
                    })
                }
            })

        }


        if (!this.state.remarksCustomer && props.remarksData &&  props.remarksData.isFetched && !this.state.isRemarksSet) {
            this.setState({ remarksCustomer: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
            setTimeout(()=>{
              props.resetRemarksData();
          },500)
          }
        if (props.taxes && props.taxes.length && props.soInvoiceData && props.soInvoiceData.salesOrderMasterId) {
            const detailList = [];
            const inheritedTxColumn = [];
            const _emptyColumnCheck = (item, taxPercent) => {
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
                }
                // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
                //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
                // }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.warehouse) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) < 0 && item.materialNumber) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER);
                }
            }
            props.soInvoiceData.customerSalesOrderDetailsList.map((ele) => {
                const tax = (find(ele.customerSalesOrderCOATxList, (ele) => {
                    return ele.txType === LEDGER_TYPE.TYPE_TAX_IGST || ele.txType === LEDGER_TYPE.TYPE_TAX_CGST || ele.txType === LEDGER_TYPE.TYPE_TAX_SGST || ele.txType === LEDGER_TYPE.TYPE_TAX_VAT
                }) || {});
                const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
                _emptyColumnCheck(ele, tax.amountPercent);
                return detailList.push({
                    selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
                    version: ele.version,
                    product: ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || ""),
                    productObj: { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku },
                    itemVariantName: ele.itemVariantName,
                    variant: {
                        attributeId1: ele.attributeId1,
                        attributeId2: ele.attributeId2,
                        attributeId3: ele.attributeId3,
                        attributeName1: ele.attributeName1,
                        attributeName2: ele.attributeName2,
                        attributeName3: ele.attributeName3,
                        attributeValue1: ele.attributeValue1,
                        attributeValue2: ele.attributeValue2,
                        attributeValue3: ele.attributeValue3
                    },
                    description: ele.description,
                    scrollHeight: ele.rowHeight,
                    qty: ele.quantityToInvoice,
                    discount: (find(ele.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
                    tax: (tax.amountPercent || 0) * (tax.txType === LEDGER_TYPE.TYPE_TAX_IGST || tax.txType === LEDGER_TYPE.TYPE_TAX_VAT ? 1 : 2),
                    taxApplied: appliedTax.taxNameDisplay,
                    taxName: appliedTax.taxName,
                    taxId: appliedTax.taxSingleRateId,
                    quantityOrdered: ele.quantity,
                    quantityInvoiced: ele.quantityInvoiced,
                    rate: ele.anItemSalePrice,
                    baseRate: ele.basePrice,
                    uom: ele.uomName,
                    hsnCode: ele.hsnCode,
                    specialDiscount: ele.basePrice && ele.specialDiscount,
                    comment: ele.warehouse,
                    stockNumber: ele.stockNumber,
                    partNumber: ele.partNumber,
                    origin: ele.origin,
                    hsCode: ele.hsCode,

                })
            });

            const rootDiscount = (find((props.soInvoiceData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent || 0;
            const expenseAmount = (find((props.soInvoiceData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_EXPENSE }) || {}).amount || 0;
            const forceHideTxColumn = [];
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
                forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
                forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
            }
            this.setState({
                version: props.soInvoiceData.version,
                currencyCode: props.soInvoiceData.foreignCurrency,
                currencyIcon: props.soInvoiceData.foreignCurrencyIcon,
                exchangeRate: props.soInvoiceData.exchangeRate,
                isSoConversionToInvoice: true,
                salesOrderMasterId: props.soInvoiceData.salesOrderMasterId,
                soNumber: props.soInvoiceData.soNumber,
                relationshipBillingAddress: find(props.soInvoiceData.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }),
                customerShippingAddress: find(props.soInvoiceData.boLocationSOList, { locationType: CONSTANTS.SHIPPING_ADDRESS }),
                customerBillingAddress: find(props.soInvoiceData.boLocationSOList, { locationType: CONSTANTS.BILLING_ADDRESS }),
                customerPaymentTerm: props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: props.soInvoiceData.paymentTermId }) || {} : {},
                customerRef: props.soInvoiceData.referenceNumber,
                customerPo: props.soInvoiceData.customerPONumber,
                placeOfSupply: props.soInvoiceData.placeOfSupply,
                customerName: props.soInvoiceData.customerName,
                customer: {
                    companyName: props.soInvoiceData.customerName, customerId: props.soInvoiceData.customerId
                },
                customerRemarks: props.soInvoiceData.remarksCustomer,
                internalRemarks: props.soInvoiceData.remarksInternal,
                footer: props.soInvoiceData.footer,
                customerSalesOrderDetailsList: detailList,
                isRootDiscount: rootDiscount ? true : false,
                rootDiscountPercent: rootDiscount,
                expenseId: props.soInvoiceData.expenseId,
                rootDiscountAmount: (find((props.soInvoiceData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amount || 0,
                expenseAmount: expenseAmount,
                projectName: props.soInvoiceData.projectName,
                projectNumber: props.soInvoiceData.projectNumber,
                projectMasterId: props.soInvoiceData.projectMasterId,
                isRootExpense: expenseAmount ? true : false,
                inheritedTxColumn: inheritedTxColumn,
                forceHideTxColumn: [],
                tableDataReRendered: true,
                salesQuotationMasterId: props.soInvoiceData.salesQuotationMasterId,
                quotationNumber: props.soInvoiceData.quotationNumber,
                rfqNumber: props.soInvoiceData.rfqNumber,
                rfqMasterId: props.soInvoiceData.rfqMasterId,
                comment: props.soInvoiceData.warehouse
            });

            props.resetSoInvoiceData();
        }
        if (this.state.stateCustomerId && props.customers[this.state.pageNumber] && props.customers[this.state.pageNumber].length && props.paymentTerms && props.paymentTerms.length) {
            const customer = find(props.customers[this.state.pageNumber], { customerId: Number(this.state.stateCustomerId || 0) }) || {};
            const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
            const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
            const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
            const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
            const customerPaymentTerm = props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: ((customer && customer.paymentTermId) || 0) }) || {} : {};
            let numberOfDays = customerPaymentTerm.numberOfDays;

            this.setState({
                stateCustomerId: null,
                customer,
                customerName: customer.companyName,
                customerPaymentTerm,
                customerShippingAddress: this.state.customerShippingAddress || defaultShippingAddress,
                customerBillingAddress: this.state.customerBillingAddress || defaultBillingAddress,
                placeOfSupply: defaultBillingAddress && defaultBillingAddress.stateName,
                invoiceDueDate: numberOfDays ? addDaysToDate((props.invoiceDate || new Date()), numberOfDays) : null,
                boContactList: customer.boContactList || [],
            });
        }

        if (props.customerDetail && props.customerDetail.customerId) {
            const customer = props.customerDetail;
            const linkProps = (this.props.location && this.props.location.state) || {};
            const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
            const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
            const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
            const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
            const customerPaymentTerm = props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: ((customer && customer.paymentTermId) || 0) }) || {} : {};
            let numberOfDays = customerPaymentTerm.numberOfDays;
            const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
            let selectedCustomerTaxIdentifications = [];
            const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
            selectedCustomerTaxIdentifications = selectedList.map(obj => {
                return obj.customerTaxIdentificationId;
            })
            if((linkProps.soMasterId || linkProps.invoiceMasterId) && linkProps.customerId === customer.customerId){
                this.setState({
                    customer,
                    customerName: customer.companyName,
                    customerPaymentTerm,
                    placeOfSupply: defaultShippingAddress.stateName || defaultBillingAddress.stateName,
                    invoiceDueDate: numberOfDays ? addDaysToDate((props.invoiceDate || new Date()), numberOfDays) : null,
                    paymentTermDays: numberOfDays,
                    selectedCustomerTaxIdentifications: selectedCustomerTaxIdentifications,
                    selectedSalesOrder: props.preservedSalesOrder || [],
                    preservedSalesOrder: [],
                    allBillingAddress,
                    allShippingAddress,
                    //selectedSalesOrderIds: [],
                    isShipmentConversion: false
                });
            }else{
                this.setState({
                    customer,
                    customerName: customer.companyName,
                    customerPaymentTerm,
                    customerShippingAddress: defaultShippingAddress,
                    customerBillingAddress: defaultBillingAddress,
                    placeOfSupply: defaultShippingAddress.stateName || defaultBillingAddress.stateName,
                    invoiceDueDate: numberOfDays ? addDaysToDate((props.invoiceDate || new Date()), numberOfDays) : null,
                    selectedContact,
                    paymentTermDays: numberOfDays,
                    selectedCustomerTaxIdentifications: selectedCustomerTaxIdentifications,
                    selectedSalesOrder: props.preservedSalesOrder || [],
                    preservedSalesOrder: [],
                    allBillingAddress,
                    allShippingAddress,
                    selectedSalesOrderIds: [],
                    isShipmentConversion: false
                });
            }
            setTimeout(() => {
                props.resetCustomerDetail();
            }, 4000)
        }

        if (props.allPreferences && props.allPreferences.length) {
            let txPreferences = find(props.allPreferences, { txType: "salesInvoice", templateName: "multicurrency" }) || {};
            if (txPreferences) {
                let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
                txColumns  = txColumns.length ? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
                const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : {};
                const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
                let tablePreference = txPreferences.tablePreference;
                this.setState({
                    tablePreference,
                    isColumnFitTable,
                    txColumns,
                    txColumnSetting,
                    txPreferences
                });
            }else{
                this.setState({
                    txColumns  : this.state.txMandatoryColumns
                })
            }
        }

        const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        this.setState({
            allRelationshipBillingAddress,
        });
        if (props.customerContactList && props.customerContactList.length) {
            this.setState({
              boContactList: props.customerContactList
            });
          }
    }

    componentDidMount() {
        // this.props.updateHeaderState({
        //     collapsed: true
        // })
        const companyInfo = this.props.companyInfo || {};
        const linkProps = (this.props.location && this.props.location.state) || {}
        const payload = {
            customerId: this.props.customerId || linkProps.customerId,
            soMasterId: this.props.soMasterId || linkProps.soMasterId,
            relationshipId: companyInfo.relationshipId,
            userId: (this.props.userInfo || {}).userId,
            pageNumber: 1,
            pageSize: 100
        };
        this.props.resetSoInvoiceData();
        payload.soMasterId && fetchDataIfNeeded('fetchSoDataForInvoice', 'soInvoiceData', this.props, payload, true);
        //payload.customerId && fetchDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerDetail', this.props, payload, true);
        payload.customerId && this.props.fetchAddresses({ ...payload, locationType: CONSTANTS.SHIPPING_ADDRESS });
        payload.customerId && this.props.fetchAddresses({ ...payload, locationType: CONSTANTS.BILLING_ADDRESS });
        payload.customerId && this.props.fetchAllContacts(payload);
        fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
        fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
        fetchDataIfNeeded('fetchPriceList', 'priceList', this.props, payload);
        fetchDataIfNeeded('fetchStates', 'states', this.props, 1);
        fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
        fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
        fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
        fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
        fetchDataIfNeeded('fetchExpenseTypes', 'expenseTypes', this.props, payload);
        fetchDataIfNeeded('fetchDocuments', 'documentList', this.props, payload);
        if (!linkProps.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
        fetchDataIfNeeded('fetchCountries', 'countries', this.props, payload);
        fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
        this.props.fetchRemarksTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Sales Invoice' });
        fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
    }



    openSalesInvoiceDetails = (data) => {
        const invoicePayload = {
            customerId: data.customerId,
            invoiceMasterId: data.invoiceMasterId
        }
        this.props.pushModalToStack({
            modalBody: <SalesInvoiceDetails {...this.props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    onSearch(text, updateAllOptions, updateFetching) {
        const payload = {
            relationshipId: (this.props || {}).companyInfo.relationshipId,
            pageNumber: 1,
            searchString: text,
            pageSize: 100,
            updateAllOptions,
            updateFetching
        };
        this.props.fetchProducts(payload)
    }

    render() {
        return (
            <div className="new-invoice-modal">
                <LedgerDrawer {...this.state} updateState={(data) => { this.setState(data) }} />
                {this.state.transportDataDrawerVisible && <TransportDataDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
                <MultiCurrencySalesInvoice
                    onSearch={(text, updateAllOptions, updateFetching) => {
                        this.onSearch(text, updateAllOptions, updateFetching)
                    }}
                    {...this.props} {...this.state} openSalesInvoiceDetails={this.openSalesInvoiceDetails} updateState={(data) => { this.setState(data) }} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        paymentTerms: state.common.paymentTerms,
        customers: state.customer.customers,
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        priceList: state.common.priceList,
        states: state.common.states,
        products: state.salesInvoice.products,
        projectList: state.common.projectList,
        uomList: state.common.uomList,
        soInvoiceData: state.so.soInvoiceData,
        taxes: state.common.taxes,
        expenseTypes: state.common.expenseTypes,
        customerDetail: state.common.customerDetail,
        documentList: state.common.documentList,
        nextTxNumber: state.salesInvoice.nextTxNumber,
        countries: state.common.countries,
        allPreferences: state.common.allPreferences,
        remarksData: state.settings.remarksData,
        permissions: state.auth.permissions,
        allRelationshipTaxIdentificatins: state.common.allRelationshipTaxIdentificatins,
        customerContactList: state.customer.contacts,
        addresses: state.customer.addresses,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCustomerDetailsByCustomerId,
    createSalesInvoice,
    resetSoInvoiceData,
    fetchSoDataForInvoice,
    fetchPaymentTerms,
    fetchCustomers,
    fetchPriceList,
    fetchCountries,
    fetchStates,
    fetchProjects,
    fetchProducts,
    fetchTaxes,
    fetchUOM,
    addUOM,
    deleteUOM,
    fetchExpenseTypes,
    addExpenseTypes,
    deleteExpenseTypes,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    deleteCustomers,
    addPaymentTerm,
    deletePaymentTerm,
    deleteProject,
    deleteAddress,
    deleteTax,
    resetRemarksData,
    deletePriceList,
    fetchDocuments,
    addDocumentName,
    deleteDocumentName,
    getNextTxNumber,
    deleteContact,
    fetchUserPreferences,
    saveUserPrefrences,
    fetchRemarksTemplate,
    fetchRelationshipTaxIdentifications,
    deleteCustomerTaxIdentifications, 
    deleteRelationshipTaxIdentifications,
    createSalesInvoiceDraft,
    fetchAllContacts,
    fetchAddresses,
    resetCustomerDetail,
    getRelationshipAddress
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoice);
