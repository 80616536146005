import { RETURN_SETTINGS } from '../static/constants';
const initialState = {
    returnDetails: [],
}

const ReturnReducer = (state = initialState, action) => {
  switch (action.type) {
    case RETURN_SETTINGS.RETURN_DETAILS:
      return { ...state, returnDetails: action.data };

    default:
      return state;
  }
};

export default ReturnReducer;