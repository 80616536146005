import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InvoiceInformationComponent from '../../../../../components/modal/modalBody/customer/InvoiceInformation';
import { fetchSalesOrderInvoice } from './action';
import { fetchDataIfNeeded } from  '../../../../../utils';
import { fetchSODetail } from '../SODetail/action';
class InvoiceInformation extends Component {
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            customerId: this.props.soData.customerId,
            salesOrderMasterId: this.props.soData.salesOrderMasterId,
            relationshipId: companyInfo.relationshipId

        }
        fetchDataIfNeeded('fetchSalesOrderInvoice', 'salesInvoiceListBySoId', this.props, payload, true);
        this.props.fetchSODetail(payload);

    }

    render() {
        return <InvoiceInformationComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        salesInvoiceListBySoId: state.salesInvoice.salesInvoiceListBySoId,
        soDetail: state.so.soDetail,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSalesOrderInvoice,
    fetchSODetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceInformation);