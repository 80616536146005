import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer } from 'antd';
// import { CustomButton } from '../../general/CustomButton';
// import { ErrorMsg } from '../../general/ErrorMessage';
// import AddressCompo from '../../../components/modal/modalBody/common/Address';
// import { Checkbox, Tooltip } from 'antd';
// import { DropdownRef } from '../../general/Dropdown';
// import { TextBox } from '../../general/TextBox';
// import { ICONS } from '../../../static/constants';
// import { showToasterMessage } from '../../../utils';
// import LogCommunication from '../../customer/listing/LogCommunication';

function CommunicationDrawer(props) {
   
  const closeDrawer = () => {
    props.updateState({
      communication: {
        commDrawer: false
      }
    });
  }
//   const formItemLayout = {
//     labelCol: {
//       xs: { span: 24 },
//       sm: { span: 8 },
//     },
//     wrapperCol: {
//       xs: { span: 24 },
//       sm: { span: 16 },
//     },
//   };

//   const tailFormItemLayout = {
//     wrapperCol: {
//       xs: {
//         span: 24,
//         offset: 0,
//       },
//       sm: {
//         span: 24,
//         offset: 0,
//       },
//     },
//   };



//   const _getLocationType = () => {
//     if (((props).formData).addressType === 'relationship') {
//       return [
//         {
//           name: "Shipping Address",
//           value: "RelationshipShippingAddress"
//         },
//         {
//           name: "Billing Address",
//           value: "RelationshipBillingAddress"
//         }
//       ]
//     } else {
//       return [
//         {
//           name: "Billing Address",
//           value: "BillingAddress"
//         },
//         {
//           name: "Shipping Address",
//           value: "ShippingAddress"
//         }
//       ]
//     }
//   }
//  const  handleSubmit = e => {
//     e.preventDefault();
//     props.form.validateFieldsAndScroll((err, values) => {
//       if (!err) {
//         const payload = {
//           locationName: values.locationName,
//           streetAddress1: values.streetAddress1,
//           version: ((props).formData).version,
//           streetAddress2: values.streetAddress2,
//           cityName: values.cityName,
//           locationId: props.locationId ? props.locationId : 0,
//           countryId: props.countryId || ((props).formData).countryId,
//           countryName: values.countryName,
//           stateName: values.stateName,
//           zipCode: values.zipCode,
//           customerId: ((props).formData).customerId,
//           supplierId: ((props).formData).supplierId,
//           locationType: values.locationType || ((props).formData).locationType,
//           relationshipId: (props.companyInfo).relationshipId,
//           //dateCreated: getCurrentDateForBackend(new Date()),
//           //dateUpdated: getCurrentDateForBackend(new Date()),
//           isDefault: values.isDefault ? 1 : 0,
//         }

//         if (props && props.formData && props.formData.customerId) {
//           payload.customerId = props.formData.customerId;
//         }
//         else if (props && props.formData && props.formData.supplierId) {
//           payload.supplierId = props.formData.supplierId;
//         }

//         if (!payload.locationType) {
//           return showToasterMessage({
//             description: 'Location type is mandatory.',
//             messageType: 'error'
//           })
//         }

//         const modalData = {
//           modalBody: 'Are you sure you want to create address ?',
//           handleSubmit: () => {
//             props.createAddress(payload, (props || {}).formData, props);
//             // props.popModalFromStack();
//             closeDrawer()
//           }
//         };
//         props.pushModalToStack(modalData);
//       }
//     });
//   };

  return (
    <Fragment>
      <Drawer 
        title={
          <div className="drawer-head-txt">
            {/* <FormattedMessage id={props.isEditLc ? 'purchase.landindCostSetup.drawer.title.update' : 'purchase.landindCostSetup.drawer.title.create'} defaultMessage='' /> */}
       
       Communication
          </div>
        }
        width={720}
        zIndex={1}
        maskClosable={false}
        keyboard={false}
        onClose={closeDrawer}
        className="custom-drawer"
        // afterVisibleChange={
        //   (visible) => {
        //     if (visible) {
        //       props.initCalls();
        //     }
        //   }
        // }
        visible={props?.commDrawer}
        // footer={
        //   <>
        //   <CustomButton
        //     intlId='confirmation.cancel'
        //     defaultMessage=''
        //     type='default'
        //     key={0}
        //     style={{ marginRight: 8 }}
        //     onClick={closeDrawer}
        //   />
        //   <CustomButton
        //     intlId='confirmation.submit'
        //     defaultMessage=''
        //     htmlType='submit'
        //     onClick={()=>{handleSubmit(props)}}
        //     key={1}
        //   />
        //   </>
        // }
      >


{/* <Form layout="vertical" onSubmit={handleSubmit} {...props} hideRequiredMark>

<Row gutter={16}>
  <Col span={12}>
    <Form.Item label={(

      <>
        <span className="text-input-label">
          <FormattedMessage id='address.primary' defaultMessage='' />

        </span>
        <Tooltip placement="bottom" title={<FormattedMessage id='address.primary.message' defaultMessage="" />}>

          <i className={`cursor-pointer ${ICONS.HELP} `} />
        </Tooltip></>
    )} colon={false}>
      {getFieldDecorator('isDefault', {
        initialValue: ((props).formData).isDefault
      })(<Select defaultValue="yes" >
        <Option value="yes">Yes</Option>
        <Option value="no">No</Option>
      </Select>)}
    </Form.Item>
  </Col>
  <Col span={12}>
    <Form.Item label={(
      <><span>{<FormattedMessage id='address.type' defaultMessage='' />}</span>{" "}
        <Tooltip placement="bottom" title="Select address type">
          <i className={`cursor-pointer ${ICONS.HELP} mr5`} />

        </Tooltip></>
    )} colon={false}>
      {getFieldDecorator('locationType', {
        initialValue: ((props).formData).locationType
      })(
        <DropdownRef
          items={_getLocationType()}
          optionKeyName='value'
          valueKeyName='name'
          onSelect={(selectedValue, option) => {
            props.form.setFieldsValue({ 'locationType': selectedValue });
          }}
        />
      )}
    </Form.Item>
  </Col>
</Row>


<Row gutter={16}>
  <Col span={12}>
    <Form.Item label={<FormattedMessage id='address.streetName' defaultMessage='' />} colon={false}>
      {getFieldDecorator('streetAddress1', {
        initialValue: props.streetAddress1 || '',
        rules: [
          {
            required: true,
            message: <FormattedMessage id='address.validation.street' defaultMessage='' />,
          }]
      })
        (<TextBox maxLength={45} placeholder={props.intl.formatMessage({ id: 'address.streetName', defaultMessage: '' })} />)}
    </Form.Item>
  </Col>

  <Col span={12}>
    <Form.Item label={<FormattedMessage id='address.streetNameContd' defaultMessage='' />} colon={false}>
      {getFieldDecorator('streetAddress2', {
        initialValue: props.streetAddress2 || ''
      })
        (<TextBox maxLength={45} placeholder={props.intl.formatMessage({ id: 'address.streetNameContd', defaultMessage: '' })} />)}
    </Form.Item>
  </Col>
</Row>



<Row gutter={16}>
  <Col span={12}>
    <Form.Item label={<FormattedMessage id='address.city' defaultMessage='' />} colon={false}>
      {getFieldDecorator('cityName', {
        initialValue: props.cityName || '',
        rules: [
          {
            required: props.countryName === 'India' ? true : false,
            message: <FormattedMessage id='address.validation.city' defaultMessage='' />,
          }]
      })
        (<TextBox maxLength={25} placeholder={props.intl.formatMessage({ id: 'address.city', defaultMessage: '' })} />)}
    </Form.Item>
  </Col>

  <Col span={12}>
    <Form.Item label={<FormattedMessage id='address.country' defaultMessage='' />} colon={false} >
      {getFieldDecorator('countryName', {
        initialValue: ((props).formData).countryName || props.countryName,
        rules: [
          {
            required: true,
            message: <FormattedMessage id='signup.validation.country' defaultMessage='' />,
          }]
      })
        (<DropdownRef
          items={props.countries || []}
          optionKeyName='countryId'
          valueKeyName='countryName'
          onSelect={(selectedValue, option) => {
            props.updateState({ 'countryId': selectedValue });
            props.form.setFieldsValue({ 'countryName': option.props.name });
            props.fetchStates(selectedValue)
          }}
        />)}
    </Form.Item>
  </Col>
</Row>


<Row gutter={16}>
  <Col span={12}>
    <Form.Item label={<FormattedMessage id='address.country' defaultMessage='' />} className='hide' colon={false}>
      {props.countries && getFieldDecorator('countryId', {
        initialValue: ((props).formData).stateName
      })
        (<span />)}
    </Form.Item>
  </Col>

  <Col span={12}>
    <Form.Item label={<FormattedMessage id='address.state' defaultMessage='' />} className='hide' colon={false}>
      {getFieldDecorator('stateName', {
        initialValue: props.stateName || ''
      })
        (<span />)}
    </Form.Item>
  </Col>
</Row>


<Row gutter={16}>
  <Col span={12}>
    {(props.form.getFieldValue('countryName') === 'India' || props.form.getFieldValue('countryName') === 'United States' || props.form.getFieldValue('countryName') === 'United States (USA)') ?

      <Form.Item label={<FormattedMessage id='address.state' defaultMessage='' />} colon={false}>
        {getFieldDecorator('stateId', {
          initialValue: ((props).formData).stateName,
          rules: [
            {
              required: true,
              message: <FormattedMessage id='address.validation.state' defaultMessage='' />,
            }]
        })
          (<DropdownRef
            items={props.states || []}
            optionKeyName='stateId'
            valueKeyName='stateName'
            onSelect={(selectedValue, option) => {
              props.form.setFieldsValue({ 'stateId': selectedValue });
              props.form.setFieldsValue({ 'stateName': option.props.name });
            }}
          />)}
      </Form.Item>
      :
      <Form.Item label={<FormattedMessage id='address.state' defaultMessage='' />} colon={false}>
        {getFieldDecorator('stateName', {
          initialValue: props.stateName || '',
          rules: [
            {
              required: false,
              message: <FormattedMessage id='address.validation.state' defaultMessage='' />,
            }]
        })
          (<TextBox maxLength={45} placeholder={props.intl.formatMessage({ id: 'address.state', defaultMessage: '' })} />)}
      </Form.Item>

    }
  </Col>

  <Col span={12}>
    <Form.Item label={<FormattedMessage id='address.zip' defaultMessage='' />} className='hide' colon={false} >
      {getFieldDecorator('zipCode', {
        initialValue: props.zipCode || ''
      })
        (<span />)}
    </Form.Item>
    <Form.Item label={<FormattedMessage id='address.zip' defaultMessage='' />} colon={false}>
      {getFieldDecorator('zipCode', {
        initialValue: props.zipCode || ''
      })
        (<TextBox maxLength={25} placeholder={props.intl.formatMessage({ id: 'address.zip', defaultMessage: '' })} />)}
    </Form.Item>

  </Col>
</Row>

<Row gutter={16}>

<Col span={24}>


<Form.Item {...tailFormItemLayout} className="modal-footer address-submit-btn">
  <div key={0} className='customModal-button-group'>
    <CustomButton
      intlId={'modal.address.button.cancel'}
      defaultMessage={''}
      type={'default'}
      key={1}
      onClick={() => {
     closeDrawer()

      }}
    />
    <CustomButton
      intlId={'button.save.label'}
      defaultMessage={''}
      htmlType='submit'
      // onClick={()=>{}}
      key={2}
    />
  </div>
</Form.Item>
</Col>


</Row>
</Form> */}

      {/* <LogCommunication {...props}/> */}
        Hello
      </Drawer>
    </Fragment>
  );
}



export default Form.create()(injectIntl(CommunicationDrawer))
 
