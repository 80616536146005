import axios from "axios";
import config from "../../../../config/environmentConfig";
import { SETTING_ACTION_LIST, FUNDRAISING_ACTION_LIST } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
// import { Router } from 'react-router'
// import createBrowserHistory from 'history/createBrowserHistory'

const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const saveFundraisingCampaign = (payload, props, history) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fundraisingCampaign/saveOrUpdate`,
        payload
      )
      .then((response) => {
        if (props) {
          if (!props.fundraisingCampaignId) {
            history.push({
              pathname: '/admin/fundraising-t',
              state: {
                templateType: 'fundraising',
                alpideFormBuilderId: (response.data || {}).formBuilderId,
                fundraisingCampaignId: (response.data || {}).fundraisingCampaignId,
                alpidePaymentGatewayId: (response.data || {}).alpidePaymentGatewayId,
                projectMasterId: (response.data || {}).projectMasterId,
                chartOfAccountDetailsId: (response.data || {}).chartOfAccountDetailsId,
              }
            })
          }
          props.updateHeaderState({
            fundraising: {
              addCampaignDrawerVisible: false
            }
          });
        
          props.openCampaignDrawerFromListing && props.getFundraisingCampaign && props.getFundraisingCampaign(props);
          props.openCampaignDrawerFromListing && props.getFundraisingCampaignCount && props.getFundraisingCampaignCount(props);
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const fetchRegistrationFormSettingList = (payload) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/getAllForms?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize}&pageNumber=${payload.pageNumber}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SETTING_ACTION_LIST.REGISTRATION_FORM_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.REGISTRATION_FORM_LIST;
        }
      })
  }
}


export const doesCampaignCodeExist = (props) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fundraisingCampaign/doesCampaignCodeExist?relationshipId=${props.relationshipId}&campaignCode=${props.campaignCode}`)
      .then(res => {
        // const { data } = res
        if (res.data) {
          props.updateState({campaignCode: ''});
          showToasterMessage({ messageType: 'error', description: lang['campaignCode.exist.message'] })
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.REGISTRATION_FORM_LIST;
        }
      })
  }
}


export const getNextTxNumber = (payload) => {
  return dispatch => {
    dispatch({ type: FUNDRAISING_ACTION_LIST.NEXT_CAMPAIGN_CODE_RESET });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fundraisingCampaign/nextCampaignCode?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: FUNDRAISING_ACTION_LIST.NEXT_CAMPAIGN_CODE, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
        } else {
          err.actionToCall = FUNDRAISING_ACTION_LIST.NEXT_CAMPAIGN_CODE;
        }
      })
  }
}
