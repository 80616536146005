import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProccessPaymnetComp from '../../components/invoicePreview/ProccessCardConnectPaymnet';
import { getInvoiceDetail, getPayOnlineUrl, processCCPayment } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../actions/commonActions';
//import queryString from 'query-string';

class ProccessPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      neverExpires: 1
    }
  }

  componentDidMount() {
    // const linkProps = queryString.parse(this.props.location.search) || {}
    // const payload = {
    //   webInvoiceId: this.props.webInvoiceId || linkProps.webInvoiceId,
    // };
    //this.props.getInvoiceDetail(payload); 
  }

  render() {
    return (
      <Fragment>
        <ProccessPaymnetComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} updateOrderState={(data) => { this.props.updateState(data) }}/>
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getInvoiceDetail,
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  getPayOnlineUrl,
  processCCPayment
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProccessPayment);
