import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Select, Slider } from 'antd';
// import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
const { Option } = Select;

function ButtonProps(props) {
    let buttonRef = document.getElementById("link_"+props.childId) || {};
    const [buttonWidth, updateWidth] = useState(buttonRef.offsetWidth);

    return (
        <Fragment>
                    <div>
                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='linkName.text' />
                        </Col>
                        <Col span={24}>
                            <Input value={props.buttonName || buttonRef.innerHTML} onChange={(e) => {
                                buttonRef.innerHTML = e.target.value;
                                props.updateState({buttonName : e.target.value})
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='linkAction.text' />
                        </Col>
                        <Col span={24}>
                            <Input onChange={(e) => {
                                //document.getElementById("button_"+props.childId).onclick = function() { window.location=e.target.value;};
                                let val = e.target.value;
                               // document.getElementById("button_"+props.childId).onclick = function(){ window.location= val} ;
                                document.getElementById("button_"+props.childId).href = val
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={18}>
                            <FormattedMessage id='buttonTextSize.text' />
                        </Col>
                        <Col span={24}>
                            <Button title='Small' onClick={() => {
                                buttonRef.style.fontSize = '20px';
                                updateWidth(120);
                            }}style={{borderRadius:'4px 0px 0px 4px'}}>
                                <span className='cirty'>
                                S </span></Button>
                            <Button title='Medium' onClick={() => {
                                 buttonRef.style.fontSize = '40px';
                                updateWidth(150);
                            }} style={{borderRadius:'0px 0px 0px 0px '}}>
                                <span className='cirty'>M </span></Button>
                            <Button title='Large' onClick={() => {
                                 buttonRef.style.fontSize = '60px';
                                updateWidth(170);
                            }} style={{borderRadius:'0px 4px 4px 0px '}}><span className='cirty'> L</span></Button>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={6}>
                        <FormattedMessage id='buttonTextSize.text' />
                        </Col>
                        <Col span={18}>
                        <Slider min={1} max={50} onChange={(val) => {
                                buttonRef.style.fontSize = val+'px';
                            }}/>
                        </Col>
                        </Row>
                  

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='buttonPosition.text' />
                        </Col>
                        <Col span={24} >
                            <Button title='Left' onClick={() => {
                                buttonRef.style.marginLeft = "10px";
                                buttonRef.style.float = 'left';
                                buttonRef.style.margin = "15px auto 0px";
                            }} style={{borderRadius:'4px 0px 0px 4px', position:'relative'}}>      
                            
                            <div className="leftd-roud1"></div>    
                            </Button>
                            <Button title='Middle' onClick={() => {
                                buttonRef.style.float = 'left';
                                buttonRef.style.marginLeft =  ((680-buttonWidth)/2)+"px";
                            }} style={{borderRadius:' 0px ', position:'relative'}} >      
                             <div className="mrd-roud1"></div>
                            </Button>
                            <Button title='Right' onClick={() => {
                                buttonRef.style.marginLeft = "10px";
                                buttonRef.style.float = 'right';
                            }} style={{borderRadius:'0px 4px 4px 0px', position:'relative'}} >      
                             <div className="rigt-roud1"></div>
                            </Button>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='buttonPosition.text' /></Col>
                        <Col span={19}>
                            <Slider max={680} onChange={(value) => {
                                buttonRef.style.float = 'left';
                                buttonRef.style.marginLeft = (buttonWidth+value) > 680 ? (680-buttonWidth)+"px" : value+"px";
                            }} />
                        </Col>
                        </Row>
                    <Row className="but-buttons">
                        <Col span={6} style={{paddingTop:'4px'}}> 
                            <FormattedMessage id='buttonTextStyle.text' />
                        </Col>
                        <Col span={18} >
                            <Select style={{width: '100%'}} onChange={(val) => {
                                buttonRef.style.fontFamily = val;
                            }}>
                                <Option value="arial">Arial</Option>
                                <Option value="verdana">Verdana</Option>
                                <Option value="verdana">Century Gothic</Option>
                                <Option value="impact">Helvetica</Option>
                                <Option value="gorgeous">gorgeous</Option>
                                <Option value="Myriad Pro">Myriad Pro</Option>
                                <Option value="New York">New York</Option>
                                <Option value="Optima">Optima</Option>
                                <Option value="impact">Impact</Option>
                                <Option value="ms comic sans">MS Comic Sans</Option>
                                <Option value="Times New Roman">Times New Roman</Option>
                                <Option value="Baskerville">Baskerville</Option>
                                <Option value="American Typewriter">American Typewriter</Option>
                                <Option value="Edwardian Script ITC">Edwardian Script ITC</Option>
                                <Option value="arial">Montserrat</Option>
                                 <Option value="ms comic sans">Phosphate</Option>

                            </Select>
                        </Col>
                    </Row>
                   
                   

                </div>
        </Fragment>
    );
}


export default injectIntl(ButtonProps);
