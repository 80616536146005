import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DebitMemoListingComponent from '../../../../components/supplier/debitMemo/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchDebitMemos, getDebitMemoCount, resetPaginatedData, deleteDebitMemo } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack, fetchUserPreferences, saveUserPrefrences, deleteAuditTrail, createAuditTrail } from '../../../../actions/commonActions';
import * as find from 'lodash.find';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
import { FormattedMessage } from 'react-intl';


class SupplierExpenseListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      txPreferences: { txType: "debitMemoList", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['debitMemoNumber', 'supplierName', 'debitMemoTotalAmount', 'debitMemoDate'],
      txAvailableColumn: {
        debitMemoNumber: true,
        supplierName: true,
        debitMemoDate: true,
        debitMemoTotalAmount: true,
        status: true,
      },
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25
    };
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    fetchPaginationDataIfNeeded('fetchDebitMemos', 'debitMemo', this.props, payload);
    fetchDataIfNeeded('getDebitMemoCount', 'debitMemoCount', this.props, payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "debitMemo", templateName: "standard" });
      if (txPreferences && txPreferences.tablePreference) {
        let txColumns = ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [];
        txColumns = txColumns.length ? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        const txColumnSetting = ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <DebitMemoListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    debitMemoList: state.debitMemo.debitMemoList,
    debitMemoCount: state.debitMemo.debitMemoCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDebitMemos,
  getDebitMemoCount,
  resetPaginatedData,
  showModal,
  pushModalToStack,
  hideModal,
  popModalFromStack,
  createAuditTrail,
  deleteAuditTrail,
  fetchUserPreferences,
  saveUserPrefrences,
  deleteDebitMemo
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierExpenseListing);
