import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, DatePicker, Row, Col, Tooltip } from 'antd';
import { getMomentDateForUI, formatDateFilterForBackend, showToasterMessage } from '../../../../../utils'
import { Dropdown } from '../../../../general/Dropdown';
import { CONSTANTS } from '../../../../../static/constants';
import { CustomButton } from '../../../../general/CustomButton';
import {QuestionCircleTwoTone} from "@ant-design/icons"
class PriceListComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      form: this.props.form,
      priceListId: this.props.data.formData.priceListId,
      priceListName: this.props.data.formData.priceListName,
      description: this.props.data.formData.description,
      type: this.props.data.formData.type,
      percentage:this.props.data.formData.percentage,
      startDate: this.props.data.formData.startDate || '',
      endDate: this.props.data.formData.endDate,
      version: this.props.data.formData.version,

    }

  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const format = CONSTANTS.TABLE_DATE_FORMAT;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      
      <div className="price-list-modal">
        <Form labelAlign='left' {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content">
            <Form.Item label={<FormattedMessage id='modal.common.priceList.name' defaultMessage='' />} colon={false} className="required-field">
              {getFieldDecorator('priceListName', {
                rules: [
                  {
                    required: true,
                    message: 'Name is required.',
                  }
                ],
                initialValue: this.state.priceListName || ''
              })(<Input style={{height: "38px"}} placeholder={this.props.intl.formatMessage({ id: 'modal.common.priceList.name', defaultMessage: '' } )} />)}
            </Form.Item>

            <Form.Item label={<FormattedMessage id='modal.common.priceList.description' defaultMessage='' />} colon={false}>
              {getFieldDecorator('description', {
                initialValue: this.state.description || ''
              })
                (<Input style={{height: "38px"}} placeholder={this.props.intl.formatMessage({ id: 'modal.common.priceList.description', defaultMessage: '' } )} />)}
            </Form.Item>

            <Form.Item label={<FormattedMessage id='modal.common.priceList.percentage' defaultMessage='' />} colon={false} className="required-field">
              <Row>
                <Col span={8} className="mark-dropdown-control" style={{marginTop: "14px"}}>
                <Dropdown
                  items={[{type: 'Markup', showType: "Markup  (↑)"}, {type: 'Markdown', showType: "Markdown  (↓)"}]}
                  placeholder={'Select Price List'}
                  value={this.state.type || "Markdown"}
                  valueKeyName="showType"
                  optionKeyName="type"
                  onSelect={(selectedValue) => {
                    this.setState({ 'type': selectedValue});
                  }}
                />
                </Col>
                <Col span={16} style={{marginTop: "5px"}}>
                  {getFieldDecorator('percentage', {
                    initialValue: this.state.percentage || '',
                    rules: [
                      {
                        required: true,
                        message: 'Percentage is required.'
                      }
                    ],
                  })
                  (<Input style={{height:'38px'}} type='number' placeholder={this.props.intl.formatMessage({ id: 'modal.common.priceList.percentage', defaultMessage: '' } )} />)}
                  <span className="percentage-mark" >
                  %
                </span>
                </Col>
              </Row>
            </Form.Item>

            {/* <Form.Item label={<FormattedMessage id='modal.common.priceList.startDate' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('startDate', {
                initialValue: this.state.startDate || undefined
              })
                (<span />)}
            </Form.Item> */}
            <Form.Item label={(<>
              <FormattedMessage id='modal.common.priceList.startDate' defaultMessage='' />
              <Tooltip placement="bottom" title={<FormattedMessage id='modal.common.priceList.startDate.tooltip' defaultMessage="" />} trigger={"click"}>
                &nbsp; <QuestionCircleTwoTone/>
              </Tooltip>
              
            </>
            )} colon={false}>
              {getFieldDecorator('startDate', {
                initialValue: this.state.startDate ? getMomentDateForUI({date: new Date(this.state.startDate), format}) : null
              })
                (<DatePicker
                  format={format}
                  key={`${getMomentDateForUI({date: new Date(), format})}`}
                 // defaultValue={getMomentDateForUI({date: new Date(), format})}
                  onChange={(selectedDate) => {
                    this.props.form.setFieldsValue({ 'startDate': getMomentDateForUI({date: selectedDate, format}) });
                  }}
                />)}
            </Form.Item>
            {/* <Form.Item label={<FormattedMessage id='modal.common.priceList.endDate' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('endDate', {
                initialValue: this.props.endDate || undefined
              })
                (<span />)}
            </Form.Item> */}
            <Form.Item label={(<>
                <FormattedMessage id='modal.common.priceList.endDate' defaultMessage=''/>
                <Tooltip placement="bottom" title={<FormattedMessage id='modal.common.priceList.endDate.tooltip' defaultMessage="" />} trigger={"click"}>
                  &nbsp; <QuestionCircleTwoTone/>
                </Tooltip>
            </>
            )} colon={false}>
              {getFieldDecorator('endDate', {
                initialValue: this.state.endDate ? getMomentDateForUI({date: new Date(this.state.endDate), format}) : null
              })
                (<DatePicker
                  format={format}
                 // key={`${getMomentDateForUI({date: new Date(), format})}`}
                 // defaultValue={getMomentDateForUI({date: new Date(), format})}
                  onChange={(selectedDate) => {
                    this.props.form.setFieldsValue({ 'endDate': getMomentDateForUI({date: selectedDate, format}) });
                  }}
                />)}
            </Form.Item>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.priceList.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal() }}
              />
              <CustomButton
                intlId={'modal.priceList.button.submit'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </div >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (Number(values.percentage) < 0 || Number(values.percentage) > 100) {
          return showToasterMessage({ messageType: 'error', description: this.props.intl.formatMessage({ id: 'modal.common.priceList.percentage.validation' } ) });
        }
        const payload = {
          priceListId: this.state.priceListId,
          priceListName: values.priceListName,
          description: values.description,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          percentage: values.percentage,
          type: this.state.type || 'Markdown',
          startDate: values.startDate ? formatDateFilterForBackend(values.startDate): null,
          endDate: values.endDate? formatDateFilterForBackend(values.endDate, CONSTANTS.FILTER_DATE_TYPE.END): null,
          version: this.state.version || 0,
          //dateCreated: getCurrentDateForBackend(new Date())
        }

        const modalData = {
          modalBody: 'Are you sure you want to create price list ?',
          handleSubmit: () => {
            this.props.createPriceList(payload, this.props);
            this.props.hideModal();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Price_list_component' })(injectIntl(PriceListComponent));
