import React, { useState } from "react";
import PageBreadcrumb from "../../PageBreadcrumb";
import "./store-settings.scss";
import { FormattedMessage } from "react-intl";
import Preferences from "../../../containers/modal/modalBody/eStore/Preferences";
import StoreTimings from "../../../containers/modal/modalBody/eStore/StoreTimings";
import SupportAndSocial from "../../../containers/modal/modalBody/eStore/SupportAndSocial";
import Domains from "../../../containers/modal/modalBody/eStore/Domains";
import Policies from "../../../containers/modal/modalBody/eStore/Policies";
import Rectangle from "../../../assets/images/icon/reaction.png"
import StoreTiming from "../../../assets/images/icon/clock.png"
import Domain from "../../../assets/images/icon/domain.png"
import DeliveryIcon from "../../../assets/images/icon/delivery.png"
import CouponIcon from "../../../assets/images/icon/coupon.png"
import Policy from "../../../assets/images/icon/google-docs.png"
import Social from "../../../assets/images/icon/social-media.png"
import Checkout from "../../../containers/modal/modalBody/eStore/Checkout";
import Seo from "../../../containers/modal/modalBody/eStore/Seo";
import Delivery from "../../../containers/modal/modalBody/eStore/Delivery";
import InPageMenu from "../../Common/InPageMenu"
import LoginWorkFlow from "../../../containers/modal/modalBody/eStore/LoginWorkFlow";
import Coupon from "../../../containers/modal/modalBody/eStore/Coupon";
import Analytics from "../../../containers/modal/modalBody/eStore/Analytics";
import ReturnOrder from "../../../containers/modal/modalBody/eStore/ReturnOrder";
import ReturnIcon from "../../../assets/images/return.png";
import AnalyticsIcon from "../../../assets/images/increasing.png";
import LoginWorkFlowIcon from "../../../assets/images/icon/workflow.png"
import PopUpManagement from "../../../containers/modal/modalBody/eStore/PopUpManagement";


function StoreSettings(props) {
  const handleActiveTab = (index) => {
      props.updateState({
          selectedMenuItem: index
      })
  };

  const activeTab = () => {
      switch (props.selectedMenuItem) {
          case 0:
              return <LoginWorkFlow />;
          case 1:
              return <Preferences />;
          case 2:
              return <StoreTimings />;
          case 3:
              return <Domains />;
          case 4:
              return <SupportAndSocial />;
          case 5:
              return <Delivery/>;
            case 6:
                return <Coupon/>;
          case 7:
              return <Policies/>;
          case 8:
              return <Seo/>;
          case 9: 
              return <Checkout/>
          case 10:
              return <Analytics/>;
        case 11:
                return <ReturnOrder/>;
        case 12:
          return <PopUpManagement/>;
          default:
              return null;
      }
  };
  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.eStores' defaultMessage='' />,
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.storeSettings' defaultMessage='' />,
    },
  ];
  const menuList = [{     
    icon: LoginWorkFlowIcon,
    name: "eStore.storeSettings.login.label",
    description: "You are powered with creating multiple users for your business.",

},
    
    {     
      icon: Rectangle,
      name: "eStore.storeSettings.preferences.label",
      description: "You are powered with creating multiple users for your business.",

  },
  {
      icon: StoreTiming,
      name: "eStore.storeSettings.storeTimings.label",
      description: "You are powered with creating multiple users for your business."

  },
  {
      icon: Domain,
      name: "eStore.storeSettings.domains.label",
      description: "You are powered with creating multiple users for your business."
  },
  {
      icon: Social,
      name: "eStore.storeSettings.supportSocial.label",
      description: "You are powered with creating multiple users for your business."
  },
  {
      icon: DeliveryIcon,
      name: "eStore.storeSettings.delivery.label",
      description: "You are powered with creating multiple users for your business."
  },
  {
    icon: CouponIcon,
    name: "eStore.storeSettings.coupon.label",
    description: "You are powered with creating multiple users for your business."
},
  {
      icon: Policy,
      name: "eStore.storeSettings.policies.labe",
      description: "You are powered with creating multiple users for your business."
  },
  {
      icon: Rectangle,
      name: "SEO",
      description: "You are powered with creating multiple users for your business."
  },
  {
    icon: Rectangle,
    name: "Checkout",
    description: "You are powered with creating multiple users for your business."
},

  {
    icon: AnalyticsIcon,
    name: "Analytics",
    description: "Connect your store to analytics tools"
},
{
    icon: ReturnIcon,
    name: "Return",
    description: ""
},
{
    icon: Policy,
    name: "eStore.storeSettings.popUpMangement.label",
    description: "You are powered with creating multiple users for your business."
},
  ]
  return (
      <>

          <div className="view-top-bar">
              <div className="top-view-left">
                  <div className="page-heading">
                  Store Settings
                  </div>
                  <div className="vertical-sep" />
                  <PageBreadcrumb breadCrumbList={breadCrumbList} />
              </div>
          </div>

          <div className="meta-container">
              <div className="meta-sidenav">
                  <InPageMenu menuList={menuList} onItemClick={handleActiveTab} />
              </div>
              <div className="meta-main-body">
                  {activeTab()}           
              </div>
          </div>
      </>
  )
}

export default StoreSettings;