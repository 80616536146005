import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CostCenterComponent from '../../../components/finance/CostCenter';

class CostCentersComp extends Component {

    constructor(props){
        super();
        this.state={
            searchedText: ''
        }
    }

    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: false
        });
    }



    render() {
        return <CostCenterComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}/>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CostCentersComp);
