import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
// import { AgGridReact } from "ag-grid-react";
import { Skeleton, Pagination, Tooltip,Empty } from "antd";
import moment from "moment-timezone";
import { fetchPaginationDataIfNeeded } from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
//import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants'
import { CONSTANTS } from "../../../../static/constants";
import InboundDeliveryCreate from "../../../../containers/supplier/InboundDelivery"
const InboundDeliveriesDraftListing = (props) => {
  const {
    inboundDeliveryDraftList,
    updateState,
    companyInfo,
    inboundDeliveryDraftCount,
    pageNumber,
    inboundDeliveryCount
  } = props;
  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };


  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchInboundDeliveriesDrafts",
      "inboundDeliveryDraftList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetDraftPaginatedData();
    props.fetchInboundDeliveriesDrafts(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  //const permittedModules = getActionMenuPermissionData();
  // const columnDefs = [
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesOrder.listing.header.draftNo"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "inboundDeliveryDraftId",

  //     cellRendererFramework: (param) => (
  //       <div className="cursor-pointer">
  //         <Link
  //           to={{
  //             pathname: "/admin/inbound-delivery-create",
  //             state: {
  //               supplierId: param.data.supplierId,
  //               inboundDeliveryData: param.data.inboundDeliveryData,
  //               inboundDeliveryDraftId: param.data.inboundDeliveryDraftId,
  //               clone: true,
  //             },
  //           }}
  //         >
  //           Draft-{param.value}
  //         </Link>
  //       </div>
  //     ),
  //     rowDrag: true,
  //     resizable: true,
  //     width: 100,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.supplier" defaultMessage="" />;
  //     },
  //     field: "supplierName",
  //     resizable: true,
  //     width: 100,
  //     cellRendererFramework: (link) => (
  //       <div className="cursor-pointer">
  //         <Link
  //           to={{
  //             pathname: "suppliers/details",
  //             state: {
  //               supplierId: link.data.inboundDeliveryData.supplierId,
  //               supplierName: link.data.inboundDeliveryData.supplierName,
  //             },
  //           }}
  //           className="company-name"
  //         >
  //           {link.data.inboundDeliveryData.supplierName}
  //         </Link>
  //       </div>
  //     ),
  //   },
  //   // {
  //   //     headerComponentFramework: () => {
  //   //         return <div><FormattedMessage id='customer.salesOrder.listing.header.amount' default='Amount' />  </div>;
  //   //     },
  //   //     cellRendererFramework: (link) => {
  //   //         return link.data.inboundDeliveryData.totalPOAmount && link.data.inboundDeliveryData.totalPOAmount !== '-' ? <span> {link.data.inboundDeliveryData.foreignCurrencyIcon ? <i className={link.data.inboundDeliveryData.foreignCurrencyIcon}></i> : (link.data.inboundDeliveryData.foreignCurrency ? <span>{link.data.inboundDeliveryData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  { Number(link.data.inboundDeliveryData.totalPOAmount || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
  //   //     },
  //   //     field: "totalPOAmount",
  //   //     resizable: true,
  //   //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //   //     width: 100,
  //   // },

  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesOrder.listing.header.date"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "inboundDeliveryDate",
  //     cellRendererFramework: (link) => {
  //       return (
  //         <div>
  //           <Tooltip
  //             placement="topLeft"
  //             title={
  //               link.data.inboundDeliveryData.inboundDeliveryDate &&
  //               moment(
  //                 link.data.inboundDeliveryData.inboundDeliveryDate
  //               ).format(CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT)
  //             }
  //           >
  //             {link.data.inboundDeliveryData.inboundDeliveryDate &&
  //               moment(
  //                 link.data.inboundDeliveryData.inboundDeliveryDate
  //               ).format(CONSTANTS.DISPLAY_DATE_FORMAT)}
  //           </Tooltip>
  //         </div>
  //       );
  //     },
  //     resizable: true,
  //     width: 100,
  //   },
  // ];

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  // const onGridReady = (params) => {
  //   props.updateState({
  //     gridApi: params.api,
  //     gridColumnApi: params.columnApi,
  //   });
  //   params.api.sizeColumnsToFit();
  // };

  return (
    <Fragment>
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="purchase.grnDraftList.table.heading"
                defaultMessage=""
              />
            </div>
                <div className="vertical-sep" />
                <div>{inboundDeliveryDraftCount}</div>            
          </div>
          <div className="right-actions"></div>
          </Skeleton>
        </div>
        {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                             <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={inboundDeliveryDraftList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                suppressDragLeaveHidesColumns={true} 
                                animateRows={true}
                                onColumnResized ={(params)=>{
                                    params.api.resetRowHeights();
                                }}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                            >
                            </AgGridReact>
                        </div>
                    </div> */}
      <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="serial.no" />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.draftNo"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage id="common.supplier" />
                </th>
                <th>
                  <FormattedMessage id="customer.salesOrder.listing.header.date" />
                </th>
              </tr>
            </thead>
            <tbody>
              {(inboundDeliveryDraftList[pageNumber] || []).length ? 
                inboundDeliveryDraftList[pageNumber].sort((a,b)=>a.rowData?.inboundDeliveryData?.inboundDeliveryDate < b.rowData?.inboundDeliveryData?.inboundDeliveryDate ? 1:-1).map((rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span>
                          {Number(
                            (pageNumber - 1) * props.pageSize + index + 1
                          )}
                        </span>
                      </td>
                      <td>
                        <div className="cursor-pointer">
                          <div 
                          onClick={()=>{

                            props.pushModalToStack({
                              modalBody: <InboundDeliveryCreate {...props} supplierId={rowData.supplierId} inboundDeliveryDataa={rowData.inboundDeliveryData}  inboundDeliveryDraftId={rowData.inboundDeliveryDraftId} clone={true}/>,
                              width: '100%',
                              hideTitle: true,
                              hideFooter: true,
                              wrapClassName: 'new-transaction-wrapper'
                          })
                          }}
                          
                            // to={{
                              
                            //   // pathname: "/admin/inbound-delivery-create",
                            //   state: {
                            //     supplierId: rowData.supplierId,
                            //     inboundDeliveryData:rowData.inboundDeliveryData,
                            //       
                            //     inboundDeliveryDraftId:rowData.inboundDeliveryDraftId,
                            //       
                            //     clone: true,
                            //   },
                            // }}
                          >
                            Draft-{rowData.inboundDeliveryDraftId}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="cursor-pointer">
                          <Link
                            to={{
                              pathname: "suppliers/details",
                              state: {
                                supplierId:
                                  rowData.inboundDeliveryData.supplierId,
                                supplierName:
                                  rowData.inboundDeliveryData.supplierName,
                              },
                            }}
                            className="company-name"
                          >
                            {rowData.inboundDeliveryData.supplierName}
                          </Link>
                        </div>
                      </td>
                      <td>
                        <div>
                          <Tooltip
                            placement="topLeft"
                            title={
                              rowData.inboundDeliveryData.inboundDeliveryDate &&
                              moment(
                                rowData.inboundDeliveryData.inboundDeliveryDate
                              ).format(CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT)
                            }
                          >
                            {rowData.inboundDeliveryData.inboundDeliveryDate &&
                              moment(
                                rowData.inboundDeliveryData.inboundDeliveryDate
                              ).format(CONSTANTS.DISPLAY_DATE_FORMAT)}
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  );
                }) : 
                <tr key="empty-data">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
            </tbody>
          </table>
        </div>
      </Skeleton>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={inboundDeliveryDraftCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(InboundDeliveriesDraftListing);
