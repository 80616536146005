import { Checkbox, Col, Drawer, Row } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { Value } from "sass";

const AdditionalFieldDrawe = (props) => {
  const rowGutter = [24, 16];


  const closeDrawer = () => {
    props.updateDrawerState({
      addNewField: false,
    });
  };
  return (
    <Drawer
      title={"Create Field"}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.addNewField}

      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              let values = props.additionalValue;
            
              if (props.index || props.index === 0) {
                let ans = props.activeTabKey === "shipping" ? values?.checkoutShippingFormValues : values?.checkoutBillingFormValues 
                let val = (props.activeTabKey === "shipping" ? values?.checkoutShippingFormValues : values?.checkoutBillingFormValues)[props.index]
                val = {
                  ...val, title: props.field_Name,
                  placeholder: props.placeholder ,
                  required: props.isRequired,
                }
                ans[props.index] = val
                props.activeTabKey === "shipping" ? values["checkoutShippingFormValues"] = ans : values["checkoutBillingFormValues"] = ans
               

                // val = val ? val[0] : ""
              
                closeDrawer();
              } else {
                // values = props.additionalValue;
                if(props.activeTabKey === "shipping"){
                  values["checkoutShippingFormValues"] = [...(values.checkoutShippingFormValues||[]), {
                    title: props.field_Name,
                    
                    key: ((values?.checkoutShippingFormValues||[])?.length || 0) + 1,
                    placeholder: props.placeholder,
                    required: props.isRequired,
                  }]
                }
               else{
                values["checkoutBillingFormValues"] = [...(values.checkoutBillingFormValues||[]), {
                  title: props.field_Name,
                  key: ((values?.checkoutBillingFormValues||[])?.length || 0) + 1,
                  placeholder: props.placeholder,
                  required: props.isRequired,
                }]
               }
                
                
                closeDrawer();
              }
              props.updateState({
                additionalValue: values,
              })
              props.updateDrawerState({
                additionalValue: values,
              })
              return
            }}
            
          // key={1}
          />
        </>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">Field Name</div>
          <TextBox
            placeholder="Field Name"
            type="text"
            maxLength={20}
            value={props.field_Name}
            onChange={(e) => {
              props.updateState({ field_Name: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.field_Name }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Placeholder</div>
          <TextBox
            placeholder="Enter the value"
            type="text"
            maxLength={20}
            value={props.placeholder}
            onChange={(e) => {
              props.updateState({ placeholder: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.placeholder }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
      <Col span={24}>
      <div className="req-checkbox">
                <Checkbox
                    checked={props.isRequired || false}
                    onChange={(e) => {
                        props.updateState({ isRequired: e.target.checked});
                    }}
                >
                    Required
                </Checkbox>
            </div>
            <div className="i-label">
                 It will make this field as a mandatory field and customer won't be able to skip it.
            </div>
      </Col>
      </Row>
    </Drawer>
  );
};

export default AdditionalFieldDrawe;
