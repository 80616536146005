import React from "react";
// import { Link } from 'react-router-dom';
import { Skeleton, Menu } from "antd";
// import * as debounce from 'lodash.debounce';
import { injectIntl, FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
// import { CustomAgGrid } from "../../general/CustomAgGrid";
import {
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  MODAL_TYPE,
  ICONS,
} from "../../../static/constants";
import { checkACLPermission, sortTable } from "../../../utils";
// import { AG_GRID_CLASS_CONSTANTS } from '../../../static/cssClassConstants';
import { MoreOutlined } from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";
import ReactHtmlParser from "react-html-parser";

// const { Search } = Input;

// let gridApi = {};

// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

// const onFirstDataRendered = (params) => {
//     params.api.sizeColumnsToFit();
// }
// const getRowHeight = (params) => {
//     return 30;
// }

const HsnComp = (props) => {
  const { permissions, dir = 0, updateState, allGstRates } = props;
  const getActionPermissionData = () => {
    const primaryPerm = permissions.primary || [];
    const permittedAction = {
      create: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.TAXES,
        PERMISSION_VALUES.CREATE
      ),
      update: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.TAXES,
        PERMISSION_VALUES.UPDATE
      ),
      delete: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.TAXES,
        PERMISSION_VALUES.DELETE
      ),
    };
    Object.keys(permittedAction).forEach((operation) => {
      if (permittedAction[operation] === false) {
        delete permittedAction[operation];
      }
    });
    return permittedAction;
  };

  const permittedAction = getActionPermissionData();

  const handleNewTaxAddition = (props, payload = {}) => {
    const { showModal, companyInfo } = props;
    const formData = payload.formData || {};
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: payload?.formData?.hsnCode ? "Edit HSN" : "Create HSN",
      // title:"Create HSN",

      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.NEW_HSN,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
      width: 850,
    };
    showModal(data);
  };

  // const onSearchedInputChange = debounce((searchedText, props) => {
  //     handleSearchedText(searchedText, props)
  // }, 2000);

  // const handleSearchedText = (searchedText, props) => {
  //     gridApi.setQuickFilter(searchedText || '');
  // }

  const breadCrumbList = [
    {
      name: "Products",
    },
    {
      name: "HSN",
    },
  ];

  const sortColumn = (e) => {
    sortTable(
      document.getElementById("hsn-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            handleNewTaxAddition(props, {
              formData: { ...e },
            });
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>

        {/* <Menu.Item key="2" onClick={()=>
                {
                    props.showModal({
                        modalBody: "Are you sure you want to delete?",
                        handleSubmit: () => {
                            props.deleteGrade(e, props);
                        }
                    })
                }
                }>
                <DeleteOutlined />
                Delete
                </Menu.Item> */}
      </Menu>
    );
  };

  const openLongtText = (title, Text) => {
    props.showModal({
      title: title,
      modalBody: (
        <div style={{ maxHeight: "550px", overflow: "auto" }}>
          {ReactHtmlParser(Text)}
        </div>
      ),
      hideFooter: true,
      width: "70%",
    });
  };

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id="heading.finance.hsn" defaultMessage="" />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          {permittedAction.create && (
            <button
              className="create-action-btn"
              onClick={() => {
                handleNewTaxAddition(props);
              }}
            >
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          )}
        </div>
      </div>

      <div className="view-container">
        <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="finance.hsn.table.heading"
                  defaultMessage=""
                />
              </div>
              {(allGstRates || []).length ? (
                <>
                  <div className="vertical-sep" />
                  <div>{(allGstRates || []).length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              {/* <Search
                    placeholder="Search"
                    value={props.searchedText}
                    onChange={e => {
                        props.updateState({ searchedText: e.target.value });
                        onSearchedInputChange(e.target.value, props);
                    }}
                    onSearch={(searchedText) => {
                        props.updateState({ searchedText });
                        onSearchedInputChange(searchedText, props);
                    }}
                    style={{ width: 330, height: 36 }}
                /> */}
            </div>
          </div>
          <div className="table-container">
            <table id="hsn-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage=" " />
                  </th>
                  <th onClick={sortColumn}>HSN Code</th>
                  <th onClick={sortColumn}>Description</th>
                  <th onClick={sortColumn}>IGST</th>
                  <th onClick={sortColumn}>CGST</th>
                  <th onClick={sortColumn}>SGST/UGST</th>
                  <th width="6%">Action</th>
                </tr>
              </thead>
              <tbody>
                {(allGstRates || []).map((rowData, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{rowData.hsnCode || "-"}</td>
                      <td>
                        {rowData.description ? (
                          <p className="short-text">
                            {ReactHtmlParser(rowData.description || "")}
                          </p>
                        ) : (
                          ""
                        )}
                        {rowData.description ? (
                          <span
                            className="more-details-btn"
                            onClick={() => {
                              openLongtText("Description", rowData.description);
                            }}
                          >
                            show more
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>{rowData.iGstRate || "-"}</td>
                      <td>{rowData.cGstRate || "-"}</td>
                      <td>{rowData.sGstUtGstRate || "-"}</td>
                      <td style={{ paddingLeft: "50px" }}>
                        <DropdownAction
                          overlay={actionMenu(rowData)}
                          trigger={["click"]}
                        >
                          <MoreOutlined />
                        </DropdownAction>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right"></div>
          </div>

          {/* <div className="agGridWrapper">
              <div className="ag-theme-balham" style={agGridStyle} >
                            <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={taxes || []}
                            rowDragManaged={true}
                            onGridReady={onGridReady}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            getRowHeight={getRowHeight}
                        /> 
                    </div>
            </div> */}
        </Skeleton>
      </div>
    </>
  );
};

// const deleteTax = (props, record) => {
//     props.showModal({
//         modalBody: <FormattedMessage id='common.delete.confirmation' defaultMessage='' />,
//         handleSubmit: () => {
//             const payload = {
//                 relationshipId: props.companyInfo.relationshipId,
//                 id: record.taxSingleRateId
//             };
//             props.deleteTax(payload);
//         }
//     })
// }

// const updateTax = (props, record) => {
//     const payload = {
//         ...record,
//         relationshipId: (props.companyInfo || {}).relationshipId,
//         taxPercent: record.taxPercent,
//         dateCreated: getCurrentDateForBackend(new Date())
//       }

//       if(props.companyInfo && (props.companyInfo || {}).countryName !== 'India'){
//         payload.taxType = 'VAT';
//       }

//       const modalData = {
//         modalBody: 'Are you sure you want to create tax ?',
//         handleSubmit: () => {
//           props.saveSingleTaxRate(payload, props);
//           props.hideModal();
//         }
//       };
//       props.showModal(modalData);

// }

export default injectIntl(HsnComp);
