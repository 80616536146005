import axios from "axios";
import config from "../../../config/environmentConfig";
import { MARKETPLACE_SALES_CHANNEL_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const getPublishQuantity = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemVariant/walmartPublishedQty?relationshipId=${payload.relationshipId}`
            )
            .then((res) => {
                dispatch({
                    type: MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_PUBLISHED_QTY,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const getUnPublishQuantity = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemVariant/walmartUnpublishedQty?relationshipId=${payload.relationshipId}`
            )
            .then((res) => {
                dispatch({
                    type: MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_UNPUBLISHED_QTY,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const getTodayListing = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemVariant/walmartListingToday?relationshipId=${payload.relationshipId}`
            )
            .then((res) => {
                dispatch({
                    type: MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_TODAY_LISTING,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const getThisWeekListing = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemVariant/walmartListingThisWeek?relationshipId=${payload.relationshipId}`
            )
            .then((res) => {
                dispatch({
                    type: MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_THIS_WEEK_LISTING,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const getThisMonthListing = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItemVariant/walmartListingThisMonth?relationshipId=${payload.relationshipId}`
            )
            .then((res) => {
                dispatch({
                    type: MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_THIS_MONTH_LISTING,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const getTotalSales = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/walmartTotalSales?relationshipId=${payload.relationshipId}`
            )
            .then((res) => {
                dispatch({
                    type: MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_TOTAL_SALES,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const getInventoryItems = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/getInventorySummary?relationshipId=${payload.relationshipId}&warehouseMasterId=0&pageSize=${payload.pageSize || 100
                }&pageNumber=${(payload.pageNumber || 1) - 1}`
            )
            .then((res) => {
                const data = {
                    pageNo: payload.pageNumber,
                    list: res.data
                }
                dispatch({
                    type: MARKETPLACE_SALES_CHANNEL_ACTION_LIST.INVENTORY_ITEM_LISTING,
                    data: data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const getInventoryItemsCount = (payload) => {
    return (dispatch) => {
        return axios
            .get(
                `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/membershipOption/countMembershipOption?relationshipId=${payload.relationshipId}`
            )
            .then((res) => {
                dispatch({
                    type: MARKETPLACE_SALES_CHANNEL_ACTION_LIST.INVENTORY_ITEMS_COUNT,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};

export const resetPaginatedData = () => {
    return (dispatch) => {
        dispatch({ type: MARKETPLACE_SALES_CHANNEL_ACTION_LIST.INVENTORY_LISTING_RESET });
    };
};
