import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Popover,Input } from 'antd';
import { Dropdown } from '../../../../../general/Dropdown';
// import { TextBox } from '../../../../general/TextBox';
import { EMAIL_BODY_CONFIGURATION } from '../../../../../../static/ckEditorConfigConstants';
import { PLACEHOLDER_CONSTANTS } from '../../../../../../static/placeholderConstants';
import CKEditor from '../../../../../general/CustomCKEditor/ckeditor';
import { showToasterMessage } from '../../../../../../utils';

const saveTemplateData = (props) => {
    
    if (!props.docName || !props.content) {
        return showToasterMessage({
            messageType: 'error', description: props.intl.formatMessage({id: 'email.template.validation'})
        });
    }
    
    let payload = {
        content: props.content,
        docName: props.docName,
        subject: props.subject,
        relationshipId: (props.companyInfo || {}).relationshipId,
        clientRelationshipFromEmailId: props.clientRelationshipFromEmailId,

    };
    if (props.templateData && props.docName === props.templateData.docName) {
        payload.settingEmailTemplateId = props.templateData.settingEmailTemplateId;
        payload.version = props.templateData.version;
    }
    props.pushModalToStack({
        modalBody: "Are you sure you want to save?",
        handleSubmit: () => {
            props.saveOrUpdateEmailTemplate(payload, props);
        }
    });
    
};

const insertTextInSubject = (props, textToInsert) => {
    if (props.subject) {
        let subject = props.subject.slice(0, props.subjectCursor) + textToInsert + props.subject.slice(props.subjectCursor + 1);
        props.updateState({ subject: subject, subjectCursor: props.subjectCursor+textToInsert?.length })
    } else {
        props.updateState({ subject: textToInsert, subjectCursor: props.subjectCursor+textToInsert?.length })
    }
}

const getSubjectPlaceHolders = (props, editorName ) => {
    return <div style={{ width: '400px' }}>
        <Row type='flex' gutter={[8, 24]}>
            {(props.docName === 'Class Assignment') ? <Col span={14}>
                <div style={{ fontWeight: '700' }}>Student</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father and Mother Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CLASS_NAME); }}>Class Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ASSIGNMENT_NAME); }}>Assignment Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ASSIGNMENT_DUE_DATE); }}>Assignment Due Date</div>
            </Col> : ''
            }
            {(props.docName === 'Pledge') ? <Col span={8}>
                <div style={{ fontWeight: '700' }}>Donor</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DONOR_NAME); }}>Name</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.MOBILE_NUMBER); }}>Mobile</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PLEDGE_AMOUNT); }}>Pledge Amount</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PLEDGE_DATE); }}>Pledge Date</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PAYMENT_LINK); }}>Payment URL</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PLEDGE_START_DATE); }}>Pledge Start Date</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.TOTAl_OCCURRENCES); }}>No. of Occurances</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.REMARKS); }}>Donor Remarks</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CAMPAIGN_NAME); }}>Campaign Name</div>
            </Col> : ''
            }
            {(props.docName === 'Sales Invoice' || props.docName === 'Recurring Sales Invoice') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Invoice</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                    {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
                </Col> : ''
            }
            {(props.docName === 'Sales Quotation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Quote</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.QUOTE_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.BID_CLOSING_DATE); }}>Expiration Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.QUOTE_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.QUOTE_NUMBER); }}>Quote #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Sales Order') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_DELIVERY_DATE); }}>Delivery Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                </Col> : ''
            }
            {(props.docName === 'Sales Payment' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_NUMBER); }}>Payment #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Credit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Credit Memo</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CM_NUMBER); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CM_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CM_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CM_NUMBER); }}>Credit Memo #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Inquiry') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Inquiry</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INQUIRY_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INQUIRY_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INQUIRY_NUMBER); }}>Inquiry #</div>
                </Col> : ''
            }
            {(props.docName === 'Sales Quotation' || props.docName === 'Sales Order' || props.docName === 'Sales Invoice' || props.docName === 'Sales Payment' || props.docName === 'Credit Memo' || props.docName === 'Inquiry' || props.docName === 'Recurring Sales Invoice' || props.docName === 'School Payment Receipt' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                    {/* <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SHIPMENT_NUMBER); }}>Shipment Number</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SHIPMENT_DATE); }}>Shipment Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORDER_DATE); }}>Order Date</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORDER_NUMBER); }}>Order Number</div> */}
                </Col> : ''
            }

            {(props.docName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                </Col> : ''
            }
             {(props.docName === 'Purchase Order' || props.docName === 'Purchase Invoice' || props.docName === 'Purchase Payment' || props.docName === 'Debit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Supplier</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SUPPLIER_NAME); }}>Supplier Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                </Col> : ''
            }
            {(props.docName === 'Kiosk Payment' || props.docName === 'Online Payment' || props.docName === 'Text2Donate Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                </Col> : ''
            }
            {(props.docName === 'Sales Shipment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Shipment</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SHIPMENT_NUMBER); }}>Shipment Number</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SHIPMENT_DATE); }}>Shipment Date</div>
                    {/* <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORDER_DATE); }}>Order Date</div> */}
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORDER_NUMBER); }}>Order Number</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.TRACKING_NUMBER); }}>Tracking #</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CARRIER_NAME); }}>Carrier Name</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DELIVERY_DATE); }}>Delivery Date</div>
                </Col> : ''
            }
            {(props.docName === 'Sales Order Status Update' || props.docName ===  'Sales Order Cancellation' || props.docName ===  'Sales Order Update') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.OOC_ORDER_DATE); }}>Order Date</div>
                    {!props.docName === 'Online Order Confirmation' ? <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.OOC_ORDER_NUMBER); }}>Order Number</div> : ""}
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.OOC_ORDER_AMOUNT); }}>Order Amount</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            {(props.docName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            <Col span={8}>
                <div style={{fontWeight: '700'}}>Organization</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STORE_NAME);}}>Name</div>
                {(props.docName === 'Pledge') ?  <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.USER);}}>User</div> : ''}
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_EMAIL);}}>Email</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_PHONE);}}>Phone #</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_FAX);}}>Fax #</div>
                <div className="ml10 pointer" onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE);}}>Website</div>
            </Col>
        </Row>
    </div>
}

const getPlaceHolders = (props, editorName ) => {
    return <div style={{ width: '400px' }}>
        <Row type='flex' gutter={[8, 24]}>
            {(props.docName === 'Class Assignment') ? <Col span={14}>
                <div style={{ fontWeight: '700' }}>Student</div>
                <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father and Mother Name</div>
                <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
                <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
                <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CLASS_NAME); }}>Class Name</div>
                <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
                <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ASSIGNMENT_NAME); }}>Assignment Name</div>
                <div className="ml10 pointer" onClick={() => {props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ASSIGNMENT_DUE_DATE); }}>Assignment Due Date</div>
            </Col> : ''
            }
        {(props.docName === 'Pledge') ? <Col span={8}>
                <div style={{ fontWeight: '700' }}>Donor</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.DONOR_NAME); }}>Name</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.MOBILE_NUMBER); }}>Mobile</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.PLEDGE_AMOUNT); }}>Pledge Amount</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.PLEDGE_DATE); }}>Pledge Date</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.PAYMENT_LINK); }}>Payment URL</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.PLEDGE_START_DATE); }}>Pledge Start Date</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.TOTAl_OCCURRENCES); }}>No. of Occurances</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.REMARKS); }}>Donor Remarks</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText( PLACEHOLDER_CONSTANTS.CAMPAIGN_NAME); }}>Campaign Name</div>
            </Col> : ''
            }
            {(props.docName === 'Sales Invoice' || props.docName === 'Recurring Sales Invoice') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Invoice</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                    {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
                </Col> : ''
            }
            {(props.docName === 'Sales Quotation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Quote</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.QUOTE_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.BID_CLOSING_DATE); }}>Bid Closing Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.QUOTE_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.QUOTE_NUMBER); }}>Quote #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Sales Order') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_DELIVERY_DATE); }}>Delivery Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Sales Order</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SO_NUMBER); }}>SO #</div>
                </Col> : ''
            }
            {(props.docName === 'Sales Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_NUMBER); }}>Payment #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Credit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Credit Memo</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CM_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CM_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CM_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CM_NUMBER); }}>Credit Memo #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Inquiry') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Inquiry</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INQUIRY_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INQUIRY_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INQUIRY_NUMBER); }}>Inquiry #</div>
                </Col> : ''
            }
             {(props.docName === 'Purchase Invoice') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Invoice</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PI_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
              {(props.docName === 'Purchase Order') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Purchase Order</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PO_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PO_DUE_DATE); }}>Due Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PO_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PO_NUMBER); }}>PO #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Purchase Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Purchase Payment</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_DATE); }}>Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PURCHASE_PAYMENT_NUMBER); }}>Payment #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Debit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Debit Memo</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DM_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DM_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DM_DATE); }}>Date Created</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DM_NUMBER); }}>Debit Memo #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
             {(props.docName === 'RFQ') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>RFQ</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.RFQ_DATE); }}>RFQ Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.RFQ_EXPIRY_DATE); }}>Expiration Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.RFQ_NUMBER); }}>RFQ #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
              {(props.docName === 'Purchase Request') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Purchase Request</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PR_DATE); }}>PR Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PR_EXPIRY_DATE); }}>Expiry Date</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PR_NUMBER); }}>PR #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
                </Col> : ''
            }
            {(props.docName === 'Sales Quotation' || props.docName === 'Sales Order' || props.docName === 'Sales Invoice' || props.docName === 'Sales Payment' || props.docName === 'Credit Memo' || props.docName === 'Inquiry' || props.docName === 'Recurring Sales Invoice' || props.docName === 'School Payment Receipt' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                </Col> : ''}
            {(props.docName === 'Online Order Confirmation' ) ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Customer</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
                </Col> 
                :
                ''
            }
            {(props.docName === 'Purchase Order' || props.docName === 'Purchase Invoice' || props.docName === 'Purchase Payment' || props.docName === 'Debit Memo') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Supplier</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SUPPLIER_NAME); }}>Supplier Name</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
                </Col> : ''}
                {(props.docName === 'Kiosk Payment' || props.docName === 'Online Payment' || props.docName === 'Text2Donate Payment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Payment</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Name</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_BALANCE); }}>Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SALES_PAYMENT_DATE); }}>Payment Date</div>
                </Col> : ''
            }
            {(props.docName === 'Sales Shipment') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Shipment</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SHIPMENT_NUMBER); }}>Shipment Number</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.SHIPMENT_DATE); }}>Shipment Date</div>
                    {/* <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORDER_DATE); }}>Order Date</div> */}
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORDER_NUMBER); }}>Order Number</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.TRACKING_NUMBER); }}>Tracking #</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.CARRIER_NAME); }}>Carrier Name</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DELIVERY_DATE); }}>Delivery Date</div>
                </Col> : ''
            }
            {( props.docName === 'Sales Order Status Update' || props.docName ===  'Sales Order Cancellation' || props.docName ===  'Sales Order Update') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.OOC_ORDER_DATE); }}>Order Date</div>
                    {!props.docName === 'Online Order Confirmation' ? <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.OOC_ORDER_NUMBER); }}>Order Number</div> : ""}
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.OOC_ORDER_AMOUNT); }}>Order Amount</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            {(props.docName === 'Online Order Confirmation') ?
                <Col span={8}>
                    <div style={{ fontWeight: '700' }}>Online Order</div>
                    <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ONLINE_ORDER_NUMBER); }}>Online Order Number</div>
                </Col> : ''
            }
            <Col span={8}>
                <div style={{ fontWeight: '700' }}>Organization</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.STORE_NAME); }}>Name</div>
                {(props.docName === 'Pledge') ?  <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.USER); }}>User</div> : ''}
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORG_EMAIL); }}>Email</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORG_PHONE); }}>Phone #</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORG_FAX); }}>Fax #</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
            </Col>
        </Row>
    </div>
}

const TxnEmailTemplate = (props) => {
    let { docName, subject, content, selectDocumentOptions, companyInfo, senderEmails, updateState } = props;
    return (
        <div className="modal-dialog">
            <div className="modal-content full-width modify-width">
                <div className="inner-content">
                    <Form className="pr20" layout="vertical" hideRequiredMark>
                        <Form.Item
                            label={
                                <FormattedMessage id='modal.settings.emailTemplate.transaction' defaultMessage='' />
                            }>
                            <Input type='text'
                                    value={props.docName}
                            /> 
                            {/* <Dropdown
                                items={selectDocumentOptions}
                                value={docName}
                                onSelect={
                                    (optionValue) => {
                                        updateState({
                                            docName: optionValue
                                        });
                                        props.fetchEmailTemplate({ docName: optionValue, relationshipId: companyInfo.relationshipId });
                                        props.fetchSenderEmailsFromRID({ relationshipId: companyInfo.relationshipId });
                                    }
                                }
                            /> */}
                        </Form.Item>

                        <Form.Item
                            label={
                                <FormattedMessage id='modal.settings.emailTemplate.senderEmail' defaultMessage='' />
                            }>
                            <Dropdown
                                items={senderEmails}
                                value={''}
                                onSelect={
                                    (optionValue) => {
                                        updateState({
                                            clientRelationshipFromEmailId: optionValue
                                        });
                                    }
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            label={
                                <div>
                                    <FormattedMessage id='modal.settings.emailTemplate.emailSubject' />
                                    {/* {props.docName === 'Sales Invoice' ? */}
                                       
                                        {/* : '' } */}
                                </div>
                            }>
                                 {/* {props.docName === 'Sales Invoice' ?
                                <CKEditor
                                    onInit={editor => {
                                        updateState({
                                            editorSubject: editor
                                        });
                                      }}
                                    data={subject}
                                    key={`${"subject"}`}
                                    onChange={(event, editor) => {
                                        const data = event.editor.getData();
                                        updateState({ subject: data, isPopoverVisible: false, isSubjectPopoverVisible: false });
                                    }}
                                    config={CUSTOM_SUBJECT_CONFIGURATION}
                                /> : */}
                                <Input type='text' style={{width:'85%', height:'34px'}}
                                     value={subject}
                                    onClick={(e) => {
                                        updateState({
                                            subjectCursor: e.target.selectionStart,
                                            isPopoverVisible: false, isSubjectPopoverVisible: false
                                        }); 
                                    }}
                                     onChange={(e) => {
                                         updateState({
                                             subject: e.target.value,
                                             subjectCursor: e.target.selectionStart,
                                             isPopoverVisible: false, isSubjectPopoverVisible: false
                                         });
                                     }}
                            /> 
                             <span className="placeholder-button">
                                            <Popover content={getSubjectPlaceHolders(props, 'editorSubject')} placement="bottom" trigger="click" onClick={()=>{ updateState({ isSubjectPopoverVisible: !props.isSubjectPopoverVisible, isPopoverVisible: false });}}>
                                                Placeholder <i className='fa fa-caret-square-o-down' />
                                            </Popover>
                                        </span> 
                        </Form.Item>

                        <Form.Item
                            label={
                                <div>
                                    <FormattedMessage id='modal.settings.emailTemplate.emailMessage' defaultMessage='' />
                                    {/* {props.docName === 'Sales Invoice' ? */}
                                       
                                        {/* : '' } */}
                                </div>
                            }
                        >
                            <div style={{ position:'relative'}}>
                             <span className="placeholder-button" style={{ position:'absolute', right:'20px', top:'25px'}}>
                                            <Popover  content={getPlaceHolders(props, 'editor')} placement="bottom" trigger="click" onClick={()=>{ updateState({ isPopoverVisible: !props.isPopoverVisible, isSubjectPopoverVisible: false });}}>
                                                Placeholder <i className='fa fa-caret-square-o-down' />
                                            </Popover>
                                        </span> 
                                {
                                    <CKEditor
                                        // editor={ClassicEditor}
                                        // config={CUSTOM_CONFIGURATION}
                                        type='replace'
                                        onInit={editor => {
                                            updateState({
                                                editor: editor
                                            });
                                        }}
                                        data={content}
                                        key={`${"content"}`}
                                        onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            updateState({ content: data, isPopoverVisible: false, isSubjectPopoverVisible: false });
                                        }}
                                        config={EMAIL_BODY_CONFIGURATION}
                                    />
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                {/* <Button onClick={() => { props.editor.insertText(' {textId} ');}}
                                type="default"
                        >
                            add textId
                        </Button> */}
                <div className="button-group-action">
                    <div className="align-footer">
                        <Button onClick={() => {
                            props.hideModal();
                           
                        }}
                                type="default"
                        >
                            <FormattedMessage id='button.close.label' defaultMessage='' />
                        </Button>

                        <Button
                            type="primary"
                            onClick={() => {
                                saveTemplateData(props);
                            }}
                        >
                            <FormattedMessage id='button.save.label' defaultMessage='' />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TxnEmailTemplate;
