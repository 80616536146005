import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SMS_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const importCourseCsv = (payload, props) => {
    var formData = new FormData();
    formData.append("file", payload.fileToUpload);
    formData.append(
      "mapping",
      new Blob([payload.mappedData], {
        type: "application/json",
      })
    );
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/course/importFromCSV`,
          formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          showToasterMessage({ messageType: "success", description: lang[(res.data || {}).message] || "Upload completed succesfully" });
          props.updateModalState({
            isuploded:true,
            resData:res.data,
            current: props.current + 1
          })
        })
        .catch(err => {
          showToasterMessage({messageType: "error", description: lang[((err.response || {}).data || {}).message] || "Please reselect the upload file."});
        })
    }
}


export const getCourseImportHeader = (relationshipId, props) => {
    return (dispatch) => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/course/getCourseHeaders?relationshipId=${relationshipId}`)
            .then((res) => {
                dispatch({
                    type: SMS_ACTION_LIST.SCHOOL_COURSE_HEADERS,
                    data: res.data,
                });
            })
            .catch((err) => {
                showToasterMessage({
                    messageType: "error",
                    description: lang[((err.response || {}).data || {}).message] || "Some Error Occurs",
                });
            });
    };
};