import axios from 'axios';
import config from '../../../config/environmentConfig';
import { PROJECT_ACTION_LIST } from '../../../static/constants';
//import {  formatDateForBackend } from '../../../utils';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const getAllProject = (payload) =>{
  return dispatch => {
    //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
   // dispatch({ type: PROJECT_ACTION_LIST.RESET_TRANSACTION_IN_ALPIDE });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/projectMaster/getProjectDetails?relationshipId=${payload.relationshipId}&startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}`)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
         dispatch({
          type: PROJECT_ACTION_LIST.PROJECT_LIST,
          data: data
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = PROJECT_ACTION_LIST.PROJECT_LIST;
        }
      })
  }
}

// export const getProjectsCount = (payload) =>{
//   return dispatch => {
//     return axios
//       .get(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/countTransactionInAlpide?relationshipId=${payload.relationshipId}`)
//       .then(res => {
//          dispatch({
//           type: PROJECT_ACTION_LIST.PROJECT_COUNT,
//           data: res.data
//         });
//       })
//       .catch((err = {}) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//           err.actionToCall = PROJECT_ACTION_LIST.TRANSACTION_COUNT_IN_ALPIDE;
//         }
//       })
//   }
// }

export const resetProjectList = () => {
  return dispatch => {
    dispatch({ type: PROJECT_ACTION_LIST.RESET_PROJECT_LIST });
  }
}