import React from "react";
import { Skeleton, Row, Col, Button, Tabs,  Menu, Dropdown } from 'antd';
import { CONSTANTS, ICONS } from '../../../static/constants';
import { injectIntl, FormattedMessage } from 'react-intl';
import PageBreadcrumb from "../../PageBreadcrumb";
import { CustomAgGrid } from "../../general/CustomAgGrid";
import AdvanceSearchComp from '../../../containers/modal/modalBody/finance/Banking/Search';
import MachedTransactionComp from '../../../containers/modal/modalBody/finance/Banking/Match';
import AssociateransactionComp from '../../../containers/modal/modalBody/finance/Banking/Associate';
import { usePlaidLink } from 'react-plaid-link';
import AuditTrailComp from './auditTrail';
import TransactionInAlpideComp from './transactionInAlpide';
//import BankUploadSatementDraftComp from './bankUploadSatementDraft';
import ImportStatementComp from '../../modal/modalBody/finance/ImportStatement';
import ImportStatementAuthrized from '../../modal/modalBody/finance/ImportStatementAuthrized';
//import UploadStatement from '../../../containers/modal/modalBody/finance/Banking/UploadStatement';
import { showToasterMessage, getMomentDateForUIReadOnly } from '../../../utils';
//import { AG_GRID_CLASS_CONSTANTS } from '../../../static/cssClassConstants';
import ReactHtmlParser from 'react-html-parser';
import * as find from 'lodash.find';


//const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { TabPane } = Tabs;
const agGridStyle = {
    height: '100%',
    width: '100%'
};


const getRowHeight = (params) => {
    return 36;
}


const BankingComp = (props) => {
 //   const [isAllChecked, toggleAllChecked] = useState(false);

    const { pageSize } = props;

    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
        props.updateState({
            gridApi: params.api,
            gridColumnApi: params.columnApi,
        });
    }

    const actionMenu = (data) => {
        return (
            <Menu className="cell-action-dropdown">
                <Menu.Item key="0" onClick={() => { openFindAndMatchPopup(data) }}>
                    <FormattedMessage id='banking.findAndMatch' />
                </Menu.Item>
                {data.Deposit > 0 ?
                    <Menu.Item key="1" onClick={() => { openCreateTransaction(data) }}>
                        <FormattedMessage id='banking.createAndMatch.salesInvoice' />
                    </Menu.Item> : ''
                }
                {!data.Deposit || data.Deposit === 0 ?
                    <Menu.Item key="2" onClick={() => { openCreatePurchaseInvoice(data) }} >
                        <FormattedMessage id='banking.createAndMatch.purchaseInvoice' />
                    </Menu.Item> : ''
                }
                {!data.Deposit || data.Deposit === 0 ?
                    <Menu.Item key="3" onClick={() => { openCreateExpense(data) }} >
                        <FormattedMessage id='banking.createAndMatch.expense'/>
                    </Menu.Item> : ''
                }
                <Menu.Item key="4" onClick={() => {
                    props.showModal({
                        modalBody: <FormattedMessage id='addUpdateData' values={{ value: 'bank' }} />,
                        handleSubmit: () => {
                            props.hideBankTransaction(data, props)
                        }
                    })
                }}>
                    <FormattedMessage id='hideTransaction.text' />
                </Menu.Item>
            </Menu >
        );
    }

    const cardActionMenu = (data) => {
        return (
            <Menu className="cell-action-dropdown" style={{ minWidth: '120px'}}>
                <Menu.Item key="0" onClick={() => { 
                    props.showModal({
                        modalBody: <FormattedMessage id='deleteItem.confirmation' values={{value: 'bank'}} />,
                        handleSubmit: () => {
                            props.deleteBank(data, props)
                        }
                    })
                }}>
                    <FormattedMessage id='delete.text' />
                </Menu.Item>
            </Menu >
        );
    }

    const columnDefs = [
        // {
        //     field: 'isChecked',
        //     colId: 'isChecked_1',
        //     headerComponentFramework: () => <Fragment />,
        //     cellRendererFramework: (params) => {
        //         return <Checkbox
        //             onChange={(e) => {
        //                 params.node.setDataValue('isChecked_1', e.target.checked);
        //                 params.node.data.isChecked = e.target.checked;
        //             }}
        //             checked={params.data.isChecked} />
        //     },
        //     width: 40,
        //     minWidth: 40,
        //     resizable: true
        // },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="common.date.text" /></span>
                </div>
            },
            field: "date",
            resizable: true,
            minWidth: 140,
            // valueFormatter: (data) => {
            //     data.value = data.value ? moment(data.value).format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) : '';
            //     return data.value;
            // },
            cellRendererFramework: (link) => <div>
            {(link.data.date || link.data.Date ) ? getMomentDateForUIReadOnly({date: link.data.date || link.data.Date, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : '' }
        </div>,
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="transactionNumber.text" /></span>
                </div>
            },
            field: "transactionNumber",
            resizable: true,
            minWidth: 140,
            // valueFormatter: (data) => {
            //     data.value = data.value ? moment(data.value).format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) : '';
            //     return data.value;
            // },
            cellRendererFramework: (link) => <div>
            {link.data.transactionNumber ? link.data.transactionNumber : '' }
        </div>,
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="banking.deposit" /></span>
                </div>
            },
            field: "Deposit",
            cellRendererFramework: (link) => <span>
                {link.data.Deposit > 0 ? Number(link.data.Deposit || 0).toFixed(2) : '-'}
            </span>,
            resizable: true,
            minWidth: 70,
            type: 'rightAligned'
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="banking.withdrawal" /></span>
                </div>
            },
            field: "Withdrawal",
            type: 'rightAligned' ,
            resizable: true,
            minWidth: 70,
            cellRendererFramework: (link) => <div>
                {link.data.Withdrawal < 0 ? Number(Math.abs(link.data.Withdrawal || 0)).toFixed(2) : link.data.Withdrawal > 0 ? Number(Math.abs(link.data.Withdrawal || 0)).toFixed(2) : '-'}
            </div>
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="banking.institutionName" /></span>
                </div>
            },
            field: "institutionName",
           hide: true,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="modal.common.priceList.name" /></span>
                </div>
            },
            field: "customerFullName",
           // hide: props.importType==='import',
            resizable: true,
            minWidth: 150,
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="subscriptionName.text" /></span>
                </div>
            },
            field: "subcriptionName",
           // hide: props.importType==='import',
            resizable: true,
            minWidth: 200,
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="banking.paymentChannel" /></span>
                </div>
            },
            field: "paymentChannel",
            // hide: props.importType==='import',
            hide: true,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="banking.merchantName" /></span>
                </div>
            },
            field: "merchantName",
            // hide: props.importType==='import',
            hide: true,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="common.description" /></span>
                </div>
            },
            field: "description",
            sortable: true,
            resizable: true,
            minWidth: 250,
            cellRendererFramework: (params) => {
                setTimeout(() => {
                    if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                        params.eParentOfValue.style.height = 'inherit';
                        params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                        params.node.data.maxHeight = params.reactContainer.scrollHeight;
                        params.node.setRowHeight(params.reactContainer.scrollHeight);
                        params.api && params.api.onRowHeightChanged();
                    }
                }, 200)
                return ReactHtmlParser(params.data.description)
            },
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="partyName.text" /></span>
                </div>
            },
            field: "partyName",
            resizable: true,
            // hide: props.importType==='import',
            hide: true,
            // cellRendererFramework: (link) => <div>
            //     {(link.data.date || link.data.Date) ? moment(link.data.date || link.data.Date).format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) : ''}
            // </div>,
            editable: true,
            cellEditor: 'customDropDownEditor',
            cellEditorParams: (obj) => {
                return obj.node.data.Deposit > 0 ? {
                    items: props.customers[props.pageNumber || 1],
                    optionKeyName: 'customerId',
                    valueKeyName: 'companyName',
                    onSelect: (selectedValue, option) => {
                        obj.node.data.customerName = selectedValue;
                        obj.node.data.customerId = option.key;
                    },
                    allowClear: true,
                    onClear: () => {
                        obj.node.data.customerName = undefined;
                        obj.node.data.customerId = undefined;
                    },
                } :
                {
                    items: props.supplierSummary[props.pageNumber || 1],
                    optionKeyName: 'supplierId',
                    valueKeyName: 'supplierName',
                    onSelect: (selectedValue, option) => {
                        obj.node.data.supplierName = selectedValue;
                        obj.node.data.supplierId = option.key;
                    },
                    allowClear: true,
                    onClear: () => {
                        obj.node.data.supplierName = undefined;
                        obj.node.data.supplierId = undefined;
                    },
                }
            },
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='ledgerAccountCredit.text' />;
            },
            field: "salesLedgerName",
            editable: true,
            resizable: true,
            // hide: props.importType==='import',
            hide: false,
            cellEditor: 'customDropDownEditor',
            cellEditorParams: (obj) => {
                return {
                    items: props.allLedgerAccounts || [],
                    valueKeyName: 'ledgerAccountName',
                    value: obj.node.data.salesLedgerName,
                    optionKeyName: 'ledgerAccountId',
                    placeholder: props.intl.formatMessage({ id: 'ledgerAccountCredit.text' }),
                    onSelect: (selectedValue, option) => {
                        obj.node.data.salesLedgerName = selectedValue;
                        obj.node.data.salesLedgerId = option.key;
                    }
                }
            }
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='ledgerAccountDebit.text' />;
            },
            field: "purchaseLedgerName",
            editable: true,
            resizable: true,
            // hide: props.importType==='import',
            hide: true,
            cellEditor: 'customDropDownEditor',
            cellEditorParams: (obj) => {
                return {
                    items: props.allLedgerAccounts || [],
                    valueKeyName: 'ledgerAccountName',
                    value: obj.node.data.purchaseLedgerName,
                    optionKeyName: 'ledgerAccountId',
                    placeholder: props.intl.formatMessage({ id: 'ledgerAccountCredit.text' }),
                    onSelect: (selectedValue, option) => {
                        obj.node.data.purchaseLedgerName = selectedValue;
                        obj.node.data.purchaseLedgerId = option.key;
                    }
                }
            }
        },
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="addItem.text.project" /></span>
                </div>
            },
            field: "projectName",
            resizable: true,
            editable: true,
            // hide: props.importType==='import',
            hide: true,
            cellEditor: 'customDropDownEditor',
            cellEditorParams: (obj) => {
                return  {
                    items: props.projectList || [],
                    optionKeyName: 'projectMasterId',
                    valueKeyName: 'projectName',
                    value: obj.node.data.projectName,
                    placeholder: props.intl.formatMessage({ id: 'addItem.text.project'}),
                    onSelect: (selectedValue, option) => {
                        const project = find(props.projectList || [], { projectMasterId: option.value }) || {};
                        obj.node.data.projectName = project.projectName;
                        obj.node.data.projectMasterId = project.projectMasterId;
                        obj.node.data.projectNumber = project.projectNumber;
                    },
                    allowClear: true,
                    onClear: () => {
                        obj.node.data.projectName = undefined;
                        obj.node.data.projectMasterId = undefined;
                        obj.node.data.projectNumber = undefined;
                    },
                } 
            },
        },
        // {
        //     headerComponentFramework: () => {
        //         return <div className='table-header-column-container'>
        //             <span className='table-header-column'><FormattedMessage id="instrumentNumber.text" /></span>
        //             <Tooltip placement="top" title={props.intl.formatMessage({ id: 'instrumentNumber.tooltip' })} trigger="click">
        //                 <i className="fa fa-question-circle-o pl5 cursor-pointer" />
        //             </Tooltip>
        //         </div>
        //     },
        //     field: "instrumentNumber",
        //     resizable: true,
        //     editable: true,
        //     cellEditor: 'customTextEditor',
        // },
        // {
        //     headerComponentFramework: () => {
        //         return <div className='table-header-column-container'>
        //             <span className='table-header-column'><FormattedMessage id="instrumentDate.text" /></span> 
        //             <Tooltip placement="top" title={props.intl.formatMessage({ id: 'instrumentDate.tooltip' })} trigger="click">
        //                 <i className="fa fa-question-circle-o pl5 cursor-pointer" />
        //             </Tooltip>
        //         </div>
        //     },
        //     field: "instrumentDate",
        //     resizable: true,
        //     editable: true,
        //     cellEditor: 'customDatePicker',
        //     cellEditorParams: (obj) => {
        //         return {
        //             onChange: (selectedValue) => {
        //                 obj.node.data.instrumentDate = getMomentDateForUI({date: new Date(selectedValue), format});
        //             },
        //         }
        //     },
        //     // cellRendererFramework: (link) => <div>
        //     //     { link.data.instrumentDate ? moment(new Date(link.data.instrumentDate), format) : ''}
        //     // </div>,
        // },
       
        {
            headerComponentFramework: () => {
                return <div className='table-header-column-container'>
                    <span className='table-header-column'><FormattedMessage id="customer.listing.header.action" /></span>
                </div>
            },
            resizable: true,
            minWidth: 330,
            cellRendererFramework: (link) => <div>
                <span style={{ marginRight: '5px' }} title={link.data.matchCount > 0 ? props.intl.formatMessage({ id: 'banking.match.tooltip', defaultMessage: '' }) : props.intl.formatMessage({ id: 'banking.noMatch.tooltip', defaultMessage: '' })}>
                    {link.data.matchCount > 0 ?
                        <Button style={{ width: '100px' }} className={link.data.amount < 0 ? "ant-btn withdrawal-button" : "ant-btn deposit-button"} onClick={() => { openMatchedPopup(link.data) }} > <span style={{ marginRight: '5px' }}>{ link.data.matchCount} </span>
                            <FormattedMessage id='banking.match' />
                        </Button>
                        :
                        <Button style={{ width: '100px', cursor: 'default' }} className="ant-btn-default" > <span style={{ marginRight: '5px' }}> <FormattedMessage id='no' /> </span>
                            <FormattedMessage id='banking.match' />
                        </Button>
                    }
                     
                </span>

                {/* <span title={props.intl.formatMessage({ id: 'banking.findAndMatch', defaultMessage: '' })}>
                    <Button style={{ marginRight: '5px' }} className="ant-btn-primary" onClick={() => { openFindAndMatchPopup(link.data) }} ><FormattedMessage id='banking.findAndMatch' /></Button>
                </span> */}
                
                {/* <span style={{ marginRight: '5px' }} title={props.intl.formatMessage({ id: 'button.create.label', defaultMessage: '' })}>
                    {link.data.Deposit > 0 ?
                        <Button style={{width: '95px'}} className="ant-btn-primary" onClick={() => { openCreateTransaction(link.data) }} ><FormattedMessage id='button.create.label' /></Button>
                        : 
                        <div className="action-dropdown-btn">
                            <Button className="ant-btn-primary" onClick={() => {}}>
                                <FormattedMessage id='button.create.label' />
                            </Button>
                            <Dropdown className="ant-btn-primary" overlay={
                                <Menu onClick={(e) => { 
                                    handleMenuClick(e, link.data) }} className="combined-dropdown">
                                    <Menu.Item key="create_invoice">
                                        <FormattedMessage id='sidebar.menuItem.purchaseInvoice' />
                                    </Menu.Item>
                                    <Menu.Item key="create_expense">
                                        <FormattedMessage id='drawer.invoice.dropdown.expense' />
                                    </Menu.Item>
                                </Menu>

                            }>
                                <span className="dropdown active">
                                    <i className="fa fa-angle-down ml5" />
                                </span>
                            </Dropdown>
                        </div>
                    }
                </span> */}
                <span title={link.data.unassociatedDeposit > 0 ? props.intl.formatMessage({ id: 'banking.associateMatch.tooltip', defaultMessage: '' }): props.intl.formatMessage({ id: 'banking.noAssociateMatch.tooltip', defaultMessage: '' })}>
                    { link.data.unassociatedDeposit > 0 ?
                    <Button style={{ marginRight: '5px', width: '160px' }} className={ link.data.amount < 0 ? "ant-btn withdrawal-button" :"ant-btn deposit-button" } onClick={() => { openAssociatePopup(link.data) }} >
                        <FormattedMessage id='associate.text' /> <span style={{padding: "0px 5px 0px 5px"}}>|</span>  <span style={{paddingRight: "5px"}}> {link.data.unassociatedDeposit}</span> <FormattedMessage id='banking.match' /></Button>
                        :
                        <Button style={{ marginRight: '5px', width: '160px', cursor: 'default' }} className="ant-btn-default" >
                        <FormattedMessage id='nothingToAssociate.text' /></Button>
                        }
                </span>
                <Dropdown overlay={actionMenu(link.data)} trigger={['click']}>
                     <Button className="action-btn small">
                         <i className="fa fa-ellipsis-v" />
                     </Button>
                 </Dropdown>
            </div>,
        }
    ]

    const breadCrumbList = [
        {
            name: 'Finance'
        }, {
            name: 'Banking'
        }
    ];

    const openMatchedPopup = (data) => {
        if(data.matchCount < 1) return;
        const modalData = {
            title: <div>
                <div className='ant-modal-title'><FormattedMessage id='banking.match' /></div>
                <div style={{ 'font-size': '12px' }}> {data.description || ''}</div>
                <div style={{ 'font-size': '12px' }}> {data.date ? getMomentDateForUIReadOnly({date: data.date, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : ''} - {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i>: props.companyInfo.currencyCode + " ")}{Math.abs(Number(data.Deposit) || Number(data.Withdrawal)).toFixed(2)}  ({data.Deposit > 0 ? 'Deposit': 'Withdrawal'}) </div>
            </div>,
            modalBody: <MachedTransactionComp {...props} transactionData={data} activeAccount={props.activeAccount}/>,
            width: 1100,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const openFindAndMatchPopup = (data) => {
        const modalData = {
            title: <div>
                <div className='ant-modal-title'><FormattedMessage id='banking.findAndMatch' /></div>
                <div style={{ 'font-size': '12px' }}> {data.Description || ''}</div>
                <div style={{ 'font-size': '12px' }}> {data.date ? getMomentDateForUIReadOnly({date: data.date, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : ''} - {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i>: props.companyInfo.currencyCode + " ")}{Math.abs(Number(data.Deposit) || Number(data.Withdrawal)).toFixed(2)}  ({data.Deposit > 0 ? 'Deposit': 'Withdrawal'})</div>
            </div>,
            modalBody: <AdvanceSearchComp {...props} transactionData={data}/>,
            width: 1200,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const openAssociatePopup = (data) => {
        const modalData = {
            title: <div>
                <div className='ant-modal-title'><FormattedMessage id='associateTransaction.text' /></div>
                <div style={{ 'font-size': '12px' }}> {data.Description || ''}</div>
                <div style={{ 'font-size': '12px' }}> {data.date ? getMomentDateForUIReadOnly({date: data.date, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : ''} - {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i>: props.companyInfo.currencyCode + " ")}{Math.abs(Number(data.Deposit) || Number(data.Withdrawal)).toFixed(2)} ({data.Deposit > 0 ? 'Deposit': 'Withdrawal'})</div>
            </div>,
            modalBody: <AssociateransactionComp {...props} transactionData={data}/>,
            width: 1200,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const openCreateTransaction = (data) => {
        if(Number(data.amount > 0))
        props.history.push({
            pathname: data.isMultiCurrency ? '/admin/sales-invoice-multi-currency' : '/admin/sales-invoice',
            state: {
                customerId: data.customerId,
                soMasterId: data.salesOrderMasterId,
                soMasterIdArr: data.salesOrderMasterId,
                isbankReco: true,
                bankRecoBalance: Number(data.amount),
                bankTxData: data
            }
        })
    }

    const openCreatePurchaseInvoice = (data) => {
        props.history.push({
            pathname: data.isMultiCurrency ? '/admin/purchase-invoice-multi-currency' : '/admin/purchase-invoice',
            state: {
                customerId: data.customerId,
                soMasterId: data.salesOrderMasterId,
                soMasterIdArr: data.salesOrderMasterId,
                isbankReco: true,
                bankRecoBalance: Math.abs(Number(data.amount)),
                bankTxData: data
            }
        })
    }

    const openCreateExpense = (data) => {
        props.history.push({
            pathname: 'business-expense-create',
            state: {
                isbankReco: true,
                bankRecoBalance: Math.abs(Number(data.amount)),
                bankTxData: data
            }
        })
    }


    // const handleMenuClick = (e, data) => {
    //     switch (e.key) {
    //         case 'create_invoice':
    //             openCreatePurchaseInvoice(data);
    //             break;
    //         case 'create_expense':
    //             openCreateExpense(data)
    //             break;

    //         default:
    //             break;
    //     }
    // }


    function callback(key, props) {
        // const payload = props.payload;
        if (key === '1') {

        } else if (key === '2') {
            const payload = {
                relationshipId: props.companyInfo.relationshipId,
                pageNumber: 1,
                pageSize: 10
            };
            props.getTransactionsInAlpide(payload);
            props.getTransactionsCountInAlpide(payload);
        } else if (key === '3') {
            const payload = {
                relationshipId: props.companyInfo.relationshipId,
                pageNumber: 1,
                pageSize: 10
            };
            props.getHiddenTransactions(payload);
            props.getHiddenTransactionsCount(payload);
        }
        else if (key === '4') {
            const payload = {
                relationshipId: props.companyInfo.relationshipId,
                pageNumber: 1,
                pageSize: 10
            };
            props.getAllBankStatementUploadDraft(payload);
            props.getStatementUploadDraftCount(payload);
        }
    }

    const openImportStatement = (data) => {
        const modalData = {
            title: <div>
                <div className='ant-modal-title'><FormattedMessage id='importBankStatement.text' /></div>
            </div>,
            modalBody: <ImportStatementComp {...props}  />,
            width: 800,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    
    const openImportStatementFromAuthrizedDotNet = (paymentAccount) => {
        const modalData = {
            title: <div>
                <div className='ant-modal-title'><FormattedMessage id='importStatement.text' /></div>
            </div>,
            modalBody: <ImportStatementAuthrized {...props} authrizeNetAcc={paymentAccount} />,
            width: 800,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const onSuccess = (token, metadata) => {
        props.setAccessToken({ publicToken: token, relationshipId: (props.companyInfo || {}).relationshipId }, props)
    }

    const config = {
        token: props.plaidLinkToken,
        onSuccess,
    };
    const { open, ready } = usePlaidLink(config);

    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="heading.finance.banking"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
            <button className="create-action-btn" onClick={() => {}}>
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>

        <Row>
          <Col span={20}>
            <Row>
              {props.availablePaymentGateways &&
              props.availablePaymentGateways.length
                ? props.availablePaymentGateways.map((paymentAccount, i) => {
                    return (
                      <Col span={5} style={{ marginRight: "10px" }}>
                        <div className="bankInfo-card">
                          <div title="">
                            <Row>
                              <Col span={20}>
                                <div>
                                  <b>
                                    {paymentAccount.userDefinedGatewayName ||
                                      "Authorize.net"}
                                  </b>
                                </div>
                                <span className="account-type">Current</span>
                              </Col>
                              <Col
                                span={2}
                                className="notification-count"
                                title="Action panding"
                              >
                                {" "}
                                <span>2</span>{" "}
                              </Col>
                              <Col span={1} title="Action panding">
                                {" "}
                                <span className="card-menu">
                                  <Dropdown
                                    overlay={
                                      <Menu
                                        className="cell-action-dropdown"
                                        style={{ minWidth: "120px" }}
                                      ></Menu>
                                    }
                                    trigger={["click"]}
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </Dropdown>
                                </span>{" "}
                              </Col>
                            </Row>
                          </div>
                          <div className="border-footer mt10"></div>
                          <Row>
                            <Col span={22}>
                              <p>
                                Bank Bal. - <b>USD </b>0.00
                              </p>
                              <p>
                                In Alpide - <b>USD </b>0.00
                              </p>
                            </Col>
                            <Col span={2}>
                              <br />
                              <span
                                title={props.intl.formatMessage({
                                  id: "fetchStatement.text",
                                  defaultMessage: "",
                                })}
                                className="right-align"
                              >
                                <span
                                  className="download-tx"
                                  onClick={() => {
                                    openImportStatementFromAuthrizedDotNet(
                                      paymentAccount
                                    );
                                  }}
                                >
                                  <i className="fa fa-download" />
                                </span>
                              </span>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    );
                  })
                : ""}
              {props.plaidLinkedAccounts &&
                props.plaidLinkedAccounts.length !== 0 &&
                props.plaidLinkedAccounts.map((account, i) => {
                  return (
                    <Col span={5} style={{ marginRight: "10px" }}>
                      {i < 4 || props.showMore ? (
                        <div className="bankInfo-card">
                          <div title="">
                            <Row>
                              <Col span={20}>
                                <div>
                                  <b>{account.institutionName || ""}</b>
                                </div>
                                <span className="account-type">Current</span>
                              </Col>
                              <Col
                                span={2}
                                className="notification-count"
                                title="Action panding"
                              >
                                {" "}
                                <span>2</span>{" "}
                              </Col>
                              <Col span={1} title="Action panding">
                                {" "}
                                <span className="card-menu">
                                  <Dropdown
                                    overlay={cardActionMenu(account)}
                                    trigger={["click"]}
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </Dropdown>
                                </span>{" "}
                              </Col>
                            </Row>
                          </div>
                          <div className="border-footer mt10"></div>
                          <Row>
                            <Col span={22}>
                              <p>
                                Bank Bal. - <b>USD </b>
                                {10000 * (i + 1)}.00
                              </p>
                              <p>
                                In Alpide - <b>USD </b>
                                {10000 * (i + 1)}.00
                              </p>
                            </Col>
                            <Col span={2}>
                              <br />
                              <span
                                title={props.intl.formatMessage({
                                  id: "fetchStatement.text",
                                  defaultMessage: "",
                                })}
                                className="right-align"
                              >
                                <span
                                  className="download-tx"
                                  onClick={() => {
                                    props.getBankTransactions({
                                      institutionId: account.institutionId,
                                      relationshipId: (props.companyInfo || {})
                                        .relationshipId,
                                    });
                                    props.updateState({
                                      importType: "fetch",
                                      activeAccount: account,
                                    });
                                  }}
                                >
                                  <i className="fa fa-download" />
                                </span>
                              </span>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  );
                })}
            </Row>
          </Col>
          {props.plaidLinkedAccounts && props.plaidLinkedAccounts.length > 4 ? (
            <Col span={4}>
              <div>
                <br />
                <br />
                <br />
                <br />
                {!props.showMore ? (
                  <h2
                    onClick={() => {
                      props.updateState({ showMore: true });
                    }}
                  >
                    Show More...
                  </h2>
                ) : (
                  <h2
                    onClick={() => {
                      props.updateState({ showMore: false });
                    }}
                  >
                    Show Less...
                  </h2>
                )}
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>

        <div className="view-container">
          <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
            {/* <Row className="search-container" type='flex' justify='center'>
                        <Col span={24} className='fa-fw'>
                            <h1><FormattedMessage id="" /></h1>
                        </Col>
                    </Row> */}

            <Tabs
              onChange={(key) => {
                callback(key, props);
              }}
              type="line"
            >
              <TabPane
                tab={props.intl.formatMessage({
                  id: "banking.tab.transacitonForReview",
                  defaultMessage: "",
                })}
                key="1"
              >
                <div className="view-container-actions">
                  <div className="left-actions">
                    <div className="table-heading">
                      <FormattedMessage
                        id="finance.banking.txnReview.table.heading"
                        defaultMessage=""
                      />
                    </div>
                    {pageSize ? (
                      <>
                        <div className="vertical-sep" />
                        <div>{pageSize}</div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="right-actions">
                    <button
                        onClick={() => openImportStatement()}
                    >
                        <FormattedMessage id="importStatement.text" />
                    </button>

                    {props.plaidLinkToken ? (
                          <button
                            onClick={() => open()}
                            disabled={!ready}
                          >
                            <FormattedMessage id="addAccount.text" />
                          </button>
                        ) 
                        :
                        ("")
                    }
                  </div>
                </div>
                      {/* <Col span={1}>
                        <button className="all-checkbox-control">
                            <Checkbox
                                checked={isAllChecked}
                                onChange={(e) => {
                                    toggleAllChecked(e.target.checked);
                                    props.gridApi.forEachNode(obj => {
                                        obj.setDataValue('isChecked_1', e.target.checked);
                                    })
                                }}
                            />
                        </button>

                        
                    </Col>
                    <Col span={16} >
                    <button className="action-btn" onClick={() => {
                            const uploadStatementList = [];
                            props.gridApi.forEachNode(obj => {
                                if (obj.data.isChecked) {
                                    uploadStatementList.push(obj.data);
                                }
                            });

                            const modalData = {
                                title: <FormattedMessage id="banking.upload.statement" />,
                                modalBody: <UploadStatement {...props} uploadStatementList={uploadStatementList} />,
                                width: 1400,
                                hideFooter: true,

                            };

                            if (uploadStatementList.length) {
                                props.showModal(modalData);
                            } else {
                                showToasterMessage({
                                    messageType: "error", description: props.intl.formatMessage({ id: 'common.record.notSelected', defaultMessage: '' })
                                })
                            }

                        }}>
                            <Tooltip placement="right" title={props.intl.formatMessage({ id: 'banking.upload.statement', defaultMessage: '' })}>
                                <i className="fa fa-upload" />
                            </Tooltip>
                        </button>
                        {(props.bankTransactions && props.bankTransactions.length) ?
                            <span style={{backgroundColor: "yellow"}}>
                                <FormattedMessage id="importStatement.notUploaded.message" />
                            </span> : ''
                        }
                    </Col> */}
                    
                <div className="agGridWrapper">
                  <div className="ag-theme-balham" style={agGridStyle}>
                    <CustomAgGrid
                      columnDefs={columnDefs}
                      rowData={props.bankTransactions || []}
                      rowDragManaged={true}
                      domLayout={"autoHeight"}
                      animateRows={true}
                      onGridReady={onGridReady}
                      getRowHeight={getRowHeight}
                      defaultColDef={{
                        flex: 1,
                        autoHeight: true,
                        wrapText: true,
                      }}
                    />
                  </div>
                </div>
                <br />
                {props.bankTransactions && props.bankTransactions.length ? (
                  <div>
                    <Row>
                      <Col span={21}>
                        <Row>
                          <Col span={1} style={{ marginRight: "-15px" }}>
                            <span className={"ant-btn deposit-button"}>
                              {"  "}
                            </span>
                          </Col>
                          <Col span={4}>
                            <FormattedMessage id="banking.deposit" />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={1} style={{ marginRight: "-15px" }}>
                            <span className={"ant-btn withdrawal-button"}>
                              {"  "}
                            </span>
                          </Col>
                          <Col span={4}>
                            <FormattedMessage id="banking.withdrawal" />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={3}>
                        <FormattedMessage id="totalTransactions.text" /> -{" "}
                        {props.bankTransactions.length}
                      </Col>
                    </Row>
                    <div className="ant-modal-footer fan-bank">
                      <Button
                        type="primary"
                        onClick={() => {
                          let ledgerAccountMissing = false;
                          let invalidLadgerAccount = false;
                          (props.bankTransactions || []).forEach((row) => {
                            if (!row.salesLedgerId || !row.purchaseLedgerId) {
                              ledgerAccountMissing = true;
                            }
                            if (
                              Number(row.salesLedgerId || 0) ===
                              Number(row.purchaseLedgerId || 0)
                            ) {
                              invalidLadgerAccount = true;
                            }
                          });
                          if (ledgerAccountMissing) {
                            return showToasterMessage({
                              messageType: "error",
                              description: props.intl.formatMessage({
                                id: "banking.ledgerAccount.validation",
                              }),
                            });
                          }
                          if (invalidLadgerAccount) {
                            return showToasterMessage({
                              description: props.intl.formatMessage({
                                id: "sameLadger.validation",
                              }),
                              messageType: "error",
                            });
                          }
                          const modalData = {
                            modalBody: (
                              <FormattedMessage
                                id="save.confirmation"
                                defaultMessage=""
                              />
                            ),
                            handleSubmit: () => {
                              props.createStatementPayment(props);
                              props.hideModal();
                              props.popModalFromStack();
                              showToasterMessage({
                                messageType: "success",
                                description: props.intl.formatMessage({
                                  id: "success.upload.message",
                                }),
                              });
                            },
                          };
                          props.pushModalToStack(modalData);
                        }}
                      >
                        <FormattedMessage
                          id="banking.upload.statement"
                          defaultMessage=""
                        />
                      </Button>
                      {/* 
                                        <Button type="primary" onClick={() => {

                                            const modalData = {
                                                modalBody: <FormattedMessage
                                                    id='supplier.rfq.form.draft.confirmation'
                                                    defaultMessage=''
                                                />,
                                                handleSubmit: () => {
                                                    props.saveAsDraft(props);
                                                    props.hideModal();
                                                    props.popModalFromStack();
                                                }
                                            };
                                            props.pushModalToStack(modalData);
                                        }}><FormattedMessage id='button.draft.label' defaultMessage='' /></Button> */}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </TabPane>

              <TabPane
                tab={props.intl.formatMessage({
                  id: "banking.tab.allocatedInAlpide",
                  defaultMessage: "",
                })}
                key="2"
              >
                <TransactionInAlpideComp {...props} />
              </TabPane>

              <TabPane
                tab={props.intl.formatMessage({
                  id: "hideTransaction.text",
                  defaultMessage: "",
                })}
                key="3"
              >
                <AuditTrailComp {...props} />
              </TabPane>
              {/* <TabPane tab={props.intl.formatMessage({ id: 'banking.tab.uploadStatementDraft', defaultMessage: '' })} key="4">
                            <br />
                            <BankUploadSatementDraftComp {...props} />
                        </TabPane> */}
            </Tabs>
          </Skeleton>
        </div>
      </>
    );
};

export default injectIntl(BankingComp);