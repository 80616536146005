import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AccountSetupComp from '../../../../../components/drawer/communication/account/setup';
import { saveOrUpdateSetup } from "./action";
import { getText2DonateConfig } from "../../../../communication/account/action";
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchProjects } from './../../../../../actions/commonActions';
import { fetchDataIfNeeded } from "../../../../../utils";
import { Select } from 'antd';
import { fetchAvailablePaymentGateway } from '../../../../integrations/Payment/action';
import { fetchAllLedgerAccounts } from '../../../../finance/LedgerAccounts/action';
import { getAllFundraisingCampaign } from '../../../fundraising/pledges/action';
const { Option } = Select;

class AccountSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cellPhone: '',
      accountConfig: props.accountConfig || {},
      projectMasterId: props.accountConfig.projectMasterId,
      alpidePaymentGatewayId: props.accountConfig.alpidePaymentGatewayId,
      chartOfAccountDetailsId: props.accountConfig.ledgerAccountId,
      displayName: props.accountConfig.displayName,
      source: props.source,
      dgNameOptionList: [],
      campaignId: props.accountConfig.fundraisingCampaignId,
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      source: this.props.source, 
      pageNumber: 1,
      pageSize: 1000
    };
   // fetchDataIfNeeded("getText2DonateConfig", "accountConfig", this.props, payload); 
    this.props.fetchAvailablePaymentGateway({ relationshipId: companyInfo.relationshipId });
    this.props.fetchProjects({ relationshipId: companyInfo.relationshipId });
    this.props.fetchAllLedgerAccounts({ relationshipId: companyInfo.relationshipId });
    fetchDataIfNeeded("getAllFundraisingCampaign", "allCampaignList", this.props, payload);
  }

  componentWillReceiveProps(props) {

    if (props.dgList && props.dgList.length > 0 && this.state.dgNameOptionList.length === 0) {
      let name = [];
      let ids = [];
      for (let i = 0; i < props.dgList.length; i++) {
        ids.push(props.dgList[i].distributionGroupId);
        name.push(<Option value={props.dgList[i].distributionGroupId}>{props.dgList[i].dgName}</Option>);
      }
      this.setState({ dgNameOptionList: name, dgIds: ids });
    }
    

  }

  render() {
    return <AccountSetupComp
      {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}
      updateHeaderState={(data) => { this.props.updateState(data) }}
      
    >
    </AccountSetupComp>
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    dgList: state.communicationReducer.dgList,
    subAccount: state.communicationReducer.subAccount,
   // accountConfig: state.communicationReducer.accountConfig,
    availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
    projectList: state.common.projectList,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    userInfo: state.common.userInfo,
    allCampaignList: state.fundraisingReducer.allCampaignList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProjects,
      fetchAvailablePaymentGateway,
      fetchAllLedgerAccounts,
      saveOrUpdateSetup,
      getText2DonateConfig,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getAllFundraisingCampaign
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetup);
