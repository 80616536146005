import React from "react";
import { injectIntl } from "react-intl";
import "./index.scss";
import {
  Row,
  Col,
  DatePicker,
} from "antd";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import {
  getUserDefinedName,
  getCurrencySymbol,
} from "../../../utils";
import { CONSTANTS } from "../../../static/constants";
import moment from "moment-timezone";


const StudentInfo = (props) => {
  const {
    formSetting = {},
    studentList = [{}],
  } = props;
  const addToStudentList = (i, obj) => {
    let temp = studentList || [];
    temp[i] = obj;
    props.updateState({ studentList: temp });
  };
  const gridColStyle = {
    xs: {
      span: 12,
    },
    lg: {
      span: 3,
    },
  };
  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={12} className="bold-title">
          Student Information
        </Col>
      </Row>

      {/* <Row
        gutter={[16, 16]}
        style={{
          borderBottom: "solid 1px #e4e4e4",
          fontWeight: 700,
          color: "#283c50",
        }}
        className="mt20"
      >
        <Col {...gridColStyle}>
          Student Name<span className="required">*</span>
        </Col>
        <Col {...gridColStyle}>Gender</Col>
        <Col {...gridColStyle}>
          {getUserDefinedName("Class", props)}
          <span className="required">*</span>
        </Col>

        <Col {...gridColStyle}>Fees</Col>

        <Col span={4}>
          {formSetting.dobType === 2 ? "Age" : "DOB"}
        </Col>
        <Col {...gridColStyle}>
          Allergies<span className="required">*</span>
        </Col>
        {(studentList || []).length && studentList[0].isAllergy ?
          <Col {...gridColStyle}>
            Description
          </Col>
          :
          ''
        }
      </Row> */}

      {(studentList || []).map((stuData, i) => {
        return (
          <>
          <Row
            gutter={[8, 16]}
            key={i}
            // style={{ borderBottom: "solid 1px #e4e4e4" }}
            justify="middle"
          >
            <Col xs={12} lg={4}>
            <div className="table-label">
          Student Name<span className="required">*</span>
        </div>
              <TextBox
                placeholder={"Student Name"}
                type="text"
                maxLength={30}
                countStyle={{ top: "34px" }}
                value={stuData.name}
                className={props.submitClicked && !((stuData.name) || (stuData.name)?.split(" ").length < 2)
                  ? "input-text-error"
                  : ""}
                onChange={(e) => {
                  addToStudentList(i, {
                    ...stuData,
                    name: e.target.value,
                  });
                } } 
              />
            </Col>
            <Col {...gridColStyle}>
              <div className="table-label">Gender</div>
              <Dropdown
                placeholder="Gender"
                items={["Male", "Female", "Rather Not Say"]}
                showSearch={true}
                value={stuData.gender}
                className={props.submitClicked && !stuData.gender
                  ? "select-error"
                  : ""}
                onSelect={(selectedValue, option) => {
                  addToStudentList(i, {
                    ...stuData,
                    gender: selectedValue,
                  });
                } } 
              />
            </Col>

            <Col {...gridColStyle}>
              <div className="table-label">
                {getUserDefinedName("Class", props)}<span className="required">*</span>
              </div>

              <Dropdown
                items={formSetting.classFees || []}
                optionKeyName="classId"
                valueKeyName="className"
                valueKeyName2="courseName"
                value={stuData.className}
                placeholder={getUserDefinedName("Class", props)}
                className={props.submitClicked && !stuData.classId
                  ? "select-error"
                  : ""}
                showSearch={true}
                onSelect={(selectedValue, option) => {
                  const obj = formSetting.classFees.find(
                    (obj) => Number(obj.classId) === selectedValue
                  );
                  const classFeeAmount = (
                    obj?.feeStructList || []
                  )?.reduce((sum, obj) => sum + obj?.amount, 0);
                  addToStudentList(i, {
                    ...stuData,
                    ...obj,
                    classFeeAmount: classFeeAmount,
                  });

                  props.getRegStudentLimitCount({
                    relationshipId: props.relationshipId,
                    classId: selectedValue
                  })
                } } 
              />
            </Col>
      
            <Col {...gridColStyle}>
              <div className="table-label">Fees</div>
              <TextBox
                prefix={getCurrencySymbol(
                  props.companyInfo?.currencyCode || "-"
                )}
                type="number"
                disabled={true}
                style={{ color: "rgba(0, 0, 0, 0.85)" }}
                placeholder="0.00"
                value={stuData.classFeeAmount || ""} 
              />
            </Col>

              <Col xs={12} lg={4}>
              <div className="table-label">
          {formSetting.dobType === 2 ? "Age" : "DOB"}
          <span className="required">*</span>
        </div>
                {formSetting.dobType === 2 ?
                  (
                    <TextBox
                      placeholder="Age"
                      type="number"
                      maxLength={3}
                      countStyle={{ top: "34px" }}
                      className={props.submitClicked && !stuData.age
                        ? "input-text-error"
                        : ""}
                      value={stuData.age || ""}
                      onChange={(ee) => {
                        addToStudentList(i, {
                          ...stuData,
                          age: ee.target.value,
                        });
                      } } />
                  )
                  :
                  (
                    <DatePicker
                      format={CONSTANTS.DISPLAY_DATE_FORMAT}
                      value={stuData.dob}
                      // className={props.submitClicked && !stuData.selectedDate
                      //   ? "select-error"
                      //   : ""}
                 
                      style={{
                        borderColor: (props.submitClicked && !stuData.dob) ? 'red' : '#d9d9d9'
                      }}
                   
                      onChange={(selectedDate) => {
                        addToStudentList(i, {
                          ...stuData,
                          dob: selectedDate,
                        });
                      } }
                      disabledDate={(current) => current && current.isAfter(moment().endOf('day'))} />
                  )}
              </Col>

              <Col {...gridColStyle}>
                <div className="table-label">
                  Allergies<span className="required">*</span>
                </div>
                <Dropdown
                  placeholder="Select Allergies"
                  items={[
                    { id: 1, value: "Yes" },
                    { id: 0, value: "No" },
                  ]}
                  optionKeyName="id"
                  valueKeyName="value"
                  value={stuData.isAllergy}
                  className={props.submitClicked && !stuData.isAllergy === undefined
                      ? "select-error"
                      : ""}
                  showSearch={false}
                  onSelect={(selectedValue, option) => {
                    addToStudentList(i, {
                      ...stuData,
                      isAllergy: selectedValue,
                    });
                  } } 
                />
              </Col>
              {stuData.isAllergy ?
                <Col xs={12} lg={4}>
               
          <div className="table-label">
            Description
          </div>
         
        
                  <TextBox
                    placeholder="Allergy Discription"
                    value={stuData.allergies || ""}
                    className={props.submitClicked && !stuData.allergies
                      ? "input-text-error"
                      : ""}
                    onChange={(e) => {
                      addToStudentList(i, {
                        ...stuData,
                        allergies: e.target.value,
                      });
                    } } />
                </Col>
                :
                ''}
            </Row>

            </>
        
        );
      })}
    </>
  );
};

export default injectIntl(StudentInfo);
