import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewsLetterPreview from "../../../components/B2B/NewsLetterPreview";
import { fetchNewsLetter } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../static/constants';

class NewLetterPreview extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(this.props.location.search) || {};
        let teamIds = (linkProps.f || '').split("_");
        this.state = {
            formSetting: {},
            status: linkProps.status,
            relationshipId: linkProps.relationshipId,
            relationshipBillingAddress: {},
            teamMasterId: teamIds[0],
            teamMemberId: teamIds[1],
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(this.props.location.search) || {};
        let ids = (linkProps.t || '').split("_");
        const payload = {
            relationshipId: Number(ids[0]),
            templateId: Number(ids[1])
        };
        this.props.fetchNewsLetter(payload, this.props);
    }

    componentWillReceiveProps(props) {
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            this.setState({
                relationshipBillingAddress,
            })
        }
    }
    render() {
        window.document.title= this.props?.companyInfo?.storeName || '';
        return <div>
             <NewsLetterPreview {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> 
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        newsLetterTemplate: state.membershipReducer.newsLetterTemplate,
        companyInfo: state.settings.relationshipData
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    fetchNewsLetter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewLetterPreview);
