import React from "react";
import CashBook from "../../../components/finance/CashBook";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchCashBookList, getCashBookCount, resetPaginatedData} from "./action";
import { pushModalToStack , popModalFromStack, showModal, hideModal} from "../../../actions/commonActions";

 class CashBookListComp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
    };
  }

  
  componentDidMount() {

    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 200
    };
    this.props.fetchCashBookList(payload, companyInfo);
    this.props.getCashBookCount(payload, companyInfo)
  }

  render() {
    return (
      <>
        <CashBook
          {...this.props}
          {...this.state}
           updateState={(data) => {
            this.setState(data);
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      isLoading: state.common.isLoading,
      permissions: state.auth.permissions,
      cashBookList : state.finance.cashBookList,
      cashBookCount :  state.finance.cashBookCount, 
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCashBookList, getCashBookCount, resetPaginatedData,
    pushModalToStack,
    popModalFromStack,
    showModal, 
    hideModal
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CashBookListComp);
