import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReminderSetupComponent from '../../../../../components/modal/modalBody/settings/ReminderSetupForForm';
import EmailComponent from '../../../../../containers/drawer/feeReminderEmail';
import { saveInvoiceReminder, getInvoiceReminderSetupByInvoiceMasterId, resetInvoiceReminderSetup} from './action';
import { deleteInvoiceReminder } from '../../../../customer/invoice/Listing/action';

class ReminderSetup extends Component {

    constructor(props){
        super(props);
        this.state= {
            feeReminderEmailDrawerVisible: false,
            subject: '',
            emailBody: '',
            remindDuration: 'before',
            isBulkInvoice: props.isBulkInvoice || false,
            emailDetails: ''
        }
    }
    componentDidMount() {
        // const companyInfo = this.props.companyInfo || {};
      //   const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.salesInvoiceDetail?.customerId, invoiceMasterId: this.props.salesInvoiceDetail?.invoiceMasterId };
    }

    componentWillReceiveProps(props) {
    }
    render() {
        return <div>
            {this.state.feeReminderEmailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <ReminderSetupComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        userInfo: state.common.userInfo,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveInvoiceReminder, 
    getInvoiceReminderSetupByInvoiceMasterId, 
    resetInvoiceReminderSetup,
    deleteInvoiceReminder
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReminderSetup);