import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FormRegistrationsListComp from "../../../components/schoolManagement/formRegistrations";
import {
  getRegisteredMemberByFormId, getRegisteredMemberCount, getRegisteredMemberByClass, 
  getRegisteredMemberCountByClass, getRegisteredMemberByCourse, getRegisteredMemberCountByCourse,
  getAllFormRegistrations, getFormRegistrations, getFormRegistrationsCount, deleteRegistration
} from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { deleteStudent } from "../student/action";



class FormRegistrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
      isStudentLoaded: false,
      formNameMap: {},
      customerInfo: props.customerInfo,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const userInfo = this.props.userInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: this.state.pageNumber,
      pageSize: this.state.pageSize,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      formId: 0
    };
    // this.props.getRegisteredMemberByFormId(payload);
    // this.props.getRegisteredMemberCount(payload);
    this.props.getAllFormRegistrations({...payload, pageSize: 500});
    this.props.getFormRegistrations(payload);
    this.props.getFormRegistrationsCount(payload);
  }


  render() {
    return (
      <FormRegistrationsListComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    studentList: state.school.studentList,
    studentCount: state.school.studentCount,
    registrationForms: state.membershipReducer.studentFormList,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,
    registrationList: state.school.formRegistrations,
    registrationCount: state.school.formRegistrationCount

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRegisteredMemberByFormId,
      getRegisteredMemberCount,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getRegisteredMemberByClass, 
      getRegisteredMemberCountByClass, 
      getRegisteredMemberByCourse, 
      getRegisteredMemberCountByCourse,
      getAllFormRegistrations,
      deleteStudent,
      getFormRegistrations,
      getFormRegistrationsCount,
      deleteRegistration
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormRegistrations);
