import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer } from 'antd';
import EmailBody from './EmailBody';



function EmailDrawer(props) {
  
  const closeDrawer = () => {
    props.updateState({
      feeReminderEmailDrawerVisible: false,
      isSendEmail : 0,
      feeReminderEmailDetails: props.updateReminder ? '':  props.feeReminderEmailDetails
    })
  }

  return (
    <Fragment>
       
      <Drawer
        title={
          <div className="drawer-title">
            <FormattedMessage id='email' defaultMessage='' />
          </div>
        }
        className="email-template-modal"
        keyboard={false}
        width={720}
        maskClosable={false}
        zIndex={99999}
        destroyOnClose={true}
        onClose={closeDrawer}
        visible={props.feeReminderEmailDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              props.initCalls();
            }
          }
        }
      >

      <EmailBody {...props}/>

      </Drawer>
      
    </Fragment>
  );
}

export default injectIntl(EmailDrawer);
