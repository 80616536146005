import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { BUSINESS_EXPENSE_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchRelationshipExpenseDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relationshipExpense/getRelationshipExpenseByExpenseId?expenseMasterId=${payload.expenseMasterId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_DETAIL, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_DETAIL;
        }
      })
  }
}


export const resetRelationshipExpenseDetails = () => {
  return dispatch => {
    dispatch({ type: BUSINESS_EXPENSE_ACTION_LIST.BUSINESS_EXPENSE_DETAIL_RESET });
  }
}

export const deleteRelationshipExpense = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relationshipExpense/deleteRelationshipExpense`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description:  lang['expense.delete.success'] });
        props.fetchBusinessExpenses(props);
        props.getBusinessExpensesCount(props);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
      })
  }
}