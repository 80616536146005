import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Popover,Input } from 'antd';
import { Dropdown } from '../../../../general/Dropdown';
//import { TextBox } from '../../../../general/TextBox';
import { EMAIL_BODY_CONFIGURATION } from '../../../../../static/ckEditorConfigConstants';
import { PLACEHOLDER_CONSTANTS } from '../../../../../static/placeholderConstants';
import CKEditor from '../../../../general/CustomCKEditor/ckeditor';
import { showToasterMessage } from '../../../../../utils';

const saveTemplateData = (props) => {
    if (!props.docName || !props.content) {
        return showToasterMessage({
            messageType: 'error', description: props.intl.formatMessage({id: 'email.template.validation'})
        });
    }
    // if (!props.senderEmails.length) {
    //     return showToasterMessage({
    //         messageType: 'error', description: props.intl.formatMessage({id: 'email.template.validation'})
    //     });
    // }
    let payload = {
        content: props.content,
        docName: props.docName,
        subject: props.subject,
        relationshipId: (props.companyInfo || {}).relationshipId,
        clientRelationshipFromEmailId: props.clientRelationshipFromEmailId,

    };
    if (props.templateData && props.docName === props.templateData.docName) {
        payload.settingEmailTemplateId = props.templateData.settingEmailTemplateId;
        payload.version = props.templateData.version;
    }
    props.pushModalToStack({
        modalBody: "Are you sure you want to save?",
        handleSubmit: () => {
            props.saveOrUpdateEmailTemplate(payload, props);
        }
    });
    
};

const insertTextInSubject = (props, textToInsert) => {
    if (props.subject) {
        let subject = props.subject.slice(0, props.subjectCursor) + textToInsert + props.subject.slice(props.subjectCursor + 1);
        props.updateState({ subject: subject, subjectCursor: props.subjectCursor+textToInsert?.length })
    } else {
        props.updateState({ subject: textToInsert, subjectCursor: props.subjectCursor+textToInsert?.length })
    }
}

const getSubjectPlaceHolders = (props, editorName ) => {
    return <div style={{ width: '400px' }}>
        <Row type='flex' gutter={[8, 24]}>
           
            <Col span={8}>
                <div style={{ fontWeight: '700' }}>Organization</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.USER); }}>User Name</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.DESIGNATION); }}>Designation</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.COMPANY_LOGO); }}>Company Logo</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.STORE_NAME); }}>Company Name</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORG_EMAIL); }}>Email</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORG_PHONE); }}>Phone #</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.ORG_FAX); }}>Fax #</div>
                <div className="ml10 pointer" onClick={() => { props[editorName || 'editor'].insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
            </Col>
        </Row>
    </div>
}

const EmailSignature = (props) => {
    let { docName, subject, content, selectDocumentOptions, companyInfo, senderEmails, updateState } = props;
    return (
        <div className="modal-dialog">
            <div className="modal-content full-width modify-width">
                <div className="inner-content">
                    <Form className="pr20" layout="vertical" hideRequiredMark>
                        
                        <Form.Item
                            label={
                                <div>
                                    <FormattedMessage id='modal.settings.emailSignature.emailMessage' defaultMessage='' />
                                    {/* {props.docName === 'Sales Invoice' ? */}
                                       
                                        {/* : '' } */}
                                </div>
                            }
                        >
                            <div style={{ position:'relative'}}>
                             <span className="placeholder-button" style={{ position:'absolute', right:'20px', top:'25px'}}>
                                            <Popover  content={getSubjectPlaceHolders(props, 'editor')} placement="bottom" trigger="click" onClick={()=>{ updateState({ isPopoverVisible: !props.isPopoverVisible, isSubjectPopoverVisible: false });}}>
                                                Placeholder <i className='fa fa-caret-square-o-down' />
                                            </Popover>
                                        </span> 
                                {
                                    <CKEditor
                                        // editor={ClassicEditor}
                                        // config={CUSTOM_CONFIGURATION}
                                        type='replace'
                                        onInit={editor => {
                                            updateState({
                                                editor: editor
                                            });
                                        }}
                                        data={content}
                                        key={`${"content"}`}
                                        onChange={(event, editor) => {
                                            const data = event.editor.getData();
                                            updateState({ content: data, isPopoverVisible: false, isSubjectPopoverVisible: false });
                                        }}
                                        config={EMAIL_BODY_CONFIGURATION}
                                    />
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                {/* <Button onClick={() => { props.editor.insertText(' {textId} ');}}
                                type="default"
                        >
                            add textId
                        </Button> */}
                <div className="button-group-action">
                    <div className="align-footer">
                        <Button onClick={() => {
                            props.hideModal();
                            props.popModalFromStack();
                        }}
                                type="default"
                        >
                            <FormattedMessage id='button.close.label' defaultMessage='' />
                        </Button>

                        <Button
                            type="primary"
                            onClick={() => {
                                saveTemplateData(props);
                            }}
                        >
                            <FormattedMessage id='button.save.label' defaultMessage='' />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmailSignature;
