import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PaidInvoicesComponent from '../../../../../components/modal/modalBody/customer/PaidInvoices';
import { fetchPaidInvoices, fetchPaidInvoicesCount, resetPaginatedData } from './action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from  '../../../../../utils';

class PaidInvoices extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          pageSize: 25,
          pageNumber: 1,
          searchedText: ''
        };
      }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            customerId: this.props.customerData && this.props.customerData.customerId,
            pageNumber: 1,
            pageSize: 25
        }
        // fetchPaginationDataIfNeeded('fetchPaidInvoices', 'paidInvoiceList', this.props, payload, true);
        // fetchDataIfNeeded('fetchPaidInvoicesCount', 'paidInvoiceListCount', this.props, payload, true);
        this.props.fetchPaidInvoices(payload, {companyInfo});
        this.props.fetchPaidInvoicesCount(payload, {companyInfo});
    }

    render() {
        return <PaidInvoicesComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        paidInvoiceListCount: state.customer.paidInvoiceListCount,
        paidInvoiceList: state.customer.paidInvoiceList,
        modalLoading:state.common.modalLoading
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPaidInvoicesCount,
    fetchPaidInvoices,
    resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaidInvoices);