import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, INTEGRATION, FINANCE_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchTransactionMatch = (payload, props) => {
    let FY = getFinancialYear(props.companyInfo);
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/finance/getAgedDebtorsByRid?relationshipId=${(props.companyInfo || {}).relationshipId}}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}`)
            .then(res => {
              //  const { data } = res
                // dispatch({ type: REPORTS_ACTION_LIST.AGED_DEBTORS_LIST, data });
                // ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    // err.actionToCall = REPORTS_ACTION_LIST.AGED_DEBTORS_LIST;
                }
            })
    }
}

export const findAdvanceMatch = (payload) => {
    let FY = getFinancialYear(payload.companyInfo);
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
            .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/finance/getAgedDebtorsByRid?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 20}&pageNumber=${payload.pageNumber || 0}&startDate=${FY.fyStartDate}&endDate=${FY.fyEndDate}&customerIds=${payload.customerId || ''}&filterStartDate=&filterEndDate=`)
            .then(res => {
               // const { data } = res
               // dispatch({ type: REPORTS_ACTION_LIST.AGED_DEBTORS_LIST, data });
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                  //  err.actionToCall = REPORTS_ACTION_LIST.AGED_DEBTORS_LIST;
                }
            })
    }
}

export const generatePlaidLinkToken = (payload) =>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/create_link_token`)
        .then(res => {
          dispatch({
            type: INTEGRATION.PLAID_LINK_TOKEN,
            data: res.data
          });
           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        })
    }
  }
  
  
  export const setAccessToken = (payload, props) =>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/save`, payload)
        .then(res => {
           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
           props.getPlaidLinkedAccounts &&  props.getPlaidLinkedAccounts(payload);
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        })
    }
  }

  export const getPlaidLinkedAccounts = (payload) =>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/getLinkedAccounts?relationshipId=${payload.relationshipId}`)
        .then(res => {
           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
           dispatch({
            type: INTEGRATION.PLAID_LINKED_ACCOUNT,
            data: res.data
          });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = INTEGRATION.PLAID_LINKED_ACCOUNT;
          }
        })
    }
  }

  export const getBankTransactions = (payload) =>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      dispatch({ type: FINANCE_ACTION_LIST.RESET_BANK_TRANSACTIONS });
      return axios
        .get(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/getTransactions?relationshipId=${payload.relationshipId}&institutionId=${payload.institutionId}`)
        .then(res => {
           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
           dispatch({
            type: FINANCE_ACTION_LIST.BANK_TRANSACTIONS,
            data: res.data
          });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = FINANCE_ACTION_LIST.BANK_TRANSACTIONS;
          }
        })
    }
  }

  export const resetBankTransactions = () => {
    return dispatch => {
      dispatch({ type: FINANCE_ACTION_LIST.RESET_BANK_TRANSACTIONS });
    }
  }

  export const getMatchTransactionCount = (payload) =>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      dispatch({ type: FINANCE_ACTION_LIST.RESET_BANK_TRANSACTIONS });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankReco/countTransaction`, payload)
        .then(res => {
           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
           dispatch({
            type: FINANCE_ACTION_LIST.BANK_TRANSACTIONS,
            data: res.data
          });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = FINANCE_ACTION_LIST.BANK_TRANSACTIONS;
          }
        })
    }
  }

  export const getTransactionsInAlpide = (payload) =>{
    return dispatch => {
      //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
     // dispatch({ type: FINANCE_ACTION_LIST.RESET_TRANSACTION_IN_ALPIDE });
      return axios
        .get(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/getTransactionInAlpide?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          const data = {
            pageNo: payload.pageNumber,
            list: res.data
          }
           dispatch({
            type: FINANCE_ACTION_LIST.TRANSACTION_IN_ALPIDE,
            data: data
          });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = FINANCE_ACTION_LIST.TRANSACTION_IN_ALPIDE;
          }
        })
    }
  }

  export const getTransactionsCountInAlpide = (payload) =>{
    return dispatch => {
      return axios
        .get(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/countTransactionInAlpide?relationshipId=${payload.relationshipId}`)
        .then(res => {
           dispatch({
            type: FINANCE_ACTION_LIST.TRANSACTION_COUNT_IN_ALPIDE,
            data: res.data
          });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = FINANCE_ACTION_LIST.TRANSACTION_COUNT_IN_ALPIDE;
          }
        })
    }
  }

  export const resetTransactionsInAlpide = () => {
    return dispatch => {
      dispatch({ type: FINANCE_ACTION_LIST.RESET_TRANSACTION_IN_ALPIDE });
    }
  }

  export const deleteBank = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PLAID_BASE_URL}${config.rootContextPlaid}/plaid/delete`, payload)
        .then(res => {
           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
           getPlaidLinkedAccounts(payload)(dispatch);
        showToasterMessage({ messageType: 'success', description: lang['common.delete.success'] });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }
        })
    }
  }

  export const getAllBankStatementUploadDraft = (payload) =>{
    return dispatch => {
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankStatementUploadDraft/getAllBankStatementUploadDraftData?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          let list = res.data.map(obj => {
            let rObj = {...obj};
            rObj.statementData = JSON.parse(obj.statementData);
            return rObj;
         })
          const data = {
            pageNo: payload.pageNumber,
            list: list
          }
           dispatch({
            type: FINANCE_ACTION_LIST.STATEMENT_UPLOAD_DRAFT_LIST,
            data: data
          });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = FINANCE_ACTION_LIST.STATEMENT_UPLOAD_DRAFT_LIST;
          }
        })
    }
  }

  export const getStatementUploadDraftCount = (payload) =>{
    return dispatch => {
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankStatementUploadDraft/getBankStatementUploadDraftCount?relationshipId=${payload.relationshipId}`)
        .then(res => {
           dispatch({
            type: FINANCE_ACTION_LIST.STATEMENT_UPLOAD_DRAFT_COUNT,
            data: res.data
          });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = FINANCE_ACTION_LIST.STATEMENT_UPLOAD_DRAFT_COUNT;
          }
        })
    }
  }

  export const resetStatementUploadDraft = () => {
    return dispatch => {
      dispatch({ type: FINANCE_ACTION_LIST.RESET_STATEMENT_UPLOAD_DRAFT });
    }
  }

  export const deleteStatementUploadDraft = (payload) =>{
    delete payload.statementData;
    return dispatch => {
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankStatementUploadDraft/deleteBankStatementUploadDraft`, payload)
        .then(res => {
          getAllBankStatementUploadDraft(payload)(dispatch);
          getStatementUploadDraftCount(payload)(dispatch);
          showToasterMessage({ messageType: 'success', description: lang['common.delete.success'] });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } 
        })
    }
  }

  export const getBankStatementFromAuthrizedNet = (payload) =>{
    return dispatch => {
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankStatementUploadDraft/getAllBankStatementUploadDraftData?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          let list = res.data.map(obj => {
            let rObj = {...obj};
            rObj.statementData = JSON.parse(obj.statementData);
            return rObj;
         })
          const data = {
            pageNo: payload.pageNumber,
            list: list
          }
           dispatch({
            type: FINANCE_ACTION_LIST.STATEMENT_UPLOAD_DRAFT_LIST,
            data: data
          });
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = FINANCE_ACTION_LIST.STATEMENT_UPLOAD_DRAFT_LIST;
          }
        })
    }
  }


export const downloadStatementFromAuthorize = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PAYMENT_SERVER_BASE_URL}/alpide_authorise/transaction_list?relationshipId=${payload.relationshipId}&beginDate=${payload.startDate}&endDate=${payload.endDate}&alpidePaymentGatewayId=${payload.alpidePaymentGatewayId}`
      )
      .then(res => {
        let importRecords = [];

        if (res.data && res.data.transactionsList && res.data.transactionsList.length) {
          res.data.transactionsList.map((tx) => {
            if (tx.status !== 'declined') {
              return importRecords.push({
                date: tx.date,
                txDate: tx.date,
                description: tx.description,
                amount: (tx.status === 'settledSuccessfully' || tx.status === 'pendingSettlement') ? tx.amount : Number(-tx.amount),
                Deposit: (tx.status === 'settledSuccessfully' || tx.status === 'pendingSettlement') ? Number(tx.amount) : 0,
                Withdrawal: (tx.status === 'refundSettledSuccessfully' || tx.status === 'refundPendingSettlement') ? Number(tx.amount) : 0,
                deposit: (tx.status === 'settledSuccessfully' || tx.status === 'pendingSettlement') ? Number(tx.amount) : 0,
                withdrawal: (tx.status === 'refundSettledSuccessfully' || tx.status === 'refundPendingSettlement') ? Number(tx.amount) : 0,
                Project: tx.project,
                institutionName: 'Authorize.net',
                invoiceNumber: tx.invoiceNumber,
                transactionId: tx.transactionId,
                relationshipId: props.companyInfo.relationshipId,
                customerFullName: (tx.first_name || '') + (tx.first_name ? ' ': '')+ (tx.last_name || ''),
                subcriptionName: tx.subcription_name
              })
            } else {
              return importRecords;
            }
          });
          props.getMatchTransactionCount && props.getMatchTransactionCount(importRecords);
        } else {
          return showToasterMessage({ description: props.intl.formatMessage({ id: 'banking.transaction.not.found' }), messageType: 'error' });
        }
      })
      .catch(err => {
      })
  }
}

export const hideBankTransaction = (payload) =>{
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/hideBankTransaction/save`, payload)
      .then(res => {
        // getAllBankStatementUploadDraft(payload)(dispatch);
        // getStatementUploadDraftCount(payload)(dispatch);
        showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } 
      })
  }
}

export const getHiddenTransactions = (payload) =>{
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/hideBankTransaction/getHiddenTransaction?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
         dispatch({
          type: FINANCE_ACTION_LIST.HIDDEN_TRANSACTION_LIST,
          data: data
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = FINANCE_ACTION_LIST.HIDDEN_TRANSACTION_LIST;
        }
      })
  }
}

export const getHiddenTransactionsCount = (payload) =>{
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/hideBankTransaction/countHiddenTransaction?relationshipId=${payload.relationshipId}`)
      .then(res => {
         dispatch({
          type: FINANCE_ACTION_LIST.HIDDEN_TRANSACTION_COUNT,
          data: res.data
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = FINANCE_ACTION_LIST.HIDDEN_TRANSACTION_COUNT;
        }
      })
  }
}

export const resetHiddenTransactionList = () => {
  return dispatch => {
    dispatch({ type: FINANCE_ACTION_LIST.RESET_HIDDEN_TRANSACTION_LIST });
  }
}