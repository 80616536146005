import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UnpaidCustomersComponent from '../../../../../components/modal/modalBody/schoolManagement/UnpaidCustomers';
import { getUnpaidCustomersByFormId, getUnpaidCustomersCount, resetPaginatedData } from './action';

class UnpaidCustomers extends Component {

    constructor(props){
        super();
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            pageNumber:1
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 0, pageSize: 100,
            formId: this.props.defaultSchoolForm || this.props.studentFormList[0].registrationFormSettingId
        };
        this.props.getUnpaidCustomersByFormId(payload);
        this.props.getUnpaidCustomersCount(payload);
    }

    render() {
        return <div>
            <UnpaidCustomersComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        studentFormList: state.membershipReducer.studentFormList,
        defaultSchoolForm: state.common.defaultSchoolForm,
        unpaidCustomerList: state.studentRegistration.unpaidCustomers,
        unpaidCustomersCount: state.studentRegistration.unpaidCustomersCount,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getUnpaidCustomersByFormId, getUnpaidCustomersCount, resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnpaidCustomers);