import React from "react";
import { Card, Button } from 'antd';
import shipstationIcon from '../../../assets/images/shipstation-logo.png';
import "./index.scss";
import { FormattedMessage } from "react-intl";
import ShipStationIntegrateComponent from './shipStationIntegrate' ;

const ShippingComponent = (props) => {
  return (
      <div className="page-details-container text-center integ">
      <Card className="accounting-market-place text-center integs" title="" extra={<img src={shipstationIcon} alt="" style={{ height: "50px" }} />} style={{ width: 300 }}>
        {(props.linkedShipStation && props.linkedShipStation.alpideCarrierId)   ? <div class="cr cr-top cr-left cr-green">Integrated</div> : ''}
        <h2><FormattedMessage id='shipStation.logo' defaultMessage='' /></h2>
        <p><FormattedMessage id='shipStation.line1' defaultMessage='' /></p>
        <p><FormattedMessage id='shipStation.line2' defaultMessage='' /></p>
        <p><FormattedMessage id='shipStation.line3' defaultMessage='' /></p>
        <div className="border-footer mt10"></div>
        {(props.linkedShipStation && props.linkedShipStation.alpideCarrierId) ? <Button className="cnant-btn grey-button" onClick={
          () => {
             props.disconnectConfiguration(props.linkedShipStation, props);
          }
        }>Disconnect </Button> : <Button style={{ marginLeft: "0px" }} className="cnant-btn ant-btn-primary" onClick={() => {
          props.pushModalToStack({
            modalBody: <ShipStationIntegrateComponent {...props} />,
            title:<FormattedMessage id='shipStation.logo' defaultMessage='' />,
            width: '30%',
            hideFooter: true,
        })
        }}>Integrate</Button>}
      </Card>
    </div>
  )
};

export default ShippingComponent;