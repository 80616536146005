import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Drawer, Row,Skeleton } from "antd";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { Dropdown } from "../../../../general/Dropdown";


const TeamDrawer = (props) => {
  const closeDrawer = () => {
    props.updateState({
      teamDrawer: false,
    });
  };
  const handleSubmit = () => {
    props.updateDrawerState({ submitted: true });
    if(!props.teamName ||  !props.teamCode || !props.departmentId  || !props.warehouseMasterId){
      return;
    }
    const payload = {
      relationshipId: props.companyInfo?.relationshipId,
      teamId: props.teamId,
      teamName: props.teamName,
      teamCode: props.teamCode,
      departmentId: props.departmentId,
      warehouseMasterId: props.warehouseMasterId,
      createdByUserId: props.createdByUserId,
      updatedByUserId: props.updatedByUserId,
      version: props.version || 0

    }
    const modalData = {     
      modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage=""/>,
      handleSubmit: () => {
        props.createTeam(payload,props);
       closeDrawer();  },
    };
    props.pushModalToStack(modalData);
  }
  return (
    <Drawer
      title="Create New"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.teamDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit()
            }}
            key={1}
          />
        </>
      }
    
    >
      <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>
        <Row gutter={[16,16]}>

          <Col span={12}>
            <div className="i-label">
              Team Name<span className="required">*</span>
            </div>
            <TextBox
              placeholder="Team Name"
              type="text"
              maxLength={20}
              value={props.teamName}
              onChange={(e) => {
                props.updateDrawerState({ teamName: e.target.value });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.teamName }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>

          <Col span={12}>
            <div className="i-label">
              Team Code<span className="required">*</span>
            </div>
            <TextBox
              placeholder="Team Code"
              type="text"
              maxLength={20}
              value={props.teamCode}
              onChange={(e) => {
                props.updateDrawerState({ teamCode: e.target.value });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.teamCode }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>

          <Col span={12}>
            <div className="i-label">
              Department<span className="required">*</span>
            </div>
            <Dropdown
              items={props.departmentList[1] || []}
              valueKeyName="departmentName"
              optionKeyName="departmentId"
              placeholder="Department"
              value={props.departmentName}
              onSelect={(optionValue, option) => {
                props.updateDrawerState({
                  departmentId: option.value,
                  departmentName: option.name,
                });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.departmentId }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>

          <Col span={12}>
            <div className="i-label">
              Branch<span className="required">*</span>
            </div>
            <Dropdown
              items={props.branchList[1] || []}
              valueKeyName="warehouseName"
              optionKeyName="warehouseMasterId"
              placeholder="Branch"
              value={props.warehouseMasterId}
              onSelect={(optionValue, option) => {
                props.updateDrawerState({
                  warehouseMasterId: option.value,
                  warehouseName: option.name,
                });
              }}
            />
            <ErrorMsg
              validator={() => { return !props.submitted || props.warehouseMasterId }}
              message={<FormattedMessage id='requiredField.missing.msg' />}
            />
          </Col>
        
        </Row>
      </Skeleton>
    </Drawer>
  );
};

export default TeamDrawer;
