import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilePdfOutlined, MailOutlined} from '@ant-design/icons';
import {Row, Col, Button} from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS, TRANSACTION_TYPES } from '../../../../../static/constants';
// import config from '../../../../../config/environmentConfig';
// import { LogoComponent } from '../../../../general/LogoComponent';
import { getMomentDateForUIReadOnly } from '../../../../../utils';

const JournalDetails =  (props) =>{
    const { JournalDetailData, companyInfo } = props;
    const getTotalLedgerAmount = (type) => {
        let amount = 0;
        (JournalDetailData.journalEntryDetailsList || []).forEach(function (d) {
            if (d[type])
                amount = amount + d[type];
        });
        return amount;
    }

    const getPayloadForPdf = () => {
        return {
            fileName : 'Journal.pdf',
            transactionName: 'journal',
            JournalDetailData: JournalDetailData,
            companyInfo: companyInfo,
            totalDebit: getTotalLedgerAmount('debitAmount'),
            totalCredit: getTotalLedgerAmount('creditAmount'),
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired= true;
        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.JOURNAL,
        });
    };

    return (
        <div className={"custom-modal show"}>
        <i className="close-icon" onClick={() =>{props.popModalFromStack();}}>
                <svg width="17" height="17">
                    <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                          d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"/>
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="modal-header">
                    <FormattedMessage id='finance.JournalDetail.journal.label' defaultMessage='' />
                    </div>
                    <div className="inner-content">
                        <Row>
                            <Col span={16}>
                            {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                            {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()}/> */}
                                <div className="info">
                                    <div className="title">
                                        {props.companyInfo.storeName}
                                    </div>
                                    {/* {(relationshipAddress.streetAddress1 && relationshipAddress.streetAddress1+', ' ) || ''}
                                    {(relationshipAddress.streetAddress2 && relationshipAddress.streetAddress2 +', ') || ''}
                                    {(relationshipAddress.cityName && relationshipAddress.cityName+', ') || ''}
                                    {(relationshipAddress.stateName && relationshipAddress.stateName+', ') || ''}
                                    {(relationshipAddress.zipCode && relationshipAddress.zipCode+' ') || ''}
                                    <div>
                                        GSTIN - {companyInfo.gstNumber || ''}
                                    </div>
                                    <div>PAN - {companyInfo.panNumber || ''}</div> */}
                                </div>
                            </Col>



                            <Col span={8}>
                                <div className="info">
                                    <div>
                                        <span className="title"><FormattedMessage id='finance.JournalDetail.journalDate' defaultMessage='' /> - </span>
                                        {JournalDetailData.dateCreated && getMomentDateForUIReadOnly({date: JournalDetailData.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT})}
                                    </div>
                                    <div>
                                        <span className="title"><FormattedMessage id='finance.JournalDetail.journalNumber' defaultMessage='' /> - </span>
                                        {JournalDetailData.journalNumber}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="modal-table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th><FormattedMessage id='finance.JournalDetail.ledgerAccount' defaultMessage='' /></th>
                                    <th><FormattedMessage id='finance.JournalDetail.description' defaultMessage='' /></th>
                                    <th><FormattedMessage id='finance.JournalDetail.debit' defaultMessage='' /> ({companyInfo.currencyIcon ? <i className={companyInfo.currencyIcon}></i> : companyInfo.currencyCode + " "}) </th>
                                    <th><FormattedMessage id='finance.JournalDetail.credit' defaultMessage='' /> ({companyInfo.currencyIcon ? <i className={companyInfo.currencyIcon}></i> : companyInfo.currencyCode + " "}) </th>
                                </tr>
                                </thead>
                                <tbody>
                                    {(JournalDetailData.journalEntryDetailsList || []).map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{item.ledgerAccountName || '-'}</td>
                                                <td dangerouslySetInnerHTML={{
                                                    __html: item.description || '-'
                                                }}></td>
                                                <td>{item.debitAmount || '-'}</td>
                                                <td>{item.creditAmount || '-'}</td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Row>
                        <Col span={12}>
                                <div className="info">
                                    <div className="title"></div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="info">
                                    <div className="title"><FormattedMessage id='finance.JournalDetail.total' defaultMessage='' /></div>
                                </div>
                            </Col>
                            <Col span={3}>
                                <div className="info">
                                    <div className="title">{getTotalLedgerAmount('debitAmount')}</div>
                                </div>
                            </Col>
                            <Col span={3}>
                                <div className="info">
                                    <div className="title"> {getTotalLedgerAmount('creditAmount')}</div>
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col span={6}>
                                <div className="info">
                                    <div className="title"><FormattedMessage id='finance.JournalDetail.narration' defaultMessage='' /></div>
                                    {ReactHtmlParser(JournalDetailData.narration)}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="button-group-action">
                        <Button
                            type="primary"
                            icon={<MailOutlined />}
                            onClick={() => {
                                openEmailDrawer(props);

                            }}
                        >
                            <FormattedMessage id='email' defaultMessage='' />
                        </Button>

                        <Button
                            type="primary"
                            icon={<FilePdfOutlined />}
                            onClick={() => {
                                generatePDF();
                            }}
                        >
                            <FormattedMessage id='download' defaultMessage='' />
                            </Button>

                        <Button onClick={() => {
                            props.popModalFromStack();
                        }}
                            type="default"
                        >
                            <FormattedMessage id='close' defaultMessage='' />
                        </Button>
                    </div>
                </div>
            </div>
            </div>
    );

}

export default JournalDetails;
