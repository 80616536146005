import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RejectApprovalCompo from '../../../../../components/modal/modalBody/common/RejectApproval';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { rejectApproval } from './action';

class RejectApprovalContainer extends Component {

  render() {
    return (
      <RejectApprovalCompo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  rejectApproval,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RejectApprovalContainer);
