import React, { Fragment } from 'react';
import './../../../../assets/stylesheets/common/invoice.scss';
import CustomerHeaderAction from "./headerAction";
import CustomerInvoiceDetails from "./invoiceDetails";
import CustomerProductDetails from "./productDetails";
import { Divider } from 'antd';

const SalesInvoiceComponent = (props) => {
    // const breadCrumbList = [
    //     {
    //         name: 'Sales',
    //     },
    //     {
    //         name: 'Sales Invoice',
    //     }
    // ];

    return (
        <Fragment>
            <div className="txn-header">
                <CustomerHeaderAction {...props} />
            </div>

            <div className="txn-body">
                <div className="txn-details-container">
                    <CustomerInvoiceDetails paymentTerms={props.paymentTerms} {...props}/>
                </div>
                <Divider style={{ margin: "0 0 10px 0px" }} />
                <div className="product-details-container">
                    <CustomerProductDetails {...props} />
                </div>
            </div>
        </Fragment>
    )
};

export default SalesInvoiceComponent;
