import React from "react";
import { FormattedMessage } from "react-intl";
import { Empty, Skeleton } from "antd";
import { getMomentDateForUIReadOnly, getUserDefinedName } from "../../../../../../utils";


const Assignment = (props) => {
    const { assignmentList } = props;

    return (
      <div className="view-container">
        <Skeleton loading={props.isLoading}>
          <div className="table-container">
            <table id="class-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>{getUserDefinedName('Class', props)}</th>
                  <th>{getUserDefinedName("Subject", props)}</th>
                  <th>Started On</th>
                  <th>Due On</th>
                </tr>
              </thead>
              <tbody>
                {assignmentList && (assignmentList[1] || []) ? assignmentList[1].map((item, i)=>{
                  return <tr key={`assign`+i}>
                    <td>{i+1}</td>
                    <td>{item.assignmentName || '-'}</td>
                    <td>{item.className || '-'}</td>
                    <td>{item.className || '-'}</td>
                    <td>{item.subjectName || '-'}</td>
                    <td>{item.scheduling || '-'}</td>
                    <td className="line-view">{item.dueDate ? getMomentDateForUIReadOnly({date: item.dueDate}) : '-'}</td>
                  </tr>
                })
                :
                <tr key="empty-data">
                  <td colSpan={"100%"} style={{borderBottom:"none"}}>
                    <Empty />
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
      </div>
    );
}


export default Assignment;
