import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import 'material-ui-rc-color-picker/assets/index.css';

import CKEditor from "../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../static/ckEditorConfigConstants";

function TextProps(props) {
   // let divRef = document.getElementById(props.childId);
    
    return (
        <Fragment>
                    <div>
                    <Row className="but-buttons">
                        {/* <Col span={24}>
                            <FormattedMessage id='browse.text' /> <FormattedMessage id='banking.auditTrail.file' />
                        </Col> */}
                        <Col span={24}>
                        {<CKEditor
                        
                            type="inline"
                            className="description-textarea"
                            key={`${"remarksInternal"}`}
                            data={props.text}
                            onInit={editor => {
                              //  this.editor = editor;
                            }}
                            onChange={(event) => {
                                const data = event.editor.getData();
                                props.updateState({ remarksInternal: data });
                                document.getElementById(props.childId).innerHTML = data;
                            }}
                            config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter internal remarks', style: {width: '100%'} }}
                        />}
                        </Col>
                    </Row>
                </div>
        </Fragment>
    );
}


export default injectIntl(TextProps);
