import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Button } from 'antd';
import { MODAL_TYPE } from '../../../static/constants';
import { CustomButton } from '../../general/CustomButton';
import { CustomAgGrid } from '../../general/CustomAgGrid';
import { Dropdown } from '../../general/Dropdown';
import { UploadQuality } from '../../general/UploadQuality';

class QualityCheckDrawer extends React.Component {
  render() {
    const self = this;
    const packageObj = this.props.packageObj || {};
    const closeDrawer = () => {
      this.props.updateState({
        qualityCheckDrawerVisible: false
      })
    }
    const handleAddQualityCheckToProps = () => {
      const modalData = {
        modalBody: "Are you sure you want to save?",
        handleSubmit: () => {
          closeDrawer();
          let ckeckList = [];
          this.gridApi && this.gridApi.forEachNode((rowNode, index) => {
            const { data } = rowNode;
            ckeckList.push(data);
          });
          this.props.addQualityCheckToProps(ckeckList, this.props.qualityChecklistStatus || 'Not Strated');
        },
      };
      this.props.pushModalToStack(modalData); 
    }


    const addItemConfirmationModal = (props, text, modalType) => {
      const { showModal, companyInfo } = props;
      const labelName = 'Quality Check';
      const modalBody = <span>
        <FormattedMessage
          id='addItem.confirmation'
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
    </span>;

      let addFunc;

      switch (modalType) {
        case MODAL_TYPE.QUALITY_CHECK: {
          addFunc = props.addQualityChecklists;
          break;
        }
        default: {
          addFunc = () => { }
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          addFunc({ checklistName: text, relationshipId: companyInfo.relationshipId })
          props.hideModal();
        },
      };
      showModal(modalData);
    }

    const getModalTitle = (newTextObj, modalType) => {
      let title;

      switch (modalType) {
        case MODAL_TYPE.QUALITY_CHECK: {
          title = <FormattedMessage id='addItem.text.qualityCheck' defaultMessage='' />;
          break;
        }
        default:
          title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
      }
      return title;
    }

    const handleNewItemAddition = (props, payload, modalType) => {
      const formData = payload.formData;
      const { showModal } = props;

      if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
        addItemConfirmationModal(props, formData.textBoxValue, modalType);
      } else {
        const data = {
          title: getModalTitle(payload, modalType),
          formData,
          modalData: { modalType, data: payload },
          handleSubmit: (formData = {}) => {
            if (formData.textBoxValue && formData.textBoxValue.length) {
              addItemConfirmationModal(props, formData.textBoxValue, modalType);
            } else {
              data.formData.submittedOnce = true;
              showModal(data);
            }
          }
        };
        showModal(data);
      }
    }

    const deleteClickHandler = (props, modalType, payload) => {
      const { showModal, companyInfo } = props;
      const modalBody =
        <FormattedMessage
          id='deleteItem.confirmation'
          defaultMessage={``}
          values={{ value: payload.text }}
        />;

      let deleteFunc;

      switch (modalType) {
        case MODAL_TYPE.QUALITY_CHECK: {
          deleteFunc = props.deleteQualityChecklists;
          break;
        }
        default: {
          deleteFunc = () => { }
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId })
          props.hideModal();
        },
      };
      showModal(modalData);
    }

    // const format = CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT;

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const onFirstDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // }
    // const getRowHeight = (params) => {
    //     return 30;
    // }


    const columnDefs = [

      {

        headerName: 'Quality Check',
        resizable: true,
        field: 'checklistName',
        editable: true,
        sortable: true,
        cellEditor: 'customDropDownEditor',
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.allQualityChecksName,
            optionKeyName: 'qualityChecklistId',
            valueKeyName: 'checklistName',
            canAddNew: true,
            canDelete: true,
            deleteClickHandler: (payload) => {
              deleteClickHandler(this.props, MODAL_TYPE.QUALITY_CHECK, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false
              }
              handleNewItemAddition(this.props, payload, MODAL_TYPE.QUALITY_CHECK, obj)
            },
            onSelect: (selectedValue, option) => {
              obj.node.data.checklistName = selectedValue;
            },
          }
        },
        cellClass: 'variant-column',
      },
      {

        headerName: 'Status',
        resizable: true,
        field: 'status',
        editable: true,
        sortable: true,
        cellEditor: 'customDropDownEditor',
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: [{key:'not.started', name:'Not Started'}, {key:'in.progress', name:'In Progress'}, {key:'completed', name:'Completed'}, {key:'not.applicable', name:'Not Applicable'}],
            optionKeyName: 'key',
            valueKeyName: 'name',
            onSelect: (selectedValue, option) => {
              obj.node.data.status = option.key;
            },
          }
        },
        cellClass: 'variant-column',
      },
      {
        headerName: 'Document',
        field: 'document',
        sortable: true,
        resizable: true,
        cellRendererFramework: function (link) {
          setTimeout(() => {
            if (link.reactContainer && link.reactContainer.scrollHeight >= (link.node.data.maxHeight || 30)) {
              link.node.setRowHeight(link.reactContainer.scrollHeight);
              link.node.data.minHeight = link.reactContainer.scrollHeight > 30 ? link.reactContainer.scrollHeight : 30;
              self.gridApi && self.gridApi.onRowHeightChanged();
            } else {
              link.node.data.minHeight = link.reactContainer.scrollHeight > 30 ? link.reactContainer.scrollHeight : 30;
            }
          }, 500)
          return <div onClick={() => {
            self.props.showModal({
              title: <Fragment>
                <div className='ant-modal-title'>Upload Documents</div>
              </Fragment>,
              modalBody: <UploadQuality {...self.props} fileList={link.data.documentDetails} onChange={
                (documentDetails) => {
                  link.data.documentDetails = documentDetails;
                  link.data.fileList = documentDetails;
                  self.gridApi.refreshCells({ force: true });
                }
              } />,
              width: 600,
              hideFooter: true,
            })
          }}>
            <Link>{((link.data || {}).documentDetails || []).length ? ((link.data || {}).documentDetails || []).map((d, i) => {
              return <div>{(d.name || d.fileName)}</div>
            }) : "Upload Document"}</Link>
          </div>
        },
      },
      {
        headerName: 'Remarks',
        field: 'remarks',
        editable: true,
        sortable: true,
        resizable: true,
        cellEditor: 'customTextEditor',
        cellEditorParams: (obj) => {
          return {
            onChange: (value) => {
              obj.node.data.remarks = value;
            }
          }
        }
      },
      {
        pinned: 'right',
        cellRenderer: 'customActionEditor',
        suppressNavigable: true,
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
              let rowData = [];
              params.api.forEachNode(node => rowData.push(node.data));
              // updateDrawerState({ customerTaxIdentificationsList: rowData });
            },
          }
        },
        width: 70
      }
    ]

    const onGridReady = (params) => {
      this.gridApi = params.api;
      params.api.sizeColumnsToFit();
    }

    return (
      <Fragment>

        <Drawer
          title={
            <div className='drawer-head-txt'>
              {this.props.title}
            </div>
          }
          width={720}
          zIndex={1}
          className="custom-drawer"
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          onClose={closeDrawer}
          visible={this.props.qualityCheckDrawerVisible}
          footer={<>
           <CustomButton
              intlId='confirmation.cancel'
              defaultMessage=''
              type='default'
              key={0}
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />
            <CustomButton
              onClick={() => {
                handleAddQualityCheckToProps();
              }}
              type='primary'
              htmlType='submit'
              intlId='button.save.label'
              defaultMessage=''
            />
           </>}
         
        >

          <div>
            <CustomAgGrid
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              rowData={this.props.qualityCheckList}
              singleClickEdit={true}
              editType={'fullRow'}
              gridStyle={{
                width: '100%',
                height: '100%',
                marginBottom: '10px'
              }} />
            <Button className="grey-button" onClick={() => {
              this.gridApi.updateRowData({ add: [{packageMasterId: packageObj.soPackageMasterId, relationshipId: packageObj.relationshipId, customerId: packageObj.customerId}] });
            }}>
              <FormattedMessage id='customer.salesOrder.form.button.addLine' defaultMessage='' />
            </Button>
          </div>
          <br />
          <div>
            <span style={{ 'marginRight': '5px' }}>Quality Status : </span>
            <Dropdown
              style={{ width: '30%' }}
              items={[{ key: 'not.started', name: 'Not Started' }, { key: 'in.progress', name: 'In Progress' }, { key: 'completed', name: 'Completed' }]}
              //placeholder='Status'
              optionKeyName='key'
              valueKeyName='name'
              value={this.props.qualityChecklistStatus || 'Not Strated'}
              onSelect={(selectedValue, option) => {
                this.props.updateState({
                  qualityChecklistStatus: option.key
                })
              }}
            />

          </div>
          {/* <div className='form-footer'>
           
          </div> */}
        </Drawer>
      </Fragment>
    );
  }
}

export default injectIntl(QualityCheckDrawer);
