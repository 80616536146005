import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InquiryDetailComponent from '../../../../../components/modal/modalBody/customer/InquiryDetail';
import { fetchInquiryDetail, resetInquiryData, uploadStamp, deleteStamp, updatePRStatus, deleteDocument } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';
import { deleteSalesEnquiry, updateUserStatus, fetchSalesEnquiry } from '../../../../customer/salesEnquiry/Listing/action';
import { deleteAttectedFile } from '../../../../../actions/commonActions';

class InquiryDetail extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.inquiryPayload.customerId, inquiryMasterId: this.props.inquiryPayload.inquiryMasterId };
        this.props.resetInquiryData();
        fetchDataIfNeeded('fetchInquiryDetail', 'inquiryData', this.props, payload, true);

    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <InquiryDetailComponent {...this.props} 
            {...this.state} updateState={(data) => { this.setState(data) }}
             />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        inquiryData: state.enquiry.enquiryDetail,
        listLoading: state.common.listLoading,
        detailLoading: state.common.detailLoading

    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchInquiryDetail,
    resetInquiryData,
    downloadFileFromBucket,
    generatePDF, printPDF,
    uploadStamp, 
    deleteStamp,
    deleteSalesEnquiry,
    updateUserStatus,
    fetchSalesEnquiry,
    updatePRStatus,
    deleteAttectedFile,
    deleteDocument
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InquiryDetail);