import { CRM_ACTION_LIST } from '../static/constants';
const initialState = {
  crmLeadFormList: {
    1: []
  },
  crmLeadFormListCount: 0,
  allLeadList: {
    1: []
  },
  allLeadListCount: 0,
  crmLeadStatusList: [],
  crmLeadSourceList: [],
  crmLeadNotesList: [],
  crmLeadReminderList: [],
  leadActivityTrackList: [],
  leadTasksList: [],
  crmCampaignType: [],
  crmActiveSource: [],
  crmCampaignStatusList: [],
  crmCampaignList: {
    1: []
  },
  employeeByTeamName: [],
  currCallLogData: {},
  leadCallLogsList: {
    1: []
  },
  leadTaskListPage: [],
  leadWorkflowList: {
    1: []
  },
}

const CrmReducer = (state = initialState, action) => {
  switch (action.type) {
    case CRM_ACTION_LIST.CRM_FORM_LIST:
      return {
        ...state,
        crmLeadFormList: {
          ...state.crmLeadFormList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CRM_ACTION_LIST.CRM_FORM_LIST_COUNT:
      return { ...state, crmLeadFormListCount: action.data };

    case CRM_ACTION_LIST.ALL_LEAD_LIST:
      return {
        ...state,
        allLeadList: {
          ...state.allLeadList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CRM_ACTION_LIST.ALL_LEAD_LIST_COUNT:
      return { ...state, allLeadListCount: action.data };
    case CRM_ACTION_LIST.CRM_LEAD_SOURCE_LIST:
      return { ...state, crmLeadSourceList: action.data };
    case CRM_ACTION_LIST.CRM_LEAD_STATUS_LIST:
      return { ...state, crmLeadStatusList: action.data };
    case CRM_ACTION_LIST.CRM_LEAD_NOTES_LIST:
      return { ...state, crmLeadNotesList: action.data };
    case CRM_ACTION_LIST.CRM_LEAD_REMINDER_LIST:
      return { ...state, crmLeadReminderList: action.data };
    case CRM_ACTION_LIST.LEAD_ACTIVITY_TRACK_LIST:
      return { ...state, leadActivityTrackList: action.data };
    case CRM_ACTION_LIST.LEAD_TASK_LIST:
      return { ...state, leadTasksList: action.data };
    case CRM_ACTION_LIST.LEAD_CAMPAIGN_TYPE_LIST:
      return { ...state, crmCampaignType: action.data };
    case CRM_ACTION_LIST.LEAD_ACTIVE_SOURCE:
      return { ...state, crmActiveSource: action.data };
    case CRM_ACTION_LIST.LEAD_CAMPAIGN_STATUS:
      return { ...state, crmCampaignStatusList: action.data };
    case CRM_ACTION_LIST.LEAD_CAMPAIGN_LIST:
      return {
        ...state,
        crmCampaignList: {
          ...state.crmCampaignList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CRM_ACTION_LIST.LEAD_EMPLOYEE_BY_TEAMNAME:
      return { ...state, employeeByTeamName: action.data };
    case CRM_ACTION_LIST.LEAD_CALL_LOG:
      return { ...state, currCallLogData: action.data };

    case CRM_ACTION_LIST.RESET_LEAD_CALL_LOG:
      return { ...state, currCallLogData: initialState.currCallLogData };

    case CRM_ACTION_LIST.LEAD_CALL_LOGS_LIST:
      return {
        ...state,
        leadCallLogsList: {
          ...state.leadCallLogsList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
      case CRM_ACTION_LIST.LEAD_WORKFLOW:
        return {
          ...state,
          leadWorkflowList: {
            ...state.leadWorkflowList,
            [action.data.pageNo || 1]: (action.data.list || [])
          }
        };
    case CRM_ACTION_LIST.LEAD_TASK_LIST_PAGE:
      return { ...state, leadTaskListPage: action.data };
    default:
      return state;
  }
};

export default CrmReducer;