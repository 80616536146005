import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, Drawer, Row } from "antd";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
const EmployeeDrawerComp = (props) => {

  const closeDrawer = () => {
    props.updateState({
        employeeTypeDrawer: false,
    });
  };
  const handleSubmit = () => {
    props.updateDrawerState({ submitted: true });
    if(!props.employeeType ||  !props.empTypeCode){
      return;
    }
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      empTypeId: props.empTypeId,
      employeeType: props.employeeType,
      empTypeCode: props.empTypeCode,
      createdByUserId: props.createdByUserId,
      updatedByUserId: props.updatedByUserId,
      version: 0,
      status:1

    }
    
    const modalData = {     
      modalBody: <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage=""/>,
      handleSubmit: () => {
        props.createEmployeeType(payload, props);
       closeDrawer();  },
    };
    props.pushModalToStack(modalData);
  }
  return (
    <Drawer
      title="Create New"
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.employeeTypeDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
              handleSubmit()             
            }
            }
            key={1}
          />
        </>
      }
    
    >
      <Row gutter={[16,16]}>
        <Col span={12}>
          <div className="i-label">Employee Type Name
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Employee Type Name"
            type="text"
            maxLength={20}
            value={props.employeeType}
            onChange={(e) => {
              props.updateDrawerState({ employeeType: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.employeeType }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label">Employee Type Code
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Employee Type Code"
            type="text"
            maxLength={20}
            value={props.empTypeCode}
            onChange={(e) => {
              props.updateDrawerState({ empTypeCode: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.empTypeCode }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
     
      </Row>
    </Drawer>
  );
};

export default EmployeeDrawerComp;
