import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AlpideProductsDrawerComp from "../../../components/drawer/alpideProduct";
import { getAlpideProducts } from '../../PricingSetup/action';
import { getSubscribedProduct } from '../../B2B/PlanPricing/action';
import { resetSubcriptionPlanCost } from '../../B2B/PlanPricing/action';



class AlpideProductsDrawer extends Component {

  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentDidMount(){
    const payload={
      relationshipId: (this.props.companyInfo || {}).relationshipId
    }
    this.props.getAlpideProducts();
    this.props.getSubscribedProduct(payload);
  }


  render() {
    return <AlpideProductsDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    alpideProducts: state.common.alpideProducts,
    listLoading: state.common.listLoading,
    subscribedProduct: state.common.subscribedProduct
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getAlpideProducts,
    getSubscribedProduct,resetSubcriptionPlanCost
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(AlpideProductsDrawer);
