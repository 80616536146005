import React from 'react';
import HeaderAction from './headerAction'
import './../../../../assets/stylesheets/common/invoice.scss';
import InvoiceDetails from "./invoiceDetails";
import ProductDetails from "./productDetails";
import { Divider } from 'antd';
const PurchaseInvoiceComponent = (props) => {

  return (
    <>
      <div className="txn-header">
        <HeaderAction {...props} />
      </div>

      <div className='txn-body'>
        <div className="txn-details-container">
          <InvoiceDetails {...props} />
        </div>

        <Divider style={{margin: '0 0 10px 0px'}}/>

        <div className="product-details-container extra-pd">
          <ProductDetails {...props} />
        </div>
      </div>
    </>
  );
}


export default PurchaseInvoiceComponent;
