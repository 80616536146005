import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Pagination, Tooltip, Checkbox, Skeleton ,Menu} from "antd";
import {
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,
  showToasterMessage,
  exportExcel,
} from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
// import adminAccess from "../../../assets/images/school/admin-access.png";
import { CONSTANTS } from "../../../static/constants";
// import checkIcon from "../../../assets/images/check.png";
// import crossIcon from "../../../assets/images/cross.png";
import * as find from "lodash.find";
import {
  DownOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileExcelOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";


const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const FinancialReport = (props) => {
  const {
    selectedItems = [],
    financialReportList,
    updateState,
    companyInfo,
    financialReportCount,
    pageNumber,
    pageSize,
    dir = 0,
  } = props;

  // const relationshipEmployee = companyInfo.relationshipEmployees || {};
  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const breadCrumbList = [
    {
      name: (
        <FormattedMessage
          id="sidebar.menuItem.schoolManagement"
          defaultMessage=""
        />
      ),
    },
    {
      name: <FormattedMessage id="financialReport.text" defaultMessage="" />,
    },
  ];
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "getFinancialReport",
      "financialReportList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getFinancialReport(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  function sortTable(table, col, reverse) {
    var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
      tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
      i;
    reverse = -(+reverse || -1);
    tr = tr.sort(function (a, b) {
      // sort rows
      return (
        reverse * // `-1 *` if want opposite order
        a.cells[col].textContent
          .trim() // using `.textContent.trim()` for test
          .localeCompare(b.cells[col].textContent.trim())
      );
    });
    for (i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
  }
  const sortColumn = (e) => {
    //sortTable(document.getElementById('invoiceTable'), e.target.parentElement.cellIndex, 1 - dir);  let cellIndex = e.target.cellIndex;
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(
      document.getElementById("financialReportTable"),
      cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      orgRegistrationId: Number(item.orgRegistrationId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.orgRegistrationId !== item.orgRegistrationId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < financialReportList[pageNumber].length; i++) {
      let item = financialReportList[pageNumber][i];
      let selectItem = find(selectedItems, {
        orgRegistrationId: Number(item.orgRegistrationId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (
      !props.financialReportList ||
      !props.financialReportList[pageNumber] ||
      props.financialReportList[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < props.financialReportList[pageNumber].length; i++) {
      let usr = props.financialReportList[pageNumber][i];
      let selectItem = find(selectedItems, {
        orgRegistrationId: Number(usr.orgRegistrationId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const getFatherInfo = (e, email) => {
    return (
      <Fragment>
        {email ? <div>Email-{email}</div> : ""}
        <div>Member-{e.isOrgMember ? "Yes" : "No"}</div>
        <div>Volunteer-{e.isVolunteer ? "Yes" : "No"}</div>
        <div>Teacher-{e.isTeacher ? "Yes" : "No"}</div>
        <div>
          Fin. Assistance-{e.isFinancialAssistanceNeeded ? "Yes" : "No"}
        </div>
        {/* <div>Member-{e.isOrgMember ? <span style={{background: '#ffffff', padding: ' 0px 3px 5px 3px'}}><img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> </span>: <span style={{background: '#ffffff', padding: ' 0px 3px 5px 3px'}}> <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" /></span>}</div>
      <div>Volunteer-{e.isVolunteer ? <span style={{background: '#ffffff', padding: ' 0px 3px 5px 3px'}}><img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> </span>: <span style={{background: '#ffffff', padding: ' 0px 3px 5px 3px'}}> <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" /></span>}</div>
      <div>Teacher-{e.isTeacher ? <span style={{background: '#ffffff', padding: ' 0px 3px 5px 3px'}}><img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" />  </span>: <span style={{background: '#ffffff', padding: ' 0px 3px 5px 3px'}}> <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" /></span>}</div>
      <div>Fin. Assistance-{e.isFinancialAssistanceNeeded ? <span style={{background: '#ffffff', padding: ' 0px 3px 5px 3px'}}><img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" />  </span>: <span style={{background: '#ffffff', padding: ' 0px 3px 5px 3px'}}> <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" /></span>}</div> */}
      </Fragment>
    );
  };
  const exportExcelData = (studentList) => {
    let dataList = [];
    (studentList || []).map((e, i) => {
      let rowData = {
        "#": i + 1,
        Gender: e.gender || "",
        Status: e.studentStatus || "",
        "Father Name":
          (e.fatherFirstName || "") +
          (e.fatherLastName ? " " + e.fatherLastName : ""),
        "Father Phone": e.fatherMobile || "",
        "Father Email": e.fatherEmail || "",
        "Mother Name":
          (e.motherFirstName || "") +
          (e.motherLastName ? " " + e.motherLastName : ""),
        "Mother Phone": e.motherMobile || "",
        "Mother Email": e.motherEmail || "",

        "Total Fees": Number(e.totalFeeswholeYearForForm || 0).toFixed(2),
        "Amt Received": Number(e.totalPaymentReceivedForForm || 0).toFixed(2),
        "Total Bal": Number(
          e.totalFeeswholeYearForForm - e.totalPaymentReceivedForForm
        ).toFixed(2),
        "Invoice Raised": Number(
          e.totalInvoiceRaisedAmountForForm || 0
        ).toFixed(2),
        "Invoice Due": Number(
          e.totalInvoiceRaisedAmountForForm - e.totalPaymentReceivedForForm
        ).toFixed(2),

        "Date Registered": e.dateCreated
          ? getMomentDateForUIReadOnly({
              date: new Date(e.dateCreated),
              format,
            })
          : "",
        Member: e.isOrgMember ? "Yes" : "No",
        Volunteer: e.isVolunteer ? "Yes" : "No",
        Teacher: e.isTeacher ? "Yes" : "No",
        "Financial Assistance": e.isFinancialAssistanceNeeded ? "Yes" : "No",
      };

      dataList.push(rowData);
      return "";
    });
    exportExcel(dataList, "Financial Report");
  };
  const downloadMenu = () =>{
    return(

    <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            if (props.selectedItems && props.selectedItems.length) {
              exportExcelData(props.selectedItems, props);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <FileExcelOutlined />
          Excel File
        </Menu.Item>
        </Menu>
    )
  }
  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <CheckCircleOutlined />
          Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <CloseCircleOutlined />
          Unselect all
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            const customerList = [];
            selectedItems.forEach((obj) => {
              customerList.push({
                schoolStudentId: obj.schoolStudentId,
                relationshipId: obj.relationshipId,
                fatherEmail: obj.fatherEmail,
                motherEmail: obj.motherEmail,
                orgRegistrationId: obj.orgRegistrationId,
                customerId: obj.customerId,
                contactId: obj.contactId || 0,
                fatherLastName: obj.fatherLastName,
                fatherFirstName: obj.fatherFirstName,
                motherFirstName: obj.motherFirstName,
                motherLastName: obj.motherLastName,
              });
            });
            if (customerList.length) {
              props.updateState({
                emailDetailDrawerVisible: true,
                isSendBulkInvoice: true,
                isBulkInvoice: true,
                selectedCustomerList: customerList,
                docName: "paymentReminder",
              });
              // props.pushModalToStack(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <CloseCircleOutlined />
          Send Email
        </Menu.Item>
       
        </Menu>
        );
        };

  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id="financialReport.text" defaultMessage="" />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        {/* <div className="top-view-right"></div> */}
      </div>

      <div className="view-container">
        <Skeleton
          loading={props.isSoListingLoading}
          active
          paragraph={{ rows: 12 }}
        >
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id="financialReport.text" defaultMessage="" />
              </div>
              <div className="vertical-sep" />
              {/* <PageBreadcrumb breadCrumbList={breadCrumbList} /> */}
              <div>{pageSize}</div>
            </div>
            <div className="right-actions">
                <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
                  <span>
                    <DownloadOutlined /> &nbsp;{" "}
                    <FormattedMessage
                      id="common.action.dropdown.downloadAs"
                      defaultMessage=""
                    />
                  </span>
                </DropdownAction>
     
            </div>
          </div>
          {/* <button className="create-action-btn" onClick={()=>{
                        // props.history.push({
                        //     pathname: "/admin/so-create",
                        //     state: {
                        //         type: "new"
                        //     }
                        // })
                        // openNewSO();

                    }}>
                        <PlusOutlined /> <FormattedMessage id='button.create.label' defaultMessage='' />
                    </button> */}

          {/* <div className="page-heading">
          <FormattedMessage id='heading.schoolManagement.financialReports' defaultMessage='' />
      </div>
      <PageBreadcrumb breadCrumbList={breadCrumbList} />
      <div className="pb10" /> */}

          {/* <div className="view-container"> */}
          {/* <div className="table-container">
        
          
        </div> */}
          {/* <div className="pb5" /> */}
          <div className="table-container">
            <table id="financialReportTable">
              <thead>
                <tr>
                  <th>
                    <div className="check-cell">
                      <Checkbox
                        onChange={(e) => {
                          selectAll(e.target.checked);
                        }}
                        checked={isCheckedAll()}
                      />
                      <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                        <DownOutlined />
                      </DropdownAction>
                    </div>
                  </th>
                  <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                  {/* {(!relationshipEmployee.jobRoleName || relationshipEmployee.jobRoleName === 'School-Admin' || relationshipEmployee.jobRoleName === 'Super-Admin') ? <th>Portal</th>: ''} */}
                  <th onClick={sortColumn}>
                    <span className="curs">
                      {" "}
                      Father Info{" "}
                      <i className="fa fa-sort" onClick={sortColumn}></i>
                    </span>
                  </th>
                  <th onClick={sortColumn}>
                    <span className="curs">
                      Mother Info{" "}
                      <i className="fa fa-sort" onClick={sortColumn}></i>
                    </span>
                  </th>
                  <th onClick={sortColumn}>
                    <span className="curs">
                      Total Fees{" "}
                      <i className="fa fa-sort" onClick={sortColumn}></i>
                    </span>
                  </th>
                  <th onClick={sortColumn}>
                    <span className="curs">
                      Amt Received{" "}
                      <i className="fa fa-sort" onClick={sortColumn}></i>
                    </span>
                  </th>
                  <th onClick={sortColumn}>
                    <span className="curs">
                      Total Bal{" "}
                      <i className="fa fa-sort" onClick={sortColumn}></i>
                    </span>
                  </th>
                  <th onClick={sortColumn}>
                    <span className="curs">
                      Invoice Raised{" "}
                      <i className="fa fa-sort" onClick={sortColumn}></i>
                    </span>
                  </th>
                  <th onClick={sortColumn}>
                    <span className="curs">
                      Invoice Due{" "}
                      <i className="fa fa-sort" onClick={sortColumn}></i>
                    </span>
                  </th>
                  {/* <th>Org Association</th> */}
                  {/* <th>Financial Assistance Needed</th> */}
                  {/* <th>Payment Plan</th> */}
                  <th onClick={sortColumn}>
                    <span className="curs">
                      Date Registered <i className="fa fa-sort"></i>
                    </span>
                  </th>
                  {/* <th><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> */}
                </tr>
              </thead>
              <tbody>
                {(financialReportList[pageNumber] || []).map((e, i) => {
                  return (
                    <tr key={"usr-" + i}>
                      <td>
                        <Checkbox
                          onClick={() => {
                            itemSelection(e);
                          }}
                          checked={
                            find(selectedItems, {
                              orgRegistrationId: Number(e.orgRegistrationId),
                            })
                              ? true
                              : false
                          }
                        />
                      </td>
                      <td>{i + 1}</td>
                      {/* {(!relationshipEmployee.jobRoleName || relationshipEmployee.jobRoleName === 'School-Admin' || relationshipEmployee.jobRoleName === 'Super-Admin') ? <td><img src={adminAccess} style={{ 'height': '25px', 'width': '25px' }} className="cursor-pointer" alt="Access Account" onClick={() => { accessPortal(e); }} /></td> : ''} */}
                      <td>
                        <Tooltip
                          placement="right"
                          title={getFatherInfo(e, e.fatherEmail)}
                        >
                          <div>{`${e.fatherFirstName || ""}${
                            e.fatherLastName ? " " + e.fatherLastName : ""
                          }`}</div>
                          <div>{e.fatherMobile}</div>
                          {/* <div>{e.fatherEmail}</div> */}
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          placement="right"
                          title={getFatherInfo(e, e.motherEmail)}
                        >
                          <div>{`${e.motherFirstName || ""}${
                            e.motherLastName ? " " + e.motherLastName : ""
                          }`}</div>
                          <div>{e.motherMobile}</div>
                          {/* <div>{e.motherEmail}</div> */}
                        </Tooltip>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <div>
                          {Number(e.totalFeeswholeYearForForm || 0).toFixed(2)}
                        </div>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <div>
                          {Number(e.totalPaymentReceivedForForm || 0).toFixed(
                            2
                          )}
                        </div>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <div>
                          {Number(
                            (e.totalFeeswholeYearForForm || 0) -
                              (e.totalPaymentReceivedForForm || 0)
                          ).toFixed(2)}
                        </div>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <div>
                          {Number(
                            e.totalInvoiceRaisedAmountForForm || 0
                          ).toFixed(2)}
                        </div>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <div>
                          {Number(
                            (e.totalInvoiceRaisedAmountForForm || 0) -
                              (e.totalPaymentReceivedForForm || 0)
                          ).toFixed(2)}
                        </div>
                      </td>
                      {/* <td>
                    <div>Member-{e.isOrgMember ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}</div>
                    <div>Volunteer-{e.isVolunteer ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}</div>
                    <div>Teacher-{e.isTeacher ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}</div>
                    <div>Fin. Assistance-{e.isFinancialAssistanceNeeded ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}</div>
                    </td> */}
                      {/* <td><div>{e.selectedPlan}</div></td> */}
                      <td>
                        {e.dateCreated
                          ? getMomentDateForUIReadOnly({
                              date: new Date(e.dateCreated),
                              format,
                            })
                          : ""}
                      </td>
                      {/* <td>
                      <span className="cursor-pointer" style={{ marginLeft: '10px' }} onClick={() => { props.sendResetMail(e.email, props.history); }}>Resend Password</span> 
                  </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* </div> */}
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={financialReportCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(FinancialReport);
