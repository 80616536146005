import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Form, Select, InputNumber,Input, Tooltip } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS, } from '../../../static/constants';
import { TextBox } from '../../general/TextBox';
import { validateEmail, showToasterMessage } from '../../../utils';
//import * as find from 'lodash.find';
// import moment from 'moment-timezone';
// import { ErrorMsg } from '../../general/ErrorMessage';
import config from '../../../config/environmentConfig';
import { LogoComponent } from '../../general/LogoComponent';

const { Option } = Select;
const { TextArea } = Input;
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const getAddressInfoFragment = (obj, additionInfo) => {

    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <span className='billing-address'>{obj.streetAddress1}
                </span>
                : ''
        }
        {
            obj.streetAddress2
                ? <span className='billing-address'> {obj.streetAddress2}
                </span>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {
            obj.countryName
                ? <div className='billing-address'>
                    {
                        `${obj.countryName || ''}`
                    }
                </div>
                : ''
        }
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}


function RSVPComp(props) {
    return (
        <div className='dark-inssp' >
             <Row gutter={24} style={{ background: props.onlineThemes.bgColor || '#06033A', color: props.onlineThemes.textColor || '#FFFFFF', padding: '5px', marginLeft:'0px', marginRight:'0px',  marginBottom:'15px' }} className='ltiuy'>
             <Col span={2} className='litlyu'></Col>
                       <Col span={2}>
                           <div className="brand-logo" style={{marginTop:'10px'}}>
                               {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                           </div>
                       </Col>
                       <Col span={12} style={{ alignSelf: 'center' }}>
                           <div className='logosizef'> {props.companyInfo.storeName}</div>
                       </Col>
                       <Col span={8} style={{ alignSelf: 'center' }} className='newaddr'>
                           {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)}
                       </Col>
            </Row>
            {!props.isSubmitRSVPSuccess ?
                <div className="header-action-container pay-ment-box">

                    <div>

                        <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px' }}>
                            <h3> <b> RSVP to {(props.newsLetterTemplate || {}).templateName} </b></h3>
                        </div>

                        <div className='pay-text'>
                            <Form>
                                <div className="information pading-rii"  >
                                    <div className=" ">

                                        <Row className="pb10">
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='signup.firstname.placeholder' /><span className="required">*</span>
                                            </Col>
                                            <Col span={14} className="input-currency-control">
                                                <Input style={{ width: '100%' }} value={props.firstName} placeholder="" onChange={(e) => {
                                                    props.updateState({ firstName: e.target.value });
                                                }}
                                                    className={(props.submittedOnce && !props.firstName) ? 'input-text-error' : ''}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pb10">
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='signup.lastname.placeholder' />
                                            </Col>
                                            <Col span={14} className="input-currency-control">
                                                <Input style={{ width: '100%' }} value={props.lastName} placeholder="" onChange={(e) => {
                                                    props.updateState({ lastName: e.target.value })
                                                }} />
                                            </Col>
                                        </Row>
                                        <Row className="pb10">
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='email' /><span className="required">*</span>
                                            </Col>
                                            <Col span={14} className="input-currency-control">
                                                <Input style={{ width: '100%' }} value={props.emailAddress} placeholder="" onChange={(e) => {
                                                    props.updateState({ emailAddress: e.target.value })
                                                }}
                                                    className={props.invalidEmailClass}
                                                    validator={(val) => { return validateEmail(val); }}
                                                />
                                                {/* <ErrorMsg
                                                validator={() => { return !props.submittedOnce || !!props.emailAddress; }}
                                                message={props.intl.formatMessage(
                                                  {
                                                      id: 'contact.email.message',
                                                      defaultMessage: '.'
                                                  })
                                              }
                                              /> */}
                                            </Col>
                                        </Row>

                                        <Row className="pb10">
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='signup.phoneNumber.text' />
                                                <Tooltip placement="top" title={props.intl.formatMessage({ id: 'phoneNumber.incorrect.message' })} trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>
                                            </Col>
                                            <Col span={14} className="input-currency-control ">
                                                <span className="currency-tag" style={{lineHeight:'30px' }}>
                                                    {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                                </span>
                                                <TextBox className="pl45" style={{ width: '80%' }} maxLength={10} value={props.phoneNumber} placeholder="" onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        props.updateState({
                                                            phoneNumber: e.target.value,
                                                        });
                                                    }
                                                    // props.updateState({ phoneNumber : e.target.value })
                                                }} />
                                            </Col>
                                        </Row>

                                        <Row className="pb10">
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='attendingStatus.text' />
                                            </Col>
                                            <Col span={14} className="input-currency-control">
                                                <Select onChange={(value) => {
                                                    props.updateState({
                                                        attendingStatus: value,
                                                    })
                                                }}
                                                    className={(props.submittedOnce && !props.attendingStatus) ? 'input-text-error' : ''}
                                                    style={{ width: '100%' }}
                                                    placeholder="Please select"
                                                    optionFilterProp="children"
                                                    value={props.period}>
                                                    <Option value="attending"><FormattedMessage id='attending.text' /></Option>
                                                    <Option value="tentative"><FormattedMessage id='tentative.text' /></Option>
                                                    <Option value="declined"><FormattedMessage id='declined.text' /></Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        {props.attendingStatus === 'attending' && <div>
                                            <Row className="pb10">
                                                <Col span={10} className="title text-right">
                                                    <FormattedMessage id='numberOfAdult.text' /><span className="required">*</span>
                                                </Col>
                                                <Col span={14} className="input-currency-control">
                                                    <InputNumber style={{ width: '100%', }} value={props.numberOfAdult} placeholder="" onChange={(val) => {
                                                        props.updateState({ numberOfAdult: val })
                                                    }}
                                                        className={props.invalidNumberOfAdultClass}
                                                        validator={(val) => { return (props.numberOfAdult !== 0 && !props.numberOfAdult); }}
                                                    />
                                                    {/* <ErrorMsg
                                                validator={() => { return !props.submittedOnce || !!props.emailAddress; }}
                                                message={props.intl.formatMessage(
                                                  {
                                                      id: 'contact.email.message',
                                                      defaultMessage: '.'
                                                  })
                                              }
                                              /> */}
                                                </Col>
                                            </Row>
                                            <Row className="pb10">
                                                <Col span={10} className="title text-right">
                                                    <FormattedMessage id='numberOfChild.text' />
                                                </Col>
                                                <Col span={14} className="input-currency-control">
                                                    <InputNumber style={{ width: '100%' }} value={props.numberOfChild} placeholder="" onChange={(val) => {
                                                        props.updateState({ numberOfChild: val })
                                                    }}
                                                    />
                                                    {/* <ErrorMsg
                                                validator={() => { return !props.submittedOnce || !!props.numberOfChild; }}
                                                message={props.intl.formatMessage(
                                                  {
                                                      id: 'contact.email.message',
                                                      defaultMessage: '.'
                                                  })
                                              }
                                              /> */}
                                                </Col>
                                            </Row>
                                        </div>
                                        }
                                        {(props.attendingStatus && props.attendingStatus !== 'attending') &&
                                            <Row className="pb10">
                                                <Col span={10} className="title text-right">
                                                    <FormattedMessage id='notes.text' />
                                                </Col>
                                                <Col span={14} className="input-currency-control">
                                                    <TextArea
                                                        className='martm'
                                                        placeholder="Enter Remarks"
                                                        value={props.remarks}
                                                        onChange={(e) => {
                                                            const { value } = e.target;
                                                            props.updateState({
                                                                remarks: value,
                                                            });
                                                        }}
                                                        autoSize={{ minRows: 1, maxRows: 4 }}
                                                    ></TextArea>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                </div>
                            </Form>
                            <button className='pay-but3' onClick={() => {
                                props.updateState({
                                    submittedOnce: true,
                                    invalidEmailClass: !validateEmail(props.emailAddress) ? 'input-text-error' : '',
                                    invalidNumberOfAdultClass: (props.attendingStatus === 'attending' && (props.numberOfAdult !== 0 && !props.numberOfAdult)) ? 'input-text-error' : ''

                                });
                                if (!props.firstName || !props.emailAddress || !props.attendingStatus || (props.attendingStatus === 'attending' && !props.numberOfAdult)) {
                                    return showToasterMessage({
                                        messageType: 'error', description: props.intl.formatMessage({ id: 'common.missing.requiredField.message' })
                                    });;
                                }
                                if (props.phoneNumber && props.phoneNumber.length > 0 && props.phoneNumber.length < 10) {
                                    return showToasterMessage({
                                        messageType: 'error', description: props.intl.formatMessage({ id: 'phoneNumber.incorrect.message' })
                                    });;
                                }
                                let payload = {
                                    relationshipId: props.relationshipId,
                                    alpideFormBuilderId: props.alpideFormBuilderId,
                                    firstName: props.firstName,
                                    lastName: props.lastName,
                                    email: props.emailAddress,
                                    phoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? '+' + props.companyInfo.countryCallingCode + props.phoneNumber : props.phoneNumber,
                                    adultAttending: props.attendingStatus === 'attending' ? props.numberOfAdult || 0 : 0,
                                    childAttending: props.attendingStatus === 'attending' ? props.numberOfChild || 0 : 0,
                                    remarks: props.remarks,
                                    isAttending: props.attendingStatus === 'attending' ? 1 : 0,
                                    isTentative: props.attendingStatus === 'tentative' ? 1 : 0,
                                    isDeclined: props.attendingStatus === 'declined' ? 1 : 0,
                                }
                                props.saveOrUpdate(payload, props)
                            }}>Submit</button>
                        </div>
                    </div>

                </div>
                :
                <div className="header-action-container pay-ment-box-status" style={{ padding: '50px 0px 100px' }}>
                     <div>
                        {/* <div style={{ fontSize: '16px', textAlign: 'center' }}><b>{props.txStatusMessage || props.intl.formatMessage({ id: 'payOnline.success' })} </b></div> */}
                        <div className="prl30">
                            <br />

                            <div style={{}}>
                                <div className='confirm-boxs1'>
                                    <div className='done-popup'>
                                        <div className='ant-modal-title' style={{ textAlign: 'center', fontSize: '20px', color: '#fff', fontWeight: '300' }}>
                                            {/* <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg> */}

                                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                            </svg>
                                            {/* Payment Complete! */}
                                            {/* <b>Thank you for your pledge. Your pledge has been submitted successfully</b> */}
                                            {/* Thank you for Your payment has been processed successfully */}
                                            <div style={{ fontSize: '20px', textAlign: 'center' }}>
                                            <b>Thank you for RSVP. Your RSVP has been submitted successfully</b>
                                                </div>
                                        </div>

                                    </div>
                                    <div style={{ padding: '50px 0px', textAlign: 'center', fontSize: '16px' }}>


                                        <div style={{ fontSize: '14px', textAlign: 'center' }}><b>  A confirmation email has been sent to your email you have proivded on the RSVP form. </b></div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            }
            {/* =================Confirmanation page=========== */}

            <div style={{clear:'both'}}></div>
            <div className='pay-fot'>
                <Row gutter={24} className='pay-footer-box'>
                    <Col span={9}><span style={{ float: 'left', paddingTop: '5px' }}>{props.onlineThemes.footerMessage || 'All Rights Reserved - ' + props.companyInfo.storeName} </span> </Col>
                    <Col span={6} style={{ textAlign: 'center', color: '#333' }}>
                        {props.onlineThemes.facebookLink ? <i className="fa fa-facebook" style={{ margin: '0px 4px 0px 5px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.facebookLink) { return '' }
                                let url = props.onlineThemes.facebookLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.instagramLink ? <i className="fa fa-instagram" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.instagramLink) { return '' }
                                let url = props.onlineThemes.instagramLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.youtubeLink ? <i className="fa fa-youtube-play" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.youtubeLink) { return '' }
                                let url = props.onlineThemes.youtubeLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.twitterLink ? <i className="fa fa-twitter" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.twitterLink) { return '' }
                                let url = props.onlineThemes.twitterLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.link ? <i className="fa fa-link" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.link) { return '' }
                                let url = props.onlineThemes.link;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                    </Col>
                    <Col span={9}>

                        <span style={{ float: 'right', paddingTop: '5px' }}>Powered by <a href="http://alifapp.com/" target={'_blank'} >alifapp.com</a></span>
                    </Col>
                </Row>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div className='clearfix'></div>
        </div>
    );

}

export default injectIntl(RSVPComp);
