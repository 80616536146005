import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
// import { AgGridReact } from "ag-grid-react";
import { Skeleton, Pagination, Tooltip,Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  fixedDecimalAndFormateNumber,
  getMomentDateForUIReadOnly,
} from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
// import { AG_GRID_CLASS_CONSTANTS } from "../../../../static/cssClassConstants";
import { CONSTANTS } from "../../../../static/constants";
import StandardSalesInvoice from "../../../../containers/customer/invoice/StandardSalesInvoice";
// import find from "lodash.find";
// import { FilterOutlined, UndoOutlined, InsertRowRightOutlined } from '@ant-design/icons';
// import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'



const salesQuoteDraft = (props) => {
  const {
    salesInvoiceDraftList,
    updateState,
    companyInfo,
    salesInvoiceDraftCount,
    pageNumber,
  } = props;
  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchSalesInvoiceDrafts",
      "salesInvoiceDraftList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetDraftPaginatedData();
    props.fetchSalesInvoiceDrafts(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  //const permittedModules = getActionMenuPermissionData();
  // const columnDefs = [
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesOrder.listing.header.draftNo"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "invoiceDraftId",

  //     cellRendererFramework: (param) => (
  //       <div className="cursor-pointer">
  //         <Link
  //           to={{
  //             pathname: "/admin/sales-invoice",
  //             state: {
  //               customerId: param.data.customerId,
  //               invoiceData: param.data.invoiceData,
  //               invoiceDraftId: param.data.invoiceDraftId,
  //               clone: true,
  //             },
  //           }}
  //         >
  //           Draft-{param.value}
  //         </Link>
  //       </div>
  //     ),
  //     rowDrag: true,
  //     resizable: true,
  //     width: 100,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesOrder.listing.header.customer"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "customerName",
  //     resizable: true,
  //     width: 100,
  //     cellRendererFramework: (link) => (
  //       <div className="cursor-pointer">
  //         <Link
  //           to={{
  //             pathname: "customers/details",
  //             state: {
  //               customerId: link.data.invoiceData.customerId,
  //               customerName: link.data.invoiceData.customerName,
  //             },
  //           }}
  //           className="company-name"
  //         >
  //           {link.data.invoiceData.customerName}
  //         </Link>
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage
  //             id="customer.salesOrder.listing.header.amount"
  //             default="Amount"
  //           />{" "}
  //         </div>
  //       );
  //     },
  //     cellRendererFramework: (link) => {
  //       return link.data.invoiceData.invoiceTotalAmount &&
  //         link.data.invoiceData.invoiceTotalAmount !== "-" ? (
  //         <span>
  //           {" "}
  //           {link.data.invoiceData.foreignCurrencyIcon ? (
  //             <i className={link.data.invoiceData.foreignCurrencyIcon}></i>
  //           ) : link.data.invoiceData.foreignCurrency ? (
  //             <span>{link.data.invoiceData.foreignCurrency}</span>
  //           ) : props.companyInfo.currencyIcon ? (
  //             <i className={props.companyInfo.currencyIcon}></i>
  //           ) : (
  //             props.companyInfo.currencyCode + " "
  //           )}
  //           {link.data.invoiceData.invoiceTotalAmount
  //             .toFixed(2)
  //             .toString()
  //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
  //         </span>
  //       ) : (
  //         "-"
  //       );
  //     },
  //     field: "invoiceTotalAmount",
  //     resizable: true,
  //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //     width: 100,
  //   },

  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="customer.salesOrder.listing.header.date"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "invoiceDate",
  //     cellRendererFramework: (link) => {
  //       return (
  //         <div>
  //           <Tooltip
  //             placement="topLeft"
  //             title={
  //               link.data.invoiceData.invoiceDate &&
  //               getMomentDateForUIReadOnly({
  //                 date: link.data.invoiceData.invoiceDate,
  //                 format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
  //               })
  //             }
  //           >
  //             {link.data.invoiceData.invoiceDate &&
  //               getMomentDateForUIReadOnly({
  //                 date: link.data.invoiceData.invoiceDate,
  //                 format: CONSTANTS.DISPLAY_DATE_FORMAT,
  //               })}
  //           </Tooltip>
  //         </div>
  //       );
  //     },
  //     resizable: true,
  //     width: 100,
  //   },
  // ];

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  // const onGridReady = (params) => {
  //   props.updateState({
  //     gridApi: params.api,
  //     gridColumnApi: params.columnApi,
  //   });
  //   params.api.sizeColumnsToFit();
  // };

  return (
    <Fragment>
     
        {/* <div className="ag-theme-balham" style={agGridStyle} >
                            {props.txColumns.length ? <AgGridReact
                                onGridReady={onGridReady}
                                onColumnResized={(params) => {
                                    params.api.resetRowHeights();
                                }}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                columnDefs={columnDefs}
                                rowData={salesInvoiceDraftList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                suppressDragLeaveHidesColumns={true}
                                animateRows={true}
                            //getRowHeight={getRowHeight}
                            >
                            </AgGridReact> : null}
                        </div> */}

       
            <div className="view-container-actions">
            <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id={props.isSchoolUser ? 'heading.fees.feesInvoiceList':"heading.sales.salesInvoice"}
                defaultMessage=""
              />
            </div>
            {salesInvoiceDraftCount ? (
              <>
                <div className="vertical-sep" />
                <div>{salesInvoiceDraftCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          </Skeleton>
          </div>
          <Skeleton loading={props.listLoading} paragraph={{ rows: 16 }} active>

          <div div className="table-container">
          <table id="sales-order-table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.draftNo"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.customer"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.amount"
                    defaultMessage=""
                  />
                </th>

                <th>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.date"
                    defaultMessage=""
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {salesInvoiceDraftList && salesInvoiceDraftList[pageNumber]
                ? salesInvoiceDraftList[pageNumber].map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <div className="cursor-pointer">
                            <div 
                            onClick={()=>{
                              props.pushModalToStack({
                                modalBody: <StandardSalesInvoice {...props}
                                customerId= {item.customerId}
                                     invoiceData= {item.invoiceData}
                                 invoiceDraftId= {item.invoiceDraftId}
                                     clone= {true}
                                
                                />,
                                width: '100%',
                                hideTitle: true,
                                hideFooter: true,
                                wrapClassName: 'new-transaction-wrapper'
                            })
                            }}
                              // to={{
                              //   pathname: "/admin/sales-invoice",
                              //   state: {
                              //     customerId: item.customerId,
                              //     invoiceData: item.invoiceData,
                              //     invoiceDraftId: item.invoiceDraftId,
                              //     clone: true,
                              //   },
                              // }}
                            >
                              Draft-{item.invoiceDraftId}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="cursor-pointer">
                            <div
                             onClick={() => {
                              props.pushModalToStack({  
                        
                                modalBody: <CustomerProfile {...props}
                                customerId= {item.invoiceData.customerId}
                              //   customerName={rowData.customerName}
                              //   customerDetails={rowData}
                            />,
                            width: '100%',
                            hideTitle: true,  
                            hideFooter: true,
                            wrapClassName: "new-transaction-wrapper",
                            })
                            }}
                              // to={{
                              //   pathname: "customers/details",
                              //   state: {
                              //     customerId: item.invoiceData.customerId,
                              //     customerName: item.invoiceData.customerName,
                              //   },
                              // }}
                              className="company-name"
                            >
                              {item.invoiceData.customerName}
                            </div>
                          </div>
                        </td>

                        <td>
                          {/* {item.invoiceData.invoiceTotalAmount && item.invoiceData.invoiceTotalAmount !== '-' ? <span> {item.invoiceData.foreignCurrencyIcon ? <i className={item.invoiceData.foreignCurrencyIcon}></i> : (item.invoiceData.foreignCurrency ? <span>{item.invoiceData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{item.invoiceData.invoiceTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"} */}
                          {item.invoiceData.invoiceTotalAmount &&
                          item.invoiceData.invoiceTotalAmount !== "-" ? (
                            <span>
                              {" "}
                              {item.invoiceData.foreignCurrencyIcon ? (
                                <i
                                  className={
                                    item.invoiceData.foreignCurrencyIcon
                                  }
                                ></i>
                              ) : item.invoiceData.foreignCurrency ? (
                                <span>{item.invoiceData.foreignCurrency}</span>
                              ) : props.companyInfo.currencyIcon ? (
                                <i
                                  className={props.companyInfo.currencyIcon}
                                ></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}
                              {fixedDecimalAndFormateNumber(
                                item.invoiceData.invoiceTotalAmount
                              )}{" "}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>
                          <div>
                            <Tooltip
                              placement="toRight"
                              title={
                                item.invoiceData.invoiceDate &&
                                getMomentDateForUIReadOnly({
                                  date: item.invoiceData.invoiceDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {item.invoiceData.invoiceDate &&
                                getMomentDateForUIReadOnly({
                                  date: item.invoiceData.invoiceDate,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                })}
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                :  <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>}
            </tbody>
          </table>
        </div>
      </Skeleton>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={salesInvoiceDraftCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(salesQuoteDraft);
