import React, {  useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import {Col,Row, Switch,Input, Popover} from "antd";
import "@ant-design/compatible/assets/index.css";
import "./index.scss";
import { Dropdown } from "../../../general/Dropdown";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { EMAIL_BODY_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";


const EmailDetails = (props) => {

  const transactionMode = [
    { trnMode: "Option 1", referCode: "OPT1" },
    { trnMode: "Option 2", referCode: "OPT2" },
    { trnMode: "Option 3", referCode: "OPT3" },
  ];
  
  const onChange = (checked) => {
    console.log(`Switch changed to ${checked}`);
  };

  return (

    <>
      <Row gutter={[16, 8]}>
              <Col span={24} className="i-label">
                <FormattedMessage
                  id="drawer.labelName.communicationTemplate.emailProvider"
                  defaultMessage=""
                />
                <span className="required">*</span>
                
              </Col>
              <Col span={24}>
                <Dropdown
                      items={transactionMode || []}
                      valueKeyName="trnMode"
                      optionKeyName="referCode"
                      value={(props.transactionMode || {}).trnMode}
                      placeholder={"Select email service provider"}
                      // onSelect={(referCode) => {
                      //   const doc =
                      //     find(transactionMode, { referCode: referCode }) || {};
                      //   props.updateState({
                      //     transactionMode: doc,
                      //   });
                      // }}
                    />
                  {/* <ErrorMsg
                    message={"Transaction Mode is required"}
                  /> */}
              </Col>
      </Row>
      <Row gutter={[16, 8]}>
              <Col span={24} className="i-label">
                <FormattedMessage
                  id="drawer.labelName.communicationTemplate.emailId"
                  defaultMessage=""
                />
                <span className="required">*</span>
                
              </Col>
              <Col span={24}>
                <Dropdown
                      items={transactionMode || []}
                      valueKeyName="trnMode"
                      optionKeyName="referCode"
                      value={(props.transactionMode || {}).trnMode}
                      placeholder={"no-reply@alpide-email.com"}
                      // onSelect={(referCode) => {
                      //   const doc =
                      //     find(transactionMode, { referCode: referCode }) || {};
                      //   props.updateState({
                      //     transactionMode: doc,
                      //   });
                      // }}
                    />
                  {/* <ErrorMsg
                    message={"Transaction Mode is required"}
                  /> */}
              </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24} className="i-label">
          <FormattedMessage
            id="modal.settings.emailTemplate.emailSubject"
            defaultMessage=""
          />
          <span className="required">*</span>
          
        </Col>
        <Col span={24}>
                <Input type='text' style={{width:'85%', height:'34px'}}
                                     //value={subject}
                                    onClick={(e) => {
                                        // updateState({
                                        //     subjectCursor: e.target.selectionStart,
                                        //     isPopoverVisible: false, isSubjectPopoverVisible: false
                                        // }); 
                                    }}
                                     onChange={(e) => {
                                        //  updateState({
                                        //      subject: e.target.value,
                                        //      subjectCursor: e.target.selectionStart,
                                        //      isPopoverVisible: false, isSubjectPopoverVisible: false
                                        //  });
                                     }}
                            /> 
                             <span className="placeholder-button">
                                            <Popover 
                                            // content={getSubjectPlaceHolders(props, 'editorSubject')} 
                                            placement="bottom" trigger="click" onClick={{}}>
                                                Placeholder <i className='fa fa-caret-square-o-down' />
                                            </Popover>
                                        </span> 
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24} className="i-label">
            <FormattedMessage
              id="modal.settings.emailTemplate.emailMessage"
              defaultMessage=""
            />
            <span className="required">*</span>
            
        </Col>
        <Col span={24}>
          <div style={{ position:'relative'}}>
                             <span className="placeholder-button" style={{ position:'absolute', right:'20px', top:'25px'}}>
                                            <Popover  
                                            //content={getPlaceHolders(props, 'editor')} 
                                            placement="bottom" trigger="click" 
                                            onClick={{}}>
                                                Placeholder <i className='fa fa-caret-square-o-down' />
                                            </Popover>
                                        </span> 
                                {
                                    <CKEditor
                                        //  editor={ClassicEditor}
                                        //  config={CUSTOM_CONFIGURATION}
                                        type='replace'
                                        // onInit={editor => {
                                        //     updateState({
                                        //         editor: editor
                                        //     });
                                        // }}
                                        // data={content}
                                        key={`${"content"}`}
                                        // onChange={(event, editor) => {
                                        //     const data = event.editor.getData();
                                        //     updateState({ content: data, isPopoverVisible: false, isSubjectPopoverVisible: false });
                                        // }}
                                         config={EMAIL_BODY_CONFIGURATION}
                                    />
                                }
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <span>
            Automate trigger email when clicking on the &#123;Module_Name&#125; Submit Button.
          </span>  
        </Col>
        <Col span={24}>
          <Switch className="customAutomateSwitch" checkedChildren="Automate" unCheckedChildren="Manual" onChange={onChange} />
        </Col>
      </Row>
    </>
  );
};

export default injectIntl(EmailDetails);
