import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Select } from 'antd';
import { CustomButton } from '../../../../general/CustomButton';
import { ErrorMsg } from '../../../../general/ErrorMessage';

import { TextBox } from '../../../../general/TextBox';
import filter from 'lodash.filter';
import { validateEmail } from "../../../../../utils";
import * as find from 'lodash.find';
import { CUSTOM_CONFIGURATION } from '../../../../../static/ckEditorConfigConstants';
import CKEditor from '../../../../general/CustomCKEditor/ckeditor';
import ReactHtmlParser from 'react-html-parser';

function BrodcastEmailDrawer(props) {

  const { dgList, dgNameOptionList, dgIds, updateState, relationshipId, to, charCount, totalCost,
    totalSelectedRecipients, selectedDGIds, selectedContacts, invalidContacts } = props;

  const closeDrawer = () => {
    props.updateHeaderState({
      communication: {
        emailDrawerVisible: false
      },
      emailDrawerVisible: false
    })
  }

  const validateBrodcastToList = () => {
    let temp = []
    for (let i = 0; i < selectedContacts.length; i++) {
      let d = selectedContacts[i];
      // const regex = /^((\+1))[0-9]{10}$/;
      if (!validateEmail(d)) {
        temp.push(d);
      }
    }

    if (temp.length > 0) {
      updateState({ 'invalidContacts': temp });
      updateState({ 'invalidTo': true });
      return false;
    } else {
      return true;
    }

  }

  const handleSubmit = (props) => {
    let viewOnline = '';
    if (props.templateContent) {
      viewOnline = "<div style='text-align: center;'> <a target='_blank' href=" + props.previewOnlineUrl + "><button style='background:inherit; border:0px; color:#000; cursor: pointer;'>View Online</button></a></div>";
    }

    if (props.to.length > 0 && (props.message || props.templateContent) && props.subject && validateBrodcastToList()) {
      const payload = {
        dgIdList: selectedDGIds,
        emailList: selectedContacts,
        relationshipId: relationshipId,
        subject: props.subject,
        message: viewOnline + (props.message || '') + (props.templateContent ? props.templateContent : ''),
        isSendFromSubaccount: 1,
        cost: props.totalCost
      };

      props.pushModalToStack({
        modalBody: <FormattedMessage id='communication.account.popup.brodcast.confimation.msg' defaultMessage='' />,
        handleSubmit: () => {
          props.brodcastTextEmail(payload, props);
        }
      });

    } else {
      updateState({
        submittedOnce: true,
      })
    }
  };

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage id='communication.account.brodcastEmail.drawer.drawerName' defaultMessage=' ' />
            {/* <div className="top-box-icon1"> <i className="fa fa-envelope" ></i></div> */}
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-supplier-drawer bottpad"
        visible={props.emailDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
      >
        <Form  layout="vertical" hideRequiredMark >

          {/* <Row gutter={16}>
            
            <Col span={5}>
              <label>
                <FormattedMessage id='communication.account.brodcastSMS.drawer.form.from' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={17}>
              <TextBox
                // minLength={15}
                // maxLength={45}
                type='text'
                // className={(props.submittedOnce && !props.name) ? 'input-text-error' : ''}
                value={subAccount.subaccountPhoneNumber}
                disabled={true}
                placeholder={props.intl.formatMessage({ id: 'communication.account.brodcastSMS.drawer.form.from', defaultMessage: '' })}
              />

            </Col>
          </Row>
          <br /> */}
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <span className="">
                <FormattedMessage id='communication.account.brodcastSMS.drawer.form.to' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>

          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24} >
              <Select  className='send-smss'
                tokenSeparators={[',']}
                bordered={true}

                onDeselect={
                  (value) => {
                    updateState({ 'invalidTo': false });

                    //update to list
                    let to = Object.assign([], props.to);
                    to = filter(to, (obj) => {
                      return obj !== value;
                    });
                    updateState({ to });

                    //update invalid contact list
                    let invalidContacts = Object.assign([], props.invalidContacts);
                    invalidContacts = filter(invalidContacts, (obj) => {
                      return obj !== value;
                    });
                    updateState({ invalidContacts });

                    //update selectedContacts/totalCost/totalSelectedRecipients
                    if (dgIds.indexOf(value) < 0) {
                      let selectedContacts = Object.assign([], props.selectedContacts);
                      selectedContacts = filter(selectedContacts, (obj) => {
                        return obj !== value;
                      });
                      updateState({ selectedContacts });

                      updateState({ 'totalCost': totalCost - 0.001 });
                      updateState({ 'totalSelectedRecipients': totalSelectedRecipients - 1 });
                    } else {
                      let dg = find(dgList, { distributionGroupId: Number(value) });
                      updateState({ 'totalCost': totalCost - (dg.totalContacts * 0.001) });
                      updateState({ 'totalSelectedRecipients': totalSelectedRecipients - dg.totalContacts });

                      let selectedDGIds = Object.assign([], props.selectedDGIds);
                      selectedDGIds = filter(selectedDGIds, (obj) => {
                        return obj !== value;
                      });
                      updateState({ selectedDGIds });
                    }
                  }
                }

                onSelect={(selectedValue) => {
                  updateState({ 'invalidTo': false });

                  //update to list
                  setTimeout(function () {
                    const to = Object.assign([], props.to);
                    to.push(selectedValue);
                    updateState({ to });
                  }, 100);

                  //update selectedContacts/totalCost/totalSelectedRecipients
                  if (dgIds.indexOf(selectedValue) < 0) {
                    const selectedContacts = Object.assign([], props.selectedContacts);
                    selectedContacts.push(selectedValue);
                    updateState({ selectedContacts });

                    updateState({ 'totalCost': totalCost + 0.001 });
                    updateState({ 'totalSelectedRecipients': totalSelectedRecipients + 1 });
                  } else {
                    let dg = find(dgList, { distributionGroupId: Number(selectedValue) });
                    updateState({ 'totalCost': totalCost + (dg.totalContacts *  0.001) });
                    updateState({ 'totalSelectedRecipients': totalSelectedRecipients + dg.totalContacts });

                    const selectedDGIds = Object.assign([], props.selectedDGIds);
                    selectedDGIds.push(selectedValue);
                    updateState({ selectedDGIds });
                  }
                }}

                filterOption={(input, option) => {
                  if (!option.props.children) return;
                  return (
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0);
                }}

                mode="tags"
                defaultValue={to}
              >
                {dgNameOptionList}
              </Select>

              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.to.length > 0 }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />

              {invalidContacts.length > 0 ?
                <div>
                  <span>
                    Invalid Recipients - {invalidContacts.map((contact, i) => {
                      return <span key={i}>{contact}{(i + 1) < invalidContacts.length ? ', ' : ''}</span>
                    })}
                  </span>
                </div>
                : ''
              }

              {to.length > 0 && !props.invalidTo && invalidContacts.length === 0 ?
                <div>
                  <span>
                    {totalSelectedRecipients} Recipients
                  </span>
                </div>
                : ''
              }

            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <label>
                <FormattedMessage id='subject.text' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextBox
                style={{ width: '100%', padding: '0px 5px 5px 5px' }}
                maxLength={ 100 }
                className={(props.submittedOnce && !props.subject) ? 'input-text-error' : ''}
                value={props.subject}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'modal.settings.emailTemplate.emailSubject',
                    defaultMessage: ''
                  })
                }
                onChange={(e) => {
                  props.updateState({
                    subject: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          {!props.templateContent ?<>
            <Row gutter={[16, 4]}>
              <Col span={12}>
                <label>
                  <FormattedMessage id='communication.account.brodcastSMS.drawer.form.message' defaultMessage='' /></label>
                <span className="required">*</span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <CKEditor
                  // editor={ClassicEditor}
                  // config={FULL_CONFIGURATION}
                  key={`${"message"}`}
                  data={props.message}

                  onChange={(event) => {
                    const data = event.editor.getData();
                    props.updateState({ message: data });
                  }}
                  // onAttachmentClick={() => {
                  //   this.uploadButtonRef && this.uploadButtonRef.current
                  //     && ReactDOM.findDOMNode(this.uploadButtonRef.current).click()
                  // }}
                  config={CUSTOM_CONFIGURATION}
                />


                <div style={{ 'float': 'right', 'padding': '0.1rem 0 0 0', 'fontSize': '0.875rem' }}>
                  {props.isMessageFocus ? <span>
                    Characters
                    <span id="current"> {charCount}</span>
                    <span id="maximum"> / {props.brodcastSMSType === 'textSMS' ? 160 : 450} &nbsp; </span>
                    {props.to.length > 0 && !props.invalidTo ? <span> | &nbsp; </span> : ''}
                  </span> : ''
                  }
                  {props.to.length > 0 && !props.invalidTo ?
                    <span>
                      {/* | &nbsp;  Cost ${totalCost.toFixed(4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                      Cost ${totalCost.toFixed(4)}
                    </span>
                    : ''
                  }
                </div>


                <ErrorMsg
                  validator={() => { return !props.submittedOnce || props.message }}
                  message={props.intl.formatMessage(
                    {
                      id: 'requiredField.missing.msg',
                      defaultMessage: ''
                    })
                  } />
              </Col>

            </Row>
            </>
            : <Row gutter={24} className="emailNews-cont">
              {ReactHtmlParser(props.templateContent)}
              <div style={{clear:'both'}}></div>
            </Row>
          }
        {/* <div style={{clear:'both'}}></div> */}
        </Form>

        <div className='form-footer'>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.sendNow'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </div>
      </Drawer>
    </Fragment>
  );

}

export default injectIntl(BrodcastEmailDrawer);
