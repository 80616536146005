import React from 'react';
import Logo from '../../../../../assets/images/new-alpide-logo.svg';
import { Row, Col, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';

function CreatePaySlip(props) {
    return <>
        <div className='main-section'>
            <div className='header-section'>
                <div className='font-head'>January 2024</div>
                <img src={Logo} />
            </div>
            <div style={{ padding: "20px" }}>

                <div className='grey-bold'>Employee Id : 1224</div>
                <div className='font-head'>Amit Kumar</div>
                <Row>
                    <Col span={6}>
                        <Row>
                            <Col span={10} className='dark-text'>
                                Designation:
                            </Col>
                            <Col span={14}>
                                Software Engineer
                            </Col>

                            <Col span={10} className='dark-text'>
                                Department:
                            </Col>
                            <Col span={14}>
                                Technology
                            </Col>

                            <Col span={10} className='dark-text'>
                                Date of Joinning:
                            </Col>
                            <Col span={14}>
                                10-09-2022
                            </Col>

                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={10} className='dark-text'>
                                Working Days:
                            </Col>
                            <Col span={14}>
                                28
                            </Col>
                            <Col span={10} className='dark-text'>
                                Absence:
                            </Col>
                            <Col span={14}>
                                1
                            </Col>
                            <Col span={10} className='dark-text'>
                                Leave:
                            </Col>
                            <Col span={14}>
                                1
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={10} className='dark-text'>
                                PF No:
                            </Col>
                            <Col span={14}>
                                234348
                            </Col>

                            <Col span={10} className='dark-text'>
                                ESI NO.
                            </Col>
                            <Col span={14}>
                                33434
                            </Col>


                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <div className='col-content'>

                                <div className='dark-text'>Alpide</div>
                                <div>Sector 13, Noida, Gurgaon, Delhi, IN - 8756</div>
                                <div><span className='dark-text'>Phone:</span>   9429877966</div>
                            </div>
                        </Row>
                    </Col>

                </Row>
                <Divider />
                <div style={{ marginTop: "20px" }}>

                    <Row gutter={16}>
                        <Col span={12} >
                            <div className='font-head'>Salary Payable</div>
                            <div className='common-type'>
                                <div>Basic Salary</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div>HRA</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div>Medical Allowance</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div>Conveyance Allowance</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div>Special  Allowance</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div>PF - Employer Contribution</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div>Others</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div  className='dark-text'>Total Earning</div>
                                <div>15,000</div>
                            </div>
                        </Col>
                        <Col span={12} style={{paddingLeft:"106px"}}>

                            <div className='font-head'>Deduction</div>
                            <div className='common-type'>
                                <div>Tax Deduction</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div>Employee Contribution</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div>ESI/ Health Insurance</div>
                                <div>15,000</div>
                            </div>
                            <div className='common-type'>
                                <div>Loan Deduction</div>
                                <div>15,000</div>
                            </div>
                            
                            <div className='common-type'>
                                <div  className='dark-text'>Total Deduction</div>
                                <div>15,000</div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Divider/>
                <div style={{width:"100%",display:"flex",justifyContent:"end"}}>

                <div style={{display:"flex",gap:"402px"}}>
                    <div className='dark-text' >Net Salary Payable</div>
                    <div>12,000</div>
                </div>
                </div>
            </div>

        </div >



    </>
}
export default CreatePaySlip;