import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as find from "lodash.find";
import { fetchDataIfNeeded } from "../../../../../utils";
import { fetchAllLedgerAccounts } from "../../../../finance/LedgerAccounts/action";
import CompanyInfoComponent from "../../../../../components/modal/modalBody/settings/CompanyInfo";

import {
  fetchSalutation,
  addSalutation,
  deleteSalutation,
  fetchCountries,
  fetchStates,
  fetchIndustries,
  fetchAllEmployees,
  fetchTaxIdentifications,
  addTaxIdentification,
  deleteTaxIdentifications,
  fetchRelationshipTaxIdentifications,
  addRelationshipTaxIdentification,
  deleteRelationshipTaxIdentifications,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  getAllPaymentGatewayConfig,
} from "../../../../../actions/commonActions";
import {
  updateBusinessData,updateDefaultLedger,
  updateOrCreateEmployee,
  deleteEmployee,
  updateBoLocation,
  deleteLocation,
  createCostCenter,
  deleteCostCenter,
  createBankDetail,
  deleteBankDetail,
  getRelationshipAddress,
  resetRelationshipAddress,
  getSubdomain,
  saveClientURL,
  checkClientURLExist,
  resetCheckClientURLExist,
  saveFromEmail,
  saveEducationLabelInfo,
} from "./action";
import {
  LEDGER_ACTION_LIST,
  LEDGER_TYPE,
} from "../../../../../static/constants";


class CompanyInfo extends Component {
  constructor(props) {
    const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, {  txType: LEDGER_ACTION_LIST.SALES, }) || {};
    const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE, }) || {};
    const defaultSalesOutputLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX, }) || {};
    const defaultSalesDiscountLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.SALES_DISCOUNT, }) || {};
    const defaultDebtorsLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEBTORS, }) || {};
    const defaultPurchaseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASES, }) || {};
    const defaultTaxLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_TAX,}) || {};
    const defaultDiscountLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.DISCOUNT_RECIEVED,}) || {};
    const defaultSupplierExpenseLedger =find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.SUPPLIER_EXPENSE,}) || {};
    const defaultCreditorLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, {txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CREDITORS, }) || {};
    const defaultCustomerPayment =find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TX_PAYMENT_RECIEVED,}) || {};
    const defaultSupplierPayment =find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TX_PAYMENT_MADE, }) || {};
    const defaultRoundingOff = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TYPE_ROUNDING_OFF, }) || {};
    const defaultCoupon =  find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TYPE_COUPON,}) || {};

    super(props);
    this.state = {
      companyInfoData: {
        ...props.companyInfo,
         onlineThemes: JSON.parse(props.companyInfo.onlineThemes || "{}"),
      //  onlineThemes: props.companyInfo.onlineThemes || "{}",
        roundingOffSettings: JSON.parse(props.companyInfo.roundingOffSettings || "{}"),
        couponSetting: JSON.parse(props.companyInfo.couponSetting || "{}")
      },
      defaultSalesLedger,
      defaultDebtorsLedger,
      defaultExpenseLedger,
      defaultSalesOutputLedger,
      defaultSalesDiscountLedger,
      defaultPurchaseLedger,
      defaultTaxLedger,
      defaultDiscountLedger,
      defaultSupplierExpenseLedger,
      defaultCreditorLedger,
      defaultCustomerPayment,
      defaultSupplierPayment,defaultCoupon,defaultRoundingOff,
      countryCallingCode: props.companyInfo.countryCallingCode

    };
  }

  componentWillMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId };
    fetchDataIfNeeded("fetchIndustries", "industries", this.props, payload);
    fetchDataIfNeeded(
      "fetchAllLedgerAccounts",
      "allLedgerAccounts",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchSalutation", "salutations", this.props, payload);
    fetchDataIfNeeded("fetchCountries", "countries", this.props, payload);
    fetchDataIfNeeded(
      "getRelationshipAddress",
      "boRelationshipAddress",
      this.props,
      payload
    );
    this.props.getAllPaymentGatewayConfig(payload);
    this.props.getSubdomain(payload);
    this.props.resetCheckClientURLExist();
  }

  componentWillReceiveProps(props) {
    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      this.setState({
        companyInfoData: {
          ...this.state.companyInfoData,
          boLocationList: props.boRelationshipAddress,
        },
      });
      props.resetRelationshipAddress();
    }
    if (
      props.subdomain &&
      props.subdomain.alpideSubdomainId &&
      !this.state.subdomainName
    ) {
      this.setState({
        subdomainName: props.subdomain.subdomainName,
      });
    }
  }

  render() {
    return (
      <div>
        <CompanyInfoComponent
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    industries: state.common.industries,
    salutations: state.common.salutations,
    countries: state.common.countries,
    allEmployee: state.common.employees,
    states: state.common.states,
    boRelationshipAddress: state.common.boRelationshipAddress,
    allTaxIdentificatinType: state.common.allTaxIdentificatinType,
    allRelationshipTaxIdentificatins:
      state.common.allRelationshipTaxIdentificatins,
    allPaymentGatewayConfig: state.common.allPaymentGatewayConfig,
    subdomain: state.common.subdomain,
    isSubdomainExist: state.common.isSubdomainExist,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchIndustries,
      fetchAllLedgerAccounts,
      updateBusinessData,
      updateOrCreateEmployee,
      fetchAllEmployees,
      deleteEmployee,
      updateBoLocation,
      deleteLocation,
      createCostCenter,
      deleteCostCenter,
      createBankDetail,
      deleteBankDetail,
      fetchCountries,
      fetchSalutation,
      addSalutation,
      deleteSalutation,
      fetchStates,
      fetchTaxIdentifications,
      addTaxIdentification,
      deleteTaxIdentifications,
      fetchRelationshipTaxIdentifications,
      addRelationshipTaxIdentification,
      deleteRelationshipTaxIdentifications,
      getRelationshipAddress,
      resetRelationshipAddress,
      getAllPaymentGatewayConfig,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getSubdomain,
      saveClientURL,
      checkClientURLExist,
      resetCheckClientURLExist,
      saveFromEmail,updateDefaultLedger,saveEducationLabelInfo
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
