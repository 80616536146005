import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PriceListListingComp from '../../../../components/inventory/PriceList/Listing';
import { fetchPriceList, deletePriceList, showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../actions/commonActions';
import { createPriceList } from '../../../modal/modalBody/common/PriceList/action';

class PriceListListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25
    };
    // fetchDataIfNeeded('fetchPriceList', 'priceList', this.props, payload);
    this.props.fetchPriceList(payload)
  }

  render() {
    return (
      <PriceListListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    priceList: state.common.priceList,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPriceList,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
  deletePriceList,
  createPriceList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceListListing);
