import React from "react";
import { Tabs} from 'antd';
import PageBreadcrumb from "../../../PageBreadcrumb";
import InboundDelivery from "./inboundDelivery";
import InboundDeliveryDraft from "./inboundDeliveryDraft";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import {  FormattedMessage } from 'react-intl';
import InboundDeliveryCreate from '../../../../containers/supplier/InboundDelivery/index';
import PageHeader from "../../../Common/pageHeader";

const { TabPane } = Tabs;

function callback(key, props) {
    const payload = { companyInfo: props.companyInfo, relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize };
    if (key === '1') {
        props.updateState({activeTab: 'Inbound Delivery', activeTabKey: "1"});
    } else if (key === '2') {
        props.updateState({activeTab: 'Draft', activeTabKey: "2"});
        fetchDataIfNeeded('getInboundDeliveriesDraftCount', 'inboundDeliveryDraftCount', props, payload);
        fetchPaginationDataIfNeeded('fetchInboundDeliveriesDrafts', 'inboundDeliveryDraftList', props, payload);
    } 
}





const InboundDeliveryListingComp = (props) => {
    const breadCrumbList = [
        {
            name: <FormattedMessage id='common.purchase' defaultMessage='' />,
        }, 
        {
            name: <FormattedMessage id='sidebar.menuItem.inboundDelivery' defaultMessage='' />,
        }, 
    ];
    const openNewGRN = () => {
        props.pushModalToStack({
            modalBody: <InboundDeliveryCreate {...props} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            wrapClassName: 'new-transaction-wrapper',
            onCancel: () => {
                props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    props.popModalFromStack();
                  }
                })
              }
        })
    }
    return (
        <>            
               
            <PageHeader {...props}
                pageName="sidebar.menuItem.inboundDelivery"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={openNewGRN}
            />
            
                <div className="view-container">
                    <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { callback(key, props); }} type="line">
                        <TabPane tab={<FormattedMessage id='sidebar.menuItem.inboundDelivery' defaultMessage='' />} key="1">
                            <InboundDelivery {...props} />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id='draft.text' defaultMessage='' />} key="2">
                            <InboundDeliveryDraft {...props} />
                        </TabPane>
                    </Tabs>
                </div>

            
            </>

    )
};

export default InboundDeliveryListingComp;
