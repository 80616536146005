import React from 'react';
//import HeaderAction from './HeaderAction'
import './../../../assets/stylesheets/common/invoice.scss';
// import PageBreadcrumb from "../../PageBreadcrumb";
import InboundDeliveryDetails from "./InboundDeliveryDetails";
import ProductDetails from "./ProductDetails";
import HeaderAction from './HeaderAction';
import { Divider } from 'antd';
const InboundDeliveryComponent = (props) => {

  return (
    <>
        <div className="txn-header">
          <HeaderAction {...props} />
        </div>

       <div className='txn-body'>

        <div className="txn-details-container">
          <InboundDeliveryDetails {...props} />
        </div>

        <Divider style={{margin: '0 0 10px 0px'}}/>

        <div className="product-details-container extra-pd">
          <ProductDetails {...props} />
        </div>
       </div>
     
    </>
  );
}


export default InboundDeliveryComponent;