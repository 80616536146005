import React from 'react';

import './tiles.scss';
import { Button } from 'antd';

const Tile = (props) => {
    const { tilesData, customClass, getSelectedTile } = props;
    return (
        <div className={`tiles-wrapper ${customClass}`}>
            {
                (tilesData && tilesData.length > 0) && tilesData.map((option, i) => {
                    const {  footer, count,  currencyIcon,   leble , count2, leble2, antIcon } = option;
                    return (
                        <div key={i}
                            className={`tile-container`}
                            onClick={() => getSelectedTile(option)}
                        >
                            {/* {
                                header && (
                                    <div className='tile-header'>
                                        {header}
                                    </div>
                                )
                            } */}
                            <div className='tile-body'>
                                
                                {/* { icon ? <i className={icon || (props.companyInfo || {}).currencyIcon} /> : <span style={{color: 'white'}}>{currencyCode || (props.companyInfo || {}).currencyCode}</span>} */}
                                <div>
                                    {antIcon ? <div className='ant-icons'>{ antIcon ? antIcon :'' }</div> : <i className={currencyIcon || (props.companyInfo || {}).currencyIcon} />}
                                    {/* <div className='ant-icons'>{ icon ? icon :'' }</div> */}
                                    <div className='tile-name'>
                                            {footer}
                                    </div>
                                </div>
                                <div className="count-value">
                                    <div>
                                    <div className='tile-count'>
                                        {leble && <span>{leble} </span>}
                                        <span className='icon-currency'>
                                            { currencyIcon ? <i className={currencyIcon || (props.companyInfo || {}).currencyIcon} /> : ''}
                                        </span>
                                        <span >{count}</span>
                                        
                                    </div>
                                    {count2 ?
                                        <div className='tile-count' style={{lineHeight: '10px'}}>
                                            {leble && <span>{leble2} </span>}
                                            <span className='icon-currency'>
                                                { currencyIcon ? <i className={currencyIcon || (props.companyInfo || {}).currencyIcon} /> : ''}
                                            </span>
                                            <span >{count2}</span>
                                        </div>
                                        :''
                                    }
                                    </div>
                                    <div style={{alignItems: 'right', color: '#ecf5ff'}} size='small'><Button onClick={()=>{
                                            getSelectedTile(option);
                                        }}>View</Button>
                                    </div>
                                </div>
                                
                            </div>
                            {/* {
                                footer && (
                                    <div className='tile-footer'>
                                        {footer}
                                    </div>
                                )
                            } */}
                        </div>
                    );
                })
            }
        </div>
    );
};

export default Tile;

//
// Tile have some color options
// for that you need to pass color key in data object for every tile
// default color is gray '#999'
// color option: 'green-tile',  'darkGray-tile',  'blue-tile',  'orange-tile' 'magenta-tile', 'purple-tile', 'default'
//
//
Tile.defaultProps = {
    customClass: 'cstmCls',
    getSelectedTile: (selectedTile) => {  },
    tilesData: [
        {
            header: 'Demo Label1',
            count: 0,
            icon: '',
            currencyIcon:'',
            color: 'green-tile'
        },
        {
            footer: 'Demo Label2',
            count: 0,
            icon: '',
            currencyIcon:'',
            color: 'darkGray-tile'
        },
        {
            header: 'Demo Label3',
            count: 0,
            icon: '',
            currencyIcon:'',
            color: 'blue-tile'
        },
        {
            footer: 'Demo Label4',
            count: 0,
            icon: '',
            currencyIcon:'',
            color: 'orange-tile'
        }
        // ,
        // {
        //     header: 'Demo Label5',
        //     count: 0,
        //     icon: 'dollar',
        //     color: 'magenta-tile'
        // },
        // {
        //     header: 'Demo Label6',
        //     count: 0,
        //     icon: 'dollar',
        //     color: 'purple-tile'
        // },
        // {
        //     header: 'Demo Label7',
        //     count: 0,
        //     icon: 'dollar',
        // },
        // {
        //     header: 'Demo Label8',
        //     count: 0,
        //     icon: 'dollar',
        //     color: 'default'
        // }
    ]
};
