import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import {  Skeleton, DatePicker, Select, Empty, Tooltip } from 'antd';
import { getMomentDateForUIReadOnly, getMomentDateForUI, getCurrentDateForBackend, showToasterMessage, fixedDecimalAndFormateNumber } from '../../../../utils'
// import { Dropdown } from '/../../../general/Dropdown';
import PageBreadcrumb from '../../../PageBreadcrumb';
import { CONSTANTS, ICONS } from '../../../../static/constants';
import '../index.scss'
import { UndoOutlined } from '@ant-design/icons';
import SalesInvoiceDetails from '../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import PurchaseInvoiceDetail from '../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail';
import PageHeader from '../../../Common/pageHeader';
import moment from 'moment-timezone';


const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const {RangePicker} = DatePicker;


const AllInventorySummaryListing = (props) => {

    const breadCrumbList = [
        {
            name: <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
        },
        {
            name: <FormattedMessage id="sidebar.menuItem.allInvValuation" defaultMessage="" />,
            link: 'all-inventory-valuation'
        },
        {
            name: <FormattedMessage id="heading.inventry.monthlyInvValuation" defaultMessage="" />,
            link: 'monthly-inventory-valuation',
            param: {
                    productName: props.location.state.productName,
                    variantId: props.variantId,
                    startDate: props.startDate,
                    endDate: props.endDate,
                 },
        },
        {
            name: <FormattedMessage id="heading.inventry.dailyInvValuation" defaultMessage="" />,
        }
    ];

    const openSalesInvoiceDetails = (data) => {
        const invoicePayload = {
            customerId: data.customerId,
            invoiceMasterId: data.invoiceMasterId
        }
        props.pushModalToStack({
            modalBody: <SalesInvoiceDetails {...props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            keyboard: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const openPOInvoiceDetails = (data) => {
        const invoicePayload = {
            supplierId: data.supplierId,
            invoiceMasterId: data.purchaseInvoiceMasterId
        }
        props.pushModalToStack({
            modalBody: <PurchaseInvoiceDetail {...props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }
    const inventoryValuationData = props.dailyInventoryValuationList;
    const {monthStartDate, monthEndDate} = props;

    return (
        <>
            <PageHeader {...props} 
                pageName="heading.inventry.dailyInvValuation"
                breadCrumbList= {breadCrumbList} 
            />

            <div className='view-container stock-summary'>
                <div className="view-container-actions">
                    <div className="left-actions">

                    </div>
                    <div className="right-actions">
                        <RangePicker
                            style={{ width: '230px' }}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            allowEmpty={true}
                            disabledDate={(current) => current.isBefore(moment(props.fStartDate).subtract(1,"day")) || current.isAfter(moment(props.fStartDate).add(30,"day"))}
                            value={[props.fStartDate ? getMomentDateForUI({ date: new Date(props.fStartDate), format }) : null, props.fEndDate ? getMomentDateForUI({ date: new Date(props.fEndDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ fStartDate: dates[0], fEndDate: dates[1] });
                                } else {
                                    props.updateState({ fStartDate: null, fEndDate: null });
                                }
                            }}
                        />

                        <Select
                            style={{ maxWidth: '120px'}}
                            options={[{ value: 'default', label: 'Default' }, { value: 'average-price', label: 'Average Price' }, { value: 'average-cost', label: 'Average Cost' }, { value: 'fifo', label: 'FIFO' }]}
                            value={props.selectedValuation}
                            placeholder='Select Valuation'
                            defaultValue= 'default'
                            onSelect={(value) => {
                                props.updateState({ selectedValuation: value });
                            }}
                        />

                        <button onClick={() => {
                            if(!props.fStartDate && !props.fEndDate && !props.selectedValuation){
                                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                            }

                            const payload = {
                                pageNumber: 1, 
                                pageSize: props.pageSize || 100,

                                filters: {
                                    customerId: props.selectedCustomerId || 0,
                                    startDate: props.fStartDate ? getCurrentDateForBackend(props.fStartDate) : null,
                                    endDate: props.fEndDate ? getCurrentDateForBackend(props.fEndDate) : null,
                                },
                                companyInfo : props.companyInfo,
                                relationshipId: props.companyInfo.relationshipId,
                            };
                            props.updateState({monthStartDate: props.startDate, monthEndDate: props.endDate})
                            props.fetchDailyInventoryValuationList(payload);

                            }}>
                                <i className={`${ICONS.FILTER} mr5`} /> Apply
                        </button>
                        <button onClick={() => {
                            props.updateState({
                                selectedCustomerId: null, selectedCustomerName: null,
                                startDate:null, endDate:null,
                                pageNumber:1 
                            });
                            const payload = {
                                pageNumber: 1, pageSize: props.pageSize,
                                filters: {},
                                companyInfo : props.companyInfo,
                                relationshipId: props.companyInfo.relationshipId,
                            };
                            props.fetchDailyInventoryValuationList(payload);

                            }}>
                                {<UndoOutlined className='mr5' />} Clear
                        </button>
                    </div>
                </div>

                <Skeleton loading={props.listLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    <div className="stock_reports_list">
                        <table className="" width="100%">
                            <thead>
                                <tr>
                                    <th colSpan="100%">
                                        <div style={{float: 'left', paddingLeft: '10px'}}>Stock Item : {props.location.state.productName}</div> 
                                        <div style={{float: 'right', paddingRight: '10px'}}>{monthStartDate && monthEndDate ? <>{getMomentDateForUIReadOnly({date: monthStartDate})} to {getMomentDateForUIReadOnly({date : monthEndDate})}</> :''}</div> 
                                    </th>
                                </tr>
                                <tr>
                                    <th rowSpan={2} className='product'> <FormattedMessage id='inventory.invValuation.listing.header.date' defaultMessage='' /> </th>
                                    <th rowSpan={2} className='product'> <FormattedMessage id='inventory.invValuation.listing.header.particulars' defaultMessage='' /> </th>
                                    <th rowSpan={2} className='product'> <FormattedMessage id='inventory.invValuation.listing.header.vchType' defaultMessage='' /> </th>
                                    <th rowSpan={2} className='product'> <FormattedMessage id='inventory.invValuation.listing.header.vchNumber' defaultMessage='' /> </th>
                                    <th colSpan={2} className='incoming'> <FormattedMessage id='inventory.invValuation.listing.header.incoming' defaultMessage='' /> </th>
                                    <th colSpan={6} className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.header.outgoing' defaultMessage='' /> </th>
                                    <th colSpan={2} className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.header.closingBal' defaultMessage='' />  </th>
                                </tr>
                                <tr>

                                    {/* Inwards */}
                                    <th className='incoming'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='incoming'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>
            
                                    {/* Outwards */}
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.grossValue' defaultMessage='' /></th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.consumption' defaultMessage='' /> </th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.grossProfit' defaultMessage='' /> </th>
                                    <th className='outgoing'> % </th>

                                    {/* Closing Balance */}
                                    <th className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>
                                </tr>
                            </thead>

                            <tbody>
                                {   
                                    inventoryValuationData && Object.keys(inventoryValuationData).length ? (Object.keys(inventoryValuationData)).sort().map((date, key)=>{
                                        let inwardStocksList = inventoryValuationData[date].inwardStocks || []
                                        let outwardStocksList = inventoryValuationData[date].outwardStocks || []
                                        let closingStocksList = inventoryValuationData[date].closingStocks || []

                                        if(inwardStocksList.length || outwardStocksList.length || closingStocksList.length){
                                        // let itrLength = Math.max(inwardStocksList.length, outwardStocksList.length, closingStocksList.length);
                                        // for(var i=0; i<itrLength; i=i+1){
                                        //   return <tr key={i}>
                                        //     <td>{date}</td>
                                        //     <td></td>
                                        //     <td></td>
                                        //     <td></td>
                                        //     <td>{inwardStocksList[i] ? inwardStocksList[i].currentStock : ''}</td>
                                        //     <td>{inwardStocksList[i] ? inwardStocksList[i].rate : ''}</td>
                                        //     <td>{outwardStocksList[i] ? outwardStocksList[i].currentStock : ''}</td>

                                        //     <td>{outwardStocksList[i] ? outwardStocksList[i].salePrice : ''}</td>
                                        //     <td></td>
                                        //     <td></td>
                                        //     <td></td>
                                        //     <td></td>
                                        //     <td>{closingStocksList[i] ? closingStocksList[i].currentStock : ''}</td>
                                        //     <td>{closingStocksList[i] ? closingStocksList[i].rate : ''}</td>
                                        //   </tr>
                                        // }
                                        return (
                                            <>
                                            {
                                                inwardStocksList.map((item, index)=>{
                                                return <tr key={index}>
                                                    <td>
                                                        <Tooltip placement="right" title={`Open invoice`} trigger="hover">
                                                            <div className="cursor-pointer" onClick={() => {
                                                                openPOInvoiceDetails(item)
                                                            }}>
                                                                {date}
                                                            </div>
                                                        </Tooltip>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                    <td>{item.currentStock}</td>
                                                    <td>
                                                    { item.currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost")  ? fixedDecimalAndFormateNumber(Number(item.currentStock) * Number(item.rate || 0)) : '' }
                                                    {/* { item.currentStock && props.selectedValuation === "average-price" ? Number(item.currentStock) * Number(item.purchasePrice || 0) : '' } */}
                                                    { props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(item.stockValuation) : '' }
                                                    </td>

                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                    <td>{closingStocksList[0] ? closingStocksList[0].currentStock:''}</td>
                                                    <td>
                                                    { closingStocksList[0] && closingStocksList[0].currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost")  ? fixedDecimalAndFormateNumber(Number(closingStocksList[0].currentStock) * Number(closingStocksList[0].rate || 0)) : '' }
                                                    {/* { closingStocksList[0].currentStock && props.selectedValuation === "average-price" ? Number(closingStocksList[0].currentStock) * Number(closingStocksList[0].purchasePrice || 0) : '' } */}
                                                    { props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(closingStocksList[0].stockValuation) : '' }
                                                    </td>
                                                </tr>
                                                })
                                            }
                                            {
                                                outwardStocksList.map((item, index)=>{
                                                return <tr key={index}>
                                                    <td>                                                
                                                    <div className="cursor-pointer" onClick={() => {
                                                            openSalesInvoiceDetails(item)
                                                        }}>{date}</div>
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                    <td></td>
                                                    <td></td>

                                                    <td>{item.currentStock}</td>
                                                    <td>
                                                    { item.currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost")  ? fixedDecimalAndFormateNumber(Number(item.currentStock) * Number(item.rate || 0)) : '' }
                                                    {/* { item.currentStock && props.selectedValuation === "average-price" ? Number(item.currentStock) * Number(item.purchasePrice || 0) : '' } */}
                                                    { props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(item.stockValuation) : '' }
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                    <td>{closingStocksList[0] ? closingStocksList[0].currentStock:''}</td>
                                                    <td>
                                                    { closingStocksList[0] && closingStocksList[0].currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost")  ? fixedDecimalAndFormateNumber(Number(closingStocksList[0].currentStock) * Number(closingStocksList[0].rate || 0)) : '' }
                                                    {/* { closingStocksList[0].currentStock && props.selectedValuation === "average-price" ? Number(closingStocksList[0].currentStock) * Number(closingStocksList[0].purchasePrice || 0) : '' } */}
                                                    { closingStocksList[0] && props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(closingStocksList[0].stockValuation): '' }
                                                    </td>
                                                </tr>
                                                })
                                            }
                                            </>
                                        )
                                        }
                                        return null;
                                        
                                    })
                                    :
                                    <tr key="empty-data-box">
                                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                            <Empty />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Skeleton>

                {/* <Pagination
                    size="small"
                    total={stockSummaryCount}
                    showTotal={showTotal}
                    defaultPageSize={100}  showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({pageSize : pageSize, pageNumber: pageNumber})
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber, pageSize, props)
                    }}
                /> */}

            </div>
        </>

    );
};

export default injectIntl(AllInventorySummaryListing);
