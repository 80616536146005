import React, {Fragment} from "react";
import PageBreadcrumb from "../../PageBreadcrumb";
import './index.scss';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button, DatePicker, Skeleton } from 'antd';
import { Dropdown } from "../../../components/general/Dropdown";
import { getMomentDateForUI } from "../../../utils";
import { FormattedMessage } from 'react-intl';
import { FilterOutlined, } from '@ant-design/icons';
import { ICONS } from "../../../static/constants";


const BankReconciliation = (props) => {
    const {pageSize} = props;

    const breadCrumbList = [
        {
            name: 'Finance'
        }, {
            name: 'Bank Reconciliation'
        }
    ];

    const dateFormat = 'DD/MM/YYYY';

    return (
      <Fragment>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="heading.finance.bankingReconcillation"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
            <button className="create-action-btn" onClick={() => {}}>
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>
        <div className="view-container">
          <Skeleton loading={props.isLoading}>
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="finance.bankReconciliation.table.heading"
                    defaultMessage=""
                  />
                  <div className="table-sub-heading">As on 31 Mar 2020</div>
                </div>
                {pageSize ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{pageSize}</div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="right-actions">
                <button onClick={() => {}}>
                  <FilterOutlined /> &nbsp; Filters
                </button>
              </div>
            </div>

            <Row gutter={8}>
              <Col span={4}>
                <label className="label-field">Start Date</label>
                <DatePicker
                  defaultValue={getMomentDateForUI({
                    date: "01/01/2020",
                    format: dateFormat,
                  })}
                  format={dateFormat}
                />
              </Col>

              <Col span={4}>
                <label className="label-field">End Date</label>
                <DatePicker
                  defaultValue={getMomentDateForUI({
                    date: "01/01/2020",
                    format: dateFormat,
                  })}
                  format={dateFormat}
                />
              </Col>

              <Col span={4}>
                <label className="label-field">Ledger</label>
                <Dropdown
                  value=""
                  items=""
                  valueKeyName="ledger"
                  optionKeyName="ledgerId"
                  allowClear={true}
                  onClear={() => {
                    props.updateState({ ledgerId: undefined });
                  }}
                />
              </Col>
              <Col span={2}>
                <Button className="ant-btn-primary">Submit</Button>
              </Col>
            </Row>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Party Name</th>
                    <th>Particulars</th>
                    <th>Payment No</th>
                    <th>Invoice No</th>
                    <th>Payment Mode</th>
                    <th>Supplier Ref No</th>
                    <th>Instrument No</th>
                    <th>Instrument Date</th>
                    <th>Bank Date</th>
                    <th>
                      Amount(
                      <strong>
                        {(props.companyInfo || {}).currencyIcon ? (
                          <i
                            className={(props.companyInfo || {}).currencyIcon}
                          ></i>
                        ) : (
                          (props.companyInfo || {}).currencyCode || "$ "
                        )}
                      </strong>{" "}
                      )
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Jan 14,2020</td>
                    <td>SL Green Realty Corp. Test</td>
                    <td>&nbsp;</td>
                    <td>CPAY-444-140120-2</td>
                    <td>INV-444-051119-3</td>
                    <td>Cheque</td>
                    <td>&nbsp;</td>
                    <td>assadsa</td>
                    <td>Jan 14,2020</td>
                    <td>Jan 14,2020</td>
                    <td>1000 (Dr)</td>
                    <td>
                      <button type="button" className="edit-btn">
                        <i className="fa fa-pencil" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="bank-details-section">
              <Row>
                <Col span={10} offset={14} className="inner">
                  <Row>
                    <Col span={16}>Balance As per Books</Col>
                    <Col span={8}>
                      <strong>
                        {(props.companyInfo || {}).currencyIcon ? (
                          <i
                            className={(props.companyInfo || {}).currencyIcon}
                          ></i>
                        ) : (
                          (props.companyInfo || {}).currencyCode + " "
                        )}
                      </strong>{" "}
                      1000.00(Dr)
                    </Col>
                  </Row>

                  <Row>
                    <Col span={16}>Balance Not Reflected in Books</Col>
                    <Col span={8}>
                      <strong>
                        {(props.companyInfo || {}).currencyIcon ? (
                          <i
                            className={(props.companyInfo || {}).currencyIcon}
                          ></i>
                        ) : (
                          (props.companyInfo || {}).currencyCode + " "
                        )}
                      </strong>{" "}
                      0.00
                    </Col>
                  </Row>

                  <Row>
                    <Col span={16}>Balance As Per Bank Statement</Col>
                    <Col span={8}>
                      <strong>
                        {(props.companyInfo || {}).currencyIcon ? (
                          <i
                            className={(props.companyInfo || {}).currencyIcon}
                          ></i>
                        ) : (
                          (props.companyInfo || {}).currencyCode + " "
                        )}
                      </strong>{" "}
                      1000.00(Cr)
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Skeleton>
        </div>
      </Fragment>
    );
};

export default BankReconciliation;