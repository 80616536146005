import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewHsnCompo from '../../../../../components/modal/modalBody/common/NewHsn';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { saveHsnCode } from './action';
import { getAllGstRates } from '../../../../drawer/inventory/action';

class NewHsnContainer extends Component {

  render() {
    return (
      <NewHsnCompo {...this.props} {...this.state} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveHsnCode,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  getAllGstRates
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewHsnContainer);
