import React, { Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Checkbox,
  Input,
  Popover,
  Tooltip,
  Select,
  Divider,
  Skeleton
} from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import ReactHtmlParser from "react-html-parser";
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
import { Dropdown } from "../../../../general/Dropdown";
import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import { AddAndRemoveColumn } from "../../../../general/AddAndRemoveColumn";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../../static/agGridConstants";
import { AG_GRID_CLASS_CONSTANTS } from "../../../../../static/cssClassConstants";
import {
  showToasterMessage,
  fixedDecimalAndFormateNumber,
  fixedDecimalNumber,
} from "../../../../../utils";
import {
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  TRANSACTION_COLUMN_INDEX,
} from "../../../../../static/constants";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { CustomTableUpload } from "../../../../general/UploadTable";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";
import cardlogo from "../../../../../assets/images/card-logo.jpg";
import NewLedgerComponent from "../../../../../containers/modal/modalBody/finance/LedgerAccount";
import { AWSFileTable } from "../../../../general/AWSFileTable";
import { TextBox } from "../../../../general/TextBox";

const { Option } = Select;
class CustomerProductDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionVisible: {
        discount: false,
        tax: false,
        expense: false,
      },
      total: 0,
      subtotal: 0,
      subtotalWithoutDiscount: 0,
      totalDiscount: 0,
      totalTax: 0,
      itemLevelTaxes: [],
      rootDiscountList: props.rootDiscountList || [{}],
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
      rootExpenseList: props.rootExpenseList || [{}],
      setOnce: false,
      isItemDiscountAplicable: true,
      totalCouponDiscount: props.couponAmount || 0,
      coupondata: props.coupondata || 0,
     
    };
    let self = this;



    document.onkeyup = function (e) {

      var charCode = typeof e.which == "number" ? e.which : e.keyCode;
      if (
        self.state &&
        self.state.scanedEAN &&
        charCode !== 16 &&
        charCode !== 13
      ) {
        self.setState({
          scanedEAN:
            self.state.scanedEAN +
            (e.code.indexOf("Key") > -1
              ? String.fromCharCode(charCode)
              : e.key),
        });
      } else if (charCode !== 16 && charCode !== 13) {
        self.setState({ scanedEAN: e.key });
      }
      if (e.code === "Enter") {


        if (self.state?.scanedEAN?.length > 10) {
          const temp = self.state.scanedEAN?.split("/")
          const ids = temp ? temp[temp.length - 1]?.split(",") : []
          if (ids.length > 1) {

            self.props.getItemByPruductId(
              {
                relationshipId: ids[0],
                productId: ids[1],
                variantId: ids?.length > 1 ? ids[2] : 0,
                callback: function (item) {
                  let selectedProductValue = "";

                  let data = {};
                  let valueArray = [item.itemId];


                  let variant = {};
                  if (
                    item.inventoryItemVariantList &&
                    item.inventoryItemVariantList[0]
                  ) {
                    valueArray.push(item.inventoryItemVariantList[0].variantId);
                    variant = item.inventoryItemVariantList[0];
                  }

                  selectedProductValue = item.hasVariant
                    ? item.itemName +
                    " (" +
                    (variant.attributeValue1 || "") +
                    (variant.attributeValue2 ? " | " : "") +
                    (variant.attributeValue2 || "") +
                    "" +
                    (variant.attributeValue3
                      ? " | " + variant.attributeValue3
                      : "") +
                    ")"
                    : item.itemName;
                  //updateSelectedValue(selectedProductValue);
                  data.selectedLabel = selectedProductValue;
                  data.selectedProductValue = valueArray;
                  data.selectedOption = item;
                  data.product = selectedProductValue;
                  data.productObj = null;
                  data.variant = null;
                  data.upc = self.state.scanedEAN;

                  const itemId = item.itemId;

                  if (itemId) {
                    data.productObj = item;
                    data.variant = item.inventoryItemVariantList[0];
                    if (data.productObj.description) {
                      data.description = data.productObj.description;
                    }
                  }
                  if (data.variant) {
                    const rate = fixedDecimalAndFormateNumber(Number(data.variant.retailPrice || 0));
                    data.baseRate = Number(rate);
                    data.rate = Number(rate);
                    data.uom = data.variant.uomName;
                    if (
                      data.productObj.isPopulateTax &&
                      !(
                        self.props.txColumns.indexOf(
                          GRID_PREFERENCE_CONSTANTS.TAX
                        ) < 0 &&
                        self.props.txMandatoryColumns.indexOf(
                          GRID_PREFERENCE_CONSTANTS.TAX
                        ) < 0
                      )
                    ) {
                      const defaultItemHsnAndTax =
                        data.productObj.inventoryItemDefaultTaxList[0] || {};
                      data.hsnCode = (defaultItemHsnAndTax || {}).hsnCode;
                      let selectedTax = {};
                      if ((defaultItemHsnAndTax || {}).taxSingleRateId) {
                        selectedTax =
                          find(self.props.taxes, {
                            taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId,
                          }) || {};
                      } else {
                        selectedTax =
                          find(self.props.taxes, {
                            taxPercent:
                              (defaultItemHsnAndTax || {}).igstRate ||
                              (defaultItemHsnAndTax || {}).cgstRate ||
                              (defaultItemHsnAndTax || {}).globleTaxRate,
                          }) || {};
                      }
                      data.taxName = selectedTax.taxName;
                      data.taxApplied = selectedTax.taxNameDisplay;
                      data.tax = selectedTax.taxPercent;
                      data.taxName = selectedTax.taxName;
                      data.taxId = selectedTax.taxSingleRateId;
                    }
                  }
                  if (self.gridApi?.rowRenderer?.lastRenderedRow === 0) {
                    let checkEmptyRow = self.gridApi.getDisplayedRowAtIndex(0).data;
                    (!checkEmptyRow || !checkEmptyRow.selectedLabel) &&
                      self.gridApi.updateRowData({
                        remove: [self.gridApi.getDisplayedRowAtIndex(0).data],
                      });
                  }
                  self.gridApi.updateRowData({ add: [data] });
                  self.setState({ scanedEAN: "" });
                },
              },
              self.props
            );
          } 
          // else {
          //   showToasterMessage({
          //     messageType: "error",
          //     description: "Selected product is not found",
          //   });
          // }
        } else {
          self.props.getItemByUPC(
            {
              relationshipId: self.props.companyInfo.relationshipId,
              upc: self.state.scanedEAN,
              callback: function (item) {
                let selectedProductValue = "";

                let data = {};
                let valueArray = [item.itemId];


                let variant = {};
                if (
                  item.inventoryItemVariantList &&
                  item.inventoryItemVariantList[0]
                ) {
                  valueArray.push(item.inventoryItemVariantList[0].variantId);
                  variant = item.inventoryItemVariantList[0];
                }

                selectedProductValue = item.hasVariant
                  ? item.itemName +
                  " (" +
                  (variant.attributeValue1 || "") +
                  (variant.attributeValue2 ? " | " : "") +
                  (variant.attributeValue2 || "") +
                  "" +
                  (variant.attributeValue3
                    ? " | " + variant.attributeValue3
                    : "") +
                  ")"
                  : item.itemName;
                //updateSelectedValue(selectedProductValue);
                data.selectedLabel = selectedProductValue;
                data.selectedProductValue = valueArray;
                data.selectedOption = item;
                data.product = selectedProductValue;
                data.productObj = null;
                data.variant = null;
                data.upc = self.state.scanedEAN;

                const itemId = item.itemId;

                if (itemId) {
                  data.productObj = item;
                  data.variant = item.inventoryItemVariantList[0];
                  if (data.productObj.description) {
                    data.description = data.productObj.description;
                  }
                }
                if (data.variant) {
                  const rate = Number(data.variant.purchasedPrice || 0).toFixed(
                    2
                  );
                  data.baseRate = Number(rate);
                  data.rate = Number(rate);
                  data.uom = data.variant.uomName;
                  if (
                    data.productObj.isPopulateTax &&
                    !(
                      self.props.txColumns.indexOf(
                        GRID_PREFERENCE_CONSTANTS.TAX
                      ) < 0 &&
                      self.props.txMandatoryColumns.indexOf(
                        GRID_PREFERENCE_CONSTANTS.TAX
                      ) < 0
                    )
                  ) {
                    const defaultItemHsnAndTax =
                      data.productObj.inventoryItemDefaultTaxList[0] || {};
                    data.hsnCode = (defaultItemHsnAndTax || {}).hsnCode;
                    let selectedTax = {};
                    if ((defaultItemHsnAndTax || {}).taxSingleRateId) {
                      selectedTax =
                        find(self.props.taxes, {
                          taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId,
                        }) || {};
                    } else {
                      selectedTax =
                        find(self.props.taxes, {
                          taxPercent:
                            (defaultItemHsnAndTax || {}).igstRate ||
                            (defaultItemHsnAndTax || {}).cgstRate ||
                            (defaultItemHsnAndTax || {}).globleTaxRate,
                        }) || {};
                    }
                    data.taxName = selectedTax.taxName;
                    data.taxApplied = selectedTax.taxNameDisplay;
                    data.tax = selectedTax.taxPercent;
                    data.taxName = selectedTax.taxName;
                    data.taxId = selectedTax.taxSingleRateId;
                  }
                }
                if (self.gridApi?.rowRenderer?.lastRenderedRow === 0) {
                  let checkEmptyRow = self.gridApi.getDisplayedRowAtIndex(0).data;
                  (!checkEmptyRow || !checkEmptyRow.selectedLabel) &&
                    self.gridApi.updateRowData({
                      remove: [self.gridApi.getDisplayedRowAtIndex(0).data],
                    });
                }
                self.gridApi.updateRowData({ add: [data] });
                self.setState({ scanedEAN: "" });
              },
            },
            self.props
          );
        }

      }
    };
  }

  getGrandTotal = () => {
    let grandTotal = 0;
    if (this.props.selectedSalesOrder.length) {
      this.props.selectedSalesOrder.forEach((soData) => {
        grandTotal =
          grandTotal +
          Number(this.state["total" + soData.salesOrderMasterId] || 0);
      });
      return Number(grandTotal || 0).toFixed(2);
    } else return 0.0;
  };

  getTotalDiscount = () => {
    let totalDiscount = 0;
    if (this.props.selectedSalesOrder.length) {
      this.props.selectedSalesOrder.forEach((soData) => {
        totalDiscount =
          totalDiscount +
          Number(this.state["totalDiscount" + soData.salesOrderMasterId] || 0);
      });
      return Number(totalDiscount || 0).toFixed(2);
    } else return 0.0;
  };

  getGrandSubTotal = () => {
    let grandSubTotal = 0;
    if (this.props.selectedSalesOrder.length) {
      this.props.selectedSalesOrder.forEach((soData) => {
        grandSubTotal =
          grandSubTotal +
          Number(
            this.state["subtotalWithoutDiscount" + soData.salesOrderMasterId] ||
            0
          );
      });
      return Number(grandSubTotal || 0).toFixed(2);
    } else return 0.0;
  };

  getItemLevelTaxes = () => {
    let itemLevelTaxes = [];
    this.props.selectedSalesOrder.forEach((soData) => {
      if (this.state["itemLevelTaxes" + soData.salesOrderMasterId]) {
        itemLevelTaxes = itemLevelTaxes.concat(
          this.state["itemLevelTaxes" + soData.salesOrderMasterId]
        );
      }
    });
    return itemLevelTaxes;
  };

  calculateSubtotal = (emptycoupan) => {

    let total = 0;
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = [];
    let subtotalWithoutDiscount = 0;
    let rootDiscountAmount = 0;
    // let totalCouponDiscount =!emptycoupan? this.props.couponAmount || 0:0;
    let totalCouponDiscount = 0;
    let maxValueReached = 0;
    let calcSubtotal = 0;
    let docType = [];

    // let self = this;
    if (!this.state.rootDiscountPercent && this.props.rootDiscountPercent) {
      this.setState({
        rootDiscountPercent: this.props.rootDiscountPercent,
        rootDiscountAmount: this.props.rootDiscountAmount,
      });
    }
    let minVaidation = this.state.coupondata?.coupon?.minPurchaseAmount ? this.props.couponSetting.isCoupanAppliedBeforeTax ? this.state.coupondata?.coupon?.minPurchaseAmount <= this.state.subtotal : this.state.coupondata?.coupon?.minPurchaseAmount <= this.state.total : true
    if (!minVaidation && Object.keys(this.state.coupondata || {})?.length) {
      showToasterMessage({
        messageType: "warning",
        description: "Add More Item To apply Coupon",
      });
      this.props.updateState({
        coupondata: null,
        viewCoupon: true,
        couponAmount: 0,
        totalCouponDiscount: 0
      })
      this.setState({
        coupondata: null,
        viewCoupon: true,
        couponAmount: 0,
        totalCouponDiscount: 0,
        // total:this.state.total + preCoupanAmt,
      })
      this.calculateSubtotal(true);
    }
    if (Object.keys(this.state.coupondata || {})?.length) {
      let type = this.state.coupondata?.couponType === "PERCENTAGE" ? 1 : 0;
      if (type) {
        maxValueReached = (this.state?.subtotal * (this.state.coupondata?.discountPercentage / 100)) >= this.state.coupondata?.maxDiscount ? true : false;

      }


    }
    if (!this.state.expenseAmount && this.props.expenseAmount) {
      this.setState({
        expenseAmount: this.props.expenseAmount,
      });
    }
    let rootDiscountList = this.state.rootDiscountList || [];
    let rootExpenseList = this.props.rootExpenseList || [];
    rootDiscountList.forEach((discountObj, i) => {
      discountObj.rootDiscountAmount = 0;
      rootDiscountList[i] = discountObj;
    });
    let self = this;

    this.gridApi.forEachNode(function (rowNode, index) {
      if(rowNode?.data?.tax){
        docType = [...docType , true]
      }
      else{
       
          docType = [...docType , false]
        
      }
      let stringifiedRowData = JSON.stringify(rowNode.data);
      let totalAmountOfColumn = 0;
     
      const rowdata = JSON.parse(stringifiedRowData);
      if (rowdata.qty && rowdata.rate) {
        totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate); 
          calcSubtotal = Number(calcSubtotal) + totalAmountOfColumn;
      }
    });

    this.props.updateState({
      documentType : docType,
    })
    let coupondata = this.props.coupondata;
    let props = this.props;
    this.gridApi &&
      this.gridApi.forEachNode(function (rowNode, index) {
       
        let stringifiedRowData = JSON.stringify(rowNode.data);
        let totalAmountOfColumn = 0;
        const rowdata = JSON.parse(stringifiedRowData);
        if (rowdata.qty && rowdata.rate) {
          let taxObj = {
            subtotal: 0,
            taxPercent: 0,
            taxAmount: 0,
            amountAfterCoupan: !emptycoupan ? 0 : 0
          };
          totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
          subtotalWithoutDiscount =
            Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
          if (rowdata.discount) {
            let discountAmount =
              Number(rowdata.discount || 0) * 0.01 * totalAmountOfColumn;
            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            totalDiscount = totalDiscount + discountAmount;
          }
          if(Object.keys(coupondata || {})?.length && props?.couponSetting?.isCoupanAppliedBeforeTax  && !emptycoupan  ){
            let discountAmount = Number(rowdata.discount || 0) * 0.01 * totalAmountOfColumn;
            if(coupondata.couponType === "PERCENTAGE"){
              let discountPercent = maxValueReached ?((1 - ((Number(calcSubtotal -discountAmount) - (self.state.coupondata?.maxDiscount))  / Number(calcSubtotal -discountAmount))) * 100) : coupondata.discountPercentage;
              let itemLevelCoupAmount = Number(discountPercent) * 0.01 * totalAmountOfColumn;
              taxObj.amountAfterCoupan =(totalAmountOfColumn - itemLevelCoupAmount)?.toFixed(2);
              
              
              
              totalCouponDiscount = totalCouponDiscount + itemLevelCoupAmount;
            }else if(coupondata.couponType === "FLAT"){

              let discountPercent = ((1 - ((Number(calcSubtotal -discountAmount) - (self.state.coupondata?.maxDiscount))  / Number(calcSubtotal -discountAmount))) * 100)?.toFixed(2);
              let itemLevelCoupAmount = Number((Number(discountPercent) * 0.01 * totalAmountOfColumn)?.toFixed(2));

              taxObj.amountAfterCoupan = (totalAmountOfColumn - itemLevelCoupAmount)?.toFixed(2);
              totalCouponDiscount = totalCouponDiscount + itemLevelCoupAmount;
            }
          }
          rootDiscountList.forEach((discountObj, i) => {
            if (discountObj.rootDiscountPercent) {
              const rootDiscountPercent = discountObj.rootDiscountPercent;
              let itemLevelDiscountAmount =
                Number(rootDiscountPercent) * 0.01 * totalAmountOfColumn;

              if (discountObj.isTaxable) {
                totalAmountOfColumn =
                  totalAmountOfColumn - itemLevelDiscountAmount;
                totalDiscount =
                  totalDiscount +
                  itemLevelDiscountAmount +
                  (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||
                    0);
                rootDiscountAmount =
                  rootDiscountAmount +
                  itemLevelDiscountAmount +
                  (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||
                    0);
                discountObj.rootDiscountAmount = (
                  Number(discountObj.rootDiscountAmount || 0) +
                  itemLevelDiscountAmount +
                  (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||
                    0)
                );
                totalTax =
                  totalTax +
                  (Number(rowdata.tax || 0) * 0.01 * itemLevelDiscountAmount ||
                    0);
              } else {
                totalAmountOfColumn =
                  totalAmountOfColumn - itemLevelDiscountAmount;
                totalDiscount = totalDiscount + itemLevelDiscountAmount;
                rootDiscountAmount =
                  rootDiscountAmount + itemLevelDiscountAmount;
                discountObj.rootDiscountAmount = (
                  Number(discountObj.rootDiscountAmount || 0) +
                  itemLevelDiscountAmount
                );
              }
              // if (discountObj.taxPercent) {
              //     let taxAmount = ((Number(discountObj.taxPercent) * 0.01) * itemLevelDiscountAmount) || 0;
              //     totalDiscount = totalDiscount + taxAmount;
              //     totalAmountOfColumn = totalAmountOfColumn - taxAmount;
              //     discountObj.taxAmount = (Number(discountObj.taxPercent) * 0.01) * discountObj.rootDiscountAmount;
              // }
              rootDiscountList[i] = discountObj;
            }
          });
          // if (self.state.rootDiscountPercent) {
          //   let itemLevelDiscountAmount =
          //     Number(self.state.rootDiscountPercent) *
          //     0.01 *
          //     totalAmountOfColumn;
          //   totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
          //   totalDiscount = totalDiscount + itemLevelDiscountAmount;
          //   rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
          // }

          if (rowdata.tax) {
            // taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
            // taxObj.taxName = rowdata.taxName;
            // let taxAmount = Number(rowdata.tax || 0) * 0.01 * (taxObj.amountAfterCoupan?taxObj.amountAfterCoupan: totalAmountOfColumn);
            // totalTax = totalTax + taxAmount;

            taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
            taxObj.taxName = rowdata.taxName;
            let taxAmount = Number(rowdata.tax || 0) * 0.01 * (taxObj.amountAfterCoupan ? taxObj.amountAfterCoupan : totalAmountOfColumn);
            totalTax = totalTax + taxAmount;
          }
          if (taxObj.taxPercent) {
            taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
            const availableTaxObj = find(itemLevelTaxes, {
              taxPercent: taxObj.taxPercent,
            });
            if (availableTaxObj) {
              itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                if (ele.taxPercent === taxObj.taxPercent) {
                  ele.subtotal = Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                  ele.amountAfterCoupan = Number(ele.amountAfterCoupan || 0) + Number(taxObj.amountAfterCoupan || 0);
                  ele.taxAmount = Number(ele.taxPercent || 0) * 0.01 * (ele.amountAfterCoupan > 0 ? (ele.amountAfterCoupan || 0) : Number(ele.subtotal || 0));
                }
                return true;
              });
            } else {
              taxObj.taxAmount = (Number(taxObj.taxPercent || 0) * 0.01 * (taxObj.amountAfterCoupan > 0 ? (taxObj.amountAfterCoupan || 0) : Number((taxObj.subtotal || 0)))).toFixed(2);
              itemLevelTaxes.push(taxObj);
            }
          }
          subtotal = Number(subtotal) + totalAmountOfColumn;
        }
      });
    let expenseTax = 0;
    let expenseAmt = 0;
    rootExpenseList.forEach((expenseObj, i) => {
      expenseAmt += Number(expenseObj.expenseAmount || 0);
      if (
        expenseObj.selectedExpenseLedger &&
        expenseObj.selectedExpenseLedger.isLedgerTaxable &&
        expenseObj.expenseLedgerAccountTaxRate &&
        expenseObj.expenseAmount
      ) {

        expenseTax =
          (Number(expenseObj.expenseAmount || 0) *
            (expenseObj.expenseLedgerAccountTaxRate || 0)) /
          100;
          let taxObj = {
            subtotal: 0,
            taxPercent: 0,
            taxAmount: 0,
          };

          taxObj.taxPercent = `${(expenseObj.expenseLedgerAccountTaxRate)?.toFixed(2)}`;
          taxObj.taxName = "expense";
          if (taxObj.taxPercent) {
            taxObj.subtotal = Number(expenseObj.expenseAmount || 0).toFixed(2);
            const availableTaxObj = find(itemLevelTaxes, {
              taxPercent: taxObj.taxPercent,
            });
            if (availableTaxObj) {
              itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                if (ele.taxPercent === taxObj.taxPercent) {
                  ele.subtotal = (
                    Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0)
                  ).toFixed(2);
                  ele.taxAmount =
                    Number(ele.taxPercent || 0) *
                    0.01 *
                    Number(ele.subtotal || 0);
                }
                return true;
              });
            } else {
              taxObj.taxAmount = (
                Number(taxObj.taxPercent || 0) *
                0.01 *
                Number(taxObj.subtotal || 0)
              ).toFixed(2);
              itemLevelTaxes.push(taxObj);
            }
          }
      }
    });
    // total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;


    total = Number(subtotal || 0) + totalTax + expenseAmt + expenseTax;

    if (Object.keys(this.state.coupondata || {})?.length && !props?.couponSetting?.isCoupanAppliedBeforeTax) {

      let type = this.state.coupondata?.couponType === "PERCENTAGE" ? 1 : 0;
      let maxDiscount = this.state.coupondata?.maxDiscount;
      let ammount = total * (this.state.coupondata?.discountPercentage / 100);

      totalCouponDiscount = !emptycoupan ? type ? ammount >= maxDiscount ? maxDiscount : ammount : maxDiscount:0;
    }

    total = total - (!emptycoupan ? totalCouponDiscount : 0);
    let roundingOffAmt = 0;
    if (this.props.roundingOffSettings && this.props.roundingOffSettings.isRoundingOffTotalAmtOnSalesInvoice) {
      roundingOffAmt = Number((Math.round(total) - total));
      total = total + roundingOffAmt;
    }


    this.setState({
      total: Number(total || 0),
      subtotal: Number(subtotal || 0),
      totalDiscount: Number(totalDiscount || 0),
      totalTax: Number(totalTax || 0),
      subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0),
      itemLevelTaxes,
      rootDiscountAmount: Number(rootDiscountAmount || 0),
      rootDiscountList: this.props.rootDiscountList || rootDiscountList,
      roundingOffAmt,
      totalCouponDiscount: (totalCouponDiscount)?.toFixed(2)
    });
    this.props.updateState({
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
      expenseAmount: 0,
      rootDiscountList: null
    });
  };

  calculateTotalExpense = () => {
    let expenseAmounts = this.state.expenseAmounts || {};
    let totalExpense = 0;
    this.props.selectedExpenseList.forEach((expenseId) => {
      const selectedExpense = find(this.props.expenseTypes, {
        lkExpenseTypeId: Number(expenseId),
      });
      let expenseObj = expenseAmounts[expenseId] || {};
      if (selectedExpense.taxSingleRateId && expenseObj.expenseAmt) {
        expenseObj.expenseTax = Number(
          (expenseObj.expenseAmt * Number(selectedExpense.taxRate || 5)) / 100
        ).toFixed(2);
        expenseAmounts[expenseId] = expenseObj;
        totalExpense +=
          Number(expenseObj.expenseAmt || 0) +
          Number(expenseObj.expenseTax || 0);
      } else {
        totalExpense += Number(expenseObj.expenseAmt || 0);
      }
    });
    this.setState({
      expenseAmounts,
      expenseAmount: totalExpense,
    });
    this.props.updateState({
      expenseAmount: totalExpense,
    });
    if (this.state.subtotal) {
      this.calculateSubtotal();
    }
  };

  handleNewTaxAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    this.gridApi && this.gridApi.stopEditing();
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.newTax" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.NEW_TAX,
        data: payload,
      },
      wrapClassName: "added-item-dropdown-modal",

      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  handleNewExpenseAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <FormattedMessage id="addItem.text.expenseType" defaultMessage="" />
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EXPENSE_TYPE,
        data: payload,
      },
      wrapClassName: "added-item-dropdown-modal",

      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        addFunc = props.addExpenseTypes;
        break;
      }
      default: {
        addFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        this.gridApi && this.gridApi.stopEditing();
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        title = (
          <FormattedMessage id="addItem.text.expenseType" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    this.gridApi && this.gridApi.stopEditing();
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      case MODAL_TYPE.NEW_TAX: {
        deleteFunc = props.deleteTax;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        deleteFunc = props.deleteExpenseTypes;
        break;
      }
      default: {
        deleteFunc = () => { };
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  newLedgerAccounts = (props) => {
    props.updateDrawerState({
      ledgerAccountsDrawerVisible: true
    })
    // props.showModal({
    //   title: (
    //     <Fragment>
    //       <div className="ant-modal-title">
    //         <FormattedMessage
    //           id="sidebar.newChartOfAccount.popup.title"
    //           defaultMessage=""
    //         />
    //       </div>
    //     </Fragment>
    //   ),
    //   modalBody: <NewLedgerComponent {...props} />,
    //   width: 1100,
    //   hideFooter: true,
    // });
  };

  render() {
    // const { txColumnSetting } = this.props;
    const self = this;
    if (
      this.gridApi &&
      this.props.priceTypeToApply &&
      this.props.isToPriceTypeToApply
    ) {
      let self = this;
      this.gridApi.forEachNode(function (rowNode, index) {
        if (rowNode.data.product) {
          let rate =
            self.props.priceTypeToApply ===
              AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0]
              ? Number(rowNode.data.variant.retailPrice || 0).toFixed(2)
              : Number(rowNode.data.variant.wholesalePrice || 0).toFixed(2);
          rowNode.setData({ ...rowNode.data, rate, baseRate: rate });
        }
      });
      this.props.updateState({
        isToPriceTypeToApply: false,
      });
    }
    if (this.props.valueSet && !this.state.setOnce) {
      {Object.keys(this.props.coupondata || {})?.length && this.props.applieCoupon({
        
        relationshipId:this.props.companyInfo?.relationshipId,
        customerId:this.props.customer?.customerId,
        couponCode:this.props.coupanValue
      },(data)=>{
        if(data?.isValid){
         let minVaidation =data?.coupon?.minPurchaseAmount ? this.props.couponSetting.isCoupanAppliedBeforeTax ? data?.coupon?.minPurchaseAmount <= this.state.subtotal : data?.coupon?.minPurchaseAmount <= this.state.total: true 
         if(minVaidation){
          showToasterMessage({
            description: 'Applied successfully.',
            messageType: 'success'
          })
           this.props.updateState({
             viewCoupon:false,
             coupondata:data,
           })
           this.setState({
             viewCoupon:false,
             coupondata:data,
           })

         this.calculateSubtotal();
         }else{
          showToasterMessage({
            messageType: "warning",
            description: "Add More Item To apply Coupon",
          }); 
         }

        }else{
          showToasterMessage({
            messageType: "warning",
            description: "Invalid Coupon",
          });    
        }
      })}
      this.setState({
        rootExpenseList: this.props.rootExpenseList,
        rootDiscountList: this.props.rootDiscountList,
        isItemDiscountAplicable: true || this.props.isItemDiscountAplicable,
        totalCouponDiscount: this.props.couponAmount,
        coupondata: this.props.coupondata,
        setOnce: true
      })
    }
    const { itemLevelTaxes } = this.state;
    // const { sectionVisible, itemLevelTaxes } = this.state;
    const isPlaceOfSupplySameAsRelationship =
      (this.props.relationshipBillingAddress || {}).stateName ===
      this.props.placeOfSupply;

    const onGridReady = (params) => {

      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      let txColumnSetting =
        this.props.txColumnSetting && this.props.txColumnSetting.length
          ? this.props.txColumnSetting
          : params.columnApi.getColumnState();
      let actionIndex = 0;
      let amountIndex = 0;
      if (Object.keys(txColumnSetting).length) {
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_INVOICE_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          // if (columnName === 'salesOrderNumber') {
          //     e.hide = (!this.props.selectedSalesOrder.length || _isColumnToHide('salesOrderNumber')) || this.props.isShipment;
          // }

          // if (columnName === 'shipmentNumber') {
          //     e.hide = !this.props.selectedSalesOrder.length || _isColumnToHide('shipmentNumber') || !this.props.isShipment;
          // }

          if (columnName === "action") {
            e.width = 52;
            actionIndex = index;
            e.hide = false;
            e.pinned = "right";
          }
          if (columnName === "amount") {
            e.hide = false;
            e.lockPosition = true;
            amountIndex = index;
            e.pinned = "right";
          }
          if (columnName === "hsn" || columnName === "hsCode") {
            e.hide = _isColumnToHide(columnName) || (self.props.companyInfo || {}).countryName !== "India";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        this.gridColumnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
            this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      }
    };

    if (this.gridApi && this.props.isColumnFitTable) {
      this.gridApi.sizeColumnsToFit();
    }

    const onModelUpdated = (params) => {
      if (this.props.tableDataReRendered) {
        setTimeout(() => {
          this.calculateSubtotal();
        }, 1500);
        //params.api.resetRowHeights();
        this.props.updateState({
          tableDataReRendered: false,
        });
        let actionIndex = 0;
        let amountIndex = 0;
        let txColumnSetting =
          this.props.txColumnSetting && this.props.txColumnSetting.length
            ? this.props.txColumnSetting
            : params.columnApi.getColumnState();
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_INVOICE_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.lockPosition = true;
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "amount") {
            e.hide = false;
            amountIndex = index;
            e.lockPosition = true;
            e.pinned = "right";
          }

          if (columnName === "salesOrderNumber") {
            e.hide = _isColumnToHide("salesOrderNumber");
          }

          if (columnName === "shipmentNumber") {
            e.hide = _isColumnToHide("shipmentNumber");
          }
          if (columnName === "action") {
            actionIndex = index;
            e.width = 52;
            e.hide = false;
            e.pinned = "right";
          }
          if (columnName === "hsn" || columnName === "hsCode") {
            e.hide = _isColumnToHide(columnName) || (self.props.companyInfo || {}).countryName !== "India";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        params.columnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
            this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      }
    };

    this.gridApis = [];
    const content = <AddAndRemoveColumn {...this.props} />;
    const _isColumnToHide = (columnName) => {
      // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
      //     return true;
      // }
      return this.props.txColumns.indexOf(columnName) < 0 &&
        this.props.txMandatoryColumns.indexOf(columnName) < 0
        ? true
        : false;
    };

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "#";
        },
        headerClass: "pointer-none",
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1;
        },
        field: "sno",
        rowDrag: true,
        pinned: true,
        suppressMovable: true,
        lockPosition: true,
        colId: "sno_1",
        hide: false,
        minWidth: 70,
        maxWidth: 70,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.product"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
        resizable: true,
        minWidth: 100,
        maxWidth: 250,
        suppressMovable: true,
        editable:
          !this.props.isSoConversionToInvoice &&
          !this.props.isShipmentConversion,
        sortable: true,
        permissions: this.props.permissions,
        moduleName: MODULE_CODED_VALUES.PRODUCTS,
        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PRODUCT),
        cellEditor: "customTreeDropDownStock",
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight >=
              (params.node.data.maxHeight || 30)
            ) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
              params.api && params.api.onRowHeightChanged();
            } else {
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
            }
          }, 500);

          return ReactHtmlParser(params.data.product);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            options: this.props.products,
            onSearch: this.props.onSearch,
            onAddNewClick: () => {
              self.props.updateHeaderState({
                inventory: {
                  inventoryDrawerVisible: true,

                },
              });
            },
            canAddNew: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.PRODUCTS,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            value: obj.node.data.selectedProductValue || [],
            selectedLabel: obj.node.data.product || "",
            allowClear: true,
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.selectedProductValue =
                obj.node.selectedProductValue;
              obj.node.data.productObj = null;
              obj.node.data.variant = null;
              const itemId =
                obj.node.data.selectedProductValue &&
                obj.node.data.selectedProductValue[0];
              if (itemId) {
                const variantId =
                  obj.data.selectedProductValue &&
                    obj.data.selectedProductValue.length > 1
                    ? obj.data.selectedProductValue[1]
                    : 0;
                const itemVariantStockId =
                  obj.data.selectedProductValue &&
                    obj.data.selectedProductValue.length === 3
                    ? obj.data.selectedProductValue[2]
                    : 0;
                obj.node.data.productObj =
                  find(this.props.products, { itemId: Number(itemId) }) || {};
                obj.node.data.variant = variantId
                  ? find(obj.node.data.productObj.inventoryItemVariantList, {
                    variantId: Number(variantId),
                  })
                  : obj.node.data.productObj.inventoryItemVariantList[0];
                obj.node.data.inventoryItemVariantId = variantId;
                obj.node.data.itemVariantStockId = itemVariantStockId;
                if (obj.node.data.productObj.shortDescription) {
                  obj.node.data.description =
                    obj.node.data.productObj.shortDescription;
                }
              }
              if (
                this.props.priceTypeToApply &&
                obj.node.data.variant &&
                !this.props.isDataToUpdate
              ) {
                const rate =
                  this.props.priceTypeToApply ===
                    AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD
                      .PRICE_TYPE_LIST[0]
                    ? Number(obj.node.data.variant.retailPrice || 0).toFixed(2)
                    : Number(obj.node.data.variant.wholesalePrice || 0).toFixed(
                      2
                    );
                obj.node.data.baseRate = Number(rate);
                obj.node.data.rate = Number(rate);
                //obj.node.setDataValue('specialDiscount', 0);
                //obj.node.setDataValue('baseRate', Number(rate));
                //obj.node.setDataValue('rate', Number(rate));
                //obj.node.setDataValue('qty', Number(obj.node.data.variant.currentStock));
                //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                obj.node.data.uom = obj.node.data.variant.uomName;
                if (
                  obj.node.data.productObj.isPopulateTax &&
                  !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)
                ) {
                  const defaultItemHsnAndTax =
                    obj.node.data.productObj.inventoryItemDefaultTaxList[0] ||
                    {};
                  obj.node.data.hsnCode = (defaultItemHsnAndTax || {}).hsnCode;
                  let selectedTax = {};
                  if (defaultItemHsnAndTax.taxSingleRateId) {
                    selectedTax = find(this.props.taxes, { taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId, }) || {};
                  } else {
                    selectedTax = find(this.props.taxes, {
                      taxPercent:
                        (defaultItemHsnAndTax || {}).igstRate ||
                        (defaultItemHsnAndTax || {}).cgstRate ||
                        (defaultItemHsnAndTax || {}).globleTaxRate,
                    }) || {};
                  }
                  obj.node.data.taxName = selectedTax.taxName;
                  obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                  obj.node.data.tax = selectedTax.taxPercent;
                  obj.node.data.taxId = selectedTax.taxSingleRateId;
                }
              }
              let isDuplicateItem = false;
              let isOutOfStock = false;
              if (
                obj.node.data.variant &&
                obj.node.data.variant.currentStock <= 0
              ) {
                isOutOfStock = true;
                showToasterMessage({
                  messageType: "error",
                  description: "Selected product is out of stock",
                });
              }
              obj.api &&
                obj.api.forEachNode((rowNode, index) => {
                  const { data } = rowNode;
                  if (
                    data.product === selectedValue &&
                    index !== obj.node.rowIndex
                  ) {
                    isDuplicateItem = true;
                  }
                });
              if (isDuplicateItem) {
                showToasterMessage({
                  messageType: "warning",
                  description: "Duplicate product is selected",
                });
              }

              obj.node.data.isDuplicateItem = isDuplicateItem;
              obj.node.data.isOutOfStock = isOutOfStock;
              setTimeout(() => {
                this.gridApi && this.gridApi.stopEditing();
              }, 100);
            },
          };
        },
        cellClassRules: {
          // apply green to 2008
          // 'ag-grid-cell-error-boundary': function (obj) {
          //     return obj.node.data.variant && obj.node.data.variant.currentStock <= 0;
          // },
          "ag-grid-cell-warning-boundary": function (obj) {
            let isDuplicateItem = false;
            self.gridApi &&
              self.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (
                  data.inventoryItemVariantId &&
                  data.inventoryItemVariantId ===
                  obj.node.data.inventoryItemVariantId &&
                  obj.node.rowIndex !== index &&
                  data.salesOrderMasterId === obj.node.data.salesOrderMasterId
                ) {
                  isDuplicateItem = true;
                }
              });
            return isDuplicateItem;
          },
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.description"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        editable: true,
        minWidth: 120,
        suppressMovable: true,
        sortable: true,
        resizable: true,
        cellClass: " ag-cell-description",
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
        colId: GRID_PREFERENCE_CONSTANTS.DESCRIPTION + "_1",
        cellEditor: "CKEditorGrid",
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true,
              });
            },
          };
        },
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight > 30 &&
              params.reactContainer.scrollHeight >=
              (params.node.data.minHeight || 0)
            ) {
              params.eParentOfValue.style.height = "inherit";
              params.eParentOfValue.style.height =
                params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.api && params.api.onRowHeightChanged();
            }
          }, 200);
          return ReactHtmlParser(params.data.description);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (
            params.event.target.scrollHeight > (params.data.minHeight || 30)
          ) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            params.api && params.api.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.node.data.minHeight || 30
                }px`;
            }
            params.node.setRowHeight(params.node.data.minHeight || 30);
            params.api && params.api.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.qty"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
        editable: !this.props.isShipmentConversion,
        sortable: true,
        suppressMovable: true,
        minWidth: 80,
        maxWidth: 100,
        resizable: true,
        cellEditor: "customNumberEditor",
        hide: this.props.isSoConversionToInvoice,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthQty,
            onChange: (value) => {
              let isOutOfStock = false;
              if (value > obj.node.data.variant?.currentStock) {

                isOutOfStock = true;
                showToasterMessage({
                  messageType: "warning",
                  description: `Only ${obj.node.data.variant.currentStock} Stock Left`,
                });
              } else {
                isOutOfStock = false;
              }
              obj.node.data.isOutOfStock = isOutOfStock;
              obj.node.data.qty = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.qtyOrdered"
              defaultMessage=""
            />
          );
        },
        field: "quantityOrdered",
        editable:
          !this.props.isSoConversionToInvoice &&
          !this.props.isShipmentConversion,
        sortable: true,
        suppressMovable: true,
        minWidth: 80,
        maxWidth: 100,
        resizable: true,
        cellEditor: "customNumberEditor",
        hide:
          this.props.isShipmentConversion ||
          !(this.props.isSoConversionToInvoice || this.props.isSoConversion),
        colId: "quantityOrdered_1",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthQty,
            onChange: (value) => {
              obj.node.data.quantityOrdered = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.qtyInvoiced"
              defaultMessage=""
            />
          );
        },
        field: "quantityInvoiced",
        editable:
          !this.props.isSoConversionToInvoice &&
          !this.props.isShipmentConversion,
        sortable: true,
        suppressMovable: true,
        minWidth: 80,
        maxWidth: 100,
        resizable: true,
        cellEditor: "customNumberEditor",
        hide:
          this.props.isShipmentConversion ||
          !(this.props.isSoConversionToInvoice || this.props.isSoConversion),
        colId: "quantityInvoiced_1",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthQty,
            onChange: (value) => {
              obj.node.data.quantityInvoiced = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.qtyToInvoice"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: "qtyToInvoiced_1",
        editable: !this.props.isShipmentConversion,
        sortable: true,
        suppressMovable: true,
        minWidth: 80,
        maxWidth: 100,
        resizable: true,
        cellEditor: "customNumberEditor",
        hide:
          this.props.isShipmentConversion ||
          !this.props.isSoConversionToInvoice,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.qty"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.QTY_FIELD + "_1",
        resizable: true,
        sortable: true,
        suppressMovable: true,
        minWidth: 80,
        maxWidth: 100,
        cellEditor: "customNumberEditor",
        hide: !this.props.isShipmentConversion,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthQty,
            onChange: (value) => {
              obj.node.data.quantity = value;


              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return "SO #";
        },
        field: "salesOrderNumber",
        colId: "salesOrderNumber_1",
        hide: _isColumnToHide("salesOrderNumber"),
        resizable: true,
        sortable: true,
        suppressMovable: true,
        minWidth: 120,
        maxWidth: 150,
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight >=
              (params.node.data.maxHeight || 30)
            ) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
              params.api && params.api.onRowHeightChanged();
            } else {
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
            }
          }, 500);
          return ReactHtmlParser(params.data.salesOrderNumber);
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return "Shipment #";
        },
        field: "shipmentNumber",
        colId: "shipmentNumber_1",
        resizable: true,
        sortable: true,
        suppressMovable: true,
        hide: _isColumnToHide("shipmentNumber"),
        minWidth: 140,
        maxWidth: 170,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.uom"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
        editable: true,
        sortable: true,
        minWidth: 80,
        maxWidth: 110,
        suppressMovable: true,
        cellEditor: "customDropDownEditor",
        colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
        resizable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.uomList,
            optionKeyName: "uomId",
            valueKeyName: "uomName",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM);
            },
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              // obj.api.refreshCells();
            },
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
            },
          };
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },

      {

        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.hsn"
              defaultMessage=""
            />
          );
        },
        field: "hsnCode",
        colId: GRID_PREFERENCE_CONSTANTS.HSN + "_1",
        resizable: true,
        minWidth: 100,
        maxWidth: 120,
        suppressMovable: true,
        editable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HSN) || (self.props.companyInfo || {}).countryName !== "India",
        cellEditor: "customTextEditor",
        cellEditorParams: (obj) => {
          return {
            onChange: (value) => {
              obj.node.data.hsnCode = value;
              obj.api.refreshCells();
            },
          };
        },
      },

      {
        headerComponentFramework: () => {
          return (
            <div>
              {" "}
              <span>MRP</span>
              <Tooltip
                placement="right"
                title={this.props.intl.formatMessage({
                  id: "tooltip.originalRate",
                  defaultMessage: "",
                })}
                trigger="click"
              >
                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
              </Tooltip>
            </div>
          );
        },
        colId: GRID_PREFERENCE_CONSTANTS.BASE_PRICE + "_1",
        resizable: true,
        maxWidth: 130,
        minWidth: 100,
        field: "baseRate",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.BASE_PRICE),
        valueGetter: (params) => {
          return (
            params.data.baseRate && Number(params.data.baseRate || 0).toFixed(2)
          );
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.rate"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.RATE + "_1",
        resizable: true,
        maxWidth: 130,
        minWidth: 100,
        suppressMovable: true,
        editable: true,
        sortable: true,
        cellEditor: "customNumberEditor",
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            onChange: (value) => {
              obj.node.data.baseRate = value;
              obj.node.data.rate = value;
              obj.api.refreshCells();
            },
          };
        },
        valueGetter: (params) => {
          if (
            params.data.specialDiscount &&
            params.data.rate &&
            Number(params.data.rate) !== 0
          ) {
            params.data.rate =
              params.data.baseRate -
              params.data.specialDiscount * 0.01 * params.data.baseRate;
          }
          if (!params.data.specialDiscount && params.data.baseRate) {
            params.data.rate = params.data.baseRate;
          }
          if (this.props.priceListId && params.data.rate) {
            params.data.rate =
              params.data.baseRate -
              (this.props.priceListType.toLowerCase() === "markdown"
                ? (params.data.specialDiscount || 0) *
                0.01 *
                params.data.baseRate +
                this.props.priceListPercentage * 0.01 * params.data.baseRate
                : (params.data.specialDiscount || 0) *
                0.01 *
                params.data.baseRate -
                this.props.priceListPercentage * 0.01 * params.data.baseRate);
          }
          return (
            params.data.rate &&
            Number(params.data.rate || 0).toFixed(
              (this.props.companyInfo || {}).decimalDigitsLengthPrice || 0
            )
          );
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.specialDiscount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD + "_1",
        editable: true,
        sortable: true,
        minWidth: 100,
        maxWidth: 120,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT),
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (<>
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.discount"
              defaultMessage=""
            />
            {/* <Checkbox
              defaultChecked={self.state.isItemDiscountAplicable}
              onChange={(e) => {
                const isChecked = e.target.checked;
                self.setState({
                  isItemDiscountAplicable: isChecked,
                });
                // self.gridApi.tableDataReRendered();
                // self.gridApi.sizeColumnsToFit();
              }}
            /> */}
          </>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
        editable: true,
        suppressMovable: true,
        sortable: true,
        minWidth: 120,
        maxWidth: 120,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT),
        colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT + "_1",
        resizable: true,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            maxValue: 100,
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            onChange: (value) => {
              let discountAmount = 0;
              obj.node.data.discount = value;
              if (obj.node.data.qty && obj.node.data.rate) {
                let totalAmountOfColumn =
                  Number(obj.node.data.qty) * Number(obj.node.data.rate);
                discountAmount =
                  Number(value || 0) * 0.01 * totalAmountOfColumn;
              }
              obj.node.data.discountAmount = discountAmount;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesInvoice.form.gridHeader.discountAmt"
                defaultMessage=""
              />{" "}
              (
              {(this.props.companyInfo || {}).currencyIcon ? (
                <i className={(this.props.companyInfo || {}).currencyIcon}></i>
              ) : (
                (this.props.companyInfo || {}).currencyCode
              )}
              ){" "}
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_AMOUNT_FIELD,
        editable: true,
        resizable: true,
        minWidth: 115,
        maxWidth: 140,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT_AMOUNT_FIELD),
        colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT_AMOUNT_FIELD + "_1",
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        valueGetter: (params) => {
          return (
            params.data.discountAmount &&
            Number(params.data.discountAmount || 0)
          );
        },
        cellEditorParams: (obj) => {
          return {
            onChange: (value) => {
              let discount = 0;
              obj.node.data.discountAmount = value;
              if (obj.node.data.qty && obj.node.data.rate) {
                let totalAmountOfColumn =
                  Number(obj.node.data.qty) * Number(obj.node.data.rate);
                discount = (
                  (Number(value || 0) * 100) /
                  totalAmountOfColumn
                );
              }
              obj.node.data.discount = discount;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.tax"
              defaultMessage=""
            />
          );
        },
        field: "taxApplied",
        editable: true,
        sortable: true,
        minWidth: 100,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX),
        colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
        resizable: true,
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: true,
            items: this.props.taxes,
            optionKeyName: "taxSingleRateId",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.TAXES,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                taxName: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewTaxAddition(
                this.props,
                payload,
                MODAL_TYPE.NEW_TAX
              );
            },
            valueKeyName: "taxNameDisplay",
            onSelect: (selectedValue, option) => {
              const selectedTax = find(this.props.taxes, {
                taxSingleRateId: Number(option.key),
              });
              obj.node.data.tax = selectedTax.taxPercent;
              obj.node.data.taxName = selectedTax.taxName;
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.taxId = selectedTax.taxSingleRateId;
              this.calculateSubtotal();
              obj.api.refreshCells();
            },
            allowClear: true,
            onClear: () => {
              obj.node.data.tax = null;
              obj.node.data.taxId = null;
              obj.node.data.taxApplied = null;
              obj.node.data.taxName = null;
              this.calculateSubtotal();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesInvoice.form.gridHeader.comment"
                defaultMessage=""
              />
              <Tooltip
                placement="top"
                title={this.props.intl.formatMessage({
                  id: "tooltip.warehouseDetail",
                  defaultMessage: "",
                })}
                trigger="click"
              >
                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
              </Tooltip>
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD,
        editable: true,
        sortable: true,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.COMMENT),
        colId: GRID_PREFERENCE_CONSTANTS.COMMENT + "_1",
        resizable: true,
        cellEditor: "CKEditorGrid",
        cellEditorParams: (params) => {
          return {
            value: params.node.data.comment || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true,
              });
            },
          };
        },
        cellRendererFramework: (params) => {
          return ReactHtmlParser(params.data.comment);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (params.event.target.scrollHeight > 30) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            params.api && params.api.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${30}px`;
            }
            params.node.setRowHeight(30);
            params.api && params.api.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesInvoice.form.gridHeader.partNumber"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD,
        suppressMovable: true,
        editable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PART_NUMBER),
        resizable: true,
        colId: GRID_PREFERENCE_CONSTANTS.PART_NUMBER + "_1",
        cellEditor: "customTextEditor",
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage id="common.materialNumber" defaultMessage="" />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.MATERIAL_NUMBER_FIELD,
        editable: true,
        sortable: true,
        suppressMovable: true,
        resizable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER),
        colId: GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER + "_1",
        cellEditor: "customTextEditor",
      },

      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesInvoice.form.gridHeader.stockNumber"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.STOCK_NUMBER_FIELD,
        suppressMovable: true,
        editable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER),
        colId: GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER + "_1",
        resizable: true,
        cellEditor: "customTextEditor",
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesInvoice.form.gridHeader.origin"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD,
        editable: true,
        sortable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.ORIGIN),
        colId: GRID_PREFERENCE_CONSTANTS.ORIGIN + "_1",
        resizable: true,
        suppressMovable: true,
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.countries,
            optionKeyName: "countryId",
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            valueKeyName: "countryName",
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.origin = selectedValue;
            },
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
              obj.node.data.origin = undefined;
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesInvoice.form.gridHeader.hsCode"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.HS_CODE_FIELD,
        editable: true,
        sortable: true,
        suppressMovable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HS_CODE),
        colId: GRID_PREFERENCE_CONSTANTS.HS_CODE + "_1",
        resizable: true,
        cellEditor: "customTextEditor",
      },

      {
        headerComponentFramework: () => {
          return (
            "Taxable Amount"
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX,
       
        
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX + "_1",
        resizable: true,
        minWidth: 150,
        maxWidth: 250,
      
        
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount =
                params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
          
            setTimeout(() => {
              this.calculateSubtotal();
            }, 100);
          }
          return totalAmountOfColumn
            ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
            : "";
        },
        
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesInvoice.form.gridHeader.amount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
        pinned: "right",
        headerClass: "pointer-none",
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
        resizable: true,
        minWidth: 150,
        maxWidth: 250,
        lockPosition: true,
        suppressMovable: true,
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount =
                params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            if (params.data.tax) {
              totalAmountOfColumn =
                params.data.tax * 0.01 * totalAmountOfColumn +
                totalAmountOfColumn;
            }
            setTimeout(() => {
              this.calculateSubtotal();
            }, 100);
          }
          return totalAmountOfColumn
            ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
            : "";
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <div className="cursor-pointer">
              <Popover
                content={content}
                title={
                  <div className="text-center">
                    {" "}
                    <FormattedMessage
                      id="common.addRemove"
                      defaultMessage=""
                    />{" "}
                  </div>
                }
                placement="left"
                trigger="click"
              >
                <i className="fa fa-bars" />
              </Popover>
            </div>
          );
        },
        field: "action",
        colId: "action_1",
        pinned: "right",
        cellRenderer: this.props.isShipmentConversion
          ? "ActionLock"
          : "customActionEditor",
        minWidth: 50,
        maxWidth: 50,
        suppressMovable: true,
        lockPosition: true,
        suppressNavigable: true,
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              if (params.api.rowRenderer.lastRenderedRow > 0) {
                // const sodata = params.api.getDisplayedRowAtIndex(params.node.rowIndex).data
                // const selectedSalesOrder = this.props.selectedSalesOrder;
                params.api.updateRowData({
                  remove: [
                    params.api.getDisplayedRowAtIndex(params.node.rowIndex)
                      .data,
                  ],
                });
                // selectedSalesOrder.forEach((soDetailData) => {
                //     if (sodata.salesOrderMasterId === soDetailData.salesOrderMasterId) {
                //         const currentDetailData = [];
                //         this["gridApi-" + sodata.salesOrderMasterId].forEachNode((rowNode) => {
                //             currentDetailData.push(rowNode.data);
                //         });
                //         soDetailData.customerSalesOrderDetailsList = currentDetailData;
                //     }
                // });
                // this.props.updateState({ selectedSalesOrder })
              }
              this.calculateSubtotal();
            },
          };
        },
      },
    ];

    const createInvoice = (isDraft) => {
      //let isOutOfStock = false;
      let totalItem = 0;
      // let isRequiredFiledMissing = false;
      let errorKey = "";
      let self = this;
      const isStopSalesInvoiceForOutOfStock = this.props.companyInfo?.isStopSalesInvoiceForOutOfStock || 0;
      if (
        this.props.isbankReco &&
        this.props.bankRecoBalance &&
        this.props.bankRecoBalance !== Number(this.state.total)
      ) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: "bankReco.createInvoice.validation",
            defaultMessage: "",
          }),
        });
      }

      if (
        this.props.isAllowPayOnline &&
        (!this.props.paymentGatewayName || !this.props.paymentGatewayId)
      ) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: "paymentGateway.notAvailable.message",
            defaultMessage: "",
          }),
        });
      }

      if (
        (!this.props.customer || !this.props.customer.customerId) &&
        !this.props.isCashSales
      ) {
        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: "missingCustomer.errorMsg",
            defaultMessage: "",
          }),
        });
      }
      if (
        this.props.customerPo?.length && !this.props.validCustomerPoNumber
      ) {
        return showToasterMessage({
          messageType: "error",
          description: "Customer Po # is already in use",
        });
      }
      if (this.props.isManualInvoiceNumber  && !this.props.manualInvoiceNumber?.length) {
        return showToasterMessage({
          messageType: "error",
          description: "Customer Invoice # is required",
        });
      }

      if (
        this.props.manualInvoiceNumber?.length && !this.props.validManualInvoiceNumber
      ) {
        return showToasterMessage({
          messageType: "error",
          description: "Duplicate Inv #",
        });
      }
      if (
        this.props.selectedSalesOrder &&
        this.props.selectedSalesOrder.length > 0
      ) {
        this.props.selectedSalesOrder.forEach((soData) => {
          soData.customerSalesOrderDetailsList.forEach((data, index) => {

            totalItem++;
            delete data.minHeight;
            if (!data.product) {
              delete data.product;
            }
            // if (data.isOutOfStock) {
            //     isOutOfStock = data.isOutOfStock;
            // }
            // if ((Object.keys(data || {}).length || index === 0) && ((!data.product && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0)))) {
            //     isRequiredFiledMissing = true;
            // }
            if (index === 0 && !data.product && !data.description) {
              errorKey = "missingProduct.errorMsg";
            } else if (index === 0 && !Number(data.qty || 0)) {
              errorKey = "missingQty.errorMsg";
            }
            // else if (data){

            // }
            else if (index === 0 && !Number(data.rate || 0)) {
              errorKey = "missingRate.errorMsg";
            }
          });
        });
      }else if(  this.props.selectedAmendSalesOrder &&
        this.props.selectedAmendSalesOrder.length > 0
      ){
        this.props.selectedAmendSalesOrder.forEach((soData) => {
          soData.customerAmendSalesOrderDetailsList.forEach((data, index) => {

            totalItem++;
            delete data.minHeight;
            if (!data.product) {
              delete data.product;
            }
            // if (data.isOutOfStock) {
            //     isOutOfStock = data.isOutOfStock;
            // }
            // if ((Object.keys(data || {}).length || index === 0) && ((!data.product && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0)))) {
            //     isRequiredFiledMissing = true;
            // }
            if (index === 0 && !data.product && !data.description) {
              errorKey = "missingProduct.errorMsg";
            } else if (index === 0 && !Number(data.qty || 0)) {
              errorKey = "missingQty.errorMsg";
            }
            // else if (data){

            // }
            else if (index === 0 && !Number(data.rate || 0)) {
              errorKey = "missingRate.errorMsg";
            }
          });
        });
      } else {
        this.gridApi.forEachNode((rowNode, index) => {
          totalItem++;
          const { data } = rowNode;
          delete data.minHeight;
          if (!data.product) {
            delete data.product;
          }
          if (data.isOutOfStock && isStopSalesInvoiceForOutOfStock) {
            //  isOutOfStock = data.isOutOfStock;
            errorKey = "product.outOfStock.errorMsg";
          }
          // if ((Object.keys(data || {}).length || index === 0) && ((!data.product && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0)))) {
          //     isRequiredFiledMissing = true;
          // }

          if (index === 0 && !data.product && !data.description) {
            errorKey = "missingProduct.errorMsg";
          } else if (index === 0 && !Number(data.qty || 0)) {
            errorKey = "missingQty.errorMsg";
          }

          else if (((this.props.companyInfo || {}).countryName === "India") && (!data.hsnCode || !(data?.hsnCode?.length === 4 || data?.hsnCode?.length === 6 || data?.hsnCode?.length === 8))) {
            errorKey = "missingHsncode.errorMsg"
          }
          else if (index === 0 && !Number(data.rate || 0)) {
            errorKey = "missingRate.errorMsg";
          }
        });
        // this.props.customerSalesOrderDetailsList.forEach((data, index) => {
        //     if (data.isOutOfStock) {
        //         isOutOfStock = data.isOutOfStock;
        //     }
        //     if ((Object.keys(data || {}).length || index === 0) && ((!data.product && !data.description) || !data.rate || !data.qty)) {
        //         isRequiredFiledMissing = true;
        //     }

        // })
      }
      // this.gridApi.forEachNode((rowNode, index) => {
      //     const { data } = rowNode;
      //     if (data.isOutOfStock) {
      //         isOutOfStock = data.isOutOfStock;
      //     }
      //     if ((Object.keys(data || {}).length || index === 0) && (!data.product || !data.rate || !data.qty)) {
      //         isRequiredFiledMissing = true;
      //     }
      // });

      // if (isOutOfStock && !isDraft) {
      //     this.gridApi.refreshCells({ force: true })
      //     return showToasterMessage({
      //         messageType: 'error', description: 'Selected product is out of stock'
      //     });
      // } else

      // if (!totalItem || (isRequiredFiledMissing && !isDraft) || (!this.props.isCashSales && !(this.props.customer && this.props.customer.customerId))) {
      //     return showToasterMessage({
      //         messageType: 'error', description: 'Please select Customer, Product/Description, Quantity and Rate'
      //     });
      // }

      if (!totalItem || (errorKey && !isDraft)) {

        return showToasterMessage({
          messageType: "error",
          description: this.props.intl.formatMessage({
            id: errorKey,
            defaultMessage: "",
          }),
        });
      }

      const modalData = {
        modalBody: (
          <FormattedMessage
            id="customer.salesInvoice.form.confirmation"
            defaultMessage=""
          />
        ),
        handleSubmit: () => {
          this.props.hideModal();
          const payload = {
            props: this.props,
            gridApis: this.gridApis,
            isPlaceOfSupplySameAsRelationship:
              isPlaceOfSupplySameAsRelationship,
            state: this.state,
            gridApi: this.gridApi,
            gridObject: this.gridApi,
            grandTotal: this.getGrandTotal(),
            isDraft: isDraft,
          };

          if (isDraft) {
            this.props.createSalesInvoiceDraft(payload);
          } else {
            if (
              self.props.customerPaymentTerm &&
              (self.props.customerPaymentTerm.termName === "Cash on Delivery" ||
                self.props.customerPaymentTerm.termName ===
                "Payment in Advance")
            ) {
              const modalData2 = {
                modalBody: (
                  <FormattedMessage
                    id="invoice.markAsPaid.confirmation"
                    defaultMessage=""
                  />
                ),
                footer: { cancel: { intlId: "no" }, submit: { intlId: "yes" } },
                isStack: true,
                handleSubmit: () => {
                  self.props.createSalesInvoice(payload, true);
                },
                onCancel: () => {
                  self.props.createSalesInvoice(payload);
                  self.props.popModalFromStack(modalData2);
                },
              };
              self.props.pushModalToStack(modalData2);
            } else {
              self.props.createSalesInvoice(payload);
            }
          }
        },
      };
      this.props.showModal(modalData);
    };

    if (this.props.toUpdatePriceList) {
      this.gridApi.refreshCells({ force: true });
      this.props.updateState({ toUpdatePriceList: false });
    }

    const getRowHeight = (params) => {
      const rowHeight = params.node.data.scrollHeight
        ? Number(params.node.data.scrollHeight.split("px")[0]) - 30
        : 30;
      return rowHeight < 30 ? 30 : rowHeight;
    };

    // const saveColumnIndex = (columnState) => {
    //     let data = this.props.txPreferences || {};
    //     let gridPreference = {};
    //     data.relationshipId = (this.props.companyInfo || {}).relationshipId;
    //     if (data.tablePreference) {
    //         gridPreference = JSON.parse(data.tablePreference);
    //     }
    //     gridPreference.columnSetting = columnState;
    //     gridPreference.columnList = this.props.txColumns;
    //     data.gridPreference = JSON.stringify(gridPreference);

    //     this.props.saveUserPrefrences(data);
    // }

    const saveColumnWidth = (columnState, width) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data, this.props);
    };

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
      const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
      event.api.resetRowHeights();
      //const gridApi = tablegridApi || this.gridApi;
      if (
        event.type === "columnResized" &&
        event.source !== "sizeColumnsToFit" &&
        event.source !== "api" &&
        event.finished === true
      ) {
        if (
          gridColumnApi &&
          gridColumnApi.columnController.bodyWidth <
          gridColumnApi.columnController.scrollWidth
        ) {
          this.gridApi.sizeColumnsToFit();
          // const columnArray = event.columnApi.getColumnState();
          // for (let i = (columnArray.length - 1); i >= 0; i--) {
          //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
          //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
          //         break;
          //     }
          // }
          // saveColumnWidth(columnArray);
          setTimeout(() => {
            const columnArray = event.columnApi.getColumnState();
            saveColumnWidth(columnArray);
          }, 1000);
        } else {
          saveColumnWidth(event.columnApi.getColumnState());
        }
      }
    };
    return (
      <Fragment>
        <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>
          {
            <>

              <div className="transaction-table">
                {this.props.txColumns.length ? (
                  <CustomAgGrid
                    getRowHeight={getRowHeight}
                    onColumnResized={onColumnResized}
                    // onDragStopped={() => {
                    //     onColumnMoved({}, true);
                    // }}
                    ////onColumnMoved={onColumnMoved}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    onModelUpdated={onModelUpdated}
                    rowData={this.props.customerSalesOrderDetailsList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [{}]}
                    gridStyle={{
                      width: "100%",
                      height: "100%",
                      marginBottom: "10px",
                    }}
                  />
                ) : null}
              </div>

              <div style={{ display: "flex", gap: "10px" }}>
                {!this.props.isSoConversionToInvoice &&
                  !this.props.isShipmentConversion ? (
                  <Button
                    className="grey-button"
                    onClick={() => {
                      this.gridApi.updateRowData({ add: [{}] });
                    }}
                  >
                    <FormattedMessage
                      id="customer.salesInvoice.form.button.addLine"
                      defaultMessage=""
                    />
                  </Button>
                ) : (
                  ""
                )}
                {!this.props.isSoConversionToInvoice &&
                  !this.props.isShipmentConversion ? (
                  <Button
                    className="grey-button"
                    onClick={() => {
                      this.props.showModal({
                        modalBody: "Are you sure you want to clear?",
                        handleSubmit: () => {
                          let gridApi = this.gridApi;
                          let self = this;
                          this.gridApi.forEachNode(function (rowNode, index) {
                            gridApi.updateRowData({ remove: [rowNode.data] });
                            if (gridApi.rowRenderer.lastRenderedRow < 0) {
                              gridApi.updateRowData({ add: [{}] });
                            }
                            self.calculateSubtotal();
                          });
                        },
                      });
                    }}
                  >
                    <FormattedMessage
                      id="customer.salesInvoice.form.button.clearAllLines"
                      defaultMessage=""
                    />
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <div className="upload-btn-so">
                <CustomTableUpload
                  fileList={this.props.fileList}
                  onChange={(fileList) => {
                    this.props.updateState({
                      fileList,
                    });
                  }}
                />
              </div>
              {(this.props?.docDetailList||[])?.length ?
                <>


                  Previous Data

                  <AWSFileTable
                    {...this.props}
                    docDetailList={this.props.docDetailList}
                    fetchTxDetail={(data) => {
                      const val = this.props.salesInvoiceEditDetail
                      val.docDetailList = []
                      // this.props.updateState({
                      //   salesInvoiceEditDetail: val
                      // })
                      this.props.fetchSalesInvoiceDetail({
                        customerId: this.props.salesInvoiceEditDetail.customerId,
                        invoiceMasterId: this.props.salesInvoiceEditDetail.invoiceMasterId,
                        relationshipId: this.props.salesInvoiceEditDetail.relationshipId
                      })
                    }}
                  />
                </>
                : ""}
              <Divider style={{ margin: "10px 0px" }} />
              <Row>
                <Col span={12}>
                  <div className="remarks-title">
                    <FormattedMessage
                      id="customer.salesInvoice.form.bankDetails.label"
                      defaultMessage=""
                    />
                  </div>

                  <MultipleSelectDropdown
                    style={{ width: "60%" }}
                    items={(this.props.companyInfo || {}).bankDetails || []}
                    placeholder="Bank Details"
                    mode="multiple"
                    onDeSelect={(value) => {
                      let { selectedBankDetails } = this.props;
                      selectedBankDetails = filter(
                        selectedBankDetails,
                        (taxId) => {
                          return taxId !== value;
                        }
                      );
                      this.props.updateState({ selectedBankDetails });
                    }}
                    valueKeyName="name"
                    addressLineKey1="accountNumber"
                    // addressLineKey2='ifscCode'
                    selectedValue={this.props.selectedBankDetails || []}
                    optionKeyName="bankDetailId"
                    onSelect={(bankDetailId) => {
                      let selectedBankDetails =
                        this.props.selectedBankDetails || [];
                      if (selectedBankDetails.indexOf(bankDetailId) === -1) {
                        selectedBankDetails.push(bankDetailId);
                      } else {
                        selectedBankDetails = selectedBankDetails.filter((el) => {
                          return el !== selectedBankDetails;
                        });
                      }
                      this.props.updateState({
                        selectedBankDetails,
                      });
                    }}
                  />

                  <div>
                    <div className="remarks-title">
                      <div style={{ marginTop: "10px" }}>
                        <Tooltip
                          placement="right"
                          title={this.props.intl.formatMessage({
                            id: "tooltip.onlinePaymnet",
                            defaultMessage: "",
                          })}
                        >
                          <span className="tooltip-title">
                            <FormattedMessage
                              id="payment.gateway"
                              defaultMessage=""
                            />
                          </span>
                        </Tooltip>
                        <Checkbox
                          style={{ marginLeft: "20px" }}
                          checked={this.props.isAllowPayOnline}
                          onChange={() => {
                            this.props.updateState({
                              isAllowPayOnline: !this.props.isAllowPayOnline,
                            });
                          }}
                        >
                          {" "}
                        </Checkbox>
                      </div>
                      {this.props.isAllowPayOnline ? (
                        <span>
                          <div className="remarks-title">
                            <FormattedMessage
                              id="select.gateway"
                              defaultMessage=""
                            />
                            <img
                              src={cardlogo}
                              alt=""
                              style={{ marginLeft: "10px" }}
                            />
                          </div>

                          <Select
                            style={{ width: "60%" }}
                            items={this.props.allPaymentGatewayConfig || []}
                            value={this.props.paymentGatewayName}
                            onSelect={(selectedId, obj) => {
                              this.props.updateState({
                                paymentGatewayId: obj.key,
                                paymentGatewayName: obj.value,
                              });
                            }}
                            allowClear={true}
                            onClear={() => {
                              this.props.updateState({
                                paymentGatewayId: undefined,
                                paymentGatewayName: undefined,
                              });
                            }}
                          >
                            {(this.props.allPaymentGatewayConfig || []).map(
                              (gatway) => {
                                return (
                                  <Option
                                    key={gatway.alpidePaymentGatewayId}
                                    value={gatway.paymentGatewayName}
                                  >
                                    <FormattedMessage
                                      id={gatway.paymentGatewayName}
                                    />
                                  </Option>
                                );
                              }
                            )}
                          </Select>

                          <Tooltip
                            placement="top"
                            title={this.props.intl.formatMessage({
                              id: "tooltip.onlinePaymnet.help",
                              defaultMessage: "",
                            })}
                            trigger="click"
                          >
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                          </Tooltip>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="remarks-title">
                      <FormattedMessage
                        id="customer.salesInvoice.form.internalRemarks.label"
                        defaultMessage=""
                      />
                    </div>
                    <CKEditor
                      type="inline"
                      className="description-textarea"
                      key={`${"internalRemarks"}`}
                      data={this.props.internalRemarks}
                      onInit={(editor) => {
                        this.editor = editor;
                      }}
                      onChange={(event) => {
                        const data = event.editor.getData();
                        this.props.updateState({ internalRemarks: data });
                      }}
                      config={CUSTOM_CONFIGURATION}
                    />
                  </div>

                  <div className="remarks-title">
                    <FormattedMessage
                      id="customer.salesInvoice.form.customerRemarks.label"
                      defaultMessage=""
                    />
                  </div>

                  {
                    <CKEditor
                      type="inline"
                      className="description-textarea"
                      key={`${"customerRemarks"}`}
                      data={this.props.customerRemarks}
                      onInit={(editor) => {
                        this.editor = editor;
                      }}
                      onChange={(event) => {
                        const data = event.editor.getData();
                        this.props.updateState({ customerRemarks: data });
                      }}
                      config={CUSTOM_CONFIGURATION}
                    />
                  }
                </Col>

                <Col span={7} offset={5}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Row gutter={[16, 8]}>
                        <Col>
                          <Checkbox
                            checked={this.props.isRootDiscount}
                            onChange={() => {
                              this.props.updateState({
                                isRootDiscount: !this.props.isRootDiscount,
                              });
                            }}
                          >
                            <FormattedMessage
                              id="customer.salesOrder.form.totalDiscount.label"
                              defaultMessage=""
                            />
                          </Checkbox>
                        </Col>
                        {this.props.isRootDiscount ? (
                          <>
                            {this.state.rootDiscountList &&
                              this.state.rootDiscountList.length > 0
                              ? (this.state.rootDiscountList || []).map(
                                (rootDiscount, i) => {
                                  return (
                                    <>
                                      {/* <Col>
                                                                <span className="label" ><FormattedMessage id='supplier.purchaseOrder.form.totalDiscount.label' defaultMessage='' /></span>
                                                                <Tooltip placement="right" title={<FormattedMessage id='tooltip.rootDiscount' defaultMessage='' />} trigger="click">
                                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                                </Tooltip>
                                                            </Col> */}

                                      <Col span={24}>
                                        <span
                                          className="mark"
                                          style={{ left: "7px", width: "36px" }}
                                          title="If checked discount will be taxable."
                                        >
                                          <Checkbox
                                            // value={rootDiscount.isTaxable}
                                            checked={rootDiscount.isTaxable ? true : false}
                                            onClick={(e) => {
                                              rootDiscount.isTaxable = e.target
                                                .checked
                                                ? 1
                                                : 0;
                                              let rootDiscountList =
                                                this.state.rootDiscountList;
                                              rootDiscountList[i] = rootDiscount;
                                              this.setState({
                                                rootDiscountList: rootDiscountList,
                                              });
                                              if (this.state.subtotal) {
                                                this.calculateSubtotal();
                                              }
                                            }}
                                          ></Checkbox>
                                        </span>
                                        <Input
                                          style={{
                                            width: "100%",
                                            paddingLeft: "40px",
                                          }}
                                          type="number"
                                          placeholder="0.00"
                                          value={
                                            rootDiscount.rootDiscountPercent || ""
                                          }
                                          onChange={(e) => {
                                            rootDiscount.rootDiscountPercent =
                                              e.target.value;
                                            let rootDiscountList =
                                              this.state.rootDiscountList;
                                            rootDiscountList[i] = rootDiscount;
                                            this.setState({
                                              rootDiscountList: rootDiscountList,
                                            });
                                          }}
                                          onBlur={(e) => {
                                            if (this.state.subtotal) {
                                              this.calculateSubtotal();
                                            }
                                          }}
                                        />
                                        {/* <span className="mark" style={{position:'absolute', right:'0px', left:'auto'}}>%</span> */}
                                        <div
                                          className="right1"
                                          style={{ right: "8px", top: "4px" }}
                                        >
                                          %
                                        </div>
                                      </Col>

                                      {/* { rootDiscount.rootDiscountAmount ? <span>{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}{(Number(rootDiscount.rootDiscountAmount) + Number(rootDiscount.taxAmount||0))|| ""}</span>: ''}  */}

                                      {/* <div className="input-label-control" style={{width: '170px'}}>
                                                                        <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                        <Input
                                                                            disabled
                                                                            placeholder="0.00"
                                                                            type='number'
                                                                            value={rootDiscount.rootDiscountAmount || ""}
                                                                            onChange={(e) => {
                                                                                rootDiscount.rootDiscountAmount= Number(e.target.value);
                                                                                let rootDiscountList = this.state.rootDiscountList;
                                                                                rootDiscountList[i] = rootDiscount;
                                                                                this.setState({
                                                                                    rootDiscountList: rootDiscountList
                                                                                })
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                if (this.state.subtotal) {
                                                                                    this.calculateSubtotal();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div> */}

                                      {/* <Col span={3}>
                                                                <div className="input-group" style={{padding: '5px', width:'100%'}}>
                                                                    <Dropdown
                                                                        items={this.props.taxes}
                                                                        value={rootDiscount.taxName}
                                                                        placeholder={this.props.intl.formatMessage({ id: 'customer.salesQuote.form.gridHeader.tax' })}
                                                                        onSelect={(selectedOption, option) => {
                                                                            const selectedTax = find(this.props.taxes, { taxSingleRateId: Number(option.key) });
                                                                            rootDiscount.taxPercent = selectedTax.taxPercent;
                                                                            rootDiscount.taxName = selectedTax.taxName;
                                                                            rootDiscount.taxId= option.key;
                                                                            let rootDiscountList = this.state.rootDiscountList;
                                                                            rootDiscountList[i] = rootDiscount;
                                                                            this.setState({
                                                                                rootDiscountList: rootDiscountList
                                                                            });
                                                                            if (this.state.subtotal) {
                                                                                this.calculateSubtotal();
                                                                            }
                                                                        }}

                                                                        valueKeyName='taxNameDisplay'
                                                                        optionKeyName='taxSingleRateId'
                                                                        allowClear={true}
                                                                        onClear={() => {
                                                                            rootDiscount.tax = null;
                                                                            rootDiscount.taxName = null;
                                                                            let rootDiscountList = this.state.rootDiscountList;
                                                                            rootDiscountList[i] = rootDiscount;
                                                                            this.setState({
                                                                                rootDiscountList: rootDiscountList
                                                                            })
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col> */}
                                      <Col span={24}>
                                        <div
                                          className="price-box"
                                          style={{
                                            marginLeft: "0px",
                                            marginRight: "0px",
                                            padding: "0px",
                                          }}
                                        >
                                          <div className="icons1">
                                            <i class="fa fa-rupee"></i>
                                          </div>
                                          {rootDiscount.rootDiscountAmount ? (
                                            <span style={{ paddingLeft: '50px' }}>
                                              {(this.props.companyInfo || {})
                                                .currencyIcon ? (
                                                <i
                                                  className={
                                                    (this.props.companyInfo || {})
                                                      .currencyIcon
                                                  }
                                                ></i>
                                              ) : (
                                                (this.props.companyInfo || {})
                                                  .currencyCode + " "
                                              )}
                                              {Number(
                                                rootDiscount.rootDiscountAmount
                                              ) +
                                                Number(
                                                  rootDiscount.taxAmount || 0
                                                ) || ""}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Col>
                                      <Col span={24}>
                                        <Dropdown
                                          items={this.props.allLedgerAccounts}
                                          value={rootDiscount.ledgerAccountName}
                                          placeholder={this.props.intl.formatMessage(
                                            {
                                              id: "selectLedger.text",
                                              defaultMessage: "",
                                            }
                                          )}
                                          onSelect={(selectedOption, option) => {
                                            rootDiscount.chartOfAccountDetailsId =
                                              selectedOption;
                                            rootDiscount.ledgerAccountName =
                                              option.name;
                                            let rootDiscountList =
                                              this.state.rootDiscountList;
                                            rootDiscountList[i] = rootDiscount;
                                            this.setState({
                                              rootDiscountList: rootDiscountList,
                                            });
                                          }}
                                          valueKeyName="ledgerAccountName"
                                          optionKeyName="chartOfAccountDetailsId"
                                          addressLineKey1="accountingEntry"
                                          allowClear={true}
                                          onClear={() => {
                                            rootDiscount.chartOfAccountDetailsId =
                                              null;
                                            rootDiscount.ledgerAccountName = null;
                                            let rootDiscountList =
                                              this.state.rootDiscountList;
                                            rootDiscountList[i] = rootDiscount;
                                            this.setState({
                                              rootDiscountList: rootDiscountList,
                                            });
                                          }}
                                          canAddNew={true}
                                          onAddNewClick={(props) => {
                                            this.newLedgerAccounts(this.props);
                                          }}
                                        />
                                      </Col>
                                      <Col>
                                        <i
                                          className="fa fa-trash fa-lg cursor-pointer"
                                          onClick={() => {
                                            let rootDiscountList =
                                              this.state.rootDiscountList;
                                            if (
                                              rootDiscountList &&
                                              rootDiscountList.length > 1
                                            ) {
                                              rootDiscountList.splice(i, 1);
                                            } else {
                                              rootDiscountList = [{}];
                                            }
                                            this.setState({
                                              rootDiscountList: rootDiscountList,
                                            });
                                            if (this.state.subtotal) {
                                              setTimeout(()=>{
                                                this.calculateSubtotal();
                                              },500)
                                            }
                                          }}
                                          type="delete"
                                        />
                                      </Col>
                                    </>
                                  );
                                }
                              )
                              : ""}
                            {this.state.rootDiscountList.length < 5 && (
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                  marginTop: "-15px",
                                }}
                                className="mb20, mr10"
                              >
                                <span
                                  className="cursor-pointer mr60 pr10"
                                  onClick={() => {
                                    let discountList = this.state.rootDiscountList;
                                    discountList.push({});
                                    this.setState({ rootDiscountList: discountList });

                                  }}
                                >
                                  <FormattedMessage id="add" />{" "}
                                  {/* <FormattedMessage id="modal.txDetails.common.discount" /> */}
                                </span>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Col>

                    <Col span={12}>
                      <Row gutter={[16, 8]}>
                        <Col span={24}>
                          <Checkbox
                            checked={this.props.isRootExpense}
                            onChange={() => {
                              this.props.updateState({
                                isRootExpense: !this.props.isRootExpense,
                              });
                            }}
                          >
                            <FormattedMessage
                              id="customer.salesInvoice.form.checkbox.expense"
                              defaultMessage=""
                            />
                          </Checkbox>
                        </Col>
                        {this.props.isRootExpense ? (
                          <>
                            {this.props.rootExpenseList &&
                              this.props.rootExpenseList.length > 0
                              ? (this.props.rootExpenseList || []).map(
                                (rootExpense, i) => {
                                  return (
                                    <>
                                      {/*                                                     
                                                        <Col span={7} className="pt5">
                                                            <FormattedMessage id='supplier.purchaseOrder.form.totalExpense.label' defaultMessage='' />
                                                        </Col> */}

                                      {/* <Col span={16}>
                                                             <Dropdown
                                                    style={{ width: '100%' }}
                                                    items={this.props.expenseTypes}
                                                    valueKeyName='expenseType'
                                                    value={this.props.expenseId}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({
                                                            expenseId: undefined,
                                                            expensName: undefined
                                                        })
                                                    }}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            textBoxValue: payload.textEntered,
                                                            submittedOnce: false
                                                        }
                                                        this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                                                    }}
                                                    onSelect={(expenseId) => {
                                                        const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                        this.props.updateState({
                                                            expenseId,
                                                            expensName: selectedExpense.expenseType

                                                        })
                                                    }}
                                                    optionKeyName='lkExpenseTypeId'
                                                /> 
                                                </Col>*/}

                                      <Col span={24}>
                                        <div className="input-group">
                                          <div
                                            className="input-label-control"
                                            style={{
                                              width: "100%",
                                              position: "relative",
                                            }}
                                          >
                                            <span
                                              className="mark"
                                              style={{ height: "32px" }}
                                            >
                                              {(this.props.companyInfo || {})
                                                .currencyIcon ? (
                                                <i
                                                  className={
                                                    (this.props.companyInfo || {})
                                                      .currencyIcon
                                                  }
                                                ></i>
                                              ) : (
                                                (this.props.companyInfo || {})
                                                  .currencyCode + " "
                                              )}
                                            </span>
                                            <Input
                                              style={{ height: "32px" }}
                                              placeholder="0.00"
                                              value={rootExpense.expenseAmount}
                                              type="number"
                                              onChange={(e) => {
                                                rootExpense.expenseAmount = Number(
                                                  e.target.value
                                                );

                                                let rootExpenseList =
                                                  this.props.rootExpenseList;
                                                rootExpenseList[i] = rootExpense;
                                                this.setState(
                                                  {
                                                    rootExpenseList:
                                                      rootExpenseList,
                                                  },
                                                  () => {
                                                    if (this.state.subtotal) {
                                                      this.calculateSubtotal();
                                                    }
                                                  }
                                                );

                                                this.props.updateState({
                                                  rootExpenseList: rootExpenseList,
                                                });

                                                // this.setState({
                                                //     expenseAmount: Number(e.target.value)
                                                // },
                                              }}
                                            />
                                          </div>

                                          {/* <span>
                                                            {(rootExpense.selectedExpenseLedger && rootExpense.selectedExpenseLedger.isLedgerTaxable && rootExpense.expenseLedgerAccountTaxRate && rootExpense.expenseAmount) ? <span>
                                                                <span className="label">Tax </span> {Number((rootExpense.expenseAmount * (rootExpense.expenseLedgerAccountTaxRate || 0)) / 100).toFixed(2)}
                                                            </span>
                                                                : ''
                                                            }
                                                        </span> */}
                                        </div>
                                      </Col>

                                      <Col span={24}>
                                        {" "}
                                        <div
                                          className="price-box1"
                                          style={{ margin: "0px", padding: "0px" }}
                                        >
                                          <div className="icons2">Tax Amt.</div>
                                          <div
                                            style={{ paddingLeft: '70px' }}
                                          >
                                            {rootExpense.selectedExpenseLedger &&
                                              rootExpense.selectedExpenseLedger
                                                .isLedgerTaxable &&
                                              rootExpense.expenseLedgerAccountTaxRate &&
                                              rootExpense.expenseAmount ? (
                                              <span>
                                                {Number(
                                                  (rootExpense.expenseAmount *
                                                    (rootExpense.expenseLedgerAccountTaxRate ||
                                                      0)) /
                                                  100
                                                ).toFixed(2)}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>{" "}
                                      </Col>
                                      <Col span={24}>
                                        <Dropdown
                                          items={this.props.allLedgerAccounts}
                                          value={
                                            rootExpense.ledgerAccountName
                                          }
                                          placeholder={this.props.intl.formatMessage(
                                            {
                                              id: "selectLedger.text",
                                              defaultMessage: "",
                                            }
                                          )}
                                          onSelect={(selectedOption, option) => {
                                            const selectedExpenseLedger = find(
                                              this.props.allLedgerAccounts,
                                              {
                                                chartOfAccountDetailsId:
                                                  Number(selectedOption),
                                              }
                                            );
                                            // this.setState({
                                            //     expenseLedgerAccountId: selectedOption,
                                            //     expenseLedgerAccountName: option.name,
                                            //     expenseLedgerAccountTaxRate: selectedExpenseLedger.taxSingleRatePercent || 0,
                                            //     selectedExpenseLedger
                                            // });
                                            rootExpense.chartOfAccountDetailsId =
                                              selectedOption;
                                            rootExpense.ledgerAccountName =
                                              option.name;
                                            rootExpense.expenseLedgerAccountTaxRate =
                                              selectedExpenseLedger.taxSingleRatePercent ||
                                              0;
                                            rootExpense.selectedExpenseLedger =
                                              selectedExpenseLedger;

                                            let rootExpenseList =
                                              this.props.rootExpenseList;
                                            rootExpenseList[i] = rootExpense;
                                            this.setState({
                                              rootExpenseList: rootExpenseList,
                                            });
                                            setTimeout(()=>{
                                              this.calculateSubtotal();
                                            },500)
                                            this.props.updateState({
                                              rootExpenseList: rootExpenseList,
                                            });

                                          }}
                                          valueKeyName="ledgerAccountName"
                                          optionKeyName="chartOfAccountDetailsId"
                                          addressLineKey1="accountingEntry"
                                          allowClear={true}
                                          onClear={() => {
                                            // this.setState({
                                            //     expenseLedgerAccountId: null,
                                            //     expenseLedgerAccountName: null,
                                            //     selectedExpenseLedger: null,
                                            //     expenseLedgerAccountTaxRate: null,
                                            // });
                                            rootExpense.chartOfAccountDetailsId =
                                              null;
                                            rootExpense.ledgerAccountName = null;
                                            rootExpense.expenseLedgerAccountTaxRate =
                                              null;
                                            rootExpense.selectedExpenseLedger =
                                              null;

                                            let rootExpenseList =
                                              this.props.rootExpenseList;
                                            rootExpenseList[i] = rootExpense;
                                            this.setState({
                                              rootExpenseList: rootExpenseList,
                                            });

                                            this.props.updateState({
                                              rootExpenseList: rootExpenseList,
                                            });
                                          }}
                                          canAddNew={true}
                                          onAddNewClick={(props) => {
                                            this.newLedgerAccounts(this.props);
                                          }}
                                        />
                                      </Col>
                                      <Col span={24}>
                                        <i
                                          className="fa fa-trash fa-lg cursor-pointer"
                                          onClick={() => {
                                            let rootExpenseList =
                                              this.props.rootExpenseList;
                                            if (
                                              rootExpenseList &&
                                              rootExpenseList.length > 1
                                            ) {
                                              rootExpenseList.splice(i, 1);
                                            } else {
                                              rootExpenseList = [{}];
                                            }
                                            this.setState({
                                              rootExpenseList: rootExpenseList,
                                            });
                                            if (this.state.subtotal) {
                                              setTimeout(()=>{
                                                this.calculateSubtotal();
                                              },500)
                                            }

                                            this.props.updateState({
                                              rootExpenseList: rootExpenseList,
                                            });
                                          }}
                                          type="delete"
                                        />
                                      </Col>

                                      {/* <Row type="flex" justify="end" className="mb20">
                                                        <Col span={8}>
                                                            <span className="label"><FormattedMessage id='supplier.purchaseOrder.form.totalExpenseAmount.label' defaultMessage='' /></span>
                                                        </Col>

                                                        <Col span={8}>
                                                            <div className="input-group">
                                                                <div className="input-label-control">
                                                                    <span className="mark">
                                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                    </span>
                                                                    <Input
                                                                        placeholder="0.00"
                                                                        value={rootExpense.expenseAmount}
                                                                        type='number'
                                                                        onChange={(e) => {
                                                                            rootExpense.expenseAmount = Number(e.target.value);
        
                                                                            let rootExpenseList = this.state.rootExpenseList;
                                                                            rootExpenseList[i] = rootExpense;
                                                                            this.setState({
                                                                                rootExpenseList: rootExpenseList
                                                                            },() => {
                                                                                if (this.state.subtotal) {
                                                                                    this.calculateSubtotal();
                                                                                }
                                                                            });
                                                                            
                                                                            // this.setState({
                                                                            //     expenseAmount: Number(e.target.value)
                                                                            // },
                                                                             
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col span={8}>
                                                            {(rootExpense.selectedExpenseLedger && rootExpense.selectedExpenseLedger.isLedgerTaxable && rootExpense.expenseLedgerAccountTaxRate && rootExpense.expenseAmount) ? <div>
                                                                <span className="label">Tax </span> {Number((rootExpense.expenseAmount * (rootExpense.expenseLedgerAccountTaxRate || 0)) / 100).toFixed(2)}
                                                            </div>
                                                                : ''
                                                            }
                                                        </Col>
                                                    </Row> */}
                                    </>
                                  );
                                }
                              )
                              : ""}
                            {this.props.rootExpenseList?.length < 5 && (
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "right",
                                }}

                              >
                                <span
                                  onClick={() => {
                                    let ExpenseList = this.props.rootExpenseList;
                                    ExpenseList.push({});
                                    this.setState({ rootExpenseList: ExpenseList });

                                    this.props.updateState({
                                      rootExpenseList: ExpenseList,
                                    });
                                  }}
                                  className="cursor-pointer "
                                >
                                  <FormattedMessage id="add" />{" "}
                                  {/* <FormattedMessage id="supplier.purchaseOrder.form.totalExpense.label" /> */}
                                </span>
                              </div>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Col>
                  </Row>
                  {/* <div className="checkbox-control-group">
                                        {<Checkbox checked={this.props.isRootDiscount} onChange={() => {
                                            this.props.updateState({ isRootDiscount: !this.props.isRootDiscount })
                                        }}> <FormattedMessage id='customer.salesInvoice.form.checkbox.discount'
                                            defaultMessage='' /></Checkbox>}
                                        {false && <Checkbox onChange={() => {
                                            this.setState({ sectionVisible: { ...sectionVisible, tax: !sectionVisible.tax } })
                                        }}><FormattedMessage id='customer.salesInvoice.form.checkbox.tax'
                                            defaultMessage='' /></Checkbox>}
                                        <Checkbox checked={this.props.isRootExpense} onChange={() => {
                                            this.props.updateState({ isRootExpense: !this.props.isRootExpense })
                                        }}><FormattedMessage id='customer.salesInvoice.form.checkbox.expense'
                                            defaultMessage='' /></Checkbox>
                                    </div> */}

                  <div className="product-amount-values">
                    <Row>
                      <Col span={15}>
                        <FormattedMessage
                          id="customer.salesOrder.form.subTotal.label"
                          defaultMessage=""
                        />
                      </Col>
                      <Col span={9}>
                        {(this.props.companyInfo || {}).currencyIcon ? (
                          <i
                            className={
                              (this.props.companyInfo || {}).currencyIcon
                            }
                          ></i>
                        ) : (
                          (this.props.companyInfo || {}).currencyCode + " "
                        )}
                        {fixedDecimalAndFormateNumber(
                          this.state.subtotalWithoutDiscount
                        )}
                      </Col>
                    </Row>

                    {this.state.totalDiscount === "0.00" ||
                      this.state.totalDiscount === 0 ? null : (
                      <div className="amount-summary">
                        <Row>
                          <Col span={15}>
                            <span className="title">
                              <FormattedMessage
                                id="customer.salesInvoice.form.totalDiscount.label"
                                defaultMessage=""
                              />
                            </span>
                          </Col>
                          <Col span={9}>
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                            {fixedDecimalAndFormateNumber(
                              this.state.totalDiscount
                            )}
                          </Col>
                        </Row>
                      </div>
                    )}

                    {itemLevelTaxes.length === 0
                      ? null
                      : itemLevelTaxes.map((taxObj, i) => {
                        if (isPlaceOfSupplySameAsRelationship) {
                        }
                        return (this.props.companyInfo || {}).countryName ===
                          "India" ? (
                          isPlaceOfSupplySameAsRelationship ? (
                            <div key={i}>
                              <Row>
                                <Col span={15}>
                                  CGST @ {taxObj.taxPercent / 2}% on{" "}
                                  {taxObj.amountAfterCoupan > 0 ? <span>
                                    {fixedDecimalNumber(taxObj.amountAfterCoupan)}{" "}
                                  </span> : <span>
                                    {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                  </span>}
                                </Col>
                                <Col span={9}>
                                  {(this.props.companyInfo || {}).currencyIcon ? (
                                    <i
                                      className={
                                        (this.props.companyInfo || {}).currencyIcon
                                      }
                                    ></i>
                                  ) : (
                                    (this.props.companyInfo || {}).currencyCode +
                                    " "
                                  )}
                                  {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                                  {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                </Col>
                              </Row>

                              <Row>
                                <Col span={15}>
                                  <span className="title">
                                    SGST @ {taxObj.taxPercent / 2}% on{" "}
                                    {taxObj.amountAfterCoupan > 0 ? <span>
                                      {fixedDecimalNumber(taxObj.amountAfterCoupan)}{" "}
                                    </span> : <span>
                                      {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                    </span>}
                                  </span>
                                </Col>
                                <Col span={9}>
                                  {(this.props.companyInfo || {}).currencyIcon ? (
                                    <i
                                      className={
                                        (this.props.companyInfo || {}).currencyIcon
                                      }
                                    ></i>
                                  ) : (
                                    (this.props.companyInfo || {}).currencyCode +
                                    " "
                                  )}
                                  {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                                  {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div key={i}>
                              <Row>
                                <Col span={15}>
                                  <span className="title">
                                    IGST @ {taxObj.taxPercent}% on{" "}
                                    {taxObj.amountAfterCoupan > 0 ? <span>
                                      {fixedDecimalNumber(taxObj.amountAfterCoupan)}{" "}
                                    </span> : <span>
                                      {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                    </span>}
                                  </span>
                                </Col>
                                <Col span={9}>
                                  {(this.props.companyInfo || {}).currencyIcon ? (
                                    <i
                                      className={
                                        (this.props.companyInfo || {}).currencyIcon
                                      }
                                    ></i>
                                  ) : (
                                    (this.props.companyInfo || {}).currencyCode +
                                    " "
                                  )}
                                  {Number(taxObj.taxAmount || 0).toFixed(2)}
                                </Col>
                              </Row>
                            </div>
                          )
                        ) : (
                          <div key={i}>
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                                  {taxObj.amountAfterCoupan > 0 ? <span>
                                    {fixedDecimalNumber(taxObj.amountAfterCoupan)}{" "}
                                  </span> : <span>
                                    {fixedDecimalNumber(taxObj.subtotal)}{" "}
                                  </span>}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {}).currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode + " "
                                )}
                                {Number(taxObj.taxAmount || 0).toFixed(2)}
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    {
                      (this.props.roundingOffSettings && this.props.roundingOffSettings.isRoundingOffTotalAmtOnSalesInvoice && this.state.roundingOffAmt)
                        ?
                        <Row>
                          <Col span={15}>
                            <span className="title">
                              <FormattedMessage
                                id="common.salesRoundingOff.label"
                                defaultMessage=""
                              />
                            </span>
                          </Col>
                          <Col span={9}>
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                            {fixedDecimalAndFormateNumber(this.state.roundingOffAmt)}
                          </Col>
                        </Row>
                        : ''
                    }
                    {this.props.couponList && this.props.couponList[this.props.pageNumber || 1]?.length ?
                      <Row>
                        <Col span={10}>
                          COUPON
                        </Col>
                        {this.props.viewCoupon ? <Col style={{ position: "relative" }} span={14}>

                          <TextBox
                            value={this.props?.coupanValue}
                            onChange={(e) => {
                              this.props.updateState({
                                coupanValue: e.target.value
                              })
                            }}
                            style={{ paddingRight: "20px" }}

                          />
                          <div onClick={() => {
                            if (this.props.customerName) {
                              this.props.applieCoupon({
                                relationshipId:this.props.companyInfo?.relationshipId,
                                customerId:this.props.customer?.customerId,
                                couponCode:this.props.coupanValue
                              },(data)=>{
                                if(data?.isValid){
                                 let minVaidation =data?.coupon?.minPurchaseAmount ? this.props.couponSetting.isCoupanAppliedBeforeTax ? data?.coupon?.minPurchaseAmount <= this.state.subtotal : data?.coupon?.minPurchaseAmount <= this.state.total: true 
                                 if(minVaidation){
                                  showToasterMessage({
                                    description: 'Applied successfully.',
                                    messageType: 'success'
                                  })
                                   this.props.updateState({
                                     viewCoupon:false,
                                     coupondata:data,
                                   })
                                   this.setState({
                                     viewCoupon:false,
                                     coupondata:data,
                                   })
           
                                 this.calculateSubtotal();
                                 }else{
                                  showToasterMessage({
                                    messageType: "warning",
                                    description: "Add More Item To apply Coupon",
                                  }); 
                                 }
          
                                }else{
                                  showToasterMessage({
                                    messageType: "warning",
                                    description: "Invalid Coupon",
                                  });    
                                }
                              })
                            } else {
                              showToasterMessage({
                                messageType: "warning",
                                description: "Please Select Customer",
                              });
                            }

                          }} style={{ position: "absolute", right: "4px", top: "10px" }} className="cursor-pointer">
                            Apply
                          </div>
                        </Col> : <Col span={14} onClick={() => {
                          this.props.updateState({
                            viewCoupon: true
                          })
                        }} className="cursor-pointer">
                          {this.props.coupondata ?
                            <span style={{ display: "flex" }}>
                              {`${this.props.coupanValue ? `(${this.props.coupanValue})` : ''}  -${this.state?.totalCouponDiscount || 0} ` + " "}
                              <span className="" onClick={() => {
                                this.props.updateState({
                                  coupondata: null,
                                  viewCoupon: true,
                                  couponAmount: 0,
                                  totalCouponDiscount: 0
                                })
                                this.setState({
                                  coupondata: null,
                                  viewCoupon: true,
                                  couponAmount: 0,
                                  totalCouponDiscount: 0,
                                  // total:this.state.total + preCoupanAmt,
                                })
                                this.calculateSubtotal(true);

                              }}>Remove</span>
                            </span>
                            : "Apply"}
                        </Col>}
                      </Row> : ""}
                    <Row>
                      <Col span={15}>
                        <span className="title">
                          <FormattedMessage
                            id="customer.salesOrder.form.totalAmount.label"
                            defaultMessage=""
                          />
                        </span>
                      </Col>
                      <Col span={9}>
                        {(this.props.companyInfo || {}).currencyIcon ? (
                          <i
                            className={
                              (this.props.companyInfo || {}).currencyIcon
                            }
                          ></i>
                        ) : (
                          (this.props.companyInfo || {}).currencyCode + " "
                        )}
                        {fixedDecimalAndFormateNumber(this.state.total)}
                      </Col>
                    </Row>
                    {/* {
                                                this.props.isRootDiscount
                                                    ?
                                                    <Fragment>
                                                        <Col span={8}>
                                                            <span className="label"><FormattedMessage
                                                                id='customer.salesInvoice.form.totalDiscount.label'
                                                                defaultMessage='' /></span>
                                                        </Col>

                                                        <Col span={16}>
                                                            <div className="input-group">
                                                                <div className="input-label-control">
                                                                    <span className="mark">%</span>
                                                                    <Input
                                                                        type='number'
                                                                        placeholder="0.00"
                                                                        value={this.state.rootDiscountPercent || ""}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                rootDiscountPercent: e.target.value
                                                                            });
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (this.state.subtotal) {
                                                                                this.calculateSubtotal();
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="input-label-control">
                                                                    <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                    <Input
                                                                        disabled
                                                                        placeholder="0.00"
                                                                        type='number'
                                                                        value={this.state.rootDiscountAmount || ""}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                rootDiscountAmount: Number(e.target.value)
                                                                            });
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (this.state.subtotal) {
                                                                                this.calculateSubtotal();
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Fragment>
                                                    : ''
                                            } */}

                    {/* {
                                            sectionVisible.tax
                                                ? <Fragment>
                                                    <Row type="flex" justify="end">
                                                        <Col span={8}>
                                                            <FormattedMessage id='customer.salesInvoice.form.taxName.label'
                                                                defaultMessage='' />
                                                        </Col>

                                                        <Col span={16}>
                                                            <Dropdown
                                                                items={this.props.taxes}
                                                                value={this.props.taxName}
                                                                onSelect={(selectedTax) => {
                                                                    this.props.updateState({
                                                                        taxName: selectedTax
                                                                    })
                                                                }}
                                                                valueKeyName='description'
                                                                optionKeyName='taxSingleRateId'
                                                                allowClear={true}
                                                                onClear={() => {
                                                                    this.props.updateState({
                                                                        taxName: undefined,
                                                                    });
                                                                    this.calculateSubtotal();
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row type="flex" justify="end">
                                                        <Col span={8}>
                                                            <span className="label"><FormattedMessage
                                                                id='customer.salesInvoice.form.tax.label' defaultMessage='' /></span>
                                                        </Col>

                                                        <Col span={16}>
                                                            <div className="input-group">
                                                                <div className="input-label-control">
                                                                    <span className="mark">%</span>
                                                                    <Input placeholder="0.00" />
                                                                </div>
                                                                <div className="input-label-control">
                                                                    <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                                    <Input placeholder="0.00" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Fragment>
                                                : ''
                                        } */}

                    {/* {
                                            this.props.isRootExpense
                                                ? <Fragment>
                                                    <Row type="flex" className='amount-summary mb20' justify="end">
                                                        <Col span={8}>
                                                            <FormattedMessage id='customer.salesInvoice.form.totalExpense.label'
                                                                defaultMessage='' />
                                                        </Col>

                                                        <Col span={16}>

                                                            <MultipleSelectDropdown
                                                                style={{ width: '100%' }}
                                                                items={this.props.expenseTypes || []}
                                                                mode='multiple'
                                                                valueKeyName='expenseType'
                                                                addressLineKey1='ledgerName'
                                                                addressLineKey2='displayTaxName'
                                                                optionKeyName='lkExpenseTypeId'
                                                                canAddNew={true}
                                                                canDelete={true}
                                                                deleteClickHandler={(payload) => {
                                                                    this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                                                                }}
                                                                onAddNewClick={(payload) => {
                                                                    payload.accountingEntry= 'Credit';
                                                                    payload.formData = {
                                                                        textBoxValue: payload.textEntered,
                                                                        submittedOnce: false
                                                                    }
                                                                    this.handleNewExpenseAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                                                                }}
                                                                selectedValue={this.props.selectedExpenseList || []}
                                                                onSelect={(expenseId) => {
                                                                    let selectedExpenseList = this.props.selectedExpenseList || [];
                                                                    if (selectedExpenseList.indexOf(expenseId) === -1) {
                                                                        selectedExpenseList.push(expenseId);
                                                                    } else {
                                                                        selectedExpenseList = selectedExpenseList.filter((el) => {
                                                                            return el !== expenseId
                                                                        });
                                                                    }
                                                                    this.props.updateState({
                                                                        selectedExpenseList
                                                                    });
                                                                    setTimeout(() => {
                                                                        this.calculateTotalExpense();
                                                                    }, 100);
                                                                }}
                                                                onDeSelect={(expenseId) => {
                                                                    const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                                    let selectedExpenseList = this.props.selectedExpenseList || [];
                                                                    selectedExpenseList = selectedExpenseList.filter((el) => {
                                                                        return el !== expenseId
                                                                    });
                                                                    this.props.updateState({
                                                                        selectedExpenseList
                                                                    });
                                                                    setTimeout(() => {
                                                                        this.calculateTotalExpense();
                                                                    }, 100);
                                                                }}
                                                            /> */}

                    {/* <Dropdown
                                                                style={{ width: '100%' }}
                                                                items={this.props.expenseTypes}
                                                                valueKeyName='expenseType'
                                                                addressLineKey1='ledgerName'
                                                                addressLineKey2='taxDisplayName'
                                                                value={this.props.expenseId}
                                                                canAddNew={true}
                                                                canDelete={true}
                                                                deleteClickHandler={(payload) => {
                                                                    this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                                                                }}
                                                                onAddNewClick={(payload) => {
                                                                    payload.formData = {
                                                                        textBoxValue: payload.textEntered,
                                                                        submittedOnce: false
                                                                    }
                                                                    this.handleNewExpenseAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                                                                }}
                                                                onSelect={(expenseId) => {
                                                                    const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                                    this.props.updateState({
                                                                        expenseId,
                                                                        expensName: selectedExpense.expenseType

                                                                    })
                                                                }}
                                                                optionKeyName='lkExpenseTypeId'
                                                                allowClear={true}
                                                                onClear={() => {
                                                                    this.props.updateState({
                                                                        expenseId: undefined,
                                                                        expensName: undefined
                                                                    })
                                                                }}
                                                            /> */}
                    {/* </Col> */}
                    {/* </Row> */}
                    {/* {this.props.selectedExpenseList &&
                  this.props.selectedExpenseList.length > 0
                    ? (this.props.selectedExpenseList || []).map(
                        (expenseId) => {
                          const selectedExpense = find(
                            this.props.expenseTypes,
                            { lkExpenseTypeId: Number(expenseId) }
                          );
                          let expenseObj =
                            this.state.expenseAmounts[expenseId] || {};
                          return (
                            <Row
                              type="flex"
                              justify="end"
                              className="mb20"
                              key={expenseId}
                            >
                              <Col span={8}>
                                <span className="label">
                                  {selectedExpense.expenseType}
                                </span>
                              </Col>
                              <Col span={8}>
                                <div className="input-group">
                                  <div className="input-label-control">
                                    <span className="mark">
                                      {(this.props.companyInfo || {})
                                        .currencyIcon ? (
                                        <i
                                          className={
                                            (this.props.companyInfo || {})
                                              .currencyIcon
                                          }
                                        ></i>
                                      ) : (
                                        (this.props.companyInfo || {})
                                          .currencyCode + " "
                                      )}
                                    </span>
                                    <Input
                                      placeholder="0.00"
                                      type="number"
                                      value={expenseObj.expenseAmt + ""}
                                      onChange={(e) => {
                                        let expObj =
                                          this.state.expenseAmounts[
                                            expenseId
                                          ] || {};
                                        expObj.expenseAmt = e.target.value;
                                        this.setState({
                                          expenseAmounts: {
                                            ...this.state.expenseAmounts,
                                            [expenseId]: expObj,
                                          },
                                        });
                                        if (expObj.expenseAmt) {
                                          setTimeout(() => {
                                            this.calculateTotalExpense();
                                          }, 100);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col span={8}>
                                {selectedExpense.taxSingleRateId &&
                                expenseObj.expenseAmt ? (
                                  <span>
                                    <div className="input-group">
                                      <div className="input-label-control">
                                        <span className="mark">
                                          {(this.props.companyInfo || {})
                                            .currencyIcon ? (
                                            <i
                                              className={
                                                (this.props.companyInfo || {})
                                                  .currencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            (this.props.companyInfo || {})
                                              .currencyCode + " "
                                          )}
                                        </span>
                                        <Input
                                          disabled
                                          placeholder="0.00"
                                          value={
                                            Number(expenseObj.expenseAmt || 0) +
                                            Number(expenseObj.expenseTax || 0)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        float: "left",
                                      }}
                                    >
                                      {" "}
                                      {(this.props.companyInfo || {})
                                        .currencyIcon ? (
                                        <i
                                          className={
                                            (this.props.companyInfo || {})
                                              .currencyIcon
                                          }
                                        ></i>
                                      ) : (
                                        (this.props.companyInfo || {})
                                          .currencyCode + " "
                                      )}
                                      {Number(
                                        expenseObj.expenseTax || 0
                                      ).toFixed(2)}{" "}
                                      ({selectedExpense.displayTaxName})
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          );
                        }
                      )
                    : ""} */}

                    {/* <Row type="flex" justify="end" className="mb20">
                    <Col span={8}>
                      <span className="label">
                        <FormattedMessage
                          id="customer.salesInvoice.form.totalExpenseAmount.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>

                    <Col span={16}>
                      <div className="input-group">
                        <div className="input-label-control">
                          <span className="mark">
                            {(this.props.companyInfo || {}).currencyIcon ? (
                              <i
                                className={
                                  (this.props.companyInfo || {}).currencyIcon
                                }
                              ></i>
                            ) : (
                              (this.props.companyInfo || {}).currencyCode + " "
                            )}
                          </span>
                          <Input
                            placeholder="0.00"
                            type="number"
                            value={this.state.expenseAmount}
                            onChange={(e) => {
                              this.setState(
                                {
                                  expenseAmount: Number(e.target.value),
                                },
                                () => {
                                  if (this.state.subtotal) {
                                    this.calculateSubtotal();
                                  }
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row> */}
                    {/* </Fragment>
                                                : ''
                                        } */}
                    {/* 
                  {this.props.isRootExpense ? (
                    <Fragment>
                      {this.props.rootExpenseList &&
                      this.props.rootExpenseList.length > 0
                        ? (this.props.rootExpenseList || []).map(
                            (rootExpense, i) => {
                              return (
                                <Row
                                  key={"exp-" + i}
                                  type="flex"
                                  className="amount-summary"
                                  justify="end"
                                >
                                  <Col span={5}>
                                    <FormattedMessage
                                      id="customer.salesInvoice.form.totalExpense.label"
                                      defaultMessage=""
                                    />
                                  </Col>
                                  <Col span={10}>
                                    <div className="input-group">
                                      <div
                                        className="input-label-control"
                                        style={{
                                          width: "130px",
                                          position: "relative",
                                        }}
                                      >
                                        <span className="mark">
                                          {(this.props.companyInfo || {})
                                            .currencyIcon ? (
                                            <i
                                              className={
                                                (this.props.companyInfo || {})
                                                  .currencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            (this.props.companyInfo || {})
                                              .currencyCode + " "
                                          )}
                                        </span>
                                        <Input
                                          placeholder="0.00"
                                          value={rootExpense.expenseAmount}
                                          type="number"
                                          onChange={(e) => {
                                            rootExpense.expenseAmount = Number(
                                              e.target.value
                                            );

                                            let rootExpenseList =
                                              this.props.rootExpenseList;
                                            rootExpenseList[i] = rootExpense;
                                            this.setState(
                                              {
                                                rootExpenseList:
                                                  rootExpenseList,
                                              },
                                              () => {
                                                if (this.state.subtotal) {
                                                  this.calculateSubtotal();
                                                }
                                              }
                                            );

                                            this.props.updateState({
                                              rootExpenseList: rootExpenseList,
                                            });

                                            // this.setState({
                                            //     expenseAmount: Number(e.target.value)
                                            // },
                                          }}
                                        />
                                      </div>
                                    </div>

                                    {rootExpense.selectedExpenseLedger &&
                                    rootExpense.selectedExpenseLedger
                                      .isLedgerTaxable &&
                                    rootExpense.expenseLedgerAccountTaxRate &&
                                    rootExpense.expenseAmount ? (
                                      <div>
                                        <span className="label">Tax </span>{" "}
                                        {Number(
                                          (rootExpense.expenseAmount *
                                            (rootExpense.expenseLedgerAccountTaxRate ||
                                              0)) /
                                            100
                                        ).toFixed(2)}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                  <Col span={6} className="filed-jamps">
                                    <div className="input-group">
                                      <Dropdown
                                        items={this.props.allLedgerAccounts}
                                        value={
                                          rootExpense.expenseLedgerAccountName
                                        }
                                        placeholder={this.props.intl.formatMessage(
                                          {
                                            id: "selectLedger.text",
                                            defaultMessage: "",
                                          }
                                        )}
                                        onSelect={(selectedOption, option) => {
                                          const selectedExpenseLedger = find(
                                            this.props.allLedgerAccounts,
                                            {
                                              chartOfAccountDetailsId:
                                                Number(selectedOption),
                                            }
                                          );

                                          rootExpense.chartOfAccountDetailsId =
                                            selectedOption;
                                          rootExpense.ledgerAccountName =
                                            option.name;
                                          rootExpense.expenseLedgerAccountTaxRate =
                                            selectedExpenseLedger.taxSingleRatePercent ||
                                            0;
                                          rootExpense.selectedExpenseLedger =
                                            selectedExpenseLedger;

                                          let rootExpenseList =
                                            this.props.rootExpenseList;
                                          rootExpenseList[i] = rootExpense;
                                          this.setState({
                                            rootExpenseList: rootExpenseList,
                                          });

                                          this.props.updateState({
                                            rootExpenseList: rootExpenseList,
                                          });
                                        }}
                                        valueKeyName="ledgerAccountName"
                                        optionKeyName="chartOfAccountDetailsId"
                                        addressLineKey1="accountingEntry"
                                        allowClear={true}
                                        onClear={() => {
                                          rootExpense.chartOfAccountDetailsId =
                                            null;
                                          rootExpense.ledgerAccountName = null;
                                          rootExpense.expenseLedgerAccountTaxRate =
                                            null;
                                          rootExpense.selectedExpenseLedger =
                                            null;

                                          let rootExpenseList =
                                            this.props.rootExpenseList;
                                          rootExpenseList[i] = rootExpense;
                                          this.setState({
                                            rootExpenseList: rootExpenseList,
                                          });

                                          this.props.updateState({
                                            rootExpenseList: rootExpenseList,
                                          });
                                        }}
                                        canAddNew={true}
                                        onAddNewClick={(props) => {
                                          this.newLedgerAccounts(this.props);
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col span={2} className="p5">
                                    <i
                                      className="fa fa-trash fa-lg cursor-pointer"
                                      onClick={() => {
                                        let rootExpenseList =
                                          this.props.rootExpenseList;
                                        if (
                                          rootExpenseList &&
                                          rootExpenseList.length > 1
                                        ) {
                                          rootExpenseList.splice(i, 1);
                                        } else {
                                          rootExpenseList = [{}];
                                        }

                                        this.props.updateState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                        this.setState({
                                          rootExpenseList: rootExpenseList,
                                        });
                                        if (this.state.subtotal) {
                                          this.calculateSubtotal();
                                        }
                                      }}
                                      type="delete"
                                    />
                                  </Col>
                                </Row>
                              );
                            }
                          )
                        : ""}
                      {this.props.rootExpenseList.length < 5 && (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "right",
                            marginTop: "-15px",
                          }}
                          className="mb20, mr10"
                        >
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              let ExpenseList = this.props.rootExpenseList;
                              ExpenseList.push({});
                              this.setState({ rootExpenseList: ExpenseList });
                              this.props.updateState({
                                rootExpenseList: ExpenseList,
                              });
                            }}
                          >
                            <FormattedMessage id="add" />{" "}
                            <FormattedMessage id="customer.salesInvoice.form.totalExpense.label" />
                          </span>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    ""
                  )} */}

                    {/* <div className="amount-summary">
                    <Row>
                      <Col span={8}>
                        <span className="title">
                          <FormattedMessage
                            id="customer.salesInvoice.form.subTotal.label"
                            defaultMessage=""
                          />
                        </span>
                      </Col>
                      <Col span={16}>
                        {(this.props.companyInfo || {}).currencyIcon ? (
                          <i
                            className={
                              (this.props.companyInfo || {}).currencyIcon
                            }
                          ></i>
                        ) : (
                          (this.props.companyInfo || {}).currencyCode + " "
                        )}
                        {fixedDecimalAndFormateNumber(
                          this.state.subtotalWithoutDiscount
                        )}
                      </Col>
                    </Row>
                  </div> */}

                    {/* <div className="amount-summary">
                                            <Row>
                                                <Col span={8}>
                                                    <span className="title"><FormattedMessage
                                                        id='customer.salesInvoice.form.totalAmount.label'
                                                        defaultMessage='' /></span>
                                                </Col>
                                                <Col span={16}>
                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                    {fixedDecimalAndFormateNumber(this.state.total)}
                                                </Col>
                                            </Row>
                                        </div> */}
                  </div>
                </Col>
              </Row>

            </>
          }

          {/* <TextArea maxLength={500} value={this.props.footer || ''} className="add-footer-textarea" onChange={(e) => {
                    this.props.updateState({
                        footer: e.target.value
                    });
                }}
                    rows={1} placeholder="Add Footer Here"></TextArea> */}
          {/* <div style={{position:"absolute",margin:"0",bottom:"0",width:"100%"}}> */}

          <CKEditor
            type="inline"
            className="add-footer-textarea"
            key={`${"footer"}`}
            data={this.props.footer}
            onInit={(editor) => {
              this.editor = editor;
            }}
            onChange={(event) => {
              const data = event.editor.getData();
              this.props.updateState({ footer: data });
            }}
            // 05-02-21 config={CUSTOM_CONFIGURATION}
            config={{ ...CUSTOM_CONFIGURATION, placeholder: "Add Footer Here" }}
          />
          <div className="pb40"></div>
          {/* </div> */}

          <div className="footer">
            <div className="left-fItems">
              <Button
                className="grey-button"
                onClick={() => {
                  this.props.showModal({
                    modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage="" />,
                    footer: { submit: { intlId: "button.close.label" } },
                    handleSubmit: () => {
                      this.props.popModalFromStack();
                    }
                  })
                }}
              >
                <FormattedMessage id="button.cancel.label" defaultMessage="" />
              </Button>
            </div>
            <div className="right-fItems">
              {!this.props.update && !this.props.isShipmentConversion && (
                <Button
                  className="mr20"
                  loading={this.props.saveLoading}
                  disabled={this.props.saveLoading}
                  onClick={() => {
                    createInvoice(true);
                  }}
                >
                  <FormattedMessage id="button.draft.label" defaultMessage="" />
                </Button>
              )}

              <Button
                type="primary"
                loading={this.props.saveLoading}
                disabled={this.props.saveLoading}
                onClick={() => {
                  createInvoice();
                }}
              >
                <FormattedMessage id="button.save.label" defaultMessage="" />
              </Button>
            </div>
          </div>
        </Skeleton>
      </Fragment>
    );
  }
}

export default injectIntl(CustomerProductDetails);
