import axios from "axios";
import config from "../../../config/environmentConfig";
import { COMMUNICATION_ACTION_LIST, DONATION_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const getAllEventTemplate = (payload) => {
    return (dispatch) => {
        return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/formBuilder/getFormsByTemplateType?relationshipId=${payload.relationshipId}&templateType=${payload.templateType}`)
            .then((res) => {
                dispatch({
                    type: COMMUNICATION_ACTION_LIST.EVENT_TEMPLATE_LIST,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            })
            .finally(() => {
                dispatch({ type: DONATION_ACTION_LIST.DONATION_SETTING_LOADING, data: {isEventTemplateListLoaded : true}});
              });
    };
};

export const deleteFormBuilder = (payload, props) => {
    return dispatch => {
        return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/formBuilder/delete`, payload)
            .then(res => {
                props.getAllEventTemplate && props.getAllEventTemplate(props);
                showToasterMessage({ messageType: 'success', description: 'template successfully deleted' });
            })
            .catch((err) => {
                ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
                }
            })
    }
}



