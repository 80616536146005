import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TxnEmailTemplateComponent from '../../../../../../components/modal/modalBody/settings/EmailTemplate/TxnEmailTemplate';
import { fetchEmailTemplate, saveOrUpdateEmailTemplate, fetchSenderEmailsFromRID } from './action';
import { pushModalToStack,hideModal, showModal, popModalFromStack  } from "../../../../../../actions/commonActions";

class TxnEmailTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectDocumentOptions: props.isAlifApp ? ['RFQ', 'Sales Quotation', 'Sales Order', 'Sales Invoice', 'Purchase Order', 'Purchase Invoice', 'Sales Payment', 'Credit Memo', 'Purchase Payment', 'Debit Memo', 'Inquiry', 'Purchase Request', 'Online Payment', 'Kiosk Payment', 'Text2Donate Payment', 'Pledge', 'Event RSVP Response', 'Subscribe', 'Pledge Reminder', 'Recurring Sales Invoice', 'School Payment Receipt', 'Online Order Confirmation', 'Class Assignment', 'Sales Shipment']
                : ['RFQ', 'Sales Quotation', 'Sales Order', 'Sales Invoice', 'Purchase Order', 'Purchase Invoice', 'Sales Payment', 'Credit Memo', 'Purchase Payment', 'Debit Memo', 'Inquiry', 'Purchase Request', 'Online Payment', 'Kiosk Payment', 'RSVP', 'Subscribe', 'Recurring Sales Invoice', 'School Payment Receipt', 'Online Order Confirmation', 'Class Assignment', 'Sales Shipment', 'Sales Order Status Update', 'Sales Order Cancellation', 'Sales Order Update'],
            docName: props.docName || '',
            subject: "",
            isFormSetting: props.isFormSetting || false,
            // senderEmails: props.senderEmails || [],
        }
        let CKEDITOR = window.CKEDITOR;
        if (CKEDITOR) {
            CKEDITOR.on('instanceReady', function (ev) {
                document.getElementById(ev.editor.id + '_top').style.display = 'block';
                let edi = document.getElementById('cke_'+ ev.editor.name);
                if (edi) { edi.style.width = '';}
                ev.editor.on('focus', function (e) {
                  document.getElementById(ev.editor.id + '_top').style.display = 'block';
                 
              });
              ev.editor.on('blur', function (e) {
                  let edi = document.getElementById(ev.editor.id + '_top');
                  if (edi) { edi.style.display = 'block'; }
              });
            });
        }
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, docName: this.props.docName};
        this.props.docName && this.props.fetchEmailTemplate( payload);
        // this.props.senderEmails && this.props.fetchSenderEmailsFromRID(payload)

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.templateData !== this.state.templateData && nextProps.templateData) {
          this.setState({ subject: nextProps.templateData.subject || '', content: nextProps.templateData.content || '' });
        }
        if(nextProps.senderEmails.length){
            this.setState({senderEmails: nextProps.senderEmails});
        }
      }

    render() {
        return <TxnEmailTemplateComponent  {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }
}

const mapStateToProps = (state) => {
    return {
        templateData: state.settings.templateData,
        isAlifApp: state.common.isAlifApp,
        senderEmails: state.settings.senderEmails,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchEmailTemplate,
    saveOrUpdateEmailTemplate,
    pushModalToStack,
    fetchSenderEmailsFromRID,
    hideModal,
    showModal,
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TxnEmailTemplate);
