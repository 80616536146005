import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegistrationFormComponent from '../../../../../components/modal/modalBody/settings/RegistrationFormSettingPreview';
import { fetchRegistrationFormSetting, saveRegistration, getTotalStudentcCount, getTotalStudentcCountByGrade } from './action';
import queryString from 'query-string';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchCountries, fetchStates, fetchPaymentTerms, fetchCustomerDetailsByCustomerId, resetCustomerDetail } from '../../../../../actions/commonActions';
import { CONSTANTS } from '../../../../../static/constants';
import { fetchStudentRegistration } from '../../../../customer/studentRegistration/Listing/action';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';

class RegistrationFormSetting extends Component {
    constructor(props) {
        super(props);
        const linkProps = this.props.location ? queryString.parse(this.props.location.search) || {} : {};
        const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
        this.state = {
            ...props.registrationData,
            formSetting: props.formSetting ||{},
            relationshipId: linkProps.relationshipId || props.relationshipId,
            modalType: props.modalType,
            relationshipBillingAddress
        }
    }
    componentDidMount() {
        const linkProps = this.props.location ? queryString.parse(this.props.location.search) || {} : {};
        const payload = {
            relationshipId: this.state.relationshipId || linkProps.relationshipId,
            customerId: this.state.customerId,
            registrationFormSettingId: this.props.registrationFormSettingId
        };
        payload.relationshipId && this.props.fetchRegistrationFormSetting(payload);
        this.props.fetchCountries(payload);
        this.props.fetchPaymentTerms(payload);
        payload.customerId && this.props.fetchCustomerDetailsByCustomerId(payload);
        
        payload.customerId && this.props.getTotalStudentcCount(payload); 
        payload.customerId && this.props.getTotalStudentcCountByGrade(payload);
    }

    componentWillReceiveProps(props) {
        if (props.registrationFormSetting && props.registrationFormSetting.registrationFormSettingId) {
            let formSetting = JSON.parse(props.registrationFormSetting.formSetting);
            delete formSetting.version;
            delete formSetting.questionList;
            this.setState({
                formSetting: {...this.state.formSetting, ...formSetting}
            })
        }
        if (props.customerDetail && props.customerDetail.customerId) {
            const customer = props.customerDetail || {};
            const defaultBillingAddress = (customer.boLocationList || [])[0] || {};
            let studentRegistration = find(props.customerDetail.orgRegistrationList || [], { studentRegistrationId: this.props.registrationData.studentRegistrationId }) || {};
            this.setState({
                ...studentRegistration,
                ...defaultBillingAddress,
                ...customer,
                registrationVersion: studentRegistration.version,
                addressVersion: defaultBillingAddress.version,
                formSetting: { ...this.state.formSetting, questionList: JSON.parse(studentRegistration.questionList) }
             
            })
            props.resetCustomerDetail();
          }
    }
    
    render() {
        return <RegistrationFormComponent {...this.props} {...this.state}  updateState={(data) => { this.setState(data) }}/>
    }
}
const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        registrationFormSetting: state.settings.registrationFormSetting,
        countries: state.common.countries,
        states: state.common.states,
        customerDetail: state.common.customerDetail,
        totalStudentCount: state.studentRegistration.totalStudentCount,
        totalStudentCountByGrade: state.studentRegistration.totalStudentCountByGrade,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveRegistration,
    fetchRegistrationFormSetting,
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    fetchCountries,
    fetchStates,
    fetchPaymentTerms,
    fetchCustomerDetailsByCustomerId,
    resetCustomerDetail,
    fetchStudentRegistration,
    getTotalStudentcCount, 
    getTotalStudentcCountByGrade
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormSetting);