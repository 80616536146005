import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Switch } from 'antd';
//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';

function MembershipOptionDrawer(props) {
  const { name, shortName, type, isActive, maxSubMember, feeId, allTermAndFee } = props;

  const closeDrawer = () => {
    props.updateHeaderState({
      membership: {
        addMembershipOptionDrawerVisible: false
      }
    })
  }

  const makeTermAndFeeDropdown = () => {
    let dropdownObj = [];

    for (var i = 0; i < allTermAndFee.length; i++) {
      let obj = {
        feeId: allTermAndFee[i].feesId,
        display: `$${allTermAndFee[i].fee}/${allTermAndFee[i].frequency} for ${allTermAndFee[i].duration} ${allTermAndFee[i].period} ${allTermAndFee[i].type?'- '+allTermAndFee[i].type:''}`
      }

      dropdownObj.push(obj);
    }

    return dropdownObj;

  };

  const handleSubmit = (props) => {
    if (props.name && props.shortName && props.type && ((props.type === 'Family' && props.maxSubMember ) || props.type !== 'Family')  ) {
      const payload = {
        name: props.name,
        shortName: props.shortName,
        type: props.type,
        isActive: props.isActive ? 1 : 0,
        maxSubMember: props.maxSubMember,
        feeId: props.feeId,
        isAutoRenew: props.isAutoRenew ? 1 : 0,
        relationshipId: props.relationshipId,
        membershipOptionId: props.membershipOptionId,
        version: props.version,
        dateCreated: props.dateCreated
      };

      props.pushModalToStack({
        modalBody: props.membershipOptionId ? <FormattedMessage id='commons.edit.popup.confimation.msg' defaultMessage='' /> : <FormattedMessage id='commons.create.popup.confimation.msg' defaultMessage='' />,
        handleSubmit: () => {
          props.saveMembershipOption(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage id='membership.membershipOption.addDrawer.drawerName' defaultMessage=' ' />
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-supplier-drawer"
        visible={props.addMembershipOptionDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <label>
                <FormattedMessage id='membership.membershipOption.addDrawer.name' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <span className="">
                <FormattedMessage id='membership.membershipOption.addDrawer.shortName' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                minLength={15}
                maxLength={45}
                type='text'
                className={(props.submittedOnce && !props.name) ? 'input-text-error' : ''}
                value={name}
                onChange={(e) => {
                  props.updateState({
                    name: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'membership.membershipOption.addDrawer.name', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.name }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
            <Col span={12}>
              <TextBox
                type='text'
                minLength={20}
                maxLength={25}
                className={(props.submittedOnce && !props.shortName) ? 'input-text-error' : ''}
                value={shortName}
                onChange={(e) => {
                  props.updateState({
                    shortName: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'membership.membershipOption.addDrawer.shortName', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.shortName }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <label>
                <FormattedMessage id='membership.membershipOption.addDrawer.type' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <label><FormattedMessage id='membership.membershipOption.addDrawer.fee' defaultMessage='' /></label>
              {/* <span className="required">*</span> */}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                className={(props.submittedOnce && !props.type) ? 'input-text-error' : ''}
                items={[{ val: 'individual', displayName: 'Individual' }, { val: 'family', displayName: 'Family' }, { val: 'corporate', displayName: 'Corporate' }]}
                valueKeyName='displayName'
                optionKeyName='val'
                value={type}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'membership.membershipOption.addDrawer.type',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      type: option.name
                    })
                  }
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.type }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
            <Col span={12}>
              <Dropdown
               // className={(props.submittedOnce && !props.feeId) ? 'input-text-error' : ''}
                items={makeTermAndFeeDropdown()}
                //`$${props.fee}/${props.frequency} for ${props.duration} ${props.period} - ${props.type}`
                valueKeyName="display"
                optionKeyName='feeId'
                value={feeId}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'membership.membershipOption.addDrawer.fee',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      feeId: option.value,
                    })
                  }
                }
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || props.feeId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>
          </Row>

          {props.type === 'Family' ? <>
            <Row gutter={[16, 4]}>
              <Col span={12}>
                <span className="">
                  <FormattedMessage id='membership.membershipOption.addDrawer.maxFamilyMembers' defaultMessage='' /></span><span className="required">*</span>
              </Col>
              <Col span={12}>
                <span className=""> <FormattedMessage id='membership.membershipOption.addDrawer.active' defaultMessage='' /></span>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  className={(props.submittedOnce && (props.maxSubMember <= 0 || !props.maxSubMember)) ? 'input-text-error' : ''}
                  type='number'
                  value={maxSubMember || null}
                  onChange={(e) => {
                    props.updateState({
                      maxSubMember: e.target.value,
                    });
                  }}
                  placeholder={props.intl.formatMessage(
                    {
                      id: 'membership.membershipOption.addDrawer.maxFamilyMembers',
                      defaultMessage: ''
                    })
                  }
                />
                <ErrorMsg
                  validator={() => { return !props.submittedOnce || props.maxSubMember }}
                  message={props.intl.formatMessage(
                    {
                      id: 'requiredField.missing.msg',
                      defaultMessage: ''
                    })
                  } />
              </Col>
              <Col span={12}>
                <Switch
                  checked={isActive || false}
                  onChange={(change) => {
                    props.updateState({
                      isActive: change,
                    })
                  }}
                //checkedChildren='Yes'
                //unCheckedChildren="No"
                />
              </Col>
            </Row> 
           </> :
            <>
              <Row gutter={[16, 4]}>
                <Col span={12}>
                  <span className=""> <FormattedMessage id='membership.membershipOption.addDrawer.active' defaultMessage='' /></span>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Switch
                    checked={isActive || false}
                    onChange={(change) => {
                      props.updateState({
                        isActive: change,
                      })
                    }}
                  //checkedChildren='Yes'
                  //unCheckedChildren="No"
                  />
                </Col>
              </Row>
            </>
            }
          {/* <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col span={6}>
              <span className=""> <FormattedMessage id='membership.membershipOption.addDrawer.autoRenew' defaultMessage='' /></span>
            </Col>
            <Col span={12}>
              <Switch

                checked={isAutoRenew || false}
                onChange={(change) => {
                  props.updateState({
                    isAutoRenew: change,
                  })
                }} />
            </Col>
          </Row> */}
        </Form>

        <div className='form-footer'>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.submit'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </div>
      </Drawer>
    </Fragment>
  );
}


export default injectIntl(MembershipOptionDrawer);
