import React, {Fragment} from 'react';
import config from '../../config/environmentConfig';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Form, DatePicker, InputNumber, Checkbox, Button, Tooltip } from 'antd';
import { TextBox } from '../general/TextBox';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS } from '../../static/constants';
import { CustomButton } from '../general/CustomButton';
import { showToasterMessage, getMomentDateForUIReadOnly, validateEmail } from '../../utils';
import './signup.scss';
//import * as find from 'lodash.find';
import moment from 'moment-timezone';
import Cardpay from "../../assets/images/card-logos.jpg"
import PicPci from "../../assets/images/pci-pic1.jpg"
// import AlpidePayLogo from "../../assets/images/alpidepay.png"

// import { ErrorMsg } from '../general/ErrorMessage';
import * as find from 'lodash.find';
// const { Option } = Select;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const fixedDecimalNumber = (number) => {
    return (Number(number || 0)).toFixed(CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
}


function ProccessPaymnet(props) {

    const { salesInvoiceDetail, recurringInvoiceData } = props;
    const billingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    //const relationshipAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const getAddressInfoFragment = (obj, additionInfo) => {
        const fragmentObj = <Fragment>
            {
                obj.streetAddress1
                    ? <div className='billing-address'>{obj.streetAddress1}
                        {obj.streetAddress2
                            ? <span className='billing-address'> {obj.streetAddress2}
                            </span>
                            : ''
                        }
                    </div>
                    : ''
            }
            {/* {
                obj.streetAddress2
                    ? <div className='billing-address'>{obj.streetAddress2}
                    </div>
                    : ''
            } */}
            {
                obj.cityName
                    ? <div className='billing-address'>
                        {
                            `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                        }
                    </div>
                    : ''
            }
            {
                additionInfo.workPhone
                    ? <div className='billing-address'>
                        <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                    </div> :
                    ''
            }
            {
                additionInfo.email
                    ? <div className='billing-address'>
                        <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                    </div> :
                    ''
            }
            {/* {
                additionInfo.gstNumber
                    ? <div className='billing-address'>
                         <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                    </div> :
                    ''
            }
            {
                additionInfo.panNumber
                    ? <div className='billing-address'>
                         <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                    </div> :
                    ''
            } */}
    
        </Fragment>
    
        return fragmentObj;
    }
    // function parseQueryString(str) {
    //     var vars = [];
    //     var arr = str.split('&');
    //     var pair;
    //     for (var i = 0; i < arr.length; i++) {
    //         pair = arr[i].split('=');
    //         vars[pair[0]] = unescape(pair[1]);
    //     }
    //     return vars;
    // }
    if (!props.isListenerAdded) {
        window.addEventListener('message', function (event) {
            if (!event.data) return '';
            if (event.data && typeof event.data === 'string') {
                var token = JSON.parse(event.data);
                var mytoken = document.getElementById('mytoken');
                mytoken.value = token.message;
                props.updateState({
                    account: token.message,
                    expiry: token.expiry,
                    validationError: token.validationError,
                })
            }
        }, false);
        props.updateState({isListenerAdded: true});
    }
    return (
        <div className="header-action-container" style={{ position: 'relative' }}>
            <div className='pci-logo'><img src={PicPci} alt='' /></div>
            <div className='payfont'><b> <i className="fa fa-lock" ></i> 
                Payment Page</b></div>
                <div className='headspce'></div>
            <Form>
                <div className="information" style={{ maxHeight: '1000px' }}>
                    <div className="">
                        <Row>
                            {/* <Col span={24} style={{ textAlign: 'center' }}><FormattedMessage id='payment.confirmation.text' /></Col>  */}
                        </Row>
                        <br />
                        <Row>
                            <Col span={14} md={14} xs={24}>
                            <Row className="pb10 ">
                                    <Col span={7} md={7} xs={24} className="title popupfont" >
                                        <FormattedMessage id='Name' defaultMessage='' /><span className="required">*</span>


                                    </Col>
                                    <Col span={15} md={15}  xs={24} className="pl35 ">
                                            <TextBox
                                            parentStyle={{ width: '50%', display: 'inline-block' }}
                                                type='text'
                                                className={(props.submittedOnce && !props.firstName) ? 'input-text-error' : ''}
                                                value={props.firstName}
                                                onChange={(e) => {
                                                    props.updateState({
                                                        firstName: e.target.value,
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'contact.firstName.placeholder', defaultMessage: '' })}
                                            />
                                            <TextBox
                                                parentStyle={{ width: '45%',display: 'inline-block', marginLeft:'4%' }}
                                                type='text'
                                                className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                                                value={props.lastName}
                                                onChange={(e) => {
                                                    props.updateState({
                                                        lastName: e.target.value,
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'contact.lastName.placeholder', defaultMessage: '' })}
                                            />
                                    </Col>
                                </Row>
                             
                                <Row className="pb10 ">
                                    <Col span={7} md={7} xs={24} className="title popupfont" >
                                        <FormattedMessage id='email' defaultMessage='' />
                                        <span className="required">*</span>

                                    </Col>
                                    <Col span={15} md={15} xs={24} className="pl35 ">
                                        <TextBox
                                            type='text'
                                            className={(props.submittedOnce && !props.emailAddress) ? 'input-text-error' : ''}
                                            value={props.emailAddress}
                                            onChange={(e) => {
                                                props.updateState({
                                                    emailAddress: e.target.value,
                                                });
                                            }}
                                            placeholder={props.intl.formatMessage({ id: 'contact.email.placeholder', defaultMessage: '' })}
                                        />

                                    </Col>
                                </Row>
                                
                                <Row className="pb10" style={{ padding: '0px 10px 5px' }}>
                                    {/* <div className='tuyicar'>
                                    <div className='leftb actives'>Credit Card</div>
                                    <div className='rightb'>ACH (eCheck) </div>

                                    </div> */}
                                    

                                    <Col span={24} className='ifheight'>
                                        <form name="tokenform" id="tokenform">
                                            <iframe title=" " id="tokenframe" name="tokenframe" src={config.CC_TOKENIZER_URL + `?useexpiry=true&usecvv=true&cardlabel=Card Number*&expirylabel=Expiration Date* &cvvlabel=CVV* &invalidcreditcardevent=true&invalidcvvevent=true&tokenizewheninactive=true&cardnumbernumericonly=true&cardinputmaxlength=16&fullmobilekeyboard=true&placeholdermonth= MM &placeholderyear=YYYY&placeholder=16 Digit Card Number&placeholdercvv=3 Digit CVV Code&usemonthnames=true
                                            &css=` + encodeURIComponent('.error{color:%20red;}input{height:31px; margin:10px 0px 5px; padding:0px 10px; border:solid 1px #e5e5e5; border-radius:2px;}select{height:31px; margin:10px 0px 5px; border:solid 1px #e5e5e5;border-radius:2px;}label{ font-family: Segoe UI, Droid Sans, Tahoma, Arial, sans-serif; font-size:16px; padding:0px 30px 0px 0px; width:28%; float:left; display:inline-block; text-align: right;  } #cccvvfield{width:120px;} #tokenform br{display:none;} #ccnumfield{width:50%;} #ccexpirymonth{width:22%;} #ccexpiryyear{width:22%;}@media only screen and (max-width: 350px){label{width:100%; font-size:14px; text-align:left; } #ccnumfield{width:90%;} #ccexpirymonth{width:42%;} #ccexpiryyear{width:42%;}} ')} scrolling="no" width="100%" height="auto" frameBorder="0"></iframe>
                                            <input type="hidden" name="mytoken" id="mytoken" />
                                        </form>
                                    </Col>
                                </Row>
                                <Row className="pb10 ">
                                    <Col span={7} md={7} xs={24} className="title popupfont" >
                                        Billing Zip Code
                                        <span className="required">*</span>
                                    </Col>
                                    <Col span={15} md={15} xs={24} className="pl35 ">
                                        <TextBox
                                            type='text'
                                            className={(props.submittedOnce && !props.zipCode) ? 'input-text-error' : ''}
                                            value={props.zipCode}
                                            onChange={(e) => {
                                                props.updateState({
                                                    zipCode: e.target.value,
                                                });
                                            }}
                                            placeholder={props.intl.formatMessage({ id: 'address.zip', defaultMessage: '' })}
                                        />

                                    </Col>
                                </Row>
                                {recurringInvoiceData && recurringInvoiceData.recurringInvoiceId ?
                                    <Row className="pb10 ">
                                        <Col span={7} md={7} xs={24} className="title popupfont" >
                                            <FormattedMessage id='enableAutoPayment.level' defaultMessage='' />
                                        
                                        
                                        </Col>
                                        <Col span={15} md={15} xs={24} className="pl35 ">
                                        <span className=''>
                                            <Checkbox checked={(props.enableAutoPayment === 1)} onChange={(e) => {
                                                props.updateState({
                                                    enableAutoPayment: e.target.checked ? 1 : 0,
                                                })
                                            }} />
                                        </span>
                                        </Col>
                                    </Row>
                                    :
                                    ''
                                    // <Row className="pb10 pt10">
                                    //     <Col span={7} md={7} xs={24} className="title popupfont" >

                                    //         <FormattedMessage id='makeRecurring.text' defaultMessage='' />
                                    //     </Col>
                                    //     <Col span={15} md={15} xs={24} className="pl35 ">
                                    //         <Select onChange={(value) => {
                                    //             props.updateState({
                                    //                 period: value,
                                    //             })
                                    //         }}
                                    //             className={(props.submittedOnce && !props.period) ? 'input-text-error' : ''}

                                    //             placeholder="Please select"
                                    //             optionFilterProp="children"
                                    //             value={props.period}>
                                    //             <Option value="oneTimePayment"><FormattedMessage id='oneTimePayment.text' /></Option>
                                    //             {/* <Option value="daily"><FormattedMessage id='recurringInvoice.setup.daily' /></Option> */}
                                    //             <Option value="weekly"><FormattedMessage id='recurringInvoice.setup.weekly' /></Option>
                                    //             <Option value="monthly"><FormattedMessage id='recurringInvoice.setup.monthly' /></Option>
                                    //             <Option value="yearly"><FormattedMessage id='recurringInvoice.setup.yearly' /></Option>
                                    //         </Select>
                                    //         <ErrorMsg
                                    //             validator={() => { return !props.submittedOnce || !!props.period }}
                                    //             message={<FormattedMessage id='addItem.validation' values={{ value: 'This Field' }} />}
                                    //         />
                                    //     </Col>
                                    // </Row>
                                }
                                {(props.period && props.period !== "oneTimePayment") ?
                                    <Row className="pb10 pt10">
                                        <Col span={7} md={7} xs={24} className="title popupfont" >  
                                            <Tooltip placement="top" title={props.intl.formatMessage({ id: 'paymentStartDate.helpMessage' })}>
                                                <span className="tooltip-title">
                                                    <FormattedMessage id='paymentStartDate.text' defaultMessage='' />
                                                </span>
                                            </Tooltip>
                                        </Col>
                                        <Col span={15} md={15}  xs={24} className="pl35 ">
                                            <DatePicker
                                                allowClear={false}
                                                format={format}
                                                style={{ width: '100%' }}
                                                // disabledDate={invalidEndDate}
                                                key={moment(new Date(), format)}
                                                defaultValue={props.paymentStartDate ? moment(new Date(props.paymentStartDate), format) : null}
                                                onChange={(selectedDate) => {
                                                    props.updateState({
                                                        paymentStartDate: selectedDate
                                                    })
                                                }} />
                                        </Col>
                                    </Row> : ''
                                }
                                {(props.period && props.period !== "oneTimePayment") ?
                                    <Row className="pb10 pt10">
                                        <Col span={7} className="title popupfont" >
                                                                                        <FormattedMessage id='noOfPayment.text' defaultMessage='' />
                                        </Col>
                                        <Col span={15} className="pl35 "style={{fontWeight:'500', fontSize:'16px'}}>
                                            {!props.neverExpires && <div>
                                                <InputNumber style={{ width: '100%' }} value={props.noOfPayment} placeholder="" min={1} onChange={(value) => {
                                                    props.updateState({ noOfPayment: value })
                                                }} /></div>
                                            }
                                            <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
                                                props.updateState({
                                                    neverExpires: e.target.checked ? 1 : 0,
                                                })
                                            }}  /> <FormattedMessage id='recurringInvoice.setup.neverExpires' defaultMessage='' />
                                        </Col>
                                    </Row> : ''
                                }
                                {(props.enableAutoPayment) ?
                                    <div>
                                        <Row className="pb10">
                                            <Col span={7} className="title popupfont"  >
                                                <Tooltip placement="top" title={props.intl.formatMessage({ id: 'paymentStartDate.helpMessage' })}>
                                                    <span className="tooltip-title">
                                                        <FormattedMessage id='paymentStartDate.text' defaultMessage='' />
                                                    </span>
                                                </Tooltip>
                                            </Col>
                                            <Col span={15} className="pl35">
                                            Your card will be auto charged when invoice is generated
                                                {/* {getMomentDateForUIReadOnly({ date: new Date(recurringInvoiceData.startDate), format })} */}
                                            </Col>
                                        </Row>
                                        <Row className="pb10">
                                            <Col span={7} className="title popupfont" >
                                                <Tooltip placement="top" title={props.intl.formatMessage({ id: 'paymentEndDate.helpMessage' })}>
                                                    <span className="tooltip-title">
                                                        <FormattedMessage id='paymentEndDate.text' defaultMessage='' />
                                                    </span>
                                                </Tooltip>
                                            </Col>
                                            <Col span={15} className="pl35">

                                                {getMomentDateForUIReadOnly({ date: new Date(recurringInvoiceData.endDate), format })}
                                            </Col>
                                        </Row>
                                    </div> : ''
                                }
                                
                                <div style={{ padding: '10px 0px 20px' }}><img src={Cardpay} alt="" /> </div>
                            </Col>
                            <Col span={10} md={10} xs={24} className=" pl40  moptp">
                                {salesInvoiceDetail.formName ? <Row className="pb10 " >
                                    <Col span={24} className="title tescrt" > <span className='spoi' ><i className="fa fa-address-card"></i> </span>

                                        <FormattedMessage id='programName.text' defaultMessage='' />
                                    </Col>
                                    <Col span={24} className="pl30 lirty" >
                                        {salesInvoiceDetail.formName}
                                    </Col>
                                </Row> : ''
                                }
                                <Row className="pb10 " >
                                    <Col span={24} className="title tescrt" > <span className='spoi' ><i className="fa fa-user-circle-o"></i> </span> 

                                        <FormattedMessage id='modal.common.priceList.name' defaultMessage='' />
                                    </Col>
                                    <Col span={24} className="pl30 lirty" >
                                        {salesInvoiceDetail.customerName}
                                    </Col>
                                </Row>

                                <Row className="pb10">
                                    <Col span={24} className="title tescrt" > <span className='spoi' ># </span>
                                        <FormattedMessage id='modal.customer.payInvoice.invoiceNumber' defaultMessage='' />
                                    </Col>
                                    <Col span={24} className="pl30 lirty" >
                                        {salesInvoiceDetail.invoiceNumber}
                                    </Col>
                                </Row>
                                <Row className="pb10">
                                    <Col span={24} className="title tescrt" ><span className='spoi' > <i className="fa fa-calendar" ></i></span> 

                                        <FormattedMessage id='drawer.invoice.invoiceDate' defaultMessage='' />
                                    </Col>
                                    <Col span={24} className="pl30  lirty">
                                        {salesInvoiceDetail.invoiceDate && getMomentDateForUIReadOnly({ date: salesInvoiceDetail.invoiceDate, format: CONSTANTS.DISPLAY_DATE_FORMAT })}
                                    </Col>
                                </Row>
                                <Row className="pb10">
                                    <Col span={24} className="title tescrt" > <span className='spoi' ><i className="fa fa-usd" ></i></span> 

                                        <FormattedMessage id='modal.txDetails.payment.invoiceAmount' defaultMessage='' />
                                    </Col>
                                    <Col span={24} className="pl30 lirty">
                                        {salesInvoiceDetail.isMultiCurrency ?
                                            <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "}</span>
                                            : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
                                        }{fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount )}
                                    </Col>
                                </Row>
                                {billingAddress.streetAddress1 ?
                                    <Row className="pb10">
                                        <Col span={24} className="title tescrt" > <span className='spoi' ><i className="fa fa-address-card" ></i></span>
                                            <FormattedMessage id='schoolManagement.school.address' defaultMessage='' />
                                        </Col>
                                        <Col span={24} className="pl30 lirty">
                                            {getAddressInfoFragment(billingAddress, (salesInvoiceDetail.additionalInfoList ? salesInvoiceDetail.additionalInfoList[0] || {} : {}))}
                                        </Col>
                                    </Row>
                                    : ''}
                                
                        {/* <div style={{height:'30px', width:'100%'}}></div> */}
                        {/* <div style={{position:'absolute', bottom:'-55px', right:'0px'}}><img src={AlpidePayLogo} alt="AlpidePay" /></div> */}
                         </Col>
                        
                        <Col span={1}></Col>
                        </Row>
                    </div>
                </div>
            </Form>

            <div className="align-footer fotyeu" >
                <br />
                <CustomButton
                    intlId='confirmation.cancel'
                    defaultMessage=''
                    type='default'
                    key={0}
                    style={{ marginRight: 8 }}
                    onClick={props.popModalFromStack}
                />
                <Button
                    type='primary'
                    htmlType='submit'
                    key={1}
                    style={{ float: 'right' }}
                    disabled={props.paymentBtnClicked}
                    onClick={() => {
                        props.updateState({ submittedOnce: true });
                        if (!props.period && !props.enableAutoPayment) {
                            return;
                        }
                        if (!props.neverExpires && !props.noOfPayment && !props.enableAutoPayment) {
                            return showToasterMessage({ description: 'Please enter No. of Payment' });;
                        }
                        if (!props.account) {
                            return showToasterMessage({ description: 'Please make sure card details are valid and all card fields are entered.' });;
                        }
                        if (!props.firstName) {
                            return showToasterMessage({ description: 'Please enter first name' });;
                        }
                        if (!props.lastName) {
                            return showToasterMessage({ description: 'Please enter last name' });;
                        }
                        if (!props.emailAddress ) {
                            return showToasterMessage({ description: 'Please enter email' });;
                        }
                        if (props.emailAddress && !validateEmail(props.emailAddress)) {
                            return showToasterMessage({ description: 'Please enter valid email' });;
                        }
                        if (!props.zipCode ) {
                            return showToasterMessage({ description: 'Please enter billing zip code' });;
                        }

                        let payload = {
                            //subscriptionName: (props.salesInvoiceDetail.projectName || '').substring(0, 15) + '-' + (salesInvoiceDetail.customerName || '').substring(0, 20) + '-' + (salesInvoiceDetail.invoiceNumber || '').substring(0, 13),
                            startDate: getMomentDateForUIReadOnly({ date: props.paymentStartDate || new Date(), format: CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT }),
                            totalOccurrences: props.neverExpires ? 9999 : props.noOfPayment,
                            relationshipId: salesInvoiceDetail.relationshipId,
                            amount: salesInvoiceDetail.invoiceTotalAmount - (salesInvoiceDetail.totalPaymentReceived || 0),
                            invoiceId: salesInvoiceDetail.invoiceMasterId,
                            invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                            account: props.account,
                            expiry: props.expiry,
                            paymentSource: 'Online',
                            isSubscription: (props.enableAutoPayment || (props.period && props.period !== "oneTimePayment")) ? 1 : 0,
                            projectMasterId: salesInvoiceDetail.projectMasterId,
                            alpidePaymentGatewayId: (salesInvoiceDetail.additionalInfoList[0] || {}).paymentGatewayId,
                            recurringInvoiceId: recurringInvoiceData.recurringInvoiceId,
                            formId: salesInvoiceDetail.registrationFormSettingId, 
                            module: salesInvoiceDetail.module, 
                            subscriptionName: `${salesInvoiceDetail.customerName || ''}_${props.salesInvoiceDetail.formName || ''}_${salesInvoiceDetail.relationshipId}-${salesInvoiceDetail.customerId}-${salesInvoiceDetail.invoiceMasterId}`,
                            firstName: props.firstName, 
                            lastName: props.lastName, 
                            email: props.emailAddress,
                            streetAddress1: billingAddress.streetAddress1,
                            streetAddress2: billingAddress.streetAddress2,
                            city: billingAddress.cityName,
                            state: billingAddress.stateName,
                            postal: billingAddress.zipCode,
                            billingZipCode: props.zipCode,
                        }
                        if (props.period === 'weekly') {
                            payload.intervalLength = 7;
                            payload.unit = 'days';
                        } else if (props.period === 'monthly') {
                            payload.intervalLength = 1;
                            payload.unit = 'months';
                        } else if (props.period === 'yearly') {
                            payload.intervalLength = 12;
                            payload.unit = 'months';
                        }

                        props.updateState({ paymentBtnClicked: true });

                        props.processCCPayment(props, payload);
                        // const modalData = {
                        //     modalBody: <FormattedMessage id='modal.customer.payInvoice.saveConfirmation' defaultMessage='' />,
                        //     handleSubmit: () => {

                        //     }
                        // };
                        // props.pushModalToStack(modalData);

                    }}

                >  <FormattedMessage id='pay.text' /> <span className='pl5'> {salesInvoiceDetail.isMultiCurrency ?
                    <span> {salesInvoiceDetail.foreignCurrencyIcon ? <i className={salesInvoiceDetail.foreignCurrencyIcon}></i> : salesInvoiceDetail.foreignCurrency + " "} </span>
                        : <span> {props.companyInfo.currencyIcon ?<i className={props.companyInfo.currencyIcon}></i>: props.companyInfo.currencyCode + " "}</span>
                        }{fixedDecimalNumber(salesInvoiceDetail.invoiceTotalAmount - (salesInvoiceDetail.totalPaymentReceived || 0))}
                    </span>
                </Button>
            </div>
        </div>
    );

}

export default injectIntl(ProccessPaymnet);
