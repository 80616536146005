import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BiddingSuppliersPopupComponent from '../../../../../components/modal/modalBody/supplier/BiddingSuppliersPopup';
import { deleteBiddingSupplierFromRFQ, } from './action';
import { fetchSupplierRFQDetail, resetRfqData } from './../RFQDetail/action';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';

class BiddingSuppliersPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            customerName: props.rfqDataForBidding.customerName,
            editingKey: '',
        }
    }
    componentWillMount() {
        this.props.resetRfqData();
       const companyInfo = this.props.companyInfo || {};
       const payload = { relationshipId: companyInfo.relationshipId, rfqMasterId: (this.props.rfqDataForBidding || {}).rfqMasterId, rfqChildMasterId: (this.props.rfqDataForBidding || {}).rfqChildMasterId };
       this.props.fetchSupplierRFQDetail(payload)

    }

    render() {
        return <>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <BiddingSuppliersPopupComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        suppliers: state.supplier.suppliers,
        rfqList: state.rfq.rfqList,
        rfqData: state.rfq.rfqData,
        listLoading: state.common.listLoading,
        detailLoading: state.common.detailLoading,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    deleteBiddingSupplierFromRFQ,
    generatePDF, printPDF,
    fetchSupplierRFQDetail,
    downloadFileFromBucket,
    resetRfqData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BiddingSuppliersPopup);