import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommunicationTemplate from '../../../../components/drawer/communication/communicationTemplate';


class communicationTemplateContainer extends Component {


  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
   
  }

  componentWillReceiveProps(props) {

  }


  render() {
    return <div>
      <CommunicationTemplate 
    //   initCalls={() => {
    //     this.initCalls();
    //   }} 
      {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      userStatus: state.common.userStatus,
     
     
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
   
    // pushModalToStack,
    // popModalFromStack,
    // showModal,
    // hideModal,
    // addRelationshipTaxIdentification,
    // fetchRelationshipTaxIdentifications,
    // generateEInvoiceDocument,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(communicationTemplateContainer);