import { PRODUCT_ACTION_LIST } from '../static/constants';

const initialState = {
  products: {
    1: []
  },
  outOfStockList: {
    1: []
  },
  reorderStockList: {
    1: []
  },
  lowStockList: {
    1: []
  },
  productCount: 0,
  outOfStockCount: 0,
  reorderStockCount: 0,
  lowStockCount: 0,
  inventoryValuation: 0,
  salesTransactions: {
    1: []
  },
  purchaseTransactions: {
    1: []
  },
  salesTransactionsCount: 0,
  purchaseTransactionsCount: 0,
  productDetails: {},
  productDetailsById: []
}

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_ACTION_LIST.PRODUCT_LIST:
      return {
        ...state,
        products: {
          ...state.products,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PRODUCT_ACTION_LIST.PRODUCT_COUNT:
      return { ...state, productCount: action.data };
    case PRODUCT_ACTION_LIST.PRODUCT_LIST_PAGE_RESET:
      return { ...state, products: initialState.products };
    case PRODUCT_ACTION_LIST.PRODUCT_OUT_OF_STOCK_COUNT:
      return { ...state, outOfStockCount: action.data };
    case PRODUCT_ACTION_LIST.PRODUCT_REORDER_STOCK:
      return { ...state, reorderStockCount: action.data };
    case PRODUCT_ACTION_LIST.PRODUCT_LOW_STOCK:
      return { ...state, lowStockCount: action.data };
    case PRODUCT_ACTION_LIST.PRODUCT_INVENTORY_VALUATION:
      return { ...state, inventoryValuation: action.data };
    case PRODUCT_ACTION_LIST.PRODUCT_OUT_OF_STOCK_LIST:
      return {
        ...state,
        outOfStockList: {
          ...state.outOfStockList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PRODUCT_ACTION_LIST.PRODUCT_OUT_OF_STOCK_LIST_PAGE_RESET:
      return { ...state, outOfStockList: initialState.outOfStockList };
    case PRODUCT_ACTION_LIST.PRODUCT_REORDER_STOCK_LIST:
      return {
        ...state,
        reorderStockList: {
          ...state.reorderStockList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PRODUCT_ACTION_LIST.PRODUCT_REORDER_STOCK_LIST_PAGE_RESET:
      return { ...state, reorderStockList: initialState.reorderStockList };
    case PRODUCT_ACTION_LIST.PRODUCT_LOW_STOCK_LIST:
      return {
        ...state,
        lowStockList: {
          ...state.lowStockList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PRODUCT_ACTION_LIST.PRODUCT_LOW_STOCK_LIST_PAGE_RESET:
      return { ...state, lowStockList: initialState.lowStockList };
    // case PRODUCT_ACTION_LIST.PRODUCT_SALES_TRANSACTIONS:
    //   return { ...state, salesTransactions: action.data };
    // case PRODUCT_ACTION_LIST.PRODUCT_PURCHASE_TRANSACTIONS:
    //   return { ...state, purchaseTransactions: action.data };
    case PRODUCT_ACTION_LIST.PRODUCT_SALES_TRANSACTIONS:
      return {
        ...state,
        salesTransactions: {
          ...state.salesTransactions,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PRODUCT_ACTION_LIST.PRODUCT_PURCHASE_TRANSACTIONS:
      return {
        ...state,
        purchaseTransactions: {
          ...state.purchaseTransactions,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PRODUCT_ACTION_LIST.PRODUCT_SALES_TRANSACTIONS_COUNT:
      return { ...state, salesTransactionsCount: action.data };
    case PRODUCT_ACTION_LIST.PRODUCT_PURCHASE_TRANSACTIONS_COUNT:
      return { ...state, purchaseTransactionsCount: action.data };
    case PRODUCT_ACTION_LIST.RESET_PRODUCT_PURCHASE_TRANSACTIONS:
      return { ...state, purchaseTransactions: initialState.purchaseTransactions };
    case PRODUCT_ACTION_LIST.RESET_PRODUCT_SALES_TRANSACTIONS:
      return { ...state, salesTransactions: initialState.salesTransactions };
    case PRODUCT_ACTION_LIST.PRODUCT_DETAILS:
      return { ...state, productDetails: action.data };
    case PRODUCT_ACTION_LIST.RESET_PRODUCT_DETAILS:
      return { ...state, productDetails: initialState.productDetails };
      case PRODUCT_ACTION_LIST.PRODUCT_INVENTORY_ITEMBYID:
        return { ...state, productDetailsById: action.data };
    default:
      return state;
  }
};

export default ProductReducer;