import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WarehouseCompo from '../../../../../components/modal/modalBody/common/Warehouse';
import { fetchCountries, fetchStates, showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { createUpdateWarehouse } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { fetchWarehouseNames } from '../../../../drawer/inventory/action';

class WarehouseContainer extends Component {
  constructor(props) {
    super(props);
    let formData = (props.data || {}).formData; 
    
    this.state = {
      formData
    };
  }
  componentDidMount() {
    //const companyInfo = this.props.companyInfo || {};
    // const payload = {
    //   relationshipId: companyInfo.relationshipId
    // };
    fetchDataIfNeeded('fetchCountries', 'countries', this.props);
  }

  
  componentWillReceiveProps(nextProps) {
    
    const warehouseAddress = (this.state.formData && this.state.formData.locationList && this.state.formData.locationList[0]) ? this.state.formData.locationList[0] : {};
   if (nextProps.countries !== this.state.countries && nextProps.countries && !this.state.isStateLoaded) {
      let obj = nextProps.countries.find(country => (country.countryName === warehouseAddress.countryName)) || {};
      if ((obj.countryName === 'India' || obj.countryName === 'United States' || obj.countryName === 'United States (USA)') && !nextProps.states.length) {
        this.props.fetchStates(obj.countryId);
      }
      this.setState({ isStateLoaded: true, currencyCode: obj.currencyCode});
    }
  }

  render() {
    return (
      <WarehouseCompo {...this.props} {...this.state} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    countries: state.common.countries,
    states: state.common.states,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createUpdateWarehouse,
  fetchCountries, 
  fetchStates,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  fetchWarehouseNames
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseContainer);
