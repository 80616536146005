import React, { Component, Fragment } from "react";
// import { AgGridReact } from "ag-grid-react";
import { Skeleton, Dropdown, Menu, Switch, Pagination, Empty, Tabs } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { getMomentDateForUIReadOnly, getUserDefinedName, sortTable } from "../../../utils";
import { CONSTANTS, ICONS } from "../../../static/constants";
import PageBreadcrumb from "../../PageBreadcrumb";
import RegistrationFormSetting from "../../../containers/modal/modalBody/settings/RegistrationFormSetting";
import ShareRegistrationFormCompo from "../../../components/modal/modalBody/settings/ShareRegistrationForm";
import AssignFormToTeacher from "../../../containers/modal/modalBody/schoolManagement/AssignFormToTeacher";
import { SolutionOutlined } from "@ant-design/icons";
import "./index.scss";

const { TabPane } = Tabs;

class FormListTableComp extends Component {
  render() {
    const {
      studentFormList,
      updateState,
      dir = 0,
    } = this.props;
    // const agGridStyle = {
    //   height: "100%",
    //   width: "100%",
    // };

    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='setting.student.registration.list.formName' defaultMessage='' />;
    //     },
    //     field: 'programName'
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='user.text' defaultMessage='' />;
    //     },
    //     cellRendererFramework: (link) => {
    //      let stdNameList = (link.data.orgRegFormSettingPermissionList || []).map(usrObj => `${usrObj.firstName} ${usrObj.lastName || ''}`).join(', ');
    //       return <div>{stdNameList}</div>
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <FormattedMessage id='setting.student.registration.list.date' defaultMessage='' />;
    //     },
    //     cellRendererFramework: (link) => {
    //       let d = JSON.parse(link.data.formSetting)
    //       return <div>{link.data?.dateCreated ? getMomentDateForUIReadOnly({ date: link.data.dateCreated, format }) : ''}</div>
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return <div>
    //         Action
    //       </div>;
    //     },
    //     field: 'action',
    //     cellRendererFramework: (params) => {

    //       return <div>
    //         <span className="cursor-pointer" title='Edit' onClick={
    //           () => {
    //             const modalData = {
    //               title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
    //               modalBody: <RegistrationFormSetting registrationType={params.data.registrationType} {...this.props} registrationFormSettingId={params.data.registrationFormSettingId} style={{ overflow: 'auto' }} />,
    //               width: '100%',
    //               hideFooter: true,
    //               maskClosable: true,
    //               hideCloseIcon: true,
    //               hideTitle: true,
    //               wrapClassName: "modal-custom-registration"
    //             };
    //             this.props.pushModalToStack(modalData);
    //           }}>
    //           <i className='fa fa-pencil' />
    //         </span>
    //         {!params.data.isAccountHolder ?
    //           <span style={{ marginLeft: '10px' }} title='Delete' className="cursor-pointer" onClick={
    //             () => {
    //               const modalData = {
    //                 modalBody: <FormattedMessage
    //                   id='registration.form.delete.confirmation'
    //                   defaultMessage=''
    //                 />,
    //                 handleSubmit: () => {
    //                   this.props.deleteRegistrationForm({
    //                     relationshipId: this.props.relationshipId,
    //                     registrationFormSettingId: params.data.registrationFormSettingId

    //                   }, this.props);
    //                   //deleteContact(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data)
    //                 }
    //               };
    //               this.props.pushModalToStack(modalData);
    //             }}>
    //             <i className='fa fa-trash' />
    //           </span> : ''
    //         }
    //          <span style={{ marginLeft: '10px' }} className="cursor-pointer" title='Assign Permission' onClick={
    //           () => {
    //             openAssignClass(params.data);
    //           }}>
    //           Review Access
    //         </span>

    // <span style={{ marginLeft: '10px' }} className="cursor-pointer" title='Share' onClick={
    //     () => {
    //       this.props.pushModalToStack({
    //         title: <div>
    //           <FormattedMessage id='shareRegistrationForm.text' defaultMessage='' />
    //           <div style={{ 'fontSize': '12px' }}> {params.data.programName || ''}</div>
    //         </div>,
    //         modalBody: <ShareRegistrationFormCompo {...this.props} formSettingData={params.data} />,
    //         width: '50%',
    //         hideFooter: true,
    //       })
    //     }}>
    //     <i className='fa fa-share' />
    //   </span>
    //       </div >
    //     },
    //     width: 52
    //   }
    // ];

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
              const modalData = {
                title: (
                  <div className="ant-modal-title">
                    <FormattedMessage
                      id="setting.student.registration.form.label"
                      defaultMessage=""
                    />
                  </div>
                ),
                modalBody: (
                  <RegistrationFormSetting
                    registrationType={e.registrationType}
                    {...this.props}
                    registrationFormSettingId={e.registrationFormSettingId}
                    style={{ overflow: "auto" }}
                  />
                ),
                width: "100%",
                hideFooter: true,
                maskClosable: true,
                hideCloseIcon: true,
                hideTitle: true,
                wrapClassName: "modal-custom-registration",
              };
              this.props.pushModalToStack(modalData);
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>
          <Menu.Item
            key="4"
            value="edit"
            onClick={() => {
              this.props.pushModalToStack({
                title: (
                  <div>
                    <FormattedMessage
                      id="shareRegistrationForm.text"
                      defaultMessage=""
                    />
                    <div style={{ fontSize: "12px" }}>
                      {" "}
                      {e.programName || ""}
                    </div>
                  </div>
                ),
                modalBody: (
                  <ShareRegistrationFormCompo
                    {...this.props}
                    formSettingData={e}
                  />
                ),
                width: "50%",
                hideFooter: true,
              });
            }}
          >
            <i className="fi fi-rr-share mr5" />
            Share
          </Menu.Item>

          {e && !e.isAccountHolder ? (
            <Menu.Item
              key="2"
              onClick={() => {
                const modalData = {
                  modalBody: (
                    <FormattedMessage
                      id="registration.form.delete.confirmation"
                      defaultMessage=""
                    />
                  ),
                  handleSubmit: () => {
                    this.props.deleteRegistrationForm(
                      {
                        relationshipId: this.props.relationshipId,
                        registrationFormSettingId: e.registrationFormSettingId,
                      },
                      this.props
                    );
                    //deleteContact(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data)
                  },
                };
                this.props.pushModalToStack(modalData);
              }}
            >
              <i className={ICONS.DELETE} />
              Delete
            </Menu.Item>
          ) : (
            ""
          )}

          <Menu.Item
            key="3"
            onClick={() => {
              openAssignClass(e);
            }}
          >
            <SolutionOutlined />
            Review Access
          </Menu.Item>
        </Menu>
      );
    };

    const openAssignClass = (data) => {
      this.props.pushModalToStack({
        title: (
          <div>
            <FormattedMessage
              id="setting.student.registration.form.permission.label"
              defaultMessage=""
            />
            <h6>{`${data.programName}`}</h6>
          </div>
        ),
        modalBody: (
          <AssignFormToTeacher
            {...this.props}
            registrationFormSettingId={data.registrationFormSettingId}
          />
        ),
        width: "50%",
        hideFooter: true,
        keyboard: true,
      });
    };

    const sortColumn = (e) => {
      sortTable(
        document.getElementById("forms-list-table"),
        e.target.cellIndex,
        1 - dir
      );
      updateState({ dir: 1 - dir });
    };

    return (
      <>
          <div className="view-container-actions">
          <Skeleton loading={this.props.headerLoading} paragraph={false} active>

            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="schoolManagement.registrationForms.table.heading"
                  defaultMessage=""
                />
              </div>

              {this.props.pageSize ? (
                <>
                  <div className="vertical-sep" />
                  {/* <div>{this.props.pageSize ? this.props.pageSize : ""}</div>
                   */}
                   <div>{studentFormList.length}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              {/* <button className="create-action-btn" onClick={() => {
              const modalData = {
                title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                modalBody: <RegistrationFormSetting  registrationType='student' {...this.props} style={{ overflow: 'auto' }} />,
                width: '100%',
                hideFooter: true,
                maskClosable: true,
                hideCloseIcon: true,
                hideTitle: true,
                keyboard: false,
                wrapClassName: "modal-custom-registration"
              };
              this.props.pushModalToStack(modalData);
            }}>
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button> */}
            </div>
          </Skeleton>
          </div>
          <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 15 }}>

          <div className="table-container no-pagination">
            <table id="forms-list-table">
              <thead>
                <tr>
                  <th width='5%'>
                    <FormattedMessage id="serial.no" />
                  </th>

                  <th onClick={sortColumn}>
                    <FormattedMessage id="setting.student.registration.list.formName" />{" "}
                    <i className={ICONS.SORT} />
                  </th>

                  {/* <th onClick={sortColumn}>
                    <FormattedMessage id="formType.text" />{" "}
                    <i className={ICONS.SORT} />
                  </th> */}

                  <th onClick={sortColumn}>
                    {getUserDefinedName('Deparment Name', this.props)} <i className={ICONS.SORT} />
                  </th>

                  <th onClick={sortColumn}>
                    <FormattedMessage id="user.text" defaultMessage="" />{" "}
                    <i className={ICONS.SORT} />
                  </th>

                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="common.dateCreated"
                      defaultMessage=""
                    />{" "}
                    <i className={ICONS.SORT} />
                  </th>

                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.status" defaultMessage="" />
                  </th>

                  <th width="6%">
                    <FormattedMessage id="common.actions" defaultMessage="" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {studentFormList && !!Object.keys(studentFormList).length ? (
                  studentFormList.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td width='5%'>{i + 1}</td>
                        <td>{e.formShortName}</td>
                        {/* <td>{e.formType}</td> */}
                        <td>{e.departmentName}</td>
                        <td>
                          {e.orgRegFormSettingPermissionList.map((name) => {
                            return `${name.firstName} ${name.lastName}`;
                          }).join(', ')}
                        </td>
                        <td>
                          {e.dateCreated
                            ? getMomentDateForUIReadOnly({
                                date: e.dateCreated,
                                format: CONSTANTS.TABLE_DATE_FORMAT,
                              })
                            : ""}
                        </td>
                        <td>
                          <div className="status-toggle">
                            <Switch
                              checked={e.isFormActive}
                              onChange={() => {
                                this.props.updateFormStatus(
                                  {
                                    relationshipId:
                                      this.props.companyInfo.relationshipId,
                                    registrationFormSettingId:
                                      e.registrationFormSettingId,
                                    isFormActive:
                                      e.isFormActive === 1 ? 0 : 1,
                                  },
                                  this.props
                                );
                              }}
                            />
                          </div>
                        </td>
                        <td style={{ paddingLeft: "50px" }}>
                          <Dropdown
                            overlay={actionMenu(e)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </Dropdown>
                        </td>

                        {/* <td>{e.programName}</td>
                      <td >
                        {e.orgRegFormSettingPermissionList.map((name)=>{
                            return `${name.firstName} ${name.lastName}, `
                        })}
                      </td>
                      <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: e.dateCreated, format }) : ''}</td>
                      <td style={{width : '8%', paddingLeft: '50px'  }}>
                        <span className="action-icon">
                          <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                            <MoreOutlined />
                          </Dropdown>
                        </span>
                      </td>

                      <td>{e.programName}</td>
                      <td >
                        {e.orgRegFormSettingPermissionList.map((name)=>{
                            return `${name.firstName} ${name.lastName}, `
                        })}
                      </td>
                      <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: e.dateCreated, format }) : ''}</td>
                      <td style={{width : '8%', paddingLeft: '50px'}}>
                        <span className="action-icon">
                          <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                            <MoreOutlined />
                          </Dropdown>
                        </span>
                      </td>

                      <td>{e.programName}</td>
                      <td >
                        {e.orgRegFormSettingPermissionList.map((name)=>{
                            return `${name.firstName} ${name.lastName}, `
                        })}
                      </td>
                      <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: e.dateCreated, format }) : ''}</td>
                      <td style={{width : '8%', padddingLeft: '50px'}}>
                        <span className="action-icon">
                          <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                            <MoreOutlined />
                          </Dropdown>
                        </span>
                      </td> */}
                        {/* <span className="cursor-pointer" title='Edit' onClick={
                        () => {
                          const modalData = {
                            title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                            modalBody: <RegistrationFormSetting registrationType={e.registrationType} {...this.props} registrationFormSettingId={e.registrationFormSettingId} style={{ overflow: 'auto' }} />,
                            width: '100%',
                            hideFooter: true,
                            maskClosable: true,
                            hideCloseIcon: true,
                            hideTitle: true,
                            wrapClassName: "modal-custom-registration"
                          };
                          this.props.pushModalToStack(modalData);
                        }}>
                        <i className='fa fa-pencil' />
                      </span>
                      {!e.isAccountHolder ?
                        <span style={{ marginLeft: '10px' }} title='Delete' className="cursor-pointer" onClick={
                          () => {
                            const modalData = {
                              modalBody: <FormattedMessage
                                id='registration.form.delete.confirmation'
                                defaultMessage=''
                              />,
                              handleSubmit: () => {
                                this.props.deleteRegistrationForm({
                                  relationshipId: this.props.relationshipId,
                                  registrationFormSettingId: e.registrationFormSettingId

                                }, this.props);
                                //deleteContact(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data)
                              }
                            };
                            this.props.pushModalToStack(modalData);
                          }}>
                          <i className='fa fa-trash' />
                        </span> : ''
                      }
                      <span style={{ marginLeft: '10px' }} className="cursor-pointer" title='Assign Permission' onClick={
                        () => {
                          openAssignClass(e);
                        }}>
                        Review Access
                      </span> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr key="empty-data">
                    <td colSpan={"100%"} style={{borderBottom:"none"}}>
                      <Empty />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* {
          Object.keys(studentFormList).length ?<table className="school_form_list">
              <thead>
                  <tr>
                      <th><FormattedMessage id='setting.student.registration.list.formName' /></th>
                      <th><FormattedMessage id='user.text' defaultMessage='' /></th>
                      <th><FormattedMessage id='setting.student.registration.list.date' defaultMessage='' /></th>
                      <th><FormattedMessage id='Action' defaultMessage='' /></th>
                  </tr>
              </thead>
              <tbody>
                {
                  studentFormList.map((e, i)=>{
                    return <tr key = {i}>
                      <td>{e.programName}</td>
                      <td >
                        {e.orgRegFormSettingPermissionList.map((name)=>{
                            return `${name.firstName} ${name.lastName}, `
                        })}
                      </td>
                      <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: e.dateCreated, format }) : ''}</td>
                      <td style={{width : '15%'}}>
                      <span className="cursor-pointer" title='Edit' onClick={
                        () => {
                          const modalData = {
                            title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                            modalBody: <RegistrationFormSetting registrationType={e.registrationType} {...this.props} registrationFormSettingId={e.registrationFormSettingId} style={{ overflow: 'auto' }} />,
                            width: '100%',
                            hideFooter: true,
                            maskClosable: true,
                            hideCloseIcon: true,
                            hideTitle: true,
                            wrapClassName: "modal-custom-registration"
                          };
                          this.props.pushModalToStack(modalData);
                        }}>
                        <i className='fa fa-pencil' />
                      </span>

                      {!e.isAccountHolder ?
                        <span style={{ marginLeft: '10px' }} title='Delete' className="cursor-pointer" onClick={
                          () => {
                            const modalData = {
                              modalBody: <FormattedMessage
                                id='registration.form.delete.confirmation'
                                defaultMessage=''
                              />,
                              handleSubmit: () => {
                                this.props.deleteRegistrationForm({
                                  relationshipId: this.props.relationshipId,
                                  registrationFormSettingId: e.registrationFormSettingId

                                }, this.props);
                                //deleteContact(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data)
                              }
                            };
                            this.props.pushModalToStack(modalData);
                          }}>
                          <i className='fa fa-trash' />
                        </span> : ''
                      }
                      <span style={{ marginLeft: '10px' }} className="cursor-pointer" title='Assign Permission' onClick={
                        () => {
                          openAssignClass(e);
                        }}>
                        Review Access
                      </span>
                      </td>
                    </tr>
                  })
                }
              </tbody>
          </table>
          : 'No form exists. You can create new one by clicking on Create button' 
        } */}
          {/* <div className='team-and-free'>
          <div className="agGridWrapper">
            <div className="ag-theme-balham" style={agGridStyle}>
              <AgGridReact
                // onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={{
                  flex: 1,
                  autoHeight: true,
                  wrapText: true,
                }}
                rowData={studentFormList || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                //getRowHeight={getRowHeight}
                suppressDragLeaveHidesColumns={true}
                animateRows={true}


                ////below id comment under ag(when uncommented)
              // onModelUpdated={onModelUpdated}
              // onColumnResized={onColumnResized}
              // onColumnVisible={(params) => {
              //   params.api.resetRowHeights();
              // }}
              // onDragStopped={() => {
              //   onColumnMoved({}, true);
              // }}
              //onColumnMoved={onColumnMoved}


              ></AgGridReact>
            </div>
          </div>
        </div> */}
        </Skeleton>
        <div className="footer">
        <Skeleton loading={this.props.headerLoading} paragraph={false} active>

          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={this.props.pageSize}
              // showTotal={this.props.pageSize}
              defaultPageSize={this.props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                // loadPageData(pageNumber, pageSize);
                // updateState({ pageSize: pageSize, pageNumber: pageNumber })
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                // handlePageSizeChange(1, pageSize, props)
              }}
            />
          </div>
        </Skeleton>
        </div>
      </>
    );
  }
}

export default injectIntl(FormListTableComp);
