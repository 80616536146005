import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Button, Col, Tooltip } from "antd";
// import { MultipleSelectDropdown } from '../../../../general/MultipleSelectDropdown';
import { Dropdown } from "../../../../general/Dropdown";
import "./index.scss";
// import * as filter from 'lodash.filter';
import { getUserDefinedName, showToasterMessage } from "../../../../../utils";
import { ICONS } from "../../../../../static/constants";
// import get from 'lodash.get';

const EnrollStudentComp = (props) => {
  const {
    studentDetail = {},
    classList = {},
    pageNumber = 1,
    companyInfo,
    // assignClasses = [...studentDetail?.schoolStudentClassRefList],
  } = props;

  // const dupClassList = [...classList[pageNumber]];
  const [sectionId, setSectionId] = useState(studentDetail.classSectionId || "");


  // const deleteAssignedClass = (idx) => {
  //   if (idx >= 0 && idx < assignClasses.length) {
  //     const tempList = assignClasses;
  //     tempList.splice(idx, 1);
  //     props.updateState({ assignClasses: tempList });
  //   } else {
  //     // imp console
  //   }
  // };

  return (
    <>
      {/* <div className="table-container" style={{ height: "500px" }}>
        <table>
          <thead>
            <tr>
              <th width="6%">S.No.</th>
              <th width="44%">{getUserDefinedName("Class", props)}</th>
              <th width="44%">Section</th>
              <th width="6%">
                Action{" "}
                {assignClasses.length === 0 ? (
                  <i
                    className={`${ICONS.PLUS} pointer`}
                    onClick={() =>
                      props.updateState({
                        assignClasses: [...assignClasses, {}],
                      })
                    }
                  />
                ) : (
                  ""
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {(assignClasses || []).length ? (
              (assignClasses || []).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <Dropdown
                        items={
                          dupClassList && dupClassList.length
                            ? dupClassList.filter(
                                (obj1) =>
                                  !assignClasses.some(
                                    (obj2) =>
                                      obj1.classId === obj2.classId &&
                                      obj1.classId !== item.classId
                                  )
                              )
                            : []
                        }
                        placeholder={getUserDefinedName("Select Class", props)}
                        optionKeyName="classId"
                        valueKeyName="className"
                        value={item.classId}
                        onSelect={(selectedValue) => {
                          const temp = assignClasses;
                          temp[i].classId = selectedValue;
                          // scenario if section is only one, auto select section
                          const sections =
                            (dupClassList || []).find(
                              (obj) => obj.classId === selectedValue
                            )?.classSectionList || [];

                          if (sections.length === 1) {
                            temp[i].classSectionId = sections[0].classSectionId;
                          } else {
                            temp[i].classSectionId = "";
                          }
                          props.updateState({ assignClasses: temp });
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        items={
                          (dupClassList || []).find(
                            (obj) => obj.classId === item.classId
                          )?.classSectionList
                        }
                        placeholder={"Select Section"}
                        optionKeyName="classSectionId"
                        valueKeyName="sectionName"
                        value={item.classSectionId}
                        onSelect={(selectedValue) => {
                          const temp = assignClasses;
                          temp[i].classSectionId = selectedValue;
                          props.updateState({ assignClasses: temp });
                        }}
                      />
                    </td>
                    <td>
                      <i
                        className={`${ICONS.DELETE} pointer`}
                        onClick={() => deleteAssignedClass(i)}
                      />
                      {i + 1 === assignClasses.length ? (
                        <i
                          className={`${ICONS.ADD} pointer ml10`}
                          onClick={() =>
                            props.updateState({
                              assignClasses: [...assignClasses, {}],
                            })
                          }
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div>
                No class assigned.{" "}
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    props.updateState({ assignClasses: [...assignClasses, {}] })
                  }
                >
                  Assign Now
                </span>
              </div>
            )}
          </tbody>
        </table>
      </div> */}

      <Row gutter={24}>
        <Col span={12}>
          <div className="i-label">
            {getUserDefinedName('Class', props)}
            <Tooltip 
              placement="right" 
              trigger={'click'}
              title={'This is class in which student has taken admission, you can asssign section if number student exceeded in particular class'}
            >
              <i className={`${ICONS.HELP} ml5 cursor-pointer`} />
              
            </Tooltip>
          </div>
          <Dropdown 
            value={studentDetail.className}
            disabled={true}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">
            Section
          </div>
            <Dropdown
              items={
                (classList[pageNumber] || []).find(
                  (obj) => obj.classId === studentDetail.classId
                )?.classSectionList
              }
              placeholder={"Select Section"}
              optionKeyName="classSectionId"
              valueKeyName="sectionName"
              value={sectionId}
              onSelect={(selectedValue,option) => {
                setSectionId(selectedValue)
                
              }}
            />
        </Col>
      </Row>
      <hr className="mt10" style={{marginLeft: '-25px', marginRight: '-25px'}}></hr>
      <Row justify="space-between" className="mt10">
        <Button
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={() => {
            // for (const item in assignClasses) {
            //   if (
            //     !assignClasses[item].classId ||
            //     !assignClasses[item].classSectionId
            //   ) {
            //     return showToasterMessage({
            //       messageType: "error",
            //       description: getUserDefinedName(
            //         "assign.classSection.validation.text",
            //         props
            //       ),
            //     });
            //   }
            // }
            // if(!sectionId){
            //   return showToasterMessage({
            //     messageType: "error",
            //     description: getUserDefinedName(
            //       "assign.classSection.validation.text",
            //       props
            //     ),
            //   });
            // }

            const assignClasses = [{
              classId: studentDetail.classId,
              classSectionId: sectionId || 0,
              studentClassRefId: studentDetail.studentClassRefId || 0
            }]

            const modalData = {
              modalBody: "Are you sure you want to submit?",
              handleSubmit: () => {
                props.saveOrUpdateStudentClassRef(
                  {         
                    orgRegistrationId: studentDetail.orgRegistrationId,
                    formId: studentDetail.formId,
                    relationshipId: companyInfo.relationshipId,
                    schoolStudentId: studentDetail.schoolStudentId,
                    schoolStudentClassRefList: assignClasses,
                    createdByEmpId: (props.userInfo || {})
                      .relationshipEmployeeId,
                  },   
                  props
                );
                props.popModalFromStack();
              },
            };
            props.pushModalToStack(modalData);

          }}
        >
          <FormattedMessage id="button.submit.label" defaultMessage="" />
        </Button>
      </Row>
    </>
  );
};

export default EnrollStudentComp;
