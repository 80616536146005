import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
//import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Input, Menu, Button, Dropdown, Tooltip, } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from '../../../../utils';
//import StudentRegistrationDetail from '../../../../containers/modal/modalBody/customer/StudentRegistrationDetail';
//import RegistrationFormComponent from '../../../../containers/modal/modalBody/settings/RegistrationFormSettingPreview';
//import StudentDetails from '../../../../containers/modal/modalBody/customer/StudentDetail';
//import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import { CONSTANTS, MODAL_TYPE, CONSTANTS_TRANSACTIONS } from '../../../../static/constants';
import { DropdownRef } from '../../../general/Dropdown';
//import { FileExcelOutlined } from '@ant-design/icons';
//const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { Search } = Input;


const RegistrationListingComponent = (props) => {
    const { registerParentsData, updateState, companyInfo, pageNumber } = props;
    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, formId: props.selectedFormId };
        fetchPaginationDataIfNeeded('getRegisterParentsData', 'registerParentsData', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, formId: props.selectedFormId };
        props.resetRegisterParentsData();
        props.getRegisterParentsData(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }


    // const openStudentDetails = (data) => {
    //     if (data.studentCount > 0) {
    //         props.pushModalToStack({
    //             modalBody: <StudentDetails {...props} registrationData={{ ...data }} />,
    //             title: <FormattedMessage id='studentDetail.text' defaultMessage='' />,
    //             width: '80%',
    //             hideFooter: true,
    //             wrapClassName: ''
    //         })
    //     }
    // }

    // const updateRegistraion = (data) => {
    //     props.pushModalToStack({
    //         modalBody: <RegistrationFormComponent registrationData={data} registrationFormSettingId={data.registrationFormSettingId} relationshipId={(props.companyInfo || {}).relationshipId} />,
    //         title: <FormattedMessage id='preview.text' />,
    //         width: '100%',
    //         hideFooter: true,
    //         wrapClassName: 'modal-custom-detail'
    //     })
    // }

    // const openStudentRegistartionDetails = (data) => {
    //     props.pushModalToStack({
    //         modalBody: <StudentRegistrationDetail {...props} registrationData={data} />,
    //         width: '100%',
    //         hideFooter: true,
    //         wrapClassName: 'modal-custom-detail'
    //     })
    // }

    const addItemConfirmationModal = (props, text, studentData) => {
        const { showModal, companyInfo } = props;
       
        const modalBody = <span>
            <FormattedMessage
                id='setItem.confirmation'
                defaultMessage=''
            />
           
      </span>;

        let payload = {
            userStatus: text,
            relationshipId: companyInfo.relationshipId,
            studentRegistrationId: studentData.studentRegistrationId,
            studentId: studentData.studentId,
            pageNumber: props.pageNumber, 
            pageSize: props.pageSize, 
            formId: props.selectedFormId
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateStudentUserStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    }



    const updateUserStatus = (props, studentData) => {
        let payload = { formData: {}, txData: studentData };
        const formData = payload.formData;
        const { pushModalToStack } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, studentData);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.STUDENT },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, studentData);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    const actionMenu = (param) => {
        return (
            <Menu className="cell-action-dropdown">
                <Menu.Item key="0" value="view" onClick={() => {
                    updateUserStatus(props, param.data);
                }}>
                    <span className="cursor-pointer"><FormattedMessage id='modal.txDetails.common.updateStatus' /></span>
                </Menu.Item>

               

                {/* {
                    permittedModules.secondSection.cloneCustomer
                        ? (
                            <Menu.Item onClick={() => cloneCustomer(param)} key="8">
                                <i className="fa fa-copy" />
                                <a>Clone Customer</a>
                            </Menu.Item>
                        )
                        : <Fragment />
                } */}

            </Menu >
        )
    };



    // const _isColumnToHide = (columnName) => {
    //     return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    // }

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='father.name' />;
            },
            field: "Father First Name",
            // hide: _isColumnToHide('fatherName'),
            colId: 'Father First Name_1',
            cellRendererFramework: (param) => {
                return <div>{(param?.data?.['Father First Name'] || '') + ' ' + (param?.data?.['Father Last Name'] || '')}</div>
            },
            minWidth: 70,
            maxWidth: 400,
            suppressMovable: true,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='mother.name' />;
            },
            field: "motherFirstName",
            // hide: _isColumnToHide('motherName'),
            colId: 'motherFirstName_1',
            cellRendererFramework: (param) => {
                return <div>{(param?.data?.['Mother First Name'] || '') + ' ' + (param?.data?.['Mother Last Name'] || '')}</div>
            },
            minWidth: 150,
            maxWidth: 400,
            suppressMovable: true,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='student.text' />;
            },
            field: "student",
            colId: 'student_1',
            minWidth: 70,
            maxWidth: 300,
            suppressMovable: true,
            resizable: true,
            cellRendererFramework: (param) => <div>{(param?.data?.['First Name'] || '') + ' ' + (param?.data?.['Last Name'] || '')}</div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='grade.text' defaultMessage='' />;
            },
            field: "Grade",
            // hide: _isColumnToHide('fatherMobile'),
            colId: 'Grade_1',
            minWidth: 70,
            maxWidth: 300,
            suppressMovable: true,
            resizable: true,
            cellRendererFramework: (link) => <div>{link.value || '-'}</div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='dob.text' defaultMessage='' />;
            },
            field: "Date of Birth",
            // hide: _isColumnToHide('fatherMobile'),
            colId: 'Date of Birth_1',
            minWidth: 70,
            maxWidth: 300,
            suppressMovable: true,
            resizable: true,
            cellRendererFramework: (link) => <div>{link.value || '-'}</div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='enrollmentDate.text' defaultMessage='' />;
            },
            field: "Enrollment Date",
            minWidth: 122,
            maxWidth: 300,
            suppressMovable: true,
            colId: "Enrollment Date_1",
            cellRendererFramework: (param) => {
                return <div>
                    <Tooltip placement="topLeft" title={(param.data['Enrollment Date'] && getMomentDateForUIReadOnly({date: param.data['Enrollment Date'], format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
                        {(param.data['Enrollment Date'] && getMomentDateForUIReadOnly({date: param.data['Enrollment Date'], format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                    </Tooltip>
                </div>
            },
            resizable: true
        },

        {
            headerComponentFramework: () => {
                return <FormattedMessage id='fatherMobile.text' defaultMessage='' />;
            },
            field: "Father Mobile",
            // hide: _isColumnToHide('fatherMobile'),
            colId: 'Father Mobile_1',
            minWidth: 70,
            maxWidth: 300,
            suppressMovable: true,
            resizable: true,
            cellRendererFramework: (link) => <div>{link.value || '-'}</div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='motherMobile.text' defaultMessage='' />;
            },
            field: 'Mother Mobile',
            // hide: _isColumnToHide('motherMobile'),
            colId: "Mother Mobile_1",
            resizable: true,
            suppressMovable: true,
            minWidth: 70,
            maxWidth: 300,
            cellRendererFramework: (link) => <div>
                {link.data['Mother Mobile'] || "-"}
            </div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='addItem.text.userStatus' />;
            },
            field: 'Student Status',
            // hide: _isColumnToHide('motherMobile'),
            colId: "Student Status_1",
            resizable: true,
            suppressMovable: true,
            minWidth: 70,
            maxWidth: 300,
            cellRendererFramework: (link) => <div>
                {link.value || '-'}
            </div>,
        },
       
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='setting.student.registration.form.emergencyContactName' defaultMessage='' />;
            },
            field: 'Emergency Contact Name',
            // hide: _isColumnToHide('emergencyContactName'),
            colId: "Emergency Contact Name_1",
            resizable: true,
            suppressMovable: true,
            minWidth: 70,
            maxWidth: 400,
            cellRendererFramework: (link) => <div>
                {link.value || '-'}
            </div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='setting.student.registration.form.emergencyPhone' defaultMessage='' />;
            },
            field: 'Emergency Phone',
            // hide: _isColumnToHide('emergencyPhone'),
            colId: "Emergency Phone_1",
            resizable: true,
            suppressMovable: true,
            minWidth: 70,
            maxWidth: 400,
            cellRendererFramework: (link) => <div>
                {link.value || '-'}
            </div>,
        },

        {
            headerComponentFramework: () => {
                return <div className="cursor-pointer">

                </div>;
            },
            cellRendererFramework: (param) => {
                return <Dropdown overlay={actionMenu(param)} trigger={['click']}>
                    <Button className="action-btn small">
                        <i className="fa fa-ellipsis-v" />
                    </Button>
                </Dropdown>
            },
            resizable: true,
            pinned: 'right',
            field: 'action',
            colId: "action_1",
            minWidth: 52,
            maxWidth: 52,
            suppressMovable: true,
            cellClass: 'lastActionCell'
        }
    ];

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };



    const onGridReady = (params) => {
        props.updateState({
            gridApi: params.api,
            gridColumnApi: params.columnApi
        });
        if (props.isColumnFitTable && params.api) {
            params.api.sizeColumnsToFit();
            props.updateState({
                isColumnFitTable: false
            })
        }
        // onDataRendered(params);
    }

    const onModelUpdated = (params) => {
        //params.api.resetRowHeights();
        if ((registerParentsData[pageNumber] || []).length && !props.isStudentRowHeightSet) {
            setTimeout(() => {
                params.api.resetRowHeights();
            }, 2000)
            props.updateState({
                isStudentRowHeightSet: true
            })
        }
    }

    return (
        <Fragment>

            <div className='view-container mt0'>

                <Skeleton loading={props.isLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    <div className="table-action-container">
                        <div className="d-inline-flex" style={{ marginLeft: '15px', marginTop: '5px' }}>
                        <DropdownRef
                                style={{ width: "210px", marginRight: '10px', marginTop: '-16px' }}
                                items={props.allForms || []}
                                valueKeyName='formName'
                                optionKeyName='registrationFormSettingId'
                                placeholder='Select Form'
                                value={props.selectedFormId}
                                onSelect={
                                    (formId) => {
                                        updateState({ selectedFormId: formId });
                                        const payload = {
                                            companyInfo: props.companyInfo,
                                            relationshipId: props.companyInfo.relationshipId,
                                            formId: formId,
                                            pageNumber: props.pageNumber || 1,
                                            pageSize: props.pageSize || 25
                                        };
                                        props.getRegisterParentsData(payload);
                                        props.getTotalParentsCount(payload);
                                        props.getTotalStudentsCount(payload);
                                    }}
                            />
                            <Search
                                placeholder="Enter Searched Text"
                                value={props.searchedText}
                                onChange={e => {
                                    updateState({ searchedText: e.target.value, pageNumber: pageNumber || 1 });
                                    props.gridApi.setQuickFilter(e.target.value);
                                }}
                                onSearch={(searchedText) => {
                                    updateState({ searchedText, pageNumber: pageNumber || 1 });
                                    props.gridApi.setQuickFilter(searchedText);
                                }}
                                style={{ width: 330, height: 36 }}
                            />
                            
                            {/* {props.totalStudentCount ?
                                <div className="small-btn-group" style={{ marginLeft: '10px' }}>
                                    <button title="Export to Excel" className="ant-btn ant-btn-default" onClick={() => {
                                        props.getRegistrationDataForExcel({ relationshipId: props.companyInfo.relationshipId, formId: props.selectedFormId })
                                    }}>{<FileExcelOutlined />}</button >
                                </div> : ''
                            } */}
                        </div>
                    </div>
                    <div className='agGridWrapper' style={{ marginLeft: '15px', marginRight: '15px' }}>
                        <div className="ag-theme-balham" style={agGridStyle} >

                            <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={registerParentsData[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                animateRows={true}

                                onModelUpdated={onModelUpdated}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </Skeleton>
                <Pagination
                    size="small"
                    total={props.totalStudentCount}
                    showTotal={showTotal}
                    defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber|| 1, pageSize, props)
                    }}
                />
            </div>
        </Fragment>
    );
};

export default injectIntl(RegistrationListingComponent);
