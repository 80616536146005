import React from "react";
import JournalReport from "../../../components/finance/JournalReport";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchJournalReportList, getJournalReportCount, resetPaginatedData } from "./action";
import { pushModalToStack , popModalFromStack, showModal, hideModal} from "../../../actions/commonActions";

 class JournalReportComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      pageSize: 200,
      isStudentLoaded: false,
      formNameMap: {},
      customerInfo: props.customerInfo,
     
    };
  }

  
  componentDidMount() {

    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 200
    };
    this.props.fetchJournalReportList(payload, companyInfo);
    this.props.getJournalReportCount(payload, companyInfo)
  }

  render() {
    return (
      <>
        <JournalReport
          {...this.props}
          {...this.state}
           updateState={(data) => {
            this.setState(data);
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      isLoading: state.common.isLoading,
      permissions: state.auth.permissions,
      crmLeadFormList: state.crm.crmLeadFormList,
      crmLeadFormListCount: state.crm.crmLeadFormListCount,
      journalReportList : state.finance.journalReportList,
      journalReportCount :  state.finance.journalReportCount, 
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchJournalReportList,
    getJournalReportCount,
    pushModalToStack,
    popModalFromStack,
    showModal, 
    hideModal,
    resetPaginatedData
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JournalReportComp);
