import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportComponent from '../../../../../components/modal/modalBody/product/Import';
import { fetchDataIfNeeded } from '../../../../../utils';
import {
    
} from '../../../../../actions/commonActions';
import {
    fetchCategory, deleteCategory, addCategory,
    fetchManufacturer, deleteManufacturer, addManufacturer,
    fetchBrand, deleteBrand, addBrand,
    fetchWarehouseNames,
    fetchItemVariantAttributes, deleteAttribute, addAttribute
} from '../../../../drawer/inventory/action';
import { downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import { importProducts,downloadExelFile,getProductImportHeader,downloadExelSampleFile } from './action';


class ImportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            // headerMandatoryValues:[],
            // headerValues:[]
        };
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId };
        fetchDataIfNeeded('fetchCategory', 'productCategories', this.props, payload);
        fetchDataIfNeeded('fetchManufacturer', 'manufacturers', this.props, payload);
        fetchDataIfNeeded('fetchBrand', 'productBrands', this.props, payload);
        fetchDataIfNeeded('fetchWarehouseNames', 'allWarehouses', this.props, payload);
        fetchDataIfNeeded('fetchItemVariantAttributes', 'variantAttributes', this.props, payload);
        this.props.getProductImportHeader(companyInfo.relationshipId,this.props)
    }

    componentWillReceiveProps(){
    //     if(this.props.importProductHeader){
    //         let headerValues = this.props.importProductHeader?.map((item)=>{
    //                    return item.columnName
    //         })
    //         let headerMandatoryValues = this.props.importProductHeader?.map((item)=>{
    //             if(item.mandatory){
    //                 return true
    //             }else{
    //                  return false
    //             }
    //  })
    //         this.setState({
    //             headerValues:headerValues,
    //             headerMandatoryValues:headerMandatoryValues
    //           });
    //     }
    }

    render() {
        return <ImportComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        productCategories: state.inventory.productCategories,
        productBrands: state.inventory.productBrands,
        manufacturers: state.inventory.manufacturers,
        allWarehouses: state.inventory.allWarehouses,
        variantAttributes: state.inventory.variantAttributes,
        companyInfo: state.common.companyInfo,
        importProductHeader:state.common.productImportHeader,
        headerMandatoryValues: state.common.headerMandatoryValues,
        headerValues: state.common.headerValues,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCategory,
    deleteCategory,
    downloadExelSampleFile,
    addCategory,
    fetchManufacturer,
    deleteManufacturer,
    addManufacturer,
    fetchBrand,
    deleteBrand,
    addBrand,
    fetchWarehouseNames,
    fetchItemVariantAttributes,
    deleteAttribute,
    addAttribute,
    importProducts,
    downloadFileFromBucket,
    getProductImportHeader,
    downloadExelFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
