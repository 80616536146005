import React, { Fragment } from "react";
import { Row, Col, Button, Input, Popover, Tooltip } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';

import { Dropdown } from "../../../../general/Dropdown";
import { AddAndRemoveColumn } from "../../../../general/AddAndRemoveColumn"
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import {
    MODAL_TYPE, MODULE_CODED_VALUES, PERMISSION_VALUES
} from '../../../../../static/constants'
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
import { fixedDecimalAndFormateNumber, showToasterMessage } from '../../../../../utils';
import { CustomTableUpload } from "../../../../general/UploadTable";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";


class CustomerProductDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            sectionVisible: {
                discount: false,
                tax: false,
                expense: false
            },
            total: 0,
            subtotal: 0,
            subtotalWithoutDiscount: 0,
            totalDiscount: 0,
            expenseAmount: 0,
            totalTax: 0,
            itemLevelTaxes: [],
            rootDiscountPercent: 0,
            rootDiscountAmount: 0
        }
    }

    calculateSubtotal = () => {
        let total = 0;
        let subtotal = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let itemLevelTaxes = []
        let subtotalWithoutDiscount = 0;
        let rootDiscountAmount = 0;
        let self = this;

        this.gridApi.forEachNode(function (rowNode, index) {
            let stringifiedRowData = JSON.stringify(rowNode.data);
            let totalAmountOfColumn = 0;
            const rowdata = JSON.parse(stringifiedRowData);
            if (rowdata.qty && rowdata.rate) {
                let taxObj = {
                    subtotal: 0,
                    taxPercent: 0,
                    taxAmount: 0
                }
                totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
                subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
                if (rowdata.discount) {
                    let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
                    totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                    totalDiscount = totalDiscount + discountAmount;
                }

                if (self.state.rootDiscountPercent) {
                    let itemLevelDiscountAmount = (Number(self.state.rootDiscountPercent) * 0.01) * totalAmountOfColumn;
                    totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
                    totalDiscount = totalDiscount + itemLevelDiscountAmount
                    rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
                }

                if (rowdata.tax) {
                    taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
                    let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
                    totalTax = totalTax + taxAmount;
                }
                if (taxObj.taxPercent) {
                    taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
                    const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                    if (availableTaxObj) {
                        itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                            if (ele.taxPercent === taxObj.taxPercent) {
                                ele.subtotal = Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                                ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
                            }
                            return true;
                        });
                    } else {
                        taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
                        itemLevelTaxes.push(taxObj);
                    }
                }
                subtotal = Number(subtotal) + totalAmountOfColumn;
            }
        });


        total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;

        this.setState({
            total: Number(total || 0).toFixed(2),
            subtotal: Number(subtotal || 0).toFixed(2),
            totalDiscount: Number(totalDiscount || 0).toFixed(2),
            totalTax: Number(totalTax || 0).toFixed(2),
            subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
            itemLevelTaxes,
            rootDiscountAmount
        });
    }


    componentDidMount() {
        setTimeout(() => {
            this.calculateSubtotal();

        }, 3500)
    }

    handleNewTaxAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.NEW_TAX,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                addFunc = props.addUOM;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId });
                this.gridApi && this.gridApi.stopEditing();
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                deleteFunc = props.deleteUOM;
                break;
            }
            case MODAL_TYPE.NEW_TAX: {
                deleteFunc = props.deleteTax;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }

    render() {
        let self = this;
        if (this.gridApi && this.props.priceTypeToApply && this.props.isToPriceTypeToApply) {
            this.gridApi.forEachNode(function (rowNode, index) {
                if (rowNode.data.product) {
                    let rate = self.props.priceTypeToApply === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0] ? Number(rowNode.data.variant.retailPrice || 0).toFixed(2) : Number(rowNode.data.variant.wholesalePrice || 0).toFixed(2);
                    rowNode.setData({ ...rowNode.data, rate, baseRate: rate });
                }
            });
            this.props.updateState({
                isToPriceTypeToApply: false
            })
        }

        const saveColumnIndex = (columnName, index) => {
            if (!columnName || index < 0) return;
            let data = this.props.txPreferences || {};
            let gridPreference = {};
            let columnSetting = this.props.txColumnSetting || {}
            data.relationshipId = (this.props.companyInfo || {}).relationshipId;
            data.clientUserAccountId = (this.props.userInfo || {}).userId;
            if (data.gridPreference) {
                gridPreference = JSON.parse(data.gridPreference);
            }
            let fieldSetting = columnSetting[columnName] || {};
            fieldSetting.index = index;
            columnSetting[columnName] = fieldSetting;
            gridPreference.columnSetting = columnSetting;
            data.gridPreference = JSON.stringify(gridPreference);
            data.preferenceId = this.props.preferenceId;
            data.tablePreference = this.props.tablePreference;
            this.props.saveUserPrefrences(data);
        }

        const saveColumnWidth = (columnName, width) => {
            if (!columnName || !width) return;
            let data = this.props.txPreferences || {};
            let gridPreference = {};
            let columnSetting = this.props.txColumnSetting || {}
            data.relationshipId = (this.props.companyInfo || {}).relationshipId;
            data.clientUserAccountId = (this.props.userInfo || {}).userId;
            if (data.gridPreference) {
                gridPreference = JSON.parse(data.gridPreference);
            }
            let fieldSetting = columnSetting[columnName] || {};
            fieldSetting.width = width;
            columnSetting[columnName] = fieldSetting;
            gridPreference.columnSetting = columnSetting;
            data.gridPreference = JSON.stringify(gridPreference);
            data.preferenceId = this.props.preferenceId;
            data.tablePreference = this.props.tablePreference;
            this.props.saveUserPrefrences(data);
        }

        let moveEvent = {};
        const onColumnMoved = (event, isDragStopped) => {
            if (event.column) {
                moveEvent = event;
            }
            if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
                saveColumnIndex(moveEvent.column.colDef.field, moveEvent.toIndex);
            }
        }
        const onColumnResized = (event) => {
            event.api.resetRowHeights();
            if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
                //event.api.resetRowHeights();
                if (this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                    const columnArray = event.columnApi.getColumnState();
                    for (let i = (columnArray.length - 1); i >= 0; i--) {
                        if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
                            columnArray[i].width = columnArray[i].width + (this.gridColumnApi.columnController.scrollWidth - this.gridColumnApi.columnController.bodyWidth);
                            break;
                        }
                    }
                    saveColumnWidth(columnArray);
                } else {
                    saveColumnWidth(event.columnApi.getColumnState());
                }
            }
        }

        const content = <AddAndRemoveColumn {...this.props} />



        const { txColumnSetting } = this.props;
        const { sectionVisible, itemLevelTaxes } = this.state;
        const isPlaceOfSupplySameAsRelationship = (this.props.relationshipBillingAddress || {}).stateName === this.props.placeOfSupply;
        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        }

        const _isColumnToHide = (columnName) => {
            // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
            //     return true;
            // }
            return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
        }

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.product' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
                resizable: true,
                editable: !this.props.isRFQConversion,
                sortable: true,
                permissions: this.props.permissions,
                moduleName: MODULE_CODED_VALUES.PRODUCTS,
                operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                width: (this.props.txColumnSetting[AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD] || {}).width,
                cellEditor: 'customTreeDropDown',
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight >= (params.node.data.maxHeight || 30)) {
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        } else {
                            params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.product)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        options: this.props.products,
                        onSearch: this.props.onSearch,
                        onAddNewClick: () => {
                            self.props.updateHeaderState({
                                inventory: {
                                    inventoryDrawerVisible: true
                                }
                            });
                        },
                        canAddNew: true,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.PRODUCTS,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        value: obj.node.data.selectedProductValue || [],
                        selectedLabel: obj.node.data.product || "",
                        allowClear: true,
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.selectedProductValue = obj.node.selectedProductValue;
                            obj.node.data.productObj = null;
                            obj.node.data.variant = null;
                            const itemId = obj.node.data.selectedProductValue && obj.node.data.selectedProductValue[0];
                            if (itemId) {
                                const variantId = obj.data.selectedProductValue && obj.data.selectedProductValue.length === 2 ? obj.data.selectedProductValue[1] : 0;
                                obj.node.data.productObj = find(this.props.products, { itemId: Number(itemId) }) || {};
                                obj.node.data.variant = variantId ? find(obj.node.data.productObj.inventoryItemVariantList, { variantId: Number(variantId) }) : obj.node.data.productObj.inventoryItemVariantList[0];
                                if (obj.node.data.productObj.description) {
                                    obj.node.data.description = obj.node.data.productObj.description;
                                }
                            }
                            if (this.props.priceTypeToApply && obj.node.data.variant && !this.props.isDataToUpdate) {
                                const rate = this.props.priceTypeToApply === AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0] ? Number(obj.node.data.variant.retailPrice || 0).toFixed(2) : Number(obj.node.data.variant.wholesalePrice || 0).toFixed(2);
                                obj.node.data.baseRate = Number(rate);
                                obj.node.data.rate = Number(rate);
                                //obj.node.setDataValue('specialDiscount', 0);
                                //obj.node.setDataValue('baseRate', Number(rate));
                                //obj.node.setDataValue('rate', Number(rate));
                                //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                                obj.node.data.uom = obj.node.data.variant.uomName;
                                // if (obj.node.data.productObj.isPopulateTax) {
                                //     const defaultItemHsnAndTax = obj.node.data.productObj.inventoryItemDefaultTaxList[0] || {};
                                //     obj.node.data.hsnCode = ((defaultItemHsnAndTax || {}).hsnCode);
                                //     const selectedTax = find(this.props.taxes, { taxPercent: ((defaultItemHsnAndTax || {}).igstRate || (defaultItemHsnAndTax || {}).cgstRate || (defaultItemHsnAndTax || {}).globleTaxRate) }) || {};
                                //     obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                                //     obj.node.data.tax = selectedTax.taxPercent;
                                // }
                            }
                            let isDuplicateItem = false;
                            this.gridApi && this.gridApi.forEachNode((rowNode, index) => {
                                const { data } = rowNode;
                                if (data.product === selectedValue && index !== obj.node.rowIndex) {
                                    isDuplicateItem = true;
                                }
                            });
                            if (isDuplicateItem) {
                                showToasterMessage({
                                    messageType: 'warning', description: 'Duplicate product is selected'
                                });
                            }

                            obj.node.data.isDuplicateItem = isDuplicateItem;
                        }
                    }
                },
                cellClassRules: {
                    'ag-grid-cell-warning-boundary': function (obj) {
                        let isDuplicateItem = false;
                        self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                            const { data } = rowNode;
                            if (data.product && data.product === obj.node.data.product && obj.node.rowIndex !== index) {
                                isDuplicateItem = true;
                            }
                        });
                        return isDuplicateItem;
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.description' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
                cellClass: " ag-cell-description",
                editable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DESCRIPTION),
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.description || "",
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value

                        }
                    }
                },
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.description)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > (params.data.minHeight || 30)) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${(params.node.data.minHeight || 30)}px`;
                        }
                        params.node.setRowHeight((params.node.data.minHeight || 30));
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.qty' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
                editable: true,
                sortable: true,
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                cellEditorParams: (obj) => {
                    return { 
                        decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthQty,
                        onChange: (value) => {
                            obj.node.data.qty = value;
                            obj.api.refreshCells();
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.uom' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
                editable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.uomList,
                        optionKeyName: 'uomId',
                        canAddNew: true,
                        canDelete: true,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.UOM,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM)
                        },
                        valueKeyName: 'uomName',
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                        },
                    }
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.hsn' defaultMessage='' />;
                },
                field: 'hsnCode',
                editable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HSN) || (this.props.companyInfo || {}).countryName !== 'India',
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.data.hsnCode = value;
                            obj.api.refreshCells();

                        }
                    }
                },
            },
            {
                headerComponentFramework: () => {
                    return <div> <span>MRP</span><Tooltip placement="right" title={this.props.intl.formatMessage(
                        {
                            id: 'tooltip.originalRate',
                            defaultMessage: ''
                        }
                    )} trigger="click">
                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                    </Tooltip></div>;
                },
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.BASE_PRICE),
                field: "baseRate"
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.rate' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
                editable: true,
                sortable: true,
                cellEditor: 'customNumberEditor',
                cellEditorParams: (obj) => {
                    return {
                        decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
                        onChange: (value) => {
                            obj.node.data.baseRate = value;
                            obj.node.data.rate = value;
                            obj.api.refreshCells();

                        }
                    }
                },
                valueGetter: (params) => {
                    if (params.data.specialDiscount && params.data.rate && Number(params.data.rate) !== 0) {
                        params.data.rate = params.data.baseRate - ((params.data.specialDiscount * .01) * params.data.baseRate);
                    }
                    if (!params.data.specialDiscount && params.data.baseRate) {
                        params.data.rate = params.data.baseRate;
                    }
                    return params.data.rate && Number(params.data.rate || 0).toFixed((this.props.companyInfo || {}).decimalDigitsLengthPrice || 0);
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.specialDiscount' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
                editable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT),
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.discount' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
                editable: true,
                sortable: true,
                hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0,
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                cellEditorParams: (obj) => {
                    return { 
                        decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
                        maxValue: 100,
                        onChange: (value) => {
                            obj.node.data.discount = value;
                            obj.api.refreshCells();
                        }
                     }
                  }
            },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='customer.salesQuote.form.gridHeader.tax' defaultMessage='' />;
            //     },
            //     field: 'taxApplied',
            //     editable: true,
            //     sortable: true,
            //     cellEditor: 'customDropDownEditor',
            // suppressKeyboardEvent: (params) => {
            //     const KEY_ENTER = 13;
            //     const keyCode = params.event.keyCode;
            //     const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //     return gridShouldDoNothing;
            // },
            //     cellEditorParams: (obj) => {
            //         return {
            //             lastColumnCell: true,
            //             items: this.props.taxes,
            //             optionKeyName: 'taxSingleRateId',
            //             canAddNew: true,
            //             canDelete: true,
            //             deleteClickHandler: (payload) => {
            //                 this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
            //             },
            //             onAddNewClick: (payload) => {
            //                 payload.formData = {
            //                     taxName: payload.textEntered,
            //                     submittedOnce: false
            //                 }
            //                 this.handleNewTaxAddition(this.props, payload, MODAL_TYPE.NEW_TAX)
            //             },
            //             valueKeyName: 'taxNameDisplay',
            //             onSelect: (selectedValue, option) => {
            //                 const selectedTax = find(this.props.taxes, { taxSingleRateId: Number(option.key) });
            //                 obj.node.data.tax = selectedTax.taxPercent;
            //                 //obj.node.setDataValue(obj.colDef.field, selectedValue);
            //                 obj.node.data.taxId = selectedTax.taxSingleRateId;
            //             },
            //         }
            //     }
            // },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesOrder.form.gridHeader.comment' defaultMessage='' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage(
                            {
                                id: 'tooltip.warehouseDetail',
                                defaultMessage: ''
                            }
                        )} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD,
                editable: true,
                sortable: true,
                width: (this.props.txColumnSetting[AG_GRID_CONSTANTS.COMMON.COMMENT_FIELD] || {}).width || 200,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.COMMENT),
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.comment || "",
                        isEditorLoaded: this.props.isEditorLoaded,
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value;
                            params.node.data.isEditorLoaded = true;
                            this.props.updateState({
                                isEditorLoaded: true
                            })
                        }
                    }
                },
                cellRendererFramework: (params) => {
                    return ReactHtmlParser(params.data.comment)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > 30) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${30}px`;
                        }
                        params.node.setRowHeight(30);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesQuote.form.gridHeader.partNumber' defaultMessage='' />
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD,
                editable: true,
                sortable: true,
                width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.PART_NUMBER_FIELD] || {}).width || 200,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.PART_NUMBER),
                cellEditor: 'customTextEditor'
            },

            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesQuote.form.gridHeader.stockNumber' defaultMessage='' />
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.STOCK_NUMBER_FIELD,
                editable: true,
                sortable: true,
                width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.STOCK_NUMBER_FIELD] || {}).width || 200,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER),
                cellEditor: 'customTextEditor'
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesQuote.form.gridHeader.origin' defaultMessage='' />
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD,
                editable: true,
                sortable: true,
                width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD] || {}).width || 200,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.ORIGIN),
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.countries,
                        optionKeyName: 'countryId',
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.UOM,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        valueKeyName: 'countryName',
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.origin = selectedValue;
                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                            obj.node.data.origin = undefined;
                        },
                    }
                },
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesQuote.form.gridHeader.hsCode' defaultMessage='' />
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.HS_CODE_FIELD,
                editable: true,
                sortable: true,
                width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.HS_CODE_FIELD] || {}).width || 200,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.HS_CODE),
                cellEditor: 'customTextEditor'
            },

            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='amount.text' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
                // pinned: 'right',
                suppressNavigable: true,
                valueGetter: (params) => {
                    let totalAmountOfColumn = 0
                    if (params.data.qty && params.data.rate) {
                        totalAmountOfColumn = params.data.qty * params.data.rate;
                        if (params.data.discount) {
                            let discountAmount = ((params.data.discount * 0.01) * totalAmountOfColumn);
                            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                        }
                        if (params.data.tax) {
                            totalAmountOfColumn = ((params.data.tax * 0.01) * totalAmountOfColumn) + totalAmountOfColumn;
                        }
                        this.calculateSubtotal();
                    }
                    return totalAmountOfColumn ? fixedDecimalAndFormateNumber(totalAmountOfColumn) : "";
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN

            },
            {
                headerComponentFramework: () => {
                    return <div className="cursor-pointer">
                        <Popover content={content} title={<div style={{ marginLeft: 35 }}><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>} placement="leftTop">
                            <i className='fa fa-bars' />
                        </Popover>
                    </div>;
                },
                width: 70,
                field: 'action', lockPosition: true,
                pinned: 'right',
                cellRenderer: 'customActionEditor',
                suppressNavigable: true,
                cellRendererParams: (params) => {
                    return {
                        onClick: () => {
                            this.gridApi.updateRowData({ remove: [this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                            if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                            this.calculateSubtotal();
                        },
                    }
                }
            }
        ];


        const createQuote = (isDraft) => {
            let isRequiredFiledMissing = false;
            this.gridApi.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (index === 0 && ((!data.product && !data.description) || !data.rate || !data.qty)) {
                    // if (index === 0 && (!data.product || !data.rate || !data.qty)) {
                    isRequiredFiledMissing = true;
                } else if (!data.product && !data.rate && !data.qty) {
                    data = {};
                } else if ((!data.product && !data.description) || !data.rate || !data.qty) {
                    isRequiredFiledMissing = true;
                }
            });

            if ((isRequiredFiledMissing && !isDraft) || !(this.props.customer && this.props.customer.customerId)) {
                return showToasterMessage({
                    messageType: 'error', description: 'Please select Customer, Product/Description, Quantity and Rate'
                });
            }
            const modalData = {
                modalBody: <FormattedMessage
                    id='customer.salesQuote.form.confirmation'
                    defaultMessage=''
                />,
                handleSubmit: () => {
                    const payload = {
                        props: this.props,
                        gridObject: this.gridApi,
                        fileToUpload: this.state.fileToUpload,
                        state: this.state,
                        isDraft: isDraft
                    }
                    if (isDraft) {
                        this.props.createSalesQuoteDraft(payload);
                    } else {
                        this.props.createSalesQuote(payload);
                    }
                    this.props.hideModal();
                }
            };
            if (!this.props.exchangeRate) {
                return showToasterMessage({ messageType: 'error', description: "Exchange Rate is required" });
            }
            if (!this.props.currencyCode) {
                return showToasterMessage({ messageType: 'error', description: "Currency is required" });
            }
            this.props.showModal(modalData);



        }

        if (this.props.txColumns.length) {
            this.gridApi && this.gridApi.sizeColumnsToFit();
        }

        if (self.gridColumnApi && self.props.txColumnSetting) {
            (Object.keys(self.props.txColumnSetting)).forEach(function (key) {
                if (self && self.props.txColumnSetting[key].index) {
                    self.gridColumnApi.moveColumn(key, self.props.txColumnSetting[key].index);
                }
            })
        }

        const getRowHeight = (params) => {
            const rowHeight = params.node.data.scrollHeight ? (Number(params.node.data.scrollHeight.split("px")[0]) - 30) : 30;
            return rowHeight < 30 ? 30 : rowHeight;
        }

        return (
            <Fragment>
                <div className="transaction-table">
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        getRowHeight={getRowHeight}
                        onColumnResized={onColumnResized}
                        onDragStopped={() => {
                            onColumnMoved({}, true);
                        }}
                        //onColumnMoved={onColumnMoved}
                        rowData={this.props.salesQuoteDetails || [{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100%',
                            marginBottom: '10px'
                        }} />
                </div>

                < div style={{display: 'flex', gap: '10px'}} >

                        <Button className="grey-button" onClick={
                            () => {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                        }><FormattedMessage id='customer.salesQuote.form.button.addLine' defaultMessage='' /></Button>
                        <Button className="grey-button" onClick={
                            () => {
                                this.props.showModal({
                                    modalBody: <FormattedMessage id='common.confirmation.clear' defaultMessage='' />,
                                    handleSubmit: () => {
                                        let gridApi = this.gridApi;
                                        let self = this;
                                        this.gridApi.forEachNode(function (rowNode, index) {
                                            gridApi.updateRowData({ remove: [rowNode.data] });
                                            if (gridApi.rowRenderer.lastRenderedRow < 0) {
                                                gridApi.updateRowData({ add: [{}] });
                                            }
                                            self.calculateSubtotal();
                                        })
                                    }
                                })
                            }
                        }><FormattedMessage id='customer.salesQuote.form.button.clearAllLines' defaultMessage='' /></Button>
                </div>
                <div className="upload-btn-so">

                        <CustomTableUpload fileList={this.props.fileList} onChange={
                            (fileList) => {
                                this.props.updateState({
                                    fileList
                                })
                            }
                        } />
                </div>
                         <Row >
                          <Col span={11}>
                        <div>
                            <div className="remarks-title">
                                <FormattedMessage id='customer.salesQuote.form.internalRemarks.label' defaultMessage='' />
                            </div>
                            <CKEditor
                                type="inline"
                                className="description-textarea"
                                key={`${"internalRemarks"}`}
                                data={this.props.internalRemarks}
                                onInit={editor => {
                                    this.editor = editor;
                                }}
                                onChange={(event) => {
                                    const data = event.editor.getData();
                                    this.props.updateState({ internalRemarks: data });
                                }}
                                config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter internal remarks' }}
                            />
                        </div>

                        <div className="remarks-title">
                            <FormattedMessage id='customer.salesQuote.form.customerRemarks.label' defaultMessage='' />
                        </div>

                        {/* <TextArea maxLength={500} value={this.props.customerRemarks || ''} className="description-textarea" rows={4} onChange={(e) => {
                            this.props.updateState({
                                customerRemarks: e.target.value
                            });
                        }} placeholder={intl.formatMessage(
                            { id: 'customer.salesQuote.form.customerRemarks.placeholder', defaultMessage: '' }
                        )}></TextArea> */}
                        <CKEditor
                            type="inline"
                            className="description-textarea"
                            key={`${"customerRemarks"}`}
                            data={this.props.customerRemarks}
                            onInit={editor => {
                                this.editor = editor;
                            }}
                            onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({ customerRemarks: data });
                            }}
                            config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter customer remarks' }}
                        />
                    </Col>

                    <Col span={12} offset={1}>
                        {/* <div className="checkbox-control-group">
                            {<Checkbox onChange={() => {
                                this.setState({ sectionVisible: { ...sectionVisible, discount: !sectionVisible.discount } })
                            }}> Discount</Checkbox>}
                            {false && <Checkbox onChange={() => {
                                this.setState({ sectionVisible: { ...sectionVisible, tax: !sectionVisible.tax } })
                            }}>Tax</Checkbox>}
                            <Checkbox onChange={() => {
                                this.setState({ sectionVisible: { ...sectionVisible, expense: !sectionVisible.expense } })
                            }}>Expense</Checkbox>
                        </div> */}

                        <div className="product-amount-details">
                            <Row type="flex" justify="end">
                                {
                                    sectionVisible.discount
                                        ?
                                        <Fragment>
                                            <Col span={15}>
                                                <span className="label"><FormattedMessage id='customer.salesQuote.form.totalDiscount.label' defaultMessage='' /></span>
                                            </Col>

                                            <Col span={9}>
                                                <div className="input-group">
                                                    <div className="input-label-control">
                                                        <span className="mark">%</span>
                                                        <Input
                                                            type='number'
                                                            placeholder="0.00"
                                                            value={this.state.rootDiscountPercent || ""}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    rootDiscountPercent: e.target.value
                                                                });
                                                            }}
                                                            onBlur={(e) => {
                                                                if (this.state.subtotal) {
                                                                    this.calculateSubtotal();
                                                                }
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    <div className="input-label-control">
                                                        <span className="mark">{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}</span>
                                                        <Input
                                                            disabled
                                                            placeholder="0.00"
                                                            type='number'
                                                            value={this.state.rootDiscountAmount || ""}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    rootDiscountAmount: Number(e.target.value)
                                                                });
                                                            }}
                                                            onBlur={(e) => {
                                                                if (this.state.subtotal) {
                                                                    this.calculateSubtotal();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Fragment>
                                        : ''
                                }
                            </Row>

                            {
                                sectionVisible.tax
                                    ? <Fragment>
                                        <Row type="flex" justify="end">
                                            <Col span={15}>
                                                <FormattedMessage id='customer.salesQuote.form.taxName.label' defaultMessage='' />
                                            </Col>

                                            <Col span={9}>
                                                <Dropdown
                                                    items={this.props.taxes}
                                                    value={this.props.taxName}
                                                    onSelect={(selectedTax) => {
                                                        this.props.updateState({
                                                            taxName: selectedTax
                                                        })
                                                    }}
                                                    valueKeyName='description'
                                                    optionKeyName='taxSingleRateId'
                                                />
                                            </Col>
                                        </Row>

                                        <Row type="flex" justify="end">
                                            <Col span={15}>
                                                <span className="label"><FormattedMessage id='customer.salesQuote.form.tax.label' defaultMessage='' /></span>
                                            </Col>

                                            <Col span={9}>
                                                <div className="input-group">
                                                    <div className="input-label-control">
                                                        <span className="mark">%</span>
                                                        <Input placeholder="0.00" />
                                                    </div>
                                                    <div className="input-label-control">
                                                        <span className="mark">{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}</span>
                                                        <Input placeholder="0.00" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    : ''
                            }

                            {
                                sectionVisible.expense
                                    ? <Fragment>
                                        <Row type="flex" className='amount-summary' justify="end">
                                            <Col span={15}>
                                                <FormattedMessage id='customer.salesQuote.form.totalExpense.label' defaultMessage='' />
                                            </Col>

                                            <Col span={9}>
                                                <Dropdown
                                                    items={this.props.expenseTypes}
                                                    valueKeyName='expenseType'
                                                    value={this.props.expenseId}
                                                    onSelect={(expenseId) => {
                                                        const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                        this.props.updateState({
                                                            expenseId,
                                                            expensName: selectedExpense.expenseType

                                                        })
                                                    }}
                                                    optionKeyName='lkExpenseTypeId'
                                                />
                                            </Col>
                                        </Row>

                                        <Row type="flex" justify="end">
                                            <Col span={15}>
                                                <span className="label"><FormattedMessage id='customer.salesQuote.form.totalExpenseAmount.label' defaultMessage='' /></span>
                                            </Col>

                                            <Col span={9}>
                                                <div className="input-group">
                                                    <div className="input-label-control">
                                                        <span className="mark">{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}</span>
                                                        <Input
                                                            placeholder="0.00"
                                                            type='number'
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    expenseAmount: Number(e.target.value)
                                                                }, () => {
                                                                    if (this.state.subtotal) {
                                                                        this.calculateSubtotal();
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    : ''
                            }

                            <div className="amount-summary">
                                <Row>
                                    <Col span={15}>
                                        <span className="title"><FormattedMessage id='customer.salesQuote.form.subTotal.label' defaultMessage='' /></span>
                                    </Col>
                                    <Col span={9}>
                                        {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}
                                        {this.state.subtotalWithoutDiscount}
                                    </Col>
                                </Row>
                            </div>

                            {this.state.totalDiscount === '0.00' || this.state.totalDiscount === 0 ? null : <div className="amount-summary">
                                <Row>
                                    <Col span={15}>
                                        <span className="title"><FormattedMessage id='customer.salesQuote.form.totalDiscount.label' defaultMessage='' /></span>
                                    </Col>
                                    <Col span={9}>
                                        -{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}
                                        {this.state.totalDiscount}
                                    </Col>
                                </Row>
                            </div>}

                            {/* {
                                false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                                    <div className="w-50">
                                        <span className="title">Tax</span>
                                    </div>
                                    <div className="w-50-default">
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {this.state.totalTax}
                                    </div>
                                </div>
                            } */}

                            {
                                itemLevelTaxes.length === 0 ? null : itemLevelTaxes.map((taxObj, i) => {
                                    if (isPlaceOfSupplySameAsRelationship) {

                                    }
                                    return (
                                        (this.props.companyInfo || {}).countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                            <div className="amount-summary">
                                                <Row>
                                                    <Col span={15}>
                                                        <span
                                                            className="title">CGST @ {this.props.update || this.props.clone ?taxObj.taxPercent: taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                    </Col>
                                                    <Col span={9}>
                                                        {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}
                                                        {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                                                        {(this.props.update || this.props.clone ?Number(taxObj.taxAmount || 0): Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="amount-summary">
                                                <Row>
                                                    <Col span={15}>
                                                        <span
                                                            className="title">SGST @ {this.props.update || this.props.clone ?taxObj.taxPercent: taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                    </Col>
                                                    <Col span={9}>
                                                        {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}
                                                        {/* {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)} */}
                                                        {(this.props.update || this.props.clone ?Number(taxObj.taxAmount || 0): Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div> : <div key={i}>
                                                <div className="amount-summary">
                                                    <Row>
                                                        <Col span={15}>
                                                            <span
                                                                className="title">IGST @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                        </Col>
                                                        <Col span={9}>
                                                            {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}
                                                            {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>) :
                                            <div key={i}>
                                                <div className="amount-summary">
                                                    <Row>
                                                        <Col span={15}>
                                                            <span
                                                                className="title">VAT @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                        </Col>
                                                        <Col span={9}>
                                                            {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}
                                                            {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                    )
                                })
                            }

                            <div className="amount-summary">
                                <Row>
                                    <Col span={15}>
                                        <span className="title"><FormattedMessage id='customer.salesQuote.form.totalAmount.label' defaultMessage='' /></span>
                                    </Col>
                                    <Col span={9}>
                                        {this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : <span>{(this.props.currencyCode || '')} </span>}
                                        {this.state.total}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* <TextArea maxLength={500} value={this.props.footer || ''} className="add-footer-textarea" onChange={(e) => {
                    this.props.updateState({
                        footer: e.target.value
                    });
                }}
                    rows={1} placeholder="Add Footer Here"></TextArea> */}
                <CKEditor
                    type="inline"
                    className="add-footer-textarea"
                    key={`${"footer"}`}
                    data={this.props.footer}
                    onInit={editor => {
                        this.editor = editor;
                    }}
                    onChange={(event) => {
                        const data = event.editor.getData();
                        this.props.updateState({ footer: data });
                    }}
                    config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Add Footer Here' }}
                />

                {/* <Row type="flex" className="action-button-group">
                    <Col span={this.props.update ? 3 : 1}>
                        <Button className="ant-btn-primary mr10" onClick={() => {
                            createQuote();
                        }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                    </Col>
                    {!this.props.update &&
                        <Col span={2}>
                            <Button className="ant-btn-primary ml10"
                                onClick={() => {
                                    createQuote(true);
                                }}
                            ><FormattedMessage id='button.draft.label' defaultMessage='' /></Button>
                        </Col>
                    }
                    <Col span={2} offset={19}>
                        <Button className="grey-button" onClick={() => {
                            this.props.history.goBack();
                        }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                    </Col>
                </Row> */}
                <div className="footer">
                    <div className="left-fItems">
                        <Button className="grey-button" onClick={() => {
                            // this.props.history.goBack();
                            this.props.popModalFromStack()
                        }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                    </div>
                    <div className="right-fItems">

                   
                    {!this.props.update &&
                       
                       <Button className="grey-button"
                       onClick={() => {
                           createQuote(true);
                        }}
                        ><FormattedMessage id='button.draft.label' defaultMessage='' /></Button>
                        
                    }
                    <Button className="ant-btn-primary" onClick={() => {
                        createQuote();
                    }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                    </div>

                </div>
            </Fragment>
        )
    }
};

export default injectIntl(CustomerProductDetails);
