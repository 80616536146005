import React from 'react';
import { injectIntl } from 'react-intl';
import HeaderAction from './HeaderAction'
import './../../../../assets/stylesheets/common/invoice.scss';
// import PageBreadcrumb from "../../../PageBreadcrumb";
import InvoiceDetails from "./InvoiceDetails";
import CreditMemoDetails from './CreditMemoDetails';
import { Divider } from 'antd';
const CreditMemoCreateComponent = (props) => {
  // const breadCrumbList = [
  //   // {
  //   //   name: 'Dashboard',
  //   //   link: 'dashboard'
  //   // }, {
  //   //   name: 'Customer',
  //   //   link: 'customers'
  //   // }, {
  //   //   name: 'Credit Memo'
  //   // }
  //   {
  //     name: <FormattedMessage id='common.sales' defaultMessage=' ' />,
  //   },
  //   {
  //     name: <FormattedMessage id='common.creditMemo' defaultMessage=' ' />,
  //   }
  // ];

  return (
    <>
      <div className="txn-header">
        {/* <PageBreadcrumb breadCrumbList={breadCrumbList} /> */}
        <HeaderAction {...props} />
      </div>
      <div className='txn-body'>

            <div className="txn-details-container">
              <InvoiceDetails {...props} />
            </div>
            <Divider style={{margin: '0 0 10px 0px'}}/>
            <div className="product-details-container">
              <CreditMemoDetails {...props} />
            </div>
      </div>
    </>
  );
}


export default injectIntl(CreditMemoCreateComponent);
