import React from "react";
import { injectIntl, FormattedMessage } from 'react-intl';
import './index.scss';
// import ReactHtmlParser from 'react-html-parser';
import { getMomentDateForUIReadOnly } from "../../../utils";
import {PhoneTwoTone} from '@ant-design/icons';
import {Row, Col, Divider, Button} from 'antd';


const ClientOnboarding = (props) => {

    // const urlAction = new URLSearchParams(props.location.search).get('action') || '';
    const urlSourceSite = new URLSearchParams(props.location.search).get('sourceSite') || '';
    const payload = {
    //   urlAction,
      urlSourceSite,
      fileName: 'Onboarded Client Data',
      fileHeader: {
        'clientOnboardingDetailId': 'Client Onboard Id',
        // 'productType': 'Product Type',
        'industry': 'Industry',
        'businessType': 'Business Type',
        // 'isAlreadyUsingPOS':' Is already using POS',
        // 'posName': 'POS Name',
        // 'selectedModules': 'Selected Modules',
        // 'isNeedOnlineStore': 'Is need online Store',
        // 'country': 'Counrty',
        // 'city': 'City',
        'businessName': 'Business Name',
        // 'vatNumber': 'VAT Number',
        'contactPerson': 'Contact Person',
        'address': 'Address',
        'fullName': 'Full Name',
        'mobile': 'Mobile',
        'email': 'Email',
        // 'businessRequirement': 'Business Requirement',
        // 'partnerType': 'Partner Type',
        'sourceSite': 'Source Site',
        // 'website': 'Website',
        'dateCreated': 'Date Created',
      }
    }

    const handleDownload = ()=>{
        if(payload.urlSourceSite === 'alpide'){
            props.downloadClientOnboardingData(payload);
        }
    }

    const openViewDetails = (data)=>{

        let selectedModules = data.selectedModules ? JSON.parse(data.selectedModules) : {};
        let businessType = (data.businessType && data.businessType.indexOf("[") > -1) ? JSON.parse(data.businessType) : data.businessType;

        const lableStyle = {paddingRight: '10px', fontWeight:'bold'};
        const quesLabel = {fontWeight:'bold', backgroundColor:'#ebddb1', padding: '4px' };
        const ansLabel = {paddingLeft: '8px', marginBottom: '6px'}

        const modalBody =  <div  className={"custom-modal-content"} style={{maxHeight: '590px', overflowY: 'scroll', overflowX: 'hidden'}}>
            <h3 style={{marginBottom: '0px'}}>Client information</h3>
            <Divider style={{marginTop: '0px', marginBottom: '10px'}}/>
            <Row gutter={[6, 6]}>
                <Col span={8}>
                    <div style={lableStyle}>Business Name</div>
                    <div>{data.businessName}</div>
                </Col>
                <Col span={8}>
                    <div style={lableStyle}>Business Type</div>
                    <div>{(businessType && typeof businessType === "object") ? businessType.map((bType, index)=>{
                        return <div key={index}>{bType}</div>
                    }): businessType || ''}</div>
                </Col>
                <Col span={8}>
                    <div style={lableStyle}>Industry</div>
                    <div>{data.industry}</div>
                </Col>
            </Row>
            <Row gutter={[6, 6]}>
                <Col span={8}>
                    <div style={lableStyle}>Product Type</div>
                    <div>{data.productType}</div>
                </Col>
                <Col span={8}>
                    <div style={lableStyle}>VAT Number</div>
                    <div>{data.vatNumber}</div>
                </Col>
                <Col span={8}>
                    <div style={lableStyle}>Contact Person</div>
                    <div>{data.contactPerson}</div>
                </Col>
            </Row>
            <Row gutter={[6, 6]}>
                <Col span={8}>
                    <div style={lableStyle}>Email</div>
                    <div>{data.email}</div>
                </Col>
                <Col span={8}>
                    <div style={lableStyle}>Mobile</div>
                    <div>{data.mobile}</div>
                </Col>
                <Col span={8}>
                    <div style={lableStyle}>Address</div>
                    <div>{data.address}</div>
                </Col>
            </Row>
            <Row gutter={[6, 6]}>
                <Col span={8}>
                    <div style={lableStyle}>City</div>
                    <div>{data.city}</div>
                </Col>
                <Col span={8}>
                    <div style={lableStyle}>Country</div>
                    <div>{data.country}</div>
                </Col>
                <Col span={8}>
                    <div style={lableStyle}>Date Created</div>
                    <div>{data.dateCreated ? getMomentDateForUIReadOnly(data.dateCreated) : '' }</div>
                </Col>
            </Row>
            
            <h3 style={{marginBottom: '0px', marginTop: '15px'}}>Business requirements</h3>
            <Divider  style={{marginTop: '0px', marginBottom: '15px'}}/>
            <Row gutter={16}>
                <Col span={12}>
                    <div style={quesLabel}>Are you using any POS?</div>
                    <div style={ansLabel}>
                        {data.isAlreadyUsingPOS ? "Yes":"No"}
                    </div>
                    {data.isAlreadyUsingPOS ? <div>
                        <div style={quesLabel}>Name of POS system using?</div>
                            <div style={ansLabel}>
                                {data.posName}
                            </div>
                        </div> :''
                    }
                    <div style={quesLabel}>Purchase - Procure to Pay</div>
                    <div style={ansLabel}>
                        {selectedModules.purchase ? selectedModules.purchase.map((item, index)=>{
                            return <div key={index}>{item}</div>
                        }):''}
                    </div>
                    <div style={quesLabel}>Sales - Order to Cash</div>
                    <div style={ansLabel}>
                        {selectedModules.sales ? selectedModules.sales.map((item, index)=>{
                            return <div key={index}>{item}</div>
                        }):''}
                    </div>
                </Col>
                
                <Col span={12}>
                    <div style={quesLabel}>Inventory and Warehouse Management</div>
                    <div style={ansLabel}>
                        {selectedModules.inventoryandWarehouseManagement ? selectedModules.inventoryandWarehouseManagement.map((item, index)=>{
                            return <div key={index}>{item}</div>
                        }):''}
                    </div>
                    <div style={quesLabel}>Marketplace</div>
                    <div style={ansLabel}>
                        {selectedModules.marketplace ? selectedModules.marketplace.map((item, index)=>{
                            return <div key={index}>{item}</div>
                        }):''}
                    </div>
                    <div style={quesLabel}>Financial Management</div>
                    <div style={ansLabel}>
                        {selectedModules.financialManagement ? selectedModules.financialManagement.map((item, index)=>{
                            return <div key={index}>{item}</div>
                        }):''}
                    </div>
                </Col>
            </Row>
        </div>;
        const modalData = {
            title: <h4>Client Onboarding Details</h4>,
            modalBody,
            hideFooter: true,
            width: '734px',
            wrapClassName: 'modal-clientOnboarding-detail'
        };
        props.pushModalToStack(modalData);
    }


    return (
        <>  
            <h4 className="text-center" style={{marginTop: '40px'}}>Client Onboarding Details</h4>
            {urlSourceSite === 'alpide' ? 
                <div style={{display: 'flex', justifyContent: 'center', paddingRight: '15px'}}>
                    <Button type="primary" onClick={()=>{
                        handleDownload();
                    }}> <i className="fi fi-rr-download" style={{fontSize: '11px'}}></i>&nbsp;Download As CSV</Button>
                </div>
                :
                <div style={{width : '100%', padding: '15px'}}>
                    <table className="client-onboarding-table">
                        <thead>
                            <tr>
                                <th><FormattedMessage id="serial.no" defaultMessage=""/></th>
                                <th>Industry</th>
                                <th>Business Type</th>
                                <th>Business Name</th>
                                <th>Contact Person</th>
                                <th>Address</th>
                                <th width='5%'>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (props.clientOnboardingList && props.clientOnboardingList.length) ? (props.clientOnboardingList || []).map((rowData, index)=>{
                                    let businessType = (rowData.businessType && rowData.businessType.indexOf("[") > -1) ? JSON.parse(rowData.businessType) : rowData.businessType;
                                return <tr key={index}>
                                        <td>{index+1 + '.'}</td>
                                        <td>{rowData.industry}</td>
                                        <td>
                                            {(businessType && businessType.length && typeof businessType === "object") ? (businessType).map((bName, index)=>{
                                                return <div key={index}>{bName}</div>
                                            })
                                            : businessType || ''}
                                        </td>
                                        <td>{rowData.businessName}</td>
                                        <td>
                                            <div>{rowData.contactPerson}</div>
                                            <div><span><PhoneTwoTone /> </span><span>{rowData.mobile}</span></div>
                                        </td>
                                        <td>
                                            <div>{rowData.address +',' || ''}</div>
                                            <span>{rowData.city +','|| ''} </span>
                                            <span>{rowData.country || ''}</span>
                                        </td>
                                        <td>
                                            <div className="cursor-pointer" onClick={()=>{
                                                openViewDetails(rowData);
                                            }}>
                                                View
                                            </div>
                                        </td>
                                    </tr>
                                }
                                ):<tr>
                                    <td colSpan={'100%'} className="text-center"><i>No Data exists.</i></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
        </>        
    )
   
};

export default injectIntl(ClientOnboarding);
