import React from 'react';
import { FormattedMessage } from 'react-intl';
//import { MailOutlined } from '@ant-design/icons';
import { Row, Col, InputNumber, Button, Select, Checkbox } from 'antd';
import { Dropdown } from "../../../../../general/Dropdown";
//import moment from 'moment-timezone';
// import { MODAL_TYPE } from '../../../../../../static/constants';
import { showToasterMessage } from '../../../../../../utils';
const { Option } = Select;

const DiscountRule = (props) => {

    // const addItemConfirmationModal = (props, text, modalType) => {
    //     const { showModal, companyInfo } = props;
    //     const labelName = <FormattedMessage id='subcourse.text' defaultMessage='' />;
    //     const modalBody = <span>
    //         <FormattedMessage
    //             id='addItem.confirmation'
    //             values={{ value: `${text} to ` }}
    //             defaultMessage=''
    //         />
    //         {labelName} ?
    //     </span>;

    //     let addFunc;

    //     switch (modalType) {
    //         case MODAL_TYPE.SUBCOURSE: {
    //             addFunc = props.addSubcourse;
    //             break;
    //         }
    //         default: {
    //             addFunc = () => { }
    //         }
    //     }

    //     const modalData = {
    //         modalBody,
    //         handleSubmit: () => {
    //             addFunc({ text: text, relationshipId: companyInfo.relationshipId })
    //             props.hideModal();
    //         },
    //     };
    //     showModal(modalData);
    // };

    // const getModalTitle = (newTextObj, modalType) => {
    //     let title;
    //     switch (modalType) {
    //         case MODAL_TYPE.SUBCOURSE: {
    //             title = <FormattedMessage id='subcourse.text' defaultMessage='' />;
    //             break;
    //         }
    //         default:
    //             title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
    //     }
    //     return title;
    // };

    // const handleNewItemAddition = (props, payload, modalType) => {
    //     const formData = payload.formData;
    //     const { showModal } = props;

    //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
    //         addItemConfirmationModal(props, formData.textBoxValue, modalType);
    //     } else {
    //         const data = {
    //             title: getModalTitle(payload, modalType),
    //             formData,
    //             modalData: { modalType, data: payload },
    //             handleSubmit: (formData = {}) => {
    //                 if (formData.textBoxValue && formData.textBoxValue.length) {
    //                     addItemConfirmationModal(props, formData.textBoxValue, modalType);
    //                 } else {
    //                     data.formData.submittedOnce = true;
    //                     showModal(data);
    //                 }
    //             }
    //         };
    //         showModal(data);
    //     }
    // };

    // const deleteClickHandler = (props, modalType, payload) => {
    //     const { showModal, companyInfo } = props;
    //     const modalBody =
    //         <FormattedMessage
    //             id='deleteItem.confirmation'
    //             defaultMessage=''
    //             values={{ value: payload.text }}
    //         />;

    //     let deleteFunc;

    //     switch (modalType) {
    //         case MODAL_TYPE.SUBCOURSE: {
    //             deleteFunc = props.deleteSubcourse;
    //             break;
    //         }
    //         default: {
    //             deleteFunc = () => { }
    //         }
    //     }

    //     const modalData = {
    //         modalBody,
    //         handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
    //     };
    //     showModal(modalData);
    // };
    const getDiscountTypeName = (type) => {
        let title = '';
        switch (type) {
            case 'regular': {
                title = 'Regular';
                break;
            } case 'financialAssistance': {
                title = 'Financial Assistance';
                break;
            } case 'membership': {
                title = 'Membership';
                break;
            } case 'teacher': {
                title = 'Teacher';
                break;
            } case 'volunteer': {
                title = 'Volunteer';
                break;
            } case 'boardMember': {
                title = 'Board Member';
                break;
            } case 'sibling1': {
                title = 'Sibling-1';
                break;
            } case 'sibling2': {
                title = 'Sibling-2';
                break;
            } case 'sibling3': {
                title = 'Sibling-3';
                break;
            } case 'sibling4': {
                title = 'Sibling-4';
                break;
            } case 'sibling5': {
                title = 'Sibling-5';
                break;
            } 
            default:
                title = '';
        }
        return title;
    };
    return (
        <div className="modal-dialog ">
            <div className="modal-content full-width">
                <div className='newheight'>
                    <Row style={{ borderBottom: 'solid 1px #e4e4e4', margin: '0px 0px 10px', paddingBottom: '10px' }}>
                        <Col span={1}><b>#</b></Col>
                        <Col span={6} style={{padding:'0px 10px'}}><b><FormattedMessage id='common.type' /></b></Col>
                        <Col span={6} style={{padding:'0px 10px'}}><b><FormattedMessage id='priority.text' /></b></Col>
                        <Col span={8} style={{padding:'0px 10px'}}><b><FormattedMessage id='modal.txDetails.common.discount' /></b></Col>
                        {/* <Col span={5}><b><FormattedMessage id='discountUnit.text' /></b></Col> */}
                        <Col span={2} style={{padding:'0px 10px'}}><b><FormattedMessage id='common.listing.header.action' /></b></Col>
                    </Row>
                    {
                        (props.discountRuleList || []).map((item, i) => {

                            return (
                                <div key={'ques' + i}>
                                    <Row style={{ marginBottom: '10px', borderBottom: 'solid 1px #e4e4e4', paddingBottom: '5px' }}>
                                        <Col span={1}><b> {i + 1}</b></Col>
                                        <Col span={6} style={{padding:'0px 10px'}}>
                                            <Select onChange={(value) => {
                                                item.discountType = value;
                                                let discountRuleList = props.discountRuleList;
                                                discountRuleList.splice(i, 1, item)
                                                props.updateState({ discountRuleList });
                                            }}
                                                 style={{ width: '100%',marginTop:'-9px' }}
                                                value={item.discountType}>
                                                <Option value="regular">Regular</Option>
                                                <Option value="financialAssistance">Financial Assistance</Option>
                                                {/* <Option value="membership">Membership</Option> */}
                                                <Option value="teacher">Teacher</Option>
                                                <Option value="volunteer">Volunteer</Option>
                                                <Option value="boardMember">Board Member</Option>
                                                <Option value="sibling1">Sibling-1</Option>
                                                <Option value="sibling2">Sibling-2</Option>
                                                <Option value="sibling3">Sibling-3</Option>
                                                <Option value="sibling4">Sibling-4</Option>
                                                <Option value="sibling5">Sibling-5</Option>
                                            </Select>
                                        </Col>
                                        <Col span={6} style={{padding:'0px 10px'}}>
                                            <Dropdown
                                                items={[1,2,3,4,5,6,7,8,9, 10, 11]}
                                                value={item.priority}
                                                onSelect={
                                                    (optionValue) => {
                                                        item.priority = optionValue;
                                                        let discountRuleList = props.discountRuleList;
                                                        discountRuleList.splice(i, 1, item)
                                                        props.updateState({ discountRuleList });
                                                    }
                                                }
                                            />
                                        </Col>
                                         
                                        <Col span={6} style={{padding:'0px 0px 0px 10px'}}>
                                            <InputNumber min={0}

                                                style={{ width: '100%', marginTop: '-5px', paddingLeft:'5px'}}
                                                placeholder='discount rate or amount'
                                                value={item.discount}
                                                onChange={(discount) => {
                                                    item.discount = discount;
                                                    let discountRuleList = props.discountRuleList;
                                                    discountRuleList.splice(i, 1, item)
                                                    props.updateState({ discountRuleList });
                                                }}
                                            />
                                        </Col>
                                        <Col span={2}  style={{ textAlign: 'right' }}>
                                       
                                        <span className="mark lertpi" style={{ marginTop:'-9px',marginLeft:'-2px' }}>
                                            {/* <span className="mark" style={{ left: '15px', width: '30px', marginTop:'-9px' }}> */}
                                                {/* {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} */}
                                             <Select style={{width: '60px',}}
                                              onChange={(unit) => {
                                               item.discountUnit = unit;
                                               let discountRuleList = props.discountRuleList;
                                               discountRuleList.splice(i, 1, item)
                                               props.updateState({ discountRuleList });
                                            }}
                                                // style={{ width: '30px' }}
                                                value={item.discountUnit}>
                                                <Option value="Percent">%</Option>
                                                <Option value="Amount"> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</Option>
                                                </Select>
                                            </span>
                                        </Col>
                                        {/* <Col span={5}>
                                            <Dropdown
                                                items={['Percent', 'Amount']}
                                                value={item.discountUnit}
                                                onSelect={
                                                    (unit) => {
                                                        item.discountUnit = unit;
                                                        let discountRuleList = props.discountRuleList;
                                                        discountRuleList.splice(i, 1, item)
                                                        props.updateState({ discountRuleList });
                                                    }
                                                }
                                            />
                                        </Col> */}
                                        <Col span={2}>
                                            <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer', position:'relative', top:'-7px' }} onClick={() => {
                                                let discountRuleList = props.discountRuleList;
                                                discountRuleList.splice(i, 1)
                                                props.updateState({ discountRuleList });
                                            }
                                            }><i className="fa fa-trash" title={props.intl.formatMessage({ id: 'delete.text' })} /> </span>
                                        </Col>
                                    </Row>

                                </div>
                            )
                        })
                    }
                    <Button className="add-new-record ant-btn ant-btn-default" onClick={
                        () => {
                            let discountRuleList = props.discountRuleList || [];
                            discountRuleList.push({discountUnit: 'Percent'});
                            props.updateState({ discountRuleList });
                        }
                    }><FormattedMessage id='addRow' />  </Button>
                </div>
                <div>
                
                
                </div>
                <Row className='mt15'>
                    <Col span={8}>
                        <b>Apply Discount to Onetime Charges</b>
                    </Col>
                    <Col span={3}>
                        <Checkbox checked={props.isApplyDiscountToOnetimeInvoice} onChange={(e) => {
                            props.updateState({ isApplyDiscountToOnetimeInvoice: e.target.checked ? 1 : 0 });
                        }}>
                        </Checkbox>
                    </Col>
                </Row>
            </div>
            <div>

                <hr className="mt10 "></hr>
                <Row justify='center'>
                    <button type="button" className="ant-btn ant-btn-primary mt20" onClick={() => {
                          let isValidDiscountType = true;
                          let isValidPriority = true;
                          let isValidDiscount = true;

                        let discountRuleList = (props.discountRuleList || []).map((item) => {
                            if (!item.discountType) { isValidDiscountType = false }
                            if (!item.priority) { isValidPriority = false }
                            if (!item.discount) { isValidDiscount = false }
                            if (item.discountUnit ==='Percent' && item.discount > 100) { isValidDiscount = false }

                            item.discountAmount = item.discountUnit === 'Amount' ? item.discount : 0;
                            item.discountPercent = item.discountUnit === 'Percent' ? item.discount : 0;
                            item.discountTypeName = getDiscountTypeName(item.discountType);
                            return item;
                        })
                        if (discountRuleList && discountRuleList.length > 0 && isValidDiscountType && isValidPriority && isValidDiscount) {
                            props.addDiscountRuleList(discountRuleList, props.isApplyDiscountToOnetimeInvoice);
                            props.popModalFromStack();
                        } else {
                            if (!discountRuleList || !discountRuleList.length) {
                                return showToasterMessage({ messageType: 'error', description: 'Please enter at least one record.' });
                            }
                            if (!isValidDiscountType) {
                                return showToasterMessage({ messageType: 'error', description: 'Discount type is required' });
                            }
                            if (!isValidPriority) {
                                return showToasterMessage({ messageType: 'error', description: 'Priority is required' });
                            }
                            if (!isValidDiscount) {
                                return showToasterMessage({ messageType: 'error', description: 'Discount can not be more than 100pc' });
                            }
                        }

                    }}><FormattedMessage id='forgot.btn.save' defaultMessage='' /></button>
                </Row>
            </div>
        </div>
    );

}

export default DiscountRule;