import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { Skeleton,Menu, Pagination } from 'antd';
import PageBreadcrumb from '../../../../PageBreadcrumb';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { MoreOutlined } from '@ant-design/icons';
import DropdownAction from "antd/lib/dropdown";
import './index.scss';
import {
  CONSTANTS, ICONS, MODAL_TYPE, MODULE_CODED_VALUES, PERMISSION_VALUES
} from '../../../../../static/constants';
import { checkACLPermission, fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, getMomentLoclDateForUIReadOnly, showToasterMessage } from '../../../../../utils';


const format = CONSTANTS.TABLE_DATE_FORMAT;
  
let gridApi;
const ManufacturerListingComp = (props) => {
  const { permissions, manufacturers, companyInfo ,hideModal, showModal ,updateState,creditMemoCount} = props;

  // const getRowHeight = (params) => {
  //   let height = 36;
  //   return height;
  // }

  const addItemConfirmationModal = (text ,e) => {
    const labelName = <FormattedMessage id='addItem.text.productManufacturer' defaultMessage='' />;
    const modalBody = <span>
      <FormattedMessage
        id='addItem.confirmation'
        values={{ value: `${text} to ` }}
        defaultMessage=''
      />
      {labelName} ?
    </span>;
    const modalData = {
      modalBody,
      handleSubmit: () => {
      
        props.addManufacturer({ text: text, relationshipId: companyInfo.relationshipId , id: e.inventoryProductManufacturerId ,version: e.version }, props)
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const addManufacturer = (e) => {
    const formData = {};
    formData.relationshipId = companyInfo.relationshipId;
    formData.textBoxValue = e.manufacturerName;
    formData.selectedValue = e.manufacturerName
    const payload = { formData: formData };
   
    const data = {
      title: <FormattedMessage id='addItem.text.createManufacturer' defaultMessage='' />,
      formData,
      modalData: { modalType: MODAL_TYPE.PRODUCT_MANUFACTURER, data: {payload,textBoxValue : e.manufacturerName } },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
        
          addItemConfirmationModal(formData.textBoxValue ,e);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
          showToasterMessage({
            messageType: "error",
            description: "Manufacturer Name is required",
          });

        }
      }
    };
    showModal(data);
  }

  const deleteManufacturer = (payload) => {
    const modalBody =
      <FormattedMessage
        id='deleteItem.confirmation'
        defaultMessage=''
        values={{ value: payload.manufacturerName }}
      />;
    const modalData = {
      modalBody,
      handleSubmit: () => {
         props.deleteManufacturer({ id: payload.inventoryProductManufacturerId, relationshipId: companyInfo.relationshipId })
          hideModal()
        },
    };
    showModal(modalData);
  }

  const showTotal = (total) => {
    return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
};
const loadPageData = (pageNumber, pageSize) => {
  const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
  fetchPaginationDataIfNeeded('fetchManufacture', 'manufactureList', props, payload);
}

const handlePageSizeChange = (pageNumber, pageSize, props) => {
  const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
  props.resetPaginatedData();
  props.fetchSalesEnquiry(payload);
  props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
}
  const updateManufacturer = (payload) => {
    const modalBody =
      <FormattedMessage
        id='updateItem.confirmation'
        defaultMessage=''
        values={{ value: payload.manufacturerName }}
      />;
    const modalData = {
      modalBody,
      handleSubmit: () => { props.updateManufacturer(payload) },
    };
    showModal(modalData);
  }

  const getActionPermissionData = () => {
    const primaryPerm = permissions.primary || [];
    const permittedAction = {
      update: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PRODUCT_MANUFACTURER, PERMISSION_VALUES.UPDATE),
      create: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PRODUCT_MANUFACTURER, PERMISSION_VALUES.CREATE),
      delete: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.PRODUCT_MANUFACTURER, PERMISSION_VALUES.DELETE)
    };
    Object.keys(permittedAction).forEach((operation) => {
      if (permittedAction[operation] === false) {
        delete permittedAction[operation];
      }
    })
    return permittedAction;
  }

  const permittedAction = getActionPermissionData();
  const columnDefs = [
    {
      headerName: "#",
      width: 70,
      resizable: true,
      cellRendererFramework: (link) => <div>
        {link.rowIndex + 1}
      </div>
    },
    {
      headerComponentFramework: () => {
        return <FormattedMessage id='inventory.extras.manufacturer.listing.header.manufacturerName' defaultMessage='' />;
      },
      editable: true,
      cellEditor: 'customTextEditor',
      field: "manufacturerName",
      resizable: true,
      cellClass: 'cellInputControl'
    },
    {
      headerComponentFramework: () => {
        return <FormattedMessage id='inventory.extras.manufacturer.listing.header.description' defaultMessage='' />;
      },
      field: "description",
      resizable: true,
      editable: true,
      cellEditor: 'customTextEditor',
      cellRendererFramework: (params) => <div dangerouslySetInnerHTML={{
        __html: params.value || '-'
      }}>
      </div>,
      cellClass: 'cellInputControl'
    },
    {
      headerComponentFramework: () => {
        return <FormattedMessage id='inventory.extras.manufacturer.listing.header.dateCreated' defaultMessage='' />;
      },
      resizable: true,
      cellRendererFramework: (link) => <div>
        {getMomentDateForUIReadOnly({date: new Date(link.data.dateCreated), format})}
      </div>,
    },
    {
      headerComponentFramework: () => {
        return <FormattedMessage id='inventory.extras.manufacturer.listing.header.totalProducts' defaultMessage='' />;
      },
      resizable: true,
      field: 'totalProducts'
    }
  ];

  if (permittedAction.update || permittedAction.delete) {
    columnDefs.push({
      pinned: 'right',
      width: 80,
      suppressNavigable: true,
      cellClass: 'lastActionCell',
      cellRendererFramework: (params) => <div>
        {params.node.data.isToEdit &&
          <div className="table-cell-action">
            <Link onClick={() => {
              updateManufacturer(params.data);
              gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
              gridApi.refreshCells({ force: true });
            }} >
              <i className="fa fa-save" />
            </Link>
            <Link onClick={() => {
              params.api.stopEditing(true);
              gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
              gridApi.refreshCells({ force: true });
            }} >
              <i className="fa fa-ban" />
            </Link>
          </div>
        }

        {!params.node.data.isToEdit &&
          <div className="table-cell-action" >
            {
              permittedAction.update && (
                <Link onClick={() => {
                  gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                  gridApi.refreshCells({ force: true });
                }}>
                  <i className="fa fa-edit" />
                </Link>
              )
            }
            {
              permittedAction.delete && (
                <Link onClick={() => deleteManufacturer(params.data)}>
                  <i className="fa fa-remove" />
                </Link>
              )
            }
          </div>}
      </div>
    });
  }

  // const onFirstDataRendered = (params) => {
  //   //params.api.sizeColumnsToFit();
  // }

  // const agGridStyle = {
  //   height: '100%',
  //   width: '100%'
  // };

  // const onGridReady = (params) => {
  //   gridApi = params.api;
  //   params.api.sizeColumnsToFit();
  // }

  const breadCrumbList = [
    {
      name: 'Products',
    },
    {
      name: 'Manufacturer',
    }
  ];
  const actionMenu = (e) => {
   
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="edit" onClick={()=>{
          
              addManufacturer(e)
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

           { permittedAction.delete &&<Menu.Item key="2" onClick={()=>
            {
              deleteManufacturer(e)
            }
          }>
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>}

        </Menu >
    )
  };

  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="inventory.extras.manufacturer.listing.header.productManufacturer"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep"/>
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          {permittedAction.create && (
            <button className="create-action-btn" onClick={addManufacturer}>
              <i className={`${ICONS.ADD} mr5`} /><FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          )}
        </div>
      </div>

      <div className="view-container">
        {/* <div className="page-title">
          <div><FormattedMessage id='inventory.extras.manufacturer.listing.header.productManufacturer' defaultMessage='' /></div>
          <div className="modal-sub-header"><FormattedMessage id='inventory.extras.manufacturer.listing.subHeader.text' defaultMessage='' /></div>
        </div> */}

        {/* <div className="action-dropdown-btn pull-right">
          {
            permittedAction.create && (
              <button className="right-border" onClick={addManufacturer}>
                <i className="fa fa-user" />
                <FormattedMessage id='inventory.extras.manufacturer.listing.header.newManufacturer' defaultMessage='' />
              </button>
            )
          }
        </div> */}

        <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="inventory.extras.category.listing.header.productManufacture.table.heading"
                  defaultMessage=""
                />
              </div>
              {(props.manufacturers || []).length ?
                <>
                  <div className="vertical-sep" />
                  <div>{props.manufacturers.length}</div>
                </>
              : 
                ""
              }
            </div>
            <div className="right-actions"></div>
          </div>
          {/* <div className='agGridWrapper d-flex w-100 pt15'>
            <div className="ag-theme-balham" style={agGridStyle} >
              <CustomAgGrid
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={manufacturers || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                animateRows={true}
                getRowHeight={getRowHeight}
                onGridSizeChanged={onFirstDataRendered}
                editType={'fullRow'}
              >
              </CustomAgGrid>
            </div>
          </div> */}
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.extras.manufacturer.listing.header.manufacturerName"
                      defaultMessage=""
                    />
                  </th>
                  {/* <th>
                    <FormattedMessage
                      id="inventory.extras.manufacturer.listing.header.description"
                      defaultMessage=""
                    />
                  </th> */}
                  <th>
                    <FormattedMessage
                      id="inventory.extras.manufacturer.listing.header.dateCreated"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.extras.manufacturer.listing.header.totalProducts"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage id="Action" defaultMessage="" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(manufacturers || []).length &&
                  manufacturers.sort((a, b) =>
                  a.dateCreated < b.dateCreated ? 1 : -1
                ).map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{rowData.manufacturerName}</td>
                        {/* <td>
                          {
                            <div
                              dangerouslySetInnerHTML={{
                                __html: rowData.description || "-",
                              }}
                            ></div>
                          }
                        </td> */}
                        <td>
                         {rowData.dateCreated ? <div>
                            {getMomentLoclDateForUIReadOnly({
                              date: rowData.dateCreated,
                              format,
                            })}
                          </div> :  "-"}
                        </td>
                        <td>{rowData.totalProducts}</td>
                        <td style={{ paddingLeft: "50px" }}>
                          <div className="action-icon">
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <MoreOutlined />
                            </DropdownAction>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
              <Pagination
                size="small"
                total={creditMemoCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}  showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({pageSize : pageSize, pageNumber: pageNumber})
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props)
                }}
            />
            </div>       
      </div>
    </div>
    </Fragment>
  );
};

export default injectIntl(ManufacturerListingComp);
