import React from "react";
import { FormattedMessage } from "react-intl";
import { Empty } from "antd";
import {
  fixedDecimalNumber,
  getMomentDateForUIReadOnly,
} from "../../../../../../utils";
import { CONSTANTS } from "../../../../../../static/constants";
import SupplierDetails from "../../../../../../containers/supplier/detail";

function CurrentStockDetails(props) {
  const { stockSummaryData } = props;

  return (
    <>
      <div className="table-container" style={{ height: "480px" }}>
        <table id="current-stock-table">
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id="modal.currentStockDetails.listing.supplierName"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="modal.currentStockDetails.listing.qtyPurchased"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="modal.currentStockDetails.listing.currentStock"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="modal.currentStockDetails.listing.purchasePrice"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="modal.currentStockDetails.listing.purchaseDate"
                  defaultMessage=""
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {
              stockSummaryData?.length ? (
              
               stockSummaryData?.map((rowData, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          props.pushModalToStack({
                            modalBody: (
                              <SupplierDetails
                                {...props}
                                supplierId={rowData.supplierId}
                                supplierName={rowData.supplierName}
                              />
                            ),
                            width: "100%",
                            hideTitle: true,
                            hideFooter: true,
                            wrapClassName: "new-transaction-wrapper",
                          });
                        }}
                      >
                        {rowData.supplierName}
                      </div>
                    </td>
                    <td>{rowData.qtyPurchased}</td>
                    <td>{rowData.currentStock}</td>
                    <td>
                      {props.companyInfo.currencyIcon ? (
                        <i className={props.companyInfo.currencyIcon}></i>
                      ) : (
                        props.companyInfo.currencyCode + " "
                      )}
                      {fixedDecimalNumber(rowData.purchasePrice)}
                    </td>
                    <td>
                      {" "}
                      {rowData.datePurchased
                        ? getMomentDateForUIReadOnly({
                            date: rowData.datePurchased,
                            format: CONSTANTS.TABLE_DATE_FORMAT,
                          })
                        : "-"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr key="empty-data">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CurrentStockDetails;
