import React, { Component, Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { CustomAgGrid } from '../../../../../general/CustomAgGrid';
import { Row, Col, Button, DatePicker, Pagination } from 'antd';
import { showToasterMessage, fixedDecimalNumber, isValidDateRange, getMomentDateForUI, getMomentDateForUIReadOnly, formatDateFilterForBackend, getFinancialYear } from '../../../../../../utils';
import { TextBox } from '../../../../../general/TextBox';
import { CONSTANTS } from '../../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../../static/cssClassConstants';
import { FilterOutlined } from '@ant-design/icons';
import { Dropdown } from '../../../../../general/Dropdown';
import * as find from 'lodash.find';
import PurchaseInvoiceDetail from '../../../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail';
import SalesInvoiceDetails from '../../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import BusinessExpenseDetail from '../../../../../../containers/modal/modalBody/finance/BusinessExpense';

class AdvanceSearchComponent extends Component {
    constructor(props) {
        super(props);
        const finacialYear = getFinancialYear(this.props.companyInfo);
        this.state = {
            ledgerStartDate: getMomentDateForUI({date: new Date(finacialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT}),
            ledgerEndDate: getMomentDateForUI({date: new Date(), format: CONSTANTS.BACKEND_FORMAT}),
        }
    }

    render() {

        
        const format = CONSTANTS.DISPLAY_DATE_FORMAT;
        const finacialYear = getFinancialYear(this.props.companyInfo);
        const fyStartDate = finacialYear.fyStartDate;
        const calculateSubtotal = (obj) => {
            let total = 0;
            let currentNodeAmt = 0;
            let requiredAmount = 0;
            let recordAdded = 0;
            let self = this;
            this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
                if (rowNode.data.payment && Number(rowNode.data.payment)) {
                    total += Number(rowNode.data.payment);
                    recordAdded++;
                    if (index === obj.node.rowIndex) {
                        currentNodeAmt = rowNode.data.payment;
                    }
                }
            });
            let txTotalAmount = 0;
            if (Number(self.props.transactionData.Withdrawal) < 0) {
                txTotalAmount = Math.abs(Number(self.props.transactionData.Withdrawal));
            } else {
                txTotalAmount = Number(self.props.transactionData.Deposit);
            }
            if (total > txTotalAmount) {
                requiredAmount = txTotalAmount - (total - currentNodeAmt);
                this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
                    if (index === obj.node.rowIndex) {
                        obj.node.data.payment = Number(requiredAmount.toFixed(2));
                    }
                });
                total = txTotalAmount;
            }
            
            this.setState({
                totalPayment: total,
                recordAdded
            });
        }

        const handleApplyCredit = () => {
            const self = this;
            if (!this.state.totalPayment || this.state.totalPayment- (Math.abs(this.props.transactionData.amount)) !== 0) {
               return showToasterMessage({messageType: 'error', description: this.props.intl.formatMessage({ id: 'banking.findMatch.validation', defaultMessage: '' }) });
            }
            const modalData = {
                modalBody: <FormattedMessage id='save.confirmation' defaultMessage="" />,
                handleSubmit: () => {
                    let paymentList = [];

                    this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
                        let paymentObj = { ...rowNode.data };
                        if (paymentObj.payment && Number(paymentObj.payment)) {
                            paymentList.push(paymentObj);
                        }
                    });
                    if(Number(self.props.transactionData.Withdrawal) < 0){
                        self.props.createMatchedPaymentForSupplier(paymentList, self.props);
                    }else{
                        self.props.createMatchedPayment(paymentList, self.props);
                    }
                   // this.props.getBankTransactions({ institutionId: (self.props.activeAccount || {}).institutionId, relationshipId: (self.props.companyInfo || {}).relationshipId });
                    self.props.popModalFromStack();
                    self.props.hideModal();
                }
            };
            self.props.pushModalToStack(modalData);
        }

        const showTotal = (total) => {
            return this.props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + this.props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
        };
    
        const loadPageData = (pageSize, pageNumber, props) => {
            let companyInfo = props.companyInfo || {};
            let payload = {
                startDate: getMomentDateForUI({date: new Date(this.state.ledgerStartDate), format}),
                endDate: getMomentDateForUI({date: new Date(this.state.ledgerEndDate), format}),
                customerId: (this.props.customerObj || {}).customerId || 0,
                supplierId: (this.props.supplierObj || {}).supplierId || 0,
                relationshipId: companyInfo.relationshipId,
                pageNumber: pageNumber || 1,
                pageSize: pageSize || 10,
                maxAmount: this.state.maxAmount || 0,
                minAmount: this.state.minAmount || 0,
                txType: this.state.txType || "",
                projectName: this.state.projectName || ""
            };
            this.props.findMatchingInvoice(payload, props);
        }
        const handlePageSizeChange = (pageNumber, pageSize, props) => {
            let companyInfo = props.companyInfo || {};
            let payload = {
                startDate: getMomentDateForUI(new Date(this.state.ledgerStartDate), format),
                endDate: getMomentDateForUI({date: new Date(this.state.ledgerEndDate), format}),
                customerId: (this.props.customerObj || {}).customerId || 0,
                supplierId: (this.props.supplierObj || {}).supplierId || 0,
                relationshipId: companyInfo.relationshipId,
                pageNumber: pageNumber || 1,
                pageSize: pageSize || 10,
                maxAmount: this.state.maxAmount || 0,
                minAmount: this.state.minAmount || 0,
                txType: this.state.txType || "",
                projectName: this.state.projectName || ""
            };
            this.props.findMatchingInvoice(payload, props);
            //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
        }

        const openInvoiceDetails = (data) => {
            if (Number(this.props.transactionData.Withdrawal || 0) < 0) {
                if (data.expenseMasterId) {
                    const expensePayload = {
                        expenseMasterId: data.expenseMasterId
                    }
                    this.props.pushModalToStack({
                        modalBody: <BusinessExpenseDetail {...this.props} expensePayload={expensePayload} />,
                        width: '100%',
                        hideFooter: true,
                        wrapClassName: 'modal-custom-detail'
                    })
                } else {
                    const invoicePayload = {
                        supplierId: data.supplierId,
                        invoiceMasterId: data.invoiceMasterId
                    }
                    this.props.pushModalToStack({
                        modalBody: <PurchaseInvoiceDetail {...this.props} invoicePayload={invoicePayload} />,
                        width: '100%',
                        hideFooter: true,
                        wrapClassName: 'modal-custom-detail'
                    })
                }
            } else {
                const invoicePayload = {
                    customerId: data.customerId,
                    invoiceMasterId: data.invoiceMasterId
                }
                this.props.pushModalToStack({
                    modalBody: <SalesInvoiceDetails {...this.props} invoicePayload={invoicePayload} />,
                    width: '100%',
                    hideFooter: true,
                    wrapClassName: 'modal-custom-detail'
                })
            }
        }

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.supplier.payInvoice.supplierName' defaultMessage="" />;
                },
                field: "supplierName",
                resizable: true,
                maxWidth: 200,
                hide: !this.state.showSupplierColumn,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.customer.payInvoice.customerName' defaultMessage="" />;
                },
                field: "customerName",
                resizable: true,
                maxWidth: 200,
                hide: this.props.transactionData.amount < 0
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.date' defaultMessage="" />;
                },
                field: "invoiceDate",
                cellRendererFramework: (link) => <div>{getMomentDateForUIReadOnly({date: new Date(link.value), format})}</div>,
                resizable: true,
                maxWidth: 200,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.transactionNumber' defaultMessage="" />;
                },
                field: "invoiceNumber",
                resizable: true,
                maxWidth: 300,
                 cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                    openInvoiceDetails(link.data)
                }}>{ link.data.expenseNumber || link.data.invoiceNumber}</div>,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='common.project' defaultMessage='' />;
                },
                field: "project",
                minWidth: 70,
                maxWidth: 300,
                suppressMovable: true,
                cellRendererFramework: (link) => {
                    return <div>
                        {link.data.projectNumber ? <span>{link.data.projectNumber + ' (' + link.data.projectName + ')'}</span> : ''}
                    </div>
                },
                resizable: true
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='transactionAmount.text' defaultMessage="" />;
                },
                field: "invoiceTotalAmount",
                cellRendererFramework: (params) => <div>
                        <div>{ (params.data.invoiceTotalAmount).toFixed(2)}</div>
                </div>,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='partially.paid' defaultMessage="" />;
                },
                field: "totalPaymentReceived",
                cellRendererFramework: (params) => {
                    return <div>
                        {(params.data.totalPaymentReceived || 0).toFixed(2)}
                    </div>
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN

            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='common.dueAmount' defaultMessage="" />;
                },
                field: "dueAmount",
                cellRendererFramework: (params) => {
                    return <div>
                        {(params.data.invoiceTotalAmount - (params.data.totalPaymentReceived || 0)).toFixed(2)}
                         </div>
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN

            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='sidebar.menuItem.payment' defaultMessage="" />;
                },
                field: "payment",
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                editable: true,
                suppressMovable: true,
                cellEditor: 'customNumberEditor',
                cellEditorParams: (obj) => {
                    return {
                        maxValue : (obj.node.data.invoiceTotalAmount - (obj.node.data.totalPaymentReceived || 0)),
                        onChange: (value) => {
                            obj.node.data.payment = value;
                                
                        }
                    }
                },
                valueGetter: (obj) =>{
                    if(obj.node.data.payment > (obj.node.data.invoiceTotalAmount - (obj.node.data.totalPaymentReceived || 0))){
                        obj.node.data.payment = obj.node.data.invoiceTotalAmount - (obj.node.data.totalPaymentReceived || 0).toFixed(2)
                    }
                    calculateSubtotal(obj);
                    return obj.node.data.payment
                }

            }
        ];

        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        }

        return (
            <div>
                <Fragment>
                    <Row>
                        {this.props.transactionData.amount < 0 ? 
                            <Col span={4} style={{ paddingLeft: "10px" }}>
                                <strong><FormattedMessage id='common.supplier' defaultMessage="" /></strong>
                                <div style={{ margin: "5px 0px 0px 0px" }}>
                                    <Dropdown
                                        // style={{ width: "210px" }}
                                        items={this.props.supplierSummary[1] || []}
                                        valueKeyName='supplierName'
                                        optionKeyName='supplierId'
                                        value={(this.props.supplierObj || {}).supplierName}
                                        placeholder={this.props.intl.formatMessage({ id: 'modal.supplier.payInvoice.selectSupplier.placeholder', defaultMessage: '' })}
                                        onSelect={
                                            (supplierId) => {
                                                const supplier = find(this.props.supplierSummary[1], { supplierId: supplierId }) || {};
                                                this.props.updateState({
                                                    supplierObj: supplier,
                                                    supplierName: supplier.supplierName,
                                                });
                                            }}
                                        allowClear={true}
                                        onClear={() => {
                                            this.props.updateState({
                                                supplierObj: undefined,
                                                supplierName: undefined,
                                            });
                                        }}     
                                    />
                                </div>
                            </Col> :
                            <Col span={4} style={{ paddingLeft: "10px" }}>
                                <strong><FormattedMessage id='common.customer' defaultMessage="" /></strong>
                                <div style={{ margin: "5px 0px 0px 0px" }}>
                                    <Dropdown
                                        // style={{ width: "210px" }}
                                        items={this.props.customers[1] || []}
                                        valueKeyName='companyName'
                                        optionKeyName='customerId'
                                        value={(this.props.customerObj || {}).companyName}
                                        placeholder={this.props.intl.formatMessage({ id: 'salesInvoice.customer.placeholder', defaultMessage: '' })}
                                        onSelect={
                                            (customerId) => {
                                                const customer = find(this.props.customers[1], { customerId: customerId }) || {};
                                                this.props.updateState({
                                                    customerObj: customer,
                                                    customerName: customer.companyName,
                                                });
                                            }}
                                        allowClear={true}
                                        onClear={() => {
                                            this.props.updateState({
                                                customerObj: undefined,
                                                customerName: undefined,
                                            });
                                        }}  
                                    />
                                </div>
                            </Col>}

                            {this.props.transactionData.amount < 0 ?
                            <Col span={3} style={{ paddingLeft: "10px" }}>
                                <strong><FormattedMessage id='transactionType.text' defaultMessage="" /></strong>
                                <div style={{ margin: "5px 0px 0px 0px" }}>
                                    <Dropdown
                                        items={['Business Expense', 'Purchase Invoice']}
                                        value={this.props.txType}
                                        placeholder={this.props.intl.formatMessage({ id: 'transactionType.text', defaultMessage: '' })}
                                        onSelect={
                                            (txType) => {
                                                this.setState({ txType: txType });
                                            }}
                                    />
                                </div>
                            </Col> : ''
                        }

                        <Col span={3} style={{ paddingLeft: "10px" }}>
                            <strong><FormattedMessage id='modal.reports.partyLedger.startDate.label' defaultMessage="" /></strong>
                            <DatePicker onChange={(dateObj) => {
                                if (dateObj) {
                                    this.setState({
                                        ledgerStartDate: dateObj.format(CONSTANTS.BACKEND_FORMAT)
                                    });
                                } else {
                                    this.setState({
                                        ledgerStartDate: undefined
                                    });
                                }
                            }} format={format}
                                key={`${this.state.ledgerStartDate ? getMomentDateForUI({date: new Date(this.state.ledgerStartDate), format}) : getMomentDateForUI({date: new Date(fyStartDate), format})}`}
                                defaultValue={this.state.ledgerStartDate ? getMomentDateForUI({date: new Date(this.state.ledgerStartDate), format}) : getMomentDateForUI({date: new Date(fyStartDate), format})}
                            />
                        </Col>

                        
                        <Col span={3} style={{ paddingLeft: "15px" }}>
                            <strong><FormattedMessage id='modal.reports.partyLedger.endDate.label' defaultMessage="" /></strong>
                            <DatePicker onChange={(dateObj) => {
                                if (dateObj) {
                                    this.setState({
                                        ledgerEndDate: dateObj.format(CONSTANTS.BACKEND_FORMAT)
                                    });
                                } else {
                                    this.setState({
                                        ledgerEndDate: undefined
                                    });
                                }
                            }} format={format}
                                key={`${this.state.ledgerEndDate ? getMomentDateForUI({date: new Date(this.state.ledgerEndDate), format}) : getMomentDateForUI({date: new Date(), format})}`}
                                defaultValue={this.state.ledgerEndDate ? getMomentDateForUI({date: new Date(this.state.ledgerEndDate), format}) : getMomentDateForUI({date: new Date(), format})}
                            />
                        </Col>
                        <Col span={3} style={{ paddingLeft: "10px" }}>
                            <strong><FormattedMessage id='banking.search.minAmount' defaultMessage="" /></strong>
                            <TextBox
                                type='number'
                                value={this.state.minAmount}
                                onChange={(e) => {
                                    this.setState({
                                        minAmount: e.target.value,
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({ id: 'banking.search.minAmount', defaultMessage: '' })}
                            />
                        </Col>
                        <Col span={3} style={{ paddingLeft: "10px" }}>
                            <strong><FormattedMessage id='banking.search.maxAmount' defaultMessage="" /></strong>
                            <TextBox
                                type='number'
                                value={this.state.maxAmount}
                                onChange={(e) => {
                                    this.setState({
                                        maxAmount: e.target.value,
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({ id: 'banking.search.maxAmount', defaultMessage: '' })}
                            />
                        </Col>
                        
                        <Col span={3} style={{ paddingLeft: "10px" }}>
                                <strong><FormattedMessage id='addItem.text.project' defaultMessage="" /></strong>
                            <div style={{ margin: "5px 0px 0px 0px" }}>
                                <Dropdown
                                    items={this.props.projectList || []}
                                    value={this.state.projectName}
                                    optionKeyName='projectMasterId'
                                    valueKeyName='projectName'
                                    placeholder={this.props.intl.formatMessage({ id: 'addItem.text.project', defaultMessage: '' })}
                                    onSelect={
                                        (selectedValue, option) => {
                                            this.setState({ projectName: option.name });
                                        }}
                                    allowClear={true}
                                    onClear={() => {
                                        this.setState({
                                            projectName: undefined,
                                        });
                                    }}
                                />
                            </div>
                            </Col>
                        <Col span={2} style={{ paddingTop: "2px" }}>
                            <Button className="ant-btn ant-btn-default vab ml15 mt20 ml10" onClick={() => {
                                if (!this.state.ledgerStartDate || !this.state.ledgerEndDate) {
                                    showToasterMessage({
                                        description: 'Please select date range.',
                                        messageType: 'error'
                                    })
                                } else if (!isValidDateRange(this.state.ledgerStartDate, this.state.ledgerEndDate)) {
                                    showToasterMessage({
                                        description: 'Please select valid date range',
                                        messageType: 'error'
                                    })
                                }else if (this.props.transactionData.amount < 0 && !this.state.txType) {
                                    showToasterMessage({
                                        description: 'Please select transaction type',
                                        messageType: 'error'
                                    })
                                }
                                // else if (!this.props.customerObj || !this.props.customerObj.customerId) {
                                //     showToasterMessage({
                                //         description: 'Please select customer',
                                //         messageType: 'error'
                                //     })
                                // } 
                                else {
                                    const companyInfo = this.props.companyInfo || {};
                                    let payload = {
                                        startDate: formatDateFilterForBackend(this.state.ledgerStartDate, CONSTANTS.FILTER_DATE_TYPE.START),
                                        endDate: formatDateFilterForBackend(this.state.ledgerEndDate, CONSTANTS.FILTER_DATE_TYPE.END),
                                        customerId: (this.props.customerObj || {}).customerId || 0,
                                        supplierId: (this.props.supplierObj || {}).supplierId || 0,
                                        relationshipId: companyInfo.relationshipId,
                                        pageNumber: 1,
                                        pageSize: 10,
                                        maxAmount: this.state.maxAmount || 0,
                                        minAmount: this.state.minAmount || 0,
                                        txType: this.state.txType || "",
                                        projectName: this.state.projectName || ""
                                    };
                                    this.props.findMatchingInvoice(payload, this.props);
                                    this.props.getMatchingInvoiceCount(payload, this.props);

                                    if(this.state.txType && this.state.txType === "Purchase Invoice"){
                                        this.setState({ showSupplierColumn: true });
                                    }else{
                                        this.setState({ showSupplierColumn: false }); 
                                    }
                                }
                            }}>
                                {/* <FormattedMessage id='modal.reports.partyLedger.submit.label' defaultMessage="Submit" /> */}
                                {<FilterOutlined />}
                            </Button>
                        </Col>
                        {/* <Col span={4} offset={4} style={{ paddingTop: "2px" }}>
                            {(this.props.customerLedger && this.props.customerLedger.length) ?
                                <div className="small-btn-group pl20">
                                    <button className="ant-btn ant-btn-default vab  mt20 " onClick={openEmailDrawer}>{<MailOutlined />}</button >
                                    <button className="ant-btn ant-btn-default vab  mt20 " onClick={downloadPDf}>{<FilePdfOutlined />}</button>
                                    <button className="ant-btn ant-btn-default vab  mt20 " onClick={printPdf}>{<PrinterOutlined />}</button>
                                </div> : ''
                            }
                        </Col> */}

                    </Row>
                    <br />
                    <div className='agGridWrapper pr10 pl10'>
                        <div className="ag-theme-balham customer-details-table" >
                            {(this.props.findTransactions && this.props.findTransactions.length) ?
                                <CustomAgGrid
                                    columnDefs={columnDefs}
                                    rowData={this.props.findTransactions || []}
                                    rowDragManaged={true}
                                    domLayout={"autoHeight"}
                                    animateRows={true}
                                    onGridReady={onGridReady}
                                    onColumnResized={(params) => {
                                        params.api.resetRowHeights();
                                    }}
                                    defaultColDef={{
                                        flex: 1,
                                        autoHeight: true,
                                        wrapText: true,
                                    }}
                                    onGridSizeChanged={(params) => {
                                        params.api.sizeColumnsToFit();
                                    }}
                                >
                                </CustomAgGrid> : ''
                            }
                            <Pagination
                                size="small"
                                total={this.props.findTransactionCount}
                                showTotal={showTotal}
                                defaultPageSize={this.props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                                // showQuickJumper
                                onChange={(pageNumber, pageSize) => {
                                    loadPageData(pageSize, pageNumber, this.props);
                                    this.props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
                                }}
                                onShowSizeChange={(pageNumber, pageSize) => {
                                    handlePageSizeChange(pageNumber, pageSize, this.props)
                                }}
                            />
                            <br></br>
                            {(this.props.findTransactions && this.props.findTransactions.length) ?
                                <div>
                                    <Row type="flex">
                                        <Col offset={14} span={5} style={{ textAlign: "right" }}>
                                            <strong><div><FormattedMessage id='amountFrom.text' defaultMessage="" /> {this.state.recordAdded || 0} <FormattedMessage id='common.Transactions' defaultMessage="" /></div></strong>
                                        </Col>
                                        <Col span={5} style={{ textAlign: "right" }}>
                                            <strong> {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(this.state.totalPayment || 0)}</strong>
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row type="flex">
                                        <Col offset={14} span={5} style={{ textAlign: "right" }}>
                                            <strong><FormattedMessage id='amountFromStatement.text' defaultMessage="" /></strong>
                                        </Col>
                                        <Col span={5} style={{ textAlign: "right" }}>
                                            <strong> {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(Math.abs(this.props.transactionData.amount || 0))}</strong>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row type="flex">
                                        <Col offset={14} span={5} style={{ textAlign: "right" }}>
                                            <strong><FormattedMessage id='tooltip.amountDifference' defaultMessage="" /></strong>
                                        </Col>
                                        <Col span={5} style={{ textAlign: "right" }}>
                                            <strong> {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(Math.abs(this.props.transactionData.amount) - (this.state.totalPayment || 0))}</strong>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row type="flex">
                                        <Col offset={14} span={5} style={{ textAlign: "right" }}>
                                            <strong><FormattedMessage id='total.text' defaultMessage="" /></strong>
                                        </Col>
                                        <Col span={5} style={{ textAlign: "right" }}>
                                            <strong> {this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "}{fixedDecimalNumber(Math.abs(this.props.transactionData.amount || 0))}</strong>
                                        </Col>
                                    </Row>
                                </div> : ''
    }
                        </div >
                      
                    </div>
                </Fragment>
                <div className="ant-modal-footer">
                    {(this.props.findTransactions && this.props.findTransactions.length) ?
                        <Button type="primary" onClick={() => {
                            handleApplyCredit();
                        }}><FormattedMessage id='banking.match' defaultMessage='' /></Button>
                        : ''
                    }
                </div>
            </div>
        )
    }
}

export default injectIntl(AdvanceSearchComponent);
