import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip, Input } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from '../../../../../utils'
import { CONSTANTS,} from '../../../../../static/constants';
const { Search } = Input;
//import ReactHtmlParser from 'react-html-parser';

const TotalParents = (props) => {
    const { registerParentsData, updateState, companyInfo, pageNumber } = props;
    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, formId: props.selectedFormId };
        fetchPaginationDataIfNeeded('getRegisterParentsData', 'registerParentsData', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, formId: props.selectedFormId };
        props.resetRegisterParentsData();
        props.getRegisterParentsData(payload);
        // props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    const columnDefs = [

        {
            headerComponentFramework: () => {
                return <FormattedMessage id='father.name' />;
            },
            field: "Father First Name",
            // hide: _isColumnToHide('fatherName'),
            colId: 'Father First Name_1',
            cellRendererFramework: (param) => {
                return <div>{(param?.data?.['Father First Name'] || '') + ' ' + (param?.data?.['Father Last Name'] || '')}</div>
            },
            minWidth: 70,
            maxWidth: 400,
            suppressMovable: true,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='mother.name' />;
            },
            field: "motherFirstName",
            // hide: _isColumnToHide('motherName'),
            colId: 'motherFirstName_1',
            cellRendererFramework: (param) => {
                return <div>{(param?.data?.['Mother First Name'] || '') + ' ' + (param?.data?.['Mother Last Name'] || '')}</div>
            },
            minWidth: 150,
            maxWidth: 400,
            suppressMovable: true,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='student.text' />;
            },
            field: "student",
            colId: 'student_1',
            minWidth: 70,
            maxWidth: 300,
            suppressMovable: true,
            resizable: true,
            cellRendererFramework: (param) => <div>{(param?.data?.['First Name'] || '') + ' ' + (param?.data?.['Last Name'] || '')}</div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='grade.text' defaultMessage='' />;
            },
            field: "Grade",
            // hide: _isColumnToHide('fatherMobile'),
            colId: 'Grade_1',
            minWidth: 70,
            maxWidth: 300,
            suppressMovable: true,
            resizable: true,
            cellRendererFramework: (link) => <div>{link.value || '-'}</div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='dob.text' defaultMessage='' />;
            },
            field: "Date of Birth",
            // hide: _isColumnToHide('fatherMobile'),
            colId: 'Date of Birth_1',
            minWidth: 70,
            maxWidth: 300,
            suppressMovable: true,
            resizable: true,
            cellRendererFramework: (link) => <div>{link.value || '-'}</div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='enrollmentDate.text' defaultMessage='' />;
            },
            field: "Enrollment Date",
            minWidth: 122,
            maxWidth: 300,
            suppressMovable: true,
            colId: "Enrollment Date_1",
            cellRendererFramework: (param) => {
                return <div>
                    <Tooltip placement="topLeft" title={(param.data['Enrollment Date'] && getMomentDateForUIReadOnly({date: param.data['Enrollment Date'], format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
                        {(param.data['Enrollment Date'] && getMomentDateForUIReadOnly({date: param.data['Enrollment Date'], format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                    </Tooltip>
                </div>
            },
            resizable: true
        },

        {
            headerComponentFramework: () => {
                return <FormattedMessage id='fatherMobile.text' defaultMessage='' />;
            },
            field: "Father Mobile",
            // hide: _isColumnToHide('fatherMobile'),
            colId: 'Father Mobile_1',
            minWidth: 70,
            maxWidth: 300,
            suppressMovable: true,
            resizable: true,
            cellRendererFramework: (link) => <div>{link.value || '-'}</div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='motherMobile.text' defaultMessage='' />;
            },
            field: 'Mother Mobile',
            // hide: _isColumnToHide('motherMobile'),
            colId: "Mother Mobile_1",
            resizable: true,
            suppressMovable: true,
            minWidth: 70,
            maxWidth: 300,
            cellRendererFramework: (link) => <div>
                {link.data['Mother Mobile'] || "-"}
            </div>,
        },

       
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='setting.student.registration.form.emergencyContactName' defaultMessage='' />;
            },
            field: 'Emergency Contact Name',
            // hide: _isColumnToHide('emergencyContactName'),
            colId: "Emergency Contact Name_1",
            resizable: true,
            suppressMovable: true,
            minWidth: 70,
            maxWidth: 400,
            cellRendererFramework: (link) => <div>
                {link.value || '-'}
            </div>,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='setting.student.registration.form.emergencyPhone' defaultMessage='' />;
            },
            field: 'Emergency Phone',
            // hide: _isColumnToHide('emergencyPhone'),
            colId: "Emergency Phone_1",
            resizable: true,
            suppressMovable: true,
            minWidth: 70,
            maxWidth: 400,
            cellRendererFramework: (link) => <div>
                {link.value || '-'}
            </div>,
        },

        // {
        //     headerComponentFramework: () => {
        //         return <div className="cursor-pointer">

        //         </div>;
        //     },
        //     cellRendererFramework: (param) => {
        //         return <Dropdown overlay={actionMenu(param)} trigger={['click']}>
        //             <Button className="action-btn small">
        //                 <i className="fa fa-ellipsis-v" />
        //             </Button>
        //         </Dropdown>
        //     },
        //     resizable: true,
        //     pinned: 'right',
        //     field: 'action',
        //     colId: "action_1",
        //     minWidth: 52,
        //     maxWidth: 52,
        //     suppressMovable: true,
        //     cellClass: 'lastActionCell'
        // }
    ];

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    const onGridReady = (params) => {
        props.updateState({
            gridApi: params.api,
            gridColumnApi: params.columnApi
        });
        if (props.isColumnFitTable && params.api) {
            params.api.sizeColumnsToFit();
            props.updateState({
                isColumnFitTable: false
            })
        }
        // onDataRendered(params);
    }

    const onModelUpdated = (params) => {
        //params.api.resetRowHeights();
        if ((registerParentsData[pageNumber] || []).length && !props.isRowHeightSet) {
            setTimeout(() => {
                params.api.resetRowHeights();
                props.updateState({
                    isRowHeightSet: true
                })
            }, 1000)
        }
    }

    return (
        <div className={""} style={{ minHeight: '300px' }}>

            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="inner-content">
                        <div className="modal-table-container">
                            <Fragment>
                                
                                <div className='view-container mt0'>

                                    <Skeleton loading={props.isLoading}
                                        active
                                        paragraph={{ rows: 12 }}
                                    >
                                        <div className="table-action-container">
                                            <div className="d-inline-flex" style={{ marginLeft: '15px', marginTop: '5px' }}>
                                                <Search
                                                    placeholder="Enter Searched Text"
                                                    value={props.searchedText}
                                                    onChange={e => {
                                                        updateState({ searchedText: e.target.value, pageNumber: pageNumber || 1 });
                                                        props.gridApi.setQuickFilter(e.target.value);
                                                    }}
                                                    onSearch={(searchedText) => {
                                                        updateState({ searchedText, pageNumber: pageNumber || 1 });
                                                        props.gridApi.setQuickFilter(searchedText);
                                                    }}
                                                    style={{ width: 330, height: 36 }}
                                                />
                                            </div>
                                        </div>
                                        <div className='agGridWrapper' style={{ marginLeft: '15px', marginRight: '15px' }}>
                                            <div className="ag-theme-balham" style={agGridStyle} >
                                               
                                                <AgGridReact
                                                    onGridReady={onGridReady}
                                                    columnDefs={columnDefs}
                                                    rowData={registerParentsData[pageNumber] || []}
                                                    rowDragManaged={true}
                                                    domLayout={"autoHeight"}
                                                    defaultColDef={{
                                                        flex: 1,
                                                        autoHeight: true,
                                                        wrapText: true,
                                                    }}
                                                    animateRows={true}
                                                    
                                onModelUpdated={onModelUpdated}
                                                >
                                                </AgGridReact>
                                            </div>
                                        </div>
                                    </Skeleton>
                                    <Pagination
                                        size="small"
                                        total={props.totalStudentCount}
                                        showTotal={showTotal}
                                        defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                                        showQuickJumper
                                        onChange={(pageNumber, pageSize) => {
                                            loadPageData(pageNumber, pageSize);
                                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                                        }}
                                        onShowSizeChange={(pageNumber, pageSize) => {
                                            handlePageSizeChange(pageNumber, pageSize, props)
                                        }}
                                    />
                                </div>
                            </Fragment >
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default TotalParents;
