import React from "react";
import { ICONS } from "../../../../static/constants";

const Contact = (primaryContact) => {

    return (
        <div className="contact-info">
            <div>
                <ul className="primary-info">
                    <div className="b-text">Primary</div>

                    <li className="p-info">
                        <i className="fi fi-rs-circle-user mr5" /> {primaryContact?.firstName ?  (primaryContact?.firstName + " " + primaryContact?.lastName) : <div className="empty-data-box"></div>}
                    </li>
                    <li className="p-info">
                        <i class={`${ICONS.MAIL} mr5`} /> {primaryContact.emailAddress || <div className="empty-data-box"></div>}
                    </li>
                    <li className="p-info">
                        <i class={`${ICONS.CALL} mr5`} /> {primaryContact.cellPhone ? `${primaryContact.countryCallingCode} ${primaryContact.cellPhone}` :<div className="empty-data-box"></div>}
                    </li>
                    <li className="p-info">
                        <i class="fi fi-brands-whatsapp mr5" /> <div className="empty-data-box"></div>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Contact;

