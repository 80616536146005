import { SUPPLIER_EXPENSE_ACTION_LIST } from '../static/constants';
const initialState = {
  supplierExpenseList: {
    1: []
  },
  supplierExpenseCount: 0,
  supplierExpenseDetail: {}
}

const SupplierExpenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER_EXPENSE_ACTION_LIST.SUPPLIER_EXPENSE_LIST:
      return {
        ...state,
        supplierExpenseList: {
          ...state.supplierExpenseList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_EXPENSE_ACTION_LIST.SUPPLIER_EXPENSE_COUNT:
      return { ...state, supplierExpenseCount: action.data };
    case SUPPLIER_EXPENSE_ACTION_LIST.SUPPLIER_EXPENSE_LIST_PAGE_RESET:
      return { ...state, supplierExpenseList: initialState.supplierExpenseList };
    case SUPPLIER_EXPENSE_ACTION_LIST.SUPPLIER_EXPENSE_DETAIL:
      return { ...state, supplierExpenseDetail: action.data };
    default:
      return state;
  }
};

export default SupplierExpenseReducer;