import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CustomButton } from '../../../../general/CustomButton';
import { TextBox } from '../../../../general/TextBox';

class AddSubAccountComponent extends Component {

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 24,
                    offset: 0,
                },
            },
        };

        return (
            <Fragment>
                <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
                    <div className="modal-content billing-address-control">

                        <Form.Item label={<FormattedMessage id='communication.account.addSubAccount.model.field.accountName' defaultMessage='' />} colon={false}>
                            {getFieldDecorator('taxRate', {
                                initialValue: this.props.friendlyName ? this.props.friendlyName : null,
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id='requiredField.missing.msg' defaultMessage='' />,
                                    }]
                            })
                                (<TextBox
                                    type='text'
                                    placeholder={this.props.intl.formatMessage({ id: 'communication.account.addSubAccount.model.field.accountName', defaultMessage: '' })}
                                    //value={this.props.taxRate}
                                    onChange={(e) => {
                                        this.props.updateState({
                                            friendlyName: e.target.value,
                                        });
                                    }}
                                />)}
                        </Form.Item>

                    </div>
                    <Form.Item {...tailFormItemLayout} className="modal-footer">
                        <div key={0} className='customModal-button-group'>
                            <CustomButton
                                intlId={'modal.address.button.cancel'}
                                defaultMessage={''}
                                type={'default'}
                                key={1}
                                onClick={() => {
                                    if (((this.props.data || {}).formData || {}).onCancel) {
                                        this.props.data.formData.onCancel();
                                    }
                                    this.props.hideModal();

                                }}
                            />
                            <CustomButton
                                intlId={'button.save.label'}
                                defaultMessage={''}
                                htmlType='submit'
                                key={2}
                            />
                        </div>
                    </Form.Item>
                </Form>
            </Fragment >
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {


                const payload = {
                    friendlyName: this.props.friendlyName,
                    relationshipId: this.props.relationshipId,
                }

                const modalData = {
                    modalBody: 'Are you sure you want to create a new account?',
                    handleSubmit: () => {
                        this.props.createSubAccount(payload, this.props);
                        this.props.popModalFromStack();
                        this.props.hideModal();
                    }
                };
                this.props.pushModalToStack(modalData);
            }
        });
    };
}

export default Form.create({ name: 'add_marketplace_state_tax_component' })(injectIntl(AddSubAccountComponent));
