import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CONSTANTS } from '../../../static/constants';
import TrialBalanceComponent from '../../../components/finance/TrialBalances';
import { fetchAllLedgerAccounts } from '../LedgerAccounts/action';
import { fetchDataIfNeeded, getMomentDateForUI, getFinancialYear } from '../../../utils';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';

class TrialBalanceComp extends Component {
    constructor(props) {
        super(props);
        const financialYear = getFinancialYear(props.companyInfo);
        this.state = {
            searchedText: '',
            startDate: getMomentDateForUI({date: new Date(financialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT}) ,
            endDate: getMomentDateForUI({date: new Date(), format:  CONSTANTS.BACKEND_FORMAT}) ,
        }
    }
    componentWillMount(){
        this.props.updateHeaderState({
            collapsed: false
          });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId
        };
        fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
    }

    render() {
        return <TrialBalanceComponent {...this.props}  {...this.state}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        allLedgerAccounts: state.ledger.allLedgerAccounts
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllLedgerAccounts, 
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TrialBalanceComp);
