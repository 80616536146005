import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, DatePicker, Tabs } from "antd";
import { FormattedMessage } from "react-intl";
import {
  CONSTANTS,
  PERMISSION_VALUES,
  MODULE_CODED_VALUES,
} from "../../../../../static/constants";
import SODetail from "../../../../../containers/modal/modalBody/customer/SODetail";
import ShipmentDetail from "../../../../../containers/modal/modalBody/customer/ShipmentDetail";
//import ShippingInvoiceDetail from '../../../../../containers/modal/modalBody/customer/ShippingInvoiceDetail';
//import CertificateOfConformity from '../../../../../containers/modal/modalBody/customer/CertificateOfConformity';
import ShipmentIcon from "../../../../../assets/images/shipment.jpg";
import "./index.scss";
import {
  getCurrentDateForBackend,
  checkACLPermission,
  getMomentDateForUI,
  getMomentDateForUIReadOnly,
} from "../../../../../utils";
import Shipment from "../../../../../containers/customer/salesOrder/shipment";
const { TabPane } = Tabs;
const ShipmentInformation = (props) => {
  const { permissions } = props;
  const primaryPerm = permissions.primary;
  const openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
    };
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
      handleSubmit: () => { },
    });
  };

  // const updateStock = (data) => {
  //     const modalData = {
  //         modalBody: "Are you sure to update stock",
  //         handleSubmit: () => {
  //             const payload = {
  //                 shipmentMasterId: data.shipmentMasterId,
  //                 relationshipId: data.relationshipId,
  //                 customerId: data.customerId
  //             }
  //              props.updateInventory(payload);
  //             //props.hideModal();
  //         }
  //     };
  //     props.pushModalToStack(modalData);
  // }

  const createShipment = (props) => {
    // if (props.qualityChecklistSetting && !props.qualityChecklistSetting.isGenerateShipmentWithoutQualityCheck) {
    //         return showToasterMessage({ messageType: 'error', description: 'Shipment can not be generate without quality check' });
    // }
    props.pushModalToStack({
      modalBody: (
        <Shipment
          {...props}
          customerId={props.soData.customerId}
          soMasterId={props.soData.salesOrderMasterId}
        />
      ),
      width: "100%",
      hideTitle: true,
      hideFooter: true,
      wrapClassName: "new-transaction-wrapper",
    });
    // props.history.push({
    //     pathname: '/admin/so/shipment',
    //     state: {
    //         customerId: props.soData.customerId,
    //         soMasterId: props.soData.salesOrderMasterId
    //     }
    // })
  };

  const ShipmentInfoDetail = (props) => {
    return (
      <Tabs type="line" className="ml20 mr20">
        {props.soShipmentList.map((data, index) => {
          let actualDate =
            (data && data.actualDeliveryDate) || (data && data.shipmentDate);
          actualDate = actualDate
            ? getMomentDateForUI({
              date: new Date(actualDate),
              format: CONSTANTS.DISPLAY_DATE_FORMAT,
            })
            : getMomentDateForUI({
              date: new Date(),
              format: CONSTANTS.DISPLAY_DATE_FORMAT,
            });
          return (
            <TabPane tab={data.shipmentNumber} key={index}>
              {" "}
              <div className="shipment-info-detail-data">
                <Row>
                  <Col span={6} className="text-right shipment-info-label">
                    <FormattedMessage
                      id="modal.txDetails.shipment.shipmentNumber"
                      defaultMessage=""
                    />
                  </Col>
                  <Col span={6} className="shipment-info-value">
                    <Link
                      onClick={() => {
                        openDeliveryNotesDetails(data);
                      }}
                    >
                      {data.shipmentNumber || "-"}
                    </Link>{" "}
                  </Col>
                  <Col span={6} className="text-right shipment-info-label">
                    {" "}
                    <FormattedMessage
                      id="modal.txDetails.shipment.consignee"
                      defaultMessage=""
                    />{" "}
                  </Col>
                  <Col span={6} className="shipment-info-value">
                    {" "}
                    {data.consigneeName || "-"}
                  </Col>
                </Row>

                <Row>
                  <Col span={6} className="text-right shipment-info-label">
                    {" "}
                    <FormattedMessage
                      id="modal.txDetails.shipment.shipmentDate"
                      defaultMessage=""
                    />{" "}
                  </Col>
                  <Col span={6} className="shipment-info-value">
                    {(data.shipmentDate &&
                      getMomentDateForUIReadOnly({
                        date: data.shipmentDate,
                        format: CONSTANTS.DISPLAY_DATE_FORMAT,
                      })) ||
                      "-"}{" "}
                  </Col>
                  <Col span={6} className="text-right shipment-info-label">
                    {" "}
                    <FormattedMessage
                      id="modal.txDetails.shipment.carrier"
                      defaultMessage=""
                    />{" "}
                  </Col>
                  <Col span={6} className="shipment-info-value">
                    {" "}
                    {data.carrierName || "-"}
                  </Col>
                </Row>

                <Row>
                  <Col span={6} className="text-right shipment-info-label">
                    <FormattedMessage
                      id="modal.txDetails.shipment.expectedDelivery"
                      defaultMessage=""
                    />{" "}
                  </Col>
                  <Col span={6} className="shipment-info-value">
                    {(data.expectedDeliveryDate &&
                      getMomentDateForUIReadOnly({
                        date: data.expectedDeliveryDate,
                        format: CONSTANTS.DISPLAY_DATE_FORMAT,
                      })) ||
                      "-"}{" "}
                  </Col>
                  <Col span={6} className="text-right shipment-info-label">
                    {" "}
                    <FormattedMessage
                      id="modal.txDetails.shipment.freightType"
                      defaultMessage=""
                    />{" "}
                  </Col>
                  <Col span={6} className="shipment-info-value">
                    {" "}
                    {data.freightType || "-"}
                  </Col>
                </Row>

                <Row>
                  <Col span={6} className="text-right shipment-info-label">
                    <FormattedMessage id="common.status" defaultMessage="" />{" "}
                  </Col>
                  <Col span={6} className="shipment-info-value">
                    <span
                      className={[
                        "status-code",
                        (data.status || "").toLowerCase(),
                      ].join(" ")}
                    >
                      <FormattedMessage id={data.status} defaultMessage="" />
                    </span>
                  </Col>
                  <Col span={6} className="text-right shipment-info-label">
                    {" "}
                    <FormattedMessage
                      id="customer.shipment.listing.header.actualDeliveyDate"
                      defaultMessage=""
                    />{" "}
                  </Col>
                  <Col span={6} className="shipment-info-value">
                    <div>
                      {!props.isEditActualDate ? (
                        <span>
                          {actualDate &&
                            getMomentDateForUIReadOnly({
                              date: actualDate,
                              format: CONSTANTS.DISPLAY_DATE_FORMAT,
                            })}
                        </span>
                      ) : (
                        <DatePicker
                          format={CONSTANTS.DISPLAY_DATE_FORMAT}
                          defaultValue={actualDate}
                          style={{ width: "70%", height: "28px" }}
                          onChange={(selectedDate) => {
                            let shipmentList = props.soShipmentList;
                            shipmentList[0].actualDeliveryDate = selectedDate;
                            props.updateState({
                              soShipmentList: shipmentList,
                            });
                          }}
                        />
                      )}
                      {!props.isEditActualDate ? (
                        <span
                          className=" cursor-pointer"
                          onClick={() => {
                            props.updateState({
                              isEditActualDate: true,
                            });
                          }}
                        >
                          {" "}
                          <i className="fa fa-pencil"></i>
                        </span>
                      ) : (
                        <span className=" cursor-pointer">
                          {" "}
                          <i
                            onClick={() => {
                              props.updateState({
                                isEditActualDate: false,
                              });
                              props.updateActualDeliveryDate({
                                actualDeliveryDate:
                                  getCurrentDateForBackend(actualDate),
                                relationshipId: (props.companyInfo || {})
                                  .relationshipId,
                                shipmentMasterId: data.shipmentMasterId,
                              });
                            }}
                            className="fa fa-save"
                          ></i>{" "}
                          <i
                            onClick={() => {
                              props.updateState({
                                isEditActualDate: false,
                              });
                            }}
                            className="fa fa-ban"
                          ></i>
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={6} className="text-right shipment-info-label">
                    <FormattedMessage
                      id="modal.txDetails.shipment.trackingNumber"
                      defaultMessage=""
                    />{" "}
                  </Col>
                  <Col span={6} className="shipment-info-value">
                    {data.trackingNumber || "-"}{" "}
                  </Col>
                </Row>
                <hr />
                {/* <div className="footer">
                        <Row>
                            <Col span={2}></Col>
                            {
                                data.status === "Delivered" ? <Col span={5} className="text-right">
                                    <Button className="ant-btn ant-btn-primary" onClick={() => {
                                        props.markAsDelivered({
                                            relationshipId: (props.companyInfo || {}).relationshipId,
                                            shipmentMasterId: data.shipmentMasterId,
                                            status: "Shipped",
                                            lableColor: 'shipped',
                                            customerId: data.customerId,
                                        }, props)
                                    }}> <FormattedMessage id='modal.txDetails.shipment.markAsShipped' defaultMessage='' /> </Button>
                                </Col> : <Col span={5} className="text-right">
                                        <Button className="ant-btn ant-btn-primary" onClick={() => {
                                            props.markAsDelivered({
                                                relationshipId: (props.companyInfo || {}).relationshipId,
                                                shipmentMasterId: data.shipmentMasterId,
                                                status: "Delivered",
                                                lableColor: 'delivered',
                                                customerId: data.customerId,
                                            }, props)
                                        }}> <FormattedMessage id='modal.txDetails.shipment.markAsDelivered' defaultMessage='' /> </Button>
                                    </Col>
                            }
                            {!data.isStockUpdated ?
                                <Col span={4} className="text-right">
                                    <Button className="ant-btn ant-btn-primary" onClick={() => {
                                        updateStock(data);
                                    }}> <FormattedMessage id='customer.salesOrder.form.updateStock.label' defaultMessage='' /> </Button>
                                </Col> : ''
                            }
                            <Col span={4} className="text-right">
                                <Button className="ant-btn ant-btn-primary" onClick={() => {
                                    openShippingInvoiceDetails(data);
                                }}><FormattedMessage id='modal.txDetails.shipment.shippingInvoice' defaultMessage='' /></Button>
                            </Col>
                            {/* <Col span={4} className="text-right">
                                <Button className="ant-btn ant-btn-primary" onClick={() => {
                                    openDeliveryNotesDetails(data);
                                }}> Delievry Notes</Button>
                            </Col> 
                            <Col span={4} className="text-right">
                                <Button className="ant-btn ant-btn-primary" onClick={() => {
                                    openCertifcateOfConformity(data);
                                }}><FormattedMessage id='certifcateOfConformity.text' defaultMessage='' /></Button>
                            </Col>
                        </Row>
                    </div> */}
              </div>
            </TabPane>
          );
        })}
      </Tabs>
    );

    // return props.soShipmentList.map((data) =>{
    //     let actualDate = (data && data.actualDeliveryDate) || (data && data.shipmentDate)
    //     actualDate = actualDate ? moment(new Date(actualDate), CONSTANTS.DISPLAY_DATE_FORMAT) : moment(new Date(), CONSTANTS.DISPLAY_DATE_FORMAT);
    //     return <div className="shipment-info-detail-data">
    //     <Row>
    //         <Col span={6} className="text-right shipment-info-label">Shipment No </Col>
    //         <Col span={6} className="shipment-info-value"><Link onClick={() => {
    //             openDeliveryNotesDetails(data);
    //         }}>{data.shipmentNumber || "-"}</Link> </Col>
    //         <Col span={6} className="text-right shipment-info-label">  Consignee Name </Col>
    //         <Col span={6} className="shipment-info-value"> {data.consigneeName || "-"}</Col>
    //     </Row>

    //     <Row>
    //         <Col span={6} className="text-right shipment-info-label">  Shipment Date </Col>
    //         <Col span={6} className="shipment-info-value">{(data.shipmentDate && moment(data.shipmentDate).format(CONSTANTS.DISPLAY_DATE_FORMAT)) || "-"} </Col>
    //         <Col span={6} className="text-right shipment-info-label"> Carrier Name: </Col>
    //         <Col span={6} className="shipment-info-value"> {data.carrierName || "-"}</Col>
    //     </Row>

    //     <Row>
    //         <Col span={6} className="text-right shipment-info-label">Expected Delivery Date </Col>
    //         <Col span={6} className="shipment-info-value">{(data.expectedDeliveryDate && moment(data.expectedDeliveryDate).format(CONSTANTS.DISPLAY_DATE_FORMAT)) || "-"} </Col>
    //         <Col span={6} className="text-right shipment-info-label">  Freight Type: </Col>
    //         <Col span={6} className="shipment-info-value"> {data.freightType || "-"}</Col>
    //     </Row>

    //     <Row>
    //         <Col span={6} className="text-right shipment-info-label">Status:  </Col>
    //         <Col span={6} className="shipment-info-value">
    //             <span className={["status-code", (data.status || "").toLowerCase()].join(' ')}>
    //             {data.status || ""}
    //             </span>
    //         </Col>
    //         <Col span={6} className="text-right shipment-info-label">  Actual Delivery Date </Col>
    //         <Col span={6} className="shipment-info-value">
    //             <div>
    //                 {
    //                     !props.isEditActualDate ? <span>{actualDate && moment(actualDate).format(CONSTANTS.DISPLAY_DATE_FORMAT)}</span> : <DatePicker
    //                         format={CONSTANTS.DISPLAY_DATE_FORMAT}
    //                         defaultValue={actualDate}
    //                         style={{ width: "70%", height: "28px" }}
    //                         onChange={(selectedDate) => {
    //                             let shipmentList = props.soShipmentList;
    //                             shipmentList[0].actualDeliveryDate = selectedDate;
    //                             props.updateState({
    //                                 soShipmentList: shipmentList
    //                             });
    //                         }}
    //                     />
    //                 }
    //                 {!props.isEditActualDate ? <span className=" cursor-pointer" onClick={() => {
    //                     props.updateState({
    //                         isEditActualDate: true
    //                     });
    //                 }}> <i className="fa fa-pencil"></i></span> : <span className=" cursor-pointer"> <i onClick={() => {
    //                     props.updateState({
    //                         isEditActualDate: false
    //                     });
    //                     props.updateActualDeliveryDate({
    //                         actualDeliveryDate: getCurrentDateForBackend(actualDate),
    //                         relationshipId: (props.companyInfo || {}).relationshipId,
    //                         shipmentMasterId: data.shipmentMasterId
    //                     });
    //                 }} className="fa fa-save"></i> <i onClick={() => {
    //                     props.updateState({
    //                         isEditActualDate: false
    //                     });
    //                 }} className="fa fa-ban"></i></span>}
    //             </div>
    //         </Col>
    //     </Row>
    //     <Row>
    //         <Col span={6} className="text-right shipment-info-label">Tracking Number </Col>
    //         <Col span={6} className="shipment-info-value">{data.trackingNumber || "-"} </Col>
    //     </Row>
    //     <hr />
    //     <div className="footer">
    //         <Row>
    //             <Col span={2}></Col>
    //             {
    //                 data.status === "Delivered" ? <Col span={5} className="text-right">
    //                     <Button className="ant-btn ant-btn-primary" onClick={() => {
    //                         props.markAsDelivered({
    //                             relationshipId: (props.companyInfo || {}).relationshipId,
    //                             shipmentMasterId: data.shipmentMasterId,
    //                             status: "Shipped",
    //                             lableColor: 'shipped'
    //                         }, props)
    //                     }}> Mark As Shipped </Button>
    //                 </Col> : <Col span={5} className="text-right">
    //                         <Button className="ant-btn ant-btn-primary" onClick={() => {
    //                             props.markAsDelivered({
    //                                 relationshipId: (props.companyInfo || {}).relationshipId,
    //                                 shipmentMasterId: data.shipmentMasterId,
    //                                 status: "Delivered",
    //                                 lableColor: 'delivered'
    //                             }, props)
    //                         }}> Mark As Delivered </Button>
    //                     </Col>
    //             }
    //             <Col span={4} className="text-right">
    //                 <Button className="ant-btn ant-btn-primary" onClick={() => {

    //                 }}>Shipping Invoice</Button>
    //             </Col>
    //             <Col span={4} className="text-right">
    //                 <Button className="ant-btn ant-btn-primary" onClick={() => {
    //                     openDeliveryNotesDetails(data);
    //                 }}> Delievry Notes</Button>
    //             </Col>
    //             <Col span={4} className="text-right">
    //                 <Button className="ant-btn ant-btn-primary" onClick={() => {

    //                 }}>Certifcate of Conformity</Button>
    //             </Col>
    //         </Row>
    //     </div>
    // </div>
    // })
  };

  const openDeliveryNotesDetails = (data) => {
    const shipmentPayload = {
      customerId: data.customerId,
      shipmentMasterId: data.shipmentMasterId,
    };
    props.pushModalToStack({
      modalBody: (
        <ShipmentDetail
          {...props}
          shipmentData={data}
          shipmentPayload={shipmentPayload}
        />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
      handleSubmit: () => { },
    });
  };

  // const openShippingInvoiceDetails = (data) => {
  //     const shipmentPayload = {
  //         customerId: data.customerId,
  //         shipmentMasterId: data.shipmentMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: <ShippingInvoiceDetail {...props} shipmentData={data} shipmentPayload={shipmentPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail',
  //         handleSubmit: () => {
  //         }
  //     });
  // }

  // const openCertifcateOfConformity = (data) => {
  //     const shipmentPayload = {
  //         customerId: data.customerId,
  //         shipmentMasterId: data.shipmentMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: <CertificateOfConformity {...props} shipmentData={data} shipmentPayload={shipmentPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail',
  //         handleSubmit: () => {
  //         }
  //     });
  // }

  return (
    <div className="modal-table-container">
      <div className="table-heading prl20">
        <FormattedMessage id="salesOrderDetails.text" defaultMessage="" />
      </div>
      <div className="prl20">
        <table>
          <thead>
            <tr>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.listing.header.soNo"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.listing.header.date"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.form.gridHeader.qtyOrdered"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.listing.header.qtyPacked"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="customer.salesOrder.listing.header.qtyShipped"
                  defaultMessage=""
                />
              </th>
              <th>
                <FormattedMessage
                  id="modal.txDetails.package.soStatus"
                  defaultMessage=""
                />
              </th>
              {/* <th><FormattedMessage id='package.productDetail.action' defaultMessage='' /></th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    openSoDetails(props.soData);
                  }}
                >
                  {props.soData.soNumber}
                </div>
              </td>
              <td>
                {(props.soData.salesOrderDate || "") &&
                  getMomentDateForUIReadOnly({
                    date: props.soData.salesOrderDate,
                    format: CONSTANTS.DISPLAY_DATE_FORMAT,
                  })}
              </td>
              <td>{props.soDetail?.totalQtyOrdered}</td>
              <td>{props.soDetail?.totalQtyPacked}</td>
              <td>{props.soDetail?.totalQtyShipped}</td>
              <td>
                <span
                  className={[
                    "status-code",
                    props.soData.status?.toLowerCase(),
                  ].join(" ")}
                >
                  {props.soData.status ? <FormattedMessage id={props.soData.status} defaultMessage="" />
                    : ""}
                    </span>
              </td>
              {/* {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT, PERMISSION_VALUES.CREATE) && <td>
                                {props.soData.statusShipment !== TRANSACTION_STATUS.FULFILLED ?
                                    <div className="cursor-pointer" onClick={() => {
                                        props.hideModal();
                                        createShipment(props);
                                    }} >
                                        <FormattedMessage id='createShipment.text' defaultMessage='' />
                                </div>
                                    : <span className={["status-code", props.soData.statusShipment.toLowerCase()].join(' ')}>
                                        <FormattedMessage id={props.soData.statusShipment} defaultMessage='' />
                                    </span>}
                            </td>} */}
            </tr>
          </tbody>
        </table>
      </div>
      <br />

      <div className="shipment-detail-data-heading">
        {props.soShipmentList.length ? (
          <h3>
            {" "}
            <FormattedMessage
              id="shipmentDetails.text"
              defaultMessage=""
            />{" "}
          </h3>
        ) : (
          ""
        )}
      </div>
      {props.soShipmentList.length ? (
        <ShipmentInfoDetail {...props} />
      ) : (
        <div className="create-data-container">
          {checkACLPermission(
            primaryPerm,
            MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT,
            PERMISSION_VALUES.CREATE
          ) && (
              <h4 className="progressbar-modal-heading">
                <FormattedMessage id="shipment.doesnot.exist" defaultMessage="" />
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    props.hideModal();
                    createShipment(props);
                  }}
                >
                  <span> </span>{" "}
                  <FormattedMessage id="clickhere" defaultMessage="" />{" "}
                  <span> </span>
                </span>
                <FormattedMessage
                  id="shipment.doesnot.exist2"
                  defaultMessage=""
                />
              </h4>
            )}

          <img src={ShipmentIcon} alt="Invoice" height="275" />
        </div>
      )}
    </div>
  );
};

export default ShipmentInformation;
