import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SchoolComp from "../../../components/schoolManagement/school";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getSchool, saveSchool, getSchoolCount, deleteSchool, resetPaginatedData } from "./action";
import { pushModalToStack, popModalFromStack } from './../../../actions/commonActions';

class School extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded("getSchool", "schoolList", this.props, payload);
    fetchDataIfNeeded("getSchoolCount", "schoolCount", this.props, payload);
  }

  render() {
    return (
      <SchoolComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></SchoolComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    schoolList: state.school.schoolList,
    schoolCount: state.school.schoolCount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSchool,
      getSchoolCount,
      deleteSchool,
      saveSchool,
      resetPaginatedData,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(School);
