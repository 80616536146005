import React, {useState} from 'react'
// import { UploadOutlined, DeleteTwoTone, DownCircleTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
// import { Switch } from 'react-router-dom';
// import { PlaidLink } from 'react-plaid-link';
import { showToasterMessage } from '../../../utils';
import { getMomentDateForUIReadOnly} from '../../../utils';
import { CONSTANTS} from '../../../static/constants';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const UplaodFiles = (props) => {
    const handleUploadSubmit = () =>{
        if(!selectedFile){
            return showToasterMessage({ messageType: "error", description: 'Please Upload File before submitting' });
        }
        var payload = {};
        // if(props.txnType === 'salesOrder'){
        //     payload = {
        //         endpointUrl:'/doc/uploadSODoc',
        //         files : selectedFile,
        //         txNumber : props.txnFileUploadItem.soNumber,
        //         relationshipId : props.relationshipId,
        //         txId: props.txnFileUploadItem.salesOrderMasterId,
        //     }
        // }
        if(props.txnType === 'shipment'){
            payload = {
                endpointUrl:'/doc/uploadSalesShipmentDoc',
                files : selectedFile,
                txNumber : props.txnFileUploadItem.shipmentNumber,
                relationshipId : props.relationshipId,
                txId: props.txnFileUploadItem.shipmentMasterId,
            }
        }
        // if(props.txnType === 'purchaseOrder'){
        //     payload = {
        //         endpointUrl:'/doc/uploadPODoc',
        //         files : selectedFile,
        //         txNumber : props.txnFileUploadItem.poNumber,
        //         relationshipId : props.relationshipId,
        //         txId: props.txnFileUploadItem.poMasterId,
        //     }
        // }
        if(props.txnType === 'inboundDelivery'){
            payload = {
                endpointUrl:'/doc/uploadGRNDoc',
                files : selectedFile,
                txNumber : props.txnFileUploadItem.inboundDeliveryNumber,
                relationshipId : props.relationshipId,
                txId: props.txnFileUploadItem.inboundDeliveryMasterId,
            }
        }

        props.UploadFiles(payload);
    }
    const backClick = () =>{
        props.updateState({activePage : 'transactionList'})
    }

    const [name, setName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    // useEffect(() => {

    //   },[selectedFile]);

    return (
        <div className=' box-container'>
             <div className="form-boxs">

                {
                    props.txnType === 'shipment' ? <>
                        <h2>
                            Upload Files For Shipment
                        </h2>
                        <div className='detailBox'>
                            <p><b>Shipment # </b> {props.txnFileUploadItem.shipmentNumber || ''} | {props.txnFileUploadItem.customerName || ''} </p>
                            <p><b>Status </b> {props.txnFileUploadItem.status || ''} </p>
                            <p><b>Date </b>  {getMomentDateForUIReadOnly({ date: new Date(props.txnFileUploadItem.shipmentDate), format })} </p>
                        </div>
                    </>
                        : ''
                }
                {
                    props.txnType === 'inboundDelivery' ? <>
                        <h2>
                            Upload Files For GRN
                        </h2>
                        <div className='detailBox'>
                            <p><b>GRN # </b> {props.txnFileUploadItem.inboundDeliveryNumber || ''} | {props.txnFileUploadItem.supplierName || ''} </p>
                            <p><b>Place of Supply </b> {props.txnFileUploadItem.placeOfSupply || ''} </p>
                            <p><b>Date </b>  {getMomentDateForUIReadOnly({ date: new Date(props.txnFileUploadItem.inboundDeliveryDate), format })} </p>
                        </div>
                    </>
                        : ''
                }
        
            {/* <h2>
                {props.txnType === 'shipment' ? 'Upload Files For Shipment': ''}
                {props.txnType === 'inboundDelivery' ? 'Upload Files For GRN': ''}
            </h2> */}
            
            <form className='text-center'>
                <input className='infput-widt'
                type="file"
                value={""}
                onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    setName(e.target.files[0].name);
                }}
                

                />
                <br />
                <p>{name || ''}</p>
                <br />

                <Button type='primary'  onClick={()=>{
                    handleUploadSubmit();
                }}>
                    Submit
                </Button>
                <br />
                <Button  onClick={()=>{
                        backClick()
                    }}
                >
                    Back
                </Button>
            </form>
   
        
        </div>
        </div>
    );
  
}

export default UplaodFiles