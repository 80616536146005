// import React, {Component} from "react";

// class TopCustomerPayment extends Component {
//     render() {

//         const keys = Object.keys(this.props.topPaymentCustomer || {});
//         const values = Object.values(this.props.topPaymentCustomer || {});

//         return (
//             <div className="grid-data-container">
//                 <table>
//                     <thead>
//                     <tr>
//                         <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
//                         <th>Customer Name</th>
//                         <th>Total Payment</th>
//                     </tr>
//                     </thead>
//                     <tbody>
//                     {keys.map((item, i) => {
//                         return (
//                             <tr key={i}>
//                                <td>{i+1}</td>
//                                 <td>{item}</td>
//                                 <td>{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}{values[i].toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
//                             </tr>
//                         );
//                     })}
//                     </tbody>
//                 </table>
//             </div>
//         )
//     }
// }

// export default TopCustomerPayment;


import React from "react";
import ReactApexChart from "react-apexcharts"

class StudentByStatus extends React.Component {
    
    render() {
        
        const data = {
            series: [this.props.totalFinancialAssisatance.financialAssistanceAmt || 0 , this.props.totalFinancialAssisatance.teacherDiscountAmt || 0, this.props.totalFinancialAssisatance.membershipDiscountAmt || 0, this.props.totalFinancialAssisatance.volunteerDiscountAmt || 0 ],
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ['Financial Assistance', 'Teacher', 'Membership', 'Volunteer' ],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        
                    }
                }],
                legend: {
                    position: 'bottom'
                }
            },


        };
        return (
            <div id="chart">
                <ReactApexChart  style={{minHeight: '225.7px', paddingTop: '30px'}} options={data.options} series={data.series} type="pie" width={400}  />
            </div>


        );
    }
}

export default StudentByStatus;