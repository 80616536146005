import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Input, Select, Modal  } from 'antd';
import moment from 'moment-timezone';
import './resetpassword.scss';
import { ErrorMsg } from "../general/ErrorMessage";                                                                                                                                                                                                                                                                                    
// import LogoImg from "../assets/image/logoAlpide.svg";
import SuccessIcon from "../../assets/images/success.svg";



function ResetPasswordForm(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const [email, setEmail] = useState('');
  const [invalidEmailClass, setInvalidEmailClass] = useState(null);
  const [validPassword, setValidPassword] = useState(null);
  const [password, setPassword] = useState(null);
  const [invalidPasswordClass, setInvalidPasswordClass] = useState(null);
  const [passwordMismatch, setPasswordMismatch] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [invalidRepeatPasswordClass, setInvalidRepeatPasswordClass] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sentDone, setSentDone] = useState(false);
 
 
  let source = urlParams.get("source");
  
 
 
  const token = urlParams.get('t');
  
  let repeatErrorMessage = <ErrorMsg
    message={<div className="errorMsg">Password does not match</div>}
    validator={() => {
      return !submittedOnce || (repeatPassword && !passwordMismatch);
    }}
  />

  const validateEmail = (val) => {
    return !!(val && val.toLowerCase().match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/))
  }
  if (!repeatPassword) {
    repeatErrorMessage = <ErrorMsg
      message={<div className="errorMsg">Confirm password is required</div>}
      validator={() => {
        return !submittedOnce || !!repeatPassword;
      }}
    />
  }

  function validatePasswordCheck(password) {
    const minLength = 8;
    const maxLength = 16;
    const specialCharacters = /[!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|]/;
    const capitalLetter = /[A-Z]/;
    const number = /[0-9]/;
    const passwordc = password || '';

    if (passwordc.length < minLength || passwordc.length > maxLength) {
      return false;
    }
    if (!specialCharacters.test(password)) {
      return false;
    }
    if (!capitalLetter.test(password)) {
      return false;
    }
    if (!number.test(password)) {
      return false;
    }

    return true;
  }

  const handleForgotPassword =  () => {
    if(isLoading){return;}
      
  
 
 
 
   
  
   
      if (!submittedOnce) {
        setSubmittedOnce(true);
      }
     
      if ( password && repeatPassword && password === repeatPassword && validPassword) {
        let payload = {
          "token" : token,
          "newPassword" : password
      }
        props.resetPasswordMail(payload,(e)=>{setSentDone(e)})
           

      } else {
       
        setPassword(password);
        setRepeatPassword(repeatPassword);
        setInvalidPasswordClass(!password || !validPassword ? 'input-text-error' : '');
        setInvalidRepeatPasswordClass((!repeatPassword || !passwordMismatch) ? 'input-text-error' : '');
        setPasswordMismatch(password !== repeatPassword);
        setInvalidEmailClass(validateEmail(email) ? '' : 'input-text-error');

      }

   
  }

  return (
    <div className="forgetPasswordPart">
      <div className="forgetPasswordForm">
        {!sentDone ?
        <div >
          {/* <div><img src={LogoImg} /></div> */}
          <div><h2>Reset your password</h2></div>
            <Form>
              <div className="inputField">
                <Input placeholder="Password" value={password} type='password' 
                  className={invalidPasswordClass || 'signup-form-input' }
                  onChange={(e) => {
                                    setPassword(e.target.value);
                                    setInvalidPasswordClass(!e.target.value ? 'input-text-error' : '');
                                    setPasswordMismatch(repeatPassword !== e.target.value);
                                    setValidPassword(validatePasswordCheck(e.target.value));
                                    // updateState({
                                    //     password: e.target.value,
                                    //     invalidPasswordClass: !e.target.value ? 'input-text-error' : '',
                                    //     passwordMismatch: repeatPassword !== e.target.value,
                                    //     validPassword: validatePasswordCheck(e.target.value),
                                    // });
                                  }}
                                  validator={() => {
                                    return !!password || validPassword;
                }}
                                />
                <ErrorMsg
                  validator={() => {
                  
                    return !submittedOnce || (!!password && validPassword);
                  }}
                  message={<div className="errorMsg">Invalid Password (Password must be a combination of lower-case, upper-case, numbers, special characters  and at least 8 characters long)</div>}
                />
              </div>

              <div className="inputField">
                <Input placeholder="Repeat Password"
                                  value={repeatPassword}
                                  type='password'
                                  className={invalidRepeatPasswordClass || 'signup-form-input'}
                                  validator={() => {
                                    return !!repeatPassword;
                                  }}
                                  onChange={(e) => {
                                    setRepeatPassword(e.target.value);
                                    setInvalidRepeatPasswordClass(!e.target.value || (password !== e.target.value) ? 'input-text-error' : '');
                                    setPasswordMismatch(password !== e.target.value);
                                    // updateState({
                                    //     repeatPassword: e.target.value,
                                    //     invalidRepeatPasswordClass: !e.target.value || (password !== e.target.value) ? 'input-text-error' : '',
                                    //     passwordMismatch: password !== e.target.value,
                                    // });
                                  }}
                                />
                                {
                                  repeatErrorMessage
                                }
              </div>

              <div className="inputField">
                <Button className="signup-create-account"  onClick={() => {
                            handleForgotPassword(props);
                          }}>
                    {!isLoading ? <span>Submit</span>
                    :
                      <span><i class="fa fa-circle-o-notch fa-spin"></i>Loading</span>
                    }
                </Button>
              </div>
            </Form>
            <div className="inputField text-center">
              Back to <span className="cursor-pointer link" onClick={(e) => {
                  e.preventDefault();
                   props.history.push('/') 
                  }}>
                    <span>Login</span>
                </span>
              {/* <a href="https://login.alpide.com/wp-login.php">Login</a> */}
            </div>
        </div>
        :
        <div>
          <div className="text-center">
            <img src={SuccessIcon} alt="success"/>
          </div>
          <div className="text-center"><h2>Password Changed!</h2></div>
          <div className="text-center"><p>Your password has been changed successfully</p></div>
          <div className="inputField">
                <Button className="signup-create-account" onClick={() => {
                   window.location = window.location.origin+"/?source="+source;
                  //window.location.href=('https://login.alpide.com/wp-login.php') 
                  }}>
                    <span>Back to Login</span>
                </Button>
              </div>
        </div>
}
      </div>
    </div>
  );
}

export default ResetPasswordForm;