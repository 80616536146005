import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { Menu, Skeleton, Dropdown, Pagination, Empty } from "antd";
import PageBreadcrumb from "../../../../PageBreadcrumb";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { MoreOutlined } from "@ant-design/icons";

import "./index.scss";
import {
  CONSTANTS,
  MODAL_TYPE,
  GRID_ACTIONS,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  ICONS,
} from "../../../../../static/constants";
import {
  checkACLPermission,
  getMomentDateForUIReadOnly,
} from "../../../../../utils";
import PageHeader from "../../../../Common/pageHeader";
// import WarehouseIcon from './../../../../../assets/images/warehouse.svg';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const WarehouseListingComp = (props) => {
  const {
    permissions,
    allWarehouses,
    companyInfo,
    showModal,
    pageNumber,
    pageSize,
  } = props;
  // const getRowHeight = (params) => {
  //     let height = 34;
  //     let calcHeight = 0;
  //     if (params.data.locationList && params.data.locationList[0]) {
  //         const address = params.data.locationList[0];
  //         if (address) {
  //             calcHeight = (address.streetAddress1 || address.streetAddress2) ? calcHeight + 1 : calcHeight;
  //             calcHeight = (address.stateName || address.cityName || address.zipCode) ? calcHeight + 1 : calcHeight;
  //         } else {
  //             calcHeight = 1;
  //         }
  //     }
  //     return height * (calcHeight < 1 ? 1 : calcHeight);
  // }

  const newWarehouseAddition = (warehouseData) => {
    const formData = warehouseData || {};
    formData.relationshipId = companyInfo.relationshipId;
    const payload = { formData: formData };
    const data = {
      title: !warehouseData.warehouseMasterId
        ? "Create Warehouse"
        : "Edit Warehouse",
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.WAREHOUSE,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  const deleteWarehouse = (payload) => {
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.warehouseName }}
      />
    );
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteWarehouse({
          id: payload.warehouseMasterId,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  const getAddrsssView = (data) => {
    if (data.locationList && data.locationList[0]) {
      const address = data.locationList[0];
      return (
        <div>
          <div>
            {" "}
            {address.streetAddress1 || " "}
            {address.streetAddress2 || ""}
          </div>
          <div>
            {" "}
            {address.cityName ? address.cityName + ", " : ""}{" "}
            {address.stateName ? address.stateName + ", " : ""}
            {address.zipCode || ""}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const getActionPermissionData = () => {
    const primaryPerm = permissions.primary || [];
    const permittedAction = {
      update: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.MY_WAREHOUSE,
        PERMISSION_VALUES.UPDATE
      ),
      create: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.MY_WAREHOUSE,
        PERMISSION_VALUES.CREATE
      ),
      delete: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.MY_WAREHOUSE,
        PERMISSION_VALUES.DELETE
      ),
    };
    Object.keys(permittedAction).forEach((operation) => {
      if (permittedAction[operation] === false) {
        delete permittedAction[operation];
      }
    });
    return permittedAction;
  };

  const permittedAction = getActionPermissionData();
  const columnDefs = [
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="inventory.warehouse.myWarehouse.listing.header.warehouseName"
            defaultMessage=""
          />
        );
      },
      field: "warehouseName",
      rowDrag: true,
      resizable: true,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="inventory.warehouse.myWarehouse.listing.header.isPrimary"
            defaultMessage=""
          />
        );
      },
      resizable: true,
      cellRendererFramework: (params) => (
        <div>{params.data.isPrimary ? "Yes" : "No"}</div>
      ),
      width: 140,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="inventory.warehouse.myWarehouse.listing.header.totalItems"
            defaultMessage=""
          />
        );
      },
      field: "totalItem",
      resizable: true,
      width: 100,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="inventory.warehouse.myWarehouse.listing.header.warehouseAddress"
            defaultMessage=""
          />
        );
      },
      cellRendererFramework: function (link) {
        return getAddrsssView(link.data);
      },
      width: 400,
    },
    {
      headerComponentFramework: () => {
        return (
          <FormattedMessage
            id="inventory.warehouse.myWarehouse.listing.header.dateCreated"
            defaultMessage=""
          />
        );
      },
      resizable: true,
      cellRendererFramework: (link) => (
        <div>
          {getMomentDateForUIReadOnly({
            date: new Date(link.data.dateCreated),
            format,
          })}
        </div>
      ),
      width: 150,
    },
  ];

  if (permittedAction.update || permittedAction.delete) {
    columnDefs.push({
      cellRenderer: "actionEditAndDelete",
      suppressNavigable: true,
      cellRendererParams: (params) => {
        return {
          permittedAction,
          onClick: (action) => {
            switch (action) {
              case GRID_ACTIONS.EDIT: {
                permittedAction.update && newWarehouseAddition(params.data);
                break;
              }
              case GRID_ACTIONS.DELETE: {
                permittedAction.delete && deleteWarehouse(params.node.data);
                break;
              }
              default: {
                break;
              }
            }
          },
        };
      },
      width: 95,
    });
  }

  // const onFirstDataRendered = (params) => {
  //     params.api.sizeColumnsToFit();
  // }

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.warehouse" defaultMessage="" />
    },
  ];

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => permittedAction.update && newWarehouseAddition(item)}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>
        <Menu.Item
          key="1"
          value="delete"
          onClick={() => permittedAction.delete && deleteWarehouse(item)}
        >
          <i className={ICONS.DELETE} />
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  return (
    <>
      {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="heading.inventry.myWarehouse"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          {permittedAction.create ? (
            <button
              className="create-action-btn"
              onClick={newWarehouseAddition}
            >
              <i className={`${ICONS.ADD} mr5`} />
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          ) : (
            <Fragment />
          )}
        </div>
      </div> */}

      <PageHeader {...props}
        pageName="heading.inventry.myWarehouse"
        breadCrumbList= {breadCrumbList}
        canCreate = {permittedAction.create}
        onCreate = {newWarehouseAddition}
      />


      <div className="view-container">
        {/* <div className="page-title">
                    <FormattedMessage id='inventory.warehouse.myWarehouse.listing.header.warehouse' defaultMessage='' />
                </div> */}
        {/* {
                    permittedAction.create
                        ? (
                            <div className="action-dropdown-btn pull-right">
                                <button className="right-border" onClick={newWarehouseAddition}>
                                    <img src={WarehouseIcon} alt="warehouse" className="warehouse-icon mr5" />
                                    <FormattedMessage id='inventory.warehouse.myWarehouse.listing.header.newWarehouse' defaultMessage='' />
                                </button>
                            </div>
                        )
                        : <Fragment />
                } */}
 
          {" "}
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="inventory.warehouse.myWarehouse.listing.header.warehouse-list"
                  defaultMessage=""
                />
              </div>
            </div>
            <div className="right-actions"></div>
          </div>
          {/* <div className='agGridWrapper d-flex w-100 pt15'>
                        <div className="ag-theme-balham myWarehouse-listing" style={agGridStyle} >
                            <CustomAgGrid
                                columnDefs={columnDefs}
                                rowData={allWarehouses || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                getRowHeight={getRowHeight}
                                onGridSizeChanged={onFirstDataRendered}
                            >
                            </CustomAgGrid>
                        </div>
                    </div> */}
          <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.warehouse.myWarehouse.listing.header.warehouseName"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.warehouse.myWarehouse.listing.header.isPrimary"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.warehouse.myWarehouse.listing.header.totalItems"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.warehouse.myWarehouse.listing.header.warehouseAddress"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th>
                    <FormattedMessage
                      id="inventory.warehouse.myWarehouse.listing.header.dateCreated"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th>
                    <FormattedMessage
                      id="customer.listing.header.action"
                      defaultMessage=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(allWarehouses || []).length ?
                  allWarehouses
                    .map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>
                          <td>{rowData.warehouseName}</td>
                          <td>{rowData.isPrimary ? "Yes" : "No"}</td>
                          <td>{rowData.totalItem}</td>
                          <td>{getAddrsssView(rowData)}</td>
                          <td>
                            {getMomentDateForUIReadOnly({
                              date: rowData.dateCreated,
                              format,
                            })}
                          </td>
                          <td style={{ paddingLeft: "50px" }}>
                            <Dropdown
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })
                    :
                    <tr key="empty-data-box">
                      <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                        <Empty />
                      </td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </Skeleton>

        {/* <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={allWarehouses.length}
              showTotal={showTotal}
              defaultPageSize={100}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                // loadPageData(pageNumber, pageSize);
                // updateState({pageSize : pageSize, pageNumber: pageNumber})
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                // handlePageSizeChange(pageNumber, pageSize, props)
              }}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default injectIntl(WarehouseListingComp);
