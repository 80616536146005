import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductDetailsComponent from '../../../components/product/Details';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
import { fetchInventoryItemDetails, fetchSalesTransactions, fetchPurchaseTransactions, fetchSalesTransactionsCount, fetchPurchaseTransactionsCount, resetSalesPaginatedData, resetPurchasePaginatedData } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../actions/commonActions';

class ProductDetailContainer extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    this.state = {
      itemId: (location.state || {}).itemId,
      pageSize: 25,
      pageNumber: 1,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      itemId: this.state.itemId,
      pageNumber: 1,
      pageSize: 25
    };
    fetchDataIfNeeded('fetchInventoryItemDetails', 'productDetails', this.props, payload, true);
    fetchPaginationDataIfNeeded('fetchSalesTransactions', 'salesTransactions', this.props, payload, true);
    fetchPaginationDataIfNeeded('fetchPurchaseTransactions', 'purchaseTransactions', this.props, payload, true);
    fetchDataIfNeeded('fetchSalesTransactionsCount', 'salesTransactionsCount', this.props, payload, true);
    fetchDataIfNeeded('fetchPurchaseTransactionsCount', 'purchaseTransactionsCount', this.props, payload, true);
  }

  render() {
    return (
      <ProductDetailsComponent
        {...this.props}
        {...this.state}
        updateState={(data) => { this.setState(data) }}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    salesTransactions: state.product.salesTransactions,
    purchaseTransactions: state.product.purchaseTransactions,
    productDetails: state.product.productDetails,
    salesTransactionsCount: state.product.salesTransactionsCount,
    purchaseTransactionsCount: state.product.purchaseTransactionsCount,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchInventoryItemDetails,
  fetchSalesTransactions,
  fetchPurchaseTransactions,
  showModal,
  pushModalToStack,
  hideModal,
  popModalFromStack,
  fetchSalesTransactionsCount,
  fetchPurchaseTransactionsCount,
  resetSalesPaginatedData, 
  resetPurchasePaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailContainer);
