import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InvoiceComp from "../../../../components/drawer/schoolManagement/invoice";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { saveSchool } from '../../../schoolManagement/school/action';

class InvoiceDrawer extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {

  }

  render() {
    return (
      <InvoiceComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></InvoiceComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveSchool,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDrawer);
