import axios from "axios";
import config from "../../../config/environmentConfig";
import { showToasterMessage } from "../../../utils";
import { COMMON_ACTIONS } from "../../../static/constants";
export const updateoraddThemeHeaderLogo = (payload) => {
  return (dispatch) => {
    let formData = new FormData();

    if (payload.file) {
      formData.append("file", payload.file);
    }
    formData.append("relationshipId ", payload.relationshipId);
    formData.append('bucketName',payload.bucketName)
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/addHeaderLogo`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "Image Upload Succssfully.",
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};
export const updateoraddThemeHeaderLogo1 = (payload) => {
  return (dispatch) => {
    let formData = new FormData();

    if (payload.file) {
      formData.append("file", payload.file);
    }
    formData.append("relationshipId ", payload.relationshipId);
    formData.append('bucketName',payload.bucketName)
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/addLightBgLogo`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "Image Upload Succssfully.",
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};
export const updateoraddThemeStoreFavicon = (payload) => {
  return (dispatch) => {
    let formData = new FormData();
    if (payload.file) {
      formData.append("file", payload.file, payload.file.name);
    }
    formData.append("relationshipId ", payload.relationshipId);
    formData.append('bucketName',payload.bucketName)
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/addFaviconIcon`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "Image Upload Succssfully.",
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const uploadPopUpIcon = (payload) => {
  return (dispatch) => {
    let formData = new FormData();

    if (payload.file) {
      formData.append("file", payload.file);
    }
    formData.append("relationshipId ", payload.relationshipId);
    formData.append('bucketName',payload.bucketName)
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_setting/addPopUpIcon`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "Image Upload Succssfully.",
        });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};
