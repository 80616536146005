import React, { Fragment } from "react";
import { Drawer, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../general/CustomButton";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { Dropdown } from "../../general/Dropdown";
import { TextBox } from "../../general/TextBox";
import { showToasterMessage } from "../../../utils";

const PaymentListFilterComp = (props) => {
  const {
    updateState,
    companyInfo,
    pageNumber,
  } = props;
  const closeDrawer = () => {
    props.updateState({
      paymentListFilterDrawerVisible: false,
    });
  };

  const handleClearFilter = () => {
    props.updateState({
      selectedSupplierName: null,
      selectedSupplierId: null,
      paymentNum: null,
      selectedInvNum: null,
      selectedPaymentAmt: null,
      selectedAmountTerm: null,
      selectedIsInvoicedValue: null,
      selectedIsInvoiced: null,
      selectedRefNum: null,
      selectedProjectMasterId: null,
      selectedProjectName: null,
    });
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {},
    };
    props.fetchSupplierPaymentsByFilter(payload);
    props.getSupplierPaymentsCountByFilter(payload);
  };

  const handleSubmit = () => {
    if (
      !props.selectedSupplierName &&
      !props.paymentNum &&
      !props.selectedInvNum &&
      !(props.selectedPaymentAmt && props.selectedAmountTerm) &&
      !props.selectedRefNum &&
      !props.selectedProjectMasterId
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please select at least one filter",
      });
    }
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {
        supplierId: props.selectedSupplierId,
        paymentNumber: props.paymentNum,
        invoiceNumber: props.selectedInvNum,
        paymentAmount: props.selectedPaymentAmt,
        amountSymbol: props.selectedPaymentAmt
          ? props.selectedAmountTerm
          : null,
        reference: props.selectedRefNum,
        projectMasterId: props.selectedProjectMasterId,
      },
    };
    props.fetchSupplierPaymentsByFilter(payload);
    props.getSupplierPaymentsCountByFilter(payload);
    closeDrawer();
  };

  return (
    <Fragment>
      <Drawer
        title={
          // <FormattedMessage id="teacher.teacherList.filter.drawer.title" />
          "Filters"
        }
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.paymentListFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={handleClearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={handleSubmit}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={[16, 16]}>
        <Col span="12">
            <div className="i-label">Payment #</div>
            <TextBox
              // style={{ width: '120px'}}
              placeholder="Payment #"
              type="text"
              maxLength={20}
              countStyle={{top:"40px"}}
              value={props.paymentNum}
              onChange={(e) => {
                props.updateState({ paymentNum: e.target.value });
              }}
            />
          </Col>
          <Col span="12">
            <div className="i-label">Supplier</div>
            <Dropdown
              //   style={{ width: "160px", marginTop: "-20px" }}
              items={props.suppliers[pageNumber] || []}
              sortItems={true}
              valueKeyName="supplierStoreName"
              optionKeyName="supplierId"
              value={props.selectedSupplierName}
              onSearch={(searchedText) => {
                props.fetchSuppliers({
                  isCompact: true,
                  searchedText: searchedText,
                  pageNumber: 1,
                  hideSpinner: true,
                  pageSize: 100,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
              }}
              placeholder={props.intl.formatMessage({
                id: "supplier.filter.name.placeholder",
                defaultMessage: "",
              })}
              onSelect={(supplierId, option) => {
                updateState({
                  selectedSupplierId: option.value,
                  selectedSupplierName: option.name,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedSupplierId: undefined,
                  selectedSupplierName: undefined,
                });
              }}
            />
          </Col>

         

          <Col span="12">
            <div className="i-label">Invoice #</div>
            <TextBox
              // style={{ width: '120px'}}
              placeholder="Invoice #"
              type="text"
              maxLength={20}
              countStyle={{top:"40px"}}
              value={props.selectedInvNum}
              onChange={(e) => {
                props.updateState({ selectedInvNum: e.target.value });
              }}
            />
          </Col>

          <Col span="12">
            <div className="i-label">Projects</div>
            <Dropdown
              //   style={{ width: "160px", marginTop: "-20px" }}
              items={props.projectList}
              sortItems={true}
              valueKeyName="projectName"
              optionKeyName="projectMasterId"
              // projectIdKey="projectMasterId"
              value={props.selectedProjectName}
              placeholder={props.intl.formatMessage({
                id: "salesInvoice.project.placeholder",
              })}
              onSelect={(status, option) => {
                updateState({
                  selectedProjectName: option.name,
                  selectedProjectMasterId: option.value,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedProjectName: undefined,
                  selectedProjectMasterId: undefined,
                });
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </Fragment>
  );
};

export default PaymentListFilterComp;
