import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Form, Select, DatePicker, InputNumber, Checkbox, Button, Tooltip } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS } from '../../static/constants';
import { CustomButton } from '../general/CustomButton';
import { showToasterMessage, getMomentDateForUIReadOnly } from '../../utils';
//import * as find from 'lodash.find';
import moment from 'moment-timezone';
import { ErrorMsg } from '../general/ErrorMessage';
const { Option } = Select;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const fixedDecimalNumber = (number) => {
    return (Number(number || 0)).toFixed( CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
  }


function ProcessPayment(props) {
   
    const { soDetail} = props;
    //const relationshipAddress = find(soDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};

    return (
        <>{soDetail.soNumber}</>
        // <div className="header-action-container">
        //     <Form>
        //         <div className="information" style={{ maxHeight: '510px', height: '350px' }}>
        //             <div className="prl30">
        //                 <Row>
        //                     <Col span={24} style={{ textAlign: 'center' }}><FormattedMessage id='payment.confirmation.text' /></Col>
        //                 </Row>
        //                 <br/>
        //                 <Row className="pb10">
        //                     <Col span={8} className="title text-right">
        //                                 <FormattedMessage id='modal.common.priceList.name' defaultMessage='' />
        //                     </Col>
        //                     <Col span={16}>
        //                       {soDetail.customerName}
        //                     </Col>
        //                 </Row>

        //                 <Row className="pb10">
        //                     <Col span={8} className="title text-right">
        //                         <FormattedMessage id='modal.customer.payInvoice.invoiceNumber' defaultMessage='' />
        //                     </Col>
        //                     <Col span={16}>
        //                         {soDetail.soNumber}
        //                     </Col>
        //                 </Row>
        //                 <Row className="pb10">
        //                     <Col span={8} className="title text-right">
        //                         <FormattedMessage id='drawer.invoice.invoiceDate' defaultMessage='' />
        //                     </Col>
        //                     <Col span={16}>
        //                         { soDetail.invoiceDate && getMomentDateForUIReadOnly({date: soDetail.invoiceDate, format: CONSTANTS.DISPLAY_DATE_FORMAT})}
        //                     </Col>
        //                 </Row>
        //                 <Row className="pb10">
        //                     <Col span={8} className="title text-right">
        //                         <FormattedMessage id='modal.txDetails.payment.invoiceAmount' defaultMessage='' />
        //                     </Col>
        //                     <Col span={16}>
        //                         {soDetail.isMultiCurrency ?
        //                             <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency+ " "}</span>
        //                             : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
        //                         } {fixedDecimalNumber(soDetail.invoiceTotalAmount)}
        //                     </Col>
        //                 </Row>
        //                 <Row className="pb10">
        //                     <Col span={8} className="title text-right">
        //                         <FormattedMessage id='makeRecurring.text' defaultMessage='' />
        //                     </Col>
        //                     <Col span={16}>
        //                         <Select onChange={(value) => {
        //                             props.updateState({
        //                                 period: value,
        //                             })
        //                         }}
        //                             className={(props.submittedOnce && !props.period) ? 'input-text-error' : ''}
        //                             style={{ width: 250 }}
        //                             placeholder="Please select"
        //                             optionFilterProp="children"
        //                             value={props.period}>
        //                             <Option value="oneTimePayment"><FormattedMessage id='oneTimePayment.text' /></Option>
        //                             {/* <Option value="daily"><FormattedMessage id='recurringInvoice.setup.daily' /></Option> */}
        //                             <Option value="weekly"><FormattedMessage id='recurringInvoice.setup.weekly' /></Option>
        //                             <Option value="monthly"><FormattedMessage id='recurringInvoice.setup.monthly' /></Option>
        //                             <Option value="yearly"><FormattedMessage id='recurringInvoice.setup.yearly' /></Option>
        //                         </Select>
        //                         <ErrorMsg
        //                             validator={() => { return !props.submittedOnce || !!props.period }}
        //                             message={<FormattedMessage id='addItem.validation' values={{value: 'This Field'}} />}
        //                         />
        //                     </Col>
        //                 </Row>
        //                 {(props.period && props.period !== "oneTimePayment") ?
        //                     <Row className="pb10">
        //                         <Col span={8} className="title text-right">
        //                             <Tooltip placement="top" title={props.intl.formatMessage({ id: 'paymentStartDate.helpMessage' })}>
        //                                 <span className="tooltip-title">
        //                                     <FormattedMessage id='paymentStartDate.text' defaultMessage='' />
        //                                 </span>
        //                             </Tooltip>
        //                         </Col>
        //                         <Col span={16}>
        //                             <DatePicker
        //                                 allowClear={false}
        //                                 format={format}
        //                                 style={{ width: 250 }}
        //                                 // disabledDate={invalidEndDate}
        //                                 key={moment(new Date(), format)}
        //                                 defaultValue={props.paymentStartDate ? moment(new Date(props.paymentStartDate), format) : null}
        //                                 onChange={(selectedDate) => {
        //                                     props.updateState({
        //                                         paymentStartDate: selectedDate
        //                                     })
        //                                 }} />
        //                         </Col>
        //                     </Row> : ''
        //                 }
        //                 {(props.period && props.period !== "oneTimePayment") ?
        //                     <Row className="pb10">
        //                         <Col span={8} className="title text-right">
        //                             <FormattedMessage id='noOfPayment.text' defaultMessage='' />
        //                         </Col>
        //                         <Col span={16}>
        //                             {!props.neverExpires && <div>
        //                                 <InputNumber style={{ width: 250 }} value={props.noOfPayment} placeholder="" min={1} onChange={(value) => {
        //                                     props.updateState({ noOfPayment: value })
        //                                 }} /></div>
        //                             }
        //                             <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
        //                                 props.updateState({
        //                                     neverExpires: e.target.checked ? 1 : 0,
        //                                 })
        //                             }} /> <FormattedMessage id='recurringInvoice.setup.neverExpires' defaultMessage='' />
        //                         </Col>
        //                     </Row> : ''
        //                 }
        //             </div>
        //         </div>
        //     </Form>

        //     <div className="align-footer" style={{     borderTop: '1px solid #e5e5e5', }}>
        //         <br/>
        //         <CustomButton
        //             intlId='confirmation.cancel'
        //             defaultMessage=''
        //             type='default'
        //             key={0}
        //             style={{ marginRight: 8 }}
        //             onClick={props.popModalFromStack}
        //         />
        //         <Button
        //             type='primary'
        //             htmlType='submit'
        //             key={1}
        //             style={{ float: 'right' }}
        //             onClick={() => {
        //                 props.updateState({ submittedOnce: true });
        //                 if (!props.period) {
        //                     return;
        //                 }
        //                 if (!props.neverExpires && !props.noOfPayment) {
        //                     return showToasterMessage({ description: 'Please enter No. of Payment' });;
        //                 }

        //                 let payload = {
        //                     subscriptionName: (props.soDetail.projectName || '').substring(0,15) +'-' +(soDetail.customerName|| '').substring(0,20)+'-' +(soDetail.invoiceNumber|| '').substring(0,13),
        //                     startDate: getMomentDateForUIReadOnly({ date: props.paymentStartDate || new Date(), format: CONSTANTS.BACKEND_FORMAT_FORMAT_SHORT }),
        //                     totalOccurrences: props.neverExpires ? 9999 : props.noOfPayment
        //                 }
        //                 if (props.period === 'weekly') {
        //                     payload.intervalLength = 7;
        //                     payload.unit = 'days';
        //                 } else if (props.period === 'monthly') {
        //                     payload.intervalLength = 1;
        //                     payload.unit = 'months';
        //                 } else if (props.period === 'yearly') {
        //                     payload.intervalLength = 12;
        //                     payload.unit = 'months';
        //                 }
        //                 props.getAuthNetPaymentUrl(soDetail, props.period === 'oneTimePayment' ? undefined : payload)
        //                 // const modalData = {
        //                 //     modalBody: <FormattedMessage id='modal.customer.payInvoice.saveConfirmation' defaultMessage='' />,
        //                 //     handleSubmit: () => {

        //                 //     }
        //                 // };
        //                 // props.pushModalToStack(modalData);

        //             }}

        //         >  <FormattedMessage id='pay.text' /> <span className='pl5'>- {soDetail.isMultiCurrency ?
        //             <span> {soDetail.foreignCurrencyIcon ? <i className={soDetail.foreignCurrencyIcon}></i> : soDetail.foreignCurrency+ " "} </span>
        //             : <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</span>
        //         }{fixedDecimalNumber(soDetail.invoiceTotalAmount)}
        //             </span>
        //         </Button>
        //     </div>
        // </div>
    );

}

export default injectIntl(ProcessPayment);
