import React, { Component, Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { AgGridReact } from 'ag-grid-react';
import {   Button } from 'antd';
import {  fixedDecimalNumber, getMomentDateForUIReadOnly, getMomentDateForUI, getFinancialYear } from '../../../../../../utils';
import { CONSTANTS } from '../../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../../static/cssClassConstants';
import PurchaseInvoiceDetail from '../../../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail';
import SalesInvoiceDetails from '../../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import BusinessExpenseDetail from '../../../../../../containers/modal/modalBody/finance/BusinessExpense';
import CustomerPaymentDetails from '../../../../../../containers/modal/modalBody/customer/PaymentDetail';
import SupplierPaymentDetails from '../../../../../../containers/modal/modalBody/supplier/PaymentDetail'

class AssociateTransactionsComponent extends Component {
    constructor(props) {
        super(props);
        const finacialYear = getFinancialYear(this.props.companyInfo);
        this.state = {
            ledgerStartDate: getMomentDateForUI({date: new Date(finacialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT}),
            ledgerEndDate: getMomentDateForUI({date: new Date(),format:  CONSTANTS.BACKEND_FORMAT}),
        }
    }

    render() {


        const format = CONSTANTS.DISPLAY_DATE_FORMAT;

        const getRowHeight = (params) => {
            return 36;
        }
        const handleAssociate = (data, props) => {
            const modalData = {
                modalBody: <FormattedMessage
                    id='save.confirmation'
                    defaultMessage=''
                />,
                handleSubmit: () => {
                    data.transactionId = props.transactionData.transactionId;
                    data.institutionName = props.transactionData.institutionName;
                    data.paymentChannel = props.transactionData.paymentChannel;
                    data.merchantName = props.transactionData.merchantName;
                    if (Number(props.transactionData.Withdrawal || 0) < 0) {
                        this.props.associateWithdrawal(data, props);
                    } else {
                        this.props.associateDiposit(data, props);
                    }
                    props.popModalFromStack();
                }
            };
            props.pushModalToStack(modalData);
        }

        const openInvoiceDetails = (data) => {
            if (Number(this.props.transactionData.Withdrawal || 0) < 0) {
                if (data.expenseMasterId) {
                    const expensePayload = {
                        expenseMasterId: data.expenseMasterId
                    }
                    this.props.pushModalToStack({
                        modalBody: <BusinessExpenseDetail {...this.props} expensePayload={expensePayload} />,
                        width: '100%',
                        hideFooter: true,
                        wrapClassName: 'modal-custom-detail'
                    })
                } else {
                    const invoicePayload = {
                        supplierId: data.supplierId,
                        invoiceMasterId: data.invoiceMasterId
                    }
                    this.props.pushModalToStack({
                        modalBody: <PurchaseInvoiceDetail {...this.props} invoicePayload={invoicePayload} />,
                        width: '100%',
                        hideFooter: true,
                        wrapClassName: 'modal-custom-detail'
                    })
                }
            } else {
                const invoicePayload = {
                    customerId: data.customerId,
                    invoiceMasterId: data.invoiceMasterId
                }
                this.props.pushModalToStack({
                    modalBody: <SalesInvoiceDetails {...this.props} invoicePayload={invoicePayload} />,
                    width: '100%',
                    hideFooter: true,
                    wrapClassName: 'modal-custom-detail'
                })
            }
        }

        const openPaymentDetails = (data) => {
            if (data.supplierPaymentId) {
                const paymentPayload = {
                    supplierId: data.supplierId,
                    supplierPaymentId: data.supplierPaymentId
                }
                this.props.pushModalToStack({
                    title: <FormattedMessage id='sidebar.purchasePayment.popup.title'/>,
                    modalBody: <SupplierPaymentDetails {...this.props} paymentPayload={paymentPayload} />,
                    width: 500,
                    hideFooter: true,
                })
            } else {
                const paymentPayload = {
                    customerId: data.customerId,
                    customerPaymentId: data.customerPaymentId
                }
                this.props.pushModalToStack({
                    title: <FormattedMessage id='sidebar.salesPayment.popup.title'/>,
                    modalBody: <CustomerPaymentDetails paymentPayload={paymentPayload} />,
                    width: 500,
                    hideFooter: true,
                })
            }
        }
    

        const columnDefs = [
            // {
            //     field: 'isChecked',
            //     colId: 'isChecked_1',
            //     headerComponentFramework: () => <Fragment />,
            //     cellRendererFramework: (params) => {
            //         return <Checkbox
            //             onChange={(e) => {
            //                 params.node.setDataValue('isChecked_1', e.target.checked);
            //                 params.node.data.isChecked = e.target.checked;
            //             }}
            //             checked={params.data.isChecked} />
            //     },
            //     width: 50,
            //     resizable: false
            // },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.date' defaultMessage="" />;
                },
                field: "paymentDate",
                cellRendererFramework: (link) => <div>{getMomentDateForUIReadOnly({date: new Date(link.value), format})}</div>,
                resizable: true,
                maxWidth: 200,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.customer.payInvoice.customerName' defaultMessage="" />;
                },
                field: "customerName",
                resizable: true,
                maxWidth: 200,
                hide: Number(this.props.transactionData.Withdrawal || 0) < 0
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.supplier.payInvoice.supplierName' defaultMessage="" />;
                },
                field: "supplierName",
                resizable: true,
                maxWidth: 200,
                hide: Number(this.props.transactionData.Deposit || 0) > 0,
                cellRendererFramework: (link) => <div>{link.value || 'NA'}</div>,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.transactionNumber' defaultMessage="" />;
                },
                field: "txNumber",
                resizable: true,
                maxWidth: 300,
                cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                    openInvoiceDetails(link.data)
                }}>{link.value}</div>,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.customerPayment.listing.header.paymentNo' defaultMessage="" />;
                },
                field: "paymentNumber",
                resizable: true,
                maxWidth: 300,
                cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                    openPaymentDetails(link.data)
                }}>{link.value}</div>,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='transactionAmount.text' defaultMessage="" />;
                },
                field: "totalAmount",
                cellRendererFramework: (link) => {
                    return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "))}{fixedDecimalNumber(Number(link.value))} </span> : "-"
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='common.project' defaultMessage='' />;
                },
                field: "project",
                minWidth: 70,
                maxWidth: 300,
                suppressMovable: true,
                cellRendererFramework: (link) => {
                    return <div>
                        {link.data.projectNumber ? <span>{link.data.projectNumber + ' (' + link.data.projectName + ')'}</span> : ''}
                    </div>
                },
                resizable: true
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.listing.header.action' defaultMessage="" />;
                },
                maxWidth: 120,
                cellRendererFramework: (link) => {
                    return <Button className="ant-btn-primary" onClick={() => { 
                        handleAssociate(link.data, this.props);
                    }}><FormattedMessage id='associate.text' defaultMessage="" /></Button>
                },
            },
          
        ];


        return (
            <div>
                <Fragment>
                    <br />
                    <div className='agGridWrapper pr10 pl10'>
                        <div className="ag-theme-balham customer-details-table" >
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={this.props.unassociateTransactions || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onColumnResized={(params) => {
                                    params.api.resetRowHeights(34);
                                }}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                onGridSizeChanged={(params) => {
                                    params.api.sizeColumnsToFit();
                                }}
                                getRowHeight={getRowHeight}
                            >
                            </AgGridReact>
                            <br></br>
                            
                        </div >
                    </div>
                </Fragment>
            </div>
        )
    }
}

export default injectIntl(AssociateTransactionsComponent);
