import React, { Component, Fragment } from "react";
// import { Form } from "@ant-design/compatible";
import { QuestionCircleTwoTone,EditOutlined } from "@ant-design/icons";

import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Tooltip, Input } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment-timezone";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import { Dropdown } from "../../../../general/Dropdown";
import { TextBox } from "../../../../general/TextBox";
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../../static/constants";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { showToasterMessage } from "../../../../../utils";
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
// import LoadingIcon from "../../../../../assets/images/loading.gif";

const format = CONSTANTS.TABLE_DATE_FORMAT;
class PurchaseRequestDetails extends Component {
  constructor(props) {
    super(props);
    this.companyInfo = this.props.companyInfo || {};
  }
  handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (taxIdentification) {
      if (props.customer && props.customer.customerTaxIdentificationsList) {
        let list = props.customer.customerTaxIdentificationsList || [];
        list.push(taxIdentification)
        props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
      }
    }
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }
  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS && (!formData || !formData.customerId)) {
      return showToasterMessage({ messageType: 'error', description: 'Please Select Customer' });
    }
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
      payload.formData = { addressType:'relationship', locationType: 'RelationshipBillingAddress' };
  }
  payload.formData.callback = function (address) {
      props.hideModal(data);
      if (address.locationType === 'BillingAddress' || address.locationType === 'ShippingAddress') {
        props.fetchAddresses({
          customerId: address.customerId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: address.locationType
        });
      } else {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId
        });
      }
    }
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }

  handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <FormattedMessage id="addItem.text.project" defaultMessage="Project" />
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewEmployeeAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.employee" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EMPLOYEE,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };

    switch (modalType) {
      case MODAL_TYPE.DEPARTMENT: {
        addFunc = props.addDepartment;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType =
          CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST];
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = (
          <FormattedMessage id="addItem.text.department" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = (
          <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.DEPARTMENT: {
        deleteFunc = props.deleteDepartment;
        break;
      }
      case MODAL_TYPE.EMPLOYEE: {
        deleteFunc = props.deleteEmployee;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        deleteFunc = props.deleteProject;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
        props.hideModal();
        if (
          modalType === MODAL_TYPE.DOCUMENT_NAME &&
          payload.id === this.props.documentNameId
        ) {
          const selectedObj =
            find(this.props.documentList || [], {
              txType:
                CONSTANTS_TRANSACTIONS_KEY[
                  CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST
                ],
              isDefault: 1,
            }) || {};
          this.props.updateState({
            documentNameId: selectedObj.documentNameId,
            docName: selectedObj.docName,
          });
        }
      },
    };
    showModal(modalData);
  };

  render() {
    const { intl } = this.props;
  
    const getAddressInfoFragment = () => {
      let adress = this.props.companyInfo.boLocationList?.filter((obj)=>{
        return(obj.locationType === CONSTANTS.RELATIONSHIP_BILLING_ADDRESS && obj.isDefault === 1 )
      })
     
      const fragmentObj = <Fragment>
        
          {
              adress[0].streetAddress1
                  ? <div className='billing-address'>{adress[0].streetAddress1}
                  </div>
                  : ''
          }
          {
              adress[0].streetAddress2
                  ? <div className='billing-address'>{adress[0].streetAddress2}
                  </div>
                  : ''
          }
          {
              adress[0].stateName
                  ? <div className='billing-address'>
                      {
                          `${adress[0].cityName || ''}${adress[0].stateName ? ', ' + adress[0].stateName : ''}${adress[0].zipCode ? ', ' + adress[0].zipCode : ''}`
                      }
                  </div>
                  : ''
          }
  
      </Fragment>
  
      return fragmentObj;
  }
    return (
      <Fragment>
        <Row>
          <Col span={5}>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className='details-heading-subheading'>

                <FormattedMessage id="sales.order.create.organization.name" defaultMessage=''/>
                </div>
                </Col>
                <Col span={24}>
               <div className='deatils-field-value'>

                {this.props.companyInfo.storeName}
               </div>
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">Address</div>
              </Col>

              <Col span={24}>
              <div style={{height:'96px',paddingTop:'10px'}}>
              {getAddressInfoFragment()}
                  
                </div>
               
              
              </Col>

              {this.props.customerId ? (
                <div className="date-picker-container">
                  <>
                    <Col span={24}>
                      <div className="details-heading-subheading">
                        <FormattedMessage
                          id="supplier.rfq.form.customer.label"
                          defaultMessage=""
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      <span className="deatils-field-value">
                        {this.props.customerName || ""}
                      </span>
                    </Col>
                  </>
                </div>
              ) : (
                ""
              )}
 <Col span={24}>
                <div className='details-heading-subheading'>

                <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                <Tooltip placement="right" title={this.props.intl.formatMessage(
                  {
                    id: 'tooltip.taIdentification',
                    defaultMessage: ''
                  }
                )} trigger="click">
                  <QuestionCircleTwoTone />
                </Tooltip>
                </div>
                
                <MultipleSelectDropdown
                  items={this.props.allRelationshipTaxIdentificatins || []}
                  placeholder="Tax Identification(s)"
                  mode='multiple'
                
                  onDeSelect={(value) => {
                    let { selectedRelationshipTaxIdentifications } = this.props
                    selectedRelationshipTaxIdentifications = filter(selectedRelationshipTaxIdentifications, (taxId) => {
                      return taxId !== value;
                    });
                    this.props.updateState({ selectedRelationshipTaxIdentifications });
                  }}
                  valueKeyName='taxIdentificationType'
                  valueKeyName2='taxIdentificationNumber'
                  selectedValue={(this.props.allRelationshipTaxIdentificatins || []).length ? (this.props.selectedRelationshipTaxIdentifications || []) : []}
                  optionKeyName='relationshipTaxIdentificationId'
                  onSelect={(relationshipTaxIdentificationId) => {
                    let selectedRelationshipTaxIdentifications = this.props.selectedRelationshipTaxIdentifications || [];
                    if (selectedRelationshipTaxIdentifications.indexOf(relationshipTaxIdentificationId) === -1) {
                      selectedRelationshipTaxIdentifications.push(relationshipTaxIdentificationId);
                    } else {
                      selectedRelationshipTaxIdentifications = selectedRelationshipTaxIdentifications.filter((el) => {
                        return el !== selectedRelationshipTaxIdentifications
                      });
                    }
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications
                    });
                  }}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION, payload);
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      taxIdentificationNumber: payload.textEntered,
                      submittedOnce: false,
                    }
                    this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                  }}
                />

              </Col>
              {/* <Row>
                                        <Col span={10} className='detail-column'>
                                            <div >
                                                <FormattedMessage id='supplier.purchaseRequest.form.department.label' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={14}>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                items={this.props.departments || []}
                                                valueKeyName='departmentName'
                                                optionKeyName='departmentId'
                                                placeholder='Select Department'
                                                value={this.props.department}
                                                canAddNew={true}
                                                canDelete={true}
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({ department: undefined })
                                                }}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.DEPARTMENT, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        textBoxValue: payload.textEntered,
                                                        submittedOnce: false
                                                    }
                                                    this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DEPARTMENT)
                                                }}
                                                onSelect={(department) => {
                                                    const selectedDepartMent = find(this.props.departments, {departmentId: Number(department)})
                                                    this.props.updateState({
                                                        department: selectedDepartMent.departmentName
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row> */}

              {/* <div className='date-picker-container'>
                                    <Row>
                                        <Col span={10} className='detail-column'>
                                            <div >
                                                <FormattedMessage id='common.status' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={14}>
                                            <span>
                                                Open
                                            </span>
                                        </Col>
                                    </Row>
                                </div> */}

              {/* <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.reason.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "reason.placeholder",
                    defaultMessage: "",
                  })}
                  type="text"
                  value={this.props.reason}
                  onChange={(e) => {
                    this.props.updateState({
                      reason: e.target.value,
                    });
                  }}
                />
              </Col> */}
            </Row>
          </Col>

          <Col span={1}></Col>

          <Col span={5}>
            <Row gutter={[0,3]}>
              <Col span={24}>
              <Col span={24}>
                <div className="details-heading">Requestor Information</div>
              </Col>
              </Col>
            <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.requestor.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={14}>
                <div className="deatils-field-value">
                  {this.props.userInfo.firstName || ""}{" "}
                  {this.props.userInfo.middleName || ""}{" "}
                  {this.props.userInfo.lastName || ""}
                </div>
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  Address
                </div>
              </Col>

              <Col span={24}>
                <span>{
                  !this.props.addressHide && this.props.relationshipBillingAddress ?
                  <>
                  
                  <div className='detail-address-value'>

                     <div style={{height:'96px',paddingTop:'10px'}}>
                      {

                  getAddressInfoFragment2 (this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo,this.props)
                      }
                     </div>
                     <div onClick={()=>{
                        
                        this.props.updateState({
                          addressHide: true,
                        })
                      }}>
                        <EditOutlined />
                     </div>
                  </div>
                  </> 
                  : 
                  <Dropdown
                  style={{ width: "100%" }}
                  value={
                    (this.props.relationshipBillingAddress || {})
                      .streetAddress1
                  }
                  items={this.props.allRelationshipBillingAddress || []}
                  valueKeyName="streetAddress1"
                  addressLineKey1="streetAddress2"
                  addressLineKey2="cityName"
                  addressLineKey3="stateName"
                  addressLineKey4="zipCode"
                  optionClassAddress="custom-address-dropdown"
                  optionKeyName="locationId"
                  isFavouriteKeyName="isDefault"
                  canAddNew={true}
                  canDelete={true}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      relationshipBillingAddress: undefined,
                    });
                  }}
                  //permissions={this.props.permissions}
                  //moduleName={MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS}
                  //operations={[PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE]}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                    };
                    this.handleNewAddressAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                    );
                  }}
                  onSelect={(locationId) => {
                    this.props.updateState({
                      relationshipBillingAddress: (
                        this.props.allRelationshipBillingAddress || []
                      ).find((x) => x.locationId === locationId),
                      addressHide: false,
                    });
                  }}
                />
                  }
                 
                </span>

              </Col>
            </Row>
         
          </Col>

          <Col span={1} />
          <Col span={5}>
          <Row gutter={[0, 3]}>
              <Col span={24}>
                <div className="details-heading">Suppliyer information</div>
              </Col>
              
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.owner.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={this.props.employees}
                  valueKeyName="fullName"
                  placeholder="Select Owner"
                  value={this.props.owner}
                  optionKeyName="relationshipEmployeeId"
                  canAddNew={true}
                  canDelete={true}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({ owner: undefined });
                  }}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.EMPLOYEE,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    // payload.formData = {
                    //     firstName: payload.textEntered,
                    //     submittedOnce: false
                    // }
                    // this.handleNewEmployeeAddition(this.props, payload, MODAL_TYPE.EMPLOYEE)
                    this.props.updateHeaderState({
                      company: {
                        ...this.props.company,
                        employeeDrawerVisible: true,
                      },
                    });
                  }}
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.employees || [], {
                      relationshipEmployeeId: Number(selectedValue),
                    });
                    this.props.updateState({
                      owner: selectedObj.fullName,
                    });
                  }}
                />
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.priority.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={CONSTANTS.PRIORITIES}
                  value={this.props.priority}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({ priority: undefined });
                  }}
                  placeholder="Select Priority"
                  onSelect={(priority) => {
                    this.props.updateState({
                      priority,
                    });
                  }}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.refNumber.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <TextBox
                  placeholder={intl.formatMessage({
                    id: "supplier.purchaseRequest.form.refNumber.placeholder",
                    defaultMessage: "",
                  })}
                  maxLength={20}
                  type="text"
                 countStyle={ {right: '0px', top: '1px',height:'32px',backgroundColor:"#f3f3f3",display:'flex',alignItems:'center',padding:'4px',}}
                  value={this.props.reference}
                  onChange={(e) => {
                    this.props.updateState({
                      reference: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        <Col span={1}></Col>
          <Col span={6} className='order-value-info'>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Order Information
              </Col>

              {/* <Col span={24} >
                        <div className="details-heading-subheading">

                    <FormattedMessage
                            id="supplier.purchaseRequest.form.purchaseRequestNumber.label"
                            defaultMessage=""
                          />
                      <Tooltip
                        placement="right"
                        title={
                          <FormattedMessage
                            id="system.generatedPurchase.RequestNumber"
                            defaultMessage=""
                          />
                        }trigger="click"
                      >
                        <QuestionCircleTwoTone />
                      </Tooltip>
                        </div>
                    </Col>
                    <Col span={24}>
                      {this.props.nextTxNumber ? (
                        <span>{this.props.nextTxNumber}</span>
                      ) : (
                        <img
                          src={LoadingIcon}
                          alt="Invoice"
                          style={{
                            height: "74px",
                            "margin-top": "-25px",
                            "margin-bottom": "-25px",
                          }}
                        />
                      )}
                    </Col> */}

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.purchaseRequestDate.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  key={`${this.props.purchaseRequestDate}`}
                  defaultValue={
                    this.props.purchaseRequestDate
                      ? moment(new Date(this.props.purchaseRequestDate), format)
                      : moment(new Date(), format)
                  }
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      purchaseRequestDate: selectedDate,
                    });
                    // this.props.getNextTxNumber({date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                  }}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.expirationDate.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  defaultValue={
                    this.props.prExpirationDate
                      ? moment(new Date(this.props.prExpirationDate), format)
                      : moment(new Date(), format)
                  }
                  key={`${this.props.prExpirationDate}`}
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      prExpirationDate: selectedDate,
                    });
                  }}
                />
              </Col>

              <Col span={24}>
                                <div className='className="details-heading-subheading'>
                                    Currency
                                </div>
                            </Col>
                            <Col span={24} >
                                {this.props.currencyCode ? <span className="currency-icon" style={{position:'relative',top:'1px',height:'33px'}}>{this.props.currencyIcon ? <i className={this.props.currencyIcon}></i> : (this.props.currencyCode || "-") + " "}</span> : <span className="currency-icon" style={{position:'relative',top:'1px',height:'33px'}}>-</span>}
                                <Dropdown
                                        items={filter((this.props.countries || []), (country) => { return country.countryName !== (this.props.companyInfo || {}).countryName; })}
                                    placeholder={this.props.intl.formatMessage(
                                        {
                                            id: 'common.currency.placeholder',
                                            defaultMessage: ''
                                        })
                                    }
                                    style={{ width: "80%"}}
                                    value={this.props.currencyCode && this.props.currencyCode + " "}
                                    onSelect={(selectedValue) => {
                                        const selectedCountry = find(this.props.countries, { currencyCode: selectedValue });
                                        this.props.updateState({
                                            currencyCode: selectedValue,
                                            currencyIcon: selectedCountry.currencyIcon
                                        })
                                    }}
                                    valueKeyName='currencyCode'
                                    valueKeyName2='countryName'
                                    optionKeyName='currencyCode'
                                    allowClear={true}
                                    onClear={() => {
                                        this.props.updateState({
                                            currencyIcon: undefined,
                                            currencyCode: undefined
                                        })
                                    }}
                                />
                            </Col>


                            <Col span={24}>
                                <span >
                                    Exchange Rate
                                </span>
                            </Col>
                            <Col span={24}>
                                <span className="currency-icon" style={{position:'relative',top:'-1px',height:'33px'}}> {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon} /> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                <Input
                                    type='number'
                                    style={{ width: "80%" }}
                                    placeholder="0.00"
                                    value={this.props.exchangeRate || ""}
                                    onChange={(e) => {
                                        this.props.updateState({
                                            exchangeRate: e.target.value
                                        });
                                    }}
                                />
                            </Col>

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesOrder.form.documentDropdown.label"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={
                      <FormattedMessage
                        id="documentTitle.tooltip"
                        defaultMessage=""
                      />
                    }
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={filter(this.props.documentList || [], (document) => {
                    return (
                      document.txType ===
                      CONSTANTS_TRANSACTIONS_KEY[
                        CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST
                      ]
                    );
                  })}
                  placeholder="Document name"
                  value={this.props.docName}
                  canAddNew={true}
                  canDelete={true}
                  // allowClear={true}
                  // onClear={() => {
                  //     this.props.updateState({ documentNameId: undefined, docName: undefined })
                  // }}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.DOCUMENT_NAME,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                      maxLength: 30,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.DOCUMENT_NAME
                    );
                  }}
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.documentList || [], {
                      documentNameId: Number(selectedValue),
                    });
                    this.props.updateState({
                      documentNameId: selectedValue,
                      docName: selectedObj.docName,
                    });
                  }}
                  valueKeyName="docName"
                  optionKeyName="documentNameId"
                  isLockKeyName="isSystemGenerated"
                />
              </Col>

              {this.props.customerInquiryNumber &&
              this.props.customerInquiryNumber !== "0" ? (
                <>
                  <Col span={24}>
                    <div className="detail-column">
                      <span className="tooltip-title">
                        <FormattedMessage
                          id="modal.txDetails.inquiry.customerInquiryNumber"
                          defaultMessage=""
                        />
                      </span>
                    </div>
                  </Col>
                  <Col span={24}>{this.props.customerInquiryNumber}</Col>
                </>
              ) : null}

              {this.props.customerRFQNumber &&
              this.props.customerRFQNumber !== "0" ? (
                <>
                  <Col span={24}>
                    <div className="detail-column">
                      <span className="tooltip-title">
                        <FormattedMessage
                          id="customerRfqNumber.text"
                          defaultMessage=""
                        />
                      </span>
                    </div>
                  </Col>
                  <Col span={24}>{this.props.customerRFQNumber}</Col>
                </>
              ) : null}

              {this.props.customerPONumber ? (
                <>
                  <Col span={24}>
                    <div className="detail-column">
                      <span className="tooltip-title">
                        <FormattedMessage
                          id="customerPONumber.text"
                          defaultMessage=""
                        />
                      </span>
                    </div>
                  </Col>
                  <Col span={24}>{this.props.customerPONumber}</Col>
                </>
              ) : null}

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseRequest.form.projectDropdown.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={this.props.projectList || []}
                  valueKeyName={"projectName"}
                  value={this.props.projectName}
                  optionKeyName={"projectMasterId"}
                  projectIdKey={"projectMasterId"}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.PROJECT,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      projectName: payload.projectName,
                    };
                    this.handleNewProjectAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.PROJECT
                    );
                  }}
                  onSelect={(selectedValue, option) => {
                    const selectedProject =
                      find(this.props.projectList, {
                        projectMasterId: Number(option.key),
                      }) || {};
                    this.props.updateState({
                      projectMasterId: selectedProject.projectMasterId,
                      projectName: selectedProject.projectName,
                      projectNumber: selectedProject.projectNumber,
                    });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.updateState({
                      projectMasterId: undefined,
                      projectName: undefined,
                      projectNumber: undefined,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const getAddressInfoFragment2 = (obj, customerContactObj, customerObj,props) => {
  // if (!obj || !Object.keys(obj).length) {
  //     return '';
  // }


  let contactName = "";
  if (customerContactObj.salutationName) contactName += customerContactObj.salutationName + ' ';
  if (customerContactObj.firstName) contactName += customerContactObj.firstName;
  if (customerContactObj.middleName) contactName += ' ' + customerContactObj.middleName;
  if (customerContactObj.lastName) contactName += ' ' + customerContactObj.lastName;
  const fragmentObj = <Fragment>
    {
      obj.streetAddress1
        ? <div className='billing-address'>{obj.streetAddress1}
        </div>
        : ''
    }
    {
      obj.streetAddress2
        ? <div className='billing-address'>{obj.streetAddress2}
        </div>
        : ''
    }
    {
      obj.cityName
        ? <div className='billing-address'>
          {
            `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
          }
        </div>
        : ''
    }
    {
      customerContactObj.firstName
        ? <div className='billing-address'>
          {contactName}
        </div> :
        ''
    }
    {
      customerContactObj.workPhone
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.phone.label' defaultMessage='' /> - {customerContactObj.workPhone}
        </div> :
        ''
    }
    {
      customerContactObj.email
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.email.label' defaultMessage='' /> - {customerContactObj.email}
        </div> :
        ''
    }
    {/* {
      customerObj.gstNumber
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.gstIn.label' defaultMessage='' /> - {customerObj.gstNumber}
        </div> :
        ''
    }
    {
      customerObj.panNumber
        ? <div className='billing-address'>
          <FormattedMessage id='customer.salesOrder.form.pan.label' defaultMessage='' /> - {customerObj.panNumber}
        </div> :
        ''
    } */}

  </Fragment>

  return fragmentObj;
}
export default injectIntl(PurchaseRequestDetails);
