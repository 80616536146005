import React from 'react';
import './../../../../assets/stylesheets/common/invoice.scss';

//import HeaderAction from './Header';
import RFQDetails from "./RFQDetails";
import HeaderAction from "./HeaderAction";
import ProductDetails from "./ProductDetails";
import { Divider } from 'antd';
const PurchaseRequestComponent = (props) => {
  return (
    <>
      <div className="txn-header">
        <HeaderAction {...props} />
      </div>

      <div className='txn-body'>
        <div className="txn-details-container">
          <RFQDetails {...props} />
        </div>

        <Divider style={{margin: '0 0 10px 0px'}}/>

        <div className="product-details-container">
          <ProductDetails {...props} />
        </div>
      </div>
    </>
    
  );
}


export default PurchaseRequestComponent;