import React from "react";
import { FormattedMessage } from "react-intl";
import { Col, DatePicker, Drawer, Row,Skeleton } from "antd";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown"

const TaxRuleDrawer = (props) => {
  const closeDrawer = () => {
    props.updateState({
        taxRuleDrawer: false,
    });
  };

  return (
    <Drawer
    title="Create New"
    width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.taxRuleDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {
                
            }
            
            }
            key={1}
          />
        </>
      }
    >
        <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>
      
      <Row gutter={[16,16]}>
        <Col span={12}>
          <div className="i-label">Setup Tax Rule For
          <span className="required">*</span>

          </div>
          <Dropdown
          placeholder="Weekly"
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label">Amount
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Amount"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label">Tax Rate
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Enter Rate"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={12}>
          <div className="i-label">Taxable Amount
          <span className="required">*</span>
          
          </div>
          <TextBox
            placeholder="Enter Amount"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        
      </Row>
    </Skeleton>
    </Drawer>
  );
};

export default TaxRuleDrawer;
