import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, DatePicker, Radio, Switch } from 'antd';
import EmailBody from './EmailBody';
import { CustomButton } from '../../../general/CustomButton';
import { Dropdown } from '../../../general/Dropdown';
import { TextBox } from '../../../general/TextBox';
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from '../../../../static/ckEditorConfigConstants';
import { getCurrentDateForBackend, showToasterMessage } from '../../../../utils';
import { CONSTANTS } from '../../../../static/constants';

function BroadcastMessage(props) {

  const closeDrawer = () => {
    props.updateState({
      broadcastMessageDrawerVisible: false,
      isSendEmail: 0
    })
  }
  // const handleSubmit = e => {
  //   e.preventDefault();
  //   props.form.validateFieldsAndScroll((err, values) => {
  //     if (!err) {
  //       const payload = {
  //         ...(this.props.emailPayloadProps || {}),
  //         sender: values.sender,
  //         to: Array.isArray(values.receivers) ? values.receivers : [values.receivers],
  //         // cc: Array.isArray(values.ccRecievers) ? values.ccRecievers: [values.ccRecievers],
  //         // bcc: Array.isArray(values.bccRecievers) ? values.bccRecievers: [values.bccRecievers],
  //         subject: values.subject,
  //         message: props.body,
  //         sendMeACopy: values.sendMeACopy ? 1 : 0
  //       };
  //       if (values.ccRecievers) {
  //         payload.cc = Array.isArray(values.ccRecievers) ? values.ccRecievers : [values.ccRecievers];
  //       }
  //       if (values.bccRecievers) {
  //         payload.bcc = Array.isArray(values.bccRecievers) ? values.bccRecievers : [values.bccRecievers];
  //       }
  //       props.updateDrawerState({ emailDetails: JSON.stringify(payload), emailDetailDrawerVisible: false });
  //     }
  //   });
  // };

  return (
    <Drawer
      title={
        <>
          <div className="drawer-head-txt">
            <h3><FormattedMessage id='notice.drawer' defaultMessage='' /></h3>
          </div>
          <div style={{ fontSize: 14, textAlign: 'left' }}><FormattedMessage id='broadcastMessage.subheading.text' defaultMessage='' /></div>
        </>
      }
      className="custom-drawer"
      keyboard={false}
      width={720}
      maskClosable={false}
      zIndex={99999}
      destroyOnClose={true}
      onClose={closeDrawer}
      visible={props.broadcastMessageDrawerVisible}
      afterVisibleChange={
        (visible) => {
          if (visible) {
            // props.initCalls();
          }
        }
      }
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {

              if (!props.subject) {
                showToasterMessage({
                  messageType: "error",
                  description: "Subject is required.",
                });
                return;
              }
              if (!props.body) {
                showToasterMessage({
                  messageType: "error",
                  description: "Message is required.",
                });
                return;
              }
              const payload = {
                dgName: props.dgName,
                distributionGroupId:props.distributionGroupId,
                subject: props.subject,
                message: props.body,
                broadcastMessageId: props.broadcastMessageId,
                relationshipId: (props.companyInfo || {})
                  .relationshipId,
                createdByEmpId: (props.userInfo || {})
                  .relationshipEmployeeId,
                employeeName:
                  ((props.userInfo || {}).firstName || "") +
                  " " +
                  ((props.userInfo || {}).lastName || ""),
                startDate:  getCurrentDateForBackend(
                  props.proceedNow ? new Date(): props.startDate  
                ),
                endDate: getCurrentDateForBackend(
                  props.endDate || new Date()
                ),
              };
              let formData = new FormData();
              formData.append("payload", JSON.stringify(payload));
              // if (
              //   props.attachments &&
              //   Array.isArray(state.attachments.fileList) &&
              //   props.attachments.fileList.length
              // ) {
              //   props.attachments.fileList.forEach((fileObj) => {
              //     const file = new Blob([fileObj.originFileObj], {
              //       type: fileObj.type,
              //     });
              //     formData.append("files", file, fileObj.name);
              //   });
              // }
/////////////////////////////////////////////////////////////////////// 
              props.createBroadcastMessage(
                formData,
                props,
              );
            }}
            key={1}
          />
        </>
      }
    >
      {/* <Form layout="vertical"
        className="email-form-group"          
        onSubmit={handleSubmit}
      > */}
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <label>
            Distribution Group
          </label>
          <span className="required">*</span>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>

          <Dropdown
            items={props.dgList || []}
            valueKeyName="dgName"
            optionKeyName="distributionGroupId"
            value={props.dgName}
            onSelect={(optionVal, obj) => {
              props.updateDrawerState({
                distributionGroupId: optionVal,
                dgName: obj.name
              })
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <label>
            Title
          </label>
          <span className="required">*</span>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>

          <TextBox
            value={props.subject}
            onChange={(e) => {
              props.updateDrawerState({
                subject: e.target.value
              })
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <label>
            Text
          </label>
          <span className="required">*</span>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>

          <CKEditor
            type="replace"
            className="description-input"
            key={`${"emailBody"}`}
            data={props.body}
            editor={props.editor}
            onInit={(editor) => {
              props.updateDrawerState({
                editor,
              });
            }}
            onChange={(e) => {
              const data = e.editor.getData();
              props.updateDrawerState({
                body: data,
              });
            }}
            config={CUSTOM_CONFIGURATION}
          />
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <label>
            Schedule For Now
          </label>
          <span className="required">*</span>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>

          <Switch
            onChange={(event) => {
              props.updateDrawerState({
                proceedNow: event
              })
            }}
            checked={props.proceedNow}

          />
        </Col>
      </Row>
      {!props.proceedNow ?
        <>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <label>
                Broadcast Schedule
              </label>
              <span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <DatePicker
                allowClear={false}
                // format={format}
                // key="startDate"
                showTime
                value={props.startDate}
                onChange={(selectedDate) => {
                  props.updateDrawerState({
                    startDate: selectedDate,
                  });
                }}
              />
            </Col>
          </Row>
        </>
        : ""}
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <label>
            Expiry Time
          </label>
          <span className="required">*</span>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <DatePicker
            value={props.endDate}
            onChange={(selectedDate) => {
              
              props.updateDrawerState({
                endDate: selectedDate,
              });
            }}
            showTime
          />

        </Col>
      </Row>

    </Drawer>
  );
}

export default injectIntl(BroadcastMessage);


