import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';

import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import MultiCurrencySalesQuote from '../../../../components/customer/salesQuote/MultiCurrencySalesQuote';
import {
    fetchProducts, fetchTaxes, fetchUOM, addUOM, deleteUOM, showModal, hideModal, pushModalToStack, popModalFromStack, addPaymentTerm, deletePaymentTerm, deleteAddress, deleteTax, fetchDocuments, addDocumentName, deleteDocumentName, deleteContact, fetchProjects, deleteProject, fetchCountries, fetchUserPreferences, saveUserPrefrences,
    fetchAllEmployees, deleteEmployee, fetchRelationshipTaxIdentifications, deleteCustomerTaxIdentifications, deleteRelationshipTaxIdentifications, fetchIncoterms, addIncoterm, deleteIncoterm,
    fetchCustomerDetailsByCustomerId, resetCustomerDetail, fetchAddresses,
    getRelationshipAddress, resetAddresses
} from './../../../../actions/commonActions';
import { fetchAllFreightType, addFreightType, deleteFreightType, } from '../../salesOrder/shipment/action';
import { fetchCustomers } from '../../Listing/action';
import { createSalesQuote, fetchSQDetail, resetSQDetails, fetchRfqData, resetRfqPoData, getNextTxNumber, createSalesQuoteDraft } from './action';
import { CONSTANTS, LEDGER_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY } from '../../../../static/constants';
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import SQDetail from '../../../modal/modalBody/customer/SQDetail';
import { deleteCustomers } from '../../Listing/action';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import { fetchAllContacts } from '../../Listing/ShowConact/action';

class SalesQuote extends Component {

    constructor(props) {
        super(props);
        const companyInfo = props.companyInfo;
        const allRelationshipBillingAddress = filter(companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });

        const linkProps = (props.location && props.location.state) || {};
        this.state = {
            customer: linkProps.customer,
            customerName: (linkProps.customer || {}).companyName,
            pageSize: 100,
            pageNumber: 1,
            searchedText: '',
            quoteDate: new Date(),
            quoteExpiryDate: new Date(),
            columnPreferences: {
                description: false,
                uom: false,
                hsn: false,
                specialDiscount: false,
                discount: false,
                tax: false
            },
            relationshipBillingAddress,
            allRelationshipBillingAddress,
            priceTypeToApply: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0],
            inheritedTxColumn: [],
            forceHideTxColumn: [],
            txPreferences: { txType: "salesQuote", templateName: "multicurrency" },
            txColumns: [],
            txColumnSetting: {},
            txMandatoryColumns: [
                GRID_PREFERENCE_CONSTANTS.PRODUCT,
                GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
                GRID_PREFERENCE_CONSTANTS.QUANTITY,
                GRID_PREFERENCE_CONSTANTS.RATE,
                GRID_PREFERENCE_CONSTANTS.AMOUNT,
            ],
            txAvailableColumn: {
                product: true,
                quantity: true,
                rate: true,
                amount: true,
                description: true,
                uom: true,
                hsn: (props.companyInfo || {}).countryName === 'India',
                basePrice: true,
                specialDiscount: true,
                discount: true,
                comment: true,
                stocknumber: true,
                partNumber: true,
                origin: true,
                hsCode: true,
            },
        };
    }

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true
        })
        const companyInfo = this.props.companyInfo || {};
        const linkProps = (this.props.location && this.props.location.state) || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            salesQuotationMasterId: linkProps.salesQuotationMasterId,
            rfqMasterId: linkProps.rfqMasterId,
            userId: (this.props.userInfo || {}).userId,
            customerId: linkProps.customerId,
            pageSize: 100
        };
        this.props.resetSQDetails();
        this.props.resetRfqPoData();
        fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
        payload.customerId && fetchDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerDetail', this.props, payload, true);
        payload.rfqMasterId && fetchDataIfNeeded('fetchRfqData', 'rfqPoData', this.props, payload, true);
        payload.salesQuotationMasterId && fetchDataIfNeeded('fetchSQDetail', 'sqDetail', this.props, payload, true);
        fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
        fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
        fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
        fetchDataIfNeeded('fetchDocuments', 'documentList', this.props, payload);
        if (!linkProps.update) {
            fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
        }
        fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
        fetchDataIfNeeded('fetchCountries', 'countries', this.props, payload);
        fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
        fetchDataIfNeeded('fetchAllEmployees', 'employees', this.props, payload);
        this.props.fetchRemarksTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Sales Quotation' });
        fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
        fetchDataIfNeeded('fetchIncoterms', 'incoterms', this.props, payload);
        fetchDataIfNeeded('fetchAllFreightType', 'carrierList', this.props, payload);
    }

    openSQDetails = (data) => {
        const sqPayload = {
            customerId: data.customerId,
            salesQuotationMasterId: data.salesQuotationMasterId
        }
        this.props.pushModalToStack({
            modalBody: <SQDetail {...this.props} sqPayload={sqPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    onSearch(text, updateAllOptions, updateFetching) {
        const payload = {
            relationshipId: (this.props || {}).companyInfo.relationshipId,
            pageNumber: 1,
            searchString: text,
            pageSize: 100,
            updateAllOptions,
            updateFetching
        };
        this.props.fetchProducts(payload)
    }

    componentWillReceiveProps(props) {

        if (props.customerDetail && props.customerDetail.customerId) {
            const customer = props.customerDetail;
            const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
            const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
            const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
            const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
            const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
            const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
            let selectedCustomerTaxIdentifications = selectedList.map(obj => {
                return obj.customerTaxIdentificationId;
            })
            this.setState({
                customer,
                customerName: customer.companyName,
                customerBillingAddress: defaultBillingAddress,
                customerShippingAddress: defaultShippingAddress,
                placeOfSupply: defaultShippingAddress.stateName || defaultBillingAddress.stateName,
                selectedContact,
                selectedCustomerTaxIdentifications
            });
            props.resetCustomerDetail();
        }

        if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
            const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
            let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
                return obj.relationshipTaxIdentificationId;
            })
            this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
        }

        if (props.documentList && props.documentList.length && !this.state.docName) {
            props.documentList.forEach((document) => {

                if (document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_QUOTE] && document.isDefault) {
                    this.setState({
                        documentNameId: document.documentNameId,
                        docName: document.docName
                    })
                }
            })

        }
        if (!this.state.remarksCustomer && props.remarksData && props.remarksData.isFetched && !this.state.isRemarksSet) {
            this.setState({ remarksCustomer: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
            setTimeout(() => {
                props.resetRemarksData();
            }, 500)
        }
        const linkProps = (this.props.location && this.props.location.state) || {};
        if ((props.sqDetail || {}).salesQuotationMasterId) {
            const detailList = [];
            props.sqDetail.customerSalesQuotationDetailsList.map((ele) => {
                const tax = (find(ele.customerSalesQuotationCOATxList, (ele) => {
                    return ele.txType === LEDGER_TYPE.TYPE_TAX_IGST || ele.txType === LEDGER_TYPE.TYPE_TAX_CGST || ele.txType === LEDGER_TYPE.TYPE_TAX_SGST
                }) || {});

                return detailList.push({
                    version: ele.version,
                    selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
                    product: ele.itemName ? ele.itemName + ((ele.itemVariantName && (" (" + ele.itemVariantName + ") ")) || "") : '',
                    productObj: { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku },
                    itemVariantName: ele.itemVariantName,
                    variant: {
                        attributeId1: ele.attributeId1,
                        attributeId2: ele.attributeId2,
                        attributeId3: ele.attributeId3,
                        attributeName1: ele.attributeName1,
                        attributeName2: ele.attributeName2,
                        attributeName3: ele.attributeName3,
                        attributeValue1: ele.attributeValue1,
                        attributeValue2: ele.attributeValue2,
                        attributeValue3: ele.attributeValue3
                    },
                    description: ele.description,
                    scrollHeight: ele.rowHeight,
                    qty: ele.quantity,
                    qtyBeforeUpdate: ele.quantity,
                    discount: (find(ele.customerSalesQuotationCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
                    tax: (tax.amountPercent || 0) * (tax.txType === LEDGER_TYPE.TYPE_TAX_IGST || tax.txType === LEDGER_TYPE.TYPE_TAX_VAT ? 1 : 2),
                    rate: ele.anItemSalePrice,
                    baseRate: ele.basePrice || ele.anItemSalePrice,
                    uom: ele.uomName,
                    hsnCode: ele.hsnCode,
                    specialDiscount: ele.basePrice && ele.specialDiscount,
                    salesQuotationDdetailsId: linkProps.update ? ele.salesQuotationDdetailsId : 0,
                    salesQuotationMasterId: linkProps.update ? ele.salesQuotationMasterId : 0,
                    customerId: ele.customerId, 
                    comment: ele.warehouse,
                    stockNumber: ele.stockNumber,
                    partNumber: ele.partNumber,
                    origin: ele.origin,
                    hsCode: ele.hsCode,
                    materialNumber: ele.materialNumber,
                    parentDetailsId: linkProps.update ? ele.parentDetailsId : 0,
                    salesQuotationDetailsId: linkProps.update ? ele.salesQuotationDetailsId : 0
                })
            });

            this.setState({
                update: linkProps.update,
                version: props.sqDetail.version,
                currencyCode: props.sqDetail.foreignCurrency,
                currencyIcon: props.sqDetail.foreignCurrencyIcon,
                exchangeRate: props.sqDetail.exchangeRate,
                salesQuotationMasterId: linkProps.update ? props.sqDetail.salesQuotationMasterId : 0,
                quotationNumber: linkProps.update ? props.sqDetail.quotationNumber : '',
                customerBillingAddress: find(props.sqDetail.boLocationSQList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {},
                relationshipBillingAddress: find(props.sqDetail.boLocationSQList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {},
                priority: props.sqDetail.orderPriority,
                rfqNumber: props.sqDetail.rfqNumber,
                customerRef: props.sqDetail.referenceNumber,
                customerRfq: props.sqDetail.customerRFQNumber,
                rfqMasterId: props.sqDetail.rfqMasterId,
                quoteDate: new Date(props.sqDetail.salesQuoteDate),
                quoteExpiryDate: new Date(props.sqDetail.quoteExpiryDate),
                customerId: props.sqDetail.customerId,
                customerName: props.sqDetail.customerName,
               // customer: { customerId: props.sqDetail.customerId, companyName: props.sqDetail.customerName, customerGstNumber: props.sqDetail.customerGstNumber, customerPanNumber: props.sqDetail.customerPanNumber },
                internalRemarks: props.sqDetail.remarksInternal,
                customerRemarks: props.sqDetail.remarksCustomer,
                footer: props.sqDetail.footer,
                quotationAwsObjectKey: props.sqDetail.quotationAwsObjectKey,
                salesQuoteDetails: detailList,
                isInquiryConversion: linkProps.update ? (props.sqDetail.isInquiryConversion ? true : false) : false,
                columnPreferences: {
                    description: true
                },
                oldPayload: linkProps.update ? props.sqDetail : {}
            });
            if (linkProps.update) {
                this.setState({ nextTxNumber: props.sqDetail.quotationNumber });
            }
            props.resetSQDetails();
        }

        if (props.rfqPoData && props.rfqPoData.rfqMasterId && props.customers[this.state.pageNumber] && props.customers[this.state.pageNumber].length) {
            const detailList = [];
            let customer = { customerId: props.rfqPoData.customerId, customerName: props.rfqPoData.customerName };
            let allBillingAddress = [];
            const defaultBillingAddress = find((props.rfqPoData.boLocationRFQList || []), { locationType: CONSTANTS.BILLING_ADDRESS }) || {}
            if (props.customers[this.state.pageNumber] && props.customers[this.state.pageNumber].length) {
                customer = find(props.customers[this.state.pageNumber], { customerId: Number(props.rfqPoData.customerId || 0) }) || {};
                allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
            }
            const inheritedTxColumn = [];
            const _emptyColumnCheck = (item) => {
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
                }
                // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
                //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
                // }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
                }
            }
            props.rfqPoData.rfqDetailsList.map((ele) => {
                _emptyColumnCheck(ele);
                return detailList.push({
                    selectedProductValue: [ele.itemId, ele.itemVariantId],
                    product: ele.itemName ? ele.itemName + ((ele.itemVariantName && (" (" + ele.itemVariantName + ") ")) || "") : '',
                    productObj: { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku },
                    itemVariantName: ele.itemVariantName,
                    variant: {
                        attributeId1: ele.attributeId1,
                        attributeId2: ele.attributeId2,
                        attributeId3: ele.attributeId3,
                        attributeName1: ele.attributeName1,
                        attributeName2: ele.attributeName2,
                        attributeName3: ele.attributeName3,
                        attributeValue1: ele.attributeValue1,
                        attributeValue2: ele.attributeValue2,
                        attributeValue3: ele.attributeValue3
                    },
                    description: ele.description,
                    qty: ele.quantity,
                    rate: ele.anItemSalePrice,
                    baseRate: ele.basePrice,
                    uom: ele.uomName,
                    hsnCode: ele.hsnCode,
                    specialDiscount: ele.specialDiscount
                })
            });
            const forceHideTxColumn = [];
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
                forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
                forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
            }
            this.setState({
                isRFQConversion: true,
                customerId: props.rfqPoData.customerId,
                customer: customer,
                selectedContact: (props.rfqPoData.additionalInfoList || [])[0] || {},
                customerName: props.rfqPoData.customerName,
                priority: props.rfqPoData.orderPriority,
                rfqNumber: props.rfqPoData.rfqNumber,
                customerRef: props.rfqPoData.refNumber,
                customerRfq: props.rfqPoData.customerRFQNumber,
                rfqMasterId: props.rfqPoData.rfqMasterId,
                internalRemarks: props.rfqPoData.remarksInternal,
                customerRemarks: props.rfqPoData.remarksSupplier,
                footer: props.rfqPoData.footer,
                rfqDate: new Date(props.rfqPoData.rfqDate),
                rfqExpirationDate: props.rfqPoData.rfqExpirationDate && new Date(props.rfqPoData.rfqExpirationDate),
                allBillingAddress: allBillingAddress,
                customerBillingAddress: defaultBillingAddress,
                salesQuoteDetails: detailList,
                projectMasterId: props.rfqPoData.projectMasterId,
                projectName: props.rfqPoData.projectName,
                projectNumber: props.rfqPoData.projectNumber,
                inheritedTxColumn: inheritedTxColumn,
                forceHideTxColumn: forceHideTxColumn,
                tableDataReRendered: true,
            });

            props.resetRfqPoData()
        }

        if (props.customers[this.state.pageNumber] && props.customers[this.state.pageNumber].length && linkProps.customerId) {
            const customer = find(props.customers[this.state.pageNumber], { customerId: Number(linkProps.customerId || 0) }) || {};
            const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
            const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
            const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
            const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
            const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
            const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
            let selectedCustomerTaxIdentifications = selectedList.map(obj => {
                return obj.customerTaxIdentificationId;
            })
            this.setState({
                customer,
                customerName: customer.companyName,
                shippingAddress: defaultShippingAddress,
                billingAddress: defaultBillingAddress,
                placeOfSupply: defaultBillingAddress && defaultBillingAddress.stateName,
                selectedContact,
                selectedCustomerTaxIdentifications,
                boContactList: customer.boContactList || [],
            });
        }

        if (props.allPreferences && props.allPreferences.length) {
            let txPreferences = find(props.allPreferences, { txType: "salesQuote", templateName: "multicurrency" }) || {};
            if (txPreferences) {
                let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
                txColumns = txColumns.length ? txColumns : this.state.txMandatoryColumns;
                const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : [];
                const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
                let gridPreference = txPreferences.gridPreference;
                this.setState({
                    gridPreference,
                    isColumnFitTable,
                    txColumns,
                    txColumnSetting,
                    txPreferences
                });
            }
        }
        const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        this.setState({
            allRelationshipBillingAddress,
        });
        if (props.customerContactList && props.customerContactList.length) {
            this.setState({
              boContactList: props.customerContactList
            });
          }
    }

    render() {
        return (
            <div>
                <MultiCurrencySalesQuote onSearch={(text, updateAllOptions, updateFetching) => {
                    this.onSearch(text, updateAllOptions, updateFetching)
                }} {...this.props} {...this.state} openSQDetails={this.openSQDetails} updateState={(data) => { this.setState(data) }} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        customers: state.customer.customers,
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        customerDetail: state.common.customerDetail,
        products: state.salesInvoice.products,
        uomList: state.common.uomList,
        taxes: state.common.taxes,
        sqDetail: state.sq.sqDetail,
        rfqPoData: state.purchaseOrder.rfqPoData,
        documentList: state.common.documentList,
        nextTxNumber: state.sq.nextTxNumber,
        projectList: state.common.projectList,
        countries: state.common.countries,
        allPreferences: state.common.allPreferences,
        employees: state.common.employees,
        remarksData: state.settings.remarksData,
        permissions: state.auth.permissions,
        allRelationshipTaxIdentificatins: state.common.allRelationshipTaxIdentificatins,
        incoterms: state.common.incoterms,
        freightList: state.so.freightList,
        customerContactList: state.customer.contacts,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createSalesQuote,
    resetSQDetails,
    fetchCustomers,
    fetchSQDetail,
    fetchProducts,
    fetchTaxes,
    fetchCountries,
    fetchUOM,
    addUOM,
    deleteUOM,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    deleteCustomers,
    addPaymentTerm,
    deletePaymentTerm,
    deleteAddress,
    deleteTax,
    resetRfqPoData,
    fetchRfqData,
    fetchDocuments,
    addDocumentName,
    deleteDocumentName,
    getNextTxNumber,
    deleteContact,
    fetchProjects,
    deleteProject,
    resetRemarksData,
    fetchUserPreferences,
    saveUserPrefrences,
    fetchAllEmployees,
    deleteEmployee,
    fetchRemarksTemplate,
    fetchRelationshipTaxIdentifications,
    deleteCustomerTaxIdentifications, deleteRelationshipTaxIdentifications,
    createSalesQuoteDraft,
    fetchIncoterms,
    addIncoterm,
    deleteIncoterm,
    fetchAllFreightType,
    addFreightType,
    deleteFreightType,
    fetchCustomerDetailsByCustomerId, 
    resetCustomerDetail, 
    fetchAddresses,
    getRelationshipAddress, 
    resetAddresses,
    fetchAllContacts
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(SalesQuote);
