import axios from "axios";
import config from "../../config/environmentConfig";
import { showToasterMessage } from "../../utils";
import { COMMON_ACTIONS } from "../../static/constants";
const { lang } = require("../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const saveTemplate = (payload, props, cb) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/formBuilder/saveOrUpdate`,
        payload
      )
      .then((res) => {
        //showToasterMessage({ messageType: 'success', description: lang['common.saved.success'] });
        // payload.updateState({  isReplicate: true, alpideFormBuilderId : {} });
        if (cb) return cb(res.data);
        if (payload.templateType === "newsletter") {
          props.history.push("/admin/news-letter-listing");
        }
        if (payload.templateType === "event") {
          props.history.push("/admin/events");
        }
        if (payload.templateType === "fundraising") {
          props.history.push("/admin/fundraising-campaign");
        }
        props.getAllFormsBuilder(payload)
      })
      .catch((err) => {
        payload.updateState({  isReplicate: true, alpideFormBuilderId : {} });
        showToasterMessage({
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};

export const uploadImage = (payload, cb) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("fileAsByte", payload.file);
    formData.append("objectKey", payload.objectKey);
    formData.append("bucketName", payload.bucketName);
    formData.append("contentType", "multipart/form-data;");
    formData.append("contentLength", "1234");
    formData.append("relationshipId", payload.relationshipId);
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.AWS_BASE_URL}${config.API_VERSION}/alpide-aws/boBucketOperations/saveOrUpdate`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        cb && cb(res.data);
      })
      .catch((err) => {
        // cb && cb('1-a9d0319f-a7cb-48f7-a9c5-a72cc30f38d');
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};
