import React from "react";
import { Row, Col, DatePicker } from 'antd'
import { Dropdown } from "../../general/Dropdown";
import { CONSTANTS } from "../../../static/constants";
import { getMomentDateForUI } from "../../../utils/index";
import * as find from 'lodash.find';
import { FormattedMessage, injectIntl } from "react-intl";



const { RangePicker } = DatePicker;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const BusinessDetails = (props) => {
    
    return (
        <div className="step-details-box">
            <div className="heading">
                <FormattedMessage id='company.companySetup.industryType.title' defaultMessage='' />
                {/* <div className="modal-sub-header"> 
                    <FormattedMessage id='company.companySetup.businessDetail.optionalFields' defaultMessage='' />
                </div> */}
            </div>

            <div className="step-form-body">

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div className="i-label"><FormattedMessage id='industry.text' defaultMessage='' /><span className="required">*</span></div>
                        <div>
                            <Dropdown
                                items={props.industries}
                                valueKeyName='industryName'
                                optionKeyName='businessIndustryId'
                                value={(props.selectedIndustry || {}).industryName}
                                onSelect={(id)=>{
                                    const selectedIndustry = find(props.industries, { businessIndustryId: Number(id) });
                                    props.updateState({
                                        selectedIndustry
                                    })
                                }}
                                placeholder={props.intl.formatMessage(
                                    {
                                        id: 'industry.placeholder',
                                        defaultMessage: ''
                                    })
                                }
                            />
                        </div>
                    </Col>

                    <Col span={24}>
                        <div className="i-label"> <FormattedMessage id='company.companySetup.businessDetail.companySize' defaultMessage='' /></div>
                        <div>
                            <Dropdown
                                items={CONSTANTS.COMPANY_SIZE_LIST}
                                placeholder={props.intl.formatMessage(
                                    {
                                        id: 'company.companySetup.companySize.placeholder',
                                        defaultMessage: ''
                                    })
                                }
                                value= {props.companySize}
                                onSelect={(val)=>{
                                    props.updateState({
                                        companySize: val
                                    });
                                }}
                            />
                        </div>
                    </Col>

                    <Col span={24}>
                        <div className="i-label"> <FormattedMessage id='company.companySetup.businessDetail.financialYear' defaultMessage='' /><span className="required">*</span></div>
                        <div>
                            <RangePicker
                                allowEmpty={true}
                                format={format}
                                placeholder={['Start date', 'End date']}
                                value={[props.fyStartDate ? getMomentDateForUI({ date: new Date(props.fyStartDate), format }) : null, props.fyEndDate ? getMomentDateForUI({ date: new Date(props.fyEndDate), format }) : null]}
                                onChange={(dates) => {
                                    if (dates) {
                                        props.updateState({ fyStartDate: dates[0], fyEndDate: dates[1] });
                                    } else {
                                        props.updateState({ fyStartDate: null, fyEndDate: null });
                                    }
                                }}
                            />
                            
                        </div>
                    </Col>

                    <Col span={24}>
                        <div className="i-label"> <FormattedMessage id='howDidYouHear.text' defaultMessage='' /></div>
                        <div>
                            <Dropdown
                                items={CONSTANTS.SOURCE_OF_HEARING_LIST}
                                placeholder={props.intl.formatMessage(
                                    {
                                        id: 'howDidYouHear.paceholder',
                                        defaultMessage: ''
                                    })
                                }
                                value= {props.sourceOfListening}
                                onSelect={(val)=>{
                                    props.updateState({
                                        sourceOfListening: val
                                    });
                                }}
                            />
                        </div>
                    </Col>
                </Row>

{/* 
                <Row>
                    <Col span={4}>
                        <FormattedMessage id='company.companySetup.businessDetail.companySize' defaultMessage='' />
                    </Col>
                    <Col span={8}>
                        <Dropdown
                            items={CONSTANTS.COMPANY_SIZE_LIST}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'company.companySetup.companySize.placeholder',
                                    defaultMessage: ''
                                })
                            }
                            value= {props.companySize}
                            onSelect={(val)=>{
                                props.updateState({
                                    companySize: val
                                });
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                    <FormattedMessage id='industry.text' defaultMessage='' /> 
                  </Col>
                    <Col span={16}>
                        <Dropdown
                            items={props.industries}
                            valueKeyName='industryName'
                            optionKeyName='businessIndustryId'
                            value={(props.selectedIndustry || {}).industryName}
                            onSelect={(id)=>{
                                const selectedIndustry = find(props.industries, { businessIndustryId: Number(id) });
                                props.updateState({
                                    selectedIndustry
                                })
                            }}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'industry.placeholder',
                                    defaultMessage: ''
                                })
                            }
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                    <FormattedMessage id='inventoryItemQty.text' defaultMessage='' /> 
                  </Col>
                    <Col span={16}>
                        <Dropdown
                            items={CONSTANTS.INVENTORY_ITEM_TY_LIST}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'inventoryItemQty.placeholder',
                                    defaultMessage: ''
                                })
                            }
                            value= {props.inventoryItemQuantity}
                            onSelect={(val)=>{
                                props.updateState({
                                    inventoryItemQuantity: val
                                });
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                    <FormattedMessage id='averageMonthlyOrder.text' defaultMessage='' /> 
                  </Col>
                    <Col span={16}>
                        <Dropdown
                            items={CONSTANTS.AVERAGE_MONTHLY_ORDER_LIST}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'averageMonthlyOrder.placeholder',
                                    defaultMessage: ''
                                })
                            }
                            value= {props.averageMonthlyOrders}
                            onSelect={(val)=>{
                                props.updateState({
                                    averageMonthlyOrders: val
                                });
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                    <FormattedMessage id='howDidYouHear.text' defaultMessage='' /> 
                  </Col>
                    <Col span={16}>
                        <Dropdown
                            items={CONSTANTS.SOURCE_OF_HEARING_LIST}
                            placeholder={props.intl.formatMessage(
                                {
                                    id: 'howDidYouHear.paceholder',
                                    defaultMessage: ''
                                })
                            }
                            value= {props.sourceOfListening}
                            onSelect={(val)=>{
                                props.updateState({
                                    sourceOfListening: val
                                });
                            }}
                        />
                    </Col>
                </Row> */}
            </div>
        </div>
    )
};

export default injectIntl(BusinessDetails);