import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../static/constants';
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');





export const fetchReminderByUserId = (payload, ) => {
  return dispatch => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadCallLogs/getCallLogsByUserId?relationshipId=${payload.relationshipId}&createdByUserId=${payload.createdByUserId}&pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`)
      .then(res => {

          const data = {
              list: res.data,
              pageNo: res.pageNo,
            }
        dispatch({ type: CRM_ACTION_LIST.LEAD_CALL_LOGS_LIST, data: data });
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
      })
}
}

export const getAllLeadReminderByUserId = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios 
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadReminder/getAllLeadReminderByUserId?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.createdByEmpId}`)
      .then(res => {
        dispatch({ type: CRM_ACTION_LIST.CRM_LEAD_REMINDER_LIST, data: res.data });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CRM_ACTION_LIST.CRM_LEAD_REMINDER_LIST;
          // err.data = err.data;
        }
      })
  }
}