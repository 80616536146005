import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FoodComp from "../../../components/integrations/FoodTab";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions'


class Food extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentWillMount() {

    }

    componentDidMount() {

        
    }

    render() {
        return <FoodComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.companyInfo,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Food);
