import React, { Fragment } from "react";
import './ledger.scss';
import { Tabs, Menu } from 'antd';
import PageBreadcrumb from "../../PageBreadcrumb";
import AllLedgerAccounts from './LedgerAccount'
import LedgerAccountGroup from './LedgerAccountGroup';
import Category from './Category';
import CategoryGroup from './CategoryGroup'
import { fetchDataIfNeeded, checkACLPermission, } from '../../../utils';
import { FormattedMessage } from 'react-intl';
// import NewLedgerComponent from '../../../containers/modal/modalBody/finance/LedgerAccount';
import LedgerAccountGroupComponent from '../../../containers/modal/modalBody/finance/LedgerAccount/ledgerAccountGroupTab';
import CategoryComponent from '../../../containers/modal/modalBody/finance/LedgerAccount/category';
import { MODULE_CODED_VALUES, PERMISSION_VALUES,} from '../../../static/constants';
import DropdownAction from "antd/lib/dropdown";




const { TabPane } = Tabs;

function callback(key, props) {
    const companyInfo = props.companyInfo || {};
    const payload = {
        relationshipId: companyInfo.relationshipId
    };
    if (key === '1') {
        fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', props, payload);
    } else if (key === '2') {
        fetchDataIfNeeded('fetchAllLedgerAccountGroup', 'allLedgerAccountGroups', props, payload);
    } else if (key === '3') {
        fetchDataIfNeeded('fetchAllCategories', 'allCategories', props, payload);
    } else if (key === '4') {
        fetchDataIfNeeded('fetchAllCategoryGroups', 'allCategoryGroup', props, payload);
    }
}

const breadCrumbList = [
    {
        name: 'Finance'
    },
    {
        name: 'Ledger'
    }
];





const LedgerAccounts = (props) => {

  const {permissions} = props;
  const getActionPermissionData = () => {
    const primaryPerm = permissions.primary || [];
    const permittedAction = {
        create: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.LEDGER_ACCOUNTS, PERMISSION_VALUES.CREATE)
    };
    Object.keys(permittedAction).forEach((operation) => {
        if (permittedAction[operation] === false) {
            delete permittedAction[operation];
        }
    })
    return permittedAction;
  }

  const actionMenu = () => {
    return (
      <Menu className="create-action-dropdown">
        <Menu.Item
          key="1"
          value="ledgerAccounts"
          onClick={() => {
            props.updateDrawerState({ledgerAccountsDrawerVisible: true,data:{}})
          }}
        >
          Ledger Accounts
        </Menu.Item>
        <Menu.Item
          key="2"
          value="ledgerAccountGroup"
          onClick={() => {
            props.showModal({
              title: (
                <Fragment>
                  <div className="ant-modal-title">
                    Create Ledger Account Group
                  </div>
                </Fragment>
              ),
              modalBody: <LedgerAccountGroupComponent {...props} />,
              width: '60%',
              hideFooter: true,
            });
          }}
        >
          Ledger Account Group
        </Menu.Item>

        <Menu.Item
          key="3"
          value="category"
          onClick={() => {
            props.showModal({
              title: (
                <Fragment>
                  <div className="ant-modal-title">
                    Create Category
                  </div>
                </Fragment>
              ),
              modalBody: <CategoryComponent {...props} />,
              width: '60%',
              hideFooter: true,
            });
          }}
        >
          Category
        </Menu.Item>
      </Menu>
    );
  };
  
  const permittedActions = getActionPermissionData();

    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="heading.finance.ledgerAccounts"
                defaultMessage=""
              />
            </div>
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="top-view-right">
              {
                permittedActions.create &&
                <DropdownAction overlay={actionMenu()} trigger={['click']}>
                  <button className="create-action-btn"
                    onClick={() => {
                      // props.showModal({
                      //   title: (
                      //     <Fragment>
                      //       <div className="ant-modal-title">
                      //         New Chart of Account
                      //       </div>
                      //     </Fragment>
                      //   ),
                      //   modalBody: <NewLedgerComponent {...props} />,
                      //   width: 1100,
                      //   hideFooter: true,
                      // });
                      // props.updateState({ledgerAccountsDrawerVisible: true})
                    }}
                  >
                      <i class="fi fi-br-plus"></i> <FormattedMessage id="button.create.label"  defaultMessage=""/> <i class="fi fi-bs-angle-down"></i>
                  </button>
                </DropdownAction>
              }
            </div>
          
        </div>
        <div className="view-container">
            <Tabs
              onChange={(key) => {
                callback(key, props);
              }}
              type="line"
            >
              <TabPane tab="Ledger Account" key="1">
                <AllLedgerAccounts {...props} />
              </TabPane>
              <TabPane tab="Ledger Account Group" key="2">
                <LedgerAccountGroup {...props} />
              </TabPane>
              <TabPane tab="Category" key="3">
                <Category {...props} />
              </TabPane>
              <TabPane tab="Category Group" key="4">
                <CategoryGroup {...props} />
              </TabPane>
            </Tabs>
          </div>
      </>
    );
};

export default LedgerAccounts;