import React from "react";
import InPageMenu from "../../Common/InPageMenu";
import PageBreadcrumb from "../../PageBreadcrumb";
import { FormattedMessage } from "react-intl";
import { ICONS } from '../../../static/constants';
import './_meta-style.scss'
import Department from '../../../assets/images/department.svg';
import Designation from '../../../assets/images/designation.svg'
import Hierarchy from '../../../assets/images/hierarchy.svg'
import Location from '../../../assets/images/location.svg'
import JobRole from '../../../assets/images/recognition.svg'
import Team from '../../../assets/images/team.svg'
import Employee from '../../../assets/images/employee.svg'
// import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DepartmentContainer from '../../../containers/company/EmployeeMeta/Department';
import DesignationContainer from '../../../containers/company/EmployeeMeta/Designation';
import BranchContainer from '../../../containers/company/EmployeeMeta/Branch';
import JobRoleContainer from '../../../containers/company/EmployeeMeta/JobRole'
import EmployeeContainer from '../../../containers/company/EmployeeMeta/EmployeeType'
import HierarchyContainer from '../../../containers/company/EmployeeMeta/OrgHierarchy'
import TeamContainer from '../../../containers/company/EmployeeMeta/Team';
import UnderDev from "../../Common/NotFound";
// import CompanyHierarchy from "../../../containers/compamy";


function EmployeeMeta(props) {
    // const { path } = useRouteMatch();
    const handleActiveTab = (index) => {
        props.updateState({
            selectedMenuItem: index
        })
    };

    const activeTab = () => {
        switch (props.selectedMenuItem) {
            case 0:
                return <DepartmentContainer />;
            case 1:
                return <DesignationContainer />;
            case 2:
                return <BranchContainer />;
            case 3:
                return <JobRoleContainer />;
            case 4:
                return <EmployeeContainer />;
            case 5:
                return <HierarchyContainer />;
            case 6:
                return <TeamContainer />;
            default:
                return null;
        }
    };
    const breadCrumbList = [
        {
            name: <FormattedMessage id='employeeMeta.pageName.dashboard' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='employeeMeta.pageName.employee' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='employeeMeta.pageName.meta' defaultMessage='' />,
        }
    ];
    const menuList = [{
        path: 'a',
        icon: Department,
        name: "employeeMeta.menulabel.department",
        description: "You are powered with creating multiple users for your business.",

    },
    {
        icon: Designation,
        name: "employeeMeta.menulabel.designation",
        description: "You are powered with creating multiple users for your business."

    },
    {
        icon: Location,
        name: "employeeMeta.menulabel.branch",
        description: "You are powered with creating multiple users for your business."
    },
    {
        icon: JobRole,
        name: "employeeMeta.menulabel.jobRole",
        description: "You are powered with creating multiple users for your business."
    },
    {
        icon: Employee,
        name: "employeeMeta.menulabel.employeeType",
        description: "You are powered with creating multiple users for your business."
    },
    {
        icon: Hierarchy,
        name: "employeeMeta.menulabel.hierarchy",
        description: "You are powered with creating multiple users for your business."
    },
    {
        icon: Team,
        name: "employeeMeta.menulabel.team",
        description: "You are powered with creating multiple users for your business."
    }

    ]
    return (
        <>
        {/* <UnderDev /> */}

            <div className="view-top-bar">
                <div className="top-view-left">
                    <div className="page-heading">
                        <FormattedMessage
                            id="heading.team.employeeMeta"
                            defaultMessage="" />
                    </div>
                    <div className="vertical-sep" />
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div>
            </div>

            <div className="meta-container">
                <div className="meta-sidenav">
                    <InPageMenu menuList={menuList} onItemClick={handleActiveTab} />
                </div>
                <div className="meta-main-body">
                    {activeTab()}
                    {/* <Switch>
                        {menuList.map((menuItem, index) => {
                            return (
                                <Route key={index} path={`${path}/a`} component={menuItem.component || 'none'} />
                            );
                        })}
                    </Switch> */}
                </div>
            </div>
        </>
    )
}
export default EmployeeMeta;