import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllEmployees } from '../../../../actions/commonActions';
import SettingSalesDrawerComp from '../../../../components/drawer/customer/settingSalesDrawer';
import { fetchAllApprovalSetting } from '../../../modal/modalBody/settings/DocumentApproval/action';
import {showModal, pushModalToStack, popModalFromStack, hideModal} from '../../../../actions/commonActions';
import { updateBusinessData } from '../../../modal/modalBody/settings/CompanyInfo/action';
import { fetchAllLedgerAccounts } from '../../../finance/LedgerAccounts/action';
import { fetchDataIfNeeded } from '../../../../utils';
import { CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY, TRANSACTION_TYPES } from '../../../../static/constants';
import { saveOrUpdateDocApproval } from '../../../modal/modalBody/settings/DocumentApproval/action';
import { getDocumentNumber, getDocumentPrefix } from '../../../modal/modalBody/settings/DocumentNumber/action';
import { updateOrCreateDocName } from './action';
import { loadEmailData } from '../../email/action';



class SettingSalesDrawer extends Component {
    constructor(props) {
        super(props);
        let dataNeedToInit = Object.keys(props.data || {})?.length ?true :false;
        let data = dataNeedToInit?props:{}
       
        this.state = {
            companyInfoData: {
                ...props.companyInfo,
                 onlineThemes: JSON.parse(props.companyInfo.onlineThemes || "{}"),
                roundingOffSettings: JSON.parse(props.companyInfo.roundingOffSettings || "{}"),
                couponSetting: JSON.parse(props.companyInfo.couponSetting || "{}")
              },
              
                 };
     
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            type: TRANSACTION_TYPES.SHIPMENT,
        }


          this.props.fetchAllLedgerAccounts({ relationshipId: companyInfo.relationshipId });


          this.props.fetchAllApprovalSetting({ relationshipId: companyInfo.relationshipId });


          this.props.getDocumentNumber({ relationshipId: companyInfo.relationshipId });


          this.props.getDocumentPrefix({ relationshipId: companyInfo.relationshipId });

          this.props.fetchAllEmployees(payload);
          this.props.loadEmailData({...this.props ,type: TRANSACTION_TYPES.SHIPMENT})

  
    }

    componentWillReceiveProps(props) {
        // if (props.documentNumbers && this.state.tabDocumentOptions && this.state.tabDocumentOptions.length && props.documentNumbers.isLast) {
            if (props.documentNumbers && props.tabDocumentOptions && Object.keys(props.documentPrefix)?.length ) {
                let value=props.tabDocumentOptions;
                value.number= props.documentNumbers[CONSTANTS_TRANSACTIONS_KEY[value.txType]] || 1;
                value.txName = CONSTANTS_TRANSACTIONS_KEY[value.txType];
                value.txNamePefix = props.documentPrefix[CONSTANTS_TRANSACTIONS_KEY[value.txType]];
           
            this.setState({
                tabDocumentOptions: value,
            });
        }
    }

    render() {
        return <SettingSalesDrawerComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        documentNumbers: state.settings.documentNumbers,
        documentPrefix: state.settings.documentPrefix,
        docApprovalSettings: state.settings.docApprovalSettings,
        employeeList: state.common.employees,  
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
 showModal,
    hideModal, 
    pushModalToStack,
    popModalFromStack,
    updateBusinessData,
    fetchAllLedgerAccounts,
    getDocumentNumber,
    getDocumentPrefix,
    fetchAllApprovalSetting,
    fetchAllEmployees,
    updateOrCreateDocName,
    saveOrUpdateDocApproval,
    loadEmailData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SettingSalesDrawer);