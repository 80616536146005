import React, {Component} from "react";
import { FormattedMessage } from 'react-intl';

class MovingProduct extends Component {
    render() {
        const keys = Object.keys(this.props.fastestMovingProducts || {});
        const values = Object.values(this.props.fastestMovingProducts || {});
        return (
            <div className="table-container">
                <table>
                    <thead>
                    <tr>
                        <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                        <th>Product Name</th>
                        <th>Quantity</th>
                    </tr>
                    </thead>
                    <tbody>
                    {keys.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>{item}</td>
                                {/* <td>{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}{values[i]}</td> */}
                                <td>{values[i] || ''}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default MovingProduct;

// import React from "react";
// import ReactApexChart from "react-apexcharts"

// class MovingProduct extends React.Component {

//     render() {
//         const data = {

//             series: Object.values(this.props.fastestMovingProducts || {}),
//             options: {
//                 chart: {
//                     width: 380,
//                     type: 'pie',
//                 },
//                 labels: Object.keys(this.props.fastestMovingProducts || {}),
//                 responsive: [{
//                     breakpoint: 480,
//                     options: {
//                         chart: {
//                             width: 200
//                         },
//                         legend: {
//                             position: 'bottom'
//                         }
//                     }
//                 }]
//             },


//         };
//         return (
//             <div id="chart">
//                 <ReactApexChart  style={{minHeight: '225.7px', paddingTop: '50px', paddingLeft: '40%'}} options={data.options} series={data.series} type="pie" width={380} />
//             </div>


//         );
//     }
// }

// export default MovingProduct;