import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
//import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import {ICONS} from '../../../static/constants'
import DropdownAction from "antd/lib/dropdown";
import { getMomentLoclDateForUIReadOnly } from "../../../utils";
import { Skeleton, Pagination, Tooltip, Popover,Dropdown,Menu, Button } from "antd";


import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import Enquiry from "./Enquiry";
const AllPageList = (props) => {
    
  const { updateState, companyInfo } = props;

    const list = props.allPageListData;
 

 

  

  const openNewSi = (item) => {
    props.pushModalToStack({
      modalBody: <Enquiry {...props} pageData={item} pageTitle="View page" />,
      width: "100%",
      hideTitle: true,
      hideFooter: true,
      wrapClassName: "new-transaction-wrapper",
    });
  };

 

 
  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
       
        <Menu.Item key="1" value="view"  onClick={()=>openNewSi(item)}>
            <i className={ICONS.VIEW} /> View
          </Menu.Item>
  
          <Menu.Item key="2" value="edit"  
           onClick={()=>{
            props.history.push({
              pathname: '/admin/e-store/page-edit',
              state: item,
            })
           }}
           >
            <i className={ICONS.EDIT} 
           
            /> Edit 
          </Menu.Item>
          <Menu.Item key="3" value="delete"    onClick={() => {
              
              if (item.ecomHeaderPageId) {
                const modalData = {
                  modalBody: "Are you sure you want to delete Page?",
                  handleSubmit: () => {
                    const payload ={
                      id:item.ecomHeaderPageId,
                    }
                    props.deletePageListItem(payload)
                
                    
                  },
                };
                props.pushModalToStack(modalData);
              } else {
                const modalData = {
                  title: (
                    <div className="title">
                      <i className="fa fa-ban mr15" />
                      Validation Failed
                    </div>
                  ),
                  modalBody: (
                    <Fragment>
                      <div className="warning-message">
                        No customer selected. Please select
                        customerTransaction(s) before deleting.
                      </div>
                      <div className="button-action">
                        <Button
                          className="ant-btn-default"
                          onClick={() => props.popModalFromStack()}
                        >
                          OK
                        </Button>
                      </div>
                    </Fragment>
                  ),
                  hideFooter: true,
                  wrapClassName: "validation-failed-modal",
                };
                props.pushModalToStack(modalData);
              }
            }}>
            <i className={ICONS.DELETE} /> Delete
          </Menu.Item>


        

      </Menu>
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };

}

const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
    props.resetPaginatedData();
    props.fetchCreditMemos(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
}

  return (
    <Fragment>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Status</th>
              <th>Created By</th>
              <th>Updated By</th>
              
              <th>Date</th>
            
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
             list.length>0 ? list?.map((item,index)=>{
                return(
                  <tr key={index}>
                  <td>{item.pageTitle}</td>
                  <td ><button style ={{color:"white",margin:"auto 0px",border:"none",padding:"5px",borderRadius:"2px",display:"block",width:"80px",textAlign:"center",backgroundColor:`${item.isPublished?'green':'red'}`}}>{item.isPublished?'Published':'Draft'}</button></td>
                  <td>Vivek Gupta</td>
                  <td>  {getMomentLoclDateForUIReadOnly({date:item.dateUpdated})}</td>
                  <td>{getMomentLoclDateForUIReadOnly({date:item.dateCreated})}</td>
                  <td style={{ paddingLeft: "50px",cursor:"pointer" }} >
                                  <DropdownAction
                                    overlay={actionMenu(item)}
                                    trigger={["click"]}
                                    
                                  >
                                    <i className={ICONS.MORE} />
                                  </DropdownAction>
                                </td>
                </tr>
                )
              })  : <span>Empty List</span>
            }
          
          </tbody>
        </table>
      </div>

      <div className="footer">
        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
                        size="small"
                        total={list.length}
                       
                        defaultPageSize={10} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber || 1, pageSize, props)
                        }}
                    />
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(AllPageList);
