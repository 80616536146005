import React from 'react';
// import { FormattedMessage } from 'react-intl';
//import { MailOutlined } from '@ant-design/icons';
import { Row, Col, Button, Switch, Tooltip, Divider } from 'antd';
// import { Dropdown } from "../../../../../general/Dropdown";
import { TextBox } from '../../../../../general/TextBox';
//import moment from 'moment-timezone';
import { ICONS } from '../../../../../../static/constants';
import { getCurrencySymbol, showToasterMessage } from '../../../../../../utils';
import { LabelBox } from '../../../../../general/LabelBox';
// const { Option } = Select;

const FeesStructure = (props) => {

    const {classFees, feeStructList = [{}], submitted} = props;


    return <div style={{maxHeight: '80vh', overflowY: 'auto', overflowX:'hidden'}}>
                <Row gutter={[16, 16]} style={{ borderBottom: 'solid 1px #e4e4e4' }}>
                    <Col span={2}><b>S.No.</b></Col>
                    <Col span={7}><b>Fee Type</b></Col>
                    <Col span={6}><b>Amount</b></Col>
                    <Col span={3}>
                        <b>One Time</b>
                        <Tooltip placement="top" title={props.intl.formatMessage({ id: 'setting.student.registration.oneTimeFee.helpMessage' })}>
                            <i className={`${ICONS.HELP} cursor-pointer ml5`} />
                        </Tooltip>
                    </Col>
                    <Col span={3}>
                        <b>Discount</b>
                        <Tooltip placement="top" title={props.intl.formatMessage({ id: 'setting.student.registration.discount.helpMessage' })}>
                            <i className={`${ICONS.HELP} cursor-pointer ml5`} />
                        </Tooltip>
                    </Col>
                    <Col span={3}>
                        <b>Action</b>
                        {
                            feeStructList.length === 0 ? <i className={ICONS.ADD_P} onClick={()=>{ props.updateModalState({feeStructList: [...feeStructList, {}]}); }}/> : <i style={{width: '12px'}} />
                        }
                    </Col>
                </Row>

                {feeStructList.length ? feeStructList.map((item, i)=>{

                    return <Row gutter={[16, 16]} key={i} style={{ borderBottom: 'solid 1px #e4e4e4' }} align='middle'>
                        <Col span={2}>
                            {i+1}
                        </Col>

                        <Col span={7}>
                            <TextBox 
                                placeholder={'Fees Type'}
                                value={item.feeType}
                                className = {submitted && !item.feeType ? 'input-text-error': ''}
                                onChange={(e) => {
                                    const temp = feeStructList;
                                    const tempObj = feeStructList[i] || {};
                                    tempObj.feeType = e.target.value;
                                    temp[i] = tempObj;
                                    props.updateModalState({feeStructList: temp});
                                }}
                            />
                        </Col>
                        <Col span={6}>
                            <LabelBox
                                labelValue={getCurrencySymbol(props.companyInfo?.currencyCode || '-')}
                                labelStyle={{marginRight: '-41px'}}
                                type='number'
                                placeholder={'Enter Amount'}
                                value={item.amount}
                                className = {submitted && !item.amount ? 'input-text-error': ''}
                                onChange={(e) => {
                                    const temp = feeStructList;
                                    const tempObj = feeStructList[i] || {};
                                    tempObj.amount = Number(e.target.value);
                                    temp[i] = tempObj;
                                    props.updateModalState({feeStructList: temp});
                                }}
                            />
                        </Col>
                        <Col span={3}>
                            <Switch 
                                checked = {item.oneTimeFee}
                                value={1}
                                onChange={(val)=>{
                                    const temp = feeStructList;
                                    const tempObj = temp[i] || {};
                                    tempObj.oneTimeFee = val;
                                    temp[i] = tempObj;
                                    props.updateModalState({feeStructList: temp});
                                }} 
                            />
                        </Col>
                        <Col span={3}>
                            <Switch 
                                checked = {item.applyDiscounts}
                                value={1}
                                onChange={(val)=>{
                                    const temp = feeStructList;
                                    const tempObj = temp[i] || {};
                                    tempObj.applyDiscounts = val;
                                    temp[i] = tempObj;
                                    props.updateModalState({feeStructList: temp});
                                }} 
                            />
                        </Col>
                        <Col span={3} style={{display: 'flex', justifyContent: 'space-evenly'}}>
                            <i className={ICONS.DELETE_P} 
                                onClick={()=>{
                                    const temp = feeStructList;
                                    temp.splice(i, 1);
                                    props.updateModalState({feeStructList: temp});
                                }}
                            />

                            {
                                feeStructList.length === i+1 ? <i className={`${ICONS.ADD} pointer`} onClick={()=>{ props.updateModalState({feeStructList: [...feeStructList, {}]}); }}/> : <i style={{width: '12px'}} />
                            }
                        </Col>

                    </Row>
                })
                :''}
                <Divider />
                <Row justify='end' align='middle'>
                    <div className='mr15'><b>Total Fees : </b> {getCurrencySymbol(props?.companyInfo?.currencyCode)} { (feeStructList || []).length ? feeStructList.reduce((res, obj)=> res+(obj?.amount || 0), 0).toFixed(2) : '0' } </div>
                </Row>
                <Divider />
                <Row justify='space-between' align='middle'>
                    <Button onClick={()=>props.popModalFromStack()}> Cancel </Button>
                    <Button type='primary' onClick={()=>{
                        for(const obj of feeStructList){
                            if(!(obj?.feeType && obj?.amount)){
                                props.updateModalState({submitted: true});
                                showToasterMessage({
                                    description: 'Some fields are empty!',
                                    messageType: 'error'
                                })
                                return;
                            }
                        }
                        var tempClassFees = classFees || [];
                        var tempObj = tempClassFees[props.structIdx] || {};
                        tempObj.feeStructList= props.feeStructList;
                        tempClassFees[props.structIdx] = tempObj;
                        props.updateState({classFees: tempClassFees})
                        props.popModalFromStack();
                    }}>
                        Submit
                    </Button>
                </Row>
            </div>

}

export default FeesStructure;