import React from 'react';
import getEditorNamespace from './getEditorNamespace.js';

class CKEditor extends React.Component {
  constructor(props) {
    super(props);

    this.element = null;
    this.editor = null;
  }

  componentDidMount() {
    this._initEditor();
  }

  componentWillUnmount() {
    this._destroyEditor();
  }

  componentDidUpdate(prevProps) {
    const { props, editor } = this;

    /* istanbul ignore n"ext */
    if (!editor) {
      return;
    }

    if (prevProps.data !== props.data && editor.getData() !== props.data) {
    //  editor.setData(props.data);
    // this check add to prevent ckeditor parse content in base64 when send email rest triggered 
      if (props.data?.indexOf('data:text/xml;base64,') < 0) {
        editor.setData(props.data);
      }
    }

    if (prevProps.readOnly !== props.readOnly) {
      editor.setReadOnly(props.readOnly);
    }

    if (prevProps.style !== props.style && editor.container) {
      editor.container.setStyles(props.style);
    }

    this._attachEventHandlers(prevProps);
  }

  render() {
    return <div contentEditable={this.props.disabled?this.props.disabled:true} style={this.props.style} className={this.props.className + " custm-ckeditor"} ref={ref => (this.element = ref)}></div>;
  }

  _initEditor() {
    const { config, readOnly, type, onBeforeLoad, onInit, style, data, onAttachmentClick } = this.props;
    config.readOnly = readOnly;

    getEditorNamespace(CKEditor.editorUrl).then(CKEDITOR => {
      const constructor = type === 'inline' ? 'inline' : 'replace';

      if (onBeforeLoad) {
        onBeforeLoad(CKEDITOR);
      }
      const editor = this.editor = CKEDITOR[constructor](this.element, config);

      if (onInit) {
        onInit(editor);
      }

      if (!CKEDITOR.plugins.registered.attachment) {
        CKEDITOR.plugins.add('attachment', {
          init: (editor) => {
            editor.ui.addButton('Attachment', {
              label: "Add Attachments",
              command: 'addAttachments',
              toolbar: 'insert',
              icon: `${window.location.origin}/images/attachment_icon.png`
            });
          }
        });
      }

      editor && editor.addCommand('addAttachments', {
        exec: (editor) => {
          if (onAttachmentClick) {
            onAttachmentClick(editor);
          }
        }
      });

      let baseUrl = window.location.origin;
      // if(!baseUrl.includes("localhost")){
      //   baseUrl = window.location.origin+"/app";
      // }
       if(baseUrl.includes("optomac")){
        baseUrl = window.location.origin+"/app";
      }
      
      CKEDITOR.plugins.addExternal('notification', `${baseUrl}/ckeditor/plugins/notificaion/`, 'plugin.js');
      CKEDITOR.plugins.addExternal('wordcount', `${baseUrl}/ckeditor/plugins/wordcount/`, 'plugin.js');
      CKEDITOR.plugins.addExternal('confighelper', `${baseUrl}/ckeditor/plugins/confighelper/`, 'plugin.js');

      this._attachEventHandlers();

      // We must force editability of the inline editor to prevent `element-conflict` error.
      // It can't be done via config due to CKEditor 4 upstream issue (#57, ckeditor/ckeditor4#3866).
      if (type === 'inline' && !readOnly) {
        editor.on('instanceReady', () => {
          editor.setReadOnly(false);
        }, null, null, -1);
      }

      if (style && type !== 'inline' &&  editor.container) {
        editor.on('loaded', () => {
          editor.container.setStyles(style);
        });
      }

      if (data) {
        // editor.setData(data);
        // this check add to prevent ckeditor parse content in base64 when send email rest triggered 
        if (data.indexOf('data:text/xml;base64,') < 0) {
          editor.setData(data);
        }
      }
    }).catch(console.error);
  }

  _attachEventHandlers(prevProps = {}) {
    const props = this.props;

    Object.keys(this.props).forEach(propName => {
      if (!propName.startsWith('on') || prevProps[propName] === props[propName]) {
        return;
      }

      this._attachEventHandler(propName, prevProps[propName]);
    });
  }

  _attachEventHandler(propName, prevHandler) {
    const evtName = `${propName[2].toLowerCase()}${propName.substr(3)}`;

    if (prevHandler) {
      this.editor.removeListener(evtName, prevHandler);
    }

    this.editor.on(evtName, this.props[propName]);
  }

  _destroyEditor() {
    if (this.editor) {
      this.editor.destroy();
    }

    this.editor = null;
    this.element = null;
  }
}

CKEditor.defaultProps = {
  type: 'classic',
  data: '',
  onBeforeLoad: ( CKEDITOR ) => { 
    CKEDITOR.disableAutoInline = true;
   
    CKEDITOR.addCss( '.cke_editable_themed.cke_editable > p { margin-block-start: 0px !important; margin-block-end: 0px !important; } ' );
  },
  config: {},
  readOnly: false
};


CKEditor.editorUrl = 'https://cdn.ckeditor.com/4.14.0/full/ckeditor.js';
CKEditor.displayName = 'CKEditor';

export default CKEditor;