import React from "react";
import "./../../../../assets/stylesheets/common/invoice.scss";
// import PageBreadcrumb from "../../../PageBreadcrumb";
import PurchaseRequestDetails from "./PurchaseRequestDetails";
import ProductDetails from "./ProductDetails";
import HeaderAction from "./HeaderAction";
// import { FormattedMessage } from 'react-intl';
import { Divider } from 'antd';
const PurchaseRequestComponent = (props) => {
  // const breadCrumbList = [
  //   {
  //     name: <FormattedMessage id='sidebar.menuItem.purchase' defaultMessage='' /> ,
  //   },
  //   {
  //     name: <FormattedMessage id='standard.Purchase.Request' defaultMessage='' />
  //   }
  // ];

  return (
    <>
      <div className="txn-header">
        <HeaderAction {...props} />
      </div>
      {/* <div className="invoice-header">
        <PageBreadcrumb breadCrumbList={breadCrumbList} />
      </div> */}
      <div className="txn-body">
        <div className="txn-details-container">
          <PurchaseRequestDetails {...props} />
        </div>

        <Divider style={{ margin: "0 0 10px 0px" }} />

        <div className="product-details-container">
          <ProductDetails {...props} />
        </div>
      </div>
    </>
  );
};

export default PurchaseRequestComponent;
