import React from "react";
import { injectIntl } from 'react-intl';
import './index.scss';
import ReactHtmlParser from 'react-html-parser';

const RegistrationFormSettingPreview = (props) => {
   
    return (
        <div>
            <div className="mobile-shapes">
                <div className="mobile-previews">
                    {ReactHtmlParser(props.templateContent)}
                </div>
            </div>
        </div>
    )
};

export default injectIntl(RegistrationFormSettingPreview);
