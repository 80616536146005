import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';

import DebitMemoComponent from '../../../../components/supplier/debitMemo/StandardDebitMemo';
import { fetchSuppliers,resetPaginatedData } from '../../../../containers/supplier/Listing/action';
import {
  fetchTaxes, fetchUOM, addUOM, deleteUOM, fetchExpenseTypes, addExpenseTypes, deleteExpenseTypes, fetchProducts, showModal, hideModal, pushModalToStack, popModalFromStack, deleteAddress, deleteTax, fetchDocuments, addDocumentName, deleteDocumentName, deleteContact,
  fetchUserPreferences, saveUserPrefrences, fetchRelationshipTaxIdentifications, deleteRelationshipTaxIdentifications, deleteSupplierTaxIdentifications,
  fetchSupplierSummary, fetchSupplierDetailsBySupplierId, resetSupplierDetail, getRelationshipAddress
} from '../../../../actions/commonActions';
import LedgerDrawer from '../../../../containers/drawer/ledgers/DebitMemoLedger';
import { createDebitMemo, getNextTxNumber, createDebitMemoDraft } from './action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import DebitMemoDetail from '../../../modal/modalBody/supplier/DebitMemoDetail';
import { CONSTANTS, LEDGER_ACTION_LIST, CONSTANTS_TRANSACTIONS,CONSTANTS_TRANSACTIONS_KEY, LEDGER_TYPE } from '../../../../static/constants';
import { GRID_PREFERENCE_CONSTANTS, AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import { deleteSuppliers } from '../../Listing/action';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import { fetchDebitMemoDetail, resetDebitMemoDetail } from '../../../modal/modalBody/supplier/DebitMemoDetail/action';
import { fetchAddresses } from '../../Listing/ShowAddress/action';
import { fetchAllContacts } from '../../Listing/ShowConact/action';
import { fetchDebitMemos,getDebitMemoCount } from '../Listing/action';
import SettingPurchaseDrawer from '../../../drawer/supplier/settingPurchaseDrawer';
class PurchaseInvoice extends Component {
  constructor(props) {
    super(props);
    const defaultPurchaseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.PURCHASE }) || {};
    const defaultTaxLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_TAX }) || {};
    const defaultDiscountLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DISCOUNT_RECIEVED }) || {};
    const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.SUPPLIER_EXPENSE }) || {};
    const defaultCreditorLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CREDITORS }) || {};
    const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const relationshipBillingAddress = allRelationshipBillingAddress.find(x => x.isDefault) || {};

    // const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNameDebitMemo",
      viewName: "DebitMemo",
      docName:"Debit Memo",
      tabDocumentOptions: 
      { txType: CONSTANTS_TRANSACTIONS.DEBIT_MEMO, label: 'Debit Memo', numberExample: 'DM-1', numberInfo: 'DM-SupplierNumber-DDMMYY' },
      settingPurchaseDrawerVisible:false,

      stateSupplierId: props.supplierId,
      pageNumber: props.pageNumber || 1,
      supplier: undefined,
      debitMemoDate: new Date(),
      isClone: props.clone,
      priceListName: undefined,
      allRelationshipBillingAddress,
      relationshipBillingAddress,
      pageSize: 100,
      purchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      purchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      discountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      taxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      purchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,

      igstPurchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      igstPurchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      igstPurchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      igstDiscountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      igstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      igstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      igstPurchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      igstPurchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      
      cgstPurchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      cgstPurchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      cgstPurchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      cgstDiscountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      cgstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      cgstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      cgstPurchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      cgstPurchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      
      sgstPurchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      sgstPurchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      sgstPurchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      sgstDiscountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      sgstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      sgstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      sgstPurchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      sgstPurchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      txPreferences: { txType: "debitMemo", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
        GRID_PREFERENCE_CONSTANTS.AMOUNT_WITHOUT_TAX,
        "sno"
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === 'India',
        // basePrice: true,
        // specialDiscount: true,
        discount: true,
        tax: true,
        taxableAmount:true

      },
    }
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true
    // });
    const companyInfo = this.props.companyInfo || {};
    // const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      debitMemoMasterId: this.props.debitMemoMasterId,
      userId: (this.props.userInfo || {}).userId,
      supplierId: this.props.supplierId,
      pageNumber: 1,
      pageSize: 100
    };
    this.props.resetDebitMemoDetail();
    this.props.resetSupplierDetail();
    payload.supplierId && this.props.fetchSupplierDetailsBySupplierId(payload);
    fetchDataIfNeeded('getDebitMemoCount', 'debitMemoCount', this.props, payload);
    this.props.fetchDebitMemos(payload)
    // fetchPaginationDataIfNeeded('fetchSuppliers', 'suppliers', this.props, payload, true);
    this.props.resetPaginatedData();
    fetchPaginationDataIfNeeded('fetchSupplierSummary', 'supplierSummary', this.props, payload, true);
    fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
    fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
    fetchDataIfNeeded('fetchExpenseTypes', 'expenseTypes', this.props, payload);
    fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('fetchDocuments', 'documentList', this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    this.props.fetchRemarksTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Debit Memo' });
    fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
    payload.debitMemoMasterId && this.props.fetchDebitMemoDetail(payload);
  }

  componentWillReceiveProps(props) {
    // const linkProps = (this.props.location && this.props.location.state) || {};  
    if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
      const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
      let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
        return obj.relationshipTaxIdentificationId;
      })
      this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
    }

    if (props.documentList && props.documentList.length && !this.state.docName) {
      props.documentList.forEach((document) => {

        if (document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.DEBIT_MEMO] && document.isDefault) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName
          })
        }
      })

    }
    if (!this.state.supplierRemarks && props.remarksData && props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ supplierRemarks: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500)
    }

    if(props.boRelationshipAddress && props.boRelationshipAddress.length){
      const allRelationshipBillingAddress = [];
      props.boRelationshipAddress.forEach((e)=>{
        if(e.locationType === 'RelationshipBillingAddress'){
          allRelationshipBillingAddress.push(e);
        }
      });
      this.setState({
        relationshipBillingAddresses: allRelationshipBillingAddress
      })
    }else{
      const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      this.setState({
        allRelationshipBillingAddress,
      });
  
    }
   
    if (props.taxes && props.taxes.length && props.debitMemoData && props.debitMemoData.debitMemoMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.supplierCOATxDebitMemoList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.comment) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      }

      props.debitMemoData.supplierDebitMemoDetailsList.map((ele) => {
        const tax = (find(ele.supplierCOATxDebitMemoList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX;
        }) || {});
        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + " )") || ""),
          productObj: { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku },
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          discount: (find(ele.supplierCOATxDebitMemoList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax: (tax.amountPercent || 0),
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          taxName: appliedTax.taxName,
          rate: ele.anItemPurchasedPrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount
        })
      });
      let selectedContact = (props.debitMemoData.additionalInfoList || [])[0];
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      this.setState({
        version: props.debitMemoData.version,
        selectedContact: selectedContact || this.state.selectedContact,
        update: props.update,
        debitMemoMasterId: props.update ? props.debitMemoData.debitMemoMasterId : 0,
        relationshipBillingAddress: find(props.debitMemoData.boLocationDebitMemoList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {},
        shippingAddress: find(props.debitMemoData.boLocationDebitMemoList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {},
        billingAddress: find(props.debitMemoData.boLocationDebitMemoList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {},
        paymentTermName: props.debitMemoData.paymentTermName,
        paymentTermId: props.debitMemoData.paymentTermId,
        referenceNumber: props.debitMemoData.referenceNumber,
        priority: props.debitMemoData.orderPriority,
        customerPo: props.debitMemoData.customerPONumber,
        customerName: props.debitMemoData.customerName,
        remarksCustomer: props.debitMemoData.remarksCustomer,
        salesQuotationMasterId: props.debitMemoData.salesQuotationMasterId,
        quotationNumber: props.debitMemoData.quotationNumber,
        internalRemarks: props.debitMemoData.remarksInternal,
        footer: props.debitMemoData.footer,
        customer: { ...props.customer, companyName: props.debitMemoData.customerName, customerId: props.debitMemoData.customerId },
        supplierDebitMemoDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        rfqNumber: props.debitMemoData.rfqNumber,
        rfqMasterId: props.debitMemoData.rfqMasterId,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        oldPayload: props.update ? props.debitMemoData : {},
        reference: props.debitMemoData.reference,
        updateInventory: props.debitMemoData.isUpdateInventory ? true: false
      });
      if (props.update) {
        this.setState({ nextTxNumber: props.debitMemoData.debitMemoNumber });
      }
      props.resetDebitMemoDetail();
    }

    if (this.state.stateSupplierId && props.supplierDetail && props.supplierDetail.supplierId) {
      const supplierObj = props.supplierDetail || {};
      const allSupplierBillingAddress = filter(supplierObj.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS });
      const defaultBillingAddress = find(allSupplierBillingAddress, { isDefault: 1 });
      const selectedContact = (supplierObj.boContactList || []).find(x => x.isPrimaryContact) || {}; 
      const selectedList = (supplierObj && filter(supplierObj.supplierTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
      let selectedSupplierTaxIdentifications = selectedList.map(obj => {
        return obj.supplierTaxIdentificationId;
      })
      this.setState({
        stateSupplierId: null,
        supplierAddresses: (supplierObj.boLocationList || []).filter(x => x.locationType === CONSTANTS.TYPE_SHIPPING_ADDRESS) || [],
        supplier: {...supplierObj, id: supplierObj.supplierId, supplierId: supplierObj.supplierId, name: supplierObj.supplierStoreName, gstNumber: supplierObj.gstNumber, panNumber: supplierObj.panNumber },
        supplierBillingAddress: defaultBillingAddress,
        selectedSupplierTaxIdentifications,
        selectedContact,
        allBillingAddress: allSupplierBillingAddress,
        boContactList: supplierObj.boContactList || [],
      });
    }

    if (props.addresses) {
      if (props.addresses.BillingAddress && props.addresses.BillingAddress.length) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress
        });
      }
    }
    if (props.supplierContactList && props.supplierContactList.length) {
        this.setState({
          boContactList: props.supplierContactList
        });
    }
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "debitMemo", templateName: "standard" }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns : (this.state.txMandatoryColumns || []).concat('product', 'description')
        const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        })
      }
    }
  }

  openDebitmemoDetails = (data) => {
    const debitMemoPayload = {
      supplierId: data.supplierId,
      debitMemoMasterId: data.debitMemoMasterId
    }
    this.props.pushModalToStack({
      modalBody: <DebitMemoDetail {...this.props} debitMemoPayload={debitMemoPayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching
    };
    this.props.fetchProducts(payload)
    this.props.fetchDebitMemos(payload)
    fetchDataIfNeeded('getDebitMemoCount', 'debitMemoCount', this.props, payload);

  }
  render() {
    return (
      <Fragment>
        {this.state.settingPurchaseDrawerVisible && 
          <SettingPurchaseDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
            />
        }
        <LedgerDrawer {...this.state} updateState={(data) => { this.setState(data) }}
         />
        <DebitMemoComponent
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching)
          }}
          {...this.props}
          {...this.state}
          openDebitmemoDetails={this.openDebitmemoDetails}
          updateState={(data) => { this.setState(data) }}
          updateDrawerState={(data) => { this.setState(data) }}
        />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    suppliers: state.supplier.suppliers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    supplierAddresses: state.supplier.supplierAddresses,
    uomList: state.common.uomList,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    products: state.salesInvoice.products,
    documentList: state.common.documentList,
    nextTxNumber: state.debitMemo.nextTxNumber,
    allPreferences: state.common.allPreferences,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    allRelationshipTaxIdentificatins: state.common.allRelationshipTaxIdentificatins,
    debitMemoData: state.debitMemo.debitMemoDetail,
    supplierSummary: state.supplier.supplierSummary,
    supplierDetail: state.common.supplierDetail,
    addresses: state.supplier.addresses,
    supplierContactList: state.supplier.contacts,
    boRelationshipAddress: state.common.boRelationshipAddress,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchProducts,
  fetchTaxes,
  fetchUOM,
  addUOM,
  fetchDebitMemos,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  showModal,
  hideModal,
  createDebitMemo,
  pushModalToStack,
  popModalFromStack,
  deleteSuppliers,
  deleteAddress,
  deleteTax,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  getNextTxNumber,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchRemarksTemplate,
  resetRemarksData,
  fetchRelationshipTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  deleteSupplierTaxIdentifications,
  createDebitMemoDraft,
  fetchDebitMemoDetail,
  resetDebitMemoDetail,
  fetchSupplierSummary, 
  fetchSupplierDetailsBySupplierId, 
  resetSupplierDetail,
  fetchAddresses,
  getRelationshipAddress,
  fetchAllContacts,
  getDebitMemoCount,fetchSuppliers,resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoice);
