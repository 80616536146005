import React, { Fragment } from "react";
import { Skeleton, Pagination } from 'antd';
import { CONSTANTS } from '../../../static/constants';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { CustomAgGrid } from "../../general/CustomAgGrid";
import { getMomentDateForUIReadOnly, sortTable } from "../../../utils";

// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };


// const getRowHeight = (params) => {
//     return 28;
// }




const TransactionInAlpideComp = (props) => {

    const {pageSize, dir=0, transactionsInAlpide, pageNumber, updateState} = props
    // const onGridReady = (params) => {
    //     params.api.sizeColumnsToFit();
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi,
    //     });
    // }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageSize, pageNumber, props) => {
        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize
        };
        props.getTransactionsInAlpide(payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize
        };
        props.getTransactionsInAlpide(payload);
       props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="common.date.text" /></span>
    //             </div>
    //         },
    //         field: "paymentDate",
    //         resizable: true,
    //         valueFormatter: (data) => {
    //             data.value = data.value ? getMomentDateForUIReadOnly({date: data.value, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : '';
    //             return data.value;
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.txInAlpide.party" /></span>
    //             </div>
    //         },
    //         cellRendererFramework: (link) => <div>
    //             {link.data.customerName ? link.data.customerName : link.data.supplierName}
    //         </div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.deposit" /></span>
    //             </div>
    //         },
    //         field: "paymentAmount",
    //         cellRendererFramework: (link) => <div>
    //             {link.data.salesPaymentId > 0 ? Number(link.data.paymentAmount || 0).toFixed(2) : '-'}
    //         </div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.withdrawal" /></span>
    //             </div>
    //         },
    //         field: "amount",
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //            {link.data.purchasePaymentId > 0 ? Number(link.data.paymentAmount || 0).toFixed(2) : '-'}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="common.ref" /></span>
    //             </div>
    //         },
    //         field: "reference",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="customer.customerPayment.listing.header.paymentNo" /></span>
    //             </div>
    //         },
    //         field: "paymentNumber",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="transactionNumber.text" /></span>
    //             </div>
    //         },
    //         field: "invoiceNumber",
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.invoiceNumber ? link.data.invoiceNumber : link.data.expenseNumber ? link.data.expenseNumber : link.data.txNumber}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="common.project" /></span>
    //             </div>
    //         },
    //         field: "projectName",
    //         resizable: true,
    //     },
       
    // ]

    const sortColumn = (e) => {
        sortTable(document.getElementById('transactionInAlpide'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
      }


    return (
      <Fragment>
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="finance.banking.reconciledtxn.table.heading"
                defaultMessage=""
              />
            </div>
            {pageSize ? (
              <>
                <div className="vertical-sep" />
                <div>{pageSize}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions"></div>
        </div>

        <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
            <div className="table-container">
                <table id='transactionInAlpide'>
                    <thead>
                        <tr>
                            <th><FormattedMessage id='serial.no' /></th>
                            <th onClick={sortColumn}><FormattedMessage id="common.date.text" /> <i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="banking.txInAlpide.party" /> <i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="banking.deposit" /> <i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="banking.withdrawal" /> <i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="common.ref" /> <i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="customer.customerPayment.listing.header.paymentNo" /> <i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="transactionNumber.text" /> <i class="fi fi-rr-sort-alt"></i></th>
                            <th onClick={sortColumn}><FormattedMessage id="common.project" /> <i class="fi fi-rr-sort-alt"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionsInAlpide && (transactionsInAlpide || [])[pageNumber || 1] ? ((transactionsInAlpide || [])[pageNumber || 1]).map((rowData, index)=>{
                            return <tr key={index}>
                                <td>{index+1}</td>
                                <td>{rowData.paymentDate ? getMomentDateForUIReadOnly({date: rowData.paymentDate, format: CONSTANTS.DISPLAY_DATE_FORMAT_FY}) : '-'}</td>
                                <td>{rowData.customerName ? rowData.customerName : rowData.supplierName}</td>
                                <td> {rowData.salesPaymentId > 0 ? Number(rowData.paymentAmount || 0).toFixed(2) : '-'}</td>
                                <td>{rowData.purchasePaymentId > 0 ? Number(rowData.paymentAmount || 0).toFixed(2) : '-'}</td>
                                <td>{rowData.reference}</td>
                                <td>{rowData.paymentNumber}</td>
                                <td> {rowData.invoiceNumber ? rowData.invoiceNumber : rowData.expenseNumber ? rowData.expenseNumber : rowData.txNumber}</td>
                                <td>{rowData.projectName}</td>
                            </tr>
                        })
                        :''
                        }
                    </tbody>
                </table>
            </div>
          {/* <div className="agGridWrapper">
            <div className="ag-theme-balham" style={agGridStyle}>
              <CustomAgGrid
                columnDefs={columnDefs}
                rowData={
                  (props.transactionsInAlpide || [])[props.pageNumber || 1]
                }
                rowDragManaged={true}
                domLayout={"autoHeight"}
                animateRows={true}
                onGridReady={onGridReady}
                getRowHeight={getRowHeight}
              />
            </div>
          </div> */}

        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
                size="small"
                total={props.transactionCountInAlpide}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                loadPageData(pageSize, pageNumber, props);
                props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber, pageSize, props);
                }}
            />
          </div>
        </div>
      </Fragment>
    );
};

export default injectIntl(TransactionInAlpideComp);