import React, { Fragment, useEffect, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";

import { ErrorMsg } from "../../general/ErrorMessage";
// import ProductDetailsTab from "./ProductDetailsTab";

import {  CONSTANTS } from "../../../static/constants";

import "@ant-design/compatible/assets/index.css";


import {
  
  Col,
  Row,
  Input,
  Tabs,

  Select,
 
  DatePicker
} from "antd";

const ShippingDetails = (props) => {
  return (
    <div>
      <div>
      <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship to Legal Name"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship to Address 1"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Ship to Legal Name'}
type="text"
value={props.ShipLegalName}
onChange={(e) => {
props.updateState({
  ShipLegalName: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Shipping Address'}
type="text"
maxLength={20}
value={props.ShipAddress}
onChange={(e) => {
props.updateState({
  ShipAddress: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship to Location"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship to State Code"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Shipping Location'}
type="text"
value={props.shipLocation}
onChange={(e) => {
props.updateState({
  shipLocation: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Shipping State Code'}
type="text"
maxLength={20}
value={props.shipStateCode}
onChange={(e) => {
props.updateState({
  shipStateCode: e.target.value,
});
}}
/>

</Col>
</Row>
           </div> 

            <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship to Pincode"
    defaultMessage=""
  />
  

</Col>


</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Ship to Pincode'}
type="text"
value={props.shipPincode}
onChange={(e) => {
props.updateState({
  shipPincode: e.target.value,
});
}}
/>

</Col>


</Row>
           </div>
      </div>
      <div>
      {
    props.optionalFields ? (
<div className="mt20">
   <div className="drawer-heading">Optional Details</div>
   <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship to Trade Name"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship to Address 2"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Ship Trade Name'}
type="text"
value={props.shipTradeName}
onChange={(e) => {
props.updateState({
  shipTradeName: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Shipping Address 2'}
type="text"
maxLength={20}
value={props.shipAddress2}
onChange={(e) => {
props.updateState({
  shipAddress2: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship to District"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship To Phone or Mobile No"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={' District'}
type="text"
maxLength={20}
value={props.shipDistrict}
onChange={(e) => {
props.updateState({
  shipDistrict: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Phone No.'}
type="text"
maxLength={20}
value={props.shipPhoneNo}
onChange={(e) => {
props.updateState({
  shipPhoneNo: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Ship To E-mail id"
    defaultMessage=""
  />
  

</Col>


</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={' Email'}
type="text"
value={props.shipEmailId}
onChange={(e) => {
props.updateState({
  shipEmailId: e.target.value,
});
}}
/>

</Col>


</Row>
           </div>
   </div>
    ):''
   }
      </div>
    </div>
  )
}

export default ShippingDetails