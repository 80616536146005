import axios from "axios";
import config from "../../../config/environmentConfig";
import { COMMON_ACTIONS, SMS_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getSubject = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER })
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS
        }/subject/getSubjects?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
        }&pageNumber=${(payload.pageNumber || 1) - 1}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.SUBJECT_LIST,
          data,
        });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_SKELETON })
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      });
  };
};

export const getSubjectCount = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_HEADER_LOADER })

    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/subject/countSubject?relationshipId=${payload.relationshipId}`)
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.SUBJECT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });

      })
      .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      });
  };
};

export const saveSubject = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/subject/saveOrUpdate`,
        payload
      )
      .then((response) => {
        showToasterMessage({
          messageType: "success",
          description: lang[(response || {}).message] || 'Saved Successfully.',
        });
        props.updateHeaderState({
          schoolDrawers: {
            ...props.schoolDrawers,
            addSubjectDrawerVisible: false
          }
        });
        props.getSubject(response.data);
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteSubject = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/subject/delete`, payload)
      .then(res => {
        // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // props.getFees && props.getFees(props);
        getSubject(props)(dispatch);
        //props.getFeeCount && props.getFeeCount(props);
       // showToasterMessage({ messageType: 'success', description: 'Term & Fee deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.SCHOOL_LIST_PAGE_RESET });
  };
};


export const fetchSubjectListByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getPOSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SMS_ACTION_LIST.TEACHER_LIST, data });
      })
      .catch((err) => {
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SMS_ACTION_LIST.SUBJECT_LIST;
          err.data = {
            pageNo: payload.pageNumber || 1,
            list: []
          };
        }
      })
  }
}

export const getParentslistCountByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/countPOSummary?relationshipId=${payload.relationshipId}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: SMS_ACTION_LIST.TEACHER_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SMS_ACTION_LIST.SUBJECT_COUNT;
        }
      })
  }
}