import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewsLetterCompo from '../../components/NewsLetter';
import NewsLetterPropsUpdater from '../../components/drawer/NewsLetterPropsUpdater';
import NewsLetterThemeUpdater from '../../components/drawer/NewsLetterThemeUpdater';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchProjects, getClientRelationshipAdditionalInfo, saveClientRelationshipAdditionalInfo } from './../../actions/commonActions';
import { saveTemplate, uploadImage } from './action';
import { getAllFundraisingCampaign, resetAllFundraisingCampaign } from '../drawer/fundraising/pledges/action';
import config from '../../config/environmentConfig';
import { getAWSFileURL } from '../../utils';
import EmailComponent from '../drawer/communication/account/brodcast-email';
// import BrodcastSMS from '../drawer/communication/account/brodcast-sms';
import { fetchAvailablePaymentGateway } from '../integrations/Payment/action';
import { fetchAllLedgerAccounts } from '../finance/LedgerAccounts/action';
import { getSubdomain } from '../modal/modalBody/settings/CompanyInfo/action';
import { fetchNewsLetter, resetNewsLetter } from '../B2B/NewLetterPreview/action';
import { getAllDG } from'../communication/distribution-group/action';
import * as find from 'lodash.find';
import { getAllFormsBuilder } from '../communication/NewLetterListing/action';

class NewsLetter extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    let eventData = {
      eventTitle: 'Its a Big Event',
      eventDate: new Date(),
      eventTime: '7:00 pm',
      street1: '1002 Main Street',
      street2: 'Suite 232',
      cityName: 'Concord',
      stateName: 'Concord',
      zipCode: '25696'
  }
  
    this.state = {
      // templateType: linkProps.templateType || 'newsletter',
      templateType: linkProps.templateType || this.props.templateType,

     // imageUrl: 'https://bo-relationship.s3.amazonaws.com/amazing-wave-2.jpg',
     imageUrl: getAWSFileURL('101-1130f54bbe-343e-48e8-a9c2-364f60edd7a2-2024.06.13.13.03.26', config.BUCKET_NAME.BO_RELATIONSHIP),
      logoUrl: getAWSFileURL((props.companyInfo || {}).logoAwsObjectKey, config.BUCKET_NAME.BO_RELATIONSHIP),
      childIndex: 10,
      broadcastSMSDrawerVisible: false,
      emailDrawerVisible: false,
      isTemplateInit: false,
      eventDetails: linkProps.templateType === 'event'? { child_6: eventData } : {},
      campaignId: linkProps.fundraisingCampaignId ? Number(linkProps.fundraisingCampaignId) : 0,
      alpidePaymentGatewayId: linkProps.alpidePaymentGatewayId,
      projectMasterId: linkProps.projectMasterId,
      chartOfAccountDetailsId: linkProps.chartOfAccountDetailsId,
      teamMasterId: linkProps.teamMasterId,
      teamMemberId: linkProps.teamMemberId,
      templateFunctionRef: {},
      allChildIds: [],
      isReplicate:props.isReplicate
    };

  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true
    // });
    
    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    let self = this;
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 1000,
      templateId: linkProps.alpideFormBuilderId || this.props.alpideFormBuilderId,
    };
    this.props.resetAllFundraisingCampaign();
    this.props.resetNewsLetter();
    this.props.getClientRelationshipAdditionalInfo(payload);
    this.props.getAllFundraisingCampaign(payload);
    this.props.fetchAvailablePaymentGateway({ relationshipId: companyInfo.relationshipId });
    this.props.fetchProjects({ relationshipId: companyInfo.relationshipId });
    this.props.fetchAllLedgerAccounts({ relationshipId: companyInfo.relationshipId });
    this.props.getSubdomain(payload, function (stat) {self.setState({isSubDomainLoaded : stat})} ); 
    this.props.getAllDG({ relationshipId: companyInfo.relationshipId });
    payload.templateId && this.props.fetchNewsLetter({...payload,isReplicate:true}, this.props);

  }

  componentWillReceiveProps(props) {
    if (props.allCampaignList && props.allCampaignList.length && this.state.campaignId && !this.state.fundraisingCampaignId ) {
      let selectedCampaign = find(props.allCampaignList || [], { fundraisingCampaignId: this.state.campaignId }) || {};

      this.setState({
        selectedCampaign: selectedCampaign,
        fundraisingCampaignId: selectedCampaign.fundraisingCampaignId,
        templateName: selectedCampaign.campaignName,
      });
    }
    if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
      this.setState({
        subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
      });
    }
    if (props.newsLetterTemplate && props.newsLetterTemplate.alpideFormBuilderId && !this.state.alpideFormBuilderId) {
      let templateFunctionRef = JSON.parse(props.newsLetterTemplate.templateFunctionRef || '{}');

      let eventData = {
        eventTitle: props.newsLetterTemplate.eventTitle || '',
        eventDate: props.newsLetterTemplate.eventDate? new Date(props.newsLetterTemplate.eventDate) : new Date(),
        eventTime: props.newsLetterTemplate.eventTime || '',
        street1: props.newsLetterTemplate.eventStreet1 || '',
        street2: props.newsLetterTemplate.eventStreet2 || '',
        cityName: props.newsLetterTemplate.eventCity || '',
        stateName: props.newsLetterTemplate.eventState || '',
        zipCode: props.newsLetterTemplate.eventZip || '',
        eventHostedBy: props.newsLetterTemplate.eventHostedBy || ''
    }
      
      this.setState({
        isTemplateInit: false,
        alpideFormBuilderId: props.newsLetterTemplate.alpideFormBuilderId,
        templateName: props.newsLetterTemplate.templateName,
        childIndex: Number(props.newsLetterTemplate.childIndex) || this.state.childIndex,
        allChildIds: Object.keys(templateFunctionRef),
        templateFunctionRef,
        eventDetails: props.newsLetterTemplate.templateType === 'event'? { child_6: eventData } : {},
      });
    }
  }

  render() {
    return (
      <>
        {/* {this.state.broadcastSMSDrawerVisible && <BrodcastSMS {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />} */}
        {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.isNewsLetterPropsUpdaterVisible && <NewsLetterPropsUpdater  {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />}
        {this.state.isNewsLetterThemeUpdaterVisible && <NewsLetterThemeUpdater  {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />}
        { (( ((this.state.templateType === 'fundraising' && this.state.fundraisingCampaignId) || 
        this.state.templateType === 'event') && this.state.isSubDomainLoaded) || 
        (this.state.templateType !== 'fundraising' && this.state.templateType !== 'event')) ? 
        <NewsLetterCompo {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> 
        : ''}
      </>

    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    allCampaignList: state.fundraisingReducer.allCampaignList,
    userInfo: state.common.userInfo,
    availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
    projectList: state.common.projectList,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    subdomain: state.common.subdomain,
    newsLetterTemplate: state.membershipReducer.newsLetterTemplate,
    dgList: state.communicationReducer.dgList,
    companyAdditionalInfo: state.common.companyAdditionalInfo,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  saveTemplate,
  uploadImage,
  getAllFundraisingCampaign,
  resetAllFundraisingCampaign,
  fetchProjects, 
  fetchAllLedgerAccounts,
  fetchAvailablePaymentGateway,
  getSubdomain,
  fetchNewsLetter,
  resetNewsLetter,
  getAllDG,
  getClientRelationshipAdditionalInfo, 
  saveClientRelationshipAdditionalInfo,
  getAllFormsBuilder
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetter);
