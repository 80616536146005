import React, { Fragment} from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ICONS } from "../../../../../static/constants";
import { Empty, Pagination, Skeleton, Switch } from 'antd';
import { getCurrencySymbol, getMomentDateForUIReadOnly } from '../../../../../utils';

const CouponListComp = (props) => {
    const { couponUsageList, updateState, companyInfo, dir=0, pageNumber, couponUsageCount, pageSize } = props;

    const sortColumn = () => {
        updateState({ dir: 1 - dir })
    }

    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            }) +
            ` ${total} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        props.getAllCouponUsage(payload);
        props.getCouponUsageCount(payload);
    };

    const handlePageSizeChange = (pageNumber, pageSize) => {
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        props.getAllCoupons(payload);
        props.getAllCouponsCount(payload);
    };

    return (
        <div className="view-container coupon-container">
            <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id='eStore.coupons.table.label' defaultMessage='' />
                        </div>
                        {
                            couponUsageCount ? <>
                            <div className="vertical-sep" />
                            <div>{couponUsageCount}</div>
                            </>:''
                        }
                    </div>
                    <div className="right-actions">
                    </div>
                </Skeleton>
            </div>
            <Skeleton loading={props.listLoading} paragraph={{ rows: 7 }} active>
                <div className='table-container'>
                    <table id='coupons-table'>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage id="serial.no" />
                            </th>
                            <th onClick={sortColumn}>
                                <FormattedMessage id="eStore.coupons.table.couponCode.header" defaultMessage="" /> <i className={ICONS.SORT} />
                            </th>
                            <th onClick={sortColumn}>
                                <FormattedMessage id="eStore.coupons.table.header.orderNumber" defaultMessage="" /> <i className={ICONS.SORT} />
                            </th>
                            <th>
                                <FormattedMessage id="eStore.coupons.table.discount.header" defaultMessage="" />
                            </th>
                            {/* <th>
                                <FormattedMessage id="eStore.coupons.table.minPurchase.header" defaultMessage="" />
                            </th> */}
                            <th>
                                Customer
                            </th>
                            <th>
                                Date Created
                            </th>
                        </tr>
                    </thead>
                        <tbody>
                            {couponUsageList && couponUsageList[pageNumber]?.length ? couponUsageList[pageNumber].map((rowData, i)=> (
                                <tr key={"coupon" + i}>
                                    <td width={"5%"}>{(pageNumber - 1) * pageSize + (i + 1)}</td>
                                    <td>{rowData.couponCode}</td>
                                    <td className='table-link'>{rowData.orderId}</td>
                                    <td>{rowData.discountAmount ? `${getCurrencySymbol(rowData.currencyCode)}${rowData.discountAmount}` : <div className="empty-data" /> }</td>
                                    {/* <td></td> */}
                                    <td>{rowData.customerName}</td>
                                    <td>{rowData?.createdDate ? getMomentDateForUIReadOnly({date: rowData.createdDate}) : ''}</td>
                                </tr>
                            ))
                            :
                            <tr key="empty-data-box">
                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                    <Empty />
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Skeleton>
            <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={couponUsageCount}
                            showTotal={showTotal}
                            current={props.pageNumber || 1}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props)
                            }}
                        />
                    </div>
                </Skeleton>
            </div>
        </div>
    );
}

export default injectIntl(CouponListComp);
