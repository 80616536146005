import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesChannelWalmartComponent from '../../../components/salesChannels/walmart';
import { fetchLinkedMarketplace, saveMWSToken, saveMarketplaceConfiguration, disconnectConfiguration } from '../../../containers/integrations/SalesChannels/action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import config from '../../../config/environmentConfig';
import queryString from 'query-string';
import { getCompanyInfo,fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
import { getPublishQuantity, getUnPublishQuantity, getTodayListing, getThisWeekListing, getThisMonthListing, getTotalSales, getInventoryItems,getInventoryItemsCount,resetPaginatedData } from './action';

class SalesChannelWalmart extends Component {
  
  constructor(props) {
    super(props);
   // const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      marketplaceConfig: {}
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: false
    });
    const linkProps = queryString.parse(this.props.location.search) || {};
    const companyInfo = this.props.companyInfo || getCompanyInfo() || {};
    const payload = {
        relationshipId: companyInfo.relationshipId,
    };
    if (linkProps.amazon_callback_uri) {
        //  this.props.saveMWSToken({...linkProps, relationshipId: companyInfo.relationshipId});
        window.location = linkProps.amazon_callback_uri + `?redirect_uri=${config.REDIRECT_URI_FOR_AMAZON}&amazon_state=${linkProps.amazon_state}&state=example&version=beta&selling_partner_id=${linkProps.selling_partner_id}`
    }
    if (linkProps.spapi_oauth_code) {
        //send code and partener Id to backend
        this.props.saveMWSToken({ ...linkProps, relationshipId: companyInfo.relationshipId });
    }
    this.props.fetchLinkedMarketplace(payload);
   
    fetchPaginationDataIfNeeded("getInventoryItems", "inventoryItemsListing", this.props, payload);
    fetchDataIfNeeded("getInventoryItemsCount", "inventoryItemsCount", this.props, payload);
    fetchDataIfNeeded("getPublishQuantity", "walmartPublishedQty", this.props, payload);
    fetchDataIfNeeded("getUnPublishQuantity", "walmartUnPublishedQty", this.props, payload);
    fetchDataIfNeeded("getTodayListing", "walmartTodayListing", this.props, payload);
    fetchDataIfNeeded("getThisWeekListing", "walmartThisWeekListing", this.props, payload);
    fetchDataIfNeeded("getThisMonthListing", "walmartThisMonthListing", this.props, payload);
    fetchDataIfNeeded("getTotalSales", "walmartTotalSales", this.props, payload);
  }

  componentWillReceiveProps(props) {
    if (props.linkedMarketplaceList && props.linkedMarketplaceList.length) {
      let marketplaceConfig = {};
      props.linkedMarketplaceList.map((marketplace) => {
          marketplaceConfig[marketplace.marketplaceName] = marketplace;
          return '';
      })
      this.setState({ marketplaceConfig })
  }
    
  }

  render() {
    return (
      <div>
        <SalesChannelWalmartComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    linkedMarketplaceList: state.integration.linkedMarketplaceList,
    inventoryItemsListing: state.marketplaceSalesChannelReducer.inventoryItemsListing,
    inventoryItemsCount: state.marketplaceSalesChannelReducer.inventoryItemsCount,
    walmartPublishedQty: state.marketplaceSalesChannelReducer.walmartPublishedQty,
    walmartUnPublishedQty: state.marketplaceSalesChannelReducer.walmartUnPublishedQty,
    walmartTodayListing: state.marketplaceSalesChannelReducer.walmartTodayListing,
    walmartThisWeekListing: state.marketplaceSalesChannelReducer.walmartThisWeekListing,
    walmartThisMonthListing: state.marketplaceSalesChannelReducer.walmartThisMonthListing,
    walmartTotalSales: state.marketplaceSalesChannelReducer.walmartTotalSales,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchLinkedMarketplace, 
  saveMWSToken, 
  saveMarketplaceConfiguration, 
  disconnectConfiguration,
  getPublishQuantity, getUnPublishQuantity, getTodayListing, getThisWeekListing, getThisMonthListing, getTotalSales, getInventoryItems, getInventoryItemsCount,resetPaginatedData,
  showModal, hideModal, pushModalToStack, popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesChannelWalmart);
