import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DesignationDrawer from '../../../../../components/drawer/company/EmployeeMeta/Designation';
import { createDesignation } from '../../../../company/EmployeeMeta/Designation/action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { getDepartment } from '../../../../company/EmployeeMeta/Department/action';

class DepartmentDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(this.props.designationItem || {})

        };
    }

componentDidMount(){
    const companyInfo = this.props.companyInfo || {};
    const payload = {
        relationshipId: companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: 25,
      };
  this.props.getDepartment(payload);

}


    render() {
        return <DesignationDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        departmentList: state.employee.departmentList,
        drawerLoading: state.common.drawerLoading,


    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createDesignation,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    getDepartment

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDrawer);