import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RemarksTemplateComponent from '../../../../../components/modal/modalBody/settings/RemarksTemplate';
import { fetchRemarksTemplate, saveOrUpdateThankyouEmailTemplate } from './action';
import { pushModalToStack } from "../../../../../actions/commonActions";
class RemarksTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectDocumentOptions: window.location.host === 'app.alifapp.com' ? ['Inquiry', 'Sales Quotation', 'Sales Order', 'Sales Invoice', 'Sales Payment', 'Credit Memo', 'Request for Quote', 'Purchase Order', 'Purchase Invoice', 'Purchase Payment', 'Debit Memo', 'Online Payment', 'Donation Invoice', 'Text2Donate Invoice'] :
                ['Inquiry', 'Sales Quotation', 'Sales Order', 'Sales Invoice', 'Sales Payment', 'Credit Memo', 'Request for Quote', 'Purchase Order', 'Purchase Invoice', 'Purchase Payment', 'Debit Memo', 'Online Payment', 'Donation Invoice'],
            // selectDocumentOptions: ['Sales Order', 'Credit Memo', 'Debit Memo', 'Sales Invoice', 'Purchase Invoice', 'Purchase Order', 'Payment Received', 'Supplier Payment', 'Shipping Notification', 'Acknowledgement', 'Sales Quote', 'Request for Quote'],
            docName: '',
            subject: ''

        }
    }
    // componentWillMount() {
    //     const companyInfo = this.props.companyInfo || {};
    //     const payload = { relationshipId: companyInfo.relationshipId, docName: 'Sales Order'};
    //     fetchDataIfNeeded('fetchRemarksTemplate', 'remarksData', this.props, payload, true);

    // }
    componentWillReceiveProps(nextProps) {
        setTimeout(() => {
            if (nextProps.remarksData !== this.state.remarksData && nextProps.remarksData) {
                this.setState({ content: nextProps.remarksData.content || '' });
                this.setState({ footer: nextProps.remarksData.footer || '' });
            }
        }, 500);
    }

    render() {
        return <div>
            <RemarksTemplateComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        remarksData: state.settings.remarksData
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchRemarksTemplate,
    saveOrUpdateThankyouEmailTemplate,
    pushModalToStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RemarksTemplate);