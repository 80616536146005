import React, {Fragment} from "react";
//import { Form } from '@ant-design/compatible';
import { injectIntl, FormattedMessage } from 'react-intl';
import './index.scss';
import { Row, Col } from "antd";
import { LogoComponent } from '../../general/LogoComponent';
import config from '../../../config/environmentConfig';
import { CONSTANTS } from '../../../static/constants';
import ProccessPaymnet from '../../../containers/B2B/Donation/ProccessPayment';

const Donation = (props) => {
    const { donationSettings = {}, companyInfo } = props;
    const getAddressInfoFragment = (obj, additionInfo) => {
        const fragmentObj = <Fragment>
            {
                obj.streetAddress1
                    ? <span className='billing-address'>{obj.streetAddress1}
                    </span>
                    : ''
            }
            {
                obj.streetAddress2
                    ? <span className='billing-address'> {obj.streetAddress2}
                    </span>
                    : ''
            }
            {
                obj.cityName
                    ? <div className='billing-address'>
                        {
                            `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                        }
                    </div>
                    : ''
            }
            {
                additionInfo.workPhone
                    ? <div className='billing-address'>
                        <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                    </div> :
                    ''
            }
            {
                additionInfo.email
                    ? <div className='billing-address'>
                        <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                    </div> :
                    ''
            }
        </Fragment>
        return fragmentObj;
    }
    const proccessPayment = (props, project) => {
        props.pushModalToStack({
            title: 'Make Payment for '+ project.displayName,
            modalBody: <ProccessPaymnet {...props} projectData={project} />,
            width: '50%',
            hideFooter: true,
        })
    }

    return (
        <div style={{ padding: '0px 0px 100px' }} >
              {/* <Row gutter={24} style={{ background: donationSettings.bgColor, color: donationSettings.textColor, padding: '5px' }} className='ltiuy'> */}
              <Row gutter={24} style={{background: props.onlineThemes.bgColor || '#06033A', color: props.onlineThemes.textColor || '#FFFFFF', padding: '5px', marginLeft: '0px', marginRight: '0px', marginBottom: '15px' }} className='ltiuy'>   
                <Col span={2} className='litlyu'> </Col>
                <Col span={2}>
                    <div className="brand-logo">
                        {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                    </div>
                </Col>
                <Col span={12} style={{ alignSelf: 'center' }}>
                    <div className='logosizef'> {companyInfo.storeName}</div>
                </Col>
                <Col span={8} style={{ alignSelf: 'center' }} className='newaddr'>
                    {getAddressInfoFragment(props.relationshipBillingAddress, companyInfo)}
                </Col>
            </Row>
            <div className='main-container'>
                {props.formHeading ? <div className="modal-header align-left titr">
                    <div>{props.formHeading}</div>
                </div> : ''}
                <div className='conte'>
                    <ul>
                        {
                            (Object.values(donationSettings.selectedProjects) || []).map((project, i) => {
                                return (
                                <li><span onClick={()=>proccessPayment(props, project)} className={i === 0 ? "blue" : 'colo' + i.toString().slice(0, 1)}>{project.displayName || ''}</span></li>
                                )
                            })
                        }
                        {/* <li><a href="#" className="blue">First 10 days of Dhul Hijjah 2021</a></li>
                        <li><a href="#" className='colo1'>MCC General Operations/ Ramadan 2021</a></li>
                        <li><a href="#" className='colo2'>Zakat al Fitr - $10/Person</a></li>
                        <li><a href="#" className='colo3'>Sadaqah </a></li>
                       
                        <li><a href="#" className='colo5'>Zakat al-Mal</a></li>
                        <li><a href="#" className='colo6'>Sunday School & Quran Class 2020-2021</a></li>
                        <li><a href="#" className='colo7'>Friday Sales</a></li>
                        <li><a href="#" className='colo4'>Repay Infrastructure Project Loan (Parking & Retention Pond)</a></li> */}
                    </ul>
                    <div className="clearfix"></div>
                    </div>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <div>{donationSettings.footer || ''}</div>
                </div>
            </div>
        </div>
        
    )
   
};

export default injectIntl(Donation);
