import { LANDING_COST_ACTION_LIST } from '../static/constants';
const initialState = {
  lcPurchaseInvoiceList: [],
  lcGRNInvoiceList: [],
  lcTxnList: [],
  lcPurchaseDetail: {},
  lcGRNDetail: {},
}

const LandingCostReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANDING_COST_ACTION_LIST.LC_PURCHASE_INVOICE_LIST:
      return { ...state, lcPurchaseInvoiceList: action.data };
    case LANDING_COST_ACTION_LIST.LC_GRN_INVOICE_LIST:
      return { ...state, lcGRNInvoiceList: action.data };
    case LANDING_COST_ACTION_LIST.LC_TRANSACTION_LIST:
      return { ...state, lcTxnList: action.data };
    case LANDING_COST_ACTION_LIST.PURCHASE_INVOICE_DETAIL:
      return { ...state, lcPurchaseDetail: action.data };
    case LANDING_COST_ACTION_LIST.GRN_INVOICE_DETAIL:
      return { ...state, lcGRNDetail: action.data };
    default:
      return state;
  }
};

export default LandingCostReducer;