/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Input, Checkbox, Row, Col, Popover } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';

import { CustomButton } from '../../general/CustomButton';
//import { CustomUploader } from '../../general/CustomUploader';
import '../../../assets/stylesheets/common/_email_template.scss';
import {  validateEmail, showToasterMessage } from './../../../utils';
import { EMAIL_BODY_CONFIGURATION } from '../../../static/ckEditorConfigConstants';
import { PLACEHOLDER_CONSTANTS } from '../../../static/placeholderConstants';
import CKEditor from '../../general/CustomCKEditor/ckeditor';
import filter from 'lodash.filter';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import {  Spin } from 'antd';
import uniq from 'lodash.uniq';
//import { max } from 'moment-timezone';
const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
const { Option } = Select;


class EmailBody extends Component {

  constructor(props) {
    super(props);
    // let fileList = [{
    //     uid: '-1',
    //     name: (props.txData || {}).fileName || "Document.pdf",
    //     status: 'done',
    //     url: this.props.txFileUrl
    //   }];
    //   if(props.txData2){
    //     fileList.push({
    //       uid: '-2',
    //       name: (props.txData2 || {}).fileName || "Document.pdf",
    //       status: 'done',
    //       url: this.props.txFileUrl2
    //     })
    //   }
    this.state = {
      sender: props.userInfo.email || '',
      receivers: props.receivers || [],
      ccRecievers: props.ccRecievers || [],
      bccRecievers: props.bccRecievers || [],
      subject: props.subject,
      body: props.body || props.emailBody,
      emailBody: props.body || props.emailBody,
      ccVisible: props.ccVisible || false,
      bccVisible: props.bccVisible || false,
      isSendInvoicePdf: props.isSendInvoicePdf || 0,
      updateReminder: props.updateReminder,
      docName: props.docName
    };

    this.uploadButtonRef = React.createRef();

    this.formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrappercol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };

    this.tailFormItemLayout = {
      wrappercol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 22,
          offset: 2,
        },
      },
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...(this.props.emailPayloadProps || {}),
          sender: values.sender,
          to: Array.isArray(values.receivers) ? values.receivers: [values.receivers],
         // cc: Array.isArray(values.ccRecievers) ? values.ccRecievers: [values.ccRecievers],
         // bcc: Array.isArray(values.bccRecievers) ? values.bccRecievers: [values.bccRecievers],
          subject: values.subject,
          message: this.state.body ,
          sendMeACopy: values.sendMeACopy ? 1 : 0
        };
        if (values.ccRecievers) {
          payload.cc = Array.isArray(values.ccRecievers) ? values.ccRecievers : [values.ccRecievers];
        }
        if (values.bccRecievers) {
          payload.bcc = Array.isArray(values.bccRecievers) ? values.bccRecievers : [values.bccRecievers];
        }
        this.props.updateState({ feeReminderEmailDetails: JSON.stringify(payload), feeReminderEmailDrawerVisible: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const receiverItems = [];
    const ccRecievers = [];
    const bccRecievers = [];

    uniq(this.props.receiverItems).forEach((data, i)=>{
      receiverItems.push(<Option value={data}>{data}</Option>);
    });

    uniq(this.props.ccRecievers).forEach((data, i)=>{
      ccRecievers.push(<Option key={data}>{data}</Option>);
    });

    uniq(this.props.bccRecievers).forEach((data, i)=>{
      bccRecievers.push(<Option key={data}>{data}</Option>);
    });

    // this.defaultFileList = [
    //   {
    //     uid: '-1',
    //     name: (this.props.txData || {}).fileName || 'Document.pdf',
    //     status: 'done',
    //     url: this.props.txFileUrl,
    //     blobData: this.props.txFile
    //   }
    // ]
    // if (this.props.txData2) {
    //   this.defaultFileList.push({
    //     uid: '-2',
    //     name: (this.props.txData2 || {}).fileName || 'Document2.pdf',
    //     status: 'done',
    //     url: this.props.txFileUrl2,
    //     blobData: this.props.txFile2
    //   })
    // }
    const insertTextInSubject = (props, textToInsert) => {
      if (this.state.subject) {
          let subject = this.state.subject.slice(0, this.state.subjectCursor) + textToInsert + this.state.subject.slice(this.state.subjectCursor + 1);
          this.props.form.setFieldsValue({ subject });
          this.setState({ subject: subject, subjectCursor: this.state.subjectCursor+textToInsert.length })
      } else {
        this.setState({ subject: textToInsert, subjectCursor: this.state.subjectCursor+textToInsert.length })
      }
  }
    const getSubjectPlaceHolders = (props, editorName ) => {
      return <div style={{ width: '400px' }}>
          <Row type='flex'>
            <Col span={8}>
              <div style={{ fontWeight: '500' }}>Invoice</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
            </Col> 
              <Col span={8}>
                  <div style={{fontWeight: '500'}}>Customer</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_NAME);}}>Customer Name</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALUTATION);}}>Salutation</div>
                  {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_BALANCE);}}>Customer Amount</div> */}
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE);}}>Website</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.FIRST_NAME);}}>First Name</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.LAST_NAME);}}>Last Name</div>
                  {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DEPARTMENT);}}>Department</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DESIGNATION);}}>Designation</div> */}
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.EMAIL);}}>Email</div>
              </Col>
              <Col span={8}>
                  <div style={{fontWeight: '500'}}>Organization</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STORE_NAME);}}>Name</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.USER);}}>User</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_EMAIL);}}>Email</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_PHONE);}}>Phone #</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_FAX);}}>Fax #</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE);}}>Website</div>
              </Col>
          </Row>
      </div>
  }
  
  const getPlaceHolders = (props, editorName ) => {
      return <div style={{ width: '400px' }}>
          <Row type='flex'>
          {(props.docName !== 'registration') ?
            <Col span={8}>
              <div style={{ fontWeight: '500' }}>Invoice</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
            </Col> : ''}
              <Col span={8}>
                  <div style={{fontWeight: '500'}}>Customer</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME);}}>Customer Name</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.SALUTATION);}}>Salutation</div>
                  {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_BALANCE);}}>Customer Amount</div> */}
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.WEBSITE);}}>Website</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.FIRST_NAME);}}>First Name</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.LAST_NAME);}}>Last Name</div>
                  {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DEPARTMENT);}}>Department</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DESIGNATION);}}>Designation</div> */}
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.EMAIL);}}>Email</div>
              </Col>
              <Col span={8}>
                  <div style={{fontWeight: '500'}}>Organization</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.STORE_NAME);}}>Name</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.USER);}}>User</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ORG_EMAIL);}}>Email</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ORG_PHONE);}}>Phone #</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ORG_FAX);}}>Fax #</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.WEBSITE);}}>Website</div>
              </Col>
          </Row>
      </div>
  }
  

    return (
        <div className="modal-content" >
          <Spin indicator={antIcon} className="spinner" tip="Loading..." spinning={this.props.isEmailSpinner} >
          <Form className="email-form-group" {...this.formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
            <div className="scrollable-content">
            {(!this.props.isFormSetting && !this.props.isBulkInvoice) &&
              <Form.Item label='From' colon={false} >
                {getFieldDecorator('sender', {
                  initialValue: this.props.userInfo.email || '',
                  rules: [
                    {
                      required: true,
                      message: 'From is required.',
                    }
                  ],
                })(<Input />)}
              </Form.Item> }

              {(this.props.isBulkInvoice) &&
              <div className="email-to-control">
                <Form.Item label='To' colon={false} >
                  {getFieldDecorator('receivers',{initialValue: this.props.intl.formatMessage( { id: 'send.email.to.default.contact.message' })})(
                      <Input disabled
                      /> 
                  )}
                </Form.Item>
                </div> 
              }
             
{(!this.props.isFormSetting && !this.props.isBulkInvoice) &&
              <div className="email-to-control">
                <Form.Item label='To' colon={false} >
                  {getFieldDecorator('receivers', {
                    rules: [
                      {
                        required: true,
                        message: 'Enter valid email address',
                        validator: (rule, value, cb) => {
                          let isInValidEmail = false;
                          if (!this.state.receivers || !this.state.receivers.length) {
                            return cb(rule.message);
                          }
                          (this.state.receivers || []).forEach((v) => {
                            if (!validateEmail(v)) {
                              isInValidEmail = true
                            }
                          });
                          if (isInValidEmail) {
                            return false;
                          }

                          if (rule.required) {
                            if (value.length) {
                              return cb();
                            } else {
                              return cb(rule.message);
                            }
                          }
                          return cb(rule.message);
                        },
                        
                      }
                    ],
                    initialValue: this.state.receivers || []
                  })(
                      <div className="to-input-control">
                        <Select
                        tokenSeparators={[',']}
                            bordered={false}
                            onDeSelect={
                              (value) =>{
                                let receivers = Object.assign([], this.state.receivers);
                                receivers = filter(receivers, (obj) => {
                                    return obj !== value;
                                });
                                this.props.form.setFieldsValue({ receivers });
                                this.setState({ receivers });
                              }
                            }
                            onSelect={(selectedValue) => {
                              const receivers = Object.assign([], this.state.receivers);
                              receivers.push(selectedValue);
                              this.props.form.setFieldsValue({ receivers });
                              this.setState({ receivers });
                            }}
                            onChange={(e)=>{
                              this.setState({ receivers: e });
                              this.props.form.setFieldsValue({ receivers: e });
                            }}
                            mode="tags"
                            defaultValue={this.state.receivers}
                        >
                          {receiverItems}
                        </Select>

                        <div className="cc-bcc-button">
                          <a onClick={
                            () => {
                              this.setState({ ccVisible: !this.state.ccVisible });
                              if (this.state.ccVisible) {
                                this.setState({ ccRecievers: [] });
                              }
                            }
                          }>
                            {this.state.ccVisible ? 'Hide CC' : 'Show CC'}
                          </a>
                          <span className="prl5">{' | '}</span>
                          <a onClick={
                            () => {
                              this.setState({ bccVisible: !this.state.bccVisible });
                              if (this.state.bccVisible) {
                                this.setState({ bccRecievers: [] });
                              }
                            }
                          }>
                            {this.state.bccVisible ? 'Hide BCC' : 'Show BCC'}
                          </a>
                        </div>
                      </div>  
                  )}
                </Form.Item>

                {
                  this.state.ccVisible
                      ? (
                          <Form.Item label='CC' colon={false} >
                            {getFieldDecorator('ccRecievers', {})(
                              <div className="to-input-control">
                              <Select
                                  bordered={false}
                                  onChange={(val)=>{
                                    this.setState({
                                      ccRecievers: val
                                    });
                                    this.props.form.setFieldsValue({ ccRecievers: val });
                                  }}
                                  onSelect={(selectedValue) => {
                                    const ccRecievers = Object.assign([], this.state.ccRecievers);
                                    ccRecievers.push(selectedValue);
                                    this.props.form.setFieldsValue({ ccRecievers });
                                    this.setState({ ccRecievers });
                                  }}
                                  mode="tags"
                                  defaultValue={this.state.ccRecievers}
                              >
                                {ccRecievers}
                              </Select>
                              </div>
                            )}
                          </Form.Item>
                      )
                      : ''
                }

                {
                  this.state.bccVisible
                      ? (
                          <Form.Item label='BCC' colon={false} >
                            {getFieldDecorator('bccRecievers', {})(
                              <div className="to-input-control">
                              <Select
                                  bordered={false}
                                  onChange={(val)=>{
                                    this.setState({
                                      bccRecievers: val
                                    });
                                    this.props.form.setFieldsValue({ bccRecievers: val });
                                  }}
                                  onSelect={(selectedValue) => {
                                    const bccRecievers = Object.assign([], this.state.bccRecievers);
                                    bccRecievers.push(selectedValue);
                                    this.props.form.setFieldsValue({ bccRecievers });
                                    this.setState({ bccRecievers });
                                  }}
                                  mode="tags"
                                  defaultValue={this.state.bccRecievers}
                              >
                                {bccRecievers}
                              </Select>
                              </div>
                            )}
                          </Form.Item>
                      )
                      : ''
                }
              </div> 
              }
              <div className="top-button" style={{cursor: 'pointer', marginTop:'4px', padding:'4px'}}>
                <Popover content={getSubjectPlaceHolders(this.state, 'editorSubject')} placement="bottom" trigger="click" onClick={() => { this.setState({ isSubjectPopoverVisible: !this.state.isSubjectPopoverVisible, isPopoverVisible: false }); }}>
                  Placeholder <i className='fa fa-caret-square-o-down' />
                </Popover>
              </div>
              <Form.Item label={<div>
                Subject
              </div>} colon={false} className="subject-form-control">
                {getFieldDecorator('subject', {
                  initialValue: this.state.subject || this.props.subject,
                  rules: [
                    {
                      required: true,
                      message: 'Subject is required.',
                    },
                    { max: 500, message: 'Subject cannot be greater than 500 characters' },
                  ],
                })(<Input onChange={(e) => {
                  this.setState({ subject: e.target.value, subjectCursor: e.target.selectionStart });}}
                  onClick={(e) => {
                    this.setState({
                        subjectCursor: e.target.selectionStart,
                        isPopoverVisible: false, isSubjectPopoverVisible: false
                    }); 
                }}
                />)}
              </Form.Item>
              <div className="top-button" style={{cursor: 'pointer', position:'absolute', top:'90px', right:'10px', zIndex:'91'}}>
                <Popover content={getPlaceHolders(this.state, 'editor')} placement="bottom" trigger="click" onClick={() => { this.setState({ isPopoverVisible: !this.state.isPopoverVisible, isSubjectPopoverVisible: false }); }}>
                  Placeholder <i className='fa fa-caret-square-o-down' />
                </Popover>
              </div>
              <Form.Item colon={false} className="email-editor">
                {getFieldDecorator('body', {
                  rules: [
                    { required: true, 
                      message: 'Message is required.',
                      
                    }
                  ],
                  initialValue: this.state.emailBody || this.props.emailBody,
                })(
                    <CKEditor
                        // editor={ClassicEditor}
                        // config={FULL_CONFIGURATION}
                        key={`${"emailBody"}`}
                        data={this.state.body}
                        onInit={editor => {
                          this.editor = editor;
                        }}
                        onChange={(event) => {
                          const data = event.editor.getData();
                          this.setState({ body: data });
                        }}
                        onAttachmentClick={() => {
                          this.uploadButtonRef && this.uploadButtonRef.current
                          && ReactDOM.findDOMNode(this.uploadButtonRef.current).click()
                        }}
                        config={EMAIL_BODY_CONFIGURATION}
                    />
                )}
              </Form.Item>

              {/* <Form.Item className="copy-checkbox">
                {getFieldDecorator('sendMeACopy', {
                })(
                    <Fragment>
                      <Checkbox id="sendCopy" checked={this.props.isSendCopy} onChange={(e) =>{
                        this.props.updateState({
                          isSendCopy: e.target.checked
                        })
                      }} />
                      <label className="pl10" htmlFor="sendCopy">Send me a copy</label>
                    </Fragment>
                  )}
              </Form.Item> */}
              {!this.props.isFormSetting &&
                <Form.Item className="copy-checkbox">
                  {getFieldDecorator('isSendInvoicePdf', {
                  })(
                    <Fragment>
                      <Checkbox id="isSendInvoicePdf" checked={this.state.isSendInvoicePdf} onChange={(e) => {
                        this.setState({
                          isSendInvoicePdf: e.target.checked
                        })
                      }} />
                      <label className="pl10" htmlFor="isSendInvoicePdf"><FormattedMessage id='attachInvoicePdf.text' defaultMessage='' /></label>
                    </Fragment>
                  )}
                </Form.Item>
              }

            </div>
            <div {...this.tailFormItemLayout} className="footer-btn-group mt15">
              <CustomButton
                  type='primary'
                  intlId='button.save.label'
                  defaultMessage=''
                  onClick={() => {
                    this.props.form.validateFieldsAndScroll((err, values) => {
                      if (!err) {
                        if(!this.props.isFormSetting && !this.props.isBulkInvoice && (!this.state.receivers || !this.state.receivers.length )){
                          showToasterMessage({ messageType: 'error', description:'To email id is required.'});
                          return ;
                        } 
                        if( !this.state.subject && !this.props.subject){
                          showToasterMessage({ messageType: 'error', description:'Subject is required.'});
                          return ;
                        } 
                        if(!this.state.body){
                          showToasterMessage({ messageType: 'error', description:'Message is required.'});
                          return ;
                        } 
                        const payload = {
                          sender: this.state.sender,
                          to: Array.isArray(this.state.receivers) ? this.state.receivers: [this.state.receivers],
                          subject: this.state.subject || this.props.subject,
                          message: this.state.body ,
                         // sendMeACopy: this.state.sendMeACopy ? 1 : 0
                         isSendInvoicePdf: this.state.isSendInvoicePdf ? 1: 0
                        };
                        if (this.state.ccRecievers) {
                          payload.cc = Array.isArray(this.state.ccRecievers) ? this.state.ccRecievers : [this.state.ccRecievers];
                        }
                        if (this.state.bccRecievers) {
                          payload.bcc = Array.isArray(this.state.bccRecievers) ? this.state.bccRecievers : [this.state.bccRecievers];
                        }
    
                        if (this.state.updateReminder) {
                          this.state.updateReminder(JSON.stringify(payload));
                          this.props.updateState({  feeReminderEmailDrawerVisible: false });
                        } else if (this.props.isFormSetting) {
                          this.props.updateState({ feeReminderEmailDetails: payload, feeReminderEmailDrawerVisible: false });
                        }else {
                          this.props.updateState({ feeReminderEmailDetails: JSON.stringify(payload), feeReminderEmailDrawerVisible: false });
                        }
                      }
                    });
                   
                  }}
              />
            </div>
          </Form>
          </Spin>
        </div>
    );
  }
}

export default Form.create({ name: 'Email_Template' })(injectIntl(EmailBody));
