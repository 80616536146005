import React from "react";
import DevelopingPic from '../../../assets/images/working.gif';
import CommingSoon from '../../../assets/images/Framenotfound.png';
import './index.scss';
import InPageMenu from "../InPageMenu";

const NotFound = (props) =>{


    return <div className="main-div">
      

        {/* <img src={CommingSoon} alt="Under Development" className="img1"/> */}
        <img src={CommingSoon} alt="Under Development" className="img2"/>
    </div>
}

export default NotFound;