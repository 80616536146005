import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LedgerAccountsComponent from '../../../components/finance/LedgerAccounts';
import { fetchAllLedgerAccounts, fetchAllLedgerAccountGroup, updateLedger, fetchAllCategories, fetchAllCategoryGroups, deleteLedgerAccount } from './action';
import { fetchDataIfNeeded, getMomentDateForUI, getFinancialYear } from '../../../utils';
import { hideModal, showModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { CONSTANTS } from '../../../static/constants';
import LedgerAccountsDrawer from '../../drawer/finance/ledgers/index'

class LedgerAccountsComp extends Component {

    constructor(props) {
        super();
        const financialYear = getFinancialYear(props.companyInfo);
        this.state = {
            searchedText: '',
            startDate: getMomentDateForUI({date: new Date(financialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT}) ,
            endDate: getMomentDateForUI({date: new Date(), format:  CONSTANTS.BACKEND_FORMAT}) ,
            ledgerAccountsDrawerVisible: false,
        }
    }

    componentWillMount() {
        // this.props.updateHeaderState({
        //     collapsed: true
        // });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId
        };
        fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
    }



    render() {
        return <>

            {this.props.ledgerAccountsDrawerVisible|| this.state.ledgerAccountsDrawerVisible && (
              <LedgerAccountsDrawer
                {...this.state}
                {...this.props}
                updateState={(data) => {
                  this.setState(data);
                }}
                updateDrawerState={(data) => { this.setState(data) }}
              />
            )}
            <LedgerAccountsComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }}  />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        isLoading: state.common.isLoading,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        allLedgerAccountGroups: state.ledger.allLedgerAccountGroups,
        allCategories: state.ledger.allCategories,
        allCategoryGroup: state.ledger.allCategoryGroup,
        showAllLedgerAccounts: state.ledger.showAllLedgerAccounts,
        showAllLedgerAccountGroups: state.ledger.showAllLedgerAccountGroups,
        showAllCategories: state.ledger.showAllCategories,
        showAllCategoryGroup: state.ledger.showAllCategoryGroup,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllLedgerAccounts,
    fetchAllLedgerAccountGroup,
    fetchAllCategories,
    fetchAllCategoryGroups,
    hideModal,
    showModal,
    pushModalToStack,
    popModalFromStack,
    updateLedger,
    deleteLedgerAccount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LedgerAccountsComp);
