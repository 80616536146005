import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Form, Input } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS } from '../../../static/constants';
// import { TextBox } from '../../general/TextBox';
import { validateEmail, showToasterMessage } from '../../../utils';
//import * as find from 'lodash.find';
// import moment from 'moment-timezone';
// import { ErrorMsg } from '../../general/ErrorMessage';
import config from '../../../config/environmentConfig';
import { LogoComponent } from '../../general/LogoComponent';

// const { Option } = Select;
// const { TextArea } = Input;
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const getAddressInfoFragment = (obj, additionInfo) => {

    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <span className='billing-address'>{obj.streetAddress1}
                </span>
                : ''
        }
        {
            obj.streetAddress2
                ? <span className='billing-address'> {obj.streetAddress2}
                </span>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {
            obj.countryName
                ? <div className='billing-address'>
                    {
                        `${obj.countryName || ''}`
                    }
                </div>
                : ''
        }
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}


function UnsubscribeComp(props) {
    return (
        <div className='dark-inssp' >
             <Row gutter={24} style={{ background: props.onlineThemes.bgColor || '#06033A', color: props.onlineThemes.textColor || '#FFFFFF', padding: '5px', marginLeft:'0px', marginRight:'0px',  marginBottom:'15px' }} className='ltiuy'>
             <Col span={2} className='litlyu'></Col>
                       <Col span={2}>
                           <div className="brand-logo" style={{marginTop:'10px'}}>
                               {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                           </div>
                       </Col>
                       <Col span={12} style={{ alignSelf: 'center' }}>
                           <div className='logosizef'> {props.companyInfo.storeName}</div>
                       </Col>
                       <Col span={8} style={{ alignSelf: 'center' }} className='newaddr'>
                           {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)}
                       </Col>
            </Row>
                <div className="header-action-container pay-ment-box">

                    <div>

                        <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px' }}>
                            <h3> <b> Unsubscribe </b></h3>
                        </div>

                        <div className='pay-text'>
                        <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px' }}>
                            <div><h3>  We're sorry to see you go. Please enter your email to complete your unsubscribe request. </h3></div>
                        </div>
                        <br/>
                            <Form>
                                <div className="information pading-rii"  >
                                    <div className=" ">

                                        <Row className="pb10">
                                            <Col span={10} className="title text-right">
                                                <FormattedMessage id='email' /><span className="required">*</span>
                                            </Col>
                                            <Col span={14} className="input-currency-control">
                                                <Input style={{ width: '100%' }} value={props.emailAddress} placeholder="" onChange={(e) => {
                                                    props.updateState({ emailAddress: e.target.value })
                                                }}
                                                    className={props.invalidEmailClass}
                                                    validator={(val) => { return validateEmail(val); }}
                                                />
                                                {/* <ErrorMsg
                                                validator={() => { return !props.submittedOnce || !!props.emailAddress; }}
                                                message={props.intl.formatMessage(
                                                  {
                                                      id: 'contact.email.message',
                                                      defaultMessage: '.'
                                                  })
                                              }
                                              /> */}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Form>
                            <button className='pay-but3' onClick={() => {
                                props.updateState({
                                    submittedOnce: true,
                                    invalidEmailClass: !validateEmail(props.emailAddress) ? 'input-text-error' : '',
                                });
                                if ( !props.emailAddress) {
                                    return showToasterMessage({
                                        messageType: 'error', description: props.intl.formatMessage({ id: 'login.email.requiredEmail' })
                                    });
                                }
                                if ( props.emailAddress && !validateEmail(props.emailAddress)) {
                                    return showToasterMessage({
                                        messageType: 'error', description: props.intl.formatMessage({ id: 'login.email.invalidEmail' })
                                    });
                                }
                                let payload = {
                                    relationshipId: props.relationshipId,
                                    email: props.emailAddress,
                                    distributionGroupIds: props.distributionGroupIds,
                                }
                                props.unsubscribe(payload, props)
                            }}>Submit</button>
                        </div>
                    </div>

                </div>

            <div style={{clear:'both'}}></div>
            <div className='pay-fot'>
                <Row gutter={24} className='pay-footer-box'>
                    <Col span={9}><span style={{ float: 'left', paddingTop: '5px' }}>{props.onlineThemes.footerMessage || 'All Rights Reserved - ' + props.companyInfo.storeName} </span> </Col>
                    <Col span={6} style={{ textAlign: 'center', color: '#333' }}>
                        {props.onlineThemes.facebookLink ? <i className="fa fa-facebook" style={{ margin: '0px 4px 0px 5px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.facebookLink) { return '' }
                                let url = props.onlineThemes.facebookLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.instagramLink ? <i className="fa fa-instagram" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.instagramLink) { return '' }
                                let url = props.onlineThemes.instagramLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.youtubeLink ? <i className="fa fa-youtube-play" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.youtubeLink) { return '' }
                                let url = props.onlineThemes.youtubeLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.twitterLink ? <i className="fa fa-twitter" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.twitterLink) { return '' }
                                let url = props.onlineThemes.twitterLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.link ? <i className="fa fa-link" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.link) { return '' }
                                let url = props.onlineThemes.link;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                    </Col>
                    <Col span={9}>

                        <span style={{ float: 'right', paddingTop: '5px' }}>Powered by <a href="http://alifapp.com/" target={'_blank'} >alifapp.com</a></span>
                    </Col>
                </Row>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div className='clearfix'></div>
        </div>
    );

}

export default injectIntl(UnsubscribeComp);
