import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomerRegistrationComponent from '../../../../../components/modal/modalBody/schoolManagement/CustomerRegistration';
import { getOrgRegistrationByCustomerId } from './action';
import { showModal, pushModalToStack } from '../../../../../actions/commonActions';

class CustomerRegistration extends Component {

    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            customerId: props.customerData.customerId,
            pageNumber:1
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 0, pageSize: 100,
            formId: this.props.defaultSchoolForm || this.props.studentFormList[0].registrationFormSettingId,
            customerId: this.state.customerId
        };
        this.props.getOrgRegistrationByCustomerId(payload);
       // this.props.getUnpaidInvoiceCount(payload);
    }

    render() {
        return <div>
            <CustomerRegistrationComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        studentFormList: state.membershipReducer.studentFormList,
        defaultSchoolForm: state.common.defaultSchoolForm,
        customerRegistration: state.studentRegistration.customerRegistration,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOrgRegistrationByCustomerId,
    showModal,
    pushModalToStack,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRegistration);