import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const saveDocumentNumber = (params,props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    //let url = "";
    let payload = {
      relationshipId: params.relationshipId,
      txId: Number(params.number || 0),
      txName: params.txName,
      txNamePrefix:params.txNamePrefix
    };
    // switch (params.txType) {
    //   case CONSTANTS_TRANSACTIONS.INQUIRY: {
    //     url = 'inquiry/resetInquiryId';
    //     payload.inquiryId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.SALES_QUOTE: {
    //     url = 'salesQuote/resetSQId';
    //     payload.sqId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.SALES_ORDER: {
    //     url = 'salesOrder/resetSOId';
    //     payload.soId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.SALES_INVOICE: {
    //     url = 'customerInvoice/resetInvoiceId';
    //     payload.invoiceId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST: {
    //     url = 'purchaseOrder/resetPRId';
    //     payload.prId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.RFQ: {
    //     url = 'rfq/resetRFQId';
    //     payload.rfqId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.PURCHASE_ORDER: {
    //     url = 'purchaseOrder/resetPOId';
    //     payload.poId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE: {
    //     url = 'supplierInvoice/resetInvoiceId';
    //     payload.invoiceId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.SALES_PAYMENT: {
    //     url = 'customerPayment/resetPaymentId';
    //     payload.paymentId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.PURCHASE_PAYMENT: {
    //     url = 'supplierPayment/resetPaymentId';
    //     payload.paymentId = Number(params.number || 0);
    //     break;
    //   } case CONSTANTS_TRANSACTIONS.CREDIT_MEMO: {
    //     url = 'creditMemo/resetCreditMemoId';
    //     payload.creditMemoId = Number(params.number || 0);
    //     break;
    //   } case CONSTANTS_TRANSACTIONS.DEBIT_MEMO: {
    //     url = 'debitMemo/resetDebitMemoId';
    //     payload.debitMemoId = Number(params.number || 0);
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY: {
    //     url = 'inboundDelivery/resetInboundDeliveryId';
    //     payload.inboundDeliveryId = Number(params.number || 0);
    //     break;
    //   }
    //   default: {
    //     break;
    //   }
    // }
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/sequence/updateTxId`, payload)
      .then(res => {
        const data = {
          data: Number(params.number || 0),
          txType: params.txType
        }
        dispatch({ type: SETTING_ACTION_LIST.DOCUMNET_NUMBERS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.getDocumentNumber({ relationshipId: props.companyInfo.relationshipId });
        props.getDocumentPrefix({ relationshipId: props.companyInfo.relationshipId });
        showToasterMessage({ messageType: 'success', description: res.data.message || 'Data Saved successfully.' })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.DOCUMNET_NUMBERS;
        }
      })
  }
}

export const getDocumentNumber = (props) => {

  return dispatch => {
    // let url = "";
    let payload = {
      relationshipId: props.relationshipId
    };
    // switch (props.txType) {
    //   case CONSTANTS_TRANSACTIONS.INQUIRY: {
    //     url = 'inquiry/getNextInquiryId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.SALES_QUOTE: {
    //     url = 'salesQuote/getNextSQId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.SALES_ORDER: {
    //     url = 'salesOrder/getNextSOId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.SALES_INVOICE: {
    //     url = 'customerInvoice/getNextInvoiceId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.PURCHASE_REQUEST: {
    //     url = 'purchaseOrder/getNextPRId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.RFQ: {
    //     url = 'rfq/getNextRFQId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.PURCHASE_ORDER: {
    //     url = 'purchaseOrder/getNextPOId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE: {
    //     url = 'supplierInvoice/getNextInvoiceId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.SALES_PAYMENT: {
    //     url = 'customerPayment/getNextPaymentId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.PURCHASE_PAYMENT: {
    //     url = 'supplierPayment/getNextPaymentId';
    //     break;
    //   } case CONSTANTS_TRANSACTIONS.CREDIT_MEMO: {
    //     url = 'creditMemo/getNextCreditMemoId';
    //     break;
    //   } case CONSTANTS_TRANSACTIONS.DEBIT_MEMO: {
    //     url = 'debitMemo/getNextDebitMemoId';
    //     break;
    //   }
    //   case CONSTANTS_TRANSACTIONS.INBOUND_DELIVERY: {
    //     url = 'inboundDelivery/getNextInboundDeliveryId';
    //     break;
    //   }
    //   default: {
    //     break;
    //   }
    // }
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/sequence/getDocSequence?relationshipId=${payload.relationshipId}`)
      .then(res => {
        // const data = {
        //   data: res.data,
        //   txType: props.txType,
        //   isLast: props.isLast
        // }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: SETTING_ACTION_LIST.DOCUMNET_NUMBERS, data: res.data });
       // showToasterMessage({ description: 'Saved Succesfully', messageType: 'success', })
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
       // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const getDocumentPrefix = (props) => {

  return dispatch => {
    // let url = "";
    let payload = {
      relationshipId: props.relationshipId
    };
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/sequence/getDocPrefix?relationshipId=${payload.relationshipId}`)
      .then(res => {
      
        dispatch({ type: SETTING_ACTION_LIST.DOCUMNET_PREFIXS, data: res.data });
      })
      .catch(err => {
      })
  }
}