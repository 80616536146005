import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as find from 'lodash.find';
import CompanySetupComponent from '../../components/CompanySetup';
import { fetchDataIfNeeded, fetchFromLocalStorage } from '../../utils';
import { fetchIndustries } from '../../actions/commonActions';
import { completeCompanySetup } from './action'
import { fetchAllLedgerAccounts } from '../finance/LedgerAccounts/action';
import { LEDGER_TYPE, LOCAL_STORAGE_KEYS } from '../../static/constants';



class CompanySetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount(props) {
    const companyInfo = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) && JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA));
    const payload= {
      relationshipId: ((companyInfo || {}).defaultCompany || {}).relationshipId 
    }
    fetchDataIfNeeded('fetchIndustries', 'industries', this.props, payload);
    fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
  }

  componentWillReceiveProps(props){
    if(props.allLedgerAccounts && props.allLedgerAccounts.length){
      this.setState({
        salesLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_SALES})} || {},
        purchaseLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_PURCHASE})} || {},
        debtorsLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_DEBTORS})} || {},
        creditorsLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TX_TYPE_CREDITORS})} || {},
        customerDiscountLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_SALES_DISCOUNT})} || {},
        supplierDiscountLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_PURCHASE_DISCOUNT})} || {},
        salesOutputTaxLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_SALES_OUTPUT_TAX})} || {},
        purchaseInputTaxLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_PURCHASE_INPUT_TAX})} || {},
        creditMemoLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_SALES_RETURNS})} || {},
        debitMemoLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_PURCHASE_RETURNS})} || {},
        customerExpenseLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_GENERAL_EXPENSE})} || {},
        supplierExpenseLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_GENERAL_SUPPLIER_EXPENSE})} || {},
        paymentRecieveLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_DEBTORS})} || {},
        paymentMadeLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TX_PAYMENT_MADE})} || {},
        roundingOffLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_ROUNDING_OFF})} || {},
        couponLedgerAccount: {...find(props.allLedgerAccounts, {ledgerAccountName:  LEDGER_TYPE.TYPE_COUPON})} || {}

      });
    }
  }

  render() {
    return (
      <Fragment>
        <CompanySetupComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    industries: state.common.industries
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchIndustries,
  fetchAllLedgerAccounts,
  completeCompanySetup
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanySetup);
