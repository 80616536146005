import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GradeComp from "../../../../components/drawer/schoolManagement/grade";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import {  saveSchool } from '../../../schoolManagement/school/action';

class SchoolDrawer extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
  }

  render() {
    return (
      <GradeComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></GradeComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveSchool,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDrawer);
