import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer } from 'antd';
import 'material-ui-rc-color-picker/assets/index.css';
import ThemeProps from './ThemeProps';
import BackgroundProps from './BackgroundProps';


function NewsLetterThemeUpdater(props) {

    const closeDrawer = () => {
        props.updateState({
            isNewsLetterThemeUpdaterVisible: false
        })
    }

    return (
        <div  className='top-space-drawer'>
        <Fragment >

            <Drawer
                title={
                    props.title
                }
                className=""
                placement={"left"}
                keyboard={false}
                width={350}
                maskClosable={false}
                zIndex={99999}
                destroyOnClose={true}
                onClose={closeDrawer}
                visible={props.isNewsLetterThemeUpdaterVisible}
            >
                <h2>Customize {props.elementType}</h2>
                {props.elementType === 'Theme' &&
                    <ThemeProps {...props}></ThemeProps>
                }
                {props.elementType === 'Background' &&
                    <BackgroundProps {...props}></BackgroundProps>
                }
                <br />
                {/* 
                        <CustomButton className='ant-btn-primary'
                            onClick={() => {
                                closeDrawer()
                            }}
                            defaultMessage=''
                            type='default'
                            htmlType='submit'
                            intlId='common.cancel'
                        /> */}

            </Drawer>
        </Fragment>
        </div>
    );
}


export default injectIntl(NewsLetterThemeUpdater);
