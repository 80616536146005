import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardConnectPaymentComp from '../../../components/B2B/CardConnectPayment';
import { getAuthNetPaymentUrl, getFormToken, fetchRelationshipDataByRID, paymentSuccess } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchProjects } from '../../../actions/commonActions';
import { getAllFundraisingCampaign } from '../../drawer/fundraising/pledges/action';
import queryString from 'query-string';
// import * as find from 'lodash.find';
// import * as filter from 'lodash.filter';
// import { getCurrentDateForBackend } from '../../../utils';
// import { CONSTANTS } from '../../../static/constants';

class CardConnectPayment extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(this.props.location.search) || {};
    
    let ids = (linkProps.t || '').split("_");
    let teamIds = (linkProps.f || '').split("_");
    let registrationData = (linkProps.r || '').split("_");
    let cName = (linkProps.cName || '').split("_");
  //  t='rid_paymentGatewayId_sourceId_projectMasterId'
  //t=rid_ paymentGatewayId_source_ projectMasterId_ledgerAccountId _ amount _ fundraisingCampaignId
  // t='17_22_1'
    this.state = {
      ...props,
      period: 'oneTimePayment',
      relationshipBillingAddress: {},
      neverExpires: 1,
      relationshipId: Number(ids[0]),
      paymentGatewayId: Number(ids[1]),
      sourceId: Number(ids[2]),
      projectMasterId: Number(ids[3]),
      ledgerAccountId: Number(ids[4]),
      amountToDonate: Number(ids[5] || 0),
      fundraisingCampaignId: Number(ids[6]),
      isCustomeAmount: Number(ids[5] || 0) ? true: false,
      pledgeId: Number(ids[7]) || 0,
      teamMasterId: teamIds[0],
      teamMemberId: teamIds[1],
      onlineThemes: {},
      customerId: registrationData[0],
      studentRegistrationId: registrationData[1],
      email: registrationData[2],
      registrationFormSettingId: registrationData[3],
      firstName: cName[0],
      lastName: cName[1], 
    }
  }

  componentDidMount() {
    // let self = this;
    // const linkProps = queryString.parse(this.props.location.search) || {};
   // let ids = (linkProps.t || '').split("_");
    // const payload = {
    //   relationshipId: Number(ids[0]),
    //   projectMasterId: Number(ids[3]),
    //   fundraisingCampaignId: Number(ids[6]),
    //   pageSize: 1000
    // };
   // this.props.fetchRelationshipDataByRID(payload, this.props);
   // this.props.fetchProjects(payload, this.props);
 //   this.props.getAllFundraisingCampaign(payload, this.props);

    
  }

  componentWillReceiveProps(props) {
    // if (props.companyInfo && props.companyInfo.relationshipId) {
    //   const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    //   const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
    //   const onlineThemes = JSON.parse( props.companyInfo.onlineThemes || '{}');
    //   this.setState({
    //     onlineThemes,
    //     relationshipBillingAddress,
    //     companyInfo: props.companyInfo,
    //   })
    // }
   
  }

  render() {
    return (
      <Fragment>
        <CardConnectPaymentComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.settings.relationshipData
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  getAuthNetPaymentUrl,
  getFormToken,
  fetchRelationshipDataByRID,
  paymentSuccess,
  fetchProjects,
  getAllFundraisingCampaign
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardConnectPayment);
