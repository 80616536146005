import React from 'react';
import { Card, Row, Button, Pagination } from 'antd';
import { Dropdown } from '../../general/Dropdown';
// import { title } from 'process';
import { getMomentDateForUIReadOnly} from '../../../utils';
import { CONSTANTS} from '../../../static/constants';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const TxnListing = (props) => {
    // const gridStyle = {
    //     width: '25%',
    //     textAlign: 'center',
    //     // height : '40px',
    //     background: 'rgba(128, 128, 128, 0.1)'
    //   };
    // var placeholder = '';
    // if(props.selectedItem === 'Shipment'){
    //   placeholder = 'Search Customer'
    // }
    // if(props.selectedItem === 'Inbound Delivery'){
    //   placeholder = 'Search Supplier'
    // }

    const txnDetailList = props.transactionList[props.pageNumber || 1] || [];

    const handleClick = (item) =>{
      props.updateState({activePage : 'uploadFiles', txnFileUploadItem : item})
    }
    const backClick = () =>{
      props.updateState({activePage : 'transactiontypes', pageNumber: 1, selectedCustomerId: null})
    }
 
    const handleSelectedCustomer = (customerId) =>{
      let endpointUrl = {
        fetchUrl : '',
        countUrl: ''
      }
      if(props.txnType === 'shipment'){
        endpointUrl.fetchUrl = '/shipment/getAllShipmentByCustomerId';
        endpointUrl.countUrl = '/shipment/countAllShipmentByCustomerId';
      }
      if(props.txnType === 'inboundDelivery'){
        endpointUrl.fetchUrl = '/inboundDelivery/getInboundDeliveryBySupplierId';
        endpointUrl.countUrl = '/inboundDelivery/countInboundDeliveryBySupplierId';
      }
      props.getTransactionByCustomerId(props, endpointUrl, customerId);
      props.getTransactionCountByCustomerId(props, endpointUrl, customerId);
    }

    const showTotal = (total) => {
      return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
  };


  const handleShipmentClick= (pageNumber, pageSize) =>{
    const endpointUrl = {
      fetchUrl : '/shipment/getAllShipments',
      countUrl : '/shipment/countAllShipments'
    }
    let payload ={
      relationshipId: props.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize
    }
    props.fetchTransactionDataByRID(payload, endpointUrl);
    props.getTransactionCountByRID(payload, endpointUrl);
    //props.fetchCustomers(props);
 
  }

  const handleInboundDeliveryClick= (pageNumber, pageSize) =>{
    const endpointUrl = {
      fetchUrl : '/inboundDelivery/getAllInboundDeliveries',
      countUrl : '/inboundDelivery/countAllInboundDeliveries'
    }
    let payload ={
      relationshipId: props.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize
    }
    props.fetchTransactionDataByRID(payload, endpointUrl);
    props.getTransactionCountByRID(payload, endpointUrl);
    // props.fetchCustomers(props);
  }


    return (
        <div className='transactions-box'>

            <Card 
              title={props.txnType === 'shipment' ?'All Shipment Transactions' : (props.txnType === 'inboundDelivery' ? 'All GRN Transactions' : 'All Transactions' )} 
              headStyle = {{textAlign:'center', fontSize:30, boxShadow: `2px 2px 10px 2px #9E9E9E`}}>
                
              <div className='search-box'>
                {
                  (props.txnType === 'shipment') ? <Dropdown
                        items={props.txnCustomers[0] || []}
                        valueKeyName='companyName'
                        optionKeyName='customerId'
                        value={props.selectedCustomerId}
                        onSearch= {(searchedText)=>{
                            props.fetchCustomers({
                                pageNumber: 1,
                                hideSpinner: true,
                                pageSize: 100,
                                relationshipId: props.relationshipId,
                                searchedText: searchedText
                            })
                        }}
                        placeholder='Search Customer'
                        onSelect={(selectedId, option) => {
                              props.updateState({selectedCustomerId : selectedId})
  
                              handleSelectedCustomer(selectedId);
                            }
                        }
                      />
                    : ''
                } 
                {
                  (props.txnType === 'inboundDelivery') ? <Dropdown
                      items={props.txnSuppliers[0] || []}
                      valueKeyName='supplierName'
                      optionKeyName='supplierId'
                      value={props.selectedCustomerId}
                      onSearch= {(searchedText)=>{
                          props.fetchSuppliers({
                              pageNumber: 1,
                              hideSpinner: true,
                              pageSize: 100,
                              relationshipId: props.relationshipId,
                              searchedText: searchedText
                          })
                      }}
                      placeholder='Search Supplier'
                      onSelect={(selectedId, option) => {
                            props.updateState({selectedSupplierId : selectedId})

                            handleSelectedCustomer(selectedId);
                          }
                      }
                    />
                  : ''
                }

                {/* <input type='text' placeholder='Search Customer or Supplier' /> */}
                {/* <button><i className="fa fa-search" ></i>
                </button> */}
              </div>
                {
                  txnDetailList.map((item)=>{
                      return(
                        <Row type='flex' justify='left'>
                          <Card.Grid className='links1'
                            onClick = {
                            ()=>{
                              handleClick(item);
                            }
                            }>
                              {/* {
                                props.txnType === 'salesOrder' ?
                                <div>
                                  <p><b>Sales Order ID :</b> {item.soNumber || ''} </p>
                                  <p><b>Customer Name :</b> {item.customerName || ''} </p>
                                  <p><b>Total Amount :</b> {item.salesOrderTotalAmount || ''} </p>
                                </div>
                                :''
                              } */}

                              {/* {
                                props.txnType === 'purchaseOrder' ?
                                <div>
                                  <p><b>Purchase Order Number :</b> {item.poNumber || ''} </p>
                                  <p><b>Supplier Name :</b> {item.supplierName || ''} </p>
                                  <p><b>Total Amount :</b> {item.totalPOAmount || ''} </p>
                                </div>
                                : ''
                              } */}

                              {
                                props.txnType === 'shipment' ?
                                <div>
                                  <p><b>Shipment # </b> {item.shipmentNumber || ''} | {item.customerName || ''} </p>
                                  {/* <p><b>Customer Name :</b> {item.customerName || ''} </p> */}
                                  <p><b>Status </b> {item.status || ''} </p>
                                  <p><b>Date </b>  {getMomentDateForUIReadOnly({date: new Date(item.shipmentDate), format})} </p>
                                </div>
                                : ''
                              }

                              {
                                props.txnType === 'inboundDelivery' ?
                                <div>
                                  <p><b>GRN Number </b> {item.inboundDeliveryNumber || ''} | {item.supplierName || ''} </p>
                                  <p><b>Place of Supply </b> {item.placeOfSupply || ''} </p>
                                  {/* <p><b>Supplier Name :</b>{item.supplierName || ''} </p>  */}
                                  <p><b>Date </b>  {getMomentDateForUIReadOnly({date: new Date(item.inboundDeliveryDate), format})} </p>
                                </div>
                                : ''
                              }
                          </Card.Grid>
                        </Row>
                      )
                    }
                  )
                }
                {
                  txnDetailList.length === 0 ? <div style={{ textAlign : 'center' }}>No data found</div> : ''
                }

              <Row type='flex' justify='center'>
                <Button  style = {{width : '30%', marginTop: '30px', borderRadius: '40px'}} onClick={()=>{
                  backClick()
                  }}
                >
                  Back
                </Button>
              </Row>
            </Card>
            <Pagination
                size="small"
                total={props.transactionCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                showQuickJumper
                onChange={(pageNumber, pageSize) => {
                    props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    if(props.txnType === 'shipment'){
                      handleShipmentClick(pageNumber, pageSize);
                    }
                    if(props.txnType === 'inboundDelivery'){
                      handleInboundDeliveryClick(pageNumber, pageSize);
                    }

                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                    // handlePageSizeChange(pageNumber || 1, pageSize, props)
                }}
            />
        </div>
      )
}

export default TxnListing