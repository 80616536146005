import { REPORTS_ACTION_LIST } from '../static/constants';
const initialState = {
  agedDebtorsList: [],
  agedCreditorsList: [],
}

const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_ACTION_LIST.AGED_DEBTORS_LIST:
      return { ...state, agedDebtorsList: action.data };
    case REPORTS_ACTION_LIST.AGED_CREDITORS_LIST:
      return { ...state, agedCreditorsList: action.data };
    default:
      return state;
  }
};

export default ReportsReducer;