import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportComponent from '../../../../../components/modal/modalBody/customer/ImportContact';
import {
    fetchPaymentTerms, addPaymentTerm, deletePaymentTerm,
    fetchBusinessTypes, addBusinessType, deleteBusinessType,
    fetchIndustries, addIndustry, deleteIndustry,
    fetchSources, addCustomerSource, deleteCustomerSource, hideModal
} from '../../../../../actions/commonActions';

// import {
//     fetchCustomers
// } from '../../../../../containers/customer/Listing/action';
import { importContact } from './action';


class ImportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
        };
    }

    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};
        // const payload = { relationshipId: companyInfo.relationshipId };
        // fetchDataIfNeeded('fetchSources', 'sources', this.props, payload);
        // fetchDataIfNeeded('fetchBusinessTypes', 'businessTypes', this.props, payload);
        // fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
        // fetchDataIfNeeded('fetchIndustries', 'industries', this.props, payload);
    }

    render() {
        return <ImportComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
      sources: state.common.sources,
      businessTypes: state.common.businessTypes,
      paymentTerms: state.common.paymentTerms,
      industries: state.common.industries,
      companyInfo: state.common.companyInfo,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSources,
    addCustomerSource,
    deleteCustomerSource,
    fetchPaymentTerms,
    addPaymentTerm,
    deletePaymentTerm,
    fetchIndustries,
    addIndustry,
    deleteIndustry,
    fetchBusinessTypes,
    addBusinessType,
    importContact,
    deleteBusinessType,
    hideModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
