import React from "react";
import { Drawer, Row, Col, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";

import { showToasterMessage } from "../../../../../utils";
import { TextBox } from "../../../../general/TextBox";
import { CustomButton } from "../../../../general/CustomButton";

const StockAdjustmentEdit = (props) => {

  const closeDrawer = () => {

    props.updateState({
      stockAdjustmentDrawerVisible: false,
    });
  };

  const updateProduct = () => {
    if (!props.purchasedPrice && Number(props.currentStock || 0) > 0) {
    //   cancelEditing();
      return showToasterMessage({
        description:
          "Current stock can'nt be changed because purchase price not available for this product",
      });
    }
    let product =
      props.products[props.pageNumber].find(
        (item) => item.itemId === props.itemId
      ) || {};
    let payload = {
      currentStock: Number(props.currentStock || 0,),
      salesCommitted: Number(props.salesCommitted || 0),
      qtyOnPO: Number(props.qtyOnPO|| 0),
      itemId: props.itemId,
      updatedByEmpId: ((props.companyInfo || {}).relationshipEmployees || {}).relationshipEmployeeId,
      itemName: props.itemNameWithVariant,
      relationshipId: props.relationshipId,
    };
    if (props.variantId) {
      payload.variantId = props.variantId;
    } else {
      let variant = product.inventoryItemVariantList[0] || {};
      payload.variantId = variant.variantId;
    }
  
    const modalData = {
      modalBody: <FormattedMessage id="save.confirmation" defaultMessage="" />,
      handleSubmit: () => {
        props.updateInventoryItem(payload, props);
        // props.hideModal();
        closeDrawer()
        // cancelEditing();
      },
    };
    props.showModal(modalData);
  };

  const rowGutter = [24, 16];
  return (
    <>
      <Drawer
        title={<div className="drawer-head-txt">Edit Price</div>}
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.stockAdjustmentDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="button.save.label"
                defaultMessage=""
                htmlType="submit"
                onClick={()=>updateProduct(props)}
                key={1}
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">Brand Name</div>
            <TextBox
              value={props.brandName}
              disabled={true}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">SKU #</div>
            <TextBox
              value={props.sku}
              disabled={true}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Category</div>
            <TextBox
              value={(props.inventoryItemCategoryRefs || []).map(obj=>obj.inventoryItemCategoryName).join(', ')}
              disabled={true}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Current Stock</div>

            <TextBox
              placeholder="Enter Amount"
              type="text"
              maxLength={20}
              value={props.currentStock}
              onChange={(e) => {
                props.updateDrawerState({ currentStock: e.target.value });
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Qty on SO</div>

            <TextBox
              placeholder="Enter Amount"
              type="text"
              maxLength={20}
              value={props.salesCommitted }
              onChange={(e) => {
                props.updateDrawerState({ salesCommitted: e.target.value });
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Qty on PO</div>

            <TextBox
              placeholder="Enter Po"
              type="text"
              maxLength={20}
              value={props.qtyOnPO}
              onChange={(e) => {
                props.updateDrawerState({ qtyOnPO: e.target.value });
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default StockAdjustmentEdit;
