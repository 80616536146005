import { SQ_ACTION_LIST } from '../static/constants';
const initialState = {
  sqList: {
    1: []
  },
  salesQuoteCount: 0,
  sqDetail: {},
  sqDraftList: {
    1: []
  },
  sqDraftCount: 0,
}

const SQReducer = (state = initialState, action) => {
  switch (action.type) {
    case SQ_ACTION_LIST.SQ_LIST:
      return {
        ...state,
        sqList: {
          ...state.sqList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SQ_ACTION_LIST.SALES_QUOTE_COUNT:
      return { ...state, salesQuoteCount: action.data };
    case SQ_ACTION_LIST.SQ_LIST_PAGE_RESET:
      return { ...state, sqList: initialState.sqList };
    case SQ_ACTION_LIST.SQ_DETAIL:
      return { ...state, sqDetail: action.data };
    case SQ_ACTION_LIST.RESET_SQ_DATA:
      return { ...state, sqDetail: initialState.sqDetail };
    case SQ_ACTION_LIST.NEXT_SQ_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case SQ_ACTION_LIST.NEXT_SQ_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case SQ_ACTION_LIST.SQ_DRAFT_LIST:
      return {
        ...state,
        sqDraftList: {
          ...state.sqDraftList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SQ_ACTION_LIST.SQ_DRAFT_COUNT:
      return { ...state, sqDraftCount: action.data };
    case SQ_ACTION_LIST.SQ_DRAFT_LIST_PAGE_RESET:
      return { ...state, sqDraftList: initialState.sqDraftList }; 
    default:
      return state;
  }
};

export default SQReducer;