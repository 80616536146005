import { Col, DatePicker, Drawer, Row,Skeleton } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown"

const MonthlyPayDrawer = (props) => {
  const closeDrawer = () => {
    props.updateState({
      monthlyPayDrawer: false,
    });
  };

  return (
    <Drawer
      title={<FormattedMessage id="drawer.leaveMangement.titleName.payGrade" />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.monthlyPayDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => {

            }

            }
            key={1}
          />
        </>
      }

    >
        <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div className="i-label">Pay Grade Name
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Pay Grade Name"
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.leadSourceName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Gross Salary
            <span className="required">*</span>

          </div>
          <Dropdown
          canAddNew={true}
            placeholder="Gross Salary"
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.leadSourceName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label"><FormattedMessage id="company.payrollManagement.percentage" />
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Percentage of Basic"

            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
          <ErrorMsg
            validator={() => { return !props.submitted || props.leadSourceName }}
            message={<FormattedMessage id='requiredField.missing.msg' />}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Basic Salary
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Basic Salary"
          />

        </Col>
        <Col span={12}>
          <div className="i-label">Allowance
            <span className="required">*</span>
          </div>
          <Dropdown
            placeholder="Allowance"
          canAddNew={true}

          />

        </Col>
        <Col span={12}>
          <div className="i-label">Deduction
            <span className="required">*</span>

          </div>
          <Dropdown
            placeholder="Deduction"
          canAddNew={true}

          />

        </Col>
        <Col span={12}>
          <div className="i-label">Over Time Rate
            <span className="required">*</span>

          </div>
          <TextBox
            placeholder="Over Time Rate"
          />

        </Col>

      </Row>
      </Skeleton>
    </Drawer>
  );
};

export default MonthlyPayDrawer;
