import React from "react";
import { Drawer, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import {
  showToasterMessage,
  getUserDefinedName,
} from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { CustomButton } from "../../general/CustomButton";


const SubjectListFilterComp = (props) => {
  const { updateState, companyInfo } = props;

  const closeDrawer = () => {
    props.updateState({
      subjectListFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
    if (!props.subjectName && !props.subjectCode && !props.subjectType) {
      return showToasterMessage({
        messageType: "error",
        description: "Please select at least one filter",
      });
    }
    const payload = {
      companyInfo,
      relationshipId:companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {
        subjectName: props.subjectName || null,
        subjectCode: props.subjectCode || null,
        subjectType: props.subjectType || null,
      },
    };
    // props.fetchSubjectListByFilter(payload);
  };

  const clearFilter = () => {
    props.updateState({
      subjectName: null,
      subjectCode: null,
      subjectType: null,
    });
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: 0,
    //   pageSize: props.pageSize,
    //   filters: {},
    // };
    // props.fetchParentsListByFilter(payload);
    // props.getParentslistCountByFilter(payload);
  };

  const rowGutter = [24, 16];

  return (
    <>
      <Drawer
        title={
          <div className="drawer-head-txt">
           <FormattedMessage id={getUserDefinedName('subject.subjectList.filter.drawer.title',props)} />
          </div>
        }
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.subjectListFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">{getUserDefinedName('schoolManagement.subject.name',props)}</div>
            <TextBox
              placeholder={getUserDefinedName('schoolManagement.subject.name',props)}
              type="text"
              maxLength={20}
              value={props.subjectName}
              onChange={(e) => {
                props.updateState({subjectName: e.target.value });
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">{getUserDefinedName('schoolManagement.subject.code',props)}</div>
            <TextBox
              placeholder={getUserDefinedName('schoolManagement.subject.code',props)}
              type="text"
              maxLength={20}
              value={props.subjectCode}
              onChange={(e) => {
                props.updateState({ subjectCode: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row gutter={rowGutter}>
        <Col span={12}>
            <div className="i-label">{getUserDefinedName('schoolManagement.subject.type',props)}</div>
            <Dropdown
              items={[
                {
                  label: "Core",
                  key: "1",
                },
                {
                  label: "Elective",
                  key: "2",
                },
            
         
              ]}
              valueKeyName="label"
              optionKeyName="key"
              value={props.subjectType}
              placeholder={getUserDefinedName('schoolManagement.subject.type',props)}
              onSelect={(optionValue, option) => {
                props.updateState({
                  subjectType: option.name,

                  //   formId: option.value,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({ subjectType: undefined });
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default SubjectListFilterComp;
