import React from 'react';
import { FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';
import PageBreadcrumb from './../PageBreadcrumb';
import './show-settings.scss';
//import { Switch } from 'antd';
import EmailTemplate from '../../containers/modal/modalBody/settings/EmailTemplate';
import EmailSignature from '../../containers/modal/modalBody/settings/EmailSignature';
import CompanyInfo from '../../containers/modal/modalBody/settings/CompanyInfo';
import RemarksTemplate from '../../containers/modal/modalBody/settings/RemarksTemplate';
import ChangePassword from '../../containers/modal/modalBody/settings/ChangePassword';
import DocumentName from '../../containers/modal/modalBody/settings/DocumentName';
import DocumentNumber from '../../containers/modal/modalBody/settings/DocumentNumber';
import DocumentApproval from '../../containers/modal/modalBody/settings/DocumentApproval';
import QualityCheck from '../../containers/modal/modalBody/settings/QualityCheck';
import { MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../static/constants';
import { checkACLPermission } from '../../utils';
import RegisterFormList from '../../containers/modal/modalBody/settings/RegisterFormList';
import DonationSetting from '../../containers/modal/modalBody/settings/DonationSetting';
import DonationKioskSetting from '../../containers/modal/modalBody/settings/DonationKioskSetting';
//import RegisterFormPermissionList from '../../containers/modal/modalBody/settings/RegisterFormPermissionList';
import OrgIcon from "../../../src/assets/images/icon/skyline.png";
import LocIcon from "../../../src/assets/images/icon/key.png";
import UserIcon from "../../../src/assets/images/icon/user.png";
import DocIcon from "../../../src/assets/images/icon/paper.png";
import QualityIcon from "../../../src/assets/images/icon/qlyIcon.png";
import DonateIcon from "../../../src/assets/images/icon/heart.png";
import KioskIcon from "../../../src/assets/images/icon/kioskIcon.png";
import MailIcon from "../../../src/assets/images/icon/mailIcon.png";
import MailSignIcon from "../../../src/assets/images/icon/emailSign.png";
import RemarkIcon from "../../../src/assets/images/icon/remarkIcon.png";
import DocNameIcon from "../../../src/assets/images/icon/docNameIcon.png";
import DocApproveIcon from "../../../src/assets/images/icon/docApproveicon.png";
import DocRegisIcon from "../../../src/assets/images/icon/docRegisIcon.png";
import TaxIcon from "../../../src/assets/images/icon/taxIcon.png";
import ShippingIcon from "../../../src/assets/images/icon/shippingIcon.png";

const breadCrumbList = [
    {
        name: 'Dashboard',
        link: 'dashboard'
    }, {
        name: 'Settings',
    }
];


const ShowSettings = (props) => {
    const { permissions } = props;
    const openEmailTemplates = (data) => {
        const modalData = {
            title: <FormattedMessage id='setting.emailTemplates.label' defaultMessage='' />,
            modalBody: <EmailTemplate {...props} />,
            width: 800,
            hideFooter: true,
            wrapClassName: 'show-setting-email-template',
        };
        props.showModal(modalData);
    };
    const openEmailSignature = (data) => {
        const modalData = {
            title:<FormattedMessage id='setting.emailSignature.label' defaultMessage='' />,
            modalBody: <EmailSignature {...props} />,
            width: 800,
            hideFooter: true,
            wrapClassName: 'show-setting-email-template',
        };
        props.showModal(modalData);
    };
    
    const openDocumentNumner = (data) => {
        const modalData = {
            title: <FormattedMessage id='setting.documentSequence.label' defaultMessage='' />,
            modalBody: <DocumentNumber {...props} />,
            width: 1000,
            hideFooter: true,
            wrapClassName: 'show-setting-email-template',
        };
        props.showModal(modalData);
    };

    const openRemarksTemplate = (data) => {
        const modalData = {
            title: <div className='ant-modal-title'><FormattedMessage id='setting.remarks.label' defaultMessage='' /></div>,
            modalBody: <RemarksTemplate {...props} />,
            width: 700,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const openChangePassword = (data) => {
        const modalData = {
            title: <div className='ant-modal-title'><FormattedMessage id='setting.passwordManagement.label' defaultMessage='' /></div>,
            modalBody: <ChangePassword {...props} />,
            width: 600,
            hideFooter: true
        };
        props.pushModalToStack(modalData);
    }

    const openDocumentNameTemplate = (data) => {
        const modalData = {
            title: <div className='ant-modal-title'><FormattedMessage id='setting.documentName.label' defaultMessage='' /></div>,
            modalBody: <DocumentName {...props} />,
            width: 700,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const openRegistrationFormSetting = (data) => {
        const modalData = {
            title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.list.heading' defaultMessage='' /></div>,
            modalBody: <RegisterFormList {...props} style={{ overflow: 'auto' }} />,
            width: 1100,
            hideFooter: true,
            maskClosable: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
        // const modalData = {
        //     title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
        //     modalBody: <RegistrationFormSetting {...props} style={{overflow: 'auto'}} />,
        //     width: 1100,
        //     hideFooter: true,
        //     maskClosable: true,
        //     wrapClassName: "remark-modal"
        // };
        // props.showModal(modalData);
    };

    const openDonationSetting = (data) => {
        const modalData = {
            title: <div className='ant-modal-title'><FormattedMessage id='setting.donation.heading' defaultMessage='' /></div>,
            modalBody: <DonationSetting {...props} style={{ overflow: 'auto' }} />,
            width: 1100,
            hideFooter: true,
            maskClosable: true,
            innerHeight: 800,
            wrapClassName: "remark-modalm",
        };
        props.showModal(modalData);
    };

    const openKioskDonationSetting = (data) => {
        const modalData = {
            title: <div className='ant-modal-title'><FormattedMessage id='setting.kioskDonation.heading' defaultMessage='' /></div>,
            modalBody: <DonationKioskSetting {...props} style={{ overflow: 'auto' }} />,
            width: 1100,
            hideFooter: true,
            maskClosable: true,
            innerHeight: 900,
            wrapClassName: "remark-modalms",
        };
        props.showModal(modalData);
    };

    const openDocumentApprovalTemplate = (data) => {
        const modalData = {
            title: <div className='ant-modal-title'><FormattedMessage id='setting.documentApproval.label' defaultMessage='' /></div>,
            modalBody: <DocumentApproval {...props} />,
            width: 700,
            hideFooter: true,
            wrapClassName: "remark-modal"
        };
        props.showModal(modalData);
    };

    const openQualityCheckSettingTemplate = (data) => {
        const modalData = {
            title: <div className='ant-modal-title'><FormattedMessage id='setting.qualityCheckSetting.label' defaultMessage='' /></div>,
            modalBody: <QualityCheck {...props} />,
            width: 600,
            hideFooter: true
        };
        props.showModal(modalData);
    };

    const getModulePermissionData = () => {
        const primaryPerm = permissions.primary || [];
        const permittedModules = {
            companyInfo: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_COMPANY_INFORMATION, PERMISSION_VALUES.CREATE),
            userManagement: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_USER_MANAGEMENT, PERMISSION_VALUES.CREATE),
            documentCenter: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_DOCUMENTS_CENTER, PERMISSION_VALUES.CREATE),
            emailTemplates: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_EMAIL_TEMPLATES, PERMISSION_VALUES.CREATE),
            emailSignature: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_EMAIL_TEMPLATES, PERMISSION_VALUES.CREATE),
            remarks: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_REMARKS, PERMISSION_VALUES.CREATE),
            documentName: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_DOCUMENTS_NAME, PERMISSION_VALUES.CREATE),
            documentApproval: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_DOCUMENTS_APPROVAL, PERMISSION_VALUES.CREATE),
            registrationForm: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_REGISTRATION_FORM, PERMISSION_VALUES.CREATE),
            donation: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SETTINGS_DONATION, PERMISSION_VALUES.CREATE),
            kioskConfig: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.KIOSK, PERMISSION_VALUES.CREATE),
        };
        Object.keys(permittedModules).forEach((moduleName) => {
            if (permittedModules[moduleName] === false) {
                delete permittedModules[moduleName];
            }
        })
        return permittedModules;
    }

    // const openRegistrationFormPermissionList = (data) => {
    //     const modalData = {
    //         title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.list.heading' defaultMessage='' /></div>,
    //         modalBody: <RegisterFormPermissionList {...props} style={{ overflow: 'auto' }} />,
    //         width: 1100,
    //         hideFooter: true,
    //         maskClosable: true,
    //         wrapClassName: "remark-modal"
    //     };
    //     props.showModal(modalData);
      
    // };

    const permittedModules = getModulePermissionData();
    
const inlineStyle = {
    width:"26.947px",
     height:"26.947px"
  };
    return (
        <div className="settings-container">
            <div className='page-title-bar'>
                <div className='page-title'>Account Settings</div>
                <div className='sep' />
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>

            <div className='settings-body'>

                <div className='settings-body-child'>
                    <div className="child-title"><FormattedMessage id='setting.accountInformation.label' defaultMessage='' /></div>
                    <div className="child-subTitle">Manage your company information to use the platform</div>
                        <div className="child-body">
                            {
                                permittedModules.companyInfo && (
                                    <div className="tile" onClick={()=>{
                                        props.pushModalToStack({
                                            title: <div><FormattedMessage id='setting.companyInfo.label' defaultMessage='' /></div>,
                                            modalBody: <CompanyInfo {...props} />,
                                            width: 1000,
                                            wrapClassName: 'custom-modal-content def-pdd',
                                            hideFooter: true,
                                        })
                                       }}>
                                        <div className="tile-icon">
                                        <img src={OrgIcon} alt='' style={inlineStyle}></img> 
                                        {/* <i class="fi fi-rs-building"></i> */}
                                        </div>
                                        <div className='tile-title'>
                                            <FormattedMessage id='setting.companyInformation.label' defaultMessage='' />
                                            <div className='tile-description'>
                                                <FormattedMessage id='setting.companyInformation.message' defaultMessage='' />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="tile" onClick={() => openChangePassword(props)}>
                                <div className="tile-icon">
                                    <img src={LocIcon} alt='' style={inlineStyle}></img>
                                    {/* <i class="fi fi-br-lock"></i> */}
                                </div>
                                <div className="tile-title" >
                                    <FormattedMessage id='setting.changePassword.label' defaultMessage='' />
                                    <div className='tile-description'>
                                        <FormattedMessage id='setting.changePassword.message' defaultMessage="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>

                {
                    (permittedModules.documentCenter || permittedModules.userManagement)
                        ? (
                            <div className="settings-body-child">
                                <div className="child-title"><FormattedMessage id='setting.administration.label' defaultMessage='' /></div>
                                <div className="child-subTitle">Manage your company information to use the platform</div>
                                <div className="child-body">
                                    {
                                        permittedModules.userManagement && (
                                            <div className="tile" onClick={()=>{props.history.push('/admin/settings/user-management')}}>
                                                <div className="tile-icon">
                                                <img src={UserIcon} alt='' style={inlineStyle}></img>
                                                    {/* <i class="fi fi-rr-user"></i> */}
                                                </div>
                                                <div className='tile-title'>
                                                    <FormattedMessage id='setting.userManagement.label' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.userManagement.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        permittedModules.documentCenter && (
                                            <div className="tile" onClick={() => openDocumentNumner(props)}>
                                                <div className="tile-icon">
                                                <img src={DocIcon}  alt='' style={inlineStyle} />
                                                {/* <i class="fi fi-rs-album-collection"></i> */}
                                                </div>
                                                <div className="tile-title">
                                                    <FormattedMessage id='setting.documentSequence.label' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.documentSequence.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        permittedModules.documentCenter && (
                                            <div className="tile" onClick={() => openQualityCheckSettingTemplate(props)}>
                                                <div className="tile-icon">
                                                <img src={QualityIcon} alt='' />
                                                    {/* <i class="fi fi-rs-badge"></i> */}
                                                </div>
                                                <div className="tile-title" >
                                                    <FormattedMessage id='setting.qualityCheck.label' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.qualityCheck.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    :
                    ''
                }

                {
                    (permittedModules.emailTemplates || permittedModules.emailSignature || permittedModules.remarks || permittedModules.documentName || permittedModules.documentApproval || permittedModules.registrationForm || permittedModules.donation || permittedModules.kioskConfig)
                        ? (
                            <div className="settings-body-child">
                                <div className="child-title"><FormattedMessage id='setting.customizations.label' defaultMessage='' /></div>
                                <div className="child-subTitle">Manage your company information to use the platform</div>
                                <div className='child-body'>
                                    {
                                        permittedModules.donation && (
                                            <div className="tile" onClick={() => openDonationSetting(props)}>
                                                <div className="tile-icon">
                                                <img src={DonateIcon}  alt='' style={inlineStyle}/>
                                                {/* <i class="fi fi-rs-donate"></i> */}
                                                </div>
                                                <div className="tile-title">
                                                    <FormattedMessage id='setting.donation.heading' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.donation.form.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        permittedModules.kioskConfig && (
                                            <div className="tile" onClick={() => openKioskDonationSetting(props)}>
                                                <div className="tile-icon">
                                                <img src={KioskIcon} alt='' />
                                                {/* <i class="fi fi-rr-money-simple-from-bracket"></i> */}
                                                </div>
                                                <div className="tile-title">
                                                    <FormattedMessage id='setting.kioskDonation.heading' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.donation.form.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        permittedModules.emailTemplates && (
                                            <div className="tile"  onClick={() => openEmailTemplates(props)}>
                                                <div className="tile-icon">
                                                <img src={MailIcon} alt='MailIcon' />
                                                {/* <i class="fi fi-br-envelope"></i> */}
                                                </div>
                                                <div className="tile-title">
                                                    <FormattedMessage id='setting.emailTemplates.label' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.emailTemplates.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        permittedModules.emailSignature && (
                                            <div className="tile"  onClick={() => openEmailSignature(props)}>
                                                <div className="tile-icon">
                                                <img src={MailSignIcon} alt='MailSignIcon' style={{height:"30px"}} />
                                                {/* <i class="fi fi-br-envelope"></i> */}
                                                </div>
                                                <div className="tile-title">
                                                    <FormattedMessage id='setting.emailSignature.label' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.emailSignature.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        permittedModules.remarks && (
                                            <div className="tile" onClick={() => openRemarksTemplate(props)}>
                                                <div className="tile-icon">
                                                <img src={RemarkIcon} alt='' />
                                                {/* <i class="fi fi-rr-comment-alt-middle"></i> */}
                                                </div>
                                                <div className="tile-title" >
                                                    <FormattedMessage id='setting.remarks.label' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.remarks.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        permittedModules.documentName && (
                                            <div className="tile" onClick={() => openDocumentNameTemplate(props)}>
                                                <div className="tile-icon">
                                                <img src={DocNameIcon} alt='' />
                                                {/* <i class="fi fi-rr-document"></i> */}
                                                </div>
                                                <div className="tile-title">
                                                    <FormattedMessage id='setting.documentName.label' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.documentName.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        permittedModules.documentApproval && (
                                            <div className="tile">
                                                <div className="tile-icon">
                                                <img src={DocApproveIcon} alt='' />
                                                {/* <i class="fi fi-rr-memo-circle-check"></i> */}
                                                </div>
                                                <div className="tile-title" onClick={() => openDocumentApprovalTemplate(props)}>
                                                    <FormattedMessage id='setting.documentApproval.label' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.documentApproval.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        props.companyInfo.industryName === "Education" ?
                                        (permittedModules.registrationForm && (
                                            <div className="tile" onClick={() => openRegistrationFormSetting(props)}>
                                                <div className="tile-icon">
                                                <img src={DocRegisIcon} alt='' />
                                                    {/* <i class="fi fi-rs-form"></i> */}
                                                </div>
                                                <div className="tile-title" >
                                                    <FormattedMessage id='setting.student.registration.form.label' defaultMessage='' />
                                                    <div className='tile-description'>
                                                        <FormattedMessage id='setting.student.registration.form.message' defaultMessage='' />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :""
                                    }
                                </div>
                    
                                {/* <div className="setting-actions next-row">
                                    {
                                        permittedModules.registrationForm && (
                                            <div className="tile">
                                                <div className="tile-icon">
                                                    <i className="fa fa-file-text-o" />
                                                    <div className="tile-icon" onClick={() => openRegistrationFormPermissionList(props)}>
                                                        <FormattedMessage id='setting.student.registration.form.permission.label' defaultMessage='' />
                                                    </div>
                                                </div>
                                                <div>
                                                    <FormattedMessage id='setting.student.registration.form.permission.message' defaultMessage='' />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div> */}
                            </div>
                        )
                    : 
                    ''
                }

                {/* <div className="settings-body-child">
        <h2 className="title small"><FormattedMessage id='setting.enableTestData.label' defaultMessage='' /></h2>

        <div className="setting-actions">
            <div className="tile">
                <Switch
                    checkeds
                    onClick={(checked, e) => { }}
                />
            </div>
        </div>
    </div> */}




                <div className="settings-body-child">
                    <div className="child-title"><FormattedMessage id='setting.marketplaceConfiguration.label' defaultMessage='' /></div>
                    <div className="child-subTitle">Manage your company information to use the platform</div>
                    <div className="child-body">
                        <div className="tile" onClick={()=>props.history.push('/admin/settings/taxes')}>
                            <div className="tile-icon">
                            <img src={TaxIcon} alt='' />
                            {/* <i class="fi fi-rs-coins"></i> */}
                            </div>
                            <div className='tile-title'>
                                <FormattedMessage id='setting.marketplaceConfiguration.taxes.label' defaultMessage='' />
                                <div className='tile-description'>
                                    <FormattedMessage id='setting.marketplaceConfiguration.taxes.msg' defaultMessage='' />
                                </div>
                            </div>
                        </div>

                        <div className="tile" onClick={()=>props.history.push('/admin/settings/shipping')}>
                            <div className="tile-icon">
                            <img src={ShippingIcon} alt='' />
                            {/* <i class="fi fi-bs-truck-side"></i> */}
                            </div>
                            <div className='tile-title'>
                                <FormattedMessage id='setting.marketplaceConfiguration.shipping.label' defaultMessage='' />
                                <div className='tile-description'>
                                    <FormattedMessage id='setting.marketplaceConfiguration.shipping.msg' defaultMessage="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>




        </div >
    )
};

export default ShowSettings;
