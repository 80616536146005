import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegisterFormList from '../../../../../components/modal/modalBody/settings/RegisterFormList';
import { pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { fetchRegistrationFormSettingList, deleteRegistrationForm } from './action';
class RegistrationFormSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            relationshipId: props?.companyInfo?.relationshipId
        }
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        this.props.fetchRegistrationFormSettingList({relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: 100});
        
    }

   

    render() {
        return <div>
            <RegisterFormList {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        registrationFormList: state.settings.registrationFormList,
        paymentTerms: state.common.paymentTerms,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    pushModalToStack,
    popModalFromStack,
    fetchRegistrationFormSettingList,
    deleteRegistrationForm
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationFormSetting);