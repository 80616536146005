import React from 'react';
// import { Link } from 'react-router-dom';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
// import { Checkbox, Button,  Row } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
// import * as find from 'lodash.find';

// import { DropdownPriceList } from '../../../../general/MarkupDropdown';
// import { Dropdown } from '../../../../general/Dropdown';
// import {  MODAL_TYPE, CONSTANTS } from '../../../../../static/constants';
// import { AG_GRID_CONSTANTS } from '../../../../../static/agGridConstants';
import LoadingIcon from "../../../../../assets/images/loading.gif"
// import StudentInvoice from '../../../../../containers/customer/invoice/StudentInvoice';
// import MultiCurrencySalesInvoice from '../../../../../containers/customer/invoice/MultiCurrencySalesInvoice';
// import StandardSalesInvoice from '../../../../../containers/customer/invoice/StandardSalesInvoice';


const ExpenseHeader = (props) => {
    // const [templateDropdown, updateTemplateDropdown] = useState(false);
    // const {
    //     showModal,
    //     companyInfo
    // } = props;


    // const useOuterClick = (callback) => {
    //     const innerRef = useRef();
    //     const callbackRef = useRef();

    //     // set current callback in ref, before second useEffect uses it
    //     useEffect(() => { // useEffect wrapper to be safe for concurrent mode
    //         callbackRef.current = callback;
    //     });

    //     useEffect(() => {
    //         document.addEventListener("click", handleClick);
    //         return () => document.removeEventListener("click", handleClick);

    //         // read most recent callback and innerRef dom node from refs
    //         function handleClick(e) {
    //             if (
    //                 innerRef.current &&
    //                 callbackRef.current &&
    //                 !innerRef.current.contains(e.target)
    //             ) {
    //                 callbackRef.current(e);
    //             }
    //         }
    //     }, []); // no need for callback + innerRef dep

    //     return innerRef; // return ref; client can omit `useRef`
    // }

    // const innerRef = useOuterClick(e => {
    //    updateTemplateDropdown(!templateDropdown)
    // });

    // const handleNewPriceListAddition = (props, payload) => {
    //     const formData = payload.formData;
    //     formData.relationshipId = companyInfo.relationshipId;
    //     const data = {
    //         title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
    //         formData,
    //         hideFooter: true,
    //         modalData: {
    //             modalType: MODAL_TYPE.PRICE_LIST,
    //             data: payload,

    //         },
    //         handleSubmit: (formData = {}) => {
    //             data.formData.submittedOnce = true;
    //             showModal(data);
    //         }
    //     };
    //     showModal(data);
    // }

    // const deleteClickHandler = (props, modalType, payload) => {
    //     const modalBody =
    //         <FormattedMessage
    //             id='deleteItem.confirmation'
    //             defaultMessage=''
    //             values={{ value: payload.text }}
    //         />;

    //     let deleteFunc;

    //     switch (modalType) {
    //         case MODAL_TYPE.PRICE_LIST: {
    //             deleteFunc = props.deletePriceList;
    //             break;
    //         }
    //         default: {
    //             deleteFunc = () => { }
    //         }
    //     }
    //     const modalData = {
    //         modalBody,
    //         handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
    //     };
    //     showModal(modalData);
    // }

    return (<>
        <div className='left-itms'>
      <ul>
        <li className='title'>
          <FormattedMessage id='heading.sales.bussinesExpanse.create' />
        </li>
        <li className='sep' />
        <li>
          <FormattedMessage id='heading.sales.bussinesExpanse.sub' defaultMessage='' /> &nbsp; - &nbsp;
          {props.nextTxNumber ?
            <span>{getFormatedNextTxNumber(props)}</span>
            //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
            :
            <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />
          }
        </li>
      </ul>
     </div>
        <div className="right-itms">
           
          
        </div>
    </>
    );
};
const getFormatedNextTxNumber = (props) => {
    // const { customer } = props;
    // if (!customer || !customer.customerId) {
    //     return <span>{props.nextTxNumber}</span>;
    // }
    // return <span>{props.nextTxNumber.replace('INV-00', 'INV-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
    return <span>{props.nextTxNumber}</span>;
}

export default injectIntl(ExpenseHeader);
