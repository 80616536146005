import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PERMISSION_VALUES, MODULE_CODED_VALUES } from './../../static/constants';

export const headerMenuOptionsData = [
    {
        label: <FormattedMessage id='header.menuItem.salesHead.label' defaultMessage='' />,
        name: 'customerHead',
        hierarchy: [
            {
                label: <FormattedMessage id='header.menuItem.customer.label' defaultMessage='' />,
                name: 'customer',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.CUSTOMERS
            },
            {
                label: <FormattedMessage id='header.menuItem.salesInquiry.label' defaultMessage='' />,
                name: 'salesInquiry',
                // link: '/admin/sales-inquiry-create',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.SALES_QUOTE
            },
            {
                label: <FormattedMessage id='header.menuItem.salesQuote.label' defaultMessage='' />,
                name: 'salesQuote',
                // link: '/admin/create-sales-quote',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.SALES_QUOTE
            },
            {
                label: <FormattedMessage id='header.menuItem.salesOrder.label' defaultMessage='' />,
                name: 'salesOrder',
                // link: '/admin/so-create',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.SALES_ORDER
            },
            {
                label: <FormattedMessage id='header.menuItem.salesInvoice.label' defaultMessage='' />,
                name: 'salesInvoice',
                // link: '/admin/sales-invoice',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.SALES_INVOICE
            },
            {
                label: <FormattedMessage id='header.menuItem.receivePayment.label' defaultMessage='' />,
                name: 'receivePayment',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.CUSTOMER_PAYMENT
            },
            {
                label: <FormattedMessage id='header.menuItem.creditMemo.label' defaultMessage='' />,
                name: 'creditMemo',
                // link: '/admin/create-credit-memo',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.CREDIT_MEMO
            },
        ]
    },
    {
        label: <FormattedMessage id='header.menuItem.purchaseHead.label' defaultMessage='' />,
        name: 'supplierHead',
        hierarchy: [
            {
                label: <FormattedMessage id='header.menuItem.supplier.label' defaultMessage='' />,
                name: 'supplier',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.SUPPLIERS
            },
            {
                label: <FormattedMessage id='header.menuItem.purchaseRequest.label' defaultMessage='' />,
                name: 'purchaseRequest',
                // link: '/admin/pr-create',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.PURCHASE_REQUEST
            },
            {
                label: <FormattedMessage id='header.menuItem.rfq.label' defaultMessage='' />,
                name: 'rfq',
                // link: '/admin/rfq-create',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.RFQ
            },
            {
                label: <FormattedMessage id='header.menuItem.purchaseOrder.label' defaultMessage='' />,
                name: 'purchaseOrder',
                // link: '/admin/po-create',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.PURCHASE_ORDER
            },
            {
                label: <FormattedMessage id='header.menuItem.purchaseInvoice.label' defaultMessage='' />,
                name: 'purchaseInvoice',
                // link: '/admin/purchase-invoice',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.PURCHASE_INVOICE
            },
            {
                label: <FormattedMessage id='header.menuItem.payInvoice.label' defaultMessage='' />,
                name: 'payInvoice',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.PAY_INVOICE
            },
            {
                label: <FormattedMessage id='header.menuItem.debitMemo.label' defaultMessage='' />,
                name: 'debitMemo',
                // link: '/admin/debit-memo-create',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.DEBIT_MEMO
            }
        ]
    },
    {
        label: <FormattedMessage id='header.menuItem.inventoryHead.label' defaultMessage='' />,
        name: 'inventoryHead',
        hierarchy: [
            {
                label: <FormattedMessage id='header.menuItem.inventory.label' defaultMessage='' />,
                name: 'inventory',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.PRODUCTS
            },
            {
                label: <FormattedMessage id='header.menuItem.priceList.label' defaultMessage='' />,
                name: 'priceList',
                // link: '/admin/price-list',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.PRICE_LIST
            },
            {
                label: <FormattedMessage id='header.menuItem.category.label' defaultMessage='' />,
                name: 'category',
                // link: '/admin/extras/product-category',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.PRODUCT_CATEGORY
            },
            {
                label: <FormattedMessage id='header.menuItem.uom.label' defaultMessage='' />,
                name: 'uom',
                // link: '/admin/extras/UOM',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.UOM
            },
            {
                label: <FormattedMessage id='header.menuItem.brands.label' defaultMessage='' />,
                name: 'brands',
                // link: '/admin/extras/product-brands',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.PRODUCT_BRAND
            },
            {
                label: <FormattedMessage id='header.menuItem.manufacturer.label' defaultMessage='' />,
                name: 'manufacturer',
                // link: '/admin/extras/product-manufacturer',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.PRODUCT_MANUFACTURER
            },
            {
                label: <FormattedMessage id='header.menuItem.warehouse.label' defaultMessage='' />,
                name: 'warehouse',
                // link: '/admin/warehouse/my-warehouse',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.MY_WAREHOUSE
            }
        ]
    },
    {
        label: <FormattedMessage id='header.menuItem.financeHead.label' defaultMessage='' />,
        name: 'financeHead',
        hierarchy: [
            {
                label: <FormattedMessage id='sidebar.menuItem.businessExpense' defaultMessage='' />,
                name: 'businessExpense',
                // link: '/admin/business-expense-create',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.LEDGER_ACCOUNTS
            },
            {
                label: <FormattedMessage id='header.menuItem.ledgerAccount.label' defaultMessage='' />,
                name: 'ledgerAccount',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.LEDGER_ACCOUNTS
            },
            // {
            //     label: <FormattedMessage id='header.menuItem.costCenter.label' defaultMessage='' />,
            //     name: 'costCenter',
            //     operation: PERMISSION_VALUES.CREATE,
            //     moduleCode: MODULE_CODED_VALUES.COST_CENTERS
            // },
            {
                label: <FormattedMessage id='header.menuItem.tax.label' defaultMessage='' />,
                name: 'tax',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.TAXES
            },
            {
                label: <FormattedMessage id='header.menuItem.journal.label' defaultMessage='' />,
                name: 'journal',
                // link: '/admin/create-journal',
                operation: PERMISSION_VALUES.CREATE,
                moduleCode: MODULE_CODED_VALUES.JOURNALS
            },
        ]
    }
];
