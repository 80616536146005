import React from 'react';
import '@ant-design/compatible/assets/index.css';
import {  injectIntl } from 'react-intl';
import './index.scss'
import TransactionTypes from './txnTypes'
import LoginUser from './login'
import TxnListing from './txnListing';
import UplaodFiles from './uplaodFiles';



function TransactionFileUpload(props){

    const {activePage} = props;
    return (
      <>
        {
          activePage === 'login' ? <LoginUser {...props}/> 
            : ''
        }
        {
          activePage === 'transactiontypes' ? <TransactionTypes {...props}/> 
            : ''
        }
        {
          activePage === 'transactionList' ? <TxnListing {...props}/> 
            : ''
        }
        {
          activePage === 'uploadFiles' ? <UplaodFiles {...props}/> 
            : ''
        }
        
        {/* <LoginUser /> */}
      </>

    );
}

export default injectIntl(TransactionFileUpload);
