import React from "react";
import { injectIntl } from "react-intl";
import "./index.scss";
import { Button, Divider } from "antd";
import { getCurrencySymbol } from "../../../utils";

const AlreadyRegisteredPopup = (props) => {

  const {registrationInvoiceProforma=[], pendingFeeProforma={}} = props;

  const invoiceData = JSON.parse(pendingFeeProforma.invoiceData || '{}')
  return (
    <div className="top-layer">
      <div className="info-container">
        <div className="full_cont">
          <div>
            <div className="head">
              Already Registered
            </div>
            <Divider />
            <div className="alert_msg">
              Your previous application is pending, please pay fees and confirm you application<br />
              Amount To be Paid - {getCurrencySymbol(invoiceData.currencyCode || 'USD')}{invoiceData.invoiceTotalAmount ? Math.round(invoiceData.invoiceTotalAmount) : 0}
            </div>

            <div className="pending_fee_details">

            </div>
          </div>

          <div className="info_footer">
            <Button 
              className="mt20"
              type="primary"
              onClick={()=>{
                props.history.push(`/proforma-payment?${encodeURIComponent(`rid=${pendingFeeProforma.relationshipId}&proformaId=${pendingFeeProforma.invoiceProformaId || 0}&customerId=${pendingFeeProforma.customerId || 0}`)}`)
              }}
            >
              Pay now
            </Button>

            <Divider className="mt15 mb15"/>

            <Button
              className="mb10"
              onClick={()=>{
                props.resetOrgInvoiceProforma();
                props.updateState({
                  isFeesPending: false
                });
              }}
            >
              Skip and Continue to new Registration
            </Button>
          </div>


        </div>
      </div>
    </div>
  );
};

export default injectIntl(AlreadyRegisteredPopup);
