import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AgedDebtorsComponent from '../../../../../components/modal/modalBody/reports/AgedDebtors';
import { fetchAgedDebtors } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';

class AgedDebtors extends Component {
    constructor(props) {
        super();
        this.state = {
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, companyInfo: this.props.companyInfo };
        fetchDataIfNeeded('fetchAgedDebtors', 'agedDebtorsList', this.props, payload, true);

    }

    render() {
        return <div>
            <AgedDebtorsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        agedDebtorsList: state.reports.agedDebtorsList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAgedDebtors
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgedDebtors);