import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddMarketplaceStateTaxComponent from "../../../../../../components/modal/modalBody/settings/taxes/AddMarketplaceStateTax";
import { fetchDataIfNeeded } from "../../../../../../utils";

class AddMarketplaceStateTax extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.rowData,
            configuredStates:props.configuredStates
        }
    }

    componentDidMount() {
        const payload = {
            relationshipId: (this.props.companyInfo || {}).relationshipId,
            countryId: this.props.countryId
        };
        fetchDataIfNeeded("getCountryStates", "countryStates", this.props, payload);
    }

    render() {
        return (
            <AddMarketplaceStateTaxComponent {...this.state} {...this.props} updateState={(data) => {
                this.setState(data);
            }} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        countryStates: state.taxSettingReducer.countryStates,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddMarketplaceStateTax);
