import React, { Component, Fragment } from "react";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, DatePicker, Tooltip, Input,Skeleton } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { Dropdown } from "../../../../general/Dropdown";
import { MultipleSelectDropdown } from "../../../../general/MultipleSelectDropdown";
import * as find from "lodash.find";
import moment from "moment-timezone";
import * as filter from "lodash.filter";
import { QuestionCircleTwoTone, EditOutlined } from "@ant-design/icons";
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
  ICONS,
} from "../../../../../static/constants";
import { AG_GRID_CONSTANTS } from "../../../../../static/agGridConstants";
import { addDaysToDate } from "../../../../../utils";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { showToasterMessage } from "../../../../../utils";
import { TextBox } from "../../../../general/TextBox";

const format = CONSTANTS.TABLE_DATE_FORMAT;
class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "default",
      billingAddress: this.defaultBillingAddress,
    };
  }

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  getDueDateChange = () => {
    const supplierObj = this.props.supplierPaymentTerm || {};
    const selectedterm = this.props.paymentTerms.length
      ? find(this.props.paymentTerms, {
          paymentTermId: supplierObj.paymentTermId || 0,
        }) || {}
      : {};
    if (selectedterm.numberOfDays) {
      const updatedInvoiceDueDate = addDaysToDate(
        this.props.invoiceDate || new Date(),
        selectedterm.numberOfDays
      );
      return updatedInvoiceDueDate;
    }
    return this.props.invoiceDueDate || new Date();
  };

  handleNewTaxIdentificationAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (taxIdentification) {
      if (props.supplier) {
        let list = props.supplier.supplierTaxIdentificationsList || [];
        list.push(taxIdentification);
        props.updateState({
          supplier: { ...props.supplier, supplierTaxIdentificationsList: list },
        });
      }
    };
    const data = {
      title: (
        <FormattedMessage
          id="addItem.text.taxIdentification"
          defaultMessage=""
        />
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (
      !formData ||
      (modalType === MODAL_TYPE.BILLING_ADDRESS && !formData.supplierId)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please Select Supplier",
      });
    }
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
      payload.formData = {
        addressType: "relationship",
        locationType: "RelationshipBillingAddress",
      };
    }
    payload.formData.callback = function (address) {
      props.hideModal(data);
      if (
        address.locationType === "BillingAddress" ||
        address.locationType === "ShippingAddress"
      ) {
        props.fetchAddresses({
          supplierId: address.supplierId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: address.locationType,
        });
      } else {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: this.getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      keyboard:false,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = function (contact) {
      props.hideModal(data);
      if (contact.supplierId) {
        props.fetchAllContacts({
          supplierId: contact.supplierId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    const data = {
      title: (
        <div>
          <FormattedMessage id="addItem.text.contact" defaultMessage="" />
          <div style={{ "font-size": "12px" }}>
            {" "}
            {formData.supplierName || ""}
          </div>
        </div>
      ),
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.project" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PROJECT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      },
    };
    showModal(data);
  };

  handleNewPaymentTermAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, hideModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: (
        <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
      ),
      formData,
      hideFooter: true,
      modalData: { modalType: MODAL_TYPE.PAYMENT_TERM, data: payload },
      handleSubmit: (formData = {}) => {
        if (
          formData.termName &&
          formData.termName.length &&
          formData.days !== 0 &&
          formData.days
        ) {
          hideModal();
          formData.relationshipId = (props.companyInfo || {}).relationshipId;
          props.addPaymentTerm(formData);
        } else {
          data.formData.submittedOnce = true;
          showModal(data);
        }
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = {
      text: text,
      relationshipId: companyInfo.relationshipId,
    };

    switch (modalType) {
      case MODAL_TYPE.SO_SOURCE: {
        addFunc = props.addSalesOrderSources;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        addFunc = props.addDocumentName;
        payload.txType =
          CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE];
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        title = (
          <FormattedMessage id="addItem.text.paymentTerm" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.billingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.supplier || {}).name} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.relationshipBillingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
        title = (
          <div>
            <FormattedMessage
              id="addItem.text.relationshipShippingAddress"
              defaultMessage=""
            />
            <h6>{(this.props.companyInfo || {}).storeName} </h6>
          </div>
        );
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        title = (
          <FormattedMessage id="addItem.text.documentName" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };



  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;
    let callback = function (data) {
      if (modalType === MODAL_TYPE.CONTACT && props.supplier.supplierId) {
        props.fetchAllContacts({
          supplierId: props.supplier.supplierId,
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
      if (
        modalType === MODAL_TYPE.BILLING_ADDRESS &&
        props.supplier.supplierId
      ) {
        props.fetchAddresses({
          supplierId: props.supplier.supplierId,
          relationshipId: (props.companyInfo || {}).relationshipId,
          locationType: "BillingAddress",
        });
      }
      if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
        props.getRelationshipAddress({
          relationshipId: (props.companyInfo || {}).relationshipId,
        });
      }
    };
    switch (modalType) {
      case MODAL_TYPE.PAYMENT_TERM: {
        deleteFunc = props.deletePaymentTerm;
        break;
      }
      case MODAL_TYPE.PROJECT: {
        deleteFunc = props.deleteProject;
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
        deleteFunc = props.deleteAddress;
        break;
      }
      case MODAL_TYPE.DOCUMENT_NAME: {
        deleteFunc = props.deleteDocumentName;
        break;
      }
      case MODAL_TYPE.CONTACT: {
        deleteFunc = props.deleteContact;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        deleteFunc = props.deleteRelationshipTaxIdentifications;
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER: {
        deleteFunc = props.deleteSupplierTaxIdentifications;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
          callback: callback,
        });
        if (
          modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER &&
          props.supplier &&
          props.supplier.supplierTaxIdentificationsList
        ) {
          let list = (
            props.supplier.supplierTaxIdentificationsList || []
          ).filter((x) => x.supplierTaxIdentificationId !== payload.id);
          props.updateState({
            supplier: {
              ...props.supplier,
              supplierTaxIdentificationsList: list,
            },
          });
        }
        if (
          modalType === MODAL_TYPE.DOCUMENT_NAME &&
          payload.id === this.props.documentNameId
        ) {
          const selectedObj =
            find(this.props.documentList || [], {
              txType:
                CONSTANTS_TRANSACTIONS_KEY[
                  CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE
                ],
              isDefault: 1,
            }) || {};
          this.props.updateState({
            documentNameId: selectedObj.documentNameId,
            docName: selectedObj.docName,
          });
        }
      },
    };
    showModal(modalData);
  };
  render() {
    const { size } = this.state;
    const { supplier = {} } = this.props;
    //const supplierObj = this.props.supplierDetail || {};
    //const supplierAddressObjs = (supplierObj.boLocationList || []).filter(x => x.locationType === CONSTANTS.BILLING_ADDRESS) || [];

    const supplierPaymentTerm = this.props.supplierPaymentTerm || {};
    const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
    if (rowNode) {
      rowNode.data["customerInquiryNumber"] = this.props.customerInquiryNumber;
      rowNode.data["customerPONumber"] = this.props.customerPONumber;
      rowNode.data["customerRFQNumber"] = this.props.customerRFQNumber;
      rowNode.data[CONSTANTS.SHIPPING_ADDRESS] =
        (this.props.relationshipShippingAddress || {}).streetAddress1 || "";
      rowNode.data[CONSTANTS.SHIPPING_ADDRESS] =
        (this.props.relationshipShippingAddress || {}).streetAddress1 || "";
      rowNode.data[AG_GRID_CONSTANTS.COMMON.PLACE_OF_SUPPLY_FIELD] =
        this.props.placeOfSupply || "";
      rowNode.data[
        AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD
      ] = supplierPaymentTerm.termName || "";
      rowNode.data["reference"] = this.props.reference;
      rowNode.data[AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT] =
        this.props.projectName;
      rowNode.data["supplierInvoiceNumber"] = this.props.supplierInvoiceNumber;
      rowNode.data["supplierPONumber"] = this.props.supplierPONumber;
      rowNode.data["supplierQuoteNumber"] = this.props.supplierQuoteNumber;
      this.gridApi.refreshCells({ force: true });
      this.gridApi.sizeColumnsToFit();
    }

    // const columnDefs = [
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.gridHeader.placeOfSupply"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: AG_GRID_CONSTANTS.COMMON.PLACE_OF_SUPPLY_FIELD,
    //     resizable: true,
    //     hide: (this.props.companyInfo || {}).countryName !== "India",
    //     editable: true,
    //     cellEditor: "customDropDownEditor",
    //     //pinned: 'left',
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.states || [],
    //         optionKeyName: "stateId",
    //         valueKeyName: "stateName",
    //         onSelect: (selectedValue) => {
    //           this.props.updateState({
    //             placeOfSupply: selectedValue,
    //           });
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //         },
    //         // allowClear: true,
    //         // onClear: () => {
    //         //     //obj.node.setDataValue(obj.colDef.field, undefined);
    //         //     this.props.updateState({
    //         //         placeOfSupply: undefined
    //         //     })
    //         // },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="modal.txDetails.inquiry.customerInquiryNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "customerInquiryNumber",
    //     hide: this.props.customerInquiryNumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage id="supplierPONumber.text" defaultMessage="" />
    //         </div>
    //       );
    //     },
    //     field: "supplierPONumber",
    //     hide: this.props.supplierPONumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage id="customerPONumber.text" defaultMessage="" />
    //         </div>
    //       );
    //     },
    //     field: "customerPONumber",
    //     hide: this.props.customerPONumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.rfq.form.gridHeader.customerRFQNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "customerRFQNumber",
    //     hide: this.props.customerRFQNumber ? false : true,
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.purchaseOrder.form.gridHeader.supplierInvoiceNumber"
    //             defaultMessage=""
    //           />
    //           <Tooltip
    //             placement="top"
    //             title={
    //               <FormattedMessage
    //                 id="common.You.can.enter.Supplier.Invoice.Number"
    //                 defaultMessage=""
    //               />
    //             }
    //             trigger="click"
    //           >
    //             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //     field: "supplierInvoiceNumber",
    //     resizable: true,
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             supplierInvoiceNumber: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.rfq.form.gridHeader.supplierQuoteNumber"
    //             defaultMessage=""
    //           />
    //         </div>
    //       );
    //     },
    //     field: "supplierQuoteNumber",
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             supplierQuoteNumber: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <div>
    //           <FormattedMessage
    //             id="supplier.purchaseinvoice.form.gridHeader.refNumber"
    //             defaultMessage=""
    //           />
    //           <Tooltip
    //             placement="top"
    //             title={
    //               <FormattedMessage
    //                 id="tooltip.referenceNumberAssign"
    //                 defaultMessage=""
    //               />
    //             }
    //             trigger="click"
    //           >
    //             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //     field: "reference",
    //     editable: true,
    //     cellEditor: "customTextEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         maxLength: 20,
    //         onChange: (val) => {
    //           this.props.updateState({
    //             reference: val,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.gridHeader.shippingAddress"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: CONSTANTS.SHIPPING_ADDRESS,
    //     editable: true,
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         value: (this.props.relationshipShippingAddress || {})
    //           .streetAddress1,
    //         items: this.props.allRelationshipShippingAddress || [],
    //         optionKeyName: "locationId",
    //         valueKeyName: "streetAddress1",
    //         addressLineKey1: "streetAddress2",
    //         addressLineKey2: "cityName",
    //         addressLineKey3: "stateName",
    //         addressLineKey4: "zipCode",
    //         isFavouriteKeyName: "isDefault",
    //         optionClassAddress: "custom-address-dropdown",
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(
    //             this.props,
    //             MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS,
    //             payload
    //           );
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
    //             addressType: "relationship",
    //           };
    //           this.handleNewAddressAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS
    //           );
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //           const relationshipShippingAddress = find(
    //             this.props.allRelationshipShippingAddress || [],
    //             { locationId: Number(optionObj.key) }
    //           );
    //           this.props.updateState({
    //             relationshipShippingAddress,
    //             placeOfSupply: (relationshipShippingAddress || {}).stateName,
    //           });
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             relationshipShippingAddress: undefined,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.gridHeader.paymentTerms"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field:
    //       AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PAYMENT_TERM_FIELD,
    //     editable: true,
    //     //pinned: 'right',
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.paymentTerms,
    //         optionKeyName: "paymentTermId",
    //         valueKeyName: "termName",
    //         isLockKeyName: "isSystemGenerated",
    //         //isFavouriteKeyName: 'isDefault',
    //         canAddNew: true,
    //         canDelete: true,
    //         // permissions: this.props.permissions,
    //         // moduleName: MODULE_CODED_VALUES.PAYMENT_TERM,
    //         // operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(
    //             this.props,
    //             MODAL_TYPE.PAYMENT_TERM,
    //             payload
    //           );
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             termName: payload.textEntered,
    //             days: null,
    //             description: "",
    //             isDefaultPayment: false,
    //             submittedOnce: false,
    //           };
    //           this.handleNewPaymentTermAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.PAYMENT_TERM
    //           );
    //         },
    //         onSelect: (selectedValue, optionObj) => {
    //           //obj.node.setDataValue(obj.colDef.field, selectedValue)
    //           const supplierPaymentTerm = find(this.props.paymentTerms, {
    //             paymentTermId: Number(optionObj.key),
    //           });
    //           let invoiceDueDate = addDaysToDate(
    //             this.props.invoiceDate || new Date(),
    //             supplierPaymentTerm.numberOfDays
    //           );
    //           this.props.updateState({
    //             supplierPaymentTerm,
    //             invoiceDueDate,
    //           });
    //         },
    //       };
    //     },
    //   },
    //   {
    //     headerComponentFramework: () => {
    //       return (
    //         <FormattedMessage
    //           id="supplier.purchaseinvoice.form.projectDropdown.label"
    //           defaultMessage=""
    //         />
    //       );
    //     },
    //     field: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PROJECT,
    //     editable: true,
    //     //pinned: 'right',
    //     cellEditor: "customDropDownEditor",
    //     cellEditorParams: (obj) => {
    //       return {
    //         lastColumnCell: false,
    //         items: this.props.projectList,
    //         valueKeyName: "projectName",
    //         value: this.props.projectName,
    //         optionKeyName: "projectMasterId",
    //         projectIdKey: "projectMasterId",
    //         canAddNew: true,
    //         canDelete: true,
    //         deleteClickHandler: (payload) => {
    //           this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
    //         },
    //         onAddNewClick: (payload) => {
    //           payload.formData = {
    //             projectName: payload.projectName,
    //           };
    //           this.handleNewProjectAddition(
    //             this.props,
    //             payload,
    //             MODAL_TYPE.PROJECT
    //           );
    //         },
    //         onSelect: (selectedValue, option) => {
    //           const selectedProject =
    //             find(this.props.projectList, {
    //               projectMasterId: Number(option.key),
    //             }) || {};
    //           this.props.updateState({
    //             projectMasterId: selectedProject.projectMasterId,
    //             projectName: selectedProject.projectName,
    //             projectNumber: selectedProject.projectNumber,
    //           });
    //         },
    //         allowClear: true,
    //         onClear: () => {
    //           //obj.node.setDataValue(obj.colDef.field, undefined);
    //           this.props.updateState({
    //             projectMasterId: undefined,
    //             projectName: undefined,
    //             projectNumber: undefined,
    //           });
    //         },
    //       };
    //     },
    //   },
    // ];

    // const onGridReady = (params) => {
    //   this.gridApi = params.api;
    //   this.gridApi.sizeColumnsToFit();
    //   this.gridColumnApi = params.columnApi;
    // };
    const openAddTransportDrawer = () => {
      this.props.updateState({
        title: (<FormattedMessage
              id="customer.salesInvoice.form.transportData.label"
              defaultMessage=""
            />),
        transportDataDrawerVisible: true,
        addTransportDataToSO: function (transportData) {
          this.updateState({
              ...transportData
          })
      }
      });
    }
    return (
      <Fragment>
        <Row>
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

            <Row gutter={[0, 3]}>
              {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
              {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 +'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()} /> */}
              <Col span={24} className="details-heading">
                Organization Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.name"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="deatils-field-value">
                  {(this.props.companyInfo || {}).storeName}
                </div>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="sales.order.create.organization.adress"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.companyBillingAddress",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <span>
                  {!this.props.addressHide &&
                  (this.props.relationshipBillingAddress || {})
                    .streetAddress1 ? (
                    <>
                      <div className="detail-address-value">
                        <div style={{ height: "96px", paddingTop: "10px" }}>
                          {getAddressInfoFragment(
                            this.props.relationshipBillingAddress || {},
                            {},
                            this.props.companyInfo || {}
                          )}
                        </div>
                        <div
                          onClick={() => {
                            
                            this.props.updateState({
                              addressHide: true,
                            });
                          }}
                        >
                          <EditOutlined />
                        </div>
                      </div>
                    </>
                  ) : (
                    <Dropdown
                      style={{ width: "100%" }}
                      value={
                        (this.props.relationshipBillingAddress || {})
                          .streetAddress1
                      }
                      items={this.props.allRelationshipBillingAddress}
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      isFavouriteKeyName="isDefault"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          relationshipBillingAddress: undefined,
                        });
                      }}
                      //permissions={this.props.permissions}
                      //moduleName={MODULE_CODED_VALUES.SUPPLIER_BILLING_ADDRESS}
                      //operations={[PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE]}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS
                        );
                      }}
                      onSelect={(locationId) => {
                        let relationshipBillingAddress = this.props.allRelationshipBillingAddress.find((x) => x.locationId === locationId);
                        this.props.updateState({
                          relationshipBillingAddress:relationshipBillingAddress,
                          addressHide: false,
                          placeOfSupply: (relationshipBillingAddress || {}).stateName,
                        });
                      }}
                    />
                  )}
                </span>
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.form.taxIdentification.label"
                    defaultMessage=""
                  />
                  <Tooltip
                    placement="right"
                    title={this.props.intl.formatMessage({
                      id: "tooltip.taIdentification",
                      defaultMessage: "",
                    })}
                    trigger="click"
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>

                <MultipleSelectDropdown
                  style={{ width: "100%" }}
                  items={this.props.allRelationshipTaxIdentificatins || []}
                  placeholder="Tax Identification(s)"
                  mode="multiple"
                  onDeSelect={(value) => {
                    let { selectedRelationshipTaxIdentifications } = this.props;
                    selectedRelationshipTaxIdentifications = filter(
                      selectedRelationshipTaxIdentifications,
                      (taxId) => {
                        return taxId !== value;
                      }
                    );
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });
                  }}
                  valueKeyName="taxIdentificationType"
                  valueKeyName2="taxIdentificationNumber"
                  selectedValue={
                    (this.props.allRelationshipTaxIdentificatins || []).length
                      ? this.props.selectedRelationshipTaxIdentifications || []
                      : []
                  }
                  optionKeyName="relationshipTaxIdentificationId"
                  onSelect={(relationshipTaxIdentificationId) => {
                    let selectedRelationshipTaxIdentifications =
                      this.props.selectedRelationshipTaxIdentifications || [];
                    if (
                      selectedRelationshipTaxIdentifications.indexOf(
                        relationshipTaxIdentificationId
                      ) === -1
                    ) {
                      selectedRelationshipTaxIdentifications.push(
                        relationshipTaxIdentificationId
                      );
                    } else {
                      selectedRelationshipTaxIdentifications =
                        selectedRelationshipTaxIdentifications.filter((el) => {
                          return el !== selectedRelationshipTaxIdentifications;
                        });
                    }
                    this.props.updateState({
                      selectedRelationshipTaxIdentifications,
                    });
                  }}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      taxIdentificationNumber: payload.textEntered,
                      submittedOnce: false,
                    };
                    this.handleNewTaxIdentificationAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.NEW_TAX_IDENTIFICATION
                    );
                  }}
                />
              </Col>
            </Row>
          </Skeleton>  
          </Col>
          <Col span={1}></Col>
          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>
            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Supplier Information
              </Col>

              {supplier.name ? (
                <>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id="supplierName" defaultMessage="" />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="deatils-field-value">
                      {/* <FormattedMessage id='supplier.purchaseinvoice.form.supplier.label' defaultMessage='' /> */}
                      {supplier.name}
                    </div>
                  </Col>

                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage id="contact" defaultMessage="" />
                      <Tooltip
                        placement="right"
                        title={
                          <FormattedMessage
                            id="common.supplier.contact"
                            defaultMessage=""
                          />
                        }
                        trigger="click"
                      >
                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                      </Tooltip>
                    </div>
                  </Col>

                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      value={
                        ((this.props.selectedContact || {}).firstName || "") +
                        " " +
                        ((this.props.selectedContact || {}).lastName || "")
                      }
                      optionKeyName="contactId"
                      items={this.props.boContactList}
                      valueKeyName="firstName"
                      lastNameKey="lastName"
                      placeholder="Please select contact name"
                      isFavouriteKeyName="isPrimaryContact"
                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({ selectedContact: undefined });
                      }}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.CONTACT,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          firstName: payload.textEntered,
                          submittedOnce: false,
                          supplierId: (this.props.supplier || {}).id,
                          pageNumber: this.props.pageNumber || 1,
                          supplierName: supplier.name,
                        };
                        this.handleNewContactAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.CONTACT
                        );
                      }}
                      onSelect={(contactId) => {
                        let contact =
                          find(this.props.boContactList, {
                            contactId: +contactId,
                          }) || {};
                        this.props.updateState({
                          selectedContact: contact,
                        });
                      }}
                    />
                  </Col>

                  <Col span={24} >
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="signup.tab.address"
                        defaultMessage=""
                      />
                      <Tooltip
                        placement="right"
                        title={
                          <FormattedMessage
                            id="common.supplier.address"
                            defaultMessage=""
                          />
                        }
                        trigger="click"
                      >
                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Dropdown
                      style={{ width: "100%" }}
                      value={
                        (this.props.supplierBillingAddress || {}).streetAddress1
                      }
                      items={this.props.allBillingAddress}
                      valueKeyName="streetAddress1"
                      addressLineKey1="streetAddress2"
                      addressLineKey2="cityName"
                      addressLineKey3="stateName"
                      addressLineKey4="zipCode"
                      optionClassAddress="custom-address-dropdown"
                      optionKeyName="locationId"
                      isFavouriteKeyName="isDefault"
                      canAddNew={true}
                      canDelete={true}
                      allowClear={true}
                      onClear={() => {
                        this.props.updateState({
                          supplierBillingAddress: undefined,
                        });
                      }}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.BILLING_ADDRESS,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          locationType: CONSTANTS.BILLING_ADDRESS,
                          supplierId: (this.props.supplier || {}).supplierId,
                          pageNumber: this.props.pageNumber,
                        };
                        this.handleNewAddressAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.BILLING_ADDRESS
                        );
                      }}
                      onSelect={(locationId) => {
                        let supplierBillingAddress =
                          this.props.allBillingAddress.find(
                            (x) => x.locationId === locationId
                          );
                        let isPlaceOfSupplySameAsRelationship =
                          (supplierBillingAddress || {}).stateName ===
                          this.props.placeOfSupply;
                        this.props.updateState({
                          supplierBillingAddress: supplierBillingAddress || {},
                    
                          isPlaceOfSupplySameAsRelationship,
                        });
                      }}
                    />
                  </Col>

                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="customer.form.taxIdentification.label"
                        defaultMessage=""
                      />
                      <Tooltip
                        placement="right"
                        title={
                          <FormattedMessage
                            id="taxIdentification.tooltip"
                            defaultMessage=""
                          />
                        }
                        trigger="click"
                      >
                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                      </Tooltip>
                    </div>
                  </Col>

                  <Col span={24}>
                    <MultipleSelectDropdown
                      style={{ width: "100%" }}
                      items={
                        this.props.supplier.supplierTaxIdentificationsList || []
                      }
                      placeholder="Tax Identification(s)"
                      mode="multiple"
                      onDeSelect={(value) => {
                        let { selectedSupplierTaxIdentifications } = this.props;
                        selectedSupplierTaxIdentifications = filter(
                          selectedSupplierTaxIdentifications,
                          (taxId) => {
                            return taxId !== value;
                          }
                        );
                        this.props.updateState({
                          selectedSupplierTaxIdentifications,
                        });
                      }}
                      valueKeyName="taxIdentificationType"
                      valueKeyName2="taxIdentificationNumber"
                      selectedValue={
                        (
                          this.props.supplier.supplierTaxIdentificationsList ||
                          []
                        ).length
                          ? this.props.selectedSupplierTaxIdentifications || []
                          : []
                      }
                      optionKeyName="supplierTaxIdentificationId"
                      onSelect={(supplierTaxIdentificationId) => {
                        let selectedSupplierTaxIdentifications =
                          this.props.selectedSupplierTaxIdentifications || [];
                        if (
                          selectedSupplierTaxIdentifications.indexOf(
                            supplierTaxIdentificationId
                          ) === -1
                        ) {
                          selectedSupplierTaxIdentifications.push(
                            supplierTaxIdentificationId
                          );
                        } else {
                          selectedSupplierTaxIdentifications =
                            selectedSupplierTaxIdentifications.filter((el) => {
                              return el !== supplierTaxIdentificationId;
                            });
                        }
                        this.props.updateState({
                          selectedSupplierTaxIdentifications,
                        });
                      }}
                      canAddNew={true}
                      canDelete={true}
                      deleteClickHandler={(payload) => {
                        this.deleteClickHandler(
                          this.props,
                          MODAL_TYPE.NEW_TAX_IDENTIFICATION_SUPPLIER,
                          payload
                        );
                      }}
                      onAddNewClick={(payload) => {
                        payload.formData = {
                          taxIdentificationNumber: payload.textEntered,
                          submittedOnce: false,
                          supplierId: (this.props.supplier || {}).id,
                          pageNumber: this.props.pageNumber || 1,
                          supplierName: supplier.name,
                        };
                        this.handleNewTaxIdentificationAddition(
                          this.props,
                          payload,
                          MODAL_TYPE.NEW_TAX_IDENTIFICATION
                        );
                      }}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
          </Skeleton>  
          </Col>

          <Col span={1}></Col>

          <Col span={5}>
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
                Delivery Information
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">eWay Bill No.</div>
              </Col>
              <Col span={24}>
                <Input
                  placeholder="eWay Bill No."
                  type="text"
                  value={this.props.ewayBillNo}
                  onChange={(e) => {
                    this.props.updateState({
                      ewayBillNo: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col span={24}>
                <div className="details-heading-subheading">Date</div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  key={`${
                    this.props.invoiceDate
                      ? moment(new Date(this.props.invoiceDate), format)
                      : moment(new Date(), format)
                  }`}
                  defaultValue={
                   moment(new Date(), format)
                  }
                  onChange={(selectedDate) => {
                    this.props.updateState({
                      eBayBillDate: selectedDate,
                    });
                  }}
                  size={size}
                />
              </Col>
              {/* <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesInvoice.form.transportData.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                {this.props.nameOfTransport ? 
                  <Row>
                    <Col span={22}>
                      Name - {this.props.nameOfTransport}
                    </Col>
                    <Col span={2}>
                      <i className={ICONS.EDIT_P} onClick={openAddTransportDrawer}/>
                    </Col>

                    {!!this.props.vehicleNumber && 
                      <Col span={22}>
                        Vehicle Number - {this.props.vehicleNumber}
                      </Col>
                    }
                    {!!this.props.freightType && 
                      <Col span={22}>
                        Freight Type - {this.props.freightType}
                      </Col>
                    }
                    {!! this.props.Consignee &&
                      <Col span={22}>
                        Consignee - {this.props.Consignee}
                      </Col>
                    }
                    {!!this.props.station && 
                      <Col span={22}>
                        Station - {this.props.station}
                      </Col>
                    }
                  </Row>
                  :
                  <div
                    className="cursor-pointer"
                    onClick={openAddTransportDrawer}
                  >
                    <FormattedMessage
                      id="forgot.btn.addTransportData"
                      defaultMessage=""
                    />
                  </div>
                }
              </Col> */}
            </Row>
          </Skeleton>
          </Col>

          <Col span={1}></Col>

          <Col span={6} className="order-value-info">
          <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

            <Row gutter={[0, 3]}>
              <Col span={24} className="details-heading">
               Invoice Information
              </Col>

              {/* {supplier.name ?
                                        <div className="date-picker-container">
                                            <Row>
                                                <Col span={8} className='detail-column'>
                                                    <FormattedMessage id='supplier.purchaseinvoice.form.supplierName.label' defaultMessage='' />
                                                </Col>
                                                <Col span={16}>
                                                    {supplier.name}
                                                </Col>
                                            </Row>
                                            {
                                                this.props.supplier.gstNumber &&
                                                <Row>
                                                    <Col span={8} className='detail-column'>
                                                        <div className="title">
                                                            <FormattedMessage id='supplier.purchaseinvoice.form.sgtIn.label' defaultMessage='' />
                                                        </div>
                                                    </Col>
                                                    <Col span={16}>
                                                        <div className="value">
                                                            {this.props.supplier.gstNumber}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }

                                            {
                                                this.props.supplier.panNumber &&
                                                <Row>
                                                    <Col span={8} className='detail-column'>
                                                        <div className="title">
                                                            <FormattedMessage id='supplier.purchaseinvoice.form.pan.label' defaultMessage='' />
                                                        </div>
                                                    </Col>
                                                    <Col span={16}>
                                                        <div className="value">
                                                            {this.props.supplier.panNumber}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </div> : null} */}
              <div className="date-picker-container">
                {/* <Row>
                                            <Col span={8} className='detail-column'>
                                                <Tooltip placement="bottom" title={<FormattedMessage id='common.System.Generated.Purchase.Invoice.Number' defaultMessage='' />}>
                                                    <span className="tooltip-title">
                                                        <FormattedMessage id='supplier.purchaseinvoice.form.invoiceNumber.label' defaultMessage='' />
                                                    </span>
                                                </Tooltip>
                                            </Col>
                                            <Col span={16} style={{ 'textAlign': 'left' }}>
                                                {this.props.nextTxNumber ?
                                                    <span>{getFormatedNextTxNumber(this.props)}</span>
                                                    :
                                                    <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'marginTop': '-25px', 'marginBottom': '-25px' }} />
                                                }
                                            </Col>
                                        </Row> */}
              </div>
              {this.props.supplierPo && (
                <>
                  <Col span={24} className="detail-column">
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="supplier.purchaseinvoice.form.poNumber.label"
                        defaultMessage=""
                      /> &nbsp; - &nbsp;
                       {/* <div className="deatils-field-value"> */}
                      {this.props.supplierPo}
                    {/* </div> */}
                    </div>
                  </Col>
                  {/* <Col span={24}>
                    <div className="deatils-field-value">
                      {this.props.supplierPo}
                    </div>
                  </Col> */}
                </>
              )}
              {this.props.inboundDeliveryNumber && (
                <>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="supplier.purchaseinvoice.form.idNumber.label"
                        defaultMessage=""
                      /> &nbsp; - &nbsp;
                      {this.props.inboundDeliveryNumber}
                    </div>
                  </Col>
                  {/* <Col span={24}>
                    <div className="deatils-field-value">
                      {this.props.inboundDeliveryNumber}
                    </div>
                  </Col> */}
                </>
              )}

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="supplier.purchaseinvoice.form.invoiceDate.label"
                    defaultMessage=""
                  />
                </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  key={`${
                    this.props.invoiceDate
                      ? moment(new Date(this.props.invoiceDate), format)
                      : moment(new Date(), format)
                  }`}
                  defaultValue={
                    this.props.invoiceDate
                      ? moment(new Date(this.props.invoiceDate), format)
                      : moment(new Date(), format)
                  }
                  onChange={(selectedDate) => {
                    let selectedterm = this.props.supplierPaymentTerm || {};
                    const updatedInvoiceDueDate = addDaysToDate(
                      selectedDate || new Date(),
                      selectedterm.numberOfDays
                    );
                    this.props.updateState({
                      invoiceDate: selectedDate,
                      invoiceDueDate: updatedInvoiceDueDate,
                    });
                    // this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                  }}
                  size={size}
                />
              </Col>

              <Col span={24}>
                <div className="details-heading-subheading"> Expiration Date </div>
              </Col>
              <Col span={24}>
                <DatePicker
                  allowClear={false}
                  format={format}
                  disabled={true}
                  key={`${moment(this.getDueDateChange(), format)}`}
                  defaultValue={moment(this.getDueDateChange(), format)}
                  size={size}
                />
              </Col>

              {this.props.customerId ? (
                <>
                  <Col span={24}>
                    <div className="details-heading-subheading">
                      <FormattedMessage
                        id="common.customer.text"
                        defaultMessage=""
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="deatils-field-value">
                      {this.props.customerName || ""}
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}

              {/* <div className="project-dropdown mt5">
                                        <Row>
                                            <Col span={8} className='detail-column'>
                                                <FormattedMessage id='supplier.purchaseinvoice.form.projectDropdown.label' defaultMessage='' />
                                            </Col>
                                            <Col span={16}>
                                                <Dropdown
                                                    items={this.props.projectList}
                                                    valueKeyName='projectName'
                                                    value={this.props.projectName}
                                                    optionKeyName='projectMasterId'
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            projectName: payload.projectName,
                                                            projectNumber: payload.projectNumber,
                                                        }
                                                        this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                                                    }}
                                                    onSelect={(projectMasterId) => {
                                                        const selectedProject = find(this.props.projectList, { projectMasterId: Number(projectMasterId) }) || {};
                                                        this.props.updateState({
                                                            projectMasterId,
                                                            projectName: selectedProject.projectName,
                                                            projectNumber: selectedProject.projectNumber
                                                        });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </div> */}

              <Col span={24}>
                <div className="details-heading-subheading">
                  <FormattedMessage
                    id="customer.salesOrder.form.documentDropdown.label"
                    defaultMessage=""
                  />

                  <Tooltip
                    placement="right"
                    title={
                      <FormattedMessage
                        id="documentTitle.tooltip"
                        defaultMessage=""
                      />
                    }
                  >
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
              </Col>
              <Col span={24}>
                <Dropdown
                  style={{ width: "100%" }}
                  items={filter(this.props.documentList || [], (document) => {
                    return (
                      document.txType ===
                      CONSTANTS_TRANSACTIONS_KEY[
                        CONSTANTS_TRANSACTIONS.PURCHASE_INVOICE
                      ]
                    );
                  })}
                  placeholder="Document name"
                  value={this.props.docName}
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(
                      this.props,
                      MODAL_TYPE.DOCUMENT_NAME,
                      payload
                    );
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false,
                      maxLength: 30,
                    };
                    this.handleNewItemAddition(
                      this.props,
                      payload,
                      MODAL_TYPE.DOCUMENT_NAME
                    );
                  }}
                  onSelect={(selectedValue) => {
                    const selectedObj = find(this.props.documentList || [], {
                      documentNameId: Number(selectedValue),
                    });
                    this.props.updateState({
                      documentNameId: selectedValue,
                      docName: selectedObj.docName,
                    });
                  }}
                  valueKeyName="docName"
                  optionKeyName="documentNameId"
                  isLockKeyName="isSystemGenerated"
                />
              </Col>
            </Row>
          </Skeleton>
          </Col>
        </Row>
        <Row>
        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 2 }}>

          <Col span={24}>
            <div className="txn-table">
              <table>
                <thead>
                  <tr>
                  { (this.props.companyInfo || {}).countryName ==="India" ?
                    <th width="10%">
                      <FormattedMessage
                        id="supplier.purchaseinvoice.form.gridHeader.placeOfSupply"
                        defaultMessage=""
                      />
                    </th>
                    :""}
                    <th width="10%">
                      <FormattedMessage
                        id="supplier.purchaseOrder.form.gridHeader.supplierInvoiceNumber"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="supplier.rfq.form.gridHeader.supplierQuoteNumber"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="supplier.purchaseinvoice.form.gridHeader.refNumber"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="supplier.purchaseinvoice.form.gridHeader.shippingAddress"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="supplier.purchaseinvoice.form.gridHeader.paymentTerms"
                        defaultMessage=""
                      />
                    </th>
                    <th width="10%">
                      <FormattedMessage
                        id="supplier.purchaseinvoice.form.projectDropdown.label"
                        defaultMessage=""
                      />
                    </th>
                
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  { (this.props.companyInfo || {}).countryName ==="India" ?
                    <td>
                      <Dropdown
                        lastColumnCell={false}
                        items={this.props.states || []}
                        value={this.props.placeOfSupply|| this.props.supplierBillingAddress?.stateName}
                        optionKeyName="stateId"
                        valueKeyName="stateName"
                        onSelect={(selectedValue,option) => {
                          this.props.updateState({
                            placeOfSupply:option.name,
                          });
                          // obj.node.setDataValue(obj.colDef.field, selectedValue)
                        }}
                      />
                    </td>
                    :""}
                    <td>
                      <TextBox
                        maxLength={20}
                        value={this.props.supplierInvoiceNumber}
                        onBlur={()=>{ 
                          if(this.props.supplierInvoiceNumber?.length &&  this.props.supplierInvoiceNumberReplica !== this.props.supplierInvoiceNumber && this.props.supplier){
                            this.props.checkSupplierInvoiceNumber(this.props,this.props.supplierInvoiceNumber,this.props.supplier?.id)
                          }
                        }}
                        onChange={(val) => {
                          this.props.updateState({
                            supplierInvoiceNumber: val.target.value,
                          })
                        }}
                      />
                    </td>
                    <td>
                      <TextBox
                        maxLength={20}
                        value={this.props.supplierQuoteNumber}
                        onChange={(val) => {
                          this.props.updateState({
                            supplierQuoteNumber: val.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <TextBox
                        maxLength={20}
                        value={this.props.reference}
                        onChange={(val) => {
                          this.props.updateState({
                            reference: val.target.value,
                          });
                        }}
                      />
                    </td>
                    <td>
                      <Dropdown
                        lastColumnCell={false}
                        value={
                          (this.props.relationshipShippingAddress || {})
                            .streetAddress1
                        }
                        items={this.props.allRelationshipShippingAddress || []}
                        optionKeyName="locationId"
                        valueKeyName="streetAddress1"
                        addressLineKey1="streetAddress2"
                        addressLineKey2="cityName"
                        addressLineKey3="stateName"
                        addressLineKey4="zipCode"
                        isFavouriteKeyName="isDefault"
                        optionClassAddress="custom-address-dropdown"
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            locationType:
                              CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS,
                            addressType: "relationship",
                          };
                          this.handleNewAddressAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS
                          );
                        }}
                        onSelect={(selectedValue, optionObj) => {
                          const relationshipShippingAddress = find(
                            this.props.allRelationshipShippingAddress || [],
                            { locationId: Number(optionObj.key) }
                          );
                          this.props.updateState({
                            relationshipShippingAddress,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            relationshipShippingAddress: undefined,
                          });
                        }}
                      />
                    </td>

                    <td>
                      <Dropdown
                        lastColumnCell={false}
                        items={this.props.paymentTerms}
                        optionKeyName="paymentTermId"
                        valueKeyName="termName"
                        isLockKeyName="isSystemGenerated"
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.PAYMENT_TERM,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            termName: payload.textEntered,
                            days: null,
                            description: "",
                            isDefaultPayment: false,
                            submittedOnce: false,
                          };
                          this.handleNewPaymentTermAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.PAYMENT_TERM
                          );
                        }}
                        value={this.props.supplierPaymentTerm?.paymentTermId}
                        onSelect={(selectedValue, optionObj) => {
                          const supplierPaymentTerm = find(
                            this.props.paymentTerms,
                            {
                              paymentTermId: Number(optionObj.key),
                            }
                          );
                          let invoiceDueDate = addDaysToDate(
                            this.props.invoiceDate || new Date(),
                            supplierPaymentTerm.numberOfDays
                          );
                          this.props.updateState({
                            supplierPaymentTerm,
                            invoiceDueDate,
                          });
                        }}
                      />
                    </td>

                    <td>
                      <Dropdown
                        items={this.props.projectList}
                        valueKeyName="projectName"
                        value={this.props.projectName}
                        optionKeyName="projectMasterId"
                        projectIdKey="projectMasterId"
                        canAddNew={true}
                        canDelete={true}
                        deleteClickHandler={(payload) => {
                          this.deleteClickHandler(
                            this.props,
                            MODAL_TYPE.PROJECT,
                            payload
                          );
                        }}
                        onAddNewClick={(payload) => {
                          payload.formData = {
                            projectName: payload.projectName,
                          };
                          this.handleNewProjectAddition(
                            this.props,
                            payload,
                            MODAL_TYPE.PROJECT
                          );
                        }}
                        onSelect={(projectMasterId, option) => {
                          const selectedProject =
                            find(this.props.projectList, {
                              projectMasterId: Number(option.key),
                            }) || {};
                          this.props.updateState({
                            projectMasterId: selectedProject.projectMasterId,
                            projectName: selectedProject.projectName,
                            projectNumber: selectedProject.projectNumber,
                          });
                        }}
                        allowClear={true}
                        onClear={() => {
                          this.props.updateState({
                            projectMasterId: undefined,
                            projectName: undefined,
                            projectNumber: undefined,
                          });
                        }}
                      />
                    </td>
                   
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Skeleton>
        </Row>
        {/* <Row>
          <CustomAgGrid
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            disableFocusOnClickOutside={true}
            rowData={[{}]}
            gridStyle={{
              width: "100%",
              height: "100px",
            }}
          />
        </Row> */}
      </Fragment>
    );
  }
}

const getAddressInfoFragment = (obj, supplierContactObj, supplierObj) => {
  if (!obj || !Object.keys(obj).length) {
    return "";
  }

  const fragmentObj = (
    <Fragment>
      <div className="billing-address first-caps">
        {obj.streetAddress1 || obj.streetAddress2 ? (
          <div>
            {obj.streetAddress1} {obj.streetAddress2}{", "}
          </div>
        ) : ""}
        {obj.cityName || obj.stateName || obj.zipCode ? (
          <div>
            {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
          </div>
        ) : ""}
        {obj.countryName ? (
          <div>{obj.countryName}</div>
        ) : ""}
      </div>
      {supplierContactObj.workPhone ? (
        <div className="billing-address">
          <FormattedMessage
            id="supplier.purchaseinvoice.form.phone.label"
            defaultMessage=""
          />{" "}
          - {supplierContactObj.workPhone}
        </div>
      ) : (
        ""
      )}
      {supplierContactObj.email ? (
        <div className="billing-address">
          <FormattedMessage
            id="supplier.purchaseinvoice.form.email.label"
            defaultMessage=""
          />{" "}
          - {supplierContactObj.email}
        </div>
      ) : (
        ""
      )}
      {/* {
            supplierObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.purchaseinvoice.form.gstIn.label' defaultMessage='' /> - {supplierObj.gstNumber}
                </div> :
                ''
        }
        {
            supplierObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.purchaseinvoice.form.pan.label' defaultMessage='' />  - {supplierObj.panNumber}
                </div> :
                ''
        } */}
    </Fragment>
  );

  return fragmentObj;
};

export default injectIntl(InvoiceDetails);
