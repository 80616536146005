import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Pagination, Menu, Checkbox, Skeleton, Switch } from "antd";
import { getMomentDateForUIReadOnly, showToasterMessage, sortTable } from "../../../utils";
import { CONSTANTS, } from '../../../static/constants';
// import { Dropdown } from '../../general/Dropdown';
import PageBreadcrumb from "../../PageBreadcrumb";
// import AssignClassToStudent from "../../../containers/modal/modalBody/schoolManagement/AssignClassToStudent";
import * as find from 'lodash.find';
// import ClassDetails from './ClassDetails';
// import QuestionDetails from './QuestionDetails';
import './index.scss';
import DropdownAction from "antd/lib/dropdown";
// import checkIcon from '../../../assets/images/check.png';
// import crossIcon from '../../../assets/images/cross.png';
import { DownloadOutlined, FilePdfOutlined,MoreOutlined, FilterOutlined } from '@ant-design/icons';
import CustomerRegistration from "../../../containers/modal/modalBody/schoolManagement/CustomerRegistration";
import UnpaidInvoices from "../../../containers/modal/modalBody/schoolManagement/UnpaidInvoices";


// const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const Parent = (props) => {
  const { selectedItems=[],  parentList, updateState, companyInfo, userInfo, parentCount, pageNumber, dir=0, } = props;
    
  //   const [isAllChecked, toggleAllChecked] = useState(false);
  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };


  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.parents' defaultMessage='' />,
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.parentsInfo' defaultMessage='' />,
    },
  ];
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: pageNumber,
    //   pageSize: pageSize,
    // };
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 200,
      formId: (props.selectedForm || {}).registrationFormSettingId
    };
    props.getRegisteredMemberByFormId(payload);
    props.getRegisteredMemberCount(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: pageNumber,
    //   pageSize: pageSize,
    // };
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 200,
      formId: (props.selectedForm || {}).registrationFormSettingId
    };
    
    props.resetPaginatedData();
    //props.getTeacher(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getRegisteredMemberByFormId(payload);
    props.getRegisteredMemberCount(payload);
  };

  // const handleNewContactAddition = (contactData, props) => {
  //   const { pushModalToStack, companyInfo } = props;
  //   let payload = {};
  //   let formData = {
  //     customerId: props.customerId,
  //     contactData,
  //     isTeacher: true
  //     // pageNumber: pageNumber || 1,
  //     // relationshipId: companyInfo.relationshipId
  //   }

  //   formData.callback = function (contact) {
  //     props.fetchAllContacts({
  //       companyInfo: props.companyInfo,
  //       relationshipId: props.companyInfo.relationshipId,
  //     })
  //   }
  //   payload.formData = formData;
  //   const data = {
  //     title: <FormattedMessage id='sidebar.menuItem.teachers' defaultMessage='' />,
  //     formData,
  //     hideFooter: true,
  //     modalData: {
  //       modalType: MODAL_TYPE.CONTACT,
  //       data: payload,

  //     },
  //     handleSubmit: (formData = {}) => {
  //       data.formData.submittedOnce = true;
  //     }
  //   };
  //   pushModalToStack(data);
  // }

  // const openClassDetailPopup = (studentObj) => {
  //   const modalData = {
  //       title: <div>
  //           <FormattedMessage id='sidebar.menuItem.classDetails' defaultMessage='' />
  //       </div>,
  //       modalBody: <ClassDetails {...props} studentObj={studentObj} />,
  //       width: '50%',
  //       hideFooter: true,
  //   };
  
  //   props.pushModalToStack(modalData);
  // }

  // const openQuestionDetailPopup = (studentObj) => {
  //   const modalData = {
  //       title: <div>
  //          Question And Answer
  //       </div>,
  //       modalBody: <QuestionDetails {...props} studentObj={studentObj} />,
  //       width: '50%',
  //       hideFooter: true,
  //   };
  
  //   props.pushModalToStack(modalData);
  // }

  // const openAssignClass = (data) => {
   
  //   props.pushModalToStack({
  //     title:<div>{`${data.firstName}${data.lastName? ' '+data.lastName : ''}`}</div>,
  //     modalBody: <AssignClassToStudent {...props} studentDetail={data} />,
  //     width: '50%',
  //     hideFooter: true,
  //     keyboard: true,
  //   })
  // }

  // const getPlanInfo = (studentObj) => {
  //   let classNameList = '';
  //   classNameList = (studentObj.schoolStudentClassRefList || []).map(classObj => classObj.className).join(', ');
  //   return classNameList;
  // }

  
//   const addItemConfirmationModal = (props, text, studentData, formData) => {
//     const { showModal, companyInfo } = props;
//    // const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
//     const modalBody = <span>
//         <FormattedMessage
//             id='addUpdateData'
//             defaultMessage=''
//         />
//   </span>;

//     let payload = {
//         relationshipId: companyInfo.relationshipId,
//         orgRegistrationId: studentData.orgRegistrationId,
//         isOrgMember: formData.isOrgMember,
//         isTeacher: formData.isTeacher || 0,
//         isVolunteer: formData.isVolunteer || 0,
//         isFinancialAssistanceNeeded: formData.isFinancialAssistanceNeeded || 0
//     };

//     const modalData = {
//         modalBody,
//         handleSubmit: () => {
//             props.updateOrgRegistrationData(payload, props);
//             props.hideModal();
//             props.popModalFromStack();
//         },
//     };
//     showModal(modalData);
// }

// const updateParentInfo = (props, studentData) => {
//     let payload = { formData: {}, txData: studentData };
//     const formData = payload.formData;
//     const { pushModalToStack } = props;

//     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
//         addItemConfirmationModal(props, formData.textBoxValue, studentData);
//     } else {
//         const data = {
//             title: <FormattedMessage id='parentInfo.text' defaultMessage='' />,
//             formData,
//             hideFooter: true,
//             modalData: { modalType: MODAL_TYPE.PARENT_INFO, data: payload, transactionName: CONSTANTS_TRANSACTIONS.STUDENT },
//             handleSubmit: (formData = {}) => {
              
//                 // if (formData.textBoxValue && formData.textBoxValue.length) {
//                     addItemConfirmationModal(props, formData.textBoxValue, studentData, formData);
//                 // } else {
//                 //     data.formData.submittedOnce = true;
//                 //     pushModalToStack(data);
//                 // }
//             }
//         };
//         pushModalToStack(data);
//     }
// }

const accessPortal = (e) => {
  props.getAccessToken({
    relationshipId: companyInfo.relationshipId,
    relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
    clientOnlineUserAccountId: e.clientOnlineUserAccountId
  }, props);
};

const actionMenu = (item) => {
  return (
    <Menu className="row-action-dropdown">
      <Menu.Item key="0" value="title">
        Actions
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="1" value="edit" onClick={() => 
        // updateParentInfo(props, item)
        accessPortal(item)
      }
      >
        <i className="fi fi-rs-door-open mr5" /><FormattedMessage id='parent.action.portalAccess' />
      </Menu.Item>

      {/* <Menu.Item key="3" value="delete" onClick={() => {
        props.showModal({
          modalBody: "Are you sure you want to delete?",
          handleSubmit: () => {
              props.deleteStudent(item, props);
          }
        })
      }
      }>
        <a><FormattedMessage id='delete.text' /></a>
      </Menu.Item> */}

      <Menu.Item key="2" value="generateInvoice"  onClick={() => {
        if (!item.isInvoiced) {
          props.showModal({
            modalBody: "Are you sure you want to generate invoice?",
            handleSubmit: () => {
              const payload = {
                relationshipId: item.relationshipId,
                contact: item.contactId,
                registrationFormSettingId: (props.selectedForm || {}).registrationFormSettingId,
                orgRegistrationId: item.orgRegistrationId,
                createdByEmpId: userInfo.relationshipEmployeeId,

              };
              props.generateInvoice(payload, props);
            }
          })
        }
      }
      }>
        <i className="fi fi-rr-receipt" /> <FormattedMessage id='generateInvoice.text' />
      </Menu.Item>

      <Menu.Item key="3" value="resendPassword"  onClick={() => {
          props.sendResetMail(item.studentList[0].fatherEmail, props.history);
        }}
      >
        <i className="fi fi-sr-arrows-repeat" /> <FormattedMessage id='resendPassword.text' />
      </Menu.Item>

      <Menu.Item key="4" value="delete"  onClick={() => {
          props.showModal({
            modalBody: "Are you sure you want to delete?",
            handleSubmit: () => {
              props.deleteOrgRegistration(item, props);
            }
          })
      }
      }>
        <i className="fi fi-rr-trash" /> <FormattedMessage id='delete.text' />
      </Menu.Item>
    </Menu >
  )
};

const downloadMenu = () => {
  return (
      <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Download as
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="pdf" onClick={()=>{
              const payload = {
                companyInfo,
                relationshipId: companyInfo.relationshipId,
                formId: (props.selectedForm || {}).registrationFormSettingId,
                relationshipEmployeeId: userInfo.relationshipEmployeeId,
              };
              props.exportStudentExcelData(payload, props);
            }}
          >
            <FilePdfOutlined />
            PDF File
          </Menu.Item>

      </Menu >
  )
};


  // const exportExcelData = () => {
  //   let dataList = [];
  //      (props.parentList[pageNumber] || []).map((e, i) => {
  //       let rowData = {
  //         '#': i + 1,
  //         'Student Name': e.firstName || '' + (e.lastName ? ' ' + e.lastName : ''),
  //         'Status': e.studentStatus,
  //         'Father Name': e.fatherFirstName || '' + (e.fatherLastName ? ' ' + e.fatherLastName : ''),
  //         'Father Phone': e.fatherMobile,
  //         'Father Email': e.fatherEmail,
  //         'Mother Name': e.motherFirstName || '' + (e.motherLastName ? ' ' + e.motherLastName : ''),
  //         'Mother Phone': e.motherMobile,
  //         'Mother Email': e.motherEmail,
  //         'Student DOB': e.dob ? getMomentDateForUIReadOnly({ date: new Date(e.dob), format }) : '',
  //         'Grade': e.grade,
  //         'Allergies': e.allergies,
  //         'Emergency Contact': (e.emergencyContactName || '') + (e.emergencyPhone ? ' ' + e.emergencyPhone : ''),
  //         'Date Created': e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : '',
  //         'Class': getClassList(e)
  //       }
  //       dataList.push(rowData);
  //       return '';
  //     })
  //   exportExcel(dataList, 'parentList');
  // }

  
  const itemSelection = (item) => {
    let selectItem = find(selectedItems, { orgRegistrationId: Number(item.orgRegistrationId) });
    let updatedItems = [];
    if (selectItem) {
        updatedItems = selectedItems.filter(obj => obj.orgRegistrationId !== item.orgRegistrationId);
    } else {
        updatedItems = JSON.parse(JSON.stringify(selectedItems));
        updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
}

const selectAll = (context) => {
    if (!context) {
        selectedItems.splice(0, selectedItems.length);
        props.updateState({ selectedItems: selectedItems });
        return;
    }
    for (let i = 0; i < parentList[pageNumber].length; i++) {
        let item = parentList[pageNumber][i];
        let selectItem = find(selectedItems, { orgRegistrationId: Number(item.orgRegistrationId) });
        if (!selectItem) {
            selectedItems.push(item);
        }
  }
    props.updateState({ selectedItems: selectedItems });
}

const isCheckedAll = () => {
    if (!props.parentList || !props.parentList[pageNumber] || props.parentList[pageNumber].length === 0) {
        return false;
    }
    for (let i = 0; i < props.parentList[pageNumber].length; i++) {
        let usr = props.parentList[pageNumber][i];
        let selectItem = find(selectedItems, { orgRegistrationId: Number(usr.orgRegistrationId) });
        if (!selectItem) {
            return false;
        }
    }
    return true;
}

const sortColumn = (e) => {
  //sortTable(document.getElementById('invoiceTable'), e.target.parentElement.cellIndex, 1 - dir);
  sortTable(document.getElementById('family-reg-table'), e.target.cellIndex, 1 - dir);
  updateState({ dir: 1 - dir })
}

const checkMenu = (e) => {
  return <Menu className="row-action-dropdown">

        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="1" onClick={()=>
          {
            selectAll(true);
          }
        }>
          <i className="fi fi-rs-check-circle" />Select all
        </Menu.Item>

        <Menu.Item key="2" onClick={()=>
          {
            selectAll(false);
          }
        }>
          <i className="fi fi-rr-cross-circle" /> Unselect all
        </Menu.Item>

        <Menu.Item key="3" onClick={()=>
          {
            const parentList = [];

            selectedItems.forEach(obj => {
              parentList.push({
                schoolStudentId: obj.schoolStudentId,
                orgRegistrationId: obj.orgRegistrationId,
                subcourseId: obj.subcourseId,
                courseId: obj.courseId,
                customerId: obj.customerId,
                contactId: obj.contactId || 0,
              });
            });
            if (parentList.length) {
              props.updateState({
                emailDetailDrawerVisible: true,
                isSendBulkInvoice: true,
                isBulkInvoice: true,
                selectedParentList: parentList,
                docName: 'students'
              })
              // props.pushModalToStack(modalData);
            } else {
              showToasterMessage({ messageType: "error", description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding' }) })
            }
          }
        }>
          <i className="fi fi-rr-envelope" />Send Email
        </Menu.Item>

      </Menu >
};

const openCustomerRegistrationModal = (data) => {
  props.showModal(
      {
        title: <Fragment>
            <div className='ant-modal-title'>Enrolled</div>
        </Fragment>,
        modalBody: <CustomerRegistration {...props} customerData={data}/>,
        width: 900,
        hideFooter: true,
        wrapClassName: 'tiles-modal'
      }
  )
}

const getAdditionalInfo = (id) =>{
  let listArr = (props.familyListing[1] || []).filter(obj => obj.customerId === id);
  return listArr[0] || {};
}

const openInvoicesModal = (context, id , title) => {
  const customer = getAdditionalInfo(id)
  props.showModal(
      {
        title: <Fragment>
            <div className='ant-modal-title'>{title || 'Total Invoices'}</div>
        </Fragment>,
        modalBody: <UnpaidInvoices {...props} context={context} customerData={customer}/>,
        width: 1100,
        hideFooter: true,
        wrapClassName: 'tiles-modal'
      }
  )
}


  return (
    <Fragment>
      {/* <div className="page-heading">
          <FormattedMessage id='heading.schoolManagement.families.registration' defaultMessage='' />
      </div>
      <PageBreadcrumb breadCrumbList={breadCrumbList} />
      <div className="pb10" /> */}
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='sidebar.menuItem.parents' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div>
      <div className="view-container">
        <Skeleton loading={props.isLoading}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='parentInfo.text' defaultMessage='' />
              </div>
              {
                props.parentList[pageNumber]?.length ? <>
                  <div className="vertical-sep" />
                  <div>{props.parentList[pageNumber]?.length}</div>
                </>:''
              }
            </div>
            <div className="right-actions">
              {/* <Dropdown
                style={{width: '250px'}}
                items={props.studentFormList || []}
                valueKeyName='formShortName'
                optionKeyName='registrationFormSettingId'
                className={(props.submittedOnce && !props.registrationFormSettingId) ? 'input-text-error' : ''}
                value={(props.selectedForm || {}).formShortName}
                placeholder='Filter by form'
                onSelect={(optionValue, option) => {
                  let selectedForm = find(props.studentFormList || [], { registrationFormSettingId: option.value }) || {};
                  props.updateState({
                    selectedForm: selectedForm,
                    selectedClass: null,
                    selectedCourse: null,
                  })
                  const payload = {
                    companyInfo,
                    relationshipId: companyInfo.relationshipId,
                    relationshipEmployeeId: userInfo.relationshipEmployeeId,
                    pageNumber: props.pageNumber || 1,
                    pageSize: props.pageSize || 200,
                    formId: option.value
                  };
                  props.getRegisteredMemberByFormId(payload);
                  props.getRegisteredMemberCount(payload);
                }}
              /> */}
                  <button
                className="action-btn"
                onClick={() => {
                  props.updateState({
                    parentListFilterDrawerVisible: true,
                  });
                }}
               >
                <FilterOutlined /> &nbsp;
                <FormattedMessage id="filter.text" defaultMessage="" />
              </button>
              <DropdownAction overlay={downloadMenu()} trigger={['click']}>
                <span><DownloadOutlined /> &nbsp; Download as</span>
              </DropdownAction>
            </div>
          </div>

          {/* <div style={{display: 'flex', justifyContent: "flex-start", marginBottom: '10px'}} className="table-action-container">
            <Dropdown
              style={{width: '300px'}}
              items={props.studentFormList || []}
              valueKeyName='formShortName'
              optionKeyName='registrationFormSettingId'
              className={(props.submittedOnce && !props.registrationFormSettingId) ? 'input-text-error' : ''}
              value={(props.selectedForm || {}).formShortName}
              placeholder='Filter by form'
              onSelect={(optionValue, option) => {
                let selectedForm = find(props.studentFormList || [], { registrationFormSettingId: option.value }) || {};
                props.updateState({
                  selectedForm: selectedForm,
                  selectedClass: null,
                  selectedCourse: null,
                })
                const payload = {
                  companyInfo,
                  relationshipId: companyInfo.relationshipId,
                  relationshipEmployeeId: userInfo.relationshipEmployeeId,
                  pageNumber: props.pageNumber || 1,
                  pageSize: props.pageSize || 200,
                  formId: option.value
                };
                  props.getRegisteredMemberByFormId(payload);
                props.getRegisteredMemberCount(payload);
              }}
            />
            <Dropdown
              style={{width: '20%', marginRight:'5px'}}
              items={props.classList[1]||[]}
              valueKeyName='className'
              optionKeyName='classId'
              className={(props.submittedOnce && !props.classId) ? 'input-text-error' : ''}
              value={(props.selectedClass || {}).className}
              placeholder='Filter by class'
              onSelect={(optionValue, option) => {
                let selectedClass = find(props.classList[1] || [], { classId: option.value }) || {};
                props.updateState({
                  selectedForm: null,
                  selectedClass: selectedClass,
                  selectedCourse: null
                  
                });
                const payload = {
                  companyInfo,
                  relationshipId: companyInfo.relationshipId,
                  pageNumber: props.pageNumber || 1,
                  pageSize: props.pageSize || 25,
                  classId: option.value
                };
                  props.getRegisteredMemberByClass(payload);
                props.getRegisteredMemberCountByClass(payload);
              }}
            />
              <Dropdown
              style={{width: '20%', marginRight:'5px'}}
              items={props.courseList[1]||[]}
              valueKeyName='courseName'
              optionKeyName='courseId'
              className={(props.submittedOnce && !props.courseId) ? 'input-text-error' : ''}
              value={(props.selectedCourse || {}).courseName}
              placeholder='Filter by course'
              onSelect={(optionValue, option) => {
                let selectedCourse = find(props.courseList[1] || [], { courseId: option.value }) || {};
                props.updateState({
                  selectedForm: null,
                  selectedClass: null,
                  selectedCourse: selectedCourse,
                });
                const payload = {
                  companyInfo,
                  relationshipId: companyInfo.relationshipId,
                  pageNumber: props.pageNumber || 1,
                  pageSize: props.pageSize || 25,
                  courseId: option.value
                };
                  props.getRegisteredMemberByCourse(payload);
                props.getRegisteredMemberCountByCourse(payload);
              }}
            />
            <button title={props.intl.formatMessage({ id: 'forgot.btn.submit' })}
              // style={{ marginLeft: '10px', height: '38px', width: '80px', cursor:'pointer' }} 
              className="action-btn"
              onClick={() => {
                const parentList = [];

                selectedItems.forEach(obj => {
                  parentList.push({
                    schoolStudentId: obj.schoolStudentId,
                    relationshipId: obj.relationshipId,
                    subcourseId: obj.subcourseId,
                    courseId: obj.courseId,
                    orgRegistrationId: obj.orgRegistrationId,
                    customerId: obj.customerId,
                    contactId: obj.contactId || 0,
                  });
                });
                if (parentList.length) {
                  props.updateState({
                    emailDetailDrawerVisible: true,
                    isSendBulkInvoice: true,
                    isBulkInvoice: true,
                    selectedParentList: parentList,
                    docName: 'students'
                  })
                  // props.pushModalToStack(modalData);
                } else {
                  showToasterMessage({ messageType: "error", description: props.intl.formatMessage({ id: 'common.record.notSelected.proceeding' }) })
                }

              }}>
                <FormattedMessage id='forgot.btn.submit' />
            </button>

            <button
              // style={{ marginLeft: '10px', height: '30px', width: '40px', cursor:'pointer' }}
              className="action-icon-btn"
              onClick={() => {
                const payload = {
                  companyInfo,
                  relationshipId: companyInfo.relationshipId,
                  formId: (props.selectedForm || {}).registrationFormSettingId,
                  relationshipEmployeeId: userInfo.relationshipEmployeeId,
                };
                props.exportStudentExcelData(payload, props);

                // if (props.parentList && props.parentList[pageNumber] && props.parentList[pageNumber].length) {
                //   exportExcelData()
                // } else {
                //   const modalData = {
                //     title:
                //       <div className="title">
                //         <i className="fa fa-ban mr15" />
                //         Validation Failed
                //       </div>,
                //     modalBody:
                //       <Fragment>
                //         <div className="warning-message">
                //           No record found.
                //         </div>
                //         <div className="button-action">
                //           <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
                //         </div>
                //       </Fragment>,
                //     hideFooter: true,
                //     wrapClassName: 'validation-failed-modal'
                //   };
                //   props.pushModalToStack(modalData);
                // }
              }}
              title="Download Excel"
            >
              <i className="fa fa-file-excel-o" />
            </button>
            
          </div> */}

          <div className="table-container">
            <table id='family-reg-table'>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    <DropdownAction overlay={checkMenu()} trigger={['click']}>
                      <i className="fi fi-rr-angle-down ml5" />
                    </DropdownAction>
                  </th>
                  <th>S. No.</th>
                  <th>Registration No.</th>
                  <th onClick={sortColumn}>Father Name <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>Mother Name <i class="fi fi-rr-sort-alt"></i></th>
                  <th onClick={sortColumn}>Enrolled <i class="fi fi-rr-sort-alt"></i></th>
                  {/* {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Parent Info <i class="fi fi-rr-sort-alt"></i></th>} */}
                  {/* {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Volunteer?</th>}
                  {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Teacher?</th>}
                  {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Invoiced?</th>}
                  {userInfo.jobRoleName !== 'Teacher' && <th>Financial Assistance</th>}
                  <th onClick={sortColumn}>Emergency Contact</th>
                  {userInfo.jobRoleName !== 'Teacher' && <th>Family Doctor</th>} */}
                  
                  
                  {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Invoices <i class="fi fi-rr-sort-alt"></i></th>}
                  {/* {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Paid Invoices <i class="fi fi-rr-sort-alt"></i></th>} */}
                  {/* {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Unpaid Invoices <i class="fi fi-rr-sort-alt"></i></th>} */}
                  {/* {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Payment Plan <i class="fi fi-rr-sort-alt"></i></th>} */}
                  {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Date Created <i class="fi fi-rr-sort-alt"></i></th>}
                  {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Status <i class="fi fi-rr-sort-alt"></i></th>}
                  {/* {userInfo.jobRoleName !== 'Teacher' && <th onClick={sortColumn}>Q&A <i class="fi fi-rr-sort-alt"></i></th>} */}
                  {userInfo.jobRoleName !== 'Teacher' && <th width='6%'><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>}
                </tr>
              </thead>
                
              <tbody>
                {(props.parentList && props.parentList[pageNumber]).map((e, i) => {
                  //  return (registationObj.parentList || []).map((e, j) => {
                  return <tr key={'std' + i}>
                    <td>
                      <Checkbox
                        onClick={() => {
                          itemSelection(e);
                        }}
                        checked={find(selectedItems, { orgRegistrationId: Number(e.orgRegistrationId) }) ? true : false}
                      />
                    </td>
                    <td>{i+1}</td>
                    <td>{e.orgRegistrationId}</td>
                    <td>
                      <div>{`${e.studentList[0]?.fatherFirstName || ''}${e.studentList[0]?.fatherLastName ? ' ' + e.studentList[0]?.fatherLastName : ''}`}</div>
                      <div>{e.studentList[0]?.fatherEmail}</div>
                      <div>{e.studentList[0]?.fatherMobile}</div>
                      {/* {
                        e.emergencyContactName ? <div style={{float : 'left'}}>
                          <b style={{float : 'left'}}>Emer. Contact &nbsp;</b>
                          <div style={{float : 'left'}}>
                            <div>{e.emergencyContactName || ''}</div>
                            <div>{e.emergencyPhone || ''}</div>
                          </div>
                        </div>: ''
                      } */}
                    </td>
                    <td>
                      <div>{`${e.studentList[0]?.motherFirstName || ''}${e.studentList[0]?.motherLastName ? ' ' + e.studentList[0]?.motherLastName : ''}`}</div>
                      <div>{e.studentList[0]?.motherEmail?e.studentList[0]?.motherEmail:'-'}</div>
                      <div>{e.studentList[0]?.motherMobile?e.studentList[0]?.motherMobile:'-'}</div>
                      {/* {
                        e.familyDoctorName ? <div>
                          <b style={{float : 'left'}}>Family Dr. &nbsp;</b>
                          <div style={{float : 'left'}}>
                            <div>{e.familyDoctorName || ''}</div>
                            <div>{e.familyDoctorPhNumber || ''}</div>
                          </div>
                        </div>
                          : ''
                      } */}
                    </td>
                    <td>
                      <div className="link" onClick={()=>{
                        openCustomerRegistrationModal(e);
                      }}>
                        {/* {e.studentList.length} */}
                        {getAdditionalInfo(e.customerId).registrationCount}
                      </div>
                    </td>

                    {/* {userInfo.jobRoleName !== 'Teacher' &&
                   
                    <td>
                        <div>Member {e.isOrgMember ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}</div>
                        <div>Volunteer {e.isVolunteer ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}</div>
                        <div>Teacher {e.isTeacher ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />} </div>
                        <div>Is Invoiced {e.isInvoiced ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}</div>
                        {/* <div>{e.paymentPlanName ? e.paymentPlanName : ''}</div> */}
                        {/* <div>Financial Assistance {e.isFinancialAssistanceNeeded ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />}</div>
                      </td>  */}
                    {/* }  */}
                    
                    {/* <td>
                      <div>{e.emergencyContactName || ''}</div>
                      <div>{e.emergencyPhone || ''}</div>
                    </td>
                    <td>
                      <div>{e.familyDoctorName || ''}</div>
                      <div>{e.familyDoctorPhNumber || ''}</div>
                    </td> */}
                  {userInfo.jobRoleName !== 'Teacher' &&
                    <td>
                      <div> Total - {getAdditionalInfo(e.customerId).totalInvoicesRaisedCount ? <span className="cursor-pointer" onClick={()=>{openInvoicesModal('totalInvoices', e.customerId, 'Total Invoices')}}>{ getAdditionalInfo(e.customerId).totalInvoicesRaisedCount }</span>: '0'}</div>
                      <div> Paid - {getAdditionalInfo(e.customerId).paidInvoicesCount ? <span className="cursor-pointer" onClick={()=>{openInvoicesModal('paidInvoices', e.customerId, 'Total Invoices')}}>{ getAdditionalInfo(e.customerId).paidInvoicesCount }</span> : '0'}</div>
                      <div> Unpaid - {getAdditionalInfo(e.customerId).unpaidInvoicesCount ? <span className="cursor-pointer" onClick={()=>{openInvoicesModal('unpaidInvoices', e.customerId, 'Total Invoices')}}>{ getAdditionalInfo(e.customerId).unpaidInvoicesCount }</span>: '0'}</div>
                    </td>
                  }

                    
                    {/* 
                    {userInfo.jobRoleName !== 'Teacher' && <td></td>}
                    {userInfo.jobRoleName !== 'Teacher' && <td></td>} */}
                    {/* {userInfo.jobRoleName !== 'Teacher' && <td>
                      {/* <span onClick={() => { openClassDetailPopup(e) }} className="cursor-pointer">{getPlanInfo(e)}</span> */}
                      {/* <span>{getPlanInfo(e)}</span> */}
                    {/* </td>} */} 
                    {userInfo.jobRoleName !== 'Teacher' && <td className="one-line-view">{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format:CONSTANTS.TABLE_DATE_FORMAT }) : ''}</td>}
                    {userInfo.jobRoleName !== 'Teacher' && <td>
                        <div className="status-toggle">
                          <Switch defaultChecked />
                        </div>
                       </td>}
                  {/* {userInfo.jobRoleName !== 'Teacher' && <td>{e.questionList ? <span style={{ cursor: 'pointer' }} onClick={() => { openQuestionDetailPopup(e) }}><i className="fa fa-file-text" aria-hidden="true"></i></span> : ''}</td>}  */}
                    {userInfo.jobRoleName !== 'Teacher' && <td style={{paddingLeft: '50px'}}>
                      <DropdownAction overlay={actionMenu(e)} trigger={['click']}>
                      <MoreOutlined />
                      </DropdownAction>
                    </td>}
                  </tr>
                  // })
                })}
              </tbody>
            </table>
          </div>
        </Skeleton>

        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={parentCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(Parent);
