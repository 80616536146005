import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OpenOrdersComponent from '../../../../../components/modal/modalBody/supplier/OpenOrders';
import { fetchOpenOrders, fetchOpenOrdersCount, resetOpenOrderPaginatedData } from './action';
import { popModalFromStack, showModal, hideModal, pushModalToStack } from '../../../../../actions/commonActions';

class OpenOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 100,
            pageNumber: 1,
            searchedText: ''
        };
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            supplierId: this.props.supplierData ? this.props.supplierData.supplierId : '',
            isForSupplier: this.props.supplierData ? true:false,
            pageNumber: 1,
            pageSize: 25
        }
        // this.props.resetOpenOrderPaginatedData();
        // fetchPaginationDataIfNeeded('fetchOpenOrders', 'openOrderList', this.props, payload, true);
        // fetchDataIfNeeded('fetchOpenOrdersCount', 'openOrderListCount', this.props, payload, true);
        this.props.fetchOpenOrders(payload, {companyInfo});
        this.props.fetchOpenOrdersCount(payload, {companyInfo});
    }

    render() {
        return <OpenOrdersComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
}

const mapStateToProps = (state) => {
    return {
        openOrderListCount: state.supplier.openOrderListCount,
        openOrderList: state.supplier.openOrderList,
        modalLoading: state.common.modalLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchOpenOrders,
    popModalFromStack,
    pushModalToStack,
    showModal,
    hideModal,
    fetchOpenOrdersCount,
    resetOpenOrderPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OpenOrders);