import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FinancialYearModalComponent from '../../../../../components/modal/modalBody/common/FinancialYear';
import { deleteFY, updateFinancialYear, fetchFinancialYears } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../../actions/commonActions';

class FinancialYearMComp extends Component {

    constructor(props){
        super();
        this.state={
            searchedText: ''
        }
    }

    componentDidMount() {
        // const companyInfo = this.props.companyInfo || {};
        // const payload = { relationshipId: companyInfo.relationshipId };
        
    }
    
    componentWillMount() {
        // this.props.updateHeaderState({
        //     collapsed: false
        // });
    }


    render() {
        return <FinancialYearModalComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}/>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        financialYearList : state.financialYear.financialYearList,
       
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    deleteFY,
    updateFinancialYear,
    fetchFinancialYears,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinancialYearMComp);
