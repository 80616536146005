import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PRDetailsComponent from '../../../../../components/modal/modalBody/supplier/PRDetail';
import { fetchPurchaseRequestDetail, approvePR, uploadStamp, deleteStamp, updateInquiryPRStatus, updateSOPRStatus } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchPurchaseRequests, getPurchaseRequestsCount, deletePurchaseRequest, updateUserStatus } from '../../../../supplier/purchaseRequest/Listing/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';
import { deleteAttectedFile } from '../../../../../actions/commonActions';
import { deleteDocument } from '../../../../supplier/purchaseRequest/StandardPurchaseRequest/action';
class PRDetail extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, supplierId: this.props.prPayload.supplierId, prMasterId: this.props.prPayload.prMasterId };
        fetchDataIfNeeded('fetchPurchaseRequestDetail', 'prData', this.props, payload, true);

    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
             <PRDetailsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
             </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        prData: state.purchaseRequest.prDetail,
        userInfo: state.common.userInfo,
        detailLoading: state.common.detailLoading

    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPurchaseRequestDetail,
    downloadFileFromBucket,
    generatePDF, printPDF,
    approvePR,
    fetchPurchaseRequests,
    getPurchaseRequestsCount,
    uploadStamp, 
    deleteStamp,
    deletePurchaseRequest,
    updateUserStatus,
    updateInquiryPRStatus, 
    updateSOPRStatus,
    deleteAttectedFile,
    deleteDocument
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PRDetail);