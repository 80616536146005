import React, { Fragment } from 'react';
import { Button, Row, Col, Select, Skeleton } from 'antd';
import { EditOutlined, FilePdfOutlined, MailOutlined, PrinterOutlined, DeleteOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from '../../../../general/Dropdown';
import { checkACLPermission, fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../../../utils';
import ReactHtmlParser from "react-html-parser";
import { CustomUploader } from '../../../../general/CustomUploader';
import find from 'lodash.find';
import { CONSTANTS } from '../../../../../static/constants';


const { Option } = Select;

function StockTransferDetails(props) {
  const { companyInfo, stockTransferData ,stockTransferList} = props;

  const relationshipAddress =
    find(companyInfo.boLocationList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};

  const billingAddress =
    find(stockTransferData?.boLocationGITList, {
      locationType: CONSTANTS.WAREHOUSE_ADDRESS,
    }) || {};

  let isPlaceOfSupplySameAsRelationship = false;
  isPlaceOfSupplySameAsRelationship =
    (stockTransferData?.placeOfSupply &&
      billingAddress.stateName === stockTransferData?.placeOfSupply) ||
    false;

  let isColumnEmpty = {
    itemName: true,
    service: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
    rate: true,
    hsn: true,
    discount: true,
    tax: true,
    specialDiscount: true,
  };

  const getAddressInfoFragment = (obj={}, additionInfo={}) => {
    const fragmentObj = (
      <Fragment>
        {obj.streetAddress1 ? (
          <div className="billing-address">{obj.streetAddress1}</div>
        ) : (
          ""
        )}
        {obj.streetAddress2 ? (
          <div className="billing-address">{obj.streetAddress2}</div>
        ) : (
          ""
        )}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""} ${obj.stateName ? "," + obj.stateName : ""} ${obj.zipCode ? "," + obj.zipCode : ""
              }`}
          </div>
        ) : (
          ""
        )}
        {additionInfo.workPhone ? (
          <div className="billing-address">
            <FormattedMessage id="phone" defaultMessage="" /> -{" "}
            {additionInfo.workPhone}
          </div>
        ) : (
          ""
        )}
        {additionInfo.email ? (
          <div className="billing-address">
            <FormattedMessage id="email" defaultMessage="" /> -{" "}
            {additionInfo.email}
          </div>
        ) : (
          ""
        )}

      </Fragment>
    );
    return fragmentObj;
  };
  const getVariantFragment = (item) => {
    let itemName = null;
    if (item.itemName) {
      itemName = item.itemName;
    }
    if (item.itemVariantName) {
      itemName = itemName + " (" + item.itemVariantName + ")";
    }
    return itemName ? itemName : "";
  };

  const getNetAmount = (row) => {
    let netAmountItem =
      Number(row.quantity * row.itemPurchasedPrice)
    return fixedDecimalNumber(netAmountItem);
  };

  const getSubTotalOrTaxOrDiscountAmount = (data) => {
    
    let total = 0;
  
    (data.gitDetailsList || []).map(val=>{
      total = total + Number(val.quantity * val.itemPurchasedPrice)
    })   
    
    return fixedDecimalNumber(total);
  };
  return (
    <div className={"custom-modal show"}>
      <i className="close-icon">
        <svg
          width="17"
          height="17"
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          <title>
            <FormattedMessage id="button.close.label" defaultMessage="" />
          </title>
          <path
            fill="#67757d"
            fillRule="evenodd"
            d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"
          />
        </svg>
      </i>
      <div className="modal-dialog">
        <div className="modal-content full-width">
          <div className="modal-header align-left">
            {props.documentName || "Stock Transfer"}
          </div>
          <div className="inner-content">
            
            <Row>
              <Col span={6}>
                <Skeleton loading={props.isLoading} paragraph={{ rows: 4 }} active>
                  <div className="read-only-content pt20">
                    <div className="title lh-adjust pb5">
                      {props.companyInfo.storeName}
                    </div>
                    <div>
                      {getAddressInfoFragment(relationshipAddress, companyInfo)}
                      <div>
                      <div className="read-only-content pt10">
                    <div className="title lh-adjust underline-text pb5">
                      <FormattedMessage
                        id="modal.txDetails.common.shipTo"
                        defaultMessage=""
                      />
                    </div>
                    {/* <div className="title">
                                          {poData.supplierName}
                                      </div> */}
                    <div>{getAddressInfoFragment(billingAddress, {})}</div>
                  </div>
                      </div>
                    </div>
                  </div>
                </Skeleton>
              </Col>

              <Col span={6}>
                <Skeleton loading={props.isLoading} paragraph={{ rows: 4 }} active>
                  <div className="read-only-content pt20">
                    <div className="pb5">
                      <div className="title">
                        Source Warehouse
                      </div>

                      <div>{props.sourceWarehouse?.warehouseName || ''}</div>
                    </div>

                    <div className="pb5">
                      <div className="title">
                        Warehouse Address
                      </div>
                        {getAddressInfoFragment(props.sourceWarehouse?.locationList[0])}
                      <div>
                          
                      </div>
                    </div>
                  </div>
                </Skeleton>
              </Col>

              <Col span={6}>
                <Skeleton loading={props.isLoading} paragraph={{ rows: 4 }} active>
                  <div className="read-only-content pt20">
                    <div className="pb5">
                      <div className="title">
                        Destination Warehouse
                      </div>

                      <div>{props.destinationWarehouse?.warehouseName || ''}</div>
                    </div>

                    <div className="pb5">
                      <div className="title">
                        Warehouse Address
                      </div>
                        {getAddressInfoFragment(props.destinationWarehouse?.locationList[0])}
                      <div>
                          
                      </div>
                    </div>
                  </div>
                </Skeleton>
              </Col>

              <Col span={6}>
                <Skeleton loading={props.isLoading} paragraph={{ rows: 4 }} active>
                  <div className="read-only-content pt20">
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.stockTransfer.stNumber"
                          defaultMessage=""
                        />
                      </div>

                      <div>{stockTransferData.gitNumber}</div>
                    </div>

                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="common.dateCreated"
                          defaultMessage=""
                        />
                      </div>

                      <div>
                        {stockTransferData.gitDate &&
                          getMomentDateForUIReadOnly({
                            date: props.poDate,
                            format: CONSTANTS.DISPLAY_DATE_FORMAT,
                          })}
                      </div>
                    </div>
                    {stockTransferData.gitDueDate && (
                      <div className="pb5">
                        <div className="title">
                          <FormattedMessage
                            id="modal.txDetails.purchaseOrder.poDueDate"
                            defaultMessage=""
                          />
                        </div>

                        <div>
                          {stockTransferData.gitDueDate &&
                            getMomentDateForUIReadOnly({
                              date: props.gitDueDate,
                              format: CONSTANTS.DISPLAY_DATE_FORMAT,
                            })}
                        </div>
                      </div>
                    )}


                    {props.projectMasterId ? (
                      <div className="pb5">
                        <div className="title">
                          <FormattedMessage
                            id="modal.txDetails.common.project"
                            defaultMessage=""
                          />
                        </div>
                        <div>
                          <abbr>
                            {props.projectName}
                          </abbr>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {props.isDropShipping ? (
                      <div className="pb5">
                        <div className="title">
                          <FormattedMessage
                            id="modal.txDetails.common.dropshipOrder"
                            defaultMessage=""
                          />
                        </div>
                        <div>
                          <abbr>
                            <FormattedMessage id="yes" defaultMessage="" />{" "}
                          </abbr>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {props.rfqNumber ? (
                      <div className="pb5">
                        <div className="title">
                          <FormattedMessage
                            id="modal.txDetails.purchaseOrder.rfqNumber"
                            defaultMessage=""
                          />
                        </div>
                        <div>
                          <abbr>{props.rfqNumber}</abbr>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {props.customerId ? (
                      <div className="pb5">
                        <div className="title">
                          <FormattedMessage
                            id="common.customer.text"
                            defaultMessage=""
                          />
                        </div>
                        <div>
                          <abbr>{props.customerName}</abbr>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Skeleton>
              </Col>

            </Row>
       

            <div className="modal-table-container mb15">
              <Skeleton loading={props.isLoading} paragraph={{ rows: 4 }} active>
                <table className="transaction-details-table">
                  <thead>
                    <tr>
                      <th className="cell-size-50">#</th>
                        <th>
                            <span>
                              {" "}
                              <FormattedMessage
                                id="modal.txDetails.common.product"
                                defaultMessage=""
                              />
                            </span>
                                          
                            <span>
                              {" "}
                              <FormattedMessage
                                id="modal.txDetails.common.description"
                                defaultMessage=""
                              />
                            </span>
                          
                        </th>
                                      
                        <th >
                          <FormattedMessage
                            id="modal.txDetails.common.qty"
                            defaultMessage=""
                          />
                        </th>
                      
                        <th >
                          <FormattedMessage
                            id="modal.txDetails.common.rate"
                            defaultMessage=""
                          />
                        </th>
                        <th>
                        <FormattedMessage
                          id="modal.txDetails.common.netAmount"
                          defaultMessage=""
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(stockTransferData?.gitDetailsList || []).map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className="cell-size-50">{i + 1}</td>
                    
                              <td>
                                <strong>{getVariantFragment(item)}</strong>
                              
                                  <div className="description-cell">
                                    <pre>
                                      {ReactHtmlParser(item.description || "-")}
                                    </pre>
                                  </div>
                              
                              </td>
                                          
                            <td >
                              {item.quantity +
                                (item.uomName ? " " + item.uomName : "")}
                            </td>
                                        
                            <td>
                              {fixedDecimalNumber(item.itemPurchasedPrice) ||
                                "-"}
                            </td>
                                              
                          <td>
                            {getNetAmount(item) || "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Skeleton>
            </div>
          
          </div>
          <Col span={24}>
            <div className="bill-summary mt5" style={{marginLeft:"70%"}}>
              <Row>
                <Col span={14}>
                  <div className="right-txt bold">
                  Total
                  </div>
                </Col>

                <Col span={10} className="text-right ">
                  
                  {getSubTotalOrTaxOrDiscountAmount(stockTransferData)}
                </Col>
              </Row>
            </div>
          </Col>
        </div>
      </div>
    </div>

  )
}

export default StockTransferDetails