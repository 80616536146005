import React from 'react';
import { FilePdfOutlined, MailOutlined, PrinterOutlined, EditOutlined } from '@ant-design/icons';
//import { Link } from 'react-router-dom';
import {  Button } from 'antd'
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS, TRANSACTION_TYPES, MODAL_TYPE } from '../../../../../static/constants';
import { fixedDecimalNumber } from '../../../../../utils';
import { FormattedMessage } from 'react-intl';
//import { Dropdown } from '../../../../general/Dropdown';
import Template2 from "./template2";
import PerformaInvoice from '../../../../../containers/customer/invoice/StandardPerformaInvoice';
//import PayInvoice from '../../../../../containers/customer/invoice/PayInvoice';

const SalesInvoiceDetails = (props) => {
    const { salesInvoiceDetail, companyInfo, templateName= 'Professional' } = props;
    const { customerInvoiceDetailsList } = salesInvoiceDetail;
    const relationshipAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (salesInvoiceDetail.placeOfSupply && relationshipAddress.stateName === salesInvoiceDetail.placeOfSupply) || false;
    let itemLevelTaxes = [];
    if (customerInvoiceDetailsList) {
        itemLevelTaxes = calculateTaxRateList(customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true,
        comment: true
    };
    if (salesInvoiceDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, salesInvoiceDetail, props);
    }


    const getPayloadForPdf = () => {
        return {
            templateName: templateName,
            fileName: (salesInvoiceDetail.invoiceProformaId ? 'INV-'+salesInvoiceDetail.invoiceProformaId : 'Proforma Invoice') + '.pdf',
            transactionName: 'proformaInvoice',
            salesInvoiceDetail: salesInvoiceDetail,
            relationshipAddress: relationshipAddress,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            companyInfo: companyInfo,
            isColumnEmpty: isColumnEmpty,
            isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
            itemLevelTaxes: itemLevelTaxes,
            bucketName: config.BUCKET_NAME.BO_CUSTOMER_INVOICE,
            stampAwsKey: salesInvoiceDetail.stampAwsKey
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () =>{
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired = true;
        payload.customerId = salesInvoiceDetail.customerId;
        payload.invoiceMasterId = salesInvoiceDetail.invoiceMasterId;
        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.PROFORMA_INVOICE,
        });
    };

    const approveInvoice = () => {
        const modalBody = 'Are you sure you want to approve?';
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.approveInvoice({
                    relationshipId: salesInvoiceDetail.relationshipId,
                    customerId: salesInvoiceDetail.customerId,
                    invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                    approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                }, props)
                props.popModalFromStack();
            },
        };
        props.showModal(modalData);
    }

    const handleDelegateApprover = () => {
        const formData = {
            relationshipId: salesInvoiceDetail.relationshipId,
            customerId: salesInvoiceDetail.customerId,
            invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            approverEmpId: salesInvoiceDetail.approverEmpId,
            txType: TRANSACTION_TYPES.SALES_INVOICE
        };
        const { showModal } = props;
        const data = {
            title: 'Delegate Approver',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.DELEGATE_APPROVER,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const handleRejectApproval = () => {
        const formData = {
            relationshipId: salesInvoiceDetail.relationshipId,
            customerId: salesInvoiceDetail.customerId,
            invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            txType: TRANSACTION_TYPES.SALES_INVOICE
        };
        const { showModal } = props;
        const data = {
            title: 'Reject Approval',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.REJECT_APPROVAL,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    // const openAuditDrawer = (props, data) => {
    //     props.updateState({
    //         title: <div className="drawer-title">
    //             <FormattedMessage id='modal.txDetails.common.auditTrail' defaultMessage='' />
    //         </div>,
    //         auditDrawerVisible: true,
    //         deletApiUrl: 'deleteAuditTrailSalesInvoice',
    //         masterKey: 'invoiceMasterId',
    //         masterKeyValue: data.invoiceMasterId,
    //         apiUrl: 'saveAuditTrailSalesInvoice',
    //         customerId: data.customerId,
    //         trailList: data.auditTrailSalesInvoiceList,
    //         updateTrailListInTransaction: (list) =>{
    //             data.auditTrailSalesInvoiceList = list;
    //         } 
    //     });
    // }

    return (
        <div className={"custom-modal show"}>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="inner-content">
                        {templateName === 'Professional' && <Template2 {...props} />}
                    </div>
                    {salesInvoiceDetail.status !== 'Pending Approval' && salesInvoiceDetail.status !== 'Rejected' ?
                        <div className="button-group-action header-control">
                            {salesInvoiceDetail.salesOrderMasterId ?
                             <div className="small-btn-group">
                                <Button title={props.intl.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                            props.popModalFromStack();
                                            props.pushModalToStack({
                                                modalBody: <PerformaInvoice {...props} 
                                                customerId = {salesInvoiceDetail.customerId} 
                                                invoiceProformaId= {salesInvoiceDetail.invoiceProformaId}
                                                invoiceData = {salesInvoiceDetail}
                                                // update ={true} soMasterIdArr= {_getSoMasterId(salesInvoiceDetail)}
                                                // registrationFormSettingId= {salesInvoiceDetail.registrationFormSettingId}
                                                  />,
                                                width: '100%',
                                                hideTitle: true,
                                                hideFooter: true,
                                                wrapClassName: 'new-transaction-wrapper'
                                            })
                                            // props.history.push({
                                            //     pathname: '/admin/performa-invoice',
                                            //     state: {
                                            //         customerId: salesInvoiceDetail.customerId,
                                            //         invoiceProformaId: salesInvoiceDetail.invoiceProformaId,
                                            //         invoiceData: salesInvoiceDetail,
                                            //         update: true
                                            //     }
                                            // })
                                    }}
                                >
                                </Button>
                                {/* <Button
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.clone', defaultMessage: '' })}
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                            props.popModalFromStack();
                                            props.history.push({
                                                pathname: '/admin/performa-invoice',
                                                state: {
                                                    customerId: salesInvoiceDetail.customerId,
                                                    invoiceMasterId: salesInvoiceDetail.invoiceMasterId
                                                }
                                            })
                                    }}
                                >
                                </Button> */}
                                {/* <Button
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.delete', defaultMessage: '' })}
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                        props.showModal({
                                            modalBody: "Are you sure you want to delete this transaction?",
                                            handleSubmit: () => {
                                                props.popModalFromStack();
                                                props.deleteSalesInvoice({
                                                    customerId: salesInvoiceDetail.customerId,
                                                    invoiceMasterId: salesInvoiceDetail.invoiceMasterId,
                                                    relationshipId: salesInvoiceDetail.relationshipId
                                                }, props)
                                            }
                                        })
                                    }}
                                >
                                </Button> */}
                            </div>
                            :""}
                            <div className="small-btn-group">

                                <Button
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                    icon={<MailOutlined />}
                                    onClick={() => {
                                        openEmailDrawer(props);

                                    }}
                                >
                                </Button>

                                <Button
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        generatePDF();
                                    }}
                                >
                                </Button>
                                <Button
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                    icon={<PrinterOutlined />}
                                    onClick={() => {
                                        printPdf();
                                    }}
                                >
                                </Button>
                            </div>
                            {!salesInvoiceDetail.isInvoiceConverted ?
                                <Button

                                    onClick={() => {
                                        props.popModalFromStack();
                                        props.canInvoiceBeGenerated(salesInvoiceDetail, props, [salesInvoiceDetail.salesOrderMasterId])

                                    }}
                                    type="primary"
                                >
                                    {/* <Link
                                        to={
                                            {
                                                pathname: salesInvoiceDetail.isMultiCurrency ? 'sales-invoice-multi-currency' : '/admin/sales-invoice',
                                                state: {
                                                    customerId: salesInvoiceDetail.customerId,
                                                    invoiceData: salesInvoiceDetail,
                                                    invoiceProformaId: salesInvoiceDetail.invoiceProformaId,
                                                 //   clone: true
                                                }
                                            }}
                                    >
                                        <FormattedMessage id='modal.txDetails.inboundDelivery.convertToInvoice' defaultMessage='' />
                                    </Link> */}
                                    <FormattedMessage id='modal.txDetails.inboundDelivery.convertToInvoice' defaultMessage='' />
                                </Button> : ''
                            }
                          {/* <div className="small-btn-group">
                                <Button title={props.intl.formatMessage({ id: 'modal.txDetails.common.auditTrail', defaultMessage: '' })}
                                    icon={<HistoryOutlined />}
                                    onClick={() => {
                                        openAuditDrawer(props, salesInvoiceDetail);
                                    }}
                                >
                                </Button>
                            </div> */}

                              {/* {(salesInvoiceDetail.status !== "Paid") ?
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        const modalData = {
                                            title: 'Sales Payment',
                                            modalBody: <PayInvoice invoiceData={salesInvoiceDetail} />,
                                            wrapClassName: 'pay-invoice-modal',
                                            width: 500,
                                            hideFooter: true,
                                        };
                                        props.showModal(modalData);
                                    }}
                                >Pay Invoice
                                </Button> : ''
                            }
                            
                            <div className="action-dropdown-btn">
                                <Dropdown style={{'width': '110px'}}
                                    placeholder="Template"
                                    items={['Standard', 'Professional', 'GST Invoice']}
                                    onSelect={(selectedValue) => {
                                        props.updateState({
                                            templateName: selectedValue,
                                        });
                                    }}
                                ></Dropdown>
                            </div> */}
                        </div>
                        :
                        <div className="button-group-action">
                            {
                                salesInvoiceDetail.status !== 'Rejected' && (props.userInfo || {}).relationshipEmployeeId === salesInvoiceDetail.approverEmpId ? <div className="button-group-action header-control">
                                    <Button type="primary"  onClick={() => { approveInvoice() }}>
                                    <FormattedMessage id='modal.txDetails.salesInvoice.approveInvoice' defaultMessage='' /> 
                                </Button>
                                    <Button type="primary"  onClick={() => { handleDelegateApprover(); }}>
                                    <FormattedMessage id='modal.txDetails.common.delegateApprover' defaultMessage='' />
                            </Button>
                                    <Button type="danger"  onClick={() => { handleRejectApproval(); }}>
                                    <FormattedMessage id='modal.txDetails.common.reject' defaultMessage='' /> 
                            </Button>
                                </div> :
                                    <div>
                                    </div>
                            }

                        </div>
                    }
                </div>
            </div>
        </div>
    );

}


const getTxTypeData = (row, type, toReturn) => {
    if (!row || !row.customerInvoiceCOATxList) return;
    let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + data[toReturn] + "%)";
            }
            return "(" + data[toReturn] + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return fixedDecimalNumber(data[toReturn]);
        } else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}

const calculateTaxRateList = (customerInvoiceDetailsList, isPlaceOfSupplySameAsRelationship) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerInvoiceDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0
            }
            totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
            let discount = getTxTypeData(rowdata, 'discount', 'amount');
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = (rowdata.customerInvoiceCOATxList || []).find(tx => (tx.txType === 'tax')) || {};
            taxObj.taxPercent = tax1.amountPercent;
            taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
            totalTax = totalTax + tax1.amount;
            taxObj.taxName = tax1.taxName;
            //  let tax2 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'sgst'));
            // let tax3 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'igst'));
            // let tax4 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'vat'));
            //   if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
            //       taxObj.taxPercent = tax1.amountPercent + tax2.amountPercent;
            //       taxObj.taxAmount = tax1.amount + tax2.amount;
            //       totalTax = totalTax + taxObj.taxAmount;
            //   }
            //   else if (!isPlaceOfSupplySameAsRelationship && tax3) {
            //       taxObj.taxPercent = tax3.amountPercent;
            //       taxObj.taxAmount = tax3.amount;
            //       totalTax = totalTax + tax3.amount;
            //   }
            //   else if (tax4) {
            //       taxObj.taxPercent = tax4.amountPercent;
            //       taxObj.taxAmount = tax4.amount;
            //       totalTax = totalTax + tax4.amount;
            //   }
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                if (availableTaxObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                    taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });
    return itemLevelTaxes;
}

const updateColumnEmptyCheck = (isColumnEmpty, invoiceData, props) => {
    if (!invoiceData) return isColumnEmpty;
    for (let i in invoiceData.customerInvoiceDetailsList) {
        let row = invoiceData.customerInvoiceDetailsList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantityToInvoice) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }
        if (row.comment) { isColumnEmpty.comment = false; }
    }
    return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
    if (!row) return true;
    let data = (row.customerInvoiceCOATxList || []).find(tx => (tx.txType === type)) || {};
    if (data && data.amount) {
        return false;
    }
    else {
        return true;
    }
}


export default SalesInvoiceDetails;
