import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CommunicationTemplateComp from "../../../components/communication/communicationTemplate";
import { fetchDataIfNeeded } from "../../../utils";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { createSubAccount, getSubAccount, getText2DonateConfig, updatePhoneNumber } from './action';
import EmailComponent from '../../drawer/communication/account/brodcast-email';
import AccSetupComponent from '../../drawer/communication/account/setup';
import { FormattedMessage } from 'react-intl';
import CommunicationTemplateDrawer from "../../drawer/communication/communicationTemplate";
class CommunicationTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      communicationTemplateDrawerVisiable: false,
    }
  }

  componentDidMount() {
   
  }

  componentWillReceiveProps(props) {

  }

  render() {

    return <div>
      {this.state.communicationTemplateDrawerVisiable && <CommunicationTemplateDrawer {...this.props}
        {...this.state}
        updateDrawerState={(data) => {
          this.setState(data);
        }}
        />}
      { <CommunicationTemplateComp  {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
        updateDrawerState={(data) => {
          this.setState(data);
        }}
        > 

      </CommunicationTemplateComp>}
    </div>


  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    subAccount: state.communicationReducer.subAccount,
    accountConfigPh: state.communicationReducer.accountConfigPh,
    accountConfigWhatsApp: state.communicationReducer.accountConfigWhatsApp,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSubAccount,
      getSubAccount,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getText2DonateConfig,
      updatePhoneNumber
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationTemplate);
