import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InvoicesComponent from '../../../../../components/modal/modalBody/supplier/AllInvoicesByLedger';
import { fetchPurchaseInvoicesByFilter,getPurchaseInvoicesCountByFilter,  resetPaginatedData } from './action';
import { popModalFromStack, showModal, hideModal, pushModalToStack } from './../../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from '../../../../../utils';
import { printPDF } from '../../../../../actions/downloadFileAction';
class AllInvoicesByLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 100,
            pageNumber: 1,
            searchedText: ''
        };
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            supplierId: this.props.supplierId,
            pageNumber: 1,
            pageSize: 100,
            filters: {
                supplierId: this.props.supplierId,
                ledgerAccountId: this.props.ledgerAccountId
            }
        };
        resetPaginatedData();
        fetchPaginationDataIfNeeded('fetchPurchaseInvoicesByFilter', 'purchaseInvoiceList', this.props, payload, true);
        fetchDataIfNeeded('getPurchaseInvoicesCountByFilter', 'purchaseInvoiceCount', this.props, payload, true);
    }

    render() {
        return <InvoicesComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        purchaseInvoiceList: state.purchaseInvoice.purchaseInvoiceList,
        purchaseInvoiceCount: state.purchaseInvoice.purchaseInvoiceCount,
        permissions: state.auth.permissions,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    popModalFromStack,
    pushModalToStack,
    showModal,
    hideModal,
    fetchPurchaseInvoicesByFilter,
    getPurchaseInvoicesCountByFilter,
    resetPaginatedData,
    printPDF
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllInvoicesByLedger);