import React from "react";
import PageBreadcrumb from "../../PageBreadcrumb";
import { FormattedMessage } from "react-intl";
import { Pagination, Tooltip, Skeleton } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, fixedDecimalNumber, sortTable } from "../../../utils";
import { CONSTANTS } from '../../../static/constants';
import { SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons';

const PaymentListingComp = (props) => {
    const { companyInfo, donationPaymentList, campaignListCount,pageNumber, updateState, pageSize, dir=0, cellNumber=0 } = props;
    const showTotal = (total) => {
        return (
          props.intl.formatMessage({
            id: "pagination.text.total",
            defaultMessage: "",
          }) +
          ` ${campaignListCount || 0} ` +
          props.intl.formatMessage({
            id: "pagination.text.items",
            defaultMessage: "",
          })
        );
      };
      const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchDonationPayments', 'donationPaymentList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchDonationPayments(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }
    const breadCrumbList = [
      {
        name: <FormattedMessage id='sidebar.menuItem.fundraising' defaultMessage='' />
      },
      {
        name: <FormattedMessage id='sidebar.menuItem.donations' defaultMessage='' />,
      }
    ];

    const sortColumn = (e) => {
      sortTable(document.getElementById('donation-table'), e.target.cellIndex, 1 - dir);
      // let a = (e.target.cellIndex + 'a') === cellNumber ? (e.target.cellIndex + 'd') : (e.target.cellIndex + 'a');
      updateState({ 
        dir: 1 - dir, 
        cellNumber: (e.target.cellIndex + 'a') === cellNumber ? (e.target.cellIndex + 'd') : (e.target.cellIndex + 'a')
      });
    }
    return (
      <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='heading.fundRaising.campaignPayments' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
        </div>
      </div>
        <div className="view-container">
        <Skeleton loading={props.isLoading}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='fundraising.donation.table.heading' defaultMessage='' />
              </div>
              {
                pageSize ? <>
                  <div className="vertical-sep" />
                  <div>{pageSize}</div>
                </>:''
              }
            </div>
            <div className="right-actions">
            </div>
          </div>
          <div className="table-container">
            <table id="donation-table">
              <thead>
                <tr>
                  <th><FormattedMessage id="serial.no" defaultMessage=""/></th>
                  <th width={"12%"} onClick={sortColumn}><FormattedMessage id='customer.customerPayment.listing.header.paymentNo' defaultMessage='' />  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id="fundraising.pledge.listing.header.donar" defaultMessage="" />  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id='customer.customerPayment.listing.header.paymentAmount' defaultMessage='' />  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id='customer.customerPayment.listing.header.paymentDate' defaultMessage='' />  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id="fundraising.campaign.listing.header.campaign" defaultMessage="" />  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id="paymentSource.text" defaultMessage="" />  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                  <th onClick={sortColumn}><FormattedMessage id="modal.customer.payInvoice.remarks" defaultMessage="" />  {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                </tr>
              </thead>
              <tbody>
                {donationPaymentList[pageNumber].map((cl, i) => {
                  return <tr key={i}>
                    <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                    <td>{cl.paymentNumber}</td>
                    <td>{cl.customerName}</td>
                    <td style={{textAlign: 'end'}}>{(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{fixedDecimalNumber(cl.paymentAmount)}</td>
                    <td><Tooltip placement="topLeft" title={(cl.paymentDate && getMomentDateForUIReadOnly({ date: cl.paymentDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                      {(cl.paymentDate && getMomentDateForUIReadOnly({ date: cl.paymentDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))} 
                    </Tooltip></td>
                    <td>{cl.campaignName}</td>
                    <td>{<FormattedMessage id={cl.paymentSource} />}</td>
                    <td>{cl.remarks}</td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={campaignListCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
        </div>
      </>
    )
};

export default PaymentListingComp;
