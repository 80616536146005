import React , {Fragment} from 'react';
import { FilePdfOutlined, MailOutlined, PrinterOutlined} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import config from '../../../../../config/environmentConfig';
import {Row, Col, Button,Radio, Checkbox,} from 'antd'
import { CONSTANTS } from '../../../../../static/constants';
import { LogoComponent } from '../../../../general/LogoComponent';

import { TextArea } from "../../../../general/TextAreaComponent";


const StudentRegistrationDetail =  (props) =>{
    const { studentRegistrationDetail, companyInfo, formSetting={} } = props;
   
 
    const getPayloadForPdf = () => {
        return {
            fileName : (props.registrationFormSetting.programName || 'Registration')+'.pdf',
            titleName: props.registrationFormSetting.programName,
            transactionName: 'studentRegistration',
            studentRegistrationDetail: studentRegistrationDetail,
            billingAddress: props.defaultBillingAddress,
            companyInfo: companyInfo,
            relationshipAddress: props.relationshipBillingAddress,
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }
   
    const printPdf = () =>{
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    // const openEmailDrawer = (props) => {
    //     let payload = getPayloadForPdf();
    //     payload.fileDataReqeired= true;
    //     payload.customerId = studentRegistrationDetail.customerId;
    //     payload.creditMemoMasterId = studentRegistrationDetail.creditMemoMasterId;
    //     props.updateState({
    //         emailDrawerVisible: true,
    //         txData: payload,
    //         type: TRANSACTION_TYPES.CREDIT_MEMO,
    //     });
    // };

    return (
        <div className={"custom-modal show"}>
        <i className="close-icon">
                <svg width="17" height="17" onClick={() =>{props.popModalFromStack();}}>
                    <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                          d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"/>
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="modal-header align-left">
                        {props.registrationFormSetting.programName || 'Registration'}
                    </div>
                    <div className="inner-content">
                        <Row gutter={24} style={{ background: formSetting.bgColor, color: formSetting.textColor, padding: '5px' }}>
                            <Col span={2}> </Col>
                            <Col span={2}>
                                <div className="brand-logo">
                                    {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                                </div>
                            </Col>
                            <Col span={14} style={{ alignSelf: 'center' }}>
                                <div style={{ fontSize: '22px' }}> {companyInfo.storeName}</div>
                            </Col>
                            <Col span={6} style={{ alignSelf: 'center' }}>
                                {getAddressInfoFragment(props.relationshipBillingAddress, companyInfo)}
                            </Col>
                        </Row>

                        <div className="modal-table-container mb15 mt15" style={{ fontSize: '12px' }}>

                            <Row gutter={16} className="mb10">
                                {formSetting.fatherFirstNameDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='register.fatherFirstName' /></Col>
                                            <Col span={16}> {studentRegistrationDetail.fatherFirstName || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                                {formSetting.fatherLastNameDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='register.fatherLastName' /></Col>
                                            <Col span={12}> {studentRegistrationDetail.fatherLastName || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16} className="mb10">
                                {formSetting.fatherMobileDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='father.mobile' /></Col>
                                            <Col span={12}> {studentRegistrationDetail.fatherMobile || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                                {formSetting.fatherEmailDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='father.email' /></Col>
                                            <Col span={12}> {studentRegistrationDetail.motherEmail || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16} className="mb10">
                                {formSetting.motherFirstNameDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='mother.fName' /></Col>
                                            <Col span={12}> {studentRegistrationDetail.motherFirstName || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                                {formSetting.motherLastNameDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='mother.lName' /></Col>
                                            <Col span={12}> {studentRegistrationDetail.motherLastName || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                            </Row>


                            <Row gutter={16} className="mb10">
                                {formSetting.motherMobileDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='mother.mobile' /></Col>
                                            <Col span={12}> {studentRegistrationDetail.motherMobile || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                                {formSetting.motherEmailDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='mother.email' /></Col>
                                            <Col span={12}> {studentRegistrationDetail.motherEmail || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                            </Row>

                            <Row gutter={16} className="mb10">
                                {formSetting.emergencyContactDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='setting.student.registration.form.emergencyContactName' /></Col>
                                            <Col span={12}> {studentRegistrationDetail.emergencyContactName || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                                {formSetting.emergencyPhoneDisplay ?
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='setting.student.registration.form.emergencyPhone' /></Col>
                                            <Col span={12}> {studentRegistrationDetail.emergencyPhone || '-'}</Col>
                                        </Row>
                                    </Col> : ''}
                            </Row>

                        <Row gutter={16}>
                            {formSetting.addressDisplay ?
                                <Col span={12}>
                                     <Row>
                                            <Col span={8} className="title text-right"> <FormattedMessage id='signup.tab.address' /></Col>
                                            <Col span={12}> {getAddressInfoFragment(props.defaultBillingAddress, companyInfo)}</Col>
                                            </Row>
                                </Col> : ''}
                        </Row>
                        {formSetting.collectStudentInfo ? <div>
                                <b className='label-emergency'><FormattedMessage id='studentDetail.text' /></b>
                                <Row gutter={16}>

                                    <Col span={24}>
                            <table className="transaction-details-table">
                                <thead>
                                    <tr>
                                        <th className="text-center"><FormattedMessage id='signup.firstname.placeholder'/></th>
                                        <th className="text-center"><FormattedMessage id='signup.lastname.placeholder'/></th>
                                        <th className="text-center"><FormattedMessage id='grade.text'/></th>
                                        <th className="text-center"><FormattedMessage id='dob.text'/></th>
                                        <th className="text-center"><FormattedMessage id='allergies.header.text'/></th>
                                        <th className="text-center"><FormattedMessage id='gender.text'/></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {( studentRegistrationDetail.studentList || []).map((item, i) => {
                                    return (
                                        <tr key={i}>
                                          <td className="text-center">{item.firstName || '-'}</td>
                                          <td className="text-center">{item.lastName || '-'}</td>
                                          <td className="text-center">{item.grade || '-'}</td>
                                          <td className="text-center">{item.dob || '-'}</td>
                                          <td className="text-center">{item.allergies || '-'}</td>
                                          <td className="text-center">{item.gender || '-'}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                            </Col>
                            </Row>
                            </div> : ''}

                            {
                    (props.questionList || []).map((queObj, i) => {

                        return (
                            <div style={{ marginBottom: '10px', padding: '10px 10px 10px 10px' }}>
                                <Row style={{ marginBottom: '10px' }}>
                                    <Col span={20}><b>{queObj.questionText} {queObj.isRequired ? <span className="required">*</span>: '' }</b></Col>
                                </Row>
                                <Row>
                                    {(queObj.optionList && queObj.optionList.length) ?
                                        <div>
                                            {queObj.answerType === 'multiChoice' ?
                                                <Checkbox.Group value={queObj.answers} onChange={(answers) => {
                                                    queObj.answers = answers;
                                                    let questionList = formSetting.questionList;
                                                    questionList.splice(i, 1, queObj);
                                                    formSetting.questionList = questionList;
                                                    props.updateState({ formSetting });
                                                }}
                                                >
                                                    {(queObj.optionList || []).map((optObj, j) => {
                                                        return (<div><Checkbox value={optObj.optText} disabled readonly="readonly">{optObj.optText}</Checkbox></div>)
                                                    })
                                                    }

                                                </Checkbox.Group> :
                                                <Radio.Group value={queObj.answers} onChange={(e) => {  
                                                    queObj.answers = e.target.value;
                                                    let questionList = formSetting.questionList;
                                                    questionList.splice(i, 1, queObj);
                                                    formSetting.questionList = questionList;
                                                    props.updateState({ formSetting }); }} >
                                                    {(queObj.optionList || []).map((optObj, k) => {
                                                        return (<div>{optObj.optText ? <Radio value={optObj.optText} disabled readonly="readonly">{optObj.optText}</Radio> : ''}</div>)
                                                    })
                                                    }

                                                </Radio.Group>
                                            }
                                        </div> : ''
                                    }
                                </Row>
                                {queObj.answerType === 'shortAnswer' ?
                                    <Row>
                                        <Col span={20}>
                                            <TextArea
                                                style={{ width: '100%' }}
                                                placeholder='Enter here'
                                                type='text'
                                                value={queObj.answers}
                                                disabled
                                                onChange={(e) => {
                                                    queObj.answers = e.target.value;
                                                    let questionList = formSetting.questionList;
                                                    questionList.splice(i, 1, queObj);
                                                    formSetting.questionList = questionList;
                                                    props.updateState({ formSetting });
                                                }}
                                            />
                                        </Col>
                                    </Row> : ''
                                }
                            </div>
                        )
                    })
                }
                        </div>

                    </div>

                    <div className=" button-group-action header-control">
                   
                            <div className="small-btn-group">

                                <Button
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                    icon={<MailOutlined />}
                                    onClick={() => {
                                       // openEmailDrawer(props);

                                    }}
                                >
                                </Button>

                                <Button
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        generatePDF();
                                    }}
                                >
                                </Button>
                                <Button
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                    icon={<PrinterOutlined />}
                                    onClick={() => {
                                        printPdf();
                                    }}
                                >
                                </Button>
                            </div>
                            {/* <div className="small-btn-group">
                                <Button title='Audit Trail'
                                    icon={<HistoryOutlined />}
                                    onClick={() => {
                                       // openEmailDrawer(props);
                                    }}
                                >
                                </Button>
                            </div> */}
                    </div>
                </div>
            </div>
            </div>
    );

}

const getAddressInfoFragment = (obj, additionInfo) => {

    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <span className='billing-address'>{obj.streetAddress1}
                </span>
                : ''
        }
        {
            obj.streetAddress2
                ? <span className='billing-address'> {obj.streetAddress2}
                </span>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

export default StudentRegistrationDetail;
