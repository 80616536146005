import axios from "axios";
import config from "../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

// export const getSponsorships = (payload) => {
//   return (dispatch) => {
//     return axios
//       .get(
//         `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext
//         }/sponsorship/getSponsorshipMaster?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
//         }&pageNumber=${(payload.pageNumber || 1) - 1}`
//       )
//       .then((res) => {
//         const data = {
//           pageNo: payload.pageNumber,
//           list: res.data
//         }
//         dispatch({
//           type: FUNDRAISING_ACTION_LIST.SPONSORSHIP_LIST,
//           data,
//         });
//       })
//       .catch((err) => {
//         if (!err.__isRetryRequest) {
//           showToasterMessage({
//             messageType: "error",
//             description:
//               lang[((err.response || {}).data || {}).message] ||
//               "Some error occurred",
//           });
//         }
//       }).finally(() => {
//         dispatch({ type: DONATION_ACTION_LIST.DONATION_SETTING_LOADING, data: {isSponsorshipListLoaded : true}});
//       });;
//   };
// };

// export const sponsorshipCount = (payload) => {
//   return (dispatch) => {
//     return axios
//       .get(
//         `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/sponsorship/countSponsorship?relationshipId=${payload.relationshipId}`
//       )
//       .then((res) => {
//         dispatch({
//           type: FUNDRAISING_ACTION_LIST.SPONSORSHIP_LIST_COUNT,
//           data: res.data,
//         });
//       })
//       .catch((err) => {
//         if (!err.__isRetryRequest) {
//           showToasterMessage({
//             messageType: "error",
//             description:
//               lang[((err.response || {}).data || {}).message] ||
//               "Some error occurred",
//           });
//         }
//       });
//   };
// };

export const saveOrUpdateSponsorship = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/sponsorship/saveOrUpdate`, payload)
      .then(res => {
       // getSponsorships(props)(dispatch);
      //  sponsorshipCount(props)(dispatch);
        props.history.push('/admin/sponsorship');
        showToasterMessage({ messageType: 'success', description: 'Sponsorship saved successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
};
