/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Input, Checkbox } from 'antd';
import { injectIntl } from 'react-intl';
import { CustomButton } from '../../general/CustomButton';
import { CustomUploader } from '../../general/CustomUploader';
import '../../../assets/stylesheets/common/_email_template.scss';
import { bytesToSize, validateEmail } from './../../../utils';
import { EMAIL_BODY_CONFIGURATION } from '../../../static/ckEditorConfigConstants';
import CKEditor from '../../general/CustomCKEditor/ckeditor';
import filter from 'lodash.filter';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { TRANSACTION_TYPES } from '../../../static/constants';
import uniq from 'lodash.uniq';
import "./index.scss";
//import config from '../../../config/environmentConfig';

const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
const { Option } = Select;


// const MultiSelect = (props) => {
//   const values = props.values || [];
//   let optionValues = props.items || [];
//   optionValues = difference(optionValues, values);
//   const onSelect = props.onSelect || (() => { });
//   const options = [];
//   optionValues.forEach((email, index) => {
//     options.push(<Option key={index}>{email}</Option>);
//   });

//   return (
//       <Select
//           onSelect={onSelect}
//           mode="tags"
//           placeholder={props.placeholder}
//           defaultValue={values}
//           style={{ width: '100%' }}
//       >
//         {options}
//       </Select>
//   );
// }

//const TabSelect = forwardRef(MultiSelect);
class EmailBody extends Component {

  constructor(props) {
    super(props);
    let fileList = [{
      uid: '-1',
      name: (props.txData || {}).fileName || "Document.pdf",
      status: 'done',
      url: this.props.txFileUrl
    }];
    if (props.txData2) {
      fileList.push({
        uid: '-2',
        name: (props.txData2 || {}).fileName || "Document.pdf",
        status: 'done',
        url: this.props.txFileUrl2
      })
    }
    this.state = {
      sender: props.userInfo.email || '',
      from: props.userInfo.email || '',
      receivers: props.receivers || [],
      ccRecievers: props.ccRecievers || [],
      bccRecievers: props.bccRecievers || [],
      subject: props.subject,
      body: props.body || props.emailBody,
      ccVisible: true,
      bccVisible: true,
      attachments: {
        fileList: fileList
      },
      senderList: props.senderList || [],
    };

    this.uploadButtonRef = React.createRef();

    this.formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 2 },
      },
      wrappercol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };

    this.tailFormItemLayout = {
      wrappercol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 22,
          offset: 2,
        },
      },
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...(this.props.emailPayloadProps || {}),
          sender: (values.senders || [])[0],
          from: (values.senders || [])[0],
          to: Array.isArray(values.receivers) ? values.receivers : [values.receivers],
          // cc: Array.isArray(values.ccRecievers) ? values.ccRecievers: [values.ccRecievers],
          // bcc: Array.isArray(values.bccRecievers) ? values.bccRecievers: [values.bccRecievers],
          subject: values.subject,
          message: this.state.body || this.props.emailBody,
          sendMeACopy: values.sendMeACopy ? 1 : 0,
           createdByEmpId: (this.props.userInfo || {}).relationshipEmployeeId
        };
        if (values.ccRecievers) {
          payload.cc = Array.isArray(values.ccRecievers) ? values.ccRecievers : [values.ccRecievers];
        }
        if (values.bccRecievers) {
          payload.bcc = Array.isArray(values.bccRecievers) ? values.bccRecievers : [values.bccRecievers];
        }
        if (this.props.txData && this.props.txData.supplierId) {
          payload.supplierId = this.props.txData.supplierId;
        } else if (this.props.txData && this.props.txData.customerId) {
          payload.customerId = this.props.txData.customerId;
        }
        if (this.props.txData) {
          payload.relationshipId = (this.props.txData.companyInfo || {}).relationshipId
          payload.salesOrderMasterId = this.props.txData.salesOrderMasterId;
          payload.invoiceMasterId = this.props.txData.invoiceMasterId;
          payload.poMasterId = this.props.txData.poMasterId;
          payload.purchaseInvoiceMasterId = this.props.txData.purchaseInvoiceMasterId;
          payload.creditMemoMasterId = this.props.txData.creditMemoMasterId;
          payload.debitMemoMasterId = this.props.txData.debitMemoMasterId;
          payload.customerPaymentId = this.props.txData.customerPaymentId;
          payload.supplierPaymentId = this.props.txData.supplierPaymentId;
          payload.salesQuotationMasterId = this.props.txData.salesQuotationMasterId;
          payload.purchaseRequestMasterId = this.props.txData.purchaseRequestMasterId;
          payload.rfqMasterId = this.props.txData.rfqMasterId;
          payload.inquiryMasterId = this.props.txData.inquiryMasterId;
          payload.inboundDeliveryMasterId = this.props.txData.inboundDeliveryMasterId;
          payload.invoiceProformaId = this.props.txData.invoiceProformaId
        }else{
          payload.relationshipId = (this.props.companyInfo || {}).relationshipId
        }
        // if (this.props.txData && this.props.txData.salesInvoiceDetail && this.props.txData.salesInvoiceDetail.additionalInfoList && this.props.txData.salesInvoiceDetail.additionalInfoList[0] && this.props.txData.salesInvoiceDetail.additionalInfoList[0].paymentGatewayId) {
        //  let previewUrl = config.UI_BASE_URL + 'invoice?webInvoiceId=' + this.props.txData.salesInvoiceDetail.webInvoiceId;
        //   payload.message = payload.message + " <a target='_blank' href=" + previewUrl + "><button>Pay Online</button></a>"
        // }
         // var token = this.props.payOnlineUrl;
        // payload.message = payload.message + " <a target='_blank' href="+token+"><button>Reset your password</button></a>";
      //  payload.message = payload.message + '<button type="button" onclick="alert(\'Hello world!\')">Click Me!</button>';
        switch (this.props.type) {
          case TRANSACTION_TYPES.SALES_ORDER: {
            payload.docType = 'sales-order';
            break;
          }
          case TRANSACTION_TYPES.SALES_INVOICE: {
            payload.docType = 'sales-invoice';
            break;
          }
          case TRANSACTION_TYPES.PROFORMA_INVOICE: {
            payload.docType = 'sales-invoice';
            break;
          }
          case TRANSACTION_TYPES.PURCHASE_ORDER: {
            payload.docType = 'purchase-order';
            break;
          }
          case TRANSACTION_TYPES.PURCHASE_INVOICE: {
            payload.docType = 'purchase-invoice';
            break;
          }
          case TRANSACTION_TYPES.CREDIT_MEMO: {
            payload.docType = 'credit-memo';
            break;
          }
          case TRANSACTION_TYPES.DEBIT_MEMO: {
            payload.docType = 'debit-memo';
            break;
          }
          case TRANSACTION_TYPES.CUSTOMER_PAYMENT: {
            payload.docType = 'sales-payment';
            break;
          }
          case TRANSACTION_TYPES.SUPPLIER_PAYMENT: {
            payload.docType = 'purchase-payment';
            break;
          }
          case TRANSACTION_TYPES.SALES_QUOTE: {
            payload.docType = 'sales-quote';
            break;
          }
          case TRANSACTION_TYPES.PURCHASE_REQUEST: {
            payload.docType = 'purchase-request';
            break;
          }
          case TRANSACTION_TYPES.RFQ: {
            payload.docType = 'rfq';
            break;
          }
          case TRANSACTION_TYPES.INBOUND_DELIVERY: {
            payload.docType = 'inbound-delivery';
            break;
          }
          case TRANSACTION_TYPES.INQUIRY: {
            payload.docType = 'sales-inquiry';
            break;
          }
          case TRANSACTION_TYPES.SHIPMENT: {
            payload.docType = 'shipment';
            break;
          }
          case TRANSACTION_TYPES.SO_PACKAGE: {
            payload.docType = 'packaging';
            break;
          }
          case TRANSACTION_TYPES.CUSTOMER_PARTY_LEDGER: {
            payload.docType = 'customer-party-ledger';
            break;
          }
          case TRANSACTION_TYPES.SUPPLIER_PARTY_LEDGER: {
            payload.docType = 'supplier-party-ledger';
            break;
          }
          case TRANSACTION_TYPES.BUSINESS_EXPENSE: {
            payload.docType = 'relationship-expense';
            break;
          }
          default: {
            break;
          }
        }

        let formData = new FormData();
        formData.append('payload', JSON.stringify(payload));

        if (this.state.attachments
          && Array.isArray(this.state.attachments.fileList)
          && this.state.attachments.fileList.length) {
          this.state.attachments.fileList.forEach(fileObj => {
            if (fileObj.uid === "-1") {
              const file = this.props.txFile;
              if (file) {
                formData.append('files', file, fileObj.name);
              }
            } else if (fileObj.uid === "-2") {
              const file2 = this.props.txFile2;
              if (file2) {
                formData.append('files', file2, fileObj.name);
              }
            } else {
              const file = new Blob([fileObj.originFileObj], { type: fileObj.type });
              formData.append('files', file, fileObj.name);
            }
          })
        }
        this.props.sendEmail(this.props, formData);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const receiverItems = [];
    const ccRecieversItems = [];
    const bccRecieversItems = [];
    const senderItems = [];

    uniq(this.props.senderList).forEach((data, i) => {
      senderItems.push(<Option value={data} disabled={this.props.userInfo.email === data && this.props.userInfo.isFromEmailVerified === 0}>
        <div title={data}>{data } {(this.props.userInfo.email === data && this.props.userInfo.isFromEmailVerified === 0)? <span> (This email has not been authorized to send email)</span> :''}</div>
        </Option>);
    });

    uniq(this.props.receiverItems).forEach((data, i) => {
      receiverItems.push(<Option value={data}>{data}</Option>);
    });

    uniq(this.props.ccRecieversList).forEach((data, i) => {
      ccRecieversItems.push(<Option key={data}>{data}</Option>);
    });

    uniq(this.props.bccRecieversList).forEach((data, i) => {
      bccRecieversItems.push(<Option key={data}>{data}</Option>);
    });

    this.defaultFileList = [
      {
        uid: '-1',
        name: (this.props.txData || {}).fileName || 'Document.pdf',
        status: 'done',
        url: this.props.txFileUrl,
        blobData: this.props.txFile
      }
    ]
    if (this.props.txData2) {
      this.defaultFileList.push({
        uid: '-2',
        name: (this.props.txData2 || {}).fileName || 'Document2.pdf',
        status: 'done',
        url: this.props.txFileUrl2,
        blobData: this.props.txFile2
      })
    }


    return (
      <div className="modal-content" >
        <Spin indicator={antIcon} className="spinner" tip="Loading..." spinning={this.props.isEmailSpinner} >
          <Form className="email-form-group" {...this.formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
            <div className="scrollable-content">
              {(senderItems && senderItems.length) ?
                <div className="email-to-control">
                  <Form.Item label='From' colon={false} >
                    {getFieldDecorator('senders', {
                      initialValue: this.props.senders,
                      rules: [
                        {
                          required: true,
                          message: 'From is required.',
                        }
                      ],
                      //  })(<Input />)}
                    })(<div className="to-input-control" style={{ paddingLeft: '10px' }}><Select
                      // tokenSeparators={[',']}
                      bordered={false}
                      onDeselect={
                        (value) => {
                          let senders = Object.assign([], this.state.senders);
                          senders = filter(senders, (obj) => {
                            return obj !== value;
                          });
                          this.props.form.setFieldsValue({ senders });
                          this.setState({ senders });
                        }
                      }
                      onSelect={(selectedValue) => {
                        const senders = [selectedValue];
                        //senders.push(selectedValue);
                        this.props.form.setFieldsValue({ senders });
                        this.setState({ senders });
                      }}
                      onChange={(e) => {
                        this.setState({ senders: [e] });
                        this.props.form.setFieldsValue({ senders: [e] });
                      }}

                      //mode="tags"
                      defaultValue={this.state.senders}
                      value={this.state.senders || this.props.senders}
                    >
                      {senderItems}
                    </Select>
                    </div>
                    )}
                  </Form.Item>
                </div>
                : ''
              }

              <div className="email-to-control">
                <Form.Item label='To' colon={false} >
                  {getFieldDecorator('receivers', {
                    rules: [
                      
                      {
                        required: true,
                        message: 'Enter valid email address',
                        validator: (rule, value, cb) => {
                          let isInValidEmail = false;
                          if (!this.state.receivers || !this.state.receivers.length) {
                            return cb(rule.message);
                          }
                          (this.state.receivers || []).forEach((v) => {
                            if (!validateEmail(v)) {
                              isInValidEmail = true
                            }
                          });
                          if (isInValidEmail) {
                            return false;
                          }

                          if (rule.required) {
                            if (value.length) {
                              return cb();
                            } else {
                              return cb(rule.message);
                            }
                          }
                          //return cb();
                        },
                      }
                    ],
                    initialValue: this.state.receivers || ''
                  })(
                    <div className="to-input-control">
                      <Select
                        tokenSeparators={[',']}
                        bordered={false}
                        onDeselect={
                          (value) => {
                            let receivers = Object.assign([], this.state.receivers);
                            receivers = filter(receivers, (obj) => {
                              return obj !== value;
                            });
                            this.props.form.setFieldsValue({ receivers });
                            this.setState({ receivers });
                          }
                        }
                        onSelect={(selectedValue) => {
                          const receivers = Object.assign([], this.state.receivers);
                          receivers.push(selectedValue);
                          this.props.form.setFieldsValue({ receivers });
                          this.setState({ receivers });
                        }}
                        onChange={(e)=>{
                          this.setState({ receivers: e });
                          this.props.form.setFieldsValue({ receivers: e });
                        }}
                        
                        mode="tags"
                        defaultValue={this.state.receivers}
                      >
                        {receiverItems}
                      </Select>

                      <div className="cc-bcc-button">
                        <a onClick={
                          () => {
                            this.setState({ ccVisible: !this.state.ccVisible });
                            if (this.state.ccVisible) {
                              this.setState({ ccRecievers: [] });
                            }
                          }
                        }>
                          {this.state.ccVisible ? 'Hide CC' : 'Show CC'}
                        </a>
                        <span className="prl5">{' | '}</span>
                        <a onClick={
                          () => {
                            this.setState({ bccVisible: !this.state.bccVisible });
                            if (this.state.bccVisible) {
                              this.setState({ bccRecievers: [] });
                            }
                          }
                        }>
                          {this.state.bccVisible ? 'Hide BCC' : 'Show BCC'}
                        </a>
                      </div>
                    </div>
                    // <TabSelect
                    //   items={this.props.receiverItems}
                    //   values={this.state.receivers}
                    //   onSelect={(selectedValue) => {
                    //     const receivers = Object.assign([], this.state.receivers);
                    //     receivers.push(selectedValue);
                    //     this.props.form.setFieldsValue({ receivers });
                    //     this.setState({ receivers });
                    //   }}
                    // />
                  )}
                </Form.Item>

                {
                  this.state.ccVisible
                    ? (
                      <Form.Item label='CC' colon={false} >
                        {getFieldDecorator('ccRecievers', {
                          rules: [

                            {
                             // required: true,
                              message: 'Enter valid email address',
                              validator: (rule, value, cb) => {
                                let isInValidEmail = false;
                                // if (!this.state.ccRecievers || !this.state.ccRecievers.length) {
                                //   return cb(rule.message);
                                // }
                                (this.state.ccRecievers || []).forEach((v) => {
                                  if (!validateEmail(v)) {
                                    isInValidEmail = true
                                  }
                                });
                                if (isInValidEmail) {
                                  return false;
                                }else{
                                  return true;
                                }

                                // if (rule.required) {
                                //   if (value.length) {
                                //     return cb();
                                //   } else {
                                //     return cb(rule.message);
                                //   }
                                // }
                                //return cb();
                              },
                            }
                          ],
                          initialValue: this.state.ccRecievers || ''
                        })(
                          <div className="to-input-control">
                            <Select
                              bordered={false}
                              onChange={(val) => {
                                this.setState({
                                  ccRecievers: val
                                });
                                this.props.form.setFieldsValue({ ccRecievers: val });
                              }}
                              onSelect={(selectedValue) => {
                                const ccRecievers = Object.assign([], this.state.ccRecievers);
                                ccRecievers.push(selectedValue);
                                this.props.form.setFieldsValue({ ccRecievers });
                                this.setState({ ccRecievers });
                              }}
                              mode="tags"
                              defaultValue={this.state.ccRecievers}
                            >
                              {ccRecieversItems}
                            </Select>
                          </div>
                        )}
                      </Form.Item>
                    )
                    : ''
                }

                {
                  this.state.bccVisible
                    ? (
                      <Form.Item label='BCC' colon={false} >
                        {getFieldDecorator('bccRecievers', {
                          rules: [

                            {
                             // required: true,
                              message: 'Enter valid email address',
                              validator: (rule, value, cb) => {
                                let isInValidEmail = false;
                                // if (!this.state.bccRecievers || !this.state.bccRecievers.length) {
                                //   return cb(rule.message);
                                // }
                                (this.state.bccRecievers || []).forEach((v) => {
                                  if (!validateEmail(v)) {
                                    isInValidEmail = true
                                  }
                                });
                                if (isInValidEmail) {
                                  return false;
                                }else{
                                  return true;
                                }

                                // if (rule.required) {
                                //   if (value.length) {
                                //     return cb();
                                //   } else {
                                //     return cb(rule.message);
                                //   }
                                // }
                                //return cb();
                              },
                            }
                          ],
                          initialValue: this.state.bccRecievers || ''
                        })(
                          <div className="to-input-control">
                            <Select
                              bordered={false}
                              onChange={(val) => {
                                this.setState({
                                  bccRecievers: val
                                });
                                this.props.form.setFieldsValue({ bccRecievers: val });
                              }}
                              onSelect={(selectedValue) => {
                                const bccRecievers = Object.assign([], this.state.bccRecievers);
                                bccRecievers.push(selectedValue);
                                this.props.form.setFieldsValue({ bccRecievers });
                                this.setState({ bccRecievers });
                              }}
                              mode="tags"
                              defaultValue={this.state.bccRecievers}
                            >
                              {bccRecieversItems}
                            </Select>
                          </div>
                        )}
                      </Form.Item>
                    )
                    : ''
                }
              </div>

              <Form.Item label='Subject' colon={false} className="subject-form-control">
                {getFieldDecorator('subject', {
                  initialValue: this.props.subject,
                  rules: [
                    {
                      required: true,
                      message: 'Subject is required.'
                    },
                    {
                      validator: (rule, value, cb) => {
                        if (rule.required) {
                          const subject = this.state.subject;
                          if (subject && subject.length) {
                            return cb();
                          } else {
                            return cb(rule.message);
                          }
                        }
                        return cb();
                      }
                    }
                  ],
                })
                // (this.props.txData.transactionName === 'salesInvoice' && this.props.isEditorDataReady ? 
                // <CKEditor
                //     // editor={ClassicEditor}
                //     // config={FULL_CONFIGURATION}
                //     key={`${"subject"}`}
                //     data={this.props.subject}
                //     onInit={editor => {
                //       this.editorSubject = editor;
                //     }}
                //     onChange={(event) => {
                //       const data = event.editor.getData();
                //       this.setState({ subject: data });
                //     }}
                //     config={CUSTOM_SUBJECT_CONFIGURATION}
                //   />
                //   : <Input />)}
                  (<Input />)}
              </Form.Item>

              <div className={(this.state.attachments.fileList || {}).length ? "file-uploader-container" : ''}>
                <CustomUploader
                  className={"upload-files-container"}
                  defaultFileList={this.defaultFileList}
                  fileLimit={5}
                  hideDownloadIcon={!this.props.isFileLoaded}
                  onDownload={(data) => {
                    let file = null;
                    if (data.originFileObj) {
                      file = new Blob([data.originFileObj]);
                    } else if (data.uid === "-1") {
                      file = this.props.txFile;
                    } else if (data.uid === "-2") {
                      file = this.props.txFile2;
                    }
                    // const file = data.originFileObj ?  new Blob([data.originFileObj], {type: 'application/pdf'}) : this.props.txFile;
                    if (!file) return;
                    const fileURL = URL.createObjectURL(file);
                    const link = document.createElement("a");
                    document.body.appendChild(link);
                    link.download = data.name;
                    link.href = fileURL;
                    setTimeout(function () {
                      link.click();
                    }, 100);
                  }}
                  reference={this.uploadButtonRef}
                  // hideButton={true}
                  onChange={(obj) => {
                    if (obj && obj.file) {
                      obj.file.name = `${obj.file.name} (${bytesToSize(obj.file.size)})`;
                    }
                    this.setState({ attachments: obj });
                  }}
                />
              </div>
              {this.props.isEditorDataReady ?
                <Form.Item colon={false} className="email-editor">
                  {getFieldDecorator('body', {
                    initialValue: this.props.emailBody,
                    rules: [
                      {
                        validator: (rule, value, cb) => {
                          if (rule.required) {
                            const emailBody = this.state.body;
                            if (emailBody && emailBody.length) {
                              return cb();
                            } else {
                              return cb(rule.message);
                            }
                          }
                          return cb();
                        }
                      }
                    ],
                  })(
                    <CKEditor
                      // editor={ClassicEditor}
                      // config={FULL_CONFIGURATION}
                      key={`${"emailBody111"}`}
                      data={this.props.emailBody}
                      onInit={editor => {
                        this.editor = editor;
                      }}
                      onChange={(event) => {
                        const data = event.editor.getData();
                        this.setState({ body: data });
                      }}
                      onAttachmentClick={() => {
                        this.uploadButtonRef && this.uploadButtonRef.current
                          && ReactDOM.findDOMNode(this.uploadButtonRef.current).click()
                      }}
                      config={EMAIL_BODY_CONFIGURATION}
                    />
                  )}
                </Form.Item> : ''
              }

              <Form.Item className="copy-checkbox">
                {getFieldDecorator('sendMeACopy', {
                })(
                  <Fragment>
                    <Checkbox id="sendCopy" checked={this.props.isSendCopy} onChange={(e) => {
                      this.props.updateState({
                        isSendCopy: e.target.checked
                      })
                    }} />
                    <label className="pl10" htmlFor="sendCopy">Send me a copy</label>
                  </Fragment>
                )}
              </Form.Item>

            </div>
            <div {...this.tailFormItemLayout} className="footer-btn-group mt15">
              <CustomButton
                type='primary'
                htmlType='submit'
                intlId='forgot.btn.submit'
                defaultMessage=''
              />
            </div>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create({ name: 'Email_Template' })(injectIntl(EmailBody));
