import React, { Fragment } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, DatePicker } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

import { CONSTANTS} from '../../../../static/constants';
import { getMomentDateForUI } from '../../../../utils';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class JournalDetails extends React.Component {
    
    render() {
        
        return (
            <Fragment>
                <Row type='flex' justify='end'>
                    <Col span={6}>
                        
                    </Col>

                    <Col span={9} offset={9}>
                        <div className='right-col align-form'>
                            <Form.Item>

                                <div className='date-picker-container'>
                                    <Row>
                                        <Col span={12}>
                                            <div className="title">
                                                <FormattedMessage id='finance.JournalDetail.journalDate' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div className="value">
                                                <DatePicker
                                                    allowClear={false}
                                                    format={format}
                                                    key={`${getMomentDateForUI({date: new Date(this.props.journalDate), format})}`}
                                                    defaultValue={getMomentDateForUI({date: new Date(this.props.journalDate), format})}
                                                    onChange={(selectedDate) => {
                                                        this.props.updateState({
                                                            journalDate: selectedDate
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

            </Fragment>
        )
    }
}

export default injectIntl(JournalDetails);