/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import PageBreadcrumb from "../../PageBreadcrumb";
import { AgGridReact } from "ag-grid-react";
import AddCountry from "../../../containers/modal/modalBody/settings/shipping/AddCountry";


const ShippingComp = (props) => {
    const { shippingCountries } = props;
    const breadCrumbList = [
        {
            name: 'Dashboard',
            link: 'dashboard'
        }, {
            name: 'Settings',
            link: '/admin/show-settings'
        },
        {
          name: 'Shipping',
         
      }
    ];
    const deleteHandle = (shippingCountry) => {
        const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.deleteShippingMarketplaceCountry(shippingCountry, props);
            },
        };
        props.showModal(modalData);
    };

    const agGridStyle = {
        height: "100%",
        width: "100%",
    };
    const addAndUpdateCountry = (payload) => {
        const modalData = {
          title:<FormattedMessage id='setting.marketplaceConfiguration.taxes.model.name' defaultMessage='' />,
          modalBody: (
            <AddCountry {...props}
              countryId={payload?.countryId}
            countryName = {payload?.countryName}
            />
          ),
          width: 600,
          hideFooter: true,
        //   wrapClassName: "log-communication-modal",
        };
        props.showModal(modalData);
      };
    const columnDefs = [
        {
            headerComponentFramework: () => {
                return (
                    <FormattedMessage
                        id="setting.marketplaceConfiguration.shipping.listing.header.country"
                        defaultMessage=""
                    />
                );
            },
            field: "countryName",
            resizable: true,
            minWidth: 110,
            maxWidth: 400,
        },
        {
            headerComponentFramework: () => {
                return (
                    <div>
                        <FormattedMessage
                            id="setting.marketplaceConfiguration.shipping.listing.header.state"
                            defaultMessage=""
                        />
                    </div>
                );
            },
            cellRendererFramework: (link) => {
                return "4 regions";

            },
            field: "state",
            resizable: true,
            minWidth: 124,
            maxWidth: 300,
        },
        {
            headerComponentFramework: () => {
                return (
                    <FormattedMessage
                        id="setting.marketplaceConfiguration.shipping.listing.header.shippingChoice"
                        defaultMessage=""
                    />
                );
            },
            cellRendererFramework: (link) => {
                return link.data.shippingChoiceName ? link.data.shippingChoiceName : 'Not Setup';
            },
            field: "marketplaceShippingChoiceId",
            minWidth: 70,
            maxWidth: 405,
        },
        {
            field: "action",
            headerComponentFramework: () => {
                return <div className="cursor-pointer">

                </div>;
            },
            colId: "action_1",
            pinned: "right",
            lockPosition: true,
            cellRendererFramework: (params) => {
                return <div>
                    <span style={{ marginRight: '10px' }} className="cursor-pointer"                     
                        onClick={() => {
                          addAndUpdateCountry(params.data);
                        }}
                            >
                        <FormattedMessage
                            id="commons.listing.edit.tooltip.msg"
                            defaultMessage=""
                        />
                    </span>
                    |
                    <span style={{ marginLeft: '10px' }} className="cursor-pointer"
                        onClick={
                            () => {
                                deleteHandle(params.data);
                            }}>
                        <FormattedMessage
                            id="commons.listing.delete.tooltip.msg"
                            defaultMessage=""
                        />
                    </span>
                </div>;
            },
            minWidth: 300,
            maxWidth: 300,
            suppressMovable: true,
        }
    ];

    return (
        <Fragment>
            <div className="view-top-bar">
                <div className="top-view-left">
                    <div className="page-heading">
                        Account Settings
                    </div>
                    <div className="vertical-sep" />
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div>
            </div>
            <div className="ledger-details-container">
                {/* <div className="ptb10">
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div> */}


                {/* <div className="ledger-details">
                    <div>
                        <Dropdown
                            className={(props.submittedOnce && !props.type) ? 'input-text-error' : ''}
                            items={marketplaceCountries}
                            valueKeyName='countryName'
                            optionKeyName='marketplaceTaxCountryId'
                            value={selectedMarketPlaceCountry.marketplaceTaxCountryId}
                            // placeholder={props.intl.formatMessage(
                            //     {
                            //         id: 'membership.membershipOption.addDrawer.maxFamilyMembers',
                            //         defaultMessage: ''
                            //     })
                            // }
                            onChange={
                                (optionValue, option) => {
                                    props.updateState({
                                        selectedMarketPlaceCountry: marketplaceCountries.find((element) => {
                                            return element.marketplaceTaxCountryId === option.value;
                                        })
                                    })
                                }
                            }
                        />
                    </div>
                    <FormattedMessage id='setting.marketplaceConfiguration.taxSetting.radioGroupMsg' defaultMessage='' />
                    <Radio.Group
                        onChange={(e) => {
                            updateTaxSetting(e.target.value);
                        }}
                        value={selectedMarketPlaceCountry.isTaxIncludedInPrice}
                        defaultValue={selectedMarketPlaceCountry.isTaxIncludedInPrice}
                    >
                        <Radio value={1} className=" pt10 ">
                            <FormattedMessage id='setting.marketplaceConfiguration.taxSetting.radio1' defaultMessage='' />
                        </Radio>
                        <br></br>
                        <Radio value={0} className=" pt10 ">
                            <FormattedMessage id='setting.marketplaceConfiguration.taxSetting.radio2' defaultMessage='' />
                        </Radio>
                        <br></br>
                    </Radio.Group>
                </div> */}
                <div className="view-container sales-order-details mt0">
                    <div className='team-and-free'>
                        <div className="right-align pb10">
                            <div className="action-dropdown-btn">
                                <button onClick={() => {                               
                                    addAndUpdateCountry();
                                }}>
                                    <i className='fa fa-plus' />
                                    <FormattedMessage id="setting.marketplaceConfiguration.taxes.listing.header.countryName" defaultMessage="" />
                                </button>
                            </div>
                        </div>

                        <div className="agGridWrapper">
                            <div className="ag-theme-balham" style={agGridStyle}>
                                <AgGridReact
                                    // onGridReady={onGridReady}
                                    columnDefs={columnDefs}
                                    defaultColDef={{
                                        flex: 1,
                                        autoHeight: true,
                                        wrapText: true,
                                    }}
                                    rowData={shippingCountries || []}
                                    rowDragManaged={true}
                                    domLayout={"autoHeight"}
                                    //getRowHeight={getRowHeight}
                                    suppressDragLeaveHidesColumns={true}
                                    animateRows={true}

                                ></AgGridReact>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};


export default injectIntl(ShippingComp);
