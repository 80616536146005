import React, {useRef, useEffect,  useState} from 'react';
import { Link } from 'react-router-dom';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Button,  Row, Tooltip, Col } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import cashSaleInvoice from '../../../../../containers/customer/invoice/cashSaleInvoice';
import { DropdownPriceList } from '../../../../general/MarkupDropdown';
import { Dropdown } from '../../../../general/Dropdown';
import {  MODAL_TYPE, CONSTANTS, ICONS } from '../../../../../static/constants';
import { AG_GRID_CONSTANTS } from '../../../../../static/agGridConstants';
import LoadingIcon from "../../../../../assets/images/loading.gif"
import StudentInvoice from '../../../../../containers/customer/invoice/StudentInvoice';
import MultiCurrencySalesInvoice from '../../../../../containers/customer/invoice/MultiCurrencySalesInvoice';
import StandardSalesInvoice from '../../../../../containers/customer/invoice/StandardSalesInvoice';
import { DetailDropdown } from '../../../../general/DetailDropdown';
import { QuestionCircleTwoTone } from "@ant-design/icons";
import PayInvoice from '../../../../../containers/customer/invoice/PayInvoice';
import CashSaleInvoice from '../../../../../containers/customer/invoice/cashSaleInvoice';

const ErrorComp = (props) => {
    const { warningData, show } = props;
  
    return (
      <div>
        <Row>
          <Col span={24}>
            <div>
              {(warningData || []).length ? (
                <div>
                  {" "}
                  {(warningData || [])?.map((item, i) => {
                    return <div className="error">{item?.description}</div>;
                  })}
                </div>
              ) : (
                <div className="success">
                  There is no {show} in the Invoices!!
                </div>
              )}
            </div>
          </Col>
        </Row>
  
        
      </div>
    );
  };
const CustomerHeaderAction = (props) => {
    const [templateDropdown, updateTemplateDropdown] = useState(false);
    const {
        intl,
        customers,
        pageNumber,
        customerName,
        updateState,
        priceListId,
        priceTypeToApply,
        priceList,
        showModal,
        companyInfo
    } = props;

     useEffect(()=>{
       if(props.validatedGstr1){
        const modalData = {
            title: props.btnName,
            modalBody: (
              <ErrorComp {...props} warningData={props?.errorsData} show={props.btnName} />
            ),
            width: 900,
            closableIcon : true ,
            hideFooter: true,
          };
      
          props.pushModalToStack(modalData);
       }
     },[])
    const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();

        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });

        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);

            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep

        return innerRef; // return ref; client can omit `useRef`
    }

    const innerRef = useOuterClick(e => {
       updateTemplateDropdown(!templateDropdown)
    });

    const handleNewPriceListAddition = (props, payload) => {
        const formData = payload.formData;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PRICE_LIST,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.PRICE_LIST: {
                deleteFunc = props.deletePriceList;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }
        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }

    return (<>
        <div className='left-itms'>
      <ul>
        <li className='title'>
          <FormattedMessage id='heading.sales.salesInvoice.create' />
        </li>
        <li className='sep' />
        <li>
          <FormattedMessage id='customer.salesInvoice.form.invoiceNumber.label' defaultMessage='' /> &nbsp; - &nbsp;
          {props.nextTxNumber ?
            <span>{getFormatedNextTxNumber(props)}</span>
            //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
            :
            <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />
          }
        </li>
        <li style={{gap:"2px"}}>
         <Checkbox  checked={props.isManualInvoiceNumber} onChange={(e)=>{
            if(!e.target.checked){
                props.updateState({
                    manualInvoiceNumber:"",
                    validManualInvoiceNumber:true

                })
            }
              props.updateState({
                isManualInvoiceNumber: e.target.checked
            });
         }}/>
         <Tooltip
                  placement="right"
                  title={"To Enter Manual Invoice #"}
                >
                  <QuestionCircleTwoTone />
                </Tooltip>
        </li>
      </ul>
     </div>
        <div className="right-itms">
            {(!props.isSoConversionToInvoice && !props.isbankReco && !props.isShipmentConversion) && <Checkbox   onChange={(e) => {
                props.updateState({
                    isCashSales: e.target.checked
                });
               if(e.target.checked){

                const modalData = {
                    title: <FormattedMessage id='sidebar.salesPayment.popup.title' defaultMessage='' />,
                    modalBody: <CashSaleInvoice {...props} invoiceData={props?.salesInvoiceEditDetail} paymentDetails = {(props.salesInvoiceEditDetail?.customerPaymentList||[])[0]|| props.paymentDetails || {}} />,
                    wrapClassName: 'sale-invoice-modal',
                    width: 500,
                    hideFooter: true,
                    handleSubmit: (prm, prm2) => {
                       
                    }
                };
                props.pushModalToStack(modalData);
               } 
            }} checked= {props.isCashSales} />}
        
            {(!props.isSoConversionToInvoice && !props.isbankReco  && !props.isShipmentConversion) &&
                <span className='line-view'>
                    <FormattedMessage id='salesInvoice.cashSale.checkbox.text' defaultMessage='' />
                </span>
            }
            {(!props.isSoConversionToInvoice && !props.isShipmentConversion) && <DropdownPriceList style={{ width: "200px" }}
                items={priceList}
                valueKeyName='priceListName'
                optionKeyName='priceListId'
                value={priceListId}
                canAddNew={true}
                canDelete={true}
                deleteClickHandler={(payload) => {
                    deleteClickHandler(props, MODAL_TYPE.PRICE_LIST, payload);
                }}
                onAddNewClick={(payload) => {
                    payload.formData = {
                        priceListName: payload.textEntered,
                        submittedOnce: false
                    }
                    handleNewPriceListAddition(props, payload, MODAL_TYPE.PRICE_LIST)
                }}
                placeholder={intl.formatMessage({
                    id: 'customer.salesInvoice.form.priceList.placeholder', defaultMessage: ''
                })}
                onSelect={
                    (priceListId) => {
                        const priceListData = find(priceList, {priceListId }) || {};
                        updateState({ toUpdatePriceList: true, priceListId, priceListName: priceListData.priceListName, priceListType: priceListData.type, priceListPercentage: priceListData.percentage });
                    }
                    }
                    allowClear={true}
                    onClear={() => {
                    updateState({toUpdatePriceList: true, priceListId: undefined, priceListName: undefined, priceListType: undefined, priceListPercentage: undefined})
                    }}
            />}

            {(!props.isSoConversionToInvoice  && !props.isShipmentConversion ) && 
            <Dropdown style={{ width: "200px" }}
                items={AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST}
                value={priceTypeToApply || AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0]}
                onSelect={(priceTypeToApply) => {
                    updateState({
                        priceTypeToApply,
                        isToPriceTypeToApply: true
                    })
                }}
                placeholder={intl.formatMessage({
                    id: 'customer.salesInvoice.form.priceType.placeholder', defaultMessage: ''
                })}
                allowClear={true}
                onClear={() => {
                    updateState({
                        priceTypeToApply: undefined,
                        isToPriceTypeToApply: false,
                    })
                }}
            />}
            
            { (!props.isSoConversionToInvoice  && !props.isShipmentConversion)?  
            <DetailDropdown style={{ width: "200px" }}
                items={customers[pageNumber] || []}
                valueKeyName='companyName'
                optionKeyName='customerId'               
                value={customerName}
                className = "detailDropdown"
                onSearch= {(searchedText)=>{
                    props.fetchCustomers({
                        // isCompact: true,
                        searchedText: searchedText,
                        pageNumber: 1,
                        hideSpinner: true,
                        pageSize: 100,
                        companyInfo:companyInfo,
                        relationshipId: (props.companyInfo || {}).relationshipId,filterData:true
                    })
                }}
                placeholder={intl.formatMessage({
                    id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                })}
                canAddNew={true}
                canDelete={true}
                deleteClickHandler={(payload) => {
                    props.deleteCustomers({ groupCustomerIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                }}
                onAddNewClick={(payload) => {
                    props.updateHeaderState({
                        customer: {
                            customerDrawerVisible: true,
                            customerName: payload.textEntered,
                        }
                    })
                }}
                onSelect={
                    (customerId) => {
                        props.fetchCustomerDetailsByCustomerId({
                            customerId,
                            relationshipId: (props.companyInfo || {}).relationshipId
                        });
                        props.fetchAddresses({ customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.SHIPPING_ADDRESS });
                        props.fetchAddresses({ customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.BILLING_ADDRESS });
                        props.fetchAllUnfulfilledSalesOrderByCustomerId({ relationshipId: (props.companyInfo || {}).relationshipId, customerId: customerId });
                        props.fetchAllContacts({ customerId, relationshipId: (props.companyInfo || {}).relationshipId });
                    }
                }
                allowClear={true}
                onClear={() => {
                    updateState({
                        customer: undefined,
                        customerName: undefined,
                        customerPaymentTerm: undefined,
                        customerShippingAddress: undefined,
                        customerBillingAddress: undefined,
                        placeOfSupply: undefined,
                        invoiceDueDate: undefined,
                        selectedContact: undefined,
                    })
                    props.resetUnfulfilledSalesOrder();
                }}
            /> : null}
            
            <Button onClick={() => {
                props.updateState({
                    ledgerDrawerVisible: true
                })
            }} >
            <i className='fa fa-book fa-lg'> </i>
            </Button>
           {
            props.validatedGstr1 ? (  <Button style={{borderColor:"red" ,color:"red" ,backgroundColor:" #f8f8c0"}} onClick={()=>{
                const modalData = {
                    title: props.btnName,
                    modalBody: (
                      <ErrorComp {...props} warningData={props?.errorsData} show={props.btnName} />
                    ),
                    closableIcon : true ,
                    width: 900,
                    hideFooter: true,
                  };
              
                  props.pushModalToStack(modalData);
            }}>
              show {props.btnName}
            </Button>):""
           }

            
          
            <div className="currency-converter-modal">
                <Button onClick={() => updateTemplateDropdown(!templateDropdown)}>
                    <i className="fa fa-exchange" />
                </Button>

                {templateDropdown &&
                    <div ref={innerRef} className="content">
                        <Row className="mb5">
                            <Link onClick={()=>{
                                props.popModalFromStack();
                                props.pushModalToStack({
                                    modalBody: <StandardSalesInvoice {...props} />,
                                    width: '100%',
                                    hideTitle: true,
                                    hideFooter: true,
                                    wrapClassName: 'new-transaction-wrapper'
                                })
                                updateTemplateDropdown(false)
                            }}
                            // to="/admin/sales-invoice"
                            >Standard Invoice</Link>
                        </Row>

                        <Row className="mb5">
                            <Link onClick={()=>{
                                props.popModalFromStack();
                                props.pushModalToStack({
                                    modalBody: <MultiCurrencySalesInvoice {...props} />,
                                    width: '100%',
                                    hideTitle: true,
                                    hideFooter: true,
                                    wrapClassName: 'new-transaction-wrapper'
                                })
                                updateTemplateDropdown(false)
                            }}
                            // to="/admin/sales-invoice-multi-currency"
                            >Multi Currency Invoice</Link>
                        </Row>

                        <Row>
                            <Link onClick={()=>{
                                props.popModalFromStack();
                                props.pushModalToStack({
                                    modalBody: <StudentInvoice {...props} />,
                                    width: '100%',
                                    hideTitle: true,
                                    hideFooter: true,
                                    wrapClassName: 'new-transaction-wrapper'
                                })
                                updateTemplateDropdown(false)
                            }}
                            >Student Invoice</Link>
                        </Row>
                    </div>
                }
            </div>
                
            <Button onClick={() => {
                props.updateDrawerState({
                    settingSalesDrawerVisible: true
                })
            }} >
            <i class="fa fa-cog" aria-hidden="true"></i>
            </Button>
            
                {/* <div className="space-lr">
                    <Button icon={<SwapOutlined />} />
                </div> */}
          
        </div>
    </>
    );
};
const getFormatedNextTxNumber = (props) => {
    // const { customer } = props;
    // if (!customer || !customer.customerId) {
    //     return <span>{props.nextTxNumber}</span>;
    // }
    // return <span>{props.nextTxNumber.replace('INV-00', 'INV-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
    return <span>{props.nextTxNumber}</span>;
}

export default injectIntl(CustomerHeaderAction);
