import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UnpaidInvoicesComponent from '../../../../../components/modal/modalBody/schoolManagement/UnpaidInvoices';
import { getUnpaidInvoicesByFormId,  getUnpaidInvoiceCount, resetPaginatedData } from './action';

class UnpaidInvoices extends Component {

    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            context: props.context,
            customerId: (props.customerData || {}).customerId,
            pageNumber:1
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 0, pageSize: 100,
            formId: this.props.defaultSchoolForm || this.props.studentFormList[0].registrationFormSettingId,
            context: this.state.context,
            customerId: this.state.customerId
        };
        this.props.getUnpaidInvoicesByFormId(payload);
        this.props.getUnpaidInvoiceCount(payload);
    }

    render() {
        return <UnpaidInvoicesComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        studentFormList: state.membershipReducer.studentFormList,
        defaultSchoolForm: state.common.defaultSchoolForm,
        unpaidInvoiceList: state.studentRegistration.unpaidInvoices,
        unpaidInvoiceCount: state.studentRegistration.unpaidInvoiceCount,
        invoiceListByFormId: state.salesInvoice.salesInvoiceList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getUnpaidInvoicesByFormId,  getUnpaidInvoiceCount, resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnpaidInvoices);