import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MembershipOptionDrawer from '../../../../components/drawer/membership/membershipOption';
import { saveMembershipOption, getAllFees } from "./action";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { getMembershipOptionCount, getMembershipOptionListing } from '../../../membership/membershipOption/action'
import { fetchDataIfNeeded } from "../../../../utils";

class MembershipOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.membershipOptionData,
      isActive: 1
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
    };
    fetchDataIfNeeded("getAllFees", "allTermAndFee", this.props, payload);
  }

  render() {
    return <MembershipOptionDrawer
      {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}
    ></MembershipOptionDrawer>
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    allTermAndFee: state.membershipReducer.allTermAndFee,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveMembershipOption,
      getMembershipOptionCount,
      getMembershipOptionListing,
      pushModalToStack,
      popModalFromStack,
      getAllFees
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MembershipOption);
