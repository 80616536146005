import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupplierListingComp from '../../../components/supplier/Listing';
import { fetchSuppliers, getSupplierCount, resetPaginatedData, getTotalSupplierOrdersCount, getTotalSupplierPOCount, getTotalSupplierUnpaidBalance, getTotalPurchase, exportPdf, exportExcel, deleteSuppliers,updateStatus } from './action';
import { popModalFromStack, showModal, hideModal, pushModalToStack, saveUserPrefrences, fetchUserPreferences } from '../../../actions/commonActions';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils'
import { exportLedgerPdf } from '../detail/action';
import { generatePDF } from '../../../actions/downloadFileAction';
import { fetchPaidInvoicesCount } from '../../modal/modalBody/supplier/PaidInvoices/action';
import find from 'lodash.find';

class SupplierListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isColumnFitTable:undefined,
      gridApi: undefined,
      searchedText: '',
      txPreferences: { txType: "supplier", templateName: "listing" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['supplierStoreName', 'contact', 'totalAmountDue', 'openedOrdersCount', 'unpaidInvoicesCount'],
      txAvailableColumn: {
        supplierStoreName: true,
        contact: true,
        totalAmountDue: true,
        openedOrdersCount: true,
        paidInvoicesCount: true,
        paymentTermName: true,
        unpaidInvoicesCount: true
    },
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25
    };
    // fetchPaginationDataIfNeeded('fetchSuppliers', 'suppliers', this.props, payload, true);
    fetchDataIfNeeded('getSupplierCount', 'supplierCount', this.props, payload);
    fetchDataIfNeeded('getTotalSupplierOrdersCount', 'supplierOpenOrderCount', this.props, payload);
    fetchDataIfNeeded('getTotalSupplierPOCount', 'supplierOpenPOCount', this.props, payload);
    fetchDataIfNeeded('getTotalSupplierUnpaidBalance', 'supplierUnpaidBalance', this.props, payload);
    // fetchDataIfNeeded('getTotalPurchase', 'supplierTotalPurchase', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    this.props.fetchPaidInvoicesCount(payload);
    this.props.fetchSuppliers({...payload,hideLoad:true})
  }


  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "supplier", templateName: "listing" });
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let gridPreference = txPreferences.gridPreference
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

    // const totalPaidInvoice = props.
  }

  render() {
    return (
      <SupplierListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }

}


const mapStateToProps = (state) => {
  return {
    suppliers: state.supplier.suppliers,
    supplierCount: state.supplier.supplierCount,
    allPreferences: state.common.allPreferences,
    supplierOpenOrderCount: state.supplier.supplierOpenOrderCount,
    supplierOpenPOCount: state.supplier.supplierOpenPOCount,
    supplierUnpaidBalance: state.supplier.supplierUnpaidBalance,
    supplierPaidInvoiceListCount: state.supplier.paidInvoiceListCount,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions,
    headerLoading: state.common.headerLoading,
    listLoading: state.common.listLoading,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSuppliers,
  getSupplierCount,
  resetPaginatedData,
  getTotalSupplierOrdersCount,
  getTotalSupplierPOCount,
  popModalFromStack,
  showModal,
  hideModal,
  pushModalToStack,
  getTotalSupplierUnpaidBalance,
  getTotalPurchase,
  exportPdf,
  exportExcel,
  saveUserPrefrences,
  fetchUserPreferences,
  deleteSuppliers,
  exportLedgerPdf,
  generatePDF,
  updateStatus,
  fetchPaidInvoicesCount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierListing);
