import React, { Fragment } from "react";
import {Skeleton, Pagination, DatePicker, Empty} from 'antd';
import { CONSTANTS } from '../../../../static/constants';
import { fetchPaginationDataIfNeeded, getMomentDateForUI, getCurrentDateForBackend, getMomentDateForUIReadOnly } from '../../../../utils';
import { injectIntl, FormattedMessage } from 'react-intl';
import SalesInvoiceDetail from '../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import SODetail from '../../../../containers/modal/modalBody/customer/SODetail';
import CustomerPaymentDetails from '../../../../containers/modal/modalBody/customer/PaymentDetail';
// import { AG_GRID_CLASS_CONSTANTS } from "../../../../static/cssClassConstants";
// import { FilterOutlined, UndoOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const openTransactionDetail = (data, props, type) => {
    const txType = type || data.txType;
    switch (txType) {
        case 'Standard Sales Order':
        case 'Sales Order': {
            const soPayload = {
                customerId: data.customerId,
                soMasterId: data.soMasterId
            }
            props.pushModalToStack({
                modalBody: <SODetail {...props} soPayload={soPayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
            break;
        }
        case 'Invoice': {
            const invoicePayload = {
                customerId: data.customerId,
                invoiceMasterId: data.invoiceMasterId
            }
            props.pushModalToStack({
                modalBody: <SalesInvoiceDetail {...props} invoicePayload={invoicePayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
            break;
        }
        case 'Payment': {
            const paymentPayload = {
                customerId: data.customerId,
                customerPaymentId: data.customerPaymentId || (data.customerTxId || {}).customerTxPaymentId
            }
            const modalData = {
                title: <div className='ant-modal-title'>Purchase Payment</div>,
                modalBody: <CustomerPaymentDetails paymentPayload={paymentPayload} />,
                width: 500,
                hideFooter: true
            };
            props.showModal(modalData);
            break;
        }
        default:{
            break;
        }
    }
};


const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = { ...props.payload, pageNumber: pageNumber, pageSize: pageSize };
    props.resetInvoiceTransactionListData();
    props.fetchCustomerInvoiceList(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
}


const loadPageData = (pageNumber, pageSize, props) => {
    const payload = { ...props.payload, pageNumber: pageNumber, pageSize: pageSize };
    fetchPaginationDataIfNeeded('fetchCustomerInvoiceList', 'invoiceList', props, payload);
}

const Invoice = (props) => {
    const { companyInfo } = props;
    const _getInvoiceList = (dataList) =>{
        dataList && dataList.forEach((dt, dtIndex) => {
            if (dt.customerPaymentsList && dt.customerPaymentsList.length)
                dt.children = dt.customerPaymentsList;
                dt.children && dt.children.forEach((dt2)=>{
                    dt2.invoiceNumber = dt2.paymentNumber;
                    dt2.invoiceTotalAmount = dt.invoiceTotalAmount;
                    dt2.paymentTermName = dt.paymentTermName;
                });
        });

        return dataList || [];
    }

    const invoiceList = _getInvoiceList(props.invoiceList[props.pageNumber || 1]);
    const invoiceListCount = props.invoiceListCount;
    // const columnDefs = [
    //     {
    //         title : <FormattedMessage id='customer.detail.transaction.gridHeader.transaction' defaultMessage='' />,
    //         dataIndex: "txType",
    //         key: "txType"
    //     },
    //     {
    //         title : <FormattedMessage id='customer.detail.transaction.gridHeader.invoiceNumber' defaultMessage='' />,
    //         dataIndex: "invoiceNumber",
    //         key: "invoiceNumber",
    //         render: (invoiceNumber, row) => <div>
    //             <div className='cursor-pointer' onClick={() => openTransactionDetail(row, props)}>{invoiceNumber}</div>
    //         </div>
    //     },
    //     // {
    //     //     title : <FormattedMessage id='customer.detail.transaction.gridHeader.customerInvNumber' defaultMessage='' />,
    //     //     dataIndex: "customerInvoiceNumber",
    //     //     key: "customerInvoiceNumber",
    //     //     render: (customerInvoiceNumber, row) => <div>
    //     //         <div className='cursor-pointer' onClick={() => openTransactionDetail(row, props)}>{customerInvoiceNumber}</div>
    //     //     </div>
    //     // },
    //     {
    //         title : <FormattedMessage id='customer.detail.transaction.gridHeader.poNumber' defaultMessage='' />,
    //         dataIndex: "customerPONumber",
    //         key: "customerPONumber",
    //         render: (customerPONumber, row) => <div>
    //             <div className='cursor-pointer' onClick={() => openTransactionDetail(row, props, 'Sales Order')}>{customerPONumber}</div>
    //         </div>
    //     },
    //     {
    //         title : <FormattedMessage id='customer.detail.transaction.gridHeader.amount' defaultMessage='' />,
    //         dataIndex: "invoiceTotalAmount",
    //         key: "invoiceTotalAmount",
    //         className: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         render: (amount, data) =>{
    //             return amount  ? ( <span> {data.foreignCurrencyIcon ? <i className={data.foreignCurrencyIcon}></i> : (data.foreignCurrency ? <span>{data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> ): "-"
    //         }

    //     },
    //     {
    //         title : <FormattedMessage id='customer.detail.transaction.gridHeader.paymentDueDate' defaultMessage='' />,
    //         dataIndex: "invoiceDueDate",
    //         key: "invoiceDueDate",
    //         render: (invoiceDueDate) => <div>
    //             {moment(new Date(invoiceDueDate)).format(format)}
    //         </div>,

    //     },
    //     {
    //         title : <FormattedMessage id='customer.detail.transaction.gridHeader.paymentTerm' defaultMessage='' />,
    //         dataIndex: "paymentTermName",
    //         key: "paymentTermName"
            
    //     },
    //     {
    //         title : <FormattedMessage id='customer.detail.transaction.gridHeader.date' defaultMessage='' />,
    //         dataIndex: "invoiceDate",
    //         key: "invoiceDate",
    //         render: (invoiceDate) => <div>
    //             {moment(new Date(invoiceDate)).format(format)}
    //         </div>,

    //     },
    //     {
    //         title : <FormattedMessage id='common.status' defaultMessage='' />,
    //         dataIndex: "status",
    //         key: "status",
    //         render: (status) => <div>
    //             <span className={["status-code", status.toLowerCase()].join(' ')}>
    //                 <FormattedMessage id={status} defaultMessage={status} />
    //             </span>
    //         </div>,

    //     },
    //     // {
    //     //     title: "",
    //     //     dataIndex: 'action',
    //     //     key: 'action',
    //     //     render: (param, row) => <div>
    //     //         <Dropdown overlay={actionMenu(row, props)} trigger={['click']} overlayClassName="action-listing-dropdown">
    //     //             <Button className="action-btn small">
    //     //                 <i className="fa fa-ellipsis-v" />
    //     //             </Button>
    //     //         </Dropdown>
    //     //     </div>
    //     // }
    // ]


    return (
        <Fragment>
            <div className="view-container">
          
                    <div className="view-container-actions">
                        <div className="left-actions">
                            <div className="table-heading">Invoice List</div>
                                {invoiceListCount ? <>
                                    <div className="vertical-sep" />
                                    <div>{invoiceListCount}</div>
                                </>:''}
                            </div>
                        <div className="right-actions">
                            <RangePicker
                                allowEmpty={true}
                                format={format}
                                placeholder={['Start date', 'End date']}
                                value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                                onChange={(dates) => {
                                    if ((dates || []).length === 2) {
                                        props.updateState({ startDate: dates[0], endDate: dates[1] });

                                        const payload = {
                                            customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 100,
                                            filters: {
                                                startDate: getCurrentDateForBackend(dates[0]),
                                                endDate: getCurrentDateForBackend(dates[1]),
                                            }
                                        };
                                        props.resetInvoiceTransactionListData();
                                        props.fetchCustomerInvoiceList(payload);
                                        props.fetchCustomerInvoiceCount(payload);

                                    } else {
                                        props.updateState({ startDate: null, endDate: null });
                                        const payload = {
                                            customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                            filters: {}
                                        };
                                        props.resetInvoiceTransactionListData();
                                        props.fetchCustomerInvoiceList(payload);
                                        props.fetchCustomerInvoiceCount(payload);
                                    }
                                }}
                            />
                            {/* <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                                if( !props.startDate && !props.endDate ){
                                    return showToasterMessage({ messageType: 'error', description: 'Please select date range' });
                                }
                                const payload = {
                                    customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 100,
                                    filters: {
                                        startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                                        endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                                    }
                                };
                                props.resetInvoiceTransactionListData();
                                props.fetchCustomerInvoiceList(payload);
                                props.fetchCustomerInvoiceCount(payload);
                            }}>
                                {<FilterOutlined />} Apply Filter
                            </button>
                            <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                                props.updateState({
                                    startDate: null, endDate: null,
                                });
                                const payload = {
                                    customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                    filters: {}
                                };
                                props.resetInvoiceTransactionListData();
                                props.fetchCustomerInvoiceList(payload);
                                props.fetchCustomerInvoiceCount(payload);
                                }}>
                                    {<UndoOutlined />} Clear Filter
                            </button> */}

                        </div>
                    </div>
            <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>

                    <div className="table-container table-h-profile">
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th><FormattedMessage id='customer.detail.transaction.gridHeader.transaction' defaultMessage='' /></th>
                                    <th><FormattedMessage id='customer.detail.transaction.gridHeader.invoiceNumber' defaultMessage='' /></th>
                                    <th><FormattedMessage id='customer.detail.transaction.gridHeader.poNumber' defaultMessage='' /></th>
                                    <th><FormattedMessage id='customer.detail.transaction.gridHeader.amount' defaultMessage='' /></th>
                                    <th><FormattedMessage id='customer.detail.transaction.gridHeader.paymentDueDate' defaultMessage='' /></th>
                                    <th><FormattedMessage id='customer.detail.transaction.gridHeader.paymentTerm' defaultMessage='' /></th>
                                    <th><FormattedMessage id='customer.detail.transaction.gridHeader.date' defaultMessage='' /></th>
                                    <th><FormattedMessage id='common.status' defaultMessage='' /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoiceList && invoiceList.length ? invoiceList.map((rowData, i)=>{
                                    return <tr key={'invSumm'+i}>
                                        <td>{i+1}</td>
                                        <td>{rowData.txType ? rowData.txType : <div className="empty-data"></div>}</td>
                                        <td>
                                            {
                                                rowData.invoiceNumber 
                                                ?
                                                <div className='cursor-pointer' onClick={() => openTransactionDetail(rowData, props)}>{rowData.invoiceNumber}</div>
                                                :
                                                <div className="empty-data"></div>
                                            }
                                            
                                        </td>
                                        <td>
                                            <div className='cursor-pointer' onClick={() => openTransactionDetail(rowData, props, 'Sales Order')}>{rowData.customerPONumber}</div>
                                        </td>
                                        <td>
                                            {rowData.invoiceTotalAmount ? 
                                                <span> {rowData.foreignCurrencyIcon ? <i className={rowData.foreignCurrencyIcon} /> : (rowData.foreignCurrency ? <span>{rowData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{rowData.invoiceTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span>
                                            :''
                                            }
                                        </td>
                                        {/* <td>
                                            {rowData.invoiceDueDate ? getMomentDateForUI({date: new Date(rowData.invoiceDueDate)}) : '-'}
                                        </td> */}
                                        <td></td>
                                        <td>{rowData.paymentTermName}</td>
                                        {/* <td>
                                            {rowData.invoiceDate ? getMomentDateForUI({date: new Date(rowData.invoiceDate)}) : '-'}
                                        </td> */}
                                        
                          <td>
                            {rowData.dateCreated
                              ? getMomentDateForUIReadOnly({
                                  date: rowData.dateCreated,
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })
                              : "-"}
                          </td>
                                        <td>
                                            <span className={["status-code", (rowData.status || '').toLowerCase()].join(' ')}>
                                                <FormattedMessage id={rowData.status} defaultMessage={rowData.status} />
                                            </span>
                                        </td>
                                    </tr>
                                })
                                    :
                                    <tr key="empty-data">
                                        <td colSpan={"100%"} style={{borderBottom:"none"}}>
                                        <Empty />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        {/* <Table pagination={false} columns={columnDefs}  dataSource={invoiceList || []} className="customer-transactions-table" /> */}
                    </div>
                </Skeleton>
                <div className="footer">
                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={invoiceListCount}
                            // showTotal={(total) => {
                            //     return props.intl.formatMessage({id: 'pagination.text.total', defaultMessage: ''}) + ` ${ total } `+ props.intl.formatMessage({id: 'pagination.text.items', defaultMessage: ''});
                            // }}
                            defaultPageSize={100}
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize, props);
                                props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber, pageSize, props);
                            }}
                        />
                    </div>
                </div>

            </div>
        </Fragment >
    )
};

export default injectIntl(Invoice);