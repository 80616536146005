import React from "react";
import { FormattedMessage } from 'react-intl';
import PageBreadcrumb from "../../PageBreadcrumb";
import { ICONS } from "../../../static/constants";


const PageHeader = (props) =>{
    const {pageName="", breadCrumbList, canCreate=false} = props;

    return <>      
      
        <div className="view-top-bar">
            <div className="top-view-left">
                <div className="page-heading">
                    {typeof pageName=="string" && pageName.includes(".") ? <FormattedMessage id={pageName} defaultMessage="" /> : pageName}
                </div>
                <div className="vertical-sep" />
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="top-view-right">
                {canCreate ? <button
                    className="create-action-btn"
                    onClick={props.onCreate}
                    >
                        <i className={`${ICONS.ADD} mr5`} />
                        <FormattedMessage id="button.create.label" defaultMessage="" />
                    </button>
                :
                ''}
            </div>
        </div>
    </>
}

export default PageHeader;