import axios from "axios";
import config from "../../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../../utils";
const { lang } = require("../../../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const brodcastTextEmail = (payload, props, state) => {
    let formData = new FormData();
    formData.append('payload', JSON.stringify(payload));
  
    if (state && state.attachments && Array.isArray(state.attachments.fileList) && state.attachments.fileList.length) {
      state.attachments.fileList.forEach(fileObj => {
        const file = new Blob([fileObj.originFileObj], { type: fileObj.type });
        formData.append('files', file, fileObj.name);
      })
    }

    return (dispatch) => {
        return axios
            // .post(
            //     `${config.API_BASE_URL}${config.API_VERSION}alpide-sg/sg/email`,
            //     formData,
            //     { headers: { 'Content-Type': 'multipart/form-data' } }
            //   )
            .post(
                `${config.API_BASE_URL}${config.API_VERSION}alpide-sg/sg/email`,
                payload
            )
             .then((response) => {
                if (props) {
                    props.updateHeaderState({
                        communication: {
                            emailDrawerVisible: false,
                            message: '',
                            previewUrl: '',
                            brodcastSMSType: '',
                        },
                        emailDrawerVisible: false,
                    });
                }
                showToasterMessage({ messageType: 'success', description: 'Message has been sent successfully' });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            });
    };
};







