import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Input } from 'antd';
import { Form } from '@ant-design/compatible';
import { DropdownRef } from '../../../../general/Dropdown';
import {  MODAL_TYPE } from '../../../../../static/constants';
import { CustomButton } from '../../../../general/CustomButton';
import { getLabelName } from '../../../../modal/modalBody/customerInfo';

class EmployeeComponent extends Component {

  addItemConfirmationModal = (props, text, modalType) => {
    const { pushModalToStack, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = <span>
      <FormattedMessage
        id='addItem.confirmation'
        values={{ value: `${text} to ` }}
        defaultMessage=''
      />
      {labelName} ?
    </span>;
  
    let addFunc;
  
    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        addFunc = props.addSalutation;
        break;
      }
      default: {
        addFunc = () => { }
      }
    }
  
    const modalData = {
      modalBody,
      handleSubmit: () => {
         addFunc({text:text, relationshipId: companyInfo.relationshipId}) 
         props.popModalFromStack();
    },
    };
    pushModalToStack(modalData);
  }
  
  getModalTitle = (modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        title = <FormattedMessage id='addItem.text.salutation' defaultMessage='' />;
        break;
      }
      default:
        title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
    }
    return title;
  }
  
   handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { pushModalToStack } = props;
  
    if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
          }
        }
      };
      pushModalToStack(data);
    }
  }
  
   deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody =
      <FormattedMessage
        id='deleteItem.confirmation'
        defaultMessage=''
        values={{ value: payload.text }}
      />;
  
    let deleteFunc;
  
    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        deleteFunc = props.deleteSalutation;
        break;
      }
      default: {
        deleteFunc = () => { }
      }
    }
  
    const modalData = {
      modalBody,
      handleSubmit: () => { deleteFunc({id:payload.id, relationshipId: companyInfo.relationshipId}) },
    };
    showModal(modalData);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content employee-modal-control">
            <Form.Item label={<FormattedMessage id='contact.title' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('titleName', {
                initialValue: this.props.titleName || ''
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='contact.title' defaultMessage='' />} colon={false}>
              {getFieldDecorator('salutationId', {
                initialValue: this.props.salutationId || ''
              })
                (<DropdownRef
                  items={this.props.salutations || []}
                  optionKeyName='salutationId'
                  valueKeyName='titleName'
                  canAddNew={true}
                  canDelete={true}
                  deleteClickHandler={(payload) => {
                    this.deleteClickHandler(this.props, MODAL_TYPE.SALUTATION, payload);
                  }}
                  onAddNewClick={(payload) => {
                    payload.formData = {
                      textBoxValue: payload.textEntered,
                      submittedOnce: false
                    }
                    this.handleNewItemAddition(this.props, payload, MODAL_TYPE.SALUTATION)
                  }}
                  onSelect={(selectedValue, option) => {
                    this.props.form.setFieldsValue({ 'salutationId': selectedValue });
                    this.props.form.setFieldsValue({ 'titleName': option.props.name });
                  }}
                />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='contact.firstName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: 'First Name is required.',
                    initialValue: this.props.firstName || ''
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'contact.firstName.placeholder', defaultMessage: '' } )}  />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='contact.middleName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('middleName', {
                initialValue: this.props.middleName || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'contact.middleName.placeholder', defaultMessage: '' } )} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='contact.lastName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('lastName', {
                initialValue: this.props.lastName || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'contact.lastName.placeholder', defaultMessage: '' } )} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='contact.callName' defaultMessage='' />} colon={false}>
              {getFieldDecorator('preferCallName', {
                initialValue: this.props.preferCallName || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'contact.callName.placeholder', defaultMessage: '' } )} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='contact.workPhone' defaultMessage='' />} colon={false}>
              {getFieldDecorator('workPhone', {
                initialValue: this.props.workPhone || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'contact.mobile.placeholder', defaultMessage: '' } )} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='contact.mobile' defaultMessage='' />} colon={false}>
              {getFieldDecorator('cellPhone', {
                initialValue: this.props.cellPhone || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'contact.mobile.placeholder', defaultMessage: '' } )} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='contact.faxNumber' defaultMessage=''/>} colon={false}>
              {getFieldDecorator('faxNumber', {
                initialValue: this.props.faxNumber || ''
              })
                (<Input placeholder={this.props.intl.formatMessage({ id: 'contact.faxNumber', defaultMessage: '' } )} />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='contact.email' defaultMessage=''/>} colon={false}>
              {getFieldDecorator('emailAddress', {
                initialValue: this.props.emailAddress || ''
              })
                (<Input maxLength={254} placeholder={this.props.intl.formatMessage({ id: 'contact.email', defaultMessage: '' } )} />)}
            </Form.Item>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal() }}
              />
              <CustomButton
                intlId={'modal.contact.button.createContact'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          salutationName: values.salutationName,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          preferCallName: values.preferCallName,
          workPhone: values.workPhone,
          cellPhone: values.cellPhone,
          faxNumber: values.faxNumber,
          emailAddress: values.emailAddress,

          relationshipId: (this.props.companyInfo || {}).relationshipId,
          isPrimaryContact: 0,
          contactType: 'Contact',
          //dateCreated: getCurrentDateForBackend(new Date()),
          userCreated: ''
        }
        const modalData = {
          modalBody: 'Are you sure you want to create Employee ?',
          handleSubmit: () => {
            this.props.createEmployee(payload);
            this.props.hideModal();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Employee_component' })(injectIntl(EmployeeComponent));
