import { PURCHASE_REQUEST_ACTION_LIST } from '../static/constants';
const initialState = {
  purchaseRequestList: {
    1: []
  },
  purchaseRequestCount: 0,
  prDetail: {},
  rfqPrData: {},
  poPrData: {},
  soPrData: {},
  purchaseRequestDraftList: {
    1: []
  },
  purchaseRequestDraftCount: 0,
}

const PurchaseRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_LIST:
      return {
        ...state,
        purchaseRequestList: {
          ...state.purchaseRequestList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_COUNT:
      return { ...state, purchaseRequestCount: action.data };
    case PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_LIST_PAGE_RESET:
      return { ...state, purchaseRequestList: initialState.purchaseRequestList };
    case PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DETAIL:
      return { ...state, prDetail: action.data };
    case PURCHASE_REQUEST_ACTION_LIST.RESET_PURCHASE_REQUEST_DETAIL:
      return { ...state, prDetail: initialState.prDetail };
    case PURCHASE_REQUEST_ACTION_LIST.RFQ_PR_DATA:
      return { ...state, rfqPrData: action.data };
    case PURCHASE_REQUEST_ACTION_LIST.RESET_RFQ_PR_DATA:
      return { ...state, rfqPrData: initialState.rfqPrData };
    case PURCHASE_REQUEST_ACTION_LIST.NEXT_PURCHASE_REQUEST_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case PURCHASE_REQUEST_ACTION_LIST.NEXT_PURCHASE_REQUEST_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case PURCHASE_REQUEST_ACTION_LIST.PO_PR_DATA:
      return { ...state, poPrData: action.data };
    case PURCHASE_REQUEST_ACTION_LIST.RESET_PO_PR_DATA:
      return { ...state, poPrData: initialState.poPrData };
    case PURCHASE_REQUEST_ACTION_LIST.SO_PR_DATA:
      return { ...state, soPrData: action.data };
    case PURCHASE_REQUEST_ACTION_LIST.RESET_SO_PR_DATA:
      return { ...state, soPrData: initialState.soPrData };
    case PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DRAFT_LIST:
      return {
        ...state,
        purchaseRequestDraftList: {
          ...state.purchaseRequestDraftList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DRAFT_COUNT:
      return { ...state, purchaseRequestDraftCount: action.data };
    case PURCHASE_REQUEST_ACTION_LIST.PURCHASE_REQUEST_DRAFT_LIST_PAGE_RESET:
      return { ...state, purchaseRequestDraftList: initialState.purchaseRequestDraftList };
    default:
      return state;
  }
};

export default PurchaseRequestReducer;