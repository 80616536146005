import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssociateTransactionsComponent from '../../../../../../components/modal/modalBody/finance/Banking/Associate';
import { fetchAssociateTransactions, associateDiposit, associateWithdrawal } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../../actions/commonActions';

class AssociateTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ledgerStartDate: null,
            ledgerEndDate: null,
            activeAccount: props.activeAccount
        }
    }
    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};
        // const payload = {
        //   companyInfo,
        //   relationshipId: companyInfo.relationshipId,
        //   userId: (this.props.userInfo || {}).userId,
        //   pageNumber: 1,
        //   pageSize: 5
        // };
        this.props.fetchAssociateTransactions({...this.props.transactionData, pageSize: 100, pageNumber: 0});
    }

    render() {
        return <div>
            <AssociateTransactionsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        unassociateTransactions: state.finance.unassociateTransactions,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAssociateTransactions,
    associateDiposit, 
    associateWithdrawal,
    showModal, 
    hideModal,
    pushModalToStack, 
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssociateTransactions);