import { ECOMMERCE_ACTION_LIST } from '../static/constants';
const initialState = {
    publishedProducts: {
        1: []
    },
    unpublishedProducts: {
        1: []
    },
    clearanceProducts: {
        1: []
    },
    dealOfTheDayProducts: {
        1: []
    },
    newArrivalProducts: {
        1: []
    },
    bestSellingProducts: {
        1: []
    },
    onSaleProducts:{
        1:[]
    },
    publishedProductCount: 0,
    unpublishedProductCount: 0,
    clearanceProductCount: 0,
    dealOfTheDayProductCount: 0,
    newArrivalProductCount: 0,
    bestSellingProductCount: 0,
    onSaleProductCount: 0,
    allCategories:{
        1: []
    },
    publishedCategories: {
        1: []
    },
    unpublishedCategories: {
        1: []
    },
    allCategoryCount: 0,
    publishedCategoryCount: 0,
    unpublishedCategoryCount: 0,
}


const ProductReducer = (state = initialState, action) => {
    switch (action.type) {

        case ECOMMERCE_ACTION_LIST.ALL_CATEGORY_LIST:
            return {
                ...state,
                allCategories: {
                    ...state.allCategories,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case ECOMMERCE_ACTION_LIST.ALL_CATEGORY_COUNT:
            return { ...state, allCategoryCount: action.data };

        case ECOMMERCE_ACTION_LIST.PUBLISHED_PRODUCT_LIST:
            return {
                ...state,
                publishedProducts: {
                    ...state.publishedProducts,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case ECOMMERCE_ACTION_LIST.PUBLISHED_PRODUCT_COUNT:
            return { ...state, publishedProductCount: action.data };
            
        case ECOMMERCE_ACTION_LIST.PUBLISHED_PRODUCT_LIST_PAGE_RESET:
            return { ...state, publishedProducts: initialState.publishedProducts };
        case ECOMMERCE_ACTION_LIST.UNPUBLISHED_PRODUCT_LIST:
            return {
                ...state,
                unpublishedProducts: {
                    ...state.unpublishedProducts,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case ECOMMERCE_ACTION_LIST.UNPUBLISHED_PRODUCT_COUNT:
            return { ...state, unpublishedProductCount: action.data };
        case ECOMMERCE_ACTION_LIST.UNPUBLISHED_PRODUCT_LIST_PAGE_RESET:
            return { ...state, unpublishedProducts: initialState.unpublishedProducts };
        case ECOMMERCE_ACTION_LIST.CLEARANCE_PRODUCT_LIST:
            return {
                ...state,
                clearanceProducts: {
                    ...state.clearanceProducts,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case ECOMMERCE_ACTION_LIST.CLEARANCE_PRODUCT_COUNT:
            return { ...state, clearanceProductCount: action.data };
        case ECOMMERCE_ACTION_LIST.CLEARANCE_PRODUCT_LIST_PAGE_RESET:
            return { ...state, clearanceProducts: initialState.clearanceProducts };
        case ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_LIST:
            return {
                ...state,
                dealOfTheDayProducts: {
                    ...state.dealOfTheDayProducts,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_COUNT:
            return { ...state, dealOfTheDayProductCount: action.data };
        case ECOMMERCE_ACTION_LIST.DEAL_OF_THE_DAY_PRODUCT_LIST_PAGE_RESET:
            return { ...state, dealOfTheDayProducts: initialState.dealOfTheDayProducts };
        case ECOMMERCE_ACTION_LIST.NEW_ARRIVAL_PRODUCT_LIST:
            return {
                ...state,
                newArrivalProducts: {
                    ...state.newArrivalProducts,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case ECOMMERCE_ACTION_LIST.NEW_ARRIVAL_PRODUCT_COUNT:
            return { ...state, newArrivalProductCount: action.data };
        case ECOMMERCE_ACTION_LIST.NEW_ARRIVAL_PRODUCT_LIST_PAGE_RESET:
            return { ...state, newArrivalProducts: initialState.newArrivalProducts };

        case ECOMMERCE_ACTION_LIST.BEST_SELLING_PRODUCT_LIST:
            return {
                ...state,
                bestSellingProducts: {
                    ...state.bestSellingProducts,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case ECOMMERCE_ACTION_LIST.BEST_SELLING_PRODUCT_COUNT:
            return { ...state, bestSellingProductCount: action.data };
        case ECOMMERCE_ACTION_LIST.BEST_SELLING_PRODUCT_LIST_PAGE_RESET:
            return { ...state, bestSellingProducts: initialState.bestSellingProducts };
            case ECOMMERCE_ACTION_LIST.ON_SALE_PRODUCT_LIST:
                return {
                    ...state,
                    onSaleProducts: {
                        ...state.onSaleProducts,
                        [action.data.pageNo || 1]: action.data.list
                    }
                };
            case ECOMMERCE_ACTION_LIST.ON_SALE_PRODUCT_COUNT:
                return { ...state, onSaleProductCount: action.data };
            case ECOMMERCE_ACTION_LIST.ON_SALE_PRODUCT_LIST_PAGE_RESET:
                return { ...state, onSaleProducts: initialState.onSaleProducts };
        case ECOMMERCE_ACTION_LIST.PUBLISHED_CATEGORY_LIST:
            return {
                ...state,
                publishedCategories: {
                    ...state.publishedCategories,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case ECOMMERCE_ACTION_LIST.PUBLISHED_CATEGORY_COUNT:
            return { ...state, publishedCategoryCount: action.data };
        case ECOMMERCE_ACTION_LIST.PUBLISHED_CATEGORY_LIST_PAGE_RESET:
            return { ...state, publishedCategories: initialState.publishedCategories };
            
        case ECOMMERCE_ACTION_LIST.UNPUBLISHED_CATEGORY_LIST:
            return {
                ...state,
                unpublishedCategories: {
                    ...state.unpublishedCategories,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case ECOMMERCE_ACTION_LIST.UNPUBLISHED_CATEGORY_COUNT:
            return { ...state, unpublishedCategoryCount: action.data };
        case ECOMMERCE_ACTION_LIST.UNPUBLISHED_CATEGORY_LIST_PAGE_RESET:
            return { ...state, unpublishedCategories: initialState.unpublishedCategories };
        default:
            return state;
    }
};

export default ProductReducer;