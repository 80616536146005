import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button,  } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS, PAYMENT_SOURCE_BY_ID } from '../../../static/constants';
// import { CustomButton } from '../../general/CustomButton';
import { showToasterMessage, getCurrentDateForBackend, validateEmail } from '../../../utils';
//import * as find from 'lodash.find';
// import moment from 'moment-timezone';
// import { ErrorMsg } from '../../general/ErrorMessage';
// import Reward from '../../../assets/images/rewards.png'
// import ThankYou from '../../../assets/images/thank-you.png'
import CardLogo from '../../../assets/images/card-logos.jpg'
// import ThankPic from '../../../assets/images/thanks.gif'
import config from '../../../config/environmentConfig';
import { LogoComponent } from '../../general/LogoComponent';
// import { Dropdown } from '../../general/Dropdown';
// import * as find from 'lodash.find';
// import loadScript from 'load-script';
// import PciPic from '../../../assets/images/pci-pic.jpg';
// import GiftPic from '../../../assets/images/gift-pic.png'

// const { Option } = Select;
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;
window.CommunicationHandler = {};


// const fixedDecimalNumber = (number) => {
//     return (Number(number || 0)).toFixed(CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
// }


const getAddressInfoFragment = (obj, additionInfo) => {

    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <span className='billing-address'>{obj.streetAddress1}
                </span>
                : ''
        }
        {
            obj.streetAddress2
                ? <span className='billing-address'> {obj.streetAddress2}
                </span>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {/* {
            obj.countryName
                ? <div className='billing-address'>
                    {
                        `${obj.countryName || ''}`
                    }
                </div>
                : ''
        } */}
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}
// const { TabPane } = Tabs;

const proccessPayment = (props) => {
    if (!props.amountToDonate) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'missing.payment.amount' }) });;
    }
    if (!props.projectMasterId && !props.fundraisingCampaignId && !props.selectedCampaign && !props.selectedProject) {
        return showToasterMessage({ description: 'Either category or campaign name is required' });
    }
    if (!props.firstName) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'first.name.required' }) });
    }
    if (!props.email) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'login.email.requiredEmail' }) });
    }
    if (!validateEmail(props.email)) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'commmon.email.invalidEmail' }) });
    }
    if (props.period !== 'oneTimePayment' && !props.paymentStartDate) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'paymentStartDate.validationMessage' }) });
    }
    if (props.phoneNumber && props.phoneNumber.length > 0 && props.phoneNumber.length < 10) {
        return showToasterMessage({
            messageType: 'error', description: props.intl.formatMessage({ id: 'phoneNumber.incorrect.message' })
        });;
    }
    let payload = {
        relationshipId: props.relationshipId,
        alpidePaymentGatewayId: props.paymentGatewayId,
        amount: props.amountToDonate || 0,
        color: "Blue",
        payButtonName: "Donate",
        isCardCodeRequired: 0,
        isShowCreditCard: 1,
        isShowBankAccount: 0,
        isShowCaptcha: 0,
        isShowBillingAddress: 1,
        isBillingAddressRequired: 1,
        isShowEmail: 1,
        isEmailRequired: 1,
        isAddPaymentProfile: 0,
        startDate: getCurrentDateForBackend(props.paymentStartDate || new Date()),
        totalOccurrences: props.neverExpires ? 9999 : props.noOfPayment,
        projectMasterId: props.projectMasterId || (props.selectedProject || {}).projectMasterId,
        ledgerAccountId: props.ledgerAccountId,
        fundraisingCampaignId: props.fundraisingCampaignId || (props.selectedCampaign || {}).fundraisingCampaignId,
        firstName: props.firstName,
        lastName: props.lastName,
        emailAddress: props.email,
        phoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? ('+' + props.companyInfo.countryCallingCode + props.phoneNumber) : props.phoneNumber,
        email: props.email,
        donorPhoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? ('+' + props.companyInfo.countryCallingCode + props.phoneNumber) : props.phoneNumber,
        isAnonymous: props.isAnonymous ? 1 : 0,
        isGiftAidIt: props.isGiftAidIt || 0
    }
    if (props.period === 'weekly') {
        payload.intervalLength = 7;
        //  payload.unit = 'days';
    } else if (props.period === 'monthly') {
        payload.intervalLength = 30;
        // payload.unit = 'months';
    }
    // else if (props.period === 'yearly') {
    //     payload.intervalLength = 12;
    //    // payload.unit = 'months';
    // }
    props.getFormToken(payload, props);
}

function CardConnectPayment(props) {


    // function parseQueryString(str) {
    //     var vars = [];
    //     var arr = str.split('&');
    //     var pair;
    //     for (var i = 0; i < arr.length; i++) {
    //         pair = arr[i].split('=');
    //         vars[pair[0]] = unescape(pair[1]);
    //     }
    //     return vars;
    // }
    window.addEventListener('message', function (event) {
        if (!event.data) return '';
        if(event.data && typeof event.data === 'string'){
            var token = JSON.parse(event.data);
        var mytoken = document.getElementById('mytoken');
        mytoken.value = token.message;
        props.updateState({
           account: token.message,
           expiry: token.expiry,
           validationError: token.validationError,
        })
        }
    }, false);
    // window.CommunicationHandler.onReceiveCommunication = function (argument = {}) {

    //  
    //     var params = parseQueryString(argument.qstr || '')

    //     switch (params['action']) {
    //         case "resizeWindow":
    //            
    //         case "successfulSave":
    //            
    //         case "cancel":
    //            
    //             //window.location.href = "<?php echo $_SESSION['callback_uri']; ?>&status=cancelled";
    //             break;

    //         case "transactResponse":
    //             var transResponse = JSON.parse(params['response']);
    //           

    //             /*
    //             1 -- Approved
    //             2 -- Declined
    //             3 -- Error
    //             4 -- Held for Review
    //             */
    //             var responseCode = transResponse.responseCode;
    //             if (responseCode === '1') {
    //                 props.updateState({
    //                     paymentStatus: 'success',
    //                     transactionId: transResponse.transId,
    //                     paymentReference: transResponse.refId
    //                 })

    //             } else if (responseCode === '3' || responseCode === '2') {
    //          
    //                 props.updateState({
    //                     paymentStatus: 'failed',
    //                     transactionId: transResponse.transId,
    //                     paymentReference: transResponse.refId
    //                 })
    //                 return false;
    //             }
    //             else if (responseCode === '4') {
    //                 props.updateState({
    //                     paymentStatus: 'hold',
    //                     transactionId: transResponse.transId,
    //                     paymentReference: transResponse.refId
    //                 })
    //                 return false;
    //             }

    //             let requestObj = {
    //                 authNetResponseData: params['response'],
    //                 amount: transResponse.totalAmount,
    //                 transactionId: transResponse.transId,
    //                 payment_reference: transResponse.refId,
    //                 isSubscription: props.period === 'oneTimePayment' ? 0 : 1,
    //                 subscriptionStartDate: getCurrentDateForBackend(props.paymentStartDate || new Date()),
    //                 noOfPayments: props.neverExpires ? 9999 : props.noOfPayment,
    //                 paymentSource: PAYMENT_SOURCE_KEY_BY_ID[props.sourceId], // - whatsapp, webDonation, text2donate
    //                 relationshipId: props.relationshipId,
    //                 cardHolderName: ((transResponse.billTo || {}).firstName || '') + ' ' + ((transResponse.billTo || {}).lastName || ''),
    //                 customerId: props.customerId,
    //                 formId: props.formId,
    //                 alpidePaymentGatewayId: props.paymentGatewayId,
    //                 projectMasterId: props.projectMasterId || (props.selectedProject || {}).projectMasterId,
    //                 ledgerAccountId: props.ledgerAccountId,
    //                 fundraisingCampaignId: props.fundraisingCampaignId || (props.selectedCampaign || {}).fundraisingCampaignId,
    //                 pledgeId: props.pledgeId,
    //                 teamMasterId: props.teamMasterId,
    //                 teamMemberId: props.teamMemberId,
    //                 firstName: props.firstName,
    //                 lastName: props.lastName,
    //                 emailAddress: props.email,
    //                 phoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? ('+' + props.companyInfo.countryCallingCode + props.phoneNumber) : props.phoneNumber,
    //                 email: props.email,
    //                 donorPhoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? ('+' + props.companyInfo.countryCallingCode + props.phoneNumber) : props.phoneNumber,
    //                 isAnonymous: props.isAnonymous ? 1 : 0,
    //                 customerId: props.customerId,
    //                 studentRegistrationId: props.studentRegistrationId,
    //                 registrationFormSettingId: props.registrationFormSettingId,
    //                 isGiftAidIt: props.isGiftAidIt || 0
    //             }
    //             if (props.period === 'weekly') {
    //                 requestObj.subscriptionIntervalLength = 7;
    //             } else if (props.period === 'monthly') {
    //                 requestObj.subscriptionIntervalLength = 30;
    //             }

    //             props.paymentSuccess(requestObj, props);
    //             return true;

    //         // $.ajax('<?php echo $phpUrl ?>/payment-success', {
    //         //     type: 'POST',
    //         //     data: { 'amount':amount, 'transactionId':transaction_id, 'payment_reference':payment_reference },
    //         //     success: function (data, status, xhr) {
    //         //         if(data == 'recurring'){
    //         //             window.location.href = '<?php echo $phpUrl ?>/make-subscription-profile-request';
    //         //             return false;
    //         //         }
    //         //         window.location.href = "<?php echo $_SESSION['callback_uri'] ?>&status=success&transactionId="+transaction_id+"&formId=<?php echo $_SESSION['formId'] ?>";
    //         //         return false;
    //         //     },
    //         //     error: function (jqXhr, textStatus, errorMessage) {
    //         //         alert("There is some issue while processing your payment.")
    //         //     }
    //         // });
    //     }
    // }
    return (
        <div className='pay-box-por' >
            <Row gutter={24} style={{ background: props.onlineThemes.bgColor || '#06033A', color: props.onlineThemes.textColor || '#FFFFFF', padding: '5px', marginLeft: '0px', marginRight: '0px', marginBottom: '15px' }} className='ltiuy'>
                <Col span={2} className='litlyu'></Col>
                <Col span={2}>
                    <div className="brand-logo" style={{ marginTop: '10px' }}>
                        {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                    </div>
                </Col>
                <Col span={12} style={{ alignSelf: 'center' }}>
                    <div className='logosizef'> {props.companyInfo.storeName}</div>
                </Col>
                <Col span={8} style={{ alignSelf: 'center' }} className='newaddr'>
                    {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)}
                </Col>
            </Row>

            <div className="header-action-container pay-ment-box">
                <div>

                    <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px' }}>
                        {(props.selectedCampaign || props.selectedProject) ?
                            <h3> <b> You are paying for {props.selectedCampaign ? props.selectedCampaign.campaignName : props.selectedProject.projectName}</b></h3>
                            :
                            <h3> <b> Welcome to {PAYMENT_SOURCE_BY_ID[props.sourceId]} Payment Form</b></h3>}
                    </div>

                    <div className='pay-text'>
                        <div className='payipuyt'>
                            <Row style={{ padding: '15px 10px 5px' }}>
                                <form name="tokenform" id="tokenform">
                                    <iframe id="tokenframe" title=" " name="tokenframe" src={"https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true&cardlabel=Card Number&expirylabel=Expiration Date&cvvlabel=CVV&invalidcreditcardevent=true&invalidcvvevent=true&css=" + encodeURIComponent('.error{color:%20red;}input{width:323px;height:30px; margin:10px 0px 20px; padding:0px 10px;}select{width:158px;height:30px; margin:10px 0px 20px;}label{ font-family: arial; padding:0px 10px;} #cccvvfield{width:123px;}')} scrolling="no" width="400" height="250px" frameBorder="0"></iframe>
                                    <input type="hidden" name="mytoken" id="mytoken" />
                                </form>
                            </Row>
                        </div>
                    </div>
                    <div style={{ borderTop: '1px solid #e5e5e5', marginTop: '0px' }}>


                        {/* <CustomButton
intlId='confirmation.cancel'
defaultMessage=''
type='default'
key={0}
style={{ marginRight: 7 }}
onClick={props.popModalFromStack}
/> */}
                        <Button className='pay-but3'
                            type='primary'
                            htmlType='submit'
                            key={1}
                            // style={{ float: 'right' }}
                            onClick={() => {
                                props.updateState({ submittedOnce: true });

                                if (props.validationError) {
                                    return showToasterMessage({ description: props.validationError });;
                                }
                                if (!props.period) {
                                    return;
                                }
                                if (!props.neverExpires && !props.noOfPayment) {
                                    return showToasterMessage({ description: 'Please enter No. of Payment' });
                                }


                                proccessPayment(props);

                                // props.getAuthNetPaymentUrl(props, props.projectData, props.period === 'oneTimePayment' ? undefined : payload)


                            }}

                        >  <FormattedMessage id='pay.text' />  <span style={{ padding: '0px 0px 0px 8px' }}>  {props.amountToDonate ? '$' + props.amountToDonate : ''}</span>

                        </Button>
                    </div>
                </div>
                <div className='pay-card-pic'>
                    <img src={CardLogo} alt=''></img>
                </div>

            </div>

            <div style={{ clear: 'both' }}></div>
            <div className='pay-fot'>
                <Row gutter={24} className='pay-footer-box'>
                    <Col span={9}><span style={{ float: 'left', paddingTop: '5px' }}>{props.onlineThemes.footerMessage || 'All Rights Reserved - ' + props.companyInfo.storeName} </span> </Col>
                    <Col span={6} style={{ textAlign: 'center', color: '#333' }}>
                        {props.onlineThemes.facebookLink ? <i className="fa fa-facebook" style={{ margin: '0px 4px 0px 5px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.facebookLink) { return '' }
                                let url = props.onlineThemes.facebookLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.instagramLink ? <i className="fa fa-instagram" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.instagramLink) { return '' }
                                let url = props.onlineThemes.instagramLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.youtubeLink ? <i className="fa fa-youtube-play" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.youtubeLink) { return '' }
                                let url = props.onlineThemes.youtubeLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.twitterLink ? <i className="fa fa-twitter" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.twitterLink) { return '' }
                                let url = props.onlineThemes.twitterLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.link ? <i className="fa fa-link" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.link) { return '' }
                                let url = props.onlineThemes.link;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                    </Col>
                    <Col span={9}>

                        <span style={{ float: 'right', paddingTop: '5px' }}>Powered by <a href="http://alifapp.com/" target={'_blank'} >alifapp.com</a></span>
                    </Col>
                </Row>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div className='clearfix'></div>
        </div>
    );

}

export default injectIntl(CardConnectPayment);
