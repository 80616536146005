import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import SignupComponent from '../../components/signup/SignupComponent';
import { signIn } from './action';
import { fetchDataIfNeeded } from '../../utils';
import { fetchCountries, fetchStates, showModal, hideModal } from './../../actions/commonActions';
import { PLAN_IDS } from '../../static/constants';

class Signup extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(props.location.search) || {};
    // let permList = linkProps.permList ? '{'+linkProps.permList+'}' : '{}';
    let permList = linkProps.permList ? linkProps.permList + ",AK,AJ,CK,CL,K,BP,BQ,BS,BT,BU,BY,CG,L,CF": '';
    if(permList && permList.indexOf('AQ') > -1){ permList = permList+ ',AD,CB,X,Y,Z,AA,AB,AC,AE,AF' }
    this.state = {
      plan: linkProps.plan,
      planId: PLAN_IDS[linkProps.plan],
      permList: permList,
      email: null,
      password: null,
      repeatPassword: null,
      company: null,
      country: null,
      phoneNumber: null,
      contact: {
        firstName: null,
        lastName: null,
        workPhone: null
      },
      address: {
        street: null,
        streetCont: null,
        city: null,
        zip: null,
        state: null
      },
      invalidEmailClass: null,
      invalidPasswordClass: null,
      invalidRepeatPasswordClass: null,
      invalidCompanyClass: null,
      passwordMismatch: null,
      submittedOnce: false,
      isSignupCompleted: false,
    }
  }

  componentDidMount() {
   // const linkProps = queryString.parse(this.props.location.search) || {};
    fetchDataIfNeeded('fetchCountries', 'countries', this.props);
  }

  render() {
    return (
      <Fragment>
        <SignupComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    states: state.common.states,
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchCountries,
  fetchStates,
  signIn,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
