import React from "react";
import { Row, Col } from 'antd';
import { getCurrencySymbol, getMomentDateForUIReadOnly, getUserDefinedName } from "../../../../../../utils";


const Info = (props) => {
    const {studentData, companyInfo} = props;
    const calculateAge = (dateOfBirth)=> {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      }
    return(
        <div className='info-details'>

            <Row gutter={[16, 22]}>
                <Col span={12}>
                    <div className='b-text'>Reg. ID</div>
                    <div className='light-txt'>{studentData.orgRegistrationId || '-'}</div>
                </Col>
                <Col span={12}>
                    <div className='b-text'>Admission No.</div>
                    <div className='light-txt'>{studentData.orgRegistrationId ? (`${studentData.orgRegistrationId}-` + studentData.schoolStudentId) : studentData.schoolStudentId}</div>
                </Col>

                <Col span={12}>
                    <div className='b-text'>Session</div>
                    <div className='light-txt'>{ `${props.companyInfo.fyStartDate.split("-")[0]} - ${props.companyInfo.fyEndDate.split("-")[0]}` }
                    </div>
                </Col>
                <Col span={12}>
                    <div className='b-text'>{getUserDefinedName('Course')}</div>
                    <div className='light-txt'>{studentData.courseName || '-'}</div>
                </Col>
                <Col span={12}>
                    <div className='b-text'>{getUserDefinedName('Class')}</div>
                    <div className='light-txt'>{studentData.className || '-'}</div>
                </Col>
                <Col span={12}>
                    <div className='b-text'> Section</div>
                    <div className='light-txt'>{studentData.sectionName || '-'}</div>
                </Col>
                <Col span={12}>
                    <div className='b-text'>Roll No.</div>
                    <div className='light-txt'>{studentData.rollNo || '-'}</div>
                </Col>
                <Col span={12}>
                    <div className='b-text'>Fees</div>
                    <div className='light-txt'>{getCurrencySymbol(companyInfo.currencyCode)} {studentData.courseFee || 0}</div>
                </Col>
                <Col span={12}>
                    <div className='b-text'>Age</div>
                    <div className='light-txt'>{studentData.age ? studentData.age : calculateAge(getMomentDateForUIReadOnly({date: studentData.dateOfBirth}))}</div>
                </Col>
                <Col span={12}>
                    <div className='b-text'>Allergies</div>
                    <div className='light-txt'>{studentData.isAllergy ? studentData.allergies : 'No'}</div>
                </Col>
            
            </Row>

        </div>   
    )
}
export default Info;

