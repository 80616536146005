import React, { Fragment, useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { TextBox } from "../../general/TextBox";


import {  CONSTANTS } from "../../../static/constants";
import InvoiceDetails from "./invoiceDetails";
import "@ant-design/compatible/assets/index.css";
import { checkCodeRanges, getStateCode } from "../../general/stateCode";
import './index.scss'

import {
  Drawer,
  Button,
  Col,
  Row,

  Tabs,
  Switch,

  
 
  DatePicker
} from "antd";
import {
  showToasterMessage,
 
  getMomentDateForUI,
} from "../../../utils";
import SupplierDetails from "./supplierDetails";
import BuyerDetails from "./buyerDetails";
import ShippingDetails from "./shippingDetails";
import DispatchDetails from "./dispatchDetails";
import TransportDetails from "./transportDetails";
import ItemDetails from "./itemDetails";
import ValueDetails from "./valueDetails";
import AdditionalFields from "./additionalFields";
import { Dropdown } from "../../general/Dropdown";
import find from "lodash.find";


const { TabPane } = Tabs;


function SalesEInvoiceDrawer(props) {
  const buyerGSTIN = (props?.buyerGSTIN||{})?.taxIdentificationNumber
  const supplierGSTIN =(props?.supplierGstin||{})?.taxIdentificationNumber
  const userGstin = (props?.userGstin||{}?.taxIdentificationNumber);
  let handleSubmitKey;
  useEffect(()=>{
   
    const val =   (buyerGSTIN || "")?.substring(0, 2) ===
    (supplierGSTIN || "")?.substring(0, 2);
    props.updateState({
      transactionNature: val === true ? 'Intra':'Inter',
    })
    props.updateState({
      placeOfSupply: buyerGSTIN ? (buyerGSTIN||"")?.substring(0, 2) :  getStateCode(props?.salesInvoiceDetail?.placeOfSupply),
     })
    //  props.updateState({
    //   placeOfBusiness: supplierGSTIN ? (supplierGSTIN||"")?.substring(0, 2) : ''
    //  })
     props.updateState({
      supplierStateCode:(props.supplierGstin?.taxIdentificationNumber||'')?.substring(0,2)
     })
     props.updateState({
      buyerStateCode: (props.buyerGSTIN?.taxIdentificationNumber||'')?.substring(0,2)
     })
    
  },[supplierGSTIN ,buyerGSTIN,userGstin])
    const closeDrawer = () => {
        props.updateDrawerState({
            salesE_InvoiceDrawerVisisble: false
        })
      }
      const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    
      
     const checkHandleSubmit = ()=>{
      if(props.optionalFields||props.additionalFields){
        handleSubmitKey = "13"
      }
     
      else{
        handleSubmitKey = "8"
      }
      return handleSubmitKey;
     }

      const validateTab1 = (props) => {
        let isOK = true
         if(props.userGstin?.taxIdentificationNumber && props.supplyType && props.transactionNature && props.documentType && props.transactionMode && props.documentNumber && props.documentDate && props.taxScheme){
          isOK = false
         }
        return isOK;
      };
      const validateTab2 = (props) => {

       
        let isOK = true
        let valid;
        // if(props.isSameSateCode){
        //   if((supplierGSTIN||"").slice(0,2) === (buyerGSTIN||"")){
        //     valid = true;
        //   }
        //   else{
        //     valid = false;
        //   }
        // }
        // else{
        //   if((supplierGSTIN||"").slice(0,2) === (buyerGSTIN||"")){
        //     valid = false;
        //   }
        //   else{
        //     valid = true;
        //   }
        // }
        // const checkSateCode = props.isSameSateCode && ((props.buyerGSTIN||"").slice(0,2) === (props.supplierGSTIN||""))
         if(props.buyerGSTIN?.taxIdentificationNumber && props.buyerName && props.buyerAddress && props.buyerLocation && props.buyerStateCode && props.buyerPincode &&checkCodeRanges(props.buyerGSTIN?.taxIdentificationNumber ,props.buyerPincode, false)){
          isOK = false
         }
        return isOK;
      };
      const validateTab3 = (props) => {
     
        let isOK = true
         if(props.supplierGstin?.taxIdentificationNumber && props.supplierName && props.supplierAddress && props.supplierLocation && props.supplierStateCode && props.supplierPincode && checkCodeRanges(props.supplierGstin?.taxIdentificationNumber ,props.supplierPincode ,false)){
          isOK = false
         }
        return isOK;
      };
      const validateTab4 = (props) => {
      
        let isOK = true
         if(props.finalInvoiceValue && props.totalTaxableValue){
          isOK = false
         }
        return isOK;
      }
      const validateTab5 = (props) => {
        let isOK = true;
         const hasInvalidHSNCode  = (props?.salesInvoiceDetail?.customerInvoiceDetailsList || [])?.some((item ,i)=>{
            if((item?.hsnCode && (item?.hsnCode?.length === 4 || item?.hsnCode?.length === 8 || item?.hsnCode?.length === 6))){
              return false
            }
            else{
              return true
            }
        })
       
        if (hasInvalidHSNCode) {
          isOK = false;
      } else {
          isOK = true;
      }
        return isOK;
      }
      const validateTab = (props) => {
        let errorCode = "";
        switch (props.activeTabKey) {
    
          case "1":
            errorCode = validateTab1(props);
           
            if (errorCode) {
              props.updateState({
                submittedOnce: true,
              });
              return errorCode;
            } 
            break;
          case "2":
            errorCode = validateTab3(props);
          
            if (errorCode){
              props.updateState({
                submittedOnce: true,
              });
              return errorCode;
            }
             
           
            break;
            case "4":
              errorCode = validateTab5(props);
              if (!errorCode){
               showToasterMessage({description: 'Some Items have Invalid HSN Code and Unit',  messageType: 'error' });
                return !errorCode;
              }
                
             
              break;
         case "5" :
        
          errorCode = validateTab4(props);
          if (errorCode){
            props.updateState({
              submittedOnce: true,
            });
            return errorCode;
          }
           
          
          break;
            case "3":
            errorCode = validateTab2(props);
        
            if (errorCode){
              props.updateState({
                submittedOnce: true,
              });
              return errorCode;
            }
              
           
            break;
         
          default:
            break;
        }
      };
      const getCreateConfirmationModalBody = (payload) => {
        return (
          <Fragment>
            <p>
              <FormattedMessage
                id="Are you sure you want to create an e-invoice?"
                defaultMessage=""
              />
            </p>
          </Fragment>
        );
      };
      const handleSubmit = (payload)=>{
        if (!payload.submittedOnce) {
            payload.updateState({ submittedOnce: true });
          }
          
          if (!validateTab1(payload)&& !validateTab2(payload)&& !validateTab3(payload) && !validateTab4(payload) && validateTab5(payload)) {
            const modalData = {
              title: (
                <FormattedMessage
                  id="Confirmation"
                  defaultMessage=""
                />
              ),
              modalBody: getCreateConfirmationModalBody(payload),
              handleSubmit: () => {
                props.generateEInvoiceDocument(payload)
                payload.hideModal();
              },
            };
            payload.showModal(modalData);
          } else {
            if(validateTab1(payload)){
              payload.updateState({
                activeTabKey: `${1}`,
                // invalidProductNameClass: !payload.productName ? "input-text-error" : "",
              });
            }
            else if(validateTab3(payload)){
              payload.updateState({
                activeTabKey: `${2}`,
                // invalidProductNameClass: !payload.productName ? "input-text-error" : "",
              });
            }
            else if(validateTab2(payload)){
              payload.updateState({
                activeTabKey: `${3}`,
                // invalidProductNameClass: !payload.productName ? "input-text-error" : "",
              });
            }
            else if(!validateTab5(payload)){
              payload.updateState({
                activeTabKey: `${4}`,
                // invalidProductNameClass: !payload.productName ? "input-text-error" : "",
              });
            }
            else if(validateTab4(payload)){
              payload.updateState({
                activeTabKey: `${5}`,
                // invalidProductNameClass: !payload.productName ? "input-text-error" : "",
              });
            }
           
          }
        
        
      }
      const invoiceType = [
        {
          invType: "Regular B2B Invoices of registered counterparty",
          referCode: "B2B",
        },
        {
          invType:
            "Regular B2CL invoices (Interstate supplies to unregistered dealers or consumers>2.5 lakhs)",
          referCode: "B2CL",
        },
        {
          invType: "Other than B2CL (unregistered counterparty and consumers)",
          referCode: "B2CS",
        },
        {
          invType: "SEZ exports without payment",
          referCode: "SEWOP",
        },
        {
          invType: "SEZ Exports with payment",
          referCode: "SEWP",
        },
        {
          invType: "Deemed Exports",
          referCode: "DE",
        },
        {
          invType: "Export with payment of duty",
          referCode: "EXWP",
        },
        {
          invType: "Export without payment of duty",
          referCode: "EXWOP",
        },
        {
          invType: "Import of Goods",
          referCode: "IMG",
        },
        {
          invType: "Others",
          referCode: "OTH",
        },
      ];
    
     
      return (
        <Fragment>
           
          <Drawer
             title={
                
              <FormattedMessage id='modal.txDetails.common.eInvoice' defaultMessage='' /> 
                
              }
              width={920}
              destroyOnClose={true}
              onClose={closeDrawer}
              keyboard={false}
              maskClosable={false}
              className="custom-drawer"
              afterVisibleChange={() => {
                // props.initCalls();
              }}
              visible={props.salesE_InvoiceDrawerVisisble}
              footer={
                <>
        
        
        
                  {props.activeTabKey === "1" ? (
                    <Button
        
                      onClick={() => {
                        const modalData = {
                          modalBody: props.intl.formatMessage({
                            id: "close.confirmation",
                          }),
                          footer: {
                            cancel: { intlId: "no" },
                            submit: { intlId: "yes" },
                          },
                          handleSubmit: () => {
                            // props.popModalFromStack();
                            closeDrawer();
                          },
                        };
                        props.pushModalToStack(modalData);
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
        
                      onClick={() => {
        
                        props.updateState({ activeTabKey: `${Number(props.activeTabKey || 1) - 1}` });
                      }}
                    >
                      Back
                    </Button>
                  )}
        
                  {props.activeTabKey === checkHandleSubmit() ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        handleSubmit(props);
                      }}
                    >
                      <FormattedMessage
                        id="button.save.label"
                        defaultMessage=""
                      />
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        const val = validateTab(props)
                        // if(!val){
                          props.updateState({ activeTabKey: `${Number(props.activeTabKey || 1) + 1}` });
                        // }
                       
                    
                       
                      }}
                    >
                      Next
                    </Button>
                  )}
        
        
        
                </>
              }
          >
           {/* <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active> */}

            <Row>
    <Col span={12}>
    <span className="mr20">
        Optional Fields
      </span>
    <Switch
                          checked={
                          props.optionalFields
                          }
                          onClick={(checked, e) => {
                          
                            props.updateState({
                              optionalFields : checked
                            })
                          }}
                        />
    
                      
                      </Col>

                      <Col span={12}>
                      <span className="mr20">
      Conditional Required
      </span>
    <Switch
                          checked={
                          props.additionalFields
                          }
                          onClick={(checked, e) => {
                          
                            props.updateState({
                              additionalFields : checked
                            })
                          }}
                        />
      
                      
                      </Col>
            </Row>
        
          
            <Tabs type="line"
        activeKey={props.activeTabKey || "1"}

        onChange={(key) => {
         
          props.updateState({ activeTabKey: key });
        }}
      >
         <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="drawer.e_invoice.section.invoice"
            defaultMessage=""
          />}
          key="1"
        >
         <InvoiceDetails {...props}/>
         
        </TabPane>
        <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="drawer.e_invoice.section.supplier"
            defaultMessage=""
          />}
          key="2"
        >
          {/* <div className="mt20"> */}
       <SupplierDetails {...props}/>
        </TabPane>
        <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="drawer.e_invoice.section.buyer"
            defaultMessage=""
          />}
          key="3"
        >
          <BuyerDetails {...props}/>
         
        </TabPane>
        <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="drawer.e_invoice.section.item"
            defaultMessage=""
          />}
          key="4"
        >
          {/* <div className="mt20"> */}
          <ItemDetails {...props}/>
         
        
        </TabPane>

        <TabPane 
        tab={<FormattedMessage
          id="Value Details"
          defaultMessage=""
        />}
        key="5"
        >
         <ValueDetails {...props}/>
        </TabPane>
        <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="Ship To Details"
            defaultMessage=""
          />}
          key="6"
        >
        <ShippingDetails {...props}/>
        </TabPane>
        <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="Dispatch from Details"
            defaultMessage=""
          />}
          key="7"
        >
         <DispatchDetails {...props}/>
        </TabPane>
        <TabPane
          // style={{ paddingBottom: "20px" }}
          tab={<FormattedMessage
            id="Transport Details"
            defaultMessage=""
          />}
          key="8"
        >
          <TransportDetails {...props}/>
        </TabPane>
      

     




{
  props.optionalFields ? ( 
 
   <TabPane
    // style={{ paddingBottom: "20px" }}
    tab={<FormattedMessage
      id="Export Details"
      defaultMessage=""
    />}
    key="9"
  >
   <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Shipping Bill No."
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Shipping Bill Date"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Shipping Bill No.'}
type="text"
value={props.shippingBillNo}
onChange={(e) => {
props.updateState({
  shippingBillNo: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<DatePicker
                    getPopupContainer={(trigger) => trigger.parentNode}
                    format={format}
                    key={`${props.shippingBillDate
                      ? getMomentDateForUI({
                        date: new Date(props.shippingBillDate),
                        format,
                      })
                      : null
                      }`}
                    defaultValue={
                      props.shippingBillDate
                        ? getMomentDateForUI({
                          date: new Date(props.shippingBillDate),
                          format,
                        })
                        : null
                    }
                    onChange={(selectedDate) => {
                      props.updateState({
                        shippingBillDate: selectedDate,
                      });
                    }}
                    placeholder={'Select Bill Date'}
                  />

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Port Code"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Country"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Port Code'}
type="text"
value={props.portCode}
onChange={(e) => {
props.updateState({
  portCode: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Country'}
type="text"
maxLength={20}
value={props.country}
onChange={(e) => {
props.updateState({
  country: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Foreign Currency"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Total Invoice value in Foreign Currency"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Foreign Currency'}
type="text"
value={props.foreignCurrency}
onChange={(e) => {
props.updateState({
  foreignCurrency: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Invoice value in Foreign Currency'}
type="text"
maxLength={20}
value={props.totalInvoiceValue}
onChange={(e) => {
props.updateState({
  totalInvoiceValue: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Supplier Claiming Refund"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Export Duty"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Supplier Refund'}
type="text"
value={props.supplierrefund}
onChange={(e) => {
props.updateState({
  supplierrefund: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Export Duty'}
type="text"
maxLength={20}
value={props.exportDuty}
onChange={(e) => {
props.updateState({
  exportDuty: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
  </TabPane>

 

 


):''
}
{
  props.optionalFields ? ( 
  
   <TabPane
    // style={{ paddingBottom: "20px" }}
    tab={<FormattedMessage
      id="Additional Document Details"
      defaultMessage=""
    />}
    key="10"
  >
   
   <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Supporting Document URL"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Supporting Document"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Document URL'}
type="text"
value={props.documentURL}
onChange={(e) => {
props.updateState({
  documentURL: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Supporting Document'}
type="text"
maxLength={20}
value={props.supportingDocs}
onChange={(e) => {
props.updateState({
  supportingDocs: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Any Additional Info"
    defaultMessage=""
  />
  

</Col>


</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Additional Info'}
type="text"
value={props.additionalInfo}
onChange={(e) => {
props.updateState({
  additionalInfo: e.target.value,
});
}}
/>

</Col>


</Row>
           </div>
  </TabPane>
  
 

 

 


):''
}
{
  props.optionalFields ? ( 
  
   <TabPane
    // style={{ paddingBottom: "20px" }}
    tab={<FormattedMessage
      id="Payment Details"
      defaultMessage=""
    />}
    key="11"
  >
   
   <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Payee Name"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Bank Account Number of Payee"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Payee Name'}
type="text"
value={props.payeeName}
onChange={(e) => {
props.updateState({
  payeeName: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Account Number'}
type="text"
maxLength={20}
value={props.bankAccount}
onChange={(e) => {
props.updateState({
  bankAccount: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Mode of Payment"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Financial Institution Branch (IFSC Code)"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Payment Mode'}
type="text"
value={props.modeofPayemnt}
onChange={(e) => {
props.updateState({
  modeofPayemnt: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'IFSC Code'}
type="text"
maxLength={20}
value={props.ifscCode}
onChange={(e) => {
props.updateState({
  ifscCode: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Terms of Payment"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Payemnt Instruction"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Terms of Payment'}
type="text"
value={props.paymentTerms}
onChange={(e) => {
props.updateState({
  paymentTerms: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Payment Instruction'}
type="text"
maxLength={20}
value={props.paymentInstruction}
onChange={(e) => {
props.updateState({
  paymentInstruction: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Credit Transfer"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Direct Debit"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Credit Transfer'}
type="text"
value={props.creditTransfer}
onChange={(e) => {
props.updateState({
  creditTransfer: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Direct Debit'}
type="text"
maxLength={20}
value={props.directDebit}
onChange={(e) => {
props.updateState({
  directDebit: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Credit Days"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Balance Amount to be Paid"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Credit Days'}
type="text"
value={props.creditDays}
onChange={(e) => {
props.updateState({
  creditDays: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Balance Amount'}
type="text"
maxLength={20}
value={props.balanceAmount}
onChange={(e) => {
props.updateState({
  balanceAmount: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Paid Amount"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Due Date of Payment"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Paid Amount'}
type="text"
value={props.paidAmount}
onChange={(e) => {
props.updateState({
  paidAmount: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<DatePicker
                    getPopupContainer={(trigger) => trigger.parentNode}
                    format={format}
                    key={`${props.dueDatePayment
                      ? getMomentDateForUI({
                        date: new Date(props.dueDatePayment),
                        format,
                      })
                      : null
                      }`}
                    defaultValue={
                      props.dueDatePayment
                        ? getMomentDateForUI({
                          date: new Date(props.dueDatePayment),
                          format,
                        })
                        : null
                    }
                    onChange={(selectedDate) => {
                      props.updateState({
                        dueDatePayment: selectedDate,
                      });
                    }}
                    placeholder={'Select Due Date of Payment'}
                  />

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Payee UPI ID(VPA)"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Merchant Code"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'UPI Id'}
type="text"
value={props.upiId}
onChange={(e) => {
props.updateState({
  upiId: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Merchant Code'}
type="text"
maxLength={20}
value={props.merchantCode}
onChange={(e) => {
props.updateState({
  merchantCode: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
  </TabPane>

 

 


):''
}
{
  props.optionalFields ? ( 
    <TabPane
    // style={{ paddingBottom: "20px" }}
    tab={<FormattedMessage
      id="ANX-1 Extra Fields"
      defaultMessage=""
    />}
    key="12"
  >
   
   <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Original Month"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Original Document Type"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Original Month'}
type="text"
value={props.originalMonth}
onChange={(e) => {
props.updateState({
  originalMonth: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Original Document Type'}
type="text"
maxLength={20}
value={props.originalDocumentType}
onChange={(e) => {
props.updateState({
  originalDocumentType: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Original Invoice Type"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Original GSTIN/UIN Number"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<Dropdown
                // style={{ width: "210px" }}
                items={invoiceType || []}
                valueKeyName="invType"
                optionKeyName="referCode"
                value={(props.originalInvoiceType || {}).invType}
                placeholder={"Invoice Type"}
                onSelect={(referCode) => {
                  const doc = find(invoiceType, { referCode: referCode }) || {};
                  props.updateState({
                    originalInvoiceType: doc,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
<TextBox
maxLength={20}
placeholder={'Invoice Type'}
type="text"
value={props.originalInvoiceType}
onChange={(e) => {
props.updateState({
  originalInvoiceType: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'GST/UIN Number'}
type="text"
maxLength={20}
value={props.originalUINNumber}
onChange={(e) => {
props.updateState({
  originalUINNumber: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Flag indicating whether document to be auto-populated to refunds"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Supply to DTA on BOE"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Populate Refunds'}
type="text"
value={props.autoPopulatedRefunds}
onChange={(e) => {
props.updateState({
  autoPopulatedRefunds: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Supply to DTA'}
type="text"
maxLength={20}
value={props.dtaOnBOE}
onChange={(e) => {
props.updateState({
  dtaOnBOE: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
  </TabPane>
 


):''
}
{
  (props.additionalFields|| props.optionalFields) ?  (
    <TabPane
    // style={{ paddingBottom: "20px" }}
    tab={<FormattedMessage
      id="Additional Fields"
      defaultMessage=""
    />}
    key="13"
  >
   <AdditionalFields {...props}/>

  </TabPane>):''
}
            </Tabs>
       
           {/* </Skeleton> */}
         
    
          </Drawer>
          
        </Fragment>
      );
}

export default injectIntl(SalesEInvoiceDrawer);