import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  getMomentDateForUIReadOnly,
  sortTable,
} from "../../../utils";
import {Menu ,Button,Switch} from "antd";
import DropdownAction from "antd/lib/dropdown";
import { ICONS } from "../../../static/constants";
// import LeadStageDrawer from "../../drawer/leads/leadStageList";
// import { checkACLPermission, getMomentDateForUIReadOnly } from '../../../../utils';
// import { checkACLPermission } from "../../../utils";
// import { MODULE_CODED_VALUES,PERMISSION_VALUES } from "../../../static/constants";
const LeadStageListComp = (props) => {
  const {
    dir=0,
  } = props;
  // const { permissions } = this.props;

  // const primaryPerm = (permissions && permissions.primary) || [];

  // const breadCrumbList = [
  //   {
  //     name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
  //   },
  //   {
  //     name: <FormattedMessage id="heading.lead.leadForm" defaultMessage="" />,
  //   },
  //   {
  //     name: <FormattedMessage id="sidebar.menuItems.FormMeta" defaultMessage="" />,
  //   },
  //   {
  //     name: <FormattedMessage id="lead.leadStage.label" defaultMessage="" />,
  //   }
  // ];

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("leadStage-table"), cellIndex, 1 - dir);
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item key="1" value="edit" onClick={() => {
          props.updateState({
            leadStageListDrawer: true,
            leadStageItem : item,
          })
        }}>
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>

        <Menu.Item key="2" value="delete" onClick={() => {
          props.showModal({
            modalBody: "Are you sure you want to delete?",
            handleSubmit: () => {
              props.deleteCrmLeadStage(item, props);
            }
          })
        }}>
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item>
      </Menu>
    );
  };


  return (
    <>
      {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="heading.lead.leadForm"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button 
            className="create-action-btn" 
            onClick={() => {
              props.updateState({
                leadStageListDrawer: true,
              })
            }}
          >
            <i className={ICONS.PLUS} /> <FormattedMessage id="button.create.label" defaultMessage="" />
          </button>
        </div>
      </div> */}

      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="leads.leadForm.formMeta.leadStage.table.heading"
                defaultMessage=""
              />
            </div>
          </div>

          <div className="right-actions">
          {/* {checkACLPermission(
                  primaryPerm,
                  MODULE_CODED_VALUES.CUSTOMER_ACTIVITY,
                  PERMISSION_VALUES.CREATE
                ) ?   */}
                  <Button
                    style={{backgroundColor:"#1cb961", color: '#ffffff'}}
                    className="create-action-btn"
                    onClick={() => {
                      // handleAddUpdateActivity({});
                      props.updateState({leadStageListDrawer: true, leadStageItem: {}})
                    }}
                  >
                    <i className={ICONS.ADD} style={{margin: '4px 5px 0px 0px'}} /> <FormattedMessage id="button.create.label" defaultMessage="" />
                  </Button>
                  {/* :''
                } */}
          </div>
        </div>
        <div className="table-container" style={{height: 'calc(100% - 59px)'}}>
          <table id="leadStage-table">
            <thead>
              <tr>
                <th>
                  S.No.
                </th>
                <th onClick={sortColumn}>
                  Stage Name <i className={ICONS.SORT}></i>
                </th>
                {/* <th onClick={sortColumn}>
                  Source Code <i className={ICONS.SORT}></i>
                </th> */}
                <th onClick={sortColumn}>
                  Date Created <i className={ICONS.SORT}></i>
                </th>
                <th onClick={sortColumn}>
                    Status <i className={ICONS.SORT}></i>
                  </th>
                <th width= '6%'>
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {(props.crmLeadStageList || []).map((rowData, i) => {
                return (
                  <tr key={"crm" + rowData.crmleadStatusId}>
                    <td>{i+1}</td>
                    <td>{rowData.statusName || "-"}</td>
                    {/* <td>{e.source_code || "-"}</td> */}
                    <td>
                    {rowData.dateCreated
                          ? getMomentDateForUIReadOnly({
                              date: rowData.dateCreated,
                            })
                          : "-"}
                      </td>
                    <td>
                        <div className="status-toggle">
                          <Switch
                            checked={rowData.isActive === 1}
                            onClick={(checked, e) => {
                              props.createLeadStage(
                                { ...rowData, isActive: checked ? 1 : 0 },
                                props
                              );
                            }}
                          />
                        </div>
                      </td>
                    <td style={{paddingLeft: '50px'}}>
                      <DropdownAction
                        overlay={actionMenu(rowData)}
                        trigger={["click"]}
                      >
                        <i className={ICONS.MORE} />
                      </DropdownAction>
                    </td>
                  </tr>
                );
                // })
              })}
            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};

export default injectIntl(LeadStageListComp);
