import React from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "antd";
// import { MultipleSelectDropdown } from '../../../../general/MultipleSelectDropdown';
import { Dropdown } from "../../../../general/Dropdown";
import "./index.scss";
import { Button } from "antd";
// import * as filter from 'lodash.filter';
import { showToasterMessage, getUserDefinedName } from "../../../../../utils";
import { ICONS } from "../../../../../static/constants";

const AssignClassToTeacher = (props) => {
  const {
    teacherDetail = {},
    classList,
    pageNumber,
    assignClasses = [...teacherDetail?.instructorClassRefList],
  } = props;
  const dupClassList = classList[pageNumber || 1];
  // const getSectionsFromClass = (classId) =>{
  //     const sections = classList[pageNumber || 1].find(obj => obj.classId === classId).classSectionList;
  //     props.updateState({classSectionList: sections})
  // }

  const deleteAssignedClass = (idx) => {
    if (idx >= 0 && idx < assignClasses.length) {
      const tempList = assignClasses;
      tempList.splice(idx, 1);
      props.updateState({ assignClasses: tempList });
    } 
  };

  return (
    <>
      <div className="table-container" style={{ height: "500px" }}>
        <table>
          <thead>
            <tr>
              <th width="6%">S.No.</th>
              <th width="44%">{getUserDefinedName("Class", props)}</th>
              <th width="44%">{getUserDefinedName('Section', props)}</th>
              <th width="6%">
                Action{" "}
                {assignClasses.length === 0 ? (
                  <i
                    className={`${ICONS.PLUS} pointer`}
                    onClick={() =>
                      props.updateState({
                        assignClasses: [...assignClasses, {}],
                      })
                    }
                  />
                ) : (
                  ""
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {(assignClasses || []).length ? (
              (assignClasses || []).map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <Dropdown
                        // items={
                        //   dupClassList && dupClassList.length
                        //     ? dupClassList.filter(
                        //         (obj1) =>
                        //           !assignClasses.some(
                        //             (obj2) =>
                        //               obj1.classId === obj2.classId &&
                        //               obj1.classId !== item.classId
                        //           )
                        //       )
                        //     : []
                        // }
                        items={dupClassList}
                        placeholder={getUserDefinedName("Select Class", props)}
                        optionKeyName="classId"
                        valueKeyName="className"
                        valueKeyName2="courseName"
                        className={
                          props.submitted && !item.classId ? "select-error" : ""
                        }
                        value={item.classId}
                        onSelect={(selectedValue) => {
                          const temp = assignClasses;
                          temp[i].classId = selectedValue;
                          // scenario if section is only one, auto select section
                          const sections =
                            (dupClassList || []).find(
                              (obj) => obj.classId === selectedValue
                            )?.classSectionList || [];

                          if (sections.length === 1) {
                            temp[i].classSectionId = sections[0].classSectionId;
                            temp[i].sectionName = sections[0].sectionName;
                          } else {
                            temp[i].classSectionId = 0;
                            temp[i].sectionName = "";
                          }
                          props.updateState({ assignClasses: temp });
                        }}
                      />
                    </td>
                    <td>
                      {!(item.className === item.sectionName) ? 
                        <Dropdown
                          items={
                            (dupClassList || []).find(
                              (obj) => obj.classId === item.classId
                            )?.classSectionList || []
                          }
                          placeholder={"Select Section"}
                          optionKeyName="classSectionId"
                          valueKeyName="sectionName"
                          value={item.sectionName}
                          className={
                            props.submitted && !item.classSectionId
                              ? "select-error"
                              : ""
                          }
                          onSelect={(selectedValue) => {
                            const temp = assignClasses;
                            temp[i].classSectionId = selectedValue;
                            props.updateState({ assignClasses: temp });
                          }}
                        />
                        :
                        '-'
                      }
                    </td>
                    <td>
                      <i
                        className={ICONS.DELETE_P}
                        onClick={() => deleteAssignedClass(i)}
                      />
                      {i + 1 === assignClasses.length ? (
                        <i
                          className={`${ICONS.ADD_P} ml10`}
                          onClick={() =>
                            props.updateState({
                              assignClasses: [...assignClasses, {}],
                            })
                          }
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div>
                No class assigned.{" "}
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    props.updateState({ assignClasses: [...assignClasses, {}] })
                  }
                >
                  Assign Now
                </span>
              </div>
            )}
          </tbody>
        </table>
      </div>
      <hr className="mt10 mb10"></hr>
      <Row justify="space-between">
        <Button
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={() => {
            for (const item of assignClasses) {
              if (
                !item.classId || !item.classSectionId
              ) {
                props.updateState({ submitted: true });
                return showToasterMessage({
                  messageType: "error",
                  description: getUserDefinedName(
                    "assign.classSection.validation.text",
                    props
                  )
                });
              }
            }

            const modalData = {
              modalBody: "Are you sure you want to submit?",
              handleSubmit: () => {
                props.saveOrUpdateInstructorClassRef(
                  {
                    relationshipId: teacherDetail.relationshipId,
                    relationshipEmployeeId: teacherDetail.relationshipEmployeeId,
                    assignedClassList: assignClasses.map(obj=>({ instructorClassRefId: obj.instructorClassRefId, classId: obj.classId, classSectionId: obj.classSectionId })),
                    createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                    formId: 0,
                    classId: 0,
                    classSectionId: 0,
                  },
                  props
                );
                props.popModalFromStack();
              },
            };
            props.pushModalToStack(modalData);
          }}
        >
          <FormattedMessage id="button.submit.label" defaultMessage="" />
        </Button>
      </Row>
    </>
  );
};

export default AssignClassToTeacher;
