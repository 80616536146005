import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SalesEnquiryListingComponent from "../../../../components/customer/salesEnquiry/Listing";
import SalesEnquiryFilterComponent from "../../../../components/customer/salesEnquiry/Listing/salesInquiryFilter";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import {
  showModal,
  pushModalToStack,
  hideModal,
  popModalFromStack,
  fetchUserPreferences,
  saveUserPrefrences,
  deleteAuditTrail,
  createAuditTrail,
  fetchProjects,
  fetchAllEmployees,
  resetCustomerDetail,
} from "../../../../actions/commonActions";
import {
  fetchSalesEnquiry,
  fetchSalesEnquiryCount,
  resetPaginatedData,
  fetchSalesInquiryDrafts,
  getSalesInquiryDraftCount,
  resetDraftPaginatedData,
  deleteSalesEnquiry,
  updateUserStatus,
  fetchSalesEnquiryByFilter,
  getSalesEnquiryCountByFilter,
  updateStatus
} from "./action";
import EmailComponent from "../../../../containers/drawer/email";
import * as find from "lodash.find";
import AuditTimeLine from "../../../../components/drawer/AuditTimeLine";
import { fetchCustomers } from "../../Listing/action";
import { fetchAllStatus } from "../../../modal/modalBody/common/UserStatus/action";
import { CONSTANTS_TRANSACTIONS } from "../../../../static/constants";
import { FormattedMessage } from "react-intl";


class SalesEnquiryListing extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      activeTab: linkProps.activeTab,
      activeTabKey: linkProps.activeTab === "Draft" ? "2" : "1",
      txPreferences: { txType: "inquiry", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        "inquiryNumber",
        "customerName",
        "inquiryDate",
        "expirationDate",
      ],
      txAvailableColumn: {
        inquiryNumber: true,
        inquiryDate: true,
        customerName: true,
        expirationDate: true,
        priority: true,
        status: false,
        isQuoteConverted: true,
        salesPersonFullName: true,
        refNumber: true,
        totalQuantityOnPR: true,
        totalQuantityOnQuote: true,
        userStatus: true,
        customerInquiryNumber: true,
        project: true,
        referenceNumber: true,
        statusPRMessage: true,
        systemStatus: true
      },
      isActive:1,
      amountSymbol: "=",
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    this.props.updateHeaderState({
      pageHeading: (
        <FormattedMessage id="heading.sales.salesInquiry" defaultMessage="" />
      ),
    });
    this.props.updateHeaderState({
      breadCrumbList: [
        {
          name: (
            <FormattedMessage id="sidebar.menuItem.sales" defaultMessage="" />
          ),
        },
        {
          name: (
            <FormattedMessage id="sidebar.menuItem.inquiry" defaultMessage="" />
          ),
        },
      ],
    });
    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
      transactionName: CONSTANTS_TRANSACTIONS.INQUIRY,
      
    };
    // fetchPaginationDataIfNeeded(
    //   "fetchSalesEnquiry",
    //   "enquiryList",
    //   this.props,
    //   {...payload, isActive:1}
    // );
   this.props.fetchSalesEnquiry({...payload, isActive:1})
   this.props.fetchSalesEnquiryCount({...payload, isActive:1})

    // fetchDataIfNeeded(
    //   "fetchSalesEnquiryCount",
    //   "enquiryCount",
    //   this.props,
    //   payload
    // );
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    if (linkProps.activeTab === "Draft") {
      fetchDataIfNeeded(
        "getSalesInquiryDraftCount",
        "inquiryDraftCount",
        this.props,
        payload
      );
      fetchPaginationDataIfNeeded(
        "fetchSalesInquiryDrafts",
        "inquiryDraftList",
        this.props,
        payload
      );
    }
    this.props.fetchCustomers({...payload, isCompact: true});
    this.props.fetchProjects(payload);
    this.props.fetchAllEmployees(payload);
    this.props.fetchAllStatus(payload);
    
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, {
        txType: "inquiry",
        templateName: "standard",
      });
      if (txPreferences && txPreferences.tablePreference) {
        let txColumns =
          (JSON.parse(txPreferences.tablePreference) || {}).columnList || [];
        txColumns = txColumns.length
          ? txColumns
          : this.state.txMandatoryColumns;
        const txColumnSetting =
          (JSON.parse(txPreferences.tablePreference) || {}).columnSetting || {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
          this.state.isColumnFitTable === false
            ? false
            : true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.emailDrawerVisible && (
          <EmailComponent
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        {this.state.salesInquiryFilterDrawerVisible && (
          <SalesEnquiryFilterComponent
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        {this.state.auditDrawerVisible && (
          <AuditTimeLine
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        <SalesEnquiryListingComponent
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    enquiryList: state.enquiry.enquiryList,
    enquiryCount: state.enquiry.enquiryCount,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    inquiryDraftList: state.enquiry.inquiryDraftList,
    inquiryDraftCount: state.enquiry.inquiryDraftCount,
    customers: state.customer.customers,
    projectList: state.common.projectList,
    salesPersonList: state.common.employees,
    userStatus: state.common.userStatus,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSalesEnquiry,
      fetchSalesEnquiryCount,
      showModal,
      popModalFromStack,
      pushModalToStack,
      hideModal,
      resetPaginatedData,
      fetchUserPreferences,
      deleteSalesEnquiry,
      createAuditTrail,
      deleteAuditTrail,
      saveUserPrefrences,
      fetchSalesInquiryDrafts,
      getSalesInquiryDraftCount,
      resetDraftPaginatedData,
      updateUserStatus,
      fetchCustomers,
      fetchProjects,
      fetchSalesEnquiryByFilter,
      getSalesEnquiryCountByFilter,
      fetchAllEmployees,
      fetchAllStatus,
      resetCustomerDetail,
      updateStatus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesEnquiryListing);
