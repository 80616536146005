import React from 'react';
// import { AgGridReact } from 'ag-grid-react';
import { Tooltip, Pagination ,Skeleton} from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import SODetail from '../../../../../containers/modal/modalBody/customer/SODetail';
import { CONSTANTS } from '../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../../../utils';


const TotalOrdersComponent = (props) => {
    const { totalOrderList, totalOrderListCount, companyInfo, updateState, pageNumber, dgList } = props;

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    // const getRowHeight = () => {
    //     return 28;
    // }

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo,relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        // fetchPaginationDataIfNeeded('fetchTotalOrders', 'totalOrderList', props, payload);
        props.fetchTotalOrders(payload,props);
    }

    const handlePageSizeChange = (pageNumber, pageSize) => {
        const payload = { companyInfo,relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        // props.resetPaginatedData();
        props.fetchTotalOrders(payload,props);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    const openSoDetails = (data) => {
        const soPayload = {
            customerId: data.customerId,
            salesOrderMasterId: data.salesOrderMasterId
        }
        props.pushModalToStack({
            modalBody: <SODetail {...props} soPayload={soPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.totalOrder.header.soNumber' defaultMessage='' />;
    //         },
    //         field: "soNumber",
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openSoDetails(link.data)
    //         }}>{link.value}</div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.totalOrder.header.soDate' defaultMessage='' />;
    //         },
    //         field: "salesOrderDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}>
    //                     {(link.value ? getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : '-')}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.totalOrder.header.soAmount' defaultMessage='' /> </div>;
    //         },
    //         field: "salesOrderTotalAmount",
    //         cellRendererFramework: (params) => {
    //             return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
    //         },
    //         valueFormatter: (data) => {
    //             data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    //             return data.value;
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "status",
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span>
    //             </div>
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.totalOrder.header.priority' defaultMessage='' />;
    //         },
    //         field: "orderPriority",
    //         cellRendererFramework: (params) => {
    //             return <div> {params.value || '-'} </div>
    //         },
    //         resizable: true,
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='customer.listing.totalOrder.header.soPerson' defaultMessage='' />;
    //     //     },
    //     //     field: "soPersonFullName",
    //     //     resizable: true,
    //     // }
    // ];

    return (
        // <div className='agGridWrapper'>
        //     <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
        //         <AgGridReact
        //             columnDefs={columnDefs}
        //             rowData={totalOrderList[pageNumber] || []}
        //             rowDragManaged={true}
        //             domLayout={"autoHeight"}
        //             onColumnResized ={(params)=>{
        //                 params.api.resetRowHeights();
        //             }}
        //             defaultColDef={{
        //                 flex:1,
        //                 autoHeight: true,
        //                 wrapText: true,
        //             }}
        //             animateRows={true}
        //             //getRowHeight={getRowHeight}
        //             onGridSizeChanged={onFirstDataRendered}
        //         >
        //         </AgGridReact>
        //     </div>
        // </div>
        <>
                <Skeleton loading={props.modalLoading}   paragraph={{ rows: 8 }} active>

            <div className="table-container" style={{ height: "480px" }}>
                <table id='customer-table'>
                    <thead>
                        <tr>
                            <th><FormattedMessage id='customer.listing.totalOrder.header.soNumber' defaultMessage='' /></th>
                            <th><FormattedMessage id="customer.listing.totalOrder.header.soDate" defaultMessage="" /></th>
                            <th><FormattedMessage id="customer.listing.totalOrder.header.soAmount" defaultMessage="" /></th>
                            <th><FormattedMessage id='common.status' defaultMessage='' /></th>
                            <th><FormattedMessage id='customer.listing.totalOrder.header.priority' defaultMessage='' /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {(totalOrderList[pageNumber] || []).length ? (totalOrderList[pageNumber] || []).map((rowData, i) => {
                            return <tr key={i}>
                                <td><div className="cursor-pointer" onClick={() => {
                                    openSoDetails(rowData)
                                }}>{rowData.soNumber}</div></td>
                                <td>

                                    <div>
                                        {(rowData.salesOrderDate && getMomentDateForUIReadOnly({ date: rowData.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                    </div>
                                </td>
                                <td>

                                    <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(rowData.salesOrderTotalAmount)}</span>

                                </td>

                                <td>
                                    <div>
                                        {rowData.status ? <span className="status-code" style={{ background: rowData.userStatusColor }}>
                                            <FormattedMessage id={rowData.status || ''} defaultMessage="" />

                                        </span> : ''}
                                    </div>
                                </td>
                                <td>{rowData.orderPriority}</td>

                            </tr>
                        }) : 'No data'}

                    </tbody>
                </table>
            </div>

            </Skeleton>
            <div className="footer">
            <Pagination
                size="small"
                total={totalOrderListCount}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber })
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props)
                }}
            />
            </div>
        </>
    );
};

export default injectIntl(TotalOrdersComponent);
