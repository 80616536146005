import React from "react";
import { Drawer, Row, Col, DatePicker, Select } from "antd";
import { FormattedMessage } from "react-intl";
import {
  getMomentDateForUI,
  getCurrentDateForBackend,
  showToasterMessage,
} from "../../../../utils";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { Dropdown } from "../../../general/Dropdown";
import { CONSTANTS } from "../../../../static/constants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;

const SalesInquiryFilterComp = (props) => {
  const { updateState, companyInfo } = props;

  const closeDrawer = () => {
    props.updateState({
    salesQuoteFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
    // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
    if (
      !props.selectedCustomerId &&
      !props.quoteNum &&
      !props.startDate &&
      !props.endDate &&
      !(props.selectedAmount && props.selectedAmountTerm) &&
      !props.selectedStatus &&
      !props.selectedUserStatus &&
      !props.selectedPriority &&
      !props.bidStartDate &&
      !props.bidEndDate &&
      !props.refNum &&
      !props.selectedSalesPersonId &&
      !props.selectedProjectMasterId
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please select at least one filter",
      });
    }
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {
        customerId: props.selectedCustomerId || 0,
        quoteNumber: props.quoteNum || null,
        startDate: props.startDate
          ? getCurrentDateForBackend(props.startDate)
          : null,
        endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
        amount: props.selectedAmount || 0,
        amountSymbol: props.selectedAmount ? props.selectedAmountTerm : null,
        status: props.selectedStatus || null,
        userStatus: props.selectedUserStatusName || null,
        priority: props.selectedPriority || null,
        bidStartDate: props.bidStartDate
          ? getCurrentDateForBackend(props.bidStartDate)
          : null,
        bidEndDate: props.bidEndDate
          ? getCurrentDateForBackend(props.bidEndDate)
          : null,
        reference: props.refNum || null,
        salesPersonId: props.selectedSalesPersonId || 0,
        salesPersonName: props.selectedSalesPersonName || null,
        projectMasterId: props.selectedProjectMasterId || 0,
      },
    };

    props.fetchSalesQuotesByFilter(payload);
    props.getSalesQuotesCountByFilter(payload);
    props.updateState({
      salesQuoteFilterDrawerVisible: false
  })
  };

  const clearFilter = () => {
    props.updateState({
      selectedCustomerId: null,
      selectedCustomerName: null,
      quoteNum: null,
      startDate: null,
      endDate: null,
      selectedAmount: null,
      selectedAmountTerm: null,
      selectedStatus: null,
      selectedStatusName: null,
      selectedUserStatusName: undefined,
      selectedUserStatus: undefined,
      selectedPriority: null,
      bidStartDate: null,
      bidEndDate: null,
      refNum: null,
      selectedSalesPersonId: null,
      selectedSalesPersonName: null,
      selectedProjectName: null,
      selectedProjectMasterId: null,
    });
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {},
    };
    props.fetchSalesQuotes(payload);
    props.getSalesQuoteCount(payload);
    props.updateState({
      salesQuoteFilterDrawerVisible: false
  })
  };

  const rowGutter = [24, 16];

  return (
    <>
      <Drawer
        title={<div className="drawer-head-txt">Filters</div>}
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.salesQuoteFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
        <Col span={12}>
            <div className="i-label">Quote #</div>
            <TextBox
              // style={{ marginTop: '-0px', width: '140px', marginRight: '5px', float:'left'}}
              className=""
              placeholder="Quote #"
              countStyle={{ top: "43px"}}
              type="text"
              maxLength={15}
              value={props.quoteNum}
              onChange={(e) => {
                props.updateState({ quoteNum: e.target.value });
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Customer</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}

              items={(props.customers[props.pageNumber] || []).sort((a, b) => (a.companyName||'').localeCompare(b.companyName||''))}
              valueKeyName="companyName"
              optionKeyName="customerId"
              value={props.selectedCustomerName}
              onSearch={(searchedText) => {
                props.fetchCustomers({
                  isCompact: true,
                  searchedText: searchedText,
                  pageNumber: 1,
                  hideSpinner: true,
                  pageSize: 100,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
              }}
              placeholder={props.intl.formatMessage({
                id: "customer.salesInvoice.form.customer.placeholder",
                defaultMessage: "",
              })}
              onSelect={(customerId, option) => {
                updateState({
                  selectedCustomerId: option.value,
                  selectedCustomerName: option.name,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedCustomerId: undefined,
                  selectedCustomerName: undefined,
                });
              }}
            />
          </Col>
        
        </Row>
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">Quote Date</div>
            <RangePicker
              // style={{ marginTop: '-0px', width: '300px', marginRight: '5px',float:'left' }}
              allowClear={false}
              format={format}
              placeholder={["Quote Start Date", "Quote End Date"]}
              value={[
                props.startDate
                  ? getMomentDateForUI({
                      date: new Date(props.startDate),
                      format,
                    })
                  : null,
                props.endDate
                  ? getMomentDateForUI({
                      date: new Date(props.endDate),
                      format,
                    })
                  : null,
              ]}
              onChange={(dates) => {
                props.updateState({ startDate: dates[0], endDate: dates[1] });
              }}
            />
          </Col>
          <Col span={12}>
            <Row>
             
              <Col span={20}>
                <div className="i-label">Quote Amount</div>

                <TextBox
                //   style={{ width: "140px" }}
                  placeholder="Enter Amount"
                  type="Number"
                  // maxLength={12}
                  value={props.selectedAmount}
                  onChange={(e) => {
                    props.updateState({ selectedAmount: e.target.value });
                  }}
                />
              </Col>
              <Col span={4}>
              {/* <div className="i-label one-line-view">Quote</div> */}

                <Select
                  style={{ marginTop: '20.5px',}}
                  className="drop-filed3"
                  options={[
                    { label: "<", value: "<" },
                    { label: ">", value: ">" },
                    { label: "=", value: "=" },
                  ]}
                  value={props.selectedAmountTerm}
                  placeholder="="
                  defaultValue="="
                  onSelect={(value) => {
                    updateState({ selectedAmountTerm: value });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
       
        <Col span={12}>
            <div className="i-label">Sales Person</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '140px', marginRight: '5px',float:'left' }}
              className=""
              items={(props.salesPersonList||[]).sort((a, b) => a.fullName.localeCompare(b.fullName))}
              valueKeyName="fullName"
              optionKeyName="relationshipEmployeeId"
              value={props.selectedSalesPersonName}
              placeholder={props.intl.formatMessage({
                id: "filter.salesPerson.placeholder",
              })}
              onSelect={(status, option) => {
                updateState({
                  selectedSalesPersonName: option.name,
                  selectedSalesPersonId: option.value,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedSalesPersonName: undefined,
                  selectedSalesPersonId: undefined,
                });
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">
              <FormattedMessage id="common.systemStatus" />
            </div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '140px', marginRight: '5px',float:'left' }}
              className=""
              items={[
                { name: "Open", value: "open" },
                { name: "Partially Fullfilled", value: "partially.fulfilled" },
                { name: "Fullfilled", value: "fulfilled" },
              ].sort((a, b) => a.name.localeCompare(b.name))}
              valueKeyName="name"
              optionKeyName="value"
              value={props.selectedStatusName}
              placeholder={props.intl.formatMessage({
                id: "common.systemStatus",
              })}
              onSelect={(status, option) => {
                updateState({
                  selectedStatusName: option.name,
                  selectedStatus: option.value,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedStatusName: undefined,
                  selectedStatus: undefined,
                });
              }}
            />
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          {/* <Col span={12}>
            <div className="i-label">User Status</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '140px', marginRight: '5px',float:'left' }}
              className=""
              items={props.userStatus || []}
              valueKeyName="statusName"
              optionKeyName={"salesQuoteStatusId"}
              statusColorKey="statusColor"
              // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' }}
              value={props.selectedUserStatusName}
              placeholder={props.intl.formatMessage({ id: "common.status" })}
              onSelect={(selectedValue, option) => {
                updateState({
                  selectedUserStatusName: option.name,
                  selectedUserStatus: option.value,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedUserStatusName: undefined,
                  selectedUserStatus: undefined,
                });
              }}
            />
          </Col> */}

          <Col span={12}>
            <div className="i-label">Priority</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' ,float:'left'}}
              className=""
              items={[
                { name: "Low", value: "low" },
                { name: "Medium", value: "medium" },
                { name: "High", value: "high" },
                { name: "Urgent", value: "urgent" },
              ]}
              valueKeyName="name"
              optionKeyName="value"
              value={props.selectedPriority}
              placeholder={props.intl.formatMessage({
                id: "customer.filter.priority.placeholder",
              })}
              onSelect={(status, option) => {
                updateState({ selectedPriority: option.name });
              }}
              allowClear={true}
              onClear={() => {
                updateState({ selectedPriority: undefined });
              }}
            />
          </Col>
     
          {/* <Col span={12}>
            <div className="i-label">Bid start date</div>
            <RangePicker
              // style={{ marginTop: '-0px', width: '300px', marginRight: '5px', float:'left' }}
              className="2"
              allowEmpty={true}
              format={format}
              placeholder={["Bid start date", "Bid end date"]}
              value={[
                props.bidStartDate
                  ? getMomentDateForUI({
                      date: new Date(props.bidStartDate),
                      format,
                    })
                  : null,
                props.bidEndDate
                  ? getMomentDateForUI({
                      date: new Date(props.bidEndDate),
                      format,
                    })
                  : null,
              ]}
              onChange={(dates) => {
                if (dates) {
                  props.updateState({
                    bidStartDate: dates[0],
                    bidEndDate: dates[1],
                  });
                } else {
                  props.updateState({ bidStartDate: null, bidEndDate: null });
                }
              }}
            />
          </Col> */}
          <Col span={12}>
            <div className="i-label">Ref #</div>
            <TextBox
              // className='new-fed1'
              // style={{ marginTop: '-0px', width: '140px', marginRight: '5px',float:'left'}}
              className=""
              placeholder="Ref #"
              type="text"
              countStyle={{ top: "43px"}}
              maxLength={15}
              value={props.refNum}
              onChange={(e) => {
                props.updateState({ refNum: e.target.value });
              }}
            />
          </Col>
       
         
          <Col span={12}>
            <div className="i-label">Projects</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '160px', marginRight: '5px', float:'left' }}
            //   className="1"
              items={(props.projectList||[]).sort((a, b) => a.projectName.localeCompare(b.projectName))}
              valueKeyName="projectName"
              optionKeyName="projectMasterId"
              // projectIdKey="projectMasterId"
              value={props.selectedProjectName}
              placeholder={props.intl.formatMessage({
                id: "salesInvoice.project.placeholder",
              })}
              onSelect={(status, option) => {
                updateState({
                  selectedProjectName: option.name,
                  selectedProjectMasterId: option.value,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedProjectName: undefined,
                  selectedProjectMasterId: undefined,
                });
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default SalesInquiryFilterComp;
