import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import {SaveOutlined ,CheckOutlined,DownloadOutlined }  from '@ant-design/icons';

import { getMomentDateForUIReadOnly } from "../../../utils";
import { Skeleton } from "antd";




const GstrComp = (props) => {
  const {heading ,data={} , index ,type=""} = props;
  return (
    <div className="summary-comp">
      <div className="heading">
        <div className="left-particular">
          <span>{index}. </span>
          <span>{heading}</span>
        </div>
        <div className="right-particular">
          <span>{type}</span>
        </div>
      </div>
      <div className="summary-table">
        <table>
          <thead>
            <tr>
              <th className="text-center">Record Count</th>
              <th className="text-end">Total Invoice Value</th>
              <th className="text-end">Total Taxable Value</th>
              <th className="text-end">Total Tax Amt.</th>
              <th className="text-end">IGST</th>
              <th className="text-end">CGST</th>
              <th className="text-end">SGST</th>
              <th className="text-end">Cess</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">{data?.invoiceCount||0}</td>
              <td className="text-end">₹{data?.totalInvValue||0}</td>
              <td className="text-end">₹{data?.totalTaxableValue||0}</td>
              <td className="text-end">₹{data?.totalTaxableAmt||0}</td>
              <td className="text-end">₹{data?.totalIGSTAmount||0}</td>
              <td className="text-end">₹{data?.totalCGSTAmount||0}</td>
              <td className="text-end">₹{data?.totalSGSTAmount||0}</td>
              <td className="text-end">₹{data?.totalCESSAmount||0}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const SummaryComp = (props) => {

  const summaryList = props.summaryInvoiceListing;
  return (
    <Fragment>
      {" "}
      <div className="summary-container">
      <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
        <div className="left-actions">
          <div className="table-heading">
            <FormattedMessage id="Summary" defaultMessage="" />
          </div>

          <div className="vertical-sep" />
          <div>Summary Created on:  {getMomentDateForUIReadOnly({date: summaryList?.id?.date , format: "DD-MMMM-YYYY"}) }</div>
        </div>

        <div className="right-actions">
        <button
           className="upload-button"
              onClick={()=>{
                props.saveToGstinStatus({gstin:"33ABCDE9876A1ZE" ,fp: props.selectedDate ,formType :"GSTR1 "})
              }}
            >
              <CheckOutlined />
             
              <FormattedMessage id="SAVED STATUS" defaultMessage="" />
            </button>
            <button
            className="upload-button"
              onClick={() => {
               props.saveDataToGSTIN({gstin:"33ABCDE9876A1ZE" ,fp: props.selectedDate ,formType :"GSTR1 "})
              }}
            >
              <DownloadOutlined />
              
              <FormattedMessage id="SAVE DATA TO GSTIN" defaultMessage="" />
            </button>
        <button

         
              onClick={() => {
                const modalData = {
                  title: <FormattedMessage
                  id="Confirmation"
                  defaultMessage=""
                />,
                hideFooter: false,
                  modalBody: (
                   <div>
            Are you sure you want to disacrd the Summary?
                   </div>
                  ),
                  width: 500,
                  handleSubmit: ()=>{
                    
                    
                    
                   
                    props.discardSummaryDraft({gstin:"33ABCDE9876A1ZE" ,fp: props.selectedDate ,formType :"GSTR1 "},props)
                    
                   
                  }
                };
  
                props.pushModalToStack(modalData) 
               
              }
             
            }
              
            >
              {/* <SaveOutlined /> */}
              <FormattedMessage id="DISCARD SUMMARY" defaultMessage="" />
            </button>
        
        </div>
        </Skeleton>
      </div>
      <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>

      <div className="table-container">
        <div className="summary-section">
          <div className="summary-comp">

          <div
           className="summary-table"
          >
            <table>
              <thead>
                <tr>
                  
                  <th>
                    <FormattedMessage
                      id="Filing Period"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage id="GSTIN" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="Aggregate Turnover in previous FY"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="Aggregate Turnover from Apr- Jun."
                      defaultMessage=""
                    />
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{summaryList?.fp}</td>
                  <td>
                  {summaryList?.gstin}
                  </td>
                  <td>

                  </td>
                  <td>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        
            <GstrComp heading={"B2B"} index={1} data ={summaryList?.b2bSecSummary} type={'4A/B/C,6B/C'}/>

            <GstrComp heading={"B2CL"} index={2} type={"5A/B"} />
            <GstrComp heading={"B2C(Others)"} index={3} type={'7'} data={summaryList?.b2csSecSummary}/>
            <GstrComp heading={"Credit/Debit Notes (Unregistered)"} index={4} type={'9B'} data={summaryList?.cdnrSecSummary
}/>
<GstrComp heading={"HSN Summary"} index={5} type={12}/>
</div>
      </div>
    </Skeleton>
      </div>
    </Fragment>
  );
};

export default SummaryComp;
