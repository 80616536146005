import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
//import { Link } from 'react-router-dom';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Button } from 'antd';
import { MODAL_TYPE } from '../../../static/constants';
import { CustomButton } from '../../general/CustomButton';
import { CustomAgGrid } from '../../general/CustomAgGrid';
import { UploadQuality } from '../../general/UploadQuality';
import { showToasterMessage } from '../../../utils';
//import { Dropdown } from '../../general/Dropdown';

//function QualityCheckDrawer(props) 
class QualityCheckAddDrawer extends React.Component {
    render() {
        const self = this;
        const closeDrawer = () => {
            this.props.updateState({
                qualityCheckDrawerVisible: false
            })
        }

        // const customProps = {
        //     name: 'file',
        //     action: null,
        //     buttonName: 'Upload Document',
        //     onChange: (file) => {
        //         this.props.updateState({
        //             fileToUpload: file
        //         })
        //     }
        // }

        const handleAddQualityCheckToSO = () => {
            let ckeckList = [];
            let isRequiredFiledMissing = false;
            this.gridApi && this.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (!data.checklistName) {
                    isRequiredFiledMissing = true;
                    return;
                } else {
                    ckeckList.push(data);
                }
            });
            if (isRequiredFiledMissing) {
                return showToasterMessage({
                    messageType: 'error', description: 'Quality Check is required'
                });
            }

            closeDrawer();
            this.props.addQualityCheckToSO(ckeckList);
            // const modalData = {
            //     modalBody: "Are you sure you want to save?",
            //     handleSubmit: () => {
            //         closeDrawer();
            //         this.props.addQualityCheckToSO(ckeckList);
            //         this.props.hideModal();
            //     },
            // };
            // this.props.showModal(modalData);
        }


        const addItemConfirmationModal = (props, text, modalType) => {
            const { showModal, companyInfo } = props;
            const labelName = 'Quality Check';
            const modalBody = <span>
                <FormattedMessage
                    id='addItem.confirmation'
                    values={{ value: `${text} to ` }}
                    defaultMessage={``}
                />
                {labelName} ?
    </span>;

            let addFunc;

            switch (modalType) {
                case MODAL_TYPE.QUALITY_CHECK: {
                    addFunc = props.addQualityChecklists;
                    break;
                }
                default: {
                    addFunc = () => { }
                }
            }

            const modalData = {
                modalBody,
                handleSubmit: () => {
                    addFunc({ checklistName: text, relationshipId: companyInfo.relationshipId })
                    props.hideModal();
                },
            };
            showModal(modalData);
        }

        const getModalTitle = (newTextObj, modalType) => {
            let title;

            switch (modalType) {
                case MODAL_TYPE.QUALITY_CHECK: {
                    title = <FormattedMessage id='addItem.text.qualityCheck' defaultMessage='' />;
                    break;
                }
                default:
                    title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
            }
            return title;
        }

        const handleNewItemAddition = (props, payload, modalType) => {
            const formData = payload.formData;
            const { showModal } = props;

            if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
                addItemConfirmationModal(props, formData.textBoxValue, modalType);
            } else {
                const data = {
                    title: getModalTitle(payload, modalType),
                    formData,
                    modalData: { modalType, data: payload },
                    handleSubmit: (formData = {}) => {
                        if (formData.textBoxValue && formData.textBoxValue.length) {
                            addItemConfirmationModal(props, formData.textBoxValue, modalType);
                        } else {
                            data.formData.submittedOnce = true;
                            showModal(data);
                        }
                    }
                };
                showModal(data);
            }
        }


        const deleteClickHandler = (props, modalType, payload) => {
            const { showModal, companyInfo } = props;
            const modalBody =
                <FormattedMessage
                    id='deleteItem.confirmation'
                    defaultMessage={``}
                    values={{ value: payload.text }}
                />;

            let deleteFunc;

            switch (modalType) {
                case MODAL_TYPE.QUALITY_CHECK: {
                    deleteFunc = props.deleteQualityChecklists;
                    break;
                }
                default: {
                    deleteFunc = () => { }
                }
            }

            const modalData = {
                modalBody,
                handleSubmit: () => {
                    deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId })
                    props.hideModal();
                },
            };
            showModal(modalData);
        }

        // const format = CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT;

        // const agGridStyle = {
        //     height: '100%',
        //     width: '100%'
        // };

        // const onFirstDataRendered = (params) => {
        //     params.api.sizeColumnsToFit();
        // }
        // const getRowHeight = (params) => {
        //     return 30;
        // }


        const columnDefs = [

            {

                headerName: 'Quality Check',
                resizable: true,
                field: 'checklistName',
                editable: true,
                sortable: true,
                cellEditor: 'customDropDownEditor',
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.allQualityChecksName,
                        optionKeyName: 'qualityChecklistId',
                        valueKeyName: 'checklistName',
                        canAddNew: true,
                        canDelete: true,
                        deleteClickHandler: (payload) => {
                            deleteClickHandler(this.props, MODAL_TYPE.QUALITY_CHECK, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false
                            }
                            handleNewItemAddition(this.props, payload, MODAL_TYPE.QUALITY_CHECK, obj)
                        },
                        onSelect: (selectedValue, option) => {
                            let isDuplicateItem = false;
                            this.gridApi && this.gridApi.forEachNode((rowNode, index) => {
                                const { data } = rowNode;
                                if (data.checklistName  === selectedValue && index !== obj.node.rowIndex) {
                                    isDuplicateItem = true;
                                }
                            });
                            if (isDuplicateItem) {
                                showToasterMessage({
                                    messageType: 'warning', description: 'Duplicate product is selected'
                                });
                            }

                            obj.node.data.isDuplicateItem = isDuplicateItem;
                            obj.node.data.checklistName = selectedValue;
                        },
                    }
                },
                cellClassRules: {
                    'ag-grid-cell-warning-boundary': function (obj) {
                      let isDuplicateItem = false;
                      self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                        const { data } = rowNode;
                        if (data.checklistName && data.checklistName === obj.node.data.checklistName && obj.node.rowIndex !== index) {
                          isDuplicateItem = true;
                        }
                      });
                      return isDuplicateItem;
                    }
                  },
                cellClass: 'variant-column',
            },
            // {
            //     headerComponentFramework: () => {
            //         return <div>
            //             Completed
            //             <Tooltip placement="top" title="Quality Check status" trigger="click">
            //                 <i className="fa fa-question-circle-o pl5 cursor-pointer" />
            //             </Tooltip>
            //         </div>
            //     },
            //     field: "completed",
            //     resizable: true,
            //     editable: true,
            //     cellRendererFramework: function (link) {
            //         return <div>
            //             {link.value ? "Yes" : "No"}
            //         </div>
            //     },
            //     cellEditor: 'customCheckboxEditor',
            //     cellEditorParams: (obj) => {
            //         return {
            //             onChange: (value) => {
            //                 obj.node.data.completed = value ? 1: 0;
            //             }
            //         }
            //     }
            // },
            {
                headerName: 'Document',
                field: 'document',
                sortable: true,
                resizable: true,
                cellRendererFramework: function (link) {
                    setTimeout(() => {
                        if (link.reactContainer && link.reactContainer.scrollHeight >= (link.node.data.maxHeight || 30)) {
                            link.node.setRowHeight(link.reactContainer.scrollHeight);
                            link.node.data.minHeight = link.reactContainer.scrollHeight > 30 ? link.reactContainer.scrollHeight : 30;
                            self.gridApi && self.gridApi.onRowHeightChanged();
                        } else {
                            link.node.data.minHeight = link.reactContainer.scrollHeight > 30 ? link.reactContainer.scrollHeight : 30;
                        }
                    }, 500)
                    return <div onClick={() => {
                        self.props.showModal({
                            title: <Fragment>
                                <div className='ant-modal-title'>Upload Documents</div>
                            </Fragment>,
                            modalBody: <UploadQuality {...self.props} fileList={link.data.documentDetails} onChange={
                                (documentDetails) => {
                                    link.data.documentDetails = documentDetails;
                                    self.gridApi.refreshCells({ force: true });
                                }
                            } />,
                            width: 600,
                            hideFooter: true,
                        })
                    }}>
                        <div className="cursor-pointer">{((link.data || {}).documentDetails || []).length ? ((link.data || {}).documentDetails || []).map((d, i) => {
                            return <div>{(d.name || d.fileName)}</div>
                        }) : "Upload Document"}</div>
                    </div>
                },
            },
            {
                headerName: 'Remarks',
                field: 'remarks',
                editable: true,
                sortable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.data.remarks = value;
                        }
                    }
                },
                cellRendererFramework: function (link) {
                    setTimeout(() => {
                        if (link.reactContainer && link.reactContainer.scrollHeight >= (link.node.data.maxHeight || 30)) {
                            link.node.setRowHeight(link.reactContainer.scrollHeight);
                            link.node.data.minHeight = link.reactContainer.scrollHeight > 30 ? link.reactContainer.scrollHeight : 30;
                            self.gridApi && self.gridApi.onRowHeightChanged();
                        } else {
                            link.node.data.minHeight = link.reactContainer.scrollHeight > 30 ? link.reactContainer.scrollHeight : 30;
                        }
                    }, 500)
                    return <div>{link.value}</div>
                }
            },
            {
                pinned: 'right',
                cellRenderer: 'customActionEditor',
                suppressNavigable: true,
                cellRendererParams: (params) => {
                    return {
                        onClick: () => {
                            // const modalData = {
                            //     modalBody: 'Are you sure you want to delete ?',
                            //     handleSubmit: () => {
                            //         params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                            //         let rowData = [];
                            //         params.api.forEachNode(node => rowData.push(node.data));
                            //         this.props.deleteQualityChecklistFromSO(params.node.data)
                            //         this.props.hideModal();
                            //     },
                            // };
                            // this.props.showModal(modalData);
                            params.api.updateRowData({ remove: [params.api.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                            let rowData = [];
                            params.api.forEachNode(node => rowData.push(node.data));
                        },
                    }
                },
                width: 70
            }
        ]

        const onGridReady = (params) => {
            this.gridApi = params.api;
            params.api.sizeColumnsToFit();
        }

        return (
            <Fragment>

                <Drawer
                    title={
                        this.props.title
                    }
                    width={720}
                    keyboard={false}
                    zIndex={1}
                    maskClosable={false}
                    destroyOnClose={true}
                    onClose={closeDrawer}
                    className="custom-drawer"
                    visible={this.props.qualityCheckDrawerVisible}
                    footer={
                        <>
                        <CustomButton
                            intlId='confirmation.cancel'
                            defaultMessage=''
                            type='default'
                            key={0}
                            style={{ marginRight: 8 }}
                            onClick={closeDrawer}
                        />
                        <CustomButton
                            onClick={() => {
                                handleAddQualityCheckToSO();
                            }}
                            type='primary'
                            htmlType='submit'
                            intlId='button.save.label'
                            defaultMessage=''
                        />
                        </>
                    }
                >

                    <div>
                        {/* <div>
                         
                        <Dropdown
                        className="pull-right"
                        style={{width:'30%'}}
                            items={['Not Strated', 'In Progress', 'Completed']}
                            placeholder='Status'
                        />
                        <br/>
                        <br/>
                        <br/>
                    </div> */}
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            onGridReady={onGridReady}
                            rowData={this.props.qualityCheckList}
                            singleClickEdit={true}
                            disableFocusOnClickOutside={true}
                            editType={'fullRow'}
                            defaultColDef={{
                                flex: 1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            gridStyle={{
                                width: '100%',
                                height: '100%',
                                marginBottom: '10px'
                            }} />
                        <Button className="grey-button" onClick={
                            () => {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                        }><FormattedMessage id='customer.salesOrder.form.button.addLine' defaultMessage='' /></Button>
                    </div>

                    {/* <div className='form-footer'>
                        
                    </div> */}
                </Drawer>
            </Fragment>
        );
    }
}

export default injectIntl(QualityCheckAddDrawer);
