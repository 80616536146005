import React from "react";
import { Card, Col, Row, Collapse, Checkbox, Button, Input } from "antd";

import "./menus.scss";

import * as find from "lodash.find";



const { Panel } = Collapse;

const HomePageSection = (props) => {

  const handleSave = () => {
    const payload = {
          relationshipId: props.relationshipId,
          isActive: 1,
          ecomSettingId:props.storeValues.ecomSettingId,
          homePageSetting:JSON.stringify(props.sections|| "[]")
      }    
    props.updateHomePageSettings(payload ,props);
  };

  const handleCheckFlags =(e , id)=>{
     
    let selectedObjects = props.sections || [];
   
   
    if (e.target.checked) {
      let flag = find(selectedObjects, {
        sectionId: id,
      }) ;
      
       flag.type ="Sections"
       flag.isVisible = 1;
       
      // selectedObjects.push(flag);
    } 
    else {
      let flag = find(selectedObjects || [], {
        sectionId: id,
      });
      flag.type ="Sections"
      flag.isVisible = 0;
     
    }
  
    props.updateState({
      sections: selectedObjects,
    });
  }
  return (
    <div className="store-model-container-f">
    
            <div className="menuEstore-Container" style={{position:"relative"}}>
              <Row>
        
                <Col lg={24} xxl={24}>
                  <Card className="header-structure-card">
                    <div className="card-header">
                      <h2>Home Sections</h2>
                    </div>

                    <div className="menuEstore-structure-card-content">     
                      {(props.sections||[]).length ? (props.sections).map((selectedItems, index) => {
                          return (
                          <div>
                            {
                             
                               <Row style={{ marginTop: "10px" }}>
                              <Col lg={16} xxl={10}>
                                <Collapse
                                  accordion
                                  expandIconPosition="right"
                                  activeKey={props.activeKey|| 0}
                                  // defaultActiveKey={["0"]}
                                  onChange={(key)=>{
                                    props.updateState({
                                    activeKey:key
                                  })}}
                                >
                                  <Panel
                                  key={index}
                                    header={
                                      <div className="header-structure-card-collapse-header">
                                        <div className="header-structure-card-collapse-header-child">
                                          <span>
                                           <Checkbox
                                            checked={selectedItems.isVisible?true:false}
                                            onClick={(event) =>{
                                                event.stopPropagation();
                                                handleCheckFlags(event,selectedItems.sectionId)
                                            }}
                                            ></Checkbox>
                                          </span>
                                          <span className="collapse-header-right">
                                            <span className="">
                                              {
                                                selectedItems.itemName ||selectedItems.categoryName||
                                                selectedItems.customLinkName ||
                                                selectedItems.pageTitle || selectedItems.label
                                              }  {(selectedItems.itemName && selectedItems.label ) ?<span>&#40; {selectedItems.label} &#41;</span> :''} 
                                            </span>
                                          </span>
                                        </div>

                                        <div>
                                          {selectedItems?.type}
                                        </div>
                                      </div>
                                    }
                                  >
                                    <Row>
                                      <Col span={24}>
                                        <div className="i-label">
                                          Name
                                          <span className="required">*</span>
                                        </div>
                                      </Col>
                                      <Col span={24}>
                                        <Input
                                          onChange={(e) => {
                                           
                                            const value = {
                                              ...selectedItems,
                                              displayName: e.target.value,
                                            };
                                           
                                            props.sections[index] = { ...value };
                                            props.updateState({
                                              sections: [
                                                ...props.sections,
                                              ],
                                              
                                            });
                                          }}
                                          value={
                                             selectedItems?.displayName || selectedItems.label
                                          }
                                          placeholder={`Rename ${selectedItems?.type.slice(0,-1)} Name`}
                                        />
                                      </Col>
                                      <Col span={24}>
                                        <div className="header-structure-card-buttons-wapper mt10 ">
                                          <div className="buttons-inner-wapper">
                                          
                                          </div>
                                          <div>
                                          
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Panel>
                                </Collapse>
                              </Col>
                            </Row>
                            }
                              
                          </div>
                          
                          );
                        }
                      ) : <div className="empty-list">
                           <p>Start adding menu elements from header items</p>
                        </div>}
                    </div>
                    <div className="footer-btn">
                      <Button onClick={() => {handleSave();}} type="primary">
                        Submit
                      </Button>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
            
    </div>
  );
};

export default HomePageSection;