import React, {useState} from 'react';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
// import config from '../../../../../config/environmentConfig';
// import NewPics from "../../../../../assets/images/inbound-delivery.png";
import TextIcon from "../../../../../assets/images/message-icon.png";
import EmailIcon from "../../../../../assets/images/email-icon.png";
import WhatsIcon from "../../../../../assets/images/whatsapp-icon.png";
import KiosksIcon from "../../../../../assets/images/kiosks-icon.png";
import FaceIcon from "../../../../../assets/images/facebook-icon.png";
// import TwitterIcon from "../../../../../assets/images/twitter-icon.png";
// import InstagramIcon from "../../../../../assets/images/instagram-icon.png";
// import PrinterIcon from "../../../../../assets/images/printer-icon.png";
import {showToasterMessage} from "../../../../../utils";
import DonationKioskSetting from '../../../../../containers/modal/modalBody/settings/DonationKioskSetting';
import { FacebookShareButton } from "react-share";


const NewsLetterShare = (props) => {

    const [textAreaRef, updateTextAreaRef] = useState({});
    const openKioskDonationSetting = (data) => {
        const modalData = {
            title: <div className='ant-modal-title'><FormattedMessage id='setting.kioskDonation.heading' defaultMessage='' /></div>,
            modalBody: <DonationKioskSetting {...props} formFundraising={true} style={{ overflow: 'auto' }} />,
            width: 1100,
            hideFooter: true,
            maskClosable: true,
            innerHeight: 800,
            wrapClassName: "remark-modalm",
        };
        props.pushModalToStack(modalData);
    };
    
    let uri = '/app/newsletter-v';
    let memberToken;
    let sourceId = 5;
    if((props.templateObj || {}).templateType ==='event'){
        uri = '/app/event-v';
        sourceId = 3;
    } else if((props.templateObj || {}).templateType ==='fundraising'){
        uri = '/app/campaign-v';
        sourceId = 4;
    }
    if(props.isRegistrationForm){
        uri = '/app/register';
        sourceId = 4;
    }
    let token = `${props.companyInfo.relationshipId}_${(props.templateObj || {}).alpideFormBuilderId}`;
   
    if (props.memberData && props.memberData.teamMemberId) {
        memberToken = props.memberData.teamMasterId + '_' + props.memberData.teamMemberId;
    }
    const previewUrl = (props.subdomainName || window.location.origin) + uri + `?t=${token}`;
    const copyCodeToClipboard = () => {
        const el = textAreaRef;
        el.select();
        document.execCommand("copy");
        showToasterMessage({
            messageType: 'success', description: props.intl.formatMessage(
                {
                    id: 'copyToClipboard.text',
                    defaultMessage: ''
                }
            )
        });
    }

    return (
        <div className={"header-action-container"}>
            <i className="close-icon">
                <svg width="17" height="17" onClick={() => { props.popModalFromStack(); }}>
                    <title>Close</title>
                    <path fill="#67757d" fillRule="evenodd"
                        d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content ">
                    <div className="modal-header">
                        Share
                    </div>
                    <div className="inner-content news-heding" >


                        <Row>

                            <Col span={24}>
                                <div className='white-new'>
                                    <Row>

                                        <Col span={24}>
                                            {/* <div style={{ padding: '20px 0px 10px', fontSize: '16px' }}>Shared on social networks raise up to 5x more.</div> */}

                                            {/* ===========share=========== */}
                                            <div className='share-boxs'>
                                                <ul>
                                                    <li onClick={() => {
                                                        props.updateHeaderState({
                                                            communication: {
                                                                ...props.communication,
                                                                addBrodcastSMSDrawerVisible: true,
                                                                brodcastSMSType: 'textSMS',
                                                                previewUrl: previewUrl + '_1_' + Math.floor((Math.random() * 10000) + 1) + (memberToken ? '&f=' + memberToken : '')
                                                            }
                                                        });
                                                    }}>
                                                        <span style={{cursor: 'pointer'}}><div className='pics'><img src={TextIcon} alt='' /> </div>
                                                            Text
                                                        </span>
                                                    </li>

                                                    <li onClick={() => {
                                                    //     let tempCont = `<div style="   padding: 10px 0px; position: absolute;left: 0px; width: 100%; z-index: 99;" >
                                                    //     <div style="background: #fff;padding: 20px 0px;margin-top: 0px; margin: 0px auto;width: 750px;padding: 0px0px0px;">
                                                    //         <div style="position: relative;margin: 0px auto 30px; text-align: center;" id="temmplateDiv">
                                                    //             ${(props.templateObj || {}).templateContent}
                                                    //         </div>
                                                    //     </div>
                                                    // </div>`;
                                                    let tempCont = `<div style="text-align: center;"> ${(props.templateObj || {}).templateContent}</div>`;
                                                        props.updateState({ emailDrawerVisible: true, templateContent: tempCont, previewOnlineUrl: previewUrl + '_4_' + Math.floor((Math.random() * 10000) + 1) + (memberToken ? '&f=' + memberToken : '') });
                                                        props.popModalFromStack();
                                                    }}>
                                                        <span style={{cursor: 'pointer'}}><div className='pics'><img src={EmailIcon} alt='' /> </div>
                                                            Email
                                                        </span>
                                                    </li>

                                                    <li onClick={() => {
                                                         props.updateHeaderState({
                                                            communication: {
                                                                ...props.communication,
                                                                addBrodcastSMSDrawerVisible: true,
                                                                brodcastSMSType: 'whatsappSMS',
                                                                previewUrl: previewUrl+'_2_'+Math.floor((Math.random() * 10000) + 1)+ (memberToken ? '&f=' + memberToken : '')
                                                            }
                                                        });
                                                    }}>
                                                        <span style={{cursor: 'pointer'}}><div className='pics'><img src={WhatsIcon} alt='' /> </div>
                                                            Whatsapp
                                                        </span>
                                                    </li>

                                                    <li onClick={() => { openKioskDonationSetting() }}>
                                                        <span style={{ cursor: 'pointer' }}><div className='pics'><img src={KiosksIcon} alt='' /> </div>
                                                            Kiosk
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <div className='pics'>
                                                            <FacebookShareButton style={{ paddingLeft: '5px', paddingRight: '5px' }}
                                                                url={previewUrl+'_2_'+Math.floor((Math.random() * 10000) + 1)+ (memberToken ? '&f=' + memberToken : '')}
                                                                quote={"Share Campaing"}
                                                                className="Demo__some-network__share-button"
                                                            >
                                                                <img src={FaceIcon} alt='' />
                                                            </FacebookShareButton>
                                                        </div>
                                                        Facebook
                                                </li>

                                                    {/* <li>
                                                        <a href='#'><div className='pics'><img src={FaceIcon} alt='' /> </div>
                                                            Facebook
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href='#'><div className='pics'><img src={TwitterIcon} alt='' /> </div>
                                                            Twitter
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a href='#'><div className='pics'><img src={InstagramIcon} alt='' /> </div>
                                                            Instagram
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href='#'><div className='pics'><img src={PrinterIcon} alt='' /> </div>
                                                            Print
                                                        </a>
                                                    </li> */}
                                                    <div style={{ clear: 'both' }}></div>
                                                </ul>
                                                <Row>
                                                    <div className='share-butts'>
                                                        <span>Shareable Link</span>

                                                        <input type='text' readOnly ref={(textarea) => updateTextAreaRef(textarea) } value={ previewUrl+'_'+ sourceId+'_'+Math.floor((Math.random() * 10000) + 1)+ (memberToken ? '&f=' + memberToken : '')} className='ant-input' style={{ width: '90%' }} />

                                                        <button className='ant-btn ant-btn-default' style={{position: 'relative',top: '3px'}} onClick={() => copyCodeToClipboard() }>
                                                            <i className="fa fa-clone"></i>
                                                            </button>
                                                    </div>
                                                </Row>

                                            </div>
                                            {/* ===========share=========== */}

                                        </Col>
                                    </Row>
                                </div>
                                {/* <div className='white-new'>


                           <h2> Embed HTML</h2>
                           <div className='new-checks1'>
                           <input className="checkbox-tools1" type="radio" name="tools" id="tool-1" />
        <label className="for-checkbox-tools1" for="tool-1">
           <span className="new-checkt">Large</span> 
            </label>


            <input className="checkbox-tools1" type="radio" name="tools" id="tool-2" />
        <label className="for-checkbox-tools1" for="tool-1">
           <span className="new-checkt">Medium</span> 
            </label>


            <input className="checkbox-tools1" type="radio" name="tools" id="tool-3" />
        <label className="for-checkbox-tools1" for="tool-1">
           <span className="new-checkt">Small (Button only)</span> 
            </label>
                           </div>
                           <h3>Preview</h3>
                       
                       <div className='newelter-pic'>
<img src={NewPics} alt=''/>
                       </div>
                       <h2> Raising fund to promote</h2>
                       <Progress strokeLinecap="square" percent={75} />
                      <span className='lage-text'> $ 500,00 </span ><span className='small-text'> Goal</span> <a href='#' className='give-today-butt'>Give today</a>
                           
                           <div style={{ padding:'20px 0px 10px', fontSize:'16px'}}> Copy and paste following embed code: </div>
                           <textarea className='news-teatrea'></textarea>

                           <Row>
                               <Col span={7}><a href='#' className='ant-btn ant-btn-primary ml10'>Copy  HTML code </a></Col>
                               <Col span={8}><a href='#' className='doatn-but'>Donation</a> </Col>

                           </Row>
 <div style={{ padding:'20px 0px 10px', fontSize:'16px'}}> What is this ? <a href='#' style={{color:'#0000ff', textDecoration:'underline'}}> Learn more</a></div>
                          
                           </div> */}
                            </Col>

                        </Row>

                        <Row>
                            <Col span={6}></Col>
                            <Col span={12}>


                            </Col>
                        </Row>


                    </div>
                </div>
            </div>
        </div>
    );

}

export default NewsLetterShare;
