import { Col, Drawer, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";

const LeadSourceDrawer = (props) => {
  const rowGutter = [24, 16];


  const closeDrawer = () => {
    props.updateState({
        leadSourceListDrawer: false,
    });
  };

  return (
    <Drawer
      title={<FormattedMessage id={props.crmLeadSourceId ? "leads.leadForm.formMeta.leadSource.drawer.title.edit"  : "leads.leadForm.formMeta.leadSource.drawer.title.create"} />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.leadSourceListDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={()=>{
                props.updateDrawerState({submitted: true})
                if(!props.leadSourceName){
                    return
                }
                const payload = {
                    relationshipId: props.companyInfo.relationshipId,
                    crmLeadSourceId: props.crmLeadSourceId || 0,
                    leadSourceName: props.leadSourceName,
                    isActive: props.isActive || 1,
                }
                props.createLeadSource(payload, props);
                closeDrawer();
            }}
            key={1}
          />
        </>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">Source Name</div>
          <TextBox
            placeholder="Source Name"
            type="text"
            maxLength={20}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
{/* 
        <Col span={12}>
          <div className="i-label">Source Code</div>
          <TextBox
            placeholder="Source Code"
            type="text"
            maxLength={20}
            value={props.sourceCode}
            onChange={(e) => {
              props.updateState({ sourceCode: e.target.value });
            }}
          />
        </Col> */}
      </Row>
    </Drawer>
  );
};

export default LeadSourceDrawer;
