import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col } from 'antd';

//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
// import { Switch } from 'antd';
function TermAndFeeDrawer(props) {

  const { type, fee, frequency,  duration, period} = props;

  const closeDrawer = () => {
    props.updateHeaderState({
      membership: {
        addTermAndFeeDrawerVisible: false
      }
    })
  }

  const handleSubmit = (props) => {
    if (props.fee && props.frequency) {
      const payload = {
        type: props.type,
        fee: props.fee,
        frequency: props.frequency,
        duration: props.duration,
        period: props.period,
        isLifetime: props.isLifetime ? 1 : 0,
        relationshipId: props.relationshipId,
        feesId: props.feesId,
        version: props.version,
        dateCreated: props.dateCreated
      };

      props.pushModalToStack({
        modalBody: props.feesId ? <FormattedMessage id='commons.edit.popup.confimation.msg' defaultMessage='' /> : <FormattedMessage id='membership.termAndFee.addDrawer.confitmation' defaultMessage='' />,
        handleSubmit: () => {
          props.saveFees(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-title">

            <FormattedMessage id='membership.termAndFee.addDrawer.drawerName' defaultMessage=' ' />
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-supplier-drawer"
        visible={props.addTermAndFeeDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
      >
        <Form layout="vertical" hideRequiredMark>


          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='membership.termAndFee.addDrawer.fee' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12} className='input-currency-control' style={{ padding: '0px 17px 0px 0px', marginLeft: '8px' }}>
              <span className="currency-tag">
                {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
              </span>
              <TextBox
                type='number'
                className={(props.submittedOnce && (props.fee <= 0 || !props.fee)) ? 'input-text-error' : ''}
                value={fee || null}
                onChange={(e) => {
                  props.updateState({
                    fee: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'membership.termAndFee.addDrawer.fee', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.fee }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>

          <br />


          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <label>
                <FormattedMessage id='membership.termAndFee.addDrawer.frequency' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <Dropdown
                className={(props.submittedOnce && !props.frequency) ? 'input-text-error' : ''}
                items={[{ val: 'monthly', displayName: 'Monthly' }, { val: 'yearly', displayName: 'Yearly' }, { val: 'weekly', displayName: 'Weekly' }, { val: 'daily', displayName: 'Daily' }, { val: 'onetime', displayName: 'Onetime' }]}
                valueKeyName='displayName'
                optionKeyName='val'
                value={frequency}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'membership.termAndFee.addDrawer.frequency',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      frequency: option.name,
                      stmtFreq: option.name === 'Daily' ? 'Day' : (option.name).split('ly')[0],
                    })
                  }
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.frequency }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>

          </Row>

          <br />

          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='membership.termAndFee.addDrawer.duration' defaultMessage='' /></span>
                {/* <span className="required">*</span> */}

            </Col>
            <Col span={12}>
              <TextBox
               // className={(props.submittedOnce && (props.duration <= 0 || !props.duration)) ? 'input-text-error' : ''}
                type='number'
                disabled={props.frequency === 'Onetime'}
                value={duration || null}
                onChange={(e) => {
                  props.updateState({
                    duration: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'membership.termAndFee.addDrawer.duration',
                    defaultMessage: ''
                  })
                }
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.duration }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>

          </Row>
          <br />
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <label>
                <FormattedMessage id='membership.termAndFee.addDrawer.type' defaultMessage='' /></label>
              {/* <span className="required">*</span> */}
            </Col>
            <Col span={12}>
              <Dropdown
                //className={(props.submittedOnce && !props.type) ? 'input-text-error' : ''}
                items={[{ val: 'new', displayName: 'New' }, { val: 'renew', displayName: 'Renew' }]}
                valueKeyName='displayName'
                optionKeyName='val'
                disabled={frequency === 'Onetime'}
                value={type}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'membership.termAndFee.addDrawer.type',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      type: option.name
                    })
                  }
                }
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || props.type }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <label><FormattedMessage id='membership.termAndFee.addDrawer.period' defaultMessage='' /></label>
              {/* <span className="required">*</span> */}
            </Col>
            <Col span={12}>
              <Dropdown
               // className={(props.submittedOnce && !props.period) ? 'input-text-error' : ''}
                items={[{ val: 'week', displayName: 'Week' }, { val: 'month', displayName: 'Month' }, { val: 'year', displayName: 'Year' }]}
                valueKeyName='displayName'
                optionKeyName='val'
                disabled={frequency === 'Onetime'}
                value={period}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'membership.termAndFee.addDrawer.period',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      period: option.name,
                    })
                  }
                }
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.period }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>
          </Row>
          
          {/* <br />
          <Row gutter={16}>

            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className=""> <FormattedMessage id='membership.termAndFee.addDrawer.lifetime' defaultMessage='' /></span>
            </Col>
            <Col span={12}>
              <Switch
                checked={isLifetime || false}
                onChange={(change) => {
                  props.updateState({
                    isLifetime: change,
                  })
                }} />
            </Col>
          </Row> */}
          <br />
          <Row>
            <Col span={9}></Col>
            <Col span={14} style={{ padding: '0px 0px 0px 6px', fontWeight: '600' }}>  {(props.fee && props.stmtFreq ) ? `$${props.fee}/${props.stmtFreq} ${props.duration ? 'for '+props.duration : ''} ${props.period || ''}  ${props.type?'- '+props.type:''}` : ''}
            </Col>
          </Row> <br />
        </Form>

        <div className='form-footer'>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.submit'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </div>
      </Drawer>
    </Fragment>
  );
}

export default injectIntl(TermAndFeeDrawer);
