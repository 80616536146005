import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tabs, Button, Skeleton, Result, Typography } from 'antd';
import { getInitialsFromString } from '../../../../../utils';
import DocumentListing from './Document';
import EducationListing from './Education';
import EmploymentListing from './Employment';
import Information from './SideComp/information';
import ContactInfo from './SideComp/Contact';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Paragraph, Text } = Typography;
const { TabPane } = Tabs;

function EmployeeProfile(props) {
    const { employee, intl } = props;
    const getAddressInfoFragment = (obj) => {
        return (
            <div className="billing-address first-caps">
                {obj.streetAddress1 || obj.streetAddress2 ? (
                    <div>
                        {obj.streetAddress1} {obj.streetAddress2}
                    </div>
                ) : ""}
                {obj.cityName || obj.stateName || obj.zipCode ? (
                    <div>
                        {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                    </div>
                ) : ""}
                {obj.countryName ? (
                    <div>{obj.countryName}</div>
                ) : ""}
            </div>
        );
    };
    return (
        <>
            <div className="txn-header">
                <div className="left-itms">
                    <ul>
                        <li className="title">Employee Details </li>
                    </ul>
                </div>
            </div>
            <div className="profile-container">
                <div className="sidenav">
                    <div className="sidenav-top">
                        <Skeleton loading={props.detailLoading} active paragraph={{ rows: 3 }}>

                            <div className="top-view">
                                <div className="view1">
                                    <div className="name-icon">
                                        {employee?.firstName
                                            ? getInitialsFromString(employee.firstName)
                                            : "USER"}
                                    </div>
                                    <div>
                                        <div className="profile-name">
                                            {employee?.fullName}

                                        </div>
                                        <div>
                                            {employee?.emailAddress ||
                                                <div className="empty-data-box"></div>}
                                        </div>
                                    </div>
                                </div>


                                <div className="addressBar">
                                    <div className="addressIcon"><i className="fi fi-rs-marker" /></div>
                                    {(Object.keys(employee?.locationList || {}) || []).length ? (
                                        <>
                                            <div className="address-data">
                                                <div>{getAddressInfoFragment(employee.locationList?.[0])}</div>
                                                {/* <Button
            className="edit-btn-rf"
            type="primary"
            ghost
            style={{ color: "#1890ff" }}
            onClick={() => {
                props.updateHeaderState({
                    address: {
                        addressDrawer: true,
                        formData: {
                            isUpdate: true,
                            ...employee.primaryAddress,
                            isDefault: "Yes",
                            supplierId: props.supplierId,
                        },
                    },
                });
            }}
        >
            <EditOutlined style={{ color: "#1890ff" }} />
            <span style={{ color: "#1890ff" }}>Edit</span>
        </Button> */}
                                            </div>
                                        </>
                                    ) : (

                                        <div className="address-data">
                                            <div>
                                                <div className="grey-box"></div>
                                                <div className="grey-box"></div>
                                            </div>

                                            {/* <Button
            className="edit-btn-rf"
            type="primary"
            ghost
            style={{ color: "#1890ff" }}
            onClick={() => {
                props.updateHeaderState({
                    address: {
                        addressDrawer: true,
                        formData: {
                            isUpdate: false,
                            isDefault: "Yes",
                            supplierId: props.supplierId,
                        },
                    },
                });
            }}
        >
            <EditOutlined style={{ color: "#1890ff" }} />
            <span style={{ color: "#1890ff" }}>Add</span>
        </Button> */}

                                        </div>


                                    )}

                                </div>
                            </div>
                        </Skeleton>
                    </div>

                    <div className="sidenav-bottom">
                        <div className="tabs-info">
                            <Tabs type="line" className="custom-tab-st" animated={false}>
                                <TabPane tab="Information" key="1">
                                    <Information {...employee} {...props} />
                                </TabPane>
                                <TabPane tab="Contact" key="2">
                                    {/* {...customerObj.primaryContact}  */}
                                    <ContactInfo {...employee} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
                <div
                    className="leftnav"

                >
                    {
                        !props.relationshipId && !props.relationshipEmployeeId ?
                            <div className='error-status'>

                                <Result
                                    status="error"
                                    title="Error Occured"
                                >
                                </Result>
                            </div> 
                            
                            :
                            <Tabs
                                className="primary-tab-container secondary-tab"
                                onChange={(key) => {
                                    // callback(key, props);
                                }}
                                type="line"
                                animated={false}
                            >


                                <TabPane
                                    tab={intl.formatMessage({
                                        id: "drawer.employeeMangement.tabNames.education",
                                        defaultMessage: "",
                                    })}
                                    key="1"
                                >
                                    <EducationListing {...props} />
                                </TabPane>


                                <TabPane
                                    tab={intl.formatMessage({
                                        id: "drawer.employeeMangement.tabNames.employment",
                                        defaultMessage: "",
                                    })}
                                    key="2"
                                >
                                    <EmploymentListing {...props} />
                                </TabPane>


                                <TabPane
                                    className="alert-tab-section"
                                    tab={intl.formatMessage({
                                        id: "drawer.employeeMangement.tabNames.document",
                                        defaultMessage: "",
                                    })}
                                    key="3"
                                >
                                    <DocumentListing {...props} />
                                </TabPane>
                            </Tabs>
                    }
                </div>
            </div>

        </>
    )
}
export default injectIntl(EmployeeProfile);
