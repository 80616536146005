import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import PageBreadcrumb from "../../PageBreadcrumb";
// import DropdownAction from "antd/lib/dropdown";
import { CONSTANTS, ICONS } from "../../../static/constants";
import { MultipleSelectDropdown } from "../../general/MultipleSelectDropdown";
import { Dropdown } from "../../general/Dropdown";
import moment from "moment-timezone";
import {
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  Row,
  Col,
  Radio,
  Space,
  Divider,
  DatePicker, 
  TimePicker,
  Tabs
} from "antd";
import {

  getMomentDateForUIReadOnly,
  getCurrentDateForBackend,
  getLocalDateFromUtcStr
} from "../../../utils";
import LeadsTable from "./LeadsTable";
import { TextBox } from "../../general/TextBox";
import { TextArea } from "../../general/TextAreaComponent";
//import { ReactComponent as PhoneCall } from "../../../assets/images/phone-call.svg";
// import { ReactComponent as Pencil } from "../../../assets/images/pencil.svg";
// import { ReactComponent as WhatsApp } from "../../../assets/images/whatsapp.svg";
import AllLeadsModal from "../../../containers/modal/modalBody/leads/createLead/index";
import * as filter from 'lodash.filter';
import * as find from "lodash.find";
// import ReminderList from './reminderList';


const { TabPane } = Tabs;

const AllLeads = (props) => {
  const {
    activeRowData = {},
  } = props;
  
  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItems.Leads" defaultMessage="" />,
    },
    {
      name: !props.isMyLeads ? <FormattedMessage id="sidebar.menuItems.AllLeads" defaultMessage="" /> : 'My Leads',
    },
  ];
  const [showReminder, setShowReminder] = useState(1);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [title, setTitle] = useState("");

const createNotesSubmit = () =>{
    if(!props.notesContent){
      props.updateState({
        errMsg: true
      })
      return
    }
    const payload = {
        relationshipId: props.companyInfo.relationshipId,
        crmLeadId: props.activeRowData?.crmLeadId,
        content: props.notesContent,
        crmLeadNotesId: props.crmLeadNotesId,
        createdByEmpId: props.userInfo.relationshipEmployeeId, 
        updatedByEmpId: props.userInfo.relationshipEmployeeId
    }
    props.saveOrUpdateNotes(payload, props);
   
}

const createReminderSubmit = () =>{
  let dateAndTime = selectedDate;
  if(selectedDate && selectedTime){
     dateAndTime.set({"hour": selectedTime.get('hour'), "minute": selectedTime.get('minute'), "second": selectedTime.get('second')});
  }
  const payload = {
      relationshipId: props.companyInfo.relationshipId,
      crmLeadId: props.activeRowData?.crmLeadId,
      remiderDateAndTime: getCurrentDateForBackend(dateAndTime),
      reminderTitle:title,
      createdByEmpId: props.userInfo.relationshipEmployeeId, 
      updatedByEmpId: props.userInfo.relationshipEmployeeId
  }
  props.saveOrUpdateReminder(payload, props);
  setShowReminder(1);
  setSelectedTime(null);
  setSelectedDate(null);
}

const handleRadioChange = (e) => {
  setShowReminder(e.target.value);
};


const getLabelValue = (item, label) => {
  if (!item || !item.crmLeadDetailsList || !item.crmLeadDetailsList.length || !label) {
    return '-';
  } else {
    const lblObj = find(item.crmLeadDetailsList, { label: label }) || {};
      return lblObj.value || '-';
  }
};

// function formatDate(date) {
//   const options = {
//     weekday: 'ddd',
//     day: 'DD',
//     month: 'MMM',
//     year: 'YYYY',
//     hour: 'HH',
//     minute: 'mm',
//     second: 'ss',
//   };
//   return date.format(options);
// }

// function isTimeDifferenceGreaterThan30Seconds(providedDate) {
//   // Get the current time as a moment object
//   const currentDate = moment();

//   // Calculate the time difference in milliseconds
//   const timeDifference = currentDate - providedDate;

//   // Convert the time difference to seconds
//   const timeDifferenceInSeconds = timeDifference / 1000;

//   if (timeDifferenceInSeconds > 30) {
//     // If the time difference is greater than 30 seconds, return the formatted date
//     return formatDate(moment(providedDate));
//   } else {
//     // Otherwise, return "less than 30 sec ago"
//     return "less than 30 sec ago";
//   }
// }

const findReminder = (e) =>{
  // for(let i=0; i<(props.allLeadReminderList || []).length; i++){
  //   if(props.allLeadReminderList[i].crmLeadId === e.crmLeadId){
  //     return props.allLeadReminderList[i];
  //   }
  // }
  return props.allLeadReminderList.find(obj => obj.crmLeadId === e.crmLeadId)||{};
}

const isOldDate = (pDateTime) =>{
  const currentDateTime = moment.utc();
  const providedDateTime = moment.utc(pDateTime, CONSTANTS.BACKEND_FORMAT);
  if(providedDateTime.isBefore(currentDateTime)) {
    return true;
  } 
  return false;
}
const callTabApi = (key, props) => {
  const payload = {
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: 1,
    pageSize: 25,
  };

  if (key === 'all_leads') {
   // will do something
   props.updateState({
    isMyLeads:false,
   })
  } else if (key === 'my_leads') {
   //will do something
   props.updateState({
    isMyLeads:true,
   })
  } 


}
  return (
    <div className={props.activeRowData?.crmLeadId  ? "main-page" : ""}>
      <div className={props.activeRowData?.crmLeadId  ? "main-lead-list-page" : ""}>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="sidebar.menuItems.Leads"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
            <button className="create-action-btn" onClick={() => {
              props.pushModalToStack({
                modalBody: <AllLeadsModal {...props}/>,
                width: '100%',
                hideTitle: true,
                hideFooter: true,
                wrapClassName: 'new-transaction-wrapper',
                keyboard: false,
                alertCancel: true,
                onCancel: () => {
                  props.showModal({
                    modalBody: 'All changes will be erased. Are you sure want to close?',
                    handleSubmit: () => {
                      props.popModalFromStack();
                    }
                  })
                }
              })
            }}>
              <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>
        <div className="view-container">
        <Tabs type="line"
            onChange={(key) => {
              callTabApi(key, props);
            }}
          >
            <TabPane
              tab="All Leads"
              key="all_leads"
            >
                      <LeadsTable {...props} />

            </TabPane>
            <TabPane
              tab="MY Leads"
              key="my_leads"
            >
                        <LeadsTable {...props}   />

            </TabPane>
            
          </Tabs>
        </div>
      </div>
      {props.activeRowData?.crmLeadId  ? (
        <div className="side-drawer">
          <div className="side-Wrapper">
            <div className="side-header">
              <h5>{getLabelValue(activeRowData, 'Full Name')}</h5>
              <button onClick={()=>{  props.updateState({ activeRowData: null }); }} className="closebtn">
                <CloseOutlined />
              </button>
            </div>

            <div className="date-wrapper">
              <p className="date">{activeRowData?.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(activeRowData.dateCreated), format: CONSTANTS.TABLE_DATE_FORMAT }) : ''}</p>
              <p className="paid">{activeRowData.leadSourceName}</p>
            </div>

            <div className="label-wrapper">
              <p className="phone-no">
              
                 {getLabelValue(activeRowData,'Mobile No.')}
              </p>
            </div>
          </div>

          <div className="divider mb0"></div>

          <div className="side-Wrapper">
            <div className="lead-management">
              <div className="lead_sub_head">Lead Management</div>
                <p className="light-txt">
                  Manage Lead Progression & Ownership
                </p>

                <div className="lead_sub_head mt5 mb5">Status</div>
                  <Dropdown
                    style={{minWidth: '100%'}}
                    items={props.crmLeadStatusList}
                    placeholder={'Select Status'}
                    valueKeyName='statusName'
                    optionKeyName='leadStatusId'
                    value={activeRowData?.statusName || ''}
                    onSelect={(id, options)=>{
                      let payload = activeRowData;
                      payload.statusId = options.key;
                      payload.statusName = options.name;
                      payload.relEmpId= props.userInfo.relationshipEmployeeId
                      props.changeLeadStatus(payload, props)
                    }}
                  />
                <div>
                <div className="lead_sub_head mt10 mb5">Assign to</div>
                  <MultipleSelectDropdown
                    style={{minWidth: '100%', maxHeight: '38px'}}
                    items={props.leadAssignList || []}
                    placeholder={'Assign to'}
                    mode='multiple'
                    allowClear={false}
                    // onDeSelect={(empId, options) => {
                    //     let tempList = props.selectedAssignList || [];
                    //     const empExits = tempList.find((obj)=> obj.relEmployeeId === options.key)
                    //     if(empExits){
                    //         tempList = tempList.filter((obj) => obj.relEmployeeId !== options.key);
                    //         props.updateState({selectedAssignList: tempList});
                    //     }
                    // }}
                    selectedValue={(props.activeRowData?.crmLeadEmpAssignedList || []).map((obj) => {return obj.relEmpId})}
                    valueKeyName='fullName'
                    valueKeyName2='hierarchyName'
                    optionKeyName='relationshipEmployeeId'
                    onSelect={(empId, options) => {
                      let tempList = [];
                      tempList.push({relEmpId: Number(options.key), relationshipId: props.companyInfo.relationshipId, crmLeadId: props.activeRowData.crmLeadId});
                      props.changeCrmLeadEmpAssignedList(tempList, props);
                    }}
                  />
              </div>

              {!Object.keys(findReminder(props.activeRowData)).length ? <>
              
                <div className="reminder-wrap">
                  <div className="lead_sub_head mt10 mb5">Reminder</div>
                  <Radio.Group
                    onChange={handleRadioChange}
                    value={showReminder}
                  >
                    <Space direction="vertical">
                      <Radio value={1}>No reminder</Radio>
                      <Radio value={2}>Schedule a reminder</Radio>
                    </Space>
                  </Radio.Group>
                </div>

                {showReminder === 2 && (
                  <div className="reminder-section">
                    <div className="title-wrap" style={{ marginTop: "15px" }}>
                      <label className="input-label">Title</label>
                      <TextBox onChange={(e)=>{setTitle(e.target.value);}} />
                    </div>

                    <div style={{ marginTop: "15px" }}>
                      <label className="input-label">Select Time</label>
                      <div className="datetime-picker-container">
                        <DatePicker
                          placeholder="Select date and time"
                          disabledDate={(current)=>{return current && current < moment().startOf('day');}}
                          onChange={(time)=>{setSelectedDate(time)}}
                          value={selectedDate}
                        />
                        <TimePicker
                          placeholder="Select time"
                          format='HH:mm'
                          onChange={(time)=>{setSelectedTime(time);}}
                          value={selectedTime}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginTop: "15px",
                          gap: "10px",
                        }}
                      >
                        <Button on onClick={()=>{setShowReminder(1)}}>
                          Cancel
                        </Button>
                        <Button className="ant-btn-primary" onClick={createReminderSubmit}>
                          <FormattedMessage
                            id="lead.save.button"
                            defaultMessage=""
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                </>
                :
                <div className="reminder-wrap">
                  <div className="lead_sub_head mt10 mb5">Reminder</div>
                  <div className="note-text">
                    <Row>
                      <Col span={20}>
                        {findReminder(props.activeRowData).reminderTitle || '-'}
                      </Col>
                      <Col span={2}>
                        <i className="fi fi-rr-pencil pointer" onClick={()=>{
                          setShowReminder(2);
                          setTitle(findReminder(props.activeRowData).reminderTitle)
                        }}/>
                      </Col>
                      <Col span={2}>
                        <i className="fi fi-rr-trash pointer" onClick={()=>{
                          props.showModal({
                            title: 'Confirmation',
                            modalBody: 'Are you sure to delete reminder?',
                            handleSubmit: ()=>{
                              props.deleteLeadReminder(findReminder(props.activeRowData), props)
                            }
                          })
                        }}></i>
                      </Col>
                      <Col span={24}>
                        <div className="required">{!isOldDate(findReminder(props.activeRowData).remiderDateAndTime) ? 'due' : 'expired'} on {getLocalDateFromUtcStr({date: findReminder(props.activeRowData).remiderDateAndTime, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT})}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              }


              <div className="note-wrap">
                <div className="lead_sub_head mt10">Notes</div>

                <p className="light-txt" style={{lineHeight: '1.2em', marginTop: '2px !important'}}>
                  Notes help you remember important details about your leads.
                </p>
                <TextArea
                  className="textAreaStyle mt10"
                  placeholder="Write a note..."
                  value={props.notesContent}
                  onChange={(e)=>{
                    props.updateState({
                        notesContent: e.target.value,
                        errMsg: false
                    })
                }}
                />
                {props.errMsg ? <div className="required">Please enter content</div> : ''}
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button type="primary" className="mb10" onClick={createNotesSubmit}>
                    <FormattedMessage
                      id="lead.save.button"
                      defaultMessage=""
                    />
                  </Button>
                </div>

                {( (props.allLeadNotesList && filter(props.allLeadNotesList, { crmLeadId: props.activeRowData?.crmLeadId }).sort((a, b)=> a.dateCreated < b.dateCreated ? 1 : -1)) || []).map((e, i) => {
                  return <>
                    <div className="light-txt line-view">
                      {/* {isTimeDifferenceGreaterThan30Seconds(? 'xxx':'yy'} */}
                      {e.dateCreated ? getLocalDateFromUtcStr({date: e.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}): '-'} 
                      <span className="pointer ml15" onClick={()=>{
                        props.updateState({
                          notesContent: e.content,
                          crmLeadNotesId: e.crmLeadNotesId
                        })
                      }}>
                        Edit
                      </span>
                      <span className="pointer ml15" onClick={()=>{
                        props.showModal({
                          title: 'Confirmation',
                          modalBody: 'Do you want to delete this note?',
                          handleSubmit: ()=>{
                            props.deleteCrmNotes(e, props);

                          }
                        })
                      }}>
                        Delete
                      </span>
                    </div>
                    <p className="note-text">
                      {e.content}
                    </p>
                    <Divider className="mt5 mb5" />
                  </>
                })
                }
              </div>

            </div>
          </div>
        </div>
      )
      :
      ''
    }
    </div>
  );
};

export default injectIntl(AllLeads);
