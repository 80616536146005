import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PublishedStockComp from '../../../components/ecommerce/PublishedStock';
import { exportExcel,
  fetchPublishedProducts, getPublishedProductsCount, resetPublishedProductsPage, 
  fetchUnpublishedProducts, getUnpublishedProductsCount, resetUnpublishedProductsPage,
  fetchClearanceProducts, getClearanceProductsCount, resetClearanceProductsPage,
  fetchDealOfTheDayProducts, getDealOfTheDayProductsCount, resetDealOfTheDayProductsPage,
  fetchNewArrivalProducts, getNewArrivalProductsCount, resetNewArrivalProductsPage,
  fetchBestSellingProducts, getBestSellingProductsCount, resetBestSellingProductsPage,
  updatePublisedItem, updateClearanceItem, updateDealOfTheDayItem, updateNewArrivalItem, updateBestSellingItem , updateOnSaleItem ,fetchOnSaleProducts ,getOnSaleProductsCount,resetOnSaleProductsPage
 } from './action';
 
import { fetchProducts, getProductCount, resetPaginatedData } from '../../product/Listing/action';
import { fetchWarehouseNames } from '../../drawer/inventory/action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';

class PublishedStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 100,
      searchedText: '',
      invoiceReminderList: [],
      selectedItems: []
    };
  }

  componentWillReceiveProps(props) {
    
  }

  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      userId: (this.props.userInfo || {}).userId,
      pageSize: 100
    };

    fetchPaginationDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('getProductCount', 'productCount', this.props, payload);
    fetchDataIfNeeded('fetchWarehouseNames', 'allWarehouses', this.props, payload);
  }

  render() {
    return (
      <PublishedStockComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: (state.common.companyInfo|| {}).relationshipId,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions,
    products: state.product.products,
    productCount: state.product.productCount,
    publishedProducts: state.ecommerce.publishedProducts,
    publishedProductCount: state.ecommerce.publishedProductCount,
    unpublishedProducts: state.ecommerce.unpublishedProducts,
    clearanceProducts: state.ecommerce.clearanceProducts,
    dealOfTheDayProducts: state.ecommerce.dealOfTheDayProducts,
    newArrivalProducts: state.ecommerce.newArrivalProducts,
    bestSellingProducts: state.ecommerce.bestSellingProducts,
    onSaleProducts:state.ecommerce.onSaleProducts,
    unpublishedProductCount: state.ecommerce.unpublishedProductCount,
    clearanceProductCount: state.ecommerce.clearanceProductCount,
    dealOfTheDayProductCount: state.ecommerce.dealOfTheDayProductCount,
    newArrivalProductCount: state.ecommerce.newArrivalProductCount,
    bestSellingProductCount: state.ecommerce.bestSellingProductCount,
    onSaleProductCount: state.ecommerce.onSaleProductCount,
    allWarehouses: state.inventory.allWarehouses,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  exportExcel,
  fetchProducts, getProductCount, resetPaginatedData,
  fetchPublishedProducts, getPublishedProductsCount, resetPublishedProductsPage,
  fetchUnpublishedProducts, getUnpublishedProductsCount, resetUnpublishedProductsPage,
  fetchClearanceProducts, getClearanceProductsCount, resetClearanceProductsPage,
  fetchDealOfTheDayProducts, getDealOfTheDayProductsCount, resetDealOfTheDayProductsPage,
  fetchNewArrivalProducts, getNewArrivalProductsCount, resetNewArrivalProductsPage,
  fetchBestSellingProducts,fetchOnSaleProducts, getBestSellingProductsCount, getOnSaleProductsCount , resetBestSellingProductsPage,
  updatePublisedItem, 
  updateClearanceItem, 
  updateDealOfTheDayItem, 
  updateNewArrivalItem, 
  updateBestSellingItem,
  updateOnSaleItem,
  resetOnSaleProductsPage,
  fetchWarehouseNames
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PublishedStock);
