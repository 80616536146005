import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getUserDefinedName } from '../../../utils';

const ClassDetails = (props) => {
    return (
        <div className="modal-content full-width" style={{height:'500px'}}>
            <div className='table-container'>
                <table>

                <thead >
                    <tr>
                        <th><FormattedMessage id='serial.no'/></th>
                        <th>{getUserDefinedName('schoolManagement.class.code',props)}</th>
                        <th>{getUserDefinedName('schoolManagement.class.name',props)}</th>
                        <th>{getUserDefinedName('schoolManagement.section.name',props)}</th>
                        {/* <th span={8}><FormattedMessage id='schoolManagement.subject.name' /></th>
                        <th span={7}><FormattedMessage id='schoolManagement.subject.code' /></th> */}
                        {/* <th><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> */}
                    </tr>
                </thead>
                <tbody>
                {
                    (props.instructorObj.instructorClassRefList || []).map((item, i) => {
                        return (
                            <tr key={'cls' + i} >
                                <td>{i + 1}</td>
                                <td>
                                    {item.classCode || '-'}
                                </td>
                                <td>
                                    {item.className || '-'}
                                </td>
                                <td>
                                    {item.sectionName || '-'}
                                </td>
                                {/* <td span={5} >
                                    {item.subjectName}
                                </td> */}
                                {/* <td>
                                    <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                                        props.showModal({
                                            modalBody: "Are you sure you want to delete?",
                                            handleSubmit: () => {
                                                props.deleteAssignedClass(item, props);
                                                props.popModalFromStack();
                                            }
                                        })
                                    }}>
                                        <i className="fi fi-rr-trash" title={props.intl.formatMessage({ id: 'delete.text' })} />
                                    </span>
                                </td> */}
                            </tr>
                            
                        )
                    })
                }

                </tbody>
                </table>
            </div>
        </div>
    );

}

export default ClassDetails;