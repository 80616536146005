import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChangePasswordComponent from '../../../../../components/modal/modalBody/settings/ChangePassword';
import { changePassword} from './action';
import { popModalFromStack, pushModalToStack } from '../../../../../actions/commonActions';

class ChangePassword extends Component {
    constructor(props){
        super(props);
        this.state= {
            selectDocumentOptions: ['Sales Quotation', 'Request for Quote', 'Sales Order', 'Sales Invoice', 'Purchase Order', 'Purchase Invoice', 'Sales Payment', 'Purchase Payment', 'Credit Memo', 'Debit Memo'],
            docName: 'Sales Order',
            subject: ''

        }
    }
    // componentWillMount() {
    //     const companyInfo = this.props.companyInfo || {};
    //     //const payload = { relationshipId: companyInfo.relationshipId, docName: 'Sales Order'};
    //     //fetchDataIfNeeded('fetchRemarksTemplate', 'remarksData', this.props, payload, true);
        
    // }
    componentWillReceiveProps(nextProps) {
        if (nextProps.remarksData !== this.state.remarksData && nextProps.remarksData) {
          this.setState({ content: nextProps.remarksData.content || '' });
        }
      }

    render() {
        return <div>
            <ChangePasswordComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        remarksData: state.settings.remarksData,
        userInfo: state.common.userInfo
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    changePassword,
    pushModalToStack,
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);