import { SUPPLIER_ACTION_LIST, CONSTANTS } from '../static/constants';
const initialState = {
  suppliers: {
    1: []
  },
  landedCostSetupList:{
    1:[]
  },
  openOrderList: {
    1: []
  },
  unpaidInvoiceList: {
    1: []
  },
  paidInvoiceList: {
    1: []
  },
  totalOrderList: {
    1: []
  },
  transactionsList: {
    1: []
  },
  purchaseOrderTransactionsList: {
    1: []
  },
  invoiceList: {
    1: []
  },
  debitMemoList: {
    1: []
  },
  paymentList: {
    1: []
  },
  supplierCount: 0,
  supplierOpenOrderCount: 0,
  supplierOpenPOCount: 0,
  supplierUnpaidBalance: 0,
  supplierTotalPurchase: 0,
  openOrderListCount: 0,
  unpaidInvoiceListCount: 0,
  paidInvoiceListCount: 0,
  totalOrderListCount: 0,
  paymentListCount: 0,
  debitMemoListCount: 0,
  invoiceListCount: 0,
  purchaseOrderTransactionsCount: 0,
  transactionsCount: 0,
  commuincationList: [],
  addressList: [],
  taskList: [],
  activitiesList: [],
  contacts: [],
  addresses: {
    [CONSTANTS.SHIPPING_ADDRESS]: [],
    [CONSTANTS.BILLING_ADDRESS]: []
  },
  supplierLedger: [],
  supplierSummary: {
    1: []
  },
  supplierInvoiceLedgerSummaryList: [],
  landedCostList:{
    1:[]
  },
  supplierTotalOrderCount:0
}

const SupplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER_ACTION_LIST.SUPPLIERS_LIST:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          [action.data.pageNo || 1]: action.data.list
        }
      };
     
    case SUPPLIER_ACTION_LIST.SUPPLIER_COUNT:
      return { ...state, supplierCount: action.data };
    case SUPPLIER_ACTION_LIST.SUPPLIERS_PAGE_RESET:
      return { ...state, suppliers: initialState.suppliers };
    case SUPPLIER_ACTION_LIST.SUPPLIER_TOTAL_ORDER_LIST_PAGE_RESET:
      return { ...state, totalOrderList: initialState.totalOrderList };
    case SUPPLIER_ACTION_LIST.SUPPLIER_PAID_INVOICES_BY_SUPPLIER_RESET:
      return { ...state, paidInvoiceList: initialState.paidInvoiceList };
    case SUPPLIER_ACTION_LIST.SUPPLIER_UNPAID_INVOICES_BY_SUPPLIER_RESET:
      return { ...state, unpaidInvoiceList: initialState.unpaidInvoiceList };
    case SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_ORDER_PAGE_RESET:
      return { ...state, openOrderList: initialState.openOrderList };
    case SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_ORDER_COUNT:
      return { ...state, supplierOpenOrderCount: action.data }
    case SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_PO_COUNT:
      return { ...state, supplierOpenPOCount: action.data }
    case SUPPLIER_ACTION_LIST.SUPPLIER_UNPAID_BALANCE:
      return { ...state, supplierUnpaidBalance: action.data }
    case SUPPLIER_ACTION_LIST.SUPPLIER_TOTAL_PURCHASE:
      return { ...state, supplierTotalPurchase: action.data }
    case SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_ORDER:
      return {
        ...state,
        openOrderList: {
          ...state.openOrderList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_ACTION_LIST.SUPPLIER_OPEN_ORDER_COUNT_BY_SUPPLIER_ID:
      return { ...state, openOrderListCount: action.data }
    case SUPPLIER_ACTION_LIST.SUPPLIER_UNPAID_INVOICES_BY_SUPPLIER:
      return {
        ...state,
        unpaidInvoiceList: {
          ...state.unpaidInvoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_ACTION_LIST.SUPPLIER_UNPAID_INVOICES_COUNT_BY_SUPPLIER:
      return { ...state, unpaidInvoiceListCount: action.data }
    case SUPPLIER_ACTION_LIST.SUPPLIER_PAID_INVOICES_BY_SUPPLIER:
      return {
        ...state,
        paidInvoiceList: {
          ...state.paidInvoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_ACTION_LIST.SUPPLIER_PAID_INVOICES_COUNT_BY_SUPPLIER:
      return { ...state, paidInvoiceListCount: action.data }
    case SUPPLIER_ACTION_LIST.SUPPLIER_TOTAL_ORDER:
      return {
        ...state,
        totalOrderList: {
          ...state.totalOrderList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_ACTION_LIST.SUPPLIER_TOTAL_ORDER_COUNT_BY_R_ID:
      return { ...state, totalOrderListCount: action.data }
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_TRANSACTIONS:
      return {
        ...state, transactionsList: {
          ...state.transactionsList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_TRANSACTIONS_COUNT:
      return { ...state, transactionsCount: action.data };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PURCHASE_ORDER_TRANSACTIONS:
      return {
        ...state, purchaseOrderTransactionsList: {
          ...state.purchaseOrderTransactionsList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PURCHASE_ORDER_TRANSACTIONS_COUNT:
      return { ...state, purchaseOrderTransactionsCount: action.data };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_INVOICE_LIST:
      return {
        ...state, invoiceList: {
          ...state.invoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_INVOICE_LIST_COUNT:
      return { ...state, invoiceListCount: action.data };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_DEBIT_MEMO_LIST:
      return {
        ...state, debitMemoList: {
          ...state.debitMemoList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_DEBIT_MEMO_LIST_COUNT:
      return { ...state, debitMemoListCount: action.data };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PAYMENT_LIST:
      return {
        ...state, paymentList: {
          ...state.paymentList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_PAYMENT_LIST_COUNT:
      return { ...state, paymentListCount: action.data };
    case SUPPLIER_ACTION_LIST.SUPPLIER_TRANSACTIONS_RESET:
      return { ...state, transactionsList: initialState.transactionsList };
    case SUPPLIER_ACTION_LIST.SUPPLIER_INVOICE_TRANSACTIONS_RESET:
      return { ...state, invoiceList: initialState.invoiceList };
    case SUPPLIER_ACTION_LIST.SUPPLIER_PAYMENT_TRANSACTIONS_RESET:
      return { ...state, paymentList: initialState.paymentList };
    case SUPPLIER_ACTION_LIST.SUPPLIER_DEBIT_MEMO_TRANSACTIONS_RESET:
      return { ...state, debitMemoList: initialState.debitMemoList };
    case SUPPLIER_ACTION_LIST.SUPPLIER_PURCHASE_ORDER_TRANSACTIONS_RESET:
      return { ...state, purchaseOrderTransactionsList: initialState.purchaseOrderTransactionsList };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_ACTIVITIES:
      return { ...state, activitiesList: action.data };
    case SUPPLIER_ACTION_LIST.SUPPLIER_DELETE_ACTIVITIES: {
        return { ...state, activitiesList: state.activitiesList.filter(x => x.activityId !== action.data.activityId) }
    }
    case SUPPLIER_ACTION_LIST.SUPPLIER_ADD_UPDATE_ACTIVITIES: {
      let updatedTaskIndex = -1;
      const activitiesList = Object.assign([], state.activitiesList);
      activitiesList.forEach((obj, index) => {
        if (obj.activityId === action.data.activityId) {
          updatedTaskIndex = index;
        }
      })
      if (updatedTaskIndex > -1) {
        activitiesList[updatedTaskIndex] = action.data;
      } else {
        activitiesList.push(action.data);
      }

      return { ...state, activitiesList: activitiesList }
    }  
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_COMMUINACTIONS:
      return { ...state, commuincationList: action.data };

    case SUPPLIER_ACTION_LIST.SUPPLIER_ADD_UPDATE_COMMUINACTIONS: {
      let updatedTaskIndex = -1;
      const commuincationList = Object.assign([], state.commuincationList);
      commuincationList.forEach((obj, index) => {
        if (obj.supplierCommunicationTxId === action.data.supplierCommunicationTxId) {
          updatedTaskIndex = index;
        }
      })
      if (updatedTaskIndex > -1) {
        commuincationList[updatedTaskIndex] = action.data;
      } else {
        commuincationList.splice(0 , 0, action.data);
      }

      return { ...state, commuincationList: commuincationList }
    }

    case SUPPLIER_ACTION_LIST.SUPPLIER_DELETE_COMMUINACTIONS: {
      return { ...state, commuincationList: state.commuincationList.filter(x => x.supplierCommunicationTxId !== action.data.supplierCommunicationTxId) }
    }  
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_TASKS:
      return { ...state, taskList: action.data };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_ADDRESSES:
      return { ...state, addressList: action.data };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_CONTACTS:
      return { ...state, contacts: action.data };
    case SUPPLIER_ACTION_LIST.ADD_UPDATE_TASK: {
      let updatedTaskIndex = -1;
      state.taskList.forEach((obj, index) => {
        if (obj.taskWidgetId === action.data.taskWidgetId) {
          updatedTaskIndex = index;
        }
      })
      if (updatedTaskIndex > -1) {
        state.taskList[updatedTaskIndex] = action.data;
      } else {
        state.taskList.splice(0 , 0, action.data);
      }

      return { ...state, taskList: Object.assign([], state.taskList) }
    }
    case SUPPLIER_ACTION_LIST.DELETE_TASK: {
      return { ...state, taskList: state.taskList.filter(x => x.taskWidgetId !== action.data.taskWidgetId) }
    }
    case SUPPLIER_ACTION_LIST.ADD_SUPPLIER_BO_LOCATION: {
      let suppliers = state.suppliers[action.formData.pageNumber] || [];
      let supplierIndex = -1;
      suppliers.forEach((supplier, index) => {
        if (supplier.supplierId === action.formData.supplierId) {
          supplierIndex = index;
        }
      })
      if (supplierIndex > -1) {
        let boLocationList = suppliers[supplierIndex].boLocationList || [];
        boLocationList.push(action.data);
        suppliers[supplierIndex].boLocationList = boLocationList;
      }
      state.suppliers[action.formData.pageNumber] = suppliers;
      return {
        ...state,
        suppliers: state.suppliers
      };
    }
    case SUPPLIER_ACTION_LIST.ADD_SUPPLIER_BO_CONTACT: {
      let suppliers = state.suppliers[action.formData.pageNumber] || [];
      let supplierIndex = -1;
      suppliers.forEach((supplier, index) => {
        if (supplier.supplierId === action.formData.supplierId) {
          supplierIndex = index;
        }
      })
      if (supplierIndex > -1) {
        let boContactList = suppliers[supplierIndex].boContactList || [];
        boContactList.push(action.data);
        suppliers[supplierIndex].boContactList = boContactList;
      }
      state.suppliers[action.formData.pageNumber] = suppliers;
      return {
        ...state,
        suppliers: state.suppliers
      };
    }
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_BILLING_ADDRESSES:
      return { ...state, addresses: { ...state.addresses, [CONSTANTS.BILLING_ADDRESS]: action.data } };
    case SUPPLIER_ACTION_LIST.SUPPLIER_FETCH_SHIPPING_ADDRESSES:
      return { ...state, addresses: { ...state.addresses, [CONSTANTS.SHIPPING_ADDRESS]: action.data } };
    case SUPPLIER_ACTION_LIST.SUPPLIER_ADD_SHIPPING_ADDRESS: {
      state.addresses[CONSTANTS.SHIPPING_ADDRESS].push({ locationId: action.data });
      return { ...state, addresses: { ...state.addresses, [CONSTANTS.SHIPPING_ADDRESS]: Object.assign([], state.addresses[CONSTANTS.SHIPPING_ADDRESS]) } };
    }
    case SUPPLIER_ACTION_LIST.SUPPLIER_ADD_BILLING_ADDRESS: {
      state.addresses[CONSTANTS.BILLING_ADDRESS].push({ locationId: action.data });
      return { ...state, addresses: { ...state.addresses, [CONSTANTS.BILLING_ADDRESS]: Object.assign([], state.addresses[CONSTANTS.BILLING_ADDRESS]) } };
    }
    case SUPPLIER_ACTION_LIST.SUPPLIER_ADD_CONTACTS: {
      state.contacts.push({ contactId: action.data });
      return { ...state, contacts: Object.assign([], state.contacts) };
    }
    case SUPPLIER_ACTION_LIST.SUPPLIER_SUPPLIER_DELETE_CONTACTS: {
      return { ...state, contacts: state.contacts.filter(x => x.contactId !== action.data.contactId) }
    }
    // case SUPPLIER_ACTION_LIST.ADD_SUPPLIER_BO_LOCATION: {
    //   let suppliers = state.suppliers[action.formData.pageNumber] || [];
    //   let supplierIndex = -1;
    //   suppliers.forEach((supplier, index) => {
    //     if (supplier.supplierId === action.formData.supplierId) {
    //       supplierIndex = index;
    //     }
    //   })
    //   if (supplierIndex > -1) {
    //     let boContactList = suppliers[supplierIndex].boContactList || [];
    //     boContactList.push(action.data);
    //     suppliers[supplierIndex].boContactList = boContactList;
    //   }
    //   state.suppliers[action.formData.pageNumber] = suppliers;
    //   return {
    //     ...state,
    //     suppliers: state.suppliers
    //   };
    // }
    case SUPPLIER_ACTION_LIST.SUPPLIER_LEDGER_LIST: {
      return { ...state, supplierLedger: action.data };
    }
    case SUPPLIER_ACTION_LIST.RESET_SUPPLIER_LEDGER_LIST:
      return { ...state, supplierLedger: initialState.supplierLedger };  
    case SUPPLIER_ACTION_LIST.SUPPLIER_SUMMARY:
      return {
        ...state,
        supplierSummary: {
          ...state.supplierSummary,
          [action.data.pageNo || 1]: action.data.list
        }
      };
      case SUPPLIER_ACTION_LIST.LANDED_COST_SETUP_LIST:
        return {
          ...state,
          landedCostSetupList: {
            ...state.landedCostSetupList,
            [action.data.pageNo || 1]: action.data.list
          }
        };

      case SUPPLIER_ACTION_LIST.LANDED_COST_LIST:
        return {
          ...state,
          landedCostList: {
            ...state.landedCostList,
            [action.data.pageNo || 1]: action.data.list
          }
        };
      
    case SUPPLIER_ACTION_LIST.SUPPLIER_INVOICE_LEDGER_SUMMARY_LIST:
      return { ...state, supplierInvoiceLedgerSummaryList: action.data }  
      case SUPPLIER_ACTION_LIST.SUPPLIER_TOTAL_ORDER_COUNT_BY_SUPPLIER_ID:
        return { ...state, supplierTotalOrderCount: action.data }
    default:
      return state;
  }
};

export default SupplierReducer;