import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { injectIntl } from 'react-intl';
import "./index.scss";
import {Row,Col} from "antd";
import Icon1 from "../../../assets/images/icon/xeroIcon.png";
import Icon2 from "../../../assets/images/icon/quickBookIcon.png";

function AccountingComp(props) {
    return (
      <div className="container">
      <Row>
        <Col span={24}>
          <div className="header">
            <h2>Shipping</h2>
            <p>Manage your company shipping</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={20} xl={12} xxl={8}>
          <div className="paymentOptionArea">
            <div className='paymentOption'>
              <div className='nameArea'>
                <img src={Icon1} alt="" />
                <h2>Xero</h2>
              </div>
              <div className='buttonArea'>
                <button className='notActiveButton'>Inprocess</button>
              </div>
              <div className='notActive'></div>
            </div>
            <div className='paymentOption'>
              <div className='nameArea'>
                <img src={Icon2} alt="" />
                <h2>Quickbook</h2>
              </div>
              <div className='buttonArea'>
                <button className='notActiveButton'>Inprocess</button>
              </div>
              <div className='notActive'></div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
    );

}

export default injectIntl(AccountingComp);