import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import "@ant-design/compatible/assets/index.css";
import {
  Drawer,
  Row,
  Col,
  Checkbox,
  DatePicker,
  TimePicker,
  Divider,
  Button,
  Skeleton,
} from "antd";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import {
  getUserDefinedName,
  getMomentDateForUI,
  getCurrentDateForBackend,
} from "../../../../utils";
import { Dropdown } from "../../../general/Dropdown";
import { CONSTANTS, ICONS } from "../../../../static/constants";


const { RangePicker } = DatePicker;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

function TimeTableDrawer(props) {
  const { classList, pageNumber = 1 } = props;
  const [scheduleObj, setScheduleObj] = useState({});
  const [sectionList, setSectionList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [recurrence, setRecurrence] = useState(["MO", "TU", "WE", "TH", "FR"]);

  const [scheduleList, setScheduleList] = useState([]);

  const closeDrawer = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addTimeTableDrawerVisible: false,
      },
    });
  };

  const addTimeTable = () => {

    if (
      !scheduleObj.classId ||
      !scheduleObj.sectionId ||
      !scheduleObj.subjectId ||
      !scheduleObj.startDate ||
      !scheduleObj.endDate ||
      !scheduleObj.startTime ||
      !scheduleObj.endTime ||
      !recurrence.length
    ) {
      props.updateState({
        submittedSchdule: true,
      });

      return;
    }

    else {
      const payload = {
        ...scheduleObj,
        relationshipId: props.companyInfo.relationshipId,
        recurrenceDays: recurrence.join(),
      };
      setScheduleList([...scheduleList, payload]);
      setScheduleObj({
        ...scheduleObj,
        subjectName: "",
        teacherName: "",
        subjectId: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        location: "",
        description: ""

      });
      props.updateState({
        submittedSchdule: false,
      });
    }
  };

  const handleSubmit = () => {
    props.updateState({
      submittedOnce: true,
    });
    if (scheduleList.length < 1) {

      return;
    }
    else {
      let arr = [];
      for (let item of scheduleList) {
        arr.push({
          ...item,
          startDate: getCurrentDateForBackend(
            item.startDate.set({
              hour: item.startTime.hours(),
              minute: item.startTime.minutes(),
              second: item.startTime.seconds(),
            })
          ),
          endDate: getCurrentDateForBackend(
            item.endDate.set({
              hour: item.endTime.hours(),
              minute: item.endTime.minutes(),
              second: item.endTime.seconds(),
            })
          ),
        })

      }
      props.pushModalToStack({
        modalBody: <FormattedMessage id='modal.commmon.submit' defaultMessage='' />,
        handleSubmit: () => {
          props.saveTimeTable(arr, props);
        }
      });
     
    }
  };

  const handleWeekends = (dayCode) => {
    const idx = recurrence.indexOf(dayCode);
    if (idx > -1) {
      let tempArr = recurrence;
      tempArr.splice(idx, 1);
      setRecurrence(tempArr);
    } else {
      let tempArr = recurrence;
      tempArr.push(dayCode);
      setRecurrence(tempArr);
    }
  };

  return (
    <Drawer
      title="Create Time Table"
      width={720}
      keyboard={false}
      zIndex={1}
      destroyOnClose={true}
      onClose={closeDrawer}
      maskClosable={false}
      className="custom-drawer"
      visible={props.schoolDrawers.addTimeTableDrawerVisible}
      afterVisibleChange={(visible) => {
        if (visible) {
          //props.initCalls();
        }
      }}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            disabled={!scheduleList.length}
            onClick={() => handleSubmit()}
            key={1}
          />
        </>
      }
    >
      <Skeleton loading={props.listLoading} paragraph={{ rows: 14 }} active>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <label>
              {getUserDefinedName("Class", props)}
              <span className="required">*</span>
            </label>
            <Dropdown
              disabled={scheduleList?.length}
              items={classList[pageNumber || 1] || []}
              optionKeyName="classId"
              valueKeyName="className"
              valueKeyName2="courseName"
              value={scheduleObj.classId}
              placeholder={getUserDefinedName("Select Class", props)}
              onSearch={false}
              onSelect={(selectedValue, option) => {
                setScheduleObj({
                  ...scheduleObj,
                  classId: selectedValue,
                  className: option.name,
                  sectionId: "",
                  sectionName: "",
                  subjectId: "",
                  subjectName: "",
                });
                const tempList = classList[pageNumber || 1].find(
                  (obj) => obj.classId === selectedValue
                );
                setSectionList(tempList.classSectionList);
                setSubjectList(tempList.subjectList);
                props.updateState({
                  classTeacherList:tempList.teachersName
                })
                if (tempList.classSectionList.length === 1) {
                  setScheduleObj({
                    ...scheduleObj,
                    classId: selectedValue,
                    className: option.name,
                    sectionId: tempList.classSectionList[0].classSectionId,
                    sectionName: tempList.classSectionList[0].sectionName,
                    subjectId: "",
                    subjectName: "",
                  })
                }
              }}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || scheduleObj.className;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>

          {
            (sectionList.length === 1 && sectionList[0].sectionName === scheduleObj.className) || sectionList.length === 0 ? ''
              :
              <Col span={12}>
                <label>
                  Section<span className="required">*</span>
                </label>
                <Dropdown
                  disabled={scheduleList?.length}
                  items={sectionList || []}
                  optionKeyName="classSectionId"
                  valueKeyName="sectionName"
                  value={scheduleObj.sectionName}
                  placeholder={"Select Section"}
                  onSearch={false}
                  onSelect={(selectedValue, option) => {
                    setScheduleObj({
                      ...scheduleObj,
                      sectionId: selectedValue,
                      sectionName: option.name,
                    });
                  }}
                />
                <ErrorMsg
                  validator={() => {
                    return !props.submittedOnce || scheduleObj.sectionName;
                  }}
                  message={props.intl.formatMessage({
                    id: "requiredField.missing.msg",
                    defaultMessage: "",
                  })}
                />
              </Col>
          }

          {scheduleList?.length ? <>
            <Divider />
            <Col span={24}>
              <Row>
                <Col span={4}>
                  <b>Time</b>
                </Col>
                <Col span={4}>
                  <b>Subject</b>
                </Col>
                <Col span={5}>
                  <b>Teacher</b>
                </Col>
                <Col span={5}>
                  <b>Location</b>
                </Col>
                <Col span={4}>
                  <b>Schedule Days</b>
                </Col>
                <Col span={2}>
                  <b>Action</b>
                </Col>
              </Row>
              <Divider className="mb0 mt0" />
            </Col>
            {scheduleList.map((item, idx) => {
              return <>
                <Col span={4} key={idx + 'k'}>
                  {item.startTime.format("HH:mm")} - {item.endTime.format("HH:mm")}
                </Col>
                <Col span={4} key={idx + 'i'}>
                  {item.subjectName}
                </Col>
                <Col span={5} key={idx + 'j'}>
                  {item.teacherName || '-'}
                </Col>
                <Col span={5} key={idx + 'l'}>
                  {item.location || '-'}
                </Col>
                <Col span={4}>
                  {item.recurrenceDays || '-'}
                </Col>
                <Col span={2} key={idx + 'm'}>
                  <i
                    className={ICONS.DELETE_P}
                    onClick={() => {
                      let arr = scheduleList;
                      arr.splice(idx, 1);
                      setScheduleList(arr);
                    }}
                  />
                </Col>
              </>
            })}
          </>
            :
            ''
          }

          <Divider />

          <Col span={12}>
            <label>
              Subject<span className="required">*</span>
            </label>
            <Dropdown
              items={subjectList || []}
              optionKeyName="subjectId"
              valueKeyName="subjectName"
              value={scheduleObj.subjectName}
              placeholder={"Select Subject"}
              onSearch={false}
              onSelect={(selectedValue, option) => {
                setScheduleObj({
                  ...scheduleObj,
                  subjectId: selectedValue,
                  subjectName: option.name,
                });
              }}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedSchdule || scheduleObj.subjectName;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>

          <Col span={12}>
            <label>
              Teacher<span className="required">*</span>
            </label>
            <Dropdown
              items={props.classTeacherList || []}
              optionKeyName="relationshipEmployeeId"
              valueKeyName="fullName"
              value={scheduleObj.teacherName}
              placeholder={"Select Section"}
              onSearch={false}
              onSelect={(selectedValue, option) => {
                setScheduleObj({
                  ...scheduleObj,
                  teacherId: selectedValue,
                  teacherName: option.name,
                });
              }}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedSchdule || scheduleObj.sectionName;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>

          <Col span={12}>
            <label>
              Schedule Date<span className="required">*</span>
            </label>
            <RangePicker
              allowEmpty={true}
              format={format}
              placeholder={["Start date", "End date"]}
              value={[
                scheduleObj.startDate
                  ? getMomentDateForUI({
                    date: new Date(scheduleObj.startDate),
                    format,
                  })
                  : null,
                scheduleObj.endDate
                  ? getMomentDateForUI({
                    date: new Date(scheduleObj.endDate),
                    format,
                  })
                  : null,
              ]}
              onChange={(dates) => {
                if (dates) {
                  setScheduleObj({
                    ...scheduleObj,
                    startDate: dates[0],
                    endDate: dates[1],
                  });
                } else {
                  setScheduleObj({
                    ...scheduleObj,
                    startDate: "",
                    endDate: "",
                  });
                }
              }}
            />
            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedSchdule ||
                  scheduleObj.startDate ||
                  scheduleObj.endDate
                );
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>

          <Col span={12}>
            <label>
              Schedule Time<span className="required">*</span>
            </label>
            <TimePicker.RangePicker
              placeholder={["Start Time", "End Time"]}
              format={'HH:mm'}
              value={[
                scheduleObj.startTime
                  ? getMomentDateForUI({
                    date: new Date(scheduleObj.startTime),
                    format,
                  })
                  : null,
                scheduleObj.endTime
                  ? getMomentDateForUI({
                    date: new Date(scheduleObj.endTime),
                    format,
                  })
                  : null,
              ]}
              onChange={(times, x) => {
                if (times) {
                  setScheduleObj({
                    ...scheduleObj,
                    startTime: times[0],
                    endTime: times[1],
                  });
                } else {
                  setScheduleObj({
                    ...scheduleObj,
                    startTime: "",
                    endTime: "",
                  });
                }
              }}
            />
            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedSchdule ||
                  scheduleObj.startTime ||
                  scheduleObj.endTime
                );
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>

          <Col span={12}>
            <label>Location</label>
            <TextBox
              value={scheduleObj.location}
              placeholder="Location"
              onChange={(e) => {
                setScheduleObj({ ...scheduleObj, location: e.target.value });
              }}
            />
          </Col>

          <Col span={12}>
            <label>Description</label>
            <TextBox
              value={scheduleObj.description}
              placeholder="Description"
              onChange={(e) => {
                setScheduleObj({ ...scheduleObj, description: e.target.value });
              }}
            />
          </Col>

          <Col span={24}>
            <label>Schedule Days</label>
            <Row>
              <Col span={6}>
                <Checkbox
                  checked={recurrence.includes("MO")}
                  value={"MO"}
                  onChange={(e) => {
                    handleWeekends(e.target.value);
                  }}
                />{" "}
                Monday
              </Col>
              <Col span={6}>
                <Checkbox
                  checked={recurrence.includes("TU")}
                  value={"TU"}
                  onChange={(e) => {
                    handleWeekends(e.target.value);
                  }}
                />{" "}
                Tuesday
              </Col>
              <Col span={6}>
                <Checkbox
                  checked={recurrence.includes("WE")}
                  value={"WE"}
                  onChange={(e) => {
                    handleWeekends(e.target.value);
                  }}
                />{" "}
                Wednesday
              </Col>
              <Col span={6}>
                <Checkbox
                  checked={recurrence.includes("TH")}
                  value={"TH"}
                  onChange={(e) => {
                    handleWeekends(e.target.value);
                  }}
                />{" "}
                Thursday
              </Col>
              <Col span={6}>
                <Checkbox
                  checked={recurrence.includes("FR")}
                  value={"FR"}
                  onChange={(e) => {
                    handleWeekends(e.target.value);
                  }}
                />{" "}
                Friday
              </Col>
              <Col span={6}>
                <Checkbox
                  checked={recurrence.includes("SA")}
                  value={"SA"}
                  onChange={(e) => {
                    handleWeekends(e.target.value);
                  }}
                />{" "}
                Saturday
              </Col>
              <Col span={6}>
                <Checkbox
                  checked={recurrence.includes("SU")}
                  value={"SU"}
                  onChange={(e) => {
                    handleWeekends(e.target.value);
                  }}
                />{" "}
                Sunday
              </Col>


            </Row>
            <Row>
              <ErrorMsg
                validator={() => {
                  return !props.submittedSchdule || recurrence.length;
                }}
                message={props.intl.formatMessage({
                  id: "requiredField.missing.msg",
                  defaultMessage: "",
                })}
              />


            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={8} offset={8}>
            <Button
              style={{ width: '100%' }}

              onClick={() => {
                addTimeTable()

              }}
            >
              Add Schedule
            </Button>
          </Col>

        </Row>
      </Skeleton>
    </Drawer>

  );
}

export default injectIntl(TimeTableDrawer);
