import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { postPagePolicyData } from '../../../../eStore/pages/action';
import { getPolicyPageData } from './action';
import { pushModalToStack } from '../../../../../actions/commonActions';
import { showModal, hideModal } from '../../../../../actions/commonActions';

import PoliciesComp from '../../../../../components/modal/modalBody/eStore/Policies';
//import { info } from 'sass';
class Policies extends Component {
    constructor(props){
        super(props);
        this.state= {
          isLoaded: false,
        }
    }
    componentDidMount(){
      const companyInfo = this.props.companyInfo || {}
      const payload ={
        relationshipId:companyInfo.relationshipId,
      }
      this.props.getPolicyPageData(payload)
    }
    componentWillReceiveProps(props){
      if(!this.state.isLoaded && Object.keys(props.policyPageData).length){
          this.setState({
            privacy: props.policyPageData?.privacy?.pageContent,
            refund: props.policyPageData.refund?.pageContent,
            tnc: props.policyPageData.tnc?.pageContent,
            cookies: props.policyPageData.cookies?.pageContent,
            isLoaded: true
          })
      }
    }
   

    render() {
        return <>
            <PoliciesComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    policyPageData: state.eStore.policyPageData
  };
};

  const mapDispatchToProps = (dispatch) => bindActionCreators({

     pushModalToStack,
     showModal,
     hideModal,
     postPagePolicyData,
     getPolicyPageData,
  }, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(Policies);