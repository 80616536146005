import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col } from 'antd';

//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
function SchoolDrawer(props) {

  const closeDrawer = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addSchoolDrawerVisible: false
      }
    })
  }

  const handleSubmit = (props) => {
    if (props.vpId && props.pricipleId & props.locationId) {
      const payload = {
        principleContactId: props.pricipleId,
        vicePrincileContactId: props.vpId,
        schoolName: props.schoolName,
        relationshipId: (props.companyInfo || {}).relationshipId,
        createdByEmpId: (props.userInfo || {}).userId,
      };

      props.pushModalToStack({
        modalBody:  <FormattedMessage id='schoolManagement.school.confirmation' defaultMessage='' />,
        handleSubmit: () => {
          props.saveSchool(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-title">
            <FormattedMessage id='schoolManagement.school.new' defaultMessage=' ' />
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-supplier-drawer"
        visible={props.schoolDrawers.addSchoolDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
      >
        <Form layout="vertical" hideRequiredMark>


          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='schoolManagement.school.name' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12}>
              <TextBox
                // className={(props.submittedOnce && (props.duration <= 0 || !props.duration)) ? 'input-text-error' : ''}
                type='text'
                value={props.schoolName || null}
                onChange={(e) => {
                  props.updateState({
                    schoolName: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'schoolManagement.school.name',
                    defaultMessage: ''
                  })
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.schoolName }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>

          <br />


          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <label>
                <FormattedMessage id='schoolManagement.school.principle' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <Dropdown
                //className={(props.submittedOnce && !props.type) ? 'input-text-error' : ''}
                items={props.allEmployee || []}
                valueKeyName='firstName'
                valueKeyName2='lastName'
                optionKeyName='relationshipEmployeeId'
                placeholder={props.intl.formatMessage(
                  {
                    id: 'schoolManagement.school.principle',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue) => {
                    props.updateState({
                      pricipleId: optionValue
                    });
                  }
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.pricipleId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>

          </Row>

          <br />

          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='schoolManagement.school.vp' defaultMessage='' /></span>
              {/* <span className="required">*</span> */}

            </Col>
            <Col span={12}>
              <Dropdown
                //className={(props.submittedOnce && !props.type) ? 'input-text-error' : ''}
                items={props.allEmployee || []}
                valueKeyName='firstName'
                valueKeyName2='lastName'
                optionKeyName='relationshipEmployeeId'
                placeholder={props.intl.formatMessage(
                  {
                    id: 'schoolManagement.school.vp',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue) => {
                    props.updateState({
                      vpId: optionValue
                    })
                  }
                }
              />

            </Col>

          </Row>
          <br />
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='schoolManagement.school.address' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12}>
              <Dropdown
                //className={(props.submittedOnce && !props.type) ? 'input-text-error' : ''}
                items={props.relationshipAddress || []}
                valueKeyName='streetAddress1'
                addressLineKey1='streetAddress2'
                addressLineKey2='cityName'
                addressLineKey3='stateName'
                addressLineKey4='zipCode'
                optionClassAddress='custom-address-dropdown'
                optionKeyName='locationId'
                placeholder={props.intl.formatMessage(
                  {
                    id: 'schoolManagement.school.address',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue) => {
                    props.updateState({
                      locationId: optionValue
                    })
                  }
                }
              />

              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.locationId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />

            </Col>
          </Row>

        </Form>

        <div className='form-footer'>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.submit'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </div>
      </Drawer>
    </Fragment>
  );
}

export default injectIntl(SchoolDrawer);
