import React, {Component} from "react";
import { FormattedMessage } from "react-intl";
import { fixedDecimalAndFormateNumber } from "../../../utils";

class TopCustomerPayment extends Component {
    render() {

        // const values = Object.values(this.props.topSalesCustomer || {});
        // const keys1 = Object.keys(this.props.topSalesCustomer || {});
        // const values1 = Object.values(this.props.topPaymentCustomer || {});
        
        
        // const dataArr = [];
        // Object.values(this.props.topSalesCustomer || {}).forEach((e)=>{
            //     dataArr.push(Math.round(e));
            // });
            const keys = Object.keys(this.props.topSalesCustomer || {});
        return (
            <div className="table-container">
                <table>
                    <thead>
                    <tr>
                        <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                        <th>Top Customer</th>
                        <th>Total Sales</th>
                        <th>Total Payment</th>
                    </tr>
                    </thead>
                    <tbody>
                    {keys.map((item, i) => {
                        return (
                            <tr key={i}>
                               <td>{i+1}</td>
                                <td>{item}</td>
                                <td>{(this.props.topPaymentCustomer || {})[item]?(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " ":'-'}{fixedDecimalAndFormateNumber((this.props.topPaymentCustomer || {})[item] || 0) ||  ''}</td>
                                <td>{(this.props.topSalesCustomer || {})[item]?(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " ":'-'}{fixedDecimalAndFormateNumber((this.props.topSalesCustomer || {})[item]|| 0) ||  ''}</td>
                                {/* <td>{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}{values[i].toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td> */}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>  
        )
    }
}

export default TopCustomerPayment;


// import React from "react";
// import ReactApexChart from "react-apexcharts"

// class TopCustomerPayment extends React.Component {
//     constructor(props) {
//         super(props);
//         const dataArr = [];
//         Object.values(this.props.topPaymentCustomer || {}).forEach((e)=>{
//             dataArr.push(Math.round(e));
//         });

//         this.state = {

//             series: [{
//                 name: 'Amount ('+(this.props.companyInfo ||{}).currencyCode+')',
//                 data: dataArr
//             }],
//             options: {
//                 chart: {
//                     height: 250,
//                     type: 'bar',
//                     events: {
//                         click: function (chart, w, e) {
//                         }
//                     }
//                 },
//                // colors: colors,
//                 plotOptions: {
//                     bar: {
//                         columnWidth: '45%',
//                         distributed: true
//                     }
//                 },
//                 dataLabels: {
//                     enabled: false
//                 },
//                 legend: {
//                     show: false
//                 },
//                 xaxis: {
//                     categories: Object.keys(this.props.topPaymentCustomer || {}),
//                     labels: {
//                         style: {
//                             //colors: colors,
//                             fontSize: '12px'
//                         }
//                     }
//                 }
//             },


//         };
//     }



//     render() {
//         return (


//             <div id="chart">
//                 <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
//             </div>


//         );
//     }
// }

// export default TopCustomerPayment;