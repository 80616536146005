import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CertificateOfConformityComponent from '../../../../../components/modal/modalBody/customer/CertificateOfConformity';
import { updateCertificateOfConformity } from './action';
import { generatePDF } from '../../../../../actions/downloadFileAction'

class CertificateOfConformity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowCkEditor: (props.shipmentData && props.shipmentData.certificateOfConformity) ? false : true,
            certificateData: props.shipmentData && props.shipmentData.certificateOfConformity
        };
      }
    // componentWillMount() {
    //     const companyInfo = this.props.companyInfo || {};
    //     const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.shipmentPayload.customerId, shipmentMasterId: this.props.shipmentPayload.shipmentMasterId };
    //     fetchDataIfNeeded('fetchShippingInvoiceDetail', 'shippingInvoiceData', this.props, payload, true);
    //     fetchDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerData', this.props, payload, true);

    // }

    render() {
        return <CertificateOfConformityComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        shippingInvoiceData: state.so.soShippingInvoiceDetail,
        customerData: state.common.customerDetail
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateCertificateOfConformity,
    generatePDF
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CertificateOfConformity);