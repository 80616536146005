import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LedgerDetailComponent from '../../../../../../components/modal/modalBody/finance/LedgerAccounts/LedgerDetail';
import { fetchLedgerDetails, fetchLedgerDetailsCount, resetPaginatedData,fetchLedgerDetailsByAccountId } from './action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded, formatDateFilterForBackend } from  '../../../../../../utils';
import { CONSTANTS } from '../../../../../../static/constants';

class LedgerDetails extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          pageSize: 100,
          pageNumber: 1,
          searchedText: '',

        };
      }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            ledgerAccountId: this.props.ledgerAccountId,
            pageNumber: 1,
            pageSize: 100,
            startDate: formatDateFilterForBackend(this.props.startDate, CONSTANTS.FILTER_DATE_TYPE.START),
            endDate: formatDateFilterForBackend(this.props.endDate, CONSTANTS.FILTER_DATE_TYPE.END),
        }
        // fetchPaginationDataIfNeeded('fetchLedgerDetails', 'ledgerDetailList', this.props, payload, true);
        // fetchDataIfNeeded('fetchLedgerDetailsCount', 'LedgerDetailsAcoountCount', this.props, payload, true);
        this.props.fetchLedgerDetailsByAccountId(payload, {companyInfo});
        
    }

    render() {
        return <LedgerDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        ledgerDetailList: state.ledger.ledgerDetailList,
        LedgerDetailsAcoountCount: state.ledger.LedgerDetailsAcoountCount,
        ledgerDetails: state.ledger.ledgerDetails
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchLedgerDetails,
    fetchLedgerDetailsCount,
    resetPaginatedData,
    fetchLedgerDetailsByAccountId
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LedgerDetails);