import React, { Component, Fragment } from 'react';
//import { AgGridReact } from 'ag-grid-react';
//import ShowContact from './../../../../containers/customer/Listing/ShowConact';
import ImportContact from './../../../../containers/modal/modalBody/customer/ImportContact';
import { CONSTANTS, ICONS, MODAL_TYPE } from '../../../../static/constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, sortTable } from '../../../../utils';
import PageBreadcrumb from '../../../PageBreadcrumb';
import { Dropdown, Menu, Skeleton, Button, Empty, Pagination } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';


const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class Contact extends Component {
    state = {
        buttonDropDown: false
    };

    toggleDropdown = () => {
        const { buttonDropDown } = this.state;
        this.setState({
            buttonDropDown: !buttonDropDown
        })
    };

    render() {
        const { contacts, pageSize, updateState, dir = 0, companyInfo } = this.props;
        // const { buttonDropDown } = this.state;
        // const { toggleDropdown } = this;
        // const agGridStyle = {
        //     height: '100%',
        //     width: '100%'
        // };

        // const onFirstDataRendered = (params) => {
        //     //params.api.sizeColumnsToFit();
        // }
        // const getRowHeight = (params) => {
        //     return 30;
        // }


        const deleteContact = (props, record) => {
            const payload = {
                contactId: record.contactId,
                relationshipId: props.companyInfo.relationshipId,
                customerId: props.customerId,
                isPrimaryContact: record.isPrimaryContact
            };
            props.deleteContact(payload);
            // props.deleteContact(payload, function () {
            //     props.fetchAllContacts({
            //         companyInfo: props.companyInfo,
            //         relationshipId: props.companyInfo.relationshipId,
            //     })
            // });

        }
        // const addUpdateContact = (props, payload) => {
        //     payload.isPrimaryContact = payload.isPrimaryContact ? 1 : 0;
        //     payload.customerId = props.customerId;
        //     props.createUpdateContact(payload);
        //     props.resetPaginatedData();
        //     props.fetchCustomers({ relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize });
        // }
        const handleNewContactAddition = (contactData, props) => {
            const { pushModalToStack } = props;
            let payload = { isDGContact: props.isDGContact };
            let formData = {
                customerId: props.customerId,
                ...contactData,
                // pageNumber: pageNumber || 1,
                // relationshipId: companyInfo.relationshipId
            }
            formData.callback = function (contact) {
                props.popModalFromStack();
                props.fetchAllContacts({
                    customerId: props.customerId,
                    companyInfo: props.companyInfo,
                    relationshipId: props.companyInfo.relationshipId,
                })
            }
            payload.formData = formData;
            const data = {
                title: contactData.contactId ? "Edit Contact" : <FormattedMessage id='addItem.text.createContact' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: {
                    modalType: MODAL_TYPE.CONTACT,
                    data: payload,

                },
                handleSubmit: (formData = {}) => {
                    data.formData.submittedOnce = true;
                    props.hideModal(data);

                }
            };
            pushModalToStack(data);
        }
        const showTotal = (total) => {
            return (
                this.props.intl.formatMessage({
                    id: "pagination.text.total",
                    defaultMessage: "",
                }) +
                ` ${total} ` +
                this.props.intl.formatMessage({
                    id: "pagination.text.items",
                    defaultMessage: "",
                })
            );
        };
        const loadPageData = (pageNumber, pageSize) => {
            const payload = {
                companyInfo,
                relationshipId: companyInfo.relationshipId,
                pageNumber: pageNumber,
                pageSize: pageSize,
            };
            fetchPaginationDataIfNeeded("fetchAllContacts", "contacts", this.props, payload, true);
        };
        const handlePageSizeChange = (pageNumber, pageSize, props) => {
            const payload = {
                companyInfo,
                relationshipId: companyInfo.relationshipId,
                pageNumber: pageNumber,
                pageSize: pageSize,
            };
            // props.resetPaginatedData();
            props.fetchAllContacts(payload);
            props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
        };
        // const columnDefs = [
        //     {
        //         headerName: "#",
        //         resizable: true,
        //         cellRendererFramework: function (link) {
        //             return <div>
        //                 {link.rowIndex + 1}
        //             </div>
        //         },
        //         width: 50,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='contact.title' defaultMessage="" />;
        //         },
        //         field: "salutationName",
        //         resizable: true,
        //         width: 100,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='contact.firstName' defaultMessage="" />;
        //         },
        //         field: "firstName",
        //         resizable: true,

        //     },
        //     // {
        //     //     headerComponentFramework: () => {
        //     //         return <FormattedMessage id='contact.middleName' defaultMessage="" />;
        //     //     },
        //     //     field: "middleName",
        //     //     resizable: true,

        //     // },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='contact.lastName' defaultMessage="" />;
        //         },
        //         field: "lastName",
        //         resizable: true,

        //     },
        //     // {
        //     //     headerName: "Preferred Call Name",
        //     //     resizable: true,
        //     //     field: "preferCallName",
        //     // },
        //     // {
        //     //     headerName: "Job Title",
        //     //     resizable: true,
        //     //     field: "jobTitle",
        //     // },
        //     // {
        //     //     headerName: "Department",
        //     //     resizable: true,
        //     //     field: "department",
        //     // },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='contact.email' defaultMessage="" />;
        //         },
        //         headerName: "Email",
        //         resizable: true,
        //         field: "emailAddress",
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='contact.phone' defaultMessage="" />;
        //         },
        //         resizable: true,
        //         field: "workPhone",
        //         width: 130,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='contact.mobile' defaultMessage="" />;
        //         },
        //         resizable: true,
        //         field: "cellPhone",
        //         width: 130,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='contact.faxNumber' defaultMessage="" />;
        //         },
        //         resizable: true,
        //         field: "faxNumber",
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='common.dateCreated' defaultMessage="" />;
        //         },
        //         resizable: true,
        //         width: 130,
        //         cellRendererFramework: (link) => <div>
        //             {link.data.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(link.data.dateCreated), format }) : ''}
        //         </div>
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='common.listing.header.action' defaultMessage="" />;
        //         },
        //         resizable: true,
        //         width: 130,
        //         cellRendererFramework: (link) => <div>
        //             <span className='cursor-pointer' onClick={() => {
        //                // handleNewContactAddition(params.node.data)
        //             }}>
        //                 <i className="fa fa-edit" />
        //             </span>
        //             <span className='cursor-pointer' onClick={() => {
        //                 const modalData = {
        //                     modalBody: <FormattedMessage
        //                         id='customer.address.delete.confirmation'
        //                         defaultMessage=''
        //                     />,
        //                     handleSubmit: () => {
        //                         deleteContact(this.props, link.data)
        //                     }
        //                 };
        //                 this.props.pushModalToStack(modalData);
        //             }}>
        //                 <i className="fa fa-remove" />
        //             </span>
        //         </div>
        //     }
        // ]

        const handleMenuClick = (e) => {
            switch (e.key) {
                case 'upload_contact':
                    const modalData = {
                        title: <div className='ant-modal-title'><FormattedMessage id='contact.importContact.modal.title' defaultMessage='' /></div>,
                        modalBody: <ImportContact {...this.props} />,
                        hideFooter: true,
                        width: 900,
                        height: 450,
                        maskClosable: false,
                        handleSubmit: () => {
                            //props.createOrUpdateCustomer(payload);
                            //props.hideModal();
                        },
                        // wrapClassName: 'import-details-modal'
                    };
                    this.props.pushModalToStack(modalData);
                    break;

                default:
                    break;
            }
        }

        //   const menu = (
        //     <Menu onClick={handleMenuClick} className="combined-dropdown">
        //       <Menu.Item key="upload_contact">
        //         <i className="fa fa-upload mr5" />
        //         <FormattedMessage id='contact.buttonMenu.importContact' defaultMessage='' />
        //       </Menu.Item>
        //     </Menu>
        //   );

        const breadCrumbList = [
            {
                name: <FormattedMessage id='sidebar.menuItem.communication' defaultMessage='' />
            },
            {
                name: <FormattedMessage id='contact' defaultMessage='' />,
            }
        ];

        const actionMenu = (e) => {
            return (
                <Menu className="row-action-dropdown">

                    <Menu.Item key="0" value="title">
                        Action
                    </Menu.Item>

                    <Menu.Divider />

                    <Menu.Item key="1" value="edit" onClick={() => {
                        handleNewContactAddition(e, this.props);
                    }}
                    >
                        <i className={ICONS.EDIT} />
                        Edit
                    </Menu.Item>

                    <Menu.Item key="2" onClick={() => {
                        this.props.showModal({
                            modalBody: <FormattedMessage id='commons.delete.popup.confimation.msg' />,
                            handleSubmit: () => {
                                deleteContact(this.props, e)
                            }
                        })
                    }
                    }>
                        <i className={ICONS.DELETE} />
                        Delete
                    </Menu.Item>

                </Menu >
            )
        };

        const sortColumn = (e) => {
            sortTable(document.getElementById('contacts-table'), e.target.cellIndex, 1 - dir);
            updateState({ dir: 1 - dir })
        }


        return (
            <Fragment>
                {/* {!this.props.isProfileView ? 
                    <div className="view-top-bar">
                        <div className="top-view-left">
                            <div className="page-heading">
                                <FormattedMessage id='heading.communications.contact' defaultMessage='' />
                            </div>
                            <div className="vertical-sep" />
                            <PageBreadcrumb breadCrumbList={breadCrumbList} />
                        </div>
                        <div className="top-view-right">
                            <button className="create-action-btn" onClick={()=>{
                                handleNewContactAddition( {}, this.props)
                            }}>
                                <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
                            </button>
                        </div>
                    </div>
                    :
                    ''
                } */}
                <div className="view-container">
                    <div className="view-container-actions">
                        <div className="left-actions">
                            <div className="table-heading">Contact List</div>
                            {
                      (contacts || []).length ? <>
                        <div className="vertical-sep" />
                        <div>{contacts?.length}</div>
                      </> :''
                    }
                        </div>

                        <div className="right-actions">
                            <Button
                                className="actionBtn2"

                                onClick={() => {
                                    handleMenuClick({ key: 'upload_contact' });
                                }}>
                                <DownloadOutlined /> <FormattedMessage id='contact.buttonMenu.importContact' defaultMessage='' />
                            </Button>
                            {
                                // this.props.isProfileView ? 

                                <Button
                                    className="create-action-btn"
                                    style={{ backgroundColor: "#1cb961", color: '#ffffff' }}

                                    onClick={() => {
                                        handleNewContactAddition({}, this.props)
                                    }}
                                >
                                    <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
                                </Button>
                                // :
                                // ''
                            }
                        </div>
                    </div>


                    {/* <div className="view-container-actions">
                            <div className="left-actions">
                            <div className="table-heading">
                                <FormattedMessage id='communication.contacts.table.heading' defaultMessage='' />
                            </div>
                            {
                                pageSize ? <>
                                <div className="vertical-sep" />
                                <div>{contacts.length}</div>
                                </>:''
                            }
                            </div>
                            <div className="right-actions">
                                
                            </div>
                        </div> */}

                    {/* <div className="right-align pb10">
                            <div className="action-dropdown-btn">
                                <button onClick={() => {
                                    const modalData = {
                                        title: <React.Fragment>
                                            <div className='ant-modal-title'>
                                                <FormattedMessage id='contact.text' defaultMessage="" />
                                            </div>
                                        </React.Fragment>,
                                        modalBody: <ShowContact customerId={this.props.customerId} />,
                                        width: 1100,
                                        hideFooter: true
                                    };
                                    this.props.pushModalToStack(modalData);
                                }}>
                                    <i className="fa fa-address-book" />
                                    <FormattedMessage id='manageContact.text' defaultMessage="" />
                                </button>
                                <span className={buttonDropDown ? "arrow-down active" : "arrow-down"} onClick={toggleDropdown}>
                                    <i className="fa fa-angle-down" />
                                </span>

                                <ul className="dropdown">
                                    <li className="option-list">
                                        <i className="fa fa-file-pdf-o" />
                                        <FormattedMessage id='common.downloadPdf' defaultMessage="" />
                                    </li>
                                    <li className="option-list">
                                        <i className="fa fa-file-excel-o" />
                                        <FormattedMessage id='common.export' defaultMessage="" />
                                    </li>
                                </ul>
                                <div className="action-dropdown-btn" style={{ float: 'right' }}>
                                    <button onClick={() => handleNewContactAddition( {}, this.props)}>
                                        <i className="fa fa-user pr5" />
                                        <FormattedMessage id='communication.dgGroup.addDrawer.field.newContact' defaultMessage='' />
                                    </button>
                                    <Dropdown overlay={menu}>
                                        <span className="dropdown active">
                                            <i className="fa fa-angle-down ml5" />
                                        </span>
                                    </Dropdown>
                                </div>
                            </div>
                        </div> */}

                    <Skeleton loading={this.props.listLoading} active paragraph={{ rows: 16 }}>

                        <div className='table-container table-h-profile'>
                            <table id='contacts-table'>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th onClick={sortColumn}><FormattedMessage id='contact.primaryAddress' defaultMessage="" /></th>
                                        <th onClick={sortColumn}><FormattedMessage id='contact.fullName' defaultMessage="" /></th>
                                        {/* <th onClick={sortColumn}><FormattedMessage id='contact.title' defaultMessage="" /></th>
                                        <th onClick={sortColumn}><FormattedMessage id='contact.firstName' defaultMessage="" /></th>
                                        <th onClick={sortColumn}><FormattedMessage id='contact.lastName' defaultMessage="" /></th> */}
                                        <th onClick={sortColumn}><FormattedMessage id='contact.email' defaultMessage="" /></th>
                                        <th onClick={sortColumn}><FormattedMessage id='contact.phone' defaultMessage="" /></th>
                                        <th onClick={sortColumn}><FormattedMessage id='contact.mobile' defaultMessage="" /></th>
                                        <th onClick={sortColumn}><FormattedMessage id='contact.faxNumber' defaultMessage="" /></th>
                                        <th onClick={sortColumn}><FormattedMessage id='common.dateCreated' defaultMessage="" /></th>
                                        <th className='text-center'><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contacts && contacts.length ? contacts.
                                        sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1)).map((e, i) => {
                                            return <tr key={i}>
                                                <td>{i + 1}</td>
                                                {/* <td>{e.isDefault ? "yes" : "No"}</td> */}
                                                <td>{e?.isPrimaryContact === 1 ? "Yes" : "No"}</td>

                                                {/* <td>
                                                {e.salutationName ? e.salutationName : <div className="empty-data-box"></div>}
                                            </td>
                                            <td>
                                                {e.firstName ? e.firstName : <div className="empty-data-box"></div>}
                                            </td>
                                            <td>
                                                {e.lastName ? e.lastName : <div className="empty-data-box"></div>}
                                            </td> */}
                                                <td>{e.salutationName}{" "}{e.firstName}{" "}{e.lastName}</td>
                                                <td>
                                                    {e.emailAddress ? e.emailAddress : <div className="empty-data-box"></div>}
                                                </td>
                                                <td>
                                                    {e.workPhone ? e.workPhone : <div className="empty-data-box"></div>}
                                                </td>
                                                <td>
                                                    {e.cellPhone ? e.cellPhone : <div className="empty-data-box"></div>}
                                                </td>
                                                <td>
                                                    {e.faxNumber ? e.faxNumber : <div className="empty-data-box"></div>}
                                                </td>
                                                <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : ''}</td>
                                                <td className='text-center'>
                                                    {/* <span className="cursor-pointer" title={this.props.intl.formatMessage({ id: 'commons.listing.edit.tooltip.msg' })} onClick={() => {
                                                    handleNewContactAddition(e, this.props);
                                                }}>
                                                    <i className='fa fa-pencil' />
                                                </span>

                                                <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={this.props.intl.formatMessage({ id: 'commons.listing.delete.tooltip.msg' })} onClick={
                                                    () => {

                                                        this.props.showModal({
                                                            modalBody: <FormattedMessage id='commons.delete.popup.confimation.msg' />,
                                                            handleSubmit: () => {
                                                                deleteContact(this.props, e)
                                                            }
                                                        })
                                                    }}>
                                                    <i className='fa fa-trash' />
                                                </span> */}
                                                    <div className="action-icon">
                                                        <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                                                            <i className={ICONS.MORE} />
                                                        </Dropdown>
                                                    </div>

                                                </td>
                                            </tr>
                                        }) :
                                        <tr key="empty-data-box">
                                            <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                                <Empty />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <div className='agGridWrapper'>
                            <div className="ag-theme-balham" style={agGridStyle} >
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    rowData={contacts || []}
                                    rowDragManaged={true}
                                    domLayout={"autoHeight"}
                                    animateRows={true}
                                    onGridSizeChanged={onFirstDataRendered}
                                    onColumnResized={(params) => {
                                        params.api.resetRowHeights();
                                    }}
                                    defaultColDef={{
                                        flex: 1,
                                        autoHeight: true,
                                        wrapText: true,
                                    }}
                                //getRowHeight={getRowHeight}
                                >
                                </AgGridReact>
                            </div>
                        </div> */}
                    </Skeleton>
                    {/* <div className="footer">
                        <div className="f-left"></div>
                        <div className="f-right">
                            <Pagination
                                size="small"
                                // total={100}
                                showTotal={showTotal}
                                defaultPageSize={this.props.pageSize}
                                showSizeChanger
                                pageSizeOptions={[10, 25, 50, 100, 200]}

                                onChange={(pageNumber, pageSize) => {
                                    loadPageData(pageNumber, pageSize);
                                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                                }}
                                onShowSizeChange={(pageNumber, pageSize) => {

                                    handlePageSizeChange(1, pageSize, this.props);
                                }}
                            />
                        </div>
                    </div> */}
                </div>
            </Fragment>
        )
    }
}

export default injectIntl(Contact);