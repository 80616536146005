import { LEDGER_ACTION_LIST } from '../static/constants';
const initialState = {
    generalIncomeLedgerList: [],
    salesLedgerList: [],
    reservedAccounts: [],
    salesDiscountLedgerList: [],
    salesTaxLedgerList: [],
    debtorsLedgerList: [],
    creditorsLedgerList: [],
    discountRecievedList: [],
    purchaseTaxList: [],
    purchaseLedgerList: [],
    generalExpenseLedgerList: [],
    allLedgerAccounts: [],
    allPrevFYLedgerAccounts: [],
    allLedgerAccountGroups: [],
    allCategories: [],
    allCategoryGroup: [],
    LedgerDetailsAcoountCount: '0',
    ledgerDetailList: {
        1: []
    },
    nextLedgerAccountNo: '',
    showAllLedgerAccounts: false,
    showAllLedgerAccountGroups: false,
    showAllCategories: false,
    showAllCategoryGroup: false,
    allLedgerAccountsSummary: [],
    ledgerDetails:{
        1:[]
    }
}

const CreditMemoReducer = (state = initialState, action) => {
    switch (action.type) {
        case LEDGER_ACTION_LIST.GENERAL_INCOME:
            return { ...state, generalIncomeLedgerList: action.data ? [action.data] : [] };
        case LEDGER_ACTION_LIST.SALES:
            return { ...state, salesLedgerList: action.data ? [action.data] : [] };
        case LEDGER_ACTION_LIST.SALES_DISCOUNT:
            return { ...state, salesDiscountLedgerList: action.data ? [action.data] : [] };
        case LEDGER_ACTION_LIST.SALES_OUTPUT_TAX:
            return { ...state, salesTaxLedgerList: action.data ? [action.data] : [] };
        case LEDGER_ACTION_LIST.DEBTORS:
            return { ...state, debtorsLedgerList: action.data ? [action.data] : [] };
        case LEDGER_ACTION_LIST.CREDITORS:
            return { ...state, creditorsLedgerList: action.data ? [action.data] : [] };
        case LEDGER_ACTION_LIST.DISCOUNT_RECIEVED:
            return { ...state, discountRecievedList: action.data ? [action.data] : [] };
        case LEDGER_ACTION_LIST.PURCHASE_INPUT_TAX:
            return { ...state, purchaseTaxList: action.data ? [action.data] : [] };
        case LEDGER_ACTION_LIST.GENERAL_EXPENSE:
            return { ...state, generalExpenseLedgerList: action.data ? [action.data] : [] };
        case LEDGER_ACTION_LIST.ALL_LEDGER_ACCOUNTS:
            return { ...state, allLedgerAccounts: action.data };
        case LEDGER_ACTION_LIST.ALL_PREV_FY_LEDGER_ACCOUNTS:
            return { ...state, allPrevFYLedgerAccounts: action.data };
        case LEDGER_ACTION_LIST.ALL_LEDGER_ACCOUNT_GROUPS:
            return { ...state, allLedgerAccountGroups: action.data };
        case LEDGER_ACTION_LIST.ALL_CATEGORIES:
            return { ...state, allCategories: action.data };
        case LEDGER_ACTION_LIST.ALL_CATEGORY_GROUP:
            return { ...state, allCategoryGroup: action.data };
        case LEDGER_ACTION_LIST.NEXT_LEDGER_ACCOUNT_NO:
            return { ...state, nextLedgerAccountNo: action.data };
        case LEDGER_ACTION_LIST.LEDGER_DETAIL_LIST_COUNT:
            return { ...state, LedgerDetailsAcoountCount: action.data };
        case LEDGER_ACTION_LIST.SHOW_ALL_LEDGER_ACCOUNTS:
            return { ...state, showAllLedgerAccounts: true };
        case LEDGER_ACTION_LIST.SHOW_ALL_LEDGER_ACCOUNT_GROUPS:
            return { ...state, showAllLedgerAccountGroups: true };
        case LEDGER_ACTION_LIST.SHOW_ALL_CATEGORIES:
            return { ...state, showAllCategories: true };
        case LEDGER_ACTION_LIST.RESERVED_LEDGER_LIST:
            return { ...state, reservedAccounts: action.data };
        case LEDGER_ACTION_LIST.RESET_RESERVED_LEDGER_LIST:
            return { ...state, reservedAccounts: initialState.reservedAccounts };
        case LEDGER_ACTION_LIST.SHOW_ALL_CATEGORY_GROUP:
            return { ...state, showAllCategoryGroup: true };
        case LEDGER_ACTION_LIST.LEDGER_DETAIL_LIST:
            return {
                ...state,
                ledgerDetailList: {
                    ...state.ledgerDetailList,
                    [(action.data || {}).pageNumber || 1]: (action.data || {}).list
                }
            };
        case LEDGER_ACTION_LIST.LEDGER_DETAIL_LIST_RESET:
            return { ...state, ledgerDetailList: initialState.ledgerDetailList };
        case LEDGER_ACTION_LIST.HIDE_ALL_LEDGER_ACCOUNTS:
            return { ...state, showAllLedgerAccounts: false };
        case LEDGER_ACTION_LIST.HIDE_ALL_LEDGER_ACCOUNT_GROUPS:
            return { ...state, showAllLedgerAccountGroups: false };
        case LEDGER_ACTION_LIST.HIDE_ALL_CATEGORIES:
            return { ...state, showAllCategories: false };
        case LEDGER_ACTION_LIST.HIDE_ALL_CATEGORY_GROUP:
            return { ...state, showAllCategoryGroup: false };
        case LEDGER_ACTION_LIST.ALL_LEDGER_ACCOUNTS_SUMMARY:
            return { ...state, allLedgerAccountsSummary: action.data };   
        case LEDGER_ACTION_LIST.LEDGER_DETAILS:
                return {
                    ...state,
                    ledgerDetails: {
                        ...state.ledgerDetails,
                        [(action.data || {}).pageNumber || 1]: (action.data || {}).list
                    }
                };    
        default:
            return state;
    }
};

export default CreditMemoReducer;