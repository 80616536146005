import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReserveLedgerAccountsComponent from '../../../../../../components/modal/modalBody/finance/LedgerAccounts/ReserveLedgerAccounts';
import { fetchAllReservedLedgerAccounts } from './action'

class LedgerAccountsComp extends Component {

    componentDidMount() {
        this.props.fetchAllReservedLedgerAccounts({
            relationshipId: (this.props.companyInfo || {}).relationshipId
        });
    }



    render() {
        return <ReserveLedgerAccountsComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        isLoading: state.common.isLoading,
        reservedAccounts: state.ledger.reservedAccounts
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllReservedLedgerAccounts
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LedgerAccountsComp);
