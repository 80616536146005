import React ,  { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilePdfOutlined, MailOutlined, PrinterOutlined, EditOutlined, DeleteOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import { Button } from 'antd'
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS, TRANSACTION_TYPES, MODAL_TYPE, MODULE_CODED_VALUES, PERMISSION_VALUES, CONSTANTS_TRANSACTIONS } from '../../../../../static/constants';
import { fixedDecimalNumber, checkACLPermission } from '../../../../../utils';
import StandardTemplate from "./standard";
import Template2 from "./template2";
import { Dropdown } from '../../../../general/Dropdown';
import StandardSalesQuote from "../../../../../containers/customer/salesQuote/StandardSalesQuote"
import StandardSalesOrder from "../../../../../containers/customer/salesOrder/StandardSalesOrder"
import MultiCurrencySalesOrder from '../../../../../containers/customer/salesOrder/MultiCurrencySalesOrder';

const SqDetails = (props) => {
    const { sqDetail, companyInfo, templateName = "Professional" } = props;
    const { permissions } = props;
    const primaryPerm = (permissions && permissions.primary) || [];
    const { customerSalesQuotationDetailsList } = sqDetail;
    const relationshipAddress = find(sqDetail.boLocationSQList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(sqDetail.boLocationSQList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(sqDetail.boLocationSQList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (sqDetail.placeOfSupply && relationshipAddress.stateName === sqDetail.placeOfSupply) || false;
    let itemLevelTaxes = [];
    if (customerSalesQuotationDetailsList) {
        itemLevelTaxes = calculateTaxRateList(customerSalesQuotationDetailsList, isPlaceOfSupplySameAsRelationship);
    }
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true
    };
    if (sqDetail) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, sqDetail, props);
    }
    const [showComp, setShowComp] = useState(false);
    useEffect(()=>{
      const delay = setTimeout(()=>{
        setShowComp(true);
      }, 500);
      return ()=> clearTimeout(delay);
    }, []);
    const getPayloadForPdf = () => {
        return {
            templateName: templateName,
            fileName: (sqDetail.quotationNumber || 'Sales Quote') + '.pdf',
            transactionName: 'salesQuote',
            sqDetail: sqDetail,
            relationshipAddress: relationshipAddress,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            companyInfo: companyInfo,
            isColumnEmpty: isColumnEmpty,
            isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
            itemLevelTaxes: itemLevelTaxes,
            bucketName: config.BUCKET_NAME.BO_SALES_QUOTE,
            stampAwsKey: sqDetail.stampAwsKey
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () =>{
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired = true;
        payload.customerId = sqDetail.customerId;
        payload.salesQuotationMasterId = sqDetail.salesQuotationMasterId;


        let payload2 = { ...payload };
        payload2.transactionName = 'technicalOffer';
        payload2.fileName = 'Technical Submission.pdf'
        props.updateState({
            //  emailBody: document.getElementById("sq-readonly").innerHTML,
            emailDrawerVisible: true,
            txData: payload,
            txData2: payload2,
            type: TRANSACTION_TYPES.SALES_QUOTE,
        });
    };

    const approveQuote = () => {
        const modalBody = 'Are you sure you want to approve?';
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.approveQuote({
                    relationshipId: props.sqDetail.relationshipId,
                    customerId: props.sqDetail.customerId,
                    salesQuotationMasterId: props.sqDetail.salesQuotationMasterId,
                    approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId
                }, props)
                props.popModalFromStack();
            },
        };
        props.showModal(modalData);
    }

    const handleDelegateApprover = () => {
        const formData = {
            relationshipId: props.sqDetail.relationshipId,
            customerId: props.sqDetail.customerId,
            salesQuotationMasterId: props.sqDetail.salesQuotationMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            approverEmpId: props.sqDetail.approverEmpId,
            txType: TRANSACTION_TYPES.SALES_QUOTE
        };
        const { showModal } = props;
        const data = {
            title: 'Delegate Approver',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.DELEGATE_APPROVER,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const handleRejectApproval = () => {
        const formData = {
            relationshipId: props.sqDetail.relationshipId,
            customerId: props.sqDetail.customerId,
            salesQuotationMasterId: props.sqDetail.salesQuotationMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            txType: TRANSACTION_TYPES.SALES_QUOTE
        };
        const { showModal } = props;
        const data = {
            title: 'Reject',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.REJECT_APPROVAL,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const openAuditDrawer = (props, data) => {
        props.updateState({
            title: <div className="drawer-title">
                <FormattedMessage id='modal.txDetails.common.auditTrail' defaultMessage='' />
            </div>,
            auditDrawerVisible: true,
            deletApiUrl: 'deleteAuditTrailSalesQuotation',
            masterKey: 'salesQuotationMasterId',
            masterKeyValue: data.salesQuotationMasterId,
            apiUrl: 'saveAuditTrailSalesQuotation',
            customerId: data.customerId,
            trailList: data.auditTrailSalesQuotationList,
            updateTrailListInTransaction: (list) =>{
                data.auditTrailSalesQuotationList = list;
            } 
        });
    }

    const addItemConfirmationModal = (props, text, color, sqData) => {
        const { showModal, companyInfo } = props;
       
        const modalBody = <span>
            <FormattedMessage
                id='setItem.confirmation'
                defaultMessage={``}
            />
      </span>;

        let payload = {
            userStatus: text,
            userStatusColor: color,
            relationshipId: companyInfo.relationshipId,
            salesQuotationMasterId: sqData.salesQuotationMasterId,
            customerId: sqData.customerId
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateUserStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    }



    const updateUserStatus = (props, sqData) => {
        let payload = { formData: {}, txData: sqData };
        const formData = payload.formData;
        const { pushModalToStack } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, sqData);
        } else {
            const data = {
                // title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_QUOTE },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, formData.statusColor, sqData);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    return (
        <div className={"custom-modal show"}>
            {/* <i className="close-icon">
                <svg width="17" height="17" onClick={() => { props.popModalFromStack(); }}>
                    <title>Close</title>
                    <path fill="#67757d" fillRule="evenodd"
                        d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                </svg>
            </i> */}
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    {showComp ?
                    <div id="sq-readonly" className="inner-content">

                        {templateName === 'Standard' && <StandardTemplate {...props} />}
                        {templateName === 'Professional' && <Template2 {...props} />}

                        {props.sqDetail.status !== 'Pending Approval' && props.sqDetail.status !== 'Rejected' ?
                            <div className=" button-group-action header-control">
                             <div className="small-btn-group">
                               {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_QUOTE, PERMISSION_VALUES.UPDATE) &&  <Button style={{height:'38px'}} title={props.intl.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        props.popModalFromStack();
                                        props.pushModalToStack({
                                            modalBody: <StandardSalesQuote {...props} 
                                            customerId={props.sqDetail.customerId}
                                            sqEditDetail = {sqDetail}
                                            salesQuotationMasterId={ props.sqDetail.salesQuotationMasterId} 
                                            update={true} 
                                            />,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,
                                            wrapClassName: 'new-transaction-wrapper',keyboard:false
                                        })
                                    }}
                                >
                                </Button>}
                               {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_QUOTE, PERMISSION_VALUES.CREATE) && <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.clone', defaultMessage: '' })}
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        props.popModalFromStack();
                                        props.pushModalToStack({
                                            modalBody: <StandardSalesQuote {...props} 
                                            sqEditDetail = {sqDetail}
                                            customerId={props.sqDetail.customerId} 
                                            salesQuotationMasterId={ props.sqDetail.salesQuotationMasterId} 
                                            clone={true} 
                                            update={false} 
                                            
                                            />,
                                            width: '100%',
                                            hideTitle: true,
                                            hideFooter: true,keyboard:false,
                                            wrapClassName: 'new-transaction-wrapper'
                                        })
                                    }}
                                >
                                </Button>}
                                    {checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_QUOTE, PERMISSION_VALUES.DELETE) && <Button
                                        style={{height:'38px'}}
                                        title={props.intl.formatMessage({ id: 'modal.txDetails.common.delete', defaultMessage: '' })}
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            props.showModal({
                                                modalBody: "Are you sure you want to delete this transaction?",
                                                handleSubmit: () => {
                                                    props.popModalFromStack();
                                                    props.deleteSalesQuote({
                                                        salesQuotationMasterId: props.sqDetail.salesQuotationMasterId,
                                                        customerId: props.sqDetail.customerId,
                                                        relationshipId: props.sqDetail.relationshipId
                                                    }, props)
                                                }
                                            })
                                        }}
                                    >
                                    </Button>}
                            </div>
                            <div className="small-btn-group">

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                    icon={<MailOutlined />}
                                    onClick={() => {
                                        openEmailDrawer(props);

                                    }}
                                >
                                </Button>

                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                    icon={<FilePdfOutlined />}
                                    onClick={() => {
                                        generatePDF();
                                    }}
                                >
                                </Button>
                                <Button style={{height:'38px'}}
                                    title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                    icon={<PrinterOutlined />}
                                    onClick={() => {
                                        printPdf();
                                    }}
                                >
                                </Button>
                            </div>
                            <div className="small-btn-group">
                                <Button style={{height:'38px'}} title={props.intl.formatMessage({ id: 'modal.txDetails.common.auditTrail', defaultMessage: '' })}
                                    icon={<HistoryOutlined />}
                                    onClick={() => {
                                        openAuditDrawer(props, props.sqDetail);
                                    }}
                                >
                                </Button>
                            </div>

                                {(props.sqDetail.statusSO !== "converted") ?
                                    <Button style={{height:'38px'}}
                                        type="primary"
                                    >
                                        <div onClick={() => {
                                            props.popModalFromStack();
                                            props.sqDetail.foreignCurrency
                                            ?
                                            props.pushModalToStack({
                                                modalBody: <MultiCurrencySalesOrder {...props} customerId={props.sqDetail.customerId} salesQuotationMasterId={ props.sqDetail.salesQuotationMasterId}  />,
                                                width: '100%',
                                                hideTitle: true,
                                                hideFooter: true,
                                                wrapClassName: 'new-transaction-wrapper',keyboard:false
                                            })
                                            :
                                            props.pushModalToStack({
                                                modalBody: <StandardSalesOrder {...props} customerId={props.sqDetail.customerId} salesQuotationMasterId={ props.sqDetail.salesQuotationMasterId}  />,
                                                width: '100%',
                                                hideTitle: true,
                                                hideFooter: true,
                                                wrapClassName: 'new-transaction-wrapper',keyboard:false
                                            })
                                        }}
                                        >
                                            <FormattedMessage id='modal.txDetails.salesQuote.convertToSO' defaultMessage='' />
                                        </div>
                                    </Button> : ''
                                }

                               
                                <div>
                                    <Dropdown style={{'width': '110px'}}
                                        placeholder="Template"
                                        items={['Standard', 'Professional']}
                                        onSelect={(selectedValue) => {
                                            props.updateState({
                                                templateName: selectedValue,
                                            });
                                        }}
                                    ></Dropdown>
                                </div>
                                <div className="small-btn-group" style={{marginLeft: '10px'}}>
                                    <Button style={{ width: 'auto',height:'38px' }}
                                        onClick={() => {
                                            updateUserStatus(props, props.sqDetail);
                                        }}
                                    >
                                        <FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage='' />
                                    </Button>
                                </div>
                            </div>
                            :
                            <div className="button-group-action">
                                {props.sqDetail.status !== 'Rejected' && (props.userInfo || {}).relationshipEmployeeId === sqDetail.approverEmpId ? <div className="button-group-action header-control">
                                    <Button type="primary" icon={<FilePdfOutlined />} onClick={() => { approveQuote() }}>
                                    <FormattedMessage id='modal.txDetails.salesQuote.approveQuote' defaultMessage='' /> 
                                </Button>
                                    <Button type="primary" icon={<FilePdfOutlined />} onClick={() => { handleDelegateApprover(); }}>
                                    <FormattedMessage id='modal.txDetails.common.delegateApprover' defaultMessage='' />
                            </Button>
                                    <Button type="danger" icon={<FilePdfOutlined />} onClick={() => { handleRejectApproval(); }}>
                                    <FormattedMessage id='modal.txDetails.common.reject' defaultMessage='' /> 
                            </Button>
                                </div> :
                                    <div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    : ""}
                </div>
            </div>
        </div>
    );

}


const getTxTypeData = (row, type, toReturn) => {
    if (!row) return;
    let data = row.customerSalesQuotationCOATxList.find(tx => (tx.txType === type));
    if (data) {
        if (data[toReturn] && toReturn === 'amountPercent') {
            if (type === 'VAT') {
                return "VAT(" + data[toReturn] + "%)";
            }
            return "(" + data[toReturn] + "%)";
        } else if (data[toReturn] && toReturn === 'amount') {
            return (fixedDecimalNumber(data[toReturn]));
        } else {
            return ""
        }

    } else if (!data && toReturn === 'amount') {
        return "";
    }
    else {
        return "-";
    }
}

const calculateTaxRateList = (customerSalesQuotationDetailsList, isPlaceOfSupplySameAsRelationship) => {
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = []

    customerSalesQuotationDetailsList.forEach(function (rowdata, index) {
        let totalAmountOfColumn = 0;
        if (rowdata.quantity && rowdata.anItemSalePrice) {
            let taxObj = {
                subtotal: 0,
                taxPercent: 0,
                taxAmount: 0
            }
            totalAmountOfColumn = rowdata.quantity * rowdata.anItemSalePrice;
            let discount = getTxTypeData(rowdata, 'discount', 'amount');
            if (discount) {
                totalAmountOfColumn = totalAmountOfColumn - discount;
                totalDiscount = totalDiscount + discount;
            }

            let tax1 = rowdata.customerSalesQuotationCOATxList.find(tx => (tx.txType === 'tax')) || {};
            taxObj.taxPercent = Number(tax1.amountPercent);
            taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
            taxObj.taxAmount = Number(fixedDecimalNumber(tax1.amount))
            totalTax = totalTax + taxObj.taxAmount;
            taxObj.taxName = tax1.taxName;
            // let tax2 = rowdata.customerSalesQuotationCOATxList.find(tx => (tx.txType === 'sgst'));
            // let tax3 = rowdata.customerSalesQuotationCOATxList.find(tx => (tx.txType === 'igst'));
            // let tax4 = rowdata.customerSalesQuotationCOATxList.find(tx => (tx.txType === 'vat'));
            // if (isPlaceOfSupplySameAsRelationship && tax1 && tax2) {
            //     taxObj.taxPercent = Number(tax1.amountPercent) + Number(tax2.amountPercent);
            //     taxObj.taxAmount = Number(tax1.amount) + Number(tax2.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            // else if (!isPlaceOfSupplySameAsRelationship && tax3) {
            //     taxObj.taxPercent = Number(tax3.amountPercent);
            //     taxObj.taxAmount = Number(tax3.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // } else if (tax4) {
            //     taxObj.taxPercent = Number(tax4.amountPercent);
            //     taxObj.taxAmount = Number(tax4.amount);
            //     totalTax = totalTax + taxObj.taxAmount;
            // }
            if (taxObj.taxPercent) {
                taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                if (availableTaxObj) {
                    itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                        if (ele.taxPercent === taxObj.taxPercent) {
                            ele.subtotal = ele.subtotal + taxObj.subtotal;
                            ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                        }
                        return true;
                    });
                } else {
                    taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0));
                    itemLevelTaxes.push(taxObj);
                }
            }
            subtotal = subtotal + totalAmountOfColumn;
        }
    });
    return itemLevelTaxes;
}

const updateColumnEmptyCheck = (isColumnEmpty, sqDetail, props) => {
    if (!sqDetail) return isColumnEmpty;
    for (let i in sqDetail.customerSalesQuotationDetailsList) {
        let row = sqDetail.customerSalesQuotationDetailsList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantity) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.anItemSalePrice) { isColumnEmpty.rate = false; }
        if (row.specialDiscount) { isColumnEmpty.specialDiscount = false; }
        if (row.hsnCode && props.companyInfo.countryName === 'India') { isColumnEmpty.hsn = false; }
        if (!isTxTypeEmpty(row, 'discount')) { isColumnEmpty.discount = false; }
        if (!isTxTypeEmpty(row, 'tax')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'sgst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'igst')) { isColumnEmpty.tax = false; }
        //if (!isTxTypeEmpty(row, 'vat')) { isColumnEmpty.tax = false; }
        if (row.documentDetails && row.documentDetails.length) { isColumnEmpty.documentDetails = false; }
    }
    return isColumnEmpty;
}

const isTxTypeEmpty = (row, type) => {
    if (!row) return true;
    let data = row.customerSalesQuotationCOATxList.find(tx => (tx.txType === type));
    if (data && data.amount) {
        return false;
    }
    else {
        return true;
    }
}

export default SqDetails;
