import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StudentDetailsComponent from '../../../../../components/modal/modalBody/customer/StudentDetails';
import { getStudentDetail, resetStudentDetails } from './action';

class StudentDetail extends Component {

    constructor(props){
        super();
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, studentRegistrationId: this.props.registrationData.studentRegistrationId };
        this.props.resetStudentDetails();
        this.props.getStudentDetail(payload);
    }

    render() {
        return <StudentDetailsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        studentDetailsList: state.studentRegistration.studentDetailsList
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getStudentDetail, 
    resetStudentDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetail);