import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';

import { fetchDataIfNeeded, fetchPaginationDataIfNeeded, addDaysToDate } from '../../../../utils';
import SalesInvoiceComponent from '../../../../components/customer/invoice/StudentInvoice';
import LedgerDrawer from '../../../../containers/drawer/ledgers/SalesInvoiceLedger'
import {
    fetchProducts, fetchPaymentTerms, fetchPriceList,
    fetchStates, fetchProjects, fetchTaxes, fetchUOM, addUOM, deleteUOM,
    fetchExpenseTypes, addExpenseTypes, deleteExpenseTypes, showModal, hideModal, pushModalToStack, popModalFromStack,
    fetchCustomerDetailsByCustomerId, addPaymentTerm, deletePaymentTerm,
    deleteProject, deleteAddress, deleteTax, deletePriceList,
    fetchDocuments, addDocumentName, deleteDocumentName, deleteContact, fetchUserPreferences, saveUserPrefrences, fetchCountries, fetchRelationshipTaxIdentifications, deleteCustomerTaxIdentifications, deleteRelationshipTaxIdentifications, resetCustomerDetail,
    fetchAddresses,
    getRelationshipAddress, resetAddresses, getAllPaymentGatewayConfig
} from './../../../../actions/commonActions';
import { createSalesInvoice, resetSalesInvoice, fetchSalesInvoiceDetail, fetchSoDataForInvoice, resetSoInvoiceData, getNextTxNumber, fetchAllUnfulfilledSalesOrderByCustomerId, resetUnfulfilledSalesOrder, fetchShipmentForInvoice, resetShipmentForInvoice, createSalesInvoiceDraft, fetchSalesOrderSOId, resetSOBySOID, fetchShipmentByShipmentId, resetShipmentData } from './action';
import { LEDGER_ACTION_LIST, CONSTANTS, LEDGER_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY } from '../../../../static/constants';
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import SalesInvoiceDetails from '../../../modal/modalBody/customer/SalesInvoiceDetail';
import { fetchCustomers, deleteCustomers } from '../../Listing/action';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import TransportDataDrawer from '../../../../containers/drawer/transportData/index';
import { fetchAllContacts } from '../../Listing/ShowConact/action';
import { getFormNames } from '../../studentRegistration/Listing/action';

class SalesInvoice extends Component {

    constructor(props) {
        super(props);
        const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES }) || {};
        const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE }) || {};
        const defaultSalesOutputLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX }) || {};
        const defaultSalesDiscountLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_DISCOUNT }) || {};
        const defaultDebtorsLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEBTORS }) || {};
        const companyInfo = props.companyInfo;
        const allRelationshipBillingAddress = filter(companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
        const linkProps = (this.props.location && this.props.location.state) || {};
        this.state = {
            preservedSalesOrder: [],
            isbankReco: linkProps.isbankReco,
            bankRecoBalance: linkProps.bankRecoBalance,
            bankTxData: linkProps.bankTxData,
            invoiceDraftId: linkProps.invoiceDraftId || 0,
            isClone: linkProps.clone,
            invoiceProformaId: linkProps.invoiceProformaId || 0,
            invoiceDraftData: linkProps.invoiceData || {},
            customer: linkProps.customer,
            customerName: (linkProps.customer || {}).companyName,
            stateCustomerId: linkProps.customerId,
            registrationFormSettingId: props.invoiceForFinancialAssitance ? (props.registrationFormSettingId || '') : (props?.linkProps?.registrationFormSettingId || ''),
            pageNumber: linkProps.pageNumber || 1,
            pageSize: 100,
            cols: 20,
            searchedText: '',
            isSoConversionToInvoice: false,
            ledgerDrawerVisible: false,
            invoiceDate: new Date(),
            salesLedgerId: defaultSalesLedger.ledgerAccountId,
            salesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            expenseLedgerId: defaultExpenseLedger.ledgerAccountId,
            expenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            salesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
            taxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            salesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
            discountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
            allRelationshipBillingAddress,
            relationshipBillingAddress,
            priceTypeToApply: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0],
            customerShippingAddress: undefined,
            customerBillingAddress: undefined,
            customerPaymentTerm: undefined,
            customerRef: undefined,
            customerPo: undefined,
            placeOfSupply: undefined,
            customerRemarks: undefined,
            internalRemarks: undefined,
            footer: undefined,
            inheritedTxColumn: [],
            forceHideTxColumn: [],
            txPreferences: { txType: "salesInvoice", templateName: "standard" },
            txColumns: [],
            txColumnSetting: {},
            txMandatoryColumns: [
                GRID_PREFERENCE_CONSTANTS.STUDENT,
                GRID_PREFERENCE_CONSTANTS.QUANTITY,
                GRID_PREFERENCE_CONSTANTS.RATE,
                GRID_PREFERENCE_CONSTANTS.AMOUNT,
                "sno"
            ],
            txAvailableColumn: {
                product: true,
                quantity: true,
                rate: true,
                amount: true,
                description: true,
                uom: true,
                hsn: (props.companyInfo || {}).countryName === 'India',
                basePrice: true,
                specialDiscount: true,
                discount: true,
                discountType: true,
                tax: true,
                comment: true,
                stockNumber: true,
                partNumber: true,
                origin: true,
                hsCode: true,
                materialNumber: true,
                shipmentNumber: true,
                salesOrderNumber: true,
                student: true,
            },
            selectedSalesOrder: [],
            customerSalesOrderDetailsList: [{}],
            allForms: []
        };
    }




    componentWillReceiveProps(props) {

        if (props.addresses) {
            if (props.addresses.BillingAddress && props.addresses.BillingAddress.length) {
                this.setState({
                    allBillingAddress: props.addresses.BillingAddress
                });
            }
            if (props.addresses.ShippingAddress && props.addresses.ShippingAddress.length) {
                this.setState({
                    allShippingAddress: props.addresses.ShippingAddress
                });
            }
        }

        if (props.customerDetail && props.customerDetail.customerId) {
            const customer = props.customerDetail;
            const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
            const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
            const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
            const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
            const customerPaymentTerm = props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: ((customer && customer.paymentTermId) || 0) }) || {} : {};
            let numberOfDays = customerPaymentTerm.numberOfDays;
            const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
            let selectedCustomerTaxIdentifications = [];
            const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
            selectedCustomerTaxIdentifications = selectedList.map(obj => {
                return obj.customerTaxIdentificationId;
            })
            let studentRegistration = {};
            if(props.registrationFormSettingId || this.state.registrationFormSettingId){
             studentRegistration = find(props.customerDetail.orgRegistrationList || [], { registrationFormSettingId: this.state.registrationFormSettingId }) || {};
            }
            this.setState({
                customer,
                customerName: customer.companyName,
                customerPaymentTerm,
                customerShippingAddress: defaultShippingAddress,
                customerBillingAddress: defaultBillingAddress.streetAddress1 ? defaultBillingAddress: this.state.customerBillingAddress,
                placeOfSupply: defaultShippingAddress.stateName || defaultBillingAddress.stateName,
                invoiceDueDate: numberOfDays ? addDaysToDate((props.invoiceDate || new Date()), numberOfDays) : null,
                selectedContact,
                paymentTermDays: numberOfDays,
                selectedCustomerTaxIdentifications: selectedCustomerTaxIdentifications,
                selectedSalesOrder: props.preservedSalesOrder || [],
                preservedSalesOrder: [],
                allBillingAddress,
                allShippingAddress,
                selectedSalesOrderIds: [],
                isShipmentConversion: false,
                studentList: studentRegistration.studentList || []
            });
            props.resetCustomerDetail();
        }

        if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
            const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
            let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
                return obj.relationshipTaxIdentificationId;
            })
            this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
        }

        if (props.documentList && props.documentList.length && !this.state.docName) {
            props.documentList.forEach((document) => {

                if (document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE] && document.isDefault) {
                    this.setState({
                        documentNameId: document.documentNameId,
                        docName: document.docName
                    })
                }
            })

        }
        if (!this.state.customerRemarks && props.remarksData && props.remarksData.isFetched && !this.state.isRemarksSet) {
            this.setState({ customerRemarks: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
            setTimeout(() => {
                props.resetRemarksData();
            }, 500)
        }

        if (props.taxes && props.taxes.length && props.salesInvoiceDetail && props.salesInvoiceDetail.invoiceMasterId) {
          //  let stateProps = (this.props.location && this.props.location.state) || {};
            let stateProps = {...props};
            const detailList = [];
            const inheritedTxColumn = [];
            const _emptyColumnCheck = (item, taxPercent) => {
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
                }
                // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
                //     inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
                // }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerInvoiceCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.warehouse) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
                }
                if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER) < 0 && item.materialNumber) {
                    inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.MATERIAL_NUMBER);
                }
            }

            let selectedLedgers = {};

            props.salesInvoiceDetail.customerInvoiceDetailsList.map((ele) => {
                const tax = (find(ele.customerInvoiceCOATxList, (ele) => {
                    return ele.txType === LEDGER_TYPE.TYPE_TAX
                }) || {});

                ele.customerInvoiceCOATxList.map((ledgerObj) => {
                    if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "credit") {
                        selectedLedgers.discountDebtorLedgerId = ledgerObj.ledgerAccountId
                    }
                    else if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "debit") {
                        selectedLedgers.salesDiscountLedgerId = ledgerObj.ledgerAccountId
                    }
                    else if (ledgerObj.txType === LEDGER_TYPE.TYPE_TAX && ledgerObj.accountingEntry === "credit") {
                        selectedLedgers.salesOutputTaxLedgerId = ledgerObj.ledgerAccountId
                    }
                    else if (ledgerObj.txType === LEDGER_TYPE.TYPE_TAX && ledgerObj.accountingEntry === "debit") {
                        selectedLedgers.taxDebtorLedgerId = ledgerObj.ledgerAccountId
                    }
                    return '';
                });

                const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
                _emptyColumnCheck(ele, tax.amountPercent);
                return detailList.push({
                    version: ele.version,
                    invoiceMasterId: stateProps.update ? ele.invoiceMasterId : 0,
                    studentName: ele.studentName,
                    schoolStudentId: ele.studentId,
                    studentGrade: ele.studentGrade,
                    description: ele.description,
                    scrollHeight: ele.rowHeight,
                    qty: ele.quantityToInvoice,
                    discount: (find(ele.customerInvoiceCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
                    discountType: (find(ele.customerInvoiceCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).discountType,
                    tax: (tax.amountPercent || 0),
                    taxApplied: appliedTax.taxNameDisplay,
                    taxName: appliedTax.taxName,
                    taxId: appliedTax.taxSingleRateId,
                    quantityOrdered: ele.quantity,
                    quantityInvoiced: ele.quantityInvoiced,
                    rate: ele.anItemSalePrice,
                    baseRate: ele.basePrice,
                    uom: ele.uomName,
                    hsnCode: ele.hsnCode,
                    specialDiscount: ele.basePrice && ele.specialDiscount,
                    comment: ele.warehouse,
                    stockNumber: ele.stockNumber,
                    partNumber: ele.partNumber,
                    origin: ele.origin,
                    hsCode: ele.hsCode,
                    parentDetailsId: ele.parentDetailsId,
                    materialNumber: ele.materialNumber,
                    invoiceDetailsId: stateProps.update ? ele.invoiceDetailsId : 0,
                    customerInvoiceDetailsTaxList: stateProps.update ? ele.customerInvoiceDetailsTaxList : [],
                    documentDetails: stateProps.update ? ele.documentDetails : [],
                })
            });

           
            props.salesInvoiceDetail.customerInvoiceCOATxList.map((ledgerObj) => {
                if (ledgerObj.txType === LEDGER_TYPE.TYPE_SALES && ledgerObj.accountingEntry === "credit") {
                    selectedLedgers.salesLedgerId = ledgerObj.ledgerAccountId
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_SALES && ledgerObj.accountingEntry === "debit") {
                    selectedLedgers.salesDebtorLedgerId = ledgerObj.ledgerAccountId
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE && ledgerObj.accountingEntry === "credit") {
                    selectedLedgers.expenseLedgerId = ledgerObj.ledgerAccountId
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE && ledgerObj.accountingEntry === "debit") {
                    selectedLedgers.expenseDebtorLedgerId = ledgerObj.ledgerAccountId
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "credit") {
                    selectedLedgers.discountDebtorLedgerId = ledgerObj.ledgerAccountId
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "debit") {
                    selectedLedgers.salesDiscountLedgerId = ledgerObj.ledgerAccountId
                }
                return '';
            });


            const linkProps = (this.props.location && this.props.location.state) || {};
            const rootDiscount = (find((props.salesInvoiceDetail.customerInvoiceCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent || 0;
            const expenseAmount = (find((props.salesInvoiceDetail.customerInvoiceCOATxList || []), { txType: LEDGER_TYPE.TYPE_EXPENSE }) || {}).amount || 0;
            let selectedContact = (props.salesInvoiceDetail.additionalInfoList || [])[0] || {};
            delete selectedContact.additionalInfoId;
            const selectedSalesOrder = [];
            const forceHideTxColumn = [];
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
                //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
            }
            if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
                //forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
            }
            this.setState({
                ...selectedLedgers,
                salesOrderMasterId: props.salesInvoiceDetail.salesOrderMasterId,
                version: props.salesInvoiceDetail.version,
                isUpdate: true,
                invoiceMasterId: stateProps.update ? props.salesInvoiceDetail.invoiceMasterId : 0,
                soNumber: props.salesInvoiceDetail.soNumber,
                relationshipBillingAddress: find(props.salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }),
                customerShippingAddress: find(props.salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.SHIPPING_ADDRESS }),
                customerBillingAddress: find(props.salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.BILLING_ADDRESS }),
                customerPaymentTerm: props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: props.salesInvoiceDetail.paymentTermId }) || {} : {},
                selectedContact: selectedContact,
                customerRef: props.salesInvoiceDetail.referenceNumber,
                invoiceDueDate: props.salesInvoiceDetail.invoiceDueDate ? new Date(props.salesInvoiceDetail.invoiceDueDate): null,
                invoiceDate: props.salesInvoiceDetail.invoiceDate ? new Date(props.salesInvoiceDetail.invoiceDate): null,
                customerPo: props.salesInvoiceDetail.customerPONumber,
                placeOfSupply: props.salesInvoiceDetail.placeOfSupply,
                customerName: props.salesInvoiceDetail.customerName,
                customer: {
                    companyName: props.salesInvoiceDetail.customerName, customerId: props.salesInvoiceDetail.customerId
                },
                customerRemarks: props.salesInvoiceDetail.remarksCustomer,
                internalRemarks: props.salesInvoiceDetail.remarksInternal,
                footer: props.salesInvoiceDetail.footer,
                customerSalesOrderDetailsList: detailList,
                isRootDiscount: rootDiscount ? true : false,
                rootDiscountPercent: rootDiscount,
                expenseId: props.salesInvoiceDetail.expenseId,
                rootDiscountAmount: (find((props.salesInvoiceDetail.customerInvoiceCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amount || 0,
                expenseAmount: expenseAmount,
                projectName: props.salesInvoiceDetail.projectName,
                projectNumber: props.salesInvoiceDetail.projectNumber,
                projectMasterId: props.salesInvoiceDetail.projectMasterId,
                isRootExpense: expenseAmount ? true : false,
                inheritedTxColumn: inheritedTxColumn,
                forceHideTxColumn: forceHideTxColumn,
                tableDataReRendered: true,
                salesQuotationMasterId: props.salesInvoiceDetail.salesQuotationMasterId,
                quotationNumber: props.salesInvoiceDetail.quotationNumber,
                rfqNumber: props.salesInvoiceDetail.rfqNumber,
                rfqMasterId: props.salesInvoiceDetail.rfqMasterId,
                selectedSalesOrder,
                selectedSalesOrderIds: linkProps.soMasterIdArr ? [linkProps.soMasterIdArr] : null,
                isSORadio: linkProps.soMasterIdArr ? true : false,
                isSoConversionToInvoice: selectedSalesOrder.length ? true : false,
                isCashSales: props.salesInvoiceDetail.isCashInvoice,
                oldPayload: stateProps.update ? props.salesInvoiceDetail : {},
                isSoConversion: stateProps.update ? props.salesInvoiceDetail.isSOConversion : 0,
                isProformaConversion: stateProps.update ? props.salesInvoiceDetail.isProformaConversion : 0,
                isShipmentConversion: stateProps.update ? props.salesInvoiceDetail.isShipmentConversion : 0,
                docName: props.salesInvoiceDetail.documentName,
                documentNameId: (find((props.documentList || []), { docName: props.salesInvoiceDetail.documentName })  || {}).documentNameId,
                isAllowPayOnline: selectedContact.paymentGatewayName ? true : false,
                paymentGatewayId: selectedContact.paymentGatewayId,
                paymentGatewayName: selectedContact.paymentGatewayName,
                formName: props.salesInvoiceDetail.formName,
                registrationFormSettingId: props.salesInvoiceDetail.registrationFormSettingId
            });
            if (!this.state.isClone) {
                this.setState({
                    nextTxNumber: props.salesInvoiceDetail.invoiceNumber,
                })
            }
            this.props.resetSalesInvoice();
        }

        if (props.customerDetail && props.customerDetail.customerId) {
            const customer = props.customerDetail;
            const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
            const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
            const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
            const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
            const customerPaymentTerm = this.state.customerPaymentTerm || ( props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: ((customer && customer.paymentTermId) || 0) }) || {} : {});
            let numberOfDays = customerPaymentTerm.numberOfDays;
           let allForms = customer.orgRegistrationList  || []

            this.setState({
                stateCustomerId: null,
                customer,
                customerName: customer.companyName,
                customerPaymentTerm,
                paymentTermId: customerPaymentTerm.paymentTermId,
                paymentTermName: customerPaymentTerm.termName,
                customerShippingAddress: this.state.customerShippingAddress || defaultShippingAddress,
                customerBillingAddress: this.state.customerBillingAddress || defaultBillingAddress,
                placeOfSupply: defaultBillingAddress && defaultBillingAddress.stateName,
                invoiceDueDate: numberOfDays ? addDaysToDate((props.invoiceDate || new Date()), numberOfDays) : null,
                boContactList: customer.boContactList || [],
                allForms
            });
        }

        if (props.allPreferences && props.allPreferences.length) {
            let txPreferences = find(props.allPreferences, { txType: "salesInvoice", templateName: "standard" }) || {};
            if (txPreferences) {
                let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
                txColumns = txColumns.length ? txColumns : (this.state.txMandatoryColumns || []).concat('product', 'description');
                let tablePreference = txPreferences.tablePreference;
                const txColumnSetting = txPreferences.gridPreference ? (((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {}) : {};
                const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;

                this.setState({
                    isColumnFitTable,
                    tablePreference,
                    txColumns,
                    txColumnSetting,
                    txPreferences
                });
            } else {
                this.setState({
                    txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
                })
            }
        }


        if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
            const allRelationshipBillingAddress = [];
            props.boRelationshipAddress.forEach((e) => {
                if (e.locationType === 'RelationshipBillingAddress') {
                    allRelationshipBillingAddress.push(e);
                }
            });
            this.setState({
                allRelationshipBillingAddress
            })
        }else{
            const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            this.setState({
                allRelationshipBillingAddress,
            });
        }
        if (props.customerContactList && props.customerContactList.length) {
            this.setState({
              boContactList: props.customerContactList
            });
          }
       
    }

    componentDidMount() {
        // this.props.updateHeaderState({
        //     collapsed: true
        // })
        const companyInfo = this.props.companyInfo || {};
        const linkProps = (this.props.location && this.props.location.state) || {}
        const payload = {
            customerId: this.props.customerId || linkProps.customerId,
            soMasterId: this.props.soMasterId || linkProps.soMasterId,
            shipmentMasterId: this.props.shipmentMasterId || linkProps.shipmentMasterId,
            soMasterIdArr: this.props.soMasterIdArr || linkProps.soMasterIdArr,
            invoiceMasterId: this.props.invoiceMasterId || linkProps.invoiceMasterId,
            relationshipId: companyInfo.relationshipId,
            userId: (this.props.userInfo || {}).userId,
            relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
            isClone: linkProps.clone,
            pageNumber: 1,
            pageSize: 100,
            module: 'school'
        };
      
        this.props.resetAddresses();
        this.props.resetSalesInvoice();
        payload.invoiceMasterId && fetchDataIfNeeded('fetchSalesInvoiceDetail', 'salesInvoiceDetail', this.props, payload, true);

        payload.customerId && this.props.fetchAddresses({ ...payload, locationType: CONSTANTS.SHIPPING_ADDRESS });
        payload.customerId && this.props.fetchAddresses({ ...payload, locationType: CONSTANTS.BILLING_ADDRESS });
        payload.customerId && this.props.fetchAllContacts(payload);
        payload.customerId && fetchDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerDetail', this.props, payload, true);
        fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
        fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
      
        fetchDataIfNeeded('fetchStates', 'states', this.props, 1);
        fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
        fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
        fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
        fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
        fetchDataIfNeeded('fetchDocuments', 'documentList', this.props, payload);
        if (!linkProps.update) {
            fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
        }
        fetchDataIfNeeded('fetchCountries', 'countries', this.props, payload);
        fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
        if (payload.customerId) {
            this.props.fetchAllUnfulfilledSalesOrderByCustomerId(payload);
        }
        this.props.fetchRemarksTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Sales Invoice' });
        fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
        this.props.getAllPaymentGatewayConfig(payload);
        this.props.getFormNames(payload);
    }



    openSalesInvoiceDetails = (data) => {
        const invoicePayload = {
            customerId: data.customerId,
            invoiceMasterId: data.invoiceMasterId
        }
        this.props.pushModalToStack({
            modalBody: <SalesInvoiceDetails {...this.props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    onSearch(text, updateAllOptions, updateFetching) {
        const payload = {
            relationshipId: (this.props || {}).companyInfo.relationshipId,
            pageNumber: 1,
            searchString: text,
            pageSize: 100,
            updateAllOptions,
            updateFetching
        };
        this.props.fetchProducts(payload)
    }

    render() {
        return (
            <div className="new-invoice-modal">
                <LedgerDrawer {...this.state} updateState={(data) => { this.setState(data) }} />
                {this.state.transportDataDrawerVisible && <TransportDataDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
                <SalesInvoiceComponent
                    onSearch={(text, updateAllOptions, updateFetching) => {
                        this.onSearch(text, updateAllOptions, updateFetching)
                    }}
                    {...this.props} {...this.state} openSalesInvoiceDetails={this.openSalesInvoiceDetails} updateState={(data) => { this.setState(data) }} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        paymentTerms: state.common.paymentTerms,
        customers: state.customer.customers,
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        priceList: state.common.priceList,
        states: state.common.states,
        products: state.salesInvoice.products,
        projectList: state.common.projectList,
        uomList: state.common.uomList,
        soInvoiceData: state.so.soInvoiceData,
        taxes: state.common.taxes,
        soDataBySOId: state.so.soDataBySOId,
        shipmentData: state.shipment.shipmentData,
        expenseTypes: state.common.expenseTypes,
        customerDetail: state.common.customerDetail,
        salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,
        documentList: state.common.documentList,
        nextTxNumber: state.salesInvoice.nextTxNumber,
        allPreferences: state.common.allPreferences,
        remarksData: state.settings.remarksData,
        permissions: state.auth.permissions,
        countries: state.common.countries,
        soUnfulfilledList: state.so.soUnfulfilledList,
        soShipmentList: state.so.shipmentListForInvoice,
        addresses: state.customer.addresses,
        boRelationshipAddress: state.common.boRelationshipAddress,
        allRelationshipTaxIdentificatins: state.common.allRelationshipTaxIdentificatins,
        allPaymentGatewayConfig: state.common.allPaymentGatewayConfig,
        customerContactList: state.customer.contacts,
       // allForms: state.studentRegistration.allForms,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCustomerDetailsByCustomerId,
    createSalesInvoice,
    resetSoInvoiceData,
    fetchSoDataForInvoice,
    fetchPaymentTerms,
    fetchCustomers,
    fetchPriceList,
    fetchStates,
    fetchProjects,
    fetchProducts,
    fetchTaxes,
    fetchUOM,
    addUOM,
    deleteUOM,
    fetchExpenseTypes,
    addExpenseTypes,
    deleteExpenseTypes,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    deleteCustomers,
    addPaymentTerm,
    deletePaymentTerm,
    deleteProject,
    deleteAddress,
    deleteTax,
    deletePriceList,
    fetchDocuments,
    addDocumentName,
    deleteDocumentName,
    getNextTxNumber,
    deleteContact,
    fetchUserPreferences,
    saveUserPrefrences,
    fetchRemarksTemplate,
    resetRemarksData,
    resetSOBySOID,
    fetchSalesOrderSOId,
    resetSalesInvoice,
    fetchCountries,
    fetchAllUnfulfilledSalesOrderByCustomerId,
    resetUnfulfilledSalesOrder,
    fetchShipmentForInvoice,
    fetchSalesInvoiceDetail,
    resetShipmentForInvoice,
    fetchRelationshipTaxIdentifications,
    deleteCustomerTaxIdentifications,
    deleteRelationshipTaxIdentifications,
    createSalesInvoiceDraft,
    resetShipmentData,
    fetchShipmentByShipmentId,
    fetchAddresses,
    getRelationshipAddress,
    resetCustomerDetail,
    resetAddresses,
    getAllPaymentGatewayConfig,
    fetchAllContacts,
    getFormNames,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoice);
