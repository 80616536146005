import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, sortTable, exportExcel } from '../../../utils';
import { Pagination, Menu, Skeleton, Button, Empty, Tabs, Checkbox, Dropdown,Input } from 'antd';
import { CONSTANTS, ICONS } from '../../../static/constants';
import * as find from "lodash.find";
import { DownloadOutlined, FileExcelOutlined ,FilePdfOutlined} from "@ant-design/icons";
import * as debounce from "lodash.debounce";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { Search } = Input;

const SupplierContact = (props) => {
    const { supplierContacts, pageSize, updateState, dir = 0, pageNumber, companyInfo, selectedItems = [] } = props;
    const sortColumn = (e) => {
        sortTable(document.getElementById('contacts-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }
    const handleSearchedText = (searchSupplier, props) => {
        let supplierResult = [];

        if (searchSupplier.length) {
            supplierResult = supplierContacts.filter((user) => {
                return (
                    (user?.firstName && user.firstName.toLowerCase().includes(searchSupplier.toLowerCase())) ||
                    (user?.emailAddress && user.emailAddress.toLowerCase().includes(searchSupplier.toLowerCase())) ||
                    (user?.cellPhone && user.cellPhone.includes(searchSupplier))
                );
            });

            props.updateState({
                supplierResult: [...supplierResult],
                selectedType: props.selectedType
            });
        };
    }

    const onSearchedInputChange = debounce((searchSupplier, props) => {
        handleSearchedText(searchSupplier, props);
    }, 100);
    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            }) +
            ` ${total} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };
    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        fetchPaginationDataIfNeeded("fetchSupplierContacts", "supplierContacts", props, payload);
    };
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        // props.resetPaginatedData();
        props.fetchCustomerContacts(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    };
    const itemSelection = (item) => {
        let selectItem = find(selectedItems, {
            contactId: Number(item.contactId),
        });
        let updatedItems = [];
        if (selectItem) {
            updatedItems = selectedItems.filter(
                (obj) => obj.contactId !== item.contactId
            );
        } else {
            updatedItems = JSON.parse(JSON.stringify(selectedItems));
            updatedItems.push(item);
        }
        props.updateState({ selectedItems: updatedItems });
    };
    const selectAll = (context) => {
        if (!context) {
            selectedItems.splice(0, selectedItems.length);
            props.updateState({ selectedItems: selectedItems });
            return;
        }
        for (let i = 0; i < supplierContacts?.length; i++) {
            let item = supplierContacts[i];
            let selectItem = find(selectedItems, {
                contactId: Number(item.contactId),
            });
            if (!selectItem) {
                selectedItems.push(item);
            }
        }
        props.updateState({ selectedItems: selectedItems });
    };

    const isCheckedAll = () => {
        if (
            !supplierContacts ||
            supplierContacts.length === 0
        ) {
            return false;
        }
        for (let i = 0; i < supplierContacts.length; i++) {
            let usr = supplierContacts[i];
            let selectItem = find(selectedItems, {
                contactId: Number(usr.contactId),
            });
            if (!selectItem) {
                return false;
            }
        }
        return true;
    };
    const exportExcelData = (selectedContactList, props) => {
        const dataList = (selectedContactList || []).map((item) => {
            return {
                "Full Name": (item.salutationName || "") + " " + (item.firstName || "") + " " + (item.lastName || ""),
                "Email": item.emailAddress || "",
                "Phone Number": item.cellPhone || "",

            };
        });
        exportExcel(dataList, "Supplier");
    };
    const downloadMenu = () => {
        return (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Download as
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item
                    key="1"
                    value="excel"
                    onClick={() => {
                        if (selectedItems.length) {
                            exportExcelData(selectedItems);
                        } else {
                            const modalData = {
                                title: (
                                    <div className="title">
                                        <i className="fa fa-ban mr15" />
                                        Validation Failed
                                    </div>
                                ),
                                modalBody: (
                                    <Fragment>
                                        <div className="warning-message">
                                            No customer selected. Please select customer before
                                            downloading.
                                        </div>
                                        <div className="button-action">
                                            <Button
                                                className="ant-btn-default"
                                                onClick={() => props.popModalFromStack()}
                                            >
                                                OK
                                            </Button>
                                        </div>
                                    </Fragment>
                                ),
                                hideFooter: true,
                                wrapClassName: "validation-failed-modal",
                            };
                            props.pushModalToStack(modalData);
                        }
                    }}
                >
                    <FileExcelOutlined />
                    Excel File
                </Menu.Item>
                <Menu.Item
                    key="2"
                    value="pdf"
                    onClick={() => {
                      const customerList = [];
                      selectedItems.forEach((data) => {
                        // let data = obj.data;
                        let address =
                          find(data.boLocationList, {
                            isDefault: 1,
                            locationType: "BillingAddress",
                          }) || {};
                        let contact =
                          data.boContactList &&
                          find(data.boContactList, { isPrimaryContact: 1 });
                        customerList.push({
                          ...data,
                          defaultAddress: address,
                          defaultContact: contact,
                        });
                      });
                      if (customerList.length) {
                        props.generatePDF({
                          companyInfo: props.companyInfo,
                          fileName: "Supplier.pdf",
                          transactionName: "supplierListing",
                          customerList: customerList,
                        });
                      } else {
                        const modalData = {
                          title: (
                            <div className="title">
                              <i className="fa fa-ban mr15" />
                              Validation Failed
                            </div>
                          ),
                          modalBody: (
                            <Fragment>
                              <div className="warning-message">
                                No customer selected. Please select customer before
                                downloading.
                              </div>
                              <div className="button-action">
                                <Button
                                  className="ant-btn-default"
                                  onClick={() => props.popModalFromStack()}
                                >
                                  OK
                                </Button>
                              </div>
                            </Fragment>
                          ),
                          hideFooter: true,
                          wrapClassName: "validation-failed-modal",
                        };
                        props.pushModalToStack(modalData);
                      }
                    }}
                  >
                    <FilePdfOutlined />
                    PDF File
                  </Menu.Item>
            </Menu>
        );
    };
    return (
        <>
            <div className="view-container">
                <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">Supplier List</div>

                    </div>

                    <div className="right-actions">
                        <Search
                            placeholder="Search"
                            value={props.searchSupplier}
                            onChange={(e) => {
                                props.updateState({ searchSupplier: e.target.value, pageNumber: 1 });
                                onSearchedInputChange(e.target.value, props);
                            }}
                            onSearch={(searchSupplier) => {
                                props.updateState({ searchSupplier, pageNumber: 1 });
                                onSearchedInputChange(searchSupplier, props);
                                handleSearchedText(searchSupplier, props);
                            }}
                        />


                        <Dropdown overlay={downloadMenu()} trigger={["click"]}>
                            <span>
                                <DownloadOutlined /> &nbsp;{" "}
                                <FormattedMessage
                                    id="common.action.dropdown.downloadAs"
                                    defaultMessage=""
                                />
                            </span>
                        </Dropdown>
                    </div>
                </div>
                <Skeleton loading={props.listLoading} paragraph={{ rows: 17 }} active>
                    <div className='table-container table-h-profile'
                        style={{ height: "calc(100% - 144px)" }}>
                        <table id='contacts-table'>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="check-cell">
                                            <Checkbox
                                                onChange={(e) => {
                                                    selectAll(e.target.checked);
                                                }}
                                                checked={isCheckedAll()}
                                            />

                                        </div>
                                    </th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.fullName' defaultMessage="" /></th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.email' defaultMessage="" /></th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.phone' defaultMessage="" /></th>
                                    <th onClick={sortColumn}><FormattedMessage id='contact.mobile' defaultMessage="" /></th>
                                    {/* <th onClick={sortColumn}><FormattedMessage id='contact.faxNumber' defaultMessage="" /></th> */}
                                    <th onClick={sortColumn}><FormattedMessage id='contact.primaryAddress' defaultMessage="" /></th>
                                    <th onClick={sortColumn}><FormattedMessage id='common.dateCreated' defaultMessage="" /></th>
                                    {/* <th className='text-center'><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {/* {supplierContacts && supplierContacts.length ? supplierContacts. */}
                                {((props.supplierResult && props.supplierResult.length > 0) || !props.searchSupplier) &&
                            (props.searchSupplier ? props.supplierResult : supplierContacts).
                                    sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1)).map((e, i) => {
                                        return <tr key={i}>
                                            {/* <td>{i + 1}</td> */}
                                            <td>
                                                <Checkbox
                                                    onClick={() => {
                                                        itemSelection(e);
                                                    }}
                                                    checked={
                                                        find(selectedItems, {
                                                            contactId: Number(e.contactId),
                                                        })
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </td>
                                            {/* <td>
                                                        {e.salutationName ? e.salutationName : <div className="empty-data-box"></div>}
                                                    </td>
                                                    <td>
                                                    {e.firstName ? e.firstName : <div className="empty-data-box"></div>}
                                                    </td>
                                                    <td>
                                                    {e.lastName ? e.lastName : <div className="empty-data-box"></div>}
                                                </td> */}
                                            <td>{e.salutationName}{" "}{e.firstName}{" "}{e.lastName}</td>
                                            <td>
                                                {e.emailAddress ? e.emailAddress : <div className="empty-data-box"></div>}
                                            </td>
                                            <td>
                                                {e.workPhone ? e.workPhone : <div className="empty-data-box"></div>}
                                            </td>
                                            <td>
                                                {e.cellPhone ? e.cellPhone : <div className="empty-data-box"></div>}
                                            </td>
                                            {/* <td>
                                            {e.faxNumber ? e.faxNumber : <div className="empty-data-box"></div>}
                                        </td> */}
                                            <td>{e.isDefault ? "yes" : "No"}</td>
                                            <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : ''}</td>
                                        
                                        </tr>
                                    }) ||
                                    <tr key="empty-data-box">
                                        <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                            <Empty />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Skeleton>
                {/* <div className="footer">
                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            // total={contacts.length}
                            showTotal={showTotal}
                            defaultPageSize={25}
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber });
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props);
                            }}
                        />
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default SupplierContact;