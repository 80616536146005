import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PartyLedgerComponent from '../../../../../../components/modal/modalBody/reports/supplier/partyLedger';
import { fetchSuppliers } from '../../../../../supplier/Listing/action';
import { fetchPaginationDataIfNeeded } from '../../../../../../utils';
import { exportLedgerPdf, resetSupplierLedgerData } from '../../../../../supplier/detail/action';
import { generatePDF, printPDF } from '../../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../../containers/drawer/email';

class SupplierPartyLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ledgerStartDate: null,
            ledgerEndDate: null
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
          companyInfo,
          relationshipId: companyInfo.relationshipId,
          userId: (this.props.userInfo || {}).userId,
          pageNumber: 1,
          pageSize: 500
        };
        fetchPaginationDataIfNeeded('fetchSuppliers', 'suppliers', this.props, payload, true);
        this.props.resetSupplierLedgerData();
    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <PartyLedgerComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        suppliers: state.supplier.suppliers,
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        supplierLedger: state.supplier.supplierLedger,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSuppliers,
    exportLedgerPdf,
    generatePDF,
    printPDF,
    resetSupplierLedgerData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierPartyLedger);