import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';

import MultiCurrencyPurchaseOrder from '../../../../components/supplier/purchaseOrder/MultiCurrencyPurchaseOrder';
import {
  fetchPriceList, fetchPaymentTerms, addPaymentTerm, deletePaymentTerm,
  fetchProjects, fetchStates, fetchTaxes,
  fetchUOM, addUOM, deleteUOM, fetchExpenseTypes, addExpenseTypes, deleteExpenseTypes, fetchProducts,
  showModal, hideModal, pushModalToStack, popModalFromStack,
  deleteProject, deleteAddress, deleteTax, deletePriceList,
  fetchDocuments, addDocumentName, deleteDocumentName, deleteContact, fetchCountries,
  fetchUserPreferences, saveUserPrefrences, fetchShippingInstructions, addShippingInstruction, deleteShippingInstruction, fetchRelationshipTaxIdentifications,
  deleteRelationshipTaxIdentifications, deleteSupplierTaxIdentifications,
  fetchSupplierSummary, fetchSupplierDetailsBySupplierId, resetSupplierDetail, getRelationshipAddress
} from '../../../../actions/commonActions';
import LedgerDrawer from '../../../../containers/drawer/ledgers/PurchaseOrderLedger';
import { createPO, fetchRfqDataForPo, resetRfqPoData, getNextTxNumber, createPODraft } from './action'
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import PurchaseOrderDetail from '../../../modal/modalBody/supplier/PurchaseOrderDetail';
import { CONSTANTS, LEDGER_ACTION_LIST } from '../../../../static/constants';
import { GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import { deleteSuppliers } from '../../Listing/action';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import { fetchAddresses } from '../../Listing/ShowAddress/action';
import { fetchAllContacts } from '../../Listing/ShowConact/action';

class PurchaseInvoice extends Component {
  constructor(props) {
    super(props);
    const defaultPurchaseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.PURCHASE }) || {};
    const defaultTaxLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_TAX }) || {};
    const defaultDiscountLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DISCOUNT_RECIEVED }) || {};
    const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.SUPPLIER_EXPENSE }) || {};
    const defaultCreditorLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CREDITORS }) || {};
    const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const allRelationshipShippingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS }) || [];
    const relationshipBillingAddress = allRelationshipBillingAddress.find(x => x.isDefault) || {};
    const relationshipShippingAddress = allRelationshipShippingAddress.find(x => x.isDefault) || {};
    const placeOfSupply = (relationshipBillingAddress || {}).stateName;
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      stateSupplierId: linkProps.supplierId,
      pageNumber: linkProps.pageNumber || 1,
      supplier: undefined,
      allRelationshipBillingAddress,
      allRelationshipShippingAddress,
      relationshipBillingAddress,
      relationshipShippingAddress,
      placeOfSupply,
      priceListName: undefined,
      poDate: new Date(),
      pageSize: 2000,
      igstPurchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      igstPurchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      igstPurchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      igstDiscountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      igstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      igstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      igstPurchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      igstPurchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      
      cgstPurchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      cgstPurchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      cgstPurchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      cgstDiscountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      cgstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      cgstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      cgstPurchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      cgstPurchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      
      sgstPurchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      sgstPurchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      sgstPurchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      sgstDiscountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      sgstPurchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      sgstTaxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      sgstPurchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      sgstPurchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseLedgerId: defaultPurchaseLedger.ledgerAccountId,
      purchaseCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseDiscountLedgerId: defaultDiscountLedger.ledgerAccountId,
      discountCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseTaxLedgerId: defaultTaxLedger.ledgerAccountId,
      taxCreditorsLedgerId: defaultCreditorLedger.ledgerAccountId,
      purchaseExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      purchaseExpenseCreditorLedgerId: defaultCreditorLedger.ledgerAccountId,
      companyInfo: props.companyInfo,
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "purchaseOrder", templateName: "multicurrency" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === 'India',
        basePrice: true,
        specialDiscount: true,
        discount: true,
        //tax: true
      },
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      rfqMasterId: this.props.rfqMasterId || linkProps.rfqMasterId,
      userId: (this.props.userInfo || {}).userId,
      supplierId: linkProps.supplierId,
      pageNumber: 1,
      pageSize: 2000
    };

    this.props.resetRfqPoData();
    payload.rfqMasterId && fetchDataIfNeeded('fetchRfqDataForPo', 'rfqPoData', this.props, payload, true);
    this.props.resetSupplierDetail();
    payload.supplierId && this.props.fetchSupplierDetailsBySupplierId(payload);
    fetchPaginationDataIfNeeded('fetchSupplierSummary', 'supplierSummary', this.props, payload, true);
    fetchDataIfNeeded('fetchPriceList', 'priceList', this.props, payload);
    fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
    fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
    fetchDataIfNeeded('fetchStates', 'states', this.props, 1);
    fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
    fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
    fetchDataIfNeeded('fetchExpenseTypes', 'expenseTypes', this.props, payload);
    fetchDataIfNeeded('fetchDocuments', 'documentList', this.props, payload);
    if (!linkProps.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
    fetchDataIfNeeded('fetchCountries', 'countries', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    fetchDataIfNeeded('fetchShippingInstructions', 'allShippingInstruction', this.props, payload);
    this.props.fetchRemarksTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Purchase Order' });
    fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
  }

  openPODetails = (data) => {
    const poPayload = {
      supplierId: data.supplierId,
      poMasterId: data.poMasterId
    }
    this.props.pushModalToStack({
      modalBody: <PurchaseOrderDetail {...this.props} poPayload={poPayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }


  componentWillReceiveProps(props) {

    if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
      const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
      let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
        return obj.relationshipTaxIdentificationId;
      })
      this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
    }
    if (!this.state.remarksCustomer && props.remarksData && props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ remarksCustomer: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500)
    }
    const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const allRelationshipShippingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS }) || [];
    this.setState({
      allRelationshipBillingAddress,
      allRelationshipShippingAddress,
    });
    if (props.rfqPoData && props.rfqPoData.rfqMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
      }
      props.rfqPoData.rfqDetailsList.map((ele) => {
        _emptyColumnCheck(ele);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.itemVariantId],
          product: ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || ""),
          productObj: { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku },
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.specialDiscount
        })
      });

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      this.setState({
        version: props.rfqPoData.version,
        isRFQConversion: true,
        rfqNumber: props.rfqPoData.rfqNumber,
        rfqMasterId: props.rfqPoData.rfqMasterId,
        purchaseRequestNumber: props.rfqPoData.purchaseRequestNumber,
        reference: props.rfqPoData.refNumber,
        projectMasterId: props.rfqPoData.projectMasterId,
        projectName: props.rfqPoData.projectName,
        projectNumber: props.rfqPoData.projectNumber,
        internalRemarks: props.rfqPoData.remarksInternal,
        supplierRemarks: props.rfqPoData.remarksSupplier,
        poDetailsList: detailList,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
      });
      props.resetRfqPoData()
    }

    if (this.state.stateSupplierId && props.supplierDetail && props.supplierDetail.supplierId && props.paymentTerms && props.paymentTerms.length) {
      const supplierObj = props.supplierDetail || {};
      const supplierPaymentTerm = find(props.paymentTerms, { paymentTermId: supplierObj.paymentTermId });
      const allSupplierBillingAddress = filter(supplierObj.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS });
      const defaultBillingAddress = find(allSupplierBillingAddress, { isDefault: 1 });
      const selectedContact = (supplierObj.boContactList || []).find(x => x.isPrimaryContact) || {};
      const selectedList = (supplierObj && filter(supplierObj.supplierTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
      let selectedSupplierTaxIdentifications = selectedList.map(obj => {
        return obj.supplierTaxIdentificationId;
      })
      this.setState({
        stateSupplierId: null,
        supplierAddresses: (supplierObj.boLocationList || []).filter(x => x.locationType === CONSTANTS.TYPE_SHIPPING_ADDRESS) || [],
        supplier: { id: supplierObj.supplierId, supplierId: supplierObj.supplierId, name: supplierObj.supplierStoreName, gstNumber: supplierObj.gstNumber, panNumber: supplierObj.panNumber },
        supplierPaymentTerm,
        supplierBillingAddress: defaultBillingAddress,
        selectedContact,
        selectedSupplierTaxIdentifications,
        allBillingAddress: allSupplierBillingAddress,
        boContactList: supplierObj.boContactList || [],
      });
    }
    if (props.addresses) {
      if (props.addresses.BillingAddress && props.addresses.BillingAddress.length) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress
        });
      }
    }
    if (props.supplierContactList && props.supplierContactList.length) {
        this.setState({
          boContactList: props.supplierContactList
        });
    }
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "purchaseOrder", templateName: "multicurrency" }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns : (this.state.txMandatoryColumns || []).concat('product', 'description')
        const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        })
      }
    }

  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 2000,
      updateAllOptions,
      updateFetching
    };
    this.props.fetchProducts(payload)
  }

  render() {
    return (
      <Fragment>
        <LedgerDrawer {...this.state} updateState={(data) => { this.setState(data) }} />
        <MultiCurrencyPurchaseOrder
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching)
          }}
          {...this.props}
          {...this.state}
          openPODetails={this.openPODetails}
          updateState={(data) => { this.setState(data) }}
        />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    priceList: state.common.priceList,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    supplierAddresses: state.supplier.supplierAddresses,
    paymentTerms: state.common.paymentTerms,
    states: state.common.states,
    projectList: state.common.projectList,
    uomList: state.common.uomList,
    rfqPoData: state.purchaseOrder.rfqPoData,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    products: state.salesInvoice.products,
    documentList: state.common.documentList,
    nextTxNumber: state.purchaseOrder.nextTxNumber,
    countries: state.common.countries,
    allPreferences: state.common.allPreferences,
    allShippingInstruction: state.common.allShippingInstruction,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    allRelationshipTaxIdentificatins: state.common.allRelationshipTaxIdentificatins,
    supplierSummary: state.supplier.supplierSummary,
    supplierDetail: state.common.supplierDetail,
    addresses: state.supplier.addresses,
    supplierContactList: state.supplier.contacts
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  resetRfqPoData,
  fetchRfqDataForPo,
  fetchPriceList,
  fetchCountries,
  fetchProjects,
  fetchPaymentTerms,
  addPaymentTerm,
  deletePaymentTerm,
  fetchStates,
  fetchProducts,
  fetchTaxes,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  showModal,
  hideModal,
  createPO,
  pushModalToStack,
  popModalFromStack,
  deleteSuppliers,
  deleteProject,
  deleteAddress,
  deleteTax,
  deletePriceList,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  getNextTxNumber,
  resetRemarksData,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchShippingInstructions,
  addShippingInstruction,
  deleteShippingInstruction,
  fetchRemarksTemplate,
  fetchRelationshipTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  deleteSupplierTaxIdentifications,
  createPODraft,
  fetchSupplierSummary, 
  fetchSupplierDetailsBySupplierId, 
  resetSupplierDetail,
  fetchAddresses,
  fetchAllContacts,
  getRelationshipAddress
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoice);
