import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddLandedCostComponent from '../../../../../components/modal/modalBody/supplier/AddLandedCost';
import { pushModalToStack, popModalFromStack, fetchSupplierSummary, fetchTaxes, fetchCountries } from '../../../../../actions/commonActions';
import { fetchLandedCostSetupList } from '../../../../supplier/landedCostSetup/actions';
import { fetchAllLedgerAccounts } from '../../../../finance/LedgerAccounts/action';
import { createLandingCostSetup } from './action';

class AddLandedCostSupplier extends Component {
    constructor(props){
        super(props);
        this.state= {
        }
    }
    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 1000,
        };

        this.props.fetchLandedCostSetupList(payload);
        this.props.fetchSupplierSummary(payload);
        this.props.fetchAllLedgerAccounts(payload);
        this.props.fetchTaxes(payload);
        this.props.fetchCountries(payload);
    }

    render() {
        return <AddLandedCostComponent {...this.props} {...this.state} updatePopupState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        landedCostSetupList: state.supplier.landedCostSetupList,
        suppliersList: state.supplier.supplierSummary,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        taxes: state.common.taxes,
        countries: state.common.countries,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({

    fetchSupplierSummary,
    pushModalToStack, 
    popModalFromStack,
    fetchLandedCostSetupList,
    fetchAllLedgerAccounts,
    fetchTaxes,
    fetchCountries,
    createLandingCostSetup,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddLandedCostSupplier);