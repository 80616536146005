import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SODetailComponent from '../../../../../components/modal/modalBody/customer/AmendSoDetail';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchAmendSODetail, resetSODetails} from './action';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import { fetchSalesOrders, getSalesOrderCount, deleteSalesOrder, updateQualityCheckList, updateUserStatus,cancelSalesOrder,requestCancelSalesOrder } from '../../../../customer/salesOrder/Listing/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';
import QualityCheckDrawer from '../../../../../containers/drawer/qualityCheck';
import { getQualityChecklistSettings, deleteAttectedFile } from '../../../../../actions/commonActions';
import { fetchDataIfNeeded } from '../../../../../utils';
import {  getCardConnectConfiguration} from '../../../../integrations/Payment/action'

class SODetail extends Component {

    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            templateName: 'Professional'
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.soPayload.customerId, amendSalesOrderMasterId: this.props.soPayload?.amendSalesOrderMasterId, gatewayName:'CardPointe' };
      
        // this.props.fetchAmendSODetail();
        this.props.fetchAmendSODetail(payload);
        this.props.getCardConnectConfiguration(payload);
        fetchDataIfNeeded('getQualityChecklistSettings', 'qualityChecklistSetting', this.props, payload);
    }

    render() {
        return <>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.qualityCheckDrawerVisible && <QualityCheckDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <SODetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        soDetail: state.so.soDetail,
        userInfo: state.common.userInfo,
        permissions: state.auth.permissions,
        qualityChecklistSetting: state.settings.qualityChecklistSetting,
        detailLoading: state.common.detailLoading,
        cardConnectConfigData: state.common.cardConnectConfigData,
        
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({

    resetSODetails,
    downloadFileFromBucket,
    generatePDF, 
    printPDF, 

    fetchSalesOrders,
    getSalesOrderCount,

    deleteSalesOrder,
    updateQualityCheckList,
    getQualityChecklistSettings,
    updateUserStatus,
    fetchAmendSODetail,
    deleteAttectedFile,
    cancelSalesOrder,requestCancelSalesOrder,
    getCardConnectConfiguration
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SODetail);