import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PledgesComp from "../../../components/fundraising/pledges";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getPledge, pledgeCount, deletePledge, resetPaginatedData } from "./action";
import { showModal, hideModal } from './../../../actions/commonActions';
import { FormattedMessage } from 'react-intl';

class Pledges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.fundRaising.pledge' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
        {
          name: <FormattedMessage id='sidebar.menuItem.fundraising' defaultMessage='' />
        },
        {
          name: <FormattedMessage id='sidebar.menuItem.pledges' defaultMessage='' />,
        }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded("getPledge", "pledgeList", this.props, payload);
    fetchDataIfNeeded("pledgeCount", "pledgeListCount", this.props, payload);
  }

  render() {
    return (
      <PledgesComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></PledgesComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    pledgeList: state.fundraisingReducer.pledgeList,
    pledgeListCount: state.fundraisingReducer.pledgeListCount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPledge,
      pledgeCount,
      deletePledge,
      resetPaginatedData,
      showModal,
      hideModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Pledges);
