import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from '../../../config/environmentConfig';
import { bindActionCreators } from 'redux';
import SalesChannelComponent from '../../../components/integrations/SalesChannel'
import { fetchLinkedMarketplace, saveMWSToken, saveMarketplaceConfiguration, disconnectConfiguration } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import queryString from 'query-string';
import { getCompanyInfo } from '../../../utils';
class SalesChannels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            marketplaceConfig: {}
        };
    }

    componentDidMount() {
        const linkProps = queryString.parse(this.props.location.search) || {};
        const companyInfo = this.props.companyInfo || getCompanyInfo() || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
        };
        if (linkProps.amazon_callback_uri) {
            //  this.props.saveMWSToken({...linkProps, relationshipId: companyInfo.relationshipId});
            window.location = linkProps.amazon_callback_uri + `?redirect_uri=${config.REDIRECT_URI_FOR_AMAZON}&amazon_state=${linkProps.amazon_state}&state=example&version=beta&selling_partner_id=${linkProps.selling_partner_id}`
        }
        if (linkProps.spapi_oauth_code) {
            //send code and partener Id to backend
            this.props.saveMWSToken({ ...linkProps, relationshipId: companyInfo.relationshipId });
        }

        // const companyInfo = this.props.companyInfo || getCompanyInfo() || {};
        // const linkProps = queryString.parse(this.props.location.search) || {}
        // const payload = {
        //   code: this.props.code || linkProps.code,
        //   relationshipId: companyInfo.relationshipId
        // };
        // payload.code && this.props.generateToken(payload, this.props);
        // this.props.fetchLinkedMarketplace(payload);
        this.props.fetchLinkedMarketplace(payload);
    }

    componentWillReceiveProps(props) {
        if (props.linkedMarketplaceList && props.linkedMarketplaceList.length) {
            let marketplaceConfig = {};
            props.linkedMarketplaceList.map((marketplace) => {
                marketplaceConfig[marketplace.marketplaceName] = marketplace;
                return '';
            })
            this.setState({ marketplaceConfig })
        }
    }

    render() {
        return <SalesChannelComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        xeroConfigData: state.common.xeroConfigData,
        linkedMarketplaceList: state.integration.linkedMarketplaceList
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchLinkedMarketplace,
    disconnectConfiguration,
    saveMWSToken,
    saveMarketplaceConfiguration,
    showModal, hideModal, pushModalToStack, popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesChannels);
