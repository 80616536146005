import React from 'react';
import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SO_ACTION_LIST, COMMON_ACTIONS, SHIPMENT_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
import StandardSalesInvoice from '../../../../customer/invoice/StandardSalesInvoice';
import MultiCurrencySalesInvoice from '../../../../customer/invoice/MultiCurrencySalesInvoice';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchDeliveryNotesDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    dispatch({ type: SO_ACTION_LIST.SO_DELIVERY_NOTES_DETAIL_RESET });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/shipment/getShipmentDataByShipmentMasterId?shipmentMasterId=${payload.shipmentMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SO_ACTION_LIST.SO_DELIVERY_NOTES_DETAIL, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.SO_DELIVERY_NOTES_DETAIL;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}


export const resetDeliveryNotesDetail = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.SO_DELIVERY_NOTES_DETAIL_RESET });
  }
}


export const canInvoiceBeGenerated = (deliveyNotesData, props, soIds) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/canInvoiceBeGenerated?relationshipId=${deliveyNotesData.relationshipId}&customerId=${deliveyNotesData.customerId}&soMasterIdArr=${soIds}&invoiceSource=3`)
      .then(res => {
        //if (res.data) {
          props.popModalFromStack();
          props.hideModal();
          deliveyNotesData.isMultiCurrency ?     props.pushModalToStack({
            modalBody: <MultiCurrencySalesInvoice {...props } 
            customerId= {deliveyNotesData.customerId}
              shipmentMasterId= {deliveyNotesData.shipmentMasterId}
            />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            wrapClassName: 'new-transaction-wrapper',
  
        }) :
        props.pushModalToStack({
          modalBody: <StandardSalesInvoice {...props } 
          customerId= {deliveyNotesData.customerId}
            shipmentMasterId= {deliveyNotesData.shipmentMasterId}
          />,
          width: '100%',
          hideTitle: true,
          hideFooter: true,
          wrapClassName: 'new-transaction-wrapper',

      })
          // props.history.push({
          //   pathname: deliveyNotesData.isMultiCurrency ? 'sales-invoice-multi-currency' : '/admin/sales-invoice',
          //   state: {
          //     customerId: deliveyNotesData.customerId,
          //     shipmentMasterId: deliveyNotesData.shipmentMasterId
          //   }
          // })
       // }
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

export const getPurchasedShipment = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios.get(`${config.API_BASE_URL}/alpide-ep/epCarrier/getPurchasedShipment?relationshipId=${payload.relationshipId}&shipmentMasterId=${payload.shipmentMasterId}`)
          .then(res => {
              let data = res.data
              dispatch({ type: SHIPMENT_ACTION_LIST.BOUGHT_SHIPMENT, data });
              dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch(err => {
              dispatch({ type: SHIPMENT_ACTION_LIST.BOUGHT_SHIPMENT});
              showToasterMessage({ messageType: 'error', description: lang[((err.res || {}).data || {}).message] || 'Some error occurred' })
              dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
              ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
  }
}
