import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { SALES_INVOICE_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const getEInvoiceDetail = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideIrn/getGeneratedIrn?irn=${payload.irn}&relationshipId=${payload.relationshipId}
`)
        .then(res => {

           const { data } = res
           dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_E_INVOICE_DETAIL, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        //   dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_LIST_PAGE_RESET });
        //   props.fetchSalesInvoices({ ...props, relationshipId: payload.relationshipId });

        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            // showToasterMessage({
            //   description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            // })
          }
        })
    }
  }