import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Checkbox } from "antd";
import "./general.scss";
import { GRID_PREFERENCE_CONSTANTS } from "../../static/agGridConstants";

export function AddAndRemoveSupplierListingColumn(props) {
  const {
    txAvailableColumn = {},
    txColumns = [],
    txMandatoryColumns = [],
  } = props;

  const [poNumber, updatePoNumber] = useState(
    txColumns.indexOf("poNumber") > -1 ||
      txMandatoryColumns.indexOf("poNumber") > -1
  );
  const [supplierName, updateSupplierName] = useState(
    txColumns.indexOf("supplierName") > -1 ||
      txMandatoryColumns.indexOf("supplierName") > -1
  );
  const [totalPOAmount, updateTotalPOAmount] = useState(
    txColumns.indexOf("totalPOAmount") > -1 ||
      txMandatoryColumns.indexOf("totalPOAmount") > -1
  );
  const [status, updateStatus] = useState(
    txColumns.indexOf("status") > -1 ||
      txMandatoryColumns.indexOf("status") > -1
  );
  const [reference, updateReference] = useState(
    txColumns.indexOf("reference") > -1 ||
      txMandatoryColumns.indexOf("reference") > -1
  );
  const [projectName, updateProjectName] = useState(
    txColumns.indexOf("projectName") > -1 ||
      txMandatoryColumns.indexOf("poDprojectNameate") > -1
  );
  const [isRFQCconversion, updateIsRFQCconversion] = useState(
    txColumns.indexOf("isRFQCconversion") > -1 ||
      txMandatoryColumns.indexOf("isRFQCconversion") > -1
  );
  const [rfqNumber, updateRFQNumber] = useState(
    txColumns.indexOf("rfqNumber") > -1 ||
      txMandatoryColumns.indexOf("rfqNumber") > -1
  );
  const [expectedDeliveryDate, updateExpectedDeliveryDate] = useState(
    txColumns.indexOf("expectedDeliveryDate") > -1 ||
      txMandatoryColumns.indexOf("expectedDeliveryDate") > -1
  );
  const [poDate, updatePoDate] = useState(
    txColumns.indexOf("poDate") > -1 ||
      txMandatoryColumns.indexOf("poDate") > -1
  );
  const [paymentTermName, updatePaymentTermName] = useState(
    txColumns.indexOf("paymentTermName") > -1 ||
      txMandatoryColumns.indexOf("paymentTermName") > -1
  );
  const [totalQtyReceived, updateTotalQtyReceived] = useState(
    txColumns.indexOf("totalQtyReceived") > -1 ||
      txMandatoryColumns.indexOf("totalQtyReceived") > -1
  );
  const [totalQtyOnId, upadteTotalQtyOnId] = useState(
    txColumns.indexOf("totalQtyOnId") > -1 ||
      txMandatoryColumns.indexOf("totalQtyOnId") > -1
  );
  const [userStatus, updateUserStatus] = useState(
    txColumns.indexOf("userStatus") > -1 ||
      txMandatoryColumns.indexOf("userStatus") > -1
  );

  const [customerInquiryNumber, updateCustomerInquiryNumber] = useState(
    txColumns.indexOf("customerInquiryNumber") > -1 ||
      txMandatoryColumns.indexOf("customerInquiryNumber") > -1
  );
  const [customerPONumber, updateCustomerPONumber] = useState(
    txColumns.indexOf("customerPONumber") > -1 ||
      txMandatoryColumns.indexOf("customerPONumber") > -1
  );
  const [customerRFQNumber, updateCustomerRFQ] = useState(
    txColumns.indexOf("customerRFQNumber") > -1 ||
      txMandatoryColumns.indexOf("customerRFQNumber") > -1
  );

  const [inboundDeliveryNumber, updateInboundDeliveryNumber] = useState(
    txColumns.indexOf("inboundDeliveryNumber") > -1 ||
      txMandatoryColumns.indexOf("inboundDeliveryNumber") > -1
  );
  const [inboundDeliveryDate, updateInboundDeliveryDate] = useState(
    txColumns.indexOf("inboundDeliveryDate") > -1 ||
      txMandatoryColumns.indexOf("inboundDeliveryDate") > -1
  );
  const [inquiryNumber, updateInquiryNumber] = useState(
    txColumns.indexOf("inquiryNumber") > -1 ||
      txMandatoryColumns.indexOf("inquiryNumber") > -1
  );
  const [soNumber, updateSoNumber] = useState(
    txColumns.indexOf("soNumber") > -1 ||
      txMandatoryColumns.indexOf("soNumber") > -1
  );
  const [statusInvoice, updateStatusInvoice] = useState(
    txColumns.indexOf("statusInvoice") > -1 ||
      txMandatoryColumns.indexOf("statusInvoice") > -1
  );

  const [supplierStoreName, updateSupplierStoreName] = useState(
    txColumns.indexOf("supplierStoreName") > -1 ||
      txMandatoryColumns.indexOf("supplierStoreName") > -1
  );
  const [contact, updateContact] = useState(
    txColumns.indexOf("contact") > -1 ||
      txMandatoryColumns.indexOf("contact") > -1
  );
  const [totalAmountDue, updateTotalAmountDue] = useState(
    txColumns.indexOf("totalAmountDue") > -1 ||
      txMandatoryColumns.indexOf("totalAmountDue") > -1
  );
  const [openedOrdersCount, updateOpenedOrdersCount] = useState(
    txColumns.indexOf("openedOrdersCount") > -1 ||
      txMandatoryColumns.indexOf("openedOrdersCount") > -1
  );
  const [paidInvoicesCount, updatePaidInvoicesCount] = useState(
    txColumns.indexOf("paidInvoicesCount") > -1 ||
      txMandatoryColumns.indexOf("paidInvoicesCount") > -1
  );
  const [unpaidInvoicesCount, updateUnpaidInvoicesCount] = useState(
    txColumns.indexOf("unpaidInvoicesCount") > -1 ||
      txMandatoryColumns.indexOf("unpaidInvoicesCount") > -1
  );

  const [isXeroUploaded, updateIsXeroUploaded] = useState(
    txColumns.indexOf("isXeroUploaded") > -1 ||
      txMandatoryColumns.indexOf("isXeroUploaded") > -1
  );
  const [invoiceNumber, updateInvoiceNumber] = useState(
    txColumns.indexOf("invoiceNumber") > -1 ||
      txMandatoryColumns.indexOf("invoiceNumber") > -1
  );
  const [txNumber, updateTxNumber] = useState(
    txColumns.indexOf("txNumber") > -1 ||
      txMandatoryColumns.indexOf("txNumber") > -1
  );
  const [supplierPoNumber, updateSupplierPoNumber] = useState(
    txColumns.indexOf("supplierPoNumber") > -1 ||
      txMandatoryColumns.indexOf("supplierPoNumber") > -1
  );
  const [invoiceTotalAmount, updateInvoiceTotalAmount] = useState(
    txColumns.indexOf("invoiceTotalAmount") > -1 ||
      txMandatoryColumns.indexOf("invoiceTotalAmount") > -1
  );
  const [totalPaymentMade, updateTotalPaymentMade] = useState(
    txColumns.indexOf("totalPaymentMade") > -1 ||
      txMandatoryColumns.indexOf("totalPaymentMade") > -1
  );
  const [invoiceDueAmount, updateInvoiceDueAmount] = useState(
    txColumns.indexOf("invoiceDueAmount") > -1 ||
      txMandatoryColumns.indexOf("invoiceDueAmount") > -1
  );
  const [invoiceDate, updateInvoiceDate] = useState(
    txColumns.indexOf("invoiceDate") > -1 ||
      txMandatoryColumns.indexOf("invoiceDate") > -1
  );
  const [invoiceDueDate, updateInvoiceDueDate] = useState(
    txColumns.indexOf("invoiceDueDate") > -1 ||
      txMandatoryColumns.indexOf("invoiceDueDate") > -1
  );
  const [isCashInvoice, updateIsCashInvoice] = useState(
    txColumns.indexOf("isCashInvoice") > -1 ||
      txMandatoryColumns.indexOf("isCashInvoice") > -1
  );

  const [purchaseRequestNumber, updatePurchaseRequestNumber] = useState(
    txColumns.indexOf("purchaseRequestNumber") > -1 ||
      txMandatoryColumns.indexOf("purchaseRequestNumber") > -1
  );
  const [prRequestedBy, updatePRRequestedBy] = useState(
    txColumns.indexOf("prRequestedBy") > -1 ||
      txMandatoryColumns.indexOf("prRequestedBy") > -1
  );
  const [orderPriority, updateOrderPriority] = useState(
    txColumns.indexOf("orderPriority") > -1 ||
      txMandatoryColumns.indexOf("orderPriority") > -1
  );
  const [departmentName, updateDepartmentName] = useState(
    txColumns.indexOf("departmentName") > -1 ||
      txMandatoryColumns.indexOf("departmentName") > -1
  );
  const [purchaseRequestDate, updatePurchaseRequestDate] = useState(
    txColumns.indexOf("purchaseRequestDate") > -1 ||
      txMandatoryColumns.indexOf("purchaseRequestDate") > -1
  );
  const [prExpirationDate, updatePrExpirationDate] = useState(
    txColumns.indexOf("prExpirationDate") > -1 ||
      txMandatoryColumns.indexOf("prExpirationDate") > -1
  );
  const [expirationDate, updateExpirationDate] = useState(
    txColumns.indexOf("expirationDate") > -1 ||
      txMandatoryColumns.indexOf("expirationDate") > -1
  );
  const [reason, updateReason] = useState(
    txColumns.indexOf("reason") > -1 ||
      txMandatoryColumns.indexOf("reason") > -1
  );
  const [prOwnerName, updatePROwnerName] = useState(
    txColumns.indexOf("prOwnerName") > -1 ||
      txMandatoryColumns.indexOf("prOwnerName") > -1
  );

  const [paymentNumber, updatePaymentNumber] = useState(
    txColumns.indexOf("paymentNumber") > -1 ||
      txMandatoryColumns.indexOf("paymentNumber") > -1
  );
  const [paymentAmount, updatePaymentAmount] = useState(
    txColumns.indexOf("paymentAmount") > -1 ||
      txMandatoryColumns.indexOf("paymentAmount") > -1
  );
  const [invoiceAmount, updateInvoiceAmount] = useState(
    txColumns.indexOf("invoiceAmount") > -1 ||
      txMandatoryColumns.indexOf("invoiceAmount") > -1
  );
  const [paymentDate, updatePaymentDate] = useState(
    txColumns.indexOf("paymentDate") > -1 ||
      txMandatoryColumns.indexOf("paymentDate") > -1
  );

  const [debitMemoNumber, updateDebitMemoNumber] = useState(
    txColumns.indexOf("debitMemoNumber") > -1 ||
      txMandatoryColumns.indexOf("debitMemoNumber") > -1
  );
  const [debitMemoTotalAmount, updateDebitMemoTotalAmount] = useState(
    txColumns.indexOf("debitMemoTotalAmount") > -1 ||
      txMandatoryColumns.indexOf("debitMemoTotalAmount") > -1
  );
  const [debitMemoDate, updateDebitMemoDate] = useState(
    txColumns.indexOf("debitMemoDate") > -1 ||
      txMandatoryColumns.indexOf("debitMemoDate") > -1
  );

  const [rfqExpirationDate, updateRfqExpirationDate] = useState(
    txColumns.indexOf("rfqExpirationDate") > -1 ||
      txMandatoryColumns.indexOf("rfqExpirationDate") > -1
  );
  const [rfqDate, updateRFQDate] = useState(
    txColumns.indexOf("rfqDate") > -1 ||
      txMandatoryColumns.indexOf("rfqDate") > -1
  );
  const [rfqBiddingSuppliersList, updateRFQBiddingSuppliersList] = useState(
    txColumns.indexOf("rfqBiddingSuppliersList") > -1 ||
      txMandatoryColumns.indexOf("rfqBiddingSuppliersList") > -1
  );
  const [customerName, updateCustomerName] = useState(
    txColumns.indexOf("customerName") > -1 ||
      txMandatoryColumns.indexOf("customerName") > -1
  );
  const [salesPersonFullName, updateSalesPersonFullName] = useState(
    txColumns.indexOf("salesPersonFullName") > -1 ||
      txMandatoryColumns.indexOf("salesPersonFullName") > -1
  );
  const [requestingDepartment, updateRequestingDepartment] = useState(
    txColumns.indexOf("requestingDepartment") > -1 ||
      txMandatoryColumns.indexOf("requestingDepartment") > -1
  );
  const [refNumber, updateRefNumber] = useState(
    txColumns.indexOf("refNumber") > -1 ||
      txMandatoryColumns.indexOf("refNumber") > -1
  );
  const [remarksInternal, updateRemarksInternal] = useState(
    txColumns.indexOf("remarksInternal") > -1 ||
      txMandatoryColumns.indexOf("remarksInternal") > -1
  );
  const [remarksSupplier, updateRemarksSupplier] = useState(
    txColumns.indexOf("remarksSupplier") > -1 ||
      txMandatoryColumns.indexOf("remarksSupplier") > -1
  );
  const [purchaseType, updatePurchaseType] = useState(
    txColumns.indexOf("purchaseType") > -1 ||
      txMandatoryColumns.indexOf("purchaseType") > -1
  );

  const [totalQuantityOnPO, updateTotalQuantityOnPO] = useState(
    txColumns.indexOf("totalQuantityOnPO") > -1 ||
      txMandatoryColumns.indexOf("totalQuantityOnPO") > -1
  );
  const [totalQuantityOnRFQ, updateTotalQuantityOnRFQ] = useState(
    txColumns.indexOf("totalQuantityOnRFQ") > -1 ||
      txMandatoryColumns.indexOf("totalQuantityOnRFQ") > -1
  );
  const [project, updateProject] = useState(
    txColumns.indexOf("project") > -1 ||
      txMandatoryColumns.indexOf("project") > -1
  );
  const [description, updateDescription] = useState(
    txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1 ||
      txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1
  );
  const [dueDays, updateDueDays] = useState(
    txColumns.indexOf("dueDays") > -1 ||
      txMandatoryColumns.indexOf("dueDays") > -1
  );
  const [debitApplied, updateDebitApplied] = useState(
    txColumns.indexOf("debitApplied") > -1 ||
      txMandatoryColumns.indexOf("debitApplied") > -1
  );

  const [supplierInvoiceNumber, updatesupplierInvoiceNumber] = useState(
    txColumns.indexOf("supplierInvoiceNumber") > -1 ||
      txMandatoryColumns.indexOf("supplierInvoiceNumber") > -1
  );

  const savePreferences = () => {
    let columnList = [];
    if (poNumber) columnList.push("poNumber");
    if (supplierName) columnList.push("supplierName");
    if (totalPOAmount) columnList.push("totalPOAmount");
    if (status) columnList.push("status");
    if (reference) columnList.push("reference");
    if (projectName) columnList.push("projectName");
    if (isRFQCconversion) columnList.push("isRFQCconversion");
    if (rfqNumber) columnList.push("rfqNumber");
    if (expectedDeliveryDate) columnList.push("expectedDeliveryDate");
    if (poDate) columnList.push("poDate");
    if (paymentTermName) columnList.push("paymentTermName");
    if (totalQtyReceived) columnList.push("totalQtyReceived");
    if (totalQtyOnId) columnList.push("totalQtyOnId");
    if (userStatus) columnList.push("userStatus");

    if (inboundDeliveryNumber) columnList.push("inboundDeliveryNumber");
    if (inboundDeliveryDate) columnList.push("inboundDeliveryDate");
    if (inquiryNumber) columnList.push("inquiryNumber");
    if (soNumber) columnList.push("soNumber");
    if (statusInvoice) columnList.push("statusInvoice");

    if (isXeroUploaded) columnList.push("isXeroUploaded");
    if (invoiceNumber) columnList.push("invoiceNumber");
    if (txNumber) columnList.push("txNumber");
    if (supplierPoNumber) columnList.push("supplierPoNumber");
    if (invoiceTotalAmount) columnList.push("invoiceTotalAmount");
    if (totalPaymentMade) columnList.push("totalPaymentMade");
    if (invoiceDueAmount) columnList.push("invoiceDueAmount");
    if (invoiceDate) columnList.push("invoiceDate");
    if (invoiceDueDate) columnList.push("invoiceDueDate");
    if (isCashInvoice) columnList.push("isCashInvoice");

    if (purchaseRequestNumber) columnList.push("purchaseRequestNumber");
    if (prRequestedBy) columnList.push("prRequestedBy");
    if (orderPriority) columnList.push("orderPriority");
    if (departmentName) columnList.push("departmentName");
    if (purchaseRequestDate) columnList.push("purchaseRequestDate");
    if (prExpirationDate) columnList.push("prExpirationDate");
    if (expirationDate) columnList.push("expirationDate");
    if (reason) columnList.push("reason");
    if (prOwnerName) columnList.push("prOwnerName");

    if (paymentNumber) columnList.push("paymentNumber");
    if (paymentAmount) columnList.push("paymentAmount");
    if (invoiceAmount) columnList.push("invoiceAmount");
    if (paymentDate) columnList.push("paymentDate");

    if (debitMemoNumber) columnList.push("debitMemoNumber");
    if (debitMemoTotalAmount) columnList.push("debitMemoTotalAmount");
    if (debitMemoDate) columnList.push("debitMemoDate");

    if (rfqExpirationDate) columnList.push("rfqExpirationDate");
    if (rfqDate) columnList.push("rfqDate");
    if (rfqBiddingSuppliersList) columnList.push("rfqBiddingSuppliersList");
    if (customerName) columnList.push("customerName");
    if (customerInquiryNumber) columnList.push("customerInquiryNumber");
    if (customerRFQNumber) columnList.push("customerRFQNumber");
    if (customerPONumber) columnList.push("customerPONumber");
    if (salesPersonFullName) columnList.push("salesPersonFullName");
    if (refNumber) columnList.push("refNumber");
    if (remarksInternal) columnList.push("remarksInternal");
    if (remarksSupplier) columnList.push("remarksSupplier");
    if (purchaseType) columnList.push("purchaseType");

    if (totalQuantityOnPO) columnList.push("totalQuantityOnPO");
    if (totalQuantityOnRFQ) columnList.push("totalQuantityOnRFQ");

    if (supplierStoreName) columnList.push("supplierStoreName");
    if (contact) columnList.push("contact");
    if (totalAmountDue) columnList.push("totalAmountDue");
    if (openedOrdersCount) columnList.push("openedOrdersCount");
    if (paidInvoicesCount) columnList.push("paidInvoicesCount");
    if (unpaidInvoicesCount) columnList.push("unpaidInvoicesCount");
    if (paymentTermName) columnList.push("paymentTermName");
    if (project) columnList.push("project");
    if (description) columnList.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
    if (dueDays) columnList.push("dueDays");
    if (debitApplied) columnList.push("debitApplied");
    if (supplierInvoiceNumber) columnList.push("supplierInvoiceNumber");

    props.updateState({ txColumns: columnList, tableDataReRendered: true });
    let data = props.txPreferences || {};
    data.relationshipId = (props.companyInfo || {}).relationshipId;
    data.clientUserAccountId = (props.userInfo || {}).userId;
    if (data.tablePreference) {
      let obj = JSON.parse(data.tablePreference);
      obj.columnList = columnList;
      data.tablePreference = JSON.stringify(obj);
    } else {
      data.tablePreference = JSON.stringify({
        columnList: columnList,
        columnSetting: props.txColumnSetting,
      });
    }
    data.preferenceId = props.preferenceId;
    data.gridPreference = props.gridPreference;
    props.saveUserPrefrences(data);
  };
  return (
    <><div className="add-remove-column">
      {txAvailableColumn.debitMemoNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("debitMemoNumber") > -1}
            checked={debitMemoDate}
            onChange={(e) => {
              updateDebitMemoNumber(e.target.checked);
            } }
          >
            {" "}
            Debit Memo #
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.supplierStoreName && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("supplierStoreName") > -1}
            checked={supplierStoreName}
            onChange={(e) => {
              updateSupplierStoreName(e.target.checked);
            } }
          >
            {" "}
            Supplier Name
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.contact && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("contact") > -1}
            checked={contact}
            onChange={(e) => {
              updateContact(e.target.checked);
            } }
          >
            {" "}
            Contact
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.totalAmountDue && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("totalAmountDue") > -1}
            checked={totalAmountDue}
            onChange={(e) => {
              updateTotalAmountDue(e.target.checked);
            } }
          >
            {" "}
            Total Amount Due
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.openedOrdersCount && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("openedOrdersCount") > -1}
            checked={openedOrdersCount}
            onChange={(e) => {
              updateOpenedOrdersCount(e.target.checked);
            } }
          >
            {" "}
            Opened Orders
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.paidInvoicesCount && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("paidInvoicesCount") > -1}
            checked={paidInvoicesCount}
            onChange={(e) => {
              updatePaidInvoicesCount(e.target.checked);
            } }
          >
            {" "}
            Paid Invoices
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.unpaidInvoicesCount && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("unpaidInvoicesCount") > -1}
            checked={unpaidInvoicesCount}
            onChange={(e) => {
              updateUnpaidInvoicesCount(e.target.checked);
            } }
          >
            {" "}
            Unpaid Invoice
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.paymentNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("paymentNumber") > -1}
            checked={paymentNumber}
            onChange={(e) => {
              updatePaymentNumber(e.target.checked);
            } }
          >
            {" "}
            Payment #
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.purchaseRequestNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("purchaseRequestNumber") > -1}
            checked={purchaseRequestNumber}
            onChange={(e) => {
              updatePurchaseRequestNumber(e.target.checked);
            } }
          >
            {" "}
            PR #
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.isXeroUploaded && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("isXeroUploaded") > -1}
            checked={isXeroUploaded}
            onChange={(e) => {
              updateIsXeroUploaded(e.target.checked);
            } }
          >
            {" "}
            Xero
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.invoiceNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("invoiceNumber") > -1}
            checked={invoiceNumber}
            onChange={(e) => {
              updateInvoiceNumber(e.target.checked);
            } }
          >
            {" "}
            Invoice #
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.txNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("txNumber") > -1}
            checked={txNumber}
            onChange={(e) => {
              updateTxNumber(e.target.checked);
            } }
          >
            {" "}
            Transaction #
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.supplierPoNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("supplierPoNumber") > -1}
            checked={supplierPoNumber}
            onChange={(e) => {
              updateSupplierPoNumber(e.target.checked);
            } }
          >
            {" "}
            PO #
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.poNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("poNumber") > -1}
            checked={poNumber}
            onChange={(e) => {
              updatePoNumber(e.target.checked);
            } }
          >
            {" "}
            PO #
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.supplierName && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("supplierName") > -1}
            checked={supplierName}
            onChange={(e) => {
              updateSupplierName(e.target.checked);
            } }
          >
            {" "}
            Supplier
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.description && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf(
              GRID_PREFERENCE_CONSTANTS.DESCRIPTION
            ) > -1}
            checked={description}
            onChange={(e) => {
              updateDescription(e.target.checked);
            } }
          >
            {" "}
            <FormattedMessage
              id="package.productDetail.description"
              defaultMessage="" />
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.totalPOAmount && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("totalPOAmount") > -1}
            checked={totalPOAmount}
            onChange={(e) => {
              updateTotalPOAmount(e.target.checked);
            } }
          >
            {" "}
            Amount
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.dueDays && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("dueDays") > -1}
            checked={dueDays}
            onChange={(e) => {
              updateDueDays(e.target.checked);
            } }
          >
            {" "}
            <FormattedMessage id="dueDays.text" defaultMessage="" />
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.status && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("status") > -1}
            checked={status}
            onChange={(e) => {
              updateStatus(e.target.checked);
            } }
          >
            {" "}
            System Status
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.reference && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("reference") > -1}
            checked={reference}
            onChange={(e) => {
              updateReference(e.target.checked);
            } }
          >
            {" "}
            Ref #
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.projectName && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("projectName") > -1}
            checked={projectName}
            onChange={(e) => {
              updateProjectName(e.target.checked);
            } }
          >
            {" "}
            Project Name
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.isRFQCconversion && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("isRFQCconversion") > -1}
            checked={isRFQCconversion}
            onChange={(e) => {
              updateIsRFQCconversion(e.target.checked);
            } }
          >
            {" "}
            Is RFQ Conversion
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.rfqNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("rfqNumber") > -1}
            checked={rfqNumber}
            onChange={(e) => {
              updateRFQNumber(e.target.checked);
            } }
          >
            {" "}
            RFQ #
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.expectedDeliveryDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("expectedDeliveryDate") > -1}
            checked={expectedDeliveryDate}
            onChange={(e) => {
              updateExpectedDeliveryDate(e.target.checked);
            } }
          >
            {" "}
            Expected Delivery
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.poDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("poDate") > -1}
            checked={poDate}
            onChange={(e) => {
              updatePoDate(e.target.checked);
            } }
          >
            {" "}
            Date
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.paymentTermName && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("paymentTermName") > -1}
            checked={paymentTermName}
            onChange={(e) => {
              updatePaymentTermName(e.target.checked);
            } }
          >
            {" "}
            Payment Term
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.totalQtyReceived && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("totalQtyReceived") > -1}
            checked={totalQtyReceived}
            onChange={(e) => {
              updateTotalQtyReceived(e.target.checked);
            } }
          >
            {" "}
            Invoiced
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.totalQtyOnId && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("totalQtyOnId") > -1}
            checked={totalQtyOnId}
            onChange={(e) => {
              upadteTotalQtyOnId(e.target.checked);
            } }
          >
            {" "}
            Received
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.userStatus && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("userStatus") > -1}
            checked={userStatus}
            onChange={(e) => {
              updateUserStatus(e.target.checked);
            } }
          >
            {" "}
            Status{" "}
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.invoiceTotalAmount && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("invoiceTotalAmount") > -1}
            checked={invoiceTotalAmount}
            onChange={(e) => {
              updateInvoiceTotalAmount(e.target.checked);
            } }
          >
            {" "}
            Invoice Amount
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.totalPaymentMade && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("totalPaymentMade") > -1}
            checked={totalPaymentMade}
            onChange={(e) => {
              updateTotalPaymentMade(e.target.checked);
            } }
          >
            {" "}
            Paid
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.invoiceDueAmount && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("invoiceDueAmount") > -1}
            checked={invoiceDueAmount}
            onChange={(e) => {
              updateInvoiceDueAmount(e.target.checked);
            } }
          >
            {" "}
            Due Amount
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.invoiceDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("invoiceDate") > -1}
            checked={invoiceDate}
            onChange={(e) => {
              updateInvoiceDate(e.target.checked);
            } }
          >
            {" "}
            Invoice Date
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.invoiceDueDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("invoiceDueDate") > -1}
            checked={invoiceDueDate}
            onChange={(e) => {
              updateInvoiceDueDate(e.target.checked);
            } }
          >
            {" "}
            Invoice Due Date
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.isCashInvoice && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("isCashInvoice") > -1}
            checked={isCashInvoice}
            onChange={(e) => {
              updateIsCashInvoice(e.target.checked);
            } }
          >
            {" "}
            Cash Invoice
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.prRequestedBy && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("prRequestedBy") > -1}
            checked={prRequestedBy}
            onChange={(e) => {
              updatePRRequestedBy(e.target.checked);
            } }
          >
            {" "}
            Requester
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.orderPriority && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("orderPriority") > -1}
            checked={orderPriority}
            onChange={(e) => {
              updateOrderPriority(e.target.checked);
            } }
          >
            {" "}
            Priority
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.departmentName && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("departmentName") > -1}
            checked={departmentName}
            onChange={(e) => {
              updateDepartmentName(e.target.checked);
            } }
          >
            {" "}
            Department
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.purchaseRequestDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("purchaseRequestDate") > -1}
            checked={purchaseRequestDate}
            onChange={(e) => {
              updatePurchaseRequestDate(e.target.checked);
            } }
          >
            {" "}
            {txAvailableColumn.rfqBiddingSuppliersList ? "PR Date" : "Date"}
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.expirationDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("expirationDate") > -1}
            checked={expirationDate}
            onChange={(e) => {
              updateExpirationDate(e.target.checked);
            } }
          >
            {" "}
            {"Expiration Date"}
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.prExpirationDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("prExpirationDate") > -1}
            checked={prExpirationDate}
            onChange={(e) => {
              updatePrExpirationDate(e.target.checked);
            } }
          >
            {" "}
            {txAvailableColumn.rfqBiddingSuppliersList
              ? "PR Expiration Date"
              : "Expiration Date"}
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.reason && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("reason") > -1}
            checked={reason}
            onChange={(e) => {
              updateReason(e.target.checked);
            } }
          >
            {" "}
            Reason
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.prOwnerName && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("prOwnerName") > -1}
            checked={prOwnerName}
            onChange={(e) => {
              updatePROwnerName(e.target.checked);
            } }
          >
            {" "}
            Owner
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.paymentAmount && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("paymentAmount") > -1}
            checked={paymentAmount}
            onChange={(e) => {
              updatePaymentAmount(e.target.checked);
            } }
          >
            {" "}
            Payment Amount
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.invoiceAmount && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("invoiceAmount") > -1}
            checked={invoiceAmount}
            onChange={(e) => {
              updateInvoiceAmount(e.target.checked);
            } }
          >
            {" "}
            Invoice Amount
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.paymentDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("paymentDate") > -1}
            checked={paymentDate}
            onChange={(e) => {
              updatePaymentDate(e.target.checked);
            } }
          >
            {" "}
            Payment Date
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.debitMemoTotalAmount && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("debitMemoTotalAmount") > -1}
            checked={debitMemoTotalAmount}
            onChange={(e) => {
              updateDebitMemoTotalAmount(e.target.checked);
            } }
          >
            {" "}
            Amount
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.debitMemoDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("debitMemoDate") > -1}
            checked={debitMemoDate}
            onChange={(e) => {
              updateDebitMemoDate(e.target.checked);
            } }
          >
            {" "}
            Date
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.rfqExpirationDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("rfqExpirationDate") > -1}
            checked={rfqExpirationDate}
            onChange={(e) => {
              updateRfqExpirationDate(e.target.checked);
            } }
          >
            {" "}
            Bid Closing Date
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.rfqDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("rfqDate") > -1}
            checked={rfqDate}
            onChange={(e) => {
              updateRFQDate(e.target.checked);
            } }
          >
            {" "}
            RFQ Date
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.rfqBiddingSuppliersList && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("rfqBiddingSuppliersList") > -1}
            checked={rfqBiddingSuppliersList}
            onChange={(e) => {
              updateRFQBiddingSuppliersList(e.target.checked);
            } }
          >
            {" "}
            Supplier
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.customerName && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("customerName") > -1}
            checked={customerName}
            onChange={(e) => {
              updateCustomerName(e.target.checked);
            } }
          >
            {" "}
            Customer
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.customerInquiryNumber && (
        <div className="mb5">
          <Checkbox
            onChange={(e) => {
              updateCustomerInquiryNumber(e.target.checked);
            } }
            disabled={txMandatoryColumns.indexOf("customerInquiryNumber") > -1}
            checked={customerInquiryNumber}
          >
            {" "}
            Customer Inquiry #
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.customerRFQNumber && (
        <div className="mb5">
          <Checkbox
            onChange={(e) => {
              updateCustomerRFQ(e.target.checked);
            } }
            checked={customerRFQNumber}
          >
            {" "}
            Customer RFQ #
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.customerPONumber && (
        <div className="mb5">
          <Checkbox
            onChange={(e) => {
              updateCustomerPONumber(e.target.checked);
            } }
            disabled={txMandatoryColumns.indexOf("customerPONumber") > -1}
            checked={customerPONumber}
          >
            {" "}
            Customer PO #
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.salesPersonFullName && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("salesPersonFullName") > -1}
            checked={salesPersonFullName}
            onChange={(e) => {
              updateSalesPersonFullName(e.target.checked);
            } }
          >
            {" "}
            Sales Person
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.requestingDepartment && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("requestingDepartment") > -1}
            checked={requestingDepartment}
            onChange={(e) => {
              updateRequestingDepartment(e.target.checked);
            } }
          >
            {" "}
            Requesting Department
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.refNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("refNumber") > -1}
            checked={refNumber}
            onChange={(e) => {
              updateRefNumber(e.target.checked);
            } }
          >
            {" "}
            Ref #
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.remarksInternal && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("remarksInternal") > -1}
            checked={remarksInternal}
            onChange={(e) => {
              updateRemarksInternal(e.target.checked);
            } }
          >
            {" "}
            Remarks (Internal)
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.remarksSupplier && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("remarksSupplier") > -1}
            checked={remarksSupplier}
            onChange={(e) => {
              updateRemarksSupplier(e.target.checked);
            } }
          >
            {" "}
            Remarks
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.purchaseType && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("purchaseType") > -1}
            checked={purchaseType}
            onChange={(e) => {
              updatePurchaseType(e.target.checked);
            } }
          >
            {" "}
            Purchase Type
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.totalQuantityOnRFQ && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("totalQuantityOnRFQ") > -1}
            checked={totalQuantityOnRFQ}
            onChange={(e) => {
              updateTotalQuantityOnRFQ(e.target.checked);
            } }
          >
            {" "}
            Qty on RFQ
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.totalQuantityOnPO && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("totalQuantityOnPO") > -1}
            checked={totalQuantityOnPO}
            onChange={(e) => {
              updateTotalQuantityOnPO(e.target.checked);
            } }
          >
            Qty on PO
          </Checkbox>
        </div>
      )}

      {txAvailableColumn.inboundDeliveryNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("inboundDeliveryNumber") > -1}
            checked={inboundDeliveryNumber}
            onChange={(e) => {
              updateInboundDeliveryNumber(e.target.checked);
            } }
          >
            Inbound Delivery #
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.inboundDeliveryDate && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("inboundDeliveryDate") > -1}
            checked={inboundDeliveryDate}
            onChange={(e) => {
              updateInboundDeliveryDate(e.target.checked);
            } }
          >
            Date
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.statusInvoice && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("statusInvoice") > -1}
            checked={statusInvoice}
            onChange={(e) => {
              updateStatusInvoice(e.target.checked);
            } }
          >
            Invoice Status
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.inquiryNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("inquiryNumber") > -1}
            checked={inquiryNumber}
            onChange={(e) => {
              updateInquiryNumber(e.target.checked);
            } }
          >
            Inquiry #
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.soNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("soNumber") > -1}
            checked={soNumber}
            onChange={(e) => {
              updateSoNumber(e.target.checked);
            } }
          >
            SO #
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.project && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("project") > -1}
            checked={project}
            onChange={(e) => {
              updateProject(e.target.checked);
            } }
          >
            {" "}
            Project
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.debitApplied && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("debitApplied") > -1}
            checked={debitApplied}
            onChange={(e) => {
              updateDebitApplied(e.target.checked);
            } }
          >
            {" "}
            Debit Applied
          </Checkbox>
        </div>
      )}
      {txAvailableColumn.supplierInvoiceNumber && (
        <div className="mb5">
          <Checkbox
            disabled={txMandatoryColumns.indexOf("supplierInvoiceNumber") > -1}
            checked={supplierInvoiceNumber}
            onChange={(e) => {
              updatesupplierInvoiceNumber(e.target.checked);
            } }
          >
            {" "}
            Supplier Invoice #
          </Checkbox>
        </div>
      )}

    </div><div className="button-action">
        <Button
          type="primary"
          onClick={() => {
            props.updateState({ showColPop: false });
            savePreferences();
          } }
        >
          <FormattedMessage id="button.save.label" defaultMessage="" />
        </Button>
      </div></>
  );
}
