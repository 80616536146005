import React from 'react';
import { FormattedMessage } from 'react-intl';
//import ReactHtmlParser from 'react-html-parser';

const TotalStudents =  (props) =>{
    const { totalStudentCountByGrade } = props;


    return (
        <div className={""} style={{ minHeight: '300px'}}>
       
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="inner-content">
                        <div className="modal-table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th><FormattedMessage id='grade.text' /></th>
                                    <th><FormattedMessage id='totalStudent.text' /></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {( Object.keys(totalStudentCountByGrade) ||[]).map((grade, i) => {
                                        return (
                                            <tr key={i}>
                                                <td><FormattedMessage id='grade.text' /> {grade || ''}</td>
                                                <td>{totalStudentCountByGrade[grade] || 0}</td>
                                                
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
            </div>
    );

}

export default TotalStudents;
