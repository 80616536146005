import React from "react";
import LeadCallLogsComp from "../../../components/leads/CallLogs";
import LeadSourceDrawer from "../../drawer/leads/leadSourceList";
import { getAllLeadSources } from "../../modal/modalBody/leads/createLead/action";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createLeadSource } from "../../drawer/leads/leadSourceList/action";
import { fetchCallLogsByRid,fetchCallLogsByUserId } from "./action";
import { showModal, hideModal } from "../../../actions/commonActions";

class LeadCallLogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 0,
      pageSize: 100,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: 100,
    }
    this.props.fetchCallLogsByRid(payload);
  }

  render() {
    return (
      <>
        <LeadCallLogsComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />

       {/* {this.state.leadSourceListDrawer && <LeadSourceDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      leadCallLogsList: state.crm.leadCallLogsList
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllLeadSources,
  createLeadSource,
  fetchCallLogsByRid,
  showModal,
  hideModal,fetchCallLogsByUserId
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadCallLogs);
