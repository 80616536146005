import React from "react";
import { ICONS } from "../../../static/constants";
import { FormattedMessage, injectIntl } from "react-intl";
import { Switch, Menu, Button, Pagination, Skeleton,Empty } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import { MoreOutlined } from '@ant-design/icons';

function HierarchyComp(props) {

  const { companyHierarchyList, dir = 0, updateState, companyInfo ,pageNumber,pageSize} = props;

  const loadPageData = (pageNumber, pageSize) => {
    const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
  }
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
    props.resetPaginatedData();
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  }
  const showTotal = (total) => {
    return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
  };
  const deleteHandle = (hierarchy) => {
    const modalBody = <FormattedMessage id='modalBody.deleteHierarchy' defaultMessage='' />;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteHierarchy({
          relationshipId: hierarchy.relationshipId,
          id: hierarchy.hierarchyId
        })
      },
    };
    props.showModal(modalData);
  }
  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({ hierarchyDrawer: true, hierarchyData: e });
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>

        <Menu.Item
          key="2"
          value="delete"
          onClick={() => {
            deleteHandle(e);
          }}
        >
          <i className={ICONS.DELETE} /> Delete
        </Menu.Item>
      </Menu>
    );
  }
  const sortColumn = (e) => {
    sortTable(document.getElementById('project-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  return (
    <><div className="view-container">
      <div className="view-container-actions">
      <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

        <div className="left-actions">
          <div className="table-heading">
            <FormattedMessage
              id="employeeMeta.hierarchy.pageHeading"
              defaultMessage="" />
            <div className="table-sub-heading">
              Manage Org Hierarchy meta here
            </div>
          </div>
        </div>

        <div className="right-actions">
          <Button
            style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
            className="create-action-btn"
            onClick={() => {
              props.updateState({ hierarchyDrawer: true, hierarchyData: {} });
            }}
          >
            <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
            <FormattedMessage id="button.create.label" defaultMessage="" />
          </Button>
        </div>
        </Skeleton>
      </div>
    

        <div className="table-container">
      <Skeleton loading={props.listLoading} paragraph={{ rows: 18 }} active>
          <table id="hierarchy-table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>
                  <FormattedMessage
                    id="company.hierarchy.hierarchyName"
                    defaultMessage=" "
                  />
                  <i class="fi fi-rr-sort-alt"></i>
                </th>
                <th>
                  <FormattedMessage
                    id="company.hierarchy.hierarchyLevel"
                    defaultMessage=""
                  />
                  <i class="fi fi-rr-sort-alt"></i>
                </th>
                <th>
                  <FormattedMessage
                    id="company.hierarchy.hierarchyCode"
                    defaultMessage=" "
                  />
                  <i class="fi fi-rr-sort-alt"></i>
                </th>
                <th>
                  <FormattedMessage
                    id="company.hierarchy.description"
                    defaultMessage=""
                  />
                  <i class="fi fi-rr-sort-alt"></i>
                </th>
                <th>
                  <FormattedMessage
                    id="company.hierarchy.rootHierarchy"
                    defaultMessage=""
                  />
                  <i class="fi fi-rr-sort-alt"></i>
                </th>
                <th className='text-center'>
                  <FormattedMessage
                    id="company.hierarchy.action"
                    defaultMessage=""
                  />

                </th>
              </tr>
            </thead>
            <tbody>
              {(companyHierarchyList || []).length ? 
                (companyHierarchyList || []).map((rowData, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{rowData.hierarchyName}</td>
                      <td>{rowData.hierarchyLevel}</td>
                      <td>{rowData.hierarchyCode}</td>
                      <td>{rowData.hierarchyDescription}</td>
                      <td>{rowData.isRootHierarchy}</td>
                      <td className='text-center'>
                        <DropdownAction
                          overlay={actionMenu(rowData)}
                          trigger={["click"]}
                        >
                          <MoreOutlined />
                        </DropdownAction>
                      </td>
                    </tr>
                  );
                })
              : <tr key="empty-data-box">
              <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                  <Empty />
              </td>
          </tr>
              }
            </tbody>
          </table>
      </Skeleton>
        </div>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={1}
            showTotal={showTotal}
            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber })
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props)
            }}
          />
        </div>
        </Skeleton>
      </div>

    </div>
    </>
  )
}
export default injectIntl(HierarchyComp);