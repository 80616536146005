import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import { fetchSalesInquiryDrafts } from "../Listing/action";
import { deleteDocument } from "../../../modal/modalBody/customer/InquiryDetail/action";
import StandardSalesEnquiry from "../../../../components/customer/salesEnquiry/StandardSalesEnquiry";
import { fetchSuppliers } from "../../../../containers/supplier/Listing/action";
import { fetchCustomers,resetPaginatedData } from "../../../../containers/customer/Listing/action";
import {
  fetchProjects,
  fetchProducts,
  fetchCustomerDetailsByCustomerId,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchAllDepartments,
  addDepartment,
  deleteDepartment,
  deleteProject,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  deleteContact,
  deleteAddress,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchAllEmployees,
  deleteEmployee,
  fetchRelationshipTaxIdentifications,
  deleteCustomerTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  resetCustomerDetail,
  fetchAddresses,
  getRelationshipAddress,
  resetAddresses,
  deleteAttectedFile
} from "../../../../actions/commonActions";
import { downloadFileFromBucket } from "../../../../actions/downloadFileAction";
import {
  getNextTxNumber,
  createEnquiry,
  fetchInquiryDetail,
  resetEnquiryDetail,
  createEnquiryDraft,
} from "./action";

import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import { GRID_PREFERENCE_CONSTANTS } from "../../../../static/agGridConstants";
import {
  CONSTANTS,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../static/constants";
import { deleteCustomers } from "../../../customer/Listing/action";
import {
  fetchRemarksTemplate,
  resetRemarksData,
} from "../../../modal/modalBody/settings/RemarksTemplate/action";
import InquiryDetail from "../../../modal/modalBody/customer/InquiryDetail";
import { fetchAllContacts } from "../../Listing/ShowConact/action";
import { fetchSalesEnquiry } from "../Listing/action";
import SettingSalesDrawer from "../../../drawer/customer/settingSalesDrawer";

class SalesEnquiry extends Component {
  constructor(props) {
    super(props);
    const allRelationshipBillingAddress =
      filter(props.companyInfo.boLocationList, {
        locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
      }) || [];
    const relationshipAddress =
      allRelationshipBillingAddress.find((x) => x.isDefault) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNameInquiry",
      viewName:"Inquiry",
      docName:"Sales Inquiry",
      tabDocumentOptions: { txType: CONSTANTS_TRANSACTIONS.INQUIRY, label: 'Sales Inquiry', numberExample: 'INQ-1', numberInfo: 'INQ-CustomerNumber-DDMMYY' },

      settingSalesDrawerVisible:false,
      inquiryDraftId: props.inquiryDraftId,
      inquiryDraftData: props.inquiryData,
      customer: props.customer,
      isClone: props.clone,
      update: props.update,
      stateCustomerId: this.props.customerId,
      customerName: props.clone ? (props.customer || {}).companyName : "",
      allRelationshipBillingAddress,
      relationshipAddress,
      supplier: undefined,
      enquiryDate: new Date(),
      expiryDate: new Date(),
      priceListName: undefined,
      pageSize: 100,
      pageNumber: 1,
      inheritedTxColumn: [],
      txPreferences: { txType: "inquiry", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        "sno",
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        description: true,
        uom: true,
        // hsn: true,
        // specialDiscount: true,
        // discount: true,
        // tax: true
      },
    };
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true
    // });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      enquiryDate: new Date(),
      salesQuotationMasterId: this.props.salesQuotationMasterId,
      purchaseRequestMasterId: this.props.purchaseRequestMasterId,
      customerId: this.props.inquiryMasterId ? this.props.customerId : "",
      inquiryMasterId: this.props.inquiryMasterId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 100,
      isCompact: true
    };
    this.props.resetPaginatedData();
    this.props.resetEnquiryDetail();
    this.props.resetAddresses();
    this.props.resetCustomerDetail();        

    fetchPaginationDataIfNeeded(
      "fetchCustomers",
      "customers",
      this.props,
      payload,
      true
    );
    fetchDataIfNeeded(
      "fetchAllDepartments",
      "departments",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchProducts", "products", this.props, payload, true);
    fetchDataIfNeeded("fetchProjects", "projectList", this.props, payload);
    fetchDataIfNeeded("fetchUOM", "uomList", this.props, payload);
    fetchDataIfNeeded("fetchDocuments", "documentList", this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded("getNextTxNumber", "nextTxNumber", this.props, payload);
    }
    fetchDataIfNeeded(
      "fetchUserPreferences",
      "allPreferences",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchAllEmployees", "employees", this.props, payload);
    this.props.fetchRemarksTemplate({
      relationshipId: companyInfo.relationshipId,
      docName: "Inquiry",
    });
    payload.inquiryMasterId && this.props.fetchInquiryDetail(payload);
    fetchDataIfNeeded(
      "fetchRelationshipTaxIdentifications",
      "allRelationshipTaxIdentificatins",
      this.props,
      payload
    );
  }

  componentWillReceiveProps(props) {
    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === "RelationshipBillingAddress") {
          allRelationshipBillingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress,
      });
    }

    if (props.customerDetail && props.customerDetail.customerId) {
      const customer = props.customerDetail;
      const allBillingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          })) ||
        [];
      const defaultBillingAddress =
        find(allBillingAddress, { isDefault: 1 }) || {};
      const selectedContact =
        (customer.boContactList || []).find((x) => x.isPrimaryContact) || {};
      // const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
      const selectedList = customer.customerTaxIdentificationsList || [];
      let selectedCustomerTaxIdentifications = selectedList.map((obj) => {
        return obj.customerTaxIdentificationId;
      });
      this.setState({
        customer,
        customerName: customer.companyName,
        allBillingAddress: allBillingAddress,
        customerBillingAddress: defaultBillingAddress,
        selectedContact: selectedContact,
        selectedCustomerTaxIdentifications,
      });
      props.resetCustomerDetail();
    }

    if (props.addresses) {
      if (
        props.addresses.BillingAddress &&
        props.addresses.BillingAddress.length
      ) {
        this.setState({
          allBillingAddress: props.addresses.BillingAddress,
        });
      }
      if (
        props.addresses.ShippingAddress &&
        props.addresses.ShippingAddress.length
      ) {
        this.setState({
          allShippingAddress: props.addresses.ShippingAddress,
        });
      }
    }

    if (
      !this.state.selectedRelationshipTaxIdentifications &&
      props.allRelationshipTaxIdentificatins &&
      props.allRelationshipTaxIdentificatins.length && !props.isClone && !props.update
    ) {
      const selectedList =
        (props.allRelationshipTaxIdentificatins &&
          filter(props.allRelationshipTaxIdentificatins, {
            populateOnTransaction: 1,
          })) ||
        [];
      let selectedRelationshipTaxIdentifications = selectedList.map((obj) => {
        return obj.relationshipTaxIdentificationId;
      });
      this.setState({
        selectedRelationshipTaxIdentifications:
          selectedRelationshipTaxIdentifications,
      });
    }

    if (
      props.documentList &&
      props.documentList.length &&
      !this.state.docName
    ) {
      props.documentList.forEach((document) => {
        if (
          document.txType ===
          CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.INQUIRY] &&
          document.isDefault
        ) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName,
          });
        }
      });
    }

    if (
      !this.state.customerRemarks &&
      props.remarksData &&
      props.remarksData.isFetched &&
      !this.state.isRemarksSet
    ) {
      this.setState({
        customerRemarks: props.remarksData.content || "",
        isRemarksSet: true,
        footer: props.remarksData.footer || "",
      });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500);
    }
    if (props.enquiryDetail && props.enquiryDetail.inquiryMasterId) {

      const detailList = [];
      let customer = {
        customerId: props.enquiryDetail.customerId,
        customerName: props.enquiryDetail.customerName,
      };
      let allBillingAddress = [];
      const defaultBillingAddress =
        find(props.enquiryDetail.boLocationInquiryList || [], {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }) || {};
      if (
        props.customers[this.state.pageNumber] &&
        props.customers[this.state.pageNumber].length
      ) {
        customer =
          find(props.customers[this.state.pageNumber], {
            customerId: Number(props.enquiryDetail.customerId || 0),
          }) || {};
        allBillingAddress =
          (customer &&
            filter(customer.boLocationList, {
              locationType: CONSTANTS.BILLING_ADDRESS,
            })) ||
          [];
      }
      const inheritedTxColumn = [];
      const additionalInfoList =
        props.enquiryDetail.additionalInfoList[0] || {};

      // const _emptyColumnCheck = (item) => {
      //     if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
      //         inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      //     }
      //     if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
      //         inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      //     }
      //     if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
      //         inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
      //     }
      //     if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
      //         inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
      //     }
      // }

      props.enquiryDetail.inquiryDetailsList &&
        props.enquiryDetail.inquiryDetailsList.map((ele) => {
          // _emptyColumnCheck(ele);
          return detailList.push({
            version: ele.version,
            selectedProductValue: [ele.itemId, ele.itemVariantId],
            product: ele.itemName
              ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") ||
                "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : {},
            itemVariantName: ele.itemVariantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            description: ele.description,
            qty: ele.quantity.toFixed(2),
            uom: ele.uomName,
            inquiryDetailsId: props.update ? ele.inquiryDetailsId : 0,
          });
        });

      this.setState({
        update: props.update,
        inquiryMasterId: props.update ? props.enquiryDetail.inquiryMasterId : 0,
        isInquiryConversion: 1,
        version: props.enquiryDetail.version,
        customerId: props.enquiryDetail.customerId,
        customer: customer,
        relationshipBillingAddress:
          find(props.enquiryDetail.boLocationInquiryList, {
            locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
          }) || {},
        selectedContact:
          (props.enquiryDetail.additionalInfoList || [])[0] || {},
        customerName: customer.companyName,
        priority: props.enquiryDetail.priority,
        customerInquiryNumber: props.enquiryDetail.customerInquiryNumber,
        refNumber: props.enquiryDetail.refNumber,
        internalRemarks: props.enquiryDetail.remarksInternal,
        customerRemarks: props.enquiryDetail.remarksCustomer,
        footer: props.enquiryDetail.footer,
        enquiryDate: props.update
          ? new Date(props.enquiryDetail.inquiryDate)
          : new Date(),
        expiryDate: props.update
          ? props.enquiryDetail.inquiryExpirationDate &&
          new Date(props.enquiryDetail.inquiryExpirationDate)
          : new Date(),
          docDetailList:props.enquiryDetail.docDetailList || [],
        projectMasterId: props.enquiryDetail.projectMasterId,
        projectName: props.enquiryDetail.projectName,
        projectNumber: props.enquiryDetail.projectNumber,
        allBillingAddress: allBillingAddress,
        customerBillingAddress: defaultBillingAddress,
        enquiryDetailsList: detailList?.sort((a, b) => (a.sNo > b.sNo) ? 1 : -1) || [],
        inheritedTxColumn: inheritedTxColumn,
        tableDataReRendered: true,
        salesPersonName: props.enquiryDetail.salesPersonFullName,
        salesPersonId: props.enquiryDetail.salesPersonId,
        inquiryNumber: props.enquiryDetail.inquiryNumber,
        docName: props.enquiryDetail.documentName,
        oldPayload: props.update ? props.enquiryDetail : {},
        selectedRelationshipTaxIdentifications:
          additionalInfoList.relationshipTaxIdentifications
            ? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(
              (obj) => {
                return obj.relationshipTaxIdentificationId;
              }
            )
            : this.state.selectedRelationshipTaxIdentifications,
      });
      if (props.update && props.enquiryDetail.inquiryNumber) {
        this.setState({
          salesEnquiryNumber: props.enquiryDetail.inquiryNumber,
        });
      }
      props.resetEnquiryDetail();
    }

    if (this.state.inquiryDraftId && this.state.inquiryDraftData) {
      const detailList = [];
      let customer = {
        customerId: this.state.inquiryDraftData.customerId,
        customerName: this.state.inquiryDraftData.customerName,
      };
      let allBillingAddress = [];
      const defaultBillingAddress =
        find(this.state.inquiryDraftData.boLocationInquiryList || [], {
          locationType: CONSTANTS.BILLING_ADDRESS,
        }) || {};
      if (
        props.customers[this.state.pageNumber] &&
        props.customers[this.state.pageNumber].length
      ) {
        customer =
          find(props.customers[this.state.pageNumber], {
            customerId: Number(this.state.inquiryDraftData.customerId || 0),
          }) || {};
        allBillingAddress =
          (customer &&
            filter(customer.boLocationList, {
              locationType: CONSTANTS.BILLING_ADDRESS,
            })) ||
          [];
      }
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item) => {
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 &&
          item.itemName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) <
          0 &&
          item.description
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 &&
          item.uomName
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (
          inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 &&
          item.hsnCode
        ) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
      };

      this.state.inquiryDraftData.inquiryDetailsList &&
        this.state.inquiryDraftData.inquiryDetailsList.map((ele) => {
          _emptyColumnCheck(ele);
          let variantName =
            (ele.attributeValue1 || "") +
            (ele.attributeValue2 ? " | " : "") +
            (ele.attributeValue2 || "") +
            "" +
            (ele.attributeValue3 ? " | " + ele.attributeValue3 : "");
          return detailList.push({
            selectedProductValue: [ele.itemId, ele.itemVariantId],
            product: ele.itemName
              ? ele.itemName +
              ((variantName && " (" + variantName + " ) ") || "")
              : "",
            productObj: ele.itemName
              ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
              : "",
            itemVariantName: variantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3,
            },
            description: ele.description,
            qty: ele.quantity,
            uom: ele.uomName,
          });
        });

      setTimeout(() => {
        if (this.state.inquiryDraftData) {
          this.setState({
            update: props.update,
            inquiryMasterId: props.update
              ? this.state.inquiryDraftData.inquiryMasterId
              : 0,
            isInquiryConversion: 1,
            customerId: this.state.inquiryDraftData.customerId,
            customer: customer,
            relationshipBillingAddress:
              find(this.state.inquiryDraftData.boLocationInquiryList, {
                locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
              }) || {},
            selectedContact:
              (this.state.inquiryDraftData.additionalInfoList || [])[0] || {},
            customerName: customer.companyName,
            priority: this.state.inquiryDraftData.priority,
            customerInquiryNumber:
              this.state.inquiryDraftData.customerInquiryNumber,
            refNumber: this.state.inquiryDraftData.refNumber,
            internalRemarks: this.state.inquiryDraftData.remarksInternal,
            customerRemarks: this.state.inquiryDraftData.remarksCustomer,
            footer: this.state.inquiryDraftData.footer,
            enquiryDate: new Date(this.state.inquiryDraftData.inquiryDate),
            expiryDate:
              this.state.inquiryDraftData.inquiryExpirationDate &&
              new Date(this.state.inquiryDraftData.inquiryExpirationDate),
            projectMasterId: this.state.inquiryDraftData.projectMasterId,
            projectName: this.state.inquiryDraftData.projectName,
            projectNumber: this.state.inquiryDraftData.projectNumber,
            allBillingAddress: allBillingAddress,
            customerBillingAddress: defaultBillingAddress,
            enquiryDetailsList: detailList,
            inheritedTxColumn: inheritedTxColumn,
            tableDataReRendered: true,
            salesPersonName: this.state.inquiryDraftData.salesPersonFullName,
            inquiryNumber: this.state.inquiryDraftData.inquiryNumber,
            docName: this.state.inquiryDraftData.documentName,
            inquiryDraftData: null,
          });
        }
      }, 1000);
    }
    if (
      this.state.stateCustomerId &&
      props.customers[this.state.pageNumber] &&
      props.customers[this.state.pageNumber].length
    ) {
      const customer =
        find(props.customers[this.state.pageNumber], {
          customerId: Number(this.state.stateCustomerId || 0),
        }) || {};
      //const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
      // const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress =
        (customer &&
          filter(customer.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
          })) ||
        [];
      const defaultBillingAddress =
        find(allBillingAddress, { isDefault: 1 }) || {};
      const selectedContact =
        (customer.boContactList || []).find((x) => x.isPrimaryContact) || {};
      // const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
      const selectedList = customer.customerTaxIdentificationsList || [];
      let selectedCustomerTaxIdentifications = selectedList.map((obj) => {
        return obj.customerTaxIdentificationId;
      });

      this.setState({
        stateCustomerId: null,
        customer,
        customerName: customer.companyName,
        allBillingAddress: allBillingAddress,
        //  shippingAddress: this.state.shippingAddress || defaultShippingAddress,
        customerBillingAddress:
          this.state.billingAddress || defaultBillingAddress,
        selectedContact,
        selectedCustomerTaxIdentifications,
        boContactList: customer.boContactList || [],
      });
    }
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences =
        find(props.allPreferences, {
          txType: "inquiry",
          templateName: "standard",
        }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnList || []
          : [];
        txColumns = txColumns.length
          ? txColumns
          : (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          );
        const txColumnSetting = txPreferences.gridPreference
          ? (JSON.parse(txPreferences.gridPreference) || {}).columnSetting || {}
          : {};
        const isColumnFitTable =
          Object.keys(txColumnSetting).length ||
            this.state.isColumnFitTable === false
            ? false
            : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences,
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat(
            "product",
            "description"
          ),
        });
      }
    }
    if (props.customerContactList && props.customerContactList.length) {
      this.setState({
        boContactList: props.customerContactList,
      });
    }
  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching,
    };
    this.props.fetchProducts(payload);
  }

  openInquiryDetails = (data) => {
    const inquiryPayload = {
      customerId: data.customerId,
      inquiryMasterId: data.inquiryMasterId,
    };
    this.props.pushModalToStack({
      modalBody: (
        <InquiryDetail {...this.props} inquiryPayload={inquiryPayload} />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  render() {
    return (
      <Fragment>
        {this.state.settingSalesDrawerVisible && 
        <SettingSalesDrawer
        {...this.state}
        {...this.props}
        updateState={(data) => {
          this.setState(data);
        }}
        updateDrawerState={(data) => { this.setState(data) }}
        />
        }
        
        <StandardSalesEnquiry
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching);
          }}
          {...this.props}
          {...this.state}
          openInquiryDetails={this.openInquiryDetails}
          updateState={(data) => {
            this.setState(data);
          }}
          updateDrawerState={(data) => { this.setState(data) }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    suppliers: state.supplier.suppliers,
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    uomList: state.common.uomList,
    projectList: state.common.projectList,
    sqSoData: state.so.sqSoData,
    departments: state.common.departmentList,
    taxes: state.common.taxes,
    enquiryDetail: state.enquiry.enquiryDetail,
    enquiryPrData: state.purchaseRequest.enquiryPrData,
    products: state.salesInvoice.products,
    customerDetail: state.common.customerDetail,
    documentList: state.common.documentList,
    salesEnquiryNumber: state.enquiry.nextEnquiryNumber,
    allPreferences: state.common.allPreferences,
    employees: state.common.employees,
    remarksData: state.settings.remarksData,
    allRelationshipTaxIdentificatins:
      state.common.allRelationshipTaxIdentificatins,
    addresses: state.customer.addresses,
    boRelationshipAddress: state.common.boRelationshipAddress,
    customerContactList: state.customer.contacts,
    permissions: state.auth.permissions,
    headerLoading:state.common.headerLoading,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCustomerDetailsByCustomerId,
      fetchSuppliers,
      fetchCustomers,
      fetchProjects,
      resetRemarksData,
      fetchUOM,
      fetchProducts,
      fetchAllDepartments,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      deleteCustomers,
      addUOM,
      deleteUOM,
      addDepartment,
      deleteDepartment,
      deleteProject,
      fetchDocuments,
      addDocumentName,
      deleteDocumentName,
      deleteContact,
      fetchUserPreferences,
      saveUserPrefrences,
      getNextTxNumber,
      fetchAllEmployees,
      deleteEmployee,
      fetchRemarksTemplate,
      createEnquiry,
      fetchRelationshipTaxIdentifications,
      deleteCustomerTaxIdentifications,
      deleteRelationshipTaxIdentifications,
      fetchInquiryDetail,
      resetEnquiryDetail,
      deleteAddress,
      resetCustomerDetail,
      createEnquiryDraft,
      getRelationshipAddress,
      fetchAddresses,
      resetAddresses,
      fetchAllContacts,
      fetchSalesEnquiry,
      deleteAttectedFile, 
      fetchSalesInquiryDrafts, 
      downloadFileFromBucket,
      resetPaginatedData,
      deleteDocument,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SalesEnquiry);
