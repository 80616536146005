import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Tooltip, Pagination } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import SalesInvoiceDetail from '../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import { CONSTANTS } from '../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../../../utils';


const AllInvoicesByLedgerComponent = (props) => {
    const { salesInvoiceList, salesInvoiceCount, companyInfo, updateState, pageNumber } = props;

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    // const getRowHeight = () => {
    //     return 28;
    // }

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    const openInvoiceDetails = (data) => {
        const invoicePayload = {
            customerId: data.customerId,
            invoiceMasterId: data.invoiceMasterId
        }
        props.pushModalToStack({
            modalBody: <SalesInvoiceDetail {...props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceNumber' defaultMessage='' />;
            },
            field: "invoiceNumber",
            cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                openInvoiceDetails(link.data)
            }}>{link.value}</div>,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <div><FormattedMessage id='supplier.listing.unPaidInvoice.header.amount' defaultMessage='' /></div>;
            },
            field: "invoiceTotalAmount",
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
            resizable: true,
            cellRendererFramework: (params) => {
                return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
            },
            valueFormatter: (data) => {
                data.value = data.value ? fixedDecimalNumber(data.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                return data.value;
            }
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceDate' defaultMessage='' />;
            },
            field: "invoiceDate",
            cellRendererFramework: (link) => {
                return <div>
                    <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}>
                        {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                    </Tooltip>
                </div>
            },
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceDueDate' defaultMessage='' />;
            },
            field: "invoiceDueDate",
            cellRendererFramework: (link) => {
                return <div>
                    <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}>
                        {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                    </Tooltip>
                </div>
            },
            resizable: true,
        },

        {
            headerComponentFramework: () => {
                return <div><FormattedMessage id='supplier.listing.unPaidInvoice.header.paymentAmount' defaultMessage='' /> </div>;
            },
            field: "paymentAmount",
            cellRendererFramework: (params) => {
               
                return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.data.totalPaymentMade).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>;
            },
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {
            headerComponentFramework: () => {
                return <div><FormattedMessage id='supplier.listing.unPaidInvoice.header.dueAmount' defaultMessage='' /></div>;
            },
            field: "invoiceDueAmount",
            resizable: true,
            cellRendererFramework: (params) => {
                return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber( (params.data?.invoiceTotalAmount  - params.data?.totalPaymentMade) || 0)}</span>;
            },
            valueFormatter: (data) => {
                data.value = data.value ? fixedDecimalNumber(data.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
                return data.value;
            },
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='common.status' defaultMessage='' />;
            },
            field: "status",
            cellRendererFramework: (params) => {
                return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span> </div>
            },
            resizable: true,
        }
    ];

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize,  
            filters: {
            supplierId: this.props.supplierId,
            ledgerAccountId: this.props.ledgerAccountId
        } };
        fetchPaginationDataIfNeeded('fetchsalesInvoicesByFilter', 'salesInvoiceList', props, payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize,
            filters: {
                supplierId:  this.props.supplierId,
                ledgerAccountId: this.props.ledgerAccountId
            } };
        props.resetPaginatedData();
        props.fetchsalesInvoicesByFilter(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({id: 'pagination.text.total', defaultMessage: ''}) + ` ${ total } `+ props.intl.formatMessage({id: 'pagination.text.items', defaultMessage: ''});
    };


    return (
        <div className='agGridWrapper'>
            <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={salesInvoiceList[pageNumber] || []}
                    rowDragManaged={true}
                    domLayout={"autoHeight"}
                    animateRows={true}
                    onColumnResized ={(params)=>{
                        params.api.resetRowHeights();
                    }}
                    defaultColDef={{
                        flex:1,
                        autoHeight: true,
                        wrapText: true,
                    }}
                    //getRowHeight={getRowHeight}
                    onGridSizeChanged={onFirstDataRendered}
                >
                </AgGridReact>
                <Pagination
                    size="small"
                    total={salesInvoiceCount}
                    showTotal={showTotal}
                    defaultPageSize={100}  showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    // showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber, pageSize, props)
                    }}
                />
            </div>
        </div>
    );
};

export default injectIntl(AllInvoicesByLedgerComponent);
