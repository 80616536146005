import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

import PageBreadcrumb from "../../PageBreadcrumb";
import { FilterOutlined, InsertRowRightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import AllPageList from "./AllPageList";
import PublishedPageList from "./PublishedPageList";
import Dropdown from "../../leads/Formbuilder/components/Dropdown";
import DraftPageList from "./DraftPageList";
import { fetchDataIfNeeded } from "../../../utils";
import { fetchPaginationDataIfNeeded } from "../../../utils";
import { ICONS } from "../../../static/constants";
import Enquiry from "./Enquiry";
import { Input } from "antd";
import { Tabs } from "antd";
import "./index.scss";
function callback(key, props) {
  
  if (key === "1") {
    props.updateState({ activeTab: "All", activeTabKey: "1" });
  } else if (key === "2") {
    props.updateState({ activeTab: "Published", activeTabKey: "2" });
    
  } else if (key === "3") {
    props.updateState({ activeTab: "Draft", activeTabKey: "3" });
    
  }
}
const { TabPane } = Tabs;
const { Search } = Input;
const Pages = (props) => {
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.eStores" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.appearance" defaultMessage="" />
      ),
    },
    {
      name: "Page",
    },
  ];

  const openNewSi = () => {
    props.pushModalToStack({
      modalBody: <Enquiry {...props} pageTitle ="Add new page" buttonOne="Published" buttonTwo="Save As Draft"/>,
      width: "100%",
      hideTitle: true,
      hideFooter: true,
      wrapClassName: "new-transaction-wrapper",
    });
  };

  //  const handleCustomize = () =>{
  //   props.history.push('themes/customize')
  //  }

  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id="Page" defaultMessage="" />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div>

      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            {/* <Search
              placeholder="Search Page"
              // value={searchedText}
              // onChange={e => {
              //     updateState({ searchedText: e.target.value, pageNumber: 1 });
              //     onSearchedInputChange(e.target.value, props);
              // }}
              // onSearch={(searchedText) => {
              //     updateState({ searchedText, pageNumber: 1 });
              //     onSearchedInputChange(searchedText, props);
              //     handleSearchedText(searchedText, props);
              // }}
            /> */}
          </div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={() => {
                // props.history.push({
                //     pathname: "/admin/sales-inquiry-create",
                //     state: {
                //         type: "new"
                //     }
                // })
                openNewSi();
              }}
            >
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>

        <Tabs
          activeKey={props.activeTabKey || "1"}
          onChange={(key) => {
            callback(key, props);
          }}
          type="line"
        >
          <TabPane tab="All" key="1">
            <AllPageList {...props}/>
          </TabPane>
          <TabPane tab="Published" key="2">
            <PublishedPageList {...props}/>
          </TabPane>
          <TabPane tab="Draft" key="3">
            <DraftPageList {...props}/>
          </TabPane>
        </Tabs>
      </div>
    </Fragment>
  );
};

export default injectIntl(Pages);
