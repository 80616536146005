import React, { Component, Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { MailOutlined } from "@ant-design/icons";
import { Button, Switch, Row, Col, Tooltip, Radio, Checkbox, Tabs } from "antd";
import { Form } from "@ant-design/compatible";
import { Dropdown } from "../../../../general/Dropdown";
// import { MultipleSelectDropdown } from '../../../../general/MultipleSelectDropdown';
import { TextArea } from "../../../../general/TextAreaComponent";
import { TextBox } from "../../../../general/TextBox";
import { showToasterMessage } from "../../../../../utils";
// import RegistrationFormComponent from '../../../../../containers/modal/modalBody/membership/RegistrationFormSettingPreview';
// import ColorPicker from 'material-ui-rc-color-picker';
import "material-ui-rc-color-picker/assets/index.css";
import {
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  CONSTANTS_TRANSACTIONS_KEY,
} from "../../../../../static/constants";
import "./index.scss";
import RegistrationFormPreviewComponent from "../../../../../components/modal/modalBody/membership/RegistrationFormSettingPreview/inPagePreview";
import MobleLogo from "../../../../../assets/images/phone-icon.png";
import TableLogo from "../../../../../assets/images/tablet-icon.png";
import * as filter from "lodash.filter";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION_FORM } from "../../../../../static/ckEditorConfigConstants";
// import NewsLetterShare from '../../../../modal/modalBody/communication/NewsLetterShare';
import MobilePreview from "../../../../../components/modal/modalBody/membership/RegistrationFormSettingPreview/mobilePreview";
import TabPreview from "../../../../../components/modal/modalBody/membership/RegistrationFormSettingPreview/tabPreview";
import * as find from "lodash.filter";
import RecurringInvoiceSetup from "../../../../../containers/modal/modalBody/settings/RecurringInvoiceSetupForForm";
import RegistrationInvoiceSetting from "../../../../../containers/modal/modalBody/settings/RegistrationInvoiceSetting";
import RegistrationPaymentPlan from "../../../../../containers/modal/modalBody/settings/RegistrationPaymentPlan";
import ReminderSetupComponent from "../../../../../containers/modal/modalBody/settings/ReminderSetupForForm";
import EmailTemplate from "../../../../../containers/modal/modalBody/settings/EmailTemplate";
// import SetupSubcourseAndFees from '../../../../../containers/modal/modalBody/settings/RegistrationForm/SetupSubcourseAndFees';
import DiscountRuleCompo from "../../../../../containers/modal/modalBody/membership/RegistrationForm/DiscountRule";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";

const { TabPane } = Tabs;

class RegistrationFormSettingComponent extends Component {
  render() {
    const agGridStyle = {
      width: "100%",
    };
    // const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    const removeQuestion = (props, index) => {
      const modalData = {
        modalBody: (
          <FormattedMessage
            id="deleteItem.confirmation"
            values={{ value: "question" }}
          />
        ),
        handleSubmit: () => {
          let questionList = props.questionList;
          questionList.splice(index, 1);
          props.updateState({ questionList });
        },
      };
      props.pushModalToStack(modalData);
    };

    const getGradeLevelValidation = (props) => {
      return {
        1: props.grade1MaxStudent,
        2: props.grade2MaxStudent,
        3: props.grade3MaxStudent,
        4: props.grade4MaxStudent,
        5: props.grade5MaxStudent,
        6: props.grade6MaxStudent,
        7: props.grade7MaxStudent,
        8: props.grade8MaxStudent,
        9: props.grade9MaxStudent,
        10: props.grade10MaxStudent,
        11: props.grade11MaxStudent,
        12: props.grade12MaxStudent,
      };
    };

    // const getGradeLevelCount = () => {
    //     const props = this.props;
    //     return Number(props.grade1MaxStudent || 0) +
    //         Number(props.grade2MaxStudent || 0) +
    //         Number(props.grade3MaxStudent || 0) +
    //         Number(props.grade4MaxStudent || 0) +
    //         Number(props.grade5MaxStudent || 0) +
    //         Number(props.grade6MaxStudent || 0) +
    //         Number(props.grade7MaxStudent || 0) +
    //         Number(props.grade8MaxStudent || 0) +
    //         Number(props.grade9MaxStudent || 0) +
    //         Number(props.grade10MaxStudent || 0) +
    //         Number(props.grade11MaxStudent || 0) +
    //         Number(props.grade12MaxStudent || 0)
    // }

    const getGradeFee = (props) => {
      return {
        1: props.grade1Fee ? Number(props.grade1Fee).toFixed(2) : 0,
        2: props.grade2Fee ? Number(props.grade2Fee).toFixed(2) : 0,
        3: props.grade3Fee ? Number(props.grade3Fee).toFixed(2) : 0,
        4: props.grade4Fee ? Number(props.grade4Fee).toFixed(2) : 0,
        5: props.grade5Fee ? Number(props.grade5Fee).toFixed(2) : 0,
        6: props.grade6Fee ? Number(props.grade6Fee).toFixed(2) : 0,
        7: props.grade7Fee ? Number(props.grade7Fee).toFixed(2) : 0,
        8: props.grade8Fee ? Number(props.grade8Fee).toFixed(2) : 0,
        9: props.grade9Fee ? Number(props.grade9Fee).toFixed(2) : 0,
        10: props.grade10Fee ? Number(props.grade10Fee).toFixed(2) : 0,
        11: props.grade11Fee ? Number(props.grade11Fee).toFixed(2) : 0,
        12: props.grade12Fee ? Number(props.grade12Fee).toFixed(2) : 0,
      };
    };

    const getIsGradeDisplayed = (props) => {
      return {
        1: props.idGrade1Display,
        2: props.idGrade2Display,
        3: props.idGrade3Display,
        4: props.idGrade4Display,
        5: props.idGrade5Display,
        6: props.idGrade6Display,
        7: props.idGrade7Display,
        8: props.idGrade8Display,
        9: props.idGrade9Display,
        10: props.idGrade10Display,
        11: props.idGrade11Display,
        12: props.idGrade12Display,
      };
    };

    const getGradeDGList = (props) => {
      return {
        1: props.grade1DGList,
        2: props.grade2DGList,
        3: props.grade3DGList,
        4: props.grade4DGList,
        5: props.grade5DGList,
        6: props.grade6DGList,
        7: props.grade7DGList,
        8: props.grade8DGList,
        9: props.grade9DGList,
        10: props.grade10DGList,
        11: props.grade11DGList,
        12: props.grade12DGList,
      };
    };
    const getFormSettingPermissionList = (props) => {
      let permList = [
        {
          relationshipId: (props.companyInfo || {}).relationshipId,
          registrationFormSettingId: props.registrationFormSettingId,
          relEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
        },
      ];
      // props.userPermissions.forEach(function (userId) {
      //     if (userId !== (props.userInfo || {}).relationshipEmployeeId) {
      //         permList.push({
      //             relationshipId: (props.companyInfo || {}).relationshipId,
      //             registrationFormSettingId: props.registrationFormSettingId,
      //             relEmployeeId: userId,
      //         })
      //     }
      // });
      return props.registrationFormSettingId
        ? props.orgRegFormSettingPermissionList
        : permList;
    };

    const getFormSetting = (props) => {
      // let paymentGatewayObj = {
      //     alpidePaymentGatewayId: this.props.availablePaymentGateways.alpidePaymentGatewayId,
      //     userDefinedGatewayName: this.props.availablePaymentGateways.userDefinedGatewayName,
      //     paymentGatewayName: this.props.availablePaymentGateways.paymentGatewayName,
      // }
      return {
        formShortName: props.formShortName,
        // programDate: props.programDate ? formatDateForBackend(props.programDate) : formatDateForBackend(new Date()),
        fatherInfoRequired: props.fatherInfoRequired || 0,
        motherInfoRequired: props.motherInfoRequired || 0,
        spouseInfoRequired: props.spouseInfoRequired || 0,
        fatherFirstNameDisplay: props.fatherFirstNameDisplay || 0,
        fatherFirstNameRequired: props.fatherFirstNameRequired || 0,

        fatherLastNameDisplay: props.fatherLastNameDisplay || 0,
        fatherLastNameRequired: props.fatherLastNameRequired || 0,

        fatherMobileDisplay: props.fatherMobileDisplay || 0,
        fatherMobileRequired: props.fatherMobileRequired || 0,

        fatherEmailDisplay: props.fatherEmailDisplay || 0,
        fatherEmailRequired: props.fatherEmailRequired || 0,

        motherFirstNameDisplay: props.motherFirstNameDisplay || 0,
        motherFirstNameRequired: props.motherFirstNameRequired || 0,

        motherLastNameDisplay: props.motherLastNameDisplay || 0,
        motherLastNameRequired: props.motherLastNameRequired || 0,

        motherMobileDisplay: props.motherMobileDisplay || 0,
        motherMobileRequired: props.motherMobileRequired || 0,

        motherEmailDisplay: props.motherEmailDisplay || 0,
        motherEmailRequired: props.motherEmailRequired || 0,

        emergencyContactDisplay: props.emergencyContactDisplay || 0,
        emergencyContactRequired: props.emergencyContactRequired || 0,
        emergencyPhoneDisplay: props.emergencyPhoneDisplay || 0,
        emergencyPhoneRequired: props.emergencyPhoneRequired || 0,

        addressDisplay: props.addressDisplay,
        addressRequired: props.addressRequired,

        paymentTermName: (props.paymentTerm || {}).termName,
        paymentTermId: (props.paymentTerm || {}).paymentTermId,
        numberOfDays: (props.paymentTerm || {}).numberOfDays,

        textColor: props.textColor,
        bgColor: props.bgColor,

        questionList: props.questionList,
        collectStudentInfo: props.collectStudentInfo,
        schoolLevelValidation: props.schoolLevelValidation,
        maxStudent: props.schoolLevelValidation ? props.maxStudent : 0,
        gradeLevelValidation: props.gradeLevelValidation,
        gradeValidations: props.gradeLevelValidation
          ? getGradeLevelValidation(props)
          : {},
        totalStudentCountByGrade: props.gradeLevelValidation
          ? getGradeLevelValidation(props)
          : {},
        collectOnlinePayment: props.collectOnlinePayment,
        gradeFees: getGradeFee(props),
        paymentGateway: props.selectedPaymentGateway,
        paymentGatewayId: (props.selectedPaymentGateway || {})
          .alpidePaymentGatewayId,
        userDefinedGatewayName: (props.selectedPaymentGateway || {})
          .userDefinedGatewayName,
        paymentGatewayName: (props.selectedPaymentGateway || {})
          .paymentGatewayName,
        subHeading: this.props.subHeading,
        inactiveMessage: props.inactiveMessage,
        registrationType: props.registrationType,
        firstNameDisplay: props.firstNameDisplay,
        firstNameRequired: props.firstNameRequired,
        lastNameDisplay: props.lastNameDisplay,
        lastNameRequired: props.lastNameRequired,
        phoneDisplay: props.phoneDisplay,
        phoneRequired: props.phoneRequired,
        genderDisplay: props.genderDisplay,
        genderRequired: props.genderRequired,
        dobDisplay: props.dobDisplay,
        dobRequired: props.dobRequired,
        emailDisplay: props.emailDisplay,
        emailRequired: props.emailRequired,
        fatherDOBDisplay: props.fatherDOBDisplay,
        fatherDOBRequired: props.fatherDOBRequired,
        motherDOBDisplay: props.motherDOBDisplay,
        motherDOBRequired: props.motherDOBRequired,
        // membershipOptions: props.membershipOptions,
        selectedMembershipOptions: filter(
          props.membershipOptionList[1],
          function (opt) {
            return (
              (props.membershipOptions || []).indexOf(opt.membershipOptionId) >
              -1
            );
          }
        ),
        membershipOptionRequired: props.membershipOptionRequired,
        formDescription: props.formDescription,
        footerMessage: props.footerMessage,
        feeAmount: props.collectOnlinePayment ? props.feeAmount : 0,

        spouseFirstNameDisplay: props.spouseFirstNameDisplay || 0,
        spouseFirstNameRequired: props.spouseFirstNameRequired || 0,
        spouseLastNameDisplay: props.spouseLastNameDisplay || 0,
        spouseLastNameRequired: props.spouseLastNameRequired || 0,
        spouseEmailDisplay: props.spouseEmailDisplay || 0,
        spouseEmailRequired: props.spouseEmailRequired || 0,
        spouseMobileDisplay: props.spouseMobileDisplay || 0,
        spouseMobileRequired: props.spouseMobileRequired || 0,
        spouseDOBDisplay: props.spouseDOBDisplay || 0,
        spouseDOBRequired: props.spouseDOBRequired || 0,

        familyDoctorNameDisplay: props.familyDoctorNameDisplay || 0,
        familyDoctorNameRequired: props.familyDoctorNameRequired || 0,
        familyDoctorPhoneDisplay: props.familyDoctorPhoneDisplay || 0,
        familyDoctorPhoneRequired: props.familyDoctorPhoneRequired || 0,

        maritalStatusDisplay: props.maritalStatusDisplay,
        maritalStatusRequired: props.maritalStatusRequired,

        nationalityDisplay: props.nationalityDisplay,
        nationalityRequired: props.nationalityRequired,
        countryOfBirthDisplay: props.countryOfBirthDisplay,
        countryOfBirthRequired: props.countryOfBirthRequired,
        countryOfResidenceDisplay: props.countryOfResidenceDisplay,
        countryOfResidenceRequired: props.countryOfResidenceRequired,

        membershipTypeDisplay: props.membershipTypeDisplay,
        membershipTypeRequired: props.membershipTypeRequired,

        primaryContactForInvoicing: props.primaryContactForInvoicing,

        isGradeDisplayed: getIsGradeDisplayed(props),
        addEnrolmentToDG: props.addEnrolmentToDG,
        //reminderList:  props.reminderList || [],
        sessionStart: props.sessionStart,
        sessionEnd: props.sessionEnd,
        // totalMonthSchoolSession: getMonthsBetweenDates(props.sessionStart, props.sessionEnd),
        isGenerateInvoiceOnRegistration: props.isGenerateInvoice
          ? props.isGenerateInvoiceOnRegistration
          : 0,
        isDisplayYearlyFee: props.isGenerateInvoice
          ? props.isDisplayYearlyFee
          : 0,
        isStopRegistrationAfterMaxStudent:
          props.isStopRegistrationAfterMaxStudent,
        invalidGradeMessage: props.invalidGradeMessage,
        askFinanacialAssistance: props.askFinanacialAssistance,
        askOrgMember: props.askOrgMember,
        askFinanacialAssistanceRequired: props.askFinanacialAssistanceRequired,
        askOrgMemberRequired: props.askOrgMemberRequired,
        documentNameId: props.documentNameId,
        docName: props.docName,
        invoiceTitle: props.invoiceTitle,
        invoiceTitleOnetime: props.invoiceTitleOnetime,
        askAddTeacher: props.askAddTeacher,
        askAddTeacherRequired: props.askAddTeacherRequired,
        askAddVolunteer: props.askAddVolunteer,
        askAddVolunteerRequired: props.askAddVolunteerRequired,
        isApplyDiscountToOnetimeInvoice: props.isApplyDiscountToOnetimeInvoice
          ? props.isApplyDiscountToOnetimeInvoice
          : 0,
        educationHistory: props.educationHistory || 0,
        educationHistoryRequired: props.educationHistoryRequired || 0,
        employmentHistoryDisplay: this.props.employmentHistoryDisplay || 0,
        employmentHistoryRequired: this.props.employmentHistoryRequired || 0,
      };
    };

    const isGradeValidationRulesAdded = (props) => {
      if (!props.gradeLevelValidation) {
        return true;
      }
      let isValidMaxStudent = true;
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(function (grade) {
        if (
          props[`idGrade${grade}Display`] &&
          !props[`grade${grade}MaxStudent`]
        ) {
          isValidMaxStudent = false;
          return isValidMaxStudent;
        }
      });
      return isValidMaxStudent;
    };

    // const isValidGradeFee = (props) => {
    //     // if (!props.collectOnlinePayment) { return true }
    //     let validGradFee = true;
    //     [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(function (grade) {
    //         if (props[`idGrade${grade}Display`] && !props[`grade${grade}Fee`]) {
    //             validGradFee = false;
    //             return validGradFee;
    //         }
    //     });
    //     return validGradFee;
    // }

    const isValidGradeDG = (props) => {
      if (!props.addEnrolmentToDG) {
        return true;
      }
      let validGradeDG = true;
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(function (grade) {
        if (
          props[`idGrade${grade}Display`] &&
          !(props[`grade${grade}DGList`] && props[`grade${grade}DGList`].length)
        ) {
          validGradeDG = false;
          return validGradeDG;
        }
      });
      return validGradeDG;
    };

    const saveSettings = (props) => {
      if (
        props.textColor &&
        props.bgColor &&
        props.textColor === props.bgColor
      ) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({ id: "sameColor.validation" }),
        });
      }
      if (!props.formHeading) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "registration.formHeading.required",
          }),
        });
      }

      if (!props.formShortName) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "registration.formShortName.required",
          }),
        });
      }
      // if (props.registrationType === 'student' && (!props.sessionStart || !props.sessionEnd)) {
      //     return showToasterMessage({
      //         description: props.intl.formatMessage({ id: 'registation.validation.schoolSession' }),
      //         messageType: 'error'
      //     });
      // }
      if (!props.paymentTerm || !props.paymentTerm.termName) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "registration.paymentTerm.required",
          }),
        });
      }
      if (!props.projectName || !props.projectMasterId) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "projectName.required.validation",
          }),
        });
      }
      if (props.registrationType === "student" && !props.courseId) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "registration.course.required",
          }),
        });
      }
      if (props.schoolLevelValidation && !props.maxStudent) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "setting.student.registration.maxStudent.missing",
          }),
        });
      }
      if (props.collectOnlinePayment && !props.selectedPaymentGateway) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "paymentGateway.notAvailable.message",
          }),
        });
      }
      // if (props.collectOnlinePayment && !props.ledgerAccountIdPayment) {
      //     return showToasterMessage({
      //         messageType: 'error', description: props.intl.formatMessage({ id: 'paymenLedgerAccount.notSelected.message' })
      //     });
      // }
      if (
        props.registrationType === "student" &&
        !(
          (props.fatherFirstNameDisplay && props.fatherFirstNameRequired) ||
          (props.motherFirstNameDisplay && props.motherFirstNameRequired)
        )
      ) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "fatherAndMotherName.validation.message",
          }),
        });
      }
      if (
        props.registrationType === "student" &&
        !(
          (props.fatherEmailDisplay && props.fatherEmailRequired) ||
          (props.motherEmailDisplay && props.motherEmailRequired)
        )
      ) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "fatherAndMotherEmail.validation.message",
          }),
        });
      }
      if (
        props.registrationType === "student" &&
        !props.primaryContactForInvoicing
      ) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "primaryContactForInvoicing.validation.message",
          }),
        });
      }
      if (props.gradeLevelValidation && !isGradeValidationRulesAdded(props)) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "maxStudent.missing.for.grade.message",
          }),
        });
      }
      // if((props.collectOnlinePayment === 1 || props.isGenerateInvoice === 1) && !isValidGradeFee(props)){
      // if (!isValidGradeFee(props)) {
      //     return showToasterMessage({
      //         messageType: 'error', description: props.intl.formatMessage({ id: 'amount.missing.for.grade.message' })
      //     });
      // }
      if (props.addEnrolmentToDG && !isValidGradeDG(props)) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "dg.missing.for.grade.message",
          }),
        });
      }
      if (props.isGenerateInvoice === 1 && !props.invoiceItemDescription) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "invoiceItemDescription.validationMessage",
          }),
        });
      }
      if (props.isGenerateInvoice && !props.ledgerAccountIdInvoice) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "invoiceLedgerAccount.notSelected.message",
          }),
        });
      }
      if (
        props.isAllowParentPaymentPlan &&
        (!props.paymentPlan || !props.paymentPlan.length)
      ) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "registration.parentPaymentPlan.required",
          }),
        });
      }
      // if (props.isGenerateInvoice && (!props.isAllowParentPaymentPlan && props.invoiceCycle !== 'monthly' && props.invoiceCycle !== 'oneTime')) {
      //     return showToasterMessage({
      //         messageType: 'error', description: props.intl.formatMessage({ id: 'registration.feeSetup.required' })
      //     });
      // }
      if (
        props.isGenerateInvoice &&
        (props.isAllowParentPaymentPlan || props.invoiceCycle === "monthly") &&
        (!props.templateData || !props.templateData.settingEmailTemplateId)
      ) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "recurringInvoiceEmailTemplate.validation",
          }),
        });
      }
      // if (props.isGenerateInvoice && (!props.subcourseAndFeeList || !props.subcourseAndFeeList.length)) {
      //     return showToasterMessage({
      //         messageType: 'error', description: props.intl.formatMessage({ id: 'registration.subcourseSetup.required' })
      //     });
      // }
      if (props.gradeLevelValidation && !props.invalidGradeMessage) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "registration.invalidGradeMessage.validationMessage",
          }),
        });
      }
      // if (!props.userPermissions || !props.userPermissions.length) {
      //     return showToasterMessage({
      //         messageType: 'error', description: props.intl.formatMessage({ id: 'allowedUser.validationMessage' })
      //     });
      // }
      const modalData = {
        modalBody: <FormattedMessage id="save.confirmation" />,
        handleSubmit: () => {
          let formSetting = getFormSetting(props);
          let payload = {
            formShortName: props.formShortName,
            relationshipId: (props.companyInfo || {}).relationshipId,
            registrationFormSettingId: props.registrationFormSettingId,
            updatedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            createdByEmpId:
              props.createdByEmpId ||
              (props.userInfo || {}).relationshipEmployeeId,
            version: props.version,
            registrationType: props.registrationType || "student",
            programName: props.formHeading,
            formSetting: JSON.stringify(formSetting),
            isSendEmail: props.isSendEmail,
            successMessage: props.successMessage,
            emailMessage: props.isSendEmail
              ? (props.emailDetails || {}).message
              : "",
            emailSubject: props.isSendEmail
              ? (props.emailDetails || {}).subject
              : "",
            projectName: props.projectName,
            projectMasterId: props.projectMasterId,
            isFormActive: props.isFormActive,
            ledgerAccountIdPayment: props.ledgerAccountIdPayment,
            isGenerateInvoice: props.isGenerateInvoice,
            ledgerAccountIdInvoice: props.ledgerAccountIdInvoice,
            invoiceCycle: props.invoiceCycle,
            isGenerateOnetimeInvoice: props.invoiceCycle === "oneTime" ? 1 : 0,
            isGenerateRecurringInvoice:
              props.invoiceCycle === "monthly" &&
              !props.isAllowParentPaymentPlan
                ? 1
                : 0,
            isAllowParentPaymentPlan: props.isGenerateInvoice
              ? props.isAllowParentPaymentPlan
              : 0,
            recurringInvoiceSettings:
              props.invoiceCycle === "monthly"
                ? JSON.stringify(props.recurringInvoiceSettings || {})
                : "{}",
            gradeDG: JSON.stringify(getGradeDGList(props) || ""),
            addEnrolmentToDG: props.addEnrolmentToDG,
            invoiceItemDescription: props.invoiceItemDescription,
            onetimeInvoiceItem: JSON.stringify(props.invoiceItem || []),
            paymentPlan: JSON.stringify(props.paymentPlan || []),
            courseId: props.courseId,
            invoiceReminder: JSON.stringify(props.reminderList || []),
            subcourse: JSON.stringify(props.subcourseAndFeeList || []),
            isPublishParentPortal: props.isPublishParentPortal,
            invoiceDiscountRule: JSON.stringify(props.discountRuleList || []),
            // schoolSessionId: props.schoolSessionId,
            // userPermissions: JSON.stringify(props.userPermissions || [])
            orgRegFormSettingPermissionList:
              getFormSettingPermissionList(props),
          };

          props.saveRegistrationFormSetting(payload, props);
        },
      };
      props.pushModalToStack(modalData);
    };

    // const previewForm = (props) => {
    //     let formSetting = getFormSetting(props);
    //     this.props.pushModalToStack({
    //         modalBody: <RegistrationFormComponent formSetting={formSetting} registrationFormSettingId={props.registrationFormSettingId} relationshipId={(this.props.companyInfo || {}).relationshipId} modalType='preview' />,
    //         title: <FormattedMessage id='preview.text' />,
    //         width: '600',
    //         hideFooter: true,
    //         wrapClassName: 'modal-custom-detail '
    //     })
    // }

    const mobilePreviewPopup = (props) => {
      let formSetting = getFormSetting(props);
      this.props.pushModalToStack({
        modalBody: (
          <MobilePreview
            {...this.props}
            formSetting={formSetting}
            registrationFormSettingId={props.registrationFormSettingId}
            relationshipId={(this.props.companyInfo || {}).relationshipId}
            modalType="preview"
          />
        ),
        // title: <FormattedMessage id='preview.text' />,
        title: "Mobile Preview",
        width: "300px",
        hideFooter: true,
        wrapClassName: "modal-custom-detail-new ",
      });
    };
    const tabPreviewPopup = (props) => {
      let formSetting = getFormSetting(props);
      this.props.pushModalToStack({
        modalBody: (
          <TabPreview
            {...this.props}
            formSetting={formSetting}
            registrationFormSettingId={props.registrationFormSettingId}
            relationshipId={(this.props.companyInfo || {}).relationshipId}
            modalType="preview"
          />
        ),
        // title: <FormattedMessage id='preview.text' />,
        title: "Tablet Preview",
        width: "450px",
        hideFooter: true,
        wrapClassName: "modal-custom-detail-tab ",
      });
    };

    const openEmailTemplates = (props) => {
      const modalData = {
        title: (
          <FormattedMessage
            id="setting.emailTemplates.label"
            defaultMessage=""
          />
        ),
        modalBody: (
          <EmailTemplate
            {...props}
            docName="Recurring Sales Invoice"
            isFormSetting={true}
          />
        ),
        width: 800,
        hideFooter: true,
        wrapClassName: "show-setting-email-template",
      };
      props.pushModalToStack(modalData);
    };

    const openRecurringSetting = (props) => {
      const modalData = {
        title: (
          <FormattedMessage
            id="popup.recurringInvoice.title"
            defaultMessage=""
          />
        ),
        modalBody: (
          <RecurringInvoiceSetup
            {...props}
            isBulkInvoice={true}
            isFormSetting={true}
            addRecurringInvoiceSettings={(data) => {
              props.updateState({ recurringInvoiceSettings: data });
            }}
          />
        ),
        width: "50%",
        hideFooter: true,
      };

      props.pushModalToStack(modalData);
    };

    const getOneTimeChargesTotalAmount = (props) => {
      if (!props.invoiceItem || !props.invoiceItem.length) {
        return 0.0;
      }
      let total = 0;
      props.invoiceItem.forEach(function (item) {
        if (item.amount) {
          total += Number(item.amount);
        }
      });
      return total.toFixed(2);
    };

    const openRegistrationInvoiceSetting = (props) => {
      const modalData = {
        title: (
          <div>
            <FormattedMessage id="invoiceDetails.text" defaultMessage="" />
            <div style={{ fontSize: "12px" }}> Onetime invoice charges</div>
          </div>
        ),
        modalBody: (
          <RegistrationInvoiceSetting
            {...props}
            isBulkInvoice={true}
            isFormSetting={true}
            addInvoiceItemList={(data, invoiceTitleOnetime) => {
              props.updateState({
                invoiceItem: data,
                invoiceTitleOnetime: invoiceTitleOnetime,
              });
            }}
          />
        ),
        width: "50%",
        hideFooter: true,
      };

      props.pushModalToStack(modalData);
    };

    const openAddPaymentPlan = (props) => {
      // if (!props.sessionStart || !props.sessionEnd) {
      //     return showToasterMessage({
      //         description: props.intl.formatMessage({ id: 'registation.validation.schoolSession' }),
      //         messageType: 'error'
      //     });
      // }
      const modalData = {
        title: (
          <div>
            <FormattedMessage id="addPaymentPlan.text" defaultMessage="" />
          </div>
        ),
        modalBody: (
          <RegistrationPaymentPlan
            {...props}
            isBulkInvoice={true}
            isFormSetting={true}
            addPaymentPlans={(data) => {
              props.updateState({ paymentPlan: data });
            }}
          />
        ),
        width: "50%",
        hideFooter: true,
      };

      props.pushModalToStack(modalData);
    };

    // const openSetupSubcourseAndFees = (props) => {
    //     let gradeList = [];
    //     [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(function (grade) {
    //         if (props[`idGrade${grade}Display`]) {
    //             gradeList.push(grade);
    //         }
    //     });
    //     if (!gradeList.length) {
    //         return showToasterMessage({
    //             description: props.intl.formatMessage({ id: 'registration.grade.required' }),
    //             messageType: 'error'
    //         });
    //     }
    //     const modalData = {
    //         title: <div>
    //             <FormattedMessage id='setupSubcourseAndFees.text' defaultMessage='' />
    //         </div>,
    //         modalBody: <SetupSubcourseAndFees {...props} gradeList={gradeList} addSubcourseAndFeeList={(data) => { props.updateState({ subcourseAndFeeList: data }) }} />,
    //         width: '50%',
    //         hideFooter: true,
    //     };

    //     props.pushModalToStack(modalData);
    // }

    const openAddDiscountRule = (props) => {
      const modalData = {
        title: (
          <div>
            <FormattedMessage id="discountRule.text" defaultMessage="" />
          </div>
        ),
        modalBody: (
          <DiscountRuleCompo
            {...props}
            addDiscountRuleList={(data, isApplyDiscountToOnetimeInvoice) => {
              props.updateState({
                discountRuleList: data,
                isApplyDiscountToOnetimeInvoice:
                  isApplyDiscountToOnetimeInvoice,
              });
            }}
          />
        ),
        width: "60%",
        hideFooter: true,
      };

      props.pushModalToStack(modalData);
    };

    const openAddReminder = (props) => {
      props.pushModalToStack({
        title: <FormattedMessage id="dueFeeReminder.text" defaultMessage="" />,
        modalBody: (
          <ReminderSetupComponent
            {...props}
            isFormSetting={true}
            addReminderSetting={(data) => {
              // let reminderList = props.reminderList || [];
              // reminderList.push(data);
              props.updateState({ reminderList: data });
            }}
          />
        ),
        width: "50%",
        hideFooter: true,
      });
    };

    const handleNewProjectAddition = (props, payload) => {
      const formData = payload.formData;
      const { showModal, companyInfo } = props;
      formData.relationshipId = companyInfo.relationshipId;
      const data = {
        title: <FormattedMessage id="addItem.text.project" defaultMessage="" />,
        formData,
        hideFooter: true,
        modalData: {
          modalType: MODAL_TYPE.PROJECT,
          data: payload,
        },
        handleSubmit: (formData = {}) => {
          data.formData.submittedOnce = true;
          showModal(data);
        },
      };
      showModal(data);
    };

    // const handleNewSessionAddition = (props, payload) => {
    //     const formData = payload.formData;
    //     const { showModal, companyInfo } = props;
    //     formData.relationshipId = companyInfo.relationshipId;
    //     const data = {
    //         title: <FormattedMessage id='schoolSession.text' defaultMessage='' />,
    //         formData,
    //         hideFooter: true,
    //         width: '40%',
    //         modalData: {
    //             modalType: MODAL_TYPE.SCHOOL_SESSION,
    //             data: payload,

    //         },
    //         handleSubmit: (formData = {}) => {
    //             data.formData.submittedOnce = true;
    //             showModal(data);
    //         }
    //     };
    //     showModal(data);
    // }

    const addItemConfirmationModal = (props, text, modalType) => {
      const { showModal, companyInfo } = props;
      const labelName = getLabelName(modalType);
      const modalBody = (
        <span>
          <FormattedMessage
            id="addItem.confirmation"
            values={{ value: `${text} to ` }}
            defaultMessage=""
          />
          {labelName} ?
        </span>
      );

      let addFunc;
      let payload = {
        text: text,
        relationshipId: companyInfo.relationshipId,
      };
      switch (modalType) {
        case MODAL_TYPE.DOCUMENT_NAME: {
          addFunc = props.addDocumentName;
          payload.txType =
            CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE];
          break;
        }
        default: {
          addFunc = () => {};
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          addFunc(payload);
          props.hideModal();
        },
      };
      showModal(modalData);
    };

    const getModalTitle = (newTextObj, modalType) => {
      let title;
      switch (modalType) {
        case MODAL_TYPE.DOCUMENT_NAME: {
          title = (
            <FormattedMessage id="invoiceDocumentName.text" defaultMessage="" />
          );
          break;
        }

        default:
          title = (
            <FormattedMessage
              id="signup.confirmation.title"
              defaultMessage=""
            />
          );
      }
      return title;
    };

    const handleNewItemAddition = (props, payload, modalType) => {
      const formData = payload.formData;
      const { showModal } = props;

      if (
        formData.textBoxValue &&
        formData.textBoxValue.length &&
        !payload.existsInData
      ) {
        addItemConfirmationModal(props, formData.textBoxValue, modalType);
      } else {
        const data = {
          title: getModalTitle(payload, modalType),
          formData,
          modalData: { modalType, data: payload },
          handleSubmit: (formData = {}) => {
            if (formData.textBoxValue && formData.textBoxValue.length) {
              addItemConfirmationModal(props, formData.textBoxValue, modalType);
            } else {
              data.formData.submittedOnce = true;
              showModal(data);
            }
          },
        };
        showModal(data);
      }
    };

    const deleteClickHandler = (props, modalType, payload) => {
      const { showModal, companyInfo } = props;
      const modalBody = (
        <FormattedMessage
          id="deleteItem.confirmation"
          defaultMessage=""
          values={{ value: payload.text }}
        />
      );

      let deleteFunc;
      let callback = function (data) {};
      switch (modalType) {
        case MODAL_TYPE.PAYMENT_TERM: {
          deleteFunc = props.deletePaymentTerm;
          break;
        }
        case MODAL_TYPE.PROJECT: {
          deleteFunc = props.deleteProject;
          break;
        }
        // case MODAL_TYPE.SCHOOL_SESSION: {
        //     deleteFunc = props.deleteSchoolSession;
        //     break;
        // }
        case MODAL_TYPE.DOCUMENT_NAME: {
          deleteFunc = props.deleteDocumentName;
          break;
        }
        default: {
          deleteFunc = () => {};
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          deleteFunc({
            id: payload.id,
            relationshipId: companyInfo.relationshipId,
            customerId: (props.customer || {}).customerId,
            callback,
          });
          if (
            modalType === MODAL_TYPE.DOCUMENT_NAME &&
            payload.id === this.props.documentNameId
          ) {
            const selectedObj =
              find(this.props.documentList || [], {
                txType:
                  CONSTANTS_TRANSACTIONS_KEY[
                    CONSTANTS_TRANSACTIONS.SALES_INVOICE
                  ],
                isDefault: 1,
              }) || {};
            this.props.updateState({
              documentNameId: selectedObj.documentNameId,
              docName: selectedObj.docName,
            });
          }
        },
      };
      showModal(modalData);
    };

    return (
      <Fragment>
        <div className="modal-dialog">
          <div className="modal-content full-width modify-width">
            <div className="inner-content reg-responsive">
              <div className="bank-info">
                <Row>
                  <Col span={11} className="respo-box">
                    <div className="account-integsmr padding-right20 newheights">
                      <div className="height580  padding-right20 ltuiy ">
                        {/* <h2 style={{ textAlign: 'center' }}><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></h2> */}
                        <Tabs type="line" className="primary-tab-container">
                          <TabPane tab="Form" key="1">
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage id="formActive.text" />
                                </Col>
                                <Col span={13}>
                                  <Switch
                                    checked={this.props.isFormActive === 1}
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        isFormActive: checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage id="publishParentPortal.text" />
                                </Col>
                                <Col span={13}>
                                  <Switch
                                    checked={
                                      this.props.isPublishParentPortal === 1
                                    }
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        isPublishParentPortal: checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>

                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <Tooltip
                                    placement="bottom"
                                    title={this.props.intl.formatMessage({
                                      id: "registration.inactiveMessage.helpMessage",
                                    })}
                                  >
                                    <span className="tooltip-title">
                                      <FormattedMessage id="inactiveMessage.text" />{" "}
                                    </span>
                                  </Tooltip>
                                </Col>
                                <Col span={13}>
                                  <TextArea
                                    maxLength={200}
                                    rows={1}
                                    style={{ width: "100%", padding: "5px" }}
                                    placeholder={this.props.intl.formatMessage({
                                      id: "registration.inactiveMessage.helpMessage",
                                    })}
                                    value={this.props.inactiveMessage}
                                    onChange={(e) => {
                                      this.props.updateState({
                                        inactiveMessage: e.target.value,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>

                            {/* <Form.Item>
                                            <Row>
                                                <Col span={1}>
                                                </Col>
                                                <Col span={10}>
                                                    <FormattedMessage id='formHeading.bgColor' />
                                                </Col>
                                                <Col span={13} className='regis-color'>
                                                    <ColorPicker
                                                        enableAlpha={false}
                                                        color={this.props.bgColor || '31C0BE'}
                                                        onChange={(obj) => {
                                                            this.props.updateState({ bgColor: obj.color });
                                                        }}
                                                        mode="RGB"
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Form.Item>
                                            <Row>
                                                <Col span={1}>
                                                </Col>
                                                <Col span={10}>
                                                    <FormattedMessage id='formHeading.textColor' />
                                                </Col>
                                                <Col span={13} className='regis-color'>
                                                    <ColorPicker
                                                        enableAlpha={false}
                                                        color={this.props.textColor || 'fff'}
                                                        onChange={(obj) => {
                                                            this.props.updateState({ textColor: obj.color });
                                                        }}
                                                        mode="RGB"
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Item> */}

                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage id="formHeading.text" />
                                  <span className="required">*</span>
                                </Col>
                                <Col span={13}>
                                  <TextBox
                                    maxLength={45}
                                    style={{ width: "100%", padding: "5px" }}
                                    placeholder={this.props.intl.formatMessage({
                                      id: "formHeading.text",
                                    })}
                                    value={this.props.formHeading}
                                    onChange={(e) => {
                                      this.props.updateState({
                                        formHeading: e.target.value,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage id="formSubHeading.text" />
                                </Col>
                                <Col span={13}>
                                  <TextArea
                                    maxLength={1000}
                                    rows={1}
                                    style={{ width: "100%", padding: "5px" }}
                                    placeholder={this.props.intl.formatMessage({
                                      id: "formSubHeading.text",
                                    })}
                                    value={this.props.subHeading}
                                    onChange={(e) => {
                                      this.props.updateState({
                                        subHeading: e.target.value,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <Tooltip
                                    placement="bottom"
                                    title={this.props.intl.formatMessage({
                                      id: "tooltip.formShortName",
                                    })}
                                  >
                                    <span className="tooltip-title">
                                      <FormattedMessage id="formShortName.text" />
                                    </span>
                                    <span className="required">*</span>
                                  </Tooltip>
                                </Col>
                                <Col span={13}>
                                  <TextBox
                                    maxLength={20}
                                    style={{ width: "100%", padding: "5px" }}
                                    placeholder={this.props.intl.formatMessage({
                                      id: "formShortName.text",
                                    })}
                                    value={this.props.formShortName}
                                    onChange={(e) => {
                                      this.props.updateState({
                                        formShortName: e.target.value,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>

                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage id="formDescription.text" />
                                </Col>
                                <Col span={13}>
                                  <CKEditor
                                    data={this.props.formDescription}
                                    onInit={(editor) => {
                                      this.editor = editor;
                                    }}
                                    onChange={(event) => {
                                      const data = event.editor.getData();
                                      this.props.updateState({
                                        formDescription: data,
                                      });
                                    }}
                                    config={{
                                      ...CUSTOM_CONFIGURATION_FORM,
                                      wordcount: {},
                                      placeholder: "Enter form description",
                                    }}
                                  />
                                  {/* <TextArea maxLength={1000}
                                                        rows={1}
                                                        style={{ width: '100%', padding: '5px' }}
                                                        placeholder={this.props.intl.formatMessage({ id: 'formDescription.text' })}
                                                        value={this.props.formDescription}
                                                        onChange={(e) => {
                                                            this.props.updateState({ formDescription: e.target.value });
                                                        }}
                                                    /> */}
                                </Col>
                              </Row>
                            </Form.Item>

                            {/* <Form.Item>
                                                            <Row>
                                                                <Col span={1}>
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Tooltip placement="bottom" title={this.props.intl.formatMessage({ id: 'tooltip.schoolSession' })}>
                                                                        <span className="tooltip-title">
                                                                            <FormattedMessage id='schoolSession.text' /><span className="required">*</span>
                                                                        </span>
                                                                    </Tooltip>
                                                                </Col>
                                                                <Col span={13}>

                                                                    <Dropdown
                                                                        items={this.props.schoolSessionList || []}
                                                                        placeholder={this.props.intl.formatMessage({ id: 'schoolSession.text' })}
                                                                        valueKeyName=' '
                                                                        dateKeyName1='startDate'
                                                                        dateKeyName2='endDate'
                                                                        optionKeyName='schoolSessionId'
                                                                        value={this.props.selectedSessionName}
                                                                        onSelect={
                                                                            (optionValue) => {
                                                                                let selectedSession = (this.props.schoolSessionList.find(ind => (ind.schoolSessionId === optionValue))) || {};
                                                                                this.props.updateState({
                                                                                    selectedSessionName: getMomentDateForUIReadOnly({ date: selectedSession.startDate, format }) + ' - ' + getMomentDateForUIReadOnly({ date: selectedSession.endDate, format }),
                                                                                    schoolSessionId: selectedSession.schoolSessionId,
                                                                                    sessionStart: selectedSession.startDate,
                                                                                    sessionEnd: selectedSession.endDate
                                                                                });
                                                                            }
                                                                        }
                                                                        canAddNew={true}
                                                                        canDelete={true}
                                                                        deleteClickHandler={(payload) => {
                                                                            deleteClickHandler(this.props, MODAL_TYPE.SCHOOL_SESSION, payload);
                                                                        }}
                                                                        onAddNewClick={(payload) => {
                                                                            payload.formData = {}
                                                                            handleNewSessionAddition(this.props, payload, MODAL_TYPE.SCHOOL_SESSION);
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {(this.props.sessionStart && this.props.sessionEnd) ?
                                                                <Row>
                                                                    <Col span={11}></Col>
                                                                    <Col span={13}>Total months - {getMonthsBetweenDates(this.props.sessionStart, this.props.sessionEnd)}</Col>
                                                                </Row>
                                                                : ''
                                                            }
                                                        </Form.Item> */}

                            {/* <Form.Item>
                                            <Row>
                                                <Col span={1}>
                                                </Col>
                                                <Col span={10}>
                                                    <FormattedMessage id='registrationForm.date' /><span className="required">*</span>
                                                </Col>
                                                <Col span={13}>
                                                    <DatePicker
                                                        className={'cursor-pointer'}
                                                        allowClear={false}
                                                        format={format}
                                                        key={`${this.props.programDate ? getMomentDateForUI({ date: new Date(this.props.programDate), format }) : getMomentDateForUI({ date: new Date(), format })}`}
                                                        defaultValue={this.props.programDate ? getMomentDateForUI({ date: new Date(this.props.programDate), format }) : getMomentDateForUI({ date: new Date(), format })}
                                                        onChange={(selectedDate) => {
                                                            this.props.updateState({
                                                                programDate: selectedDate
                                                            })
                                                        }}

                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Item> */}

                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <Tooltip
                                    placement="bottom"
                                    title={this.props.intl.formatMessage({
                                      id: "registration.project.helpMessage",
                                    })}
                                  >
                                    <span className="tooltip-title">
                                      <FormattedMessage id="modal.common.project.projectName" />
                                      <span className="required">*</span>
                                    </span>
                                  </Tooltip>
                                </Col>
                                <Col span={13}>
                                  <Dropdown
                                    items={this.props.projectList}
                                    placeholder={this.props.intl.formatMessage({
                                      id: "salesInvoice.project.placeholder",
                                    })}
                                    valueKeyName="projectName"
                                    optionKeyName="projectMasterId"
                                    value={this.props.projectName}
                                    onSelect={(optionValue) => {
                                      let selectedProject =
                                        this.props.projectList.find(
                                          (ind) =>
                                            ind.projectMasterId === optionValue
                                        ) || {};
                                      this.props.updateState({
                                        projectName:
                                          selectedProject.projectName,
                                        projectMasterId:
                                          selectedProject.projectMasterId,
                                      });
                                    }}
                                    canAddNew={true}
                                    // canDelete={true}
                                    // deleteClickHandler={(payload) => {
                                    //   this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                                    // }}
                                    onAddNewClick={(payload) => {
                                      payload.formData = {
                                        projectName: payload.projectName,
                                      };
                                      handleNewProjectAddition(
                                        this.props,
                                        payload,
                                        MODAL_TYPE.PROJECT
                                      );
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                            {/* {this.props.registrationType === 'student' ?
                                                            <Form.Item>
                                                                <Row>
                                                                    <Col span={1}>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Tooltip placement="bottom" title={this.props.intl.formatMessage({ id: 'registation.course.helpMessage' })}>
                                                                            <span className="tooltip-title">
                                                                                <FormattedMessage id='schoolManagement.class.course' /><span className="required">*</span>
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col span={13}>
                                                                        <Dropdown
                                                                            items={this.props.courseList[1] || []}
                                                                            placeholder={this.props.intl.formatMessage({ id: 'schoolManagement.class.course' })}
                                                                            valueKeyName='courseName'
                                                                            optionKeyName='courseId'
                                                                            value={this.props.courseId}
                                                                            onSelect={
                                                                                (optionValue) => {
                                                                                    let selectedCourse = ((this.props.courseList[1] || []).find(ind => (ind.courseId === optionValue))) || {};
                                                                                    this.props.updateState({
                                                                                        courseName: selectedCourse.courseName,
                                                                                        courseId: selectedCourse.courseId
                                                                                    });
                                                                                }
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item> : ''
                                                        } */}
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <Tooltip
                                    placement="bottom"
                                    title={this.props.intl.formatMessage({
                                      id: "tooltip.isSendEmail",
                                    })}
                                  >
                                    <span className="tooltip-title">
                                      <FormattedMessage id="registration.isSendEmail" />
                                    </span>
                                  </Tooltip>
                                </Col>
                                <Col span={3}>
                                  <Switch
                                    checked={this.props.isSendEmail === 1}
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        isFormSetting: true,
                                        isSendEmail: checked ? 1 : 0,
                                        emailDetailDrawerVisible: checked
                                          ? 1
                                          : 0,
                                        docName: "registration",
                                        emailContext: "registrationEmail",
                                      });
                                    }}
                                  />
                                </Col>
                                {this.props.emailDetails ? (
                                  <Col span={1}>
                                    {" "}
                                    <Button
                                      icon={<MailOutlined />}
                                      onClick={() => {
                                        this.props.updateState({
                                          isFormSetting: true,
                                          emailDetailDrawerVisible: true,
                                          docName: "registration",
                                          emailContext: "registrationEmail",
                                        });
                                      }}
                                    ></Button>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Form.Item>
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <Tooltip
                                    placement="bottom"
                                    title={this.props.intl.formatMessage({
                                      id: "registration.successMessage.helpMessage",
                                    })}
                                  >
                                    <span className="tooltip-title">
                                      <FormattedMessage id="registration.successMessage" />
                                    </span>
                                  </Tooltip>
                                </Col>
                                <Col span={13}>
                                  {/* <TextArea maxLength={200}
                                                                rows={1}
                                                                style={{ width: '100%', padding: '5px' }}
                                                                placeholder={this.props.intl.formatMessage({ id: 'registration.successMessage.helpMessage' })}
                                                                value={this.props.successMessage || ''}
                                                                onChange={(e) => {
                                                                    this.props.updateState({ successMessage: e.target.value });
                                                                }}
                                                            /> */}
                                  <CKEditor
                                    data={this.props.successMessage}
                                    onInit={(editor) => {
                                      this.editor = editor;
                                    }}
                                    onChange={(event) => {
                                      const data = event.editor.getData();
                                      this.props.updateState({
                                        successMessage: data,
                                      });
                                    }}
                                    config={{
                                      ...CUSTOM_CONFIGURATION_FORM,
                                      wordcount: {},
                                      placeholder: "Enter success message",
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                            {/* <Form.Item>
                                                            <Row>
                                                                <Col span={1}>
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Tooltip placement="bottom" title={this.props.intl.formatMessage({ id: 'allowedUser.helpMessage' })}>
                                                                        <span className="tooltip-title">
                                                                            <FormattedMessage id='allowedUser.text' /><span className="required">*</span>
                                                                        </span>
                                                                    </Tooltip>
                                                                </Col>
                                                                <Col span={13}>

                                                                    <MultipleSelectDropdown
                                                                        items={this.props.allCompanyUsers || []}
                                                                        placeholder={this.props.intl.formatMessage({ id: 'allowedUser.text' })}
                                                                        mode='multiple'
                                                                        onDeSelect={(value) => {
                                                                            let userPermissions = this.props.userPermissions;
                                                                            userPermissions = filter(userPermissions, (relationshipEmployeeId) => {
                                                                                return relationshipEmployeeId !== value;
                                                                            });
                                                                            this.props.updateState({ userPermissions: userPermissions });
                                                                        }}
                                                                        valueKeyName='firstName'
                                                                        lastNameKey='lastName'
                                                                        addressLineKey1='email'
                                                                        optionKeyName='relationshipEmployeeId'
                                                                        selectedValue={this.props.userPermissions || []}
                                                                        onSelect={(relationshipEmployeeId) => {
                                                                            let userPermissions = this.props.userPermissions || [];
                                                                            if (userPermissions.indexOf(relationshipEmployeeId) === -1) {
                                                                                userPermissions.push(relationshipEmployeeId);
                                                                            } else {
                                                                                userPermissions = userPermissions.filter((el) => {
                                                                                    return el !== relationshipEmployeeId
                                                                                });
                                                                            }
                                                                            this.props.updateState({
                                                                                userPermissions
                                                                            });
                                                                        }}

                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Form.Item> */}
                          </TabPane>
                          {/* <TabPane tab='Grade' key="2">
                                                        <Form.Item>
                                                            <Row>
                                                              
                                                                {this.props.registrationType === 'generalpurpose' ?
                                                                    <Col span={9} className="input-currency-control nert">
                                                                        <span className="currency-tag" style={{ marginTop: '3px' }}>
                                                                            <i className="fa fa-dollar"></i>
                                                                        </span>
                                                                        <TextBox
                                                                            style={{ width: '100%', padding: '5px' }}
                                                                            type='number'
                                                                            placeholder={this.props.intl.formatMessage({ id: 'setting.student.registration.feeAmount' })}
                                                                            value={this.props.feeAmount}
                                                                            onChange={(e) => {
                                                                                if (!Number(e.target.value) || Number(e.target.value) >= 0)
                                                                                    this.props.updateState({ feeAmount: e.target.value });
                                                                            }}
                                                                        />

                                                                    </Col> : ''
                                                                }
                                                            </Row>
                                                        </Form.Item>

                                                        {this.props.collectStudentInfo === 1 && <div>

                                                            <Form.Item>
                                                                <Row>
                                                                    <Col span={1}>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Tooltip placement="bottom" title={this.props.intl.formatMessage({ id: 'setting.student.registration.gradeLevelValidation.helpMessage' })}>
                                                                            <span className="tooltip-title">
                                                                                <FormattedMessage id='setting.student.registration.gradeLevelValidation' />
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col span={13}>
                                                                        <Switch
                                                                            checked={(this.props.gradeLevelValidation === 1)}
                                                                            onClick={(checked, e) => {
                                                                                this.props.updateState({ gradeLevelValidation: checked ? 1 : 0 })
                                                                            }} />
                                                                        {this.props.gradeLevelValidation === 1 &&
                                                                            <Row>
                                                                                <Col span={5}> <Switch style={{ float: 'left', margin: '10px 0px 0px 0px' }}
                                                                                    checked={(this.props.isStopRegistrationAfterMaxStudent === 1)}
                                                                                    onClick={(checked, e) => {
                                                                                        this.props.updateState({
                                                                                            isStopRegistrationAfterMaxStudent: checked ? 1 : 0,
                                                                                        })

                                                                                    }} /></Col>
                                                                                <Col span={19} style={{ lineHeight: '20px', padding: '12px 0px 5px', marginBottom: '10px' }}><span ><FormattedMessage id='registration.stopRegistrationAfterMaxStudent.label' /> </span></Col>
                                                                            </Row>
                                                                        }
                                                                    </Col>

                                                                    <Col span={11}>


                                                                    </Col>
                                                                </Row>
                                                                {this.props.gradeLevelValidation === 1 &&
                                                                    <Form.Item>
                                                                        <Row>
                                                                            <Col span={1}>
                                                                            </Col>
                                                                            <Col span={10}>
                                                                                <Tooltip placement="bottom" title={this.props.intl.formatMessage({ id: 'registration.invalidGradeMessage.helpMessage' })}>
                                                                                    <span className="tooltip-title">
                                                                                        <FormattedMessage id='registration.invalidGradeMessage' /><span className="required">*</span>
                                                                                    </span>
                                                                                </Tooltip>
                                                                            </Col>
                                                                            <Col span={13}>
                                                                                <TextArea maxLength={1000}
                                                                                    rows={1}
                                                                                    style={{ width: '100%', padding: '5px' }}
                                                                                    placeholder={this.props.intl.formatMessage({ id: 'registration.invalidGradeMessage.helpMessage' })}
                                                                                    value={this.props.invalidGradeMessage}
                                                                                    onChange={(e) => {
                                                                                        this.props.updateState({ invalidGradeMessage: e.target.value });
                                                                                    }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Form.Item>
                                                                }

                                                                <Row>
                                                                    <Col span={1}>
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Tooltip placement="bottom" title={this.props.intl.formatMessage({ id: 'addEnrolmentToDG.helpMessage' })}>
                                                                            <span className="tooltip-title">
                                                                                <FormattedMessage id='setting.student.registration.addEnrolmentToDG' />
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Col>
                                                                    <Col span={13}>
                                                                        <Switch
                                                                            checked={(this.props.addEnrolmentToDG === 1)}
                                                                            onClick={(checked, e) => {
                                                                                this.props.updateState({ addEnrolmentToDG: checked ? 1 : 0 })
                                                                            }} />
                                                                    </Col>
                                                                </Row>

                                                                {this.props.collectStudentInfo === 1 &&
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={23} style={{ textAlign: 'center' }}>
                                                                            <Row style={{ borderBottom: 'solid 1px #e4e4e4' }}>
                                                                                <Col span={3}>
                                                                                    <b>{this.props.intl.formatMessage({ id: 'grade.text' })}</b>
                                                                                </Col>
                                                                                <Col span={3}>
                                                                                    <b>{this.props.intl.formatMessage({ id: 'display.text' })}?</b>
                                                                                </Col>
                                                                                {this.props.gradeLevelValidation === 1 &&
                                                                                    <Col span={4}>
                                                                                        <b>{this.props.intl.formatMessage({ id: 'setting.student.registration.maxStudent' })}</b>
                                                                                    </Col>}
                                                                                
                                                                                {this.props.addEnrolmentToDG === 1 &&
                                                                                    <Col span={14}>
                                                                                        <b>{this.props.intl.formatMessage({ id: 'dg.text' })}</b>
                                                                                    </Col>}
                                                                            </Row>
                                                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((grade, i) => {
                                                                                return (
                                                                                    <Row key={i}>
                                                                                        <Col span={3}>
                                                                                            <b>{grade}</b>
                                                                                        </Col>
                                                                                        <Col span={3}>
                                                                                            <Checkbox checked={this.props[`idGrade${grade}Display`]} onChange={(e) => {
                                                                                                this.props.updateState({ [`idGrade${grade}Display`]: e.target.checked ? 1 : 0 });
                                                                                            }}>
                                                                                            </Checkbox>
                                                                                        </Col>
                                                                                        {this.props.gradeLevelValidation === 1 &&
                                                                                            <Col span={4} className='studet-box'>
                                                                                                <TextBox
                                                                                                    style={{ width: '100%', padding: '5px' }}
                                                                                                    type='number'
                                                                                                    placeholder={this.props.intl.formatMessage({ id: 'setting.student.registration.maxStudent' })}
                                                                                                    value={this.props[`grade${grade}MaxStudent`]}
                                                                                                    onChange={(e) => {
                                                                                                        if (!Number(e.target.value) || Number(e.target.value) >= 0)
                                                                                                            this.props.updateState({ [`grade${grade}MaxStudent`]: e.target.value });
                                                                                                    }}
                                                                                                />
                                                                                            </Col>}
                                                                                       
                                                                                        {this.props.addEnrolmentToDG === 1 &&

                                                                                            <Col span={14}>

                                                                                                <MultipleSelectDropdown
                                                                                                    items={this.props.dgList || []}
                                                                                                    placeholder={this.props.intl.formatMessage({ id: 'dgNames.text' })}
                                                                                                    mode='multiple'
                                                                                                    onDeSelect={(value) => {
                                                                                                        let distributionGroupIds = this.props[`grade${grade}DGList`];
                                                                                                        distributionGroupIds = filter(distributionGroupIds, (distributionGroupId) => {
                                                                                                            return distributionGroupId !== value;
                                                                                                        });
                                                                                                        this.props.updateState({ [`grade${grade}DGList`]: distributionGroupIds });
                                                                                                    }}
                                                                                                    valueKeyName='dgName'
                                                                                                    optionKeyName='distributionGroupId'
                                                                                                    selectedValue={this.props[`grade${grade}DGList`] || []}
                                                                                                    onSelect={(optId) => {
                                                                                                        let distributionGroupIds = this.props[`grade${grade}DGList`] || [];
                                                                                                        if (distributionGroupIds.indexOf(optId) === -1) {
                                                                                                            distributionGroupIds.push(optId);
                                                                                                        } else {
                                                                                                            distributionGroupIds = distributionGroupIds.filter((el) => {
                                                                                                                return el !== optId
                                                                                                            });
                                                                                                        }
                                                                                                        this.props.updateState({
                                                                                                            [`grade${grade}DGList`]: distributionGroupIds
                                                                                                        });
                                                                                                    }}

                                                                                                />
                                                                                            </Col>}
                                                                                    </Row>)
                                                                            })
                                                                            }

                                                                        </Col>
                                                                    </Row>}
                                                            </Form.Item>
                                                           
                                                        </div>}
                                                    </TabPane> */}
                          <TabPane tab="Fees" key="3">
                            <Form.Item>
                              {/* {this.props.collectOnlinePayment === 1 &&
                                                        <Row>
                                                            <Col span={1}>
                                                            </Col>
                                                            <Col span={10}></Col>
                                                            <Col span={13}>
                                                                <Row>

                                                                    <Col span={14}>
                                                                        <b>{this.props.intl.formatMessage({ id: 'paymentGateway.available' })}</b><span className="required">*</span>
                                                                    </Col>
                                                                    <Col span={9}>
                                                                        {this.props.availablePaymentGateways ?
                                                                            // <Dropdown
                                                                            // items={this.props.availablePaymentGateways || []}
                                                                            // optionKeyName='optionKey'
                                                                            // valueKeyName='optionText'
                                                                            // value={this.props.paymentGateway}
                                                                            // onSelect={
                                                                            //     (optionValue) => {
                                                                            //     }
                                                                            // }>

                                                                            // </Dropdown>
                                                                            "Authorize.Net"
                                                                            : <span style={{ color: '#d81616' }}>{this.props.intl.formatMessage({ id: 'paymentGateway.notavailable' })}</span>}
                                                                    </Col>
                                                                </Row> </Col>
                                                        </Row>} */}
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage id="registration.isGenerateInvoice" />
                                  {/* <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'registration.successMessage.helpMessage' })} trigger="click">
                                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                    </Tooltip> */}
                                </Col>
                                <Col span={12}>
                                  <Switch
                                    checked={this.props.isGenerateInvoice === 1}
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        isGenerateInvoice: checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                              {this.props.isGenerateInvoice === 1 && (
                                <Row>
                                  <Col span={1}></Col>
                                  <Col span={10}></Col>
                                  <Col span={13}>
                                    <Row>
                                      <Col span={14}>
                                        <b>
                                          {this.props.intl.formatMessage({
                                            id: "paymentGateway.available",
                                          })}
                                        </b>
                                        <span className="required">*</span>
                                      </Col>
                                      <Col span={9}>
                                        {this.props.availablePaymentGateways &&
                                        this.props.availablePaymentGateways
                                          .length ? (
                                          <Dropdown
                                            items={
                                              this.props
                                                .availablePaymentGateways || []
                                            }
                                            optionKeyName="alpidePaymentGatewayId"
                                            valueKeyName="userDefinedGatewayName"
                                            value={
                                              (
                                                this.props
                                                  .selectedPaymentGateway || {}
                                              ).userDefinedGatewayName
                                            }
                                            onSelect={(optionValue, option) => {
                                              let selectedPaymentGateway =
                                                find(
                                                  this.props
                                                    .availablePaymentGateways ||
                                                    [],
                                                  {
                                                    alpidePaymentGatewayId:
                                                      option.value,
                                                  }
                                                ) || [];
                                              this.props.updateState({
                                                selectedPaymentGateway:
                                                  selectedPaymentGateway[0],
                                              });
                                            }}
                                          ></Dropdown>
                                        ) : (
                                          <span style={{ color: "#d81616" }}>
                                            {this.props.intl.formatMessage({
                                              id: "paymentGateway.notavailable",
                                            })}
                                          </span>
                                        )}
                                      </Col>
                                    </Row>{" "}
                                  </Col>
                                </Row>
                              )}
                              {this.props.isGenerateInvoice === 1 && (
                                <Row>
                                  <Col span={11}></Col>
                                  <Col span={13}>
                                    {/* <div> <span className='cursor-pointer' onClick={() => { openSetupSubcourseAndFees(this.props); }}>
                                                                            <b> {this.props.intl.formatMessage({ id: 'setupSubcourseAndFees.text' })}</b>
                                                                        </span>
                                                                            {this.props.subcourseAndFeeList && this.props.subcourseAndFeeList.length ? <span className='ml10'>{this.props.subcourseAndFeeList.length} {this.props.subcourseAndFeeList.length > 1 ? 'Subcourses Added' : 'Subcourse Added'}</span> : ''}
                                                                        </div> */}
                                    {/* <div>How do you make the fees payment?</div> */}
                                    <div>
                                      {" "}
                                      <span
                                        className="cursor-pointer"
                                        onClick={() => {
                                          openAddDiscountRule(this.props);
                                        }}
                                      >
                                        <b>
                                          {" "}
                                          {this.props.intl.formatMessage({
                                            id: "discountRule.text",
                                          })}
                                        </b>
                                      </span>
                                      {this.props.discountRuleList &&
                                      this.props.discountRuleList.length ? (
                                        <span className="ml10">
                                          {this.props.discountRuleList.length}{" "}
                                          {this.props.discountRuleList.length >
                                          1
                                            ? "Discount Rules Added"
                                            : "Discount Rule Added"}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {/* <div>How do you make the fees payment?</div> */}
                                    <div>
                                      {/* <Row>
                                                                                <Col span={5}> <Switch style={{ float: 'left', margin: '10px 0px 0px 0px' }}
                                                                                    checked={(this.props.isDisplayYearlyFee === 1)}
                                                                                    onClick={(checked, e) => {
                                                                                        this.props.updateState({
                                                                                            isDisplayYearlyFee: checked ? 1 : 0,
                                                                                        })

                                                                                    }} /></Col>
                                                                                <Col span={19} style={{ lineHeight: '20px', padding: '12px 0px 5px', marginBottom: '10px' }}>
                                                                                    <Tooltip placement="bottom" title={this.props.intl.formatMessage({ id: 'setting.student.registration.displayYearlyFee.helpMessage' })}>
                                                                                        <span className="tooltip-title">
                                                                                            <FormattedMessage id='registration.isDisplayYearlyFee.label' />
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                            </Row> */}
                                      <Row>
                                        <Col span={5}>
                                          <Switch
                                            checked={
                                              this.props
                                                .collectOnlinePayment === 1
                                            }
                                            onClick={(checked, e) => {
                                              this.props.updateState({
                                                collectOnlinePayment: checked
                                                  ? 1
                                                  : 0,
                                              });
                                            }}
                                          />
                                        </Col>
                                        <Col span={19}>
                                          <Tooltip
                                            placement="bottom"
                                            title={this.props.intl.formatMessage(
                                              {
                                                id: "setting.student.registration.collectOnlinePayment.helpMessage",
                                              }
                                            )}
                                          >
                                            <span className="tooltip-title">
                                              <FormattedMessage id="setting.student.registration.collectOnlinePayment" />
                                            </span>
                                          </Tooltip>
                                        </Col>
                                      </Row>
                                      {/* {this.props.collectOnlinePayment === 1 &&
                                                                                <Row>
                                                                                    <Col span={14}>
                                                                                        {this.props.intl.formatMessage({ id: 'paymentLedger.label' })}<span className="required">*</span>
                                                                                    </Col>
                                                                                    <Col span={9}>
                                                                                        <Dropdown
                                                                                            items={this.props.allLedgerAccounts}
                                                                                            valueKeyName='ledgerAccountName'
                                                                                            optionKeyName='chartOfAccountDetailsId'
                                                                                            value={this.props.ledgerAccountIdPayment}
                                                                                            placeholder={this.props.intl.formatMessage({ id: 'paymentLedger.label' })}
                                                                                            onSelect={(optionValue, option) => {
                                                                                                let selectedLedger = find(this.props.allLedgerAccounts || [], { chartOfAccountDetailsId: option.value }) || {};
                                                                                                this.props.updateState({
                                                                                                    selectedLedger: selectedLedger,
                                                                                                    ledgerAccountIdPayment: option.value
                                                                                                });
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            } */}

                                      <Row>
                                        <Col span={5}>
                                          {" "}
                                          <Switch
                                            style={{
                                              float: "left",
                                              margin: "10px 0px 0px 0px",
                                            }}
                                            checked={
                                              this.props
                                                .isGenerateInvoiceOnRegistration ===
                                              1
                                            }
                                            onClick={(checked, e) => {
                                              this.props.updateState({
                                                isGenerateInvoiceOnRegistration:
                                                  checked ? 1 : 0,
                                              });
                                            }}
                                          />
                                        </Col>
                                        <Col
                                          span={19}
                                          style={{
                                            lineHeight: "20px",
                                            padding: "12px 0px 5px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          {/* <span ><FormattedMessage id='registration.generateInvoice.onRegistration' /> </span> */}
                                          <Tooltip
                                            placement="bottom"
                                            title={this.props.intl.formatMessage(
                                              {
                                                id: "setting.student.registration.generateInvoiceOnReg.helpMessage",
                                              }
                                            )}
                                          >
                                            <span className="tooltip-title">
                                              <FormattedMessage id="registration.generateInvoice.onRegistration" />
                                            </span>
                                          </Tooltip>
                                        </Col>
                                      </Row>

                                      {/* <Row>
                                                                                <Col span={5}> <Switch style={{ float: 'left', margin: '10px 0px 0px 0px' }}
                                                                                    checked={(this.props.isAllowParentPaymentPlan === 1)}
                                                                                    onClick={(checked, e) => {
                                                                                        this.props.updateState({
                                                                                            isAllowParentPaymentPlan: checked ? 1 : 0,
                                                                                        })

                                                                                    }} />
                                                                                </Col>
                                                                                <Col span={19}>
                                                                                    <Tooltip placement="bottom" title={this.props.intl.formatMessage({ id: 'setting.student.registration.feesPaymentPlan.helpMessage' })}>
                                                                                        <span className="tooltip-title">
                                                                                            <FormattedMessage id='parent.paymentPlan.text' />
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </Col>
                                                                            </Row> */}
                                    </div>
                                    {this.props.isAllowParentPaymentPlan ? (
                                      <div>
                                        {" "}
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            openAddPaymentPlan(this.props);
                                          }}
                                        >
                                          <b>
                                            {" "}
                                            {this.props.intl.formatMessage({
                                              id: "addPaymentPlan.text",
                                            })}
                                          </b>
                                        </span>
                                        {this.props.paymentPlan &&
                                        this.props.paymentPlan.length ? (
                                          <span className="ml10">
                                            {this.props.paymentPlan.length}{" "}
                                            {this.props.paymentPlan.length > 1
                                              ? "Payment Plans Added"
                                              : "Payment Plan Added"}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      <Radio.Group
                                        onChange={(e) => {
                                          this.props.updateState({
                                            invoiceCycle: e.target.value,
                                          });
                                          // if ('monthly' === e.target.value) {
                                          //     openRecurringSetting(this.props);
                                          // }
                                        }}
                                        value={this.props.invoiceCycle}
                                      >
                                        <Radio value="oneTime" disabled>
                                          {" "}
                                          <FormattedMessage id="registration.generateInvoice.oneTime" />
                                        </Radio>
                                        <Radio
                                          value="monthly"
                                          onClick={() => {
                                            openRecurringSetting(this.props);
                                          }}
                                        >
                                          {" "}
                                          <Tooltip
                                            placement="bottom"
                                            title={this.props.intl.formatMessage(
                                              {
                                                id: "registration.recurringInvoic.helpMessage",
                                              }
                                            )}
                                          >
                                            <span className="tooltip-title">
                                              <span
                                                className={
                                                  this.props
                                                    .recurringInvoiceSettings
                                                    ? "cursor-pointer"
                                                    : ""
                                                }
                                              >
                                                <FormattedMessage id="registration.generateInvoice.monthly" />
                                              </span>
                                            </span>
                                          </Tooltip>
                                        </Radio>
                                      </Radio.Group>
                                    )}
                                    {this.props.isAllowParentPaymentPlan ||
                                    this.props.invoiceCycle === "monthly" ? (
                                      <div>
                                        {" "}
                                        <span
                                          className="cursor-pointer"
                                          onClick={() => {
                                            openEmailTemplates(this.props);
                                          }}
                                        >
                                          <b>
                                            {" "}
                                            {this.props.intl.formatMessage({
                                              id: "registration.recurringInvoiceEmailTemplate.text",
                                            })}
                                          </b>
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <hr className="mt20 " />
                                    <div>
                                      <Tooltip
                                        placement="bottom"
                                        title={this.props.intl.formatMessage({
                                          id: "collectOnetimeCharges.helpMessage",
                                        })}
                                      >
                                        <span className="tooltip-title">
                                          <b
                                            className="cursor-pointer"
                                            onClick={() => {
                                              openRegistrationInvoiceSetting(
                                                this.props
                                              );
                                            }}
                                          >
                                            {this.props.intl.formatMessage({
                                              id: "collectOnetimeCharges.text",
                                            })}
                                          </b>
                                        </span>
                                      </Tooltip>
                                      {this.props.invoiceItem &&
                                      this.props.invoiceItem.length ? (
                                        <span className="ml10">
                                          {this.props.companyInfo
                                            .currencyIcon ? (
                                            <i
                                              className={
                                                this.props.companyInfo
                                                  .currencyIcon
                                              }
                                            ></i>
                                          ) : (
                                            this.props.companyInfo
                                              .currencyCode + " "
                                          )}
                                          {getOneTimeChargesTotalAmount(
                                            this.props
                                          )}{" "}
                                          Onetime charges{" "}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div>
                                      <Tooltip
                                        placement="bottom"
                                        title={this.props.intl.formatMessage({
                                          id: "membershipPaymentReminder.helpMessage",
                                        })}
                                      >
                                        <span className="tooltip-title">
                                          <b
                                            className="cursor-pointer"
                                            onClick={() => {
                                              openAddReminder(this.props);
                                            }}
                                          >
                                            {" "}
                                            {this.props.intl.formatMessage({
                                              id: "membershipPaymentReminder.text",
                                            })}
                                          </b>
                                        </span>
                                      </Tooltip>
                                      {this.props.reminderList &&
                                      this.props.reminderList.length ? (
                                        <span className="ml10">
                                          {this.props.reminderList.length}{" "}
                                          {this.props.reminderList.length > 1
                                            ? "Reminders Added"
                                            : "Reminder Added"}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              )}
                              {this.props.isGenerateInvoice === 1 && (
                                <Row>
                                  <Col span={1}></Col>
                                  <Col span={10}>
                                    <Tooltip
                                      placement="bottom"
                                      title={this.props.intl.formatMessage({
                                        id: "tooltip.invoiceLedger",
                                      })}
                                    >
                                      <span className="tooltip-title">
                                        {this.props.intl.formatMessage({
                                          id: "invoiceLedger.label",
                                        })}
                                        <span className="required">*</span>
                                      </span>
                                    </Tooltip>
                                  </Col>
                                  <Col span={13}>
                                    <Dropdown
                                      items={this.props.allLedgerAccounts}
                                      valueKeyName="ledgerAccountName"
                                      optionKeyName="chartOfAccountDetailsId"
                                      value={this.props.ledgerAccountIdInvoice}
                                      placeholder={this.props.intl.formatMessage(
                                        { id: "invoiceLedger.label" }
                                      )}
                                      onSelect={(optionValue, option) => {
                                        let selectedLedger =
                                          find(
                                            this.props.allLedgerAccounts || [],
                                            {
                                              chartOfAccountDetailsId:
                                                option.value,
                                            }
                                          ) || {};
                                        this.props.updateState({
                                          selectedLedger: selectedLedger,
                                          ledgerAccountIdInvoice: option.value,
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              )}

                              {this.props.isGenerateInvoice === 1 && (
                                <Row>
                                  <Col span={1}></Col>
                                  <Col span={10}>
                                    <Tooltip
                                      placement="bottom"
                                      title={this.props.intl.formatMessage({
                                        id: "invoiceItemDescription.helpMessage",
                                      })}
                                    >
                                      <span className="tooltip-title">
                                        <FormattedMessage id="invoiceItemDescription.text" />
                                        <span className="required">*</span>
                                      </span>
                                    </Tooltip>
                                  </Col>
                                  <Col span={13}>
                                    <TextBox
                                      maxLength={100}
                                      rows={1}
                                      style={{ width: "100%", padding: "5px" }}
                                      placeholder={this.props.intl.formatMessage(
                                        { id: "invoiceItemDescription.text" }
                                      )}
                                      value={this.props.invoiceItemDescription}
                                      onChange={(e) => {
                                        this.props.updateState({
                                          invoiceItemDescription:
                                            e.target.value,
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </Form.Item>
                            {this.props.isGenerateInvoice === 1 && (
                              <Form.Item>
                                {/* <Row>
                                                            <Col span={1}>
                                                            </Col>
                                                            <Col span={10}>
                                                                <FormattedMessage id='invoiceTitle.text' />
                                                            </Col>
                                                            <Col span={13}>
                                                                <TextBox maxLength={40}
                                                                    style={{ width: '100%', padding: '5px' }}
                                                                    placeholder={this.props.intl.formatMessage({ id: 'invoiceTitle.text' })}
                                                                    value={this.props.invoiceTitle}
                                                                    onChange={(e) => {
                                                                        this.props.updateState({ invoiceTitle: e.target.value });
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row> */}
                                <Row>
                                  <Col span={1}></Col>
                                  <Col span={10}>
                                    <FormattedMessage id="invoiceDocumentName.text" />
                                  </Col>
                                  <Col span={13}>
                                    <Dropdown
                                      items={filter(
                                        this.props.documentList || [],
                                        (document) => {
                                          return (
                                            document.txType ===
                                            CONSTANTS_TRANSACTIONS_KEY[
                                              CONSTANTS_TRANSACTIONS
                                                .SALES_INVOICE
                                            ]
                                          );
                                        }
                                      )}
                                      placeholder={this.props.intl.formatMessage(
                                        { id: "invoiceDocumentName.text" }
                                      )}
                                      valueKeyName="docName"
                                      optionKeyName="documentNameId"
                                      value={this.props.documentNameId}
                                      onSelect={(optionValue) => {
                                        let selectedObj =
                                          (this.props.documentList || []).find(
                                            (ind) =>
                                              ind.documentNameId === optionValue
                                          ) || {};
                                        this.props.updateState({
                                          docName: selectedObj.docName,
                                          documentNameId:
                                            selectedObj.documentNameId,
                                        });
                                      }}
                                      canAddNew={true}
                                      canDelete={true}
                                      deleteClickHandler={(payload) => {
                                        deleteClickHandler(
                                          this.props,
                                          MODAL_TYPE.DOCUMENT_NAME,
                                          payload
                                        );
                                      }}
                                      onAddNewClick={(payload) => {
                                        payload.formData = {
                                          textBoxValue: payload.textEntered,
                                          submittedOnce: false,
                                          maxLength: 30,
                                        };
                                        handleNewItemAddition(
                                          this.props,
                                          payload,
                                          MODAL_TYPE.DOCUMENT_NAME
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Form.Item>
                            )}

                            {/* <Form.Item>
                                            <Row>
                                                <Col span={1}>
                                                </Col>
                                                <Col span={10}>
                                                    <FormattedMessage id='primaryContactForInvoicing.text' /><span className="required">*</span>
                                                </Col>
                                                <Col span={13}>
                                                 <Switch
                                                        checked={(this.props.primaryContactForInvoicing === 1)}
                                                        onClick={(checked, e) => {
                                                            this.props.updateState({ primaryContactForInvoicing: checked ? 1 : 0 })
                                                        }} />
                                                </Col>
                                            </Row>
                                        </Form.Item> */}
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <Tooltip
                                    placement="bottom"
                                    title={this.props.intl.formatMessage({
                                      id: "registration.paymentTerm.helpMessage",
                                    })}
                                  >
                                    <span className="tooltip-title">
                                      <FormattedMessage id="drawer.customer.paymentTerm" />
                                      <span className="required">*</span>
                                    </span>
                                  </Tooltip>
                                </Col>
                                <Col span={13}>
                                  <Dropdown
                                    items={this.props.paymentTerms}
                                    placeholder={this.props.intl.formatMessage({
                                      id: "drawer.customer.paymentTerm.placeholder",
                                    })}
                                    valueKeyName="termName"
                                    optionKeyName="paymentTermId"
                                    value={
                                      (this.props.paymentTerm || {}).termName
                                    }
                                    onSelect={(optionValue) => {
                                      let paymentTerm =
                                        this.props.paymentTerms.find(
                                          (ind) =>
                                            ind.paymentTermId === optionValue
                                        ) || {};
                                      this.props.updateState({ paymentTerm });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>

                            {this.props.registrationType === "membership" ? (
                              <Form.Item>
                                <Row>
                                  <Col span={1}></Col>
                                  <Col span={10}>
                                    <FormattedMessage id="sidebar.menuItem.membershipOption" />
                                  </Col>
                                  <Col span={13}>
                                    <Checkbox.Group
                                      value={this.props.membershipOptions}
                                      onChange={(e) => {
                                        this.props.updateState({
                                          membershipOptions: e,
                                        });
                                      }}
                                    >
                                      {(
                                        this.props.membershipOptionList[1] || []
                                      ).map((membershipOption, i) => {
                                        return (
                                          <div>
                                            <Checkbox
                                              value={
                                                membershipOption.membershipOptionId
                                              }
                                            >
                                              {membershipOption.name} -{" "}
                                              {`$${
                                                membershipOption.termAndFees.fee
                                              }/${
                                                membershipOption.termAndFees
                                                  .frequency
                                              } for ${
                                                membershipOption.termAndFees
                                                  .duration
                                              } ${
                                                membershipOption.termAndFees
                                                  .period
                                              }${
                                                membershipOption.termAndFees
                                                  .type
                                                  ? " - " +
                                                    membershipOption.termAndFees
                                                      .type
                                                  : ""
                                              }`}
                                            </Checkbox>
                                          </div>
                                        );
                                      })}
                                    </Checkbox.Group>
                                  </Col>
                                </Row>
                              </Form.Item>
                            ) : (
                              ""
                            )}
                            {this.props.registrationType === "membership" ? (
                              <Form.Item>
                                <Row>
                                  <Col span={1}></Col>
                                  <Col span={10}>
                                    {/* <FormattedMessage id='sidebar.menuItem.membershipOption' /> <FormattedMessage id='required.text' /> */}
                                  </Col>
                                  <Col span={13}>
                                    <Switch
                                      checked={
                                        this.props.membershipOptionRequired ===
                                        1
                                      }
                                      onClick={(checked, e) => {
                                        this.props.updateState({
                                          membershipOptionRequired: checked
                                            ? 1
                                            : 0,
                                        });
                                      }}
                                    />
                                    <span style={{ marginLeft: "10px" }}>
                                      Required
                                    </span>
                                  </Col>
                                </Row>
                              </Form.Item>
                            ) : (
                              ""
                            )}
                          </TabPane>

                          <TabPane tab="Component" key="4">
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <b>Form Item</b>
                                </Col>
                                <Col span={6}>
                                  <b>Show</b>
                                </Col>
                                <Col span={6}>
                                  <b>Required</b>
                                </Col>
                              </Row>
                            </Form.Item>
                            {/* {this.props.registrationType !== 'membership' ?
                                                            <div>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='father.fName' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                // disabled
                                                                                checked={(this.props.fatherFirstNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherFirstNameDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                // disabled
                                                                                checked={(this.props.fatherFirstNameRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherFirstNameRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='father.lName' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.fatherLastNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherLastNameDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.fatherLastNameRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherLastNameRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='father.mobile' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.fatherMobileDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherMobileDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.fatherMobileRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherMobileRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='father.email' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.fatherEmailDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherEmailDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.fatherEmailRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherEmailRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='mother.fName' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherFirstNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherFirstNameDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherFirstNameRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherFirstNameRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='mother.lName' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherLastNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherLastNameDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherLastNameRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherLastNameRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='mother.mobile' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherMobileDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherMobileDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherMobileRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherMobileRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='mother.email' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherEmailDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherEmailDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherEmailRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherEmailRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='fatherDOB.text' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.fatherDOBDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherDOBDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.fatherDOBRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherDOBRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>

                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='motherDOB.text' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherDOBDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherDOBDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.motherDOBRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ motherDOBRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                            </div>
                                                            : ''
                                                        } */}

                            {this.props.registrationType === "membership" ? (
                              <div>
                                <Form.Item>
                                  <Row>
                                    <Col span={1}></Col>
                                    <Col span={10}>
                                      <FormattedMessage
                                        id="membershipType.text"
                                        defaultMessage=""
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <Switch
                                        checked={
                                          this.props.membershipTypeDisplay === 1
                                        }
                                        onClick={(checked, e) => {
                                          this.props.updateState({
                                            membershipTypeDisplay: checked
                                              ? 1
                                              : 0,
                                          });
                                        }}
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <Switch
                                        checked={
                                          this.props.membershipTypeRequired ===
                                          1
                                        }
                                        onClick={(checked, e) => {
                                          this.props.updateState({
                                            membershipTypeRequired: checked
                                              ? 1
                                              : 0,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={1}></Col>
                                    <Col span={10}>Father Info</Col>
                                    <Col span={6}>
                                      {/* <Switch
                                                                                // disabled
                                                                                checked={(this.props.fatherFirstNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherFirstNameDisplay: checked ? 1 : 0 })
                                                                                }} /> */}
                                    </Col>
                                    <Col span={6}>
                                      <Switch
                                        // disabled
                                        checked={
                                          this.props.fatherInfoRequired === 1
                                        }
                                        onClick={(checked, e) => {
                                          this.props.updateState({
                                            fatherInfoRequired: checked ? 1 : 0,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={1}></Col>
                                    <Col span={10}>Mother Info</Col>
                                    <Col span={6}>
                                      {/* <Switch
                                                                                // disabled
                                                                                checked={(this.props.fatherFirstNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherFirstNameDisplay: checked ? 1 : 0 })
                                                                                }} /> */}
                                    </Col>
                                    <Col span={6}>
                                      <Switch
                                        // disabled
                                        checked={
                                          this.props.motherInfoRequired === 1
                                        }
                                        onClick={(checked, e) => {
                                          this.props.updateState({
                                            motherInfoRequired: checked ? 1 : 0,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={1}></Col>
                                    <Col span={10}>Spouse Info</Col>
                                    <Col span={6}>
                                      {/* <Switch
                                                                                // disabled
                                                                                checked={(this.props.fatherFirstNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ fatherFirstNameDisplay: checked ? 1 : 0 })
                                                                                }} /> */}
                                    </Col>
                                    <Col span={6}>
                                      <Switch
                                        // disabled
                                        checked={
                                          this.props.spouseInfoRequired === 1
                                        }
                                        onClick={(checked, e) => {
                                          this.props.updateState({
                                            spouseInfoRequired: checked ? 1 : 0,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Form.Item>
                                {/* <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='contact.firstName' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                disabled
                                                                                checked={(this.props.firstNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ firstNameDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                disabled
                                                                                checked={(this.props.firstNameRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ firstNameRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='contact.lastName' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                disabled
                                                                                checked={(this.props.lastNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ lastNameDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                disabled
                                                                                checked={(this.props.lastNameRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ lastNameRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='company.employee.phone' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.phoneDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ phoneDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.phoneRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ phoneRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>

                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='gender.text' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.genderDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ genderDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.genderRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ genderRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>

                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='dob.text' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.dobDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ dobDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.dobRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ dobRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>

                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='email' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.emailDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ emailDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.emailRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ emailRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>

                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='maritalStatus.text' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.maritalStatusDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ maritalStatusDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.maritalStatusRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ maritalStatusRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='spouse.fName' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseFirstNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseFirstNameDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseFirstNameRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseFirstNameRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='spouse.lName' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseLastNameDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseLastNameDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseLastNameRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseLastNameRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='spouse.mobile' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseMobileDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseMobileDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseMobileRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseMobileRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='spouse.email' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseEmailDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseEmailDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseEmailRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseEmailRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>

                                                                <Form.Item>
                                                                    <Row>
                                                                        <Col span={1}>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <FormattedMessage id='spouseDOB.text' defaultMessage='' />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseDOBDisplay === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseDOBDisplay: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                        <Col span={6}>
                                                                            <Switch
                                                                                checked={(this.props.spouseDOBRequired === 1)}
                                                                                onClick={(checked, e) => {
                                                                                    this.props.updateState({ spouseDOBRequired: checked ? 1 : 0 })
                                                                                }} />
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item> */}
                              </div>
                            ) : (
                              ""
                            )}

                            {this.props.registrationType === "student" ? (
                              <div>
                                <Form.Item>
                                  <Row>
                                    <Col span={1}></Col>
                                    <Col span={10}>
                                      <FormattedMessage
                                        id="familyDoctorName.text"
                                        defaultMessage=""
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <Switch
                                        checked={
                                          this.props.familyDoctorNameDisplay ===
                                          1
                                        }
                                        onClick={(checked, e) => {
                                          this.props.updateState({
                                            familyDoctorNameDisplay: checked
                                              ? 1
                                              : 0,
                                          });
                                        }}
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <Switch
                                        checked={
                                          this.props
                                            .familyDoctorNameRequired === 1
                                        }
                                        onClick={(checked, e) => {
                                          this.props.updateState({
                                            familyDoctorNameRequired: checked
                                              ? 1
                                              : 0,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Form.Item>
                                <Form.Item>
                                  <Row>
                                    <Col span={1}></Col>
                                    <Col span={10}>
                                      <FormattedMessage
                                        id="familyDoctorPhone.text"
                                        defaultMessage=""
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <Switch
                                        checked={
                                          this.props
                                            .familyDoctorPhoneDisplay === 1
                                        }
                                        onClick={(checked, e) => {
                                          this.props.updateState({
                                            familyDoctorPhoneDisplay: checked
                                              ? 1
                                              : 0,
                                          });
                                        }}
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <Switch
                                        checked={
                                          this.props
                                            .familyDoctorPhoneRequired === 1
                                        }
                                        onClick={(checked, e) => {
                                          this.props.updateState({
                                            familyDoctorPhoneRequired: checked
                                              ? 1
                                              : 0,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                </Form.Item>
                              </div>
                            ) : (
                              ""
                            )}

                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage
                                    id="setting.student.registration.form.emergencyContactName"
                                    defaultMessage=""
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={
                                      this.props.emergencyContactDisplay === 1
                                    }
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        emergencyContactDisplay: checked
                                          ? 1
                                          : 0,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={
                                      this.props.emergencyContactRequired === 1
                                    }
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        emergencyContactRequired: checked
                                          ? 1
                                          : 0,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage
                                    id="setting.student.registration.form.emergencyPhone"
                                    defaultMessage=""
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={
                                      this.props.emergencyPhoneDisplay === 1
                                    }
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        emergencyPhoneDisplay: checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={
                                      this.props.emergencyPhoneRequired === 1
                                    }
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        emergencyPhoneRequired: checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage
                                    id="educationHistory.text"
                                    defaultMessage=""
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={this.props.educationHistory === 1}
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        educationHistory: checked ? 1 : 0,
                                        educationHistoryRequired: checked
                                          ? this.props.educationHistoryRequired
                                          : 0,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={
                                      this.props.educationHistoryRequired === 1
                                    }
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        educationHistoryRequired: checked
                                          ? 1
                                          : 0,
                                        educationHistory: checked
                                          ? 1
                                          : this.props.educationHistory,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage
                                    id="employmentHistory.text"
                                    defaultMessage=""
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={
                                      this.props.employmentHistoryDisplay === 1
                                    }
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        employmentHistoryDisplay: checked
                                          ? 1
                                          : 0,
                                        employmentHistoryRequired: checked
                                          ? this.props.employmentHistoryRequired
                                          : 0,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={
                                      this.props.employmentHistoryRequired === 1
                                    }
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        employmentHistoryRequired: checked
                                          ? 1
                                          : 0,
                                        employmentHistoryDisplay: checked
                                          ? 1
                                          : this.props.employmentHistoryDisplay,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>

                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage id="signup.tab.address" />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={this.props.addressDisplay === 1}
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        addressDisplay: checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={this.props.addressRequired === 1}
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        addressRequired: checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>

                              {/* <Row>
                                                                <Col span={1}></Col>
                                                                <Col span={10}>
                                                                    Organization Member
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Switch
                                                                        checked={(this.props.askOrgMember === 1)}
                                                                        onClick={(checked, e) => {
                                                                            this.props.updateState({ askOrgMember: checked ? 1 : 0 })
                                                                        }} />

                                                                </Col>
                                                                <Col span={6}>
                                                                    <Switch
                                                                        checked={(this.props.askOrgMemberRequired === 1)}
                                                                        onClick={(checked, e) => {
                                                                            this.props.updateState({ askOrgMemberRequired: checked ? 1 : 0 })
                                                                        }} />
                                                                </Col>
                                                            </Row> */}
                              {/* <Row>
                                                                <Col span={1}>
                                                                </Col>
                                                                <Col span={10}>
                                                                    Finanacial Assistance
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Switch
                                                                        checked={(this.props.askFinanacialAssistance === 1)}
                                                                        onClick={(checked, e) => {
                                                                            this.props.updateState({ askFinanacialAssistance: checked ? 1 : 0 })
                                                                        }} />
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Switch
                                                                        checked={(this.props.askFinanacialAssistanceRequired === 1)}
                                                                        onClick={(checked, e) => {
                                                                            this.props.updateState({ askFinanacialAssistanceRequired: checked ? 1 : 0 })
                                                                        }} />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={1}></Col>
                                                                <Col span={10}>
                                                                    Add Teacher
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Switch
                                                                        checked={(this.props.askAddTeacher === 1)}
                                                                        onClick={(checked, e) => {
                                                                            this.props.updateState({ askAddTeacher: checked ? 1 : 0 })
                                                                        }} />

                                                                </Col>
                                                                <Col span={6}>
                                                                    <Switch
                                                                        checked={(this.props.askAddTeacherRequired === 1)}
                                                                        onClick={(checked, e) => {
                                                                            this.props.updateState({ askAddTeacherRequired: checked ? 1 : 0 })
                                                                        }} />
                                                                </Col>
                                                            </Row> */}
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>Add Volunteer</Col>
                                <Col span={6}>
                                  <Switch
                                    checked={this.props.askAddVolunteer === 1}
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        askAddVolunteer: checked ? 1 : 0,
                                      });
                                    }}
                                  />
                                </Col>
                                <Col span={6}>
                                  <Switch
                                    checked={
                                      this.props.askAddVolunteerRequired === 1
                                    }
                                    onClick={(checked, e) => {
                                      this.props.updateState({
                                        askAddVolunteerRequired: checked
                                          ? 1
                                          : 0,
                                      });
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Form.Item>
                            {this.props.registrationType === "membership" ? (
                              <Form.Item>
                                <Row>
                                  <Col span={1}></Col>
                                  <Col span={10}>
                                    <FormattedMessage
                                      id="nationality.text"
                                      defaultMessage=""
                                    />
                                  </Col>
                                  <Col span={6}>
                                    <Switch
                                      checked={
                                        this.props.nationalityDisplay === 1
                                      }
                                      onClick={(checked, e) => {
                                        this.props.updateState({
                                          nationalityDisplay: checked ? 1 : 0,
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col span={6}>
                                    <Switch
                                      checked={
                                        this.props.nationalityRequired === 1
                                      }
                                      onClick={(checked, e) => {
                                        this.props.updateState({
                                          nationalityRequired: checked ? 1 : 0,
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col span={1}></Col>
                                  <Col span={10}>
                                    <FormattedMessage
                                      id="countryOfBirth.text"
                                      defaultMessage=""
                                    />
                                  </Col>
                                  <Col span={6}>
                                    <Switch
                                      checked={
                                        this.props.countryOfBirthDisplay === 1
                                      }
                                      onClick={(checked, e) => {
                                        this.props.updateState({
                                          countryOfBirthDisplay: checked
                                            ? 1
                                            : 0,
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col span={6}>
                                    <Switch
                                      checked={
                                        this.props.countryOfBirthRequired === 1
                                      }
                                      onClick={(checked, e) => {
                                        this.props.updateState({
                                          countryOfBirthRequired: checked
                                            ? 1
                                            : 0,
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>

                                <Row>
                                  <Col span={1}></Col>
                                  <Col span={10}>
                                    <FormattedMessage
                                      id="countryOfResidence.text"
                                      defaultMessage=""
                                    />
                                  </Col>
                                  <Col span={6}>
                                    <Switch
                                      checked={
                                        this.props.countryOfResidenceDisplay ===
                                        1
                                      }
                                      onClick={(checked, e) => {
                                        this.props.updateState({
                                          countryOfResidenceDisplay: checked
                                            ? 1
                                            : 0,
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col span={6}>
                                    <Switch
                                      checked={
                                        this.props
                                          .countryOfResidenceRequired === 1
                                      }
                                      onClick={(checked, e) => {
                                        this.props.updateState({
                                          countryOfResidenceRequired: checked
                                            ? 1
                                            : 0,
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Form.Item>
                            ) : (
                              ""
                            )}

                            <Form.Item>
                              <Row>
                                <Col span={1}></Col>
                                <Col span={10}>
                                  <FormattedMessage id="footerMessage.text" />
                                </Col>
                                <Col span={13}>
                                  <CKEditor
                                    data={this.props.footerMessage}
                                    onInit={(editor) => {
                                      this.editor = editor;
                                    }}
                                    onChange={(event) => {
                                      const data = event.editor.getData();
                                      this.props.updateState({
                                        footerMessage: data,
                                      });
                                    }}
                                    config={{
                                      ...CUSTOM_CONFIGURATION_FORM,
                                      wordcount: {},
                                      placeholder: "Enter form description",
                                    }}
                                  />

                                  {/* <TextArea maxLength={1000}
                                                        rows={1}
                                                        style={{ width: '100%', padding: '5px' }}
                                                        placeholder={this.props.intl.formatMessage({ id: 'footerMessage.text' })}
                                                        value={this.props.footerMessage}
                                                        onChange={(e) => {
                                                            this.props.updateState({ footerMessage: e.target.value });
                                                        }}
                                                    /> */}
                                </Col>
                              </Row>
                            </Form.Item>
                          </TabPane>

                          <TabPane tab="Q&A" key="5">
                            <div
                              className="ag-theme-balham"
                              style={agGridStyle}
                            >
                              {/* <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={(this.props.questionList || [])}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            getRowHeight={getRowHeight}
                            editType={'fullRow'}
                            onGridReady={onGridReady}
                        >
                        </CustomAgGrid> */}

                              {(this.props.questionList || []).map(
                                (queObj, i) => {
                                  return (
                                    <div
                                      key={"ques" + i}
                                      style={{
                                        marginBottom: "10px",
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        padding: "10px",
                                      }}
                                    >
                                      <Row style={{ marginBottom: "10px" }}>
                                        <Col span={5}>
                                          <b>Question {i + 1}</b>
                                        </Col>
                                        <Col span={17}>
                                          <TextArea
                                            maxLength={500}
                                            style={{
                                              width: "100%",
                                              padding: "5px",
                                            }}
                                            rows={1}
                                            placeholder={this.props.intl.formatMessage(
                                              { id: "questionText.placeholder" }
                                            )}
                                            value={queObj.questionText}
                                            onChange={(e) => {
                                              queObj.questionText =
                                                e.target.value;
                                              let questionList =
                                                this.props.questionList;
                                              questionList.splice(i, 1, queObj);
                                              this.props.updateState({
                                                questionList,
                                              });
                                            }}
                                          />
                                        </Col>
                                        <Col span={2}>
                                          <span
                                            style={{
                                              fontSize: "20px",
                                              marginLeft: "10px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              removeQuestion(this.props, i);
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              title={this.props.intl.formatMessage(
                                                { id: "deleteQuestion.text" }
                                              )}
                                            />{" "}
                                          </span>
                                        </Col>
                                      </Row>
                                      <Row style={{ marginBottom: "10px" }}>
                                        <Col span={5}>
                                          <b>
                                            <FormattedMessage id="answerType.text" />{" "}
                                          </b>
                                        </Col>
                                        <Col span={8}>
                                          <Dropdown
                                            items={[
                                              {
                                                optionText: "Single Choice",
                                                optionKey: "singleChoice",
                                              },
                                              {
                                                optionText: "Multiple Choice",
                                                optionKey: "multiChoice",
                                              },
                                              {
                                                optionText: "Short Answer",
                                                optionKey: "shortAnswer",
                                              },
                                            ]}
                                            optionKeyName="optionKey"
                                            valueKeyName="optionText"
                                            value={queObj.answerType}
                                            onSelect={(optionValue) => {
                                              queObj.answerType = optionValue;
                                              if (
                                                optionValue === "shortAnswer"
                                              ) {
                                                queObj.optionList = [];
                                              }
                                              let questionList =
                                                this.props.questionList;
                                              questionList.splice(i, 1, queObj);
                                              this.props.updateState({
                                                questionList,
                                              });
                                            }}
                                          />
                                        </Col>
                                        <Col span={1}></Col>
                                        <Col span={3}>
                                          <FormattedMessage id="required.text" />
                                        </Col>
                                        <Col span={3}>
                                          <Switch
                                            checked={queObj.isRequired === 1}
                                            onClick={(checked, e) => {
                                              queObj.isRequired = checked
                                                ? 1
                                                : 0;
                                              let questionList =
                                                this.props.questionList;
                                              questionList.splice(i, 1, queObj);
                                              this.props.updateState({
                                                questionList,
                                              });
                                            }}
                                          />
                                        </Col>
                                        <div className="clearfix"></div>
                                      </Row>

                                      {(queObj.optionList || []).map(
                                        (optObj, j) => {
                                          return (
                                            <div
                                              key={"que" + j}
                                              style={{
                                                marginBottom: "10px",
                                                marginLeft: "0px",
                                              }}
                                            >
                                              <Row
                                                style={{ marginBottom: "10px" }}
                                              >
                                                <Col span={5}>
                                                  <b>Answer {j + 1}</b>
                                                </Col>
                                                <Col span={17}>
                                                  <TextArea
                                                    maxLength={2000}
                                                    style={{
                                                      width: "100%",
                                                      padding: "5px",
                                                    }}
                                                    rows={1}
                                                    placeholder={this.props.intl.formatMessage(
                                                      {
                                                        id: "optionText.placeholder",
                                                      }
                                                    )}
                                                    value={optObj.optText}
                                                    onChange={(e) => {
                                                      optObj.optText =
                                                        e.target.value;
                                                      let optionList =
                                                        queObj.optionList;
                                                      optionList.splice(
                                                        j,
                                                        1,
                                                        optObj
                                                      );
                                                      queObj.optionList =
                                                        optionList;
                                                      let questionList =
                                                        this.props.questionList;
                                                      questionList.splice(
                                                        i,
                                                        1,
                                                        queObj
                                                      );
                                                      this.props.updateState({
                                                        questionList,
                                                      });
                                                    }}
                                                  />
                                                </Col>
                                              </Row>
                                            </div>
                                          );
                                        }
                                      )}
                                      {queObj.answerType !== "shortAnswer" ? (
                                        <Row>
                                          <Col span={5}></Col>
                                          <Col span={5}>
                                            <Button
                                              title={this.props.intl.formatMessage(
                                                { id: "addAnswer.text" }
                                              )}
                                              className="add-new-record ant-btn ant-btn-default"
                                              style={{ float: "right" }}
                                              onClick={() => {
                                                let optionList =
                                                  queObj.optionList;
                                                optionList.push({});
                                                queObj.optionList = optionList;
                                                let questionList =
                                                  this.props.questionList;
                                                questionList.splice(
                                                  i,
                                                  1,
                                                  queObj
                                                );
                                                this.props.updateState({
                                                  questionList,
                                                });
                                              }}
                                            >
                                              <FormattedMessage id="addAnswer.text" />
                                            </Button>
                                          </Col>
                                          <Col span={8}>
                                            <Button
                                              title={this.props.intl.formatMessage(
                                                { id: "removeAnswer.text" }
                                              )}
                                              className="add-new-record ant-btn ant-btn-default"
                                              style={{ float: "right" }}
                                              onClick={() => {
                                                let optionList =
                                                  queObj.optionList;
                                                optionList.splice(
                                                  optionList.length - 1,
                                                  1
                                                );
                                                queObj.optionList = optionList;
                                                let questionList =
                                                  this.props.questionList;
                                                questionList.splice(
                                                  i,
                                                  1,
                                                  queObj
                                                );
                                                this.props.updateState({
                                                  questionList,
                                                });
                                              }}
                                            >
                                              <FormattedMessage id="removeAnswer.text" />
                                            </Button>
                                          </Col>
                                          <div className="clearfix"></div>
                                        </Row>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                }
                              )}

                              <Button
                                className="add-new-record ant-btn ant-btn-default"
                                onClick={() => {
                                  // this.gridApi.stopEditing();
                                  // this.gridApi.updateRowData({ add: [{ }] });
                                  // this.gridApi.refreshCells({ force: true });
                                  let questionList = this.props.questionList;
                                  questionList.push({ optionList: [{}, {}] });
                                  this.props.updateState({ questionList });
                                }}
                              >
                                <FormattedMessage id="addQuestion.text" />{" "}
                              </Button>
                            </div>
                          </TabPane>
                        </Tabs>
                      </div>
                      <div className="button-group-action">
                        <div className="align-footer">
                          <Button
                            onClick={() => {
                              const modalData = {
                                modalBody: this.props.intl.formatMessage({
                                  id: "close.confirmation",
                                }),
                                footer: {
                                  cancel: { intlId: "no" },
                                  submit: { intlId: "yes" },
                                },
                                handleSubmit: () => {
                                  this.props.popModalFromStack();
                                },
                              };
                              this.props.pushModalToStack(modalData);
                            }}
                            type="default"
                          >
                            <FormattedMessage
                              id="button.close.label"
                              defaultMessage=""
                            />
                          </Button>
                          {/* {this.props.registrationFormSettingId ? <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        previewForm(this.props);
                                                    }}
                                                    style={{ marginRight: '-270px' }}
                                                >
                                                    <FormattedMessage id='preview.text' defaultMessage='' />
                                                </Button> : null} */}
                          <Button
                            type="primary"
                            onClick={() => {
                              saveSettings(this.props);
                            }}
                          >
                            <FormattedMessage
                              id="button.save.label"
                              defaultMessage=""
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={13} className="respo-box">
                    <div className="account-integsmrs newheights">
                      <div className="height580  ">
                        {/* <h2 style={{textAlign:'center'}}><FormattedMessage id='preview.text' /></h2> */}
                        <RegistrationFormPreviewComponent
                          {...this.props}
                          formSetting={getFormSetting(this.props)}
                          registrationFormSettingId={
                            this.props.registrationFormSettingId
                          }
                          relationshipId={
                            (this.props.companyInfo || {}).relationshipId
                          }
                          modalType="preview"
                        />
                      </div>
                      <div className="button-group-action">
                        <div className="align-footer ">
                          <Row style={{ width: "100%" }}>
                            <Col span={24}>
                              <div style={{ float: "left" }}>
                                <span
                                  className="mt-hover"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    tabPreviewPopup(this.props);
                                  }}
                                >
                                  <img
                                    src={TableLogo}
                                    alt="Tablet Preview"
                                    title="Tablet Preview"
                                  />
                                </span>
                                <span
                                  className="mt-hover"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    mobilePreviewPopup(this.props);
                                  }}
                                >
                                  <img
                                    src={MobleLogo}
                                    alt="Mobile Preview"
                                    title="Mobile Preview"
                                  />
                                </span>{" "}
                              </div>
                              {/* <button type="button" className="register-buts" style={{ float: 'right' }} onClick={() => {
                                                                const modalData = {
                                                                    // title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                                                                    modalBody: <NewsLetterShare {...this.props} style={{ overflow: 'auto' }} isRegistrationForm={true} />,
                                                                    hideFooter: true,
                                                                    maskClosable: true,
                                                                    hideCloseIcon: true,
                                                                    hideTitle: true,
                                                                    wrapClassName: 'pay-invoice-modals',
                                                                    width: 600,
                                                                };
                                                                this.props.pushModalToStack(modalData);
                                                            }}>Share <i className="fa fa-share-alt"></i></button> */}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(RegistrationFormSettingComponent);
