import axios from "axios";
import config from "../../../config/environmentConfig";
import { COMMUNICATION_ACTION_LIST,COMMON_ACTIONS } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const getAllFormsBuilder = (payload) => {
    return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });

        return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/formBuilder/getFormsByTemplateType?relationshipId=${payload.relationshipId}&templateType=${payload.templateType}`)
            .then((res) => {
                dispatch({
                    type: COMMUNICATION_ACTION_LIST.NEWSLETTER_TEMPLATE_LIST,
                    data: res.data,
                });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({
                        messageType: "error",
                        description:
                            lang[((err.response || {}).data || {}).message] ||
                            "Some error occurred",
                    });
                }
            }).finally(() => {
                dispatch({
                  type: COMMON_ACTIONS.HIDE_LIST_LOADER,
                });
              })
    };
};

export const deleteFormBuilder = (payload, props) => {
    return dispatch => {
        return axios.post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/formBuilder/delete`, payload)
            .then(res => {
                const payload = {
                    relationshipId: props.companyInfo.relationshipId,
                    templateType:props.templateType
                  }
                props.getAllFormsBuilder && props.getAllFormsBuilder(payload);
                showToasterMessage({ messageType: 'success', description: 'template successfully deleted' });
            })
            .catch((err) => {
                if (!err.__isRetryRequest) {
                    showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
                } else {
                    // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
                }
            })
    }
}



