import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import LogoShape from '../../assets/images/favicon.ico';

export class LogoComponent extends React.Component {
    constructor(props){
        super(props);
        this.state={
            src: props.src
        }
    }
    shouldComponentUpdate(nextProps, nextState){
        return this.state.src !== this.props.src; 
     }

    render() {
        return (
            <Avatar
                shape={this.props.shape || "square"}
                size={64}
                style={this.props.style}
                icon={
                    this.props.islogoImage ? (
                    <img src={LogoShape} alt="" />
                    ) : (
                    <UserOutlined />
                    )
                }
                src={this.state.src}
            />
        );
    }
} 