import axios from "axios";
import config from "../../../config/environmentConfig";
import { showToasterMessage } from "../../../utils";
import { INTEGRATION } from "../../../static/constants";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");


export const saveBrevo = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextBrevo}/api/save`,payload)
      .then((res) => {
        showToasterMessage({
            description: (res || {}).message || 'Created successfully.',
            messageType: 'success'
          })
          const payload2 = {
            relationshipId: payload.relationshipId, 
          
          }
        props.fetchAllSettings(payload2)
    
      })
      .catch((err) => {
        showToasterMessage({
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};

export const fetchAllSettings = (payload) => {
  return dispatch => {
    return axios.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextBrevo}/api/getAllSetting?relationshipId=${payload.relationshipId}`)

      .then(response => {
     
        dispatch({ type: INTEGRATION.BREVO_SETTINGS, data:response.data });

      })
      .catch(err => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      
      })
  }
}