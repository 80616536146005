import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RFQSplitDetailsComponent from '../../../../../components/modal/modalBody/supplier/RFQSplitDetail';
import { fetchSupplierRFQDetail, approveRFQ } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchAllRFQ, getRFQCount } from '../../../../supplier/rfq/Listing/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';

class RFQSplitDetail extends Component {
    constructor(props){
        super();
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            templateName: 'Professional'
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, supplierId: this.props.rfqPayload.supplierId, rfqMasterId: this.props.rfqPayload.rfqMasterId };
        fetchDataIfNeeded('fetchSupplierRFQDetail', 'rfqData', this.props, payload, true);
    }

    render() {
        return <>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <RFQSplitDetailsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        rfqData: state.rfq.rfqDetail,
        userInfo: state.common.userInfo,
        detailLoading: state.common.detailLoading,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSupplierRFQDetail,
    generatePDF, printPDF,
    approveRFQ,
    fetchAllRFQ,
    getRFQCount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RFQSplitDetail);