import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input,  Slider, Collapse, Checkbox } from 'antd';
import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
// import FaceBookWhite from '../../../assets/images/social-media/facebook-white.png';
// import TwittekWhite from '../../../assets/images/social-media/twitter-white.png';
// import InstaWhite from '../../../assets/images/social-media/insta-white.png';
// import LinkedWhite from '../../../assets/images/social-media/linked-white.png';
// import PintWhite from '../../../assets/images/social-media/print-white.png';
// import GoogleWhite from '../../../assets/images/social-media/google-plus-white.png';
// import TimbWhite from '../../../assets/images/social-media/timber-white.png';
// import VimWhite from '../../../assets/images/social-media/vim-white.png';
// import YouTubeWhite from '../../../assets/images/social-media/youtube-white.png';

// import FaceBookBlack from '../../../assets/images/social-media/facebook-black.png';
// import TwittekBlack from '../../../assets/images/social-media/twitter-black.png';
// import InstaBlack from '../../../assets/images/social-media/insta-black.png';
// import LinkedBlack from '../../../assets/images/social-media/linked-black.png';
// import PintBlack from '../../../assets/images/social-media/print-black.png';
// import GoogleBlack from '../../../assets/images/social-media/google-plus-black.png';
// import TimbBlack from '../../../assets/images/social-media/timber-black.png';
// import VimBlack from '../../../assets/images/social-media/vim-black.png';
// import YouTubeBlack from '../../../assets/images/social-media/youtube-black.png';

const FaceBookWhite = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/facebook-white.png';
const TwittekWhite = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/twitter-white.png';
const InstaWhite = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/insta-white.png';
const LinkedWhite = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/linked-white.png';
const PintWhite = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/print-white.png';
const GoogleWhite = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/google-plus-white.png';
const TimbWhite = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/timber-white.png';
const VimWhite = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/vim-white.png';
const YouTubeWhite = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/youtube-white.png';

const FaceBookBlack = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/facebook-black.png';
const TwittekBlack = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/twitter-black.png';
const InstaBlack = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/insta-black.png';
const LinkedBlack = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/linked-black.png';
const PintBlack = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/print-black.png';
const GoogleBlack = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/google-plus-black.png';
const TimbBlack = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/timber-black.png';
const VimBlack = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/vim-black.png';
const YouTubeBlack = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/youtube-black.png';

const FaceBookIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/facebook.png';
const GooglePlusIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/google-plus.png';
const InstagramIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/instagram.png';
const LinkedinIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/linkedin.png';
const PinterestIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/pinterest.png';
const TumblrIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/tumblr.png';
const TwitterIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/twitter.png';
const VimeoIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/vimeo.png';
const YouTubeIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/youtube.png';

const { Panel } = Collapse;



function SocialLinksProps(props) {
    
    let facebookRef = document.getElementById('facebook_'+props.childId) || {};
    let twitterRef = document.getElementById('twitter_'+props.childId) || {};
    let instagramkRef = document.getElementById('instagram_'+props.childId) || {};
    let googlePlusRef = document.getElementById('google-plus_'+props.childId) || {};
    let linkedinRef = document.getElementById('linkedin_'+props.childId) || {};
    let pinterestRef = document.getElementById('pinterest_'+props.childId) || {};
    let tumblrRef = document.getElementById('tumblr_'+props.childId) || {};
    let vimeoRef = document.getElementById('vimeo_'+props.childId) || {};
    let youtubeRef = document.getElementById('youtube_'+props.childId) || {};

    const [facebookHref, updateFacebookHref] = useState(facebookRef.href !== window.location.href+'#' ? facebookRef.href : '');
    const [twitterHref, updateTwitterHref] = useState(twitterRef.href !== window.location.href+'#' ? twitterRef.href : '');
    const [instagramHref, updateInstagramHref] = useState(instagramkRef.href !== window.location.href+'#' ? instagramkRef.href : '');
    const [googlePlusHref, updateGooglePlusHref] = useState(googlePlusRef.href !== window.location.href+'#' ? googlePlusRef.href : '');
    const [linkedinHref, updateLinkedinHref] = useState(linkedinRef.href !== window.location.href+'#' ? linkedinRef.href : '');
    const [pinterestHref, updatePinterestHref] = useState(pinterestRef.href !== window.location.href+'#' ? pinterestRef.href : '');
    const [tumblrHref, updateTumblrHref] = useState(tumblrRef.href !== window.location.href+'#' ? tumblrRef.href : '');
    const [vimeoHref, updateVimeoHref] = useState(vimeoRef.href !== window.location.href+'#' ? vimeoRef.href : '');
    const [youtubeHref, updateYoutubeHref] = useState(youtubeRef.href !== window.location.href+'#' ? youtubeRef.href : '');
    const [iconHeight, updateIconHeight] = useState(facebookRef.children[0].offsetHeight);
    const [iconBackHeight, updateIconBackHeight] = useState(facebookRef.offsetHeight);


    function callback(key) {
    }
    // function ButtonProps(props) {
    //     let buttonRef = document.getElementById("button_"+props.childId) || {};
    //     const [buttonWidth, updateWidth] = useState(buttonRef.offsetWidth);
    //     const [buttonHref, updateHref] = useState(buttonRef.href);}
    

    return (
        <Fragment>
            <Collapse defaultActiveKey={['1']} accordion onChange={callback}>
                
                <Panel header="Icon Styling" key="1">
                    <Row className="but-buttons">
                        <Col span={8} style={{lineHeight:'40px'}}>Icon Color</Col>
                        <Col span={16} style={{ background: '#fff' }}>
                            <button style={{ background: 'white', cursor:'pointer', width: '48%', height:'40px', border:'1px solid #666', borderRadius:'6px' }} onClick={() => {
                                facebookRef.children[0].src= FaceBookWhite;
                                twitterRef.children[0].src= TwittekWhite;
                                instagramkRef.children[0].src= InstaWhite;
                                googlePlusRef.children[0].src= GoogleWhite;
                                linkedinRef.children[0].src= LinkedWhite;
                                pinterestRef.children[0].src= PintWhite;
                                tumblrRef.children[0].src= TimbWhite;
                                vimeoRef.children[0].src= VimWhite;
                                youtubeRef.children[0].src= YouTubeWhite;
                            }}>White</button>
                            <button style={{ background: 'black', cursor:'pointer',  width: '48%', marginLeft: '4%', color:'white',height:'40px', border:'0px', borderRadius:'6px' }} onClick={() => {
                                facebookRef.children[0].src= FaceBookBlack;
                                twitterRef.children[0].src= TwittekBlack;
                                instagramkRef.children[0].src= InstaBlack;
                                googlePlusRef.children[0].src= GoogleBlack;
                                linkedinRef.children[0].src= LinkedBlack;
                                pinterestRef.children[0].src= PintBlack;
                                tumblrRef.children[0].src= TimbBlack;
                                vimeoRef.children[0].src= VimBlack;
                                youtubeRef.children[0].src= YouTubeBlack;
                            }}>Black</button>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                        <Col span={8}></Col>
                        <Col span={16}>
                            <button style={{ background: '#D3D3D3', color:'#000', cursor: 'pointer', width: '100%', marginRight: '10px', height: '40px', border: '1px solid #666', borderRadius: '6px' }} onClick={() => {
                                facebookRef.children[0].src = FaceBookIcon;
                                twitterRef.children[0].src = TwitterIcon;
                                instagramkRef.children[0].src = InstagramIcon;
                                googlePlusRef.children[0].src = GooglePlusIcon;
                                linkedinRef.children[0].src = LinkedinIcon;
                                pinterestRef.children[0].src = PinterestIcon;
                                tumblrRef.children[0].src = TumblrIcon;
                                vimeoRef.children[0].src = VimeoIcon;
                                youtubeRef.children[0].src = YouTubeIcon;

                                let styleObj = {
                                    cursor: 'pointer',
                                    margin: '0px 2px',
                                    textAlign: 'center',
                                    padding: '0px 0px',
                                    height: '60px',
                                    width: '60px',
                                    lineHeight: '55px',
                                    display: 'inline-block',
                                    border: '#525156 0px solid',
                                    verticalAlign: 'middle',
                                    background:''
                                };
                                Object.assign(facebookRef.style, styleObj);
                                Object.assign(twitterRef.style, styleObj);
                                Object.assign(instagramkRef.style, styleObj);
                                Object.assign(googlePlusRef.style, styleObj);
                                Object.assign(linkedinRef.style, styleObj);
                                Object.assign(pinterestRef.style, styleObj);
                                Object.assign(tumblrRef.style, styleObj);
                                Object.assign(vimeoRef.style, styleObj);
                                Object.assign(youtubeRef.style, styleObj);
                                
                            }}>Reset Default</button>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                        <Col span={8}>
                            <FormattedMessage id='buttonBackgraound.text' />
                        </Col>
                        <Col span={16} >
                            <ColorPicker 
                             placement='bottomLeft'
                                enableAlpha={false}
                                color={facebookRef.style.background || '#4267B2'}
                                onChange={(obj) => {
                                    facebookRef.style.background = obj.color;
                                    twitterRef.style.background = obj.color;
                                    instagramkRef.style.background = obj.color;
                                    googlePlusRef.style.background = obj.color;
                                    linkedinRef.style.background = obj.color;
                                    pinterestRef.style.background = obj.color;
                                    tumblrRef.style.background = obj.color;
                                    vimeoRef.style.background = obj.color;
                                    youtubeRef.style.background = obj.color;
                                }}
                                mode="RGB"
                            />
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={8}><FormattedMessage id='buttonCorners.text' /></Col>
                        <Col span={16}>
                            <Slider defaultValue={0} min={0} max={50} onChange={(value) => {
                                facebookRef.style.borderRadius = value + "%";
                                twitterRef.style.borderRadius = value + "%";
                                instagramkRef.style.borderRadius = value + "%";
                                googlePlusRef.style.borderRadius = value + "%";
                                linkedinRef.style.borderRadius = value + "%";
                                pinterestRef.style.borderRadius = value + "%";
                                tumblrRef.style.borderRadius = value + "%";
                                vimeoRef.style.borderRadius = value + "%";
                                youtubeRef.style.borderRadius = value + "%";

                                facebookRef.children[0].style.borderRadius = value + "%";
                                twitterRef.children[0].style.borderRadius = value + "%";
                                instagramkRef.children[0].style.borderRadius = value + "%";
                                googlePlusRef.children[0].style.borderRadius = value + "%";
                                linkedinRef.children[0].style.borderRadius = value + "%";
                                pinterestRef.children[0].style.borderRadius = value + "%";
                                tumblrRef.children[0].style.borderRadius = value + "%";
                                vimeoRef.children[0].style.borderRadius = value + "%";
                                youtubeRef.children[0].style.borderRadius = value + "%";
                            }} />
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={8}><FormattedMessage id='showBorder.text' /></Col>
                        <Col span={16}>
                            <Checkbox defaultChecked onChange={(e) => {
                                facebookRef.style.borderWidth = e.target.checked ? '1px' : '0px';
                                twitterRef.style.borderWidth = e.target.checked ? '1px' : '0px';
                                instagramkRef.style.borderWidth = e.target.checked ? '1px' : '0px';
                                googlePlusRef.style.borderWidth = e.target.checked ? '1px' : '0px';
                                linkedinRef.style.borderWidth = e.target.checked ? '1px' : '0px';
                                pinterestRef.style.borderWidth = e.target.checked ? '1px' : '0px';
                                tumblrRef.style.borderWidth = e.target.checked ? '1px' : '0px';
                                vimeoRef.style.borderWidth = e.target.checked ? '1px' : '0px';
                                youtubeRef.style.borderWidth = e.target.checked ? '1px' : '0px';


                                facebookRef.style.borderStyle = 'solid';
                                twitterRef.style.borderWidth = 'solid';
                                instagramkRef.style.borderWidth = 'solid';
                                googlePlusRef.style.borderWidth = 'solid';
                                linkedinRef.style.borderWidth = 'solid';
                                pinterestRef.style.borderWidth = 'solid';
                                tumblrRef.style.borderWidth = 'solid';
                                vimeoRef.style.borderWidth = 'solid';
                                youtubeRef.style.borderWidth = 'solid';
                        }} > </Checkbox>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={8}>
                            <FormattedMessage id='bordercolor.text' />
                        </Col>
                        <Col span={16} >
                            <ColorPicker 
                             placement='bottomLeft'
                                enableAlpha={false}
                                color={facebookRef.style.borderColor || '#4267B2'}
                                onChange={(obj) => {
                                    facebookRef.style.borderColor= obj.color;
                                    twitterRef.style.borderColor = obj.color;
                                    instagramkRef.style.borderColor = obj.color;
                                    googlePlusRef.style.borderColor = obj.color;
                                    linkedinRef.style.borderColor = obj.color;
                                    pinterestRef.style.borderColor = obj.color;
                                    tumblrRef.style.borderColor = obj.color;
                                    vimeoRef.style.borderColor = obj.color;
                                    youtubeRef.style.borderColor = obj.color;
                                }}
                                mode="RGB"
                            />
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={8}><FormattedMessage id='socialIcon.height' /></Col>
                        <Col span={16}>

                            {/* <Slider defaultValue={15} tooltipVisible /> */}
                            <Slider value={iconHeight} min={10} max={100} onChange={(value) => {
                            updateIconHeight(value);
                                // facebookRef.children[0].style.lineHeight = value + "px";
                                // twitterRef.children[0].style.lineHeight = value + "px";
                                // instagramkRef.children[0].style.lineHeight = value + "px";
                                // googlePlusRef.children[0].style.lineHeight = value + "px";
                                // linkedinRef.children[0].style.lineHeight = value + "px";
                                // pinterestRef.children[0].style.lineHeight = value + "px";
                                // tumblrRef.children[0].style.lineHeight = value + "px";
                                // vimeoRef.children[0].style.lineHeight = value + "px";
                                // youtubeRef.children[0].style.lineHeight = value + "px";
                                
                                facebookRef.children[0].style.height = value + "px";
                                twitterRef.children[0].style.height = value + "px";
                                instagramkRef.children[0].style.height = value + "px";
                                googlePlusRef.children[0].style.height = value + "px";
                                linkedinRef.children[0].style.height = value + "px";
                                pinterestRef.children[0].style.height = value + "px";
                                tumblrRef.children[0].style.height = value + "px";
                                vimeoRef.children[0].style.height = value + "px";
                                youtubeRef.children[0].style.height = value + "px";

                                facebookRef.children[0].style.width = value + "px";
                                twitterRef.children[0].style.width = value + "px";
                                instagramkRef.children[0].style.width = value + "px";
                                googlePlusRef.children[0].style.width = value + "px";
                                linkedinRef.children[0].style.width = value + "px";
                                pinterestRef.children[0].style.width = value + "px";
                                tumblrRef.children[0].style.width = value + "px";
                                vimeoRef.children[0].style.width = value + "px";
                                youtubeRef.children[0].style.width = value + "px";
                            }} />
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={8}><FormattedMessage id='socialIcon.bgHeight' /></Col>
                        <Col span={16}>

                        {/* <Slider defaultValue={15} tooltipVisible /> */}
                        <Slider value={iconBackHeight} min={10} max={100} onChange={(value) => {
                            updateIconBackHeight(value);
                            facebookRef.style.lineHeight = (value-7) + "px";
                            twitterRef.style.lineHeight = (value-7) + "px";
                            instagramkRef.style.lineHeight = (value-7) + "px";
                            googlePlusRef.style.lineHeight = (value-7) + "px";
                            linkedinRef.style.lineHeight = (value-7) + "px";
                            pinterestRef.style.lineHeight = (value-7) + "px";
                            tumblrRef.style.lineHeight = (value-7) + "px";
                            vimeoRef.style.lineHeight = (value-7) + "px";
                            youtubeRef.style.lineHeight = (value-7) + "px";
                            
                            facebookRef.style.height = value + "px";
                            twitterRef.style.height = value + "px";
                            instagramkRef.style.height = value + "px";
                            googlePlusRef.style.height = value + "px";
                            linkedinRef.style.height = value + "px";
                            pinterestRef.style.height = value + "px";
                            tumblrRef.style.height = value + "px";
                            vimeoRef.style.height = value + "px";
                            youtubeRef.style.height = value + "px";

                            facebookRef.style.width = value + "px";
                            twitterRef.style.width = value + "px";
                            instagramkRef.style.width = value + "px";
                            googlePlusRef.style.width = value + "px";
                            linkedinRef.style.width = value + "px";
                            pinterestRef.style.width = value + "px";
                            tumblrRef.style.width = value + "px";
                            vimeoRef.style.width = value + "px";
                            youtubeRef.style.width = value + "px";
                        }} />
                    </Col>
                </Row>

                    
                </Panel>
                <Panel header="Links" key="2">
                    <Row className="but-buttons">
                        <Col span={2}> <Checkbox defaultChecked onChange={(e) => {
                            let iconRef = document.getElementById('facebook_' + props.childId);
                            iconRef.style.display = e.target.checked ? "initial" : "none";
                        }} > </Checkbox>
                        </Col>
                        <Col span={2}><i className="fa fa-facebook" /></Col>
                        <Col span={20}>
                            <Input style={{ width: '210px', height: '25px' }} value={facebookHref} onChange={(e) => {
                                updateFacebookHref(e.target.value);
                                let url = e.target.value;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                document.getElementById('facebook_' + props.childId).href = url;
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={2}> <Checkbox defaultChecked onChange={(e) => {
                            let iconRef = document.getElementById('twitter_' + props.childId);
                            iconRef.style.display = e.target.checked ? "initial" : "none";
                        }} > </Checkbox>
                        </Col>
                        <Col span={2}><i className="fa fa-twitter" /></Col>
                        <Col span={20}>
                            <Input style={{ width: '210px', height: '25px' }} value={twitterHref} onChange={(e) => {
                                updateTwitterHref(e.target.value);
                                let url = e.target.value;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                document.getElementById('twitter_' + props.childId).href = url;
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={2}> <Checkbox defaultChecked onChange={(e) => {
                            let iconRef = document.getElementById('instagram_' + props.childId);
                            iconRef.style.display = e.target.checked ? "initial" : "none";
                        }} > </Checkbox>
                        </Col>
                        <Col span={2}><i className="fa fa-instagram" /></Col>
                        <Col span={20}>
                            <Input style={{ width: '210px', height: '25px' }} value={instagramHref} onChange={(e) => {
                                updateInstagramHref(e.target.value);
                                let url = e.target.value;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                document.getElementById('instagram_' + props.childId).href = url;
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={2}> <Checkbox defaultChecked onChange={(e) => {
                            let iconRef = document.getElementById('google-plus_' + props.childId);
                            iconRef.style.display = e.target.checked ? "initial" : "none";
                        }} > </Checkbox>
                        </Col>
                        <Col span={2}><i className="fa fa-google-plus" /> </Col>
                        <Col span={20}>
                            <Input style={{ width: '210px', height: '25px' }} value={googlePlusHref} onChange={(e) => {
                                updateGooglePlusHref(e.target.value);
                                let url = e.target.value;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                document.getElementById('google-plus_' + props.childId).href = url;
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={2}> <Checkbox defaultChecked onChange={(e) => {
                            let iconRef = document.getElementById('linkedin_' + props.childId);
                            iconRef.style.display = e.target.checked ? "initial" : "none";
                        }} > </Checkbox>
                        </Col>
                        <Col span={2}><i className="fa fa-linkedin" /></Col>
                        <Col span={20}>
                            <Input style={{ width: '210px', height: '25px' }} value={linkedinHref} onChange={(e) => {
                                updateLinkedinHref(e.target.value);
                                let url = e.target.value;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                document.getElementById('linkedin_' + props.childId).href = url;
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={2}> <Checkbox defaultChecked onChange={(e) => {
                            let iconRef = document.getElementById('pinterest_' + props.childId);
                            iconRef.style.display = e.target.checked ? "initial" : "none";
                        }} > </Checkbox>
                        </Col>
                        <Col span={2}><i className="fa fa-pinterest-p" /></Col>
                        <Col span={20}>
                            <Input style={{ width: '210px', height: '25px' }} value={pinterestHref} onChange={(e) => {
                                updatePinterestHref(e.target.value);
                                let url = e.target.value;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                document.getElementById('pinterest_' + props.childId).href = url;
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={2}> <Checkbox defaultChecked onChange={(e) => {
                            let iconRef = document.getElementById('tumblr_' + props.childId);
                            iconRef.style.display = e.target.checked ? "initial" : "none";
                        }} > </Checkbox>
                        </Col>
                        <Col span={2}><i className="fa fa-tumblr" /></Col>
                        <Col span={20}>
                            <Input style={{ width: '210px', height: '25px' }} value={tumblrHref} onChange={(e) => {
                                updateTumblrHref(e.target.value);
                                let url = e.target.value;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                document.getElementById('tumblr_' + props.childId).href = url;
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={2}> <Checkbox defaultChecked onChange={(e) => {
                            let iconRef = document.getElementById('vimeo_' + props.childId);
                            iconRef.style.display = e.target.checked ? "initial" : "none";
                        }} > </Checkbox>
                        </Col>
                        <Col span={2}><i className="fa fa-vimeo" /></Col>
                        <Col span={20}>
                            <Input style={{ width: '210px', height: '25px' }} value={vimeoHref} onChange={(e) => {
                                updateVimeoHref(e.target.value);
                                let url = e.target.value;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                document.getElementById('vimeo_' + props.childId).href = url;
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={2}> <Checkbox defaultChecked onChange={(e) => {
                            let iconRef = document.getElementById('youtube_' + props.childId);
                            iconRef.style.display = e.target.checked ? "initial" : "none";
                        }} > </Checkbox>
                        </Col>
                        <Col span={2}><i className="fa fa-youtube" /></Col>
                        <Col span={20}>
                            <Input style={{ width: '210px', height: '25px' }} value={youtubeHref} onChange={(e) => {
                                updateYoutubeHref(e.target.value);
                                let url = e.target.value;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                document.getElementById('youtube_' + props.childId).href = url;
                            }}></Input>
                        </Col>
                    </Row>
                </Panel>

                 {/* <Panel header="Icon Styling1" key="2">
                    <div>
                        <Row className="but-buttons1">
                            <Col span={18}>
                                <FormattedMessage id='buttonSize.text' />
                            </Col>
                            <Col span={24}>
                                <Button onClick={() => {
                                    // let buttonRef = document.getElementById(props.childId);
                                    let fb_iconRef = document.getElementById('facebook_' + props.childId);
                                    let twitter_iconRef = document.getElementById('twitter_' + props.childId);
                                    let instagram_iconRef = document.getElementById('instagram_' + props.childId);
                                    let googlePlus_iconRef = document.getElementById('google-plus_' + props.childId);
                                    let linkedin_iconRef = document.getElementById('linkedin_' + props.childId);
                                    let pinterest_iconRef = document.getElementById('pinterest_' + props.childId);
                                    let tumblr_iconRef = document.getElementById('tumblr_' + props.childId);
                                    let vimeo_iconRef = document.getElementById('vimeo_' + props.childId);
                                    let youtube_iconRef = document.getElementById('youtube_' + props.childId);
                                    fb_iconRef.style.width = "120px";
                                    fb_iconRef.style.height = "40px";
                                    fb_iconRef.style.fontSize = '20px'; 

                                    twitter_iconRef.style.width = "120px";
                                    twitter_iconRef.style.height = "40px";
                                    twitter_iconRef.style.fontSize = '20px'; 

                                    instagram_iconRef.style.width = "120px";
                                    instagram_iconRef.style.height = "40px";
                                    instagram_iconRef.style.fontSize = '20px'; 

                                    googlePlus_iconRef.style.width = "120px";
                                    googlePlus_iconRef.style.height = "40px";
                                    googlePlus_iconRef.style.fontSize = '20px'; 

                                    linkedin_iconRef.style.width = "120px";
                                    linkedin_iconRef.style.height = "40px";
                                    linkedin_iconRef.style.fontSize = '20px'; 

                                    pinterest_iconRef.style.width = "120px";
                                    pinterest_iconRef.style.height = "40px";
                                    pinterest_iconRef.style.fontSize = '20px'; 

                                    tumblr_iconRef.style.width = "120px";
                                    tumblr_iconRef.style.height = "40px";
                                    tumblr_iconRef.style.fontSize = '20px'; 

                                    vimeo_iconRef.style.width = "120px";
                                    vimeo_iconRef.style.height = "40px";
                                    vimeo_iconRef.style.fontSize = '20px'; 

                                    youtube_iconRef.style.width = "120px";
                                    youtube_iconRef.style.height = "40px";
                                    youtube_iconRef.style.fontSize = '20px'; 

                                }} style={{ borderRadius: '4px 0px 0px 4px' }}>
                                    <span className='cirty'>
                                        S </span></Button>
                                <Button onClick={() => {
                                    let fb_iconRef = document.getElementById('facebook_' + props.childId);
                                    let twitter_iconRef = document.getElementById('twitter_' + props.childId);
                                    let instagram_iconRef = document.getElementById('instagram_' + props.childId);
                                    let googlePlus_iconRef = document.getElementById('google-plus_' + props.childId);
                                    let linkedin_iconRef = document.getElementById('linkedin_' + props.childId);
                                    let pinterest_iconRef = document.getElementById('pinterest_' + props.childId);
                                    let tumblr_iconRef = document.getElementById('tumblr_' + props.childId);
                                    let vimeo_iconRef = document.getElementById('vimeo_' + props.childId);
                                    let youtube_iconRef = document.getElementById('youtube_' + props.childId);
                                    fb_iconRef.style.width = "180px";
                                    fb_iconRef.style.height = "60px";
                                    fb_iconRef.style.fontSize = '30px'; 

                                    twitter_iconRef.style.width = "180px";
                                    twitter_iconRef.style.height = "60px";
                                    twitter_iconRef.style.fontSize = '30px'; 

                                    instagram_iconRef.style.width = "180px";
                                    instagram_iconRef.style.height = "60px";
                                    instagram_iconRef.style.fontSize = '30px'; 

                                    googlePlus_iconRef.style.width = "180px";
                                    googlePlus_iconRef.style.height = "60px";
                                    googlePlus_iconRef.style.fontSize = '30px'; 

                                    linkedin_iconRef.style.width = "180px";
                                    linkedin_iconRef.style.height = "60px";
                                    linkedin_iconRef.style.fontSize = '30px'; 

                                    pinterest_iconRef.style.width = "180px";
                                    pinterest_iconRef.style.height = "60px";
                                    pinterest_iconRef.style.fontSize = '30px'; 

                                    tumblr_iconRef.style.width = "180px";
                                    tumblr_iconRef.style.height = "60px";
                                    tumblr_iconRef.style.fontSize = '30px'; 

                                    vimeo_iconRef.style.width = "180px";
                                    vimeo_iconRef.style.height = "60px";
                                    vimeo_iconRef.style.fontSize = '30px'; 

                                    youtube_iconRef.style.width = "180px";
                                    youtube_iconRef.style.height = "60px";
                                    youtube_iconRef.style.fontSize = '30px'; 
                                }} style={{ borderRadius: '0px 0px 0px 0px ' }}>
                                    <span className='cirty'>M </span></Button>
                                <Button onClick={() => {
                                      let fb_iconRef = document.getElementById('facebook_' + props.childId);
                                      let twitter_iconRef = document.getElementById('twitter_' + props.childId);
                                      let instagram_iconRef = document.getElementById('instagram_' + props.childId);
                                      let googlePlus_iconRef = document.getElementById('google-plus_' + props.childId);
                                      let linkedin_iconRef = document.getElementById('linkedin_' + props.childId);
                                      let pinterest_iconRef = document.getElementById('pinterest_' + props.childId);
                                      let tumblr_iconRef = document.getElementById('tumblr_' + props.childId);
                                      let vimeo_iconRef = document.getElementById('vimeo_' + props.childId);
                                      let youtube_iconRef = document.getElementById('youtube_' + props.childId);
                                      fb_iconRef.style.width = "220px";
                                      fb_iconRef.style.height = "80px";
                                      fb_iconRef.style.fontSize = '40px'; 
  
                                      twitter_iconRef.style.width = "220px";
                                      twitter_iconRef.style.height = "80px";
                                      twitter_iconRef.style.fontSize = '40px'; 
  
                                      instagram_iconRef.style.width = "220px";
                                      instagram_iconRef.style.height = "80px";
                                      instagram_iconRef.style.fontSize = '40px'; 
  
                                      googlePlus_iconRef.style.width = "220px";
                                      googlePlus_iconRef.style.height = "80px";
                                      googlePlus_iconRef.style.fontSize = '40px'; 
  
                                      linkedin_iconRef.style.width = "220px";
                                      linkedin_iconRef.style.height = "80px";
                                      linkedin_iconRef.style.fontSize = '40px'; 
  
                                      pinterest_iconRef.style.width = "220px";
                                      pinterest_iconRef.style.height = "80px";
                                      pinterest_iconRef.style.fontSize = '40px'; 
  
                                      tumblr_iconRef.style.width = "220px";
                                      tumblr_iconRef.style.height = "80px";
                                      tumblr_iconRef.style.fontSize = '40px'; 
  
                                      vimeo_iconRef.style.width = "220px";
                                      vimeo_iconRef.style.height = "80px";
                                      vimeo_iconRef.style.fontSize = '40px'; 
  
                                      youtube_iconRef.style.width = "220px";
                                      youtube_iconRef.style.height = "80px";
                                      youtube_iconRef.style.fontSize = '40px'; 
                                }} style={{ borderRadius: '0px 4px 4px 0px ' }}><span className='cirty'> L</span></Button>
                            </Col>
                        </Row>
                        <Row className="but-buttons1">
                            <Col span={7}><FormattedMessage id='buttonSize.text' /></Col>
                            <Col span={17}>
                                <Slider min={1} max={100} onChange={(value) => {
                                     let fb_iconRef = document.getElementById('facebook_' + props.childId);
                                     let twitter_iconRef = document.getElementById('twitter_' + props.childId);
                                     let instagram_iconRef = document.getElementById('instagram_' + props.childId);
                                     let googlePlus_iconRef = document.getElementById('google-plus_' + props.childId);
                                     let linkedin_iconRef = document.getElementById('linkedin_' + props.childId);
                                     let pinterest_iconRef = document.getElementById('pinterest_' + props.childId);
                                     let tumblr_iconRef = document.getElementById('tumblr_' + props.childId);
                                     let vimeo_iconRef = document.getElementById('vimeo_' + props.childId);
                                     let youtube_iconRef = document.getElementById('youtube_' + props.childId);
                                     fb_iconRef.style.width = value + "%";
                                     twitter_iconRef.style.width = value + "%";
                                     instagram_iconRef.style.width = value + "%";
                                     googlePlus_iconRef.style.width = value + "%";
                                     linkedin_iconRef.style.width = value + "%";
                                     pinterest_iconRef.style.width = value + "%";
                                     tumblr_iconRef.style.width = value + "%";
                                     vimeo_iconRef.style.width = value + "%";
                                     youtube_iconRef.style.width = value + "%";
                                }} />
                            </Col>
                        </Row>


                        <Row className="but-buttons1">
                            <Col span={5}><FormattedMessage id='buttonHeight.height' /></Col>
                            <Col span={19}>

                                <Slider min={10} max={100} onChange={(value) => {
                                    let fb_iconRef = document.getElementById('facebook_' + props.childId);
                                    let twitter_iconRef = document.getElementById('twitter_' + props.childId);
                                    let instagram_iconRef = document.getElementById('instagram_' + props.childId);
                                    let googlePlus_iconRef = document.getElementById('google-plus_' + props.childId);
                                    let linkedin_iconRef = document.getElementById('linkedin_' + props.childId);
                                    let pinterest_iconRef = document.getElementById('pinterest_' + props.childId);
                                    let tumblr_iconRef = document.getElementById('tumblr_' + props.childId);
                                    let vimeo_iconRef = document.getElementById('vimeo_' + props.childId);
                                    let youtube_iconRef = document.getElementById('youtube_' + props.childId);
                                    fb_iconRef.style.lineHeight = value + "%";
                                    twitter_iconRef.style.lineHeight = value + "%";
                                    instagram_iconRef.style.lineHeight = value + "%";
                                    googlePlus_iconRef.style.lineHeight = value + "%";
                                    linkedin_iconRef.style.lineHeight = value + "%";
                                    pinterest_iconRef.style.lineHeight = value + "%";
                                    tumblr_iconRef.style.lineHeight = value + "%";
                                    vimeo_iconRef.style.lineHeight = value + "%";
                                    youtube_iconRef.style.lineHeight = value + "%";
                                    
                                    fb_iconRef.style.height = value + "%";
                                    twitter_iconRef.style.height = value + "%";
                                    instagram_iconRef.style.height = value + "%";
                                    googlePlus_iconRef.style.height = value + "%";
                                    linkedin_iconRef.style.height = value + "%";
                                    pinterest_iconRef.style.height = value + "%";
                                    tumblr_iconRef.style.height = value + "%";
                                    vimeo_iconRef.style.height = value + "%";
                                    youtube_iconRef.style.height = value + "%";
                                }} />
                            </Col>


                        </Row>
                        <Row className="but-buttons1">
                            <Col span={24}>
                                <FormattedMessage id='buttonCorner.text' />
                            </Col>
                            <Col span={24} >
                                <Button onClick={() => {
                                    let buttonRef = document.getElementById(props.childId);
                                    buttonRef.style.borderRadius = "0px"
                                }} style={{ borderRadius: '4px 0px 0px 4px', position: 'relative' }}>

                                    <div className="leftd-roud1"></div>
                                </Button>
                                <Button onClick={() => {
                                    let buttonRef = document.getElementById(props.childId);
                                    buttonRef.style.borderRadius = "4px"
                                }} style={{ borderRadius: '0px', position: 'relative' }}>
                                    <div className="mrd-roud1"></div>
                                </Button>
                                <Button onClick={() => {
                                    let buttonRef = document.getElementById(props.childId);
                                    buttonRef.style.borderRadius = "20px"
                                }} style={{ borderRadius: '0px 4px 4px 0px', position: 'relative' }}>
                                    <div className="rigt-roud1"></div>
                                </Button>
                            </Col>
                        </Row>
                        <Row className="but-buttons1">
                            <Col span={7}><FormattedMessage id='buttonCorner.text' /></Col>
                            <Col span={17}>
                                <Slider defaultValue={10} min={0} max={50} onChange={(value) => {
                                    let buttonRef = document.getElementById(props.childId);
                                    buttonRef.style.borderRadius = value + "%"
                                }} />
                            </Col>
                        </Row>

                        <Row className="but-buttons1">
                            <Col span={24}>
                                <FormattedMessage id='buttonPosition.text' />
                            </Col>
                            <Col span={24} >
                                <Button onClick={() => {
                                    let buttonRef = document.getElementById(props.childId);
                                    buttonRef.style.marginLeft = "10px";
                                    buttonRef.style.float = 'left';
                                }} style={{ borderRadius: '4px 0px 0px 4px', position: 'relative' }}>

                                    <div className="left-roud"></div>
                                </Button>
                                <Button onClick={() => {
                                    let buttonRef = document.getElementById(props.childId);
                                    buttonRef.style.float = 'unset';
                                    buttonRef.style.margin = "15px auto 0px";
                                }} style={{ borderRadius: ' 0px ', position: 'relative' }} >
                                    <div className="mr-roud"></div>
                                </Button>
                                <Button onClick={() => {
                                    let buttonRef = document.getElementById(props.childId);
                                    buttonRef.style.marginLeft = "10px";
                                    buttonRef.style.float = 'right';
                                }} style={{ borderRadius: '0px 4px 4px 0px', position: 'relative' }} >
                                    <div className="right-roud"></div>
                                </Button>
                            </Col>
                        </Row>

                        <Row className="but-buttons1">
                            <Col span={8}><FormattedMessage id='buttonPosition.text' /></Col>
                            <Col span={16}>
                                <Slider defaultValue={20} onChange={(value) => {
                                    let buttonRef = document.getElementById(props.childId);
                                    buttonRef.style.float = 'left';
                                    buttonRef.style.marginLeft = value + "%";
                                }} />
                            </Col>
                        </Row>
                       
                        <Row className="but-buttons1">
                        <Col span={8} style={{ paddingTop: '2px',  }}>
                                <FormattedMessage id='buttonTextColor.text' />
                            </Col>
                            <Col span={16} >
                                <ColorPicker
                                    enableAlpha={false}
                                    color={props.textColor || '#fff'}
                                    onChange={(obj) => {
                                        props.updateState({ textColor: obj.color });
                                        let buttonRef = document.getElementById(props.childId);
                                        buttonRef.style.color = obj.color;
                                    }}
                                    mode="RGB"
                                />
                            </Col>
                           
                        </Row>
                        <Row className="but-buttons1">
                        <Col span={8} style={{ paddingTop: '2px',  }}>
                                <FormattedMessage id='buttonBackgraound.text' />
                            </Col>
                            <Col span={16} >
                                <ColorPicker
                                    enableAlpha={false}
                                    color={props.bgColor || '#45806c'}
                                    onChange={(obj) => {
                                        props.updateState({ bgColor: obj.color });
                                        let buttonRef = document.getElementById(props.childId);
                                        buttonRef.style.background = obj.color;
                                    }}
                                    mode="RGB"
                                />
                            </Col>
                           

                        </Row>


                    </div>
                </Panel>  */}
            </Collapse>
        </Fragment>
    );
}


export default injectIntl(SocialLinksProps);
