import React, { Fragment } from "react";
import { Row, Col, Button, Popover,Skeleton } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import { showToasterMessage } from '../../../../utils';
import ReactHtmlParser from 'react-html-parser';
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
import { MODAL_TYPE, PERMISSION_VALUES, MODULE_CODED_VALUES, TRANSACTION_COLUMN_INDEX } from "../../../../static/constants";
import { AddAndRemoveColumn } from "../../../general/AddAndRemoveColumn";
import find from "lodash.find";
import { getLabelName } from "../../../modal/modalBody/customerInfo";


class ProductDetail extends React.Component {

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = <span>
      <FormattedMessage
        id='addItem.confirmation'
        values={{ value: `${text} to ` }}
        defaultMessage=''
      />
      {labelName} ?
    </span>;

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        addFunc = props.addExpenseTypes;
        break;
      }
      default: {
        addFunc = () => { }
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId })
        props.hideModal();
      },
    };
    showModal(modalData);
  }

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        title = <FormattedMessage id='addItem.text.expenseType' defaultMessage='' />;
        break;
      }
      default:
        title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
    }
    return title;
  }

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        }
      };
      showModal(data);
    }
  }

  render() {
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      setTimeout(() => {
        onDataRendered(params);
      }, 1000)
    }



    const _isColumnToHide = (columnName) => {
      // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
      //     return true;
      // }
      return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }

    const saveColumnIndex = (columnState) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.tablePreference) {
        gridPreference = JSON.parse(data.tablePreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data);
    }

    const saveColumnWidth = (columnState, width) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data, this.props);
    }

    let moveEvent = {};
    const onColumnMoved = (event, isDragStopped) => {
      if (event.column) {
        moveEvent = event;
      }
      if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
        saveColumnIndex(moveEvent.columnApi.getColumnState());
      }
    }

    const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
      const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
      event.api.resetRowHeights();
      //const gridApi = tablegridApi || this.gridApi;
      if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
        //event.api.resetRowHeights();
        if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
          this.gridApi.sizeColumnsToFit();
          // const columnArray = event.columnApi.getColumnState();
          // for (let i = (columnArray.length - 1); i >= 0; i--) {
          //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
          //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
          //         break;
          //     }
          // }
          // saveColumnWidth(columnArray);
          setTimeout(() => {
            const columnArray = event.columnApi.getColumnState();
            saveColumnWidth(columnArray);
          }, 1000);
        } else {
          saveColumnWidth(event.columnApi.getColumnState());
        }
      }
    }


    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "#";
        },
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1
        },
        field: "sno",
        headerClass: "pointer-none",
        rowDrag: true,
        pinned: true,
        suppressMovable: true,
        lockPosition: true,
        colId: "sno_1",
        hide: false,
        minWidth: 75,
        maxWidth: 75,
        sortable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='package.productDetail.product' defaultMessage='' />;
        },
        field: 'product',
        colId: 'product_1',
        hide: _isColumnToHide('product'),
        resizable: true,
        suppressMovable: true,
        sortable: true,
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (params.reactContainer && params.reactContainer.scrollHeight > 30) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500)
          return (params.data.itemName || "") + ((params.data.itemVariantName && " (" + params.data.itemVariantName + ") ") || "");
        },

      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='package.productDetail.description' defaultMessage='' />
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD + '_1',
        cellClass: " ag-cell-description",
        editable: true,
        sortable: true,
        suppressMovable: true,
        resizable: true,
        hide: _isColumnToHide(AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD),
        cellEditor: 'CKEditorGrid',
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true
              })
            }
          }
        },
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
              params.eParentOfValue.style.height = 'inherit';
              params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500)
          return ReactHtmlParser(params.data.description)
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (params.event.target.scrollHeight > (params.data.minHeight || 30)) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = 'inherit';
              params.event.path[2].style.height = `${params.event.target.scrollHeight + 20}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight + 20);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = 'inherit';
              params.event.path[2].style.height = `${(params.node.data.minHeight || 30)}px`;
            }
            params.node.setRowHeight((params.node.data.minHeight || 30));
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        }
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='package.productDetail.uom' defaultMessage='' />
        },
        field: 'uomId',
        hide: _isColumnToHide('uomId'),
        colId: 'uomId_1',
        sortable: true,
        editable: true,
        suppressMovable: true,
        resizable: true,
        cellEditor: 'customDropDownEditor',
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellRendererFramework: (params) => {
          return (params.data.uomName || "");
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.uomList,
            optionKeyName: 'uomId',
            valueKeyName: 'uomName',
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false
              }
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM)
            },
            onSelect: (selectedValue, option) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.uomName = selectedValue;
              obj.node.data.uomId = option.key;
            },
            allowClear: true,
            onClear: () => {
              obj.node.data.uomName = null;
              this.props.updateState({
                uomName: undefined,
                uomId: undefined
              });
            },
          }
        },
        //cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='package.productDetail.qtyOrdered' defaultMessage='' />
        },
        field: 'quantityOrdered',
        hide: _isColumnToHide('quantity'),
        colId: 'quantity_1',
        suppressMovable: true,
        resizable: true,
        sortable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='package.productDetail.qtyPacked' defaultMessage='' />
        },
        field: 'quantityPacked',
        colId: 'quantityPacked_1',
        hide: _isColumnToHide('quantityPacked'),
        resizable: true,
        sortable: true,
        suppressMovable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='package.productDetail.qtyToPack' defaultMessage='' />
        },
        field: 'qtyToPack',
        colId: 'qtyToPack_1',
        hide: _isColumnToHide('qtyToPack'),
        editable: true,
        resizable: true,
        suppressMovable: true,
        sortable: true,
        cellEditor: 'customNumberEditor',
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (params) => {
          return {
            value: params.node.data.qtyToPack,
            onChange: (data) => {
              const qtyToPack = params.node.data.quantityOrdered - Number(params.node.data.quantityPacked)
               // const qtyToPack = params.node.data.quantityOrdered - Number(data);
                if (qtyToPack < data) {
                 // params.node.data.quantityPacked = quantityPacked;
                  params.node.data.qtyToPack = Number(data);
                }else{
                //  params.node.data.quantityPacked = params.node.data.quantityOrdered;
                  params.node.data.qtyToPack = qtyToPack;
                }
                this.gridApi.refreshCells();
            },
            valueGetter: (params) =>{
              const qtyToPack = params.node.data.quantityOrdered - Number(params.node.data.quantityPacked)
              if (qtyToPack < params.data.qtyToPack) {
                  return params.data.qtyToPack
              }else{
                return qtyToPack;
              }
          },
          cellRendererFramework: (params) => {
            setTimeout(() => {
              if (params.reactContainer && params.reactContainer.scrollHeight >= (params.node.data.maxHeight || 30)) {
                  params.node.setRowHeight(params.reactContainer.scrollHeight);
                  params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                  this.gridApi && this.gridApi.onRowHeightChanged();
              } else {
                  params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
              }
          }, 500)
            return <div>{params.node.data.quantityOrdered - Number(params.node.data.quantityPacked)}</div>
        },
            // getNewMax: (newVal) => {
            //   const val  = Number(newVal) <= params.node.data.quantityOrdered ? Number(newVal) : params.node.data.quantityOrdered;
            //   return this.props.packageType === 'update' ? params.node.data.quantityOrdered - val: params.data.quantityOrdered - params.data.quantityPacked;
              
            // },
            maxValue: params.data.quantityOrdered - params.data.quantityPacked
          }
        },
      },
      {
        headerComponentFramework: () => {
          return <div className="cursor-pointer">
            <Popover
              content={<AddAndRemoveColumn {...this.props} />}
              title={<div className="text-center">Add/Remove </div>}
              placement="left"
              trigger="click"
            >
              <i className='fa fa-bars' />
            </Popover>
          </div>;
        },
        field: 'action',
        colId: 'action_1',
        suppressMovable: true,
        width: 52,
        lockPosition: true,
        pinned: 'right',
        cellRenderer: 'customActionEditor',
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              if (this.gridApi.rowRenderer.lastRenderedRow > 0) {
                this.gridApi.updateRowData({ remove: [this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                  this.gridApi.updateRowData({ add: [{}] });
                }
              } else {
                showToasterMessage({ messageType: 'error', description: 'Atleast one item required for package' })
              }
            },
          }
        }
      }
    ];

    const onModelUpdated = (params) => {
      if (this.props.tableDataReRendered) {
        //params.api.resetRowHeights();
        this.props.updateState({
          tableDataReRendered: false
        })
        let actionIndex = 0;
        let amountIndex = 0;
        let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.PACKAGE_FORM

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + '_1' });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        })
        txColumnSetting = columnToBeSet.concat(txColumnSetting);

        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== 'action' && columnName !== 'amount') {
            if (columnName === 'isChecked' || columnName === 'sno') {
              e.pinned = 'left';
              e.lockPosition = true;
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === 'amount') {
            e.hide = false;
            amountIndex = index;
            e.lockPosition = true;
            e.pinned = 'right';
          }
          if (columnName === 'action') {
            actionIndex = index;
            e.width = 52;
            e.hide = false;
            e.pinned = 'right';
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== 'action' && columnName !== 'amount';
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        params.columnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
            params.api.sizeColumnsToFit();
          }
        }, 500);

      }
    }

    const onDataRendered = (params) => {
      let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
      let actionIndex = 0;
      let amountIndex = 0;
      if (Object.keys(txColumnSetting).length) {
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.PACKAGE_FORM

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + '_1' });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        })
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== 'action' && columnName !== 'amount') {
            if (columnName === 'isChecked' || columnName === 'sno') {
              e.pinned = 'left';
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === 'action') {
            e.width = 52;
            actionIndex = index;
            e.hide = false;
            e.pinned = 'right';
          }
          if (columnName === 'amount') {
            e.hide = false;
            e.lockPosition = true;
            amountIndex = index;
            e.pinned = 'right';
          }

        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== 'action' && columnName !== 'amount';
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        this.gridColumnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      } else {
        this.gridApi.sizeColumnsToFit();
      }
    }

    const getRowHeight = (params) => {

      const rowHeight = params.node.data.rowHeight ? (Number(params.node.data.rowHeight.split("px")[0]) - 30) : 30;
      return rowHeight < 30 ? 30 : rowHeight;

    };

    return (
      <Fragment> 
        <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

        <div className="transaction-table">
          {this.props.txColumns.length ? <CustomAgGrid
            columnDefs={columnDefs}
            onModelUpdated={onModelUpdated}
            onGridReady={onGridReady}
            onColumnResized={onColumnResized}
            onDragStopped={() => {
              onColumnMoved({}, true);
            }}
            //onColumnMoved={onColumnMoved}
            getRowHeight={getRowHeight}
            rowData={((this.props.soPackageData || {}).customerSalesOrderDetailsList)?.sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1) ||((this.props.amendSoInvoiceData || {}).customerAmendSalesOrderDetailsList)?.sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1) || []}
            gridStyle={{
              width: '100%',
              height: '100%',
              marginBottom: '10px'
            }} /> : null}
        </div>

        <Row type='flex' className=" package-action-btn">
          <Col span={12}>
            <div>
              <div className="remarks-title">
                Remarks (For Internal Use)
            </div>
              <CKEditor
                type="inline"
                className="description-textarea"
                key={`${"remarksInternal"}`}
                data={this.props.remarksInternal}
                onInit={editor => {
                  this.editor = editor;
                }}
                onChange={(event) => {
                  const data = event.editor.getData();
                  this.props.updateState({ remarksInternal: data });
                }}
                config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter internal remarks' }}
              />
            </div>
          </Col>
          <Col span={12}>

          </Col>
        </Row>


        <Row type='flex' className=" package-action-btn">
          <Col span={12}>
            <div className="remarks-title">
              Remarks
            </div>

            {/* <TextArea maxLength={500}
              className="description-textarea"
              rows={4}
              placeholder='Enter remarks here'
              value={this.props.remarksCustomer}
              onChange={(e) => { this.props.updateState({ remarksCustomer: e.target.value }); }}
            /> */}

            <CKEditor
              type="inline"
              className="description-textarea"
              key={`${"remarksCustomer"}`}
              data={this.props.remarksCustomer}
              onInit={editor => {
                this.editor = editor;
              }}
              onChange={(event) => {
                const data = event.editor.getData();
                this.props.updateState({ remarksCustomer: data });
              }}
              config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter customer remarks' }}
            />
          </Col>
          <Col span={12}>

          </Col>
        </Row>

        <div className="footer extra-height">
        
          

       <div className="left-fItems">

            <Button className="grey-button" 
             onClick={() => {
                this.props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    this.props.popModalFromStack();
                  }
                })
              }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
       </div>
       <div className="right-fItems">

            <Button className="ant-btn-primary"
             loading={this.props.saveLoading}
             disabled={this.props.saveLoading}
              onClick={() => {
                createPackage(this.props, this.state, this.gridApi);
              }}
            >Submit</Button>
       </div>
        </div>
        </Skeleton>
      </Fragment>
    )
  }
};

const createPackage = (props, state, agGridObj) => {
  let isQtyZero = false;
  const {length, width, height, weight, dim} = props;
  agGridObj.forEachNode((rowNode) => {
    if (Number((rowNode.data || {}).qtyToPack) < 1) {
      isQtyZero = true;
    }
  });
  if (isQtyZero) {
    showToasterMessage({ description: 'Quantity to pack should not be 0' })
    return;
  }
  if (!props.customerId && (!props.soPackageData || !props.soPackageData.customerName)) {
    showToasterMessage({ description: props.intl.formatMessage({ id: 'selectCustomer.message', defaultMessage: '' }) })
    return;
  }
  if (!props.soData && !props.soPackageData) {
    showToasterMessage({ description: props.intl.formatMessage({ id: 'selectSalesOrder.message', defaultMessage: '' }) })
    return;
  }
  if(!length || !width || !height){
    return showToasterMessage({
        messageType: 'error', description: props.intl.formatMessage({ id: 'customer.shipment.shipmentDetails.missingDimensions' })
    });
  }
  if(!props.dimensionUnit){
    return showToasterMessage({
        messageType: 'error', description: props.intl.formatMessage({ id: 'customer.shipment.shipmentDetails.missingDimensionsUnit' })
    });
  }
  if(!weight){
    return showToasterMessage({
      messageType: 'error', description: props.intl.formatMessage({ id: 'customer.shipment.shipmentDetails.missingWeight' })
    });
  }
  if(!props.weightUnit){
    return showToasterMessage({
      messageType: 'error', description: props.intl.formatMessage({ id: '"customer.shipment.shipmentDetails.missingWeightUnit"' })
    });
  }


  const modalData = {
    modalBody: 'Are you sure you want to create package ?',
    handleSubmit: () => {
      const payload = {
        props,
        state,
        agGridObj
      }
      props.createPackage(payload);
      props.hideModal();
    }
  };
  props.showModal(modalData);
}

// const getDescriptionFragment = (soDetail, item, index) => {
//   if (!item) return <Fragment />;
//   const fragmentObj = <Fragment>
//     {
//       item.stockNumber
//         ? <div>
//           <span className="title"> Stock # : </span>{item.stockNumber}
//           <br></br>
//           <br></br>
//         </div> :
//         ''
//     }
//     {
//       item.partNumber
//         ? <div>
//           <span className="title"> Part # : </span>{item.partNumber}
//           <br></br>
//           <br></br>
//         </div> :
//         ''
//     }
//     {
//       item.description
//         ? <div>
//           {ReactHtmlParser(item.description || '')}
//           <br></br>
//           <br></br>
//         </div> :
//         ''
//     }
//     {
//       item.origin
//         ? <div>
//           <span className="title"> Origin : </span>{item.origin}
//         </div> :
//         ''
//     }
//     {
//       item.hsCode
//         ? <div>
//           <span className="title"> HS Code : </span>{item.hsCode}
//           <br></br>
//           <br></br>
//         </div> :
//         ''
//     }
//     {
//       item.comment
//         ? <div>
//           <span className="title"> Warehouse Name : </span>{item.comment}
//           <br></br>
//           <br></br>
//         </div> :
//         ''
//     }
//     {
//       (soDetail.customerPONumber || index)
//         ? <div> (
//                   {soDetail.customerPONumber ? <span className="title">PO {soDetail.customerPONumber} - </span> : ''}
//           {index ? <span className="title"> Line {index}</span> : ''}
//               )
//               </div> :
//         ''
//     }
//   </Fragment>
//   return fragmentObj;
// }

export default injectIntl(ProductDetail);