import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OnlineUsersComp from "../../../components/schoolManagement/onlineUser"
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getOnlineUsers, getOnlineUsersCount, resetPaginatedData, getAccessToken } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { getSubdomain } from '../../modal/modalBody/settings/CompanyInfo/action';
import { sendResetMail } from '../../Forgot/action';
import { FormattedMessage } from 'react-intl';

class OnlineUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.schoolManagement.portalUsers' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
        name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
      },
      {
        name: <FormattedMessage id='onlineUser.text' defaultMessage='' />,
      }
    ]})
    let self = this;
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 200,
    };
    fetchPaginationDataIfNeeded("getOnlineUsers", "onlineUserList", this.props, payload);
    fetchDataIfNeeded("getOnlineUsersCount", "onlineUserCount", this.props, payload);
    this.props.getSubdomain(payload, function (stat) { self.setState({ isSubDomainLoaded: stat }) });
  }
  componentWillReceiveProps(props) {
    if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
      this.setState({
        subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
      });
    }
  }


  render() {
    return (
      <OnlineUsersComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></OnlineUsersComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    onlineUserList: state.school.onlineUserList,
    onlineUserCount: state.school.onlineUserCount,
    subdomain: state.common.subdomain,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOnlineUsers, getOnlineUsersCount,
      resetPaginatedData,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getAccessToken,
      getSubdomain,
      sendResetMail
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OnlineUsers);
