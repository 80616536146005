import axios from 'axios';
import config from '../../../../../../config/environmentConfig';
import { FINANCE_ACTION_LIST, COMMON_ACTIONS } from '../../../../../../static/constants';
import { showToasterMessage, getFinancialYear, formatDateForBackend, getCurrentDateForBackend } from '../../../../../../utils';
const { lang } = require('../../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const findMatchingInvoice = (payload, props) => {
  let uri = `customerInvoice/findMatchingInvoice?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`;
  if (payload.txType === 'Purchase Invoice') {
    uri = `supplierInvoice/findMatchingInvoice?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`;
  } else if (payload.txType === 'Business Expense') {
    uri = `relationshipExpense/findMatchingInvoice?relationshipId=${payload.relationshipId}`;
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/${uri}&startDate=${payload.startDate}&endDate=${payload.endDate}&maxAmount=${payload.maxAmount}&minAmount=${payload.minAmount}&projectName=${payload.projectName}&pageSize=${payload.pageSize || 10}&pageNumber=${(payload.pageNumber || 1)-1}`)
      .then(res => {
        dispatch({ type: FINANCE_ACTION_LIST.FIND_TX_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{

        }
      })
  }
}

export const getMatchingInvoiceCount = (payload, props) => {
  let uri = `customerInvoice/countMatchingInvoice?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`;
  if (payload.txType === 'Purchase Invoice') {
    uri = `supplierInvoice/countMatchingInvoice?relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`;
  } else if (payload.txType === 'Business Expense') {
    uri = `relationshipExpense/countMatchingExpense?relationshipId=${payload.relationshipId}`;
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/${uri}&startDate=${payload.startDate}&endDate=${payload.endDate}&maxAmount=${payload.maxAmount}&minAmount=${payload.minAmount}&projectName=${payload.projectName}`)
      .then(res => {
        dispatch({ type: FINANCE_ACTION_LIST.FIND_TX_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{

        }
      })
  }
}

export const resetFindTransactions = () => {
  return dispatch => {
    dispatch({ type: FINANCE_ACTION_LIST.RESET_FIND_TX_LIST });
  }
}

export const createMatchedPayment = (paymentList, props) => {
  const payload = _getPaymentData(paymentList, props);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerPayment/saveBatchPayment`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.hideModal();
        props.getBankTransactions({ institutionId: (props.activeAccount || {}).institutionId, relationshipId: (props.companyInfo || {}).relationshipId });
        let data = {
          relationshipId: props.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
          companyInfo: props.companyInfo
        }
        props.fetchCustomerPayments && props.fetchCustomerPayments(data)(dispatch);
        showToasterMessage({ messageType: 'success',  description: lang['common.saved.success'] });
        props.fetchSalesInvoices && props.fetchSalesInvoices(data)(dispatch);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

const _getPaymentData = (paymentList, props) => {
  let payloadList = []
  
  let createdByUserId = props.userInfo.userId || 0;
  let relationshipId = props.companyInfo.relationshipId || 0;
  let finYear = getFinancialYear(props.companyInfo);

  (paymentList || []).forEach((payloadData) => {
    let payload = {};
    let customerName = payloadData.customerName;
    payload.paymentDate = formatDateForBackend(new Date() );
    payload.currencyCode = payloadData.currencyCode || props.companyInfo.currencyCode;
    payload.createdByEmpId = props.userInfo && props.userInfo.relationshipEmployeeId;
    payload.createdByUserId = createdByUserId;
  
    let txList = {
      fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
      relationshipId: relationshipId,
      dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      txType: 'Payment',
      txDate: formatDateForBackend(new Date() ),
      accountingEntry: 'CREDIT',
      invoiceMasterId: payloadData.invoiceMasterId,
      remarks: payloadData.remarks,
      amount: Number(payloadData.payment),
      customerId: payloadData.customerId,
      customerName: customerName,
    };
  
    var txList1 = {
      yStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
      relationshipId: relationshipId,
      dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      txType: 'Payment',
      txDate: formatDateForBackend(new Date() ),
      accountingEntry: 'DEBIT',
      invoiceMasterId: payloadData.invoiceMasterId,
      remarks: payloadData.remarks,
      amount: Number(payloadData.payment),
      customerId: payloadData.customerId,
      customerName: customerName,
    };
  
    var customerTxPayment = {
      relationshipId: relationshipId,
      txAmount: Number(payloadData.payment),
      paymentModeDetail: payloadData.paymentModeDetail,
      fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
      status: 'Processed',
      statusColor: 'success',
      txDate: formatDateForBackend(new Date() ),
      txType: 'Payment',
      invoiceNumber: payloadData.invoiceNumber,
      invoiceMasterId: payloadData.invoiceMasterId,
      isMultiCurrency: payloadData.isMultiCurrency,
      exchangeRate: payloadData.exchangeRate,
      remarks: payloadData.remarks,
      invoiceDueDate: payloadData.invoiceDueDate,
      dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      customerId: payloadData.customerId,
      soMasterId: payloadData.salesOrderMasterId,
    }
    payload.customerId = payloadData.customerId;
    payload.fyStartDate = finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate);
    payload.fyEndDate = finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate);
    payload.remarks = payloadData.remarks;
    payload.soNumber = payloadData.soNumber;
  
    payload.coaTxPaymentsList = [txList, txList1];
    payload.txDate = formatDateForBackend(new Date() );
    payload.customerTxPaymentList = [customerTxPayment];
  
    payload.invoiceAmount = payloadData.invoiceTotalAmount;
    payload.relationshipId = relationshipId;
    payload.chartOfAccountDetailsId = payloadData.chartOfAccountDetailsId;
    payload.txType = 'Payment';
    payload.remarks = payloadData.remarks;
    payload.invoiceMasterId = payloadData.invoiceMasterId;
    payload.status = "Processed";
    payload.statusColor = "success";
    payload.invoiceNumber = payloadData.invoiceNumber;
    payload.paymentAmount = Number(payloadData.payment);
    payload.isMultiCurrency = payloadData.isMultiCurrency;
    payload.currencyCode = payloadData.isMultiCurrency ? payloadData.foreignCurrency : props.companyInfo.currencyCode;
    payload.exchangeRate = payloadData.exchangeRate;
    payload.templateCode = payloadData.templateCode;
    payload.relationshipName = props.companyInfo.storeName;
    payload.projectNumber = payloadData.projectNumber;
    payload.projectName = payloadData.projectName;
    payload.projectMasterId = payloadData.projectMasterId;
    payload.customerName = customerName;
    payload.rfqNumber = payloadData.rfqNumber;
    payload.rfqMasterId = payloadData.rfqMasterId;
    payload.salesQuotationMasterId = payloadData.salesQuotationMasterId;
    payload.quotationNumber = payloadData.quotationNumber;
    payload.transactionId = props.transactionData.transactionId;
    payload.institutionName = props.transactionData.institutionName;
    payload.paymentChannel = props.transactionData.paymentChannel;
    payload.merchantName = props.transactionData.merchantName;
    payloadList.push(payload);
  })
 

  return payloadList;
}

export const createMatchedPaymentForSupplier = (paymentList, props) => {
  const payload = _getSupplierPaymentData(paymentList, props);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierPayment/saveBatchPayment`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.hideModal();
        props.getBankTransactions({ institutionId: (props.activeAccount || {}).institutionId, relationshipId: (props.companyInfo || {}).relationshipId });
        let data = {
          relationshipId: props.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
          companyInfo: props.companyInfo
        }
        props.fetchSupplierPayments && props.fetchSupplierPayments(data)(dispatch);
        showToasterMessage({ messageType: 'success',  description: lang['common.saved.success'] });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

const _getSupplierPaymentData = (paymentList, props) => {
  let payloadList = []
  
  let createdByUserId = props.userInfo.userId || 0;
  let relationshipId = props.companyInfo.relationshipId || 0;
  let finYear = getFinancialYear(props.companyInfo);

  (paymentList || []).forEach((payloadData) => {
    let payload = {};
    let supplierName = payloadData.supplierName;
    payload.paymentDate = formatDateForBackend(new Date() );
    payload.currencyCode = payloadData.currencyCode || props.companyInfo.currencyCode;
    payload.createdByEmpId = props.userInfo && props.userInfo.relationshipEmployeeId;
    payload.createdByUserId = createdByUserId;
  
    let txList = {
      fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
      relationshipId: relationshipId,
      dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      txType: 'Payment',
      txDate: formatDateForBackend(new Date() ),
      accountingEntry: 'CREDIT',
      invoiceMasterId: payloadData.invoiceMasterId,
      expenseMasterId: payloadData.expenseMasterId,
      remarks: payloadData.remarks,
      amount: Number(payloadData.payment),
      supplierId: payloadData.supplierId,
      supplierName: supplierName,
    };
  
    var txList1 = {
      yStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
      relationshipId: relationshipId,
      dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      txType: 'Payment',
      txDate: formatDateForBackend(new Date() ),
      accountingEntry: 'DEBIT',
      invoiceMasterId: payloadData.invoiceMasterId,
      remarks: payloadData.remarks,
      amount: Number(payloadData.payment),
      supplierId: payloadData.supplierId,
      supplierName: supplierName,
      expenseMasterId: payloadData.expenseMasterId,
    };
  
    var customerTxPayment = {
      relationshipId: relationshipId,
      txAmount: Number(payloadData.payment),
      paymentModeDetail: payloadData.paymentModeDetail,
      fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
      status: 'Processed',
      statusColor: 'success',
      txDate: formatDateForBackend(new Date() ),
      txType: 'Payment',
      txNumber: payloadData.invoiceNumber,
      invoiceMasterId: payloadData.invoiceMasterId,
      isMultiCurrency: payloadData.isMultiCurrency,
      exchangeRate: payloadData.exchangeRate,
      remarks: payloadData.remarks,
      invoiceDueDate: payloadData.invoiceDueDate,
      dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      supplierId: payloadData.supplierId,
      supplierName: supplierName,
      expenseMasterId: payloadData.expenseMasterId,
      poMasterId: payloadData.poMasterId,
    }
    payload.supplierId = payloadData.supplierId;
    payload.expenseMasterId= payloadData.expenseMasterId;
    payload.fyStartDate = finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate);
    payload.fyEndDate = finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate);
    payload.remarks = payloadData.remarks;
    payload.poNumber = payloadData.poNumber;
  
    payload.coaTxPaymentList = [txList, txList1];
    payload.txDate = formatDateForBackend(new Date() );
    payload.customerTxPaymentList = [customerTxPayment];
  
    payload.invoiceAmount = payloadData.invoiceTotalAmount;
    payload.relationshipId = relationshipId;
    payload.chartOfAccountDetailsId = payloadData.chartOfAccountDetailsId;
    payload.txType = 'Payment';
    payload.remarks = payloadData.remarks;
    payload.invoiceMasterId = payloadData.invoiceMasterId;
    payload.status = "Processed";
    payload.statusColor = "success";
    payload.txNumber = payloadData.invoiceNumber;
    payload.paymentAmount = Number(payloadData.payment);
    payload.isMultiCurrency = payloadData.isMultiCurrency;
    payload.currencyCode = payloadData.isMultiCurrency ? payloadData.foreignCurrency : props.companyInfo.currencyCode;
    payload.exchangeRate = payloadData.exchangeRate;
    payload.templateCode = payloadData.templateCode;
    payload.relationshipName = props.companyInfo.storeName;
    payload.projectNumber = payloadData.projectNumber;
    payload.projectName = payloadData.projectName;
    payload.projectMasterId = payloadData.projectMasterId;
    payload.supplierName = supplierName;
    payload.rfqNumber = payloadData.rfqNumber;
    payload.rfqMasterId = payloadData.rfqMasterId;
    payload.transactionId = props.transactionData.transactionId;
    payload.institutionName = props.transactionData.institutionName;
    payload.paymentChannel = props.transactionData.paymentChannel;
    payload.merchantName = props.transactionData.merchantName;
    payloadList.push(payload);
  })
 

  return payloadList;
}