import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Button } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import LoadingIcon from '../../../../assets/images/loading.gif';


const HeaderPackage = (props) => {
  // const [templateDropdown, updateTemplateDropdown] = useState(false);

//   const useOuterClick = (callback) => {
//     const innerRef = useRef();
//     const callbackRef = useRef();

//     // set current callback in ref, before second useEffect uses it
//     useEffect(() => { // useEffect wrapper to be safe for concurrent mode
//         callbackRef.current = callback;
//     });

//     useEffect(() => {
//         document.addEventListener("click", handleClick);
//         return () => document.removeEventListener("click", handleClick);

//         // read most recent callback and innerRef dom node from refs
//         function handleClick(e) {
//             if (
//                 innerRef.current &&
//                 callbackRef.current &&
//                 !innerRef.current.contains(e.target)
//             ) {
//                 callbackRef.current(e);
//             }
//         }
//     }, []); // no need for callback + innerRef dep

//     return innerRef; // return ref; client can omit `useRef`
// }

// const innerRef = useOuterClick(e => {
//    updateTemplateDropdown(!templateDropdown)
// });

  return (
    <>
     <div className='left-itms'>
      <ul>
        <li className='title'>
          Shipment
        </li>
        <li className='sep' />
        <li>
          <FormattedMessage id='Shipment #' defaultMessage='' /> &nbsp; - &nbsp;
          {props.nextTxNumber ?
            <span>{getFormatedNextTxNumber(props)}</span>
            :
            <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />
          }
        </li>
      </ul>
     </div>
    <div className='right-itms'>
      

                
    {/* { props.type === 'new' ? 
                  <Dropdown
                    style={{ width: '200px' }}
                    items={props.customers[props.pageNumber || 1] || []}
                    valueKeyName='companyName'
                    optionKeyName='customerId'
                    value={(props.customerDetail || {} || {}).companyName}
                    onSearch={(searchedText) => {
                      props.fetchCustomers({
                        isCompact: true,
                        searchedText: searchedText,
                        pageNumber: 1,
                        hideSpinner: true,
                        pageSize: 100,
                        relationshipId: (props.companyInfo || {}).relationshipId
                      })
                    }}
                    placeholder={intl.formatMessage({
                      id: 'customer.salesOrder.form.customer.placeholder', defaultMessage: ''
                    })}
                    onSelect={
                      (customerId, data) => {
                        props.updateState({
                          customerId,
                          customerName: data.name,
                          shippingAddress: null,
                          billingAddress: null
                        });
                        props.fetchCustomerDetailsByCustomerId({
                          customerId,
                          relationshipId: (props.companyInfo || {}).relationshipId
                        });
                        props.fetchAddresses({customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.SHIPPING_ADDRESS});
                        props.fetchAddresses({customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.BILLING_ADDRESS});
                        props.fetchSoDataForShipment({
                          customerId,
                          relationshipId: (props.companyInfo || {}).relationshipId
                        });
                        props.fetchAllContacts({ customerId, relationshipId: (props.companyInfo || {}).relationshipId });
                      }
                    }
                  /> : <span>{ (props.customerDetail || {}).companyName || ''}</span>
                   } */}
                  {/* {(props.soPackageData || {}).customerName || ''} */}
            
                  <Button onClick={() => {
                        props.updateDrawerState({
                            settingSalesDrawerVisible: true
                        })
                    }} >
                        <i class="fa fa-cog" aria-hidden="true"></i>
                    </Button>
    </div>
    </>
  );
};

const getFormatedNextTxNumber = (props) => {
  // const { customer } = props;
  // if (!customer || !customer.customerId) {
  //   return <span>{props.nextTxNumber}</span>;
  // }
  // return <span>{props.nextTxNumber.replace('SO-00', 'SO-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
  return <span>{props.nextTxNumber}</span>;
}


export default injectIntl(HeaderPackage);
