import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { showToasterMessage } from '../../utils';
import { CustomButton } from './CustomButton';


export const UploadQuality = (props) => {

    const maxFileLength = props.maxFile || 10;
    const [fileList, updateFileList] = useState(props.fileList);
    const maxFileSize = props.maxFileSize || 50;

    const calculateTotlaFileSize = (fileList) => {
        let totalSize = 0;
        fileList.forEach((file) => {
            totalSize += (file.size || file.fileSize) / (1024 * 1024)
        });

        return totalSize;
    }

    return <div>
        <br />
        <div className="remarks-title">
            <input type="file" name="filefield" multiple="multiple" onChange={(e) => {
                const files = e.target.files;
                let currentFileList = fileList ? [...fileList] : [];
                for (let i = 0; i < files.length; i++) {
                    currentFileList.push(files[i]);
                }
                if (currentFileList.length > maxFileLength) {
                    return showToasterMessage({ messageType: 'error', description: `You can only upload ${maxFileLength} files` })
                }
                if (calculateTotlaFileSize(currentFileList) <= maxFileSize) {
                    props.onChange(currentFileList);
                    updateFileList(currentFileList);
                } else {
                    
                    return showToasterMessage({ messageType: 'error', description: `Total files size is exceeding ${maxFileSize} MB` })
                }
            }} />
            <span>Max {maxFileLength} files of {maxFileSize} MB</span>
        </div>
        <br/>
        {fileList && fileList.length ? <div className="remarks-title upoad-file-table">
            <div className=" modal-table-container ">
                <table className="transaction-details-table">
                    <thead>
                        <tr>
                            <th width="10%">#</th>
                            <th>File Name</th>
                            {/* <th>Type</th> */}
                            <th>Size</th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>

                        {fileList.map((e, index) => {
                            let _size = e.size || e.fileSize;
                            let fSExt = ['Bytes', 'KB', 'MB', 'GB'],
                                i = 0; while (_size > 900) { _size /= 1024; i++; }
                            let exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
                            return (<tr key={index}>
                                <td>{index + 1}</td>
                                <td><Link onClick={()=>{
                                    //downloadDataFromAWS(e.bucketName, e.awsKeyName, e.fileName)
                                    props.downloadFileFromBucket({bucketName: e.bucketName,awsKeyName: e.awsKeyName, fileName:e.fileName})
                                }}>{e.name || e.fileName}</Link></td>
                                {/* <td>{e.type || e.fileType}</td> */}
                                <td>{exactSize}</td>
                                <td className="cursor-pointer"><i className="fa fa-trash" onClick={() => {
                                    const currentFileList = fileList.filter((data, fileIndex) => {
                                        return index !== fileIndex;
                                    });

                                    props.onChange(currentFileList);
                                    updateFileList(currentFileList);
                                }} /></td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <br/>
            <div className='customModal-button-group-center'>
               
            <CustomButton
                intlId={'modal.address.button.ok'}
                defaultMessage={''}
                onClick={() => { props.hideModal(); }}
                htmlType='submit'
                key={2}
            />
            </div>
        </div>  : null
        // <div className='customModal-button-group'>
        //         <br />
        //         <CustomButton
        //             intlId={'modal.address.button.cancel'}
        //             defaultMessage={''}
        //             type={'default'}
        //             key={1}
        //             onClick={() => { props.hideModal(); }}
        //         />
        //     </div>
            }
    </div>


}
