import React, { Fragment } from "react";
import { Row, Col, Button } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';

import { CustomAgGrid } from '../../../general/CustomAgGrid';
import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants';
import { showToasterMessage } from '../../../../utils';

class ProductDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sectionVisible: {
                discount: false,
                tax: false,
                expense: false
            },
            total: 0,
            subtotal: 0,
            subtotalWithoutDiscount: 0,
            totalDiscount: 0,
            expenseAmount: 0,
            totalTax: 0,
            itemLevelTaxes: [],
            rootDiscountPercent: 0,
            rootDiscountAmount: 0
        }
    }

    calculateDebitOrCredit = () => {
        let totalDebit = 0;
        let totalCredit = 0;
        this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
            let stringifiedRowData = JSON.stringify(rowNode.data);
            const rowdata = JSON.parse(stringifiedRowData);
            totalDebit += Number(rowdata.debit || 0);
            totalCredit += Number(rowdata.credit || 0);
        });

        this.setState({
            totalDebit,
            totalCredit
        })
    }


    render() {
        const { intl } = this.props;
        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='finance.JournalDetail.ledgerAccount' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
                resizable: true,
                editable: true,
                sortable: true,
                cellEditor: 'customDropDownEditor',
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.allLedgerAccounts,
                        optionKeyName: 'chartOfAccountDetailsId',
                        valueKeyName: 'ledgerAccountName',
                        onSelect: (selectedValue, option) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.chartOfAccountDetailsId = option.key;
                            obj.node.data.ledgerAccountName = selectedValue;
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='finance.JournalDetail.description' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
                cellClass: " ag-cell-description",
                editable: true,
                sortable: true,
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.description || "",
                        isEditorLoaded: this.props.isEditorLoaded,
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value;
                            params.node.data.isEditorLoaded = true;
                            this.props.updateState({
                                isEditorLoaded: true
                            })
                        }
                    }
                },
                cellRendererFramework: (params) => {
                    return ReactHtmlParser(params.data.description)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > 30) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${30}px`;
                        }
                        params.node.setRowHeight(30);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='finance.JournalDetail.debit' defaultMessage='' />;
                },
                field: 'debit',
                editable: true,
                sortable: true,
                cellEditorParams: (obj) => {
                    return {
                        onChange: (val) => {
                            if (val) {
                                obj.data.credit = 0;
                            }
                        }
                    }
                },
                aggFunc: "sum",
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='finance.JournalDetail.credit' defaultMessage='' />;
                },
                field: 'credit',
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        onChange: (val) => {
                            if (val) {
                                obj.data.debit = 0;
                            }
                        }
                    }
                },
                editable: true,
                sortable: true,
                aggFunc: "sum",
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                valueGetter: (params) => {
                    this.calculateDebitOrCredit();
                    return params.data.credit;
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.action' defaultMessage='' />;
                },
                field: 'action', lockPosition: true,
                pinned: 'right',
                width: 50,
                cellRenderer: 'customActionEditor',
                suppressNavigable: true,
                cellRendererParams: (params) => {
                    return {
                        onClick: () => {
                            this.gridApi.updateRowData({ remove: [this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                            if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                        },
                    }
                }
            }
        ];

        const createJournal = () => {
            if (!this.state.totalDebit || !this.state.totalCredit) {
                return showToasterMessage({ messageType: 'error', description: 'Please select debit/credit value for journal' });
            }

            if (this.state.totalDebit && this.state.totalCredit && this.state.totalDebit !== this.state.totalCredit) {
                return showToasterMessage({ messageType: 'error', description: 'Debit and credit amount should be same' });
            }

            let isLedgerInValid = false;
            this.gridApi.forEachNode((rowNode) => {
                const { data } = rowNode
                if (!data.product && (data.debit || data.credit)) {
                    isLedgerInValid = true;
                }
            });

            if (isLedgerInValid) {
                return showToasterMessage({ messageType: 'error', description: 'Please select ledger name' });
            }


            const modalData = {
                modalBody: <FormattedMessage
                    id='finance.journal.form.confirmation'
                    defaultMessage=''
                />,
                handleSubmit: () => {
                    const payload = {
                        props: this.props,
                        gridObject: this.gridApi,
                        state: this.state
                    }
                    this.props.createJournal(payload);
                    this.props.hideModal();
                }
            };
            this.props.showModal(modalData);



        }

        return (
            <Fragment>
                <div>
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        rowData={this.props.customerSalesOrderDetailsList || [{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100%',
                            marginBottom: '10px'
                        }} />
                </div>

                <Row type="flex" justify="end">
                    <Col span={6}>
                        <Button className="grey-button" onClick={
                            () => {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                        }><FormattedMessage id='customer.salesInvoice.form.button.addLine' defaultMessage='' /></Button>
                        <Button className="grey-button" onClick={
                            () => {
                                let gridApi = this.gridApi;
                                this.gridApi.forEachNode(function (rowNode, index) {
                                    gridApi.updateRowData({ remove: [rowNode.data] });
                                    if (gridApi.rowRenderer.lastRenderedRow < 0) {
                                        gridApi.updateRowData({ add: [{}] });
                                    }
                                })
                            }
                        }><FormattedMessage id='customer.salesInvoice.form.button.clearAllLines' defaultMessage='' /></Button>

                        <div className="remarks-title">

                        </div>

                        <div className="remarks-title">
                            <FormattedMessage id='finance.JournalDetail.narration' defaultMessage='' />
                        </div>

                        <textarea value={this.props.narration || ''} className="description-textarea" rows={4} onChange={(e) => {
                            this.props.updateState({
                                narration: e.target.value
                            })
                        }} placeholder={intl.formatMessage(
                            { id: 'finance.JournalDetail.narration', defaultMessage: '' }
                        )}></textarea>
                    </Col>

                    <Col span={6} offset={6}>
                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "} {this.state.totalDebit || ''}
                    </Col>
                    <Col span={6}>
                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "} {this.state.totalCredit || ''}
                    </Col>
                </Row>

                <Row type="flex">
                    <Col span={3}>
                        <Button className="ant-btn-primary" onClick={() => {
                            createJournal();
                        }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                    </Col>

                    <Col span={2} offset={19}>
                        <Button className="grey-button" onClick={() => {
                            this.props.history.goBack();
                        }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                    </Col>
                </Row>
            </Fragment>
        )
    }
};

export default injectIntl(ProductDetails);