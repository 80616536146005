import { CUSTOMER_ACTION_LIST, CONSTANTS } from '../static/constants';
const initialState = {
  customers: {
    1: []
  },
  customerLedger: [],
  openOrderList: {
    1: []
  },
  unpaidInvoiceList: {
    1: []
  },
  paidInvoiceList: {
    1: []
  },
  totalOrderList: {
    1: []
  },
  transactionsList: {
    1: []
  },
  customerRfqList: {
    1: []
  },
  customerSqList: {
    1: []
  },
  salesOrderTransactionsList: {
    1: []
  },
  invoiceList: {
    1: []
  },
  creditMemoList: {
    1: []
  },
  paymentList: {
    1: []
  },
  customerPurchaseItems: {
    1: []
  },
  commuincationList: [],
  addressList: [],
  taskList: [],
  activitiesList: [],
  paymentListCount: 0,
  creditMemoListCount: 0,
  invoiceListCount: 0,
  salesOrderTransactionsCount: 0,
  transactionsCount: 0,
  customerRfqCount: 0,
  customerSqCount: 0,
  customerCount: 0,
  customerOpenOrderCount: 0,
  customerOpenSOCount: 0,
  customerUnpaidBalance: 0,
  customerSalesRevenue: 0,
  openOrderListCount: 0,
  unpaidInvoiceListCount: 0,
  paidInvoiceListCount: 0,
  totalOrderListCount: 0,
  addresses: {
    [CONSTANTS.SHIPPING_ADDRESS]: [],
    [CONSTANTS.BILLING_ADDRESS]: []
  },
  contacts: [],
  users: [],
  activityPriorities: [],
  activityStatus: [],
  activityTypes: [],
  memberCount: 0,
  customerInvoiceLedgerSummaryList: [],
  customerPurchaseItemsCount: 0,
  customerActivityTrackList: [],
  customerContacts: [],
  supplierContacts: [],
  customerTotalOrderCount:0,
  customerPaidBalance:0,
  paidInvoiceData:{},
  subscribers: []

}

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_ACTION_LIST.CUSTOMER_LIST:
      return {
        ...state,
        customers: {
          ...state.customers,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.CUSTOMER_COUNT:
      return { ...state, customerCount: action.data };
    case CUSTOMER_ACTION_LIST.CUSTOMER_LIST_PAGE_RESET:
      return { ...state, customers: initialState.customers };
    // case CUSTOMER_ACTION_LIST.OPEN_ORDER_LIST_PAGE_RESET:
    //   return { ...state, openOrderList: initialState.openOrderList };
    case CUSTOMER_ACTION_LIST.CUSTOMER_PAID_INVOICES_RESET:
      return { ...state, paidInvoiceList: initialState.paidInvoiceList };
    case CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_INVOICES_RESET:
      return { ...state, unpaidInvoiceList: initialState.unpaidInvoiceList };
    // case CUSTOMER_ACTION_LIST.TOTAL_ORDER_LIST_PAGE_RESET:
    //   return { ...state, unpaidInvoiceList: initialState.totalOrderList };
    case CUSTOMER_ACTION_LIST.CUSTOMER_OPEN_ORDER_COUNT:
      return { ...state, customerOpenOrderCount: action.data }
    case CUSTOMER_ACTION_LIST.CUSTOMER_OPEN_SO_COUNT:
      return { ...state, customerOpenSOCount: action.data }
    case CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_BALANCE:
      return { ...state, customerUnpaidBalance: action.data }
    case CUSTOMER_ACTION_LIST.CUSTOMER_LEDGER_LIST:
      return { ...state, customerLedger: action.data }
    case CUSTOMER_ACTION_LIST.RESET_CUSTOMER_LEDGER_LIST:
      return { ...state, customerLedger: initialState.customerLedger };
    case CUSTOMER_ACTION_LIST.CUSTOMER_SALES_REVENUE:
      return { ...state, customerSalesRevenue: action.data }
    case CUSTOMER_ACTION_LIST.CUSTOMER_OPEN_ORDER:
      return {
        ...state,
        openOrderList: {
          ...state.openOrderList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.CUSTOMER_OPEN_ORDER_COUNT_BY_CUSTOMER_ID:
      return { ...state, openOrderListCount: action.data }
    case CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_INVOICES_BY_CUSTOMER:
      return {
        ...state,
        unpaidInvoiceList: {
          ...state.unpaidInvoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.CUSTOMER_UNPAID_INVOICES_COUNT_BY_CUSTOMER:
      return { ...state, unpaidInvoiceListCount: action.data }
    case CUSTOMER_ACTION_LIST.CUSTOMER_PAID_INVOICES_BY_CUSTOMER:
      return {
        ...state,
        paidInvoiceList: {
          ...state.paidInvoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.CUSTOMER_PAID_INVOICES_COUNT_BY_CUSTOMER:
      return { ...state, paidInvoiceListCount: action.data }
    case CUSTOMER_ACTION_LIST.CUSTOMER_TOTAL_ORDER:
      return {
        ...state,
        totalOrderList: {
          ...state.totalOrderList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.CUSTOMER_TOTAL_ORDER_COUNT_BY_R_ID:
      return { ...state, totalOrderListCount: action.data }
    case CUSTOMER_ACTION_LIST.MEMBER_COUNT:
      return { ...state, memberCount: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_BILLING_ADDRESSES:
      return { ...state, addresses: { ...state.addresses, [CONSTANTS.BILLING_ADDRESS]: action.data } };
    case CUSTOMER_ACTION_LIST.FETCH_SHIPPING_ADDRESSES:
      return { ...state, addresses: { ...state.addresses, [CONSTANTS.SHIPPING_ADDRESS]: action.data } };
    case CUSTOMER_ACTION_LIST.ADD_SHIPPING_ADDRESS: {
      state.addresses[CONSTANTS.SHIPPING_ADDRESS].push({ locationId: action.data });
      return { ...state, addresses: { ...state.addresses, [CONSTANTS.SHIPPING_ADDRESS]: Object.assign([], state.addresses[CONSTANTS.SHIPPING_ADDRESS]) } };
    }
    case CUSTOMER_ACTION_LIST.ADD_BILLING_ADDRESS: {
      state.addresses[CONSTANTS.BILLING_ADDRESS].push({ locationId: action.data });
      return { ...state, addresses: { ...state.addresses, [CONSTANTS.BILLING_ADDRESS]: Object.assign([], state.addresses[CONSTANTS.BILLING_ADDRESS]) } };
    }
    case CUSTOMER_ACTION_LIST.FETCH_CONTACTS:
      return { ...state, contacts: action.data };
    case CUSTOMER_ACTION_LIST.ADD_CONTACT: {
      state.contacts.push({ contactId: action.data });
      return { ...state, contacts: Object.assign([], state.contacts) };
    }
    case CUSTOMER_ACTION_LIST.FETCH_USERS:
      return { ...state, users: action.data || [] };
    case CUSTOMER_ACTION_LIST.FETCH_ACTIVITY_PRIORITIES:
      return { ...state, activityPriorities: action.data || [] };
    case CUSTOMER_ACTION_LIST.ADD_ACTIVITY_PRIORITIES:
      return { ...state, activityPriorities: [action.data, ...state.activityPriorities] }
    case CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_PRIORITIES:
      return { ...state, activityPriorities: state.activityPriorities.filter(x => x.activityPriorityId !== action.data.id) }
    case CUSTOMER_ACTION_LIST.FETCH_ACTIVITY_STATUS:
      return { ...state, activityStatus: action.data || [] };
    case CUSTOMER_ACTION_LIST.ADD_ACTIVITY_STATUS:
      return { ...state, activityStatus: [action.data, ...state.activityStatus] }
    case CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_STATUS:
      return { ...state, activityStatus: state.activityStatus.filter(x => x.activityStatusId !== action.data.id) }
    case CUSTOMER_ACTION_LIST.FETCH_ACTIVITY_TYPES:
      return { ...state, activityTypes: action.data || [] };
    case CUSTOMER_ACTION_LIST.ADD_ACTIVITY_TYPES:
      return { ...state, activityTypes: [action.data, ...state.activityTypes] }
    case CUSTOMER_ACTION_LIST.DELETE_ACTIVITY_TYPES:
      return { ...state, activityTypes: state.activityTypes.filter(x => x.activityTypeId !== action.data.id) }
    case CUSTOMER_ACTION_LIST.FETCH_TRANSACTIONS:
      return {
        ...state, transactionsList: {
          ...state.transactionsList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.FETCH_RFQ_TRANSACTIONS:
      return {
        ...state, customerRfqList: {
          ...state.customerRfqList,
          [action.data.pageNo || 1]: action.data.list
        }
      };

    case CUSTOMER_ACTION_LIST.FETCH_SQ_TRANSACTIONS:
      return {
        ...state, customerSqList: {
          ...state.customerSqList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.FETCH_RFQ_TRANSACTIONS_COUNT:
      return { ...state, customerRfqCount: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_SQ_TRANSACTIONS_COUNT:
      return { ...state, customerSqCount: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_TRANSACTIONS_COUNT:
      return { ...state, transactionsCount: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_SALES_ORDER_TRANSACTIONS:
      return {
        ...state, salesOrderTransactionsList: {
          ...state.salesOrderTransactionsList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.FETCH_SALES_ORDER_TRANSACTIONS_COUNT:
      return { ...state, salesOrderTransactionsCount: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_INVOICE_LIST:
      return {
        ...state, invoiceList: {
          ...state.invoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.FETCH_INVOICE_LIST_COUNT:
      return { ...state, invoiceListCount: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_CREDIT_MEMO_LIST:
      return {
        ...state, creditMemoList: {
          ...state.creditMemoList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.FETCH_CREDIT_MEMO_LIST_COUNT:
      return { ...state, creditMemoListCount: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_PAYMENT_LIST:
      return {
        ...state, paymentList: {
          ...state.paymentList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.FETCH_PAYMENT_LIST_COUNT:
      return { ...state, paymentListCount: action.data };
    case CUSTOMER_ACTION_LIST.TRANSACTIONS_RESET:
      return { ...state, transactionsList: initialState.transactionsList };
    // case CUSTOMER_ACTION_LIST.FETCH_RFQ_TRANSACTIONS:
    //   return { ...state, customerRfqList: initialState.customerRfqList };
    // case CUSTOMER_ACTION_LIST.FETCH_SQ_TRANSACTIONS:
    //   return { ...state, customerRfqList: initialState.customerSqList };
    case CUSTOMER_ACTION_LIST.INVOICE_TRANSACTIONS_RESET:
      return { ...state, invoiceList: initialState.invoiceList };
    case CUSTOMER_ACTION_LIST.PAYMENT_TRANSACTIONS_RESET:
      return { ...state, paymentList: initialState.paymentList };
    case CUSTOMER_ACTION_LIST.CREDIT_MEMO_TRANSACTIONS_RESET:
      return { ...state, creditMemoList: initialState.creditMemoList };
    case CUSTOMER_ACTION_LIST.SALES_ORDER_TRANSACTIONS_RESET:
      return { ...state, salesOrderTransactionsList: initialState.salesOrderTransactionsList };
    case CUSTOMER_ACTION_LIST.FETCH_ACTIVITIES:
      return { ...state, activitiesList: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_COMMUINACTIONS:
      return { ...state, commuincationList: action.data };
    case CUSTOMER_ACTION_LIST.RESET_COMMUNICATIONS:
      return { ...state, commuincationList: initialState.commuincationList }
    case CUSTOMER_ACTION_LIST.RESET_CONTACTS:
      return { ...state, contacts: initialState.contacts }
    case CUSTOMER_ACTION_LIST.RESET_ADDRESS:
      return { ...state, addresses: initialState.addresses }
    case CUSTOMER_ACTION_LIST.COMMUNICATION_ADDED: {
      let updatedTaskIndex = -1;
      const commuincationList = Object.assign([], state.commuincationList);
      commuincationList.forEach((obj, index) => {
        if (obj.customerCommunicationTxId === action.data.customerCommunicationTxId) {
          updatedTaskIndex = index;
        }
      })
      if (updatedTaskIndex > -1) {
        commuincationList[updatedTaskIndex] = action.data;
      } else {
        commuincationList.splice(0, 0, action.data);
      }

      return { ...state, commuincationList: commuincationList }
    }

    case CUSTOMER_ACTION_LIST.COMMUNICATION_DELETED: {
      return { ...state, commuincationList: state.commuincationList.filter(x => x.customerCommunicationTxId !== action.data.customerCommunicationTxId) }
    }

    case CUSTOMER_ACTION_LIST.ACTIVITY_ADDED: {
      let updatedTaskIndex = -1;
      const activitiesList = Object.assign([], state.activitiesList);
      activitiesList.forEach((obj, index) => {
        if (obj.activityId === action.data.activityId) {
          updatedTaskIndex = index;
        }
      })
      if (updatedTaskIndex > -1) {
        activitiesList[updatedTaskIndex] = action.data;
      } else {
        activitiesList.splice(0, 0, action.data);
      }

      return { ...state, activitiesList: activitiesList }
    }
    case CUSTOMER_ACTION_LIST.DELETE_ACTIVITY: {
      return { ...state, activitiesList: state.activitiesList.filter(x => x.activityId !== action.data.activityId) }
    }
    case CUSTOMER_ACTION_LIST.FETCH_TASKS:
      return { ...state, taskList: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_ADDRESSES:
      return { ...state, addressList: action.data };
    case CUSTOMER_ACTION_LIST.ADD_UPDATE_TASK: {
      let updatedTaskIndex = -1;
      state.taskList.forEach((obj, index) => {
        if (obj.taskWidgetId === action.data.taskWidgetId) {
          updatedTaskIndex = index;
        }
      })
      if (updatedTaskIndex > -1) {
        state.taskList[updatedTaskIndex] = action.data;
      } else {
        state.taskList.push(action.data);
      }

      return { ...state, taskList: Object.assign([], state.taskList) }
    }
    case CUSTOMER_ACTION_LIST.DELETE_TASK: {
      return { ...state, taskList: state.taskList.filter(x => x.taskWidgetId !== action.data.taskWidgetId) }
    }
    case CUSTOMER_ACTION_LIST.ADD_CUSTOMER_BO_LOCATION: {
      let customers = state.customers[action.formData.pageNumber] || [];
      let customerIndex = -1;
      customers.forEach((customer, index) => {
        if (customer.customerId === action.formData.customerId) {
          customerIndex = index;
        }
      })
      if (customerIndex > -1) {
        let boLocationList = customers[customerIndex].boLocationList || [];
        boLocationList.push(action.data);
        customers[customerIndex].boLocationList = boLocationList;
      }
      state.customers[action.formData.pageNumber] = customers;
      return {
        ...state,
        customers: state.customers
      };
    }
    case CUSTOMER_ACTION_LIST.ADD_CUSTOMER_BO_CONTACT: {
      let customers = state.customers[action.formData.pageNumber] || [];
      let customerIndex = -1;
      customers.forEach((customer, index) => {
        if (customer.customerId === action.formData.customerId) {
          customerIndex = index;
        }
      })
      if (customerIndex > -1) {
        let boContactList = customers[customerIndex].boContactList || [];
        boContactList.push(action.data);
        customers[customerIndex].boContactList = boContactList;
      }
      state.customers[action.formData.pageNumber] = customers;
      return {
        ...state,
        customers: state.customers
      };
    }

    case CUSTOMER_ACTION_LIST.CUSTOMER_INVOICE_LEDGER_SUMMARY_LIST:
      return { ...state, customerInvoiceLedgerSummaryList: action.data }

    case CUSTOMER_ACTION_LIST.CUSTOMER_PURCHASE_ITEMS:
      return {
        ...state, customerPurchaseItems: {
          ...state.customerPurchaseItems,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CUSTOMER_ACTION_LIST.COUNT_CUSTOMER_PURCHASE_ITEMS:
      return { ...state, customerPurchaseItemsCount: action.data };

    case CUSTOMER_ACTION_LIST.CUSTOMER_ACTIVITIES_TRACK_LIST:
      return { ...state, customerActivityTrackList: action.data.list };

      case CUSTOMER_ACTION_LIST.FETCH_CUSTOMER_CONTACTS:
      return { ...state, customerContacts: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_SUPPLIER_CONTACTS:
      return { ...state, supplierContacts: action.data };
    case CUSTOMER_ACTION_LIST.CUSTOMER_TOTAL_ORDER_COUNT_BY_CUSTOMER_ID:
      return { ...state, customerTotalOrderCount: action.data };
    case CUSTOMER_ACTION_LIST.CUSTOMER_PAID_BALANCE:
      return { ...state, customerPaidBalance: action.data }
    case CUSTOMER_ACTION_LIST.PAID_INVOICE_Data:
      return { ...state, paidInvoiceData: action.data };
    case CUSTOMER_ACTION_LIST.FETCH_SUBSCRIBERS:
      return { ...state, subscribers: action.data };
    default:
      return state;
  }
};

export default CustomerReducer;