import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { popModalFromStack } from './../../../../../actions/commonActions';
import TotalSentComponent from '../../../../../components/modal/modalBody/EmailMarketing/TotalSent';
import { fetchTotalSend } from './action';

class TotalSentCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pageSize: 25,
          pageNumber: 1,
     
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            // pageNumber: 1,
            // pageSize: 25
        }
        this.props.fetchTotalSend(payload)

    }

    render() {
        return <TotalSentComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
    
      sendStatusDetails: state.communicationReducer.sendStatusDetails,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchTotalSend,
      
    popModalFromStack,
   
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TotalSentCon);