import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CustomButton } from '../../general/CustomButton';
import { Input } from 'antd';

class EasyPostIntegrateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };


    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content employee-modal-control">

            <Form.Item label={(<span><FormattedMessage id='apiKey.text' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('apiKey', {
                rules: [
                  {
                    required: true,
                    message: 'API keys is required.',
                    initialValue: this.props.apiKey || ''
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'apiKey.text', defaultMessage: '' })} maxLength={150}
                onChange={e => {
                  this.setState({ apiKey: e.target.value });
                }}
              />)}
            </Form.Item>

            {/* <Form.Item label={(<span><FormattedMessage id='wallmart.secretKey.text' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('secretKey', {
                rules: [
                  {
                    required: true,
                    message: 'Secret key is required.',
                    initialValue: this.props.secretKey || '',
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'wallmart.secretKey.text', defaultMessage: '' })} maxLength={150}
                onChange={e => {
                  this.setState({ secretKey: e.target.value });
                }} />)}
                
            </Form.Item> */}
            <Form.Item label={(<span></span>)} colon={false}>
            <a rel="noopener noreferrer"  href="https://www.easypost.com/account/settings?tab=api-keys" target="_blank">How to get credentials ?</a>
            </Form.Item> 

          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.popModalFromStack(); }}
              />
              <CustomButton
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...this.props.data,
          apiKey: values.apiKey,
          secretKey: values.secretKey,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          carrierName: 'easyPost'
        }

        const modalData = {
          modalBody: <FormattedMessage id='save.confirmation' defaultMessage='' />,
          handleSubmit: () => {
            this.props.saveEasyPostConfiguration(payload, (this.props.data || {}).formData);
            this.props.handleSubmit && this.props.handleSubmit();
            this.props.popModalFromStack();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'easy_post_integrate_component' })(injectIntl(EasyPostIntegrateComponent));
