import React, { Fragment } from 'react';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, DatePicker } from 'antd';
import {  injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import { Dropdown } from '../../../../general/Dropdown';
// import config from '../../../../../config/environmentConfig';
import { TextBox } from "../../../../general/TextBox";
import { CONSTANTS, MODAL_TYPE } from '../../../../../static/constants';
// import LoadingIcon from '../../../../../assets/images/loading.gif';
// import { LogoComponent } from '../../../../general/LogoComponent';
import { getMomentDateForUI } from '../../../../../utils';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class ExpenseDetails extends React.Component {
    handleNewProjectAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PROJECT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;
        switch (modalType) {
            case MODAL_TYPE.PROJECT: {
                deleteFunc = props.deleteProject;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId, customerId: props.customer.customerId });
            },
        };
        showModal(modalData);
    }

    render() {
        // const relationshipBillingAddress = this.props.relationshipBillingAddress || find((this.props.companyInfo || {}).boLocationList, { isDefault: 1 }) || {};
        const getAddressInfoFragment = (obj =this.props.relationshipBillingAddress || find((this.props.companyInfo || {}).boLocationList, { isDefault: 1 }) || {}) => {
            const fragmentObj = (
              <Fragment>
                {obj.streetAddress1 ? (
                  <div className="billing-address">{obj.streetAddress1}</div>
                ) : (
                  ""
                )}
                {obj.streetAddress2 ? (
                  <div className="billing-address">{obj.streetAddress2}</div>
                ) : (
                  ""
                )}
                {obj.cityName ? (
                  <div className="billing-address">
                    {`${obj.cityName || ""}${obj.stateName ? ", " + obj.stateName : ""}${
                      obj.zipCode ? ", " + obj.zipCode : ""
                    }`}
                  </div>
                ) : (
                  ""
                )}
              </Fragment>
            );
          
            return fragmentObj;
          };
          
        return (
            <Fragment>
                <Row>
                    <Col span={5}>
                    {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                        {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 + '/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()} /> */}
                        <Row gutter={[0,3]}>
                        <Col span={24} className="details-heading">
                            Organization Information
                        </Col>
                        <Col span={24}>
                            <div className="details-heading-subheading">
                            <FormattedMessage
                                id="sales.order.create.organization.name"
                                defaultMessage=""
                            />
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className="deatils-field-value">
                            {(this.props.companyInfo || {}).storeName}

                            </div>
                        </Col>
                            
                            <div className='phone'>
                               {getAddressInfoFragment()}
                                {(this.props.companyInfo || {}).gstNumber && <div className='address-gst'><FormattedMessage id='gstnumber.text' defaultMessage=' ' />-{(this.props.companyInfo || {}).gstNumber}</div>}
                                {(this.props.companyInfo || {}).panNumber && <div className='address-gst'><FormattedMessage id='pannumber.text' defaultMessage=' ' /> - {(this.props.companyInfo || {}).panNumber}</div>}
                            </div>
                        </Row>
                    </Col>
         <Col span={13}></Col>
                    <Col span={6} className="order-value-info">
                        <Row gutter={[0,3]}>
                           
                        <Col span={24} className="details-heading">
                Expense Information
              </Col>
                                                                       
                                         {/*   <Col span={24} className='text-right'>
                                            <Tooltip placement="bottom" title={this.props.intl.formatMessage(
                                                {
                                                    id: 'tooltip.generateExpenseNumber',
                                                    defaultMessage: ''
                                                }
                                            )}>
                                                <span className="tooltip-title">
                                                    <FormattedMessage id='supplier.businessExpense.listing.header.expenseNo' defaultMessage='' />
                                                </span>
                                            </Tooltip>
                                        </Col>
                                        <Col span={24} style={{ 'text-align': 'left' }}>
                                            {this.props.nextTxNumber ?
                                            <span>{this.props.nextTxNumber}</span>
                                                // <span>{getFormatedNextTxNumber(this.props)}</span>
                                                //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
                                                :
                                                <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'marginTop': '-25px', 'marginBottom': '-25px' }} />
                                            }
                                        </Col> */}
                                    
                                        <Col span={24}>
                                            <div className="details-heading-subheading">
                                            <FormattedMessage id='expenseDate.text' defaultMessage=' ' />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                         
                                                <DatePicker
                                                    format={format}
                                                    key={`${this.props.expenseDate ? getMomentDateForUI({date: new Date(this.props.expenseDate), format}) : getMomentDateForUI({date: new Date(), format})}`}
                                                    defaultValue={this.props.expenseDate ? getMomentDateForUI({date: new Date(this.props.expenseDate), format}) : getMomentDateForUI({date: new Date(), format})}
                                                    onChange={(selectedDate) => {
                                                        this.props.updateState({
                                                            expenseDate: selectedDate
                                                        })
                                                    }}
                                                />
                                          
                                        </Col>
                                   
                               

                                {/* <div className='date-picker-container mb5'>
                                    <Row>
                                        <Col span={11}>
                                            <div className="title">
                                            <FormattedMessage id='costCenter.text' defaultMessage='  ' />
                                            </div>
                                        </Col>
                                        <Col span={13}>
                                            <Dropdown
                                                items={this.props.costCenters}
                                                valueKeyName='costCenterName'
                                                style={{ width: '92%' }}
                                                value={this.props.costCenterId}
                                                optionKeyName='costCenterId'
                                                onSelect={(costCenterId) => {
                                                    const selectedProject = find(this.props.costCenters, { costCenterId: Number(costCenterId) }) || {};
                                                    this.props.updateState({
                                                        costCenterId,
                                                        costCenterNumber: selectedProject.costCenterNumber,
                                                        costCenterName: selectedProject.costCenterName
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div> */}

                                
                                    
                                        <Col span={24}>
                                            <div className="details-heading-subheading">
                                            <FormattedMessage id='customer.salesOrder.form.projectDropdown.label' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                       
                                            <Dropdown
                                                items={this.props.projectList}
                                                valueKeyName='projectName'
                                                value={this.props.projectName}
                                                optionKeyName='projectMasterId'
                                                canAddNew={true}
                                                canDelete={true}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        projectName: payload.projectName,
                                                    }
                                                    this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                                                }}
                                                onSelect={(projectMasterId, option) => {
                                                    const selectedProject = find(this.props.projectList, { projectMasterId: Number(option.key) }) || {};
                                                    this.props.updateState({
                                                        projectMasterId: selectedProject.projectMasterId,
                                                        projectName: selectedProject.projectName,
                                                        projectNumber: selectedProject.projectNumber
                                                    });
                                                }}
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({
                                                        projectMasterId: undefined,
                                                        projectName: undefined,
                                                        projectNumber: undefined
                                                    })
                                                }}
                                            />
                                          
                                        </Col>
                                   
                                        <Col span={24}>
                                            <div className="details-heading-subheading">
                                                <FormattedMessage id='referenceNumber.text' defaultMessage='  ' />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                         
                                                <TextBox value={this.props.refNumber} onChange={(e)=>{
                                                    this.props.updateState({
                                                        refNumber: e.target.value
                                                    })
                                                }}/>
                                            
                                        </Col>
                                  
                               
                        
                        </Row>
                    </Col>
                </Row>

                <Row></Row>

            </Fragment>
        );
    }
}

export default injectIntl(ExpenseDetails);