import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer } from 'antd';
import EmailBody from './EmailBody';

function EmailDrawer(props) {
  
  const closeDrawer = () => {
    props.updateState({
        emailDrawerVisible: false
    })
  }

  return (
    <Drawer
        title={<FormattedMessage id='email' defaultMessage='' />}
        className="email-template-modal"
        keyboard={false}
        width={720}
        maskClosable={false}
        zIndex={99999}
        destroyOnClose={true}
        onClose={closeDrawer}
        visible={props.emailDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              props.initCalls();
              if (props.txData) {
                props.generatePDF(props.txData, function (file) {
                  let reader = new FileReader();
                  reader.readAsDataURL(file); 
                  reader.onloadend = function() {
                      let base64data = reader.result;          
                      props.updateState({ emailBody: base64data, txFileUrl: base64data , txFile: file, isFileLoaded : true});
                  }
                });
              }
              if (props.txData2) {
                props.generatePDF(props.txData2, function (file) {
                  let reader = new FileReader();
                  reader.readAsDataURL(file); 
                  reader.onloadend = function() {
                      let base64data = reader.result;                
                      props.updateState({ txFileUrl2: base64data , txFile2: file, isFileLoaded : true});
                  }
                  
                });
              }
            }
          }
        }
      >

      <EmailBody {...props}/>
      <div className='mb30' />

      </Drawer>
  );
}

export default injectIntl(EmailDrawer);
