import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EventTemplateListingComp from "../../../components/communication/EventTemplateListing";
import { fetchDataIfNeeded } from "../../../utils";
import { getAllEventTemplate, deleteFormBuilder } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import EmailComponent from '../../drawer/email';
import BrodcastSMS from '../../drawer/communication/account/brodcast-sms';

class EventListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      broadcastSMSDrawerVisible: false,
      emailDrawerVisible: false,
      templateType: 'event'
    };

  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      templateType: 'event'
    };
    fetchDataIfNeeded("getAllEventTemplate", "eventTemplateList", this.props, payload);
  }

  render() {
    return <>
      {this.state.broadcastSMSDrawerVisible && <BrodcastSMS {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      <EventTemplateListingComp  {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }} />
    </>
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    eventTemplateList: state.communicationReducer.eventTemplateList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllEventTemplate, 
      deleteFormBuilder,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EventListing);
