import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPaymentModes, showModal, hideModal, fetchCashEquivalentLedgers, pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { createCustomerPayment, getNextTxNumber } from './action';
import { fetchUnpaidInvoices, resetPaginatedData } from '../../../modal/modalBody/customer/UnpaidInvoices/action';
import { fetchCustomers } from '../../Listing/action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import PayInvoice from '../../../../components/customer/invoice/PayInvoice';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import find from 'lodash.find';

class PayInvoiceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: props.invoiceData.customerId,
      customerName: props.invoiceData.customerName,
      invoiceMasterId: props.invoiceData.invoiceMasterId,
      paymentDate: new Date(),
      invoiceAmount: props.invoiceData.invoiceTotalAmount || 0,
      invoiceNumber: props.invoiceData.invoiceNumber,
      amount: Number(props.invoiceData.invoiceDueAmount || 0).toFixed(2),
      invoiceAmountDue: props.invoiceData.invoiceDueAmount || 0,
      salesQuotationMasterId: props.invoiceData.salesQuotationMasterId,
      quotationNumber: props.invoiceData.quotationNumber,
      rfqNumber: props.invoiceData.rfqNumber,
      rfqMasterId: props.invoiceData.rfqMasterId,
      reference: props.invoiceData.referenceNumber,
      cashInHandLedger: find((props.companyInfo || {}).defaultCoaLedgerAccountsList, { txType: "Cash in hand" }) || {},
      debtorsLedger: find((props.companyInfo || {}).defaultCoaLedgerAccountsList, { txType: "Debtors" }) || {}
    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      customerId: this.props.invoiceData.customerId,
      pageSize: 100,
      pageNumber: 1
    };
    this.props.resetPaginatedData();
    payload.customerId &&  fetchPaginationDataIfNeeded('fetchUnpaidInvoices', 'unpaidInvoiceList', this.props, payload, true);
    fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
    fetchDataIfNeeded('fetchPaymentModes', 'paymentModeList', this.props, payload);
    fetchDataIfNeeded('fetchCashEquivalentLedgers','cashEquivalentLedgers', this.props, payload);
    if (!linkProps.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
    this.props.fetchRemarksTemplate({relationshipId: companyInfo.relationshipId, docName: 'Sales Payment'});
  }
  componentWillReceiveProps(props) {
    if (!this.state.remarks && props.remarksData &&  props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ remarks: props.remarksData.content || '', isRemarksSet: true });
      setTimeout(()=>{
        props.resetRemarksData();
    },500)
    }
  }

  render() {
    return (
      <PayInvoice {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    unpaidInvoiceList: state.customer.unpaidInvoiceList,
    paymentModeList: state.common.paymentModeList,
    customers: state.customer.customers,
    cashEquivalentLedgers: state.common.cashEquivalentLedgers,
    nextTxNumber: state.customerPayment.nextTxNumber,
    remarksData: state.settings.remarksData,
    detailLoading: state.common.detailLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUnpaidInvoices,
  fetchPaymentModes,
  fetchCustomers,
  createCustomerPayment,
  fetchCashEquivalentLedgers,
  resetPaginatedData,
  showModal,
  hideModal,
  resetRemarksData,
  getNextTxNumber,
  fetchRemarksTemplate,
  pushModalToStack,
  popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayInvoiceContainer);
