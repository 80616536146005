import React from "react";
import { Drawer, Row, Col, DatePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getMomentDateForUI, getCurrentDateForBackend, showToasterMessage } from '../../../../utils';
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { CONSTANTS } from '../../../../static/constants';
import { FilterOutlined, UndoOutlined, } from '@ant-design/icons';



const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;



const EmployeeFilterComp = (props) => {

    const { updateState, companyInfo } = props;

    const closeDrawer = () => {
        props.updateState({
            employeeFilterDrawerVisible: false
        })
    }

    const applyFilter = () => {
        // if(!props.selectedCustomerId && !props.inqNum && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatusName && !props.selectedStatus && !props.selectedPriority && !props.refNum && !props.selectedSalesPersonId && !props.selectedProjectMasterId ){
        //     return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
        // }
        // const payload = {
        //     companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
        //     filters: {
        //         customerId: props.selectedCustomerId,
        //         inquiryNumber: props.inqNum,
        //         startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
        //         endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
        //         expStartDate: props.expStartDate ? getCurrentDateForBackend(props.expStartDate) : null,
        //         expEndDate: props.expEndDate ? getCurrentDateForBackend(props.expEndDate) : null,
        //         userStatus: props.selectedUserStatusName,
        //         status: props.selectedStatus,
        //         priority: props.selectedPriority,
        //         reference: props.refNum,
        //         salesPersonId: props.selectedSalesPersonId,
        //         salesPersonName: props.selectedSalesPersonName,
        //         projectMasterId: props.selectedProjectMasterId,
        //     }
        // };
        // props.fetchSalesEnquiryByFilter(payload);
        // props.getSalesEnquiryCountByFilter(payload);
        props.updateState({
            employeeFilterDrawerVisible: false
        })
    }

    const clearFilter = () => {
        // props.updateState({
        //     selectedCustomerId: null, selectedCustomerName: null,
        //     inqNum: null, 
        //     startDate:null,endDate:null,
        //     expStartDate:null, expEndDate:null,
        //     selectedUserStatusName: null, selectedUserStatus: null,
        //     selectedStatus:null, selectedStatusName: null,
        //     selectedPriority: null,
        //     refNum:null,
        //     selectedSalesPersonId: null, selectedSalesPersonName:null, 
        //     selectedProjectName:null, selectedProjectMasterId: null 
        // });
        // const payload = {
        //     companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
        //     filters: {}
        // };
        // props.fetchSalesEnquiry(payload);
        // props.fetchSalesEnquiryCount(payload);
    }

    const rowGutter = [24, 16];

    return (
        <>
            <Drawer
                title={
                    <div className="drawer-head-txt">
                        Filters
                    </div>
                }
                width={720}
                zIndex={1}
                maskClosable={false}
                destroyOnClose={true}
                onClose={closeDrawer}
                className="custom-drawer"
                visible={props.employeeFilterDrawerVisible}
                footer={<>
                    <CustomButton
                        intlId='confirmation.cancel'
                        defaultMessage=''
                        type='default'
                        key={0}
                        onClick={closeDrawer}
                    />
                    <div>
                        <CustomButton
                            intlId='common.clearFilter.txt'
                            defaultMessage=''
                            type='default'
                            key={0}
                            onClick={clearFilter}
                            btnIcon={<><UndoOutlined /> &nbsp;</>}
                            style={{ marginRight: '10px' }}
                        />
                        <CustomButton
                            intlId='common.applyFilter.txt'
                            defaultMessage=''
                            htmlType='submit'
                            onClick={applyFilter}
                            key={1}
                            btnIcon={<><FilterOutlined /> &nbsp;</>}
                        />
                    </div>
                </>}
            >
                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <div className="i-label">Department</div>
                        <Dropdown
                            items={props.departmentList[props.pageNumber || 1] || []}
                            valueKeyName="departmentName"
                            optionKeyName="departmentId"
                            value={props.departmentName}

                            placeholder={props.intl.formatMessage({
                                id: 'common.select.placeholder', defaultMessage: ''
                            })}

                            onSelect={
                                (optionValue, option) => {
                                    updateState({
                                        departmentId: option.value,
                                        departmentName: option.name,
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    departmentId: undefined,
                                    departmentName: undefined,
                                })
                            }}
                        />

                    </Col>
                    <Col span={12}>
                        <div className="i-label">Designation</div>
                        <Dropdown
                            items={props.designationList[props.pageNumber || 1] || []}
                            valueKeyName="designationName"
                            optionKeyName="designationId"
                            value={props.designationName}

                            placeholder={props.intl.formatMessage({
                                id: 'common.select.placeholder', defaultMessage: ''
                            })}

                            onSelect={
                                (optionValue, option) => {
                                    updateState({
                                        designationId: option.value,
                                        designationName: option.name,
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    designationId: undefined,
                                    designationName: undefined,
                                })
                            }}
                        />

                    </Col>

                </Row>
                <Row gutter={rowGutter}>


                    <Col span={12}>
                        <div className="i-label">Job Role</div>
                        <Dropdown
                            items={props.jobRoleList[props.pageNumber || 1] || []}
                            valueKeyName="jobRoleName"
                            optionKeyName="jobRoleId"
                            value={props.jobRoleName}

                            placeholder={props.intl.formatMessage({
                                id: 'common.select.placeholder', defaultMessage: ''
                            })}

                            onSelect={
                                (optionValue, option) => {
                                    updateState({
                                        jobRoleId: option.value,
                                        jobRoleName: option.name,
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    jobRoleId: undefined,
                                    jobRoleName: undefined,
                                })
                            }}
                        />
                    </Col>



                </Row>



            </Drawer>
        </>
    )
};

export default EmployeeFilterComp;
