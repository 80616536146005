import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDataIfNeeded } from '../../../../utils';
import EmployeeDrawer from '../../../../components/drawer/company/Employee';
import {
  fetchSalutation, addSalutation, deleteSalutation, showModal, hideModal, pushModalToStack, popModalFromStack, fetchJobTitles, addJobTitle, deleteJobTitle, fetchAllEmployees, createEmployee, fetchAllDepartments, addDepartment, deleteDepartment,
  addJobRole, deleteJobRole, fetchJobRoles,
  addEmpType, deleteEmpType, fetchEmpTypes
} from './../../../../actions/commonActions';
import {
  fetchChildCompanyHierarchiesByLevel, resetChildCompanyHierarchyByLevel
} from './../../../../containers/drawer/company/CompanyHierarchy/action';
import { getTeacher } from '../../../schoolManagement/teacher/action';

class EmployeeDrawerComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.employeeData,
      isRoleTeacher: props.isRoleTeacher,
    }
  }
  

  initCalls() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId, hierarchyLevel: this.state.hierarchyLevel ? this.state.hierarchyLevel : 0 };
    fetchDataIfNeeded('fetchSalutation', 'salutations', this.props, payload);
    this.props.fetchJobTitles(payload);
    this.props.resetChildCompanyHierarchyByLevel(payload);
    this.props.fetchChildCompanyHierarchiesByLevel(payload);
    this.props.fetchAllDepartments(payload)
    this.props.fetchAllEmployees(payload);
    this.props.fetchJobRoles(payload);
    this.props.fetchEmpTypes(payload);
  }

  render() {
    return <EmployeeDrawer initCalls={() => {
          this.initCalls();
        }} {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} 
      />
  }

}

const mapStateToProps = (state) => {
  return {
    salutations: state.common.salutations,
    companyHierarchyList: state.hierarchy.childHierarchyListByLevel,
    companyInfo: state.common.companyInfo,
    jobTitleList: state.common.jobTitleList,
    departments: state.common.departmentList,
    managerList: state.common.employees,
    jobRoleList: state.common.jobRoleList,
    empTypeList: state.common.empTypeList,
    listLoading: state.common.listLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSalutation,
  addSalutation, 
  deleteSalutation,
  fetchChildCompanyHierarchiesByLevel,
  resetChildCompanyHierarchyByLevel,
  fetchAllEmployees,
  fetchAllDepartments,
  addDepartment, 
  deleteDepartment,
  createEmployee,
  fetchJobTitles,
  addJobTitle, 
  deleteJobTitle,
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  addJobRole, deleteJobRole, fetchJobRoles, 
  addEmpType, deleteEmpType, fetchEmpTypes,
  getTeacher
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDrawerComp);
