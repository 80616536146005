import React, { Fragment } from "react";
import { FormattedMessage } from 'react-intl';
import { Col, Collapse, Row, Tabs } from "antd";
import PageBreadcrumb from "../../PageBreadcrumb";
import SalesTransactions from "./salesTransactions";
import PurchaseTransactions from "./purchaseTransactions";
import './details.scss';
import { CONSTANTS } from '../../../static/constants';
import { getMomentDateForUIReadOnly } from "../../../utils";
import CategoryDetailsModel from "../../../containers/modal/modalBody/product/CategoryDetailsModel";

const { TabPane } = Tabs;
const { Panel } = Collapse;

const breadCrumbList = [
    {
        name: <FormattedMessage id="product.breadcrum.list" defaultMessage="" />,
    }, {
        name: 'Products',
        link: '/admin/products'
    }, {
        name: 'Product Details'
    }
];

function callback(key, props) {
    props.updateState({ pageNumber: 1 })
}


const ProductDetails = (props) => {
    const { productDetails } = props;
    let address = (productDetails.boLocationList || [])[0];


    const openClassDetailPopup = () => {
        const modalData = {
            title: <FormattedMessage id='inventory.invValuation.listing.model.title' defaultMessage='' />,
            modalBody: <CategoryDetailsModel {...props} CategoryObj={{itemId:props.itemId}} />,
            width: '50%',
            hideFooter: true,
        };
        props.pushModalToStack(modalData);
    }

    return <>
        <div className="view-top-bar">
            <div className="top-view-left">
                <div className="page-heading">
                    Product Details
                </div>
                <div className="vertical-sep" />
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
        </div>

        <div className="view-container">
            <Collapse accordion onChange={(e) => props.updateState({ accordianOpen: e })}>
                <Panel header={<FormattedMessage id='product.detail.panel.header.label' defaultMessage='' />} key="1">
                    <Row>
                        <Col span={8}>
                            <Row>
                                <Col span={10}>
                                    <FormattedMessage id='product.detail.panel.productName.label' defaultMessage='' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.itemName}
                                </Col>



                                <Col span={10}>
                                    <FormattedMessage id='product.detail.panel.firstProcurement.label' defaultMessage='' />
                                </Col>
                                <Col span={14}>
                                    {(productDetails.dateCreated ? getMomentDateForUIReadOnly({ date: productDetails.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : '-')}
                                </Col>



                                <Col span={10}>
                                    <FormattedMessage id='signup.tab.address' />
                                </Col>
                                <Col span={14}>
                                    {address ? getAddressInfoFragment(address) : '-'}
                                </Col>

                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={10}>
                                    <FormattedMessage id='product.detail.panel.productCategory.label' defaultMessage='' />
                                </Col>
                                <Col span={14}>
                                  
                                    {/* {productDetails.inventoryItemCategoryRefs?.map((val) => val.inventoryItemCategoryName)} */}

                                    {productDetails.inventoryItemCategoryRefs?.length ? <span onClick={() => { openClassDetailPopup() }}
                                                className="cursor-pointer">{productDetails.inventoryItemCategoryRefs?.length}</span> : '0'}

{/* {productDetails.inventoryItemCategoryRefs?.map((val) => (
    productDetails.inventoryItemCategoryRefs?.length ? (
        <span onClick={() => { openClassDetailPopup(val) }} className="cursor-pointer">
            ({productDetails.inventoryItemCategoryRefs?.length})
        </span>
    ) : (
        '0'
    )
))} */}

                                </Col>

                                <Col span={10}>
                                    <FormattedMessage id='product.detail.panel.brand.label' defaultMessage='' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.brandName || '-'}
                                </Col>

                                <Col span={10}>
                                    <FormattedMessage id='product.detail.panel.manufacturer.label' defaultMessage='' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.manufacturerName || '-'}
                                </Col>

                                <Col span={10}>
                                    <FormattedMessage id='drawer.inventory.sku' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.sku || '-'}
                                </Col>

                                <Col span={10}>
                                    <FormattedMessage id='drawer.inventory.partNo' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.partNumber || '-'}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={10}>
                                    <FormattedMessage id='drawer.inventory.customerStockNo' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.customerStockNumber || '-'}
                                </Col>

                                <Col span={10}>
                                    <FormattedMessage id='product.detail.panel.articleNo.label' defaultMessage='' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.articleNumber || '-'}
                                </Col>

                                <Col span={10}>
                                    <FormattedMessage id='drawer.inventory.upc' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.upc || '-'}
                                </Col>

                                <Col span={10}>
                                    <FormattedMessage id='drawer.inventory.ean' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.ean || '-'}
                                </Col>

                                <Col span={10}>
                                    <FormattedMessage id='drawer.inventory.isbn' />
                                </Col>
                                <Col span={14}>
                                    {productDetails.isbn || '-'}
                                </Col>

                                {/* <Col span={10}>
                                        Ledger Accounts
                                    </Col>
                                    <Col span={14}>
                                        <dd>21001 - Sales Revenue </dd>
                                        <dd>12011 - Purchase Account</dd>
                                    </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>

            <Tabs onChange={(key) => callback(key, props)} type="line">
                <TabPane tab={<FormattedMessage id='product.detail.tab.header.outboundInventory' defaultMessage='' />} key="1">
                    <SalesTransactions {...props} />
                </TabPane>
                <TabPane tab={<FormattedMessage id='product.detail.tab.header.inboundInventory' defaultMessage='' />} key="2">
                    <PurchaseTransactions {...props} />
                </TabPane>
            </Tabs>
        </div>
    </>
};

const getAddressInfoFragment = (obj) => {
    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <div className='billing-address'>{obj.streetAddress1}
                </div>
                : ''
        }
        {
            obj.streetAddress2
                ? <div className='billing-address'>{obj.streetAddress2}
                </div>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
    </Fragment>

    return fragmentObj;
}

export default ProductDetails;