import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Tooltip, Menu, Button, Dropdown, Skeleton } from 'antd';
import { CONSTANTS, ICONS, TRANSACTION_TYPES } from '../../../../../static/constants';
import {  camelize, getMomentDateForUIReadOnly } from '../../../../../utils';
import config from '../../../../../config/environmentConfig';
import RFQIcon from '../../../../../assets/images/rfq.jpeg';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import AddUpdateBiddingSupplier from '../../../../../containers/modal/modalBody/supplier/AddUpdateBiddingSupplier';
import RFQDetail from '../../../../../containers/modal/modalBody/supplier/RFQDetail';
import RFQSplitDetail from '../../../../../containers/modal/modalBody/supplier/RFQSplitDetail';
import SupplierResponse from '../../../../../containers/modal/modalBody/supplier/SupplierResponse';
import * as find from 'lodash.find';
import ShareRFQComponent from '../ShareRFQ';
import Template2Email from "../RFQDetail/template2Email";
//import { compose } from 'redux';

class BiddingSuppliersPopup extends React.Component {

    render() {
        let rfqData = this.props.rfqData;
        const getPayloadForPdf = (row) => {
            let additionalInfo = (row.additionalInfoList && row.additionalInfoList[0]) || {};
            const billingAddress = (rfqData.boLocationRFQList || []).filter(x => x.locationType === CONSTANTS.BILLING_ADDRESS && x.supplierId === row.supplierId) || [];
            const relationshipAddress = find((rfqData.boLocationRFQList || []), { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
            let isColumnEmpty = {
                product: true,
                service: true,
                variant: true,
                description: true,
                qty: true,
                uomName: true,
            };
            if (rfqData) {
                isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, rfqData);
            }
            return {
                fileName: (rfqData.rfqNumber || 'RFQ') + '.pdf',
                transactionName: 'RFQBiddingSupplier',
                rfqData: rfqData,
                relationshipAddress: relationshipAddress,
                billingAddress: billingAddress[0] || {},
                companyInfo: this.props.companyInfo,
                isColumnEmpty: isColumnEmpty,
                additionalInfo: additionalInfo,
                supplierName: row.supplierName
            }
        }
        const openEmailDrawer = (row) => {
            let buttonComp = document.getElementById("rfq-collect-res");
            if (buttonComp) {
                const previewUrl = config.UI_BASE_URL + 'rfqSubmission?t=' + rfqData.relationshipId + '_' + rfqData.rfqMasterId + '_' + row.data.rfqBiddingSuppliersId + '_' + Math.floor((Math.random() * 10000) + 1)
                buttonComp.href = previewUrl;
            }
           
            let rfqComp = document.getElementById("RFQ-Details");
            let tempCont = rfqComp? `<div"> ${rfqComp.innerHTML}</div>` : '';
            let payload = getPayloadForPdf(row.data);
            payload.supplierId = (row.data || {}).supplierId;
            payload.rfqMasterId = rfqData.rfqMasterId;
            payload.fileDataReqeired = true;
            this.props.updateState({
                emailDrawerVisible: true,
                txData: payload,
                type: TRANSACTION_TYPES.BIDDING_SUPPLIER,
                templateContent: tempCont,
            });
        };

        const deleteSupplier = (props, record) => {
            const modalData = {
                modalBody: <FormattedMessage
                    id='deleteItem.confirmation'
                    defaultMessage={``}
                    values={{ value: 'this supplier' }}
                />,
                handleSubmit: () => {
                    props.deleteBiddingSupplierFromRFQ(record.data, props);
                }
            };
            props.pushModalToStack(modalData);
        };

        const editBiddingSupplier = (param) => {
            let contact = (((param.node || {}).data || {}).additionalInfoList && param.node.data.additionalInfoList[0]) || {};
            const modalData = {
                title: 'Bidding Supplier',
                modalBody: <AddUpdateBiddingSupplier selectedContact={contact} biddingSupplierData={param.data || {}} rfqData={rfqData} {...this.props} />,
                wrapClassName: 'bidding-supplier-address',
                width: 500,
                hideFooter: true,
            };
            this.props.showModal(modalData);
        }

        const actionMenu = (param) => (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Actions
                </Menu.Item>

                <Menu.Item key="1" value="edit" onClick={() => editBiddingSupplier(param)}>
                    <i className={`${ICONS.EDIT} mr5`} /> Edit Supplier
                </Menu.Item>

                <Menu.Item key="2" value="email" onClick={() => openEmailDrawer(param)}>
                    <i className={`${ICONS.MAIL} mr5`} /> Email
                </Menu.Item>

                <Menu.Item key="3" value="std_pdf" onClick={() => { 
                    const templateName= "Standard";
                    generatePDF(param, templateName);
                }}
                >
                    <i className={`${ICONS.PDF} mr5`} /> Download PDF
                </Menu.Item>

                <Menu.Item key="6" value="prof_pdf" onClick={() => { 
                    const templateName= "Professional";
                    generatePDF(param, templateName);
                    }}
                >
                    <i className={`${ICONS.PDF} mr5`} /> Download Professional PDF
                </Menu.Item>

                <Menu.Item key="4" value="delete" onClick={() => deleteSupplier(this.props, param)}>
                    <i className={`${ICONS.DELETE} mr5`} /> Delete Supplier
                </Menu.Item>
                <Menu.Item key="5" onClick={() => {
                    this.props.pushModalToStack({
                        title: <>
                            <FormattedMessage id='shareRFQ.text' defaultMessage='' />
                            <div style={{ 'fontSize': '12px' }}> {param.node.data.supplierName || ''}</div>
                        </>,
                        modalBody: <ShareRFQComponent {...this.props} biddingSupplierData={param.node.data}/>,
                        width: '50%',
                        hideFooter: true,
                    })
                }}><i className={`${ICONS.SHARE} mr5`} /> <FormattedMessage id='shareRFQ.text' defaultMessage='' />
                </Menu.Item>
            </Menu >
        );




        const getAWSFiles = (row) => {
            return <div className="cursor-pointer">
                {(row.awsDocumentDetails || []).map((item, i) => {
                    return (
                        <div onClick={() => this.props.downloadFileFromBucket({
                            bucketName: config.BUCKET_NAME.BO_RFQ,
                            awsKeyName: item.awsKeyName,
                            fileName: item.fileName
                        })}> {item.fileName}  </div>
                    )
                })
                }
            </div>
        }

        const getSupplierNameAndAddress = (row) => {
            let address = [];
            address = (rfqData.boLocationRFQList || []).filter(x => x.locationType === CONSTANTS.BILLING_ADDRESS && x.supplierId === row.supplierId) || [];
            return address[0] ?
                <div className="bidding-supplier-name-cell">
                    <div>
                        <strong className="supplier-name">{row.supplierName}</strong>
                    </div>
                    <div className="address-details">
                        <div>{address[0].streetAddress1} </div>
                        <div>{address[0].streetAddress2}</div>
                        <span>{address[0].cityName || ""} </span>
                        <span>{address[0].stateName || ""}</span>
                        <span> {address[0].zipCode || ""}</span>
                    </div>
                </div>
                :
                <div>{row.supplierName}</div>;
        }

        const getSupplierContact = (row) => {
            let contact = (row.additionalInfoList && row.additionalInfoList[0]) || {};
            let contactName = "";
            if (contact.salutationName) contactName += contact.salutationName + ' ';
            if (contact.firstName) contactName += contact.firstName;
            if (contact.middleName) contactName += ' ' + contact.middleName;
            if (contact.lastName) contactName += ' ' + contact.lastName;
            return <div>{contactName}</div>;
        }

        const updateColumnEmptyCheck = (isColumnEmpty, rfqData) => {
            if (!rfqData) return isColumnEmpty;
            for (let i in rfqData.rfqDetailsList) {
                let row = rfqData.rfqDetailsList[i];
                if (row.itemName) { isColumnEmpty.product = false; };
                if (row.serviceName) { isColumnEmpty.service = false; };
                if (row.itemVariantName) { isColumnEmpty.variant = false; }
                if (row.description) { isColumnEmpty.description = false; }
                if (row.quantity) { isColumnEmpty.qty = false; }
                if (row.uomName) { isColumnEmpty.uomName = false; }
            }
            return isColumnEmpty;
        }

       

        const generatePDF = (row, templateName) => {
            let payload = getPayloadForPdf(row.data);
            payload.templateName = templateName;
            this.props.generatePDF(payload);
        }


        //let rfqBiddingSuppliersList = rfqData.rfqBiddingSuppliersList || [];
        // const allAddress = filter((this.props.supplierData.boLocationList || []), (add) => { return add.locationType === CONSTANTS.BILLING_ADDRESS; }) || [];
        ///const contactList = this.props.supplierData.boContactList || [];

        const columnDefs = [
            {
                headerComponentFramework: () => <FormattedMessage id='supplier.rfq.biddingSuppliers.gridHeader.supplierName' defaultMessage='' />,
                field: "supplierName",
                resizable: true,
                width: 220,
                cellRendererFramework: (params) => {
                    return getSupplierNameAndAddress(params.node.data);
                },
                cellClass: 'supplierNameCell'
            },
            // {
            //     headerComponentFramework: () => <FormattedMessage id='supplier.rfq.biddingSuppliers.gridHeader.address' defaultMessage='' />,
            //     field: "address",
            //     resizable: true,
            //     suppressKeyboardEvent: (params) => {
            //         const KEY_ENTER = 13;
            //         const keyCode = params.event.keyCode;
            //         if (keyCode === 9) {
            //             params.api.tabToNextCell();
            //             return true;
            //         }
            //         const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
            //         return gridShouldDoNothing;
            //     },
            //     cellRendererFramework: (params) => {
            //         return getSupplierAddress(params.node.data);
            //     },
            // },
            {
                headerComponentFramework: () => <FormattedMessage id='supplier.rfq.biddingSuppliers.gridHeader.contact' defaultMessage='' />,
                field: "contact",
                resizable: true,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellRendererFramework: (params) => {
                    return getSupplierContact(params.node.data);
                },
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='supplier.rfq.biddingSuppliers.gridHeader.documents' defaultMessage='' />
                        <Tooltip placement="top" title="Document descriptions" trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "documents",
                width: 200,
                resizable: true,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellRendererFramework: (params) => {
                    return getAWSFiles(params.node.data);
                },
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='supplier.rfq.biddingSuppliers.gridHeader.priceCode' defaultMessage='' />
                        <Tooltip placement="top" title="Price Code" trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "priceCode",
                resizable: true,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
            },
            {
                headerComponentFramework: () => <FormattedMessage id='common.status' defaultMessage='' />,
                field: "statusName",
                cellRendererFramework: (params) => {
                    return <div> <span className={["status-code", (camelize((params.data || {}).statusName || "") || ""), " " + (params.data || {}).statusColor].join(' ')}><FormattedMessage id={((params.data || {}).statusName || "open")} defaultMessage='' /></span> </div>;
                },
                resizable: true,
                width: 120,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                // cellRendererFramework: (params) => {
                //     return <div><span className={["status-code", params.data.statusName.toLowerCase()].join(' ')}>{
                //         params.data.statusName
                //     }</span></div>
                // },
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.rfq.biddingSuppliers.gridHeader.comments' defaultMessage='' />
                },
                field: "comments",
                width: 228,
                resizable: true,
                cellRendererFramework: (params) => {
                    return <pre> {params.data.comments}</pre>
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                        this.gridApi.refreshCells({ force: true });
                        params.api.tabToNextCell();
                        return true;
                    }
                    params.event.target.style.height = 'inherit';
                    params.event.target.style.height = `${params.event.target.scrollHeight}px`;
                    params.node.setRowHeight(params.event.target.scrollHeight - 10);
                    this.gridApi && this.gridApi.onRowHeightChanged();
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
            },
            {
                headerComponentFramework: () => <FormattedMessage id='response.text' />,
                field: "supplierResponse",
                cellRendererFramework: (params) => {
                    return <div> {(params.data || {}).supplierResponse ?
                        <span className="cursor-pointer"onClick={() => {  openSupplierResponse(params.data) }}>Yes</span>
                        : <span>No</span>
                    }</div>;
                },
                resizable: true,
                width: 120,
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
            },
            {
                headerComponentFramework: () => <FormattedMessage id='actions.text' />,
                cellRendererFramework: (param) => {
                    return <Dropdown overlay={actionMenu(param)} trigger={['click']} overlayClassName="action-listing-dropdown">
                        <i className={`${ICONS.MORE} pointer`} />
                    </Dropdown>
                },
                resizable: true,
                pinned: 'right',
                width: 100,
                cellClass: 'lastActionCell'
            }
        ];

        const onGridReady = (params) => {
            this.gridApi = params.api;
        }

        const onFirstDataRendered = (params) => {
            params.api.sizeColumnsToFit();
        }

        const getRowHeight = (params) => {
            let height = 28;
            let calcHeight = 1;
            if (params.data.supplierId && rfqData && rfqData.boLocationRFQList) {
                let address = (rfqData.boLocationRFQList || []).filter(x => x.locationType === CONSTANTS.BILLING_ADDRESS && x.supplierId === params.data.supplierId) || [];
                if (address && address[0]) {
                    calcHeight = (address[0].streetAddress1) ? calcHeight + 1 : calcHeight;
                    calcHeight = (address[0].streetAddress2) ? calcHeight + 1 : calcHeight;
                }
            }
            if (params.data.awsDocumentDetails && params.data.awsDocumentDetails.length > 0) {
                calcHeight = calcHeight > params.data.awsDocumentDetails.length ? calcHeight : params.data.awsDocumentDetails.length;
                calcHeight += 1;
            }

            return height * calcHeight;
        }

        // const addRow = () => {
        //     this.gridApi.updateRowData({ add: [{}] });
        //     this.gridApi.getDisplayedRowAtIndex(this.gridApi.rowRenderer.lastRenderedRow).data.isToEdit = true;
        //     this.gridApi.refreshCells({ force: true });
        //     let startEditingParams = {
        //         rowIndex: this.gridApi.rowRenderer.lastRenderedRow,
        //         colKey: 'supplierName',
        //     };
        //     this.gridApi.startEditingCell(startEditingParams);
        // }
        const openRFQDetails = (data) => {
            const rfqPayload = {
                supplierId: data.supplierId,
                rfqMasterId: data.rfqMasterId,
                rfqChildMasterId: data.rfqChildMasterId
            }
            this.props.pushModalToStack({
                modalBody: data.rfqChildMasterId ? <RFQSplitDetail {...this.props} rfqPayload={rfqPayload} /> : <RFQDetail {...this.props} rfqPayload={rfqPayload} />,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
        }

        const openSupplierResponse = (biddingSupplierData) => {
          
            this.props.pushModalToStack({
                modalBody:  <SupplierResponse biddingSupplierData={biddingSupplierData} rfqData={rfqData} {...this.props}/> ,
                width: '100%',
                hideFooter: true,
                wrapClassName: 'modal-custom-detail'
            })
        }

        return (
            <>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="inner-content">
                            <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 2 }} active>
                                <div className="modal-table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage id='supplier.rfq.biddingSuppliers.rfqDetail.rfqNumber' defaultMessage='' /></th>
                                                {/* <th><FormattedMessage id='supplier.rfq.biddingSuppliers.rfqDetail.customerName' defaultMessage='' /></th> */}
                                                <th><FormattedMessage id='supplier.rfq.biddingSuppliers.rfqDetail.refNumber' defaultMessage='' /></th>
                                                <th><FormattedMessage id='supplier.rfq.biddingSuppliers.rfqDetail.bidClosingDate' defaultMessage='' /></th>
                                                <th><FormattedMessage id='supplier.rfq.biddingSuppliers.rfqDetail.rfqDate' defaultMessage='' /></th>
                                                <th><FormattedMessage id='supplier.rfq.biddingSuppliers.rfqDetail.priority' defaultMessage='' /></th>
                                                <th><FormattedMessage id='common.status' defaultMessage='' /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                {rfqData.rfqChildNumber ? <td className="cursor-pointer" onClick={() => { openRFQDetails(rfqData) }}>{rfqData.rfqChildNumber || '-'}</td> : <td className="cursor-pointer" onClick={() => { openRFQDetails(rfqData) }}>{rfqData.rfqNumber || '-'}</td>}
                                                {/* <td>{rfqData.customerName || this.props.customerName || '-'}</td> */}
                                                <td>{rfqData.refNumber || this.props.refNumber || '-'}</td>
                                                <td>{rfqData.rfqExpirationDate ? getMomentDateForUIReadOnly({date: rfqData.rfqExpirationDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}) : '-'}</td>
                                                <td>{rfqData.rfqDate ? getMomentDateForUIReadOnly({date: rfqData.rfqDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}) : '-'}</td>
                                                <td>{rfqData.orderPriority || ''}</td>
                                                <td><span className={["status-code", (camelize(rfqData.status || "")), " " + rfqData.statusColor].join(' ')}><FormattedMessage id={(rfqData.status || "open")} defaultMessage='' /></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Skeleton>

                            <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 3}} active>
                                {(rfqData.rfqBiddingSuppliersList?.length) ?
                                    <>
                                        <div className="modal-table-container rfqBidding-supplier-list mt20">
                                            <CustomAgGrid
                                                columnDefs={columnDefs}
                                                rowData={rfqData.rfqBiddingSuppliersList || []}
                                                rowDragManaged={true}
                                                suppressClickEdit={true}
                                                noSingleClickEdit={true}
                                                disableFocusOnClickOutside={true}
                                                domLayout={"autoHeight"}
                                                animateRows={true}
                                                onGridSizeChanged={onFirstDataRendered}
                                                getRowHeight={getRowHeight}
                                                editType={'fullRow'}
                                                onGridReady={onGridReady}
                                            />
                                        </div>
                                        <div className="pull-left mt10">
                                            <Button type='primary' className="right-border" onClick={() => {
                                                editBiddingSupplier({});
                                            }}>
                                                Add Supplier
                                            </Button>
                                        </div>
                                    </>
                                    :
                                    !this.props.rfqBiddingSuppliersList?.length ? 
                                        <div className="create-data-container">
                                            <div className='mt20 mb20'> 
                                               Bidding Supplier does not exist.
                                                <span 
                                                    className='cursor-pointer ml5 mr5'
                                                    onClick={()=>{
                                                        editBiddingSupplier({});
                                                    }}
                                                >
                                                    Click here 
                                                </span>
                                                to add a supplier.
                                            </div>
                                            <img src={RFQIcon} alt="Invoice" height="275" />
                                        </div>
                                        :
                                        ''
                                }
                            </Skeleton>
                        </div>
                    </div>
                </div>
                
                <div style={{display:'none'}}><Template2Email {...this.props} /></div>
            </>
        )
    }

}


export default Form.create()(BiddingSuppliersPopup);
