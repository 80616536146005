import React from "react";
import { showToasterMessage } from "../../utils";

export const CustomTableUpload = (props) => {
  const maxFileLength = props.maxFile || 10;
  const maxFileSize = props.maxFileSize || 50;

  const calculateTotlaFileSize = (fileList) => {
    let totalSize = 0;
    fileList.forEach((file) => {
      totalSize += file.size / (1024 * 1024);
    });

    return totalSize;
  };
  return (
    <div>
      <div className="remarks-title pb10">
        <input
          type="file"
          name="filefield"
          multiple="multiple"
          accept={
            props.acceptFormats || [
              ".xls",
              ".pdf",
              ".jpeg",
              ".jpg",
              "png",
              ".doc",
              ".docx",
              ".xlsx",
            ]
          }
          onChange={(e) => {
            const files = e.target.files;
            let fileList = props.fileList ? [...props.fileList] : [];
            for (let i = 0; i < files.length; i++) {
              fileList.push(files[i]);
            }
            if (fileList.length > maxFileLength) {
              return showToasterMessage({
                messageType: "error",
                description: `You can only upload ${maxFileLength} files`,
              });
            }
            if (calculateTotlaFileSize(fileList) <= maxFileSize) {
              props.onChange(fileList);
            } else {
              return showToasterMessage({
                messageType: "error",
                description: `Total files size is exceeding ${maxFileSize} MB`,
              });
            }
          }}
        />
        <span style={{ fontSize: "12px" }}>
          Max {maxFileLength} files of {maxFileSize} MB
        </span>
      </div>
      {props.fileList && props.fileList.length ? (
        <div className="remarks-title upoad-file-table">
          <div className=" modal-table-container " width>
            <table className="transaction-details-table">
              <thead>
                <tr>
                  <th width="10%">#</th>
                  <th>File Name</th>
                  {/* <th>Type</th> */}
                  <th>Size</th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                {props.fileList.map((e, index) => {
                  let _size = e.size;
                  let fSExt = ["Bytes", "KB", "MB", "GB"],
                    i = 0;
                  while (_size > 900) {
                    _size /= 1024;
                    i++;
                  }
                  let exactSize =
                    Math.round(_size * 100) / 100 + " " + fSExt[i];
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{e.name}</td>
                      {/* <td>{e.type}</td> */}
                      <td>{exactSize}</td>
                      <td className="">
                        <i
                          className="fi fi-rs-trash"
                          onClick={() => {
                            const fileList = props.fileList.filter(
                              (data, fileIndex) => {
                                return index !== fileIndex;
                              }
                            );

                            props.onChange(fileList);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </div>
  );
};
