import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { Form } from '@ant-design/compatible';
import "@ant-design/compatible/assets/index.css";
import { Button, Row } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as find from "lodash.find";
import { Dropdown } from "../../../general/Dropdown";
import { DropdownPriceList } from "../../../general/MarkupDropdown";
import { AG_GRID_CONSTANTS } from "../../../../static/agGridConstants";
import { MODAL_TYPE, CONSTANTS } from "../../../../static/constants";
import LoadingIcon from "../../../../assets/images/loading.gif";
import StandardSalesOrder from "../../../../containers/customer/salesOrder/StandardSalesOrder";
import MultiCurrencySalesOrder from "../../../../containers/customer/salesOrder/MultiCurrencySalesOrder";
import { DetailDropdown } from "../../../general/DetailDropdown";

const HeaderAction = (props) => {
  const [templateDropdown, updateTemplateDropdown] = useState(false);
  const {
    intl,
    customers,
    pageNumber,
    customer,
    updateState,
    priceListId,
    priceList,
    priceTypeToApply,
    // allWarehouses,
    //  warehouse,
    showModal,
    companyInfo,
  } = props;

  const handleNewPriceListAddition = (props, payload) => {
    const formData = payload.formData;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <FormattedMessage id="addItem.text.priceList" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.PRICE_LIST,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.PRICE_LIST: {
        deleteFunc = props.deletePriceList;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }
    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  const useOuterClick = (callback) => {
    const innerRef = useRef();
    const callbackRef = useRef();

    // set current callback in ref, before second useEffect uses it
    useEffect(() => {
      // useEffect wrapper to be safe for concurrent mode
      callbackRef.current = callback;
    });

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);

      // read most recent callback and innerRef dom node from refs
      function handleClick(e) {
        if (
          innerRef.current &&
          callbackRef.current &&
          !innerRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }
      }
    }, []); // no need for callback + innerRef dep

    return innerRef; // return ref; client can omit `useRef`
  };

  const innerRef = useOuterClick((e) => {
    updateTemplateDropdown(!templateDropdown);
  });

  return (
    <>
      <div className="left-itms">
        <ul>
          <li className="title">
            <FormattedMessage id="heading.sales.salesOrder" />
          </li>
          <li className="sep" />
          <li>
            <FormattedMessage
              id="customer.salesOrder.form.soNumber.label"
              defaultMessage=""
            />
            &nbsp; - &nbsp;
            {props.nextTxNumber ? (
              <span>{getFormatedNextTxNumber(props)}</span>
            ) : (
              //<span>{this.props.nextTxNumber.replace('SO-00', 'SO-'+(this.props.customer || {}).customerId) )}</span>
              <img src={LoadingIcon} alt="Invoice" style={{ height: "50px" }} />
            )}
          </li>
        </ul>
      </div>
      <div className="right-itms">
        {

        }
        <DropdownPriceList
          style={{ width: "200px" }}
          items={priceList}
          valueKeyName="priceListName"
          optionKeyName="priceListId"
          value={priceListId}
          canAddNew={true}
          canDelete={true}
          deleteClickHandler={(payload) => {
            deleteClickHandler(props, MODAL_TYPE.PRICE_LIST, payload);
          }}
          onAddNewClick={(payload) => {
            payload.formData = {
              priceListName: payload.textEntered,
              submittedOnce: false,
            };
            handleNewPriceListAddition(props, payload, MODAL_TYPE.PRICE_LIST);
          }}
          placeholder={intl.formatMessage({
            id: "customer.salesOrder.form.priceList.placeholder",
            defaultMessage: "",
          })}
          onSelect={(priceListId) => {
            const priceListData = find(priceList, { priceListId }) || {};
            updateState({
              toUpdatePriceList: true,
              priceListId,
              priceListName: priceListData.priceListName,
              priceListType: priceListData.type,
              priceListPercentage: priceListData.percentage,
            });
          }}
          allowClear={true}
          onClear={() => {
            updateState({
              toUpdatePriceList: true,
              priceListId: undefined,
              priceListName: undefined,
              priceListType: undefined,
              priceListPercentage: undefined,
            });
          }}
        />

        <Dropdown
          style={{ width: "200px" }}
          items={AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST}
          value={
            priceTypeToApply ||
            AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0]
          }
          onSelect={(priceTypeToApply) => {
            updateState({
              priceTypeToApply,
              isToPriceTypeToApply: true,
            });
          }}
          placeholder={intl.formatMessage({
            id: "customer.salesOrder.form.priceType.placeholder",
            defaultMessage: "",
          })}
          allowClear={true}
          onClear={() => {
            updateState({
              priceTypeToApply: undefined,
              isToPriceTypeToApply: false,
            });
          }}
        />
      {

      }
        {props.isSQConvertions ? (
          ""
        ) : (
          <DetailDropdown
            style={{ width: "200px" }}
            items={customers[pageNumber] || []}
            valueKeyName="companyName"
            optionKeyName="customerId"
            value={(customer || {}).companyName}
            canAddNew={true}
            canDelete={true}
            className = "detailDropdown"
                        onSearch= {(searchedText)=>{
                            props.fetchCustomers({
                                // isCompact: true,
                                searchedText: searchedText,
                                pageNumber: 1,
                                hideSpinner: true,
                                pageSize: 100,
                                companyInfo:props.companyInfo,
                                relationshipId: (props.companyInfo || {}).relationshipId,filterData:true
                            })
                        }}
            placeholder={intl.formatMessage({
              id: "customer.salesOrder.form.customer.placeholder",
              defaultMessage: "",
            })}
            onSelect={(customerId) => {
              props.fetchCustomerDetailsByCustomerId({
                customerId,
                relationshipId: (props.companyInfo || {}).relationshipId,
              });
              props.fetchAddresses({
                customerId,
                relationshipId: (props.companyInfo || {}).relationshipId,
                locationType: CONSTANTS.SHIPPING_ADDRESS,
              });
              props.fetchAddresses({
                customerId,
                relationshipId: (props.companyInfo || {}).relationshipId,
                locationType: CONSTANTS.BILLING_ADDRESS,
              });
              props.fetchAllContacts({
                customerId,
                relationshipId: (props.companyInfo || {}).relationshipId,
              });
            }}
            deleteClickHandler={(payload) => {
              props.deleteCustomers({
                groupCustomerIds: [payload.id],
                relationshipId: (props.companyInfo || {}).relationshipId,
              });
            }}
            onAddNewClick={(payload) => {
              props.updateHeaderState({
                customer: {
                  customerDrawerVisible: true,
                  customerName: payload.textEntered,
                },
              });
            }}
            allowClear={true}
            onClear={() => {
              updateState({
                customer: undefined,
                customerName: undefined,
                paymentTermId: undefined,
                paymentTermName: undefined,
                shippingAddress: undefined,
                billingAddress: undefined,
                placeOfSupply: undefined,
                invoiceDueDate: undefined,
                selectedContact: undefined,
              });
            }}
          />
        )}

        {/* <Dropdown
        style={{ width: 150 }}
        placeholder={intl.formatMessage({
          id: 'customer.salesOrder.form.warehouse.placeholder', defaultMessage: ''
        })}
        items={allWarehouses}
        valueKeyName='warehouseName'
        optionKeyName='warehouseName'
        value={(warehouse || {}).warehouseName}
        onSelect={(optionValue, elem) => {
          let warehouse = allWarehouses.find(warehouse => (warehouse.warehouseName === optionValue));
          updateState({ warehouse });
        }}
        allowClear={true}
        onClear={() => {
          updateState({
            warehouse: undefined
          })
        }}
      /> */}
        <Button
          style={{ height: "38px" }}
          onClick={() => {
            props.updateState({
              ledgerDrawerVisible: true,
            });
          }}
        >
          <i className="fa fa-book fa-lg"> </i>
        </Button>
        <div className="currency-converter-modal">
          <span
            style={{ height: "38px" }}
            className="ant-btn ant-btn-icon-only icon"
            onClick={() => updateTemplateDropdown(!templateDropdown)}
          >
            <i className="fa fa-exchange" />
          </span>

          {templateDropdown && (
            <div ref={innerRef} className="content">
              <Row className="mb10">
                <Link
                  onClick={() => {
                    updateTemplateDropdown(false);
                    props.popModalFromStack();
                    props.pushModalToStack({
                      modalBody: <StandardSalesOrder {...props} />,
                      width: "100%",
                      hideTitle: true,
                      hideFooter: true,
                      wrapClassName: "new-transaction-wrapper",
                    });
                  }}
                  // to="/admin/so-create"
                >
                  Standard SO Template
                </Link>
              </Row>

              <Row>
                <Link
                  onClick={() => {
                    updateTemplateDropdown(false);
                    props.popModalFromStack();
                    props.pushModalToStack({
                      modalBody: <MultiCurrencySalesOrder {...props} />,
                      width: "100%",
                      hideTitle: true,
                      hideFooter: true,
                      wrapClassName: "new-transaction-wrapper",
                    });
                  }}
                  // to="/admin/so-multi-currency"
                >
                  Multi Currency SO Template
                </Link>
              </Row>
            </div>
          )}
        </div>
        <Button onClick={() => {
            props.updateDrawerState({
                settingSalesDrawerVisible: true
            })
        }} >
            <i class="fa fa-cog" aria-hidden="true"></i>
        </Button>
      </div>
    </>
  );
};

const getFormatedNextTxNumber = (props) => {
  // const { customer } = props;
  // if (!customer || !customer.customerId) {
  //   return <span>{props.nextTxNumber}</span>;
  // }
  // return <span>{props.nextTxNumber.replace('SO-00', 'SO-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
  return <span>{props.nextTxNumber}</span>;
};

export default injectIntl(HeaderAction);
