import React from "react";
import { ICONS } from "../../../static/constants";
import { FormattedMessage } from "react-intl";
import { Switch, Menu, Button } from "antd";
import DropdownAction from "antd/lib/dropdown";
import { getMomentDateForUIReadOnly } from "../../../utils";

function TaxRuleComp(props) {
    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Actions
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item
                    key="1"
                    value="edit"
                    onClick={() => {

                    }}
                >
                    <i className={ICONS.EDIT} /> Edit
                </Menu.Item>

                <Menu.Item
                    key="2"
                    value="delete"
                    onClick={() => {
                        props.showModal({
                            modalBody: "Are you sure want to delete?",
                            handleSubmit: () => {
                            },
                        });
                    }}
                >
                    <i className={ICONS.DELETE} /> Delete
                </Menu.Item>
            </Menu>
        );
    };
    return (
        <><div className="view-container">
            <div className="view-container-actions">
                <div className="left-actions">
                    <div className="table-heading">
                        <FormattedMessage
                            id="payrollMeta.taxRule.pageHeading"
                            defaultMessage="" />
                        <div className="table-sub-heading">
                            Manage tax rule meta here
                        </div>
                    </div>
                </div>

                <div className="right-actions">
                    <Button
                        style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
                        className="create-action-btn"
                        onClick={() => {
                            props.updateState({ taxRuleDrawer: true });
                        }}
                    >
                        <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
                        <FormattedMessage id="button.create.label" defaultMessage="" />
                    </Button>
                </div>
            </div>
            <div className="table-container" style={{ height: "calc(100% - 59px)" }}>
                <table id="leadSource-table">
                    <thead>
                        <tr>
                            <th>SL</th>
                            <th>Amount</th>
                            <th>Rule For</th>
                            <th>Tax Rate</th>
                            <th>Taxable Amount</th>
                            <th width="6%">Action</th>
                        </tr>
                    </thead><tbody>
                        {([]).map((rowData, i) => {
                            return (
                                <tr key={"crm" + rowData.crmLeadSourceId}>
                                    <td>{i + 1}</td>
                                    <td>{rowData.leadSourceName || "-"}</td>
                                    {/* <td>{e.source_code || "-"}</td> */}
                                    <td>
                                        {rowData.dateCreated
                                            ? getMomentDateForUIReadOnly({
                                                date: rowData.dateCreated,
                                            })
                                            : "-"}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ paddingLeft: "50px" }}>
                                        <DropdownAction
                                            overlay={actionMenu(rowData)}
                                            trigger={["click"]}
                                        >
                                            <i className={ICONS.MORE} />
                                        </DropdownAction>
                                    </td>
                                </tr>
                            );
                            // })
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}
export default TaxRuleComp;