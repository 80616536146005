import React from "react";
import LeadSubSourceList from "../../../components/leads/LeadSubSourceList";
import LeadSubSourceDrawer from "../../../components/leads/LeadSubSourceList/leadSubSourceDrawer";


export class LeadSubSourceListComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <>
        <LeadSubSourceList
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />

       {this.state.leadSubSourceCreateEditDrawer && <LeadSubSourceDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    );
  }
}
