import { Col, Drawer, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { Dropdown } from "../../../general/Dropdown";

const MaketingCampaignDrawerComp = (props) => {
  const rowGutter = [24, 16];


  const closeDrawer = () => {
    props.updateState({
        marketingCampaignDrawer: false,
    });
  };

  return (
    <Drawer
      title={<FormattedMessage id={props.crmLeadSourceId ? "leads.leadForm.formMeta.leadSource.drawer.title.edit"  : "emailMarketing.mailCampaign.drawer.title.create"} />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.marketingCampaignDrawer}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={()=>{
                props.updateDrawerState({submitted: true})
                if(!props.leadSourceName){
                    return
                }
                const payload = {
                    relationshipId: props.companyInfo.relationshipId,
                    crmLeadSourceId: props.crmLeadSourceId || 0,
                    leadSourceName: props.leadSourceName,
                    isActive: props.isActive || 1,
                }
                props.createLeadSource(payload, props);
                closeDrawer();
            }}
            key={1}
          />
        </>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={24}>
          <div className="i-label">Send a Test Mail to<span>*</span></div>
          <TextBox
            placeholder="abc@xyz.com, xyz@abc.com"
            type="text"
            maxLength={75}
            value={props.leadSourceName}
            onChange={(e) => {
              props.updateDrawerState({ leadSourceName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>
        <Col span={24}>
          <div className="i-label">Email Provider<span>*</span></div>
          <Dropdown/>
            <ErrorMsg
                validator={() => { return !props.submitted || props.leadSourceName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>

      </Row>
    </Drawer>
  );
};

export default MaketingCampaignDrawerComp;
