import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchProducts, getProductCount, resetPaginatedData, getOutOfStockCount, getLowStockCount,
   getReorderStockCount, getInventoryEvaluation, exportPdf, exportExcel, deleteProducts } from '../../../../product/Listing/action'
import { showModal, hideModal, pushModalToStack, popModalFromStack, 
  fetchUserPreferences, saveUserPrefrences } from '../../../../../actions/commonActions';
import { fetchInventoryItemDetails } from '../../../../product/detail/action';
// import CategoryDetailsModelComp from '../../../components/product/CategoryDetailsModel/CategoryDetailsModel';
import CategoryDetailsModelComp from '../../../../../components/modal/modalBody/product/CategoryDetailsModel/CategoryDetailsModel';

class CategoryDetailsModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 100
    };
  }



  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      itemId : this.props.CategoryObj?.itemId
    };
    this.props.fetchInventoryItemDetails(payload)
  }

  render() {
   
    return (
      <CategoryDetailsModelComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.product.products,
    productCount: state.product.productCount,
    outOfStockCount: state.product.outOfStockCount,
    reorderStockCount: state.product.reorderStockCount,
    lowStockCount: state.product.lowStockCount,
    inventoryValuation: state.product.inventoryValuation,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    productDetails: state.product.productDetails
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchProducts,
  resetPaginatedData,
  getProductCount,
  getOutOfStockCount,
  getLowStockCount,
  fetchUserPreferences,
  getReorderStockCount,
  getInventoryEvaluation,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  exportPdf,
  exportExcel,
  saveUserPrefrences,
  deleteProducts,
  fetchInventoryItemDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetailsModel);
