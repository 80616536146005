import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MembershipFormListComp from "../../../components/membership/membershipFormList";
import {fetchPaginationDataIfNeeded } from "../../../utils";
import { getAllMembershipForm, deleteRegistrationForm } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { fetchRegistrationFormSettingList } from '../../modal/modalBody/settings/RegisterFormList/action';
import { getSubdomain } from '../../modal/modalBody/settings/CompanyInfo/action';

class membershipFormList extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      templateType: linkProps.templateType,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0, pageSize: 100
    };
    fetchPaginationDataIfNeeded("getAllMembershipForm", "membershipFormList", this.props, payload);
    this.props.getSubdomain(payload);
  }

  componentWillReceiveProps(props){
    if(props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName){
        this.setState({
            subdomainName: props.subdomain.subdomainName
        });
    }
}


  render() {
    return <MembershipFormListComp  {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}>

    </MembershipFormListComp>;
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    membershipFormList: state.membershipReducer.membershipFormList,
    subdomain: state.common.subdomain,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllMembershipForm,
      showModal,
      hideModal,
      pushModalToStack, 
      popModalFromStack,
      deleteRegistrationForm,
      fetchRegistrationFormSettingList,
      getSubdomain
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(membershipFormList);
