import React from "react";
import { Skeleton } from "antd";
import {
  getUserDefinedName,
} from "../../../../../../utils";
import { DownloadOutlined } from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";
import { Dropdown } from "../../../../../general/Dropdown";
import UnderDev from "../../../../../Common/NotFound";

const Documents = (props) => {
 

  return (
    <div className="view-container">
      <Skeleton loading={props.isLoading}>
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              Documents
            </div>
          </div>
          <div className="right-actions">
            <Dropdown placeholder="April" />
          </div>
        </div>
        <div className="table-container" style={{height: '40px'}}>
          <table id="class-table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Type</th>
                <th>Document</th>
                <th>Uploaded On</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {[].map((e, i) => {
                return (
                  <tr key={i}>
                   
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <UnderDev />
      </Skeleton>
    </div>
  );
};

export default Documents;