import React, { Component, Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { AgGridReact } from 'ag-grid-react';
import {   Button, Input } from 'antd';
import {  fixedDecimalNumber, getMomentDateForUI, getMomentDateForUIReadOnly, getFinancialYear } from '../../../../../../utils';
import { CONSTANTS } from '../../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../../static/cssClassConstants';
import PurchaseInvoiceDetail from '../../../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail';
import SalesInvoiceDetails from '../../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';
import BusinessExpenseDetail from '../../../../../../containers/modal/modalBody/finance/BusinessExpense';

const { Search } = Input;

class MatchedTransactionsComponent extends Component {
    constructor(props) {
        super(props);
        const finacialYear = getFinancialYear(this.props.companyInfo);
        this.state = {
            ledgerStartDate: getMomentDateForUI({date: new Date(finacialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT}),
            ledgerEndDate: getMomentDateForUI({date: new Date(), format: CONSTANTS.BACKEND_FORMAT}),
        }
    }

    render() {


        const format = CONSTANTS.DISPLAY_DATE_FORMAT;

        const getRowHeight = (params) => {
            return 36;
        }

        const openInvoiceDetails = (data) => {
            if (Number(this.props.transactionData.Withdrawal || 0) < 0) {
                if (data.expenseMasterId) {
                    const expensePayload = {
                        expenseMasterId: data.expenseMasterId
                    }
                    this.props.pushModalToStack({
                        modalBody: <BusinessExpenseDetail {...this.props} expensePayload={expensePayload} />,
                        width: '100%',
                        hideFooter: true,
                        wrapClassName: 'modal-custom-detail'
                    })
                } else {
                    const invoicePayload = {
                        supplierId: data.supplierId,
                        invoiceMasterId: data.invoiceMasterId
                    }
                    this.props.pushModalToStack({
                        modalBody: <PurchaseInvoiceDetail {...this.props} invoicePayload={invoicePayload} />,
                        width: '100%',
                        hideFooter: true,
                        wrapClassName: 'modal-custom-detail'
                    })
                }
            } else {
                const invoicePayload = {
                    customerId: data.customerId,
                    invoiceMasterId: data.invoiceMasterId
                }
                this.props.pushModalToStack({
                    modalBody: <SalesInvoiceDetails {...this.props} invoicePayload={invoicePayload} />,
                    width: '100%',
                    hideFooter: true,
                    wrapClassName: 'modal-custom-detail'
                })
            }
        }

        const columnDefs = [
            // {
            //     field: 'isChecked',
            //     colId: 'isChecked_1',
            //     headerComponentFramework: () => <Fragment />,
            //     cellRendererFramework: (params) => {
            //         return <Checkbox
            //             onChange={(e) => {
            //                 params.node.setDataValue('isChecked_1', e.target.checked);
            //                 params.node.data.isChecked = e.target.checked;
            //             }}
            //             checked={params.data.isChecked} />
            //     },
            //     width: 50,
            //     resizable: false
            // },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.date' defaultMessage="" />;
                },
                field: "invoiceDate",
                cellRendererFramework: (link) => <div>{getMomentDateForUIReadOnly({date: new Date(link.value), format})}</div>,
                resizable: true,
                maxWidth: 200,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.customer.payInvoice.customerName' defaultMessage="" />;
                },
                field: "customerName",
                resizable: true,
                maxWidth: 200,
                hide: Number(this.props.transactionData.Withdrawal || 0) < 0
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.supplier.payInvoice.supplierName' defaultMessage="" />;
                },
                field: "supplierName",
                resizable: true,
                maxWidth: 200,
                hide: Number(this.props.transactionData.Deposit || 0) > 0,
                cellRendererFramework: (link) => <div>{link.value || 'NA'}</div>,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.reports.partyLedger.gridHeader.transactionNumber' defaultMessage="" />;
                },
                field: "invoiceNumber",
                resizable: true,
                maxWidth: 300,
                cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                    openInvoiceDetails(link.data)
                }}>{link.value}</div>,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='common.project' defaultMessage='' />;
                },
                field: "project",
                minWidth: 70,
                maxWidth: 300,
                suppressMovable: true,
                cellRendererFramework: (link) => {
                    return <div>
                        {link.data.projectNumber ? <span>{link.data.projectNumber + ' (' + link.data.projectName + ')'}</span> : ''}
                    </div>
                },
                resizable: true
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='transactionAmount.text' defaultMessage="" />;
                },
                field: "invoiceTotalAmount",
                cellRendererFramework: (link) => {
                    return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "))}{fixedDecimalNumber(Number(link.value))} </span> : "-"
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.listing.header.action' defaultMessage="" />;
                },
                maxWidth: 100,
                cellRendererFramework: (link) => {
                    return <Button className="ant-btn-primary" onClick={() => { 
                        const modalData = {
                            modalBody: <FormattedMessage
                                id='save.confirmation'
                                defaultMessage=''
                            />,
                            handleSubmit: () => {
                                if(Number(this.props.transactionData.Withdrawal || 0) < 0){
                                    this.props.createMatchedPaymentForSupplier(link.data, this.props) 
                                }else{
                                    this.props.createMatchedPayment(link.data, this.props) 
                                }
                                this.props.popModalFromStack();
                            }
                        };
                        this.props.pushModalToStack(modalData);
                    }}><FormattedMessage id='banking.match' defaultMessage="" /></Button>
                },
            },
          
        ];

        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi= params.columnApi
        }
        return (
            <div>
                <Fragment>
                    <Search
                        placeholder="Enter Searched Text"
                        value={this.state.searchedText}
                        onChange={e => {
                            //updateState({ searchedText: e.target.value, pageNumber: pageNumber || 1 });
                        this.setState({ searchedText: e.target.value});
                            this.gridApi.setQuickFilter(e.target.value);
                        }}
                        onSearch={(searchedText) => {
                            this.setState({ searchedText: searchedText});
                            this.gridApi.setQuickFilter(searchedText);
                        }}
                        style={{ width: 330, height: 36, margin: 10 }}
                    />
                    <div className='agGridWrapper pr10 pl10'>
                        <div className="ag-theme-balham customer-details-table" >
                            <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={this.props.matchedTransactions || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onColumnResized={(params) => {
                                    params.api.resetRowHeights(34);
                                }}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                onGridSizeChanged={(params) => {
                                    params.api.sizeColumnsToFit();
                                }}
                                getRowHeight={getRowHeight}
                            >
                            </AgGridReact>
                            <br></br>
                            
                        </div >
                    </div>
                </Fragment>
            </div>
        )
    }
}

export default injectIntl(MatchedTransactionsComponent);
