import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LogoutComponent from "../../components/Logout";
import {showModal} from "../../actions/commonActions";


class Logout extends Component {
    render() {
        return <LogoutComponent {...this.props} />
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal
}, dispatch);

export default connect(null, mapDispatchToProps)(Logout);
