import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { popModalFromStack } from './../../../../../actions/commonActions';
import TotalSuccessComponent from '../../../../../components/modal/modalBody/EmailMarketing/TotalSuccess';
import { fetchDeliveredData } from './action';

class TotalSuccessCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
      
     
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload ={
          relationshipId: companyInfo.relationshipId,
        }
       
 
this.props.fetchDeliveredData(payload);
    }

    render() {
        return <TotalSuccessComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
    
      deliveredStatusDetails: state.communicationReducer.deliveredStatusDetails
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
 
    popModalFromStack,
    fetchDeliveredData
   
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TotalSuccessCon);