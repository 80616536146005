import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Drawer, Row, Col, Checkbox } from "antd";
//import './customerDrawer.scss';
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { Dropdown } from "../../../general/Dropdown";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { formatDateForBackend } from "../../../../utils";
import CompanyHierarchy from "../../../../assets/images/companyHierarchy.png";
import find from "lodash.find";

function CompanyHierarchyDrawer(props) {
  const imageFullView = () => {
    props.showModal({
      title: (
        <FormattedMessage
          id="company.companyHierarchy.title"
          defaultMessage=""
        />
      ),
      modalBody: (
        <Fragment>
          <div style={{ marginLeft: "190px" }}>
            <img
              id="myImg"
              src={CompanyHierarchy}
              alt="CompanyHierarchy"
              height="500px"
              margin="180px"
            />
          </div>
        </Fragment>
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
      handleSubmit: () => {},
    });
  };

  const closeDrawer = () => {
    props.updateState({
      company: {
        hierarchyDrawerVisible: false,
      },
    });
  };

  const handleSubmit = (props) => {
    if (
      props.hierarchyName &&
      (props.hierarchyParentId || props.isRootHierarchy) &&
      props.hierarchyLevel &&
      props.hierarchyCode
    ) {
      const payload = {
        relationshipId: (props.companyInfo || {}).relationshipId,
        isRootHierarchy: props.isRootHierarchy ? 1 : 0,
        hierarchyName: props.hierarchyName,
        hierarchyDescription: props.hierarchyDescription,
        hierarchyLevel: props.hierarchyLevel,
        hierarchyParentId: props.hierarchyParentId,
        hierarchyCode: props.hierarchyCode,
        dateCreated: formatDateForBackend(new Date()),
      };
      if (props.hierarchyData) {
        payload.hierarchyId = props.hierarchyData.hierarchyId;
      }

      props.showModal({
        modalBody: (
          <FormattedMessage
            id="company.companyHierarchy.confirmation.message"
            defaultMessage=""
          />
        ),
        handleSubmit: () => {
          props.createCompanyHierarchy(payload, props);
          closeDrawer();
        },
      });
    } else {
      props.updateDrawerState({
        invalidHeirarchyLevelCodeClass: !props.heirarchyLevelCode
          ? "input-text-error"
          : "",
        invalidHeirarchyLevelNameClass: !props.heirarchyLevelName
          ? "input-text-error"
          : "",
        invalidHierarchyNameClass: !props.hierarchyName
          ? "input-text-error"
          : "",
        invalidParentHierarchyNameClass: !props.hierarchyParentId
          ? "input-text-error"
          : "",
        submittedOnce: true,
      });
    }
  };
  const hierarchyParentName = find(props.companyHierarchyList, {
    hierarchyId: props.hierarchyParentId,
  })?.hierarchyName;

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage
              id={
                Object.keys(props.hierarchyData || {}).length
                  ? "company.companyHierarchy.editTitle"
                  : "company.companyHierarchy.title"
              }
              defaultMessage=""
            />
          </div>
        }
        width={720}
        zIndex={1}
        keyboard={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-drawer"
        visible={props.hierarchyDrawerVisible}
        afterVisibleChange={(visible) => {
          if (visible) {
            props.initCalls();
          }
        }}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />
            <CustomButton
              intlId="confirmation.submit"
              defaultMessage=""
              htmlType="submit"
              onClick={() => handleSubmit(props)}
              key={1}
            />
          </>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <FormattedMessage
                id="company.hierarchy.hierarchyName"
                defaultMessage=""
              />{" "}
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <FormattedMessage
                id="company.hierarchy.hierarchyLevel"
                defaultMessage=" "
              />
              <span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={45}
                type="text"
                value={props.hierarchyName || undefined}
                onChange={(e) => {
                  props.updateDrawerState({
                    hierarchyName: e.target.value,
                    invalidHierarchyNameClass: "",
                  });
                }}
                placeholder={props.intl.formatMessage({
                  id: "company.heirarchy.name.placeholder",
                  defaultMessage: "",
                })}
                className={props.invalidHierarchyNameClass}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.hierarchyName;
                }}
                message={props.intl.formatMessage({
                  id: "company.heirarchy.name.messgae",
                  defaultMessage: "",
                })}
              />
            </Col>
            <Col span={12}>
              <Dropdown
                items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                // valueKeyName='hierarchyName'
                // optionKeyName='hierarchyId'
                value={props.hierarchyLevel}
                placeholder={props.intl.formatMessage({
                  id: "company.hierarchy.hierarchyLevel.placeholder",
                  defaultMessage: "",
                })}
                onSelect={(optionValue) => {
                  props.updateDrawerState({
                    hierarchyLevel: optionValue,
                    invalidHeirarchyLevelCodeClass: "",
                  });
                  props.resetParentCompanyHierarchyByLevel();
                  props.fetchParentCompanyHierarchiesByLevel({
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    hierarchyLevel: optionValue,
                  });
                }}
                className={props.invalidHeirarchyLevelCodeClass}
              />
              {/* <TextBox
                maxLength={45}
                type='text'
                value={props.hierarchyLevel || undefined}
                onChange={(e) => {
                  props.updateDrawerState({
                    hierarchyLevel: e.target.value,
                    invalidHeirarchyLevelCodeClass: ''
                  });
                }}
                placeholder={'Enter hierarchy level code'}
                className={props.invalidHeirarchyLevelCodeClass}
              /> */}
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.hierarchyLevel;
                }}
                message={props.intl.formatMessage({
                  id: "company.hierarchy.hierarchyLevel.message",
                  defaultMessage: "",
                })}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <FormattedMessage
                id="company.hierarchy.hierarchyCode"
                defaultMessage=" "
              />{" "}
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <FormattedMessage
                id="company.hierarchy.hierarchDescription"
                defaultMessage=" "
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={10}
                type="text"
                value={props.hierarchyCode || undefined}
                onChange={(e) => {
                  props.updateDrawerState({
                    hierarchyCode: e.target.value,
                    invalidHeirarchyLevelNameClass: "",
                  });
                }}
                placeholder={props.intl.formatMessage({
                  id: "company.heirarchy.level.name.placeholder",
                  defaultMessage: "",
                })}
                className={props.invalidHeirarchyLevelNameClass}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.hierarchyCode;
                }}
                message={props.intl.formatMessage({
                  id: "company.heirarchy.level.name.message",
                  defaultMessage: "",
                })}
              />
            </Col>
            <Col span={12}>
              <TextBox
                maxLength={45}
                type="text"
                value={props.hierarchyDescription || undefined}
                onChange={(e) => {
                  props.updateDrawerState({
                    hierarchyDescription: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({
                  id: "company.hierarchy.hierarchDescription.placeholder",
                  defaultMessage: "",
                })}
              />
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <FormattedMessage
                id="company.hierarchy.parentHierarchy"
                defaultMessage=""
              />{" "}
              {props.isRootHierarchy ? "" : <span className="required">*</span>}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                disabled={!(props.companyHierarchyList || []).length}
                items={props.companyHierarchyList || []}
                valueKeyName="hierarchyName"
                optionKeyName="hierarchyId"
                hierarchyLevelKey="hierarchyLevel"
                hierarchyCodeLineKey="hierarchyCode"
                value={props.hierarchyParentName || hierarchyParentName}
                placeholder={props.intl.formatMessage({
                  id: "company.hierarchy.parentHierarchy.placeholder",
                  defaultMessage: "",
                })}
                onSelect={(optionValue, optionKey) => {
                  props.updateDrawerState({
                    hierarchyParentId: optionValue,
                    hierarchyParentName: optionKey.name,
                    invalidParentHierarchyNameClass: "",
                  });
                }}
                className={
                  props.isRootHierarchy
                    ? ""
                    : props.invalidParentHierarchyNameClass
                }
              />
              <ErrorMsg
                validator={() => {
                  return (
                    !props.submittedOnce ||
                    !!props.parentHierarchyName ||
                    props.isRootHierarchy
                  );
                }}
                message={props.intl.formatMessage({
                  id: "company.hierarchy.parentHierarchy.message",
                  defaultMessage: "",
                })}
              />
            </Col>
            <Col span={1}>
              <Checkbox
                value={props.isRootHierarchy}
                onChange={(e) => {
                  props.updateDrawerState({
                    isRootHierarchy: e.target.checked,
                  });
                }}
              />
            </Col>
            <Col span={11}>
              <FormattedMessage
                id="company.hierarchy.rootHierarchy.text"
                defaultMessage=""
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={3}></Col>
            <Col
              span={18}
              className="cursor-pointer"
              onClick={() => {
                imageFullView();
              }}
            >
              <img
                id="myImg"
                src={CompanyHierarchy}
                alt="CompanyHierarchy"
                height="275"
              />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </Fragment>
  );
}

export default injectIntl(CompanyHierarchyDrawer);
