import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import CKEditor from '../../../../../general/CustomCKEditor/ckeditor';
import { showToasterMessage } from "../../../../../../utils";
import { CUSTOM_CONFIGURATION } from '../../../../../../static/ckEditorConfigConstants';
import "../index.scss";
CUSTOM_CONFIGURATION.wordcount.maxCharCount = 1000;
CUSTOM_CONFIGURATION.height = 200;

const saveTemplateData = (props) => {
    if(!props.docName){
        return showToasterMessage({
            messageType: 'error', description: <FormattedMessage id='transaciton.required' defaultMessage='' />
        }); 
    }
    let payload = {
        content: props.content,
        footer: props.footer,
        docName: props.docName,
        relationshipId: (props.companyInfo || {}).relationshipId
    }
    if (props.remarksData && props.docName === props.remarksData.docName) {
        payload.thankyouEmailTemplateId = props.remarksData.thankyouEmailTemplateId;
    }
    const modalData = {
        modalBody: <FormattedMessage id='save.confirmation' defaultMessage='' /> ,
        handleSubmit: () => {
            props.saveOrUpdateThankyouEmailTemplate(payload);
        }
    };
    props.pushModalToStack(modalData);
}
const TxnRemarksTemplate = (props) => {
    console.log("props",props);
    let { content, footer, selectDocumentOptions, companyInfo, updateState } = props;
    return (
        <div className="modal-dialog">
            <div className="modal-content full-width modify-width">
                <div className="inner-content">
                    <Form layout="vertical" hideRequiredMark>
                        <Form.Item
                            label={
                                <FormattedMessage id='modal.settings.remarksTemplate.transaction' defaultMessage='' />
                            }
                        >
                            <Input type='text'
                                    value={props.docName}
                            /> 
                            {/* <Dropdown
                                items={selectDocumentOptions}
                                value={docName}
                                onSelect={
                                    (optionValue) => {
                                        updateState({
                                            docName: optionValue
                                        });
                                        props.fetchRemarksTemplate({ docName: optionValue, relationshipId: companyInfo.relationshipId });
                                    }
                                }
                            /> */}
                        </Form.Item>

                        <Form.Item
                            label={
                                <FormattedMessage id='modal.settings.remarksTemplate.message' defaultMessage='' />
                            }
                        >
                            {
                                <CKEditor
                                className="remarks"
                                    data={content}
                                    key={`${"content"}`}
                                    onChange={(event, editor) => {
                                        const data = event.editor.getData();
                                        updateState({ content: data });
                                    }}
                                    config={CUSTOM_CONFIGURATION}
                                />
                            }
                        </Form.Item>
                        <Form.Item
                            label={
                                <FormattedMessage id='modal.settings.remarksTemplate.footer' defaultMessage='' />
                            }
                        >
                            {
                                <CKEditor
                                    data={footer}
                                    key={`${"footer"}`}
                                    onChange={(event, editor) => {
                                        const data = event.editor.getData();
                                        updateState({ footer: data });
                                    }}
                                    config={CUSTOM_CONFIGURATION}
                                />
                            }
                        </Form.Item>
                    </Form>
                </div>

                <div className="button-group-action">
                    <div className="align-footer">
                        <Button onClick={() => {
                            props.hideModal();
                        }}
                                type="default"
                        >
                            <FormattedMessage id='button.close.label' defaultMessage='' />
                        </Button>

                        <Button
                            type="primary"
                            onClick={() => {
                                saveTemplateData(props);
                            }}
                        >
                            <FormattedMessage id='button.save.label' defaultMessage='' />
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    );

}


export default TxnRemarksTemplate;
