import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WorkShift from '../../../../components/company/AttendanceMeta/WorkShift';
import WorkShiftDrawer from '../../../drawer/company/AttendanceMeta/WorkShift';
import { createWorkShift,getWorkShift,getTotalWorkShiftCount,deleteWorkShift,setWorkShiftStatus } from './action';
import { showModal,hideModal, pushModalToStack } from '../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded ,fetchDataIfNeeded} from '../../../../utils';


class WorkShiftContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
    }
    };
  

  componentDidMount(props) {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
     
    }
   fetchPaginationDataIfNeeded('getWorkShift','workShift',this.props,payload,true)
   fetchDataIfNeeded('getTotalWorkShiftCount', 'workShiftCount', this.props, payload);

  }

  render() {
      return <>

<WorkShift {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
{this.state.workShiftDrawer && <WorkShiftDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    
    ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    headerLoading:state.common.headerLoading,
    workShift: state.employee.workShift,
    workShiftCount: state.employee.workShiftCount,
   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  createWorkShift,
  getWorkShift,
  getTotalWorkShiftCount,
  deleteWorkShift,
  setWorkShiftStatus,
  pushModalToStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WorkShiftContainer);
