import React from "react";
import { Button, Row, Col } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import config from "../../../../../config/environmentConfig";
import "./index.scss";
// import { MODAL_TYPE, CONSTANTS } from '../../../../../static/constants';
import { TextBox } from "../../../../general/TextBox";
// import { getLabelName } from '../../customerInfo';
import {
  base64Toblob,
  getAWSFileURL,
} from "../../../../../utils";
import { ImageCropper } from "../../../../../components/general/ImageCropper";
import { CustomUploader } from "../../../../../components/general/CustomUploader";
import { Dropdown } from "../../../../general/Dropdown";
// import * as find from "lodash.find";
import { ErrorMsg } from "../../../../general/ErrorMessage";

// const { Step } = Steps;

function CreateCategory(props) {
  const { intl, companyInfo } = props;
  const rowGutter = [24, 16];
  const customProps = {
    // type: CONSTANTS.TYPE_IMAGE,
    imgUrl: getAWSFileURL(null, config.BUCKET_NAME.BO_CUSTOMER_INVOICE) || "",
    //isSignature: true,
    multiple: false,
    height: 60,
    width: 60,
    name: "file",
    action: null,
    maxCount: 1,
    accept: ".jpg, .jpeg, .png",
    buttonName: "Upload Category Image",
    onChange: (file) => {
      if (file.file.originFileObj) {
        var reader = new FileReader();
        reader.onload = function (e) {
          // props.updateState({
          //     fileList: file.fileList
          // })
          props.pushModalToStack({
            title: <div className="ant-modal-title">Crop Image</div>,
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                onClick={(e) => {}}
                {...props}
                handleSubmit={(newUrl) => {
                  props.updateState({
                    fileList: base64Toblob(newUrl),
                  });
                }}
                url={e.target.result}
              />
            ),
            width: 800,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
          });
        };

        reader.readAsDataURL(file.file.originFileObj); // convert to base64 string
      }
    },
  };

  return (
    // <div className="template-stepbar-modal">
    <>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage
              id="drawer.inventory.category"
              defaultMessage=""
            />
            <span>*</span>
          </div>
          <div
            className={
              !props.submittedOnce ||
              !props.categoryName ||
              !props.submittedOnce ||
              !!props.categoryName
                ? "product-name-input-error"
                : ""
            }
          >
            <TextBox
              value={props.categoryName}
              placeholder={intl.formatMessage({
                id: "drawer.inventory.category.placeholder",
                defaultMessage: "",
              })}
              onChange={(e) => {
                props.updateState({
                  categoryName: e.target.value,
                  invalidcategoryName: e.target.value ? "" : "input-text-error",
                });
              }}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.categoryName;
              }}
              message={"Enter the value"}
            />
          </div>
        </Col>

        <Col span={12}>
          Parent Category
          <Dropdown
            items={props.productCategories || []}
            valueKeyName="categoryName"
            optionKeyName="inventoryItemCategoryId"
            //   className={(props.submittedOnce && !props.inventoryItemCategoryId) ? 'input-text-error' : ''}
            value={(props.categoryName || {}).inventoryItemCategoryId}
            //   placeholder='Filter by form'
            onSelect={(optionValue, option) => {
              // let selectedForm = find(props.productCategories || [], { productCategoriesName: option.value  }) || {};
              props.updateState({
                parentCategoryName: option.name,
                parentInventoryItemCategoryId: optionValue,
              });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Description</div>
          <TextBox
            value={props.categoryDesc}
            placeholder={"Enter Description"}
            onChange={(e) => {
              props.updateState({
                categoryDesc: e.target.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        {
          <Col span={12}>
            <div className="i-lable">
              <FormattedMessage
                id="drawer.inventory.tabNames.image"
                defaultMessage=""
              />
            </div>
            <CustomUploader {...customProps} />
          </Col>
        }
      </Row>

      {/* <div>
                <input accept='.jpeg, .jpg, .png' type="file" className="" onChange={(e) => {
                    props.updateState({
                        fileToUpload: e.target.files[0]
                    })
                }} /> {(props.fileToUpload || {}).name}
            </div> */}
      <br />
      <div className="modal-footer form-footer" style={{ textAlign: "center" }}>
        <hr></hr>
        <br />
        <Button
          type="primary"
          onClick={() => {
            if (!props.submittedOnce) {
              props.updateState({ submittedOnce: true });
            }
            if (props.categoryName) {
              props.addCategory(
                {
                  relationshipId: companyInfo.relationshipId,
                  fileList: props.fileList,
                  categoryName: props.categoryName,
                  description: props.categoryDesc,
                  inventoryItemCategoryId: props.inventoryItemCategoryId,
                  version: props.version,
                  awsKeyName: props.awsKeyName,
                  isPublished: props.isPublished || 0,
                  subcategories: [],
                  inventoryItemCategoryRefs: [],
                },
                props
              );
              // props.hideModal();
            } else {
              // showToasterMessage({
              //     messageType: 'error', description: 'Category Name in required'
              // });
            }
          }}
        >
          Submit
        </Button>
      </div>
      {/* </div> */}
    </>
  );
}

export default injectIntl(CreateCategory);
