import React from "react";
import { injectIntl } from "react-intl";
import "./index.scss";
import {
  Row,
  Col,
  Radio,
  Checkbox,
} from "antd";
import { TextArea } from "../../general/TextAreaComponent";


const QuestionListComp = (props) => {
  const {
    formSetting = {},
  } = props;

  return (
    <>
      <Row gutter={[16, 16]}>
        {Object.keys(formSetting).length &&
        (formSetting.questionList || []).length > 0 ? (
          <Col span={24}>
            {(formSetting.questionList || []).map((queObj, i) => {
              return (
                queObj.questionText ? 
                  <Row gutter={[0, 16]}>
                    <Col span={24}>
                      <span className="bold-title">{i + 1}. </span>
                      {queObj.questionText || ""}{" "}
                      {queObj.isRequired ? (
                        <span className="required">*</span>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col span={24} style={{ paddingLeft: "10px" }}>
                      {(queObj.optionList || []).length ? (
                        <>
                          {queObj.answerType === "multiChoice" ? 
                          (
                            <Checkbox.Group
                              onChange={(answers) => {
                                queObj.answers = answers;
                                let qamList = props.questionList || [];
                                qamList.splice(i, 1, queObj);
                                props.updateState({
                                  questionList: qamList,
                                  isAnswerSubmitted: true,
                                });
                              }}
                              value={queObj.answers || []}
                            >
                              {(queObj.optionList || []).map(
                                (optObj, j) => {
                                  return (
                                    <div key={"opt" + j}>
                                      <Checkbox
                                        value={optObj.optText}
                                      
                                      >
                                        {" "}
                                        {optObj.optText}{" "}
                                      </Checkbox>
                                    </div>
                                  );
                                }
                              )}
                            </Checkbox.Group>
                          ) 
                            : 
                          (
                            <Radio.Group
                              onChange={(e) => {
                                queObj.answers = e.target.value;
                                let qasList = props.questionList || [];
                                qasList.splice(i, 1, queObj);
                                props.updateState({
                                  questionList: qasList,
                                  isAnswerSubmitted: true,
                                });
                              }}
                              value={queObj.answers}
                            >
                              {(queObj.optionList || []).map(
                                (optObj, k) => {
                                  return optObj.optText ? (
                                    <div key={"optA" + k}>
                                      <Radio value={optObj.optText}>
                                        {" "}
                                        {optObj.optText}{" "}
                                      </Radio>
                                    </div>
                                  ) : (
                                    ""
                                  );
                                }
                              )}
                            </Radio.Group>
                          )}
                        </>
                      ) : (
                        ""
                      )}

                      {queObj.answerType === "shortAnswer" ? (
                        <TextArea
                          style={{
                            maxWidth: "100%",
                            width: "100%",
                            padding: "2px 10px",
                          }}
                          placeholder="Enter here"
                          type="text"
                          value={queObj.answers}
                          onChange={(e) => {
                            e.preventDefault();
                            queObj.answers = e.target.value;
                            let qashList = props.questionList || [];
                            qashList.splice(i, 1, queObj);
                            props.updateState({
                              questionList: qashList,
                              isAnswerSubmitted: true,
                            });
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                :
                ''
              );
            })}
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

export default injectIntl(QuestionListComp);
