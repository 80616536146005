import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import EmailTemplateComp from '../../../components/emailMarketing/emailTemplate';

import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { getAllFormsBuilder,deleteFormBuilder } from '../../communication/NewLetterListing/action';


class EmailTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
          templateType: 'marketingTemplate',
        };
    
      }
    // componentWillMount() {
    //     this.props.updateHeaderState({
    //         collapsed: false
    //     });
    // }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
          companyInfo,
          relationshipId: companyInfo.relationshipId,
          templateType: 'marketingTemplate'
        };
        this.props.getAllFormsBuilder(payload);
      }
      
    render() {
        return <EmailTemplateComp {...this.props}  {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        permissions: state.auth.permissions,
        newsletterTemplateList: state.communicationReducer.newsletterTemplateList,
        listLoading: state.common.listLoading,
        userInfo: state.common.userInfo,


    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    getAllFormsBuilder,
    deleteFormBuilder
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
