import React from 'react';
import { Col, Row, DatePicker, Tooltip, Skeleton,Input } from 'antd';
import { Form } from '@ant-design/compatible';
import { injectIntl, FormattedMessage } from 'react-intl';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { CustomButton } from '../../../general/CustomButton';
import LoadingIcon from '../../../../assets/images/loading.gif';
import * as moment from 'moment';
import { CONSTANTS, ICONS } from '../../../../static/constants';
import CKEditor from '../../../general/CustomCKEditor/ckeditor';
import { CUSTOM_CONFIGURATION } from '../../../../static/ckEditorConfigConstants';
import { fixedDecimalNumber } from '../../../../utils';
const {TextArea} = Input
const PayInvoice = (props) => {
    const {
        intl,
        supplierName,
        invoiceData,
        suppliers,
        invoiceMasterId,
        paymentModeDetails,
        paymentModeName,
        amount,
        invoiceAmount,
        invoiceAmountDue,
        remarks,
        unpaidInvoiceList,
        paymentModeList,
        cashEquivalentLedgers,
        updateState,
        createSupplierPayment,
        hideModal,
    } = props;



    return (
        <div className="header-action-container sales-invoice">
            <Skeleton loading={props.detailLoading} active paragraph={{ rows: 12 }}>

            <Form>
                <Row>
                    <Col span={6}>
                        <Tooltip placement="bottom" title="System Generated Payment Number">
                            <span>
                                <FormattedMessage id='modal.supplier.payInvoice.paymentNumber' defaultMessage='' />
                            <i className={`${ICONS.HELP} cursor-pointer ml5`} />
                              
                            </span>
                        </Tooltip>
                    </Col>

                    <Col span={18}>
                        {props.nextTxNumber ?
                            <span>{getFormatedNextTxNumber(props)}</span>
                            :
                            <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'marginTop': '-25px', 'marginBottom': '-25px' }} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.selectSupplier' defaultMessage='' />
                    </Col>

                    <Col span={18}>
                        <Dropdown
                            items={suppliers[1] || []}
                            valueKeyName='supplierStoreName'
                            optionKeyName='supplierId'
                            className={(props.submittedOnce && !props.supplierName) ? 'input-text-error' : ''}
                            value={(suppliers[1] || []).length ? (supplierName || props.supplierId) : undefined}
                            placeholder={intl.formatMessage(
                                { id: 'modal.supplier.payInvoice.selectSupplier.placeholder', defaultMessage: '' }
                            )}
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    supplierName: undefined,
                                    supplierId: undefined
                                });
                                resetInvoiceDetail(props);
                            }}
                            onSelect={
                                (optionValue) => {
                                    let supplier = (suppliers[1] || []).find(sup => (sup.supplierId === optionValue));
                                    updateState({
                                        supplierName: supplier.supplierStoreName,
                                        supplierId: optionValue
                                    });
                                    resetInvoiceDetail(props);
                                    props.fetchUnpaidInvoices({
                                        supplierId: optionValue,
                                        relationshipId: (props.companyInfo || {}).relationshipId,
                                        pageSize: 100,
                                        pageNumber: 1
                                    })

                                }
                            }
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnce || !!props.supplierName }}
                            message={<FormattedMessage id='modal.supplier.payInvoice.supplierValidation' defaultMessage='' />}
                        />
                    </Col>
                </Row>
                {/* <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.supplierName' defaultMessage=''/>
                    </Col>

                    <Col span={16}>
                        <div className="pl5"><strong>{supplierName}</strong></div>
                    </Col>
                </Row> */}
                <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.paymentDate' defaultMessage='' />
                    </Col>

                    <Col span={18}>
                        <DatePicker
                            allowClear={false}
                            style={{ width: '100%' }}
                            placeholder={intl.formatMessage(
                                { id: 'modal.supplier.payInvoice.paymentDate.placeholder', defaultMessage: '' }
                            )}
                            key={`${moment(new Date(props.paymentDate), CONSTANTS.DISPLAY_DATE_FORMAT)}`}
                            defaultValue={moment(props.paymentDate, CONSTANTS.DISPLAY_DATE_FORMAT)}
                            onChange={(selectedDate) => {
                                updateState({
                                    paymentDate: selectedDate
                                })
                                //props.getNextTxNumber({ date: selectedDate, relationshipId: props.companyInfo.relationshipId });
                            }}
                            className={(props.submittedOnce && !props.paymentDate) ? 'input-text-error' : ''}
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnce || !!props.paymentDate }}
                            message={<FormattedMessage id='modal.supplier.payInvoice.paymentDateValidation' defaultMessage='' />}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.invoiceNumber' defaultMessage='' />
                    </Col>

                    <Col span={18}>
                        <Dropdown
                            items={unpaidInvoiceList[1] || []}
                            valueKeyName='invoiceNumber'
                            optionKeyName='invoiceMasterId'
                            valueKeyName3='invoiceDueAmount'
                            currencyCode={(props.companyInfo || {}).currencyCode + " "}
                            value={props.invoiceNumber ? (`${props.invoiceNumber} (Bal. ${props.companyInfo.currencyCode + " "} ${fixedDecimalNumber(invoiceAmountDue)})` || invoiceMasterId) : undefined}
                            placeholder={intl.formatMessage(
                                { id: 'modal.supplier.payInvoice.invoiceNumber.placeholder', defaultMessage: '' }
                            )}
                            className={(props.submittedOnce && !props.invoiceNumber) ? 'input-text-error' : ''}
                            onSelect={
                                (optionValue) => {
                                    let selectedInvoice = (unpaidInvoiceList[1] || []).find(inv => (inv.invoiceMasterId === optionValue)) || {};
                                    updateState({
                                        invoiceMasterId: optionValue,
                                        invoiceNumber: selectedInvoice.invoiceNumber,
                                        invoiceAmount: selectedInvoice.invoiceTotalAmount,
                                        amount: Number(selectedInvoice.invoiceDueAmount)?.toFixed(2),
                                        invoiceAmountDue: fixedDecimalNumber(selectedInvoice.invoiceDueAmount),
                                        invoiceData: selectedInvoice,
                                        salesQuotationMasterId: selectedInvoice.salesQuotationMasterId,
                                        quotationNumber: selectedInvoice.quotationNumber,
                                        rfqNumber: selectedInvoice.rfqNumber,
                                        rfqMasterId: selectedInvoice.rfqMasterId,
                                        reference: selectedInvoice.referenceNumber
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                resetInvoiceDetail(props);
                            }}
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnce || !!props.invoiceNumber }}
                            message={<FormattedMessage id='modal.supplier.payInvoice.invoiceValidation' defaultMessage='' />}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.paymentMode' defaultMessage='' />
                    </Col>

                    <Col span={18}>
                        <Dropdown
                            className={(props.submittedOnce && !props.paymentModeId) ? 'input-text-error' : ''}
                            items={paymentModeList || []}
                            valueKeyName='paymentModeName'
                            optionKeyName='paymentModeId'
                            value={paymentModeList.length ? (props.paymentModeName || props.paymentModeId) : undefined}
                            placeholder={intl.formatMessage(
                                { id: 'modal.supplier.payInvoice.paymentMode.placeholder', defaultMessage: '' }
                            )}
                            onSelect={
                                (optionValue) => {
                                    let selectedPaymentMode = (paymentModeList || []).find(pay => (pay.paymentModeId === optionValue)) || {};
                                    updateState({
                                        paymentModeId: optionValue,
                                        paymentModeName: selectedPaymentMode.paymentModeName,  
                                        chartOfAccountDetailsId: selectedPaymentMode.paymentModeName === 'Cash' ? props.cashInHandLedger.ledgerAccountId : 0,
                                        ledgerAccountName: selectedPaymentMode.paymentModeName === 'Cash' ? props.cashInHandLedger.txType : '',
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    paymentModeId: undefined,
                                    paymentModeName: undefined
                                })
                            }}
                        />
                         <ErrorMsg
                                    validator={() => { return !props.submittedOnce || !!props.paymentModeId }}
                                    message={<FormattedMessage id='modal.payInvoice.paymentModeValidation' defaultMessage='' />}
                                />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.paymentModeDetails' defaultMessage='' />
                    </Col>

                    <Col span={18}>
                        <TextBox
                            type='text'
                            maxLength= {20}
                            countStyle={{top :"10px"}}
                            value={paymentModeDetails}
                            onChange={(e) => {
                                updateState({
                                    paymentModeDetails: e.target.value
                                });
                            }}
                            placeholder={intl.formatMessage({ id: 'modal.supplier.payInvoice.paymentModeDetails.placeholder', defaultMessage: '' })}
                        />
                    </Col>
                </Row>

                {(paymentModeName && paymentModeName === 'Cash') &&
                    <Row>
                        <Col span={6}>
                            <FormattedMessage id='cashAccount.text' defaultMessage='' />
                        </Col>
                        <Col span={18}>
                            <TextBox type='text' value={props.ledgerAccountName} disabled={true} />
                        </Col>
                    </Row>
                }
                {(paymentModeName && paymentModeName !== 'Cash') &&
                    <Row>
                        <Col span={6}>
                            <FormattedMessage id='modal.supplier.payInvoice.bankAccount' defaultMessage='' />
                            <Tooltip placement="top"
                                title={props.intl.formatMessage({ id: 'cashEquivalents.ledgers' })} trigger="click">
                                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                            </Tooltip>
                        </Col>

                        <Col span={18}>
                            <Dropdown
                                className={(props.submittedOnce && !props.chartOfAccountDetailsId) ? 'input-text-error' : ''}
                                items={cashEquivalentLedgers || []}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='chartOfAccountDetailsId'
                                value={cashEquivalentLedgers.length ? (props.ledgerAccountName || props.chartOfAccountDetailsId) : undefined}
                                placeholder={intl.formatMessage(
                                    { id: 'modal.supplier.payInvoice.bankAccount.placeholder', defaultMessage: '' }
                                )}
                                onSelect={
                                    (optionValue) => {
                                        let selectedAcc = (cashEquivalentLedgers || []).find(acc => (acc.chartOfAccountDetailsId === optionValue)) || {};
                                        updateState({
                                            chartOfAccountDetailsId: optionValue,
                                            ledgerAccountName: selectedAcc.ledgerAccountName
                                        })
                                    }
                                }
                                allowClear={true}
                                onClear={() => {
                                    updateState({
                                        chartOfAccountDetailsId: undefined,
                                        ledgerAccountName: undefined
                                    })
                                }}
                            />
                            <ErrorMsg
                                    validator={() => { return !props.submittedOnce || !!props.chartOfAccountDetailsId }}
                                    message={<FormattedMessage id='modal.payInvoice.ledgerValidation' defaultMessage='' />}
                                />
                            {/* <Tooltip placement="top"
                                title={props.intl.formatMessage({ id: 'cashEquivalents.ledgers' })} trigger="click">
                                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                            </Tooltip> */}
                        </Col>
                    </Row>
                }
                <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.amount' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                        <TextBox
                            type='number'
                            className={(props.submittedOnce && ((props.amount > Number(invoiceAmountDue)) || (props.amount <= 0) || !props.amount)) ? 'input-text-error' : ''}
                            value={Number(amount)}
                            prefix={props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
                            onChange={(e) => {
                                updateState({
                                    amount: Number(e.target.value)?.toFixed(2),
                                    invalidAmountClass: (Number(e.target.value) > Number(invoiceAmountDue)) ? 'input-text-error' : '',
                                });
                            }}
                            placeholder={intl.formatMessage({ id: 'modal.supplier.payInvoice.placeholder', defaultMessage: '' })}
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnce || !!amount }}
                            message={<FormattedMessage id='modal.supplier.payInvoice.amountValidation' defaultMessage='' />}
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnce || !amount || !(Number(amount) > Number(invoiceAmountDue)?.toFixed(2))}}
                            message={<FormattedMessage id='modal.supplier.payInvoice.amountOverValidation' defaultMessage='' />}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.invoiceAmount' defaultMessage='' />
                    </Col>

                    <Col span={18}>
                        <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{invoiceAmount?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                </Row>

                <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.invoiceAmountDue' defaultMessage='' />
                    </Col>

                    <Col span={18}>
                        <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(Number(invoiceAmountDue) - amount)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={6} className='detail-column'>
                        <div >
                            <FormattedMessage id='supplier.purchaseRequest.form.refNumber.label' defaultMessage='' />
                        </div>
                    </Col>
                    <Col span={18}>
                        <TextBox
                            placeholder={intl.formatMessage(
                                { id: 'supplier.purchaseRequest.form.refNumber.placeholder', defaultMessage: '' }
                            )}
                            type='text'
                            maxLength= {20}
                            countStyle={{top :"10px"}}
                            value={props.reference}
                            onChange={(e) => {
                                updateState({
                                    reference: e.target.value
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <FormattedMessage id='modal.supplier.payInvoice.remarks' defaultMessage='' />
                    </Col>

                    <Col span={18}>
                        {/* <TextBox
                            type='text'
                            value={remarks}
                            maxLength={250}
                            onChange={(e) => {
                                updateState({
                                    remarks: e.target.value
                                });
                            }}
                            placeholder={intl.formatMessage({ id: 'modal.supplier.payInvoice.remarks.placeholder', defaultMessage: '' })}
                        /> */}
                         <TextArea 
              placeholder="Enter"
              style={{resize:"none",height:"100px"}}         
              maxLength={250} 
              onChange={(e) => {
                props.updateState({ remarks: e.target.value });
              }}
              onFocus={() => {
                props.updateState({ isFocus: true });
              }}
              onBlur={() => {
                props.updateState({ isFocus: false });
              }}                    
              /> 
                    {props.isFocus ? (
            <div style={{ marginTop:"-35px",zIndex:"auto",position:"absolute" ,marginLeft:"73%",}}>

                {(props.remarks || "").length
                  ? props.remarks.length +
                    " / " +
                    250 +
                    " characters"
                  : ""}
              </div>
            ) : null}  
                    </Col>
                </Row>
                {(invoiceData && invoiceData.projectNumber) &&
                    <Row>
                        <Col span={6}>
                            <FormattedMessage id='modal.supplier.payInvoice.project' defaultMessage='' />
                        </Col>

                        <Col span={18}>
                            <span>{invoiceData.projectNumber + ' (' + invoiceData.projectName + ')'}</span>
                        </Col>
                    </Row>
                }
                {invoiceData?.customerId ?
                    <Row>
                        <Col span={6}>
                            <FormattedMessage id='modal.customer.payInvoice.customerName' defaultMessage='' />
                        </Col>
                        <Col span={18}>
                            <span>{invoiceData.customerName || ''}</span>
                        </Col>
                    </Row>:''
                }
            </Form>
            <div className='form-footer'>
                <CustomButton
                    intlId='confirmation.cancel'
                    defaultMessage=''
                    type='default'
                    className="back-white"
                    key={0}                  
                    onClick={() => {
                        props.pushModalToStack({
                          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                          footer:{submit:{intlId:"button.close.label"}},
                          handleSubmit: () => {
                            props.hideModal();
                          }
                        })
                      }}
                />
                <CustomButton
                    intlId='confirmation.submit'
                    defaultMessage=''
                    htmlType='submit'
                    key={1}
                    onClick={() => {
                        props.updateState({ submittedOnce: true });
                        if (Number(props.amount) > Number(props.invoiceAmountDue)?.toFixed(2)) {
                            return;
                        }
                        if (!props.supplierName || !props.paymentDate || !props.invoiceNumber || !props.paymentModeId || !props.chartOfAccountDetailsId || Number(props.amount) <= 0) {
                            return;
                        }
                        const modalData = {
                            modalBody: <FormattedMessage id='modal.commmon.submit' defaultMessage='' />,
                            handleSubmit: () => { 
                                createSupplierPayment(props);
                                props.popModalFromStack();
                            },
                        };
                        props.pushModalToStack(modalData);
                    }}
                />
            </div>
            </Skeleton>
        </div>
    );
};

const resetInvoiceDetail = (props) => {
    props.updateState({
        invoiceMasterId: undefined,
        invoiceNumber: undefined,
        invoiceAmount: 0,
        amount: 0,
        invoiceAmountDue: 0,
        invoiceData: undefined,
        salesQuotationMasterId: undefined,
        quotationNumber: undefined,
        rfqNumber: undefined,
        rfqMasterId: undefined,
        reference: undefined
    })
}

const getFormatedNextTxNumber = (props) => {
    return <span>{props.nextTxNumber}</span>;
}

export default injectIntl(PayInvoice);