import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
//import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import ReactHtmlParser from 'react-html-parser';
import { showToasterMessage } from '../../../../../utils';

class EWayList extends Component {
    render() {
        const variantKyes = ['retailPrice', 'sku'];
        const getVariantName = (data) => {
            return <span>
                {data.attributeValue1 || ''} {data.attributeValue2 ? ' | ' + data.attributeValue2 : ''} {data.attributeValue3 ? ' | ' + data.attributeValue3 : ''}
            </span>
        }
        // const columnDefs = [
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='product.listing.header.productDetails' defaultMessage='' />;
        //         },
        //         field: "itemName",
        //         resizable: true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='drawer.inventory.description' defaultMessage='' />;
        //         },
        //         field: "description",
        //         editable: true,
        //         resizable: true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='product.listing.amazon.asin' defaultMessage='' />;
        //         },
        //         field: "asin",
        //         editable: true,
        //         resizable: true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='product.listing.amazon.sku' defaultMessage='' />;
        //         },
        //         field: "sku",
        //         editable: true,
        //         resizable: true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='drawer.inventory.brand' defaultMessage='' />;
        //         },
        //         field: "brandName",
        //         editable: true,
        //         resizable: true,
        //     },    
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='drawer.inventory.manufacturer' defaultMessage='' />;
        //         },
        //         field: "manufacturerName",
        //         editable: true,
        //         resizable: true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='product.listing.amazon.itemType' defaultMessage='' />;
        //         },
        //         field: "categoryName",
        //         editable: true,
        //         resizable: true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='product.listing.amazon.taxCode' defaultMessage='' />;
        //         },
        //         field: "productTaxCode",
        //         editable: true,
        //         resizable: true,
        //     },
        //     {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='product.listing.amazon.price' defaultMessage='' />;
        //         },
        //         field: "retailPrice",
        //         editable: true,
        //         resizable: true,
        //     },
        // ];

        const uploadDateToEWay = () => {
            let isPriceMissing = false;
            (this.props.selectedProductList || []).map(item => {
                if (item.hasVariant) {
                    (item.inventoryItemVariantList || []).map(variant => {
                       if(!variant.retailPrice){ isPriceMissing = true}
                       return '';
                    })
                } else {
                    if (!((item.inventoryItemVariantList || [])[0].retailPrice))
                        isPriceMissing = true;
                }
                return '';
            });
            if (isPriceMissing) {
                return showToasterMessage({
                    messageType: 'error', description: 'Price is missing'
                });
            }
            const modalData = {
                modalBody: <FormattedMessage
                    id='addUpdateData'
                    defaultMessage=''
                />,
                handleSubmit: () => {
                    this.props.popModalFromStack();
                    let listToUpload = (this.props.selectedProductList || []).map(item => {
                        let itemObj = {
                            sku: item.sku || '',
                            asin: item.amazonAsinNumber || '',
                            productTaxCode: item.productTaxCode || '',
                            title: item.itemName || '',
                            brand: item.brandName || '',
                            description: item.description || '',
                            msrp: item.retailPrice || 0,
                            currency: (this.props.companyInfo || {}).currencyCode,
                            manufacturer: item.manufacturerName || '',
                            itemType: item.categoryName || '',
                            itemId: item.itemId || '',
                            variantList: [],
                            hasVariant: item.hasVariant,
                            images:[]
                        };
                        if (item.hasVariant) {
                            let grantCurrentStock = 0;
                            let variantList = (item.inventoryItemVariantList || []).map(variant => {
                                let variantAttribute = {};
                                if (variant.attributeName1) {
                                    variantAttribute[variant.attributeName1] = variant.attributeValue1
                                }
                                if (variant.attributeName2) {
                                    variantAttribute[variant.attributeName2] = variant.attributeValue2
                                }
                                if (variant.attributeName3) {
                                    variantAttribute[variant.attributeName3] = variant.attributeValue3
                                }
                                grantCurrentStock += Number(variant.currentStock || 0);
                                return {
                                    variantAttribute,
                                    itemId: item.itemId || '',
                                    variantId: variant.variantId,
                                    msrp: variant.retailPrice,
                                    currentStock: variant.currentStock,
                                    uomName: variant.uomName,
                                    sku: variant.sku,
                                }
                            })
                            itemObj.currentStock= grantCurrentStock;
                            itemObj.variantList = variantList;
                        } else {
                            itemObj.itemId = item.itemId || '';
                            itemObj.variantId = (item.inventoryItemVariantList || [])[0].variantId;
                            itemObj.msrp = (item.inventoryItemVariantList || [])[0].retailPrice;
                            itemObj.currentStock = (item.inventoryItemVariantList || [])[0].currentStock;
                            itemObj.uomName = (item.inventoryItemVariantList || [])[0].uomName;
                            itemObj.sku = (item.inventoryItemVariantList || [])[0].sku;
                        }
                        return itemObj;
                    })
                    this.props.uploadInEWay(listToUpload, this.props);
                }
            };
            this.props.pushModalToStack(modalData);
        }
        const updateProductInfo = (value, key, itemIndex, variantIndex) => {
            if (variantKyes.indexOf(key) > -1) {
                let selectedProductList = this.props.selectedProductList;
                selectedProductList[itemIndex].inventoryItemVariantList[variantIndex][key] = value;
                this.props.updateState({ selectedProductList });
            } else {
                let selectedProductList = this.props.selectedProductList;
                selectedProductList[itemIndex][key] = value;
                this.props.updateState({ selectedProductList });
            }
        }

        return (
            <div>
            <div className="product-scroll">
                <Fragment>
                    <div className="modal-table-container rfqBidding-supplier-list">
                    {/* <div>
                                <CustomAgGrid
                                    columnDefs={columnDefs}
                                    onGridReady={(params) => {
                                        params.api.sizeColumnsToFit();
                                    }}
                                    rowData={this.props.selectedProductList || [{}]}
                                    gridStyle={{
                                        width: '100%',
                                        height: '100%',
                                        marginBottom: '10px'
                                    }} />
                            </div> */}
                              <table style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                    <th><FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /> & <FormattedMessage id='drawer.inventory.description' defaultMessage='' /></th>
                                        <th><FormattedMessage id='product.listing.amazon.sku' defaultMessage='' /></th>
                                        <th><FormattedMessage id='product.listing.amazon.itemType' defaultMessage='' /></th>
                                        <th><FormattedMessage id='drawer.inventory.manufacturer' defaultMessage='' /></th>
                                        <th><FormattedMessage id='drawer.inventory.brand' defaultMessage='' /></th>
                                        <th><FormattedMessage id='product.listing.amazon.taxCode' defaultMessage='' /></th>
                                        <th><FormattedMessage id='product.listing.amazon.price' defaultMessage='' /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (this.props.selectedProductList || []).map((item, i) => {

                                            if (!item.hasVariant) {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <strong>{item.itemName}</strong>
                                                            <div className="description-cell">
                                                                <pre>{ReactHtmlParser(item.description || '')}</pre>
                                                            </div>
                                                        </td>
                                                        <td>{item.sku}</td>
                                                        <td>{item.categoryName}</td>
                                                        <td>{item.manufacturerName}</td>
                                                        <td>{item.brandName}</td>
                                                        <td>{<Input value={item.productTaxCode} onChange={(e) => { updateProductInfo(e.target.value, 'productTaxCode', i, 0) }} />}</td>
                                                        {/* <td>{<Input value={item.sku} onChange={(e) => { updateProductInfo(e.target.value, 'sku', i, 0) }} />}</td> */}

                                                        <td>{<Input type='number' value={item.inventoryItemVariantList[0].retailPrice} onChange={(e) => { updateProductInfo(e.target.value, 'retailPrice', i, 0) }} />}</td>

                                                    </tr>

                                                )
                                            } else {
                                                return (item.inventoryItemVariantList || []).map((variant, j) => {

                                                    return (
                                                        <tr key={i+j}>
                                                            <td>
                                                                <strong><div><div>{item.itemName}</div><div style={{ fontSize: '10px', fontWeight: '500' }}>({getVariantName(variant)})</div></div></strong>
                                                                <div className="description-cell">
                                                                    <pre>{ReactHtmlParser(item.description || '')}</pre>
                                                                </div>
                                                            </td>
                                                            <td>{item.sku}</td>
                                                            <td>{item.categoryName}</td>
                                                            <td>{item.manufacturerName}</td>
                                                            <td>{item.brandName}</td>
                                                            <td>{<Input value={item.productTaxCode} onChange={(e) => { updateProductInfo(e.target.value, 'productTaxCode', i, j) }} />}</td>
                                                            {/* <td>{<Input value={variant.sku} onChange={(e) => { updateProductInfo(e.target.value, 'sku', i, 0) }} />}</td> */}
                                                            <td>{<Input type='number' value={variant.retailPrice} onChange={(e) => { updateProductInfo(e.target.value, 'retailPrice', i, j) }} />}</td>
                                                        </tr>
                                                    )
                                                }
                                                )
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                    </div>
                    
                    <div className="align-footer addfoot" style={{ borderTop: '1px solid #e5e5e5', textAlign: 'center' }}>
                        <br />
                        <Row type="flex" className="action-button-group">
                        <Col span={9}> </Col>
                            <Col span={3}>
                                <Button className="ant-btn-primary" onClick={() => {
                                    uploadDateToEWay();
                                }}><FormattedMessage id='product.listing.eBay.upload' defaultMessage='' /></Button>
                            </Col>

                            <Col span={2}>
                                <Button className="grey-button" onClick={() => {
                                    this.props.hideModal();
                                }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                            </Col>
                        </Row>
                    </div>
                </Fragment>
            </div>
            </div>
        )
    }
}

export default EWayList;
