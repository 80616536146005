import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Col, Row } from 'antd';
import {TextBox} from "../../general/TextBox"
import {Dropdown} from "../../general/Dropdown"
import './supplierDrawer.scss';
import { CustomButton } from '../../general/CustomButton';
import { ErrorMsg } from '../../general/ErrorMessage';
function LandedCostDrawer(props) {
  const closeDrawer = () => {
    props.updateState({
      landedCost: {
        landedCostDrawer: false
      }
    });
  }
  const allLedgerAccounts = [...props.allLedgerAccounts]
  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage id={props.isUpdate ? 'purchase.landindCostSetup.drawer.title.update' : 'purchase.landindCostSetup.drawer.title.create'} defaultMessage='' />
          </div>
        }
        width={720}
        zIndex={1}
        maskClosable={false}
        keyboard={false}
        onClose={closeDrawer}
        className="custom-drawer"
        afterVisibleChange={
          (visible) => {
            if (visible) {
              props.initCalls();
            }
          }
        }
        visible={props.landedCostDrawer}
        footer={
          <>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.submit'
            defaultMessage=''
            htmlType='submit'
            onClick={()=>{handleSubmit(props)}}
            key={1}
          />
          </>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FormattedMessage id='purchase.landindCost.drawer.expenseName'/><span className='required'>*</span>

            <TextBox 
              placeholder='Expense Name'
              type='text'
              className="textbox-pd"
              maxLength={50}
              countStyle={{top:"35px"}}
              value={props.expenseName}
              onChange={(e) => {
                props.updateDrawerState({
                  expenseName: e.target.value
                });
              }} 
            />
            <ErrorMsg
              validator={() => { return !props.submittedOnce || props.expenseName }}
              message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
              } 
            />
          </Col>
          <Col span={12}>
            <FormattedMessage id='purchase.landindCost.drawer.ledgerCredit'/><span className='required'>*</span>
          
            <Dropdown
              items={allLedgerAccounts}
              optionKeyName='chartOfAccountDetailsId'
              valueKeyName='ledgerAccountName'
              value={props.ledgerAccountCredit}
              placeholder={"Select"}
              onSelect={
                (ledgerCreditId, obj) => {
                  props.updateDrawerState({
                    ledgerAccountIdCredit: obj.key,
                    ledgerAccountCredit: obj.name
                  })
                }
              }
            />
            <ErrorMsg
              validator={() => { return !props.submittedOnce || props.ledgerAccountIdCredit }}
              message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
              } 
            />
          </Col>
        
          <Col span={12}>
            <FormattedMessage id='purchase.landindCost.drawer.ledgerDebit'/><span className='required'>*</span>
        
            <Dropdown
              items={allLedgerAccounts}
              optionKeyName='chartOfAccountDetailsId'
              valueKeyName='ledgerAccountName'
              value={props.ledgerAccountDebit}
              placeholder={"Select"}
              onSelect={
                (ledgerCreditId, obj) => {
                  props.updateDrawerState({
                    ledgerAccountIdDebit: obj.key,
                    ledgerAccountDebit: obj.name
                  })
                }
              }
            />
            <ErrorMsg
              validator={() => { return !props.submittedOnce || props.ledgerAccountIdDebit }}
              message={props.intl.formatMessage(
                {
                  id: 'requiredField.missing.msg',
                  defaultMessage: ''
                })
              } 
            />
          </Col>
        </Row>
      </Drawer>
    </Fragment>
  );
}
const handleSubmit = (props) => {
  
  if (!props.expenseName || !props.ledgerAccountIdCredit || !props.ledgerAccountIdDebit) {
    props.updateDrawerState({submittedOnce: true})
    return
  }

  const payload = {
    relationshipId:(props.companyInfo.relationshipId || ''),
    ledgerAccountIdCredit:(props.ledgerAccountIdCredit || ''),
    ledgerAccountIdDebit:(props.ledgerAccountIdDebit || ''),
    expenseType: (props.expenseName || ''),
    lcExpenseTypeId: props.lcExpenseTypeId || '',
    version: props.lcVersion || '', 
  };
  props.pushModalToStack({
    modalBody : <FormattedMessage id={props.isUpdate ? 'save.confirmation' : 'drawer.common.submit'} defaultMessage='' />,

    handleSubmit: () => {
      props.saveLandedCost(props, payload);
    }
  });
}
export default injectIntl(LandedCostDrawer);
