import React, { useState } from 'react';
import { Row, Col, Button } from "antd";
import userImage from './../../../../assets/images/user.png';
import config from '../../../../config/environmentConfig';
import { TextBox } from "../../../general/TextBox";
import { Link } from "react-router-dom";
import { logout } from './../../../../actions/commonActions';
import './lock-screen.scss';
import { getAWSFileURL } from '../../../../utils';

const LockScreen = (props) => {
    const [password, updatePassword] = useState("");
    const companyLogo = getAWSFileURL((props.companyInfo || {}).logoAwsObjectKey, config.BUCKET_NAME.BO_RELATIONSHIP)
    return (
        <div className={"custom-modal show"}>
            <div className="modal-dialog">
                <div className="modal-content full-width lock-screen-container">
                    <Row>
                        <Col span={12} className="form-control">
                            <h3 className="title pb30"><span className="color-text">Alpide</span></h3>
                            {/*<div className="sub-title pb30">Account Locked</div>*/}
                            <div className="user-img-box">
                                <img src={companyLogo} onError={(e) => {
                                    e.target.src = userImage;
                                }} alt="user" />
                            </div>
                            <div className="sub-title highlighted pb30">{props.userInfo.firstName || ""}  {props.userInfo.lastName || ""}</div>
                            <div className="form-width">
                                <TextBox
                                    placeholder="Password..."
                                    value={password}
                                    onChange={(e) => {
                                        updatePassword(e.target.value);
                                    }}
                                    type="password"
                                />
                            </div>

                            <div className="form-width pt20 pb10">
                                <Button type="primary" onClick={() => {
                                    props.verifyCredentials({
                                        email: (props.userInfo || {}).email,
                                        password: password
                                    }, props);
                                }}>
                                    <i className="fa fa-unlock mr10" />
                                    UNLOCK
                                </Button>
                            </div>

                            <div className="form-width">
                                <Link to="/app/logout" className="logout-btn" onClick={() => {
                                    logout();
                                }}>
                                    <i className="fa fa-sign-out mr5" />
                                    Sign Out
                                </Link>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="lock-screen-bg" />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
};

export default LockScreen;
