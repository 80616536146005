import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ImportComponent from "../../../../../components/modal/modalBody/supplier/Import";
import { fetchDataIfNeeded } from "../../../../../utils";
import {
  fetchPaymentTerms,
  addPaymentTerm,
  deletePaymentTerm,
  fetchBusinessTypes,
  addBusinessType,
  deleteBusinessType,
  fetchIndustries,
  addIndustry,
  deleteIndustry,
  fetchSupplierSources,
  addSupplierSource,
  deleteSupplierSource,
} from "../../../../../actions/commonActions";
import { importSupplier, getSupplierImportHeader,downloadExelFile } from "./action";

class ImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      isuploded:false,
  
    };
  }

  componentWillMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId };
    fetchDataIfNeeded(
      "fetchSupplierSources",
      "supplierSources",
      this.props,
      payload
    );
    fetchDataIfNeeded(
      "fetchBusinessTypes",
      "businessTypes",
      this.props,
      payload
    );
    fetchDataIfNeeded("fetchPaymentTerms", "paymentTerms", this.props, payload);
    fetchDataIfNeeded("fetchIndustries", "industries", this.props, payload);
    this.props.getSupplierImportHeader(companyInfo.relationshipId, this.props);
  }
  componentWillReceiveProps(){
//     if(this.props.importSupplierHeader){
//         let headerValues = this.props.importSupplierHeader?.map((item)=>{
//                    return item.columnName
//         })
//         let headerMandatoryValues = this.props.importSupplierHeader?.map((item)=>{
//             if(item.mandatory){
//                 return true
//             }else{
//                  return false
//             }
//  })
//         this.setState({
//             headerValues:headerValues,
//             headerMandatoryValues:headerMandatoryValues
//           });
//     }
}
  render() {
    return (
      <ImportComponent
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    supplierSources: state.common.supplierSources,
    businessTypes: state.common.businessTypes,
    paymentTerms: state.common.paymentTerms,
    industries: state.common.industries,
    companyInfo: state.common.companyInfo,
    importSupplierHeader: state.common.supplierImportHeader,
    headerMandatoryValues: state.common.headerMandatoryValues,
    headerValues: state.common.headerValues,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSupplierSources,
      addSupplierSource,
      deleteSupplierSource,
      fetchPaymentTerms,
      addPaymentTerm,
      deletePaymentTerm,
      fetchIndustries,
      addIndustry,
      deleteIndustry,
      fetchBusinessTypes,
      addBusinessType,
      importSupplier,
      deleteBusinessType,
      getSupplierImportHeader,
      downloadExelFile
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
