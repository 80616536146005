import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, CRM_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const saveOrUpdateLeads = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lead/saveOrUpdateLead`, payload)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Created Successfully.',
            messageType: 'success'
          })
          props.popModalFromStack();
          const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25,
          }
          props.fetchAllLeadList(payload);
          props.getAllLeadListCount(payload);
        })
        .catch((err = {}) => {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
        })
    }
}

export const getAllLeadSources = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios 
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadSource/getAllLeadSources?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: CRM_ACTION_LIST.CRM_LEAD_SOURCE_LIST, data: res.data });
        })
        .catch((err) => {
          if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = CRM_ACTION_LIST.CRM_LEAD_SOURCE_LIST;
            // err.data = err.data;
          }
        })
    }
}

export const getAllLeadStatus = (payload) => {
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios 
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/leadStatus/getAllLeadStatus?relationshipId=${payload.relationshipId}`)
        .then(res => {
            const data = res.data
            dispatch({ type: CRM_ACTION_LIST.CRM_LEAD_STATUS_LIST, data });
        })
        .catch((err) => {
            if (!err.__isRetryRequest) {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            } else {
            err.actionToCall = CRM_ACTION_LIST.CRM_LEAD_STATUS_LIST;
            // err.data = err.data;
            }
        })
    }
}

export const getTeamMembersByTeamName = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios 
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relEmployee/getEmpByTeam`,{
        params:{
          relationshipId:payload.relationshipId,
          teamNames:payload.teamNames
        }
      })
      .then(res => {
          const data = res.data
          dispatch({ type: CRM_ACTION_LIST.LEAD_EMPLOYEE_BY_TEAMNAME, data });
      })
      .catch((err) => {
          if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
          err.actionToCall = CRM_ACTION_LIST.LEAD_EMPLOYEE_BY_TEAMNAME;
          // err.data = err.data;
          }
      })
  }
}

// export const deleteCrmLeadFormListItem = (payload, props) => {
//     return dispatch => {
//       dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//       return axios 
//         .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lead/deleteLead`, payload)
//         .then(res => {
//             showToasterMessage({
//                 description: 'Deleted Successfully!',
//                 messageType: 'success'
//             })

//             const payload = {
//                 relationshipId: props.companyInfo.relationshipId,
//                 pageNumber: 1,
//                 pageSize: 25,
//             }
//             props.fetchAllLeadsList(payload);
//             props.getAllLeadListCount(payload);
//         })
//         .catch((err) => {
//           if (!err.__isRetryRequest) {
//             showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//           } else {
//             err.actionToCall = CRM_ACTION_LIST.CRM_FORM_LIST;
//             err.data = err.data;
//           }
//         })
//     }
// }