import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InvoicePreviewComp from '../../components/invoicePreview/InvoicePreviewComp';
import { getInvoiceDetail, getPayOnlineUrl, getAuthNetPaymentUrl, fetchRelationshipDataByRID,getRecurringInvoiceDataByFormId, processCCPayment } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../actions/commonActions';
import queryString from 'query-string';

class InvoicePreview extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(this.props.location.search) || {};
    this.state = {
      status: linkProps.status,
      transactionId: linkProps.transactionId,
      txStatusMessage: decodeURI(linkProps.message || ''),
      period: 'oneTimePayment',
      neverExpires: 1
    }
  }

  componentDidMount() {
    const linkProps = queryString.parse(this.props.location.search) || {}
    const payload = {
      webInvoiceId: this.props.webInvoiceId || linkProps.webInvoiceId,
    };
    let ids = (linkProps.webInvoiceId || '').split("-")
     this.props.getInvoiceDetail(payload);
    if (ids && ids.length) {
      payload.relationshipId = Number(ids[0]);
      this.props.fetchRelationshipDataByRID(payload);
    }
    const script = document.createElement("script");

    script.src = "https://www.paypal.com/sdk/js?client-id=test&currency=USD";
    script.async = true;

    document.body.appendChild(script);
  }
  componentWillReceiveProps(props) {
    
    if (props.salesInvoiceDetail && props.salesInvoiceDetail.invoiceMasterId && !this.state.isFetchedRecurring ) {
      const payload = {
        relationshipId:props.salesInvoiceDetail.relationshipId,
        customerId:props.salesInvoiceDetail.customerId,
        formId:props.salesInvoiceDetail.registrationFormSettingId,
        module: 'school',
      };
      
      this.props.getRecurringInvoiceDataByFormId(payload);
      this.setState({ isFetchedRecurring: true });
    }
  }

  render() {
    return (
      <Fragment>
        <InvoicePreviewComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}  />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    salesInvoiceDetail: state.common.webInvoiceData,
    companyInfo: state.settings.relationshipData,
    recurringInvoiceData: state.common.recurringInvoiceData
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getInvoiceDetail,
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  getPayOnlineUrl,
  getAuthNetPaymentUrl,
  fetchRelationshipDataByRID,
  getRecurringInvoiceDataByFormId,
  processCCPayment
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePreview);
