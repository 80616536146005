import React, { useState } from "react";
import PageBreadcrumb from "../../PageBreadcrumb";
import "../leads.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import LeadWorkflowListComp from "../../../containers/leads/LeadWorkFlow/Lead";
import { ICONS } from "../../../static/constants";

const LeadWorkflow = (props) => {
  const breadCrumbList = [
    {
      name: <FormattedMessage id="breadcrum.dashboard" defaultMessage="" />,
    },

    {
      name: <FormattedMessage id="sidebar.menuItem.settings" defaultMessage="" />,
    },

    {
      name: (
       "Workflow"
      ),
    },
  ];

  const [componentToDisplay, setcomponentToDisplay] = useState("");




  return (
    <div className="lead-meta" style={{backgroundColor:"#ffffff"}}>

      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            Lead Workflow
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div>

      <div className="profile-container">
        <div className="sidenav">
          <div className="settings-body" style={{paddingTop:"0px"}}>

            <div className="settings-body-child">
              <div className="child-body">


                <div className={`lead-tile ${componentToDisplay==='Lead Source' ? 'lead-back' : ''} ${componentToDisplay==='' ? 'lead-back' : ''}`} 
      
                onClick={()=>setcomponentToDisplay('Lead Source')}>

                  <div className="tile-icon1">
                    <i class="fi fi-rs-globe" style={{fontSize:"24px"}}></i>
                  </div>
                  <div className="tile-title1 b-text">
                    Lead Workflow
                  </div>

                  <div className="tile-description1">
                  Streamline lead management with intuitive workflow automation settings.
                  </div>
                </div>
                <div className={`lead-tile`} 
       
                // onClick={()=>setcomponentToDisplay('Lead Stage')}
                >

                  <div className="tile-icon1" >

                 <i className={ICONS.FILTER} style={{fontSize:"24px"}} /> &nbsp;
                  </div>
                  <div className="tile-title1 b-text">

                   Email Workflow
                  </div>
                  <div className="tile-description1">
                  Automate email responses for timely and personalized communication.
                  </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div className="main-body" style={{padding:"20px"}}>
          
            {<LeadWorkflowListComp />}
            {/* {componentToDisplay ==='Lead Source' ? <LeadSourceListComp /> : <LeadStageList />} */}

        </div>
      </div>

    </div>
  );

};

export default injectIntl(LeadWorkflow);