

import React from "react";
import ReactApexChart from "react-apexcharts"

class StudentByGrade extends React.Component {
    constructor(props) {
        super(props);
       // const dataArr = [25000, 20000, 10000, 0, 15000];
       const dataArr = [];
       const categoriesArr = [];
        Object.values(this.props.totalStudentCountByGrade || {}).forEach((e)=>{
            dataArr.push(Math.round(Number(e)));
        });
        Object.keys(this.props.totalStudentCountByGrade || {}).forEach((e)=>{
            categoriesArr.push('Grade-'+e);
        });
        this.state = {

            series: [{
                name: 'Amount ('+(this.props.companyInfo ||{}).currencyCode+')',
  

                data: dataArr
            }],
            options: {
                chart: {
                    height: 250,
                    type: 'bar',
                    events: {
                        click: function (chart, w, e) {
                        }
                    }
                },
               // colors: colors,
                plotOptions: {
                    bar: {
                        columnWidth: '45%',
                        distributed: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: categoriesArr,
                    labels: {
                        style: {
                            //colors: colors,
                            fontSize: '12px'
                        }
                    }
                }
            },


        };
    }
 getSeriesData =(props) => {
    const dataArr = [];
     Object.values(props.totalStudentCountByGrade || {}).forEach((e)=>{
         dataArr.push(Math.round(Number(e)));
     });
     return  [{
        // name: 'Amount ('+(props.companyInfo ||{}).currencyCode+')',
        name: 'Count',

        data: dataArr
    }];
} 

getOptionsData =(props) => {
    const categoriesArr = [];
     Object.keys(props.totalStudentCountByGrade || {}).forEach((e)=>{
        categoriesArr.push('Grade-'+e);
     });
     return  {
        chart: {
            height: 250,
            type: 'bar',
            events: {
                click: function (chart, w, e) {
                }
            }
        },
       // colors: colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true
            }
        },
        dataLabels: {
            enabled: true
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: categoriesArr,
            labels: {
                style: {
                    //colors: colors,
                    fontSize: '12px'
                }
            }
        }
    };
} 


    render() {

        return (


            <div id="chart">
                <ReactApexChart options={this.getOptionsData(this.props)} series={this.getSeriesData(this.props)} type="bar" height={350} />
            </div>


        );
    }
}

export default StudentByGrade;