import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FundraiserDrawerComponent from "../../../../../../components/drawer/fundraising/campaign/team/fundraiser";
import { pushModalToStack, popModalFromStack, showModal, hideModal } from './../../../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded } from "../../../../../../utils";
import { getAllTeamLead, resetTeamLeadData } from "../action";
import { getTeamMaster } from "../../../../../../containers/fundraising/campaign/campaignDetaill/action";
import { saveTeamMember } from './action'


class FundraiserDrawer extends Component {

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true,
        });
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            companyInfo,
            pageNumber: 0, 
            pageSize: 100,
            relationshipId: companyInfo.relationshipId,
            fundraisingCampaignId: (this.props.teamData || {}).fundraisingCampaignId
        };

        fetchPaginationDataIfNeeded("getAllTeamLead", "teamLeadList", this.props, payload);
        fetchPaginationDataIfNeeded("getTeamMaster", "teamMasterList", this.props, payload);
    }

    render() {
        return (
            <FundraiserDrawerComponent
                {...this.props}
                {...this.state}
                updateState={(data) => {
                    this.setState(data);
                }}
            ></FundraiserDrawerComponent>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        teamLeadList: state.fundraisingReducer.teamLeadList,
        teamMasterList: state.fundraisingReducer.teamMasterList
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            pushModalToStack,
            popModalFromStack,
            getAllTeamLead,
            showModal, 
            hideModal,
            saveTeamMember,
            resetTeamLeadData,
            getTeamMaster
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FundraiserDrawer);
