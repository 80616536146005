import { DEBIT_MEMO_ACTION_LIST } from '../static/constants';
const initialState = {
  debitMemoList: {
    1: []
  },
  debitMemoCount: 0,
  debitMemoDetail: {}
}

const DebitMemoReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_LIST:
      return {
        ...state,
        debitMemoList: {
          ...state.debitMemoList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_COUNT:
      return { ...state, debitMemoCount: action.data };
    case DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_LIST_PAGE_RESET:
      return { ...state, debitMemoList: initialState.debitMemoList };
    case DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_DETAIL:
      return { ...state, debitMemoDetail: action.data };
    case DEBIT_MEMO_ACTION_LIST.DEBIT_MEMO_DETAIL_RESET:
      return { ...state, debitMemoDetail: initialState.debitMemoDetail };  
    case DEBIT_MEMO_ACTION_LIST.NEXT_DEBIT_MEMO_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case DEBIT_MEMO_ACTION_LIST.NEXT_DEBIT_MEMO_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };     
    default:
      return state;
  }
};

export default DebitMemoReducer;