import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegisterComponent from "../../../components/B2B/Register1";
import { fetchRegistrationFormSetting } from './action';
import queryString from 'query-string';
class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formSetting: {},
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(this.props.location.search) || {}
        const payload = {
            relationshipId: this.props.relationshipId || linkProps.relationshipId,
        };
        payload.relationshipId && this.props.fetchRegistrationFormSetting(payload);
    }

    componentWillReceiveProps(props) {
        if (props.registrationFormSetting && props.registrationFormSetting.registrationFormSettingId) {
            this.setState({
                formSetting: JSON.parse(props.registrationFormSetting.formSetting),
            })
        }
    }
    render() {
        return <RegisterComponent {...this.props} {...this.state}/>
    }
}
const mapStateToProps = (state) => {
    return {
        registrationFormSetting: state.settings.registrationFormSetting,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchRegistrationFormSetting
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register);
