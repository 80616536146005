import React, {Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Dropdown } from '../../../../general/Dropdown';
import { Row, Col, Checkbox, Radio, Button, Divider, Switch } from 'antd';
import { TextBox } from '../../../../general/TextBox';
import { MultipleSelectDropdown } from '../../../../general/MultipleSelectDropdown';
import { CRM_COMPONENT_TYPES, MODAL_TYPE } from '../../../../../static/constants';
import { TextArea } from '../../../../general/TextAreaComponent';
// import { CustomUploaderGrid } from '../../../../general/CustomUploaderGrid';
import { showToasterMessage,  } from '../../../../../utils';
import MapComponent from '../locationComp';


const CreateLead = (props) => {

    const { formFieldsSetting } = props;

    const handleLeadSubmit = () =>{

        if(!props.formName){
            return showToasterMessage({
                description: 'Please select form name',
                messageType: 'error'
              })
        }

        for(let i=0; i<(formFieldsSetting||[]).length; i++){
            if(formFieldsSetting[i].isRequired && !formFieldsSetting[i].value){
                return showToasterMessage({
                    description: 'Some required fields are missing',
                    messageType: 'error'
                  })
            }
        }

        // for(let i=0; i<(formFieldsSetting||[]).length; i++){
        //     let fieldObj = formFieldsSetting[i];
        //     delete fieldObj.icon;
        //     if (fieldObj.optionList) { fieldObj.optionList = JSON.stringify(fieldObj.optionList) }
        //     if(typeof fieldObj.value !== 'string') {fieldObj.value = JSON.stringify(fieldObj.value)}
        //     formFieldsSetting[i] = fieldObj;
        //     props.updateState({
        //         formFieldsSetting
        //     });
        // }

        (formFieldsSetting || []).forEach((fieldObj, i) => {
            const { icon, optionList, value } = fieldObj;
        
            delete fieldObj.icon;
        
            if (optionList) {
                fieldObj.optionList = JSON.stringify(optionList);
            }
        
            if (typeof value !== 'string') {
                fieldObj.value = JSON.stringify(value);
            }
        
            formFieldsSetting[i] = fieldObj;
        });
        
        props.updateState({
            formFieldsSetting
        });
        
        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            leadName: props?.formFieldsSetting ? props?.formFieldsSetting[0]?.value : '',
            leadSourceId: props.selectedLeadSource?.id,
            leadSourceName: props.selectedLeadSource?.name,
            leadStatusId: props.selectedLeadStatus?.id,
            statusName: props.selectedLeadStatus?.name,
            crmLeadDetailsList : props.formFieldsSetting,
            crmLeadEmpAssignedList: props.crmLeadEmpAssignedList,
            statusColorForUICell: '',
            createdByEmpId: props.userInfo.relationshipEmployeeId, 
            updatedByEmpId: props.userInfo.relationshipEmployeeId,
            crmLeadFormSettingId: props.crmLeadFormSettingId,
            formName: props.formName,
            crmLeadTeamAssignedList:props.crmLeadTeamAssignedList,
            lat:props.latLong.lat,
            log:props.latLong.lng,
            isWalkInLead:props.isWalkInLead

        }
        props.saveOrUpdateLeads(payload, props)
    }

    const getComponentItem = (item, idx) =>{
        switch(item.type){
            case CRM_COMPONENT_TYPES.INPUT_TEXT: {
                return  <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span>: ''}</div>
                    <TextBox
                        placeholder={item.placeholder}
                        type="text"
                        value={item.value}
                        onChange={(e)=>{
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = e.target.value;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                    />
                </Col>
            }
            case CRM_COMPONENT_TYPES.INPUT_NUMBER: {
                return  <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span>: ''}</div>

                    <TextBox
                        placeholder={item.placeholder}
                        type="text"
                        value={item.value}
                        onChange={(e) =>{
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = e.target.value;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                    />
                </Col>
            }
            case CRM_COMPONENT_TYPES.MULTILINEINPUT: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span>: ''}</div>
                    <TextArea
                        style={{width: '100%', padding: '0px 5px'}}
                        placeholder={item.placeholder}
                        value={item.value}
                        onChange={(e)=>{
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = e.target.value;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                    />
                </Col>
            }
            case CRM_COMPONENT_TYPES.DROPDOWN: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span>: ''}</div>
                    <Dropdown
                        items={item.options}
                        placeholder={item.placeholder}
                        valueKeyName='value'
                        optionKeyName='key'
                        value={item.value}
                        onSelect={
                            (optionValue, option) => {
                                const tempFormFieldsSetting = props.formFieldsSetting;
                                const itemObj = tempFormFieldsSetting[idx];
                                itemObj.value = option.value;
                                tempFormFieldsSetting[idx] = itemObj;
                                props.updateState({
                                    formFieldsSetting: tempFormFieldsSetting
                                })
                            }
                        }
                    />
                </Col>
            }
            case CRM_COMPONENT_TYPES.MULTIPLE_CHOICE: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span>: ''}</div>
                    <Radio.Group 
                        value={item.value}
                        onChange={(e)=>{
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = e.target.value;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                        style={{width: '100%'}}
                    >
                        <Row gutter={[16, 8]}>
                            {(item.options || []).map((rObj)=>{
                                return <Col span={8}><Radio value={rObj.key}>{rObj.value}</Radio></Col>
                            })}
                        </Row>
                    </Radio.Group>
                </Col>
            }
            case CRM_COMPONENT_TYPES.CHECKBOX: {
                return <Col span={8}>
                    <div className='i-label'>{item.label}{item.isRequired ? <span className='required'>*</span>: ''}</div>
                    <Checkbox.Group
                        value={item.value}
                        onChange={(val)=>{
                            const tempFormFieldsSetting = props.formFieldsSetting;
                            const itemObj = tempFormFieldsSetting[idx];
                            itemObj.value = val;
                            tempFormFieldsSetting[idx] = itemObj;
                            props.updateState({
                                formFieldsSetting: tempFormFieldsSetting
                            })
                        }}
                        style={{width: '100%'}}
                    >
                        <Row gutter={[16, 8]}>
                            {(item.options || []).map((obj)=>{
                                return <Col span={8}><Checkbox value={obj.key}>{obj.value}</Checkbox></Col>
                            })}
                        </Row>

                    </Checkbox.Group>
                </Col>
            }
            // case CRM_COMPONENT_TYPES.FILE_ATTACHMENT: {
            //     return <Dragger {...dragProps} >
            //             <p className="ant-upload-drag-icon">
            //                 <i className="fi fi-rr-cloud-upload" style={{fontSize: '30px'}}></i>
            //             </p>
            //             <p className="ant-upload-text">Click or drag file to this area to upload</p>
            //             <p className="ant-upload-hint">
            //                 Support for a single or bulk upload. Strictly prohibited from uploading company data or other
            //                 banned files.
            //             </p>
            //     </Dragger>
            // }
            //     break;
            default: {return}
            }
    }

    const handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: 'Add Status',
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    const addItemConfirmationModal = (props, text, modalType) => {
        const { showModal } = props;
        const labelName = 'Status Name';
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        const modalData = {
            modalBody,
            handleSubmit: () => {
                const payload = {};
                props.saveOrUpdateLeadStatus(payload);
                props.hideModal();
            },
        };
        showModal(modalData);
    }


    return (
        <>
            <div className='lead_head'>Add New Lead</div>
            <Divider style={{margin: '0px'}}/>
                 <div style={{padding:"30px 100px"}}>

            <Switch checked={props.isWalkInLead} onChange={()=>{
                props.updateState({
                    isWalkInLead:!props.isWalkInLead
                })
                
            }}> Walk-in Lead</Switch>
            Walk-in Lead
                 </div>

           {props.isWalkInLead && props.stage !== 2?<div className='lead-map-div'><MapComponent {...props} value ={{ lat: 0, lng: 0 }} radius={100} onChange = {(value)=>{
            props.updateState({
            latLong:value
           })}} /></div>: <div className='lead-div'>
                <Row gutter={[24, 16]}>
             {props.isWalkInLead &&        <Col span={24}>
                    <div>
        <h2>Selected Address:</h2>
        <p>{props.selectedAddress}</p>

        </div>
                    </Col>}
                    <Col span={12}>
                        <div className='i-label'>Select Name<span className='required'>*</span></div>
                        <Dropdown
                            items={props.crmLeadFormList.filter((obj)=> obj.isActive)}
                            valueKeyName='formName'
                            optionKeyName='crmLeadFormSettingId'
                            value={props.crmLeadFormSettingId}
                            placeholder={'Select Form'}
                            onSelect={
                                (optionValue, option) => {
                                    const selectedLeadForm = props.crmLeadFormList.find((obj => obj.crmLeadFormSettingId === optionValue));
                                    props.updateState({
                                        formFieldsSetting: JSON.parse(selectedLeadForm.formFieldsSetting),
                                        formName: option.name,
                                        crmLeadFormSettingId: optionValue,
                                        selectedLeadForm
                                    })
                                }
                            }
                        />
                    </Col>
                    <Col span={12}>
                   {props.selectedLeadForm?.campaignName ?<>
                    <div className='i-label'>Campaign<span className='required'>*</span></div>
                        <TextBox
                        disable
                           value={(props.selectedLeadForm || {})?.campaignName}
                        />
                   </>:""} 
                    </Col>
                    {(props.crmCampaignList[props.pageNumber || 1] || []).find((item)=>item.campaignId === props.selectedLeadForm?.campaignId)?.crmLeadCampaignProjectList?.length  
                        &&
                            <Col className="detail-para"  span={12}>This Campaign is associated with Project -
                            {`${(props.crmCampaignList[props.pageNumber || 1] || []).find((item)=>item.campaignId === props.selectedLeadForm?.campaignId)
                            ?.crmLeadCampaignProjectList?.map((item)=>item.projectName)?.join(" ,")}`} </Col>}
                    
                    <Divider style={{marginBottom: '16px'}} />
                    <Col span={24}>
                        <div className='sub_head'>Lead Details</div>
                    </Col>
                    {
                        (props.formFieldsSetting || []).length ? props.formFieldsSetting.map((item, i)=>{
                            return < Fragment key={'formField-'+i}>
                                {getComponentItem(item, i)}
                                {
                                    i === 2 ? <>
                                    <Col span={8}>

                                    </Col>
                                    <Divider style={{marginBottom: '16px'}}/>
                                    <Col span={24}>
                                        <div className='sub_head'>Additional Details</div>
                                    </Col>
                                    </>
                                    :''
                                }
                            </Fragment>
                        })
                        :
                        <Col span={24}>
                            *Please select form to fill lead details.
                        </Col>
                    }



                    <Divider style={{marginBottom: '16px'}}/>
                    <Col span={24}>
                        <div className='sub_head'>Lead Information</div>
                    </Col>

                    <Col span={8}>
                        <div className='i-label'>Source<span className='required'>*</span></div>
                        <Dropdown
                            items={props.crmLeadSourceList || []}
                            valueKeyName='leadSourceName'
                            optionKeyName='crmLeadSourceId'
                            value={props.selectedLeadSource?.name}
                            placeholder={'Select Source'}
                            onSelect={(id, options)=>{
                                props.updateState({
                                    selectedLeadSource: { id: options.value, name: options.name }
                                })
                            }}
                        />
                    </Col>

                    <Col span={8}>
                        <div className='i-label'>Status<span className='required'>*</span></div>
                        <Dropdown
                            items={props.crmLeadStatusList}
                            valueKeyName='statusName'
                            optionKeyName='leadStatusId'
                            value={props.selectedLeadStatus?.name}
                            placeholder={'Select Status'}
                            onSelect={(id, options)=>{
                                props.updateState({
                                    selectedLeadStatus: { id: options.value, name: options.name }
                                })
                            }}
                            canAddNew={true}
                            onAddNewClick={(payload) => {
                                // let payload = {};
                                payload.formData = {
                                    textBoxValue: payload.textEntered,
                                    submittedOnce: false,
                                    maxLength: 30,
                                  };
                                  handleNewItemAddition(
                                    props,
                                    payload,
                                    MODAL_TYPE.CRM_STATUS
                                  );
                            }}
                        />
                    </Col>
                    <Col span={8}>
                        <div className='i-label'>Assign To Team<span className='required'>*</span></div>
                        <MultipleSelectDropdown
                            items={props.teamList[1] || []}
                            placeholder={'Assign To Team'}
                            mode='multiple'
                            onDeSelect={(teamId, options) => {
                                let tempList = props.crmLeadTeamAssignedList || [];
                                let temp = props.selectedTeam || [];
                                const empExits = tempList.find((obj)=> obj.teamId === teamId)
                                const teamExits = temp.find((obj)=> obj === options.name)
                                if(empExits){
                                    tempList = tempList.filter((obj) => obj.teamId !== teamId);
                                    props.updateState({crmLeadTeamAssignedList: tempList});
                                }
                                if(teamExits){
                                    temp = temp.filter((obj) => obj !== options.name);
                                    props.updateState({selectedTeam: temp});

                                    const payload = { 
                                        relationshipId:props.companyInfo?.relationshipId,
                                        teamNames:temp?.join(",")
                                    };
                                    props.getTeamMembersByTeamName(payload);
                                }

                                
                            }}
                            valueKeyName='teamName'
                            // selectedValue={(props.crmLeadTeamAssignedList|| []).map((obj) => {return obj.teamId})}
                            optionKeyName='teamId'
                            onSelect={(teamId, options) => {
                                let tem = props.selectedTeam || [];
                                let tempList = props.crmLeadTeamAssignedList || [];
                                tempList.push({teamId: options.key, relationshipId: props.companyInfo.relationshipId});
                                tem.push(options.name);
                                props.updateState({crmLeadTeamAssignedList: tempList ,
                                    selectedTeam:tem
                                });
                                const payload = { 
                                    relationshipId:props.companyInfo?.relationshipId,
                                    teamNames:tem?.join(",")
                                };
                                props.getTeamMembersByTeamName(payload);
                            }}
                        />
                    </Col>

                   {props.employeeByTeamName?.length  ? <Col span={8}>
                        <div className='i-label'>Assign Team Member<span className='required'>*</span></div>
                        <MultipleSelectDropdown
                            items={props.employeeByTeamName || []}
                            placeholder={'Assign to'}
                            mode='multiple'
                            onDeSelect={(empId, options) => {
                                let tempList = props.crmLeadEmpAssignedList || [];
                                const empExits = tempList.find((obj)=> obj.relEmployeeId === empId)
                                if(empExits){
                                    tempList = tempList.filter((obj) => obj.relEmployeeId !== empId);
                                    props.updateState({crmLeadEmpAssignedList: tempList});
                                }
                            }}
                            valueKeyName='fullName'
                            valueKeyName2='hierarchyName'
                            // selectedValue={(props.crmLeadEmpAssignedList|| []).map((obj) => {return obj.relEmployeeId})}
                            optionKeyName='relationshipEmployeeId'
                            onSelect={(empId, options) => {
                                let tempList = props.crmLeadEmpAssignedList || [];
                                tempList.push({relEmpId: options.key, relationshipId: props.companyInfo.relationshipId});
                                props.updateState({crmLeadEmpAssignedList: tempList});
                            }}
                        />
                    </Col>:""}
                </Row>

                {/* <div className='form-footer' style={{paddingLeft: '10%', paddingRight: '10%'}}>
                    <div className='f-left'>
                        <Button
                            onClick={()=>{
                                props.popModalFromStack();
                            }}
                        >
                            Cancel
                        </Button>   
                    </div>
                    <div className='f-right'>
                        <Button 
                            type='primary'
                            onClick={()=>{
                                handleLeadSubmit();
                            }}
                        >
                            Submit
                        </Button>
                    </div>
                </div> */}
            </div>}
           { props.isWalkInLead && props.stage ?
           <div className='form-footer' style={{paddingLeft: '10%', paddingRight: '10%'}}>
                    <div className='f-left'>
                        <Button
                            onClick={()=>{
                                if(props.stage === 2 && props.isWalkInLead ){
                                    props.updateState({
                                        stage :1
                                    })
                                }else{

                                    props.popModalFromStack();
                                }
                            }}
                        >
                            {(props.stage === 2 && props.isWalkInLead )?"Back":"Cancel"}
                        </Button>   
                    </div>
                    <div className='f-right'>
                        <Button 
                            type='primary'
                            onClick={()=>{
                                if(props.stage === 1 && props.isWalkInLead ){
                                    if(Object.keys(props.latLong || {})?.length && props.selectedAddress?.length){
                                        props.updateState({
                                            stage :2
                                        })

                                    }else{
                                        showToasterMessage({
                                            description: 'Select Location',
                                            messageType: 'error'
                                          });
                                    }
                                }else{
                                   handleLeadSubmit();
                                }
                            }}
                        >
                            {(props.stage === 1 && props.isWalkInLead ) ?"Next":"Submit"}
                        </Button>
                    </div>
                </div>: <div className='form-footer' style={{paddingLeft: '10%', paddingRight: '10%'}}>
                    <div className='f-left'>
                        <Button
                            onClick={()=>{
                                    props.popModalFromStack(); 
                            }}
                        >
                            {"Cancel"}
                        </Button>   
                    </div>
                    <div className='f-right'>
                        <Button 
                            type='primary'
                            onClick={()=>{
                           
                                   handleLeadSubmit();
                            
                            }}
                        >
                            {"Submit"}
                        </Button>
                    </div>
                </div>}
        </>
    );
};

export default injectIntl(CreateLead);
