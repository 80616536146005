import React, { Component, Fragment } from "react";
import "@ant-design/compatible/assets/index.css";
import { FormattedMessage, injectIntl } from "react-intl";
import LoadingIcon from '../../../../../assets/images/loading.gif';
import { Button } from "antd";

class HeaderAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "default",
      ...props,
    };
  }

  render() {
    return (
      <>
        <div className="left-itms">
          <ul>
            <li className="title">
              RFQ
            </li>
            <li className="sep" />
            <li>
              <FormattedMessage
                id="supplier.rfq.form.rfqNumber.label"
                defaultMessage=""
              />{" "}
              &nbsp; - &nbsp;
              {this.props.nextTxNumber ? (
                <span>{this.props.nextTxNumber}</span>
              ) : (
                <img
                  src={LoadingIcon}
                  alt="Invoice"
                  style={{
                    height: "74px",
                    marginTop: "-25px",
                    marginBottom: "-25px",
                  }}
                />
              )}
            </li>
          </ul>
        </div>
        <div className="right-itms">
          <Button onClick={() => {
                this.state.updateDrawerState({
                    settingPurchaseDrawerVisible: true
                })
            }} >
                <i class="fa fa-cog" aria-hidden="true"></i>
            </Button>
        </div>
      </>
    );
  }
}

export default injectIntl(HeaderAction);
