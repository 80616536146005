import React from 'react';
import { injectIntl } from 'react-intl';
import HeaderAction from './HeaderAction'
import './../../../../assets/stylesheets/common/invoice.scss';
// import PageBreadcrumb from "../../../PageBreadcrumb";
import SoDetails from "./SoDetails";
import ProductDetails from "./ProductDetails";
import { Divider } from 'antd';

const SalesOrderCreateComponent = (props) => {
  return (
      <>    
        <div className="txn-header">
          <HeaderAction {...props} />
        </div>

       <div className='txn-body'>

        <div className="txn-details-container">
          <SoDetails {...props} />
        </div>

        <Divider style={{margin: '0 0 10px 0px'}}/>

        <div className="product-details-container">
          <ProductDetails {...props} />
        </div>
       </div>
      </>
  );
}


export default injectIntl(SalesOrderCreateComponent);
