import axios from "axios";
import config from "../../../../config/environmentConfig";
import { SMS_ACTION_LIST, COMMON_ACTIONS } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const saveClass = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/class/saveOrUpdate`,
        payload
      )
      .then((response) => {
        if (props) {
          props.updateHeaderState({
            schoolDrawers: {
              ...props.schoolDrawers,
              addClassDrawerVisible: false,
              classData: {}
            }
          });
          showToasterMessage({messageType: 'success',
          description: lang[((response || {}).data || {}).message] || "Saved Successfully",})
           props.getClassList && props.getClassList({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId, formId: payload.formId});
          props.getClassListCount && props.getClassListCount({...props, relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,formId: payload.formId});
        }
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  };
};



export const fetchClassSchedule = (payload) => {
  return dispatch => {
    //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/classSchedule/getClassSchedule?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SMS_ACTION_LIST.CLASS_SCHEDULE_LIST, data: res.data });
       // //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
      //  //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });

        if (err.__isRetryRequest) {
          err.actionToCall = SMS_ACTION_LIST.CLASS_SCHEDULE_LIST;
        }
      })
  }
}

export const deleteClassSchedule = (payload) => {
  const requestObj = {
    classScheduleId: payload.id,
    relationshipId: payload.relationshipId,
  }
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/classSchedule/delete`, requestObj)
      .then(res => {
        //dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST_DELETION, data: { id: payload.id } })
        fetchClassSchedule({relationshipId : payload.relationshipId})(dispatch);
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        if (err.__isRetryRequest) {
          err.actionToCall = SMS_ACTION_LIST.CLASS_SCHEDULE_LIST_DELETION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const addClassSchedule = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    classTime: payload.text,
   createdByEmpId: payload.createdByEmpId
  }
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/classSchedule/saveOrUpdate`, requestObj)
      .then(res => {
        //dispatch({ type: COMMON_ACTIONS.DOCUMENT_LIST_ADDITION, data: res.data });
        fetchClassSchedule({relationshipId : payload.relationshipId})(dispatch);
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        if (err.__isRetryRequest) {
          err.actionToCall = SMS_ACTION_LIST.CLASS_SCHEDULE_LIST_ADDITION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}