import React, { Fragment } from "react";
import "./index.scss";

import "@ant-design/compatible/assets/index.css";
import ValidatedInvoiceListingCom from "./validateInvoice";
import SummaryComp from "./summary";

import {  DatePicker, Tabs } from "antd";
import {
 
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
  fixedDecimalNumber,
  getMomentDateForUI,
 
} from "../../../utils";


import { FormattedMessage } from "react-intl";


import NotFiledInvoice from "./notFiledInvoice";
import PageBreadcrumb from "../../PageBreadcrumb";
const { TabPane } = Tabs;
const {MonthPicker} = DatePicker;

const disabledDate = (current) => {
  // Get the current date
  const now = new Date();
  
  // Calculate the start and end dates for the allowed range
  const start = new Date(now.getFullYear() - 7, now.getMonth(), 1); // Same month, 7 years ago
  const end = new Date(now.getFullYear(), now.getMonth() + 1, 1); // First day of the next month
  
  return current && (current < start || current >= end);
};
const GSTFilling = (props) => {
  const { eInvoiceListing, selectedItems } = props;
  const {gstr1Details ,totalTaxableAmount,totalTaxAmount,totalInvoiceCount,totalInvoiceAmount ,totalSgst ,totalIgst ,totalCgst} = eInvoiceListing 
 

  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.finance" defaultMessage="" />
      ),
    },

    {
      name: (
        <FormattedMessage
          id="sidebar.menuItem.invoiceDetail"
          defaultMessage=""
        />
      ),
    },
  ];
  if (!selectedItems) {
    props.updateState({ selectedItems: [] });
  }
  
 
  

  const onTabChange =(key , props)=>{

    if(key==="invoiceNotFiled"){
      const payload = { relationshipId: props.companyInfo.relationshipId ,pageNumber: props.pageNumber , pageSize: props.pageSize , filters:{monthly: getMomentDateForUI({ date: props.selectedDate, format:"DD-MM-YYYY" })}};
      props.updateState({ activeTabKey: key});
      fetchDataIfNeeded('fetchGSTFilingDeatils', 'eInvoiceListing', props, payload);
      fetchPaginationDataIfNeeded('fetchGSTFilingDeatils', 'eInvoiceListing', props, payload);
    }
    else if(key === "validated"){
      const payload ={
        date: props.selectedDate,
      }
      props.updateState({ activeTabKey: key});
      fetchDataIfNeeded('fetchValidatedGstDetails', 'validateInvoiceListing', props, payload);
      fetchPaginationDataIfNeeded('fetchValidatedGstDetails', 'validateInvoiceListing', props, payload);
  
    }
    else{
      const payload ={
        date: props.selectedDate,
      }
      props.updateState({ activeTabKey: key});
      fetchDataIfNeeded('fetchGstSummaryDetails', 'summaryInvoiceListing', props,payload);
      fetchPaginationDataIfNeeded('fetchGstSummaryDetails', 'summaryInvoiceListing', props,payload);
  
    }
  
  }
  return (
    <Fragment>
       <div className="view-top-bar">
            <div className="top-view-left">
                <div className="page-heading">
                     <FormattedMessage id={"sidebar.menuItem.invoiceDetail"} defaultMessage="" /> 
                </div>
                <div className="vertical-sep" />
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
            <div className="top-view-right">
            <MonthPicker
            placeholder="Select month and year"
            disabledDate={disabledDate}
            getPopupContainer={(trigger) => trigger.parentNode}
            format={"MMMM-YYYY"} // Change format to "MMMM-YYYY"
            key={`${
              props.selectedDate
                ? getMomentDateForUI({
                    date: new Date(props.selectedDate),
                    format: "MMMM-YYYY", // Change format to "MMMM-YYYY"
                  })
                : null
            }`}
            defaultValue={
              props.selectedDate
                ? getMomentDateForUI({
                    date: new Date(props.selectedDate),
                    format: "MMMM-YYYY", // Change format to "MMMM-YYYY"
                  })
                : null
            }
            onChange={(date) => {
              props.updateState({
                loading: false,
                showTaxPeriod : "",
                selectedDate: getMomentDateForUI({
                  date: date,
                  format: "MMMM-YYYY", // Change format to "MMMM-YYYY"
                }),
              });
            }}
          />
            </div>
        </div>
      
        {((gstr1Details || [])?.length && props.activeTabKey ==="invoiceNotFiled" ) ? (
          <div
            style={{ height: "calc(100% - 50px)", borderRadius: "10px" }}
            className="table-container"
          >
            <table className="finance-table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>
                    <FormattedMessage
                      id="Invoice Type"
                      defaultMessage="Particulars"
                    />
                  </th>
                  <th>
                    <FormattedMessage id="Count" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="IGST"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="CGST"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="SGST"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage id="Total Tax" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="Total Taxable Amount"
                      defaultMessage=""
                    />
                  </th>
                 
                  <th>
                    <FormattedMessage
                      id="Total Amount"
                      defaultMessage="Total Invoice Value"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(gstr1Details || [])?.map((item, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item?.eInvoiceType}</td>
                      <td>{item?.eInvoiceTypeCount}</td>
                      <td>{fixedDecimalNumber(item?.igst||0)}</td>
                  <td>{fixedDecimalNumber(item?.cgst||0)}</td>
                  <td>{fixedDecimalNumber(item?.sgst||0)}</td>
                      <td>
                        {fixedDecimalNumber(
                          item?.eInvoiceTypeTotalTaxAmount
                        )}
                      </td>
                      <td>
                        {fixedDecimalNumber(item?.eInvoiceTypeTotalTaxableAmount)}
                      </td>
                      <td>
                        {fixedDecimalNumber(item?.eInvoiceTypeTotalAmount)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={2}> Total</td>

                  <td>{totalInvoiceCount || 0}</td>
                  <td>{fixedDecimalNumber(totalIgst||0)}</td>
                  <td>{fixedDecimalNumber(totalCgst||0)}</td>
                  <td>{fixedDecimalNumber(totalSgst||0)}</td>
                  <td>{fixedDecimalNumber(totalTaxAmount||0)}</td>
                  <td>{fixedDecimalNumber(totalTaxableAmount||0)}</td>
                 
                  <td>{fixedDecimalNumber(totalInvoiceAmount||0)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}

        {/* {
          props.activeTabKey === "summary" ? ( <div
            style={{ height: "calc(100% - 50px)", borderRadius: "10px" }}
            className="table-container"
          >
            <table className="finance-table">
              <thead>
                <tr>
                  
                  <th>
                    <FormattedMessage
                      id="Filing Period"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage id="GSTIN" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="Aggregate Turnover in previous FY"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="Aggregate Turnover from Apr- Jun."
                      defaultMessage=""
                    />
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>062024</td>
                  <td>
                  33ABCDE9876A1ZE
                  </td>
                  <td>

                  </td>
                  <td>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>):""
        } */}
   
   <div className="view-container">
    <Tabs 
      activeKey={props.activeTabKey }
      onChange={(key) => {
        onTabChange(key, props);
      }}
      type="line"
    >
  <TabPane key="invoiceNotFiled" tab="Invoice">
    <NotFiledInvoice {...props}/>
  </TabPane>
  <TabPane key="validated" tab="Validated">
    <ValidatedInvoiceListingCom {...props}/>
  </TabPane>
  <TabPane key="summary" tab="Summary">
    <SummaryComp {...props}/>
  </TabPane>
    </Tabs>
    
  
    </div>     
      

       
    </Fragment>
  );
};

export default GSTFilling;
