import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimeTableComp from "../../../components/schoolManagement/TimeTable";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { getSection } from "../section/action";
import { getTimeTableByClassSection } from "./action";
import { getClassList } from "../class/action";

class TimeTableCont extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {

    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 100,
    };

    this.props.getSection(payload);
    this.props.getClassList(payload);
  }

  render() {
    return (
      <>
        <TimeTableComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
          this.setState(data);
          }}
        />
        {/* {this.state.NewTimeTableDrawerVisible && <TimeTableDrawerCont {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />} */}
    </>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    timeTableClasses: state.school.sectionList,
    timeTableList: state.school.timeTableList,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading,
    classList: state.school.classList, 
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTimeTableByClassSection,
      popModalFromStack,
      pushModalToStack,
      showModal,
      hideModal,
      getSection,
      getClassList

    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TimeTableCont);
