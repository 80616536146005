import axios from "axios";
import config from "../../../../../config/environmentConfig"
import { showToasterMessage } from "../../../../../utils";
import { RETURN_SETTINGS } from "../../../../../static/constants";
const { lang } = require("../../../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const saveReturnSettings = (payload, props) => {
    return (dispatch) => {
    
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_return_setting/saveAndUpdate`,payload)
        .then((res) => {
          showToasterMessage({
              description: (res || {}).message || 'Created successfully',
              messageType: 'success'
            })
        })
        .catch((err) => {         
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        });
    };
  };

  export const saveReturnSetting = (payload, props) => {
    return (dispatch) => {
    
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_return_setting/save`,payload)
        .then((res) => {
          showToasterMessage({
              description: (res || {}).message || 'Created successfully',
              messageType: 'success'
            })
        })
        .catch((err) => {         
          showToasterMessage({
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        });
    };
  };

  export const getReturnSettings = (payload) => {
    let url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_return_setting/getEcomReturnSettingByType?relationshipId=${payload.relationshipId}&type=${payload.type}`;
  
    return dispatch => {
        // dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
        return axios.get(url)
          .then(response => {         
            dispatch({ type: RETURN_SETTINGS.RETURN_DETAILS, data : response.data });
    
          })
          .catch(err => {
              showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          
          })
        //   .finally(() => {
        //     dispatch({
        //       type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        //     });
        //   })
      }
    }