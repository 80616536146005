import React, { Fragment } from "react";
import { Row,Col, Skeleton } from "antd";
import { FormattedMessage } from "react-intl";
import { getMomentDateForUIReadOnly } from "../../../../../../utils";
import { CONSTANTS } from "../../../../../../static/constants";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const Information = (props) => {
    // const {employee} = props
   return (
    <Skeleton loading={props.detailLoading} active paragraph={{ rows: 6 }}>

   <div className="details">
      <div className="info-details">
        <Row gutter={[16, 22]}>
        <Col span={12}>
              <div className="b-text">
                <FormattedMessage id="modal.employeeProfile.information.doj" defaultMessage="" />
              </div>
              <div className="light-txt">
              {getMomentDateForUIReadOnly({date: new Date(props.dateOfJoining || <div className="empty-data-box"></div>), format})}
              </div>
            </Col>
            <Col span={12}>
              <div className="b-text">
              <FormattedMessage id="modal.employeeProfile.information.department" defaultMessage="" />
                
              </div>
              <div className="light-txt">
             
                  {props.departmentName || <div className="empty-data-box"></div> } 
                                              
              </div>
            </Col>
          <Col span={12}>
            <div className="b-text"> 
            <FormattedMessage id="modal.employeeProfile.information.designation" defaultMessage="" />

            </div>
           
            <div className="light-txt">
            {props.designationName || <div className="empty-data-box"></div> } 

            </div>
          </Col>
        
        
            <Col span={12}>
              <div className="b-text">
              <FormattedMessage id="modal.employeeProfile.information.jobRole" defaultMessage="" />

              </div>
              <div className="light-txt">
              {props.jobRoleName || <div className="empty-data-box"></div> } 

              </div>

            </Col> 
          <Col span={12}>
            <div className="b-text">
            <FormattedMessage id="modal.employeeProfile.information.employeeType" defaultMessage="" />

            </div>
            <div className="light-txt">
            {props.departmentName || <div className="empty-data-box"></div> } 

            </div>
          </Col>
        
            <Col span={12}>
              <div className="b-text">
              <FormattedMessage id="modal.employeeProfile.information.orgHierarchy" defaultMessage="" />

              </div>
              <div className="light-txt">
              {props.hierarchyName || <div className="empty-data-box"></div> } 

              </div>
            </Col> 
       
            <Col span={12}>
              <div className="b-text">
              <FormattedMessage id="modal.employeeProfile.information.team" defaultMessage="" />

              </div>
              <div className="light-txt">
              {props.teamName || <div className="empty-data-box"></div> } 
               
              </div>
            </Col> 
            <Col span={12}>
              <div className="b-text">
              <FormattedMessage id="modal.employeeProfile.information.dob" defaultMessage="" />

              </div>
              <div className="light-txt">
              {props.dob || <div className="empty-data-box"></div> } 
               
              </div>
            </Col>
            <Col span={12}>
              <div className="b-text">
              <FormattedMessage id="modal.employeeProfile.information.maritalStatus" defaultMessage="" />

              </div>
              <div className="light-txt">
              {props.maritalStatus || <div className="empty-data-box"></div> } 
               
              </div>
            </Col> 
            <Col span={12}>
              <div className="b-text">
              <FormattedMessage id="modal.employeeProfile.information.religion" defaultMessage="" />

              </div>
              <div className="light-txt">
              {props.religion || <div className="empty-data-box"></div> } 
               
              </div>
            </Col> 
        </Row>
      </div>
   </div>
   </Skeleton>
)

}
export default Information;
