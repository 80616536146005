import React, { Fragment
 } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import config from '../../../config/environmentConfig';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Upload } from 'antd';
import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CONSTANTS } from '../../../static/constants';
import {ImageCropper} from '../../general/ImageCropper';
import { base64Toblob, getAWSFileURL } from '../../../utils';
// import  WateLine from '../../../assets/images/pattern/water.png'
// import  Arviine from '../../../assets/images/pattern/arvi.png'
// import  Dotts from '../../../assets/images/pattern/dott.png'
// import  BarfiPatt from '../../../assets/images/pattern/bf.png'
// import  BoxBg from '../../../assets/images/pattern/box-bg.png'
// import  BoxGril from '../../../assets/images/pattern/box-gril.png'
// import  ZicZg from '../../../assets/images/pattern/gic.png'
// import  Linine from '../../../assets/images/pattern/line-crs.png'
// import  StarPat from '../../../assets/images/pattern/line-star.png'
// import  PlusPatt from '../../../assets/images/pattern/plus-o.png'
// import  SunBg from '../../../assets/images/pattern/sun-whi.png'
// import  Swfr from '../../../assets/images/pattern/swirl.png'
// import  Wbb from '../../../assets/images/pattern/webb.png'
// import  WindPatt from '../../../assets/images/pattern/wind.png'
// import  BaanBg from '../../../assets/images/pattern/banna.png'
// import  FlwBg from '../../../assets/images/pattern/floor-tile.png'
// import  FlowOrg from '../../../assets/images/pattern/flow-org.png'
// import  BlueDot from '../../../assets/images/pattern/blue-dott.png'
// import  MoreLiv from '../../../assets/images/pattern/more-leaves-on-green.png'
// import  MoroBlue from '../../../assets/images/pattern/morocco-blue.png'
// import  YalowBg from '../../../assets/images/pattern/yollw.png'
// import  OrientalTiles from '../../../assets/images/pattern/oriental-tiles.png'
// import  BlackBF from '../../../assets/images/pattern/black-bf.png'
// import  BlyeBf from '../../../assets/images/pattern/blue.png'
// import  BlueFlo from '../../../assets/images/pattern/blue-flo.png'
// import  DarkFlo from '../../../assets/images/pattern/dark-flo.png'
// import  FlrtBg from '../../../assets/images/pattern/flrt.png'

const Arviine = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-arvi.png';
const WateLine = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-water.png';
const Dotts = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-dott.png';
const BarfiPatt = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-bf.png';
const BoxBg = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-box-bg.png';
const BoxGril = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-box-gril.png';
const ZicZg = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-gic.png';
const Linine = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-line-crs.png';
const StarPat = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-line-star.png';
const PlusPatt = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-plus-o.png';
const SunBg = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-sun-whi.png';
const Swfr = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-swirl.png';
const Wbb = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-webb.png';
const WindPatt = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-wind.png';
const BaanBg = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-banna.png';
const FlwBg = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-floor-tile.png';
const FlowOrg = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-flow-org.png';
const BlueDot = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-blue-dott.png';
const MoreLiv = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-more-leaves-on-green.png';
const MoroBlue = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-morocco-blue.png';
const YalowBg = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-yollw.png';
const OrientalTiles = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-oriental-tiles.png';
const BlackBF = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-black-bf.png';
const BlyeBf = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-blue.png';
const BlueFlo = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-blue-flo.png';
const DarkFlo = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-dark-flo.png';
const FlrtBg = 'https://alpide-prd-us1-inventory.s3.amazonaws.com/template-bg-flrt.png';


function BackgroundProps(props) {
    const {  loading = false } = props;
    let divRef = document.getElementById('greyBox') || {};

    const loadFile = (event) => {
        var reader = new FileReader();
        reader.onload = function(e){

            props.showModal({
                title: <Fragment>
                    <div className='ant-modal-title'>Crop Image</div>
                </Fragment>,
                //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                modalBody: <ImageCropper onClick={(e) => {
                }} handleSubmit={(newUrl) => {
                    
                   props.uploadImage({
                       file: base64Toblob(newUrl),
                       bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                       objectKey: new Date().getTime(),
                       relationshipId: props.companyInfo?.relationshipId
                   }, function(awsKey){
                     divRef.style.backgroundImage = `url(${getAWSFileURL(awsKey, config.BO_RELATIONSHIP)})`;
                   })
                
                }}{...props} url={e.target.result} />,
                width: 700,
                hideFooter: true,
                wrapClassName: 'Upload-profile-modal',
                type: CONSTANTS.TYPE_IMAGE,
                multiple: false,
            })
        };
        reader.readAsDataURL(event.file.originFileObj);
    };

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );
    
    return (
        <Fragment>
            <div>
                <Row className="but-buttons">
                    <Col span={8}>
                        <FormattedMessage id='buttonBackgraound.text' />
                    </Col>
                    <Col span={16} >
                        <ColorPicker
                        placement='bottomLeft'
                            enableAlpha={false}
                            color={divRef.style.background || '#F3F4F6'}
                            onChange={(obj) => {
                                props.updateState({ bgColor: obj.color });
                                divRef.style.background = obj.color;
                            }}
                            mode="RGB"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <div className='bg-boxs'>
                            <ul>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${FlrtBg})`;
                                }}><img src={FlrtBg} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${DarkFlo})`;
                                }}><img src={DarkFlo} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${BlueFlo})`;
                                }}><img src={BlueFlo} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${BlyeBf})`;
                                }}><img src={BlyeBf} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${BlackBF})`;
                                }}><img src={BlackBF} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${OrientalTiles})`;
                                }}><img src={OrientalTiles} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${YalowBg})`;
                                }}><img src={YalowBg} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${MoroBlue})`;
                                }}><img src={MoroBlue} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${MoreLiv})`;
                                }}><img src={MoreLiv} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${BlueDot})`;
                                }}><img src={BlueDot} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${FlowOrg})`;
                                }}><img src={FlowOrg} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${FlwBg})`;
                                }}><img src={FlwBg} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${BaanBg})`;
                                }}><img src={BaanBg} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${WindPatt})`;
                                }}><img src={WindPatt} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${Wbb})`;
                                }}><img src={Wbb} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${Swfr})`;
                                }}><img src={Swfr} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${SunBg})`;
                                }}><img src={SunBg} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${PlusPatt})`;
                                }}><img src={PlusPatt} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${StarPat})`;
                                }}><img src={StarPat} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${Linine})`;
                                }}><img src={Linine} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${ZicZg})`;
                                }}><img src={ZicZg} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${BoxGril})`;
                                }}><img src={BoxGril} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${BoxBg})`;
                                }}><img src={BoxBg} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${BarfiPatt})`;
                                }}><img src={BarfiPatt} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${Dotts})`;
                                }}><img src={Dotts} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${Arviine})`;
                                }}><img src={Arviine} alt=""/> </li>
                                <li onClick={()=> {
                                divRef.style.backgroundImage = `url(${WateLine})`;
                                }}><img src={WateLine} alt=""/> </li>
                                
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className="but-buttons">
                    {/* <Col span={24}>
                        <FormattedMessage id='browse.text' /> <FormattedMessage id='banking.auditTrail.file' />
                    </Col> */}
                    <Col span={24}>
                        {/* <input type="file" className='choose-file' name="profile_photo" id="fileInput" onChange={loadFile} required/>
                    */}
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        // beforeUpload={loadFile}
                        onChange={loadFile}
                    >
                        {uploadButton}
                    </Upload>
                    </Col>
                </Row>

            </div>
        </Fragment>
    );
}


export default injectIntl(BackgroundProps);
