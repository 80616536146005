import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
import { fetchAllRFQ, resetPaginatedData, getRFQCount } from '../../../../supplier/rfq/Listing/action';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


// export const fetchAllRFQStatus = (payload) => {
//     return dispatch => {
//         dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//         return axios
//           .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lookupRfqStatus/getAllRFQStatus?relationshipId=${payload.relationshipId}`)
//           .then(res => {
//             const { data } = res
//             dispatch({ type: RFQ_ACTION_LIST.RFQ_STATUS_LIST, data });
//             ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           })
//           .catch((err) => {
//             ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//             if(!err.__isRetryRequest){
//               showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//             }else{
//               err.actionToCall = RFQ_ACTION_LIST.RFQ_STATUS_LIST;
//             }
//           })
//       }
//   }

//   export const deleteRFQStatus = (payload) => {
//     const requestObj = {
//       rfqStatusId: payload.id,
//       relationshipId: payload.relationshipId,
//     }
//     return dispatch => {
//       dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//       return axios
//         .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lookupRfqStatus/deleteRFQStatus`, requestObj)
//         .then(res => {
//           dispatch({ type: RFQ_ACTION_LIST.RFQ_STATUS_LIST_DELETION, data: { id: payload.id } })
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           showToasterMessage({
//             description: (res || {}).message || 'Deleted successfully.',
//             messageType: 'success'
//           })
//         })
//         .catch((err = {}) => {
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           if (err.__isRetryRequest) {
//             err.actionToCall = RFQ_ACTION_LIST.RFQ_STATUS_LIST_DELETION;
//           } else {
//             showToasterMessage({
//               description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//             })
//           }
//         })
//     }
//   }

//   export const addRFQStatus = (payload) => {
//     const requestObj = {
//       relationshipId: payload.relationshipId,
//       statusName: payload.text,
//       statusColor: payload.statusColor,
//       dateCreated: getCurrentDateForBackend(new Date()),
//     }
//     return dispatch => {
//       dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//       return axios
//         .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/lookupRfqStatus/createRFQStatus`, requestObj)
//         .then(res => {
//           dispatch({ type: RFQ_ACTION_LIST.RFQ_STATUS_LIST_ADDITION, data: res.data });
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           showToasterMessage({
//             description: (res || {}).message || 'Created successfully.',
//             messageType: 'success'
//           })
//         })
//         .catch((err = {}) => {
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           if (err.__isRetryRequest) {
//             err.actionToCall = RFQ_ACTION_LIST.RFQ_STATUS_LIST_ADDITION;
//           } else {
//             showToasterMessage({
//               description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//             })
//           }
//         })
//     }
//   }

//   export const fetchAllPriceCodes = (payload) => {
//     return dispatch => {
//         dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//         return axios
//           .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqBiddingSupplierPriceCode/getAllPriceCodes?relationshipId=${payload.relationshipId}`)
//           .then(res => {
//             const { data } = res
//             dispatch({ type: RFQ_ACTION_LIST.RFQ_PRICE_CODES, data });
//             ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           })
//           .catch((err) => {
//             ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//             if(!err.__isRetryRequest){
//               showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//             }else{
//               err.actionToCall = RFQ_ACTION_LIST.RFQ_PRICE_CODES;
//             }
//           })
//       }
//   }

//   export const deleteRFQPriceCodes= (payload) => {
//     const requestObj = {
//       rfqBiddingSupplierPriceCodeId: payload.id,
//       relationshipId: payload.relationshipId,
//     }
//     return dispatch => {
//       dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//       return axios
//         .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqBiddingSupplierPriceCode/deletePriceCode`, requestObj)
//         .then(res => {
//           dispatch({ type: RFQ_ACTION_LIST.RFQ_PRICE_CODES_DELETION, data: { id: payload.id } })
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           showToasterMessage({
//             description: (res || {}).message || 'Deleted successfully.',
//             messageType: 'success'
//           })
//         })
//         .catch((err = {}) => {
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           if (err.__isRetryRequest) {
//             err.actionToCall = RFQ_ACTION_LIST.RFQ_PRICE_CODES_DELETION;
//           } else {
//             showToasterMessage({
//               description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//             })
//           }
//         })
//     }
//   }

//   export const addRFQPriceCodes= (payload) => {
//     const requestObj = {
//       relationshipId: payload.relationshipId,
//       priceCode: payload.text,
//       dateCreated: getCurrentDateForBackend(new Date()),
//     }
//     return dispatch => {
//       dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//       return axios
//         .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqBiddingSupplierPriceCode/createPriceCode`, requestObj)
//         .then(res => {
//           dispatch({ type: RFQ_ACTION_LIST.RFQ_PRICE_CODES_ADDITION, data: res.data });
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           showToasterMessage({
//             description: (res || {}).message || 'Created successfully.',
//             messageType: 'success'
//           })
//         })
//         .catch((err = {}) => {
//           ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//           if (err.__isRetryRequest) {
//             err.actionToCall = RFQ_ACTION_LIST.RFQ_PRICE_CODES_ADDITION;
//           } else {
//             showToasterMessage({
//               description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//             })
//           }
//         })
//     }
//   }

export const deleteBiddingSupplierFromRFQ = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqBiddingSupplier/deleteBiddingSupplierFromRFQ?supplierId=${payload.supplierId}&relationshipId=${payload.relationshipId}&rfqBiddingSuppliersId=${payload.rfqBiddingSuppliersId}`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        resetPaginatedData()(dispatch);
        fetchAllRFQ({ relationshipId: props.companyInfo.relationshipId, companyInfo: props.companyInfo })(dispatch);
        getRFQCount({ relationshipId: props.companyInfo.relationshipId, companyInfo: props.companyInfo })(dispatch);
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        });
        const payload = { relationshipId: (props.companyInfo || {}).relationshipId, rfqMasterId: (props.rfqDataForBidding || {}).rfqMasterId, rfqChildMasterId: (props.rfqDataForBidding || {}).rfqChildMasterId };
        props.fetchSupplierRFQDetail(payload)
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          // err.actionToCall = RFQ_ACTION_LIST.RFQ_PRICE_CODES_ADDITION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const updateRFQSupplier = (requestObj, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqBiddingSupplier/updateBiddingSupplier`, requestObj, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        resetPaginatedData()(dispatch);
        fetchAllRFQ({ relationshipId: props.companyInfo.relationshipId, companyInfo: props.companyInfo })(dispatch);
        showToasterMessage({
          description: (res || {}).message || 'Updated successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const updateRFQSupplierAddress = (requestObj, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRFQRest/saveOrUpdate`, requestObj, { headers: { 'Content-Type': 'application/json' } })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        resetPaginatedData()(dispatch);
        fetchAllRFQ({ relationshipId: props.companyInfo.relationshipId, companyInfo: props.companyInfo })(dispatch);
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}