import React, { Fragment, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Switch, Menu, Skeleton, Empty } from "antd";
import PageBreadcrumb from "../../PageBreadcrumb";
import { CONSTANTS, ICONS } from '../../../static/constants';
import DropdownAction from "antd/lib/dropdown";
import "./index.scss";

const CommunicationTemplateComp = (props) => {

  const breadCrumbList = [
    {
      name: <FormattedMessage id='breadcrum.communications' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='breadcrum.communications.communicationTemplate' defaultMessage='' />,
    }
  ];

  const sortColumn = (e) => {
    // sortTable(document.getElementById('newsletter-table'), e.target.cellIndex, 1 - dir);
    // updateState({ dir: 1 - dir })
  }
  const [showNextRow, setShowNextRow] = useState(true);
  const [showNextRow1, setShowNextRow1] = useState(false);

  const handleClick = () => {
    setShowNextRow(!showNextRow);
  };
  const handleClick1 = () => {
    setShowNextRow1(!showNextRow1);
  };

  const onChange = (checked) => {
    console.log(`Switch changed to ${checked}`);
  };

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="edit" 
          onClick={()=>{
            props.updateDrawerState({
              communicationTemplateDrawerVisiable: true,
              
          })
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

          <Menu.Item key="2" 
          onClick={()=>{
          }}
          >
            <i className={ICONS.DELETE} />
            Delete
          </Menu.Item>

        </Menu >
    )
  };


  return (
    <Fragment >
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='heading.communications.communicationTemplate' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
      </div>
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='heading.table.communicationTemplate' defaultMessage='' />
            </div>
          </div>
          <div className="right-actions">
          </div>
        </div>
        <div className="table-container">
          <table id='communicationTemplate-table' className="communicationTemplateTable">
            <thead>
              <tr>
                <th onClick={sortColumn} width="25%"><FormattedMessage id="communicationTemplate.table-heading-name.module" defaultMessage="" /></th>
                <th onClick={sortColumn}><FormattedMessage id='communicationTemplate.table-heading-name.transaction' defaultMessage='' /></th>
                <th onClick={sortColumn}><FormattedMessage id='communicationTemplate.table-heading-name.event-type' defaultMessage='' /></th>
                <th className="text-center"><FormattedMessage id='communicationTemplate.table-heading-name.communication' defaultMessage='' /></th>
                <th onClick={sortColumn}><FormattedMessage id='communicationTemplate.table-heading-name.date' defaultMessage='' /></th>
                <th className="text-center"><FormattedMessage id='communicationTemplate.table-heading-name.status' defaultMessage="" /></th>
                <th className="text-center"><FormattedMessage id='communicationTemplate.table-heading-name.automate' defaultMessage="" /></th>
                <th className="text-center"><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="25%">
                  <div className="flexItem" onClick={handleClick}> 
                    <i className={showNextRow ? ICONS.MINUS_CIRCLE : ICONS.PLUS_CIRCLE}></i> Sales
                  </div>
                </td>
                <td></td>
                <td></td>
                <td className="text-center">
                </td>
                <td></td>
                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-center">
                </td>
              </tr>
              {showNextRow && (
                <>
                <tr>
                  <td width="25%">
                    <div className={`${showNextRow ? 'mrL30' : 'mrL0'}`}></div>
                  </td>
                  <td>Sales Order</td>
                  <td>Created</td>
                  <td className="text-center">
                    <div className="iconArea">
                      <span className="activeMail"><i className={ICONS.MAIL}></i></span>
                      <span className=""><i className={ICONS.SMS_ICON}></i></span>
                      <span className=""><i className={ICONS.WHATSAPP_ICON}></i></span>
                    </div>
                  </td>
                  <td>12-12-2024</td>
                  <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" checked onChange={onChange} /></td>
                  <td className="text-center"><span className="status-code rejected">Deactive</span></td>
                  <td className="text-center">
                    <DropdownAction overlay={actionMenu} trigger={['click']}>
                      <i className={ICONS.MORE} />
                    </DropdownAction>
                  </td>
                </tr>
                <tr>
                 <td width="25%">
                   <div className={`${showNextRow ? 'mrL30' : 'mrL0'}`}></div>
                 </td>
                 <td>Sales Inquiry</td>
                 <td>Edited</td>
                 <td className="text-center">
                   <div className="iconArea">
                     <span className="active"><i className={ICONS.MAIL}></i></span>
                     <span className="activeSMS"><i className={ICONS.SMS_ICON}></i></span>
                     <span className=""><i className={ICONS.WHATSAPP_ICON}></i></span>
                   </div>
                 </td>
                 <td>12-12-2024</td>
                 <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" checked onChange={onChange} /></td>
                 <td className="text-center"><span className="status-code ready">Active</span></td>
                 <td className="text-center">
                   <DropdownAction overlay={actionMenu} trigger={['click']}>
                     <i className={ICONS.MORE} />
                   </DropdownAction>
                 </td>
                </tr>
                <tr>
                <td>
                  <div className={`${showNextRow ? 'mrL30' : 'mrL0'}`}></div>
                </td>
                <td>Sales Quote</td>
                <td>Edited</td>
                <td className="text-center">
                  <div className="iconArea">
                    <span className=""><i className={ICONS.MAIL}></i></span>
                    <span className=""><i className={ICONS.SMS_ICON}></i></span>
                    <span className="activeWhatsapp"><i className={ICONS.WHATSAPP_ICON}></i></span>
                  </div>
                </td>
                <td>12-12-2024</td>
                <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" checked onChange={onChange} /></td>
                <td className="text-center"><span className="status-code rejected">Deactive</span></td>
                <td className="text-center">
                  <DropdownAction overlay={actionMenu} trigger={['click']}>
                    <i className={ICONS.MORE} />
                  </DropdownAction>
                </td>
                </tr>
                <tr>
                    <td>
                        <div className={`${showNextRow1 ? 'mrL30' : 'mrL0'}`}></div>
                      </td>
                    <td>Sales Invoice</td>
                    <td>Created</td>
                    <td className="text-center">
                      <div className="iconArea">
                        <span className="activeMail"><i className={ICONS.MAIL}></i></span>
                        <span className=""><i className={ICONS.SMS_ICON}></i></span>
                        <span className=""><i className={ICONS.WHATSAPP_ICON}></i></span>
                      </div>
                    </td>
                    <td>12-12-2024</td>
                    <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" onChange={onChange} /></td>
                    <td className="text-center"><span className="status-code ready">Active</span></td>
                    <td className="text-center">
                      <DropdownAction overlay={actionMenu} trigger={['click']}>
                        <i className={ICONS.MORE} />
                      </DropdownAction>
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div className={`${showNextRow1 ? 'mrL30' : 'mrL0'}`}></div>
                      </td>
                    <td>Sales Support</td>
                    <td>Created</td>
                    <td className="text-center">
                      <div className="iconArea">
                        <span className=""><i className={ICONS.MAIL}></i></span>
                        <span className="activeSMS"><i className={ICONS.SMS_ICON}></i></span>
                        <span className=""><i className={ICONS.WHATSAPP_ICON}></i></span>
                      </div>
                    </td>
                    <td>12-12-2024</td>
                    <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" onChange={onChange} /></td>
                    <td className="text-center"><span className="status-code ready">Active</span></td>
                    <td className="text-center">
                      <DropdownAction overlay={actionMenu} trigger={['click']}>
                        <i className={ICONS.MORE} />
                      </DropdownAction>
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div className={`${showNextRow ? 'mrL30' : 'mrL0'}`}></div>
                      </td>
                    <td>Coupon Management</td>
                    <td>Edited</td>
                    <td className="text-center">
                      <div className="iconArea">
                        <span className=""><i className={ICONS.MAIL}></i></span>
                        <span className=""><i className={ICONS.SMS_ICON}></i></span>
                        <span className="activeWhatsapp"><i className={ICONS.WHATSAPP_ICON}></i></span>
                      </div>
                    </td>
                    <td>12-12-2024</td>
                    <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" onChange={onChange} /></td>
                    <td className="text-center"><span className="status-code rejected">Deactive</span></td>
                    <td className="text-center">
                      <DropdownAction overlay={actionMenu} trigger={['click']}>
                        <i className={ICONS.MORE} />
                      </DropdownAction>
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td>
                  <div className="flexItem" onClick={handleClick1}> 
                  <i className={showNextRow1 ? ICONS.MINUS_CIRCLE : ICONS.PLUS_CIRCLE}></i> Sales
                  </div>
                </td>
                <td></td>
                <td></td>
                <td className="text-center">
                </td>
                <td></td>
                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-center">
                </td>
              </tr>
              {showNextRow1 && (
                <>
                  <tr>
                    <td>
                        <div className={`${showNextRow1 ? 'mrL30' : 'mrL0'}`}>Sales</div>
                      </td>
                    <td>Sales Invoice</td>
                    <td>Created</td>
                    <td className="text-center">
                      <div className="iconArea">
                        <span className="activeMail"><i className={ICONS.MAIL}></i></span>
                        <span className=""><i className={ICONS.SMS_ICON}></i></span>
                        <span className=""><i className={ICONS.WHATSAPP_ICON}></i></span>
                      </div>
                    </td>
                    <td>12-12-2024</td>
                    <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" onChange={onChange} /></td>
                    <td className="text-center"><span className="status-code ready">Active</span></td>
                    <td className="text-center">
                      <DropdownAction overlay={actionMenu} trigger={['click']}>
                        <i className={ICONS.MORE} />
                      </DropdownAction>
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div className={`${showNextRow1 ? 'mrL30' : 'mrL0'}`}>Sales</div>
                      </td>
                    <td>Sales Enquiry</td>
                    <td>Created</td>
                    <td className="text-center">
                      <div className="iconArea">
                        <span className=""><i className={ICONS.MAIL}></i></span>
                        <span className="activeSMS"><i className={ICONS.SMS_ICON}></i></span>
                        <span className=""><i className={ICONS.WHATSAPP_ICON}></i></span>
                      </div>
                    </td>
                    <td>12-12-2024</td>
                    <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" onChange={onChange} /></td>
                    <td className="text-center"><span className="status-code ready">Active</span></td>
                    <td className="text-center">
                      <DropdownAction overlay={actionMenu} trigger={['click']}>
                        <i className={ICONS.MORE} />
                      </DropdownAction>
                    </td>
                  </tr>
                  <tr>
                    <td>
                        <div className={`${showNextRow1 ? 'mrL30' : 'mrL0'}`}>Sales</div>
                      </td>
                    <td>Sales Enquiry</td>
                    <td>Edited</td>
                    <td className="text-center">
                      <div className="iconArea">
                        <span className=""><i className={ICONS.MAIL}></i></span>
                        <span className=""><i className={ICONS.SMS_ICON}></i></span>
                        <span className="activeWhatsapp"><i className={ICONS.WHATSAPP_ICON}></i></span>
                      </div>
                    </td>
                    <td>12-12-2024</td>
                    <td className="text-center"><Switch className="customAutomateSwitch" checkedChildren="Active" unCheckedChildren="Inactive" onChange={onChange} /></td>
                    <td className="text-center"><span className="status-code rejected">Deactive</span></td>
                    <td className="text-center">
                      <DropdownAction overlay={actionMenu} trigger={['click']}>
                        <i className={ICONS.MORE} />
                      </DropdownAction>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(CommunicationTemplateComp);
