import React, { Fragment, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { TreeSelect} from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { showToasterMessage, checkACLPermission, getMomentDateForUIReadOnly } from './../../utils';
import * as debounce from 'lodash.debounce';
import './general.scss';
import { PERMISSION_VALUES, CONSTANTS } from "../../static/constants";
import { useSelector } from 'react-redux'
const format = CONSTANTS.DISPLAY_DATE_FORMAT_SHORT;

const { TreeNode } = TreeSelect;

const TreeDropdownGridComp = (props, ref) => {
  
  const companyInfo = useSelector((state) => state.common.companyInfo);
  
  const inputRef = useRef();
  let canAddNew = props.canAddNew || false;
  const [selectedProductValue, updateSelectedValue] = useState(props.selectedLabel);
  const [allOptions, updateAllOptions] = useState(props.options);
  //const [customOptions, updateCustomOptions] = useState([]);
  const [fetching, updateFetching] = useState(false);
  //const [editable, updateeditable] = useState(true);
  let [expandedKeys, updateExpandedKeys] = useState([]);
  const [isDropdownVisible, updateDropdownVisible] = useState(false);
  const operations = props.operations;
  const permissions = props.permissions || {};
  const moduleName = props.moduleName;
  const primaryPerm = permissions.primary;
  let canDelete = props.canDelete || false;
  if (canAddNew) {
    canAddNew = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.CREATE);
    
  }
  if (canDelete && Array.isArray(operations) && operations.indexOf(PERMISSION_VALUES.DELETE) > -1) {
    canDelete = checkACLPermission(primaryPerm, moduleName, PERMISSION_VALUES.DELETE);
  }
  
  // const updateCustomList = (itemList) => {
  //   let customeList = [];
  //   itemList && itemList.length && itemList.map((product) => {
  //       let productToCreate = {
  //         title: product.itemName,
  //         value: product.itemId,
  //         id: product.itemId,
  //         itemId: product.itemId
  //       }
  //       if (product.inventoryItemVariantList.length) {
  //         productToCreate.children = [];
  //       };
  //       product.inventoryItemVariantList.map((variant, index) => {
  //         if (variant.attributeValue1 || variant.attributeValue2 || variant.attributeValue3) {
  //           productToCreate.children.push({
  //             itemId: product.itemId,
  //             pId: product.itemId,
  //             itemName: product.itemName,
  //             title: (variant.attributeValue1 || '') +  (variant.attributeValue2 ? ' | ': '') + (variant.attributeValue2 || '') + '' + (variant.attributeValue3 ? ' | ' + variant.attributeValue3 : ''),
  //             value: Date.now() + '' + variant.variantId,
  //             variantId: variant.variantId
  //           });
  //         }
  //         return productToCreate;
  //       });
        
  //       return customeList.push(productToCreate);
  //     });
  //     updateCustomOptions(customeList);
  // } 

  //updateCustomList(allOptions);

  let customOptions = [];
  allOptions && allOptions.length && allOptions.map((product) => {
    let fVariant = product.inventoryItemVariantList[0] || {};
    let fVariantStock = (fVariant.itemVariantStockList || [])[0] || {};
    let productToCreate = {
      title: product.hasVariant ? product.itemName : <div> {product.itemName}
        {/* <br /> <span><b>Stock-</b> {fVariantStock.currentStock} {fVariant.uomName || ''} | <b>Qty on SO-</b> {fVariantStock.salesCommitted} {fVariant.uomName || ''} | <b>Qty on PO-</b> {fVariantStock.onOrder} {fVariant.uomName || ''}</span>
         <span><b>Price-</b>{(companyInfo || {}).currencyIcon ? <i className={(companyInfo || {}).currencyIcon}></i>:(props.companyInfo || {}).currencyCode}{Number(fVariantStock.purchasePrice).toFixed(2)}</span> */}
      </div>,
      value: product.itemId || '',
      id: product.itemId || '',
      itemId: product.itemId,
      variantName: product.itemName,
      variantId: fVariant.variantId,
      itemVariantStockId: fVariantStock.itemVariantStockId
    }
    if (product.inventoryItemVariantList.length) {
      productToCreate.children = [];
    };
    if(product.hasVariant){
      product.inventoryItemVariantList.map((variant, index) => {
        if ((variant.attributeValue1 || variant.attributeValue2 || variant.attributeValue3 )&&  variant.itemVariantStockList &&  variant.itemVariantStockList.length) {
          let variantToCreate = {
            itemId: product.itemId,
            pId: product.itemId,
            itemName: product.itemName,
            variantName: (variant.attributeValue1 || '') + (variant.attributeValue2 ? ' | ' : '') + (variant.attributeValue2 || '') + '' + (variant.attributeValue3 ? ' | ' + variant.attributeValue3 : ''),
            title: <div>{(variant.attributeValue1 || '') + (variant.attributeValue2 ? ' | ' : '') + (variant.attributeValue2 || '') + '' + (variant.attributeValue3 ? ' | ' + variant.attributeValue3 : '')}
            </div>,
            value: variant.variantId,
            variantId: variant.variantId,
            id: variant.variantId,
          }
          if (variant.itemVariantStockList && variant.itemVariantStockList.length) {
            variantToCreate.children = [];
          };
          (variant.itemVariantStockList || []).map((variantStock, index) => {
            variantToCreate.children.push({
              itemId: product.itemId,
              pId: variant.variantId,
              itemName: product.itemName,
              variantName: (variant.attributeValue1 || '') + (variant.attributeValue2 ? ' | ' : '') + (variant.attributeValue2 || '') + '' + (variant.attributeValue3 ? ' | ' + variant.attributeValue3 : ''),
              title: <div><span>Stock - {variantStock.currentStock} {variant.uomName || ''} | Qty on SO - {variantStock.salesCommitted} {variant.uomName || ''} | Qty on PO - {variantStock.onOrder} {variant.uomName || ''} </span>
                <span style={{ marginLeft: '0px' }}> Price - {(companyInfo || {}).currencyIcon ? <i className={(companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode}{Number(variantStock.purchasePrice).toFixed(2)}</span> | <span>{variantStock.datePurchased ? getMomentDateForUIReadOnly({date: new Date(variantStock.datePurchased), format}) : ''} </span> | <span>{variantStock.supplierName || ''} </span>
              </div>,
              value: variant.variantId + '_' + variantStock.itemVariantStockId,
              variantId: variant.variantId,
              itemVariantStockId: variantStock.itemVariantStockId,
              isLeaf: true,
              id: variantStock.itemVariantStockId,
            });
            return variantToCreate;
          });
          productToCreate.children.push(variantToCreate);
        }
        return productToCreate;
      });
    }else{
      let variant = product.inventoryItemVariantList[0] || {};
      variant.itemVariantStockList.map((variantStock, index) => {
        productToCreate.children.push({
          itemId: product.itemId,
          pId: variant.variantId,
          itemName: product.itemName,
          variantName: (variant.attributeValue1 || '') + (variant.attributeValue2 ? ' | ' : '') + (variant.attributeValue2 || '') + '' + (variant.attributeValue3 ? ' | ' + variant.attributeValue3 : ''),
          title: <div><span>Stock - {variantStock.currentStock} {variant.uomName || ''} | Qty on SO - {variantStock.salesCommitted} {variant.uomName || ''} | Qty on PO - {variantStock.onOrder} {variant.uomName || ''} </span>
             | <span style={{ marginLeft: '0px' }}>Price - {(companyInfo || {}).currencyIcon ? <i className={(companyInfo || {}).currencyIcon}></i> : (props.companyInfo || {}).currencyCode}{Number(variantStock.purchasePrice).toFixed(2)}</span> | <span>{variantStock.datePurchased ? getMomentDateForUIReadOnly({date: new Date(variantStock.datePurchased), format}) : ''} </span> | <span>{variantStock.supplierName || ''} </span>
          </div>,
          value: variant.variantId + '_' + variantStock.itemVariantStockId,
          variantId: variant.variantId,
          itemVariantStockId: variantStock.itemVariantStockId,
          isLeaf: true,

          id: variantStock.itemVariantStockId,
        });
        return "";
      });
  }
    customOptions.push(productToCreate)
    return customOptions;
  });

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return selectedProductValue;
      },
      afterGuiAttached: () => {
        inputRef.current.focus();
      },
      isCancelAfterEnd: () => {
        if (props.lastColumnCell && props.node.lastChild) {
          props.api.updateRowData({ add: [{}] });
        }
        return false;
      }
    };
  });

  const onChange = (values, selectedOption) => {
    if (selectedOption.children && selectedOption.children.length) {
      return showToasterMessage({ messageType: 'error', description: 'Please select variant' })
    }
    let selectedProductValue = '';
    let valueArray = [selectedOption.itemId];
    if (selectedOption.variantId) {
      valueArray.push(selectedOption.variantId);
    }
    if (selectedOption.itemVariantStockId) {
      valueArray.push(selectedOption.itemVariantStockId);
    }

    selectedProductValue = selectedOption.variantName ? (selectedOption.itemName ? (selectedOption.itemName) + " (" + selectedOption.variantName + ")" : selectedOption.variantName) : selectedOption.itemName;
    updateSelectedValue(selectedProductValue);
    props.node.selectedLabel = selectedProductValue;
    props.node.selectedProductValue = valueArray;
    props.node.selectedOption = selectedOption;
    props.onSelect(selectedProductValue, selectedOption);
  }

  const filter = (inputValue, option) => {
    return option.value && option.variantName && option.variantName.toLowerCase().includes(inputValue.toLowerCase());
  }

  const onSearchInput = debounce((text) => {
    updateFetching(true);
    props.onSearch(text, updateAllOptions, updateFetching)
  }, 1000);

  // if (editable) {
  //   onSearchInput();
  //   updateeditable(false);
  // }


  const onExpand = (expandedKeys) => {
    updateExpandedKeys(expandedKeys);
  };

  const onDropdownVisibleChange = visible => {
    if (!visible) return;
    updateDropdownVisible(
      true 
    );
  };

  return (
    <TreeSelect
      showSearch={true}
      ref={inputRef}
      value={selectedProductValue || undefined}
      filterTreeNode={filter}
      open={isDropdownVisible}
      treeExpandedKeys={expandedKeys}
      onSearch={onSearchInput}
      // style={{ width: '690px' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder="Please select"
      notFoundContent={fetching ? <Loading3QuartersOutlined className="spinnerIcon" spin /> : "No record(s) found"}
      //treeData={customOptions}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onTreeExpand={onExpand}
      onSelect={(value, node) => {
        if(node.isAddNew){
          setTimeout(()=>{
            updateSelectedValue("")
          },100);
          return props.onAddNewClick();
        }

        if (node.children && node.children.length) {
          updateDropdownVisible(true);
          
          const isKey = expandedKeys.indexOf(node.key) !== -1;
          if (isKey) {
            expandedKeys = expandedKeys.filter((e) => {
              return e !== node.key;
            })
            updateExpandedKeys(expandedKeys);
          } else {
            updateExpandedKeys(expandedKeys.concat(node.key));
          }
        } else {
          updateDropdownVisible(false);
          onChange(value, node);
        }
      }}
      >

      {
        canAddNew ?
          <Fragment>
            <div
            key ={"Add New"}
              title = {<span><i className="fa fa-plus" /> Add New</span>}
              isAddNew={true}
              style={{ padding: "4px 8px", cursor: "pointer" }}
              //onMouseDown={e => e.preventDefault()}
              onClick={() => {
                props.onAddNewClick()
              }}
              className='add-new'
            >
              
            </div>
          </Fragment>
          : ''
      }

      {
        customOptions.map((d) => {
          return <TreeNode name={d.title} key={d.itemId} value={d.value} title={d.title} id={d.id} itemId={d.itemId} variantName={d.variantName} itemVariantStockId={d.itemVariantStockId}>
            {(d && d.children && d.children.length ) ? d.children.map((child) => {
              return <TreeNode name={d.title} key={child.value} itemId={child.itemId} itemName={child.itemName} title={child.title} variantName={child.variantName} id={child.id} pId={child.pId} variantId={child.variantId} itemVariantStockId={child.itemVariantStockId} value={child.value} >
                {(child && child.children && child.children.length ) ? child.children.map((Schild) => {
              return <TreeNode name={d.title} key={Schild.value} itemId={Schild.itemId} itemName={Schild.itemName} title={Schild.title} variantName={Schild.variantName} id={Schild.id} pId={Schild.pId} variantId={Schild.variantId} itemVariantStockId={Schild.itemVariantStockId} value={Schild.value} >
                </TreeNode>
            }) : null}
                </TreeNode>
            }) : null}
          </TreeNode>
        })
      }
    </TreeSelect >
  );
}

export const TreeDropDownStock = forwardRef(TreeDropdownGridComp);