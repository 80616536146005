import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreateSponsorshipComp from "../../../../components/fundraising/sponsorship/CreateSponsorship";
import { saveOrUpdateSponsorship } from "./action";
import { showModal, hideModal, fetchProjects } from './../../../../actions/commonActions';
import AddTicketDrawerDrawer from "../../../../components/drawer/AddTicket";
import { fetchAvailablePaymentGateway } from '../../../integrations/Payment/action';
import { fetchAllLedgerAccounts } from '../../../finance/LedgerAccounts/action';

class SponsorshipsCreate extends Component {
  constructor(props) {
    super(props);

    const linkProps = (this.props.location && this.props.location.state) || {};
    let sponsorshipData = linkProps.sponsorshipData || {};
    this.state = {
    pageNumber: 1,
      pageSize: 25,
      addTicketDrawerDrawerVisible: false,
      giveSponsorshipMasterId: sponsorshipData.giveSponsorshipMasterId,
      name: sponsorshipData.name,
      descriptionMaster: sponsorshipData.description,
      locationId: sponsorshipData.locationId,
      saleStartDate: sponsorshipData.saleStartDate ? new Date(sponsorshipData.saleStartDate) : new Date(),
      saleEndDate: sponsorshipData.saleEndDate ? new Date(sponsorshipData.saleEndDate) : new Date(),
      isDisplayStartDate: sponsorshipData.isDisplayStartDate,
      isDisplayEndDate: sponsorshipData.isDisplayEndDate,
      ticketList: sponsorshipData.ticketItemDetailsList || [],
      projectMasterId: sponsorshipData.projectMasterId,
      chartOfAccountDetailsId: sponsorshipData.chartOfAccountDetailsId,
      alpidePaymentGatewayId: sponsorshipData.alpidePaymentGatewayId,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: 1,
    //   pageSize: 25,
    // };
    //fetchPaginationDataIfNeeded("getSponsorships", "sponsorshipList", this.props, payload);
   // fetchDataIfNeeded("sponsorshipCount", "ponsorshipListCount", this.props, payload);
    this.props.fetchAvailablePaymentGateway({ relationshipId: companyInfo.relationshipId });
   this.props.fetchProjects({ relationshipId: companyInfo.relationshipId });
   this.props.fetchAllLedgerAccounts({ relationshipId: companyInfo.relationshipId });
  }

  render() {
    return (
      <div>
      {this.state.addTicketDrawerDrawerVisible && <AddTicketDrawerDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      
      <CreateSponsorshipComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></CreateSponsorshipComp>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    sponsorshipList: state.fundraisingReducer.sponsorshipList,
    ponsorshipListCount: state.fundraisingReducer.ponsorshipListCount,
    availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
    projectList: state.common.projectList,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveOrUpdateSponsorship,
      showModal,
      hideModal,
      fetchProjects, 
      fetchAllLedgerAccounts,
      fetchAvailablePaymentGateway,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SponsorshipsCreate);
