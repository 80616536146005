import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Drawer, Row, Col, Tooltip,Skeleton } from "antd";
//import './customerDrawer.scss';
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { Dropdown } from "../../../general/Dropdown";
import { ErrorMsg } from "../../../general/ErrorMessage";
import {
  validateEmail,
  showToasterMessage,
  getAWSFileURL,
  base64Toblob,
} from "../../../../utils";
import { CONSTANTS, MODAL_TYPE } from "../../../../static/constants";
import { getLabelName } from "../../../modal/modalBody/customerInfo";
import { CustomUploader } from "../../../general/CustomUploader";
import { ImageCropper } from "../../../general/ImageCropper";
import config from "../../../../config/environmentConfig";
function EmployeeDrawer(props) {
  const closeDrawer = () => {
    props.updateState({
      company: {
        employeeDrawerVisible: false,
      },
    });
  };

  const appendCountryCode = (number) => {
    // if (props.companyInfo.countryCallingCode && number) {
    //     return '+' + props.companyInfo.countryCallingCode + number;
    // }else{
    //     return number;
    // }
    return number;
  };

  const handleSubmit = (props) => {
    if (
      (props.workPhone &&
        props.workPhone.length > 0 &&
        props.workPhone.length < 10) ||
      (props.cellPhone &&
        props.cellPhone.length > 0 &&
        props.cellPhone.length < 10)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "phoneNumber.incorrect.message",
        }),
      });
    }
    if (
      validateEmail(props.emailAddress) &&
      props.firstName &&
      props.lastName &&
      props.jobTitleName &&
      (Number(props.hierarchyLevel) === 1 || props.managerName) &&
      props.hierarchyId
    ) {
      const payload = {
        firstName: props.firstName,
        lastName: props.lastName,
        middleName: props.middleName,
        salutationName: props.salutationName,
        workPhone: appendCountryCode(props.workPhone),
        cellPhone: appendCountryCode(props.cellPhone),
        emailAddress: props.emailAddress,
        relationshipId: (props.companyInfo || {}).relationshipId,
        jobTitleId: props.jobTitleId,
        jobTitleName: props.jobTitleName,
        managerName: props.managerName,
        managerId: props.managerId,
        hierarchyName: props.hierarchyName,
        hierarchyLevel: props.hierarchyLevel,
        hierarchyId: props.hierarchyId,
        departmentName: props.departmentName,
        departmentId: props.departmentId,
        jobRoleId: props.jobRoleId,
        jobRoleName: props.jobRoleName,
        version: props.version,
        empTypeId: props.empTypeId,
        empType: props.empType,
        instructorClassRefList: props.instructorClassRefList || [],
        hasSoftwareAccess: props.hasSoftwareAccess,
        employeeCode: props.employeeCode,
        logoAwsObjectKey: props.logoAwsObjectKey,
        faceId: props.faceId,
      };

      if (props.relationshipEmployeeId) {
        payload.relationshipEmployeeId = props.relationshipEmployeeId;
      }

      props.pushModalToStack({
        modalBody: (
          <FormattedMessage
            id="company.employee.confitmation"
            defaultMessage=""
          />
        ),
        handleSubmit: () => {
          props.createEmployee(payload, props);
        },
      });
    } else {
      props.updateDrawerState({
        submittedOnce: true,
        invalidFirstNameClass: !props.firstName ? "input-text-error" : "",
        invalidHierarchyNameClass: !props.hierarchyId ? "input-text-error" : "",
        invalidJobTitleClass: !props.jobTitleName ? "input-text-error" : "",
        invalidLastNameClass: !props.lastName ? "input-text-error" : "",
        invalidManagerNameClass:
          !props.managerName && Number(props.hierarchyLevel) !== 1
            ? "input-text-error"
            : "",
        invalidEmailClass: !validateEmail(props.emailAddress)
          ? "input-text-error"
          : "",
      });
    }
  };

  const customProps = {
    type: CONSTANTS.TYPE_IMAGE,
    imgUrl: getAWSFileURL("", config.BUCKET_NAME.BO_RELATIONSHIP) || "",
    height: 120,
    isSignature: false,
    isImage: true,
    width: 120,
    name: "file",
    action: null,
    buttonName: "Upload Image",
    multiple: false,
    accept: ".jpg, .jpeg, .png",
    maxCount: 1,
    onChange: (file) => {
      if (file.file.originFileObj) {
        var reader = new FileReader();

        reader.onload = function (e) {
          props.showModal({
            title: <div className="ant-modal-title">Crop Image</div>,
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                {...props}
                handleSubmit={(newUrl) => {
                  props.updateDrawerState({
                    fileToUpload: base64Toblob(newUrl),
                  });
                }}
                url={e.target.result}
              />
            ),
            width: 600,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
            type: CONSTANTS.TYPE_IMAGE,
            multiple: false,
          });
        };
        reader.readAsDataURL(file.file.originFileObj); // convert to base64 string
      }
    },
  };
  const profileImgUrl = getAWSFileURL(
    (props || {}).logoAwsObjectKey,
    config.BUCKET_NAME.BO_RELATIONSHIP
  );

  const addItemConfirmationModal = (props, text, modalType) => {
    const { pushModalToStack, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        addFunc = props.addSalutation;
        break;
      }
      case MODAL_TYPE.JOB_TITLE: {
        addFunc = props.addJobTitle;
        break;
      }
      case MODAL_TYPE.DEPARTMENT: {
        addFunc = props.addDepartment;
        break;
      }
      case MODAL_TYPE.JOB_ROLE: {
        addFunc = props.addJobRole;
        break;
      }
      case MODAL_TYPE.EMPLOYEE_TYPE: {
        addFunc = props.addEmpType;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.popModalFromStack();
      },
    };
    pushModalToStack(modalData);
  };

  const getModalTitle = (modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        title = (
          <FormattedMessage id="addItem.text.salutation" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.JOB_TITLE: {
        title = (
          <FormattedMessage id="addItem.text.jobTitle" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.DEPARTMENT: {
        title = (
          <FormattedMessage id="addItem.text.department" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.JOB_ROLE: {
        title = <FormattedMessage id="jobRole.text" defaultMessage="" />;
        break;
      }
      case MODAL_TYPE.EMPLOYEE_TYPE: {
        title = (
          <FormattedMessage id="company.employee.type" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { pushModalToStack, popModalFromStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            popModalFromStack();
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        deleteFunc = props.deleteSalutation;
        break;
      }
      case MODAL_TYPE.JOB_TITLE: {
        deleteFunc = props.deleteJobTitle;
        break;
      }
      case MODAL_TYPE.DEPARTMENT: {
        deleteFunc = props.deleteDepartment;
        break;
      }
      case MODAL_TYPE.JOB_ROLE: {
        deleteFunc = props.deleteJobRole;
        break;
      }
      case MODAL_TYPE.EMPLOYEE_TYPE: {
        deleteFunc = props.deleteEmpType;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  if (
    props.isRoleTeacher &&
    props.jobRoleList &&
    props.jobRoleList.length &&
    !props.isTeacherFound
  ) {
    const ind = props.jobRoleList.findIndex(
      (obj) => obj.jobRoleName.toLowerCase() === "teacher"
    );
    if (ind > -1) {
      props.updateDrawerState({
        jobRoleId: props.jobRoleList[ind].jobRoleId,
        jobRoleName: props.jobRoleList[ind].jobRoleName,
        isTeacherFound: true,
      });
    }
  }

  return (
  
      <Drawer
        title={
          <FormattedMessage
            id={
              Object.keys(props.employeeData || {}).length > 0
                ? "company.employee.editTitle"
                : "company.employee.title"
            }
            defaultMessage=" "
            values={{ value: props.isRoleTeacher ? "Teacher" : "Employee" }}
          />
        }
        width={720}
        keyboard={false}
        // zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-drawer"
        visible={props.employeeDrawerVisible}
        // afterVisibleChange={(visible) => {
        //   if (visible) {
        //     props.initCalls();
        //   }
        // }}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />
            <CustomButton
              intlId="confirmation.submit"
              defaultMessage=""
              htmlType="submit"
              onClick={() => handleSubmit(props)}
              key={1}
            />
          </>
        }
      >
        <>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 10 }} active>

          <Row gutter={[16, 4]} className="pt15">
            <Col span={12}>Employee Id</Col>
            <Col span={12}>
              <FormattedMessage id="contact.email" defaultMessage="" />
              <span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={40}
                value={props.employeeCode}
                onChange={(e) => {
                  props.updateDrawerState({
                    employeeCode: e.target.value,
                  });
                }}
                placeholder={"Employe id"}
              />
            </Col>

            <Col span={12}>
              <TextBox
                disabled={
                  props.relationshipEmployeeId > 0 && props.isAccountHolder > 0
                }
                maxLength={75}
                type="text"
                value={props.emailAddress || undefined}
                onChange={(e) => {
                  props.updateDrawerState({
                    emailAddress: e.target.value,
                    invalidEmailClass: "",
                  });
                }}
                placeholder={props.intl.formatMessage({
                  id: "contact.email.placeholder",
                  defaultMessage: "",
                })}
                className={props.invalidEmailClass}
                validator={(val) => {
                  return validateEmail(val);
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.emailAddress;
                }}
                message={props.intl.formatMessage({
                  id: "contact.email.message",
                  defaultMessage: ".",
                })}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <span className="">
                <FormattedMessage id="contact.firstName" defaultMessage="" />
              </span>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <FormattedMessage id="contact.middleName" defaultMessage="" />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Row>
                <Col span={6}>
                  <Dropdown
                    items={props.salutations || []}
                    valueKeyName="titleName"
                    optionKeyName="salutationId"
                    value={props.salutationName}
                    placeholder={props.intl.formatMessage({
                      id: "common.title.placeholder",
                      defaultMessage: "",
                    })}
                    canAddNew={true}
                    canDelete={true}
                    allowClear={true}
                    deleteClickHandler={(payload) => {
                      deleteClickHandler(props, MODAL_TYPE.SALUTATION, payload);
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        textBoxValue: payload.textEntered,
                        submittedOnce: false,
                      };
                      handleNewItemAddition(
                        props,
                        payload,
                        MODAL_TYPE.SALUTATION
                      );
                    }}
                    onClear={() => {
                      props.updateDrawerState({
                        salutationName: undefined,
                      });
                    }}
                    onSelect={(optionValue, option) => {
                      props.updateDrawerState({
                        salutationName: option.name,
                      });
                    }}
                  />
                </Col>
                <Col span={18}>
                  <TextBox
                    maxLength={45}
                    type="text"
                    value={props.firstName || undefined}
                    onChange={(e) => {
                      props.updateDrawerState({
                        firstName: e.target.value,
                        invalidFirstNameClass: "",
                      });
                    }}
                    placeholder={props.intl.formatMessage({
                      id: "contact.firstName.placeholder",
                      defaultMessage: "",
                    })}
                    className={props.invalidFirstNameClass}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submittedOnce || !!props.firstName;
                    }}
                    message={props.intl.formatMessage({
                      id: "contact.firstName.message",
                      defaultMessage: "",
                    })}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <TextBox
                maxLength={45}
                type="text"
                value={props.middleName || undefined}
                onChange={(e) => {
                  props.updateDrawerState({
                    middleName: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({
                  id: "contact.middleName.placeholder",
                  defaultMessage: "",
                })}
                // className={invalidCompanyNameClass}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <span className="">
                <FormattedMessage id="contact.lastName" defaultMessage="" />
              </span>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <span className="">
                {" "}
                <FormattedMessage
                  id="company.employee.type"
                  defaultMessage=""
                />
              </span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={45}
                type="text"
                value={props.lastName || undefined}
                onChange={(e) => {
                  props.updateDrawerState({
                    lastName: e.target.value,
                    invalidLastNameClass: "",
                  });
                }}
                placeholder={props.intl.formatMessage({
                  id: "contact.lastName.placeholder",
                  defaultMessage: "",
                })}
                className={props.invalidLastNameClass}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.lastName;
                }}
                message={props.intl.formatMessage({
                  id: "contact.lastName.message",
                  defaultMessage: "",
                })}
              />
            </Col>
            {/* <ErrorMsg
      validator={() => { return !submittedOnce || !!companyName  }}
      message={intl.formatMessage(
        { id: 'drawer.customer.validation.companyName', defaultMessage: '' }
      )}/> */}
            <Col span={12}>
              <Dropdown
                items={props.empTypeList || []}
                valueKeyName="empType"
                optionKeyName="empTypeId"
                value={props.empType}
                placeholder={props.intl.formatMessage({
                  id: "company.employee.type",
                  defaultMessage: "",
                })}
                canAddNew={true}
                canDelete={true}
                allowClear={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.EMPLOYEE_TYPE, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false,
                  };
                  handleNewItemAddition(
                    props,
                    payload,
                    MODAL_TYPE.EMPLOYEE_TYPE
                  );
                }}
                onClear={() => {
                  props.updateDrawerState({
                    empType: undefined,
                    empTypeId: undefined,
                  });
                }}
                onSelect={(optionValue, obj) => {
                  props.updateDrawerState({
                    empTypeId: optionValue,
                    empType: obj.name,
                    invalidEmpTypeClass: "",
                  });
                }}
                className={props.invalidEmpTypeClass}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <FormattedMessage id="contact.departments" defaultMessage="" />
            </Col>
            <Col span={12}>
              <span className="">
                {" "}
                <FormattedMessage id="contact.jobTitle" defaultMessage="" />
              </span>
              <span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                items={props.departments || []}
                valueKeyName="departmentName"
                optionKeyName="departmentId"
                value={props.departmentName}
                placeholder={props.intl.formatMessage({
                  id: "contact.departments.placeholder",
                  defaultMessage: "",
                })}
                canAddNew={true}
                canDelete={true}
                allowClear={true}
                onClear={() => {
                  props.updateDrawerState({
                    departmentName: undefined,
                    departmentId: undefined,
                  });
                }}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.DEPARTMENT, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false,
                  };
                  handleNewItemAddition(props, payload, MODAL_TYPE.DEPARTMENT);
                }}
                onSelect={(optionValue, option) => {
                  props.updateDrawerState({
                    departmentName: option.name,
                    departmentId: optionValue,
                  });
                }}
              />
            </Col>
            <Col span={12}>
              <Dropdown
                items={props.jobTitleList || []}
                valueKeyName="jobTitle"
                optionKeyName="jobTitleId"
                value={props.jobTitleName}
                placeholder={props.intl.formatMessage({
                  id: "contact.jobTitle.placeholder",
                  defaultMessage: "",
                })}
                canAddNew={true}
                canDelete={true}
                allowClear={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.JOB_TITLE, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false,
                  };
                  handleNewItemAddition(props, payload, MODAL_TYPE.JOB_TITLE);
                }}
                onClear={() => {
                  props.updateDrawerState({
                    jobTitle: undefined,
                  });
                }}
                onSelect={(optionValue, obj) => {
                  props.updateDrawerState({
                    jobTitleId: optionValue,
                    jobTitleName: obj.name,
                    invalidJobTitleClass: "",
                  });
                }}
                className={props.invalidJobTitleClass}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.jobTitleName;
                }}
                message={props.intl.formatMessage({
                  id: "contact.jobTitle.message",
                  defaultMessage: "",
                })}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <Tooltip
                placement="bottom"
                title={props.intl.formatMessage({
                  id: "organization.newEmployee.jobRole",
                })}
              >
                <FormattedMessage id="jobRole.text" defaultMessage="" />
              </Tooltip>
              {/* <span className="tooltip-title">
                  <FormattedMessage id='jobRole.text' defaultMessage='' />
              </span> */}
            </Col>
            <Col span={12}>
              <FormattedMessage id="contact.manager" defaultMessage="" />{" "}
              {Number(props.hierarchyLevel) !== 1 && (
                <span className="required">*</span>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                items={props.jobRoleList || []}
                valueKeyName="jobRoleName"
                optionKeyName="jobRoleId"
                // disabled={props.isRoleTeacher ? true : false}
                value={props.jobRoleName}
                placeholder={
                  props.isRoleTeacher
                    ? "Teacher"
                    : props.intl.formatMessage({
                        id: "contact.jobTitle.placeholder",
                        defaultMessage: "",
                      })
                }
                canAddNew={true}
                canDelete={true}
                allowClear={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(props, MODAL_TYPE.JOB_ROLE, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false,
                  };
                  handleNewItemAddition(props, payload, MODAL_TYPE.JOB_ROLE);
                }}
                onClear={() => {
                  props.updateDrawerState({
                    jobRoleName: undefined,
                    jobRoleId: undefined,
                  });
                }}
                onSelect={(optionValue, obj) => {
                  props.updateDrawerState({
                    jobRoleId: optionValue,
                    jobRoleName: obj.name,
                    invalidJobRoleClass: "",
                  });
                }}
                className={props.invalidJobRoleClass}
              />
              {/* <ErrorMsg
              validator={() => { return !props.submittedOnce || !!props.jobRoleName }}
              message={props.intl.formatMessage(
                {
                  id: 'contact.jobTitle.message',
                  defaultMessage: ''
                })
              } /> */}
            </Col>
            <Col span={12}>
              <Dropdown
                disabled={Number(props.hierarchyLevel) === 1}
                items={props.managerList || []}
                valueKeyName="fullName"
                optionKeyName="relationshipEmployeeId"
                hierarchyNameKey="hierarchyName"
                hierarchyLevelKey="hierarchyLevel"
                hierarchyCodeKey="hierarchyCode"
                value={props.managerName}
                placeholder={props.intl.formatMessage({
                  id: "contact.manager.placeholder",
                  defaultMessage: "",
                })}
                onSelect={(optionValue, obj) => {
                  let managerObj =
                    (props.managerList || []).find(
                      (x) => x.relationshipEmployeeId === +obj.value
                    ) || {};
                  props.updateDrawerState({
                    managerName: obj.name,
                    managerId: optionValue,
                    invalidManagerNameClass: "",
                  });
                  props.resetChildCompanyHierarchyByLevel();
                  props.fetchChildCompanyHierarchiesByLevel({
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    hierarchyLevel: +managerObj.hierarchyLevel,
                  });
                }}
                className={
                  Number(props.hierarchyLevel) === 1
                    ? ""
                    : props.invalidManagerNameClass
                }
              />
              <ErrorMsg
                validator={() => {
                  return (
                    !props.submittedOnce ||
                    !!props.managerName ||
                    Number(props.hierarchyLevel) === 1
                  );
                }}
                message={props.intl.formatMessage({
                  id: "contact.manager.message",
                  defaultMessage: "",
                })}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <FormattedMessage
                id="company.companyHierarchy.text"
                defaultMessage=""
              />
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <FormattedMessage id="contact.mobile" defaultMessage="" />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                disabled={!(props.companyHierarchyList || []).length}
                items={props.companyHierarchyList || []}
                valueKeyName="hierarchyName"
                optionKeyName="hierarchyId"
                hierarchyLevelKey="hierarchyLevel"
                hierarchyCodeLineKey="hierarchyCode"
                value={props.hierarchyName}
                placeholder={props.intl.formatMessage({
                  id: "company.companyHierarchy.placeholder",
                  defaultMessage: "",
                })}
                onSelect={(optionValue, option) => {
                  let hierarchyObj =
                    (props.companyHierarchyList || []).find(
                      (x) => x.hierarchyId === +optionValue
                    ) || {};
                  props.updateDrawerState({
                    hierarchyId: optionValue,
                    hierarchyName: option.name,
                    invalidHierarchyNameClass: "",
                    hierarchyLevel: hierarchyObj.hierarchyLevel,
                  });
                }}
                className={props.invalidHierarchyNameClass}
              />

              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.hierarchyId;
                }}
                message={props.intl.formatMessage({
                  id: "company.companyHierarchy.message",
                  defaultMessage: "",
                })}
              />
            </Col>
            <Col span={12} className="input-currency-control">
              <span className="currency-tag">
                {props.companyInfo.countryCallingCode
                  ? "+" + props.companyInfo.countryCallingCode
                  : " "}
              </span>
              <TextBox
                className="pl45"
                maxLength={10}
                type="text"
                style={{ paddingLeft: "45px", height: "38px" }}
                value={props.cellPhone}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    props.updateDrawerState({
                      cellPhone: e.target.value,
                    });
                  } else if (!props.cellPhone) {
                    props.updateDrawerState({
                      cellPhone: "",
                    });
                  }
                }}
                placeholder={props.intl.formatMessage({
                  id: "contact.mobile.placeholder",
                  defaultMessage: "",
                })}
                // className={invalidCompanyNameClass}
              />
            </Col>
          </Row>

          <Row gutter={[16, 4]}>
            <Col span={12}>
              <FormattedMessage id="contact.workPhone" defaultMessage="" />
            </Col>
            <Col span={12}>Upload image</Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* <ErrorMsg
            validator={() => { return !submittedOnce || !!companyName  }}
            message={intl.formatMessage(
              { id: 'drawer.customer.validation.companyName', defaultMessage: '.' }
            )}/> */}
            <Col span={12} className="input-currency-control">
              <span className="currency-tag pt3">
                {props.companyInfo.countryCallingCode
                  ? "+" + props.companyInfo.countryCallingCode
                  : " "}
              </span>
              <TextBox
                className="pl45"
                style={{ paddingLeft: "45px", height: "38px" }}
                maxLength={10}
                type="text"
                value={props.workPhone}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    props.updateDrawerState({
                      workPhone: e.target.value,
                    });
                  } else if (!props.workPhone) {
                    props.updateDrawerState({
                      workPhone: "",
                    });
                  }
                }}
                placeholder={props.intl.formatMessage({
                  id: "contact.phone.placeholder",
                  defaultMessage: "",
                })}
                // className={invalidCompanyNameClass}
              />
            </Col>
            <Col span={12} style={{ display: "flex", gap: "30px" }}>
              {props.logoAwsObjectKey && !props.fileToUpload ? (
                <div className="top-profile-pic">
                  <img src={profileImgUrl} alt="U" />
                </div>
              ) : (
                ""
              )}
              <CustomUploader 
                {...customProps}
                type={CONSTANTS.TYPE_IMAGE} 
                multiple={false} maxCount={1}
                isProfile={true} 
              />
            </Col>
          </Row>
          </Skeleton>
          {/* <CustomTableUpload fileList={props.fileList} onChange={
                                (fileList) => {
                             
                                    props.updateDrawerState({
                                        fileList
                                    })
                                }
                            } /> */}

          {/* <Upload {...customProps}>
    <Button style={{height:'38px'}} icon={<UploadOutlined />}>Click to Upload</Button>
  </Upload> */}

          {/* <CustomTableUpload fileList={props.fileList} onChange={
            (fileList) => {
              props.updateState({
                fileList
              })
            }
          } /> */}
        </>
      </Drawer>
    
  );
}

export default injectIntl(EmployeeDrawer);
