import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShippingComponent from '../../../components/ShowSettings/shipping';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { getMarketplaceShippingCountries, saveUpdateShippingMarketplaceCountry, deleteShippingMarketplaceCountry, getAllCountries, getAllShippingChoices } from "./action"
import { fetchDataIfNeeded } from "../../../utils";
// import { getAllCountries } from './action';



class MarketplaceShippingComponent extends Component {

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true
        });
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
        };
        fetchDataIfNeeded("getMarketplaceShippingCountries", "shippingCountries", this.props, payload);
        fetchDataIfNeeded("getAllShippingChoices", "shippingChoices", this.props, payload);
        this.props.getAllCountries(payload)

    }

    render() {
        return <ShippingComponent {...this.props}  {...this.state} updateState={(data) => {
            this.setState(data);
        }} />
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        shippingCountries: state.shippingReducer.shippingCountries,
        shippingChoices: state.shippingReducer.shippingChoices,
        countries: state.taxSettingReducer.countries,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getMarketplaceShippingCountries,
    saveUpdateShippingMarketplaceCountry,
    deleteShippingMarketplaceCountry,
    getAllCountries,
    getAllShippingChoices,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceShippingComponent);
