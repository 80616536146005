import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Tooltip, Switch } from 'antd';
import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
import moment from 'moment-timezone';
import { MultipleSelectDropdown } from '../../general/MultipleSelectDropdown';
import * as filter from 'lodash.filter';

function FooterProps(props) {

    let aboutusRef = document.getElementById('aboutus_' + props.childId) || {};
    let linksRef = document.getElementById('links_' + props.childId) || {};
    let copyrightRef = document.getElementById('copyright_' + props.childId) || {};
    let unsubscribeRef = document.getElementById('unsubscribe_' + props.childId) || {};
    let contactusRef = document.getElementById('contactus_' + props.childId) || {};

    let buttonRef = document.getElementById(props.childId) || {};
   

    let timeZones = moment.tz.names();
    let abbrs = {
        EST: 'Eastern Standard Time',
        EDT: 'Eastern Daylight Time',
        CST: 'Central Standard Time',
        CDT: 'Central Daylight Time',
        MST: 'Mountain Standard Time',
        MDT: 'Mountain Daylight Time',
        PST: 'Pacific Standard Time',
        PDT: 'Pacific Daylight Time',
    };

    moment.fn.zoneName = function () {
        var abbr = this.zoneAbbr();
        return abbrs[abbr] || abbr;
    };
    let offsetTmz = [];

    for (var i in timeZones) {
        offsetTmz.push({ name: timeZones[i], value: " (GMT" + moment.tz(timeZones[i]).format('Z') + ") " + moment.tz(timeZones[i]).format('z') + " " + timeZones[i] });
    }

    return (
        <Fragment>
            <div>
                <Row className="but-buttons">
                    <Col span={7} style={{ paddingTop: '2px', paddingRight: '4px' }}>
                        <FormattedMessage id='buttonBackgraound.text' />
                    </Col>
                    <Col span={17} >
                        <ColorPicker
                         placement='bottomLeft'
                            enableAlpha={false}
                            color={buttonRef.style.background || '#FFFFFF'}
                            onChange={(obj) => {
                                props.updateState({ bgColor: obj.color });
                                buttonRef.style.background = obj.color;
                            }}
                            mode="RGB"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={16}>
                        <FormattedMessage id='templateFooter.aboutUs.display' />
                    </Col>
                    <Col span={6}>
                        <Switch
                            checked={aboutusRef.style && aboutusRef.style.display !== 'none'}
                            onClick={(checked, e) => {
                                if (aboutusRef) {
                                    if (checked) { aboutusRef.style.display = '' }
                                    else { aboutusRef.style.display = 'none' }
                                }
                                props.updateState({ useSystemSku: checked ? 1 : 0 });
                            }} />
                    </Col>
                </Row>
                <br />
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.aboutUs.title' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.aboutTitle || document.getElementById('about_title').innerHTML} onChange={(e) => {
                            document.getElementById('about_title').innerHTML = e.target.value;
                            props.updateState({ aboutTitle: e.target.value });
                        }}></Input>
                    </Col>
                </Row>
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.aboutUs.message' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.aboutDesc || document.getElementById('about_desc').innerHTML} onChange={(e) => {
                            document.getElementById('about_desc').innerHTML = e.target.value;
                            props.updateState({ aboutDesc: e.target.value });
                        }}></Input>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col span={16}>
                        <FormattedMessage id='templateFooter.link.message' />
                    </Col>
                    <Col span={6}>
                        <Switch
                            checked={linksRef.style && linksRef.style.display !== 'none'}
                            onClick={(checked, e) => {
                                if (linksRef) {
                                    if (checked) { linksRef.style.display = '' }
                                    else { linksRef.style.display = 'none' }
                                }
                            }} />
                    </Col>
                </Row>

                <br />
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.link.message' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.link1Message || document.getElementById('link1_message').innerHTML} onChange={(e) => {
                            document.getElementById('link1_message').innerHTML = e.target.value;
                            props.updateState({ link1Message: e.target.value });
                        }}></Input>
                    </Col>
                </Row>
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.link.linkText' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.link1URL || document.getElementById('link1_URL').innerHTML} onChange={(e) => {
                            document.getElementById('link1_URL').innerHTML = e.target.value;
                            props.updateState({ link1URL: e.target.value });
                        }}></Input>
                    </Col>
                </Row>
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.link.link1URL' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.link1_URL || document.getElementById('link1_URL').href} onChange={(e) => {
                            let url = e.target.value;
                            url = url.match(/^https?:/) ? url : '//' + url;
                            document.getElementById('link1_URL').href = url;
                            props.updateState({ link1_URL: e.target.value });
                        }}></Input>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col span={16}>
                        <FormattedMessage id='templateFooter.copyrightMessage.display' />
                    </Col>
                    <Col span={6}>
                        <Switch
                            checked={copyrightRef.style && copyrightRef.style.display !== 'none'}
                            onClick={(checked, e) => {
                                if (copyrightRef) {
                                    if (checked) { copyrightRef.style.display = '' }
                                    else { copyrightRef.style.display = 'none' }
                                }
                            }} />
                    </Col>
                </Row>
                <br />
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.copyrightMessage.message' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.copyrightMessage || document.getElementById('copyright_message').innerHTML} onChange={(e) => {
                            document.getElementById('copyright_message').innerHTML = e.target.value;
                            props.updateState({ copyrightMessage: e.target.value });
                        }}></Input>
                    </Col>
                </Row>

                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.copyrightMessage.term' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.termText || document.getElementById('term').innerHTML} onChange={(e) => {
                            document.getElementById('term').innerHTML = e.target.value;
                            props.updateState({ termText: e.target.value });
                        }}></Input>
                    </Col>
                </Row>
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.copyrightMessage.termURL' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.termURL || document.getElementById('term').href} onChange={(e) => {
                            let url = e.target.value;
                            url = url.match(/^https?:/) ? url : '//' + url;
                            document.getElementById('term').href = url;
                            props.updateState({ termURL: e.target.value });
                        }}></Input>
                    </Col>
                </Row>
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.copyrightMessage.privacy' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.privacyText || document.getElementById('privacy').innerHTML} onChange={(e) => {
                            document.getElementById('privacy').innerHTML = e.target.value;
                            props.updateState({ privacyText: e.target.value });
                        }}></Input>
                    </Col>
                </Row>
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.copyrightMessage.privacyUrl' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.privacyUrl || document.getElementById('privacy').href} onChange={(e) => {
                            let url = e.target.value;
                            url = url.match(/^https?:/) ? url : '//' + url;
                            document.getElementById('privacy').href = url;
                            props.updateState({ privacyUrl: e.target.value });
                        }}></Input>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col span={16}>
                        <FormattedMessage id='templateFooter.unsubscribe.display' />
                    </Col>
                    <Col span={6}>
                        <Switch
                            checked={unsubscribeRef.style && unsubscribeRef.style.display !== 'none'}
                            onClick={(checked, e) => {
                                if (unsubscribeRef) {
                                    if (checked) {
                                        unsubscribeRef.style.display = '';
                                        document.getElementById('unsubscribe_URL').style.display = ''
                                    }
                                    else {
                                        unsubscribeRef.style.display = 'none';
                                        document.getElementById('unsubscribe_URL').style.display = 'none';
                                    }
                                }
                            }} />
                    </Col>
                </Row>
                <br />
                <Row className="but-buttons">
                    <Col span={9} style={{ paddingTop: '4px' }}>
                        <FormattedMessage id='dgNames.text' defaultMessage='' /><span className="required">*</span>
                        <Tooltip placement="right" title={props.intl.formatMessage({ id: 'templateFooter.unsubscribe.DG.helpMessag' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </Col>
                    <Col span={15} >
                        {/* <MultipleSelectDropdown
                            items={props.dgList}
                            valueKeyName='dgName'
                            optionKeyName='distributionGroupId'
                            value={props.distributionGroupId}
                            placeholder={props.intl.formatMessage({ id: 'dgNames.text' })}
                            onSelect={(optionValue, option) => {
                                let selectedDG = find(props.dgList || [], { distributionGroupId: option.value }) || {};
                                props.updateState({
                                    selectedDG: selectedDG,
                                    distributionGroupId: option.value,
                                })
                                document.getElementById('unsubscribe_URL').href = `${window.location.origin}/app/dg-unsubscribe?t=${props.companyInfo.relationshipId}_${selectedDG.distributionGroupId || 0}`;
                            }}
                        /> */}

                        <MultipleSelectDropdown
                            items={props.dgList || []}
                            placeholder={props.intl.formatMessage({ id: 'dgNames.text' })}
                            mode='multiple'
                            onDeSelect={(value) => {
                                let { distributionGroupIds = {} } = props;
                                distributionGroupIds = filter(distributionGroupIds, (distributionGroupId) => {
                                    return distributionGroupId !== value;
                                });
                                props.updateState({ distributionGroupIds });
                                document.getElementById('unsubscribe_URL').href = `${window.location.origin}/app/dg-unsubscribe?t=${props.companyInfo.relationshipId}_${distributionGroupIds || 0}`;
                            }}
                            valueKeyName='dgName'
                            optionKeyName='distributionGroupId'
                            selectedValue={props.distributionGroupIds || []}
                            onSelect={(optId) => {
                                let distributionGroupIds = props.distributionGroupIds || [];
                                if (distributionGroupIds.indexOf(optId) === -1) {
                                    distributionGroupIds.push(optId);
                                } else {
                                    distributionGroupIds = distributionGroupIds.filter((el) => {
                                        return el !== optId
                                    });
                                }
                                props.updateState({
                                    distributionGroupIds
                                });
                                document.getElementById('unsubscribe_URL').href = `${window.location.origin}/app/dg-unsubscribe?t=${props.companyInfo.relationshipId}_${distributionGroupIds || 0}`;
                            }}

                        />
                    </Col>
                </Row>
                <br />
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.unsubscribe.message' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.unsubscribeMessage || document.getElementById('unsubscribe_message').innerHTML} onChange={(e) => {
                            document.getElementById('unsubscribe_message').innerHTML = e.target.value;
                            props.updateState({ unsubscribeMessage: e.target.value });
                        }}></Input>
                    </Col>
                </Row>
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.unsubscribe.text' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.unsubscribeText || document.getElementById('unsubscribe_URL').innerHTML} onChange={(e) => {
                            document.getElementById('unsubscribe_URL').innerHTML = e.target.value;
                            props.updateState({ unsubscribeText: e.target.value });
                        }}></Input>
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col span={16}>
                        <FormattedMessage id='templateFooter.contactUs.display' />
                    </Col>
                    <Col span={6}>
                        <Switch
                            checked={contactusRef.style && contactusRef.style.display !== 'none'}
                            onClick={(checked, e) => {
                                if (contactusRef) {
                                    if (checked) { contactusRef.style.display = '' }
                                    else { contactusRef.style.display = 'none' }
                                }
                            }} />
                    </Col>
                </Row>
                <br />
                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.contactUs.title' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.contactusTitle || document.getElementById('contactus_title').innerHTML} onChange={(e) => {
                            document.getElementById('contactus_title').innerHTML = e.target.value;
                            props.updateState({ contactusTitle: e.target.value });
                        }}></Input>
                    </Col>
                </Row>

                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.contactUs.address' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.contactusAddress || document.getElementById('contactus_address').innerHTML} onChange={(e) => {
                            document.getElementById('contactus_address').innerHTML = e.target.value;
                            props.updateState({ contactusAddress: e.target.value });
                        }}></Input>
                    </Col>
                </Row>

                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.contactUs.phone' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.contactusPhone || document.getElementById('contactus_phone').innerHTML} onChange={(e) => {
                            document.getElementById('contactus_phone').innerHTML = e.target.value;
                            props.updateState({ contactusPhone: e.target.value });
                        }}></Input>
                    </Col>
                </Row>

                <Row className="but-buttons">
                    <Col span={24}>
                        <FormattedMessage id='templateFooter.contactUs.email' />
                    </Col>
                    <Col span={24}>
                        <Input value={props.contactusEmail || document.getElementById('contactus_email').innerHTML} onChange={(e) => {
                            document.getElementById('contactus_email').innerHTML = e.target.value;
                            props.updateState({ contactusEmail: e.target.value });
                        }}></Input>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
}


export default injectIntl(FooterProps);
