import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StockTransferDetails from '../../../../../components/modal/modalBody/inventory/StockTransit';
import { fetchStockTransferDetail } from './action';


class StockTransferDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isGitLoaded: false
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { 
            relationshipId: companyInfo.relationshipId,
              gitMasterId: this.props.stPayload.gitMasterId 
            };

        this.props.fetchStockTransferDetail(payload);
    }

    componentWillReceiveProps(props){
        if(!this.state.isGitLoaded && Object.keys(props.stockTransferData || {}).length){
            const {sourceWarehouseMasterId, destinationWarehouseMasterId } = props.stockTransferData;
            const {allWarehouses=[]} = this.props;

            this.setState({
                sourceWarehouse: allWarehouses.find(obj=>obj.warehouseMasterId === sourceWarehouseMasterId),
                destinationWarehouse: allWarehouses.find(obj=>obj.warehouseMasterId === destinationWarehouseMasterId),
                isGitLoaded: true
            })
        }
    }

    render() {
        return <StockTransferDetails {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        stockTransferData:state.inventory.stockTransferData,
        isLoading: state.common.isLoading,
        allWarehouses: state.inventory.allWarehouses,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStockTransferDetail

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StockTransferDetail);