import { Col, Drawer, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../general/CustomButton";
import { TextBox } from "../../general/TextBox";
import { MultipleSelectDropdown } from "../../general/MultipleSelectDropdown";

const LeadTranferDrawer = (props) => {
  const rowGutter = [24, 16];
  const closeDrawer = () => {
    props.updateState({
    allLeadsTransferDrawerVisible: false,
    });
  };


  return (
    <>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage id={props.leadSubSourceEditDrawerValue ? "leads.leadForm.formMeta.leadSubSource.edit.drawer.title.edit" : "leads.leadForm.formMeta.leadSubSource.edit.drawer.title.create"} />
          </div>
        }
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.allLeadsTransferDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />

            <CustomButton
              intlId="button.save.label"
              defaultMessage=""
              htmlType="submit"
              onClick={()=>{

                let team = [];
                let emp = [];
                for (let index = 0; index < props.selectedLead?.length; index++) {
                     let id = props.selectedLead[index];
                     for (let i = 0; i < props.crmLeadTeamAssignedList?.length; i++) {
                          team.push({...props.crmLeadTeamAssignedList[index],crmLeadId:id})
                      
                     }

                     for (let i = 0; i < props.crmLeadEmpAssignedList?.length; i++) {
                      emp.push({...props.crmLeadEmpAssignedList[index],crmLeadId:id})
                  
                 }
                  
                }
                const payload = {
                  crmLeadEmpAssignedList:props.crmLeadEmpAssignedList,
                  crmLeadTeamAssignedList:props.crmLeadTeamAssignedList,
                  createdByEmpId: props.userInfo.relationshipEmployeeId, 
                  updatedByEmpId: props.userInfo.relationshipEmployeeId,
                  relationshipId:props.companyInfo.relationshipId
                };
                props.bulkTransfer(payload,props);
              }}
              key={1}
            />
          </>
        }
      >
        <Row gutter={rowGutter}>
        <Col span={8}>
                        <div className='i-label'>Assign To Team<span className='required'>*</span></div>
                        <MultipleSelectDropdown
                            items={props.teamList[1] || []}
                            placeholder={'Assign To Team'}
                            mode='multiple'
                            onDeSelect={(teamId, options) => {
                                let tempList = props.crmLeadTeamAssignedList || [];
                                let temp = props.selectedTeam || [];
                                const empExits = tempList.find((obj)=> obj.teamId === teamId)
                                const teamExits = temp.find((obj)=> obj === options.name)
                                if(empExits){
                                    tempList = tempList.filter((obj) => obj.teamId !== teamId);
                                    props.updateState({crmLeadTeamAssignedList: tempList});
                                }
                                if(teamExits){
                                    temp = temp.filter((obj) => obj !== options.name);
                                    props.updateState({selectedTeam: temp});

                                    const payload = { 
                                        relationshipId:props.companyInfo?.relationshipId,
                                        teamNames:temp?.join(",")
                                    };
                                    props.getTeamMembersByTeamName(payload);
                                }

                                
                            }}
                            valueKeyName='teamName'
                            // selectedValue={(props.crmLeadTeamAssignedList|| []).map((obj) => {return obj.teamId})}
                            optionKeyName='teamId'
                            onSelect={(teamId, options) => {
                                console.log(options,"svsvsvfvdfvddb",teamId)
                                let tem = props.selectedTeam || [];
                                let tempList = props.crmLeadTeamAssignedList || [];
                                tempList.push({teamId: options.key, relationshipId: props.companyInfo.relationshipId});
                                tem.push(options.name);
                                props.updateState({crmLeadTeamAssignedList: tempList ,
                                    selectedTeam:tem
                                });
                                const payload = { 
                                    relationshipId:props.companyInfo?.relationshipId,
                                    teamNames:tem?.join(",")
                                };
                                props.getTeamMembersByTeamName(payload);
                            }}
                        />
                    </Col>

                   {props.employeeByTeamName?.length  ? <Col span={8}>
                        <div className='i-label'>Assign Team Member<span className='required'>*</span></div>
                        <MultipleSelectDropdown
                            items={props.employeeByTeamName || []}
                            placeholder={'Assign to'}
                            mode='multiple'
                            onDeSelect={(empId, options) => {
                                let tempList = props.crmLeadEmpAssignedList || [];
                                const empExits = tempList.find((obj)=> obj.relEmployeeId === empId)
                                if(empExits){
                                    tempList = tempList.filter((obj) => obj.relEmployeeId !== empId);
                                    props.updateState({crmLeadEmpAssignedList: tempList});
                                }
                            }}
                            valueKeyName='fullName'
                            valueKeyName2='hierarchyName'
                            // selectedValue={(props.crmLeadEmpAssignedList|| []).map((obj) => {return obj.relEmployeeId})}
                            optionKeyName='relationshipEmployeeId'
                            onSelect={(empId, options) => {
                                let tempList = props.crmLeadEmpAssignedList || [];
                                tempList.push({relEmpId: options.key, relationshipId: props.companyInfo.relationshipId});
                                props.updateState({crmLeadEmpAssignedList: tempList});
                            }}
                        />
                    </Col>:""}
        </Row>
      </Drawer>
    </>
  );
};

export default LeadTranferDrawer;
