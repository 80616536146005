import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker } from 'antd';
// import { TextBox } from '../../../../general/TextBox';
import { getMomentDateForUI, getCurrentDateForBackend, getMonthsBetweenDates } from '../../../../../utils'
// import { DropdownRef } from '../../../../general/Dropdown';
import { CONSTANTS } from '../../../../../static/constants';
import { CustomButton } from '../../../../general/CustomButton';
import moment from 'moment-timezone';

class SchoolSessionComponent extends Component {
  constructor(props) {
    super(props);
    // let data = props.data || {}
    this.state = {
      
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    
    const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content create-project-control">
            <Form.Item label={<FormattedMessage id='schoolSession.text' defaultMessage='' />} labelAlign="left" colon={false}>
              {getFieldDecorator('schoolSession', {
                initialValue: this.state.schoolSession || undefined,
                rules: [
                  {
                    required: false,
                    message: 'School Session is required.',
                  }
                ],
              })
              (<>
                <DatePicker.RangePicker 
                  className={'cursor-pointer'}
                  allowClear={false}
                  format={format}
                  key={`schoolSession`}

                  value={this.state.startDate ? [getMomentDateForUI({ date: this.state.startDate, format }), getMomentDateForUI({ date: this.state.endDate, format })] : []}
                  onChange={(selectedDate, dateString) => {
                    
                      this.props.form.setFieldsValue({ startDate: dateString[0], endDate: dateString[1]});
                      this.setState({ startDate: dateString[0], endDate: dateString[1] });
                  }}
                  disabledDate={(current) => current && current < moment().startOf('year')} 
                />
                <div style={{marginTop: '-10px'}}>{this.state.startDate && this.state.endDate ? `Total Months - ${getMonthsBetweenDates(this.state.startDate, this.state.endDate)}`:''}</div>
              </>)}
            </Form.Item>
            
      
            {/* <Form.Item label={<FormattedMessage id='isCurrentschoolSession.text' defaultMessage='' />} className='hide' colon={false} >
              {getFieldDecorator('isDefault', {
                initialValue: this.state.isDefault || undefined
              })
                (<span />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='isCurrentschoolSession.text' defaultMessage='' />} colon={false}>
              {getFieldDecorator('isDefault', {
                initialValue: this.state.isDefault || undefined,
              })
                (<Checkbox className='login-form-checkbox' checked={this.state.isDefault} onChange={(e) => {
                  this.setState({ isDefault: e.target.checked ? 1 : 0 })
                }}></Checkbox>)}
            </Form.Item>  */}
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.project.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal() }}
              />
              <CustomButton
                intlId={'confirmation.submit'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          startDate: getCurrentDateForBackend(this.state.startDate),
          endDate: getCurrentDateForBackend(this.state.endDate),
          relationshipId: (this.props.companyInfo || {}).relationshipId,
         // createdByEmpId: (this.props.userInfo || {}).relationshipEmployeeId,
          isDefault: this.state.isDefault ? 1 : 0,
        }

        const modalData = {
          modalBody: 'Are you sure you want to create session ?',
          handleSubmit: () => {
            this.props.addSchoolSession(payload, this.props);
            this.props.hideModal();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'School_session_component' })(injectIntl(SchoolSessionComponent));
