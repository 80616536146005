import React, { Fragment, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, Button } from "antd";
import { CustomButton } from "../../../../general/CustomButton";
import { MODAL_ACTIONS } from "../../../../../static/constants";
// import { Dropdown } from '../../../../general/Dropdown';
import { TextBox } from "../../../../general/TextBox";
// import ColorPicker from 'material-ui-rc-color-picker';
import { Panel as ColorPickerPanel } from "material-ui-rc-color-picker";
import "material-ui-rc-color-picker/assets/index.css";
import { ErrorMsg } from "../../../../general/ErrorMessage";
// import * as find from "lodash.find";

const ColorStatusCompo = (props) => {
  const { formData = {}, data = {} } = props;
  //const labelName = getLabelName(modalType);
  formData.textBoxValue = data.txData
    ? data.txData.status
    : formData.textBoxValue;
  const [formDataObj, setFormDataObj] = useState(formData);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Form
        layout="vertical"
        className="login-form"
        onSubmit={(e) => {
          e.preventDefault();
          if (!formDataObj.textBoxValue) {
            // return showToasterMessage({
            //   description: "Please enter user status",
            // });
            setFormDataObj({
              submittedOnce: true,
              textBoxValue: formDataObj.textBoxValue,
              statusColor: formDataObj.statusColor,
            });

            // props.updateState({
            //   submittedOnce: true,
            // });
          }
          if (!formDataObj.statusColor) {
            // return showToasterMessage({
            //   description: "Please enter user status color",
            // });
            setFormDataObj({
              submittedOnce: true,
              textBoxValue: formDataObj.textBoxValue,
              statusColor: formDataObj.statusColor,
            });
          }

          if (
            formDataObj.textBoxValue &&
            formDataObj.textBoxValue.length &&
            formDataObj.statusColor
          ) {
            e.preventDefault();
            props.handleSubmit(formDataObj);
          } else {
            setFormDataObj({
              submittedOnce: true,
              textBoxValue: formDataObj.textBoxValue,
              statusColor: formDataObj.statusColor,
            });
          }
        }}
      >
        <Row>
          <Col span={6} className="padding-top-7">
            <span className="text-input-label">
              <FormattedMessage id="common.status" defaultMessage="" />
              <span className="required">*</span>
            </span>
          </Col>
          <Col span={12}>
            <TextBox
              className="new-fed1 mb10"
              placeholder="Status Name"
              type="text"
              maxLength={25}
              value={props.statusText}
              onChange={(e) => {
                setFormDataObj({
                  ...formDataObj,
                  textBoxValue: e.target.value,
                });
              }}
            />
            <ErrorMsg
              validator={() => {
                return !formDataObj.submittedOnce || formDataObj.textBoxValue;
              }}
              message={
                <FormattedMessage
                  id="requiredField.missing.msg"
                  defaultMessage=""
                  values={{ value: "Status Name" }}
                />
              }
            />
            {/* <Dropdown
                  // items={statusList}
                  items={[{statusId: 'open', statusName: 'Open'}, {statusId: 'hold', statusName: 'Hold'}, {statusId: 'inprogress', statusName: 'Inprogress'}, {statusId: 'closed', statusName: 'Closed'}]}
                  valueKeyName='statusName'
                  optionKeyName='statusId'
                  value={formDataObj.textBoxValue}
                  style={{ width: '100%', marginBottom : '10px'}}
                  onSelect={(selectedValue, option) => {
                  const selectedObj = find((statusList || []), { statusId: option.value }) || {};
                  updateFormData && updateFormData({ ...formDataObj, textBoxValue: ((option || {}).props || {}).name, statusColor: selectedObj.statusColor });
                  setFormDataObj({ ...formDataObj, textBoxValue: ((option || {}).props || {}).name, statusColor: selectedObj.statusColor });
                  dispatch({
                      type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                      data: { formData: { textBoxValue: ((option || {}).props || {}).name, optionId: ((option || {}).props || {}).key } }
                  })
                  }}
              /> */}
          </Col>
        </Row>
        <Row>
          <Col span={6} className="padding-top-7">
            <span className="text-input-label">
              <FormattedMessage
                id="addStatus.text.statusColor"
                defaultMessage=""
              />
              <span className="required">*</span>
            </span>
          </Col>
          <Col
            span={12}
            className="mr20 pl10 pr10 pt5 pb5"
            style={{
              background: formDataObj.statusColor || "#FFFFFF",
              borderRadius: "5px",
              color:
                !formDataObj.statusColor ||
                formDataObj.statusColor === "#FFFFFF"
                  ? "#262626"
                  : "#FFFFFF",
            }}
          >
            {formDataObj.textBoxValue}
          </Col>
        </Row>
        <Row>
          <Col span={6} className="padding-top-7"></Col>
          <Col span={12} style={{ marginLeft: "-7px" }}>
            <ColorPickerPanel
              enableAlpha={false}
              color={formDataObj.statusColor || "#FFFFFF"}
              className="mt10"
              onChange={(obj) => {
                setFormDataObj({ ...formDataObj, statusColor: obj.color });
              }}
              mode="RGB"
            />
            <ErrorMsg
              validator={() => {
                return !formDataObj.submittedOnce || formDataObj.statusColor;
              }}
              message={
                <FormattedMessage
                  id="requiredField.missing.msg"
                  defaultMessage=""
                  values={{ value: "Status Name" }}
                />
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={21}>
            <Button
             
            
              key={1}
              onClick={() => {
                dispatch({ type: MODAL_ACTIONS.HIDE_MODAL });
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col span={3}>
            <CustomButton
              className="right-pull"
              intlId={"button.save.label"}
              defaultMessage={""}
              htmlType="submit"
              key={2}
            />
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};
export default injectIntl(ColorStatusCompo);
