import React, { Fragment, useEffect, useState} from 'react';
import { FormattedMessage } from 'react-intl';
import {  FilePdfOutlined, MailOutlined, DeleteOutlined, PrinterOutlined, EditOutlined} from '@ant-design/icons';
import { Row, Col, Button,Skeleton } from 'antd'
import * as find from 'lodash.find';
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS, TRANSACTION_TYPES, PERMISSION_VALUES, MODULE_CODED_VALUES, MODAL_TYPE, CONSTANTS_TRANSACTIONS } from '../../../../../static/constants';
import PackageLabel from './LabelDownload';
import PackageLabelHeader from './PackageLabelHeader';
import { showToasterMessage, checkACLPermission, getMomentDateForUIReadOnly } from '../../../../../utils';
import filter from 'lodash.filter';
// import { LogoComponent } from '../../../../general/LogoComponent';
import StandardShipment from '../../../../../containers/customer/salesOrder/shipment';
import StandardPackage from '../../../../../containers/customer/salesOrder/package';


const PackageDetails = (props) => {
    const { soPackageDetail, companyInfo, permissions } = props;
    const primaryPerm = permissions.primary;
    const { customerSOPackageDetailsList } = soPackageDetail;
    const relationshipAddress = find(soPackageDetail.boLocationPackageList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const companyRelationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
    const billingAddress = find(soPackageDetail.boLocationPackageList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find(soPackageDetail.boLocationPackageList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    let isColumnEmpty = {
        product: true,
        description: true,
        sku: true,
        uomName: true,
        quantityOrdered: true,
        quantityPacked: true,
    };
    if (customerSOPackageDetailsList && customerSOPackageDetailsList.length) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, customerSOPackageDetailsList, props);
    }
    const [showComp, setShowComp] = useState(false);
  useEffect(()=>{
    const delay = setTimeout(()=>{
      setShowComp(true);
    }, 400);
    return ()=> clearTimeout(delay);
  }, []);

    const getPayloadForPdf = () => {
        return {
            fileName: (soPackageDetail.packageNumber || 'Package')+'.pdf',
            transactionName: 'soPackage',
            relationshipAddress: relationshipAddress,
            billingAddress: billingAddress,
            shippingAddress: shippingAddress,
            isColumnEmpty: isColumnEmpty,
            soData: {...soPackageDetail, customerName: soPackageDetail.companyName },
            companyInfo: companyInfo,
            soPackageDetail: soPackageDetail
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () =>{
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const generateLabel = () => {
        const { soPackageDetail, companyInfo } = props;
        const labelPackageList = [];
        customerSOPackageDetailsList.forEach((itemData) => {
            labelPackageList.push({
                customer: soPackageDetail.companyName || "",
                po: soPackageDetail.customerPONumber || "",
                poLineNo: itemData.sNo || 1,
                stockNo: itemData.stockNumber || "",
                materialName: itemData.itemName ? itemData.itemName + ((itemData.itemVariantName && " (" + itemData.itemVariantName + " ) ") || "") : '',
                qty: itemData.quantityPacked || ""
            })
        });
        //props.generateLabelPdf(payload)
        props.pushModalToStack({
            title: <PackageLabelHeader {...props}  companyInfo={companyInfo} packageItemList = {labelPackageList}/>,
            modalBody: <PackageLabel {...props} companyRelationshipBillingAddress={companyRelationshipBillingAddress} companyInfo={companyInfo} packageItemList = {labelPackageList}
            />,
            width: 1000,
            hideFooter: true
        })
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired= true;
        payload.customerId = soPackageDetail.customerId;
        payload.invoiceMasterId = soPackageDetail.invoiceMasterId;
        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.SO_PACKAGE,
        });
    };

    const createShipment = (props, packageObj) => {
        if (props.qualityChecklistSetting && !props.qualityChecklistSetting.isGenerateShipmentWithoutQualityCheck && ( packageObj.qualityChecklistPackageList && packageObj.qualityChecklistPackageList.length && packageObj.qualityChecklistStatus !== 'completed')) {
            return showToasterMessage({ messageType: 'error', description: 'Shipment can not be generate without quality check' });
        }
        props.popModalFromStack();
        props.pushModalToStack({
            modalBody: <StandardShipment {...props}
            customerId= {packageObj.customerId}
            soMasterId= {packageObj.salesOrderMasterId}
            amendSalesOrderMasterId= {packageObj.amendSalesOrderMasterId}
            packageNumber= {packageObj.packageNumber}
            type={"connect"}
            />,
            width: '100%',
            hideFooter: true,
            hideTitle:true,
            keyboard:false,
            wrapClassName: 'new-transaction-wrapper'
        })
        // props.history.push({
        //     pathname: '/admin/so/shipment',
        //     state: {
        //         customerId: packageObj.customerId,
        //         soMasterId: packageObj.salesOrderMasterId,
        //         packageNumber: packageObj.packageNumber
        //     }

        // })
    }

    const updatePackage = (props, packageObj, isUpdate) => {
        props.pushModalToStack({
            modalBody: <StandardPackage {...props} 
            customerId= {packageObj.customerId}
                salesOrderMasterId= {packageObj.salesOrderMasterId}
                soPackageMasterId= {packageObj.soPackageMasterId}
                update= {isUpdate}
                type={"new"}
            />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            keyboard:false,
            wrapClassName: 'new-transaction-wrapper'
        })
       
        // props.history.push({
        //     pathname: '/admin/so/package',
        //     state: {
                // customerId: packageObj.customerId,
                // salesOrderMasterId: packageObj.salesOrderMasterId,
                // soPackageMasterId: packageObj.soPackageMasterId,
                // update: isUpdate
        //     }
        // })
    }

    const qualityCheckUpdated = () => {
        const payload = {
            companyInfo: companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: props.pageNumber || 1,
            pageSize: props.pageSize || 25,
            packageStatus: props.packageStatus || 'all'
        };
        props.fetchAllPackage(payload);
    }

    const addItemConfirmationModal = (props, text, color, txData) => {
        const { showModal, companyInfo } = props;
      
        const modalBody = <span>
            <FormattedMessage
                id='setItem.confirmation'
                defaultMessage={``}
            />
           
      </span>;

        let payload = {
            userStatus: text,
            userStatusColor: color,
            relationshipId: companyInfo.relationshipId,
            soPackageMasterId: txData.soPackageMasterId,
            customerId: txData.customerId,
            salesOrderMasterId: txData.salesOrderMasterId
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateUserStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    }



    const updateUserStatus = (props, txData) => {
        let payload = { formData: {}, txData };
        const formData = payload.formData;
        const { pushModalToStack } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, formData.statusColor, txData);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
                formData,
                hideFooter: true,
                modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.PACKAGE },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, formData.statusColor, txData);
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                }
            };
            pushModalToStack(data);
        }
    }

    return (
        <div className={"custom-modal show"}>
            <i className="close-icon">
                <svg width="17" height="17" onClick={() => { props.popModalFromStack(); }}>
                    <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                    <path fill="#67757d" fillRule="evenodd"
                        d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    {showComp ?

                    <><div className="modal-header align-left">
                            <FormattedMessage id='modal.txDetails.package.label' defaultMessage='' />
                            <h6> {soPackageDetail.companyName || ''}</h6>
                        </div><div className="inner-content">
                                <Row>
                                    <Col span={5} className="d-inline-flex w-100 pr20">
                                        {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                                        {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 + '/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/' + (props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now() + "?"+Date.now()} /> */}
                                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                                            <div className="read-only-content pl10">
                                                <div className="title lh-adjust pb5">
                                                    {props.companyInfo.storeName}
                                                </div>
                                                <div>{getAddressInfoFragment(relationshipAddress, companyInfo)}</div>
                                            </div>
                                        </Skeleton>
                                    </Col>

                                    <Col span={5} className="read-only-content">
                                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                                            <div className="title lh-adjust"><FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' /></div>
                                            <div>
                                            <div>
                                              
                                            {/* { soPackageDetail.salesOrderNumber?.includes("SOO") ?"":getContactNameFragment(soPackageDetail?.additionalInfo)} */}
                                            {
                                                soPackageDetail.salesOrderNumber?.includes("SOO") ?
                                                <>
                                                {billingAddress?.fullName}
                                                {billingAddress?.emailAddress}
                                                {billingAddress?.cellPhone}
                                                </>:""
                                            }
                                              </div>
                                                {soPackageDetail.salesOrderNumber?.includes("SOO") ?"":getContactNameFragment((soPackageDetail.additionalInfoList ? soPackageDetail.additionalInfoList[0] || {} : {}))}
                                                {soPackageDetail.companyName}
                                            </div>
                                            <div>
                                                {getAddressInfoFragment(billingAddress, (soPackageDetail.additionalInfoList ? soPackageDetail.additionalInfoList[0] || {} : {}))}
                                            </div>
                                        </Skeleton>
                                    </Col>

                                    <Col span={5} className="read-only-content">
                                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                                            <div className="title lh-adjust"><FormattedMessage id='modal.txDetails.common.shipTo' defaultMessage='' /></div>
                                              <div>
                                                
                                              {
                                                soPackageDetail.salesOrderNumber?.includes("SOO") ?
                                                <>
                                                {shippingAddress?.fullName}
                                                {shippingAddress?.emailAddress}
                                                {shippingAddress?.cellPhone}
                                                </>:""
                                            }
                                              </div>
                                            {soPackageDetail.companyName}
                                            <div>
                                                {getAddressInfoFragment(shippingAddress, (soPackageDetail.additionalInfoList ? soPackageDetail.additionalInfoList[0] || {} : {}))}
                                            </div>
                                        </Skeleton>
                                    </Col>

                                    <Col span={3} className="read-only-content">
                                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                                            {soPackageDetail.length &&
                                                <Row>
                                                    <Col span={8} className="title"><FormattedMessage id='modal.txDetails.package.length' defaultMessage='' /></Col>
                                                    <Col span={16}>{soPackageDetail.length} {soPackageDetail?.dimensionUnit || 'Inches'} </Col>
                                                </Row>}
                                            {soPackageDetail.width &&
                                                <Row>
                                                    <Col span={8} className="title"><FormattedMessage id='modal.txDetails.package.width' defaultMessage='' /></Col>
                                                    <Col span={16}>{soPackageDetail.width} {soPackageDetail?.dimensionUnit || 'Inches'}</Col>
                                                </Row>}
                                            {soPackageDetail.height &&
                                                <Row>
                                                    <Col span={8} className="title"><FormattedMessage id='modal.txDetails.package.height' defaultMessage='Inches' /></Col>
                                                    <Col span={16}>{soPackageDetail.height} {soPackageDetail?.dimensionUnit || 'Inches'} </Col>
                                                </Row>}
                                            {soPackageDetail.weight &&
                                                <Row>
                                                    <Col span={8} className="title"><FormattedMessage id='modal.txDetails.package.weight' defaultMessage='' /></Col>
                                                    <Col span={16}>{soPackageDetail.weight} {soPackageDetail?.weightUnit || 'Ounces'} </Col>
                                                </Row>}
                                        </Skeleton>
                                    </Col>

                                    <Col span={6} className="read-only-content">
                                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                                            <Row>
                                                <Col span={12} className="title text-right"><FormattedMessage id='modal.txDetails.package.packageNumber' defaultMessage='' /></Col>
                                                <Col span={12}>{soPackageDetail.packageNumber}</Col>
                                            </Row>

                                            <Row>
                                                <Col span={12} className="title text-right">
                                                    <FormattedMessage id='salesOrder.creation.date' defaultMessage='' />

                                                </Col>
                                                <Col span={12}>{(soPackageDetail.packageDate && getMomentDateForUIReadOnly({ date: soPackageDetail.packageDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}</Col>
                                            </Row>

                                            <Row>
                                                <Col span={12} className="title text-right"><FormattedMessage id='modal.txDetails.package.soNumber' defaultMessage='' /></Col>
                                                <Col span={12}>{soPackageDetail.salesOrderNumber}</Col>
                                            </Row>

                                            <Row>
                                                <Col span={12} className="title text-right">
                                                    <FormattedMessage id='addItem.text.soDate' defaultMessage='' />
                                                </Col>
                                                <Col span={12}>
                                                    {(soPackageDetail.salesOrderDate && getMomentDateForUIReadOnly({ date: soPackageDetail.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                </Col>
                                            </Row>
                                            {soPackageDetail.companyNamecustomerPONumber &&
                                                <Row>
                                                    <Col span={12} className="title text-right">
                                                        <FormattedMessage id='modal.txDetails.package.poNumber' defaultMessage='' />
                                                    </Col>
                                                    <Col span={12}>
                                                        {(soPackageDetail.companyNamecustomerPONumber && getMomentDateForUIReadOnly({ date: soPackageDetail.companyNamecustomerPONumber, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                    </Col>
                                                </Row>}


                                            {soPackageDetail.status &&
                                                <Row>
                                                    <Col span={12} className="title text-right"><FormattedMessage id='common.status' defaultMessage='' /> </Col>
                                                    <Col span={12}><FormattedMessage id={soPackageDetail.status} defaultMessage={soPackageDetail.status} /></Col>
                                                </Row>}
                                            {soPackageDetail.deliveryDate &&
                                                <Row>
                                                    <Col span={12} className="title text-right"><FormattedMessage id='modal.txDetails.package.deliveryDate' defaultMessage='' /> </Col>
                                                    <Col span={12}>{(soPackageDetail.deliveryDate && getMomentDateForUIReadOnly({ date: soPackageDetail.deliveryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}</Col>
                                                </Row>}
                                            {soPackageDetail.referenceNumber &&
                                                <Row>
                                                    <Col span={12} className="title text-right"><FormattedMessage id='modal.txDetails.common.refNumber' defaultMessage='' /></Col>
                                                    <Col span={12}>{soPackageDetail.referenceNumber}</Col>
                                                </Row>}
                                            {soPackageDetail.incotermName &&
                                                <Row>
                                                    <Col span={12} className="title text-right"><FormattedMessage id='package.packageDetail.incoterm' defaultMessage='' /></Col>
                                                    <Col span={12}>{soPackageDetail.incotermName}</Col>
                                                </Row>}
                                        </Skeleton>
                                    </Col>
                                </Row>
                                <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                                    <div className="modal-table-container">
                                        <table className="transaction-details-table">
                                            <thead>
                                                <tr>
                                                    <th className=''>#</th>
                                                    {(!isColumnEmpty.product || !isColumnEmpty.description) && <th>
                                                        {!isColumnEmpty.product && <span> <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                                        {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                                        {!isColumnEmpty.description && <span> <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                                    </th>}
                                                    {!isColumnEmpty.sku && <th className="text-center"><FormattedMessage id='modal.txDetails.common.sku' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.quantityOrdered && <th className="text-center"><FormattedMessage id='modal.txDetails.package.qtyOrdered' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.quantityPacked && <th className="text-center"><FormattedMessage id='modal.txDetails.package.qtyPacked' defaultMessage='' /></th>}
                                                    {!isColumnEmpty.uomName && <th className="text-center"><FormattedMessage id='modal.txDetails.common.uom' defaultMessage='' /></th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(customerSOPackageDetailsList || []).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="text-center">{i + 1}</td>
                                                            {(!isColumnEmpty.product || !isColumnEmpty.description) && <td>
                                                                <strong>{(item.itemName || "") + (item.itemVariantName ? " (" + item.itemVariantName + ")" : '') || ''}</strong>
                                                                {!isColumnEmpty.description && <div className="description-cell">
                                                                    {/* {getDescriptionFragment(soPackageDetail, item, i + 1)} */}
                                                                    <pre>{ReactHtmlParser(item.description || '-')}</pre>
                                                                </div>}
                                                            </td>}
                                                            {!isColumnEmpty.sku && <td className="text-center">{item.sku || '-'}</td>}
                                                            {!isColumnEmpty.quantityOrdered && <td className="text-center">{item.quantityOrdered || '-'}</td>}
                                                            {!isColumnEmpty.quantityPacked && <td className="text-center">{item.quantityPacked || '-'}</td>}
                                                            {!isColumnEmpty.uomName && <td className="text-center">{item.uomName || '-'}</td>}
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                           
                                <Row>
                                    <Col span={12} className="pb50">
                                        {soPackageDetail.remarksInternal &&
                                            <Col span={15} className="read-only-content pt10">
                                                <Row>
                                                    <Col span={24} className="remarks-heading">
                                                        <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                                    </Col>
                                                    <Col span={24}>
                                                        {ReactHtmlParser(soPackageDetail.remarksInternal)}
                                                    </Col>
                                                </Row>
                                            </Col>}
                                        {soPackageDetail.remarksCustomer &&
                                            <Col span={15} className="read-only-content">
                                                <Row>
                                                    <Col span={24} className="remarks-heading">
                                                        <FormattedMessage id='modal.txDetails.common.remarks' defaultMessage='' />
                                                    </Col>
                                                    <Col span={24}>
                                                        {ReactHtmlParser(soPackageDetail.remarksCustomer)}
                                                    </Col>
                                                </Row>
                                            </Col>}
                                    </Col>
                                </Row>
                                </Skeleton>
                            </div><div className="button-group-action header-control">
                                {soPackageDetail.status === "not.shipped" && <div className="small-btn-group">
                                    <Button style={{ height: '38px' }} title={props.intl?.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                        icon={<EditOutlined />}
                                        onClick={() => {
                                            props.popModalFromStack();
                                            updatePackage(props, soPackageDetail, true);
                                        } }
                                    >
                                    </Button>
                                    {/* <Button
            title='Clone'
            icon={<CopyOutlined />}
            onClick={() => {
                updatePackage(props, soPackageDetail, true);
            }}
            >
        </Button> */}
                                    <Button style={{ height: '38px' }}
                                        title={props.intl?.formatMessage({ id: 'modal.txDetails.common.delete', defaultMessage: '' })}
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            props.showModal({
                                                modalBody: "Are you sure you want to delete this transaction?",
                                                handleSubmit: () => {
                                                    props.popModalFromStack();
                                                    props.deletePackage({
                                                        customerId: soPackageDetail.customerId,
                                                        packageId: soPackageDetail.packageId,
                                                        soPackageMasterId: soPackageDetail.soPackageMasterId,
                                                        relationshipId: soPackageDetail.relationshipId,
                                                        salesOrderMasterId: soPackageDetail.salesOrderMasterId
                                                    }, props);
                                                }
                                            });
                                        } }
                                    >
                                    </Button>
                                </div>}
                                <div className="small-btn-group">

                                    <Button style={{ height: '38px' }}
                                        title={props.intl?.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                        icon={<MailOutlined />}
                                        onClick={() => {
                                            openEmailDrawer(props);

                                        } }
                                    >
                                    </Button>

                                    <Button style={{ height: '38px' }}
                                        title={props.intl?.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                        icon={<FilePdfOutlined />}
                                        onClick={() => {
                                            generatePDF();
                                        } }
                                    >
                                    </Button>

                                    <Button style={{ height: '38px' }}
                                        title={props.intl?.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                        icon={<PrinterOutlined />}
                                        onClick={() => {
                                            printPdf();
                                        } }
                                    >
                                    </Button>
                                </div>
                                <Button style={{ height: '38px' }}
                                    title='Package Label'

                                    onClick={() => {
                                        generateLabel();
                                    } }
                                >
                                    <FormattedMessage id='modal.txDetails.package.packageLabel' defaultMessage='' />
                                </Button>
                                {soPackageDetail.status === "not.shipped" ?
                                    <Button style={{ height: '38px' }}

                                        onClick={() => {
                                            createShipment(props, soPackageDetail);
                                        } }
                                    >
                                        <FormattedMessage id='modal.txDetails.package.convertToShipment' defaultMessage='' />
                                    </Button> : ''}
                                {
                                    //(soPackageDetail.qualityChecklistPackageList && soPackageDetail.qualityChecklistPackageList.length) 
                                    (checkACLPermission(primaryPerm, MODULE_CODED_VALUES.QUALITY_CHECK, PERMISSION_VALUES.CREATE) && soPackageDetail.qualityChecklistPackageList && soPackageDetail.qualityChecklistPackageList.length) ?
                                        <Button style={{ height: '38px' }}
                                            onClick={() => {
                                                props.popModalFromStack();
                                                props.updateState({
                                                    title: <div className="drawer-title"><FormattedMessage id='modal.txDetails.package.qualityCheck' defaultMessage='' /></div>,
                                                    qualityCheckList: soPackageDetail.qualityChecklistPackageList,
                                                    qualityChecklistStatus: soPackageDetail.qualityChecklistStatus,
                                                    qualityCheckAction: "check",
                                                    qualityCheckDrawerVisible: true,
                                                    addQualityCheckToProps: function (qualityCheckList, status) {
                                                        props.updateState({
                                                            qualityCheckList: qualityCheckList
                                                        });
                                                        props.updatePackageQualityCheck({ ...soPackageDetail, qualityChecklistPackageList: qualityCheckList, qualityChecklistStatus: status }, props, qualityCheckUpdated);
                                                    }
                                                });
                                            } }
                                            type="primary"
                                        >
                                            <FormattedMessage id='modal.txDetails.package.qualityCheck' defaultMessage='' />
                                        </Button> : ''}
                                <Button style={{ width: 'auto', height: '38px' }}
                                    onClick={() => {
                                        updateUserStatus(props, soPackageDetail);
                                    } }
                                >
                                    <FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage='' />

                                </Button>
                            </div></>
                :""    
                }
                    
                </div>
            </div>
        </div>
    );

}

const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = <Fragment>
        <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (

                <div>
                    {obj.streetAddress1} {obj.streetAddress2}{", "}
                </div>

            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (

                <div>
                    {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>

            ) : ""}
             {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
        </div>
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        }

    </Fragment>

    return fragmentObj;
}

const getContactNameFragment = (additionInfo) => {
    if (!additionInfo) return <Fragment />;
    let contactName = "";
    if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
    if (additionInfo.firstName) contactName += additionInfo.firstName;
    if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
    if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
    const fragmentObj = <Fragment>
        {
            (contactName && contactName.length)
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

// const getDescriptionFragment = (soDetail, item, index) => {
//     if (!item) return <Fragment />;
//     const fragmentObj = <Fragment>
//         {
//             item.stockNumber
//                 ? <div>
//                     <span className="title"> Stock # : </span>{item.stockNumber}
//                     <br></br>
//                     <br></br>
//                 </div> :
//                 ''
//         }
//         {
//             item.partNumber
//                 ? <div>
//                     <span className="title"> Part # : </span>{item.partNumber}
//                     <br></br>
//                     <br></br>
//                 </div> :
//                 ''
//         }
//         {
//             item.description
//                 ? <div>
//                     {ReactHtmlParser(item.description || '')}
//                     <br></br>
//                     <br></br>
//                 </div> :
//                 ''
//         }
//         {
//             item.origin
//                 ? <div>
//                     <span className="title"> Origin : </span>{item.origin}
//                 </div> :
//                 ''
//         }
//         {
//             item.hsCode
//                 ? <div>
//                     <span className="title"> HS Code : </span>{item.hsCode}
//                     <br></br>
//                     <br></br>
//                 </div> :
//                 ''
//         }
//         {
//             item.comment
//                 ? <div>
//                     <span className="title"> Warehouse Name : </span>{item.comment}
//                     <br></br>
//                     <br></br>
//                 </div> :
//                 ''
//         }
//         {
//             (soDetail.customerPONumber)
//                 ? <div> (
//                     {soDetail.customerPONumber ? <span className="title">PO {soDetail.customerPONumber} - </span> : ''}
//                     {index ? <span className="title"> Line {index}</span> : ''}
//                 )
//                 </div> :
//                 ''
//         }
//     </Fragment>
//     return fragmentObj;
// }

const updateColumnEmptyCheck = (isColumnEmpty, customerSOPackageDetailsList, props) => {
    if (!customerSOPackageDetailsList) return isColumnEmpty;
    for (let i in customerSOPackageDetailsList) {
        let row = customerSOPackageDetailsList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.description) { isColumnEmpty.description = false; }
        if (row.sku) { isColumnEmpty.sku = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
        if (row.quantityPacked) { isColumnEmpty.quantityPacked = false; }
        if (row.quantityOrdered) { isColumnEmpty.quantityOrdered = false; }

    }
    return isColumnEmpty;
}


export default PackageDetails;
