import React from "react";
import { Card, Button } from 'antd';
import envConfig from '../../../config/environmentConfig';
import AmazonIcon from '../../../assets/images/amazon-logo.png';
import ShopifyIcon from '../../../assets/images/shopify.png';
import EabayIcon from '../../../assets/images/eabay-logo.png';
import WalmartIcon from '../../../assets/images/walmart-logo.png';
import "./index.scss";
import { FormattedMessage } from "react-intl";
import WallmartIntegrateComponent from './wallmartIntegrate' ;
import { MARKETPLACE_NAME } from '../../../static/constants';


const SalesChannelComponent = (props) => {
    return (
        <div className="page-details-container text-center integ">
           <Card className= "accounting-market-place text-center integs" title="" extra={<img src={AmazonIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
           {props.marketplaceConfig  && props.marketplaceConfig[MARKETPLACE_NAME.AMAZON] ? <div className="cr cr-top cr-left cr-green">Integrated</div> : ''}
           <h2><FormattedMessage id='amazon.logo' defaultMessage='' /></h2>
            <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
            <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
            <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
            <div className="border-footer mt10"></div>
      {props.marketplaceConfig  && props.marketplaceConfig[MARKETPLACE_NAME.AMAZON] ? <Button className="cnant-btn grey-button" onClick={
          () =>{
            props.disconnectConfiguration(props.marketplaceConfig[MARKETPLACE_NAME.AMAZON], props);
          }
      }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn ant-btn-primary" onClick={()=>{
        //props.fetchMwsAuthURL();
       // window.location = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.bf1ea735-726f-461e-95c1-cf04a73de466&version=beta`;
       window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/alpide_amazon/onboarding?callback_uri=${envConfig.CALLBACK_URI_MARKTPLACE}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}${envConfig.rootContext}/`;
    }}>Integrate</Button>}
    </Card>
    <Card className= "accounting-market-place text-center integs" title="" extra={<img src={ShopifyIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
    {props.marketplaceConfig  && props.marketplaceConfig[MARKETPLACE_NAME.SHOPIFY] ? <div class="cr cr-top cr-left cr-green">Integrated</div> : ''}
           <h2><FormattedMessage id='shopify.logo' defaultMessage='' /></h2>
            <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
            <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
            <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
            <div className="border-footer mt10"></div>
      {props.marketplaceConfig  && props.marketplaceConfig[MARKETPLACE_NAME.SHOPIFY] ? <Button   className="cnant-btn grey-button" onClick={
          () =>{
            props.disconnectConfiguration(props.marketplaceConfig[MARKETPLACE_NAME.SHOPIFY], props);
          }
      }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn ant-btn-primary" onClick={()=>{
       // props.fetchXeroAuthURL();
    }}>Integrate</Button>}
    </Card>

    <Card className= "accounting-market-place text-center integs" title="" extra={<img src={EabayIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
    { props.marketplaceConfig  && props.marketplaceConfig[MARKETPLACE_NAME.EBAY] ? <div class="cr cr-top cr-left cr-green">Integrated</div> : ''}
           <h2><FormattedMessage id='eabay.logo' defaultMessage='' /></h2>
            <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
            <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
            <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
            <div className="border-footer mt10"></div>
      {props.marketplaceConfig  && props.marketplaceConfig[MARKETPLACE_NAME.EBAY] ? <Button   className="cnant-btn grey-button" onClick={
          () =>{
            props.disconnectConfiguration(props.marketplaceConfig[MARKETPLACE_NAME.EBAY], props);
          }
      }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn ant-btn-primary" onClick={()=>{
        //props.fetchXeroAuthURL();
        window.location = `${envConfig.PAYMENT_SERVER_BASE_URL}/ebay/onboarding?callback_uri=${envConfig.CALLBACK_URI_MARKTPLACE}&relationshipId=${props.companyInfo?.relationshipId}&apiBaseUrl=${envConfig.API_BASE_URL}${envConfig.rootContext}/`;
    }}>Integrate</Button>}
    </Card>

    <Card className= "accounting-market-place text-center integs" title="" extra={<img src={WalmartIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
    {props.marketplaceConfig  && props.marketplaceConfig[MARKETPLACE_NAME.WALMART] ? <div class="cr cr-top cr-left cr-green">Integrated</div>: ''}
           <h2><FormattedMessage id='walmart.logo' defaultMessage='' /></h2>
            <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
            <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
            <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
            <div className="border-footer mt10"></div>
      {props.marketplaceConfig  && props.marketplaceConfig[MARKETPLACE_NAME.WALMART] ? <Button   className="cnant-btngrey-button" onClick={
          () =>{
            props.disconnectConfiguration(props.marketplaceConfig[MARKETPLACE_NAME.WALMART], props);
          }
      }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn ant-btn-primary" onClick={()=>{
     // props.fetchXeroAuthURL();
     props.pushModalToStack({
      modalBody: <WallmartIntegrateComponent {...props} />,
      title:<FormattedMessage id='walmart.logo' defaultMessage='' />,
      width: '30%',
      hideFooter: true,
  })
    }}>Integrate</Button>}
    </Card>

 </div>
 )
};

export default SalesChannelComponent;