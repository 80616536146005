import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Button } from 'antd';
import { Input } from 'antd';
// import Tabs from 'antd/es/tabs';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ErrorMsg } from '../general/ErrorMessage';
import { Dropdown } from '../general/Dropdown';
import { TextBox } from '../general/TextBox';
// import { CustomButton } from '../general/CustomButton';
import { validateEmail } from '../../utils';
import './signup.scss';

// const { TabPane } = Tabs;
const InputGroup = Input.Group;
// const { RangePicker } = DatePicker;

function SignupComponent(props) {
    const {
        email, password, repeatPassword, company, country, contact, address,
        submittedOnce, passwordMismatch, invalidEmailClass, invalidPasswordClass,
        invalidRepeatPasswordClass, invalidCompanyClass, invalidFirstNameClass,
        intl, updateState, countries, fetchStates, phoneNumber, validPassword
    } = props;

    let  timeZones = moment.tz.names();
    let abbrs = {
        EST : 'Eastern Standard Time',
        EDT : 'Eastern Daylight Time',
        CST : 'Central Standard Time',
        CDT : 'Central Daylight Time',
        MST : 'Mountain Standard Time',
        MDT : 'Mountain Daylight Time',
        PST : 'Pacific Standard Time',
        PDT : 'Pacific Daylight Time',
    };
    
    moment.fn.zoneName = function () {
        var abbr = this.zoneAbbr();
        return abbrs[abbr] || abbr;
    };
    let  offsetTmz = [];

    for (var i in timeZones) {
        offsetTmz.push({name: timeZones[i], value: " (GMT" + moment.tz(timeZones[i]).format('Z') + ") " + moment.tz(timeZones[i]).format('z')+ " "+  timeZones[i]});
    }

    let repeatErrorMessage = <ErrorMsg
        message={intl.formatMessage({
            id: 'signup.validation.passwordMismatch',
            defaultMessage: ''
        })}
        validator={() => {
            return !submittedOnce || (repeatPassword && !passwordMismatch);
        }}
    />

    if (!repeatPassword) {
        repeatErrorMessage = <ErrorMsg
            message={intl.formatMessage({
                id: 'signup.validation.confirmPassword',
                defaultMessage: ''
            })}
            validator={() => {
                return !submittedOnce || !!repeatPassword;
            }}
        />
    }

    function validatePasswordCheck(password) {
        const minLength = 8;
        const maxLength = 16;
        const specialCharacters = /[!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|]/;
        const capitalLetter = /[A-Z]/;
        const number = /[0-9]/;
        const passwordc = password || '';

        if (passwordc.length < minLength || passwordc.length > maxLength) {
            return false;
        }
        if (!specialCharacters.test(password)) {
            return false;
        }
        if (!capitalLetter.test(password)) {
            return false;
        }
        if (!number.test(password)) {
            return false;
        }
      
        return true;
    }

    return (
        <Row className='signup-page' type='flex' justify='center' align='middle'>
            <Col className="signup-panel" >
                
                <div className='signup-container'>
                    {
                        !props.isSignupCompleted ?
                        <>
                            <h4 className="heading-title">
                                <FormattedMessage id='signup.text' defaultMessage='' />
                            </h4>
                            {/* <div className="subheading-title">
                                <FormattedMessage id='signup.subheading.text' defaultMessage='' />
                            </div> */}
                            <div className='signup.subheading.text' style={{paddingLeft: '70px', paddingRight: '70px'}}> 
                                Sign up with Alpide to access a world of possibilities for your organization. Enter your details below and embark on a seamless onboarding experience.
                            </div>

                            <div className="signup-form-container">
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSignIn(props);
                                }}>

                                    <Row className="pb15">
                                        <Col  md={24} sm={24} xs={24}>
                                            <TextBox
                                                placeholder={intl.formatMessage(
                                                    { id: 'signup.fullName.placeholder.text', defaultMessage: '' }
                                                )}
                                                maxLength={40}
                                                type='text'
                                                className={invalidFirstNameClass || 'signup-form-input'}
                                                value={contact.firstName}
                                                onChange={(e) => {
                                                    updateState({
                                                        contact: { ...contact, firstName: e.target.value },
                                                        invalidFirstNameClass: !e.target.value ? 'input-text-error' : ''
                                                    })

                                                }}
                                                validator={() => {
                                                    return !!contact.firstName;
                                                }}
                                            />
                                            <ErrorMsg
                                                validator={() => {
                                                    return !submittedOnce || !!contact.firstName;
                                                }}
                                                message={<div style={{fontSize: '12px'}}>{intl.formatMessage(
                                                    { id: 'contact.firstName.message', defaultMessage: '' }
                                                )}</div>}
                                            />
                                        </Col>
                                    </Row>
                                    {/* <Row className="pb15">
                                        <Col md={24} sm={24} xs={24}>
                                            <TextBox
                                                placeholder={intl.formatMessage(
                                                    { id: 'signup.lastName.placeholder.text', defaultMessage: '' }
                                                )}
                                                maxLength={40}
                                                type='text'
                                                value={contact.lastName}
                                                className= 'signup-form-input'
                                                onChange={(e) => {
                                                    updateState({ contact: { ...contact, lastName: e.target.value } })
                                                }}
                                            />
                                        </Col>
                                    </Row>   */}
                                    <Row className="pb15">
                                        <Col md={24} sm={24} xs={24}>
                                            <TextBox
                                                placeholder={intl.formatMessage(
                                                    { id: 'signup.workEmail.text', defaultMessage: '' }
                                                )}
                                                type='text'
                                                maxLength={254} 
                                                value={email}
                                                className={invalidEmailClass || 'signup-form-input'}
                                                onChange={(e) => {
                                                    updateState({
                                                        email: e.target.value,
                                                        invalidEmailClass: validateEmail(e.target.value) ? '' : 'input-text-error'
                                                    });
                                                }}
                                                validator={(val) => {
                                                    return validateEmail(val);
                                                }}
                                                autoComplete="none"
                                            />
                                            <ErrorMsg
                                                validator={() => {
                                                    return !submittedOnce || !!email;
                                                }}
                                                message={<div style={{fontSize: '12px'}}>{intl.formatMessage(
                                                    { id: 'signup.validation.workEmail', defaultMessage: '' }
                                                )}</div>}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="pb15">
                                        <Col md={24} sm={24} xs={24}>
                                            <TextBox
                                                placeholder={intl.formatMessage(
                                                    { id: 'signup.orgName.placeholder', defaultMessage: '' }
                                                )}
                                                type='text'
                                                maxLength={100}
                                                value={company}
                                                className={invalidCompanyClass || 'signup-form-input'}
                                                onChange={(e) => {
                                                    updateState({
                                                        company: e.target.value,
                                                        invalidCompanyClass: !e.target.value ? 'input-text-error' : ''
                                                    })
                                                }}
                                                validator={() => {
                                                    return !!company;
                                                }}
                                            />
                                            <ErrorMsg
                                                validator={() => {
                                                    return !submittedOnce || !!company;
                                                }}
                                                message={<div style={{fontSize: '12px'}}>{intl.formatMessage(
                                                    { id: 'signup.validation.orgName', defaultMessage: '' }
                                                )}</div>}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="pb15">
                                        <Col md={24} sm={24} xs={24}>
                                            <InputGroup compact>
                                                <Dropdown
                                                    className = {'signup-form-input'}
                                                    items={countries}
                                                    valueKeyName='countryName'
                                                    optionKeyName='countryId'
                                                    value={(props.country || {}).countryName || ''}
                                                    placeholder={intl.formatMessage({
                                                        id: 'signup.country.placeholder', defaultMessage: ''
                                                    })}
                                                    onSelect={(optionValue, elem) => {
                                                        updateState({
                                                            country: countries.find(x => x.countryId === +elem.key),
                                                            address: { ...address, state: null },
                                                            invalidCountryClass: !elem.key ? 'input-text-error' : ''
                                                        });
                                                        fetchStates(elem.key);
                                                    }}
                                                />
                                            </InputGroup>
                                            <ErrorMsg
                                                validator={() => {
                                                    return !submittedOnce || !!country;
                                                }}
                                                message={intl.formatMessage(
                                                    { id: 'signup.validation.country', defaultMessage: '' }
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="pb15">
                                        <Col md={24} sm={24} xs={24}>
                                            {/* <Select
                                                style={{ width: 60, height: '48px', zIndex: 1 }}
                                                // onChange={handleChange}
                                                options={[
                                                    { value: 'jack', label: 'Jack' },
                                                ]}
                                            /> */}
                                            <TextBox
                                                className="signup-form-input"
                                                
                                                placeholder={intl.formatMessage(
                                                    { id: 'signup.mobileNumber.placeholder.text', defaultMessage: '' }
                                                )}
                                                type='text'
                                                maxLength={10}
                                                value={phoneNumber}
                                                onChange={(e) => {
                                                    // updateState({
                                                    //     phoneNumber: e.target.value
                                                    // })
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        updateState({ 
                                                            phoneNumber: e.target.value,
                                                            invalidPhoneNumberClass: !e.target.value ?  'input-text-error' : ''
                                                        })

                                                    }
                                                }}
                                            />
                                            <ErrorMsg
                                                validator={() => {
                                                    return !submittedOnce || !!phoneNumber;
                                                }}
                                                message={<div style={{fontSize: '12px'}}>{intl.formatMessage(
                                                    { id: 'signup.validation.mobile', defaultMessage: '' }
                                                )}</div>}
                                            />
                                            {/* <Button 
                                            style={{ marginLeft: -40 }}
                                            onClick={()=>{
                                            }}>
                                                <FormattedMessage id='verify.text' defaultMessage='' />
                                            </Button> */}
                                        </Col>
                                    </Row>

                                    <Row className="pb15">
                                        <Col md={24} sm={24} xs={24}>
                                            <TextBox
                                                placeholder={intl.formatMessage(
                                                    { id: 'signup.password.placeholder.text', defaultMessage: '' }
                                                )}
                                                value={password}
                                                type='password'
                                                className={  'signup-form-input'|| invalidPasswordClass}
                                                onChange={(e) => {
                                                    updateState({
                                                        password: e.target.value,
                                                        invalidPasswordClass: !e.target.value ? 'input-text-error' : '',
                                                        passwordMismatch: repeatPassword !== e.target.value,
                                                        validPassword: validatePasswordCheck(e.target.value),
                                                    });
                                                }}
                                                validator={() => {
                                                    return !!password || validPassword;
                                                }}
                                            />
                                            <ErrorMsg
                                                validator={() => {
                                                    return !submittedOnce || (!!password && validPassword);
                                                }}
                                                message={<div style={{fontSize: '12px'}}>{intl.formatMessage(
                                                    { id: password && !validPassword ? 'password.verification': 'signup.validation.password' , defaultMessage: '' }
                                                )}</div>}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className="pb15">
                                        <Col md={24} sm={24} xs={24}>
                                            <TextBox
                                                placeholder={intl.formatMessage(
                                                    { id: 'signup.confirmPassword.placeholder.text', defaultMessage: '' }
                                                )}
                                                value={repeatPassword}
                                                type='password'
                                                className={  'signup-form-input' || invalidRepeatPasswordClass}
                                                validator={() => {
                                                    return !!repeatPassword;
                                                }}
                                                onChange={(e) => {
                                                    updateState({
                                                        repeatPassword: e.target.value,
                                                        invalidRepeatPasswordClass: !e.target.value || (password !== e.target.value) ? 'input-text-error' : '',
                                                        passwordMismatch: password !== e.target.value,
                                                    });
                                                }}
                                            />
                                            {
                                                repeatErrorMessage
                                            }
                                        </Col>
                                    </Row>

                                    {/* 

                                    <Row className="pb15">

                                        <Col  md={24} sm={24} xs={24}>
                                            <InputGroup compact>
                                                <Dropdown
                                                    className = {'signup-form-input'}
                                                    items={offsetTmz}
                                                    value={props.timezone}
                                                    valueKeyName='value'
                                                    optionKeyName='name'
                                                    placeholder={intl.formatMessage({
                                                        id: 'signup.timezone.placeholder', defaultMessage: ''
                                                    })}
                                                    onSelect={(optionValue, elem) => {
                                                        updateState({
                                                            timezone: optionValue
                                                        });
                                                    }}
                                                />
                                            </InputGroup>
                                            <ErrorMsg
                                                validator={() => {
                                                    return !submittedOnce || !!props.timezone;
                                                }}
                                                message={intl.formatMessage(
                                                    { id: 'signup.validation.timezone', defaultMessage: '' }
                                                )}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className="pb15">

                                        <Col md={24} sm={24} xs={24}>
                                            <InputGroup compact>
                                                <RangePicker size={'default'}
                                                    className = {'signup-form-input'}
                                                    onChange={(value, dateString) => {
                                                        updateState({
                                                            fyStartDate: dateString[0],
                                                            fyEndDate: dateString[1]
                                                        });
                                                    }} 
                                                />
                                            </InputGroup>
                                            <ErrorMsg
                                                validator={() => {
                                                    return !submittedOnce || !!props.fyStartDate || !!props.fyEndDate;
                                                }}
                                                message={intl.formatMessage(
                                                    { id: 'signup.validation.financialYear', defaultMessage: '' }
                                                )}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className="pb15">

                                        <Col md={24} sm={24} xs={24}>
                                            <TextBox
                                                className={'signup-form-input'}
                                                placeholder={intl.formatMessage(
                                                    { id: 'signup.website.placeholder', defaultMessage: '' }
                                                )}
                                                maxLength={100}
                                                type='text'
                                                value={website}
                                                onChange={(e) => {
                                                    updateState({
                                                        website: e.target.value
                                                    })
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <CustomButton className='hide' htmlType='submit' />
                                    </Row> */}
                                </Form>
                                {/* <div style={{marginTop: '15px', fontSize: '12px'}}>
                                    <FormattedMessage id='signup.termAndPolicy.message' defaultMessage='' />
                                </div> */}

                                <Row className="signup-action-container">
                                    <Col md={24} sm={24} xs={24}>
                                        <Button
                                            className='signup-create-account'
                                            width='100%'
                                            loading={props.isLoading}
                                            disabled={props.isLoading}
                                            onClick={() => {
                                                handleSignIn(props);
                                            }}
                                        >
                                            <FormattedMessage id='signup.btn.create' defaultMessage='' />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24} sm={24} xs={24}>
                                        {/* <CustomButton
                                            type='default'
                                            className='signup-cancel'
                                            intlId='signup.btn.cancel'
                                            defaultMessage=''
                                            onClick={() => {
                                                history.push('/');
                                            }}
                                        /> */}
                                        <div style={{fontSize: '12px', textAlign:'center'}}>
                                            <FormattedMessage id='signup.privacyPolicy.preText' defaultMessage='' /> <div className='cursor-pointer' onClick={()=>{ window.open('https://alpide.com/privacy.html')}}> <FormattedMessage id='signup.termsOfService.text' defaultMessage='' /></div> & <div className='cursor-pointer' onClick={()=>{ window.open('https://alpide.com/privacy.html')}}><FormattedMessage id='signup.privacyPolicy.text' defaultMessage='' /></div>
                                        </div>
                                        <div className='signup.subheading.text' style={{textAlign: 'center'}}>Already have an account? 
                                            <span>
                                                <a className='link-url' href={'/app'}> Sign In</a>
                                            </span>
                                        </div>
                                        {/* <div style={{fontSize: '15px', marginTop: '5px', textAlign:'center'}}>Already have an account? 
                                            <span>
                                            <a className='link-url' href={'/'}> Sign In</a>
                                            </span>
                                        </div> */}

                                    </Col>
                                </Row>
                            </div>
                        </>
                        :
                        <div className='signup-notify'>
                           <h4 className="heading-title">
                                Account Activation
                            </h4>

                            <div className='msg' style={{padding: '0px 120px 25px 70px'}}> 
                                Verify your email to proceed. A verification link has been sent to email address {props.mailSentTo}.
                            </div>

                            <div className='msg' style={{padding: '0px 120px 25px 70px'}}> 
                               Check your spam and promotions folder if it doesn't appear in your main mailbox.
                            </div>

                            <div className='msg' style={{padding: '0px 120px 25px 70px'}}> 
                               Didn't recieve the verification email?<br />
                               Contact <a href="mailto:support@alpide.com" className='link'>support@alpide.com</a>
                            </div>
                            <div style={{padding: '0px 120px 0px 70px'}}>
                                <Button type='primary' style={{marginBottom: '8px', width: '200px' }} onClick={()=>props.history.push('/')}>Login</Button>
                                <div className='msg'>
                                    Don't have an account? <span className='cursor-pointer link' onClick={()=> props.history.push('/')}>Create Your Account</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Col>
            <Col  className='signup-panel2'>
      
            </Col>
        </Row>
    );
}

const handleSignIn = (payload) => {
    const { contact, email, company, password, repeatPassword, passwordMismatch, country, phoneNumber, validPassword } = payload;

    if (!payload.submittedOnce) {
        payload.updateState({ submittedOnce: true });
    }
    // if (country && country.countryName === 'India' && !((payload.address || {}).state)) {
    //     return showToasterMessage({
    //         description: 'State is required for GST. Please select State from Address tab page and try again.',
    //         messageType: 'error'
    //     });
    // }

    // if (contact && !contact.firstName) {
    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'contact.firstName.message', defaultMessage: 'Name is required.' }),
    //         messageType: 'error'
    //     });
    // }

    // if (!validateEmail(email) || !email) {
    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'signup.validation.workEmail', defaultMessage: 'Name is required.' }),
    //         messageType: 'error'
    //     });
    // }
    // if (!phoneNumber) {

    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'signup.validation.mobile', defaultMessage: 'Name is required.' }),
    //         messageType: 'error'
    //     });
    // }

    // if (phoneNumber && phoneNumber.length != 10) {
    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'signup.validation.validMobile', defaultMessage: 'Name is required.' }),
    //         messageType: 'error'
    //     });
    // }
    // if (!company) {
    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'signup.validation.orgName', defaultMessage: 'Name is required.' }),
    //         messageType: 'error'
    //     });
    // }
    // if (!password) {
    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'signup.validation.password', defaultMessage: 'Name is required.' }),
    //         messageType: 'error'
    //     });
    // }

    // if (!repeatPassword) {
    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'signup.validation.confirmPassword', defaultMessage: 'Name is required.' }),
    //         messageType: 'error'
    //     });
    // }

    // if (passwordMismatch) {
    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'signup.validation.passwordMismatch', defaultMessage: 'Name is required.' }),
    //         messageType: 'error'
    //     });
    // }

    // if ( !timezone) {
    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'signup.validation.timezone' }),
    //         messageType: 'error'
    //     });
    // }
    // if(!payload.fyStartDate || !payload.fyEndDate){
    //     return showToasterMessage({
    //         description: intl.formatMessage({ id: 'signup.validation.financialYear' }),
    //         messageType: 'error'
    //     });
    // }

    if (contact.firstName && validateEmail(email) && company && country && phoneNumber && password && repeatPassword && password === repeatPassword && validPassword) {
            payload.signIn(payload);
            // payload.hideModal();
            // const modalData = {
        //     title: <FormattedMessage id='signup.confirmation.title' defaultMessage='' />,
        //     modalBody: getContryConfirmationModalBody(payload),
        //     handleSubmit: () => {
        //         payload.signIn(payload);
        //         payload.hideModal();
        //     }
        // };
        // payload.showModal(modalData);
    } else {
        payload.updateState({
            email, password, repeatPassword, company,
            invalidFirstNameClass: !password ? 'input-text-error' : '',
            invalidEmailClass: validateEmail(email) ? '' : 'input-text-error',
            invalidCompanyClass: !company ? 'input-text-error' : '',
            invalidCountryClass: !country ? 'input-text-error' : '',
            invalidPhoneNumberClass: !phoneNumber ? 'input-text-error' : '',
            invalidPasswordClass: !password || !validPassword ? 'input-text-error' : '',
            invalidRepeatPasswordClass: (!repeatPassword || !passwordMismatch) ? 'input-text-error' : '',
            passwordMismatch: password !== repeatPassword,
        })
    }
}

// const getContryConfirmationModalBody = (payload) => {
//     return (
//         <Fragment>
//             <p>
//                 <FormattedMessage
//                     id='signup.confirmation.text'
//                     defaultMessage=''
//                 />
//             </p>

//         </Fragment>
//     )
// }

export default injectIntl(SignupComponent);
