import { HIERARCHY_ACTION_LIST } from '../static/constants';
const initialState = {
  hierarchyList:[],
  hierarchyListByLevel: [],
  parentHierarchyListByLevel: [],
  childHierarchyListByLevel: []
}

const HierarchyReducer = (state = initialState, action) => {
  switch (action.type) {
    case HIERARCHY_ACTION_LIST.HIERARCHY_LIST:
      return { ...state, hierarchyList: action.data };
    case HIERARCHY_ACTION_LIST.HIERARCHY_LIST_DELETION:
      return { ...state, hierarchyList: state.hierarchyList.filter(x => x.hierarchyId !== action.data.id) };
    case HIERARCHY_ACTION_LIST.HIERARCHY_LIST_BY_LEVEL:
      return { ...state, hierarchyListByLevel: action.data };
    case HIERARCHY_ACTION_LIST.RESET_HIERARCHY_LIST_BY_LEVEL:
      return { ...state, hierarchyListByLevel: initialState.hierarchyListByLevel };

    case HIERARCHY_ACTION_LIST.PARENT_HIERARCHY_LIST_BY_LEVEL:
      return { ...state, parentHierarchyListByLevel: action.data };
    case HIERARCHY_ACTION_LIST.RESET_PARENT_HIERARCHY_LIST_BY_LEVEL:
      return { ...state, parentHierarchyListByLevel: initialState.parentHierarchyListByLevel };

      case HIERARCHY_ACTION_LIST.CHILD_HIERARCHY_LIST_BY_LEVEL:
        return { ...state, childHierarchyListByLevel: action.data };
      case HIERARCHY_ACTION_LIST.RESET_CHILD_HIERARCHY_LIST_BY_LEVEL:
        return { ...state, childHierarchyListByLevel: initialState.childHierarchyListByLevel };
    default:
      return state;
  }
};

export default HierarchyReducer;