import React from 'react';
import { injectIntl , FormattedMessage  } from 'react-intl';


const ProductDetailComponent = (props) => {
    const { product } = props;

    const getCurrentStock = (data, keyName, type) => {
        let sum = data[keyName] || 0;
        let variantCount = 0;
        data.inventoryItemVariantList.forEach(function (ele) {
            if (ele[keyName]) {
                variantCount = variantCount + 1;
                sum = sum + ele[keyName];
            }
        });


        if (type === 'sum')
            return sum;
        if (type === 'detail') {
            var variantVal = variantCount > 1 ? 'variants' : 'variant'
            return "From " + variantCount + " " + variantVal
        }
    };

    const getQtyAvailable = (product ) => {
        let qty = getCurrentStock(product, 'currentStock', 'sum') - getCurrentStock(product, 'salesCommitted', 'sum');
        return qty > 0 ? qty : 'Negative Stock';
    };

    const getVariantQtyAvailable = (variantData) => {
        let qty = variantData.currentStock - variantData.salesCommitted;
        return qty > 0 ? qty + " " + (variantData.uomName || '') : (qty === 0 ? 'Out of Stock' : 'Negative Stock');
    };
    return (
        <div className='agGridWrapper'>
            <div className="modal-table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>SKU</th>
                            <th>Category</th>
                            <th>Curernt Stock</th>
                            <th>Qty on SO</th>
                            <th>Qty Available</th>
                            <th>Qty on PO</th>
                            <th>Warehouse</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(product && product.inventoryItemVariantList) ? <tr>
                            <td>{product.itemName || '-'}</td>
                            <td>{product.sku || '-'}</td>
                            <td>{product.inventoryItemCategoryRefs?.length || '-'}</td>
                            <td>{getCurrentStock(product, 'currentStock', 'sum')}</td>
                            <td>{getCurrentStock(product, 'salesCommitted', 'sum')}</td>
                            <td>{getQtyAvailable(product)}</td>
                            <td>{getCurrentStock(product, 'onOrder', 'sum')}</td>
                            <td>{product.warehouseName || '-'}</td>
                        </tr> : ''}
                    </tbody>
                </table> 
            </div>

            {
                product.hasVariant ? 
                <div className="modal-table-container">
                   <div><h3>Variant Details</h3></div>
                    <table>
                        <thead>
                            <tr>
                                <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                                <th>SKU</th>
                                <th>Variant</th>
                                <th>Current Stock</th>
                                <th>Qty on SO</th>
                                <th>Qty Available</th>
                                <th>Qty on PO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(product.inventoryItemVariantList || []).map((variantData, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{variantData.sku || '-'}</td>
                                        <td>
                                            {variantData.attributeName1 && variantData.attributeName1 + ": " + variantData.attributeValue1} <br></br>
                                            {variantData.attributeName2 && variantData.attributeName2 + ": " + variantData.attributeValue2} <br></br>
                                            {variantData.attributeName3 && variantData.attributeName3 + ": " + variantData.attributeValue3}
                                        </td>
                                        <td>{variantData.currentStock + " " + (variantData.currentStock !== 0 && variantData.uomName ? variantData.uomName : '')}</td>
                                        <td>{variantData.salesCommitted + " " + (variantData.salesCommitted !== 0 && variantData.uomName  ? variantData.uomName : '')}</td>
                                        <td>{getVariantQtyAvailable(variantData)}</td>
                                        <td>{variantData.onOrder + " " + (variantData.onOrder !== 0 && variantData.uomName  ? variantData.uomName : '')}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div> 
                :
                <div className="modal-table-container" style={{'text-align': 'center','margin-top': '25px'}}> <h3> Product does not have variant </h3> </div>
            }

        </div>
    );
};

export default injectIntl(ProductDetailComponent);
