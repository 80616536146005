import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RSVPComp from '../../../components/B2B/RSVP';
import { fetchRelationshipDataByRID, saveOrUpdate } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../static/constants';
import { fetchNewsLetter } from '../NewLetterPreview/action';

class RSVP extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(this.props.location.search) || {};
    let ids = (linkProps.t || '').split("_");
  //  t='rid_paymentGatewayId_sourceId'
  // t='17_22_1'
    this.state = {
      ...props,
      relationshipBillingAddress: {},
      neverExpires: 1,
      relationshipId: Number(ids[0]),
      paymentGatewayId: Number(ids[1]),
      sourceId: Number(ids[2]),
      alpideFormBuilderId: Number(ids[3]),
      phoneNumber:'',
      onlineThemes: {}
    }
  }

  componentDidMount() {
    const linkProps = queryString.parse(this.props.location.search) || {};
    let ids = (linkProps.t || '').split("_");
    const payload = {
      relationshipId: Number(ids[0]),
      templateId: Number(ids[3]),
    };
    this.props.fetchRelationshipDataByRID(payload, this.props);
     payload.templateId && this.props.fetchNewsLetter(payload, this.props);
  }

  componentWillReceiveProps(props) {
    if (props.companyInfo && props.companyInfo.relationshipId) {
      const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
      const onlineThemes = JSON.parse( props.companyInfo.onlineThemes || '{}');
      this.setState({
        onlineThemes,
        relationshipBillingAddress,
        companyInfo: props.companyInfo
      })
    }
    if (props.newsLetterTemplate || !this.state.newsLetterTemplate) {
      this.setState({
        newsLetterTemplate: props.newsLetterTemplate,
      })
    }
  }

  render() {
    return (
      <Fragment>
        <RSVPComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.settings.relationshipData,
    newsLetterTemplate: state.membershipReducer.newsLetterTemplate,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  saveOrUpdate,
  fetchRelationshipDataByRID,
  fetchNewsLetter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RSVP);
