import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreatePaySlip from '../../../../../components/modal/modalBody/company/CreatePaySlip';
import EmployeeProfile from '../../../../../components/modal/modalBody/company/EmployeeProfile';
import { fetchEmployeeDetailsByEmployeeId } from './action';


class EmployeeProfileCon extends Component {

    constructor(props){
        super(props);
        this.state= {
         
        }
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            employeeId: this.props.relationshipEmployeeId,
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25,
          
          };
      this.props.fetchEmployeeDetailsByEmployeeId(payload);
      
    }

    render() {
        return <div>

            <EmployeeProfile {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        employee: state.employee.employeeDetail,
        detailLoading: state.common.detailLoading
      
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({

    fetchEmployeeDetailsByEmployeeId
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeProfileCon);