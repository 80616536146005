import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StreamingComponent from '../../../components/B2B/Streaming';
import { fetchRelationshipDataByRID, saveOrUpdate } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchPaymentModes } from '../../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../static/constants';
import { getAllFundraisingCampaign, resetAllFundraisingCampaign } from '../../drawer/fundraising/pledges/action';

import firebaseDataService from "../../../actions/firbaseAction";

class Streaming extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(this.props.location.search) || {};
    let ids = (linkProps.t || '').split("_");
    // let firebaseKey = ids[0] + '_' + ids[1];
    this.state = {
      ...props,
      relationshipBillingAddress: {},
      neverExpires: 1,
      relationshipId: Number(ids[0]),
      campaignId: Number(ids[1]),
      selectedCampaign: {},
      timer: {
        month: 0, days: 0, hours: 0, minutes: 0, seconds: 0,
      },
      isShowTimer: true
    }
    // let data = {
    //   goal: 50000,
    //   totalDoner: 2,
    //   name: "Test Camp"
    // };
    // firebaseDataService.create(firebaseKey, data)
    //   .then(() => {
    //   })
    //   .catch((e) => {
    //   });
  }

  componentDidMount() {
    const linkProps = queryString.parse(this.props.location.search) || {};
    const self = this;
    let ids = (linkProps.t || '').split("_");
    let firebaseKey = ids[0] + '_' + ids[1];
    //let firebaseKey = '22_27';
    const payload = {
      relationshipId: Number(ids[0]),
      pageNumber: 1,
      pageSize: 1000,
    };

    this.props.fetchRelationshipDataByRID(payload);
    this.props.resetAllFundraisingCampaign();
    this.props.getAllFundraisingCampaign(payload);
    // firebaseDataService.getByDocId(firebaseKey).get().then(snapshot => { self.onFirebaseDataChange(snapshot.data(), self) });
    firebaseDataService.getByDocId(firebaseKey).onSnapshot((snapshot) => {
      self.onFirebaseDataChange(snapshot.data(), self)
    })
  }

  onFirebaseDataChange(selectedCampaign, thisRef) {
    if(!selectedCampaign){return ''}
    let countDownDate = new Date(selectedCampaign.endDate).getTime();
    // Fixed Value to add each time the counter get to 0
    const distanceToAdd = countDownDate - new Date().getTime();
    let month;
    let days;
    let hours;
    let minutes;
    let seconds;
    if (!thisRef.state.isCounterStart) {
      setInterval(function () { thisRef.setState({ isShowTimer: !thisRef.state.isShowTimer }) }, 5000);
    }
    setInterval(function () {

      let now = new Date().getTime();

      let distance = countDownDate - now;
      if (!isNaN(distance)) {
        days = Math.floor(distance / (1000 * 60 * 60 * 24));
        month = Math.floor(days/30);
        days = Math.floor(days%30);
        hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
          //Add Time to your timer goal instead of canceling interval
          countDownDate += distanceToAdd;
        }
        else {
          thisRef.setState({ timer: { month, days, hours, minutes, seconds } });
        }
      }
    }, 1000);

    thisRef.setState({
      selectedCampaign: selectedCampaign,
      fundraisingCampaignId: selectedCampaign.fundraisingCampaignId,
      isCounterStart: true
    });
  }

  componentWillReceiveProps(props) {
    if (props.companyInfo && props.companyInfo.relationshipId) {
      const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
      this.setState({
        relationshipBillingAddress,
        companyInfo: props.companyInfo
      })
    }
    // if (props.allCampaignList && props.allCampaignList.length && this.state.campaignId && !this.state.fundraisingCampaignId ) {
    //   let selectedCampaign = find(props.allCampaignList || [], { fundraisingCampaignId: this.state.campaignId }) || {};
    //   let countDownDate = new Date(selectedCampaign.endDate).getTime();
    //   // Fixed Value to add each time the counter get to 0
    //   const distanceToAdd = countDownDate - new Date().getTime();
    //   let month;
    //   let days;
    //   let hours;
    //   let minutes;
    //   let seconds;
    //   const self = this;

    //   setInterval(function () { self.setState({ isShowTimer: !self.state.isShowTimer }) }, 5000);

    //   setInterval(function () {

    //     let now = new Date().getTime();

    //     let distance = countDownDate - now;
    //     if (!isNaN(distance)) {
    //       days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //       month = Math.floor(days/30);
    //       days = Math.floor(days%30);
    //       hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //       minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //       seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //       if (distance < 0) {
    //         //Add Time to your timer goal instead of canceling interval
    //         countDownDate += distanceToAdd;
    //       }
    //       else {
    //         self.setState({ timer: { month, days, hours, minutes, seconds } });
    //       }
    //     }
    //   }, 1000);

    //   this.setState({
    //     selectedCampaign: selectedCampaign,
    //     fundraisingCampaignId: selectedCampaign.fundraisingCampaignId,
    //   });
    // }
  }

  render() {
    return (
      <StreamingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.settings.relationshipData,
    allCampaignList: state.fundraisingReducer.allCampaignList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  saveOrUpdate,
  fetchRelationshipDataByRID,
  getAllFundraisingCampaign,
  fetchPaymentModes,
  resetAllFundraisingCampaign
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Streaming);
