import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomerPaymentDetailComponent from '../../../../../components/modal/modalBody/customer/paymentDetail';
import { fetchPaymentDetail } from './action'
import { fetchDataIfNeeded } from '../../../../../utils'
import { hideModal, showModal, pushModalToStack } from './../../../../../actions/commonActions';
import { generatePDF } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import {deletePayment, fetchCustomerPayments} from '../../../../customer/payment/Listing/action';
import { CONSTANTS} from '../../../../../static/constants';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';

class CustomerPaymentDetail extends Component {
    constructor(props){
        super(props);
        const companyInfo = props.companyInfo;
        const allRelationshipBillingAddress = filter(companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
        const relationshipAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            relationshipAddress
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.paymentPayload.customerId, customerPaymentId: this.props.paymentPayload.customerPaymentId };
        fetchDataIfNeeded('fetchPaymentDetail', 'paymentDetail', this.props, payload, true);

    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <CustomerPaymentDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        paymentDetail: state.customerPayment.customerPaymentDetail,
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        detailLoading: state.common.detailLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPaymentDetail,
    hideModal,
    generatePDF,
    showModal,
    pushModalToStack,
    deletePayment, 
    fetchCustomerPayments
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentDetail);