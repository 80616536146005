import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ExpenseDetailComponent from '../../../../../components/modal/modalBody/customer/ExpenseDetail';
import { fetchExpenseDetail } from './action'
import { fetchDataIfNeeded } from '../../../../../utils'

class ExpenseDetail extends Component {
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.expensePayload.customerId, expenseMasterId: this.props.expensePayload.expenseMasterId };
        fetchDataIfNeeded('fetchExpenseDetail', 'expenseDetail', this.props, payload, true);

    }

    render() {
        return <ExpenseDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        expenseDetail: state.customerExpense.customerExpenseDetail
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchExpenseDetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetail);