import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Col, Row, Input, DatePicker, Switch } from 'antd';
import { MODAL_TYPE, CONSTANTS, SALES_COMPLAINT_CONSTANTS } from '../../../static/constants';
import { CustomButton } from '../../general/CustomButton';
import { Dropdown } from '../../general/Dropdown';
import * as find from 'lodash.find';
import { formatDateForBackend, showToasterMessage, getMomentDateForUI } from '../../../utils';
import LoadingIcon from '../../../assets/images/loading.gif';
import { CUSTOM_CONFIGURATION } from '../../../static/ckEditorConfigConstants';
import CKEditor from '../../general/CustomCKEditor/ckeditor';
import { CustomTableUpload } from '../../general/UploadTable';
import { MultipleSelectDropdown } from '../../general/MultipleSelectDropdown';
import filter from 'lodash.filter';
import { ErrorMsg } from '../../general/ErrorMessage';
// import TextArea from 'antd/lib/input/TextArea';
import { TextArea } from '../../general/TextAreaComponent';
import { TextBox } from '../../general/TextBox';


const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class SalesComplaintDrawer extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const closeDrawer = () => {
      this.props.updateHeaderState({
        salesComplaint: {
          salesComplaintDrawerVisible: false
        }
      })
    }

    const getFormatedNextTxNumber = (props) => {
      if (!this.props.selectedSO || !this.props.selectedSO.customerId) {
        return <span>{this.props.nextTxNumber}</span>;
      }
      return <span>{props.nextTxNumber.replace('CMP-00', 'CMP-' + (this.props.selectedSO.customerId < 10 ? ('0' + this.props.selectedSO.customerId) : this.props.selectedSO.customerId))}</span>
    }


    const handleAddQualityCheckToProps = () => {
      this.props.updateDrawerState({ submitted: true });

      // if ((!this.props.selectedSO || {}).soNumber || !this.props.complaintDetails) {
      //   return showToasterMessage({ messageType: 'error', description: lang['salesComplaint.create.validation.message'] });
      // }
      if((!this.props.selectedSO || {}).soNumber || !this.props.complaintDetails || !this.props.statusName){
        return
      }
      // if(this.props.isUpdate && this.props.soMasterId && !this.props.salesOrderNumber){ 
      //   return
      // }
      const modalData = {
        modalBody: this.props.isUpdate ?  "Are you sure you want to Save?" : "Are you sure you want to Submit?",
        handleSubmit: () => {

          closeDrawer();
          let obj = {
            relationshipId: (this.props.companyInfo || {}).relationshipId,
            salesComplaintId: this.props.salesComplaintId,
            complaintDetails: this.props.complaintDetails,
            description: this.props.description,
            salesComplaintType: this.props.salesComplaintType,
            refNumber: this.props.refNumber,
            priority: this.props.priority,
            statusName: this.props.statusName,
            soMasterId: (this.props.selectedSO || {}).salesOrderMasterId || this.props.soMasterId,
            salesOrderNumber: (this.props.selectedSO || {}).soNumber || (this.props.complaintData || []).salesOrderNumber ,
            customerName: (this.props.selectedSO || {}).customerName || this.props.customerName,
            customerId: (this.props.selectedSO || {}).customerId || this.props.customerId,
            dueDate: this.props.dueDate ? formatDateForBackend(this.props.dueDate) : null,
            assignedUserId: (this.props.selectedEmployee || {}).relationshipEmployeeId || this.props.assignedUserId,
            assignedUserName: (this.props.selectedEmployee || {}).fullName || this.props.assignedUserName,
            fileList: this.props.salesComplaintfileList,
            customerSalesComplainItemRef: getItemListForBackend(this.props.selectItem),
            version: this.props.version,
            awsDocumentDetails: this.props.salesComplaintfileList || [],
            invoiceMasterId: (this.props.selectedInvoice || {}).invoiceMasterId || this.props.invoiceMasterId,
            invoiceNumber: (this.props.selectedInvoice || {}).invoiceNumber || this.props.invoiceNumber,
            salesComplaintNumber: this.props.isUpdate ? this.props.salesComplaintNumber : this.props.nextTxNumber
          }
          this.props.createOrUpdateComplaint(obj, this.props);
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    }


    const addItemConfirmationModal = (props, text, modalType) => {
      const { showModal, companyInfo } = props;
      const labelName = 'Complaint';
      const modalBody = <span>
        <FormattedMessage
          id="commons.create.popup.confimation.msg"        
        />
      
      </span>;

      let addFunc;

      switch (modalType) {
        case MODAL_TYPE.SALES_COMPLAINT_TYPE: {
          addFunc = props.addSalesComplaintType;
          break;
        }
        default: {
          addFunc = () => { }
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          addFunc({ text: text, relationshipId: companyInfo.relationshipId })
          props.hideModal();
          props.popModalFromStack();

        },
      };
      showModal(modalData);
    }

    const getModalTitle = (newTextObj, modalType) => {
      let title;

      switch (modalType) {
        case MODAL_TYPE.SALES_COMPLAINT_TYPE: {
          title = <FormattedMessage id='addItem.text.salesComplaintType' defaultMessage='' />;
          break;
        }
        default:
          title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
      }
      return title;
    }

    const handleNewItemAddition = (props, payload, modalType) => {
      const formData = payload.formData;
      const { showModal } = props;

      if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
        addItemConfirmationModal(props, formData.textBoxValue, modalType);
      } else {
        const data = {
          title: getModalTitle(payload, modalType),
          formData,
          modalData: { modalType, data: payload },
          handleSubmit: (formData = {}) => {
            if (formData.textBoxValue && formData.textBoxValue.length) {
              addItemConfirmationModal(props, formData.textBoxValue, modalType);
            } else {
              data.formData.submittedOnce = true;
              // showModal(data);
            }
          }
        };
        props.pushModalToStack(data);
      }
    }

    const deleteClickHandler = (props, modalType, payload) => {
      const { showModal, companyInfo } = props;
      const modalBody =
        <FormattedMessage
          id='deleteItem.confirmation'
          defaultMessage={``}
          values={{ value: payload.text }}
        />;

      let deleteFunc;

      switch (modalType) {
        case MODAL_TYPE.SALES_COMPLAINT_TYPE: {
          deleteFunc = props.deleteSalesComplaintType;
          break;
        }
        default: {
          deleteFunc = () => { }
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId,props })
          props.hideModal();
        },
      };
      showModal(modalData);
    }


    const getItemListForBackend = (selectItem) => {
      let items = []
      if (selectItem && selectItem.length) {
        this.props.selectItem.forEach((num) => {
          items.push({ relationshipId: this.props.companyInfo.relationshipId, itemId: num })
        })
      }
      return items;
    }



    return (
      <Fragment>

        <Drawer
          title={
            <div className='drawer-head-txt'>{!this.props.isUpdate ? this.props.title || 'Create Sales Complaints' : "Edit Sales Complaints"}</div>
          }
          width={720}
          zIndex={1}
          maskClosable={false}
          keyboard={false}
          destroyOnClose={true}
          onClose={closeDrawer}
          className="custom-drawer"
          visible={this.props.salesComplaintDrawerVisible}
          footer={<>
            <CustomButton
              intlId='confirmation.cancel'
              defaultMessage=''
              type='default'
              key={0}
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />
            <CustomButton
              onClick={() => {
                handleAddQualityCheckToProps();
              }}
              type='primary'
              htmlType='submit'
              intlId='button.save.label'
              defaultMessage=''
            />
          </>}
        >
          <Row gutter={16}>
            <Col span={12} >
              Complaint #
            </Col>
            {this.props.customerName ?
              <Col span={12} >
                Customer Name
              </Col>
              : ''
            }

          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>

              {
                this.props.isUpdate ?
                  <span style={{ color: 'red' }}>
                    {this.props.salesComplaintNumber}
                  </span>
                  :
                  this.props.nextTxNumber ?
                    <span style={{ color: 'red' }}>
                      {getFormatedNextTxNumber(this.props)}

                    </span>
                    :
                    <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'marginTop': '-25px', 'marginBottom': '-25px' }} />
              }
            </Col>
            <Col span={6} className='complaint-toggle'>

              <Switch
                checked={this.props.orderComplaint}
                disabled = {this.props.isUpdate}
                onChange={(e) => {
                  this.props.updateDrawerState({
                    orderComplaint: e ? 1 : 0,
                    customerName:"",
                    refNumber:"",
                    soNumber:"",
                    invoiceNumber:"",
                    selectItem:[],
                    salesComplaintType:"",
                    priority:"",
                    statusName:"",
                    dueDate:"",
                    selectedEmployee:"",
                    complaintDetails:"",
                    submitted:false

                  });
                }}
              />

            </Col>
            {this.props.customerName ?
              <Col span={12}>
                {this.props.customerName || "vivek"}
              </Col>
              : ''
            }
          </Row>
          <Row gutter={[16, 4]}>
            {this.props.orderComplaint === 1 ?
              (<Col span={12}>
                <span className="text-input-label">
                  Sales Order #
                  <span className="required">*</span>
                </span>
              </Col>)
              :
              (<Col span={12}>
                <span className="text-input-label">
                  Sales Invoice #
                  <span className="required">*</span>
                </span>
              </Col>)
            }
            <Col span={12} >
              Select Item
            </Col>

          </Row>
          <Row gutter={[16, 16]}>
            {this.props.orderComplaint === 1 ?
              <Col span={12}>
                <Dropdown
                  style={{ width: '100%', marginLeft: 0 }}
                disabled = {this.props.isUpdate}
                  items={(this.props.soList || {})[1] || []}
                  value={this.props.soNumber || this.props.salesOrderNumber}
                  placeholder='Select Sales Order'
                  valueKeyName='soNumber'
                  optionKeyName='salesOrderMasterId'
                  addressLineKey1='customerName'
                  amountKey='salesOrderTotalAmount'
                  currencyCodeKey='currencyCode'
                  onSelect={(selectedValue, option) => {
                    let selectedSO = find(this.props.soList[1] || [], { salesOrderMasterId: Number(option.key) });
                    this.props.updateDrawerState({ soNumber: option.name, selectedSO: selectedSO, customerName: selectedSO.customerName, refNumber: selectedSO.referenceNumber });
                    this.props.fetchSODetail({ salesOrderMasterId: selectedSO.salesOrderMasterId, relationshipId: this.props.companyInfo.relationshipId, customerId: selectedSO.customerId })
                  }}
                />
                <ErrorMsg
                  validator={() => { return !this.props.submitted || this.props.soNumber || this.props.salesOrderNumber}}
                  message={<FormattedMessage id='requiredField.missing.msg' />}
                />
              </Col>
              :
              <Col span={12}>
                <Dropdown
                  style={{ width: '100%', marginLeft: 0 }}
                disabled = {this.props.isUpdate}
                  items={(this.props.salesInvoiceList || {})[1] || []}
                  value={this.props.invoiceNumber}
                  placeholder='Select Sales Invoice'
                  valueKeyName='invoiceNumber'
                  optionKeyName='invoiceMasterId'
                  addressLineKey1='customerName'
                  amountKey='invoiceTotalAmount'
                  currencyCodeKey='currencyCode'
                  onSelect={(selectedValue, option) => {
                    let selectedInvoice = find(this.props.salesInvoiceList[1] || [], { invoiceMasterId: Number(option.key) });
                    this.props.updateDrawerState({ invoiceNumber: option.name, selectedInvoice: selectedInvoice, customerName: selectedInvoice.customerName, refNumber: selectedInvoice.referenceNumber });
                    this.props.fetchSalesInvoiceDetail({ invoiceMasterId: selectedInvoice.invoiceMasterId, relationshipId: this.props.companyInfo.relationshipId, customerId: selectedInvoice.customerId })
                  }}
                />
                <ErrorMsg
                  validator={() => { return !this.props.submitted || this.props.invoiceNumber}}
                  message={<FormattedMessage id='requiredField.missing.msg' />}
                />
              </Col>
            }

            {this.props.orderComplaint === 1 ?
              <Col span={12}>

                <MultipleSelectDropdown
                  style={{ width: '100%' }}
                  items={((this.props.soDetail || {}).customerSalesOrderDetailsList) || []}
                  mode='multiple'
                  placeholder="Select Item"
                  onDeSelect={(value) => {
                    let { selectItem } = this.props
                    selectItem = filter(selectItem, (itemId) => {
                      return itemId !== value;
                    });
                    this.props.updateDrawerState({ selectItem });
                  }}
                  valueKeyName='itemName'
                  selectedValue={ (this.props.selectItem || [])  }
                  optionKeyName='itemId'
                  onChange={(val) => {
                    this.props.updateDrawerState({
                      selectItem: val
                    });
                  }}

                />

              </Col>
              :
              <Col span={12}>

                <MultipleSelectDropdown
                  style={{ width: '100%' }}
                  items={((this.props.salesInvoiceDetail || {}).customerInvoiceDetailsList) || []}
                  mode='multiple'
                  placeholder="Select Item"
                  valueKeyName='itemName'
                  selectedValue={this.props.selectItem || []}
                  // selectedValue={itemName}
                  optionKeyName='itemId'
                  onChange={(val) => {
                    this.props.updateDrawerState({
                      selectItem: val 
                    });
                  }}
                />


              </Col>
            }
          </Row>

          {/* <Row className='input-control' style={{'marginBottom': '25px'}}>
            <Col span={8} >
            Description
            </Col>

            <Col span={16}>
              <Input placeholder='Description'
                type='text'
                value={this.props.description}
                onChange={(e) => {
                  this.props.updateDrawerState({
                    description: e.target.value
                  });
                }} />
            </Col>
          </Row> */}


          <Row gutter={[16, 4]}>
            <Col span={12} >
              Sales Complaint Type
            </Col>
            <Col span={12} >
              Ref #
            </Col>

          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                style={{ width: '100%', marginLeft: 0 }}
                items={this.props.complaintTypeList || []}
                value={ this.props.salesComplaintType }
                placeholder='Select Complaint Type'
                valueKeyName='comlaintType'
                optionKeyName='complaintTypeId'
                allowClear={true}
                canAddNew={true}
                canDelete={true}
                deleteClickHandler={(payload) => {
                  deleteClickHandler(this.props, MODAL_TYPE.SALES_COMPLAINT_TYPE, payload);
                }}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    textBoxValue: payload.textEntered,
                    submittedOnce: false
                  }
                  handleNewItemAddition(this.props, payload, MODAL_TYPE.SALES_COMPLAINT_TYPE)
                }}
                onSelect={(selectedValue, option) => {
                  this.props.updateDrawerState({ salesComplaintType: option.name })
                }}
                onClear={() => {
                  this.props.updateDrawerState({ salesComplaintType: null })
                }}
              />
            </Col>
            <Col span={12}>
              <TextBox placeholder='Ref #'
                type='text'
                countStyle={{top:"20px"}}
                maxLength={20}
                // style={{ height: '38px' }}
                value={ this.props.refNumber }
                onChange={(e) => {
                  this.props.updateDrawerState({
                    refNumber: e.target.value
                  });
                }} />
            </Col>

          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12} >
              Priority
            </Col>
            <Col span={12} >
              Status
              <span className="required">*</span>
            </Col>

          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                style={{ width: '100%', marginLeft: 0 }}
                items={CONSTANTS.PRIORITIES}
                value={ this.props.priority }
                allowClear={true}
                placeholder={this.props.intl.formatMessage({
                  id: "common.select.placeholder",
                  defaultMessage: "",
                })}
                onSelect={(selectedValue, option) => {
                  this.props.updateDrawerState({ priority: selectedValue })
                }}
                onClear={() => {
                  this.props.updateDrawerState({ priority: null })
                }}
              />
            </Col>
            <Col span={12}>
              <Dropdown
                style={{ width: '100%', marginLeft: 0 }}
                items={SALES_COMPLAINT_CONSTANTS.STATUS_TYPES}
                allowClear={true} 
                value={ this.props.statusName}
                placeholder={this.props.intl.formatMessage({
                  id: "common.select.placeholder",
                  defaultMessage: "",
                })}
                onClear={() => {
                  this.props.updateDrawerState({ statusName: null });
                }}
                onSelect={(selectedValue, option) => {
                  this.props.updateDrawerState({ statusName: selectedValue });
                }}
              />
                <ErrorMsg
                  validator={() => { return !this.props.submitted || this.props.statusName }}
                  message={<FormattedMessage id='requiredField.missing.msg' />}
                />
            </Col>

          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12} >
              Due Date
            </Col>
            <Col span={12} >
              Assign To
            </Col>

          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DatePicker
                style={{ width: '100%' }}
                getPopupContainer={trigger => trigger.parentNode}
                format={format}
                key={`${ (this.props.dueDate  ? getMomentDateForUI({ date: new Date(this.props.dueDate), format }) : null) }`}
                defaultValue={ (this.props.dueDate  ? getMomentDateForUI({ date: new Date(this.props.dueDate), format }) : null) }
                onChange={(selectedDate) => {
                  this.props.updateDrawerState({ dueDate: selectedDate })
                }}
                placeholder='Due Date'
              />
            </Col>
            <Col span={12}>
              <Dropdown
                style={{ width: '100%', marginLeft: 0 }}
                items={this.props.allEmployee || []}
                placeholder='Assign To'
                optionKeyName='relationshipEmployeeId'
                value={ ((this.props.selectedEmployee || {}).fullName || this.props.assignedUserName || "")}
                valueKeyName='fullName'
                addressLineKey1='jobTitleName'
                hierarchyLevelKey='hierarchyLevel'
                allowClear={true}
                onClear={() => {
                  this.props.updateDrawerState({ selectedEmployee: null });
                }}
                managerKey='managerName'
                onSelect={(selectedValue, optionObj) => {
                  const selectedEmployee = find(this.props.allEmployee, { relationshipEmployeeId: Number(optionObj.key) });
                  this.props.updateDrawerState({ selectedEmployee: selectedEmployee })
                }}
              />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12} >
              <span>Complaint Details</span>
              <i className="required">*</i>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {/* <CKEditor
                style={{ width: "100%"}}
                type="inline"           
                className="description-textbox"
                key={`${"compliantDetails"}`}
                data={this.props.complaintDetails || ''}
                onInit={editor => {
                  this.editor = editor;
                }}
                onChange={(event) => {
                  const data = event.editor.getData();
                  this.props.updateDrawerState({
                    complaintDetails: data
                  });
                }}
                config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Mention your complaint details here...' }}
              /> */}
              <TextArea
              style={{height:"100px",resize:"none"}}
              countStyle={{top:"80px"}}
              maxLength={500}
                value={ this.props.complaintDetails}
                 className="description-textbox"
                placeholder='Mention your complaint details here...'
                onChange={(event) => {
                  this.props.updateDrawerState({
                    complaintDetails: event.target.value
                  });
                }}
              />

              <ErrorMsg
                validator={() => { return !this.props.submitted || this.props.complaintDetails }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
              />
            </Col>
          </Row>
          <CustomTableUpload fileList={this.props.salesComplaintfileList || []} onChange={
            (fileList) => {
              this.props.updateDrawerState({
                salesComplaintfileList: fileList
              })
            }
          } />

          {/* <div className='form-footer'>
            <CustomButton
              intlId='confirmation.cancel'
              defaultMessage=''
              type='default'
              key={0}
              style={{ marginRight: 8 }}
              onClick={closeDrawer}
            />
            <CustomButton
              onClick={() => {
                handleAddQualityCheckToProps();
              }}
              type='primary'
              htmlType='submit'
              intlId='forgot.btn.save'
              defaultMessage=''
            />
          </div> */}
        </Drawer>
      </Fragment>
    );
  }
}

export default injectIntl(SalesComplaintDrawer);
