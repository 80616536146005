import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import { injectIntl } from 'react-intl';

import './signup.scss';


function SignupComponent(props) {
    //const { } = props;

    return (
        <Row className='signup-wrapper' type='flex' justify='center' align='middle'>
            <Col className="signup-panel">
                <Col>
                    <h4 className="heading-title">
                        Pricing                    </h4>
                </Col>

                <div className="signup-form-container">
                    <Row>
                        <Col justify='center' align='middle' span={8}>
                            <a href='/app/signup?plan=GOLD' rel="noopener noreferrer"> Gold </a>
                        </Col>
                        <Col justify='center' align='middle' span={8}>
                            <a href='/app/signup?plan=PLATINUM' rel="noopener noreferrer"> Platinum </a>
                        </Col>
                        <Col justify='center' align='middle' span={8}>
                            <a href='/app/signup?plan=DIAMOND' rel="noopener noreferrer"> Diamond </a>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}


export default injectIntl(SignupComponent);
