import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FeeStructureComponent from '../../../../../../components/modal/modalBody/settings/RegistrationForm/FeesStructure';

class FeesStructureCont extends Component {

    constructor(props){
        super(props);
        this.state= {

        }
    }
    componentDidMount() {

    }

   
    render() {
        return <FeeStructureComponent {...this.props} {...this.state} updateModalState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FeesStructureCont);