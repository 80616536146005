import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DepartmentComp from "../../../../components/drawer/schoolManagement/department";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { saveDepartment } from './action';
import { getDepartmentList, getDepartmentCount } from "../../../schoolManagement/department/action";

class DepartmentDrawer extends Component {
  constructor(props) {
    super(props);
    let deptData = props.deptData || {};
    this.state = {
      deptName: deptData.deptName,
      deptCode: deptData.deptCode,
      deptId: deptData.deptId,
      version: deptData.version,
    };
  }

  componentDidMount() {
    // const companyInfo = this.props.companyInfo || {};
    // const payload = {
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: 1,
    //   pageSize: 25,
    // };
    // fetchDataIfNeeded('fetchAllEmployees', 'allEmployee', this.props, payload);
    // fetchPaginationDataIfNeeded("getSchoolCourse", "courseList", this.props, payload);
    // fetchPaginationDataIfNeeded("getGrade", "gradeList", this.props, payload);
    // fetchPaginationDataIfNeeded("getSection", "sectionList", this.props, payload);
    // fetchPaginationDataIfNeeded("getSubject", "subjectList", this.props, payload);
  }

  render() {
    return (
      <DepartmentComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></DepartmentComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    allEmployee: state.common.employees,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushModalToStack,
      popModalFromStack,
      saveDepartment,
      getDepartmentList, 
      getDepartmentCount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentDrawer);
