import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddSubAccountComponent from "../../../../../components/modal/modalBody/communication/AddSubAccount";

class AddSubAccount extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         ...props.rowData,
    //         configuredStates:props.configuredStates
    //     }
    // }

    // componentDidMount() {
    //     const payload = {
    //         relationshipId: (this.props.companyInfo || {}).relationshipId,
    //         countryId: this.props.countryId
    //     };
    //     fetchDataIfNeeded("getCountryStates", "countryStates", this.props, payload);
    // }

    render() {
        return (
            <AddSubAccountComponent {...this.state} {...this.props} updateState={(data) => {
                this.setState(data);
            }} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddSubAccount);
