import React, { Fragment } from "react";
import { Row, Col, Button, Popover, DatePicker, Divider } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import ReactHtmlParser from 'react-html-parser';

import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import { Dropdown } from "../../../../general/Dropdown";
import * as filter from 'lodash.filter';
import moment from 'moment-timezone';
import { AG_GRID_CONSTANTS } from '../../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { CONSTANTS, MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../../../static/constants';
import { MODAL_TYPE } from '../../../../../static/constants'
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


class ProductDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            sectionVisible: {
                discount: false,
                tax: false,
                expense: false
            },
            total: 0,
            subtotal: 0,
            subtotalWithoutDiscount: 0,
            totalDiscount: 0,
            expenseAmount: 0,
            totalTax: 0,
            itemLevelTaxes: [],
            rootDiscountPercent: 0,
            rootDiscountAmount: 0
        }
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                addFunc = props.addUOM;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId })
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                deleteFunc = props.deleteUOM;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }

    render() {
        const { intl,rFQSplitList } = this.props;
        const self = this;
        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return "#";
                },
                cellRendererFramework: (params) => {
                    return params.node.rowIndex + 1
                },
                field: "sno", rowDrag: true,
                colId: "sno_1",
                width: 80,
                sortable: true,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },

            {
                headerComponentFramework: () => {
                    return "";
                },
                field: 'action', lockPosition: true,
                pinned: 'left',
                width: 20,
                cellRendererFramework: (params) => {
                    const content = (
                        <div>
                            <div className={"cursor-pointer"} onClick={() => {
                                const objList = Object.assign({}, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data);
                                this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isDisable = true;
                                objList.isMaster = false;
                                objList.index = this.props.rFQSplitList.length;
                                objList.parentIndex = params.node.rowIndex;
                                objList.rfqDetailsId = params.node.data.rfqDetailsId;
                                const list = [objList];
                                let state = {}
                                state["rfqexpirationDate"+ this.props.rFQSplitList.length] = this.props.expirationDate
                                const rfqList = this.props.rFQSplitList;
                                rfqList.push(list);
                                this.props.updateState({
                                    ...state,
                                    rFQSplitList: rfqList
                                });
                                this.gridApi.refreshCells({
                                    force: true
                                });
                            }}>Split Again</div>
                            {this.props.rFQSplitList.map((data, i) => {
                                return i !== 0 ? <div key={i}>
                                    <div className={"cursor-pointer"} onClick={
                                        () => {
                                            const objList = Object.assign({}, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data);
                                            this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isDisable = true;
                                            objList.isMaster = false;
                                            objList.index = i;
                                            objList.parentIndex = params.node.rowIndex;
                                            objList.rfqDetailsId = params.node.data.rfqDetailsId;
                                            const tableList = [...this.props.rFQSplitList[i]];
                                            tableList.push(objList);
                                            const rfqList = [...this.props.rFQSplitList];
                                            rfqList[i] = tableList
                                            this.props.updateState({
                                                rFQSplitList: rfqList
                                            });

                                            this.gridApi.refreshCells({
                                                force: true
                                            });
                                        }
                                    }>{this.props.rfqNumber + "-" + i}</div>
                                </div> : ""
                            })}
                        </div>
                    );

                    return (

                        <div>

                            {!params.node.data.isMaster ? <i onClick={
                                () => {
                                    if (!params.node.data.isMaster) {
                                        let rfqList = [...this.props.rFQSplitList];
                                        const currentIndex = params.node.data.index;
                                        const childGridApi = this["gridApi" + params.node.data.index];
                                        this.gridApi.getDisplayedRowAtIndex(params.node.data.parentIndex).data.isDisable = false;
                                        rfqList[params.node.data.index] = rfqList[params.node.data.index].filter((d, i) => {
                                            return i !== params.node.rowIndex;
                                        });
                                        rfqList = rfqList.filter((d, i) => {
                                            if (!d.length && params.node.data.index < rfqList.length) {
                                                rfqList.map((innerTableList, i) => {
                                                    return innerTableList.map((data) => {
                                                        if (i > currentIndex - 1) {
                                                            data.index = data.index - 1;
                                                        }
                                                        return "";
                                                    })
                                                })
                                            }
                                            return d.length
                                        });
                                        childGridApi.updateRowData({ remove: [childGridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                                        this.props.updateState({
                                            rFQSplitList: rfqList
                                        });
                                        this.gridApi.refreshCells({
                                            force: true
                                        });

                                    }
                                }
                            } className='fa fa-arrow-circle-up fa-lg arrow-icon' style={{padding: '5px !important'}} /> : ""}

                            {params.node.data.isMaster ?
                                <Popover content={content}
                                    title={<FormattedMessage id='split.RFQ.merge.existing' defaultMessage='' />}
                                    trigger={"click"}> <i className={!params.node.data.isDisable ? 'fa fa-arrow-circle-down fa-lg arrow-icon' : ""} onClick={() => {
                                        if (this.props.rFQSplitList.length > 1) {
                                            return
                                        }
                                        if (!params.node.data.isDisable && params.node.data.isMaster && this.gridApi.rowRenderer.lastRenderedRow > 0) {
                                            const objList = Object.assign({}, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data);
                                            this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isDisable = true;
                                            objList.isMaster = false;
                                            objList.index = this.props.rFQSplitList.length;
                                            objList.parentIndex = params.node.rowIndex;
                                            const list = [objList];
                                            let state = {}
                                            state["rfqexpirationDate"+ this.props.rFQSplitList.length] = this.props.expirationDate
                                            const rfqList = this.props.rFQSplitList;
                                            rfqList.push(list);
                                            this.props.updateState({
                                                ...state,
                                                rFQSplitList: rfqList
                                            });
                                            var params2 = {
                                                force: true
                                            };
                                            this.gridApi.refreshCells(params2);
                                        }
                                    }
                                    } /> </Popover> : ""}
                        </div>
                    )
                },
                suppressNavigable: true,
                cellClass: 'arrow-cell'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.rfq.form.gridHeader.product' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
                resizable: true,
                sortable: true,
                permissions: this.props.permissions,
                moduleName: MODULE_CODED_VALUES.PRODUCTS,
                operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                cellEditor: 'customTreeDropDown', suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        options: this.props.products,
                        onSearch: this.props.onSearch,
                        onAddNewClick: () => {
                            self.props.updateHeaderState({
                                inventory: {
                                    inventoryDrawerVisible: true
                                }
                            });
                        },
                        canAddNew: true,
                        value: obj.node.data.selectedProductValue || [],
                        selectedLabel: obj.node.data.product || "",
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.selectedProductValue = obj.node.selectedProductValue;
                            const itemId = obj.node.data.selectedProductValue && obj.node.data.selectedProductValue[0];
                            if (itemId) {
                                const variantId = obj.data.selectedProductValue && obj.data.selectedProductValue.length === 2 ? obj.data.selectedProductValue[1] : 0;
                                obj.node.data.productObj = find(this.props.products, { itemId: Number(itemId) }) || {};
                                obj.node.data.variant = variantId ? find(obj.node.data.productObj.inventoryItemVariantList, { variantId: Number(variantId) }) : obj.node.data.productObj.inventoryItemVariantList[0];
                                obj.node.data.uom = obj.node.data.variant.uomName;
                                if (obj.node.data.productObj.description) {
                                    obj.node.data.description = obj.node.data.productObj.description;
                                }
                            }
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.rfq.form.gridHeader.description' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
                cellClass: " ag-cell-description",

                sortable: true,
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.description || "",
                        isEditorLoaded: this.props.isEditorLoaded,
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value;
                            params.node.data.isEditorLoaded = true;
                            this.props.updateState({
                                isEditorLoaded: true
                            })
                        }
                    }
                },
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.description)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > 30) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${30}px`;
                        }
                        params.node.setRowHeight(30);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.rfq.form.gridHeader.qty' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,

                sortable: true,
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.rfq.form.gridHeader.uom' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,

                sortable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: true,
                        items: this.props.uomList || [],
                        optionKeyName: 'uomId',
                        valueKeyName: 'uomName',
                        canAddNew: true,
                        canDelete: true,
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                        },
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM)
                        },
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                        },
                    }
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            }
        ];

        const createRFQ = () => {
            const modalData = {
                modalBody: <FormattedMessage
                    id='supplier.rfq.form.confirmation'
                    defaultMessage={``}
                />,
                handleSubmit: () => {
                    const payload = {
                        props: this.props,
                        gridObject: this.gridApi,
                        state: this.state
                    }
                    this.props.createRFQ(payload);
                    this.props.hideModal();
                }
            };
            this.props.showModal(modalData);
        };

        return (
            <>

                {
                    rFQSplitList?.length ? rFQSplitList.map((list, i) => {
                        return <div key={i} className="transaction-table">
                            <Divider />
                            {
                                i !== 0 ?
                                    <Row>
                                        <Col span={5}>
                                            <Row gutter={[0,3 ]}>
                                                <Col span={24}>
                                                    <FormattedMessage id='common.RFQ.Number' defaultMessage='' />
                                                    <div>{this.props.rfqNumber + "-" + i}</div>
                                                </Col>

                                                <Col span={24}>
                                                    <FormattedMessage id='common.suppliers' defaultMessage='' />
                                                    <Dropdown
                                                        items={this.props.suppliers[(this.props.pageNumber || 0)] || []}
                                                        mode='multiple'
                                                        onSearch={(searchedText) => {
                                                            this.props.fetchSuppliers({
                                                                searchedText: searchedText,
                                                                pageNumber: 1,
                                                                hideSpinner: true,
                                                                pageSize: 100,
                                                                relationshipId: (this.props.companyInfo || {}).relationshipId
                                                            })
                                                        }}
                                                        onDeSelect={(value) => {
                                                            let selectedSuppliers = this.props["selectedSuppliers" + i];
                                                            selectedSuppliers = filter(selectedSuppliers, (supplier) => {
                                                                return supplier !== value;
                                                            });
                                                            let state = {};
                                                            state["selectedSuppliers" + i] = selectedSuppliers
                                                            this.props.updateState(
                                                                state
                                                            );
                                                        }}
                                                        valueKeyName='supplierStoreName'
                                                    // defaultValue={this.props["selectedSuppliers" + i]}
                                                        optionKeyName='supplierId'
                                                        selectedValue={this.props["selectedSuppliers" + i]}
                                                        onSelect={(supplierId) => {
                                                            let selectedSuppliers = this.props["selectedSuppliers" + i] || [];
                                                            if(selectedSuppliers.indexOf(supplierId) < 0){
                                                                selectedSuppliers.push(supplierId);
                                                            }else{
                                                                selectedSuppliers = filter(selectedSuppliers, (supplier) => {
                                                                    return supplier !== supplierId;
                                                                });
                                                            }
                                                        
                                                            let state = {};
                                                            state["selectedSuppliers" + i] = selectedSuppliers;
                                                            this.props.updateState(
                                                                state
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={5} offset={14}>
                                            <Row gutter={[0,3 ]}>
                                                <Col span={24}>
                                                    <FormattedMessage id='supplier.rfq.biddingSuppliers.rfqDetail.bidClosingDate' defaultMessage='' />
                                                    <DatePicker
                                                        format={format}
                                                        key={`${this.props["rfqexpirationDate" + i] ? moment(new Date(this.props["rfqexpirationDate" + i]), format) : moment(new Date(), format)}`}
                                                        defaultValue={this.props["rfqexpirationDate" + i] ? moment(new Date(this.props["rfqexpirationDate" + i]), format) : moment(new Date(), format)}
                                                        onChange={(selectedDate) => {
                                                            let state = {}
                                                            state["rfqexpirationDate" + i] = selectedDate;
                                                            this.props.updateState(state)
                                                        }}
                                                    />  
                                                </Col>

                                                <Col span={24}>
                                                    <FormattedMessage id='customer.listing.totalOrder.header.priority' defaultMessage='' />
                                                    <Dropdown
                                                        items={CONSTANTS.PRIORITIES}
                                                        placeholder='Select Priority'
                                                        value={this.props["childOrderPriority" + i]}
                                                        onSelect={(selectedValue) => {
                                                            let state = {};
                                                            state["childOrderPriority" + i] = selectedValue;
                                                            this.props.updateState(state);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                :
                                ''
                            }
                            <CustomAgGrid
                                key={i}
                                columnDefs={columnDefs}
                                rowData={list || [{}]}
                                onGridReady={(params) => {
                                    params.api.sizeColumnsToFit();
                                    if (i === 0) {
                                        this.gridApi = params.api;
                                        this.gridColumnApi = params.columnApi;
                                    } else {
                                        this["gridApi" + i] = params.api;
                                        this["gridColumnApi" + i] = params.columnApi;
                                    }
                                }}
                                gridStyle={{
                                    width: '100%',
                                    height: '100%',
                                    marginBottom: '10px'
                                }} />

                            {/* remarks */}
                            {i !== 0 ? <>
                                <Row>
                                    <Col span={12} >
                                        <div>
                                            <div className="remarks-title">
                                                <FormattedMessage id='supplier.rfq.form.internalRemarks.label' defaultMessage='' />
                                            </div>
                                            <CKEditor
                                                type="inline"
                                                className="description-textarea"
                                                key={`${("internalRemarks" + i)}`}
                                                data={this.props["internalRemarks" + i]}
                                                onInit={editor => {
                                                    this.editor = editor;
                                                }}
                                                onChange={(event) => {
                                                    const data = event.editor.getData();
                                                    let state = {}
                                                    state["internalRemarks" + i] = data;
                                                    this.props.updateState(state)
                                                }}
                                                config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter internal remarks' }}
                                            />
                                        </div>

                                        <div className="remarks-title">
                                            <FormattedMessage id='supplier.rfq.form.supplierRemarks.label' defaultMessage='' />
                                        </div>

                                        <CKEditor
                                            type="inline"
                                            className="description-textarea"
                                            key={`${("supplierRemarks" + i)}`}
                                            data={this.props["supplierRemarks" + i]}
                                            onInit={editor => {
                                                this.editor = editor;
                                            }}
                                            onChange={(event) => {
                                                const data = event.editor.getData();
                                                let state = {}
                                                state["supplierRemarks" + i] = data;
                                                this.props.updateState(state)
                                            }}
                                            config={{
                                                ...CUSTOM_CONFIGURATION, placeholder: intl.formatMessage(
                                                    { id: 'supplier.rfq.form.supplierRemarks.placeholder', defaultMessage: '' }
                                                )
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <CKEditor
                                    type="inline"
                                    className="add-footer-textarea"
                                    key={`${("footer" + i)}`}
                                    data={this.props["footer" + i]}
                                    onInit={editor => {
                                        this.editor = editor;
                                    }}
                                    onChange={(event) => {
                                        const data = event.editor.getData();
                                        let state = {}
                                        state["footer" + i] = data;
                                        this.props.updateState(state)
                                    }}
                                    config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Add Footer Here' }}
                                />
                            </> 
                            :
                            ''
                            }
                        </div>
                    })
                    :
                    ''
                }
                <div className="mt50" />


                {/* footer */}
                <div className="footer">
                    <div className="left-fItems">
                        <Button className="grey-button"
                            onClick={() => {
                                this.props.showModal({
                                    modalBody: 'All your changes will be erased, do you want to close?',
                                    handleSubmit:()=>{
                                        this.props.hideModal();
                                        this.props.popModalFromStack();
                                    }
                                })
                            }}
                        >
                            <FormattedMessage id='button.cancel.label' defaultMessage='' />
                        </Button>
                    </div>

                    <div className="right-fItems">
                        <Button 
                            type="primary"
                            loading={this.props.saveLoading}
                            disabled={this.props.saveLoading}
                            onClick={() => {
                                createRFQ();
                            }}
                        >
                            <FormattedMessage id='button.save.label' defaultMessage='' />
                        </Button>
                    </div>
                </div>
            </>
        )
    }
};

export default injectIntl(ProductDetails);
