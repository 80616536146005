import React, { Fragment } from "react";
// import config from "../../../config/environmentConfig";
import { injectIntl, FormattedMessage } from "react-intl";
import { Empty, Pagination, Skeleton } from "antd";
import { getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import { CONSTANTS, ICONS } from '../../../static/constants';
import './index.scss';
// import ReactHtmlParser from 'react-html-parser';
import PageBreadcrumb from "../../PageBreadcrumb";
import { SortAscendingOutlined, SortDescendingOutlined} from '@ant-design/icons';


//import EmailInfo from '../../../components/modal/modalBody/communication/EmailInfo'
//import checkIcon from '../../../assets/images/check.png';
//import crossIcon from '../../../assets/images/cross.png';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;


const FeeList = (props) => {
  const { updateState, companyInfo, customerInfo, communicationCount, pageNumber, pageSize, dir=0 } = props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      customerId: customerInfo.customerId
    };

    props.getCustomerCommunication(payload);
    props.getCustomerCommunicationCount(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      customerId: customerInfo.customerId
    };

    props.resetPaginatedData();
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getCustomerCommunication(payload);
    props.getCustomerCommunicationCount(payload);
  };

  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.communication' defaultMessage='' />,
    }
  ];
  
  const sortColumn = (e) => {
    sortTable(document.getElementById('communication-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }
  

  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='heading.schoolManagement.communication' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
        </div>
      </div>
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage id='schoolManagement.communication.table.heading' defaultMessage='' />
            </div>
            {
              pageSize ? <>
                <div className="vertical-sep" />
                <div>{pageSize}</div>
              </>:0
            }
          </div>
          <div className="right-actions">
            {/* <button className="create-action-btn" onClick={() => {
              const modalData = {
                title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                modalBody: <RegistrationFormSetting  registrationType='student' {...this.props} style={{ overflow: 'auto' }} />,
                width: '100%',
                hideFooter: true,
                maskClosable: true,
                hideCloseIcon: true,
                hideTitle: true,
                keyboard: false,
                wrapClassName: "modal-custom-registration"
              };
              this.props.pushModalToStack(modalData);
            }}>
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button> */}
          </div>
        </div>
        <Skeleton loading={props.listLoading} paragraph={{ rows: 12 }} active>
          <div className="table-container">
            <table id='communication-table'>
              <thead>
                <tr>
                  <th style={{width: '100px'}}>Type</th>
                  {/* <th>Description</th> */}
                  <th onClick={sortColumn}>Communiated To <i className={ICONS.SORT} /></th>
                  {/* <th>Transaction</th> */}
                  <th style={{width:'50%'}}>Email Subject <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Communicated By <i className={ICONS.SORT} /></th>
                  <th onClick={sortColumn}>Date <i className={ICONS.SORT} /></th>
                  {/* <th>Origination</th> */}
                </tr>
              </thead>
              <tbody>
                {(props.communicationList[pageNumber] || []).length ? props.communicationList[pageNumber].map((e, i) => {
                  return <tr key={'std' + i}>
                    <td>
                      {/* {e.origination === 'System' ? <span className="cursor-pointer" onClick={() => {
                        props.updateState({ communicationReviewDrawerVisible: true, communicationDetail: e });
                      }}>{e.communicationMode || '-'}</span>
                        : <span>{e.communicationMode || '-'}</span>
                      } */}
                      <span className="table-link-text" onClick={() => {
                          props.updateState({ communicationReviewDrawerVisible: true, communicationDetail: e });
                        }}>{e.communicationMode || '-'}
                      </span>
                  </td>
                    {/* <td>{ReactHtmlParser(e.description)} </td> */}
                    <td>{e.communiatedTo || '-'} </td>
                    {/* <td><div>
                      {e.transactionType ? <FormattedMessage id={e.transactionType} /> : ''}
                    </div></td> */}
                    <td>{e.emailSubject || '-'}</td>
                    <td>{ e.relationshipEmployeeName === 'System Generated'? 'System':  e.relationshipEmployeeName || '-'}</td>
                    <td>{e.communicationDate ? getMomentDateForUIReadOnly({ date: new Date(e.communicationDate), format }) : '-'}</td>
                    {/* <td>{e.origination || '-'}</td> */}
                  </tr>
                  // })
                })
                :
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              defaultCurrent={props.pageNumber || 1}
              total={communicationCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(FeeList);
