import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailDrawer from '../../../components/drawer/email/EmailDrawer';
import { loadEmailData, sendEmail, fetchEmailAddress, getPayOnlineUrl, getFromEmail } from './action';
import { generatePDF } from './../../../actions/downloadFileAction';
import { replacePlaceHolders } from './../../../utils';
//import { PLACEHOLDER_CONSTANTS } from '../../../static/placeholderConstants';
//import config from '../../../config/environmentConfig';

class EmailContainer extends Component {

  constructor(props){
    super(props);
    this.state = {
      body: props.body || props.templateContent,
      emailBody: props.templateContent,
      receiverItems: [],
      ccRecievers: [],
      bccRecievers: [],
      isEditorDataReady: false, 
      //senderList: props.userInfo.email ? [props.userInfo.email]: [],
      //senders: [props.userInfo.email],
      senderList: [],
      senders: [],
      ccRecieversList: [],
      bccRecieversList: [],
    }
  }
  componentDidMount() {
    this.props.type && this.props.loadEmailData(this.props);
  }

  initCalls() {
    //this.props.loadEmailData(this.props);
    let self = this;
    this.props.fetchEmailAddress({
      customerId: (this.props.txData || {}).customerId,
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      supplierId: (this.props.txData || {}).supplierId,
    });

    this.props.getFromEmail(this.props, function(list){ self.setState({senderList:  list})});
    // if (this.props.txData && this.props.txData.transactionName === 'salesInvoice') {
    //   this.props.getInvoiceShortLink({ webInvoiceId: this.props.txData.salesInvoiceDetail.webInvoiceId });
    // }
    // if (this.props.txData && this.props.txData.salesInvoiceDetail && this.props.txData.salesInvoiceDetail.additionalInfoList && this.props.txData.salesInvoiceDetail.additionalInfoList[0] && this.props.txData.salesInvoiceDetail.additionalInfoList[0].paymentGatewayId) {
    //   this.props.getPayOnlineUrl(this.props.txData, this.props);
    // }
  }

  componentWillReceiveProps(props) {
    if(props.emailAddressList && props.emailAddressList.length){
      this.setState({
        receiverItems:  props.emailAddressList,
        ccRecieversList:  props.emailAddressList,
        bccRecieversList:  props.emailAddressList
      })
    }

    if(props.fromEmail && this.state.senderList.indexOf(props.fromEmail) < 0){
      let senderList = this.state.senderList || [];
      senderList.push(props.fromEmail);
      this.setState({ senderList:  senderList, senders: [props.fromEmail]});
      
    }

    if (this.props.txData) {
      if (this.props.emailBody || this.props.subject) {
        this.setState({
          emailBody: replacePlaceHolders(this.props.emailBody || '', this.props.txData, this.props),
          subject: replacePlaceHolders(this.props.subject || '', this.props.txData, this.props),
          isEditorDataReady: true 
        });
      }else{
        setTimeout(() => {
          this.setState({ isEditorDataReady: true });
        }, 2000)
      }
    }else {
      this.setState({ isEditorDataReady: true });
    }
  }


  render() {
    return <EmailDrawer 
        initCalls={() => {
          this.initCalls();
        }}
        {...this.props} 
        {...this.state} 
        updateDrawerState={(data) => { this.setState(data) }} 
      />
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isEmailSpinner: state.common.isEmailSpinner,
    emailAddressList: state.common.emailAddressList,
    payOnlineUrl: state.common.payOnlineUrl,
    webInvoiceShortUrl: state.common.webInvoiceShortUrl
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  loadEmailData,
  sendEmail,
  fetchEmailAddress,
  generatePDF,
  getPayOnlineUrl,
  getFromEmail
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(EmailContainer);
