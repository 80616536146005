import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesComplaintListingComp from '../../../../components/customer/salesComplaint/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchSalesComplaints, getSalesComplaintCount, resetPaginatedData, deleteSalesComplaint, fetchComplaints, getComplaintsCount } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack, fetchUserPreferences, saveUserPrefrences } from '../../../../actions/commonActions';
import * as find from 'lodash.find';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
import { FormattedMessage } from 'react-intl';
import { createOrUpdateComplaint } from '../../../drawer/salesComplaint/action';


class SalesComplaintListing extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};

    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      txPreferences: { txType: "salesComplaints", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      activeTabKey: "1",
      searchedText: '',
      txMandatoryColumns: ['salesComplaintNumber', 'customerName', 'dateCreated'],
      txAvailableColumn: {
        salesComplaintNumber: true,
        assignedTo: true,
        complaintDetails: true,
        soNumber: true,
        referenceNumber: true,
        complaintType: true,
        customerName: true,
        dueDate: true,
        priority: true,
        status: true,
        complaintClosedDate: true,
        dateCreated: true
      },
    };
  }

  componentDidMount() {

    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.sales.salesSupport' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
        {
            name: <FormattedMessage id='sidebar.menuItem.sales' defaultMessage='' />,
        }, 
        {
            name: <FormattedMessage id='sidebar.menuItem.salesSupport' defaultMessage='' />,
        }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
   
    };
    
    // fetchPaginationDataIfNeeded('fetchSalesComplaints', 'salesComplaintList', this.props, payload);
    // fetchDataIfNeeded('getSalesComplaintCount', 'salesComplaintCount', this.props, payload);
    if(this.state.activeTabKey === "1"){

      this.props.fetchComplaints(payload,{activeTabKey:"1"})
      this.props.getComplaintsCount(payload,{activeTabKey:"1"})
    }
    else{
      this.props.fetchComplaints(payload,{activeTabKey:"2"})
      this.props.getComplaintsCount(payload,{activeTabKey:"2"})

    }
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
   
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "salesComplaints", templateName: "standard" }) || this.state.txPreferences;

      if (txPreferences) {
        let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        const txColumnSetting = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <SalesComplaintListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    salesComplaintList: state.salesComplaint.salesComplaintList,
    salesComplaintCount: state.salesComplaint.salesComplaintCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    salesComplaintData: state.salesComplaint.salesComplaintData,
    complaintCount: state.salesComplaint.complaintCount,
    listLoading: state.common.listLoading,
    headerLoading:state.common.headerLoading,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSalesComplaints, 
  getSalesComplaintCount, 
  resetPaginatedData, 
  deleteSalesComplaint,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
  fetchUserPreferences,
  saveUserPrefrences,
  createOrUpdateComplaint,
  fetchComplaints,
  getComplaintsCount
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesComplaintListing);
