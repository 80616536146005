import React, { Fragment } from "react";
import { Row, Col } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import WhatupLogo from "../../../../src/assets/images/whatsapp.png";
import PhoneLogo from "../../../../src/assets/images/phone-iconac.png";
import EmailLogo from "../../../../src/assets/images/email-acc.png";
import DonationsLogo from "../../../../src/assets/images/donate-icons.png";
import AddSubAccount from "../../../containers/modal/modalBody/communication/AddSubAccount";
import { MODAL_TYPE } from '../../../static/constants';
// import PageBreadcrumb from "../../PageBreadcrumb";

const AcoountCompo = (props) => {
  const { subAccount } = props;


    const addItemConfirmationModal = (props, text) => {
      const { showModal, companyInfo } = props;
      const labelName = <FormattedMessage id='communication.account.addSubAccount.model.field.accountPhoneNumber' />;
      const modalBody = <span>
        <FormattedMessage
          id='addItem.confirmation'
          values={{ value: `${text} to ` }}
          defaultMessage=''
        />
        {labelName} ?
      </span>;

      const modalData = {
        modalBody,
        handleSubmit: () => {
          props.updatePhoneNumber({ subaccountPhoneNumber: text, relationshipId: companyInfo.relationshipId })
          props.hideModal();
        },
      };
      showModal(modalData);
    }

    const handleUpdatePhoneNumber = (props, phoneNumber) => {
      let payload = {
        formData: {
          textBoxValue: phoneNumber || '',
          submittedOnce: false,
          maxLength: 10
        },
      }

      const formData = { ...payload.formData};
      const { showModal } = props;

      // if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
      //   addItemConfirmationModal(props, formData.textBoxValue,  MODAL_TYPE.SUBACCOUNT_PHONE_NUMBER);
      // } else {
        const data = {
          title: <FormattedMessage id='communication.account.addSubAccount.model.field.accountPhoneNumber' />,
          formData,
          modalData: {modalType: MODAL_TYPE.SUBACCOUNT_PHONE_NUMBER, data: payload, companyInfo: props.companyInfo || {} },
          handleSubmit: (formData = {}) => {
            if (formData.textBoxValue && formData.textBoxValue.length && formData.textBoxValue.length === 10) {
              addItemConfirmationModal(props, formData.textBoxValue,  MODAL_TYPE.SUBACCOUNT_PHONE_NUMBER);
            } else {
              data.formData.submittedOnce = true;
              showModal(data);
            }
          }
        };
        showModal(data);
     // }
    }
    // const breadCrumbList = [
    //   {
    //     name: <FormattedMessage id='sidebar.menuItem.communication' defaultMessage='' />
    //   },
    //   {
    //     name: <FormattedMessage id='sidebar.menuItem.account' defaultMessage='' />,
    //   }
    // ];

  return (
    <Fragment >
        {/* <div className="page-heading">
          <FormattedMessage id='heading.fundRaising.communications.account' defaultMessage='' />
        </div>
        <div className="pb10">
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div> */}
      <div className="cacount-bgs">
      <div className="view-container sales-order-details  " style={{paddingBottom:'0px'}}>
       {!subAccount || !subAccount.alpideTwilioSubaccountId ? 
       
          <div className='team-and-free '>
            <Row>
              <Col span={4}></Col>
              <Col span={16} style={{ paddingTop: '60px' }}>
                <p style={{ textAlign: 'center', fontSize: '18px', }}> We  use 3rd party services to enable SMS, WhatsApp and Bulk email services.
                  To start using these services, please setup your account with  AlifApp. We will assign a dedicated phone number that
                  you can use for broadcasting SMS, sending whatspp messages, OTP, Text2Donate etc.</p>
              </Col>
            </Row>

            <Row>

              <Col span={8} >


              </Col>
              <Col span={1}></Col>
              <Col span={8} >
                <div className="accunt-box">

                  <div className="account-box1">

                    <ul>
                      <li><span><i className="fa fa-hand-o-right" ></i>
                      </span> Dedicated number from $1/month</li>
                      <li><span><i className="fa fa-hand-o-right" ></i></span> Broadcast Message through SMS</li>
                      <li><span><i className="fa fa-hand-o-right" ></i></span> Use it for OTP</li>
                      <li><span><i className="fa fa-hand-o-right" ></i></span> Bulk WhatsApp Messages</li>
                    </ul>
                  </div>

                  <div className="acc-button">
                    <div className="cursor-pointer" onClick={() => {
                      props.showModal({
                        title: <FormattedMessage id='communication.account.addSubAccount.model.name' defaultMessage='' />,
                        modalBody: <AddSubAccount {...props} />,
                        width: 600,
                        hideFooter: true,
                        // wrapClassName: "manage-user-modal"
                      })
                    }}>Setup Account</div>
                  </div>

                  <div className="acc-icon">
                    <div className="cursor-pointer" style={{ background: '#fff' }}><img src={PhoneLogo} alt="Phone"/></div>
                    <div className="cursor-pointer" style={{ background: '#fff' }}><img src={EmailLogo} alt="Email"/></div>              
                    <div className="cursor-pointer" style={{ background: '#fff' }}><img src={WhatupLogo} alt="" /> </div>
                 
                </div>
                </div>

              </Col>
            </Row>

          </div>
          :
          <div className="team-and-free ">
            {subAccount.subaccountPhoneNumber ?
              <div style={{ paddingLeft: '15px', fontSize: '18px', fontWeight: '600' }}>Your Phone number - {subAccount.subaccountPhoneNumber}
                <span className="cursor-pointer" title={props.intl.formatMessage({ id: 'commons.listing.edit.tooltip.msg' })} onClick={() => { handleUpdatePhoneNumber(props, subAccount.subaccountPhoneNumber || ''); }}>
                  <span> <i className='fa fa-pencil' /></span>
                </span></div> :
              <h2 style={{ paddingLeft: '15px' }}>You have not purchased the phone number. Please contact the support team or <span className="cursor-pointer" onClick={() => { handleUpdatePhoneNumber(props, ''); }}>Click Here</span> to add phone number.</h2>
            }
            <Row>

              <Col span={12}>
                <div className="account-integsm ">
                  <div className="top-box-hed">
                    
                    <div className="top-box-sms" style={{marginRight: '35px'}} onClick={() => {
                      props.updateHeaderState({
                        communication: {
                          ...props.communication,
                          addBrodcastSMSDrawerVisible: true,
                          brodcastSMSType: 'textSMS',
                        },
                      });
                    }}><div className="cursor-pointer">Send SMS</div></div>
                     <div className="top-box-icon cursor-pointer" style={{marginRight: '35px'}} onClick={() => {
                      props.updateState({ accSetupDrawerVisible: true, source: 'sms', accountConfig: props.accountConfigPh || {} })
                    }}><i className="fa fa-gear"></i></div>
                    <div className="top-box-icon"> <i className="fa fa-phone" ></i></div>
                   
                    Phone
                    {/* {!props.accountConfigPh || !props.accountConfigPh.message2DonateConfigId?
                      <Row>
                        <Col span={24} style={{ paddingTop: '60px' }}>
                          <p style={{ textAlign: 'center', fontSize: '18px', }}>  You have not setup Text2Donate. {<span className="cursor-pointer" onClick={() => {
                            props.updateState({ accSetupDrawerVisible: true, source: 'sms' })
                          }}>Click here</span>} to setup now.</p>
                        </Col>
                      </Row>
                      :
                      ''} */}
                  </div>
                  
                  <div className="cont-padd20">
                 
                  </div>
                </div>
              </Col>

              <Col span={12}>
                <div className="account-integsm ">
                  <div className="top-box-hed">
                    <div className="top-box-sms" style={{marginRight: '35px'}}
                      onClick={() => {
                        props.updateHeaderState({
                          communication: {
                            ...props.communication,
                            addBrodcastSMSDrawerVisible: true,
                            brodcastSMSType: 'whatsappSMS',
                          },
                        });
                      }}
                    ><div className="cursor-pointer">Send WhatsApp Message</div></div>
                    <div className="top-box-icon cursor-pointer" style={{ marginRight: '35px' }} onClick={() => {
                      props.updateState({ accSetupDrawerVisible: true, source: 'whatsapp', accountConfig: props.accountConfigWhatsApp || {} })
                    }}><i className="fa fa-gear"></i></div>
                    <div className="top-box-icon"> <img src={WhatupLogo} alt="" /></div>
                    
                    WhatsApp
                    {/* {!props.accountConfigWhatsApp || !props.accountConfigWhatsApp.message2DonateConfigId?
                      <Row>
                        <Col span={24} style={{ paddingTop: '60px' }}>
                          <p style={{ textAlign: 'center', fontSize: '18px', }}>  You have not setup WhatsApp {<span className="cursor-pointer" onClick={() => {
                            props.updateState({ accSetupDrawerVisible: true, source: 'whatsapp' })
                          }}>Click here</span>} to setup now.</p>
                        </Col>
                      </Row>
                      :
                      ''} */}
                  </div>
                  <div className="cont-padd20"></div>
                </div>
              </Col>

              <Col span={12}>
                <div className="account-integsm ">
                  <div className="top-box-hed">
                    <div className="top-box-sms"
                      onClick={() => {
                        props.updateState({
                          emailDrawerVisible: true,
                        });
                      }}
                    ><div className="cursor-pointer"> Send Email</div></div>
                    <div className="top-box-icon"> <i className="fa fa-envelope-o" ></i></div>
                    Email
                  </div>
                  <div className="cont-padd20">
                    {/* <ul>
                      <li><span><i className="fa fa-circle" ></i>
                      </span> Newsletter sent to 22 recepieints on 11st December 2021</li>
                      <li><span><i className="fa fa-circle" ></i>
                      </span>Registration request sent to 325 recepieints on 11st December 2021</li>

                    </ul> */}

                  </div>
                </div>
              </Col>

              <Col span={12}>
                <div className="account-integsm ">
                  <div className="top-box-hed">
                    <div className="top-box-icon"> <img src={DonationsLogo} alt="" /></div>
                    Failed Transactions
                  </div>

                  <div className="cont-padd20">
                    {/* <ul>
                      <li><span><i className="fa fa-circle" ></i>
                      </span> $50 recieved from Arshan KHan on 12th December 2021</li>
                      <li><span><i className="fa fa-circle" ></i>
                      </span>$100 received from Sam Fadul on 11th December 2021</li>
                      <li><span><i className="fa fa-circle" ></i>
                      </span>$100 received from Isam Saif on 11th December 2021</li>
                      <li><span><i className="fa fa-circle" ></i>
                      </span>$100 received from Tariq Moshawi on 11th December 2021</li>

                    </ul> */}
                  </div>

                </div>
              </Col>

            </Row>
          </div>

        }
      </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(AcoountCompo);
