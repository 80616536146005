import axios from "axios";
import config from "../../../../../../config/environmentConfig";
import { showToasterMessage } from "../../../../../../utils";
const { lang } = require("../../../../../../translations/" +
    (sessionStorage.getItem("language") || "en") +
    ".js");

export const saveTeamMember = (payload, props, callback) => {
    return dispatch => {
        return axios
            .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/teamMember/saveOrUpdate`, payload)
            .then(res => {
                props.getTeamMaster(props.teamData);
                showToasterMessage({ messageType: 'success', description: lang[res.message] || 'Team Added to campaingn successfully.' });
                props.hideModal();
                props.getAllTeamLead(props.teamData);
                callback(res.data);
            })
            .catch((err) => {
                showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            })
    }
}