import React from "react";
import "./style.scss";
import alpideIcon from "../../../assets/images/logoIconAlpide.svg";
import successIcon from "../../../assets/images/successIcon.svg";
import { Button, Skeleton } from "antd";
import PackageComp from "./package";
import AddOnsComponent from "./addOns";
import PaymentComponent from "./paymentComp";


function PlanPricing(props) {

    return (
        <Skeleton loading={props.listLoading} paragraph={{ rows: 15}} active>
            <div className={"plan_page_wrapper"}>
                <div className="logoArea">
                    <div className="planPricingContaner">
                        <img src={alpideIcon} alt="" />
                    </div>
                </div>

                {
                    props.activeTab === 0 ? <PackageComp {...props} /> : ''
                }

                {
                    props.activeTab === 1 ?  <AddOnsComponent {...props} /> : ''
                }

                {
                    props.activeTab === 2 ? <PaymentComponent {...props} /> : ''
                }

                {
                    props.activeTab === 3 ? 
                    <div>
                        <div className="successPart">
                            <div>
                                <img src={successIcon} alt="" />
                            </div>
                            <div className="headingArea">
                                <div className="planPricingContaner">
                                    <div className="price_page_head">
                                        <h3 className="successHead">Congratulations! You have upgraded to <span>{props.companyInfo.industryName} - Starter Package</span></h3>
                                        <p className="successText">Thank you for your order, you can get back to dashboard.</p>
                                    </div>
                                    <div className="footerButton">
                                        <Button type="primary" onClick={()=>{
                                            props.popModalFromStack();
                                        }}>Go back to Dashboard</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>:''
                }
            </div>
        </Skeleton>
    )
}
export default PlanPricing