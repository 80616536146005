import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import AdditionalFieldDrawer from '../../../../../components/modal/modalBody/eStore/PopUpMangement/addAdditionalField';
import { pushModalToStack ,popModalFromStack } from '../../../../../actions/commonActions';
import { fetchE_StoreData } from '../../../../../actions/commonActions';
import { showModal, hideModal } from '../../../../../actions/commonActions';
import { uploadPopUpIcon } from '../../../../eStore/themes/action';
import PopUpComp from '../../../../../components/modal/modalBody/eStore/PopUpMangement';
import { updatePopUpSetting } from '../../../../eStore/menus/action';

class PopUpManagement extends Component {
    constructor(props){
        super(props);
        this.state= {
          isLoaded: false,
          timeDuration: false,
          popUpType:'Subscribe',
          fieldsRequired: false,
          showModal: false,
          timeValue:'',
        
          leadFormValues:[
            {"isRequired":true,
              "label":"Full Name",
              "placeholder":"Full Name",
              "type":"text"},
              {"isRequired":true,
                "label":"Email",
                "placeholder":"Email",
                "type":"text"},
                {"isRequired":true,
                  "label":"Mobile No.","placeholder":"Mobile No.","type":"number"}],
        }
    }
   
    componentDidMount() {

      const companyInfo = this.props.companyInfo || {};
      const payload = {
        relationshipId: companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: 100
      };
      const payloadValue ={
        relationshipId: companyInfo.relationshipId,
        pageNumber: 0,
        pageSize: 100,
        isPublish:1,
       
      }
      this.props.fetchE_StoreData(payload);
      
    }
    componentWillReceiveProps(props){
    
      if(!this.state.isHeaderMenuLoaded && Object.keys(props.storeValues || {}) && (props.storeValues.headerMenu|| props.storeValues.footerMenu) ){
      
        let HeaderMenuData=JSON.parse(props.storeValues.headerMenu || '{}');
      
          
        this.setState({
             isHeaderMenuLoaded: true ,

          })
      }
    }
  
    render() {
        return <>
          {this.state.addNewField  && <AdditionalFieldDrawer {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }}/>}
            <PopUpComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    storeValues: state.common.store_values,
  };
};

  const mapDispatchToProps = (dispatch) => bindActionCreators({
     pushModalToStack,
     popModalFromStack,
     fetchE_StoreData,
     showModal,
     hideModal,
     uploadPopUpIcon,updatePopUpSetting
  }, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(PopUpManagement);