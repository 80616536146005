import React,{useState} from "react";
import './index.scss';
import { FormattedMessage } from "react-intl";

const InPageMenu = (props) =>{

    const { menuList, onItemClick } = props;
    const [activeMenu, setActiveMenu] = useState(0); 
  
    return <div className="menu_container">
    {menuList.map((menu, i)=>{
        
       return (

       <>
       <div
        // className="menu_tile"
        className={`menu_tile ${i === activeMenu ? 'active' : ''}`}     
        key={`in_menu`+ i} onClick={() =>{
            setActiveMenu(i);
            onItemClick(i);
            }}>

            <div className="tile_icon">
                <img src={menu.icon} alt="" />
            </div>
            <div class="tile_title">

                <div className="tile_heading">
                    <FormattedMessage
                        id={menu.name}
                        defaultMessage=""
                    />
                </div>

                <div className="tile_description">
                    {menu.description || ''}
                </div>
            </div>
        </div>
       </>
       )
       
        
    })}
        <div>

        </div>
    </div>

}

export default InPageMenu;