import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StudentRegistrationDetailComponent from '../../../../../components/modal/modalBody/customer/StudentRegistrationDetail';
import { getStudentRegistraionDetail, resetStudentRegistrationDetail } from './action';
import { fetchRegistrationFormSetting } from '../../settings/RegistrationFormSettingPreview/action';
import { fetchCustomerDetailsByCustomerId, resetCustomerDetail } from '../../../../../actions/commonActions';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../../../static/constants';
import { generatePDF, printPDF } from '../../../../../actions/downloadFileAction';

class StudentRegistrationDetail extends Component {

    constructor(props){
        super();
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            relationshipBillingAddress: {},
            studentRegistrationDetail: {},
            defaultBillingAddress: {},
            questionList: [],
            isRowHeightSet: false
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            studentRegistrationId: this.props.registrationData.studentRegistrationId,
            registrationFormSettingId: this.props.registrationData.registrationFormSettingId,
            customerId: this.props.registrationData.customerId
        };
        this.props.resetCustomerDetail();
        this.props.fetchRegistrationFormSetting(payload);
        payload.customerId && this.props.fetchCustomerDetailsByCustomerId(payload);
    }

    componentWillReceiveProps(props) {
        if (props.registrationFormSetting && props.registrationFormSetting.registrationFormSettingId) {
            this.setState({
                relationshipId: props.registrationFormSetting.relationshipId,
                registrationFormSettingId: props.registrationFormSetting.registrationFormSettingId,
                formSetting: JSON.parse(props.registrationFormSetting.formSetting),
                programName: props.registrationFormSetting.programName
            })
        }
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            this.setState({
                relationshipBillingAddress,
            })
        }
        if (props.customerDetail && props.customerDetail.customerId) {
            const customer = props.customerDetail || {};
            const defaultBillingAddress = (customer.boLocationList || [])[0] || {};
            let studentRegistration = find(props.customerDetail.orgRegistrationList || [], { studentRegistrationId: this.props.registrationData.studentRegistrationId }) || {};
            this.setState({
                studentRegistrationDetail: studentRegistration,
                questionList: JSON.parse(studentRegistration.questionList || '[]'),
                defaultBillingAddress,
                ...customer,
             
            })
            props.resetCustomerDetail();
          }
    }

    render() {
        return <div>
            <StudentRegistrationDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        permissions: state.auth.permissions,
        registrationFormSetting: state.settings.registrationFormSetting,
        customerDetail: state.common.customerDetail,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getStudentRegistraionDetail, 
    resetStudentRegistrationDetail,
    fetchRegistrationFormSetting,
    fetchCustomerDetailsByCustomerId, 
    resetCustomerDetail,
    generatePDF, 
    printPDF
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudentRegistrationDetail);