import React, { Fragment, useState } from "react";
import { injectIntl } from "react-intl";
import { Tabs } from "antd";
// import { CONSTANTS } from "../../../static/constants";
import TableView from "../../../containers/modal/modalBody/schoolManagement/TimeTable";
import CalenderView from "./calenderView";

const { TabPane } = Tabs;

const TimeTableComp = (props) => {
  // const breadCrumbList = [
  //   {
  //     name: <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />,
  //   },
  //   {
  //     name: (
  //       <FormattedMessage id="sidebar.menuItem.timeTable" defaultMessage="" />
  //     ),
  //   },
  // ];

  const [activeTab, setActiveTab] = useState("1");

  return (
    <Fragment>
      {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.timeTable"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">

        </div>
      </div> */}
      <div
        className="view-container table-height"
      >
        <Tabs
          activeKey={activeTab || "1"}
          onChange={(key) => {
            setActiveTab(key);
          }}
        >
          <TabPane tab="Table View" key="1">
            <TableView {...props} />
          </TabPane>
          <TabPane tab="Calendar View" key="2">
            <CalenderView {...props} />
          </TabPane>
        </Tabs>

        {/* <div className="footer">
          <div className="f-left"></div>
          <div className="f-right"></div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default injectIntl(TimeTableComp);
