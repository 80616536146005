import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreateCategoryComponent from '../../../../../components/modal/modalBody/product/CreateCategory';
import {
    popModalFromStack, pushModalToStack, showModal, hideModal
} from '../../../../../actions/commonActions';
import { addCategory, fetchCategory } from '../../../../drawer/inventory/action';
import { downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import { importProducts } from './action';


class CreateCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            ...props.categoryData,
            pageSize: 100,
            pageNumber: 1,
        };
    }

    componentDidMount(){
        const companyInfo = this.props.companyInfo || {};
        const payload = {
          relationshipId: companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 100
        };
        this.props.fetchCategory(payload);
    }


    render() {
        return <CreateCategoryComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}
const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        productCategories: state.inventory.productCategories,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addCategory,
    fetchCategory,
    importProducts,
    downloadFileFromBucket,
    popModalFromStack, pushModalToStack, showModal, hideModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategory);
