import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CourseComp from "../../../components/schoolManagement/course"
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getSchoolCourse, getSchoolCourseCount, deleteCourse, resetPaginatedData,  getSectionByOfferingDepartmentCount,
  getsectionByOfferingDepartment } from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { FormattedMessage } from 'react-intl';
import {getDepartmentList} from '../department/action';

class School extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {

    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded("getSchoolCourse", "courseList", this.props, payload);
    fetchDataIfNeeded("getSchoolCourseCount", "courseCount", this.props, payload);
    
    this.props.getDepartmentList(payload);
  }

  render() {
    return (
      <CourseComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></CourseComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    courseList: state.school.courseList,
    courseCount: state.school.courseCount,
    departmentList: state.school.departmentList,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSchoolCourse, 
      getSchoolCourseCount, 
      deleteCourse,
      resetPaginatedData,
      showModal,
      hideModal,
      pushModalToStack, 
      popModalFromStack,
      getDepartmentList,
      getSectionByOfferingDepartmentCount,
      getsectionByOfferingDepartment
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(School);
