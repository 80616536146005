import React, { Fragment, Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, DatePicker, Checkbox, } from 'antd';
import { CustomButton } from '../../../../general/CustomButton';
import { getCurrentDateForBackend, getMomentDateForUI } from '../../../../../utils';

class FinancialYearComponent extends Component {
  render() {
    const { getFieldDecorator } = this.props.form;
    const { formData } = this.props.data;
    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 12,
        },
        layout: "horizontal",
        style: {
            maxWidth: 600,
        }
    }
    const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 24,
            offset: 0,
          },
        },
      };

    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true} colon={false}>
          <Form.Item>
              {getFieldDecorator('financialYearId', {
                      initialValue: formData.financialYearId || null,
                  })}
          </Form.Item>
          <Form.Item label={(<span>Is Current FY ?</span>)}>
              {getFieldDecorator('isCurrentFY', {
                      initialValue: formData.isCurrentFY || '',
                      valuePropName: "checked"
                  })(<Checkbox />)}
          </Form.Item>
          <Form.Item label={(<span>Name<span className="required">*</span> </span>)}>
              {getFieldDecorator('fyName', {
                  initialValue: formData.fyName || '',
                  rules: [
                  {
                      required: true,
                      message: 'Name is required.',
                  }
                  ],
              })(<Input placeholder="Name" />)}
          </Form.Item>
          <Form.Item label={(<span>Start Date<span className="required">*</span> </span>)}>
              {getFieldDecorator('fyStartDate', {
                  initialValue: getMomentDateForUI(formData.fyStartDate) || '',
                  rules: [
                  {
                      required: true,
                      message: 'Start Date Required',
                  }
                  ],
              })(<DatePicker placeholder="FY Start Date"/>)}
              
          </Form.Item>
          <Form.Item label={(<span>End Date<span className="required">*</span> </span>)}>
              {getFieldDecorator('fyEndDate', {
                      initialValue: getMomentDateForUI(formData.fyEndDate) || '',
                      rules: [
                      {
                          required: true,
                          message: 'End Date Required',
                      }
                      ],
                  })(<DatePicker placeholder="FY End Date" />)}
          </Form.Item>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'button.cancel.label'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.hideModal() }}
              />

              <CustomButton
                intlId={Object.keys(formData).length !== 0 ? 'modal.finYear.button.updateFY': 'modal.finYear.button.createFY'}
                defaultMessage=''
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
            financialYearId: values.financialYearId,
            isCurrentFY: values.isCurrentFY ? 1: 0,
            fyName: values.fyName,
            fyStartDate: values.fyStartDate ? getCurrentDateForBackend(values.fyStartDate) : null,
            fyEndDate: values.fyEndDate ? getCurrentDateForBackend(values.fyEndDate): null,
            relationshipId: (this.props.companyInfo || {}).relationshipId,
        }
        const modalData = {
          modalBody: payload.financialYearId ? 'Are you sure you want to update financial year ?': 'Are you sure you want to add financial year ?',
          handleSubmit: () => {
            this.props.updateFinancialYear(payload, this.props);
            this.props.hideModal();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'financial_year_component' })(injectIntl(FinancialYearComponent));
