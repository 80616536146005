import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BranchDrawer from '../../../../../components/drawer/company/EmployeeMeta/Branch';
import { fetchCountries, fetchStates, hideModal, showModal } from '../../../../../actions/commonActions';
import { createBranch } from './action';
import { getAllBranches } from '../../../../company/EmployeeMeta/Branch/action';

class BranchDrawerCon extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
      }

      componentDidMount(){
        this.props.fetchCountries();
      }


    render() {
        return <BranchDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo?.relationshipId,
        countries: state.common.countries,
        states: state.common.states,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCountries,
    fetchStates,
    createBranch,
    getAllBranches,
    showModal,
    hideModal,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BranchDrawerCon);