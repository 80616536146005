import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Skeleton, Pagination, Popover, Empty } from "antd";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { FilterOutlined, InsertRowRightOutlined } from "@ant-design/icons";
import "./index.scss";
import { CONSTANTS, ICONS } from "../../../../static/constants";
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const FeesManagementComp = (props) => {
  const {
    updateState,
    companyInfo,
    salesInvoiceCount,
    schoolStudentList,
    schoolStudentsCount,
  } = props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.getSchoolStudents(payload);
    props.countSchoolStudents(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.getSchoolStudents(payload);
    props.countSchoolStudents(payload);
  };

  return (
    <>
      <div className="view-container-actions">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="left-actions">
          <div className="table-heading">Fees Management</div>
          <div className="vertical-sep" />
          {schoolStudentsCount ? (
            <>
             
              <div>{schoolStudentsCount}</div>
            </>
          ) : (
            0
          )}
        </div>

        <div className="right-actions">
          <button
            onClick={() => {
              props.updateState({
                salesInvoiceFilterDrawerVisible: true,
              });
            }}
          >
            <FilterOutlined onClick={() => {}} /> &nbsp;{" "}
            <FormattedMessage id="filter.text" defaultMessage="" />
          </button>

          <Popover
            content={<AddAndRemoveCustomerListingColumn {...props} />}
            title={<div className="text-center">Add/Remove</div>}
            placement="leftTop"
            trigger="click"
            arrow="right"
            visible={props.showColPop}
          >
            <button
              onClick={() => {
                props.updateState({ showColPop: !props.showColPop });
              }}
            >
              <InsertRowRightOutlined className='mr5'/>
               Columns
            </button>
          </Popover>
        </div>
        </Skeleton>
      </div>

      <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Student Id</th>
                <th>Roll No.</th>
                <th>Program Name</th>
                <th>Course</th>
                <th>class</th>
                <th>Student Name</th>
                <th>Primary Contact</th>
                <th>Type of Payment</th>
                <th>Payment Plan</th>
              </tr>
            </thead>

            <tbody>
              {(schoolStudentList[1] || []).length
                ? schoolStudentList[1].map((rowData, i) => {
                    return (
                      <tr key={`feeInvoice` + i}>
                        <td>{i + 1}</td>
                        <td>{rowData.schoolStudentId || "-"}</td>
                        <td>{rowData.rollNo || "-"}</td>
                        <td>{rowData.programName || "-"}</td>
                        <td>{rowData.courseName || "-"}</td>
                        <td>{rowData.className || "-"}</td>
                        <td>{rowData.studentName || "-"}</td>
                        <td>{rowData.primaryName || "-"}</td>
                        <td>{"-"}</td>
                        <td>{"-"}</td>
                      </tr>
                    );
                  })
                : ( <tr key="empty">
                  <td colSpan={"100%"}>
                    <Empty />
                  </td>
                </tr>)}
            </tbody>
          </table>
        </div>
      </Skeleton>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={salesInvoiceCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </Skeleton>
      </div>
    </>
  );
};

export default injectIntl(FeesManagementComp);
