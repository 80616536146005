import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { ICONS } from "../../../static/constants";
// import { AgGridReact } from "ag-grid-react";
import {
  Tooltip,
  Skeleton,
  Pagination,
  Checkbox,
  Popover,
  Menu,
  Empty,
  DatePicker,
  Row,
  Col,
  Button,
} from "antd";

import {
  fetchPaginationDataIfNeeded,
  showToasterMessage,
  getMomentDateForUIReadOnly,
  exportExcel,
  fixedDecimalAndFormateNumber,
  sortTable,
  getMomentDateForUI,
} from "../../../utils";

import DropdownAction from "antd/lib/dropdown";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
 
  WarningOutlined,
  RetweetOutlined,
  DeleteOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import "./index.scss";
import GSTRFillingDetails from "../../../containers/modal/modalBody/finance/gstrDetails";

import { CONSTANTS } from "../../../static/constants";

import find from "lodash.find";
import StandardSalesInvoice from "../../../containers/customer/invoice/StandardSalesInvoice";





const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const ErrorComp = (props) => {
  const { warningData, show} = props;
  
  return (
    <div>
      <Row>
        <Col span={24}>
          <div>
            {(warningData || []).length ? (
              <div>
                {" "}
                {(warningData || [])?.map((item, i) => {
                  return <div className="error">{item?.description}</div>;
                })}
              </div>
            ) : (
              <div className="success">
                There is no {show} in the Invoices!!
              </div>
            )}
          </div>
        </Col>
      </Row>

     
    </div>
  );
};
const ValidatedInvoiceListingCom = (props) => {
  const { dir = 0 } = props;
  


 
  const showErrorAndWarnings = (warnings, index, inum ,i) => {
    const values = (inum || "").split("/");
    const modalData = {
      title: index,
      modalBody: (
        <ErrorComp {...props} warningData={warnings} show={index} />
      ),
      width: 900,
      hideFooter: false,
      footer:{
        submit:{
          intlId: "Fix Now"
        }
      },
      handleSubmit: ()=>{
        
        
        props.pushModalToStack({
          modalBody: (
            <StandardSalesInvoice
              {...props}
              // salesInvoiceEditDetail = {salesInvoiceDetail}
              invoiceNumber={values[0] || 0}
              customerId={values[2]}
              invoiceMasterId={values[1]}
              update={true}
              btnName={index}
              errorsData={warnings}
              validatedGstr1={true}
              uploadInvoiceId={i}
              //  soMasterIdArr= {_getSoMasterId(salesInvoiceDetail)}
              registrationFormSettingId={0}
            />
          ),
          width: "100%",
          hideTitle: true,
          hideFooter: true,
          wrapClassName: "new-transaction-wrapper",
        });
       
      
     
    }
    };

    props.showModal(modalData);
  };

  
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchGSTFilingDeatils",
      "eInvoiceListing",
      props,
      payload
    );
  };
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    // props.resetPaginatedData();
    props.fetchGSTFilingDeatils(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const sortColumn = (e) => {
    //sortTable(document.getElementById('invoiceTable'), e.target.parentElement.cellIndex, 1 - dir);
    sortTable(
      document.getElementById("invoiceTable"),
      e.target.cellIndex,
      1 - dir
    );
    // updateState({ dir: 1 - dir });
  };

  const documentType = {
    RI: "Regular Invoice",
    C: "Credit Note",
    D: "Debit Note",
    BS: "Bill of Supply",
    BOE: "Bill of Entry",
    DC: "Delivery Challan",
    OTH: "Others",
  };

  
  const handleSummary = ()=>{
     const hasError = (props.validateInvoiceListing || [])?.find((item)=>item?.hasError);
     const hasWarning = (props.validateInvoiceListing||[])?.find((item)=> item?.hasWarning);
     if((props.validateInvoiceListing || [])?.length === 0){
      return (
        showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "There are no invoices to create summary. Please check and try again.",
          }),
        })
      )
     }
     if(Object.keys(hasError||{})?.length){
      showToasterMessage({
                      messageType: "error",
                      description: props.intl.formatMessage({
                        id: "Error detected in one of invoices. Please correct it, then create a summary",
                      }),
                    });
     }
    else if(Object.keys(hasWarning||{})?.length){
      const modalData = {
        title: <FormattedMessage
        id="confirmation.submit"
        defaultMessage=""
      />,
      hideFooter: false,
        modalBody: (
         <div>
             Are you sure to create summary with warnings?
         </div>
        ),
        width: 500,
        handleSubmit: ()=>{
          
          props.createSummaryDraft(
            { gstin: "33ABCDE9876A1ZE", fp: props.selectedDate },
            props
          );
        }
      };
  
      props.pushModalToStack(modalData); 
    }
    else{
      props.createSummaryDraft(
        { gstin: "33ABCDE9876A1ZE", fp: props.selectedDate },
        props
      );
    }

  
  }
  return (
    <Fragment>
      <div className="view-container-actions summary-container">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
        <div className="left-actions">
          <div className="table-heading">
            <FormattedMessage id="View Listing" defaultMessage="" />
          </div>

          <div className="vertical-sep" />
          <div>{(props.validateInvoiceListing || []).length || 0}</div>
        </div>

        <div className="right-actions">
          <button
            style={{ width: "200px" }}
            className="upload-button"
            // disabled={props.createSummaryBtn}
            onClick={handleSummary}
          >
            <SaveOutlined />
            <FormattedMessage id="Create Summary" defaultMessage="" />
          </button>
          <button
            style={{ width: "200px" }}
            
            // disabled={props.createSummaryBtn}
            onClick={() => {
              const modalData = {
                title: <FormattedMessage
                id="Confirmation"
                defaultMessage=""
              />,
              hideFooter: false,
                modalBody: (
                 <div>
          Are you sure you want to disacrd the Summary?
                 </div>
                ),
                width: 500,
                handleSubmit: ()=>{
                  
                  
                  
                 
                  props.discardSummaryDraft({gstin:"33ABCDE9876A1ZE" ,fp: props.selectedDate ,formType :"GSTR1 "},props)
                  
                 
                }
              };

              props.pushModalToStack(modalData)
              // props.updateState({
              //   salesEInvoiceFilterDrawerVisible: true,
              // });
            }
           
          }
          >
            {/* <SaveOutlined /> */}
            <FormattedMessage id="Discard Summary" defaultMessage="" />
          </button>
        </div>
        </Skeleton>
      </div>
      <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>
      <div className="table-container">
        <table id="invoiceTable">
          <thead>
            <tr>
              <th onClick={sortColumn}>
                <FormattedMessage id="Document Type" defaultMessage="" />{" "}
              
              </th>
              <th onClick={sortColumn}>
                <FormattedMessage id="Invoice #" defaultMessage="" />{" "}
              
              </th>
              <th onClick={sortColumn}>
                <FormattedMessage id="Invoice Type" defaultMessage="" />{" "}
              
              </th>
              <th onClick={sortColumn}>
                <FormattedMessage id="Document #" defaultMessage="" />{" "}
              
              </th>
              <th onClick={sortColumn}>
                <FormattedMessage id="Document Date" defaultMessage="" />{" "}
              
              </th>

              <th onClick={sortColumn}>
                <FormattedMessage
                  id="GSTIN of Counterparty"
                  defaultMessage=""
                />{" "}
              
              </th>

              <th onClick={sortColumn}>
                <FormattedMessage id="Name of Counterparty" defaultMessage="" />{" "}
              
              </th>

              <th onClick={sortColumn}>
                <FormattedMessage id="Total Value" defaultMessage="" />{" "}
              
              </th>

              <th>
                <FormattedMessage id="GSTIN Status" defaultMessage="" />{" "}
              </th>
              <th className="text-center">
                <FormattedMessage id="Error/Warning" defaultMessage="" />{" "}
              </th>
              <th className="text-center">
                <FormattedMessage
                      id="Actions"
                      defaultMessage=""
                    />{" "}
                </th>
              {/* <th>
                <FormattedMessage
                  id="Reconcilation Category"
                  defaultMessage=""
                />{" "}
              </th> */}
            </tr>
          </thead>
          <tbody>
            {(props.validateInvoiceListing || []).length ? (
              (props.validateInvoiceListing || []).map((item, i) => {
                const values = (item?.inum || "").split("/");
                return (
                  <tr key={i}>
                    <td>
                      <div className="one-line-view">
                        {documentType[item.dty]}
                      </div>
                    </td>

                    <td>
                      <div className="one-line-view">
                      INV-{values[0] || <div className="empty-data-box" />}
                      </div>
                    </td>
                    <td>
                      <div className="one-line-view">
                        {item?.invTyp|| <div className="empty-data-box" />}
                      </div>
                    </td>
                    <td>
                      <div className="one-line-view">
                        {values[1] && values[2]
                          ? `${values[1]}/${values[2]}`
                          : values[0] || <div className="empty-data-box" />}
                      </div>
                    </td>

                    <td>
                      <div>
                        {item.idt || <div className="empty-data-box" />}
                      </div>
                    </td>

                    <td>
                      <div>
                        {item.ctin || <div className="empty-data-box" />}
                      </div>
                    </td>

                    <td>
                      <div>
                        {item.cname || <div className="empty-data-box" />}
                      </div>
                    </td>

                    <td>
                      <div>
                        {item.val || <div className="empty-data-box" />}
                      </div>
                    </td>
                    <td>
                      <div>{item.uploaded ? "TO BE SAVED" : ""}</div>
                    </td>
                    <td className="text-center">
                      {item?.hasWarning ? (
                        <span
                        className="warning"
                          onClick={() => {
                            let warningList=[];
                            let warning =[];
  
                            warningList =
                              item?.errorDataModel?.warning === null
                                ? []
                                : item?.errorDataModel?.warning;
                            let finalWarningList = (
                              item?.itemDetails || []
                            )?.flatMap((val) => {
                              return val?.errorDataModel?.warning !== null
                                ? val?.errorDataModel?.warning
                                : [];
                            });
                             warning = [
                              ...warningList||[],
                              ...finalWarningList,
                            ];
                            showErrorAndWarnings(
                              warning,
                              "Warnings",
                              item?.inum,
                              item?.id
                            );
                          }}
                        >
                          {" "}
                          <Tooltip
                            title=" Warings"
                            color={"transparent"}
                            key={"transparent"}
                          >
                            <WarningOutlined color="red" />
                          </Tooltip>
                        </span>
                      ) : (
                        " "
                      )}{" "}
                      {item?.hasError ? (
                        <span
                        className="warning"
                          onClick={() => {
                            let errorList=[];
                            let error=[];
                            errorList =
                              item?.errorDataModel?.error === null
                                ? []
                                : item?.errorDataModel?.error;

                            let finalErrorList = (
                              item?.itemDetails || []
                            )?.flatMap((val) => {
                              return val?.errorDataModel?.error !== null
                                ? val?.errorDataModel?.error
                                : [];
                            });

                             error = [...errorList, ...finalErrorList];

                            showErrorAndWarnings(error, "Errors", item?.inum ,item?.id);
                          }}
                        >
                          {" "}
                          <Tooltip
                            title="Errors "
                            color={"transparent"}
                            key={"transparent"}
                          >
                            <InfoCircleOutlined color="red" />
                          </Tooltip>
                        </span>
                      ) : (
                        " "
                      )}{" "}
                        {/* {
                         <span className="warning"
                         aria-disabled={true}
                         onClick={()=>{

                          const modalData = {
                            title: <FormattedMessage
                            id="Confirmation"
                            defaultMessage=""
                          />,
                          hideFooter: false,
                            modalBody: (
                             <div>
                                 Are you want to sure to delete the filed invoice?
                             </div>
                            ),
                            width: 500,
                            handleSubmit: ()=>{
                              
                              
                           props.deleteValidatedInvoice({date: props.selectedDate ,idList:[item?.id] ,relationshipId: props.companyInfo?.relationshipId} ,props)
                              
                             
                               
                              
                             
                            }
                          };
                           props.pushModalToStack(modalData)
                         }}>  <Tooltip
                         title="Delete"
                         color={"transparent"}
                         key={"transparent"}
                       >
                         <DeleteOutlined color="red" />
                       </Tooltip></span>
                     } */}
                     {" "}
                      {
                       
                       (item?.hasWarning||item?.hasError) ? <span
                          className="table-link-text "
                          onClick={() => {
                            const payload ={
                              relationshipId: props.companyInfo?.relationshipId,
                              pageNumber: 1,
                              pageSize: 25,
                              filters:{
                                invoiceNumber: `INV-${values[0]}` ,
                                customerId: 1939,
                              }
                            }
                             props.fetchSalesInvoicesByFilter(payload ,(data)=>{
                             })
                            let warningList=[];
                            let warning =[];
  
                            warningList =
                              item?.errorDataModel?.warning === null
                                ? []
                                : item?.errorDataModel?.warning;
                            let finalWarningList = (
                              item?.itemDetails || []
                            )?.flatMap((val) => {
                              return val?.errorDataModel?.warning !== null
                                ? val?.errorDataModel?.warning
                                : [];
                            });
                             warning = [
                              ...warningList||[],
                              ...finalWarningList,
                            ];

                            let errorList=[];
                            let error=[];
                            errorList =
                              item?.errorDataModel?.error === null
                                ? []
                                : item?.errorDataModel?.error;

                            let finalErrorList = (
                              item?.itemDetails || []
                            )?.flatMap((val) => {
                              return val?.errorDataModel?.error !== null
                                ? val?.errorDataModel?.error
                                : [];
                            });

                             error = [...errorList, ...finalErrorList];

                            props.pushModalToStack({
                              modalBody: (
                                <StandardSalesInvoice
                                  {...props}
                                  // salesInvoiceEditDetail = {salesInvoiceDetail}
                                  invoiceNumber={`INV-${values[0]}`}
                                  customerId={values[2]}
                                  invoiceMasterId={values[1]}
                                  uploadInvoiceId={item?.id}
                                  errorsData ={[...warning ,...error]}
                                  update={true}
                                  btnName={"Warnings"}
                                  validatedGstr1={true}
                                  //  soMasterIdArr= {_getSoMasterId(salesInvoiceDetail)}
                                  registrationFormSettingId={0}
                                />
                              ),
                              width: "100%",
                              hideTitle: true,
                              hideFooter: true,
                              wrapClassName: "new-transaction-wrapper",
                            });
                          }}
                        >
                         Fix Now
                        
                        </span>:""
                      }
                   
                    </td>
                    <td className="text-center">
                    {
                        <span className="warning"
                        aria-disabled={true}
                        onClick={()=>{

                          const modalData = {
                            title: <FormattedMessage
                            id="Confirmation"
                            defaultMessage=""
                          />,
                          hideFooter: false,
                            modalBody: (
                             <div>
                                 Are you want to sure to delete the filed invoice?
                             </div>
                            ),
                            width: 500,
                            handleSubmit: ()=>{
                              
                              
                           props.deleteInvoiceList({date: props.selectedDate ,idList:[item?.id] ,relationshipId: props.companyInfo?.relationshipId , masterId:values[1]} ,props)
                              
                             
                               
                              
                             
                            }
                          };
                           props.pushModalToStack(modalData)
                         }}
                        
                        > 
                        
                         <Tooltip
                        title="Remove from filing"
                        color={"transparent"}
                        key={"transparent"}
                      >
                        <DeleteOutlined color="red" />
                      </Tooltip></span>
                      }{" "}
                     
                    </td>
                   
                  </tr>
                );
              })
            ) : (
              <tr key="empty">
                <td colSpan={"100%"}>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      </Skeleton>
      <div className="footer-gst-btn">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={(props.validateInvoiceListing || []).length}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                props.updateState({
                  pageSize: pageSize,
                  pageNumber: pageNumber,
                });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(ValidatedInvoiceListingCom);
