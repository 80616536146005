export const lang = {
  'phone': 'الهاتف',
  'email': 'إيميل',
  'gstIn': 'جي ستين',
  'pan': 'رقم الحساب الدائم',
  'ok': 'موافق',
  'company': 'شركة',
  'supplier': 'مورد',
  'contact': 'اتصل',
  'billTo': 'فاتورة إلى',
  'shipTo': 'شحنة إلى',
  'download': 'حمّل',
  'button.create.label': 'إيجاد',
  'button.save.label': 'احفظ',
  'draft.text': 'مسودة',
  'button.draft.label': 'إحفظ بصيغة مسودة',
  'button.cancel.label': 'إلغاء',
  'button.close.label': 'إغلاق',
  'pagination.text.total': 'المجموع',
  'pagination.text.items': 'أشياء',
  'login.text': 'تسجيل الدخول بإدخال مؤهلاتك',
  'login.or': 'أو',
  'login.text-signup': 'اشتراك مجاني/ اشترك مجانا',
  'login.submit': 'تسجيل الدخول',
  'login.email.placeholder': 'إيميل',
  'login.email.invalidEmail': 'بريدك الكتروني خاطئ',
  'login.email.requiredEmail': 'المطلوب بريدك الالكتروني',
  'login.email.requiredPassword': 'المطلوب كلمة المرور',
  'login.forgot': 'هل نسيت كلمة المرور',
  'login.password.placeholder': 'كلمة السر',
  'login.remember': 'تذكرني',
  'login.capsLockIsOn': 'كابس لوك يعمل',
  'login.notification.lable': 'إشعار',
  'login.notification.message': 'سنجري صيانة روتينية في 18 يونيو 2021. نشكرك على سعة صدرك بينما نجري هذه التغييرات من أجلك.',
  'login.text1': 'أعمالك التجارية على  ',
  'login.text2': 'أطراف أصابعك',
  'login.text3': ' يتيح Alpide ERP الوصول الكامل إلى كل عملية مهمة في عملك عن طريق تسهيل الوصول إلى البيانات لك.',
  'signup.text': 'اشترك',
  'signup.tab.contact': 'اتصل',
  'signup.tab.address': 'عنوان',
  'signup.email.text': 'إيميل',
  'signup.currencyCode.text': 'رمز عملة',
  'signup.country.text': 'بلد',
  'signup.password.text': 'كلمة السر',
  'signup.companyName.text': 'إسم الشركة',
  'signup.companyName.placeholder': 'إسم الشركة',
  'signup.country.placeholder': 'إسم البلد',
  'signup.passwordRepeat.text': 'كلمة السر (كرر)',
  'signup.repeatPassword.placeholder': 'كرر إدخال كلمة السر',
  'signup.firstname.placeholder': 'الإسم الأول',
  'signup.lastname.placeholder': 'الإسم الأخير',
  'signup.streetname.placeholder': 'إسم الشارع',
  'signup.street(contd).placeholder': 'الشارع (مسلسل)',
  'signup.city.placeholder': 'مدينة',
  'signup.postal/zipcode.placeholder': 'رمز بريدي',
  'signup.statename.placeholder': 'إسم الولاية',
  'signup.btn.cancel': 'إلغاء',
  'signup.btn.create': 'إنشاء حساب',
  'signup.workPhone.placeholder': 'هاتف العمل',
  'signup.validation.email': 'ايميل مطلوب',
  'signup.validation.password': 'كلمة المرور مطلوبة',
  'signup.validation.confirmPassword': 'إعادة كتابة كملة المرور',
  'signup.validation.passwordMismatch': 'لا تطابق كلمة المرور',
  'signup.validation.company': 'إسم الشركة مطلوب',
  'signup.validation.country': 'إسم البلد مطلوب',
  'forgot.headingText': 'Password Reset',
  'forgot.text': 'أكتب عنوان بريدك الالكتروني المسجل عند تسجيلك الأول. سنرسل لك إيميلا مع إسم العضو و كلمة المرور',
  'forgot.btn.save': 'احفظ',
  'forgot.btn.back': 'العودة إلى السابق',
  'forgot.btn.submit': 'أرسل بريدك الالكتروني',
  'forgot.btn.addComment': 'إحفظ مراجعة حسابات',
  'forgot.email.placeholder': 'أدخل بريدك الالكتروني المسجل',
  'confirmation.submit': 'تقديم',
  'confirmation.cancel': 'إلغاء',
  'signup.confirmation.country': 'بلدك – {قيمة }',
  'signup.confirmation.text': 'هل أنت متأكد من أنك تريد إنشاء الحساب؟',
  'signup.confirmation.title': 'موافق',
  'signup.gstNumber.text': 'جي ستين',
  'signup.gstNumber.placeholder': 'جي ستين',
  'signup.vatNumber.text': 'رقم فات',
  'signup.vatNumber.placeholder': 'رقم فات',
  'signup.phoneNumber.text': 'رقم الهاتف',
  'signup.phoneNumber.placeholder': 'رقم الهاتف',
  'signup.faxNumber.text': 'رقم فاكس',
  'signup.faxNumber.placeholder': 'رقم فاكس',
  'signup.website.text': 'موقع ويب',
  'signup.website.placeholder': 'موقع ويب',

  'drawer.ledger.salesDiscount' : 'خصم (Dr)',
  'drawer.ledger.sales' : 'المبيعات (Dr)',
  'drawer.ledger.salesOutput' : 'ضريبة (Cr)',
  'drawer.ledger.generalIncome' : 'تكلفة (Cr)',
  'drawer.ledger.debtors' : 'مدين (Dr)',
  
  'drawer.inventory.title': 'معلومات جرد',
  'drawer.inventory.tabNames.productInfo': 'معلومات عن المنتج',
  'drawer.inventory.tabNames.productDetails': 'تفاصيل المنتج',
  'drawer.inventory.tabNames.finance': 'ضريبة',
  'drawer.inventory.tabNames.warehouse': 'ويرهاوس',
  'drawer.inventory.tabNames.alerts': 'تنبيهات',
  'drawer.inventory.tabNames.image': 'صورة',

  
  'modal.common.taxIdentification.taxType': 'نوعية الضريبة',
  'modal.common.taxIdentification.number': 'رقم',
  'modal.common.taxIdentification.populateOnTransaction': 'ملء المعاملات',
  'modal.common.taxIdentification.populateOnTransaction.tooltip': 'إذا تم الاختيار سيتم عرض الرقم الضريبي المحدد على وثيقة بصيغة بي دي إف',

  'drawer.inventory.productName': 'إسم السلعة',
  'drawer.inventory.description': 'مواصفات',
  'drawer.inventory.category': 'صنف',
  'drawer.inventory.brand': 'علامة تجارية',
  'drawer.inventory.partNo': 'جزء #',
  'drawer.inventory.arcticleNumber': 'سلعة',
  'drawer.inventory.manufacturer': 'شركة مصنعة',
  'drawer.inventory.sku': 'إس كيه يو',
  'drawer.inventory.systemSku': 'استخدم نظام إس كيه يو',
  'drawer.inventory.customerStockNo': 'مخزون #',
  'drawer.inventory.salesTarget': 'مستهدف المبيعات',
  'drawer.inventory.publishToB2BSite': 'أنشر على موقع B2B',
  'drawer.inventory.variantSwitch': 'أضف البدائل للمنتج',
  'drawer.inventory.purchasedPrice': 'سعر الشراء',
  'drawer.inventory.wholeSalePrice': 'سعر الجملة',
  'drawer.inventory.retailPrice': 'سعر التجزئة',
  'drawer.inventory.barCode': 'باركود',
  'drawer.inventory.currentStock': 'المخزون الحالي',
  'drawer.inventory.uom': 'يو أو إم',
  'drawer.inventory.attribute': 'خاصية',
  'drawer.inventory.attributeValue': 'قيمة',

  'drawer.inventory.upc': 'يو في سي',
  'drawer.inventory.ean': 'إي إيه ان',
  'drawer.inventory.isbn': 'آي إس بي ان',

  'drawer.inventory.upc.placeholder': 'رمز المنتج العالمي',
  'drawer.inventory.ean.placeholder': 'الرقم العالمي للسلع والمنتجات',
  'drawer.inventory.isbn.placeholder': 'الرقم القياسي الدولي للكتاب',

  'drawer.inventory.warehouse': 'ويرهاوس',
  'drawer.inventory.warehouse.placeholder': 'الرجاء اختيار ويرهاوس',

  'drawer.inventory.productName.placeholder': 'إسم السلعة',
  'drawer.inventory.description.placeholder': 'تفاصيل المنتج',
  'drawer.inventory.category.placeholder': 'اختر صنف المنتج',
  'drawer.inventory.brand.placeholder': 'اختر البراند',
  'drawer.inventory.partNo.placeholder': 'أكتب جزء#',
  'drawer.inventory.arcticleNumber.placeholder': 'أكتب سلعة #',
  'drawer.inventory.manufacturer.placeholder': 'قم بتحديد شركة مصنعة',
  'drawer.inventory.sku.placeholder': 'أدخل إس كيه يو',
  'drawer.inventory.customerStockNo.placeholder': 'مخزون العملاء #',
  'drawer.inventory.salesTarget.placeholder': 'مستهدف المبيعات',
  'drawer.inventory.purchasedPrice.placeholder': 'أكتب سعر الشراء',
  'drawer.inventory.wholeSalePrice.placeholder': 'أكتب سعر الجملة',
  'drawer.inventory.retailPrice.placeholder': 'أكتب سعر التجزئة',
  'drawer.inventory.barCode.placeholder': 'أكتب الباركود المكتوب على السلعة',
  'drawer.inventory.currentStock.placeholder': 'أدخل الخزون الحالي',
  'drawer.inventory.uom.placeholder': 'حدد UOM',
  'drawer.inventory.attribute.placeholder': 'تحديد خاصية',
  'drawer.inventory.attributeValue.placeholder': 'أدخل قيمة الخاصية',

  'drawer.inventory.productDetails.addVariantToProduct.button': 'أضف البدائل للسلعة',
  'drawer.inventory.productDetails.reset.button': 'إعادة تعيين البديل',
  'drawer.inventory.productDetails.gridHeader.variant': 'البديل',
  'drawer.inventory.productDetails.gridHeader.purchasedPrice': 'سعر الشراء',
  'drawer.inventory.productDetails.gridHeader.retailPrice': 'سعر التجزئة',
  'drawer.inventory.productDetails.gridHeader.wholeSalePrice': 'سعر الجملة',
  'drawer.inventory.productDetails.gridHeader.currentStock': 'المخزون الحالي',
  'drawer.inventory.productDetails.gridHeader.uom': 'يو أو إم',
  'drawer.inventory.productDetails.gridHeader.sku': 'إس كيه يو',

  'drawer.inventory.finance.popuplateTaxOnTransactions': 'تعبئة الضريبة على المعاملات',
  'drawer.inventory.finance.popuplateTaxOnTransactions.desc': 'تضاف عند الفحص معدلات الضريبة في SO، فاتورة، PO إلخ',
  'drawer.inventory.finance.HSNCode': 'رمز شبكة التسوق المنزلي',
  'drawer.inventory.finance.HSNCode.placeholder': 'حدد رمز شبكة التسوق المنزلي',
  'drawer.inventory.finance.purchaseLedgerAccount': 'حساب دفتر الأستاذ الخاص بالشراء',
  'drawer.inventory.finance.purchaseLedgerAccount.placeholder': 'حدد حساب دفتر الأستاذ الخاص بالشراء',
  'drawer.inventory.finance.salesLedgerAccount': 'حساب دفتر الأستاذ الخاص بالمبيعات',
  'drawer.inventory.finance.salesLedgerAccount.placeholder': 'حدد دفتر الأستاذ الخاص بالمبيعات',
  'drawer.inventory.finance.tax': 'ضريبة',
  'drawer.inventory.finance.tax.placeholder': 'حدد معدل الضريبة',
  'drawer.inventory.image.productImg': 'حدد صورة السلعة',
  'drawer.inventory.image.productImg.upload': 'تحميل',

  'drawer.inventory.alert.lowStockLevel': 'مستوى إنخفاض المخزون',
  'drawer.inventory.alert.lowStockLevel.desc': 'أكتب كم سلعة بقيت حتى تبدأ تلقي التنبيه لإنخفاض المخزون',
  'drawer.inventory.alert.lowStockLevel.placeholder': 'أدخل مستوى إنخفاض المخزون',
  'drawer.inventory.alert.velocityAlert': 'تنبيه السرعة',
  'drawer.inventory.alert.velocityAlert.desc': 'تنبيه إرتفاع مفاجئ بالمبيعات',
  'drawer.inventory.alert.reorderLevel': 'مستوى إعادة الطلب',
  'drawer.inventory.alert.reorderLevel.desc': 'أكتب كم سلعة بقيت حتى دفعتك لإعادة الطلب',
  'drawer.inventory.alert.reorderLevel.placeholder': 'أكتب مستوى إعادة الطلب',
  'drawer.inventory.alert.outOfStockAlert': 'تنبيه نفاد المخزون',
  'drawer.inventory.alert.outOfStockAlert.desc': 'نبه إذا ما نفد المخزون',
  'drawer.inventory.alert.aboveThresholdLevel': 'فوق مستوى الحد الأدنى',
  'drawer.inventory.alert.aboveThresholdLevel.desc': 'نبهني إذا كان المخزون المتوقع فوق المستوى الأقصى للمخزون',
  'drawer.inventory.alert.aboveThresholdLevel.placeholder': 'أدخل فوق المستوى الأقصى للمخزون',

  'drawer.inventory.confirmation.title': 'موافق',
  'drawer.inventory.confirmation.text': 'هل أنت متأكد بأنك تريد إيجاد السلعة؟',
  'drawer.inventory.validation.productName': 'يطلب إسم السلعة',
  'drawer.inventory.validation.productNameLenght': '4 أحرف على الأقل مطلوبة',
  'drawer.inventory.validation.duplicateAttribute': 'تم اختيار الخاصية للتو',
  'drawer.inventory.validation.defaultHSN': 'الرجاء اختيار رمز HSN قبل إضافة الضريبة على التبادلات',
  'drawer.inventory.validation.defaultTax': 'الرجاء اختيار الضريبة قبل فحص إضافة الضريبة على التبادلات',
  'drawer.inventory.validation.purchasePrice': 'يطلب سعر الشراء للمخزون الحالي لتقييم المخزون',

  'drawer.customer.title': 'معلومات عن العميل',
  'drawer.customer.companyName': 'إسم الشركة',
  'drawer.customer.companyName.required': 'يطلب أسم الشركة ',
  'drawer.customer.companyName.placeholder': 'أدخل إسم الشركة',
  'drawer.customer.paymentTerm': 'شوط الدفع',
  'drawer.customer.paymentTerm.placeholder': 'حدد شرط الدفع',
  'drawer.customer.customerType': 'نوع العميل',
  'drawer.customer.customerType.placeholder': 'حدد نوع العميل',
  'drawer.customer.websiteUrl': 'عنوان مرقع ويب',
  'drawer.customer.websiteUrl.placeholder': 'أدخل عنوان موقع ويب',
  'drawer.customer.customerSource': 'مصدر العميل',
  'drawer.customer.customerSource.placeholder': 'حدد مصدر العميل',
  'drawer.customer.rating': 'تقييم شركتك',
  'drawer.customer.rating.placeholder': 'حدد درجة تقييم الشركة',
  'drawer.customer.industry': 'القطاع الصناعي',
  'drawer.customer.industry.placeholder': 'حدد القطاع الصناعي',
  'drawer.customer.relationshipSince': 'علاقة معها منذ',
  'drawer.customer.relationshipSince.placeholder': 'علاقة معها منذ تاريخ',
  'drawer.customer.address.streetName.placeholder': 'حدد الإسم',
  'drawer.customer.address.streetContd.placeholder': 'إسم الشارع',
  'drawer.customer.address.city.placeholder': 'مدينة',
  'drawer.customer.address.state.placeholder': 'ولاية/محافظة/ منطقالة',
  'drawer.customer.address.country.placeholder': 'حدد الدولة',
  'drawer.customer.address.zipCode.placeholder': 'رمز بريدي/منطقي',
  'drawer.customer.billingAddress': 'عنوان الفواتير',
  'drawer.customer.shippingAndBillingAddressSame': 'عنوان الشحن نفس عنوان الفواتير',
  'drawer.customer.contact.title.placeholder': 'حدد العنوان',
  'drawer.customer.contact.firstName.placeholder': 'الإسم الأول',
  'drawer.customer.contact.middleName.placeholder': 'الإسم الأوسط',
  'drawer.customer.contact.lastName.placeholder': 'الإسم الأخير',
  'drawer.customer.contact.callName.placeholder': 'اللقب',
  'drawer.customer.contact.phone.placeholder': 'هاتف',
  'drawer.customer.contact.mobile.placeholder': 'جوال',
  'drawer.customer.contact.faxNumber.placeholder': 'فاكس#',
  'drawer.customer.contact.email.placeholder': 'البريد الالكتروني',
  'drawer.customer.termName': 'إسم المصطلح',
  'drawer.customer.days': 'أيام',
  'drawer.customer.description': 'مواصفات',
  'drawer.customer.defaultPayment': 'دفع افتراضي',
  'drawer.customer.taxInfo.gstMechanism': 'ميكانيكية جي إس تي',
  'drawer.customer.taxInfo.gstMechanism.placeholder': 'حدد ميكانيكية جي إس تي',
  'drawer.customer.taxInfo.sez': 'الواقع في سيز',
  'drawer.customer.taxInfo.companyLogo': 'حدد شعار الشركة',
  'drawer.customer.taxInfo.companyLogo.upload': ' تنزيل',
  'drawer.customer.taxInfo.taxType': ' نوع الضريبة',
  'drawer.customer.taxInfo.taxNumber': 'Number',
  'drawer.customer.taxInfo.populateOnTransaction': 'ملء المعاملات',
  'drawer.customer.confirmation.title': 'موافق',
  'drawer.customer.confirmation.text': 'هل أنت متأكد بأنك تريد إيجاد العميل؟',
  'drawer.customer.validation.companyName': 'يطلب أسم الشركة ',
  'drawer.customer.validation.billingStreet1Name': 'إسم الشارع مطلوب',
  'drawer.customer.validation.billingCityName': 'إسم المدينة مطلوب',
  'drawer.customer.validation.billingStateName': 'إسم الولاية مطلوب',
  'drawer.customer.validation.shippingStreet1Name': 'إسم الشارع مطلوب',
  'drawer.customer.validation.shippingCityName': 'إسم المدينة مطلوب',
  'drawer.customer.validation.paymentTerm': 'شروط الدفع مطلوبة',
  'drawer.customer.validation.shippingStateName': 'إسم الولاية مطلوب',
  'drawer.supplier.title': 'معلومات عن المورد',
  'drawer.supplier.companyName': 'إسم المورد',
  'drawer.supplier.companyName.placeholder': 'أكتب إسم المورد',
  'drawer.supplier.paymentTerm': 'شوط الدفع',
  'drawer.supplier.paymentTerm.placeholder': 'حدد شرط الدفع',
  'drawer.supplier.supplierType': 'نوع المورد',
  'drawer.supplier.supplierType.placeholder': 'حدد نوع المورد',
  'drawer.supplier.websiteUrl': 'عنوان مرقع ويب',
  'drawer.supplier.websiteUrl.placeholder': 'أدخل عنوان موقع ويب',
  'drawer.supplier.supplierSource': 'مصدر المورد',
  'drawer.supplier.supplierSource.placeholder': 'حدد مصدر المورد',
  'drawer.supplier.rating.placeholder': 'حدد درجة تقييم الشركة',
  'drawer.supplier.industry': 'القطاع الصناعي',
  'drawer.supplier.industry.placeholder': 'حدد القطاع الصناعي',
  'drawer.supplier.relationshipSince': 'علاقة معها منذ',
  'drawer.supplier.relationshipSince.placeholder': 'علاقة معها منذ تاريخ',
  'drawer.supplier.address.streetName.placeholder': 'حدد الإسم',
  'drawer.supplier.address.streetContd.placeholder': 'إسم الشارع',
  'drawer.supplier.address.city.placeholder': 'مدينة',
  'drawer.supplier.address.state.placeholder': 'ولاية/محافظة/ منطقالة',
  'drawer.supplier.address.country.placeholder': 'حدد الدولة',
  'drawer.supplier.address.zipCode.placeholder': 'رمز بريدي/منطقي',
  'drawer.supplier.billingAddress': 'عنوان الفواتير',
  'drawer.supplier.shippingAndBillingAddressSame': 'عنوان الشحن نفس عنوان الفواتير',
  'drawer.supplier.contact.title.placeholder': 'حدد العنوان',
  'drawer.supplier.contact.firstName.placeholder': 'الإسم الأول',
  'drawer.supplier.contact.middleName.placeholder': 'الإسم الأوسط',
  'drawer.supplier.contact.lastName.placeholder': 'الإسم الأخير',
  'drawer.supplier.contact.callName.placeholder': 'اللقب',
  'drawer.supplier.contact.phone.placeholder': 'هاتف',
  'drawer.supplier.contact.mobile.placeholder': 'جوال',
  'drawer.supplier.contact.faxNumber.placeholder': 'فاكس# #',
  'drawer.supplier.contact.email.placeholder': 'البريد الالكتروني',
  'drawer.supplier.taxInfo.gstMechanism': 'ميكانيكية جي إس تي',
  'drawer.supplier.taxInfo.gstMechanism.placeholder': 'حدد ميكانيكية جي إس تي',
  'drawer.supplier.taxInfo.sez': 'الواقع في سيز',
  'drawer.supplier.taxInfo.taxType': ' نوع الضريبة',
  'drawer.supplier.taxInfo.taxNumber': 'عدد',
  'drawer.supplier.taxInfo.populateOnTransaction': 'ملء المعاملات',
  'drawer.supplier.taxInfo.companyLogo': 'حدد شعار الشركة',
  'drawer.supplier.taxInfo.companyLogo.upload': ' تنزيل',
  'drawer.supplier.confirmation.title': 'موافق',
  'drawer.supplier.confirmation.text': 'هل أنت متأكد بأنك تريد إيجاد المورد؟',
  'drawer.supplier.validation.companyName': 'يطلب أسم الشركة ',
  'drawer.supplier.validation.billingStreet1Name': 'إسم الشارع مطلوب',
  'drawer.supplier.validation.billingCityName': 'إسم المدينة مطلوب',
  'drawer.supplier.validation.billingStateName': 'إسم الولاية مطلوب',
  'drawer.supplier.validation.shippingStreet1Name': 'إسم الشارع مطلوب',
  'drawer.supplier.validation.shippingCityName': 'إسم المدينة مطلوب',
  'drawer.supplier.validation.shippingStateName': 'إسم الولاية مطلوب',
  'drawer.ledger.title': 'معلومات عن دفتر الأستاذ',
  'add': 'عنوان',
  'yes': 'نعم',
  'no': 'لا',
  'addRow': 'مجال للعنوان',
  'addUpdateData': 'هل أنت متأكد بأنك تريد إيجاد أو تجديد العنوان؟',
  'createSalesOrder': 'هل أنت متأكد بأنك تريد إيجاد أوطلب المبيعات؟',
  'customer.address.delete.confirmation': 'هل أنت متأكد أنك تريد حذف هذا العنوان؟',
  'deleteItem.confirmation': 'هل أنت متأكد بأنك تريد حذف (قيمة)؟',
  'updateItem.confirmation': 'هل أنت متأكد بأنك تريد تجديد (قيمة)؟',
  'addItem.confirmation': 'هل أنت متأكد بأنك تريد إضافة (قيمة)؟ ',
  'setItem.confirmation': 'هل أنت متأكد أنك تريد أن تحدد؟',
  'addItem.validation': '{قيمة} مطلوبة',
  'addItem.text.paymentTerm': 'شروط الدفع',
  'addItem.text.customerSource': 'مصدر العميل',
  'addItem.text.customerType': 'نوع العميل',
  'addItem.text.industry': 'القطاع الصناعي',
  'addItem.text.supplierSource': 'مصدر المورد',
  'addItem.text.supplierType': 'نوع المورد',
  'addItem.text.soSource': 'SO Source',
  'addItem.text.productCategory': 'صنف السلعة',
  'addItem.text.productBrand': 'علامة تجارية السلعة',
  'addItem.text.productManufacturer': 'شركة مصنعة السلعة',
  'addItem.text.uom': 'يو أو إم',
  'addItem.text.department': 'قسم',
  'addItem.text.warehouse': 'ويرهاوس',
  'addItem.text.project': 'مشروع',
  'addItem.text.billingAddress': 'عنوان الفواتير',
  'addItem.text.shippingAddress': 'عنوان الشحن',
  'addItem.text.relationshipBillingAddress': 'عنوان الفواتير',
  'addItem.text.relationshipShippingAddress': 'عنوان الشحن',
  'addItem.text.employee': 'موظف',
  'addItem.text.contact': 'اتصال',
  'addItem.text.priceList': 'قائمة الأسعار',
  'addItem.text.documentName': 'إسم الوثيقة',
  'addItem.text.newTax': 'ضريبة جديدة',
  'addItem.text.attribute': 'خاصية',
  'addItem.text.rfqStatus': 'حالة طلب عرض أسعار',
  'addItem.text.rfqPriceCode': 'رمز سعر طلب عرض أسعار',
  'addItem.text.salutation': 'تحية',
  'addItem.text.activityStatus': 'حالة النشاط',
  'addItem.text.activityPriority': 'نشاط ذو الأولوية',
  'addItem.text.activityType': 'نوع الأولوية',
  'addItem.text.carrierName': 'إسم الناقل',
  'addItem.text.freightType': 'نوع الشحن',
  'addItem.text.expenseType': 'نوع التكلفة',
  'addItem.text.shippingInstruction': 'إرشادات عن الشحن',
  'addItem.text.jobTitle': 'لقب وظيفي',
  'addItem.text.taxIdentification': 'تعريف ضريبي',
  'addItem.text.qualityCheck': 'تحقق من الجودة',
  'addItem.text.userStatus': 'وضع المستخدم',
  'addItem.text.actualDeliveryDate': 'موعد حقيقي للتوصيل',
  'addItem.text.incoterm': 'إنكوترم',
  'addItem.text.salesComplaintType': 'نوع شكوى المبيعات',
  'addItem.text.statusName': 'Status Name',
  'salesInvoice.cashSale.checkbox.text': ' بيع نقدي',
  'salesInvoice.pricelist.placeholder': 'حدد قائمة الأسعار',
  'salesInvoice.retailPrice.placeholder': 'تطبيق سعر التجزئة',
  'salesInvoice.priceType.placeholder': 'حدد نوع السعر',
  'salesInvoice.customer.placeholder': 'حدد العميل',
  'salesInvoice.billingAddress.placeholder': 'حدد عنوان الفواتير',
  'salesInvoice.currenctFormat.placeholder': 'حدد تنسيق العملة',
  'salesInvoice.project.placeholder': 'حدد المشاريع',
  'salesInvoice.taxName.placeholder': 'حدد إسم الضريبة',
  'salesInvoice.expense.placeholder': 'حدد التلكفة',
  'drawer.invoice.dropdown.placeholder': 'حدد قائمة الأسعار',
  'drawer.invoice.cashPurchase': 'شراء نقدي',
  'drawer.invoice.invoiceDate': 'تاريخ الفاتورة',
  'drawer.invoice.invoiceDueDate': 'موعد محدد للفاتورة',
  'drawer.invoice.projectDropdown': 'مراجعة المشروع',
  'drawer.invoice.dropdown.taxName': 'إسم ضريبة',
  'drawer.invoice.dropdown.expense': 'تكلفة',
  'warehouse.dropdown.placeholder': 'حدد المستودع',
  'project.label': 'مشروع',
  'salesOrder.creation.date': 'تاريخ إس أو',
  'sidebar.menuItem.dashboard': 'لوحة التحكم',
  'sidebar.menuItem.inboundDelivery': 'توصيل داخلي',
  'sidebar.menuItem.purchase': 'شراء',
  'sidebar.menuItem.purchaseRequest': 'طلب الشراء',
  'sidebar.menuItem.RFQ': 'آر إف قيو',
  'sidebar.menuItem.purchaseOrder': 'طلب الشراء',
  'sidebar.menuItem.purchaseInvoice': 'فاتورة الشراء',
  'sidebar.menuItem.payment': 'دفع',
  'sidebar.menuItem.debitMemo': 'مذكرة مدينة',
  'sidebar.menuItem.expense': 'تكلفة',
  'sidebar.menuItem.sales': 'مبيعات',
  'sidebar.menuItem.salesQuote': 'تسعير مبيعات',
  'sidebar.menuItem.salesOrder': 'طلب مبيعات',
  'sidebar.menuItem.salesInvoice': 'فاتورة مبيعات',
  'sidebar.menuItem.inquiry': 'استفسار',
  'sidebar.menuItem.customerPayment': 'دفع',
  'sidebar.menuItem.creditMemo': 'مذكرة ائتمان',
  'sidebar.menuItem.salesSupport': 'دعم المبيعات',
  'sidebar.menuItem.package': 'حزمة',
  'sidebar.menuItem.shipment': 'شحن',
  'sidebar.menuItem.customerExpense': 'تكلفة',
  'sidebar.menuItem.customers': 'عملاء',
  'sidebar.menuItem.suppliers': 'موردون',
  'sidebar.menuItem.inventory': 'مستودعات',
  'sidebar.menuItem.products': 'سلع',
  'sidebar.menuItem.warehouse': 'ويرهاوس',
  'sidebar.menuItem.myWarehouse': 'مخزوني',
  'sidebar.menuItem.stockSummary': 'خلاصة المخزون',
  'sidebar.menuItem.stockAdjustment': 'تسوية المخزون',
  'sidebar.menuItem.agedStock': 'مخزونات قديمة',
  'sidebar.menuItem.productPrice': 'سعر السلعة',
  'sidebar.menuItem.priceList': 'قائمة الأسعار',
  'sidebar.menuItem.extras': 'إضافات',
  'sidebar.menuItem.category': 'صنف',
  'sidebar.menuItem.manufactures': 'شركات مصنعة',
  'sidebar.menuItem.brands': 'علامات تجارية',
  'sidebar.menuItem.uom': 'يو أو إم',
  'sidebar.menuItem.finance': 'تمويل',
  'sidebar.menuItem.leadgerAccounts': 'حسابات دفتر الأستاذ',
  'sidebar.menuItem.balanceSheet': 'ميزانية عمومية',
  'sidebar.menuItem.profitLoss': 'أرباح و خسائر',
  'sidebar.menuItem.trialBalance': 'ميزان المراجعة',
  'sidebar.menuItem.openingBalance': 'رصيد افتتاحي',
  'sidebar.menuItem.journals': 'مجلات',
  'sidebar.menuItem.businessExpense': 'تكلفة تجارية',
  'sidebar.menuItem.bankReconciliation': 'تسويات مصرفية',
  'sidebar.menuItem.taxes': 'ضرائب',
  'sidebar.menuItem.company': 'شركة',
  'sidebar.menuItem.companyHierarcy': 'تسلسل هيكلي للشركة',
  'sidebar.menuItem.employee': 'موظف',
  'sidebar.menuItem.financialYears': 'سنوات مالية',
  'sidebar.menuItem.costCenter': 'مركز التكلفة',
  'sidebar.menuItem.reports': 'تقارير',
  'sidebar.menuItem.settings': 'إعدادات',
  'sidebar.menuItem.lockScreen': 'قفل الشاسة',
  'sidebar.menuItem.integrations': 'تكامل',
  'sidebar.menuItem.salesChannels': 'قنوات المبيعات',
  'sidebar.menuItem.publicProducts': 'سلع شائعة',
  'sidebar.menuItem.payments': 'دفعات',
  'sidebar.menuItem.shipping': 'شحن',
  'sidebar.menuItem.accounting': 'إجراء الحساب',
  'header.menuItem.salesHead.label': 'مبيعات',
  'header.menuItem.customer.label': 'عميل',
  'header.menuItem.salesQuote.label': 'تسعير مبيعات',
  'header.menuItem.salesInquiry.label': 'استفسار',
  'header.menuItem.salesOrder.label': 'طلب مبيعات',
  'header.menuItem.salesInvoice.label': 'فاتورة مبيعات',
  'header.menuItem.creditMemo.label': 'مذكرة ائتمان',
  'header.menuItem.receivePayment.label': 'استلام المدفوعات',
  'header.menuItem.purchaseHead.label': 'شراء',
  'header.menuItem.supplier.label': 'مورد',
  'header.menuItem.rfq.label': 'آر إف قيو',
  'header.menuItem.purchaseRequest.label': 'طلب الشراء',
  'header.menuItem.purchaseOrder.label': 'طلب الشراء',
  'header.menuItem.purchaseInvoice.label': 'فاتورة الشراء',
  'header.menuItem.debitMemo.label': 'مذكرة مدينة',
  'header.menuItem.payInvoice.label': 'ادفع الفاتورة',
  'header.menuItem.inventoryHead.label': 'مستودعات',
  'header.menuItem.inventory.label': 'سلع المخزون',
  'header.menuItem.priceList.label': 'قائمة الأسعار',
  'header.menuItem.category.label': 'صنف',
  'header.menuItem.uom.label': 'يو أو إم',
  'header.menuItem.brands.label': 'علامات تجارية',
  'header.menuItem.manufacturer.label': 'شركة مصنعة',
  'header.menuItem.warehouse.label': 'ويرهاوس',
  'header.menuItem.financeHead.label': 'تمويل',
  'header.menuItem.ledgerAccount.label': 'حساب دفتر الأستاذ',
  'header.menuItem.costCenter.label': 'مركز التكلفة',
  'header.menuItem.tax.label': 'ضريبة',
  'header.menuItem.journal.label': 'مجلة',

  'customer.listing.tiles.tatalOrders.label': 'إجمالية طلبات',
  'customer.listing.tiles.openSalesOrder.label': 'طلبات مبيعات مفتوحة',
  'customer.listing.tiles.unpaidBalance.label': 'رصيد غير مدفوع',
  'customer.listing.tiles.salesRevenue.label': 'إيرادات المبيعات',
  'customer.listing.header.customerDetails': 'تفاصيل العميل',
  'customer.listing.header.contact': 'اتصال',
  'customer.listing.header.dueAmount': 'مبالغ مستحقة',
  'customer.listing.header.openOrder': 'أوامر افتتاحية',
  'customer.listing.header.unpaidInvoices': 'فواتير غير مدفوعة',
  'customer.listing.header.paidInvoices': 'فواتير مدفوعة',
  'customer.listing.header.paymentTerm': 'شوط الدفع',
  'customer.listing.header.action': 'اتخاذ إجراء',
  'customer.listing.button.newCustomer.level': 'عميل جديد',
  'customer.listing.importCustomer.modal.title': 'استيراد عميل',
  'customer.listing.buttonMenu.importCustomer': 'استيراد عميل',

  'customer.listing.totalOrder.header.soNumber': 'أمر المبيعات#',
  'customer.listing.totalOrder.header.soDate': 'تاريخ إس أو',
  'customer.listing.totalOrder.header.soAmount': 'مبالغ أمر المبيعات',
  // 'customer.listing.totalOrder.header.status': 'حالة',
  'customer.listing.totalOrder.header.priority': 'اولوية',
  'customer.listing.totalOrder.header.soPerson': 'شخص أمر المبيعات',

  'customer.listing.openOrder.header.soNumber': 'أمر المبيعات#',
  'customer.listing.openOrder.header.soDate': 'تاريخ إس أو',
  'customer.listing.openOrder.header.soAmount': 'مبالغ أمر المبيعات',
  // 'customer.listing.openOrder.header.status': 'حالة',
  'customer.listing.openOrder.header.priority': 'اولوية',
  'customer.listing.openOrder.header.soPerson': 'شخص أمر المبيعات',

  'customer.listing.unPaidInvoice.header.invoiceNumber': 'فاتورة#',
  'customer.listing.unPaidInvoice.header.amount': 'مبلغ الفاتورة',
  'customer.listing.unPaidInvoice.header.paymentAmount': 'مبلغ الدفع',
  'customer.listing.unPaidInvoice.header.dueAmount': 'مبالغ مستحقة',
  'customer.listing.unPaidInvoice.header.invoiceDate': 'تاريخ الفاتورة',
  'customer.listing.unPaidInvoice.header.invoiceDueDate': 'موعد محدد للفاتورة',
  'customer.listing.unPaidInvoice.header.paymentTerm': 'شوط الدفع',
  // 'customer.listing.unPaidInvoice.header.status': 'حالة',

  'customer.listing.paidInvoice.header.invoiceNumber': 'فاتورة#',
  'customer.listing.paidInvoice.header.amount': 'مبلغ الفاتورة',
  'customer.listing.paidInvoice.header.paymentAmount': 'مبلغ الدفع',
  'customer.listing.paidInvoice.header.invoiceDate': 'تاريخ الفاتورة',
  // 'customer.listing.paidInvoice.header.status': 'حالة',

  'customer.salesQuote.listing.header.sqNo': 'تسعير#',
  'customer.salesQuote.listing.header.rfqNumber': 'طلب عرض الأسعار#',
  'customer.salesQuote.listing.header.customer': 'عميل',
  'customer.salesQuote.listing.header.amount': 'مبلغ',
  'customer.salesQuote.listing.header.priority': 'اولوية',
  'customer.salesQuote.listing.header.refNumber': 'مراجعة',
  'customer.salesQuote.listing.header.date': 'تاريخ',
  'customer.salesQuote.listing.header.systemStatus': 'حالة النظام',
  'customer.salesQuote.listing.header.qtyOnSO': 'To SO',
  'customer.salesQuote.listing.header.rfqExpirationDate': 'تاريخ إنهاء العرض',
  'customer.salesQuote.listing.header.isInquiryConversion': 'محادثة استفسارية',
  'customer.salesQuote.listing.header.remarksInternal': 'أقاويل (داخلية)',
  'customer.salesQuote.listing.header.remarksCustomer': 'أقاويل',

  'customer.salesOrder.listing.header.soNo': 'أمر المبيعات#',
  'customer.salesOrder.listing.header.customer': 'عميل',
  'customer.salesOrder.listing.header.quoteNumber': 'تسعير#',
  'customer.salesOrder.listing.header.rfqNumber': 'طلب عرض الأسعار#',
  'customer.salesOrder.listing.header.systemStatus': 'حالة النظام',
  'customer.salesOrder.listing.header.amount': 'مبلغ',
  'customer.salesOrder.listing.header.refNumber': 'مراجعة',
  'customer.salesOrder.listing.header.date': 'تاريخ',
  'customer.salesOrder.listing.header.qtyInvoiced': 'تم إعداد الفاتورة',
  'customer.salesOrder.listing.header.qtyPacked': 'معباه',
  'customer.salesOrder.listing.header.qtyShipped': 'تم الشحن',
  'customer.salesOrder.listing.header.qtyOnPR': 'الطلبات المتأخرة',
  'customer.salesOrder.listing.header.qtyOnQuote' : 'يقتبس',
  'customer.salesOrder.listing.header.deliveryDate': 'تاريخ التوصيل',
  // 'customer.salesOrder.listing.header.status': 'حالة',
  'customer.salesOrder.listing.header.paymentTerm': 'شروط الدفع',

  'customer.package.listing.header.packageNo': 'تعبئة#',
  'customer.package.listing.header.soNo': 'أمر المبيعات#',
  'customer.package.listing.header.customer': 'عميل',
  'customer.package.listing.header.date': 'تاريخ التعبئة',
  'customer.package.listing.header.qtyPacked': 'كمية معبأة',
  'customer.package.listing.header.systemStatus': 'حالة النظام',
  'customer.package.listing.header.qcStatus': 'حالة التحقق من الجودة',

  'customer.shipment.listing.header.shipmentNo': 'شحنة#',
  'customer.shipment.listing.header.carrierName': 'إسم الناقل',
  'customer.shipment.listing.header.trackingNumber': 'رقم التتبع',
  'customer.shipment.listing.header.shipmentDate': 'تاريخ الشحنة',
  'customer.shipment.listing.header.expectedDeliveryDate': 'تاريخ توصيل متوقع',
  'customer.shipment.listing.header.qtyShipped': 'كمية مشحونة',
  'customer.shipment.listing.header.stockUpdated': 'تم تجديد المخزون',
  'customer.shipment.listing.header.totalPackage': 'إجمالي حزمة',
  'customer.shipment.listing.header.comments': 'تعليقات',
  'customer.shipment.listing.header.consigneeName': 'اسم الراسل',
  'customer.shipment.listing.header.actualDeliveyDate': 'موعد حقيقي للتوصيل',
  'customer.shipment.listing.header.customerRemarks': 'تعليقات العملاء',
  'customer.shipment.listing.header.freightType': 'نوع الشحن',

  'customer.salesInvoice.listing.header.invoiceNo': 'فاتورة#',
  'customer.salesInvoice.listing.header.soNo': 'أمر المبيعات#',
  'customer.salesInvoice.listing.header.customer': 'عميل',
  'customer.salesInvoice.listing.header.quoteNumber': 'تسعير#',
  'customer.salesInvoice.listing.header.rfqNumber': 'طلب عرض الأسعار#',
  'customer.salesInvoice.listing.header.invoiceAmount': 'مبلغ الفاتورة',
  'customer.salesInvoice.listing.header.paymentAmount': 'Paid',
  'customer.salesInvoice.listing.header.dueAmount': 'مبالغ مستحقة',
  'customer.salesInvoice.listing.header.paymentTerm': 'شوط الدفع',
  'customer.salesInvoice.listing.header.invoiceDate': 'تاريخ الفاتورة',
  'customer.salesInvoice.listing.header.invoiceDueDate': 'تاريخ محدد',
  'customer.salesInvoice.listing.header.systemStatus': 'حالة النظام',
  'customer.salesInvoice.listing.header.action': 'اتخاذ إجراء',
  'customer.salesInvoice.listing.header.isCashInvoice': 'فاتورة مبلغ',
  'customer.salesInvoice.listing.header.isProformaConversion': 'الفاتورة المحولة',

  'customer.customerExpense.listing.header.expenseNo': 'تكلفة#',
  'customer.customerExpense.listing.header.customer': 'عميل',
  'customer.customerExpense.listing.header.refNumber': 'مراجعة',
  'customer.customerExpense.listing.header.expenseDate': 'تاريخ التكلفة',
  'customer.customerExpense.listing.header.amount': 'مبلغ',
  'customer.customerExpense.listing.header.systemStatus': 'حالة النظام',

  'customer.creditMemo.listing.header.creditMemoNo': 'مذكرة مدينة#',
  'customer.creditMemo.listing.header.customer': 'عميل',
  'customer.creditMemo.listing.header.transactionDate': 'تاريخ التحويل',
  'customer.creditMemo.listing.header.amount': 'مبلغ',
  'customer.creditMemo.listing.header.systemStatus': 'حالة النظام',

  'customer.customerPayment.listing.header.customer': 'عميل',
  'customer.customerPayment.listing.header.paymentNo': 'دفع المبلغ#',
  'customer.customerPayment.listing.header.invoiceNo': 'فاتورة#',
  'customer.customerPayment.listing.header.soNo': 'أمر المبيعات#',
  'customer.customerPayment.listing.header.quoteNumber': 'تسعير#',
  'customer.customerPayment.listing.header.rfqNumber': 'طلب عرض الأسعار#',
  'customer.customerPayment.listing.header.paymentAmount': 'مبلغ الدفع',
  'customer.customerPayment.listing.header.paymentDate': 'تاريخ دفع المبلغ',
  'customer.customerPayment.listing.header.systemStatus': 'حالة النظام',

  'supplier.listing.openOrder.header.poNumber': 'أمر الشراء#',
  // 'supplier.listing.openOrder.header.status': 'حالة',
  'supplier.listing.openOrder.header.poAmount': 'مبلغ أمر الشراء',
  'supplier.listing.openOrder.header.poDate': 'تاريخ أمر الشراء',

  'supplier.listing.totalOrder.header.poNumber': 'أمر الشراء#',
  // 'supplier.listing.totalOrder.header.status': 'حالة',
  'supplier.listing.totalOrder.header.poAmount': 'مبلغ أمر الشراء',
  'supplier.listing.totalOrder.header.poDate': 'تاريخ أمر الشراء',

  'supplier.listing.unPaidInvoice.header.invoiceNumber': 'فاتورة#',
  'supplier.listing.unPaidInvoice.header.amount': 'مبلغ الفاتورة',
  'supplier.listing.unPaidInvoice.header.paymentAmount': 'مبلغ الدفع',
  'supplier.listing.unPaidInvoice.header.dueAmount': 'مبالغ مستحقة',
  'supplier.listing.unPaidInvoice.header.invoiceDate': 'تاريخ الفاتورة',
  'supplier.listing.unPaidInvoice.header.invoiceDueDate': 'موعد محدد للفاتورة',
  'supplier.listing.unPaidInvoice.header.paymentTerm': 'شوط الدفع',
  // 'supplier.listing.unPaidInvoice.header.status': 'حالة',

  'supplier.listing.paidInvoice.header.invoiceNumber': 'فاتورة#',
  'supplier.listing.paidInvoice.header.amount': 'مبلغ الفاتورة',
  'supplier.listing.paidInvoice.header.paymentAmount': 'مبلغ الدفع',
  'supplier.listing.paidInvoice.header.dueAmount': 'مبالغ مستحقة',
  'supplier.listing.paidInvoice.header.invoiceDate': 'تاريخ الفاتورة',
  'supplier.listing.paidInvoice.header.invoiceDueDate': 'موعد محدد للفاتورة',
  'supplier.listing.paidInvoice.header.paymentTerm': 'شوط الدفع',
  // 'supplier.listing.paidInvoice.header.status': 'حالة',

  'supplier.debitMemo.listing.header.debitMemoNo': 'مذكرة مدين#',
  'supplier.debitMemo.listing.header.supplier': 'مورد',
  'supplier.debitMemo.listing.header.date': 'تاريخ',
  'supplier.debitMemo.listing.header.amount': 'مبلغ',
  'supplier.debitMemo.listing.header.systemStatus': 'حالة النظام',

  'supplier.supplierExpense.listing.header.expenseNo': 'تكلفة#',
  'supplier.supplierExpense.listing.header.supplier': 'مورد',
  'supplier.supplierExpense.listing.header.refNumber': 'مراجعة',
  'supplier.supplierExpense.listing.header.expenseDate': 'تاريخ التكلفة',
  'supplier.supplierExpense.listing.header.amount': 'مبلغ',
  'supplier.supplierExpense.listing.header.systemStatus': 'حالة النظام',

  'supplier.purchaseInvoice.listing.header.invoiceNo': 'فاتورة#',
  'supplier.purchaseInvoice.listing.header.poNo': 'امر الشراء#',
  'customer.purchaseInvoice.listing.header.rfqNumber': 'طلب عرض الأسعار#',
  'supplier.purchaseInvoice.listing.header.supplier': 'مورد',
  'supplier.purchaseInvoice.listing.header.invoiceAmount': 'مبلغ الفاتورة',
  'supplier.purchaseInvoice.listing.header.paymentAmount': 'مدفوع',
  'supplier.purchaseInvoice.listing.header.dueAmount': 'مبالغ مستحقة',
  'supplier.purchaseInvoice.listing.header.paymentTerm': 'شوط الدفع',
  'supplier.purchaseInvoice.listing.header.invoiceDate': 'تاريخ الفاتورة',
  'supplier.purchaseInvoice.listing.header.invoiceDueDate': 'تاريخ محدد',
  'supplier.purchaseInvoice.listing.header.systemStatus': 'حالة النظام',
  'supplier.purchaseInvoice.listing.header.action': 'اتخاذ إجراء',

  'supplier.listing.tiles.tatalOrders.label': 'إجمالية طلبات',
  'supplier.listing.tiles.openSalesOrder.label': 'طلبات مبيعات مفتوحة',
  'supplier.listing.tiles.unpaidBalance.label': 'رصيد غير مدفوع',
  'supplier.listing.tiles.salesRevenue.label': 'إيرادات المبيعات',
  'supplier.listing.header.supplierDetails': 'تفاصيل المورد',
  'supplier.listing.header.contact': 'اتصل',
  'supplier.listing.header.dueAmount': 'مبالغ مستحقة',
  'supplier.listing.header.openOrder': 'أوامر افتتاحية',
  'supplier.listing.header.unpaidInvoices': 'فواتير غير مدفوعة',
  'supplier.listing.header.paidInvoices': 'فواتير مدفوعة',
  'supplier.listing.header.paymentTerm': 'شوط الدفع',
  'supplier.listing.header.action': 'اتخاذ إجراء',
  'supplier.listing.button.newSupplier.level': 'مورد جديد',
  'supplier.listing.buttonMenu.importSuppliers': 'استيراد الموردين',
  'supplier.listing.importSuppliers.modal.title': 'استيراد الموردين',

  'supplier.supplierPayment.listing.header.supplier': 'مورد',
  'supplier.supplierPayment.listing.header.paymentNo': 'دفع المبلغ#',
  'supplier.supplierPayment.listing.header.invoiceNo': 'فاتورة#',
  'supplier.supplierPayment.listing.header.poNo': 'امر الشراء#',
  'customer.supplierPayment.listing.header.rfqNumber': 'طلب عرض الأسعار#',
  'supplier.supplierPayment.listing.header.paymentAmount': 'مبلغ الدفع',
  'supplier.supplierPayment.listing.header.invoiceAmount': 'مبلغ الفاتورة',
  'supplier.supplierPayment.listing.header.paymentDate': 'تاريخ دفع المبلغ',
  'supplier.supplierPayment.listing.header.systemStatus': 'حالة النظام',

  'supplier.purchaseOrder.listing.header.poNo': 'امر الشراء#',
  'supplier.purchaseOrder.listing.header.qtyRecieved': 'كمية واردة',
  'supplier.purchaseOrder.listing.header.supplier': 'مورد',
  'supplier.purchaseOrder.listing.header.amount': 'مبلغ',
  'supplier.purchaseOrder.listing.header.refNumber': 'مراجعة',
  'supplier.purchaseOrder.listing.header.projectName': 'إسم المشروع',
  'supplier.purchaseOrder.listing.header.rfqConversion': 'محادة آر إف قيو',
  'supplier.purchaseOrder.listing.header.rfqNumber': 'طلب عرض الأسعار#',
  'supplier.purchaseOrder.listing.header.expectedDeliveryDate': 'تاريخ توصيل متوقع',
  'supplier.purchaseOrder.listing.header.date': 'تاريخ',
  'supplier.purchaseOrder.listing.header.paymentTerm': 'شوط الدفع',
  'supplier.purchaseOrder.listing.header.systemStatus': 'حالة النظام',
  'supplier.purchaseOrder.listing.header.qtyInvoiced': 'كمية واردة في الفاتورة',

  'supplier.purchaseRequest.listing.header.purchaseRequestNo': 'طلب الشراء#',
  'supplier.purchaseRequest.listing.header.requester': 'طالب',
  'supplier.purchaseRequest.listing.header.orderPriority': 'اولوية',
  'supplier.purchaseRequest.listing.header.department': 'قسم',
  'supplier.purchaseRequest.listing.header.date': 'تاريخ',
  'supplier.purchaseRequest.listing.header.expirationDate': 'تاريخ الإنتهاء',
  'supplier.purchaseRequest.listing.header.reason': 'سبب',
  'supplier.purchaseRequest.listing.header.owner': 'مالك',
  'supplier.purchaseRequest.listing.header.systemStatus': 'حالة النظام',
  'supplier.purchaseRequest.listing.header.qtyOnRFQ': 'كمبة عند طلب عرض الإسعار',
  'supplier.purchaseRequest.listing.header.qtyOnPO': 'كمية عند أمر الشراء',

  'supplier.rfq.listing.header.rfqNo': 'طلب عرض الأسعار#',
  'supplier.rfq.listing.header.rfqDate': 'تاريخ طلب عرض الأسعار',
  'supplier.rfq.listing.header.supplier': 'مورد',
  'supplier.rfq.listing.header.priority': 'اولوية',
  'supplier.rfq.listing.header.prNumber': 'طلب الشراء#',
  'supplier.rfq.listing.header.prDate': 'تاريخ طلب الشراء',
  'supplier.rfq.listing.header.prExpirationDate': 'تاريخ انتهاء طلب الشراء',
  'supplier.rfq.listing.header.requestingDepartment': 'قسم مطالبة',
  'supplier.rfq.listing.header.project': 'مشروع',
  'supplier.rfq.listing.header.systemStatus': 'حالة النظام',

  'modal.common.warehouse.warehouseName': 'إسم المخزون',

  'modal.customer.payInvoice.paymentNumber': 'دفع المبلغ#',
  'modal.customer.payInvoice.selectCustomer': 'عميل',
  'modal.customer.payInvoice.customerName': 'إسم العميل',
  'modal.customer.payInvoice.paymentDate': 'تاريخ دفع المبلغ',
  'modal.customer.payInvoice.invoiceNumber': 'فاتورة#',
  'modal.customer.payInvoice.paymentMode': 'طريقة الدفع',
  'modal.customer.payInvoice.paymentModeDetails': 'تفاصيل طريقة الدفع',
  'modal.customer.payInvoice.bankAccount': 'حساب بنكي',
  'modal.customer.payInvoice.amount': 'مبلغ',
  'modal.customer.payInvoice.customerValidation': 'يطلب العميل',
  'modal.customer.payInvoice.paymentDateValidation': 'يطلب تاريخ الدفع',
  'modal.customer.payInvoice.invoiceValidation': 'الفاتورة مطلوبة',
  'modal.customer.payInvoice.amountValidation': 'مبلغ الدفغ مطلوب',
  'modal.customer.payInvoice.amountOverValidation': 'يمكن أن يكون مبلغ الدفع أكثر من مبلغ واجب الأداء',
  'modal.customer.payInvoice.invoiceAmount': 'مبلغ الفاتورة',
  'modal.customer.payInvoice.invoiceAmountDue': 'فاتورة مبلغ واجت الأداء',
  'modal.customer.payInvoice.remarks': 'أقاويل',
  'modal.customer.payInvoice.project': 'مشروع',
  'modal.customer.payInvoice.saveConfirmation': 'هل أنت متأكد بأنك تريد حفظ الدفع؟',

  'modal.customer.payInvoice.selectCustomer.placeholder': 'حدد العميل',
  'modal.customer.payInvoice.paymentDate.placeholder': 'تاريخ دفع المبلغ',
  'modal.customer.payInvoice.invoiceNumber.placeholder': 'حدد الفاتورة',
  'modal.customer.payInvoice.paymentMode.placeholder': 'حدد طريقة الدفع',
  'modal.customer.payInvoice.paymentModeDetails.placeholder': 'أدخل تفاصيل طريقة الدفع',
  'modal.customer.payInvoice.placeholder': 'أدخل الميلغ',
  'modal.customer.payInvoice.remarks.placeholder': 'أقاويل',
  'modal.customer.payInvoice.bankAccount.placeholder': 'حدد الحساب البنكي',

  'modal.supplier.payInvoice.paymentNumber': 'دفع المبلغ#',
  'modal.supplier.payInvoice.selectSupplier': 'حدد المورد',
  'modal.supplier.payInvoice.supplierName': 'إسم المورد',
  'modal.supplier.payInvoice.paymentDate': 'تاريخ دفع المبلغ',
  'modal.supplier.payInvoice.invoiceNumber': 'فاتورة#',
  'modal.supplier.payInvoice.paymentMode': 'طريقة الدفع',
  'modal.supplier.payInvoice.paymentModeDetails': 'تفاصيل طريقة الدفع',
  'modal.supplier.payInvoice.bankAccount': 'حساب بنكي',
  'modal.supplier.payInvoice.amount': 'مبلغ',
  'modal.supplier.payInvoice.invoiceAmount': 'مبلغ الفاتورة',
  'modal.supplier.payInvoice.invoiceAmountDue': 'فاتورة مبلغ واجت الأداء',
  'modal.supplier.payInvoice.remarks': 'أقاويل',
  'modal.supplier.payInvoice.project': 'مشروع',
  'modal.supplier.payInvoice.saveConfirmation': 'Aهل أنت متأكد بأنك تريد حفظ الدفع؟',

  'modal.supplier.payInvoice.selectSupplier.placeholder': 'حدد المورد',
  'modal.supplier.payInvoice.paymentDate.placeholder': 'تاريخ دفع المبلغ',
  'modal.supplier.payInvoice.invoiceNumber.placeholder': 'حدد الفاتورة',
  'modal.supplier.payInvoice.paymentMode.placeholder': 'حدد طريقة الدفع',
  'modal.supplier.payInvoice.paymentModeDetails.placeholder': 'أدخل تفاصيل طريقة الدفع',
  'modal.supplier.payInvoice.placeholder': 'أدخل الميلغ',
  'modal.supplier.payInvoice.remarks.placeholder': 'أقاويل',
  'modal.supplier.payInvoice.bankAccount.placeholder': 'حدد الحساب البنكي',
  'modal.supplier.payInvoice.supplierValidation': 'المورد مطلوب',
  'modal.supplier.payInvoice.paymentDateValidation': 'يطلب تاريخ الدفع',
  'modal.supplier.payInvoice.invoiceValidation': 'الفاتورة مطلوبة',
  'modal.supplier.payInvoice.amountValidation': 'مبلغ الدفغ مطلوب',
  'modal.supplier.payInvoice.amountOverValidation': 'يمكن أن يكون مبلغ الدفع أكثر من مبلغ واجب الأداء',

  'modal.ImportSupplier.industry.placeholder': 'حدد القطاع الصناعي',
  'modal.ImportSupplier.supplierType.placeholder': 'حدد نوع المورد',
  'modal.ImportSupplier.supplierSources.placeholder': 'حدد مصدر المورد',
  'modal.ImportSupplier.paymentTerm.placeholder': 'حدد شرط الدفع',

  'product.listing.outOfStock.header.productDetails': 'تفاصيل المنتج',
  'product.listing.outOfStock.header.variant': 'البديل',
  'product.listing.outOfStock.header.sku': 'إس كيه يو',
  'product.listing.outOfStock.header.category': 'صنف',
  'product.listing.outOfStock.header.warehouse': 'ويرهاوس',
  'product.listing.outOfStock.header.currentStock': 'المخزون الحالي',

  'product.listing.reorderStock.header.productDetails': 'تفاصيل المنتج',
  'product.listing.reorderStock.header.variant': 'البديل',
  'product.listing.reorderStock.header.sku': 'إس كيه يو',
  'product.listing.reorderStock.header.category': 'صنف',
  'product.listing.reorderStock.header.warehouse': 'ويرهاوس',
  'product.listing.reorderStock.header.currentStock': 'المخزون الحالي',
  'product.listing.reorderStock.header.reorderStock': 'إعادة أمر المخزون',

  'product.listing.lowStock.header.productDetails': 'تفاصيل المنتج',
  'product.listing.lowStock.header.variant': 'البديل',
  'product.listing.lowStock.header.sku': 'إس كيه يو',
  'product.listing.lowStock.header.category': 'صنف',
  'product.listing.lowStock.header.warehouse': 'ويرهاوس',
  'product.listing.lowStock.header.currentStock': 'المخزون الحالي',
  'product.listing.lowStock.header.lowStock': 'إنخفاض المخزون',

  'product.listing.buttonMenu.importProducts': 'استيراد السلع',

  'package.packageDetail.soNo': 'أمر المبيعات#',
  'package.packageDetail.poNo': 'امر الشراء#',
  'package.packageDetail.refNo': 'مراجعة',
  'package.packageDetail.incoterm': 'إنكوترم',
  'package.packageDetail.packedBy': 'تم التعبئة:',
  'package.packageDetail.packageDate': 'تاريخ التعبئة',
  'package.productDetail.product': 'السلعة',
  'package.productDetail.variant': 'البديل',
  'package.productDetail.description': 'مواصفات',
  'package.productDetail.uom': 'يو أو إم',
  'package.productDetail.qtyOrdered': 'كمية تم طلبها',
  'package.productDetail.qtyPacked': 'كمية معبأة',
  'package.productDetail.qtyToPack': 'كمية تم تعبئتها',
  'package.productDetail.action': 'اتخاذ إجراء',
  'package.productDetail.length': 'طول',
  'package.productDetail.width': 'عرض',
  'package.productDetail.height': 'ارتفاع',
  'package.productDetail.weight': 'وزن',

  'supplier.debitMemo.form.confirmation': 'هل أنت متأكد بأنك تريد إيجادج مذكرة المدين؟',
  'supplier.debitMemo.form.gridHeader.product': 'السلعة',
  'supplier.debitMemo.form.gridHeader.description': 'مواصفات',
  'supplier.debitMemo.form.gridHeader.qty': 'كمية',
  'supplier.debitMemo.form.gridHeader.uom': 'يو أو إم',
  'supplier.debitMemo.form.gridHeader.hsn': 'شبكة التسوق المنزلي/ SAC',
  'supplier.debitMemo.form.gridHeader.rate': 'تقييم',
  'supplier.debitMemo.form.gridHeader.specialDiscount': 'خصم خاص (%)',
  'supplier.debitMemo.form.gridHeader.discount': 'خصم (%)',
  'supplier.debitMemo.form.gridHeader.tax': 'ضريبة (%)',
  'supplier.debitMemo.form.gridHeader.amount': 'مبلغ ',
  'supplier.debitMemo.form.gridHeader.action': 'اتخاذ إجراء',
  'supplier.debitMemo.form.button.addLine': 'مجال للعنوان',
  'supplier.debitMemo.form.button.clearAllLines': 'امح كل الخطوط',
  'supplier.debitMemo.form.internalRemarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'supplier.debitMemo.form.internalRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'supplier.debitMemo.form.supplierRemarks.label': 'تعليقات المورد',
  'supplier.debitMemo.form.supplierRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'supplier.debitMemo.form.checkbox.discount': 'خصم',
  'supplier.debitMemo.form.checkbox.tax': 'ضريبة',
  'supplier.debitMemo.form.checkbox.expense': 'تكلفة',
  'supplier.debitMemo.form.totalDiscount.label': 'خصم',
  'supplier.debitMemo.form.totalTax.label': 'ضريبة',
  'supplier.debitMemo.form.totalExpense.label': 'تكلفة',
  'supplier.debitMemo.form.totalExpenseAmount.label': 'مبلغ التكلفة',
  'supplier.debitMemo.form.subTotal.label': 'مجموع فرعي',
  'supplier.debitMemo.form.totalAmount.label': 'المبلغ الإجمالي',
  'supplier.debitMemo.form.supplier.label': 'مورد',
  'supplier.debitMemo.form.supplier.placeholder': 'حدد المورد',
  'supplier.debitMemo.form.supplierName.label': 'إسم المورد',
  'supplier.debitMemo.form.gstnIn.label': 'جي ستين',
  'supplier.debitMemo.form.pan.label': 'رقم الحساب الدائم',
  'supplier.debitMemo.form.phone.label': 'الهاتف',
  'supplier.debitMemo.form.email.label': 'إيميل',
  'supplier.debitMemo.form.debitMemoNumber.label': 'مذكرة مدين#',
  'supplier.debitMemo.form.debitMemoDate.label': 'تاريخ مذكرة الخصم',
  'supplier.debitMemo.form.updateInventory.label': 'قم بتجديد المخزون',

  'supplier.purchaseOrder.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد طلب الشراء؟',
  'supplier.purchaseOrder.form.priceListDropdown.placeholder': 'حدد قائمة الأسعار',
  'supplier.purchaseOrder.form.gridHeader.placeOfSupply': 'محل التوريد',
  'supplier.purchaseOrder.form.gridHeader.refNumber': 'مراجعة',
  'supplier.purchaseOrder.form.gridHeader.shippingAddress': 'عنوان الشحن',
  'supplier.purchaseOrder.form.gridHeader.paymentTerms': 'شروط الدفع',
  'supplier.purchaseOrder.form.gridHeader.product': 'السلعة',
  'supplier.purchaseOrder.form.gridHeader.description': 'مواصفات',
  'supplier.purchaseOrder.form.gridHeader.qty': 'كمية',
  'supplier.purchaseOrder.form.gridHeader.uom': 'يو أو إم',
  'supplier.purchaseOrder.form.gridHeader.hsn': 'شبكة التسوق المنزلي/ SAC',
  'supplier.purchaseOrder.form.gridHeader.rate': 'تقييم',
  'supplier.purchaseOrder.form.gridHeader.specialDiscount': 'خصم خاص (%)',
  'supplier.purchaseOrder.form.gridHeader.discount': 'خصم (%)',
  'supplier.purchaseOrder.form.gridHeader.tax': 'ضريبة (%)',
  'supplier.purchaseOrder.form.gridHeader.amount': 'مبلغ ',
  'supplier.purchaseOrder.form.gridHeader.action': 'اتخاذ إجراء',
  'supplier.purchaseOrder.form.button.addLine': 'مجال للعنوان',
  'supplier.purchaseOrder.form.button.clearAllLines': 'امح كل الخطوط',
  'supplier.purchaseOrder.form.internalRemarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'supplier.purchaseOrder.form.shippingInstructions.label': 'إرشادات عن الشحن',
  'supplier.purchaseOrder.form.internalRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'supplier.purchaseOrder.form.supplierRemarks.label': 'تعليقات المورد',
  'supplier.purchaseOrder.form.supplierRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'supplier.purchaseOrder.form.checkbox.discount': 'خصم',
  'supplier.purchaseOrder.form.checkbox.tax': 'ضريبة',
  'supplier.purchaseOrder.form.checkbox.expense': 'تكلفة',
  'supplier.purchaseOrder.form.totalDiscount.label': 'خصم',
  'supplier.purchaseOrder.form.totalTax.label': 'ضريبة',
  'supplier.purchaseOrder.form.totalExpense.label': 'تكلفة',
  'supplier.purchaseOrder.form.totalExpenseAmount.label': 'مبلغ التكلفة',
  'supplier.purchaseOrder.form.subTotal.label': 'مجموع فرعي',
  'supplier.purchaseOrder.form.totalAmount.label': 'المبلغ الإجمالي',
  'supplier.purchaseOrder.form.supplier.label': 'مورد',
  'supplier.purchaseOrder.form.supplierName.label': 'إسم المورد',
  'supplier.purchaseOrder.form.supplierName.placeholder': 'حدد المورد',
  'supplier.purchaseOrder.form.gstnIn.label': 'جي ستين',
  'supplier.purchaseOrder.form.pan.label': 'رقم الحساب الدائم',
  'supplier.purchaseOrder.form.phone.label': 'الهاتف',
  'supplier.purchaseOrder.form.email.label': 'إيميل',
  'supplier.purchaseOrder.form.poNumber.label': 'امر الشراء#',
  'supplier.purchaseOrder.form.rfqNumber.label': 'طلب عرض الأسعار#',
  'supplier.purchaseOrder.form.poDate.label': 'تاريخ طلب الشراء',
  'supplier.purchaseOrder.form.poDueDate.label': 'تاريخ محدد لطلب الشراء',
  'supplier.purchaseOrder.form.expectedDeliveryDate.label': 'تاريخ توصيل متوقع',
  'supplier.purchaseOrder.form.projectDropdown.label': 'مشروع',

  'supplier.inboundDelivery.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد التوصيل الداخلي؟',

  'supplier.rfq.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد طلب عرض الأسعار؟',
  'supplier.rfq.form.customer.label': 'عميل',
  'supplier.rfq.form.customer.placeholder': 'حدد العميل',
  'supplier.rfq.form.rfqNumber.label': 'طلب عرض الأسعار#',
  'supplier.rfq.form.prNumber.label': 'طلب الشراء#',
  'supplier.rfq.form.gridHeader.supplierQuoteNumber': 'تسعير المورد #',
  'supplier.rfq.form.gridHeader.customerRFQNumber': 'طلب عرض الأسعار من قبل العميل #',
  'supplier.rfq.form.gridHeader.refNumber': 'مراجعة',
  'supplier.rfq.form.gridHeader.requestingDepartment': 'قسم مطالبة',
  'supplier.rfq.form.gridHeader.project': 'مشروع',
  'supplier.rfq.form.gridHeader.purchaseType': 'نوع الشراء',
  'supplier.rfq.form.gridHeader.prRequestedBy': 'طالب',
  'supplier.rfq.form.gridHeader.salesPerson': 'مندوب المبيعات',
  'supplier.rfq.form.gridHeader.priority': 'اولوية',
  'supplier.rfq.form.gridHeader.product': 'السلعة',
  'supplier.rfq.form.gridHeader.description': 'مواصفات',
  'supplier.rfq.form.gridHeader.qty': 'كمية',
  'supplier.rfq.form.gridHeader.uom': 'يو أو إم',
  'supplier.rfq.form.gridHeader.action': 'اتخاذ إجراء',
  'supplier.rfq.form.button.addLine': 'مجال للعنوان',
  'supplier.rfq.form.button.clearAllLines': 'امح كل الخطوط',
  'supplier.rfq.form.internalRemarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'supplier.rfq.form.internalRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'supplier.rfq.form.supplierRemarks.label': 'أقاويل',
  'supplier.rfq.form.supplierRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'supplier.rfq.form.checkbox.discount': 'خصم',
  'supplier.rfq.form.checkbox.tax': 'ضريبة',
  'supplier.rfq.form.checkbox.expense': 'تكلفة',
  'supplier.rfq.form.totalDiscount.label': 'خصم',
  'supplier.rfq.form.totalTax.label': 'ضريبة',
  'supplier.rfq.form.totalExpense.label': 'تكلفة',
  'supplier.rfq.form.totalExpenseAmount.label': 'مبلغ التكلفة',
  'supplier.rfq.form.subTotal.label': 'مجموع فرعي',
  'supplier.rfq.form.totalAmount.label': 'المبلغ الإجمالي',
  'supplier.rfq.form.supplier.label': 'مورد',
  'supplier.rfq.form.supplierName.label': 'إسم المورد',
  'supplier.rfq.form.supplierName.placeholder': 'حدد المورد',
  'supplier.rfq.form.gstnIn.label': 'جي ستين',
  'supplier.rfq.form.pan.label': 'رقم الحساب الدائم',
  'supplier.rfq.form.companyName.label': 'شركة',
  'supplier.rfq.form.contact.label': 'اتصل',
  'supplier.rfq.form.billTo.label': 'فاتورة إلى',


  'customer.salesEnquiry.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد الاستفسار؟',
  'customer.salesEnquiry.form.customer.label': 'عميل',
  'customer.salesEnquiry.form.customer.placeholder': 'حدد العميل',
  'customer.salesEnquiry.form.salesEnquiryNumber.label': 'استفسار#',
  'customer.salesEnquiry.form.prNumber.label': 'طلب الشراء#',
  'customer.salesEnquiry.form.gridHeader.customerSalesEnquiryNumber': 'استفسار العميل #',
  'customer.salesEnquiry.form.gridHeader.refNumber': 'مراجعة',
  'customer.salesEnquiry.form.gridHeader.requestingDepartment': 'قسم مطالبة',
  'customer.salesEnquiry.form.gridHeader.project': 'مشروع',
  'customer.salesEnquiry.form.gridHeader.purchaseType': 'نوع الشراء',
  'customer.salesEnquiry.form.gridHeader.prRequestedBy': 'طالب',
  'customer.salesEnquiry.form.gridHeader.salesPerson': 'مندوب المبيعات',
  'customer.salesEnquiry.form.gridHeader.priority': 'اولوية',
  'customer.salesEnquiry.form.gridHeader.product': 'السلعة',
  'customer.salesEnquiry.form.gridHeader.description': 'مواصفات',
  'customer.salesEnquiry.form.gridHeader.qty': 'كميةيو أو إم',
  'customer.salesEnquiry.form.gridHeader.uom': 'يو أو إم',
  'customer.salesEnquiry.form.gridHeader.action': 'اتخاذ إجراء',
  'customer.salesEnquiry.form.button.addLine': 'مجال للعنوان',
  'customer.salesEnquiry.form.button.clearAllLines': 'امح كل الخطوط',
  'customer.salesEnquiry.form.internalRemarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'customer.salesEnquiry.form.internalRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط.',
  'customer.salesEnquiry.form.supplierRemarks.label': 'أقاويل',
  'customer.salesEnquiry.form.supplierRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط.',
  'customer.salesEnquiry.form.checkbox.discount': 'خصم',
  'customer.salesEnquiry.form.checkbox.tax': 'ضريبة',
  'customer.salesEnquiry.form.checkbox.expense': 'تكلفة',
  'customer.salesEnquiry.form.totalDiscount.label': 'خصم',
  'customer.salesEnquiry.form.totalTax.label': 'ضريبة',
  'customer.salesEnquiry.form.totalExpense.label': 'تكلفة',
  'customer.salesEnquiry.form.totalExpenseAmount.label': 'مبلغ التكلفة',
  'customer.salesEnquiry.form.subTotal.label': 'مجموع فرعي',
  'customer.salesEnquiry.form.totalAmount.label': 'المبلغ الإجمالي',
  'customer.salesEnquiry.form.supplier.label': 'مورد',
  'customer.salesEnquiry.form.supplierName.label': 'إسم المورد',
  'customer.salesEnquiry.form.supplierName.placeholder': 'حدد المورد',
  'customer.salesEnquiry.form.gstnIn.label': 'جي ستين',
  'customer.salesEnquiry.form.pan.label': 'رقم الحساب الدائم',
  'customer.salesEnquiry.form.companyName.label': 'شركة',
  'customer.salesEnquiry.form.contact.label': 'اتصل',
  'customer.salesEnquiry.form.billTo.label': 'فاتورة إلى',
  'customer.form.taxIdentification.label': 'هوية ضريبية',

  'supplier.purchaseRequest.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد طلب الشراء؟',
  'supplier.purchaseRequest.form.gridHeader.product': 'السلعة',
  'supplier.purchaseRequest.form.gridHeader.description': 'مواصفات',
  'supplier.purchaseRequest.form.gridHeader.qty': 'كمية',
  'supplier.purchaseRequest.form.gridHeader.uom': 'يو أو إم',
  'supplier.purchaseRequest.form.gridHeader.action': 'اتخاذ إجراء',
  'supplier.purchaseRequest.form.button.addLine': 'مجال للعنوان',
  'supplier.purchaseRequest.form.button.clearAllLines': 'امح كل الخطوط',
  'supplier.purchaseRequest.form.remarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'supplier.purchaseRequest.form.remarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'supplier.purchaseRequest.form.requestor.label': 'طالب',
  'supplier.purchaseRequest.form.department.label': 'قسم',
  // 'supplier.purchaseRequest.form.status.label': 'حالة',
  'supplier.purchaseRequest.form.owner.label': 'مالك',
  'supplier.purchaseRequest.form.purchaseRequestNumber.label': 'طلب الشراء#',
  'supplier.purchaseRequest.form.purchaseRequestDate.label': 'تاريخ طلب الشراء',
  'supplier.purchaseRequest.form.expirationDate.label': 'تاريخ الإنتهاء',
  'supplier.purchaseRequest.form.sendEmail.label': 'البريد الالكتروني إذا تم إيجاد طلب عرض الأسعار/ طلب الشراء ',
  'supplier.purchaseRequest.form.reason.label': 'سبب',
  'supplier.purchaseRequest.form.priority.label': 'اولوية',
  'supplier.purchaseRequest.form.refNumber.label': 'مراجعة',
  'supplier.purchaseRequest.form.refNumber.placeholder': 'أدخل مراجعة #',
  'supplier.purchaseRequest.form.projectDropdown.label': 'مشروع',
  'supplier.purchaseRequest.form.projectDropdown.placeholder': 'حدد المشروع',

  'supplier.purchaseinvoice.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد فاتورة الشراء؟',
  'supplier.purchaseinvoice.form.cashPurchase.label': 'شراء نقدي',
  'supplier.purchaseinvoice.form.priceListDropdown.placeholder': 'حدد قائمة الأسعار',
  'supplier.purchaseinvoice.form.gridHeader.placeOfSupply': 'محل التوريد',
  'supplier.purchaseOrder.form.gridHeader.supplierInvoiceNumber': 'فاتورة المورد #',
  'supplier.purchaseinvoice.form.gridHeader.refNumber': 'مراجعة',
  'supplier.purchaseinvoice.form.gridHeader.shippingAddress': 'عنوان الشحن',
  'supplier.purchaseinvoice.form.gridHeader.paymentTerms': 'شروط الدفع',
  'supplier.purchaseinvoice.form.gridHeader.product': 'السلعة',
  'supplier.purchaseinvoice.form.gridHeader.description': 'مواصفات',
  'supplier.purchaseinvoice.form.gridHeader.qty': 'كمية',
  'supplier.purchaseinvoice.form.gridHeader.uom': 'يو أو إم',
  'supplier.purchaseinvoice.form.gridHeader.hsn': 'شبكة التسوق المنزلي/ SAC',
  'supplier.purchaseinvoice.form.gridHeader.rate': 'تقييم',
  'supplier.purchaseinvoice.form.gridHeader.specialDiscount': 'خصم خاص (%)',
  'supplier.purchaseinvoice.form.gridHeader.discount': 'خصم (%)',
  'supplier.purchaseinvoice.form.gridHeader.tax': 'ضريبة (%)',
  'supplier.purchaseinvoice.form.gridHeader.amount': 'مبلغ ',
  'supplier.purchaseinvoice.form.gridHeader.action': 'اتخاذ إجراء',
  'supplier.purchaseinvoice.form.button.addLine': 'مجال للعنوان',
  'supplier.purchaseinvoice.form.button.clearAllLines': 'امح كل الخطوط',
  'supplier.purchaseinvoice.form.internalRemarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'supplier.purchaseinvoice.form.internalRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'supplier.purchaseinvoice.form.supplierRemarks.label': 'أقاويل',
  'supplier.purchaseinvoice.form.supplierRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط.',
  'supplier.purchaseinvoice.form.checkbox.discount': 'خصم',
  'supplier.purchaseinvoice.form.checkbox.tax': 'ضريبة',
  'supplier.purchaseinvoice.form.checkbox.expense': 'تكلفة',
  'supplier.purchaseinvoice.form.totalDiscount.label': 'خصم',
  'supplier.purchaseinvoice.form.totalTax.label': 'ضريبة',
  'supplier.purchaseinvoice.form.totalExpense.label': 'تكلفة',
  'supplier.purchaseinvoice.form.totalExpenseAmount.label': 'مبلغ التكلفة',
  'supplier.purchaseinvoice.form.subTotal.label': 'مجموع فرعي',
  'supplier.purchaseinvoice.form.totalAmount.label': 'المبلغ الإجمالي',
  'supplier.purchaseinvoice.form.supplier.label': 'مورد',
  'supplier.purchaseinvoice.form.supplierName.label': 'إسم المورد',
  'supplier.purchaseinvoice.form.supplierName.placeholder': 'حدد المورد',
  'supplier.purchaseinvoice.form.gstnIn.label': 'جي ستين',
  'supplier.purchaseinvoice.form.pan.label': 'رقم الحساب الدائم',
  'supplier.purchaseinvoice.form.phone.label': 'الهاتف',
  'supplier.purchaseinvoice.form.email.label': 'إيميل',
  'supplier.purchaseinvoice.form.invoiceNumber.label': 'فاتورة#',
  'supplier.purchaseinvoice.form.poNumber.label': 'امر الشراء#',
  'supplier.purchaseinvoice.form.idNumber.label': 'توصيل داخلي #',
  'supplier.purchaseinvoice.form.invoiceDate.label': 'تاريخ الفاتورة',
  'supplier.purchaseinvoice.form.invoiceDueDate.label': 'موعد محدد للفاتورة',
  'supplier.purchaseinvoice.form.projectDropdown.label': 'مشروع',

  'customer.creditMemo.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد مذكرة الائتمان؟',
  'customer.creditMemo.form.gridHeader.product': 'السلعة',
  'customer.creditMemo.form.gridHeader.description': 'مواصفات',
  'customer.creditMemo.form.gridHeader.qty': 'كمية',
  'customer.creditMemo.form.gridHeader.uom': 'يو أو إم',
  'customer.creditMemo.form.gridHeader.hsn': 'شبكة التسوق المنزلي/ SAC',
  'customer.creditMemo.form.gridHeader.rate': 'تقييم',
  'customer.creditMemo.form.gridHeader.specialDiscount': 'خصم خاص (%)',
  'customer.creditMemo.form.gridHeader.discount': 'خصم (%)',
  'customer.creditMemo.form.gridHeader.tax': 'ضريبة (%)',
  'customer.creditMemo.form.gridHeader.amount': 'مبلغ ',
  'customer.creditMemo.form.gridHeader.action': 'اتخاذ إجراء',
  'customer.creditMemo.form.button.addLine': 'مجال للعنوان',
  'customer.creditMemo.form.button.clearAllLines': 'امح كل الخطوط',
  'customer.creditMemo.form.internalRemarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'customer.creditMemo.form.internalRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'customer.creditMemo.form.customerRemarks.label': 'تعليقات العملاء',
  'customer.creditMemo.form.customerRemarks.placeholder': 'شكرا لعملك نحن نقدر عملك حقًا ، ونحن ممتنون للثقة التي وضعتها فينا. إذا كانت لديك أية استفسارات أو أسئلة ، فلا تتردد في الاتصال بنا.',
  'customer.creditMemo.form.checkbox.costCenter': 'مركز التكلفة',
  'customer.creditMemo.form.totalDiscount.label': 'خصم',
  'customer.creditMemo.form.costCenter.label': 'مركز التكلفة',
  'customer.creditMemo.form.addCostCenter.label': 'أضف مركز التكلفة',
  'customer.creditMemo.form.subTotal.label': 'مجموع فرعي',
  'customer.creditMemo.form.totalAmount.label': 'المبلغ الإجمالي',
  'customer.creditMemo.form.customer.label': 'عميل',
  'customer.creditMemo.form.customerName.label': 'إسم العميل',
  'customer.creditMemo.form.customer.placeholder': 'حدد العميل',
  'customer.creditMemo.form.priceList.placeholder': 'حدد قائمة الأسعار',
  'customer.creditMemo.form.gstnIn.label': 'جي ستين',
  'customer.creditMemo.form.pan.label': 'رقم الحساب الدائم',
  'customer.creditMemo.form.phone.label': 'هاتف',
  'customer.creditMemo.form.email.label': 'إيميل',
  'customer.creditMemo.form.creditMemoNumber.label': 'مذكرة مدينة#',
  'customer.creditMemo.form.creditMemoDate.label': 'تاريخ مذكرة الائتمان',
  'customer.creditMemo.form.creditMemoExpiry.label': 'تاريخ انتهاء المذكرة',
  'customer.creditMemo.form.updateInventory.label': 'قم بتجديد المخزون',

  'customer.salesInvoice.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد فاتورة المبيعات؟',
  'customer.salesInvoice.form.priceListDropdown.placeholder': 'حدد قائمة الأسعار',
  'customer.salesInvoice.form.gridHeader.placeOfSupply': 'محل التوريد',
  'customer.salesInvoice.form.gridHeader.customerPO': 'طلب شراء العميل #',
  'customer.salesInvoice.form.gridHeader.refNumber': 'مراجعة',
  'customer.salesInvoice.form.gridHeader.billingAddress': 'عنوان الفواتير',
  'customer.salesInvoice.form.gridHeader.shippingAddress': 'عنوان الشحن',
  'customer.salesInvoice.form.gridHeader.paymentTerms': 'شروط الدفع',
  'customer.salesInvoice.form.gridHeader.product': 'السلعة',
  'customer.salesInvoice.form.gridHeader.description': 'مواصفات',
  'customer.salesInvoice.form.gridHeader.qty': 'كمية',
  'customer.salesInvoice.form.gridHeader.qtyOrdered': 'كمية تم طلبها',
  'customer.salesInvoice.form.gridHeader.qtyInvoiced': 'كمية واردة في الفاتورة',
  'customer.salesInvoice.form.gridHeader.qtyToInvoice': 'كمية واردة في الفاتورة',
  'customer.salesInvoice.form.gridHeader.uom': 'يو أو إم',
  'customer.salesInvoice.form.gridHeader.hsn': 'شبكة التسوق المنزلي/ SAC',
  'customer.salesInvoice.form.gridHeader.rate': 'تقييم',
  'customer.salesInvoice.form.gridHeader.specialDiscount': 'خصم خاص (%)',
  'customer.salesInvoice.form.gridHeader.discount': 'خصم (%)',
  'customer.salesInvoice.form.gridHeader.tax': 'ضريبة (%)',
  'customer.salesInvoice.form.gridHeader.amount': 'مبلغ ',
  'customer.salesInvoice.form.gridHeader.stockNumber': 'مخزون #',
  'customer.salesInvoice.form.gridHeader.partNumber': 'جزء #',
  'customer.salesInvoice.form.gridHeader.origin': 'أصل',
  'customer.salesInvoice.form.gridHeader.hsCode': 'رمز التسوق المنزلي',
  'customer.salesInvoice.form.gridHeader.comment': 'ويرهاوس',
  'customer.salesInvoice.form.gridHeader.action': 'اتخاذ إجراء',
  'customer.salesInvoice.form.button.addLine': 'مجال للعنوان',
  'customer.salesInvoice.form.button.clearAllLines': 'امح كل الخطوط',
  'customer.salesInvoice.form.internalRemarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'customer.salesInvoice.form.internalRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'customer.salesInvoice.form.customerRemarks.label': 'تعليقات العملاء',
  'customer.salesInvoice.form.customerRemarks.placeholder': 'شكرا لعملك نحن نقدر عملك حقًا ، ونحن ممتنون للثقة التي وضعتها فينا. إذا كانت لديك أية استفسارات أو أسئلة ، فلا تتردد في الاتصال بنا.',
  'customer.salesInvoice.form.checkbox.discount': 'خصم',
  'customer.salesInvoice.form.checkbox.tax': 'ضريبة',
  'customer.salesInvoice.form.checkbox.expense': 'تكلفة',
  'customer.salesInvoice.form.totalDiscount.label': 'خصم',
  'customer.salesInvoice.form.totalTax.label': 'ضريبة',
  'customer.salesInvoice.form.taxName.label': 'إسم ضريبة',
  'customer.salesInvoice.form.totalExpense.label': 'تكلفة',
  'customer.salesInvoice.form.totalExpenseAmount.label': 'مبلغ التكلفة',
  'customer.salesInvoice.form.subTotal.label': 'مجموع فرعي',
  'customer.salesInvoice.form.totalAmount.label': 'المبلغ الإجمالي',
  'customer.salesInvoice.form.customer.label': 'عميل',
  'customer.salesInvoice.form.customerName.label': 'إسم العميل',
  'customer.salesInvoice.form.customer.placeholder': 'حدد العميل',
  'customer.salesInvoice.form.priceList.placeholder': 'حدد قائمة الأسعار',
  'customer.salesInvoice.form.priceType.placeholder': 'تطبيق سعر التجزئة',
  'customer.salesInvoice.form.gstnIn.label': 'جي ستين',
  'customer.salesInvoice.form.pan.label': 'رقم الحساب الدائم',
  'customer.salesInvoice.form.phone.label': 'هاتف',
  'customer.salesInvoice.form.email.label': 'إيميل',
  'customer.salesInvoice.form.loadFromShipment.label': 'فاتورة للشحن',
  'customer.salesInvoice.form.selectSalesOrder.label': 'أمر/أوامر المبيعات',
  'customer.salesInvoice.form.selectShipment.label': 'حدد الشحن',
  'customer.salesInvoice.form.invoiceNumber.label': 'فاتورة#',
  'customer.salesInvoice.form.soNumber.label': 'أمر المبيعات#',
  'customer.salesInvoice.form.invoiceDate.label': 'تاريخ الفاتورة',
  'customer.salesInvoice.form.invoiceDueDate.label': 'موعد محدد للفاتورة',
  'customer.salesInvoice.form.projectDropdown.label': 'مشروع',
  'customer.salesInvoice.form.transportData.label': 'تاريخ النقل',
  'customer.salesInvoice.form.bankDetails.label': 'تفاصيل البنك',

  'customer.salesOrder.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد أوطلب المبيعات؟',
  'customer.salesOrder.form.priceListDropdown.placeholder': 'حدد قائمة الأسعار',
  'customer.salesOrder.form.gridHeader.placeOfSupply': 'محل التوريد',
  'customer.salesOrder.form.gridHeader.deliveryMethod': 'طريقة التوصيل',
  'customer.salesOrder.form.gridHeader.soSource': 'مصدر إس أو',
  'customer.salesOrder.form.gridHeader.customerPO': 'طلب شراء العميل #',
  'customer.salesOrder.form.gridHeader.salesPerson': 'مندوب المبيعات',
  'customer.salesOrder.form.gridHeader.incoterm': 'إنكوترم',
  'customer.salesOrder.form.gridHeader.refNumber': 'مراجعة',
  'customer.salesOrder.form.gridHeader.priority': 'اولوية',
  'customer.salesOrder.form.gridHeader.billingAddress': 'عنوان الفواتير',
  'customer.salesOrder.form.gridHeader.shippingAddress': 'عنوان الشحن',
  'customer.salesOrder.form.gridHeader.paymentTerms': 'شروط الدفع',
  'customer.salesOrder.form.gridHeader.product': 'السلعة',
  'customer.salesOrder.form.gridHeader.description': 'مواصفات',
  'customer.salesOrder.form.gridHeader.qty': 'كمية',
  'customer.salesOrder.form.gridHeader.qtyOrdered': 'كمية تم طلبها',
  'customer.salesOrder.form.gridHeader.qtyInvoiced': 'كمية واردة في الفاتورة',
  'customer.salesOrder.form.gridHeader.qtyToInvoice': 'كمية واردة في الفاتورة',
  'customer.salesOrder.form.gridHeader.uom': 'يو أو إم',
  'customer.salesOrder.form.gridHeader.hsn': 'شبكة التسوق المنزلي/ SAC',
  'customer.salesOrder.form.gridHeader.rate': 'تقييم',
  'customer.salesOrder.form.gridHeader.specialDiscount': 'خصم خاص (%)',
  'customer.salesOrder.form.gridHeader.discount': 'خصم (%)',
  'customer.salesOrder.form.gridHeader.tax': 'ضريبة (%)',
  'customer.salesOrder.form.gridHeader.amount': 'مبلغ ',
  'customer.salesOrder.form.gridHeader.stockNumber': 'مخزون #',
  'customer.salesOrder.form.gridHeader.partNumber': 'جزء #',
  'customer.salesOrder.form.gridHeader.origin': 'أصل',
  'customer.salesOrder.form.gridHeader.hsCode': 'رمز التسوق المنزلي',
  'customer.salesOrder.form.gridHeader.comment': 'ويرهاوس',
  'customer.salesOrder.form.gridHeader.action': 'اتخاذ إجراء',
  'customer.salesOrder.form.button.addLine': 'مجال للعنوان',
  'customer.salesOrder.form.button.clearAllLines': 'امح كل الخطوط',
  'customer.salesOrder.form.internalRemarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'customer.salesOrder.form.internalRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'customer.salesOrder.form.customerRemarks.label': 'تعليقات العملاء',
  'customer.salesOrder.form.customerRemarks.placeholder': 'شكرا لعملك نحن نقدر عملك حقًا ، ونحن ممتنون للثقة التي وضعتها فينا. إذا كانت لديك أية استفسارات أو أسئلة ، فلا تتردد في الاتصال بنا.',
  'customer.salesOrder.form.checkbox.discount': 'خصم',
  'customer.salesOrder.form.checkbox.tax': 'ضريبة',
  'customer.salesOrder.form.checkbox.expense': 'تكلفة',
  'customer.salesOrder.form.totalDiscount.label': 'خصم',
  'customer.salesOrder.form.totalTax.label': 'ضريبة',
  'customer.salesOrder.form.taxName.label': 'إسم ضريبة',
  'customer.salesOrder.form.totalExpense.label': 'تكلفة',
  'customer.salesOrder.form.totalExpenseAmount.label': 'مبلغ التكلفة',
  'customer.salesOrder.form.subTotal.label': 'مجموع فرعي',
  'customer.salesOrder.form.totalAmount.label': 'المبلغ الإجمالي',
  'customer.salesOrder.form.warehouse.placeholder': 'ويرهاوس',
  'customer.salesOrder.form.customer.label': 'عميل',
  'customer.salesOrder.form.customerName.label': 'إسم العميل',
  'customer.salesOrder.form.customer.placeholder': 'حدد العميل',
  'customer.salesOrder.form.priceList.placeholder': 'حدد قائمة الأسعار',
  'customer.salesOrder.form.priceType.placeholder': 'تطبيق سعر التجزئة',
  'customer.salesOrder.form.gstnIn.label': 'جي ستين',
  'customer.salesOrder.form.pan.label': 'رقم الحساب الدائم',
  'customer.salesOrder.form.phone.label': 'Ph',
  'customer.salesOrder.form.email.label': 'إيميل',
  'customer.salesOrder.form.soNumber.label': 'أمر المبيعات#',
  'customer.salesOrder.form.deliveryDate.label': 'تاريخ التوصيل',
  'customer.salesOrder.form.quotationNumber.label': 'تسعير#',
  'customer.salesOrder.form.soDate.label': 'SO Date',
  'customer.salesOrder.form.projectDropdown.label': 'مشروع',
  'customer.salesOrder.form.documentDropdown.label': 'عنوان المستند',
  'customer.salesOrder.form.qualityCheck.label': 'تحقق من الجودة',
  'customer.salesOrder.form.updateStock.label': 'قم بتجديد المخزون',
  'customer.salesQuote.form.confirmation': 'هل أنت متأكد بأنك تريد إيجاد تسعير المبيعات؟',
  'customer.salesQuote.form.priceListDropdown.placeholder': 'حدد قائمة الأسعار',
  'customer.salesQuote.form.gridHeader.customerRFQ': 'عميل طلب عرض الأسعار#',
  'customer.salesQuote.form.gridHeader.refNumber': 'مراجعة',
  'customer.salesQuote.form.gridHeader.salesPerson': 'مندوب المبيعات',
  'customer.salesQuote.form.gridHeader.priority': 'اولوية',
  // 'customer.salesQuote.form.gridHeader.billingAddress': 'عنوان الفواتير',
  'customer.salesQuote.form.gridHeader.project': 'مشروع',
  'customer.salesQuote.form.gridHeader.product': 'السلعة',
  'customer.salesQuote.form.gridHeader.description': 'مواصفات',
  'customer.salesQuote.form.gridHeader.qty': 'كمية',
  'customer.salesQuote.form.gridHeader.qtyOrdered': 'كمية تم طلبها',
  'customer.salesQuote.form.gridHeader.qtyInvoiced': 'كمية واردة في الفاتورة',
  'customer.salesQuote.form.gridHeader.qtyToInvoice': 'كمية واردة في الفاتورة',
  'customer.salesQuote.form.gridHeader.uom': 'يو أو إم',
  'customer.salesQuote.form.gridHeader.hsn': 'شبكة التسوق المنزلي/ SAC',
  'customer.salesQuote.form.gridHeader.rate': 'تقييم',
  'customer.salesQuote.form.gridHeader.specialDiscount': 'خصم خاص (%)',
  'customer.salesQuote.form.gridHeader.discount': 'خصم (%)',
  'customer.salesQuote.form.gridHeader.tax': 'ضريبة (%)',
  'customer.salesQuote.form.gridHeader.amount': 'مبلغ ',
  'customer.salesQuote.form.gridHeader.stockNumber': 'مخزون #',
  'customer.salesQuote.form.gridHeader.partNumber': 'جزء #',
  'customer.salesQuote.form.gridHeader.origin': 'أصل',
  'customer.salesQuote.form.gridHeader.hsCode': 'رمز التسوق المنزلي',
  'customer.salesQuote.form.gridHeader.comment': 'ويرهاوس',
  'customer.salesQuote.form.gridHeader.action': 'اتخاذ إجراء',
  'customer.salesQuote.form.button.addLine': 'مجال للعنوان',
  'customer.salesQuote.form.button.clearAllLines': 'امح كل الخطوط',
  'customer.salesQuote.form.internalRemarks.label': 'تعليقات (للاستخدام الداخلي فقط)',
  'customer.salesQuote.form.internalRemarks.placeholder': 'يكون غير مرئي للمورد.للأغراض الداخلية فقط',
  'customer.salesQuote.form.customerRemarks.label': 'تعليقات العملاء',
  'customer.salesQuote.form.customerRemarks.placeholder': 'شكرا لعملك نحن نقدر عملك حقًا ، ونحن ممتنون للثقة التي وضعتها فينا. إذا كانت لديك أية استفسارات أو أسئلة ، فلا تتردد في الاتصال بنا.',
  'customer.salesQuote.form.checkbox.discount': 'خصم',
  'customer.salesQuote.form.checkbox.tax': 'ضريبة',
  'customer.salesQuote.form.checkbox.expense': 'تكلفة',
  'customer.salesQuote.form.totalDiscount.label': 'خصم',
  'customer.salesQuote.form.totalTax.label': 'ضريبة',
  'customer.salesQuote.form.taxName.label': 'إسم ضريبة',
  'customer.salesQuote.form.totalExpense.label': 'تكلفة',
  'customer.salesQuote.form.totalExpenseAmount.label': 'مبلغ التكلفة',
  'customer.salesQuote.form.subTotal.label': 'مجموع فرعي',
  'customer.salesQuote.form.totalAmount.label': 'المبلغ الإجمالي',
  'customer.salesQuote.form.warehouse.placeholder': 'ويرهاوس',
  'customer.salesQuote.form.customer.label': 'عميل',
  'customer.salesQuote.form.customerName.label': 'إسم العميل',
  'customer.salesQuote.form.rfqNumber.label': 'طلب عرض الأسعار#',
  'customer.salesQuote.form.rfqDate.label': 'تاريخ طلب عرض الأسعار',
  'customer.salesQuote.form.rfqExpirationDate.label': 'تم تقديم طلب عرض الشراء من',
  'customer.salesQuote.form.customer.placeholder': 'حدد العميل',
  'customer.salesQuote.form.priceList.placeholder': 'حدد قائمة الأسعار',
  'customer.salesQuote.form.priceType.placeholder': 'تطبيق سعر التجزئة',
  'customer.salesQuote.form.gstnIn.label': 'جي ستين',
  'customer.salesQuote.form.pan.label': 'رقم الحساب الدائم',
  'customer.salesQuote.form.phone.label': 'هاتف',
  'customer.salesQuote.form.email.label': 'إيميل',
  'customer.salesQuote.form.sqNumber.label': 'تسعير#',
  'customer.salesQuote.form.sqDate.label': 'تاريخ التسعير',
  'customer.salesQuote.form.projectDropdown.label': 'مشروع',

  'modal.warehouse.button.createWarehouse': 'إيجاد المخزون',
  'modal.warehouse.button.updateWarehouse': 'تجديد المخزون',
  'modal.warehouse.button.cancel': 'إلغاء',

  'modal.project.button.createProject': 'إيجاد المشروع',
  'modal.project.button.cancel': 'إلغاء',

  'modal.paymentTerm.button.create': 'إيجاد',
  'modal.paymentTerm.button.cancel': 'إلغاء',

  'modal.contact.button.createContact': 'إيجاد',
  'modal.contact.button.cancel': 'إلغاء',

  'modal.priceList.button.createPriceList': 'إيجاد',
  'modal.priceList.button.cancel': 'إلغاء',

  'modal.newTax.button.createTax': 'إيجاد',
  'modal.newTax.button.cancel': 'إلغاء',

  'modal.address.button.createAddress': 'إيجاد',
  'modal.address.button.cancel': 'إلغاء',
  'modal.address.button.ok': 'موافق',
  'finance.journal.newJournal.button.name': 'مجلة جديدة',

  'supplier.rfq.biddingSuppliers.rfqDetail.rfqNumber': 'طلب عرض الأسعار#',
  'supplier.rfq.biddingSuppliers.rfqDetail.customerName': 'عميل',
  'supplier.rfq.biddingSuppliers.rfqDetail.refNumber': 'مراجعة',
  'supplier.rfq.biddingSuppliers.rfqDetail.bidClosingDate': 'تاريخ إنهاء العرض',
  'supplier.rfq.biddingSuppliers.rfqDetail.rfqDate': 'تاريخ طلب عرض الأسعار',
  'supplier.rfq.biddingSuppliers.rfqDetail.priority': 'اولوية',
  // 'supplier.rfq.biddingSuppliers.rfqDetail.status': 'حالة',
  'supplier.rfq.biddingSuppliers.gridHeader.supplierName': 'إسم المورد',
  'supplier.rfq.biddingSuppliers.gridHeader.address': 'عنوان',
  'supplier.rfq.biddingSuppliers.gridHeader.contact': 'اتصل',
  'supplier.rfq.biddingSuppliers.gridHeader.rfqEmailed': 'تم ترسيل RFQ عبر البريد الالكتروني',
  'supplier.rfq.biddingSuppliers.gridHeader.documents': 'مستندات',
  'supplier.rfq.biddingSuppliers.gridHeader.comments': 'تعليقات',
  'supplier.rfq.biddingSuppliers.gridHeader.priceCode': 'رمز السعر',
  // 'supplier.rfq.biddingSuppliers.gridHeader.status': 'حالة',
  'supplier.rfq.biddingSuppliers.gridHeader.action': 'اتخاذ إجراء',

  'modal.settings.emailTemplate.transaction': 'تحويل',
  'modal.settings.emailTemplate.emailSubject': 'موضوع البريد',
  'modal.settings.emailTemplate.emailMessage': 'رسالة البريد الالكتروني',

  'modal.settings.remarksTemplate.transaction': 'تحويل',
  'modal.settings.remarksTemplate.message': 'رسالة',
  'modal.settings.remarksTemplate.footer': 'تذييل',

  'modal.settings.changePassword.currentPassword': 'كلمة السر الحالية',
  'modal.settings.changePassword.newPassword': 'أدخل كلمة السر',
  'modal.settings.changePassword.confirmPassword': 'تأكيد كلمة السر',
  'modal.settings.changePassword.currentPassword.placeholder': 'كلمة السر الحالية',
  'modal.settings.changePassword.newPassword.placeholder': 'أدخل كلمة السر',
  'modal.settings.changePassword.confirmPassword.placeholder': 'تأكيد كلمة السر',
  'modal.settings.changePassword.validation.currentPassword': 'كلمة السر الحالية مطلوبة',
  'modal.settings.changePassword.validation.newPassword': 'كلمة السر الجديدة مطلوبة',
  'modal.settings.changePassword.validation.confirmPassword': 'كلمة السر المثبتة لا تتطابق',

  'inventory.priceList.listing.header.name': 'إسم',
  'inventory.priceList.listing.header.description': 'مواصفات',
  'inventory.priceList.listing.header.percentage': 'نسبة مئوية',
  'inventory.priceList.listing.header.type': 'نوع',
  'inventory.priceList.listing.header.startDate': 'تاريخ البدء',
  'inventory.priceList.listing.header.endDate': 'تاريخ الانتهاء',
  'inventory.priceList.listing.header.action': 'اتخاذ إجراء',
  'inventory.priceList.listing.header.priceList': 'قائمة الأسعار',
  'inventory.priceList.listing.header.newPriceList': 'قائمة أسعار جديدة',
  'inventory.productPrice.listing.header.productPrice': 'سعر السلعة',

  'inventory.extras.category.listing.header.categoryName': 'إسم الصنف',
  'inventory.extras.category.listing.header.categoryDescription': 'مواصفات الصنف',
  'inventory.extras.category.listing.header.dateCreated': 'تاريخ الإيجاد',
  'inventory.extras.category.listing.header.totalProducts': 'السلع الإجمالية',
  'inventory.extras.category.listing.header.action': 'اتخاذ إجراء',
  'inventory.extras.category.listing.header.newCategory': 'صنف جديد',
  'inventory.extras.category.listing.header.productCategories': 'أصناف السلع',
  'inventory.extras.category.listing.subHeader.text': 'تساعد فئة المنتج في تنظيم المنتجات حسب سماتها. يمكن تصنيف مجموعة معينة من المنتجات ذات الصلة في فئة واحدة.',

  'inventory.extras.brand.listing.header.brandName': 'إسم العلامة التجارية',
  'inventory.extras.brand.listing.header.brandDescription': 'مواصفات العلامة التجارية',
  'inventory.extras.brand.listing.header.dateCreated': 'تاريخ الإيجاد',
  'inventory.extras.brand.listing.header.totalProducts': 'السلع الإجمالية',
  'inventory.extras.brand.listing.header.action': 'اتخاذ إجراء',
  'inventory.extras.brand.listing.header.newBrand': 'علامات تجارية جديدة',
  'inventory.extras.brand.listing.header.productBrands': 'علامات تجارية للمنتجات',
  'inventory.extras.brand.listing.subHeader.text': 'العلامة التجارية هي مصطلح يستخدم لتمييز المنتج من بائع لآخر. يمكن تحديد العلامة التجارية من خلال الشعارات أو الاسم التجاري أو العلامة التجارية.',

  'inventory.extras.manufacturer.listing.header.manufacturerName': 'إسم شركة مصنعة',
  'inventory.extras.manufacturer.listing.header.description': 'موصفات شركة مصنعة',
  'inventory.extras.manufacturer.listing.header.dateCreated': 'تاريخ الإيجاد',
  'inventory.extras.manufacturer.listing.header.totalProducts': 'السلع الإجمالية',
  'inventory.extras.manufacturer.listing.header.action': 'اتخاذ إجراء',
  'inventory.extras.manufacturer.listing.header.newManufacturer': 'شركة مصنعة جديدة',
  'inventory.extras.manufacturer.listing.header.productManufacturer': 'شركة مصنعة السلعة',
  'inventory.extras.manufacturer.listing.subHeader.text': 'الشركة المصنعة هي كيان يقوم بتصنيع البضائع لتلبية توقعات العملاء.',


  'inventory.extras.uom.listing.header.uom': 'يو أو إم',
  'inventory.extras.uom.listing.header.isDefault': 'افتراضي',
  'inventory.extras.uom.listing.header.description': 'مواصفات',
  'inventory.extras.uom.listing.header.dateCreated': 'تاريخ الإيجاد',
  'inventory.extras.uom.listing.header.action': 'اتخاذ إجراء',
  'inventory.extras.uom.listing.header.newUOM': 'UOM جديدة',
  'inventory.extras.uom.listing.subHeader.text': 'UOM مصطلح يستخدم لوحدة قياس المنتج',

  'inventory.warehouse.myWarehouse.listing.header.warehouseName': 'إسم المخزون',
  'inventory.warehouse.myWarehouse.listing.header.isPrimary': 'مخزون أساسي',
  'inventory.warehouse.myWarehouse.listing.header.totalItems': 'سلع إجمالية',
  'inventory.warehouse.myWarehouse.listing.header.warehouseAddress': 'عنوان المخزون',
  'inventory.warehouse.myWarehouse.listing.header.dateCreated': 'تاريخ الإيجاد',
  'inventory.warehouse.myWarehouse.listing.header.action': 'اتخاذ إجراء',
  'inventory.warehouse.myWarehouse.listing.header.warehouse': 'ويرهاوس',
  'inventory.warehouse.myWarehouse.listing.header.newWarehouse': 'مخزون جديد',

  'inventory.warehouse.agedStock.listing.header.product': 'السلعة',
  'inventory.warehouse.agedStock.listing.header.variant': 'البديل',
  'inventory.warehouse.agedStock.listing.header.sku': 'إس كيه يو',
  'inventory.warehouse.agedStock.listing.header.days': 'أيام',
  'inventory.warehouse.agedStock.listing.header.currentStock': 'المخزون الحالي',
  'inventory.warehouse.agedStock.listing.header.lastPurchasePrice': 'سعر الشراء الأخير',
  'inventory.warehouse.agedStock.listing.header.agedProducts': 'سلع قديمة',

  'inventory.warehouse.stockSummary.listing.header.product': 'السلعة',
  'inventory.warehouse.stockSummary.listing.header.sku': 'إس كيه يو',
  'inventory.warehouse.stockSummary.listing.header.category': 'صنف',
  'inventory.warehouse.stockSummary.listing.header.currentStock': 'المخزون الحالي',
  'inventory.warehouse.stockSummary.listing.header.qtyOnSO': 'كمية عند أمر البيع',
  'inventory.warehouse.stockSummary.listing.header.qtyAvailable': 'كمية متوفرة',
  'inventory.warehouse.stockSummary.listing.header.qtyOnPO': 'كمية عند أمر الشراء',
  'inventory.warehouse.stockSummary.listing.header.reorderLevel': 'مستوى إعادة الطلب',
  'inventory.warehouse.stockSummary.listing.header.lowStockLevel': 'مستوى إنخفاض المخزون',
  'inventory.warehouse.stockSummary.listing.warehouseFilter.placeholder': 'حدد المستودع',
  'inventory.warehouse.stockAdjustment.listing.header.stockSummary': 'خلاصة المخزون',

  'inventory.warehouse.stockAdjustment.listing.header.product': 'السلعة',
  'inventory.warehouse.stockAdjustment.listing.header.sku': 'إس كيه يو',
  'inventory.warehouse.stockAdjustment.listing.header.category': 'صنف',
  'inventory.warehouse.stockAdjustment.listing.header.currentStock': 'المخزون الحالي',
  'inventory.warehouse.stockAdjustment.listing.header.qtyOnSO': 'كمية عند أمر البيع',
  'inventory.warehouse.stockAdjustment.listing.header.qtyAvailable': 'كمية متوفرة',
  'inventory.warehouse.stockAdjustment.listing.header.qtyOnPO': 'كمية عند أمر الشراء',
  'inventory.warehouse.stockAdjustment.listing.header.action': 'اتخاذ إجراء',
  'inventory.warehouse.stockAdjustment.listing.header.stockAdjustment': 'تسوية المخزون',

  'inventory.warehouse.productPrice.listing.header.product': 'السلعة',
  'inventory.warehouse.productPrice.listing.header.sku': 'إس كيه يو',
  'inventory.warehouse.productPrice.listing.header.itemCode': 'رمز السلعة',
  'inventory.warehouse.productPrice.listing.header.description': 'مواصفات',
  'inventory.warehouse.productPrice.listing.header.category': 'صنف',
  'inventory.warehouse.productPrice.listing.header.purchasedPrice': 'سعر الشراء',
  'inventory.warehouse.productPrice.listing.header.wholesalePrice': 'سعر الجملة',
  'inventory.warehouse.productPrice.listing.header.retailPrice': 'سعر التجزئة',
  'inventory.warehouse.productPrice.listing.header.action': 'اتخاذ إجراء',

  'company.companyHierarchy.message' : 'تسلسل هيكلي للشركة مطلوب',
  'company.hierarchy.button.newCompanyHierarchy': 'تسلسل هيكلي جديد للشركة',
  'company.hierarchy.hierarchyName': ' إسم التسلسل الهيكلي',
  'company.hierarchy.hierarchyLevel': ' مستوى التسلسل الهيكلي',
  'company.hierarchy.hierarchyCode': ' رمز التسلسل الهيكلي',
  'company.hierarchy.hierarchyLevel.placeholder': 'حدد مستوى التسلسل الهيكلي',
  'company.hierarchy.hierarchyLevel.message': 'رمز مستوى التسلسل الهيكلي مطلوب',
  'company.hierarchy.description': ' مواصفات',
  'company.hierarchy.rootHierarchy': 'تسلسل هيكلي أساسي',
  'company.hierarchy.action': 'اتخاذ إجراء',
  'company.heirarchy.name.placeholder': 'أكتب إسم التسلسل الهيكلي',
  'company.heirarchy.level.name.placeholder': 'أكتب إسم مستوى التسلسل الهيكلي',
  'company.heirarchy.name.messgae': 'إسم التسلسل الهيكلي مطلوب',
  'company.heirarchy.level.name.message': 'إسم مستوى التسلسل الهيكلي مطلوب',

  'company.employee.name.placeholder': 'أدخل 3 أحرف من أسم الموظف',
  'company.employee.title.newEmployee': 'موظف جديد',
  'company.employee.firstName': 'الإسم الأول',
  'company.employee.lastName': 'الإسم الأخير',
  'company.employee.hierarchyName': 'إسم التسلسل الهيكلي',
  'company.employee.hierarchyCode': ' رمز التسلسل الهيكلي',
  'company.employee.jobTitle': 'لقب وظيفي',
  'company.employee.manager': 'مدير',
  'company.employee.companyHierarchy': 'تسلسل هيكلي للشركة',
  'company.employee.hierarchyLevel': 'مستوى التسلسل الهيكلي',
  'company.employee.phone': 'هاتف',
  'company.employee.email': 'إيميل',
  'company.employee.department': 'قسم',
  'common.addRemove': 'إضافة/حذف',
  'company.companySetup.accountingType': 'نوع إجراء الحسابات',
  'company.companySetup.financialYear': 'ترتيب السنة المالية',
  'company.companySetup.businessDetail.title': 'المزيد حول تجارتك',
  'company.companySetup.businessDetail.optionalFields': 'هذه مجالات خيارية',
  'company.companySetup.businessDetail.companySize': 'حجم الشركة',
  'company.companySetup.companySize.placeholder': 'حدد حجم الشركة',
  'company.companySetup.ledgerAccounts.title': 'المزيد حول تجارتك',
  'company.companySetup.ledgerAccounts.defaultLedger': 'حسابات دفتر الأستاذ الافتراضية (خياري)',
  'company.companySetup.ledgerAccounts.sales': 'مبيعات',
  'company.companySetup.ledgerAccounts.salesLedger.placeholder': 'حدد دفتر الأستاذ للمبيعات',
  'company.companySetup.ledgerAccounts.purchaseLedger.placeholder': 'حدد دفتر الأستاذ للشراء',
  'company.companySetup.ledgerAccounts.debtorsLedger.placeholder': 'حدد حساب المدينين',
  'company.companySetup.ledgerAccounts.creditorsLedger.placeholder': 'حدد حساب الدائنين',
  'company.companySetup.ledgerAccounts.customerDiscountLedger.placeholder': 'حدد حساب دفتر الأستاذ للعميل',
  'company.companySetup.ledgerAccounts.supplierDiscountLedger.placeholder': 'حدد دفتر الأستاذ للموردين',
  'company.companySetup.ledgerAccounts.salesoutputTaxLedger.placeholder': 'حدد دفتر الأستاذ لضريبة إنتاجية المبيعات',
  'company.companySetup.ledgerAccounts.purchaseInputTaxLedger.placeholder': 'حدد دفتر الأستاذ لضريبة مدخلات الشراء',
  'company.companySetup.ledgerAccounts.creditMemoTaxLedger.placeholder': 'حدد دفترالأستاذ لمذكرة حسابات الائتمان',
  'company.companySetup.ledgerAccounts.debitMemoLedger.placeholder': 'حدد دفترالأستاذ لمذكرة حسابات المدين',
  'company.companySetup.ledgerAccounts.customerExpenseLedger.placeholder': 'حدد دفترالأستاذ لتكلفة العميل',
  'company.companySetup.ledgerAccounts.supplierExpenseLedger.placeholder': 'حدد تكلفة المورد',
  'company.companySetup.ledgerAccounts.paymentRecieveLedger.placeholder': 'حدد دفترالأستاذ للمدفوعات المستلمة',
  'company.companySetup.ledgerAccounts.paymentMadeLedger.placeholder': 'حدد دفتر الأستاذ للمدفوعات المقدمة',
  'company.companySetup.ledgerAccounts.paymentMadeLedger.title': 'مدفوعات مقدمة',
  'company.companySetup.ledgerAccounts.paymentRecievedLedger.title': 'مدفوعات مستلمة',
  'company.companySetup.ledgerAccounts.supplierExpense.title': 'تكلفة المورد',
  'company.companySetup.ledgerAccounts.customerExpense.title': 'تكلفة العميل',
  'company.companySetup.ledgerAccounts.debitMemo.title': 'مذكرة مدينة',
  'company.companySetup.ledgerAccounts.creditMemo.title': 'مذكرة ائتمان',
  'company.companySetup.ledgerAccounts.purchaseInputTax.title': 'ضريبة مدخلات الشراء',
  'company.companySetup.ledgerAccounts.salesOutputTax.title': 'ضريبة إنتاجية المبيعات',
  'company.companySetup.ledgerAccounts.salesDiscount.title': ' خصم المورد',
  'company.companySetup.ledgerAccounts.customerDiscount.title': 'خصم العميل',
  'company.companySetup.ledgerAccounts.creditors.title': 'دائنون',
  'company.companySetup.ledgerAccounts.debtors.title': 'مدينون',
  'company.companySetup.ledgerAccounts.purchase.title': 'شراء',
  'company.companySetup.setupFinancial.setupFinYear.title': 'ترتيب السنة المالية',
  'company.companySetup.setupFinancial.finYearStartDate.title': 'تاريخ بدء السنة المالية',
  'company.companySetup.setupFinancial.finYearEndDate.title': 'تاريخ انتهاء السنة المالية',
  'company.companySetup.button.previous': 'سابق',
  'company.companySetup.button.next': 'لاحق',
  'company.companySetup.button.done': 'تم',
  'common.sales': 'مبيعات',
  'common.creditMemo': 'مذكرة ائتمان',
  'common.salesInvoice': 'فاتورة مبيعات',
  'common.proformaInvoice': ' فاتورة مبدئية ',
  'relationshipAddress.tooltip': 'هذا هو عنوان إرسال الفواتير لشركتك. يمكن إدارة عناوين إضافية من الإعداد> معلومات الشركة> صفحة العنوان',
  'relationshiptaxIdentification.placeholder': 'هوية (هويات) ضريبة',
  'contact.placeholder': 'الرجاء تحديد إسم جهة الاتصال',
  'billTo.placeholder': 'عنوان الفواتير',
  'taxIdentification.placeholder': 'هوية (هويات) ضريبة',
  'taxIdentification.tooltip': 'هوية ضريبية العميل',
  'documentTitle.tooltip': 'عنوان المستند سيظهر على بي دي إف و على التقارير إلخ',
  'uploadDocument.button': ' حمل المستندات',
  'activity.activityType': 'نوع الأولوية',
  'activity.assignedTo': 'معينة إلى ',
  'activity.dueDate': 'تاريخ محدد',
  'activity.closeDate': 'تاريخ الإغلاق',
  'common.dateCreated': 'تاريخ الإيجاد',
  'common.date.text': 'تاريخ',
  'activity.documents': 'مستندات',
  'activity.title': 'نشاط جديد',
  'activity.downloadPdf': 'حمل بي دي إف',
  'activity.exportExcel': ' تصدير إلى صيغة إكسيل',
  'activity.email': 'إيميل',
  'address.addressType': 'نوع العنوان',
  'address.streetName': 'إسم الشارع',
  'address.streetNameContd': 'عنوان الشارع (مسلسل)،',
  'address.city': 'مدينة',
  'address.state': 'ولاية',
  'address.zip': 'رمز بريدي',
  'common.contact': 'اتصل',
  'address.title': 'إدارة العنوان',
  'expense.customers': 'عملاء',
  'expense.lable': 'تكلفة',
  'expense.list': 'قائمة',
  'xero.tile': 'تحميل فاتورة Xero',
  'xero.invoice.slected.message': 'الفاتورة المحددة تم تحميلها فعلا. الرجاء الحذف والمحاولة لمرة ثانية',
  'xero.invoice.slected.before.message': 'الرجاء تحديد الفاتورة قبل التحميل',
  'updateInvoice.text': 'تحديث الفاتورة',
  'update.text': 'تحديث',
  'clone.text': 'استنساخ',
  'delete.text': 'حذف',
  'common.delete.confirmation': 'هل أنت متأكد بأنك تريد حذف هذا التحويل؟',
  'delete.invoice': 'حذف الفاتورة',
  'void.invoice': 'فاتورة باطلة',
  'update.user.status': 'تحديث وضع المستخدم',
  'template.standard.invoice': 'نموذج أساسي للفاتورة القياسية',
  'template.standard.invoice.multicurrency': 'نموذج أساسي لفاتورة متعددة العملات',
  'common.currency.placeholder': 'حدد العملة',
  'common.confirmation.clear': 'هل أنت متأكد بأنك تريد التصفية؟',
  'bankDetail.placeholder': 'تفاصيل البنك',
  'activity.confirmation': 'هل أنت متأكد بأنك تريد إيجاد النشاط؟',
  'address.primary.message': 'إذا كان هذا افتراضيا فهذا العنوان تكتب على حميع التعاملات الجديدة',
  'address.primary': 'عنوان رئيسي',
  'address.streetAddress1': 'عنوان الشارع 1',
  'address.streetAddress2': 'عنوان الشارع 2',
  'address.country': 'البلد',
  'contact.primary.message': 'بعد الفحص، هذا العنوان ستكتب على جميع التعاملات. يمكنك تغييره من صفحة التعاملات',
  'contact.primary': 'اتصال أساسي',
  'contact.title': 'إسم',
  'contact.text': 'اتصل',
  'manageContact.text': 'إدارة الاتصال',
  'addContact.text': 'أضف الاتصال',
  'addAddress.text': ' أضف العنوان',
  'contact.firstName': 'الإسم الأول',
  'contact.firstName.placeholder': 'أدخل الأسم الأول',
  'contact.firstName.message': 'الإسم الأول مطلوب',
  'contact.middleName': 'الإسم الأوسط',
  'contact.phone': 'هاتف',
  'contact.phone.placeholder': 'أدخل رقم الهاتف',
  'contact.middleName.placeholder': 'أدخل الإسم الأوسط',
  'contact.lastName': 'الإسم الأخير',
  'contact.lastName.placeholder': 'أدخل الإسم الأخير',
  'contact.lastName.message': 'الإسم الأخير مطلوب',
  'contact.departments': 'قسم',
  'contact.jobTitle': 'لقب وظيفي',
  'contact.jobTitle.placeholder': 'حدد اللقب الوظيفي',
  'contact.jobTitle.message': 'اللقب الوظيفي مطلوب',
  'contact.manager': 'مدير',
  'contact.manager.placeholder': 'اختيار المدير',
  'contact.manager.message': 'إسم المدير مطلوب',
  'contact.departments.placeholder': 'اختيار القسم',
  'contact.callName': 'اللقب',
  'contact.callName.placeholder': 'اختيار إسم المكالمة',
  'contact.workPhone': 'هاتف العمل',
  'contact.mobile': 'جوال',
  'contact.mobile.placeholder': 'أدخل رقم الجوال ',
  'contact.faxNumber': 'فاكس#',
  'contact.email': 'إيميل',
  'contact.email.placeholder': 'أدخل البريد الالكتروني',
  'contact.email.message': 'البريد الالكتروني مطلوب',
  'contact.manageContact': 'إدارة الاتصال',
  'contact.downloadPdf': 'تحميل بي دي إف',
  'contact.exportExcel': ' تصدير إلى صيغة إكسيل',
  'tiles.customerDue': 'واجبات تجاة العميل',
  'common.error.swr': 'شئ سار على نحو خاطئ',
  'common.error.invalidPath': 'مسار غير صالح',
  'tiles.supplierDue': 'واجبات تجاه المورد',
  'tile.balanceSheet': 'ميزانية عمومية',
  'tiles.pAndL': 'أرباح و خسائر',
  'common.purchase': 'شراء',
  'dashboard.topCustomerSales': 'أبرز العملاء من حيث المبيعات',
  'dashboard.topCustomerPurchase': 'أبرز الموردين من حيث الشراء',
  'dashboard.topSupplierPayment': 'أبرز الموردين من حيث دفع الواجبات',
  'dashboard.topCustomerPayment': ' أبرز العملاء من حيث دفع الواجبات',
  'dashboard.fasetesProduct': ' سلع أسرع الدوران',
  'company.companyHierarchy.title': 'تسلسل هيكلي للشركة',
  'company.companyHierarchy.confirmation.message': 'هل أنت متأكد بأنك تريد إيجاد التسلسل الهيكلي للشركة',
  'company.hierarchy.hierarchDescription': 'مواصفات التسلسل الهيكلي',
  'company.hierarchy.hierarchDescription.placeholder': 'أدخل مواصفات التسلسل الهيكلي',
  'company.hierarchy.parentHierarchy': ' التسلسل الرئيسي',
  'company.hierarchy.parentHierarchy.placeholder': 'اختيار التسلسل الهيكلي',
  'company.hierarchy.parentHierarchy.message': 'إسم التسلسل الهيكلي مطلوب',
  'company.hierarchy.rootHierarchy.text': 'هذا تسلسل هيكلي جذري',
  'company.employee.confitmation': 'هل أنت متأكد بأنك تريد إيجاد الموظف؟',
  'company.employee.text': 'موظف',
  'common.title.placeholder': 'حدد العنوان',
  'title.text': "لقب",
  'company.companyHierarchy.text': 'تسلسل هيكلي للشركة',
  'company.companyHierarchy.placeholder': 'اختيار تسلسل هرمي للشركة',
  'breadcrum.finance.balanceSheet.text': 'ميزانية عمومية',
  'breadcrum.finance.text': 'تمويل',
  'breadcrum.inquiry.text': 'استفسار',
  'finance.balanceSheetAsOn.text': '  ميزانية كما في',
  'particulars.text': 'مفردات',
  'notes.text': 'ملاحظات',
  'asOn.text': 'كما في',
  'equityAndLiabilities.text': 'رأس المال السهمي والخصوم',
  'a.text': 'أ',
  'shareHolderFund.text': 'صناديق المساهمين',
  'shareCapital.text': 'رأس مال الأسهم',
  'reserveAndSurplus.text': 'احتياطي و قيمة مضافة',
  'moneyRecievedShareWarrants.text': 'مبلغ مستلمة تجاة شهادات الأسهم',
  'nonCurrentLibilities.text': 'التزامات غير متداولة',
  'longTermBorrow.text': 'اقتراض طويل المدى',
  '(a).text': '(ا)',
  '(b).text': '(ب)',
  '(c).text': '(ج)',
  '(d).text': '(د)',
  'b.text': 'ب',
  'assets.text': 'أصول',
  'noncurrentAssets.text': 'أصول غير متداولة',
  'fixedAssets.text': 'أصول ثابتة',
  'tangibleAssets.text': ' (i)أصول ملموسة',
  'intangibleAssets.text': ' (ii)أصول غير ملموسة',
  'nonCurrentInvestMents.text': 'استثمار غير متداول',
  'longTermLoansAndAdvance': 'اقتراض طويل المدى وقروض',
  'otherNonCurrentAssets.text': 'أصول غير ملموسة أخرى',
  'currentAssets.text': 'أصول متداولة',
  'currentInvestment.text': 'استثمار جار',
  'inventories.text': 'مخزونات',
  'tradeRecievable.text': 'ذمم مدينة تجارية',
  'cashEquivalent.text': 'نقد ومكافئات النقدية',
  'shortTermAdvance.text': 'اقتراض قصير المدى وقروض',
  'inboundDelivery.selectPO':'الرجاء تحديد أمر الشراء',
  '(e).text': '(ه)',
  '(f).text': '(و)',
  'total.text': 'مجموع',
  'defferedLiablities.text': 'مطلوبات ضريبية مؤجلة',
  'otherLongTermLiabilities.text': 'خصوم طويلة المدى أخرى',
  'longTermProvision.text': 'مخصصات طويلة الأجل',
  'currentLibilities.text': 'خصوم جارية',
  'shortTermBorrowing.text': 'اقتراضات قصيرة الأجل',
  'tradePayable.text': 'تجارة مستحة الدفع',
  'otherCurrentLiabilities.text': 'خصوم جارية أخرى',
  'gstnumber.text': 'رقم ضريبة السلع والخدمات',
  'panNumber.text': 'رقم الحساب الدائم',
  'expenseDate.text': 'تاريخ التكلفة',
  'costCenter.text': '  مركز التكلفة',
  'referenceNumber.text': 'رقم مرجعي',
  'expenseType.text': 'نوع التكلفة',
  'amountTaxInclusive.text': 'مبلغ (شامل للضريبة)',
  'remarks.text': 'أقاويل',
  'remarks.placeholder': 'أقاويل',
  'expense.text': 'تكلفة',
  'list.text': 'قائمة',
  'newBusinessExpense.text': 'تكلفة التجارة الجديدة',
  'costCenterName.text': 'إسم مركز التكلفة',
  'costCenterNumber.text': 'رقم مركز التكلفة',
  'department.text': 'قسم',
  'finance.text': 'تمويل',
  'financeYear.text': 'سنوات مالية',
  'error.parseJson': 'حدث خطأ في تحويل الطلب',
  'common.error.processing.request': 'هناك مشكلة في إجراء الطلب. الرجاء المحاولة مرة أخرى',
  'aboutus.update.success': 'تم تحديث محتوى "عنا" بنجاح',
  'returnPolicy.update.success': 'تم تحديث محتوى سياسة إرجاع بنجاح',
  'mission.update.success': 'تم تحديث محتوى بيان المهمة بنجاح',
  'visionStatement.content.success': 'تم تحديث محتوى بيان الرؤية بنجاح',
  'shippingContent.update.success': 'تم تحديث محتوى الشحن المجاني بنجاح',
  'promotion.update.success': 'تم تحديث محتوى الترقيات بنجاح',
  'licence.updload.success': 'تم تحميل الرخصة بنجاح',
  'business.data.update.success': 'تم تحديث بيانات تجارية بنجاح',
  'testData.upload.success': 'تم تحميل بيانات اختبارية بنجاح',
  'user.already.assign.message': 'المستخدم معين فعلا لهذه الشركة',
  'company.access.successfully': 'تم منح إمكانية الوصول إلى الشركة بنجاح',
  'unable.revoke.access': 'عاجز عن استرجاع إمكانية الوصول',
  'access.revoked.success': 'تم استرجاع إمكانية الوصول بنجاح',
  'sales.invoice.approoved.success': 'تم تصديق فاتورة المبيعات بنجاح',
  'so.approoved.success': 'تم تصديق أمر المبيعات بنجاح',
  'actualDeliveryDate.update.success': 'تم تحديث التاريخ الفعلي للتوصيل بنجاح',
  'shipment.update.success': 'تم تحديث حالة الشحن بنجاح',
  'inventory.update.success': 'تم تحديث المحزون بنجاح',
  'conformity.update.success': 'تم تحديث شهادة التوافق بنجاح',
  'paymentDetail.update.success': 'تم تحديث بيانات الدفعات بنجاح',
  'account.create.trial.success': 'تم إنشاء حسابك بنجاح.تجربتك المجانية ساري المفعول لـ 14 يوما',
  'so.delete.success': 'تم حذف أمر المبيعات بنجاح',
  'so.cancel.success': 'تم إلغاء أمر المبيعات بنجاح',
  'so.reinitiate.success': 'تم إستعادة أمر المبيعات بنجاح',
  'product.not.exist': 'لا توجد السلعة  ',
  'stock.transfer.success': 'تم نقل المخزون بنجاح',
  'po.cancel.success': 'تم إلغاء أمر الشراء بنجاح',
  'po.delete.success': 'تم حذف أمر الشراء بنجاح',
  'po.unableTo.delete': 'لا يمكن حذف أمر الشراء إذ تم إيجاد مستند التوصيل الداخلي ',
  'po.unableTo.delete.dueToInvoice': 'لا يمكن حذف أمر الشراء إذ تم إعداد الفواتير',
  'tooltip.originalRate': 'هذا هو المعدل الأصلي',
  'tooltip.taIdentification': 'هذه هوية شركتك الضريبية',
  'tooltip.customerContact': 'اتصال العميل',
  'tooltip.customerAddress': 'عنوان العميل',
  'tooltip.generateCreditMemo': 'رقم مذكرة الائتمان المولدة بواسطة النظام الآلي',
  'tooltip.uploadToXero': 'تحميل الفاتورة إلى xero؟',
  'tooltip.referenceNumberAssign': 'الرقم المرجعي علامة تحديد تم تعيينها لأي عملية. يمكن استخدام هذا الرقم لربط أو إحالة إلى عملية التحويل الأخرى',
  'tooltip.companyBillingAddress': 'هذا هو عنوان الفواتير لشركتك. يمكن إدارة عناوين إضافية من الإعداد> معلومات الشركة> صفحة العنوان',
  'tooltip.generateSI': 'رقم فاتورة المبيعات المولدة بواسطة النظام الآلي',
  'tooltip.documentAppearInPdf': 'عنوان المستند سيظهر على بي دي إف و على التقارير إلخ',
  'tooltip.qualityChekSetting': 'إعدادات لفحص التحقق من الجودة  ',
  'tooltip.warehouseDetail': 'هذه تفاصيل مخزون العميل',
  'tooltip.generatePaymentNumber': 'رقم الدفع المولد بواسطة النظام الآلي',
  'tooltip.enableToCreateShipment': 'الرجاء الإتاحة لإيجاد الفاتورة من الشحن',
  'tooltip.transactionNumberRelevent': 'قد يكون إسم التعامل أو رقم التعامل المتصلة بهذا النشاط',
  'tooltip.communicationType': 'قد يكون نوع الاتصال من قبيل البريد الالكتروني، كوريير، فاكس إلخ',
  'tooltip.briefDescription': 'وصف موجز للاتصال',
  'tooltip.typeOfCommunication': 'قد يكون بريد الكتروني، أو فاكس أو العنوان البريدي الكامل',
  'tooltip.qualityChecklist': 'هذه حالة قائمة فحص التحقق من الجودة',
  'tooltip.isInvoiceUploadedToXero': 'تم تحميل الفاتورة إلى Xero؟',
  'tooltip.InqNumberToLocateInquiry': 'هذا رقم الاستفسار الذي قد تحدد من استفسار عميلك',
  'tooltip.generateSalesInquiryNum': 'فحص المبيعات المولد بواسطة النظام الآلي',
  'tooltip.internationalCommercial': 'المصطلحات التجارية الدولية سلسلة لمصطلحات تجارية محددة سابقا التي تحدد مسئوليات البائعين والمشتريين',
  'tooltip.generateSONumber': 'رقم SO المولد بواسطة النظام الآلي',
  'tooltip.qualityCheckList': 'قائمة فحص التحقق من الجودة',
  'tooltip.checkedPackage': 'بعد الفحص، سيتم عرض حزمات من أوامر المبيعات الأخرى ',
  'tooltip.consigneePerson': 'المراسل إليه شخص مسئول عن استلام الشحنة',
  'tooltip.rfqNoToLocate': 'هذا رقم طلب عرض الأسعار الذي يمكنك تحديده من طلب عرض الأسعار من عميلك',
  'tooltip.generateSQNumber': 'رقم إعطاء السعر المولد بواسطة النظام الآلي',
  'tooltip.taxDisplayOnPdf': 'إذا تم الاختيار سيتم عرض الرقم الضريبي المحدد على وثيقة بصيغة بي دي إف',
  'tooltip.searchHSN': 'البحث ممكن على رمز شبكة التسوق المنزلي',
  'tooltip.taxPopulateOnItemSelect': 'بعد الفحص، تضاف الضريبة بشكل آلي إلى الضريبة عندما يتم اختيار هذه السلعة',
  'tooltip.showTooltipMessage': 'هنا رسالة عرض تلميح الأدوات',
  'tooltip.category': 'هذا صنف',
  'tooltip.part': 'هذا جزء  #',
  'tooltip.manufacturer': 'هذه شركة مصنعة',
  'tooltip.sku': 'هذه وحدة حفظ المخزون',
  'tooltip.stokNumber': 'هذا مخزون #',
  'tooltip.article': 'هذه سلعة #',
  'tooltip.universalProduct': 'رمز المنتج العالمي رمز أكثر شيوعا متكون من 12 رقما',
  'tooltip.internationalArticle': 'رقم المادة الدولية رقم باركود فريد متكون من 13 رقما',
  'tooltip.internationalStandard': 'الرقم الدولي القياسي للكتاب باركود متكون من 13 رقما. وكثيرا ما يستعمل تنسيق الباركود على الكتب ومتمش مع أرقام  المادة الأوروبية.',
  'tooltip.recievingLowStock': 'أدخل عدد العناصر المتبقية حتى تبدأ في تلقي تنبيه انخفاض المخزون',
  'tooltip.fyEndDate': 'تاريخ انتهاء السنة المالية',
  'tooltip.department': 'قسم',
  // 'tooltip.status': 'حالة',
  'tooltip.journalNumber': 'رقم المجلة',
  'tooltip.narration': 'سرد',
  'tooltip.amount': 'مبلغ',
  'amount.text': 'مبلغ',
  'tooltip.date': 'تاريخ',
  'tooltip.startAcNumber': 'رقم بدء الحساب',
  'tooltip.endAcNumber': 'رقم انتهاء الحساب',
  'tooltip.categoryGrp': 'مجموعة الأصناف',
  'tooltip.systemAccount': 'حساب النظام؟',
  'tooltip.inbuilt': 'مدمج؟',
  'tooltip.accNNumber': 'رقم الحساب',
  'tooltip.ledgerAccNumber': 'يمكن أن يكون هذا رقم حساب دفتر الأستاذ من برامج المحاسبة الأخرى مثل QuickBook و Sage و Xero و SAP وما إلى ذلك',
  'tooltip.ledgerNo': 'رقم دفتر الأستاذ',
  'tooltip.ledgerAccount': 'حسابات دفتر الأستاذ',
  'tooltip.totalAmount': 'المبلغ الإجمالي',
  'tooltip.openingBalance': 'رصيد افتتاحي',
  'tooltip.debitAmount': 'مبلغ الدين',
  'tooltip.creditAmount': 'مبلغ الائتمان',
  'tooltip.amountDifference': 'اختلاف المبالغ',
  'tooltip.nature': 'طبيعة',
  'tooltip.ledgerAccGrp': 'مجموعة حساب دفترالأستاذ',
  'tooltip.accountEntry': 'إدخال الحسابات',
  'tooltip.txDate': 'تاريخ التحويل',
  'tooltip.taxName': 'إسم ضريبة',
  'tooltip.description': 'مواصفات',
  'tooltip.rate': 'تقييم',
  'tooltip.soTax': 'ضريبة إنتاجية المبيعات',
  'tooltip.poTax': 'ضريبة مدخلات الشراء',
  'modalBody.deleteHierarchy': 'هل أنت متأكد بأنك تريد حذف التسلسل الهرمي؟',
  'breadcrum.dashboard': 'لوحة القيادة',
  'breadcrum.dashboard.customer': 'عميل',
  'breadcrum.dashboard.customerDetail': 'تفاصيل عن العميل',
  'breadcrum.company': 'Company',
  'breadcrum.companyHierarchy': 'تسلسل هيكلي للشركة',
  'modalBody.deleteEmployee': 'هل أنت متأكد بأنك تريد حذف الموظف؟',
  'breadcrum.employee': 'موظف',
  'industry.text': 'القطاع الصناعي',
  'industry.placeholder': 'حدد القطاع الصناعي',
  'inventoryItemQty.text': 'كمية سلع المخزون',
  'inventoryItemQty.placeholder': 'اختيار كمية سلع المخزون',
  'averageMonthlyOrder.text': 'أوامر متوسطة شهريا',
  'averageMonthlyOrder.placeholder': 'اختيار أوامر متوسطة شهريا',
  'howDidYouHear.text': 'كيف سمعت؟',
  'howDidYouHear.paceholder': 'اختيار مصدر الوصول إلينا',
  'updateCM.text': 'تحديث مذكرة الائتمان',
  'deleteCm.text': 'حذف مذكرة الائتمان',
  'common.duplicateProduct.message': 'تم اختيار السلعة المتكررة',
  'common.type': 'نوع',
  'communicationTo.text': 'اتصال بـ',
  'communicationDate.text': 'تاريخ الاتصال',
  'transaction.text': 'تحويل',
  'origination.text': 'إنشاء',
  'communicatedBy.text': 'اتصال من',
  'newCommunication.text': 'اتصال جديد',
  'common.downloadPdf': 'تحميل PDF',
  'common.export': 'تصدير إلى صيغة إكسيل',
  'email.text': ' إيميل',
  'transactionNumber.text': 'صفقة',
  'transactionAmount.text': 'مبلغ الصفقة',
  'transactionDate.text': 'تاريخ التحويل',
  'actions.text': 'إجراءات',
  'customerPoNumber.text': 'رقم أمر شراء العميل',
  'priority.text': 'اولوية',
  'communicationMode.required.message': 'حالة الاتصال مطلوبة',
  'createInquiry.text': 'إيجاد الاستفسار',
  'createSalesQuote.text': 'إيجاد إعطاء سعر المبيعات',
  'createSalesOrder.text': 'إيجاد أمر المبيعات',
  'createInvoice.text': 'إيجاد الفاتورة',
  'createCreditMemo.text': 'إيجاد مذكرة الائتمان',
  'createShipment.text': 'إيجاد الشحنة',
  'viewShipment.text': 'معاينة الشحنة',
  'updatePackage.text': 'تحديث التعبئة',
  'template.standardSalesQuote': 'نموذج أساسي لإعطاء السعر القياسي',
  'template.multicurrencySalesQuote': 'نموذج أساسي لإعطاء السعر المتعدد العملة',
  'customerSalesEnquiryNumber.text': 'استفسار العميل #',
  'customerRfqNumber.text': 'طلب عرض الأسعار من قبل العميل #',
  'customerPONumber.text': 'طلب شراء العميل #',
  'package.text': 'حزمة',
  'newPackage.text': 'تعبئة جديدة',
  'xeroPaymentUpload.text': 'تم تحميل دفعة  Xero ',
  'paymentBeforeUpload.message': 'الرجاء اختيار الدفعة قبل التحميل',
  'salesSupport.text': 'دعم المبيعات',
  'convertToSq.text': 'تغيير إلى SQ',
  'convertToPr.text': 'تغيير إلى PR',
  'updateInquiry.text': 'تحديث الاستفسار',
  'cloneInquiry.text': 'تكرار الاستفسار',
  'deleteInquiry.text': 'حذف الاستفسار',
  'inquiryNumber.text': 'رقم الاستفسار',
  'common.customer.text': 'عميل',
  'expirationDate.text': 'تاريخ الإنتهاء',
  'inquiryDate.text': 'تاريخ الاستفسار',
  'quoteConverted.text': 'تم تغيير التسعير',
  'salesPerson.text': 'مندوب المبيعات',
  'salesOrder.text': 'طلب مبيعات',
  'salesQuote.text': 'تسعير مبيعات',
  'selectCustomer.message': 'الرجاء اختيار العميل',
  'multicurrencySalesQuote.text': 'تسعير المبيعات المتعدد العملة',
  'addAuditTrail.text': 'إضافة مراجعة حسابات',
  'addAuditTrail.placeholder': 'أضف هنا مراجعة حسابات',
  'button.uploadCustomerPO.label': 'تحميل أمر شراء العميل',
  'companyInfo.text': 'معلومات عن الشركة',
  'signout.text': ' تسجيل خروج',
  'lastLogin.text': 'تسجيل دخول سابق',
  'homeCurr.text': 'عملة محلية',
  'language.text': 'لغة',
  'timezon.text': 'منطقة التوقيت',
  'setting.text': 'إعدادات',
  'privacyPolicy.text': 'سياسة الخصوصية',
  'termsOfService.text': 'شروط الخدمة',
  'xero.text': 'Xero',
  'createUpdateInvoice.text': 'إيجاد/تحديث الفاتورة',
  'createUpdateNotes.text': 'تحديث ملاحظات الفاتورة',
  'applyPayment.text': 'تطبيق الدفعات',

  'update': 'تحديث',
  'clone': 'استنساخ',
  'delete': 'حذف',
  'product.listing.buttonMenu.newProducts': 'سلع جديدة',
  'product.listing.validation.failed.label': 'فشل التحقق من صحة',
  'product.listing.validation.failed.message.delete': 'لم يتم اختيار أي سلعة. الرجاء اختيار السلعة (السلع) قبل الحذف',
  'product.listing.validation.failed.message.download': 'لم يتم اختيار أي سلعة. الرجاء اختيار السلعة قبل التنزيل',
  'product.listing.header.productDetails': 'تفاصيل المنتج',
  'product.listing.header.variant': 'البديل',
  'product.listing.header.sku': 'إس كيه يو',
  'product.listing.header.category': 'صنف',
  'product.listing.header.hsnCode': 'رمز شبكة التسوق المنزلي',
  'product.listing.header.currentStock': 'المخزون الحالي',
  'product.listing.header.warehouse': 'ويرهاوس',
  'product.listing.header.valuation': 'تقييم',

  'product.detail.panel.header.label': 'معلومات عن المنتج',
  'product.detail.panel.productName.label': 'إسم السلعة',
  'product.detail.panel.productCategory.label': 'صنف السلعة',
  'product.detail.panel.firstProcurement.label': 'توريد أول',
  'product.detail.panel.manufacturer.label': 'شركة مصنعة',
  'product.detail.panel.brand.label': 'علامات تجارية',
  'product.detail.panel.articleNo.label': 'رقم السلعة',

  'product.detail.tab.header.outboundInventory': 'مخزون صادر',
  'product.detail.tab.header.inboundInventory': 'مخزون داخلي',
  'product.detail.tab.inboundInventory.productName': 'إسم السلعة',
  'product.detail.tab.inboundInventory.variant': 'البديل',
  'product.detail.tab.inboundInventory.sku': 'إس كيه يو',
  'product.detail.tab.inboundInventory.supplier': 'مورد',
  'product.detail.tab.inboundInventory.invoiceNumber': 'فاتورة#',
  'product.detail.tab.inboundInventory.quantity': 'كمية',
  'product.detail.tab.inboundInventory.price': 'سعر',
  'product.detail.tab.inboundInventory.txDate': 'تاريخ ضريبة',

  'product.detail.tab.outboundInventory.productName': 'إسم السلعة',
  'product.detail.tab.outboundInventory.variant': 'البديل',
  'product.detail.tab.outboundInventory.sku': 'إس كيه يو',
  'product.detail.tab.outboundInventory.customer': 'عميل',
  'product.detail.tab.outboundInventory.invoiceNumber': 'فاتورة#',
  'product.detail.tab.outboundInventory.quantity': 'كمية',
  'product.detail.tab.outboundInventory.price': 'سعر',
  'product.detail.tab.outboundInventory.txDate': 'تاريخ ضريبة',

  'setting.accountInformation.label': 'معلومات عن الحساب',
  'setting.companyInfo.label':' معلومات عن الشركة',
  'setting.companyInformation.label': 'معلومات عن الشركة',
  'setting.companyInformation.message': 'قم بتحديث ملف شركتك، شعارها وعنوانها إلخ',
  'setting.changePassword.label': 'تغيير كلمة السر',
  'setting.changePassword.message': "نوصي بشدة بتغيير كلمة المرور الخاصة بك بشكل دوري.",
  'setting.administration.label': 'إدارة',
  'setting.userManagement.label': 'إدارة المستخدم',
  'setting.passwordManagement.label': 'إدارة كلمة السر',
  'setting.documentSequence.label': 'تسلسل المستند',
  'setting.qualityCheck.label': 'Quality Check',
  'setting.qualityCheckSetting.label': 'إعدادات فحص التحقق من الجودة',
  'setting.userManagement.message': 'أنت مدعوم من إنشاء العديد من المستخدمين لعملك. يمكنك التحكم الدقيق في العمليات التي يمكن للمستخدمين تنفيذها أو البيانات التي يمكن للمستخدمين رؤيتها.',
  'setting.documentSequence.message': 'Yيمكنك تجاوز اسم المستند الافتراضي الذي أنشأه النظام لأمر الشراء وأمر المبيعات والفاتورة وما إلى ذلك.',
  'setting.qualityCheck.message': 'يمكنك تمكين إعدادات فحص التحقق من الجودة',
  'setting.customizations.label': 'تكييفات',
  'setting.emailTemplates.label': 'نماذج أساسية للبريد الالكتروني',
  'setting.emailTemplates.message': 'غير راضٍ عن خطتك الحالية. لا تقلق ، يمكنك تحديد الخطة التي تناسب احتياجات عملك. يمكنك إعداد وتيرة الفواتير (شهريًا أو ربع سنويًا أو سنويًا) للاشتراك.',
  'setting.remarks.label': 'أقاويل',
  'setting.remarks.message': 'الملاحظات التي تمت طباعتها في طلب عرض الأسعار ، وعرض الأسعار ، والفاتورة ، والمبيعات وأمر الشراء ، وعلى المعاملات الأخرى.',
  'setting.documentName.label': 'إسم الوثيقة',
  'setting.documentName.message': '',
  'setting.documentApproval.label': 'اعتماد مستندات',
  'setting.documentApproval.message': 'يمكنك تجاوز الإعدادات الافتراضية لاعتماد المستندات',
  'setting.enableTestData.message': 'تمكين بيانات اختبارية',

  'setting.userManagement.updatePermissions.label': 'تحديث ترخيصات',
  'setting.userManagement.revokeAccess.label': 'إلغاء الوصول',
  'setting.userManagement.listing.title': 'إسم',
  'setting.userManagement.listing.firstName': 'الإسم الأول',
  'setting.userManagement.listing.middleName': 'الإسم الأوسط',
  'setting.userManagement.listing.lastName': 'الإسم الأخير',
  'setting.userManagement.listing.callName': 'اللقب',
  'setting.userManagement.listing.phone': 'هاتف',
  'setting.userManagement.listing.mobile': 'جوال',
  // 'setting.userManagement.listing.status': 'حالة',
  'setting.userManagement.listing.email': 'إيميل',
  'setting.userManagement.listing.createdDate': 'تاريخ الإيجاد',
  'setting.userManagement.addUser.label': 'أضف المستخدم',
  'setting.userManagement.addEmployee.label': 'أضف الموظف الجديد',

  'sidebar.salesPayment.popup.title': 'دفعات المبيعات',
  'sidebar.purchasePayment.popup.title': 'دفعات الشراء',
  'sidebar.newChartOfAccount.popup.title': 'جدول جديد للحساب',

  'reports.title.agingOfAccountReceivable': 'تعمير الذمم المدينة',
  'reports.title.agingOfAccountPayable': 'تقادم الحسابات الدائنة',
  'reports.title.customerPartyLedger': 'دفتر أستاذ الذمم لجهة خارجية',
  'reports.title.supplierPartyLedger': 'دفتر أستاذ المورد لجهة خارجية',

  'reports.title.customer': 'عميل',
  'reports.customerByPayment.label': 'العميل عن طريق الدفع',
  'reports.customerByPayment.message': 'هذا التقرير يسرد قائمة جميع العملاء من حيث دفع السداد',
  'reports.agedDebtors.label': 'مدين قديم',
  'reports.agedDebtors.message': 'يسرد هذا التقرير جميع العملاء بالمبلغ المستحق',
  'reports.partyLedger.label': 'دفتر الأستاذ لجهة خارجية',
  'reports.partyLedger.message': 'إظهار تقرير دفتر الأستاذ لجهة خارجية',

  'reports.title.supplier': 'مورد',
  'reports.supplierByPayment.label': 'مورد من حيث دفع السداد',
  'reports.supplierByPayment.message': 'هذا التقرير يسرد قائمة جميع الموردين من حيث المبالغ الواجبة',
  'reports.agedCreditors.label': 'دائنون قديمون',
  'reports.agedCreditors.message': 'هذا التقرير يسرد قائمة جميع الموردين من حيث المبالغ الواجبة',

  'reports.title.salesReports.label': 'تقارير المبيعات',
  'reports.salesByCustomer.label': 'مبيعات من حيث العميل',
  'reports.salesByCustomer.message': 'هذا التقرير يسرد قائمة جميع أنشطة المبيعات للعميل',
  'reports.salesByProduct.label': 'مبيعات من حيث المنتج',
  'reports.salesByProduct.message': 'هذا التقرير يسرد قائمة جميع المبيعات من حيث المنتجات',
  'reports.salesOrderByFulfillment.label': 'أمر المبيعات من حيث التوفير',
  'reports.salesOrderByFulfillment.message': 'هذا التقرير يسرد قائمة جميع الأوامر من حيث حالة توفيرها',

  'reports.title.purchaseReports.label': 'تقارير الشراء',
  'reports.purchaseBySupplier.label': 'شراء من قبل الموردين',
  'reports.purchaseBySupplier.message': 'هذا التقرير يسرد قائمة جميع أنشطة شراء الموردين',
  'reports.purchaseByFulfillment.label': 'شراء من حيث التوفير',
  'reports.purchaseByFulfillment.message': 'هذا التقرير يسرد قائمة جميع الشراءات من حيث حالة توفيرها',

  'reports.title.inventoryReports.label': 'تقارير المخزون',
  'reports.agedInventory.label': 'مخزون قديم',
  'reports.agedInventory.message': 'يسرد تقرير تقادم المخزون جميع عناصر المخزون الموجودة في المخزون بناءً على تاريخها الوارد',
  'reports.inventoryValuation.label': 'تقييم المخزون',
  'reports.inventoryValuation.message': 'يتضمن تقرير ملخص تقييم المخزون التكلفة الإجمالية للمخزون عبر المستودعات',
  'reports.lowStockReport.label': 'تقرير عن إنخفاض المخزون',
  'reports.lowStockReport.message': 'يسرد تقرير المخزون المنخفض جميع العناصر عبر المستودعات التي تكون منخفضة على أساس المخزون بناءً على مستويات المخزون الدنيا المحددة مقابل كل عنصر',
  'reports.fastestSellingProduct.label': 'المنتج الأسرع مبيعا',
  'reports.fastestSellingProduct.message': 'يسرد هذا التقرير جميع العناصر التي يتم بيعها بسرعة. يساعد هذا التقرير في التخطيط لشراء المخزون مسبقًا',
  'reports.outOfStockReport.label': 'تقرير عدم التوفر',
  'reports.outOfStockReport.message': 'يسرد هذا التقرير جميع العناصر التي يتم بيعها بسرعة. يساعد هذا التقرير في التخطيط لشراء المخزون مسبقًا',

  'supplier.listing.actionMenu.title.newActivity': 'نشاط جديد',
  'supplier.listing.actionMenu.newActivity': 'نشاطات السجل',
  'supplier.listing.actionMenu.title.newCommunication': 'اتصال جديد',
  'supplier.listing.actionMenu.newCommunication': 'اتصالات السجل',
  'supplier.listing.actionMenu.downloadLedgerStatement': 'حمل بيانات دفتر الأستاذ',
  'supplier.listing.actionMenu.title.contact': 'اتصل',
  'supplier.listing.actionMenu.contact': 'إظهار الاتصال',
  'supplier.listing.actionMenu.title.address': 'عنوان',
  'supplier.listing.actionMenu.address': 'إظهار العنوان',
  'supplier.listing.actionMenu.createPurchaseOrder': 'إيجاد أمر الشراء',
  'supplier.listing.actionMenu.bookPurchase': 'تدوين الشراء',
  'supplier.listing.actionMenu.createDebitMemo': 'إيجاد مذكرة المدينة',
  'supplier.listing.actionMenu.makePayment': 'قم بالدفع',
  'supplier.listing.actionMenu.makePayment.title': 'دفعات الشراء',
  'supplier.listing.actionMenu.editSupplier': 'تعديل المورد',
  'supplier.listing.actionMenu.deleteSupplier': 'حذف المورد',
  'supplier.listing.validation.failed.label': 'فشل التحقق من صحة',
  'supplier.listing.validation.failed.message': 'لم يتم اختيار أس المورد. الرجاء اختيار المورد(ين) قبل عملية الحذف',

  'supplier.listing.showContact.gridHeader.primaryContact': 'اتصال أساسي',
  'supplier.listing.showContact.gridHeader.title': 'إسم',
  'supplier.listing.showContact.gridHeader.firstName': 'الإسم الأول',
  'supplier.listing.showContact.gridHeader.lastName': 'الإسم الأخير',
  'supplier.listing.showContact.gridHeader.callName': 'اللقب',
  'supplier.listing.showContact.gridHeader.workPhone': 'هاتف العمل',
  'supplier.listing.showContact.gridHeader.mobile': 'جوال',
  'supplier.listing.showContact.gridHeader.faxNumber': 'رقم فاكس',
  'supplier.listing.showContact.gridHeader.email': 'إيميل',

  'supplier.listing.showAddress.gridHeader.primaryAddress': 'اتصال أساسي',
  'supplier.listing.showAddress.gridHeader.streetAddress1': 'عنوان الشارع 1',
  'supplier.listing.showAddress.gridHeader.streetAddress2': 'عنوان الشارع 2',
  'supplier.listing.showAddress.gridHeader.city': 'مدينة',
  'supplier.listing.showAddress.gridHeader.country': 'البلد',
  'supplier.listing.showAddress.gridHeader.state': 'ولاية',
  'supplier.listing.showAddress.gridHeader.postalCode': 'رمز بريدي/منطقي',

  'supplier.listing.logCommunication.communicationType.label': 'نوع الاتصال',
  'supplier.listing.logCommunication.communicationType.tooltip': 'قد يكون نوع الاتصال من قبيل البريد الالكتروني، كوريير، فاكس إلخ',
  'supplier.listing.logCommunication.communicationType.validationMessage': 'حالة الاتصال مطلوبة',
  'supplier.listing.logCommunication.description.label': 'مواصفات',
  'supplier.listing.logCommunication.description.tooltip': 'وصف موجز للاتصال',
  'supplier.listing.logCommunication.communicatedTo.label': 'اتصال بـ',
  'supplier.listing.logCommunication.communicatedTo.tooltip': 'قد يكون بريد الكتروني، أو فاكس أو العنوان البريدي الكامل',
  'supplier.listing.logCommunication.communicatedBy.label': 'اتصال من',

  'supplier.listing.logActivity.activityType.label': 'نوع الأولوية',
  'supplier.listing.logActivity.description.label': 'مواصفات',
  'supplier.listing.logActivity.description.placeholder': 'وضف موجز عن الأنشطة',
  'supplier.listing.logActivity.refNumber.label': 'مراجعة',
  'supplier.listing.logActivity.refNumber.tooltip': 'قد يكون إسم التعامل أو رقم التعامل المتصلة بهذا النشاط',
  'supplier.listing.logActivity.refNumber.placeholder': 'رقم مرجعي',
  // 'supplier.listing.logActivity.status.label': 'حالة',
  'supplier.listing.logActivity.priority.label': 'اولوية',
  'supplier.listing.logActivity.assignTo.label': 'تعيين إلى',
  'supplier.listing.logActivity.activityDueDate.label': 'تاريخ محدد للنشاط',
  'supplier.listing.logActivity.ActivityClosedDate.label': 'تاريخ إغلاق النشاط',

  'supplier.detail.activity.gridHeader.activityType': 'نوع الأولوية',
  'supplier.detail.activity.gridHeader.description': 'مواصفات',
  'supplier.detail.activity.gridHeader.refNumber': 'مراجعة',
  // 'supplier.detail.activity.gridHeader.status': 'حالة',
  'supplier.detail.activity.gridHeader.priority': 'اولوية',
  'supplier.detail.activity.gridHeader.assignedTo': 'معينة إلى ',
  'supplier.detail.activity.gridHeader.dueDate': 'تاريخ محدد',
  'supplier.detail.activity.gridHeader.closedDate': 'تاريخ الإغلاق',
  'supplier.detail.activity.gridHeader.dateCreated': 'تاريخ الإيجاد',
  'supplier.detail.activity.gridHeader.documents': 'مستندات',

  'supplier.detail.communication.gridHeader.type': 'نوع',
  'supplier.detail.communication.gridHeader.description': 'مواصفات',
  'supplier.detail.communication.gridHeader.communicatedTo': 'اتصال بـ',
  'supplier.detail.communication.gridHeader.transaction': 'تحويل',
  'supplier.detail.communication.gridHeader.date': 'تاريخ',
  'supplier.detail.communication.gridHeader.origination': 'إنشاء',
  'supplier.detail.communication.gridHeader.communicatedBy': 'اتصال من',

  'supplier.detail.transaction.gridHeader.transaction': 'تحويل',
  'supplier.detail.transaction.gridHeader.transactionNumber': 'رقم المعاملة',
  'supplier.detail.transaction.gridHeader.transactionAmount': 'مبلغ الصفقة',
  'supplier.detail.transaction.gridHeader.transactionDate': 'تاريخ التحويل',
  // 'supplier.detail.transaction.gridHeader.status': 'حالة',
  'supplier.detail.transaction.gridHeader.invoiceNumber': 'رقم الفاتورة',
  'supplier.detail.transaction.gridHeader.poNumber': 'رقم أمر الشراء',
  'supplier.detail.transaction.gridHeader.amount': 'مبلغ',
  'supplier.detail.transaction.gridHeader.paymentDueDate': 'تاريخ محدد للدفع',
  'supplier.detail.transaction.gridHeader.paymentTerm': 'شوط الدفع',
  'supplier.detail.transaction.gridHeader.date': 'تاريخ',
  'supplier.detail.transaction.gridHeader.paymentNumber': 'رقم الدفع',
  'supplier.detail.transaction.gridHeader.priority': 'اولوية',

  'modal.settings.companyInfo.aboutCompany.companySize': 'حجم الشركة',
  'modal.settings.companyInfo.aboutCompany.companySize.placeholder': 'حدد حجم الشركة',
  'modal.settings.companyInfo.aboutCompany.industry': 'القطاع الصناعي',
  'modal.settings.companyInfo.aboutCompany.industry.placeholder': 'حدد القطاع الصناعي',
  'modal.settings.companyInfo.aboutCompany.inventoryItemQty': 'كمية سلع المخزون',
  'modal.settings.companyInfo.aboutCompany.inventoryItemQty.placeholder': 'اختيار كمية سلع المخزون',
  'modal.settings.companyInfo.aboutCompany.averageMonthlyOrders': 'أوامر متوسطة شهريا',
  'modal.settings.companyInfo.aboutCompany.averageMonthlyOrders.placeholder': 'اختيار أوامر متوسطة شهريا',
  'modal.settings.companyInfo.aboutCompany.howDidYouHear': 'كيف سمعت؟',
  'modal.settings.companyInfo.aboutCompany.howDidYouHear.placeholder': 'اختيار مصدر الوصول إلينا',

  'modal.settings.companyInfo.bankInfo.bankName': 'إسم البنك',
  'modal.settings.companyInfo.bankInfo.accountNumber': 'رقم الحساب',
  'modal.settings.companyInfo.bankInfo.ifsc': 'آي إف إس سي',
  'modal.settings.companyInfo.bankInfo.newAccount': 'إضافة حساب البنك الجديد',

  'modal.settings.companyInfo.company.companyName': 'إسم الشركة',
  'modal.settings.companyInfo.company.phoneNumber': 'رقم الهاتف',
  'modal.settings.companyInfo.company.faxNumber': 'رقم فاكس',
  'modal.settings.companyInfo.company.website': 'موقع ويب',
  'modal.settings.companyInfo.company.decimalDigitsLength': 'طول الأرقام العشرية',
  'modal.settings.companyInfo.company.updateInventoryOnSalesInvoice': 'تحديث المخزون في فاتورة المبيعات',
  'modal.settings.companyInfo.company.updateInventoryOnPurchaseInvoice': 'تحديث المخزون في فاتورة الشراء',

  'modal.settings.companyInfo.costCenter.costCenterName': 'إسم مركز التكلفة',
  'modal.settings.companyInfo.costCenter.costCenterNumber': 'رقم مركز التكلفة',
  'modal.settings.companyInfo.costCenter.department': 'قسم',
  'modal.settings.companyInfo.costCenter.description': 'مواصفات',
  'modal.settings.companyInfo.costCenter.newCostCenter': 'إضافة مركز التكلفة الجديدة',

  'modal.settings.companyInfo.taxInfo.taxType': 'نوعية الضريبة',
  'modal.settings.companyInfo.taxInfo.number': 'رقم',
  'modal.settings.companyInfo.taxInfo.populateOnTransaction': 'ملء المعاملات',
  'modal.settings.companyInfo.taxInfo.populateOnTransaction.tooltip': 'إذا تم الاختيار سيتم عرض الرقم الضريبي المحدد على وثيقة بصيغة بي دي إف',
  'modal.settings.companyInfo.taxInfo.addLine': 'مجال للعنوان',

  'modal.settings.documentApproval.transaction': 'تحويل',

  'modal.settings.quilityCheck.qualityChecklistRequired': 'قائمة فحص التحقق من الجودة مطلوبة',
  'modal.settings.quilityCheck.generateShipmentWithoutQuilityCheck': 'إنشاء الشحنة من دون فحص التحقق من الجودة',

  'modal.settings.documentnumber.transaction': 'تحويل',
  'modal.settings.documentnumber.numberExample': 'مثال العدد',
  'modal.settings.documentnumber.numberInfo': 'معلومات عن الأعداد',
  'modal.settings.documentnumber.nextSequence': 'تسلسل تالي',
  'modal.settings.documentnumber.nextSequence.tooltip': 'سيتم إنشاء مستندك التالي مع التسلسل  الذي ',
  'modal.settings.documentnumber.action': 'اتخاذ إجراء',

  'modal.settings.manageUser.permissions.view': 'شاهد',
  'modal.settings.manageUser.permissions.create': 'إيجاد',
  'modal.settings.manageUser.permissions.update': 'تحديث',
  'modal.settings.manageUser.permissions.delete': 'حذف',
  'modal.settings.manageUser.permissions.fullControl': 'تحكم كامل',
  'modal.settings.manageUser.employee': 'موظف',
  'modal.settings.manageUser.email': 'إيميل',
  'modal.settings.manageUser.email.placeholder': 'مطلوب و سيتم إرسال البريد الالكتروني للتفعيل ',
  'modal.settings.manageUser.notifyUser': 'نبهني',
  'modal.settings.manageUser.password': 'كلمة السر',
  'modal.settings.manageUser.password.placeholder': 'كلمة السر',
  'modal.settings.manageUser.password.validationMessage': 'كلمة السر مطلوبة',
  'modal.settings.manageUser.confirmPassword': 'تأكيد كلمة السر',
  'modal.settings.manageUser.confirmPassword.placeholder': 'إعادة كتابة كلمة السر',
  'modal.settings.manageUser.employee.requiredMessage': 'الموظف مطلوب',
  'modal.settings.manageUser.employee.validationMessage': 'البريد الالكتروني خاطئ',

  'modal.supplier.applyPurchaseCredit.debitMemoNumber': 'مذكرة مدين#',
  'modal.supplier.applyPurchaseCredit.debitMemoDate': 'تاريخ مذكرة الخصم',
  'modal.supplier.applyPurchaseCredit.debitAmount': 'مبلغ الدين',
  'modal.supplier.applyPurchaseCredit.debitsAvailable': 'خصم متوفر',
  'modal.supplier.applyPurchaseCredit.amountToDebit': 'مبلغ إلى الخصم',
  'modal.supplier.applyPurchaseCredit.remarks': 'أقاويل',
  'modal.supplier.applyPurchaseCredit.invoiceNumber': 'فاتورة#',
  'modal.supplier.applyPurchaseCredit.invoiceDate': 'تاريخ الفاتورة',
  'modal.supplier.applyPurchaseCredit.dueDate': 'تاريخ محدد',
  'modal.supplier.applyPurchaseCredit.invoiceAmount': 'مبلغ الفاتورة',
  'modal.supplier.applyPurchaseCredit.totalAmountToDebit': 'مبلغ إلى الخصم',
  'modal.supplier.applyPurchaseCredit.invoiceDue': 'فاتورة واجب الدفع',
  'modal.supplier.applyPurchaseCredit.applyCredits': 'تطبيق الائتمان',
  'modal.txDetails.rfq.convertToPO': 'تغيير إلى أمر الشراء',
  'modal.txDetails.rfq.approveRFQ': 'موفقة على RFQ',
  'modal.txDetails.rfq.rfqDate': 'تاريخ طلب عرض الأسعار',
  'modal.txDetails.rfq.bidClosingDate': 'تاريخ إنهاء العرض',
  'modal.txDetails.rfq.customerRFQNumber': 'طلب عرض الأسعار من قبل العميل #',


  'modal.txDetails.creditMemo.creditMemoNumber': 'مذكرة مدينة#',
  'modal.txDetails.creditMemo.creditMemoDate': 'تاريخ مذكرة الائتمان',
  'modal.txDetails.creditMemo.creditMemoExp': 'تاريخ انتهاء مذكرة الائتمان',
  
  'modal.txDetails.common.billTo': 'فاتورة إلى:',
  'modal.txDetails.common.shipTo': 'شحنة إلى:',
  'modal.txDetails.common.shippingAddress': 'عنوان الشحن',
  'modal.txDetails.common.placeOfSupply': 'محل التوريد',
  'modal.txDetails.common.foreignCurrency': 'عملة أجنبية',
  'modal.txDetails.common.exchangeRate': 'سعر الصرف',
  'modal.txDetails.common.project': 'مشروع',
  'modal.txDetails.common.dropshipOrder': 'أمر سفينة الإنزال؟',
  'modal.txDetails.common.refNumber': 'مراجعة',
  'modal.txDetails.common.paymentTerm': 'شوط الدفع',
  'modal.txDetails.common.priority': 'اولوية',
  'modal.txDetails.common.product': 'السلعة',
  'modal.txDetails.common.description': 'مواصفات',
  'modal.txDetails.common.qty': 'كمية',
  'modal.txDetails.common.basePrice': 'سعر أساسي',
  'modal.txDetails.common.specialDiscount': 'خصم خاص',
  'modal.txDetails.common.rate': 'Rate',
  'modal.txDetails.common.hsn': 'إتش إس إن',
  'modal.txDetails.common.discount': 'خصم',
  'modal.txDetails.common.tax': 'ضريبة',
  'modal.txDetails.common.netAmount': 'المبلغ الصافي',
  'modal.txDetails.common.attachments': 'مرفقات',
  'modal.txDetails.common.internalRemarks': 'تعليقات داخلية',
  'modal.txDetails.common.supplierRemarks': 'تعليقات المورد',
  'modal.txDetails.common.customerRemarks': 'تعليقات العملاء',
  'modal.txDetails.common.remarks': 'أقاويل',
  'modal.txDetails.common.employeeRemarks': 'تعليقات الموظف',
  'modal.txDetails.common.shippingInstructions': 'إرشادات عن الشحن',
  'modal.txDetails.common.subTotal': 'مجموع فرعي',
  'modal.txDetails.common.expense': 'تكلفة',
  'modal.txDetails.common.grandTotal': 'المجموع الكلي',
  'modal.txDetails.common.deleteStamp': 'حذف الطابع',
  'modal.txDetails.common.edit': 'تعديل',
  'modal.txDetails.common.clone': 'استنساخ',
  'modal.txDetails.common.delete': 'حذف',
  'modal.txDetails.common.void': 'إلغاء',
  'modal.txDetails.common.void.confirmation': 'هل أنت متأكد بأنك تريد إلغاء هذا الصفقة؟',
  'modal.txDetails.common.delete.confirmation': 'هل أنت متأكد بأنك تريد حذف هذا التحويل؟',
  'modal.txDetails.common.email': 'إيميل',
  'modal.txDetails.common.downloadPDF': 'حمل بي دي إف',
  'modal.txDetails.common.download': 'تحميل',
  'modal.txDetails.common.print': 'اطبع',
  'modal.txDetails.common.auditTrail': 'مراجعة حسابات',
  'modal.txDetails.common.updateStatus': 'تحديث الحالة',
  'modal.txDetails.common.delegateApprover': 'انتداب موافق',
  'modal.txDetails.common.reject': 'رفض',

  'modal.txDetails.package.labelDownload.itemName': 'رقم السلعة',
  'modal.txDetails.package.labelDownload.poNumber': 'امر الشراء#',
  'modal.txDetails.package.labelDownload.poLineNumber': 'خط أمر الشراء',
  'modal.txDetails.package.labelDownload.stockNumber': 'مخزون #',
  'modal.txDetails.package.labelDownload.qty': 'كمية',
  'modal.txDetails.package.qtyYetToPack': 'كمية لم يتم تعبئتها بعد',
  'modal.txDetails.package.soStatus': 'حالة SO',
  'modal.txDetails.package.createPackage': 'إيجاد حزمة',

  'modal.txDetails.purchaseOrder.approvePO': 'موافقة على أمر الشراء',
  'modal.txDetails.purchaseOrder.convertToID': 'تغيير إلى هوية',
  'modal.txDetails.purchaseOrder.convertToInboundDelivery': 'تغيير إلى توصيل داخلي',
  'modal.txDetails.purchaseOrder.poNumber': 'امر الشراء#',
  'modal.txDetails.purchaseOrder.poDate': 'تاريخ طلب الشراء',
  'modal.txDetails.purchaseOrder.poDueDate': 'تاريخ محدد لطلب الشراء',
  'modal.txDetails.purchaseOrder.expectedDelivery': 'تاريخ توصيل متوقع',
  'modal.txDetails.purchaseOrder.rfqNumber': 'طلب للتسعير',
  'modal.txDetails.purchaseOrder.supplierQuoteNumber': 'تسعير المورد #',

  'modal.txDetails.purchaseInvoice.payInvoice': 'ادفع الفاتورة',
  'modal.txDetails.purchaseInvoice.approveInvoice': 'موافقة على فاتورة',
  'modal.txDetails.purchaseInvoice.availableDebits': 'المبالغ المدينة متوفرة',
  'modal.txDetails.purchaseInvoice.applyDebits': 'تطبيق المبالغ المدينة',
  'modal.txDetails.purchaseInvoice.invoiceNumber': 'فاتورة#',
  'modal.txDetails.purchaseOrder.invoiceDate': 'تاريخ الفاتورة',
  'modal.txDetails.purchaseOrder.dueDate': 'تاريخ محدد',

  'modal.txDetails.purchaseRequest.approvePR': 'موافقة على طلب الشراء',
  'modal.txDetails.purchaseRequest.requestor': 'طالب',
  'modal.txDetails.purchaseRequest.owner': 'مالك',
  'modal.txDetails.purchaseRequest.purchaseRequestNumber': 'طلب الشراء#',
  'modal.txDetails.purchaseRequest.prDate': 'تاريخ طلب الشراء',
  'modal.txDetails.purchaseRequest.expireDate': 'تاريخ الانتهاء',
  'modal.txDetails.purchaseRequest.reason': 'سبب',
  'modal.txDetails.purchaseRequest.convertTo.rfq': 'آر إف قيو',
  'modal.txDetails.purchaseRequest.convertTo.po': 'آر إف قيو',

  'modal.txDetails.debitMemo.debitMemoNumber': 'مذكرة مدين#',
  'modal.txDetails.debitMemo.debitMemoDate': 'تاريخ مذكرة الخصم',

  'modal.txDetails.inboundDelivery.inboundDeliveryNumber': 'توصيل داخلي #',
  'modal.txDetails.inboundDelivery.poNumber': 'امر الشراء#',
  'modal.txDetails.inboundDelivery.inboundDeliveryDate': 'تاريخ التوصيل الداخلي',
  'modal.txDetails.inboundDelivery.rfqNumber': 'طلب للتسعير',
  'modal.txDetails.inboundDelivery.supplierQuoteNumber': 'تسعير المورد #',
  'modal.txDetails.inboundDelivery.convertToInvoice': 'تغيير إلى فاتورة',

  'modal.txDetails.payment.supplierName': 'إسم المورد',
  'modal.txDetails.payment.paymentNumber': 'رقم الدفع',
  'modal.txDetails.payment.invoiceNumber': 'رقم الفاتورة',
  'modal.txDetails.payment.paymentDate': 'تاريخ دفع المبلغ',
  'modal.txDetails.payment.paymentMode': 'طريقة الدفع',
  'modal.txDetails.payment.paymentAmount': 'مبلغ الدفع',
  'modal.txDetails.payment.invoiceAmount': 'مبلغ الفاتورة',
  'modal.txDetails.payment.refNumber': 'مراجعة',
  'modal.txDetails.payment.remarks': 'أقاويل',
  'modal.txDetails.payment.project': 'مشروع',

  'modal.txDetails.inquiry.inquiryNumber': 'استفسار#',
  'modal.txDetails.inquiry.inquiryDate': 'تاريخ الاستفسار',
  'modal.txDetails.inquiry.inquiryClosingDate': 'تاريخ انتهاء الاستفسار',
  'modal.txDetails.inquiry.customerInquiryNumber': 'استفسار العميل # ',
  'modal.txDetails.inquiry.convertTo.salesQuote': 'تسعير مبيعات',
  'modal.txDetails.inquiry.convertTo.purchaseRequest': 'طلب الشراء',

  'modal.customer.applySalesCredit.creditMemoNumber' : 'مذكرة مدينة#',
  'modal.customer.applySalesCredit.creditMemoDate' : 'تاريخ مذكرة الائتمان',
  'modal.customer.applySalesCredit.creditAmount' : 'مبلغ الائتمان',
  'modal.customer.applySalesCredit.creditsAvailable' : 'ائتمانات متوفرة',
  'modal.customer.applySalesCredit.amountToCredit' : 'مبلغ إلى الائتمان',
  'modal.customer.applySalesCredit.remarks' : 'أقاويل',
  'modal.customer.applySalesCredit.invoiceNumber' : 'فاتورة#',
  'modal.customer.applySalesCredit.invoiceDate' : 'تاريخ الفاتورة',
  'modal.customer.applySalesCredit.dueDate' : 'تاريخ محدد',
  'modal.customer.applySalesCredit.invoiceAmount' : 'مبلغ الفاتورة',
  'modal.customer.applySalesCredit.totalAmountToCredit' : 'مبلغ إلى الائتمان',
  'modal.customer.applySalesCredit.invoiceDue' : 'فاتورة واجب الدفع',
  'modal.customer.applySalesCredit.applyCredits' : 'تطبيق الائتمان',

  'reports.agedDebtor.header.pastDue.Last30Days'  : 'واجب سابق 1-30 يوما',
  'reports.agedDebtor.header.pastDue.30To60Days' : 'واجب سابق 31-60 يوما',
  'reports.agedDebtor.header.pastDue.60To90Days' : 'واجب سابق 61-90 يوما',
  'reports.agedDebtor.header.pastDue.above90Days' : 'واجب سابق 91+ أيام',
  
  'modal.reports.agedDebtors.customerName' : 'إسم العميل',
  'modal.reports.agedDebtors.invoiceNumber': 'رقم الفاتورة',
  'modal.reports.agedDebtors.invoiceDate' : 'تاريخ الفاتورة',
  'modal.reports.agedDebtors.invoiceAmount' : 'مبلغ الفاتورة',
  // 'modal.reports.agedDebtors.status' : 'حالة',
  
  'reports.agedDebtor.header.companyName' : 'إسم الشركة',
  'reports.agedDebtor.header.totalReceivable' : 'مجموع المستحقات',

  'modal.reports.partyLedger.gridHeader.date' :'تاريخ',
  'modal.reports.partyLedger.gridHeader.transaction' : 'تحويل',
  'modal.reports.partyLedger.gridHeader.transactionNumber' :'رقم المعاملة',
  'modal.reports.partyLedger.gridHeader.debit' : 'مدين',
  'modal.reports.partyLedger.gridHeader.credit' : "ائتمان",
  'modal.reports.partyLedger.startDate.label' : "تاريخ البدء",
  'modal.reports.partyLedger.endDate.label' : "تاريخ الانتهاء",
  'modal.reports.partyLedger.submit.label' : "عرض",
  'modal.reports.partyLedger.total.label' : "مجموع",
  'modal.reports.partyLedger.closingAmount.label' : 'مبلغ ختامي (Dr)',
  'modal.reports.partyLedger.download.label' : 'تحميل',
  
  'customer.salesOrder.listing.header.draftNo' : 'سحب #',

  'modal.txDetails.package.label': 'حزمة',
  'modal.txDetails.package.length': 'طول',
  'modal.txDetails.package.width': 'عرض',
  'modal.txDetails.package.height': 'ارتفاع',
  'modal.txDetails.package.weight': 'وزن',
  'modal.txDetails.package.packageNumber': 'رقم حزمة',
  'modal.txDetails.package.packageDate': 'تاريخ التعبئة',
  'modal.txDetails.package.soNumber': 'أمر المبيعات#',
  'modal.txDetails.package.soDate': 'تاريخ إس أو',
  'modal.txDetails.package.poNumber': 'امر الشراء#',
  // 'modal.txDetails.package.status': 'حالة',
  'modal.txDetails.package.deliveryDate': 'تاريخ التوصيل',
  'modal.txDetails.package.qtyOrdered': 'كمية تم طلبها',
  'modal.txDetails.package.qtyPacked': 'كمية معبأة',
  'modal.txDetails.package.packageLabel': 'ملصق حزمة',
  'modal.txDetails.package.convertToShipment': 'تغيير إلى شحن',
  'modal.txDetails.package.qualityCheck': 'تحقق من الجودة',
  
  'forgot.btn.addQualityChecklist': 'إضافة فحص التحقق من الجودة',

  'modal.certificateOfConformity.title': 'شهادة الموافقة',
  'modal.shippingInvoice.title': 'فاتورة الشحن',
  'modal.txDetails.shipment.markAsShipped': 'وضع علامة لتمام الشحن',
  'modal.txDetails.shipment.markAsDelivered': 'وضع علامة لتمام التوصيل',
  'modal.txDetails.shipment.shippingInvoice': 'فاتورة الشحن',
  'modal.txDetails.shipment.updateDeliveryDate': 'تحديث تاريخ التوصيل',
  'modal.txDetails.shipment.shipmentNumber': 'شحنة#',
  'modal.txDetails.shipment.carrier': 'شاحن',
  'modal.txDetails.shipment.trackingNumber': 'متابعة',
  'modal.txDetails.shipment.freightType': 'نوع الشحن',
  'modal.txDetails.shipment.consignee': 'المراسل إلية',
  'modal.txDetails.shipment.shipmentDate': 'تاريخ الشحنة',
  'modal.txDetails.shipment.expectedDelivery': 'تاريخ توصيل متوقع',
  'modal.txDetails.shipment.poNumber': 'امر الشراء#',
  'modal.txDetails.shipment.soNumber': 'أمر المبيعات#',
  'modal.txDetails.shipment.packageNumber': 'تعبئة#',
  'modal.txDetails.shipment.qtyOrdered': 'كمية تم طلبها',
  'modal.txDetails.shipment.qtyPacked': 'كمية معبأة',
  'modal.txDetails.shipment.qtyShipped': 'كمية مشحونة',
  'supplier.purchaseOrder.form.gridHeader.qtyReceived': 'كمية واردة',
  'supplier.purchaseOrder.form.gridHeader.qtyToReceive': 'كمية للاستلام',

  'modal.txDetails.salesOrder.soNumber': 'أمر المبيعات#',
  'modal.txDetails.salesOrder.soDate': 'تاريخ إس أو',
  'modal.txDetails.salesOrder.deliveryDate': 'تاريخ التوصيل',
  'modal.txDetails.salesOrder.userStatus': 'وضع المستخدم',
  'modal.txDetails.salesOrder.convertTo.purchaseRequest': 'طلب الشراء',
  'modal.txDetails.salesOrder.convertTo.salesInvoice': 'فاتورة مبيعات',
  'modal.txDetails.salesOrder.convertTo.proformaInvoice': 'فاتورة مبدئية',
  'modal.txDetails.salesOrder.convertTo.package': 'حزمة',
  'modal.txDetails.salesOrder.approveSO': 'موفقة على SO',
  'modal.txDetails.salesOrder.qualityCheck': 'تحقق من الجودة',
  'modal.txDetails.salesOrder.soSource': 'مصدر إس أو',
  'modal.txDetails.salesOrder.customerPO': 'أمر شراء العميل',
  'modal.txDetails.salesInvoice.payInvoice' : 'ادفع الفاتورة',

  'modal.common.project.projectName': 'إسم المشروع',
  'modal.common.project.description': 'مواصفات',
  'modal.common.project.departmentName': 'إسم القسم',
  'modal.common.project.projectOwner': 'صاحب المشروع',
  'modal.common.project.startDate': 'تاريخ البدء',
  'modal.common.project.endDate': 'تاريخ الانتهاء',

  'modal.common.priceList.name': 'Name',
  'modal.common.priceList.description': 'مواصفات',
  'modal.common.priceList.percentage': 'نسبة مئوية',
  'modal.common.priceList.startDate': 'تاريخ البدء',
  'modal.common.priceList.endDate': 'تاريخ الانتهاء',
  'modal.common.priceList.startDate.tooltip': 'تاريخ بدء قائمة الأسعار',
  'modal.common.priceList.endDate.tooltip': 'تاريخ انتهاء قائمة الأسعار',

  'modal.txDetails.salesQuote.convertToSO': 'تغيير إلى SO',
  'modal.txDetails.salesQuote.approveQuote': 'موافقة على التسعير',
  'modal.txDetails.salesQuote.quoteNumber': 'تسعير#',
  'modal.txDetails.salesQuote.quoteDate': 'تاريخ التسعير',
  'modal.txDetails.salesQuote.quoteExpiryDate': 'تاريخ انتهاء التسعير',
  'modal.txDetails.salesQuote.rfqNumber': ' طلب للتسعير ',
  'modal.txDetails.salesQuote.customerRFQ': 'RFQ العميل',
  'modal.txDetails.salesQuote.customerRFQNumber': 'RFQ العميل',
  'modal.txDetails.salesQuote.deliveryMethod': 'طريقة التوصيل',
  'so.trackingNumber' : 'أدخل رقم المتابعة',
  'so.consignee' : 'مستلم الشحنة',
  'customer.salesComplaint.listing.header.salesComplaintNumber' : 'شكوى #',
  'customer.salesComplaint.listing.header.assignedTo' : 'معينة إلى ',
  'customer.salesComplaint.form.gridHeader.complaintDetails' : 'تفاصيل الشكوى',
  'customer.salesComplaint.listing.header.complaintType' : 'نوع الشكوى',
  'customer.salesComplaint.listing.header.complaintClosedDate' : 'تاريخ إغلاق الشكوى',
  'reason.placeholder' : 'أكتب السبب',
  'forgot.btn.addTransportData' : 'إضف بيانات النقل',

  'salesQuote.sqExpiryDate' : 'تاريخ انتهاء التسعير',

  'package.packageDetail.length' : 'طول',
  'package.packageDetail.width' : 'عرض',
  'package.packageDetail.height' : 'ارتفاع',
  'package.packageDetail.weight' : 'عرض',

  'confirmation.staySignedIn' : 'أبق كمسجل الدخول',
  'confirmation.signout' : 'خروج ',

  // common  number
  'common.paymentNumber': 'دفع المبلغ#',
  'common.invoiceNumber' : 'فاتورة#',  
  'common.customer.poNumber' : 'طلب شراء العميل #',
  'common.customer.inquiryNumber' : 'استفسار العميل #',
  'common.inquiryNumber' : 'استفسار#',
  'common.soNumber' : 'أمر المبيعات#',  
  'common.rfqNumber' : 'طلب للتسعير',  
  'common.poNumber' : 'امر الشراء#',  

  'common.ref': 'مراجعة',
  'modal.txDetails.common.uom': 'يو أو إم',
  'modal.txDetails.common.sku': 'إس كيه يو',

  'invoiceAmount.text': 'مبلغ الفاتورة',
  'paymentAmount.text': 'مبلغ الدفع',
  'common.dueAmount': 'مبالغ مستحقة',
  'paymentTerm.text': 'شوط الدفع',
  'totalOrder.text': 'إجمالية طلبات',
  'openSalesOrder.text': 'طلبات مبيعات مفتوحة',
  'salesRevenue.text': 'إيرادات المبيعات',
  'paidInvoices.text': 'فواتير مدفوعة',
  'communicationType.text': 'نوع الاتصال',

  'common.salesPerson' : 'مندوب المبيعات',
  'common.status' : 'حالة',
  'common.description': 'مواصفات',
  'common.priority': 'اولوية',
  'common.invoiceDate' : 'تاريخ الفاتورة',
  'common.dueDate' : 'تاريخ محدد',
  'common.project': 'مشروع',
  'common.cancel' : 'إلغاء',
  'common.middleName' : 'الإسم الأوسط',
  'common.phone' : 'هاتف',
  'common.customer' : 'عميل',
  'common.supplier' : 'مورد',
  'common.suppliers': 'مورد (ون)',
  
  'common.shipping.address' : 'عنوان الشحن',
  'common.billing.address' : 'عنوان الفواتير',

  'common.materialNumber': 'مواد',

  'partially.fulfilled' : 'استيفاء بشكل جزئي',
  'fulfilled' : 'استيفاء',
  'shipped' : 'تم الشحن',
  'not.shipped' : 'لم يتم الشحن',
  'completed': 'مكتمل',
  'not.started': 'لم يبدأ',
  'not.required': 'غير مطلوب',

  'incorrect.password.entered' : 'تم إدخال كلمة السر الخاطئ. الرجاء إدخال كلمة السر الصحيحة والمحاولة مرة أخرى',
  'insufficient.company.access' : 'You don`t have access to the company, please contact the administrator.',
  'account.suspended' : 'The account is currently suspended. Please contact the administrator.',
  'trial.license.expired' : 'Your trial license has expired. Please contact support.',
  'email.exist' : 'البريد الالكتروني موجود بالفعل. الرجاء تجربة البريد الالكتروني الآخر',
  'delieverd.package.cant.delete' : 'لا يمكن حذف الحزمة لأنه قد تم شحنها.',
  'shipped.package.cant.delete' : 'لا يمكن حذف الحزمة لأنه قد تم شحنها.',
  'delievered.shipment.cant.delete' : 'لا يمكن حذف الشحنة لأنه تم تسليمها',
  'empty.brand.name' : 'إسم العلامة التجارية فارغ. الرجاء كتابة إسم العلامة التجارية وحفظها مرة أخرى',
  'empty.category.name' : 'إسم الصنف فارغ. الرجاء كتابة إسم الصنف وحفظه مرة أخرى.',
  'empty.manufacturer' : 'إسم الشركة المصنعة فارغ. الرجاء كتابة إسم الشركة المصنعة والمحاولة مرة أخرى.',
  'empty.uom.name' : 'UOM فارغ. الرجاء إدخال إسم UOM وحفظه مرة أخرى',
  'verification.code.does.not.match' : 'رمز التحقق لا يتطابق. الرجاء إدخال رمز التحقق الصحيح الذي تم إرسالها على invite email.',
  'missing.relationsip.id' : 'هوية العلاقة غير موجودة',
  'misisng.contact' : 'الاتصال غير موجود. الرجاء إنعاش صفحتك.',
  'primary.contact.cant.delete' : 'لا يمكن حذف الاتصال الرئيسي. أولا، اجعل عنوانا آخر كالاتصال الرئيسي من صفحة الاتصال وحاول مرة أخرى.',
  'missing.address' : 'لا يوجد العنوان. الرجاء إنعاش صفحتك.',
  'cant.delete.project' : 'لا يمكن حذف المشروع لأنه مربوط بصفقة أخرى.',
  'cant.delete.inquiry.so.converted' : 'لا يمكن حذف الاستفسار لأنه تم تغييره إلى تسعير المبيعات',
  'cant.delete.inquiry.pr.converted' : 'لا يمكن حذف الاستفسار لأنه تم تغييره إلى طلب الشراء',
  'cant.update.void.invoice' : 'لا يمكن حذف الفاتورة لأنها أبطلت',
  'cant.delete.delivered.shipment' : 'لا يمكن حذف الشحن الذي تم توصيلها.',
  'qty.to.invoice.error' : 'لا ينبغي إدخال الكمية إلى الفاتورة أكثر منها التي تم أمرها.',
  'invoice.date.blank' : 'تاريخ الفاتورة فارغ. الرجاء إعادة تقديم الطلب.',
  'sequence.id.used' : 'تم استخدام الهوية التسلسلية هذه مسبقا. حاول الهوية  التسلسلية الأخرى.',
  'cant.void.cash.invoice' : 'لا يمكن إبطال فاتورة النقد.',
  'proforma.invoice.exist' : 'فاتورة مبدئية  موجودة بالفعل لأمر المبيعات.',
  'sequence.id.saved' : 'تم حفظ هوية التسلسل بنجاح.',
  'payment.cant.save.on.void.invoice': 'لا يمكن تطبيق الدفع على الفاتورة الباطلة.',
  'cant.update.cancelled.so' : 'لا يمكن تحديث أمر المبيعات لأنه تم إلغاءه.',
  'cant.update.fulfilled.so' : 'لا يمكن تحديث أمر المبيعات لأنه تم استيفاءه.',
  'cant.update.partially.fulfilled.so' : 'لا يمكن تحديث أمر المبيعات لأنه تم استيفاءه بشكل جزئي.',
  'insufficient.access.to.approve.so' : 'ليس لديك الإذن اللازم للموافقة على أمر المبيعات.',
  'so.already.approved' : 'تم الموافقة على أمر المبيعات مسبقا.',
  'cant.delete.so.invoice.created' : 'لا يمكن حذف أمر المبيعات لأنه تم إعداد الفاتورة .',
  'cant.delete.so.partially.packed': 'لا يمكن حذف أمر المبيعات لأن تم تعبئته بشكل جزئي.',
  'cant.delete.so.packed' : 'لا يمكن حذف أمر المبيعات لأنه تم تعبئته.',
  'cant.delete.so.partially.shipped' : 'لا يمكن حذف أمر المبيعات لأنه تم شحنه بشكل جزئي.',
  'cant.delete.so.shipped' : 'لا يمكن حذف أمر المبيعات لأنه تم شحنه.',
  'cant.cancel.so.partially.invoiced' : 'لا يمكن إلغاء أمر المبيعات لأنه تم إعداد الفاتورة بشكل جزئي.',
  'cant.cancel.so.invoiced' : 'لا يمكن إلغاء أمر المبيعات لأنه تم إعداد الفاتورة.',
  'cant.cancel.so.partially.packed' : 'لا يمكن إلغاء أمر المبيعات لأنه تم تعبئته.',
  'cant.cancel.so.packed' : 'لا يمكن إلغاء أمر المبيعات لأنه تم تعبئته والتغليف اكتمال.',
  'cant.cancel.so.partially.shipped' : 'لا يمكن إلغاء أمر المبيعات لأنه تم شحنه بشكل جزئي.',
  'cant.cancel.so.shipped' : 'لا يمكن إلغاء أمر المبيعات لأنه تم شحنه كاملا.',
  'no.permission.reject.so' : 'ليس لديك الإذن اللازم لرفض أمر المبيعات..',
  'quote.already.approved' : 'تم الموافقة على التسعير مسبقا.فحص مسار المراجعة للتفاصيل.',
  'cant.delete.quote.converted.to.so': 'لا يمكن حذف التسعير لأنه تم تغييره إلى أمر المبيعات.',
  'customer.incorrect.upload.template' : 'يظهر أنه تم استخدام نموذج خاطئ للعميل. يمكنك تحميل النموذج الحالي والمحاولة مرة أخرى.',
  'customer.incorrect.country.template' : 'أنت تحمل نموذجا خاطئا للبلد. يمكنك تنزيل النموذج والمحاولة مرة أخرى لتحميله.',
  'customer.upload.gst.15.char.required' : 'يجب أن يكون رقم ضريبة السلع والخدمات 15 حرفا.',
  'customer.upload.pan.10.char.required' : 'يجب أن يكون رقم الحساب الدائم 10 حرفا.',
  'customer.upload.invalid.relatioship.date' : 'تاريخ العلاقة باطل. الرجاء إدخال التاريخ الصحيح في عمود تاريخ العلاقة والمحاولة مرة أخرى.',
  'customer.upload.invalid.date.format' : 'تاريخ العلاقة غير صحيح إذ ينبغي أن يكون التاريخ بالشكل التالي: ي ي/ش ش/س س س س.',
  'customer.upload.website.length' : 'ينبغي أن لا يكون إسم موقع الويب أكثر من 70 حرفا.',
  'customer.upload.company.name.length' : 'ينبغي أن لا يكون إسم الشركة أكثر من 75 حرفا.',
  'customer.upload.email.length' : 'لن يكون عنوان البريد الالكتروني أكثر من 75 حرفا.',
  'customer.upload.fax.length' : 'لايمكن أن يكون رقم الفاكس أكثر من 15 حرفا.',
  'customer.upload.first.name.length' : 'لا يمكن أن يكون الإسم الأول أكثر من 20 حرفا.',
  'customer.upload.job.title' : 'لا يمكن أن يكون اللقب المهني أكثر من 20 حرفا.',
  'customer.upload.last.name.length' : 'لا يمكن أن يكون الإسم الآخر أكثر من 20 حرفا.',
  'customer.upload.middle.name' : 'لا يمكن أن يكون الإسم المتوسط أكثر من 20 حرفا.',
  'customer.upload.department.name.length' : 'لا يمكن أن يكون إسم القسم أكثر من 30 حرفا.',
  'customer.upload.call.name.length' : 'لا يمكن أن يكون أفضل الإسم للاتصال أكثر من 20 حرفا.',
  'customer.upload.work.phone.length': 'لا يمكن أن يكون رقم هاتف العمل أكثر من 15 حرفا.',
  'customer.upload.city.length' : 'لا يمكن أن يكون إسم المدينة أكثر من 45 حرفا.',
  'customer.upload.street1.length' : 'لا يمكن أن يكون عنوان الشارع1 أكثر من 75 حرفا.',
  'customer.upload.street2.length' : 'لا يمكن أن يكون عنوان الشارع2 أكثر من 75 حرفا.',
  'customer.upload.postal.length' : 'لا يمكن أن يكون أن يكون الرمز البريدي/المنطقي أكثر من 25 حرفا.',
  'Customer uploaded successfully' : 'تم تحميل العميل بنجاح.',
  'customer.upload.fail.empty.file' : 'لم يتم تحميل العميل. قد يكون الملف فارغا. الرجاء فحص الملف والمحاولة مرة أخرى.',
  'cant.delete.customer' : 'لا يمكن حذف العميل لأن واحد أو أكثر الصفقات مربوطة بالعميل/العملاء.',
  'customer.upload.cellphone.length' : 'لا يمكن أن يكون رقم الجوال أكثر من 15 حرفا.', 
  'stock.already.updated' : 'تم تحديث المخزون مسبقا لهذه الشحنة.',
  'new.inventory.warehouse.required' : 'الرجاء اختيار مخزون',
  'new.inventory.purchase.price.required.for.current.stock' : 'سعر الشراء مطلوب للمخزون الحالي لتقييم المخزون.',
  'new.inventory.invalid.variant' :'تم إدخال الحرف الخاطئ للبديل.',
  'new.inventory.sku.required' :'الرجاء توفير SKU',
  'new.inventory.invalid.tax' :' تم توفير الضريبة الخاطئة. الرجاء تحديد الضريبة الصحيحة من ملف إكسل المحملة.',
  'new.inventory.invalid.hsn' : 'تم توفير زمز خاطئ لشبكة التسوق المنزلي. قم باختيار رمز شبكة التسوق المنزلي من عمود رمز شبكة التسوق المنزلي من ملف إكسيل.',
  'new.inventory.missing.variant' : 'قيمة متباينة غير موجودة. الرجاء إدخال قيمة متباينة والمحاولة مرة أخرى.',
  'inventory.upload.incorrect.template' : 'يظهر أنه تم استخدام النموذج الخاطئ. يمكنك تنزيل النموذج الحالي من قافزة استيراد المخزون والمحاولة للتحميل مرة أخرى.',
  'inventory.upload.duplicate.sku' : 'تم العثور علىSKU ثنائي ',
  'inventory.upload.incorrect.variant.attribut1' : 'خاصية متغيرة1 خاطئة قم باختيار من المربع المنسدل. الرجاء اختيار القمية الصحيحة من عمود خاصية متغيرة1وحاول للتحميل مرة أخرى.',
  'inventory.upload.incorrect.variant.attribut2' : 'تم اختيارخاصية متغيرة2  من المربع المنسدل. الرجاء اختيار القيمة الصحيحة من عمود خاصية متغيرة 2 والمحاولة للتحميل مرة أخرى.',
  'inventory.upload.incorrect.variant.attribut3' : 'تم اختيارخاصية متغيرة3  من المربع المنسدل. الرجاء اختيار القيمة الصحيحة من عمود خاصية متغيرة32 والمحاولة للتحميل مرة أخرى.',
  'inventory.upload.purchase.price.missing' : 'سعر الشراء مطلوب للمخزون الحالي لتقييم المخزون.',
  'inventory.upload.invalid.char.variant1' : 'تم إدخال الحرف الخاطئ لخاصية 1.قم بتصحيح الخاصية1 وحاول مرة أخرى.',
  'inventory.upload.invalid.char.variant2' : 'تم إدخال الحرف الخاطئ لخاصية 1.قم بتصحيح الخاصية2 وحاول مرة أخرى.',
  'inventory.upload.invalid.char.variant3' : 'تم إدخال الحرف الخاطئ لخاصية1.قم تصحيح الخاصية3 وحاول مرة أخرى.',
  'inventory.upload.part.length' : 'لا يمكن أن يكون رقم الجزء أكثر من 20 حرفا.',
  'inventory.upload.stocknumber.length' : 'لا يمكن أن يكون رقم المخزون أكثر من 20 حرفا.',
  'inventory.upload.upc.length' : 'لا يمكن أن يكون UPC أكثر من 12 حرفا.' ,
  'inventory.upload.ean.length': 'لا يمكن أن يكون EAN أكثر من 13 حرفا.',
  'inventory.upload.isbn.length' : 'لا يمكن أن يكون الرقم الدولي المحدد للكتاب أكثر من 13 حرفا.',
  'inventory.upload.description' : 'لا يمكن أن يكون المواصفة أكثرمن 1000 حرف.',
  'inventory.upload.item.name' : 'لا يمكن أن يكون إسم السلعة أكثر من 75 حرفا.',
  'inventory.upload.variant value' : 'لا يمكن أن يكون إسم قيمة الخاصية1أكثر من 20 حرفا.',
  'inventory.upload.variant.value2' : 'لا يمكن أن يكون إسم قيمة الخاصية2 أكثر من 20 حرفا.',
  'inventory.upload.variant.value3' : 'لا يمكن أن يكون إسم قيمة الخاصية3 أكثر من 20 حرفا.',
  'inventory.upload.sku.length' : 'لا يمكن أن يكون SKU أكثر من 20 حرفا.',
  'inventory.upload.purchase.price.required.current.stock' : 'لتقييم المخزون سعر الشراء مطلوب للمخزون الحالي.',
  'inventory.upload.first.record.missing' : 'وجد السجل الأول بدون إسم السلعة في إكسيل. الرجاء توفير إسم السلعة.',
  'no.product.imported' : 'لم يتم استيراد أي سلعة. يمكن أن يكون الملف فارعا. الرجاء فحص الملف وتحميله مرة أخرى.',
  'can`t.delete.item.po' : 'لا يمكن حذف السلعة لأنه تم استخدامها في أمر الشراء.',
  'can`t.delete.item.pi' : 'لا يمكن حذف السلعة لأنه تم استخدامها في فاتورة الشراء.',
  'can`t.delete.item.dm': 'لا يمكن حذف السلعة لأنه تم استخدامها في مذكرة المدين.',
  'can`t.delete.item.so' : 'لا يمكن حذف السلعة لأنه تم استخدامها في أمر المبيعات.',
  'can`t.delete.item.si' : 'لا يمكن حذف السلعة لأنه تم استخدامها في فاتورة المبيعات.',
  'can`t.delete.item.cm' : 'لا يمكن حذف السلعة لأنه تم استخدامها في مذكرة الائتمان.',
  'insufficient.access.to.approve.qty.change' : 'ليس لديك الإذن اللازم للموافقة على تغيير الطلب.',
  'insufficient.access.to.delegate.qty.change' : 'ليس لديك الإذن اللازم لتفويض طلب تغييرالكمبة.',
  'insufficient.access.to.delegate.price.change' : 'ليس لديك الإذن اللازم لتفويض طلب تغيير السعر.',
  'insufficient.access.to.reject.price.change' : 'ليس لديك الإذن اللازم لرفض طلب تغيير السعر.',
  'insufficient.access.to.reject.qty.change' : 'ليس لديك الإذن اللازم لرفض طلب تغيير السعر.',
  'insufficient.access.to.approve.price.change' : 'ليس لديك الإذن اللازم للموافقة على طلب تغيير السعر.',
  'cant.delete.warehouse.stock.exist' : 'لا يمكن حذف المخزون لأن هناك سلعة /سلع المخزون تم تعيينها إلى هذا المخزون.',
  'warehouse.deleted' : 'تم حذف المخزون بنجاح.',
  'cant.delete.system.doc' : 'لا يمكنك حذف إسم مستندات المملوكه لدى النظام.',
  'email.not available' : 'البريد الالكتروني موجود مسبقا. الرجاء المحاولة بالبريد الالكتروني الآخر.',
  'cant.delete.primary.user' : 'لا يمكن حذف المستخدم الرئيسي.',
  'cant.delete.employee' : 'لدى المستخدم حق الوصول إلى Alpide. الرجاء إبطال حق وصول المستخدم قبل الحذف. للإبطال، إذهب في الإعدادات/إدارة المستخدم.',
  'cant.delete.manager' : 'لا يمكن حذف الموظف لأن الموظف مدير والموظفون الآخرون يتبعون المدير.',
  'cant.delete.rfq.converted.to.po' : 'لا يمكن حذف RFQ لأنه تم تغييره إلى أمر الشراء.',
  'pr.converted.to.rfq' : 'تم تغيير RFQ إلى أمر الشراء مسبقا.',
  'cant.update.rfq.converted.to.po': 'لا يمكن تحديث RFQ لأنه تم تغييره إلى أمر الشراء.',
  'not.enough.stock.to.update' : 'لا يوجد مخزون كاف لتحديث المخزون. يمكنك إلغاء تحديد حقل "تحديث المخزون" وإعادة إرسال الطلب',
  'cant.delete.id.invoice.done' : 'لا يمكن حذف التوصيل الداخلي لأن إعداد الفاتورة قد تم.',
  'qtytoinvoice.more.than.qty.ordered': 'لا ينبغي أن تكون الكمية في الفاتورة أكثر من الكمية تم أمرها.',
  'invoice.id.saved': 'تم حفظ هوية تسلسل الفاتورة بنجاح.',
  'payment.sequence.id.saved' : 'تم حفظ هوية تسلسل الفاتورة بنجاح.',
  'cant.apply.payment.void.invoice' : 'لا يمكن تطبيق الدفع على الفاتورة الباطلة',
  'cant.update.cancel.po': 'لا يمكن تحديث أمر الشراء لانه تم إلغاءه',
  'cant.update.fulfilled.po' : 'لا يمكن تحديث أمر الشراء لانه تم استفاءه.',
  'cant.update.partially.fulfilled.po' : 'لا يمكن تحديث أمر الشراء لانه تم استيفاءه بشكل جزئي.',
  'insufficient.access.to.approve.po': 'ليس لديك الإذن اللازم للموافقة على أمر الشراء.',
  'cant.canceled.partially.invoiced.po' : 'لا يمكن إلغاء أمر الشراء لأنه تم إعداد فاتورته بشكل جزئي.',
  'cant.canceled.invoiced.po' : 'لا يمكن إلغاء أمر الشراء لأنه تم إعداد فاتورته بالكامل.',
  'cant.delete.pr.convertedto.rfq' : 'لا يمكن حذف أمر الشراء لأنه تم تغييره إلى RFQ',
  'cant.delete.pr.convertedto.po' : 'لا يمكن حذف طلب الشراء لأنه تم تغييره إلى أمر الشراء',
  'upload.supplier.incorrect.template' : 'يظهر أنه استخدم نموذجا خاطئا للمورد. يمكنك تنزيل النموذج الحالي والمحاولة للتحميل مرة أخرى',
  'supplier.upload.future.relationship.date.error' : 'لا يمكن أن تكون العلاقة منذ التاريخ تاريخ المستقبل. الرجاء تصحيح التاريخ والتحميل مرة أخرى.',
  'supplier.upload.relationship.date.incorrect.format' : 'صيغة التاريخ خاطئة للعلاقة منذ التاريخ. ينبغي أن تكون بشكل تالي: ي ي/ش ش/س س س س.',
  'supplier.upload.website.length' : 'ينبغي أن لا يكون إسم موقع الويب أكثر من 70 حرفا.',
  'supplier.upload.supplier.name.length' : 'لا يمكن أن يكون إسم المورد أكثر من 75 حرفا.',
  'one.supplier.imported' : 'تم استيراد أسم/أسماء المورد بنجاح.',
  'suppliers.imported' : 'تم استيراد أسم/أسماء المورد بنجاح.',
  'supplier.not.imported' : 'لم يتم استيراد إي مورد. قد يكون الملف فارغا. الرجاء فحص الملف والمحاولة مرة أخرى.',
  'cant.delete.supplier.tx.exist' : 'لا يمكن حذف المورد لأن واحدا أو أكثر من الصفقاف مرتبطة بالمورد (ين).',
  'invalid.date.format' : 'تم توفير التاريخ الخاطئ. ينبغي أن يكون التاريخ بشكل تالي: ي ي/ ش ش /س س س س',
  'missing.day' : 'يوم فارغ لـ علاقة منذ (تاريخ).الرجاء توفير  القيمة لـ يوم',
  'missing.month' : 'شهر فارغ لـ علاقة منذ (تاريخ)الرجاء توفير قيمة لـ شهر',
  'missing.year' : 'سنه فارغة لـ علاقة منذ (تاريخ)الرجاء توفير قيمة لـ سنة.',
  'invalid.day' : 'يوم خاطئ لـ علاقة منذ (تاريخ).لا يمكن أن يكون اليوم أكثر من 31.',
  'invalid.month' : 'شهر خاطئ لـ علاقة منذ (تاريخ).لا يمكن أن يكون الشهر أكثر من 12.',
  'invalid.login.email' : 'حساب لـ Alpide مع هذا البريد الالكتروني لا يوجد. الرجاء إدخال البريد الالكتروني الصحيح أو سجل الدخول مع Alpide',
  'signup.email.unavailable': 'البريد الالكتروني لا يتوفرلتسجيل الدخول. الرجاء استخدام شئ آخر.',
  'email.not.available' : 'البريد الإلكتروني موجود في النظام. استخدم عنوان بريد إلكتروني مختلف',
  'root.hierarchy.exist' : 'تسلسل جذري موجود فعلا. يمكن أن يكون تسلسل جذري واحد فقط. صوب الخطأ وحاول مرة أخرى.',
  'sequence.id.rest.success' : 'نجحت إعادة تعيين معرف مذكرة الائتمان.',
  'sequence.rest.success' : 'إعادة تعيين التسلسل تم بنجاح.',
  'sales.invoice.already.approved' : 'تم الموافقة على فاتورة المبيعات مسبقا. افحص تفاصيل مسار المراجعة.',
  'cancelled' : 'تم الإلغاء',
  'delivered' : 'تم التوصيل',
  'partially.paid' : 'مدفوع بشكل جزئي',
  'paid' : 'مدفوع',
  'void': 'Void',
  'unpaid' :'غير مدفوع',
  'new' : 'جديد',
  'open' : 'افتح',
  'in.process' : 'في طور',
  'approved' : 'تم الموافقة',
  'rejected' : 'مرفوض',
  'pending.approval' : 'الموافقة معلقة',
  'not.applicable' : 'غير منطبق',
  'partially.received' : 'تم الاستلام بشكل جزئي',
  'received' : 'تم الاستلام',
  'over.tolerance' :'انتهى التحمل',
  'pending' : 'معلق',
  'converted.partially' : 'تم التغيير بشكل جزئي',
  'partially.cConverted.to.pr' : 'تم التغيير بشكل جزئي إلى طلب الشراء',
  'converted' : 'تم التغيير',
  'invoiced' : 'تم إعداد الفاتورة',
  'not.invoiced' : 'لم يتم إعداد الفاتورة',
  'not.received' : 'لم يتم الاستلام',
  'converted.to.lead' : 'تم التغيير إلى ليد',

  'package.doesnot.exist' : 'الحزمة لا توجد',
  'shipment.doesnot.exist' : 'الشحنة لا توجد. يمكن إيجاد الشحنة لحزمة (مات).',
  'sales.invoice.doesnot.exist' : 'لم يتم إعداد الفاتورة لأمر المبيعات.',

  'package.doesnot.exist2': 'لإيجاد حزمة جديدة',
  'shipment.doesnot.exist2': 'لإيجاد شحنة جديدة',
  'sales.invoice.doesnot.exist2' : 'لإيجاد فاتورة جديدة',

  'supplier.businessExpense.listing.header.expenseNo': 'تكلفة#',
  'supplier.businessExpense.listing.header.refNumber': 'مراجعة',
  'supplier.businessExpense.listing.header.expenseDate': 'تاريخ التكلفة',
  'supplier.businessExpense.listing.header.expenseAmount': 'مبلغ',
  
  'modal.common.newTax.taxName': 'إسم ضريبة',
  'modal.common.newTax.taxRate': 'معدل الضريبة',
  'showAllPackages.text': 'إظهار جميع الحزمات',

  'clickhere': 'أضغط هنا',
  'certifcateOfConformity.text': 'شهادة الموافقة',
  'salesOrderDetails.text': 'تفاصيل أمر المبيعات',
  'shipmentDetails.text': 'تفاصيل الشحنة',
  'invoiceStatus.text': 'حالة الفاتورة',
  'paymentStatus.text': 'حالة الدفع',

  'customer.proformaInvoice.form.confirmation': 'هل أنت متأكد أنك تريد إنشاء فاتورة أولية؟',

  'location.type.missing' : 'نوع المحل لا يوجد',
  'email.doesnot.exist': 'لم يقدر الجهاز على العثور على البريد الالكتروني الذي تم إدخاله. الرجاء إدخال البريد الالكتروني الذي استخدمته لتسجيل الدخول وحاول مرة أخرى.',
  'account.already.activated' : 'تم تفعيل الحساب مسبقا.',  
  'user.saved' : 'تم حفظ المستخدم بنجاح',

  'category.group.exist' : 'مجموعة الأصناف موجودة فعلا.',
  'category.exist' : 'الصنف موجود فعلا',
  'start.end.account.number.rule.error' : 'لن يكون رقم حساب البداية أكبر من الحساب الانتهاء.',
  'start.account.zero.error' :'يجب أن يكون حساب البداية اقل من صفر.',
  'start.end.account.cant.be.same' : 'يجب أن لا يكون رقم حساب البداية مثل رقم حساب الانتهاء.',
  'account.range' : 'نطاق الحساب ',
  'is.reserved.for' : ' مخصص لـ',
  'stock.not.found' : 'المخزون لم يوجد',
  'product.name.min.4.char' : 'يجب أن يكون إسم السلعة 2 حرفا على الأقل.',
  'invalid.purchased.price' : 'تم إدخال سعر الشراء الخاطئ لواحدة من السلع.',
  'invalid.retail.price' : 'تم إدخال سعر التجزئة الخاطئ لواحدة من السلع.',
  'invalid.wholesale.price' : 'تم إدخال سعر المخزون الخاطئ لواحدة من السلع.',
  'invalid.current.stock' : 'تم إدخال سعر المخزون الخاطئ. الرجاء إدخال القيمة الصحيحة.',
  'low.stock.must.be.number' : 'يجب أن يكون تنبيه كمية إنخفاض المخزون رقما',
  'reorder.stock.must.be.number' : 'يجب أن يكون تنبيه كمية مسجلة المخزون رقما',
  'product.impoorted' : 'تم استيراد السلعة بنجاح',

  'package.shipped' : 'لا يمكن إنشاء الشحنة للطرود المشحونة',
  'package.not.selected' : 'تعذر العثور على الحزمة. الرجاء تحديد الحزمة والمحاولة مرة أخرى',
  'customer.salesInvoice.listing.header.invoiceConverted': 'الفاتورة المحولة',
  'salesComplaint.create.validation.message': 'الرجاء تحديد رقم أمر المبيعات وتفاصيل الشكوى',
  
  'finance.JournalDetail.journal.label': 'مجلة',
  'finance.JournalDetail.journalDate': 'تاريخ المجلة',
  'finance.JournalDetail.journalNumber': 'رقم المجلة',
  'finance.JournalDetail.ledgerAccount': 'حساب دفتر الأستاذ',
  'finance.JournalDetail.description': 'مواصفات',
  'finance.JournalDetail.debit': 'مدين',
  'finance.JournalDetail.credit': 'ائتمان',
  'finance.JournalDetail.total': 'مجموع',
  'finance.JournalDetail.narration': 'سرد',
  'finance.journal.form.confirmation': 'هل أنت متأكد أنك تريد إنشاء المجلة?',
  'address.type': 'نوع العنوان',
  'address.type.tooltip': 'حدد نوع العنوان',

  'drawer.customer.address.note.text': 'ملاحظات',
  'drawer.customer.address.note.message1': 'يمكنك إضافة وإدارة عناوين إضافية من قسم تفاصيل الاتصال.',
  // 'drawer.customer.address.note.message2': 'View and edit the address format of your transactions under Customer Detail page.',
  // 'so.shipmentName' : 'أدخل إسم الشحن',
  'so.shipmentName' : 'أدخل رقم الشحنة', // changed from name to number
  'popup.recurringInvoice.title': 'إعداد الفاتورة المتكررة',
  'in.progress': 'In Progress',
  'supplier.rfq.form.draft.confirmation': 'هل أنت متأكد أنك تريد الحفظ كمسودة؟',
  'modal.reports.partyLedger.closingAmount.dr.label' : 'مبلغ الإغلاق - الخصم',
  'modal.reports.partyLedger.closingAmount.cr.label' : 'مبلغ الإغلاق - الائتمان',

  'recurringInvoice.text': 'فاتورة متكررة',
  'recurringInvoice.setup.neverExpires': 'لا تنتهي صلاحيته',
  'recurringInvoice.setup.daily': 'يوميا',
  'recurringInvoice.setup.weekly': 'أسبوعي',
  'recurringInvoice.setup.monthly': 'متوسطة ',
  'recurringInvoice.setup.yearly': 'سنوي',
  'recurringInvoice.setup.generatedOn.message': 'سيتم إنشاء الفاتورة في',
  'recurringInvoice.setup.daily.message': 'سيتم إنشاء الفاتورة يوميًا',
  'recurringInvoice.setup.weekly.message': 'من كل أسبوع',
  'recurringInvoice.setup.monthly.message': 'من كل شهر',
  'recurringInvoice.setup.yearly.message': 'من كل عام',
  'recurringInvoice.setup.emailCustomer': 'عميل البريد الإلكتروني',
  'sunday' : 'الأحد',
  'monday' : 'الاثنين',
  'tuesday' : 'يوم الثلاثاء',
  'wednusday' : 'الأربعاء',
  'thursday' : 'يوم الخميس',
  'friday' : 'جمعة',
  'january' : 'يناير',
  'february' : 'شهر فبراير',
  'march' : 'مارس',
  'april' : 'أبريل',
  'may' : 'يمكن',
  'june' : 'يونيو',
  'july' : 'تموز',
  'august' : 'أغسطس',
  'september' : 'سبتمبر',
  'october' : 'اكتوبر',
  'november' : 'شهر نوفمبر',
  'december' : 'ديسمبر'
  
};

export const ar = lang;
