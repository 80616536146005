import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from '../../../utils';
import SalesComplaintDrawerComp from '../../../components/drawer/salesComplaint';
import { showModal, hideModal, fetchAllEmployees, pushModalToStack,popModalFromStack } from './../../../actions/commonActions';
import { fetchSalesComplaintTypes, addSalesComplaintType, deleteSalesComplaintType, createOrUpdateComplaint, getNextTxNumber } from './action';
import { fetchSalesOrders } from '../../customer/salesOrder/Listing/action';
import { fetchSODetail } from '../../modal/modalBody/customer/SODetail/action';
import { CONSTANTS } from '../../../static/constants';
import { fetchSalesInvoices } from '../../customer/invoice/Listing/action';
import { fetchSalesInvoiceDetail } from '../../modal/modalBody/customer/SalesInvoiceDetail/action';
import { fetchComplaints,getComplaintsCount } from '../../customer/salesComplaint/Listing/action';

class SalesComplaintDrawer extends Component {
  constructor(props) {
    super(props);
    const needToInIt = props.isUpdate  ? true : false;
    let complaintData = props.complaintData || {};
    this.state = {
      ...complaintData,
        selectItem: (complaintData.customerSalesComplainItemRef || [])?.map(obj=>obj.itemId),
        orderComplaint:needToInIt?
        complaintData.salesOrderNumber?1:0
        :1
    }
  }


  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    // let complaintData = props.complaintData || {};
    const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.customerId, soMasterId: this.props.soMasterId, pageNumber: 1,
      pageSize: 1000};
    fetchPaginationDataIfNeeded('fetchSalesOrders', 'soList', this.props, payload);
    fetchDataIfNeeded('fetchSalesComplaintTypes', 'complaintTypeList', this.props, payload);
    this.props.fetchAllEmployees(payload);
    fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    this.props.fetchSalesInvoices(payload)
    const payload2 = {
      relationshipId: companyInfo.relationshipId,
      customerId: this.state.customerId,
      salesOrderMasterId: this.state.soMasterId,
      invoiceMasterId:this.state.invoiceMasterId
    }
    this.props.isUpdate && this.state.soMasterId && this.props.fetchSODetail(payload2);
    this.props.isUpdate && this.state.invoiceMasterId && this.props.fetchSalesInvoiceDetail(payload2);

  }
  componentWillReceiveProps(props){
    // props.isUpdate 

  }

  render() {
    return <div>
      <SalesComplaintDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    soList: state.so.soList,
    complaintTypeList: state.salesComplaint.salesComplaintTypeList,
    allEmployee : state.common.employees,
    nextTxNumber: state.salesComplaint.nextTxNumber,
    soDetail: state.so.soDetail,
    salesInvoiceList: state.salesInvoice.salesInvoiceList,
    salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,
    complaintCount: state.salesComplaint.complaintCount,


  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  fetchSalesComplaintTypes, 
  addSalesComplaintType, 
  deleteSalesComplaintType,
  fetchSalesOrders,
  createOrUpdateComplaint,
  fetchAllEmployees,
  getNextTxNumber,
  fetchSODetail,
  fetchSalesInvoices,
  fetchSalesInvoiceDetail,
  fetchComplaints,
  pushModalToStack,
  popModalFromStack,
  getComplaintsCount

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesComplaintDrawer);
