import { Button, Input, Radio } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
const { Search } = Input;


const CategoryModel = (props) => {
    const [searchText, setSearchText] = useState("");
    const [productTaxCode, setProductTaxCode] = useState(props.productTaxCode||''); 
      

    function selectChangeHandler(e) {
        const selectedTaxCodeCategory = props.taxjarCategories.find(
          (category) => category.productTaxCode === e.target.value
        );
        setProductTaxCode(selectedTaxCodeCategory?.productTaxCode);
        props.updateState({
          productTaxCodeCatName: selectedTaxCodeCategory?.name,
          productTaxCode: selectedTaxCodeCategory?.productTaxCode,
          selectedTaxCodeCategory,
        });
      }
    
      const handleCategorySelect = () => {
        props.popModalFromStack();
      };
    
     

  return (
    <>
    <div className="cat-search-wrapper">
    <Search
      placeholder="Search categories or subcategories..."
      value={props.searchCategories}
      onChange={(e) => {
         setSearchText(e.target.value);         
      }}  
    />
    </div>
    <div className="parent-category-list">
      <Radio.Group
        onChange={selectChangeHandler}
         value={productTaxCode}
        width="100%"
      >
          {(searchText ? props.taxjarCategories.filter((category) => {
        return category.name?.toLowerCase().includes(searchText?.toLowerCase());
      })
      : props.taxjarCategories)
          .map((category) => (
              <div
                key={category.productTaxCode}
                className="radio-button-Wrapper"
              >
                <Radio
                  key={category.name}
                  value={category.productTaxCode}
                >
                  <div className="parent-category-sub-wrapper">
                   
                    <div className="parent-category-details">
                      <h2>{category.name} <span>({category.productTaxCode})</span></h2>
                      <p>{category.description}</p>
                    </div>
                  </div>
                </Radio>
              </div>
             
          ))}
      </Radio.Group>
      <div className="modal-footer">
        <div className='customModal-button-group'>
      {/* <div className="left-fItems"> */}
        <Button
          className="grey-button"
          onClick={() => {
            props.popModalFromStack();

          }}
        >
          <FormattedMessage id="button.cancel.label" defaultMessage="" />
        </Button>
        <Button
          type="primary"
          onClick={() => {
            handleCategorySelect();
          }}
        >
          <FormattedMessage id="button.save.label" defaultMessage="" />
        </Button>
        </div>
        </div>
      {/* </div> */}
    </div>
  </>
  )
}

export default CategoryModel