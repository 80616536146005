import React from 'react';

import {
    FilePdfOutlined,
    FileTextOutlined,
    MailOutlined,
    PrinterOutlined
} from '@ant-design/icons';

import {Row, Col, Button} from 'antd'
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS } from '../../../../../static/constants';
import config from '../../../../../config/environmentConfig';
import { LogoComponent } from '../../../../general/LogoComponent';
import { getMomentDateForUIReadOnly } from '../../../../../utils';

const SupplierExpenseDetails =  (props) =>{
    const { expenseDetail, companyInfo } = props;
    const { expenseDetailsList } = expenseDetail;
    const relationshipAddress = {};

    return (
        <div className={"custom-modal show"}>
        <i className="close-icon">
                <svg width="17" height="17" onClick={() =>{props.popModalFromStack();}}>
                    <title>Close</title>
                    <path fill="#67757d" fillRule="evenodd"
                          d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"/>
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="modal-header">
                        Expense
                    </div>
                    <div className="inner-content">
                        <Row>
                            <Col span={18}>
                            <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/>
                            {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()}/> */}
                                <div className="info">
                                    <div className="title">
                                        {props.companyInfo.storeName}
                                    </div>
                                    {(relationshipAddress.streetAddress1 && relationshipAddress.streetAddress1+', ' ) || ''}
                                    {(relationshipAddress.streetAddress2 && relationshipAddress.streetAddress2 +', ') || ''}
                                    {(relationshipAddress.cityName && relationshipAddress.cityName+', ') || ''}
                                    {(relationshipAddress.stateName && relationshipAddress.stateName+', ') || ''}
                                    {(relationshipAddress.zipCode && relationshipAddress.zipCode+' ') || ''}
                                    <div>
                                        GSTIN - {companyInfo.gstNumber || ''}
                                    </div>
                                    <div>PAN - {companyInfo.panNumber || ''}</div>
                                </div>
                            </Col>


                            <Col span={6}>
                                <div className="info">
                                    <div>
                                        <span className="title">Expense No. - </span>
                                        {expenseDetail.expenseNumber}
                                    </div>

                                    <div>
                                        <span className="title"> Expense Date - </span>
                                        {(expenseDetail.expenseDate  && getMomentDateForUIReadOnly({date: expenseDetail.expenseDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                    </div>
                                    { expenseDetail.costCenterName &&
                                        <div>
                                            <span className="title"> Cost Center Name - </span>
                                            { expenseDetail.costCenterName }
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>

                        <div className="modal-table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th>Payment Mode</th>
                                    <th>Reference No</th>
                                    <th>Billing Address</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{ expenseDetail.paymentModeName || '-'}</td>
                                    <td>{ expenseDetail.refNumber || '-'}</td>
                                    <td>{ expenseDetail.address || '-'}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Expense Type</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(expenseDetailsList || []).map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{item.expenseTypeName || '-'}</td>
                                            <td>{item.description || '-'}</td>
                                            <td>{item.expenseAmount || '-'}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>

                        <Row>
                            <Col span={6}>
                                <div className="info">
                                    <div className="title">Customer Remarks</div>
                                    {ReactHtmlParser(expenseDetail.remarksInternal)}
                                </div>
                            </Col>

                            <Col offset={9} span={9}>
                                <div className="bill-summary mt20 mr15">
                                    <div className="pt25">
                                        <Row>
                                            <Col span={14}>
                                                <div className="right-txt bold">
                                                    Grand Total
                                                </div>
                                            </Col>

                                            <Col span={10}>
                                                {expenseDetail.expenseTotalAmount ? expenseDetail.expenseTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ''}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <div className="upload-link">
                                    Upload Stamp/Signature
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="button-group-action">
                        <Button
                            type="primary"
                            icon={<MailOutlined />}
                        >
                            Email
                        </Button>

                        <Button
                            type="primary"
                            icon={<FilePdfOutlined />}
                        >
                            Download
                        </Button>
                        <Button
                            type="primary"
                            icon={<PrinterOutlined />}
                        >
                            Print
                        </Button>
                        <Button
                            type="primary"
                            icon={<FileTextOutlined />}
                        >
                            Convert to Invoice
                        </Button>

                        <Button onClick={() =>{
                            props.popModalFromStack();
                        }}
                                type="default"
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
            </div>
    );

}

export default SupplierExpenseDetails;
