import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import MulticurrencySalesOrderComp from '../../../../components/customer/salesOrder/MultiCurrencySalesOrder';
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import { fetchCustomers } from '../../Listing/action';
import { fetchWarehouseNames } from '../../../drawer/inventory/action';
import {
  fetchAllSalesOrderSources, addSalesOrderSources, deleteSalesOrderSources, createSalesOrder,
  fetchSQDataForSO, resetSalesQuoteSOData, fetchSOData, resetSODetails, getNextTxNumber
} from './action';
import {
  fetchPriceList, deletePriceList, fetchPaymentTerms, fetchProjects, fetchStates, fetchAllEmployees, deleteEmployee, deleteContact,
  fetchTaxes, deleteTax, fetchUOM, addUOM, deleteUOM, fetchExpenseTypes, addExpenseTypes, deleteExpenseTypes, fetchProducts,
  fetchDocuments, addDocumentName, deleteDocumentName,
  showModal, hideModal, pushModalToStack, popModalFromStack,
  fetchCustomerDetailsByCustomerId, addPaymentTerm, deletePaymentTerm, deleteProject, deleteAddress, fetchCountries,
  fetchUserPreferences, saveUserPrefrences, fetchRelationshipTaxIdentifications, deleteCustomerTaxIdentifications, deleteRelationshipTaxIdentifications,
  fetchIncoterms, addIncoterm, deleteIncoterm, resetCustomerDetail, fetchAddresses, getRelationshipAddress
} from '../../../../actions/commonActions';
import LedgerDrawer from '../../../../containers/drawer/ledgers/SalesOrderLedger';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded, addDaysToDate } from '../../../../utils';
import SODetail from '../../../modal/modalBody/customer/SODetail';
import { CONSTANTS, LEDGER_TYPE, LEDGER_ACTION_LIST } from '../../../../static/constants';
import { deleteCustomers } from '../../Listing/action';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import { fetchAllContacts } from '../../Listing/ShowConact/action';

class MulticurrencySalesOrder extends Component {
  constructor(props) {
    super(props);
    const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES }) || {};
    const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE }) || {};
    const defaultSalesOutputLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX }) || {};
    const defaultSalesDiscountLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_DISCOUNT }) || {};
    const defaultDebtorsLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEBTORS }) || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
    this.state = {
      salesOrderDate: new Date(),
      customer: linkProps.customer,
      customerName: (linkProps.customer || {}).companyName,
      stateCustomerId: linkProps.customerId,
      pageNumber: linkProps.pageNumber || 1,
      priceListName: undefined,
      pageSize: 100,
      salesLedgerId: defaultSalesLedger.ledgerAccountId,
      salesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      expenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      expenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      taxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
      discountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,

      igstSalesLedgerId: defaultSalesLedger.ledgerAccountId,
      igstSalesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      igstExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      igstExpenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      igstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      igstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      igstSalesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
      igstDiscountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,

      cgstSalesLedgerId: defaultSalesLedger.ledgerAccountId,
      cgstSalesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      cgstExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      cgstExpenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      cgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      cgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      cgstSalesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
      cgstDiscountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,

      sgstSalesLedgerId: defaultSalesLedger.ledgerAccountId,
      sgstSalesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      sgstExpenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      sgstExpenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      sgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      sgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      sgstSalesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
      sgstDiscountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,  
      searchedText: '',
      salesQuotationMasterId: this.props.salesQuotationMasterId || linkProps.salesQuotationMasterId,
      customerId: this.props.customerId || linkProps.customerId,
      placeOfSupply: undefined,
      soSourceId: 0,
      soSourceName: '',
      customerPO: undefined,
      referenceNumber: undefined,
      salesPersonId: undefined,
      salesPersonName: undefined,
      priority: undefined,
      billingAddress: undefined,
      shippingAddress: undefined,
      relationshipBillingAddress,
      paymentTermId: undefined,
      paymentTermName: undefined,
      footer: undefined,
      remarksCustomer: undefined,
      remarksInternal: undefined,
      deliveryDate: new Date(),
      priceTypeToApply: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0],
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      txPreferences: { txType: "salesOrder", templateName: "multicurrency" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === 'India',
        basePrice: true,
        specialDiscount: true,
        discount: true,
        comment: true,
        stockNumber: true,
        partNumber: true, 
        origin: true,
        hsCode: true,
      },
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      salesOrderMasterId: this.props.salesOrderMasterId || linkProps.salesOrderMasterId,
      salesQuotationMasterId: this.props.salesQuotationMasterId || linkProps.salesQuotationMasterId,
      customerId: this.props.customerId || linkProps.customerId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 100
    };
    this.props.resetSalesQuoteSOData();
    this.props.resetSODetails();
    payload.salesQuotationMasterId && fetchDataIfNeeded('fetchSQDataForSO', 'sqSoData', this.props, payload, true);
    payload.salesOrderMasterId && fetchDataIfNeeded('fetchSOData', 'soDetail', this.props, payload, true);
    payload.customerId && this.props.fetchAddresses({...payload, locationType: CONSTANTS.SHIPPING_ADDRESS});
    payload.customerId && this.props.fetchAddresses({...payload, locationType: CONSTANTS.BILLING_ADDRESS});
    //payload.customerId && fetchDataIfNeeded('fetchCustomerDetailsByCustomerIdForUpdate', 'customerDetailUpdate', this.props, payload, true);
    payload.customerId && fetchPaginationDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerDetail', this.props, payload, true);
    fetchDataIfNeeded('fetchPriceList', 'priceList', this.props, payload);
    fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
    fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
    fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
    fetchDataIfNeeded('fetchStates', 'states', this.props, 1);
    fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
    fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
    fetchDataIfNeeded('fetchExpenseTypes', 'expenseTypes', this.props, payload);
    fetchDataIfNeeded('fetchWarehouseNames', 'allWarehouses', this.props, payload);
    fetchDataIfNeeded('fetchDocuments', 'documentList', this.props, payload);
    fetchDataIfNeeded('fetchAllSalesOrderSources', 'sources', this.props, payload);
    fetchDataIfNeeded('fetchAllEmployees', 'employees', this.props, payload);
    if (!linkProps.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    fetchDataIfNeeded('fetchCountries', 'countries', this.props, payload);
    this.props.fetchRemarksTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Sales Order' });
    fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
    fetchDataIfNeeded('fetchIncoterms', 'incoterms', this.props, payload);
  }


  componentWillReceiveProps(props) {

    if(props.addresses){
      if(props.addresses.BillingAddress && props.addresses.BillingAddress.length){
        this.setState({
          allBillingAddress: props.addresses.BillingAddress
        });
      }
      if(props.addresses.ShippingAddress && props.addresses.ShippingAddress.length){
        this.setState({
          allShippingAddress: props.addresses.ShippingAddress
        });
      }
    }

    if (props.customerDetail && props.customerDetail.customerId) {
      const customer = props.customerDetail || {};
      const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
      const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
      const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
      const customerPaymentTerm = props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: ((customer && customer.paymentTermId) || 0) }) || {} : {};
      let numberOfDays = customerPaymentTerm.numberOfDays;
      const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
      const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
      let selectedCustomerTaxIdentifications = selectedList.map(obj => {
        return obj.customerTaxIdentificationId;
      })
      this.setState({
        customer,
        customerName: customer.companyName,
        paymentTermId: customerPaymentTerm.paymentTermId,
        paymentTermName: customerPaymentTerm.termName,
        shippingAddress: defaultShippingAddress,
        billingAddress: defaultBillingAddress,
        placeOfSupply: defaultShippingAddress.stateName || defaultBillingAddress.stateName,
        invoiceDueDate: numberOfDays ? addDaysToDate((this.state.invoiceDate || new Date()), numberOfDays) : null,
        selectedContact: selectedContact,
        selectedCustomerTaxIdentifications
      })
      props.resetCustomerDetail();
    }

    if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
      const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
      let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
        return obj.relationshipTaxIdentificationId;
      })
      this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
    }
    
    const linkProps = (this.props.location && this.props.location.state) || {};
    if (!this.state.remarksCustomer && props.remarksData &&  props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ remarksCustomer: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
      setTimeout(()=>{
        props.resetRemarksData();
    },500)
    }
    if (props.taxes && props.taxes.length && props.sqSoData && props.sqSoData.salesQuotationMasterId && props.customerDetail) {
      const detailList = []; const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.comment) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      }
      props.sqSoData.customerSalesQuotationDetailsList.map((ele) => {
        const tax = (find(ele.customerSalesQuotationCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX_IGST || ele.txType === LEDGER_TYPE.TYPE_TAX_CGST || ele.txType === LEDGER_TYPE.TYPE_TAX_SGST || ele.txType === LEDGER_TYPE.TYPE_TAX_VAT
        }) || {});

        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + ")") || ""),
          productObj: { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku },
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          discount: (find(ele.customerSalesQuotationCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax: (tax.amountPercent || 0) * (tax.txType === LEDGER_TYPE.TYPE_TAX_IGST || tax.txType === LEDGER_TYPE.TYPE_TAX_VAT ? 1 : 2),
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.comment,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
        })
      });

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      this.setState({
        currencyCode: props.sqSoData.foreignCurrency,
        currencyIcon: props.sqSoData.foreignCurrencyIcon,
        exchangeRate: props.sqSoData.exchangeRate,
        relationshipBillingAddress: find(props.sqSoData.boLocationSQList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {},
        shippingAddress: find(props.sqSoData.boLocationSQList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {},
        billingAddress: find(props.sqSoData.boLocationSQList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {},
        customerPaymentTerm: props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: props.sqSoData.paymentTermId }) || {} : {},
        referenceNumber: props.sqSoData.referenceNumber,
        priority: props.sqSoData.orderPriority,
        customerPo: props.sqSoData.customerPONumber,
        customerName: props.sqSoData.customerName,
        salesQuotationMasterId: props.sqSoData.salesQuotationMasterId,
        quotationNumber: props.sqSoData.quotationNumber,
        remarksCustomer: props.sqSoData.remarksCustomer,
        remarksInternal: props.sqSoData.remarksInternal,
        footer: props.sqSoData.footer,
        customer: props.customerDetail,
        customerSalesOrderDetailsList: detailList,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        isSQConvertions: true,
        rfqNumber: props.sqSoData.rfqNumber,
        rfqMasterId: props.sqSoData.rfqMasterId,
        tableDataReRendered: true,
      });
      props.resetSalesQuoteSOData();
    }
    if (props.soDetail && props.soDetail.salesOrderMasterId) {
      const detailList = [];
      props.soDetail.customerSalesOrderDetailsList.map((ele) => {
        const tax = (find(ele.customerSalesOrderCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX_IGST || ele.txType === LEDGER_TYPE.TYPE_TAX_CGST || ele.txType === LEDGER_TYPE.TYPE_TAX_SGST
        }) || {});

        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};

        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || ""),
          productObj: { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku },
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          discount: (find(ele.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax: (tax.amountPercent || 0) * (tax.txType === LEDGER_TYPE.TYPE_TAX_IGST || tax.txType === LEDGER_TYPE.TYPE_TAX_VAT ? 1 : 2),
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          qtyBeforeUpdate: ele.quantity,
        })
      });

      this.setState({
        relationshipBillingAddress: find(props.soDetail.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {},
        shippingAddress: find(props.soDetail.boLocationSOList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {},
        version: props.soDetail.version,
        billingAddress: find(props.soDetail.boLocationSOList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {},
        paymentTermName: props.soDetail.paymentTermName,
        paymentTermId: props.soDetail.paymentTermId,
        referenceNumber: props.soDetail.referenceNumber,
        priority: props.soDetail.orderPriority,
        customerPo: props.soDetail.customerPONumber,
        customerName: props.soDetail.customerName,
        customerRemarks: props.soDetail.remarksCustomer,
        salesQuotationMasterId: props.soDetail.salesQuotationMasterId,
        quotationNumber: props.soDetail.quotationNumber,
        internalRemarks: props.soDetail.remarksInternal,
        footer: props.soDetail.footer,
        customer: props.customerDetail,
        customerSalesOrderDetailsList: detailList,
        rfqNumber: props.soDetail.rfqNumber,
        rfqMasterId: props.soDetail.rfqMasterId,
        deliveryDate: props.soDetail.deliveryDate,
        oldPayload: linkProps.update ? props.soDetail : {},
        isSQConvertions: linkProps.update ? (props.soDetail.salesQuotationMasterId ? true : false) : false,
        salesOrderDate: linkProps.update ? new Date(props.soDetail.salesOrderDate): new Date(),
      });
      if (linkProps.update) {
        this.setState({ nextTxNumber: props.soDetail.soNumber });
      }
      props.resetSODetails();
    }
    if (this.state.stateCustomerId && props.customers[this.state.pageNumber] && props.customers[this.state.pageNumber].length && props.paymentTerms && props.paymentTerms.length) {
      const customer = find(props.customers[this.state.pageNumber], { customerId: Number(this.state.stateCustomerId || 0) }) || {};
      const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
      const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
      const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
      const customerPaymentTerm = props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: ((customer && customer.paymentTermId) || 0) }) || {} : {};
      let numberOfDays = customerPaymentTerm.numberOfDays;
      const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
      const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
      let selectedCustomerTaxIdentifications = selectedList.map(obj => {
        return obj.customerTaxIdentificationId;
      })
      this.setState({
        stateCustomerId: null,
        customer,
        customerName: customer.companyName,
        paymentTermId: customerPaymentTerm.paymentTermId,
        paymentTermName: customerPaymentTerm.termName,
        shippingAddress: defaultShippingAddress,
        billingAddress: defaultBillingAddress,
        placeOfSupply: defaultBillingAddress && defaultBillingAddress.stateName,
        invoiceDueDate: numberOfDays ? addDaysToDate((props.invoiceDate || new Date()), numberOfDays) : null,
        selectedContact,
        selectedCustomerTaxIdentifications,
        boContactList: customer.boContactList || [],
      });
    }
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "salesOrder", templateName: "multicurrency" });
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference ?((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns: this.state.txMandatoryColumns;
        const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
    }
    if(props.boRelationshipAddress && props.boRelationshipAddress.length){
      const allRelationshipBillingAddress = [];
      props.boRelationshipAddress.forEach((e)=>{
        if(e.locationType === 'RelationshipBillingAddress'){
          allRelationshipBillingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress
      })
    }else{
      const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      this.setState({
        allRelationshipBillingAddress,
      });
    }
    if (props.customerContactList && props.customerContactList.length) {
      this.setState({
        boContactList: props.customerContactList
      });
    }
  }

  openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId
    }

    this.props.pushModalToStack({
      modalBody: <SODetail  {...this.props} soPayload={soPayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching
    };
    this.props.fetchProducts(payload)
  }

  render() {
    return (
      <Fragment>
        <LedgerDrawer {...this.state} updateState={(data) => { this.setState(data) }} />
        <MulticurrencySalesOrderComp
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching)
          }}
          {...this.props}
          {...this.state}
          openSoDetails={this.openSoDetails}
          updateState={(data) => { this.setState(data) }}
        />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    customers: state.customer.customers,
    priceList: state.common.priceList,
    documentList: state.common.documentList,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    supplierAddresses: state.supplier.supplierAddresses,
    paymentTerms: state.common.paymentTerms,
    states: state.common.states,
    sqSoData: state.so.sqSoData,
    projectList: state.common.projectList,
    uomList: state.common.uomList,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    products: state.salesInvoice.products,
    allWarehouses: state.inventory.allWarehouses,
    sources: state.so.sources,
    employees: state.common.employees,
    countries: state.common.countries,
    soDetail: state.so.soDetail,
    customerDetail: state.common.customerDetail,
    nextTxNumber: state.so.nextTxNumber,
    allPreferences: state.common.allPreferences,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    allRelationshipTaxIdentificatins: state.common.allRelationshipTaxIdentificatins,
    incoterms: state.common.incoterms,
    addresses: state.customer.addresses,
    customerContactList: state.customer.contacts,
    boRelationshipAddress: state.common.boRelationshipAddress,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSQDataForSO,
  fetchSOData,
  resetSODetails,
  resetSalesQuoteSOData,
  createSalesOrder,
  fetchCustomerDetailsByCustomerId,
  fetchCustomers,
  fetchPriceList,
  fetchProjects,
  fetchPaymentTerms,
  fetchStates,
  fetchProducts,
  fetchTaxes,
  deleteTax,
  fetchUOM,
  fetchCountries,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  fetchWarehouseNames,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  fetchAllEmployees,
  deleteEmployee,
  fetchAllSalesOrderSources,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  deleteCustomers,
  addPaymentTerm,
  resetRemarksData,
  deletePaymentTerm,
  addSalesOrderSources,
  deleteSalesOrderSources,
  deleteProject,
  deleteAddress,
  deletePriceList,
  getNextTxNumber,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchRemarksTemplate,
  fetchRelationshipTaxIdentifications,
  deleteCustomerTaxIdentifications, 
  deleteRelationshipTaxIdentifications,
  fetchIncoterms, 
  addIncoterm, 
  deleteIncoterm,
  resetCustomerDetail,
  fetchAddresses,
  fetchAllContacts,
  getRelationshipAddress
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MulticurrencySalesOrder);
