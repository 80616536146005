import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Checkbox, Slider } from 'antd';
import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';

function ThemeProps(props) {
    let temmplateDiv = document.getElementById("temmplateDiv").parentNode || {};
    
    const [showBorder, updateShowBorder] = useState(temmplateDiv.style.borderStyle === 'solid' && temmplateDiv.style.borderWidth !== '0px');
    const [borderWidth, updateBorderWidth] = useState( Number((temmplateDiv.style.borderWidth || '').replace('px', '') || 0));
    
    return (
        <Fragment>
                    <div>
                        <Row className="but-buttons">
                            <Col span={8} style={{ paddingTop:'2px', paddingRight:'4px'}}>
                                <FormattedMessage id='buttonBackgraound.text' />
                            </Col>
                            <Col span={16} >
                                <ColorPicker
                                placement='bottomLeft'
                                    enableAlpha={false}
                                    color={temmplateDiv.style.background || '#F3F4F6'}
                                    onChange={(obj) => {
                                        props.updateState({ bgColor: obj.color });
                                        temmplateDiv.style.background = obj.color;
                                        let CKEDITOR = window.CKEDITOR;
                                        //CKEDITOR.instances['editor_child_1'].setUiColor(obj.color );
                                        //CKEDITOR.instances['editor_child_1'].addCss( 'body { background-color: '+obj.color+'; }' );
                                        Array.prototype.forEach.call(Object.keys(CKEDITOR.instances || []), function (editorId, i) {
                                            CKEDITOR.instances[editorId].setUiColor(obj.color);
                                        }
                                        )
                                        let allEditor = document.getElementsByClassName('cke_wysiwyg_frame cke_reset') || [];
                                        Array.prototype.forEach.call(allEditor, function (editor, i) {
                                            editor.contentDocument.body.style.background = obj.color;
                                        }
                                        )
                                    }}
                                    mode="RGB"
                                />
                            </Col>
                        </Row>

                        <Row className="but-buttons">
                            <Col span={8}><FormattedMessage id='showBorder.text' /></Col>
                            <Col span={16}>
                                <Checkbox checked={showBorder} onChange={(e) => {
                                temmplateDiv.style.borderWidth = e.target.checked ? '1px' : '0px';
                                temmplateDiv.style.borderStyle = 'solid';
                                updateShowBorder(e.target.checked);
                                updateBorderWidth(e.target.checked ? 1 : 0);
                                }} />
                            </Col>
                        </Row>

                        <Row className="but-buttons">
                            <Col span={8} style={{ paddingTop:'2px', paddingRight:'4px'}}>
                                <FormattedMessage id='bordercolor.text' />
                            </Col>
                            <Col span={16} >
                            <ColorPicker
                             placement='bottomLeft'
                                enableAlpha={false}
                                color={temmplateDiv.style.borderColor || '#525156'}
                                onChange={(obj) => {
                                    temmplateDiv.style.borderColor = obj.color;
                                }}
                                mode="RGB"
                            />
                            </Col>
                        </Row>

                        <Row className="but-buttons">
                            <Col span={8}><FormattedMessage id='borderWidth.text' /></Col>
                            <Col span={16}>
                                <Slider defaultValue={0} value={borderWidth} min={0} max={10} onChange={(value) => {
                                    temmplateDiv.style.borderWidth = value + "px";
                                    updateBorderWidth(value);
                                }} />
                            </Col>
                        </Row>

                        <Row className="but-buttons">
                            <Col span={8}><FormattedMessage id='buttonCorners.text' /></Col>
                            <Col span={16}>
                                <Slider defaultValue={0} min={0} max={10} onChange={(value) => {
                                    temmplateDiv.style.borderRadius = value + "%";
                                }} />
                            </Col>
                        </Row>


                </div>
        </Fragment>
    );
}


export default injectIntl(ThemeProps);
