import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProjectListingComp from '../../../components/company/Projects';
import { getAllProject, resetProjectList } from './action';
import { showModal, hideModal, deleteProject, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { getMomentDateForUI, getFinancialYear } from '../../../utils';
import { CONSTANTS } from '../../../static/constants';
import { getSubdomain } from '../../modal/modalBody/settings/CompanyInfo/action'; 
import { fetchAvailablePaymentGateway } from '../../integrations/Payment/action';
import { fetchAllLedgerAccounts } from '../../finance/LedgerAccounts/action';
import ProjectAddDrawer from "../../../containers/drawer/Project/index"

class Projects extends Component {
  constructor(props) {
    super(props);
    const finacialYear = getFinancialYear(this.props.companyInfo);
    this.state = {
      pageNumber: 1,
      pageSize: 50,
      startDate: getMomentDateForUI({ date: new Date(finacialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT }),
      endDate: new Date(),
      allLedgerAccounts: []
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    let self = this;
    const companyInfo = (this.props.companyInfo || {})
    const payload= {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 50, 
    }
    this.props.getAllProject(payload);
    this.props.getSubdomain(payload, function (stat) {self.setState({isSubDomainLoaded : stat})} ); 
    this.props.fetchAvailablePaymentGateway(payload);
    this.props.fetchAllLedgerAccounts(payload);
  }

  componentWillReceiveProps(props) {
   
    if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
      this.setState({
        subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
      });
    }
  }

  render() {
    return (
      <Fragment>
         {this.state.projectDrawerVisible && <ProjectAddDrawer {...this.state} updateState={(data) => { this.setState(data) }}  />}
        <ProjectListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    projectList: state.project.projects,
    projectsCount: state.project.projectsCount,
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    subdomain: state.common.subdomain,
    availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getAllProject, 
  resetProjectList,
  deleteProject,
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  getSubdomain,
  fetchAllLedgerAccounts,
  fetchAvailablePaymentGateway,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
