import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS} from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
//const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const uploadInWalmart = (itemList, props) => {
  let formData = {
    feed_data: itemList,
    callback_uri: `${config.UI_BASE_URL}admin/products?rid=${props.relationshipId}`,
    relationshipId: props.relationshipId,
    apiBaseUrl: config.API_BASE_URL + config.rootContext + '/'
  }
  // let formData = new FormData();
  // formData.append('feed_data', JSON.stringify(itemList, true));
  // formData.append('callback_uri', `${config.UI_BASE_URL}admin/products?rid=${props.relationshipId}`);
  // formData.append('relationshipId', props.relationshipId);
  // formData.append('apiBaseUrl', config.API_BASE_URL + config.rootContext + '/');
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PAYMENT_SERVER_BASE_URL}/walmart/catalogFeed`,
        formData, {
          headers: {
            'content-type': 'application/json'
          },
          cors: true,
          contentType: 'application/json',
          secure: true,
        }
      )
      .then(res => {
        showToasterMessage({ messageType: "success", description: "Upload successfully" });
      })
      .catch(err => {
      })
  }
}