import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
//import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip, Popover, Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,
  fixedDecimalAndFormateNumber,
  sortTable,
} from "../../../../utils";
import SODetail from "../../../../containers/modal/modalBody/customer/SODetail";
import SQDetail from "../../../../containers/modal/modalBody/customer/SQDetail";
// import RFQDetail from '../../../../containers/modal/modalBody/supplier/RFQDetail';
// import RFQSplitDetail from '../../../../containers/modal/modalBody/supplier/RFQSplitDetail';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
//import PageBreadcrumb from '../../../PageBreadcrumb';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants'
import InvoiceInformation from "./../../../../containers/modal/modalBody/customer/InvoiceInformation";
import PackageInformation from "./../../../../containers/modal/modalBody/customer/PackageInformation";
import ShipmentInformation from "./../../../../containers/modal/modalBody/customer/ShipmentInformation";
import { CONSTANTS } from "../../../../static/constants";
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
// import { Dropdown } from '../../../general/Dropdown';
// import { TextBox } from '../../../general/TextBox';
// import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
// import find from 'lodash.find';
import { FilterOutlined, InsertRowRightOutlined } from "@ant-design/icons";
import {
  AmazonLogo,
  CareemLogo,
  CarrefourLogo,
  DesertCartLogo,
  DubaiStoreLogo,
  GoogleMerchantLogo,
  JuztBuyLogo,
  NoonFoodLogo,
  NoonLogo,
  TalabatLogo,
  TradelingLogo,
  ShopifyLogo,
  EbayLogo,
  MeeshoLogo,
} from "../../../../assets/images";
import CustomerProfile from "../../../../containers/modal/modalBody/customer/Profile";
import { TextBox } from "../../../general/TextBox";

// import { Switch } from 'react-router-dom';

// const { RangePicker } = DatePicker;
// const { Panel } = Collapse;
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const SalesOrderListingComp = (props) => {
  const {
    soList,
    updateState,
    companyInfo,
    salesOrderCount,
    pageNumber,
    pageSize,
    dir = 0,
  } = props;

  // const [collapseActiveKey, setCollapseActiveKey] = useState('');
  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // };

  // const getPayloadForPdf = (soDetail) => {
  //     return {
  //         fileName: 'Sales Order.pdf',
  //         transactionName: 'salesOrder',
  //         soDetail: soDetail,
  //         companyInfo: props.companyInfo,
  //         customerId: soDetail.customerId
  //     }
  // }

  // const openEmailDrawer = (props, soDetail) => {
  //     let payload = getPayloadForPdf(props, soDetail);
  //     payload.fileDataReqeired = true;
  //     props.updateState({
  //         emailDrawerVisible: true,
  //         txData: payload,
  //         type: TRANSACTION_TYPES.SALES_ORDER,
  //     });
  // };

  // const openAuditDrawer = (props, soDetail) => {
  //     props.updateState({
  //         title: <div className="drawer-title">
  //             Audit Trail
  //         </div>,
  //         auditDrawerVisible: true,
  //         deletApiUrl: 'deleteAuditTrailSalesOrder',
  //         masterKey: 'soMasterId',
  //         masterKeyValue: soDetail.salesOrderMasterId,
  //         apiUrl: 'saveAuditTrailSalesOrder',
  //         customerId: soDetail.customerId,
  //         trailList: soDetail.auditTrailSalesOrderList,
  //         updateTrailListInTransaction: (list) => {
  //             soDetail.auditTrailSalesOrderList = list;
  //         }
  //     });
  // }

  // const getActionMenuPermissionData = () => {
  //     const primaryPerm = permissions.primary || [];
  //     const permittedModules = {
  //         firstSection: {
  //             updateSalesOrder: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER, PERMISSION_VALUES.UPDATE),
  //             cloneSalesOrder: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER, PERMISSION_VALUES.CREATE),
  //             deleteSO : checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER, PERMISSION_VALUES.DELETE),
  //         }
  //     };
  //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
  //         if (permittedModules.firstSection[moduleName] === false) {
  //             delete permittedModules.firstSection[moduleName];
  //         }
  //     })
  //     return permittedModules;
  // }

  // const addItemConfirmationModal = (props, text, soData) => {
  //     const { showModal, companyInfo } = props;
  //     const labelName = <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />;
  //     const modalBody = <span>
  //         <FormattedMessage
  //             id='setItem.confirmation'
  //             values={{ value: `${text} to ` }}
  //             defaultMessage=''
  //         />
  //         {labelName} ?
  //   </span>;

  //     let payload = {
  //         userStatus: text,
  //         relationshipId: companyInfo.relationshipId,
  //         salesOrderMasterId: soData.salesOrderMasterId,
  //         customerId: soData.customerId
  //     };

  //     const modalData = {
  //         modalBody,
  //         handleSubmit: () => {
  //             props.updateUserStatus(payload, props);
  //             props.hideModal();
  //             props.popModalFromStack();
  //         },
  //     };
  //     showModal(modalData);
  // }

  // const updateUserStatus = (props, soData) => {
  //     let payload = { formData: {} };
  //     const formData = payload.formData;
  //     const { pushModalToStack } = props;

  //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
  //         addItemConfirmationModal(props, formData.textBoxValue, soData);
  //     } else {
  //         const data = {
  //             title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
  //             formData,
  //             hideFooter: true,
  //             modalData: { modalType: MODAL_TYPE.USER_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_ORDER },
  //             handleSubmit: (formData = {}) => {
  //                 if (formData.textBoxValue && formData.textBoxValue.length) {
  //                     addItemConfirmationModal(props, formData.textBoxValue, soData);
  //                 } else {
  //                     data.formData.submittedOnce = true;
  //                     pushModalToStack(data);
  //                 }
  //             }
  //         };
  //         pushModalToStack(data);
  //     }
  // }

  // const actionMenu = (param, permittedModules) => {
  //     return (
  //         <Menu className="cell-action-dropdown">
  //             {
  //                 permittedModules.firstSection.updateSalesOrder
  //                     ? (
  //                         <Menu.Item key="0">
  //                             <i className="fa fa-pencil" />
  //                             <Link
  //                                 to={
  //                                     {
  //                                         pathname: '/admin/so-update',
  //                                         state: {
  //                                             customerId: param.data.customerId,
  //                                             salesOrderMasterId: param.data.salesOrderMasterId,
  //                                             update: true
  //                                         }
  //                                     }}
  //                             >Update Sales Order</Link>
  //                         </Menu.Item>
  //                     )
  //                     : <Fragment />
  //             }

  //             {(param.data.status !== 'Pending Approval' && param.data.status !== 'Rejected') ?
  //                 <Menu.Item key="1" onClick={() => {
  //                     openEmailDrawer(props, param.data);
  //                 }}>
  //                     <i className="fa fa-envelope" />
  //                  Send Email
  //                 </Menu.Item>
  //                 :
  //                 ''
  //             }

  //             {/* <Menu.Item key="2" onClick={() => {
  //                 openAuditDrawer(props, param.data);
  //             }}>
  //                 <i className="fa fa-history" />
  //                  View Audit Trail
  //                 </Menu.Item> */}

  //             {
  //                 permittedModules.firstSection.cloneSalesOrder
  //                     ? (
  //                         <Menu.Item key="3">
  //                             <i className="fa fa-clone" />
  //                             <Link
  //                                 to={
  //                                     {
  //                                         pathname: '/admin/so-create',
  //                                         state: {
  //                                             customerId: param.data.customerId,
  //                                             salesOrderMasterId: param.data.salesOrderMasterId,
  //                                             clone: true
  //                                         }
  //                                     }}
  //                             >Clone Sales Order</Link>
  //                         </Menu.Item>
  //                     )
  //                     : <Fragment />
  //             }
  //             {(param.data.qualityCheckList && param.data.qualityCheckList.length) ?
  //                 <Menu.Item key="4" onClick={() => {
  //                     props.updateState({
  //                         title: <div className="drawer-title">Quality Check</div>,
  //                         qualityCheckList: param.data.qualityCheckList,
  //                         customerId: param.data.customerId,
  //                         soMasterId: param.data.salesOrderMasterId,
  //                         qualityCheckAction: "add",
  //                         qualityCheckDrawerVisible: true,
  //                         addQualityCheckToSO: function (qualityCheckList, qualityCheckStatus) {
  //                             props.updateState({
  //                                 qualityCheckList: qualityCheckList,
  //                                 qualityCheckStatus: qualityCheckStatus
  //                             })
  //                             let list = qualityCheckList.map(obj => {
  //                                 obj.customerId = param.data.customerId;
  //                                 obj.salesOrderMasterId = param.data.salesOrderMasterId;
  //                                 obj.relationshipId = param.data.relationshipId;
  //                                 return obj;
  //                             })
  //                             props.updateQualityCheckList(list, props);
  //                             // const modalData = {
  //                             //     modalBody: "Are you sure you want to save?",
  //                             //     handleSubmit: () => {
  //                             //         let list = qualityCheckList.map(obj => {
  //                             //             obj.customerId = param.data.customerId ;
  //                             //             obj.salesOrderMasterId = param.data.salesOrderMasterId ;
  //                             //             obj.relationshipId = param.data.relationshipId ;
  //                             //             return obj;
  //                             //          })
  //                             //         props.updateQualityCheckList(list, props);
  //                             //         props.popModalFromStack();
  //                             //     },
  //                             // };
  //                             // props.pushModalToStack(modalData);
  //                         }
  //                     })
  //                 }}>
  //                     <i className="fa fa-check-square-o" />
  //                  Quality Check
  //                 </Menu.Item>
  //                 :
  //                 <Fragment />
  //             }
  //             <Menu.Item key="5" onClick={() => {
  //                 updateUserStatus(props, param.data);
  //             }}>
  //                 <i className="fa fa-pencil" />
  //                  Update User Status
  //                 </Menu.Item>

  //            { permittedModules.firstSection.deleteSO && <Menu.Item key="6">
  //                 <i className="fa fa-trash" />
  //                 <Link onClick={() => {
  //                     props.showModal({
  //                         modalBody: "Are you sure you want to delete this transaction?",
  //                         handleSubmit: () => {
  //                             props.deleteSalesOrder({
  //                                 salesOrderMasterId: param.data.salesOrderMasterId,
  //                                 customerId: param.data.customerId,
  //                                 relationshipId: param.data.relationshipId
  //                             }, props)
  //                         }
  //                     })

  //                 }}>Delete Sales Order</Link>
  //             </Menu.Item>}

  //         </Menu >

  //     );
  // }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("fetchSalesOrders", "soList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchSalesOrders(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const getInvoicedQuantity = (data) => {
    return (
      <div
        className="cursor-pointer circles-ling"
        style={{ cursor: data.status === "cancelled" ? "not-allowed" : "pointer" }}
        onClick={() => {
          if (data.status === "Pending Approval" || data.status === "Rejected" || data.status === "cancelled")
            return;
          props.showModal({
            title: (
              <div>
                <div className="ant-modal-title">Invoice Information</div>

                <div
                  className="ant-modal-subtitle light-txt"
                  style={{ fontSize: "14px" }}
                >
                  {data.customerName}
                </div>
              </div>
            ),
            modalBody: <InvoiceInformation soData={data} {...props} />,
            width: 900,
            hideFooter: true,
          });
        }}
      >
        {/* //<Progress className={data.statusInvoice === 'fulfilled' ? "complete" : "incomplete"} format={() => {
        //     return ''
        // }} percent={data.statusInvoice === 'fulfilled' ? 100 : 33} /></div>; */}
        {data.statusInvoice === "fulfilled" ? (
          <i className="fa fa-circle"></i>
        ) : data.statusInvoice === "partially.fulfilled" ? (
          <i className="fa fa-adjust"></i>
        ) : (
          <i className="fa fa-circle-o"></i>
        )}
      </div>
    );
  };

  const getShippedQuantity = (data) => {
    return (
      <div
        className="cursor-pointer circles-ling"
        style={{ cursor: data.status === "cancelled" ? "not-allowed" : "pointer" }}

        onClick={() => {
          if (data.status === "Pending Approval" || data.status === "Rejected")
            return;
          props.showModal({
            title: (
              <div>
                <div className="ant-modal-title">Shipment Information</div>
                <div
                  className="ant-modal-subtitle light-txt"
                  style={{ fontSize: "14px" }}
                >
                  {data.customerName}
                </div>
              </div>
            ),
            modalBody: <ShipmentInformation soData={data} {...props} />,
            width: 900,
            hideFooter: true,
            wrapClassName: "shipment-information-modal",
          });
        }}
      >
        {/* <Progress className={data.statusShipment === 'fulfilled' ? "complete" : "incomplete"} format={() => {
                return ''
            }} percent={data.statusShipment === 'fulfilled' ? 100 : 33} /></div>; */}
        {data.statusShipment === "fulfilled" ? (
          <i className="fa fa-circle"></i>
        ) : data.statusShipment === "partially.fulfilled" ? (
          <i className="fa fa-adjust"></i>
        ) : (
          <i className="fa fa-circle-o"></i>
        )}
      </div>
    );
  };

  const getQuantityPacked = (data) => {
    return (
      <div
        className="cursor-pointer circles-ling"
        style={{ cursor: data.status === "cancelled" ? "not-allowed" : "pointer" }}

        onClick={() => {
          if (data.status === "Pending Approval" || data.status === "Rejected")
            return;
          props.showModal({
            title: (
              <div>
                <div className="ant-modal-title">Package Information</div>
                <div
                  className="ant-modal-subtitle light-txt"
                  style={{ fontSize: "14px" }}
                >
                  {data.customerName}
                </div>
              </div>
            ),
            modalBody: <PackageInformation soData={data} {...props} />,
            width: 900,
            hideFooter: true,
          });
        }}
      >
        {/* <Progress className={data.statusPackage === 'fulfilled' ? "complete" : "incomplete"} format={() => {
                return ``
            }} percent={data.statusPackage === 'fulfilled' ? 100 : 33} />  */}
        {data.statusPackage === "fulfilled" ? (
          <i className="fa fa-circle"></i>
        ) : data.statusPackage === "partially.fulfilled" ? (
          <i className="fa fa-adjust"></i>
        ) : (
          <i className="fa fa-circle-o"></i>
        )}
      </div>
    );
  };

  const getQuantityOnPR = (data) => {
    return (
      <div className="circles-ling" 
      style={{ cursor: data.status === "cancelled" ? "not-allowed" : "pointer" }}
      
      >
        {data.statusPR === "converted" ? (
          <i className="fa fa-circle"></i>
        ) : data.statusPR === "converted.partially" ? (
          <i className="fa fa-adjust"></i>
        ) : (
          <i className="fa fa-circle-o"></i>
        )}
      </div>
    );
  };

  const openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId,
    };
    props.pushModalToStack({
      modalBody: <SODetail {...props} soPayload={soPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  const openSQDetails = (data) => {
    const sqPayload = {
      customerId: data.customerId,
      salesQuotationMasterId: data.salesQuotationMasterId,
    };
    props.pushModalToStack({
      modalBody: <SQDetail {...props} sqPayload={sqPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const openRFQDetails = (data) => {
  //     const rfqPayload = {
  //         supplierId: data.supplierId,
  //         rfqMasterId: data.rfqMasterId,
  //         rfqChildMasterId: data.rfqChildMasterId
  //     }
  //     props.pushModalToStack({
  //         modalBody: data.rfqChildMasterId ? <RFQSplitDetail {...props} rfqPayload={rfqPayload} /> : <RFQDetail {...props} rfqPayload={rfqPayload} />,
  //         width: '100%',
  //         hideFooter: true,
  //         wrapClassName: 'modal-custom-detail'
  //     })
  // }

  // const saveColumnIndex = (columnState) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data);
  // }

  // const saveColumnWidth = (columnState, width) => {
  //     let data = props.txPreferences || {};
  //     let tablePreference = {};
  //     data.relationshipId = (props.companyInfo || {}).relationshipId;
  //     data.clientUserAccountId = (props.userInfo || {}).userId;
  //     if (data.tablePreference) {
  //         tablePreference = JSON.parse(data.tablePreference);
  //     }
  //     tablePreference.columnSetting = columnState;
  //     tablePreference.columnList = props.txColumns;
  //     data.tablePreference = JSON.stringify(tablePreference);
  //     data.preferenceId = props.preferenceId;
  //     data.gridPreference = props.gridPreference;
  //     props.saveUserPrefrences(data, props);
  // }

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //     if (event.column) {
  //         moveEvent = event;
  //     }
  //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
  //         saveColumnIndex(moveEvent.columnApi.getColumnState());
  //     }
  // }

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //     event.api.resetRowHeights();
  //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
  //         //event.api.resetRowHeights();
  //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
  //             props.gridApi.sizeColumnsToFit();
  //             setTimeout(() => {
  //                 const columnArray = event.columnApi.getColumnState();
  //                 saveColumnWidth(columnArray);
  //             }, 1000);
  //         } else {
  //             saveColumnWidth(event.columnApi.getColumnState());
  //         }
  //     }
  // }

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  const fetchSourceLogo = (salesChannel,defName) => {
    switch (salesChannel) {
      case "amazon":
        return (
          <img
            src={AmazonLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "careem":
        return (
          <img
            src={CareemLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "carrefour":
        return (
          <img
            src={CarrefourLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "desertcart":
        return (
          <img
            src={DesertCartLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "dubaistore":
        return (
          <img
            src={DubaiStoreLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "googlemerchant":
        return (
          <img
            src={GoogleMerchantLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "juztbuy":
        return (
          <img
            src={JuztBuyLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "noonfood":
        return (
          <img
            src={NoonFoodLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "noon":
        return (
          <img
            src={NoonLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "talabat":
        return (
          <img
            src={TalabatLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "tradeling":
        return (
          <img
            src={TradelingLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "shopify":
        return (
          <img
            src={ShopifyLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "ebay":
        return (
          <img
            src={EbayLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      case "meesho":
        return (
          <img
            src={MeeshoLogo}
            alt="source-logo"
            style={{ maxWidth: 80, maxHeight: 35 }}
          />
        );
      default:
        return defName ||<div className="empty-data"></div> ;
    }
  };

  // const permittedModules = getActionMenuPermissionData();
  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.soNo' defaultMessage='' />;
  //         },
  //         field: "soNumber",
  //         colId: "soNumber_1",
  //         hide: _isColumnToHide('soNumber'),
  //         minWidth: 90,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openSoDetails(link.data)
  //         }}>{link.value}</div>,
  //         rowDrag: true,
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.orderNo' defaultMessage='' />;
  //         },
  //         field: "salesOrderMasterId",
  //         colId: "salesOrderMasterId_1",
  //         hide: _isColumnToHide('salesOrderMasterId'),
  //         minWidth: 50,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openSoDetails(link.data)
  //         }}>{link.value}</div>,
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.salesChannel' defaultMessage='' />;
  //         },
  //         field: "soSourceName",
  //         colId: "soSourceName_1",
  //         hide: false,
  //         minWidth: 110,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div className='text-center'>

  //             {link.value ? fetchSourceLogo((link.value).toLowerCase()): ''}
  //         </div>,
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.customer' defaultMessage='' />;
  //         },
  //         field: "customerName",
  //         resizable: true,
  //         minWidth: 110,
  //         maxWidth: 400,
  //         suppressMovable: true,
  //         hide: _isColumnToHide('customerName'),
  //         colId: "customerName_1",
  //         cellRendererFramework: (link) => {
  //             return <div className="cursor-pointer" >
  //                 <div onClick={() => {
  //                     props.history.push("customers/details", {
  //                         customerId: link.data.customerId,
  //                         customerName: link.data.customerName,
  //                     })
  //                 }}>{link.data.customerName}</div>
  //             </div>
  //         },
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.quoteNumber' defaultMessage='' />;
  //         },
  //         field: "quotationNumber",
  //         hide: _isColumnToHide('quotationNumber'),
  //         colId: "quotationNumber_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openSQDetails(link.data)
  //         }}>{link.value}</div>,
  //         resizable: true
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <FormattedMessage id='customer.salesOrder.listing.header.rfqNumber' defaultMessage='' />;
  //     //     },
  //     //     field: "rfqNumber",
  //     //     hide: _isColumnToHide('rfqNumber'),
  //     //     width: (props.txColumnSetting['rfqNumber'] || {}).width,
  //     //     cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //     //         openRFQDetails(link.data)
  //     //     }}>{link.value}</div>,
  //     //     resizable: true
  //     // },
  //     {
  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='customer.salesOrder.listing.header.amount' defaultMessage='Amount' />  </div>;
  //         },
  //         cellRendererFramework: (link) => {
  //         //     return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
  //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}{fixedDecimalAndFormateNumber(link.value)} </span> : "-"
  //         },
  //         field: "salesOrderTotalAmount",
  //         resizable: true,
  //         minWidth: 110,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         hide: _isColumnToHide('salesOrderTotalAmount'),
  //         colId: "salesOrderTotalAmount_1",
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },

  //      {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.status' defaultMessage='' />;
  //         },
  //         field: "userStatus",
  //         hide: _isColumnToHide('userStatus'),
  //         colId: "userStatus_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.value? <span className="status-code" style={{background: link.data.userStatusColor}}>{link.value || ''}</span> : ''}
  //             </div>
  //         },
  //         resizable: true,
  //         cellStyle: function (props){
  //             if(props.statusColor){
  //                 return {backgroundColor: props.statusColor}
  //             }
  //         }
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.systemStatus' defaultMessage='' />;
  //         },
  //         field: "status",
  //         resizable: true,
  //         minWidth: 124,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         hide: _isColumnToHide('status'),
  //         colId: "status_1",
  //         cellRendererFramework: (params) => {
  //             return (params.value === 'Rejected' && params.data.rejectionReason) ?
  //                 <div>
  //                     <Tooltip placement="topLeft" title={params.data.rejectionReason}>
  //                         <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /> </span>
  //                     </Tooltip>
  //                 </div> :
  //                 <div>
  //                     <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /> </span>
  //                 </div>
  //         },
  //         cellStyle: function (props){
  //             if(props.statusColor){
  //                 return {backgroundColor: props.statusColor}
  //             }
  //         }
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.refNumber' defaultMessage='' />;
  //         },
  //         field: "referenceNumber",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         hide: _isColumnToHide('referenceNumber'),
  //         colId: "referenceNumber_1",
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' />;
  //         },
  //         field: "salesOrderDate",
  //         minWidth: 122,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         hide: _isColumnToHide('salesOrderDate'),
  //         colId: "salesOrderDate_1",
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
  //                     {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
  //                 </Tooltip>
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.qtyInvoiced' defaultMessage='' />;
  //         },
  //         field: "totalQtyInvoiced",
  //         hide: _isColumnToHide('totalQtyInvoiced'),
  //         colId: "totalQtyInvoiced_1",
  //         minWidth: 70,
  //         maxWidth: 100,
  //         suppressMovable: true,
  //         cellRendererFramework: function (param) {
  //             return getInvoicedQuantity(param.data);
  //         },
  //         resizable: true,
  //         cellClass: 'sales-order-progressbar'
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.qtyPacked' defaultMessage='' />;
  //         },
  //         field: "totalQtyPacked",
  //         hide: _isColumnToHide('totalQtyPacked'),
  //         colId: "totalQtyPacked_1",
  //         minWidth: 70,
  //         maxWidth: 100,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: function (param) {
  //             return getQuantityPacked(param.data);
  //         },
  //         cellClass: 'sales-order-progressbar'
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.qtyShipped' defaultMessage='' />;
  //         },
  //         field: "totalQtyShipped",
  //         hide: _isColumnToHide('totalQtyShipped'),
  //         colId: "totalQtyShipped_1",
  //         minWidth: 70,
  //         maxWidth: 100,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: function (param) {
  //             return getShippedQuantity(param.data);
  //         },
  //         cellClass: 'sales-order-progressbar'
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.qtyOnPR' defaultMessage='' />;
  //         },
  //         field: "totalQtyOnPR",
  //         hide: _isColumnToHide('totalQtyOnPR'),
  //         colId: "totalQtyOnPR_1",
  //         minWidth: 70,
  //         maxWidth: 100,
  //         suppressMovable: true,
  //         resizable: true,
  //         cellRendererFramework: function (param) {
  //             return getQuantityOnPR(param.data);
  //         },
  //         cellClass: 'sales-order-progressbar'
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.deliveryDate' defaultMessage='' />;
  //         },
  //         field: "deliveryDate",
  //         hide: _isColumnToHide('deliveryDate'),
  //         colId: "deliveryDate_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
  //                     {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
  //                 </Tooltip>
  //             </div>
  //         },
  //         resizable: true
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='prStatus.text' defaultMessage='' />;
  //         },
  //         field: "statusPRMessage",
  //         resizable: true,
  //         minWidth: 124,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         hide: _isColumnToHide('statusPRMessage'),
  //         colId: "statusPRMessage_1",
  //         cellRendererFramework: (link) => {
  //             return <div>{link.value || ''}</div>
  //         },
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerSalesEnquiryNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerInquiryNumber',
  //         hide: _isColumnToHide('customerInquiryNumber'),
  //         colId: "customerInquiryNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerInquiryNumber || '-'}
  //         </div>,
  //     },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerRfqNumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerRFQNumber',
  //         hide: _isColumnToHide('customerRFQNumber'),
  //         colId: "customerRFQNumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerRFQNumber || '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customerPONumber.text' defaultMessage='' />;
  //         },
  //         field: 'customerPONumber',
  //         hide: _isColumnToHide('customerPONumber'),
  //         colId: "customerPONumber_1",
  //         resizable: true,
  //         suppressMovable: true,
  //         minWidth: 70,
  //         maxWidth: 400,
  //         cellRendererFramework: (link) => <div>
  //             {link.data.customerPONumber || '-'}
  //         </div>,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.paymentTerm' defaultMessage='' />;
  //         },
  //         field: "paymentTermName",
  //         hide: _isColumnToHide('paymentTermName'),
  //         colId: "paymentTermName_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.value || ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.project' defaultMessage='' />;
  //         },
  //         field: "project",
  //         hide: _isColumnToHide('project'),
  //         colId: "project_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' +link.data.projectName }</span> : ''}
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.listing.totalOrder.header.priority' defaultMessage='' />;
  //         },
  //         field: "priority",
  //         hide: _isColumnToHide('priority'),
  //         colId: "priority_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 { link.data.orderPriority ? <span>{ link.data.orderPriority }</span> : '' }
  //             </div>
  //         },
  //         resizable: true
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='Sales Person' defaultMessage='' />;
  //         },
  //         field: "salesPersonFullName",
  //         hide: _isColumnToHide('salesPersonFullName'),
  //         colId: "salesPersonFullName_1",
  //         minWidth: 70,
  //         maxWidth: 300,
  //         suppressMovable: true,
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 { link.data.salesPersonFullName ? <span>{ link.data.salesPersonFullName }</span> : '' }
  //             </div>
  //         },
  //         resizable: true
  //     }
  // ]

  // if (Object.keys(permittedModules.firstSection).length) {
  //     columnDefs.push({
  //         headerComponentFramework: () => {
  //             return <div className="cursor-pointer">
  //                 <Popover
  //                     content={<AddAndRemoveCustomerListingColumn {...props} />}
  //                     title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
  //                     placement="left"
  //                     trigger="click"
  //                 >
  //                     <i className='fa fa-bars' />
  //                 </Popover>
  //             </div>;
  //         },
  //         cellRendererFramework: (param) => {
  //             return <Dropdown overlay={actionMenu(param, permittedModules)} trigger={['click']}>
  //                 <Button className="action-btn small">
  //                     <i className="fa fa-ellipsis-v" />
  //                 </Button>
  //             </Dropdown>
  //         },
  //         colId: 'action',
  //         field: 'action_1',
  //         lockPosition: true,
  //         pinned: 'right',
  //         minWidth: 60,
  //         maxWidth: 60,
  //         suppressMovable: true,
  //     });
  // }

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // const breadCrumbList = [
  //     {
  //         name: 'Customer',
  //         link: 'customers'
  //     }, {
  //         name: 'Sales Order',
  //     }, {
  //         name: 'List'
  //     }
  // ];
  // const onGridReady = (params) => {
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi
  //     });
  //     onDataRendered(params);
  // }

  // const onModelUpdated = (params) => {
  //     if((soList[pageNumber]||[]).length &&  !props.isRowHeightSet){
  //         setTimeout(()=>{
  //             params.api.resetRowHeights();
  //         }, 1000)
  //         props.updateState({
  //             isRowHeightSet: true
  //         })
  //     }
  //     if (props.tableDataReRendered) {
  //         //params.api.resetRowHeights();
  //         props.updateState({
  //             tableDataReRendered: false
  //         })
  //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_ORDER_LISTING;

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     }
  // }

  // const onDataRendered = (params) => {
  //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length? props.txColumnSetting: params.columnApi.getColumnState();
  //     if (Object.keys(txColumnSetting).length) {
  //         const columnToBeSet = [];
  //         const indexObjArr = TRANSACTION_COLUMN_INDEX.SALES_ORDER_LISTING;

  //         indexObjArr.forEach((e) => {
  //             let colObj = find(txColumnSetting, { colId: e + '_1' });
  //             if (colObj) {
  //                 columnToBeSet.push(colObj);
  //             }
  //         });

  //         txColumnSetting = txColumnSetting.filter((ele) => {
  //             let columnName = ele.colId.split("_")[0];
  //             return indexObjArr.indexOf(columnName) === -1;
  //         })
  //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //         txColumnSetting.forEach((e) => {
  //             let columnName = e.colId.split("_")[0];
  //             if (columnName !== 'action') {
  //                 if (columnName === 'isChecked') {
  //                     e.hide = false;
  //                 } else {
  //                     e.hide = _isColumnToHide(columnName);
  //                 }
  //             } if (columnName === 'action') {
  //                 e.hide = false;
  //                 e.width = 52;
  //                 e.pinned = 'right';
  //             }
  //         });
  //         params.columnApi.setColumnState(txColumnSetting);
  //         setTimeout(() => {
  //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
  //                 params.api.sizeColumnsToFit();
  //             }
  //         }, 500);
  //     } else {
  //         params.api.sizeColumnsToFit();
  //     }
  // }

  const sortColumn = (e) => {
    sortTable(
      document.getElementById("sales-order-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };

  return (
    <Fragment>
      {/* <div className="invoice-header">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}

      {/* <Collapse activeKey={collapseActiveKey} onChange  = {()=>{
                setCollapseActiveKey(collapseActiveKey ? '' : '1')
            }}>
                <Panel header="Apply Filter" key="1"  style={{ width : '100%' }}>
                <div className='qute-sect1'>
                    <Dropdown
                        // style={{ marginTop: '-20px', width: '160px', marginRight: '5px', float:'left' }}
                        className='drop-fileds1'
                        items={props.customers[pageNumber] || []}
                        valueKeyName='companyName'
                        optionKeyName='customerId'
                        value={props.selectedCustomerName}
                        onSearch={(searchedText) => {
                            props.fetchCustomers({
                                isCompact: true,
                                searchedText: searchedText,
                                pageNumber: 1, 
                                hideSpinner: true,
                                pageSize: 100,
                                relationshipId: (props.companyInfo || {}).relationshipId
                            })
                        }}
                        placeholder={props.intl.formatMessage({
                            id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                        })}
                        onSelect={
                            (customerId, option) => {
                                updateState({
                                    selectedCustomerId: option.value,
                                    selectedCustomerName: option.name,
                                })
                            }
                        }
                        allowClear={true}
                        onClear={() => {
                            updateState({
                                selectedCustomerId: undefined,
                                selectedCustomerName: undefined,
                            })
                        }}
                    />

                    <TextBox 
                        // className='new-fed1'
                        // style={{ marginTop: '', width: '140px', marginRight: '5px', float:'left'}}
                        className='drop-fileds'
                        placeholder='Enter SO #'
                        type='text'
                        maxLength={15}
                        value={props.soNum}
                        onChange={(e) => {
                            props.updateState({ soNum: e.target.value });
                        }}
                    />

                    <RangePicker
                        // style={{ width: '300px', marginRight: '5px', float:'left' }}
                        className='drop-fileds2'
                        format={format}
                        placeholder={['Start date', 'End date']}
                        allowEmpty={true}
                        value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                        onChange={(dates) => {
                            if (dates) {
                                props.updateState({ startDate: dates[0], endDate: dates[1] });
                            } else {
                                props.updateState({ startDate: null, endDate: null });
                            }
                        }}
                        />
                    <div className='solpty' style={{float:'left', marginTop:'0px'}}>
                        <i className={props.companyInfo.currencyIcon}></i> 
                        <TextBox 
                            style={{ width: '140px' }}
                            placeholder='Enter Amount'
                            type='Number'
                            className='certso'
                            // maxLength={12}
                            value={props.selectedAmount}
                            onChange={(e) => {
                                props.updateState({ selectedAmount: e.target.value });
                            }}
                        />
                    </div>

                    <Select
                        // style={{ marginTop: '', width: '60px', marginLeft:'0px', float:'left', marginRight: '5px' }}
                        className='drop-fileds3'
                        options={[{ label: '<', value: '<' }, { label: '>', value: '>' }, { label: '=', value: '=' }]}
                        value={props.selectedAmountTerm}
                        placeholder='='
                        defaultValue= '='
                        onSelect={(value) => {
                            updateState({ selectedAmountTerm: value });
                        }}
                    />

                    <Dropdown
                        items={props.userStatus || []}
                        valueKeyName='statusName'
                        optionKeyName={'soStatusId'}
                        statusColorKey='statusColor'
                        // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' }}
                        className='drop-fileds'
                        value={props.selectedUserStatusName}
                        placeholder={props.intl.formatMessage({ id: 'common.status' })}
                        onSelect={(selectedValue, option) => {
                            updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                        }}
                    />
                    <div className='cler'></div>
                    </div>
                    <div style={{clear:'both'}}></div>
                    <div className='qute-sect1'>
                    <Dropdown
                        // style={{width: '150px', float:'left', marginRight: '5px', marginTop: '-20px' }}
                        className='drop-fileds1'
                        items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partially.fulfilled' }, { name: 'Fullfilled', value: 'fulfilled' }]}
                        valueKeyName='name'
                        optionKeyName='value'
                        value={props.selectedStatusName}
                        placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                        onSelect={(status, option) => {
                            updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                        }}
                    />
 
                     <Dropdown
                        // style={{ marginTop: '-20px', width: '140px', marginRight: '5px', float:'left' }}
                        className='drop-fileds'
                        items={[{ name: 'Low', value: 'low' }, { name: 'Medium', value: 'medium' }, { name: 'High', value: 'high' }, { name: 'Urgent', value: 'urgent' }]}
                        valueKeyName='name'
                        optionKeyName='value'
                        value={props.selectedPriority}
                        placeholder={props.intl.formatMessage({ id: 'customer.filter.priority.placeholder' })}
                        onSelect={(status, option) => {
                            updateState({ selectedPriority: option.name })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedPriority: undefined })
                        }}
                    />
                    <TextBox 
                        // style={{ marginTop: '', width: '140px', marginRight: '5px', float:'left' }}
                        className='drop-fileds'
                        placeholder='Enter Ref #'
                        type='text'
                        maxLength={15}
                        value={props.refNum}
                        onChange={(e) => {
                            props.updateState({ refNum: e.target.value });
                        }}
                    />
                    <Dropdown
                        // style={{ marginTop: '-20px', width: '140px', float:'left', marginRight: '5px'}}
                        className='drop-fileds'
                        items={props.salesPersonList}
                        valueKeyName='fullName'
                        optionKeyName='relationshipEmployeeId'
                        value={props.selectedSalesPersonName}
                        placeholder={props.intl.formatMessage({ id: 'filter.salesPerson.placeholder' })}
                        onSelect={(status, option) => {
                            updateState({ selectedSalesPersonName: option.name, selectedSalesPersonId: option.value })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedSalesPersonName: undefined, selectedSalesPersonId: undefined })
                        }}
                    />
                    <Dropdown
                        // style={{ marginTop: '-20px', width: '160px', marginRight: '5px', float:'left' }}
                        className='drop-fileds1'
                        items={props.projectList}
                        valueKeyName='projectName'
                        optionKeyName='projectMasterId'
                        projectIdKey='projectMasterId'
                        value={props.selectedProjectName}
                        placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                        onSelect={(status, option) => {
                            updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                        }}
                        allowClear={true}
                        onClear={() => {
                            updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                        }}
                    />
                    <div style={{clear:'both'}}></div>
                    </div>

                    <div style={{clear:'both'}}></div>

                    <div className='qute-sect1'>
                    <button className="ant-btn ant-btn-default vab ml5" style={{marginTop:'-5px'}}  onClick={() => {
                        if(!props.selectedCustomerId && !props.soNum && !props.startDate && !props.endDate && !(props.selectedAmount && props.selectedAmountTerm) && !props.selectedUserStatusName && !props.selectedStatus && !props.selectedPriority && !props.refNum &&  !props.selectedSalesPersonName && !props.selectedProjectMasterId ){
                            return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                        }

                        const payload = {
                            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                            filters: {
                                customerId: props.selectedCustomerId || 0,
                                soNumber:props.soNum || null,
                                startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                                endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                                amount: props.selectedAmount || 0,
                                amountSymbol: props.selectedAmount ? props.selectedAmountTerm : null,
                                userStatus: props.selectedUserStatusName || null,
                                status: props.selectedStatus || null,
                                priority: props.selectedPriority || null,
                                reference : props.refNum || null,
                                salesPersonId: props.selectedSalesPersonId || 0,
                                salesPersonName: props.selectedSalesPersonName || null,
                                projectMasterId: props.selectedProjectMasterId || 0,
                            }
                        };
                        props.fetchSalesOrdersByFilter(payload);
                        props.getSalesOrdersCountByFilter(payload);

                        }}>
                            {<FilterOutlined />} Apply Filter
                    </button>
                    <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                        props.updateState({
                            selectedCustomerId: null, selectedCustomerName: null,
                            soNum: null,
                            startDate:null, endDate:null,
                            selectedAmount: null, selectedAmountTerm: null,
                            selectedUserStatusName: null,
                            selectedStatus:null, selectedStatusName:null,
                            selectedPriority: null,
                            refNum: null,
                            selectedSalesPersonName:null, selectedSalesPersonId: null,
                            selectedProjectName:null, selectedProjectMasterId: null,
                            pageNumber:1 
                        });
                        const payload = {
                            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize,
                            filters: {}
                        };
                        props.fetchSalesOrders(payload);
                        props.getSalesOrderCount(payload);
                        }}>
                            {<UndoOutlined />} Clear Filter
                    </button>
                    <div style={{clear:'both'}}></div>
                    </div>
                </Panel>
            </Collapse> */}

   
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="sales.salesOrder.table.heading"
                defaultMessage=""
              />
            </div>
            {props.salesOrderCount ? (
              <>
                <div className="vertical-sep" />
                <div>{props.salesOrderCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">
          <TextBox 
                        // className='new-fed1'
                        // style={{ marginTop: '', width: '140px', marginRight: '5px', float:'left'}}
                        className=''
                        placeholder='SO #'
                        type='text'
                        maxLength={15}
                        value={props.soNum}
                        onChange={(e) => {
                       if(e.target.value?.length > 3){  
                         const payload = {
                            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                            filters: {
                                customerId: props.selectedCustomerId || 0,
                                soNumber:e.target.value || null,
                        
                            }
                        }
                         
                        props.fetchSalesOrdersByFilter(payload);
                        props.getSalesOrdersCountByFilter(payload);} else if(e.target.value.length === 0){
                          const payload = {
                            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                            filters: {
                                customerId: props.selectedCustomerId || 0,
                                soNumber:e.target.value || null,
                        
                            }
                            
                        } 
                        props.fetchSalesOrdersByFilter(payload);
                        props.getSalesOrdersCountByFilter(payload);
                         };
                            
                        }}
                    />
            <button
              onClick={() => {
                props.updateState({
                  salesOrderFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined onClick={() => {}} /> &nbsp;{" "}
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>

            <Popover
              content={<AddAndRemoveCustomerListingColumn {...props} />}
              title={
                <div className="text-center">
                  <FormattedMessage id="common.addRemove" defaultMessage="" />{" "}
                </div>
              }
              placement="leftTop"
              trigger="click"
              arrow="right"
              visible={props.showColPop}
            >
              <button
                onClick={() => {
                  props.updateState({ showColPop: !props.showColPop });
                }}
              >
                <InsertRowRightOutlined className='mr5'/>
                Columns
              </button>
            </Popover>
          </div>
          </Skeleton>
        </div>

        {/* {props.txColumns.length ?
                    <div>
                        <div className="cursor-pointer">
                            <Tooltip placement='left' title={props.intl.formatMessage({ id: 'tooltip.addRemoveColumn.msg' })} trigger='hover'>
                                <span className="top-button">
                                    <Popover
                                        content={<AddAndRemoveCustomerListingColumn {...props} />}
                                        title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                                        placement="left"
                                        trigger="click"
                                    >
                                        <i className='fa fa-bars' />
                                    </Popover>
                                </span>
                            </Tooltip>
                        <div className="clearfix"></div>
                        </div>
                        <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            defaultColDef={{
                                flex: 1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            rowData={soList[pageNumber] || []}
                            rowDragManaged={true}
                            domLayout={"autoHeight"}
                            //getRowHeight={getRowHeight}
                            suppressDragLeaveHidesColumns={true}
                            animateRows={true}
                            onModelUpdated={onModelUpdated}
                            onColumnResized={onColumnResized}
                            onColumnVisible={(params) => {
                                params.api.resetRowHeights();
                            }}
                            onDragStopped={() => {
                                onColumnMoved({}, true);
                            }}
                            //onColumnMoved={onColumnMoved}
                        >
                        </AgGridReact>
                    </div> : null} */}

        {/* <div ></div> */}

        <div className="table-container">
        <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>
          <table id="sales-order-table">
            <thead>
              <tr>
                <th>S. No.</th>
                {!_isColumnToHide("soNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.soNo"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}

                <th>Order #</th>
                <th>Source</th>
                {!_isColumnToHide("salesOrderMasterId") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.orderNo"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("soSourceName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.salesChannel"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.customer"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("quotationNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.quoteNumber"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("salesOrderTotalAmount") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.amount"
                      defaultMessage="Amount"
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("userStatus") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.status" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("status") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.systemStatus"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("referenceNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.refNumber"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("salesOrderDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.date"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQtyInvoiced") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.qtyInvoiced"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQtyPacked") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.qtyPacked"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQtyShipped") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.qtyShipped"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("totalQtyOnPR") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.qtyOnPR"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("deliveryDate") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.deliveryDate"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("statusPRMessage") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="prStatus.text" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerInquiryNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customerSalesEnquiryNumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerRFQNumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customerRfqNumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("customerPONumber") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customerPONumber.text"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("paymentTermName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.salesOrder.listing.header.paymentTerm"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("project") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.project" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("priority") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage
                      id="customer.listing.totalOrder.header.priority"
                      defaultMessage=""
                    />
                  </th>
                ) : (
                  ""
                )}
                {!_isColumnToHide("salesPersonFullName") ? (
                  <th onClick={sortColumn}>
                    <FormattedMessage id="Sales Person" defaultMessage="" />
                  </th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {soList && soList[pageNumber]
                ? soList[pageNumber].map((rowData, index) => (
                    <tr key={index}>
                      <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>
                      {!_isColumnToHide("soNumber") ? (
                        <td className="one-line-view">
                          <div
                            className="table-link-text"
                            onClick={() => {
                              openSoDetails(rowData);
                            }}
                          >
                            {rowData.soNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}
                      <td>{rowData.customerPreOrderMasterId ?rowData.customerPreOrderMasterId :<div className="empty-data"></div>}</td>
                      <td>{rowData.source ?rowData.source:<div className="empty-data"></div>}</td>
                      {!_isColumnToHide("salesOrderMasterId") ? (
                        <td>
                          <div
                            className="table-link-text"
                            onClick={() => {
                              openSoDetails(rowData);
                            }}
                          >
                            {rowData.salesOrderMasterId||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("soSourceName") ? (
                        <td>
                          <div className="text-center">
                            {rowData.soSourceName
                              ? fetchSourceLogo(
                                  rowData.soSourceName?.toLowerCase(),rowData.soSourceName
                                )
                              : <div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerName") ? (
                        <td className="one-line-view">
                          <div className="table-link-text">
                            <div
                              // onClick={() => {
                              //     props.history.push("customers/details", {
                              //         customerId: rowData.customerId,
                              //         customerName: rowData.customerName,
                              //     })

                              onClick={() => {
                                props.pushModalToStack({
                                  modalBody: (
                                    <CustomerProfile
                                      {...props}
                                      customerId={rowData.customerId}
                                      //   customerName={rowData.customerName}
                                      //   customerDetails={rowData}
                                    />
                                  ),
                                  width: "100%",
                                  hideTitle: true,
                                  hideFooter: true,
                                  wrapClassName: "new-transaction-wrapper",
                                });
                              }}
                            >
                              {rowData.customerName||<div className="empty-data"></div>}
                            </div>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("quotationNumber") ? (
                        <td>
                          <div
                            className="table-link-text"
                            onClick={() => {
                              openSQDetails(rowData);
                            }}
                          >
                            {rowData.quotationNumber||<div className="empty-data"></div>}
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("salesOrderTotalAmount") ? (
                        <td className="one-line-view">
                          {rowData.salesOrderTotalAmount &&
                          rowData.salesOrderTotalAmount !== "-" ? (
                            <span>
                              {" "}
                              {rowData.foreignCurrencyIcon ? (
                                <i className={rowData.foreignCurrencyIcon}></i>
                              ) : rowData.foreignCurrency ? (
                                <span>{rowData.foreignCurrency}</span>
                              ) : props.companyInfo.currencyIcon ? (
                                <i
                                  className={props.companyInfo.currencyIcon}
                                ></i>
                              ) : (
                                props.companyInfo.currencyCode + " "
                              )}
                              {fixedDecimalAndFormateNumber(
                                rowData.salesOrderTotalAmount
                              )}{" "}
                            </span>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("userStatus") ? (
                        <td>
                          {rowData.userStatus ? (
                            <span
                              className="status-code"
                              style={{ background: rowData.userStatusColor }}
                            >
                              {rowData.userStatus || <div className="empty-data"></div>}
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("status") ? (
                        <td className="one-line-view">
                          {rowData.status === "Rejected" &&
                          rowData.rejectionReason ? (
                            <div>
                              <Tooltip
                                placement="topLeft"
                                title={rowData.rejectionReason}
                              >
                                <span
                                  className={[
                                    "status-code",
                                    rowData.status?.toLowerCase(),
                                  ]?.join(" ")}
                                >
                                  {rowData.status ?<FormattedMessage id={rowData.status} defaultMessage=""/>:"" }
                                </span>
                              </Tooltip>
                            </div>
                          ) : (
                            <div>
                              {rowData.status ? (
                                <span
                                  className={`status-code ${rowData.status?.toLowerCase().replace(".", "")}`}
                                >
                                   {rowData.status ?<FormattedMessage id={rowData.status} defaultMessage=""/>:"" }
                                </span>
                              ) : (
                                <div className="empty-data"></div>
                              )}
                            </div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("referenceNumber") ? (
                        <td>{rowData.referenceNumber||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("salesOrderDate") ? (
                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.salesOrderDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.salesOrderDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {rowData.salesOrderDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.salesOrderDate,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                    {!_isColumnToHide("totalQtyInvoiced") ? (
                     
                          <td >
                           
                            {getInvoicedQuantity(rowData) || <div className="empty-data"></div>}
                            
                             
                            </td>
                        
                      
                    ) : (
                      ""
                    )}

                      {!_isColumnToHide("totalQtyPacked") ? (
                        <td>{getQuantityPacked(rowData)||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("totalQtyShipped") ? (
                        <td>{getShippedQuantity(rowData)||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("totalQtyOnPR") ? (
                        <td>{getQuantityOnPR(rowData)||<div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("deliveryDate") ? (
                        <td>
                          <div>
                            <Tooltip
                              placement="topLeft"
                              title={
                                rowData.deliveryDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.deliveryDate,
                                  format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                })
                              }
                            >
                              {rowData.deliveryDate &&
                                getMomentDateForUIReadOnly({
                                  date: rowData.deliveryDate,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                })}
                            </Tooltip>
                          </div>
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("statusPRMessage") ? (
                        <td>{rowData.statusPRMessage || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerInquiryNumber") ? (
                        <td>{rowData.customerInquiryNumber || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerRFQNumber") ? (
                        <td>{rowData.customerRFQNumber || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("customerPONumber") ? (
                        <td>{rowData.customerPONumber || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("paymentTermName") ? (
                        <td>{rowData.paymentTermName || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("project") ? (
                        <td className="one-line-view">
                          {rowData.projectMasterId ? (
                            <span>
                              {
                                rowData.projectName}
                            </span>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("priority") ? (
                        <td>{rowData.orderPriority || <div className="empty-data"></div>}</td>
                      ) : (
                        ""
                      )}

                      {!_isColumnToHide("salesPersonFullName") ? (
                        <td>
                          {rowData.salesPersonFullName ? (
                            <span>{rowData.salesPersonFullName}</span>
                          ) : (
                            <div className="empty-data"></div>
                          )}
                        </td>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))
                :  <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>}
            </tbody>
          </table>
          </Skeleton>
        </div>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={salesOrderCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize);
            }}
          />
        </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(SalesOrderListingComp);
