import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { CONSTANTS } from "../../../../../static/constants";
import { getMomentDateForUIReadOnly, sortTable } from "../../../../../utils";
import { Empty } from "antd";


const Reminders = (props) => {
  const { pageNumber,pageSize, allLeadReminderList, leadData, leadAssignList=[] } = props;

  const getEmpName=(empId)=>{
    const emp = leadAssignList.find(obj=>obj.relationshipEmployeeId === empId) || {}
    return `${emp.salutationName || ''} ${emp.firstName || ''} ${emp.lastName || ''}`
  }

  // const sortColumn = (e) => {
  //   sortTable(document.getElementById('section-table'), e.target.cellIndex, 1 - dir);
  //   updateState({ dir: 1 - dir })
  // }

  // const actionMenu = (e) => {
  //   return (
  //       <Menu className="row-action-dropdown">

  //           <Menu.Item key="0" value="title">
  //             Actions
  //           </Menu.Item>

  //           <Menu.Divider />

  //           <Menu.Item key="1" value="edit" onClick={()=>{
  //             }}
  //           >
  //             <i className={ICONS.EDIT} />
  //             Edit
  //           </Menu.Item>

  //           <Menu.Item key="2" onClick={()=>
  //             {
  //               props.showModal({
  //                 modalBody: "Are you sure you want to delete?",
  //                 handleSubmit: () => {
  //                   props.deleteSection(e, props);
  //                 }
  //               })
  //             }
  //           }>
  //             <i className={ICONS.DELETE}/>
  //             Delete
  //           </Menu.Item>

  //       </Menu >
  //   )
  // };


  return (
    
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">Reminders</div>
          </div>
          <div className="right-actions">

          </div>
        </div>

        <div className="table-container">
          <table id="reminder-table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="serial.no" defaultMessage="" />
                </th>
                <th>Title</th>
                <th>Created By</th>
                <th>Updated By</th>
                <th>Date Created</th>
                {/* <th width="6%">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {
                (allLeadReminderList || []).length ? (allLeadReminderList || []).filter(obj => leadData.crmLeadId === obj.crmLeadId).map((rowData, i) => {
                  return (
                    <tr key={i}>
                      <td>{(pageNumber - 1) * (pageSize || 25) + (i + 1)}</td>
                      <td>{rowData.content}</td>
                      <td>{getEmpName(rowData.createdByEmpId)}</td>
                      <td>{getEmpName(rowData.updatedByEmpId)}</td>
                      <td>
                        {rowData.dateCreated &&
                          getMomentDateForUIReadOnly({
                            date: rowData.dateCreated,
                            format: CONSTANTS.DISPLAY_DATE_FORMAT,
                          })}
                      </td>
                      {/* <td style={{ paddingLeft: "50px" }}>
                        <div className="action-icon">
                          <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </DropdownAction>
                        </div>
                      </td> */}
                    </tr>
                  );
                })
                :
                (
                  <tr key="empty-data">
                    <td colSpan={"100%"} style={{borderBottom:"none"}}>
                      <Empty />
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
   
  );
};

export default injectIntl(Reminders);
