import React from 'react';
import { FormattedMessage } from 'react-intl';


const PackageLabelHeader = (props) => {
    const { soPackageDetail } = props;

    return (
        <div className={""}>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div style={{padding: '0px', display : "flex", alignItems : "flex-start",  flexDirection : "column"}} className="modal-header align-left">
                    <FormattedMessage id='modal.txDetails.package.label' defaultMessage='' />
                    <h6> {soPackageDetail.companyName || ''}</h6>
                    </div>
                  
                </div>
            </div>

        </div>
    );

}




export default PackageLabelHeader;
