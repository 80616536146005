import React from 'react';

import './tiles.scss';


const Tile = (props) => {
    const { tilesData,  getSelectedTile, className } = props;
    return (
        <div className={`tiles-wrapper-dashboard-index ${className}`} style={props.style}>
            {
                (tilesData && tilesData.length > 0) && tilesData.map((option, i) => {
                    const { footer, count, icon, currencyIcon, color='default', leble , antIcon } = option;
                    return (
                        <div key={i}
                        className={`tile-container-dashboard-index ${color}`}
                            onClick={() => getSelectedTile(option)}
                        >
                          <div className="tile-body-dashboard">
                            <div className="left-tile-body-dashboard">
                              <div className="tile-name-dashboard">
                                {footer}
                              </div>
                              <div style={{ fontSize: "16px" }}>
                                {leble && <span>{leble} </span>}
                                {currencyIcon ? (
                                  <i
                                    className={currencyIcon}
                                  />
                                ) : (
                                  ""
                                )}
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {count}
                                </span>
                              </div>
                            </div>

                            <div className="count-value-dashboard">
                              {antIcon ? (
                                <div className="ant-icons-dashboard">
                                  {antIcon ? antIcon : ""}
                                </div>
                              ) : (
                                <i
                                  className={
                                    currencyIcon ||
                                    (this.props.companyInfo || {})
                                      .currencyIcon
                                  }
                                />
                              )}
                              <div className="ant-icons-dashboard">
                                {icon ? icon : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default Tile;

//
// Tile have some color options
// for that you need to pass color key in data object for every tile
// default color is gray '#999'
// color option: 'green-tile',  'darkGray-tile',  'blue-tile',  'orange-tile' 'magenta-tile', 'purple-tile', 'default'
//
//
Tile.defaultProps = {
    customClass: 'cstmCls',
    getSelectedTile: (selectedTile) => {  },
    tilesData: [
        {
            header: 'Demo Label1',
            count: 0,
            icon: '',
            currencyIcon:'',
            color: 'green-tile'
        },
        {
            footer: 'Demo Label2',
            count: 0,
            icon: '',
            currencyIcon:'',
            color: 'darkGray-tile'
        },
        {
            header: 'Demo Label3',
            count: 0,
            icon: '',
            currencyIcon:'',
            color: 'blue-tile'
        },
        {
            footer: 'Demo Label4',
            count: 0,
            icon: '',
            currencyIcon:'',
            color: 'orange-tile'
        }
        // ,
        // {
        //     header: 'Demo Label5',
        //     count: 0,
        //     icon: 'dollar',
        //     color: 'magenta-tile'
        // },
        // {
        //     header: 'Demo Label6',
        //     count: 0,
        //     icon: 'dollar',
        //     color: 'purple-tile'
        // },
        // {
        //     header: 'Demo Label7',
        //     count: 0,
        //     icon: 'dollar',
        // },
        // {
        //     header: 'Demo Label8',
        //     count: 0,
        //     icon: 'dollar',
        //     color: 'default'
        // }
    ]
};
