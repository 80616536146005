import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TasksDrawerCompo from '../../../../components/drawer/leads/leadTask';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchAllEmployees } from '../../../../actions/commonActions';
import { createLeadTask } from './action';


class LeadTask extends Component {
    constructor(props){
      super(props);
      this.state ={
        
      }
    }
    componentDidMount() {
      const companyInfo = this.props.companyInfo || {};
      const payload = {
        relationshipId: companyInfo.relationshipId,
        customerId: this.props.customerId
      };
      this.props.fetchAllEmployees(payload);
    }
  
    render() {
      return (
        <TasksDrawerCompo {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data)}}/>
      )
    }
  }
  
  const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      allEmployee: state.common.employees,
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    createLeadTask,
    pushModalToStack,
    popModalFromStack,
    showModal,
    hideModal,
    fetchAllEmployees
  }, dispatch);
  
  export default connect(mapStateToProps, mapDispatchToProps)(LeadTask);
  