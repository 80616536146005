import React from "react";
import { Skeleton, Pagination } from 'antd';
import { CONSTANTS } from '../../../static/constants';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { CustomAgGrid } from "../../general/CustomAgGrid";
import { getMomentDateForUIReadOnly, sortTable } from "../../../utils";

// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };


// const getRowHeight = (params) => {
//     return 28;
// }



const AuditTrailComp = (props) => {

    const {pageSize, pageNumber, dir=0, hiddenTransactionList, updateState} = props;

    // const onGridReady = (params) => {
    //     params.api.sizeColumnsToFit();
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi,
    //     });
    // }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageSize, pageNumber, props) => {
        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize
        };
        props.getHiddenTransactions(payload);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize
        };
        props.getHiddenTransactions(payload);
       props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    const sortColumn = (e) => {
        sortTable(document.getElementById('audit-trail-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
      }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="common.date.text" /></span>
    //             </div>
    //         },
    //         field: "date",
    //         resizable: true,
    //         valueFormatter: (data) => {
    //             data.value = data.value ? getMomentDateForUIReadOnly({date: data.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}) : '';
    //             return data.value;
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.deposit" /></span>
    //             </div>
    //         },
    //         field: "amount",
    //         cellRendererFramework: (link) => <div>
    //             {link.data.amount > 0 ? Number(link.data.amount || 0).toFixed(2) : '0.00'}
    //         </div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.withdrawal" /></span>
    //             </div>
    //         },
    //         field: "amount",
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //             {link.data.amount < 0 ? Number(Math.abs(link.data.amount || 0)).toFixed(2) : '0.00'}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.institutionName" /></span>
    //             </div>
    //         },
    //         field: "institutionName",
    //         hide: props.importType==='import',
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.auditTrail.transacitonCount" /></span>
    //             </div>
    //         },
    //         field: "transacitonCount",
    //         hide: props.importType==='import',
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.auditTrail.file" /></span>
    //             </div>
    //         },
    //         field: "file",
    //         resizable: true,
    //         cellRendererFramework: (link) => <div>
    //         <i className="fa fa-file-excel-o" />
    //     </div>,
    //     },
       
    // ]


    return (
      <>
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="finance.banking.hidetxn.table.heading"
                defaultMessage=""
              />
            </div>
            {pageSize ? (
              <>
                <div className="vertical-sep" />
                <div>{pageSize}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions"></div>
        </div>
        <Skeleton loading={props.isLoading} active paragraph={{ rows: 12 }}>
          <div className="table-container">
            <table id='audit-trail-table'>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="common.date.text" /> <i class="fi fi-rr-sort-alt"></i>
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="banking.deposit" /> <i class="fi fi-rr-sort-alt"></i>
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="banking.withdrawal" /> <i class="fi fi-rr-sort-alt"></i>
                  </th>
                  {props.importType === "import" ? (
                    <th onClick={sortColumn}>
                      <FormattedMessage id="banking.institutionName" /> <i class="fi fi-rr-sort-alt"></i></th>
                  ) : (
                    ""
                  )}
                  <th onClick={sortColumn}>
                    <FormattedMessage id="banking.auditTrail.transacitonCount" /> <i class="fi fi-rr-sort-alt"></i>
                  </th>
                  <th>
                    <FormattedMessage id="banking.auditTrail.file" /> <i class="fi fi-rr-sort-alt"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {hiddenTransactionList &&
                (hiddenTransactionList || [])[pageNumber || 1]
                  ? (hiddenTransactionList || [])[pageNumber || 1].map(
                      (rowData, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {rowData.date
                                ? getMomentDateForUIReadOnly({
                                    date: rowData.date,
                                    format: CONSTANTS.DISPLAY_DATE_FORMAT_FY,
                                  })
                                : "-"}
                            </td>
                            <td>
                              {" "}
                              {rowData.amount > 0
                                ? Number(rowData.amount || 0).toFixed(2)
                                : "0.00"}
                            </td>
                            <td>
                              {" "}
                              {rowData.amount < 0
                                ? Number(Math.abs(rowData.amount || 0)).toFixed(
                                    2
                                  )
                                : "0.00"}
                            </td>
                            {props.importType === "import" ? (
                              <td>{rowData.institutionName}</td>
                            ) : (
                              ""
                            )}
                            <td>{rowData.transacitonCount}</td>
                            <td>
                              <i className="fa fa-file-excel-o" />
                            </td>
                          </tr>
                        );
                      }
                    )
                  : ""}
              </tbody>
            </table>
          </div>

          {/* <div className="agGridWrapper">
            <div className="ag-theme-balham" style={agGridStyle}>
              <CustomAgGrid
                columnDefs={columnDefs}
                rowData={
                  (props.hiddenTransactionList || [])[props.pageNumber || 1]
                }
                rowDragManaged={true}
                domLayout={"autoHeight"}
                animateRows={true}
                onGridReady={onGridReady}
                getRowHeight={getRowHeight}
              />
            </div>
          </div> */}
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={props.hiddenTransactionCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageSize, pageNumber, props);
                props.updateState({
                  pageSize: pageSize,
                  pageNumber: pageNumber,
                });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber, pageSize, props);
              }}
            />
          </div>
        </div>
      </>
    );
};

export default injectIntl(AuditTrailComp);