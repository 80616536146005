import React from "react";
import { Tabs } from 'antd';
import PageBreadcrumb from "../../PageBreadcrumb";
import AllStock from "./allStock";
import PublishedStock from "./publishedStock";
import UnpublishedStock from "./unpublishedStock";
import Clearance from "./clearance";
import DealOfTheDay from "./dealOfTheDay";
import NewArrival from "./newArrival";
import BestSellingItem from "./bestSellingItem";
import OnSaleComp from "./onSale";
import './index.scss'
import { FormattedMessage } from "react-intl";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
import { Dropdown } from '../../general/Dropdown';
const { TabPane } = Tabs;

function callback(key, props) {
    const payload = {
        companyInfo: props.companyInfo,
        relationshipId: props.companyInfo.relationshipId,
        pageNumber:1,
        pageSize: props.pageSize,
        isDetails: true,
        warehouseMasterId: props.warehouseMasterId || 0
    };
    if (key === '1') {
        props.updateState({activeTab: 'All Stock', activeTabKey: "1"});
        fetchDataIfNeeded('fetchProducts', 'products', props, payload);
        fetchPaginationDataIfNeeded('fetchProducts', 'products', props, payload);
    } else if (key === '2') {
        props.updateState({activeTab: 'Published Stock', activeTabKey: "2"});
        fetchDataIfNeeded('getPublishedProductsCount', 'publishedProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchPublishedProducts', 'publishedProducts', props, payload);
    }else if (key === '3') {
        props.updateState({activeTab: 'Unpublished Stock', activeTabKey: "3"});
       fetchDataIfNeeded('getUnpublishedProductsCount', 'unpublishedProductCount', props, payload);
       fetchPaginationDataIfNeeded('fetchUnpublishedProducts', 'unpublishedProducts', props, payload);
    }
    else if (key === '4') {
        props.updateState({activeTab: 'Clearance', activeTabKey: "4"});
        fetchDataIfNeeded('getClearanceProductsCount', 'clearanceProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchClearanceProducts', 'clearanceProducts', props, payload);
    }
    else if (key === '5') {
        props.updateState({activeTab: 'Deal of the Day', activeTabKey: "5"});
        fetchDataIfNeeded('getDealOfTheDayProductsCount', 'dealOfTheDayProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchDealOfTheDayProducts', 'dealOfTheDayProducts', props, payload);
    }
    else if (key === '6') {
        props.updateState({activeTab: 'New Arrival', activeTabKey: "6"});
        fetchDataIfNeeded('getNewArrivalProductsCount', 'newArrivalProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchNewArrivalProducts', 'newArrivalProducts', props, payload);
    }
    else if (key === '7') {
        props.updateState({activeTab: 'Best Selling Item', activeTabKey: "7"});
        fetchDataIfNeeded('getBestSellingProductsCount', 'bestSellingProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchBestSellingProducts', 'bestSellingProducts', props, payload);
    }
    else if (key === '8') {
        props.updateState({activeTab: 'On Sale', activeTabKey: "8",});
        fetchDataIfNeeded('getOnSaleProductsCount', 'onSaleProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchOnSaleProducts', 'onSaleProducts', props, payload);
    }
}


function warehouseChange(activeTabKey, props) {
    const payload = {
        companyInfo: props.companyInfo,
        relationshipId: props.companyInfo.relationshipId,
        pageNumber: props.pageNumber,
        pageSize: props.pageSize,
        isDetails: true,
        warehouseMasterId: props.warehouseMasterId || 0
    };
    if (activeTabKey === '1') {
        fetchPaginationDataIfNeeded('fetchProducts', 'products', props, payload);
        fetchDataIfNeeded('getProductCount', 'productCount', props, payload);
    } else if (activeTabKey === '2') {
        fetchDataIfNeeded('getPublishedProductsCount', 'publishedProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchPublishedProducts', 'publishedProducts', props, payload);
    }else if (activeTabKey === '3') {
       fetchDataIfNeeded('getUnpublishedProductsCount', 'unpublishedProductCount', props, payload);
       fetchPaginationDataIfNeeded('fetchUnpublishedProducts', 'unpublishedProducts', props, payload);
    }
    else if (activeTabKey === '4') {
        fetchDataIfNeeded('getClearanceProductsCount', 'clearanceProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchClearanceProducts', 'clearanceProducts', props, payload);
    }
    else if (activeTabKey === '5') {
        fetchDataIfNeeded('getDealOfTheDayProductsCount', 'dealOfTheDayProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchDealOfTheDayProducts', 'dealOfTheDayProducts', props, payload);
    }
    else if (activeTabKey === '6') {
        fetchDataIfNeeded('getNewArrivalProductsCount', 'newArrivalProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchNewArrivalProducts', 'newArrivalProducts', props, payload);
    }
    else if (activeTabKey === '7') {
        fetchDataIfNeeded('getBestSellingProductsCount', 'bestSellingProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchBestSellingProducts', 'bestSellingProducts', props, payload);
    }
    else if (activeTabKey === '8') {
        fetchDataIfNeeded('getOnSaleProductsCount', 'onSaleProductCount', props, payload);
        fetchPaginationDataIfNeeded('fetchOnSaleProducts', 'onSaleProducts', props, payload);
    }
}



const SalesQuoteListingComp = (props) => {
  
    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        // {
        //     name: 'Sales',
        // },
        {
            name: <FormattedMessage id='ecommerce.text' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='publishedStock.text' defaultMessage='' />,
        }
    ];
   
    return (
        <>
        <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
           Product Management
          </div>
          <div className="vertical-sep"/>
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
        <Dropdown
                    style={{ width: 320 }}
                    placeholder={props.intl.formatMessage({ id: 'drawer.inventory.warehouse.placeholder', defaultMessage: '' })}
                    items={props.allWarehouses}
                    valueKeyName='warehouseName'
                    optionKeyName='warehouseName'
                    value={props.warehouseName}
                    onSelect={(optionValue, elem) => {
                        let obj = props.allWarehouses.find(warehouse => (warehouse.warehouseName === optionValue));
                        props.updateState({
                            warehouseName: optionValue,
                            warehouseMasterId: obj.warehouseMasterId,
                        });
                        warehouseChange(props.activeTabKey || "1",props);
                    }}
                    
                />
        </div>
      </div>
        <div className="view-container">
        
        

                

           
                    <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => {
                        props.updateState({
                            selectedItems:[],
                           
                        })
                        callback(key, props); }} type="line">
                    <TabPane tab="All Stock" key="1">
                            <AllStock {...props} />
                        </TabPane>
                        <TabPane tab="Published Stock" key="2">
                            <PublishedStock {...props} />
                        </TabPane>
                        <TabPane tab="Unpublished Stock" key="3">
                            <UnpublishedStock {...props} />
                        </TabPane>
                        <TabPane tab="Clearance" key="4">
                            <Clearance {...props} />
                        </TabPane>
                        <TabPane tab="Deal of the Day" key="5">
                            <DealOfTheDay {...props} />
                        </TabPane>
                        <TabPane tab="New Arrival" key="6">
                            <NewArrival {...props} />
                        </TabPane>
                        <TabPane tab="Best Selling Item" key="7">
                            <BestSellingItem {...props} />
                        </TabPane>
                        <TabPane tab="On Sale" key="8">
                            <OnSaleComp {...props} />
                        </TabPane>
                    </Tabs>
             

           
        </div>
        </>
    )
};

export default SalesQuoteListingComp;
