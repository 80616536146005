import React from 'react';
import { Col, Row, DatePicker, Tooltip } from 'antd';
import { Form } from '@ant-design/compatible';
import { injectIntl, FormattedMessage } from 'react-intl';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { CustomButton } from '../../../general/CustomButton';
import LoadingIcon from '../../../../assets/images/loading.gif';
import * as moment from 'moment';
import { CONSTANTS } from '../../../../static/constants';
import CKEditor from '../../../general/CustomCKEditor/ckeditor';
import { CUSTOM_CONFIGURATION } from '../../../../static/ckEditorConfigConstants';

const PayExpense = (props) => {
    const {
        intl,
        expenseNumber,
        businessExpenseData,
        paymentModeDetails,
        paymentModeName,
        amount,
        expenseAmount,
        expenseAmountDue,
        remarks,
        paymentModeList,
        cashEquivalentLedgers,
        updateState,
        createSupplierPayment,
        hideModal
    } = props;


    return (
        <div className="header-action-container sales-invoice">
            <Form>
                <Row>
                    <Col span={8}>
                        <Tooltip placement="bottom" title="System Generated Payment Number">
                            <span className="tooltip-title">
                                <FormattedMessage id='modal.supplier.payInvoice.paymentNumber' defaultMessage='' />
                            </span>
                        </Tooltip>
                    </Col>

                    <Col span={16}>
                        {props.nextTxNumber ?
                            <span>{getFormatedNextTxNumber(props)}</span>
                            :
                            <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'marginTop': '-25px', 'marginBottom': '-25px' }} />
                        }
                    </Col>
                </Row>
                {/* <Row>
                    <Col span={8}>
                        <FormattedMessage id='modal.supplier.payInvoice.selectSupplier' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                        <Dropdown
                            items={suppliers[1] || []}
                            valueKeyName='supplierStoreName'
                            optionKeyName='supplierId'
                            className={(props.submittedOnce && !props.supplierName) ? 'input-text-error' : ''}
                            value={(suppliers[1] || []).length ? (supplierName || props.supplierId) : undefined}
                            placeholder={intl.formatMessage(
                                { id: 'modal.supplier.payInvoice.selectSupplier.placeholder', defaultMessage: '' }
                            )}
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    supplierName: undefined,
                                    supplierId: undefined
                                });
                                resetInvoiceDetail(props);
                            }}
                            onSelect={
                                (optionValue) => {
                                    let supplier = (suppliers[1] || []).find(sup => (sup.supplierId === optionValue));
                                    updateState({
                                        supplierName: supplier.supplierStoreName,
                                        supplierId: optionValue
                                    });
                                    resetInvoiceDetail(props);
                                    props.fetchUnpaidInvoices({
                                        supplierId: optionValue,
                                        relationshipId: (props.companyInfo || {}).relationshipId,
                                        pageSize: 100,
                                        pageNumber: 1
                                    })

                                }
                            }
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnce || !!props.supplierName }}
                            message={<FormattedMessage id='modal.supplier.payInvoice.supplierValidation' defaultMessage='' />}
                        />
                    </Col>
                </Row> */}
                {/* <Row>
                    <Col span={8}>
                        <FormattedMessage id='modal.supplier.payInvoice.supplierName' defaultMessage=''/>
                    </Col>

                    <Col span={16}>
                        <div className="pl5"><strong>{supplierName}</strong></div>
                    </Col>
                </Row> */}
                <Row>
                    <Col span={8}>
                        <FormattedMessage id='modal.supplier.payInvoice.paymentDate' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                        <DatePicker
                            allowClear={false}
                            style={{ width: '100%' }}
                            placeholder={intl.formatMessage(
                                { id: 'modal.supplier.payInvoice.paymentDate.placeholder', defaultMessage: '' }
                            )}
                            key={`${moment(new Date(props.paymentDate), CONSTANTS.DISPLAY_DATE_FORMAT)}`}
                            defaultValue={moment(props.paymentDate, CONSTANTS.DISPLAY_DATE_FORMAT)}
                            onChange={(selectedDate) => {
                                updateState({
                                    paymentDate: selectedDate
                                })
                                props.getNextTxNumber({ date: selectedDate, relationshipId: props.companyInfo.relationshipId });
                            }}
                            className={(props.submittedOnce && !props.paymentDate) ? 'input-text-error' : ''}
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnce || !!props.paymentDate }}
                            message={<FormattedMessage id='modal.supplier.payInvoice.paymentDateValidation' defaultMessage='' />}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <FormattedMessage id='supplier.businessExpense.listing.header.expenseNo' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                    <div className="pl5"><strong>{expenseNumber}</strong></div>
                        {/* <Dropdown
                            items={unpaidInvoiceList[1] || []}
                            valueKeyName='invoiceNumber'
                            optionKeyName='invoiceMasterId'
                            valueKeyName3='invoiceDueAmount'
                            currencyCode={(props.companyInfo || {}).currencyCode + " "}
                            value={props.invoiceNumber ? (`${props.invoiceNumber} (Bal. ${props.companyInfo.currencyCode + " "} ${invoiceAmountDue})` || invoiceMasterId) : undefined}
                            placeholder={intl.formatMessage(
                                { id: 'modal.supplier.payInvoice.invoiceNumber.placeholder', defaultMessage: '' }
                            )}
                            className={(props.submittedOnce && !props.invoiceNumber) ? 'input-text-error' : ''}
                            onSelect={
                                (optionValue) => {
                                    let selectedInvoice = (unpaidInvoiceList[1] || []).find(inv => (inv.invoiceMasterId === optionValue)) || {};
                                    updateState({
                                        invoiceMasterId: optionValue,
                                        invoiceNumber: selectedInvoice.invoiceNumber,
                                        invoiceAmount: selectedInvoice.invoiceTotalAmount,
                                        amount: selectedInvoice.invoiceDueAmount,
                                        invoiceAmountDue: selectedInvoice.invoiceDueAmount,
                                        invoiceData: selectedInvoice,
                                        salesQuotationMasterId: selectedInvoice.salesQuotationMasterId,
                                        quotationNumber: selectedInvoice.quotationNumber,
                                        rfqNumber: selectedInvoice.rfqNumber,
                                        rfqMasterId: selectedInvoice.rfqMasterId,
                                        reference: selectedInvoice.referenceNumber
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                resetInvoiceDetail(props);
                            }}
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnce || !!props.invoiceNumber }}
                            message={<FormattedMessage id='modal.supplier.payInvoice.invoiceValidation' defaultMessage='' />}
                        /> */}
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <FormattedMessage id='modal.supplier.payInvoice.paymentMode' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                        <Dropdown
                            items={paymentModeList || []}
                            valueKeyName='paymentModeName'
                            optionKeyName='paymentModeId'
                            value={paymentModeList.length ? (props.paymentModeName || props.paymentModeId) : undefined}
                            placeholder={intl.formatMessage(
                                { id: 'modal.supplier.payInvoice.paymentMode.placeholder', defaultMessage: '' }
                            )}
                            onSelect={
                                (optionValue) => {
                                    let selectedPaymentMode = (paymentModeList || []).find(pay => (pay.paymentModeId === optionValue)) || {};
                                    updateState({
                                        paymentModeId: optionValue,
                                        paymentModeName: selectedPaymentMode.paymentModeName,  
                                        chartOfAccountDetailsId: selectedPaymentMode.paymentModeName === 'Cash' ? props.cashInHandLedger.ledgerAccountId : 0,
                                        ledgerAccountName: selectedPaymentMode.paymentModeName === 'Cash' ? props.cashInHandLedger.txType : '',
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    paymentModeId: undefined,
                                    paymentModeName: undefined
                                })
                            }}
                        />
                        <ErrorMsg
                            validator={() => { return !props.submittedOnce || !!props.paymentModeId }}
                            message={<FormattedMessage id='modal.payInvoice.paymentModeValidation' defaultMessage='' />}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormattedMessage id='modal.supplier.payInvoice.paymentModeDetails' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                        <TextBox
                            type='text'
                            value={paymentModeDetails}
                            onChange={(e) => {
                                updateState({
                                    paymentModeDetails: e.target.value
                                });
                            }}
                            placeholder={intl.formatMessage({ id: 'modal.supplier.payInvoice.paymentModeDetails.placeholder', defaultMessage: '' })}
                        />
                    </Col>
                </Row>
                {(paymentModeName && paymentModeName === 'Cash') &&
                    <Row>
                        <Col span={8}>
                            <FormattedMessage id='cashAccount.text' defaultMessage='' />
                        </Col>
                        <Col span={16}>
                            <TextBox type='text' value={props.ledgerAccountName} disabled={true} />
                        </Col>
                    </Row>
                }
                {(paymentModeName && paymentModeName !== 'Cash') &&
                    <Row>
                        <Col span={8}>
                            <FormattedMessage id='modal.supplier.payInvoice.bankAccount' defaultMessage='' />
                        </Col>

                        <Col span={16}>
                            <Dropdown
                            style={{ width: "94%"}}
                                items={cashEquivalentLedgers || []}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='chartOfAccountDetailsId'
                                value={cashEquivalentLedgers.length ? (props.ledgerAccountName || props.chartOfAccountDetailsId) : undefined}
                                placeholder={intl.formatMessage(
                                    { id: 'modal.supplier.payInvoice.bankAccount.placeholder', defaultMessage: '' }
                                )}
                                onSelect={
                                    (optionValue) => {
                                        let selectedAcc = (cashEquivalentLedgers || []).find(acc => (acc.chartOfAccountDetailsId === optionValue)) || {};
                                        updateState({
                                            chartOfAccountDetailsId: optionValue,
                                            ledgerAccountName: selectedAcc.ledgerAccountName
                                        })
                                    }
                                }
                                allowClear={true}
                                onClear={() => {
                                    updateState({
                                        chartOfAccountDetailsId: undefined,
                                        ledgerAccountName: undefined
                                    })
                                }}
                            />
                             <ErrorMsg
                                    validator={() => { return !props.submittedOnce || !!props.chartOfAccountDetailsId }}
                                    message={<FormattedMessage id='modal.payInvoice.ledgerValidation' defaultMessage='' />}
                                />
                           
                            <Tooltip placement="top"
                                title={props.intl.formatMessage({ id: 'cashEquivalents.ledgers' })} trigger="click">
                                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                            </Tooltip>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col span={8}>
                        <FormattedMessage id='modal.supplier.payInvoice.amount' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                        <div className="input-currency-control">
                            <span className="currency-tag">
                                {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
                            </span>

                            <TextBox
                                type='number'
                                className={(props.submittedOnce && ((props.amount > expenseAmountDue) || (props.amount <= 0) || !props.amount)) ? 'input-text-error' : ''}
                                value={Number(amount)}
                                onChange={(e) => {
                                    updateState({
                                        amount: Number(e.target.value).toFixed(2),
                                        invalidAmountClass: (Number(e.target.value) > expenseAmountDue) ? 'input-text-error' : '',
                                    });
                                }}
                                placeholder={intl.formatMessage({ id: 'modal.supplier.payInvoice.placeholder', defaultMessage: '' })}
                            />
                            <ErrorMsg
                                validator={() => { return !props.submittedOnce || !!amount }}
                                message={<FormattedMessage id='modal.supplier.payInvoice.amountValidation' defaultMessage='' />}
                            />
                            <ErrorMsg
                                validator={() => { return !props.submittedOnce || !amount || !(Number(amount) > expenseAmountDue.toFixed(2))}}
                                message={<FormattedMessage id='modal.supplier.payInvoice.amountOverValidation' defaultMessage='' />}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormattedMessage id='supplier.rfq.form.totalExpenseAmount.label' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                        <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{expenseAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <FormattedMessage id='supplier.rfq.form.expenseAmountDue.label' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                        <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(expenseAmountDue - amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={8} className='detail-column'>
                        <div >
                            <FormattedMessage id='supplier.purchaseRequest.form.refNumber.label' defaultMessage='' />
                        </div>
                    </Col>
                    <Col span={16}>
                        <TextBox
                            placeholder={intl.formatMessage(
                                { id: 'supplier.purchaseRequest.form.refNumber.placeholder', defaultMessage: '' }
                            )}
                            type='text'
                            value={props.reference}
                            onChange={(e) => {
                                updateState({
                                    reference: e.target.value
                                });
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormattedMessage id='modal.supplier.payInvoice.remarks' defaultMessage='' />
                    </Col>

                    <Col span={16}>
                        {/* <TextBox
                            type='text'
                            value={remarks}
                            onChange={(e) => {
                                updateState({
                                    remarks: e.target.value
                                });
                            }}
                            placeholder={intl.formatMessage({ id: 'modal.supplier.payInvoice.remarks.placeholder', defaultMessage: '' })}
                        /> */}
                        { <CKEditor
                            type="inline"
                            className="description-input"
                            key={`${"remarks"}`}
                            data={remarks}
                            editor={props.editor}
                            onInit={editor => {
                                updateState({
                                    editor
                                })
                            }}
                            onChange={(event) => {
                                const data = event.editor.getData();
                                updateState({
                                    remarks: data
                                });
                            }}
                            config={CUSTOM_CONFIGURATION}
                        />}
                    </Col>
                </Row>
                {(businessExpenseData && businessExpenseData.projectNumber) &&
                    <Row>
                        <Col span={8}>
                            <FormattedMessage id='modal.supplier.payInvoice.project' defaultMessage='' />
                        </Col>

                        <Col span={16}>
                            <span>{businessExpenseData.projectNumber + ' (' + businessExpenseData.projectName + ')'}</span>
                        </Col>
                    </Row>
                }
            </Form>
            <div className='form-footer'>
                <CustomButton
                    intlId='confirmation.cancel'
                    defaultMessage=''
                    type='default'
                    key={0}
                    style={{ marginRight: 8 }}
                    onClick={hideModal}
                />
                <CustomButton
                    intlId='confirmation.submit'
                    defaultMessage=''
                    htmlType='submit'
                    key={1}
                    onClick={() => {
                        props.updateState({ submittedOnce: true });
                        if (Number(props.amount) > props.expenseAmountDue.toFixed(2)) {
                            return;
                        }
                        if ( !props.paymentDate || !props.expenseNumber || !props.paymentModeId || !props.chartOfAccountDetailsId || Number(props.amount) <= 0) {
                            return;
                        }
                        const modalData = {
                            modalBody: <FormattedMessage id='modal.supplier.payInvoice.saveConfirmation' defaultMessage='' />,
                            handleSubmit: () => { 
                                createSupplierPayment(props);
                                props.popModalFromStack();// for closing Invoice rad only to reflact amt
                            },
                        };
                        props.pushModalToStack(modalData);
                    }}
                />
            </div>
        </div>
    );
};

// const resetInvoiceDetail = (props) => {
//     props.updateState({
//         invoiceMasterId: undefined,
//         invoiceNumber: undefined,
//         invoiceAmount: 0,
//         amount: 0,
//         invoiceAmountDue: 0,
//         invoiceData: undefined,
//         salesQuotationMasterId: undefined,
//         quotationNumber: undefined,
//         rfqNumber: undefined,
//         rfqMasterId: undefined,
//         reference: undefined
//     })
// }

const getFormatedNextTxNumber = (props) => {
    const { supplierId } = props;
    if (!supplierId) {
        return <span>{props.nextTxNumber}</span>;
    }
    return <span>{props.nextTxNumber.replace('SPAY-00', 'SPAY-' + (supplierId < 10 ? ('0' + supplierId) : supplierId))}</span>
}

export default injectIntl(PayExpense);