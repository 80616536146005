import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AssignRollNoStudentComp from '../../../../../components/modal/modalBody/schoolManagement/AssignRollNoToStudent';
import { saveOrUpdateRollNo } from './action';
import { getClassList } from '../../../../schoolManagement/class/action';

class AssignRollNo extends Component {
    constructor(props) {
        super(props);

    }
    componentWillMount() {

        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 500,relationshipEmployeeId: userInfo.relationshipEmployeeId,formId: 0 };

    }

    render() {
        return <AssignRollNoStudentComp {...this.props} {...this.state} updateModalState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        classList: state.school.classList,
        userInfo: state.common.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveOrUpdateRollNo,
    getClassList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AssignRollNo);