import React from "react";
import { Button, Tabs } from "antd";
import PageBreadcrumb from "../../../PageBreadcrumb";
import SalesInquiry from "./salesInquiry";
import SalesInquiryDraft from "./salesInquiryDraft";
import {
  fetchDataIfNeeded,
  fetchPaginationDataIfNeeded,
} from "../../../../utils";
import { FormattedMessage } from "react-intl";
import StandardSalesEnquiry from "../../../../containers/customer/salesEnquiry/StandardSalesEnquiry";
import { ICONS } from "../../../../static/constants";
import PageHeader from "../../../Common/pageHeader";
import { CustomButton } from "../../../general/CustomButton";
import DeadInquiry from "./deadInquiry";


const { TabPane } = Tabs;

function callback(key, props) {
  const payload = {
    companyInfo: props.companyInfo,
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: props.pageNumber,
    pageSize: props.pageSize,
  };
  if (key === "1") {
    props.updateState({ activeTab: "Inquiry", activeTabKey: "1" ,isActive:1});
    props.fetchSalesEnquiry({...payload, isActive:1})

  } else if (key === "2") {
    props.updateState({ activeTab: "Draft", activeTabKey: "2" });    
    fetchDataIfNeeded(
      "getSalesInquiryDraftCount",
      "inquiryDraftCount",
      props,
      payload
    );
    fetchPaginationDataIfNeeded(
      "fetchSalesInquiryDrafts",
      "inquiryDraftList",
      props,
      payload
    );
  }
 else if (key === "3") {
    props.updateState({ activeTab: "Inactive Inquiry", activeTabKey: "3",isActive:0 ,pageNumber:1,pageSize:25})
    props.resetPaginatedData();
    props.fetchSalesEnquiry({...payload,pageNumber:1,pageSize:25, isActive:0})
    props.fetchSalesEnquiryCount({...payload, isActive:0})


}
}
const SalesEnquiryListingComp = (props) => {
  const openNewSi = () => {
    props.resetCustomerDetail();        
    props.pushModalToStack({
      modalBody: <StandardSalesEnquiry {...props} />,
      width: "100%",
      hideTitle: true,
      hideFooter: true, keyboard: false,
      wrapClassName: "new-transaction-wrapper",
      onCancel: () => {
        props.showModal({
          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
          footer:{submit:{intlId:"button.close.label"}},
          handleSubmit: () => {
            props.popModalFromStack();
          }
        })
      }
   
    });
  };
  const breadCrumbList = [
    {
      name: <FormattedMessage id="common.sales" defaultMessage="" />,
    },
    {
      name: (
        <FormattedMessage id="breadcrum.sales-inquiry.text" defaultMessage="" />
      ),
    },
  ];

  return (
    <>
      <PageHeader {...props}
        pageName="heading.sales.salesInquiry"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={openNewSi}
      />
      <div className="view-container">
        <Tabs
          activeKey={props.activeTabKey || "1"}
          onChange={(key) => {
            callback(key, props);
          }}
          type="line"
        >
          <TabPane tab="Inquiry" key="1">
            <SalesInquiry {...props} />
          </TabPane>
          <TabPane tab="Draft" key="2">
            <SalesInquiryDraft {...props} />
          </TabPane>
          <TabPane tab="Inactive Inquiry" key="3">
            <DeadInquiry {...props} />
            
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default SalesEnquiryListingComp;
