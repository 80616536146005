import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import './index.scss';

const HeaderMenuOptions = (props) => {
    const { data, getSelectedOption } = props;

    const getCategoryOptions = (data) => {
        return (data && data.length > 0) && data.map((option) => {
            const { name, label, hierarchy, link } = option;

            return (
                <li key={name} className='sub-category'>
                    {
                        link ?
                            <Link className='sub-category-label' to={
                                {
                                    pathname: link,
                                    state: {}
                                }
                            }>{label}</Link>
                            : <div className='sub-category-label' onClick={() => getSelectedOpt(option)}>{label}</div>

                    }

                    {
                        (hierarchy && hierarchy.length > 0) &&
                        <ul className='sub-category-wrapper'>
                            {getCategoryOptions(hierarchy)}
                        </ul>
                    }
                </li>
            )
        });
    }

    const getSelectedOpt = (selectedValue) => {
        getSelectedOption(selectedValue);
    }

    const useOuterClick = (callback) => {
        const innerRef = useRef();
        const callbackRef = useRef();

        // set current callback in ref, before second useEffect uses it
        useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });

        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);

            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep

        return innerRef; // return ref; client can omit `useRef`
    }

    const innerRef = useOuterClick(e => {
        // counter state is up-to-date, when handler is called
        props.updateState({
            showHeaderMenuOptions: false
        })
    });

    return (
        <ul ref={innerRef} className='header-menu-options-wrapper category-wrapper'>
            {getCategoryOptions(data)}
        </ul>
    );
};

export default injectIntl(HeaderMenuOptions);

HeaderMenuOptions.defaultProps = {
    data: [],
    getSelectedOption: () => { }
}
