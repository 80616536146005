import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DiscountRuleComponent from '../../../../../../components/modal/modalBody/settings/RegistrationForm/DiscountRule';
import { getSubcourse, addSubcourse, deleteSubcourse } from './action';

class DiscountRule extends Component {

    constructor(props){
        super(props);
        this.state= {
            emaildrawerVisible: false,
            subject: '',
            emailBody: '',
            period: '',
            startDate: new Date(),
            endDate: new Date(),
          //  customerId: props.salesInvoiceDetail.customerId,
          //  isBulkInvoice: props.isBulkInvoice || false,
          //  ...props.recurringInvoiceSettings
        }
    }
    componentDidMount() {
       // const companyInfo = this.props.companyInfo || {};
        //  this.props.getSubcourse({relationshipId: companyInfo.relationshipId,pageNumber: 1, pageSize: 1000});
    }

   
    render() {
        return <div>
            <DiscountRuleComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
       // subcourseList: state.school.subcourseList,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getSubcourse, addSubcourse, deleteSubcourse
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DiscountRule);