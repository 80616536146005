import React from "react";
import { injectIntl } from 'react-intl';
import './index.scss';
import {  Tabs } from "antd";
import ReactHtmlParser from 'react-html-parser';
import TablatLogo from "../../../assets/images/tablet-icons.png";
import TablatVLogo from "../../../assets/images/tablet-iconv.png";
const { TabPane } = Tabs;
const RegistrationFormSettingPreview = (props) => {

    return (
        <div className="tab-but-center">
            <Tabs defaultActiveKey="1" centered>
    <TabPane tab={
        <span>
         <img src={TablatLogo} alt="" title="Vertical Layout"/>
          
        </span>
      } key="1">
     <div className="tablate-shapes">
                <div className="tablate-previews">
                {ReactHtmlParser(props.templateContent)}
                </div>
            </div>
    </TabPane>
    <TabPane tab={
        <span>
         <img src={TablatVLogo} alt="" title="Horizontal Layout"/>
          
        </span>
      } key="2">
     <div className="tablatev-shapes">
                <div className="tablatev-previews">
                {ReactHtmlParser(props.templateContent)}
                </div>
            </div>
    </TabPane>
   
  </Tabs>
            
        </div>
    )
};

export default injectIntl(RegistrationFormSettingPreview);
