import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST, COMMON_ACTIONS } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");


export const getSchoolAssignmentResponse = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignmentResponse/getByClassId?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}&classId=${payload.classId || 0}&assignmentId=${payload.assignmentId || 0}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.ASSIGNMENT_RESPONSE_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getSchoolAssignmentResponseCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignmentResponse/countByClassId?relationshipId=${payload.relationshipId}&createdByEmpId=${payload.relationshipEmployeeId}&classId=${payload.classId || 0}&assignmentId=${payload.assignmentId || 0}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.ASSIGNMENT_RESPONSE_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.ASSIGNMENT_RESPONSE_LIST_PAGE_RESET });
  };
};


// export const getStudentByClassId = (payload) => {
//   return (dispatch) => {
//     return axios
//       .get(
//         `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/studentClassRef/getStudentByClassId?relationshipId=${payload.relationshipId}&orgRegistrationId=${payload.orgRegistrationId || 0}&classId=${payload.classId || 0}`
//       )
//       .then((res) => {
//         dispatch({
//           type: SMS_ACTION_LIST.CLASS_STUDENT_LIST,
//           data: res.data,
//         });
//       })
//       .catch((err) => {
//         if (!err.__isRetryRequest) {
//           showToasterMessage({
//             messageType: "error",
//             description:
//               lang[((err.response || {}).data || {}).message] ||
//               "Some error occurred",
//           });
//         }
//       });
//   };
// };

export const deleteAssignedGrade = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/assignmentResponse/delete`, payload
      )
      .then(res => {
        
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
          pageNumber: 1,
          pageSize: 200,
          formId: props.defaultSchoolForm,
          classId: props.classId,
          assignmentId: props.assignmentId
        }
        getSchoolAssignmentResponse(payload)(dispatch);
        getSchoolAssignmentResponseCount(payload)(dispatch);
        showToasterMessage({ messageType: "success", description: lang[(res.data || {}).message] || "Deleted succesfully" });
      })
      .catch(err => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({ messageType: "error", description: lang[((err.response || {}).data || {}).message] || "Some error occurred." });
        } else {
          showToasterMessage({ messageType: "error", description: "Some error occurred." });
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}