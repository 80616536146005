import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const verifyCredentials = (payload, props) => {

  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/login?username=${payload.email}&password=${payload.password}`, {email: payload.email, password: payload.password}
      )
      .then(res => {
        if(res.data.errorRespone){
            showToasterMessage({ messageType: 'error', description: ((res.data || {}).errorRespone || {}).error_description || 'Please enter correct password' })
        }else{
            props.hideModal();
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Please enter correct password' })
      })
  }
}
