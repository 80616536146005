import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CourseTermComp from "../../../components/schoolManagement/courseTerm"
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getCourseTermList, getCourseTermCount, deleteCourseTerm, resetPaginatedData, saveCourseTerm } from "./action";
import { showModal, hideModal } from '../../../actions/commonActions';

class CourseTerm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded("getCourseTermList", "courseTermList", this.props, payload);
    fetchDataIfNeeded("getCourseTermCount", "courseTermCount", this.props, payload);
  }

  render() {
    return (
      <CourseTermComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    courseTermList: state.school.courseTermList,
    courseTermCount: state.school.courseTermCount,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCourseTermList, 
      getCourseTermCount, 
      deleteCourseTerm,
      saveCourseTerm,
      resetPaginatedData,
      showModal,
      hideModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CourseTerm);
