import React, {useState} from 'react';
import { Input } from 'antd';
import './general.scss';
export function LabelBox(props) {
  let className = props.className || 'input-text';
  if (props.value && props.validator && !props.validator(props.value)) {
    className = props.invalidClassName || 'input-text-error text-red';
  }

  let [isFocus, setIsFocus] = useState(false)

  return (
    <div style={{...props.parentStyle, display: 'flex'}}>
      <div className='currency-label' style={{marginRight: '-40px', zIndex: '9', ...props.labelStyle}}>
        {props.labelValue || '-'}
      </div>
      <Input 
        style={{...props.style, paddingLeft: '45px'}}
        placeholder={props.placeholder}
        type={props.type || 'text'}
        maxLength={props.maxLength}
        defaultValue={props.type === 'number' ? (props.value || '') : (props.value || '')}
        className={className}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          props.onBlur && props.onBlur();
          setIsFocus(false);
        }}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
        required={props.required}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        ref={props.ref}
      />
      {isFocus ? <div style={{ right: '20px', top: '44px' ,textAlign: "right", position: 'absolute', ...props.countStyle,}} >{(props.maxLength && (props.value || "").length) ? props.value.length + " / " + props.maxLength + " chars" : ""}</div> : null}
    </div>
  )
}