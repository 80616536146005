import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DeductionDrawer from '../../../../../components/drawer/company/PayrollManagement/Deduction';
import { showModal, hideModal } from '../../../../../actions/commonActions';
import { createDeduction } from '../../../../company/PayrollManagement/Deduction/action';


class DeductionDrawerCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(this.props.deductionData || {})

        };
      }

    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};

    }


    render() {
        return <DeductionDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        drawerLoading: state.common.drawerLoading,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal, 
    createDeduction
  

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeductionDrawerCon);