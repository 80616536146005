import React from "react";
import { Drawer, Row, Col, DatePicker,Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getMomentDateForUI , getCurrentDateForBackend, showToasterMessage } from '../../../../utils';
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { CONSTANTS } from '../../../../static/constants';
import { FilterOutlined, UndoOutlined,} from '@ant-design/icons';



const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;




const PurchaseOrderFilterComp = (props) => {
    const { updateState, companyInfo } = props;

    const closeDrawer = () =>{
        props.updateState({
            purchaseOrderFilterDrawerVisible: false
        })
    }

    const applyFilter = () => {
        // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
        if(!props.selectedSupplierName && !props.poNum && !props.startDate && !props.endDate && !(props.selectedAmount && props.selectedAmountTerm) && !props.selectedUserStatus && !props.selectedStatus && !props.selectedRefNum && !props.selectedProjectMasterId ){

            return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
        }
        const payload = {
            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
            filters: {
                supplierId: props.selectedSupplierId || 0,
                poNumber: props.poNum || null,
                startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                amount: props.selectedAmount || 0,
                amountSymbol: props.selectedAmount ? props.selectedAmountTerm : null,
                userStatus: props.selectedUserStatusName,
                status: props.selectedStatus,
                reference: props.selectedRefNum,
                projectMasterId: props.selectedProjectMasterId,
            }
        };
        props.fetchPurchaseOrdersByFilter(payload);
        props.getPurchaseOrdersCountByFilter(payload);
        closeDrawer();
    }

    const clearFilter = () =>{
        props.updateState({
            selectedSupplierName: null, selectedSupplierId: null,
            poNum: null,
            startDate: null, endDate: null,
            selectedAmount: 0, selectedAmountTerm:null,
            selectedUserStatus: null, selectedUserStatusName: null,
            selectedStatus: null, selectedStatusName: null,
            selectedRefNum: null,
            selectedProjectMasterId: null, selectedProjectName: null
        });
        const payload = {
            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
            filters: {}
        };
        props.fetchPurchaseOrders(payload);
        props.getPurchaseOrdersCount(payload);
    }

    const rowGutter = [24, 16];

    return (
        <>
            <Drawer
                title={
                    // <div className="drawer-head-txt">
                    //     <FormattedMessage id='supplier.purchaseRequest.filter.drawer.title' />
                    // </div>
                    "Filters"
                }
                width={720}
                zIndex={1}
                maskClosable={false}
                destroyOnClose={true}
                onClose={closeDrawer}
                className="custom-drawer"
                visible={props.purchaseOrderFilterDrawerVisible}
                footer={<>
                    <CustomButton
                        intlId='confirmation.cancel'
                        defaultMessage=''
                        type='default'
                        key={0}
                        onClick={closeDrawer}
                      />
                      <div>
                        <CustomButton
                            intlId='common.clearFilter.txt'
                            defaultMessage=''
                            type='default'
                            key={0}
                            onClick={clearFilter}
                            btnIcon={<><UndoOutlined /> &nbsp;</>}
                            style={{marginRight: '10px'}}
                        />
                        <CustomButton
                            intlId='common.applyFilter.txt'
                            defaultMessage=''
                            htmlType='submit'
                            onClick={applyFilter}
                            key={1}
                            btnIcon = {<><FilterOutlined /> &nbsp;</>}
                        />
                      </div>
                      </>}
            >
                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <div className="i-label">PO #</div>
                        <TextBox 
                            // style={{ marginTop: '-0px', width: '140px', marginRight: '5px', float:'left'}}
                            // className='drop-fileds'
                            placeholder='PO #'
                            type='text'
                            maxLength={20}
                            countStyle={{top:"40px"}}
                            value={props.poNum}
                            onChange={(e) => {
                                props.updateState({ poNum: e.target.value });
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <div className="i-label">Order Date</div>
                        <RangePicker
                            // style={{ marginTop: '-0px', width: '300px', marginRight: '5px', float:'left' }}
                            // className='drop-fileds2'
                            allowEmpty={true}
                            format={format}
                            placeholder={['PO Start Date', 'PO End Date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <Row>
                            <Col span={20}>
                            <div className="i-label">Order Amount</div>
                        <TextBox 
                                // style={{ marginTop: '-20px', width: '200px', marginRight: '0px'}}
                                // className='certso'
                                placeholder={props.intl.formatMessage({
                                    id: 'supplier.filter.amount.placeholder', defaultMessage: ''
                                })}
                                type='Number'
                                // maxLength={12}
                                value={props.selectedAmount}
                                onChange={(e) => {
                                    props.updateState({ selectedAmount: e.target.value });
                                }}
                            />
                            </Col>
                            <Col span={4}>
                            <div className="i-label">&nbsp;</div>
                        
                        <Select
                            options={[{ label: '<', value: '<' }, { label: '>', value: '>' }, { label: '=', value: '=' }]}
                            value={props.selectedAmountTerm}
                            placeholder= '='
                            defaultValue= '='
                            onSelect={(value) => {
                                updateState({ selectedAmountTerm: value });
                            }}
                        />

                            </Col>
                        </Row>
                       
                    </Col>
                    <Col span={12} >
                    <div className="i-label"><FormattedMessage id='common.status' /></div>
                        <Dropdown
                            items={props.userStatus || []}
                            valueKeyName='statusName'
                            optionKeyName='poStatusId'
                            statusColorKey='statusColor'
                            
                            value={props.selectedUserStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.status' })}
                            onSelect={(selectedValue, option) => {
                                updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <div className="i-label"><FormattedMessage id='common.systemStatus' /></div>
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' ,float:'left' }}
                            // className='drop-fileds1'
                            items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partially.fulfilled' }, { name: 'Fullfilled', value: 'fulfilled' }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            sortItems={true}
                            value={props.selectedStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                            onSelect={(status, option) => {
                                updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                            }}
                        />

                    </Col>
                    <Col span={12}>
                        <div className="i-label">
                            {/* <FormattedMessage id='customer.filter.priority.placeholder' /> */}
                            Ref #
                            </div>
                        <TextBox 
                            // style={{ marginTop: '-0px', width: '140px', marginRight: '5px',float:'left'}}
                            // className='drop-fileds1'
                            placeholder={props.intl.formatMessage({
                                id: 'supplier.filter.refNum.placeholder', defaultMessage: ''
                            })}
                            type='text'
                            maxLength={20}
                            countStyle={{top:"40px"}}
                            value={props.selectedRefNum}
                            onChange={(e) => {
                                props.updateState({ selectedRefNum: e.target.value });
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    
                    <Col span={12}>
                        <div className="i-label">
                            Projects
                            {/* <FormattedMessage id='supplier.filter.refNum.placeholder' /> */}
                            </div>
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '160px', marginRight: '5px',float:'left' }}
                            // className='drop-fileds1'
                            items={props.projectList}
                            sortItems={true}
                            valueKeyName='projectName'
                            optionKeyName='projectMasterId'
                            value={props.selectedProjectName}
                            placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                            }}
                        />
                    </Col>
                </Row>
                {/* <Row gutter={rowGutter}>
                    <Col span={12}>
                        <div className="i-label"><FormattedMessage id='salesInvoice.project.placeholder' /></div>
                        <Dropdown
                            items={props.projectList}
                            valueKeyName='projectName'
                            optionKeyName='projectMasterId'
                            projectIdKey='projectMasterId'
                            value={props.selectedProjectName}
                            placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                            }}
                        />
                    </Col>
                    <Col span={12}>
                    
                    </Col>
                </Row> */}

            </Drawer>
        </>
    )
};

export default PurchaseOrderFilterComp;
