import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, RFQ_TYPE, RFQ_ACTION_LIST, CONSTANTS_TRANSACTIONS } from '../../../../static/constants';
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils'
import * as find from 'lodash.find';
import { TX_TYPE, TX_STATUS } from "../../../../static/constants";
import { resetPaginatedData, getRFQCount } from '../Listing/action';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createRFQ = (payloadObject) => {
  const props = payloadObject.props;
  const payload = _getRFQData(payloadObject);
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqRest/splitRFQ`, payload)
      .then(res => {
        getRFQCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        showToasterMessage({ messageType: 'success', description: lang['saved.successfully'] });
        // props.history.push('/admin/rfq-list');
        props.popModalFromStack && props.popModalFromStack();
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          userId: (props.userInfo || {}).userId,
          pageNumber: 1,
          pageSize: 25,
          transactionName: CONSTANTS_TRANSACTIONS.RFQ,
        }
        props.fetchAllRFQ(payload);
        props.fetchAllRFQStatus(payload);
        props.getRFQCount(payload); 
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

const _getRFQData = (payload) => {
  const props = payload.props;
  const gridObject = payload.gridObject;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const rfqBiddingSuppliersList = [];
  !props.linkProps.isAllSupplier && props.selectedSuppliers && props.selectedSuppliers.map((supplierId) => {
    const supplier = find(props.suppliers[props.pageNumber || 0], { supplierId: Number(supplierId) }) || {};
    return rfqBiddingSuppliersList.push({
      supplierId: supplier.supplierId,
      relationshipId: companyInfo.relationshipId,
      supplierName: supplier.supplierStoreName
    });

  })
  const financialYear = getFinancialYear(companyInfo);
  let prMaster = {
    version: props.version,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    relationshipName: companyInfo && companyInfo.storeName,
    relationshipId: companyInfo.relationshipId,
    rfqMasterId: props.rfqMasterId,
    rfqId: props.rfqId,
    rfqNumber: props.rfqNumber,
    rfqDate: props.rfqDate && formatDateForBackend(props.rfqDate),
    status: TX_STATUS.OPEN,
    txType: RFQ_TYPE.STANDARD,
    remarksInternal: props.internalRemarks,
    remarksSupplier: props.supplierRemarks,
    footer: props.footer,
    customerRFQNumber: props.customerRFQNumber,
    refNumber: props.refNumber,
    salesPersonId: props.salesPersonId,
    salesPersonFullName: props.salesPersonFullName,
    requestingDepartment: props.departmentName,
    purchaseType: TX_TYPE.PO,
    purchaseRequestNumber: props.prNumber,
    projectNumber: props.projectNumber,
    projectName: props.projectName,
    projectMasterId: props.projectMasterId,
    isPRCconversion: props.isPRCconversion ? 1 : 0,
    orderPriority: props.orderPriority,
    boLocationRFQList: [],
    customerId: (props.customer || {}).customerId,
    customerName: props.customerName,
    rfqExpirationDate: props.rfqExpirationDate && formatDateForBackend(props.rfqExpirationDate),
    rfqBiddingSuppliersList: rfqBiddingSuppliersList,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    createdByUserId: userInfo.userId,
    rfqDetailsList: [],
    rfqChildMasterList: []
  };

  // if (props.relationshipAddress && (props.relationshipAddress.locationId || props.relationshipAddress.locationRFQId)) {
  //   prMaster.boLocationRFQList.push(props.relationshipAddress);
  // }

  // if (props.customerBillingAddress && props.customerBillingAddress.locationId) {
  //   prMaster.boLocationRFQList.push(props.customerBillingAddress);
  // }


  prMaster.boLocationRFQList = props.rfqData.boLocationRFQList;
  prMaster.additionalInfoList = props.rfqData.additionalInfoList;

  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    if(!data.itemId && !data.description && !data.qty && !data.rate){
      return
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    //if (!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
    if(data.productObj && !data.productObj.hasVariant && !variantId){
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId: data.inventoryItemVariantId;
    }
    let product = {};
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }
    prMaster.rfqDetailsList.push({
      version: data.version,
      rfqDetailsId: data.rfqDetailsId,
      rfqMasterId: props.rfqMasterId,
      relationshipId: companyInfo && companyInfo.relationshipId,
      quantity: Number(data.qty),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      txDate: formatDateForBackend(props.invoiceDate),
      itemName: product.itemName,
      itemId: product.itemId,
      description: data.description,
      hsnCode: data.hsnCode,
      itemVariantId: variantId,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3
    });
  });

  props.rFQSplitList.forEach((childData, i) => {
    if (i !== 0) {
      const splitObj = {
        relationshipId: companyInfo && companyInfo.relationshipId,
        rfqChildNumber: props.rfqNumber + "-" + i,
        rfqMasterId: props.rfqMasterId,
        customerName: (props.customer || {}).customerName,
        customerId: (props.customer || {}).customerId,
        rfqDate: props.rfqDate && formatDateForBackend(props.rfqDate),
        status: TX_STATUS.OPEN,
        txType: RFQ_TYPE.STANDARD,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        relationshipName: companyInfo && companyInfo.storeName,
        remarksInternal: props["internalRemarks" + i],
        remarks: props["supplierRemarks" + i],
        footer: props["footer" + i],
        requestingDepartment: props.departmentName,
        purchaseType: TX_TYPE.PO,
        projectNumber: props.projectNumber,
        projectName: props.projectName,
        projectMasterId: props.projectMasterId,
        createdByUserId: userInfo.userId,
        createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
        rfqExpirationDate: props["rfqexpirationDate" + i] && formatDateForBackend(props["rfqexpirationDate" + i]),
        //rfqExpirationDate: props.rfqExpirationDate && formatDateForBackend(props.rfqExpirationDate),
        orderPriority: props["childOrderPriority" + i],
        rfqBiddingSuppliersList: [],
        rfqChildDetailList: []
      }

      props["selectedSuppliers" + i] && props["selectedSuppliers" + i].map((supplierId) => {
        const supplier = find(props.suppliers[props.pageNumber || 0], { supplierId: Number(supplierId) }) || {};
        return splitObj.rfqBiddingSuppliersList.push({
          supplierId: supplier.supplierId,
          relationshipId: companyInfo.relationshipId,
          supplierName: supplier.supplierStoreName
        });
      });

      childData.forEach((child, childIndex) => {
        const itemId = child.selectedProductValue && child.selectedProductValue[0];
        //if (!itemId) return;
        const variantId = child.selectedProductValue && child.selectedProductValue.length === 2 ? child.selectedProductValue[1] : 0;
        let product = {};
        if (itemId) {
          product = find(props.products, { itemId: Number(itemId) }) || {};
        }
        let  variant = {};
        if (variantId) {
          variant = child.variant || {};
        }

        splitObj.rfqChildDetailList.push({
          relationshipId: companyInfo && companyInfo.relationshipId,
          rfqDetailsId: child.rfqDetailsId,
          rfqMasterId: props.rfqMasterId,
          quantity: Number(child.qty),
          sNo: childIndex + 1,
          sku: product.sku,
          uomName: child.uom,
          txDate: formatDateForBackend(props.invoiceDate),
          itemName: product.itemName || child.product,
          itemId: product.itemId || itemId,
          description: child.description,
          hsnCode: child.hsnCode,
          itemVariantId: variantId,
          attributeId1: variant.attributeId1,
          attributeId2: variant.attributeId2,
          attributeId3: variant.attributeId3,
          attributeName1: variant.attributeName1,
          attributeName2: variant.attributeName2,
          attributeName3: variant.attributeName3,
          attributeValue1: variant.attributeValue1,
          attributeValue2: variant.attributeValue2,
          attributeValue3: variant.attributeValue3
        })
      });

      prMaster.rfqChildMasterList.push(splitObj);
    }
  });

  return prMaster;
}


export const fetchRfqData = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/rfqRest/getRFQsByRFQId?rfqMasterId=${payload.rfqMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: RFQ_ACTION_LIST.RFQ_DETAIL, data });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_DETAIL_LOADER });
      })
  }
}


export const resetRfqData = (payload) => {
  return dispatch => {
    dispatch({ type: RFQ_ACTION_LIST.RESET_RFQ_DETAIL });
  }
}