  import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDataIfNeeded } from '../../../utils';
import ProjectAddComp from '../../../components/drawer/Project';
import { pushModalToStack,popModalFromStack } from './../../../actions/commonActions';
import { showModal, hideModal } from './../../../actions/commonActions';
import { downloadFileFromBucket } from './../../../actions/downloadFileAction';
import { createOrUpdateProject } from '../../modal/modalBody/common/Project/action';
import { getAllProject } from '../../company/Project/action';

class ProjectAddDrawer extends Component {
  constructor(props) {
    super(props);
    const needToinit = Object.keys(props.projectData || {})?.length;
    const  data = needToinit ? props.projectData : {}
    this.state = {
      projectDrawerVisible: props.projectDrawerVisible, 
      projectName:needToinit?data.projectName:"",
      projectDescription:needToinit?data.projectDescription:"",
      projectMasterId:needToinit?data.projectMasterId:null,
    }
  }
  

  componentDidMount() {
    // const companyInfo = this.props.companyInfo || {};
    // const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.customerId, soMasterId: this.props.soMasterId };
  }

  render() {
    return <div>
       <ProjectAddComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal, 
  hideModal,
  downloadFileFromBucket,
  pushModalToStack,popModalFromStack,
  createOrUpdateProject,
  getAllProject
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddDrawer);
