import { RFQ_ACTION_LIST } from '../static/constants';
const initialState = {
  rfqList: {
    1: []
  },
  rfqDraftList: {
    1: []
  },
  rfqCount: 0,
  rfqDraftCount: 0,
  rfqData: {},
  rfqDetail: {},
  rfqStatusList: [],
  rfqPriceCodes: [],
  nextRFQNumber: ''
}

const RFQReducer = (state = initialState, action) => {
  switch (action.type) {
    case RFQ_ACTION_LIST.RFQ_LIST:
      return {
        ...state,
        rfqList: {
          ...state.rfqList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case RFQ_ACTION_LIST.RFQ_DRAFT_LIST:
      return {
        ...state,
        rfqDraftList: {
          ...state.rfqDraftList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case RFQ_ACTION_LIST.RFQ_DRAFT_COUNT:
      return { ...state, rfqDraftCount: action.data };
    case RFQ_ACTION_LIST.RFQ_COUNT:
      return { ...state, rfqCount: action.data };
    case RFQ_ACTION_LIST.RFQ_LIST_PAGE_RESET:
      return { ...state, rfqList: initialState.rfqList };
    case RFQ_ACTION_LIST.RFQ_DETAIL:
      return { ...state, rfqDetail: action.data };
    case RFQ_ACTION_LIST.RFQ_Data:
      return { ...state, rfqData: action.data };
    case RFQ_ACTION_LIST.RESET_RFQ_DETAIL:
      return { ...state, rfqDetail: initialState.rfqDetail, rfqData: initialState.rfqData };
    case RFQ_ACTION_LIST.RFQ_STATUS_LIST:
      return { ...state, rfqStatusList: action.data };
    case RFQ_ACTION_LIST.RFQ_STATUS_LIST_ADDITION:
      return { ...state, rfqStatusList: [action.data, ...state.rfqStatusList] }
    case RFQ_ACTION_LIST.RFQ_STATUS_LIST_DELETION:
      return { ...state, rfqStatusList: state.rfqStatusList.filter(x => x.rfqStatusId !== action.data.id) }
    case RFQ_ACTION_LIST.RFQ_PRICE_CODES:
      return { ...state, rfqPriceCodes: action.data };
    case RFQ_ACTION_LIST.RFQ_PRICE_CODES_ADDITION:
      return { ...state, rfqPriceCodes: [action.data, ...state.rfqPriceCodes] }
    case RFQ_ACTION_LIST.RFQ_PRICE_CODES_DELETION:
      return { ...state, rfqPriceCodes: state.rfqPriceCodes.filter(x => x.rfqBiddingSupplierPriceCodeId !== action.data.id) }
    case RFQ_ACTION_LIST.NEXT_RFQ_NUMBER:
      return { ...state, nextRFQNumber: action.data };
    case RFQ_ACTION_LIST.NEXT_RFQ_NUMBER_RESET:
      return { ...state, nextRFQNumber: '' };
    default:
      return state;
  }
};

export default RFQReducer;