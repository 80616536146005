export const AG_GRID_CONSTANTS = {
    EVENT_SOURCE: {
        API: "api"
    },
    STANDARD_SALES_INVOICE_FIELD: {
        TYPE_BILLING_ADDRESS: 'BillingAddress',
        TYPE_SHIPPING_ADDRESS: 'ShippingAddress',
        TYPE_BILLING_ADDRESS_FIELD: 'billingAddress',
        TYPE_SHIPPING_ADDRESS_FIELD: 'shippingAddress',
        CUSTOMER_PO_FIELD: 'customerPO',
        PLACE_OF_SUPPLY_FIELD: 'placeOfSupply',
        PAYMENT_TERM_FIELD: 'paymentTerms',
        SALES_PERSON: 'salesPerson',
        PRIORITY: 'priority',
        SOURCE: 'source',
        REFERENCE_NUMBER: 'referenceNumber',
        PRICE_TYPE_LIST: ['Apply Retail Price', 'Apply Wholesale Price'],
        PROJECT: 'project',
        DELIVERY_METHOD: 'deliveryMethod',
        PROGRAM_NAME: 'program_name',
    },
    COMMON: {
        PAYMENT_TERM_FIELD: 'paymentTerms',
        PLACE_OF_SUPPLY_FIELD: 'placeOfSupply',
        PRODUCT_FIELD: 'product',
        DESCRIPTION_FIELD: 'description',
        QTY_FIELD: 'qty',
        UOM_FIELD: 'uom',
        RATE_FIELD: 'rate',
        DISCOUNT_FIELD: 'discount',
        SPL_DISCOUNT_FIELD: 'specialDiscount',
        TAX_FIELD: 'tax',
        AMOUNT_FIELD: 'amount',
        AMOUNT_FIELD_WITHOUTTAX: 'taxableAmount',
        HSN: 'HSN',
        COMMENT_FIELD: 'comment',
        STOCK_NUMBER_FIELD: 'stockNumber',
        PART_NUMBER_FIELD: 'partNumber',
        MATERIAL_NUMBER_FIELD: 'materialNumber',
        ORIGIN_FIELD: 'origin',
        HS_CODE_FIELD: 'hsCode',
        STUDENT: 'student',
        DISCOUNT_AMOUNT_FIELD: 'discountAmount',
    }
}
export const GRID_PREFERENCE_CONSTANTS = {
    PRODUCT: 'product',
    QUANTITY: 'quantity',
    QUANTITY_FROM_PO: 'qtyFromPO',
    QUANTITY_RECEIVED: 'qtyReceived',
    RATE: 'rate',
    BASE_PRICE: 'basePrice',
    BASE_RATE: 'baseRate',
    AMOUNT: 'amount',
    AMOUNT_WITHOUT_TAX: 'taxableAmount',
    DESCRIPTION: 'description',
    SPECIAL_DISCOUNT: 'specialDiscount',
    UOM: 'uomId',
    TAX: 'tax',
    DISCOUNT: 'discount',
    DISCOUNT_TYPE: 'discountType',
    DISCOUNT_AMOUNT_FIELD: 'discountAmount',
    HSN: 'hsn',
    COMMENT: 'comment',
    STOCK_NUMBER: 'stockNumber',
    PART_NUMBER: 'partNumber',
    MATERIAL_NUMBER: 'materialNumber',
    ORIGIN: 'origin',
    HS_CODE: 'hsCode',
    STUDENT: 'student',
    PAYMENT_MODE: 'paymentMode',
    TRANSACTION_ID: 'transactionId',
    PAYMENT_SOURCE: 'paymentSource',
    PRODUCT_IMAGE: 'productImage',
    LEDGER_ACCOUNT: 'ledgerAccount'
}