import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer } from 'antd';
import 'material-ui-rc-color-picker/assets/index.css';
import ButtonProps from './ButtonProps';
import ImageProps from './ImageProps';
import TextProps from './TextProps';
import SocialLinksProps from './SocialLinksProps';
import ProgressBarProps from './ProgressBarProps';
import LogoProps from './LogoProps';
import LinkProps from './LinkProps';
import SectionProps from './section';
import EventProps from './EventProps';
import DividerProps from './divider';
import FooterProps from './FooterProps';


function NewsLetterPropsUpdater(props) {

    const closeDrawer = () => {
        props.updateState({
            isNewsLetterPropsUpdaterVisible: false
        })
    }

    return (
        <div  className='top-space-drawer'>
        <Fragment >

            <Drawer
                title={
                    props.title
                }
                width={330}
                placement="left"
                zIndex={1}
                maskClosable={false}
                destroyOnClose={true}
                onClose={closeDrawer}
                className="newdrawes"
                visible={props.isNewsLetterPropsUpdaterVisible}
            >
                <h2>Customize {props.elementType}</h2>
                {props.elementType === 'Button' &&
                    <ButtonProps {...props}></ButtonProps>
                }
                {props.elementType === 'Logo' &&
                    <LogoProps {...props}></LogoProps>
                }
                {props.elementType === 'Image' &&
                    <ImageProps {...props}></ImageProps>
                }
                {props.elementType === 'Text' &&
                    <TextProps {...props}></TextProps>
                }
                {props.elementType === 'Social Links' &&
                    <SocialLinksProps  {...props}></SocialLinksProps>
                }
                 {props.elementType === 'Progress Bar' &&
                    <ProgressBarProps  {...props}></ProgressBarProps>
                }
                {props.elementType === 'Link' &&
                    <LinkProps  {...props}></LinkProps>
                }
                {props.elementType === 'Section' &&
                    <SectionProps  {...props}></SectionProps>
                }
                {props.elementType === 'Event' &&
                    <EventProps  {...props}></EventProps>
                }
                {props.elementType === 'Divider' &&
                    <DividerProps  {...props}></DividerProps>
                }
                 {props.elementType === 'Footer' &&
                <FooterProps  {...props}></FooterProps>
            }
                <br />
                {/* 
                        <CustomButton className='ant-btn-primary'
                            onClick={() => {
                                closeDrawer()
                            }}
                            defaultMessage=''
                            type='default'
                            htmlType='submit'
                            intlId='common.cancel'
                        /> */}

            </Drawer>
        </Fragment>
        </div>
    );
}


export default injectIntl(NewsLetterPropsUpdater);
