import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesEInvoice from '../../../../../components/modal/modalBody/customer/SalesE_Invoice';
import { LEDGER_TYPE } from '../../../../../static/constants';
import { getEInvoiceDetail } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { fetchSalesInvoiceDetail } from '../SalesInvoiceDetail/action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { getAvailableBalance } from '../SalesInvoiceDetail/action';
import { generatePDF ,printPDF } from '../../../../../actions/downloadFileAction';
import find from 'lodash.find';

class EInvoiceContainer extends Component {
  
  constructor(props){
    super(props);
    this.state= {
        
        subject: '',
        emailBody: '',
    }
}
  componentWillMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.invoicePayload.customerId, invoiceMasterId: this.props.invoicePayload.invoiceMasterId };
    fetchDataIfNeeded('fetchSalesInvoiceDetail', 'salesInvoiceDetail', this.props, payload, true);
    this.props.getEInvoiceDetail({irn: this.props.data?.irn ,relationshipId: companyInfo.relationshipId})
    // this.props.getAvailableBalance(payload);
    // this.props.fetchAllLedgerAccounts(payload);
}
componentWillReceiveProps(props) {
    if (props.allLedgerAccounts && props.allLedgerAccounts.length && props.salesInvoiceDetail && props.salesInvoiceDetail.invoiceMasterId) {
        let selectedLedgers = {};

        props.salesInvoiceDetail.customerInvoiceCOATxList.map((ledgerObj) => {
            const ledgerObj2 = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(ledgerObj.ledgerAccountId) }) || {};
            if (ledgerObj.txType === LEDGER_TYPE.TYPE_SALES && ledgerObj.accountingEntry === "credit") {
                selectedLedgers.salesLedgerId = ledgerObj2.chartOfAccountDetailsId;
                selectedLedgers.salesLedgerName = ledgerObj2.ledgerAccountName;
            }
            else if (ledgerObj.txType === LEDGER_TYPE.TYPE_SALES && ledgerObj.accountingEntry === "debit") {
                selectedLedgers.salesDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                selectedLedgers.salesDebtorLedgerName = ledgerObj2.ledgerAccountName;
            }
            else if (ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE && ledgerObj.accountingEntry === "credit") {
                selectedLedgers.expenseLedgerId = ledgerObj2.chartOfAccountDetailsId;
                selectedLedgers.expenseLedgerName = ledgerObj2.ledgerAccountName;
            }
            else if (ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE && ledgerObj.accountingEntry === "debit") {
                selectedLedgers.expenseDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                selectedLedgers.expenseDebtorLedgerName = ledgerObj2.ledgerAccountName;
            }
            else if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "credit") {
                selectedLedgers.discountDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                selectedLedgers.discountDebtorLedgerName = ledgerObj2.ledgerAccountName;
            }
            else if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "debit") {
                selectedLedgers.salesDiscountLedgerId = ledgerObj2.chartOfAccountDetailsId;
                selectedLedgers.salesDiscountLedger = ledgerObj2.ledgerAccountName;
            }
            return '';
        });

        props.salesInvoiceDetail.customerInvoiceDetailsList.map((ele) => {
            ele.customerInvoiceCOATxList.map((ledgerObj) => {
                const ledgerObj2 = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(ledgerObj.ledgerAccountId) }) || {};
                if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "credit") {
                    selectedLedgers.discountDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.discountDebtorLedgerName = ledgerObj2.ledgerAccountName;
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "debit") {
                    selectedLedgers.salesDiscountLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.salesDiscountLedgerName = ledgerObj2.ledgerAccountName;
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_TAX && ledgerObj.accountingEntry === "credit") {
                    selectedLedgers.salesOutputTaxLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.salesOutputTaxLedgerName = ledgerObj2.ledgerAccountName;
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_TAX && ledgerObj.accountingEntry === "debit") {
                    selectedLedgers.taxDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.taxDebtorLedgerName = ledgerObj2.ledgerAccountName;
                }
                return '';
            });
            return '';
        });
        this.setState({ selectedLedgers});
       
    }
}

  render() {
    return (
      <SalesEInvoice {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    userStatus: state.common.userStatus,
    salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,
    EInvoiceDetails: state.salesInvoice.salesEInvoiceDetailList
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSalesInvoiceDetail,
  pushModalToStack,
  popModalFromStack,
  showModal,
  printPDF,
  generatePDF,
  getEInvoiceDetail,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EInvoiceContainer);
