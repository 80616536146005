import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { injectIntl } from 'react-intl';

const HeaderAction = (props) => {

    return (
        <div className="left-itms">
            <ul>
                <li className="title">
                    Split RFQ
                </li>
            </ul>
      </div>
    );
};

export default injectIntl(HeaderAction);
