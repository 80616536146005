import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import "./index.scss";
import {
  Button,
} from "antd";
import { TextBox } from "../../general/TextBox";
import {
  validateEmail,
} from "../../../utils";
import { ICONS } from "../../../static/constants";
import { ErrorMsg } from "../../general/ErrorMessage";
import FlashImg from "../../../assets/images/form1.png";
import OtpInput from "react-otp-input";


const OtpPopup = (props) => {

  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(59);
  const [otpSent, setOtpSent] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0 && otpSent) {
        setSeconds(seconds - 1);
      } else if (seconds === 0) {
        setOtpSent(false);
        setSeconds(59);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [seconds, otpSent]);

  const handleSubmitForOtp = () => {

    props.updateState({ submittedEmail: true });

    if (!validateEmail(props.primaryEmail)) {
      return;
    }
    props.sendOtpToUser({
      email: props.primaryEmail,
      relationshipId: props.companyInfo.relationshipId || props.relationshipId,
    });
  };

  return (
      <div className="top-layer">
        <div className="info-container">
          <div className="left">
            <img
              src={FlashImg}
              alt="Form Register"
              height="120px"
              width="120px"
            />
          </div>

          <div className="right">
            <div className="header">
              Welcome to the {props.companyInfo?.storeName || "School"}
            </div>
            <div className="body">
              {!props?.otpSentRes?.isOtpSent ? (
                <>
                  <div className="f-label">Enter Email</div>

                  <TextBox
                    placeholder={"Enter Email"}
                    disabled={props.saveLoading}
                    type="text"
                    value={props.primaryEmail}
                    onChange={(e) => {
                      props.updateState({ primaryEmail: e.target.value });
                    }}
                    onPressEnter={() => {
                      handleSubmitForOtp();
                    }}
                    autoFocus={true}
                  />
                  <ErrorMsg
                    validator={() => {
                      return (
                        !props.submittedEmail ||
                        validateEmail(props.primaryEmail)
                      );
                    }}
                    message={"Please enter valid Email"}
                  />

                  <Button
                    loading={props.saveLoading}
                    type="primary"
                    onClick={() => {
                      handleSubmitForOtp();
                    }}
                  >
                    <span style={{fontSize: '14px', letterSpacing: '1px'}}>Continue</span>
                  </Button>
                </>
              ) : (
                <>
                  <div className="otp-label">
                    Please enter the 6 digit OTP that we just sent on <br />
                    {props.primaryEmail || "username@alpide.com"}{" "}
                    <i
                      className={`${ICONS.EDIT_P} cursor-pointer`}
                      onClick={() => {
                        props.updateState({ otpSentRes: {} });
                      }}
                    />
                  </div>

                  <OtpInput
                    value={otp}
                    placeholder={"------"}
                    isInputNum={true}
                    numInputs={6}
                    shouldAutoFocus={true}
                    // renderSeparator={<span> </span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={`otp-input`}
                    onChange={(val) => {
                      setOtp(val);
                      // api call for otp verication when otp digit is 6
                      if (val.length === 6) {
                        props.verifyOtp(
                          {
                            email: props.primaryEmail,
                            relationshipId: props.companyInfo.relationshipId,
                            otp: val,
                          },
                          props
                        );
                        props.updateState({ submittedOTP: true });
                      }
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submittedOTP || (otp || "").length === 6;
                    }}
                    message={"Please enter valid OTP"}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                    }}
                  >
                    <div className="mt10">
                      <span
                        className={`${
                          !otpSent ? "cursor-pointer" : "light-txt"
                        }`}
                        onClick={() => {
                          props.sendOtpToUser({
                            email: props.primaryEmail,
                            relationshipId: props.companyInfo.relationshipId,
                          });
                          setOtpSent(true);
                          setOtp("");
                          // call for resend api
                        }}
                      >
                        Resend OTP
                      </span>
                      {otpSent ? (
                        <span
                          className={`light-txt ${otpSent ? "wf" : "w0"}`}
                        >
                          &nbsp;in {seconds} seconds
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    {
                      // <div className="light-txt mt10">
                      //     Resend OTP in {seconds} seconds
                      // </div>
                      // :
                      // <div
                      //     className={`mt10 ${!otpSent ? 'cursor-pointer' : 'light-txt'}`}
                      //     onClick={()=>{
                      //         setOtpSent(true);
                      //         setOtp('');
                      //         // call for resend api
                      //     }}
                      // >
                      //     Resend OTP
                      // </div>
                    }
                  </div>

                  <Button
                    type="primary"
                    loading={props.saveLoading}
                    className="mt20"
                    onClick={() => {
                      if (otp.length === 6) {
                        // api call for otp verication
                        props.verifyOtp(
                          {
                            email: props.primaryEmail,
                            relationshipId: props.companyInfo.relationshipId,
                            otp: otp,
                          },
                          props
                        );
                      }
                      props.updateState({ submittedOTP: true });
                    }}
                  >
                    <span style={{fontSize: '14px', letterSpacing: '1px'}}>Submit</span>
                  </Button>
                </>
              )}

              <div>
                We are looking forward to support your child's academic
                journey!
              </div>

              <div>
                Best Regards,
                <br />
                <b>Team - {props.companyInfo.storeName || ""}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default injectIntl(OtpPopup);
