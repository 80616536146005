import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST } from '../../static/constants';
import { showToasterMessage } from '../../utils';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchRelationshipDataByRID = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/getRelationshipByRID?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SETTING_ACTION_LIST.RELATIONSHIP_DATA, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
        }
      })
  }
}

export const getInvoiceDetail = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getInvoiceByWebInvoiceId?webInvoiceId=${payload.webInvoiceId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: COMMON_ACTIONS.WEB_INVOICE_DATA, data });
      })
      .catch(err => {
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const getPayOnlineUrl = (payload) => {
  let baseUrl = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/`;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PAYMENT_GATEWAY_BASE_URL}${config.rootContextPaymentGateway}/paymentGateway/getPaymentUrl?relationshipId=${payload.relationshipId}`, {...payload, baseUrl })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        window.open(res.data);
        dispatch({ type: COMMON_ACTIONS.PAY_ONLINE_URL, data: res.data });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const getAuthNetPaymentUrl = (payload, recData) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getAuthNetPaymentUrl?relationshipId=${payload.relationshipId}&amount=${payload.invoiceTotalAmount}&invoiceId=${payload.invoiceMasterId}`)
      .then(res => {
        let paymentUrl = res.data+`&callback_uri=${window.location.origin}/app/invoice?webInvoiceId=${payload.webInvoiceId}&invoice_id=${payload.invoiceMasterId}&project_name=${(payload.projectName? payload.projectName+ '_' : '') + payload.customerName+'-' +(payload.invoiceNumber|| '')}&formId=${payload.registrationFormSettingId}`
        if(recData){
          paymentUrl = paymentUrl+`&invoiceNumber=${'CUSTID-'+ payload.customerId}&subscription_name=${recData.subscriptionName}&intervalLength=${recData.intervalLength}&unit=${recData.unit}&start_date=${recData.startDate}&totalOccurrences=${recData.totalOccurrences}`
        }else{
          paymentUrl = paymentUrl+`&invoiceNumber=${payload.invoiceNumber}`
        }
        window.location = (paymentUrl);
        //window.open(res.data);
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}


export const processCCPayment = (props, recData={}) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextCC}/payment/processPayment`,recData)
      .then(res => {
        const { data } = res;
        props.updateState({
          paymentSucceeded: true,
          paymentSuccessDetails: {
            status: res.status || 'success',
            txnId: data,
          }
        });
        props.fetchPaymentDetail && props.fetchPaymentDetail({
          relationshipId: props.companyInfo?.relationshipId,
          customerId: props.customerId || props.userData?.customerId || 0,
          customerPaymentId: data
        })
        props.popModalFromStack();
        props.updateOrderState && props.updateOrderState({
          paymentSucceeded: true,
          status: 'success',
            txnId: data,
        });
      })
      .catch((err = {}) => {

        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}
export const getRecurringInvoiceDataByFormId = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextInvoiceService}/recurringInvoice/getRecurringInvoiceDataByFormId?relationshipId=${payload.relationshipId}&customerId=${payload.customerId}&formId=${payload.formId}&module=${payload.module}`)
      .then(res => {
        const { data } = res
        dispatch({ type: COMMON_ACTIONS.RECURRING_INVOICE_DATA, data });
      })
      .catch(err => {
       // showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}