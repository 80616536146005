import React, { Fragment, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Skeleton, Pagination, Input, Popconfirm, Menu, Table, Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  checkACLPermission,
  showToasterMessage,
  sortTable,
} from "../../../../../utils";
import {
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  TRANSACTION_TYPES,
  MODAL_TYPE,
  ICONS,
} from "../../../../../static/constants";
import PageBreadcrumb from "../../../../PageBreadcrumb";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  PlusSquareOutlined,
  MinusSquareOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";

import "./index.scss";
import cloneDeep from "lodash/cloneDeep";
import * as debounce from "lodash.debounce";
import CategoryDetailsModel from "../../../../../containers/modal/modalBody/product/CategoryDetailsModel";
const { Search } = Input;

const EditableContext = React.createContext();

// class EditableCell extends React.Component {
//     getInput = () => {
//         if (this.props.inputType === 'number') {
//             return <Input type="number" />;
//         }
//         return <Input />;
//     };

//     renderCell = ({ getFieldDecorator }) => {
//         const {
//             editing,
//             dataIndex,
//             title,
//             inputType,
//             record,
//             index,
//             children,
//             ...restProps
//         } = this.props;
//         return (
//             <td {...restProps}>
//                 {editing ? (
//                     <Form.Item style={{ margin: 0 }}>
//                         {getFieldDecorator(dataIndex, {
//                             rules: [{ required: false }],
//                             initialValue: record[dataIndex],
//                             valuePropName: 'value',
//                         })(this.getInput())}
//                     </Form.Item>
//                 ) : (
//                         children
//                     )}
//             </td>
//         );
//     };

//     render() {
//         return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
//     }
// }

const onSearchedInputChange = debounce((searchedText, props) => {
  handleSearchedText(searchedText, props);
}, 500);

const handleSearchedText = (searchedText, props) => {
  const payload = {
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: 1,
    pageSize: props.pageSize,
    searchedText: searchedText,
  };
  if (searchedText.length) {
    if (
      searchedText.length >= 3 &&
      !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())
    ) {
      props.resetPaginatedData();
      props.getProductCount(payload);
      props.fetchProducts(payload);
    }
  } else {
    props.resetPaginatedData();
    props.getProductCount(payload);
    props.fetchProducts(payload);
  }
};

const StockAdjustmentListingComp = (props) => {
  const {
    permissions,
    updateState,
    searchedText,
    companyInfo,
    warehouseMasterId,
    dir = 0,
  } = props;
  const [childOnList, setChildOn] = useState([]);
  const getStockDetail = (data, keyName, type) => {
    let sum = data[keyName] || 0;
    let variantCount = 0;
    (data.inventoryItemVariantList || []).forEach(function (ele) {
      if (ele[keyName]) {
        variantCount = variantCount + 1;
        sum = sum + parseInt(ele[keyName] || 0);
      }
    });

    if (type === "sum") return sum;
    if (type === "detail") {
      let variantVal = variantCount > 1 ? "variants" : "variant";
      return "From " + variantCount + " " + variantVal;
    }
  };

  const getVariantName = (data) => {
    return (
      <div>
        {data.attributeValue1 || ""}{" "}
        {data.attributeValue2 ? " | " + data.attributeValue2 : ""}{" "}
        {data.attributeValue3 ? " | " + data.attributeValue3 : ""}
      </div>
    );
  };

  const _getDataList = (dataList) => {
    dataList &&
      dataList.forEach((dt, dtIndex) => {
        dt.currentStock = getStockDetail(dt, "currentStock", "sum");
        dt.itemNameWithVariant = dt.itemName;
        dt.salesCommitted = getStockDetail(dt, "salesCommitted", "sum");
        dt.qtyAvailable = dt.currentStock - dt.salesCommitted;
        dt.qtyOnPO = getStockDetail(dt, "onOrder", "sum");

        if (
          dt.hasVariant &&
          dt.inventoryItemVariantList &&
          dt.inventoryItemVariantList.length
        )
          dt.children = dt.inventoryItemVariantList;

        dt.children &&
          dt.children.forEach((dt2, dt2Index) => {
            dt2.itemName = getVariantName(dt2);
            dt2.itemNameWithVariant =
              dt.itemName +
              "(" +
              (dt2.attributeValue1 || "") +
              (dt2.attributeValue2 ? " | " + dt2.attributeValue2 : "") +
              (dt2.attributeValue3 ? " | " + dt2.attributeValue3 : "") +
              ")";
            dt2.key = dtIndex + "" + dt2Index;
            dt2.qtyAvailable = dt2.currentStock - dt2.salesCommitted;
            dt2.qtyOnPO = dt2.onOrder;
          });

        if (
          !dt.hasVariant &&
          dt.inventoryItemVariantList &&
          dt.inventoryItemVariantList.length
        ) {
          dt.variantId = dt.inventoryItemVariantList[0].variantId;
          dt.isQtyUpdatePendingApproval =
            dt.inventoryItemVariantList[0].isQtyUpdatePendingApproval;
          dt.stockUpdateApproverEmpId =
            dt.inventoryItemVariantList[0].stockUpdateApproverEmpId;
          dt.purchasedPrice = dt.inventoryItemVariantList[0].purchasedPrice;
        }
      });

    return dataList || [];
  };

  let products = _getDataList(cloneDeep(props.products[props.pageNumber]));
  products = products.map((contactObj, index) => {
    contactObj.key = index;
    return contactObj;
  });
  const productCount = props.productCount;

  const getActionPermissionData = () => {
    const primaryPerm = permissions.primary || [];
    const permittedAction = {
      update: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
        PERMISSION_VALUES.UPDATE
      ),
      create: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
        PERMISSION_VALUES.CREATE
      ),
    };
    Object.keys(permittedAction).forEach((operation) => {
      if (permittedAction[operation] === false) {
        delete permittedAction[operation];
      }
    });
    return permittedAction;
  };

  const permittedAction = getActionPermissionData();
  const columnDefs = [
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.product"
          defaultMessage=""
        />
      ),
      dataIndex: "itemName",
      key: "itemName",
      width: 300,
      className: "leftAlignCell",
    },
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.sku"
          defaultMessage=""
        />
      ),
      dataIndex: "sku",
      key: "sku",
      width: 150,
      className: "leftAlignCell",
    },
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.category"
          defaultMessage=""
        />
      ),
      dataIndex: "categoryName",
      key: "categoryName",
      width: 150,
      className: "leftAlignCell",
    },
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.currentStock"
          defaultMessage=""
        />
      ),
      dataIndex: "currentStock",
      key: "currentStock",
      editable: true,
      className: "editable-cell",
    },
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.qtyOnSO"
          defaultMessage=""
        />
      ),
      dataIndex: "salesCommitted",
      key: "salesCommitted",
      editable: true,
      className: "editable-cell",
    },
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.qtyAvailable"
          defaultMessage=""
        />
      ),
      dataIndex: "qtyAvailable",
      key: "qtyAvailable",
      width: 125,
    },
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.qtyOnPO"
          defaultMessage=""
        />
      ),
      dataIndex: "qtyOnPO",
      key: "qtyOnPO",
      editable: true,
      className: "editable-cell",
    },
  ];

  if (permittedAction.update) {
    columnDefs.push({
      title: "",
      render: (text, record) => {
        const { editingKey } = props;
        const editable = isEditing(record);
        return editable ? (
          <span className="table-cell-action">
            <EditableContext.Consumer>
              {(form) => (
                <Link
                  onClick={() => save(form, record)}
                  style={{ marginRight: 8 }}
                >
                  <i className="fa fa-save" />
                </Link>
              )}
            </EditableContext.Consumer>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => cancel(record.key)}
            >
              <Link>
                <i className="fa fa-ban" />
              </Link>
            </Popconfirm>
          </span>
        ) : record.hasVariant ? (
          <div></div>
        ) : (
          <div className="table-cell-action">
            {record.isQtyUpdatePendingApproval ? (
              <div>
                {(props.userInfo || {}).relationshipEmployeeId ===
                record.stockUpdateApproverEmpId ? (
                  <div>
                    <Link
                      disabled={editingKey !== ""}
                      onClick={() => {
                        approve(record);
                      }}
                    >
                      <i title="Approved" className="fa fa-thumbs-up" />
                    </Link>
                    <Link
                      disabled={editingKey !== ""}
                      onClick={() => {
                        handleDelegateApprover(record);
                      }}
                    >
                      <i title="Delegate Approver" className="fa fa-share" />
                    </Link>
                    <Link
                      disabled={editingKey !== ""}
                      onClick={() => {
                        handleRejectApproval(record);
                      }}
                    >
                      <i
                        title="Reject Approval"
                        className="fa fa-thumbs-down"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <Link
                disabled={editingKey !== ""}
                onClick={() => {
                  edit(record.key !== undefined ? record.key : record.sNo);
                }}
              >
                <i className="fa fa-edit" />
              </Link>
            )}
          </div>
        );
      },
      width: 120,
    });
  }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "l",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      warehouseMasterId: warehouseMasterId,
      isDetails: true,
    };
    fetchPaginationDataIfNeeded("fetchProducts", "products", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      warehouseMasterId: warehouseMasterId,
      isDetails: true,
    };
    props.resetPaginatedData();
    props.fetchProducts(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };

  const agGridStyle = {
      height: '100%',
      width: '100%'
  };

  const isEditing = (record) => record.key === props.editingKey;

  const cancel = () => {
    updateState({ editingKey: "" });
  };

  const save = (form, data) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      updateProduct(props, data, row, cancel);
    });
  };

  const edit = (key) => {
    updateState({ editingKey: key });
  };

  const approve = (record) => {
    const modalBody = "Are you sure you want to approve?";
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.approveQtyChange(
          {
            relationshipId: record.relationshipId,
            variantId: record.variantId,
            itemId: record.itemId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
          },
          props
        );
      },
    };
    props.showModal(modalData);
  };

  const handleDelegateApprover = (record) => {
    const formData = {
      relationshipId: record.relationshipId,
      variantId: record.variantId,
      itemId: record.itemId,
      approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
      txType: TRANSACTION_TYPES.STOCK_CHANGE,
    };
    const { showModal } = props;
    const data = {
      title: "Delegate Approver",
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.DELEGATE_APPROVER,
        data: formData,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
        props.popModalFromStack();
      },
    };
    showModal(data);
  };

  const handleRejectApproval = (record) => {
    const modalBody = "Are you sure you want to reject?";
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.rejectQtyChange(
          {
            relationshipId: record.relationshipId,
            variantId: record.variantId,
            itemId: record.itemId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
          },
          props
        );
      },
    };
    props.showModal(modalData);
  };

  // const components = {
  //     body: {
  //         cell: EditableCell,
  //     },
  // };

  // const columns = columnDefs.map((col, index) => {
  //     col.key = index;
  //     if (!col.editable) {
  //         return col;
  //     }
  //     let inputType;
  //     if (col.dataIndex === 'currentStock' || col.dataIndex === 'salesCommitted' || col.dataIndex === 'qtyOnPO') {
  //         inputType = 'number'
  //     } else {
  //         inputType = 'text';
  //     }
  //     return {
  //         ...col,
  //         onCell: record => {
  //             const cellProps = {
  //                 record,
  //                 inputType,
  //                 dataIndex: col.dataIndex,
  //                 title: col.title,
  //                 editing: isEditing(record),
  //             }
  //             return cellProps;
  //         },
  //     };
  // });

  const breadCrumbList = [

    {
      name: <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.stockAdjustment" defaultMessage="" />
    },
  ];

  const sortColumn = (e) => {
    sortTable(
      document.getElementById("stock-adjustment-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };
  const isChildOn = (id) => {
    if (childOnList.includes(id)) {
      return true;
    }
    return false;
  };

  const setChildOff = (data) => {
    const itemIndex = childOnList.indexOf(data);
    const newArr = childOnList.splice(itemIndex, 1);
  };

  const actionMenu = (e,childData) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            handleEditing(props, e,childData);
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>

       { (props.userInfo || {}).relationshipEmployeeId ===
                e.stockUpdateApproverEmpId ? (
                  <div>

                  <Menu.Item
                    key="1"
                    value="edit"
                    onClick={() => {
                      approve(e);
                    }}
                  >
                    <i className={ICONS.EDIT} />
                    Approved
                  </Menu.Item>
                    {/* <Link
                      disabled={editingKey !== ""}
                      onClick={() => {
                        approve(record);
                      }}
                    >
                      <i title="Approved" className="fa fa-thumbs-up" />
                    </Link> */}
                    <Menu.Item
                    key="1"
                    value="edit"
                    onClick={() => {
                      handleDelegateApprover(e);
                    }}
                  >
                    <i className={ICONS.EDIT} />
                    Delegate Approver
                  </Menu.Item>
                    {/* <Link
                      disabled={editingKey !== ""}
                      onClick={() => {
                        handleDelegateApprover(record);
                      }}
                    >
                      <i title="Delegate Approver" className="fa fa-share" />
                    </Link> */}
                    <Menu.Item
                      key="1"
                      value="edit"
                      onClick={() => {
                        handleRejectApproval(e);
                      }}
                    >
                      <i className={ICONS.EDIT} />
                      Reject Approval
                    </Menu.Item>
                    {/* <Link
                      disabled={editingKey !== ""}
                      onClick={() => {
                        handleRejectApproval(record);
                      }}
                    >
                      <i
                        title="Reject Approval"
                        className="fa fa-thumbs-down"
                      />
                    </Link> */}
                  </div>
                ):""}
      </Menu>
    );
  };
  
  const handleEditing = (props, payload,childData) => {
    const { showModal, companyInfo } = props;
    const formData = payload || {};
    formData.relationshipId = companyInfo.relationshipId;
   props.updateState({
    stockAdjustmentDrawerVisible:true,
     data:payload,
     childData:childData
  })

  // : props.updateState({
  //   stockAdjustmentDrawerVisible:true,
  //   data: { ...payload },
  // })}
    // const data = {
    //   title: <FormattedMessage id="addItem.text.newTax" defaultMessage="" />,
    //   formData,
    //   hideFooter: true,
    //   modalData: {
    //     modalType: MODAL_TYPE.NEW_TAX,
    //     data: { formData: formData },
    //   },
    //   handleSubmit: (formData = {}) => {
    //     data.formData.submittedOnce = true;
    //     showModal(data);
    //   },
    // };
    // showModal(data);
  };
  const openClassDetailPopup = (item) => {
    const modalData = {
      title: <FormattedMessage id='inventory.invValuation.listing.model.title' defaultMessage='' />,
      modalBody: <CategoryDetailsModel {...props} CategoryObj={item} />,
      width: '50%',
      hideFooter: true,
    };
    props.pushModalToStack(modalData);
  }
  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="heading.inventry.stockAdjustment"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div>
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="inventory.stockAdjustment.table.heading"
                defaultMessage=""
              />
            </div>
            {props.productCount ? (
              <>
                <div className="vertical-sep" />
                <div>{productCount}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">
            <Search
              placeholder="Enter 3 Characters of SKUID or Product"
              value={searchedText}
              onChange={(e) => {
                updateState({ searchedText: e.target.value, pageNumber: 1 });
                onSearchedInputChange(e.target.value, props);
              }}
              onSearch={(searchedText) => {
                updateState({ searchedText, pageNumber: 1 });
                onSearchedInputChange(searchedText, props);
                handleSearchedText(searchedText, props);
              }}
              className="search-col"
            />
          </div>
        </div>
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 12 }}>
          <div className="table-container">
            <table className="stock-adjustment-table" id="stock-adjustment-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th onClick={sortColumn} className="skuId">
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.sku"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn} className="productName">
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.product"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                
                  <th onClick={sortColumn}  className="text-center">
                    <FormattedMessage
                      id="inventory.warehouse.stockAdjustment.listing.header.category"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn} className="text-center" >
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.currentStock"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn} className="text-center" >
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.qtyOnSO"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn} className="text-center" >
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.qtyOnPO"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn} className="text-center" >
                    <FormattedMessage
                      id="inventory.warehouse.stockSummary.listing.header.qtyAvailable"
                      defaultMessage=""
                    /> <i className={ICONS.SORT} />
                  </th>
                 
                  <th >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(products || []).length ?
                  products
                    .sort((a, b) => (a.itemId < b.itemId ? 1 : -1))
                    .map((rowData, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              <span>
                                {Number(
                                  (props.pageNumber - 1) * props.pageSize +
                                    index +
                                    1
                                )}
                              </span>{" "}
                              &nbsp;
                              {rowData.hasVariant &&
                              rowData.inventoryItemVariantList ? (
                                isChildOn(rowData.itemId) ? (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setChildOff(rowData.itemId);
                                    }}
                                  >
                                    <MinusSquareOutlined />
                                  </span>
                                ) : (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setChildOn([
                                        ...childOnList,
                                        rowData.itemId,
                                      ]);
                                    }}
                                  >
                                    <PlusSquareOutlined />
                                  </span>
                                )
                              ) : (
                                ""
                              )}
                            </td>
                            <td>{rowData.sku} </td>
                            <td>{rowData.itemName} </td>
                           
                            <td  className="text-center link"  ><span onClick={() => { openClassDetailPopup(rowData) }}>{rowData.categoryCount || 0}</span></td>
                            <td className="text-center" > {rowData.currentStock}</td>
                            <td className="text-center" > {rowData.salesCommitted}</td>
                            <td className="text-center" > {rowData.qtyOnPO} </td>
                            <td className="text-center" >{rowData.qtyAvailable} </td>
                           
                            <td  style={{ paddingLeft: "50px" }}>
                             {!(rowData.hasVariant) && <DropdownAction
                                overlay={actionMenu(rowData)}
                                trigger={["click"]}
                              >
                                <i className={ICONS.MORE} />
                              </DropdownAction>}
                            </td>
                          </tr>
                          {
                          (rowData.children || []).length &&
                          isChildOn(rowData.itemId)
                            ? rowData.children.map((childRowData, i) => {
                                return (
                                  <tr key={childRowData.rfqChildMasterId}>
                                    <td>
                                      <span className="child-sr">
                                        {index + 1 + "." + Number(i + 1)}
                                      </span>{" "}
                                      &nbsp;
                                    </td>
                                    <td>{childRowData.sku} </td>
                                    <td>
                                      {childRowData.attributeValue1 +
                                        " |" +
                                        childRowData.attributeValue2}{" "}
                                    </td>
                                  
                                    <td>{childRowData.categoryName} </td>
                                    <td> {childRowData.currentStock}</td>
                                    <td> {childRowData.salesCommitted}</td>
                                    <td> {childRowData.qtyOnPO} </td>
                                    <td>{childRowData.qtyAvailable} </td>
                                  
                                    <td style={{ paddingLeft: "50px" }}>
                                      <DropdownAction
                                        overlay={actionMenu(rowData,childRowData)}
                                        trigger={["click"]}
                                      >
                                        <i className={ICONS.MORE} />
                                      </DropdownAction>
                                    </td>
                                  </tr>
                                );
                              })
                            : ""}
                        </>
                      );
                    })
                  :
                  <tr key="empty-data-box">
                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                      <Empty />
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>

        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={productCount}
              current={props.pageNumber || 1}
              showTotal={showTotal}
              defaultPageSize={props.pageSize || 25}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
// const updateProduct = (props, data, record, cancelEditing) => {
//     let product = props.products[props.pageNumber].find(item => (item.itemId === data.itemId)) || {};
//     let variantList = [];
//     if (product.hasVariant) {
//         variantList = product.inventoryItemVariantList.map((variant, index) => {
//             if (variant.variantId === data.variantId) {
//                 variant.currentStock = Number(record.currentStock || 0);
//                 variant.salesCommitted = Number(record.salesCommitted || 0);
//                 variant.onOrder = Number(record.qtyOnPO || 0);
//             }
//             return variant;
//         });
//     }
//     else {
//         let variant = product.inventoryItemVariantList[0] || {};
//         variant.currentStock = Number(record.currentStock || 0);
//         variant.salesCommitted = Number(record.salesCommitted || 0);
//         variant.onOrder = Number(record.qtyOnPO || 0);
//         variantList.push(variant);
//     }
//     product.inventoryItemVariantList = variantList || [];

//     const modalData = {
//         modalBody: <FormattedMessage
//             id='updateProduct'
//             defaultMessage=''
//         />,
//         handleSubmit: () => {
//             props.updateInventoryItem(product);
//             props.hideModal();
//             cancelEditing();
//         }
//     };
//     props.showModal(modalData);
// }
const updateProduct = (props, data, record, cancelEditing) => {
  if (!data.purchasedPrice && Number(record.currentStock || 0) > 0) {
    cancelEditing();
    return showToasterMessage({
      description:
        "Current stock can'nt be changed because purchase price not available for this product",
    });
  }
  let product =
    props.products[props.pageNumber].find(
      (item) => item.itemId === data.itemId
    ) || {};
  let payload = {
    currentStock: Number(record.currentStock || 0),
    salesCommitted: Number(record.salesCommitted || 0),
    onOrder: Number(record.qtyOnPO || 0),
    itemId: data.itemId,
    updatedByEmpId: ((props.companyInfo || {}).relationshipEmployees || {}).relationshipEmployeeId,
    itemName: data.itemNameWithVariant,
    relationshipId: data.relationshipId,
  };
  if (data.variantId) {
    payload.variantId = data.variantId;
  } else {
    let variant = product.inventoryItemVariantList[0] || {};
    payload.variantId = variant.variantId;
  }

  const modalData = {
    modalBody: <FormattedMessage id="save.confirmation" defaultMessage="" />,
    handleSubmit: () => {
      props.updateInventoryItem(payload, props);
      props.hideModal();
      cancelEditing();
    },
  };
  props.showModal(modalData);
};
export default Form.create()(injectIntl(StockAdjustmentListingComp));
