import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CustomAgGrid } from '../../../../../general/CustomAgGrid';
import { showToasterMessage, getMomentDateForUIReadOnly, fixedDecimalNumber } from '../../../../../../utils';
import { Dropdown } from '../../../../../general/Dropdown';
import { CONSTANTS } from '../../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../../static/cssClassConstants';
import * as find from 'lodash.find';
import ReactHtmlParser from 'react-html-parser';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class XeroInvoicePopup extends Component {
    render() {

        const getTxTypeData = (row, type, toReturn) => {
            let coatTxListName = this.props.type === "Supplier" ? 'supplierInvoiceCOATxList' : 'customerInvoiceCOATxList';
            if (!row || !row[coatTxListName]) return;
            let data = row[coatTxListName].find(tx => (tx.txType === type));
            if (data) {
                if (data[toReturn] && toReturn === 'amountPercent') {
                    if (type === 'VAT') {
                        return "VAT(" + data[toReturn] + "%)";
                    }
                    return "(" + data[toReturn] + "%)";
                } else if (data[toReturn] && toReturn === 'amount') {
                    return fixedDecimalNumber(data[toReturn]);
                } else {
                    return ""
                }

            } else if (!data && toReturn === 'amount') {
                return "";
            }
            else {
                return "-";
            }
        }
        const getTotalTaxAmount = (row) => {
            let tax = getTxTypeData(row, 'tax', 'amount', true) || 0;
            let totalTax = Number(tax);
            return totalTax;

        }

        const getTotalTaxPercent = (row, toReturn) => {
            let coatTxListName = this.props.type === "Supplier" ? 'supplierInvoiceCOATxList' : 'customerInvoiceCOATxList';
            let tax1 = row[coatTxListName].find(tx => (tx.txType === 'tax')) || {};
            let totalPercent = Number(tax1.amountPercent || 0);
            if (toReturn === 'amountPercent') {
                return totalPercent ? ('(' + totalPercent + "%)") : '';
            }
            else {
                return totalPercent;
            }
        }

        const getNetAmount = (row) => {
            let tax = getTotalTaxAmount(row) || 0;
            let discount = getTxTypeData(row, 'discount', 'amount', true) || 0;
            let netAmountItem = 0 ;
            if(this.props.type === "Supplier"){
                netAmountItem = (Number(row.quantity * row.itemPurchasedPrice) + Number(tax) - Number(discount));
            }else{
                netAmountItem = (Number(row.quantityToInvoice * row.anItemSalePrice) + Number(tax) - Number(discount));
            }
            return fixedDecimalNumber(netAmountItem);

        }
        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.product' defaultMessage='' />;
                },
                field: "itemName",
                resizable: true,
                width: 200,
                cellClass: "cell-wrap-text",
                cellRendererFramework: (link) => {
                    return (link.data.itemName || link.data.description) ? <span>
                        <strong>{link.data.itemName}</strong>
                        {link.data.description && <span className="description-cell">
                            <pre>{ReactHtmlParser(link.data.description || '-')}</pre>
                        </span>}
                    </span> : "-"
                },
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.rate' defaultMessage='' />;
                },
                field: this.props.type === "Supplier" ? "itemPurchasedPrice" : "anItemSalePrice",
                cellRendererFramework: (link) => {
                    return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : ((this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "))}  {link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
                },
                resizable: true,
                width: 130,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesInvoice.form.gridHeader.qty' defaultMessage='' />;
                },
                field: this.props.type === "Supplier" ? "quantity" : "quantityToInvoice",
                resizable: true,
                width: 80,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },

            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.common.discount' defaultMessage='' />;
                },
                field: "discountAmt",
                cellRendererFramework: (link) => {
                    return !isTxTypeEmpty(link.data, 'discount') ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : ((this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "))}
                        {getTxTypeData(link.data, 'discount', 'amount')} {getTxTypeData(link.data, 'discount', 'amountPercent')}
                    </span> : '-'
                },
                resizable: true,
                width: 130,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.common.tax' defaultMessage='' />;
                },
                field: "taxAmount",
                cellRendererFramework: (link) => {
                    return  !isTxTypeEmpty(link.data, 'tax') ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : ((this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "))}
                        {getTotalTaxAmount(link.data)} {getTotalTaxPercent(link.data, 'amountPercent')}
                    </span> : '-'
                },
                resizable: true,
                width: 130,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <span><FormattedMessage id='xeroTax.text' defaultMessage='' /><span className="required">*</span></span>;
                },
                field: "xeroTaxType",
                editable: true,
                resizable: true,
                cellEditor: 'customDropDownEditor',
                colId: "xeroTaxType_1",
                cellRendererFramework: (params) => {
                    return <span>{params.data.xeroTaxName}</span>
                  },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.xeroTaxList,
                        optionKeyName: 'Name',
                        valueKeyName: 'Name',
                        addressLineKey1: 'TaxType',
                        value:obj.data.xeroTaxName,
                        onSelect: (selectedValue, option) => {
                            const selectedXeroTax = find((this.props.xeroTaxList || []), { Name: option.name }) || {};
                            obj.data.xeroTaxName = selectedXeroTax.Name;
                            obj.data.xeroTaxType = selectedXeroTax.TaxType;
                            setTimeout(function(){obj.api.redrawRows();}, 200);
                        },
                    }
                },
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.common.netAmount' defaultMessage='' />;
                },
                field: "totalAmount",
                cellRendererFramework: (link) => {
                    return  <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : ((this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "))}
                        {getNetAmount(link.data)}
                    </span> 
                },
                resizable: true,
                width: 175,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
           

            // {
            //     headerComponentFramework: (params) => {
            //         return <span>
            //         {this.props.invoiceListForUpload.length > 1 ? <i className="fa fa-trash" onClick={(e) => {
            //             const deleteIndex = (((params.agGridReact || {}).gridOptions || {}).rowData).index;
            //             const invoiceListForUpload = this.props.invoiceListForUpload.filter((ele, i) =>{
            //                 return i !== deleteIndex;
            //             });

            //             this.props.updateState({
            //                 invoiceListForUpload
            //             });
            //             this["gridApi0"].refreshHeader({
            //                 force: true
            //             });
            //         }} /> : ""}
            //     </span>},
            //     field: "delete",
            //     resizable: true,
            //     width: 80
            // }
        ];

        const isTxTypeEmpty = (row, type) => {
            if (!row) return true;
            let coatTxListName = this.props.type === "Supplier" ? 'supplierInvoiceCOATxList' : 'customerInvoiceCOATxList';
            let data = row[coatTxListName].find(tx => (tx.txType === type));
            if (data && data.amount) {
              return false;
            }
            else {
              return true;
            }
          }

        const uploadDateToXero = () => {
            const invoiceList = [];
            let isXeroCustomerMissing = false;
            let isXeroAccountMissing = false;
            let isXeroTaxMissing = false;
            // let isDescription = false;
            let self = this;
            // this.props.invoiceListForUpload.map((rowData, i) => {
            //     if (!rowData.xeroContactId) {
            //         isXeroCustomerMissing = true;
            //     }
            //     if (!rowData.xeroLedgerId) {
            //         isXeroAccountMissing = true;
            //     }
                
            // });

            for (let i = 0; i < (this.props.invoiceListForUpload || []).length; i++) {
                const rowData = this.props.invoiceListForUpload[i];
                
                if (!rowData.xeroContactId) {
                    isXeroCustomerMissing = true;
                }
                if (!rowData.xeroLedgerId) {
                    isXeroAccountMissing = true;
                }
            }
            if (isXeroCustomerMissing) {
                return showToasterMessage({ messageType: "error", description: "Xero contact is required" })
            }
            if (isXeroAccountMissing) {
                return showToasterMessage({ messageType: "error", description: "Xero Account is required" })
            }
            this.props.invoiceListForUpload.map((rootData, i) => {
                const invoiceData = []
                this["gridApi" + i].forEachNode((rowNode) => {
                    const { data } = rowNode;
                    if (!data.description) {
                        // isDescription = true;
                    } else if (!isTxTypeEmpty(data, 'tax') && !data.xeroTaxName) {
                        isXeroTaxMissing = true
                    }
                    else {
                        data.description = `${data.itemName || ''}${(data.itemName && data.description) ? ' ' : ''}${data.description || ''}`
                        if (self.props.type === "Supplier") {
                            data.invoiceCOATxList = data.supplierInvoiceCOATxList;
                        }else{
                            data.invoiceCOATxList = data.customerInvoiceCOATxList;
                        }
                        invoiceData.push(data);
                    }
                });
                rootData.invoiceDetailsList = invoiceData;
                if (self.props.type === "Supplier") {
                    rootData.invoiceCOATxList = rootData.supplierInvoiceCOATxList;
                }else{
                    rootData.invoiceCOATxList = rootData.customerInvoiceCOATxList;
                }
                return invoiceList.push(rootData);
            });
            // if (isDescription) {
            //     return showToasterMessage({ messageType: "error", description: "Description is required" })
            // }
            if (isXeroTaxMissing) {
                return showToasterMessage({ messageType: "error", description: "Xero tax is required" })
            }

            if (this.props.type === "Supplier") {
                this.props.postPurchaseInvoiceToXero({
                    relationshipId: (this.props.companyInfo || {}).relationshipId,
                    invoiceList
                }, this.props);
            } else {
                this.props.postSalesInvoiceToXero({
                    relationshipId: (this.props.companyInfo || {}).relationshipId,
                    invoiceList
                }, this.props);
            }
        }
        return (
            <div>
                <div className='popup-height'>
                    <Fragment>
                        <div className="modal-table-container ">
                            {this.props.type === 'Supplier' ? this.props.invoiceListForUpload.map((invoice, i) => {

                                invoice.supplierInvoiceDetailsList = invoice.supplierInvoiceDetailsList || [];
                                invoice.supplierInvoiceDetailsList.index = i;

                                return <div key={i}>
                                    {/* <Row className="mb15">
                                        <Col span={3} className="text-right">
                                            <FormattedMessage id='modal.customer.payInvoice.invoiceNumber' defaultMessage='' />
                                        </Col>
                                        <Col span={3}>
                                            {invoice.invoiceNumber}
                                        </Col>
                                    </Row>

                                    <Row className="mb15">
                                        <Col span={3} className="text-right">
                                            <FormattedMessage id='modal.customer.payInvoice.customerName' defaultMessage='' />
                                        </Col>
                                        <Col span={3}>
                                            {invoice.customerName}
                                        </Col>
                                    </Row> */}
                                    <table className="mb15" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th><FormattedMessage id='modal.customer.payInvoice.invoiceNumber' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='common.invoiceDate' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='amount.text' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='common.status' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='modal.txDetails.payment.supplierName' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='xeroCustomer.text' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='xeroLedger.text' defaultMessage='' /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{invoice.invoiceNumber}</td>
                                                    <td>{<Tooltip placement="topLeft" title={(invoice.invoiceDate && getMomentDateForUIReadOnly({ date: invoice.invoiceDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                        {invoice.invoiceDate && getMomentDateForUIReadOnly({ date: new Date(invoice.invoiceDate), format })}
                                                    </Tooltip>}</td>
                                                    <td>{Number(invoice.invoiceTotalAmount || 0).toFixed(2)}</td>
                                                    <td><FormattedMessage id={invoice.status} defaultMessage={invoice.status} /></td>
                                                    <td>{invoice.supplierName}</td>
                                                    <td>
                                                        <Dropdown
                                                            items={this.props.xeroContactList || []}
                                                            valueKeyName='Name'
                                                            addressLineKey1='EmailAddress'
                                                            optionKeyName='ContactID'
                                                            value={invoice.xeroContactId}
                                                            placeholder={this.props.intl.formatMessage({ id: 'xeroCustomer.text' })}
                                                            onSelect={(ContactID, option) => {
                                                                invoice.xeroContactId = ContactID;
                                                                let invoiceListForUpload = this.props.invoiceListForUpload;
                                                                invoiceListForUpload[i] = invoice;
                                                                this.props.updateState({
                                                                    invoiceListForUpload: invoiceListForUpload
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <Dropdown
                                                            items={this.props.xeroLedgerList || []}
                                                            valueKeyName='Name'
                                                            addressLineKey1='Code'
                                                            addressLineKey2='Type'
                                                            addressLineKey3='Class'
                                                            optionKeyName='AccountID'
                                                            value={invoice.xeroLedgerId}
                                                            placeholder={this.props.intl.formatMessage({ id: 'xeroLedger.text' })}
                                                            onSelect={(AccountID, option) => {
                                                                const selectedAccount = find((this.props.xeroLedgerList || []), { AccountID: AccountID });
                                                                invoice.xeroAccountCode = selectedAccount.Code;
                                                                invoice.xeroAccountId = AccountID;
                                                                invoice.xeroLedgerId = AccountID;
                                                                let invoiceListForUpload = this.props.invoiceListForUpload;
                                                                invoiceListForUpload[i] = invoice;
                                                                this.props.updateState({
                                                                    invoiceListForUpload: invoiceListForUpload
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    <CustomAgGrid
                                        key={i}
                                        columnDefs={columnDefs}
                                        rowData={invoice.supplierInvoiceDetailsList || [{}]}
                                        onGridReady={(params) => {
                                            this["gridApi" + i] = params.api;
                                            this["gridColumnApi" + i] = params.columnApi;
                                        }}
                                        gridStyle={{
                                            width: '100%',
                                            height: '100%',
                                            marginBottom: '10px'
                                        }} />
                                </div>
                            }) :
                                this.props.invoiceListForUpload.map((invoice, i) => {
                                    return <div className="mb30" key={'inv'+i}>
                                        {/* <Row className="mb15">
                                        <Col span={3} className="text-right">
                                    <FormattedMessage id='modal.customer.payInvoice.invoiceNumber' defaultMessage='' />
                                        </Col>
                                        <Col span={3}>
                                            {invoice.invoiceNumber}
                                        </Col>
                                    </Row>

                                    <Row className="mb15">
                                        <Col span={3} className="text-right">
                                        <FormattedMessage id='modal.customer.payInvoice.customerName' defaultMessage='' />
                                        </Col>
                                        <Col span={3}>
                                            {invoice.customerName}
                                        </Col>
                                    </Row> */}
                                        <table className="mb15" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th><FormattedMessage id='modal.customer.payInvoice.invoiceNumber' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='common.invoiceDate' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='amount.text' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='common.status' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='alpideCustomer.text' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='xeroCustomer.text' defaultMessage='' /></th>
                                                    <th> <FormattedMessage id='xeroLedger.text' defaultMessage='' /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{invoice.invoiceNumber}</td>
                                                    <td>{<Tooltip placement="topLeft" title={(invoice.invoiceDate && getMomentDateForUIReadOnly({ date: invoice.invoiceDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                        {invoice.invoiceDate && getMomentDateForUIReadOnly({ date: new Date(invoice.invoiceDate), format })}
                                                    </Tooltip>}</td>
                                                    <td>{Number(invoice.invoiceTotalAmount || 0).toFixed(2)}</td>
                                                    <td><FormattedMessage id={invoice.status} defaultMessage={invoice.status} /></td>
                                                    <td>{invoice.customerName}</td>
                                                    <td>
                                                        <Dropdown
                                                            items={this.props.xeroContactList || []}
                                                            valueKeyName='Name'
                                                            addressLineKey1='EmailAddress'
                                                            optionKeyName='ContactID'
                                                            value={invoice.xeroContactId}
                                                            placeholder={this.props.intl.formatMessage({ id: 'xeroCustomer.text' })}
                                                            onSelect={(ContactID, option) => {
                                                                invoice.xeroContactId = ContactID;
                                                                let invoiceListForUpload = this.props.invoiceListForUpload;
                                                                invoiceListForUpload[i] = invoice;
                                                                this.props.updateState({
                                                                    invoiceListForUpload: invoiceListForUpload
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <Dropdown
                                                            items={this.props.xeroLedgerList || []}
                                                            valueKeyName='Name'
                                                            addressLineKey1='Code'
                                                            addressLineKey2='Type'
                                                            addressLineKey3='Class'
                                                            optionKeyName='AccountID'
                                                            value={invoice.xeroLedgerId}
                                                            placeholder={this.props.intl.formatMessage({ id: 'xeroLedger.text' })}
                                                            onSelect={(AccountID, option) => {
                                                                const selectedAccount = find((this.props.xeroLedgerList || []), { AccountID: AccountID });
                                                                invoice.xeroAccountCode = selectedAccount.Code;
                                                                invoice.xeroAccountId = AccountID;
                                                                invoice.xeroLedgerId = AccountID;
                                                                let invoiceListForUpload = this.props.invoiceListForUpload;
                                                                invoiceListForUpload[i] = invoice;
                                                                this.props.updateState({
                                                                    invoiceListForUpload: invoiceListForUpload
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <CustomAgGrid
                                            key={i}
                                            columnDefs={columnDefs}
                                            rowData={invoice.customerInvoiceDetailsList || [{}]}
                                            onGridReady={(params) => {
                                                this["gridApi" + i] = params.api;
                                                this["gridColumnApi" + i] = params.columnApi;
                                            }}
                                            gridStyle={{
                                                width: '100%',
                                                height: '100%',
                                                marginBottom: '10px'
                                            }} />
                                    </div>
                                })}
                        </div>
                        {/* <div className="">
                        <Row type="flex" className="action-button-group">
                            <Col span={3}>
                                <Button className="ant-btn-primary" onClick={() => {
                                    uploadDateToXero();
                                }}><FormattedMessage id='button.upload.label' defaultMessage='' /></Button>
                            </Col>

                            <Col span={2} offset={19}>
                                <Button className="grey-button" onClick={() => {
                                    this.props.hideModal();
                                }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                            </Col>
                        </Row>
                    </div> */}
                    </Fragment>
                    <div style={{ clear: 'both' }}></div>

                </div>
                <div className="ant-modal-footer">
                    <Row type="flex" className="action-button-group">
                        <Col span={3}>
                            <Button className="ant-btn-primary" onClick={() => {
                                uploadDateToXero();
                            }}><FormattedMessage id='drawer.inventory.image.productImg.upload' defaultMessage='' /></Button>
                        </Col>

                        <Col span={2} offset={19}>
                            <Button className="grey-button" onClick={() => {
                                this.props.hideModal();
                            }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default XeroInvoicePopup;
