import React from "react";
import { Drawer, Row, Col, DatePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getMomentDateForUI , getCurrentDateForBackend, showToasterMessage } from '../../../../utils';
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { CONSTANTS } from '../../../../static/constants';
import { FilterOutlined, UndoOutlined,} from '@ant-design/icons';



const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;



const SalesInquiryFilterComp = (props) => {

    const { updateState, companyInfo } = props;

    const closeDrawer = () =>{
        props.updateState({
            salesInquiryFilterDrawerVisible: false
        })
    }

    const applyFilter = () => {
        // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
            if(!props.selectedCustomerId && !props.inqNum && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatusName && !props.selectedStatus && !props.selectedPriority && !props.refNum && !props.selectedSalesPersonId && !props.selectedProjectMasterId ){
                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
            }
            const payload = {
                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                filters: {
                    customerId: props.selectedCustomerId,
                    inquiryNumber: props.inqNum,
                    startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                    endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                    expStartDate: props.expStartDate ? getCurrentDateForBackend(props.expStartDate) : null,
                    expEndDate: props.expEndDate ? getCurrentDateForBackend(props.expEndDate) : null,
                    userStatus: props.selectedUserStatusName,
                    status: props.selectedStatus,
                    priority: props.selectedPriority,
                    reference: props.refNum,
                    salesPersonId: props.selectedSalesPersonId,
                    salesPersonName: props.selectedSalesPersonName,
                    projectMasterId: props.selectedProjectMasterId,
                }
            };
            props.fetchSalesEnquiryByFilter(payload);
            props.getSalesEnquiryCountByFilter(payload);
            props.updateState({
                salesInquiryFilterDrawerVisible: false
            })
    }

    const clearFilter = () =>{
        props.updateState({
            selectedCustomerId: null, selectedCustomerName: null,
            inqNum: null, 
            startDate:null,endDate:null,
            expStartDate:null, expEndDate:null,
            selectedUserStatusName: null, selectedUserStatus: null,
            selectedStatus:null, selectedStatusName: null,
            selectedPriority: null,
            refNum:null,
            selectedSalesPersonId: null, selectedSalesPersonName:null, 
            selectedProjectName:null, selectedProjectMasterId: null 
        });
        const payload = {
            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
            filters: {}
        };
        props.fetchSalesEnquiry(payload);
        props.fetchSalesEnquiryCount(payload);
    }

    const rowGutter = [24, 16];

    return (
        <>
            <Drawer
                title={
                    <div className="drawer-head-txt">
                    Filters
                    </div>
                }
                width={720}
                zIndex={1}
                maskClosable={false}
                destroyOnClose={true}
                onClose={closeDrawer}
                className="custom-drawer"
                visible={props.salesInquiryFilterDrawerVisible}
                footer={<>
                    <CustomButton
                        intlId='confirmation.cancel'
                        defaultMessage=''
                        type='default'
                        key={0}
                        onClick={closeDrawer}
                      />
                      <div>
                        <CustomButton
                            intlId='common.clearFilter.txt'
                            defaultMessage=''
                            type='default'
                            key={0}
                            onClick={clearFilter}
                            btnIcon={<><UndoOutlined /> &nbsp;</>}
                            style={{marginRight: '10px'}}
                        />
                        <CustomButton
                            intlId='common.applyFilter.txt'
                            defaultMessage=''
                            htmlType='submit'
                            onClick={applyFilter}
                            key={1}
                            btnIcon = {<><FilterOutlined /> &nbsp;</>}
                        />
                      </div>
                      </>}
            >
                <Row gutter={rowGutter}>
                <Col span={12}>
                        <div className="i-label">Inquiry #</div>
                        <TextBox 
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                                className=''
                                placeholder='Inquiry #'
                                type='text'
                                countStyle={{ top: "43px"}}
                                maxLength={15}
                                value={props.inqNum}
                                onChange={(e) => {
                                    props.updateState({ inqNum: e.target.value });
                                }}
                            />
                    </Col>
                    <Col span={12}>
                        <div className="i-label">Customer</div>
                        <Dropdown
                                // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}
                                
                                items={(props.customers[props.pageNumber] || []).sort((a, b) => (a.companyName||'').localeCompare(b.companyName||''))}
                                valueKeyName='companyName'
                                optionKeyName='customerId'
                                value={props.selectedCustomerName}
                                onSearch={(searchedText) => {
                                    props.fetchCustomers({
                                        isCompact: true,
                                        searchedText: searchedText,
                                        pageNumber: 1, 
                                        hideSpinner: true,
                                        pageSize: 100,
                                        relationshipId: (props.companyInfo || {}).relationshipId
                                    })
                                }}
                                placeholder={props.intl.formatMessage({
                                    id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                                })}

                                onSelect={
                                    (customerId, option) => {
                                        updateState({
                                            selectedCustomerId: option.value,
                                            selectedCustomerName: option.name,
                                        })
                                    }
                                }
                                allowClear={true}
                                onClear={() => {
                                    updateState({
                                        selectedCustomerId: undefined,
                                        selectedCustomerName: undefined,
                                    })
                                }}
                            />

                    </Col>
                 
                </Row>
                <Row gutter={rowGutter}>
                    
                       
                            <Col span={12}>
                            <div className="i-label">Inquiry Date</div>
                            <RangePicker
                                // style={{ marginTop: '-20px', width: '300px', marginRight: '5px' }}
                                className='2'
                                allowEmpty={true}
                                format={format}
                                placeholder={['Inquiry Start Date', 'Inquiry End Date']}
                                value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                                onChange={(dates) => {
                                    if (dates) {
                                        props.updateState({ startDate: dates[0], endDate: dates[1] });
                                    } else {
                                        props.updateState({ startDate: null, endDate: null });
                                    }
                                }}
                            />
                            </Col>
                            <Col span={12}>
                            <div className="i-label">Expiration Date</div>
                        
                            <RangePicker
                                // style={{ marginTop: '-20px', width: '300px', marginRight: '5px' }}
                                className='2'
                                allowEmpty={true}
                                format={format}
                                placeholder={['Exp Start Date', 'Exp End Date']}
                                value={[props.expStartDate ? getMomentDateForUI({ date: new Date(props.expStartDate), format }) : null, props.expEndDate ? getMomentDateForUI({ date: new Date(props.expEndDate), format }) : null]}
                                onChange={(dates) => {
                                    if (dates) {
                                        props.updateState({ expStartDate: dates[0], expEndDate: dates[1] });
                                    } else {
                                        props.updateState({ expStartDate: null, expEndDate: null });
                                    }
                                }}
                            />

                            </Col>
                       

                    
                </Row>
                <Row gutter={rowGutter}>
                    {/* <Col span={12} >
                    <div className="i-label"><FormattedMessage id='common.status' /></div>
                    <Dropdown
                                items={props.userStatus || []}
                                valueKeyName='statusName'
                                optionKeyName='purchaseInvoiceStatusId'
                                statusColorKey='statusColor'
                                className=''
                                // style={{ marginTop: '-25px', width: '140px', marginRight: '5px' }}
                                value={props.selectedUserStatusName}
                                placeholder={props.intl.formatMessage({ id: 'common.status' })}
                                onSelect={(selectedValue, option) => {
                                    updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                                }}
                            />
                    </Col> */}
              
                    {/* <Col span={12}>
                        <div className="i-label"><FormattedMessage id='common.systemStatus' /></div>
                        <Dropdown
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' }}
                                className=''
                                items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partially.fulfilled' }, { name: 'Fullfilled', value: 'fulfilled' }].sort((a, b) => a.value.localeCompare(b.value))}
                                valueKeyName='name'
                                optionKeyName='value'
                                value={props.selectedStatusName}
                                placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                                }}
                            />

                    </Col> */}
                   

                    <Col span={12}>
                        <div className="i-label"><FormattedMessage id='customer.filter.priority.placeholder' /></div>
                        <Dropdown
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' }}
                                className=''
                                items={[{ name: 'Low', value: 'low' }, { name: 'Medium', value: 'medium' }, { name: 'High', value: 'high' }, { name: 'Urgent', value: 'urgent' }]}
                                valueKeyName='name'
                                optionKeyName='value'
                                value={props.selectedPriority}
                                placeholder={props.intl.formatMessage({ id: 'customer.filter.priority.placeholder' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedPriority: option.name })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedPriority: undefined })
                                }}
                            />
                    </Col>
                
              
                    
                    <Col span={12}>
                        <div className="i-label"><FormattedMessage id='supplier.filter.refNum.placeholder' /></div>
                        <TextBox 
                                // className='new-fed1'
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                                className=''
                                placeholder='Ref #'
                                countStyle={{ top: "43px"}}
                                type='text'
                                maxLength={15}
                                value={props.refNum}
                                onChange={(e) => {
                                    props.updateState({ refNum: e.target.value });
                                }}
                            />
                    </Col>
               

                    {/* <Col span={12}>
                        <div className="i-label">Sales Person</div>
                        <Dropdown
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' }}
                                className=''
                                items={props.salesPersonList}
                                valueKeyName='fullName'
                                optionKeyName='relationshipEmployeeId'
                                value={props.selectedSalesPersonName}
                                placeholder={props.intl.formatMessage({ id: 'filter.salesPerson.placeholder' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedSalesPersonName: option.name, selectedSalesPersonId: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedSalesPersonName: undefined, selectedSalesPersonId: undefined })
                                }}
                            />
                    </Col> */}
                    <Col span={12}>
                    <div className="i-label"><FormattedMessage id='salesInvoice.project.placeholder' /></div>
                    <Dropdown
                                // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}
                                
                                items={(props.projectList || []).sort((a, b) => a.projectName.localeCompare(b.projectName))}
                                valueKeyName='projectName'
                                optionKeyName='projectMasterId'
                                // projectIdKey='projectMasterId'
                                value={props.selectedProjectName}
                                placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })

                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })

                                }}
                            />
                    </Col>
                    </Row>
               

            </Drawer>
        </>
    )
};

export default SalesInquiryFilterComp;
