import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GstrFillingComp from '../../../../../components/modal/modalBody/finance/gstrDetails';
import { uploadGSTR1FilingDetails } from './action';
class GSTRFillingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierGstin:"",
      buyerGstin:"",
     
    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
  
  }

  render() {
    return (
      <GstrFillingComp {...this.state} {...this.props} invoiceList={this.props.invoiceList} updateState= {(data)=>{
        this.setState(data)
      }}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    xeroContactList: state.common.xeroContactList,
    xeroLedgerList: state.common.xeroLedgerList,
    xeroTaxList: state.common.xeroTaxList,
    invoiceListForUpload: state.salesInvoice.invoiceListByMasterId
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    uploadGSTR1FilingDetails
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GSTRFillingDetails);
