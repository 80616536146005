import React from "react";
import { getUserDefinedName } from "../../../../../../utils";
import { Empty, Tooltip } from "antd";
import AwardImg from "../../../../../../assets/images/award.png";


const Academics = (props) => {
  
  const {schoolCourseDetail} = props;

  const separatedObjects = (schoolCourseDetail?.subjectList || []).reduce((acc, obj) => {
    const key = obj.courseTermId;
    if (!acc[key]) {
        acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});


  return(
    <div className="academic_details">
      <div className="head_boxes">
          <div className="head_box">
            <div className="h_label">{getUserDefinedName("Department", props)}</div>
            <div className="h_subLabel">Dept. of Engg.</div>
          </div>
          <div className="head_box">
            <div className="h_label">{getUserDefinedName("Course", props)}</div>
            <div className="h_subLabel">B.Tech</div>
          </div>
      </div>

      {Object.keys(separatedObjects || {}).length ? 
        <div className="terms_box">
          {Object.keys(separatedObjects || {}).map((termId, i)=>{
            return <>
              <div className="term_table">
                <div className="table_heading">
                  {(separatedObjects[termId] || []).length ? <>
                    {separatedObjects[termId][0].courseTermName || '-'}
                    <Tooltip placement="top" title={"Certificate will be awarded"} trigger="hover" className="ml10">
                      {separatedObjects[termId][0].isCertificateAwarded ? 
                        <img src={AwardImg} alt="Certificate Awarded" height="38px" width="38px"/> 
                        : 
                        ''
                      }
                      
                    </Tooltip>
                  </> : "-"}
                </div>
                <table>
                  <thead>
                    <tr>
                      <th width="10%">S.No.</th>
                      <th width="40%">{getUserDefinedName('Subject Name', props)}</th>
                      <th width="20%">{getUserDefinedName('Subject Code', props)}</th>
                      <th width="20%">{getUserDefinedName('Subject Credit', props)}</th>
                      <th width="10%">{getUserDefinedName('Subject Type', props)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(separatedObjects[termId] || []).map((subject, j)=>{

                      return <tr key={`subject` + j}>
                        <td>{j+1}</td>
                        <td>{subject.subjectName || '-'}</td>
                        <td>{subject.subjectCode || '-'}</td>
                        <td>{subject.subjectCredit || '0'}</td>
                        <td><div className={`${subject.subjectType}`}>{subject.subjectType || '-'}</div></td>
                      </tr>

                    })}
                  </tbody>
                </table>
              </div>
            </>
          })}
        </div>
        :
        <Empty  className="mt20"/>
      }
    </div>
  )
}


export default Academics;