import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SQDetailComponent from '../../../../../components/modal/modalBody/customer/SQDetail';
import { fetchSQDetail, approveQuote, uploadStamp, deleteStamp } from './action'
import { fetchDataIfNeeded } from '../../../../../utils'
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchSalesQuotes, getSalesQuoteCount, deleteSalesQuote, updateUserStatus } from '../../../../customer/salesQuote/Listing/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';
import { deleteAttectedFile } from '../../../../../actions/commonActions';
import { deleteDocument } from '../../../../customer/salesQuote/StandardSalesQuote/action';
class SQDetail extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.sqPayload.customerId, salesQuotationMasterId: this.props.sqPayload.salesQuotationMasterId };
        fetchDataIfNeeded('fetchSQDetail', 'sqDetail', this.props, payload, true);

    }

    render() {
        return <>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <SQDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        sqDetail: state.sq.sqDetail,
        userInfo: state.common.userInfo,
        detailLoading: state.common.detailLoading

    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSQDetail,
    downloadFileFromBucket,
    generatePDF, printPDF,
    approveQuote,
    fetchSalesQuotes,
    getSalesQuoteCount,
    uploadStamp, 
    deleteStamp,
    deleteSalesQuote,
    updateUserStatus,
    deleteAttectedFile,
    deleteDocument
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SQDetail);