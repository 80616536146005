import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Row, Col, Drawer, DatePicker } from "antd";
import { TextBox } from "../../../../general/TextBox";
import { CustomButton } from "../../../../general/CustomButton";
import { Dropdown } from "../../../../general/Dropdown";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { MODAL_TYPE } from "../../../../../static/constants";
import { TextArea } from "../../../../general/TextAreaComponent";
import { CONSTANTS } from "../../../../../static/constants";
import {
  getLocalDateFromUtcStr,
  getCurrentDateForBackend,
} from "../../../../../utils";
import { hideModal } from "../../../../../actions/commonActions";

const customerTaskDrawer = (props) => {
  const { customerTask = {} } = props;

  const rowgutter = [24, 16];
  const format = CONSTANTS.DISPLAY_DATE_FORMAT;

  const addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getModalTitle("", modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="modal.commmon.submit"
          // values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {/* {labelName} ? */}
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.ACTIVITY_STATUS: {
        addFunc = props.addActivityStatus;
        break;
      }
      case MODAL_TYPE.ACTIVITY_PRIORITY: {
        addFunc = props.addActivityPriorities;
        break;
      }
      case MODAL_TYPE.ACTIVITY_TYPE: {
        addFunc = props.addActivityTypes;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.hideModal();
        props.popModalFromStack();
      },
    };
    showModal(modalData);
  };

  const getModalTitle = (newTextObj, modalType) => {
    let title;
    switch (modalType) {
      case MODAL_TYPE.ACTIVITY_STATUS: {
        title = (
          <FormattedMessage
            id="addItem.text.taskStatus"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.ACTIVITY_PRIORITY: {
        title = (
          <FormattedMessage
            id="addItem.text.taskPriority"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.ACTIVITY_TYPE: {
        title = (
          <FormattedMessage id="addItem.text.taskType" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            // props.pushModalToStack(data);
          }
        },
      };
      props.pushModalToStack(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;
    switch (modalType) {
      case MODAL_TYPE.ACTIVITY_STATUS: {
        deleteFunc = props.deleteActivityStatus;
        break;
      }
      case MODAL_TYPE.ACTIVITY_PRIORITY: {
        deleteFunc = props.deleteActivityPriorities;
        break;
      }
      case MODAL_TYPE.ACTIVITY_TYPE: {
        deleteFunc = props.deleteActivityTypes;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  const handleSubmit = () => {
    props.updateDrawerState({
      submittedOnce: true,
    });
    if (!customerTask.activityTypeName || !customerTask.description) {
      return;
    }
    const payload = {
      ...customerTask,
      relationshipId: (props.companyInfo || {}).relationshipId,
      dateCreated: getCurrentDateForBackend(new Date()),
      userCreated: props.userInfo.firstName,
      customerName: props.customer?.companyName,
      customerId: props.customer?.customerId,
      version: (customerTask || {}).version || 0,
      activityDueDate: getCurrentDateForBackend(customerTask.activityDueDate),
      activityClosedDate: getCurrentDateForBackend(
        customerTask.activityClosedDate
      ),
    };
    const modalData = {
      modalBody: customerTask.isUpadte ? (
        <FormattedMessage id="activity.updateConfirmation" defaultMessage="" />
      ) : (
        <FormattedMessage id="activity.confirmation" defaultMessage="" />
      ),
      handleSubmit: () => {
        props.createUpdateActivity(payload);
        props.updateState({
          customerTaskDrawerVisible: false,
        });
      },
    };
    props.pushModalToStack(modalData);
  };

  return (
    <Drawer
      title={"Create Task"}
      keyboard={false}
      className="custom-drawer"
      width={720}
      maskClosable={false}
      zIndex={99999}
      destroyOnClose={true}
      onClose={() => {
        props.updateState({
          customerTaskDrawerVisible: false,
        });
      }}
      visible={props.customerTaskDrawerVisible}
      footer={
        <>
          <CustomButton
            intlId={"confirmation.cancel"}
            defaultMessage={""}
            type={"default"}
            key={1}
            onClick={() => {
              props.updateState({
                customerTask: {},
                customerTaskDrawerVisible: false,
              });
            }}
          />
          <CustomButton
            intlId={"button.save.label"}
            defaultMessage={""}
            htmlType="submit"
            key={2}
            onClick={() => {
              handleSubmit();
            }}
          />
        </>
      }
    >

      <Row gutter={rowgutter}>
        <Col span={12}>
          <div className="i-label">Task Type
          <span className="required">*</span>
          </div>
          <Dropdown
            items={(props.activityTypes || []).sort((a, b) => a.activityTypeName.localeCompare(b.activityTypeName))}
            placeholder="Select"
            optionKeyName="activityTypeId"
            valueKeyName="activityTypeName"
            value={customerTask.activityTypeName}
            onSelect={(selectedValue, option) => {
              props.updateDrawerState({
                customerTask: {
                  ...customerTask,
                  activityTypeId: selectedValue,
                  activityTypeName: option.name,
                },
              });
            }}
            canAddNew={true}
            canDelete={true}
            deleteClickHandler={(payload) => {
              deleteClickHandler(props, MODAL_TYPE.ACTIVITY_TYPE, payload);
            }}
            onAddNewClick={(payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              handleNewItemAddition(props, payload, MODAL_TYPE.ACTIVITY_TYPE);
            }}
          />
          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || customerTask.activityTypeName;
            }}
            message={props.intl.formatMessage({
              id: "drawer.task.taskType.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">
            <FormattedMessage
              id="supplier.listing.logActivity.refNumber.label"
              defaultMessage=""
            />
          </div>
          <TextBox
            value={customerTask.refNumber}
            placeholder={"Ref #"}
            maxLength={20}
            countStyle={{top:"40px"}}
            onChange={(e) => {
              props.updateDrawerState({
                customerTask: {
                  ...customerTask,
                  refNumber: e.target.value,
                },
              });
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Status</div>
          <Dropdown
            items={props.activityStatus || []}
            placeholder="Select"
            optionKeyName="activityStatusId"
            valueKeyName="activityStatusName"
            value={customerTask.activityStatusName}
            onSelect={(selectedValue, option) => {
              props.updateDrawerState({
                customerTask: {
                  ...customerTask,
                  activityStatusId: selectedValue,
                  activityStatusName: option.name,
                },
              });
            }}
            canAddNew={true}
            canDelete={true}
            deleteClickHandler={(payload) => {
              deleteClickHandler(props, MODAL_TYPE.ACTIVITY_STATUS, payload);
            }}
            onAddNewClick={(payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              handleNewItemAddition(props, payload, MODAL_TYPE.ACTIVITY_STATUS);
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Priority</div>
          <Dropdown
            items={props.activityPriorities || []}
            placeholder="Select"
            optionKeyName="activityPriorityId"
            valueKeyName="activityPriorityName"
            value={customerTask.activityPriorityName}
            onSelect={(selectedValue, option) => {
              props.updateDrawerState({
                customerTask: {
                  ...customerTask,
                  activityPriorityId: selectedValue,
                  activityPriorityName: option.name,
                },
              });
            }}
            canAddNew={true}
            canDelete={true}
            deleteClickHandler={(payload) => {
              deleteClickHandler(props, MODAL_TYPE.ACTIVITY_PRIORITY, payload);
            }}
            onAddNewClick={(payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              handleNewItemAddition(
                props,
                payload,
                MODAL_TYPE.ACTIVITY_PRIORITY
              );
            }}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Start Date
          <span className="required">*</span>
          </div>
          <DatePicker
            allowClear={false}
            format={format}
            style={{ width: "100%" }}
            key={"duedate"}
            defaultValue={
              customerTask.activityDueDate
                ? getLocalDateFromUtcStr({ date: customerTask.activityDueDate })
                : null
            }
            onChange={(selectedDate) => {
              props.updateDrawerState({
                customerTask: {
                  ...customerTask,
                  activityDueDate: selectedDate,
                },
              });
            }}
          />
          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || customerTask.activityDueDate;
            }}
            message={props.intl.formatMessage({
              id: "contact.startDate.message",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">End Date
          <span className="required">*</span>
          </div>
          <DatePicker
            allowClear={false}
            format={format}
            style={{ width: "100%" }}
            key={"closedDate"}
            defaultValue={
              customerTask.activityClosedDate
                ? getLocalDateFromUtcStr({
                    date: customerTask.activityClosedDate,
                  })
                : null
            }
            onChange={(selectedDate) => {
              props.updateDrawerState({
                customerTask: {
                  ...customerTask,
                  activityClosedDate: selectedDate,
                },
              });
            }}
          />
          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || customerTask.activityClosedDate;
            }}
            message={props.intl.formatMessage({
              id: "contact.endDate.message",
              defaultMessage: "",
            })}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Assign To</div>
          <Dropdown
            items={(props.allEmployee || []).sort((a,b)=>a.fullName?.localeCompare(b.fullName))}
            placeholder="Select"
            optionKeyName="relationshipEmployeeId"
            valueKeyName="fullName"
            value={customerTask.assignedUserName}
            onSelect={(selectedValue, option) => {
              props.updateDrawerState({
                customerTask: {
                  ...customerTask,
                  assignedUserId: selectedValue,
                  assignedUserName: option.name,
                },
              });
            }}
          />
        </Col>

        <Col span={24}>
          <div className="i-label">Description
          <span className="required">*</span>
          </div>
          <TextArea
            placeholder="Brief description about the task"
            value={customerTask.description}
            maxLength={250}
            style={{
              width: "100%",
              height: "100px",
              padding: "5px 8px",
              resize: "none",
              color: "#000000",
              opacity: "0.6",
            }}
            onChange={(e) => {
              props.updateDrawerState({
                customerTask: {
                  ...customerTask,
                  description: e.target.value,
                },
              });
            }}
          />
          <ErrorMsg
            validator={() => {
              return !props.submittedOnce || customerTask.description;
            }}
            message={props.intl.formatMessage({
              id: "drawer.task.description.missing.msg",
              defaultMessage: "",
            })}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default injectIntl(customerTaskDrawer);
