import { Button, Col, Row, Tabs } from "antd";
import React from "react";
import { ExpandOutlined } from "@ant-design/icons";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION_FULL_PAGE } from "../../../../../static/ckEditorConfigConstants";
import "../../../../eStore/storeSettings/store-settings.scss";

const PolicyPopup = (props) => {
  const { TabPane } = Tabs;

  

 

  return (
    <div
      className=""
      style={{  padding: "2rem" }}
    >
      <div>
        
        <Row>
          <Col span={24}>
            <div className="customEditor">
              <CKEditor
                data={props.assignmentDescription || props.data}
                key={`${"emailBody"}`}
                // onInit={editor => {
                //     this.editor = editor;
                // }}
                onChange={(event) => {
                  const data = event.editor.getData();
                  props.updateState({ assignmentDescription: data });
                }}
                config={{
                  ...CUSTOM_CONFIGURATION_FULL_PAGE,
                  placeholder: "Assignment Description",
                }}
              />
            </div>
          </Col>
        </Row>

       
      </div>
    </div>
  );
};

export default PolicyPopup;
