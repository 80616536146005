import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TaxesComponent from '../../../components/ShowSettings/taxes';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { getMarketplcaeCountries, saveUpdateMarketplaceTaxCountry, updateTaxIncludeInPrice, deleteMarketplaceCountry, getAllCountries } from "./action"
import { fetchDataIfNeeded } from "../../../utils";

class TaxesSettingComponent extends Component {

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true
        });
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
        };
        fetchDataIfNeeded("getMarketplcaeCountries", "marketplaceCountries", this.props, payload);
    }

    render() {
        return <TaxesComponent {...this.props}  {...this.state} updateState={(data) => {
            this.setState(data);
        }} />
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        marketplaceCountries: state.taxSettingReducer.marketplaceCountries,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getMarketplcaeCountries,
    saveUpdateMarketplaceTaxCountry,
    updateTaxIncludeInPrice,
    deleteMarketplaceCountry,
    getAllCountries,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaxesSettingComponent);
