import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DepartmentComp from "../../../components/schoolManagement/department"
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getDepartmentList, getDepartmentCount, deleteDepartment, resetPaginatedData } from "./action";
import { showModal, hideModal } from '../../../actions/commonActions';
// import { FormattedMessage } from 'react-intl';

class Department extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25,
    };
    this.props.getDepartmentList(payload);
    this.props.getDepartmentCount(payload);
  }

  render() {
    return (
      <DepartmentComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    departmentList: state.school.departmentList,
    departmentCount: state.school.departmentCount,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDepartmentList, getDepartmentCount, deleteDepartment,
      resetPaginatedData,
      showModal,
      hideModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Department);
