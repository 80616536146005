import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const fetchAllUsers = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/user/getAllUsers?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_USERS, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_USERS;
        }
      })
  }
}

export const fetchAllActivityPriorities = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/crmActivity/getAllActivityPriorities?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_ACTIVITY_PRIORITIES, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_ACTIVITY_PRIORITIES;
        }
      })
  }
}

export const fetchAllActivityStatus = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/crmActivity/getAllActivityStatus?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_ACTIVITY_STATUS, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_ACTIVITY_STATUS;
        }
      })
  }
}

export const fetchAllActivityTypes = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/crmActivity/getAllActivityTypes?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_ACTIVITY_TYPES, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_ACTIVITY_TYPES;
        }
      })
  }
}

export const createUpdateActivity = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerActivity/saveOrUpdateActivity`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: CUSTOMER_ACTION_LIST.ACTIVITY_ADDED, data: res.data });
        showToasterMessage({ messageType: 'success', description: 'Task Created successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}