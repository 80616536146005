import { SALES_COMPLAINT_ACTION_LIST } from '../static/constants';
const initialState = {
  salesComplaintList: {
    1: []
  },
  salesComplaintCount: 0,
  salesComplaintTypeList: 0,
  salesComplaintData:{
    1:[]
  },
  complaintCount:0
}

const SalesComplaintReducer = (state = initialState, action) => {
  switch (action.type) {
    case SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_LIST:
      return {
        ...state,
        salesComplaintList: {
          ...state.salesComplaintList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_COUNT:
      return { ...state, salesComplaintCount: action.data };
    case SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_LIST_PAGE_RESET:
      return { ...state, salesComplaintList: initialState.salesComplaintList };
    case SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_TYPE_LIST:
      return { ...state, salesComplaintTypeList: action.data };
    case SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_TYPE_LIST_ADDITION:
      return { ...state, salesComplaintTypeList: [action.data, ...state.salesComplaintTypeList] }
    case SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_TYPE_LIST_DELETION:
      return { ...state, salesComplaintTypeList: state.salesComplaintTypeList.filter(x => x.complaintTypeId !== action.data.id) }
    case SALES_COMPLAINT_ACTION_LIST.NEXT_COMPLAINT_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case SALES_COMPLAINT_ACTION_LIST.NEXT_COMPLAINT_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
      case SALES_COMPLAINT_ACTION_LIST.SALES_COMPLAINT_DATA:
        return {
          ...state,
          salesComplaintData: {
            ...state.salesComplaintData,
            [action.data.pageNo || 1]: action.data.list
          }
        };
        case SALES_COMPLAINT_ACTION_LIST.COMPLAINT_COUNT:
          return { ...state, complaintCount: action.data };
    default:
      return state;
  }
};

export default SalesComplaintReducer;