import { JOURNAL_ACTION_LIST } from '../static/constants';
const initialState = {
    allJournals: [],
    journalDetailsData:  {}
}

const JournalReducer = (state = initialState, action) => {
    switch (action.type) {
        case JOURNAL_ACTION_LIST.JOURNAL_LIST:
            return { ...state, allJournals: action.data };
        case JOURNAL_ACTION_LIST.JOURNAL_DETAILS_DATA:
            return { ...state, journalDetailsData: action.data };
        default:
            return state;
    }
};

export default JournalReducer;