import axios from "axios";
import React from "react";
import config from "../../../../../config/environmentConfig";
import { COMMON_ACTIONS } from "../../../../../static/constants";
import { showToasterMessage } from "../../../../../utils";
import download from "downloadjs";
const { lang } = require("../../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const importSupplier = (payload, props, callBack) => {
  var formData = new FormData();
  formData.append("file", payload.fileToUpload);
  formData.append(
    "mapping",
    new Blob([payload.mappedData], {
      type: "application/json",
    })
  );
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierExcel/importSupplierExcel`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        callBack()
        if(!(res?.data?.SuccessRecords || []).length){
          throw new Error(`${(res?.data?.ErrorRecords || []).length || "Some"} Records Failed`)
        }
        showToasterMessage({
          messageType: "success",
          description:
            lang[(res.data || {}).message] || "Upload completed succesfully",
        });
        props.updateState({
          isuploded:true,
          resData:res.data
        })
        props.fetchSuppliers({
          relationshipId: props.companyInfo.relationshipId,
          pageSize: 25,
          pageNumber: 1,
        });
        props.getSupplierCount({
          relationshipId: props.companyInfo.relationshipId,
        });

      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Please reselect the upload file.",
        });
      });
  };
};

export const getSupplierImportHeader = (relationshipId, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierExcel/getSupplierHeaders?relationshipId=${relationshipId}`
      )
      .then((res) => {
        const response = res.data;
        dispatch({
          type: COMMON_ACTIONS.SUPPLIER_IMPORT_HEADER,
          data: response,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some Error Occurs",
          });
        } else {
          showToasterMessage({
            messageType: "error",
            description: "Some Error Occurs",
          });
          props.popModalFromStack();
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};

export const downloadExelFile = (payload, props) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierExcel/download-error-excel`,
        payload,
        { responseType: 'arraybuffer' } // Specify responseType as 'arraybuffer' to get a byte array
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' }); // Adjust the MIME type according to your file type
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = 'sample.xls'; // Set the desired file name with .xls extension
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        props.popModalFromStack()
      })
      .catch((err) => {
        if (lang[((err.response || {}).data || {}).message]) {
          showToasterMessage({
            messageType: 'error',
            description:
              lang[((err.response || {}).data || {}).message] || 'Some Error Occurs',
          });
        } else {
          showToasterMessage({
            messageType: 'error',
            description: 'Some Error Occurs',
          });
          props.popModalFromStack();
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      });
  };
};
