import React from "react";
import QRCode from 'qrcode.react';
import { Card } from "antd";
import { getAWSFileURL, getMomentDateForUIReadOnly } from "../../../../../../utils";
import AlpideLogo from "../../../../../../assets/images/favicon.ico";

const { Meta } = Card;

const IdCard = (props) => {

  const {studentData} = props;

  const studentIdData = JSON.stringify({
    "studentId": `${studentData.schoolStudentId}`,
    "name": `${studentData.studentName || ''}`,
    "contact": `${studentData.primaryMobileNo || ''}`,
    "dateOfBirth": `${studentData.dateOfBirth ? getMomentDateForUIReadOnly({date: new Date(studentData.dateOfBirth)}) : ''}`,
    "Address": "C-116, Office-on, Sector-2, Noida",
    "issueDate": "issue",
    "expiryDate": "ex"
  })

  return (
    <div className="student_id">
      <Card
        hoverable
        style={{ width: 240, textAlign: 'center' }}
        cover={<>
            <div className='mt10' style={{fontSize: '14px', fontWeight: 700}}>
              {props.companyInfo?.storeName || ''}
            </div>
            {studentData.logoAwsObjectKey ? 
            <img src={
              getAWSFileURL(studentData.logoAwsObjectKey ,props.companyInfo.bucketName , "student")
            } height={'240px'} width={'240px'} />
            
            :
             <img alt="student_pic" src="https://img.freepik.com/premium-photo/beautiful-girl-student-smiling-holding-book-photography_878783-7414.jpg" /> 
          }

                              </>
        }
      >
        <QRCode value={studentIdData} renderAs="canvas" />
        <img alt="logo" className="logo" src= {AlpideLogo}/>
        <Meta title={studentData.studentName || ''} description={studentData.courseName || '' } />
      </Card>
    </div>
  );
};

export default IdCard;