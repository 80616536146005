import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { injectIntl } from 'react-intl';
import SalesChannelEBay from "../../../containers/salesChannels/ebay";
import SalesChannelWalmart from "../../../containers/salesChannels/walmart";
import SalesChannelAmazon from "../../../containers/salesChannels/amazon";
import SalesChannelShopify from "../../../containers/salesChannels/shopify";
import Icon1 from "../../../assets/images/icon/amazonIcon.png";
import Icon2 from "../../../assets/images/icon/ebayIcon.png";
import Icon3 from "../../../assets/images/icon/walmartIcon.png";
import Icon4 from "../../../assets/images/icon/shopifyIcon.png";
// import Icon5 from "../../../assets/images/icon/carrefourIcon.png";
// import Icon6 from "../../../assets/images/icon/noonIcon.png";
import {Row,Col} from "antd";
import "./index.scss";
// import SalesChannelCarrefour from "../../../containers/salesChannels/carrefour";
// import SalesChannelNoon from "../../../containers/salesChannels/noon";
function MarketPlaceComp(props) {
    return (
      <div className="container">
        <Row>
          <Col span={24}>
            <div className="header">
              <h2>Marketplaces</h2>
              <p>Manage your company market places</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div className='paymentOptionArea'>
              <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon1} alt="" />
                  <h2>Amazon</h2>
                </div>
                <div className='buttonArea'>
                  <button className='notActiveButton' 
                  onClick={()=>{
                  props.pushModalToStack({
                    title:"Sales Channel Amazon",
                    modalBody: <SalesChannelAmazon {...props} />,
                    width: '100%',
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                  }}
                  >Inprocess</button>
                </div>
                <div className='notActive'></div>
              </div>
              <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon2} alt="" />
                  <h2>Ebay</h2>
                </div>
                <div className='buttonArea'>
                  <button className='notActiveButton'
                  onClick={()=>{
                  props.pushModalToStack({
                    title:"Sales Channel EBay",

                    modalBody: <SalesChannelEBay {...props} />,
                    width: '100%',
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                  }}
                  >Inprocess</button>
                </div>
                <div className='notActive'></div>
              </div>
              <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon3} alt="" />
                  <h2>Walmart</h2>
                </div>
                <div className='buttonArea'>
                  <button className='notActiveButton'
                  onClick={()=>{
                  props.pushModalToStack({
                    title:"Sales Channel Walmart",

                    modalBody: <SalesChannelWalmart {...props} />,
                    width: '100%',
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                  }}
                  >Inprocess</button>
                </div>
                <div className='notActive'></div>
              </div>
              <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon4} alt="" />
                  <h2>Shopify</h2>
                </div>
                <div className='buttonArea'>
                  <button className='notActiveButton'
                  onClick={()=>{
                  props.pushModalToStack({
                    title:"Sales Channel Shopify",

                    modalBody: <SalesChannelShopify {...props} />,
                    width: '100%',
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                  }}
                  >Inprocess</button>
                </div>
                <div className='notActive'></div>
              </div>
              {/* <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon5} alt="" />
                  <h2>Carrefour</h2>
                </div>
                <div className='buttonArea'>
                  <button
                  onClick={()=>{
                  props.pushModalToStack({
                    title:"Sales Channel Carrefour",

                    modalBody: <SalesChannelCarrefour {...props} />,
                    width: '100%',
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                  }}
                  >Set Up</button>
                </div>
              </div> */}
              {/* <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon6} alt="" />
                  <h2>Noon </h2>
                </div>
                <div className='buttonArea'>
                  <button
                  onClick={()=>{
                  props.pushModalToStack({
                    title:"Sales Channel Noon",

                    modalBody: <SalesChannelNoon {...props} />,
                    width: '100%',
                    hideFooter: true,
                    wrapClassName: 'new-transaction-wrapper'
                })
                  }}
                  >Set Up</button>
                </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    );

}

export default injectIntl(MarketPlaceComp);