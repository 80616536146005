import React, { Fragment } from "react";
import { Row, Col, Button,Input } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import ReactHtmlParser from "react-html-parser";

import { CustomAgGrid } from "../../../general/CustomAgGrid";
//import { AddAndRemoveColumn } from "../../../general/AddAndRemoveColumn";
import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../static/agGridConstants";
import { AG_GRID_CLASS_CONSTANTS } from "../../../../static/cssClassConstants";
import {
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
} from "../../../../static/constants";
import { getLabelName } from "../../../modal/modalBody/customerInfo";
import {
  showToasterMessage,
  fixedDecimalAndFormateNumber,
} from "../../../../utils";
//import { CustomTableUpload } from "../../../general/UploadTable";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
import { AWSFileTable } from "../../../general/AWSFileTable";
const { TextArea } = Input;

class ProductDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      sectionVisible: {
        discount: false,
        tax: false,
        expense: false,
      },
      total: 0,
      subtotal: 0,
      subtotalWithoutDiscount: 0,
      totalDiscount: 0,
      expenseAmount: 0,
      totalTax: 0,
      itemLevelTaxes: [],
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
    };
  }
  calculateSubtotal = () => {
    let total = 0;
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = [];
    let subtotalWithoutDiscount = 0;
    let rootDiscountAmount = 0;
    let self = this;
    if (!this.state.rootDiscountPercent && this.props.rootDiscountPercent) {
      this.setState({
        rootDiscountPercent: this.props.rootDiscountPercent,
        rootDiscountAmount: this.props.rootDiscountAmount,
      });
    }

    if (!this.state.expenseAmount && this.props.expenseAmount) {
      this.setState({
        expenseAmount: this.props.expenseAmount,
      });
    }

    this.gridApi &&
      this.gridApi.forEachNode(function (rowNode, index) {
        let stringifiedRowData = JSON.stringify(rowNode.data);
        let totalAmountOfColumn = 0;
        const rowdata = JSON.parse(stringifiedRowData);
        if (rowdata.qty && rowdata.rate) {
          let taxObj = {
            subtotal: 0,
            taxPercent: 0,
            taxAmount: 0,
          };
          totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
          subtotalWithoutDiscount =
            Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
          if (rowdata.discount) {
            let discountAmount =
              Number(rowdata.discount || 0) * 0.01 * totalAmountOfColumn;
            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            totalDiscount = totalDiscount + discountAmount;
          }

          if (self.state.rootDiscountPercent) {
            let itemLevelDiscountAmount =
              Number(self.state.rootDiscountPercent) *
              0.01 *
              totalAmountOfColumn;
            totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
            totalDiscount = totalDiscount + itemLevelDiscountAmount;
            rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
          }

          if (rowdata.tax) {
            taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
            taxObj.taxName = rowdata.taxName;
            let taxAmount =
              Number(rowdata.tax || 0) * 0.01 * totalAmountOfColumn;
            totalTax = totalTax + taxAmount;
          }
          if (taxObj.taxPercent) {
            taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
            const availableTaxObj = find(itemLevelTaxes, {
              taxPercent: taxObj.taxPercent,
            });
            if (availableTaxObj) {
              itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                if (ele.taxPercent === taxObj.taxPercent) {
                  ele.subtotal =
                    Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                  ele.taxAmount =
                    Number(ele.taxPercent || 0) *
                    0.01 *
                    Number(ele.subtotal || 0);
                }
                return true;
              });
            } else {
              taxObj.taxAmount = (
                Number(taxObj.taxPercent || 0) *
                0.01 *
                Number(taxObj.subtotal || 0)
              ).toFixed(2);
              itemLevelTaxes.push(taxObj);
            }
          }
          subtotal = Number(subtotal) + totalAmountOfColumn;
        }
      });

    total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;

    this.setState({
      total: Number(total || 0).toFixed(2),
      subtotal: Number(subtotal || 0).toFixed(2),
      totalDiscount: Number(totalDiscount || 0).toFixed(2),
      totalTax: Number(totalTax || 0).toFixed(2),
      subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
      itemLevelTaxes,
      rootDiscountAmount,
    });
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    this.gridApi && this.gridApi.stopEditing();
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  render() {
    const self = this;
    const { itemLevelTaxes } = this.state;
    const isPlaceOfSupplySameAsRelationship =
      (this.props.relationshipBillingAddress || {}).stateName ===
      this.props.placeOfSupply;
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      // onDataRendered(params);
      params.api.sizeColumnsToFit();
    };
    // const content = <AddAndRemoveColumn {...this.props} />

    // const _isColumnToHide = (columnName) => {
    //     // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
    //     //     return true;
    //     // }
    //     return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    // }

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "#";
        },
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1;
        },
        field: "sno",
        rowDrag: true,
        colId: "sno_1",
        minWidth: 70,
        maxWidth: 70,
        suppressMovable: true,
        lockPosition: true,
        sortable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseRequest.form.gridHeader.product"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
        resizable: true,
        //editable: true,
        minWidth: 120,
        suppressMovable: true,
        sortable: true,
        allowClear: true,
        permissions: this.props.permissions,
        moduleName: MODULE_CODED_VALUES.PRODUCTS,
        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        // hide: _isColumnToHide(AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD),
        cellEditor: "customTreeDropDown",
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight >=
                (params.node.data.maxHeight || 30)
            ) {
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
              this.gridApi && this.gridApi.onRowHeightChanged();
            } else {
              params.node.data.minHeight =
                params.reactContainer.scrollHeight > 30
                  ? params.reactContainer.scrollHeight
                  : 30;
            }
          }, 500);
          return ReactHtmlParser(params.data.product);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            options: this.props.products,
            onSearch: this.props.onSearch,
            onAddNewClick: () => {
              self.props.updateHeaderState({
                inventory: {
                  inventoryDrawerVisible: true,
                },
              });
            },
            canAddNew: true,
            value: obj.node.data.selectedProductValue || [],
            selectedLabel: obj.node.data.product || "",
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.selectedProductValue =
                obj.node.selectedProductValue;
              const itemId =
                obj.node.data.selectedProductValue &&
                obj.node.data.selectedProductValue[0];
              if (itemId) {
                const variantId =
                  obj.data.selectedProductValue &&
                  obj.data.selectedProductValue.length === 2
                    ? obj.data.selectedProductValue[1]
                    : 0;
                obj.node.data.productObj =
                  find(this.props.products, { itemId: Number(itemId) }) || {};
                obj.node.data.variant = variantId
                  ? find(obj.node.data.productObj.inventoryItemVariantList, {
                      variantId: Number(variantId),
                    })
                  : obj.node.data.productObj.inventoryItemVariantList[0];
                if (obj.node.data.productObj.description) {
                  obj.node.data.description =
                    obj.node.data.productObj.description;
                }
                if (obj.node.data.variant && !this.props.isDataToUpdate) {
                  const rate = Number(
                    obj.node.data.variant.purchasedPrice || 0
                  ).toFixed(2);
                  obj.node.data.baseRate = Number(rate);
                  obj.node.data.rate = Number(rate);
                  //obj.node.setDataValue('specialDiscount', 0);
                  //obj.node.setDataValue('baseRate', Number(rate));
                  //obj.node.setDataValue('rate', Number(rate));
                  //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                  obj.node.data.uom = obj.node.data.variant.uomName;
                  if (obj.node.data.productObj.isPopulateTax) {
                    const defaultItemHsnAndTax =
                      obj.node.data.productObj.inventoryItemDefaultTaxList[0] ||
                      {};
                    obj.node.data.hsnCode = (
                      defaultItemHsnAndTax || {}
                    ).hsnCode;
                    let selectedTax = {};
                    if ((defaultItemHsnAndTax || {}).taxSingleRateId) {
                      selectedTax =
                        find(this.props.taxes, {
                          taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId,
                        }) || {};
                    } else {
                      selectedTax =
                        find(this.props.taxes, {
                          taxPercent:
                            (defaultItemHsnAndTax || {}).igstRate ||
                            (defaultItemHsnAndTax || {}).cgstRate ||
                            (defaultItemHsnAndTax || {}).globleTaxRate,
                        }) || {};
                    }
                    obj.node.data.taxName = selectedTax.taxName;
                    obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                    obj.node.data.tax = selectedTax.taxPercent;
                    obj.node.data.taxName = selectedTax.taxName;
                    obj.node.data.taxId = selectedTax.taxSingleRateId;
                  }
                }
                let isDuplicateItem = false;
                this.gridApi &&
                  this.gridApi.forEachNode((rowNode, index) => {
                    const { data } = rowNode;
                    if (
                      data.product === selectedValue &&
                      index !== obj.node.rowIndex
                    ) {
                      isDuplicateItem = true;
                    }
                  });
                if (isDuplicateItem) {
                  showToasterMessage({
                    messageType: "warning",
                    description: "Duplicate product is selected",
                  });
                }

                obj.node.data.isDuplicateItem = isDuplicateItem;
              }
            },
          };
        },
        cellClassRules: {
          "ag-grid-cell-warning-boundary": function (obj) {
            let isDuplicateItem = false;
            self.gridApi &&
              self.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (
                  data.product &&
                  data.product === obj.node.data.product &&
                  obj.node.rowIndex !== index
                ) {
                  isDuplicateItem = true;
                }
              });
            return isDuplicateItem;
          },
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseRequest.form.gridHeader.description"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        cellClass: " ag-cell-description",
        resizable: true,
        //editable: true,
        sortable: true,
        minWidth: 120,
        suppressMovable: true,
        //hide: _isColumnToHide(AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD),
        colId: GRID_PREFERENCE_CONSTANTS.DESCRIPTION + "_1",
        cellEditor: "CKEditorGrid",
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            isEditorLoaded: this.props.isEditorLoaded,
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
              params.node.data.isEditorLoaded = true;
              this.props.updateState({
                isEditorLoaded: true,
              });
            },
          };
        },
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight > 30 &&
              params.reactContainer.scrollHeight >=
                (params.node.data.minHeight || 0)
            ) {
              params.eParentOfValue.style.height = "inherit";
              params.eParentOfValue.style.height =
                params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500);
          return ReactHtmlParser(params.data.description);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (
            params.event.target.scrollHeight > (params.data.minHeight || 30)
          ) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${
                params.node.data.minHeight || 30
              }px`;
            }
            params.node.setRowHeight(params.node.data.minHeight || 30);
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseRequest.form.gridHeader.qty"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
        editable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        resizable: true,
        sortable: true,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseRequest.form.gridHeader.uom"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        // editable: true,
        resizable: true,
        sortable: true,
        // hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0,
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: true,
            items: this.props.uomList,
            optionKeyName: "uomId",
            valueKeyName: "uomName",
            canAddNew: true,
            canDelete: true,
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
            },
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM);
            },
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.rate"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.RATE_FIELD + "_1",
        editable: true,
        minWidth: 100,
        maxWidth: 150,
        suppressMovable: true,
        sortable: true,
        resizable: true,
        cellEditor: "customNumberEditor",
        cellEditorParams: (obj) => {
          return {
            onChange: (value) => {
              obj.node.data.baseRate = value;
              obj.node.data.rate = value;
              obj.api.refreshCells();
            },
          };
        },
        valueGetter: (params) => {
          if (
            params.data.specialDiscount &&
            params.data.rate &&
            Number(params.data.rate) !== 0
          ) {
            params.data.rate =
              params.data.baseRate -
              params.data.specialDiscount * 0.01 * params.data.baseRate;
          }
          if (!params.data.specialDiscount && params.data.baseRate) {
            params.data.rate = params.data.baseRate;
          }

          if (this.props.priceListId && params.data.rate) {
            params.data.rate =
              params.data.baseRate -
              (this.props.priceListType.toLowerCase() === "markdown"
                ? (params.data.specialDiscount || 0) *
                    0.01 *
                    params.data.baseRate +
                  this.props.priceListPercentage * 0.01 * params.data.baseRate
                : (params.data.specialDiscount || 0) *
                    0.01 *
                    params.data.baseRate -
                  this.props.priceListPercentage * 0.01 * params.data.baseRate);
          }
          return params.data.rate && Number(params.data.rate || 0).toFixed(2);
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.discount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
        editable: true,
        sortable: true,
        minWidth: 120,
        maxWidth: 120,
        suppressMovable: true,
        resizable: true,
        colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT + "_1",
        //hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT),
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            maxValue: 100,
            onChange: (value) => {
              obj.node.data.discount = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.tax"
              defaultMessage=""
            />
          );
        },
        field: "taxApplied",
        editable: true,
        sortable: true,
        minWidth: 120,
        suppressMovable: true,
        resizable: true,
        colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
        // hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX),
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: true,
            items: this.props.taxes,
            optionKeyName: "taxSingleRateId",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.TAXES,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                taxName: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewTaxAddition(
                this.props,
                payload,
                MODAL_TYPE.NEW_TAX
              );
            },
            valueKeyName: "taxNameDisplay",
            onSelect: (selectedValue, option) => {
              const selectedTax = find(this.props.taxes, {
                taxSingleRateId: Number(option.key),
              });
              obj.node.data.tax = selectedTax.taxPercent;
              obj.node.data.taxName = selectedTax.taxName;
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.taxId = selectedTax.taxSingleRateId;
            },
            allowClear: true,
            onClear: () => {
              obj.node.data.tax = null;
              obj.node.data.taxId = null;
              obj.node.data.taxApplied = null;
              obj.node.data.taxName = null;
              this.calculateSubtotal();
            },
          };
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="supplier.purchaseOrder.form.gridHeader.amount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
        resizable: true,
        pinned: "right",
        minWidth: 120,
        lockPosition: true,
        suppressMovable: true,
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount =
                params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            if (params.data.tax) {
              totalAmountOfColumn =
                params.data.tax * 0.01 * totalAmountOfColumn +
                totalAmountOfColumn;
            }
            this.calculateSubtotal();
          }
          return totalAmountOfColumn ? totalAmountOfColumn.toFixed(2) : "";
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
    ];

    const submitResponse = () => {
      let isRequiredFiledMissing = false;
      const rowData = this.gridApi.gridOptionsWrapper?.gridOptions?.rowData || [];
      for(let data of rowData){
        if(!data.rate){
          isRequiredFiledMissing = true;
          break;
        }
      }

      if(isRequiredFiledMissing) {
          return showToasterMessage({
              messageType: 'error', description: 'Please fill the product details'
          });
      }
      const modalData = {
        modalBody: (
          <FormattedMessage
            id="modal.commmon.submit"
            defaultMessage={``}
          />
        ),
        handleSubmit: () => {
          const payload = {
            props: this.props,
            gridObject: this.gridApi,
            state: this.state,
          };
          this.props.submitSupplierResponse(payload, this.props);
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    };

    const getRowHeight = (params) => {
      const rowHeight = params.node.data.scrollHeight
        ? Number(params.node.data.scrollHeight.split("px")[0]) - 30
        : 30;
      return rowHeight < 30 ? 30 : rowHeight;
    };

    return (
      <Fragment>
        <div className="transaction-table">
          <CustomAgGrid
            columnDefs={columnDefs}
            rowData={this.props.rFQDetailsList || [{}]}
            onGridReady={onGridReady}
            //onModelUpdated={onModelUpdated}
            getRowHeight={getRowHeight}
            //onColumnResized={onColumnResized}
            // onDragStopped={() => {
            //     onColumnMoved({}, true);
            // }}
            //onColumnMoved={onColumnMoved}
            gridStyle={{
              width: "100%",
              height: "100%",
              marginBottom: "10px",
            }}
          />
        </div>

        <Row type="flex" justify="end">
          <Col span={12}>
            <div className="read-only-content">
              {this.props.oldRfqDetailData.docDetailList &&
              this.props.oldRfqDetailData.docDetailList.length ? (
                <Row>
                  <Col span={24} className="title">
                    <FormattedMessage
                      id="modal.txDetails.common.attachments"
                      defaultMessage=""
                    />
                  </Col>
                  <Col span={12}>
                    <AWSFileTable
                      {...this.props}
                      hideDownloadButton={true}
                      docDetailList={this.props.oldRfqDetailData.docDetailList}
                      // fetchTxDetail={(data) => {
                      //   this.props.fetchSupplierRFQDetail({
                      //     // supplierId: rfqData.supplierId,
                      //     // rfqMasterId: rfqData.rfqMasterId,
                      //     // relationshipId: rfqData.relationshipId,
                      //   })
                      // }}
                    />
                    {this.props.oldRfqDetailData.docDetailList.map((doc) => {
                      return (
                        <span
                          className={"cursor-pointer"}
                          onClick={() => {
                            this.props.downloadFileFromBucket({
                              bucketName: doc.bucketName,
                              awsKeyName: doc.awsKeyName,
                              fileName: doc.fileName,
                            });
                          }}
                        >
                          {doc.fileName} &nbsp;
                        </span>
                      );
                    })}
                  </Col>
                </Row>
              ) : null}
              {/* {this.props.oldRfqDetailData.remarksInternal &&
                                    <Row>
                                        <Col span={7} className="title">
                                        <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                        </Col>
                                        <Col span={17}>
                                            {ReactHtmlParser(this.props.oldRfqDetailData.remarksInternal)}
                                        </Col>
                                    </Row>
                                    } */}
              {this.props.oldRfqDetailData.remarksSupplier && (
                <Row>
                  {/* <Col span={5}>
                                        <FormattedMessage id='modal.txDetails.common.supplierRemarks' defaultMessage='' />
                                        </Col> */}
                  <Col span={17}>
                    {ReactHtmlParser(
                      this.props.oldRfqDetailData.remarksSupplier
                    )}
                  </Col>
                </Row>
              )}
              <div className="remarks-title">
                <FormattedMessage id="supplier.rfq.biddingSuppliers.gridHeader.remarks" />
              </div>
              <TextArea
                type="inline"
                className="description-textarea"
                key={`${"supplierComment"}`}
                data={this.props.supplierComment}
                style={{height:"95px"}}
                // onInit={(editor) => {
                //   this.editor = editor;
                // }}
                onChange={(event) => {
                  this.props.updateState({ supplierComment: event.target.value });
                }}
                placeholder = {this.props.intl.formatMessage({
                      id: "webRfq.comment.placeholder",
                    })}
                // config={{
                //   ...CUSTOM_CONFIGURATION,
                //   placeholder: this.props.intl.formatMessage({
                //     id: "webRfq.comment.placeholder",
                //   }),
                // }}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="product-amount-details">
              <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title">
                      <FormattedMessage
                        id="supplier.purchaseOrder.form.subTotal.label"
                        defaultMessage=""
                      />
                    </span>
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {fixedDecimalAndFormateNumber(
                      this.state.subtotalWithoutDiscount
                    )}
                  </Col>
                </Row>
              </div>

              {this.state.totalDiscount === "0.00" ||
              this.state.totalDiscount === 0 ? null : (
                <div className="amount-summary">
                  <Row>
                    <Col span={15}>
                      <span className="title">
                        <FormattedMessage
                          id="supplier.purchaseOrder.form.totalDiscount.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>
                    <Col span={9}>
                      -
                      {(this.props.companyInfo || {}).currencyIcon ? (
                        <i className={this.props?.companyInfo?.currencyIcon}/>
                      ) : (
                        this.props?.companyInfo?.currencyCode
                      )}
                      {this.state.totalDiscount}
                    </Col>
                  </Row>
                </div>
              )}

              {/* {
                                false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                                    <div className="w-50">
                                        <span className="title">Tax</span>
                                    </div>
                                    <div className="w-50-default">
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {this.state.totalTax}
                                    </div>
                                </div>
                            } */}

              {itemLevelTaxes.length === 0
                ? null
                : itemLevelTaxes.map((taxObj, i) => {
                    if (isPlaceOfSupplySameAsRelationship) {
                    }
                    return (this.props.companyInfo || {}).countryName ===
                      "India" ? (
                      isPlaceOfSupplySameAsRelationship ? (
                        <div key={i}>
                          <div className="amount-summary">
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  <FormattedMessage
                                    id="common.CGST"
                                    defaultMessage=""
                                  />{" "}
                                  {taxObj.taxPercent / 2}% on{" "}
                                  {Number(taxObj.subtotal).toFixed(2)}{" "}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode +
                                  " "
                                )}
                                {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                              </Col>
                            </Row>
                          </div>

                          <div className="amount-summary">
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  <FormattedMessage
                                    id="common.SGST"
                                    defaultMessage=""
                                  />{" "}
                                  {taxObj.taxPercent / 2}% on {taxObj.subtotal}{" "}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode +
                                  " "
                                )}
                                {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        <div key={i}>
                          <div className="amount-summary">
                            <Row>
                              <Col span={15}>
                                <span className="title">
                                  <FormattedMessage
                                    id="common.IGST"
                                    defaultMessage=""
                                  />{" "}
                                  {taxObj.taxPercent}% on{" "}
                                  {Number(taxObj.subtotal).toFixed(2)}{" "}
                                </span>
                              </Col>
                              <Col span={9}>
                                {(this.props.companyInfo || {}).currencyIcon ? (
                                  <i
                                    className={
                                      (this.props.companyInfo || {})
                                        .currencyIcon
                                    }
                                  ></i>
                                ) : (
                                  (this.props.companyInfo || {}).currencyCode +
                                  " "
                                )}
                                {Number(taxObj.taxAmount || 0).toFixed(2)}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )
                    ) : (
                      <div key={i}>
                        <div className="amount-summary">
                          <Row>
                            <Col span={15}>
                              <span className="title">
                                {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                                {Number(taxObj.subtotal).toFixed(2)}{" "}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {Number(taxObj.taxAmount || 0).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })}

              <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title">
                      <FormattedMessage
                        id="supplier.purchaseOrder.form.totalAmount.label"
                        defaultMessage=""
                      />
                    </span>
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {fixedDecimalAndFormateNumber(this.state.total)}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>

        <div className="contact-details" style={{ textAlign: "center" }}>
          {this.props.oldRfqDetailData.footer && (
            <>
              {ReactHtmlParser(this.props.oldRfqDetailData.footer)}
            </>
          )}
        </div>

        {/* <Row
          type="flex"
          className="action-button-group"
          style={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            width: "100%",
          }}
        >
          <Col span={3}>
            <Button
              className="grey-button"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <FormattedMessage id="button.cancel.label" defaultMessage="" />
            </Button>
          </Col>

          <Col span={2} offset={19}>
            <Button
              className="ant-btn-primary mr10"
              onClick={() => {
                submitResponse();
              }}
            >
              <FormattedMessage id="button.save.label" defaultMessage="" />
            </Button>
          </Col>
        </Row> */}
        <div className="cus-footer">
          <div className="left-btn">
            <Button
              className="grey-button"
              disabled={this.props.saveLoading}
              onClick={() => {
                this.props.popModalFromStack();
                this.props.history.goBack();
              }}
            >
              <FormattedMessage id="button.cancel.label" defaultMessage="" />
            </Button>
          </div>

          <div className="right-btn">
            <Button
              type="primary"
              className="mr10"
              disabled={this.props.saveLoading}
              loading={this.props.saveLoading}
              onClick={() => {
                submitResponse();
              }}
            >
              <FormattedMessage id="button.save.label" defaultMessage="" />
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(ProductDetails);
