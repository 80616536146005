import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesQuoteListingComponent from '../../../../components/customer/salesQuote/Listing';
import SalesQuoteFilterComponent from '../../../../components/customer/salesQuote/Listing/salesQuoteFilter';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { showModal, pushModalToStack, hideModal, popModalFromStack, fetchUserPreferences, saveUserPrefrences, deleteAuditTrail, createAuditTrail } from '../../../../actions/commonActions';
import { fetchSalesQuotes, getSalesQuoteCount, resetPaginatedData, fetchSalesQuoteDrafts, getSalesQuoteDraftCount, resetDraftPaginatedData, deleteSalesQuote, updateUserStatus, fetchSalesQuotesByFilter, getSalesQuotesCountByFilter } from './action';
import * as find from 'lodash.find';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
import { fetchCustomers } from '../../Listing/action';
import { fetchProjects, fetchAllEmployees } from '../../../../actions/commonActions';
import { fetchAllStatus } from '../../../modal/modalBody/common/UserStatus/action';
import { CONSTANTS_TRANSACTIONS } from '../../../../static/constants';
import { FormattedMessage } from 'react-intl';


class SalesQuoteListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      activeTab: props.activeTab,
      activeTabKey: props.activeTab === "Draft" ? '2' : '1', 
      txPreferences: { txType: "salesQuote", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['quotationNumber', 'customerName', 'salesQuoteDate'],
      txAvailableColumn: {
        quotationNumber: true,
        //rfqNumber: true,
        customerName: true,
        salesQuoteTotalAmount: true,
        // status: true,
        orderPriority: true,
        referenceNumber: true,
        salesQuoteDate: true,
        rfqExpirationDate: true,
        isInquiryConversion: true,
        remarksInternal: true,
        remarksCustomer: true,
        qtyOnSalesOrder: true,
        userStatus: true,
        salesPersonFullName: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        project: true
      },
      selectedAmountTerm: '=',
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.sales.salesQuote' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
          name: <FormattedMessage id='common.sales' defaultMessage='' />,
      },
      {
          name: <FormattedMessage id='salesQuote.text' defaultMessage='' />,
      }
    ]})

    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
      transactionName: CONSTANTS_TRANSACTIONS.SALES_QUOTE
    };
    fetchPaginationDataIfNeeded('fetchSalesQuotes', 'sqList', this.props, payload);
    fetchDataIfNeeded('getSalesQuoteCount', 'salesQuoteCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    if (linkProps.activeTab === "Draft") {
      fetchDataIfNeeded('getSalesQuoteDraftCount', 'sqDraftCount', this.props, payload);
      fetchPaginationDataIfNeeded('fetchSalesQuoteDrafts', 'sqDraftList', this.props, payload);
    }
    this.props.fetchCustomers({...payload, isCompact: true});
    this.props.fetchProjects(payload);
    this.props.fetchAllEmployees(payload);
    this.props.fetchAllStatus(payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "salesQuote", templateName: "standard" });
      if (txPreferences && txPreferences.tablePreference) {
        let txColumns = ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [];
        txColumns = txColumns.length ? txColumns: this.state.txMandatoryColumns;
        const txColumnSetting = ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else  {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.salesQuoteFilterDrawerVisible && <SalesQuoteFilterComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <SalesQuoteListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
      
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    sqList: state.sq.sqList,
    salesQuoteCount: state.sq.salesQuoteCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    sqDraftList: state.sq.sqDraftList,
    sqDraftCount: state.sq.sqDraftCount,
    customers: state.customer.customers,
    projectList: state.common.projectList,
    salesPersonList: state.common.employees,
    userStatus: state.common.userStatus,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSalesQuotes,
  getSalesQuoteCount,
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
  deleteSalesQuote,
  createAuditTrail,
  deleteAuditTrail,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchSalesQuoteDrafts, 
  getSalesQuoteDraftCount, 
  resetDraftPaginatedData,
  updateUserStatus,
  fetchCustomers,
  fetchAllEmployees,
  fetchProjects,
  fetchSalesQuotesByFilter,
  getSalesQuotesCountByFilter,
  fetchAllStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesQuoteListing);
