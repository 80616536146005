import React from "react";
import './logout.scss';
// import socialLinks from './socialLinks';
// import CookiePolicy from "../../containers/modal/modalBody/CookiePolicy";

const Logout = (props) => {
    return (
        <div className="logout-container">
            <div className="center-view mrl30">
                <div className="top-header">
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                    <div className="title">
                        We weren't sure if you left, so we logged you out to keep your account secure.
                    </div>
                </div>

                <div className="content">
                    <p className="prl30">
                        It is recommended that you close your browser window in order to ensure that any
                        additional application specific session information is cleared from memory.
                    </p>
                </div>
            </div>

            <div className="footer" style={{justifyContent: 'center'}}>
                <div className="copy-right">
                    Copyright © 2023 Alpide Technologies LLC. All Rights Reserved.
                </div>

                {/* <div className="d-flex">
                    <ul className="footer-links">
                        <li onClick={() => {
                            props.showModal({
                                title: "Cookie Policy",
                                modalBody: <CookiePolicy {...props} />,
                                width: 500,
                                hideFooter: true,
                            })
                        }}>
                            <span>Cookie Policy</span>
                        </li>
                        <li>
                            <a href="http://thepromptsolutions.com/contact" rel="noreferrer noopener">
                                Contact
                            </a>
                        </li>
                        <li>
                            <a href="http://thepromptsolutions.com/" rel="noreferrer noopener">
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a href="http://thepromptsolutions.com/" rel="noreferrer noopener">
                                Terms of Service
                            </a>
                        </li>
                    </ul>

                    <ul className="social-links">
                        {
                            socialLinks.map((data,index) =>
                                <li key={index}>
                                    <a
                                        href={data.url}
                                        target='_blank'
                                        rel="noopener noreferrer"
                                        title={data.title}
                                    >
                                        <i className={`fa fa-${data.icon}`}/>
                                    </a>
                                </li>
                            )
                        }
                    </ul>
                </div> */}
            </div>
        </div>
    )
};

export default Logout;
