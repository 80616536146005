import React, {  useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";

import { ErrorMsg } from "../../general/ErrorMessage";
// import ProductDetailsTab from "./ProductDetailsTab";


import "@ant-design/compatible/assets/index.css";

import { Col, Row, DatePicker } from "antd";
import { getMomentDateForUI } from "../../../utils";
import { Dropdown } from "../../general/Dropdown";
import find from "lodash.find";
import { MODAL_TYPE } from "../../../static/constants";

const InvoiceDetails = (props) => {
  const format = "DD-MM-YYYY";
 
  const documentArr = [
    {
      docType: "Regular Invoice (Tax Invoice)",
      referCode: "RI",
    },
    {
      docType: "Credit Note",
      referCode: "C",
    },
    {
      docType: "Debit Note",
      referCode: "D",
    },
    {
      docType: "Bill of Supply",
      referCode: "BS",
    },
    {
      docType: "Bill of Entry",
      referCode: "BOE",
    },
    {
      docType: "Delivery Challan",
      referCode: "DC",
    },
    {
      docType: "Others",
      referCode: "OTH",
    },
  ];

  const docStatus =[{docStatus: "Original" , referCode:"O"}, {docStatus: "Revised" , referCode:"R"}]
  
  const transactionMode = [
    { trnMode: "Regular", referCode: "REG" },
    { trnMode: "Bill To - Ship To", referCode: "SHP" },
    { trnMode: "Bill From - Dispatch From", referCode: "DIS" },
    {
      trnMode: "Bill To - Ship To and Bill From - Dispatch From",
      referCode: "CMB",
    },
  ];
  const invoiceType = [
    {
      invType: "Regular B2B Invoices of registered counterparty",
      referCode: "B2B",
    },
    {
      invType:
        "Regular B2CL invoices (Interstate supplies to unregistered dealers or consumers>2.5 lakhs)",
      referCode: "B2CL",
    },
    {
      invType: "Other than B2CL (unregistered counterparty and consumers)",
      referCode: "B2CS",
    },
    {
      invType: "SEZ exports without payment",
      referCode: "SEWOP",
    },
    {
      invType: "SEZ Exports with payment",
      referCode: "SEWP",
    },
    {
      invType: "Deemed Exports",
      referCode: "DE",
    },
    {
      invType: "Export with payment of duty",
      referCode: "EXWP",
    },
    {
      invType: "Export without payment of duty",
      referCode: "EXWOP",
    },
    {
      invType: "Import of Goods",
      referCode: "IMG",
    },
    {
      invType: "Others",
      referCode: "OTH",
    },
  ];
  const [isValid, setIsValid] = useState(null);
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

  return (
    <div>
      <div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id={`My GSTIN (${props.companyInfo?.storeName})`} defaultMessage="" />
              <span className="required">*</span>
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.e_invoice.Supply"
                defaultMessage=""
              />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
            <Dropdown
                // style={{ width: "210px" }}
                items={props.userGstVal || []}
                valueKeyName="taxIdentificationNumber"
                optionKeyName="taxIdentificationNumber"
                value={(props.userGstin || {}).taxIdentificationNumber}
                placeholder={"GSTIN of User"}
                onSelect={(e) => {
                  const doc = find(props.userGstVal, { taxIdentificationNumber: e }) || {};
                  props.updateState({
                    userGstin: doc,
                  });
                }}
                canAddNew={true}
                onAddNewClick={(payload) => {
                 
                  const formData = {
                    
                      "isToEdit": false,
                      
                    
                  };
                  const { showModal, companyInfo } = props;
                  formData.relationshipId = companyInfo.relationshipId;
                  formData.isGstin = true;
                  formData.callback = function (taxIdentification) {
                    props.addRelationshipTaxIdentification(formData);
                    return;
                  };
                  const data = {
                    title: (
                      <FormattedMessage id="addItem.text.taxIdentification" />
                    ),
                    formData,
                    hideFooter: true,
                    modalData: {
                      modalType: MODAL_TYPE.NEW_TAX_IDENTIFICATION,
                      data: { formData },
                    },
                    handleSubmit: (formData = {}) => {
                      data.formData.submittedOnce = true;
                      props.hideModal(data);
                    },
                  };
                  showModal(data);
                }}
                allowClear={true}
                onClear={() => {
                    this.props.updateState({
                        supplierObj: undefined,
                        supplierName: undefined,
                    });
                }}
              />
             
               {/* {isValid === false && <p style={{ color: 'red' }}>Invalid GST Number</p>} */}
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.userGstin?.taxIdentificationNumber;
                }}
                message={"User GSTIN is required"}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"Supply Type"}
                type="text"
                maxLength={20}
                value={props.supplyType}
                onChange={(e) => {
                  props.updateState({
                    supplyType: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.supplyType;
                }}
                message={"Supply Type is required"}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.e_invoice.transaction"
                defaultMessage=""
              />
              <span className="required">*</span>
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.e_invoice.documentType"
                defaultMessage=""
              />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Nature of Transaction"}
                type="text"
                value={props.transactionNature}
                // onChange={(e) => {
                //   props.updateState({
                //     transactionNature: transactionNature ? "INTRA" : "INTER",
                //   });
                // }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.transactionNature;
                }}
                message={"Nature of Transaction is required"}
              />
            </Col>

            <Col span={12}>
              <Dropdown
                // style={{ width: "210px" }}
                items={documentArr || []}
                valueKeyName="docType"
                optionKeyName="referCode"
                value={(props.documentType || {}).docType}
                placeholder={"Document Type"}
                onSelect={(referCode) => {
                  const doc = find(documentArr, { referCode: referCode }) || {};
                  props.updateState({
                    documentType: doc,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.documentType;
                }}
                message={"Document Type is required"}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Place of Business" defaultMessage="" />
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage id="Invoice Type" defaultMessage="" />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={50}
                placeholder={"Place of Business"}
                type="text"
                value={props.placeOfBusiness}
                onChange={(e) => {
                  props.updateState({
                    placeOfBusiness: e.target.value,
                  });
                }}
              />
            </Col>

            <Col span={12}>
              <Dropdown
                // style={{ width: "210px" }}
                items={invoiceType || []}
                valueKeyName="invType"
                optionKeyName="referCode"
                value={(props.invoiceType || {}).invType}
                placeholder={"Invoice Type"}
                onSelect={(referCode) => {
                  const doc = find(invoiceType, { referCode: referCode }) || {};
                  props.updateState({
                    invoiceType: doc,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.e_invoice.transactionMode"
                defaultMessage=""
              />
              <span className="required">*</span>
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="drawer.e_invoice.documentNumber"
                defaultMessage=""
              />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Dropdown
                // style={{ width: "210px" }}
                items={transactionMode || []}
                valueKeyName="trnMode"
                optionKeyName="referCode"
                value={(props.transactionMode || {}).trnMode}
                placeholder={"Transaction Mode"}
                onSelect={(referCode) => {
                  const doc =
                    find(transactionMode, { referCode: referCode }) || {};
                  props.updateState({
                    transactionMode: doc,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.transactionMode;
                }}
                message={"Transaction Mode is required"}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"Document Number"}
                type="text"
                maxLength={20}
                value={props.documentNumber}
                onChange={(e) => {
                  props.updateState({
                    documentNumber: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.documentNumber;
                }}
                message={"Document Type is required"}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Document Date" defaultMessage="" />
              <span className="required">*</span>
            </Col>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Place of Supply" defaultMessage="" />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DatePicker
                getPopupContainer={(trigger) => trigger.parentNode}
                format={format}
                // key={`${
                //   props.documentDate
                //     ? getMomentDateForUI({
                //         date: new Date(props.documentDate),
                //         format,
                //       })
                //     : null
                // }`}
                defaultValue={
                  props.documentDate
                    ? getMomentDateForUI({
                        date: new Date(props.documentDate),
                        format,
                      })
                    : null
                }
                onChange={(selectedDate) => {
             
                 
                  props.updateState({
                    documentDate: getMomentDateForUI({date: selectedDate , format:format}),
                  });
                }}
                placeholder={"Select Document Date"}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.documentDate;
                }}
                message={"Document Date is required"}
              />
            </Col>
            <Col span={12}>
              <TextBox
                maxLength={2}
                placeholder={"Place of Supply"}
                type="text"
                value={props.placeOfSupply}
                onChange={(e) => {
                  props.updateState({
                    placeOfSupply: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="mt20">
        <div className="drawer-heading">Other Invoice Details</div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Tax Scheme" defaultMessage="" />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Tax Scheme"}
                type="text"
                value={props.taxScheme}
                onChange={(e) => {
                  props.updateState({
                    taxScheme: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.taxScheme;
                }}
                message={"Tax Scheme is required"}
              />
            </Col>
          </Row>
        </div>
      </div>

      {props.optionalFields ? (
        <div className="mt20">
          <div className="drawer-heading">Optional Details</div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage id="Document Status" defaultMessage="" />
              </Col>

              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="Reference Invoice Number"
                  defaultMessage=""
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
              <Dropdown
                // style={{ width: "210px" }}
                items={docStatus || []}
                valueKeyName="docStatus"
                optionKeyName="referCode"
                value={(props.documentStatus || {}).docStatus}
                placeholder={"Document Type"}
                onSelect={(referCode) => {
                  const doc = find(docStatus, { referCode: referCode }) || {};
                  props.updateState({
                    documentStatus: doc,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
              </Col>

              <Col span={12}>
                <TextBox
                  placeholder={"Invoice Number"}
                  type="text"
                  maxLength={20}
                  value={props.referenceInvoiceNo}
                  onChange={(e) => {
                    props.updateState({
                      referenceInvoiceNo: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="Reference Invoice Date"
                  defaultMessage=""
                />
              </Col>

              <Col span={12} className="drawer-label">
                <FormattedMessage id="Reverse Charge" defaultMessage="" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <DatePicker
                  getPopupContainer={(trigger) => trigger.parentNode}
                  format={format}
                  // key={`${
                  //   props.referenceInvoiceDate
                  //     ? getMomentDateForUI({
                  //         date: new Date(props.referenceInvoiceDate),
                  //         format,
                  //       })
                  //     : null
                  // }`}
                  defaultValue={
                    props.referenceInvoiceDate
                      ? getMomentDateForUI({
                          date: new Date(props.referenceInvoiceDate),
                          format,
                        })
                      : null
                  }
                  onChange={(selectedDate) => {
                    const day = selectedDate
                      .getUTCDate()
                      .toString()
                      .padStart(2, "0");
                    const month = (selectedDate.getUTCMonth() + 1)
                      .toString()
                      .padStart(2, "0"); // Adding 1 since months are zero-based
                    const year = selectedDate.getUTCFullYear();

                    const formattedDate = `${day}-${month}-${year}`;
                    props.updateState({
                      referenceInvoiceDate: formattedDate,
                    });
                  }}
                  placeholder={"Select Invoice Date"}
                />
              </Col>

              <Col span={12}>
              <Dropdown
                // style={{ width: "210px" }}
                items={["Yess" ,"No"]}
                // valueKeyName="docType"
                // optionKeyName="referCode"
                value={(props.reverseCharge )}
                placeholder={"Reverse Charge"}
                onSelect={(referCode) => {
                 
                  props.updateState({
                    documentType: referCode,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
               
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="Differential Percentage"
                  defaultMessage=""
                />
              </Col>

              <Col span={12} className="drawer-label">
                <FormattedMessage id="E-commerce GSTIN" defaultMessage="" />
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <TextBox
                  maxLength={20}
                  placeholder={"Differential Percentage"}
                  type="text"
                  value={props.differentialPer}
                  onChange={(e) => {
                    props.updateState({
                      differentialPer: e.target.value,
                    });
                  }}
                />
              </Col>

              <Col span={12}>
                <TextBox
                  placeholder={"E-commerce GSTIN"}
                  type="text"
                  maxLength={20}
                  value={props.EcommGSTIN}
                  onChange={(e) => {
                    props.updateState({
                      EcommGSTIN: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[16, 8]}>
              <Col span={12} className="drawer-label">
                <FormattedMessage
                  id="IGST Applicability despite Supplier and Recipient located in same State/UT"
                  defaultMessage=""
                />
              </Col>

             
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
              <Dropdown
                
                items={["Yess","No"]}
               
                value={props.IGSTApplicability_recipient}
                placeholder={"IGST Apllicability"}
                onSelect={(referCode) => {
                 
                  props.updateState({
                    IGSTApplicability_recipient: referCode,
                  });
                }}
                
              />
              </Col>

             
            </Row>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default injectIntl(InvoiceDetails);
