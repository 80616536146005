import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import PageBreadcrumb from "../../../PageBreadcrumb";
import { Button, Card, Col, Row } from "antd";
import "./index.scss";
import HeaderFaviconComponent from "./compoents/HeaderFaviconComponent";
import Banners from "./compoents/Banners";
 
const CustomizeThemes = (props) => {
  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.eStores" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.appearance" defaultMessage="" />
      ),
    },

    {
      name: (
        <FormattedMessage
          id="sidebar.menuItem.appearance.themes"
          defaultMessage=""
        />
      ),
    },
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.customize" defaultMessage="" />
      ),
    },
  ];
  const [selectedButton, setSelectedButton] = useState("Header & Favicon");
  const renderRightComponent = () => {
    switch (selectedButton) {
      case "Header & Favicon":
        return <HeaderFaviconComponent {...props}/>;
      case "Banners":
        return <Banners {...props} />;
      case "Sections":
        return <h1>Sections</h1>;
      case "Fonts & Colors":
        return <h1>Fonts & Colors</h1>;
      case "Advanced":
        return <h1>Advanced</h1>;
      case "Homepage":
        return <h1>homepage</h1>;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.customize"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right"></div>
      </div>

      <div className="view-container">
        <div className="customize-main-content">
          <Row gutter={16}>
            <Col span={4} style={
              {borderRight:"1px solid #E7E6E5"}

            }>
              <Card className="left-card">
                <Button onClick={() => setSelectedButton("Header & Favicon")}>
                  Header & Favicon
                </Button>
                {/* <Button onClick={() => setSelectedButton("Banners")}>
                  Banners
                </Button>
                <Button onClick={() => setSelectedButton("Sections")}>
                  Sections
                </Button>
                <Button onClick={() => setSelectedButton("Fonts & Colors")}>
                  Fonts & Colors
                </Button>
                <Button onClick={() => setSelectedButton("Advanced")}>
                  Advanced
                </Button>
                <Button onClick={() => setSelectedButton("Homepage")}>
                  Homepage
                </Button> */}
              </Card>
            </Col>
            <Col span={20} style={{height: 'calc(100vh - 122px)', overflow: 'auto', paddingBottom: '8px' }}>{renderRightComponent()}</Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomizeThemes;
