import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OpeningBalanceComponent from '../../../components/finance/OpeningBalance';
import { fetchAllLedgerAccounts, updateOpeningBalance } from '../LedgerAccounts/action';
import { fetchDataIfNeeded } from '../../../utils';

class OpeningBalanceComp extends Component {

    constructor(props){
        super();
        this.state={
            searchedText: ''
        }
    }

    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: false
        });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId
        };
        fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, payload);
    }



    render() {
        return <OpeningBalanceComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}/>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        allLedgerAccounts: state.ledger.allLedgerAccounts
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllLedgerAccounts,
    updateOpeningBalance
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OpeningBalanceComp);
