import React from 'react';
// import { AgGridReact } from 'ag-grid-react';
import { Tooltip, Pagination, Skeleton } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS } from '../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../../../utils';
import SalesInvoiceDetail from '../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';


const UnpaidInvoicesComponent = (props) => {
    const { unpaidInvoiceList, unpaidInvoiceListCount, companyInfo, updateState, customerData, pageNumber } = props;
    let totalPayment = 0;

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    // const getRowHeight = () =>{
    //     return 28;
    // }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        if (customerData && customerData.customerId) {
            payload.customerId = customerData.customerId;
        }
        // props.resetPaginatedData();
        props.fetchUnpaidInvoices(payload,props);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }


    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo,relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        if (customerData && customerData.customerId) {
            payload.customerId = customerData.customerId;
        }
        props.fetchUnpaidInvoices(payload,props);
    }

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    const openInvoiceDetails = (data) => {
        const invoicePayload = {
            customerId: data.customerId,
            invoiceMasterId: data.invoiceMasterId
        }
        props.pushModalToStack({
            modalBody: <SalesInvoiceDetail {...props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.unPaidInvoice.header.invoiceNumber' defaultMessage='' />;
    //         },
    //         field: "invoiceNumber",
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openInvoiceDetails(link.data)
    //         }}>{link.value}</div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.unPaidInvoice.header.invoiceDate' defaultMessage='' />;
    //         },
    //         field: "invoiceDate",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
    //                     {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.unPaidInvoice.header.amount' defaultMessage='' /> </div>;
    //         },
    //         field: "invoiceTotalAmount",
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
    //         },
    //         valueFormatter: (data) => {
    //             data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    //             return data.value;
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.unPaidInvoice.header.paymentAmount' defaultMessage='' /> </div>;
    //         },
    //         field: "paymentAmount",
    //         cellRendererFramework: (params) => {
    //             let totalPayment = 0;
    //             if (params.data && (params.data.customerPaymentList || []).length) {
    //                 params.data.customerPaymentList.map((e) => {
    //                     totalPayment += e.paymentAmount;
    //                     return fixedDecimalNumber(totalPayment)
    //                 })

    //             }
    //             return <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(fixedDecimalNumber(totalPayment)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span>;
    //         },
    //         resizable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.unPaidInvoice.header.dueAmount' defaultMessage='' /> </div>;
    //         },
    //         field: "invoiceDueAmount",
    //         resizable: true,
    //         cellRendererFramework: (params) => {
    //             return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
    //         },
    //         valueFormatter: (data) => {
    //             data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    //             return data.value;
    //         },
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },

    //     //{
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='customer.listing.unPaidInvoice.header.invoiceDueDate' defaultMessage='' />;
    //     //     },
    //     //     field: "invoiceDueDate",
    //     //     cellRendererFramework: (link) => {
    //     //         return <div>
    //     //             <Tooltip placement="topLeft" title={(link.value && moment(link.value).format(CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT))}>
    //     //             {(link.value && moment(link.value).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
    //     //             </Tooltip>
    //     //         </div>
    //     //     },
    //     //     resizable: true,
    //     // },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='customer.listing.unPaidInvoice.header.paymentTerm' defaultMessage=' />;
    //     //     },
    //     //     field: "paymentTermName",
    //     //     resizable: true,
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "status",
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span> </div>
    //         },
    //         resizable: true,
    //         cellClass: 'status-cell'
    //     }
    // ];

    return (
        // <div className='agGridWrapper'>
        //     <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
        //         <AgGridReact
        //             columnDefs={columnDefs}
        //             rowData={unpaidInvoiceList[pageNumber] || []}
        //             rowDragManaged={true}
        //             domLayout={"autoHeight"}
        //             animateRows={true}
        //             onColumnResized={(params) => {
        //                 params.api.resetRowHeights();
        //             }}
        //             defaultColDef={{
        //                 flex: 1,
        //                 autoHeight: true,
        //                 wrapText: true,
        //             }}
        //             //getRowHeight={getRowHeight}
        //             onGridSizeChanged={onFirstDataRendered}
        //         >
        //         </AgGridReact>
        //     </div>

        // </div>
        <>
            <Skeleton loading={props.modalLoading} paragraph={{ rows: 8 }} active>
                <div className="table-container" style={{ height: "480px" }}>

                    <table id='customer-table'>
                        <thead>
                            <tr>
                                <th><FormattedMessage id='customer.listing.unPaidInvoice.header.invoiceNumber' defaultMessage='' /></th>
                                <th><FormattedMessage id="customer.listing.unPaidInvoice.header.invoiceDate" defaultMessage="" /></th>
                                <th><FormattedMessage id="customer.listing.unPaidInvoice.header.amount" defaultMessage="" /></th>
                                <th><FormattedMessage id='customer.listing.unPaidInvoice.header.paymentAmount' defaultMessage='' /></th>
                                <th><FormattedMessage id='customer.listing.unPaidInvoice.header.dueAmount' defaultMessage='' /></th>
                                <th><FormattedMessage id='common.status' defaultMessage='' /></th>

                            </tr>
                        </thead>
                        <tbody>
                            {(unpaidInvoiceList[pageNumber] || []).length ? (unpaidInvoiceList[pageNumber] || []).map((rowData, i) => {
                                return <tr key={i}>
                                    <td>
                                        <div className="cursor-pointer" onClick={() => {
                                            openInvoiceDetails(rowData)
                                        }}>
                                            {rowData.invoiceNumber}
                                        </div>
                                    </td>
                                    <td>
                                        {(rowData.invoiceDate && getMomentDateForUIReadOnly({ date: rowData.salesOrderDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                    </td>

                                    <td>

                                        {/* {rowData.invoiceTotalAmount} */}


                                        <span>   {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(rowData.invoiceTotalAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span>

                                    </td>
                                    <td>
                                    <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{(fixedDecimalNumber(rowData.totalPaymentReceived)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span>
                                    </td>
                                    <td>
                                        <span> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " " }{(Number(rowData.invoiceTotalAmount) - Number(rowData.totalPaymentReceived)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span>

                                    </td>
                                    <td>
                                        {rowData.status ? <span className="status-code" style={{ background: rowData.userStatusColor }}>
                                            <FormattedMessage id={rowData.status || ''} defaultMessage="" />
                                        </span> : ''}

                                    </td>
                                </tr>
                            }) : 'No data'}

                        </tbody>
                    </table>
                </div>
            </Skeleton>
                {!props.customerId && !props.customerData?.customerId? (

                <div style={{ padding: "20px" }}>

                    <Pagination
                        size="small"
                        total={unpaidInvoiceListCount}
                        showTotal={showTotal}
                        defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber, pageSize, props)
                        }}
                    />
                </div>
                     ) : (
          null 
        )}
        </>
    );
};

export default injectIntl(UnpaidInvoicesComponent);
