import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WarningDrawer from '../../../../../components/drawer/company/EmployeeManagement/Warning';

class WarningDrawerCon extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
      }

    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};

    }


    render() {
        return <WarningDrawer {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        drawerLoading: state.common.drawerLoading,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    // showModal,
    // hideModal, 
    // pushModalToStack,
    // popModalFromStack,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WarningDrawerCon);