import { Button, Col, Row, Tabs } from "antd";
import React, { useEffect } from "react";
import { ExpandOutlined } from "@ant-design/icons";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION_FULL_PAGE } from "../../../../../static/ckEditorConfigConstants";
import "../../../../eStore/storeSettings/store-settings.scss";
import { useState } from "react";
import PolicyPopup from "./PolicyPopup";
import { FormattedMessage } from "react-intl";

const PoliciesComp = (props) => {
   const data = props.policyPageData
  const { TabPane } = Tabs;
  const [tab ,setTab] = useState(1)
  


  const postPolicyPageData = () => {
   
    let payload ={
      relationshipId:props.relationshipId,
      
      isPolicy:1
    }
    switch (tab) {
      case 1:
        payload.pageTitle = "Privacy Policy";
        payload.pageContent = props.privacy;
        payload.ecomHeaderPageId = data?.privacy.ecomHeaderPageId;
        payload.policyType = "privacy";
        break;
    
      case 2:
        payload.pageTitle = "Refund Policy";
        payload.pageContent = props.refund;
        payload.ecomHeaderPageId = data?.refund.ecomHeaderPageId;
        payload.policyType = "refund";
        break;
    
      case 3:
        payload.pageTitle = "Terms & Conditions";
        payload.pageContent = props.tnc;
        payload.ecomHeaderPageId = data?.tnc?.ecomHeaderPageId;
        payload.policyType = "tnc";
        break;
    
      case 4:
        payload.pageTitle = "Cookies Policy";
        payload.pageContent = props.cookies;
        payload.ecomHeaderPageId = data?.cookies.ecomHeaderPageId;
        payload.policyType = "cookies";
         break;

         default :
              break;
    }
    const modalData ={
      modalBody: (
        <FormattedMessage
          id="customer.salesQuote.edit.form.confirmation"
          defaultMessage=""
        />
      ),
      handleSubmit: () => {
        props.hideModal()
        props.postPagePolicyData({ecomHeaderPage:payload})
       
      }
     }
     props.showModal(modalData)
   
  };
  const handleOnChange =(key)=>{
    setTab(Number(key))
  
  }
  const openNewSi = (heading, data) => {
    props.pushModalToStack({
    title:  <div className="model-head">
    <div>
      <p className="heading"><b>{heading}</b></p>
      <p>
        Customize your privacy policy to ensure customer trust and
        legal compliance.
      </p>
    </div>
  </div>,
      modalBody: <PolicyPopup  data={data} />,
      width: 1000,
      // hideTitle: true,
      hideFooter: true,
      // wrapClassName: "new-transaction-wrapper",
    });
  };

  return (
    <div className="store-model-container cus-space">
      <div className="store-main-container">
      <Tabs type="line" onChange={handleOnChange} animated={false}>
        <TabPane tab="Privacy Policy" key="1">
          <div>
            <Row >
              <Col span={24}>
                <div
                  className="model-head"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p className="heading">Privacy Policy</p>
                    <p>
                      Customize your privacy policy to ensure customer trust and
                      legal compliance.
                    </p>
                  </div>
                  <div>
                    <Button
                      type="text"
                      style={{ border: "none" }}
                      onClick={() => {
                        openNewSi("Privacy Policy", props.privacy);
                      }}
                    >
                      <ExpandOutlined />
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            {
             
               ( 
              <Row >
                <Col span={24}>
                  <div className="customEditor">
                   
                    <CKEditor
                      data={ props.privacy}
                      key={`${"emailBody"}`}
                      // onInit={editor => {
                      //     this.editor = editor;
                      // }}
                      onChange={(event) => {
                        const data = event.editor.getData();
                       
                        props.updateState({privacy:data})
                        
                      }}
                      config={{
                        ...CUSTOM_CONFIGURATION_FULL_PAGE,
                        placeholder: "Assignment Description",
                      }}
                    />
                  </div>
                </Col>
              </Row>
  )
            }
           
            
          </div>
        </TabPane>
        <TabPane tab="Refund Policy" key="2">
          <Row>
            <Col span={24}>
              <div
                className="model-head"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <p className="heading">Refund Policy</p>
                  <p>
                    Customize your privacy policy to ensure customer trust and
                    legal compliance.
                  </p>
                </div>
                <div>
                  <Button
                    type="text"
                    style={{ border: "none" }}
                    onClick={() => {
                      openNewSi("Refund Policy", props.refund);
                    }}
                  >
                    <ExpandOutlined />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="customEditor">
                <CKEditor
                  data={ props.refund}
                  key={`${"emailBody"}`}
                  // onInit={editor => {
                  //     this.editor = editor;
                  // }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    props.updateState({refund:data})
                  
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION_FULL_PAGE,
                    placeholder: "Assignment Description",
                  }}
                />
              </div>
            </Col>
          </Row>

          
        </TabPane>

        <TabPane tab="Terms & Conditions" key="3">
          <Row>
            <Col span={24} >
              <div
                className="model-head"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <p className="heading">Terms & conditions</p>
                  <p>
                    Customize your privacy policy to ensure customer trust and
                    legal compliance.
                  </p>
                </div>
                <div>
                  <Button
                    type="text"
                    style={{ border: "none" }}
                    onClick={() => {
                      openNewSi("Terms & condition", props.tnc);
                    }}
                  >
                    <ExpandOutlined />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="customEditor">
                <CKEditor
                  data={ props.tnc}
                  key={`${"emailBody"}`}
                  // onInit={editor => {
                  //     this.editor = editor;
                  // }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                   props.updateState({tnc:data})
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION_FULL_PAGE,
                    placeholder: "Assignment Description",
                  }}
                />
              </div>
            </Col>
          </Row>

          
        </TabPane>
        <TabPane tab="Cookies Policy" key="4">
          <Row>
            <Col span={24}>
              <div
                className="model-head"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <p className="heading">Cookies Policy</p>
                  <p>
                    Customize your privacy policy to ensure customer trust and
                    legal compliance.
                  </p>
                </div>
                <div>
                  <Button
                    type="text"
                    style={{ border: "none" }}
                    onClick={() => {
                      openNewSi("Cookies Policy", props.cookies);
                    }}
                  >
                    <ExpandOutlined />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="customEditor">
                <CKEditor
                  data={ props.cookies}
                  key={`${"emailBody"}`}
                  // onInit={editor => {
                  //     this.editor = editor;
                  // }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                   props.updateState({cookies:data})
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION_FULL_PAGE,
                    placeholder: "Assignment Description",
                  }}
                />
              </div>
            </Col>
          </Row>

          
        </TabPane>
      </Tabs>
      </div>
      <div className="footer-btn" >
        <Button type="primary" onClick={()=>postPolicyPageData()}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default PoliciesComp;
