import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UpdateParentInfo from '../../../../../components/modal/modalBody/common/UpdateParentInfo';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { createStatus, fetchAllStatus, deleteStatus, resetUserStatusList } from './action';

class UserStatusContainer extends Component {
  // componentDidMount() {
  //   const companyInfo = this.props.companyInfo || {};
  //   const payload = {
  //     relationshipId: companyInfo.relationshipId,
  //     transactionName: this.props.transactionName,
  //   };
  //   this.props.resetUserStatusList();
  //   fetchDataIfNeeded('fetchAllStatus', 'userStatus', this.props, payload);
  // }

  render() {
    return (
      <UpdateParentInfo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
   // userStatus: state.common.userStatus,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createStatus, 
  fetchAllStatus, 
  deleteStatus,
  resetUserStatusList,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserStatusContainer);
