import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Skeleton, Menu, Input, Empty } from "antd";
import { CONSTANTS,ICONS } from "../../../static/constants";
import DropdownAction from "antd/lib/dropdown";
import StandardLandedCost from "../../../containers/supplier/landedCost/StandardLandedCost";
import { getMomentDateForUIReadOnly } from "../../../utils";
import PageHeader from "../../Common/pageHeader";

const { Search } = Input;

const format = CONSTANTS.TABLE_DATE_FORMAT;
// const { RangePicker } = DatePicker;
// const { Panel } = Collapse

const LandedCostPageListingComp = (props) => {
  const { landedCostList, pageNumber } = props;

  const breadCrumbList = [
    {
      name: "Purchase",
    },
    {
      name: "Landed Cost",
    },
  ];
  const openLc = (e) => {
    let editPayload = {};
    if (e) {
      editPayload = {
        isEditLc: true,
        lcMasterId: e.lcMasterId,
        selectedSupplierName: e.supplierName,
        selectedSupplierId: e.supplierId,
        selectedTxn: e.isPIConversion
          ? "purchaseInvoice"
          : e.isIDConversion
          ? "grn"
          : "",
        selectedTxnId: e.isPIConversion
          ? e.invoiceMasterId
          : e.isIDConversion
          ? e.inboundDeliveryMasterId
          : "",
        lcDate: e.lcDate,
        lcDueDate: e.lcDueDate,
        lcNumber: e.lcNumber,
        docName: e.documentName,
        lcItemList: e.landedCostDetailList,
        exchangeRate: e.exchangeRate,
        lcSetupList: e.landedCostExpenseDetailList,
      };
    }
    props.pushModalToStack({
      modalBody: <StandardLandedCost {...props} {...editPayload} />,
      width: "100%",
      hideTitle: true,
      hideFooter: true,
      wrapClassName: "new-transaction-wrapper",
      alertCancel: true,
      onCancel: () => {
        props.showModal({
          modalBody: "All changes will be erased. Are you sure want to close?",
          footer:{submit:{intlId:"button.close.label"}},
          handleSubmit: () => {
            props.popModalFromStack();
          },
        });
      },
    });
  };
  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            openLc(e);
          }}
        >
          <i className={ICONS.EDIT} /> Edit
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            props.deleteLandedCostItems(e, props);
          }}
        >
          <i className="fi fi-rr-trash"></i>&nbsp; Delete
        </Menu.Item>
      </Menu>
    );
  };

  // const selectAll = (context) => {
  //   if (!context) {
  //     selectedItems.splice(0, selectedItems.length);
  //     props.updateState({ selectedItems: selectedItems });
  //     return;
  //   }
  //   for (let i = 0; i < landedCostList[pageNumber].length; i++) {
  //     let item = landedCostList[pageNumber][i];
  //     let selectItem = find(selectedItems, {
  //       invoiceMasterId: Number(item.invoiceMasterId),
  //     });
  //     if (!selectItem) {
  //       selectedItems.push(item);
  //     }
  //   }
  // };

  // const isCheckedAll = () => {
  //   if (
  //     !landedCostList ||
  //     !landedCostList[pageNumber] ||
  //     landedCostList[pageNumber].length === 0
  //   ) {
  //     return false;
  //   }
  //   for (let i = 0; i < landedCostList[pageNumber].length; i++) {
  //     let usr = landedCostList[pageNumber][i];
  //     let selectItem = find(selectedItems, {
  //       invoiceMasterId: Number(usr.invoiceMasterId),
  //     });
  //     if (!selectItem) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  // const checkMenu = (e) => {
  //   return (
  //     <Menu className="row-action-dropdown">
  //       <Menu.Item key="0" value="title">
  //         Actions
  //       </Menu.Item>

  //       <Menu.Divider />

  //       <Menu.Item
  //         key="1"
  //         onClick={() => {
  //           selectAll(true);
  //         }}
  //       >
  //         <i className="fi fi-rr-checkbox"></i>&nbsp;
  //         Select all
  //       </Menu.Item>

  //       <Menu.Item
  //         key="2"
  //         onClick={() => {
  //           selectAll(false);
  //         }}
  //       >
  //         <i className="fi fi-rr-cross-circle"></i>&nbsp;
  //         Unselect all
  //       </Menu.Item>

  //     </Menu>
  //   );
  // };

  return (
    <Fragment>
   
      <PageHeader {...props}
                pageName="sidebar.menuItem.landedCost"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={openLc}
            />
      <div className="view-container">
          <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} active paragraph={false}>
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="purchase.landindCostPage.table.heading"
                  defaultMessage=""
                />
              </div> 
                  <div className="vertical-sep" />
                  <div>{(landedCostList[pageNumber] || []).length}</div>           
            </div>
            <div className="right-actions">
              <Search placeholder="Enter" />
            </div>
            </Skeleton>
          </div>
          <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>

          <div className="table-container no-pagination">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  {/* <th>
                        <Checkbox/>&nbsp;
                        <DropdownAction overlay={checkMenu()} trigger={['click']}>
                          <i className="fi fi-rr-angle-down"></i>
                        </DropdownAction>
                      </th> */}
                  <th>
                    <FormattedMessage
                      id="purchase.landindCost.table.heading.Cost"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="purchase.landindCostPage.table.heading.supplier"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="purchase.landindCost.table.heading.createdDate"
                      defaultMessage=""
                    />
                  </th>
                  <th width="6%" className="text-center">
                    <FormattedMessage
                      id="purchase.landindCost.table.heading.action"
                      defaultMessage=""
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(landedCostList[pageNumber] || []).length
                  ? landedCostList[pageNumber]
                      .sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1))
                      .map((rowData, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          {/* <td><Checkbox/></td> */}
                          <td>{rowData.lcNumber}</td>
                          <td>{rowData.supplierName}</td>
                          <td>
                            {getMomentDateForUIReadOnly({
                              date: rowData.dateCreated,
                              format: format,
                            })}
                          </td>
                          <td className="text-center">
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className="fi fi-rr-menu-dots-vertical"></i>
                            </DropdownAction>
                          </td>
                        </tr>
                      ))
                  : <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>}
              </tbody>
            </table>
          </div>
        </Skeleton>
        {/* <div className="footer">

          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={purchaseInvoiceCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber, pageSize, props);
              }}
            />
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};

export default injectIntl(LandedCostPageListingComp);
