import React, { Fragment, useState } from 'react';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { Steps, Button, Row, Col, message } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';

import './index.scss';
import { MODAL_TYPE } from '../../../../../static/constants';
import { Dropdown } from '../../../../general/Dropdown';
import config from '../../../../../config/environmentConfig';
import { getLabelName } from '../../customerInfo';
import { showToasterMessage } from '../../../../../utils';
import Dragger from 'antd/lib/upload/Dragger';
import * as XLSX from "xlsx";
import frameImport from "../../../../../assets/images/Frame-import.png"
import successImg from "../../../../../assets/images/Framesuccess.png"
import failImg from "../../../../../assets/images/Groupfail.png"
const { Step } = Steps;


function ImportProducts(props) {
    const [dropdownValues, setDropdownValues] = useState([]);

    const { productCategories, productBrands, manufacturers, allWarehouses, variantAttributes, intl, companyInfo } = props;

    // const firstStep = () => {
    //     return (<div style={{height: '400px'}}>
    //         <Row className='mb20'>
    //             <Col span={8} className="text-right">
    //                 <FormattedMessage id='drawer.inventory.category' defaultMessage='' />
    //             </Col>

    //             <Col span={12}>
    //                 <Dropdown
    //                     items={productCategories}
    //                     valueKeyName='categoryName'
    //                     optionKeyName='inventoryItemCategoryId'
    //                     value={props.categoryName}
    //                     canAddNew={true}
    //                     canDelete={true}
    //                     deleteClickHandler={(payload) => {
    //                         deleteClickHandler(props, MODAL_TYPE.PRODUCT_CATEGORY, payload);
    //                     }}
    //                     onAddNewClick={(payload) => {
    //                         payload.formData = {
    //                             textBoxValue: payload.textEntered,
    //                             submittedOnce: false
    //                         }
    //                         handleNewItemAddition(props, payload, MODAL_TYPE.PRODUCT_CATEGORY)
    //                     }}
    //                     placeholder={intl.formatMessage({ id: 'drawer.inventory.category.placeholder', defaultMessage: '' })}
    //                     onSelect={(optionValue, elem) => {
    //                         let obj = productCategories.find(category => (category.inventoryItemCategoryId === optionValue)) || {};
    //                         props.updateState({
    //                             categoryName: obj.categoryName,
    //                         });
    //                     }}
    //                 />
    //             </Col>

    //         </Row>

    //         <Row className='mb20'>
    //             <Col span={8} className="text-right">
    //                 <FormattedMessage id='drawer.inventory.manufacturer' defaultMessage='' />
    //             </Col>

    //             <Col span={12}>
    //                 <Dropdown
    //                     items={manufacturers}
    //                     valueKeyName='manufacturerName'
    //                     optionKeyName='inventoryProductManufacturerId'
    //                     value={props.manufacturerName}
    //                     canAddNew={true}
    //                     canDelete={true}
    //                     deleteClickHandler={(payload) => {
    //                         deleteClickHandler(props, MODAL_TYPE.PRODUCT_MANUFACTURER, payload);
    //                     }}
    //                     onAddNewClick={(payload) => {
    //                         payload.formData = {
    //                             textBoxValue: payload.textEntered,
    //                             submittedOnce: false
    //                         }
    //                         handleNewItemAddition(props, payload, MODAL_TYPE.PRODUCT_MANUFACTURER)
    //                     }}
    //                     placeholder={intl.formatMessage({ id: 'drawer.inventory.manufacturer.placeholder', defaultMessage: '' })}
    //                     onSelect={(optionValue, elem) => {
    //                         let obj = manufacturers.find(manufacturer => (manufacturer.inventoryProductManufacturerId === optionValue)) || {};
    //                         props.updateState({
    //                             manufacturerName: obj.manufacturerName,
    //                         });
    //                     }}
    //                 />
    //             </Col>

    //         </Row>

    //         <Row className='mb20'>
    //             <Col span={8} className="text-right">
    //                 <FormattedMessage id='drawer.inventory.brand' defaultMessage='' />
    //             </Col>

    //             <Col span={12}>
    //                 <Dropdown
    //                     items={productBrands}
    //                     valueKeyName='brandName'
    //                     optionKeyName='itemBrandId'
    //                     value={props.brandName}
    //                     canAddNew={true}
    //                     canDelete={true}
    //                     deleteClickHandler={(payload) => {
    //                         deleteClickHandler(props, MODAL_TYPE.PRODUCT_BRAND, payload);
    //                     }}
    //                     onAddNewClick={(payload) => {
    //                         payload.formData = {
    //                             textBoxValue: payload.textEntered,
    //                             submittedOnce: false
    //                         }
    //                         handleNewItemAddition(props, payload, MODAL_TYPE.PRODUCT_BRAND)
    //                     }}
    //                     placeholder={intl.formatMessage({ id: 'drawer.inventory.brand.placeholder', defaultMessage: '' })}
    //                     onSelect={(optionValue, elem) => {
    //                         let obj = productBrands.find(brand => (brand.itemBrandId === optionValue)) || {};
    //                         props.updateState({
    //                             brandName: obj.brandName,
    //                         });
    //                     }}
    //                 />
    //             </Col>
    //         </Row>

    //         <Row className='mb20'>
    //             <Col span={8} className="text-right">
    //                 <FormattedMessage id='drawer.inventory.warehouse' defaultMessage='' />
    //             </Col>

    //             <Col span={12}>
    //                 <Dropdown
    //                     items={allWarehouses}
    //                     valueKeyName='warehouseName'
    //                     optionKeyName='warehouseName'
    //                     value={props.warehouseName}
    //                     placeholder={intl.formatMessage({ id: 'drawer.inventory.warehouse.placeholder', defaultMessage: '' })}
    //                     canAddNew={true}
    //                     onAddNewClick={(payload) => {
    //                         payload.formData = {
    //                             warehouseName: payload.textEntered
    //                         }
    //                         handleNewWarehouseAddition(props, payload, MODAL_TYPE.WAREHOUSE)
    //                     }}
    //                     onSelect={(optionValue, elem) => {
    //                         props.updateState({
    //                             warehouseName: optionValue,
    //                         });
    //                     }}
    //                 />
    //             </Col>
    //         </Row>

    //         <Row className='mb20'>
    //             <Col span={8} className="text-right">
    //                 <FormattedMessage id='drawer.inventory.attribute' defaultMessage='' />
    //             </Col>

    //             <Col span={12}>
    //                 <Dropdown
    //                     items={variantAttributes}
    //                     valueKeyName='attributeName'
    //                     optionKeyName='itemVariantAttributeId'
    //                     value={props.attributeName}
    //                     canAddNew={true}
    //                     canDelete={true}
    //                     deleteClickHandler={(payload) => {
    //                         deleteClickHandler(props, MODAL_TYPE.ATTRIBUTE, payload);
    //                     }}
    //                     onAddNewClick={(payload) => {
    //                         payload.formData = {
    //                             textBoxValue: payload.textEntered,
    //                             submittedOnce: false
    //                         }
    //                         handleNewItemAddition(props, payload, MODAL_TYPE.ATTRIBUTE)
    //                     }}
    //                     placeholder={intl.formatMessage({
    //                         id: 'drawer.inventory.attribute.placeholder', defaultMessage: ''
    //                     })}
    //                     onSelect={(optionValue, elem) => {
    //                         let obj = variantAttributes.find(attribute => (attribute.itemVariantAttributeId === optionValue)) || {};
    //                         props.updateState({
    //                             attributeName: obj.attributeName,
    //                         });
    //                     }}
    //                 />
    //             </Col>
    //         </Row>
    //         {/* <div>
    //             <FormattedMessage id='download' />  <sapn className="cursor-pointer" onClick={() => {
    //                 props.downloadFileFromBucket({
    //                     awsKeyName: 'SampleProductTemplate.xls',
    //                     bucketName: config.BUCKET_NAME.BO_SAMPLE_RESOURCES,
    //                     fileName: 'SampleProductTemplate.xls'
    //                 })
    //             }}><FormattedMessage id='sampleFile' /> </sapn>
    //             <FormattedMessage id='compare.sampleFile.message' defaultMessage='' />
    //         </div> */}
    //         <br/>


    //             <br/>
    //     </div>)
    // }


    const secondStep = () => {
        const propss = {
            name: 'file',
            multiple: false,
            action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
            onChange(info) {
                const { status } = info.file;
                if (status !== 'uploading') {
                    handleFileChange(info?.file?.originFileObj)
                }
                if (status === 'done') {
                    handleFileChange(info?.file?.originFileObj)

                } else if (status === 'error') {
                }
            },
            onDrop(e) {
                handleFileChange(e.dataTransfer.files)

            },
        };
        // const handleFileRead = (e) => {
        //     const content = e.target.result;
        //     const workbook = XLSX.read(content, { type: "binary" });

        //     const sheetName = workbook.SheetNames[0];
        //     const sheet = workbook.Sheets[sheetName];

        //     const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        //     props.updateState({
        //         fileHeader: jsonData[0],
        //     });
        //     const ans = jsonData[0]?.map((item) => {
        //         if (props.headerValues?.includes(item)) {
        //             return item;
        //         }
        //     });
        //     const val = ans.map((item,i)=>{
        //         if(item){
        //           return false
        //         }else{
        //           return true
        //         }
        //       })
        //       props.updateState({
        //           headerMandatoryValues:val
        //       })
        //     setDropdownValues(ans);
        // };
        const handleFileChange = (data) => {
            const file = data;
            props.updateState({
                fileToUpload: file,
            });
            if (file) {
              const reader = new FileReader();
          
              reader.onload = (e) => {
                const content = e.target.result;
                const rows = content.split('\n'); 
                const val = rows[0]?.replaceAll("\r","")
                const header =val.split(','); 
                const dataRows = rows.slice(1);
                props.updateState({
                    fileHeader: header,
                });
                const ans = header?.map((item) => {
                    if (props.headerValues?.includes(item)) {
                        return item;
                    }
                });
                const value = ans.map((item,i)=>{
                    if(item){
                      return false
                    }else{
                      return true
                    }
                  })
                  props.updateState({
                      headerMandatoryValues:value
                  })
                setDropdownValues(ans);

              };
          
              reader.readAsText(file);
            }
          };
        // const handleFileInputChange = (e) => {


        //     const file = e;
        //     props.updateState({
        //         fileToUpload: file,
        //     });
        //     if (file) {
        //         const reader = new FileReader();
        //         reader.onloadend = handleFileRead;
        //         reader.readAsBinaryString(file);
        //     }
        // };

        return (<>
            <div className='second-step'>
                <div className='secont-step-heding'>Upload File</div>

                <div className='second-step-content'>

                    <div className='right-item'>
                        <Dragger {...propss}>
                            <p className="ant-upload-drag-icon" style={{ fontSize: "24px" }}>
                                <InboxOutlined style={{ fontSize: "24px" }} />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                banned files.
                            </p>
                        </Dragger>
                    </div>
                    <div className="left-item">
                        <div className='secont-step-heding' >Download Sample File</div>
                        <img src={frameImport} alt="" />
                        <p>Download a sample XLS template to see an example of the format required.</p>
                        <div className='cursor-pointer'
                        onClick={()=>{
                            props.downloadExelSampleFile(props)
                        }}
                        //  href={`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/downloadInventoryItemTemplate?relationshipId=${companyInfo.relationshipId}&countryName=${companyInfo.countryName}`}
                         >
                            <i class="fi fi-rr-file-excel"></i>
                            <span className="ml5">Download Template</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }
    const thirdStep = () => {
        const handleChange = (index) => (selectedValue) => {
            const newDropdownValues = [...dropdownValues];
            newDropdownValues[index] = selectedValue;
            const val = [...props.headerMandatoryValues]
            val[index] = false
             props.updateState({
                headerMandatoryValues:val
             })  
            setDropdownValues(newDropdownValues);
        };

        return (
            <>
                 <div className='third-step'>
                    <div className='third-step-heading'>
                        <div>File Header</div>
                        <div>Mapping value</div>
                        <div>&nbsp;</div>
                    </div>
                    <ul className='third-step-content' style={{ listStyle: 'none' }}>

                        {props.fileHeader?.map((head, i) => (
                            <li
                                key={i}

                            >
                                <div className='content-value'>{head}</div>
                                <div
                                    className='content-value'
                                >
                                    <Dropdown
                                        items={props.headerValues || []}
                                        canDelete={true}
                                        onSelect={(selectedValue) => {handleChange(i)(selectedValue);}}
                                        value={dropdownValues[i] || ""}
                                        placeholder={"Select Fields"}
                                    />
                                </div>
                                <div className='content-value-error'>
                                  {props.headerMandatoryValues[i] && props.submitOnce  ?"Please select the value":""}
                                </div>
                            </li>
                        ))}

                    </ul>
                </div> 
                
            </>
        );
    };
    const fourthstep = () => {
        return (
            <>
                {props.isuploded && props.resData.SuccessRecords.length ?
                    <div className='error-page'>
                        <div className='part-1'>We've successfully uploaded a specific number of records. For any data with errors, you can download and re-upload them to ensure accuracy.</div>
                        <div className='part-2'>
                            <div className='error-page-img'>
                                <img src={successImg} alt="" />
                            </div>
                            <div className='error-info'>
                                <div className='heading-count'>{props.resData?.SuccessRecords?.length}</div>
                                <p>
                                    <b>Great news!</b> Out of the <b>{props.totaData - 1}</b>  entries you submitted, <b>{props.resData?.SuccessRecords?.length}</b> have been successfully uploaded. Congratulations on a job well done!
                                </p>
                            </div>
                        </div>
                      {props.resData.ErrorRecords.length ?  <div className='part-3'>
                            <div className='error-page-img'>
                                <img className='' src={failImg} alt="" />
                            </div>
                            <div className='error-info'>
                                <div className='heading-count'>{props.resData?.ErrorRecords?.length}</div>
                                <p>
                                    Out of <b>{props.totaData - 1}</b>  entries, {props.resData?.ErrorRecords?.length} require your attention. No problem! We've compiled the details of these entries for you to review and correct. Simply download the file, make the necessary adjustments, and re upload.
                                </p>
                            </div>
                            <div className='error-record-btn'>
                                <button onClick={() => {
                                    props.downloadExelFile({ errorDataList: props.resData?.ErrorRecords, relationshipId: props.companyInfo.relationshipId }, props)
                                }}>Download Error File</button>
                            </div>
                        </div>:""}

                    </div>
                     : <div className='error-page'>

                        <div className='part-3'>
                            <div className='error-page-img'>
                                <img className='' src={failImg} alt="" />
                            </div>
                            <div className='error-info'>
                                <div className='heading-count'>Opps!</div>
                                <p>
                                    It seems that there was an issue with the entire dataset. No worries, though! Please download the file provided, make the necessary corrections, and re-upload it to ensure accurate data.
                                </p>
                            </div>
                            <div className='error-record-btn'>
                                <button onClick={() => {
                                    props.downloadExelFile({ errorDataList: props.resData?.ErrorRecords, relationshipId: props.companyInfo.relationshipId }, props)
                                }}>Download Error File</button>
                            </div>
                        </div>

                    </div>}
            </>
        )
    }
    const steps = [
        // {
        //   title: "Setup Data for Import",
        //   content: firstStep(),
        // },
        {
            title: "Upload Products",
            description:"Upload Your Excel File",


            content: secondStep(),
        },
        {
            title: "Map Products Fields",
            description:"Map Your Fields",

            content: thirdStep(),
        },
        {
            title: "Review & Confirm",
            description:"Your Data, Your Way",

            content: fourthstep(),
        },
    ];


    const next = () => {
        const current = props.current + 1
        props.updateState({ current });
    }

    const prev = () => {
        const current = props.current - 1
        props.updateState({ current });
    }


    const handleNewWarehouseAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.warehouse' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.WAREHOUSE,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    const handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    const addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, hideModal } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.PRODUCT_CATEGORY: {
                addFunc = props.addCategory;
                break;
            }
            case MODAL_TYPE.PRODUCT_BRAND: {
                addFunc = props.addBrand;
                break;
            }
            case MODAL_TYPE.PRODUCT_MANUFACTURER: {
                addFunc = props.addManufacturer;
                break;
            }
            case MODAL_TYPE.ATTRIBUTE: {
                addFunc = props.addAttribute;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId });
                hideModal();
            },
        };
        showModal(modalData);
    }

    const getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.PRODUCT_CATEGORY: {
                title = <FormattedMessage id='addItem.text.productCategory' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.PRODUCT_BRAND: {
                title = <FormattedMessage id='addItem.text.productBrand' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.PRODUCT_MANUFACTURER: {
                title = <FormattedMessage id='addItem.text.productManufacturer' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.WAREHOUSE: {
                title = <FormattedMessage id='addItem.text.warehouse' defaultMessage='' />;
                break;
            }
            default:
                title = <Fragment />;
        }
        return title;
    }


    const deleteClickHandler = (props, modalType, payload) => {
        const { companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.PRODUCT_CATEGORY: {
                deleteFunc = props.deleteCategory;
                break;
            }
            case MODAL_TYPE.PRODUCT_BRAND: {
                deleteFunc = props.deleteBrand;
                break;
            }
            case MODAL_TYPE.PRODUCT_MANUFACTURER: {
                deleteFunc = props.deleteManufacturer;
                break;
            }
            case MODAL_TYPE.ATTRIBUTE: {
                deleteFunc = props.deleteAttribute;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ relationshipId: companyInfo.relationshipId, id: payload.id }) },
        };
        props.pushModalToStack(modalData);
    }


    return (
        <div className="template-stepbar-modal">
            <Steps current={props.current}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} description={item.description} />
                ))}
            </Steps>
            <div className="steps-content">{steps[props.current].content}</div>
            <div className="steps-action">

                {
                   props.current > 0 && (props.current < steps?.length - 1) ?(
                        <Button style={{ marginRight: 8 }} onClick={() => prev()}>
                            Previous
                        </Button>
                    ):""}
                    {props.current < steps?.length - 1 && (
    
                        <Button type="primary" onClick={() => {
                            if (props.fileToUpload) {
                                if (!(props.current === steps.length - 2)) {
    
                                    next(props)
                                }
    
                                if (props.current === steps?.length - 2) {
                                    if (!props.headerMandatoryValues.includes(true)) {
                                        const resultObject = props.fileHeader?.reduce(
                                            (acc, key, index) => {
                                                acc[`${key}`?.trim()] = dropdownValues[index];
                                                return acc;
                                            },
                                            {}
                                        );
                                      
                                        const mapedData = {
                                            relationshipId: companyInfo.relationshipId,
                                            mapping: resultObject,
                                        };
                                      const callback = ()=>{
                                        next(props)
                                      }
                                        props.importProducts(
                                            {
                                                mappedData: JSON.stringify(mapedData || ""),
                                                fileToUpload: props.fileToUpload,
                                            },
                                            props,
                                            callback
                                        );
                                    } 
                                    
                                else {
                                        props.updateState({
                                            submitOnce:true
                                        })
                                    }
                                }
    
                            } else {
                                showToasterMessage({
                                    messageType: "error",
                                    description: "Please select a file",
                                });
                            }
                            }}>
                            Next
                        </Button>
                    )}
                {props.current === steps?.length - 1 && (
                    <Button
                        type="primary"
                        onClick={() => {
                          props.popModalFromStack()
                        }}
                    >
                        Done
                    </Button>
                )}
            </div>
        </div>
    );
}

export default injectIntl(ImportProducts);
