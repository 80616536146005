import React, { Component } from 'react';
// import config from '../../../../config/environmentConfig';
import { FormattedMessage } from 'react-intl';
import { Input, Button } from 'antd';
import { showToasterMessage } from '../../../../utils';

import QRCode from 'qrcode.react';
import download from 'downloadjs';

export default class ShareTvURL extends Component {

  constructor(props) {
    super(props);
    this.state = {
      previewUrl: `https://alpide.com/app/payment-setup?t=${props.campaignRowData.relationshipId}_${props.campaignRowData.alpidePaymentGatewayId}_4_${props.campaignRowData.projectMasterId}_${props.campaignRowData.chartOfAccountDetailsId}_0_${props.campaignRowData.fundraisingCampaignId}`
    }
  }

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
    showToasterMessage({
      messageType: 'success', description: this.props.intl.formatMessage(
        {
          id: 'copyToClipboard.text',
          defaultMessage: ''
        }
      )
    });
  }

  render() {
    return (
      <div>
        <div> <FormattedMessage id='sharePaymentUrl.link.description' /></div>
        <br />
        <div>
          <Input style={{ width: '90%' }}
            ref={(textarea) => this.textArea = textarea}
            value={this.state.previewUrl}
          />
          <Button title='Copy Link' type="default" style={{ paddingLeft: '5px', paddingRight: '5px' }} onClick={() => this.copyCodeToClipboard()}>
            <i class="fa fa-clone"></i>
          </Button>
        </div>
        {/* <br />
        <br /> */}
        {/* <div className="align-footer" style={{ borderBottom: '1px solid #e5e5e5', textAlign: 'center' }}>
          <Button style={{ marginBottom: '10px' }} type="primary" onClick={() => {
            //window.location = this.state.previewUrl;
            // window.open(this.state.previewUrl, '_blank');
            Object.assign(document.createElement('a'), {
              target: '_blank',
              href: this.state.previewUrl,
            }).click();
          }}>
            <FormattedMessage id='preview.text' />
          </Button>
        </div> */}
        <br />
        <br />
        <div className="align-footer" style={{ textAlign: 'center' }}>
          <div className="HpQrcode">
            <QRCode value={this.state.previewUrl} renderAs="canvas" />
          </div>
          <br/>
          <br/>
          <div className="align-footer" style={{ borderTop: '1px solid #e5e5e5', textAlign: 'center' }}>
            <Button style={{ marginTop: '10px' }} type="primary" onClick={() => {
              const canvas = document.querySelector('.HpQrcode > canvas');
              download(canvas.toDataURL(), this.props.campaignRowData.campaignName +' Payment Link.png');
            }}>Download QR Code</Button>
          </div>
        </div>

      </div>
    )
  }
}
