import { FUNDRAISING_ACTION_LIST } from "../static/constants";

const initialState = {
    campaignList: {
        1: [],
    },
    allCampaignList: [],
    teamMasterList: [],
    teamLeadList: [],
    campaignListCount: 0,
    pledgeList: {
        1: [],
    },
    pledgeListCount: 0,
    nextCampaignCode : '',
    donationPaymentList: {
        1: [],
    },
    donationPaymentCount: 0,
    sponsorshipList: {
        1: [],
    },
    sponsorshipListCount: 0,
};

const FundraisingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FUNDRAISING_ACTION_LIST.CAMPAIGN_LIST:
            return {
                ...state,
                campaignList: {
                    ...state.campaignList,
                    [action.data.pageNo || 1]: action.data.list,
                },
            };
        case FUNDRAISING_ACTION_LIST.CAMPAIGN_LIST_COUNT:
            return {
                ...state,
                campaignListCount: action.data
            };

        case FUNDRAISING_ACTION_LIST.TEAM_MASTER_LIST:
            return {
                ...state,
                teamMasterList: action.data
            };

        case FUNDRAISING_ACTION_LIST.TEAM_LEAD_LIST:
            return {
                ...state,
                teamLeadList: action.data
            };

        case FUNDRAISING_ACTION_LIST.TEAM_LEAD_LIST_RESET:
            return {
                ...state,
                teamLeadList: initialState.teamLeadList
            };
        case FUNDRAISING_ACTION_LIST.TEAM_MASTER_LIST_RESET:
            return {
                ...state,
                teamMasterList: initialState.teamMasterList
            };
        case FUNDRAISING_ACTION_LIST.CAMPAIGN_LIST_PAGE_RESET:
            return {
                ...state,
                campaignList: initialState.campaignList
            };
        case FUNDRAISING_ACTION_LIST.ALL_CAMPAIGN_LIST:
            return {
                ...state,
                allCampaignList: action.data
            };

        case FUNDRAISING_ACTION_LIST.PLEDGE_LIST:
            return {
                ...state,
                pledgeList: {
                    ...state.pledgeList,
                    [action.data.pageNo || 1]: action.data.list,
                },
            };
        case FUNDRAISING_ACTION_LIST.PLEDGE_LIST_COUNT:
            return {
                ...state,
                pledgeListCount: action.data
            };
        case FUNDRAISING_ACTION_LIST.PLEDGE_LIST_PAGE_RESET:
            return {
                ...state,
                pledgeList: initialState.pledgeList
            };
        case FUNDRAISING_ACTION_LIST.RESET_ALL_CAMPAIGN_LIST:
            return {
                ...state,
                allCampaignList: initialState.pledgeList
            };
        case FUNDRAISING_ACTION_LIST.NEXT_CAMPAIGN_CODE:
            return {
                ...state,
                nextCampaignCode: action.data
            };
        case FUNDRAISING_ACTION_LIST.NEXT_CAMPAIGN_CODE_RESET:
            return {
                ...state,
                nextCampaignCode: initialState.nextCampaignCode
            };
        case FUNDRAISING_ACTION_LIST.DONATION_PAYMENT_LIST:
            return {
                ...state,
                donationPaymentList: {
                    ...state.donationPaymentList,
                    [action.data.pageNo || 1]: action.data.list
                }
            };
        case FUNDRAISING_ACTION_LIST.DONATION_PAYMENT_COUNT:
            return { ...state, donationPaymentCount: action.data };
        case FUNDRAISING_ACTION_LIST.DONATION_PAYMENT_LIST_PAGE_RESET:
            return { ...state, donationPaymentList: initialState.donationPaymentList };
        case FUNDRAISING_ACTION_LIST.SPONSORSHIP_LIST:
            return {
                ...state,
                sponsorshipList: {
                    ...state.sponsorshipList,
                    [action.data.pageNo || 1]: action.data.list,
                },
            };
        case FUNDRAISING_ACTION_LIST.SPONSORSHIP_LIST_COUNT:
            return {
                ...state,
                sponsorshipListCount: action.data
            };
        case FUNDRAISING_ACTION_LIST.SPONSORSHIP_LIST_PAGE_RESET:
            return {
                ...state,
                sponsorshipList: initialState.sponsorshipList
            };    
        default:
            return state;
    }
};

export default FundraisingReducer;
