import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, LEDGER_ACTION_LIST, LEDGER_TYPE, LOCAL_STORAGE_KEYS } from '../../../../../static/constants';
import { showToasterMessage, addToLocalStorage, fetchFromLocalStorage, fetchFromLocalMemory } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const updateBusinessData = (props) => {
    const payload = _getData(props);
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/updateBusinessData`, payload)
          .then(res => {
            showToasterMessage({messageType: "success", description: "Organization Info updated successfully."});
            const entityData = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
            entityData.defaultCompany = {
              ...entityData.defaultCompany,
              ...payload
            }
            addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(entityData));
            dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data: entityData.defaultCompany });
            
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }

  export const updateDefaultLedger = (props) => {
    const payload = getDefaultLedgerAccounts(props);
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios

      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/chartOfAccountDetails/saveDefaultCoaLedgerAccount`, payload)
      .then(res => {
        showToasterMessage({messageType: "success", description: "Ledger Info updated successfully."});
        const entityData = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
        
        entityData.defaultCompany = {
          ...entityData.defaultCompany,
          defaultCoaLedgerAccountsList:res.data ? res.data :entityData.defaultCompany?.defaultCoaLedgerAccountsList
        }
            addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(entityData));
            dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data: entityData.defaultCompany });
            //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }
  

  export const updateOrCreateEmployee = (props)=>{
    const payload = _getEmployeeData(props);
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relEmployee/createEmployee`, payload)
          .then(res => {
            showToasterMessage({messageType: "success", description: "Employee data updated successfully."});
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }


  export const updateBoLocation = (payload, props)=>{
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/createAddress`, payload)
          .then(res => {
            showToasterMessage({messageType: "success", description: "Address updated successfully."});
            props.getRelationshipAddress({relationshipId: (res.data.relationshipId || (props.companyInfo || {}).relationshipId)});
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }


  export const createCostCenter = (payload)=>{
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/costCenter/createCostCenter`, payload)
          .then(res => {
            showToasterMessage({messageType: "success", description: "Cost center updated successfully."});
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }


  export const createBankDetail = (payload, props)=>{
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankDetails/createBankDetail`, payload)
          .then(res => {
            showToasterMessage({messageType: "success", description: "Bank detail updated successfully."});
            const companyInfoData = props.companyInfoData;
            if(payload.bankDetailId){
              companyInfoData.bankDetails = companyInfoData.bankDetails.filter(x => x.bankDetailId !== payload.bankDetailId) 
            }
            companyInfoData.bankDetails.push(res.data);
            const userData = JSON.parse(fetchFromLocalStorage("logged_in_entity"));
            userData.defaultCompany = companyInfoData
            addToLocalStorage("logged_in_entity", JSON.stringify(userData));
            props.updateState({
              companyInfoData : {...companyInfoData}
            })
            
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }

  export const getRelationshipAddress = (payload)=>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/getRelationshipAddress?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.BO_RELATIONSHIP_ADDRESS, data: res.data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
  }

  export const resetRelationshipAddress = (payload)=>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.BO_RELATIONSHIP_ADDRESS_RESET });
    }
  }

  export const deleteBankDetail = (payload, props) =>{
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankDetails/deleteBankDetail`, payload)
          .then(res => {
            showToasterMessage({messageType: "success", description: "Bank detail updated successfully."});
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            const companyInfoData = props.companyInfoData;
            let bankList = companyInfoData.bankDetails.filter(x => x.bankDetailId !== payload.bankDetailId)
            companyInfoData.bankDetails = bankList;
            const userData = JSON.parse(fetchFromLocalStorage("logged_in_entity"));
            companyInfoData = {
              ...companyInfoData,
              couponSetting:JSON.stringify(userData.defaultCompany?.couponSetting),
              roundingOffSettings:JSON.stringify(userData.defaultCompany?.roundingOffSettings),
            }
            userData.defaultCompany = companyInfoData
            addToLocalStorage("logged_in_entity", JSON.stringify(userData));
            dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data: companyInfoData });
            props.updateState({
              companyInfoData : {...companyInfoData}
            })
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }

  

  export const deleteCostCenter = (payload)=>{
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/costCenter/deleteCostCenter`, payload)
          .then(res => {
            showToasterMessage({messageType: "success", description: "Cost center deleted successfully."});
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }


  export const deleteLocation = (payload, data)=>{
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boLocationRest/deleteAddress`, payload)
          .then(res => {
            showToasterMessage({messageType: "success", description: "Address deleted successfully."});
            dispatch({ type: COMMON_ACTIONS.BO_LOCATION_DELETION, data: res.data });
            if(data.callback){
              data.callback();
            }
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }
  


  export const deleteEmployee = (props) =>{
    const payload = {
        relationshipEmployeeId: props.relationshipEmployeeId,
        relationshipId: props.relationshipId
    };
    return dispatch => {
        dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
        return axios
          .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relEmployee/deleteEmployee`, payload)
          .then(res => {
            showToasterMessage({messageType: "success", description: "Employee data deleted successfully."});
            dispatch({ type: COMMON_ACTIONS.EMPLOYEES_LIST_DELETION, data: payload });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
      }
  }


  function _getEmployeeData(payload){
      return {
          version: payload.version,
          relationshipEmployeeId:payload.relationshipEmployeeId,
          firstName: payload.firstName,
          lastName: payload.lastName,
          salutationName: payload.salutationName,
          preferCallName: payload.preferCallName,
          workPhone: payload.workPhone,
          cellPhone: payload.cellPhone,
          faxNumber:payload.faxNumber,
          emailAddress: payload.emailAddress,
          isAccountHolder: payload.isAccountHolder,
          relationshipId:payload.relationshipId,
        }
  }

  

  function _getData(props){
      return {
          approxInventoryItems: props.companyInfoData.approxInventoryItems,
          avgMonthlySOorInvoices: props.companyInfoData.avgMonthlySOorInvoices,
          companySize: props.companyInfoData.companySize,
          howDidYouHear: props.companyInfoData.howDidYouHear,
          industryId: props.companyInfoData.industryId,
          storeName: props.companyInfoData.storeName,
          gstNumber: props.companyInfoData.gstNumber,
          panNumber: props.companyInfoData.panNumber,
          relationshipId: props.companyInfoData.relationshipId,
          vatNumber: props.companyInfoData.vatNumber,
          phoneNumber: props.companyInfoData.phoneNumber,
          faxNumber: props.companyInfoData.faxNumber,
          website: props.companyInfoData.website,
          decimalDigitsLengthPrice: props.companyInfoData.decimalDigitsLengthPrice,
          isUpdateInventoryOnSalesInvoice: props.companyInfoData.isUpdateInventoryOnSalesInvoice,
          isUpdateInventoryOnPurchaseInvoice: props.companyInfoData.isUpdateInventoryOnPurchaseInvoice,
          defaultPaymentGatewayId: props.companyInfoData.defaultPaymentGatewayId,
          defaultPaymentGatewayName: props.companyInfoData.defaultPaymentGatewayName,
          fromEmail: props.companyInfoData.fromEmail,
          onlineThemes: props.companyInfoData.onlineThemes ? JSON.stringify(props.companyInfoData.onlineThemes) : '',
          email: props.companyInfoData.email,
          decimalDigitsLengthQty: props.companyInfoData.decimalDigitsLengthQty,
          roundingOffSettings: props.companyInfoData.roundingOffSettings ? JSON.stringify(props.companyInfoData.roundingOffSettings) : '',
          isStopSalesInvoiceForOutOfStock: props.companyInfoData.isStopSalesInvoiceForOutOfStock,
          couponSetting: props.companyInfoData.couponSetting ? JSON.stringify(props.companyInfoData.couponSetting || "{}") :"",
        }
  }
  const getDefaultLedgerAccounts = (payload) => {
    const defaulltLedger = [];
    if (payload.defaultCoupon) {
        payload.defaultCoupon.txType = LEDGER_TYPE.TYPE_COUPON;
        payload.defaultCoupon.relationshipId = payload.companyInfo?.relationshipId;
        defaulltLedger.push(payload.defaultCoupon);

    } if (payload.defaultCreditorLedger) {
        payload.defaultCreditorLedger.txType = LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.CREDITORS
        payload.defaultCreditorLedger.relationshipId = payload.companyInfo?.relationshipId;
        defaulltLedger.push(payload.defaultCreditorLedger);

    } if (payload.defaultCustomerPayment) {
        payload.defaultCustomerPayment.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultCustomerPayment.txType = LEDGER_TYPE.TX_PAYMENT_RECIEVED;

        defaulltLedger.push(payload.defaultCustomerPayment);

    } if (payload.defaultDebtorsLedger) {
        payload.defaultDebtorsLedger.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultDebtorsLedger.txType = LEDGER_ACTION_LIST.DEBTORS;

        defaulltLedger.push(payload.defaultDebtorsLedger);

    } if (payload.defaultDiscountLedger) {
        payload.defaultDiscountLedger.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultDiscountLedger.txType = LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.DISCOUNT_RECIEVED;

        defaulltLedger.push(payload.defaultDiscountLedger);

    } if (payload.defaultExpenseLedger) {
        payload.defaultExpenseLedger.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultExpenseLedger.txType = LEDGER_ACTION_LIST.CUSTOMER_EXPENSE;

        defaulltLedger.push(payload.defaultExpenseLedger);

    } if (payload.defaultPurchaseLedger) {
        payload.defaultPurchaseLedger.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultPurchaseLedger.txType = LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASES;

        defaulltLedger.push(payload.defaultPurchaseLedger);

    } if (payload.defaultRoundingOff) {
        payload.defaultRoundingOff.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultRoundingOff.txType = LEDGER_TYPE.TYPE_ROUNDING_OFF;

        defaulltLedger.push(payload.defaultRoundingOff);

    } if (payload.defaultSalesDiscountLedger) {
        payload.defaultSalesDiscountLedger.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultSalesDiscountLedger.txType = LEDGER_ACTION_LIST.SALES_DISCOUNT;

        defaulltLedger.push(payload.defaultSalesDiscountLedger);
        
    } if (payload.defaultSalesLedger) {
        payload.defaultSalesLedger.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultSalesLedger.txType = LEDGER_ACTION_LIST.SALES;

        defaulltLedger.push(payload.defaultSalesLedger);

    } if (payload.defaultSalesOutputLedger) {
        payload.defaultSalesOutputLedger.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultSalesOutputLedger.txType =  LEDGER_ACTION_LIST.SALES_OUTPUT_TAX;




        defaulltLedger.push(payload.defaultSalesOutputLedger);

    } if (payload.defaultSupplierExpenseLedger) {
        payload.defaultSupplierExpenseLedger.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultSupplierExpenseLedger.txType = LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.SUPPLIER_EXPENSE;

        defaulltLedger.push(payload.defaultSupplierExpenseLedger);

    } if (payload.defaultSupplierPayment) {
        payload.defaultSupplierPayment.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultSupplierPayment.txType = LEDGER_TYPE.TX_PAYMENT_MADE;

        defaulltLedger.push(payload.defaultSupplierPayment);

    } if (payload.defaultTaxLedger) {
        payload.defaultTaxLedger.relationshipId = payload.companyInfo?.relationshipId;
        payload.defaultTaxLedger.txType = LEDGER_ACTION_LIST.DEFAULT_LEDGER_TYPE.PURCHASE_TAX;

        defaulltLedger.push(payload.defaultTaxLedger);

    }
    
    return defaulltLedger;
}

  export const getSubdomain = (payload, cb)=>{
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getSubdomain?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.SUBDOMAIN, data: res.data });
          cb && cb(res.data);
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
           cb && cb({});
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        }) .finally(() => {
          dispatch({
            type: COMMON_ACTIONS.HIDE_LIST_LOADER,
          });
        })
    }
  }

export const saveClientURL = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/saveClientURL`, payload)
      .then(res => {
        // showToasterMessage({ messageType: "success", description: "Saved successfully." });
        dispatch({ type: COMMON_ACTIONS.SUBDOMAIN, data: res.data });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const checkClientURLExist = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getRelationshipId?name=${payload.name}`)
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.IS_SUBDAMIAN_EXIST, data: true });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.IS_SUBDAMIAN_EXIST, data: false });
      })
  }
}

export const resetCheckClientURLExist = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.IS_SUBDAMIAN_EXIST, data: false });
  }
}

export const saveFromEmail = (props) => {
  let payload = {
        relationshipId: props.companyInfoData.relationshipId,
        fromEmail: props.companyInfoData.fromEmail
      }
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/saveFromEmail`, payload)
        .then(res => {
          showToasterMessage({messageType: "success", description: "Organization Info updated successfully."});
          const entityData = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
          entityData.defaultCompany = {
            ...entityData.defaultCompany,
            ...payload
          }
          addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(entityData));
          dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data: entityData.defaultCompany });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
}


export const saveEducationLabelInfo =(payload ,props)=>{
   const val ={
    labelConfig: JSON.stringify(payload||'{}')
   }
return dispatch => {
  dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  return axios
    .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/updateLabelConfig?relationshipId=${props.companyInfoData.relationshipId}`, val)
    .then(res => {
      showToasterMessage({messageType: "success", description: "Organization Info updated successfully."});
      // const entityData = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
      // entityData.defaultCompany = {
      //   ...entityData.defaultCompany,
      //   ...payload
      // }
      // addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(entityData));
      // dispatch({ type: COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO, data: entityData.defaultCompany });
      ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
    })
    .catch((err) => {
      ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
    })
}
}