/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Switch } from "antd";
import PageBreadcrumb from "../../PageBreadcrumb";
import { AgGridReact } from "ag-grid-react";
import AddCountry from "../../../containers/modal/modalBody/settings/taxes/AddCountry";

const TaxesComp = (props) => {
    const { marketplaceCountries } = props;



    const breadCrumbList = [
        {
            name: 'Dashboard',
            link: 'dashboard'
        }, {
            name: 'Settings',
            link: '/admin/show-settings'
        },
        {
            name: 'Taxes',

        }
    ];
    const updateTaxSetting = (data, isTaxIncludedInPrice) => {

        const modalBody = isTaxIncludedInPrice ? <FormattedMessage id='setting.marketplaceConfiguration.taxSetting.yesConfirm.popup' defaultMessage='' /> : <FormattedMessage id='setting.marketplaceConfiguration.taxSetting.noConfirm.popup' defaultMessage='' />;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                // updateState({
                //     selectedMarketPlaceCountry: { ...selectedMarketPlaceCountry, isTaxIncludedInPrice: val },
                // });
                props.updateTaxIncludeInPrice({ ...data, isTaxIncludedInPrice: isTaxIncludedInPrice ? 1 : 0, relationshipId: props.companyInfo.relationshipId }, props);
                props.hideModal();
            },
        };
        props.showModal(modalData);
    };

    const switchTaxRate = (data, isAutomatic) => {

        const modalBody = isAutomatic ? <FormattedMessage id='setting.marketplaceConfiguration.taxes.switch.automatic.popup.msg' defaultMessage='' /> : <FormattedMessage id='setting.marketplaceConfiguration.taxes.switch.manual.popup.msg' defaultMessage='' />;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                // updateState({
                //     selectedMarketPlaceCountry: { ...selectedMarketPlaceCountry, isTaxIncludedInPrice: val },
                // });
                props.saveUpdateMarketplaceTaxCountry({ ...data, isAutomatic: isAutomatic ? 1 : 0, relationshipId: props.companyInfo.relationshipId }, props);
                props.hideModal();
            },
        };
        props.showModal(modalData);
    };

    const deleteHandle = (marketplaceCountry) => {
        const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.deleteMarketplaceCountry(marketplaceCountry, props);
            },
        };
        props.showModal(modalData);
    };

    const agGridStyle = {
        height: "100%",
        width: "100%",
    };

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return (
                    <FormattedMessage
                        id="setting.marketplaceConfiguration.taxes.listing.header.countryName"
                        defaultMessage=""
                    />
                );
            },
            field: "countryName",
            resizable: true,
            minWidth: 110,
            maxWidth: 400,
        },
        {
            headerComponentFramework: () => {
                return (
                    <div>
                        <FormattedMessage
                            id="setting.marketplaceConfiguration.taxes.listing.header.isPartOfSellingprice"
                            defaultMessage=""
                        />
                    </div>
                );
            },
            cellRendererFramework: (link) => {
                return <Switch
                    //checkedChildren="Yes"
                    //unCheckedChildren="No"
                    defaultChecked={link.data.isTaxIncludedInPrice}
                    checked={link.data.isTaxIncludedInPrice}
                    onChange={(change) => {
                        updateTaxSetting(link.data, change);
                    }} />

            },
            field: "isTaxIncludedInPrice",
            resizable: true,
            minWidth: 124,
            maxWidth: 300,
        },
        {
            headerComponentFramework: () => {
                return (
                    <FormattedMessage
                        id="setting.marketplaceConfiguration.taxes.listing.header.taxRate"
                        defaultMessage=""
                    />
                );
            },
            cellRendererFramework: (link) => {
                return <Switch
                    //checkedChildren="Automated"
                    //unCheckedChildren="Manual"
                    checked={link.data.isAutomatic}
                    onChange={(change) => {
                        switchTaxRate(link.data, change);
                    }} />

            },
            field: "isAutomatic",
            minWidth: 70,
            maxWidth: 300,
        },
        {
            headerComponentFramework: () => {
                return (
                    <FormattedMessage
                        id="setting.marketplaceConfiguration.taxes.listing.header.totalStates"
                        defaultMessage=""
                    />
                );
            },
            cellRendererFramework: (link) => {
                return link.data.totalTaxableStates ? link.data.totalTaxableStates : 0;
            },
            field: "totalTaxableStates",
            minWidth: 70,
            maxWidth: 300,
            suppressMovable: true,
        },
        {
            headerComponentFramework: () => {
                return (
                    <div>
                        <FormattedMessage
                            id="setting.marketplaceConfiguration.taxes.listing.header.minRate"
                            defaultMessage=""
                        />{" "}
                    </div>
                );
            },
            cellRendererFramework: (link) => {
                return (link.data.minTaxRate && link.data.minTaxRate > 0) ? `${link.data.minTaxRate} (${link.data.minTaxRateState})` : '-';

            },
            field: "minTaxRate",
            resizable: true,
            minWidth: 110,
            maxWidth: 300,
        },
        {
            headerComponentFramework: () => {
                return (
                    <div>
                        <FormattedMessage
                            id="setting.marketplaceConfiguration.taxes.listing.header.maxRate"
                            defaultMessage=""
                        />
                    </div>
                );
            },
            cellRendererFramework: (link) => {
                return (link.data.maxTaxRate && link.data.maxTaxRate > 0) ? `${link.data.maxTaxRate} (${link.data.maxTaxRateState})` : '-';

            },
            field: "maxTaxRate",
            resizable: true,
            minWidth: 124,
            maxWidth: 300,
        },
        {
            field: "action",
            headerComponentFramework: () => {
                return <div className="cursor-pointer">

                </div>;
            },
            colId: "action_1",
            pinned: "right",
            lockPosition: true,
            cellRendererFramework: (params) => {
                return <div>
                    <span style={{ marginRight: '10px' }} className="cursor-pointer"
                        onClick={
                            () => {
                                props.history.push({
                                    pathname: '/admin/settings/taxes/states',
                                    state: {
                                        marketplaceTaxCountryId: params.data.marketplaceTaxCountryId,
                                        countryId: params.data.countryId,
                                        countryName: params.data.countryName,
                                        isTaxAutomated: params.data.isAutomatic,
                                        update: true
                                    }
                                })
                            }}>
                        <FormattedMessage
                            id="setting.marketplaceConfiguration.taxes.listing.header.configureStates"
                            defaultMessage=""
                        />
                    </span>
                    |
                    <span style={{ marginLeft: '10px', color: "red" }} className="cursor-pointer"
                        onClick={
                            () => {
                                deleteHandle(params.data);
                            }}>
                        <FormattedMessage
                            id="commons.listing.delete.tooltip.msg"
                            defaultMessage=""
                        />
                    </span>
                </div>;
            },
            minWidth: 300,
            maxWidth: 300,
            suppressMovable: true,
        }
    ];

    return (
        <Fragment>
            <div className="view-top-bar">
                <div className="top-view-left">
                    <div className="page-heading">
                        Account Settings
                    </div>
                    <div className="vertical-sep" />
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div>
            </div>
            <div className="ledger-details-container">
                {/* <div className="ptb10 pl20">
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div> */}


                {/* <div className="ledger-details">
                    <div>
                        <Dropdown
                            className={(props.submittedOnce && !props.type) ? 'input-text-error' : ''}
                            items={marketplaceCountries}
                            valueKeyName='countryName'
                            optionKeyName='marketplaceTaxCountryId'
                            value={selectedMarketPlaceCountry.marketplaceTaxCountryId}
                            // placeholder={props.intl.formatMessage(
                            //     {
                            //         id: 'membership.membershipOption.addDrawer.maxFamilyMembers',
                            //         defaultMessage: ''
                            //     })
                            // }
                            onChange={
                                (optionValue, option) => {
                                    props.updateState({
                                        selectedMarketPlaceCountry: marketplaceCountries.find((element) => {
                                            return element.marketplaceTaxCountryId === option.value;
                                        })
                                    })
                                }
                            }
                        />
                    </div>
                    <FormattedMessage id='setting.marketplaceConfiguration.taxSetting.radioGroupMsg' defaultMessage='' />
                    <Radio.Group
                        onChange={(e) => {
                            updateTaxSetting(e.target.value);
                        }}
                        value={selectedMarketPlaceCountry.isTaxIncludedInPrice}
                        defaultValue={selectedMarketPlaceCountry.isTaxIncludedInPrice}
                    >
                        <Radio value={1} className=" pt10 ">
                            <FormattedMessage id='setting.marketplaceConfiguration.taxSetting.radio1' defaultMessage='' />
                        </Radio>
                        <br></br>
                        <Radio value={0} className=" pt10 ">
                            <FormattedMessage id='setting.marketplaceConfiguration.taxSetting.radio2' defaultMessage='' />
                        </Radio>
                        <br></br>
                    </Radio.Group>
                </div> */}
                <div className="view-container sales-order-details mt0">
                    <div className='team-and-free'>
                        <div className="right-align pb10">
                            <div className="action-dropdown-btn">
                                <button onClick={() => {
                                    props.showModal({
                                        title: <FormattedMessage id='setting.marketplaceConfiguration.taxes.model.name' defaultMessage='' />,
                                        modalBody: <AddCountry {...props} />,
                                        width: 600,
                                        hideFooter: true,
                                        // wrapClassName: "manage-user-modal"
                                    })
                                }}>
                                    <i className='fa fa-plus' />
                                    <FormattedMessage id="setting.marketplaceConfiguration.taxes.listing.header.countryName" defaultMessage="" />
                                </button>
                            </div>
                        </div>
                        <div className="mb10">
                            {
                                marketplaceCountries && marketplaceCountries.length > 0 ?
                                    <span>You are collecting Tax in {marketplaceCountries.length} Countries. </span>
                                    : ''
                            }
                        </div>

                        <div className="agGridWrapper">
                            <div className="ag-theme-balham" style={agGridStyle}>
                                <AgGridReact
                                    // onGridReady={onGridReady}
                                    columnDefs={columnDefs}
                                    defaultColDef={{
                                        flex: 1,
                                        autoHeight: true,
                                        wrapText: true,
                                    }}
                                    rowData={marketplaceCountries || []}
                                    rowDragManaged={true}
                                    domLayout={"autoHeight"}
                                    //getRowHeight={getRowHeight}
                                    suppressDragLeaveHidesColumns={true}
                                    animateRows={true}

                                ></AgGridReact>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};


export default injectIntl(TaxesComp);
