import axios from 'axios';
import config from '../../../config/environmentConfig';
import { SALES_INVOICE_ACTION_LIST, COMMON_ACTIONS, CONSTANTS } from '../../../static/constants';
import { fixedDecimalNumber, getMomentDateForUIReadOnly, showToasterMessage } from '../../../utils';

const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const generateEInvoiceDocument = (payload) => {
  
    const obj =   generateEInvoiceData(payload)
   
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/gstin/generateIRN`, obj)
        .then(res => {
     
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        //   dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_LIST_PAGE_RESET });
        //   props.fetchSalesInvoices({ ...props, relationshipId: payload.relationshipId });
               if(res.data?.data.status === "FAILURE"){

                 (res.data?.data.errors||[]).map((item)=>{
                    return showToasterMessage({
                      description: item?.msg,
                      messageType: 'error'
                    })
                 })
               }
               else{
                showToasterMessage({
                  description: (res || {}).message || 'E-Invoice Created Successfully',
                  messageType: 'success'
                })

                payload.openEInvoiceDetails(payload , res.data?.data)
                payload.updateDrawerState({
                  salesE_InvoiceDrawerVisisble: false
              })
               }
       
          
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }
 



  const generateEInvoiceData = (payload)=>{
   
     
        return {
      relationshipId: payload.relationshipId,
      invoiceMasterId: payload?.salesInvoiceDetail?.invoiceMasterId,
        userGstin: payload.userGstin?.taxIdentificationNumber,
        supplyType: payload.supplyType === "Outward" ? 'O' :'I',
       ntr: payload.transactionNature,
       docType: payload.documentType?.referCode,
       trnTyp: payload.transactionMode?.referCode,
       
       no: payload.documentNumber,
       dt: getMomentDateForUIReadOnly({date: payload?.documentDate , format:"DD-MM-YYYY"}) ,
              
        genIrn: payload.generateIRN,
    genewb: payload.generateEWB?.referCode,
       bgstin: payload.buyerGSTIN?.taxIdentificationNumber,
       blglNm: payload.buyerName,
       bbnm: payload.buyerAddress,
       bloc: payload.buyerLocation,
       bstcd: payload.buyerStateCode,
       bpin: payload.buyerPincode,
       totinvval: fixedDecimalNumber(payload.finalInvoiceValue),
       tottxval: fixedDecimalNumber(payload.totalTaxableValue),
       totdisc: fixedDecimalNumber(payload.discountInvoiceValue),
       "totothchrg": payload.otherCharges,
   
    
    "totcsamt": payload.totalCESS,
    "totstcsamt": payload.totalStateCESS,
    "rndOffAmt": payload.roundOffAmount,
    "sec7act": payload.IGSTApplicability_recipient === "No" ? "N":"Y",
      //  num: payload.serialNumber,
      //  hsnCd: payload.hsnCode,
      //  unitPrice: payload.unitPrice,
      //  sval: payload.totalAmount,
      //  txval: payload.taxableValue,
      //  itmVal: payload.totalItemValue,
       taxSch:payload.taxScheme,

       sgstin: payload.supplierGstin?.taxIdentificationNumber,
       slglNm: payload.supplierName,
       sbnm: payload.supplierAddress,
       sloc: payload.supplierLocation,
       sstcd: payload.supplierStateCode,
       spin: payload.supplierPincode,
      
       pobCode: payload.placeOfBusiness,
       catg: payload.invoiceType?.referCode,
       pos: payload.placeOfSupply,
       tolglNm: payload.ShipLegalName,
       tobnm: payload.ShipAddress,
       toloc: payload.shipLocation,
       tostcd: payload.shipStateCode,
       topin:payload.shipPincode,
       totiamt: payload.totalIGST,
       totcamt: payload.totalCGST,
       totsamt: payload.totalSGST,
       qty: payload.quantity,
       irt: payload.IGSTRate,
       crt: payload.CGSTRate,
       srt: payload.IGSTRate,
       transId: payload.transporterID,
       subSplyTyp: payload.subTypeOfSupply,
       subSplyDes: payload.otherSupplyDesc,
       transMode: payload.modeOfTransportation,
       vehTyp: payload.vechileType,
       transDist: payload.transportationDistance,
       transDocNo: payload.transporterDocumentNo,
       transDocDate: payload.transporterDocumentDate,
       vehNo: payload.VechileNo,
       dst: payload.documentStatus?.referCode,
       rchrg: payload.reverseCharge === "No" ? "N" : "Y",
       strdNm: payload.supplierTradeName,
       sem: payload.supplierEmail,
       sph: payload.supplierPhone,
       btrdNm: payload.buyerTradeName,
       bem:payload.buyerEmail,
       bph: payload.buyerPhone,
       expduty: payload.exportDuty,
       itemList: payload.itemList,
      oinvtyp: payload?.originalInvoiceType?.referCode,

     
    }
  }