import React from "react";
import { injectIntl } from "react-intl";
import { Skeleton, Badge } from "antd";
import { Calendar } from "antd";

const CalenderView = (props) => {

  // const breadCrumbList = [
  //   {
  //     name: <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />,
  //   },
  //   {
  //     name: <FormattedMessage id="sidebar.menuItem.timeTable" defaultMessage="" />,
  //   },
  // ];


  const getListData = (value) => {
    let listData;
    switch (value.date()) {
      case 8:
        listData = [
          {
            type: 'warning',
            content: 'This is warning event.',
          },
          {
            type: 'success',
            content: 'This is usual event.',
          },
        ];
        break;
      case 10:
        listData = [
          {
            type: 'warning',
            content: 'This is warning event.',
          },
          {
            type: 'success',
            content: 'This is usual event.',
          },
          {
            type: 'error',
            content: 'This is error event.',
          },
        ];
        break;
      case 15:
        listData = [
          {
            type: 'warning',
            content: 'This is warning event',
          },
          {
            type: 'success',
            content: 'This is very long usual event......',
          },
          {
            type: 'error',
            content: 'This is error event 1.',
          },
          {
            type: 'error',
            content: 'This is error event 2.',
          },
          {
            type: 'error',
            content: 'This is error event 3.',
          },
          {
            type: 'error',
            content: 'This is error event 4.',
          },
        ];
        break;
      default:
    }
    return listData || [];
  };
  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };



  return (
    <>
        <Skeleton loading={props.isLoading}>
            {/* <div className="view-container-actions">
            <div className="left-actions">
                <div className="table-heading">
                <FormattedMessage
                    id="schoolManagement.timeTable.calenderView.heading"
                    defaultMessage=""
                />
                </div>

            </div>
            <div className="right-actions">
   
            </div>
            </div> */}
            <div  style={{height: 'calc(100vh - 94px)', overflow: 'auto'}}>
                <Calendar cellRender={cellRender}/>
            </div>
        </Skeleton>
    </>
  );
};

export default injectIntl(CalenderView);
