import { SUPPLIER_PAYMENT_ACTION_LIST } from '../static/constants';
const initialState = {
  supplierPaymentList: {
    1: []
  },
  supplierPaymentCount: 0,
  supplierPaymentDetail: {}
}

const SupplierPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER_PAYMENT_ACTION_LIST.SUPPLIER_PAYMENT_LIST:
      return {
        ...state,
        supplierPaymentList: {
          ...state.supplierPaymentList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SUPPLIER_PAYMENT_ACTION_LIST.SUPPLIER_PAYMENT_COUNT:
      return { ...state, supplierPaymentCount: action.data };
    case SUPPLIER_PAYMENT_ACTION_LIST.SUPPLIER_PAYMENT_LIST_PAGE_RESET:
      return { ...state, supplierPaymentList: initialState.supplierPaymentList };
    case SUPPLIER_PAYMENT_ACTION_LIST.SUPPLIER_PAYMENT_DETAIL:
      return { ...state, supplierPaymentDetail: action.data };
    case SUPPLIER_PAYMENT_ACTION_LIST.NEXT_SUPPLIER_PAYMENT_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case SUPPLIER_PAYMENT_ACTION_LIST.NEXT_SUPPLIER_PAYMENT_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };    
    default:
      return state;
  }
};

export default SupplierPaymentReducer;