import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import "./index.scss";
import {
  Row,
  Col,
  Select,
  Switch,
} from "antd";
import { TextBox } from "../../general/TextBox";
import { TextArea } from "../../general/TextAreaComponent";
import {
  capitalizeFirstLetter,
} from "../../../utils";
import { ErrorMsg } from "../../general/ErrorMessage";

const { Option } = Select;

const AdditionalFormDetails = (props) => {
  const {
    formSetting = {},
  } = props;

  const gridColStyle = {
    xs: {
      span: 24,
    },
    lg: {
      span: 12,
    },
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        {formSetting.familyDoctorNameDisplay ? (
          <>
            <Col {...gridColStyle}>
              <div className="i-label">
                <FormattedMessage id="familyDoctorName.text" />
                {formSetting.familyDoctorNameRequired ? (
                  <span className="required">*</span>
                ) : (
                  ""
                )}
              </div>
              <TextBox
                placeholder="Family Doctor Name"
                type="text"
                maxLength={45}
                value={props.familyDoctorName}
                onChange={(e) => {
                  props.updateState({
                    familyDoctorName: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return (
                    !props.submitClicked ||
                    !(
                      formSetting.familyDoctorNameDisplay &&
                      formSetting.familyDoctorNameRequired &&
                      !props.familyDoctorName
                    )
                  );
                }}
                message={props.intl.formatMessage({
                  id: "requiredField.missing.msg",
                  defaultMessage: "",
                })}
              />
            </Col>
            <Col {...gridColStyle}>
              <div className="i-label">
                <FormattedMessage id="familyDoctorPhone.text" />{" "}
                {formSetting.familyDoctorNameRequired ? (
                  <span className="required">*</span>
                ) : (
                  ""
                )}
              </div>

              <TextBox
                prefix={
                  props.companyInfo.countryCallingCode
                    ? `+${props.companyInfo.countryCallingCode}`
                    : "-"
                }
                placeholder="Enter Number"
                type="text"
                maxLength={10}
                value={props.familyDoctorPhNumber}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (
                    e.target.value === "" ||
                    re.test(e.target.value)
                  ) {
                    props.updateState({
                      familyDoctorPhNumber: e.target.value,
                    });
                  } else if (!props.familyDoctorPhNumber) {
                    props.updateState({ familyDoctorPhNumber: "" });
                  }
                }}
              />
              <ErrorMsg
                validator={() => {
                  return (
                    !props.submitClicked ||
                    !(
                      formSetting.familyDoctorNameDisplay &&
                      formSetting.familyDoctorNameRequired &&
                      !props.familyDoctorPhNumber
                    )
                  );
                }}
                message={props.intl.formatMessage({
                  id: "requiredField.missing.msg",
                  defaultMessage: "",
                })}
              />
            </Col>
          </>
        ) : (
          ""
        )}

        {formSetting.emergencyContactDisplay ? (
          <>
            <Col {...gridColStyle}>
              <div className="i-label">
                <FormattedMessage id="setting.student.registration.form.emergencyContactName" />{" "}
                {formSetting.emergencyContactRequired ? (
                  <span className="required">*</span>
                ) : (
                  ""
                )}
              </div>
              <TextBox
                placeholder="Enter Name"
                type="text"
                maxLength={45}
                value={props.emergencyContactName}
                onChange={(e) => {
                  props.updateState({
                    emergencyContactName: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return (
                    !props.submitClicked ||
                    !(
                      formSetting.emergencyContactDisplay &&
                      formSetting.emergencyContactRequired &&
                      !props.emergencyContactName
                    )
                  );
                }}
                message={props.intl.formatMessage({
                  id: "requiredField.missing.msg",
                  defaultMessage: "",
                })}
              />
            </Col>

            <Col {...gridColStyle}>
              <div className="i-label">
                <FormattedMessage id="setting.student.registration.form.emergencyPhone" />{" "}
                {formSetting.emergencyContactRequired ? 
                  <span className="required">*</span> : ""}
              </div>

              <TextBox
                prefix={
                  props.companyInfo.countryCallingCode
                    ? `+${props.companyInfo.countryCallingCode}`
                    : "-"
                }
                placeholder="Enter Number"
                type="text"
                maxLength={10}
                value={props.emergencyPhone}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (
                    e.target.value === "" ||
                    re.test(e.target.value)
                  ) {
                    props.updateState({
                      emergencyPhone: e.target.value,
                    });
                  } else if (!props.emergencyPhone) {
                    props.updateState({ emergencyPhone: "" });
                  }
                }}
              />

              <ErrorMsg
                validator={() => {
                  return (
                    !props.submitClicked ||
                    !(
                      formSetting.emergencyContactDisplay &&
                      formSetting.emergencyContactRequired &&
                      !props.emergencyPhone
                    )
                  );
                }}
                message={props.intl.formatMessage({
                  id: "requiredField.missing.msg",
                  defaultMessage: "",
                })}
              />
            </Col>
          </>
        ) : (
          ""
        )}

        {formSetting.askAddTeacher ? (
          <Col {...gridColStyle}>
            <div className="i-label">
              Are you Teacher?
            </div>
            <Switch
              checked={props.isTeacher === 1}
              onClick={(checked, e) => {
                props.updateState({ isTeacher: checked ? 1 : 0 });
              }}
            />
          </Col>
        ) : (
          ""
        )}

        {formSetting.askAddVolunteer ? (
          <Col {...gridColStyle}>
            <div className="i-label">
              Do you volunteer?
              {/* {props.askAddVolunteerRequired ? (
                          <span className="required">*</span>
                      ) : (
                          ""
                      )} */}
            </div>
            <Switch
              checked={props.isVolunteer === 1}
              onClick={(checked, e) => {
                props.updateState({
                  isVolunteer: checked ? 1 : 0,
                });
              }}
            />
          </Col>
        ) : (
          ""
        )}

        {formSetting.askFinanacialAssistance ? (
          <Col {...gridColStyle}>
            <div className="i-label">
              Need financial assistance?
              {/* {formSetting.askFinanacialAssistanceRequired ? (
                          <span className="required">*</span>
                      ) : (
                          ""
                      )} */}
            </div>
            <Switch
              checked={props.isFinancialAssistanceNeeded === 1}
              onClick={(checked, e) => {
                props.updateState({
                  isFinancialAssistanceNeeded: checked ? 1 : 0,
                });
              }}
            />
          </Col>
        ) : (
          ""
        )}

        {props.isFinancialAssistanceNeeded === 1 ? (
          <Col {...gridColStyle}>
            <div className="i-label">
              Reason for financial assistance{" "}
              <span className="required">*</span>
            </div>
            <TextArea
              onChange={(e) => {
                props.updateState({
                  financialAssistanceReason: e.target.value,
                });
              }}
              style={{
                maxWidth: "100%",
                width: "100%",
                padding: "3px 5px",
                maxHeight: "38px",
              }}
              value={props.financialAssistanceReason}
            />
            <ErrorMsg
              validator={() => {
                return (
                  !props.submitClicked ||
                  !(
                    formSetting.askFinanacialAssistance &&
                    !props.financialAssistanceReason
                  )
                );
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>
        ) : (
          ""
        )}

        {/* {formSetting.askOrgMember ? 
                  <Col {...gridColStyle}>
                      <div className="i-label">
                      Are you a member of {companyInfo.storeName}?
                      {formSetting.askOrgMemberRequired ? (
                          <span className="required">*</span>
                      ) : ''
                      }
                      </div>
                      <Select
                      style={{ width: "100%" }}
                      value={props.orgMember}
                      placeholder="Membership Type"
                      onChange={(val) => {
                          props.updateState({ orgMember: val });
                      }}
                      >
                      <Option key={"No"} value={"No"}>
                          No
                      </Option>
                      <Option
                          key={"Individual Membership"}
                          value={"Individual Membership"}
                      >
                          Individual Membership
                      </Option>
                      <Option
                          key={"Family Membership"}
                          value={"Family Membership"}
                      >
                          Family Membership
                      </Option>
                      <Option key={"Yes-Other"} value={"Yes-Other"}>
                          Yes-Other
                      </Option>
                      </Select>
                      <ErrorMsg
                      validator={() => { return !props.submitClicked || !(formSetting.askOrgMember && !props.orgMember) }}
                      message={props.intl.formatMessage(
                          {
                          id: 'requiredField.missing.msg',
                          defaultMessage: ''
                          })
                      }
                      />
                  </Col>
                  : 
                  ""
                  } */}
        {
        formSetting.collectOnlinePayment &&
         props.isAllowParentPaymentPlan && props.paymentPlan?.length ? (
          <Col {...gridColStyle}>
            <div className="i-label">
              <FormattedMessage id="select.paymentPlan" /><span className="required">*</span>
            </div>
            <Select
              style={{width: '100%'}}
              value={props.selectedPlanIndex}
              onChange={(val) => {
                let selectedPlan = props.paymentPlan[val];
                props.updateState({
                  selectedPlanIndex: val,
                  selectedPlan: selectedPlan,
                });
              }}
            >
              {(props.paymentPlan || []).map((plan, i) => {
                return (
                  // <Option key={i} value={i}>Pay in {plan.totalInstallment || 0} {plan.totalInstallment === 1 ? 'installment' : 'installments'}</Option>
                  <Option key={i} value={i}>
                    Pay{" "}
                    {capitalizeFirstLetter(
                      plan.period === "completeSession"
                        ? "One Time"
                        : plan.period
                    )}
                  </Option>
                );
              })}
            </Select>
            <ErrorMsg
              validator={() => {
                return (
                  !props.submitClicked ||
                  !(
                    props.isAllowParentPaymentPlan &&
                    !(props.selectedPlanIndex + 1)
                  )
                );
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

export default injectIntl(AdditionalFormDetails);
