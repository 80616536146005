import { SALES_INVOICE_ACTION_LIST } from '../static/constants';
const initialState = {
  salesInvoiceList: {
    1: []
  },
  salesInvoiceCount: 0,
  einvoiceList:{
    1: []
  },
  einvoiceCount: 0,
  proformaInvoiceList: {
    1: []
  },
  proformaInvoiceCount: 0,
  products: [],
  salesCustomerList: [],
  salesInvoiceListBySoId: [],
  salesInvoiceDetail: {},
  salesInvoiceDraftList: {
    1: []
  },
  salesInvoiceDraftCount: 0,
  availableCredits: 0,
  availableCreditList: [],
  recurringInvoiceSetup: {},
  recurringInvoiceList: {
    1: []
  },
  recurringInvoiceCount: 0,
  invoiceReminderSetup: {},
  invoiceReminderList: {
    1: []
  },
  invoiceReminderCount: 0,
  salesEInvoiceDetailList:{},
  invoiceListByMasterId: [],
  customerProformaList: [],
  invoiceProformaList: {},
  paymentData:{}
}

const SalesInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SALES_INVOICE_ACTION_LIST.E_INVOICE_LIST:
      return {
        ...state,
        einvoiceList: {
          ...state.einvoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SALES_INVOICE_ACTION_LIST.SALES_INVOICE_LIST:
      return {
        ...state,
        salesInvoiceList: {
          ...state.salesInvoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SALES_INVOICE_ACTION_LIST.SALES_INVOICE_COUNT:
      return { ...state, salesInvoiceCount: action.data };
     
    case SALES_INVOICE_ACTION_LIST.PROFORMA_INVOICE_LIST:
      const invoiceData = [];
      action.data.list.forEach((d)=>{
        let obj = {...d, ...JSON.parse(d.invoiceData),dateCreated:d.dateCreated,invoiceProformaId:d.invoiceProformaId}
        delete obj.invoiceData;
        invoiceData.push(obj);
      })
      return {
        ...state,
        proformaInvoiceList: {
          ...state.proformaInvoiceList,
          [action.data.pageNo || 1]: invoiceData
        }
      };
    case SALES_INVOICE_ACTION_LIST.PROFORMA_INVOICE_COUNT:
      return { ...state, proformaInvoiceCount: action.data };
    case SALES_INVOICE_ACTION_LIST.PRODUCT_LIST:
      return { ...state, products: action.data };
    case SALES_INVOICE_ACTION_LIST.CUSTOMER_LIST:
      return { ...state, salesCustomerList: action.data };
    case SALES_INVOICE_ACTION_LIST.SALES_INVOICE_LIST_PAGE_RESET:
      return { ...state, salesInvoiceList: initialState.salesInvoiceList };
    case SALES_INVOICE_ACTION_LIST.SALES_INVOICE_BY_SO_ID:
      return { ...state, salesInvoiceListBySoId: action.data };
    case SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DETAIL_RESET:
      return { ...state, salesInvoiceDetail: initialState.salesInvoiceDetail }
    case SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DETAIL:
      return { ...state, salesInvoiceDetail: action.data }
      case SALES_INVOICE_ACTION_LIST.SALES_E_INVOICE_DETAIL_RESET:
        return { ...state, salesEInvoiceDetailList: initialState.salesInvoiceDetail }
      case SALES_INVOICE_ACTION_LIST.SALES_E_INVOICE_DETAIL:
        return { ...state, salesEInvoiceDetailList: action.data }
    case SALES_INVOICE_ACTION_LIST.NEXT_SALES_INVOICE_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case SALES_INVOICE_ACTION_LIST.NEXT_SALES_INVOICE_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DRAFT_LIST:
      return {
        ...state,
        salesInvoiceDraftList: {
          ...state.salesInvoiceDraftList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DRAFT_COUNT:
      return { ...state, salesInvoiceDraftCount: action.data };
    case SALES_INVOICE_ACTION_LIST.SALES_INVOICE_DRAFT_LIST_PAGE_RESET:
      return { ...state, salesInvoiceDraftList: initialState.salesInvoiceDraftList };
    case SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDITS:
      return { ...state, availableCredits: action.data };
    case SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDITS_RESET:
      return { ...state, availableCredits: initialState.availableCredits };
    case SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDIT_LIST:
      return { ...state, availableCreditList: action.data };
    case SALES_INVOICE_ACTION_LIST.AVAILABLE_CREDIT_LIST_RESET:
      return { ...state, availableCreditList: initialState.availableCreditList };
    case SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_SETUP:
      return { ...state, recurringInvoiceSetup: action.data };
    case SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_SETUP_RESET:
      return { ...state, recurringInvoiceSetup: initialState.recurringInvoiceSetup };  
    case SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_LIST:
      return {
        ...state,
        recurringInvoiceList: {
          ...state.recurringInvoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_COUNT:
      return { ...state, recurringInvoiceCount: action.data };
    case SALES_INVOICE_ACTION_LIST.RECURRING_INVOICE_LIST_PAGE_RESET:
      return { ...state, recurringInvoiceList: initialState.recurringInvoiceList };

    case SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_SETUP:
      return { ...state, invoiceReminderSetup: action.data };
    case SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_SETUP_RESET:
      return { ...state, invoiceReminderSetup: initialState.invoiceReminderSetup };
    case SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_LIST:
      return {
        ...state,
        invoiceReminderList: {
          ...state.invoiceReminderList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_COUNT:
      return { ...state, invoiceReminderCount: action.data };
    case SALES_INVOICE_ACTION_LIST.INVOICE_REMINDER_LIST_PAGE_RESET:
      return { ...state, invoiceReminderList: initialState.invoiceReminderList };
    case SALES_INVOICE_ACTION_LIST.INVOICE_LIST_BY_MASTER_ID:
      return { ...state, invoiceListByMasterId: action.data };
    case SALES_INVOICE_ACTION_LIST.INVOICE_LIST_BY_MASTER_ID_RESET:
      return { ...state, invoiceListByMasterId: initialState.invoiceListByMasterId };
    case SALES_INVOICE_ACTION_LIST.CUSTOMER_PROFORMA_INVOICE_LIST:
      return { ...state, customerProformaList: action.data };
    case SALES_INVOICE_ACTION_LIST.INVOICE_PROFORMA_DETAILS:
      return { ...state, proformaDetails: action.data };
    case SALES_INVOICE_ACTION_LIST.INVOICE_PROFORMA_LIST:
      return { ...state, invoiceProformaList: action.data };
    case SALES_INVOICE_ACTION_LIST.POST_PAYMENT_DATA:
        return { ...state, paymentData: action.data };
      
    default:
      return state;
  }
};

export default SalesInvoiceReducer;