import React, { Fragment } from 'react';
import { Radio, Menu, Empty, Tooltip, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CONSTANTS, ICONS } from '../../../../../static/constants';
import NewsLetter from '../../../../../containers/NewsLetter';
import { getMomentDateForUIReadOnly, showToasterMessage } from '../../../../../utils';
import ReactHtmlParser from 'react-html-parser';
import ImagePreview from "../../../../../assets/images/img-placeholder-alpide.jpg";

function SelectTemplate(props) {
    const { selectedTemplate } = props


    const newFormBuilder = (data) => {

        props.pushModalToStack({
            modalBody: <NewsLetter {...props} templateType='marketingTemplate' alpideFormBuilderId={data?.alpideFormBuilderId} />,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            keyboard:false,
            wrapClassName: "emailerTemplate_Modal",
            onCancel: () => {
                props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    props.popModalFromStack();
                  }
                })
              }
        })
    };
   
    const selectTemplate = (item) => {       
        let isSelected = selectedTemplate && selectedTemplate.alpideFormBuilderId === Number(item.alpideFormBuilderId);    
        let updatedItem = isSelected ? {} : item;   
        props.updateState({ ...props, selectedTemplate: updatedItem ,alpideFormBuilderId:updatedItem.alpideFormBuilderId});
    };
    
    return <>

        <div className="view-container-actions">
            <div className="left-actions">
                <div className="table-heading">
                    <FormattedMessage id='emailMarketing.emailTemplate.table.heading' defaultMessage='' />
                </div>
               
            </div>
            <div className="right-actions">
                <button className="create-action-btn" onClick={() => { newFormBuilder(); }}> <i className={ICONS.ADD} /> &nbsp;Create Template</button>
            </div>
        </div>
        <div className="table-container">
            {/* <Radio.Group onChange={onChange} value={value}> */}
            <table>
                <thead>
                    <tr>
                        <th>Select</th>
                        {/* <th>Preview</th> */}
                        <th>Template Name</th>
                        <th>Date Created <i className={ICONS.SORT} /></th>
                        <th>Created By</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (props.newsletterTemplateList || []).length ? props.newsletterTemplateList.map((rowData, i) => {

                            return <tr key={i}>
                                <td>
                                    <Radio
                                        onClick={() => {
                                            selectTemplate(rowData);
                                        }}
                                        checked={selectedTemplate && selectedTemplate.alpideFormBuilderId === Number(rowData.alpideFormBuilderId)}
                                    />
                                </td>
                                {/* <td>
                                    <div
                                        onClick={() => {
                                            props.showModal({
                                                title:  'Preview Template Service',
                                                modalBody: ReactHtmlParser(rowData?.templateContent),
                                                width: "100%",
                                                height: "100%",
                                                hideFooter: true,
                                                hideTitle: true,
                                                wrapClassName: "image-preview",
                                            });
                                        }}
                                    >
                                        <Tooltip placement="topLeft" title="Preview Template">
                                            <img
                                                style={{ height: "40px", margin: "5px" }}
                                                src={ImagePreview}
                                                alt="image"
                                                className="image cursor-pointer"
                                            />
                                        </Tooltip>
                                    </div>
                                </td> */}
                                <td>
                                    <div className="link"  onClick={() => {
                                            props.showModal({
                                                title:  'Preview Template Service',
                                                modalBody: ReactHtmlParser(rowData?.templateContent),
                                                width: "100%",
                                                height: "100%",
                                                hideFooter: true,
                                                hideTitle: true,
                                                wrapClassName: "image-preview",
                                            });
                                        }}>
                                        {rowData.templateName}
                                    </div>
                                </td>
                                <td>{<Tooltip placement="topLeft" title={(rowData.dateCreated && getMomentDateForUIReadOnly({ date: rowData.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                    {(rowData.dateCreated && getMomentDateForUIReadOnly({ date: rowData.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                </Tooltip>}
                                </td>
                                <td>{props.userInfo?.firstName}</td>
                              
                            </tr>
                        }) :
                            <tr key="empty-data-box">
                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                    <Empty />
                                </td>
                            </tr>
                    }
                </tbody>
            </table>
            {/* </Radio.Group> */}
        </div>
        <div class="footer">
            <div class="left-fItems">
                {/* <Button>Cancel</Button> */}
                
           

            </div>
            <div class="right-fItems">
                <Button 
                    type="primary"
                    onClick={() => {
                    if(!selectedTemplate){
                        return showToasterMessage({ messageType: 'error', description: "Please Select Template" });
                    }
                    props.updateState({
                        isDisable: false,
                        activeTab: "2"
                    })
                }}>
                    Next
                </Button>
            </div>
        </div>
    </>
}

export default SelectTemplate;