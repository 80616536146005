import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProccessPaymnetComp from '../../../components/B2B/Donation/ProccessPaymnet';
import { getAuthNetPaymentUrl } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
//import queryString from 'query-string';

class ProccessPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      neverExpires: 1
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <Fragment>
        <ProccessPaymnetComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  getAuthNetPaymentUrl
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProccessPayment);
