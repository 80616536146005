import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import "./index.scss";
// import config from '../../config/environmentConfig';
import { injectIntl } from 'react-intl';
import Toplinks from './TopLinks';
import LogoShape from '../../assets/images/logo-shape.png';
import MobleLogo from "../../assets/images/phone-icons.png";
import TablatLogo from "../../assets/images/tablet-icons.png";
import NewsLetterShare from '../modal/modalBody/communication/NewsLetterShare';
import loadScript from 'load-script';
import MobilePreview from './NewsLetterPreview/mobilePreview';
import TabPreview from './NewsLetterPreview/tabPreview';
//import * as debounce from 'lodash.debounce';
import { Input, Button } from 'antd';
import { getMomentDateForUIReadOnly, showToasterMessage, formatDateForBackend } from '../../utils';
import { CONSTANTS } from '../../static/constants';
import ThemeLogo from "../../assets/images/theme-icon.png";
import BackICon from "../../assets/images/back-icon.png";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
// const { Option } = Select;

const FaceBookIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/facebook.png';
const GooglePlusIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/google-plus.png';
const InstagramIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/instagram.png';
const LinkedinIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/linkedin.png';
const PinterestIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/pinterest.png';
const TumblrIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/tumblr.png';
const TwitterIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/twitter.png';
const VimeoIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/vimeo.png';
const YouTubeIcon = 'https://alpide-prd-us1-relationship.s3.amazonaws.com/youtube.png';



function NewsLetterCompo(props) {
    if (!('CKEDITOR' in window)) {
        loadScript('http://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js', err => {
            if (err) {
            } else {
                props.updateState({isCKeditorReady: true });
            }
          });
    }else if(!props.isCKeditorReady){
        props.updateState({isCKeditorReady: true });
    }
    // if (!('CKEDITOR' in window)) {
    //     loadScript('https://cdn.ckeditor.com/4.14.0/full/ckeditor.js', err => {
    //         if (err) {
    //         } else {
    //             let CKEDITOR = window.CKEDITOR;
    //             CKEDITOR.on('instanceReady', function (ev) {
    //                 document.getElementById(ev.editor.id + '_top').style.display = 'none';
    //                 ev.editor.on('focus', function (e) {
    //                     document.getElementById(ev.editor.id + '_top').style.display = 'block';
    //                     let activeChild = document.getElementById(ev.editor.name.replace('editor_', ''));
    //                     if (activeChild) { activeChild.click(); }
    //                 });
    //                 ev.editor.on('blur', function (e) {
    //                     let edi = document.getElementById(ev.editor.id + '_top');
    //                     if (edi) { edi.style.display = 'none'; }
    //                 });
    //             });
    //             CKEDITOR.config.toolbar_Basic = [['Bold', 'Italic', 'Underline', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', '-', 'Undo', 'Redo', '-', 'Font', 'FontSize', 'TextColor', 'BGColor', 'Maximize', 'Link']];
    //             CKEDITOR.config.toolbar = 'Basic';
    //             CKEDITOR.config.width = 680;
    //             CKEDITOR.config.height = 100;
    //             CKEDITOR.config.marginLeft = 10;
    //             CKEDITOR.config.removePlugins = 'elementspath';
    //             setTimeout(() => {
    //                 CKEDITOR.replace("editor_child_1");
    //                 CKEDITOR.replace("editor_child_3");
    //             }, 4000);

    //         }
    //     });
    // }
    window.addEventListener('click', function (e) {
        let activeChild = document.getElementById(props.childId);
        let deleteButton = document.getElementById("delete_" + props.childId);
        let upButton = document.getElementById("up_" + props.childId);
        let downButton = document.getElementById("down_" + props.childId);
        if (activeChild && activeChild.contains(e.target)) {
            if (deleteButton) deleteButton.style.display = "";
            if (upButton) upButton.style.display = "";
            if (downButton) downButton.style.display = "";
            activeChild.style.border = "solid 1px #4ca185";
        } else {
            if (deleteButton) deleteButton.style.display = "none";
            if (upButton) upButton.style.display = "none";
            if (downButton) downButton.style.display = "none";
            if (activeChild) activeChild.style.border = "";
        }
    });
    // window.addEventListener("mouseover", function (ev) {
    //     if (document.getElementById('temmplateDiv') && document.getElementById('temmplateDiv').contains(ev.target)) {
    //     ev.target.classList.add('mouse-over')
    //     }
    // });
    // window.addEventListener("mouseout", function (ev) {
    //     if (document.getElementById('temmplateDiv') && document.getElementById('temmplateDiv').contains(ev.target)) {
    //     ev.target.classList.remove('mouse-over')
    //     }
    // });
    
    
   

    // window.addEventListener("dragenter", function(ev) {
    //     ev.preventDefault();
    //     let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, document.getElementById(ev.target.id));
    //     if (dIndex) {
    //         props.updateState({dIndex: dIndex });
    //     }
    //     if (ev.target.getAttribute("draggable") == "true") {
    //         ev.target.classList.add('drag-over');
    //     }
    //     else {
    //     }
    //     ev.target.classList.add('drag-over');
    //   });

    // window.addEventListener("dragenter", function(ev) {
    //     ev.preventDefault();
    //     let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, document.getElementById(ev.target.id));
    //     if (dIndex) {
    //         props.updateState({dIndex: dIndex });
    //     }
    //     if (ev.target.getAttribute("draggable") == "true") {
    //         ev.target.classList.add('drag-over');
    //     }
    //     else {
    //     }
    //     ev.target.classList.add('drag-over');
    //   });

    window.dragEnter = function (ev) {
        ev.preventDefault();

        if (ev.target.getAttribute("draggable") === "true") {
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, document.getElementById(ev.target.id));
            if (dIndex) {
                props.updateState({ dIndex: dIndex });
            }
            ev.target.classList.add('drag-over');
        }
    }
    window.dragLeave = function (ev) {
        ev.preventDefault();
        ev.target.classList.remove('drag-over');
    }



    // window.addEventListener("dragleave", function(e) {
    //     e.preventDefault();
    //     e.target.classList.remove('drag-over');
    //   });


    window.allowDrop = function (ev) {
        ev.preventDefault();

        let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, document.getElementById(ev.target.id));

        if (dIndex > -1) {
            props.updateState({ dIndex: dIndex });
        }
        if (ev.target.getAttribute("draggable") === "true") {
            ev.dataTransfer.dropEffect = "none"; // dropping is not allowed
        }
        else {
            ev.dataTransfer.dropEffect = "all"; // drop it like it's hot
        }
    };

    window.drag = function (ev) {
        ev.dataTransfer.setData("id", ev.target.id);
        ev.dataTransfer.setData("text/plain", ev.target.innerText);
        ev.dataTransfer.setData("text/html", ev.target.outerHTML);
        ev.dataTransfer.setData("text/uri-list", ev.target.ownerDocument.location.href);
    };

    window.drop = function (ev) {
        ev.preventDefault();
        ev.target.classList.remove('drag-over');
        var id = ev.dataTransfer.getData("id");
        var dragged = document.getElementById(id);
        if(!dragged){return;}

        for (var i = 0; i < dragged.parentNode.children.length; i++) {
            if (dragged.parentNode.children[i]) {
                dragged.parentNode.children[i].classList.remove("drag-over")
            }
        }

        if (props.dIndex) {
            let beforeNode = dragged.parentNode.children[props.dIndex];
            if (beforeNode && beforeNode.nextSibling) { beforeNode = beforeNode.nextSibling }
            if (beforeNode) { dragged.parentNode.insertBefore(dragged, beforeNode); }
            else { dragged.parentNode.appendChild(dragged); }
        } else {
            dragged.parentNode.appendChild(dragged);
        }
        dragged.className += " dropped";
    };
    

    const mobilePreviewPopup = (props) => {
        let templateContent = document.getElementById('temmplateDiv').innerHTML;
        props.pushModalToStack({
            modalBody: <MobilePreview {...props} templateContent={templateContent} registrationFormSettingId={props.registrationFormSettingId} relationshipId={(props.companyInfo || {}).relationshipId} modalType='preview' />,
            // title: <FormattedMessage id='preview.text' />,
            title: "Mobile Preview",
            width: '300px',
            hideFooter: true,
             wrapClassName: 'modal-custom-detail-new '
        })
    }
    const tabPreviewPopup = (props) => {
        let templateContent = document.getElementById('temmplateDiv').innerHTML;
        props.pushModalToStack({
            modalBody: <TabPreview {...props} templateContent={templateContent} registrationFormSettingId={props.registrationFormSettingId} relationshipId={(props.companyInfo || {}).relationshipId} modalType='preview' />,
            // title: <FormattedMessage id='preview.text' />,
            title: "Tablet Preview",
            width: '450px',
            hideFooter: true,
             wrapClassName: 'modal-custom-detail-tab '
        })
    }
    const saveTemplate = (props, cb) => {
      
        let CKEDITOR = window.CKEDITOR;
        let templateFunctionRef = {};
        Object.keys(CKEDITOR.instances).forEach(editorName => {
            var hEd = CKEDITOR.instances[editorName];
            let outputId = editorName.replace("editor_", "");
            var output = document.getElementById(outputId);
            if (hEd && output) {
                let divContent = document.createElement('div');
                divContent.id = editorName;
                divContent.style.marginLeft = "auto";
                divContent.style.marginRight = "auto";
                divContent.style.textAlign = "left";
                divContent.style.width = "680px";
                divContent.style.minHeight = "40px";
                divContent.innerHTML = hEd.getData();
                // output.parentNode.replaceChild(divContent, output);
                var actionChild = document.getElementById('action_' + outputId);
                output.innerHTML = '';
                output.appendChild(divContent);
                if (actionChild) { output.appendChild(actionChild); }
                hEd.destroy();
            }
        });

        (props.allChildIds || []).forEach(childId => {
            let ele = document.getElementById(childId);
            if (ele && ele.onclick) {
                let childFunRef = ele.onclick.toString();
                childFunRef = childFunRef.replace('function ()','() =>');
                childFunRef = childFunRef.replace('function()','() =>');
                childFunRef = childFunRef.replace('function (e)', '(e) =>');
                childFunRef = childFunRef.replace('function(e)', '(e) =>')
                templateFunctionRef[childId] = childFunRef;
            }
        });

       // let titleText = document.getElementById('editor_child_1').textContent;
       
        let eventDetails = (props.eventDetails || {}).child_6 || {};
        let payload = {
            relationshipId: props.companyInfo.relationshipId,
            templateContent: document.getElementById('templateFull').innerHTML,
            templateName: props.templateName || 'Template',
            templateType: props.templateType || 'membership',
            createdByUserId: (props.userInfo || {}).userId,
            eventTitle: eventDetails.eventTitle,
            eventHostedBy: eventDetails.hostedBy,
            eventDate: eventDetails.eventDate ? formatDateForBackend(eventDetails.eventDate) : null,
            eventStreet1: eventDetails.street1,
            eventStreet2: eventDetails.street2,
            eventCity: eventDetails.cityName,
            eventState: eventDetails.stateName,
            eventZip: eventDetails.zipCode,
            fundraisingCampaignId: props.campaignId,
            eventTime: eventDetails.eventTime + (eventDetails.timezone ? ' '+ eventDetails.timezone : ''),
            templateFunctionRef: JSON.stringify(templateFunctionRef),
            childIndex: props.childIndex,
            alpideFormBuilderId: !props.isReplicate ? props.alpideFormBuilderId : null
        }
        
        props.saveTemplate(payload, props, cb);
        
       let element = document.getElementById("action_child_footer");
       element && element.remove();
        
       let templateFooter = document.getElementById('child_footer');
       let templateSocialLinks = document.getElementById('child_socialLinks');
        if(templateFooter || templateSocialLinks){
            let footerPayload = {
                relationshipId: props.companyInfo.relationshipId,
                clientRelationshipAdditionalInfoId: (props.companyAdditionalInfo || {}).clientRelationshipAdditionalInfoId,
                templateFooter: templateFooter ? templateFooter.innerHTML: '',
                templateDGIds: JSON.stringify(props.distributionGroupIds || []),
                templateSocialMeidaIcons: templateSocialLinks.innerHTML,
                templateFooterStyle: templateFooter ? templateFooter.getAttribute('style') : ''
            }
            props.saveClientRelationshipAdditionalInfo(footerPayload, props);
        }
    }
    const shareTemplate = (tempObj) => {

        const modalData = {
            // title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
            modalBody: <NewsLetterShare templateObj={tempObj} memberData={{teamMasterId: props.teamMasterId, teamMemberId: props.teamMemberId }} {...props} updateState={(data) => { props.updateState(data) }} style={{ overflow: 'auto' }} />,
            hideFooter: true,
            maskClosable: true,
            hideCloseIcon: true,
            hideTitle: true,
            wrapClassName: 'pay-invoice-modals',
            width: 600,
        };
        props.pushModalToStack(modalData);

    }

    const getDefaultLogoRef = (type) => {
        let logoRef = document.getElementById('child_0');
        if (props.allChildIds.indexOf('child_0') < 0) {
            let allChildIds = props.allChildIds;
            allChildIds.push('child_0');
            allChildIds.push('delete_child_0');
            props.updateState(allChildIds);
        }
       if(logoRef){
           return logoRef;
       }else{
       let mainCanvasDiv = document.createElement("div");
       let actionDiv = document.createElement("div");
        actionDiv.className = "actiontop";

       mainCanvasDiv.id = "child_0";
       mainCanvasDiv.className="toplogo alltime";
       mainCanvasDiv.style.width = "680px";
       mainCanvasDiv.style.minHeight = "40px";
       mainCanvasDiv.style.textAlign = "center";
       mainCanvasDiv.style.padding = "0px 0px 0px";
       mainCanvasDiv.style.borderRadius = "5px";
       mainCanvasDiv.style.margin = "10px auto 10px";
       mainCanvasDiv.style.display = "inline-block";
       mainCanvasDiv.style.cursor = "pointer";
       let img = document.createElement('img');
       img.id = "dummy_child_0";
    //    img.src = props.logoUrl || LogoShape;
       img.src= props.imageUrl
       img.style.width= "100px";  
    //    img.style.height= "150px";
       img.className= "logotop";
       mainCanvasDiv.appendChild(img);
       
       let deleteButton = document.createElement('i');
       deleteButton.className="right-delate-box delet-icon";
       deleteButton.style.display = "none";
       deleteButton.id = "delete_child_0";
       deleteButton.onclick = function(){
           let comp = document.getElementById("child_0");
           (props && props.updateState) && props.updateState({isNewsLetterPropsUpdaterVisible: false });
           comp.parentNode.removeChild(comp);
       } ;
       actionDiv.appendChild(deleteButton);
       mainCanvasDiv.onclick = function(){ 
           let comp = document.getElementById("child_0");
           (props && props.updateState) && props.updateState({isNewsLetterPropsUpdaterVisible: comp ? true: false, childId:"child_0", elementType: 'Logo' })
       } ;
       mainCanvasDiv.appendChild(actionDiv);
            return mainCanvasDiv;
        }
    }

    const setDefaultTitleRef = (type) => {
        let divObj = document.createElement("div");
        divObj.className = "alltime ";
        divObj.style.display = "inline-block ";
        divObj.id = "child_1";
        if (props.allChildIds.indexOf('child_1') < 0) {
            let allChildIds = props.allChildIds;
            allChildIds.push('child_1');
            allChildIds.push('delete_child_1');
            props.updateState(allChildIds);
        }
        divObj.style.cursor = "pointer";
        divObj.onclick = function () {
            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false, childId: "child_1", elementType: 'Text' })
        };

        let actionDiv = document.createElement("div");
        actionDiv.className = "actiontop";

        let deleteButton = document.createElement('i');
        deleteButton.className = "right-delate-box delet-icon";
        deleteButton.style.display = "none";
        deleteButton.style.marginTop = '0px';
        deleteButton.title = "Delete";
        deleteButton.id = "delete_child_1";
        deleteButton.onclick = function () {
            let comp = document.getElementById("child_1");
            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
            comp.parentNode.removeChild(comp);
        };

        actionDiv.appendChild(deleteButton);

        let textInput = document.createElement("textarea");
        textInput.id = "editor_child_1";
        textInput.name = "editor_child_1";
        textInput.value = `<p style='text-align: center;'><b><span style='font-size: xx-large;'>${type === 'event' ? 'Its a Big Event' : (type === 'fundraising' && props.selectedCampaign) ? props.selectedCampaign.campaignName : 'Add a Catchy Title'}</span></b></p>`

        divObj.appendChild(textInput);
        divObj.appendChild(actionDiv);
        divObj.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false, childId: "child_1", elementType: 'Text' }) };
        document.getElementById('temmplateDiv').appendChild(divObj);

        let CKEDITOR = window.CKEDITOR;
        CKEDITOR.on('instanceReady', function (ev) {
            let edi = document.getElementById(ev.editor.id + '_top');
            if (edi) { edi.style.display = 'none'; }
            ev.editor.on('focus', function (e) {
                let edi = document.getElementById(ev.editor.id + '_top');
                if (edi) { edi.style.display = 'block'; }
                let activeChild = document.getElementById("child_1");
                if (activeChild) { activeChild.click(); }
            });
            ev.editor.on('blur', function (e) {
                let edi = document.getElementById(ev.editor.id + '_top');
                if (edi) { edi.style.display = 'none'; }
            });
            ev.editor.on('change', function (e) {
                let edi = document.getElementById(ev.editor.id + '_top');
                if (type === 'event' && edi.style.display !== 'none') {
                    let eventData = (props.eventDetails || {}).child_6 || {};
                    eventData.eventTitle = ev.editor.document.getBody().getText();
                    props.updateState({ eventDetails: { ...props.eventDetails, child_6: eventData } });
                    let buttonRef = document.getElementById('child_6') || {};
                    let info = `<div style= "text-align: center; padding: 3px 8px;"> <b> ${eventData.eventTitle || ''} </b></div>
                                <div style= "text-align: center; padding: 3px 8px;"> ${eventData.eventDate ? getMomentDateForUIReadOnly({ date: new Date(eventData.eventDate), format }) : ''} ${eventData.eventTime || ''} ${eventData.timezone || ''} </div>
                                <div style= "text-align: center; padding: 3px 8px;"> ${eventData.street1 || ''} ${eventData.street2 || ''} </div>
                                <div style= "text-align: center; padding: 3px 8px;"> ${eventData.cityName || ''}${eventData.stateName ? ', ' + eventData.stateName : ''}${eventData.zipCode ? ', ' + eventData.zipCode : ''} </div>
                                `;
                    buttonRef.innerHTML = info;
                }
            });
        });
        CKEDITOR.config.toolbar_Basic = [['Bold', 'Italic', 'Underline', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', '-', 'Undo', 'Redo', '-', 'Font', 'FontSize', 'TextColor', 'BGColor', 'Link']];
        CKEDITOR.config.toolbar = 'Basic';
        CKEDITOR.config.width = 680;
        CKEDITOR.config.marginLeft = 10;
        CKEDITOR.config.removePlugins = 'elementspath';
        CKEDITOR.config.autoGrow_minHeight = 100;
        CKEDITOR.config.extraPlugins = 'autogrow';
        CKEDITOR.config.autoGrow_onStartup = true;
        CKEDITOR.config.autoGrow_bottomSpace = 10;
        CKEDITOR.key='editor_child_1';
        CKEDITOR.replace("editor_child_1" );
    }

    const getDefaultImgRef = (type) => {
        if (props.allChildIds.indexOf('child_2') < 0) {
            let allChildIds = props.allChildIds;
            allChildIds.push('child_2');
            allChildIds.push('delete_child_2');
            allChildIds.push('up_child_2');
            allChildIds.push('down_child_2');
            props.updateState(allChildIds);
        }
        let mainCanvasDiv = document.createElement("div");
        let actionDiv = document.createElement("div");
        actionDiv.className = "actiontop";

         mainCanvasDiv.type = "file";
         mainCanvasDiv.id = "child_2";
         mainCanvasDiv.className = "mobpic alltime";
         mainCanvasDiv.style.width = "678px";
         mainCanvasDiv.style.minHeight = "40px";
         mainCanvasDiv.style.textAlign = "center";
         mainCanvasDiv.style.padding = "25px 0px 25px";
         mainCanvasDiv.style.borderRadius = "5px";
         mainCanvasDiv.style.margin = "10px auto 10px";
         mainCanvasDiv.style.display = "inline-block";
        mainCanvasDiv.style.cursor = "pointer";

         mainCanvasDiv.draggable=true;
         mainCanvasDiv.ondragstart=window.drag;
         mainCanvasDiv.class="drag";

         let img = document.createElement('img');
         img.id = "dummy_child_2";
         img.src = props.imageUrl;
         img.style.width = '678px'; 
         img.style.height= 'auto';
         mainCanvasDiv.appendChild(img);
         
         let deleteButton = document.createElement('i');
         deleteButton.className="right-delate-box delet-icon";
         deleteButton.style.display = "none";
         deleteButton.title = "Delete";
         deleteButton.id = "delete_child_2";
         deleteButton.onclick = function(){
             let comp = document.getElementById("child_2");
             (props && props.updateState) && props.updateState({isNewsLetterPropsUpdaterVisible: false });
             comp.parentNode.removeChild(comp);
         } ;
         actionDiv.appendChild(deleteButton);

        let moveUpButton = document.createElement('i');
        moveUpButton.className = "right-delate-box uparrow";
         moveUpButton.style.display = "none";
         moveUpButton.title = "Move up";
        moveUpButton.id = "up_child_2";
        moveUpButton.onclick = function () {
            let comp = document.getElementById("child_2");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex - 1];
                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
            }

        };
        actionDiv.appendChild(moveUpButton);
        let moveDownButton = document.createElement('i');
        moveDownButton.className = "right-delate-box downarrow";
        moveDownButton.style.display = "none";
        moveDownButton.title = "Move down";
        moveDownButton.id = "down_child_2";
        moveDownButton.onclick = function () {
            let comp = document.getElementById("child_2");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex + 2];
                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                else { comp.parentNode.appendChild(comp); }
            }
        };
        actionDiv.appendChild(moveDownButton);
        mainCanvasDiv.appendChild(actionDiv);
         mainCanvasDiv.onclick = function(){ 
             let comp = document.getElementById("child_2");
             (props && props.updateState) && props.updateState({isNewsLetterPropsUpdaterVisible: comp ? true: false, childId:"child_2", elementType: 'Image' })
         } ;
         return mainCanvasDiv;
    }

    const setDefaultTemplateBodyRef = (type) => {
        if (props.allChildIds.indexOf('child_3') < 0) {
            let allChildIds = props.allChildIds;
            allChildIds.push('child_3');
            allChildIds.push('delete_child_3');
            allChildIds.push('up_child_3');
            allChildIds.push('down_child_3');
            props.updateState(allChildIds);
        }
        let CKEDITOR = window.CKEDITOR;
        CKEDITOR.on('instanceReady', function (ev) {
            let edi = document.getElementById(ev.editor.id + '_top');
            if (edi) { edi.style.display = 'none'; }
            ev.editor.on('focus', function (e) {
                let edi = document.getElementById(ev.editor.id + '_top');
                if (edi) { edi.style.display = 'block'; }
                let activeChild = document.getElementById("child_3");
                if(activeChild){activeChild.click(); }
            });
            ev.editor.on('blur', function (e) {
                let edi = document.getElementById(ev.editor.id + '_top');
                if (edi) { edi.style.display = 'none'; }
            });
        });
        CKEDITOR.config.toolbar_Basic = [['Bold', 'Italic', 'Underline', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', '-', 'Undo', 'Redo', '-', 'Font', 'FontSize', 'TextColor', 'BGColor', 'Link']];
        CKEDITOR.config.toolbar = 'Basic';
        CKEDITOR.config.width = 680;
        CKEDITOR.config.marginLeft = 10;
        CKEDITOR.config.removePlugins = 'elementspath';
        CKEDITOR.config.autoGrow_minHeight = 100;
        CKEDITOR.config.extraPlugins = 'autogrow';
        CKEDITOR.config.autoGrow_onStartup = true;
        CKEDITOR.config.autoGrow_bottomSpace = 10;
        CKEDITOR.config.autoGrow_bottomSpace = 10;


        let divObj = document.createElement("div");
        let actionDiv = document.createElement("div");
        actionDiv.className = "actiontop";
        actionDiv.id = "action_child_3";

        divObj.id = "child_3";
        divObj.className = "alltime divi-pic";
        divObj.style.display = "inline-block ";  
        divObj.draggable=true;
		divObj.ondragstart=window.drag;
        divObj.class="drag";
        
        let mainCanvasDiv = document.createElement("textarea");
        mainCanvasDiv.id = "editor_child_3";
        mainCanvasDiv.value="Add some text here";
        
        let deleteButton = document.createElement('i');
        deleteButton.className="right-delate-box delet-icon";
        deleteButton.style.display = "none";
        deleteButton.style.marginTop= '0px';
         deleteButton.title = "Delete";
        deleteButton.id = "delete_child_3";
        deleteButton.onclick = function(){
            let comp = document.getElementById("child_3");
            (props && props.updateState) && props.updateState({isNewsLetterPropsUpdaterVisible: false });
            comp.parentNode.removeChild(comp);
        } ;

        actionDiv.appendChild(deleteButton);

         divObj.appendChild(mainCanvasDiv);
        // divObj.appendChild(deleteButton);
        let moveUpButton = document.createElement('i');
        moveUpButton.className = "right-delate-box uparrow";
         moveUpButton.style.display = "none";
         deleteButton.title = "Move up";
        moveUpButton.id = "up_child_3";
        moveUpButton.onclick = function () {
            let comp = document.getElementById("child_3");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex - 1];
                if (beforeNode) { 
                    comp.parentNode.insertBefore(comp, beforeNode); 
                    var hEd = window.CKEDITOR.instances["editor_child_3"];
                    if (hEd) { hEd.destroy(); }
                    window.CKEDITOR.replace("editor_child_3");
                }
            }

        };
        // divObj.appendChild(moveUpButton);
        actionDiv.appendChild(moveUpButton);
        let moveDownButton = document.createElement('i');
        moveDownButton.className = "right-delate-box downarrow";
        moveDownButton.style.display = "none";
        deleteButton.title = "Move down";
        moveDownButton.id = "down_child_3";
        moveDownButton.onclick = function () {
            let comp = document.getElementById("child_3");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex + 2];
                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                else { comp.parentNode.appendChild(comp); }
                var hEd = window.CKEDITOR.instances["editor_child_3"];
                if (hEd) { hEd.destroy();}
                window.CKEDITOR.replace("editor_child_3");
            }

        };
        // divObj.appendChild(moveDownButton);
        actionDiv.appendChild(moveDownButton);
        divObj.appendChild(actionDiv);

        divObj.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false, childId: "child_3", elementType: 'Text' }) };
        document.getElementById('temmplateDiv').appendChild(divObj);

        

        CKEDITOR.replace("editor_child_3");
    }

    const getDefaultButtonRef = (type) => {
        if (props.allChildIds.indexOf('child_4') < 0) {
            let allChildIds = props.allChildIds;
            allChildIds.push('child_4');
            allChildIds.push('delete_child_4');
            allChildIds.push('child_8');
            allChildIds.push('delete_child_8');           
            allChildIds.push('up_child_4');
            allChildIds.push('down_child_4');
            props.updateState(allChildIds);
        }
        let divObj = document.createElement("div");
        let actionDiv = document.createElement("div");
        actionDiv.className = "actiontop";

        divObj.id = "child_4";
        divObj.className = "mdilebutton alltime inty-buts";
        divObj.style.width = "680px";
        // divObj.style.border = "#ddd 1px solid";
        divObj.style.textAlign = "center";
        divObj.style.borderRadius = "5px";
        divObj.style.margin = "10px auto 10px";
        divObj.style.display = "inline-block";
        divObj.style.cursor = "pointer";
        divObj.draggable=true;
		divObj.ondragstart=window.drag;
        divObj.class="drag";
        divObj.dragenter=window.dragEnter;
        divObj.dragover=window.dragOver;
        divObj.dragleave=window.dragLeave;

        let divObj2 = document.createElement("span");
        divObj2.style.height ="70px" ;
        divObj2.style.border = "0px solid  #fff !importent";
        divObj2.style.display = "flow-root";
        divObj2.style.position = "relative";
        divObj2.style.zIndex = "99999";

        divObj2.style.borderRadius = "5px";
        
        let pladgeButton = document.createElement("a");

        let mainCanvasDiv = document.createElement("a");
        mainCanvasDiv.id = "button_child_4";
        mainCanvasDiv.innerHTML = 'Button';
        mainCanvasDiv.style.float= 'left';
        mainCanvasDiv.style.width = "150px";
        mainCanvasDiv.style.height = "50px";
        mainCanvasDiv.style.background = "#525156";
        mainCanvasDiv.style.textAlign = "center";
        mainCanvasDiv.style.color = "#fff";
        mainCanvasDiv.style.lineHeight = "45px";
        mainCanvasDiv.style.margin = "10px auto 10px 255px";
        mainCanvasDiv.style.clear = "both";
         mainCanvasDiv.style.cursor = "pointer";
        mainCanvasDiv.style.border = "solid 1px #525156";
        mainCanvasDiv.style.borderRadius = "4px";
        mainCanvasDiv.className = "disabled-link";
        if (type === 'event') {
            mainCanvasDiv.href = `${props.subdomainName}/app/rsvp?t=${props.companyInfo.relationshipId}_${props.alpidePaymentGatewayId || 0}_3`;
            mainCanvasDiv.innerHTML = 'RSVP';
        }
        if (type === 'fundraising') {
            let selectedCampaign = props.selectedCampaign || {};
            mainCanvasDiv.href = `https://alpide.com/app/payment-setup?t=${props.companyInfo.relationshipId}_${props.alpidePaymentGatewayId || 0}_8_${props.projectMasterId || 0}_${props.chartOfAccountDetailsId || 0}_0_${selectedCampaign.fundraisingCampaignId}`;
            mainCanvasDiv.innerHTML = 'Donate';

            mainCanvasDiv.style.width = "150px";
            mainCanvasDiv.style.height = "50px";
            mainCanvasDiv.style.background = "#525156";
            mainCanvasDiv.style.textAlign = "center";
            mainCanvasDiv.style.color = "#fff";
            mainCanvasDiv.style.lineHeight = "45px";
            mainCanvasDiv.style.display = "inline-block";
            mainCanvasDiv.style.margin = "10px auto 10px 190px";
            mainCanvasDiv.style.clear = "both";
            mainCanvasDiv.style.zIndex = "999"
            // mainCanvasDiv.style.cursor = "pointer";
            mainCanvasDiv.style.border = "solid 1px #525156";
            divObj2.id = "child_8";
            divObj2.onclick = function (e) {
                e.stopPropagation();
                let deleteButtonobj = document.getElementById("delete_child_8");
                if (deleteButtonobj) deleteButtonobj.style.display = "";
                (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_8", elementType: 'Button' })
            };
            let deletePladgeButton = document.createElement('i');
            deletePladgeButton.className = "right-delate-box5 delet-icon";
            deletePladgeButton.style.display = "none";
            deletePladgeButton.title = "Delete";
            deletePladgeButton.id = "delete_child_8";
            deletePladgeButton.onclick = function () {
                let comp = document.getElementById("child_8");
                (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                comp.parentNode.removeChild(comp);
            };
            divObj2.appendChild(deletePladgeButton);

            pladgeButton.id = "button_child_8";
            pladgeButton.innerHTML = 'Button';
            pladgeButton.style.width = "150px";
            pladgeButton.style.height = "50px";
            pladgeButton.style.background = "#525156";
            pladgeButton.style.textAlign = "center";
            pladgeButton.style.color = "#fff";
            pladgeButton.style.lineHeight = "45px";
            pladgeButton.style.display = "inline-block";
            pladgeButton.style.margin = "10px 175px 10px 0px";
            pladgeButton.style.clear = "both";
            // mainCanvasDiv.style.cursor = "pointer";
            pladgeButton.style.border = "solid 1px #525156";
            pladgeButton.style.borderRadius = "4px";
            pladgeButton.className = "disabled-link";

            pladgeButton.href = `${props.subdomainName}/app/create-pledge?t=${props.companyInfo.relationshipId}_4_0_${selectedCampaign.fundraisingCampaignId}_0`;
            pladgeButton.innerHTML = 'Pledge';
        }

        if (type !== 'event' && type !== 'fundraising') {
            let deleteButton = document.createElement('i');
            deleteButton.className = "right-delate-box4 delet-icon";
            deleteButton.style.display = "none";
            deleteButton.title="Delete"
            deleteButton.id = "delete_child_4";
            deleteButton.onclick = function () {
                let comp = document.getElementById("child_4");
                (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                comp.parentNode.removeChild(comp);
            };
            actionDiv.appendChild(deleteButton);
        }
        //mainCanvasDiv.appendChild(deleteButton);
        divObj.onclick = function(){ (props && props.updateState) && props.updateState({isNewsLetterPropsUpdaterVisible: true, childId:"child_4", elementType: 'Button' })} ;

        divObj.appendChild(mainCanvasDiv);
        if (type === 'fundraising') {
            divObj2.appendChild(pladgeButton);
            divObj.appendChild(divObj2);
        }
        let moveUpButton = document.createElement('i');
        moveUpButton.className = "right-delate-box uparrow";
        moveUpButton.style.display = "none";
        moveUpButton.title ="Move up"
        moveUpButton.id = "up_child_4";
        moveUpButton.onclick = function () {
            let comp = document.getElementById("child_4");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex - 1];
                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
            }

        };
        actionDiv.appendChild(moveUpButton);
        let moveDownButton = document.createElement('i');
        moveDownButton.className = "right-delate-box downarrow";
        moveDownButton.style.display = "none";
        moveDownButton.title ="Move down"
        moveDownButton.id = "down_child_4";
        moveDownButton.onclick = function () {
            let comp = document.getElementById("child_4");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex + 2];
                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                else { comp.parentNode.appendChild(comp); }
            }

        };
        actionDiv.appendChild(moveDownButton);
        divObj.appendChild(actionDiv);
        return divObj;
    }
    const getDefaultSocialLinksRef = (type) => {
        if (props.allChildIds.indexOf('child_socialLinks') < 0) {
            let allChildIds = props.allChildIds;
            allChildIds.push('child_socialLinks');
            allChildIds.push('delete_child_socialLinks');  
            allChildIds.push('up_child_socialLinks');
            allChildIds.push('down_child_socialLinks');
            props.updateState(allChildIds);
        }
        
        let mainCanvasDiv = document.createElement("div");
        let actionDiv = document.createElement("div");
        actionDiv.className = "actiontop";

        mainCanvasDiv.id = "child_socialLinks";
        mainCanvasDiv.className = "socialbutt alltime";
        mainCanvasDiv.style.width = "680px";
        mainCanvasDiv.style.padding = "30px";
        mainCanvasDiv.style.margin = "0px auto 0px";
        mainCanvasDiv.style.fontSize = "24px";
        mainCanvasDiv.style.cursor = "pointer";
        mainCanvasDiv.draggable=true;
		mainCanvasDiv.ondragstart=window.drag;
        mainCanvasDiv.class="drag";
        
        mainCanvasDiv.dragenter=window.dragEnter;
        mainCanvasDiv.dragover=window.dragOver;
        mainCanvasDiv.dragleave=window.dragLeave;
        if (props.companyAdditionalInfo && props.companyAdditionalInfo.templateSocialMeidaIcons) {
            mainCanvasDiv.innerHTML = props.companyAdditionalInfo.templateSocialMeidaIcons;
        } else {
            mainCanvasDiv.innerHTML = '<a class="disabled-link" target="_blank" href="#" id=facebook_child_socialLinks  style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid;vertical-align: middle; "> <img src=' + FaceBookIcon + ' alt="" style="vertical-align: middle;" /> </a>'
                + '<a class="disabled-link" target="_blank" href="" id=twitter_child_socialLinks style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid ; vertical-align: middle;" ><img src=' + TwitterIcon + ' alt="" style="vertical-align: middle;"/></a>'
                + '<a class="disabled-link" target="_blank" href="#" id=instagram_child_socialLinks style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid ;vertical-align: middle;"><img src=' + InstagramIcon + ' alt="" style="vertical-align: middle;"/></a>'
                + '<a class="disabled-link" target="_blank" href="#" id=google-plus_child_socialLinks style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid ;vertical-align: middle; "><img src=' + GooglePlusIcon + ' alt="" style="vertical-align: middle;"/></a>'
                + '<a class="disabled-link" target="_blank" href="#" id=linkedin_child_socialLinks style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid ;vertical-align: middle; "><img src=' + LinkedinIcon + ' alt="" style="vertical-align: middle;"/></a>'
                + '<a class="disabled-link" target="_blank" href="#" id=pinterest_child_socialLinks style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid ;vertical-align: middle; "><img src=' + PinterestIcon + ' alt="" style="vertical-align: middle;"/></a>'
                + '<a class="disabled-link" target="_blank" href="#" id=tumblr_child_socialLinks style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid ;vertical-align: middle; "><img src=' + TumblrIcon + ' alt="" style="vertical-align: middle;"/></a>'
                + '<a class="disabled-link" target="_blank" href="#" id=vimeo_child_socialLinks style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid ;vertical-align: middle; "><img src=' + VimeoIcon + ' alt="" style="vertical-align: middle;"/></a>'
                + '<a class="disabled-link" target="_blank" href="#" id=youtube_child_socialLinks style= "cursor:pointer; margin:0px 2px;  text-align: center; padding: 0px 0px;height:60px; width:60px; line-height:55px; display:inline-block;border: #525156 0px solid ;vertical-align: middle; "><img src=' + YouTubeIcon + ' alt="" style="vertical-align: middle;"/></a>'
                ;
        }
        let deleteButton = document.createElement('i');
        deleteButton.className = "right-delate-box delet-icon";
        deleteButton.style.display = "none";
        deleteButton.title ="Delete"
        deleteButton.id = "delete_child_socialLinks";
        deleteButton.onclick = function () {
            let comp = document.getElementById("child_socialLinks");
            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
            setTimeout(function () {comp.parentNode.removeChild(comp);},100);
        };
        actionDiv.appendChild(deleteButton);
        mainCanvasDiv.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_socialLinks", elementType: 'Social Links' }) };
        
        let moveUpButton = document.createElement('i');
        moveUpButton.className = "right-delate-box uparrow";
        moveUpButton.style.display = "none";
        moveUpButton.title ="Move up"
        moveUpButton.id = "up_child_socialLinks";
        moveUpButton.onclick = function () {
            let comp = document.getElementById("child_socialLinks");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex - 1];
                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
            }

        };
        actionDiv.appendChild(moveUpButton);
        let moveDownButton = document.createElement('i');
        moveDownButton.className = "right-delate-box downarrow";
        moveDownButton.style.display = "none";
        moveDownButton.title ="Move down"
        moveDownButton.id = "down_child_socialLinks";
        moveDownButton.onclick = function () {
            let comp = document.getElementById("child_socialLinks");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex + 2];
                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                else { comp.parentNode.appendChild(comp); }
            }

        };
        actionDiv.appendChild(moveDownButton);
        mainCanvasDiv.appendChild(actionDiv);
        return mainCanvasDiv;
    }

    const setDefaultEventRef = (type) => {
        if (props.allChildIds.indexOf('child_6') < 0) {
            let allChildIds = props.allChildIds;
            allChildIds.push('child_6');
            allChildIds.push('up_child_6');
            allChildIds.push('down_child_6');
           // allChildIds.push('delete_child_6');
            props.updateState(allChildIds);
        }
        let divObj = document.createElement("div");
        let actionDiv = document.createElement("div");
        actionDiv.className = "actiontop";
        // let eventData = {
        //     eventTitle: 'Its a Big Event',
        //     eventDate: new Date(),
        //     eventTime: '7:00 pm',
        //     street1: '1002 Main Street',
        //     street2: 'Suite 232',
        //     cityName: 'Concord',
        //     stateName: 'Concord',
        //     zipCode: '25696'
        // }
        
       // props.updateState({ eventDetails: { child_6: eventData } });eventDetails
       divObj.draggable=true;
       divObj.ondragstart=window.drag;
       divObj.class="drag";
       divObj.dragenter=window.dragEnter;
       divObj.dragover=window.dragOver;
       divObj.dragleave=window.dragLeave;

       let eventData = (props.eventDetails || {}).child_6;
        divObj.innerHTML = `<div style= "text-align: center; padding: 3px 8px;"> <b> ${eventData.eventTitle || ''} </b></div>
                        <div style= "text-align: center; padding: 3px 8px;"> ${eventData.eventDate ? getMomentDateForUIReadOnly({ date: new Date(eventData.eventDate), format }) : ''} ${eventData.eventTime || ''} </div>
                        <div style= "text-align: center; padding: 3px 8px;"> ${eventData.street1 || ''} ${eventData.street2 || ''} </div>
                        <div style= "text-align: center; padding: 3px 8px;"> ${eventData.cityName || ''}${eventData.stateName ? ', ' + eventData.stateName : ''}${eventData.zipCode ? ', ' + eventData.zipCode : ''} </div>
                        `;
        divObj.id = "child_6";
        divObj.style.width = "680px";
        // divObj.style.border = "#ddd 1px solid";
        divObj.style.textAlign = "center";
        divObj.style.borderRadius = "5px";
        divObj.style.margin = "10px auto 10px";
        divObj.style.display = "inline-block";
        // divObj.style.cursor = "pointer";
        divObj.style.background = "#756AA2";
        divObj.className = "event-ups alltime";
        divObj.style.color = "#FFFFFF";

        // let deleteButton = document.createElement('i');
        // deleteButton.className = "right-delate-box4 fa fa-trash";
        // deleteButton.style.display = "none";
        // deleteButton.id = "delete_child_6";
        // deleteButton.onclick = function () {
        //     let comp = document.getElementById("child_6");
        //     (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
        //     comp.parentNode.removeChild(comp);
        // };
        //mainCanvasDiv.appendChild(deleteButton);
        divObj.onclick = function () { (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_6", elementType: 'Event' }) };
       // divObj.appendChild(deleteButton);
       let moveUpButton = document.createElement('i');
       moveUpButton.className = "right-delate-box uparrow";
       moveUpButton.style.display = "none";
       moveUpButton.title="Move up"
       moveUpButton.id = "up_child_6";
       moveUpButton.onclick = function () {
           let comp = document.getElementById("child_6");
           let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
           if (dIndex > -1) {
               let beforeNode = comp.parentNode.children[dIndex - 1];
               if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
           }

       };
       actionDiv.appendChild(moveUpButton);
       let moveDownButton = document.createElement('i');
       moveDownButton.className = "right-delate-box downarrow";
       moveDownButton.style.display = "none";
       moveDownButton.title="Move down"
       moveDownButton.id = "down_child_6";
       moveDownButton.onclick = function () {
           let comp = document.getElementById("child_6");
           let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
           if (dIndex > -1) {
               let beforeNode = comp.parentNode.children[dIndex + 2];
               if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
               else { comp.parentNode.appendChild(comp); }
           }

       };
       actionDiv.appendChild(moveDownButton);
       divObj.appendChild(actionDiv);
        document.getElementById('temmplateDiv').appendChild(divObj);
        
    }

    const getDefaultProgressBarRef = (type) => {
        if (props.allChildIds.indexOf('child_7') < 0) {
            let allChildIds = props.allChildIds;
            allChildIds.push('child_7');
            allChildIds.push('delete_child_7');
            allChildIds.push('up_child_7');
            allChildIds.push('down_child_7');
            props.updateState(allChildIds);
        }
        let mainCanvasDiv = document.createElement("div");
        let actionDiv = document.createElement("div");
        actionDiv.className = "actiontop";

        mainCanvasDiv.id = "child_7";
        mainCanvasDiv.className = "progresso alltime";
        mainCanvasDiv.style.width = "680px";
        mainCanvasDiv.style.minHeight = "40px";
        //mainCanvasDiv.style.border = "#ddd 1px solid";
        mainCanvasDiv.style.textAlign = "center";
        mainCanvasDiv.style.alignContent = "center";
        mainCanvasDiv.style.padding = "25px 0px 25px";
        mainCanvasDiv.style.borderRadius = "5px";
        // mainCanvasDiv.style.background = "#eaf7ff";
        mainCanvasDiv.style.margin = "10px auto 10px";
        mainCanvasDiv.style.display = "inline-block";
        //  mainCanvasDiv.style.cursor = "pointer";
        mainCanvasDiv.draggable=true;
		mainCanvasDiv.ondragstart=window.drag;
        mainCanvasDiv.class="drag";
        
        mainCanvasDiv.dragenter=window.dragEnter;
        mainCanvasDiv.dragover=window.dragOver;
        mainCanvasDiv.dragleave=window.dragLeave;

        let selectedCampaign = props.selectedCampaign || {};
        let progressPer = selectedCampaign.goal ? Number((selectedCampaign.totalReceived * 100) / selectedCampaign.goal).toFixed(1) : 0;
        progressPer = progressPer > 0 ? progressPer : 50;
        mainCanvasDiv.innerHTML = `<div id=bar_info_child_7 style="margin: 0px 15px 0px 15px; width: 95%" >
            <span id=amount_child_7 style= "float: left;"><b>$ ${selectedCampaign.goal || 0}</b></span>
            <span id=amount_percent_child_7 style= "float: right;" > <b>${selectedCampaign.goal ? Number((selectedCampaign.totalReceived * 100) / selectedCampaign.goal).toFixed(1) : 0} %</b></span>
            </div>
            <br/> 
            <div id=progress_bar_child_7 style="border:0; cellpadding= 0  cellspacing=0 width=95%">
                <span id=progress_bar_fill_child_7 bgcolor="#4CA185" style="width:${progressPer}%; background-color:#525156; float:left; height:20px; border-bottom-left-radius:9px; border-top-left-radius:9px;"></span>
                <span id=progress_bar_unfill_child_7 bgcolor="#cccccc" style="width:${100 - progressPer}%; background-color:#cccccc; float:left; height:20px; border-bottom-right-radius:9px; border-top-right-radius:9px;"></span>
            </div>`;

        // let progressBar = document.createElement('progress');
        // progressBar.id = "progress_bar_child_7";
        // progressBar.style.margin = "0px 15px 0px 15px";
        // progressBar.style.width = "95%";
        // progressBar.style.height = "25px";
        // progressBar.style.borderRadius = "50px";
        // progressBar.style.border = "#525156 1px solid";
        // progressBar.style.background = "#525156";
        // progressBar.style.display = "block";
        // progressBar.value = selectedCampaign.goal ? Number((selectedCampaign.totalReceived *100)/selectedCampaign.goal).toFixed(1) : 0;
        // progressBar.max = "100";
        // progressBar.color = "#4CA185";
        // mainCanvasDiv.appendChild(progressBar);

        // let deleteButton = document.createElement('i');
        // deleteButton.className = "right-delate-box delet-icon";
        // deleteButton.style.display = "none";
        // deleteButton.title="Delete";
        // deleteButton.id = "delete_child_7" ;
        // deleteButton.onclick = function () {
        //     let comp = document.getElementById("child_7");
        //     (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
        //     comp.parentNode.removeChild(comp);
        // };
        // actionDiv.appendChild(deleteButton);
        mainCanvasDiv.onclick = function () {
            let comp = document.getElementById("child_7");
            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: comp ? true : false, childId: "child_7", elementType: 'Progress Bar' })
        };
        let moveUpButton = document.createElement('i');
        moveUpButton.className = "right-delate-box uparrow";
         moveUpButton.style.display = "none";
         moveUpButton.title="Move up";
        moveUpButton.id = "up_child_7";
        moveUpButton.onclick = function () {
            let comp = document.getElementById("child_7");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex - 1];
                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
            }

        };
        actionDiv.appendChild(moveUpButton);
        let moveDownButton = document.createElement('i');
        moveDownButton.className = "right-delate-box downarrow";
        moveDownButton.style.display = "none";
        moveDownButton.title="Move down";
        moveDownButton.id = "down_child_7";
        moveDownButton.onclick = function () {
            let comp = document.getElementById("child_7");
            let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
            if (dIndex > -1) {
                let beforeNode = comp.parentNode.children[dIndex + 2];
                if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
                else { comp.parentNode.appendChild(comp); }
            }

        };
        actionDiv.appendChild(moveDownButton);
        mainCanvasDiv.appendChild(actionDiv);
        return mainCanvasDiv;
    }

    // const getPledgeButtonRef = (type) => {
    //     if (props.allChildIds.indexOf('child_8') < 0) {
    //         let allChildIds = props.allChildIds;
    //         allChildIds.push('child_8');
    //         allChildIds.push('delete_child_8');
    //         allChildIds.push('up_child_8');
    //         allChildIds.push('down_child_8');
    //         props.updateState(allChildIds);
    //     }
    //     let divObj = document.createElement("div");
    //     let actionDiv = document.createElement("div");
    //     actionDiv.className = "actiontop";

    //     divObj.id = "child_8";
    //     divObj.className = "mdilebutton alltime";
    //     divObj.style.width = "680px";
    //     // divObj.style.border = "#ddd 1px solid";
    //     divObj.style.textAlign = "center";
    //     divObj.style.borderRadius = "5px";
    //     divObj.style.margin = "10px auto 10px";
    //     divObj.style.display = "inline-block";
    //     // divObj.style.cursor = "pointer";
    //     divObj.draggable=true;
	// 	divObj.ondragstart=window.drag;
    //     divObj.class="drag";
        
    //     divObj.dragenter=window.dragEnter;
    //     divObj.dragover=window.dragOver;
    //     divObj.dragleave=window.dragLeave;

    //     let mainCanvasDiv = document.createElement("a");
    //     mainCanvasDiv.id = "button_child_8";
    //     mainCanvasDiv.innerHTML = 'Button';
    //     mainCanvasDiv.style.float= 'left';
    //     mainCanvasDiv.style.width = "150px";
    //     mainCanvasDiv.style.height = "50px";
    //     mainCanvasDiv.style.background = "#525156";
    //     mainCanvasDiv.style.textAlign = "center";
    //     mainCanvasDiv.style.color = "#fff";
    //     mainCanvasDiv.style.lineHeight = "45px";
    //     mainCanvasDiv.style.margin = "10px auto 10px 255px";
    //     mainCanvasDiv.style.clear = "both";
    //     mainCanvasDiv.style.cursor = "pointer";
    //     mainCanvasDiv.style.border = "solid 1px #525156";
    //     mainCanvasDiv.style.borderRadius = "4px";
    //     mainCanvasDiv.className = "disabled-link";

    //     let selectedCampaign = props.selectedCampaign || {};

    //     if (type === 'pledge') { 
    //         mainCanvasDiv.href = `${props.subdomainName}/app/create-pledge?t=${props.companyInfo.relationshipId}_4_0_${selectedCampaign.fundraisingCampaignId}_0`;
    //         mainCanvasDiv.innerHTML = 'Pledge';
    //     }

    //         let deleteButton = document.createElement('i');
    //         deleteButton.className = "right-delate-box4 delet-icon";
    //         deleteButton.style.display = "none";
    //         deleteButton.title="Delete";
    //         deleteButton.id = "delete_child_8";
    //         deleteButton.onclick = function () {
    //             let comp = document.getElementById("child_8");
    //             (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
    //             comp.parentNode.removeChild(comp);
    //         };
    //         actionDiv.appendChild(deleteButton);
    //     //mainCanvasDiv.appendChild(deleteButton);
    //     divObj.onclick = function(){ (props && props.updateState) && props.updateState({isNewsLetterPropsUpdaterVisible: true, childId:"child_8", elementType: 'Button' })} ;
    //     divObj.appendChild(mainCanvasDiv);
    //     let moveUpButton = document.createElement('i');
    //     moveUpButton.className = "right-delate-box uparrow";
    //     moveUpButton.style.display = "none";
    //     moveUpButton.title="Move up";
    //     moveUpButton.id = "up_child_8";
    //     moveUpButton.onclick = function () {
    //         let comp = document.getElementById("child_8");
    //         let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
    //         if (dIndex > -1) {
    //             let beforeNode = comp.parentNode.children[dIndex - 1];
    //             if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
    //         }

    //     };
    //     actionDiv.appendChild(moveUpButton);
    //     let moveDownButton = document.createElement('i');
    //     moveDownButton.className = "right-delate-box downarrow";
    //     moveDownButton.style.display = "none";
    //      moveDownButton.title="Move down";
    //     moveDownButton.id = "down_child_8";
    //     moveDownButton.onclick = function () {
    //         let comp = document.getElementById("child_8");
    //         let dIndex = Array.prototype.indexOf.call(document.getElementById("temmplateDiv").children, comp);
    //         if (dIndex > -1) {
    //             let beforeNode = comp.parentNode.children[dIndex + 2];
    //             if (beforeNode) { comp.parentNode.insertBefore(comp, beforeNode); }
    //             else { comp.parentNode.appendChild(comp); }
    //         }

    //     };
    //     actionDiv.appendChild(moveDownButton);
    //     return divObj;
    // }


    const templateTypeChangedHandle = (type) => {
        let mainDivRef = document.getElementById('temmplateDiv');
        switch (type) {
            case 'event': {

                mainDivRef.innerHTML = "";
                mainDivRef.appendChild(getDefaultLogoRef(type));
                setDefaultTitleRef(type);
                mainDivRef.appendChild(getDefaultImgRef(type));
                setDefaultEventRef(type);
                //  setDefaultTemplateBodyRef(type);
                mainDivRef.appendChild(getDefaultButtonRef(type));
                mainDivRef.appendChild(getDefaultSocialLinksRef(type));
                break;
            }
            case 'fundraising': {
                mainDivRef.innerHTML = "";
                mainDivRef.appendChild(getDefaultLogoRef(type));
                setDefaultTitleRef(type);
                mainDivRef.appendChild(getDefaultImgRef(type));
                setDefaultTemplateBodyRef(type);
                mainDivRef.appendChild(getDefaultProgressBarRef(type));
                mainDivRef.appendChild(getDefaultButtonRef(type));
               // mainDivRef.appendChild(getPledgeButtonRef('pledge'));
                mainDivRef.appendChild(getDefaultSocialLinksRef(type));
                break;
            }
            case 'newsletter': {
                let mainDivRef = document.getElementById('temmplateDiv');
                mainDivRef.innerHTML = "";
                mainDivRef.appendChild(getDefaultLogoRef(type));
                setDefaultTitleRef(type);
                mainDivRef.appendChild(getDefaultImgRef(type));
                setDefaultTemplateBodyRef(type);
                mainDivRef.appendChild(getDefaultButtonRef(type));
                mainDivRef.appendChild(getDefaultSocialLinksRef(type));
                break;
            }
            case 'marketingTemplate': {
                let mainDivRef = document.getElementById('temmplateDiv');
                mainDivRef.innerHTML = "";
                mainDivRef.appendChild(getDefaultLogoRef(type));
                setDefaultTitleRef(type);
                mainDivRef.appendChild(getDefaultImgRef(type));
                setDefaultTemplateBodyRef(type);
                mainDivRef.appendChild(getDefaultButtonRef(type));
                mainDivRef.appendChild(getDefaultSocialLinksRef(type));
                break;
            }
            default: {
                initTemplate();
                break;
            }
        }
    }

    const activateCK_editor = (id) => {
        let CKEDITOR = window.CKEDITOR;
        CKEDITOR.on('instanceReady', function (ev) {
            let edi = document.getElementById(ev.editor.id + '_top');
            if (edi) { edi.style.display = 'none'; }
            ev.editor.on('focus', function (e) {
                let edi = document.getElementById(ev.editor.id + '_top');
                if (edi) { edi.style.display = 'block'; }
                let activeChild = document.getElementById(id.replace('editor_', ''));
                if (activeChild) { activeChild.click(); }
            });
            ev.editor.on('blur', function (e) {
                let edi = document.getElementById(ev.editor.id + '_top');
                if (edi) { edi.style.display = 'none'; }
            });
        });
        CKEDITOR.config.toolbar_Basic = [['Bold', 'Italic', 'Underline', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', '-', 'Undo', 'Redo', '-', 'Font', 'FontSize', 'TextColor', 'BGColor', 'Link']];
        CKEDITOR.config.toolbar = 'Basic';
        CKEDITOR.config.width = 680;
        CKEDITOR.config.marginLeft = 10;
        CKEDITOR.config.removePlugins = 'elementspath';
        CKEDITOR.config.autoGrow_minHeight = 100;
        CKEDITOR.config.extraPlugins = 'autogrow';
        CKEDITOR.config.autoGrow_onStartup = true;
        CKEDITOR.config.autoGrow_bottomSpace = 10;
        CKEDITOR.replace(id);

    }

    const loadSavedTemplate = () => {
        setTimeout(function () {
           // let contentId = props.newsLetterTemplate.templateContent.indexOf('id="temmplateDiv"') > -1 ? 'templateFull' : 'temmplateDiv';
            let mainDivRef = document.getElementById('templateFull');
            mainDivRef.innerHTML = props.newsLetterTemplate?.templateContent;
            mainDivRef.ondrop = window.drop;
            mainDivRef.ondragover = window.allowDrop;
            setTimeout(function () {
                let allChildIds = [];
                for (var key in props.templateFunctionRef) {
                    let eleRef = document.getElementById(key);
                    if (eleRef && props.templateFunctionRef.hasOwnProperty(key)) {
                        allChildIds.push(key);
                        let funStr = props.templateFunctionRef[key];
                        let compKey = key.replace('up_', '');
                        compKey = compKey.replace('down_', '');
                        compKey = compKey.replace('delete_', '');
                        funStr = funStr.replace('"child_" + childIndex', '"' + compKey + '"');
                        funStr = funStr.replaceAll('"editor_child_" + childIndex', '"editor_' + compKey + '"');
                        funStr = funStr.replace('function ()', '() =>');
                        funStr = funStr.replace('function()', '() =>');
                        funStr = funStr.replace('function (e)', '(e) =>');
                        funStr = funStr.replace('function(e)', '(e) =>')
                        
                        eleRef.onclick = eval(funStr);
                    }
                }
                
                var ids = document.querySelectorAll('[id]');
                Array.prototype.forEach.call(ids, function (el, i) {
                    if (el.id && (el.id).startsWith('editor_')) {
                        activateCK_editor(el.id)
                    }
                    if(el.id && (el.id).startsWith('child_')){
                       let eleObj = document.getElementById(el.id);
                       eleObj.ondragstart=window.drag;
                       eleObj.class="drag";
                       eleObj.dragenter=window.dragEnter;
                       eleObj.dragover=window.dragOver;
                       eleObj.dragleave=window.dragLeave;
                    }
                });
            }, 1000)
        }, 1000)
    }

    const initTemplate = (type) => {
        props.updateState({ isTemplateInit: true });
        setTimeout(function () {
            // let mainDivRef = document.getElementById('temmplateDiv');
            // mainDivRef.innerHTML = "";
            // mainDivRef.appendChild(getDefaultLogoRef(type));
            // setDefaultTitleRef(type);
            // mainDivRef.appendChild(getDefaultImgRef(type));
            // setDefaultTemplateBodyRef(type);
            // mainDivRef.appendChild(getDefaultButtonRef(type));
            // mainDivRef.appendChild(getDefaultSocialLinksRef(type));

            if (!props.alpideFormBuilderId) {
                templateTypeChangedHandle(props.templateType);
            } else {
                loadSavedTemplate();
            }
        }, 1000);

    }
    
    return (
        <>
            <div className="header">
                <div className="leftHeadingArea">
                    <Input value={props.templateName}
                    maxLength={100}
                    className={"templatename " + (props.invalidTemplateNameClass || "") }
                    placeholder={props.templateType === 'fundraising' ? 'Campaign Name' : props.templateType === 'event' ? 'Event Name' : 'Enter Template Name'}
                    onChange={(e) => {
                        props.updateState({ templateName: e.target.value, invalidTemplateNameClass: '' })
                    }}></Input>
                </div>
                <div className="rightButtonArea">
                    <Button className='tabView' onClick={() => { tabPreviewPopup(props); }}> <img src={TablatLogo} alt='Tablet Preview' title='Tablet Preview' /></Button>
                    <Button className='mobView' onClick={() => { mobilePreviewPopup(props); }}> <img src={MobleLogo} alt='Mobile Preview' title='Mobile Preview' /> </Button>
                    {/* <Button className="grey-button">Preview</Button> */}
                    
                    <Button className='ant-btn ant-btn-primary'
                            onClick={() => {
                                if (props.templateType === 'fundraising' && !props.selectedCampaign) {
                                    return showToasterMessage({ messageType: 'error', description: props.intl.formatMessage({ id: 'fundraisingCampaign.required' }) });
                                }
                                if (!props.templateName) {
                                    props.updateState({ invalidTemplateNameClass:  'input-text-error' })
                                    return showToasterMessage({ messageType: 'error', description: props.templateType === 'marketingTemplate' ? "Template Name is required" : props.templateType === 'fundraising' ? 'Campaign Name is required': props.templateType === 'event' ? 'Event Name is required': 'Newsletter Name is required' });
                                }
                                if(document.getElementById('unsubscribe_URL') && document.getElementById('unsubscribe_URL').style.display !== 'none' && !props.distributionGroupIds){
                                    return showToasterMessage({ messageType: 'error', description: 'DG Name in the footer section is required' });
                                } 
                                const modalData = {
                                    modalBody: props.intl.formatMessage({ id: 'save.confirmation' }),
                                    handleSubmit: () => {
                                        saveTemplate(props);
                                        props.popModalFromStack()
                                    }
                                };
                                props.pushModalToStack(modalData);

                            }}>Save </Button>

                    {/* <Button className='new-butt' onClick={() => {
                                if (props.templateType === 'fundraising' && !props.selectedCampaign) {
                                    return showToasterMessage({ messageType: 'error', description: props.intl.formatMessage({ id: 'fundraisingCampaign.required' }) });
                                }
                                if (!props.templateName) {
                                    props.updateState({ invalidTemplateNameClass:  'input-text-error' })
                                    return showToasterMessage({ messageType: 'error', description: props.templateType === 'fundraising' ? 'Campaign Name is required' : props.templateType === 'event' ? 'Event Name is required' : 'Newsletter Name is required' });
                                }
                                if (document.getElementById('unsubscribe_URL') && document.getElementById('unsubscribe_URL').style.display !== 'none' && !props.distributionGroupId) {
                                    return showToasterMessage({ messageType: 'error', description: 'DG Name in the footer section is required' });
                                }
                                setTimeout(function () {
                                    saveTemplate(props, function (tempObj) {
                                        shareTemplate(tempObj);
                                    });
                                }, 100);
                            }}>Share  <i className="fa fa-share-alt"></i></Button> */}
                </div>
            </div>
            <div className='templateContent'>
                <div className='sideBar'>
                        <h2>Page Setup</h2>
                        <div className='menu'>
                            <Button className="menuItem" onClick={() => {
                                props.updateState({ isNewsLetterThemeUpdaterVisible: true,isNewsLetterPropsUpdaterVisible: false, elementType: 'Theme' })
                            }}>
                                    <div>
                                        <img src={ThemeLogo} alt="" /> 
                                    </div>
                                Settings
                            </Button>
                            <Button className="menuItem"  onClick={() => {
                                props.updateState({ isNewsLetterThemeUpdaterVisible: true,isNewsLetterPropsUpdaterVisible: false, elementType: 'Background' })
                            }}> 
                                <div>
                                    <img src={BackICon} alt="" />
                                </div>
                                Background
                            </Button>
                        </div>
                        <div>
                            <h2>Menu</h2>
                            <Toplinks {...props}/>
                        </div>
                </div>
                <div className='templatePart'>
                    {/* <Toplinks {...props}/> */}
                    
                    <div id="templateFull">
                        <div id='greyBox' style={{
                            background: '#F1F1F1', padding: '60px 0px', width: '100%', zIndex: '99'
                        }}>

                            <div id='whiteBox' style={{
                                border: '#525156 0px solid', background: '#fff', padding: '20px 0px', marginTop: '70px',
                                    margin: '0px auto 0px', borderRadius:'0%',
                                width: '750px',
                                height: '100%',
                                overflowY: 'auto'
                            }}>

                                <div className='menu boxcnt dropzone' style={{margin:"0 auto 30px",position:"relative",textAlign:"center"}} id="temmplateDiv" onDrop={window.drop} onDragOver={window.allowDrop}>
                                    {/* <div id="child_0" style={{ width: "680px", minHeight: "40px", textAlign: "center", padding: "0px 0px 0px", borderRadius: "5px", margin: "10px auto 10px", display: "inline-block", cursor: "pointer" }} onClick={() => {
                                        let comp = document.getElementById("child_0");
                                        (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: comp ? true : false, childId: "child_0", elementType: 'Logo' })
                                    }}>
                                        <div className='newr-logo'>
                                        <img style={{ width: "200px", height: "150px"}} id="dummy_child_0" src={props.logoUrl || LogoShape} alt="" /></div>
                                        <i className="right-delate-box fa fa-trash" id="delete_child_0" style={{ display: "none" }} onClick={() => {
                                            let comp = document.getElementById("child_0");
                                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                                            comp.parentNode.removeChild(comp);
                                        }}></i>

                                    </div>

                                    <div id="child_1" style={{ cursor: "pointer" }} onClick={() => {
                                        let comp = document.getElementById("child_1");
                                        (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false, childId: "child_1", elementType: 'Text' })
                                    }}>
                                        <textarea id="editor_child_1" name="editor_child_1" value="<p style='text-align: center;'><b><span style='font-size: xx-large;'>Add a Catchy Title</span></b></p>" > </textarea>
                                        
                                    </div>

                                    <div id="child_2" style={{ width: "680px", minHeight: "40px", textAlign: "center", padding: "25px 0px 25px", borderRadius: "5px", margin: "10px auto 10px", display: "inline-block", cursor: "pointer" }} onClick={() => {
                                        let comp = document.getElementById("child_2");
                                        (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: comp ? true : false, childId: "child_2", elementType: 'Image' })
                                    }}>
                                        <img id="dummy_child_2" style={{ width: '680px', height: '480px' }} src={props.imageUrl} alt="" />
                                        <i className="right-delate-box fa fa-trash" id="delete_child_2" style={{ display: "none" }} onClick={() => {
                                            let comp = document.getElementById("child_2");
                                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                                            comp.parentNode.removeChild(comp);
                                        }}></i>
                                    </div>

                                    <div id="child_3" style={{ cursor: "pointer" }} onClick={() => {
                                        let comp = document.getElementById("child_3");
                                        (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false, childId: "child_3", elementType: 'Text' })
                                    }}>
                                        <textarea id="editor_child_3" name="editor_child_3" value="Add some text here" > </textarea>
                                        <i className="right-delate-box fa fa-trash" id="delete_child_3" style={{ display: "none", marginTop: '-105px' }} onClick={() => {
                                            let comp = document.getElementById("child_3");
                                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                                            comp.parentNode.removeChild(comp);
                                        }}></i>
                                    </div>

                                    <div id="child_4" style={{ cursor: "pointer",width: "680px", textAlign: "center", borderRadius: "5px", margin: "0px auto 0px", display: "inline-block" }} onClick={() => {
                                        let comp = document.getElementById("child_4");
                                        (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_4", elementType: 'Button' })
                                    }}>
                                            <i className="right-delate-box4 fa fa-trash" id="delete_child_4" style={{ display: "none" }} onClick={() => {
                                            let comp = document.getElementById("child_4");
                                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                                            comp.parentNode.removeChild(comp);
                                        }}></i>
                                        <a id="button_child_4" style={{ float: 'left', lineHeight: "50px", cursor: "pointer",width: "150px", height: "50px", border: "solid 1px #45806c", textAlign: "center", borderRadius: "5px",  margin: "10px auto 10px 255px", background: "#525156",color: "#fff" }} name="button_child_4" >Button </a>
                                        <div style={{clear:'both'}}></div>
                                        
                                    </div>

                                    <div id="child_5" style={{ width: '680px', padding: '30px', margin: '0px auto', fontSize: '24px' }} onClick={() => {
                                        let comp = document.getElementById("child_5");
                                        (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: true, childId: "child_5", elementType: 'Social Links' })
                                    }}>
                                        <span id="facebook_child_5" style={{ background: '#394fa7', cursor: 'pointer', margin: '0px 5px', color: '#fff', textAlign: 'center', padding: '3px 8px', height: '40px', borderRadius: '5px' }}> <i className="fa fa-facebook " aria-hidden="true"></i> </span>
                                        <span id="twitter_child_5" style={{ background: '#5fb8fe', cursor: 'pointer', margin: '0px 5px', color: '#fff', textAlign: 'center', padding: '3px 8px', height: '40px', borderRadius: '5px' }}><i className="fa fa-twitter " aria-hidden="true"></i></span>
                                        <span id="instagram_child_5" style={{ background: '#e14d93', cursor: 'pointer', margin: '0px 5px', color: '#fff', textAlign: 'center', padding: '3px 8px', height: '40px', borderRadius: '5px' }}><i className="fa fa-instagram " aria-hidden="true"></i></span>
                                        <span id="google-plus_child_5" style={{ background: '#dc4b3e', cursor: 'pointer', margin: '0px 5px', color: '#fff', textAlign: 'center', padding: '3px 8px', height: '40px', borderRadius: '5px' }}><i className="fa fa-google-plus " aria-hidden="true"></i></span>
                                        <span id="linkedin_child_5" style={{ background: '#0076b2', cursor: 'pointer', margin: '0px 5px', color: '#fff', textAlign: 'center', padding: '3px 8px', height: '40px', borderRadius: '5px' }}><i className="fa fa-linkedin " aria-hidden="true"></i></span>
                                        <span id="pinterest_child_5" style={{ background: '#bd081c', cursor: 'pointer', margin: '0px 5px', color: '#fff', textAlign: 'center', padding: '3px 8px', height: '40px', borderRadius: '5px' }}><i className="fa fa-pinterest-p " aria-hidden="true"></i></span>
                                        <span id="tumblr_child_5" style={{ background: '#3b445c', cursor: 'pointer', margin: '0px 5px', color: '#fff', textAlign: 'center', padding: '3px 8px', height: '40px', borderRadius: '5px' }}><i className="fa fa-tumblr " aria-hidden="true"></i></span>
                                        <span id="vimeo_child_5" style={{ background: '#4cb5f7', cursor: 'pointer', margin: '0px 5px', color: '#fff', textAlign: 'center', padding: '3px 8px', height: '40px', borderRadius: '5px' }}><i className="fa fa-vimeo " aria-hidden="true"></i></span>
                                        <span id="youtube_child_5" style={{ background: '#e52d27', cursor: 'pointer', margin: '0px 5px', color: '#fff', textAlign: 'center', padding: '3px 8px', height: '40px', borderRadius: '5px' }}><i className="fa fa-youtube " aria-hidden="true"></i></span>
                                        <i className="right-delate-box3 fa fa-trash" id="delete_child_5" style={{display: 'none'}} onClick={() => {
                                            let comp = document.getElementById("child_5");
                                            (props && props.updateState) && props.updateState({ isNewsLetterPropsUpdaterVisible: false });
                                            comp.parentNode.removeChild(comp);
                                        }}></i>
                                        </div> */}
                                        {!props.isTemplateInit && props.isCKeditorReady ? initTemplate(): ''}
                                    <div className="clearfix"></div>

                                </div>
                                {/* <Button className="ant-btn-primary ml10"
                                        onClick={() => {
                                            // let CKEDITOR = window.CKEDITOR;
                                            // Object.keys(CKEDITOR.instances).forEach(editorName => {
                                            //     var hEd = CKEDITOR.instances[editorName];
                                            //     if (hEd) {
                                            //       var output = document.getElementById(editorName.replace("editor_", ""));
                                            //       let divContent = document.createElement('div');
                                            //       divContent.id = editorName;
                                            //       divContent.style.marginLeft = "10px";
                                            //       divContent.style.textAlign= "left";
                                            //       divContent.style.width = "680px";
                                            //       divContent.style.minHeight = "40px";
                                            //       divContent.innerHTML = hEd.getData();
                                            //       output.parentNode.replaceChild(divContent, output);
                                            //       hEd.destroy();
                                            //   }
                                            // });
                                            // let payload = {
                                            //     relationshipId: props.companyInfo.relationshipId,
                                            //     templateContent: document.getElementById('temmplateDiv').innerHTML,
                                            //     templateName: props.templateName || 'Template',
                                            //     templateType: props.templateType || 'membership'
                                            // }
                                            // props.saveTemplate(payload);

                                            
                                        }}
                                    >Share</Button> */}
                            </div>
                        </div>
                    </div>

                    

                    {/* <div className='stikcy-footer'> 

                        <ul> */}
                            
                            {/* <li>
                                <div className='sel-new-left'>
                                    <Tooltip placement="top" title="Template Type">
                                        <Select onChange={(value) => {
                                            const modalData = {
                                                modalBody: <FormattedMessage id='templateType.changed.confirmation' />,
                                                handleSubmit: () => {
                                                    props.updateState({
                                                        templateType: value,
                                                    })
                                                    props.hideModal();
                                                    templateTypeChangedHandle(value);
                                                }
                                            };
                                            props.showModal(modalData);
                                        }}
                                            style={{ width: 150 }}
                                            placeholder="Template Type"
                                            optionFilterProp="children"
                                            value={props.templateType}>
                                            <Option value="newsletter">Newsletter</Option>
                                            <Option value="fundraising">Fundraising Campaign</Option>
                                            <Option value="event">Event</Option>
                                        </Select>
                                    </Tooltip>
                                </div>
                            </li> */}

                        {/* <li><span className='cline'></span></li>
                            <li className='mt-hover'> <span style={{ margin: '0px 0px 0px', position: 'relative', top: '-3px', padding: '0px',cursor:'pointer' }} onClick={() => { tabPreviewPopup(props); }}><img src={TablatLogo} alt='Tablet Preview' title='Tablet Preview' /> </span> </li>
                            <li className='mt-hover'> <span style={{ margin: '0px 0px 0px', position: 'relative', top: '-3px', padding: '0px', cursor:'pointer' }} onClick={() => { mobilePreviewPopup(props); }}><img src={MobleLogo} alt='Mobile Preview' title='Mobile Preview' /> </span> </li>
                            <li><span className='cline'></span></li>
                            <li> <button className='ant-btn grey-button' onClick={() => {
                                const modalData = {
                                    modalBody: props.intl.formatMessage({ id: 'close.confirmation' }),
                                    footer: {cancel: { intlId: 'no' },submit: { intlId: 'yes' }},
                                    handleSubmit: () => {
                                        props.history.goBack();
                                        props.popModalFromStack()
                                    }
                                };
                                props.pushModalToStack(modalData);
                            }}>Close</button></li> */}



                                {/* <button className='new-butt' onClick={() => {
        const modalData = {
        // title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
        modalBody: <NewsLetterShare {...props} style={{ overflow: 'auto' }} />,
        hideFooter: true,
        maskClosable: true,
        hideCloseIcon: true,
        hideTitle: true,
        wrapClassName: 'pay-invoice-modals',
            width: 600,
        };
        props.pushModalToStack(modalData);
        }}>Share  <i class="fa fa-share-alt"></i></button>  */}



                            {/* <li>
                                <Input value={props.templateName}
                                maxLength={100}
                                className={props.invalidTemplateNameClass}
                                    placeholder={props.templateType === 'fundraising' ? 'Campaign Name' : props.templateType === 'event' ? 'Event Name' : 'Newsletter Name'}
                                    onChange={(e) => {
                                        props.updateState({ templateName: e.target.value, invalidTemplateNameClass: '' })
                                    }}></Input>
                            </li>


                            <li>  <button className='ant-btn ant-btn-primary'
                                onClick={() => {
                                    if (props.templateType === 'fundraising' && !props.selectedCampaign) {
                                        return showToasterMessage({ messageType: 'error', description: props.intl.formatMessage({ id: 'fundraisingCampaign.required' }) });
                                    }
                                    if (!props.templateName) {
                                        props.updateState({ invalidTemplateNameClass:  'input-text-error' })
                                        return showToasterMessage({ messageType: 'error', description: props.templateType === 'fundraising' ? 'Campaign Name is required': props.templateType === 'event' ? 'Event Name is required': 'Newsletter Name is required' });
                                    }
                                    if(document.getElementById('unsubscribe_URL') && document.getElementById('unsubscribe_URL').style.display !== 'none' && !props.distributionGroupId){
                                        return showToasterMessage({ messageType: 'error', description: 'DG Name in the footer section is required' });
                                    } 
                                    const modalData = {
                                        modalBody: props.intl.formatMessage({ id: 'save.confirmation' }),
                                        handleSubmit: () => {
                                            saveTemplate(props);
                                            props.popModalFromStack()
                                        }
                                    };
                                    props.pushModalToStack(modalData);

                                }}>Save </button> </li>
                            <li><span className='cline'></span></li>

                            <li>
                                <button className='new-butt' onClick={() => {
                                    if (props.templateType === 'fundraising' && !props.selectedCampaign) {
                                        return showToasterMessage({ messageType: 'error', description: props.intl.formatMessage({ id: 'fundraisingCampaign.required' }) });
                                    }
                                    if (!props.templateName) {
                                        props.updateState({ invalidTemplateNameClass:  'input-text-error' })
                                        return showToasterMessage({ messageType: 'error', description: props.templateType === 'fundraising' ? 'Campaign Name is required' : props.templateType === 'event' ? 'Event Name is required' : 'Newsletter Name is required' });
                                    }
                                    if (document.getElementById('unsubscribe_URL') && document.getElementById('unsubscribe_URL').style.display !== 'none' && !props.distributionGroupId) {
                                        return showToasterMessage({ messageType: 'error', description: 'DG Name in the footer section is required' });
                                    }
                                    setTimeout(function () {
                                        saveTemplate(props, function (tempObj) {
                                            shareTemplate(tempObj);
                                        });
                                    }, 100);
                                }}>Share  <i className="fa fa-share-alt"></i></button>
                            </li> */}

                        {/* </ul>

                        
                    </div> */}

                </div>
            </div>

        </>
    );

}

export default injectIntl(NewsLetterCompo);
