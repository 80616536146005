import { E_STORE } from "../static/constants";

const initialState ={
    allPageListData:[],
    pagePublishedData:[],
    policyPageData:{},
    ecomDomainData:{},
    couponList: { 1: []},
    couponCount: 0,
    couponUsageList: {},
    couponUsageCount: 0,
}

const PageListReducer = (state = initialState, action) => {
    switch (action.type) {
    case E_STORE.ALL_PAGE_LIST:
        return { ...state, allPageListData: action.data };
    case E_STORE.PUBLISHED_PAGE_DATA:
        return {...state , pagePublishedData: action.data};
    case E_STORE.POLICY_PAGE_DATA:
        return {...state , policyPageData: action.data};
    case E_STORE.ECOMSUBDOMAIN_ID:
        return {...state , ecomDomainData: action.data};
    case E_STORE.GET_COUPON_LIST:
        return {...state , couponList: {
            ...state.couponList,
            [action.data.pageNumber || 1]: action.data.list
        }};
    case E_STORE.GET_COUPON_COUNT:
        return {...state , couponCount: action.data};
    case E_STORE.COUPON_USAGE_LIST:
        return {...state , couponUsageList: {
            ...state.couponUsageList,
            [action.data.pageNumber || 1]: action.data.list
        }};
    case E_STORE.COUPON_USAGE_COUNT:
        return {...state , couponUsageCount: action.data};
    default:
        return state;
    }
};


  export default PageListReducer;