import React, { Fragment, Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Divider } from 'antd';

class SelectVariantComponent extends Component {
  

  render() {
   

    return (
      <Fragment>
        {( this.props?.data?.formData?.product?.inventoryItemVariantList || []).map((variant, i) => {
          return (
            <div className="cursor-pointer" onClick={() => {
              this.props.handleSubmit(variant);
            }}>
              {(variant.attributeValue1 || '') + (variant.attributeValue2 ? ' | ' : '') + (variant.attributeValue2 || '') + '' + (variant.attributeValue3 ? ' | ' + variant.attributeValue3 : '')}
            <Divider style={{ margin: '5px 0'}}></Divider>
            </div>
          );
        })}
      </Fragment >
    )
  }

}

export default Form.create({ name: 'Select_variant_component' })(injectIntl(SelectVariantComponent));
