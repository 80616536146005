import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RegistrationPaymentPlanComponent from '../../../../../components/modal/modalBody/settings/RegistrationPaymentPlan';

class RegistrationPaymentPlan extends Component {

    constructor(props){
        super(props);
        this.state= {
            emaildrawerVisible: false,
            subject: '',
            emailBody: '',
            period: '',
            startDate: new Date(),
            endDate: new Date(),
          //  customerId: props.salesInvoiceDetail.customerId,
            isBulkInvoice: props.isBulkInvoice || false,
            ...props.recurringInvoiceSettings
        }
    }
    componentDidMount() {
        //const companyInfo = this.props.companyInfo || {};
    }

   
    render() {
        return <div>
            <RegistrationPaymentPlanComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPaymentPlan);