import React from 'react';
import { Tabs } from 'antd';
import DefaultLedger from './DefaulLedger';
import BankInfo from './BankInfo.js';
import Address from './Address.js';
import Company from './Company.js';
//import Employee from './Employee.js';
//import CostCenter from './CostCenter.js';
import AboutCompany from './AboutCompany';
import TaxInformation from './TaxInformation';
import Settings from './Settings';
import OnlineThemes from './OnlineThemes';
import LabelConfig from './LabelConfig.js';
import './index.scss';


const { TabPane } = Tabs;
function callback(key, props) {
    const payload = { relationshipId: props.companyInfo.relationshipId, pageNumber: 1, pageSize: 100 };
    props.updateState({ pageSize: 100, pageNumber: 1 })
    if (key === '1') {
    }else if (key === '2') {
    }else if (key === '3') {
        props.fetchAllEmployees(payload);
    }else if (key === '9') {
        props.fetchTaxIdentifications(payload);
        props.fetchRelationshipTaxIdentifications(payload);
    } 
}

const CompanyInfoComponent = (props) => {
    return (
        <Tabs onChange={(key) => { callback(key, props); }} type="line" animated={false}>
            <TabPane tab="Company" key="1">
                    <Company {...props}/>
            </TabPane>
            <TabPane tab="About Organization" key="2">
                <AboutCompany {...props}/>
            </TabPane>
                {/* <TabPane tab="Employee" key="3">
                    <Employee {...props}/>
            </TabPane> */}
            <TabPane tab="Address" key="4">
                <Address {...props}/>
            </TabPane>
                {/* <TabPane tab="Cost Center" key="5">
                    <CostCenter {...props}/>
            </TabPane> */}
            <TabPane tab="Default Ledger" key="7">
                <DefaultLedger {...props}/>
            </TabPane>
            <TabPane tab="Bank Details" key="8">
                <BankInfo {...props}/>
            </TabPane>
            <TabPane tab="Tax Identification" key="9">
                <TaxInformation {...props}/>
            </TabPane>
            <TabPane tab="Settings" key="10">
                <Settings {...props}/>
            </TabPane>
            <TabPane tab="Online Branding" key="11">
                <OnlineThemes {...props}/>
            </TabPane>
            {props.companyInfo?.industryName === "Education"?
            <TabPane tab="Label Config" key="12">
                <LabelConfig {...props} />
            </TabPane>
            :""
            
            }
        </Tabs>
    );

}


export default CompanyInfoComponent;
