import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserManagementComponent from '../../../components/ShowSettings/userManagement';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { fetchAllUsers, deleteUsers } from "./action"

class UserManagement extends Component {

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        this.props.fetchAllUsers({relationshipId: companyInfo.relationshipId});
    }

    render() {
        return <UserManagementComponent {...this.props} updateState={(data) =>{
            this.setState(data);
        }}/>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        isLoading: state.common.isLoading,
        allCompanyUsers: state.settings.allCompanyUsers,
        isAlifApp: state.common.isAlifApp,
        listLoading: state.common.listLoading,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal, 
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    fetchAllUsers,
    deleteUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
