import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportComponent from '../../../../../components/modal/modalBody/customer/Import';
import { fetchDataIfNeeded } from '../../../../../utils';
import {
    fetchPaymentTerms, addPaymentTerm, deletePaymentTerm,
    fetchBusinessTypes, addBusinessType, deleteBusinessType,
    fetchIndustries, addIndustry, deleteIndustry,
    fetchSources, addCustomerSource, deleteCustomerSource, hideModal
} from '../../../../../actions/commonActions';

// import {
//     fetchCustomers
// } from '../../../../../containers/customer/Listing/action';
import { importCustomer, downloadExelFile, getCustomerImportHeader } from './action';


class ImportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            isuploded: false,
          
        };

    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId };
        this.props.getCustomerImportHeader(companyInfo.relationshipId, this.props)
        fetchDataIfNeeded('fetchSources', 'sources', this.props, payload);
        fetchDataIfNeeded('fetchBusinessTypes', 'businessTypes', this.props, payload);
        fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
        fetchDataIfNeeded('fetchIndustries', 'industries', this.props, payload);
    }
    componentWillReceiveProps(){

    //     if(this.props.importCustomerHeader.length ){
    //         let headerValues = this.props.importCustomerHeader?.map((item)=>{
    //                    return item.columnName
    //         })
    //         let headerMandatoryValues = this.props.importCustomerHeader?.map((item)=>{
    //             if(item.mandatory){
    //                 return true

    //             }else{
    //                  return false
    //             }
    //  })
    //         this.setState({
    //             headerValues:headerValues,
    //             headerMandatoryValues:headerMandatoryValues
    //           });
    //     }
    }

    render() {
        return <ImportComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        sources: state.common.sources,
        businessTypes: state.common.businessTypes,
        paymentTerms: state.common.paymentTerms,
        industries: state.common.industries,
        companyInfo: state.common.companyInfo,
        importCustomerHeader: state.common.customerImportHeader,
        headerMandatoryValues: state.common.headerMandatoryValues,
        headerValues: state.common.headerValues,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSources,
    addCustomerSource,
    deleteCustomerSource,
    fetchPaymentTerms,
    addPaymentTerm,
    deletePaymentTerm,
    fetchIndustries,
    addIndustry,
    deleteIndustry,
    fetchBusinessTypes,
    addBusinessType,
    importCustomer,
    deleteBusinessType,
    hideModal,
    getCustomerImportHeader, downloadExelFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
