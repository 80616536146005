import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  FilePdfOutlined,
  MailOutlined,
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, Select,Skeleton } from "antd";
import * as find from "lodash.find";
import ReactHtmlParser from "react-html-parser";
//import { Link } from 'react-router-dom';
import config from "../../../../../config/environmentConfig";
import {
  CONSTANTS,
  TRANSACTION_TYPES,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
} from "../../../../../static/constants";
import {
  base64Toblob,
  capitalizeFirstLetter,
  getAWSFileURL,
  getMomentDateForUIReadOnly,
} from "../../../../../utils";
import { ImageCropper } from "../../../../../components/general/ImageCropper";
import { CustomUploader } from "../../../../../components/general/CustomUploader";
// import { LogoComponent } from '../../../../general/LogoComponent';
import { AWSFileTable } from "../../../../general/AWSFileTable";
import StandardSalesEnquiry from "../../../../../containers/customer/salesEnquiry/StandardSalesEnquiry";
import StandardSalesQuote from "../../../../../containers/customer/salesQuote/StandardSalesQuote";
import StandardPurchaseRequest from "../../../../../containers/supplier/purchaseRequest/StandardPurchaseRequest";

const { Option } = Select;

const InquiryDatas = (props) => {

  const { inquiryData, companyInfo } = props;
  const { inquiryDetailsList } = inquiryData;
  const billingAddress =
    find(inquiryData.boLocationInquiryList, {
      locationType: CONSTANTS.BILLING_ADDRESS,
    }) || {};
  const relationshipAddress =
    find(inquiryData.boLocationInquiryList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};
    
  let isColumnEmpty = {
    product: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
  };
  if (inquiryData) {
    isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, inquiryData);
  }

  const customProps = {
    type: CONSTANTS.TYPE_IMAGE,
    imgUrl:
      getAWSFileURL(
        (inquiryData || {}).stampAwsKey,
        config.BUCKET_NAME.BO_SALES_INQUIRY
      ) || "",
    height: 60,
    isSignature: true,
    width: 60,
    name: "file",
    action: null,
    buttonName: "Upload Customer PO",
    onChange: (file) => {
      if (file[0]?.originFileObj) {
        var reader = new FileReader();

        reader.onload = function (e) {
          props.showModal({
            title: (
              <Fragment>
                <div className="ant-modal-title">Crop Image</div>
              </Fragment>
            ),
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                onClick={(e) => {}}
                handleSubmit={(newUrl) => {
                  props.uploadStamp({
                    customerId: inquiryData.customerId,
                    inquiryMasterId: inquiryData.inquiryMasterId,
                    rid: inquiryData.relationshipId,
                    objectKey: inquiryData.stampAwsKey,
                    bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                    file: base64Toblob(newUrl),
                  });
                }}
                {...props}
                url={e.target.result}
              />
            ),
            width: 800,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
          });
        };

        reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
      }
    },
  };

  const getPayloadForPdf = () => {
    return {
      fileName: (inquiryData.inquiryNumber || "Inquiry") + ".pdf",
      transactionName: "inquiry",
      inquiryData: inquiryData,
      relationshipAddress: relationshipAddress,
      billingAddress: billingAddress,
      companyInfo: companyInfo,
      isColumnEmpty: isColumnEmpty,
      bucketName: config.BUCKET_NAME.BO_SALES_INQUIRY,
      stampAwsKey: inquiryData.stampAwsKey,
    };
  };

  const generatePDF = () => {
    let payload = getPayloadForPdf();
    props.generatePDF(payload);
  };

  const printPdf = () => {
    let payload = getPayloadForPdf();
    props.printPDF(payload);
  };

  const openEmailDrawer = (props) => {
    let payload = getPayloadForPdf();
    payload.fileDataReqeired = true;
    payload.customerId = inquiryData.customerId;
    payload.inquiryMasterId = inquiryData.inquiryMasterId;
    props.updateState({
      emailDrawerVisible: true,
      txData: payload,
      type: TRANSACTION_TYPES.INQUIRY,
    });
  };

  const openAuditDrawer = (props, data) => {
    props.updateState({
      title: <div className="drawer-title">Audit Trail</div>,
      auditDrawerVisible: true,
      deletApiUrl: "deleteAuditTrailInquiry",
      masterKey: "inquiryMasterId",
      masterKeyValue: data.inquiryMasterId,
      apiUrl: "saveAuditTrailInquiry",
      customerId: data.customerId,
      trailList: data.auditTrailInquiryList,
      updateTrailListInTransaction: (list) => {
        data.auditTrailInquiryList = list;
      },
    });
  };

  const handleConvertTo = (actionName) => {
    switch (actionName) {
      case "Sales Quote": {
        props.popModalFromStack();
        props.pushModalToStack({
          modalBody: (
            <StandardSalesQuote
              {...props}
              inquiryMasterId={inquiryData.inquiryMasterId}
              customerId={inquiryData.customerId}
              customerName={inquiryData.customerName}
            />
          ),
          width: "100%",
          hideTitle: true,
          hideFooter: true,keyboard:false,
          wrapClassName: "new-transaction-wrapper",
        });
        // props.history.push({
        //     pathname: '/admin/create-sales-quote',
        //     state: {
        //         inquiryMasterId: inquiryData.inquiryMasterId,
        //         customerId: inquiryData.customerId,
        //         customerName: inquiryData.customerName,
        //     }
        // })
        break;
      }
      case "Purchase Request": {
        props.popModalFromStack();
        props.pushModalToStack({
          modalBody: (
            <StandardPurchaseRequest
              {...props}
              inquiryMasterId={inquiryData.inquiryMasterId}
              customerId={inquiryData.customerId}
              customerName={inquiryData.customerName}
            />
          ),
          width: "100%",
          hideTitle: true,
          hideFooter: true,keyboard:false,
          wrapClassName: "new-transaction-wrapper",
        });
        // props.history.push({
        //     pathname: '/admin/pr-create',
        //     state: {
        //         inquiryMasterId: inquiryData.inquiryMasterId,
        //         customerId: inquiryData.customerId,
        //         customerName: inquiryData.customerName,
        //     }
        // })
        break;
      }
      default: {
        break;
      }
    }
  };

  // const updatePRStatus = (props, inquiryData) => {
  //     let payload = { formData: {maxLength: 500}, txData: inquiryData };
  //     const formData = payload.formData;
  //     const { pushModalToStack } = props;

  //     if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
  //         addItemConfirmationModal(props, formData.textBoxValue, inquiryData);
  //     } else {
  //         formData.textBoxValue= inquiryData.statusPRMessage;
  //         const data = {
  //             title: <FormattedMessage id='modal.txDetails.inquiryStatus' defaultMessage='' />,
  //             formData,
  //             modalData: { modalType: MODAL_TYPE.UPDATE_STATUS, data: payload, transactionName: CONSTANTS_TRANSACTIONS.SALES_ORDER },
  //             handleSubmit: (formData = {}) => {
  //                 if (formData.textBoxValue && formData.textBoxValue.length) {
  //                     addItemConfirmationModal(props, formData.textBoxValue, inquiryData);
  //                 } else {
  //                     data.formData.submittedOnce = true;
  //                     pushModalToStack(data);
  //                 }
  //             }
  //         };
  //         pushModalToStack(data);
  //     }
  // }

  // const addItemConfirmationModal = (props, text, inquiryData) => {
  //     const { showModal, companyInfo } = props;
  //     const labelName = <FormattedMessage id='modal.txDetails.inquiryStatus' defaultMessage='' />;
  //     const modalBody = <span>
  //         <FormattedMessage
  //             id='setItem.confirmation'
  //             values={{ value: `${text} to ` }}
  //             defaultMessage=''
  //         />
  //         {labelName} ?
  //   </span>;

  //     let payload = {
  //         statusPRMessage: text,
  //         relationshipId: companyInfo.relationshipId,
  //         inquiryMasterId: inquiryData.inquiryMasterId,
  //         customerId: inquiryData.customerId
  //     };

  //     const modalData = {
  //         modalBody,
  //         handleSubmit: () => {
  //            props.updatePRStatus(payload, props);
  //             props.hideModal();
  //             props.popModalFromStack();
  //         },
  //     };
  //     showModal(modalData);
  // }

  const updateUserStatusConfirmationModal = (props, text, color, txData) => {
    const { showModal, companyInfo } = props;
   
    const modalBody = (
      <span>
        <FormattedMessage
          id="setItem.confirmation"
          defaultMessage={``}
        />
        
      </span>
    );

    let payload = {
      userStatus: text,
      userStatusColor: color,
      relationshipId: companyInfo.relationshipId,
      inquiryMasterId: txData.inquiryMasterId,
      customerId: txData.customerId,
    };

    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.updateUserStatus(payload, props);
        props.hideModal();
        props.popModalFromStack();
      },
    };
    showModal(modalData);
  };

  const updateUserStatus = (props, txData) => {
    let payload = { formData: {} };
    const formData = payload.formData;
    payload.txData = txData;
    const { pushModalToStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      updateUserStatusConfirmationModal(props, formData.textBoxValue, txData);
    } else {
      const data = {
        title: (
          <FormattedMessage id="addItem.text.userStatus" defaultMessage="" />
        ),
        formData,
        hideFooter: true,
        modalData: {
          modalType: MODAL_TYPE.USER_STATUS,
          data: payload,
          transactionName: CONSTANTS_TRANSACTIONS.INQUIRY,
        },
       
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            updateUserStatusConfirmationModal(
              props,
              formData.textBoxValue,
              formData.statusColor,
              txData
            );
          } else {
            data.formData.submittedOnce = true;
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };
  const [showComp, setShowComp] = useState(false);
  useEffect(()=>{
    const delay = setTimeout(()=>{
      setShowComp(true);
    }, 400);
    return ()=> clearTimeout(delay);
  }, []);

  return (
  <div className={"custom-modal"}>

      <i className="close-icon">
        <svg
          width="17"
          height="17"
          onClick={() => {
            props.popModalFromStack();
          } }
        >
          <title>
            <FormattedMessage id="button.close.label" defaultMessage="" />
          </title>
          <path
            fill="#67757d"
            fillRule="evenodd"
            d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
        </svg>
      </i><div className="modal-dialog">
        <div className="modal-content full-width">
          {showComp ? 
          <><div className="modal-header align-left">
                {inquiryData.documentName || "Inquiry"}
              </div><div className="inner-content">
                  <Row>
                    <Col span={8}>
                      <div className="d-inline-flex w-100">
                        {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
                        {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 + '/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/' + (props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()} /> */}

                        <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>
                          <div className="read-only-content pl10">
                            <div className="title lh-adjust pb5">
                              {props.companyInfo.storeName}
                            </div>
                            {getAddressInfoFragment(relationshipAddress, companyInfo)}
                            {getTaxIdentificationsFragment(
                              ((inquiryData.additionalInfoList || [])[0] || {})
                                .relationshipTaxIdentifications
                            )}
                          </div>
                        </Skeleton>
                      </div>
                    </Col>

                    <Col span={8}>
                      <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                        <div className="read-only-content">

                        <div>
                                
                                <div className='title'> {inquiryData.customerName}</div>
                                     
                                 
                                 <div>
                                     {getAddressInfoFragment(billingAddress, (inquiryData.additionalInfoList ? inquiryData.additionalInfoList[0] || {} : {}))}
                                     {getTaxIdentificationsFragment(((inquiryData.additionalInfoList || [])[0] || {}).taxIdentifications)}
                                 </div>
                                 <div>
                                 {(inquiryData.additionalInfoList ? inquiryData.additionalInfoList[0] || {} : {})?.customerEmail}
                                     {(inquiryData.additionalInfoList ? inquiryData.additionalInfoList[0] || {} : {})?.customerPhone}
                                 </div>
                                 </div>
                                
                                 <div className="">
                                     {getContactNameFragment((inquiryData.additionalInfoList ? inquiryData.additionalInfoList[0] || {} : {}))}
                                    
                                 </div>
                        </div>
                      </Skeleton>
                    </Col>

                    <Col span={5} offset={3}>
                      <Skeleton loading={props.detailLoading} paragraph={{ rows: 5 }} active>

                        <div className="read-only-content">
                          <Row>
                            <Col span={10} className="text-right title">
                              <FormattedMessage
                                id="common.inquiryNumber"
                                defaultMessage="" />
                            </Col>
                            <Col span={14}>{inquiryData.inquiryNumber}</Col>
                          </Row>

                          <Row>
                            <Col span={10} className="text-right title">
                              <FormattedMessage
                                id="common.dateCreated"
                                defaultMessage="" />
                            </Col>
                            <Col span={14}>
                              {inquiryData.inquiryDate &&
                                getMomentDateForUIReadOnly({
                                  date: inquiryData.inquiryDate,
                                  format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                })}
                            </Col>
                          </Row>

                          {inquiryData.inquiryExpirationDate ? (
                            <Row>
                              <Col span={10} className="text-right title">
                                <FormattedMessage
                                  id="modal.txDetails.inquiry.inquiryClosingDate"
                                  defaultMessage="" />
                              </Col>
                              <Col span={14}>
                                {inquiryData.inquiryExpirationDate &&
                                  getMomentDateForUIReadOnly({
                                    date: inquiryData.inquiryExpirationDate,
                                    format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                  })}
                              </Col>
                            </Row>
                          ) : ''}
                        </div>
                      </Skeleton>
                    </Col>
                  </Row>

                  <div className="modal-table-container vertical-space">
                    <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage
                                id="common.customer.inquiryNumber"
                                defaultMessage="" />
                            </th>
                            <th>
                              <FormattedMessage
                                id="modal.txDetails.common.refNumber"
                                defaultMessage="" />
                            </th>
                            <th>
                              <FormattedMessage
                                id="common.salesPerson"
                                defaultMessage="" />
                            </th>
                            <th>
                              <FormattedMessage
                                id="modal.txDetails.common.priority"
                                defaultMessage="" />
                            </th>
                            <th>
                              <FormattedMessage
                                id="modal.txDetails.common.project"
                                defaultMessage="" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{inquiryData.customerInquiryNumber || "-"}</td>
                            <td>{inquiryData.refNumber || "-"}</td>
                            <td>{inquiryData.salesPersonFullName || "-"}</td>
                            <td>{inquiryData.priority || "-"}</td>
                            <td>
                              {inquiryData.projectName ? inquiryData.projectName : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Skeleton>
                  </div>

                  <div className="modal-table-container mb15">
                    <Skeleton loading={props.detailLoading} paragraph={{ rows: 4 }} active>
                      <table className="transaction-details-table">
                        <thead>
                          <tr>
                            <th className="cell-size-50">#</th>
                            {(!isColumnEmpty.product || !isColumnEmpty.description) && (
                              <th>
                                {!isColumnEmpty.product && (
                                  <span>
                                    {" "}
                                    <FormattedMessage
                                      id="modal.txDetails.common.product"
                                      defaultMessage="" />
                                  </span>
                                )}
                                {!isColumnEmpty.product &&
                                  !isColumnEmpty.description && <span> & </span>}
                                {!isColumnEmpty.description && (
                                  <span>
                                    {" "}
                                    <FormattedMessage
                                      id="modal.txDetails.common.description"
                                      defaultMessage="" />
                                  </span>
                                )}
                              </th>
                            )}
                            {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                            {!isColumnEmpty.qty && (
                              <th className="cell-size-100">
                                <FormattedMessage
                                  id="modal.txDetails.common.qty"
                                  defaultMessage="" />
                              </th>
                            )}
                            {!isColumnEmpty.uomName && <th>UOM</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {(inquiryDetailsList || []).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className="cell-size-50">{i + 1}</td>
                                {(!isColumnEmpty.product ||
                                  !isColumnEmpty.description) && (
                                    <td>
                                      <strong>{getVariantFragment(item)}</strong>
                                      {!isColumnEmpty.description && (
                                        <div className="description-cell">
                                          {ReactHtmlParser(item.description || "-")}
                                        </div>
                                      )}
                                    </td>
                                  )}
                                {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                                {!isColumnEmpty.qty && (
                                  <td className="cell-size-100">{item.quantity}</td>
                                )}
                                {!isColumnEmpty.uomName && (
                                  <td>{item.uomName || "-"}</td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Skeleton>
                  </div>

                  <Row>

                    <Col span={9} className="read-only-content">
                      <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>
                        {inquiryData.docDetailList &&
                          inquiryData.docDetailList.length ? (
                          <Row>
                            <Col span={24} className="title">
                              <FormattedMessage
                                id="modal.txDetails.common.attachments"
                                defaultMessage="" />
                            </Col>
                            <Col span={12}>
                              <AWSFileTable
                                {...props}
                                docDetailList={inquiryData.docDetailList}
                                fetchTxDetail={(data) => {
                                  props.fetchInquiryDetail({
                                    customerId: inquiryData.customerId,
                                    inquiryMasterId: inquiryData.inquiryMasterId,
                                    relationshipId: inquiryData.relationshipId,
                                  });
                                } } />
                              {/* {
inquiryData.docDetailList.map((doc) => {
return <span className={"cursor-pointer"} onClick={()=>{
props.downloadFileFromBucket({
bucketName: doc.bucketName,
awsKeyName: doc.awsKeyName,
fileName: doc.fileName
})
}}>{doc.fileName} &nbsp;</span>
})
} */}
                            </Col>
                          </Row>
                        ) : null}

                        {inquiryData.remarksInternal && (
                          <><Row>
                          <Col span={7} className="remarks-heading">
                            <FormattedMessage
                              id="modal.txDetails.common.internalRemarks"
                              defaultMessage="" />
                          </Col>
                        </Row><Col span={17}>
                            {ReactHtmlParser(inquiryData.remarksInternal)}
                          </Col></>
                        )}
                        {inquiryData.remarksCustomer && (
                          <><Row className="mt10">
                          <Col span={7} className="remarks-heading">
                            <FormattedMessage
                              id="modal.txDetails.common.customerRemarks"
                              defaultMessage="" />
                          </Col>
                        </Row><Col span={17}>
                            {ReactHtmlParser(inquiryData.remarksCustomer)}
                          </Col></>
                        )}
                      </Skeleton>
                    </Col>
                    <Col offset={7} span={8}>
                      <Skeleton loading={props.detailLoading} paragraph={{ rows: 2 }} active>
                        <div
                          className="upload-link new-pic"
                          title={props.intl.formatMessage({
                            id: "uploadSignature.tooltip",
                          })}
                          style={{ width: "50px", height: "50px" }}
                        >
                          <CustomUploader {...customProps} />
                          {inquiryData.stampAwsKey && (
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                props.deleteStamp(
                                  {
                                    customerId: inquiryData.customerId,
                                    inquiryMasterId: inquiryData.inquiryMasterId,
                                    relationshipId: inquiryData.relationshipId,
                                    objectKey: inquiryData.stampAwsKey,
                                  },
                                  props
                                );
                              } }
                            >
                              {" "}
                              <FormattedMessage
                                id="modal.txDetails.common.deleteStamp"
                                defaultMessage="" />{" "}
                            </div>
                          )}
                        </div>
                      </Skeleton>
                    </Col>
                  </Row>

                  <div className="contact-details">
                    {inquiryData.footer && (
                      <Fragment>{ReactHtmlParser(inquiryData.footer)}</Fragment>
                    )}
                  </div>
                </div><div className=" button-group-action header-control">
                  <div className="small-btn-group">
                    <Button
                      style={{ height: "38px" }}
                      title={props.intl.formatMessage({
                        id: "modal.txDetails.common.edit",
                        defaultMessage: "",
                      })}
                      icon={<EditOutlined />}
                      onClick={() => {
                        props.popModalFromStack();
                        props.pushModalToStack({
                          modalBody: (
                            <StandardSalesEnquiry
                              {...props}
                              customerId={inquiryData.customerId}
                              inquiryMasterId={inquiryData.inquiryMasterId}
                              update={true} />
                          ),
                          width: "100%",
                          hideTitle: true,
                          hideFooter: true,
                          keyboard: false,
                          wrapClassName: "new-transaction-wrapper",
                        });
                        // props.history.push({
                        //     pathname: '/admin/sales-inquiry-create',
                        //     state: {
                        //         customerId: inquiryData.customerId,
                        //         inquiryMasterId: inquiryData.inquiryMasterId,
                        //         update: true
                        //     }
                        // })
                      } }
                    ></Button>
                    <Button
                      style={{ height: "38px" }}
                      title={props.intl.formatMessage({
                        id: "modal.txDetails.common.clone",
                        defaultMessage: "",
                      })}
                      icon={<CopyOutlined />}
                      onClick={() => {
                        props.popModalFromStack();
                        props.pushModalToStack({
                          modalBody: (
                            <StandardSalesEnquiry
                              {...props}
                              customerId={inquiryData.customerId}
                              inquiryMasterId={inquiryData.inquiryMasterId}
                              clone={true}
                              update={false} />
                          ),
                          width: "100%",
                          hideTitle: true,
                          hideFooter: true, keyboard: false,
                          wrapClassName: "new-transaction-wrapper",
                        });
                        // props.history.push({
                        //     pathname: '/admin/sales-inquiry-create',
                        //     state: {
                        //         customerId: inquiryData.customerId,
                        //         inquiryMasterId: inquiryData.inquiryMasterId,
                        //         clone: true
                        //     }
                        // })
                      } }
                    ></Button>
                    <Button
                      style={{ height: "38px" }}
                      title={props.intl.formatMessage({
                        id: "modal.txDetails.common.delete",
                        defaultMessage: "",
                      })}
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        props.showModal({
                          modalBody: "Are you sure you want to delete this transaction?",
                          handleSubmit: () => {
                            props.popModalFromStack();
                            props.deleteSalesEnquiry(
                              {
                                inquiryMasterId: inquiryData.inquiryMasterId,
                                customerId: inquiryData.customerId,
                                relationshipId: inquiryData.relationshipId,
                              },
                              props
                            );
                          },
                        });
                      } }
                    ></Button>
                  </div>
                  <div className="small-btn-group">
                    <Button
                      style={{ height: "38px" }}
                      title={props.intl.formatMessage({
                        id: "modal.txDetails.common.email",
                        defaultMessage: "",
                      })}
                      icon={<MailOutlined />}
                      onClick={() => {
                        openEmailDrawer(props);
                      } }
                    ></Button>

                    <Button
                      style={{ height: "38px" }}
                      title={props.intl.formatMessage({
                        id: "modal.txDetails.common.downloadPDF",
                        defaultMessage: "",
                      })}
                      icon={<FilePdfOutlined />}
                      onClick={() => {
                        generatePDF();
                      } }
                    ></Button>
                    <Button
                      style={{ height: "38px" }}
                      title={props.intl.formatMessage({
                        id: "modal.txDetails.common.print",
                        defaultMessage: "",
                      })}
                      icon={<PrinterOutlined />}
                      onClick={() => {
                        printPdf();
                      } }
                    ></Button>
                  </div>
                  <div className="small-btn-group">
                    <Button
                      style={{ height: "38px" }}
                      title={props.intl.formatMessage({
                        id: "modal.txDetails.common.auditTrail",
                        defaultMessage: "",
                      })}
                      icon={<HistoryOutlined />}
                      onClick={() => {
                        openAuditDrawer(props, inquiryData);
                      } }
                    ></Button>
                  </div>

                  {/* <Button
type="primary"
icon="printer"
>
Print
</Button> */}
                  {/* {
(inquiryData.statusRFQ !== 'Converted') && <Button

onClick={() => {
props.popModalFromStack();

}}
type="primary"
icon={<FileTextOutlined />}
>
<Link
to={
{
pathname: '/admin/rfq-create',
state: {
  inquiryMasterId: inquiryData.inquiryMasterId
}
}}
>
Convert to RFQ
</Link>
</Button>
} */}

                  {(inquiryData.statusQuote !== "converted" ||
                    inquiryData.statusPR !== "converted") && (
                      <div className="small-btn-group">
                        <Select
                          defaultValue="Convert To"
                          style={{ width: 120 }}
                          onChange={handleConvertTo}
                        >
                          {inquiryData.statusQuote !== "converted" && (
                            <Option value="Sales Quote">
                              <FormattedMessage
                                id="modal.txDetails.inquiry.convertTo.salesQuote"
                                defaultMessage="" />
                            </Option>
                          )}
                          {inquiryData.statusPR !== "converted" && (
                            <Option value="Purchase Request">
                              <FormattedMessage
                                id="modal.txDetails.inquiry.convertTo.purchaseRequest"
                                defaultMessage="" />
                            </Option>
                          )}
                        </Select>
                      </div>
                    )}

                  <div className="small-btn-group">
                    <Button
                      style={{ width: "auto", height: "38px" }}
                      onClick={() => {
                        updateUserStatus(props, inquiryData);
                      } }
                    >
                      <FormattedMessage
                        id="modal.txDetails.common.updateStatus"
                        defaultMessage="" />
                    </Button>
                  </div>
                  {/* <div className="small-btn-group" >
<Button style={{ width: 'auto' }}
onClick={() => {
updatePRStatus(props, inquiryData);
}}
>
<FormattedMessage id='modal.txDetails.inquiryStatus' defaultMessage='' />
</Button>
</div> */}
                </div></>   
            : ""}
        </div>
      </div>

    </div> 
  );
};

const updateColumnEmptyCheck = (isColumnEmpty, inquiryData) => {
  if (!inquiryData) return isColumnEmpty;
  for (let i in inquiryData.inquiryDetailsList) {
    let row = inquiryData.inquiryDetailsList[i];
    if (row.itemName) {
      isColumnEmpty.product = false;
    }
    if (row.itemVariantName) {
      isColumnEmpty.variant = false;
    }
    if (row.description) {
      isColumnEmpty.description = false;
    }
    if (row.quantity) {
      isColumnEmpty.qty = false;
    }
    if (row.uomName) {
      isColumnEmpty.uomName = false;
    }
  }
  return isColumnEmpty;
};

// const getAddressInfoFragment = (obj, additionInfo) => {
//   const fragmentObj = (
//     <Fragment>
//       {obj.streetAddress1 ? (
//         <div className="billing-address">{obj.streetAddress1}</div>
//       ) : (
//         ""
//       )}
//       {obj.streetAddress2 ? (
//         <div className="billing-address">{obj.streetAddress2}</div>
//       ) : (
//         ""
//       )}
//       {obj.cityName ? (
//         <div className="billing-address">
//           {`${obj.cityName || ""}${obj.stateName ? ", " + obj.stateName : ""}${
//             obj.zipCode ? ", " + obj.zipCode : ""
//           }`}
//         </div>
//       ) : (
//         ""
//       )}
//       {/* {
//             additionInfo.firstName
//                 ? <div className='billing-address'>
//                     {contactName}
//                 </div> :
//                 ''
//         } */}
//       {additionInfo.workPhone ? (
//         <div className="billing-address">
//           <FormattedMessage id="phone" defaultMessage="" /> -{" "}
//           {additionInfo.workPhone}
//         </div>
//       ) : (
//         ""
//       )}
//       {additionInfo.email ? (
//         <div className="billing-address">
//           <FormattedMessage id="email" defaultMessage="" /> -{" "}
//           {additionInfo.email}
//         </div>
//       ) : (
//         ""
//       )}
//       {/* {
//             additionInfo.gstNumber
//                 ? <div className='billing-address'>
//                     <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
//                 </div> :
//                 ''
//         }
//         {
//             additionInfo.panNumber
//                 ? <div className='billing-address'>
//                     <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
//                 </div> :
//                 ''
//         } */}
//     </Fragment>
//   );

//   return fragmentObj;
// };

const getAddressInfoFragment = (obj = {}) => {    
  return (
    <div className="billing-address first-caps">
    {obj.streetAddress1 || obj.streetAddress2 ? (    
        <div>
          {obj.streetAddress1} {obj.streetAddress2}{", "}
        </div>    
    ) : ""}
    {obj.cityName || obj.stateName || obj.zipCode ? (      
        <div>
          {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
        </div>      
    ) : ""}
    {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
  </div>
  );
};

const getTaxIdentificationsFragment = (taxIdentifications) => {
  if (!taxIdentifications) return <Fragment />;
  let taxIdentificationsList = [];
  if (taxIdentifications) {
    taxIdentificationsList = JSON.parse(taxIdentifications || '[]');
  }
  const fragmentObj = (
    <Fragment>
      {taxIdentificationsList?.map((obj, i) => {
        return (
          <div className="billing-address">
            {obj.taxIdentificationType || ""} - {obj.taxIdentificationNumber}
          </div>
        );
      })}
    </Fragment>
  );

  return fragmentObj;
};

const getContactNameFragment = (additionInfo) => {
  if (!additionInfo) return <Fragment />;
  let contactName = "";
  if (additionInfo.salutationName)
    contactName += additionInfo.salutationName + " ";
  if (additionInfo.firstName) contactName += additionInfo.firstName;
  if (additionInfo.middleName) contactName += " " + additionInfo.middleName;
  if (additionInfo.lastName) contactName += " " + additionInfo.lastName;
  const fragmentObj = (
    <Fragment>
      {contactName && contactName.length ? (
        <div className="title">{contactName}</div>
      ) : (
        ""
      )}
      <div>{additionInfo?.EmailAddress||''}</div>
      <div>{additionInfo?.workPhone||additionInfo?.cellPhone||''}</div>
    </Fragment>
  );
  return fragmentObj;
};
const getVariantFragment = (item) => {
  let itemName = null;
  if (item.itemName) {
    itemName = item.itemName;
  }
  if (item.itemVariantName) {
    itemName = itemName + " (" + item.itemVariantName + ")";
  }
  return itemName ? itemName : "";
};
export default InquiryDatas;
