import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StockAdjustmentListingComp from '../../../../../components/inventory/warehouse/stockAdjustment/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../../utils';
import { fetchProducts, getProductCount, resetPaginatedData } from '../../../../product/Listing/action';
import { updateInventoryItem, approveQtyChange, rejectQtyChange } from './action';
import StockAdjustmentEdit from '../stockAdjustmentDrawer';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';


class StockAdjustmentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      editingKey: ''
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
      isDetails: true
    };
    fetchPaginationDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('getProductCount', 'productCount', this.props, payload);
  }

  render() {
    return (<>

     {this.state.stockAdjustmentDrawerVisible && (
          <StockAdjustmentEdit
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
      <StockAdjustmentListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    products: state.product.products,
    productCount: state.product.productCount,
    permissions: state.auth.permissions,
    listLoading: state.common.listLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchProducts,
  getProductCount,
  resetPaginatedData,
  updateInventoryItem,
  approveQtyChange,
  rejectQtyChange,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StockAdjustmentListing);
