import { MODULE_CODED_VALUES, PERMISSION_VALUES } from "../../static/constants";

const sideMasterMenu = [
  {
    menuName: "sidebar.menuItem.dashboard",
    iconClassName: "fi fi-rr-dashboard",
    rightIconClass: "",
    childList: [],
    path: "/admin/dashboard",
    moduleCode: MODULE_CODED_VALUES.DASHBOARD,
    operation: PERMISSION_VALUES.READ,
  },

  {
    menuName: "sidebar.menuItem.dashboard",
    iconClassName: "fi fi-rr-dashboard",
    rightIconClass: "",
    childList: [],
    path: "/admin/school-management/dashboard",
    moduleCode: MODULE_CODED_VALUES.SCHOOL_DASHBOARD,
    operation: PERMISSION_VALUES.READ,
  },

  {
    menuName: "sidebar.menuItem.leads",
    iconClassName: "fi fi-rr-rectangle-list",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItems.LeadCenter",
        iconClassName: "fi fi-rr-street-view",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "sidebar.menuItems.Leads",
            rightIconClass: "",
            childList: [],
            path: "/admin/leads/leads",
            moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
            operation: PERMISSION_VALUES.READ,
          },
          // {
          //   menuName: "sidebar.menuItems.MyLeads",
          //   rightIconClass: "",
          //   path: "/admin/leads/my-leads",
          //   moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
          //   operation: PERMISSION_VALUES.READ,
          //   childList: [],
          // },
          // {
          //   menuName: "uploadImage.variant.label",
          //   rightIconClass: "",
          //   childList: [],
          //   path: "/admin/leads/all-leads",
          //   moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
          //   operation: PERMISSION_VALUES.READ,
          // },
          {
            menuName: "sidebar.menuItems.Reminder",
            rightIconClass: "",
            path: "/admin/leads/reminder",
            moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          // {
          //   menuName: "sidebar.menuItems.Opportunity",
          //   rightIconClass: "",
          //   childList: [],
          //   path: "/admin/leads/opportunity",
          //   moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
          //   operation: PERMISSION_VALUES.READ,
          // },
          {
            menuName: "sidebar.menuItems.Lead.Tasks",
            rightIconClass: "",
            path: "/admin/leads/lead-tasks",
            moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.Call.Logs",
            rightIconClass: "",
            childList: [],
            path: "/admin/leads/call-logs",
            moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
            operation: PERMISSION_VALUES.READ,
          },
          {
            menuName: "sidebar.menuItems.Lead.Notes",
            rightIconClass: "",
            path: "/admin/leads/Lead-notes",
            moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.Lead.Transfer",
            rightIconClass: "",
            path: "/admin/leads/lead-transfer",
            moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          // {
          //     menuName : 'sidebar.menuItems.FollowUpLeads',
          //     rightIconClass : '',
          //     path: '/',
          //     moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
          //     operation: PERMISSION_VALUES.READ,
          //     childList:[]
          // },
        ],
      },

      {
        menuName: "sidebar.menuItems.LeadSetting",
        iconClassName: "fi fi-rr-list-check",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "sidebar.menuItems.LeadForm",
            iconClassName: "fi fi-rr-form",
            rightIconClass: "",
            childList: [],
            moduleCode: MODULE_CODED_VALUES.LEAD_FROM_SETTING,
            operation: PERMISSION_VALUES.READ,
            path: "/admin/leads/lead-form",
          },
          {
            menuName: "sidebar.menuItems.FormMeta",
            iconClassName: "fi fi-rr-form",
            rightIconClass: "",
            childList: [],
            moduleCode: MODULE_CODED_VALUES.LEAD_FROM_SETTING,
            operation: PERMISSION_VALUES.READ,
            path: "/admin/leads/lead-meta",
          },
          {
            menuName: "sidebar.menuItems.Lead.Campaigns",
            iconClassName: "fi fi-rr-form",
            rightIconClass: "",
            childList: [],
            moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
            operation: PERMISSION_VALUES.READ,
            path: "/admin/leads/lead-campaigns",
          },{
            menuName: "sidebar.menuItems.Workflows",
            iconClassName: "fi fi-rr-form",
            rightIconClass: "",
            childList: [],
            moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
            operation: PERMISSION_VALUES.READ,
            path: "/admin/leads/lead-workflows",
          },
        ],
      },
    ],
  },

  {
    menuName: "sidebar.menuItem.crm",
    iconClassName: "fi fi-br-users-alt",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.customerInformation",
        iconClassName: "fi fi-rr-id-card-clip-alt",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          // {
          //     menuName : 'sidebar.menuItem.allCustomer',
          //     iconClassName : 'fi fi-rr-users-alt',
          //     rightIconClass : '',
          //     path:'/admin/customers',
          //     moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
          //     operation: PERMISSION_VALUES.READ,
          //     childList:[]
          // },

          {
            menuName: "sidebar.menuItem.customerProfile",
            iconClassName: "fi fi-rr-users-alt",
            rightIconClass: "",
            path: "/admin/customers-list",
            moduleCode: MODULE_CODED_VALUES.MEMBERSHIP,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
        ],
      },
      // {
      //     menuName : 'sidebar.menuItem.taskManagement',
      //     iconClassName : 'fi fi-rr-note',
      //     rightIconClass : 'fi fi-rs-angle-right',
      //     moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER,
      //     operation: PERMISSION_VALUES.READ,
      //     childList:[
      //         {
      //             menuName : 'All Task',
      //             rightIconClass : '',
      //             path:'/admin/crm/task-management/all-task',
      //             moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER,
      //             operation: PERMISSION_VALUES.READ,
      //             childList:[]
      //         },
      //         {
      //             menuName : 'Pending Task',
      //             rightIconClass : '',
      //             path:'/admin/crm/task-management/pending-task',
      //             moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
      //             operation: PERMISSION_VALUES.READ,
      //             childList:[]
      //         },
      //         {
      //             menuName : 'Follow-up Tasks',
      //             rightIconClass : '',
      //             path:'/admin/crm/task-management/follow-up',
      //             moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
      //             operation: PERMISSION_VALUES.READ,
      //             childList:[]
      //         }
      //     ]
      // }
    ],
  },

  {
    menuName: "sidebar.menuItem.school",
    iconClassName: "fi fi-rs-school",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.registrations",
        iconClassName: "fi fi-rr-money-check-edit",
        rightIconClass: "",
        path: "/admin/school-management/form-registration-list",
        moduleCode: MODULE_CODED_VALUES.SCHOOL_FORM,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.students",
        iconClassName: "fi fi-rr-id-card-clip-alt",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "sidebar.menuItem.studentsProfile",
            rightIconClass: "",
            childList: [],
            path: "/admin/school-management/student",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_STUDENT,
            operation: PERMISSION_VALUES.READ,
          },
          {
            menuName: "sidebar.menuItem.attendance",
            rightIconClass: "",
            path: "/admin/school-management/attendance",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_ATTENDANCE,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItem.documents",
            rightIconClass: "",
            path: "/admin/document-list",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_DOCUMENT,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
        ],
      },
      {
        menuName: "sidebar.menuItem.teachers",
        iconClassName: "fi fi-rr-chalkboard-user",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "sidebar.menuItem.teacherProfile",
            rightIconClass: "",
            path: "/admin/school-management/teachers",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItem.teacherAttendance",
            rightIconClass: "",
            path: "/admin/school-management/teachers-attendance",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_TEACHER_ATTENDANCE,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          }],
      },
      // {
      //   menuName: "sidebar.menuItem.parents",
      //   iconClassName: "fi fi-rr-users",
      //   rightIconClass: "fi fi-rs-angle-right",
      //   moduleCode: MODULE_CODED_VALUES.SCHOOL_PARENTS,
      //   operation: PERMISSION_VALUES.READ,
      //   childList: [
      //     {
      //       menuName: "sidebar.menuItem.parentsInfo",
      //       rightIconClass: "",
      //       path: "/admin/school-management/parent",
      //       moduleCode: MODULE_CODED_VALUES.SCHOOL_PARENTS,
      //       operation: PERMISSION_VALUES.READ,
      //       childList: [],
      //     },
      //   ],
      // },
      {
        menuName: "sidebar.menuItem.class",
        iconClassName: "fi fi-rr-backpack",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "sidebar.menuItem.classInfo",
            rightIconClass: "",
            path: "/admin/school-management/class",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItem.section",
            rightIconClass: "",
            path: "/admin/school-management/section",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_CLASS_DETAIL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
        ],
      },
      {
        menuName: "sidebar.menuItem.curriculum",
        iconClassName: "fi fi-rs-e-learning",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "sidebar.menuItem.course",
            rightIconClass: "",
            path: "/admin/school-management/course",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItem.subject",
            rightIconClass: "",
            path: "/admin/school-management/subject",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItem.term",
            rightIconClass: "",
            path: "/admin/school-management/course-term",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItem.program",
            rightIconClass: "",
            path: "/admin/school-management/department",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_COURSE_DETAIL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
        ],
      },
      {
        menuName: "sidebar.menuItem.assessment",
        iconClassName: "fi fi-rr-blog-pencil",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "sidebar.menuItem.assignment",
            rightIconClass: "",
            path: "/admin/school-management/assignment",
            moduleCode: MODULE_CODED_VALUES.SCHOOL_ASSIGNMENTS,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
        ],
      },
      {
        menuName: "sidebar.menuItem.feesManagement",
        iconClassName: "fi fi-rs-file-invoice-dollar",
        rightIconClass: "",
        path: "/admin/school-management/invoice-list",
        moduleCode: MODULE_CODED_VALUES.SCHOOL_RECURRING_PAYMENTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.timeTable",
        iconClassName: "fi fi-rr-calendar-star",
        rightIconClass: "",
        path: "/admin/school-management/time-table",
        moduleCode: MODULE_CODED_VALUES.SCHOOL_TIME_TABLE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.forms",
        iconClassName: "fi fi-rs-form",
        rightIconClass: "",
        path: "/admin/school-management/form-list",
        moduleCode: MODULE_CODED_VALUES.SCHOOL_FORM,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
    ],
  },

  {
    menuName: "sidebar.menuItem.sales",
    iconClassName: "fi fi-rr-basket-shopping-simple",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.allCustomer",
        iconClassName: "fi fi-rr-users-alt",
        rightIconClass: "",
        path: "/admin/customers",
        moduleCode: MODULE_CODED_VALUES.CUSTOMERS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.inquiry",
        iconClassName: "fi fi-rr-memo-circle-check",
        rightIconClass: "",
        path: "/admin/sales-inquiry-list",
        moduleCode: MODULE_CODED_VALUES.SALES_INQUIRY,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.salesQuote",
        iconClassName: "fi fi-sr-block-quote",
        rightIconClass: "",
        path: "/admin/sq-list",
        moduleCode: MODULE_CODED_VALUES.SALES_QUOTE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.salesOrder",
        iconClassName: "fi fi-rr-basket-shopping-simple",
        rightIconClass: "",
        path: "/admin/so-list",
        moduleCode: MODULE_CODED_VALUES.SALES_ORDER,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.package",
        iconClassName: "fi fi-rs-box-open",
        rightIconClass: "",
        path: "/admin/package-list",
        moduleCode: MODULE_CODED_VALUES.SALES_ORDER_PACKAGE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.shipment",
        iconClassName: "fi fi-rs-truck-side",
        rightIconClass: "",
        path: "/admin/shipment-list",
        moduleCode: MODULE_CODED_VALUES.SALES_ORDER_SHIPMENT,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.salesInvoice",
        iconClassName: "fi fi-rr-receipt",
        rightIconClass: "",
        path: "/admin/sales-invoice-list",
        moduleCode: MODULE_CODED_VALUES.SALES_INVOICE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.payments",
        iconClassName: "fi fi-rr-credit-card",
        rightIconClass: "",
        path: "/admin/customer-payment-list",
        moduleCode: MODULE_CODED_VALUES.CUSTOMER_PAYMENT,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.creditMemo",
        iconClassName: "fi fi-rr-memo-pad",
        rightIconClass: "",
        path: "/admin/credit-memo-list",
        moduleCode: MODULE_CODED_VALUES.CREDIT_MEMO,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.couponManagement",
        iconClassName: "fi fi-rr-memo-pad",
        rightIconClass: "",
        path: "/admin/coupon-management",
        moduleCode: MODULE_CODED_VALUES.CREDIT_MEMO,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
          menuName : 'sidebar.menuItem.salesSupport',
          iconClassName : 'fi fi-rr-life-ring',
          rightIconClass : '',
          path: '/admin/sales-complaint-list',
          moduleCode: MODULE_CODED_VALUES.SALES_ORDER,
          operation: PERMISSION_VALUES.READ,
          childList:[]
      },
      // {
      //     menuName : 'sidebar.menuItem.registeredMembers',
      //     iconClassName : 'fi fi-rr-book-user',
      //     rightIconClass : '',
      //     path: '/admin/student-registration-list',
      //     moduleCode: MODULE_CODED_VALUES.REGISTERED_MEMBER,
      //     operation: PERMISSION_VALUES.READ,
      //     childList:[]
      // }
    ],
  },

  {
    menuName: "sidebar.menuItem.purchase",
    iconClassName: "fi fi-rr-shopping-cart-add",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.suppliers",
        iconClassName: "fi fi-rr-shopping-cart-add",
        rightIconClass: "",
        path: "/admin/suppliers",
        moduleCode: MODULE_CODED_VALUES.SUPPLIERS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.purchaseRequest",
        iconClassName: "fi fi-rs-file-invoice",
        rightIconClass: "",
        path: "/admin/purchase-request-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_REQUEST,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.RFQ",
        iconClassName: "fi fi-sr-block-quote",
        rightIconClass: "",
        path: "/admin/rfq-list",
        moduleCode: MODULE_CODED_VALUES.RFQ,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.purchaseOrder",
        iconClassName: "fi fi-rs-cart-arrow-down",
        rightIconClass: "",
        path: "/admin/purchase-order-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_ORDER,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.inboundDelivery",
        iconClassName: "fi fi-rs-note",
        rightIconClass: "",
        path: "/admin/inbound-delivery-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_GRN,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.purchaseInvoice",
        iconClassName: "fi fi-rr-receipt",
        rightIconClass: "",
        path: "/admin/purchase-invoice-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_INVOICE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },

      {
        menuName: "sidebar.menuItem.purchasepayment",
        iconClassName: "fi fi-rr-credit-card",
        rightIconClass: "",
        path: "/admin/supplier-payment-list",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_PAYMENT,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },

      // {
      //     menuName : 'sidebar.menuItem.payment',
      //     iconClassName : 'fi fi-rr-receipt',
      //     rightIconClass : '',
      //     path: '/admin/supplier-payment-list',
      //     moduleCode: MODULE_CODED_VALUES.SUPPLIER_PAYMENT,
      //     operation: PERMISSION_VALUES.READ,
      //     childList:[]
      // },
      {
        menuName: "sidebar.menuItem.debitMemo",
        iconClassName: "fi fi-rr-memo-pad",
        rightIconClass: "",
        path: "/admin/debit-memo-list",
        moduleCode: MODULE_CODED_VALUES.DEBIT_MEMO,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.landedCostSetup",
        iconClassName: "fi fi-rr-sack-dollar",
        rightIconClass: "",
        path: "/admin/landed-cost-setup",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_LANDED_COST,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.landedCost",
        iconClassName: "fi fi-rr-api",
        rightIconClass: "",
        path: "/admin/landed-cost",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_LANDED_COST,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
    ],
  },

  {
    menuName: "sidebar.menuItem.cloudDrive",
    iconClassName: "fi fi-rr-folder",
    rightIconClass: "",
    childList: [],
    path: "/admin/cloud-drive",
    moduleCode: MODULE_CODED_VALUES.CLOUD_DRIVE,
    operation: PERMISSION_VALUES.READ,
  },

  {
    menuName: "sidebar.menuItem.communication",
    iconClassName: "fi fi-rr-paper-plane",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.broadcast",
        iconClassName: "fi fi-rs-broadcast-tower",
        rightIconClass: "",
        path: "/admin/school-management/broadcast-message",
        moduleCode: MODULE_CODED_VALUES.SCHOOL_BROADCAST_MESSAGE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.newsletter",
        iconClassName: "fi fi-rr-envelope-open-text",
        rightIconClass: "",
        path: "/admin/news-letter-listing",
        moduleCode: MODULE_CODED_VALUES.NEWS_LETTER,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.events",
        iconClassName: "fi fi-rr-calendar-star",
        rightIconClass: "",
        path: "/admin/events",
        moduleCode: MODULE_CODED_VALUES.EVENTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.contact",
        iconClassName: "fi fi-rr-phone-call",
        rightIconClass: "",
        path: "/admin/communication/contact",
        moduleCode: MODULE_CODED_VALUES.ALL_CONTACT,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.emailLogs",
        iconClassName: "fi fi-rr-envelope",
        rightIconClass: "",
        path: "/admin/school-management/communication",
        moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.distributionGroup",
        iconClassName: "fi fi-rr-users-alt",
        rightIconClass: "",
        path: "/admin/communication/dg",
        moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName : 'sidebar.menuItem.communicationTemplate',
        iconClassName: "fi fi-rr-envelope",
        rightIconClass : '',
        path:"/admin/communication/communication-template",
        moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
        operation: PERMISSION_VALUES.READ,
        childList:[]
      }
      // {
      //     menuName : 'sidebar.menuItem.account',
      //     iconClassName : 'fi fi-rr-user-add',
      //     rightIconClass : '',
      //     path: '/admin/account',
      //     moduleCode: MODULE_CODED_VALUES.COMMUNICATION,
      //     operation: PERMISSION_VALUES.READ,
      //     childList:[]
      // }
    ],
  },

  {
    menuName: "sidebar.menuItem.products",
    iconClassName: "fi fi-rr-box-open",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.products",
        iconClassName: "fi fi-rr-boxes",
        rightIconClass: "",
        path: "/admin/products",
        moduleCode: MODULE_CODED_VALUES.PRODUCTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },

      {
        menuName: "sidebar.menuItem.productPrice",
        iconClassName: "fi fi-rs-tags",
        rightIconClass: "",
        path: "/admin/product-price",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_PRICE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.priceList",
        iconClassName: "fi fi-rr-file-invoice-dollar",
        rightIconClass: "",
        path: "/admin/price-list",
        moduleCode: MODULE_CODED_VALUES.PRICE_LIST,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.category",
        rightIconClass: "",
        iconClassName: "fi fi-rr-apps",
        path: "/admin/extras/product-category",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_CATEGORY,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.hsn",
        iconClassName: "fi fi-rs-cube",
        rightIconClass: "",
        path: "/admin/show-HSN",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_HSN,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.manufactures",
        rightIconClass: "",
        iconClassName: "fi fi-rr-industry-windows",
        path: "/admin/extras/product-manufacturer",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_MANUFACTURER,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.brands",
        rightIconClass: "",
        iconClassName: "fi fi-rr-tags",
        path: "/admin/extras/product-brands",
        moduleCode: MODULE_CODED_VALUES.PRODUCT_BRAND,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.uom",
        rightIconClass: "",
        iconClassName: "fi fi-rr-ruler-combined",
        path: "/admin/extras/UOM",
        moduleCode: MODULE_CODED_VALUES.UOM,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.inventory",
    iconClassName: "fi fi-rr-boxes",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.myWarehouse",
        iconClassName: "fi fi-rr-warehouse-alt",
        rightIconClass: "",
        path: "/admin/warehouse/my-warehouse",
        moduleCode: MODULE_CODED_VALUES.MY_WAREHOUSE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },

      {
        menuName: "sidebar.menuItem.stockSummary",
        iconClassName: "fi fi-rs-document-signed",
        rightIconClass: "",
        path: "/admin/warehouse/stock-summary",
        moduleCode: MODULE_CODED_VALUES.STOCK_SUMMARY,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.stockAdjustment",
        iconClassName: "fi fi-rr-objects-column",
        rightIconClass: "",
        path: "/admin/warehouse/stock-adjustment",
        moduleCode: MODULE_CODED_VALUES.STOCK_ADJUSTMENT,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.inventoryValuation",
        iconClassName: "fi fi-rr-sack",
        rightIconClass: "",
        path: "/admin/warehouse/all-inventory-valuation",
        moduleCode: MODULE_CODED_VALUES.AGED_STOCK,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.agedStock",
        iconClassName: "fi fi-rr-time-fast",
        rightIconClass: "",
        path: "/admin/warehouse/aged-stock",
        moduleCode: MODULE_CODED_VALUES.AGED_STOCK,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.stock.InTransit",
        iconClassName: "fi fi-rr-truck-moving",
        rightIconClass: "",
        path: "/admin/stock-intransit",
        moduleCode: MODULE_CODED_VALUES.STOCK_TRANSFER,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.teamName",
    iconClassName: "fi fi-rr-users",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.employee",
        iconClassName: "fi fi-rr-comment-user",
        rightIconClass: "",
        path: "/admin/employee-list",
        moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.orgHierarchy",
        iconClassName: "fi fi-rs-chart-tree",
        rightIconClass: "",
        path: "/admin/company-hierarchy-list",
        moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.project",
        iconClassName: "fi fi-rs-briefcase",
        rightIconClass: "",
        path: "/admin/projects",
        moduleCode: MODULE_CODED_VALUES.PROJECT,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "Employee Attendance",
        iconClassName: "fi fi-rr-mode-portrait",
        rightIconClass: "",
        path: "/admin/employe-attendance",
        moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "Employee Meta",
        iconClassName: "fi fi-rr-mode-portrait",
        rightIconClass: "",
        path: "/admin/employee-meta",
        moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "Attendance Meta",
        iconClassName: "fi fi-rr-mode-portrait",
        rightIconClass: "",
        path: "/admin/attendance-meta",
        moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "Leave Meta",
        iconClassName: "fi fi-rr-mode-portrait",
        rightIconClass: "",
        path: "/admin/leave-meta",
        moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "Payroll Meta",
        iconClassName: "fi fi-rr-file-invoice",
        rightIconClass: "",
        path: "/admin/payroll-meta",
        moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },

      {
        menuName: "Employee Management",
        iconClassName: "fi fi-rr-comment-user",
        rightIconClass: "fi fi-rs-angle-right",
        moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
        operation: PERMISSION_VALUES.READ,
        childList: [
          {
            menuName: "sidebar.menuItems.employee",
            rightIconClass: "",
            childList: [],
            path: "/admin/employeeManagement/all-employee",
            moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
            operation: PERMISSION_VALUES.READ,
          },
          {
            menuName: "sidebar.menuItems.warning",
            rightIconClass: "",
            path: "/admin/employeeManagement/warning",
            moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.promotion",
            rightIconClass: "",
            path: "/admin/employeeManagement/promotion",
            moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.termination",
            rightIconClass: "",
            path: "/admin/employeeManagement/termination",
            moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
         
        ],
      },

      {
        menuName: "Leave Management",
        iconClassName: "fi fi-rr-leave",
        rightIconClass: "",
        path: "/admin/leave-management",
        moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "Attendance Management",
        iconClassName: "fi fi-rr-comment-user",
        rightIconClass: "fi fi-rs-angle-right",
        moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
        operation: PERMISSION_VALUES.READ,
        childList: [
          {
            menuName: "sidebar.menuItems.dailyAttendance",
            rightIconClass: "",
            childList: [],
            path: "/admin/attendanceManagement/daily-attendance",
            moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
            operation: PERMISSION_VALUES.READ,
          },
          {
            menuName: "sidebar.menuItems.monthlyAttendance",
            rightIconClass: "",
            path: "/admin/attendanceManagement/monthly-attendance",
            moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.myAttendance",
            rightIconClass: "",
            path: "/admin/attendanceManagement/my-attendance",
            moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.attendanceSummary",
            rightIconClass: "",
            path: "/admin/attendanceManagement/attendance-summary",
            moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.attendanceIp",
            rightIconClass: "",
            path: "/admin/attendanceManagement/attendance-ip",
            moduleCode: MODULE_CODED_VALUES.COMPANY_HIERARCY,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
        ],
      },
      {
        menuName: "Attendance Management",
        iconClassName: "fi fi-rr-comment-user",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "Attendance Meta",
            iconClassName: "fi fi-rr-mode-portrait",
            rightIconClass: "",
            path: "/admin/attendance-meta",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.dailyAttendance",
            rightIconClass: "",
            childList: [],
            path: "/admin/attendanceManagement/daily-attendance",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
            operation: PERMISSION_VALUES.READ,
          },
          {
            menuName: "sidebar.menuItems.monthlyAttendance",
            rightIconClass: "",
            path: "/admin/attendanceManagement/monthly-attendance",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.myAttendance",
            rightIconClass: "",
            path: "/admin/attendanceManagement/my-attendance",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.attendanceSummary",
            rightIconClass: "",
            path: "/admin/attendanceManagement/attendance-summary",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.attendanceIp",
            rightIconClass: "",
            path: "/admin/attendanceManagement/attendance-ip",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_ATTENDANCE,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
        ],
      },
      {
        menuName: "Payroll Management",
        iconClassName: "fi fi-rr-money-check-edit",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "Payroll Meta",
            iconClassName: "fi fi-rr-file-invoice",
            rightIconClass: "",
            path: "/admin/payroll-meta",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.allowance",
            rightIconClass: "",
            childList: [],
            path: "/admin/payrollManagement/allowance",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
          },
          {
            menuName: "sidebar.menuItems.deduction",
            rightIconClass: "",
            path: "/admin/payrollManagement/deduction",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "Pay Grade",
            rightIconClass: "",
            path: "/admin/payrollManagement/pay-grade",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.monthlyPayGrade",
            rightIconClass: "",
            path: "/admin/payrollManagement/monthlyPayGrade",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.hourlyPayGrade",
            rightIconClass: "",
            path: "/admin/payrollManagement/hourlyPayGrade",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.salarySheet",
            rightIconClass: "",
            path: "/admin/payrollManagement/salarySheet",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.paymentHistory",
            rightIconClass: "",
            path: "/admin/payrollManagement/paymentHistory",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.myPayroll",
            rightIconClass: "",
            path: "/admin/payrollManagement/myPayroll",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "sidebar.menuItems.bonus",
            rightIconClass: "",
            path: "/admin/payrollManagement/bonus",
            moduleCode: MODULE_CODED_VALUES.HRMS_PAYROLL,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
        ],
      },
      {
        menuName: "Employee Service",
        iconClassName: "fi fi-rr-user-crown",
        rightIconClass: "fi fi-rs-angle-right",
        childList: [
          {
            menuName: "Profile",
            iconClassName: "fi fi-rr-file-invoice",
            rightIconClass: "",
            path: "/admin/payroll-meta",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "Leave Requests",
            iconClassName: "fi fi-rr-file-invoice",
            rightIconClass: "",
            path: "/admin/payroll-meta",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "Expense Claims",
            iconClassName: "fi fi-rr-file-invoice",
            rightIconClass: "",
            path: "/admin/payroll-meta",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
          {
            menuName: "Performance Reviews",
            iconClassName: "fi fi-rr-file-invoice",
            rightIconClass: "",
            path: "/admin/payroll-meta",
            moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_SERVICES,
            operation: PERMISSION_VALUES.READ,
            childList: [],
          },
        ],
      },
      {
        menuName: "Training & Development",
        iconClassName: "fi fi-rr-chart-user",
        rightIconClass: "",
        path: "/admin/payroll-meta",
        moduleCode: MODULE_CODED_VALUES.HRMS_EMPLOYEE_TRAINING,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "Asset Management",
        iconClassName: "fi fi-rs-computer-speaker",
        rightIconClass: "",
        path: "/admin/payroll-meta",
        moduleCode: MODULE_CODED_VALUES.HRMS_ASSET,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
    ],
  },

  {
    menuName: "sidebar.menuItem.fundraising",
    iconClassName: "fi fi-rr-piggy-bank",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.fundraising-campaign",
        iconClassName: "fi fi-rr-megaphone",
        rightIconClass: "",
        path: "/admin/fundraising-campaign",
        moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.pledges",
        iconClassName: "fi fi-br-bookmark",
        rightIconClass: "",
        path: "/admin/fundraising-pledges",
        moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.donations",
        iconClassName: "fi fi-rr-credit-card",
        rightIconClass: "",
        path: "/admin/fundraising-donation",
        moduleCode: MODULE_CODED_VALUES.FUNDRAISING,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.accounting",
    iconClassName: "fi fi-rr-usd-circle",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.businessExpense",
        iconClassName: "fi fi-rs-wallet",
        rightIconClass: "",
        path: "/admin/business-expense-listing",
        moduleCode: MODULE_CODED_VALUES.BUSINESS_EXPENSE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.banking",
        iconClassName: "fi fi-rr-bank",
        rightIconClass: "",
        path: "/admin/banking",
        moduleCode: MODULE_CODED_VALUES.BANKING,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.dayBook",
        iconClassName: "fi fi-rr-book-open-cover",
        rightIconClass: "",
        path: "/admin/dayBook",
        moduleCode: MODULE_CODED_VALUES.BANKING_BOOKS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.cashBook",
        iconClassName: "fi fi-rs-hands-usd",
        rightIconClass: "",
        path: "/admin/cashbook",
        moduleCode: MODULE_CODED_VALUES.BANKING_BOOKS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.bankBook",
        iconClassName: "fi fi-rs-file-invoice-dollar",
        rightIconClass: "",
        path: "/admin/bankbook",
        moduleCode: MODULE_CODED_VALUES.BANKING_BOOKS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.journalReport",
        iconClassName: "fi fi-rr-book-open-cover",
        rightIconClass: "",
        path: "/admin/journal-report",
        moduleCode: MODULE_CODED_VALUES.REPORTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.billwiseDetail",
        iconClassName: "fi fi-rs-receipt",
        rightIconClass: "",
        path: "/admin/bill-wise-detail",
        moduleCode: MODULE_CODED_VALUES.BANKING,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.invoiceDetail",
        iconClassName: "fi fi-rs-file-invoice",
        rightIconClass: "",
        path: "/admin/gst-filling",
        moduleCode: MODULE_CODED_VALUES.GST_FILLING,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.leadgerAccounts",
        iconClassName: "fi fi-rs-book-font",
        rightIconClass: "",
        path: "/admin/ledger-accounts",
        moduleCode: MODULE_CODED_VALUES.LEDGER_ACCOUNTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.balanceSheet",
        iconClassName: "fi fi-rs-memo",
        rightIconClass: "",
        path: "/admin/balance-sheet",
        moduleCode: MODULE_CODED_VALUES.BALANCE_SHEET,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.profitLoss",
        iconClassName: "fi fi-rs-chart-histogram",
        rightIconClass: "",
        path: "/admin/profit-loss",
        moduleCode: MODULE_CODED_VALUES.PROFIT_LOSS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.trialBalance",
        iconClassName: "fi fi-rr-donate",
        rightIconClass: "",
        path: "/admin/trial-balance",
        moduleCode: MODULE_CODED_VALUES.TRIAL_BALANCE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.openingBalance",
        iconClassName: "fi fi-rr-hand-holding-usd",
        rightIconClass: "",
        path: "/admin/opening-balance",
        moduleCode: MODULE_CODED_VALUES.OPENING_BALANCE,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.journals",
        iconClassName: "fi fi-rr-notebook-alt",
        rightIconClass: "",
        path: "/admin/show-journals",
        moduleCode: MODULE_CODED_VALUES.JOURNALS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.bankReconciliation",
        iconClassName: "fi fi-rr-shuffle",
        rightIconClass: "",
        path: "/admin/bankReconciliation",
        moduleCode: MODULE_CODED_VALUES.BANK_RECONCILIATION,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.taxes",
        iconClassName: "fi fi-ss-earnings",
        rightIconClass: "",
        path: "/admin/show-taxes",
        moduleCode: MODULE_CODED_VALUES.TAXES,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },

      {
        menuName: "sidebar.menuItem.financialYears",
        iconClassName: "fi fi-rr-business-time",
        rightIconClass: "",
        path: "/admin/show-fy-years",
        moduleCode: MODULE_CODED_VALUES.FINANCIAL_YEARS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.reports",
    iconClassName: "fi fi-rr-file-medical-alt",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuItem.financialReports",
        iconClassName: "fi fi-rr-file-invoice-dollar",
        rightIconClass: "",
        path: "/admin/show-reports",
        moduleCode: MODULE_CODED_VALUES.REPORTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.salesReports",
        iconClassName: "fi fi-rr-ticket",
        rightIconClass: "",
        path: "/admin/salesReports",
        moduleCode: MODULE_CODED_VALUES.SALES_REPORTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.purcahseReports",
        iconClassName: "fi fi-rr-ticket",
        rightIconClass: "",
        path: "/admin/salesReports",
        moduleCode: MODULE_CODED_VALUES.PURCHASE_REPORTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.warehouseReports",
        iconClassName: "fi fi-rr-ticket",
        rightIconClass: "",
        path: "/admin/salesReports",
        moduleCode: MODULE_CODED_VALUES.WAREHOUSE_REPORTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.inventoryReports",
        iconClassName: "fi fi-rr-box-open-full",
        rightIconClass: "",
        path: "/admin/inventoryReports",
        moduleCode: MODULE_CODED_VALUES.INVENTORY_REPORTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.leadReports",
        iconClassName: "fi fi-rr-street-view",
        rightIconClass: "",
        path: "/admin/leadReports",
        moduleCode: MODULE_CODED_VALUES.LEAD_REPORTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
      {
        menuName: "sidebar.menuItem.CRMReports",
        iconClassName: "fi fi-rr-users-medical",
        rightIconClass: "",
        path: "/admin/show-reports",
        moduleCode: MODULE_CODED_VALUES.CRM_REPORTS,
        operation: PERMISSION_VALUES.READ,
        childList: [],
      },
    ],
  },
  {

    menuName: "sidebar.menuItem.integrations",
    iconClassName: "fi fi-rr-folder",
    rightIconClass: "",
    childList: [],
    moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
    operation: PERMISSION_VALUES.READ,

    path: "/admin/integrations",
    // childList: []
    // [
    //   {
    //     menuName: "sidebar.menuItem.marketplace",
    //     iconClassName: "fi fi-rs-shop",
    //     rightIconClass: "fi fi-rs-angle-right",
    //     childList: [
    //       {
    //         menuName: "Amazon",
    //         iconClassName: "fi fi-brands-cc-amazon-pay",
    //         rightIconClass: "",
    //         path: "/admin/sales-channels-amazon",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Ebay",
    //         iconClassName: "fi fi-brands-ebay",
    //         rightIconClass: "",
    //         path: "/admin/sales-channels-ebay",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Walmart",
    //         iconClassName: "fi fi-brands-walmart",
    //         rightIconClass: "",
    //         path: "/admin/sales-channels-walmart",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Shopify",
    //         iconClassName: "fi fi-brands-shopify",
    //         rightIconClass: "",
    //         path: "/admin/sales-channels-shopify",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Carrefour",
    //         iconClassName: "fi fi-rr-shop",
    //         rightIconClass: "",
    //         path: "/admin/sales-channels-carrefour",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Noon",
    //         iconClassName: "fi fi-bs-shop",
    //         rightIconClass: "",
    //         path: "/admin/sales-channels-noon",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //     ],
    //   },
    //   {
    //     menuName: "sidebar.menuItem.food",
    //     iconClassName: "fi fi-rr-restaurant",
    //     rightIconClass: "fi fi-rs-angle-right",
    //     childList: [
    //       {
    //         menuName: "Careem",
    //         iconClassName: "fi fi-rr-restaurant",
    //         rightIconClass: "",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Talabat",
    //         iconClassName: "fi fi-rr-room-service",
    //         rightIconClass: "",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Zomato",
    //         iconClassName: "fi fi-rr-utensils",
    //         rightIconClass: "",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Delivero",
    //         iconClassName: "fi fi-rr-salad",
    //         rightIconClass: "",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Hunger Station",
    //         iconClassName: "fi fi-rr-hamburger",
    //         rightIconClass: "",
    //         moduleCode: MODULE_CODED_VALUES.SALES_CHANNEL,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //     ],
    //   },
    //   {
    //     menuName: "sidebar.menuItem.shipping",
    //     iconClassName: "fi fi-rs-truck-side",
    //     rightIconClass: "fi fi-rs-angle-right",
    //     childList: [
    //       {
    //         menuName: "sidebar.menuItem.easyPost",
    //         iconClassName: "fi fi-rr-rocket",
    //         rightIconClass: "",
    //         path: "/admin/easy-post",
    //         moduleCode: MODULE_CODED_VALUES.SHIPPING,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "sidebar.menuItem.shipStation",
    //         iconClassName: "fi fi-rr-rocket",
    //         rightIconClass: "",
    //         path: "/admin/ship-station",
    //         moduleCode: MODULE_CODED_VALUES.SHIPPING,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //     ],
    //   },
    //   {
    //     menuName: "sidebar.menuItem.accounting",
    //     iconClassName: "fi fi-rr-book-open-cover",
    //     rightIconClass: "fi fi-rs-angle-right",
    //     childList: [
    //       {
    //         menuName: "Xero",
    //         iconClassName: "fi fi-rr-document",
    //         rightIconClass: "",
    //         moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //     ],
    //   },
    //   {
    //     menuName: "sidebar.menuItem.payments",
    //     iconClassName: "fi fi-rr-sack",
    //     rightIconClass: "fi fi-rs-angle-right",
    //     childList: [
    //       {
    //         menuName: "Authorize.net",
    //         iconClassName: "fi fi-rr-users",
    //         rightIconClass: "",
    //         path: "/admin/payment-integration",
    //         moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Stripe",
    //         iconClassName: "fi fi-brands-stripe",
    //         rightIconClass: "",
    //         path: "/admin/payment-integration",
    //         moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Cardpoint",
    //         iconClassName: "fi fi-ts-gift-card",
    //         rightIconClass: "",
    //         path: "/admin/payment-integration",
    //         moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //       {
    //         menuName: "Razorpay",
    //         iconClassName: "fi fi-tr-money-bill-wave",
    //         rightIconClass: "",
    //         path: "/admin/payment-integration",
    //         moduleCode: MODULE_CODED_VALUES.ACCOUNTING,
    //         operation: PERMISSION_VALUES.READ,
    //         childList: [],
    //       },
    //     ],
    //   },
    // ],
  },

  {
    menuName: "sidebar.menuItem.eStore",
    iconClassName: "fi fi-rr-computer",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "Appearance",
        iconClassName: "fi fi-rr-palette",
        rightIconClass: "fi fi-rs-angle-right",
        path: "/admin/e-store/appearance",
        childList: [
          {
            menuName: "sidebar.menuItem.appearance.themes",
            rightIconClass: "",
            childList: [],
            path: "/admin/e-store/themes",
            moduleCode: MODULE_CODED_VALUES.E_STORE_APPEARANCE,
            operation: PERMISSION_VALUES.READ,
          },
          {
            menuName: "sidebar.menuItem.appearance.page",
            rightIconClass: "",
            childList: [],
            path: "/admin/e-store/pages",
            moduleCode: MODULE_CODED_VALUES.E_STORE_APPEARANCE,
            operation: PERMISSION_VALUES.READ,
          },
          {
            menuName: "sidebar.menuItem.appearance.menus",
            rightIconClass: "",
            childList: [],
            path: "/admin/e-store/menu",
            moduleCode: MODULE_CODED_VALUES.E_STORE_APPEARANCE,
            operation: PERMISSION_VALUES.READ,
          },
          // {
          //     menuName : 'sidebar.menuItem.appearance.blog',
          //     rightIconClass : '',
          //     childList:[],
          //     path:'/admin/e-store/blog',
          //     moduleCode: MODULE_CODED_VALUES.SCHOOL_STUDENT,
          //     operation: PERMISSION_VALUES.READ,
          // },
          // {
          //     menuName : 'sidebar.menuItem.appearance.media',
          //     rightIconClass : '',
          //     childList:[],
          //     path:'/admin/e-store/media',
          //     moduleCode: MODULE_CODED_VALUES.SCHOOL_STUDENT,
          //     operation: PERMISSION_VALUES.READ,
          // },
        ],
      },
      {
        menuName: "Product Management",
        iconClassName: "fi fi-rr-box-open",
        rightIconClass: "",
        childList: [],
        path: "/admin/published-stock",
        moduleCode: MODULE_CODED_VALUES.E_STORE_PRODUCT_MGMT,
        operation: PERMISSION_VALUES.READ,
      },
      {
        menuName: "sidebar.menuItem.storeSettings",
        iconClassName: "fi fi-rs-gears",
        rightIconClass: "",
        childList: [],
        path: "/admin/e-store/store-settings",
        moduleCode: MODULE_CODED_VALUES.E_STORE_SETTING,
        operation: PERMISSION_VALUES.READ,
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.emailMarketing",
    iconClassName: "fi fi-rs-envelopes",
    rightIconClass: "fi fi-bs-menu-dots-vertical",
    childList: [
      {
        menuName: "sidebar.menuHeading.email",
        iconClassName: "fi fi-rr-envelope-bulk",
        rightIconClass: "fi fi-rs-angle-right",
        path: "",
        moduleCode: MODULE_CODED_VALUES.ECOMMERCE,
        operation: PERMISSION_VALUES.READ,
        childList: [
          {
            menuName: "sidebar.subMenuItem.emailCampaigns",
            rightIconClass: "",
            childList: [],
            path: "/admin/email/email-campaigns",
            moduleCode: MODULE_CODED_VALUES.ECOMMERCE,
            operation: PERMISSION_VALUES.READ,
          },
          {
            menuName: "sidebar.subMenuItem.emailTemplate",
            rightIconClass: "",
            childList: [],
            path: "/admin/email/email-template",
            moduleCode: MODULE_CODED_VALUES.ECOMMERCE,
            operation: PERMISSION_VALUES.READ,
          },
        ],
      },
    ],
  },
  {
    menuName: "sidebar.menuItem.settings",
    iconClassName: "fi fi-rr-settings",
    // rightIconClass: "fi fi-bs-menu-dots-vertical",
    path: "/admin/show-settings",
    moduleCode: MODULE_CODED_VALUES.SETTINGS,
    operation: PERMISSION_VALUES.READ,
    childList: [
      // {
      //   menuName: "System Settings",
      //   iconClassName: "fi fi-rs-gears",
      //   rightIconClass: "",
      //   path: "/admin/show-settings",
      //   moduleCode: MODULE_CODED_VALUES.SETTINGS,
      //   operation: PERMISSION_VALUES.READ,
      //   childList: [],
      // },
      // {
      //   menuName: "Administrator",
      //   iconClassName: "fi fi-rr-user-gear",
      //   rightIconClass: "",
      //   path: "/admin/show-settings",
      //   moduleCode: MODULE_CODED_VALUES.SETTINGS,
      //   operation: PERMISSION_VALUES.READ,
      //   childList: [],
      // },
      // {
      //   menuName: "Customization",
      //   iconClassName: "fi fi-rr-settings-sliders",
      //   rightIconClass: "",
      //   path: "/admin/show-settings",
      //   moduleCode: MODULE_CODED_VALUES.SETTINGS,
      //   operation: PERMISSION_VALUES.READ,
      //   childList: [],
      // },
    ],
  },
];

export default sideMasterMenu;
