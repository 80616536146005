import React, { useState } from "react";
import { Col, Row, Collapse, Checkbox, Button, Switch, Tabs, Upload , Image,Modal } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import "./menus.scss";

import * as find from "lodash.find";
import { TextBox } from "../../general/TextBox";
import { FormattedMessage } from "react-intl";

const { TabPane } = Tabs;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

const BannerManagement = (props) => {
   
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [singleFileList, setSingleFileList] = useState([]);
  const [imageUrl , setImageUrl] = useState();

  const [fileList1, setFileList1] = useState([]);
 
  const [modalOpen1, setModalOpen1] = useState(false);
  const [singleFileList1, setSingleFileList1] = useState([]);
  const [imageUrl1 , setImageUrl1] = useState();
  
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleModalChange = ({ fileList: newFileList }) => {
    setSingleFileList(newFileList);
    console.log(singleFileList ,"after upload")
  };

  const handleModalOk = () => {
    if (singleFileList.length > 0) {
      setFileList([...fileList, ...singleFileList]);
      setSingleFileList([]);
      setModalOpen(false);
    }
  };
  const handleModalChange1 = ({ fileList: newFileList }) => {
    setSingleFileList1(newFileList);
    console.log(singleFileList ,"after upload")
  };
  const handleModalCancel1 = () => {
    setSingleFileList1([]);
    setModalOpen1(false);
  };

  const handleModalOk1 = () => {
    if (singleFileList1.length > 0) {
      setFileList1([...fileList1, ...singleFileList1]);
      setSingleFileList1([]);
      setModalOpen1(false);
    }
  };

  const handleModalCancel = () => {
    setSingleFileList([]);
    setModalOpen(false);
  };

  
  const handleSave = () => {
    const payload = {
          relationshipId: props.relationshipId,
      }    
    props.updateHomePageSettings(payload ,props);
  };
  console.log(singleFileList,imageUrl ,"jokkerrrr")
  const {bannerSetting={}} = props;
  return (
    <div className="store-model-container-f">
    <div>
    <div className="heading">
    Promotional banners
        </div>
        <p>Grab your store visitor’s attention by promotional banners displayed on top of your homepage.</p>
    </div>
   
        <div className="model-container pt20">
            <Row gutter={[16, 16]}>
            <Col span={12} className="text-right">
                    <FormattedMessage
                      id="Auto Slider"
                      defaultMessage=""
                    />
                  
                  </Col>
            <Col span={12}>
                    <Switch
                      checked={
                       props.autoSlider
                      }
                      onClick={(checked, e) => {
                        props.updateState({
                            autoSlider: checked,
                        })
                      }}
                    />
                  </Col>

            </Row>

            {
               props.autoSlider ? (<div><Row>
                <Col span={12}>
                <TextBox 
                placeholder="Auto Slider Duration"
                value={props.autoTimer}
                onChange={(e)=>{
                    props.updateState({
                        autoTimer: e.target.value
                    })
                }}
                />
                </Col>
               </Row>
               <div className="banner-manangement">
               <Row>
                <Col span={24}>
                   <Tabs activeKey={props.activeTabKey || "Active"} onChange={(key) => {
                        props.updateState({
                            selectedItems:[],
                            activeTabKey:key,
                        })
                      }} type="line">


                            <TabPane tab="Active" key="Active">
                                <Row>
                                    <Col span={24}>
                                    <h2>For Mobile (1/6)</h2>
                                    </Col>
                                    <Col span={24}>
                                    <div>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        // onChange={handleChange}
        openFileDialogOnClick={false}
        
      >
        <button style={{ border: 0, background: 'none' }} type="button" onClick={() => setModalOpen(true)}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Add Banner</div>
    </button>
      
      </Upload>

      <Modal
        visible={modalOpen}
        title="Upload Image"
        width={400}
        centered={true}
        okText={'Add Banner'}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >  
      <div className="modal-banner">
     
      <Upload
          listType="picture-card"
          fileList={singleFileList}
          showUploadList={false}
          onChange={handleModalChange}
          beforeUpload={(file) => {
            // Allow only a single file upload, you can adjust this as needed
            setImageUrl(file);
            console.log(singleFileList ,"before upload")
            return false;
          }}
         
        >
          
          {singleFileList.length === 0 ? (
            <div>
  <Button type="button">
             
             <div >Upload Image</div>
           </Button>
           <div>Recommended size (360px*324px)</div>
            </div>
           
           
          ):(
        
          <img src={URL.createObjectURL(imageUrl)} alt="avatar" style={{ width: '100%' }} />
          )}
         
        </Upload>
      </div>
       
        <div>
        <FormattedMessage
                      id="Link Banner To (Optional)"
                      defaultMessage=""
                    />
                      <TextBox 
                placeholder="http://example.com"
                value={''}
                // onChange={(e)=>{
                //     props.updateState({
                //         autoTimer: e.target.value
                //     })
                // }}
                />
        </div>
      </Modal>

      <Modal
        visible={previewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
     

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                    <h2>For Desktop (1/6)</h2>
                                    </Col>
                                    <Col span={24}>
                                    <div className="desktop-banner">
      <Upload
        listType="picture-card"
        fileList={fileList1}
        // onPreview={handlePreview}
        // onChange={handleChange}
        openFileDialogOnClick={false}
        
      >
        <button style={{ border: 0, background: 'none' }} type="button" onClick={() => setModalOpen1(true)}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Add Banner</div>
    </button>
      
      </Upload>

      <Modal
        visible={modalOpen1}
        title="Upload Image"
        width={400}
        centered={true}
        okText={'Add Banner'}
        onOk={handleModalOk1}
        onCancel={handleModalCancel1}
      >  
      <div className="modal-banner">
     
      <Upload
          listType="picture-card"
          fileList={singleFileList1}
          showUploadList={false}
          onChange={handleModalChange1}
          beforeUpload={(file) => {
            // Allow only a single file upload, you can adjust this as needed
            setImageUrl1(file);
            console.log(singleFileList1 ,"before upload")
            return false;
          }}
         
        >
          
          {singleFileList1.length === 0 ? (
            <div>
  <Button type="button">
             
             <div >Upload Image</div>
           </Button>
           <div>Recommended size (360px*324px)</div>
            </div>
           
           
          ):(
        
          <img src={URL.createObjectURL(imageUrl1)} alt="avatar" style={{ width: '100%' }} />
          )}
         
        </Upload>
      </div>
       
        <div>
        <FormattedMessage
                      id="Link Banner To (Optional)"
                      defaultMessage=""
                    />
                      <TextBox 
                placeholder="http://example.com"
                value={''}
                // onChange={(e)=>{
                //     props.updateState({
                //         autoTimer: e.target.value
                //     })
                // }}
                />
        </div>
      </Modal>

      <Modal
        visible={previewOpen}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
     

                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Previously Used" key="Previous">Previously Used</TabPane>
                        </Tabs>
                </Col>
               </Row>
               </div>
              
               </div> ) :""
            }
        
      
            
                    <div className="footer-btn" >
                        <Button type="primary" onClick={() => handleSave()}>Submit</Button>
                    </div>
             

      
        </div>
            
    </div>
  );
};

export default BannerManagement;