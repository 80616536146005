import React, { useState } from "react";
import config from '../../../config/environmentConfig';
import "./style.scss";
import erpLogo from "../../../assets/images/erpIcon2.svg";
import commerceLogo from "../../../assets/images/commerceIcon.svg";
import educationLogo from "../../../assets/images/educationIcon.svg";
import crmLogo from "../../../assets/images/crmIcon.svg";
import hrmsLogo from "../../../assets/images/hrmsIcon.svg";
//import cardImg from "../../../assets/images/cardImg.svg";
//import cardCVVIcon from "../../../assets/images/cardCVVicon.svg";
import { Button, Input } from "antd";
import { Dropdown } from "../../general/Dropdown";
import { CardComponent, CardNumber, CardExpiry, CardCVV, Provider } from "@chargebee/chargebee-js-react-wrapper";
import { fixedDecimalNumber, getCountryCode, getCurrencySymbol } from "../../../utils";
import { useCookies } from 'react-cookie';
import { logout } from '../../../actions/commonActions';
import { LOCAL_STORAGE_KEYS } from '../../../static/constants';




function PaymentComponent(props) {
    const {subscriptionAddons = [], selectedPlan={}, addons={}} = props;

    const [cardRef, setCardRef] = useState(React.createRef());
    const [cbErrors, setCbErrors] = useState({});
    const [, , removeCookies] = useCookies();
    

    const noOfUser = subscriptionAddons.find(obj=>obj.addonName === "numberOfUsers") || {};
    const cloudStorage = subscriptionAddons.find(obj=>obj.addonName === "cloudStorage") || {};
    const onlineTraining = subscriptionAddons.find(obj=>obj.addonName === "onlineTraining") || {};
    const support = subscriptionAddons.find(obj=>obj.addonName === "support") || {};

    const onReady = (el) => {
        // console.log(":::::::;   el:::::", el);
         el.focus();
      }
    //   cardRef = React.createRef();

    const calulateSubTotal = ()=>{

        const planPrice = selectedPlan[props.priceTerm] || 0;
        const addonPrice = ((addons.users || 0) * (noOfUser.price || 0)) + ((addons.cloudStorage || 0) * (cloudStorage.price || 0)) + ((addons.trainingUsers || 0) * (onlineTraining.price || 0)) + ((addons.support || 0) * (support.price || 0))
        return planPrice+addonPrice;
    }


    const cookieOptions = {
        domain: '.'+(config.ROOT_DOMAIN || 'alpide.com'), // Replace with your desired domain
        path: '/', // Replace with the desired path
        expires: new Date(Date.now() + 86400 * 1000), // Replace with the desired expiration time
      };
    const handleRemoveCookie = () => {
        removeCookies(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, cookieOptions);
        removeCookies(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, cookieOptions);
        removeCookies(LOCAL_STORAGE_KEYS.EXPIRE_TIME, cookieOptions);
      };
    const callback = (e) => {
        props.updateState({activeTab: 3});
        handleRemoveCookie();
        logout();
    };
    const onSubmit = (e) => {
        if (e) e.preventDefault();
        if (cardRef) {
            // Call tokenize method on card element
           
            props.updateState({saveLoading:true})
            cardRef.current.tokenize().then((data) => {
                console.log('chargebee token', data);
                let payload = _generatePayload(data.token);
                console.log('payload payload ', payload);
                props.subscribedPlan(payload, callback);
            }).catch((err)=>{
                console.log('err :::::: ', err);
            })
            // .finally(()=>{
            //     props.updateState({saveLoading:false})
            // })
        }
    }

    const _generatePayload = (cbToken) => {
        let countryCode = getCountryCode(props.countryName);
        let rid = props.companyInfo?.relationshipId;
        let selectedPlan = props.selectedPlan || {};
        let payload = {
            relationshipId: rid,
            storeName: props.companyInfo?.storeName,
            cbToken: cbToken,
            email: props.email,
            firstName: props.firstName,
            lastName: props.lastName,
            streetAddress1: props.streetAddress1,
            streetAddress2: props.streetAddress2,
            cityName: props.cityName,
            stateName: props.stateName,
            zipCode: props.zipCode,
            countryName: props.countryName,
            countryCode: countryCode,
            clientUserAccountId: props.userInfo?.userId,
            userSubscribedPlans: [
                {
                    relationshipId: rid,
                    subcriptionPlanCostId: selectedPlan.subcriptionPlanCostId,
                    planId: selectedPlan.subcriptionPlanCostId,
                    alpideProductId: selectedPlan.alpideProductId,
                    planName: selectedPlan.planName,
                    planType: selectedPlan.planType,
                    countryName: props.countryName,
                    currencyCode: selectedPlan.currencyCode,
                    annualPrice: selectedPlan.annualPrice,
                    monthPrice: selectedPlan.monthPrice,
                    period: 1,
                    periodUnit: props.priceTerm === 'annualPrice' ? "year" : "month",
                    freeQuantity: 0,
                    quantity: 1,
                    price: 10000,
                    pricingModel: "flat_fee"
                }
            ],
            userSubscribedAddOns: []
        }
        if (props.addons && (Object.keys(props.addons)).length) {
            if (props.addons.users) {
                payload.userSubscribedAddOns.push({
                    relationshipId: rid,
                    addonId: noOfUser.subcriptionAddonCostId,
                    addonName: noOfUser.addonName,
                    alpideProductId: noOfUser.alpideProductId,
                    period: 1,
                    periodUnit: props.priceTerm === 'annualPrice' ? "year" : "month",
                    currencyCode: noOfUser.currencyCode,
                    freeQuantity: 0,
                    quantity: props.addons.users,
                    price: noOfUser.price,
                    pricingModel: "per_unit"
                })
            }
            if (props.addons.cloudStorage) {
                payload.userSubscribedAddOns.push({
                    relationshipId: rid,
                    addonId: cloudStorage.subcriptionAddonCostId,
                    addonName: cloudStorage.addonName,
                    alpideProductId: cloudStorage.alpideProductId,
                    period: 1,
                    periodUnit: props.priceTerm === 'annualPrice' ? "year" : "month",
                    currencyCode: cloudStorage.currencyCode,
                    freeQuantity: 0,
                    quantity: props.addons.cloudStorage,
                    price: cloudStorage.price,
                    pricingModel: "per_unit"
                })
            }
            if (props.addons.trainingUsers) {
                payload.userSubscribedAddOns.push({
                    relationshipId: rid,
                    addonId: onlineTraining.subcriptionAddonCostId,
                    addonName: onlineTraining.addonName,
                    alpideProductId: onlineTraining.alpideProductId,
                    period: 1,
                    periodUnit: props.priceTerm === 'annualPrice' ? "year" : "month",
                    currencyCode: onlineTraining.currencyCode,
                    freeQuantity: 0,
                    quantity: props.addons.trainingUsers,
                    price: onlineTraining.price,
                    pricingModel: "per_unit"
                })
            }
            if (props.addons.support) {
                payload.userSubscribedAddOns.push({
                    relationshipId: rid,
                    addonId: support.subcriptionAddonCostId,
                    addonName: support.addonName,
                    alpideProductId: support.alpideProductId,
                    period: 1,
                    periodUnit: props.priceTerm === 'annualPrice' ? "year" : "month",
                    currencyCode: noOfUser.currencyCode,
                    freeQuantity: 0,
                    quantity: props.addons.support,
                    price: support.price,
                    pricingModel: "per_unit"
                })
            }
        }

return payload;
    }

   const onChange = (status) => {
        cbErrors[status.field]= status.error;
        setCbErrors(cbErrors);
        props.updateState(cbErrors);
      }


    function getIconFromName(name) {
        switch (name) {
            case 'Cloud ERP':
                return erpLogo;
            case 'E-Commerce':
                return commerceLogo;
            case 'Education ERP':
                return educationLogo;
            case 'CRM':
                return crmLogo;
            case 'HRMS':
                return hrmsLogo;
            default:
                return erpLogo;
        }
    }

    const productName = (props.alpideProducts || []).find(obj=>obj.alpideProductId === props.alpideProductId)?.alpideProductName || '';
    
    return (
        <div className="ind_component" style={{height: 'calc(100vh - 68px)'}}>
            <div className="headingArea">
                <div className="planPricingContaner">
                    <div className="price_page_head">
                        <h3>Pay and Subscribe</h3>
                        <p>Automate business operations, access real-time insights, boost control, ensure data precision, cut costs, and elevate customer service with Alpide ERP.</p>
                    </div>
                    <div className="packageTabArea">
                        <div>01. Package</div>
                        <div>02. Add-on</div>
                        <div className="active">03. Confirmation</div>
                    </div>
                </div>
            </div>
            <Provider cbInstance={props.cbInstance}>
            {props.cbInstance ? <form>
            <div className="addOnArea">
            <div className="planPricingContaner">
                <div className="addOnPart">
                    <div className="cardArea">
                        <h4>Credit Card Information</h4>
                        {/* <div className="">
                            <div className="cardNunber">
                                <Input placeholder="Card Number" />
                                <img src={cardImg} alt="" />
                            </div>
                        </div>
                        <div className="cardDetails">
                            <div className="cardNunber width50">
                                <Input placeholder="MM/YY" />
                            </div>
                            <div className="cardNunber width50">
                                <Input placeholder="CVV" />
                                <img src={cardCVVIcon} alt="" />
                            </div>
                        </div> */}
                           
                                {/* <div className="cell example" id="example-1">
                                    
                                        <div className="">
                                            <div className="ex1-wrap">
                                            </div>
                                        </div>
                                    
                                </div> */}
                        <div className="ex1-fieldset">
                            <div className="ex1-field">
                                <CardComponent 
                                    className="field"
                                    fonts={props.fonts}
                                    classes={props.classes}
                                    locale={props.locale}
                                    styles={props.styles}
                                    ref={cardRef}
                                    onReady={onReady}
                                    onChange={onChange}
                                >


                                    <div className="ex1-field">
                                        {/* Card number component */}
                                        <label className="ex1-label">Card Number</label>
                                        <CardNumber className="ex1-input" />
                                    </div>

                                    <div className="ex1-fields">
                                        <div className="ex1-field">
                                            {/* Card expiry component */}
                                            <label className="ex1-label">Expiry</label>
                                            <CardExpiry className="ex1-input" />
                                        </div>

                                        <div className="ex1-field">
                                            {/* Card cvv component */}
                                            <label className="ex1-label">CVC</label>
                                            <CardCVV className="ex1-input" />
                                        </div>
                                    </div>

                                    {(cbErrors && (Object.keys(cbErrors)).length) ?
                                    (Object.values(cbErrors)).map((err)=>{
                                        return  err ? <div className="error" role="alert">{err.message}</div> : ''
                                    }) 
                                    :''
                                    }
                                    {props.token && <div className="token" >{props.token}</div>}
                                </CardComponent>

                            </div>
                            {/* <button type="submit" onClick={this.onSubmit}>Pay now</button> */}
                                {/* <button type="submit" className={props.loading ? "submit ex1-button" : "ex1-button"} onClick={onSubmit}> Tokenize</button>  */}
                            
                        </div>
                           

                        <h4 className="marTP1 mt20">Billing Address</h4>
                        <div className="cardDetails">
                            <div className="cardNunber width100">
                                <Dropdown value={props.countryName} items={["India", "USA", "UK"]} />
                            </div>
                        </div>
                        <div className="cardDetails">
                            <div className="cardNunber width50">
                                <Input placeholder="First Name*" value={props.firstName} onChange={(e) => {
                                    props.updateState({ firstName: e.target.value });
                                }} />
                            </div>
                            <div className="cardNunber width50">
                                <Input placeholder="Last Name*" value={props.lastName} onChange={(e) => {
                                    props.updateState({ lastName: e.target.value });
                                }} />
                            </div>
                        </div>
                        <div className="cardDetails">
                            <div className="cardNunber width100">
                                <Input placeholder="Company Name(Optional)" value={props.companyName} onChange={(e) => {
                                    props.updateState({ companyName: e.target.value });
                                }} />
                            </div>
                        </div>
                        <div className="cardDetails">
                            <div className="cardNunber width50">
                                <Input placeholder="Email Address*" value={props.email} onChange={(e) => {
                                    props.updateState({ email: e.target.value });
                                }} />
                            </div>
                            <div className="cardNunber width50">
                                <Input placeholder="Contact Number*" value={props.mobileNumber} onChange={(e) => {
                                    props.updateState({ mobileNumber: e.target.value });
                                }} />
                            </div>
                        </div>
                        <div className="cardDetails">
                            <div className="cardNunber width50">
                                <Input placeholder="Street Address" value={props.streetAddress1} onChange={(e) => {
                                    props.updateState({ streetAddress1: e.target.value });
                                }} />
                            </div>
                            <div className="cardNunber width50">
                                <Input placeholder="Apartment, suite, etc. (Optional)" value={props.streetAddress2} onChange={(e) => {
                                    props.updateState({ streetAddress2: e.target.value });
                                }} />
                            </div>
                        </div>
                        <div className="cardDetails">
                            <div className="cardNunber width50">
                                <Input placeholder="City" value={props.cityName} onChange={(e) => {
                                    props.updateState({ cityName: e.target.value });
                                }} />
                            </div>
                            <div className="cardNunber width50">
                                <Input placeholder="State" value={props.stateName} onChange={(e) => {
                                    props.updateState({ stateName: e.target.value });
                                }} />
                            </div>
                        </div>
                        <div className="cardDetails">
                            <div className="cardNunber width100">
                                <Input placeholder="Zip Code" value={props.zipCode} onChange={(e) => {
                                    props.updateState({ zipCode: e.target.value });
                                }} />
                            </div>
                        </div>
                        </div>

                    <div className="summary">
                        <div>
                            <h4>Summary</h4>
                            <div className="summaryPart">
                                <div className="productDetail">
                                    <div className="productName">
                                        <img src={getIconFromName(productName)} alt="" height='25px' width='25px' />
                                        <div>
                                            <h5>{productName} - {props.selectedPlan?.planName || 'Cloud ERP - Growth'}</h5>
                                        </div>
                                    </div>
                                    <div className="productPrice">
                                        <div className="buttonPart">
                                            <Button onClick={()=>{
                                                props.updateState({activeTab: 0})
                                            }}>Change</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="addOnPart">
                                    <div className="listItem">
                                        <ul>
                                            <li>
                                                <div className="listName">
                                                    License X <span>1</span> <span className="subscipton">Annual</span>
                                                </div>
                                                <div className="price">{getCurrencySymbol(selectedPlan.currencyCode)}{selectedPlan[props.priceTerm]}</div>
                                            </li>
                                            <li>
                                                <div className="listName">
                                                    Addons X <span>{Object.keys(addons)?.length}</span>
                                                </div>
                                                <div className="price">
                                                    {getCurrencySymbol(selectedPlan.currencyCode)}{((addons.users || 0) * (noOfUser.price || 0)) + ((addons.cloudStorage || 0) * (cloudStorage.price || 0)) + ((addons.trainingUsers || 0) * (onlineTraining.price || 0)) + ((addons.support || 0) * (support.price || 0))}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="listName">
                                                    Coupon
                                                </div>
                                                <div className="price">
                                                    <Button>Add</Button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="carPayment">
                                <div className="totalPriceArea">
                                    <div>Subtotal</div>
                                    <div>{getCurrencySymbol(selectedPlan.currencyCode)}{calulateSubTotal()}</div>
                                </div>
                                <div className="totalPriceArea">
                                    <div>Tax (18%)</div>
                                    <div>{getCurrencySymbol(selectedPlan.currencyCode)}{fixedDecimalNumber(calulateSubTotal() * 0.18)}</div>
                                </div>
                                <div className="totalPriceArea">
                                    <div><span>Total</span></div>
                                    <div><span>{getCurrencySymbol(selectedPlan.currencyCode)}{fixedDecimalNumber(calulateSubTotal()*1.18)}</span></div>
                                </div>
                            </div>
                            <div className="buttonArea">
                                {/* <Button type="primary" onClick={(e)=>{
                                    onSubmit(e);
                                    props.updateState({activeTab: 3})}}>Pay & Subscribe</Button> */}

                                <Button type="submit"
                                disabled={props.saveLoading}
                                loading={props.saveLoading}
                                className="subscribe-btn"

                                // className={props.loading ? "primary ex1-Button" : "ex1-button"} 
                                onClick={onSubmit}> Pay & Subscribe</Button> 
                            </div>
                            <div className="buttonArea">
                                <p>
                                    {/* By clicking the place order, I actively agree to the <a href="">terms and conditions</a>, */}
                                    By subscribing, you agree to the terms of use for Alpide Products and offers. You may cancel your subscription at any time, but please note that refunds are not provided for partially used subscriptions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </form> : ''
                                    }
            </Provider>
            <div className="mb20" />
        </div>
    )
}
export default PaymentComponent