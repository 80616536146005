import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddressDrawer from "../../../components/drawer/Address";
import {
  fetchCountries,
  fetchStates,
  resetStates,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
} from "../../../actions/commonActions";
import { createAddress } from "./action";
import { fetchDataIfNeeded } from "../../../utils";
import { CONSTANTS } from "../../../static/constants";
import { fetchSupplierAddresses } from "../../supplier/detail/action";
class NewAddressContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryName: "",
      countryId: 0,
    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    fetchDataIfNeeded("fetchCountries", "countries", this.props);
    if (companyInfo.countryName === "India") {
      fetchDataIfNeeded(
        "fetchStates",
        "states",
        this.props,
        CONSTANTS.INDIA_COUNTRY_ID
      );
    } else {
      this.props.resetStates();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.countries !== this.state.countries &&
      nextProps.countries &&
      !this.state.countryName
    ) {
      let obj =
        nextProps.countries.find(
          (country) =>
            country.countryName === this.props.companyInfo.countryName
        ) || {};
      if (
        (obj.countryName === "United States" ||
          obj.countryName === "United States (USA)") &&
        !nextProps.states.length
      ) {
        this.props.fetchStates(obj.countryId);
        // fetchDataIfNeeded('fetchStates', 'states', this.props, obj.countryId);
      }
      this.setState({
        countryName: obj.countryName || "",
        countryId: obj.countryId,
      });
    }
  }

  render() {
    return (
      <AddressDrawer
        updateState={(data) => {
          this.setState(data);
        }}
        {...this.props}
        {...this.state}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    countries: state.common.countries,
    states: state.common.states,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAddress,
      fetchCountries,
      fetchStates,
      pushModalToStack,
      popModalFromStack,
      showModal,
      hideModal,
      resetStates,
      fetchSupplierAddresses
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewAddressContainer);
