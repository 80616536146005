/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FilterOutlined,MoreOutlined, ShareAltOutlined} from '@ant-design/icons';
import { Skeleton, Menu } from 'antd';
// import { AgGridReact } from 'ag-grid-react';
import { ICONS, MODAL_TYPE } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
import { sortTable } from '../../../utils';
import SharePaymentURLComponent from './SharePaymentURL';
import DropdownAction from "antd/lib/dropdown";


const ProjectListingComp = (props) => {
    // const format = CONSTANTS.DISPLAY_DATE_FORMAT;
    // const companyInfo = props.companyInfo;
    // const onFirstDataRendered = (params) => {
    //     params.api.sizeColumnsToFit();
    // };
    // const finacialYear = getFinancialYear(companyInfo);
    const { pageNumber, projectList, dir=0, updateState} = props;
    
    // const fyStartDate = getMomentDateForUI({ date: new Date(finacialYear.fyStartDate), format: CONSTANTS.BACKEND_FORMAT });
    //const fyEndDate = getMomentDateForUI({ date: new Date(finacialYear.fyEndDate), format: CONSTANTS.BACKEND_FORMAT });

    // const columnDefs = [
        
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="common.project" /></span>
    //             </div>
    //         },
    //         field: "projectName",
    //         resizable: true,
    //     },
        
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="modal.common.project.projectNumber" /></span>
    //             </div>
    //         },
    //         field: "projectNumber",
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.deposit" /></span>
    //             </div>
    //         },
    //         field: "totalDeposit",
    //         type: 'rightAligned' ,
    //         cellRendererFramework: (link) => <div>
    //             { Number(link.data.totalDeposit || 0).toFixed(2)}
    //         </div>,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="banking.withdrawal" /></span>
    //             </div>
    //         },
    //         field: "totalWithdrawal",
    //         resizable: true,
    //         type: 'rightAligned', 
    //         cellRendererFramework: (link) => <div>
    //            { Number(link.data.totalWithdrawal || 0).toFixed(2)}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div className='table-header-column-container'>
    //                 <span className='table-header-column'><FormattedMessage id="balance.text" /></span>
    //             </div>
    //         },
    //         field: "balance",
    //         resizable: true,
    //         type: 'rightAligned', 
    //         cellRendererFramework: (link) => <div>
    //            { Number(link.data.totalDeposit || 0) >= Number(link.data.totalWithdrawal || 0) ? (Number(link.data.totalDeposit || 0) -  Number(link.data.totalWithdrawal || 0)).toFixed(2): '('+(Number(link.data.totalWithdrawal || 0) -  Number(link.data.totalDeposit || 0)).toFixed(2)+')'}
    //         </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='company.hierarchy.action' defaultMessage='' />;
    //         },
    //         field: "action",
    //         pinned: "right",
    //         lockPosition:true,
    //         cellRendererFramework: (params) => {
    //             return <div>
    //             <span className="cursor-pointer" title={<FormattedMessage id='common.edit.hierarchy' defaultMessage='' />} onClick={
    //                 () => {
    //                     projectAddOrUpdate(props, params.data)
    //                 }}>
    //                 <i className='fa fa-pencil' />
    //             </span>
    //                 <span style={{ marginLeft: '12px' }} className="cursor-pointer" title={<FormattedMessage id='common.delete.project' defaultMessage='' />} onClick={
    //                     () => {
    //                         deleteHandle(params.data);
    //                     }}>
    //                     <i className='fa fa-trash' />
    //                 </span>
    //                 <span style={{ marginLeft: '12px' }} className="cursor-pointer" title={props.intl.formatMessage({id: 'campaign.sharePaymentUrl.tooltip.msg' })} onClick={() => {
    //                   SharePaymentURLHandle(params.data);
    //                   }}>
    //                     <i className="fa fa-share" />
    //                 </span>
    //             </div>;
    //         },
    //         width: 100,
    //     }
    // ];
    
    const SharePaymentURLHandle = (project) => {
        props.pushModalToStack({
            title: <div>
                <FormattedMessage id='sharePaymentUrl.text' defaultMessage='' />
                <div style={{ 'fontSize': '12px' }}> {project.projectName|| ''}</div>
            </div>,
            modalBody: <SharePaymentURLComponent {...props} projectData={project} />,
            width: '50%',
            hideFooter: true,
        })
    }

    const deleteHandle = (project) => {
        const modalBody =  <FormattedMessage id='deleteItem.confirmation' defaultMessage=''  values={{value: project.name}}/>;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.deleteProject({
                    relationshipId: (props.companyInfo|| {}).relationshipId,
                    id: project.projectMasterId
                }, props)
            },
        };
        props.showModal(modalData);
    }

    const projectAddOrUpdate = (props, projectData) =>{
      props.updateState({
        projectDrawerVisible:true,
        projectData
      })
      
      // const formData = {};
        // const { showModal, companyInfo } = props;
        // formData.relationshipId = companyInfo.relationshipId;
        // const data = {
        //   title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
        //   formData,
        //   hideFooter: true,
        //   modalData: {
        //     modalType: MODAL_TYPE.PROJECT,
        //     data: projectData || {},
        //   },
        //   handleSubmit: (formData = {}) => {
        //     data.formData.submittedOnce = true;
        //     showModal(data);
        //   }
        // };
        // showModal(data);
    }

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    const breadCrumbList = [
       {
            name: <FormattedMessage id='sidebar.menuItem.teamName' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.project' defaultMessage='' />,
        }
    ];

    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
    
              <Menu.Item key="0" value="title">
                Actions
              </Menu.Item>
    
              <Menu.Divider />
    
              <Menu.Item key="1" value="edit" onClick={()=>{
                    projectAddOrUpdate(props, e);
                }}
              >
                <i className={ICONS.EDIT} />
                Edit
              </Menu.Item>
    
              <Menu.Item key="2" onClick={()=>
                {
                    deleteHandle(e);
                }
              }>
                <i className={ICONS.DELETE} />
                Delete
              </Menu.Item>
              <Menu.Item key="3" onClick={()=>
                {
                    SharePaymentURLHandle(e);
                }
              }>
                <ShareAltOutlined />
                Share Payment Url
              </Menu.Item>
    
            </Menu >
        )
      };
    
      const sortColumn = (e) => {
        sortTable(document.getElementById('project-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
      }

    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="sidebar.menuItem.project"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={() => {
                projectAddOrUpdate(props);
              }}
            >
              <i className={ICONS.ADD} /> <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>

        <div className="view-container">
          <Skeleton loading={props.isLoading}>
            <div className="view-container-actions">
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="organization.project.table.heading"
                    defaultMessage=""
                  />
                </div>
                {/* {pageSize ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{pageSize}</div>
                  </>
                ) : (
                  ""
                )} */}
              </div>
              {/* <div className="right-actions">
                <button>
                  <FilterOutlined /> &nbsp; Filters
                </button>
              </div> */}
            </div>
            {/* <Row>
                  <Col span={6} style={{ paddingRight: "10px" }}>
                    <FormattedMessage
                      id="modal.reports.partyLedger.startDate.label"
                      defaultMessage=""
                    />
                    <DatePicker
                      onChange={(dateObj) => {
                        if (dateObj) {
                          props.updateState({
                            startDate: dateObj.format(CONSTANTS.BACKEND_FORMAT),
                          });
                        } else {
                          props.updateState({
                            startDate: undefined,
                          });
                        }
                      }}
                      format={format}
                      key={`${
                        props.startDate
                          ? getMomentDateForUI({
                              date: new Date(props.startDate),
                              format,
                            })
                          : getMomentDateForUI({
                              date: new Date(fyStartDate),
                              format,
                            })
                      }`}
                      defaultValue={
                        props.startDate
                          ? getMomentDateForUI({
                              date: new Date(props.startDate),
                              format,
                            })
                          : getMomentDateForUI({
                              date: new Date(fyStartDate),
                              format,
                            })
                      }
                    />
                  </Col>
                  <Col span={6}>
                    <div style={{ paddingRight: "10px" }}>
                      <FormattedMessage
                        id="modal.reports.partyLedger.endDate.label"
                        defaultMessage=""
                      />
                      <DatePicker
                        onChange={(dateObj) => {
                          if (dateObj) {
                            props.updateState({
                              endDate: dateObj.format(CONSTANTS.BACKEND_FORMAT),
                            });
                          } else {
                            props.updateState({
                              endDate: undefined,
                            });
                          }
                        }}
                        format={format}
                        key={`${
                          props.endDate
                            ? getMomentDateForUI({
                                date: new Date(props.endDate),
                                format,
                              })
                            : getMomentDateForUI({ date: new Date(), format })
                        }`}
                        defaultValue={
                          props.endDate
                            ? getMomentDateForUI({
                                date: new Date(props.endDate),
                                format,
                              })
                            : undefined
                        }
                      />
                    </div>
                  </Col>
                  <Col
                    span={2}
                    style={{ paddingTop: "2px", paddingRight: "10px" }}
                  >
                    <Button
                      className="ant-btn ant-btn-default vab mt20"
                      style={{ height: "38px" }}
                      onClick={() => {
                        if (!props.startDate || !props.endDate) {
                          showToasterMessage({
                            description: "Please select date range.",
                            messageType: "error",
                          });
                        } else if (
                          !isValidDateRange(props.startDate, props.endDate)
                        ) {
                          showToasterMessage({
                            description: "Please select valid date range",
                            messageType: "error",
                          });
                        } else {
                          const companyInfo = props.companyInfo || {};
                          props.getAllProject(
                            {
                              startDate: formatDateForBackend(props.startDate),
                              endDate: formatDateForBackend(props.endDate),
                              relationshipId: companyInfo.relationshipId,
                              pageNumber: 1,
                              pageSize: 25,
                            },
                            props
                          );
                        }
                      }}
                    >
                      {<FilterOutlined />}
                    </Button>
                  </Col>
                </Row> */}

            {/* <div className="agGridWrapper">
              <div
                className="ag-theme-balham customer-details-table"
                style={agGridStyle}
              >
                <AgGridReact
                  columnDefs={columnDefs}
                  defaultColDef={{
                    flex: 1,
                    autoHeight: true,
                    wrapText: true,
                  }}
                  onColumnResized={(params) => {
                    params.api.resetRowHeights();
                  }}
                  rowData={props.projectList[props.pageNumber || 1] || []}
                  rowDragManaged={true}
                  domLayout={"autoHeight"}
                  animateRows={true}
                  onGridSizeChanged={onFirstDataRendered}
                  enableCellTextSelection={true}
                ></AgGridReact>
              </div>
            </div> */}

            <div className="table-container">
              <table id="project-table">
                <thead>
                  <tr>
                    <th>S. No</th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="common.project" /> <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="modal.common.project.projectNumber" /> <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="banking.deposit" /> <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="banking.withdrawal" /> <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th onClick={sortColumn}>
                      <FormattedMessage id="balance.text" /> <i class="fi fi-rr-sort-alt"></i>
                    </th>
                    <th className='text-center'>
                      <FormattedMessage
                        id="company.hierarchy.action"
                        defaultMessage=""
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projectList &&
                    projectList[pageNumber || 1].map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{rowData.projectName}</td>
                          <td>{rowData.projectNumber}</td>
                          <td>{(rowData.totalDeposit || 0).toFixed(2)}</td>
                          <td>{(rowData.totalWithdrawal || 0).toFixed(2)}</td>
                          <td>{(rowData.balance || 0).toFixed(2)}</td>
                          <td className='text-center'>
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <MoreOutlined />
                            </DropdownAction>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="footer">
              <div className="f-left"></div>
              <div className="f-right"></div>
            </div>
          </Skeleton>
        </div>
      </>
    );
};



export default injectIntl(ProjectListingComp);
