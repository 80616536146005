import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST, CONSTANTS, COMMON_ACTIONS } from "../../../static/constants";
import { showToasterMessage,exportExcel, getMomentDateForUIReadOnly } from "../../../utils";
const { lang } = require("../../../translations/" +(sessionStorage.getItem("language") || "en") +".js");
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

export const getTeacher = (payload) => {
  return (dispatch) => {
    dispatch({type: COMMON_ACTIONS.SHOW_LIST_LOADER});
    return axios
      .get(
       // `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getInstructor?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25
       `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relEmployee/getEmpByRole?relationshipId=${payload.relationshipId}&role=teacher&pageSize=${payload.pageSize || 25 
      }&pageNumber=${(payload.pageNumber || 1) - 1}&empId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.TEACHER_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      }) .finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_LIST_LOADER,
        });
      });
  };
};

export const getTeacherCount = (payload) => {
  return (dispatch) => {
    dispatch({type: COMMON_ACTIONS.SHOW_HEADER_LOADER});
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/relEmployee/getEmpCountByRole?relationshipId=${payload.relationshipId}&empId=${payload.relationshipEmployeeId}&role=Teacher`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.TEACHER_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_HEADER_LOADER,
        });
      });
  };
};

export const deleteTeacher = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/teacher/delete`, payload)
      .then(res => {
        // dispatch({ type: CUSTOMER_ACTION_LIST.DELETE_ACTIVITY, data: payload });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        // props.getFees && props.getFees(props);
        getTeacher(props)(dispatch);
        //props.getFeeCount && props.getFeeCount(props);
       // showToasterMessage({ messageType: 'success', description: 'Term & Fee deleted successfully' });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}
export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.TEACHER_LIST_PAGE_RESET });
  };
};

export const deleteAssignedClass = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/instructorClassRef/delete`, payload)
      .then(res => {
        getTeacher(props)(dispatch);
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}


const exportExcelData = (studentList, teacher) => {
  let dataList = [];
     (studentList || []).map((e, i) => {
     // let questionList= JSON.parse('[{\"optionList\":[{\"optText\":\"sfsdfsdf\"},{\"optText\":\"sdfsdfs\"}],\"questionText\":\"Test question 2\",\"answerType\":\"singleChoice\",\"answers\":\"sfsdfsdf\"},{\"optionList\":[{\"optText\":\"sdfsdf22\"},{\"optText\":\"sdfsdf\"},{\"optText\":\"sdffff\"},{\"optText\":\"qqqqqq\"}],\"questionText\":\"Test question 1\",\"answerType\":\"multiChoice\",\"answers\":[\"sdfsdf22\",\"sdffff\"]},{\"optionList\":[],\"answerType\":\"shortAnswer\",\"questionText\":\"Question 3333333333\",\"answers\":\"ggggggggttttttttttt\"}]');
   // let questionList= JSON.parse(e.questionList || '[]');
    
      let rowData = {
        '#': i + 1,
        'Teacher Name': teacher.firstName +(teacher.lastName ? ' ' + teacher.lastName : ''),
        'Student Name': (e.firstName || '') + (e.lastName ? ' ' + e.lastName : ''),
        'Gender': e.gender|| '',
        'Father Name': (e.fatherFirstName || '') + (e.fatherLastName ? ' ' + e.fatherLastName : ''),
        'Father Phone': e.fatherMobile || '',
        'Father Email': e.fatherEmail|| '',
        'Mother Name': (e.motherFirstName || '') + (e.motherLastName ? ' ' + e.motherLastName : ''),
        'Mother Phone': e.motherMobile|| '',
        'Mother Email': e.motherEmail|| '',
        'Student DOB': e.dob ? getMomentDateForUIReadOnly({ date: new Date(e.dob), format }) : '',
        'Grade': e.grade? 'Grade '+e.grade : '',
        'Allergies': e.allergies|| '',
        'Emergency Contact': (e.emergencyContactName || '') + (e.emergencyPhone ? ' ' + e.emergencyPhone : ''),
        'Date Created': e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : '',
        'Subcourse': e.subcourseName || '',
        //'Class': (e.schoolStudentClassRefList || []).map(classObj => classObj.className).join(', ')
        'Class': e.className
      }
     
    //   questionList.forEach(function (qaObj, i) {
    //     rowData[qaObj.questionText] = typeof qaObj.answers === 'object'? (qaObj.answers || []).join(', '): qaObj.answers ;
    // });
      dataList.push(rowData);
      return '';
    })
  exportExcel(dataList, teacher.firstName+'StudentList');
}

export const exportStudentExcelDataForTeacher = (payload, props) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/instructorClassRef/getStudentByTeacher?relationshipId=${payload.relationshipId}&formId=${40}&empId=${payload.relationshipEmployeeId}&pageSize=2000&pageNumber=0`
      )
      .then((res) => {
       let studentList = res.data;
        if (studentList && studentList.length) {
            exportExcelData(studentList, payload);
          } else {
            showToasterMessage({messageType: "error", description: "No record found",});
          }
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({messageType: "error", description:lang[((err.response || {}).data || {}).message] || "Some error occurred",});
        }
      });
  };
};





