import React, { Fragment } from 'react';
import './../../../../assets/stylesheets/common/invoice.scss';
import ExpenseDetails from "./ExpenseDetails";
import ExpenseProductDetails from "./ExpenseProductDetails";
import ExpenseHeader from "./ExpenseHeader";
import { Divider } from 'antd';

const BusinessExpenseComponent = (props) => {
    // const breadCrumbList = [
    //     // {
    //     //     name: 'Dashboard',
    //     //     link: 'dashboard'
    //     // },
    //     {
    //         name: 'Finance'
    //     }, {
    //         name: 'Expense',
    //         link: '/admin/business-expense-listing'
    //     }, {
    //         name: 'Create'
    //     }
    // ];

    return (
        <Fragment>
             <div className="txn-header">
        <ExpenseHeader {...props} />
      </div>
            {/* <div className="invoice-header">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div> */}
             <div className="txn-body">
        <div className="txn-details-container">
        <ExpenseDetails paymentTerms={props.paymentTerms} {...props}/>

        </div>
        <Divider style={{ margin: "0 0 10px 0px" }} />
        <div className="product-details-container">
        <ExpenseProductDetails {...props} />
        </div>
      </div>
           
        </Fragment>
    )
};

export default BusinessExpenseComponent;
