import axios from 'axios';
import config from '../../../config/environmentConfig';
import { JOURNAL_ACTION_LIST, COMMON_ACTIONS } from '../../../static/constants'


export const fetchAllJournals = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/journalTx/getAllJournalTx?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: JOURNAL_ACTION_LIST.JOURNAL_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = JOURNAL_ACTION_LIST.JOURNAL_LIST;
        }
      })
  }
}