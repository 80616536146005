import axios from "axios";
import config from "../../config/environmentConfig";
import { KIOSK_ACTION_LIST } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getAllKiosk = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/kiosk/getAllKiosk?relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        
        dispatch({
          type: KIOSK_ACTION_LIST.KIOSK_LIST,
          data:res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};



