import React, { Fragment, useEffect, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";

import { ErrorMsg } from "../../general/ErrorMessage";
// import ProductDetailsTab from "./ProductDetailsTab";

import {  CONSTANTS } from "../../../static/constants";

import "@ant-design/compatible/assets/index.css";


import {
  
  Col,
  Row,
  Input,
  Tabs,

  Select,
 
  DatePicker
} from "antd";
import {
  
 
  getMomentDateForUI,
} from "../../../utils";


const DispatchDetails = (props) => {
  return (
    <div>
        <div>
        <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from Trade Name"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from Address 1"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Dispatch from Trade Name'}
type="text"
value={props.dispatchTradeName}
onChange={(e) => {
props.updateState({
  dispatchTradeName: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Dispatch Address'}
type="text"
maxLength={20}
value={props.dispatchAddress}
onChange={(e) => {
props.updateState({
  dispatchAddress: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from Location"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from State Code"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Dispatch Location'}
type="text"
value={props.dispatchLocation}
onChange={(e) => {
props.updateState({
  dispatchLocation: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Dispatch State Code'}
type="text"
maxLength={20}
value={props.dispatchStateCode}
onChange={(e) => {
props.updateState({
  dispatchStateCode: e.target.value,
});
}}
/>

</Col>
</Row>
           </div> 

            <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from Pincode"
    defaultMessage=""
  />
  

</Col>


</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Dispatch Pincode'}
type="text"
value={props.dispatchPincode}
onChange={(e) => {
props.updateState({
  dispatchPincode: e.target.value,
});
}}
/>

</Col>


</Row>
           </div>
        </div>
        <div>
      {
    props.optionalFields ? (
<div className="mt20">
   <div className="drawer-heading">Optional Details</div>
   <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from Legal Name"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from Address 2"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Dispatch Legal Name'}
type="text"
value={props.dispatchLegalName}
onChange={(e) => {
props.updateState({
    dispatchLegalName: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Dispatch Address 2'}
type="text"
maxLength={20}
value={props.dispatchAddress2}
onChange={(e) => {
props.updateState({
    dispatchAddress2: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from District"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from Phone or Mobile No"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={' District'}
type="text"
maxLength={20}
value={props.dispatchDistrict}
onChange={(e) => {
props.updateState({
    dispatchDistrict: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Phone No.'}
type="text"
maxLength={20}
value={props.dispatchPhoneNo}
onChange={(e) => {
props.updateState({
    dispatchPhoneNo: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from E-mail id"
    defaultMessage=""
  />
  

</Col>
<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Dispatch from GSTIN"
    defaultMessage=""
  />
  

</Col>

</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={' Email'}
type="text"
value={props.dispatchEmailId}
onChange={(e) => {
props.updateState({
    dispatchEmailId: e.target.value,
});
}}
/>

</Col>
<Col span={12}>
<TextBox
maxLength={20}
placeholder={' GSTIN'}
type="text"
value={props.dispatchGSTIN}
onChange={(e) => {
props.updateState({
    dispatchGSTIN: e.target.value,
});
}}
/>

</Col>

</Row>
           </div>
   </div>
    ):''
   }
      </div>
    </div>
  )
}

export default injectIntl(DispatchDetails)