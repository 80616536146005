import React from "react";
import { Drawer, Row, Col, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
import {
  getMomentDateForUI,
  getCurrentDateForBackend,
  showToasterMessage,
} from "../../../../utils";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { Dropdown } from "../../../general/Dropdown";
import { CONSTANTS } from "../../../../static/constants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;

const PurchaseRequestFiltersComp = (props) => {
  const { updateState, companyInfo } = props;

  const closeDrawer = () => {
    props.updateState({
      purchaseFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
    // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
    if (
      !props.prNum &&
      !props.selectedRequesterName &&
      !props.startDate &&
      !props.endDate &&
      !props.expStartDate &&
      !props.expEndDate &&
      !props.selectedUserStatus &&
      !props.selectedPriority &&
      !props.selectedRefNum &&
      !props.selectedProjectMasterId
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please select at least one filter",
      });
    }
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {
        prNumber: props.prNum || null,
        requesterId: props.selectedRequesterId || 0,
        requesterName: props.selectedRequesterName || null,
        startDate: props.startDate
          ? getCurrentDateForBackend(props.startDate)
          : null,
        endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
        expStartDate: props.expStartDate
          ? getCurrentDateForBackend(props.expStartDate)
          : null,
        expEndDate: props.expEndDate
          ? getCurrentDateForBackend(props.expEndDate)
          : null,
        userStatus: props.selectedUserStatusName || null,
        // status: props.selectedStatus || null,
        priority: props.selectedPriority || null,
        reference: props.selectedRefNum || null,
        projectMasterId: props.selectedProjectMasterId || 0,
      },
    };
    props.fetchPurchaseRequestsByFilter(payload);
    props.getPurchaseRequestsCountByFilter(payload);
    props.updateState({purchaseFilterDrawerVisible: false});
  };

  const clearFilter = () => {
    props.updateState({
      prNum: null,
      selectedRequesterId: null,
      selectedRequesterName: null,
      startDate: null,
      endDate: null,
      expStartDate: null,
      expEndDate: null,
      selectedUserStatus: null,
      selectedUserStatusName: null,
      selectedStatus: null,
      selectedStatusName: null,
      selectedPriority: null,
      selectedRefNum: null,
      selectedProjectMasterId: null,
      selectedProjectName: null,
    });
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {},
    };
    props.fetchPurchaseRequests(payload);
    props.getPurchaseRequestsCount(payload);
  };

  const rowGutter = [24, 16];

  return (
   
    <Drawer
      title={<FormattedMessage id="supplier.purchaseRequest.filter.drawer.title" />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.purchaseFilterDrawerVisible}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />
          <div>
            <CustomButton
              intlId="common.clearFilter.txt"
              defaultMessage=""
              type="default"
              key={0}
              onClick={clearFilter}
              btnIcon={
                <>
                  <UndoOutlined /> &nbsp;
                </>
              }
              style={{ marginRight: "10px" }}
            />
            <CustomButton
              intlId="common.applyFilter.txt"
              defaultMessage=""
              htmlType="submit"
              onClick={applyFilter}
              key={1}
              btnIcon={
                <>
                  <FilterOutlined /> &nbsp;
                </>
              }
            />
          </div>
        </>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">Request #</div>
          <TextBox
            placeholder="Request #"
            type="text"
            maxLength={20}
            countStyle={{ top: "38px" }}
            value={props.prNum}
            onChange={(e) => {
              props.updateState({ prNum: e.target.value });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Requester</div>
          <Dropdown
            items={(props.requesterList || []).sort((a, b) => a.fullName.localeCompare(b.fullName))}
            valueKeyName="fullName"
            optionKeyName="relationshipEmployeeId"
            value={props.selectedRequesterName}
            placeholder="Select"
            onSelect={(status, option) => {
              updateState({
                selectedRequesterName: option.name,
                selectedRequesterId: option.value,
              });
            }}
            allowClear={true}
            onClear={() => {
              updateState({
                selectedRequesterName: undefined,
                selectedRequesterId: undefined,
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">Request Date</div>
          <RangePicker
            allowEmpty={true}
            format={format}
            placeholder={["PR Start Date", "PR End Date"]}
            value={[
              props.startDate
                ? getMomentDateForUI({
                    date: new Date(props.startDate),
                    format,
                  })
                : null,
              props.endDate
                ? getMomentDateForUI({
                    date: new Date(props.endDate),
                    format,
                  })
                : null,
            ]}
            onChange={(dates) => {
              if (dates) {
                props.updateState({ startDate: dates[0], endDate: dates[1] });
              } else {
                props.updateState({ startDate: null, endDate: null });
              }
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Expiration Date</div>
          <RangePicker
            allowEmpty={true}
            format={format}
            placeholder={["Exp start date", "Exp end date"]}
            value={[
              props.expStartDate
                ? getMomentDateForUI({
                    date: new Date(props.expStartDate),
                    format,
                  })
                : null,
              props.expEndDate
                ? getMomentDateForUI({
                    date: new Date(props.expEndDate),
                    format,
                  })
                : null,
            ]}
            onChange={(dates) => {
              if (dates) {
                props.updateState({
                  expStartDate: dates[0],
                  expEndDate: dates[1],
                });
              } else {
                props.updateState({ expStartDate: null, expEndDate: null });
              }
            }}
          />
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="common.status" />
          </div>
          <Dropdown
            items={props.userStatus || []}
            sortItems={true}
            valueKeyName="statusName"
            optionKeyName="prStatusId"
            statusColorKey="statusColor"
            value={props.selectedUserStatusName}
            placeholder={props.intl.formatMessage({ id: "common.status" })}
            onSelect={(selectedValue, option) => {
              updateState({
                selectedUserStatusName: option.name,
                selectedUserStatus: option.value,
              });
            }}
            allowClear={true}
            onClear={() => {
              updateState({
                selectedUserStatusName: undefined,
                selectedUserStatus: undefined,
              });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="common.systemStatus" />
          </div>
          <Dropdown
            items={[
              { name: "Fullfilled", value: "fullFilled" },
              { name: "Open", value: "open" },
              { name: "Partially Fullfilled", value: "partiallyFullfilled" },
            ]}
            valueKeyName="name"
            optionKeyName="value"
            value={props.selectedStatusName}
            allowClear={true}
            placeholder={props.intl.formatMessage({
              id: "common.systemStatus",
            })}
            onClear={() => {
              updateState({
                selectedStatusName: undefined,
                selectedStatus: undefined,
              });
            }}
            onSelect={(status, option) => {
              updateState({
                selectedStatusName: option.name,
                selectedStatus: option.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="customer.filter.priority.placeholder" />
          </div>
          <Dropdown
            items={[
              { name: "Low", value: "low" },
              { name: "Medium", value: "medium" },
              { name: "High", value: "high" },
              { name: "Urgent", value: "urgent" },
            ]}
            valueKeyName="name"
            optionKeyName="value"
            value={props.selectedPriority}
            placeholder={props.intl.formatMessage({
              id: "customer.filter.priority.placeholder",
            })}
            onSelect={(status, option) => {
              updateState({ selectedPriority: option.name });
            }}
            allowClear={true}
            onClear={() => {
              updateState({ selectedPriority: undefined });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="supplier.filter.refNum.placeholder" />
          </div>
          <TextBox
            placeholder={props.intl.formatMessage({
              id: "supplier.filter.refNum.placeholder",
              defaultMessage: "",
            })}
            type="text"
            maxLength={20}
            countStyle={{ top: "38px" }}
            value={props.selectedRefNum}
            onChange={(e) => {
              props.updateState({ selectedRefNum: e.target.value });
            }}
          />
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">
            <FormattedMessage id="salesInvoice.project.placeholder" />
          </div>
          <Dropdown
            items={props.projectList}
            sortItems={true}
            sortKey={"projectName"}
            sortDirection="asc"
            valueKeyName="projectName"
            optionKeyName="projectMasterId"
            //projectIdKey="projectMasterId"
            value={props.selectedProjectName}
            placeholder={props.intl.formatMessage({
              id: "salesInvoice.project.placeholder",
            })}
            onSelect={(status, option) => {
              updateState({
                selectedProjectName: option.name,
                selectedProjectMasterId: option.value,
              });
            }}
            allowClear={true}
            onClear={() => {
              updateState({
                selectedProjectName: undefined,
                selectedProjectMasterId: undefined,
              });
            }}
          />
        </Col>
      </Row>
    </Drawer>
    
  );
};

export default PurchaseRequestFiltersComp;
