import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TotalOrdersComponent from '../../../../../components/modal/modalBody/customer/TotalOrders';
import { fetchTotalOrders, fetchTotalOrdersCount, resetPaginatedData } from './action';
import { popModalFromStack } from './../../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from  '../../../../../utils';

class TotalOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
          pageSize: 25,
          pageNumber: 1,
          searchedText: ''
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 25
        }
        // this.props.fetchPaginationDataIfNeeded('fetchTotalOrders', 'totalOrderList', this.props, payload, true);
        // fetchDataIfNeeded('fetchTotalOrdersCount', 'totalOrderListCount', this.props, payload, true);
        this.props.fetchTotalOrders(payload, {companyInfo});
        this.props.fetchTotalOrdersCount(payload, {companyInfo});

    }

    render() {
        return <TotalOrdersComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        totalOrderListCount: state.customer.totalOrderListCount,
        totalOrderList: state.customer.totalOrderList,
        modalLoading: state.common.modalLoading,

    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTotalOrders,
    popModalFromStack,
    fetchTotalOrdersCount,
    resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TotalOrders);