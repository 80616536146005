import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignCompDetails from "../../../../components/fundraising/campaign/campaignDetails";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { getTeamMaster, deleteTeamMaster, deleteTeamMember } from "./action";
import { fetchNewsLetter } from "../../../B2B/NewLetterPreview/action";
import { fetchPaginationDataIfNeeded } from "../../../../utils";
import { getSubdomain } from '../../../modal/modalBody/settings/CompanyInfo/action';

class CampaignDetail extends Component {

    constructor(props) {
        super(props);
        const linkProps = (props.location && props.location.state) || {};
        this.state = {
            campaignData: linkProps.campaignData,
            activeCampaignAnalytics: linkProps.activeCampaignAnalytics
        }
    }

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true,
        });
        const companyInfo = this.props.companyInfo || {};
        const linkProps = (this.props.location && this.props.location.state) || {};
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            templateId: (linkProps.campaignData || {}).formBuilderId,
            fundraisingCampaignId: (linkProps.campaignData || {}).fundraisingCampaignId
        };
        payload.templateId && this.props.fetchNewsLetter(payload, this.props);
        fetchPaginationDataIfNeeded("getTeamMaster", "teamMasterList", this.props, payload);

    }

    componentWillReceiveProps(props) {

        if (props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
            this.setState({
                subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
            });
        }
    }


    render() {
        return (
            <CampaignCompDetails
                {...this.props}
                {...this.state}
                updateState={(data) => {
                    this.setState(data);
                }}
            ></CampaignCompDetails>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        teamMasterList: state.fundraisingReducer.teamMasterList,
        newsLetterTemplate: state.membershipReducer.newsLetterTemplate,
        subdomain: state.common.subdomain,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showModal,
            hideModal,
            pushModalToStack, 
            popModalFromStack,
            getTeamMaster,
            fetchNewsLetter,
            getSubdomain,
            deleteTeamMaster,
            deleteTeamMember
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetail);
