import React, { Fragment } from "react";
import { Form, Col, Row, Tooltip } from "antd";
import { Link } from "react-router-dom";
import "@ant-design/compatible/assets/index.css";
import { injectIntl, FormattedMessage } from "react-intl";
import { Dropdown } from "../../../../general/Dropdown";
import { CustomButton } from "../../../../general/CustomButton";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { CONSTANTS, MODAL_TYPE } from "../../../../../static/constants";
import { showToasterMessage } from "../../../../../utils";
import { getLabelName } from "../../customerInfo";
import * as find from "lodash.find";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import * as filter from "lodash.filter";
import { DocumentUpload } from "../../../../general/DocumentUpload";
import { TextBox } from "../../../../general/TextBox";

const AddUpdateBiddingSupplier = (props) => {
  const { intl, supplierSummary, supplierData, pageNumber, hideModal } = props;
  const updateState = props.updatePopupState;

  const rfqData = props.rfqData || {};
  const oldAdd =
    find(rfqData.boLocationRFQList, {
      locationType: CONSTANTS.BILLING_ADDRESS,
      supplierId: parseInt(supplierData.supplierId),
    }) || {};
  const oldContact = (supplierData.additionalInfoList || [])[0] || {};
  const fileToUpload = (props.fileToUpload || []).concat(
    (supplierData || {}).awsDocumentDetails || []
  );
  // ((supplierData || {}).awsDocumentDetails || []).forEach((ele, index) => {
  //   defaultFileList.push({
  //     uid: ele.awsKeyName,
  //     name: ele.fileName,
  //     status: 'done',
  //     url: `https://s3.amazonaws.com/bo-supplier-purchase-request/${ele.awsKeyName}`
  //   })
  // })
  // const customProps = {
  //   name: 'file',
  //   action: null,
  //   buttonName: 'Upload Document',
  //   onChange: (file) => {
  //     //file.file.originFileObj = file.file.originFileObj || {};
  //     //file.file.originFileObj.name = `${file.file.originFileObj.name} (${bytesToSize(file.file.originFileObj.size)})`;
  //     updateState({
  //       fileToUpload: file
  //     })
  //   }
  // }

  const handleNewAddressAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    if (
      !formData ||
      (modalType === MODAL_TYPE.BILLING_ADDRESS && !formData.supplierId)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please Select Supplier",
      });
    }
    const { pushModalToStack, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.funcToCall = props.fetchSupplierDetailsBySupplierId;
    formData.callback = (data) => {
      if (data) {
        if (data.locationId) {
          const updatedSupplier = props.supplierData;
          (updatedSupplier.boLocationList || []).push(data);
          props.updateState({ selectedSupplier: updatedSupplier });
        }
      }
    };
    formData.funcToCallPayload = {
      relationshipId: (props.companyInfo || {}).relationshipId,
      supplierId: supplierData.supplierId,
    };
    const data = {
      title: getModalTitle(payload, modalType),
      formData,
      hideFooter: true,
      modalData: {
        modalType: modalType,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        //props.popModalFromStack(data);
      },
    };
    pushModalToStack(data);
  };

  const handleNewContactAddition = (props, payload) => {
    const formData = payload.formData;
    const { pushModalToStack, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.funcToCall = props.fetchSupplierDetailsBySupplierId;
    formData.funcToCallPayload = {
      relationshipId: (props.companyInfo || {}).relationshipId,
      supplierId: supplierData.supplierId,
    };
    formData.callback = (data) => {
      if (data.contactId) {
        const updatedSupplier = props.selectedSupplier;
        updatedSupplier.boContactList.push(data);
        props.updateState({ selectedSupplier: updatedSupplier });
      }
    };
    const data = {
      title: <FormattedMessage id="addItem.text.contact" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        //props.popModalFromStack(data);
      },
    };
    pushModalToStack(data);
  };

  const addItemConfirmationModal = (props, text, modalType) => {
    const { pushModalToStack, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="modal.commmon.submit"
          // values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {/* {labelName} ? */}
      </span>
    );

    let addFunc;
    let payload = { text: text, relationshipId: companyInfo.relationshipId };

    switch (modalType) {
      case MODAL_TYPE.RFQ_STATUS: {
        addFunc = props.addRFQStatus;
        payload.statusColor = " new";
        break;
      }
      case MODAL_TYPE.RFQ_PRICE_CODES: {
        addFunc = props.addRFQPriceCodes;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc(payload);
        props.popModalFromStack();
      },
    };
    pushModalToStack(modalData);
  };

  const getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.BILLING_ADDRESS: {
        title = (
          <FormattedMessage
            id="addItem.text.billingAddress"
            defaultMessage=""
          />
        );
        break;
      }
      case MODAL_TYPE.RFQ_STATUS: {
        title = (
          <FormattedMessage id="addItem.text.rfqStatus" defaultMessage="" />
        );
        break;
      }
      case MODAL_TYPE.RFQ_PRICE_CODES: {
        title = (
          <FormattedMessage id="addItem.text.rfqPriceCode" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { pushModalToStack, popModalFromStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
          } else {
            data.formData.submittedOnce = true;
            pushModalToStack(data);
            popModalFromStack();
          }
        },
      };
      pushModalToStack(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { pushModalToStack, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.RFQ_STATUS: {
        deleteFunc = props.deleteRFQStatus;
        break;
      }
      case MODAL_TYPE.RFQ_PRICE_CODES: {
        deleteFunc = props.deleteRFQPriceCodes;
        break;
      }
      case MODAL_TYPE.CONTACT: {
        deleteFunc = props.deleteContact;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc(
          {
            id: payload.id,
            relationshipId: companyInfo.relationshipId,
            callback: (data) => {
              if (data.contactId) {
                const updatedSupplier = props.selectedSupplier;
                let boContatcList = [];
                (updatedSupplier.boContactList || []).forEach((e) => {
                  if (e.contactId !== data.contactId) {
                    boContatcList.push(e);
                  }
                });
                updatedSupplier.boContactList = boContatcList;
                props.updatePopupState({ selectedSupplier: updatedSupplier });
              }
            },
          },
          props
        );
      },
    };
    pushModalToStack(modalData);
  };

  const updateSupplier = (data) => {
    let fileToUpload = props.fileToUpload || [];
    const newAwsDocObj = [];
    data.awsDocumentDetails &&
      data.awsDocumentDetails.forEach((awsFile) => {
        const existFile = find(fileToUpload.fileList, {
          uid: awsFile.awsKeyName,
        });
        if (existFile) {
          newAwsDocObj.push(awsFile);
        }
      });

    data.awsDocumentDetails = newAwsDocObj;

    let formData = new FormData();
    formData.append("supplierData", JSON.stringify(data, true));
    if (fileToUpload && Array.isArray(fileToUpload) && fileToUpload.length) {
      fileToUpload.forEach((fileObj) => {
        const file = new Blob([fileObj.originFileObj], { type: fileObj.type });
        formData.append("files", file, fileObj.name);
      });
    }
    props.resetRfqData();
    props.updateRFQSupplier(formData, props);
    props.updateState({ fileToUpload: [] });
  };

  const updateRFQ = (supplierId, row) => {
    if (props.selectedAddress) {
      let address = props.selectedAddress || {};
      address.isDefault = address.isDefault ? 1 : 0;
      address.supplierId = supplierId;
      //address.rfqMasterId = rfqData.rfqChildMasterId ? 0 : rfqData.rfqMasterId;
      address.rfqMasterId = rfqData.rfqMasterId;
      address.rfqChildMasterId = rfqData.rfqChildMasterId;
      if (oldAdd && oldAdd.locationRFQId) {
        address.locationRFQId = oldAdd.locationRFQId;
      }
      props.resetRfqData();
      props.updateRFQSupplierAddress(address, props);
    }
  };

  const save = (data) => {
    const modalData = {
      modalBody: (
        <FormattedMessage
          id="modal.commmon.submit"
          defaultMessage={``}
          // values={{ value: "this supplier" }}
        />
      ),
      handleSubmit: () => {
        let contact = oldContact || {};
        if (props.selectedContact && props.selectedContact.contactId) {
          contact = { ...oldContact, ...props.selectedContact };
          contact.relationshipId = props.companyInfo.relationshipId;
        }
        if (data.rfqBiddingSuppliersId) {
          updateRFQ(data.supplierId, data);
          let payload = { ...data, rfqChildMasterId: rfqData.rfqChildMasterId };
          payload.priceCode = data.priceCode;
          payload.priceCodeId = data.priceCodeId;
          payload.statusId = data.statusId;
          payload.statusName = data.statusName || CONSTANTS.STATUS_TYPES.OPEN;
          payload.statusColor = data.statusColor;
          payload.comments = data.comments;
          payload.additionalInfoList = [contact];
          updateSupplier(payload, data.documents, props);
        } else {
          updateRFQ(data.supplierId, data);
          let payload = {
            supplierId: data.supplierId,
            relationshipId: props.companyInfo.relationshipId,
            supplierName: data.supplierStoreName,
            rfqMasterId: rfqData.rfqChildMasterId ? 0 : rfqData.rfqMasterId,
            rfqChildMasterId: rfqData.rfqChildMasterId,
          };
          payload.priceCode = data.priceCode;
          payload.priceCodeId = data.priceCodeId;
          payload.statusId = data.statusId;
          payload.statusName = data.statusName || CONSTANTS.STATUS_TYPES.OPEN;
          payload.statusColor = data.statusColor;
          payload.comments = data.comments;
          payload.additionalInfoList = [contact];
          updateSupplier(payload, data.documents, props);
        }

        //cancel(data.key);
        props.hideModal();
      },
    };
    props.pushModalToStack(modalData);
  };
  return (
    <div className="header-action-container">
      <Form
        labelAlign="left"
        style={{
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          overflow: "auto",
        }}
      >
        <Row>
          <Col span={8}>
            <FormattedMessage
              id="supplier.rfq.biddingSuppliers.gridHeader.supplierName"
              defaultMessage=""
            />
          </Col>
          <Col span={16}>
            <Dropdown
              items={supplierSummary[pageNumber || 1] || []}
              disabled={props.supplierData.rfqBiddingSuppliersId}
              valueKeyName="supplierName"
              optionKeyName="supplierId"
              value={props.supplierData.supplierName || undefined}
              placeholder="Select Supplier"
              onSearch={(searchedText) => {
                props.fetchSupplierSummary({
                  searchedText: searchedText,
                  pageNumber: 1,
                  hideSpinner: true,
                  pageSize: 100,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
              }}
              onSelect={(optionValue) => {
                let supplier = (supplierSummary[pageNumber || 1] || []).find(
                  (supplier) => supplier.supplierId === optionValue
                );               
                updateState({
                  supplierData: {
                    ...props.supplierData,
                    ...supplier,
                    supplierName: supplier.supplierName,
                  },
                  selectedSupplier: supplier,

                });
                props.fetchSupplierDetailsBySupplierId({
                  relationshipId: props.companyInfo.relationshipId,
                  supplierId: optionValue,
                });
              }}
            />
            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedOnce || !!props.supplierData.supplierName
                );
              }}
              message="Supplier Required"
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <FormattedMessage
              id="supplier.rfq.biddingSuppliers.gridHeader.address"
              defaultMessage=""
            />
          </Col>
          <Col span={16}>
            <Dropdown
              items={
                filter(props.selectedSupplier.boLocationList || [], (add) => {
                  return add.locationType === CONSTANTS.BILLING_ADDRESS;
                }) || []
              }
              // items={
              //   (props.selectedSupplier.boLocationList || []).find(
              //     (obj) => obj.locationType === CONSTANTS.BILLING_ADDRESS
              //   )
              // }
            //  items={props.selectedAddress || {}}
              valueKeyName="streetAddress1"
              addressLineKey1="streetAddress2"
              addressLineKey2="cityName"
              addressLineKey3="stateName"
              addressLineKey4="zipCode"
              isFavouriteKeyName="isDefault"
              optionClassAddress="custom-address-dropdown"
              optionKeyName="locationId"
              placeholder="Select Address"
              value={              
                (props.selectedAddress || {}).streetAddress1 ||
                oldAdd.streetAddress1
              }
              canAddNew={true}
              canDelete={true}
              deleteClickHandler={(payload) => {
                deleteClickHandler(props, MODAL_TYPE.BILLING_ADDRESS, payload);
              }}
              onAddNewClick={(payload) => {
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false,
                  supplierId: props.supplierData.supplierId,
                  locationType: CONSTANTS.BILLING_ADDRESS,
                };
                handleNewAddressAddition(
                  props,
                  payload,
                  MODAL_TYPE.BILLING_ADDRESS
                );
              }}
              onSelect={(optionValue) => {
                let address =
                  (props.selectedSupplier.boLocationList || []).find(
                    (address) => address.locationId === optionValue
                  ) || {};
                updateState({
                  selectedAddress: address,
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <FormattedMessage
              id="supplier.rfq.biddingSuppliers.gridHeader.contact"
              defaultMessage=""
            />
          </Col>
          <Col span={16}>
            <Dropdown
              items={props.selectedSupplier.boContactList || []}
              valueKeyName="firstName"
              lastNameKey="lastName"
              isFavouriteKeyName="isPrimaryContact"
              placeholder="Select"
              // value={
              //   ((props.selectedContact || {}).firstName || "") +
              //   " " +
              //   ((props.selectedContact || {}).lastName || "")
              // }
              optionKeyName="contactId"
              canAddNew={true}
              canDelete={true}
              deleteClickHandler={(payload) => {
                deleteClickHandler(props, MODAL_TYPE.CONTACT, payload);
              }}
              onAddNewClick={(payload) => {
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false,
                  supplierId: props.supplierData.supplierId,
                };
                if (props.supplierData?.supplierName) {
                  handleNewContactAddition(props, payload, MODAL_TYPE.CONTACT);
                } else {
                  showToasterMessage({
                    messageType: "error",
                    description: "Please Select Supplier",
                  });
                }
              }}
              onSelect={(optionValue) => {
                let contact =
                  (props.selectedSupplier.boContactList || []).find(
                    (contact) => contact.contactId === optionValue
                  ) || {};
                updateState({
                  selectedContact: contact,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div>
              <span className="">
                <FormattedMessage
                  id="supplier.rfq.biddingSuppliers.gridHeader.priceCode"
                  defaultMessage=""
                />
              </span>
              <Tooltip placement="right" title="Price Code">
                <QuestionCircleTwoTone />
              </Tooltip>
            </div>
          </Col>
          <Col span={16}>
            <Dropdown
              items={props.rfqPriceCodes || []}
              valueKeyName="priceCode"
              optionKeyName="rfqBiddingSupplierPriceCodeId"
              value={props.supplierData.priceCode || undefined}
              placeholder="Select Price Code"
              canAddNew={true}
              canDelete={true}
              deleteClickHandler={(payload) => {
                deleteClickHandler(props, MODAL_TYPE.RFQ_PRICE_CODES, payload);
              }}
              onAddNewClick={(payload) => {
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false,
                };
                handleNewItemAddition(
                  props,
                  payload,
                  MODAL_TYPE.RFQ_PRICE_CODES
                );
              }}
              onSelect={(optionValue, option) => {
                updateState({
                  supplierData: {
                    ...props.supplierData,
                    priceCode: option.name,
                    priceCodeId: optionValue,
                  },
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <FormattedMessage id="common.status" defaultMessage="" />
          </Col>
          <Col span={16}>
            <Dropdown
              items={props.rfqStatusList || []}
              valueKeyName="statusName"
              optionKeyName="rfqStatusId"
              value={props.supplierData.statusName || undefined}
              placeholder="Select Status"
              canAddNew={true}
              canDelete={true}
              deleteClickHandler={(payload) => {
                deleteClickHandler(props, MODAL_TYPE.RFQ_STATUS, payload);
              }}
              onAddNewClick={(payload) => {
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false,
                };
                handleNewItemAddition(props, payload, MODAL_TYPE.RFQ_STATUS);
              }}
              onSelect={(optionValue) => {
                let status = (props.rfqStatusList || []).find(
                  (status) => status.rfqStatusId === optionValue
                );
                updateState({
                  supplierData: {
                    ...props.supplierData,
                    statusId: status.rfqStatusId,
                    statusName: status.statusName,
                    statusColor: status.statusColor,
                  },
                });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <FormattedMessage
              id="supplier.rfq.biddingSuppliers.gridHeader.comments"
              defaultMessage=""
            />
          </Col>
          <Col span={16}>
            <TextBox
              value={props.supplierData.comments || undefined}
              maxLength={1000}
              onChange={(e) => {
                updateState({
                  supplierData: {
                    ...props.supplierData,
                    comments: e.target.value,
                  },
                });
              }}
              placeholder={intl.formatMessage({
                id: "modal.customer.payInvoice.remarks.placeholder",
                defaultMessage: "",
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <div>
              <span className="">
                <FormattedMessage
                  id="supplier.rfq.biddingSuppliers.gridHeader.documents"
                  defaultMessage=""
                />
              </span>
              <Tooltip placement="right" title="Document descriptions">
                <QuestionCircleTwoTone />
              </Tooltip>
            </div>
          </Col>
          <Col span={16}>
            <div
              onClick={() => {
                props.pushModalToStack({
                  title: (
                    <Fragment>
                      <div className="ant-modal-title">Upload Documents</div>
                    </Fragment>
                  ),
                  modalBody: (
                    <DocumentUpload
                      {...props}
                      fileList={fileToUpload}
                      onChange={(documentDetails) => {
                        updateState({
                          fileToUpload: documentDetails,
                        });
                        //link.data.documentDetails = documentDetails;
                        //self.gridApi.refreshCells({ force: true });
                      }}
                    />
                  ),
                  width: 600,
                  hideFooter: true,
                });
              }}
            >
              <Link>
                {(props.fileToUpload || []).length
                  ? props.fileToUpload.map((d, i) => {
                      return <div>{d.name || d.fileName}</div>;
                    })
                  : "Upload Document"}
              </Link>
            </div>
            {/* <CustomUploader
                  defaultFileList={defaultFileList}

                  {...customProps} /> */}
          </Col>
        </Row>
      </Form>

      <div className="form-footer" style={{ position: "relative" }}>
        <CustomButton
          intlId="confirmation.cancel"
          defaultMessage=""
          type="default"
          key={0}
          style={{ marginRight: 8 }}
          onClick={hideModal}
          className="back-white"
        />
        <CustomButton
          intlId="confirmation.submit"
          defaultMessage=""
          htmlType="submit"
          key={1}
          onClick={() => {
            props.updateState({ submittedOnce: true });
            if (!props.supplierData.supplierId) {
              return;
            }
            let isSupplierAlreadyAdded = false;
            (props.rfqData || {}).rfqBiddingSuppliersList &&
              props.rfqData.rfqBiddingSuppliersList.forEach((data) => {
                if (
                  !props.isUpdate &&
                  data.supplierId === props.supplierData.supplierId
                ) {
                  isSupplierAlreadyAdded = true;
                }
              });
            if (isSupplierAlreadyAdded) {
              return showToasterMessage({
                messageType: "error",
                description: "Supplier already added",
              });
            }
            save({ ...props.supplierData, ...props.selectedSupplier });
       
          }}
        />
      </div>
    </div>
  );
};

export default injectIntl(AddUpdateBiddingSupplier);
