import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BalanceSheetNotesComponent from '../../../../../../components/modal/modalBody/finance/balancesheet/BalanceSheetNotes';

class BalanceSheetNotes extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        };
      }

    render() {
        return <BalanceSheetNotesComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        allLedgerAccounts: state.ledger.allLedgerAccounts
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheetNotes);