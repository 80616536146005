import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EducationDrawerComp from "../../../../components/drawer/schoolManagement/education";
import { pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';



class EducationDrawer extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return (
      <EducationDrawerComp
        {...this.props}
        {...this.state}
        updateDrawerState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EducationDrawer);
