import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TermAndFeeComp from "../../../components/membership/termAndFee";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import { getFees, getFeeCount, deleteTermAndFee, resetPaginatedData } from "./action";
import { showModal, hideModal } from './../../../actions/commonActions';

class TermAndFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded("getFees", "termFeeList", this.props, payload);
    fetchDataIfNeeded("getFeeCount", "termFeeCount", this.props, payload);
  }

  render() {
    return (
      <TermAndFeeComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></TermAndFeeComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    termFeeList: state.membershipReducer.termFeeList,
    termFeeCount: state.membershipReducer.termFeeCount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFees,
      getFeeCount,
      deleteTermAndFee,
      resetPaginatedData,
      showModal,
      hideModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TermAndFee);
