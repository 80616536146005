import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import config from '../../../config/environmentConfig';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Slider,Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CONSTANTS } from '../../../static/constants';
import 'material-ui-rc-color-picker/assets/index.css';
import {ImageCropper} from '../../general/ImageCropper';
import { base64Toblob, getAWSFileURL } from '../../../utils';

function LogoProps(props) {
    const { loading = false } = props;
    let imgRef = document.getElementById('dummy_'+props.childId) || {};
    const [logoUrl, updateLogoUrl] = useState( imgRef.src || props.logoUrl );
    const [imgWidth, updateWidth] = useState(imgRef.offsetWidth);
    const [imgHeigth, updateHeigth] = useState(imgRef.offsetHeight);
    const loadFile = (event) => {
        var reader = new FileReader();
        reader.onload = function(e){

            props.showModal({
                title: <Fragment>
                    <div className='ant-modal-title'>Crop Image</div>
                </Fragment>,
                //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
                modalBody: <ImageCropper onClick={(e) => {
                }} handleSubmit={(newUrl) => {
                    
                   props.uploadImage({
                       file: base64Toblob(newUrl),
                       bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                       objectKey: props['awsKey_'+props.childId] || '',
                       relationshipId: props.companyInfo?.relationshipId
                   }, function(awsKey){
                    props.updateState({logoUrl: newUrl, ['awsKey_'+props.childId]: awsKey});
                    updateLogoUrl(newUrl);
                    var output = document.getElementById('dummy_'+props.childId);
                    let img = document.createElement('img');
                    //img.src = newUrl;
                    img.src = getAWSFileURL(awsKey, config.BUCKET_NAME.BO_RELATIONSHIP);
                    img.id = 'dummy_'+props.childId;
                    img.style.width = '600px';
                    img.style.height = '480px';
                    //mainCanvasDiv.appendChild(img);
                   
                    output.parentNode.replaceChild(img, output);
                    updateWidth(img.offsetWidth);
                    updateHeigth(img.offsetHeight);
                    document.getElementById(props.childId).style.background = ""
                   // output.src = newUrl;
                   })
                //     var output = document.getElementById('dummy_'+props.childId);
                //     let img = document.createElement('img');
                //     //img.src = newUrl;
                //     img.src = getAWSFileURL('bo-relationship', '1-a9d0319f-a7cb-48f7-a9c5-a72cc30f38d');
                //     img.id = 'dummy_'+props.childId;
                //     img.style.width = '600px';
                //     img.style.height = '480px';
                //     //mainCanvasDiv.appendChild(img);
                   
                //     output.parentNode.replaceChild(img, output);
                //     updateWidth(img.offsetWidth);
                //     updateHeigth(img.offsetHeight);
                //    // output.src = newUrl;
                }}{...props} url={e.target.result} />,
                width: 700,
                hideFooter: true,
                wrapClassName: 'Upload-profile-modal',
                type: CONSTANTS.TYPE_IMAGE,
                multiple: false,
            })
        };
        reader.readAsDataURL(event.file.originFileObj);
    };

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );

    
    return (
        <Fragment>
                    <div>
                        

                        
                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='browse.text' /> <FormattedMessage id='banking.auditTrail.file' />
                        </Col>
                        
                        <Col span={24}>
                            {/* <input type="file" className='choose-file' name="profile_photo" id="fileInput" onChange={loadFile} required/>
                         */}
                   
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            // beforeUpload={loadFile}
                            onChange={loadFile}
                        >
                            {logoUrl ? <img src={logoUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                        <Col span={24} className='link'>Restore to Original</Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='imgAction.text' />
                        </Col>
                        <Col span={24}>
                            <Input onChange={(e) => {
                                document.getElementById('dummy_'+props.childId).click(function() {
                                    window.location='e.target.value';
                                  });
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='altText.text' />
                        </Col>
                        <Col span={24}>
                            <Input value={props.altText || imgRef.alt} onChange={(e) => {
                                document.getElementById('dummy_'+props.childId).alt = e.target.value;
                                props.updateState({altText : e.target.value})
                            }}></Input>
                        </Col>
                    </Row>
                   

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='imageSize.text' />
                        </Col>
                        <Col span={24}>
                            <Button title='Small' onClick={() => {
                                 imgRef.style.float =  'unset';
                                 imgRef.style.margin = "15px auto 0px";
                                 imgRef.style.width = "200px";
                                 imgRef.style.height = "200px";
                            imgRef.style.lineHeight = "200px";
                            updateWidth(imgRef.offsetWidth);
                            updateHeigth(imgRef.offsetHeight);
                            }}style={{borderRadius:'4px 0px 0px 4px'}}>
                                <span className='cirty'>
                                S </span></Button>
                            <Button title='Medium' onClick={() => {
                                 imgRef.style.float =  'unset';
                                 imgRef.style.margin = "15px auto 0px";
                                 imgRef.style.width = "450px";
                                 imgRef.style.height = "400px";
                                 imgRef.style.lineHeight = "400px";
                            updateWidth(imgRef.offsetWidth);
                            updateHeigth(imgRef.offsetHeight);
                            }} style={{borderRadius:'0px 0px 0px 0px '}}>
                                <span className='cirty'>M </span></Button>
                            <Button title='Large' onClick={() => {
                                 imgRef.style.float =  'unset';
                                 imgRef.style.margin = "15px auto 0px";
                                 imgRef.style.width = "680px";
                                 imgRef.style.height = "500px";
                                 imgRef.style.lineHeight = "500px";
                                 updateWidth(imgRef.offsetWidth);
                                 updateHeigth(imgRef.offsetHeight);
                            }} style={{borderRadius:'0px 4px 4px 0px '}}><span className='cirty'> L</span></Button>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                    <Col span={5}> <FormattedMessage id='package.packageDetail.width' /></Col>
                        <Col span={19}>
                        {/* <Slider defaultValue={15} tooltipVisible /> */}
                        <Slider min={1} value={imgWidth} max={680} onChange={(value) => {
                            updateWidth(value);
                                 imgRef.style.width = value+"px"
                            }} />
                        </Col>
                        </Row>


                        <Row className="but-buttons">
                        <Col span={5}><FormattedMessage id='buttonHeight.height' /></Col>
                        <Col span={19}>
                        
                        {/* <Slider defaultValue={15} tooltipVisible /> */}
                        <Slider value={imgHeigth} min={10} max={500} onChange={(value) => {
                                 imgRef.style.lineHeight = value+"px"
                                 imgRef.style.height = value+"px"
                                 updateHeigth(value);
                            }}  />
                        </Col>

                        
                        </Row>
                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='buttonCorner.text' />
                        </Col>
                        <Col span={24} >
                            <Button title='Square' onClick={() => {
                                 
                                 imgRef.style.borderRadius = "0px"
                            }} style={{borderRadius:'4px 0px 0px 4px', position:'relative'}}> 
                            
                            <div className="left-roud"></div>     
                            </Button>
                            <Button title='Round' onClick={() => {
                                 
                                 imgRef.style.borderRadius = "4px"
                            }} style={{borderRadius:'0px', position:'relative'}}>      
                             <div className="mr-roud"></div>
                            </Button>
                            <Button  title='Extra Round' onClick={() => {
                                 
                                 imgRef.style.borderRadius = "20px"
                            }} style={{borderRadius:'0px 4px 4px 0px', position:'relative'}}>      
                              <div className="right-roud"></div>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='buttonCorners.text' /></Col>
                        <Col span={19}>
                        <Slider defaultValue={10} min={0} max={50} onChange={(value) => {
                                 
                                 imgRef.style.borderRadius = value+"%"
                            }}  />
                        </Col>
                        </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='imgPosition.text' />
                        </Col>
                        <Col span={24} >
                            <Button title='Left' onClick={() => {
                                 
                                 imgRef.style.marginLeft = "10px";
                                 imgRef.style.float = 'left';
                                 imgRef.style.margin = "15px auto 0px";
                            }} style={{borderRadius:'4px 0px 0px 4px', position:'relative'}}>      
                            
                            <div className="leftd-roud1"></div> 
                            </Button>
                            <Button title='Middle' onClick={() => {
                                 
                                 imgRef.style.float =  'unset';
                                 imgRef.style.margin = "15px auto 0px";
                            }} style={{borderRadius:' 0px ', position:'relative'}} >      
                             <div className="mrd-roud1"></div>
                            </Button>
                            <Button title='Right' onClick={() => {
                                 
                                 imgRef.style.marginLeft = "10px";
                                 imgRef.style.float = 'right';
                            }} style={{borderRadius:'0px 4px 4px 0px', position:'relative'}} >      
                             <div className="rigt-roud1"></div>
                            </Button>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                    <Col span={8}><FormattedMessage id='imgPosition.text' /></Col>
                        <Col span={16}>
                            <Slider max={680} onChange={(value) => {
                                 
                                 imgRef.style.float = 'left';
                                 imgRef.style.marginLeft = (imgWidth+value) > 680 ? (680-imgWidth)+"px" : value+"px";
                            }} />
                        </Col>
                        </Row>
                 


                </div>
        </Fragment>
    );
}


export default injectIntl(LogoProps);
