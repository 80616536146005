import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import "./price.scss";
import erpLogo from "../../assets/images/erpIcon1.svg";
import commerceLogo from "../../assets/images/commerceIcon1.svg";
import educationLogo from "../../assets/images/educationIcon1.svg";
import crmLogo from "../../assets/images/crmIcon1.svg";
import hrmsLogo from "../../assets/images/hrmsIcon1.svg";
import {Checkbox, Input, Button} from "antd";
import {TextBox} from "../general/TextBox";

function PricingSetupModal(props){

  const {loginData, productName, alpideProductId, clientUserAccountId} = props;
  const {defaultCompany} = loginData || {};
  const [clientData, setClientData] = useState(defaultCompany || {});

  const [checked, setChecked] = useState(true);
  const history = useHistory();

  function handleClick() {

    const payload = {
      ...clientData,
      industryName: productName,
      alpideProductId: alpideProductId,
      clientUserAccountId: clientUserAccountId
    }

    props.completeInitialSetup(payload, props);
    // history.push("/admin/dashboard");
    // props.popModalFromStack()
  }

  const toggleChecked = () => {
    setChecked(!checked);
  };

    const data = {
      0:
      {
        imgSource:erpLogo,
        imageTag:"Cloud ERP"
      },
      1:
      {
        imgSource:commerceLogo,
        imageTag:"Commerce"

      },
      2:{
        imgSource:educationLogo,
        imageTag:"Education ERP"

      },
      3:{
        imgSource:crmLogo,
        imageTag:"CRM"

      },
      4:{
        imgSource:hrmsLogo,
        imageTag:"HRMS"

      }
    }
const datatoUse = data[props.index];

function getIconFromName(name){
  switch(name){
      case 'Cloud ERP':
          return erpLogo;
      case 'E-Commerce':
          return commerceLogo;
      case 'Education ERP':
          return educationLogo;
      case 'CRM':
          return crmLogo;
      case 'HRMS':
          return hrmsLogo;
      default: 
          return erpLogo;
  }
}


    return(
        <>
          <div className="chooseProductPopup">
            <div className="chooseProductHeading">
              <img src={getIconFromName(productName)} alt='img' />
              <h2>{productName}</h2>
            </div>
            <div className="chooseProductText">
              <p>
                Hello <span>{clientData?.relationshipEmployees?.fullName || 'User'}</span>, welcome aboard Alpide!
              </p>
              <p>
                Please provide the following information to get started.
              </p>
            </div>
            <div className="formArea">
              <TextBox 
                placeholder="Enter Your Company Name*"
                value={clientData.storeName}
                onChange={(e)=>{
                  setClientData({...clientData, storeName: e.target.value})
                }}
              />
            </div>
            <div className="dataPart">
              <div className="data">
                Your timezone is <span>{clientData.timezone}</span>
              </div>
              <div className="data">
              Your language locale is <span>English - US</span>
              </div>
              <div className="data">
              Your data will be located in <span>{clientData.countryName}</span>
              </div>
              <div className="data">
              Your currency is <span>{clientData.currencyCode}</span>
              </div>
            </div>
            {/* <div className="selectionBox">
              <Checkbox onClick={toggleChecked} checked={checked} /> 
              <label>Load Sample Data</label>
            </div> */}
            <div className="buttonArea">
              <Button type="primary" onClick={handleClick}>Get Started</Button>
            </div>
          </div>
        </>
    )
}
export default PricingSetupModal