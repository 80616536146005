import React from "react";
import { Form } from '@ant-design/compatible';
import { injectIntl, FormattedMessage } from 'react-intl';

import './index.scss';
import { Row, Col, Radio, Checkbox, Button } from "antd";
import { TextBox } from "../../general/TextBox";
import { CustomAgGrid } from "../../general/CustomAgGrid";
import TextArea from "antd/lib/input/TextArea";




const Register = (props) => {
    const { intl } = props;
    const columnDefs = [
        {
            headerComponentFramework: () => {
                return "Grade";
            },
            field: "grade",
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return "DOB";
            },
            field: "dob",
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return "First Name";
            },
            field: "firstName",
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return "Last Name";
            },
            field: "lastName",
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return "Work Phone";
            },
            field: "workPhone",
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return "Allergies";
            },
            field: "allergies",
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return "Gender";
            },
            field: "gender",
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return "Action";
            },
            field: "action",
            resizable: true,
            cellRendererFramework: (params) => <div>
                <div>
                    <div>
                        {params.node.data.isToEdit &&
                            <div className="table-cell-action">
                                {<span className='cursor-pointer' onClick={() => {
                                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                    params.api.stopEditing(true);

                                    this.gridApi.refreshCells({ force: true });

                                }} >
                                    <i className="fa fa-save" />
                                </span>}

                                <span className='cursor-pointer' onClick={() => {
                                    params.api.stopEditing(true);
                                    this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
                                    this.gridApi.refreshCells({ force: true });
                                }} >
                                    <i className="fa fa-ban" />
                                </span>
                            </div>
                        }

                        {!params.node.data.isToEdit &&
                            <div className="table-cell-action" >
                                {<span className='cursor-pointer' onClick={() => {
                                    // this.gridApi.forEachNode((rowNode, index) => {
                                    //     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
                                    // });
                                    // this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
                                    // this.gridApi.refreshCells({ columns: ["action"], force: true });
                                    // var startEditingParams = {
                                    //     rowIndex: params.node.rowIndex,
                                    //     colKey: params.column.getId(),
                                    // };
                                    // params.api.startEditingCell(startEditingParams);

                                }}>
                                    <i className="fa fa-edit" />
                                </span>}
                                {<span className='cursor-pointer' onClick={() => {
                                    //  const modalData = {
                                    //     modalBody: <FormattedMessage
                                    //         id='customer.address.delete.confirmation'
                                    //         defaultMessage=''
                                    //     />,
                                    //     handleSubmit: () => {
                                    //         deleteAddress(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data, this.state.locationType);
                                    //     }
                                    // };
                                    // this.props.pushModalToStack(modalData);

                                }}>
                                    <i className="fa fa-remove" />
                                </span>}
                            </div>}
                    </div>
                </div>
            </div>
        }
    ]
    return (
        <div className='main-container'>
            <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id='register.fatherFirstName' />}>
                            <TextBox
                                placeholder={intl.formatMessage(
                                    { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                            //value={contact.lastName}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id='register.fatherLastName' />}>
                            <TextBox
                                placeholder={intl.formatMessage(
                                    { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                            //value={contact.lastName}

                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Mother's Firstname">
                            <TextBox
                                placeholder={intl.formatMessage(
                                    { id: 'contact.firstName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                            //value={contact.lastName}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={"Mother's Lastname"}>
                            <TextBox
                                placeholder={intl.formatMessage(
                                    { id: 'contact.lastName.placeholder', defaultMessage: '' }
                                )}
                                type='text'
                            //value={contact.lastName}

                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Father Mobile">
                            <TextBox
                                placeholder="Enter mobile number"
                                type='text'
                            //value={contact.lastName}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Father Email'>
                            <TextBox
                                placeholder='Enter email'
                                type='text'
                            //value={contact.lastName}

                            />
                        </Form.Item>
                    </Col>
                </Row>



                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Mother Mobile">
                            <TextBox
                                placeholder="Enter mobile number"
                                type='text'
                            //value={contact.lastName}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label='Mother Email'>
                            <TextBox
                                placeholder='Enter email'
                                type='text'
                            //value={contact.lastName}

                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={24}>
                        <div className='grid-container'>
                            <CustomAgGrid
                                columnDefs={columnDefs}
                                rowData={[
                                    {
                                        grade: "A+",
                                        dob: "10 July 2010",
                                        firstName: "Andrew",
                                        lastName: "Dufrain",
                                        workPhone: "234567",
                                        allergies: "NA",
                                        Gender: "Male"
                                    },
                                    {
                                        grade: "A",
                                        dob: "22 July 2010",
                                        firstName: "Allen",
                                        lastName: "Leigh",
                                        workPhone: "234567",
                                        allergies: "NA",
                                        Gender: "Male"
                                    }
                                ]}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onGridSizeChanged={(params) => {
                                    params.api.sizeColumnsToFit();

                                }}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}

                                onGridReady={(params) => {
                                    params.api.sizeColumnsToFit();

                                }}
                            >
                            </CustomAgGrid>
                        </div>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item className='label-emergency' label="Emergency contact Name">
                            <TextBox
                                placeholder="Enter name"
                                type='text'
                            //value={contact.lastName}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item className='label-emergency' label='Emergency Phone Number'>
                            <TextBox
                                placeholder='Enter Number'
                                type='text'
                            //value={contact.lastName}
                            />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item className='label-emergency' label="Which phone number can be used for whats app group">
                            <Radio><span>Father</span></Radio><br />
                            <Radio><span>Mother</span></Radio><br />
                            <Radio><span>Both</span></Radio>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item className='label-emergency' label='Do you have MCC family Membership?'>
                            <Radio><span>Y</span></Radio><br />
                            <Radio><span>N</span></Radio><br />
                        </Form.Item>
                    </Col>
                </Row>



                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item className='label-emergency' label="Sunday School- Sunday 11:30 am- 1:00 pm, Thursday 5:00 PM- 6:00 PM">
                            <Radio><span>Non member Price- $40/student</span></Radio><br />
                            <Radio><span>Family Membership Price -$25/student</span></Radio><br />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className='label-emergency' label="Sunday School- Sunday 11:30 am- 1:00 pm, Thursday 5:00 PM- 6:00 PM">
                            <Radio><span>Non member Price- $40/student</span></Radio><br />
                            <Radio><span>Family Membership Price -$25/student</span></Radio><br />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className='label-emergency' label="Sunday School- Sunday 11:30 am- 1:00 pm, Thursday 5:00 PM- 6:00 PM">
                            <Radio><span>Non member Price- $40/student</span></Radio><br />
                            <Radio><span>Family Membership Price -$25/student</span></Radio><br />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item className='label-emergency' label="LIABILTY RELEASE AND PARENTAL CONSENT">
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={16}>
                    <Col span={1}>
                    <Checkbox></Checkbox>
                    </Col>
                    <Col span={23}>
                    <TextArea></TextArea>
                    </Col>
                   
                </Row>


                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item className='label-emergency' label="PHOTO RELEASE">
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={16}>
                    <Col span={1}>
                    <Checkbox></Checkbox>
                    </Col>
                    <Col span={23}>
                    <TextArea></TextArea>
                    </Col>
                   
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item className='label-emergency' label="PHOTO RELEASE">
                        </Form.Item>
                    </Col>

                </Row>

                <Row gutter={16}>
                    <Col span={11}>
                   
                    </Col>
                    <Col span={2}>
                    <Button className="primary">Submit</Button>
                    </Col>
                   
                </Row>


            </Form>
        </div>
    )
};

export default injectIntl(Register);
