import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import ParentComp from "../../../components/schoolManagement/parent";
import { fetchPaginationDataIfNeeded } from "../../../utils";
import {
  getRegisteredMemberByFormId,
  getRegisteredMemberCount,
  resetPaginatedData,
  getRegisteredMemberByClass,
  getRegisteredMemberCountByClass,
  getRegisteredMemberByCourse,
  getRegisteredMemberCountByCourse,
  deleteAssignedClass,
  updateOrgRegistrationData,
  exportStudentExcelData,
  deleteStudent,
  generateInvoice,
  deleteOrgRegistration,
  getAccessToken,
  fetchParentsListByFilter,
  getParentslistCountByFilter
} from "./action";
import {
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
} from "./../../../actions/commonActions";
import { getAllStudentForm } from "./../../Student/studentFormList/action";
import { getSchoolCourse } from "./../../schoolManagement/course/action";
import { getClass } from "./../../schoolManagement/class/action";
// import EmailComponent from '../../../containers/drawer/recurringInvoiceEmail';
import * as find from "lodash.find";
import { sendResetMail } from "../../Forgot/action";
import { getSubdomain } from "../../modal/modalBody/settings/CompanyInfo/action";
import { fetchCustomers } from "../familyListing/action";
import ParentComp from "../../../components/schoolManagement/parent";
import EmailComponent from "../../../containers/drawer/recurringInvoiceEmail";
import ParentListFilterComp from "../../../components/schoolManagement/parent/ParentListFilter";


class Parent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
      isStudentLoaded: false,
    };
  }

  componentDidMount() {
    let self = this;
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 200,
      relationshipEmployeeId: (this.props.userInfo || {})
        .relationshipEmployeeId,
    };
    fetchPaginationDataIfNeeded(
      "getAllStudentForm",
      "studentFormList",
      this.props,
      payload
    );
    this.props.getSubdomain(payload, function (stat) {
      self.setState({ isSubDomainLoaded: stat });
    });
    this.props.fetchCustomers(payload);
    // fetchPaginationDataIfNeeded("getRegisteredMemberByFormId", "teacherList", this.props, payload);
    // fetchDataIfNeeded("getRegisteredMemberCount", "teacherCount", this.props, payload);
    // this.props.getSchoolCourse({ relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 1000 });
    // this.props.getClass({ relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 1000 });
  }

  componentWillReceiveProps(props) {
    if (props.studentFormList && props.studentFormList.length) {
      let selectedForm;
      if (this.props.defaultSchoolForm) {
        selectedForm =
          find(props.studentFormList, { registrationFormSettingId: 0 }) || {};
      }
      if (!this.state.isStudentLoaded) {
        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = {
          companyInfo,
          relationshipId: companyInfo.relationshipId,
          relationshipEmployeeId: userInfo.relationshipEmployeeId,
          pageNumber: 1,
          pageSize: 200,
          formId: 0,
          // formId:
          //   this.props.defaultSchoolForm ||
          //   props.studentFormList[0].registrationFormSettingId,
        };

        this.props.getRegisteredMemberByFormId(payload);
        this.props.getRegisteredMemberCount(payload);

        this.setState({
          isStudentLoaded: true,
          selectedForm: selectedForm || props.studentFormList[0],
        });
      }
    }
    if (
      props.subdomain &&
      props.subdomain.alpideSubdomainId &&
      !this.state.subdomainName
    ) {
      this.setState({
        subdomainName: props.subdomain.subdomainName
          ? "https://" + props.subdomain.subdomainName
          : window.location.origin,
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.emailDetailDrawerVisible && (
          <EmailComponent
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
        {this.props.userInfo.jobRoleName !== "Teacher" ? (
          <ParentComp
            {...this.props}
            {...this.state}
            updateState={(data) => {
              this.setState(data);
            }}
          ></ParentComp>
        ) : (
          <div style={{ textAlign: "center", marginTop: "50px" }}>
            <h2>You dont have permisison</h2>
          </div>
        )}

        {this.state.parentListFilterDrawerVisible && (
          <ParentListFilterComp
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    parentList: state.school.parentList,
    parentCount: state.school.parentCount,
    studentFormList: state.membershipReducer.studentFormList,
    courseList: state.school.courseList,
    classList: state.school.classList,
    defaultSchoolForm: state.common.defaultSchoolForm,
    subdomain: state.common.subdomain,
    familyListing: state.school.familyList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRegisteredMemberByFormId,
      getRegisteredMemberCount,
      resetPaginatedData,
      getAllStudentForm,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getRegisteredMemberByClass,
      getRegisteredMemberCountByClass,
      getRegisteredMemberByCourse,
      getRegisteredMemberCountByCourse,
      getClass,
      getSchoolCourse,
      deleteAssignedClass,
      updateOrgRegistrationData,
      exportStudentExcelData,
      deleteStudent,
      generateInvoice,
      deleteOrgRegistration,
      sendResetMail,
      getAccessToken,
      getSubdomain,
      fetchCustomers,
      fetchParentsListByFilter,
      getParentslistCountByFilter
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Parent);
