import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Tooltip } from 'antd';
import { CustomButton } from '../../../../general/CustomButton';
import { TextBox } from '../../../../general/TextBox';
import * as find from 'lodash.find';
import { Dropdown } from '../../../../general/Dropdown';
import { showToasterMessage, getCurrentDateForBackend } from '../../../../../utils';
import { MODAL_TYPE } from '../../../../../static/constants';

function AccountSetupCompo(props) {

  const closeDrawer = () => {
    props.updateHeaderState({
      accSetupDrawerVisible: false
    })
  }

  
 const handleNewProjectAddition = (props, payload) => {
  const formData = payload.formData;
  const { showModal, companyInfo } = props;
  formData.relationshipId = companyInfo.relationshipId;
  const data = {
      title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
      formData,
      hideFooter: true,
      modalData: {
          modalType: MODAL_TYPE.PROJECT,
          data: payload,

      },
      handleSubmit: (formData = {}) => {
          data.formData.submittedOnce = true;
          props.hideModal(data);
      }
  };
  showModal(data);
}

  const handleSubmit = (props) => {
    if (!props.displayName) {
      return showToasterMessage({
        messageType: 'error', description: props.intl.formatMessage({ id: 'account.displayName.validationMessage' })
      });
    }
    if (!props.chartOfAccountDetailsId) {
      return showToasterMessage({
        messageType: 'error', description: props.intl.formatMessage({ id: 'drawer.inventory.finance.salesLedgerAccount.placeholder' })
      });
    }
    // if (!props.projectMasterId) {
    //   return showToasterMessage({
    //     messageType: 'error', description: props.intl.formatMessage({ id: 'project.name.require' })
    //   });
    // }
    if (!props.alpidePaymentGatewayId) {
      return showToasterMessage({
        messageType: 'error', description: props.intl.formatMessage({ id: 'paymentGateway.notAvailable.message' })
      });
    }
    // if (!props.campaignId) {
    //   return showToasterMessage({
    //     messageType: 'error', description: props.intl.formatMessage({ id: 'fundraisingCampaign.required' })
    //   });
    // }
    const modalData = {
      modalBody: props.intl.formatMessage({ id: 'save.confirmation' }),
      handleSubmit: () => {
        let payload = {
          displayName: props.displayName,
          relationshipId: props.relationshipId,
          ledgerAccountId: props.chartOfAccountDetailsId,
          projectMasterId: props.projectMasterId,
          alpidePaymentGatewayId: props.alpidePaymentGatewayId,
          createdByEmpId: props.userInfo && props.userInfo.relationshipEmployeeId,
          source: props.source,
          message2DonateConfigId: props.accountConfig?.message2DonateConfigId || 0,
          version: props.accountConfig?.version || 0,
          dateCreated: props.accountConfig?.dateCreated ? getCurrentDateForBackend(props.accountConfig?.dateCreated): null,
          alpideFormBuilderId: props.formBuilderId,
          fundraisingCampaignId: props.campaignId,
          cellPhone: props.cellPhone
        }
        props.saveOrUpdateSetup(payload, props);
        props.popModalFromStack();
      }
    };
    props.pushModalToStack(modalData);
  };

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage id='phoneSetup.text' />
            {/* {props.accountType == 'textSMS' ? <div className="top-box-icon1"> <i class="fa fa-phone" ></i></div> : ''}
            {props.accountType == 'whatsappSMS' ? <div className="top-box-icon1"> <img src={WhatupLogo} alt="" /></div> : ''} */}
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-supplier-drawer"
        visible={props.accSetupDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
      >
        <Form layout="vertical" hideRequiredMark>

          {/* <Row gutter={16}>
            
            <Col className="text-right" span={5}>
              <label>
                <FormattedMessage id='communication.account.brodcastSMS.drawer.form.from' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={17}>
              <TextBox
                // minLength={15}
                // maxLength={45}
                type='text'
                // className={(props.submittedOnce && !props.name) ? 'input-text-error' : ''}
                value={subAccount.phoneNumber}
                disabled={true}
                placeholder={props.intl.formatMessage({ id: 'communication.account.brodcastSMS.drawer.form.from', defaultMessage: '' })}
              />

            </Col>
          </Row> */}
           <Row gutter={[16, 8]}>
            <Col span={12}>
              <Tooltip placement="bottom" title={<FormattedMessage id='phoneSetup.displayName.helpMessage' />}>
                <span className="tooltip-title">
                  <FormattedMessage id='displayName.text' /><span className="required">*</span>
                  <Tooltip placement="top" title={props.intl.formatMessage({ id: 'account.displayName.helpMessage' })} trigger="click">
                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                  </Tooltip>
                </span>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip placement="bottom" title={<FormattedMessage id='phoneSetup.project.helpMessage' />}>
                <span className="tooltip-title"> <FormattedMessage id='project.label' /></span>
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12} >
              <TextBox
                maxLength={20}
                type='text'
                value={props.displayName}
                onChange={(e) => {
                  props.updateState({
                    displayName: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'displayName.text' })}
              />
            </Col>
            <Col span={12} >
              <Dropdown
                items={props.projectList}
                valueKeyName='projectName'
                optionKeyName='projectMasterId'
                value={props.projectMasterId}
                placeholder={props.intl.formatMessage({ id: 'project.label' })}
                onSelect={(optionValue, option) => {
                  let selectedProject = find(props.projectList || [], { projectMasterId: option.value }) || {};
                  props.updateState({
                    selectedProject: selectedProject,
                    projectMasterId: option.value
                  })
                }}
                canAddNew={true}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    projectName: payload.projectName,
                  }
                  handleNewProjectAddition(props, payload, MODAL_TYPE.PROJECT)
                }}
                allowClear={true}
                onClear={ () => {
                  props.updateState({
                    selectedProject: undefined,
                    projectMasterId: undefined
                  })
                }}
              />
            </Col>
          </Row>

          {/* <Row className="but-buttons newregis" >
            <Col span={4}> </Col>
            <Col span={8} style={{ paddingTop: '4px' }}>
              <Tooltip placement="bottom" title={<FormattedMessage id='phoneSetup.project.helpMessage' />}>
                <span className="tooltip-title"> <FormattedMessage id='signup.phoneNumber.text' /></span>
              </Tooltip>
            </Col>
            <Col span={8} className="input-currency-control ltiyst">
              <span className="currency-tag" style={{left:'1px'  }}>
                {props.companyInfo.countryCallingCode ?  '+' + props.companyInfo.countryCallingCode : " "}
              </span>

              <TextBox
                value={props.cellPhone}
                maxLength={10}
                onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                        props.updateState({
                          cellPhone: e.target.value,
                        });
                    }
                }}
                placeholder={props.intl.formatMessage({ id: 'signup.phoneNumber.text', defaultMessage: '' })}
              />
            </Col>
          </Row> */}

            <Row gutter={[16, 8]}>
              <Col span={12}>
                <Tooltip placement="bottom" title={<FormattedMessage id='phoneSetup.ledgerAccount.helpMessage' />}>
                  <span className="tooltip-title">
                    <FormattedMessage id='header.menuItem.ledgerAccount.label' /><span className="required">*</span>
                  </span>
                </Tooltip>
              </Col>
              <Col span={12}>
                <Tooltip placement="bottom" title={<FormattedMessage id='phoneSetup.paymentGateway.helpMessage' />}>
                  <span className="tooltip-title">
                    <FormattedMessage id='paymentGateway.available' /><span className="required">*</span>
                  </span>
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12} >
                <Dropdown
                  items={props.allLedgerAccounts}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={props.chartOfAccountDetailsId}
                  placeholder={props.intl.formatMessage({ id: 'header.menuItem.ledgerAccount.label' })}
                  onSelect={(optionValue, option) => {
                    let selectedLedger = find(props.allLedgerAccounts || [], { chartOfAccountDetailsId: option.value }) || {};
                        props.updateState({
                            selectedLedger: selectedLedger,
                            chartOfAccountDetailsId: option.value
                        });
                      }}
                />
              </Col>
              <Col span={12} >
                <Dropdown
                    items={props.availablePaymentGateways}
                    valueKeyName='userDefinedGatewayName'
                    optionKeyName='alpidePaymentGatewayId'
                    value={props.alpidePaymentGatewayId}
                    placeholder={props.intl.formatMessage({ id: 'paymentGateway.available' })}
                    onSelect={(optionValue, option) => {
                        let selectedGateway = find(props.availablePaymentGateways || [], { alpidePaymentGatewayId: option.value }) || {};
                        props.updateState({
                            selectedGateway: selectedGateway,
                            alpidePaymentGatewayId: option.value
                        });
                    }}
                />
              </Col>
            </Row>

          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Tooltip placement="bottom" title={<FormattedMessage id='phoneSetup.fundraisingCampaign.helpMessage' />}>
                <span className="tooltip-title">
                <FormattedMessage id='fundraising.campaign.listing.header.campaign' />
                </span>
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12} >
              <Dropdown
               // className={(props.submittedOnce && !props.campaignId) ? 'input-text-error' : ''}
                items={props.allCampaignList}
                valueKeyName='campaignName'
                optionKeyName='fundraisingCampaignId'
                value={props.campaignId}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'fundraising.pledge.addDrawer.field.fundraisingCampaign',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    let selectedCampaign = find(props.allCampaignList|| [], { fundraisingCampaignId: option.value }) || {};
                    props.updateState({
                      campaignId: option.value,
                      formBuilderId: selectedCampaign.formBuilderId
                    })
                  }
                }
                allowClear={true}
                onClear={ () => {
                  props.updateState({
                    campaignId: undefined,
                    formBuilderId: undefined
                  })
                }}
              />
            </Col>
          </Row>
        </Form>

        <div className='form-footer'>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='button.save.label'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </div>
      </Drawer>
    </Fragment>
  );

}

export default injectIntl(AccountSetupCompo);
