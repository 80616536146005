import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DeligateApproverCompo from '../../../../../components/modal/modalBody/common/DeligateApprover';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchAllEmployees } from '../../../../../actions/commonActions';
import { delegateApprover } from './action';

class PriceListContainer extends Component {
  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId
    }
    this.props.fetchAllEmployees(payload);
  }
  render() {
    return (
      <DeligateApproverCompo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    allEmployee : state.common.employees,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllEmployees,
  delegateApprover,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceListContainer);
