import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SessionTimeoutComponent from '../../../../../components/modal/modalBody/common/SessionTimeout'

class SessionTimeoutContainer extends Component {
 

  render() {
    return (
      <SessionTimeoutComponent {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {    
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SessionTimeoutContainer);
