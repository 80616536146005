import { Button, Input, Radio } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {getAWSFileURL} from '../../../../utils';
import  config  from '../../../../config/environmentConfig';
import ImagePlaceholder from '../../../../assets/images/img-placeholder-alpide.jpg' 
const { Search } = Input;


const CategoryModel = (props) => {
    const [searchText, setSearchText] = useState("");

    function selectChangeHandler(e) {
        const selectedCategory = props.productCategories.find(
          (category) => category.inventoryItemCategoryId === e.target.value
        );
        props.updateState({
          parentName: selectedCategory?.categoryName,
          parentId: selectedCategory?.inventoryItemCategoryId,
          selectedCategory,
        });
      }
    
      const handleCategorySelect = () => {
        props.popModalFromStack();
      };
    
      const getsubcategories = (subcategories, depth = 1) => {
        const indentStyle = {
          paddingLeft: `${depth * 15}px`,
        };
        return subcategories.map((childSubcategories, i) => (
          <>

            <div
              key={childSubcategories.inventoryItemCategoryId + i}
              className="radio-button-Wrapper"
              style={indentStyle}
            >
              <Radio value={childSubcategories.inventoryItemCategoryId}>
                <div className="parent-category-sub-wrapper">
                  <div className="category-img">
                    {childSubcategories.awsKeyName ? 
                    <img
                      src={getAWSFileURL(
                        (childSubcategories || {}).awsKeyName,
                        config.BUCKET_NAME.BO_INVENTORY
                      )}
                      alt="U"
                    /> : 
                     <img style={{ height: "30px" }}  src={ImagePlaceholder} alt="U" />}
                  </div>
                  <div>
                    <h2>{childSubcategories.categoryName}</h2>
                    <p>{childSubcategories.totalProducts}</p>
                  </div>
                </div>
              </Radio>
            </div>
            {childSubcategories.subcategories &&
            childSubcategories.subcategories.length
              ? getsubcategories(childSubcategories.subcategories, depth + 1)
              : ""}
          </>
        ));
      };

  return (
    <>
    <div className="cat-search-wrapper">
    <Search
      placeholder="Search categories or subcategories..."
      value={props.searchCategories}
      onChange={(e) => {
         setSearchText(e.target.value);         
      }}  
    />
    </div>
    <div className="parent-category-list">
      <Radio.Group
        onChange={selectChangeHandler}
        // value={props.selectedCategory?.inventoryItemCategoryId}
        width="100%"
      >
          {(searchText ? props.productCategories.filter((category) => {
        return category.categoryName?.toLowerCase().includes(searchText?.toLowerCase());
      })
      :props.productCategories)
          .filter((rowData) => rowData.parentId === 0)
          .filter(
            (category) =>
              category?.inventoryItemCategoryId !==
              props?.categoryData?.inventoryItemCategoryId
          )
          .map((category) => (
            <>
              <div
                key={category.inventoryItemCategoryId}
                className="radio-button-Wrapper"
              >
                <Radio
                  key={category.categoryName}
                  value={category.inventoryItemCategoryId}
                >
                  <div className="parent-category-sub-wrapper">
                    <div className="category-img">
                      {category.awsKeyName ? 
                      <img
                        src={getAWSFileURL(
                          (category || {}).awsKeyName,
                          config.BUCKET_NAME.BO_INVENTORY
                        )}
                        alt="U"
                      /> : 
                      <img style={{ height: "30px" }} src={ImagePlaceholder} alt="U" />}
                    </div>
                    <div className="parent-category-details">
                      <h2>{category.categoryName}</h2>
                      <p>{`${category.totalProducts} products`}</p>
                    </div>
                  </div>
                </Radio>
              </div>
              {category.subcategories && category.subcategories.length
                ? getsubcategories(category.subcategories)
                : ""}
            </>
          ))}
      </Radio.Group>
      <div className="modal-footer">
        <div className='customModal-button-group'>
      {/* <div className="left-fItems"> */}
        <Button
          className="grey-button"
          onClick={() => {
            props.popModalFromStack();

          }}
        >
          <FormattedMessage id="button.cancel.label" defaultMessage="" />
        </Button>
        {/* </div>
        <div className="right-fItems"> */}
        <Button
          type="primary"
          onClick={() => {
            handleCategorySelect();
          }}
        >
          <FormattedMessage id="button.save.label" defaultMessage="" />
        </Button>
        </div>
        </div>
      {/* </div> */}
    </div>
  </>
  )
}

export default CategoryModel