import React from "react";
import { Drawer, Row, Col, DatePicker,Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getMomentDateForUI , getCurrentDateForBackend, showToasterMessage } from '../../../../utils';
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { CONSTANTS } from '../../../../static/constants';
import { FilterOutlined, UndoOutlined,} from '@ant-design/icons';



const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;




const PurchaseInvoiceFilters = (props) => {
    const { updateState, companyInfo } = props;

    const closeDrawer = () =>{
        props.updateState({
            purchaseInvoiceFilterDrawerVisible:false
        })
    }

    const applyFilter = () => {
        // if(!props.selectedRfqNum && !props.startDate && !props.endDate && !props.bidStartDate && !props.bidEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedInvStatusName && !props.selectedPriority && !props.selectedSalesPersonId && !props.selectedRefNum && !props.selectedProjectMasterId ){
            if(!props.selectedSupplierName && !props.invNum && !props.startDate && !props.endDate && !(props.selectedAmount && props.selectedAmountTerm) && !props.selectedUserStatusName && !props.selectdStatus && !props.selectedRefNum && !props.selectedProjectMasterId ){
                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
            }
            const payload = {
                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                filters: {
                    supplierId: props.selectedSupplierId,
                    invoiceNumber: props.invNum,
                    startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                    endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                    amount: props.selectedAmount,
                    amountSymbol: props.selectedAmount ? props.selectedAmountTerm : null,
                    userStatus: props.selectedUserStatusName,
                    status: props.selectedStatus,
                    reference: props.selectedRefNum,
                    projectMasterId: props.selectedProjectMasterId,
                }
            };
            props.fetchPurchaseInvoicesByFilter(payload);
            props.getPurchaseInvoicesCountByFilter(payload);
            closeDrawer();

    }

    const clearFilter = () =>{
        props.updateState({
            selectedSupplierName: null, selectedSupplierId: null,
            invNum: null,
            startDate: null, endDate: null,
            selectedAmount: null, selectedAmountTerm:null,
            selectedUserStatusName: null, selectedUserStatus: null,
            selectedStatus: null, selectedStatusName: null,
            selectedRefNum: null,
            selectedProjectMasterId: null, selectedProjectName: null
        });
        const payload = {
            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
            filters: {}
        };
        props.fetchPurchaseInvoices(payload);
        props.getPurchaseInvoicesCount(payload);
    }

    const rowGutter = [24, 16];

    return (
        <>
            <Drawer
                title={
                    // <div className="drawer-head-txt">
                    //     <FormattedMessage id='supplier.rfq.filter.drawer.title' />
                    // </div>
                    "Filters"
                }
                width={720}
                zIndex={1}
                maskClosable={false}
                destroyOnClose={true}
                onClose={closeDrawer}
                className="custom-drawer"
                visible={props.purchaseInvoiceFilterDrawerVisible}
                footer={<>
                    <CustomButton
                        intlId='confirmation.cancel'
                        defaultMessage=''
                        type='default'
                        key={0}
                        onClick={closeDrawer}
                      />
                      <div>
                        <CustomButton
                            intlId='common.clearFilter.txt'
                            defaultMessage=''
                            type='default'
                            key={0}
                            onClick={clearFilter}
                            btnIcon={<><UndoOutlined /> &nbsp;</>}
                            style={{marginRight: '10px'}}
                        />
                        <CustomButton
                            intlId='common.applyFilter.txt'
                            defaultMessage=''
                            htmlType='submit'
                            onClick={applyFilter}
                            key={1}
                            btnIcon = {<><FilterOutlined /> &nbsp;</>}
                        />
                      </div>
                      </>}
            >

                <Row gutter={rowGutter}>
                <Col span={12}>
                        <div className="i-label">Invoice #</div> 
                        <TextBox 
                            
                                placeholder='Invoice #'
                                type='text'
                                maxLength={20}
                                countStyle={{top:"40px"}}
                                value={props.invNum}
                                onChange={(e) => {
                                    props.updateState({ invNum: e.target.value });
                                }}
                            />
                    </Col>
                    <Col span={12}>
                        <div className="i-label">Supplier</div>
                        <Dropdown
                                items={props.suppliers[props.pageNumber] || []}
                                sortItems={true}
                                valueKeyName='supplierStoreName'
                                optionKeyName='supplierId'
                                value={props.selectedSupplierName}
                                onSearch={(searchedText) => {
                                    props.fetchSuppliers({
                                        isCompact: true,
                                        searchedText: searchedText,
                                        pageNumber: 1, 
                                        hideSpinner: true,
                                        pageSize: 100,
                                        relationshipId: (props.companyInfo || {}).relationshipId
                                    })
                                }}
                                placeholder={props.intl.formatMessage({
                                    id: 'supplier.filter.name.placeholder', defaultMessage: ''
                                })}
                                onSelect={
                                    (supplierId, option) => {
                                        updateState({
                                            selectedSupplierId: option.value,
                                            selectedSupplierName: option.name,
                                        })
                                    }
                                }
                                allowClear={true}
                                onClear={() => {
                                    updateState({
                                        selectedSupplierId: undefined,
                                        selectedSupplierName: undefined,
                                    })
                                }}
                            />
                        
                    </Col>
                   
                </Row>
                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <div className="i-label">Invoice Date</div>
                        <RangePicker
                              
                                allowEmpty={true}
                                format={format}
                                placeholder={['Start date', 'End date']}
                                value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                                onChange={(dates) => {
                                    if (dates) {
                                        props.updateState({ startDate: dates[0], endDate: dates[1] });
                                    } else {
                                        props.updateState({ startDate: null, endDate: null });
                                    }
                                }}
                            />
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={20}> <div className="i-label">
                                {/* <FormattedMessage id='common.status' /> */}
                                 Invoice Amount
                                </div>
                        <TextBox 
                                 
                                    placeholder={props.intl.formatMessage({
                                        id: 'supplier.filter.amount.placeholder', defaultMessage: ''
                                    })}
                                    type='text'
                                    // maxLength={12}
                                    value={props.selectedAmount}
                                    onChange={(e) => {
                                        props.updateState({ selectedAmount: e.target.value });
                                    }}
                                /></Col>
                            <Col span={4}>
                            <div className="i-label">&nbsp;</div>
                        <Select
                                options={[{ label: '<', value: '<' }, { label: '>', value: '>' }, { label: '=', value: '=' }]}
                                value={props.selectedAmountTerm}
                                placeholder='='
                                defaultValue= '='
                                onSelect={(value) => {
                                    updateState({ selectedAmountTerm: value });
                                }}
                            />
                            </Col>
                        </Row>
                       
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    {/* <Col span={12}>
                        <div className="i-label">
                            Status
                            </div>
                        <Dropdown
                                items={props.userStatus || []}
                                valueKeyName='statusName'
                                optionKeyName='purchaseInvoiceStatusId'
                                statusColorKey='statusColor'
                                value={props.selectedUserStatusName}
                                placeholder={props.intl.formatMessage({ id: 'common.status' })}
                                onSelect={(selectedValue, option) => {
                                    updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                                }}
                            />
                    </Col> */}
                    <Col span={12}>
                        <div className="i-label">
                            System Status
                            </div>
                        <Dropdown
                                items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partiallyFullfilled' }, { name: 'Fullfilled', value: 'fullFilled' }]}
                                sortItems={true}
                                valueKeyName='name'
                                optionKeyName='value'
                                value={props.selectedStatusName}
                                placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                                }}
                            />
                    </Col>
        
                    <Col span={12}>
                    <div className="i-label">
                        <FormattedMessage id='supplier.filter.refNum.placeholder' />
                        </div>

                    <TextBox 
                                placeholder={props.intl.formatMessage({
                                    id: 'supplier.filter.refNum.placeholder', defaultMessage: ''
                                })}
                                type='text'
                                maxLength={20}
                                countStyle={{top:"40px"}}
                                value={props.selectedRefNum}
                                onChange={(e) => {
                                    props.updateState({ selectedRefNum: e.target.value });
                                }}
                            />

                    </Col>
                                        
                    <Col span={12}>
                    <div className="i-label"><FormattedMessage id='salesInvoice.project.placeholder' /></div>
                    <Dropdown
                                
                                items={props.projectList}
                                sortItems={true}
                                valueKeyName='projectName'
                                optionKeyName='projectMasterId'
                                // projectIdKey='projectMasterId'
                                value={props.selectedProjectName}
                                placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                                }}
                            />
                    </Col>
                </Row>
               
            </Drawer>
        </>
    )
};

export default PurchaseInvoiceFilters;
