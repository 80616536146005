import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Skeleton, Pagination, Checkbox, Popover, Tooltip, Menu, Empty } from "antd";
import {
  fetchPaginationDataIfNeeded,
  showToasterMessage,
  getMomentDateForUIReadOnly,
  getCurrencySymbol,
} from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./index.scss";
import PageBreadcrumb from "../../../PageBreadcrumb";
import SupplierPaymentDetails from "../../../../containers/modal/modalBody/supplier/PaymentDetail";
import BusinessExpenseDetail from "../../../../containers/modal/modalBody/finance/BusinessExpense";
import XeroPayment from "../../../../containers/modal/modalBody/common/xero/Payment";
import PODetail from "../../../../containers/modal/modalBody/supplier/PurchaseOrderDetail";
import PurchaseInvoiceDetail from "../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail";
import RFQDetail from "../../../../containers/modal/modalBody/supplier/RFQDetail";
import RFQSplitDetail from "../../../../containers/modal/modalBody/supplier/RFQSplitDetail";
import { CONSTANTS, ICONS } from "../../../../static/constants";
import XeroIcon from "../../../../assets/images/xero_logo.svg";
import { AddAndRemoveSupplierListingColumn } from "../../../general/AddAndRemoveSupplierListingColumn";
import DropdownAction from "antd/lib/dropdown";
import find from "lodash.find";
import {
  FilterOutlined,
  InsertRowRightOutlined,
  DownOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import PayInvoice from "../../../../containers/supplier/invoice/PayInvoice";
import SupplierDetails from '../../../../containers/supplier/detail'
import PageHeader from "../../../Common/pageHeader";


const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const SupplierPaymentListingComp = (props) => {
  const {
    selectedItems = [],
    supplierPaymentList,
    updateState,
    companyInfo,
    supplierPaymentCount,
    pageNumber,
  } = props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchSupplierPayments",
      "supplierPaymentList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchSupplierPayments(payload);
  };
  const openPaymentDetails = (data) => {
    const paymentPayload = {
      supplierId: data.supplierId,
      supplierPaymentId: data.supplierPaymentId,
    };
    const modalData = {
      title: <div className="ant-modal-title">Purchase Payment</div>,
      modalBody: <SupplierPaymentDetails paymentPayload={paymentPayload} />,
      width: 500,
      hideFooter: true,
    };
    props.showModal(modalData);
  };

  // const openPODetails = (data) => {
  //   const poPayload = {
  //     supplierId: data.supplierId,
  //     poMasterId: data.poMasterId,
  //   };
  //   props.pushModalToStack({
  //     modalBody: <PODetail {...props} poPayload={poPayload} />,
  //     width: "100%",
  //     hideFooter: true,
  //     wrapClassName: "modal-custom-detail",
  //   });
  // };

  const openInvoiceDetails = (data) => {
    if (data.expenseMasterId) {
      const expensePayload = {
        expenseMasterId: data.expenseMasterId,
      };
      props.pushModalToStack({
        modalBody: (
          <BusinessExpenseDetail {...props} expensePayload={expensePayload} />
        ),
        width: "100%",
        hideFooter: true,
        wrapClassName: "modal-custom-detail",
      });
    } else {
      const invoicePayload = {
        supplierId: data.supplierId,
        invoiceMasterId: data.invoiceMasterId,
      };
      props.pushModalToStack({
        modalBody: (
          <PurchaseInvoiceDetail {...props} invoicePayload={invoicePayload} />
        ),
        width: "100%",
        hideFooter: true,
        wrapClassName: "modal-custom-detail",
      });
    }
  };

  const openRFQDetails = (data) => {
    const rfqPayload = {
      rfqMasterId: data.rfqMasterId,
      rfqChildMasterId: data.rfqChildMasterId,
    };
    props.pushModalToStack({
      modalBody: data.rfqChildMasterId ? (
        <RFQSplitDetail {...props} rfqPayload={rfqPayload} />
      ) : (
        <RFQDetail {...props} rfqPayload={rfqPayload} />
      ),
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const saveColumnIndex = (columnState) => {
  //   let data = props.txPreferences || {};
  //   let tablePreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.tablePreference) {
  //     tablePreference = JSON.parse(data.tablePreference);
  //   }
  //   tablePreference.columnSetting = columnState;
  //   tablePreference.columnList = props.txColumns;
  //   data.tablePreference = JSON.stringify(tablePreference);
  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data);
  // };

  // const saveColumnWidth = (columnState, width) => {
  //   let data = props.txPreferences || {};
  //   let tablePreference = {};
  //   data.relationshipId = (props.companyInfo || {}).relationshipId;
  //   data.clientUserAccountId = (props.userInfo || {}).userId;
  //   if (data.tablePreference) {
  //     tablePreference = JSON.parse(data.tablePreference);
  //   }
  //   tablePreference.columnSetting = columnState;
  //   tablePreference.columnList = props.txColumns;
  //   data.tablePreference = JSON.stringify(tablePreference);
  //   data.preferenceId = props.preferenceId;
  //   data.gridPreference = props.gridPreference;
  //   props.saveUserPrefrences(data, props);
  // };

  // let moveEvent = {};
  // const onColumnMoved = (event, isDragStopped) => {
  //   if (event.column) {
  //     moveEvent = event;
  //   }
  //   if (
  //     isDragStopped &&
  //     moveEvent.column &&
  //     moveEvent.column.colDef &&
  //     moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API
  //   ) {
  //     saveColumnIndex(moveEvent.columnApi.getColumnState());
  //   }
  // };

  // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
  //   const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
  //   event.api.resetRowHeights();
  //   if (
  //     event.type === "columnResized" &&
  //     event.source !== "sizeColumnsToFit" &&
  //     event.source !== "api" &&
  //     event.finished === true
  //   ) {
  //     //event.api.resetRowHeights();
  //     if (
  //       gridColumnApi &&
  //       gridColumnApi.columnController.bodyWidth <
  //         gridColumnApi.columnController.scrollWidth
  //     ) {
  //       props.gridApi.sizeColumnsToFit();
  //       setTimeout(() => {
  //         const columnArray = event.columnApi.getColumnState();
  //         saveColumnWidth(columnArray);
  //       }, 1000);
  //     } else {
  //       saveColumnWidth(event.columnApi.getColumnState());
  //     }
  //   }
  // };

  const _isColumnToHide = (columnName) => {
    return props.txColumns.indexOf(columnName) < 0 &&
      props.txMandatoryColumns.indexOf(columnName) < 0
      ? true
      : false;
  };

  // const actionMenu = (param, permittedModules) => (
  //     <Menu className="cell-action-dropdown">
  //         <Menu.Item key="0">
  //             <i className="fa fa-trash" />
  //             <Link onClick={() => {
  //                 props.showModal({
  //                     modalBody: "Delete payment is not allowed, to fix the problem create the journal entry",
  //                     // handleSubmit: () => {
  //                     //     props.deletePayment({
  //                     //         supplierPaymentId: param.data.supplierPaymentId,
  //                     //         invoiceMasterId: param.data.invoiceMasterId,
  //                     //         relationshipId: param.data.relationshipId
  //                     //     })
  //                     // }
  //                 })

  //             }}>Delete Payment</Link>
  //         </Menu.Item>
  //     </Menu>
  // );

  // const columnDefs = [
  //   {
  //     field: "isChecked",
  //     colId: "isChecked_1",
  //     headerComponentFramework: () => <Fragment />,
  //     cellRendererFramework: (params) => {
  //       return (
  //         <Checkbox
  //           onClick={() => {
  //             params.node.setDataValue("isChecked_1", !params.data.isChecked);
  //           }}
  //           checked={params.data.isChecked}
  //         />
  //       );
  //     },
  //     suppressMovable: true,
  //     minWidth: 70,
  //     maxWidth: 70,
  //     resizable: false,
  //   },
  //   {
  //     field: "isXeroUploaded",
  //     colId: "isXeroUploaded_1",
  //     headerComponentFramework: () => (
  //       <Tooltip
  //         trigger="click"
  //         placement="top"
  //         title={
  //           <FormattedMessage
  //             id="tooltip.isInvoiceUploadedToXero"
  //             defaultMessage=""
  //           />
  //         }
  //       >
  //         Xero <i className="fa fa-question-circle-o pl5 cursor-pointer" />
  //       </Tooltip>
  //     ),
  //     cellRendererFramework: (params) => {
  //       return params.data.isXeroUploaded ? (
  //         <img src={XeroIcon} alt="" style={{ height: "25px" }} />
  //       ) : (
  //         ""
  //       );
  //     },
  //     suppressMovable: true,
  //     minWidth: 70,
  //     maxWidth: 70,
  //     hide: _isColumnToHide("isXeroUploaded"),
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.paymentNumber" defaultMessage="" />;
  //     },
  //     field: "paymentNumber",
  //     colId: "paymentNumber_1",
  //     hide: _isColumnToHide("paymentNumber"),
  //     suppressMovable: true,
  //     minWidth: 160,
  //     maxWidth: 400,
  //     rowDrag: true,
  //     resizable: true,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openPaymentDetails(link.data);
  //         }}
  //       >
  //         {link.value}
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage id="transactionNumber.text" defaultMessage="" />
  //           <Tooltip
  //             placement="top"
  //             title={props.intl.formatMessage({
  //               id: "tooltip.supplierPayment.txNumber",
  //             })}
  //             trigger="click"
  //           >
  //             <i className="fa fa-question-circle-o pl5 cursor-pointer" />
  //           </Tooltip>
  //         </div>
  //       );
  //     },
  //     field: "txNumber",
  //     colId: "txNumber_1",
  //     hide: _isColumnToHide("txNumber"),
  //     suppressMovable: true,
  //     minWidth: 140,
  //     maxWidth: 400,
  //     resizable: true,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openInvoiceDetails(link.data);
  //         }}
  //       >
  //         {link.value}
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.poNumber" defaultMessage="" />;
  //     },
  //     field: "poNumber",
  //     colId: "poNumber_1",
  //     hide: _isColumnToHide("poNumber"),
  //     suppressMovable: true,
  //     minWidth: 140,
  //     maxWidth: 400,
  //     resizable: true,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openPODetails(link.data);
  //         }}
  //       >
  //         {link.value}
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.rfqNumber" defaultMessage="" />;
  //     },
  //     field: "rfqNumber",
  //     colId: "rfqNumber_1",
  //     hide: _isColumnToHide("rfqNumber"),
  //     suppressMovable: true,
  //     minWidth: 70,
  //     maxWidth: 400,
  //     cellRendererFramework: (link) => (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => {
  //           openRFQDetails(link.data);
  //         }}
  //       >
  //         {link.value}
  //       </div>
  //     ),
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.supplier" defaultMessage="" />;
  //     },
  //     field: "supplierName",
  //     colId: "supplierName_1",
  //     hide: _isColumnToHide("supplierName"),
  //     suppressMovable: true,
  //     minWidth: 100,
  //     maxWidth: 400,
  //     resizable: true,
  //     cellRendererFramework: (link) => {
  //       return (
  //         <div className="cursor-pointer">
  //           <div
  //             onClick={() => {
  //               props.history.push("suppliers/details", {
  //                 supplierId: link.data.supplierId,
  //                 supplierName: link.data.supplierName,
  //               });
  //             }}
  //           >
  //             {link.data.supplierName}
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage id="paymentAmount.text" defaultMessage="" />{" "}
  //         </div>
  //       );
  //     },
  //     field: "paymentAmount",
  //     colId: "paymentAmount_1",
  //     hide: _isColumnToHide("paymentAmount"),
  //     suppressMovable: true,
  //     minWidth: 70,
  //     maxWidth: 400,
  //     cellRendererFramework: (link) => {
  //       return link.value && link.value !== "-" ? (
  //         <span>
  //           {" "}
  //           {link.data.foreignCurrencyIcon ? (
  //             <i className={link.data.foreignCurrencyIcon}></i>
  //           ) : link.data.foreignCurrency ? (
  //             <span>{link.data.foreignCurrency}</span>
  //           ) : props.companyInfo.currencyIcon ? (
  //             <i className={props.companyInfo.currencyIcon}></i>
  //           ) : (
  //             props.companyInfo.currencyCode + " "
  //           )}
  //           {link.value}{" "}
  //         </span>
  //       ) : (
  //         "-"
  //       );
  //     },
  //     valueGetter: (link) => {
  //       return link.data.paymentAmount
  //         ? Number(link.data.paymentAmount)
  //             .toFixed(2)
  //             .toString()
  //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //         : "-";
  //     },
  //     resizable: true,
  //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <div>
  //           <FormattedMessage id="invoiceAmount.text" defaultMessage="" />{" "}
  //         </div>
  //       );
  //     },
  //     cellRendererFramework: (link) => {
  //       return link.value && link.value !== "-" ? (
  //         <span>
  //           {" "}
  //           {link.data.foreignCurrencyIcon ? (
  //             <i className={link.data.foreignCurrencyIcon}></i>
  //           ) : link.data.foreignCurrency ? (
  //             <span>{link.data.foreignCurrency}</span>
  //           ) : props.companyInfo.currencyIcon ? (
  //             <i className={props.companyInfo.currencyIcon}></i>
  //           ) : (
  //             props.companyInfo.currencyCode + " "
  //           )}
  //           {link.value}{" "}
  //         </span>
  //       ) : (
  //         "-"
  //       );
  //     },
  //     field: "invoiceAmount",
  //     colId: "invoiceAmount_1",
  //     hide: _isColumnToHide("invoiceAmount"),
  //     suppressMovable: true,
  //     minWidth: 120,
  //     maxWidth: 400,
  //     valueGetter: (link) => {
  //       return link.data.invoiceAmount
  //         ? Number(link.data.invoiceAmount)
  //             .toFixed(2)
  //             .toString()
  //             .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //         : "-";
  //     },
  //     resizable: true,
  //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return "Customer Inquiry #";
  //     },
  //     field: "customerInquiryNumber",
  //     hide: _isColumnToHide("customerInquiryNumber"),
  //     colId: "customerInquiryNumber_1",
  //     resizable: true,
  //     suppressMovable: true,
  //     minWidth: 70,
  //     maxWidth: 400,
  //     cellRendererFramework: (link) => (
  //       <div>{link.data.customerInquiryNumber || "-"}</div>
  //     ),
  //   },

  //   {
  //     headerComponentFramework: () => {
  //       return "Customer RFQ #";
  //     },
  //     field: "customerRFQNumber",
  //     hide: _isColumnToHide("customerRFQNumber"),
  //     colId: "customerRFQNumber_1",
  //     resizable: true,
  //     suppressMovable: true,
  //     minWidth: 70,
  //     maxWidth: 400,
  //     cellRendererFramework: (link) => (
  //       <div>{link.data.customerRFQNumber || "-"}</div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return "Customer PO #";
  //     },
  //     field: "customerPONumber",
  //     hide: _isColumnToHide("customerPONumber"),
  //     colId: "customerPONumber_1",
  //     resizable: true,
  //     suppressMovable: true,
  //     minWidth: 70,
  //     maxWidth: 400,
  //     cellRendererFramework: (link) => (
  //       <div>{link.data.customerPONumber || "-"}</div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.description" />;
  //     },
  //     field: "description",
  //     colId: "description_1",
  //     hide: _isColumnToHide("description"),
  //     sortable: true,
  //     resizable: true,
  //     minWidth: 200,
  //     cellRendererFramework: (params) => {
  //       setTimeout(() => {
  //         if (
  //           params.reactContainer &&
  //           params.reactContainer.scrollHeight > 30 &&
  //           params.reactContainer.scrollHeight >=
  //             (params.node.data.minHeight || 0)
  //         ) {
  //           params.eParentOfValue.style.height = "inherit";
  //           params.eParentOfValue.style.height =
  //             params.reactContainer.scrollHeight;
  //           params.node.data.maxHeight = params.reactContainer.scrollHeight;
  //           params.node.setRowHeight(params.reactContainer.scrollHeight);
  //           params.api && params.api.onRowHeightChanged();
  //         }
  //       }, 200);
  //       return ReactHtmlParser(params.data.description);
  //     },
  //   },
  //   // {
  //   //     headerComponentFramework: () => {
  //   //         return <FormattedMessage id='common.status' defaultMessage='' />;
  //   //     },
  //   //     field: "status",
  //   //     colId: "status_1",
  //   //     hide: _isColumnToHide('status'),
  //   //     suppressMovable: true,
  //   //     minWidth: 70,
  //   //     maxWidth: 400,
  //   //     cellRendererFramework: (params) => {
  //   //         return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}>{params.value}</span> </div>
  //   //     },
  //   //     resizable: true,
  //   //     cellClass: 'status-cell payment'
  //   // },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="modal.supplier.payInvoice.paymentDate.placeholder"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "paymentDate",
  //     colId: "paymentDate_1",
  //     hide: _isColumnToHide("paymentDate"),
  //     suppressMovable: true,
  //     minWidth: 70,
  //     maxWidth: 400,
  //     resizable: true,
  //     cellRendererFramework: (link) => (
  //       <div>
  //         <Tooltip
  //           placement="topLeft"
  //           title={
  //             link.data.paymentDate &&
  //             getMomentDateForUIReadOnly({
  //               date: link.data.paymentDate,
  //               format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
  //             })
  //           }
  //         >
  //           {getMomentDateForUIReadOnly({
  //             date: link.data.paymentDate,
  //             format,
  //           })}
  //         </Tooltip>
  //       </div>
  //     ),
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return (
  //         <FormattedMessage
  //           id="supplier.purchaseOrder.listing.header.refNumber"
  //           defaultMessage=""
  //         />
  //       );
  //     },
  //     field: "reference",
  //     colId: "reference_1",
  //     hide: _isColumnToHide("reference"),
  //     minWidth: 70,
  //     maxWidth: 300,
  //     suppressMovable: true,
  //     resizable: true,
  //   },
  //   {
  //     headerComponentFramework: () => {
  //       return <FormattedMessage id="common.project" defaultMessage="" />;
  //     },
  //     field: "project",
  //     hide: _isColumnToHide("project"),
  //     colId: "project_1",
  //     minWidth: 70,
  //     maxWidth: 300,
  //     suppressMovable: true,
  //     cellRendererFramework: (link) => {
  //       return (
  //         <div>
  //           {link.data.projectNumber ? (
  //             <span>
  //               {link.data.projectNumber + " (" + link.data.projectName + ")"}
  //             </span>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       );
  //     },
  //     resizable: true,
  //   },

  //   // {
  //   //     headerComponentFramework: () => {
  //   //         return <div className="cursor-pointer">
  //   //             <Popover
  //   //                 content={<AddAndRemoveSupplierListingColumn {...props} />}
  //   //                 title={<div className="text-center">Add/Remove </div>}
  //   //                 placement="left"
  //   //                 trigger="click"
  //   //             >
  //   //                 <i className='fa fa-bars' />
  //   //             </Popover>
  //   //         </div>;
  //   //     },
  //   //     cellRendererFramework: (param) => {
  //   //         return <Dropdown overlay={actionMenu(param)} trigger={['click']}>
  //   //             <Button className="action-btn small">
  //   //                 <i className="fa fa-ellipsis-v" />
  //   //             </Button>
  //   //         </Dropdown>
  //   //     },
  //   //     colId: "action_1",
  //   //     pinned: 'right',
  //   //     field: "action",
  //   //     lockPosition:true,
  //   //     resizable: true,
  //   //     suppressMovable: true,
  //   //     minWidth: 52,
  //   //     maxWidth: 52,
  //   // }
  // ];

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };

  const breadCrumbList = [
    {
      name: (
        <FormattedMessage id="sidebar.menuItem.purchase" defaultMessage="" />
      ),
    },
    {
      name: (
        <FormattedMessage
          id="sidebar.purchasePayment.popup.title"
          defaultMessage=""
        />
      ),
    },
  ];

  // const onGridReady = (params) => {
  //   props.updateState({
  //     gridApi: params.api,
  //     gridColumnApi: params.columnApi,
  //   });
  //   if (props.isColumnFitTable && params.api) {
  //     params.api.sizeColumnsToFit();
  //     props.updateState({
  //       isColumnFitTable: false,
  //     });
  //   }
  //   onDataRendered(params);
  // };

  // const onModelUpdated = (params) => {
  //   if (
  //     (supplierPaymentList[pageNumber] || []).length &&
  //     !props.isRowHeightSet
  //   ) {
  //     setTimeout(() => {
  //       params.api.resetRowHeights();
  //     }, 1000);
  //     props.updateState({
  //       isRowHeightSet: true,
  //     });
  //   }
  //   if (props.tableDataReRendered) {
  //     //params.api.resetRowHeights();
  //     props.updateState({
  //       tableDataReRendered: false,
  //     });
  //     let txColumnSetting =
  //       props.txColumnSetting && props.txColumnSetting.length
  //         ? props.txColumnSetting
  //         : params.columnApi.getColumnState();
  //     const columnToBeSet = [];
  //     const indexObjArr = TRANSACTION_COLUMN_INDEX.PURCHASE_PAYMENT_LISTING;

  //     indexObjArr.forEach((e) => {
  //       let colObj = find(txColumnSetting, { colId: e + "_1" });
  //       if (colObj) {
  //         columnToBeSet.push(colObj);
  //       }
  //     });

  //     txColumnSetting = txColumnSetting.filter((ele) => {
  //       let columnName = ele.colId.split("_")[0];
  //       return indexObjArr.indexOf(columnName) === -1;
  //     });
  //     txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //     txColumnSetting.forEach((e) => {
  //       let columnName = e.colId.split("_")[0];
  //       if (columnName !== "action") {
  //         if (columnName === "isChecked") {
  //           e.hide = false;
  //         } else {
  //           e.hide = _isColumnToHide(columnName);
  //         }
  //       }
  //       if (columnName === "action") {
  //         e.hide = false;
  //         e.pinned = "right";
  //       }
  //     });
  //     params.columnApi.setColumnState(txColumnSetting);
  //     setTimeout(() => {
  //       if (
  //         props.gridColumnApi &&
  //         props.gridColumnApi.columnController.bodyWidth <
  //           props.gridColumnApi.columnController.scrollWidth
  //       ) {
  //         params.api.sizeColumnsToFit();
  //       }
  //     }, 500);
  //   }
  // };

  // const onDataRendered = (params) => {
  //   let txColumnSetting =
  //     props.txColumnSetting && props.txColumnSetting.length
  //       ? props.txColumnSetting
  //       : params.columnApi.getColumnState();
  //   if (Object.keys(txColumnSetting).length) {
  //     const columnToBeSet = [];
  //     const indexObjArr = TRANSACTION_COLUMN_INDEX.PURCHASE_PAYMENT_LISTING;

  //     indexObjArr.forEach((e) => {
  //       let colObj = find(txColumnSetting, { colId: e + "_1" });
  //       if (colObj) {
  //         columnToBeSet.push(colObj);
  //       }
  //     });

  //     txColumnSetting = txColumnSetting.filter((ele) => {
  //       let columnName = ele.colId.split("_")[0];
  //       return indexObjArr.indexOf(columnName) === -1;
  //     });
  //     txColumnSetting = columnToBeSet.concat(txColumnSetting);
  //     txColumnSetting.forEach((e) => {
  //       let columnName = e.colId.split("_")[0];
  //       if (columnName !== "action") {
  //         if (columnName === "isChecked") {
  //           e.hide = false;
  //           e.pinned = "left";
  //         } else {
  //           e.hide = _isColumnToHide(columnName);
  //         }
  //       }
  //       if (columnName === "action") {
  //         e.hide = false;
  //         e.width = 52;
  //         e.pinned = "right";
  //       }
  //     });
  //     params.columnApi.setColumnState(txColumnSetting);
  //     setTimeout(() => {
  //       if (
  //         params.columnApi &&
  //         params.columnApi.columnController.bodyWidth <
  //           params.columnApi.columnController.scrollWidth
  //       ) {
  //         params.api.sizeColumnsToFit();
  //       }
  //     }, 500);
  //   } else {
  //     params.api.sizeColumnsToFit();
  //   }
  // };

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      supplierPaymentId: Number(item.supplierPaymentId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.supplierPaymentId !== item.supplierPaymentId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < supplierPaymentList[pageNumber].length; i++) {
      let item = supplierPaymentList[pageNumber][i];
      let selectItem = find(selectedItems, {
        supplierPaymentId: Number(item.supplierPaymentId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (
      //   !props.supplierPaymentList ||
      //   !props.supplierPaymentList[pageNumber] ||
      //   props.supplierPaymentList[pageNumber].length === 0
      props.selectedItems?.length !==
      props.supplierPaymentList[pageNumber]?.length
    ) {
      return false;
    }
    for (let i = 0; i < props.supplierPaymentList[pageNumber].length; i++) {
      let usr = props.supplierPaymentList[pageNumber][i];
      let selectItem = find(selectedItems, {
        supplierId: Number(usr.supplierId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <CheckCircleOutlined />
          Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <CloseCircleOutlined />
          Unselect all
        </Menu.Item>
        <Menu.Item
          key="5"
          onClick={() => {
            const paymentList = props.selectedItems;
            // props.gridApi.forEachNode((obj) => {
            //   if (obj.data.isChecked) {
            //     paymentList.push(obj.data);
            //   }
            // });
            const modalData = {
              title: (
                <FormattedMessage
                  id="xeroPaymentUpload.text"
                  defaultMessage=""
                />
              ),
              modalBody: (
                <XeroPayment
                  type={"Supplier"}
                  {...props}
                  paymentList={paymentList}
                />
              ),
              width: 1100,
              hideFooter: true,
            };
            if (paymentList.length) {
              props.showModal(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: (
                  <FormattedMessage
                    id="paymentBeforeUpload.message"
                    defaultMessage=""
                  />
                ),
              });
            }
          }}
        >
          <CheckCircleOutlined />
          Uploade Xero
        </Menu.Item>
      </Menu>
    );
  };
  const openPayInvoice = () => {
    let modalData2 = {
      title: <div className="ant-modal-title">Purchase Payment</div>,
      modalBody: <PayInvoice {...props} invoiceData={{}} />,
      width: 600,
      hideFooter: true,
      wrapClassName: "supplier-payment-modal",
      onCancel: () => {
        props.pushModalToStack({
          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
          footer:{submit:{intlId:"button.close.label"}},
          handleSubmit: () => {
            props.hideModal();
          }
        })
      }
    };
    props.showModal(modalData2);
  };
  return (
    <Fragment>
     
      <PageHeader {...props}
                pageName="sidebar.menuItem.payment"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={openPayInvoice}
            />
      <div className="view-container">
          <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} active>

            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="supplier.listing.table.heading.title"
                  defaultMessage=""
                />
              </div>
              <div className="vertical-sep" />
               
             {supplierPaymentCount}
            </div>
            
            <div className="right-actions">
              <button
                className="action-btn"
                onClick={() => {
                  props.updateState({
                    paymentListFilterDrawerVisible: true,
                  });
                }}
              >
                <FilterOutlined /> &nbsp;
                <FormattedMessage id="filter.text" defaultMessage="" />
              </button>

              <Popover
                content={<AddAndRemoveSupplierListingColumn {...props} />}
                title={
                  <div className="text-center">
                    <FormattedMessage id="common.addRemove" defaultMessage="" />{" "}
                  </div>
                }
                placement="leftTop"
                trigger="click"
                arrow="right"
                visible={props.showColPop}
              >
                <button
                  onClick={() => {
                    props.updateState({ showColPop: !props.showColPop });
                  }}
                >
                  <InsertRowRightOutlined className='mr5'/>
                   Columns
                </button>
              </Popover>
              {/* <button
                className="action-btn"
                onClick={() => {
                  const paymentList = [];
                  props.gridApi.forEachNode((obj) => {
                    if (obj.data.isChecked) {
                      paymentList.push(obj.data);
                    }
                  });
                  const modalData = {
                    title: (
                      <FormattedMessage
                        id="xeroPaymentUpload.text"
                        defaultMessage=""
                      />
                    ),
                    modalBody: (
                      <XeroPayment
                        type={"Supplier"}
                        {...props}
                        paymentList={paymentList}
                      />
                    ),
                    width: 1100,
                    hideFooter: true,
                  };
                  if (paymentList.length) {
                    props.showModal(modalData);
                  } else {
                    showToasterMessage({
                      messageType: "error",
                      description: (
                        <FormattedMessage
                          id="paymentBeforeUpload.message"
                          defaultMessage=""
                        />
                      ),
                    });
                  }
                }}
              >
                <Tooltip
                  placement="right"
                  title={
                    <FormattedMessage
                      id="upload.invoice.xero"
                      defaultMessage=""
                    />
                  }
                >
                  <img src={XeroIcon} alt="" style={{ height: "20px" }} />{" "}
                  <i className="fa fa-upload" />
                </Tooltip>
              </button> */}
            </div>
          </Skeleton>
          </div>

          {/* <div className="table-action-container">
                        <Space direction="horizontal">
                            <Dropdown
                                style={{ width: '160px', marginTop: '-20px' }}
                                items={props.suppliers[pageNumber] || []}
                                valueKeyName='supplierStoreName'
                                optionKeyName='supplierId'
                                value={props.selectedSupplierName}
                                onSearch={(searchedText) => {
                                    props.fetchSuppliers({
                                        isCompact: true,
                                        searchedText: searchedText,
                                        pageNumber: 1, 
                                        hideSpinner: true,
                                        pageSize: 100,
                                        relationshipId: (props.companyInfo || {}).relationshipId
                                    })
                                }}
                                placeholder={props.intl.formatMessage({
                                    id: 'supplier.filter.name.placeholder', defaultMessage: ''
                                })}
    
                                onSelect={
                                    (supplierId, option) => {
                                        updateState({
                                            selectedSupplierId: option.value,
                                            selectedSupplierName: option.name,
                                        })
                                    }
                                }
                                allowClear={true}
                                onClear={() => {
                                    updateState({
                                        selectedSupplierId: undefined,
                                        selectedSupplierName: undefined,
                                    })
                                }}
                            />

                            <TextBox 
                                style={{ width: '120px'}}
                                placeholder='Payment #'
                                type='text'
                                maxLength={20}
                                value={props.paymentNum}
                                onChange={(e) => {
                                    props.updateState({ paymentNum: e.target.value });
                                }}
                            />
                            <TextBox 
                                style={{ width: '120px'}}
                                placeholder='Inv #'
                                type='text'
                                maxLength={20}
                                value={props.selectedInvNum}
                                onChange={(e) => {
                                    props.updateState({ selectedInvNum: e.target.value });
                                }}
                            />
    
                            <div className='solpty' style={{marginTop: '2px'}}>
                                <i className={props.companyInfo.currencyIcon}></i> 
        
                                <TextBox 
                                    style={{ width: '150px'}}
                                    placeholder={props.intl.formatMessage({
                                        id: 'supplier.filter.amount.placeholder', defaultMessage: ''
                                    })}
                                    type='Number'
                                    className='certso'
                                    maxLength={12}
                                    value={props.selectedPaymentAmt}
                                    onChange={(e) => {
                                        props.updateState({ selectedPaymentAmt: e.target.value });
                                    }}
                                />
                            </div>
    
                            <Select
                                style={{width: '60px', marginLeft: '-9px' }}
                                options={[{ label: '<', value: '<' }, { label: '>', value: '>' }, { label: '=', value: '=' }]}
                                value={props.selectedAmountTerm}
                                placeholder='='
                                defaultValue= '='
                                onSelect={(value) => {
                                    updateState({ selectedAmountTerm: value });
                                }}
                            />

                            <TextBox 
                                style={{ width: '120px', marginRight: '0px'}}
                                placeholder={props.intl.formatMessage({
                                    id: 'supplier.filter.refNum.placeholder', defaultMessage: ''
                                })}
                                type='text'
                                maxLength={20}
                                value={props.selectedRefNum}
                                onChange={(e) => {
                                    props.updateState({ selectedRefNum: e.target.value });
                                }}
                            />
    
                            <Dropdown
                                style={{ width: '160px', marginTop: '-20px' }}
                                items={props.projectList}
                                valueKeyName='projectName'
                                optionKeyName='projectMasterId'
                                projectIdKey='projectMasterId'
                                value={props.selectedProjectName}
                                placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                                onSelect={(status, option) => {
                                    updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                                }}
                            />
    
                            <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                                if(!props.selectedSupplierName && !props.paymentNum && !props.selectedInvNum && !(props.selectedPaymentAmt && props.selectedAmountTerm) && !props.selectedRefNum && !props.selectedProjectMasterId ){
                                    return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                                }
                                const payload = {
                                    companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                    filters: {
                                        supplierId: props.selectedSupplierId,
                                        paymentNumber: props.paymentNum,
                                        invoiceNumber: props.selectedInvNum,
                                        paymentAmount: props.selectedPaymentAmt,
                                        amountSymbol: props.selectedPaymentAmt ? props.selectedAmountTerm : null,
                                        reference: props.selectedRefNum,
                                        projectMasterId: props.selectedProjectMasterId,
                                    }
                                };
                                props.fetchSupplierPaymentsByFilter(payload);
                                props.getSupplierPaymentsCountByFilter(payload);
                            }}>
                                {<FilterOutlined />}
                            </button>
                            <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                                props.updateState({
                                    selectedSupplierName: null, selectedSupplierId: null,
                                    paymentNum: null,
                                    selectedInvNum: null,
                                    selectedPaymentAmt:null, selectedAmountTerm:null,
                                    selectedIsInvoicedValue: null, selectedIsInvoiced: null,
                                    selectedRefNum: null,
                                    selectedProjectMasterId: null, selectedProjectName: null
                                });
                                const payload = {
                                    companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                    filters: {}
                                };
                                props.fetchSupplierPaymentsByFilter(payload);
                                props.getSupplierPaymentsCountByFilter(payload);
                                }}>
                                    {<UndoOutlined />}
                            </button>
                        </Space>
                    </div> */}

          {/* <div className="cursor-pointer">
                                <span className="top-button">
                                    <Popover
                                        content={<AddAndRemoveSupplierListingColumn {...props} />}
                                        title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' />  </div>}
                                        placement="left"
                                        trigger="click"
                                    >
                                        <i className='fa fa-bars' />
                                    </Popover>
                                </span>
                                <div className="clearfix"></div>
                            </div> */}

          {/* <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={supplierPaymentList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                onModelUpdated={onModelUpdated}
                                // onGridReady ={(params) => {
                                //     gridApi = params.api;
                                // }}
                                animateRows={true}
                                defaultColDef={{
                                    flex: 1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                                onColumnResized={onColumnResized}
                                onDragStopped={() => {
                                    onColumnMoved({}, true);
                                }}
                                //onColumnMoved={onColumnMoved}
                            >
                            </AgGridReact> */}
          <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>
                 
          <div className="table-container">
            <table id="shipment-table">
              <thead>
                <tr>
                  <th width="5%">
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    <DropdownAction overlay={checkMenu()} trigger={["click"]}>
                      <DownOutlined />
                    </DropdownAction>
                  </th>


                  {!_isColumnToHide("isXeroUploaded") ? (
                    <th>
                      <Tooltip
                        trigger="click"
                        placement="top"
                        title={
                          <FormattedMessage
                            id="tooltip.isInvoiceUploadedToXero"
                            defaultMessage=""
                          />
                        }
                      >
                        Xero{" "}
                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                      </Tooltip>
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("paymentNumber") ? (
                    <th>
                      <FormattedMessage
                        id="common.paymentNumber"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("supplierName") ? (
                    <th>
                      <FormattedMessage
                        id="common.supplier"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("invoiceAmount") ? (
                    <th>
                      <FormattedMessage
                        id="invoiceAmount.text"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("paymentDate") ? (
                    <th>
                      <FormattedMessage
                        id="modal.supplier.payInvoice.paymentDate.placeholder"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("paymentAmount") ? (
                    <th>
                      <FormattedMessage
                        id="paymentAmount.text"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("rfqNumber") ? (
                    <th>
                      <FormattedMessage
                        id="common.rfqNumber"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("common.rfqNumber") ? (
                    <th>
                      <FormattedMessage id="rfqNumber" defaultMessage="" />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("poNumber") ? (
                    <th>
                      <FormattedMessage
                        id="common.poNumber"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("customerPONumber") ? (
                    <th>Customer PO #</th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("customerRFQNumber") ? (
                    <th>Customer RFQ #</th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("customerInquiryNumber") ? (
                    <th>Customer Inquiry #</th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("txNumber") ? (
                    <th>
                      <FormattedMessage
                        id="transactionNumber.text"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("description") ? (
                    <th>
                      <FormattedMessage
                        id="common.description"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("reference") ? (
                    <th>
                      <FormattedMessage
                        id="supplier.purchaseOrder.listing.header.refNumber"
                        defaultMessage=""
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {!_isColumnToHide("project") ? (
                    <th>
                      <FormattedMessage id="common.project" defaultMessage="" />
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>

              <tbody>
                {supplierPaymentList && (supplierPaymentList[pageNumber] || []).length
                  ? supplierPaymentList[pageNumber].map((rowData, index) => (
                      <tr key={index}>
                        <td>
                          <Checkbox
                            onClick={() => {
                              itemSelection(rowData);
                            }}
                            checked={
                              find(selectedItems, {
                                supplierPaymentId: Number(
                                  rowData.supplierPaymentId
                                ),
                              })
                                ? true
                                : false
                            }
                          />
                        </td>

                        {!_isColumnToHide("isXeroUploaded") ? (
                          <td>
                            <div className="cursor-pointer">
                              {rowData.isXeroUploaded ? (
                                <img
                                  src={XeroIcon}
                                  alt=""
                                  style={{ height: "25px" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("paymentNumber") ? (
                          <td>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                openPaymentDetails(rowData);
                              }}
                            >
                              {rowData.paymentNumber}
                            </div>
                            
                            </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("supplierName") ? (
                          <td>
                            <div className="cursor-pointer">
                              <div
                              onClick={() => {
                                props.pushModalToStack({  
                                 
                                  modalBody: <SupplierDetails {...props}
                                  supplierId= {rowData.supplierId}
                                  supplierName={rowData.supplierName}
                              />,
                              width: '100%',
                              hideTitle: true,
                              hideFooter: true,
                              wrapClassName: "new-transaction-wrapper",
                              })
                             }}
                                // onClick={(link) => {
                                //   props.history.push("suppliers/details", {
                                //     supplierId: rowData?.supplierId,
                                //     supplierName: rowData?.supplierName,
                                //   });
                                // }}
                              >
                                {rowData.supplierName}
                              </div>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("invoiceAmount") ? (
                          <td className="line-view">
                            {props.companyInfo.currencyCode && rowData.invoiceAmount ? getCurrencySymbol(props.companyInfo.currencyCode) : ''}
                            {rowData.invoiceAmount
                              ? Number(rowData.invoiceAmount)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              : "-"}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("invoiceAmount") ? (
                          <td>
                            {
                              <div>
                                <Tooltip
                                  placement="topLeft"
                                  title={
                                    rowData.paymentDate &&
                                    getMomentDateForUIReadOnly({
                                      date: rowData.paymentDate,
                                      format:
                                        CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                    })
                                  }
                                >
                                  {getMomentDateForUIReadOnly({
                                    date: rowData.paymentDate,
                                    format,
                                  })}
                                </Tooltip>
                              </div>
                            }
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("paymentAmount") ? (
                          <td>
                            {rowData.paymentAmount &&
                            rowData.paymentAmount !== "-" ? (
                              <span>
                                {" "}
                                {rowData.foreignCurrencyIcon ? (
                                  <i
                                    className={rowData.foreignCurrencyIcon}
                                  ></i>
                                ) : rowData.foreignCurrency ? (
                                  <span>{rowData.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}{" "}
                                {(rowData.paymentAmount || 0)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("rfqNumber") ? (
                          <td>
                            {
                              <div
                                className="cursor-pointer"
                                onClick={(link) => {
                                  openRFQDetails(rowData.data);
                                }}
                              >
                                {rowData.value ||  <div className="empty-data"></div>}
                              </div>
                            }
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("poNumber") ? (
                          <td>
                            {
                              <div
                                className="cursor-pointer"
                                onClick={(link) => {
                                  openRFQDetails(link.data);
                                }}
                              >
                                {rowData.value ||  <div className="empty-data"></div>}
                              </div>
                            }
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("customerPONumber") ? (
                          <td>{rowData.customerPONumber ||  <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}
                        {!_isColumnToHide("customerRFQNumber") ? (
                          <td>{rowData.customerRFQNumber ||  <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("customerInquiryNumber") ? (
                          <td>{rowData.customerInquiryNumber ||  <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("customerInquiryNumber") ? (
                          <td>
                            {
                              <div
                                className="cursor-pointer"
                                onClick={(link) => {
                                  openInvoiceDetails(link.data);
                                }}
                              >
                                {rowData.value ||  <div className="empty-data"></div>}
                              </div>
                            }
                          </td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("description") ? (
                          <td>{rowData.description ||  <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("reference") ? (
                          <td>{rowData.reference ||  <div className="empty-data"></div>}</td>
                        ) : (
                          ""
                        )}

                        {!_isColumnToHide("project") ? (
                          <td>
                           {rowData.projectName ||  <div className="empty-data"></div>}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))
                  : <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                  </tr>
                  }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
        <div className="f-left"></div>
        <div className="f-right">
        <Pagination
          size="small"
          total={supplierPaymentCount}
          showTotal={showTotal}
          defaultPageSize={100}
          showSizeChanger
          pageSizeOptions={[10, 25, 50, 100, 200]}
          // showQuickJumper
          onChange={(pageNumber, pageSize) => {
            loadPageData(pageNumber, pageSize);
            updateState({ pageSize: pageSize, pageNumber: pageNumber });
          }}
          onShowSizeChange={(pageNumber, pageSize) => {
            handlePageSizeChange(pageNumber, pageSize, props);
          }}
        />
        </div>
        </Skeleton>
      </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(SupplierPaymentListingComp);
