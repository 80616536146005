import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DistributionGroupDrawer from '../../../../components/drawer/communication/distribution-group';
import { saveDG, getDGDetails, getAllContacts, getContactListCount, resetContactPaginatedData, fetchSubscribers } from "./action";
import { getAllDG } from "../../../../containers/communication/distribution-group/action";
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchJobRoles,fetchAllEmployees,fetchJobTitles } from './../../../../actions/commonActions';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../../utils";
import { fetchCustomerContacts,fetchSupplierContacts } from "../../../customer/Listing/ShowConact/action";
import { getClassList } from "../../../schoolManagement/class/action";
import { getSection } from "../../../schoolManagement/section/action";
import { getSchoolStudents  , countSchoolStudents, resetStudentSchoolList ,getSchoolStudentsContact} from "../../../schoolManagement/student/action";
class DistributionGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.dgData,
      pageNumber: 1,
      pageSize: 5000,
      selectedUsers: [],
      isLoading: true,
      selectedType: '',
      sectionList: [],
      selectedClassList: [],
      selectedSectionList: [],
      filteredContacts: [],
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
  
    const userInfo = this.props.userInfo || {};
  
    const payload = {
      pageNumber: 1,
      pageSize: 5000,
      companyInfo,
      relationshipEmployeeId: companyInfo?.relationshipEmployees.relationshipEmployeeId,

      relationshipId: companyInfo.relationshipId,
      distributionGroupId: this.state.distributionGroupId
    };
    fetchPaginationDataIfNeeded("getAllContacts", "contactList", this.props, payload);
    fetchDataIfNeeded("getContactListCount", "contactListCount", this.props, payload);
    if (this.state.distributionGroupId) {
      fetchDataIfNeeded("getDGDetails", "dgDetails", this.props, payload);
    }
    this.props.fetchJobRoles(payload);
    this.props.fetchAllEmployees(payload);
    this.props.fetchJobTitles(payload);
    this.props.getClassList(payload);
    this.props.getSection(payload);
    this.props.fetchCustomerContacts({...payload,pageSize:5000});
    
    this.props.fetchSupplierContacts(payload);
    this.props.fetchSubscribers(payload);
    this.props.getSchoolStudents(payload);
    this.props.countSchoolStudents(payload);
    this.props.getSchoolStudentsContact(payload)
   
  }

  componentWillReceiveProps(props) {
    if (props.dgDetails && props.dgDetails[this.state.distributionGroupId] && this.state.selectedUsers.length === 0) {
      const val = (props.dgDetails||[])[this.state.distributionGroupId].map((item)=>{
        const value = item;
        value.selectedContactId =
        Number(item?.subscriberContactId) ||
        Number(item?.contactId) ||
        Number(item?.relationshipEmployeeId) ||
        Number(item?.contactId);
      value.selectedContactName =
        item?.firstName +" "+ (item?.lastName||"") ;
        return value;
      })

     
      this.setState({ selectedUsers: val });
    }
   
     
      if (this.state?.isLoading &&props.schoolStudentContactList && (props.schoolStudentContactList||[])?.length) {


        let filteredContacts=[];
          let studentContactList =[];
          let parentContactList=[];   
        for(let i=0;i<(props.schoolStudentContactList||[])?.length ; i++){
          let val = (props.schoolStudentContactList||[])[i];
          if(val?.familyRelationship === "student"){
            studentContactList.push(val)
          }
          else{
            parentContactList.push(val)
          }
        }
     
         filteredContacts = studentContactList.map((student) => {
          const parent = parentContactList.find(item => item?.customerId === student?.customerId);
          if (parent) {
              return {
                  ...student,
                  emailAddress: parent?.emailAddress || "",
                  cellPhone: parent?.cellPhone || "",
                 
              };
          }
          return student;
      });
          console.log(filteredContacts,studentContactList,parentContactList ,"filteringggg")
        if(this.state.selectedType === "Student"){
          
          this.setState({
            filteredContacts,
            isLoading: false 
          })

          
        }                              
      }
      
     

    
  }

  render() {
    return <DistributionGroupDrawer
      {...this.props}     
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}
    />
  }
}



const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    contactList: state.communicationReducer.contactList,
    contactListCount: state.communicationReducer.contactListCount,
    dgDetails: state.communicationReducer.dgDetails,
    jobRoleList: state.common.jobRoleList,
    allEmployee : state.common.employees,
    classList: state.school.classList,
    sectionList: state.school.sectionList,
    schoolStudentList: state.school.schoolStudentList,
    jobTitleList: state.common.jobTitleList,
    customerContacts: state.customer.customerContacts,
    supplierContacts: state.customer.supplierContacts,
    subscribers: state.customer.subscribers,
    schoolStudentsCount: state.school.schoolStudentCount,
    schoolStudentContactList: state.school.schoolContactlist,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDG,
      getAllDG,
      getAllContacts,
      getContactListCount,
      resetContactPaginatedData,
      getDGDetails,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      fetchJobRoles,
      fetchAllEmployees,
      fetchJobTitles,
      fetchCustomerContacts,
      fetchSupplierContacts,
      fetchSubscribers,
      getClassList,
      getSection,
      getSchoolStudents,
      countSchoolStudents,
      resetStudentSchoolList,
      getSchoolStudentsContact
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DistributionGroup);
