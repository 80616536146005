import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {  showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import { fetchCategory } from "../../../drawer/inventory/action"; 
import { updateOrCreateEcomSettings } from "../../menus/action";  
import { fetchE_StoreData } from "../../../../actions/commonActions";
import { postPagePolicyData } from "../action";
import Enquiry from "../../../../components/eStore/page/Enquiry";

class PageCreateComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
      pageTitle :"Edit page",
      buttonOne:"Update",
      buttonTwo:"Preview",

    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
   
    };
  
    this.props.fetchE_StoreData(payload);
  
  }

  render() {
     
    return (
      <Enquiry
        {...this.props }
        {...this.state}
        
      ></Enquiry>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    schoolList: state.school.schoolList,
    storeValues: state.common.store_values,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  fetchCategory,
  hideModal,
  pushModalToStack,
   postPagePolicyData,
  popModalFromStack,
  fetchE_StoreData,
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageCreateComp);
