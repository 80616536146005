import React from "react";
import { ICONS } from "../../../static/constants";
import { FormattedMessage, injectIntl } from "react-intl";
import { Switch, Menu, Button, Pagination, Skeleton ,Empty} from "antd";
import DropdownAction from "antd/lib/dropdown";
import { fetchPaginationDataIfNeeded } from "../../../utils";

function DepartmentComp(props) {
    const { companyInfo, departmentList, pageNumber, pageSize } = props;
    const actionMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
                <Menu.Item key="0" value="title">
                    Actions
                </Menu.Item>
                <Menu.Divider />

                <Menu.Item
                    key="1"
                    value="edit"
                    onClick={() => {
                        props.updateState({
                            departmentDrawer: true,
                            departmentItem: e,
                        })
                    }}
                >
                    <i className={ICONS.EDIT} /> Edit
                </Menu.Item>

                <Menu.Item
                    key="2"
                    value="delete"
                    onClick={() => {
                        props.showModal({
                            modalBody: "Are you sure want to delete?",
                            handleSubmit: () => {
                                props.deletedepartment(e, props)
                            },
                        });
                    }}
                >
                    <i className={ICONS.DELETE} /> Delete
                </Menu.Item>
            </Menu>
        );
    };
    const showTotal = (total) => {
        return (
            props.intl.formatMessage({
                id: "pagination.text.total",
                defaultMessage: "",
            })
            +
            ` ${total} ` +
            props.intl.formatMessage({
                id: "pagination.text.items",
                defaultMessage: "",
            })
        );
    };
    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        fetchPaginationDataIfNeeded('getDepartment', 'departmentList', props, payload);

    };

    const handlePageSizeChange = (pageNumber, pageSize) => {

        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            pageNumber: pageNumber,
            pageSize: pageSize,
        };
        // props.resetPaginatedData();
        props.getDepartment(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    };
    return (
        <>
            <div className="view-container">
                <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage
                                id="employeeMeta.department.pageHeading"
                                defaultMessage="" />
                            <div className="table-sub-heading">
                                Manage department meta here
                            </div>
                        </div>
                        <div className="vertical-sep" />
                        <div>{props.departmentListCount}</div>
                    </div>

                    <div className="right-actions">
                        <Button
                            style={{ backgroundColor: "#1cb961", color: "#ffffff" }}
                            className="create-action-btn"
                            onClick={() => {
                                props.updateState({ departmentDrawer: true, departmentItem: {} });
                            }}
                        >
                            <i className={ICONS.ADD} style={{ margin: "4px 5px 0px 0px" }} />{" "}
                            <FormattedMessage id="button.create.label" defaultMessage="" />
                        </Button>
                    </div>
                    </Skeleton>
                </div>
                    <div className="table-container">
                <Skeleton loading={props.listLoading} paragraph={{ rows: 18 }} active>
                        <table id="department-table">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="serial.no" defaultMessage=" " />
                                    </th>
                                    <th>
                                        Department
                                    </th>

                                    <th>
                                        Department Code
                                    </th>

                                    <th>
                                        Status
                                    </th>
                                    <th width="6%">Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {(departmentList?.[pageNumber] || []).length > 0
                                    ? departmentList[pageNumber]
                                        // ?.sort((a, b) => (a.departmentId < b.departmentId ? 1 : -1))
                                        .map((rowData, i) => {

                                            return (
                                                <tr key={i}>
                                                    <td>{(pageNumber - 1) * pageSize + (i + 1)}</td>
                                                    <td>{rowData.departmentName || "-"}</td>
                                                    <td>{rowData.departmentCode || "-"}</td>
                                                    <td>
                                                        <div className="status-toggle">
                                                      
                                                                <Switch
                                                            checked={rowData.status === 1 ? true : false}
                                                            onClick={(checked, e) => {
                                                                props.setDepartmentStatus(
                                                                    {
                                                                        departmentId: rowData.departmentId,
                                                                        relationshipId: rowData.relationshipId,
                                                                        status: checked ? 1 : 0
                                                                    },props

                                                                );
                                                            }}
                                                        />
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <DropdownAction
                                                            overlay={actionMenu(rowData)}
                                                            trigger={["click"]}
                                                        >
                                                            <i className={ICONS.MORE} />
                                                        </DropdownAction>
                                                    </td>
                                                </tr>
                                            );

                                        })
                                        :
                                        <tr key="empty-data-box">
                                            <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                                <Empty />
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                </Skeleton>
                    </div>
                <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={props.departmentListCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize}
                            showSizeChanger
                            pageSizeOptions={[10, 25, 50, 100, 200]}
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props);
                            }}

                        />
                    </div>
                    </Skeleton>
                </div>
            </div>
        </>
    )
}
export default injectIntl(DepartmentComp);
