import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Pagination, Tooltip } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from "../../../utils";
import { CONSTANTS } from '../../../static/constants';
// import checkIcon from '../../../assets/images/check.png';
// import crossIcon from '../../../assets/images/cross.png';
import * as find from 'lodash.find';

const Sponsorships = (props) => {
  const { sponsorshipList, updateState, companyInfo, sponsorshipListCount, pageNumber,pageSize } = props;


  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };
  
  const getAddressInfoFragment = (locationId) => {
    if(!locationId) return '';
    
    let obj = find(companyInfo.boLocationList || [], { locationId: locationId }) || {};
    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <div className='billing-address'>{obj.streetAddress1}
                </div>
                : ''
        }
        {
            obj.streetAddress2
                ? <div className='billing-address'>{obj.streetAddress2}
                </div>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
      

    </Fragment>

    return fragmentObj;
  }

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getSponsorships", "sponsorshipList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getSponsorships(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const deleteHandle = (sponsorshipMaster) => {
    const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.deleteSponsorship(sponsorshipMaster, props);
      },
    };
    props.showModal(modalData);
  }

 
  const openSponsorshipcreate = () => {
    props.history.push({
      pathname: "/admin/sponsorship-create",
      state: {
        type: "new",
      },
    });
  };

  const openSponsorshipUpdate = (data) => {
    props.history.push({
      pathname: "/admin/sponsorship-create",
      state: {
        type: "update",
        sponsorshipData : {...data}
      },
    });
  };

  return (
    <Fragment>
      <div className="view-container sales-order-details mt0">
        <div className='team-and-free'>
          <div className="right-align pb10">
            <div className="action-dropdown-btn">
              <button onClick={() => {
                openSponsorshipcreate();
              }}>
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
          </div>
          <div className='new-table-camp'>
            <table>
              <tbody>
                <tr>
                  <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                  <th><FormattedMessage id="sponsorship.listing.name" defaultMessage="" /></th>
                  <th><FormattedMessage id='sponsorship.listing.location' defaultMessage='' /></th>
                  <th><FormattedMessage id="sponsorship.listing.startDate" defaultMessage="" /></th>
                  <th><FormattedMessage id="sponsorship.listing.endDate" defaultMessage="" /></th>
                  <th><FormattedMessage id="ticket.text" defaultMessage="" /></th>
                  {/*<th><FormattedMessage id="fundraising.pledge.listing.header.status" defaultMessage="" />
                   <Tooltip placement="top" title={props.intl.formatMessage({ id: 'pladgeStatus.helpMessage' })} trigger="click">
                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                  </Tooltip> </th> */}
                  <th width={'8%'}> <FormattedMessage id="common.listing.header.action" defaultMessage="" /> </th>
                </tr>
                {(sponsorshipList[pageNumber] || []).map((e, i) => {
                  return <tr key={i}>
                    <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                    <td>
                      <div>
                        <div> {e.name || ''}</div>
                        <div>{e.description || ''}</div>
                      </div>
                    </td>
                    <td>{getAddressInfoFragment(e.locationId)}</td>
                    <td>
                      <div>
                        <Tooltip placement="topLeft" title={(e.saleStartDate && getMomentDateForUIReadOnly({ date: e.saleStartDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                          {(e.saleStartDate && getMomentDateForUIReadOnly({ date: e.saleStartDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                        </Tooltip>
                      </div>
                    </td>
                    <td>
                      <div>
                        <Tooltip placement="topLeft" title={(e.saleEndDate && getMomentDateForUIReadOnly({ date: e.saleEndDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                          {(e.saleEndDate && getMomentDateForUIReadOnly({ date: e.saleEndDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                        </Tooltip>
                      </div>
                    </td>
                    <td> {e.ticketItemDetailsList ?  e.ticketItemDetailsList.length : 0}</td>
                   
                    <td>
                      <div>
                        <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage(
                          {
                            id: 'commons.listing.edit.tooltip.msg',
                            defaultMessage: ''
                          })
                        } onClick={
                          () => {
                            openSponsorshipUpdate(e);
                            // props.updateHeaderState({
                            //   fundraising: {
                            //     ...props.fundraising,
                            //     pledgeRowData: { ...e },
                            //     addPledgeDrawerVisible: true,
                            //     openPledgeDrawerFromListing: true,
                            //   }
                            // })
                          }}>
                          <i className='fa fa-pencil' />
                        </span>

                        <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage(
                          {
                            id: 'commons.listing.delete.tooltip.msg',
                            defaultMessage: ''
                          })
                        } onClick={
                          () => {
                            deleteHandle(e);
                          }}>
                          <i className='fa fa-trash' />
                        </span>

                      </div>

                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
         

          <Pagination
            size="small"
            total={sponsorshipListCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(Sponsorships);
