import React from 'react';
//import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {Row, Col, Carousel} from 'antd';
//import { Checkbox } from 'antd';
import { FormattedMessage, injectIntl  } from 'react-intl';
import { TextBox } from '../general/TextBox';
//import { CustomButton } from '../general/CustomButton';
//import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'
import { STORAGE_CONSTANTS } from './../../static/constants';
import { ErrorMsg } from '../general/ErrorMessage';
import { validateEmail, addToLocalMemory, deleteFromLocalMemory } from '../../utils';
import './login.scss';
import LoginIcon from '../../assets/images/logon.png';
import config from '../../config/environmentConfig';
import cardPic from '../../assets/images/credit-card.png';
import SeCurity from '../../assets/images/security.png';
import EasyUse from '../../assets/images/easy-use.png';
import Trkas from '../../assets/images/trkas.png';
import SignUp from '../../assets/images/signup.png';
import OperaTion from '../../assets/images/operation.png';
import GeroWup from '../../assets/images/gerowup.png';
import Mlot from '../../assets/images/mlot.png';
import Koamt from '../../assets/images/koamt.png';
import KioskIcons from '../../assets/images/kioskicons.png';
import DonaTions from '../../assets/images/donations.png';
import PleDge from '../../assets/images/pledge.png';
import Invitation from '../../assets/images/invitation.png';
import IocnInver from '../../assets/images/iocn-inver.png';
import RecurRing from '../../assets/images/recurring.png';
import Reminders from '../../assets/images/reminders.png';
import TracKings from '../../assets/images/trackings.png';



function LoginComponent(props) {
  const {
    intl, updateState, username, usernameInvalidClass, password, 
   // passwordClass,  rememberMe, usernameClass, 
  } = props;

  // const contentStyle = {
  //   height: '500px',
  //   width: '600px',
  //   color:'#fff',
  //   fontSize:'40px',
  //   margin: '0px auto 0px',
  //   textAlign: 'left',
  //   borderBottom:'1px solid #fff',
  // }; 
  // const [showDetail,setShowDetail] = useState(true);

  // const handleToggle = () => {
  //   setShowDetail(!showDetail);
  // };

  return (
    <div className='login-wrapper'>
     <div className="login-page">
<div className="login-box"> 
    <div className="left-side">
        <div className="login-logo">
           <a href="https://alifapp.com/" target="_blank" rel="noopener noreferrer"> <img src={LoginIcon} alt="" /></a>
        </div>

        <Row>
            <Col span={24}>
                <div className="login"><h2> Welcome 
                back </h2></div>
            </Col>
            <Col span={24} className="login">
              <div className="link">Sign in to your account</div>
            </Col>

            <Col span={24}>
                <div className="login-fed-box">
                    {/* <input className="login-feds" type="text" placeholder="Name" /> */}
                    <TextBox
                      placeholder={intl.formatMessage(
                          { id: 'login.email.placeholder', defaultMessage: '' }
                      )}
                      type='text'
                      value={username}
                      className={'login-feds'}
                      invalidClassName={usernameInvalidClass || 'login-form-input-error'}
                      validator={(val) => { return validateEmail(val); }}
                      onChange={(e) => {
                        updateState({
                          username: e.target.value,
                          usernameClass: 'login-form-input'
                        });
                      }}
                  />
                <ErrorMsg
                  validator={() => { return !props.submittedOnce  || !username || validateEmail(username) }}
                  message={<FormattedMessage id='login.email.invalidEmail' defaultMessage='' />}
                />
                <ErrorMsg
                  validator={() => { return !props.submittedOnce  || !!username }}
                  message={<FormattedMessage id='login.email.requiredEmail' defaultMessage='' />}
                />
                    {/* <input className="login-feds" type="password" placeholder="Password" /> */}
                    <TextBox
                    placeholder={intl.formatMessage(
                        { id: 'login.password.placeholder', defaultMessage: '' }
                    )}
                    type='password'
                    className={'login-feds'}
                    invalidClassName={usernameInvalidClass || 'login-form-input-error'}
                    value={password}
                    validator={(val) => { return !!val }}
                    onChange={(e) => {
                      updateState({
                        password: e.target.value,
                        passwordClass: 'login-form-input'
                      });
                    }}
                />
                <ErrorMsg
                  validator={() => { return !props.submittedOnce  || !!password }}
                  message={<FormattedMessage id='login.email.requiredPassword' defaultMessage='' />}
                />
                </div>
            </Col>
            <Col span={12} className="col-lg-6 top-add"> 
                <button className="login-sub" onClick={() => {
                  handleLogIn(props);
                }}>Sign in</button>
            </Col>
            <Col span={12} className="col-lg-6 top-add">
                <div className="test-fortg"><a href="/app/forgotpassword">Forgot your password ?</a> </div>
            </Col>
        </Row>
    </div>

    <div className="right-side"> 
    <Carousel autoplay style={{ height:'500px',  width:'600px',  color:'#fff' }} >
              <div>
                <div>  <h3>Payment Kiosk </h3> 
                 <div className='clearfix'></div>
                 <span style={{ fontSize:'16px', color:'#a4a4a4'}}> Payment Kiosk can be used to collect donations by category</span>
                 <div className='line-box5'></div>

                <h4><span> <img src={cardPic} alt="" /></span> Increase Cash Flow </h4>
                <h4> <span> <img src={SeCurity} alt="" /></span>Inbuilt Security </h4>
                <h4><span> <img src={EasyUse} alt="" /></span> Ease Of use </h4> 
                <h4><span> <img src={Trkas} alt="" /></span> Tracking </h4></div>
              </div>
              <div>
                <div>  <h3>Membership Management</h3> 
                 <div className='clearfix'></div>
                 {/* <span style={{ fontSize:'16px', color:'#a4a4a4'}}> Payment Kiosk can be used to collect donations by category</span> */}
                 <div className='line-box5'></div>

                <h4><span> <img src={SignUp} alt="" /></span> Allow Members to Sign Up on Your Website </h4>
                <h4> <span> <img src={OperaTion} alt="" /></span>Automate Payments and Renewals  </h4>
                <h4><span> <img src={GeroWup} alt="" /></span> Grow Your Membership</h4> 
                <h4><span> <img src={Trkas} alt="" /></span> Keep Track of Your Members’ Activities  </h4></div>
              </div>
               <div>
                <div>  <h3>Fundraising Campaigns </h3> 
                 <div className='clearfix'></div>
                 {/* <span style={{ fontSize:'16px', color:'#a4a4a4'}}> Payment Kiosk can be used to collect donations by category</span> */}
                 <div className='line-box5'></div>

                <h4><span> <img src={Mlot} alt="" /></span> Flexible and Easy-to-Use Fundraising Campaigns </h4>
                <h4> <span> <img src={Koamt} alt="" /></span>Seamlessly Create an Eye-Catching Fundraising Donation Form </h4>
                <h4><span> <img src={KioskIcons} alt="" /></span> Collect Fundraising Donations on Kiosk  </h4> 
                <h4><span> <img src={DonaTions} alt="" /></span> Collect Fundraising Donations on Your Website  </h4>
                <h4><span> <img src={PleDge} alt="" /></span>  Pledge Management   </h4>
                </div>

               
              </div>
              <div>
                <div>  <h3>Automate Sunday School Process </h3> 
                 <div className='clearfix'></div>
                 <span style={{ fontSize:'16px', color:'#a4a4a4'}}> Managing Sunday School is a tedious and time-consuming process</span>
                 <div className='line-box5'></div>

                <h4><span> <img src={Invitation} alt="" /></span> Invite Parents for Online Enrollment </h4>
                <h4> <span> <img src={IocnInver} alt="" /></span>Generate Invoice </h4>
                <h4><span> <img src={RecurRing} alt="" /></span> Recurring Invoice & Recurring Payment </h4> 
                <h4><span> <img src={Reminders} alt="" /></span> Setup Reminder for Invoice Payment</h4>
                <h4><span> <img src={TracKings} alt="" /></span> Track Past Due Invoice</h4>
                </div>
              </div> 
            </Carousel>
            <div className="login-subs">
              {props.source === 'alifApp' ?
                <a href='https://alifapp.com/#Pricing' rel="noopener noreferrer">
                  <FormattedMessage id='login.text-signup' defaultMessage='' />
                </a>
                :
                <a href={config.UI_BASE_URL === 'https://uat.alpide.com/app/' ? 'https://uat.alpide.com/price-table.html' : config.UI_BASE_URL === 'https://alpide.com/app/' ? 'https://alpide.com/price-table.html' : '/app/pricing'} rel="noopener noreferrer">
                  <FormattedMessage id='login.text-signup' defaultMessage='' />
                </a>
              }
            </div>
    </div>

</div>


  <div style={{ textAlign:'center', position:'fixed', bottom:'10px', color:'#fff', width:'100%' }}>
    Copyright © 2021 Alif App, All Rights Reserved  
  </div> 
    </div>
    
    </div>
  );
}

const handleLogIn = (payload) => {
  payload.updateState({submittedOnce: true});
  if (payload.username && payload.password && validateEmail(payload.username)) {
    if (payload.rememberMe) {
      addToLocalMemory(STORAGE_CONSTANTS.LOGIN_EMAIL, payload.username);
      addToLocalMemory(STORAGE_CONSTANTS.LOGIN_PASSWORD, payload.password);
      addToLocalMemory(STORAGE_CONSTANTS.REMEMBER_ME, payload.password);
    } else {
      deleteFromLocalMemory(STORAGE_CONSTANTS.LOGIN_EMAIL);
      deleteFromLocalMemory(STORAGE_CONSTANTS.LOGIN_PASSWORD);
      deleteFromLocalMemory(STORAGE_CONSTANTS.REMEMBER_ME);
    }
    payload.verifyCredentials({ email: payload.username, password: payload.password }, payload.history, payload);
  } else {
    if (!validateEmail(payload.username)) {
      payload.updateState({
        usernameClass: 'login-form-input-error input-error',
        usernameInvalidClass: 'login-form-input-error input-error'
      });
    } else {
      payload.updateState({ usernameClass: 'login-form-input' });
    }
    if (!payload.password) {
      payload.updateState({ passwordClass: 'input-error' });
    }
  }
}

export default injectIntl(LoginComponent);
