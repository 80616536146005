import React, { Fragment } from 'react';
// import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col,  DatePicker, Checkbox, Tooltip, Skeleton } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { Dropdown } from '../../../general/Dropdown';
// import config from '../../../../config/environmentConfig';
import { MultipleSelectDropdown } from '../../../general/MultipleSelectDropdown';
import { TextBox } from '../../../general/TextBox';
import { CONSTANTS, MODAL_TYPE, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY } from '../../../../static/constants'
import { addDaysToDate, getMomentDateForUI } from './../../../../utils';
import { showToasterMessage } from '../../../../utils';
// import LoadingIcon from '../../../../assets/images/loading.gif';
// import { LogoComponent } from '../../../general/LogoComponent';
import { QuestionCircleTwoTone ,EditOutlined} from '@ant-design/icons';

let companyInfo = {};
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class InvoiceDetails extends React.Component {

    constructor(props) {
        super();
        this.state = {
            size: 0
        }
        companyInfo = props.companyInfo;
    }

    handleSizeChange = e => {
        this.setState({ size: e.target.value });
    };

    getDueDateChange = () => {
        let selectedterm = this.props.paymentTerms.length ? find(this.props.paymentTerms, { paymentTermId: ((this.props.customer && this.props.customer.paymentTermId) || 0) }) || {} : {};
        if (selectedterm.numberOfDays) {
            const creditMemoExpDate = addDaysToDate((this.props.creditMemoDate || new Date()), selectedterm.numberOfDays);
            return creditMemoExpDate;
        }
        return new Date();
    }

    handleNewTaxIdentificationAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = function (taxIdentification) {
            if (props.customer && props.customer.customerTaxIdentificationsList) {
                let list = props.customer.customerTaxIdentificationsList || [];
                list.push(taxIdentification)
                props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
            }
        }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewContactAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        formData.callback = (contact) => {
            props.hideModal(data);
            if (contact.customerId) {
                props.fetchAllContacts({
                    customerId: contact.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
        }
        const data = {
            title: <div>
                <FormattedMessage id='addItem.text.contact' defaultMessage='' />
                <div style={{ 'font-size': '12px' }}> {formData.customerName || ''}</div>
            </div>,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.CONTACT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    handleNewAddressAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        if (modalType !== MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS && (!formData || !formData.customerId)) {
            return showToasterMessage({ messageType: 'error', description: 'Please Select Customer' });
        }
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
            payload.formData = { addressType:'relationship', locationType: 'RelationshipBillingAddress' };
        }
        payload.formData.callback = function (address) {
            props.hideModal(data);
            if (address.locationType === 'BillingAddress' || address.locationType === 'ShippingAddress') {
                props.fetchAddresses({
                    customerId: address.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    locationType: address.locationType
                });
            } else {
                props.getRelationshipAddress({
                    relationshipId: (props.companyInfo || {}).relationshipId
                });
            }
        }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            keyboard:false,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
            }
        };
        showModal(data);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.BILLING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />;
                <h6>{this.props.customerName || ''} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.SHIPPING_ADDRESS: {
                title = <div><FormattedMessage id='addItem.text.shippingAddress' defaultMessage='' />;
                <h6>{this.props.customerName || ''} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                title = <div>
                    <FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <h6>{(this.props.companyInfo || {}).storeName} </h6>
                </div>
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                title = <FormattedMessage id='addItem.text.documentName' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                title = <div>
                    <FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />
                </div>
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = this.getModalTitle(null, modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
    </span>;

        let addFunc;
        let payload = {
            text: text,
            relationshipId: companyInfo.relationshipId
        }

        switch (modalType) {
            case MODAL_TYPE.DOCUMENT_NAME: {
                addFunc = props.addDocumentName;
                payload.txType = CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.CREDIT_MEMO];
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc(payload)
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;
        let callback = function (data) {
            if (modalType === MODAL_TYPE.CONTACT && props.customer.customerId) {
                props.fetchAllContacts({
                    customerId: props.customer.customerId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                });
            }
            if(modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS){
                props.getRelationshipAddress({
                  relationshipId: (props.companyInfo || {}).relationshipId
                });
              }
        }
        switch (modalType) {
            case MODAL_TYPE.BILLING_ADDRESS:
            case MODAL_TYPE.SHIPPING_ADDRESS:
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                deleteFunc = props.deleteAddress;
                break;
            }
            case MODAL_TYPE.DOCUMENT_NAME: {
                deleteFunc = props.deleteDocumentName;
                break;
            }
            case MODAL_TYPE.CONTACT: {
                deleteFunc = props.deleteContact;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
                deleteFunc = props.deleteRelationshipTaxIdentifications;
                break;
            }
            case MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER: {
                deleteFunc = props.deleteCustomerTaxIdentifications;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId,customerId: (props.customer || {}).customerId, callback });
                if (modalType === MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER && props.customer && props.customer.customerTaxIdentificationsList) {
                    let list = (props.customer.customerTaxIdentificationsList || []).filter(x => x.customerTaxIdentificationId !== payload.id);
                    props.updateState({ customer: { ...props.customer, customerTaxIdentificationsList: list } })
                }
                if (modalType === MODAL_TYPE.DOCUMENT_NAME && payload.id === this.props.documentNameId) {
                    const selectedObj = find((this.props.documentList || []), { txType: CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.CREDIT_MEMO], isDefault: 1 }) || {};
                    this.props.updateState({
                        documentNameId: selectedObj.documentNameId,
                        docName: selectedObj.docName
                    });
                }
            },
        };
        showModal(modalData);
    }


    render() {
        const { size } = this.state;
        const { intl } = this.props;

        return (
            <Fragment>
                <Row>
                    <Col span={5}>
                    <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

                        <Row gutter={[0,3]}>
                         {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + companyInfo.logoAwsObjectKey + "?" + Date.now()}/> */}
                            {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + companyInfo.logoAwsObjectKey + "?" + Date.now()} /> */}
                            <Col span={24} className='details-heading'>
                                Organization Information
                            </Col>
                          
                            <Col span={24}>
                                <div className='details-heading-subheading'>

                                <FormattedMessage id="sales.order.create.organization.name" defaultMessage=''/>
                                </div>
                            <div className='deatils-field-value'>

                                {companyInfo.storeName}
                            </div>
                            </Col>
                            <Col span={24}>
                <div className='details-heading-subheading'>

                <FormattedMessage id="sales.order.create.organization.adress" defaultMessage=''/>
                <Tooltip placement="right" title={this.props.intl.formatMessage(
                    {
                      id: 'tooltip.companyBillingAddress',
                      defaultMessage: ''
                    }
                  )} trigger="click">
                    <QuestionCircleTwoTone />
                  </Tooltip>
                </div>
                <span>{
                  !this.props.addressHide && this.props.relationshipBillingAddress ?
                  <>
                  
                  <div className='detail-address-value'>

                     <div style={{height:'96px',paddingTop:'10px'}}>
                     {
                                    getAddressInfoFragment(this.props.relationshipBillingAddress || {}, {}, this.props.companyInfo)
                     }
                     </div>
                     <div onClick={()=>{
                        
                        this.props.updateState({
                          addressHide: true,
                        })
                      }}>
                        <EditOutlined />
                     </div>
                  </div>
                  </> 
                  : 
                  <Dropdown
                                    style={{ width: '100%'}}
                                    items={this.props.relationshipBillingAddresses || []}
                                    value={(this.props.relationshipBillingAddress || {}).streetAddress1}
                                    canAddNew={true}
                                    canDelete={true}
                                    deleteClickHandler={(payload) => {
                                        this.deleteClickHandler(this.props, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS, payload);
                                    }}
                                    onAddNewClick={(payload) => {
                                        payload.formData = {
                                            locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS
                                        }
                                        this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS)
                                    }}
                                    valueKeyName='streetAddress1'
                                    addressLineKey1='streetAddress2'
                                    addressLineKey2='cityName'
                                    addressLineKey3='stateName'
                                    addressLineKey4='zipCode'
                                    isFavouriteKeyName='isDefault'
                                    optionClassAddress='custom-address-dropdown'
                                    optionKeyName='locationId'
                                    onSelect={(selectedValue, optionObj) => {
                                        this.props.updateState({ 
                                            relationshipBillingAddress: (this.props.relationshipBillingAddresses || []).find(x => +x.locationId === +selectedValue),
                                            addressHide:false 
                                        })
                                        
                                    }}
                                    allowClear={true}
                                    onClear={() => {
                                        this.props.updateState({
                                            relationshipBillingAddress: undefined,
                                        })
                                    }}
                                />
                  }
                 
                </span>

                            </Col>
                                
                            <Col span={24}>
                                <div className='details-heading-subheading'>

                                <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                                <Tooltip placement="right" title={this.props.intl.formatMessage(
                                {
                                    id: 'tooltip.taIdentification',
                                    defaultMessage: ''
                                }
                                )} trigger="click">
                                <QuestionCircleTwoTone />
                                </Tooltip>
                                </div>
                                
                                <MultipleSelectDropdown
                                                    style={{ width: '100%'}}
                                                    items={this.props.allRelationshipTaxIdentificatins || []}
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: 'relationshiptaxIdentification.placeholder',
                                                            defaultMessage: ''
                                                        })
                                                    }
                                                    mode='multiple'
                                                    onDeSelect={(value) => {
                                                        let { selectedRelationshipTaxIdentifications } = this.props
                                                        selectedRelationshipTaxIdentifications = filter(selectedRelationshipTaxIdentifications, (taxId) => {
                                                            return taxId !== value;
                                                        });
                                                        this.props.updateState({ selectedRelationshipTaxIdentifications });
                                                    }}
                                                    valueKeyName='taxIdentificationType'
                                                    valueKeyName2='taxIdentificationNumber'
                                                    selectedValue={(this.props.allRelationshipTaxIdentificatins || []).length ? (this.props.selectedRelationshipTaxIdentifications || []) : []}
                                                    optionKeyName='relationshipTaxIdentificationId'
                                                    onSelect={(relationshipTaxIdentificationId) => {
                                                        let selectedRelationshipTaxIdentifications = this.props.selectedRelationshipTaxIdentifications || [];
                                                        if (selectedRelationshipTaxIdentifications.indexOf(relationshipTaxIdentificationId) === -1) {
                                                            selectedRelationshipTaxIdentifications.push(relationshipTaxIdentificationId);
                                                        } else {
                                                            selectedRelationshipTaxIdentifications = selectedRelationshipTaxIdentifications.filter((el) => {
                                                                return el !== selectedRelationshipTaxIdentifications
                                                            });
                                                        }
                                                        this.props.updateState({
                                                            selectedRelationshipTaxIdentifications
                                                        });
                                                    }}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            taxIdentificationNumber: payload.textEntered,
                                                            submittedOnce: false,
                                                        }
                                                        this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                                    }}
                                                />

                            </Col>          
                        </Row>
                        </Skeleton>
                    </Col>
                    
                    <Col span={1}></Col>
                    
                    <Col span={5}>
                    <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>

                        <Row gutter={[0,3]}>
                        <Col span={24} className='details-heading'>
                         {/* Organization */}
                         Customer Information
                        </Col>   

                        {
                            this.props.customerName
                                ?
                                <>
                                  
                                        <Col span={24} >
                                            <div className="details-heading-subheading">
                                                <FormattedMessage id='modal.customer.payInvoice.customerName' defaultMessage='' />
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="deatils-field-value">
                                                {((this.props.customer || {}).companyName || '')}
                                            </div>
                                        </Col>
                                    
                                 
                                        <Col span={24} >
                                            <div className="details-heading-subheading">
                                                <FormattedMessage id='contact' defaultMessage='' />
                                            <Tooltip placement="right" title={intl.formatMessage(
                                                { id: 'tooltip.customerContact', defaultMessage: '' }
                                                )} trigger="click">
                                                <QuestionCircleTwoTone />         
                                         </Tooltip>
                                                </div>
                                        </Col>
                                        <Col span={24}>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                value={((this.props.selectedContact || {}).firstName || "") + " " + ((this.props.selectedContact || {}).lastName || "")}
                                                items={this.props.boContactList || []}
                                                valueKeyName='firstName'
                                                lastNameKey='lastName'
                                                optionKeyName='contactId'
                                                isFavouriteKeyName='isPrimaryContact'
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id: 'contact.placeholder',
                                                        defaultMessage: ''
                                                    })
                                                }
                                                canAddNew={true}
                                                canDelete={true}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.CONTACT, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        firstName: payload.textEntered,
                                                        submittedOnce: false,
                                                        customerId: (this.props.customer || {}).customerId,
                                                        pageNumber: this.props.pageNumber || 1,
                                                        customerName: this.props.customerName,
                                                    }
                                                    this.handleNewContactAddition(this.props, payload, MODAL_TYPE.CONTACT)
                                                }}
                                                onSelect={(contactId) => {
                                                    let contact = find((this.props.boContactList || []), { contactId: +contactId }) || {};
                                                    this.props.updateState({
                                                        selectedContact: contact
                                                    })
                                                }}
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({
                                                        selectedContact: undefined,
                                                    })
                                                }}
                                            />
                                            
                                        </Col>
                                    
                                    
                                        <Col span={24} >
                                            <div className="title">
                                                <FormattedMessage id='billTo' defaultMessage='' />
                                                <Tooltip placement="right" title={intl.formatMessage(
                                                { id: 'tooltip.customerAddress', defaultMessage: '' }
                                            )} trigger="click">
                                              <QuestionCircleTwoTone />
                                              </Tooltip>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <Dropdown
                                                style={{ width: '100%' }}
                                                items={this.props.allBillingAddress || []}
                                                value={(this.props.billingAddress || {}).streetAddress1}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id: 'billTo.placeholder',
                                                        defaultMessage: ''
                                                    })
                                                }
                                                canAddNew={true}
                                                canDelete={true}
                                                deleteClickHandler={(payload) => {
                                                    this.deleteClickHandler(this.props, MODAL_TYPE.BILLING_ADDRESS, payload);
                                                }}
                                                onAddNewClick={(payload) => {
                                                    payload.formData = {
                                                        locationType: CONSTANTS.BILLING_ADDRESS,
                                                        customerId: (this.props.customer || {}).customerId,
                                                        pageNumber: this.props.pageNumber,
                                                    }
                                                    this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.BILLING_ADDRESS)
                                                }}
                                                valueKeyName='streetAddress1'
                                                addressLineKey1='streetAddress2'
                                                addressLineKey2='cityName'
                                                addressLineKey3='stateName'
                                                addressLineKey4='zipCode'
                                                optionClassAddress='custom-address-dropdown'
                                                optionKeyName='locationId'
                                                isFavouriteKeyName='isDefault'
                                                onSelect={(selectedValue, optionObj) => {
                                                    this.props.updateState({ billingAddress: (this.props.allBillingAddress || []).find(x => +x.locationId === +optionObj.key) })
                                                }}
                                                allowClear={true}
                                                onClear={() => {
                                                    this.props.updateState({
                                                        billingAddress: undefined,
                                                    })
                                                }}
                                            />
                                            
                                            {/* {
                                                getAddressInfoFragment((this.props.billingAddress || {}), (this.props.selectedContact || {}), (this.props.customer || {}))
                                            } */}
                                        </Col>
                                    
                                   
                                     
                                        <Col   span={24}>
                                            <div className='details-heading-subheading'>

                                            <FormattedMessage id='customer.form.taxIdentification.label' defaultMessage='' />
                                            <Tooltip placement="right" title={this.props.intl.formatMessage(
                                            {
                                                id: 'taxIdentification.tooltip',
                                                defaultMessage: ''
                                            }
                                            )} trigger="click">
                                            <QuestionCircleTwoTone />
                                            </Tooltip>
                                            </div>
                                        </Col>
                                            <Col span={24}>
                                                <MultipleSelectDropdown
                                                    style={{ width: '100%' }}
                                                    items={this.props.customer.customerTaxIdentificationsList || []}
                                                    placeholder={intl.formatMessage(
                                                        {
                                                            id: 'taxIdentification.placeholder',
                                                            defaultMessage: ''
                                                        })
                                                    }
                                                    mode='multiple'
                                                    onDeSelect={(value) => {
                                                        let { selectedCustomerTaxIdentifications } = this.props
                                                        selectedCustomerTaxIdentifications = filter(selectedCustomerTaxIdentifications, (taxId) => {
                                                            return taxId !== value;
                                                        });
                                                        this.props.updateState({ selectedCustomerTaxIdentifications });
                                                    }}
                                                    valueKeyName='taxIdentificationType'
                                                    valueKeyName2='taxIdentificationNumber'
                                                    selectedValue={(this.props.customer.customerTaxIdentificationsList || []).length ? (this.props.selectedCustomerTaxIdentifications || []) : []}
                                                    optionKeyName='customerTaxIdentificationId'
                                                    onSelect={(customerTaxIdentificationId) => {
                                                        let selectedCustomerTaxIdentifications = this.props.selectedCustomerTaxIdentifications || [];
                                                        if (selectedCustomerTaxIdentifications.indexOf(customerTaxIdentificationId) === -1) {
                                                            selectedCustomerTaxIdentifications.push(customerTaxIdentificationId);
                                                        } else {
                                                            selectedCustomerTaxIdentifications = selectedCustomerTaxIdentifications.filter((el) => {
                                                                return el !== customerTaxIdentificationId
                                                            });
                                                        }
                                                        this.props.updateState({
                                                            selectedCustomerTaxIdentifications
                                                        });
                                                    }}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX_IDENTIFICATION_CUSTOMER, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            firstName: payload.textEntered,
                                                            submittedOnce: false,
                                                            customerId: (this.props.customer || {}).customerId,
                                                            pageNumber: this.props.pageNumber || 1,
                                                            customerName: this.props.customerName
                                                        }
                                                        this.handleNewTaxIdentificationAddition(this.props, payload, MODAL_TYPE.NEW_TAX_IDENTIFICATION)
                                                    }}
                                                />

                                            </Col>
                                        
                                 
                                </> 
                                : 'Please select customer for details'
                        }

                        </Row>
                    </Skeleton>
                    </Col>
                    
                    <Col span={8}></Col>

                    <Col span={5} className='order-value-info' >
                        
                        
                                {/* {
                  this.props.customer && this.props.customer.companyName &&
                  <div>
                    <Row>
                      <Col span={12}>
                        <div className="title">
                        <FormattedMessage id='customer.creditMemo.form.customerName.label' defaultMessage='' />
                                                </div>
                      </Col>
                      <Col span={12}>
                        <div className="value">
                          {this.props.customer.companyName}
                        </div>
                      </Col>
                    </Row>
                  </div>

                } */}
                               
                                    {/* <Row>
                                        <Col span={12} className='text-right'>
                                            <Tooltip placement="bottom" title={intl.formatMessage(
                                                { id: 'tooltip.generateCreditMemo', defaultMessage: '' }
                                            )}>
                                                <span className="tooltip-title">
                                                    <FormattedMessage id='customer.creditMemo.form.creditMemoNumber.label' defaultMessage='' />
                                                </span>
                                            </Tooltip>
                                        </Col>
                                        <Col span={12} style={{ 'text-align': 'left' }}>
                                            {this.props.nextTxNumber ?
                                                <span>{getFormatedNextTxNumber(this.props)}</span>
                                                :
                                                <img src={LoadingIcon} alt="Invoice" style={{ 'height': '74px', 'margin-top': '-25px', 'margin-bottom': '-25px' }} />
                                            }
                                        </Col>
                                    </Row> */}
                               
                        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>
                            <Row gutter={[0, 3]}>
                                <Col span={24} className="text-right">
                                    <div className="title">
                                        <FormattedMessage id='customer.creditMemo.form.creditMemoDate.label' defaultMessage='' />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="value">
                                        <DatePicker
                                            allowClear={false}
                                            format={format}
                                            key={`${getMomentDateForUI({ date: new Date(this.props.creditMemoDate), format })}`}
                                            defaultValue={getMomentDateForUI({ date: new Date(this.props.creditMemoDate), format })}
                                            onChange={(selectedDate) => {
                                                this.props.updateState({
                                                    creditMemoDate: selectedDate,
                                                    creditMemoExpDate: this.getDueDateChange()
                                                })
                                                // this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                                            }}
                                            size={size}
                                        />
                                    </div>
                                </Col>





                                <Col span={24} className="text-right">
                                    <div className="title">
                                        <FormattedMessage id='customer.creditMemo.form.creditMemoExpiry.label' defaultMessage='' />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="value">
                                        <DatePicker
                                            allowClear={false}
                                            format={format}
                                            disabled={true}
                                            value={getMomentDateForUI({ date: new Date(this.props.creditMemoExpDate), format })}
                                            size={size} />
                                    </div>
                                </Col>





                                <Col span={24} className="text-right">
                                    <Tooltip placement="bottom" >
                                        <span className="">
                                            <FormattedMessage id='customer.salesOrder.form.documentDropdown.label' defaultMessage='' />
                                        </span>
                                    </Tooltip>
                                    <Tooltip placement="right" title={intl.formatMessage(
                                        {
                                            id: 'documentTitle.tooltip',
                                            defaultMessage: ''
                                        })
                                    } trigger="click">
                                        <QuestionCircleTwoTone />
                                    </Tooltip>
                                </Col>
                                <Col span={24}>
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        items={filter((this.props.documentList || []), (document) => { return document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.CREDIT_MEMO]; })}
                                        placeholder='Document name'
                                        value={this.props.docName && this.props.documentNameId}
                                        canAddNew={true}
                                        canDelete={true}
                                        deleteClickHandler={(payload) => {
                                            this.deleteClickHandler(this.props, MODAL_TYPE.DOCUMENT_NAME, payload);
                                        }}
                                        onAddNewClick={(payload) => {
                                            payload.formData = {
                                                textBoxValue: payload.textEntered,
                                                submittedOnce: false,
                                                maxLength: 30
                                            }
                                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DOCUMENT_NAME)
                                        }}
                                        onSelect={(selectedValue) => {
                                            const selectedObj = find((this.props.documentList || []), { documentNameId: Number(selectedValue) });
                                            this.props.updateState({
                                                documentNameId: selectedValue,
                                                docName: selectedObj.docName
                                            })
                                        }}
                                        valueKeyName='docName'
                                        optionKeyName='documentNameId'
                                        isLockKeyName='isSystemGenerated'
                                    // allowClear={true}
                                    // onClear={() => {
                                    //     this.props.updateState({
                                    //         documentNameId: undefined,
                                    //         docName: undefined
                                    //     })
                                    // }}
                                    />
                                </Col>




                                <Col span={24} className='detail-column'>
                                    <div >
                                        <FormattedMessage id='supplier.purchaseRequest.form.refNumber.label' defaultMessage='' />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <TextBox
                                        placeholder={intl.formatMessage(
                                            { id: 'supplier.purchaseRequest.form.refNumber.placeholder', defaultMessage: '' }
                                        )}
                                        type='text'
                                        value={this.props.reference}
                                        onChange={(e) => {
                                            this.props.updateState({
                                                reference: e.target.value
                                            })
                                        }}
                                    />
                                </Col>





                                <Col span={24} className="text-right">
                                    <div className="title">
                                        <FormattedMessage id='customer.creditMemo.form.updateInventory.label' defaultMessage='' />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="value">
                                        <Checkbox checked={this.props.isUpdateInventory} onClick={() => {
                                            this.props.updateState({ isUpdateInventory: !this.props.isUpdateInventory })
                                        }} />
                                    </div>
                                </Col>

                            </Row>               
                      </Skeleton>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
const getAddressInfoFragment = (obj, customerContactObj, customerObj) => {
    // if (!obj || !Object.keys(obj).length) {
    //     return '';
    // }


    let contactName = "";
    if (customerContactObj.salutationName) contactName += customerContactObj.salutationName + ' ';
    if (customerContactObj.firstName) contactName += customerContactObj.firstName;
    if (customerContactObj.middleName) contactName += ' ' + customerContactObj.middleName;
    if (customerContactObj.lastName) contactName += ' ' + customerContactObj.lastName;
    const fragmentObj = <Fragment>
        <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (
                <div>
                    {obj.streetAddress1} {obj.streetAddress2}{", "}
                </div>
            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (
                <div>
                    {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>
            ) : ""}
            {obj.countryName ? (
                <div>{obj.countryName}</div>
            ) : ""}
        </div>
        {
            customerContactObj.firstName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        }
        {
            customerContactObj.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.phone.label' defaultMessage='' /> - {customerContactObj.workPhone}
                </div> :
                ''
        }
        {
            customerContactObj.email
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.email.label' defaultMessage='' /> - {customerContactObj.email}
                </div> :
                ''
        }
        {/* {
            customerObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.gstIn.label' defaultMessage='' /> - {customerObj.gstNumber}
                </div> :
                ''
        }
        {
            customerObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='customer.salesOrder.form.pan.label' defaultMessage='' /> - {customerObj.panNumber}
                </div> :
                ''
        } */}

    </Fragment>

    return fragmentObj;
}

// const getFormatedNextTxNumber = (props) => {
//     // const { customer } = props;
//     // if (!customer || !customer.customerId) {
//     //     return <span>{props.nextTxNumber}</span>;
//     // }
//     // return <span>{props.nextTxNumber.replace('CM-00', 'CM-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
//     return <span>{props.nextTxNumber}</span>;
// }

export default injectIntl(InvoiceDetails);
