import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RFQDetailsComponent from '../../../../../components/modal/modalBody/supplier/RFQDetail';
import { fetchSupplierRFQDetail, approveRFQ, uploadStamp, deleteStamp } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchAllRFQ, getRFQCount, updateRfqStatus } from '../../../../supplier/rfq/Listing/action';
import { fetchAllRFQStatus, addRFQStatus, deleteRFQStatus } from '../AddUpdateBiddingSupplier/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';
import { deleteAttectedFile } from '../../../../../actions/commonActions';
import { popModalFromStack,pushModalToStack,showModal,hideModal } from '../../../../../actions/commonActions';
import { deleteDocument } from '../../../../supplier/rfq/StandardRFQ/action';
class RFQDetail extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            templateName: 'Professional'
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, supplierId: this.props.rfqPayload.supplierId, rfqMasterId: this.props.rfqPayload.rfqMasterId };
        fetchDataIfNeeded('fetchSupplierRFQDetail', 'rfqData', this.props, payload, true);
    }

    render() {
        return <>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <RFQDetailsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        rfqData: state.rfq.rfqData,
        userInfo: state.common.userInfo,
        permissions: state.auth.permissions,
        rfqStatusList: state.rfq.rfqStatusList,
        detailLoading: state.common.detailLoading
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSupplierRFQDetail,
    downloadFileFromBucket,
    generatePDF, printPDF,
    approveRFQ,
    fetchAllRFQ,
    getRFQCount, 
    uploadStamp, 
    deleteStamp,
    updateRfqStatus,
    fetchAllRFQStatus, 
    addRFQStatus, 
    deleteRFQStatus,
    deleteDocument,
    hideModal,
    showModal,
    deleteAttectedFile,popModalFromStack,pushModalToStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RFQDetail);