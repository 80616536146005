import React, {Fragment, useState} from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import config from '../../../../../config/environmentConfig';
import { FormattedMessage } from 'react-intl';
import ReactFileReader from 'react-file-reader'
// import { CSVLink } from "react-csv";
import { Button, Input, Row, Col } from 'antd';
import { showToasterMessage } from '../../../../../utils';
//import BankStatement from '../../../../../assets/sampleFiles/bank_statement.csv';
//import BankStatementSample from '../../../../../assets/sampleFiles/bank_statement_sample.csv';

const ImportStatementComponent = (props) => {
    const [importRecords, updateImportRecords] = useState([]);
    const [fileName, updateFileName] = useState("");

    // const headers = [
    //     { label: "Date (MM/DD/YYYY)", key: "date" },
    //     { label: "Description", key: "description" },
    //     { label: "Deposit", key: "deposit" },
    //     { label: "Withdrawal", key: "withdrawal" },
    //     { label: "Institution Name", key: "institutionName" }
    //   ];

    const headersKey = {
        Date: 'date',
        'Date (MM/DD/YYYY)': 'date',
        Description: 'description',
        Deposit: 'Deposit',
        Withdrawal: 'Withdrawal',
        Project: 'projectName',
    }
    headersKey['Institution Name'] = 'institutionName';
    headersKey['Ledger Account'] = 'ledgerAccount';
       
    //   const data = [
    //   ];
       
    //   const csvReport = {
    //     data: data,
    //     headers: headers,
    //     filename: 'bank_statement.csv'
    //   };

      const processData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const fileHeaders = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        //const paylod = {}
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (fileHeaders && row.length === fileHeaders.length) {
                const obj = {};
                for (let j = 0; j < fileHeaders.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] === '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] === '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (fileHeaders[j] && headersKey[fileHeaders[j]]) {

                        if ((fileHeaders[j] === 'Withdrawal' || fileHeaders[j] === 'Deposit') && isNaN(d)) {
                            showToasterMessage({
                                description: props.intl.formatMessage({ id: 'csv.amount.NAN.validation' }),
                                messageType: 'error'
                            });
                            updateFileName('');
                            return [];
                        }

                        if (fileHeaders[j] === 'Withdrawal') {
                            if (d < 0) {
                                obj[headersKey[fileHeaders[j]]] = d * -1;
                                obj.amount = d * -1;
                            }
                            else {
                                obj[headersKey[fileHeaders[j]]] = Number(d || 0);
                                obj.amount = Number(d || 0);
                            }
                        } else if (fileHeaders[j] === 'Deposit') {
                            obj[headersKey[fileHeaders[j]]] = Number(d || 0);
                            obj.amount = Number(d || 0);
                        }
                        else {
                            obj[headersKey[fileHeaders[j]]] = d;
                        }
                    }
                }

                if (Object.values(obj).filter(x => x).length > 0) {
                    obj.relationshipId = props.companyInfo.relationshipId;
                    if((Number(obj.Deposit) > 0 && Math.abs(Number(obj.Withdrawal)) > 0) || (obj.Deposit === 0 && obj.Withdrawal === 0)){
                        showToasterMessage({
                            description: props.intl.formatMessage({ id: 'csv.amount.validation' }),
                            messageType: 'error'
                        });
                        updateFileName('');
                        return [];
                    }
                    list.push(obj);
                }
            }
        }

        return list;
    }

    const handleFiles = files => {
        // var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.csv)$/i;
        let allowedType = /(\.csv)$/i;
        if (!allowedType.exec(((files|| [])[0]).name)) {
           return showToasterMessage({ description: props.intl.formatMessage({ id: 'csv.fileType.validation' }), messageType: 'error' });
        }

        updateFileName(((files|| [])[0]).name);
        var reader = new FileReader();
        reader.onload = function (e) {
            updateImportRecords(processData(reader.result));
            props.updateState({ importType: 'import'})
        }
        reader.readAsText(files[0]);
    }

    return (
        <div style={{textAlign: 'center'}}>
            <Fragment>
                <div style={{height: '440px'}}>
                    <br />
                    <div>
                        <FormattedMessage id="importStatement.message" />
                    </div>
                    <br />
                    <div>
                        <Row>
                            <Col offset={6} span={8}>
                                <b style={{margin: '0px 0px 5px -100px'}}><FormattedMessage id="selecteFile.text" /></b>
                            </Col>
                        </Row>
                        <Row>
                            <Col offset={6} span={8}>
                                <Input
                                    style={{color: 'gray' }}
                                    placeholder={props.intl.formatMessage({ id: 'uploadFile.placeholder', defaultMessage: '' })}
                                    disabled={true}
                                    value={fileName}
                                />
                            </Col>
                            <Col span={3} style={{marginLeft: '-15px' }}>
                                <ReactFileReader handleFiles={handleFiles} fileTypes={'.csv'}>
                                    <Button style={{ display: 'inline', marginRight: '5px' }} className='ant-btn-default'><FormattedMessage id="browse.text" /></Button>
                                </ReactFileReader>
                            </Col>
                        </Row>
                    </div>
                    <br /> 
                    
                    <div style={{ marginLeft: '-35px' }}>
                        {/* <CSVLink {...csvReport}><FormattedMessage id='downloadTemplate.text' defaultMessage='' /></CSVLink> */}
                        <FormattedMessage id='download' />  <span className="cursor-pointer" onClick={() => {
                            props.downloadFileFromBucket({
                                awsKeyName: 'bank_statement_sample.csv',
                                bucketName: config.BUCKET_NAME.BO_SAMPLE_RESOURCES,
                                fileName: 'bank_statement_sample.csv'
                            })
                        }}><FormattedMessage id='sampleFile' /> </span>
                        {/* <a href={BankStatementSample} download="bank_statement_sample.csv"><FormattedMessage id='sampleFile' /></a> */}
                     <FormattedMessage id='compare.sampleFile.message' defaultMessage='' />
                        <br />
                        <br />
                        <div>
                            <Button className='ant-btn ant-btn-primary' onClick={() => {
                                props.downloadFileFromBucket({
                                    awsKeyName: 'bank_statement.csv',
                                    bucketName: config.BUCKET_NAME.BO_SAMPLE_RESOURCES,
                                    fileName: 'bank_statement.csv'
                                })
                            }}>
                               <span><DownloadOutlined /> </span>
                                {/* <a href={BankStatement} download="bank_statement.csv"> */}
                                <FormattedMessage id='downloadTemplate.text' defaultMessage='' />
                            </Button>
                        </div>
                    </div>

                </div>
            </Fragment>
            <div className="ant-modal-footer">
                <Button type="primary" onClick={() => {
                    if (!fileName) {
                        return showToasterMessage({ description: props.intl.formatMessage({ id: 'selectFile.text' }), messageType: 'error' });
                    }
                    if (!importRecords || !importRecords.length) {
                        return showToasterMessage({ description: props.intl.formatMessage({ id: 'common.selectFile.empty' }), messageType: 'error' });
                    }
                    props.getMatchTransactionCount(importRecords);
                    props.updateState({ isDefaultLedgerInit: true })
                    props.hideModal();
                }}><FormattedMessage id='review.text' defaultMessage='' /></Button>
            </div>
        </div>

    );

}
export default ImportStatementComponent;
