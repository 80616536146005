import React, { Fragment, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Select, Slider, TimePicker, DatePicker } from 'antd';
import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
import {  getMomentDateForUI, getMomentDateForUIReadOnly } from '../../../utils';
import { CONSTANTS } from '../../../static/constants';
import moment from 'moment-timezone';
import { Dropdown } from '../../general/Dropdown';
const { Option } = Select;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const InputGroup = Input.Group;

function EventProps(props) {
    let eventData = (props.eventDetails || {})[props.childId] || {}; 
    let buttonRef = document.getElementById(props.childId) || {};
    const [buttonWidth, updateWidth] = useState(buttonRef.offsetWidth);
    const [buttonHeigth, updateHeigth] = useState(buttonRef.offsetHeight);
    const setEventInfo = () =>{
        let info = `<div style= "text-align: center; padding: 3px 8px;"> <b> ${eventData.eventTitle || ''} </b></div>
        <div style= "text-align: center; padding: 3px 8px;"> ${eventData.eventDate ? getMomentDateForUIReadOnly({ date: new Date(eventData.eventDate), format }) : ''} ${eventData.eventTime || ''} ${eventData.timezone || ''} </div>
        <div style= "text-align: center; padding: 3px 8px;"> ${eventData.street1 || ''} ${eventData.street2 || ''} </div>
        <div style= "text-align: center; padding: 3px 8px;"> ${eventData.cityName || ''}${eventData.stateName ? ', ' + eventData.stateName : ''}${eventData.zipCode ? ', ' + eventData.zipCode : ''} </div>
        `;
        buttonRef.innerHTML = info;
    }

    let  timeZones = moment.tz.names();
    let abbrs = {
        EST : 'Eastern Standard Time',
        EDT : 'Eastern Daylight Time',
        CST : 'Central Standard Time',
        CDT : 'Central Daylight Time',
        MST : 'Mountain Standard Time',
        MDT : 'Mountain Daylight Time',
        PST : 'Pacific Standard Time',
        PDT : 'Pacific Daylight Time',
    };
    
    moment.fn.zoneName = function () {
        var abbr = this.zoneAbbr();
        return abbrs[abbr] || abbr;
    };
    let  offsetTmz = [];

    for (var i in timeZones) {
        offsetTmz.push({name: timeZones[i], value: " (GMT" + moment.tz(timeZones[i]).format('Z') + ") " + moment.tz(timeZones[i]).format('z')+ " "+  timeZones[i]});
    }

    return (
        <Fragment>
                    <div>
                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='eventTitle.text' />
                        </Col>
                        <Col span={24}>
                            <Input value={eventData.eventTitle} onChange={(e) => {
                                eventData.eventTitle = e.target.value;
                                props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                setEventInfo(eventData);
                                let titleEditor = window.CKEDITOR.instances['editor_child_1'];
                                titleEditor.setData(`<p style='text-align: center;'><b><span style='font-size: xx-large;'>${eventData.eventTitle}</span></b></p>` );
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='event.startDate.and.time' />
                        </Col>
                        <Col span={12}>
                        <DatePicker
                            allowClear={false}
                            format={format}
                            key='eventDate'
                            defaultValue={eventData.eventDate ? getMomentDateForUI({ date: new Date(eventData.eventDate), format }) : null }
                            onChange={(selectedDate) => {
                                eventData.eventDate = selectedDate;
                                props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                setEventInfo(eventData);
                            }}
                        />
                        </Col>
                        <Col span={12}>
                        <TimePicker use12Hours defaultValue={moment(eventData.eventTime, 'h:mm a')} format="h:mm a" onChange={(selectedTime, timeString) => {
                                eventData.eventTime = timeString;
                                props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                setEventInfo(eventData);
                            }} />
                        </Col>
                        <Col span={24}>
                                <FormattedMessage id='signup.timezone.text' defaultMessage='' />
                            </Col>
                            <Col span={24}>
                                <InputGroup compact>
                                    <Dropdown
                                        items={offsetTmz}
                                        value={eventData.timezone}
                                        valueKeyName='value'
                                        optionKeyName='name'
                                        placeholder={props.intl.formatMessage({
                                            id: 'signup.timezone.placeholder', defaultMessage: ''
                                        })}
                                        onSelect={(optionValue, elem) => {
                                            eventData.timezone = optionValue;
                                            props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                            setEventInfo(eventData);
                                        }}
                                    />
                                </InputGroup>
                                </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='event.hostedBy' />
                        </Col>
                        <Col span={24}>
                            <Input value={eventData.hostedBy} onChange={(e) => {
                                eventData.hostedBy = e.target.value;
                                props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}})
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='signup.streetname.placeholder' />
                        </Col>
                        <Col span={24}>
                            <Input value={eventData.street1} onChange={(e) => {
                                eventData.street1 = e.target.value;
                                props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                setEventInfo(eventData);
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='signup.street(contd).placeholder' />
                        </Col>
                        <Col span={24}>
                            <Input value={eventData.street2} onChange={(e) => {
                                eventData.street2 = e.target.value;
                                props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                setEventInfo(eventData);
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='signup.city.placeholder' />
                        </Col>
                        <Col span={24}>
                            <Input value={eventData.cityName} onChange={(e) => {
                                eventData.cityName = e.target.value;
                                props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                setEventInfo(eventData);
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='address.state' />
                        </Col>
                        <Col span={24}>
                            <Input value={eventData.stateName} onChange={(e) => {
                                eventData.stateName = e.target.value;
                                props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                setEventInfo(eventData);
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='address.zip' />
                        </Col>
                        <Col span={24}>
                            <Input value={eventData.zipCode} onChange={(e) => {
                                eventData.zipCode = e.target.value;
                                props.updateState({eventDetails : {...props.eventDetails, [props.childId]: eventData}});
                                setEventInfo(eventData);
                            }}></Input>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                        <Col span={18}>
                            <FormattedMessage id='buttonSize.text' />
                        </Col>
                        <Col span={24}>
                            <Button title='Small' onClick={() => {

                                buttonRef.style.width = "200px";
                                buttonRef.style.height = "auto";
                                buttonRef.style.fontSize = '20px'; // setting the font size to 20px
                                updateWidth(200);
                            }}style={{borderRadius:'4px 0px 0px 4px'}}>
                                <span className='cirty'>
                                S </span></Button>
                            <Button title='Medium' onClick={() => {
                                buttonRef.style.width = "400px";
                                buttonRef.style.height = "auto";
                                updateWidth(400);
                            }} style={{borderRadius:'0px 0px 0px 0px '}}>
                                <span className='cirty'>M </span></Button>
                            <Button title='Large' onClick={() => {
                                buttonRef.style.width = "600px";
                                buttonRef.style.height = "auto";
                                updateWidth(600);
                            }} style={{borderRadius:'0px 4px 4px 0px '}}><span className='cirty'> L</span></Button>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='package.packageDetail.width' /></Col>
                        <Col span={19}>
                        {/* <Slider defaultValue={15} tooltipVisible /> */}
                        <Slider min={10} max={680} value={buttonWidth}  onChange={(value) => {
                                buttonRef.style.float = 'left';
                                buttonRef.style.marginLeft =  "0px";
                                buttonRef.style.width = value+"px";
                                updateWidth(value);
                            }} />
                        </Col>
                        </Row>


                        <Row className="but-buttons">
                        <Col span={5}><FormattedMessage id='buttonHeight.height' /></Col>
                        <Col span={19}>
                        
                        {/* <Slider defaultValue={15} tooltipVisible /> */}
                        <Slider min={10} max={600} value={buttonHeigth} onChange={(value) => {
                                //buttonRef.style.lineHeight = value+"px"
                                buttonRef.style.height = value+"px";
                                updateHeigth(value);
                            }}  />
                        </Col>

                        
                        </Row>
                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='buttonCorner.text' />
                        </Col>
                        <Col span={24} >
                            <Button title='Square' onClick={() => {
                                buttonRef.style.borderRadius = "0px"
                            }} style={{borderRadius:'4px 0px 0px 4px', position:'relative'}}> 
                            <div className="left-roud"></div>
                            
                            </Button>
                            <Button title='Round' onClick={() => {
                                buttonRef.style.borderRadius = "4px"
                            }} style={{borderRadius:'0px', position:'relative'}}>   
                            <div className="mr-roud"></div>   
                             
                            </Button>
                            <Button title='Extra Round' onClick={() => {
                                buttonRef.style.borderRadius = "20px"
                            }} style={{borderRadius:'0px 4px 4px 0px', position:'relative'}}>      
                             <div className="right-roud"></div>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='buttonCorners.text' /></Col>
                        <Col span={19}>
                        <Slider defaultValue={10} min={0} max={50} onChange={(value) => {
                                buttonRef.style.borderRadius = value+"%"
                            }}  />
                        </Col>
                        </Row>

                    <Row className="but-buttons">
                        <Col span={24}>
                            <FormattedMessage id='buttonPosition.text' />
                        </Col>
                        <Col span={24} >
                            <Button title='Left' onClick={() => {
                                buttonRef.style.marginLeft = "10px";
                                buttonRef.style.float = 'left';
                                buttonRef.style.margin = "15px auto 0px";
                            }} style={{borderRadius:'4px 0px 0px 4px', position:'relative'}}>      
                            
                            <div className="leftd-roud1"></div>    
                            </Button>
                            <Button title='Middle' onClick={() => {
                                buttonRef.style.float = 'left';
                                buttonRef.style.marginLeft =  ((680-buttonWidth)/2)+"px";
                            }} style={{borderRadius:' 0px ', position:'relative'}} >      
                             <div className="mrd-roud1"></div>
                            </Button>
                            <Button title='Right' onClick={() => {
                                buttonRef.style.marginLeft = "10px";
                                buttonRef.style.float = 'right';
                            }} style={{borderRadius:'0px 4px 4px 0px', position:'relative'}} >      
                             <div className="rigt-roud1"></div>
                            </Button>
                        </Col>
                    </Row>

                    <Row className="but-buttons">
                    <Col span={5}><FormattedMessage id='buttonPosition.text' /></Col>
                        <Col span={19}>
                            <Slider max={680} onChange={(value) => {
                                buttonRef.style.float = 'left';
                                buttonRef.style.marginLeft = (buttonWidth+value) > 680 ? (680-buttonWidth)+"px" : value+"px";
                            }} />
                        </Col>
                        <Col span={5}><FormattedMessage id='buttonPosition.vertical.text' /></Col>
                        <Col span={19}>
                            <Slider max={680} onChange={(value) => {
                                buttonRef.style.marginTop =  -value+"px";
                            }} />
                        </Col>
                        </Row>
                    <Row className="but-buttons">
                        <Col span={6} style={{paddingTop:'4px'}}> 
                            <FormattedMessage id='buttonTextStyle.text' />
                        </Col>
                        <Col span={18} >
                            <Select style={{width: '100%'}} onChange={(val) => {
                                buttonRef.style.fontFamily = val;
                            }}>
                                <Option value="arial">Arial</Option>
                                <Option value="verdana">Verdana</Option>
                                <Option value="verdana">Century Gothic</Option>
                                <Option value="impact">Helvetica</Option>
                                <Option value="gorgeous">gorgeous</Option>
                                <Option value="Myriad Pro">Myriad Pro</Option>
                                <Option value="New York">New York</Option>
                                <Option value="Optima">Optima</Option>
                                <Option value="impact">Impact</Option>
                                <Option value="ms comic sans">MS Comic Sans</Option>
                                <Option value="Times New Roman">Times New Roman</Option>
                                <Option value="Baskerville">Baskerville</Option>
                                <Option value="American Typewriter">American Typewriter</Option>
                                <Option value="Edwardian Script ITC">Edwardian Script ITC</Option>
                                <Option value="arial">Montserrat</Option>
                                 <Option value="ms comic sans">Phosphate</Option>

                            </Select>
                        </Col>
                    </Row>
                    <Row className="but-buttons">
                        <Col span={6}>
                        <FormattedMessage id='buttonTextSize.text' />
                        </Col>
                        <Col span={18}>
                        <Slider min={1} max={50} onChange={(val) => {
                                buttonRef.style.fontSize = val+'px';
                            }}/>
                        </Col>
                        <Col span={6}><FormattedMessage id='backgraund.opacity.text' /></Col>
                        <Col span={18}>
                            <Slider max={10} min={1} onChange={(value) => {
                                buttonRef.style.opacity =  value/10;
                            }} />
                        </Col>
                        </Row>
                    <Row className="but-buttons">
                    <Col span={7} style={{ paddingTop:'2px', paddingRight:'4px'}}>
                            <FormattedMessage id='buttonTextColor.text' />
                        </Col>
                        <Col span={17} >
                            <ColorPicker
                             placement='bottomLeft's
                                enableAlpha={false}
                                color={props.textColor || buttonRef.style.color}
                                onChange={(obj) => {
                                    props.updateState({ textColor: obj.color });
                                    buttonRef.style.color = obj.color;
                                }}
                                mode="RGB"
                            />
                        </Col>
                        
                    </Row>
                    <Row className="but-buttons">
                    <Col span={7} style={{ paddingTop:'2px', paddingRight:'4px'}}>
                            <FormattedMessage id='buttonBackgraound.text' />
                        </Col>
                    <Col span={17} >
                            <ColorPicker
                             placement='bottomLeft'
                                enableAlpha={false}
                                color={props.bgColor || buttonRef.style.backgroundColor}
                                onChange={(obj) => {
                                    props.updateState({ bgColor: obj.color });
                                    buttonRef.style.background = obj.color;
                                }}
                                mode="RGB"
                            />
                        </Col>
                        
                        
                    </Row>

                    <Row className="but-buttons">
                    <Col span={7} style={{ paddingTop:'2px', paddingRight:'4px'}}>
                            <FormattedMessage id='bordercolor.text' />
                        </Col>
                    <Col span={17} >
                            <ColorPicker
                                enableAlpha={false}
                                color={props.borderColor ||  buttonRef.style.borderColor}
                                onChange={(obj) => {
                                    props.updateState({ borderColor: obj.color });
                                    buttonRef.style.borderColor = obj.color;
                                }}
                                mode="RGB"
                            />
                        </Col>
                        
                        
                    </Row>


                </div>
        </Fragment>
    );
}


export default injectIntl(EventProps);
