import React, { Component, Fragment } from "react";
// import { Link } from 'react-router-dom';
import { Menu ,Empty} from "antd";
import { FormattedMessage } from "react-intl";
// import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { CONSTANTS, MODAL_TYPE } from "../../../../../static/constants";
import {
  PlusOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import DropdownAction from "antd/lib/dropdown";
class Address extends Component {
  render() {
    const alladdressList = this.props.companyInfoData.boLocationList || [];
    const addressList = alladdressList.filter((ele) => {
      return (
        ele.locationType === CONSTANTS.RELATIONSHIP_BILLING_ADDRESS ||
        ele.locationType === CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS
      );
    });
    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const onFirstDataRendered = (params) => {
    //     setTimeout(()=>{
    //         params.api.resetRowHeights();
    //     }, 1000)
    // }
    // const getRowHeight = (params) => {
    //     return 30;
    // }

    const handleNewAddressAddition = (address) => {
      let payload = {};
      let formData = {};
      payload.formData = {
        isUpdate: true,
        ...address,
        addressType: "relationship",
      };
      const { pushModalToStack, popModalFromStack, companyInfo } = this.props;
      formData.relationshipId = companyInfo.relationshipId;
      payload.formData.callback = (data) => {
        this.props.popModalFromStack();
        this.props.getRelationshipAddress({
          relationshipId: data.relationshipId,
        });
      };
      // payload.formData.onCancel = (data) => {
      //  this.props.popModalFromStack(data);
     
      // };
      payload.formData.onCancel = ()=>{
        // this.props.popModalFromStack();
          this.props.pushModalToStack({
              modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
              footer:{submit:{intlId:"button.close.label"}},
              handleSubmit: () => {
                this.props.popModalFromStack();
              }
            })
    }
      const data = {
        // title: <FormattedMessage id='addItem.text.billingAddress' defaultMessage='Billing Address' />,
        title: address.cityName ? (
          "Edit Address"
        ) : (
          <FormattedMessage
            id="addItem.text.billingAddress"
            defaultMessage="Create Address"
          />
        ),

        formData,
        hideFooter: true,
        modalData: {
          modalType: MODAL_TYPE.BILLING_ADDRESS,
          data: payload,
        },
        handleSubmit: (formData = {}) => {
          data.formData.submittedOnce = true;
          popModalFromStack(data);
        },
        onCancel: () => {
          this.props.pushModalToStack({
            modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
            footer:{submit:{intlId:"button.close.label"}},
            handleSubmit: () => {
              this.props.popModalFromStack();
            }
          })
        }
      };
      // this.props.showModal(data);
      pushModalToStack(data);

    };

    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='address.primary' defaultMessage='' />  </div>;
    //         },
    //         field: "isDefault",
    //         resizable: true,
    //         editable: true,
    //         width: 100,
    //         cellRendererFramework: function (link) {
    //             return <div>
    //                 {link.value ? "Yes" : "No"}
    //             </div>
    //         },
    //         cellEditor: 'customCheckboxEditor'
    //     },
    //     // {
    //     //     headerName: "Address Name",
    //     //     field: "locationName",
    //     //     resizable: true,
    //     //     editable: true,
    //     //     cellEditor: 'customTextEditor',
    //     //     cellEditorParams: (obj) => {
    //     //         return {
    //     //             onChange: (value) => {
    //     //                 //obj.node.setDataValue(obj.colDef.field, value);
    //     //             }
    //     //         }
    //     //     }
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='address.addressType' defaultMessage='' />  </div>;
    //         },
    //         field: "locationType",
    //         resizable: true,
    //         editable: true,
    //         minWidth: 100,
    //         maxWidth: 100,
    //         cellEditor: 'customDropDownEditor',
    //         cellRendererFramework: function (link) {
    //             return <div>
    //                 {link.value === 'RelationshipBillingAddress' ? 'Billing' : 'Shipping'}
    //             </div>
    //         },
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             if (keyCode === 9) {
    //                 params.api.tabToNextCell();
    //                 return true;
    //             }
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //         cellEditorParams: (obj) => {
    //             return {
    //                 lastColumnCell: false,
    //                 items: [{ name: 'Billing', type: 'RelationshipBillingAddress' }, { name: 'Shipping', type: 'RelationshipShippingAddress' }],
    //                 optionKeyName: 'type',
    //                 valueKeyName: 'name',
    //                 onSelect: (selectedValue, option) => {
    //                     obj.node.setDataValue(obj.colDef.field, option.value);
    //                 },
    //             }
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='address.streetAddress1' defaultMessage='' />  </div>;
    //         },
    //         field: "streetAddress1",
    //         resizable: true,
    //         editable: true,
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             if (keyCode === 9) {
    //                 params.api.tabToNextCell();
    //                 return true;
    //             }
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //         cellEditor: 'customTextEditor',
    //         cellEditorParams: (obj) => {
    //             return {
    //                 onChange: (value) => {
    //                     obj.node.setDataValue(obj.colDef.field, value);
    //                 }
    //             }
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='address.streetAddress2' defaultMessage='' />  </div>;
    //         },
    //         field: "streetAddress2",
    //         resizable: true,
    //         editable: true,
    //         cellEditor: 'customTextEditor',
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             if (keyCode === 9) {
    //                 params.api.tabToNextCell();
    //                 return true;
    //             }
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //         cellEditorParams: (obj) => {
    //             return {
    //                 onChange: (value) => {
    //                     obj.node.setDataValue(obj.colDef.field, value);
    //                 }
    //             }
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='address.city' defaultMessage='' />  </div>;
    //         },
    //         field: "cityName",
    //         editable: true,
    //         resizable: true,
    //         minWidth: 100,
    //         maxWidth: 100,
    //         cellEditor: 'customTextEditor',
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             if (keyCode === 9) {
    //                 params.api.tabToNextCell();
    //                 return true;
    //             }
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //         cellEditorParams: (obj) => {
    //             return {
    //                 onChange: (value) => {
    //                     obj.node.setDataValue(obj.colDef.field, value);
    //                 }
    //             }
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='address.country' defaultMessage='' />  </div>;
    //         },
    //         field: "countryName",
    //         editable: true,
    //         resizable: true,
    //         minWidth: 130,
    //         maxWidth: 130,
    //         cellEditor: 'customDropDownEditor',
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             if (keyCode === 9) {
    //                 params.api.tabToNextCell();
    //                 return true;
    //             }
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //         cellEditorParams: (obj) => {
    //             return {
    //                 lastColumnCell: false,
    //                 items: this.props.countries || [],
    //                 optionKeyName: 'countryId',
    //                 valueKeyName: 'countryName',
    //                 onSelect: (selectedValue) => {
    //                     obj.node.setDataValue(obj.colDef.field, selectedValue);
    //                 },
    //             }
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='address.state' defaultMessage='' />  </div>;
    //         },
    //         field: "stateName",
    //         editable: true,
    //         minWidth: 80,
    //         maxWidth: 80,
    //         resizable: true,
    //         cellEditor: 'customTextEditor',
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             if (keyCode === 9) {
    //                 params.api.tabToNextCell();
    //                 return true;
    //             }
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //         cellEditorParams: (obj) => {
    //             return {
    //                 onChange: (value) => {
    //                     obj.node.setDataValue(obj.colDef.field, value);
    //                 }
    //             }
    //         }
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='address.zip' defaultMessage='' />  </div>;
    //         },
    //         field: "zipCode",
    //         editable: true,
    //         resizable: true,
    //         minWidth: 100,
    //         maxWidth: 100,
    //         cellEditor: 'customTextEditor',
    //         suppressKeyboardEvent: (params) => {
    //             const KEY_ENTER = 13;
    //             const keyCode = params.event.keyCode;
    //             if (keyCode === 9) {
    //                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //                 this.gridApi.refreshCells({ force: true });
    //                 params.api.stopEditing(true);
    //                 return true;
    //             }
    //             const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
    //             return gridShouldDoNothing;
    //         },
    //         cellEditorParams: (obj) => {
    //             return {
    //                 onChange: (value) => {
    //                     obj.node.setDataValue(obj.colDef.field, value);
    //                 }
    //             }
    //         }
    //     },
    //     {
    //         field: 'action', lockPosition: true, pinned: 'right',
    //         minWidth: 100,
    //         maxWidth: 100,
    //         cellRendererFramework: (params) => <div>
    //             {(params.data.origination !== 'System') ? <div>
    //                 <div>
    //                     {params.node.data.isToEdit &&
    //                         <div className="table-cell-action">
    //                             <Link onClick={() => {
    //                                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.relationshipId = (this.props.companyInfo || {}).relationshipId;
    //                                 this.props.updateBoLocation(this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data, this.props);
    //                                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //                                 this.gridApi.refreshCells({ force: true });
    //                             }} >
    //                                 <i className="fa fa-save" />
    //                             </Link>
    //                             <Link onClick={() => {
    //                                 params.api.stopEditing(true);
    //                                 this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = false;
    //                                 this.gridApi.refreshCells({ force: true });
    //                             }} >
    //                                 <i className="fa fa-ban" />
    //                             </Link>
    //                         </div>
    //                     }

    //                     {!params.node.data.isToEdit &&
    //                         <div className="table-cell-action" >
    //                             <Link onClick={() => {
    //                                 this.gridApi.forEachNode((rowNode, index) => {
    //                                     this.gridApi.getDisplayedRowAtIndex(index).data.isToEdit = false;
    //                                 });
    //                                 //this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data.isToEdit = true;
    //                                 //this.gridApi.refreshCells({ columns: ["action"], force: true });
    //                                 // var startEditingParams = {
    //                                 //     rowIndex: params.node.rowIndex,
    //                                 //     colKey: params.column.getId(),
    //                                 //   };
    //                                 //params.api.startEditingCell(startEditingParams);
    //                                 handleNewAddressAddition(params.node.data)
    //                             }}>
    //                                 <i className="fa fa-edit" />
    //                             </Link>
    //                             <Link onClick={() => {
    //                                 const modalData = {
    //                                     modalBody: <FormattedMessage
    //                                         id='customer.address.delete.confirmation'
    //                                         defaultMessage=''
    //                                     />,
    //                                     handleSubmit: () => {
    //                                         this.props.deleteLocation(this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data, {
    //                                             callback: () => {
    //                                                 this.props.getRelationshipAddress({ relationshipId: (this.props.companyInfo || {}).relationshipId });
    //                                             }
    //                                         })
    //                                         this.props.popModalFromStack();
    //                                     }
    //                                 };
    //                                 this.props.pushModalToStack(modalData);

    //                             }}>
    //                                 <i className="fa fa-remove" />
    //                             </Link>
    //                         </div>}
    //                 </div>
    //             </div>
    //                 : <div></div>
    //             }
    //         </div>
    //     }
    // ]

    // const onGridReady = (params) => {
    //     this.gridApi = params.api;
    // }
    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Action
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            key="1"
            onClick={() => {
              handleNewAddressAddition(e);
            }}
          >
            <EditOutlined />
            Edit
          </Menu.Item>

          <Menu.Item
            key="2"
            onClick={() => {
              const modalData = {
                modalBody: (
                  <FormattedMessage
                    id="customer.address.delete.confirmation"
                    defaultMessage=""
                  />
                ),
                handleSubmit: () => {
                  this.props.deleteLocation(e.data, {
                    callback: () => {
                      this.props.getRelationshipAddress({
                        relationshipId: (this.props.companyInfo || {})
                          .relationshipId,
                      });
                    },
                  });
                  this.props.popModalFromStack();
                },
              };
              this.props.pushModalToStack(modalData);
            }}
          >
            <DeleteOutlined />
            Delete
          </Menu.Item>
        </Menu>
      );
    };
    return (
      <Fragment>
        <div className="view-top-bar">
          <div className="top-view-left"></div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={() => {
                handleNewAddressAddition({
                  relationshipId: (this.props.companyInfo || {}).relationshipId,
                  locationType: "RelationshipBillingAddress",
                });
              }}
            >
              <PlusOutlined />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>
        <div className="bank-info">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="address.primary" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage
                      id="address.addressType"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="address.streetAddress1"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="address.streetAddress2"
                      defaultMessage=""
                    />
                  </th>
                  <th>
                    <FormattedMessage id="address.city" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="address.country" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="address.state" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="address.zip" defaultMessage="" />
                  </th>
                  <th>
                    <FormattedMessage id="Action" defaultMessage="" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {(addressList || []).length ? 
                  addressList.map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{rowData.isDefault ? "Yes" : "No"}</td>
                        <td>
                          <div>
                            {rowData.locationType ===
                            "RelationshipBillingAddress"
                              ? "Billing"
                              : "Shipping"}
                          </div>
                        </td>
                        <td>{rowData.streetAddress1}</td>
                        <td>{rowData.streetAddress2}</td>
                        <td>{rowData.cityName}</td>
                        <td>{rowData.countryName}</td>
                        <td>{rowData.stateName}</td>
                        <td>{rowData.zipCode}</td>
                        <td className="text-center">
                          <DropdownAction
                            overlay={actionMenu(rowData)}
                            trigger={["click"]}
                            overlayClassName="action-listing-dropdown"
                          >
                            <MoreOutlined />
                          </DropdownAction>
                          
                        </td>
                      </tr>
                    );
                  })
                : <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr>
                }
              </tbody>
            </table>
          </div>
          {/* <div className="ag-theme-balham" style={agGridStyle} >
                        <CustomAgGrid
                            columnDefs={columnDefs}
                            rowData={addressList || []}
                            rowDragManaged={true}
                            defaultColDef={{
                                flex:1,
                                autoHeight: true,
                                wrapText: true,
                            }}
                            suppressClickEdit={true}
                            noSingleClickEdit={true}
                            disableFocusOnClickOutside={true}
                            domLayout={"autoHeight"}
                            animateRows={true}
                            onGridSizeChanged={onFirstDataRendered}
                            //getRowHeight={getRowHeight}
                            editType={'fullRow'}
                            onGridReady={onGridReady}
                        >
                        </CustomAgGrid>
                    </div> */}
          {/* <Button className="add-new-record" onClick={
                        () => {
                            handleNewAddressAddition({ relationshipId: (this.props.companyInfo || {}).relationshipId, locationType: 'RelationshipBillingAddress' })
                        }
                    }>Add New Adddress </Button> */}
        </div>
      </Fragment>
    );
  }
}

export default Address;
