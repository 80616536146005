import React from "react";
import BillwiseDetailComp from "../../../components/finance/BillwiseDetail";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchCashBookList, getCashBookCount, resetPaginatedData} from "./action";
import { pushModalToStack , popModalFromStack, showModal, hideModal, 
  fetchUserPreferences, saveUserPrefrences, deleteAuditTrail, createAuditTrail, fetchProjects} from "../../../actions/commonActions";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../utils';
import {fetchSalesInvoices, getSalesInvoiceCount} from '../../customer/invoice/Listing/action';
import {fetchPurchaseInvoices, getPurchaseInvoicesCount} from '../../supplier/invoice/Listing/action';
import * as find from 'lodash.find';

 class BillwiseDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
      txColumns: [],
      txPreferences: { txType: "salesInvoice", templateName: "standard" },
      txMandatoryColumns: ['invoiceNumber', 'supplierName', 'invoiceTotalAmount', 'invoiceDate', 'soNumber', 'customerName'],
      txAvailableColumn: {
        isXeroUploaded: true,
        invoiceNumber: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        customerName: true,
        invoiceTotalAmount: true,
        status: true,
        totalPaymentReceived: true,
        invoiceDueAmount: true,
        paymentTermName: true,
        invoiceDate: true,
        invoiceDueDate: true,
        quotationNumber: true,
        // rfqNumber: true,
        userStatus: true,
        project: true,
        isCashInvoice: true,
        referenceNumber: true,
        dueDays: true,
        creditApplied: true,
        onlinePayment: true,
        invoiceReminder: true,
        recurringInvoice: true,

        supplierPoNumber: true,
        supplierName: true,
        invoiceTotalAmount: true,
        totalPaymentMade: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        rfqNumber: true,
        reference: true,
        debitApplied: true,
        supplierInvoiceNumber: true,
      }
    };
  }

  
  componentDidMount() {

  
    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      relationshipEmployeeId: (this.props.userInfo || {}).relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 200,
      filters: {
        module: linkProps.module, 
        formId: linkProps.module === "school" ? this.props.defaultSchoolForm : 0,
      }
    };
    fetchPaginationDataIfNeeded('fetchSalesInvoices', 'salesInvoiceList', this.props, payload);
    fetchDataIfNeeded('getSalesInvoiceCount', 'salesInvoiceCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
  }

   componentWillReceiveProps(props) {

     if (props.allPreferences && props.allPreferences.length) {
       if (!this.state.activeTab || this.state.activeTab === 'Sales Invoice') {
         let txPreferences = find(props.allPreferences, { txType: "salesInvoice", templateName: "standard" });
         if (txPreferences && txPreferences.tablePreference) {
           let txColumns = ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [];
           txColumns = txColumns.length ? txColumns : this.state.txMandatoryColumns;
           const txColumnSetting = ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {};
           const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
           let gridPreference = txPreferences.gridPreference;
           this.setState({
             gridPreference,
             isColumnFitTable,
             preferenceId: txPreferences.preferenceId,
             txColumns,
             txColumnSetting,
             txPreferences
           });
         }
         else {
           this.setState({
             txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
           });
         }
       }
       else {
         let txPreferences = find(props.allPreferences, { txType: "purchaseInvoice", templateName: "standard" }) || this.state.txPreferences;
         if (txPreferences) {
           let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
           txColumns = txColumns.length ? txColumns : (this.state.txMandatoryColumns || []).concat('product', 'description');
           const txColumnSetting = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
           const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
           let gridPreference = txPreferences.gridPreference
           this.setState({
             gridPreference,
             isColumnFitTable,
             preferenceId: txPreferences.preferenceId,
             txColumns,
             txColumnSetting,
             txPreferences
           });
         }
         else {
           this.setState({
             txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
           });
         }
       }
     }

   }

  render() {
    return (
      <>
        <BillwiseDetailComp
          {...this.props}
          {...this.state}
           updateState={(data) => {
            this.setState(data);
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    isLoading: state.common.isLoading,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,

    salesInvoiceList: state.salesInvoice.salesInvoiceList,
    salesInvoiceCount: state.salesInvoice.salesInvoiceCount,
    purchaseInvoiceList: state.purchaseInvoice.purchaseInvoiceList,
    purchaseInvoiceCount: state.purchaseInvoice.purchaseInvoiceCount,
    allPreferences: state.common.allPreferences,
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSalesInvoices,getSalesInvoiceCount,
    fetchPurchaseInvoices, getPurchaseInvoicesCount,
    fetchCashBookList, getCashBookCount, resetPaginatedData,
    pushModalToStack,
    popModalFromStack,
    showModal, 
    hideModal,
    fetchUserPreferences, saveUserPrefrences, deleteAuditTrail, createAuditTrail, fetchProjects
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BillwiseDetail);
