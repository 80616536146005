import LeadWorkflow from "../../../components/leads/LeadWorkflow";
import React from "react";

export class LeadWorkflowComp extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
 
    }
  }
  render() {
    return <LeadWorkflow  {...this.props} {...this.state}  updateState={(data) => { this.setState(data) }}/>
  }
}