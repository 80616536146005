import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { generateEWayBillDeatils } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../actions/commonActions';
import { getStateCode } from '../../../components/general/stateCode';

import EwayBill from '../../../components/drawer/EwayBill';

class EwayBillContainer extends Component {

  constructor(props){
        const {salesInvoiceDetail} = props;
        const buyerInfo = salesInvoiceDetail?.boLocationSalesInvoiceList[1];
        const supplierInfo = salesInvoiceDetail?.boLocationSalesInvoiceList[0];
       
    super(props);
    this.state = {
      
      activeTabKey: '1',

      transportName: undefined,
      transportDist: undefined,
      vechileType: {vehType :"Regular" ,referCode:"R"}||undefined,
      transportMode:"Road"|| undefined,
      userGstin: undefined,
      
    transportdocNo:  undefined,
      transporterdocDate: new Date()||undefined,
      vechileNo: salesInvoiceDetail ? salesInvoiceDetail?.vehicleNumber : undefined,
      transportId: undefined,
      subSupplyType:"Supply"|| undefined,
      subSupplyDes: undefined,
      placeofBusinessEWB: undefined,
      
      pAddress1:salesInvoiceDetail ? buyerInfo?.streetAddress1 : undefined,
      pAddress2: undefined,
      placeLocation:  salesInvoiceDetail ?  buyerInfo?.stateName : undefined,
      stateCode:getStateCode( buyerInfo?.stateName || "") || undefined,
      pincode: salesInvoiceDetail ? buyerInfo?.zipCode : undefined,
      dispatchTraderName: salesInvoiceDetail ? salesInvoiceDetail?.customerName : undefined,
      disAddress1:  salesInvoiceDetail ? supplierInfo?.streetAddress1 : undefined,
      disAddress2: undefined,
      disLocation:salesInvoiceDetail ? supplierInfo?.stateName : undefined,
      disStateCode: getStateCode(supplierInfo?.stateName) || undefined,
      disPincode: salesInvoiceDetail ? supplierInfo?.zipCode : undefined,
      irnNo : salesInvoiceDetail ? salesInvoiceDetail?.irn : undefined,

   
    }
  }
 
//   componentDidMount() {
//     const companyInfo = this.props.companyInfo || {};
//     const payload = {
//       relationshipId: companyInfo.relationshipId,
//       transactionName: this.props.transactionName,
//     };
//     this.props.resetUserStatusList();
//     fetchDataIfNeeded('fetchAllStatus', 'userStatus', this.props, payload);
//   }



  render() {
    return <div>
      <EwayBill 
    //   initCalls={() => {
    //     this.initCalls();
    //   }} 
      {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      userStatus: state.common.userStatus,
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
   
    pushModalToStack,
    popModalFromStack,
    showModal,
    hideModal,
    generateEWayBillDeatils,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(EwayBillContainer);
