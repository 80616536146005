import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomerProfileComp from "../../../../../components/modal/modalBody/customer/Profile";
import { pushModalToStack, popModalFromStack, fetchCustomerDetailsByCustomerId, fetchSources, } from "../../../../../actions/commonActions";
import { resetEnquiryDetail } from "../../../../customer/salesEnquiry/StandardSalesEnquiry/action";
import { fetchCustomerAddresses, getCustomerActivitiesTrack } from "../../../../customer/detail/action";
import {
  getTotalOrdersCount,
  getTotalSalesOrdersCount,
  getTotalUnpaidBalance,
  getTotalSalesRevenue,
} from "../../../../customer/Listing/action";
import { getTotalOrderCountByCustomerId } from "./action";
import { fetchDataIfNeeded } from "../../../../../utils";
import { generatePDF,printPDF } from "../../../../../actions/downloadFileAction";
import { fetchPaidInvoices } from "../PaidInvoices/action";
import { fetchUnpaidInvoices } from "../UnpaidInvoices/action";
import { fetchOpenOrders } from "../OpenOrders/action";
import { fetchAllContacts } from "../../../../customer/Listing/ShowConact/action";

class CustomerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isProfileView: props.isCrmView,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {}
    const payload = {
      customerId: this.props.customerId,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100,
      primaryAddress:{},
      primaryContact:{}
    };

    this.props.fetchCustomerDetailsByCustomerId(payload);
    // this.props.getCustomerCount(payload); 
    this.props.getTotalOrdersCount(payload);
    this.props.getTotalSalesOrdersCount(payload);
    this.props.getTotalUnpaidBalance(payload);
    this.props.getTotalSalesRevenue(payload);
    this.props.getTotalOrderCountByCustomerId(payload);
    fetchDataIfNeeded('fetchSources', 'sources', this.props, payload);
    this.props.fetchPaidInvoices(payload, { companyInfo });
    this.props.fetchUnpaidInvoices(payload, { companyInfo });
    this.props.fetchOpenOrders(payload, {companyInfo});
    this.props.fetchCustomerAddresses(payload);
    this.props.fetchAllContacts(payload);
    // this.props.fetchSources(payload);
  }


  componentWillReceiveProps(props) {
    if (props.customer?.customerId) {
      this.setState({
        customer: props.customer,
        primaryContact: (props.contacts || []).find(
          (obj) => obj.isPrimaryContact === 1),
          primaryAddress: (props.addressList || []).find(
            (obj) => obj.isDefault === 1 
          ),
      })
    }
  }
  // componentWillReceiveProps(props) {
  //   if (props.customer && props.customer.customerId) {
  //     this.setState({
  //       customer: props.customer,
  //     })
  //   }
  // }
 
  render() {
    const linkProps = (this.props.location && this.props.location.state) || {}

    return (
      <CustomerProfileComp
        {...this.props}
        {...this.state}
        updateModalState={(data) => {
          this.setState(data);
        }}
        payload={{
          customerId: this.props.customerId || linkProps.customerId,
          relationshipId: (this.props.companyInfo || {}).relationshipId
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    searchedCustomers: state.customer.customers[1],
    customer: state.common.customerDetail,
    customerOpenOrderCount: state.customer.customerOpenOrderCount,
    customerOpenSOCount: state.customer.customerOpenSOCount,
    customerUnpaidBalance: state.customer.customerUnpaidBalance,
    customerSalesRevenue: state.customer.customerSalesRevenue,
    customerTotalOrderCount: state.customer.customerTotalOrderCount,
    sources: state.common.sources,
    paidInvoiceList: state.customer.paidInvoiceList,
    paidInvoiceData: state.customer.paidInvoiceData,
    unpaidInvoiceList: state.customer.unpaidInvoiceList,
    openOrderList: state.customer.openOrderList,
    addressList: state.customer.addressList,
    contacts: state.customer.contacts


  };
};


const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pushModalToStack,
      popModalFromStack,
      resetEnquiryDetail,
      fetchCustomerDetailsByCustomerId,
      getCustomerActivitiesTrack,
      getTotalOrdersCount,
      getTotalSalesOrdersCount,
      getTotalUnpaidBalance,
      getTotalSalesRevenue,
      getTotalOrderCountByCustomerId,
      fetchSources,
      generatePDF,
      fetchPaidInvoices,
      fetchUnpaidInvoices,
      fetchOpenOrders,
      fetchCustomerAddresses,
      fetchAllContacts,printPDF
    },
    dispatch
  );



export default connect(mapStateToProps, mapDispatchToProps)(CustomerProfile);
