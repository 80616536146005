import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DropdownRef } from '../../../../general/Dropdown';
import { CustomButton } from '../../../../general/CustomButton';
import { Checkbox, Tooltip } from 'antd';
import { TextBox } from '../../../../general/TextBox';
import * as find from 'lodash.find';

class AddInventoryItemLisitngComponent extends Component {

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 24,
                    offset: 0,
                },
            },
        };

        return (
            <Fragment>
                <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
                    <div className="modal-content billing-address-control">

                        <Form.Item label={<FormattedMessage id='setting.marketplaceConfiguration.taxes.model.field.state' defaultMessage='' />} colon={false}>
                            {getFieldDecorator('stateId', {
                                initialValue: this.props.stateId ? this.props.stateId : null,
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id='address.validation.state' defaultMessage='' />,
                                    }]
                            })
                                (<DropdownRef
                                    items={this.props.countryStates[this.props.countryId] || []}
                                    optionKeyName='stateId'
                                    valueKeyName='stateName'
                                    disabled={this.props.marketplaceTaxRateStateId}
                                    onSelect={(selectedValue, option) => {
                                        this.props.updateState({ 'stateId': selectedValue });
                                        this.props.form.setFieldsValue({ 'stateId': selectedValue });
                                        this.props.form.setFieldsValue({ 'stateName': option.props.name });
                                    }}
                                />)}
                        </Form.Item>

                        <Form.Item label={<FormattedMessage id='setting.marketplaceConfiguration.taxes.model.field.taxRate' defaultMessage='' />} colon={false}>
                            {getFieldDecorator('taxRate', {
                                initialValue: this.props.taxRate ? this.props.taxRate : null,
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id='requiredField.missing.msg' defaultMessage='' />,
                                    }]
                            })
                                (<TextBox
                                    type='number'
                                    placeholder={this.props.intl.formatMessage({ id: 'setting.marketplaceConfiguration.taxes.model.field.taxRate', defaultMessage: '' })}
                                    //value={this.props.taxRate}
                                    onChange={(e) => {
                                        this.props.updateState({
                                            taxRate: e.target.value,
                                        });
                                    }}
                                />)}
                        </Form.Item>

                        <Form.Item label={(
                            <Tooltip placement="bottom" title={<FormattedMessage id='setting.marketplaceConfiguration.taxes.model.field.taxOnShipping' defaultMessage="" />}>
                                <span className="tooltip-title"><FormattedMessage id='setting.marketplaceConfiguration.taxes.model.field.taxOnShipping' defaultMessage='' /></span>
                            </Tooltip>
                        )} colon={false}>
                            {getFieldDecorator('isTaxOnShipping', {
                                initialValue: this.props.isTaxOnShipping
                            })(<Checkbox checked={this.props.isTaxOnShipping ? true : false} onChange={(e) => {
                                this.props.form.setFieldsValue({ 'isTaxOnShipping': e.target.checked });
                                this.props.updateState({
                                    isTaxOnShipping: e.target.checked
                                })
                            }} className='login-form-checkbox'></Checkbox>)}
                        </Form.Item>

                    </div>
                    <Form.Item {...tailFormItemLayout} className="modal-footer">
                        <div key={0} className='customModal-button-group'>
                            <CustomButton
                                intlId={'modal.address.button.cancel'}
                                defaultMessage={''}
                                type={'default'}
                                key={1}
                                onClick={() => {
                                    if (((this.props.data || {}).formData || {}).onCancel) {
                                        this.props.data.formData.onCancel();
                                    }
                                    this.props.hideModal();

                                }}
                            />
                            <CustomButton
                                intlId={'button.save.label'}
                                defaultMessage={''}
                                htmlType='submit'
                                key={2}
                            />
                        </div>
                    </Form.Item>
                </Form>
            </Fragment >
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const selectedState = this.props.countryStates[this.props.countryId].find((element) => {
                    return element.stateId === this.props.stateId;
                });

                if (!this.props.marketplaceTaxRateStateId) {
                    let alreadyExist = find(this.props.configuredStates, { stateId: Number(this.props.stateId) });
                    if (alreadyExist) {
                        alert("Selected State is already added.");
                        return;
                    }
                }

                const marketplaceStateTaxPayload = {
                    marketplaceTaxRateStateId: this.props.marketplaceTaxRateStateId,
                    stateId: this.props.stateId,
                    stateName: selectedState.stateName,
                    marketplaceTaxCountryId: this.props.marketplaceTaxCountryId,
                    //stateCodeManual: values.streetAddress1,
                    //stateNameManual:,
                    relationshipId: this.props.relationshipId,
                    //stateCodeAutomatic:,
                    //stateNameAutomatic,
                    //isAutomatic:,
                    isTaxOnShipping: this.props.isTaxOnShipping ? 1 : 0,
                    taxRate: this.props.taxRate

                }

                const modalData = {
                    modalBody: this.props.marketplaceTaxRateStateId ? 'Are you sure you want to Update State Tax Rate?' : 'Are you sure you want to Add State Tax Rate?',
                    handleSubmit: () => {
                        this.props.saveUpdateMarketplaceStateTax(marketplaceStateTaxPayload, this.props);
                        this.props.popModalFromStack();
                        this.props.hideModal();
                    }
                };
                this.props.pushModalToStack(modalData);
            }
        });
    };
}

export default Form.create({ name: 'add_marketplace_state_tax_component' })(injectIntl(AddInventoryItemLisitngComponent));
