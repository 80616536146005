import axios from 'axios';
import config from '../../../../../../config/environmentConfig';
import { CONSTANTS, LEDGER_ACTION_LIST } from '../../../../../../static/constants';
import { showToasterMessage, getFinancialYear, formatDateForBackend, getCurrentDateForBackend } from '../../../../../../utils';
const { lang } = require('../../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const createStatementPayment = ( props) => {
  const payload = _getPaymentData(props.bankTransactions, props);
  return dispatch => {
   // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankReco/saveInBatch`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.hideModal();
        let data = {
          relationshipId: props.companyInfo.relationshipId || 0,
          pageSize: 100,
          pageNumber: 1,
          companyInfo: props.companyInfo
        }
        props.fetchCustomerPayments && props.fetchCustomerPayments(data)(dispatch);
        showToasterMessage({ messageType: 'success',  description: lang['success.uploaded.message'] });
        props.fetchSalesInvoices && props.fetchSalesInvoices(data)(dispatch);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        //showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

const _getPaymentData = (paymentList, props) => {
  let deposit = [];
  let withdrawal = [];

  let createdByUserId = props.userInfo.userId || 0;
  let relationshipId = props.companyInfo.relationshipId || 0;
  let finYear = getFinancialYear(props.companyInfo);

  (paymentList || []).forEach((payloadData) => {
    let txAmount = Number(payloadData.Deposit || 0) || Number(payloadData.Withdrawal || 0); 
    let txDate = new Date(payloadData.date || payloadData.Date)
    let payload = { 
      description : payloadData.description || payloadData.Description,
      customerName : payloadData.customerName,
      customerId : payloadData.customerId,
      supplierName : payloadData.supplierName,
      supplierId : payloadData.supplierId,
      projectName : payloadData.projectName,
      //customerName : payloadData.customerName,
      projectMasterId : payloadData.projectMasterId,
      projectNumber : payloadData.projectNumber,
      instrumentNumber : payloadData.instrumentNumber,
      instrumentDate : payloadData.instrumentDate ? formatDateForBackend(payloadData.instrumentDate) : null,
      isBankRecoTx: 1,
      paymentSource: CONSTANTS.PAYMENT_SOURCE.CSV_UPLOAD,
    };
   // let customerName = payloadData.customerName;
    payload.paymentDate = formatDateForBackend(txDate);
    payload.currencyCode = payloadData.currencyCode || props.companyInfo.currencyCode;
    payload.createdByEmpId = props.userInfo && props.userInfo.relationshipEmployeeId;
    payload.createdByUserId = createdByUserId;
  
    let txList = {
      fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
      relationshipId: relationshipId,
      dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      txType: 'Payment',
      txDate: formatDateForBackend(txDate),
      accountingEntry: 'CREDIT',
      invoiceMasterId: payloadData.invoiceMasterId,
      remarks: payloadData.remarks,
      amount: Math.abs(txAmount),
      ledgerAccountId: payloadData.salesLedgerId,
      //customerId: payloadData.customerId,
    //  customerName: customerName,
    };
  
    var txList1 = {
      yStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
      relationshipId: relationshipId,
      dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      txType: 'Payment',
      txDate: formatDateForBackend(txDate ),
      accountingEntry: 'DEBIT',
      invoiceMasterId: payloadData.invoiceMasterId,
      remarks: payloadData.remarks,
      amount: Math.abs(txAmount),
      ledgerAccountId: payloadData.purchaseLedgerId,
      // customerId: payloadData.customerId,
      // customerName: customerName,
    };
  
    var customerTxPayment = {
      relationshipId: relationshipId,
      txAmount: Math.abs(txAmount),
      paymentModeDetail: payloadData.paymentModeDetail,
      fyStartDate: finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate),
      fyEndDate: finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate),
      status: 'Processed',
      statusColor: 'success',
      txDate: formatDateForBackend(txDate),
      txType: 'Payment',
      invoiceNumber: payloadData.invoiceNumber,
      invoiceMasterId: payloadData.invoiceMasterId,
      isMultiCurrency: payloadData.isMultiCurrency,
      exchangeRate: payloadData.exchangeRate,
      remarks: payloadData.remarks,
      invoiceDueDate: payloadData.invoiceDueDate,
      dateCreated: getCurrentDateForBackend(new Date()),
      createdByUserId: createdByUserId,
      customerId: payloadData.customerId,
      soMasterId: payloadData.salesOrderMasterId,
    }
    payload.customerId = payloadData.customerId;
    payload.fyStartDate = finYear && finYear.fyStartDate && formatDateForBackend(finYear.fyStartDate);
    payload.fyEndDate = finYear && finYear.fyEndDate && formatDateForBackend(finYear.fyEndDate);
    payload.remarks = payloadData.remarks;
    payload.soNumber = payloadData.soNumber;
    if (Number(payloadData.Deposit || 0) > 0 ) {
      payload.coaTxPaymentsList = [txList, txList1];
    } else {
      payload.coaTxPaymentList = [txList, txList1];
    }
    payload.txDate = formatDateForBackend(txDate);
    payload.customerTxPaymentList = [customerTxPayment];
  
    payload.invoiceAmount = payloadData.invoiceTotalAmount;
    payload.relationshipId = relationshipId;
    payload.chartOfAccountDetailsId = payloadData.chartOfAccountDetailsId;
    payload.txType = 'Payment';
    payload.remarks = payloadData.remarks;
    payload.invoiceMasterId = payloadData.invoiceMasterId;
    payload.status = "Processed";
    payload.statusColor = "success";
    payload.invoiceNumber = payloadData.invoiceNumber;
    payload.txNumber = payloadData.invoiceNumber;
    payload.paymentAmount = Math.abs(txAmount);
    payload.isMultiCurrency = payloadData.isMultiCurrency;
    payload.currencyCode = payloadData.isMultiCurrency ? payloadData.foreignCurrency : props.companyInfo.currencyCode;
    payload.exchangeRate = payloadData.exchangeRate;
    payload.templateCode = payloadData.templateCode;
    payload.relationshipName = props.companyInfo.storeName;
    payload.projectNumber = payloadData.projectNumber;
    payload.projectName = payloadData.projectName;
    payload.projectMasterId = payloadData.projectMasterId;
  //  payload.customerName = customerName;
    payload.rfqNumber = payloadData.rfqNumber;
    payload.rfqMasterId = payloadData.rfqMasterId;
    payload.salesQuotationMasterId = payloadData.salesQuotationMasterId;
    payload.quotationNumber = payloadData.quotationNumber;
    payload.transactionId = payloadData.transactionId;
    payload.institutionName = payloadData.institutionName;
    payload.paymentChannel = payloadData.paymentChannel;
    payload.merchantName = payloadData.merchantName;
    payload.reference = Number(payloadData.Deposit || 0) > 0 ? lang['diposit'] : lang['withdrawal'];
    Number(payloadData.Deposit || 0) > 0 ? deposit.push(payload) : withdrawal.push(payload);
    
  })
 

  return {
    deposit: deposit,
    withdrawal: withdrawal,
    uploadedByEmail: props.userInfo.email,
    relationshipId: relationshipId,
    bankStatementUploadDraftId: props.bankStatementUploadDraftId
  };
}

export const fetchAllLedgerAccounts = (payload) => {
  return dispatch => {
   // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: LEDGER_ACTION_LIST.SHOW_ALL_LEDGER_ACCOUNTS });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/chartOfAccountDetails/getAllLedgerAccountsSummary?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: LEDGER_ACTION_LIST.ALL_LEDGER_ACCOUNTS_SUMMARY, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = LEDGER_ACTION_LIST.ALL_LEDGER_ACCOUNTS_SUMMARY;
        }
      })
  }
}

export const saveAsDraft = (props) => {
  const payload = {
    statementData: JSON.stringify(props.bankTransactions),
    relationshipId: props.companyInfo.relationshipId,
    dateCreated: getCurrentDateForBackend(new Date()),
    bankStatementUploadDraftId: props.bankStatementUploadDraftId,
    version: (props.statementDraftData || {}).version
  }
  return dispatch => {
   // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankStatementUploadDraft/saveOrUpdateBankStatementUploadDraft`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.hideModal();
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}