import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormDetailsComponent from '../../../../../components/modal/modalBody/schoolManagement/FormDetails';
import { showModal, pushModalToStack, fetchCustomerDetailsByCustomerId } from '../../../../../actions/commonActions';
import { fetchRegistrationFormSetting } from '../../settings/RegistrationFormSetting/action';
import { getStudentsEducation } from './action';
import * as find from 'lodash.find';

class FormDetails extends Component {

    constructor(props){
        super(props);
        this.state= {
            customerId: props?.customerData?.customerId,
            pageNumber:1
        }
    }

    componentDidMount(){
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            registrationFormSettingId: this.props.formDetailsData.registrationFormSettingId,
            customerId: this.props?.customerData?.customerId || 0,
        };

        this.props.fetchRegistrationFormSetting && this.props.fetchRegistrationFormSetting(payload);
        this.props.getStudentsEducation(payload);
        this.props.fetchCustomerDetailsByCustomerId(payload);
    }

    componentWillReceiveProps(props) {
        if (props.registrationFormSetting && props.registrationFormSetting.registrationFormSettingId) {
            let formSetting = JSON.parse(props.registrationFormSetting.formSetting);
            let onetimeInvoiceItem = JSON.parse(props.registrationFormSetting.onetimeInvoiceItem || '[]')
       
            this.setState({
                formSetting: {...this.state.formSetting, ...formSetting, ...{onetimeInvoiceItem: onetimeInvoiceItem }},
            })
        }
        if (props.customerDetail && props.customerDetail.customerId) {
            const customer = props.customerDetail || {};
            const defaultBillingAddress = (customer.boLocationList || [])[0] || {};
            let studentRegistration = find(props.customerDetail.orgRegistrationList || [], { studentRegistrationId: this.props.registrationData.studentRegistrationId }) || {};
            this.setState({
                ...studentRegistration,
                ...defaultBillingAddress,
                ...customer,
                registrationVersion: studentRegistration.version,
                addressVersion: defaultBillingAddress.version,
                formSetting: { ...this.state.formSetting, questionList: JSON.parse(studentRegistration.questionList), selectedPlan: JSON.parse(studentRegistration.selectedPlan), onetimeInvoiceItem: JSON.parse(studentRegistration.onetimeInvoiceItem) },
            })
            props.resetCustomerDetail();
          }
          
    }

    render() {
        return <FormDetailsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        registrationFormSetting: state.settings.registrationFormSetting,
        educationList: state.common.educationList,
        fullCustomerDetails: state.common.customerDetail
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    pushModalToStack,
    fetchRegistrationFormSetting,
    getStudentsEducation,
    fetchCustomerDetailsByCustomerId
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormDetails);