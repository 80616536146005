import { Col, Drawer, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { ErrorMsg } from "../../../general/ErrorMessage";

const LeadStageDrawer = (props) => {
  const rowGutter = [24, 16];


  const closeDrawer = () => {
    props.updateState({
        leadStageListDrawer: false,
        
    });
  };
  return (
    <Drawer
      title={<FormattedMessage id={props.leadStatusId ? "leads.leadForm.formMeta.leadStage.drawer.title.edit"  : "leads.leadForm.formMeta.leadStage.drawer.title.create"} />}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.leadStageListDrawer}
      
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />

          <CustomButton
            intlId="button.save.label"
            defaultMessage=""
            htmlType="submit"
            onClick={()=>{
                props.updateDrawerState({submitted: true})
                if(!props.statusName){
                    return
                }
                const payload = {
                    relationshipId: props.companyInfo.relationshipId,
                    // leadStatusId: props.leadStatusId || 0,
                    statusName: props.statusName,
                    createdByUserId: props.createdByUserId,
                    updatedByUserId: props.updatedByUserId
                }
                props.createLeadStage(payload, props);
                closeDrawer();
            }}
            key={1}
          />
        </>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">Stage Name</div>
          <TextBox
            placeholder="Stage Name"
            type="text"
            maxLength={20}
            value={props.statusName}
            onChange={(e) => {
              props.updateDrawerState({ statusName: e.target.value });
            }}
          />
            <ErrorMsg
                validator={() => { return !props.submitted || props.statusName }}
                message={<FormattedMessage id='requiredField.missing.msg' />}
            />
        </Col>

      </Row>
    </Drawer>
  );
};

export default LeadStageDrawer;
