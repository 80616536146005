import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AccountComp from "../../../components/communication/account";
import { fetchDataIfNeeded } from "../../../utils";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../actions/commonActions';
import { createSubAccount, getSubAccount, getText2DonateConfig, updatePhoneNumber } from './action';
//import EmailComponent from '../../drawer/email';
import EmailComponent from '../../drawer/communication/account/brodcast-email';
import AccSetupComponent from '../../drawer/communication/account/setup';
import { FormattedMessage } from 'react-intl';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailDrawerVisible: false,
      accSetupDrawerVisible: false,
      type:'sales_order',
    }
  }

  componentDidMount() {
    let self = this;
    this.props.updateHeaderState({
      collapsed: true,
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.fundRaising.communications.account' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
        name: <FormattedMessage id='sidebar.menuItem.communication' defaultMessage='' />
      },
      {
        name: <FormattedMessage id='sidebar.menuItem.account' defaultMessage='' />,
      }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
    };
    //fetchDataIfNeeded("getSubAccount", "subAccount", this.props, payload);
    this.props.getSubAccount(payload, function (stat) {self.setState({isSubAccountLoaded : stat}); });
    fetchDataIfNeeded("getText2DonateConfig", "accountConfigPh", this.props, {relationshipId: companyInfo.relationshipId, source: 'sms'});
    fetchDataIfNeeded("getText2DonateConfig", "accountConfigWhatsApp", this.props, {relationshipId: companyInfo.relationshipId, source: 'whatsapp'});
  }

  componentWillReceiveProps(props) {

  }

  render() {

    return <div>
      {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      {this.state.accSetupDrawerVisible && <AccSetupComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      {this.state.isSubAccountLoaded && <AccountComp  {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}> 

      </AccountComp>}
    </div>


  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    subAccount: state.communicationReducer.subAccount,
    accountConfigPh: state.communicationReducer.accountConfigPh,
    accountConfigWhatsApp: state.communicationReducer.accountConfigWhatsApp,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createSubAccount,
      getSubAccount,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getText2DonateConfig,
      updatePhoneNumber
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Account);
