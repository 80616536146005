import React from 'react';
//import { FormattedMessage } from 'react-intl';
import { FilePdfOutlined, MailOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button} from 'antd'
import * as find from 'lodash.find';
import { CONSTANTS, TRANSACTION_TYPES } from '../../../../../static/constants';
//import CertificateOfConformity from '../CertificateOfConformity';
//import { getCurrentDateForBackend } from '../../../../../utils'
import StandardTemplate from "./standard";
import Template2 from "./template2";
import { Dropdown } from '../../../../general/Dropdown';

const ShippingInvoiceDetails = (props) => {
    const { shipmentData, soData = {}, companyInfo, shippingInvoiceData, templateName = 'Professional' } = props;
    // const relationshipAddress = find(companyInfo.boLocationList, { locationId: parseInt(shipmentData.billingLocationId) }) || {};
    // const shippingAddress = find(customerData.boLocationList, {locationId: parseInt(shipmentData.shippingLocationId)}) || {};
    const relationshipAddress = find((shippingInvoiceData || {}).boLocationShipmentList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find((shippingInvoiceData || {}).boLocationShipmentList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    const shippingAddress = find((shippingInvoiceData || {}).boLocationShipmentList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
    const additionalInfo = ((shippingInvoiceData || {}).additionalInfoList || [])[0] || {}
    

        const _isColumnToHide = (columnName) => {
            // if (props.forceHideTxColumn && props.forceHideTxColumn.indexOf(columnName) > -1) {
            //     return true;
            // }
            return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
        }

        let isColumnEmpty = {
            salesOrderNumber: true,
            packageNumber: true,
            discount: true,
            tax: true
        };

        const getEmptyColumns = (notesData)=>{
            if(!notesData) return isColumnEmpty;
            for (let i in notesData) {
                let row = notesData[i];
                if (row.discount && Number(row.discount) !== 0) { isColumnEmpty.discount = false; }
                if (row.tax && Number(row.tax) !== 0) { isColumnEmpty.tax = false; }
            }
            isColumnEmpty.salesOrderNumber = _isColumnToHide('salesOrderNumber');
            isColumnEmpty.packageNumber =  _isColumnToHide('packageNumber');
            return isColumnEmpty;
        }

    const getPayloadForPdf = () => {
        const shipingData = shipmentData;
        shipingData.footer = shippingInvoiceData.footer;
        shipingData.remarksCustomer = shippingInvoiceData.remarksCustomer;
        shipingData.remarksInternal = shippingInvoiceData.remarksInternal;
        isColumnEmpty = getEmptyColumns((shippingInvoiceData || {}).customerSOShipmentDetailsList)
        // const customerSOPackageDetailsListToSend = [].concat((shippingInvoiceData || {}).customerSOShipmentDetailsList);
        // customerSOPackageDetailsListToSend.forEach((e)=>{
        //     e.itemName = e.itemVariant ? (e.itemName+ e.itemVariant) : e.itemName
        // })
        return {
            templateName: templateName,
            fileName: (shipmentData.shipmentNumber ? 'INV-'+shipmentData.shipmentNumber : 'Shipping Invoice')+'.pdf',
            transactionName: 'shippingInvoice',
            documentName: 'Shipping Invoice',
            relationshipAddress: relationshipAddress,
            shippingAddress: shippingAddress,
            billingAddress: billingAddress,
            isColumnEmpty: isColumnEmpty,
            soData: soData,
            companyInfo: companyInfo,
            itemLevelTaxes: props.itemLevelTaxes,
            isPlaceOfSupplySameAsRelationship : (props.relationshipBillingAddress || {}).stateName === props.placeOfSupply,
            subTotal: props.subtotal,
            total: props.total,
            totalDiscount: props.totalDiscount,
            shipmentData: shipingData,
            deliveyNotesData: (shippingInvoiceData || {}).customerSOShipmentDetailsList,
            additionalInfo: additionalInfo,
            txColumns: props.txColumns || []
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () =>{
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired = true;
        payload.customerId = shipmentData.customerId;
        payload.invoiceMasterId = shipmentData.invoiceMasterId;
        props.updateShippingState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.SHIPMENT,
        });
    };


    return (
        <div className={"custom-modal show"}>

            {templateName === 'Standard' && <StandardTemplate {...props} />}
            {templateName === 'Professional' && <Template2 {...props} />}

            <div className="modal-dialog">
                <div className="modal-content full-width">

                    <div className=" button-group-action header-control">
                        {/* <div className="small-btn-group">
                            <Button title='Edit'
                                icon={<EditOutlined />}
                                onClick={() => {
                                    updateShipment(props, shipmentData, true);
                                }}
                            >
                            </Button>
                            <Button
                                title='Clone'
                                icon={<CopyOutlined />}
                                onClick={() => {
                                    updateShipment(props, shipmentData, false);
                                }}
                            >
                            </Button>
                            <Button
                                title='Delete'
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    props.showModal({
                                        modalBody: "Are you sure you want to delete this transaction?",
                                        handleSubmit: () => {
                                            props.deleteShipment({
                                                shipmentMasterId: shipmentData.shipmentMasterId,
                                                customerId: shipmentData.customerId,
                                                relationshipId: shipmentData.relationshipId
                                            })
                                        }
                                    })
                                }}
                            >
                            </Button>
                        </div> */}
                        <div className="small-btn-group">

                            <Button
                            style={{height:'38px'}}
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                icon={<MailOutlined />}
                                onClick={() => {
                                    openEmailDrawer(props);
                                }}
                            >
                            </Button>

                            <Button
                            style={{height:'38px'}}
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                    generatePDF();
                                }}
                            >
                            </Button>
                            <Button
                            style={{height:'38px'}}
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                icon={<PrinterOutlined />}
                                onClick={() => {
                                    printPdf();
                                }}
                            >
                            </Button>
                        </div>

                        {/* {shipmentData.status === "Delivered" ?
                            <Button title='Certifcate of Conformity'
                                onClick={() => {
                                    props.showModal({
                                        modalBody: "Are you sure you want to change the status?",
                                        handleSubmit: () => {
                                            props.markAsDelivered({
                                                relationshipId: (props.companyInfo || {}).relationshipId,
                                                shipmentMasterId: shipmentData.shipmentMasterId,
                                                status: "Shipped",
                                                lableColor: 'shipped',
                                                customerId: shipmentData.customerId,
                                                createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                                            }, props)
                                        }
                                    })
                                }}
                            >Mark As Shipped
                        </Button> :
                            <Button title='Certifcate of Conformity'
                                onClick={() => {
                                    props.showModal({
                                        modalBody: "Are you sure you want to change the status?",
                                        handleSubmit: () => {
                                            props.markAsDelivered({
                                                relationshipId: (props.companyInfo || {}).relationshipId,
                                                shipmentMasterId: shipmentData.shipmentMasterId,
                                                status: "Delivered",
                                                lableColor: 'delivered',
                                                customerId: shipmentData.customerId,
                                                createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                                            }, props)
                                        }
                                    })
                                }}
                            >Mark As Delivered
                    </Button>
                        }

                        <Button title='Certifcate of Conformity'
                            onClick={() => {
                                openCertifcateOfConformity(shipmentData);
                            }}
                        >Certifcate of Conformity
                        </Button>

                        <Button title='Update Delivery Date'
                            onClick={() => {
                                updateActualDeliveryDate(props, shipmentData);
                            }}
                        >Update Delivery Date
                        </Button>
*/}
                        <div className="">
                            <Dropdown style={{'width': '130px'}}
                                placeholder="Template"
                                items={['Standard', 'Professional']}
                                onSelect={(selectedValue) => {
                                    props.updateShippingState({
                                        templateName: selectedValue,
                                    });
                                }}
                            ></Dropdown>
                        </div> 

                    </div>
                </div>
            </div>
        </div>
    );

}


export default ShippingInvoiceDetails;
