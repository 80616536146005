import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextBox } from "../../../../general/TextBox";
import { Row, Col, Button, Divider } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Google from "../../../../../assets/images/google.png";
import Meta from "../../../../../assets/images/meta.png";


function AnalyticsComp(props) {

    const handelSave = (type) => {
        let data = {
            siteVisitCode: props.siteVisitCode,
            googleTagManagerCode: props.googleTagManagerCode,
            metaPixelCode: props.metaPixelCode,

        };

        const payload = {
            relationshipId: props.relationshipId,
            isActive: 1,
            ecomSettingId: props.storeValues.ecomSettingId,
            analytics: JSON.stringify(data || "{}")
        };
        props.updateAnalytics(payload, props, type);
    }

    return <>

        <div className="store-model-container cus-space">
            <div className="heading">
                <FormattedMessage id="eStore.storeSettings.analytics.label" defaultMessage="" />
            </div>
            <FormattedMessage id="eStore.storeSettings.analytics.subheading" defaultMessage="" />
            <div className="model-container pt20">
                <Row gutter={[16, 8]}>
                    <Col span={24} className="flex-item strong-txt">

                        <img src={Google} height="25px" width="25px" />
                        Google Site Verification Code</Col>


                    <Col span={22}>
                        <TextBox
                            value={props.siteVisitCode}
                            onChange={(e) => {
                                props.updateState({
                                    siteVisitCode: e.target.value,
                                })
                            }} />
                    </Col>
                    <Col span={22} className="f-btn">
                        <Button
                            onClick={() => {

                                handelSave("Google Site Verification Code");
                            }}
                            type="primary">Save & Submit</Button>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col span={24} className="strong-txt flex-item">
                        <img src={Google} height={25} width={25} />
                        Google Tag Manager Code</Col>
                    <Col span={22}>
                        <TextArea
                            value={props.googleTagManagerCode}
                            onChange={(e) => {
                                props.updateState({
                                    googleTagManagerCode: e.target.value,
                                })
                            }}
                            style={{ resize: "none", height: "200px" }} />
                    </Col>
                    <Col span={22} className="f-btn">
                        <Button onClick={() => {

                            handelSave("Google Tag Manager Code");
                        }} type="primary">Save & Submit</Button>
                    </Col>
                </Row>
                <Col span={22}>
                    <Divider />

                </Col>

                <Row gutter={[16, 8]}>
                    <Col span={24} className="strong-txt">
                        <img src={Meta} className="mr5" height="20px" width="20px" />
                        Meta Pixel Code</Col>
                    <Col span={22}>
                        <TextArea
                            value={props.metaPixelCode}
                            onChange={(e) => {
                                props.updateState({
                                    metaPixelCode: e.target.value,
                                })
                            }}
                            style={{ resize: "none", height: "200px" }} />
                    </Col>
                    <Col span={22} className="f-btn">
                        <Button onClick={() => {

                            handelSave("Meta Pixel Code");
                        }} type="primary">Save & Submit</Button>
                    </Col>
                </Row>
            </div>
        </div>
    </>
}
export default AnalyticsComp;