



import React from "react";
import ReactApexChart from "react-apexcharts"

class salesStatisticChart extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      //   series: [{
      //       name: `Paid in ${(this.props.companyInfo || {}).currencyCode + " "}`,
      //       data: Object.values(this.props.salesData || {})?.map((e) => {

      //         return e?.paidAmount?Math.round(e?.paidAmount):0;
      //       })
      //   },{
      //       name: "Due",
      //       data: Object.values(this.props.salesData || {})?.map((e) => {

      //         return e?.salesAmount?Math.round( (e?.salesAmount) - (e?.paidAmount)):0  
      //       })

      //   },{
      //     name: "Total Amount",
      //     data: Object.values(this.props.salesData || {})?.map((e) => {  
      //       return  e?.salesAmount?Math.round(e?.salesAmount):0;
      //     })  
      // }],
      //   options: {
      //     legend: {
      //       position: 'top',
      //       offsetX: 0,
      //       offsetY: 0,
      //       horizontalAlign: 'right'
      //     },
      //     chart: {
      //       background: '#fff',
      //       height: 'auto',
      //       type: 'line',
      //       zoom: {
      //         enabled: false
      //       },
      //       toolbar: {
      //         show: false
      //       },
      //     },




      //     colors: ['#1CB980','#FF000D','#3150B9' ],
      //     stroke: {
      //       width: 3,
      //       curve: 'straight'
      //     },

      //     grid: {
      //       row: {
      //         colors: ['#fff', 'transparent'], // takes an array which will be repeated on columns
      //         opacity: 0.5
      //       },
      //     },
      //     xaxis: {

      //       categories: Object.keys(this.props.salesData || {}),
      //     }
      //   },


    };
  }



  render() {

    let series = [{
      name: `Paid in ${(this.props.companyInfo || {}).currencyCode + " "}`,
      data: Object.values(this.props.salesData || {})?.map((e) => {

        return e?.paidAmount ? Math.round(e?.paidAmount) : 0;
      })
    }, {
      name: "Due",
      data: Object.values(this.props.salesData || {})?.map((e) => {

        return e?.salesAmount ? Math.round((e?.salesAmount) - (e?.paidAmount)) : 0
      })

    }, {
      name: "Total Amount",
      data: Object.values(this.props.salesData || {})?.map((e) => {
        return e?.salesAmount ? Math.round(e?.salesAmount) : 0;
      })
    }];

    const options = {
      legend: {
        position: 'top',
        offsetX: 0,
        offsetY: 0,
        horizontalAlign: 'right'
      },
      chart: {
        background: '#fff',
        height: 'auto',
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },




      colors: ['#1CB980', '#FF000D', '#3150B9'],
      stroke: {
        width: 3,
        curve: 'straight'
      },

      grid: {
        row: {
          colors: ['#fff', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {

        categories: Object.keys(this.props.salesData || {}),
      }
    };


    return (


      <div id="chart">
        {

          <ReactApexChart options={options} series={series} type="line" height={260} />
        }
      </div>);
  }
}
export default salesStatisticChart;