import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaymnetSetupComp from '../../../components/B2B/PayentSetup/ProccessPaymnet';
import { getAuthNetPaymentUrl, getFormToken, fetchRelationshipDataByRID, paymentSuccess } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchProjects } from '../../../actions/commonActions';
import { getAllFundraisingCampaign } from '../../drawer/fundraising/pledges/action';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { getCurrentDateForBackend } from '../../../utils';
import { CONSTANTS } from '../../../static/constants';

class PaymentSetup extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(this.props.location.search) || {};
    
    let ids = (linkProps.t || '').split("_");
    let teamIds = (linkProps.f || '').split("_");
    let registrationData = (linkProps.r || '').split("_");
    let cName = (linkProps.cName || '').split("_");
  //  t='rid_paymentGatewayId_sourceId_projectMasterId'
  //t=rid_ paymentGatewayId_source_ projectMasterId_ledgerAccountId _ amount _ fundraisingCampaignId
  // t='17_22_1'
    this.state = {
      ...props,
      period: 'oneTimePayment',
      relationshipBillingAddress: {},
      neverExpires: 1,
      relationshipId: Number(ids[0]),
      paymentGatewayId: Number(ids[1]),
      sourceId: Number(ids[2]),
      projectMasterId: Number(ids[3]),
      ledgerAccountId: Number(ids[4]),
      amountToDonate: Number(ids[5] || 0),
      fundraisingCampaignId: Number(ids[6]),
      isCustomeAmount: Number(ids[5] || 0) ? true: false,
      pledgeId: Number(ids[7]) || 0,
      teamMasterId: teamIds[0],
      teamMemberId: teamIds[1],
      onlineThemes: {},
      customerId: registrationData[0],
      studentRegistrationId: registrationData[1],
      email: registrationData[2],
      registrationFormSettingId: registrationData[3],
      firstName: cName[0],
      lastName: cName[1], 
    }
  }

  componentDidMount() {
    let self = this;
    const linkProps = queryString.parse(this.props.location.search) || {};
    let ids = (linkProps.t || '').split("_");
    const payload = {
      relationshipId: Number(ids[0]),
      projectMasterId: Number(ids[3]),
      fundraisingCampaignId: Number(ids[6]),
      pageSize: 1000
    };
    this.props.fetchRelationshipDataByRID(payload, this.props);
    this.props.fetchProjects(payload, this.props);
    this.props.getAllFundraisingCampaign(payload, this.props);

    if (linkProps.r) {
      let cName = (linkProps.cName || '').split("_");
      let registrationData = (linkProps.r || '').split("_");
      let tokenPayload = {
        relationshipId: Number(ids[0]),
        paymentGatewayId: Number(ids[1]),
        amount: Number(ids[5] || 0),
        color: "Blue",
        payButtonName: "Donate",
        isCardCodeRequired: 0,
        isShowCreditCard: 1,
        isShowBankAccount: 0,
        isShowCaptcha: 0,
        isShowBillingAddress: 1,
        isBillingAddressRequired: 1,
        isShowEmail: 1,
        isEmailRequired: 1,
        isAddPaymentProfile: 0,
        startDate: getCurrentDateForBackend(new Date()),
        totalOccurrences: 1,
        projectMasterId: Number(ids[3]),
        ledgerAccountId: Number(ids[4]),
        fundraisingCampaignId: 0,
        emailAddress: registrationData[2],
        email: registrationData[2],
        isAnonymous: 0,
        studentRegistrationId: registrationData[1],
        registrationFormSettingId: registrationData[3],
        firstName: cName[0],
        lastName: cName[1],
      }
      this.props.getFormToken(tokenPayload, this.props, function (data) { self.setState(data) });
    }
  }

  componentWillReceiveProps(props) {
    if (props.companyInfo && props.companyInfo.relationshipId) {
      const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
      const onlineThemes = JSON.parse( props.companyInfo.onlineThemes || '{}');
      this.setState({
        onlineThemes,
        relationshipBillingAddress,
        companyInfo: props.companyInfo,
      })
    }
    if (props.allCampaignList) {
      this.setState({
        allCampaignList: props.allCampaignList,
      })
    }
    if (props.projectList) {
      this.setState({
        projectList: props.projectList,
      })
    }
    if (props.allCampaignList && props.allCampaignList.length && !this.state.selectedCampaign && this.state.fundraisingCampaignId) {
      const selectedCampaign = find(props.allCampaignList || [], { fundraisingCampaignId: Number(this.state.fundraisingCampaignId) });
      this.setState({
        selectedCampaign
      })
    }

    if (props.projectList && props.projectList.length && !this.state.selectedProject && this.state.projectMasterId) {
      const selectedProject = find(props.projectList || [], { projectMasterId: Number(this.state.projectMasterId) });
      this.setState({
        selectedProject
      })
    }
  }

  render() {
    return (
      <Fragment>
        <PaymnetSetupComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.settings.relationshipData,
    projectList: state.common.projectList,
    allCampaignList: state.fundraisingReducer.allCampaignList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  getAuthNetPaymentUrl,
  getFormToken,
  fetchRelationshipDataByRID,
  paymentSuccess,
  fetchProjects,
  getAllFundraisingCampaign
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSetup);
