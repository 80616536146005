import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DistributionGroupComp from "../../../components/communication/distribution-group";
import { fetchDataIfNeeded } from "../../../utils";
import { getAllDG, deleteDG, getAllDGCount } from "./action";
import { showModal, hideModal } from './../../../actions/commonActions';

class DistributionGroup extends Component {


  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
    };
    fetchDataIfNeeded("getAllDG", "dgList", this.props, payload);
    // this.props.getAllDGCount(payload);
  }

  render() {
    return <DistributionGroupComp  {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}/>;
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    dgList: state.communicationReducer.dgList,
    dgCount: state.communicationReducer.dgCount,
    listLoading: state.common.listLoading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllDG,
      deleteDG,
      showModal,
      hideModal,
      getAllDGCount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DistributionGroup);
