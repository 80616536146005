import React, { Fragment, useState, } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col } from 'antd';
import { CustomButton } from '../../../../../general/CustomButton';
import { TextBox } from '../../../../../general/TextBox';
import './index.scss';
import { MODAL_TYPE } from '../../../../../../static/constants';
import debounce from 'lodash.debounce';
import { showToasterMessage } from '../../../../../../utils';


function FundraiserDrawer(props) {

    const { searchFundraiser } = props;

    const [teamData, setTeamData] = useState(props.teamData);



    const closeDrawer = () => {
        props.updateHeaderState({
            fundraising: {
                addTeamDrawerVisible: false
            }
        });
    }


    const handleSubmit = (data) => {
        let formData = {
            isFundraiser: true,
            teamMasterId: data.teamMasterId,
            funcToCall: () => {
                props.getAllTeamLead({
                    relationshipId: (props.companyInfo || {}).relationshipId, pageNumber: 0,
                    pageSize: 100,
                });
            }
        };
        const payloadData = {
            title: <div>
                <FormattedMessage id='addItem.text.createFundraiser' defaultMessage='' />
                <div style={{ 'font-size': '12px' }}> {(data.firstName || "") + " " + (data.lastName || "")}</div>
            </div>,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.CONTACT,
                data: { formData },

            },
            handleSubmit: (formData = {}) => {
                props.hideModal(data);

            }
        };
        props.showModal(payloadData);
    }

    const handleSearchedText = (searchedText, props) => {
        const payload = {
            relationshipId: (props.companyInfo || {}).relationshipId,
            pageNumber: 0,
            pageSize: 100,
            searchName: searchedText
        };

        props.getAllTeamLead(payload);
    }

    const onSearchedInputChange = debounce((searchedText, props) => {
        handleSearchedText(searchedText, props)
    }, 500);

    const addFundraiserToTeam = (data) => {
        if (!data.fundraiserGoal) {
            return showToasterMessage({ messageType: 'error', description: 'Please enter the goal' })
        }
        const modalBody = <span>
            <FormattedMessage
                id='addFundraiser.confirmation'
                values={{ value: `${data.firstName + " " + data.lastName} to the team` }}
                defaultMessage=''
            />
        </span>;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.saveTeamMember({
                    contactId: data.contactId,
                    relationshipId: (props.companyInfo || {}).relationshipId,
                    teamMasterId: teamData.teamMasterId,
                    fundraiserGoal: data.fundraiserGoal,
                    pageNumber: 0,
                    pageSize: 100
                }, props, (obj) => {
                    teamData.teamMemberList.push(obj);
                    setTeamData(teamData);
                });

            },
        };
        props.showModal(modalData);
    }

    const isTeamAlreadyAdded = (data) => {
        let isAdded = false;
        for (const member of teamData.teamMemberList) {
            if (member.contactId === data.contactId) {
                isAdded = true;
                break;
            }
        }
        return isAdded;
    }

    return (
        <Fragment>
            <Drawer
                title={
                    <div>
                        <div className="drawer-title">

                            <FormattedMessage id='fundraising.campaign.addFundraiserToTeam.drawerName' defaultMessage=' ' />
                        </div>
                        <div className="sub-title">{(teamData || {}).teamName}</div>
                    </div>
                }
                width={1000}
                keyboard={false}
                zIndex={1}
                destroyOnClose={true}
                onClose={closeDrawer}
                maskClosable={false}
                className="custom-supplier-drawer"
                visible={props.addFundraiserDrawerVisible}
                afterVisibleChange={
                    (visible) => {
                        if (visible) {
                            //props.initCalls();
                        }
                    }
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row>
                        <Col span={8}>
                            <TextBox
                                type='text'
                                value={searchFundraiser}
                                onChange={(e) => {
                                    props.updateState({
                                        searchFundraiser: e.target.value,
                                    });

                                    onSearchedInputChange(e.target.value, props);

                                }}
                                placeholder={props.intl.formatMessage({ id: 'fundraising.campaign.fundraiserrawer.field.searchFundrasier', defaultMessage: '' })}
                            />
                        </Col>
                         <Col span={8}></Col> 
                        <Col span={8}><CustomButton style={{ marginLeft:'10px' , float:'right'}}
                            intlId='button.createFundraiser'
                            defaultMessage=''
                            htmlType='submit'
                            onClick={() => handleSubmit(teamData)}
                            key={1}
                        /></Col>
                    </Row>
                    <br />

                    <div className='new-tablescfc'>
                        <table>
                            <tbody>
                                <tr>
                                    <th><FormattedMessage id='fundraiser.name' defaultMessage=' ' /></th>
                                    <th><FormattedMessage id='fundraiser.cellPhone' defaultMessage=' ' /></th>
                                    <th><FormattedMessage id='email' defaultMessage=' ' /></th>
                                    <th><span><FormattedMessage id='fundraising.campaign.listing.header.goal' defaultMessage=' ' />
                                    </span><span className="required">*</span></th>
                                    <th><FormattedMessage id='common.listing.header.action' defaultMessage=' ' /></th>
                                </tr>
                                {(props.teamLeadList || []).map((member, i) => {
                                    return <tr>
                                        <td>{(member.firstName || "") + " " + (member.lastName || "")}</td>
                                        <td>{member.cellPhone}</td>
                                        <td>{member.emailAddress}</td>
                                        <td className='fcin'>
                                           {isTeamAlreadyAdded(member) ?  (member.fundraiserGoal || "") : <div className="input-currency-control">
                                                <span className="currency-tag" style={{ left: '10px' }}>
                                                    {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
                                                </span>
                                                <TextBox
                                                    type='number'
                                                    onChange={(e) => {
                                                        member.fundraiserGoal = e.target.value
                                                    }}
                                                    placeholder={props.intl.formatMessage({ id: 'fundraising.campaign.addDrawer.field.goal', defaultMessage: '' })}
                                                />
                                            </div>}

                                        </td>
                                        <td>
                                            {isTeamAlreadyAdded(member) ? "Already Added" :
                                                <CustomButton
                                                    intlId='add'
                                                    defaultMessage=''
                                                    htmlType='submit'
                                                    onClick={() => addFundraiserToTeam(member)}
                                                    key={1}
                                                />
                                            }
                                        </td>
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>
                    </div>

                </Form>
            </Drawer>
        </Fragment>
    );
}

export default injectIntl(FundraiserDrawer);
