import React from "react";
import { Card, Button } from 'antd';
import XeroIcon from '../../../assets/images/xero_logo.svg';
import "./index.scss";
import { FormattedMessage } from "react-intl";


const AccountingComponent = (props) => {
    return (
        <div className="page-details-container text-center integ">
           {/* <Card className= "accounting-market-place text-center integs" title="" extra={<img src={QbIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
           <div class="cr cr-top cr-left cr-green">Integrated</div>
           <h2><FormattedMessage id='quickBook.logo' defaultMessage='' /></h2>
            <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
            <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
            <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
            <div className="border-footer mt10"></div>
      {props.xeroConfigData  && props.xeroConfigData.relationshipConfigId ? <Button disabled style={{marginLeft: "80px"}} className="cnant-btn ant-btn-primary" onClick={
          () =>{
            props.disconnectConfiguration({relationshipId: (props.companyInfo || {}).relationshipId}, props);
          }
      }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn but-geray" onClick={()=>{
        props.fetchXeroAuthURL();
    }}>Integrate</Button>}
    </Card> */}
    {/* <Card className= "accounting-market-place text-center integs" title="" extra={<img src={SageIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
    <div class="cr cr-top cr-left cr-green">Integrated</div>
           <h2><FormattedMessage id='sage.logo' defaultMessage='' /></h2>
            <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
            <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
            <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
            <div className="border-footer mt10"></div>
      {props.xeroConfigData  && props.xeroConfigData.relationshipConfigId ? <Button disabled style={{marginLeft: "80px"}} className="cnant-btn grey-button" onClick={
          () =>{
            props.disconnectConfiguration({relationshipId: (props.companyInfo || {}).relationshipId}, props);
          }
      }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn but-geray" onClick={()=>{
        props.fetchXeroAuthURL();
    }}>Integrate</Button>}
    </Card> */}
    <Card className= "accounting-market-place text-center integs" title="" extra={<img src={XeroIcon} alt="" style={{ height: "50px" }}/>} style={{ width: 300 }}>
    {(props.xeroConfigData  && props.xeroConfigData.relationshipConfigId) ? <div class="cr cr-top cr-left cr-green">Integrated</div> : ''}
           <h2><FormattedMessage id='xero.text' defaultMessage='' /></h2>
            <p><FormattedMessage id='createUpdateInvoice.text' defaultMessage='' /></p>
            <p><FormattedMessage id='createUpdateNotes.text' defaultMessage='' /></p>
            <p><FormattedMessage id='applyPayment.text' defaultMessage='' /></p>
            <div className="border-footer mt10"></div>
      {props.xeroConfigData  && props.xeroConfigData.relationshipConfigId ? <Button disabled style={{marginLeft: "80px"}} className="cnant-btn grey-button" onClick={
          () =>{
            props.disconnectConfiguration({relationshipId: (props.companyInfo || {}).relationshipId}, props);
          }
      }>Disconnect </Button> : <Button style={{marginLeft: "0px"}} className="cnant-btn but-geray" onClick={()=>{
        props.fetchXeroAuthURL();
    }}>Integrate</Button>}
    </Card>
 </div>
 )
};

export default AccountingComponent;