import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import * as find from 'lodash.find';
import ReactHtmlParser from 'react-html-parser';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS, MODAL_TYPE } from '../../../../../static/constants';
import { getAWSFileURL, getMomentDateForUIReadOnly } from '../../../../../utils';
import { getLabelName } from '../../../../../components/modal/modalBody/customerInfo';


const RFQDetails = (props) => {
    const { rfqData, companyInfo } = props;
    const { rfqDetailsList } = rfqData;
    const onlineThemes = JSON.parse( props.companyInfo.onlineThemes || '{}');
    const previewUrl = config.UI_BASE_URL + 'rfqSubmission?t=' + rfqData.relationshipId + '_' + rfqData.rfqMasterId + '_' + rfqData.rfqBiddingSuppliersId + '_' + Math.floor((Math.random() * 10000) + 1)
    const relationshipAddress = find(rfqData.boLocationRFQList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true
    };
    if (rfqData) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, rfqData);
    }

    // const customProps = {
    //     type: CONSTANTS.TYPE_IMAGE,
    //     imgUrl: getAWSFileURL((rfqData || {}).stampAwsKey, config.BUCKET_NAME.BO_RFQ) || '',
    //     isSignature: true,
    //     height: 60,
    //     width: 60,
    //     name: 'file',
    //     action: null,
    //     buttonName: 'Upload Customer PO',
    //     onChange: (file) => {
    //         if (file.file.originFileObj) {
    //             var reader = new FileReader();

    //             reader.onload = function (e) {
    //                 props.showModal({
    //                     title: <Fragment>
    //                         <div className='ant-modal-title'>Crop Image</div>
    //                     </Fragment>,
    //                     //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
    //                     modalBody: <ImageCropper onClick={(e) => {
    //                     }} handleSubmit={(newUrl) => {
    //                         props.uploadStamp({
    //                             supplierId: rfqData.supplierId,
    //                             rfqMasterId: rfqData.rfqMasterId,
    //                             rid: rfqData.relationshipId,
    //                             objectKey: rfqData.stampAwsKey,
    //                             bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
    //                             file: base64Toblob(newUrl)
    //                         })
    //                     }}{...props} url={e.target.result} />,
    //                     width: 800,
    //                     hideFooter: true,
    //                     wrapClassName: 'Upload-profile-modal'
    //                 })
    //             }

    //             reader.readAsDataURL(file.file.originFileObj); // convert to base64 string
    //         }
    //     }
    // }


    const updateStatusConfirmationModal = (props, payload, modalType) => {
        const labelName = "New Status";
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${payload.status} to ${payload.text}` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        const modalData = {
            modalBody,
            handleSubmit: (formData) => {
                const statusObj = find(props.rfqStatusList, { rfqStatusId: payload.statusId }) || {};
                props.updateRfqStatus({
                    status: formData.textBoxValue,
                    relationshipId: props.companyInfo.relationshipId,
                    customerId: payload.customerId,
                    rfqChildMasterId: payload.rfqChildMasterId,
                    rfqMasterId: payload.rfqMasterId,
                    statusId: payload.statusId,
                    statusColor: statusObj.statusColor,
                }, props)
                props.hideModal();
            },
        };
        props.showModal(modalData);
    }

    const addItemConfirmationModal = (props, text, modalType, payloadObj) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;
        let payload = { text: text, relationshipId: companyInfo.relationshipId }

        switch (modalType) {
            case MODAL_TYPE.RFQ_STATUS: {
                addFunc = props.addRFQStatus;
                payload.statusColor = ' new';
                break;
            }
            case MODAL_TYPE.RFQ_PRICE_CODES: {
                addFunc = props.addRFQPriceCodes;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: (data) => {
                addFunc(payload, props);
                props.hideModal();
                payloadObj.formData.status = payload.text;
                handleUpdateStatus(props, payloadObj.formData, MODAL_TYPE.UPDATE_STATUS)
            },
        };
        showModal(modalData);
    }

    const handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, hideModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType, payload);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.rfqStatus' defaultMessage='' />,
                formData,
                isStack: true,
                onCancel: (type) => {
                    if (type !== "update_status") {
                        handleUpdateStatus(props, payload.formData, MODAL_TYPE.UPDATE_STATUS)
                    } else {
                        hideModal();
                    }
                },
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, modalType, payload);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.RFQ_STATUS: {
                deleteFunc = props.deleteRFQStatus;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }, props);
                props.hideModal();
                payload.formData = {};
                handleUpdateStatus(props, payload.formData, MODAL_TYPE.UPDATE_STATUS)
            },
        };
        showModal(modalData);
    }

    const handleUpdateStatus = (props, payload, modalType) => {
        const data = {
            title: <FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage="" />,
            modalData: {
                modalType,
                data: payload,
                isDropDown: true,
                list: props.rfqStatusList,
                valueKeyName: 'statusName',
                optionKeyName: 'rfqStatusId',
                selectedValue: payload.status,
                canAddNew: true,
                canDelete: true,
                deleteClickHandler: (payloadObj) => {
                    deleteClickHandler(props, MODAL_TYPE.RFQ_STATUS, payloadObj);
                },
                onAddNewClick: (payloadObj) => {
                    payloadObj.formData = {
                        textBoxValue: payloadObj.textEntered,
                        submittedOnce: false,
                        itemPayload: payload
                    }
                    handleNewItemAddition(props, payloadObj, MODAL_TYPE.RFQ_STATUS)
                }
            },
            handleSubmit: (formData = {}) => {
                if (formData.textBoxValue && formData.textBoxValue.length) {
                    updateStatusConfirmationModal(props, {
                        rfqMasterId: payload.rfqMasterId,
                        customerId: payload.customerId,
                        text: formData.textBoxValue,
                        rfqChildMasterId: payload.rfqChildMasterId,
                        statusId: Number(formData.optionId),
                        statusColor: formData.statusColor,
                        status: payload.status,
                    }, modalType);
                }
            }
        };
        props.showModal(data);
    }


    return (
        <div className={"custom-modal show"} id="RFQ-Details">
           
            <div className="modal-dialog">
                <div className="modal-content full-width vertical-view-data">
                    <div className="modal-header">

                    </div>
                    <div id="so-readonly" className="inner-content">
                        {/* <div className="brand-logo" style={{textAlign: 'right', marginRight: '25%'}}>
                            <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} />
                        </div> */}
                        <div className="doc-title">
                            {rfqData.documentName || 'RFQ'}
                        </div>
                        <Row>
                            <Col span={16}>

                            </Col>
                            <table style={{ width: '100%', border: '0px', borderCollapse: 'collapse', marginBottom: '30px' }}>
                                <tbody>
                                    <tr style={{ border: '0px', width: '40%' }}>
                                        <td style={{ border: '0px', verticalAlign: 'top', width: '20%' }}></td>
                                        <td style={{ border: '0px', verticalAlign: 'top', width: '40%' }}>  <div style={{ float: 'right', width: '100%' }}>
                                            <div className="read-only-content pt20">
                                                <div className="pb5" style={{display: 'flex'}}>
                                                    <span style={{ width: '50%', textAlign: 'right',fontWeight: 600 }}>
                                                        <FormattedMessage id='common.rfqNumber' defaultMessage='' />
                                                    </span>

                                                    <span style={{ width: '50%', paddingLeft: '10px' }}>
                                                        {rfqData.rfqNumber}
                                                    </span>
                                                </div>


                                                {
                                                    rfqData.rfqExpirationDate ?
                                                        <div className="pb5"  style={{display: 'flex'}}>
                                                            <span style={{ width: '50%', textAlign: 'right',fontWeight: 600 }}>
                                                                <FormattedMessage id='modal.txDetails.rfq.bidClosingDate' defaultMessage='' />
                                                            </span>

                                                            <span style={{ width: '50%', paddingLeft: '10px' }}>
                                                                {(rfqData.rfqExpirationDate && getMomentDateForUIReadOnly({ date: rfqData.rfqExpirationDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                            </span>
                                                        </div> : ''
                                                }

                                            </div>
                                            <div className="read-only-content ">

                                                {
                                                    rfqData.refNumber ?
                                                        <div className="pb5" style={{display: 'flex'}}>
                                                            <span style={{ width: '50%', textAlign: 'right',fontWeight: 600 }}>
                                                                <FormattedMessage id='modal.txDetails.common.refNumber' defaultMessage='' />
                                                            </span>

                                                            <span style={{ width: '50%', paddingLeft: '10px' }}>
                                                                {rfqData.refNumber}
                                                            </span>
                                                        </div> : ''
                                                }


                                                {
                                                    rfqData.orderPriority ?
                                                        <div className="pb5" style={{display: 'flex'}}>
                                                            <span style={{ width: '50%', textAlign: 'right',fontWeight: 600 }}>
                                                                <FormattedMessage id='modal.txDetails.common.priority' defaultMessage='' />
                                                            </span>

                                                            <span style={{ width: '50%', paddingLeft: '10px' }}>
                                                                {rfqData.orderPriority}
                                                            </span>
                                                        </div> : ''
                                                }
                                                {
                                                    rfqData.projectMasterId ?
                                                        <div className="pb5">
                                                            <span style={{ width: '50%', textAlign: 'right',fontWeight: 600 }}>
                                                                <FormattedMessage id='modal.txDetails.common.project' defaultMessage='' />
                                                            </span>

                                                            <span style={{ width: '50%', paddingLeft: '10px' }}>
                                                                {rfqData.projectMasterId ? (rfqData.projectMasterId + '-' + rfqData.projectName) : '-'}
                                                            </span>
                                                        </div> : ''
                                                }


                                            </div>
                                        </div></td>
                                        <td style={{ border: '0px', verticalAlign: 'top' }}><div style={{ float: 'right', width: '100%' }}>
                                            <div className="d-inline-flex w-100">
                                                <div className="read-only-content pt20 pl10">
                                                    <div className="title lh-adjust pb5">
                                                        {props.companyInfo.storeName}
                                                    </div>
                                                    <div>
                                                        {getAddressInfoFragment(relationshipAddress, companyInfo)}
                                                        <div>
                                                            {getTaxIdentificationsFragment(((rfqData.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>


                        </Row>


                        <div className="modal-table-container mb15">
                            <table className="" style={{ width: '98%', marginLeft: 'auto', marginRight: 'auto', borderCollapse: 'collapse', border:'solid 1px #cbd0d2' }}>
                                <thead style={{ backgroundColor: '#cbd0d2',border:'solid 1px #a4a4a4', height: '35px' }} bgcolor="#cbd0d2">
                                    <tr style={{ backgroundColor: 'Black' }}>
                                        <th className="cell-size-50" style={{ background: '#cbd0d2', borderRight:'solid 1px #a4a4a4', width: '4%', textAlign:'center' }}>#</th>
                                        {(!isColumnEmpty.product || !isColumnEmpty.description) && <th style={{ textAlign: 'left', background: '#cbd0d2', borderRight:'solid 1px #a4a4a4', paddingLeft: '10px' }}>
                                            {!isColumnEmpty.product && <span> <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' /></span>}
                                            {(!isColumnEmpty.product && !isColumnEmpty.description) && <span> & </span>}
                                            {!isColumnEmpty.description && <span> <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' /></span>}
                                        </th>}
                                        {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                                        {!isColumnEmpty.qty && <th className="cell-size-100" style={{ textAlign: 'left', background: '#cbd0d2', width: '15%', paddingLeft: '10px' }}><FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' /></th>}
                                        {/* {!isColumnEmpty.uomName && <th>UOM</th>} */}
                                    </tr>
                                </thead>
                                <tbody style={{border:'solid 1px #cbd0d2'}}>
                                    {(rfqDetailsList || []).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1)?.map((item, i) => {
                                        return (
                                            <tr key={i} style={{border:'solid 1px #cbd0d2'}}>
                                                <td className="cell-size-50" style={{borderRight:'solid 1px #cbd0d2',  textAlign:'center'}}>{i + 1}</td>
                                                {(!isColumnEmpty.product || !isColumnEmpty.description) && <td style={{borderRight:'solid 1px #cbd0d2', paddingLeft: '10px'}}>
                                                    <strong>{getVariantFragment(item)}</strong>
                                                    {!isColumnEmpty.description && <div className="description-cell pr100">
                                                        {ReactHtmlParser(item.description || '-')}
                                                    </div>}
                                                </td>}
                                                {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                                                {!isColumnEmpty.qty && <td className="cell-size-100" style={{ paddingLeft: '10px'}}>{item.quantity + (item.uomName ? ' ' + item.uomName : '')}</td>}
                                                {/* {!isColumnEmpty.uomName && <td>{item.uomName || '-'}</td>} */}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Row>
                            <Col span={9}>
                                <div className="read-only-content">
                                    {/* {rfqData.docDetailList && rfqData.docDetailList.length ? <Row>
                                        <Col span={24} className="title">
                                            <FormattedMessage id='modal.txDetails.common.attachments' defaultMessage='' />
                                        </Col>
                                        <Col span={12}>
                                            {
                                                rfqData.docDetailList.map((doc) => {
                                                    return <span className={"cursor-pointer"} onClick={() => {
                                                        props.downloadFileFromBucket({
                                                            bucketName: doc.bucketName,
                                                            awsKeyName: doc.awsKeyName,
                                                            fileName: doc.fileName
                                                        })
                                                    }}>{doc.fileName} &nbsp;</span>
                                                })
                                            }
                                        </Col>
                                    </Row> : null} */}
                                    {rfqData.remarksInternal &&
                                        <Row>
                                            <Col span={7} className="title">
                                                <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                            </Col>
                                            <Col span={17}>
                                                {ReactHtmlParser(rfqData.remarksInternal)}
                                            </Col>
                                        </Row>
                                    }
                                    {rfqData.remarksSupplier &&
                                        <Row>
                                            <Col span={7} className="title">
                                                <FormattedMessage id='modal.txDetails.common.supplierRemarks' defaultMessage='' />
                                            </Col>
                                            <Col span={17}>
                                                {ReactHtmlParser(rfqData.remarksSupplier)}
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </Col>
                            <Col offset={7} span={8}>
                                <div className="upload-link new-pic" title={props.intl?.formatMessage({ id: 'uploadSignature.tooltip' })} style={{marginLeft: '70%', marginTop:'20px', width: "50px", height: "50px" }}>
                                    {/* <CustomUploader  {...customProps } style={{with:'100px'}} /> */}
                                    <img src={getAWSFileURL((rfqData || {}).stampAwsKey, config.BUCKET_NAME.BO_RFQ)} alt='' />
                                </div>

                            </Col>
                        </Row>

                        <div className="contact-details">
                            {rfqData.footer &&
                                <Fragment>
                                    {ReactHtmlParser(rfqData.footer)}
                                </Fragment>
                            }
                        </div>
                        <a id="rfq-collect-res" type='submit' href={previewUrl} style={{ display: 'block', width: '150px', textAlign: 'center', textDecoration: 'none', padding: '10px 10px', margin: 'auto', marginTop: '40px', borderRadius: '45px', color: '#000', border: 'solid 1px #000000', cursor: 'pointer' }}>Submit Response</a>

                        <div style={{ backgroundColor: '#000', marginTop: '30px', paddingBottom: '20px', paddingTop: '20px', color: '#ffffff', textAlign: 'center' }}>
                            <p>{relationshipAddress.streetAddress1 || ''} {relationshipAddress.streetAddress2 || ''}
                                <br />
                                {
                                    `${relationshipAddress.cityName || ''} ${relationshipAddress.stateName ? ',' + relationshipAddress.stateName : ''} ${relationshipAddress.zipCode ? ',' + relationshipAddress.zipCode : ''}`
                                }
                            </p>
                            <p>
                                <div style={{textAlign: 'center'}}>{onlineThemes.footerMessage || 'All Rights Reserved - ' + companyInfo.storeName}</div> 
                                {onlineThemes.facebookLink ? <a href={onlineThemes.facebookLink.match(/^https?:/) ? onlineThemes.facebookLink : '//' + onlineThemes.facebookLink} style={{float:'right', marginLeft:'5px', marginRight:'5px', color:'#fff', textDecoration:'none'}} target="_blank" rel="noopener noreferrer"><img style={{height:'22px'}} alt='' src={"https://alpide-prd-us1-relationship.s3.amazonaws.com/facebook.png"} /></a> : ''}
                                {onlineThemes.instagramLink ? <a href={onlineThemes.instagramLink.match(/^https?:/) ? onlineThemes.instagramLink : '//' + onlineThemes.instagramLink} style={{float:'right', marginLeft:'5px', marginRight:'5px', color:'#fff', textDecoration:'none'}} target="_blank" rel="noopener noreferrer"><img style={{height:'22px'}} alt='' src={"https://alpide-prd-us1-relationship.s3.amazonaws.com/instagram.png"} /></a> : ''}
                                {onlineThemes.twitterLink ? <a href={onlineThemes.twitterLink.match(/^https?:/) ? onlineThemes.twitterLink : '//' + onlineThemes.twitterLink} style={{float:'right', marginLeft:'5px', marginRight:'5px', color:'#fff', textDecoration:'none'}} target="_blank" rel="noopener noreferrer"><img style={{height:'22px'}} alt='' src={"https://alpide-prd-us1-relationship.s3.amazonaws.com/twitter.png"} /></a> : ''}
                                {onlineThemes.youtubeLink ? <a href={onlineThemes.youtubeLink.match(/^https?:/) ? onlineThemes.youtubeLink : '//' + onlineThemes.youtubeLink} style={{float:'right', marginLeft:'5px', marginRight:'5px', color:'#fff', textDecoration:'none'}} target="_blank" rel="noopener noreferrer"><img style={{height:'22px'}} alt='' src={"https://alpide-prd-us1-relationship.s3.amazonaws.com/youtube.png"} /></a> : ''}
                            </p>


                        </div>
                    
                    </div>


                </div>
            </div>
        </div>
    );

}

const updateColumnEmptyCheck = (isColumnEmpty, rfqData) => {
    if (!rfqData) return isColumnEmpty;
    for (let i in rfqData.rfqDetailsList) {
        let row = rfqData.rfqDetailsList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantity) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
    }
    return isColumnEmpty;
}

const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = <Fragment>
         <div className="billing-address first-caps">
            {obj.streetAddress1 || obj.streetAddress2 ? (

                <div>
                    {obj.streetAddress1} {obj.streetAddress2}
                </div>

            ) : ""}
            {obj.cityName || obj.stateName || obj.zipCode ? (

                <div>
                    {obj.cityName} {obj.stateName}{obj.stateName && obj.zipCode ? ", " : ""}{obj.zipCode}
                </div>

            ) : ""}
             {obj.countryName ? (
     <div>{obj.countryName}</div>
    ):""}
        </div>
        {/* {
            additionInfo.firstName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        } */}
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        }

    </Fragment>

    return fragmentObj;
}

const getTaxIdentificationsFragment = (taxIdentifications) => {
    if (!taxIdentifications) return <Fragment />;
    let taxIdentificationsList = []
    if (taxIdentifications) {
        taxIdentificationsList = JSON.parse(taxIdentifications);
    }
    const fragmentObj = <Fragment>
        {taxIdentificationsList.map((obj, i) => {
            return <div className='billing-address'>
                {obj.taxIdentificationType || ''} - {obj.taxIdentificationNumber}
            </div>
        })}
    </Fragment>

    return fragmentObj;
}

// const getContactNameFragment = (additionInfo) => {
//     if (!additionInfo) return <Fragment />;
//     let contactName = "";
//     if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
//     if (additionInfo.firstName) contactName += additionInfo.firstName;
//     if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
//     if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
//     const fragmentObj = <Fragment>
//         {
//             (contactName && contactName.length)
//                 ? <div className='billing-address'>
//                     {contactName}
//                 </div> :
//                 ''
//         }
//     </Fragment>
//     return fragmentObj;
// }
const getVariantFragment = (item) => {
    let itemName = null;
    if (item.itemName) {
        itemName = item.itemName;
    }
    if (item.itemVariantName) {
        itemName = itemName + ' (' + item.itemVariantName + ')'
    }
    return itemName ? itemName : '';
}
export default RFQDetails;
