import React from "react";
import { Tabs } from 'antd';
import PageBreadcrumb from "../../../PageBreadcrumb";
import RFQList from "./RFQList";
import RFQDraftList from "./RFQDraftList";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { FormattedMessage } from 'react-intl';
import StandartRqf from "../../../../containers/supplier/rfq/StandardRFQ";
import { ICONS } from "../../../../static/constants";
import PageHeader from "../../../Common/pageHeader";

const { TabPane } = Tabs;


function callback(key, props) {
    const payload = { companyInfo: props.companyInfo, relationshipId: props.companyInfo.relationshipId, pageNumber: props.pageNumber, pageSize: props.pageSize };
    if (key === '1') {
        props.updateState({activeTab: 'RFQ', activeTabKey: "1"});
    } else if (key === '2') {
        props.updateState({activeTab: 'Draft', activeTabKey: "2"});
        fetchDataIfNeeded('getRFQDraftCount', 'rfqDraftCount', props, payload);
        fetchPaginationDataIfNeeded('fetchAllRFQDrafts', 'rfqDraftList', props, payload);
    } 
}


const RFQListingComp = (props) => {
    const breadCrumbList = [
        {
            name:<FormattedMessage id='sidebar.menuItem.purchase' defaultMessage='' /> ,
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.RFQ' defaultMessage='' />,
        }
    ];
    const openNewRqf = () => {
        props.pushModalToStack({
            modalBody: <StandartRqf {...props} type = 'new'/>,
            width: '100%',
            hideTitle: true,
            hideFooter: true,
            keyboard: false,
            wrapClassName: 'new-transaction-wrapper',
            onCancel: () => {
                props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    props.popModalFromStack();
                  }
                })
              }
        })
    }
    return (
        <>    
            <PageHeader {...props}
                pageName="heading.purchase.rfq"
                breadCrumbList={breadCrumbList}
                canCreate={true}
                onCreate={openNewRqf}
            />
            <div className="view-container">
                <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { callback(key, props); }} type="line">
                    <TabPane tab={<FormattedMessage id='sidebar.menuItem.RFQ' defaultMessage='' />} key="1">
                        <RFQList {...props} />
                    </TabPane>
                    <TabPane tab={<FormattedMessage id='draft.text' defaultMessage='' />} key="2">
                        <RFQDraftList {...props} />
                    </TabPane>
                </Tabs>
            </div>
        </>
    )
};

export default RFQListingComp;
