import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MatchedTransactionsComponent from '../../../../../../components/modal/modalBody/finance/Banking/Match';
import { fetchMatchedTransactions, createMatchedPayment, createMatchedPaymentForSupplier } from './action';
import { fetchSalesInvoices} from '../../../../../customer/invoice/Listing/action';
import { fetchCustomerPayments} from '../../../../../customer/payment/Listing/action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../../actions/commonActions';

class MatchedTransactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ledgerStartDate: null,
            ledgerEndDate: null,
            activeAccount: props.activeAccount
        }
    }
    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};
        // const payload = {
        //   companyInfo,
        //   relationshipId: companyInfo.relationshipId,
        //   userId: (this.props.userInfo || {}).userId,
        //   pageNumber: 1,
        //   pageSize: 5
        // };
        this.props.fetchMatchedTransactions({...this.props.transactionData, pageSize: 100, pageNumber: 0});
    }

    render() {
        return <div>
            <MatchedTransactionsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        matchedTransactions: state.finance.matchedTransactions,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchMatchedTransactions,
    createMatchedPayment,
    fetchCustomerPayments,
    fetchSalesInvoices,
    createMatchedPaymentForSupplier,
    showModal, hideModal, pushModalToStack, popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MatchedTransactions);