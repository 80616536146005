import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';

import { CONSTANTS, LEDGER_ACTION_LIST, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY, LEDGER_TYPE } from '../../../../static/constants';
import { GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import CreditMemoCreationComponent from '../../../../components/customer/creditMemo/StandardCreditMemo';
import { fetchCustomers } from '../../Listing/action';
import { fetchCostCenter, createCreditMemo, getNextTxNumber } from './action';
import {
  fetchPriceList, fetchPaymentTerms, fetchStates,
  fetchTaxes, fetchUOM, addUOM, deleteUOM, fetchExpenseTypes, addExpenseTypes, deleteExpenseTypes, fetchProducts,
  showModal, hideModal, pushModalToStack, popModalFromStack, addPaymentTerm, deletePaymentTerm, deleteAddress, deleteTax,
  fetchDocuments, addDocumentName, deleteDocumentName, deleteContact,
  fetchUserPreferences, saveUserPrefrences, fetchRelationshipTaxIdentifications, deleteCustomerTaxIdentifications, deleteRelationshipTaxIdentifications, resetCustomerDetail, fetchCustomerDetailsByCustomerId,
  fetchAddresses,
  resetAddresses,
  getRelationshipAddress
} from '../../../../actions/commonActions';
import LedgerDrawer from '../../../../containers/drawer/ledgers/CreditMemoLedger';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded, addDaysToDate } from '../../../../utils';
import CreditMemoDetail from '../../../modal/modalBody/customer/CreditMemoDetail';
import { deleteCustomers } from '../../Listing/action';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import { fetchCreditMemoDetail, resetCreditMemoDetail } from '../../../modal/modalBody/customer/CreditMemoDetail/action';
import { fetchAllContacts } from '../../Listing/ShowConact/action';
import { fetchCreditMemos } from '../Listing/action';
import SettingDrawer from '../../../drawer/customer/settingSalesDrawer';

class CreditMemoCreation extends Component {
  constructor(props) {
    super(props);

    const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES }) || {};
    const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE }) || {};
    const defaultSalesOutputLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX }) || {};
    const defaultSalesDiscountLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_DISCOUNT }) || {};
    const defaultDebtorsLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEBTORS }) || {};
    const relationshipBillingAddresses = filter((this.props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const relationshipBillingAddress = relationshipBillingAddresses.find(x => +x.isDefault === 1);
    const defaultCouponLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TYPE_COUPON }) || {};
    
    const linkProps = (props.location && props.location.state) || {};
    this.state = {
      displayName:"drawer.settingDrawer.headingNameCreditMemo",
      viewName:"CreditMemo",
      transaction_email_template_value:"Credit Memo",
      tabDocumentOptions: { txType: CONSTANTS_TRANSACTIONS.CREDIT_MEMO, label: 'Credit Memo', numberExample: 'CM-1', numberInfo: 'CM-CustomerNumber-DDMMYY' },

      creditMemoDate: new Date(),
      creditMemoExpDate: new Date(),
      customer: props.customer||linkProps.customer,
      isClone: props.clone||linkProps.clone,
      customerName: (props.customer || {}).companyName,
      priceListName: undefined,
      stateCustomerId: props.customerId || linkProps.customerId,
      pageNumber: props.pageNumber || 1,
      pageSize: 100,
      salesLedgerId: defaultSalesLedger.ledgerAccountId,
      salesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      expenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      expenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      taxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
      discountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      couponLedgerId: defaultCouponLedger.ledgerAccountId, 
      couponDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      
      igstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      igstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      

      
      cgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      cgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
    

      
      sgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      sgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
     
      searchedText: '',

      relationshipBillingAddresses: relationshipBillingAddresses || [],
      allBillingAddress: [],
      costCentersData: [{}],

      isUpdateInventory: false,
      placeOfSupply: undefined,
      soSourceId: 0,
      soSourceName: '',
      customerPO: undefined,
      referenceNumber: undefined,
      salesPersonId: undefined,
      salesPersonName: undefined,
      priority: undefined,
      billingAddress: undefined,
      relationshipBillingAddress: relationshipBillingAddress || {},
      hsn: undefined,
      footer: undefined,
      remarksCustomer: undefined,
      remarksInternal: undefined,
      txPreferences: { txType: "creditMemo", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
        "sno"
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === 'India',
        basePrice: true,
        specialDiscount: true,
        discount: true,
        tax: true
      },
    }
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true
    // });
    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      creditMemoMasterId: this.props.creditMemoMasterId ||  linkProps.creditMemoMasterId,
      userId: (this.props.userInfo || {}).userId,
      customerId: this.props.customerId || linkProps.customerId,
      pageNumber: 1,
      pageSize: 100
    };
    this.props.resetCreditMemoDetail();
    this.props.resetAddresses();
    this.props.resetCustomerDetail();        
    fetchDataIfNeeded('fetchPriceList', 'priceList', this.props, payload);
    fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
    payload.customerId && this.props.fetchAddresses({...payload, locationType: CONSTANTS.SHIPPING_ADDRESS});
    payload.customerId && this.props.fetchAddresses({...payload, locationType: CONSTANTS.BILLING_ADDRESS});
    fetchDataIfNeeded('fetchStates', 'states', this.props, 1);
    fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
    fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
    fetchDataIfNeeded('fetchExpenseTypes', 'expenseTypes', this.props, payload);
    fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
    fetchDataIfNeeded('fetchCostCenter', 'costCenters', this.props, payload);
    fetchDataIfNeeded('fetchDocuments', 'documentList', this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    this.props.fetchRemarksTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Credit Memo' });
    fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
    payload.creditMemoMasterId && this.props.fetchCreditMemoDetail(payload);

  }

  componentWillReceiveProps(props) {

  

    if(props.addresses){
      if(props.addresses.BillingAddress && props.addresses.BillingAddress.length){
        this.setState({
          allBillingAddress: props.addresses.BillingAddress
        });
      }
      if(props.addresses.ShippingAddress && props.addresses.ShippingAddress.length){
        this.setState({
          allShippingAddress: props.addresses.ShippingAddress
        });
      }
    }

    if (props.customerDetail && props.customerDetail.customerId) {
      const customer = props.customerDetail;
      const relationshipBillingAddresses = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      const relationshipBillingAddress = find(relationshipBillingAddresses, { isDefault: 1 });
      const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
      const billingAddress = (customer && find(allBillingAddress, { isDefault: 1 })) || [];
      const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
      const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
      let selectedCustomerTaxIdentifications = selectedList.map(obj => {
        return obj.customerTaxIdentificationId;
      })
      let creditMemoExpDate = new Date();
      let selectedterm = this.props.paymentTerms.length ? find(this.props.paymentTerms, { paymentTermId: (customer.paymentTermId || 0) }) || {} : {};
      if (selectedterm.numberOfDays) {
          creditMemoExpDate = addDaysToDate((this.props.creditMemoDate || new Date()), selectedterm.numberOfDays);
      }
      this.setState({
        customer,
        customerName: customer.companyName,
        paymentTermName: customer.paymentTermName,
        paymentTermId: customer.paymentTermId,
        relationshipBillingAddresses,
        relationshipBillingAddress,
        allBillingAddress,
        billingAddress,
        selectedContact,
        selectedCustomerTaxIdentifications,
        creditMemoExpDate,
        boContactList: customer.boContactList || [],
      });
      props.resetCustomerDetail();
    }

    // const linkProps = (this.props.location && this.props.location.state) || {};
    if (props.documentList && props.documentList.length && !this.state.docName) {
      props.documentList.forEach((document) => {

        if (document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.CREDIT_MEMO] && document.isDefault) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName
          })
        }
      })

    }

    if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
      const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
      let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
        return obj.relationshipTaxIdentificationId;
      })
      this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
    }

    if (!this.state.customerRemarks && props.remarksData && props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ customerRemarks: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500)
    }
    if (props.creditMemoDetail && props.creditMemoDetail.creditMemoMasterId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerCreditMemoCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.comment) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      }
      props.creditMemoDetail.customerCreditMemoDetailsList.map((ele) => {
        const tax = (find(ele.customerCreditMemoCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX;
        }) || {});

        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + " )") || ""),
          version: ele.version,
          productObj: { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku },
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          discount: (find(ele.customerCreditMemoCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax: (tax.amountPercent || 0),
          taxApplied: appliedTax.taxNameDisplay,
          taxName: appliedTax.taxName,
          taxId: appliedTax.taxSingleRateId,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount
        })
      });
      let selectedContact = (props.creditMemoDetail.additionalInfoList || [])[0] || {};
      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }
      this.setState({
        selectedContact,
        version: props.creditMemoDetail.version,
        relationshipBillingAddress: find(props.creditMemoDetail.boLocationCreditMemoList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {},
        shippingAddress: find(props.creditMemoDetail.boLocationCreditMemoList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {},
        billingAddress: find(props.creditMemoDetail.boLocationCreditMemoList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {},
        paymentTermName: props.creditMemoDetail.paymentTermName,
        paymentTermId: props.creditMemoDetail.paymentTermId,
        referenceNumber: props.creditMemoDetail.referenceNumber,
        priority: props.creditMemoDetail.orderPriority,
        customerPo: props.creditMemoDetail.customerPONumber,
        customerName: props.creditMemoDetail.customerName,
        remarksCustomer: props.creditMemoDetail.remarksCustomer,
        salesQuotationMasterId: props.creditMemoDetail.salesQuotationMasterId,
        quotationNumber: props.creditMemoDetail.quotationNumber,
        internalRemarks: props.creditMemoDetail.remarksInternal,
        footer: props.creditMemoDetail.footer,
        customer: { ...props.customer, companyName: props.creditMemoDetail.customerName, customerId: props.creditMemoDetail.customerId },
        customerCreditMemoDetailsList: detailList,
        rfqNumber: props.creditMemoDetail.rfqNumber,
        rfqMasterId: props.creditMemoDetail.rfqMasterId,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        tableDataReRendered: true,
        oldPayload: props.update ? props.creditMemoDetail : {},
        isUpdateInventory: props.creditMemoDetail.isUpdateInventory ? true : false,
      });
      if (props.update) {
        this.setState({ nextTxNumber: props.creditMemoDetail.creditMemoNumber });
      }
      props.resetCreditMemoDetail();
    }
    if (this.state.stateCustomerId && props.customers[this.state.pageNumber] && props.customers[this.state.pageNumber].length && props.paymentTerms && props.paymentTerms.length) {
      const customer = find(props.customers[this.state.pageNumber], { customerId: Number(this.state.stateCustomerId || 0) }) || {};
      const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
      const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
      const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
      this.setState({
        stateCustomerId: null,
        customer,
        customerName: customer.companyName,
        paymentTermName: customer.paymentTermName,
        paymentTermId: customer.paymentTermId,
        allBillingAddress,
        billingAddress: defaultBillingAddress,
        selectedContact,
        boContactList: customer.boContactList || [],
      });
    }
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "creditMemo", templateName: "standard" }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
        let tablePreference = txPreferences.tablePreference;
        txColumns = txColumns.length ? txColumns : (this.state.txMandatoryColumns || []).concat('product', 'description')
        const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        this.setState({
          isColumnFitTable,
          tablePreference,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        })
      }
    }

    if(props.boRelationshipAddress && props.boRelationshipAddress.length){
      const allRelationshipBillingAddress = [];
      props.boRelationshipAddress.forEach((e)=>{
        if(e.locationType === 'RelationshipBillingAddress'){
          allRelationshipBillingAddress.push(e);
        }
      });
      this.setState({
        relationshipBillingAddresses: allRelationshipBillingAddress
      })
    }else{
      const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      this.setState({
        allRelationshipBillingAddress,
      });
    }
    if (props.customerContactList && props.customerContactList.length) {
      this.setState({
        boContactList: props.customerContactList
      });
    }
  }

  openCreditMemoDetails = (data) => {
    const creditMemoPayload = {
      customerId: data.customerId,
      creditMemoMasterId: data.creditMemoMasterId
    }
    this.props.pushModalToStack({
      modalBody: <CreditMemoDetail {...this.props} creditMemoPayload={creditMemoPayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching
    };
    this.props.fetchProducts(payload)
  }

  render() {
    const linkProps = (this.props.location && this.props.location.state) || {}
    return (
      <Fragment>
        {this.state.settingSalesDrawerVisible && 
        <SettingDrawer
        {...this.state}
        {...this.props}
        updateState={(data) => {
          this.setState(data);
        }}
        updateDrawerState={(data) => { this.setState(data) }}
        />
        }

        <LedgerDrawer {...this.state} updateState={(data) => { this.setState(data) }} />
        <CreditMemoCreationComponent
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching)
          }}
          {...this.props}
          {...this.state}
          {...linkProps}
          openCreditMemoDetails={this.openCreditMemoDetails}
          updateState={(data) => { this.setState(data); }}
          updateDrawerState={(data) => { this.setState(data) }}
        />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    customers: state.customer.customers,
    priceList: state.common.priceList,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    supplierAddresses: state.supplier.supplierAddresses,
    paymentTerms: state.common.paymentTerms,
    states: state.common.states,
    uomList: state.common.uomList,
    products: state.salesInvoice.products,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    costCenters: state.creditMemo.costCenters,
    documentList: state.common.documentList,
    nextTxNumber: state.creditMemo.nextTxNumber,
    allPreferences: state.common.allPreferences,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    customerDetail: state.common.customerDetail,
    allRelationshipTaxIdentificatins: state.common.allRelationshipTaxIdentificatins,
    addresses: state.customer.addresses,
    boRelationshipAddress: state.common.boRelationshipAddress,
    creditMemoDetail: state.creditMemo.creditMemoDetail,
    customerContactList: state.customer.contacts,
    detailLoading: state.common.detailLoading,
    saveLoading: state.common.saveLoading

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createCreditMemo,
  fetchCustomers,
  fetchPriceList,
  fetchPaymentTerms,
  fetchStates,
  fetchProducts,
  fetchTaxes,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  fetchCostCenter,
  showModal,
  hideModal,
  pushModalToStack,
  resetRemarksData,
  popModalFromStack,
  deleteCustomers,
  addPaymentTerm,
  deletePaymentTerm,
  deleteAddress,
  deleteTax,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  getNextTxNumber,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchRemarksTemplate,
  fetchRelationshipTaxIdentifications,
  deleteCustomerTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  fetchCreditMemoDetail,
  resetCreditMemoDetail,
  resetCustomerDetail, 
  fetchCustomerDetailsByCustomerId,
  fetchAddresses,
  getRelationshipAddress,
  resetAddresses,
  fetchAllContacts,
  fetchCreditMemos
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreditMemoCreation);
