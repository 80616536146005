import React from "react";
import "@ant-design/compatible/assets/index.css";
import { Button } from 'antd';
import { injectIntl, FormattedMessage } from "react-intl";
import LoadingIcon from "../../../../assets/images/loading.gif";

const HeaderPackage = (props) => {
  // const [templateDropdown, updateTemplateDropdown] = useState(false);

  // const handleNewPriceListAddition = (props, payload) => {
  //   const formData = payload.formData;
  //   formData.relationshipId = companyInfo.relationshipId;
  //   const data = {
  //     title: <FormattedMessage id='addItem.text.priceList' defaultMessage='' />,
  //     formData,
  //     hideFooter: true,
  //     modalData: {
  //       modalType: MODAL_TYPE.PRICE_LIST,
  //       data: payload,

  //     },
  //     handleSubmit: (formData = {}) => {
  //       data.formData.submittedOnce = true;
  //       showModal(data);
  //     }
  //   };
  //   showModal(data);
  // }

  // const deleteClickHandler = (props, modalType, payload) => {
  //   const modalBody =
  //     <FormattedMessage
  //       id='deleteItem.confirmation'
  //       defaultMessage=''
  //       values={{ value: payload.text }}
  //     />;

  //   let deleteFunc;

  //   switch (modalType) {
  //     case MODAL_TYPE.PRICE_LIST: {
  //       deleteFunc = props.deletePriceList;
  //       break;
  //     }
  //     default: {
  //       deleteFunc = () => { }
  //     }
  //   }
  //   const modalData = {
  //     modalBody,
  //     handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
  //   };
  //   showModal(modalData);
  // }

  //   const useOuterClick = (callback) => {
  //     const innerRef = useRef();
  //     const callbackRef = useRef();

  //     // set current callback in ref, before second useEffect uses it
  //     useEffect(() => { // useEffect wrapper to be safe for concurrent mode
  //         callbackRef.current = callback;
  //     });

  //     useEffect(() => {
  //         document.addEventListener("click", handleClick);
  //         return () => document.removeEventListener("click", handleClick);

  //         // read most recent callback and innerRef dom node from refs
  //         function handleClick(e) {
  //             if (
  //                 innerRef.current &&
  //                 callbackRef.current &&
  //                 !innerRef.current.contains(e.target)
  //             ) {
  //                 callbackRef.current(e);
  //             }
  //         }
  //     }, []); // no need for callback + innerRef dep

  //     return innerRef; // return ref; client can omit `useRef`
  // }

  // const innerRef = useOuterClick(e => {
  //    updateTemplateDropdown(!templateDropdown)
  // });

  return (
    <>
      <div className="left-itms">
        <ul>
          <li className="title">Sales Package</li>
          <li className="sep" />
          <li>
            <FormattedMessage id="Package #" defaultMessage="" /> &nbsp; -
            &nbsp;
            {props.nextTxNumber ? (
              <span>{getFormatedNextTxNumber(props)}</span>
            ) : (
              <img src={LoadingIcon} alt="Invoice" style={{ height: "50px" }} />
            )}
          </li>
        </ul>
      </div>
      <div className="right-itms">
        {/* {props.packageType !== 'new' && props.packageType !== 'update' ? <div className="text-left">
                    {(props.soPackageData || {}).customerName || ''}
                  </div>
                    :
                    <Dropdown
                      style={{ width: "200px" }}
                      items={props.customers[props.pageNumber] || []}
                      valueKeyName='companyName'
                      optionKeyName='customerId'
                      value={props.customerName}
                      canAddNew={true}
                      canDelete={true}
                      onSearch={(searchedText) => {
                        props.fetchCustomers({
                          isCompact: true,
                          searchedText: searchedText,
                          pageNumber: 1,
                          hideSpinner: true,
                          pageSize: 100,
                          relationshipId: (props.companyInfo || {}).relationshipId
                        })
                      }}
                      placeholder={props.intl.formatMessage({
                        id: 'customer.salesOrder.form.customer.placeholder', defaultMessage: ''
                      })}
                      onSelect={
                        (customerId, data) => {
                          props.updateState({
                            customerId,
                            customerName: data.name,
                            shippingAddress: null,
                            billingAddress: null
                          });
                          props.fetchCustomerDetailsByCustomerId({
                            customerId,
                            relationshipId: (props.companyInfo || {}).relationshipId
                        });
                          props.fetchAddresses({customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.SHIPPING_ADDRESS});
                          props.fetchAddresses({customerId, relationshipId: (props.companyInfo || {}).relationshipId, locationType: CONSTANTS.BILLING_ADDRESS});
                          props.fetchUnpackedSalesOrders({
                            customerId,
                            relationshipId: (props.companyInfo || {}).relationshipId
                          });
                          props.fetchAllContacts({ customerId, relationshipId: (props.companyInfo || {}).relationshipId });
                        }
                      }
                      deleteClickHandler={(payload) => {
                        props.deleteCustomers({ groupCustomerIds: [payload.id], relationshipId: (props.companyInfo || {}).relationshipId })
                      }}
                      onAddNewClick={(payload) => {
                        props.updateHeaderState({
                          customer: {
                            customerDrawerVisible: true,
                            customerName: payload.textEntered,
                          }
                        })
                      }}
                    /> */}
        {/* } */}
        {/* {(props.soPackageData || {}).customerName || ''} */}

        <Button onClick={() => {
                        props.updateDrawerState({
                            settingSalesDrawerVisible: true
                        })
                    }} >
                        <i class="fa fa-cog" aria-hidden="true"></i>
                    </Button>
      </div>
    </>
  );
};

const getFormatedNextTxNumber = (props) => {
  // const { customer } = props;
  // if (!customer || !customer.customerId) {
  //   return <span>{props.nextTxNumber}</span>;
  // }
  // return <span>{props.nextTxNumber.replace('SO-00', 'SO-' + (customer.customerId < 10 ? ('0' + customer.customerId) : customer.customerId))}</span>
  return <span>{props.nextTxNumber}</span>;
};

export default injectIntl(HeaderPackage);
