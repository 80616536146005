import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShowAddress from "../../../../components/supplier/Listing/ShowAddress";
import {
  fetchCountries,
  pushModalToStack,
  showModal,
  hideModal,
} from "./../../../../actions/commonActions";
import {
  fetchAddresses,
  deleteAddress,
  createUpdateAddress,
  addAddressRow,
} from "./action";
import { fetchSupplierAddresses } from "../../detail/action";
import { fetchDataIfNeeded } from "../../../../utils";
import { CONSTANTS } from "../../../../static/constants";

class ShowAddressContainer extends Component {
  constructor(props) {
    super(props);
    // const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false,
      supplierId: props.supplierId,
      // ...props,
    };
  }
  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
  
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      locationType: CONSTANTS.BILLING_ADDRESS,
      supplierId: this.props.supplierId,
    };
    fetchDataIfNeeded("fetchCountries", "countries", this.props, payload);
    this.props.fetchAddresses(payload);
    this.props.fetchSupplierAddresses(payload);
  }

  render() {
    return (
      <ShowAddress
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    companyInfo: state.common.companyInfo,
    addresses: state.supplier.addresses,
    addressList: state.supplier.addressList,
    permissions: state.auth.permissions,
    listLoading:state.common.listLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCountries,
      fetchAddresses,
      deleteAddress,
      createUpdateAddress,
      addAddressRow,
      showModal,
      pushModalToStack,
      hideModal,
      fetchSupplierAddresses,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowAddressContainer);
