import React, { useState } from "react";
import "@ant-design/compatible/assets/index.css";
import { injectIntl, FormattedMessage } from "react-intl";
import LoadingIcon from "../../../../../assets/images/loading.gif";
import MultiCurrencyPurchaseRequest from "../../../../../containers/supplier/purchaseRequest/MultiCurrencyPurchaseRequest";
import StandardPurchaseRequest from "../../../../../containers/supplier/purchaseRequest/StandardPurchaseRequest";
import { Button, Row } from "antd";
import { Link } from "react-router-dom";

const HeaderAction = (props) => {
  const [templateDropdown, updateTemplateDropdown] = useState(false);

  const { nextTxNumber, popModalFromStack, pushModalToStack } = props;

  const openStandardPurchaseRequest = () => {
    popModalFromStack();
    pushModalToStack({
      modalBody: <StandardPurchaseRequest {...props} />,
      width: "100%",
      hideTitle: true,
      hideFooter: true,
      wrapClassName: "new-transaction-wrapper",
    });
    updateTemplateDropdown(false);
  };

  const openMultiCurrencyPurchaseRequest = () => {
    popModalFromStack();
    pushModalToStack({
      modalBody: <MultiCurrencyPurchaseRequest {...props} />,
      width: "100%",
      hideTitle: true,
      hideFooter: true,
      wrapClassName: "new-transaction-wrapper",
    });
    updateTemplateDropdown(false);
  };

  return (
    <>
      <div className="left-itms">
        <ul>
          <li className="title">Purchase Requests</li>
          <li className="sep" />
          <li>
            <FormattedMessage
              id="supplier.purchaseRequest.form.purchaseRequestNumber.label"
              defaultMessage=""
            />
            &nbsp; - &nbsp;
            {nextTxNumber ? (
              <span>{nextTxNumber}</span>
            ) : (
              <img
                src={LoadingIcon}
                alt="Invoice"
                style={{
                  height: "74px",
                  marginTop: "-25px",
                  marginBottom: "-25px",
                }}
              />
            )}
          </li>
        </ul>
      </div>
      <div className="right-itms">
        <div className="currency-converter-modal">
          <Button onClick={() => updateTemplateDropdown(!templateDropdown)}>
            <i className="fa fa-exchange" />
          </Button>

          {templateDropdown && (
            <div className="content">
              <Row className="mb10">
                <Link onClick={openStandardPurchaseRequest}>
                  <FormattedMessage
                    id="template.standard.invoice"
                    defaultMessage=""
                  />
                </Link>
              </Row>

              <Row>
                <Link onClick={openMultiCurrencyPurchaseRequest}>
                  <FormattedMessage
                    id="template.standard.invoice.multicurrency"
                    defaultMessage=""
                  />
                </Link>
              </Row>
            </div>
          )}
        </div>
           <Button onClick={() => {
              props.updateDrawerState({
                  settingPurchaseDrawerVisible: true
              })
          }} >
              <i class="fa fa-cog" aria-hidden="true"></i>
          </Button>
      </div>
    </>
  );
};

export default injectIntl(HeaderAction);