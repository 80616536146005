import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getUserDefinedName } from '../../../../../utils';
import './index.scss';

const CourseDetails = (props) => {
    const { courseDetail } = props;
    return (
        <div className={"custom-modal show"}>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="inner-content" style={{maxHeight: 'unset', height: '100vh', padding: '0px'}}>
                        <i className="close-icon" onClick={() => { props.popModalFromStack(); }}>
                            <svg width="17" height="17">
                                <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                                <path fill="#67757d" fillRule="evenodd"
                                    d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                            </svg>
                        </i>
                        <div className="modal-header" style={{height:'initial', top: '0px'}}>
                            <div>{getUserDefinedName("schoolManagement.course.name", props)} : {courseDetail.courseName} ({courseDetail.courseCode})</div>
                            <div>{getUserDefinedName("offeringDepartment.text", props)} : {courseDetail.deptName}</div>
                        </div>
                        <div className="timelines">
                            {
                                (Object.keys(props.courseTermSubjects) || []).map((courseTermId, i) => {
                                    let subjectList = props.courseTermSubjects[courseTermId] || [];
                                    return <div className={i % 2 === 1 ? "containers lefts" : "containers rights"}>
                                        <div className={i % 2 === 1 ? "nameleft" : "nameright"}>{subjectList[0].courseTermName}</div>
                                        <div className="contents">
                                            <ul>
                                                {subjectList.map((subject, j) => {
                                                    return <li key={"sub"+j}>{subject.subjectName} - {subject.subjectCode} ({subject.subjectCredit})</li>
                                                })
                                                }
                                            </ul>
                                            {subjectList[0].isCertificateAwarded ?
                                                <div className="timehead">
                                                    <h3>{subjectList[0].certificateMessage}</h3>
                                                    <ul>
                                                        <li><span>* </span>{subjectList[0].certificateName} </li>
                                                    </ul>
                                                </div>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}


export default CourseDetails;
