import React, { Component } from 'react';
import LeadCampaignListingComp from "../../../components/leads/LeadCampaigns";
import { pushModalToStack, popModalFromStack, showModal, hideModal, fetchAllEmployees, fetchProjects } from "../../../actions/commonActions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllLeadStatus, getAllLeadSources} from '../../modal/modalBody/leads/createLead/action';
import { fetchCrmLeadFormList } from '../../../containers/leads/LeadForm/action';
import LeadCompaignDrawer from "../../../containers/drawer/leads/leadCompaign"
import { fetchCrmLeadCampaign } from './action';
import { createCrmLeadCampaign } from '../../drawer/leads/leadCompaign/action';
class LeadCampaingn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNumber: 1,
      pageSize: 25,
      errMsg: false
    }

  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 25,
    }

    this.props.fetchAllEmployees(payload);
    this.props.getAllLeadStatus(payload);
    this.props.getAllLeadSources(payload);
    this.props.fetchCrmLeadCampaign(payload);

  }


  render() {
    return <>
      <LeadCampaignListingComp {...this.props} {...this.state}  updateState={(data) => { this.setState(data) }}/>
      {this.state.leadCompaignDrawer && <LeadCompaignDrawer {...this.state} updateState={(data) => { this.setState(data) }}  />}

    </> 
  }
}

const mapStateToProps = (state) => {
  return {
   leadCampaigns:state.crm.leadCampaigns,
   companyInfo: state.common.companyInfo,
   userInfo: state.common.userInfo,
   isLoading: state.common.isLoading,
   crmLeadStatusList: state.crm.crmLeadStatusList,
   crmLeadFormList: state.crm.crmLeadFormList,
   crmLeadSourceList: state.crm.crmLeadSourceList,
   crmCampaignList: state.crm.crmCampaignList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  fetchAllEmployees,
  getAllLeadStatus, 
  fetchCrmLeadFormList,
  fetchProjects,
  getAllLeadSources,
  fetchCrmLeadCampaign,
  createCrmLeadCampaign,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadCampaingn);