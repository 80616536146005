import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BankingComponent from '../../../components/finance/Banking';
import { hideModal, showModal, pushModalToStack, popModalFromStack, fetchSupplierSummary, fetchProjects } from '../../../actions/commonActions';
import { downloadFileFromBucket } from '../../../actions/downloadFileAction';
import { fetchCustomers } from '../../customer/Listing/action';
import { generatePlaidLinkToken, setAccessToken, getPlaidLinkedAccounts, getBankTransactions, resetBankTransactions, getMatchTransactionCount, getTransactionsInAlpide,
    getTransactionsCountInAlpide, deleteBank, getAllBankStatementUploadDraft, getStatementUploadDraftCount, resetStatementUploadDraft, deleteStatementUploadDraft, downloadStatementFromAuthorize,
    hideBankTransaction, getHiddenTransactions, getHiddenTransactionsCount, resetHiddenTransactionList } from './action';
import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from '../../../utils';
import { createStatementPayment, fetchAllLedgerAccounts, saveAsDraft } from '.././../modal/modalBody/finance/Banking/UploadStatement/action';
import { fetchAvailablePaymentGateway } from '.././../integrations/Payment/action';
import * as find from 'lodash.find';

class BankingComp extends Component {

    constructor(props) {
        super();
        this.state = {
            searchedText: '',
            isDefaultLedgerInit: true,
            pageSize: 25,
        }
    }

    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: true
        });
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            isCompact: true,
            pageNumber: 1,
            pageSize: 500,
        };
        this.props.resetBankTransactions();
        this.props.generatePlaidLinkToken();
        this.props.getPlaidLinkedAccounts(payload);
        fetchPaginationDataIfNeeded('fetchSupplierSummary', 'supplierSummary', this.props, payload, true);
        fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, payload, true);
        fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
        fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, { relationshipId: companyInfo.relationshipId });
        this.props.fetchAvailablePaymentGateway(payload);
    }

    componentWillReceiveProps(props) {
        if (props.bankTransactions && props.bankTransactions.length && props.allLedgerAccounts && props.allLedgerAccounts.length && this.state.isDefaultLedgerInit) {
            // let totalDeposit = 0;
            // let totalWithdrawal = 0;
            // let depositCount = 0;
            // let withdrawalCount = 0;
            // let isColumnEmpty = {
            //     partyName: true,
            //     project: true,
            //     instrumentNumber: true,
            //     instrumentDate: true,
            //     description: true,
            //     institutionName: true
            // }
            let list = [];
            let cashAtBankLedger = find(props.allLedgerAccounts, { ledgerAccountName: "Cash at bank" }) || {};
            //let creditorsLedger = find(props.allLedgerAccounts, { ledgerAccountName: "Creditors" }) || {};
            //let debtorsLedger = find(props.allLedgerAccounts, { ledgerAccountName: "Debtors" }) || {};
            (props.bankTransactions || []).forEach((payloadData) => {
                let ledgerAccount = find(props.allLedgerAccounts, { ledgerAccountName: payloadData.ledgerAccount }) || {};
                let project = find(props.projectList, { projectName: payloadData.projectName }) || {};
                payloadData.projectName = project.projectName;
                payloadData.projectMasterId = project.projectMasterId;
                payloadData.projectNumber = project.projectNumber;
                //let txAmount = Number(payloadData.Deposit || payloadData.Withdrawal);
                if (Number(payloadData.Deposit || 0) > 0) {
                    // depositCount++;
                    // totalDeposit += txAmount;
                    if (!(props.statementDraftData || {}).bankStatementUploadDraftId) {
                        payloadData.salesLedgerId = ledgerAccount.ledgerAccountId;
                        payloadData.salesLedgerName = ledgerAccount.ledgerAccountName;
                        payloadData.purchaseLedgerId = cashAtBankLedger.ledgerAccountId;
                        payloadData.purchaseLedgerName = cashAtBankLedger.ledgerAccountName;
                    }
                } else {
                    if (!(props.statementDraftData || {}).bankStatementUploadDraftId) {
                        payloadData.salesLedgerId = cashAtBankLedger.ledgerAccountId;
                        payloadData.salesLedgerName = cashAtBankLedger.ledgerAccountName;
                        payloadData.purchaseLedgerId = ledgerAccount.ledgerAccountId;
                        payloadData.purchaseLedgerName = ledgerAccount.ledgerAccountName;
                    }
                    //withdrawalCount++;
                   // totalWithdrawal += Math.abs(txAmount);
                }
                list.push(payloadData)
            });
            this.setState({
                bankTransactions: list,
                isDefaultLedgerInit: false,
            })
        }
    }

    render() {
        return <BankingComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        isLoading: state.common.isLoading,
        permissions: state.auth.permissions,
        plaidLinkToken: state.integration.plaidLinkToken,
        plaidLinkedAccounts: state.integration.plaidLinkedAccounts,
        bankTransactions: state.finance.bankTransactions,
        transactionsInAlpide: state.finance.transactionsInAlpide,
        transactionCountInAlpide: state.finance.transactionCountInAlpide,
        projectList: state.common.projectList,
        supplierSummary: state.supplier.supplierSummary,
        customers: state.customer.customers,
        uploadStatementDrafts: state.finance.uploadStatementDrafts,
        uploadStatementDraftCount: state.finance.uploadStatementDraftCount,
        allLedgerAccounts: state.ledger.allLedgerAccountsSummary, 
        availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
        
        hiddenTransactionList: state.finance.hiddenTransactionList,
        hiddenTransactionCount: state.finance.hiddenTransactionCount,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    generatePlaidLinkToken, 
    setAccessToken,
    hideModal,
    showModal,
    pushModalToStack,
    popModalFromStack,
    getPlaidLinkedAccounts,
    getBankTransactions, 
    resetBankTransactions,
    getMatchTransactionCount,
    getTransactionsInAlpide,
    fetchSupplierSummary,
    fetchCustomers,
    fetchProjects,
    getTransactionsCountInAlpide,
    downloadFileFromBucket,
    deleteBank,
    getAllBankStatementUploadDraft, 
    getStatementUploadDraftCount, 
    resetStatementUploadDraft,
    deleteStatementUploadDraft,
    createStatementPayment, 
    fetchAllLedgerAccounts, 
    saveAsDraft,
    downloadStatementFromAuthorize,
    fetchAvailablePaymentGateway,
    hideBankTransaction,
    getHiddenTransactions, 
    getHiddenTransactionsCount, 
    resetHiddenTransactionList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BankingComp);
