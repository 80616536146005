import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InvoiceComponent from '../../../../../../components/modal/modalBody/common/xero/Invoice';
import { getXeroAccounts, getXeroContacts, getInvoicesByInInvoiceMasterId, resetInvoicesListByInInvoiceMasterId, getAllXeroTaxes} from './action';

class XeroInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
     // invoiceListForUpload: props.invoiceList || []
    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    let invoiceMasterIdList = (this.props.invoiceList || [] || []).map(invoiceMaster => invoiceMaster.invoiceMasterId) || [];
    const payload = {
      relationshipId: companyInfo.relationshipId,
      invoiceMasterIdList,
      type: this.props.type
    };
    
    this.props.resetInvoicesListByInInvoiceMasterId(payload);
    this.props.getInvoicesByInInvoiceMasterId(payload);
    this.props.getXeroAccounts(payload);
    this.props.getXeroContacts(payload);
    this.props.getAllXeroTaxes(payload);
  }

  render() {
    return (
      <InvoiceComponent {...this.state} {...this.props} updateState= {(data)=>{
        this.setState(data)
      }}/>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    xeroContactList: state.common.xeroContactList,
    xeroLedgerList: state.common.xeroLedgerList,
    xeroTaxList: state.common.xeroTaxList,
    invoiceListForUpload: state.salesInvoice.invoiceListByMasterId
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  getXeroAccounts, 
  getXeroContacts,
  getInvoicesByInInvoiceMasterId,
  resetInvoicesListByInInvoiceMasterId,
  getAllXeroTaxes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(XeroInvoice);
