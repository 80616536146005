import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Pagination, Skeleton, Menu, Dropdown, Empty } from "antd";
import {getMomentDateForUIReadOnly, sortTable } from "../../../utils";
import { CONSTANTS, ICONS } from '../../../static/constants';
import './index.scss';
import ReactHtmlParser from 'react-html-parser';
import PageBreadcrumb from "../../PageBreadcrumb";
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const formatdateTime = CONSTANTS.DISPLAY_DATE_TIME_FORMAT;

const BroadcastMessage = (props) => {
  const { updateState, companyInfo, customerInfo, broadcastMessageCount, pageNumber, pageSize, dir=0 } = props;

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      customerId: customerInfo.customerId
    };

    props.getActiveBroadcastMessage(payload);
    props.getActiveBroadcastMessageCount(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      customerId: customerInfo.customerId
    };
    props.resetPaginatedData();
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getActiveBroadcastMessage(payload);
    props.getActiveBroadcastMessageCount(payload);
  };

  const newEmailBroadcast=(broadcastData)=>{
    props.updateState({
      broadcastMessageDrawerVisible: true,
      data: broadcastData,
    });
  }

  const openLongtText = (title, Text)=>{
    props.showModal({
        title: title,
        modalBody: <div style={{maxHeight: '550px', overflow: 'auto'}}>{ReactHtmlParser(Text)}</div>,
        hideFooter: true,
        width: '50%'
    })
  }
  
  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.schoolManagement' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='broadcastMessage.text' defaultMessage='' />,
    }
  ];

  const sortColumn = (e) => {
    sortTable(document.getElementById('broadcastMsg-table'), e.target.cellIndex, 1 - dir);
    updateState({ dir: 1 - dir })
  }

  const actionMenu = (e) => {
    return (
        <Menu className="row-action-dropdown">

            <Menu.Item key="0" value="title">
                Actions
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item key="1" value="edit" onClick={()=>{
                newEmailBroadcast(e);
              }}
            >
              <i className={ICONS.EDIT} />
                Edit
            </Menu.Item>

            <Menu.Item key="2" onClick={()=>
              {
                props.showModal({
                  modalBody: "Are you sure you want to delete?",
                  handleSubmit: () => {
                    props.deleteBroadcastMessage(e, props);
                  }
                })
              }
            }>
              <i className={ICONS.DELETE} />
                Delete
            </Menu.Item>

        </Menu >
    )
  };

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='heading.schoolManagement.broadcastMessage' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button className="create-action-btn" onClick={()=>{
            props.updateState({
              broadcastMessageDrawerVisible: true,
              data: {},
            })
          }}>
            <i className={ICONS.ADD} /> <FormattedMessage id="newMessage.text" defaultMessage="" />
          </button>
        </div>
      </div>
      <div className="view-container">
        <Skeleton loading={props.isLoading}>
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage id='schoolManagement.broadcastMessages.table.heading' defaultMessage='' />
              </div>
              {
                pageSize ? <>
                  <div className="vertical-sep" />
                  <div>{broadcastMessageCount}</div>
                </>:''
              }
            </div>
            <div className="right-actions">
            </div>
          </div>
          <div className="table-container">
            <table id='broadcastMsg-table'>
              <thead>
                <tr>
                  <th width='5%' onClick={sortColumn}>S.No.</th>
                  <th width='15%' onClick={sortColumn}>Title <i className={ICONS.SORT} /></th>
                  <th width='15%' onClick={sortColumn}>Distribution Group <i className={ICONS.SORT} /></th>
                  <th width='20%' onClick={sortColumn}>Date Scheduled <i className={ICONS.SORT} /></th>
                  <th width='20%' onClick={sortColumn}>Date Created <i className={ICONS.SORT} /></th>
                  <th width='20%' onClick={sortColumn}>Created By <i className={ICONS.SORT} /></th>
                  <th width='5%'>Action</th>
                </tr>
              </thead>
              <tbody>
                {(props.broadcastMessageList[pageNumber]  || []).length ? props.broadcastMessageList[pageNumber].map((e, i) => {
                  return <tr key={'std' + i}>
                    <td>{i+1}</td>
                    <td>{ReactHtmlParser(e.subject)} </td>
                    <td>{e.dgName?e.dgName:"-"} </td>
                    <td>{e.startDate ? getMomentDateForUIReadOnly({ date: new Date(e.startDate), format:formatdateTime }) : '-'}</td>
                    {/* <td>{e.endDate ? getMomentDateForUIReadOnly({ date: new Date(e.endDate), format }) : '-'}</td> */}
                    {/* <td>
                      <p className="liguy-max" style={{height :'35px'}}>{ReactHtmlParser(e.message || '')}</p>
                      {e.message? <span style={{ color: '#0079ff', cursor: 'pointer', padding: '0px 0px' }} onClick={()=>{openLongtText('Message', e.message)}}>Show More</span> : ''}
                    </td> */}
                    <td>{e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : '-'}</td>
                    <td>{e.employeeName || "-"}</td>
                    <td style={{paddingLeft: "50px"}}>                         
                      <Dropdown overlay={actionMenu(e)} trigger={['click']}>
                        <i className={ICONS.MORE} />
                      </Dropdown>
                    </td>
                  </tr>
                })
                : 
                <tr key="empty-data-box">
                  <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={broadcastMessageCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(BroadcastMessage);
