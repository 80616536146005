import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { Pagination, Tooltip } from "antd";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from "../../../utils";
import { CONSTANTS } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";


const School = (props) => {
  const { schoolList, updateState, companyInfo, schoolCount, pageNumber,pageSize } = props;

  // const agGridStyle = {
  //   height: "100%",
  //   width: "100%",
  // };


  const breadCrumbList = [
    {
      name: <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
    },
    {
      name: <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />,
    }
  ];
  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded("getSchool", "schoolList", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.getFees(payload);
    //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };



  const newSchool = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addSchoolDrawerVisible: true,
        saveSchool: props.saveSchool,
        getSchool: props.getSchool,
        userInfo: props.userInfo
      },
    });
  };

  return (
    <Fragment>
      <div className="pb10">
        <PageBreadcrumb breadCrumbList={breadCrumbList} />
      </div>
      <div className="view-container sales-order-details mt0">

        <div className='team-and-free'>
          <div className="right-align pb10">
            <div className="action-dropdown-btn">
              <button onClick={() => {
                newSchool();
              }}>
                <FormattedMessage id="button.create.label" defaultMessage="" />
              </button>
            </div>
          </div>
          <div className="agGridWrapper">
            <div className='new-table-camp'>
              <table>
                <tbody>
                  <tr>
                    <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
                    <th><FormattedMessage id="schoolManagement.school.name" defaultMessage="" /></th>
                    <th><FormattedMessage id="schoolManagement.school.principle" defaultMessage="" /></th>
                    <th><FormattedMessage id="schoolManagement.school.vp" defaultMessage="" /></th>
                    <th><FormattedMessage id="schoolManagement.school.address" defaultMessage="" /></th>
                    <th><FormattedMessage id="schoolManagement.school.dateCreated" defaultMessage="" /></th>
                    <th><FormattedMessage id='common.listing.header.action' defaultMessage="" /></th>
                  </tr>
                  {
                    (schoolList[pageNumber] || []).map((e, i) => {
                      return <tr>
                        <td>{(pageNumber-1)*pageSize+(i+1)}</td>
                        <td>{e.schoolName}</td>
                        <td>{e.principleName}</td>
                        <td>{e.vicePrincipleName}</td>
                        <td>{e.address}</td>
                        <td><Tooltip placement="topLeft" title={(e.dateCreated && getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                          {(e.dateCreated && getMomentDateForUIReadOnly({ date: e.dateCreated, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                        </Tooltip></td>
                        <td> <span style={{ fontSize: '20px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => {
                          props.showModal({
                            modalBody: "Are you sure you want to delete?",
                            handleSubmit: () => {
                              props.deleteSchool(e, props);
                            }
                          })
                        }}>
                          <i className="fa fa-trash" title={props.intl.formatMessage({ id: 'delete.text' })} />
                        </span></td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            size="small"
            total={schoolCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(School);
