import axios from 'axios';
import config from '../../../../../../config/environmentConfig';
import { FINANCE_ACTION_LIST, COMMON_ACTIONS } from '../../../../../../static/constants';
import { showToasterMessage } from '../../../../../../utils';
const { lang } = require('../../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAssociateTransactions = (payload, history) => {
  //let FY = getFinancialYear(payload.companyInfo);
  let uri = 'getUnassociatedDeposit';
  if(Number(payload.Withdrawal) < 0){
    uri = 'getUnassociatedWithdrawal';
  }
   
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: FINANCE_ACTION_LIST.RESET_UNASSOCIATE_TX_LIST });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankReco/${uri}`, payload)
      .then(res => {
        dispatch({ type: FINANCE_ACTION_LIST.UNASSOCIATE_TX_LIST, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          // err.actionToCall = SALES_INVOICE_ACTION_LIST.SALES_INVOICE_LIST;
          // err.data = {
          //   pageNo: payload.pageNumber,
          //   list: []
          // };
        }
      })
  }
}

export const associateDiposit= (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankReco/associateDeposit`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.hideModal();
        props.getBankTransactions({ institutionId: (props.activeAccount || {}).institutionId, relationshipId: (props.companyInfo || {}).relationshipId });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}


export const associateWithdrawal = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/bankReco/associateWithdrawal`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.hideModal();
        props.getBankTransactions({ institutionId: (props.activeAccount || {}).institutionId, relationshipId: (props.companyInfo || {}).relationshipId });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}