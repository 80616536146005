import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PriceListCompo from '../../../../../components/modal/modalBody/common/PriceList';
import { showModal, hideModal, pushModalToStack, popModalFromStack,fetchPriceList } from '../../../../../actions/commonActions';
import { createPriceList } from './action';
class PriceListContainer extends Component {
  // componentDidMount() {
  //   const companyInfo = this.props.companyInfo || {};
  //   const payload = {
  //     relationshipId: companyInfo.relationshipId
  //   };
  //   fetchDataIfNeeded('fetchAllDepartments', 'departments', this.props, payload);
  //   fetchDataIfNeeded('fetchAllEmployees', 'employees', this.props, payload);
  // }

  render() {
    return (
      <PriceListCompo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createPriceList,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  fetchPriceList,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceListContainer);
