import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JobRoleComp from '../../../../components/company/EmployeeMeta/JobRole';
import JobRoleDrawer from '../../../drawer/company/EmployeeMeta/JobRole';
import { createJobRole,getJobRole,getTotalJobRoleCount,deleteJobRole ,resetPaginatedData,setJobRoleStatus} from './action';
import { showModal,hideModal } from '../../../../actions/commonActions';
import { fetchPaginationDataIfNeeded } from '../../../../utils';

class JobRoleContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,

    }
    };
  

  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      pageNumber: 1,
      pageSize: 25,
    }
   this.props.getJobRole(payload);
   this.props.getTotalJobRoleCount(payload);
   fetchPaginationDataIfNeeded('getJobRole', 'jobRoleList', this.props, payload, true);


  }

 

  render() {
      return <>

<JobRoleComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
{this.state.jobRoleDrawer && <JobRoleDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    
    ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    userInfo: state.common.userInfo,
    isLoading: state.common.isLoading,
    jobRoleList: state.employee.jobRoleList,
    jobRoleListCount: state.employee.jobRoleListCount,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  createJobRole,
  getJobRole,
  getTotalJobRoleCount,
  deleteJobRole,
  resetPaginatedData,setJobRoleStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JobRoleContainer);
