import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPaymentModes, showModal, hideModal, fetchCashEquivalentLedgers, pushModalToStack, popModalFromStack } from './../../../../actions/commonActions';
import { createSupplierPayment, getNextTxNumber } from './action';
import { fetchUnpaidInvoices, resetPaginatedData } from '../../../modal/modalBody/supplier/UnpaidInvoices/action';
import { fetchSuppliers } from '../../Listing/action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import PayInvoice from '../../../../components/supplier/invoice/PayInvoice';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import find from 'lodash.find';

class PayInvoiceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierId: props.invoiceData.supplierId,
      paymentDate: new Date(),
      supplierName: props.invoiceData.supplierName,
      invoiceMasterId: props.invoiceData.invoiceMasterId,
      invoiceAmount: props.invoiceData.invoiceTotalAmount || 0,
      invoiceNumber: props.invoiceData.invoiceNumber,
      amount: Number(props.invoiceData.invoiceDueAmount || 0).toFixed(2),
      invoiceAmountDue: props.invoiceData.invoiceDueAmount || 0,
      salesQuotationMasterId: props.invoiceData.salesQuotationMasterId,
      quotationNumber: props.invoiceData.quotationNumber,
      rfqNumber: props.invoiceData.rfqNumber,
      rfqMasterId: props.invoiceData.rfqMasterId,
      reference: props.invoiceData.referenceNumber,
      customerId: props.invoiceData.customerId,
      customerName: props.invoiceData.customerName,  
      cashInHandLedger: find((props.companyInfo || {}).defaultCoaLedgerAccountsList, { txType: "Cash in hand" }) || {},
      creditorsLedger: find((props.companyInfo || {}).defaultCoaLedgerAccountsList, { txType: "Creditors" }) || {},
      isFocus: false,

    };
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      supplierId: this.props.invoiceData.supplierId,
      pageSize: 100,
      pageNumber: 1
    };
    this.props.resetPaginatedData();
    payload.supplierId && fetchPaginationDataIfNeeded('fetchUnpaidInvoices', 'unpaidInvoiceList', this.props, payload, true);
    fetchPaginationDataIfNeeded('fetchSuppliers', 'suppliers', this.props, payload, true);
    fetchDataIfNeeded('fetchPaymentModes', 'paymentModeList', this.props, payload);
    fetchDataIfNeeded('fetchCashEquivalentLedgers','cashEquivalentLedgers', this.props, payload);
    if (!linkProps.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }
    this.props.fetchRemarksTemplate({relationshipId: companyInfo.relationshipId, docName: 'Purchase Payment'});
  }
  componentWillReceiveProps(props) {
    if (!this.state.remarks && props.remarksData &&  props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ 
        remarks: props.remarksData.content || '',
        isRemarksSet: true,
        customerId: props.invoiceData.customerId,
        customerName: props.invoiceData.customerName, 
       });
      setTimeout(()=>{
        props.resetRemarksData();
    },500)
    }
  }

  render() {
    return (
      <PayInvoice {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    unpaidInvoiceList: state.supplier.unpaidInvoiceList,
    paymentModeList: state.common.paymentModeList,
    suppliers: state.supplier.suppliers,
    cashEquivalentLedgers: state.common.cashEquivalentLedgers,
    nextTxNumber: state.supplierPayment.nextTxNumber,
    remarksData: state.settings.remarksData,
    detailLoading: state.common.detailLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchUnpaidInvoices,
  resetPaginatedData,
  fetchPaymentModes,
  fetchSuppliers,
  createSupplierPayment,
  fetchCashEquivalentLedgers,
  showModal,
  hideModal,
  getNextTxNumber,
  fetchRemarksTemplate,
  resetRemarksData,
  pushModalToStack,
  popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PayInvoiceContainer);
