
import { GSTR_FILLING_ACTIONS } from '../static/constants';
const initialState = {
    eInvoiceListing :{},
     validateInvoiceListing:[],
     summaryInvoiceListing:[]
}

const GSTFillingReducer = (state = initialState, action) => {
  switch (action.type) {

    case GSTR_FILLING_ACTIONS.E_INVOICE_LISTING:
      return { ...state, eInvoiceListing: action.data };
    
      case GSTR_FILLING_ACTIONS.VALIDATE_GSTR_INVOICE_LISTING:
        return {...state, validateInvoiceListing: action.data};
      case GSTR_FILLING_ACTIONS.SUMMARY_GSTR_INVOICE_LISTING:
        return {...state, summaryInvoiceListing: action.data}
      default:
      return state;
  }
};

export default GSTFillingReducer;