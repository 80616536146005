import React from "react";
import "../../../assets/stylesheets/common/invoice.scss";
import RFQDetails from "./RFQDetails";
import ProductDetails from "./ProductDetails";
import { Divider, Skeleton } from "antd";
const RFQComponent = (props) => {


  return (
      <div style={{ padding: "20px" }}>
        <div className="txn-header">
          {/* <CustomerHeaderAction {...props} /> */}
        </div>
        <div className="txn-body">
          <div className="txn-details-container">
            <RFQDetails {...props} />
          </div>
          <Divider style={{ margin: "0 0 10px 0px" }} />
          <div className="product-details-container">
            <Skeleton loading={props.listLoading} paragraph={{ rows: 4}} active>
              <ProductDetails {...props} />
            </Skeleton>
          </div>
        </div>
      </div>
  );
};

export default RFQComponent;
