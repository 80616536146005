import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST, STUDENT_REGISTRATION_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const saveRegistrationFormSetting = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
   
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/saveFormSetting`, payload)
      .then(res => {
        if(!props || !props.defaultSchoolForm){ dispatch({ type: COMMON_ACTIONS.DEFAULT_SCHOOL_FORM, data: res.data.registrationFormSettingId });}
        showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'saved.successfully'] });
        props.popModalFromStack();
       // props.fetchRegistrationFormSettingList({...payload, pageSize: 100, pageNumber: 0})
        (props.getAllMembershipForm && payload.registrationType === 'membership') && props.getAllMembershipForm({...payload, pageSize: 100, pageNumber: 0});
        (props.getAllStudentForm && payload.registrationType === 'student') && props.getAllStudentForm({...payload, pageSize: 100, pageNumber: 0})
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } 
      })
  }
}

export const fetchRegistrationFormSetting = (payload) => {
  return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      dispatch({ type: SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/getFormSetting?relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`)
        .then(res => {
          const { data } = res
          dispatch({ type: SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = SETTING_ACTION_LIST.REGISTRATION_FORM_SETTING;
          }
        })
    }
}

export const fetchAvailablePaymentGateway =  (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      //.get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getAuthNetData?relationshipId=${payload.relationshipId}`)
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getAllPaymentGateways?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res;
        dispatch({type: STUDENT_REGISTRATION_ACTION_LIST.AVAILABLE_PAYMENT_GATEWAY, data})
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
        }
      })
  }
}

export const resetRegistrationFormSetting = () => {
  return dispatch => {
    dispatch({ type: SETTING_ACTION_LIST.RESET_REGISTRATION_FORM_SETTING });
  }
}