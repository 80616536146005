import React from 'react';
//import { Tabs } from 'antd';
import TabPage from './TabPage.js';
import './index.scss';

import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
//import { Button } from 'antd';
import { Dropdown } from '../../../../general/Dropdown';
import { CONSTANTS_TRANSACTIONS_KEY } from '../../../../../static/constants';

//const { TabPane } = Tabs;
function callback(key, props) {
    props.updateState({
        docName: key,
        docNameKey: CONSTANTS_TRANSACTIONS_KEY[key]
    })
}

const DocumentNameComponent = (props) => {

    return (
        <div>
            <div className="modal-dialog">
            <div className="modal-content full-width modify-width">
                <div className="inner-content">
            <Form className="pr20" layout="vertical" hideRequiredMark>
                        <Form.Item
                            label={
                                <FormattedMessage id='modal.settings.emailTemplate.transaction' defaultMessage='' />
                            }>
                            <Dropdown 
                                items={props.tabDocumentOptions} 
                                value={props.docName}
                                onSelect={
                                    (optionValue) => {
                                        callback(optionValue, props);
                                    } 
                                }
                            />
                        </Form.Item>
                        <TabPage {...props} />
                        </Form>
                        </div>
            </div>
                {/* <div className="modal-content">
                    <Tabs onChange={(key) => { callback(key, props); }} type="card">
                        {props.tabDocumentOptions.length === 0 ? null : props.tabDocumentOptions.map((tabObj, i) => {

                            return (
                                <TabPane tab={tabObj} key={tabObj}>
                                    <TabPage {...props} />
                                </TabPane>
                            );
                        })
                        }
                    </Tabs>
                </div> */}
            </div>
        </div>
    );

}


export default DocumentNameComponent;
