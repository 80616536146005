import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { injectIntl } from 'react-intl';
import "./index.scss";
import {Row,Col} from "antd";
import Icon1 from "../../../assets/images/icon/eazypostIcon.png";
import Icon2 from "../../../assets/images/icon/shipRocketIcon.png";
import EasyPost from "../../../containers/shipping/easyPost";

function ShippingComp(props) {
    return (
      <div className="container">
        <Row>
          <Col span={24}>
            <div className="header">
              <h2>Shipping</h2>
              <p>Manage your company shipping</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={20} xl={12} xxl={8}>
            <div className="paymentOptionArea">
              <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon1} alt="" />
                  <h2>Easypost</h2>
                </div>
                <div className='buttonArea'>
                  <button onClick={()=>{
                    props.pushModalToStack({
                      title:"Sales Channel Amazon",
                      modalBody: <EasyPost {...props} />,
                      width: '100%',
                      hideFooter: true,
                      wrapClassName: 'new-transaction-wrapper'
                  })
                  }} className='notActiveButton'>Connect</button>
                </div>
                {/* <div className='notActive'></div> */}
              </div>
              <div className='paymentOption'>
                <div className='nameArea'>
                  <img src={Icon2} alt="" />
                  <h2>Shiprocket</h2>
                </div>
                <div className='buttonArea'>
                  <button className='notActiveButton'>Inprocess</button>
                </div>
                <div className='notActive'></div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );

}

export default injectIntl(ShippingComp);