import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Popover, Menu, Pagination,Input  } from 'antd';
import { InsertRowRightOutlined,MoreOutlined,UserOutlined } from '@ant-design/icons';
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { getMomentDateForUIReadOnly, sortTable } from '../../../utils';
import find from 'lodash.find';
import { CONSTANTS, ICONS } from '../../../static/constants';
import {  DownloadOutlined } from "@ant-design/icons";
const { Search } = Input;

const MonthlyPayGrade = (props) => {

    const breadCrumbList = [
        {
            name: "Dashboard",
        },
        {
          name: <FormattedMessage id='sidebar.menuItems.employee'/>,
      },
        {
            name: "Monthly Pay Grade",
        }
    ];

  

    const actionMenu = (e) => {
      return (
        <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Actions
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item
            key="1"
            value="edit"
            onClick={() => {
            
            }}
          >
            <i className={ICONS.EDIT} />
            Edit
          </Menu.Item>

          {e.isAccountHolder ? (
            <Menu.Item
              key="2"
             
            >
              <i className={ICONS.DELETE} />
              Delete
            </Menu.Item>
          ) : (
            ""
          )}

        
        </Menu>
      );
    };

    

    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">
              <FormattedMessage
                id="heading.team.payrollManagement.monthlyPayGrade"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
          <div className="top-view-right">
            <button
              className="create-action-btn"
              onClick={() => {
                props.updateState({ monthlyPayDrawer: true });
              }}
            >
              <i className={ICONS.ADD} />{" "}
              <FormattedMessage id="button.create.label" defaultMessage="" />
            </button>
          </div>
        </div>

        <div className="view-container">        
            <div className="view-container-actions">
            <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
              <div className="left-actions">
                <div className="table-heading">
                  <FormattedMessage
                    id="company.payrollMangement.monthlyPayGrade.table.heading"
                    defaultMessage=""
                  />
                </div>
                {[]?.length ? (
                  <>
                    <div className="vertical-sep" />
                    <div>{[]?.length}</div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="right-actions">
             
              <Search
                placeholder="Search Monthly Pay Grade"
                value={props.searchedText}
                onChange={(e) => {
                  // updateState({ searchedText: e.target.value, pageNumber: 1 });
                  // onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  // updateState({ searchedText, pageNumber: 1 });
                  // onSearchedInputChange(searchedText, props);
                  // handleSearchedText(searchedText, props);
                }}
              />
             <DropdownAction 
            //  overlay={downloadMenu()}
              trigger={["click"]}>
                <span>
                  <DownloadOutlined /> &nbsp;{" "}
                  <FormattedMessage
                    id="common.action.dropdown.downloadAs"
                    defaultMessage=""
                  />
                </span>
              </DropdownAction>
              </div>
              </Skeleton>
            </div>

            

            <div className="table-container">
            <Skeleton loading={props.listLoading} paragraph={{ rows: 18 }} active>

              <table id="monthly-table">
                <thead>
                <tr>
                    <th>
                      <FormattedMessage id="serial.no" defaultMessage=" " />
                    </th>

                    <th>
                      <FormattedMessage
                        id="company.payrollManagement.payGradeName"
                        defaultMessage=" "
                      />
                    </th>

                   
                      <th>
                        <FormattedMessage
                          id="company.payrollManagement.grossSalary"
                          defaultMessage=" "
                        />
                      </th>
                  
                
                      <th>
                        <FormattedMessage
                          id="company.payrollManagement.percentage"
                          defaultMessage=" "
                        />
                      </th>
                  
                    <th>
                      <FormattedMessage
                        id="company.payrollManagement.basicSalary"
                        defaultMessage=" "
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="company.payrollManagement.overtimeRate"
                        defaultMessage=" "
                      />
                    </th>            
                      <th className="text-center">
                        <FormattedMessage
                          id="actions.text"
                          defaultMessage=" "
                        />
                      </th>
                  
                  
                      
                  </tr>
                </thead>
                <tbody>
                  {([]).map((rowData, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>

                          <td>
                            {rowData.employeeCode ? rowData.employeeCode : "-"}
                          </td>

                            <td>
                              {rowData.firstName +
                                (rowData.lastName
                                  ? " " + rowData.lastName
                                  : "")}
                            </td>
                          
                            <td>{rowData.empType}</td>
                         
                          <td>{rowData.hierarchyName}</td>
                          <td>{rowData.hierarchyCode}</td>
                            <td>{rowData.jobTitleName}</td>
                        
                            <td>{rowData.jobRoleName}</td>
                          
                        
                      

                          <td>
                            {rowData.dateCreated
                              ? getMomentDateForUIReadOnly({
                                  date: rowData.dateCreated,
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })
                              : "-"}
                          </td>

                          <td>
                            <DropdownAction
                              overlay={actionMenu(rowData)}
                              trigger={["click"]}
                            >
                              <i className={ICONS.MORE} />
                            </DropdownAction>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              </Skeleton>
            </div>

            {/* <div className="footer">
                  <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

              <div className="f-left"></div>
              <div className="f-right">
                <Pagination
                  size="small"
                  total={30}
                  showTotal={showTotal}
                  defaultPageSize={props.pageSize}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100, 200]}
                  // showQuickJumper
                  onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber });
                  }}
                  onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber || 1, pageSize, props);
                  }}
                />
              </div>
          </Skeleton>
            </div> */}
        </div>
      </>
    );
};




export default injectIntl(MonthlyPayGrade);
