import React from "react";
import { Drawer, Row, Col } from "antd";
// import { FormattedMessage } from "react-intl";
import {
  showToasterMessage,
  getUserDefinedName,
} from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
// import { CONSTANTS } from "../../../static/constants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { CustomButton } from "../../general/CustomButton";


const ClassListFilterComp = (props) => {
  const { updateState, companyInfo } = props;

  const closeDrawer = () => {
    props.updateState({
      classListFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
    // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
    if (!props.className && !props.classCode && !props.classStatus) {
      return showToasterMessage({
        messageType: "error",
        description: "Please select at least one filter",
      });
    }
    const payload = {
      companyInfo,
      //   relationshipId: teacherInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {
        className: props.className || null,
        classCode: props.classCode || null,
        classStatus: props.classStatus,
      },
    };
    // props.fetchPurchaseOrdersByFilter(payload);
    // props.getPurchaseOrdersCountByFilter(payload);
    props.fetchClassListByFilter(payload);
    props.getClasslistCountByFilter(payload);
  };

  const clearFilter = () => {
    props.updateState({
      className: null,
      classCode: null,
      classStatus: null,
    });
    // const payload = {
    //   companyInfo,
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: 0,
    //   pageSize: props.pageSize,
    //   filters: {},
    // };
    // props.fetchPurchaseOrders(payload);
    // props.getPurchaseOrdersCount(payload);
  };

  const rowGutter = [24, 16];

  return (
    <>
      <Drawer
        title={getUserDefinedName("class.classList.filter.drawer.title", props)}
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.classListFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">
              {getUserDefinedName("schoolManagement.class.name", props)}
            </div>
            <TextBox
              placeholder= {getUserDefinedName("schoolManagement.class.name", props)}
              type="text"
              maxLength={20}
              value={props.className}
              onChange={(e) => {
                props.updateState({ className: e.target.value });
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">
              {getUserDefinedName("schoolManagement.class.code", props)}
            </div>
            <TextBox
              placeholder= {getUserDefinedName("schoolManagement.class.code", props)}
              type="text"
              maxLength={20}
              value={props.classCode}
              onChange={(e) => {
                props.updateState({classCode: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">Status</div>
            <Dropdown
              items={[
                {
                  label: "Active",
                  key: "1",
                },
                {
                  label: "Inactive",
                  key: "2",
                },
            
         
              ]}
              valueKeyName="label"
              optionKeyName="key"
              value={props.classStatus}
              placeholder="Filter by Status"
              onSelect={(optionValue, option) => {
                props.updateState({
                  classStatus: option.name,

                  //   formId: option.value,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({ classStatus: undefined });
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default ClassListFilterComp;
