import React, { Component, Fragment } from 'react';
import config from '../../config/environmentConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../actions/commonActions';
import queryString from 'query-string';
import OrderPreviewComp from '../../components/orderPreview/OrderPreviewComp';
import { fetchSODetail } from '../modal/modalBody/customer/SODetail/action';
import { fetchRelationshipDataByRID, getAuthNetPaymentUrl, getPayOnlineUrl } from '../InvoicePreview/action';
import CryptoJS from "crypto-js";


class OrderPreview extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(this.props.location.search) || {};
    const bytes = linkProps.t ? CryptoJS.AES.decrypt(linkProps.t, config.ENCRYPT_SECRET) : null;
    const token = bytes ? bytes.toString(CryptoJS.enc.Utf8) : {};
    let ids = (token || '').split("_")
    this.state = {
     paymentGatewayName : "CardPointe",
     alpidePaymentGatewayId: ids[3]
    }
  }

  componentDidMount() {
    const linkProps = queryString.parse(this.props.location.search) || {}; 
    const bytes = linkProps.t ? CryptoJS.AES.decrypt(linkProps.t, config.ENCRYPT_SECRET) : null;
    const token = bytes ? bytes.toString(CryptoJS.enc.Utf8) : {};
    let ids = (token || '').split("_")

    const payload = {
      relationshipId: ids[0],
      customerId: ids[1],
      salesOrderMasterId: ids[2]
    };
   
    if (payload && payload.relationshipId) {
        this.props.fetchRelationshipDataByRID(payload);
      }
    this.props.fetchSODetail(payload);
  }
  componentWillReceiveProps(props) {
    
  
  }

  render() {
    return (
      <Fragment>
        <OrderPreviewComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    
    companyInfo: state.settings.relationshipData,
    soDetail: state.so.soDetail,

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
 
  showModal,
  hideModal,
  pushModalToStack, 
  popModalFromStack,
  fetchSODetail,
  fetchRelationshipDataByRID,
  getPayOnlineUrl,
  getAuthNetPaymentUrl
 
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderPreview);
