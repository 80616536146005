import { MARKETPLACE_SALES_CHANNEL_ACTION_LIST } from "../static/constants";

const initialState = {
    inventoryItemsListing: {
        1: [],
    },
    inventoryItemsCount: 0,
    
    amazonPublishedQty: 0,
    amazonUnPublishedQty: 0,
    amazonTodayListing: 0,
    amazonThisWeekListing: 0,
    amazonThisMonthListing: 0,
    amazonTotalSales: 0,

    ebayPublishedQty: 0,
    ebayUnPublishedQty: 0,
    ebayTodayListing: 0,
    ebayThisWeekListing: 0,
    ebayThisMonthListing: 0,
    ebayTotalSales: 0,

    walmartPublishedQty: 0,
    walmartUnPublishedQty: 0,
    walmartTodayListing: 0,
    walmartThisWeekListing: 0,
    walmartThisMonthListing: 0,
    walmartTotalSales: 0,
};

const MembershipReducer = (state = initialState, action) => {
    switch (action.type) {
        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.INVENTORY_ITEM_LISTING:
            return {
                ...state,
                inventoryItemsListing: {
                    ...state.inventoryItemsListing,
                    [action.data.pageNo || 1]: action.data.list,
                },
            };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.INVENTORY_ITEMS_COUNT:
            return { ...state, inventoryItemsCount: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.INVENTORY_LISTING_RESET:
            return { ...state, inventoryItemsListing: initialState.inventoryItemsListing };


        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.AMAZON_PUBLISHED_QTY:
            return { ...state, amazonPublishedQty: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.AMAZON_UNPUBLISHED_QTY:
            return { ...state, amazonUnPublishedQty: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.AMAZON_TODAY_LISTING:
            return { ...state, amazonTodayListing: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.AMAZON_THIS_WEEK_LISTING:
            return { ...state, amazonThisWeekListing: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.AMAZON_THIS_MONTH_LISTING:
            return { ...state, amazonThisMonthListing: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.AMAZON_TOTAL_SALES:
            return { ...state, amazonTotalSales: action.data };



        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.EBAY_PUBLISHED_QTY:
            return { ...state, ebayPublishedQty: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.EBAY_UNPUBLISHED_QTY:
            return { ...state, ebayUnPublishedQty: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.EBAY_TODAY_LISTING:
            return { ...state, ebayTodayListing: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.EBAY_THIS_WEEK_LISTING:
            return { ...state, ebayThisWeekListing: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.EBAY_THIS_MONTH_LISTING:
            return { ...state, ebayThisMonthListing: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.EBAY_TOTAL_SALES:
            return { ...state, ebayTotalSales: action.data };


        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_PUBLISHED_QTY:
            return { ...state, walmartPublishedQty: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_UNPUBLISHED_QTY:
            return { ...state, walmartUnPublishedQty: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_TODAY_LISTING:
            return { ...state, walmartTodayListing: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_THIS_WEEK_LISTING:
            return { ...state, walmartThisWeekListing: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_THIS_MONTH_LISTING:
            return { ...state, walmartThisMonthListing: action.data };

        case MARKETPLACE_SALES_CHANNEL_ACTION_LIST.WALMART_TOTAL_SALES:
            return { ...state, walmartTotalSales: action.data };

        default:
            return state;
    }
};

export default MembershipReducer;
