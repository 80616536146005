import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, DatePicker, Tooltip } from 'antd';
import { getCurrentDateForBackend, getMomentDateForUI } from './../../../../utils'
import { DropdownRef } from './../../../general/Dropdown';
import * as find from 'lodash.find';
import moment from 'moment-timezone';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { CONSTANTS } from '../../../../static/constants';

const { TextArea } = Input;

class LogCommunicationComponent extends Component {
  render() {
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

    const { getFieldDecorator } = this.props.form;
    const { formData } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };

    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content">
            <Form.Item label={
              <>
              <span>
                
                <FormattedMessage id='supplier.listing.logCommunication.communicationType.label' defaultMessage='' />
                <Tooltip placement="right" title={this.props.intl.formatMessage(
                {
                    id: 'supplier.listing.logCommunication.communicationType.tooltip',
                    defaultMessage: ''
                }
                )} trigger="click">
                <QuestionCircleTwoTone />
                </Tooltip>
                </span>
             
              </>
              // <Tooltip placement="right" title={<FormattedMessage id='supplier.listing.logCommunication.communicationType.tooltip' defaultMessage='' />}>
              //   <span className="tooltip-title"><FormattedMessage id='supplier.listing.logCommunication.communicationType.label' defaultMessage='' /><span className="required">*</span></span>
              // </Tooltip>
            } colon={false}>
              {getFieldDecorator('mode', {
                initialValue: formData.communicationMode || '',
                rules: [
                  {
                    required: true,
                    message: <FormattedMessage id='supplier.listing.logCommunication.communicationType.validationMessage' defaultMessage='.' />,
                  }
                ],
              })(<Input />)}
              {/* <div className="short-info">
                Communication mode could be Email, Courier, Fax etc
              </div> */}
            </Form.Item>

            <Form.Item label= {
              <>
              <span>
                
                <FormattedMessage id='supplier.listing.logCommunication.description.label' defaultMessage='' />
                <Tooltip placement="right" title={this.props.intl.formatMessage(
                {
                    id: 'supplier.listing.logCommunication.description.tooltip',
                    defaultMessage: ''
                }
                )} trigger="click">
                <QuestionCircleTwoTone />
                </Tooltip>
                </span>
              </>
              // <Tooltip placement="right" title={<FormattedMessage id='supplier.listing.logCommunication.description.tooltip' defaultMessage='' />}>
              //   <span className="tooltip-title"><FormattedMessage id='supplier.listing.logCommunication.description.label' defaultMessage='' /></span>
              // </Tooltip>
            } colon={false}>
              {getFieldDecorator('description', {
               initialValue: formData.description || '',
              })
                (<TextArea />)}
              {/* <div className="short-info">
                Brief description about communication
              </div> */}
            </Form.Item>
            <Form.Item label= {
              <>
                <span>
                
                <FormattedMessage id='supplier.listing.logCommunication.communicatedTo.label' defaultMessage='' />
                <Tooltip placement="right" title={this.props.intl.formatMessage(
                {
                    id: 'supplier.listing.logCommunication.communicatedTo.tooltip',
                    defaultMessage: ''
                }
                )} trigger="click">
                <QuestionCircleTwoTone />
                </Tooltip>
                </span>              
              </>
              // <Tooltip placement="right" title={<FormattedMessage id='supplier.listing.logCommunication.communicatedTo.tooltip' defaultMessage='' />}>
              //   <span className="tooltip-title"><FormattedMessage id='supplier.listing.logCommunication.communicatedTo.label' defaultMessage='' /></span>
              // </Tooltip>
            } colon={false}>
              {getFieldDecorator('communicatedTo', {
                initialValue: formData.communiatedTo || ''
              })
                (<Input />)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id='supplier.listing.logCommunication.communicatedBy.label' defaultMessage='' />} colon={false}>
              {getFieldDecorator('communicatedByUserId', {
                initialValue: formData.relationshipEmployeeId || ''
              })
                (<DropdownRef
                  items={this.props.allEmployee || []}
                  optionKeyName='relationshipEmployeeId'
                  valueKeyName='fullName'
                  onSelect={(selectedValue, option) => {
                    this.props.form.setFieldsValue({ 'communicatedByUserId': selectedValue });
                  }}
                  allowClear={true}
                  onClear={() => {
                    this.props.form.setFieldsValue({ 'communicatedByUserId': undefined });
                  }}
                />)}
            </Form.Item>
            <Form.Item label='Communication Date' colon={false}>
              {getFieldDecorator('communicationDate', {
                // initialValue: this.props.communicationDate || moment(new Date())
                initialValue: formData.communicationDate
                ? getMomentDateForUI({ date: new Date(formData.communicationDate) })
                : getMomentDateForUI({date:new Date()}),
              })(<DatePicker 
            
              />) }
            </Form.Item>
          </div>

          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <Button
              type="default"
              onClick={() => {
                this.props.popModalFromStack();
              }}
            >
              <FormattedMessage id='button.close.label' defaultMessage='' />
            </Button>
            <Button type="primary" htmlType="submit">
              <FormattedMessage id='button.save.label' defaultMessage='' />
            </Button>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    const { formData } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let employee = {};
        if (values.communicatedByUserId) {
          employee = find((this.props.allEmployee || []), { relationshipEmployeeId: values.communicatedByUserId }) || {};
        }
        const payload = {
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          communicationMode: values.mode,
          description: values.description,
          relationshipEmployeeId: values.communicatedByUserId,
          relationshipEmployeeName: employee.fullName,
          origination: 'Manual',
          refDocId: '',
          communiatedTo: values.communicatedTo,
          communicationDate: getCurrentDateForBackend(values.communicationDate),
          dateCreated: getCurrentDateForBackend(new Date()),
          userCreated: this.props.userInfo.firstName,
          supplierName: this.props.supplierName,
          supplierId: this.props.supplierId,
          supplierCommunicationTxId: (formData || {}).supplierCommunicationTxId || 0,
          version: (formData || {}).version || 0,
          inboundDeliveryAwsKeys: (formData || {}).inboundDeliveryAwsKeys || [],
          invoiceAwsKeys: (formData || {}).invoiceAwsKeys || [],
          poAwsKeys: (formData || {}).poAwsKeys || [],
          debitMemoAwsKeys: (formData || {}).debitMemoAwsKeys || [],
          paymentAwsKeys: (formData || {}).paymentAwsKeys || [],
        }

        const modalData = {
          modalBody: Object.keys(formData).length ? <FormattedMessage id='condfirmation.update.communication' defaultMessage='' /> : <FormattedMessage id='Are.you.sure.communication' defaultMessage='' />,
          handleSubmit: () => {
            this.props.createUpdateCommunication(payload);
            this.props.popModalFromStack();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Log_activity' })(injectIntl(LogCommunicationComponent));
