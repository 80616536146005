import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Form, Input, Select, InputNumber, DatePicker, Checkbox, Tooltip, } from 'antd';
import { TextBox } from '../../general/TextBox';
import { Dropdown } from '../../general/Dropdown';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS, PAYMENT_SOURCE_BY_ID } from '../../../static/constants';
// import { CustomButton } from '../../general/CustomButton';
import { validateEmail, getCurrentDateForBackend, showToasterMessage } from '../../../utils';
//import * as find from 'lodash.find';
import moment from 'moment-timezone';
import { ErrorMsg } from '../../general/ErrorMessage';
import config from '../../../config/environmentConfig';
import { LogoComponent } from '../../general/LogoComponent';

const { TextArea } = Input;
const { Option } = Select;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const getAddressInfoFragment = (obj, additionInfo) => {

    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <span className='billing-address'>{obj.streetAddress1}
                </span>
                : ''
        }
        {
            obj.streetAddress2
                ? <span className='billing-address'> {obj.streetAddress2}
                </span>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {
            obj.countryName
                ? <div className='billing-address'>
                    {
                        `${obj.countryName || ''}`
                    }
                </div>
                : ''
        }
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}
function CreatePledgeComponent(props) {
    return (
        <div className='pay-box-porls' >
            <Row gutter={24} style={{ background: props.onlineThemes.bgColor || '#06033A', color: props.onlineThemes.textColor || '#FFFFFF', padding: '5px', marginLeft: '0px', marginRight: '0px', marginBottom: '15px' }} className='ltiuy'>
                <Col span={2} className='litlyu'></Col>
                <Col span={2}>
                    <div className="brand-logo" style={{ marginTop: '10px' }}>
                        {(props.companyInfo || {}).logoAwsObjectKey ? <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} /> : ''}
                    </div>
                </Col>
                <Col span={12} style={{ alignSelf: 'center' }}>
                    <div className='logosizef'> {props.companyInfo.storeName}</div>
                </Col>
                <Col span={8} style={{ alignSelf: 'center' }} className='newaddr'>
                    {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)}
                </Col>
            </Row>
            
             {props.isSubmitPlageSuccess ? 
           
                <div style={{height:'80vh'}}>
                    <div className='confirm-boxs'>
                        <div className='done-popup'>
                            <div className='ant-modal-title' style={{ textAlign: 'center', fontSize: '20px', color: '#fff', fontWeight: '300' }}>
                                {/* <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg> */}

                                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                                {/* Payment Complete! */}
                                <b>Thank you for your pledge. Your pledge has been submitted successfully</b>
                            </div>

                        </div>
                        <div style={{ padding: '50px 0px', textAlign: 'center', fontSize: '16px' }}>
                        A confirmation email has been sent to your email you have proivded on the pledge form.
                        </div>

                    </div>
                </div>
                :
                <div className="header-action-container pay-ment-boxcpc butmat" >

                    <div >

                        <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px', borderBottom: 'solid 1px #e4e4e4', marginBottom: '20px' }}>
                            <h3> <b> {props.fundraisingCampaignId ? `Pledge for ${(props.selectedCampain || {}).campaignName || ''}` : 'Welcome to Online Pledge Form'}</b></h3>
                        </div>

                        <div className=''>

                            <div>
                                <Form layout="vertical" hideRequiredMark>

                                    <Row gutter={16}>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <span className="">
                                                <FormattedMessage id='contact.firstName' defaultMessage='' />
                                            </span><span className="required">*</span>
                                        </Col>
                                        <Col span={12}>

                                            <TextBox
                                                type='text'
                                                className={(props.submittedOnce && !props.firstName) ? 'input-text-error' : ''}
                                                value={props.firstName}
                                                onChange={(e) => {
                                                    props.updateState({
                                                        firstName: e.target.value,
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'contact.firstName.placeholder', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || props.firstName }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'contact.firstName.message',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row gutter={16}>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <span className="">
                                                <FormattedMessage id='contact.lastName' defaultMessage='' />
                                            </span>
                                            <span className="required">*</span>
                                        </Col>
                                        <Col span={12}>

                                            <TextBox
                                                type='text'
                                                className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                                                value={props.lastName}
                                                onChange={(e) => {
                                                    props.updateState({
                                                        lastName: e.target.value,
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'contact.lastName.placeholder', defaultMessage: '' })}
                                            />
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row gutter={16}>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <span className="">
                                                <FormattedMessage id='email' defaultMessage='' />
                                            </span>
                                            <span className="required">*</span>
                                        </Col>
                                        <Col span={12}>
                                            {/* <input type='text' className='ant-input input-text' /> */}
                                            <TextBox
                                                type='text'
                                                className={(props.submittedOnce && !props.emailAddress) ? 'input-text-error' : ''}
                                                value={props.emailAddress}
                                                onChange={(e) => {
                                                    props.updateState({
                                                        emailAddress: e.target.value,
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'contact.email.placeholder', defaultMessage: '' })}
                                            />
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row gutter={16} className='newregis'>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <span className="">
                                                <FormattedMessage id='contact.phone' defaultMessage='' />
                                                <Tooltip placement="top" title={props.intl.formatMessage({ id: 'phoneNumber.incorrect.message' })} trigger="click">
                                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                                </Tooltip>
                                            </span>

                                        </Col>
                                        <Col span={12} className="input-currency-control">
                                            <span className="currency-tag" style={{ left: '8px' }}>
                                                {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                            </span>
                                            <TextBox
                                                type='text'
                                                style={{ paddingLeft: '45px', width: '80%' }}
                                                // className={(props.submittedOnce && !props.phoneNumber) ? 'input-text-error' : ''}
                                                value={props.phoneNumber}
                                                maxLength={10}
                                                onChange={(e) => {
                                                    const re = /^[0-9\b]+$/;
                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                        props.updateState({
                                                            phoneNumber: e.target.value,
                                                        });
                                                    }
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'signup.phoneNumber.placeholder', defaultMessage: '' })}
                                            />
                                        </Col>
                                    </Row>
                                    <br />


                                    <Row gutter={16} className='loty'>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <span className="">
                                                <FormattedMessage id='fundraising.pledge.addDrawer.field.amount' defaultMessage='' />
                                            </span><span className="required">*</span>
                                        </Col>
                                        <Col span={12} className="input-currency-control ">
                                            <span className="currency-tag" style={{ left: '10px', position: 'absolute' }}>
                                                {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
                                            </span>
                                            <TextBox
                                                style={{ width: '80%' }}
                                                type='number'
                                                className={(props.submittedOnce && (props.amount <= 0 || !props.amount)) ? 'input-text-error' : ''}
                                                value={props.amount || null}
                                                onChange={(e) => {
                                                    props.updateState({
                                                        amount: e.target.value,
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'fundraising.pledge.addDrawer.field.amount', defaultMessage: '' })}
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || props.amount }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>
                                    </Row>

                                    <br />
                                    <Row gutter={16}>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <label>
                                                <FormattedMessage id='fundraising.pledge.addDrawer.field.paymentMode' defaultMessage='' /></label>
                                            <span className="required">*</span>
                                        </Col>
                                        <Col span={12}>
                                            <Dropdown
                                                 className={(props.submittedOnce && !props.paymentMode) ? 'input-text-error' : ''}
                                                items={props.paymentModeList}
                                                valueKeyName='paymentModeName'
                                                optionKeyName='paymentModeId'
                                                value={props.paymentModeId}
                                                placeholder={props.intl.formatMessage(
                                                    {
                                                        id: 'fundraising.pledge.addDrawer.field.paymentMode',
                                                        defaultMessage: ''
                                                    })
                                                }
                                                onSelect={
                                                    (optionValue, option) => {
                                                        props.updateState({
                                                            paymentModeId: option.value,
                                                            paymentMode: option.name,
                                                        })
                                                    }
                                                }
                                            />
                                            {/* <ErrorMsg
      validator={() => { return !props.submittedOnce || props.campaignId }}
      message={props.intl.formatMessage(
        {
          id: 'requiredField.missing.msg',
          defaultMessage: ''
        })
      } /> */}
                                        </Col>

                                    </Row>
                                    <br />

                                    {/* <Row gutter={16}>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <span className="">
                                                <FormattedMessage id='fundraising.pledge.addDrawer.field.paymentModeDetails' defaultMessage='' />
                                            </span>
                                        </Col>
                                        <Col span={12}>

                                            <TextBox
                                                type='text'
                                                value={props.paymentModeDetails}
                                                onChange={(e) => {
                                                    props.updateState({
                                                        paymentModeDetails: e.target.value,
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'fundraising.pledge.addDrawer.field.paymentModeDetails', defaultMessage: '' })}
                                            />
                                        </Col>
                                    </Row>

                                    <br /> */}

                                    <Row gutter={16}>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <label>
                                                <FormattedMessage id='fundraising.pledge.addDrawer.field.fundraisingCampaign' defaultMessage='' /></label>
                                            <span className="required">*</span>
                                        </Col>
                                        <Col span={12}>
                                            <Dropdown
                                                className={(props.submittedOnce && !props.campaignId) ? 'input-text-error' : ''}
                                                items={props.allCampaignList}
                                                valueKeyName='campaignName'
                                                optionKeyName='fundraisingCampaignId'
                                                value={props.campaignId}
                                                placeholder={props.intl.formatMessage(
                                                    {
                                                        id: 'fundraising.pledge.addDrawer.field.fundraisingCampaign',
                                                        defaultMessage: ''
                                                    })
                                                }
                                                onSelect={
                                                    (optionValue, option) => {
                                                        props.updateState({
                                                            campaignId: option.value
                                                        })
                                                    }
                                                }
                                            />
                                            <ErrorMsg
                                                validator={() => { return !props.submittedOnce || props.campaignId }}
                                                message={props.intl.formatMessage(
                                                    {
                                                        id: 'requiredField.missing.msg',
                                                        defaultMessage: ''
                                                    })
                                                } />
                                        </Col>

                                    </Row>
                                    <br />

                                    
                                    <Row gutter={16}>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={8} className="text-right">
                                            <FormattedMessage id='makeRecurring.text' defaultMessage='' />
                                        </Col>
                                        <Col span={12}>
                                            <Select onChange={(value) => {
                                                props.updateState({
                                                    period: value,
                                                })
                                            }}
                                                className={(props.submittedOnce && !props.period) ? 'input-text-error' : ''}
                                                style={{ width: '100%' }}
                                                placeholder="Please select"
                                                optionFilterProp="children"
                                                value={props.period}>
                                                <Option value="oneTimePayment"><FormattedMessage id='oneTimePayment.text' /></Option>
                                                {/* <Option value="daily"><FormattedMessage id='recurringInvoice.setup.daily' /></Option> */}
                                                <Option value="weekly"><FormattedMessage id='recurringInvoice.setup.weekly' /></Option>
                                                <Option value="monthly"><FormattedMessage id='recurringInvoice.setup.monthly' /></Option>
                                                {/* <Option value="yearly"><FormattedMessage id='recurringInvoice.setup.yearly' /></Option> */}
                                            </Select>
                                            {/* <ErrorMsg
                                        validator={() => { return !props.submittedOnce || !!props.period }}
                                        message={<FormattedMessage id='addItem.validation' values={{ value: 'This Field' }} />}
                                    /> */}
                                        </Col>
                                    </Row>
                                    
                                    <div>
                                        {(props.period && props.period !== "oneTimePayment") ? <div>

                                            <br />
                                            <Row gutter={16}>
                                                <Col span={1}> </Col>
                                                <Col span={8} className="text-right">
                                                    <Tooltip placement="top" title={props.intl.formatMessage({ id: 'paymentStartDate.helpMessage' })}>
                                                        <span className="tooltip-title">
                                                            <FormattedMessage id='paymentStartDate.text2' defaultMessage='' />
                                                        </span>
                                                    </Tooltip>
                                                </Col>
                                                <Col span={12}>
                                                    <DatePicker
                                                        allowClear={false}
                                                        format={format}
                                                        // style={{ width: 250 }}
                                                        // disabledDate={invalidEndDate}
                                                        key={moment(new Date(), format)}
                                                        defaultValue={props.paymentStartDate ? moment(new Date(props.paymentStartDate), format) : null}
                                                        onChange={(selectedDate) => {
                                                            props.updateState({
                                                                paymentStartDate: selectedDate
                                                            })
                                                        }} />
                                                </Col>
                                            </Row>
                                            <br />
                                        </div> :  <br />
                                        }

                                        {(props.period && props.period !== "oneTimePayment") ? <div>
                                            <Row gutter={16}>
                                                <Col span={1}> </Col>
                                                <Col span={8} className="text-right">
                                                    <FormattedMessage id='noOfPayment.text' defaultMessage='' />
                                                </Col>
                                                <Col span={12}>
                                                    {!props.neverExpires && <div>
                                                        <InputNumber style={{ width: '100%' }} value={props.noOfPayment} placeholder="" min={1} onChange={(value) => {
                                                            props.updateState({ noOfPayment: value })
                                                        }} /></div>
                                                    }
                                                    <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
                                                        props.updateState({
                                                            neverExpires: e.target.checked ? 1 : 0,
                                                        })
                                                    }} /> <FormattedMessage id='recurringInvoice.setup.neverExpires' defaultMessage='' />
                                                </Col>
                                            </Row>
                                            <br />
                                        </div> : ''
                                        }
                                    </div>
                                    
                                    <Row gutter={16}>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <span className="">
                                                <FormattedMessage id='remarks.text' defaultMessage='' />
                                            </span>
                                            {/* <span className="required">*</span> */}
                                        </Col>
                                        <Col span={12}>

                                            <TextArea
                                                type='text'
                                                style={{ width: '100%' }}
                                                // className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                                                value={props.remarks}
                                                onChange={(e) => {
                                                    props.updateState({
                                                        remarks: e.target.value,
                                                    });
                                                }}
                                                placeholder={props.intl.formatMessage({ id: 'remarks.placeholder', defaultMessage: '' })}
                                            />
                                        </Col>
                                    </Row>
                                    {/* <br/>
                                    <Row gutter={16}>
                                        <Col span={1}>
                                        </Col>
                                        <Col className="text-right" span={8}>
                                            <span className="">
                                                <FormattedMessage id='donateAsAnonymous.text' defaultMessage='' />
                                            </span>
                                            <span className="required">*</span>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox checked={(props.isAnonymous === 1)} onChange={(e) => {
                                                props.updateState({
                                                    isAnonymous: e.target.checked ? 1 : 0,
                                                })
                                            }}>
                                            </Checkbox>
                                        </Col>
                                    </Row> */}

                                </Form>


                                <button className='pay-but3' onClick={() => {
                                    props.updateState({
                                        submittedOnce: true,
                                        invalidEmailClass: !validateEmail(props.emailAddress) ? 'input-text-error' : '',

                                    });

                                    if (!props.firstName || !props.lastName || !props.amount || !props.paymentModeId || !props.campaignId || !props.emailAddress) {
                                        return showToasterMessage({
                                            messageType: 'error', description: props.intl.formatMessage({ id: 'common.missing.requiredField.message' })
                                        });;
                                    }
                                    if (props.emailAddress && !validateEmail(props.emailAddress)) {
                                        return showToasterMessage({
                                            messageType: 'error', description: props.intl.formatMessage({ id: 'login.email.invalidEmail' })
                                        });;
                                    }
                                    if (props.phoneNumber && props.phoneNumber.length > 0 && props.phoneNumber.length <10 ) {
                                        return showToasterMessage({
                                            messageType: 'error', description: props.intl.formatMessage({ id: 'phoneNumber.incorrect.message' })
                                        });;
                                    }
                                    let payload = {
                                        relationshipId: props.relationshipId,
                                        pledgeId: props.pledgeId,
                                        source: props.sourceId ? PAYMENT_SOURCE_BY_ID[props.sourceId || 0] : '',
                                        sourceCode: props.sourceId || 0,
                                        firstName: props.firstName,
                                        lastName: props.lastName,
                                        emailAddress: props.emailAddress,
                                        cellPhone: (props.phoneNumber && props.companyInfo.countryCallingCode) ? '+' + props.companyInfo.countryCallingCode + props.phoneNumber : props.phoneNumber,
                                        amount: props.amount,
                                        paymentModeId: props.paymentModeId,
                                        paymentModeDetails: props.paymentModeDetails,
                                        campaignId: props.campaignId,
                                        version: props.version,
                                        dateCreated: props.dateCreated,
                                        remarks: props.remarks,
                                        totalOccurrences: props.neverExpires ? 9999 : props.noOfPayment,
                                        startDate: getCurrentDateForBackend(props.paymentStartDate || new Date()),
                                        isRecurring: (props.period && props.period !== 'oneTimePayment') ? 1 : 0,
                                        isMonthly: 0,
                                        isWeekly: 0,
                                        isYearly: 0,
                                        teamMasterId: props.teamMasterId,
                                        teamMemberId: props.teamMemberId,
                                        isAnonymous: props.isAnonymous ? 1 : 0
                                    }
                                    if (props.period === 'weekly') {
                                        payload.isWeekly = 1;
                                    } else if (props.period === 'monthly') {
                                        payload.isMonthly = 1;
                                    }else if (props.period === 'yearly'){
                                        payload.isYearly = 1;
                                    }

                                    props.saveOrUpdate(payload, props)
                                }}>Submit</button>
                            </div>

                        </div>
                    </div>

                    <div style={{ clear: 'both' }}></div>
                </div>
            }
            <div style={{ clear: 'both' }}></div>
            <div className='pay-fot'>
                <Row gutter={24} className='pay-footer-box'>
                    <Col span={9}><span style={{ float: 'left', paddingTop: '5px' }}>{props.onlineThemes.footerMessage || 'All Rights Reserved - ' + props.companyInfo.storeName} </span> </Col>
                    <Col span={6} style={{ textAlign: 'center', color: '#333' }}>
                        {props.onlineThemes.facebookLink ? <i className="fa fa-facebook" style={{ margin: '0px 4px 0px 5px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.facebookLink) { return '' }
                                let url = props.onlineThemes.facebookLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank')
                            }}></i> : ''}

                        {props.onlineThemes.instagramLink ? <i className="fa fa-instagram" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.instagramLink) { return '' }
                                let url = props.onlineThemes.instagramLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.youtubeLink ? <i className="fa fa-youtube-play" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.youtubeLink) { return '' }
                                let url = props.onlineThemes.youtubeLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i>
                            : ''}
                        {props.onlineThemes.twitterLink ? <i className="fa fa-twitter" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.twitterLink) { return '' }
                                let url = props.onlineThemes.twitterLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i>
                            : ''}
                        {props.onlineThemes.link ? <i className="fa fa-link" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.link) { return '' }
                                let url = props.onlineThemes.link;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i>
                            : ''}
                    </Col>
                    <Col span={9}>

                        <span style={{ float: 'right', paddingTop: '5px' }}>Powered by <a href="http://alifapp.com/" target={'_blank'} >alifapp.com</a></span>
                    </Col>
                </Row>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div className='clearfix'></div>
        </div>
    );

}

export default injectIntl(CreatePledgeComponent);
