import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import {  Skeleton, DatePicker, Select, Input, Empty, Tooltip } from 'antd';
import { getMomentDateForUI, getCurrentDateForBackend, showToasterMessage, fixedDecimalAndFormateNumber } from '../../../../utils'
import PageBreadcrumb from '../../../PageBreadcrumb';
import { CONSTANTS, ICONS } from '../../../../static/constants';
import '../index.scss'
import { UndoOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PageHeader from "../../../Common/pageHeader";

const { Search } = Input;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const {RangePicker} = DatePicker;



const AllInventorySummaryListing = (props) => {

    const breadCrumbList = [
        {
            name: <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
        },
        {
            name: <FormattedMessage id="sidebar.menuItem.allInvValuation" defaultMessage="" />,
        }
    ];

    const {openingStocks = {}, closingStocks = {}, inwardStocks = {}, outwardStocks = {}} = props.allInventoryValuationList;

    const getFilterdList = (data, searchTerm) => {
        if(!data){return {}}
        let filtered = {};
        //const searchTerm = 'C';
         Object.entries(data).filter(([key, obj]) => { 
            if(obj.itemName.toLowerCase().includes(searchTerm.toLowerCase())){
                filtered[key]= obj;
            }
        });
         
            return filtered;
    }
    return (
        <>
            <PageHeader {...props}
                pageName="heading.inventry.allInvValuation"
                breadCrumbList={breadCrumbList} 
            />

            <div className='view-container stock-summary'>
                <div className="view-container-actions">
                    <div className="left-actions">

                    </div>

                    <div className="right-actions">
                        <Search
                            placeholder="Enter 3 characters item name"
                            value={props.searchedText}
                            onChange={(e) => {
                                props.updateState({ searchedText: e.target.value });
                            }}
                            onSearch={(searchedText) => {
                                props.updateState({ searchedText: searchedText });
                            }}
                        />

                        <RangePicker
                            style={{ width: '230px'}}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            allowEmpty={true}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        />

                        <Select
                            style={{ maxWidth: '120px'}}
                            options={[{ value: 'default', label: 'Default' }, { value: 'average-price', label: 'Average Price' }, { value: 'average-cost', label: 'Average Cost' }, { value: 'fifo', label: 'FIFO' }]}
                            value={props.selectedValuation}
                            placeholder='Select Valuation'
                            defaultValue= 'default'
                            onSelect={(value) => {
                                props.updateState({ selectedValuation: value });
                            }}
                        />

                        <button  onClick={() => {
                            if(!props.startDate && !props.endDate && !props.selectedValuation){
                                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                            }

                            const dates = {
                                start : getCurrentDateForBackend(props.startDate),
                                end : getCurrentDateForBackend(props.endDate)
                            }

                            const payload = {
                                pageNumber: props.pageNumber || 1,
                                pageSize: props.pageSize || 100,
                                filters: {
                                    startDate: dates.start,
                                    endDate: dates.end,
                                },
                                companyInfo: props.companyInfo,
                                relationshipId: props.companyInfo.relationshipId
                            };
                            props.updateState({
                                startDate: dates.start,
                                endDate: dates.end
                            })
                            props.fetchAllInventoryValuationList(payload);
                            }}>
                                <i className={`${ICONS.FILTER} mr5`} /> Apply
                        </button>
                        
                        <button onClick={() => {
                            props.updateState({
                                selectedCustomerId: null, selectedCustomerName: null,
                                startDate:null, endDate:null,
                                selectedValuation: 'default',
                                pageNumber:1 
                            });
                            const payload = {
                                pageNumber: 1, pageSize: props.pageSize,
                                filters: {}
                            };
                            props.fetchAllInventoryValuationList(payload);
                            }}>
                                <UndoOutlined className='mr5' /> Clear
                        </button>

                    </div>
                </div>
                <Skeleton loading={props.listLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    <div className='stock_reports_list'>
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th colSpan="17"> {props.companyInfo.storeName} </th>
                                </tr>
                                <tr>
                                    <th rowSpan={2} className='product'> <FormattedMessage id='inventory.invValuation.listing.header.product' defaultMessage='' /> </th>
                                    <th colSpan={3} className='opening_bal'> <FormattedMessage id='inventory.invValuation.listing.header.openingBal' defaultMessage='' /> </th>
                                    <th colSpan={3} className='incoming'> <FormattedMessage id='inventory.invValuation.listing.header.incoming' defaultMessage='' /> </th>
                                    <th colSpan={7} className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.header.outgoing' defaultMessage='' /> </th>
                                    <th colSpan={3} className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.header.closingBal' defaultMessage='' />  </th>
                                    
                                </tr>
                                <tr>
                                    {/* opening  balance */}
                                    <th className='opening_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='opening_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.rate' defaultMessage='' /> </th>
                                    <th className='opening_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>

                                    {/* Inwards */}
                                    <th className='incoming'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='incoming'> <FormattedMessage id='inventory.invValuation.listing.subHeader.rate' defaultMessage='' /> </th>
                                    <th className='incoming'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>
            
                                    {/* Outwards */}
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.rate' defaultMessage='' /> </th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.grossValue' defaultMessage='' /></th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.consumption' defaultMessage='' /> </th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.grossProfit' defaultMessage='' /> </th>
                                    <th className='outgoing'> % </th>

                                    {/* Closing Balance */}
                                    <th className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.rate' defaultMessage='' /> </th>
                                    <th className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>
                                </tr>
                            </thead>
                            <tbody>
                                { (closingStocks && Object.keys(closingStocks).length > 0) ? (Object.keys((props.searchedText && props.searchedText.length> 2) ? getFilterdList(closingStocks, props.searchedText) : closingStocks)).map((variantId, key)=>{
                                    let openingStocksRowItem = openingStocks[variantId] || {};
                                    let closingStocksRowItem = closingStocks[variantId] || {};
                                    let inwardStocksRowItem = inwardStocks[variantId] || {};
                                    let outwardStocksRowItem = outwardStocks[variantId] || {};
                                    return <tr key = {key}>
                                        <td>
                                            <Tooltip placement="right" title={`Open monthly valuation for ${closingStocks[variantId].itemName || 'product'}`} trigger="hover">
                                                <Link to = {{
                                                    pathname: '/admin/warehouse/monthly-inventory-valuation',
                                                    className: 'cursor-pointer',
                                                    state: { 
                                                        variantId: variantId || '',
                                                        startDate: props.startDate,
                                                        endDate: props.endDate,
                                                        productName: closingStocks[variantId].itemName || '',
                                                    }
                                                }}>
                                                    { closingStocksRowItem.itemName || '' }
                                                </Link>
                                            </Tooltip>
                                        </td>

                                        <td>{ openingStocksRowItem.currentStock || '' }</td>
                                        <td>{ openingStocksRowItem.rate ? fixedDecimalAndFormateNumber(openingStocksRowItem.rate) : '' }</td>
                                        <td>
                                            { openingStocksRowItem.currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost") ? fixedDecimalAndFormateNumber(Number(openingStocksRowItem.currentStock) * Number(openingStocksRowItem.rate || 0)) : '' }
                                            { openingStocksRowItem.currentStock && props.selectedValuation === "average-price" ? fixedDecimalAndFormateNumber(Number(openingStocksRowItem.currentStock || 0) * Number(outwardStocksRowItem.salePrice || 0)) : '' }
                                            { props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(openingStocksRowItem.stockValuation) : '' }
                                        </td>

                                        <td>{ inwardStocksRowItem.currentStock || '' }</td>
                                        <td>{ inwardStocksRowItem.rate ? fixedDecimalAndFormateNumber(inwardStocksRowItem.rate) : '' }</td>
                                        <td>
                                            { inwardStocksRowItem.currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost") ? fixedDecimalAndFormateNumber(Number(inwardStocksRowItem.currentStock) * Number(inwardStocksRowItem.rate || 0)) : '' }
                                            { inwardStocksRowItem.currentStock && props.selectedValuation === "average-price" ? fixedDecimalAndFormateNumber(Number(inwardStocksRowItem.currentStock || 0) * Number(outwardStocksRowItem.salePrice || 0)) : '' }
                                            { props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(inwardStocksRowItem.stockValuation) : '' }
                                        </td>

                                        <td>{ outwardStocksRowItem.currentStock || '' }</td>
                                        <td>{ fixedDecimalAndFormateNumber(outwardStocksRowItem.salePrice) || '' }</td>
                                        <td>
                                            { outwardStocksRowItem.currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost") ? fixedDecimalAndFormateNumber(Number(outwardStocksRowItem.currentStock) * Number(outwardStocksRowItem.purchasePrice || 0)) : '' }
                                            { outwardStocksRowItem.currentStock && props.selectedValuation === "average-price" ? fixedDecimalAndFormateNumber(Number(outwardStocksRowItem.currentStock || 0) * Number(outwardStocksRowItem.salePrice || 0)) : '' }
                                            { props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(outwardStocksRowItem.stockValuation) : '' }
                                        </td>

                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        <td>{ closingStocksRowItem.currentStock || '' }</td>
                                        <td>{ closingStocksRowItem.rate ? fixedDecimalAndFormateNumber(closingStocksRowItem.rate) : '' }</td>
                                        <td>
                                            { closingStocksRowItem.currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost") ? fixedDecimalAndFormateNumber(Number(closingStocksRowItem.currentStock) * Number(closingStocksRowItem.rate || 0)) : '' }
                                            { closingStocksRowItem.currentStock && props.selectedValuation === "average-price" ? fixedDecimalAndFormateNumber(Number(closingStocksRowItem.currentStock || 0) * Number(outwardStocksRowItem.salePrice || 0)) : '' }
                                            { props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(closingStocksRowItem.stockValuation) : '' }
                                        </td>
                                    </tr>
                                }) 
                                : 
                                <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>

                </Skeleton>
            </div>
        </>
    );
};

export default injectIntl(AllInventorySummaryListing);
