import React, { Component, Fragment } from "react";
import '@ant-design/compatible/assets/index.css';
import { Row, Col, DatePicker, Tooltip, Skeleton } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Dropdown } from "../../../../general/Dropdown";
import * as find from 'lodash.find';
import moment from 'moment-timezone';
import { CONSTANTS, ICONS, MODAL_TYPE } from '../../../../../static/constants';
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
import { TextBox } from "../../../../general/TextBox";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
class RFQDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            size: 'default',
            billingAddress: this.defaultBillingAddress,
        };
    }

    handleSizeChange = e => {
        this.setState({ size: e.target.value });
    };

    handleNewAddressAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        if (modalType === MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS) {
            payload.formData = { addressType:'relationship', locationType: 'RelationshipBillingAddress' };
        }
        payload.formData.callback = function (address) {
            props.hideModal(data);
            if (address.locationType === 'BillingAddress' || address.locationType === 'ShippingAddress') {
              props.fetchAddresses({
                customerId: address.customerId,
                relationshipId: (props.companyInfo || {}).relationshipId,
                locationType: address.locationType
              });
            } else {
              props.getRelationshipAddress({
                relationshipId: (props.companyInfo || {}).relationshipId
              });
            }
          }
        const data = {
            title: this.getModalTitle(payload, modalType),
            formData,
            hideFooter: true,
            modalData: {
                modalType: modalType,
                data: payload,
            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    handleNewProjectAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.PROJECT,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.DEPARTMENT: {
                addFunc = props.addDepartment;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId })
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.department' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                title = <div>
                    <FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />
                    <h6>{(this.props.companyInfo || {}).storeName} </h6>
                </div>
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.DEPARTMENT: {
                deleteFunc = props.deleteDepartment;
                break;
            }
            case MODAL_TYPE.PROJECT: {
                deleteFunc = props.deleteProject;
                break;
            }
            case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS: {
                deleteFunc = props.deleteAddress;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId, callback:()=>{
                props.getRelationshipAddress({
                    relationshipId: (props.companyInfo || {}).relationshipId
                  });
            } }) },
        };
        showModal(modalData);
    }

    render() {

        const { size } = this.state;
        const rowNode = this.gridApi && this.gridApi.getDisplayedRowAtIndex(0);
        if (rowNode) {
            rowNode.setDataValue("project", this.props.projectName || '');
            rowNode.setDataValue("priority", this.props.orderPriority || '');
            this.gridApi.sizeColumnsToFit();
        }

       // const columnDefs = [
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='supplier.rfq.form.gridHeader.requestingDepartment' defaultMessage='' />;
            //     },
            //     field: 'department',
            //     editable: false,
            //     sortable: true,
            //     cellEditor: 'customDropDownEditor',
            //     cellEditorParams: (obj) => {
            //         return {
            //             lastColumnCell: false,
            //             items: this.props.departments || [],
            //             optionKeyName: 'departmentId',
            //             valueKeyName: 'departmentName',
            //             value: this.props.departmentName,
            //             canAddNew: true,
            //             canDelete: true,
            //             allowClear: true,
            //             onClear: () => {
            //                 //obj.node.setDataValue(obj.colDef.field, undefined);
            //                 this.props.updateState({
            //                     departmentName: undefined
            //                 })
            //             },
            //             deleteClickHandler: (payload) => {
            //                 this.deleteClickHandler(this.props, MODAL_TYPE.DEPARTMENT, payload);
            //             },
            //             onAddNewClick: (payload) => {
            //                 payload.formData = {
            //                     textBoxValue: payload.textEntered,
            //                     submittedOnce: false
            //                 }
            //                 this.handleNewItemAddition(this.props, payload, MODAL_TYPE.DEPARTMENT)
            //             },
            //             onSelect: (selectedValue) => {
            //                 //obj.node.setDataValue(obj.colDef.field, selectedValue);
            //                 this.props.updateState({
            //                     departmentName: selectedValue
            //                 })
            //             },
            //         }
            //     }
            // },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='supplier.rfq.form.gridHeader.purchaseType' defaultMessage='' />;
            //     },
            //     field: 'ref',
            //     valueGetter: () => { return "Purchase Order" },
            // },
        //      {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='supplier.rfq.form.gridHeader.project' defaultMessage='' />;
        //         },
        //         field: 'project',
        //         editable: false,
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: this.props.projectList || [],
        //                 optionKeyName: 'projectMasterId',
        //                 valueKeyName: 'projectName',
        //                 value: this.props.projectName,
        //                 canAddNew: true,
        //                 canDelete: true,
        //                 deleteClickHandler: (payload) => {
        //                     this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
        //                 },
        //                 onAddNewClick: (payload) => {
        //                     payload.formData = {
        //                         projectName: payload.projectName,
        //                     }
        //                     this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
        //                 },
        //                 onSelect: (selectedValue, option) => {
        //                     const selectedProject = find(this.props.projectList, { projectMasterId: Number(option.key || 0) }) || {};
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue);
        //                     this.props.updateState({
        //                         projectNumber: selectedProject.projectNumber,
        //                         projectName: selectedProject.projectName,
        //                         projectMasterId: selectedProject.projectMasterId
        //                     })
        //                 },
        //             }
        //         }
        //     }, {
        //         headerComponentFramework: () => {
        //             return <FormattedMessage id='supplier.rfq.form.gridHeader.priority' defaultMessage='' />;
        //         },
        //         field: 'priority',
        //         editable: false,
        //         cellEditor: 'customDropDownEditor',
        //         cellEditorParams: (obj) => {
        //             return {
        //                 lastColumnCell: false,
        //                 items: CONSTANTS.PRIORITIES,
        //                 value: this.props.orderPriority,
        //                 onSelect: (selectedValue) => {
        //                     this.props.updateState({
        //                         orderPriority: selectedValue
        //                     })
        //                     //obj.node.setDataValue(obj.colDef.field, selectedValue);
        //                 },
        //             }
        //         }
        //     },

        // ]

        // const onGridReady = (params) => {
        //     this.gridApi = params.api;
        //     this.gridApi.sizeColumnsToFit()
        //     this.gridColumnApi = params.columnApi;
        // }

        return (
            <>
                <Row>
                    <Col span={5}>
                        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>
                            <Row gutter={[0, 3]}>
                                <Col span={24} className="details-heading">
                                    Organization Information
                                </Col>
                                <Col span={24}>
                                    <div className="details-heading-subheading">
                                        <FormattedMessage
                                            id="sales.order.create.organization.name"
                                            defaultMessage=""
                                        />
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="deatils-field-value">
                                        {this.props.companyInfo?.storeName || '-'}
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="details-heading-subheading">
                                        <FormattedMessage
                                            id="sales.order.create.organization.adress"
                                            defaultMessage=""
                                        />
                                        <Tooltip
                                            placement="right"
                                            title={this.props.intl.formatMessage({
                                                id: "tooltip.companyBillingAddress",
                                                defaultMessage: "",
                                            })}
                                            trigger="click"
                                        >
                                            <i className={`${ICONS.HELP_P} ml5`} />
                                        </Tooltip>
                                    </div>
                                </Col>
                                <Col span={24}>
                                <span>
                                    {!this.props.addressHide && this.props.relationshipAddress ? 
                                        <div className="detail-address-value">
                                            <div style={{ height: "96px", paddingTop: "10px" }}>
                                                {getAddressInfoFragment(
                                                    this.props.relationshipAddress,
                                                    this.props.companyInfo,
                                                    {}
                                                )}
                                            </div>
                                            <div onClick={() => {
                                                this.props.updateState({
                                                    addressHide: true,
                                                });
                                            }}
                                            >
                                                <i className={ICONS.EDIT_P}/>
                                            </div>
                                        </div>
                                        : 
                                        <Dropdown
                                            value={(this.props.relationshipAddress || {}).streetAddress1}
                                            items={this.props.allRelationshipBillingAddress || []}
                                            valueKeyName='streetAddress1'
                                            addressLineKey1='streetAddress2'
                                            addressLineKey2='cityName'
                                            addressLineKey3='stateName'
                                            addressLineKey4='zipCode'
                                            optionClassAddress='custom-address-dropdown'
                                            optionKeyName='locationId'
                                            isFavouriteKeyName= 'isDefault'
                                            canAddNew={true}
                                            canDelete={true}
                                            deleteClickHandler={(payload) => {
                                                this.deleteClickHandler(this.props, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS, payload);
                                            }}
                                            onAddNewClick={(payload) => {
                                                payload.formData = {
                                                    locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS
                                                }
                                                this.handleNewAddressAddition(this.props, payload, MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS)
                                            }}
                                            onSelect={(locationId) => {
                                                this.props.updateState({
                                                    relationshipAddress: this.props.allRelationshipBillingAddress.find(x => x.locationId === locationId),
                                                    addressHide: false,
                                                })
                                            }}
                                            allowClear={true}
                                            onClear={() => {
                                                this.props.updateState({
                                                    relationshipAddress: undefined,
                                                })
                                            }}
                                        />
                                    }
                                    </span>
                                </Col>
                                {/* <Tooltip placement="right" title="This is your company billing address. Additional addresses can be managed from Setting > Company Information > Address Page" trigger="click">
                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                </Tooltip>
                                {
                                    getAddressInfoFragment(this.props.relationshipAddress, this.props.companyInfo)
                                } */}
                                
                            </Row>
                        </Skeleton>
                    </Col>

                    {/* <Col span={7}>
                        <Form.Item>
                        <div>
                            <div className="year">
                                {getContactNameFragment(((this.props.rfqData || {}).additionalInfoList ? (this.props.rfqData || {}).additionalInfoList[0] || {} : {}))}
                                {(this.props.rfqData || {}).customerName}
                            </div>
                            <div>
                                {getAddressInfoFragment(this.props.customerBillingAddress, ((this.props.rfqData || {}).additionalInfoList ? (this.props.rfqData || {}).additionalInfoList[0] || {} : {}))}
                            </div>
                        </div>
                        </Form.Item>
                        {
                            Object.keys(customerBillingAddress).length
                                ? <div className="details-from-dropdown mt35">

                                    <Dropdown
                                        style={{ width: '70%' }}
                                        value={(this.props.customerBillingAddress || {}).streetAddress1}
                                        items={this.props.allBillingAddresses}
                                        valueKeyName='streetAddress1'
                                        addressLineKey1='streetAddress2'
                                        addressLineKey2='cityName'
                                        addressLineKey3='stateName'
                                        addressLineKey4='zipCode'
                                        optionClassAddress='custom-address-dropdown'
                                        optionKeyName='locationId'
                                        onSelect={(locationId) => {
                                            this.setState({
                                                customerBillingAddress: this.props.supplierAddresses.find(x => +x.locationId === +locationId) || {}
                                            })
                                        }}
                                    />
                                    <Tooltip placement="right" title="Customer Address" trigger="click">
                                        <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                    </Tooltip>
                                    {
                                        getAddressInfoFragment(customerBillingAddress, {})
                                    }
                                </div>
                                : ''
                        }
                    </Col> */}

                    <Col span={5} offset={14}>
                        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 5 }}>
                            <Row gutter={[0,3]}>
                                <Col  span={24} className='details-heading'>
                                    RFQ Information
                                </Col>

                                <Col span={24} className="title">
                                    <FormattedMessage id='common.RFQ.Number' defaultMessage='' />
                                </Col>
                                <Col span={24}>
                                    {this.props.rfqNumber}
                                </Col>

                                <Col span={24}>
                                    <FormattedMessage id='customer.salesQuote.form.rfqDate.label' defaultMessage='' />
                                </Col>
                                <Col span={24}>
                                    <DatePicker
                                        allowClear={false}
                                        disabled={true}
                                        format={format}
                                        key={`${this.props.rfqDate ? moment(new Date(this.props.rfqDate), format) : moment(new Date(), format)}`}
                                        defaultValue={this.props.rfqDate ? moment(new Date(this.props.rfqDate), format) : moment(new Date(), format)}
                                        onChange={(selectedDate) => {
                                            this.props.updateState({
                                                rfqDate: selectedDate
                                            })
                                        }}
                                        size={size}
                                    />
                                </Col>

                                <Col span={24}>
                                    <FormattedMessage id='supplier.rfq.biddingSuppliers.rfqDetail.bidClosingDate' defaultMessage='' />
                                </Col>
                                <Col span={24}>
                                    <DatePicker
                                        allowClear={false}
                                        disabled={true}
                                        format={format}
                                        key={`${this.props.expirationDate ? moment(new Date(this.props.expirationDate), format) : moment(new Date(), format)}`}
                                        defaultValue={this.props.expirationDate ? moment(new Date(this.props.expirationDate), format) : moment(new Date(), format)}
                                        onChange={(selectedDate) => {
                                            this.props.updateState({
                                                expirationDate: selectedDate
                                            })
                                        }}
                                        size={size}
                                    />
                                </Col>
                            </Row>
                        </Skeleton>
                    </Col>
                </Row >

                <Row>
                    <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 2 }}>
                        <Col span={24}>
                            <div className='txn-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th width="10%"><FormattedMessage id='supplier.rfq.form.gridHeader.refNumber' defaultMessage='' /></th>
                                            {this.props.refNumber ?<th width="10%"><FormattedMessage id='supplier.rfq.form.gridHeader.salesPerson' defaultMessage='' /></th> : ''}
                                            {this.props.salesPersonFullName ? <th width="10%"><FormattedMessage id='supplier.rfq.form.gridHeader.priority' defaultMessage='' /></th> : ''}
                                            <th width="10%"><FormattedMessage id='supplier.rfq.form.gridHeader.project' defaultMessage='' /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {this.props.refNumber ? 
                                                <td>
                                                    <TextBox
                                                        value={this.props.refNumber  || ''}
                                                        placeholder="Ref #"
                                                        disabled={true}  
                                                    />
                                                </td>
                                                :
                                                ''
                                            }

                                            {this.props.salesPersonFullName ? 
                                                <td>
                                                    <TextBox
                                                        value={this.props.salesPersonFullName || ''}
                                                        placeholder="Sales Person"
                                                        disabled={true}  
                                                    />
                                                </td>
                                                :
                                                ''
                                            }
                                            <td>
                                                <Dropdown
                                                    items={CONSTANTS.PRIORITIES}
                                                    value={this.props.orderPriority}
                                                    onSelect={(selectedValue) => {
                                                        this.props.updateState({
                                                            orderPriority: selectedValue
                                                        })
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <Dropdown
                                                    items={this.props.projectList || []}
                                                    optionKeyName='projectMasterId'
                                                    valueKeyName='projectName'
                                                    value={this.props.projectName}
                                                    projectIdKey='projectMasterId'
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    onSelect={(key, option) => {
                                                        const selectedProject = find(this.props.projectList, { projectMasterId: Number(option.key || 0) }) || {};
                                                        this.props.updateState({
                                                            projectNumber: selectedProject.projectNumber,
                                                            projectName: selectedProject.projectName,
                                                            projectMasterId: selectedProject.projectMasterId
                                                        })
                                                    }}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.PROJECT, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            projectName: payload.projectName,
                                                        }
                                                        this.handleNewProjectAddition(this.props, payload, MODAL_TYPE.PROJECT)
                                                    }}
                                                />  
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Skeleton>
                </Row>

                {/* <div>
                    <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        rowData={[{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100px'
                        }} />
                </div> */}

            </>
        );
    }
}

const getAddressInfoFragment = (obj, companyObj) => {
    if (!obj || !Object.keys(obj).length) {
        return '';
    }

    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <div className='billing-address'>{obj.streetAddress1}
                </div>
                : ''
        }
        {
            obj.streetAddress2
                ? <div className='billing-address'>{obj.streetAddress2}
                </div>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName}, ${obj.stateName},${obj.zipCode}`
                    }
                </div>
                : ''
        }
        {/* {
            companyObj.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.rfq.form.gstnIn.label' defaultMessage='' /> - {companyObj.gstNumber}
                </div> :
                ''
        }
        {
            companyObj.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='supplier.rfq.form.pan.label' defaultMessage='' /> - {companyObj.panNumber}
                </div> :
                '' */}
    </Fragment>

    return fragmentObj;
}

// const getContactNameFragment = (additionInfo) => {
//     if (!additionInfo) return <Fragment />;
//     let contactName = "";
//     if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
//     if (additionInfo.firstName) contactName += additionInfo.firstName;
//     if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
//     if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
//     const fragmentObj = <Fragment>
//         {
//             (contactName && contactName.length)
//                 ? <div>
//                     {contactName}
//                 </div> :
//                 ''
//         }
//     </Fragment>
//     return fragmentObj;
// }

export default injectIntl(RFQDetails);
