import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesEInvoice from '../../../components/drawer/SalesEInvoice';
import { generateEInvoiceDocument } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchTaxIdentifications } from '../../../actions/commonActions';
import { getStateCode } from '../../../components/general/stateCode';
import find from 'lodash.find';
import filter from 'lodash.filter';
import { CONSTANTS } from '../../../static/constants';
import { fetchDataIfNeeded, fixedDecimalNumber } from '../../../utils';
import { fetchRelationshipTaxIdentifications ,addRelationshipTaxIdentification } from '../../../actions/commonActions';
class SalesEInvoiceContainer extends Component {

  constructor(props){
    const { salesInvoiceDetail ,allRelationshipTaxIdentificatins } = props;
    const buyerInfo = salesInvoiceDetail?.boLocationSalesInvoiceList[1]||[];
    const supplierInfo = salesInvoiceDetail?.boLocationSalesInvoiceList[0]||[];
    const relationshipAddress = find(salesInvoiceDetail.boLocationSalesInvoiceList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    let isPlaceOfSupplySameAsRelationship = false
    isPlaceOfSupplySameAsRelationship = (salesInvoiceDetail.placeOfSupply && relationshipAddress.stateName === salesInvoiceDetail.placeOfSupply) || false;
    const  SuppliertaxIdentification = JSON.parse( salesInvoiceDetail?.additionalInfoList[0]?.relationshipTaxIdentifications || '[]');
    const buyertaxIdentification  = JSON.parse(salesInvoiceDetail?.additionalInfoList[0]?.taxIdentifications || '[]')
    const supplierGstinNo = SuppliertaxIdentification?.find(item=> item?.taxIdentificationType === 'GSTIN')
    const buyerGstinNo = buyertaxIdentification?.find(item=> item?.taxIdentificationType === 'GSTIN')
   const userGst = allRelationshipTaxIdentificatins?.filter((item )=> item?.taxIdentificationType === "GSTIN")
   const buyerGst = props.customerDetails?.customerTaxIdentificationsList?.filter((item )=> item?.taxIdentificationType === "GSTIN")
    // const openEInvoiceDetails = (data) => {

    //   const obj = {...props , einvoiceData:data}
    //   const invoicePayload = {
    //     customerId: 1671,
    //     invoiceMasterId: data.salesInvoiceMasterId,
    //   };
    //   props.pushModalToStack({
    //     modalBody: (
    //       <EInvoiceDetail {...props}  invoicePayload={invoicePayload} />
    //     ),
    //     width: "100%",
    //     hideFooter: true,
    //     keyboard: true,
    //     wrapClassName: "modal-custom-detail",
    //   });
    // };
    const getDiscountAmount = (row, toReturn) => {
      if (!row || !row.discountAmount) return;
      if (row?.discountAmount) {
        return  toReturn === "amount"? row?.discountAmount :  "(" + row?.discountPercentage + "%)"
        }
    };
    const getTxTypeData = (row, type, toReturn) => {
      if (!row || !row.customerInvoiceCOATxList) return;
      let data = row.customerInvoiceCOATxList.find(tx => (tx.txType === type));
      if (data) {
          if (data[toReturn] && toReturn === 'amountPercent') {
              if (type === 'VAT') {
                  return "VAT(" + data[toReturn] + "%)";
              }
              return "(" + data[toReturn] + "%)";
          } else if (data[toReturn] && toReturn === 'amount') {
              return fixedDecimalNumber(data[toReturn]);
          } else {
              return ""
          }
    
      } else if (!data && toReturn === 'amount') {
          return "";
      }
      else {
          return "-";
      }
    }
    const getTotalTaxAmount = (row, isPlaceOfSupplySameAsRelationship) => {
      let tax = getTxTypeData(row, 'tax', 'amount', true) || 0;
      let totalTax = Number(tax);
      if (isPlaceOfSupplySameAsRelationship) {
    
          return totalTax * 2;
      } else {
          return totalTax
      }
    
    }
    const getNetAmount = (row,isPlaceOfSupplySameAsRelationship,withoutTax) => {
      let tax = getTotalTaxAmount(row) || 0;
      let taxValue = isPlaceOfSupplySameAsRelationship ? tax*2:tax
      let discount = getDiscountAmount(row,'amount') || 0;
      let netAmountItemwithoutTax = (Number(row.quantityToInvoice * row.anItemSalePrice) - Number(discount));
    
      let netAmountItem = (Number(row.quantityToInvoice * row.anItemSalePrice)+Number(taxValue) - Number(discount));
      if(withoutTax){
        return fixedDecimalNumber(netAmountItemwithoutTax)
      }else{
          return fixedDecimalNumber(netAmountItem);
      }
    
    }
    
    
         const getTotalAmount = (list ,type) =>{
              const val = (list||[]).reduce((sum ,item)=>{
                if(type === 'totalIGST'){
                  return sum + item?.iamt;
                }
                
                else if (type === 'totalTaxValue'){
                   return sum + item?.txval;
                }
                else if(type === 'totalCGST'){
                  return sum + item?.camt;
                }
                else if(type === 'totalSGST'){
                  return sum + item?.samt;
                }
                else{
                  return 0;
                }
               
              },0)
              return val;
         }
    const calculateTaxRateList = (rowdata) => {
      let subtotal = 0;
      let totalDiscount = 0;
      let totalTax = 0;
      let itemLevelTaxes = []
  
      
          let totalAmountOfColumn = 0;
          if (rowdata.quantityToInvoice && rowdata.anItemSalePrice) {
              let taxObj = {
                  subtotal: 0,
                  taxPercent: 0,
                  taxAmount: 0,
  
              }
              totalAmountOfColumn = rowdata.quantityToInvoice * rowdata.anItemSalePrice;
              let discount = getDiscountAmount(rowdata, "amount");
              if (discount) {
                  totalAmountOfColumn = totalAmountOfColumn - discount;
                  totalDiscount = totalDiscount + discount;
              }
  
              let tax1 = rowdata.customerInvoiceCOATxList.find(tx => (tx.txType === 'tax')) || {};
              taxObj.taxPercent = tax1.amountPercent;
              taxObj.taxAmount = tax1.amount;
              taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
              totalTax = totalTax + tax1.amount;
              taxObj.taxName = tax1.taxName;
          
              if (taxObj.taxPercent) {
                  taxObj.subtotal = Number(fixedDecimalNumber(totalAmountOfColumn));
                  const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                
                    if (availableTaxObj ) {
                      itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                          if (ele.taxPercent === taxObj.taxPercent) {
                              ele.subtotal = ele.subtotal + taxObj.subtotal;
                              ele.taxAmount = ele.taxAmount + taxObj.taxAmount;
                          }
                          return true;
                      });
                  } else {
                    taxObj.subtotal = Number(fixedDecimalNumber(taxObj.subtotal || 0))
                      itemLevelTaxes.push(taxObj);
                  }
              }
              subtotal = subtotal + totalAmountOfColumn;
          }
      return itemLevelTaxes;
  }

  const unitCodeVal = (val)=>{
    const unitCode ={
      	Bags: "BAG",
	Bale: "BAL",
	Bundles: "BDL",
	Buckles: "BKL",
	"Billions of Units": "BOU",
	Box: "BOX",
	Bottles: "BTL",
	Bunches: "BUN",
	Cans: "CAN",
	"Cubic Metres": "CBM",
	"Cubic Centimetres": "CCM",
	Centimetres: "CMS",
	Cartons: "CTN",
	Dozens: "DOZ",
	Drums: "DRM",
	"Great Gross": "GGK",
	Grams: "GMS",
	Gross: "GRS",
	"Gross Yards": "GYD",
	Kilograms: "KGS",
	Kilolitre: "KLR",
	Kilometre: "KME",
	Litre: "LTR",
	Metre: "MTR",
	Millilitre: "MLT",
	"Metric Ton": "MTS",
	Numbers: "NOS",
	Others: "OTH",
	Packs: "PAC",
	Pieces: "PCS",
	Pairs: "PRS",
	Quintal: "QTL",
	Rolls: "ROL",
	Sets: "SET",
	"Square Feet": "SQF",
	"Square Metre": "SQM",
	"Square Yards": "SQY",
	Tablets: "TBS",
	"Ten Gross": "TGM",
	Thousands: "THD",
	Tonnes: "TON",
	Tubes: "TUB",
	"US Gallons": "UGS",
	Unit: "UNT",
	Yards: "YDS",

    }

    return unitCode[val];
  }
    const list = (salesInvoiceDetail?.customerInvoiceDetailsList || [])?.map((item ,i)=>{
        const itemLevelTaxes =  calculateTaxRateList(item);
       const itemlist =  {
        "barcde": null,
        "bchExpDt": null,
        "bchWrDt": null,
        "bchnm": null,
        "camt": (isPlaceOfSupplySameAsRelationship && itemLevelTaxes[0]?.taxName === "CGST" ) ? itemLevelTaxes[0]?.taxAmount : 0,
        "cesNonAdval": 0,
        "stCesNonAdvl": 0,
        "crt": (isPlaceOfSupplySameAsRelationship && itemLevelTaxes[0]?.taxName === "CGST" ) ? itemLevelTaxes[0]?.taxPercent : 0,
        "csamt": 0,
        "csrt": 0,
        "disc": item?.discountAmount,
        "freeQty": 0,
        "hsnCd": item?.hsnCode,
        "iamt": (!isPlaceOfSupplySameAsRelationship && itemLevelTaxes[0]?.taxName === "IGST" ) ? itemLevelTaxes[0]?.taxAmount : 0,
        "irt": (!isPlaceOfSupplySameAsRelationship && itemLevelTaxes[0]?.taxName === "IGST" ) ? itemLevelTaxes[0]?.taxPercent : 0,
        "isServc": null,
        "itmgen1": null,
        "itmgen2": null,
        "itmgen3": null,
        "itmgen4": null,
        "itmgen5": null,
        "itmgen6": null,
        "itmgen7": null,
        "itmgen8": null,
        "itmgen9": null,
        "itmgen10": null,
        "itmVal": Number(getNetAmount(item ,isPlaceOfSupplySameAsRelationship)),
        "num": `000${i+1}`,
        "ordLineRef": null,
        "orgCntry": null,
        "othchrg": 0,
        "prdDesc": null,
        "prdNm": item?.itemName,
        "prdSlNo": null,
        "preTaxVal": 0,
        "qty": item?.quantityToInvoice,
        "rt": item?.ratePercent,
        "samt": (isPlaceOfSupplySameAsRelationship && itemLevelTaxes[0]?.taxName === "CGST" ) ? itemLevelTaxes[0]?.taxAmount : 0,
        "srt": (isPlaceOfSupplySameAsRelationship && itemLevelTaxes[0]?.taxName === "CGST" ) ? itemLevelTaxes[0]?.taxPercent : 0,
        "stcsamt": 0,
        "stcsrt": 0,
        "sval": (item?.quantityToInvoice * item?.basePrice),
        "txp": null,
        "txval": Number(getNetAmount(item ,isPlaceOfSupplySameAsRelationship ,true)),
        "unit": unitCodeVal(item?.uomName)||"OTH",
        "unitPrice": item?.basePrice,
         "invItmOtherDtls": [
          {
            "attNm": "aaa",
            "attVal": "aaa"
          },
          {
            "attNm": "xyz",
            "attVal": "2300"
          }
        ]
      }
     
      return itemlist;
    })
   
    super(props);
    this.state = {
      
      activeTabKey: '1',
      submittedOnce: false,
      additionalFields: false,
      optionalFields: false,
      userGstVal: userGst,
      buyerGstVal: buyerGst,
      userGstin:supplierGstinNo?.taxIdentificationType ?  supplierGstinNo:undefined,

      isSameSateCode: isPlaceOfSupplySameAsRelationship,
      supplyType: 'Outward',
      transactionNature: 'Intra'|| undefined,
      documentType:{
        docType: "Regular Invoice (Tax Invoice)",
        referCode: "RI"
      }|| undefined,
      transactionMode:  { trnMode: "Regular", referCode: "REG" },
      documentNumber: salesInvoiceDetail ? `Al/${salesInvoiceDetail?.invoiceNumber}/2024` : undefined,
      documentDate: new Date() || undefined,
      placeOfBusiness: undefined,
      // relationshipAddress ? getStateCode(relationshipAddress?.stateName) : undefined,
      invoiceType:{
        invType: "Regular B2B Invoices of registered counterparty",
        referCode: "B2B",
      },
      placeOfSupply: salesInvoiceDetail? getStateCode(salesInvoiceDetail?.placeOfSupply) : undefined,
      documentStatus: {docStatus: "Original" , referCode:"O"},
      referenceInvoiceNo: undefined,
      referenceInvoiceDate: undefined,
      IGSTApplicability_recipient: "No"|| undefined,

      reverseCharge:"No",
      differentialPer: undefined,
      EcommGSTIN:undefined,

      supplierGstin: supplierGstinNo?.taxIdentificationType ?  supplierGstinNo:undefined,
      supplierName: props?.companyInfo ? props.companyInfo?.storeName : undefined,
      supplierAddress: salesInvoiceDetail ? supplierInfo?.streetAddress1 : undefined,
      supplierLocation: salesInvoiceDetail ? supplierInfo?.stateName : undefined,
      supplierStateCode:  undefined,
      supplierPincode: salesInvoiceDetail ? supplierInfo?.zipCode : undefined,
      supplierTradeName: undefined,
      supplierAddress2: undefined,
      supplierDistrict: undefined,
      supplierPhone: undefined,
      supplierEmail: undefined,




      buyerGSTIN: buyerGstinNo?.taxIdentificationType ?  buyerGstinNo : undefined,
      buyerName: salesInvoiceDetail ? salesInvoiceDetail?.customerName : undefined,
      buyerAddress: salesInvoiceDetail ? buyerInfo?.streetAddress1 : undefined,
      buyerLocation: salesInvoiceDetail ?  buyerInfo?.stateName : undefined,
      buyerStateCode: undefined,
      buyerPincode: salesInvoiceDetail ? buyerInfo?.zipCode : undefined,
      buyerTradeName: undefined,
      buyerAddress2: undefined,
      buyerDistrict: undefined,
      buyerPhone: undefined,
      buyerEmail: undefined,

      finalInvoiceValue: salesInvoiceDetail ? salesInvoiceDetail?.invoiceTotalAmount : 0,
      totalTaxableValue:  getTotalAmount(list , 'totalTaxValue') || 0,
     
      totalIGST:  getTotalAmount(list , 'totalIGST') || 0,
      totalCGST: getTotalAmount(list , "totalCGST") || 0,
      totalSGST:getTotalAmount(list , "totalSGST") || 0,
      discountInvoiceValue: undefined,
      freight: undefined,
      insurance: undefined,
      pacakaging: undefined,
      otherCharges: undefined,
      totalCESS: undefined,
      totalStateCESS: undefined,
      roundOffAmount: undefined,
      TCSRate: undefined,
      TCSValue: undefined,
      InvoiceValueBeforeTCS: undefined,


      serialNumber: undefined,
      hsnCode: undefined,
      unitPrice: undefined,
      totalAmount: undefined,
      taxableValue: undefined,
      totalItemValue: undefined,
      taxScheme: "GST"||undefined,
      productName: undefined,
      productDescription: undefined,
      barCode: undefined,
      freeQuantity: undefined,
      unit: undefined,
      discountAmount: undefined,
      otherCharges2: undefined,
      rate: undefined,
      IGSTAmount: undefined,
      CGSTAmount: undefined,
      SGSTAmount: undefined,
      CESSRate: undefined,
      CESSAmount: undefined,
      stateCESSRate: undefined,
      stateCESSAmount: undefined,
      advolAmount: undefined,
      nonAdvolAmount: undefined,
      stateNonAdvolAmount: undefined,
      taxApplicable: undefined,
      batchNumber: undefined,
      expiryDate: undefined,
      warrantyDate: undefined,
      supplyService: undefined,
      preTaxValue: undefined,
      orderLineRefrence: undefined,
      originCountry: undefined,
      serialNumber2: undefined,
      cessNonAdvolRate: undefined,
      totalValue: undefined,

     
      dispatchTradeName: undefined,
      dispatchAddress: undefined,
      dispatchLocation: undefined,
      dispatchStateCode: undefined,
      dispatchPincode:undefined,
      dispatchGSTIN: undefined,
      dispatchLegalName: undefined,
      dispatchAddress2: undefined,
      dispatchDistrict: undefined,
      dispatchPhoneNo: undefined,
      dispatchEmailId: undefined,

      quantity:undefined,
      IGSTRate:undefined,
      CGSTRate:undefined,
      SGSTRate:undefined,


      ShipLegalName:undefined,
      ShipAddress:undefined,
      shipLocation:undefined,
      shipStateCode:undefined,
      shipPincode:undefined,
      shipTradeName: undefined,
      shipAddress2: undefined,
      shipDistrict: undefined,
      shipPhoneNo: undefined,
      shipEmailId: undefined,

      transporterID:undefined,
      subTypeOfSupply:"Supply"||undefined,
      otherSupplyDesc:undefined,
      modeOfTransportation:undefined,
      vechileType: undefined,
      transportationDistance: undefined,
      transporterDocumentNo: undefined,
      transporterDocumentDate: undefined,
      VechileNo: undefined,
      referenceInvoiceNo2: undefined,
      referenceInvoiceDate2: undefined,
      transporterName: undefined,

      

      shippingBillNo: undefined,
      shippingBillDate: undefined,
      portCode: undefined,
      country: undefined,
      foreignCurrency: undefined,
      totalInvoiceValue: undefined,
      supplierrefund: undefined,
      exportDuty: undefined,

      payeeName: undefined,
      bankAccount: undefined,
      modeofPayemnt: undefined,
      ifscCode: undefined,
      paymentTerms: undefined,
      paymentInstruction:  undefined,
      creditTransfer: undefined,
      directDebit: undefined,
      creditDays: undefined,
      balanceAmount: undefined,
      paidAmount: undefined,
      dueDatePayment: undefined,
      upiId: undefined,
      merchantCode: undefined,

      documentURL: undefined,
      supportingDocs: undefined,
      additionalInfo: undefined,

      originalMonth: undefined,
      originalDocumentType: undefined,
      originalInvoiceType:  {
        invType: "Regular B2B Invoices of registered counterparty",
        referCode: "B2B",
      }||undefined,
      originalUINNumber: undefined,
      autoPopulatedRefunds: undefined,
      dtaOnBOE: undefined,


       generateIRN: true|| undefined,
       fiscalYear: undefined,
       internalReferenceNumber: undefined,
       postingDate: undefined,
       invoiceStatus: undefined,
       counterPartyCode: undefined,
       areaCode: undefined,
       plantCode: undefined,
       projectRefrence: undefined,
       departmentCode: undefined,
       divisionCode: undefined,
       costCenter: undefined,
       branchCode: undefined,
       userName: undefined,
       transactionCategory: undefined,
       glCode: undefined,
       generateEWB: {generateEWB:"No" , referCode:"N"},
       topazEWB: undefined,
       filingSapphire: undefined,
       templateName: undefined,
        itemList: list.length ? list : undefined,


      

    }
  }
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
   const payload = {
    relationshipId: companyInfo?.relationshipId,
   }
   
  //  fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
  }



  render() {
    return <div>
      <SalesEInvoice 
    //   initCalls={() => {
    //     this.initCalls();
    //   }} 
      {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    </div>
  }

}

const mapStateToProps = (state) => {
    return {
      companyInfo: state.common.companyInfo,
      userInfo: state.common.userInfo,
      userStatus: state.common.userStatus,
     
     
    }
  }
  
  const mapDispatchToProps = (dispatch) => bindActionCreators({
   
    pushModalToStack,
    popModalFromStack,
    showModal,
    hideModal,
    addRelationshipTaxIdentification,
    fetchRelationshipTaxIdentifications,
    generateEInvoiceDocument,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(SalesEInvoiceContainer);