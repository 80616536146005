import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdditionalFieldDrawe from '../../../../components/modal/modalBody/eStore/Checkout/addAdditionalField';
class AdditionalFieldDrawer extends Component {

  constructor(props){
    super(props);
    this.state = {
      field_Name:props.data ?props.data.field_Name:"",
      placeholder:props.data ?props.data.placeholder:"",
      field_Name:props.data ?props.data.field_Name:"",
      index:props.data ?props.data.index:null,
      additionalValue:props.additionalValue?props.additionalValue:[]
    }
}
 
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId
    };
   
    
    
    
  }
  componentWillReceiveProps(nextProps) {
  
  }

  render() {
    return (
      // <ContactCompo {...this.props} {...this.state} />
      <AdditionalFieldDrawe {...this.props} {...this.state} updateState={(data) => { this.setState(data) }}/>

    )
  }
}

const mapStateToProps = (state) => {
  return {
 
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFieldDrawer);
