import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Switch,Input } from 'antd';
import { TextArea } from '../../../general/TextAreaComponent';
//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
// import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { getUserDefinedName } from '../../../../utils';

function CourseTermDrawer(props) {
  const closeDrawer = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addCourseTermDrawerVisible: false,
      },
      termData: {}
    })
  }

  const handleSubmit = (props) => {
    props.updateState({ submittedOnce: true });
    if(!props.termName && !props.termCode){
      return;
    }
    if (props.isCertificateAwarded && (!props.certificateName || !props.certificateMessage)) {
      return;
    }
   
      const payload = {
        relationshipId: props.companyInfo.relationshipId,
        createdByEmpId: (props.userInfo || {}).userId,
        termName: props.termName,
        termCode: props.termCode,
        isCertificateAwarded: props.isCertificateAwarded,
        certificateName: props.certificateName,
        certificateMessage: props.certificateMessage,
        courseTermId: props.courseTermId,
        version: props.version,
      };

      props.pushModalToStack({
        modalBody: <FormattedMessage id={props.termData?'schoolManagement.school.confirmation.edit':'schoolManagement.school.confirmation.create'} defaultMessage='' />,
        handleSubmit: () => {
          props.saveCourseTerm(payload, props);
        }
      });

  }

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-head-txt">
            {/* <FormattedMessage
              id={
                props.termData
                  ? "schoolManagement.courseTerm.edit"
                  : "schoolManagement.courseTerm.new"
              }
              defaultMessage=" "
            /> */}
            {getUserDefinedName(props.termData
                  ? "schoolManagement.courseTerm.edit"
                  : "schoolManagement.courseTerm.new", props)}
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-drawer"
        visible={props.schoolDrawers.addCourseTermDrawerVisible}
        afterVisibleChange={(visible) => {
          if (visible) {
            //props.initCalls();
          }
        }}
        footer={<>
        <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </>}
      >
        <Row gutter={[16, 16]}>

          <Col span={12}>
            <div className='i-label'>
              {getUserDefinedName('schoolManagement.courseTerm.name', props)}<span className="required">*</span>
            </div>
            <TextBox
              placeholder={getUserDefinedName('schoolManagement.courseTerm.name', props)}
              type="text"
              maxLength={20}
              countStyle={{top:"40px"}}
              value={props.termName || null}
              onChange={(e) => {
                props.updateState({
                  termName: e.target.value,
                });
              }}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.termName;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
              })}
            />
          </Col>

          <Col span={12}>
            <div className='i-label'>
              {getUserDefinedName('schoolManagement.courseTerm.code', props)}<span className="required">*</span>
            </div>
            <TextBox
              type="text"
              value={props.termCode || null}
              maxLength={20}
              countStyle={{top:"40px"}}
              onChange={(e) => {
                props.updateState({
                  termCode: e.target.value,
                });
              }}
              placeholder={getUserDefinedName('schoolManagement.courseTerm.code', props)}
            />
            <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.termCode;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
              })}
            />
          </Col>

          <Col span={24}>
            <div className='i-label'>
              {getUserDefinedName('schoolManagement.courseTerm.isAwarded', props)}
            </div>
            <Switch
              checked={props.isCertificateAwarded === 1}
              onClick={(checked, e) => {
                if(props.isCertificateAwarded === 1){
                  props.updateState({
                    certificateName:null,
                    certificateMessage:null
                  })
                }
                props.updateState({ isCertificateAwarded: checked ? 1 : 0 });
              }}
            />
          </Col>

          {props.isCertificateAwarded === 1 ? (
            <>
              <Col span={24}>
                <div className='i-label'>
                  {getUserDefinedName("schoolManagement.courseTerm.certificateName", props)}
                </div>
                <TextBox
                  placeholder={getUserDefinedName("schoolManagement.courseTerm.certificateName", props)}
                  type="text"
                  maxLength={100}
                  countStyle={{top:"40px"}}
                  value={props.certificateName || null}
                  onChange={(e) => {
                    props.updateState({
                      certificateName: e.target.value,
                    });
                  }}
                />
                 <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.certificateName;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
              })}
            />
              </Col>

              <Col span={24}>
                <div className='i-label'>
                  {getUserDefinedName("schoolManagement.courseTerm.certificateMessage", props)}
                </div>
                <TextArea
                  placeholder={getUserDefinedName("schoolManagement.courseTerm.certificateMessage.placeholder", props)}
                  type="text"
                  rows={4}
                  maxLength={200}
                  countStyle={{top:"28px"}}
                  value={props.certificateMessage || null}
                  onChange={(e) => {
                    props.updateState({
                      certificateMessage: e.target.value,
                    });
                  }}
                />
               <ErrorMsg
              validator={() => {
                return !props.submittedOnce || !!props.certificateMessage;
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
              })}
            />
              </Col>
            </>
          ) : (
            ""
          )}

        </Row>


      </Drawer>
    </Fragment>
  );
}

export default injectIntl(CourseTermDrawer);
