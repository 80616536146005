import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RFQSubmissionComponent from "../../../components/B2B/RFQSubmission";
import {
  fetchRelationshipDataByRID,
  fetchRelationshipByDomainName,
  fetchRfqData,
  resetRfqData,
  submitSupplierResponse,
} from "./action";
import {
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  fetchCountries,
  fetchStates,
  fetchPaymentTerms,
  fetchTaxes,
} from "../../../actions/commonActions";
import { SmileOutlined, CheckCircleFilled } from '@ant-design/icons';
import queryString from "query-string";
import * as find from "lodash.find";
import { fetchDataIfNeeded } from "../../../utils";
import { downloadFileFromBucket } from "../../../actions/downloadFileAction";
import { Button, Result } from "antd";
import SuccessIcon from "../../../assets/images/thanks.gif";

class RFQSubmission extends Component {
  constructor(props) {
    super(props);
    const linkProps = queryString.parse(this.props.location.search) || {};
    let ids = (linkProps.t || "").split("_");
    this.state = {
      relationshipId: Number(ids[0]),
      rfqMasterId: Number(ids[1]),
      oldRfqDetailData: {},
    };
  }
  componentDidMount() {
    const linkProps = queryString.parse(this.props.location.search) || {};
    let ids = (linkProps.t || "").split("_");
    const payload = {
      relationshipId: Number(ids[0]),
      rfqMasterId: Number(ids[1]),
      supplierId: 600,
      pageNumber: 1,
      pageSize: 10,
    };
    //this.props.fetchRelationshipByDomainName({ domainName: window.location.host }, this.props);
    this.props.fetchRelationshipDataByRID(payload, this.props);

    fetchDataIfNeeded("fetchTaxes", "taxes", this.props, payload);
    ids[1] &&
      fetchDataIfNeeded("fetchRfqData", "rfqDetail", this.props, payload, true);
  }

  componentWillReceiveProps(props) {
    const linkProps = queryString.parse(this.props.location.search) || {};
    let ids = (linkProps.t || "").split("_");
    if (props.rfqDetail && props.rfqDetail.rfqMasterId) {
      const detailList = [];
      props.rfqDetail.rfqDetailsList.map((ele) => {
        // _emptyColumnCheck(ele);
        return detailList.push({
          version: ele.version,
          rfqDetailsId: ele.rfqDetailsId,
          isMaster: true,
          selectedProductValue: [ele.itemId, ele.itemVariantId],
          product: ele.itemName
            ? ele.itemName +
              ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "")
            : "",
          productObj: ele.itemName
            ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku }
            : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3,
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          parentDetailsId: ele.parentDetailsId,
          purchaseRequestMasterId: ele.purchaseRequestMasterId,
          prNumber: ele.purchaseRequestNumber,
        });
      });

      const customerName = props.rfqDetail.customerName;
      const departmentName = props.rfqDetail.requestingDepartment;
      const orderPriority = props.rfqDetail.orderPriority;
      const selectedSuppliers = [];
      const selectedSupplierObjects = [];
      const selectedSupplierNames = [];
      const biddingSupplier =
        find(props.rfqDetail.rfqBiddingSuppliersList, {
          rfqBiddingSuppliersId: Number(ids[2]),
        }) || {};
      // props.rfqDetail.rfqBiddingSuppliersList.forEach((d) => {
      //   if (selectedSuppliers.indexOf(d.supplierId) === -1) {
      //     const sup = find(this.props.suppliers[this.state.pageNumber], { supplierId: d.supplierId });
      //     if (sup && sup.supplierId) {
      //       selectedSuppliers.push(d.supplierId);
      //     } else {
      //       selectedSuppliers.push(d.supplierName);
      //     }
      //     selectedSupplierObjects.push({
      //       relationshipId: (this.props || {}).companyInfo.relationshipId,
      //       supplierId: d.supplierId,
      //       supplierName: d.supplierName,
      //       supplierStoreName: d.supplierName,
      //     });
      //     if(d.supplierName)
      //     selectedSupplierNames.push(d.supplierName)
      //   }
      // });

      const relationshipBillingAddress =
        find(props.rfqDetail.boLocationRFQList, {
          locationType: "RelationshipBillingAddress",
        }) || {};
      const supplierAddress =
        find(props.rfqDetail.boLocationRFQList, {
          supplierId: biddingSupplier.supplierId,
        }) || {};

      this.setState({
        relationshipBillingAddress,
        supplierAddress,
        selectedSuppliers,
        selectedSupplierObjects,
        selectedSupplierNames,
        version: props.rfqDetail.version,
        rfqBiddingSuppliersList: props.rfqDetail.rfqBiddingSuppliersList,
        oldRfqDetailData: props.rfqDetail,
        salesPersonName: props.rfqDetail.salesPersonFullName,
        orderPriority,
        rfqDate: linkProps.update ? props.rfqDetail.rfqDate : new Date(),
        expirationDate: linkProps.update
          ? props.rfqDetail.rfqExpirationDate
          : new Date(),
        docName: props.rfqDetail.documentName,
        customerName,
        customerId: props.rfqDetail.customerId,
        customer: { customerId: props.rfqDetail.customerId, customerName },
        departmentName,
        internalRemarks: props.rfqDetail.remarksInternal,
        supplierRemarks: props.rfqDetail.remarksSupplier,
        footer: props.rfqDetail.footer,
        customerRFQNumber: props.rfqDetail.customerRFQNumber,
        refNumber: props.rfqDetail.refNumber,
        rfqNumber: props.rfqDetail.rfqNumber,
        projectName: props.rfqDetail.projectName,
        projectNumber: props.rfqDetail.projectNumber,
        rfqMasterId: props.rfqDetail.rfqMasterId,
        rfqId: props.rfqDetail.rfqId,
        rFQDetailsList: detailList,
        tableDataReRendered: true,
        purchaseRequestMasterId: props.rfqDetail.purchaseRequestMasterId,
        prNumber: props.rfqDetail.purchaseRequestNumber,
        isConvertedFromPR: props.rfqDetail.isPRCconversion,
        biddingSupplier: biddingSupplier,
      });
      if (linkProps.update) {
        this.setState({ nextTxNumber: props.rfqDetail.rfqNumber });
      }
      props.resetRfqData();
    }
    // if (props.companyInfo && props.companyInfo.relationshipId) {
    //     const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    //     const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
    //     this.setState({
    //         relationshipBillingAddress,
    //     })
    // }
  }
  render() {
    window.document.title = this.props?.companyInfo?.storeName || "";
    return !this.state.submitSuccess ? 
      <RFQSubmissionComponent
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
      :
      <Result
        icon={<img src={SuccessIcon} alt='success'/>}
        status="success"
        title="RFQ Submitted Successfully"
        // subTitle="We will place purchase order if your quotation will be benificial for us. You can close this tab now."
        extra={[
          <Button 
            type="primary"
            key="exit"
            onClick={()=>{
              window.close();
            }}
          >
            Exit
          </Button>,
        ]}
      />
    
  }
}
const mapStateToProps = (state) => {
  return {
    countries: state.common.countries,
    states: state.common.states,
    companyInfo: state.settings.relationshipData,
    rfqDetail: state.rfq.rfqDetail,
    allPreferences: state.common.allPreferences,
    taxes: state.common.taxes,
    listLoading: state.common.listLoading,
    saveLoading: state.common.saveLoading
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRfqData,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      fetchCountries,
      fetchStates,
      fetchPaymentTerms,
      fetchRelationshipDataByRID,
      fetchRelationshipByDomainName,
      resetRfqData,
      submitSupplierResponse,
      fetchTaxes,
      downloadFileFromBucket,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RFQSubmission);
