import { Button } from "antd";
import QRCode from "qrcode.react";
import React from "react";
import { injectIntl } from "react-intl";
import config from '../../../../../config/environmentConfig';
import * as find from "lodash.find";
import "./productVariant.scss"
import { CONSTANTS, ICONS } from "../../../../../static/constants";
import filter from "lodash.filter";
const ProductVariantQrComp = (props) => {

    const { companyInfo } = props
    return (
        <>
            {props.productDetailsById.map((item) => {
                return (

                    item.inventoryItemVariantList.map((data) => {

                        let url = `${config.UI_BASE_URL}product-card?relationshipId=${companyInfo.relationshipId}&productId=${data.itemId}&productVarieantId=${data.variantId}/${companyInfo.relationshipId},${data.itemId},${data.variantId}`;

                        return (
                            <div style={{ display: 'flex', gap: "10", justifyContent: "flex-start", alignItems: "center", gap: "20px", marginBottom:"20px" }}>
                                <div style={{width:"20%"}} >
                                    <QRCode
                                        style={{ height: "70px", maxWidth: "70px", width: "70px" }}
                                        value={url} renderAs="canvas" />
                                </div>
                                <div style={{ width:"60%", display: 'flex', flexDirection: "column", justifyContent: "left", alignItems: "flex-start" }}>
                                    <div>SKU - {data.sku}</div>
                                    {data.attributeValue1 && data.attributeName1 === "Colour" ?<div style={{display:"flex",alignItems:"center" ,gap:"0.5rem"}}><span>{data.attributeName1}</span> - <div style={{backgroundColor:`${data.attributeValue1}`, height:"12px", width:"16px",borderRadius:"4px"}}></div></div> :<div>{data.attributeName1} - {data.attributeValue1}</div>}
                                    {data.attributeValue2 && data.attributeName2 === "Colour" ?<div style={{display:"flex",alignItems:"center" ,gap:"0.5rem"}}><span>{data.attributeName2}</span> - <div style={{backgroundColor:`${data.attributeValue2}`, height:"12px", width:"16px",borderRadius:"4px"}}></div></div> :<div>{data.attributeName2} - {data.attributeValue2}</div>}
                                    {data.attributeValue3 && data.attributeName3 === "Colour" ?<div style={{display:"flex",alignItems:"center" ,gap:"0.5rem"}}><span>{data.attributeName3}</span> - <div style={{backgroundColor:`${data.attributeValue3}`, height:"12px", width:"16px",borderRadius:"4px"}}></div></div> :<div>{data.attributeName3} - {data.attributeValue3}</div>}
                                </div>
                                <div style={{width:"20%"}}>
                                    <Button type="button" onClick={() => {
                                         const allRelationshipBillingAddress =
                                         filter(props.companyInfo.boLocationList, {
                                           locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
                                         }) || [];
                                         const relationshipBillingAddress =allRelationshipBillingAddress.find((x) => x.isDefault) || {};
                                        const val = {
                                            fileName: ('ProductQr') + '.pdf',
                                            transactionName: 'productQr',
                                            sku: data.sku,
                                            brandName: item?.brandName || '',
                                            attributeName1:data?.attributeName1,
                                            attributeName2:data?.attributeName2,
                                            attributeName3:data?.attributeName3,
                                            attributeValue1:data?.attributeValue1,
                                            attributeValue2:data?.attributeValue2,
                                            attributeValue3:data?.attributeValue3,
                                            maxRetailPrice:data?.maxRetailPrice,
                                            // currentStock:data?.currentStock,
                                            url:url,
                                            companyInfo:companyInfo,
                                            companyEmail:props.companyInfo.email,
                                            phoneNumber:props.companyInfo.countryCallingCode + "-" + props.companyInfo.phoneNumber,
                                            storeName:props.companyInfo?.storeName,
                                            pdfSize: "70,100",
                                            relationshipBillingAddress:relationshipBillingAddress ||allRelationshipBillingAddress? allRelationshipBillingAddress[0]:{} ,
                                            bucketName: config.BUCKET_NAME.BO_SALES_ORDER,
                                          }

                                          props.generateLabelPdf({transactionName:"productQr",data:[val],pdfSize: "70,100"})
                                        //   props.getInventoryItemByIds({relationshipId:companyInfo.relationshipId,inventoryItemCol:[data.itemId]},props);
                                    }}> <i className={ICONS.DOWNLOAD} /></Button>
                                </div>
                            </div>
                        )

                    })
                )
            })
            }
        </>
    );
};

export default injectIntl(ProductVariantQrComp);
