import React, { Component, Fragment } from 'react';
import { Row, Col, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CustomAgGrid } from '../../../../../general/CustomAgGrid';


class Payment extends Component {
    render() {
        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.common.xero.payment.invoiceNumber' defaultMessage='' />;
                },
                field: "invoiceNumber",
                resizable: true,
                width: 200
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.common.xero.payment.paymentNumber' defaultMessage='' />;
                },
                field: "paymentNumber",
                editable: true,
                resizable: true,
                width: 200
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.common.xero.payment.paymentAmount' defaultMessage="" />;
                },
                field: "paymentAmount",
                cellRendererFramework: (link) =>{
                    return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : ((this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "))}  {link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
                },
                resizable: true,
                width: 200
            }
        ];

        const uploadDateToXero = () => {
            this.props.postPaymentsToXero({
                relationshipId: (this.props.companyInfo || {}).relationshipId,
                paymentList: this.props.paymentList
            }, this.props);
        }
        return (
            <div style={{minHeight:'600px'}}>
                <Fragment>
                    <div className="modal-table-container rfqBidding-supplier-list">
                    <div>
                                <CustomAgGrid
                                    columnDefs={columnDefs}
                                    rowData={this.props.paymentList || [{}]}
                                    gridStyle={{
                                        width: '100%',
                                        height: '100%',
                                        marginBottom: '10px'
                                    }} />
                            </div>
                    </div>
                    <div className="">
                        <Row type="flex" className="action-button-group">
                            <Col span={3}>
                                <Button className="ant-btn-primary" onClick={() => {
                                    uploadDateToXero();
                                }}><FormattedMessage id='button.upload.label' defaultMessage='' /></Button>
                            </Col>

                            <Col span={2} offset={19}>
                                <Button className="grey-button" onClick={() => {
                                    this.props.hideModal();
                                }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                            </Col>
                        </Row>
                    </div>
                </Fragment>
            </div>
        )
    }
}

export default Payment;
