import React, { useState } from "react";
import "../../../../eStore/storeSettings/store-settings.scss";
import { Button, Input, Switch,Row,Col, Menu, Dropdown, Select } from "antd";
import { TextBox } from "../../../../general/TextBox";
import { FormattedMessage } from "react-intl";
import find from "lodash.find";
const { Option } = Select;
const StoreTimingsComp = (props) => {
  const { results } = props;
  const items = [
    "24 Hours",
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 AM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ];
  

 
  // Function to handle item selection in the dropdown
  const handleItemClick = (selectedItem, day,val) => {
   
    const updatedstoreTiming = props.storeTiming.map((item) => {
      if (item.day === day) {
        let endTimeValue;
        if(val === "Open"){
         
          const str = selectedItem.slice(6, 8);
          if (str === "AM") {
            endTimeValue = `${selectedItem.slice(0, 6)}PM`;
          } else {
            endTimeValue = `${selectedItem.slice(0, 6)}AM`;
          }
  
          return {
            ...item,
            isOpen: false,
            from_time: selectedItem,
            to_time: endTimeValue,
          };
        }
        else{
          
            endTimeValue = selectedItem;
            return {
              ...item,
              isClose: false,
              
              to_time: endTimeValue,
            };
        }
      
      } else {
        return { ...item, isOpen: false };
      }
    });
    props.updateState({storeTiming:updatedstoreTiming})
   
  };
  const SaveStoreTimings = () => {
    
    const val = {
      relationshipId: props.relationshipId,
      storeTiming: {
        results: props.storeTiming,
        message: props?.message,
      },
    };
    const modalData ={
      modalBody: (
        <FormattedMessage
          id="customer.salesQuote.edit.form.confirmation"
          defaultMessage=""
        />
      ),
      handleSubmit: () => {
        props.hideModal()
        props.TimeSettings(val);
       
      }
     }
     props.showModal(modalData)
   
  };

  
  return (
    <div className="store-model-container cus-space" >
      
      <div className="store-main-container">
      <div className="model-head">
        <p className="heading">Store Timings</p>
        <p className="sub-heading">
          Your store will be automatically switched online/offline based on the
          hours you choose.
        </p>
      </div>
      <div>
      <div className="model-container">
        <div style={{width:"50%"}}>  
          <table width="100%">
        <tbody >
          {(props.storeTiming||[]).map((item, index) => {
            return (
              <tr className="day-wrapper" key={index}>
                <td className="day" style={{width:"20%"}}>
                  <p>{item.day}</p>
                </td>

                <td className="toggle" style={{width:"20%"}}>
                  <Switch
                    className="custom-switch"
                    checked={item.online === true ? true : false}
                    onClick={() => { 
                    
                      const obj = (props?.storeTiming||[]).map((val =>(val?.day === item?.day) ? {...val , online: !item?.online , from_time : "24 hours"} : val  ))
                     
                      props.updateState({storeTiming: obj})
                    }}
                  />
                </td>

                <td className="" style={{ width:"20%"}}>
                  <span>
                    <span>{item.online === true ? "Open" : "Close"}</span>
                  </span>
                </td>

                <td className="timing" style={{textAlign:"right", width:"20%"}}>
                  
                  {
                    item.online === true ? (  <Select defaultValue={item.from_time}  onChange={(val)=> handleItemClick(val,item.day,"Open")}>
                   {
                    items?.map((item ,i)=>{
                      return(  <Option key={i} value={item}>{item}</Option>)
                    })
                   }
                  
                    
                  </Select>):''
                  }
                  
                </td>
              
                <td className="timing" style={{textAlign:"right", width:"20%"}}> 
                    {
                       (item.online === true && item?.from_time !== "24 hours") ?(  
                       <Select defaultValue={item.to_time}  onChange={(val)=> handleItemClick(val,item.day,"Close")}>
                        {
                        
                       items?.map((item ,i)=>{
                       
                         return(  <Option key={i} value={item}>{item}</Option>)
                       })
                      }
         </Select> ):''
                    }
                    </td>
                    
               
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <Row gutter={16}>
          <Col span={21}> <div className="i-label">Leave a message</div></Col>
     
        </Row>
      <Row  >
          
          <Col span={24}>
             <span style={{position:"absolute",top:"4px",right:"12px"}}>{ (props.message || "").length
            ? (props.message).length + " / " + 500 + " chars"
            : ""}</span>
            <textarea
            value={props.message||""}
            rows={5}
            cols={70}
            maxLength={500}
           
            onChange={(e) =>{
             
           

              props.updateState({message: e.target.value})
             
            }}
            style={{ width: "100%", padding: "10px" ,resize:"none"}}
            placeholder="Enter Message"
          />
            
          </Col>
         
        </Row>
    </div>
      </div>

    
    
      </div>
      </div>
      </div>

      <div className="footer-btn" >
        <Button type="primary" onClick={() => SaveStoreTimings()}>
          Submit
        </Button>
      </div>
     
    </div>
  );
};

export default StoreTimingsComp;
