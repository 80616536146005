import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesInvoiceDetailsComponent from '../../../../../components/modal/modalBody/customer/SalesInvoiceDetail';
import { fetchSalesInvoiceDetail, approveInvoice, uploadStamp, deleteStamp, getAvailableBalance, payOnline } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF,  downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchSalesInvoices, getSalesInvoiceCount, deleteSalesInvoice, updateUserStatus, updateSystemStatus } from '../../../../customer/invoice/Listing/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';
import { fetchAllLedgerAccounts } from '../../../../finance/LedgerAccounts/action';
import * as find from 'lodash.find';
import { fetchCustomerDetailsByCustomerId, fetchRelationshipTaxIdentifications } from '../../../../../actions/commonActions';
import { LEDGER_TYPE } from '../../../../../static/constants';
import EwayBill from '../../../../drawer/EwayBill';
import SalesEInvoice from '../../../../drawer/SalesEInvoice';
import EInvoiceDetail from '../EInvoiceDetail';
import { deleteDocument } from '../../../../customer/invoice/StandardSalesInvoice/action';
import EInvoiceCompo from '../../../../../components/modal/modalBody/customer/SalesE_Invoice';
import { deleteAttectedFile } from '../../../../../actions/commonActions';
import { getAllGstRates } from '../../../../drawer/inventory/action';
class SalesInvoiceDetail extends Component {

    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            salesE_InvoiceDrawerVisisble: false,
            eWayBillDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
  const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.invoicePayload.customerId, invoiceMasterId: this.props.invoicePayload.invoiceMasterId };
        fetchDataIfNeeded('fetchSalesInvoiceDetail', 'salesInvoiceDetail', this.props, payload, true);
        fetchDataIfNeeded(
            "fetchRelationshipTaxIdentifications",
            "allRelationshipTaxIdentificatins",
            this.props,
            payload
          );
          fetchDataIfNeeded('getAllGstRates', 'allGstRates', this.props, { ...payload, type: 'GOODS', pageNumber: 0, pageSize: 50 });

          this.props.fetchCustomerDetailsByCustomerId(payload);
        this.props.getAvailableBalance(payload);
        this.props.fetchAllLedgerAccounts(payload);

    }
    componentWillReceiveProps(props) {
        if (props.allLedgerAccounts && props.allLedgerAccounts.length && props.salesInvoiceDetail && props.salesInvoiceDetail.invoiceMasterId) {
            let selectedLedgers = {};

            props.salesInvoiceDetail.customerInvoiceCOATxList.map((ledgerObj) => {
                const ledgerObj2 = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(ledgerObj.ledgerAccountId) }) || {};
                if (ledgerObj.txType === LEDGER_TYPE.TYPE_SALES && ledgerObj.accountingEntry === "credit") {
                    selectedLedgers.salesLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.salesLedgerName = ledgerObj2.ledgerAccountName;
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_SALES && ledgerObj.accountingEntry === "debit") {
                    selectedLedgers.salesDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.salesDebtorLedgerName = ledgerObj2.ledgerAccountName;
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE && ledgerObj.accountingEntry === "credit") {
                    selectedLedgers.expenseLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.expenseLedgerName = ledgerObj2.ledgerAccountName;
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE && ledgerObj.accountingEntry === "debit") {
                    selectedLedgers.expenseDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.expenseDebtorLedgerName = ledgerObj2.ledgerAccountName;
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "credit") {
                    selectedLedgers.discountDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.discountDebtorLedgerName = ledgerObj2.ledgerAccountName;
                }
                else if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "debit") {
                    selectedLedgers.salesDiscountLedgerId = ledgerObj2.chartOfAccountDetailsId;
                    selectedLedgers.salesDiscountLedger = ledgerObj2.ledgerAccountName;
                }
                return '';
            });

            props.salesInvoiceDetail.customerInvoiceDetailsList.map((ele) => {
                ele.customerInvoiceCOATxList.map((ledgerObj) => {
                    const ledgerObj2 = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(ledgerObj.ledgerAccountId) }) || {};
                    if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "credit") {
                        selectedLedgers.discountDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                        selectedLedgers.discountDebtorLedgerName = ledgerObj2.ledgerAccountName;
                    }
                    else if (ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT && ledgerObj.accountingEntry === "debit") {
                        selectedLedgers.salesDiscountLedgerId = ledgerObj2.chartOfAccountDetailsId;
                        selectedLedgers.salesDiscountLedgerName = ledgerObj2.ledgerAccountName;
                    }
                    else if (ledgerObj.txType === LEDGER_TYPE.TYPE_TAX && ledgerObj.accountingEntry === "credit") {
                        selectedLedgers.salesOutputTaxLedgerId = ledgerObj2.chartOfAccountDetailsId;
                        selectedLedgers.salesOutputTaxLedgerName = ledgerObj2.ledgerAccountName;
                    }
                    else if (ledgerObj.txType === LEDGER_TYPE.TYPE_TAX && ledgerObj.accountingEntry === "debit") {
                        selectedLedgers.taxDebtorLedgerId = ledgerObj2.chartOfAccountDetailsId;
                        selectedLedgers.taxDebtorLedgerName = ledgerObj2.ledgerAccountName;
                    }
                    return '';
                });
                return '';
            });
            this.setState({ selectedLedgers});
           
        }
    }
    openEInvoiceDetails = (data ,eInvoiceData) => {
        
        const obj = {...this.props , einvoiceData: eInvoiceData?.response}
        const invoicePayload = {
          customerId: data?.salesInvoiceDetail.customerId,
          invoiceMasterId: data?.salesInvoiceDetail.invoiceMasterId,
        };
        this.props.pushModalToStack({
          modalBody: (
            <EInvoiceDetail  {...obj} data={eInvoiceData?.response
            } invoicePayload={invoicePayload}/>
          ),
          width: "100%",
          hideFooter: true,
          wrapClassName: "modal-custom-detail",
        });
      };
    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.salesE_InvoiceDrawerVisisble && <SalesEInvoice {...this.state} {...this.props} openEInvoiceDetails={this.openEInvoiceDetails} updateDrawerState={(data) => { this.setState(data) }} />}
            {this.state.eWayBillDrawerVisible && <EwayBill {...this.state} {...this.props} updateDrawerState={(data) => { this.setState(data) }} />}
            <SalesInvoiceDetailsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,
        userInfo: state.common.userInfo,
        permissions: state.auth.permissions,
        availableCredits: state.salesInvoice.availableCredits,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        detailLoading: state.common.detailLoading,
        allRelationshipTaxIdentificatins:
        state.common.allRelationshipTaxIdentificatins,
        customerDetails : state.common.customerDetail,
        allGstRates: state.inventory.allGstRates,

    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSalesInvoices,
    fetchSalesInvoiceDetail,
    getSalesInvoiceCount,
    downloadFileFromBucket,
    generatePDF, printPDF,
   fetchRelationshipTaxIdentifications,
    approveInvoice,
    uploadStamp, 
    deleteStamp,
    deleteSalesInvoice,
    getAvailableBalance, 
    payOnline,
    updateUserStatus,
    fetchAllLedgerAccounts ,
    updateSystemStatus,
    fetchCustomerDetailsByCustomerId,
    deleteAttectedFile,
    getAllGstRates,
    deleteDocument

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoiceDetail);