import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { } from './action';
import { popModalFromStack } from './../../../../../actions/commonActions';
import ProductDetailComponent from '../../../../../components/modal/modalBody/product/ProductDetail';
import { fetchInventoryItemDetails, resetInventoryItemDetails } from '../../../../product/detail/action';
class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 100,
            pageNumber: 1,
            searchedText: ''
        };
    }
    componentDidMount() {
        const payload = {
            relationshipId: (this.props.companyInfo || {}).relationshipId,
            itemId: (this.props.product || {}).itemId
        };
        this.props.resetInventoryItemDetails();
        this.props.fetchInventoryItemDetails(payload);
    }

    componentWillReceiveProps(props) {
        if (props.productDetails && props.productDetails.itemId) {
            this.setState({
                product: props.productDetails
            });
        }
    }

    render() {
        return <ProductDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}

const mapStateToProps = (state) => {
    return {
        productDetails: state.product.productDetails,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    popModalFromStack,
    fetchInventoryItemDetails,
    resetInventoryItemDetails,
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);