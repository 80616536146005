import React, { Fragment} from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Input, Select,Tooltip } from 'antd';
import { CustomButton } from '../../../../general/CustomButton';
import { TextBox } from '../../../../general/TextBox';
import { ErrorMsg } from '../../../../general/ErrorMessage';
import filter from 'lodash.filter';
// import WhatupLogo from "../../../../../../src/assets/images/whatsapp.png";
import * as find from 'lodash.find';

function BrodcastSMSDrawer(props) {

  const { dgList, dgNameOptionList, dgIds, subAccount, updateState, relationshipId, to, message, charCount, totalCost,
    totalSelectedRecipients, selectedDGIds, selectedContacts, invalidContacts } = props;

  const closeDrawer = () => {
    props.updateHeaderState({
      communication: {
        addBrodcastSMSDrawerVisible: false
      }
    })
  }

  const validateBrodcastToList = () => {
    let temp = []
    for (let i = 0; i < selectedContacts.length; i++) {
      let d = selectedContacts[i];
      const regex = /^((\+1))[0-9]{10}$/;
      if (regex.test(d) === false) {
        temp.push(d);
      }
    }

    if (temp.length > 0) {
      updateState({ 'invalidContacts': temp });
      updateState({ 'invalidTo': true });
      return false;
    } else {
      return true;
    }

  }

  const handleSubmit = (props) => {

    if (props.to.length > 0 && props.message && validateBrodcastToList()) {

      const payload = {
        dgIdList: selectedDGIds,
        contactList: selectedContacts,
        relationshipId: relationshipId,
        message: props.message,
        isSendFromSubaccount: 1,
        cost: props.totalCost
      };

      props.pushModalToStack({
        modalBody: <FormattedMessage id='communication.account.popup.brodcast.confimation.msg' defaultMessage='' />,
        handleSubmit: () => {
          if (props.brodcastSMSType === 'whatsappSMS') {
            payload.source = 'whatsapp';
            props.brodcastWhatsappSMS(payload, props);
          }
          if (props.brodcastSMSType === 'textSMS') {
            payload.source = 'sms' 
            props.brodcastTextSMS(payload, props);
          }
        }
      });

    } else {
      updateState({
        submittedOnce: true,
      })
    }
  };

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage id='communication.account.brodcastSMS.drawer.drawerName' defaultMessage=' ' />
            {/* {props.brodcastSMSType == 'textSMS' ? <div className="top-box-icon1"> <i className="fa fa-phone" ></i></div> : ''}
            {props.brodcastSMSType == 'whatsappSMS' ? <div className="top-box-icon1"> <img src={WhatupLogo} alt="" /></div> : ''} */}
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-supplier-drawer"
        visible={props.addBrodcastSMSDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <label><FormattedMessage id='communication.account.brodcastSMS.drawer.form.from' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <TextBox
                type='text'
                // className={(props.submittedOnce && !props.name) ? 'input-text-error' : ''}
                value={subAccount.subaccountPhoneNumber}
                disabled={true}
                placeholder={props.intl.formatMessage({ id: 'communication.account.brodcastSMS.drawer.form.from', defaultMessage: '' })}
              />
            </Col>
          </Row>

          <Row gutter={[16, 4]}>
            <Col span={12}>
              <span className="">
                <FormattedMessage id='communication.account.brodcastSMS.drawer.form.to' defaultMessage='' />
              </span><span className="required">*</span>
              <Tooltip placement="top" title={props.intl.formatMessage({ id: 'phone.formate.helpMessag' })} trigger="click">
                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24} >
              <Select  className='send-smss'
                tokenSeparators={[',']}
                bordered={true}
                onDeselect={
                  (value) => {
                    updateState({ 'invalidTo': false });

                    //update to list
                    let to = Object.assign([], props.to);
                    to = filter(to, (obj) => {
                      return obj !== value;
                    });
                    updateState({ to });

                    //update invalid contact list
                    let invalidContacts = Object.assign([], props.invalidContacts);
                    invalidContacts = filter(invalidContacts, (obj) => {
                      return obj !== value;
                    });
                    updateState({ invalidContacts });

                    //update selectedContacts/totalCost/totalSelectedRecipients
                    if (dgIds.indexOf(value) < 0) {
                      let selectedContacts = Object.assign([], props.selectedContacts);
                      selectedContacts = filter(selectedContacts, (obj) => {
                        return obj !== value;
                      });
                      updateState({ selectedContacts });

                      updateState({ 'totalCost': totalCost - 0.0085 });
                      updateState({ 'totalSelectedRecipients': totalSelectedRecipients - 1 });
                    } else {
                      let dg = find(dgList, { distributionGroupId: Number(value) });
                      updateState({ 'totalCost': totalCost - (dg.totalContacts * 0.0085) });
                      updateState({ 'totalSelectedRecipients': totalSelectedRecipients - dg.totalContacts });

                      let selectedDGIds = Object.assign([], props.selectedDGIds);
                      selectedDGIds = filter(selectedDGIds, (obj) => {
                        return obj !== value;
                      });
                      updateState({ selectedDGIds });
                    }
                  }
                }

                onSelect={(selectedValue) => {
                  updateState({ 'invalidTo': false });

                  //update to list
                  const to = Object.assign([], props.to);
                  to.push(selectedValue);
                  updateState({ to });

                  //update selectedContacts/totalCost/totalSelectedRecipients
                  if (dgIds.indexOf(selectedValue) < 0) {
                    const selectedContacts = Object.assign([], props.selectedContacts);
                    selectedContacts.push(selectedValue);
                    updateState({ selectedContacts });

                    updateState({ 'totalCost': totalCost + 0.0085 });
                    updateState({ 'totalSelectedRecipients': totalSelectedRecipients + 1 });
                  } else {
                    let dg = find(dgList, { distributionGroupId: Number(selectedValue) });
                    updateState({ 'totalCost': totalCost + (dg.totalContacts * 0.0085) });
                    updateState({ 'totalSelectedRecipients': totalSelectedRecipients + dg.totalContacts });

                    const selectedDGIds = Object.assign([], props.selectedDGIds);
                    selectedDGIds.push(selectedValue);
                    updateState({ selectedDGIds });
                  }
                }}

                filterOption={(input, option) => {
                  if (!option.props.children) return;
                  return (
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0);
                }}

                mode="tags"
                defaultValue={to}
              >
                {dgNameOptionList}
              </Select>

              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.to.length > 0 }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />

              {invalidContacts.length > 0 ?
                <div>
                  <span>
                    Invalid Recipients - {invalidContacts.map((contact, i) => {
                      return <span key={i}>{contact}{(i + 1) < invalidContacts.length ? ', ' : ''}</span>
                    })}
                  </span><br/> Number should be in this format - +18885559999
                </div>
                : ''
              }

              {to.length > 0 && !props.invalidTo && invalidContacts.length === 0 ?
                <div>
                  <span>
                    {totalSelectedRecipients} Recipients
                  </span>
                </div>
                : ''
              }

            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={24}>
              <label>
                <FormattedMessage id='communication.account.brodcastSMS.drawer.form.message' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Input.TextArea
                rows={5}
                maxLength={props.brodcastSMSType === 'textSMS' ? 160 : 450 }
                className={(props.submittedOnce && !props.message) ? 'input-text-error' : ''}
                value={message}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'communication.account.brodcastSMS.drawer.form.message',
                    defaultMessage: ''
                  })
                }
                onChange={(e) => {
                  props.updateState({
                    message: e.target.value,
                    charCount: (e.target.value).length,
                  });
                }}
                onFocus={() => {
                  props.updateState({isMessageFocus: true});
                }}
                onBlur={()=>{
                  props.updateState({isMessageFocus: false});
                }}
              />
              <div style={{ 'float': 'right', 'padding': '0.1rem 0 0 0', 'fontSize': '0.875rem' }}>
                {props.isMessageFocus ? <span>
                  Characters
                  <span id="current"> {charCount}</span>
                  <span id="maximum"> / {props.brodcastSMSType === 'textSMS' ? 160 : 450} &nbsp; </span>
                  {props.to.length > 0 && !props.invalidTo ? <span> | &nbsp; </span> : ''}
                </span> : ''
                }
                {props.to.length > 0 && !props.invalidTo ?
                  <span>
                    {/* | &nbsp;  Cost ${totalCost.toFixed(4).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                     Cost ${totalCost.toFixed(4)}
                  </span>
                  : ''
                }
              </div>
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.message }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
        </Form>

        <div className='form-footer'>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.sendNow'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </div>
      </Drawer>
    </Fragment>
  );

}

export default injectIntl(BrodcastSMSDrawer);
