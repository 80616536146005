import React from "react";
import InPageMenu from "../../Common/InPageMenu";
import PageBreadcrumb from "../../PageBreadcrumb";
import { FormattedMessage } from "react-intl";
import { ICONS } from '../../../static/constants';
import Holiday from '../../../assets/images/calender-icon.svg';
import Leave from '../../../assets/images/leave-icon.svg';
import Bonus from '../../../assets/images/bonus.svg';
import ConfigurationContainer from '../../../containers/company/PayrollMeta/Configuration';
import BonusContainer from '../../../containers/company/PayrollMeta/Bonus';
import TaxRuleContainer from '../../../containers/company/PayrollMeta/TaxRule'
function PayrollMeta(props) {
    // const { path } = useRouteMatch();

    const handleActiveTab = (index) => {
        props.updateState({
            selectedMenuItem: index
        })
    };

    const activeTab = () => {
        switch (props.selectedMenuItem) {
            case 0:
                return <TaxRuleContainer />;
            case 1:
                return <ConfigurationContainer />;
            case 2:
                return <BonusContainer />;
            default:
                return null;
        }
    };
    const breadCrumbList = [
        {
            name: <FormattedMessage id='employeeMeta.pageName.dashboard' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='payrollMeta.pageName.payroll' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='employeeMeta.pageName.meta' defaultMessage='' />,
        }
    ];
    const menuList = [{
        path: 'a',
        icon: Leave,
        name: "payrollMeta.menulabel.taxRule",
        description: "You are powered with creating multiple users for your business.",

    },
    {
        icon: Holiday,
        name: "payrollMeta.menulabel.lateConfiguration",
        description: "You are powered with creating multiple users for your business."

    },
    {
        icon: Bonus,
        name: "payrollMeta.menulabel.bonus",
        description: "You are powered with creating multiple users for your business."

    },


    ]
    return (
        <><div className="view-top-bar">
            <div className="top-view-left">
                <div className="page-heading">
                    <FormattedMessage
                        id="heading.team.payrollMeta"
                        defaultMessage="" />
                </div>
                <div className="vertical-sep" />
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>
        </div>

            <div className="meta-container">
                <div className="meta-sidenav">
                    <InPageMenu menuList={menuList} onItemClick={handleActiveTab} />
                </div>
                <div className="meta-main-body">
                    {activeTab()}
                    {/* <Switch>
                        {menuList.map((menuItem, index) => {
                            return (
                                <Route key={index} path={`${path}/a`} component={menuItem.component || 'none'} />
                            );
                        })}
                    </Switch> */}
                </div>
            </div></>
    )
}
export default PayrollMeta;