import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';

import StandardRFQ from '../../../../components/supplier/rfq/SplitRFQ'
import { fetchSuppliers } from '../../../../containers/supplier/Listing/action';
import { fetchCustomers } from '../../../../containers/customer/Listing/action';
import RFQDetail from '../../../modal/modalBody/supplier/RFQDetail';
import queryString from 'query-string';
import {
  fetchProjects, fetchProducts, fetchCustomerDetailsByCustomerId,
  showModal, hideModal, pushModalToStack, popModalFromStack,
  fetchUOM, addUOM, deleteUOM,
  fetchAllDepartments, addDepartment, deleteDepartment, deleteProject, fetchRelationshipTaxIdentifications,
  getRelationshipAddress, deleteAddress
} from '../../../../actions/commonActions';
import {
  createRFQ, fetchRfqData, resetRfqData
} from './action';
import { fetchAllRFQ, fetchAllRFQStatus, getRFQCount } from '../Listing/action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { CONSTANTS } from '../../../../static/constants';
import { deleteCustomers } from '../../../customer/Listing/action'

class PurchaseInvoice extends Component {
  constructor(props) {
    super(props);
    const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    let queryData = queryString.parse(this.props.location?.search) || {};
    if(this.props.location && this.props.location?.state){
      queryData = this.props.location.state;
    }
    const linkProps = queryData || {};
    this.state = {
      linkProps,
      allRelationshipBillingAddress,
      supplier: undefined,
      rfqDate: new Date(),
      priceListName: undefined,
      pageSize: 100,
      pageNumber: 1
    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      rfqDate: new Date(),
      salesQuotationMasterId: this.props.salesQuotationMasterId || this.state.linkProps.salesQuotationMasterId,
      purchaseRequestMasterId: this.props.purchaseRequestMasterId || this.state.linkProps.purchaseRequestMasterId,
      customerId: this.props.customerId || this.state.linkProps.customerId,
      rfqMasterId: this.props.rfqMasterId || this.state.linkProps.rfqMasterId,
      pageNumber: 1,
      pageSize: 100
    };

    payload.customerId && fetchDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerDetail', this.props, payload, true);
    payload.rfqMasterId && fetchDataIfNeeded('fetchRfqData', 'rfqDetail', this.props, payload, true);
    fetchPaginationDataIfNeeded('fetchSuppliers', 'suppliers', this.props, payload, true);
    fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, payload, true);
    fetchDataIfNeeded('fetchAllDepartments', 'departments', this.props, payload);
    fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
    fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);  
    fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
  }
  openRFQDetails = (data) => {
    const rfqPayload = {
      supplierId: data.supplierId,
      rfqMasterId: data.rfqMasterId
    }
    this.props.pushModalToStack({
      modalBody: <RFQDetail {...this.props} rfqPayload={rfqPayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }


  componentWillReceiveProps(props) {

    if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
      const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
      let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
        return obj.relationshipTaxIdentificationId;
      })
      this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
    }

    const isMaster = (detailId, _rfqChildMasterList, index) =>{
      let isMasterExist = false;
      for(let j=0; j< (_rfqChildMasterList || []).length ;j++){
        for(let i=0; i< _rfqChildMasterList[j].rfqChildDetailList.length; i++){
          if(_rfqChildMasterList[j].rfqChildDetailList[i].rfqDetailsId === detailId){
            _rfqChildMasterList[j].rfqChildDetailList[i].parentIndex = index;
            isMasterExist = true;
          }
        }
      }
      return isMasterExist;
    }

    if (props.rfqDetail && props.rfqDetail.rfqMasterId) {
      const _rfqChildMasterList = [...props.rfqDetail.rfqChildMasterList];
      const detailList = [];
      const masterDetailList = []
      props.rfqDetail.rfqDetailsList.map((ele, index) => {
        return masterDetailList.push({
          version: ele.version,
          rfqDetailsId: ele.rfqDetailsId,
          isMaster: true,
          isDisable: isMaster(ele.rfqDetailsId, _rfqChildMasterList, index),
          selectedProductValue: [ele.itemId, ele.itemVariantId],
          product: ele.itemName ? ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "") : '',
          productObj: ele.itemName ? {itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku} : {},
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          qty: ele.quantity,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
        });
      });

      detailList.push(masterDetailList);

      _rfqChildMasterList && _rfqChildMasterList.map((splitData, i)=>{
        let rfqChildDetailList = []
        var state = {};
        state["internalRemarks"+(i+1)] = splitData.remarksInternal;
        state["supplierRemarks"+(i+1)] = splitData.remarks;
        state["selectedSuppliers"+ (i+1)] = [];
        state["childOrderPriority"+ (i+1)] = splitData.orderPriority;
        state["rfqexpirationDate"+ (i+1)] = splitData.rfqExpirationDate ? new Date(splitData.rfqExpirationDate) : new Date();
        splitData.rfqBiddingSuppliersList.forEach((e) =>{
          state["selectedSuppliers"+ (i+1)].push(e.supplierId);
        })
        state["footer"+(i+1)] = splitData.footer;
        state["isDataLoaded"] = true;
        
        this.setState(state);
        splitData.rfqChildDetailList.map((ele) =>{
          return rfqChildDetailList.push({
            rfqDetailsId: ele.rfqDetailsId,
            version: ele.version,
            selectedProductValue: [ele.itemId, ele.itemVariantId],
            product: ele.itemName ? ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + ") ") || "") : "",
            productObj: ele.itemName ? {itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku} : {},
            itemVariantName: ele.itemVariantName,
            variant: {
              attributeId1: ele.attributeId1,
              attributeId2: ele.attributeId2,
              attributeId3: ele.attributeId3,
              attributeName1: ele.attributeName1,
              attributeName2: ele.attributeName2,
              attributeName3: ele.attributeName3,
              attributeValue1: ele.attributeValue1,
              attributeValue2: ele.attributeValue2,
              attributeValue3: ele.attributeValue3
            },
            description: ele.description,
            qty: ele.quantity,
            parentIndex: ele.parentIndex,
            index: i+1,
            rate: ele.anItemSalePrice,
            baseRate: ele.basePrice,
            uom: ele.uomName,
            hsnCode: ele.hsnCode,
          });
        });
        return detailList.push(rfqChildDetailList);
      });

      const customerName = props.rfqDetail.customerName;
      const departmentName = props.rfqDetail.requestingDepartment
      const orderPriority = props.rfqDetail.orderPriority;
      const projectName = props.rfqDetail.projectName;
      const selectedSuppliers = [];
      props.rfqDetail.rfqBiddingSuppliersList.forEach((d) => {
        selectedSuppliers.push(d.supplierId);
      });
      const relationshipAddress = find(props.rfqDetail.boLocationRFQList, { locationType: "RelationshipBillingAddress" });
      const customerBillingAddress = find(props.rfqDetail.boLocationRFQList, { customerId: props.rfqDetail.customerId });
      this.setState({
        relationshipAddress,
        selectedSuppliers,
        orderPriority,
        projectName,
        version: props.rfqDetail.version,
        rfqDate: props.rfqDetail.rfqDate ? new Date(props.rfqDetail.rfqDate): new Date(),
        expirationDate: props.rfqDetail.rfqExpirationDate ? new Date(props.rfqDetail.rfqExpirationDate): new Date(),
        projectNumber: props.rfqDetail.projectNumber,
        projectMasterId: props.rfqDetail.projectMasterId,
        salesPersonId: props.rfqDetail.salesPersonId,
        footer: props.rfqDetail.footer,
        rfqExpirationDate: props.rfqDetail.rfqExpirationDate,
        internalRemarks : props.rfqDetail.remarksInternal,
        supplierRemarks : props.rfqDetail.remarksSupplier,
        salesPersonFullName: props.rfqDetail.salesPersonFullName,
        customerId: props.rfqDetail.customerId,
        customerName,
        departmentName,
        customer: { customerId: props.rfqDetail.customerId, companName: customerName },
        customerRFQNumber: props.rfqDetail.customerRFQNumber,
        refNumber: props.rfqDetail.refNumber,
        rfqNumber: props.rfqDetail.rfqNumber,
        rfqMasterId: props.rfqDetail.rfqMasterId,
        rfqId: props.rfqDetail.rfqId,
        rFQSplitList: detailList,
        prNumber: props.rfqDetail.purchaseRequestNumber,
        rfqData: props.rfqDetail,
        isPRCconversion: props.rfqDetail.isPRCconversion,
        customerBillingAddress
      });
      props.resetRfqData();
    }

    if (props.boRelationshipAddress && props.boRelationshipAddress.length) {
      const allRelationshipBillingAddress = [];
      const allRelationshipShippingAddress = [];
      props.boRelationshipAddress.forEach((e) => {
        if (e.locationType === 'RelationshipBillingAddress') {
          allRelationshipBillingAddress.push(e);
        }else{
          allRelationshipShippingAddress.push(e)
        }
      });
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress
      })
    } else {
      const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      const allRelationshipShippingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_SHIPPING_ADDRESS }) || [];
      this.setState({
        allRelationshipBillingAddress,
        allRelationshipShippingAddress,
      });

    }
  }


  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching
    };
    this.props.fetchProducts(payload)
  }

  render() {
    return (
      <StandardRFQ
        onSearch={(text, updateAllOptions, updateFetching) => {
          this.onSearch(text, updateAllOptions, updateFetching)
        }}
        {...this.props}
        {...this.state}
        openRFQDetails={this.openRFQDetails}
        updateState={(data) => { this.setState(data) }}
      />
    );
  }

}

const mapStateToProps = (state) => {
  return {
    suppliers: state.supplier.suppliers,
    customers: state.customer.customers,
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    uomList: state.common.uomList,
    projectList: state.common.projectList,
    rfqDetail: state.rfq.rfqDetail,
    departments: state.common.departmentList,
    taxes: state.common.taxes,
    rfqPrData: state.purchaseRequest.rfqPrData,
    products: state.salesInvoice.products,
    customerDetail: state.common.customerDetail,
    boRelationshipAddress: state.common.boRelationshipAddress,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createRFQ,
  fetchRfqData,
  fetchCustomerDetailsByCustomerId,
  fetchSuppliers,
  fetchCustomers,
  fetchProjects,
  resetRfqData,
  fetchUOM,
  fetchProducts,
  fetchAllDepartments,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  deleteCustomers,
  addUOM,
  fetchRelationshipTaxIdentifications,
  deleteUOM,
  addDepartment,
  deleteDepartment,
  deleteProject,
  getRelationshipAddress,
  deleteAddress,
  fetchAllRFQ,
  fetchAllRFQStatus,
  getRFQCount,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoice);
