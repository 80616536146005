import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Row, Col, Tooltip, Input } from 'antd';
import { showToasterMessage } from '../../../../../utils';
import 'material-ui-rc-color-picker/assets/index.css';
//import { CONSTANTS } from '../../../../../static/constants';
import './index.scss';
import checkIcon from '../../../../../assets/images/check.png';
import crossIcon from '../../../../../assets/images/cross.png';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import config from '../../../../../config/environmentConfig';
import CryptoJS from "crypto-js";

class DonationSettingComponent extends Component {
    constructor(props) {
        super(props);
        let tokenInfo={
            relationshipId: props.companyInfo.relationshipId,
            source: 'web-donation'
          }
        //  config.KIOSK_BASE_URL + '?token=' + encodeURIComponent(encrypted)
          let encrypted = CryptoJS.AES.encrypt(JSON.stringify(tokenInfo), config.ENCRYPT_SECRET).toString();
        this.state = {
          previewUrl: (props.subdomainName || window.location.origin) + '/app/web-donation?token='+ encodeURIComponent(encrypted),
          encrypted,
          subdomainName: window.location.origin
        }
      }
    copyCodeToClipboard = () => {
        const el = this.textArea;
        el.select();
        document.execCommand("copy");
        showToasterMessage({
          messageType: 'success', description: this.props.intl.formatMessage(
            {
              id: 'copyToClipboard.text',
              defaultMessage: ''
            }
          )
        });
      }
      componentWillReceiveProps(props) {
        if ( props.subdomain && props.subdomain.alpideSubdomainId && !this.state.subdomainName) {
            this.setState({
             // subdomainName: props.subdomain.subdomainName ? 'https://' + props.subdomain.subdomainName : window.location.origin
             subdomainName: window.location.origin
            });
          }
    }

    render() {
        const self = this;
        // const agGridStyle = {
        //     height: '100%',
        //     width: '100%'

        // };
        const onFirstDataRendered = (params) => {
            params.api.sizeColumnsToFit();
        }
        const getRowHeight = (params) => {
            return 30;
        }

        const getFormSetting = (props) => {
            let selectedProjects = {};
            self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (data.isListingAllow) {
                    selectedProjects[data.projectMasterId] = {
                        displayName: data.ListingName,
                        projectEndDate: data.projectEndDate,
                        projectId: data.projectId,
                        projectMasterId: data.projectMasterId,
                        projectName: data.projectName,
                        projectNumber: data.projectNumber,
                        isListingAllow: data.isListingAllow ? 1 : 0,
                        ledgerAccountName: data.ledgerAccountName,
                        chartOfAccountDetailsId: data.chartOfAccountDetailsId,
                        paymentGatewayName: data.paymentGatewayName,
                        paymentGatewayId: data.paymentGatewayId,
                    }
                }
            })
            return {
                textColor: props.textColor,
                bgColor: props.bgColor,
                formHeading: props.formHeading,
                subHeading: props.subHeading,
                footer: props.footer,
                isShowDonationMenu: props.isShowDonationMenu,
                isShowFeePaymentMenu: props.isShowFeePaymentMenu,
                isShowOpenEventMenu: props.isShowOpenEventMenu,
                isShowRegistrationMenu: props.isShowRegistrationMenu,
                isShowFundRaising: props.isShowFundRaising,
                selectedProjects: selectedProjects,
            }
        }

        const saveSettings = (props) => {
            if (props.textColor && props.bgColor && props.textColor === props.bgColor) {
                return showToasterMessage({
                    messageType: 'error', description: props.intl.formatMessage({ id: 'sameColor.validation' })
                });
            }
            // if (!props.formHeading) {
            //     return showToasterMessage({
            //         messageType: 'error', description: props.intl.formatMessage({ id: 'registration.formHeading.required' })
            //     });
            // }
            let isLedgerMissing =  false;
            let isDisplayNameMissing =  false;
            let isPaymentGatewayMissing =  false;
            self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if (data.isListingAllow && !data.chartOfAccountDetailsId) {
                    isLedgerMissing= true;
                }
                if (data.isListingAllow && !data.ListingName) {
                    isDisplayNameMissing= true;
                }
                if (data.isListingAllow && !data.paymentGatewayId) {
                    isPaymentGatewayMissing= true;
                }
            });
            if (isLedgerMissing) {
                return showToasterMessage({
                    messageType: 'error', description: props.intl.formatMessage({ id: 'ledger.info.missing' })
                });
            }
            if (isDisplayNameMissing) {
                return showToasterMessage({
                    messageType: 'error', description: props.intl.formatMessage({ id: 'displayName.missing.validation' })
                });
            }
            if (isPaymentGatewayMissing) {
                return showToasterMessage({
                    messageType: 'error', description: props.intl.formatMessage({ id: 'paymentGateway.notAvailable.message' })
                });
            }

            const modalData = {
                modalBody: <FormattedMessage id='save.confirmation' />,
                handleSubmit: () => {
                    let donationContent = getFormSetting(props);
                    let payload = {
                        relationshipId: (props.companyInfo || {}).relationshipId,
                        settingsDonationId: props.settingsDonationIdWeb,
                        version: props.version,
                        donationContent: JSON.stringify(donationContent),
                        source: 'webDonation'
                    }
                    props.saveDonationSetting(payload, props);
                    props.hideModal();
                }
            };
            props.pushModalToStack(modalData);
        }

        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='project.label' defaultMessage='' />;
                },
                field: 'projectName'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='displayName.text' defaultMessage='' />;
                },
                field: 'ListingName', 
                editable: true,
                resizable: true,
                cellEditor: 'customTextEditor',
                width: 268,
                suppressKeyboardEvent: (params) => {
                    //const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {                           
                        maxLength: 30,
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        }
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        <FormattedMessage id='listedForDonation.text' defaultMessage='' />
                      <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'listedForDonation.help.message'})} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </div>
                },
                field: "isListingAllow",
                editable: true,
                resizable: true,
                width: 200,
                suppressKeyboardEvent: (params) => {
                   // const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    if (keyCode === 9) {
                        params.api.tabToNextCell();
                        return true;
                    }
                    // const gridShouldDoNothing = keyCode === KEY_ENTER;
                    // return gridShouldDoNothing;
                },
                cellEditor: 'customCheckboxEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.setDataValue(obj.colDef.field, value);
                        },
                    }
                },
                cellRendererFramework: (params) => {
                    return params.node.data.isListingAllow ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Default" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Default" />;
                },
                
            },
            {
                headerComponentFramework: () => {
                  return <FormattedMessage id='ledger.text' defaultMessage='' />;
                },
                field: 'ledgerAccountName',
                editable: true,
                sortable: true,
                resizable: true,
                suppressMovable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                  const KEY_ENTER = 13;
                  const keyCode = params.event.keyCode;
                  const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                  return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                  return {
                    lastColumnCell: false,
                    items: this.props.allLedgerAccounts,
                    valueKeyName: 'ledgerAccountName',
                    optionKeyName: 'chartOfAccountDetailsId',
                    onSelect: (selectedValue, option) => {
                      obj.node.setDataValue(obj.colDef.field, selectedValue);
                      obj.node.data.chartOfAccountDetailsId = option.value;
                    },
                  }
                },
                cellRendererFramework: (params) => {
                    return <span>{params.node.data.ledgerAccountName}</span>
                },
              },
              {
                headerComponentFramework: () => {
                  return <FormattedMessage id='paymentGateway.available' />;
                },
                field: 'paymentGatewayName',
                editable: true,
                sortable: true,
                resizable: true,
                suppressMovable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                  const KEY_ENTER = 13;
                  const keyCode = params.event.keyCode;
                  const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                  return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                  return {
                    lastColumnCell: false,
                    items: this.props.availablePaymentGateways,
                    valueKeyName: 'userDefinedGatewayName',
                    optionKeyName: 'alpidePaymentGatewayId',
                    onSelect: (selectedValue, option) => {
                      obj.node.setDataValue(obj.colDef.field, selectedValue);
                      obj.node.data.paymentGatewayId = option.value;
                    },
                  }
                },
                cellRendererFramework: (params) => {
                    return <span>{params.node.data.paymentGatewayName}</span>
                },
              },
        ];

        const onGridReady = (params) => {
            this.gridApi = params.api;
        }

        return (
            <Fragment >
                            <div className='bank-info'>
                                {/* <Form.Item>
                                    <Row>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={10}>
                                            <FormattedMessage id='formHeading.text' />
                                             <span className="required">*</span> 
                                        </Col>
                                        <Col span={6}>
                                            <TextBox maxLength={45}
                                                style={{ width: '100%', padding: '5px' }}
                                                placeholder={this.props.intl.formatMessage({ id: 'formHeading.text' })}
                                                value={this.props.formHeading}
                                                onChange={(e) => {
                                                    this.props.updateState({ formHeading: e.target.value });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item>
                                    <Row>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={10}>
                                            <FormattedMessage id='formSubHeading.text' /> 
                                        </Col>
                                        <Col span={12}>
                                            <TextArea maxLength={1000}
                                                rows={1}
                                                style={{ width: '100%', padding: '5px' }}
                                                placeholder={this.props.intl.formatMessage({ id: 'formSubHeading.text' })}
                                                value={this.props.subHeading}
                                                onChange={(e) => {
                                                    this.props.updateState({ subHeading: e.target.value });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                              
                                <Form.Item>
                                    <Row>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={10}>
                                            <FormattedMessage id='formHeading.textColor' />
                                        </Col>
                                        <Col span={2}>
                                            <ColorPicker
                                                enableAlpha={false}
                                                color={this.props.textColor || '000000'}
                                                onChange={(obj) => {
                                                    this.props.updateState({ textColor: obj.color });
                                                }}
                                                mode="RGB"
                                            />
                                        </Col>
                                        <Col gutter={10} style={{ background: this.props.bgColor, color: this.props.textColor, paddingLeft: '20px', paddingRight: '20px' }}>
                                            <div style={{ fontSize: '15px' }}> {this.props.companyInfo.storeName} </div>
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item>
                                    <Row>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={10}>
                                            <FormattedMessage id='formHeading.bgColor' />
                                        </Col>
                                        <Col span={6}>
                                            <ColorPicker
                                                enableAlpha={false}
                                                color={this.props.bgColor || 'f8f8f8'}
                                                onChange={(obj) => {
                                                    this.props.updateState({ bgColor: obj.color });
                                                }}
                                                mode="RGB"
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>
                                <Form.Item>
                                    <Row>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={10}>
                                            <FormattedMessage id='modal.settings.remarksTemplate.footer' />
                                        </Col>
                                        <Col span={6}>
                                            <TextBox maxLength={45}
                                                style={{ width: '100%', padding: '5px' }}
                                                placeholder={this.props.intl.formatMessage({ id: 'modal.settings.remarksTemplate.footer' })}
                                                value={this.props.footer}
                                                onChange={(e) => {
                                                    this.props.updateState({ footer: e.target.value });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Item>

                                <Form.Item>
                                    <Row>
                                        <Col span={1}>
                                        </Col>
                                        <Col span={10}>
                                            <FormattedMessage id='registration.isSendEmail' />
                                        </Col>
                                        <Col span={2}>
                                            <Switch
                                                checked={(this.props.isSendEmail === 1)}
                                                onClick={(checked, e) => {
                                                    this.props.updateState({
                                                        isFormSetting: true, 
                                                        isSendEmail: checked ? 1 : 0,
                                                        emailDetailDrawerVisible: checked ? 1 : 0 })
                                                    
                                                }} />
                                        </Col>
                                        {this.props.emailDetails ? <Col span={1} > <Button icon={<MailOutlined />} onClick={() => {
                                            this.props.updateState({
                                                isFormSetting: true, 
                                                emailDetailDrawerVisible: true,
                                            })
                                        }}>
                                        </Button></Col> : ''
                                        }
                                    </Row>
                                </Form.Item> */}

                    {/* <div> <b> <FormattedMessage id='menuSetting.text' />
                        <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'menuSetting.helpMessage' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                    </b> </div> */}


                    {/* <Form.Item>
                        <Row>
                            <Col span={2}> </Col>
                            <Col span={4}><FormattedMessage id='donate.text' /></Col>
                            <Col span={7}>
                                <Switch
                                    checked={(this.props.isShowDonationMenu === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowDonationMenu: checked ? 1 : 0 })

                                    }} />
                            </Col>
                            <Col span={4}> <FormattedMessage id='feePayment.text' /> </Col>
                            <Col span={7}>
                                <Switch
                                    checked={(this.props.isShowFeePaymentMenu === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowFeePaymentMenu: checked ? 1 : 0 })

                                    }} />
                            </Col>
                        </Row>
                    </Form.Item>

                    <Form.Item>
                        <Row>
                            <Col span={2}> </Col>
                            <Col span={4}> <FormattedMessage id='openEvent.text' /> </Col>
                            <Col span={7}>
                                <Switch
                                    checked={(this.props.isShowOpenEventMenu === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowOpenEventMenu: checked ? 1 : 0 })

                                    }} />
                            </Col>
                            <Col span={4}> <FormattedMessage id='registration.text' /> </Col>
                            <Col span={7}>
                                <Switch
                                    checked={(this.props.isShowRegistrationMenu === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowRegistrationMenu: checked ? 1 : 0 })

                                    }} />
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={2}> </Col>
                            <Col span={4}> <FormattedMessage id='fundRaising.text' /> </Col>
                            <Col span={7}>
                                <Switch
                                    checked={(this.props.isShowFundRaising === 1)}
                                    onClick={(checked, e) => {
                                        this.props.updateState({ isShowFundRaising: checked ? 1 : 0 })

                                    }} />
                            </Col>
                        </Row>
                    </Form.Item> */}


                    <br />
                            <div className="ag-theme-balham kiosk-scroll"  >
                               
                        <div className='pb10'>
                            <b><FormattedMessage id='projectSetting.text' />
                                <Tooltip placement="top" title={this.props.intl.formatMessage({ id: 'projectSetting.helpMessage' })} trigger="click">
                                    <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                                </Tooltip>
                            </b>
                        </div>
                        { (this.props.projectMapedList && this.props.projectMapedList.length) ? 
                                    <CustomAgGrid
                                        columnDefs={columnDefs}
                                        rowData={this.props.projectMapedList || []}
                                        rowDragManaged={true}
                                        domLayout={"autoHeight"}
                                        animateRows={true}
                                        onGridSizeChanged={onFirstDataRendered}
                                        getRowHeight={getRowHeight}
                                        onGridReady={onGridReady}
                                        defaultColDef={{
                                            flex: 1,
                                            autoHeight: true,
                                            wrapText: true,
                                        }}
                                    >
                                    </CustomAgGrid>
                                    : ''
                                    }
                    </div>
                    <br />
                    <br />
                    <div> <FormattedMessage id='shareWebDonationPaymentUrl.link.description' /></div>
                    <br />
                    <div>
                        <Row>
                            <Col span={10}>
                                <Input
                                    ref={(textarea) => this.textArea = textarea}
                                    value={this.state.subdomainName + '/app/web-donation?token='+ encodeURIComponent(this.state.encrypted)}
                                />
                            </Col>
                            <Col span={2}>
                                <Button title='Copy Link' type="default" style={{ paddingLeft: '5px', paddingRight: '5px' }} onClick={() => this.copyCodeToClipboard()}>
                                    <i className="fa fa-clone"></i>
                                </Button>
                            </Col>
                        </Row>
                        
                        
                    </div>
                    <br />
                        
                <div className="clearfix"></div>

                </div>
{/* <br/> */}

            <div className="button-group-action">
                <div className="align-footer">
                    <Button onClick={() => {
                        this.props.hideModal();
                    }}
                        type="default"
                    >
                        <FormattedMessage id='button.close.label' defaultMessage='' />
                    </Button>
                  
                    <Button
                        type="primary"
                        onClick={() => {
                            saveSettings(this.props);
                        }}
                    >
                        <FormattedMessage id='button.save.label' defaultMessage='' />
                    </Button>
                </div>
            </div>

            </Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        donationSetting: state.donation.donationSetting,
        availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
        projectList: state.common.projectList,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
        subdomain: state.common.subdomain,
        // kioskDonationSetting: state.donation.kioskDonationSetting,
    }
}

export default  connect(mapStateToProps)(injectIntl(DonationSettingComponent));