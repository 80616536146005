import React from "react";
import {Row, Col, } from 'antd'
import {Dropdown} from "../../general/Dropdown";
import { FormattedMessage } from "react-intl";
import { TextBox } from "../../general/TextBox";

// const { TabPane } = Tabs;

const EducationType = (props) => {

    const { updateState, } = props;
    const rowGutter = [16, 16];

    return (
      <div className="step-details-box">
        <div className="heading">
          <FormattedMessage
            id="company.companySetup.educationType"
            defaultMessage=""
          />
        </div>

        <div className="step-form-body">
          <Dropdown
            items={[
              { id: "school", name: "Pre-School/School" },
              { id: "college", name: "College" },
              { id: "institution", name: "Institution" },
              { id: "weekendSchool", name: "Weekend School" },
            ]}
            placeholder="Education Type"
            valueKeyName="name"
            optionKeyName="name"
            onSelect={(val, option) => {
              props.updateState({
                educationType: option.key,
              });
            }}
          />

          <Row gutter={rowGutter} style={{marginTop: '10px'}}>
            <Col span={8} className="fw-600">
              System Name
            </Col>
            <Col span={16} className="fw-600">
              Organization Defined Name
            </Col>

            <Col span={8}>
              <div className="i-label">Department</div>
            </Col>
            <Col span={16}>
              <TextBox
                placeholder="eg. Department"
                type="text"
                value={props.department || ""}
                onChange={(e) => {
                  updateState({
                    department: e.target.value
                  });
                }}
              />
            </Col>

            <Col span={8}>
              <div className="i-label">Subject</div>
            </Col>
            <Col span={16}>
              <TextBox
                placeholder="e.g. Hindi, English, GK etc.."
                type="text"
                value={props.subject}
                onChange={(e) => {
                  updateState({
                    subject: e.target.value
                  });
                }}
              />
            </Col>

            <Col span={8}>
              <div className="i-label">Term</div>
            </Col>
            <Col span={16}>
              <TextBox
                placeholder="e.g. 1 Year Session..."
                type="text"
                value={props.term}
                onChange={(e) => {
                  updateState({
                    term: e.target.value,
                  });
                }}
              />
            </Col>

            <Col span={8}>
              <div className="i-label">Course</div>
            </Col>
            <Col span={16}>
              <TextBox
                placeholder="e.g. Play Wing, Pre-Nursery…"
                type="text"
                value={props.course}
                onChange={(e) => {
                  updateState({
                    course: e.target.value,
                  });
                }}
              />
            </Col>

            <Col span={8}>
              <div className="i-label">Section</div>
            </Col>
            <Col span={16}>
              <TextBox
                placeholder="e.g. Mighty Kids, Rainbow etc.."
                type="text"
                value={props.section}
                onChange={(e) => {
                  updateState({
                    section: e.target.value,
                  });
                }}
              />
            </Col>

            <Col span={8}>
              <div className="i-label">Class</div>
            </Col>
            <Col span={16}>
              <TextBox
                placeholder="e.g. Play Wing, Pre-Nursery…"
                type="text"
                value={props.class}
                onChange={(e) => {
                  updateState({
                      class: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          {/* <Tabs
            activeKey={(props && props.activeTabKey) || "1"}
            onChange={(key) => props.updateState({ activeTabKey: key })}
            type="line"
          >
            <TabPane tab="Pre-School" key="1">
              <Row gutter={rowGutter}>
                <Col span={8} className="fw-600">
                  System Name
                </Col>
                <Col span={16} className="fw-600">
                  Organization Defined Name
                </Col>

                <Col span={8}>
                  <div className="i-label">Department</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="eg. Program"
                    type="text"
                    value={preSchool.department || ""}
                    onChange={(e) => {
                      updateState({
                        preSchool: {
                          ...preSchool,
                          department: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Subject</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Hindi, English, GK etc.."
                    type="text"
                    value={preSchool.subject}
                    onChange={(e) => {
                      updateState({
                        preSchool: {
                          ...preSchool,
                          subject: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Term</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. 1 Year Session..."
                    type="text"
                    value={preSchool.term}
                    onChange={(e) => {
                      updateState({
                        preSchool: {
                          ...preSchool,
                          term: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Course</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Play Wing, Pre-Nursery…"
                    type="text"
                    value={preSchool.course}
                    onChange={(e) => {
                      updateState({
                        preSchool: {
                          ...preSchool,
                          course: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Section</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Mighty Kids, Rainbow etc.."
                    type="text"
                    value={preSchool.section}
                    onChange={(e) => {
                      updateState({
                        preSchool: {
                          ...preSchool,
                          section: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Class</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Play Wing, Pre-Nursery…"
                    type="text"
                    value={preSchool.class}
                    onChange={(e) => {
                      updateState({
                        preSchool: {
                          ...preSchool,
                          class: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="School" key="2">
              <Row gutter={rowGutter}>
                <Col span={8} className="fw-600">
                  System Name
                </Col>
                <Col span={16} className="fw-600">
                  Organization Defined Name
                </Col>

                <Col span={8}>
                  <div className="i-label">Department</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. age Elementary, Middle Level, High Level"
                    type="text"
                    value={school.department || ""}
                    onChange={(e) => {
                      updateState({
                        school: {
                          ...school,
                          department: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Subject</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Hindi, English, Maths etc."
                    type="text"
                    value={school.subject}
                    onChange={(e) => {
                      updateState({
                        school: {
                          ...school,
                          subject: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Term</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. 1 Year Session..."
                    type="text"
                    value={school.term}
                    onChange={(e) => {
                      updateState({
                        school: {
                          ...school,
                          term: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Course</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Class 1, Class 2, Class 3…"
                    type="text"
                    value={school.course}
                    onChange={(e) => {
                      updateState({
                        school: {
                          ...school,
                          course: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Section</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Commerce, Science, Arts etc.."
                    type="text"
                    value={school.section}
                    onChange={(e) => {
                      updateState({
                        school: {
                          ...school,
                          section: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Class</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Class 1, Class 2, Class 3…"
                    type="text"
                    value={school.class}
                    onChange={(e) => {
                      updateState({
                        school: {
                          ...school,
                          class: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="College" key="3">
              <Row gutter={rowGutter}>
                <Col span={8} className="fw-600">
                  System Name
                </Col>
                <Col span={16} className="fw-600">
                  Organization Defined Name
                </Col>

                <Col span={8}>
                  <div className="i-label">Department</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Management, Law etc…"
                    type="text"
                    value={college.department || ""}
                    onChange={(e) => {
                      updateState({
                        college: {
                          ...college,
                          department: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Term</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Sem 1, Sem 2, Sem 3 etc..."
                    type="text"
                    value={college.term}
                    onChange={(e) => {
                      updateState({
                        college: {
                          ...college,
                          term: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Subject</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Sub 1, Sub 2, Sub 3 etc.."
                    type="text"
                    value={college.subject}
                    onChange={(e) => {
                      updateState({
                        college: {
                          ...college,
                          subject: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Course</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Diploma, Ph.D, Graduate…"
                    type="text"
                    value={college.course}
                    onChange={(e) => {
                      updateState({
                        college: {
                          ...college,
                          course: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Section</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. A, B, C etc.."
                    type="text"
                    value={college.section}
                    onChange={(e) => {
                      updateState({
                        college: {
                          ...college,
                          section: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Class</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. BBA, MBA, MCA etc…"
                    type="text"
                    value={college.class}
                    onChange={(e) => {
                      updateState({
                        college: {
                          ...college,
                          class: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="Institution" key="4">
              <Row gutter={rowGutter}>
                <Col span={8} className="fw-600">
                  System Name
                </Col>
                <Col span={16} className="fw-600">
                  Organization Defined Name
                </Col>

                <Col span={8}>
                  <div className="i-label">Department</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="eg. Program"
                    type="text"
                    value={institution.department || ""}
                    onChange={(e) => {
                      updateState({
                        institution: {
                          ...institution,
                          department: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Subject</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Hindi, English, GK etc.."
                    type="text"
                    value={institution.subject}
                    onChange={(e) => {
                      updateState({
                        institution: {
                          ...institution,
                          subject: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Term</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. 1 Year Session..."
                    type="text"
                    value={institution.term}
                    onChange={(e) => {
                      updateState({
                        institution: {
                          ...institution,
                          term: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Course</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. age 1-2 yrs, 2-3 yrs…"
                    type="text"
                    value={institution.course}
                    onChange={(e) => {
                      updateState({
                        institution: {
                          ...institution,
                          course: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Section</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Mighty Kids, Rainbow etc..."
                    type="text"
                    value={institution.section}
                    onChange={(e) => {
                      updateState({
                        institution: {
                          ...institution,
                          section: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Class</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Play Wing, Pre-Nursery…"
                    type="text"
                    value={institution.class}
                    onChange={(e) => {
                      updateState({
                        institution: {
                          ...institution,
                          class: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="Weekend Classes" key="5">
              <Row gutter={rowGutter}>
                <Col span={8} className="fw-600">
                  System Name
                </Col>
                <Col span={16} className="fw-600">
                  Organization Defined Name
                </Col>

                <Col span={8}>
                  <div className="i-label">Department</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Weekend Class etc…"
                    type="text"
                    value={weekendSchool.department || ""}
                    onChange={(e) => {
                      updateState({
                        weekendSchool: {
                          ...weekendSchool,
                          department: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Subject</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Computer Prac., Quran Reading, GK etc.."
                    type="text"
                    value={weekendSchool.subject}
                    onChange={(e) => {
                      updateState({
                        weekendSchool: {
                          ...weekendSchool,
                          subject: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Term</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Certificate Classes..."
                    type="text"
                    value={weekendSchool.term}
                    onChange={(e) => {
                      updateState({
                        weekendSchool: {
                          ...weekendSchool,
                          term: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Course</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Weekend Classes…"
                    type="text"
                    value={weekendSchool.course}
                    onChange={(e) => {
                      updateState({
                        weekendSchool: {
                          ...weekendSchool,
                          course: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Section</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. A, B, C etc.."
                    type="text"
                    value={weekendSchool.section}
                    onChange={(e) => {
                      updateState({
                        weekendSchool: {
                          ...weekendSchool,
                          section: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col span={8}>
                  <div className="i-label">Class</div>
                </Col>
                <Col span={16}>
                  <TextBox
                    placeholder="e.g. Quran Basic, Islamic His…"
                    type="text"
                    value={weekendSchool.class}
                    onChange={(e) => {
                      updateState({
                        weekendSchool: {
                          ...weekendSchool,
                          class: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs> */}
        </div>
      </div>
    );
};

export default EducationType;