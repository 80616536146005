import React, { useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import "@ant-design/compatible/assets/index.css";
import { Drawer, Row, Col, DatePicker, Select, Checkbox } from "antd";
import { CustomButton } from "../../../general/CustomButton";
// import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { Dropdown } from "../../../general/Dropdown";
import { ErrorMsg } from "../../../general/ErrorMessage";
import { CONSTANTS } from "../../../../static/constants";
import {
  getMomentDateForUI,
  getCurrentDateForBackend,
  getInitialsFromString,
} from "../../../../utils";
import TextArea from "antd/lib/input/TextArea";
import AvatartIcon from "../../../../assets/images/avatar.png";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

function EmployeeAttendance(props) {
  const { allEmployees } = props;
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const handleEmployeeSelect = (selectedEmployeeIds) => {
    const temp = allEmployees.filter((obj) =>
      selectedEmployeeIds.includes(obj.relationshipEmployeeId)
    );
    setSelectedEmployees(temp);
  };

  const handleSelectAll = () => {
    if ((selectedEmployees || []).length !== (allEmployees || []).length) {
      setSelectedEmployees(allEmployees);
    } else {
      setSelectedEmployees([]);
      props.updateDrawerState({ attendanceList: null });
    }
  };

  const dropdownRender = (menu) => {
    return (
      <div className="custom-dropdown-menu">
        <Checkbox
          checked={
            (selectedEmployees || []).length === (allEmployees || []).length
          }
          onChange={handleSelectAll}
          style={{ padding: "10px 16px" }}
        >
          <b>Select All</b>
        </Checkbox>

        <Checkbox.Group
          value={(selectedEmployees || []).map(
            (obj) => obj.relationshipEmployeeId
          )}
          onChange={handleEmployeeSelect}
        >
          {(allEmployees || []).map((employee) => (
            <Checkbox
              key={`emp` + employee.relationshipEmployeeId}
              value={employee.relationshipEmployeeId}
            >
              {employee.logoAwsObjectKey ? (
                <img
                  src={employee.logoAwsObjectKey}
                  alt="USER"
                  style={{ width: "38px", borderRadius: "25px" }}
                  onError={(e)=>{
                    e.target.src = AvatartIcon;
                  }}
                />
              ) : (
                <div className="user-icon">
                  {getInitialsFromString(
                    employee.fullName ? employee.fullName : "U"
                  )}
                </div>
              )}
              <div className="custom-dropdown-detail ml10">
                <div style={{ fontWeight: "700" }}>{employee.fullName}</div>
                <div className="light-font" style={{ paddingLeft: "8px" }}>
                  {employee.jobTitleName}{" "}
                  {employee.empType ? `-${employee.empType}` : ""}
                </div>
              </div>
            </Checkbox>
          ))}
        </Checkbox.Group>
      </div>
    );
  };

  const closeDrawer = () => {
    props.updateHeaderState({
      employeeAttendance: {
        addEmployeeAttendenceDrawerVisible: false,
      },
    });
  };

  const handleSubmit = (props) => {
    props.updateDrawerState({ submittedOnce: true });
    if ((selectedEmployees || []).length === 0) {
      return;
    }
    let attendanceList = [];

    (selectedEmployees || []).forEach((employee) => {
      attendanceList.push({
        relationshipEmployeeId: employee.relationshipEmployeeId,
        attendanceStatus: employee.status || "Present",
      });
    });
    const payload = {
      relationshipId: props.relationshipId,
      attendanceList: attendanceList,
      checkinDate: getCurrentDateForBackend(
        props.attendanceDateIn || new Date()
      ),
      checkoutDate: getCurrentDateForBackend(
        props.attendanceDateOut || new Date()
      ),
      createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
    };

    props.pushModalToStack({
      modalBody: <FormattedMessage id="save.confirmation" defaultMessage="" />,
      handleSubmit: () => {
        props.saveAttendance(payload, props);
      },
    });
  };

  return (
    <Drawer
      title={
        <FormattedMessage
          id="sidebar.menuItem.attendance.employee"
          defaultMessage=" "
        />
      }
      width={720}
      keyboard={false}
      zIndex={1}
      destroyOnClose={true}
      onClose={closeDrawer}
      maskClosable={false}
      className="custom-drawer"
      visible={props.addEmployeeAttendenceDrawerVisible}
      afterVisibleChange={(visible) => {
        if (visible) {
          //props.initCalls();
        }
      }}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId="confirmation.submit"
            defaultMessage=""
            htmlType="submit"
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </>
      }
    >
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <div className="i-label">
            <FormattedMessage id="addItem.text.employee" defaultMessage="" />
            <span className="required">*</span>
          </div>
          <div style={{ position: "relative" }}>
            <Select
              mode="multiple"
              value={
                (selectedEmployees || []).map((obj) =>
                  getInitialsFromString(obj.firstName + obj.lastName)
                ) || ""
              }
              onChange={handleEmployeeSelect}
              style={{ width: "100%", height: "38px" }}
              placeholder="Select employees"
              optionLabelProp="label"
              dropdownRender={dropdownRender}
              maxTagCount="responsive"
              className="employee-select"
              maxTagTextLength={10}
              maxTagPlaceholder={"..."}
              tagRender={(props) => {
                const { label } = props;
                return (
                  <span style={{ display: "inline-block", marginRight: "6px" }}>
                    <span>{`${label}, `}</span>
                  </span>
                );
              }}
            />

            <ErrorMsg
              validator={() => {
                return (
                  !props.submittedOnce || (props.selectedEmployees || []).length
                );
              }}
              message={props.intl.formatMessage({
                id: "requiredField.missing.msg",
                defaultMessage: "",
              })}
            />
            <div
              onClick={() => {
                setSelectedEmployees([]);
                props.updateDrawerState({
                  attendanceList: null,
                });
              }}
              style={{ position: "absolute", top: "10px", right: "8px" }}
              className="cursor-pointer"
            >
              Reset
            </div>
          </div>
        </Col>

        <Col span={12}>
          <div className="i-label">Clock In</div>

          <DatePicker
            // style={{ width: '70%'}}
            showTime={{
              format: "HH:mm:ss",
            }}
            allowClear={false}
            format={format}
            key="attendanceDateIn"
            defaultValue={getMomentDateForUI({ date: new Date(), format })}
            value={props.attendanceDateIn}
            
            onChange={(selectedDate) => {
              props.updateState({
                attendanceDateIn: selectedDate,
              });
            }}
            size={0}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Clock Out</div>
          <DatePicker
            showTime={{
              format: "HH:mm:ss",
            }}
            allowClear={false}
            format={format}
            key="attendanceDateOut"
            defaultValue={getMomentDateForUI({ date: new Date(), format })}
            value={props.attendanceDateOut}
            disableDate={props.attendanceDateIn}
            onChange={(selectedDate) => {
              props.updateState({
                attendanceDateOut: selectedDate,
              });
            }}
            size={0}
          />
        </Col>

        <Col span={24}>
          <div className="i-label">Reason note for mannual entry</div>

          <TextArea
            onChange={(e) => {
              props.updateDrawerState({
                reasoneEntry: e.target.value,
              });
            }}
          />
        </Col>

        <Col span={24} className="table-col-drawer">
          <table>
            <thead>
              <tr>
                <th>Employee</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {(selectedEmployees || []).length ? (
                selectedEmployees.map((rowData, index) => {
                  return (
                    <tr key={index + "tb"}>
                      <td>
                        <div style={{ display: "flex" }}>
                          {rowData.logoAwsObjectKey ? (
                            <img
                              src={rowData.logoAwsObjectKey}
                              style={{ width: "38px", borderRadius: "25px"}}
                              alt="User"
                              onError={(e)=>{
                                e.target.src = AvatartIcon;
                              }}
                            />
                          ) : (
                            <div className="user-icon">
                              {getInitialsFromString(
                                rowData.fullName ? rowData.fullName : "U"
                              )}
                            </div>
                          )}
                          <div className="custom-dropdown-detail ml10">
                            <div style={{ fontWeight: "700" }}>
                              {rowData.fullName}
                            </div>
                            <div className="light-font">
                              {rowData.jobTitleName}{" "}
                              {rowData.empType ? `-${rowData.empType}` : ""}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <Dropdown
                          items={["Present", "Absent"]}
                          // defaultValue="Present"
                          value={rowData.status || "Present"}
                          onSelect={(selectedValue, optionObj) => {
                            let tempObj = selectedEmployees[index];
                            tempObj.status = selectedValue;
                            let tempArr = selectedEmployees;
                            tempArr[index] = tempObj;
                            setSelectedEmployees(tempArr);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="mt10">
                  Please select employees to mark attendance.
                </div>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </Drawer>
  );
}

export default injectIntl(EmployeeAttendance);
