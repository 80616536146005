import { Row, Col } from "antd";
import React, { useState } from "react";
import { Dropdown } from "../../../general/Dropdown";
import { SALES_COMPLAINT_CONSTANTS } from "../../../../static/constants";
import { CustomButton } from "../../../general/CustomButton";
import { FormattedMessage, injectIntl } from "react-intl";
import { getCurrentDateForBackend } from "../../../../utils";
import { ErrorMsg } from "../../../general/ErrorMessage";

const SalesComplaintStatus = (props) => {
    const [statusName, setStatusName] = useState("");
    const [submitted, setSubmitted] = useState(false)
    return (

        <>
            <div className="create-new-control pb10" style={{ height: "125px" }}>
                <Row gutter={[16, 24]}>
                    <Col span={6} className='padding-top-7'>
                        <span className="text-input-label">
                            Status
                            <span className="required">*</span>
                        </span>
                    </Col>
                    <Col span={18} className='userStatus'>
                        <Dropdown
                            items={SALES_COMPLAINT_CONSTANTS.STATUS_TYPES}
                            placeholder={props.intl.formatMessage({
                                id: "common.select.placeholder",
                                defaultMessage: "",
                            })}
                            value={statusName}
                            onSelect={(selectedValue, option) => {
                                setStatusName(option.name);
                            }} />
                        <ErrorMsg
                            validator={() => { return !submitted || statusName }}
                            message={<FormattedMessage id='requiredField.missing.msg' />}
                        />
                    </Col>
                </Row>
            </div><Row className='modal-footer'>
                <Col span={21}>
                    <CustomButton
                        intlId="confirmation.cancel"
                        defaultMessage=""
                        type="default"
                        key={0}
                        style={{ marginRight: 8 }}
                        className="back-white"
                        onClick={() => {
                            props.hideModal();
                        }} />
                </Col>
                <Col span={3} style={{ marginLeft: "-14px" }}>
                    <CustomButton
                        intlId="confirmation.submit"
                        defaultMessage=""
                        htmlType="submit"
                        key={1}
                        onClick={() => {
                            setSubmitted({ submitted: true });
                            if (!statusName) {
                                return
                            }
                            const payload = {
                                relationshipId: (props.companyInfo || {}).relationshipId,
                                salesComplaintId: props.rowData.salesComplaintId,
                                // statusName: statusName || "",
                                ...props.rowData,
                                complaintClosedDate: getCurrentDateForBackend(new Date())

                            };
                            const modalData = {
                                modalBody: (
                                    <FormattedMessage id="commons.create.popup.confimation.msg" defaultMessage="" />
                                ),
                                handleSubmit: () => {
                                    props.createOrUpdateComplaint({ ...payload, statusName: statusName }, props);
                                    props.popModalFromStack();
                                },
                            };
                            props.pushModalToStack(modalData);
                        }} />
                </Col>
            </Row>
        </>
    )
}

export default injectIntl(SalesComplaintStatus);