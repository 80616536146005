import { Button, Col, Row, Upload } from "antd";
import React, { Fragment, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { TextBox } from "../../../../general/TextBox";

const UploadBanner = (props) => {
  const [fileList, setFileList] = useState([]);

  const handleAddBanner = () => {
    props.updateState({
      settings: {
        bannerUploadList: fileList,
      },
    });
  };
  return (
    <Fragment>
      <div className="upload-banner-wrapper">
        <Row gutter={[16, 16]}>
          <Col span="24">
            <div className="model-upload-section">
              {fileList.length > 0 ? (
                <div
                  className="img-pre"
                  style={{
                    backgroundImage: `url(${URL.createObjectURL(fileList[0])})`,
                    backgroundPosition: "center",
                  }}
                >
                  <div className="replace-banner">
                    <Upload
                      fileList={fileList}
                      beforeUpload={(file) => {
                        setFileList([file]);
                        return false;
                      }}
                    >
                      <Button className="replace-banner-btn">
                        Replace banner
                      </Button>
                    </Upload>
                  </div>
                </div>
              ) : (
                <div className="inner-wrapper">
                  <Upload
                    fileList={fileList}
                    beforeUpload={(file) => {
                      // Allow only a single file upload, you can adjust this as needed
                      setFileList([file]);
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                  </Upload>
                  <p>Recommended size (360px*324px)</p>
                </div>
              )}
            </div>
          </Col>
          <Col span="24">
            <div className="i-label">Link banner To (Optional)</div>
            <TextBox
              type="text"
              value={""}
              onChange={(e) => {}}
              placeholder={"https://example.com"}
            />
          </Col>

          <Col span={24}>
            <div className="update-data">
              <Button type="primary" onClick={handleAddBanner}>
                Update
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default UploadBanner;
