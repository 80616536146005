export const lang = {
  'button.save.label': 'Sauvegarder',
  'button.cancel.label': 'Annuler',
  'pagination.text.total': 'Totale',
  'pagination.text.items': 'articles',
  'login.text': 'Connectez-vous en utilisant vos informations didentification',
  'login.text-signup': 'Inscription gratuite',
  'login.submit': 'Se connecter',
  'login.email.placeholder': 'Email',
  'login.forgot': 'Mot de passe oublié?',
  'login.password.placeholder': 'Mot de passe',
  'login.remember': 'Souviens-toi de moi',
  'signup.text': 'S\'inscrire',
  'signup.tab.contact': 'Contact',
  'signup.tab.address': 'Adresse',
  'signup.email.text': 'Email',
  'signup.currencyCode.text': 'Code de devise',
  'signup.country.text': 'Pays',
  'signup.password.text': 'Mot de passe',
  'signup.companyName.text': 'Nom de la compagnie',
  'signup.companyName.placeholder': 'Nom de la compagnie',
  'signup.country.placeholder': 'Nom du pays',
  'signup.passwordRepeat.text': 'Mot de passe (répéter)',
  'signup.repeatPassword.placeholder': 'Répéter le mot de passe',
  'signup.firstname.placeholder': 'Prénom',
  'signup.lastname.placeholder': 'Nom de famille',
  'signup.streetname.placeholder': 'Nom de rue',
  'signup.street(contd).placeholder': 'Rue (suite)',
  'signup.city.placeholder': 'Ville',
  'signup.postal/zipcode.placeholder': 'Code postal / Zip',
  'signup.statename.placeholder': 'Nom d\'état',
  'signup.btn.cancel': 'Annuler',
  'signup.btn.create': 'Créer un compte',
  'signup.workPhone.placeholder': 'Téléphone de travail',
  'signup.validation.email': 'L\'email est requis',
  'signup.validation.password': 'Mot de passe requis',
  'signup.validation.confirmPassword': 'Confirmer le mot de passe est requis',
  'signup.validation.passwordMismatch': 'Le mot de passe ne correspond pas',
  'signup.validation.company': 'Le nom de l\'entreprise est obligatoire',
  'forgot.headingText': 'Réinitialisation du mot de passe',
  'forgot.text': 'Saisissez votre adresse e-mail que vous avez utilisée pour enregistrer votre compte. Nous vous enverrons un e-mail avec votre nom d\'utilisateur et votre mot de passe.',
  'forgot.btn.back': 'Retourner',
  'forgot.btn.submit': 'Envoyer un e-mail',
  'forgot.email.placeholder': 'Entrez votre email enregistré',
  'confirmation.submit': 'Soumettre',
  'confirmation.cancel': 'Annuler',
  'signup.confirmation.country': 'Votre pays est - <strong>{value}</strong>',
  'signup.confirmation.text': 'Voulez-vous vraiment créer un compte?',
  'signup.confirmation.title': 'Confirmation',

  'drawer.inventory.title': 'Informations d\'inventaire',
  'drawer.inventory.tabNames.productInfo': 'Information sur le produit',
  'drawer.inventory.tabNames.productDetails': 'détails du produit',
  'drawer.inventory.tabNames.finance': 'La finance',
  'drawer.inventory.tabNames.warehouse': 'Entrepôt',
  'drawer.inventory.tabNames.alerts': 'Alertes',
  'drawer.inventory.tabNames.image': 'Image',

  'drawer.inventory.productName': 'Nom du produit',
  'drawer.inventory.description': 'La description',
  'drawer.inventory.category': 'Catégorie',
  'drawer.inventory.brand': 'Marque',
  'drawer.inventory.partNo': 'No de pièce',
  'drawer.inventory.manufacturer': 'Fabricant',
  'drawer.inventory.sku': 'SKU',
  'drawer.inventory.systemSku': 'Utiliser la référence système',
  'drawer.inventory.customerStockNo': 'Numéro de stock',
  'drawer.inventory.salesTarget': 'Objectif de ventes',
  'drawer.inventory.publishToB2BSite': 'Publier sur le site B2B',
  'drawer.inventory.variantSwitch': 'Ajouter des variantes au produit',
  'drawer.inventory.purchasedPrice': 'Prix acheté',
  'drawer.inventory.wholeSalePrice': 'Prix de gros',
  'drawer.inventory.retailPrice': 'Prix en détail',
  'drawer.inventory.barCode': 'Code à barre',
  'drawer.inventory.currentStock': 'Stock actuel',
  'drawer.inventory.uom': 'UOM',
  'drawer.inventory.attribute': 'Attribut',
  'drawer.inventory.attributeValue': 'Valeur',

  'drawer.inventory.warehouse': 'Entrepôt',
  'drawer.inventory.warehouse.placeholder': 'Veuillez sélectionner l\'entrepôt',

  'drawer.inventory.productName.placeholder': 'Nom du produit',
  'drawer.inventory.description.placeholder': 'Description du produit',
  'drawer.inventory.category.placeholder': 'Sélectionnez la catégorie de produit',
  'drawer.inventory.brand.placeholder': 'Sélectionnez la marque',
  'drawer.inventory.partNo.placeholder': 'N ° de pièce',
  'drawer.inventory.manufacturer.placeholder': 'Sélectionnez le fabricant',
  'drawer.inventory.sku.placeholder': 'Entrez SKU',
  'drawer.inventory.customerStockNo.placeholder': 'Stock client #',
  'drawer.inventory.salesTarget.placeholder': 'Objectif de ventes',
  'drawer.inventory.purchasedPrice.placeholder': 'Entrer le prix acheté',
  'drawer.inventory.wholeSalePrice.placeholder': 'Entrez le prix de vente',
  'drawer.inventory.retailPrice.placeholder': 'Entrez le prix de détail',
  'drawer.inventory.barCode.placeholder': 'Entrez le code à barres associé à cet article',
  'drawer.inventory.currentStock.placeholder': 'Entrer le stock actuel',
  'drawer.inventory.uom.placeholder': 'Sélectionnez UOM',
  'drawer.inventory.attribute.placeholder': 'Sélectionnez un attribut',
  'drawer.inventory.attributeValue.placeholder': 'Entrez la valeur de l\'attribut',

  'drawer.inventory.productDetails.addVariantToProduct.button': 'Ajouter une variante au produit',
  'drawer.inventory.productDetails.reset.button': 'Réinitialiser la variante',
  'drawer.inventory.productDetails.gridHeader.variant': 'Une variante',
  'drawer.inventory.productDetails.gridHeader.purchasedPrice': 'Prix acheté',
  'drawer.inventory.productDetails.gridHeader.retailPrice': 'Prix en détail',
  'drawer.inventory.productDetails.gridHeader.wholeSalePrice': 'Prix de gros',
  'drawer.inventory.productDetails.gridHeader.currentStock': 'Stock actuel',
  'drawer.inventory.productDetails.gridHeader.uom': 'UOM',
  'drawer.inventory.productDetails.gridHeader.sku': 'SKU',

  'drawer.inventory.finance.popuplateTaxOnTransactions': 'Remplir la taxe sur les transactions',
  'drawer.inventory.finance.popuplateTaxOnTransactions.desc': 'Lorsqu\'elle est cochée, les taux de taxe seront renseignés sur SO, facture, bon de commande, etc.',
  'drawer.inventory.finance.HSNCode': 'Code HSN',
  'drawer.inventory.finance.HSNCode.placeholder': 'Sélectionnez un code HSN',
  'drawer.inventory.finance.purchaseLedgerAccount': 'Compte d\'achat',
  'drawer.inventory.finance.purchaseLedgerAccount.placeholder': 'Sélectionner un compte d\'achat',
  'drawer.inventory.finance.salesLedgerAccount': 'Compte du grand livre des ventes',
  'drawer.inventory.finance.salesLedgerAccount.placeholder': 'Sélectionner le compte du grand livre des ventes',
  'drawer.inventory.finance.tax': 'Impôt',
  'drawer.inventory.finance.tax.placeholder': 'Sélectionnez le taux de taxe',
  'drawer.inventory.image.productImg': 'Sélectionnez l\'image du produit',
  'drawer.inventory.image.productImg.upload': 'Télécharger',

  'drawer.inventory.alert.lowStockLevel': 'Niveau de stock bas',
  'drawer.inventory.alert.lowStockLevel.desc': 'Entrez le nombre d\'articles restant jusqu\'à ce que vous commenciez à recevoir une alerte de stock bas',
  'drawer.inventory.alert.lowStockLevel.placeholder': 'Entrez le niveau de stock bas',
  'drawer.inventory.alert.velocityAlert': 'Alerte de vitesse',
  'drawer.inventory.alert.velocityAlert.desc': 'Alertez les pics de ventes inattendus',
  'drawer.inventory.alert.reorderLevel': 'Niveau de réapprovisionnement',
  'drawer.inventory.alert.reorderLevel.desc': 'Entrez le nombre d\'articles restant jusqu\'à ce que vous soyez invité à réorganiser',
  'drawer.inventory.alert.reorderLevel.placeholder': 'Entrez le niveau de réorganisation',
  'drawer.inventory.alert.outOfStockAlert': 'Alerte rupture de stock',
  'drawer.inventory.alert.outOfStockAlert.desc': 'Alerte lorsque le produit est en rupture de stock',
  'drawer.inventory.alert.aboveThresholdLevel': 'Au-dessus du seuil',
  'drawer.inventory.alert.aboveThresholdLevel.desc': 'Avertir lorsque le stock projeté est supérieur au niveau de stock maximum',
  'drawer.inventory.alert.aboveThresholdLevel.placeholder': 'Entrez au-dessus du seuil',

  'drawer.inventory.confirmation.title': 'Confirmation',
  'drawer.inventory.confirmation.text': 'Êtes-vous sûr de vouloir créer un produit?',
  'drawer.inventory.validation.productName': 'Product Name is required.',
  'drawer.inventory.validation.productNameLenght': 'Le nom du produit est requis.',
  'drawer.inventory.validation.duplicateAttribute': 'L\'attribut est déjà sélectionné.',

  'drawer.customer.title': 'Informations client',
  'drawer.customer.companyName': 'Nom de la compagnie',
  'drawer.customer.companyName.required': 'Le nom de l\'entreprise est obligatoire.',
  'drawer.customer.companyName.placeholder': 'Entrez le nom de l\'entreprise',
  'drawer.customer.paymentTerm': 'Conditions de paiement',
  'drawer.customer.paymentTerm.placeholder': 'Sélectionnez le délai de paiement',
  'drawer.customer.customerType': 'Type de client',
  'drawer.customer.customerType.placeholder': 'Sélectionnez le type de client',
  'drawer.customer.websiteUrl': 'URL de site web',
  'drawer.customer.websiteUrl.placeholder': 'Entrez l\'URL du site Web',
  'drawer.customer.customerSource': 'Source du client',
  'drawer.customer.customerSource.placeholder': 'Sélectionnez la source du client',
  'drawer.customer.rating': 'Évaluez votre entreprise',
  'drawer.customer.rating.placeholder': 'Sélectionner la note de l\'entreprise',
  'drawer.customer.industry': 'Industrie',
  'drawer.customer.industry.placeholder': 'Sélectionnez l\'industrie',
  'drawer.customer.relationshipSince': 'Relation depuis',
  'drawer.customer.relationshipSince.placeholder': 'Relation depuis la date',
  'drawer.customer.address.streetName.placeholder': 'Nom de rue',
  'drawer.customer.address.streetContd.placeholder': 'Rue (suite)',
  'drawer.customer.address.city.placeholder': 'Ville',
  'drawer.customer.address.state.placeholder': 'Etat',
  'drawer.customer.address.country.placeholder': 'Choisissez le pays',
  'drawer.customer.address.zipCode.placeholder': 'Zip / code postal',
  'drawer.customer.billingAddress': 'Adresse de facturation',
  'drawer.customer.shippingAndBillingAddressSame': 'Shipping address same as billing',
  'drawer.customer.contact.title.placeholder': 'Sélectionnez le titre',
  'drawer.customer.contact.firstName.placeholder': 'Prénom',
  'drawer.customer.contact.middleName.placeholder': 'Deuxième nom',
  'drawer.customer.contact.lastName.placeholder': 'Nom de famille',
  'drawer.customer.contact.callName.placeholder': 'Nom d\'appel',
  'drawer.customer.contact.phone.placeholder': 'Téléphone',
  'drawer.customer.contact.mobile.placeholder': 'Mobile',
  'drawer.customer.contact.faxNumber.placeholder': 'Numéro de fax',
  'drawer.customer.contact.email.placeholder': 'Email',
  'drawer.customer.termName': 'Nom du terme',
  'drawer.customer.days': 'Journées',
  'drawer.customer.description': 'La description',
  'drawer.customer.defaultPayment': 'Paiement par défaut',
  'drawer.customer.taxInfo.gstMechanism': 'Mécanisme de TPS',
  'drawer.customer.taxInfo.gstMechanism.placeholder': 'Select GST Mechanism',
  'drawer.customer.taxInfo.sez': 'Located in SEZ',
  'drawer.customer.taxInfo.companyLogo': 'Sélectionnez le logo de l\'entreprise',
  'drawer.customer.taxInfo.companyLogo.upload': ' Télécharger',
  'drawer.customer.confirmation.title': 'Confirmation',
  'drawer.customer.confirmation.text': 'Voulez-vous vraiment créer un client?',
  'drawer.customer.validation.companyName': 'Le nom de l\'entreprise est obligatoire.',
  'drawer.customer.validation.billingStreet1Name': 'Le nom de la rue est requis.',
  'drawer.customer.validation.billingCityName': 'Le nom de la ville est requis.',
  'drawer.customer.validation.billingStateName': 'Le nom de l\'État est requis.',
  'drawer.customer.validation.shippingStreet1Name': 'Le nom de la rue est requis.',
  'drawer.customer.validation.shippingCityName': 'Le nom de la ville est requis.',
  'drawer.customer.validation.shippingStateName': 'Le nom de l\'État est requis.',
  'drawer.supplier.title': 'Informations sur le fournisseur',
  'drawer.supplier.companyName': 'Nom du fournisseur',
  'drawer.supplier.companyName.placeholder': 'Entrez le nom du fournisseur',
  'drawer.supplier.paymentTerm': 'Conditions de paiement',
  'drawer.supplier.paymentTerm.placeholder': 'Sélectionnez le délai de paiement',
  'drawer.supplier.supplierType': 'Type de fournisseur',
  'drawer.supplier.supplierType.placeholder': 'Sélectionnez le type de fournisseur',
  'drawer.supplier.websiteUrl': 'URL de site web',
  'drawer.supplier.websiteUrl.placeholder': 'Entrez l\'URL du site Web',
  'drawer.supplier.supplierSource': 'Source du fournisseur',
  'drawer.supplier.supplierSource.placeholder': 'Sélectionnez la source du fournisseur',
  'drawer.supplier.rating.placeholder': 'Sélectionner la note de l\'entreprise',
  'drawer.supplier.industry': 'Industrie',
  'drawer.supplier.industry.placeholder': 'Sélectionnez l\'industrie',
  'drawer.supplier.relationshipSince': 'Relation depuis',
  'drawer.supplier.relationshipSince.placeholder': 'Relation depuis la date',
  'drawer.supplier.address.streetName.placeholder': 'Nom de rue',
  'drawer.supplier.address.streetContd.placeholder': 'Rue (suite)',
  'drawer.supplier.address.city.placeholder': 'Ville',
  'drawer.supplier.address.state.placeholder': 'Etat',
  'drawer.supplier.address.country.placeholder': 'Choisissez le pays',
  'drawer.supplier.address.zipCode.placeholder': 'Zip / code postal',
  'drawer.supplier.billingAddress': 'Adresse de facturation',
  'drawer.supplier.shippingAndBillingAddressSame': 'Adresse de livraison identique à la facturation',
  'drawer.supplier.contact.title.placeholder': 'Sélectionnez le titre',
  'drawer.supplier.contact.firstName.placeholder': 'Prénom',
  'drawer.supplier.contact.middleName.placeholder': 'Middle Name',
  'drawer.supplier.contact.lastName.placeholder': 'Deuxième nom',
  'drawer.supplier.contact.callName.placeholder': 'Nom d\'appel',
  'drawer.supplier.contact.phone.placeholder': 'Téléphone',
  'drawer.supplier.contact.mobile.placeholder': 'Mobile',
  'drawer.supplier.contact.faxNumber.placeholder': 'Numéro de fax',
  'drawer.supplier.contact.email.placeholder': 'Email',
  'drawer.supplier.taxInfo.gstMechanism': 'GST Mechanism',
  'drawer.supplier.taxInfo.gstMechanism.placeholder': 'Select GST Mechanism',
  'drawer.supplier.taxInfo.sez': 'Located in SEZ',
  'drawer.supplier.taxInfo.companyLogo': 'Sélectionnez le logo de l\'entreprise',
  'drawer.supplier.taxInfo.companyLogo.upload': ' Télécharger',
  'drawer.supplier.confirmation.title': 'Confirmation',
  'drawer.supplier.confirmation.text': 'Voulez-vous vraiment créer un fournisseur?',
  'drawer.supplier.validation.companyName': 'Le nom de l\'entreprise est obligatoire.',
  'drawer.supplier.validation.billingStreet1Name': 'Le nom de la rue est requis.',
  'drawer.supplier.validation.billingCityName': 'Le nom de la ville est requis.',
  'drawer.supplier.validation.billingStateName': 'Le nom de l\'État est requis.',
  'drawer.supplier.validation.shippingStreet1Name': 'Le nom de la rue est requis.',
  'drawer.supplier.validation.shippingCityName': 'Le nom de la ville est requis.',
  'drawer.supplier.validation.shippingStateName': 'Le nom de l\'État est requis.',
  'drawer.ledger.title': 'Informations sur le grand livre',
  'add': ' Ajouter',
  'new': ' Nouveau',
  'yes': 'Oui',
  'no': 'Non',
  'addRow': 'Ajouter une rangée',
  'addUpdateData': 'Voulez-vous vraiment créer ou mettre à jour l\'adresse?',
  'createSalesOrder': 'Voulez-vous vraiment créer une commande client?',
  'deleteItem.confirmation': 'Voulez-vous vraiment supprimer {value}?',
  'updateItem.confirmation': 'Voulez-vous vraiment mettre à jour {value}?',
  'addItem.confirmation': 'Voulez-vous vraiment ajouter {value} ',
  'addItem.text.paymentTerm': 'Modalités de paiement',
  'addItem.text.customerSource': 'Source du client',
  'addItem.text.customerType': 'Type de client',
  'addItem.text.industry': 'Industrie',
  'addItem.text.supplierSource': 'Source du fournisseur',
  'addItem.text.supplierType': 'Type de fournisseur',
  'addItem.text.soSource': 'Source de commande client',
  'addItem.text.productCategory': 'catégorie de produit',
  'addItem.text.productBrand': 'Marque de produit',
  'addItem.text.productManufacturer': 'Fabricant du produit',
  'addItem.text.uom': 'UOM',
  'addItem.text.department': 'département',
  'addItem.text.warehouse': 'Entrepôt',
  'addItem.text.project': 'Projet',
  'addItem.text.billingAddress': 'Adresse de facturation',
  'addItem.text.shippingAddress': 'Adresse de livraison',
  'addItem.text.relationshipBillingAddress': 'Adresse de facturation de la relation',
  'addItem.text.relationshipShippingAddress': 'Adresse d\'expédition',
  'addItem.text.employee': 'Employee',
  'addItem.text.priceList': 'Liste de prix',
  'addItem.text.documentName': 'Nom du document',
  'addItem.text.newTax': 'Nouvelle taxe',
  'addItem.text.attribute': 'Attribut',
  'addItem.text.rfqStatus': 'Statut RFQ',
  'addItem.text.rfqPriceCode': 'Code de prix RFQ',
  'addItem.text.salutation': 'Salutation',
  'addItem.text.activityStatus': 'Statut de l\'activité',
  'addItem.text.activityPriority': 'Priorité d\'activité',
  'addItem.text.activityType': 'Type d\'activité',
  'salesInvoice.cashSale.checkbox.text': ' Vente au comptant',
  'salesInvoice.pricelist.placeholder': 'Sélectionnez la liste de prix',
  'salesInvoice.retailPrice.placeholder': 'Appliquer le prix de détail',
  'salesInvoice.priceType.placeholder': 'Sélectionnez le type de prix',
  'salesInvoice.customer.placeholder': 'Sélectionnez un client',
  'salesInvoice.billingAddress.placeholder': 'Selectionner l\'adresse de facturation',
  'salesInvoice.currenctFormat.placeholder': 'Sélectionnez le format monétaire',
  'salesInvoice.project.placeholder': 'Sélectionnez des projets',
  'salesInvoice.taxName.placeholder': 'Sélectionnez le nom de la taxe',
  'salesInvoice.expense.placeholder': 'Sélectionnez les dépenses',
  'drawer.invoice.dropdown.placeholder': 'Sélectionnez la liste de prix',
  'drawer.invoice.cashPurchase': 'Achat au comptant',
  'drawer.invoice.invoiceDate': 'Date de facturation',
  'drawer.invoice.invoiceDueDate': 'Échéance de la facture',
  'drawer.invoice.projectDropdown': 'Réf du projet',
  'drawer.invoice.dropdown.taxName': 'Nom de taxe',
  'drawer.invoice.dropdown.expense': 'Frais',
  'warehouse.dropdown.placeholder': 'Sélectionner un entrepôt',
  'project.label': 'Projet',
  'salesOrder.creation.date': 'Date SO',
  'sidebar.menuItem.dashboard': 'Tableau de bord',
  'sidebar.menuItem.purchase': 'Achat',
  'sidebar.menuItem.purchaseRequest': 'Demande d\'achat',
  'sidebar.menuItem.RFQ': 'RFQ',
  'sidebar.menuItem.purchaseOrder': 'Bon de commande',
  'sidebar.menuItem.purchaseInvoice': 'Facture d\'achat',
  'sidebar.menuItem.payment': 'Paiement',
  'sidebar.menuItem.debitMemo': 'Note de débit',
  'sidebar.menuItem.expense': 'Frais',
  'sidebar.menuItem.sales': 'Ventes',
  'sidebar.menuItem.salesQuote': 'Devis de vente',
  'sidebar.menuItem.salesOrder': 'Commande client',
  'sidebar.menuItem.salesInvoice': 'Facture de vente',
  'sidebar.menuItem.customerPayment': 'Paiement',
  'sidebar.menuItem.creditMemo': 'Note de crédit',
  'sidebar.menuItem.customerExpense': 'Frais',
  'sidebar.menuItem.customers': 'Les clients',
  'sidebar.menuItem.suppliers': 'Fournisseurs',
  'sidebar.menuItem.inventory': 'Inventaire',
  'sidebar.menuItem.products': 'Des produits',
  'sidebar.menuItem.warehouse': 'Entrepôt',
  'sidebar.menuItem.myWarehouse': 'Mon entrepôt',
  'sidebar.menuItem.stockSummary': 'Résumé du stock',
  'sidebar.menuItem.stockAdjustment': 'Ajustement des stocks',
  'sidebar.menuItem.agedStock': 'Stock vieilli',
  'sidebar.menuItem.productPrice': 'Prix du produit',
  'sidebar.menuItem.priceList': 'Liste de prix',
  'sidebar.menuItem.extras': 'Suppléments',
  'sidebar.menuItem.category': 'Catégorie',
  'sidebar.menuItem.manufactures': 'Catégorie',
  'sidebar.menuItem.brands': 'Marques',
  'sidebar.menuItem.uom': 'UOM',
  'sidebar.menuItem.finance': 'La finance',
  'sidebar.menuItem.leadgerAccounts': 'Comptes de grand livre',
  'sidebar.menuItem.balanceSheet': 'Bilan',
  'sidebar.menuItem.profitLoss': 'Perte de profit',
  'sidebar.menuItem.trialBalance': 'Balance de vérification',
  'sidebar.menuItem.openingBalance': 'Solde d\'ouverture',
  'sidebar.menuItem.journals': 'Journaux',
  'sidebar.menuItem.businessExpense': 'Dépenses d\'affaires',
  'sidebar.menuItem.bankReconciliation': 'Rapprochement bancaire',
  'sidebar.menuItem.taxes': 'Les taxes',
  'sidebar.menuItem.financialYears': 'Exercices',
  'sidebar.menuItem.costCenter': 'Centre de coûts',
  'sidebar.menuItem.reports': 'Rapports',
  'sidebar.menuItem.settings': 'Paramètres',
  'sidebar.menuItem.lockScreen': 'Écran verrouillé',
  'sidebar.menuItem.integrations': 'Intégrations',
  'sidebar.menuItem.salesChannels': 'Canaux de vente',
  'sidebar.menuItem.publicProducts': 'Produits publics',
  'sidebar.menuItem.payments': 'Paiements',
  'sidebar.menuItem.shipping': 'livraison',
  'sidebar.menuItem.accounting': 'Comptabilité',
  'header.menuItem.customerHead.label': 'Client',
  'header.menuItem.customer.label': 'Customer',
  'header.menuItem.salesQuote.label': 'Devis de vente',
  'header.menuItem.salesOrder.label': 'Commande client',
  'header.menuItem.salesInvoice.label': 'Facture de vente',
  'header.menuItem.creditMemo.label': 'Note de crédit',
  'header.menuItem.receivePayment.label': 'Paiement reçu',
  'header.menuItem.supplierHead.label': 'Fournisseur',
  'header.menuItem.supplier.label': 'Fournisseur',
  'header.menuItem.rfq.label': 'RFQ',
  'header.menuItem.purchaseRequest.label': 'Demande d\'achat',
  'header.menuItem.purchaseOrder.label': 'Bon de commande',
  'header.menuItem.purchaseInvoice.label': 'Facture d\'achat',
  'header.menuItem.debitMemo.label': 'Note de débit',
  'header.menuItem.payInvoice.label': 'Payer sa commande',
  'header.menuItem.inventoryHead.label': 'Inventaire',
  'header.menuItem.inventory.label': 'Élément d\'inventaire',
  'header.menuItem.priceList.label': 'Liste de prix',
  'header.menuItem.category.label': 'Catégorie',
  'header.menuItem.uom.label': 'UOM',
  'header.menuItem.brands.label': 'Marques',
  'header.menuItem.manufacturer.label': 'Fabricant',
  'header.menuItem.warehouse.label': 'Entrepôt',
  'header.menuItem.financeHead.label': 'La finance',
  'header.menuItem.ledgerAccount.label': 'Compte de grand livre',
  'header.menuItem.costCenter.label': 'Centre de coûts',
  'header.menuItem.tax.label': 'Impôt',
  'header.menuItem.journal.label': 'Journal',

  'customer.listing.tiles.tatalOrders.label': 'TOTAL DES COMMANDES',
  'customer.listing.tiles.openSalesOrder.label': 'COMMANDE DE VENTE OUVERTE',
  'customer.listing.tiles.unpaidBalance.label': 'SOLDE NON PAYÉ',
  'customer.listing.tiles.salesRevenue.label': 'REVENU DE VENTE',
  'customer.listing.header.customerDetails': 'Détails du client',
  'customer.listing.header.contact': 'Contact',
  'customer.listing.header.dueAmount': 'Montant dû',
  'customer.listing.header.openOrder': 'Commande ouverte',
  'customer.listing.header.unpaidInvoices': 'Factures impayées',
  'customer.listing.header.paidInvoices': 'Factures payées',
  'customer.listing.header.paymentTerm': 'Conditions de paiement',
  'customer.listing.header.action': 'action',
  'customer.listing.button.newCustomer.level': 'Nouveau client',
  'customer.listing.importCustomer.modal.title': 'Importer un client',
  'customer.listing.buttonMenu.importCustomer': 'Importer un client',

  'customer.listing.totalOrder.header.soNumber': 'Numéro SO',
  'customer.listing.totalOrder.header.soDate': 'Date SO',
  'customer.listing.totalOrder.header.soAmount': 'Montant SO',
  'customer.listing.totalOrder.header.status': 'Statut',
  'customer.listing.totalOrder.header.priority': 'Priorité',
  'customer.listing.totalOrder.header.soPerson': 'Personne SO',

  'customer.listing.openOrder.header.soNumber': 'Numéro SO',
  'customer.listing.openOrder.header.soDate': 'Date SO',
  'customer.listing.openOrder.header.soAmount': 'Montant SO',
  'customer.listing.openOrder.header.status': 'Statut',
  'customer.listing.openOrder.header.priority': 'Priorité',
  'customer.listing.openOrder.header.soPerson': 'Personne SO',

  'customer.listing.unPaidInvoice.header.invoiceNumber': 'Numéro de facture',
  'customer.listing.unPaidInvoice.header.amount': 'Montant de la facture',
  'customer.listing.unPaidInvoice.header.paymentAmount': 'Montant du paiement',
  'customer.listing.unPaidInvoice.header.dueAmount': 'Montant dû',
  'customer.listing.unPaidInvoice.header.invoiceDate': 'Date de la facture',
  'customer.listing.unPaidInvoice.header.invoiceDueDate': 'Échéance de la facture',
  'customer.listing.unPaidInvoice.header.paymentTerm': 'Conditions de paiement',
  'customer.listing.unPaidInvoice.header.status': 'Statut',

  'customer.listing.paidInvoice.header.invoiceNumber': 'Numéro de facture',
  'customer.listing.paidInvoice.header.amount': 'Montant de la facture',
  'customer.listing.paidInvoice.header.paymentAmount': 'Montant du paiement',
  'customer.listing.paidInvoice.header.invoiceDate': 'Date de facturation',
  'customer.listing.paidInvoice.header.status': 'Statut',

  'customer.salesQuote.listing.header.sqNo': 'Cite un nombre',
  'customer.salesQuote.listing.header.rfqNumber': 'Numéro RFQ',
  'customer.salesQuote.listing.header.customer': 'Client',
  'customer.salesQuote.listing.header.amount': 'Montant',
  'customer.salesQuote.listing.header.refNumber': 'Numéro de réference',
  'customer.salesQuote.listing.header.date': 'Date',
  'customer.salesQuote.listing.header.status': 'Statut',

  'customer.salesOrder.listing.header.soNo': 'Numéro de commande',
  'customer.salesOrder.listing.header.customer': 'Customer',
  'customer.salesOrder.listing.header.status': 'Statut',
  'customer.salesOrder.listing.header.amount': 'Montant',
  'customer.salesOrder.listing.header.refNumber': 'Numéro de réference',
  'customer.salesOrder.listing.header.date': 'Date',
  'customer.salesOrder.listing.header.qtyInvoiced': 'Quantité facturée',
  'customer.salesOrder.listing.header.qtyPacked': 'Quantité emballée',
  'customer.salesOrder.listing.header.qtyShipped': 'Quantité expédiée',

  'customer.salesInvoice.listing.header.invoiceNo': 'Numéro de facture',
  'customer.salesInvoice.listing.header.soNo': 'Numéro de commande',
  'customer.salesInvoice.listing.header.customer': 'Client',
  'customer.salesInvoice.listing.header.invoiceAmount': 'Montant de la facture',
  'customer.salesInvoice.listing.header.paymentAmount': 'Montant du paiement',
  'customer.salesInvoice.listing.header.dueAmount': 'Montant dû',
  'customer.salesInvoice.listing.header.paymentTerm': 'Conditions de paiement',
  'customer.salesInvoice.listing.header.invoiceDate': 'Date de facturation',
  'customer.salesInvoice.listing.header.invoiceDueDate': 'Échéance de la facture',
  'customer.salesInvoice.listing.header.status': 'Statut',
  'customer.salesInvoice.listing.header.action': 'Action',

  'customer.customerExpense.listing.header.expenseNo': 'Numéro de dépense',
  'customer.customerExpense.listing.header.customer': 'Client',
  'customer.customerExpense.listing.header.refNumber': 'Numéro de réference',
  'customer.customerExpense.listing.header.expenseDate': 'Date de dépense',
  'customer.customerExpense.listing.header.amount': 'Montant',
  'customer.customerExpense.listing.header.status': 'Statut',

  'customer.creditMemo.listing.header.creditMemoNo': 'Numéro de note de crédit',
  'customer.creditMemo.listing.header.customer': 'Client',
  'customer.creditMemo.listing.header.transactionDate': 'Transaction Date',
  'customer.creditMemo.listing.header.amount': 'Montant',
  'customer.creditMemo.listing.header.status': 'Statut',

  'customer.customerPayment.listing.header.customer': 'Client',
  'customer.customerPayment.listing.header.paymentNo': 'Numéro de paiement',
  'customer.customerPayment.listing.header.invoiceNo': 'Numéro de facture',
  'customer.customerPayment.listing.header.soNo': 'Numéro de commande',
  'customer.customerPayment.listing.header.paymentAmount': 'Montant du paiement',
  'customer.customerPayment.listing.header.paymentDate': 'Date de paiement',
  'customer.customerPayment.listing.header.status': 'Statut',

  'supplier.listing.openOrder.header.poNumber': 'Numéro de commande',
  'supplier.listing.openOrder.header.status': 'Statut',
  'supplier.listing.openOrder.header.poAmount': 'Montant du bon de commande',
  'supplier.listing.openOrder.header.poDate': 'Date du bon de commande',

  'supplier.listing.totalOrder.header.poNumber': 'Numéro de commande',
  'supplier.listing.totalOrder.header.status': 'Statut',
  'supplier.listing.totalOrder.header.poAmount': 'Montant du bon de commande',
  'supplier.listing.totalOrder.header.poDate': 'Date du bon de commande',

  'supplier.listing.unPaidInvoice.header.invoiceNumber': 'Numéro de facture',
  'supplier.listing.unPaidInvoice.header.amount': 'Montant de la facture',
  'supplier.listing.unPaidInvoice.header.paymentAmount': 'Montant du paiement',
  'supplier.listing.unPaidInvoice.header.dueAmount': 'Montant dû',
  'supplier.listing.unPaidInvoice.header.invoiceDate': 'Date de facturation',
  'supplier.listing.unPaidInvoice.header.invoiceDueDate': 'Échéance de la facture',
  'supplier.listing.unPaidInvoice.header.paymentTerm': 'Conditions de paiement',
  'supplier.listing.unPaidInvoice.header.status': 'Statut',

  'supplier.listing.paidInvoice.header.invoiceNumber': 'Numéro de facture',
  'supplier.listing.paidInvoice.header.amount': 'Montant de la facture',
  'supplier.listing.paidInvoice.header.paymentAmount': 'Montant du paiement',
  'supplier.listing.paidInvoice.header.dueAmount': 'Montant dû',
  'supplier.listing.paidInvoice.header.invoiceDate': 'Date de facturation',
  'supplier.listing.paidInvoice.header.invoiceDueDate': 'Échéance de la facture',
  'supplier.listing.paidInvoice.header.paymentTerm': 'Conditions de paiement',
  'supplier.listing.paidInvoice.header.status': 'Statut',

  'supplier.debitMemo.listing.header.debitMemoNo': 'Debit Memo Number',
  'supplier.debitMemo.listing.header.supplier': 'Fournisseur',
  'supplier.debitMemo.listing.header.date': 'Date',
  'supplier.debitMemo.listing.header.amount': 'Montant',
  'supplier.debitMemo.listing.header.status': 'Statut',

  'supplier.supplierExpense.listing.header.expenseNo': 'Numéro de dépense',
  'supplier.supplierExpense.listing.header.supplier': 'Fournisseur',
  'supplier.supplierExpense.listing.header.refNumber': 'Numéro de réference',
  'supplier.supplierExpense.listing.header.expenseDate': 'Date de dépense',
  'supplier.supplierExpense.listing.header.amount': 'Montant',
  'supplier.supplierExpense.listing.header.status': 'Statut',

  'supplier.purchaseInvoice.listing.header.invoiceNo': 'Numéro de facture',
  'supplier.purchaseInvoice.listing.header.poNo': 'Numéro de commande',
  'supplier.purchaseInvoice.listing.header.supplier': 'Fournisseur',
  'supplier.purchaseInvoice.listing.header.invoiceAmount': 'Montant de la facture',
  'supplier.purchaseInvoice.listing.header.paymentAmount': 'Montant du paiement',
  'supplier.purchaseInvoice.listing.header.dueAmount': 'Montant dû',
  'supplier.purchaseInvoice.listing.header.paymentTerm': 'Conditions de paiement',
  'supplier.purchaseInvoice.listing.header.invoiceDate': 'Date de facturation',
  'supplier.purchaseInvoice.listing.header.invoiceDueDate': 'Échéance de la facture',
  'supplier.purchaseInvoice.listing.header.status': 'Statut',
  'supplier.purchaseInvoice.listing.header.action': 'Action',

  'supplier.listing.tiles.tatalOrders.label': 'TOTAL DES COMMANDES',
  'supplier.listing.tiles.openSalesOrder.label': 'COMMANDE DE VENTE OUVERTE',
  'supplier.listing.tiles.unpaidBalance.label': 'SOLDE NON PAYÉ',
  'supplier.listing.tiles.salesRevenue.label': 'REVENU DE VENTE',
  'supplier.listing.header.supplierDetails': 'Détails du fournisseur',
  'supplier.listing.header.contact': 'Contact',
  'supplier.listing.header.dueAmount': 'Montant dû',
  'supplier.listing.header.openOrder': 'Commande ouverte',
  'supplier.listing.header.unpaidInvoices': 'Factures impayées',
  'supplier.listing.header.paidInvoices': 'Factures payées',
  'supplier.listing.header.paymentTerm': 'Conditions de paiement',
  'supplier.listing.header.action': 'Action',
  'supplier.listing.button.newSupplier.level': 'Nouveau fournisseur',
  'supplier.listing.buttonMenu.importSuppliers': 'Importer des fournisseurs',
  'supplier.listing.importSuppliers.modal.title': 'Importer des fournisseurs',

  'supplier.supplierPayment.listing.header.supplier': 'Fournisseur',
  'supplier.supplierPayment.listing.header.paymentNo': 'Numéro de paiement',
  'supplier.supplierPayment.listing.header.invoiceNo': 'Numéro de facture',
  'supplier.supplierPayment.listing.header.poNo': 'Numéro de commande',
  'supplier.supplierPayment.listing.header.paymentAmount': 'Montant du paiement',
  'supplier.supplierPayment.listing.header.invoiceAmount': 'Montant de la facture',
  'supplier.supplierPayment.listing.header.paymentDate': 'Date de paiement',
  'supplier.supplierPayment.listing.header.status': 'Statut',

  'supplier.purchaseOrder.listing.header.poNo': 'Numéro de commande',
  'supplier.purchaseOrder.listing.header.supplier': 'Fournisseur',
  'supplier.purchaseOrder.listing.header.amount': 'Montant',
  'supplier.purchaseOrder.listing.header.refNumber': 'Numéro de réference',
  'supplier.purchaseOrder.listing.header.date': 'Date',
  'supplier.purchaseOrder.listing.header.paymentTerm': 'Conditions de paiement',
  'supplier.purchaseOrder.listing.header.status': 'Statut',
  'supplier.purchaseOrder.listing.header.qtyRecieved': 'Quantité reçue',

  'supplier.purchaseRequest.listing.header.purchaseRequestNo': 'Numéro de demande d\'achat',
  'supplier.purchaseRequest.listing.header.requester': 'Demandeur',
  'supplier.purchaseRequest.listing.header.department': 'Département',
  'supplier.purchaseRequest.listing.header.date': 'Date',
  'supplier.purchaseRequest.listing.header.expirationDate': 'Date d\'expiration',
  'supplier.purchaseRequest.listing.header.reason': 'Raison',
  'supplier.purchaseRequest.listing.header.owner': 'Propriétaire',
  'supplier.purchaseRequest.listing.header.status': 'Statut',

  'supplier.rfq.listing.header.rfqNo': 'RFQ Number',
  'supplier.rfq.listing.header.rfqDate': 'RFQ Date',
  'supplier.rfq.listing.header.supplier': 'Fournisseur',
  'supplier.rfq.listing.header.priority': 'Priorité',
  'supplier.rfq.listing.header.prNumber': 'PR Number',
  'supplier.rfq.listing.header.prDate': 'PR Date',
  'supplier.rfq.listing.header.prExpirationDate': 'PR Expiration Date',
  'supplier.rfq.listing.header.requestingDepartment': 'Requesting Department',
  'supplier.rfq.listing.header.project': 'Projet',
  'supplier.rfq.listing.header.status': 'Statut',

  'modal.customer.payInvoice.selectCustomer': 'Sélectionnez un client',
  'modal.customer.payInvoice.customerName': 'Nom du client',
  'modal.customer.payInvoice.paymentDate': 'Date de paiement',
  'modal.customer.payInvoice.invoiceNumber': 'Numéro de facture',
  'modal.customer.payInvoice.paymentMode': 'Mode de paiement',
  'modal.customer.payInvoice.paymentModeDetails': 'Détails du mode de paiement',
  'modal.customer.payInvoice.bankAccount': 'Compte bancaire',
  'modal.customer.payInvoice.amount': 'Montant',
  'modal.customer.payInvoice.invoiceAmount': 'Montant de la facture',
  'modal.customer.payInvoice.invoiceAmountDue': 'Invoice Amount Due',
  'modal.customer.payInvoice.remarks': 'Remarques',
  'modal.customer.payInvoice.project': 'Projet',

  'modal.customer.payInvoice.selectCustomer.placeholder': 'Sélectionnez un client',
  'modal.customer.payInvoice.paymentDate.placeholder': 'Date de paiement',
  'modal.customer.payInvoice.invoiceNumber.placeholder': 'Select Invoice',
  'modal.customer.payInvoice.paymentMode.placeholder': 'Select Payment Mode',
  'modal.customer.payInvoice.paymentModeDetails.placeholder': 'Enter Payment Mode Details',
  'modal.customer.payInvoice.placeholder': 'Enter Amount',
  'modal.customer.payInvoice.remarks.placeholder': 'Remarques',
  'modal.customer.payInvoice.bankAccount.placeholder': 'Select Bank Account',

  'modal.supplier.payInvoice.selectSupplier': 'Select Supplier',
  'modal.supplier.payInvoice.supplierName': 'Supplier Name',
  'modal.supplier.payInvoice.paymentDate': 'Date de paiement',
  'modal.supplier.payInvoice.invoiceNumber': 'Numéro de facture',
  'modal.supplier.payInvoice.paymentMode': 'Mode de paiement',
  'modal.supplier.payInvoice.paymentModeDetails': 'Détails du mode de paiement',
  'modal.supplier.payInvoice.bankAccount': 'Compte bancaire',
  'modal.supplier.payInvoice.amount': 'Montant',
  'modal.supplier.payInvoice.invoiceAmount': 'Montant de la facture',
  'modal.supplier.payInvoice.invoiceAmountDue': 'Invoice Amount Due',
  'modal.supplier.payInvoice.remarks': 'Remarques',
  'modal.supplier.payInvoice.project': 'Projet',

  'modal.supplier.payInvoice.selectSupplier.placeholder': 'Select Supplier',
  'modal.supplier.payInvoice.paymentDate.placeholder': 'Date de paiement',
  'modal.supplier.payInvoice.invoiceNumber.placeholder': 'Select Invoice',
  'modal.supplier.payInvoice.paymentMode.placeholder': 'Select Payment Mode',
  'modal.supplier.payInvoice.paymentModeDetails.placeholder': 'Enter Payment Mode Details',
  'modal.supplier.payInvoice.placeholder': 'Enter Amount',
  'modal.supplier.payInvoice.remarks.placeholder': 'Remarques',
  'modal.supplier.payInvoice.bankAccount.placeholder': 'Select Bank Account',

  'modal.ImportSupplier.industry.placeholder': 'Select Industry',
  'modal.ImportSupplier.supplierType.placeholder': 'Select Supplier Type',
  'modal.ImportSupplier.supplierSources.placeholder': 'Select Supplier Source',
  'modal.ImportSupplier.paymentTerm.placeholder': 'Select Payment Term',

  'product.listing.outOfStock.header.productDetails': 'Product Details',
  'product.listing.outOfStock.header.variant': 'Variant',
  'product.listing.outOfStock.header.sku': 'SKU',
  'product.listing.outOfStock.header.category': 'Category',
  'product.listing.outOfStock.header.warehouse': 'Warehouse',
  'product.listing.outOfStock.header.currentStock': 'Current Stock',

  'product.listing.reorderStock.header.productDetails': 'Product Details',
  'product.listing.reorderStock.header.variant': 'Variant',
  'product.listing.reorderStock.header.sku': 'SKU',
  'product.listing.reorderStock.header.category': 'Category',
  'product.listing.reorderStock.header.warehouse': 'Warehouse',
  'product.listing.reorderStock.header.currentStock': 'Current Stock',
  'product.listing.reorderStock.header.reorderStock': 'Reorder Stock',

  'product.listing.lowStock.header.productDetails': 'Product Details',
  'product.listing.lowStock.header.variant': 'Variant',
  'product.listing.lowStock.header.sku': 'SKU',
  'product.listing.lowStock.header.category': 'Category',
  'product.listing.lowStock.header.warehouse': 'Warehouse',
  'product.listing.lowStock.header.currentStock': 'Current Stock',
  'product.listing.lowStock.header.lowStock': 'Low Stock',

  'product.listing.buttonMenu.importProducts': 'import Products',

  'package.packageDetail.soNo': 'Numéro de commande',
  'package.packageDetail.packedBy': 'Packed By',
  'package.packageDetail.packageDate': 'Package Date',
  'package.productDetail.product': 'Product',
  'package.productDetail.variant': 'Variant',
  'package.productDetail.description': 'Description',
  'package.productDetail.uom': 'UOM',
  'package.productDetail.qtyOrdered': 'Qty Ordered',
  'package.productDetail.qtyPacked': 'Qty Packed',
  'package.productDetail.qtyToPack': 'Qty to Pack',
  'package.productDetail.action': 'Action',

  'supplier.debitMemo.form.confirmation': 'Are you sure you want to create debit memo?',
  'supplier.debitMemo.form.gridHeader.product': 'Product',
  'supplier.debitMemo.form.gridHeader.description': 'Description',
  'supplier.debitMemo.form.gridHeader.qty': 'QTY',
  'supplier.debitMemo.form.gridHeader.uom': 'UOM',
  'supplier.debitMemo.form.gridHeader.hsn': 'HSN/SAC',
  'supplier.debitMemo.form.gridHeader.rate': 'Rate',
  'supplier.debitMemo.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'supplier.debitMemo.form.gridHeader.discount': 'Discount (%)',
  'supplier.debitMemo.form.gridHeader.tax': 'Tax (%)',
  'supplier.debitMemo.form.gridHeader.amount': 'Amount ',
  'supplier.debitMemo.form.gridHeader.action': 'Action',
  'supplier.debitMemo.form.button.addLine': 'Add Line',
  'supplier.debitMemo.form.button.clearAllLines': 'Clear All Lines',
  'supplier.debitMemo.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'supplier.debitMemo.form.internalRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.debitMemo.form.supplierRemarks.label': 'Supplier Remarks',
  'supplier.debitMemo.form.supplierRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.debitMemo.form.checkbox.discount': 'Discount',
  'supplier.debitMemo.form.checkbox.tax': 'Tax',
  'supplier.debitMemo.form.checkbox.expense': 'Expense',
  'supplier.debitMemo.form.totalDiscount.label': 'Discount',
  'supplier.debitMemo.form.totalTax.label': 'Tax',
  'supplier.debitMemo.form.totalExpense.label': 'Expense',
  'supplier.debitMemo.form.totalExpenseAmount.label': 'Expense Amount',
  'supplier.debitMemo.form.subTotal.label': 'Sub Total',
  'supplier.debitMemo.form.totalAmount.label': 'Total Amount',
  'supplier.debitMemo.form.supplier.label': 'Fournisseur',
  'supplier.debitMemo.form.supplier.placeholder': 'Select Supplier',
  'supplier.debitMemo.form.supplierName.label': 'Supplier Name',
  'supplier.debitMemo.form.gstnIn.label': 'GSTIN',
  'supplier.debitMemo.form.pan.label': 'PAN',
  'supplier.debitMemo.form.phone.label': 'Téléphone',
  'supplier.debitMemo.form.email.label': 'Email',
  'supplier.debitMemo.form.debitMemoDate.label': 'Debit Memo Date',
  'supplier.debitMemo.form.updateInventory.label': 'Update Inventory',

  'supplier.purchaseOrder.form.confirmation': 'Are you sure you want to create purchase order?',
  'supplier.purchaseOrder.form.priceListDropdown.placeholder': 'Sélectionnez la liste de prix',
  'supplier.purchaseOrder.form.gridHeader.placeOfSupply': 'Place of Supply',
  'supplier.purchaseOrder.form.gridHeader.refNumber': 'Ref Number',
  'supplier.purchaseOrder.form.gridHeader.shippingAddress': 'Shipping Address',
  'supplier.purchaseOrder.form.gridHeader.paymentTerms': 'Payment Terms',
  'supplier.purchaseOrder.form.gridHeader.product': 'Product',
  'supplier.purchaseOrder.form.gridHeader.description': 'Description',
  'supplier.purchaseOrder.form.gridHeader.qty': 'QTY',
  'supplier.purchaseOrder.form.gridHeader.uom': 'UOM',
  'supplier.purchaseOrder.form.gridHeader.hsn': 'HSN/SAC',
  'supplier.purchaseOrder.form.gridHeader.rate': 'Rate',
  'supplier.purchaseOrder.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'supplier.purchaseOrder.form.gridHeader.discount': 'Discount (%)',
  'supplier.purchaseOrder.form.gridHeader.tax': 'Tax (%)',
  'supplier.purchaseOrder.form.gridHeader.amount': 'Amount ',
  'supplier.purchaseOrder.form.gridHeader.action': 'Action',
  'supplier.purchaseOrder.form.button.addLine': 'Add Line',
  'supplier.purchaseOrder.form.button.clearAllLines': 'Clear All Lines',
  'supplier.purchaseOrder.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'supplier.purchaseOrder.form.internalRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseOrder.form.supplierRemarks.label': 'Supplier Remarks',
  'supplier.purchaseOrder.form.supplierRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseOrder.form.checkbox.discount': 'Discount',
  'supplier.purchaseOrder.form.checkbox.tax': 'Tax',
  'supplier.purchaseOrder.form.checkbox.expense': 'Expense',
  'supplier.purchaseOrder.form.totalDiscount.label': 'Discount',
  'supplier.purchaseOrder.form.totalTax.label': 'Tax',
  'supplier.purchaseOrder.form.totalExpense.label': 'Expense',
  'supplier.purchaseOrder.form.totalExpenseAmount.label': 'Expense Amount',
  'supplier.purchaseOrder.form.subTotal.label': 'Sub Total',
  'supplier.purchaseOrder.form.totalAmount.label': 'Total Amount',
  'supplier.purchaseOrder.form.supplier.label': 'Fournisseur',
  'supplier.purchaseOrder.form.supplierName.label': 'Supplier Name',
  'supplier.purchaseOrder.form.supplierName.placeholder': 'Select Supplier',
  'supplier.purchaseOrder.form.gstnIn.label': 'GSTIN',
  'supplier.purchaseOrder.form.pan.label': 'PAN',
  'supplier.purchaseOrder.form.phone.label': 'Téléphone',
  'supplier.purchaseOrder.form.email.label': 'Email',
  'supplier.purchaseOrder.form.poDate.label': 'PO Date',
  'supplier.purchaseOrder.form.poDueDate.label': 'PO Due Date',
  'supplier.purchaseOrder.form.projectDropdown.label': 'Projet',

  'supplier.rfq.form.confirmation': 'Are you sure you want to create RFQ?',
  'supplier.rfq.form.customer.label': 'Client',
  'supplier.rfq.form.customer.placeholder': 'Sélectionnez un client',
  'supplier.rfq.form.gridHeader.requestingDepartment': 'Requesting Department',
  'supplier.rfq.form.gridHeader.project': 'Projet',
  'supplier.rfq.form.gridHeader.purchaseType': 'Purchase Type',
  'supplier.rfq.form.gridHeader.priority': 'Priorité',
  'supplier.rfq.form.gridHeader.product': 'Product',
  'supplier.rfq.form.gridHeader.description': 'Description',
  'supplier.rfq.form.gridHeader.qty': 'QTY',
  'supplier.rfq.form.gridHeader.uom': 'UOM',
  'supplier.rfq.form.gridHeader.action': 'Action',
  'supplier.rfq.form.button.addLine': 'Add Line',
  'supplier.rfq.form.button.clearAllLines': 'Clear All Lines',
  'supplier.rfq.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'supplier.rfq.form.internalRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.rfq.form.supplierRemarks.label': 'Supplier Remarks',
  'supplier.rfq.form.supplierRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.rfq.form.checkbox.discount': 'Discount',
  'supplier.rfq.form.checkbox.tax': 'Tax',
  'supplier.rfq.form.checkbox.expense': 'Expense',
  'supplier.rfq.form.totalDiscount.label': 'Discount',
  'supplier.rfq.form.totalTax.label': 'Tax',
  'supplier.rfq.form.totalExpense.label': 'Expense',
  'supplier.rfq.form.totalExpenseAmount.label': 'Expense Amount',
  'supplier.rfq.form.subTotal.label': 'Sub Total',
  'supplier.rfq.form.totalAmount.label': 'Total Amount',
  'supplier.rfq.form.supplier.label': 'Fournisseur',
  'supplier.rfq.form.supplierName.label': 'Supplier Name',
  'supplier.rfq.form.supplierName.placeholder': 'Select Supplier',
  'supplier.rfq.form.gstnIn.label': 'GSTIN',
  'supplier.rfq.form.pan.label': 'PAN',

  'supplier.purchaseRequest.form.confirmation': 'Are you sure you want to create purchase request?',
  'supplier.purchaseRequest.form.gridHeader.product': 'Product',
  'supplier.purchaseRequest.form.gridHeader.description': 'Description',
  'supplier.purchaseRequest.form.gridHeader.qty': 'QTY',
  'supplier.purchaseRequest.form.gridHeader.uom': 'UOM',
  'supplier.purchaseRequest.form.gridHeader.action': 'Action',
  'supplier.purchaseRequest.form.button.addLine': 'Add Line',
  'supplier.purchaseRequest.form.button.clearAllLines': 'Clear All Lines',
  'supplier.purchaseRequest.form.remarks.label': 'Remarks (For Internal Use)',
  'supplier.purchaseRequest.form.remarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseRequest.form.requestor.label': 'Requestor',
  'supplier.purchaseRequest.form.department.label': 'Département',
  'supplier.purchaseRequest.form.status.label': 'Statut',
  'supplier.purchaseRequest.form.owner.label': 'Propriétaire',
  'supplier.purchaseRequest.form.purchaseRequestDate.label': 'Date de demande d\'achat',
  'supplier.purchaseRequest.form.expirationDate.label': 'Date d\'expiration',
  'supplier.purchaseRequest.form.sendEmail.label': 'Envoyez un e-mail si la demande de devis / la commande d achat est créée',
  'supplier.purchaseRequest.form.reason.label': 'Raison',
  'supplier.purchaseRequest.form.priority.label': 'Priorité',

  'supplier.purchaseinvoice.form.confirmation': 'Are you sure you want to create purchase invoice?',
  'supplier.purchaseinvoice.form.cashPurchase.label': 'Cash Purchase',
  'supplier.purchaseinvoice.form.priceListDropdown.placeholder': 'Sélectionnez la liste de prix',
  'supplier.purchaseinvoice.form.gridHeader.placeOfSupply': 'Place of Supply',
  'supplier.purchaseOrder.form.gridHeader.supplierPO': 'Supplier PO #',
  'supplier.purchaseinvoice.form.gridHeader.refNumber': 'Ref Number',
  'supplier.purchaseinvoice.form.gridHeader.shippingAddress': 'Shipping Address',
  'supplier.purchaseinvoice.form.gridHeader.paymentTerms': 'Payment Terms',
  'supplier.purchaseinvoice.form.gridHeader.product': 'Product',
  'supplier.purchaseinvoice.form.gridHeader.description': 'Description',
  'supplier.purchaseinvoice.form.gridHeader.qty': 'QTY',
  'supplier.purchaseinvoice.form.gridHeader.uom': 'UOM',
  'supplier.purchaseinvoice.form.gridHeader.hsn': 'HSN/SAC',
  'supplier.purchaseinvoice.form.gridHeader.rate': 'Rate',
  'supplier.purchaseinvoice.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'supplier.purchaseinvoice.form.gridHeader.discount': 'Discount (%)',
  'supplier.purchaseinvoice.form.gridHeader.tax': 'Tax (%)',
  'supplier.purchaseinvoice.form.gridHeader.amount': 'Amount ',
  'supplier.purchaseinvoice.form.gridHeader.action': 'Action',
  'supplier.purchaseinvoice.form.button.addLine': 'Add Line',
  'supplier.purchaseinvoice.form.button.clearAllLines': 'Clear All Lines',
  'supplier.purchaseinvoice.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'supplier.purchaseinvoice.form.internalRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseinvoice.form.supplierRemarks.label': 'Supplier Remarks',
  'supplier.purchaseinvoice.form.supplierRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseinvoice.form.checkbox.discount': 'Discount',
  'supplier.purchaseinvoice.form.checkbox.tax': 'Tax',
  'supplier.purchaseinvoice.form.checkbox.expense': 'Expense',
  'supplier.purchaseinvoice.form.totalDiscount.label': 'Discount',
  'supplier.purchaseinvoice.form.totalTax.label': 'Tax',
  'supplier.purchaseinvoice.form.totalExpense.label': 'Expense',
  'supplier.purchaseinvoice.form.totalExpenseAmount.label': 'Expense Amount',
  'supplier.purchaseinvoice.form.subTotal.label': 'Sub Total',
  'supplier.purchaseinvoice.form.totalAmount.label': 'Total Amount',
  'supplier.purchaseinvoice.form.supplier.label': 'Fournisseur',
  'supplier.purchaseinvoice.form.supplierName.label': 'Supplier Name',
  'supplier.purchaseinvoice.form.supplierName.placeholder': 'Select Supplier',
  'supplier.purchaseinvoice.form.gstnIn.label': 'GSTIN',
  'supplier.purchaseinvoice.form.pan.label': 'PAN',
  'supplier.purchaseinvoice.form.phone.label': 'Téléphone',
  'supplier.purchaseinvoice.form.email.label': 'Email',
  'supplier.purchaseinvoice.form.invoiceDate.label': 'Date de facturation',
  'supplier.purchaseinvoice.form.invoiceDueDate.label': 'Échéance de la facture',
  'supplier.purchaseinvoice.form.projectDropdown.label': 'Projet',

  'customer.creditMemo.form.confirmation': 'Are you sure you want to create credit memo?',
  'customer.creditMemo.form.gridHeader.product': 'Product',
  'customer.creditMemo.form.gridHeader.description': 'Description',
  'customer.creditMemo.form.gridHeader.qty': 'QTY',
  'customer.creditMemo.form.gridHeader.uom': 'UOM',
  'customer.creditMemo.form.gridHeader.hsn': 'HSN/SAC',
  'customer.creditMemo.form.gridHeader.rate': 'Rate',
  'customer.creditMemo.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'customer.creditMemo.form.gridHeader.discount': 'Discount (%)',
  'customer.creditMemo.form.gridHeader.tax': 'Tax (%)',
  'customer.creditMemo.form.gridHeader.amount': 'Amount ',
  'customer.creditMemo.form.gridHeader.action': 'Action',
  'customer.creditMemo.form.button.addLine': 'Add Line',
  'customer.creditMemo.form.button.clearAllLines': 'Clear All Lines',
  'customer.creditMemo.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'customer.creditMemo.form.internalRemarks.placeholder': 'Will not be visible to the customer. For internal purpose only.',
  'customer.creditMemo.form.customerRemarks.label': 'Customer Remarks',
  'customer.creditMemo.form.customerRemarks.placeholder': 'Thanks for your business We truly appreciate your business, and we are grateful for the trust you have placed in us. If you have any concerns or questions, please do not hesitate to contact us.',
  'customer.creditMemo.form.checkbox.costCenter': 'Cost Center',
  'customer.creditMemo.form.totalDiscount.label': 'Discount',
  'customer.creditMemo.form.costCenter.label': 'Cost Center',
  'customer.creditMemo.form.addCostCenter.label': 'Add Cost Center',
  'customer.creditMemo.form.subTotal.label': 'Sub Total',
  'customer.creditMemo.form.totalAmount.label': 'Total Amount',
  'customer.creditMemo.form.customer.label': 'Client',
  'customer.creditMemo.form.customerName.label': 'Nom du client',
  'customer.creditMemo.form.customer.placeholder': 'Sélectionnez un client',
  'customer.creditMemo.form.priceList.placeholder': 'Sélectionnez la liste de prix',
  'customer.creditMemo.form.gstnIn.label': 'GSTIN',
  'customer.creditMemo.form.pan.label': 'PAN',
  'customer.creditMemo.form.phone.label': 'Téléphone',
  'customer.creditMemo.form.email.label': 'Email',
  'customer.creditMemo.form.creditMemoDate.label': 'Credit Memo Date',
  'customer.creditMemo.form.creditMemoExpiry.label': 'Credit Memo Expiry',
  'customer.creditMemo.form.updateInventory.label': 'Update Inventory',

  'customer.salesInvoice.form.confirmation': 'Are you sure you want to create sales Invoice?',
  'customer.salesInvoice.form.priceListDropdown.placeholder': 'Sélectionnez la liste de prix',
  'customer.salesInvoice.form.gridHeader.placeOfSupply': 'Place of Supply',
  'customer.salesInvoice.form.gridHeader.customerPO': 'Customer PO #',
  'customer.salesInvoice.form.gridHeader.refNumber': 'Ref Number',
  'customer.salesInvoice.form.gridHeader.billingAddress': 'Billing Address',
  'customer.salesInvoice.form.gridHeader.shippingAddress': 'Shipping Address',
  'customer.salesInvoice.form.gridHeader.paymentTerms': 'Payment Terms',
  'customer.salesInvoice.form.gridHeader.product': 'Product',
  'customer.salesInvoice.form.gridHeader.description': 'Description',
  'customer.salesInvoice.form.gridHeader.qty': 'QTY',
  'customer.salesInvoice.form.gridHeader.qtyOrdered': 'Qty Ordered',
  'customer.salesInvoice.form.gridHeader.qtyInvoiced': 'Quantité facturée',
  'customer.salesInvoice.form.gridHeader.qtyToInvoice': 'Qty To Invoice',
  'customer.salesInvoice.form.gridHeader.uom': 'UOM',
  'customer.salesInvoice.form.gridHeader.hsn': 'HSN/SAC',
  'customer.salesInvoice.form.gridHeader.rate': 'Rate',
  'customer.salesInvoice.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'customer.salesInvoice.form.gridHeader.discount': 'Discount (%)',
  'customer.salesInvoice.form.gridHeader.tax': 'Tax (%)',
  'customer.salesInvoice.form.gridHeader.amount': 'Amount ',
  'customer.salesInvoice.form.gridHeader.action': 'Action',
  'customer.salesInvoice.form.button.addLine': 'Add Line',
  'customer.salesInvoice.form.button.clearAllLines': 'Clear All Lines',
  'customer.salesInvoice.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'customer.salesInvoice.form.internalRemarks.placeholder': 'Will not be visible to the customer. For internal purpose only.',
  'customer.salesInvoice.form.customerRemarks.label': 'Customer Remarks',
  'customer.salesInvoice.form.customerRemarks.placeholder': 'Thanks for your business We truly appreciate your business, and we are grateful for the trust you have placed in us. If you have any concerns or questions, please do not hesitate to contact us.',
  'customer.salesInvoice.form.checkbox.discount': 'Discount',
  'customer.salesInvoice.form.checkbox.tax': 'Tax',
  'customer.salesInvoice.form.checkbox.expense': 'Expense',
  'customer.salesInvoice.form.totalDiscount.label': 'Discount',
  'customer.salesInvoice.form.totalTax.label': 'Tax',
  'customer.salesInvoice.form.taxName.label': 'Tax Name',
  'customer.salesInvoice.form.totalExpense.label': 'Expense',
  'customer.salesInvoice.form.totalExpenseAmount.label': 'Expense Amount',
  'customer.salesInvoice.form.subTotal.label': 'Sub Total',
  'customer.salesInvoice.form.totalAmount.label': 'Total Amount',
  'customer.salesInvoice.form.customer.label': 'Client',
  'customer.salesInvoice.form.customerName.label': 'Nom du client',
  'customer.salesInvoice.form.customer.placeholder': 'Sélectionnez un client',
  'customer.salesInvoice.form.priceList.placeholder': 'Sélectionnez la liste de prix',
  'customer.salesInvoice.form.priceType.placeholder': 'Apply Retail Price',
  'customer.salesInvoice.form.gstnIn.label': 'GSTIN',
  'customer.salesInvoice.form.pan.label': 'PAN',
  'customer.salesInvoice.form.phone.label': 'Téléphone',
  'customer.salesInvoice.form.email.label': 'Email',
  'customer.salesInvoice.form.invoiceDate.label': 'Date de facturation',
  'customer.salesInvoice.form.invoiceDueDate.label': 'Échéance de la facture',
  'customer.salesInvoice.form.projectDropdown.label': 'Projet',

  'customer.salesOrder.form.confirmation': 'Are you sure you want to create sales order?',
  'customer.salesOrder.form.priceListDropdown.placeholder': 'Sélectionnez la liste de prix',
  'customer.salesOrder.form.gridHeader.placeOfSupply': 'Place of Supply',
  'customer.salesOrder.form.gridHeader.soSource': 'Source de commande client',
  'customer.salesOrder.form.gridHeader.customerPO': 'Customer PO #',
  'customer.salesOrder.form.gridHeader.salesPerson': 'Sales Person',
  'customer.salesOrder.form.gridHeader.refNumber': 'Ref Number',
  'customer.salesOrder.form.gridHeader.priority': 'Priorité',
  'customer.salesOrder.form.gridHeader.billingAddress': 'Billing Address',
  'customer.salesOrder.form.gridHeader.shippingAddress': 'Shipping Address',
  'customer.salesOrder.form.gridHeader.paymentTerms': 'Payment Terms',
  'customer.salesOrder.form.gridHeader.product': 'Product',
  'customer.salesOrder.form.gridHeader.description': 'Description',
  'customer.salesOrder.form.gridHeader.qty': 'QTY',
  'customer.salesOrder.form.gridHeader.qtyOrdered': 'Qty Ordered',
  'customer.salesOrder.form.gridHeader.qtyInvoiced': 'Quantité facturée',
  'customer.salesOrder.form.gridHeader.qtyToInvoice': 'Qty To Invoice',
  'customer.salesOrder.form.gridHeader.uom': 'UOM',
  'customer.salesOrder.form.gridHeader.hsn': 'HSN/SAC',
  'customer.salesOrder.form.gridHeader.rate': 'Rate',
  'customer.salesOrder.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'customer.salesOrder.form.gridHeader.discount': 'Discount (%)',
  'customer.salesOrder.form.gridHeader.tax': 'Tax (%)',
  'customer.salesOrder.form.gridHeader.amount': 'Amount ',
  'customer.salesOrder.form.gridHeader.action': 'Action',
  'customer.salesOrder.form.button.addLine': 'Add Line',
  'customer.salesOrder.form.button.clearAllLines': 'Clear All Lines',
  'customer.salesOrder.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'customer.salesOrder.form.internalRemarks.placeholder': 'Will not be visible to the customer. For internal purpose only.',
  'customer.salesOrder.form.customerRemarks.label': 'Customer Remarks',
  'customer.salesOrder.form.customerRemarks.placeholder': 'Thanks for your business We truly appreciate your business, and we are grateful for the trust you have placed in us. If you have any concerns or questions, please do not hesitate to contact us.',
  'customer.salesOrder.form.checkbox.discount': 'Discount',
  'customer.salesOrder.form.checkbox.tax': 'Tax',
  'customer.salesOrder.form.checkbox.expense': 'Expense',
  'customer.salesOrder.form.totalDiscount.label': 'Discount',
  'customer.salesOrder.form.totalTax.label': 'Tax',
  'customer.salesOrder.form.taxName.label': 'Tax Name',
  'customer.salesOrder.form.totalExpense.label': 'Expense',
  'customer.salesOrder.form.totalExpenseAmount.label': 'Expense Amount',
  'customer.salesOrder.form.subTotal.label': 'Sub Total',
  'customer.salesOrder.form.totalAmount.label': 'Total Amount',
  'customer.salesOrder.form.warehouse.placeholder': 'Warehouse',
  'customer.salesOrder.form.customer.label': 'Client',
  'customer.salesOrder.form.customerName.label': 'Nom du client',
  'customer.salesOrder.form.customer.placeholder': 'Sélectionnez un client',
  'customer.salesOrder.form.priceList.placeholder': 'Sélectionnez la liste de prix',
  'customer.salesOrder.form.priceType.placeholder': 'Apply Retail Price',
  'customer.salesOrder.form.gstnIn.label': 'GSTIN',
  'customer.salesOrder.form.pan.label': 'PAN',
  'customer.salesOrder.form.phone.label': 'Téléphone',
  'customer.salesOrder.form.email.label': 'Email',
  'customer.salesOrder.form.soDate.label': 'SO Date',
  'customer.salesOrder.form.projectDropdown.label': 'Projet',
  'customer.salesOrder.form.documentDropdown.label': 'Document Title',
  "customer.salesQuote.form.confirmation":
  "Are you sure you want to create sales quote?",
  "customer.salesQuote.edit.form.confirmation":
  "Are you sure you want to submit?",
  'customer.salesQuote.form.priceListDropdown.placeholder': 'Sélectionnez la liste de prix',
  'customer.salesOrder.form.gridHeader.customerRFQ': 'Customer RFQ#',
  'customer.salesQuote.form.gridHeader.refNumber': 'Ref Number',
  'customer.salesQuote.form.gridHeader.priority': 'Priorité',
  'customer.salesQuote.form.gridHeader.billingAddress': 'Billing Address',
  'customer.salesQuote.form.gridHeader.product': 'Product',
  'customer.salesQuote.form.gridHeader.description': 'Description',
  'customer.salesQuote.form.gridHeader.qty': 'QTY',
  'customer.salesQuote.form.gridHeader.qtyOrdered': 'Qty Ordered',
  'customer.salesQuote.form.gridHeader.qtyInvoiced': 'Quantité facturée',
  'customer.salesQuote.form.gridHeader.qtyToInvoice': 'Qty To Invoice',
  'customer.salesQuote.form.gridHeader.uom': 'UOM',
  'customer.salesQuote.form.gridHeader.hsn': 'HSN/SAC',
  'customer.salesQuote.form.gridHeader.rate': 'Rate',
  'customer.salesQuote.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'customer.salesQuote.form.gridHeader.discount': 'Discount (%)',
  'customer.salesQuote.form.gridHeader.tax': 'Tax (%)',
  'customer.salesQuote.form.gridHeader.amount': 'Amount ',
  'customer.salesQuote.form.gridHeader.action': 'Action',
  'customer.salesQuote.form.button.addLine': 'Add Line',
  'customer.salesQuote.form.button.clearAllLines': 'Clear All Lines',
  'customer.salesQuote.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'customer.salesQuote.form.internalRemarks.placeholder': 'Will not be visible to the customer. For internal purpose only.',
  'customer.salesQuote.form.customerRemarks.label': 'Customer Remarks',
  'customer.salesQuote.form.customerRemarks.placeholder': 'Thanks for your business We truly appreciate your business, and we are grateful for the trust you have placed in us. If you have any concerns or questions, please do not hesitate to contact us.',
  'customer.salesQuote.form.checkbox.discount': 'Discount',
  'customer.salesQuote.form.checkbox.tax': 'Tax',
  'customer.salesQuote.form.checkbox.expense': 'Expense',
  'customer.salesQuote.form.totalDiscount.label': 'Discount',
  'customer.salesQuote.form.totalTax.label': 'Tax',
  'customer.salesQuote.form.taxName.label': 'Tax Name',
  'customer.salesQuote.form.totalExpense.label': 'Expense',
  'customer.salesQuote.form.totalExpenseAmount.label': 'Expense Amount',
  'customer.salesQuote.form.subTotal.label': 'Sub Total',
  'customer.salesQuote.form.totalAmount.label': 'Total Amount',
  'customer.salesQuote.form.warehouse.placeholder': 'Warehouse',
  'customer.salesQuote.form.customer.label': 'Customer',
  'customer.salesQuote.form.customerName.label': 'Nom du client',
  'customer.salesQuote.form.customer.placeholder': 'Sélectionnez un client',
  'customer.salesQuote.form.priceList.placeholder': 'Sélectionnez la liste de prix',
  'customer.salesQuote.form.priceType.placeholder': 'Apply Retail Price',
  'customer.salesQuote.form.gstnIn.label': 'GSTIN',
  'customer.salesQuote.form.pan.label': 'PAN',
  'customer.salesQuote.form.phone.label': 'Téléphone',
  'customer.salesQuote.form.email.label': 'Email',
  'customer.salesQuote.form.sqDate.label': 'Quote Date',
  'customer.salesQuote.form.projectDropdown.label': 'Projet',

  'modal.warehouse.button.createWarehouse': 'Create Warehouse',
  'modal.warehouse.button.updateWarehouse': 'Update Warehouse',
  'modal.warehouse.button.cancel': 'Cancel',

  'modal.project.button.createProject': 'Create Project',
  'modal.project.button.cancel': 'Cancel',

  'modal.contact.button.createContact': 'Create',
  'modal.contact.button.cancel': 'Cancel',

  'modal.priceList.button.createPriceList': 'Create',
  'modal.priceList.button.cancel': 'Cancel',

  'modal.newTax.button.createTax': 'Create',
  'modal.newTax.button.cancel': 'Cancel',

  'modal.address.button.createAddress': 'Create',
  'modal.address.button.cancel': 'Cancel',
  'finance.journal.newJournal.button.name': 'New Journal',

  'supplier.rfq.biddingSuppliers.gridHeader.supplierName': 'Supplier Name',
  'supplier.rfq.biddingSuppliers.gridHeader.address': 'Address',
  'supplier.rfq.biddingSuppliers.gridHeader.rfqEmailed': 'RFQ Emailed',
  'supplier.rfq.biddingSuppliers.gridHeader.documents': 'Documents',
  'supplier.rfq.biddingSuppliers.gridHeader.priceCode': 'Price Code',
  'supplier.rfq.biddingSuppliers.gridHeader.status': 'Statut',
  'supplier.rfq.biddingSuppliers.gridHeader.action': 'Action',

  'modal.settings.emailTemplate.transaction': 'Transaction',
  'modal.settings.emailTemplate.emailSubject': 'Email Subject',
  'modal.settings.emailTemplate.emailMessage': 'Email Message',

  'modal.settings.remarksTemplate.transaction': 'Transaction',
  'modal.settings.remarksTemplate.message': 'Message',

  'modal.settings.changePassword.currentPassword': 'Current Password',
  'modal.settings.changePassword.newPassword': 'Enter New Password',
  'modal.settings.changePassword.confirmPassword': 'Confirm Password',
  'modal.settings.changePassword.currentPassword.placeholder': 'Current Password',
  'modal.settings.changePassword.newPassword.placeholder': 'Enter New Password',
  'modal.settings.changePassword.confirmPassword.placeholder': 'Confirm Password',
  'modal.settings.changePassword.validation.currentPassword': 'Current Password is required',
  'modal.settings.changePassword.validation.newPassword': 'New Password is required',
  'modal.settings.changePassword.validation.confirmPassword': 'Confirm password does not match',

  'inventory.priceList.listing.header.name': 'Name',
  'inventory.priceList.listing.header.description': 'Description',
  'inventory.priceList.listing.header.percentage': 'Percentage',
  'inventory.priceList.listing.header.type': 'Type',
  'inventory.priceList.listing.header.startDate': 'Start Date',
  'inventory.priceList.listing.header.endDate': 'End Date',
  'inventory.priceList.listing.header.action': 'Action',

  'inventory.extras.category.listing.header.categoryName': 'Category Name',
  'inventory.extras.category.listing.header.categoryDescription': 'Category Description',
  'inventory.extras.category.listing.header.dateCreated': 'Date Created',
  'inventory.extras.category.listing.header.totalProducts': 'Total Products',
  'inventory.extras.category.listing.header.action': 'Action',

  'inventory.extras.brand.listing.header.brandName': 'Brand Name',
  'inventory.extras.brand.listing.header.brandDescription': 'Brand Description',
  'inventory.extras.brand.listing.header.dateCreated': 'Date Created',
  'inventory.extras.brand.listing.header.totalProducts': 'Total Products',
  'inventory.extras.brand.listing.header.action': 'Action',

  'inventory.extras.manufacturer.listing.header.manufacturerName': 'Manufacturer Name',
  'inventory.extras.manufacturer.listing.header.description': 'Manufacturer Description',
  'inventory.extras.manufacturer.listing.header.dateCreated': 'Date Created',
  'inventory.extras.manufacturer.listing.header.totalProducts': 'Total Products',
  'inventory.extras.manufacturer.listing.header.action': 'Action',

  'inventory.extras.uom.listing.header.uom': 'UOM',
  'inventory.extras.uom.listing.header.isDefault': 'Is Default',
  'inventory.extras.uom.listing.header.description': 'Description',
  'inventory.extras.uom.listing.header.dateCreated': 'Date Created',
  'inventory.extras.uom.listing.header.action': 'Action',

  'inventory.warehouse.myWarehouse.listing.header.warehouseName': 'Warehouse Name',
  'inventory.warehouse.myWarehouse.listing.header.isPrimary': 'Primary Warehouse',
  'inventory.warehouse.myWarehouse.listing.header.totalItems': 'Total Items',
  'inventory.warehouse.myWarehouse.listing.header.warehouseAddress': 'Warehouse Address',
  'inventory.warehouse.myWarehouse.listing.header.dateCreated': 'Date Created',
  'inventory.warehouse.myWarehouse.listing.header.action': 'Action',

  'inventory.warehouse.agedStock.listing.header.product': 'Product',
  'inventory.warehouse.agedStock.listing.header.variant': 'Variant',
  'inventory.warehouse.agedStock.listing.header.sku': 'SKU',
  'inventory.warehouse.agedStock.listing.header.days': 'Days',
  'inventory.warehouse.agedStock.listing.header.currentStock': 'Current Stock',
  'inventory.warehouse.agedStock.listing.header.lastPurchasePrice': 'Last Purchase Price',

  'inventory.warehouse.stockSummary.listing.header.product': 'Product',
  'inventory.warehouse.stockSummary.listing.header.sku': 'SKU',
  'inventory.warehouse.stockSummary.listing.header.category': 'Category',
  'inventory.warehouse.stockSummary.listing.header.currentStock': 'Current Stock',
  'inventory.warehouse.stockSummary.listing.header.qtyOnSO': 'Qty on SO',
  'inventory.warehouse.stockSummary.listing.header.qtyAvailable': 'Qty Available',
  'inventory.warehouse.stockSummary.listing.header.qtyOnPO': 'Qty on PO',
  'inventory.warehouse.stockSummary.listing.header.reorderLevel': 'Reorder Level',
  'inventory.warehouse.stockSummary.listing.header.lowStockLevel': 'Low Stock Level',
  'inventory.warehouse.stockSummary.listing.warehouseFilter.placeholder': 'Select Warehouse',

  'inventory.warehouse.stockAdjustment.listing.header.product': 'Product',
  'inventory.warehouse.stockAdjustment.listing.header.sku': 'SKU',
  'inventory.warehouse.stockAdjustment.listing.header.category': 'Category',
  'inventory.warehouse.stockAdjustment.listing.header.currentStock': 'Current Stock',
  'inventory.warehouse.stockAdjustment.listing.header.qtyOnSO': 'Qty on SO',
  'inventory.warehouse.stockAdjustment.listing.header.qtyAvailable': 'Qty Available',
  'inventory.warehouse.stockAdjustment.listing.header.qtyOnPO': 'Qty on PO',
  'inventory.warehouse.stockAdjustment.listing.header.action': 'Action',

  'inventory.warehouse.productPrice.listing.header.product': 'Product',
  'inventory.warehouse.productPrice.listing.header.sku': 'SKU',
  'inventory.warehouse.productPrice.listing.header.itemCode': 'Item Code',
  'inventory.warehouse.productPrice.listing.header.description': 'Description',
  'inventory.warehouse.productPrice.listing.header.category': 'Category',
  'inventory.warehouse.productPrice.listing.header.purchasedPrice': 'Purchased Price',
  'inventory.warehouse.productPrice.listing.header.wholesalePrice': 'Wholesale Price',
  'inventory.warehouse.productPrice.listing.header.retailPrice': 'Retail Price',
  'inventory.warehouse.productPrice.listing.header.action': 'Action',
};

export const fr = lang;
