import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BusinessExpenseDetailComponent from '../../../../../components/modal/modalBody/finance/BusinessExpense';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchRelationshipExpenseDetail, resetRelationshipExpenseDetails, deleteRelationshipExpense } from './action';
import { fetchBusinessExpenses, getBusinessExpensesCount } from '../../../../finance/BusinessExpense/Listing/action';
import { fetchAllLedgerAccounts } from '../../../../finance/LedgerAccounts/action';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import { fetchDataIfNeeded } from '../../../../../utils';

class BusinessExpenseDetail extends Component {

    constructor(props){
        super();
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }

    componentDidMount(){
        const companyInfo = this.props.companyInfo || {};
        fetchDataIfNeeded('fetchAllLedgerAccounts', 'allLedgerAccounts', this.props, {relationshipId: companyInfo.relationshipId});
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, expenseMasterId: this.props.expensePayload.expenseMasterId };
        this.props.resetRelationshipExpenseDetails();
        this.props.fetchRelationshipExpenseDetail(payload);
    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <BusinessExpenseDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        businessExpenseData: state.businessExpense.businessExpenseDetail,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchRelationshipExpenseDetail,
    resetRelationshipExpenseDetails,
    deleteRelationshipExpense,
    fetchBusinessExpenses, 
    getBusinessExpensesCount,
    generatePDF, 
    printPDF, 
    fetchAllLedgerAccounts,
    downloadFileFromBucket
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BusinessExpenseDetail);