import React from "react";
import { ICONS } from "../../../../../../static/constants";

const Contact = (props) => {
    return (
        <div className="contact-info">
            <div>
                <ul className="primary-info">
                    <div className="b-text">Primary</div>

                    <li className="p-info">
                        <i className="fi fi-rs-circle-user mr5" /> {props?.fullName || <div className="empty-data-box"></div>}
                    </li>
                    <li className="p-info">
                        <i class={`${ICONS.MAIL} mr5`} /> {props.emailAddress || <div className="empty-data-box"></div>}
                    </li>
                    <li className="p-info">
                        <i class={`${ICONS.CALL} mr5`} /> {props.cellNumber || <div className="empty-data-box"></div>}
                    </li>
                    {/* <li className="p-info">
                        <i class="fi fi-brands-whatsapp mr5" /> <div className="empty-data-box"></div>
                    </li> */}
                      <li className="p-info">
                    <i class="fi fi-rr-user-add mr5" /> {props.emergencyContactName || <div className="empty-data-box"></div>}
                    </li>
                    <li className="p-info">
                    <i class="fi fi-rr-phone-plus mr5" /> {props.emergencyContactNumber || <div className="empty-data-box"></div>}
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Contact;

