import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col } from 'antd';

//import './customerDrawer.scss';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
function SchoolDrawer(props) {

  const { type, duration } = props;

  const closeDrawer = () => {
    props.updateHeaderState({
      school: {
        addInvoiceDrawerVisible: false
      }
    });
  }

  const handleSubmit = (props) => {
    if (props.fee && props.frequency) {
      const payload = {
        type: props.type,
        fee: props.fee,
        frequency: props.frequency,
        duration: props.duration,
        period: props.period,
        isLifetime: props.isLifetime ? 1 : 0,
        relationshipId: props.relationshipId,
        feesId: props.feesId,
        version: props.version,
        dateCreated: props.dateCreated
      };

      props.pushModalToStack({
        modalBody: props.feesId ? <FormattedMessage id='commons.edit.popup.confimation.msg' defaultMessage='' /> : <FormattedMessage id='membership.termAndFee.addDrawer.confitmation' defaultMessage='' />,
        handleSubmit: () => {
          props.saveFees(payload, props);
        }
      });

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-title">
            <FormattedMessage id='schoolManagement.school.new' defaultMessage=' ' />
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-supplier-drawer"
        visible={props.addInvoiceDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
      >
        <Form layout="vertical" hideRequiredMark>


          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='schoolManagement.school.name' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12}>
            <TextBox
               // className={(props.submittedOnce && (props.duration <= 0 || !props.duration)) ? 'input-text-error' : ''}
                type='text'
                value={duration || null}
                onChange={(e) => {
                  props.updateState({
                    duration: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'schoolManagement.school.name',
                    defaultMessage: ''
                  })
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.fee }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>

          <br />


          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <label>
                <FormattedMessage id='schoolManagement.school.principle' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
            <TextBox
               // className={(props.submittedOnce && (props.duration <= 0 || !props.duration)) ? 'input-text-error' : ''}
                type='text'
                value={duration || null}
                onChange={(e) => {
                  props.updateState({
                    duration: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'schoolManagement.school.principle',
                    defaultMessage: ''
                  })
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.frequency }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>

          </Row>

          <br />

          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <span className="">
                <FormattedMessage id='schoolManagement.school.vp' defaultMessage='' /></span>
                {/* <span className="required">*</span> */}

            </Col>
            <Col span={12}>
              <TextBox
               // className={(props.submittedOnce && (props.duration <= 0 || !props.duration)) ? 'input-text-error' : ''}
                type='text'
                value={duration || null}
                onChange={(e) => {
                  props.updateState({
                    duration: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'schoolManagement.school.vp',
                    defaultMessage: ''
                  })
                }
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || !!props.duration }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>

          </Row>
          <br />
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
            <span className="">
                <FormattedMessage id='schoolManagement.school.address' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12}>
              <Dropdown
                //className={(props.submittedOnce && !props.type) ? 'input-text-error' : ''}
                items={[{ val: 'new', displayName: 'New' }, { val: 'renew', displayName: 'Renew' }]}
                valueKeyName='displayName'
                optionKeyName='val'
                value={type}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'membership.termAndFee.addDrawer.type',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      type: option.name
                    })
                  }
                }
              />
              
            </Col>
          </Row>
         
        </Form>

        <div className='form-footer'>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId='confirmation.submit'
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </div>
      </Drawer>
    </Fragment>
  );
}

export default injectIntl(SchoolDrawer);
