import React, { Component, Fragment } from "react";
import { AgGridReact } from "ag-grid-react";
import { Pagination, Tooltip } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly } from "../../../utils";
import { CONSTANTS } from '../../../static/constants';
import checkIcon from '../../../assets/images/check.png';
import crossIcon from '../../../assets/images/cross.png';
import PageBreadcrumb from "../../PageBreadcrumb";

class MembershipOption extends Component {

  render() {

    const { membershipOptionList, updateState, companyInfo, membershipOptionCount, pageNumber } = this.props;
    
    const agGridStyle = {
      height: "100%",
      width: "100%",
    };

    const showTotal = (total) => {
      return (
        this.props.intl.formatMessage({
          id: "pagination.text.total",
          defaultMessage: "",
        }) +
        ` ${total || 0} ` +
        this.props.intl.formatMessage({
          id: "pagination.text.items",
          defaultMessage: "",
        })
      );
    };

    const loadPageData = (pageNumber, pageSize) => {
      const payload = {
        companyInfo,
        relationshipId: companyInfo.relationshipId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      };
      fetchPaginationDataIfNeeded("getMembershipOptionListing", "membershipOptionList", this.props, payload);
    };

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
      const payload = {
        companyInfo,
        relationshipId: companyInfo.relationshipId,
        pageNumber: pageNumber,
        pageSize: pageSize,
      };
      props.resetPaginatedData();
      props.getMembershipOptionListing(payload);
      //this.props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    };

    const deleteHandle = (membershipOption) => {
      const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
      const modalData = {
        modalBody,
        handleSubmit: () => {
          this.props.deleteMembershipOption(membershipOption, this.props);
        },
      };
      this.props.showModal(modalData);
    };

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="membership.membershipOption.listing.header.name"
              defaultMessage=""
            />
          );
        },
        field: "name",
        resizable: true,
        minWidth: 110,
        maxWidth: 400,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="membership.membershipOption.listing.header.shortName"
              defaultMessage=""
            />
          );
        },
        field: "shortName",
        resizable: true,
        minWidth: 70,
        maxWidth: 300,
        suppressMovable: true,
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="membership.membershipOption.listing.header.type"
                defaultMessage=""
              />{" "}
            </div>
          );
        },
        field: "type",
        resizable: true,
        minWidth: 110,
        maxWidth: 300,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="membership.membershipOption.listing.header.active"
              defaultMessage=""
            />
          );
        },
        cellRendererFramework: (link) => {
          return link.value ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />;

        },
        field: "isActive",
        resizable: true,
        minWidth: 124,
        maxWidth: 300,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="membership.membershipOption.listing.header.maxFamilyMembers"
              defaultMessage=""
            />
          );
        },
        field: "maxSubMember",
        cellRendererFramework: (link) => {
          return link.data.maxSubMember ? link.data.maxSubMember : 'NA';

        },
        resizable: true,
        minWidth: 70,
        maxWidth: 300,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="membership.membershipOption.listing.header.fee"
              defaultMessage=""
            />
          );
        },
        cellRendererFramework: (link) => {
          return <span>{this.props.allTermAndFeeStatementObj[link.data.termAndFees.feesId]}</span>;
        },
        field: "feeId",
        resizable: true,
        minWidth: 250,
        maxWidth: 350,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="membership.membershipOption.listing.header.totalMembers"
              defaultMessage=""
            />
          );
        },
        field: "totalMembers",
        resizable: true,
        minWidth: 122,
        maxWidth: 300,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="membership.membershipOption.listing.header.totalAmount"
              defaultMessage=""
            />
          );
        },
        cellRendererFramework: (link) => {
          return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " "))}{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
        },
        field: "totalAmount",
        resizable: true,
        minWidth: 122,
        maxWidth: 300,
      },
      {
        headerComponentFramework: () => {
          return <FormattedMessage id='common.dateCreated' defaultMessage='' />;
        },
        field: "dateCreated",
        minWidth: 122,
        maxWidth: 300,
        suppressMovable: true,
        // hide: _isColumnToHide('salesOrderDate'),
        colId: "salesOrderDate_1",
        cellRendererFramework: (link) => {
          return <div>
            <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
              {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
            </Tooltip>
          </div>
        },
        resizable: true
      },
      {
        field: "action",
        headerComponentFramework: () => {
          return <div className="cursor-pointer">

          </div>;
        },
        colId: "action_1",
        pinned: "right",
        lockPosition: true,
        cellRendererFramework: (params) => {
          return <div>
            <span className="cursor-pointer" title={this.props.intl.formatMessage(
              {
                id: 'commons.listing.edit.tooltip.msg',
                defaultMessage: ''
              })
            } onClick={
              () => {
                this.props.updateHeaderState({
                  membership: {
                    ...this.props.membership,
                    membershipOptionData: { ...params.data },
                    addMembershipOptionDrawerVisible: true,
                    openMembershipOptionDrawerFromListing: true,
                  }
                })
              }}>
              <i className='fa fa-pencil' />
            </span>

            <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={this.props.intl.formatMessage(
              {
                id: 'commons.listing.delete.tooltip.msg',
                defaultMessage: ''
              })
            } onClick={
              () => {
                deleteHandle(params.data);
              }}>
              <i className='fa fa-trash' />
            </span>

          </div>;
        },
        minWidth: 70,
        maxWidth: 70,
        suppressMovable: true,
      }
    ];

    const newMembershipOption = () => {
      this.props.updateHeaderState({
        membership: {
          ...this.props.membership,
          openMembershipOptionDrawerFromListing: true,
          addMembershipOptionDrawerVisible: true,
        },
      });
    };
    const breadCrumbList = [
      {
        name: <FormattedMessage id='sidebar.menuItem.membership' defaultMessage='' />


      }, {
        name: <FormattedMessage id='sidebar.menuItem.membershipOption' defaultMessage='' />,
      }
    ];
    return (
      <Fragment>
        <div className="pb10">
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="view-container sales-order-details mt0">
          <div className='team-and-free'>
            <div className="right-align pb10">
              <div className="action-dropdown-btn">
                <button onClick={() => {
                  newMembershipOption();
                }}>
                  <FormattedMessage id="button.create.label" defaultMessage="" />
                </button>
              </div>
            </div>
            <div className="agGridWrapper">
              <div className="ag-theme-balham" style={agGridStyle}>
                <AgGridReact
                  // onGridReady={onGridReady}
                  columnDefs={columnDefs}
                  defaultColDef={{
                    flex: 1,
                    autoHeight: true,
                    wrapText: true,
                  }}
                  rowData={membershipOptionList[pageNumber] || []}
                  rowDragManaged={true}
                  domLayout={"autoHeight"}
                  //getRowHeight={getRowHeight}
                  suppressDragLeaveHidesColumns={true}
                  animateRows={true}
                // onModelUpdated={onModelUpdated}
                // onColumnResized={onColumnResized}
                // onColumnVisible={(params) => {
                //   params.api.resetRowHeights();
                // }}
                // onDragStopped={() => {
                //   onColumnMoved({}, true);
                // }}
                //onColumnMoved={onColumnMoved}
                ></AgGridReact>
              </div>
            </div>
            <Pagination
              size="small"
              total={membershipOptionCount}
              showTotal={showTotal}
              defaultPageSize={this.props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, this.props);
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  };
};

export default injectIntl(MembershipOption);
