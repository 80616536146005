import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PackageDetailComponent from '../../../../../components/modal/modalBody/customer/PackageDetail';
import { fetchSOPackegeDetail,fetchAmendSOPackegeDetail } from './action';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, generateLabelPdf } from '../../../../../actions/downloadFileAction';
import QualityCheckDrawer from '../../../../drawer/qualityCheck';
import { getQualityChecklistSettings, fetchAddresses,popModalFromStack,pushModalToStack} from '../../../../../actions/commonActions';
import { updatePackageQualityCheck, getSOPackageList } from '../PackageInformation/action';
import { fetchAllPackage, getSOPackageCount, deletePackage } from '../../../../customer/package/Listing/action';

class PackageDetail extends Component {

    constructor(props){
        super();
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: ''
        }
    }

    
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.packagePayload.customerId, salesOrderMasterId: this.props.packagePayload.salesOrderMasterId, soPackageMasterId: this.props.packagePayload.soPackageMasterId,amendSalesOrderMasterId:this.props.packagePayload.amendSalesOrderMasterId, locationType: "ShippingAddress" };
        if(payload.amendSalesOrderMasterId){
            fetchDataIfNeeded('fetchAmendSOPackegeDetail', 'soPackageDetail', this.props, payload, true);
           
        }else{
            
            fetchDataIfNeeded('fetchSOPackegeDetail', 'soPackageDetail', this.props, payload, true);
        }
        fetchDataIfNeeded('getQualityChecklistSettings', 'qualityChecklistSetting', this.props, payload);
        this.props.fetchAddresses(payload);

    }

    render() {
        
        return <div>
            {this.state.emailDrawerVisible  && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.qualityCheckDrawerVisible && <QualityCheckDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <PackageDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        soPackageDetail: state.so.soPackageDetail,
        addresses: state.customer.addresses,
        permissions: state.auth.permissions,
        qualityChecklistSetting: state.settings.qualityChecklistSetting,
        detailLoading: state.common.detailLoading
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSOPackegeDetail,
    generateLabelPdf,
    generatePDF, printPDF,
    getQualityChecklistSettings,
    updatePackageQualityCheck, 
    getSOPackageList,
    fetchAddresses,
    fetchAllPackage, 
    getSOPackageCount, 
    deletePackage,pushModalToStack,popModalFromStack,
    fetchAmendSOPackegeDetail
    
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PackageDetail);