import axios from "axios";
import config from "../../../config/environmentConfig";
import { SMS_ACTION_LIST, CONSTANTS, COMMON_ACTIONS} from "../../../static/constants";
import { showToasterMessage, exportExcel, getMomentDateForUIReadOnly } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
  
export const getRegisteredMemberByFormId = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegisteredMemberByFormId?relationshipId=${payload.relationshipId}&formId=${payload.formId}&pageSize=${payload.pageSize || 200}&pageNumber=${(payload.pageNumber || 1) - 1}&relationshipEmployeeId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.PARENT_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getRegisteredMemberCount = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/countRegisteredParents?relationshipId=${payload.relationshipId}&formId=${payload.formId}&relationshipEmployeeId=${payload.relationshipEmployeeId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.PARENT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const resetPaginatedData = () => {
  return (dispatch) => {
    dispatch({ type: SMS_ACTION_LIST.PARENT_LIST_PAGE_RESET });
  };
};


export const getRegisteredMemberByClass = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentClassRef/getStudentByClassId?relationshipId=${payload.relationshipId}&classId=${payload.classId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.STUDENT_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getRegisteredMemberCountByClass = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentClassRef/countStudentByClassId?relationshipId=${payload.relationshipId}&classId=${payload.classId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.STUDENT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};



export const getRegisteredMemberByCourse = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/student/getStudentByCourseId?relationshipId=${payload.relationshipId}&courseId=${payload.courseId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1) - 1}`
      )
      .then((res) => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({
          type: SMS_ACTION_LIST.STUDENT_LIST,
          data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const getRegisteredMemberCountByCourse = (payload) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/student/countStudentByCourseId?relationshipId=${payload.relationshipId}&courseId=${payload.courseId}`
      )
      .then((res) => {
        dispatch({
          type: SMS_ACTION_LIST.STUDENT_COUNT,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};

export const deleteAssignedClass = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/studentClassRef/delete`, payload)
      .then(res => {
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          relationshipEmployeeId: props.userInfo.relationshipEmployeeId,
          pageNumber: props.pageNumber || 1,
          pageSize: props.pageSize || 25
        };
        if(props.selectedForm){
          payload.formId= props.selectedForm.registrationFormSettingId
         props.getRegisteredMemberByFormId(payload);
        }else if(props.selectedClass){
          payload.classId= props.selectedClass.classId
         props.getRegisteredMemberByClass(payload);
        }else if(props.selectedCourse){
          payload.courseId= props.selectedCourse.courseId
         props.getRegisteredMemberByCourse(payload);
        }
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}

export const updateOrgRegistrationData = (payload, props) => {
  return dispatch => {
    //dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/updateOrgRegistrationData`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'User status updated' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          relationshipEmployeeId: props.userInfo.relationshipEmployeeId,
          pageNumber: props.pageNumber || 1,
          pageSize: props.pageSize || 25
        };
        if(props.selectedForm){
          payload.formId= props.selectedForm.registrationFormSettingId
         props.getRegisteredMemberByFormId(payload);
        }else if(props.selectedClass){
          payload.classId= props.selectedClass.classId
         props.getRegisteredMemberByClass(payload);
        }else if(props.selectedCourse){
          payload.courseId= props.selectedCourse.courseId
         props.getRegisteredMemberByCourse(payload);
        }
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}

const exportExcelData = (studentList) => {
  let dataList = [];
     (studentList || []).map((e, i) => {
     // let questionList= JSON.parse('[{\"optionList\":[{\"optText\":\"sfsdfsdf\"},{\"optText\":\"sdfsdfs\"}],\"questionText\":\"Test question 2\",\"answerType\":\"singleChoice\",\"answers\":\"sfsdfsdf\"},{\"optionList\":[{\"optText\":\"sdfsdf22\"},{\"optText\":\"sdfsdf\"},{\"optText\":\"sdffff\"},{\"optText\":\"qqqqqq\"}],\"questionText\":\"Test question 1\",\"answerType\":\"multiChoice\",\"answers\":[\"sdfsdf22\",\"sdffff\"]},{\"optionList\":[],\"answerType\":\"shortAnswer\",\"questionText\":\"Question 3333333333\",\"answers\":\"ggggggggttttttttttt\"}]');
    let questionList= JSON.parse(e.questionList || '[]');
      let rowData = {
        '#': i + 1,
        'Student Name': (e.firstName || '') + (e.lastName ? ' ' + e.lastName : ''),
        'Gender': e.gender|| '',
        'Status': e.studentStatus|| '',
        'Father Name': (e.fatherFirstName || '') + (e.fatherLastName ? ' ' + e.fatherLastName : ''),
        'Father Phone': e.fatherMobile || '',
        'Father Email': e.fatherEmail|| '',
        'Mother Name': (e.motherFirstName || '') + (e.motherLastName ? ' ' + e.motherLastName : ''),
        'Mother Phone': e.motherMobile|| '',
        'Mother Email': e.motherEmail|| '',
        'Student DOB': e.dob ? getMomentDateForUIReadOnly({ date: new Date(e.dob), format }) : '',
        'Grade': e.grade? 'Grade '+e.grade : '',
        'Allergies': e.allergies|| '',
        'Emergency Contact': (e.emergencyContactName || '') + (e.emergencyPhone ? ' ' + e.emergencyPhone : ''),
        'Date Created': e.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(e.dateCreated), format }) : '',
        'Subcourse': e.subcourseName || '',
        'Member': e.isOrgMember ? 'Yes': 'No',
        'Financial Assistance': e.isFinancialAssistance ? 'Yes': 'No',
        'Class': (e.schoolStudentClassRefList || []).map(classObj => classObj.className).join(', ')
      }
     
      questionList.forEach(function (qaObj, i) {
        rowData[qaObj.questionText] = typeof qaObj.answers === 'object'? (qaObj.answers || []).join(', '): qaObj.answers ;
    });
      dataList.push(rowData);
      return '';
    })
  exportExcel(dataList, 'StudentList');
}

export const exportStudentExcelData = (payload, props) => {
  return (dispatch) => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/student/getStudentByFormId?relationshipId=${payload.relationshipId}&formId=${payload.formId}&pageSize=2000&pageNumber=0&empId=${payload.relationshipEmployeeId}`)
      .then((res) => {
       let studentList = res.data;
        if (studentList && studentList.length) {
            exportExcelData(studentList);
          } else {
            showToasterMessage({messageType: "error", description: "No record selected. Please select the record(s) before proceeding."});
          }
      })
      .catch((err) => {
        showToasterMessage({messageType: "error", description:lang[((err.response || {}).data || {}).message] || "Some error occurred",});
   
      });
  };
};



export const deleteStudent = (payload, props) => {
  return dispatch => {
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/student/delete`, payload)
      .then(res => {
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          relationshipEmployeeId: props.userInfo.relationshipEmployeeId,
          pageNumber: props.pageNumber || 1,
          pageSize: props.pageSize || 200,
          formId: (props.selectedForm || {}).registrationFormSettingId
        };
        props.getRegisteredMemberByFormId && props.getRegisteredMemberByFormId(payload);
        props.getRegisteredMemberCount && props.getRegisteredMemberCount(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}

export const generateInvoice = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/generateInvoice`, payload)
      .then(res => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ messageType: 'success', description: 'Invoice generated successfully' })
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          relationshipEmployeeId: props.userInfo.relationshipEmployeeId,
          pageNumber: props.pageNumber || 1,
          pageSize: props.pageSize || 25
        };
        if(props.selectedForm){
          payload.formId= props.selectedForm.registrationFormSettingId
         props.getRegisteredMemberByFormId(payload);
        }else if(props.selectedClass){
          payload.classId= props.selectedClass.classId
         props.getRegisteredMemberByClass(payload);
        }else if(props.selectedCourse){
          payload.courseId= props.selectedCourse.courseId
         props.getRegisteredMemberByCourse(payload);
        }
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER })
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}


export const deleteOrgRegistration = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/delete`, payload)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: (res.data || {}).message || 'Deleted successfully' });
        const payload = {
          relationshipId: props.companyInfo.relationshipId,
          relationshipEmployeeId: props.userInfo.relationshipEmployeeId,
          pageNumber: props.pageNumber || 1,
          pageSize: props.pageSize || 200,
          formId: (props.selectedForm || {}).registrationFormSettingId
        };
        props.getRegisteredMemberByFormId && props.getRegisteredMemberByFormId(payload);
        props.getRegisteredMemberCount && props.getRegisteredMemberCount(payload);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.DELETE_ACTIVITY;
        }
      })
  }
}


export const getAccessToken = (payload, props) => {
  return (dispatch) => {
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/onlineUser/getOnlineUserById?relationshipId=${payload.relationshipId}&clientOnlineUserAccountId=${payload.clientOnlineUserAccountId}`
      )
      .then((res) => {
        let url = `${props.subdomainName}/portal?token=${payload.clientOnlineUserAccountId}-${res.data.accountActivationToken}&at=${payload.relationshipId}_${payload.relationshipEmployeeId}`;
        Object.assign(document.createElement('a'), {
          target: '_blank',
          rel:"noopener noreferrer",
          href: url,
        }).click();
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const fetchParentsListByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getPOSummary?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 25}&pageNumber=${(payload.pageNumber || 1)-1}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        const data = {
          pageNo: payload.pageNumber,
          list: res.data
        }
        dispatch({ type: SMS_ACTION_LIST.TEACHER_LIST, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SMS_ACTION_LIST.TEACHER_LIST;
          err.data = {
            pageNo: payload.pageNumber || 1,
            list: []
          };
        }
      })
  }
}

export const getParentslistCountByFilter = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/countPOSummary?relationshipId=${payload.relationshipId}&filters=${encodeURIComponent(JSON.stringify(payload.filters || {}))}`)
      .then(res => {
        dispatch({ type: SMS_ACTION_LIST.TEACHER_COUNT, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SMS_ACTION_LIST.TEACHER_COUNT;
        }
      })
  }
}