import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConfigurationDrawerCon from '../../../drawer/company/PayrollMeta/Configuration';
import ConfigurationComp from '../../../../components/company/PayrollMeta/Configuration';


class ConfigurationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:0
    }
    };
  

  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
     
    }
   
  }

  componentWillReceiveProps(props) {
   
  }

  render() {
      return <>

<ConfigurationComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
{this.state.lateConfigurationDrawer && <ConfigurationDrawerCon {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    
    ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    headerLoading:state.common.headerLoading,
   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationContainer);
