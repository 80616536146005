import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductPriceListingComp from '../../../../components/ecommerce/productPrice/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { getProductCount, resetPaginatedData } from '../../../product/Listing/action';
import { updateInventoryItem, approvePriceChange, rejectPriceChange, fetchProducts } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import { fetchWarehouseNames } from '../../../drawer/inventory/action';

class ProductPriceListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false,
      editingKey: ''
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
    fetchPaginationDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('getProductCount', 'productCount', this.props, payload);
    fetchDataIfNeeded('fetchWarehouseNames', 'allWarehouses', this.props, payload);
  }

  render() {
    return (
      <ProductPriceListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    products: state.product.products,
    productCount: state.product.productCount,
    permissions: state.auth.permissions,
    allWarehouses: state.inventory.allWarehouses,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchProducts,
  getProductCount,
  resetPaginatedData,
  updateInventoryItem,
  showModal,
  hideModal,
  approvePriceChange, 
  rejectPriceChange,
  pushModalToStack, 
  popModalFromStack,
  fetchWarehouseNames
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceListing);
