import { Col, Row, Button ,Divider} from "antd";
import React, { useState } from "react";
import { TextBox } from "../../../../general/TextBox";
import { Dropdown } from "../../../../general/Dropdown";

const TimeModal = (props) => {
  const [value, setValue] = useState({
    time: "",
    unit: "days",
  });

  const [error ,setError] = useState('')
  return (
    <div>
      <Row gutter={[12, 10]}>
        <Col span={12}>
          <div className="i-label">
            Time
            <span className="required">*</span>
          </div>

          <TextBox
            placeholder="Eg: 10-15"
            type="text"
            required={true}
            // value={props.timeValue || ""}
            value={value.time}
            onChange={(e) => {
              if (e.target.value) {
                setValue({ ...value, time: e.target.value });
               
              }
              
            
             
              
              //   props.updateModalState({
              //     timeValue: e.target.value,
              //   });
            }}
          />
          {/* {
                    (props.deliveryValue?.isFixed && props.fixedError) && <div style={{color:"red"}}>{props.fixedError}</div>
                  } */}
        </Col>

        <Col span={12}>
          <div className="i-label">
            Unit
            <span className="required">*</span>
          </div>
          <Dropdown
            items={["days", "minutes", "hours"]}
            placeholder="Select unit"
            // value={props.unitValue || ""}
            value={value.unit}
            onSelect={(selectedValue, option) => {
              setValue({ ...value, unit: selectedValue });

              //   props.updateModalState({ unitValue: selectedValue });
            }}
            // allowClear={true}
            // onClear={() => {
            //   this.props.updateModalState({
            //     priority: undefined,
            //   });
            // }}
          ></Dropdown>
        </Col>

      </Row>
      <div>
        {
          error? <span style={{color:"red"}}>{error}</span> : (value.time ? <span>Preview: Delivery happens within {value.time} days.</span>:'')
        }
      </div>
  
  <div>
  <Divider/>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if(value.time && value.unit){
              const val = props.deliveryValue;
              let tempFilter = val;
              let arr = tempFilter?.deliveryTime || [];
              arr.push({...value ,id:arr.length+1});
              tempFilter.deliveryTime = arr;
              props.updateState({ deliveryValue: tempFilter });
  
              props.popModalFromStack();
            }
            else{
              setError('Invalid Value')
            }

          
          }}
        >
          Submit
        </Button>
      </div>
  </div>
    
    </div>
  );
};
export default TimeModal;
