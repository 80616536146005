import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewTaxCompo from '../../../../../components/modal/modalBody/common/NewTax';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchTaxes } from '../../../../../actions/commonActions';
import { saveSingleTaxRate } from './action';

class PriceListContainer extends Component {

  render() {
    return (
      <NewTaxCompo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    taxes: state.common.taxes,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  saveSingleTaxRate,
  fetchTaxes,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PriceListContainer);
