import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Formbuilder from "../../../components/leads/Formbuilder";
import {
  fetchProjects,
  showModal,
  pushModalToStack,
  popModalFromStack,
  hideModal,
} from "../../../actions/commonActions";
import { fetchCrmLeadCampaign } from "../LeadCampaigns/action";
class FormbuilderComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...(props.leadFormData || {}),
      formFieldsSetting: JSON.parse(
        props?.leadFormData?.formFieldsSetting || "[]"
      ),
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};

    const payload = {
      relationshipId: companyInfo.relationshipId,
    };
    this.props.fetchProjects(payload);
    this.props.fetchCrmLeadCampaign(payload);
  }

  render() {
    return (
      <Formbuilder
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    projectList: state.common.projectList,
   crmCampaignList: state.crm.crmCampaignList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      fetchProjects,
      pushModalToStack,
      popModalFromStack,
      fetchCrmLeadCampaign
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormbuilderComp);
