import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { popModalFromStack } from './../../../../../actions/commonActions';
import TotalFailedComponent from '../../../../../components/modal/modalBody/EmailMarketing/TotalFailed';
import { fetchTotalFailed } from './action';


class TotalFailedCon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 25,
      pageNumber: 1,

    };
  }

  componentWillMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,

    }

    this.props.fetchTotalFailed(payload)
  }

  render() {
    return <TotalFailedComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
  }

}


const mapStateToProps = (state) => {
  return {

    failedStatusDetails: state.communicationReducer.failedStatusDetails
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchTotalFailed,
  popModalFromStack,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TotalFailedCon);