import React, { Fragment } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Form, Select, DatePicker, InputNumber, Checkbox, Button, Tooltip, Tabs, Input } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS, PAYMENT_SOURCE_BY_ID, PAYMENT_SOURCE_KEY_BY_ID } from '../../../static/constants';
import { showToasterMessage, getCurrentDateForBackend, getMomentDateForUIReadOnly, validateEmail } from '../../../utils';
import moment from 'moment-timezone';
import { ErrorMsg } from '../../general/ErrorMessage';
import Reward from '../../../assets/images/rewards.png'
import ThankYou from '../../../assets/images/thank-you.png'
import CardLogo from '../../../assets/images/card-logos.jpg'
// import ThankPic from '../../../assets/images/thanks.gif'
import config from '../../../config/environmentConfig';
import { LogoComponent } from '../../general/LogoComponent';
import { Dropdown } from '../../general/Dropdown';
import { TextArea } from '../../general/TextAreaComponent';
import * as find from 'lodash.find';
import PciPic from '../../../assets/images/pci-pic.jpg';
import GiftPic from '../../../assets/images/gift-pic.png'

const { Option } = Select;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
window.CommunicationHandler = {};


const fixedDecimalNumber = (number) => {
    return (Number(number || 0)).toFixed(CONSTANTS.DEFAULT_DECIMAL_NUMBER_LENGTH);
}


const getAddressInfoFragment = (obj, additionInfo) => {

    const fragmentObj = <div>
        {
            obj.streetAddress1
                ? <span className='billing-address'>{obj.streetAddress1}
                </span>
                : ''
        }
        {
            obj.streetAddress2
                ? <span className='billing-address'> {obj.streetAddress2}
                </span>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''}${obj.stateName ? ', ' + obj.stateName : ''}${obj.zipCode ? ', ' + obj.zipCode : ''}`
                    }
                </div>
                : ''
        }
        {/* {
            obj.countryName
                ? <div className='billing-address'>
                    {
                        `${obj.countryName || ''}`
                    }
                </div>
                : ''
        } */}
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='Ph' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='Email' /> - {additionInfo.email}
                </div> :
                ''
        }
    </div>
    return fragmentObj;
}
const { TabPane } = Tabs;

const proccessPayment = (props) => {
    if (!props.amountToDonate) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'missing.payment.amount' }) });;
    }
    if (!props.projectMasterId && !props.fundraisingCampaignId && !props.selectedCampaign && !props.selectedProject) {
        return showToasterMessage({ description: 'Either category or campaign name is required' });
    }
    if (!props.firstName) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'first.name.required' }) });
    }
    if (!props.email ) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'login.email.requiredEmail' }) });
    }
    if (!validateEmail(props.email) ) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'commmon.email.invalidEmail' }) });
    }
    if (props.period !== 'oneTimePayment' && !props.paymentStartDate) {
        return showToasterMessage({ description: props.intl.formatMessage({ id: 'paymentStartDate.validationMessage' }) });
    }
    if (props.phoneNumber && props.phoneNumber.length > 0 && props.phoneNumber.length <10 ) {
        return showToasterMessage({
            messageType: 'error', description: props.intl.formatMessage({ id: 'phoneNumber.incorrect.message' })
        });;
    }
    let payload = {
        relationshipId: props.relationshipId,
        alpidePaymentGatewayId: props.paymentGatewayId,
        amount: props.amountToDonate || 0,
        color: "Blue",
        payButtonName: "Donate",
        isCardCodeRequired: 0,
        isShowCreditCard: 1,
        isShowBankAccount: 0,
        isShowCaptcha: 0,
        isShowBillingAddress: 1,
        isBillingAddressRequired: 1,
        isShowEmail: 1,
        isEmailRequired: 1,
        isAddPaymentProfile: 0,
        startDate: getCurrentDateForBackend(props.paymentStartDate || new Date()),
        totalOccurrences: props.neverExpires ? 9999 : props.noOfPayment,
        projectMasterId: props.projectMasterId || (props.selectedProject||{}).projectMasterId,
        ledgerAccountId: props.ledgerAccountId,
        fundraisingCampaignId: props.fundraisingCampaignId || (props.selectedCampaign||{}).fundraisingCampaignId,
        firstName: props.firstName,
        lastName: props.lastName,
        emailAddress: props.email,
        phoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? ('+' + props.companyInfo.countryCallingCode + props.phoneNumber) : props.phoneNumber ,
        email: props.email,
        donorPhoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? ('+' + props.companyInfo.countryCallingCode + props.phoneNumber) : props.phoneNumber ,
        isAnonymous: props.isAnonymous ? 1 : 0,
        isGiftAidIt: props.isGiftAidIt || 0,
        remarks: props.remarks
    }
    if (props.period === 'weekly') {
        payload.intervalLength = 7;
        //  payload.unit = 'days';
    } else if (props.period === 'monthly') {
        payload.intervalLength = 30;
        // payload.unit = 'months';
    }
    // else if (props.period === 'yearly') {
    //     payload.intervalLength = 12;
    //    // payload.unit = 'months';
    // }
    props.getFormToken(payload, props);
}

function PaymnetSetup(props) {


    function parseQueryString(str) {
        var vars = [];
        var arr = str.split('&');
        var pair;
        for (var i = 0; i < arr.length; i++) {
            pair = arr[i].split('=');
            vars[pair[0]] = unescape(pair[1]);
        }
        return vars;
    }

    window.CommunicationHandler.onReceiveCommunication = function (argument = {}) {

        var params = parseQueryString(argument.qstr || '')

        switch (params['action']) {
            case "resizeWindow":
                 break;
            case "successfulSave":
                 break;
            case "cancel":
                
                //window.location.href = "<?php echo $_SESSION['callback_uri']; ?>&status=cancelled";
                break;

            case "transactResponse":
                var transResponse = JSON.parse(params['response']);

                /*
                1 -- Approved
                2 -- Declined
                3 -- Error
                4 -- Held for Review
                */
                var responseCode = transResponse.responseCode;
                if (responseCode === '1') {
                    props.updateState({
                        paymentStatus: 'success',
                        transactionId: transResponse.transId,
                        paymentReference: transResponse.refId
                    })

                } else if (responseCode === '3' || responseCode === '2') {
                    props.updateState({
                        paymentStatus: 'failed',
                        transactionId: transResponse.transId,
                        paymentReference: transResponse.refId
                    })
                    return false;
                }
                else if (responseCode === '4') {
                    props.updateState({
                        paymentStatus: 'hold',
                        transactionId: transResponse.transId,
                        paymentReference: transResponse.refId
                    })
                    return false;
                }

                let requestObj = {
                    authNetResponseData: params['response'],
                    amount: transResponse.totalAmount,
                    transactionId: transResponse.transId,
                    payment_reference: transResponse.refId,
                    isSubscription: props.period === 'oneTimePayment' ? 0 : 1,
                    subscriptionStartDate: getCurrentDateForBackend(props.paymentStartDate || new Date()),
                    noOfPayments: props.neverExpires ? 9999 : props.noOfPayment,
                    paymentSource: PAYMENT_SOURCE_KEY_BY_ID[props.sourceId], // - whatsapp, webDonation, text2donate
                    relationshipId: props.relationshipId,
                    cardHolderName: ((transResponse.billTo || {}).firstName || '') + ' ' + ((transResponse.billTo || {}).lastName || ''),
                    customerId: props.customerId,
                    formId: props.formId,
                    alpidePaymentGatewayId: props.paymentGatewayId,
                    projectMasterId: props.projectMasterId || (props.selectedProject||{}).projectMasterId,
                    ledgerAccountId: props.ledgerAccountId,
                    fundraisingCampaignId: props.fundraisingCampaignId || (props.selectedCampaign||{}).fundraisingCampaignId,
                    pledgeId: props.pledgeId,
                    teamMasterId: props.teamMasterId,
                    teamMemberId: props.teamMemberId,
                    firstName: props.firstName,
                    lastName: props.lastName,
                    emailAddress: props.email,
                    phoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? ('+' + props.companyInfo.countryCallingCode + props.phoneNumber) : props.phoneNumber,
                    email: props.email,
                    donorPhoneNumber: (props.phoneNumber && props.companyInfo.countryCallingCode) ? ('+' + props.companyInfo.countryCallingCode + props.phoneNumber) : props.phoneNumber ,
                    isAnonymous: props.isAnonymous ? 1 : 0,
                    studentRegistrationId: props.studentRegistrationId,
                    registrationFormSettingId: props.registrationFormSettingId,
                    isGiftAidIt: props.isGiftAidIt || 0,
                    remarks: props.remarks
                }
                if (props.period === 'weekly') {
                    requestObj.subscriptionIntervalLength = 7;
                } else if (props.period === 'monthly') {
                    requestObj.subscriptionIntervalLength = 30;
                }

                props.paymentSuccess(requestObj, props);
                return true;

            // $.ajax('<?php echo $phpUrl ?>/payment-success', {
            //     type: 'POST',
            //     data: { 'amount':amount, 'transactionId':transaction_id, 'payment_reference':payment_reference },
            //     success: function (data, status, xhr) {
            //         if(data == 'recurring'){
            //             window.location.href = '<?php echo $phpUrl ?>/make-subscription-profile-request';
            //             return false;
            //         }
            //         window.location.href = "<?php echo $_SESSION['callback_uri'] ?>&status=success&transactionId="+transaction_id+"&formId=<?php echo $_SESSION['formId'] ?>";
            //         return false;
            //     },
            //     error: function (jqXhr, textStatus, errorMessage) {
            //         alert("There is some issue while processing your payment.")
            //     }
            // });
            default:
                break;
        }
    }
    return (
        <div className='pay-box-por' >
            <Row gutter={24} style={{ background: props.onlineThemes.bgColor || '#06033A', color: props.onlineThemes.textColor || '#FFFFFF', padding: '5px', marginLeft: '0px', marginRight: '0px', marginBottom: '15px' }} className='ltiuy'>
                <Col span={2}>
                    <div className="brand-logo">
                        {(props.companyInfo || {}).logoAwsObjectKey ? 
                            <LogoComponent 
                                style={{background: 'transparent'}}
                                islogoImage={true} 
                                src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()} 
                            /> 
                            :
                            ''
                        }
                    </div>
                </Col>
                <Col span={12} style={{ alignSelf: 'center' }}>
                    <div className='logosizef'> {props.companyInfo.storeName}</div>
                </Col>
                <Col span={10} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {getAddressInfoFragment(props.relationshipBillingAddress, props.companyInfo)}
                </Col>
            </Row>
            {(!props.isTokenReceived && !props.paymentStatus && !props.studentRegistrationId) ?
                <div className="header-action-container pay-ment-box">
                    <div>

                        <div style={{ textAlign: 'center', paddingBottom: '0px', paddingTop: '20px' }}>
                            {(props.selectedCampaign || props.selectedProject) ?
                                <h3> <b> You are paying for {props.selectedCampaign ? props.selectedCampaign.campaignName : props.selectedProject.projectName}</b></h3>
                                :
                                <h3> <b> Welcome to {PAYMENT_SOURCE_BY_ID[props.sourceId]} Payment Form</b></h3>}
                        </div>

                        <div className='pay-text'>
                            <div className='payipuyt'>
                                <Row style={{ padding: '15px 10px 5px' }}>
                                    <Col span={11}>
                                        <Input
                                            className={(props.submittedOnce && !props.firstName) ? 'input-text-error' : ''}
                                            value={props.firstName}
                                            placeholder='First Name *'
                                            onChange={(e) => {
                                                props.updateState({
                                                    firstName: e.target.value,
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center' }}>  </Col>
                                    <Col span={11}>
                                        <Input
                                            //   className={(props.submittedOnce && !props.lastName) ? 'input-text-error' : ''}
                                            value={props.lastName}
                                            placeholder='Last Name *'
                                            onChange={(e) => {
                                                props.updateState({
                                                    lastName: e.target.value,
                                                })
                                            }}
                                        />
                                    </Col>

                                </Row>
                                <Row style={{ padding: '15px 10px 5px' }}>
                                   
                                    <Col span={11}>
                                        <Input
                                            placeholder='Email *'
                                            type='email'
                                            maxLength={45}
                                            value={props.email}
                                            onChange={(e) => {
                                                props.updateState({ email: e.target.value });
                                            }}
                                        />
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center' }}>  </Col>
                                    <Col span={11} className="input-currency-control inplt">
                                        <span className="currency-tag " style={{padding:'2px'}}>
                                            {props.companyInfo.countryCallingCode ? '+' + props.companyInfo.countryCallingCode : " "}
                                        </span>
                                        <Input
                                            className={(props.submittedOnce && !props.firstName) ? 'input-text-error' : ''}
                                            placeholder="Mobile Number"
                                            type='text'
                                            maxLength={10}
                                            value={props.phoneNumber}
                                            onChange={(e) => {
                                                const re = /^[0-9\b]+$/;
                                                if (e.target.value === '' || re.test(e.target.value)) {
                                                    props.updateState({
                                                        phoneNumber: e.target.value,
                                                    });
                                                }
                                                else if (!props.phoneNumber) {
                                                    props.updateState({ phoneNumber: '' });
                                                }
                                                //props.updateState({ phoneNumber: e.target.value });
                                            }}
                                            required={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    {/* <Col span={4} style={{padding: '10px'}}>Memo Text</Col> */}
                                    {/* <Col span={3}></Col> */}
                                    <Col span={23} className='mt10 ml10'>
                                        <TextArea
                                            style={{ width: '100%' }}
                                            className='martm'
                                            placeholder="Enter Remarks"
                                            value={props.remarks}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                props.updateState({
                                                    remarks: value,
                                                });
                                            }}
                                            autoSize={{ minRows: 1, maxRows: 2 }}
                                        ></TextArea>
                                    </Col>
                                </Row>

                                {(!props.projectMasterId && !props.fundraisingCampaignId) ? <Row style={{ padding: '15px 10px 5px' }}>
                                    <Col span={11}>
                                        <Dropdown
                                            items={props.projectList}
                                            valueKeyName='projectName'
                                            optionKeyName='projectMasterId'
                                            className={(props.submittedOnce && !props.projectMasterId) ? 'input-text-error' : ''}
                                            value={(props.selectedProject || {}).projectName}
                                            placeholder='Select Category'
                                            onSelect={(optionValue, option) => {
                                                let selectedProject = find(props.projectList || [], { projectMasterId: option.value }) || {};
                                                props.updateState({
                                                    selectedProject: selectedProject,
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col span={2} style={{ textAlign: 'center' }}> Or </Col>
                                    <Col span={11}>
                                        <Dropdown
                                            // className={(props.submittedOnce && !props.campaignId) ? 'input-text-error' : ''}
                                            items={props.allCampaignList}
                                            valueKeyName='campaignName'
                                            optionKeyName='fundraisingCampaignId'
                                            value={(props.selectedCampaign || {}).campaignName}
                                            placeholder='Select Campaign'
                                            onSelect={
                                                (optionValue, option) => {
                                                    let selectedCampaign = find(props.allCampaignList || [], { fundraisingCampaignId: option.value }) || {};
                                                    props.updateState({
                                                        selectedCampaign
                                                    })
                                                }
                                            }
                                        />
                                    </Col>

                                </Row>

                                    : ''
                                }
                                {/* <Row style={{ padding: '15px 10px 5px' }}>
                                    <Col span={11}>
                                        <Row gutter={16}>
                                            <Col className="text-right" span={16}>
                                                <span className="">
                                                    <FormattedMessage id='donateAsAnonymous.text' defaultMessage='' />
                                                </span>
                                                <span className="required">*</span>
                                            </Col>
                                            <Col span={8}>
                                                <Checkbox checked={(props.isAnonymous === 1)} onChange={(e) => {
                                                    props.updateState({
                                                        isAnonymous: e.target.checked ? 1 : 0,
                                                    })
                                                }}>
                                                </Checkbox>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row> */}
                                    
                            </div>
                            <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { props.updateState({ activeTabKey: key }) }}>
                                <TabPane tab="One Time" key="1">
                                    <button className={props.amountToDonate === 25 ? 'pay-but pay-buth' : 'pay-but'} onClick={() => { props.updateState({ amountToDonate: 25 }) }} > $25</button>
                                    <button className={props.amountToDonate === 50 ? 'pay-but pay-buth' : 'pay-but'} onClick={() => { props.updateState({ amountToDonate: 50 }) }}> $50</button>
                                    <button className={props.amountToDonate === 75 ? 'pay-but pay-buth' : 'pay-but'} onClick={() => { props.updateState({ amountToDonate: 75 }) }}> $75</button>
                                    <button className={props.amountToDonate === 100 ? 'pay-but pay-buth' : 'pay-but'} onClick={() => { props.updateState({ amountToDonate: 100 }) }}> $100</button>
                                    <button className={props.amountToDonate === 200 ? 'pay-but pay-buth' : 'pay-but'} onClick={() => { props.updateState({ amountToDonate: 200 }) }}> $200</button>
                                    <button className={props.amountToDonate === 500 ? 'pay-but pay-buth' : 'pay-but'} onClick={() => { props.updateState({ amountToDonate: 500 }) }}> $500</button>
                                    {/* {!props.isCustomeAmount ? <button className={props.isCustomeAmount ? 'pay-butl pay-buth' : 'pay-butl'} onClick={() => { props.updateState({ isCustomeAmount: true }) }}> Other</button>
                                        : */}
                                        <div className='pay-but input-currency-control limtl' style={{width: '200px'}}>

                                            <span className="currency-tag" style={{height:'43px', lineHeight:'41px'}}>
                                                <i className="fa fa-dollar"></i>
                                            </span>
                                            <InputNumber style={{height:'44px', lineHeight:'41px', width:'200px', paddingLeft:'40px'}} value={props.amountToDonate} min={0}
                                            placeholder={props.intl.formatMessage({ id: 'amount.text' })}
                                            onChange={(value) => {
                                                props.updateState({ amountToDonate: value })
                                            }} />
                                        </div>
                                    {/* } */}
                                    <div style={{ clear: 'both' }}></div>
                                    {/* <div style={{ padding: '0px 10px', height: '34px' }}>
                                        {props.isCustomeAmount ?
                                            <Col span={24} className='input-currency-control'>

                                                <span className="currency-tag">
                                                    <i className="fa fa-dollar"></i>
                                                </span>
                                                <InputNumber style={{ width: '91.2%', marginLeft: '35px' }} value={props.amountToDonate} placeholder="" min={0} onChange={(value) => {
                                                    props.updateState({ amountToDonate: value })
                                                }} />
                                            </Col> : ''

                                        }
                                    </div> */}
                                    <div className='pay-but2'><img src={Reward} alt='' style={{ marginLeft: '10px' }} /> Multiply your rewards. <span className='cursor-pointer' style={{ textDecoration: 'underline', color: '#66A9FB' }} onClick={(key) => { props.updateState({ activeTabKey: "2" }) }}> Make it Monthly!</span></div>
                                    {(props.companyInfo || {}).countryName === 'United Kingdom (UK)' ?
                                        <div style={{ padding: '20px 15px' }}>
                                            <img src={GiftPic} alt="Gift" />
                                            <div style={{ fontSize: '18px', padding: '10px 0px' }}>  <b>Boost your donations by 25% at no cast to you!</b>
                                            </div>
                                            <Row>
                                                <Col span={2}><Checkbox  checked={(props.isGiftAidIt === 1)} onChange={(e) => {
                                                                    props.updateState({
                                                                        isGiftAidIt: e.target.checked ? 1 : 0,
                                                                    })
                                                                }}/></Col>
                                                <Col span={22}>
                                                    <div style={{ fontSize: '15px', fontStyle: 'italic', fontWeight: '700' }}>Yes, I am a UK taxpayer and I woould like the
                                                        Perkin Foundation to reclaim the tax on all
                                                        qualifying donations I have made, as well as any future donations, until I notify them
                                                        otherwise</div>
                                                    <div style={{ fontSize: '13px', paddingTop: '10px' }}>
                                                        I understand that if i pay less Income Tax and/or Capital
                                                        Gains Tax than the amount of Gift Aid claimed on all my
                                                        donations in that tax year it is my responsibility to pay any difference.
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div> : ''
                                    }

                                    <button className='pay-but3' onClick={() => {
                                        proccessPayment(props);
                                    }}>Pay  {props.amountToDonate ? '$' + props.amountToDonate : ''} </button>

                                </TabPane>
                                <TabPane tab="Recurring" key="2">
                                    <Form>
                                        <div className="information pading-rii"  >
                                            <div className=" ">

                                                <Row className="pb10">
                                                    <Col span={10} className="title text-right">
                                                        <FormattedMessage id='amount.text' defaultMessage='' />
                                                    </Col>
                                                    <Col span={14} className="input-currency-control">
                                                        <span className="currency-tag">
                                                            <i className="fa fa-dollar"></i>
                                                        </span>
                                                        <InputNumber style={{ width: '100%', paddingLeft: '35px' }} value={props.amountToDonate} placeholder="" min={0} onChange={(value) => {
                                                            props.updateState({ amountToDonate: value })
                                                        }} />
                                                    </Col>
                                                </Row>
                                                <Row className="pb10">
                                                    <Col span={10} className="title text-right">
                                                        <FormattedMessage id='makeRecurring.text' defaultMessage='' />
                                                    </Col>
                                                    <Col span={14}>
                                                        <Select onChange={(value) => {
                                                            props.updateState({
                                                                period: value,
                                                            })
                                                        }}
                                                            className={(props.submittedOnce && !props.period) ? 'input-text-error' : ''}
                                                            style={{ width: '100%' }}
                                                            placeholder="Please select"
                                                            optionFilterProp="children"
                                                            value={props.period}>
                                                            <Option value="oneTimePayment"><FormattedMessage id='oneTimePayment.text' /></Option>
                                                            {/* <Option value="daily"><FormattedMessage id='recurringInvoice.setup.daily' /></Option> */}
                                                            <Option value="weekly"><FormattedMessage id='recurringInvoice.setup.weekly' /></Option>
                                                            <Option value="monthly"><FormattedMessage id='recurringInvoice.setup.monthly' /></Option>
                                                            {/* <Option value="yearly"><FormattedMessage id='recurringInvoice.setup.yearly' /></Option> */}
                                                        </Select>
                                                        <ErrorMsg
                                                            validator={() => { return !props.submittedOnce || !!props.period }}
                                                            message={<FormattedMessage id='addItem.validation' values={{ value: 'This Field' }} />}
                                                        />
                                                    </Col>
                                                </Row>
                                                <div style={{ height: '70px' }}>
                                                    {(props.period && props.period !== "oneTimePayment") ?
                                                        <Row className="pb10">
                                                            <Col span={10} className="title text-right">
                                                                <Tooltip placement="top" title={props.intl.formatMessage({ id: 'paymentStartDate.helpMessage' })}>
                                                                    <span className="tooltip-title">
                                                                        <FormattedMessage id='paymentStartDate.text' defaultMessage='' />
                                                                    </span>
                                                                </Tooltip>
                                                            </Col>
                                                            <Col span={14}>
                                                                <DatePicker
                                                                    allowClear={false}
                                                                    format={format}
                                                                    // style={{ width: 250 }}
                                                                    // disabledDate={invalidEndDate}
                                                                    key={moment(new Date(), format)}
                                                                    defaultValue={props.paymentStartDate ? moment(new Date(props.paymentStartDate), format) : null}
                                                                    onChange={(selectedDate) => {
                                                                        props.updateState({
                                                                            paymentStartDate: selectedDate
                                                                        })
                                                                    }} />
                                                            </Col>
                                                        </Row> : ''
                                                    }

                                                    {(props.period && props.period !== "oneTimePayment") ?
                                                        <Row className="pb10">
                                                            <Col span={10} className="title text-right">
                                                                <FormattedMessage id='noOfPayment.text' defaultMessage='' />
                                                            </Col>
                                                            <Col span={14}>
                                                                {!props.neverExpires && <div>
                                                                    <InputNumber style={{ width: '100%' }} value={props.noOfPayment} placeholder="" min={1} onChange={(value) => {
                                                                        props.updateState({ noOfPayment: value })
                                                                    }} /></div>
                                                                }
                                                                <Checkbox checked={(props.neverExpires === 1)} onChange={(e) => {
                                                                    props.updateState({
                                                                        neverExpires: e.target.checked ? 1 : 0,
                                                                    })
                                                                }} /> <FormattedMessage id='recurringInvoice.setup.neverExpires' defaultMessage='' />
                                                            </Col>
                                                        </Row> : ''
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </Form>
                                    <button className='pay-but2'><img src={ThankYou} alt='' style={{ margin: '0px  10px' }} /> Thank you for your recurring payments</button>
                                    {/* 
            <div className="align-footer" style={{ borderTop: '1px solid #e5e5e5', width: '500px', marginLeft: '400px' }}> */}
                                    
                                    
                                    <div style={{ borderTop: '1px solid #e5e5e5', marginTop: '0px' }}>


                                        {/* <CustomButton
                                    intlId='confirmation.cancel'
                                    defaultMessage=''
                                    type='default'
                                    key={0}
                                    style={{ marginRight: 7 }}
                                    onClick={props.popModalFromStack}
                                /> */}
                                        <Button className='pay-but3'
                                            type='primary'
                                            htmlType='submit'
                                            key={1}
                                            // style={{ float: 'right' }}
                                            onClick={() => {
                                                props.updateState({ submittedOnce: true });

                                                if (!props.amountToDonate) {
                                                    return showToasterMessage({ description: props.intl.formatMessage({ id: 'missing.payment.amount' }) });;
                                                }
                                                if (!props.period) {
                                                    return;
                                                }
                                                if (!props.neverExpires && !props.noOfPayment) {
                                                    return showToasterMessage({ description: 'Please enter No. of Payment' });
                                                }


                                                proccessPayment(props);

                                                // props.getAuthNetPaymentUrl(props, props.projectData, props.period === 'oneTimePayment' ? undefined : payload)


                                            }}

                                        >  <FormattedMessage id='pay.text' />  <span style={{ padding: '0px 0px 0px 8px' }}>  {props.amountToDonate ? '$' + props.amountToDonate : ''}</span>

                                        </Button>
                                    </div>
                                </TabPane>

                            </Tabs>
                        </div>
                        
                    </div>
                    <div className='pay-card-pic'>
                        <img src={CardLogo} alt=''></img>
                    </div>

                </div> :
                <div>
                    {!props.paymentStatus ?
                        <div className="header-action-container pay-ment-box-form">
                            <div style={{ padding: '15px', fontSize: '18px', textAlign: 'right', }}>
                                <img src={PciPic} style={{ float: 'right', marginleft: '15px' }} alt="" /> Payment form is PCI compliant. Pay confidently.
                            </div>
                            {/* <form method="post" action="https://test.authorize.net/payment/payment" id="formAuthorizeNetTestPage" name="formAuthorizeNetTestPage">
                    <input type="hidden" name="token" value={props.hostedPaymentPageToken} />
                    <button id="btnContinue" style={{ display: 'none' }}>Continue to next page</button>
                </form> */}
                            {/* {setTimeout(function () { document.getElementById("btnContinue").click() }, 20)} */}

                            {/* <div style="width:100%; background:#797676; font-family:Segoe UI,Droid Sans,Tahoma,Arial,sans-serif; padding:10px 0px;">
                    <div style="width:100%; max-width: 1000px; margin:auto;">
                        {/* <div style="float:left; max-width:40%; font-size: 20px;color: #fff;"><?php echo $storeName;?></div>
                        <div style="float:right; max-width:40%;color: #fff;font-size: 15px;"><?php echo $storeAddress;?></div> 
                    </div>
                    <div style="clear:both;"></div>
                </div> */}
                            {props.hostedPaymentPageToken ? <div>
                                <div id="iframe_holder" className="center-block" style={{ width: '90%', maxWidth: '1000px', minWidth: '750px', margin: 'auto', display: 'initial' }} data-mediator="payment-form-loader">
                                    <iframe title=" " id="load_payment" className="embed-responsive-item" name="load_payment" width="100%" height="900" frameBorder="0" scrolling="yes"></iframe>

                                    <form id="send_hptoken" action={config.PAYMENT_FORM_ACTION} method="post" target="load_payment">
                                        <input type="hidden" name="token" value={props.hostedPaymentPageToken} />
                                    </form>
                                </div>
                                {setTimeout(function () { document.forms["send_hptoken"].submit() }, 20)}
                                <div className='clearfix'></div>
                            </div> : ''}
                        </div> :
                        <div className="header-action-container pay-ment-box-status" style={{ padding: '0px 0px 0px' }}>
                            {(props.paymentStatus === 'success' || props.paymentStatus === 'hold') && <div style={{height:'80vh', paddingTop:'50px'}}>
                                {/* <div style={{ fontSize: '16px', textAlign: 'center' }}><b>{props.txStatusMessage || props.intl.formatMessage({ id: 'payOnline.success' })} </b></div> */}
                                <div className="prl30">
                                    <br />
                                   
                                    <div style={{}}>
                    <div className='confirm-boxs1'>
                        <div className='done-popup'>
                            <div className='ant-modal-title' style={{ textAlign: 'center', fontSize: '20px', color: '#fff', fontWeight: '300' }}>
                                {/* <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg> */}

                                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                </svg>
                                {/* Payment Complete! */}
                                {/* <b>Thank you for your pledge. Your pledge has been submitted successfully</b> */}
                                {/* Thank you for Your payment has been processed successfully */}
                                <div style={{ fontSize: '20px', textAlign: 'center' }}><b>{props.txStatusMessage || props.intl.formatMessage({ id: 'payOnline.success' })} </b></div>
                            </div>

                        </div>
                        <div style={{ padding: '50px 0px', textAlign: 'center', fontSize: '16px' }}>
<Row className="pb10">
                                        <Col span={12} className="title text-right">
                                            <FormattedMessage id='modal.txDetails.payment.invoiceAmount' defaultMessage='' />
                                        </Col>
                                        <Col span={12} style={{textAlign:'left', paddingLeft:'30px'}}>
                                            $ {fixedDecimalNumber(props.amountToDonate)}
                                        </Col>
                                    </Row>
                                    <Row className="pb10">
                                        <Col span={12} className="title text-right">
                                            <FormattedMessage id='paymentMethod.text' defaultMessage='' />
                                        </Col>
                                        <Col span={12} style={{textAlign:'left', paddingLeft:'30px'}}>
                                            <FormattedMessage id='creditCard.text' />
                                        </Col>
                                    </Row>

                                    <Row className="pb10">
                                        <Col span={12} className="title text-right">
                                            <FormattedMessage id='modal.txDetails.payment.paymentDate' defaultMessage='' />
                                        </Col>
                                        <Col span={12} style={{textAlign:'left', paddingLeft:'30px'}}>
                                            {getMomentDateForUIReadOnly({ date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT })}
                                        </Col>
                                    </Row>

                                    <Row className="pb10">
                                        <Col span={12} className="title text-right">
                                            <FormattedMessage id='expense.Transaction.number' defaultMessage='' />
                                        </Col>
                                        <Col span={12} style={{textAlign:'left', paddingLeft:'30px'}}>
                                            {props.transactionId}
                                        </Col>
                                    </Row>

                                    <div style={{ fontSize: '14px', textAlign: 'center' }}><b> <FormattedMessage id='email.confirmation.sent.message' /> </b></div>
                        </div>

                    </div>
                </div>
                                       
                {/* <Row>
                                        <Col span={8}></Col>
                                        <Col span={8} style={{ textAlign: 'center', padding: '20px 0px' }}><img src={ThankPic} alt='' /> </Col>
                                        <Col span={8}>  </Col>
                                    </Row> */}
                                    {/* <Row className="pb10">
                                        <Col span={12} className="title text-right">
                                            <FormattedMessage id='modal.txDetails.payment.invoiceAmount' defaultMessage='' />
                                        </Col>
                                        <Col span={12}>
                                            $ {fixedDecimalNumber(props.amountToDonate)}
                                        </Col>
                                    </Row>
                                    <Row className="pb10">
                                        <Col span={12} className="title text-right">
                                            <FormattedMessage id='paymentMethod.text' defaultMessage='' />
                                        </Col>
                                        <Col span={12}>
                                            <FormattedMessage id='creditCard.text' />
                                        </Col>
                                    </Row>

                                    <Row className="pb10">
                                        <Col span={12} className="title text-right">
                                            <FormattedMessage id='modal.txDetails.payment.paymentDate' defaultMessage='' />
                                        </Col>
                                        <Col span={12}>
                                            {getMomentDateForUIReadOnly({ date: new Date(), format: CONSTANTS.DISPLAY_DATE_FORMAT })}
                                        </Col>
                                    </Row>

                                    <Row className="pb10">
                                        <Col span={12} className="title text-right">
                                            <FormattedMessage id='expense.Transaction.number' defaultMessage='' />
                                        </Col>
                                        <Col span={12}>
                                            {props.transactionId}
                                        </Col>
                                    </Row> */}
                                </div>
                                {/* <div style={{ fontSize: '14px', textAlign: 'center' }}><b> <FormattedMessage id='email.confirmation.sent.message' /> </b></div> */}
                            </div>
                            }
                        </div>
                    }
                </div>
            }

            <div style={{ clear: 'both' }}></div>
            <div className='pay-fot'>
                <Row gutter={24} className='pay-footer-box'>
                    <Col span={9}><span style={{ float: 'left', paddingTop: '5px' }}>{props.onlineThemes.footerMessage || 'All Rights Reserved - ' + props.companyInfo.storeName} </span> </Col>
                    <Col span={6} style={{ textAlign: 'center', color: '#333' }}>
                        {props.onlineThemes.facebookLink ? <i className="fa fa-facebook" style={{ margin: '0px 4px 0px 5px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.facebookLink) { return '' }
                                let url = props.onlineThemes.facebookLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.instagramLink ? <i className="fa fa-instagram" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.instagramLink) { return '' }
                                let url = props.onlineThemes.instagramLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.youtubeLink ? <i className="fa fa-youtube-play" style={{ margin: '0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.youtubeLink) { return '' }
                                let url = props.onlineThemes.youtubeLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.twitterLink ? <i className="fa fa-twitter" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.twitterLink) { return '' }
                                let url = props.onlineThemes.twitterLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                        {props.onlineThemes.link ? <i className="fa fa-link" style={{ margin: '0px 5px 0px 4px', cursor: 'pointer', height: '30px', textAlign: 'center', lineHeight: '26px', width: '30px', background: '#bdbdbd', borderRadius: '50%' }}
                            onClick={() => {
                                if (!props.onlineThemes.link) { return '' }
                                let url = props.onlineThemes.link;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                            }}></i> : ''}

                    </Col>
                    <Col span={9}>
                        <span style={{ float: 'right', paddingTop: '5px' }}>Powered by <a href="https://www.alpide.com/" target={'_blank'}>alpide.com</a></span>
                    </Col>
                </Row>
                <div style={{ clear: 'both' }}></div>
            </div>
            <div className='clearfix'></div>
        </div>
    );

}

export default injectIntl(PaymnetSetup);
