import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Col, Row, Input } from 'antd';
import { MODAL_TYPE } from '../../../static/constants';
import { CustomButton } from '../../general/CustomButton';
import { Dropdown } from '../../general/Dropdown';
import { TextBox } from '../../general/TextBox';
import { ErrorMsg } from '../../general/ErrorMessage';

class TransportDataDrawer extends React.Component {
  render() {
    const closeDrawer = () => {
      this.props.updateState({
        transportDataDrawerVisible: false
      })
    }

    const handleAddTransportData = () => {
      if(!this.props.nameOfTransport){
        this.props.updateDrawerState({submittedOnce: true});
        return;
      }

      let obj = {
        nameOfTransport: this.props.nameOfTransport,
        vehicleNumber: this.props.vehicleNumber,
        roadPermitNumber: this.props.roadPermitNumber,
        freightType: this.props.freightType,
        consignee: this.props.consignee,
        ewayBillNo: this.props.ewayBillNo,
        station: this.props.station,
        gstinNo: this.props.station
      }
      this.props.addTransportDataToSO(obj);
      closeDrawer();
    }


    const addItemConfirmationModal = (props, text, modalType) => {
      const { showModal, companyInfo } = props;
      const labelName = 'Quality Check';
      const modalBody = <span>
        <FormattedMessage
          id='addItem.confirmation'
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
    </span>;

      let addFunc;

      switch (modalType) {
        case MODAL_TYPE.FREIGHT_TYPE: {
          addFunc = props.addFreightType;
          break;
        }
        default: {
          addFunc = () => { }
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          addFunc({ text: text, relationshipId: companyInfo.relationshipId },props)
          props.hideModal();
        },
      };
      showModal(modalData);
    }

    const getModalTitle = (newTextObj, modalType) => {
      let title;

      switch (modalType) {
        case MODAL_TYPE.FREIGHT_TYPE: {
          title = <FormattedMessage id='addItem.text.freightType' defaultMessage='' />;
          break;
        }
        default:
          title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
      }
      return title;
    }

    const handleNewItemAddition = (props, payload, modalType) => {
      const formData = payload.formData;
      const { showModal } = props;

      if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
        addItemConfirmationModal(props, formData.textBoxValue, modalType);
      } else {
        const data = {
          title: getModalTitle(payload, modalType),
          formData,
          modalData: { modalType, data: payload },
          handleSubmit: (formData = {}) => {
            if (formData.textBoxValue && formData.textBoxValue.length) {
              addItemConfirmationModal(props, formData.textBoxValue, modalType);
            } else {
              data.formData.submittedOnce = true;
              showModal(data);
            }
          }
        };
        showModal(data);
      }
    }

    const deleteClickHandler = (props, modalType, payload) => {
      const { showModal, companyInfo } = props;
      const modalBody =
        <FormattedMessage
          id='deleteItem.confirmation'
          defaultMessage={``}
          values={{ value: payload.text }}
        />;

      let deleteFunc;

      switch (modalType) {
        case MODAL_TYPE.FREIGHT_TYPE: {
          deleteFunc = props.deleteFreightType;
          break;
        }
        default: {
          deleteFunc = () => { }
        }
      }

      const modalData = {
        modalBody,
        handleSubmit: () => {
          deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId })
          props.hideModal();
        },
      };
      showModal(modalData);
    }

    return (
      <Drawer
        title={this.props.title}
        width={720}
        zIndex={1}
        maskClosable={false}
        keyboard={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={this.props.transportDataDrawerVisible}
        footer={<>
          <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            onClick={closeDrawer}
          />
          <CustomButton
            onClick={() => {
              handleAddTransportData();
            }}
            type='primary'
            htmlType='submit'
            intlId='forgot.btn.addTransportData'
            defaultMessage=''
          />
        </>}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className='i-label'>
              Transport Name<span className='required'>*</span>
            </div>
            <TextBox 
              placeholder='Transport Name'
              type='text'
              value={this.props.nameOfTransport}
              onChange={(e) => {
                this.props.updateDrawerState({
                  nameOfTransport: e.target.value
                });
              }} 
            />
            <ErrorMsg
              validator={() => { return !this.props.submittedOnce || this.props.nameOfTransport }}
              message={"This field is required"} 
            />
          </Col>

          <Col span={12}>
            <div className='i-label'>
              Vehicle Number
            </div>
            <TextBox 
              placeholder='Vehicle Number'
              type='text'
              value={this.props.vehicleNumber}
              onChange={(e) => {
                this.props.updateDrawerState({
                  vehicleNumber: e.target.value
                });
              }} 
            />
          </Col>

          <Col span={12}>
            <div className='i-label'>
              Road Permit No.
            </div>
          
            <TextBox placeholder='Road Permit No.'
              type='text'
              value={this.props.roadPermitNumber}
              onChange={(e) => {
                this.props.updateDrawerState({
                  roadPermitNumber: e.target.value
                });
              }} />
          </Col>
          <Col span={12}>
            <div className='i-label'>
              Freight Type
            </div>
          
            <Dropdown
              items={this.props.freightList || []}
              value={this.props.freightType}
              placeholder='Select Freight Type'
              valueKeyName='freightTypeName'
              optionKeyName='freightTypeId'
              canAddNew={true}
              canDelete={true}
              deleteClickHandler={(payload) => {
                deleteClickHandler(this.props, MODAL_TYPE.FREIGHT_TYPE, payload);
              }}
              onAddNewClick={(payload) => {
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false
                }
                handleNewItemAddition(this.props, payload, MODAL_TYPE.FREIGHT_TYPE)
              }}
              onSelect={(selectedValue, option) => {
                this.props.updateDrawerState({ freightType: option.name, freightTypeId: option.key })
              }}
            />
          </Col>

          <Col span={12}>
            <div className='i-label'>
              Consignee
            </div>
        
            <TextBox 
              placeholder='Consignee'
              type='text'
              value={this.props.consignee}
              onChange={(e) => {
                this.props.updateDrawerState({
                  consignee: e.target.value
                });
              }} 
            />
          </Col>
          <Col span={12} className="text-right">
            <div className='i-label'>
              eWay Bill No.
            </div>
          
            <TextBox 
              placeholder='eWay Bill No.'
              type='text'
              value={this.props.ewayBillNo}
              onChange={(e) => {
                this.props.updateDrawerState({
                  ewayBillNo: e.target.value
                });
              }} 
            />
          </Col>

          <Col span={12}>
            <div className='i-label'>
              Station
            </div>
          
            <TextBox
              placeholder='Station'
              type='text'
              value={this.props.station}
              onChange={(e) => {
                this.props.updateDrawerState({
                  station: e.target.value
                });
              }} 
            />
          </Col>
          <Col span={12}>
            <div className='i-label'>
              Gstin No.
            </div>
          
            <TextBox
              placeholder='Station'
              type='text'
              value={this.props.gstinNo}
              onChange={(e) => {
                this.props.updateDrawerState({
                  gstinNo: e.target.value
                });
              }} />
          </Col>
        </Row>
      </Drawer>
    );
  }
}

export default injectIntl(TransportDataDrawer);
