import React from 'react';
import { injectIntl } from 'react-intl';
import './../../../../assets/stylesheets/common/invoice.scss';
import PackageDetail from "./PackageDetail";
import ProductDetail from './ProductDetail';
import HeaderPackage from './headerPackage';
import { Divider } from 'antd';

const PackageComponent = (props) => {

  return (
    <>
     <div className="txn-header">
      <HeaderPackage {...props}/>
     </div>
    <div className='txn-body'> 
      <div className="txn-details-container">
        <PackageDetail {...props} />
      </div>
      <Divider style={{margin: '0 0 10px 0px'}}/>
      <div className="product-details-container">
        <ProductDetail {...props} />
      </div>
    </div>
      
    </>
  );
}


export default injectIntl(PackageComponent);