import React,{useState} from "react";
import { Drawer, Row, Col, DatePicker } from "antd";
import {
  showToasterMessage,
  getCurrentDateForBackend,
  getMomentDateForUI,
} from "../../../utils";
// import { TextBox } from "../../general/TextBox";
import { CONSTANTS, ICONS } from "../../../static/constants";
import { Dropdown} from "../../general/Dropdown";
import { UndoOutlined } from "@ant-design/icons";
import { CustomButton } from "../../general/CustomButton";
import { MultipleSelectDropdown } from "../../general/MultipleSelectDropdown";


const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker

const AllLeadsFilter = (props) => {
  const { companyInfo } = props;

  const [filters, setFilters] = useState(props.leadFilters || {});

  const closeDrawer = () => {
    props.updateState({
      allLeadsFilterDrawerVisible: false,
      leadFilters: filters
    });
  };

  const applyFilter = () => {
    if ( !(Object.keys(filters) || []).length ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please select at least one filter",
      });
    }

    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: props.pageSize || 25,
      // crmLeadFormSettingId: props.searchedCrmLeadFormSettingId || 0,
      // startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : '',
      // endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : '',
      filters:{
        ...filters,
        startDate: filters.startDate ? getCurrentDateForBackend(filters.startDate) : undefined,
        endDate: filters.endDate ? getCurrentDateForBackend(filters.endDate) : undefined,
        leadAssignTo: (filters.leadAssignTo || []).length ? filters.leadAssignTo.map(String).join(", ") : undefined
      },
    };
    props.fetchAllLeadList(payload);
    props.getAllLeadListCount(payload);
  };

  const clearFilter = () => {
    setFilters({});
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: props.pageNumber || 1,
      pageSize: props.pageSize || 25,
    }
    props.fetchAllLeadList(payload);
  };

  const rowGutter = [24, 16];

  return (
    <Drawer
      title={'Leads Filter'}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.allLeadsFilterDrawerVisible}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />
          <div>
            <CustomButton
              intlId="common.clearFilter.txt"
              defaultMessage=""
              type="default"
              key={0}
              onClick={clearFilter}
              btnIcon={
                <>
                  <UndoOutlined /> &nbsp;
                </>
              }
              style={{ marginRight: "10px" }}
            />
            <CustomButton
              intlId="common.applyFilter.txt"
              defaultMessage=""
              htmlType="submit"
              onClick={applyFilter}
              key={1}
              btnIcon={
                <>
                  <i className={ICONS.FILTER} /> &nbsp;
                </>
              }
            />
          </div>
        </>
      }
    >
      <Row gutter={rowGutter}>

        <Col span={12}>
          <div className="i-label">Project</div>
          <Dropdown 
            items={props.projectList}
            valueKeyName='projectName'
            optionKeyName='projectMasterId'
            value={filters.projectMasterId}
            placeholder='Select Project'
            onSelect={(key, options) => {
              setFilters({
                ...filters,
                projectMasterId: key,
                projectName:options.name
              })
            }}
            allowClear={true}
            onClear={()=>setFilters({...filters, projectMasterId: undefined, projectName: undefined})}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">
            Form Name
          </div>
          <Dropdown
            items={props.crmLeadFormList[1] || []}
            valueKeyName="formName"
            optionKeyName="crmLeadFormSettingId"
            value={filters.crmLeadFormSettingId}
            placeholder="Select Form"
            onSelect={(val, options) => {
              setFilters({...filters, crmLeadFormSettingId: val })
            }}
            allowClear={true}
            onClear={()=>setFilters({...filters, crmLeadFormSettingId: undefined })}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Assigned To</div>
          <MultipleSelectDropdown
            style={{ width: '100%', maxHeight: '38px' }}
            items={props.leadAssignList || []}
            placeholder={'Assign to'}
            mode='multiple'
            // onDeselect={()=>{return}}
            selectedValue={filters.leadAssignTo}
            valueKeyName='fullName'
            valueKeyName2='hierarchyName'
            optionKeyName='relationshipEmployeeId'
            onSelect={(empId, options) => {
              let tempList = filters.leadAssignTo || [];
              tempList.push(empId);
              setFilters({...filters, leadAssignTo: tempList });
            }}
            allowClear={true}
            onClear={()=>setFilters({...filters, leadAssignTo: undefined })}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Source</div>
          <Dropdown
            items={props.crmLeadSourceList}
            placeholder='Select Source'
            valueKeyName='leadSourceName'
            optionKeyName='crmLeadSourceId'
            value={filters.sourceId}
            onSelect={(val, options) => {
              setFilters({
                ...filters,
                sourceId: val,
                sourceName: options.name
              })
            }}
            allowClear={true}
            onClear={()=>setFilters({...filters, sourceId: undefined, sourceName: undefined })}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Status</div>
          <Dropdown
            items={props.crmLeadStatusList}
            placeholder='Select Status'
            valueKeyName='statusName'
            optionKeyName='leadStatusId'
            value={filters.statusId || ''}
            onSelect={(id, options) => {
              setFilters({
                ...filters,
                statusId: id,
                statusName: options.name
              })
            }}
            allowClear={true}
            onClear={()=>setFilters({...filters, statusId: undefined, statusName: undefined })}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">Reminder</div>
          <Dropdown
            items={['Upcoming', 'Expired']}
            placeholder={'Select Reminder'}
            value={filters.reminderType}
            onSelect={(val) => {
              setFilters({
                ...filters,
                reminderType: val
              })
            }}
            allowClear={true}
            onClear={()=>setFilters({...filters, reminderType: undefined })}
          />
        </Col>

        <Col span={12}>
          <div className="i-label">
            Date Created
          </div>
          <RangePicker
            placeholder={['Start date', 'End date']}
            value={[filters.startDate ? getMomentDateForUI({ date: new Date(filters.startDate), format }) : null, filters.endDate ? getMomentDateForUI({ date: new Date(filters.endDate), format }) : null]}
            format={format}
            onChange={(dates) => {
              if (dates) {
                setFilters({...filters, startDate: dates[0], endDate: dates[1] });
              } else {
                setFilters({ ...filters, startDate: undefined, endDate: undefined });
              }
            }}
            allowClear={true}
            onClear={()=>setFilters({...filters, startDate: undefined, endDate: undefined })}
          />
        </Col>

      </Row>
    </Drawer>
  );
};

export default AllLeadsFilter;
