import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { injectIntl } from 'react-intl';


function FoodComp(props) {
    return (
        <div>
          Food
        </div>
    );

}

export default injectIntl(FoodComp);
