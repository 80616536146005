import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HolidayComp from '../../../../components/company/LeaveMeta/Holiday';
import HolidayDrawerCon from '../../../drawer/company/LeaveMeta/Holiday';


class HolidayContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:0
    }
    };
  

  componentDidMount(props) {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
     
    }
   
  }

  componentWillReceiveProps(props) {
   
  }

  render() {
      return <>

<HolidayComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
{this.state.holidayDrawer && <HolidayDrawerCon {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
      </>
    
    ;
  }

}

const mapStateToProps = (state) => {
  return {

    companyInfo: state.common.companyInfo,
    listLoading: state.common.listLoading,
    drawerLoading:state.common.drawerLoading,
    headerLoading:state.common.headerLoading,
   
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HolidayContainer);
