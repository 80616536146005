import { Card, Col, Row, Tabs } from "antd";
import React, { Fragment, useState } from "react";
import { PlusOutlined} from '@ant-design/icons'
import UploadBanner from "./UploadBanner";
import { FormattedMessage } from "react-intl";

const { TabPane } = Tabs;

const Banners = (props) => {
  const [tabKey, setTabKey] = useState("1");
  // const [fileList, setFileList] = useState([
  //   {
  //     uid: '-1',
  //     name: 'image.png',
  //     status: 'done',
  //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  //   },
  // ]);

   
  // const onChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  // };
  // const onPreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }

  //   // const imgWindow =  window.open(src);
  //   // imgWindow?.document.write(image.outerHTML);
  // };

 const handleUploadImg = () => {
    props.pushModalToStack({
      modalBody: <UploadBanner {...props} />,
      title: (
        <FormattedMessage
          id="model.inventory.title.SelectParentCategory"
          defaultMessage=""
        />
      ),
      width: "30%",
      wrapClassName: "cat-modal-custom",
      hideTitle: false,
      hideFooter: true,
    });
  };

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card className="common-card">
            <h5 className="card-heading">Store logo</h5>
            <p>
              Grab your store visitor’s attention by promotional banners
              displayed on top of your homepage.
            </p>

            <div className="banner-main-wrapper">
              <Tabs
                defaultActiveKey="1"
                activeKey={tabKey}
                onChange={(key) => {
                  setTabKey(key);
                }}
                type="line"
              >
                <TabPane tab="Active" key="1">
                  <div className="tab-content">
                    <h6 className="tab-content-heading">For Mobile (4/6)</h6>
                    <div className="multi-img-uploader">
                        {/* <Upload
                          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                          listType="picture-card"
                          fileList={fileList}
                        //   onChange={onChange}
                        
                        >
                          {fileList.length < 6 && "+ Upload"}
                        </Upload> */}

                        <div className="img-upload-pre" onClick={handleUploadImg} >
                        <PlusOutlined />
                        <p>Add Mobile Banner</p>
                        </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab="Previously used" key="2"></TabPane>
              </Tabs>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Banners;
