import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Switch,Divider, Input } from 'antd';
import { Dropdown } from '../../../general/Dropdown';
import { showToasterMessage } from '../../../../utils';
import * as find from 'lodash.find';
import { CustomButton } from '../../../general/CustomButton';
import "./settingPurchaseDrawer.scss";
import { CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY, ICONS } from '../../../../static/constants';
import filter from 'lodash.filter';
import { MultipleSelectDropdown } from '../../../general/MultipleSelectDropdown';
import TxnEmailTemplate from '../../../../containers/modal/modalBody/settings/EmailTemplate/TxnEmailTemplate';
import TxnRemarksTemplate from '../../../../containers/modal/modalBody/settings/RemarksTemplate/TxnRemarksTemplate';
import DocumentName from './documentName';


function SettingPurchaseDrawer(props) {

  const {
    allLedgerAccounts,intl
  } = props;
  
  const rowGutter = [24, 16]

  const closeDrawer = () => {
    props.updateDrawerState({
      settingPurchaseDrawerVisible: false
    })
  }
  const updateBusinessData = (props) => {
    if (props.companyInfoData.roundingOffSettings && props.companyInfoData.roundingOffSettings.isRoundingOffTotalAmtOnPurchaseInvoice && !props.companyInfoData.roundingOffSettings.roundingOffPurchaseLedgerAccountId) {
        return showToasterMessage({
            messageType: 'error', description: props.intl.formatMessage({ id: 'roundoff.ledger.validation' })
        });
    }
    if (props.companyInfoData.roundingOffSettings && props.companyInfoData.roundingOffSettings.isRoundingOffTotalAmtOnSalesInvoice && !props.companyInfoData.roundingOffSettings.roundingOffSalesLedgerAccountId) {
        return showToasterMessage({
            messageType: 'error', description: props.intl.formatMessage({ id: 'roundoff.ledger.validation' })
        });
    }
    const modalData = {
        modalBody: 'Are you sure you want to save?',
        handleSubmit: () => {
            props.updateBusinessData(props);
        }
    };
    props.pushModalToStack(modalData);
}
const approvalTypeObject={
    PuchaseRequest:"purchase-request",
    PuchaseOrder:"purchase-order",
    PuchaseInvoice:"purchase-invoice",
    DebitMemo:"debit-memo",
}

let value= filter(props.documentList || [], (document) => document.txType ===  CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_INVOICE]);

let docApproval = (props.docApprovalSettings || [])?.find((item) => item.approvalType === approvalTypeObject[props.viewName]) || {};


const openTxnEmailTemplates = (data) => {
    const modalData = {
        title: <FormattedMessage id='setting.emailTemplates.label' defaultMessage='' />,
        modalBody: <TxnEmailTemplate {...props} />,
        width: 800,
        hideFooter: true,
        wrapClassName: 'show-setting-email-template',
    };
    props.showModal(modalData);
};

const openTxnRemarkTemplates = (data) => {
    const modalData = {
        title: <FormattedMessage id='setting.emailTemplates.label' defaultMessage='' />,
        modalBody: <TxnRemarksTemplate {...props} />,
        width: 800,
        hideFooter: true,
        wrapClassName: 'show-setting-email-template',
    };
    props.showModal(modalData);
};





const saveSetting = (record, checked) => {
    const modalData = {
        modalBody: "Are you sure you want to save?",
        handleSubmit: () => {
            record.isApprovalRequired = checked ? 1 : 0;
            let payload = {
                documentApprovalId: record.documentApprovalId,
                approvalType: record.approvalType,
                relationshipId: record.relationshipId,
                isApprovalRequired: record.isApprovalRequired,
                reviewersList: props.selectedReviewersList || [],
                approversList: props.selectedApproversList || [],
                version: record.version,
            }
            props.saveOrUpdateDocApproval(payload);
        },
    };
    props.pushModalToStack(modalData);

}

  return (
    <div>
      <Drawer
        title={
          <FormattedMessage id={props.displayName} defaultMessage='' />
        }
        className='settingInvoiceDrawer'
        width={600}
        zIndex={1}
        keyboard={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        visible={props.settingPurchaseDrawerVisible}
        footer={
            <div style={{display:"flex", justifyContent:'space-between'}}>

              <CustomButton
                intlId="confirmation.cancel"
                defaultMessage=""
                type="default"
                key={0}
                style={{ marginRight: 8 }}
                onClick={closeDrawer}
              />
              {/* <CustomButton
                intlId="confirmation.submit"
                defaultMessage=""
                htmlType="submit"
                onClick={() => {
                    updateBusinessData(props);
                }}
                key={122}
              /> */}
            </div>
          }
        
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
      >

    {props.viewName === "PuchaseInvoice" ? 
        <>
            <div>
                <Row gutter={rowGutter}>
                    <Col>
                        <h2><FormattedMessage id='drawer.settingDrawer.headingName.organizationInfoSetting' defaultMessage='' /></h2>
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.company.updateInventoryOnPurchaseInvoice' defaultMessage='' /> </span>
                    </Col>
                    <Col span={10}>
                        <Switch
                            checked={(props.companyInfoData.isUpdateInventoryOnPurchaseInvoice === 1)}
                            onClick={(checked, e) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData, isUpdateInventoryOnPurchaseInvoice: checked ? 1 : 0
                                    }
                                });
                            }} />
                    </Col>

                    {/* <Col span={1}>
                            <div className="" style={{ 'font-size': '18px', 'cursor': 'pointer', 'margin': '0px 0px 0px 15px' }} onClick={() => {
                                updateBusinessData(props);
                            }}><button className="save-but"><i className="fa fa-floppy-o"></i></button></div>
                        </Col> */}
                </Row>

                <Row gutter={rowGutter}>
                    <Col span={12}>
                        <span className="i-lable"> <FormattedMessage id='modal.settings.companyInfo.company.rountoffTotalOnPurchaseInvoice' defaultMessage='' /> </span>
                    </Col>
                    <Col span={10}>
                        <Switch
                            checked={(props.companyInfoData.roundingOffSettings?.isRoundingOffTotalAmtOnPurchaseInvoice === 1)}
                            onClick={(checked, e) => {
                                props.updateState({
                                    companyInfoData: {
                                        ...props.companyInfoData,
                                        roundingOffSettings: {
                                            ...props.companyInfoData.roundingOffSettings,
                                            isRoundingOffTotalAmtOnPurchaseInvoice: checked ? 1 : 0
                                        }
                                    }
                                });

                            }} />
                    </Col>
                    {(props.companyInfoData.roundingOffSettings?.isRoundingOffTotalAmtOnPurchaseInvoice === 1) ?
                        <Col span={12}>
                            <Dropdown items={props.allLedgerAccounts}
                                valueKeyName='ledgerAccountName'
                                optionKeyName='chartOfAccountDetailsId'
                                value={(props.allLedgerAccounts.length && props.companyInfoData.roundingOffSettings?.roundingOffPurchaseLedgerAccountId) || ''}
                                onSelect={(optionKey) => {
                                    const defaultPurchageRoundOffLedger = find(props.allLedgerAccounts, { chartOfAccountDetailsId: Number(optionKey) })
                                    props.updateState({
                                        defaultPurchageRoundOffLedger,
                                        companyInfoData: {
                                            ...props.companyInfoData,
                                            roundingOffSettings: {
                                                ...props.companyInfoData.roundingOffSettings,
                                                roundingOffPurchaseLedgerAccountId: defaultPurchageRoundOffLedger?.chartOfAccountDetailsId
                                            }
                                        }
                                    })

                                }}
                                placeholder={intl.formatMessage(
                                    { id: 'company.companySetup.ledgerAccounts.salesLedger.placeholder', defaultMessage: '' }
                                )}
                            />
                        </Col>
                        : ''
                    }
                </Row>

            </div>

            <Divider className='customDivider'  />
        </>
        :
        ""
    }

        {props.viewName === "PuchaseRequest" ?
                
                ""
                :
                <>
                    <div>
                        <Row gutter={rowGutter}>
                            <Col span={24}>
                                <h2><FormattedMessage id='drawer.settingDrawer.headingName.documentName' defaultMessage='' /></h2>
                            </Col>
                        </Row>
                        <Row gutter={rowGutter}>
                            <Col span={24}>
                            <DocumentName {...props}/>
                                {/* <table className='bordered'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Document Name</th>
                                            <th>Is Default</th>
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(value||[]).map((value, index) => {
                                            return (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{value.docName}</td>
                                                <td>{value.isDefault ? 'Yes' : 'No'}</td>
                                                <td className='text-center'><span><i className="fa fa-edit" /></span>{value.isSystemGenerated ? <span><i className="fa fa-lock" /></span> : <span><i className="fa fa-remove" /></span>}</td>
                                            </tr>
                                            );
                                        })}
                                        </tbody>
                                </table> */}
                            </Col>
                        </Row>
                    </div>
    
                    <Divider className='customDivider'  />
                </>
                
        }
        
        {props.viewName === "LandedCost" ?
                
                ""
                :
                <>
                    <div>
                        <Row gutter={rowGutter}>
                            <Col span={24}>
                                <h2><FormattedMessage id='drawer.settingDrawer.headingName.documentSequence' defaultMessage='' /></h2>
                            </Col>
                        </Row>
                        <Row gutter={rowGutter}>
                            <Col span={12}>
                                <span className="i-lable"> <FormattedMessage id='drawer.settingDrawer.labelName.prefix' defaultMessage='' /> </span>
                            </Col>
                            <Col span={10}>
                                <Input value={props.tabDocumentOptions.txNamePefix} />  
                            </Col>

                        </Row>
                        <Row gutter={rowGutter}>
                            <Col span={12}>
                                <span className="i-lable"> <FormattedMessage id='drawer.settingDrawer.labelName.sample' defaultMessage='' /> </span>
                            </Col>
                            <Col span={10}>
                                <Input value={props.tabDocumentOptions.numberExample} />  
                            </Col>

                        </Row>
                        <Row gutter={rowGutter}>
                            <Col span={12}>
                                <span className="i-lable"> <FormattedMessage id='drawer.settingDrawer.labelName.sequence' defaultMessage='' /> </span>
                            </Col>
                            <Col span={10}>
                                <Input value={props.tabDocumentOptions.number} />  
                            </Col>

                        </Row>
                    </div>

                    <Divider className='customDivider'  />
                </>
    }
        

        {props.viewName === "PuchaseInvoice" || props.viewName === "PuchaseRequest" || props.viewName === "RFQ" || props.viewName === "PuchaseOrder" || props.viewName === "DebitMemo" ? 
            <>
                <div>
                    <Row gutter={rowGutter}>
                        <Col span={24}>
                            <h2><FormattedMessage id='drawer.settingDrawer.headingName.documentApproval' defaultMessage='' /></h2>
                        </Col>
                    </Row>
                    <Row gutter={rowGutter}>
                        <Col span={24}>
                            <Switch 
                                checked={(docApproval.isApprovalRequired === 1)}
                                onClick={(checked, e) => {
                                    saveSetting(docApproval, checked)
                                }}
                                style={{marginBottom: '20px'}}
                            />
                        </Col>
                    </Row>
                    <Row gutter={rowGutter}>
                        <Col span={12}>Reviewed By</Col>
                        <Col span={10}>
                            <MultipleSelectDropdown
                                style={{ width: '100%' }}
                                items={props.employeeList || []}
                                placeholder="Select Reviewers"
                                mode='multiple'
                                onDeSelect={(value) => {
                                    let { selectedReviewersList } = props
                                        selectedReviewersList = filter(selectedReviewersList, (relationshipEmployeeId) => {
                                            return relationshipEmployeeId !== value;
                                        });
                                    props.updateState({ selectedReviewersList });
                                }}
                                valueKeyName='fullName'
                                // valueKeyName2='relationshipEmployeeId'
                                selectedValue={(props.employeeList || []).length ? (props.selectedReviewersList || []) : []}
                                optionKeyName='relationshipEmployeeId'
                                onSelect={(value) => {
                                    let selectedReviewersList = props.selectedReviewersList || [];
                                    if (selectedReviewersList.indexOf(value) === -1) {
                                        selectedReviewersList.push(value);
                                    } else {
                                        selectedReviewersList = selectedReviewersList.filter((el) => {
                                            return el !== value
                                    });
                                    }
                                    props.updateState({
                                        selectedReviewersList
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={rowGutter}>
                        <Col span={12}>Approved By</Col>
                        <Col span={10}>
                            <MultipleSelectDropdown
                                style={{ width: '100%' }}
                                items={props.employeeList || []}
                                placeholder="Select Approvers"
                                mode='multiple'
                                onDeSelect={(value) => {
                                    let { selectedApproversList } = props
                                        selectedApproversList = filter(selectedApproversList, (relationshipEmployeeId) => {
                                            return relationshipEmployeeId !== value;
                                        });
                                    props.updateState({ selectedApproversList });
                                }}
                                valueKeyName='fullName'
                                // valueKeyName2='relationshipEmployeeId'
                                selectedValue={(props.employeeList || []).length ? (props.selectedApproversList || []) : []}
                                optionKeyName='relationshipEmployeeId'
                                onSelect={(value) => {
                                    let selectedApproversList = props.selectedApproversList || [];
                                    if (selectedApproversList.indexOf(value) === -1) {
                                        selectedApproversList.push(value);
                                    } else {
                                        selectedApproversList = selectedApproversList.filter((el) => {
                                            return el !== value
                                    });
                                    }
                                    props.updateState({
                                        selectedApproversList
                                    });
                                }}
                            />
                        </Col>
                    </Row>

                </div>
                
                <Divider className='customDivider'  />
            </>
          :
           "" 
        }
        {props.viewName === "Package" || props.viewName === "LandedCost" ? 
        
        ""
        :
    
        <div>
            <Row gutter={rowGutter}>
                <Col span={24}>
                    <h2><FormattedMessage id='drawer.settingDrawer.headingName.emailtemplate' defaultMessage='' /></h2>
                </Col>
            </Row>
            <Row gutter={rowGutter}>
                <Col span={12}>
                    <CustomButton
                        btnIcon={<span className=""><i className={ICONS.MAIL}></i>&nbsp;</span>}
                        intlId="email.template"
                        defaultMessage=""
                        type="default"
                        key={0}
                        onClick={() => openTxnEmailTemplates(props)}
                    />
                </Col>
                <Col span={12}>
                    <CustomButton
                        btnIcon={<span className=""><i className={ICONS.MAIL}></i>&nbsp;</span>}
                        intlId="email.remark"
                        defaultMessage=""
                        type="default"
                        key={0}
                        onClick={() => openTxnRemarkTemplates(props)}
                    />
                </Col>
            </Row>
        </div>
        }

      </Drawer>
    </div>
  );
}

export default injectIntl(SettingPurchaseDrawer);
