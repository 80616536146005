import React, { Fragment, useEffect, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";

import { ErrorMsg } from "../../general/ErrorMessage";
// import ProductDetailsTab from "./ProductDetailsTab";

import {  CONSTANTS } from "../../../static/constants";

import "@ant-design/compatible/assets/index.css";


import {
  
  Col,
  Row,
  Input,
  Tabs,

  Select,
 
  DatePicker
} from "antd";
import {
  
 
  getMomentDateForUI,
} from "../../../utils";
import find from "lodash.find";
import { Dropdown } from "../../general/Dropdown";

const AdditionalFields = (props) => {
    const format = CONSTANTS.DISPLAY_DATE_FORMAT;

    const genEWB = [{generateEWB:"No" , referCode:"N"} ,{generateEWB:"Yess" , referCode:"Y"}]
  return (
    <div>
        <div>
        {
    props.additionalFields ? (
<div className="mt20">
   <div className="drawer-heading">Additional Details</div>
   <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Generate IRN"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Fiscal Year"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
maxLength={20}
placeholder={'Generate IRN'}
type="text"
value={props.generateIRN}
onChange={(e) => {
props.updateState({
    generateIRN: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Fiscal Year'}
type="text"
maxLength={20}
value={props.fiscalYear}
onChange={(e) => {
props.updateState({
    fiscalYear: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Internal Reference Number"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Posting Date"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={'Reference Number'}
type="text"
maxLength={20}
value={props.internalReferenceNumber}
onChange={(e) => {
props.updateState({
    internalReferenceNumber: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<DatePicker
             getPopupContainer={(trigger) => trigger.parentNode}
             format={format}
             key={`${props.postingDate
               ? getMomentDateForUI({
                 date: new Date(props.postingDate),
                 format,
               })
               : null
               }`}
             defaultValue={
               props.postingDate
                 ? getMomentDateForUI({
                   date: new Date(props.postingDate),
                   format,
                 })
                 : null
             }
             onChange={(selectedDate) => {
               props.updateState({
                postingDate: selectedDate,
               });
             }}
             placeholder={'Select Posting Date'}
           />

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Invoice Status"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Internal Counterparty Code"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={'Invoice Status'}
type="text"
maxLength={20}
value={props.invoiceStatus}
onChange={(e) => {
props.updateState({
    invoiceStatus: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Party Code'}
type="text"
maxLength={20}
value={props.counterPartyCode}
onChange={(e) => {
props.updateState({
    counterPartyCode: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Area Code"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Plant Code"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={'Area Code'}
type="text"
maxLength={20}
value={props.areaCode}
onChange={(e) => {
props.updateState({
    areaCode: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Plant Code'}
type="text"
maxLength={20}
value={props.plantCode}
onChange={(e) => {
props.updateState({
    plantCode: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Project Reference Code"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Department Code"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={'Project Reference Code'}
type="text"
maxLength={20}
value={props.projectRefrence}
onChange={(e) => {
props.updateState({
    projectRefrence: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Department Code'}
type="text"
maxLength={20}
value={props.departmentCode}
onChange={(e) => {
props.updateState({
    departmentCode: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Division Code"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Cost Center"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={'Divison Code'}
type="text"
maxLength={20}
value={props.divisionCode}
onChange={(e) => {
props.updateState({
    divisionCode: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Cost Center'}
type="text"
maxLength={20}
value={props.costCenter}
onChange={(e) => {
props.updateState({
    costCenter: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>

           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Branch Code"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="User Name"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={'Branch Code'}
type="text"
maxLength={20}
value={props.branchCode}
onChange={(e) => {
props.updateState({
    branchCode: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'User Name'}
type="text"
maxLength={20}
value={props.userName}
onChange={(e) => {
props.updateState({
    userName: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>

           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Transaction Category"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="GL Code"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={'Transaction Category'}
type="text"
maxLength={20}
value={props.transactionCategory}
onChange={(e) => {
props.updateState({
    transactionCategory: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'GL Code'}
type="text"
maxLength={20}
value={props.glCode}
onChange={(e) => {
props.updateState({
    glCode: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
          
   </div>
    ):''
   } 
        </div>

        <div>
        {
    props.optionalFields ? (
<div className="mt20">
   <div className="drawer-heading">Optional Details</div>
   <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Generate EWB"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Place of Business (Unique Code) EWB Topaz"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<Dropdown
                // style={{ width: "210px" }}
                items={genEWB || []}
                valueKeyName="generateEWB"
                optionKeyName="referCode"
                value={(props.generateEWB || {}).docType}
                placeholder={"Document Type"}
                onSelect={(referCode) => {
                  const doc = find(genEWB, { referCode: referCode }) || {};
                  props.updateState({
                    generateEWB: doc,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />

</Col>

<Col span={12}>
<TextBox
placeholder={'Place of Business (Unique Code) EWB Topaz'}
type="text"
maxLength={20}
value={props.topazEWB}
onChange={(e) => {
props.updateState({
    topazEWB: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
           <div>
           <Row gutter={[16, 8]}>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Place of Business (Unique Code) Return Filing Sapphire"
    defaultMessage=""
  />
  

</Col>

<Col span={12} className="drawer-label">
  <FormattedMessage
    id="Invoice Template Name"
    defaultMessage=""
  />
  

</Col>
</Row>

<Row gutter={[16, 16]}>

<Col span={12}>
<TextBox
placeholder={'Place of Business (Unique Code) Return Filing Sapphire'}
type="text"
maxLength={20}
value={props.filingSapphire}
onChange={(e) => {
props.updateState({
    filingSapphire: e.target.value,
});
}}
/>

</Col>

<Col span={12}>
<TextBox
placeholder={'Template Name'}
type="text"
maxLength={20}
value={props.templateName}
onChange={(e) => {
props.updateState({
    templateName: e.target.value,
});
}}
/>

</Col>
</Row>
           </div>
          
   </div>
    ):''
   }
        </div>
    </div>
  )
}

export default AdditionalFields