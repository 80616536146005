import React from "react";
import "@ant-design/compatible/assets/index.css";
import { Form, Input, Button } from "antd";
import { injectIntl } from "react-intl";
// import { CONSTANTS, PAYMENT_SOURCE_BY_ID } from '../../../static/constants';
// import { CustomButton } from '../../general/CustomButton';
// import { validateEmail, getCurrentDateForBackend, showToasterMessage, intl } from '../../../utils';
//import * as find from 'lodash.find';
// import { ErrorMsg } from '../../general/ErrorMessage';
// import config from '../../../config/environmentConfig';
import "./index.scss";

function UserLogin(props) {
  let payload = {
    username: "",
    password: "",
  };
  const handleLogIn = (values) => {
    // props.updateState({activePage : 'transactiontypes'})
    props.verifyCredentials(payload, props);
  };

  const onFinish = (values) => {
    payload.username = values.username;
    payload.password = values.password;
    handleLogIn(values);
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <div className=" box-container">
      <div className="form-boxs">
        <Form
          name="basic"
          labelCol={{
            span: 6,
            md: 6,
            sm: 24,
          }}
          wrapperCol={{
            span: 18,
            md: 18,
            sm: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h2>Login to your Account</h2>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          {/* <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{
                offset: 4,
                span: 18,
              }}
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item> */}

          <Form.Item
            wrapperCol={{
              span: 24,
              md: 24,
              sm: 24,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default injectIntl(UserLogin);
