import React from "react";
import { Drawer, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import {
  showToasterMessage,
} from "../../../utils";
import { TextBox } from "../../general/TextBox";
// import { Dropdown } from "../../general/Dropdown";
// import { CONSTANTS } from "../../../static/constants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { CustomButton } from "../../general/CustomButton";

// const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const TeacherListFilterComp = (props) => {
  const { companyInfo } = props;

  const closeDrawer = () => {
    props.updateState({
      teacherListFilterDrawerVisible: false,
    });
  };


  const applyFilter = () => {
    if (
      !props.teacherName &&
      !props.teacherEmail &&
      !props.teacherMobile
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please select at least one filter",
      });
    }
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: props.pageSize,
      filters: {
        teacherName: props.teacherName || null,
        teacherEmail: props.teacherEmail || null,
        teacherMobile: props.teacherMobile || null,
      },
    };
    props.getTeacher(payload);
    props.getTeacherCount(payload);
    closeDrawer();
  };

  const clearFilter = () => {
    props.updateState({
      teacherName: null,
      teacherEmail: null,
      teacherMobile: null,
    });
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {},
    };
    props.fetchTeacherListByFilter(payload);
    props.getTeacherlistCountByFilter(payload);
  };

  const rowGutter = [24, 16];

  return (
    <>
      <Drawer
        title={<FormattedMessage id="teacher.teacherList.filter.drawer.title" />}
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.teacherListFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">Teacher Name</div>
            <TextBox
              placeholder="Teacher Name"
              type="text"
              maxLength={20}
              value={props.teacherName}
              onChange={(e) => {
                props.updateState({ teacherName: e.target.value });
              }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Email</div>
            <TextBox
              placeholder="Email"
              type="text"
              maxLength={20}
              value={props.teacherEmail}
              onChange={(e) => {
                props.updateState({ teacherEmail: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">Mobile No.</div>
            <TextBox
              placeholder="Mobile No."
              type="text"
              maxLength={20}
              value={props.teacherMobile}
              onChange={(e) => {
                props.updateState({ teacherMobile: e.target.value });
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default TeacherListFilterComp;
