import { PURCHASE_INVOICE_ACTION_LIST } from '../static/constants';
const initialState = {
  purchaseInvoiceList: {
    1: []
  },
  purchaseInvoiceCount: 0,
  purchaseInvoiceDetail: {},
  poInvoiceData: {},
  idInvoiceData: {},
  purchaseInvoiceDraftList: {
    1: []
  },
  purchaseInvoiceDraftCount: 0,
  availableDebits: 0,
  availableDebitList: []
}

const PurchaseInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_LIST:
      return {
        ...state,
        purchaseInvoiceList: {
          ...state.purchaseInvoiceList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_COUNT:
      return { ...state, purchaseInvoiceCount: action.data };
    case PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_LIST_PAGE_RESET:
      return { ...state, purchaseInvoiceList: initialState.purchaseInvoiceList };
    case PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_DETAIL:
      return { ...state, purchaseInvoiceDetail: action.data };
    case PURCHASE_INVOICE_ACTION_LIST.RESET_PURCHASE_INVOICE_DETAIL:
      return { ...state, purchaseInvoiceDetail: initialState.purchaseInvoiceDetail };
    case PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_PO_DETAIL:
      return { ...state, poInvoiceData: action.data };
    case PURCHASE_INVOICE_ACTION_LIST.RESET_PO_INVOICE_DATA:
      return { ...state, poInvoiceData: initialState.poInvoiceData };
    case PURCHASE_INVOICE_ACTION_LIST.NEXT_PURCHASE_INVOICE_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case PURCHASE_INVOICE_ACTION_LIST.NEXT_PURCHASE_INVOICE_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    case PURCHASE_INVOICE_ACTION_LIST.ID_INVOICE_DATA:
      return { ...state, idInvoiceData: action.data };
    case PURCHASE_INVOICE_ACTION_LIST.RESET_ID_INVOICE_DATA:
      return { ...state, idInvoiceData: initialState.idInvoiceData };
    case PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_DRAFT_LIST:
      return {
        ...state,
        purchaseInvoiceDraftList: {
          ...state.purchaseInvoiceDraftList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_DRAFT_COUNT:
      return { ...state, purchaseInvoiceDraftCount: action.data };
    case PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_DRAFT_LIST_PAGE_RESET:
      return { ...state, purchaseInvoiceDraftList: initialState.purchaseInvoiceDraftList };
    case PURCHASE_INVOICE_ACTION_LIST.AVAILABLE_DEBITS:
      return { ...state, availableDebits: action.data };
    case PURCHASE_INVOICE_ACTION_LIST.AVAILABLE_DEBITS_RESET:
      return { ...state, availableDebits: initialState.availableDebits };
    case PURCHASE_INVOICE_ACTION_LIST.AVAILABLE_DEBITS_LIST:
      return { ...state, availableDebitList: action.data };
    case PURCHASE_INVOICE_ACTION_LIST.AVAILABLE_DEBITS_LIST_RESET:
      return { ...state, availableDebitList: initialState.availableDebitList };  
    default:
      return state;
  }
};

export default PurchaseInvoiceReducer;