import React, { Fragment } from "react";
import { Skeleton, Input } from 'antd';
import * as debounce from 'lodash.debounce';
// import NewLedgerComponent from '../../../containers/modal/modalBody/finance/LedgerAccount';
import { injectIntl,FormattedMessage } from 'react-intl';
// import { CustomAgGrid } from "../../general/CustomAgGrid";
// import { MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../static/constants';
import { sortTable } from '../../../utils';
import {SortAscendingOutlined,SortDescendingOutlined } from '@ant-design/icons';


const { Search } = Input;

let gridApi = {};


// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

// const onFirstDataRendered = (params) => {
//     params.api.sizeColumnsToFit();
// }
// const getRowHeight = (params) => {
//     return 30;
// }

const CategoryGroupComp = (props) => {
    const { allCategoryGroup,dir=0, pageNumber, pageSize } = props;

    // const menu = (
    //     <Menu>
    //         <Menu.Item key="upload_customer">
    //             <i className="fa fa-file-pdf-o" />
    //             &nbsp;Download PDF
    //         </Menu.Item>
    //     </Menu>
    // );

    // const onGridReady = (params) => {
    //     gridApi = params.api;
    // }

    // const getActionPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedAction = {
    //         create: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.LEDGER_ACCOUNTS, PERMISSION_VALUES.CREATE)
    //     };
    //     Object.keys(permittedAction).forEach((operation) => {
    //         if (permittedAction[operation] === false) {
    //             delete permittedAction[operation];
    //         }
    //     })
    //     return permittedAction;
    // }

    // const permittedActions = getActionPermissionData();
    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.categoryGrp',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'>Category Group</span>
    //                 </div>
    //             </Tooltip>;
    //         },
    //         field: "categoryGroupName",
    //         rowDrag: true,
    //         resizable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <Tooltip placement="top" title={props.intl.formatMessage(
    //                 {
    //                   id: 'tooltip.inbuilt',
    //                   defaultMessage: ''
    //                 }
    //               )}>
    //                 <div className='table-header-column-container'>
    //                     <span className='table-header-column'> Inbuilt?</span>
    //                 </div>
    //             </Tooltip>;
    //         },

    //         field: "inBuilt",
    //         resizable: true,
    //         valueFormatter: (data) => {
    //             data.value = data.value ? 'Yes' : 'No';
    //             return data.value;
    //         }
    //     }
    // ]

    const onSearchedInputChange = debounce((searchedText, props) => {
        handleSearchedText(searchedText, props)
    }, 2000);

    const handleSearchedText = (searchedText, props) => {
        gridApi.setQuickFilter(searchedText || '');
    }

      const sortColumn = (e) => {
        sortTable(document.getElementById("ledger-category-group"), e.target.cellIndex, 1 - dir);
        props.updateState({ dir: 1 - dir })
      }

    return (
      <Fragment>
        <Skeleton
          loading={props.showAllCategoryGroup}
          active
          paragraph={{ rows: 12 }}
        >
          <div className="view-container-actions">
            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="heading.finance.ledgerAccountsCategoryGroup.table.heading"
                  defaultMessage=""
                />
              </div>
              {props.pageSize ? (
                <>
                  <div className="vertical-sep" />
                  <div>{props.pageSize}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">
              <Search
                placeholder="Search"
                value={props.searchedText}
                onChange={(e) => {
                  props.updateState({ searchedText: e.target.value });
                  onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  props.updateState({ searchedText });
                  onSearchedInputChange(searchedText, props);
                }}
              />
            </div>
          </div>

          {/* <Row className="search-container" type='flex' justify='end'>
                        <Col span={12}>
                            <Search
                                placeholder="Search"
                                value={props.searchedText}
                                onChange={e => {
                                    props.updateState({ searchedText: e.target.value });
                                    onSearchedInputChange(e.target.value, props);
                                }}
                                onSearch={(searchedText) => {
                                    props.updateState({ searchedText });
                                    onSearchedInputChange(searchedText, props);
                                }}
                                style={{ width: 330, height: 36 }}
                            />
                        </Col>

                       
                    </Row> */}
          {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            <CustomAgGrid
                                columnDefs={columnDefs}
                                rowData={allCategoryGroup || []}
                                rowDragManaged={true}
                                onGridReady={onGridReady}
                                domLayout={"autoHeight"}
                                animateRows={true}
                                onGridSizeChanged={onFirstDataRendered}
                                getRowHeight={getRowHeight}
                            />
                        </div>
                    </div> */}
          <div className="table-container">
            <table id="ledger-category-group">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="serial.no" defaultMessage="" />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="tooltip.categoryGrp" defaultMessage="" />
                    {dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                  </th>
                  <th onClick={sortColumn}><FormattedMessage id="tooltip.inbuilt" defaultMessage=""/>{dir ? <SortAscendingOutlined /> : <SortDescendingOutlined />}</th>
                </tr>
              </thead>
              <tbody>
                {allCategoryGroup && allCategoryGroup.map((rowData,index)=>{
                   return (

                    <tr key={index}>
                        <td>{Number(((pageNumber-1) * pageSize) + index + 1)}</td>
                        <td>{rowData.categoryGroupName}</td>
                        <td>{rowData.inBuilt ? 'Yes' : 'No'}</td>                
                    </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
            <div className="f-left"></div>
            <div className="f-right">
            
            </div>
         </div>
      </Fragment>
    );
};

export default injectIntl(CategoryGroupComp);