import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InboundDeliveryListingComponent from '../../../../components/supplier/InboundDelivery/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchAllInboundDeliveries, getInboundDeliveriesCount, resetPaginatedData, fetchInboundDeliveriesDrafts, getInboundDeliveriesDraftCount, 
  resetDraftPaginatedData, deleteInboundDelivery, updateUserStatus, fetchAllInboundDeliveriesByFilter, getInboundDeliveriesCountByFilter,
 } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchUserPreferences, saveUserPrefrences, fetchProjects } from '../../../../actions/commonActions';
import { fetchSuppliers } from '../../Listing/action';
import { fetchAllStatus } from '../../../modal/modalBody/common/UserStatus/action';
import * as find from 'lodash.find';
import { FormattedMessage } from 'react-intl';
import InboundDeliveryFilters from '../../../../components/supplier/InboundDelivery/Listing/inboundDeliveryFilter';


class InboundDeliveryListing extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,  
      activeTab: linkProps.activeTab,
      activeTabKey: linkProps.activeTab === "Draft" ? '2' : '1', 
      txPreferences: { txType: "inboundDelivery", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['inboundDeliveryNumber', 'inboundDeliveryDate', 'poNumber', 'totalQtyReceived'],
      txAvailableColumn: {
        inboundDeliveryNumber: true,
        poNumber: true,
        supplierName: true,
        inboundDeliveryDate: true,
        totalQtyReceived: true,
        userStatus: true,
        project: true,
        reference: true,
        customerRFQNumber: true,
      },
      
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.purchase.GRN' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
        {
            name: <FormattedMessage id='sidebar.menuItem.purchase' defaultMessage='' />,
        }, 
        {
          name: <FormattedMessage id='sidebar.menuItem.inboundDelivery' defaultMessage='' />,
        }
    ]})
    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
      transactionName: 'Inbound Delivery',
    };
    fetchPaginationDataIfNeeded('fetchAllInboundDeliveries', 'inboundDeliveryList', this.props, payload);
    fetchDataIfNeeded('getInboundDeliveriesCount', 'inboundDeliveryCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    if (linkProps.activeTab === "Draft") {
      fetchDataIfNeeded('getInboundDeliveriesDraftCount', 'inboundDeliveryDraftCount', this.props, payload);
      fetchPaginationDataIfNeeded('fetchInboundDeliveriesDrafts', 'inboundDeliveryDraftList', this.props, payload);
    }
    this.props.fetchSuppliers(payload);
    this.props.fetchProjects(payload);
    this.props.fetchAllStatus(payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "inboundDelivery", templateName: "standard" }) || this.state.txPreferences;
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = txPreferences.tablePreference? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {} : {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else  {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        <InboundDeliveryListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        {this.state.inboundDeliveryFilterDrawerVisible && <InboundDeliveryFilters {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />}

      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    inboundDeliveryList: state.inboundDelivery.inboundDeliveryList,
    inboundDeliveryCount: state.inboundDelivery.inboundDeliveryCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    inboundDeliveryDraftList: state.inboundDelivery.inboundDeliveryDraftList,
    inboundDeliveryDraftCount: state.inboundDelivery.inboundDeliveryDraftCount,
    suppliers: state.supplier.suppliers,
    projectList: state.common.projectList,
    userStatus: state.common.userStatus,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllInboundDeliveries,
  getInboundDeliveriesCount,
  resetPaginatedData,
  showModal,
  hideModal,
  deleteInboundDelivery,
  pushModalToStack,
  popModalFromStack,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchInboundDeliveriesDrafts, 
  getInboundDeliveriesDraftCount, 
  resetDraftPaginatedData,
  updateUserStatus,
  fetchSuppliers,
  fetchProjects,
  fetchAllStatus,
  fetchAllInboundDeliveriesByFilter,
  getInboundDeliveriesCountByFilter
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InboundDeliveryListing);
