import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Switch} from 'antd';
import { CustomButton } from '../../../../general/CustomButton';
import { MODAL_ACTIONS } from '../../../../../static/constants';
// import { Dropdown } from '../../../../general/Dropdown';

const UpdateParentInfo = (props) => {
  let { formData={}, updateFormData, data={} } = props;
  //const labelName = getLabelName(modalType);
  formData = {...formData , ...data.txData };
  const [formDataObj, setFormDataObj] = useState(formData);
  const dispatch = useDispatch();

  // const addItemConfirmationModal = (props, text, modalType, statusColor) => {
  //   const { showModal, companyInfo } = props;
  //   const modalBody = <span>
  //     <FormattedMessage
  //       id='addItem.confirmation'
  //       defaultMessage={``}
  //     />
  //   </span>;

  //   let payload = {
  //     statusName: text,
  //     statusColor: statusColor || '',
  //     relationshipId: companyInfo.relationshipId,
  //     transactionName: props.transactionName
  //   }

  //   const modalData = {
  //     modalBody,
  //     hideFooter: false,
  //     handleSubmit: () => {
  //        props.createStatus(payload);
  //        props.hideModal();
  //   },
  //   };
  //   showModal(modalData);
  // }


  // const handleNewItemAddition = (props, payload, modalType) => {
  //   const formData = payload.formData;
  //   const { showModal } = props;

  //   if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
  //     addItemConfirmationModal(props, formData.textBoxValue, modalType);
  //   } else {
  //     const data = {
  //       title: <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />,
  //       formData,
  //       hideFooter: props.transactionName === CONSTANTS_TRANSACTIONS.STUDENT? true: false,
  //       //hideFooter: false,
  //       modalData: { modalType, data: payload },
  //       handleSubmit: (formData = {}) => {
  //         if (formData.textBoxValue && formData.textBoxValue.length) {
  //           addItemConfirmationModal(props, formData.textBoxValue, modalType, formData.statusColor);
  //         } else {
  //           data.formData.submittedOnce = true;
  //           showModal(data);
  //         }
  //       }
  //     };
  //     showModal(data);
  //   }
  // }

  // const deleteClickHandler = (props, payload) => {
  //   const { showModal } = props;
  //   const modalBody =
  //     <FormattedMessage
  //       id='deleteItem.confirmation'
  //       defaultMessage={``}
  //       values={{ value: payload.text }}
  //     />;



  //   const modalData = {
  //     modalBody,
  //     handleSubmit: () => {
  //       const selectedObj = find((props.userStatus || []), { statusName: payload.text}) || {};
  //       //selectedObj.transactionName = props.transactionName;
  //       props.deleteStatus(selectedObj, props)
  //       props.hideModal();
  //     },
  //   };
  //   showModal(modalData);
  // }

 
  return (
    <Fragment>
    <Form
      layout='vertical'
      className='login-form'
      onSubmit={(e) => {
       // e.preventDefault();
        //if (formDataObj.textBoxValue && formDataObj.textBoxValue.length) {
          e.preventDefault();
          props.handleSubmit(formDataObj);
        // } else {
        //   setFormDataObj({ submittedOnce: true, textBoxValue: formDataObj.textBoxValue, statusColor: formDataObj.statusColor });
        // }
      }}
    >
      <Row>
        <Col span={12} className='padding-top-7'>
          <span className="text-input-label">
          {/* <FormattedMessage id='addItem.text.userStatus' defaultMessage='' />
            <span className="required">*</span> */}
            Does this user Volunteer
          </span>
        </Col>
       
          <Col span={12}>
            <Switch
              checked={(formDataObj.isVolunteer === 1)}
              onClick={(checked, e) => {
                //  props.updateState({ isVolunteer: checked ? 'yes' : 'no'})

                updateFormData && updateFormData({ ...formDataObj, isVolunteer: checked ? 1 : 0  });
                setFormDataObj({ ...formDataObj, isVolunteer: checked ? 1 : 0 });
              }} />
            {/* <Dropdown
              items={props.userStatus || []}
              valueKeyName='statusName'
              optionKeyName='registrationStatusId'
              value={formDataObj.textBoxValue}
              style={{ width: '100%' }}
              canAddNew={props.transactionName === CONSTANTS_TRANSACTIONS.STUDENT ? false: true}
              canDelete={props.transactionName === CONSTANTS_TRANSACTIONS.STUDENT ? false: true}
              onAddNewClick={(payload) => {
              setFormDataObj({ ...formDataObj, textBoxValue: "" });
                payload.formData = {
                  textBoxValue: payload.textEntered,
                  submittedOnce: false,
                  maxLength: 25
                }
                handleNewItemAddition(props, payload, props.transactionName === CONSTANTS_TRANSACTIONS.STUDENT ? MODAL_TYPE.NEW_STATUS : MODAL_TYPE.STATUS_NAME);
              }}
              deleteClickHandler={(payload) => {
                deleteClickHandler(props, payload);
              }}
              onSelect={(selectedValue, option) => {
                const selectedObj = find((props.userStatus || []), { registrationStatusId: option.value }) || {};
                updateFormData && updateFormData({ ...formDataObj, textBoxValue: ((option || {}).props || {}).name, statusColor: selectedObj.statusColor });
                setFormDataObj({ ...formDataObj, textBoxValue: ((option || {}).props || {}).name, statusColor: selectedObj.statusColor });
                dispatch({
                  type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                  data: { formData: { textBoxValue: ((option || {}).props || {}).name, optionId: ((option || {}).props || {}).key } }
                })
              }}
            /> */}
          </Col>
          </Row>
          <br />
          <Row>
        <Col span={12} className='padding-top-7'>
          <span className="text-input-label">
            Does this user have Membership?
          </span>
        </Col>
       
          <Col span={12}>
            <Switch
              checked={(formDataObj.isOrgMember === 1)}
              onClick={(checked, e) => {
                //  props.updateState({ isVolunteer: checked ? 'yes' : 'no'})

                updateFormData && updateFormData({ ...formDataObj, isOrgMember: checked ? 1 : 0  });
                setFormDataObj({ ...formDataObj, isOrgMember: checked ? 1 : 0 });
              }} />
        
          </Col>
          </Row>
          
          <br />
          <Row>
        <Col span={12} className='padding-top-7'>
          <span className="text-input-label">
            Is this user a Teacher?
          </span>
        </Col>
       
          <Col span={12}>
            <Switch
              checked={(formDataObj.isTeacher === 1)}
              onClick={(checked, e) => {
                //  props.updateState({ isVolunteer: checked ? 'yes' : 'no'})
                updateFormData && updateFormData({ ...formDataObj, isTeacher: checked ? 1 : 0  });
                setFormDataObj({ ...formDataObj, isTeacher: checked ? 1 : 0 });
              }} />
        
          </Col>
          </Row>
          <br />
          <Row>
        <Col span={12} className='padding-top-7'>
          <span className="text-input-label">
          Does this user in financial crisis and need financial assistance?
          </span>
        </Col>
       
          <Col span={12}>
            <Switch
              checked={(formDataObj.isFinancialAssistanceNeeded === 1)}
              onClick={(checked, e) => {
                //  props.updateState({ isVolunteer: checked ? 'yes' : 'no'})

                updateFormData && updateFormData({ ...formDataObj, isFinancialAssistanceNeeded: checked ? 1 : 0  });
                setFormDataObj({ ...formDataObj, isFinancialAssistanceNeeded: checked ? 1 : 0 });
              }} />
        
          </Col>
          </Row>
          <br></br>
          <Row>
           <Col span={21}>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL });}}
              /></Col>
               <Col span={3}>
              <CustomButton className="right-pull"
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </Col>
          </Row>

    </Form>
    </Fragment>
  )

  
}
export default (injectIntl(UpdateParentInfo));