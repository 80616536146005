import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import {  Skeleton, DatePicker, Select, Empty, Tooltip } from 'antd';
import { getMomentDateForUIReadOnly, getMomentDateForUI, getCurrentDateForBackend, showToasterMessage, fixedDecimalAndFormateNumber } from '../../../../utils'
import PageBreadcrumb from '../../../PageBreadcrumb';
import { CONSTANTS, ICONS } from '../../../../static/constants';
import '../index.scss'
import { UndoOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PageHeader from '../../../Common/pageHeader';
const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const {RangePicker} = DatePicker;


const InventoryValuationMonthlyListing = (props) => {

    const breadCrumbList = [
        {
            name: <FormattedMessage id="sidebar.menuItem.inventory" defaultMessage="" />
        },
        {
            name: <FormattedMessage id="sidebar.menuItem.allInvValuation" defaultMessage="" />,
            link: 'all-inventory-valuation'
        },
        {
            name: <FormattedMessage id="heading.inventry.monthlyInvValuation" defaultMessage="" />
        }
    ];
    const { closingStocks = {}, inwardStocks = {}, outwardStocks = {}} = props.monthlyInventoryValuationList;
    
    return (
        <>
            <PageHeader {...props} 
                pageName="heading.inventry.monthlyInvValuation"
                breadCrumbList={breadCrumbList} 
            />
            <div className='view-container stock-summary'>
                <div className="view-container-actions">
                    <div className="left-actions">

                    </div>
                    <div className="right-actions">
                        <RangePicker
                            style={{ width: '230px'}}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            allowEmpty={true}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        />

                        <Select
                            style={{ maxWidth: '120px'}}
                            options={[{ value: 'default', label: 'Default' }, { value: 'average-price', label: 'Average Price' }, { value: 'average-cost', label: 'Average Cost' }, { value: 'fifo', label: 'FIFO' }]}
                            value={props.selectedValuation}
                            placeholder='Select Valuation'
                            defaultValue= 'default'
                            onSelect={(value) => {
                                props.updateState({ selectedValuation: value });
                            }}
                        />

                        <button onClick={() => {
                            if(!props.startDate && !props.endDate && !props.selectedValuation){
                                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                            }

                            const dates = {
                                start : getCurrentDateForBackend(props.startDate),
                                end : getCurrentDateForBackend(props.endDate)
                            }

                            const payload = {
                                pageNumber: 1,
                                pageSize: props.pageSize || 100,
                                filters: {
                                    startDate: props.startDate,
                                    endDate: props.endDate,
                                },
                                companyInfo : props.companyInfo,
                                relationshipId: props.companyInfo.relationshipId,
                            };

                            props.updateState({
                                startDate: dates.start,
                                endDate: dates.end
                            })

                            props.fetchMonthlyInventoryValuationList(payload)
                            }}>
                                <i className={`${ICONS.FILTER} mr5`} /> Apply
                        </button>

                        <button onClick={() => {
                            props.updateState({
                                selectedCustomerId: null, selectedCustomerName: null,
                                startDate:null, endDate:null,
                                selectedValuation: 'default',
                                pageNumber:1 
                            });
                            }}>
                                <UndoOutlined className='mr5' /> Clear
                        </button>
                    </div>
                </div>

                <Skeleton loading={props.listLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    <div className="stock_reports_list">
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th colSpan="17"> 
                                        <div>{props.location.state.productName ? props.location.state.productName : ''}</div>
                                        <div>{props.companyInfo.storeName ? props.companyInfo.storeName : ''} </div>
                                        {props.startDate && props.endDate ? <div className='light-txt'><span>{getMomentDateForUIReadOnly({date: props.startDate || ''})}</span><span> to </span><span>{getMomentDateForUIReadOnly({ date: props.endDate || ''})}</span></div> : ''}
                                    </th>
                                </tr>
                                <tr>
                                    <th rowSpan={2} className='product'> <FormattedMessage id='inventory.invValuation.listing.header.particulars' defaultMessage='' /> </th>
                                    <th colSpan={2} className='incoming'> <FormattedMessage id='inventory.invValuation.listing.header.incoming' defaultMessage='' /> </th>
                                    <th colSpan={6} className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.header.outgoing' defaultMessage='' /> </th>
                                    <th colSpan={2} className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.header.closingBal' defaultMessage='' />  </th>
                                </tr>
                                <tr>
                                    {/* Inwards */}
                                    <th className='incoming'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='incoming'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>
            
                                    {/* Outwards */}
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.grossValue' defaultMessage='' /></th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.consumption' defaultMessage='' /> </th>
                                    <th className='outgoing'> <FormattedMessage id='inventory.invValuation.listing.subHeader.grossProfit' defaultMessage='' /> </th>
                                    <th className='outgoing'> % </th>

                                    {/* Closing Balance */}
                                    <th className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.qty' defaultMessage='' /></th>
                                    <th className='closing_bal'> <FormattedMessage id='inventory.invValuation.listing.subHeader.value' defaultMessage='' /> </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><i>Opening Balance</i></td>
                                    <td colSpan={10}></td>
                                </tr>
                                { (closingStocks && Object.keys(closingStocks).length > 0) ? (Object.keys(closingStocks)).sort().map((variantId, key)=>{
                                    let closingStocksRowItem = closingStocks[variantId] || {};
                                    let inwardStocksRowItem = inwardStocks[variantId] || {};
                                    let outwardStocksRowItem = outwardStocks[variantId] || {};
                                    return <tr key = {key}>
                                        <td>
                                            <Tooltip placement="right" title={`Open daily valuation for ${closingStocksRowItem.monthName || '' } month`} trigger="hover">
                                                <Link to = {{
                                                    pathname: '/admin/warehouse/daily-inventory-valuation',
                                                    state: {
                                                        productName: props.location.state.productName,
                                                        monthName: closingStocksRowItem.monthName,
                                                        yearAndMonth: closingStocksRowItem.yearAndMonth,
                                                        startDate: props.startDate,
                                                        endDate: props.endDate,
                                                        variantId: props.variantId
                                                        }
                                                    }}
                                                >
                                                    { closingStocksRowItem.monthName || '' }
                                                </Link>
                                            </Tooltip>
                                        </td>

                                        {/* average cost/ default : purchase price(self -- rate)* qty
                                        average price: sales price(outward)* qty(self)
                                        fifo -stockvaluation */}

                                        <td>{ inwardStocksRowItem ? inwardStocksRowItem.currentStock : '' }</td>
                                        <td>
                                            { inwardStocksRowItem && inwardStocksRowItem.currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost")  ? fixedDecimalAndFormateNumber(Number(inwardStocksRowItem.currentStock) * Number(inwardStocksRowItem.rate || 0)) : '' }
                                            { inwardStocksRowItem && inwardStocksRowItem.currentStock && props.selectedValuation === "average-price" ? fixedDecimalAndFormateNumber(Number(inwardStocksRowItem.currentStock) * Number(outwardStocksRowItem.salePrice || 0)) : '' }
                                            { props.selectedValuation === "fifo" && inwardStocksRowItem ? fixedDecimalAndFormateNumber(inwardStocksRowItem.stockValuation) : '' }
                                        </td>

                                        <td>{ outwardStocksRowItem ? outwardStocksRowItem.currentStock : '' }</td>
                                        <td>
                                            { outwardStocksRowItem && outwardStocksRowItem.currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost") ? fixedDecimalAndFormateNumber(Number(outwardStocksRowItem.currentStock) * Number(outwardStocksRowItem.purchasePrice || 0)) : '' }
                                            { outwardStocksRowItem && props.selectedValuation === "average-price" ? fixedDecimalAndFormateNumber(Number(outwardStocksRowItem.currentStock || 0) * Number(outwardStocksRowItem.salePrice || 0)) : '' }
                                            { outwardStocksRowItem && props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(outwardStocksRowItem.stockValuation) : '' }
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        <td>{ closingStocksRowItem ? fixedDecimalAndFormateNumber(closingStocksRowItem.currentStock) : '' }</td>
                                        <td>
                                            { closingStocksRowItem && closingStocksRowItem.currentStock && (!props.selectedValuation || props.selectedValuation === "default" || props.selectedValuation === "average-cost") ? fixedDecimalAndFormateNumber(Number(closingStocksRowItem.currentStock || 0) * Number(outwardStocksRowItem.rate || 0)) : '' }
                                            { closingStocksRowItem && props.selectedValuation === "average-price" ? fixedDecimalAndFormateNumber(Number(closingStocksRowItem.currentStock || 0) * Number(outwardStocksRowItem.salePrice || 0)) : '' }
                                            { closingStocksRowItem && props.selectedValuation === "fifo" ? fixedDecimalAndFormateNumber(closingStocksRowItem.stockValuation) : '' }
                                        </td>
                                    </tr>
                                }) 
                                :
                                <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                                }

                            </tbody>
                        </table>
                    </div>
                </Skeleton>

            </div>
        </>

    );
};

export default injectIntl(InventoryValuationMonthlyListing);
