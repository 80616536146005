import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilePdfOutlined, MailOutlined, PrinterOutlined, EditOutlined, DeleteOutlined,  HistoryOutlined } from '@ant-design/icons';
import { Button, Skeleton } from 'antd'
import * as find from 'lodash.find';
import config from '../../../../../config/environmentConfig';
import { CONSTANTS, TRANSACTION_TYPES, MODAL_TYPE } from '../../../../../static/constants';
import { getLabelName } from '../../../../../components/modal/modalBody/customerInfo';
import { Dropdown } from '../../../../general/Dropdown';
import StandardTemplate from "./standard";
import Template2 from "./template2";
import StandardPurchaseOrder from "../../../../../containers/supplier/purchaseOrder/StandardPurchaseOrder";
import CreateSplitRFQ from "../../../../../containers/supplier/rfq/SplitRFQ";


const RFQSplitDetails = (props) => {
    const { rfqData, companyInfo, templateName = 'Professional' } = props;
    const rfqSplitData = find(rfqData.rfqChildMasterList, { rfqChildMasterId: props.rfqPayload.rfqChildMasterId }) || {};
    const relationshipAddress = find(rfqData.boLocationRFQList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
    const billingAddress = find(rfqData.boLocationRFQList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
    let isColumnEmpty = {
        product: true,
        service: true,
        variant: true,
        description: true,
        qty: true,
        uomName: true,
        rate: true,
        hsn: true,
        discount: true,
        tax: true,
        specialDiscount: true,
        documentDetails: true
    };
    if (rfqSplitData) {
        isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, rfqSplitData);
    }

    const getPayloadForPdf = () => {
        return {
            templateName: templateName,
            fileName: (rfqSplitData.rfqChildNumber || 'RFQ')+'.pdf',
            transactionName: 'RFQChild',
            rfqData: rfqData,
            rfqChild: rfqSplitData,
            relationshipAddress: relationshipAddress,
            billingAddress: billingAddress,
            companyInfo: companyInfo,
            isColumnEmpty: isColumnEmpty,
            bucketName: config.BUCKET_NAME.BO_RFQ,
            stampAwsKey: rfqData.stampAwsKey
        }
    }

    const generatePDF = () => {
        let payload = getPayloadForPdf();
        props.generatePDF(payload);
    }

    const printPdf = () =>{
        let payload = getPayloadForPdf();
        props.printPDF(payload);
    }

    const openEmailDrawer = (props) => {
        let payload = getPayloadForPdf();
        payload.fileDataReqeired = true;
        payload.rfqMasterId = rfqData.rfqMasterId;
        props.updateState({
            emailDrawerVisible: true,
            txData: payload,
            type: TRANSACTION_TYPES.RFQ,
        });
    };
    const approveRFQ = () => {
        const modalBody = 'Are you sure you want to approve?';
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.approveRFQ({
                    relationshipId: props.rfqPayload.relationshipId,
                    rfqChildMasterId: props.rfqPayload.rfqChildMasterId,
                    rfqMasterId: props.rfqPayload.rfqMasterId,
                    approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                }, props)
                props.popModalFromStack();
            },
        };
        props.showModal(modalData);
    }

    const handleDelegateApprover = () => {
        const formData = {
            relationshipId: props.rfqPayload.relationshipId,
            rfqChildMasterId: props.rfqPayload.rfqChildMasterId,
            rfqMasterId: props.rfqPayload.rfqMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            approverEmpId: props.rfqPayload.approverEmpId,
            txType: TRANSACTION_TYPES.RFQ
        };
        const { showModal } = props;
        const data = {
            title: 'Delegate Approver',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.DELEGATE_APPROVER,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const handleRejectApproval = () => {
        const formData = {
            relationshipId: props.rfqPayload.relationshipId,
            rfqChildMasterId: props.rfqPayload.rfqChildMasterId,
            rfqMasterId: props.rfqPayload.rfqMasterId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
            txType: TRANSACTION_TYPES.RFQ
        };
        const { showModal } = props;
        const data = {
            title: 'Reject',
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.REJECT_APPROVAL,
                data: formData,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                props.hideModal(data);
                props.popModalFromStack();
            }
        };
        showModal(data);
    }

    const updateStatusConfirmationModal = (props, payload, modalType) => {
        const labelName = "New Status";
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${payload.status} to ${payload.text}` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        const modalData = {
            modalBody,
            handleSubmit: (formData) => {
                const statusObj = find(props.rfqStatusList, { rfqStatusId: payload.statusId }) || {};
                props.updateRfqStatus({
                    status: formData.textBoxValue,
                    relationshipId: props.companyInfo.relationshipId,
                    customerId: payload.customerId,
                    rfqChildMasterId: payload.rfqChildMasterId,
                    rfqMasterId: payload.rfqMasterId,
                    statusId: payload.statusId,
                    statusColor: statusObj.statusColor,
                }, props)
                props.hideModal();
            },
        };
        props.showModal(modalData);
    }

    const addItemConfirmationModal = (props, text, modalType, payloadObj) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;
        let payload = { text: text, relationshipId: companyInfo.relationshipId }

        switch (modalType) {
            case MODAL_TYPE.RFQ_STATUS: {
                addFunc = props.addRFQStatus;
                payload.statusColor = ' new';
                break;
            }
            case MODAL_TYPE.RFQ_PRICE_CODES: {
                addFunc = props.addRFQPriceCodes;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: (data) => {
                addFunc(payload, props);
                props.hideModal();
                payloadObj.formData.status = payload.text;
                handleUpdateStatus(props, payloadObj.formData, MODAL_TYPE.UPDATE_STATUS)
            },
        };
        showModal(modalData);
    }

    const handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal, hideModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType, payload);
        } else {
            const data = {
                title: <FormattedMessage id='addItem.text.rfqStatus' defaultMessage='' />,
                formData,
                isStack: true,
                onCancel: (type) => {
                    if (type !== "update_status") {
                        handleUpdateStatus(props, payload.formData, MODAL_TYPE.UPDATE_STATUS)
                    } else {
                        hideModal();
                    }
                },
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, modalType, payload);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.RFQ_STATUS: {
                deleteFunc = props.deleteRFQStatus;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }, props);
                props.hideModal();
                payload.formData = {};
                handleUpdateStatus(props, payload.formData, MODAL_TYPE.UPDATE_STATUS)
            },
        };
        showModal(modalData);
    }

    const handleUpdateStatus = (props, payload, modalType) => {
        const data = {
            title: <FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage='' />,
            modalData: {
                modalType,
                data: payload,
                isDropDown: true,
                list: props.rfqStatusList,
                valueKeyName: 'statusName',
                optionKeyName: 'rfqStatusId',
                selectedValue: payload.status,
                canAddNew: true,
                canDelete: true,
                deleteClickHandler: (payloadObj) => {
                    deleteClickHandler(props, MODAL_TYPE.RFQ_STATUS, payloadObj);
                },
                onAddNewClick: (payloadObj) => {
                    payloadObj.formData = {
                        textBoxValue: payloadObj.textEntered,
                        submittedOnce: false,
                        itemPayload: payload
                    }
                    handleNewItemAddition(props, payloadObj, MODAL_TYPE.RFQ_STATUS)
                }
            },
            handleSubmit: (formData = {}) => {
                if (formData.textBoxValue && formData.textBoxValue.length) {
                    updateStatusConfirmationModal(props, {
                        rfqMasterId: payload.rfqMasterId,
                        customerId: payload.customerId,
                        text: formData.textBoxValue,
                        rfqChildMasterId: payload.rfqChildMasterId,
                        statusId: Number(formData.optionId),
                        statusColor: formData.statusColor,
                        status: payload.status,
                    }, modalType);
                }
            }
        };
        props.showModal(data);
    }

    const openAuditDrawer = (props, data) => {
        props.updateState({
            title: <FormattedMessage id='modal.txDetails.common.auditTrail' defaultMessage='' />,
            auditDrawerVisible: true,
            deletApiUrl: 'deleteAuditTrailRFQParent',
            masterKey: 'rfqMasterId',
            masterKeyValue: data.rfqMasterId,
            apiUrl: 'saveAuditTrailRFQParent',
            supplierId: data.supplierId,
            trailList: data.auditTrailRFQParentList || data.auditTrailRFQChildList || [],
            updateTrailListInTransaction: (list) =>{
                data.auditTrailRFQParentList = data.auditTrailRFQParentList && list;
                data.auditTrailRFQChildList = data.auditTrailRFQChildList && list;
            }
        });
    }

    return (
        <div className={"custom-modal show"}>

            {templateName === 'Standard' ? <StandardTemplate {...props} /> : ''}
            {templateName === 'Professional' ? <Template2 {...props} /> : ''}

            <div className="modal-dialog">
                <div className="modal-content full-width">
                   {rfqSplitData.status !== 'Pending Approval' && rfqSplitData.status !== 'Rejected' ?
                    <div className=" button-group-action header-control">
                        <div className="small-btn-group">
                            <Button 
                                style={{height: '38px'}}
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.edit', defaultMessage: '' })}
                                icon={<EditOutlined />}
                                onClick={() => {
                                    props.popModalFromStack();
                                    // props.history.push({
                                    //     pathname: "/admin/update-rfq",
                                    //     state: {
                                    //         customerId: rfqData.customerId,
                                    //         rfqMasterId: rfqData.rfqMasterId
                                    //     }
                                    // })
                                    
                                    props.pushModalToStack({
                                        modalBody: <CreateSplitRFQ {...props} rfqMasterId= {rfqData.rfqMasterId} customerId={rfqData.customerId} isAllSupplier={1} isUpdate={true} />,
                                        width: '100%',
                                        hideTitle: true,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                }}
                            >
                            </Button>

                            <Button
                                style={{height: '38px'}}
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.delete', defaultMessage: '' })}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    props.showModal({
                                        modalBody: "Are you sure you want to delete this transaction?",
                                        handleSubmit: () => {
                                            props.popModalFromStack();
                                            props.deleteChildRFQ({
                                                supplierId: rfqData.supplierId,
                                                rfqMasterId: rfqData.rfqMasterId,
                                                rfqChildMasterId: rfqSplitData.rfqChildMasterId,
                                                relationshipId: rfqData.relationshipId,
                                                version: rfqSplitData.version,
                                            }, props)
                                        }
                                    })
                                }}
                            >
                            </Button>
                        </div>

                        <div className="small-btn-group">
                            <Button
                                style={{height: '38px'}}
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.email', defaultMessage: '' })}
                                icon={<MailOutlined />}
                                onClick={() => {
                                    openEmailDrawer(props);
                                }}
                            />

                            <Button
                                style={{height: '38px'}}
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                    generatePDF();
                                }}
                            />
                            <Button
                                style={{height: '38px'}}
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                icon={<PrinterOutlined />}
                                onClick={() => {
                                    printPdf();
                                }}
                            />
                        </div>

                        <div className="small-btn-group">
                            <Button 
                                style={{height: '38px'}}
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.auditTrail', defaultMessage: '' })}
                                icon={<HistoryOutlined />}
                                onClick={() => {
                                    openAuditDrawer(props, rfqSplitData);
                                }}
                            >
                            </Button>
                        </div>

                        {!rfqSplitData.isPoConverted ?  
                            <Button
                                style={{height: '38px'}}
                                onClick={() => {
                                    props.popModalFromStack();
                                    props.pushModalToStack({
                                        modalBody: <StandardPurchaseOrder {...props} rfqChildMasterId= {props.rfqPayload.rfqChildMasterId} rfqMasterId= {rfqSplitData.rfqMasterId} />,
                                        width: '100%',
                                        hideTitle: true,
                                        hideFooter: true,
                                        wrapClassName: 'new-transaction-wrapper'
                                    })
                                }}
                                type="primary"
                            >
                                <FormattedMessage id='modal.txDetails.rfq.convertToPO' defaultMessage='' /> 
                            </Button>
                            :
                            ""
                        }

                        <Button 
                            style={{height: '38px'}}
                            onClick={() => {
                            handleUpdateStatus(
                                props,
                                {
                                    submittedOnce: false,
                                    customerId: rfqData.customerId,
                                    rfqMasterId: rfqData.rfqMasterId,
                                    rfqChildMasterId: rfqData.rfqChildMasterId,
                                    status: rfqData.status
                                },
                                MODAL_TYPE.UPDATE_STATUS
                            )
                        }}>
                            <FormattedMessage id='modal.txDetails.common.updateStatus' defaultMessage='' /> 
                        </Button>

                        <Dropdown 
                            style={{'width': '120px'}}
                            placeholder="Template"
                            value={props.templateName}
                            items={['Standard', 'Professional']}
                            onSelect={(selectedValue) => {
                                props.updateState({
                                    templateName: selectedValue,
                                });
                            }}
                        />
                     
                    </div>
                    :
                    <div className="button-group-action">
                        {rfqSplitData.status !== 'Rejected' && (props.userInfo || {}).relationshipEmployeeId === rfqSplitData.approverEmpId ? 
                            <div className=" button-group-action header-control">
                                <Button type="primary" onClick={() => { approveRFQ() }}>
                                    <FormattedMessage id='modal.txDetails.rfq.approveRFQ' defaultMessage='' />
                                </Button>
                                <Button type="primary" onClick={() => { handleDelegateApprover(); }}>
                                    <FormattedMessage id='modal.txDetails.common.delegateApprover' defaultMessage='' />
                                </Button>
                                <Button type="danger" onClick={() => { handleRejectApproval(); }}>
                                    <FormattedMessage id='modal.txDetails.common.reject' defaultMessage='' />
                                </Button>
                            </div> 
                            :
                            ''
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    );

}

const updateColumnEmptyCheck = (isColumnEmpty, rfqSplitData) => {
    if (!rfqSplitData) return isColumnEmpty;
    for (let i in rfqSplitData.rfqChildDetailList) {
        let row = rfqSplitData.rfqChildDetailList[i];
        if (row.itemName) { isColumnEmpty.product = false; };
        if (row.serviceName) { isColumnEmpty.service = false; };
        if (row.itemVariantName) { isColumnEmpty.variant = false; }
        if (row.description) { isColumnEmpty.description = false; }
        if (row.quantity) { isColumnEmpty.qty = false; }
        if (row.uomName) { isColumnEmpty.uomName = false; }
    }
    return isColumnEmpty;
}

export default RFQSplitDetails;
