import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ExpenseCompo from '../../../../../components/modal/modalBody/common/Expense';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { createOrUpdateExpense, getExpenseLedgerAccounts } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { getAllTaxByName } from '../../../../drawer/inventory/action';

class ExpenseContainer extends Component {
  constructor(props) {
    super(props);
    let data = props.data || {};
    this.state = {
      expenseType: data.expenseType,
      version: data.version,
      accountingEntry: data.accountingEntry
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      actionName: 'globle_taxes',
      accountingEntry: this.state.accountingEntry
    };
    fetchDataIfNeeded('getExpenseLedgerAccounts', 'expenseLedgerList', this.props, payload);
    fetchDataIfNeeded('getAllTaxByName', 'globleTaxes', this.props, payload);
  }

  render() {
    return (
      <ExpenseCompo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    expenseLedgerList: state.common.expenseLedgerList,
    globleTaxes: state.inventory.globleTaxes
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createOrUpdateExpense,
  pushModalToStack,
  popModalFromStack,
  getExpenseLedgerAccounts,
  getAllTaxByName,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseContainer);
