import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchRelationshipDataByRID = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/clientRelationship/getRelationshipByRID?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SETTING_ACTION_LIST.RELATIONSHIP_DATA, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
        }
      })
  }
}


export const fetchRelationshipByDomainName = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getRelationshipId?name=${payload.domainName}`)
      .then(res => {
        const { data } = res
        const load = { relationshipId: data.relationshipId }
        props.fetchDonationSetting(load);
        props.fetchCountries(load);
        props.fetchRelationshipDataByRID(load);
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SETTING_ACTION_LIST.RELATIONSHIP_DATA;
        }
      })
  }
}

export const unsubscribe = (requestObj, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/alpide-tcom/dg/unsubscribe`,
        requestObj,
      )
      .then(res => {
        showToasterMessage({
          description: (res || {}).message || 'Unsubscribe successfully.',
          messageType: 'success'
        });
      })
      .catch(err => {
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}