import React, { Fragment } from 'react';
import './../../../../assets/stylesheets/common/invoice.scss';
import './../../finance.scss';
import PageBreadcrumb from "../../../PageBreadcrumb";
import JournalDetails from "./JournalDetail";
import ProductDetails from "./ProductDetail";

const JournalComponent = (props) => {
    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        {
            name: 'Finance'
        }, {
            name: 'Journals',
            link: 'show-Journals'
        }, {
            name: 'Create Journal'
        }
    ];

    return (
        <Fragment>
            <div className="invoice-header finance-container">
                <PageBreadcrumb breadCrumbList={breadCrumbList} />
            </div>

            <div className="invoice-details-container">
                <JournalDetails  {...props}/>
            </div>

            <div className="invoice-details-container">
                <ProductDetails {...props} />
            </div>
        </Fragment>
    )
};

export default JournalComponent;
