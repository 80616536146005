import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
// import { AgGridReact } from "ag-grid-react";
import { DatePicker, Menu, Input, Tooltip, Pagination, Skeleton, Popover } from "antd";
import {
  formatDateForBackend,
  getMomentDateForUI,
  exportExcel,
  getInitialsFromString,
  exportCSV,
} from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
import { Dropdown } from "../../general/Dropdown";
import DropdownAction from "antd/lib/dropdown";
import { DownloadOutlined } from "@ant-design/icons";
import { ICONS, MONTH_NAME_CONSTANT } from "../../../static/constants";
import EditAttendanceComponent from './EditAttendancePop';

const { Search } = Input;

const School = (props) => {
  const { activeStudentCount, companyInfo, updateState, pageNumber, studentAttendanceList } = props;
  const [viewMode, setViewMode] = useState(0);
  const [visibleEdit, setVisibleEdit] = useState([0, 0]);
  const [editPop, setEditPop] = useState(false);

  const stickyStyle = {
    position: 'sticky',
    left: '0px',
    backgroundColor: '#ffffff',
    zIndex: 1
  }

  const getDateList = (dateObj) => {
    const date = dateObj ? new Date(dateObj) : new Date();
    let dateList = [];
    let maxDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    for (let i = 1; i <= maxDate; i++) {
      dateList.push(i);
    }
    return dateList;
  };

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.students" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.attendance" defaultMessage="" />,
    },
  ];

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  // const loadPageData = (pageNumber, pageSize) => {
  //   const payload = {
  //     companyInfo,
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
  //     pageNumber: pageNumber,
  //     pageSize: pageSize,
  //   };
  //   fetchPaginationDataIfNeeded("getSchool", "schoolList", props, payload);
  // };

  // const handlePageSizeChange = (pageNumber, pageSize, props) => {
  //   const payload = {
  //     companyInfo,
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
  //     pageNumber: pageNumber,
  //     pageSize: pageSize,
  //     classId: props.classId,
  //     className: props.className,
  //   };
  //   props.resetPaginatedData();
  //   props.getSchoolAttendanceByClassId(payload);
  //   props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  // };

  // const downloadExcel = () =>{
  //   if (props.studentAttendanceList.length) {
  //     exportExcelData(props.studentAttendanceList)
  //   }
  //   // else {
  //   //   const modalData = {
  //   //     title:
  //   //       <div className="title">
  //   //         <i className="fa fa-ban mr15" />
  //   //         Validation Failed
  //   //       </div>,
  //   //     modalBody:
  //   //       <Fragment>
  //   //         <div className="warning-message">
  //   //           No record found.
  //   //         </div>
  //   //         <div className="button-action">
  //   //           <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
  //   //         </div>
  //   //       </Fragment>,
  //   //     hideFooter: true,
  //   //     wrapClassName: 'validation-failed-modal'
  //   //   };
  //   //   props.pushModalToStack(modalData);
  //   // }
  // }

  const newAttendance = () => {
    props.updateHeaderState({
      schoolDrawers: {
        ...props.schoolDrawers,
        addAttendenceDrawerVisible: true,
      },
    });
  };

  const exportDataDownload = (studentList, format) => {
    const dataList = (studentList || []).map((item, i) => {
      if (!viewMode) {
        const rowData = {
          // '0': i + 1,
          0: item.studentName || "",
        };
        dateList.forEach((dateKey) => {
          rowData[MONTH_NAME_CONSTANT[currentMonth] + " " + dateKey] =
            item.attendance[dateKey]?.split("")[0] || "";
        });
        return rowData;
      } else {
        const rowData = {
          "Student Name": item.studentName || "",
          "Total Count": item.totalCount,
          Present: item.presentCount,
          Absent: item.absentCount,
          Holiday: item.holidayCount,
          Sunday: item.sundayCount,
        };
        return rowData;
      }
    });
    const year = new Date().getFullYear();
    if (format === "Excel") {
      exportExcel(
        dataList,
        `Student_Attendance_${MONTH_NAME_CONSTANT[currentMonth]}_${year}`
      );
    }
    if (format === "Csv") {
      exportCSV(
        dataList,
        `Student_Attendance_${MONTH_NAME_CONSTANT[currentMonth]}_${year}`
      );
    }
  };

  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            if (props.studentAttendanceList.length) {
              exportDataDownload(props.studentAttendanceList, "Excel");
            }
          }}
        >
          <i className={ICONS.EXCEL} /> Excel File
        </Menu.Item>

        <Menu.Item
          key="2"
          value="csv"
          onClick={() => {
            if (props.studentAttendanceList.length) {
              exportDataDownload(props.studentAttendanceList, "Csv");
            }
          }}
        >
          <i className={ICONS.CSV} /> CSV File
        </Menu.Item>
      </Menu>
    );
  };

  const getDateList2 = (dateObj) => {
    const date = dateObj ? new Date(dateObj) : new Date();
    let dateList = [];
    let maxDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    for (let i = 1; i <= maxDate; i++) {
      dateList.push(i);
    }

    return [dateList, date.getMonth() + 1]; // Return an array with dateList and current month
  };

  const [dateList, currentMonth] = getDateList2(props.selectedDate);

  function extractFirstAndLastName(fullName) {
    const namesArray = fullName.split(" ");
    const firstName = namesArray[0];
    const lastName = namesArray[namesArray.length - 1];
    const formattedName = `${firstName} ${lastName}`;

    return formattedName;
  }

  const viewMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          View as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="Standard View"
          onClick={() => {
            setViewMode(0);
          }}
        >
          <i className="fi fi-rs-standard-definition" /> Standard View
        </Menu.Item>

        <Menu.Item
          key="2"
          value="Time View"
          onClick={() => {
            setViewMode(1);
          }}
        >
          <i className="fi fi-rs-notebook" /> Summary View
        </Menu.Item>
      </Menu>
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    let date = props.selectedDate ? new Date(props.selectedDate) : new Date();
    let y = date.getFullYear();
    let m = date.getMonth();
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      relationshipEmployeeId: (props.userInfo || {}).relationshipEmployeeId,
      classId: props.classId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      startDate: formatDateForBackend(new Date(y, m, 1)),
      endDate: formatDateForBackend(new Date(y, m + 1, 0)),
    };
    props.getSchoolAttendanceByClassId(payload);
    props.getRegisteredMemberByClass(payload);
    props.getRegisteredMemberCountByClass(payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetPaginatedData();
    props.fetchCreditMemos(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const getStatusInitial = (status) =>{
    switch (status) {
      case "Present":
        return <span className="essential">P</span>;
      case "Absent":
        return <span className="required">A</span>;
      case "Leave":
        return <span className="Leave">L</span>;
      case "Tardy":
        return <span>T</span>;
      case "Holiday":
        return <span>H</span>;
      case "Excuse Leave":
        return <span>L</span>;
      default:
        return null;
    }
  }

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.students"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button
            className="create-action-btn"
            onClick={() => {
              newAttendance();
            }}
          >
            <FormattedMessage id="markAttendance.text" defaultMessage="" />
          </button>
        </div>
      </div>
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="schoolManagement.attendance.table.heading"
                defaultMessage=""
              />
            </div>
            <div className="vertical-sep" />
            {/* {activeStudentCount ? (
             <>
            
                <div>{activeStudentCount}</div>
                </>
            ) : (
              0
            )} */}
             {(studentAttendanceList || []).length ? (
             <>
            
                <div>{(studentAttendanceList || []).length}</div>
                </>
            ) : (
              0
            )}
          </div>
          <div className="right-actions">
            <Search
              placeholder="Search by Name/Designation"
              value={props.searchedText}
              onChange={(e) => {
                props.updateState({
                  searchedText: e.target.value,
                  pageNumber: 1,
                });
                // onSearchedInputChange(e.target.value, props);
              }}
              onSearch={(searchedText) => {
                props.updateState({ searchedText, pageNumber: 1 });
                // onSearchedInputChange(searchedText, props);
                // handleSearchedText(searchedText, props);
              }}
              style={{ width: 200, height: 38 }}
            />

            <Dropdown
              style={{ width: "180px" }}
              items={props.classList[1] || []}
              valueKeyName="className"
              optionKeyName="classId"
              value={props.className}
              placeholder="Class Name"
              onSelect={(optionValue, option) => {
                let date = props.selectedDate
                  ? new Date(props.selectedDate)
                  : new Date();
                let y = date.getFullYear();
                let m = date.getMonth();
                const payload = {
                  relationshipId: props.companyInfo.relationshipId,
                  relationshipEmployeeId: (props.userInfo || {})
                    .relationshipEmployeeId,
                  pageNumber: 1,
                  pageSize: 1000,
                  classId: optionValue,

                  startDate: formatDateForBackend(new Date(y, m, 1)),
                  endDate: formatDateForBackend(new Date(y, m + 1, 0)),
                };
                props.getSchoolAttendanceByClassId(payload);
                // props.getRegisteredMemberByClass(payload);
                // props.getRegisteredMemberCountByClass(payload);
                props.updateState({
                  className: option.name,
                  classId: optionValue,
                });
              }}
            />

            <DatePicker
              style={{ maxWidth: "130px" }}
              defaultValue={getMomentDateForUI({
                date: props.selectedDate || new Date(),
                format: "MMM-YYYY",
              })}
              onChange={(selectedDate) => {
                const companyInfo = props.companyInfo || {};
                let date = new Date(selectedDate);
                props.updateState({ selectedDate });
                let y = date.getFullYear();
                let m = date.getMonth();
                const payload = {
                  relationshipId: companyInfo.relationshipId,
                  classId:
                    props.classId || (props.classList[1][0] || {}).classId,
                  relationshipEmployeeId: (props.userInfo || {})
                    .relationshipEmployeeId,
                  startDate: formatDateForBackend(new Date(y, m, 1)),
                  endDate: formatDateForBackend(new Date(y, m + 1, 0)),
                };
                props.getSchoolAttendanceByClassId(payload);
              }}
              picker="month"
            />

            {/* <Dropdown
              style={{maxWidth: '130px'}}
              items={["Standard View", "Summary View"]}
              defaultValue="Standard View"
              value={viewMode ? "Summary View": "Standard View"}
              placeholder='Select View'
              onSelect = {(val)=>{
                if(val === "Summary View")
                  setViewMode(1);
                else
                  setViewMode(0);
              }}
            /> */}

            <DropdownAction overlay={viewMenu()} trigger={["click"]}>
              <span>
                <i className={`${ICONS.VIEW} mr5`} />{" "}
                {viewMode ? "Summary View" : "Standard View"}
              </span>
            </DropdownAction>

            <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined /> &nbsp;{" "}
                <FormattedMessage
                  id="common.action.dropdown.downloadAs"
                  defaultMessage=""
                />
              </span>
            </DropdownAction>
            {/* <Button onClick={downloadExcel()}><DownloadOutlined /> Download As</Button> */}
          </div>
        </div>
        <Skeleton loading={props.isLoading}>
          {!viewMode ? (
            <div className="attendance-listing-table" style={{height: 'calc(100% - 50px)'}}>
              <table>
                <thead>
                  <tr>
                    <th style={{...stickyStyle, width: '60px', top:'0px', zIndex:9}}>S.No</th>
                    <th style={{...stickyStyle, width: '200px', left:'58px', top:'0px', zIndex:9}}>Student Name</th>

                    {(getDateList(props.selectedDate) || []).map((date, i) => {
                      return (
                        <th key={date}>
                          {MONTH_NAME_CONSTANT[currentMonth]} {date}
                        </th>
                      );
                    })}
                  
                  </tr>
                </thead>
                <tbody>
                  { (studentAttendanceList || []).length ? (
                      studentAttendanceList.map((rowData, j) => {
                        return (
                          <tr key={j}>
                            <td style={{...stickyStyle, width: '60px'}}>{j + 1}</td>
                            <td style={{...stickyStyle, width: '200px', left:'60px'}}>
                              <div className="d-flex mr10">
                                <span className="user-icon">
                                  {getInitialsFromString(rowData.studentName)}
                                </span>
                                <span>
                                  <Tooltip
                                    placement="top"
                                    title={rowData.studentName}
                                    trigger="hover"
                                  >
                                    <div className="line-view">
                                      {extractFirstAndLastName(rowData.studentName)}
                                    </div>
                                  </Tooltip>
                                  <div className="light-txt">
                                    {rowData.className || ""}-{rowData.rollNo || ""}
                                  </div>
                                </span>
                              </div>
                            </td>
                            {(getDateList(props.selectedDate) || []).map(
                              (dateKey, k) => {
                                return (
                                  <td
                                    key={"att" + k}
                                    style={{ width: "40px" }}
                                    title={rowData.attendance[dateKey]}
                                    onMouseEnter={() => {
                                      if(rowData.attendance[dateKey] && !editPop){
                                        setEditPop(false);
                                        setVisibleEdit([j, dateKey]);  //[row_number, col_number]
                                      }}
                                    } 
                                    onMouseLeave={()=>{
                                      if(rowData.attendance[dateKey] && !editPop){
                                        setEditPop(false);
                                        setVisibleEdit([0, 0]);  //[row_number, col_number]
                                      }
                                    }}
                                  >
                                    {getStatusInitial(rowData.attendance[dateKey])}

                                    {/* edit functionality for each day attendance for each cell */}
                                    {(visibleEdit || []).length && visibleEdit[0] === j && visibleEdit[1] === dateKey && rowData.attendance[dateKey] ? (
                                      <Popover
                                        content={<EditAttendanceComponent 
                                                  {...props} 
                                                  studentData= {rowData} 
                                                  attendanceStatus= {rowData.attendance[dateKey]} 
                                                  setEditPop= {setEditPop}
                                                  selectedDate= {props.selectedDate || `${MONTH_NAME_CONSTANT[currentMonth]} ${dateKey} ${(new Date()).getFullYear()}`}
                                                />}
                                        title={<div className="b-text">Edit Attendance</div>}
                                        trigger="click"
                                        visible={editPop}
                                        placement='left'
                                        style={{height: '500px'}}
                                        overlayClassName={'edit-attendance-pop'}
                                      >
                                        <i className={`${ICONS.EDIT_P} ml5`} onClick={()=>setEditPop(!editPop)}/>
                                      </Popover>
                                    )
                                    :
                                    ''
                                    }
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        );
                    })
                  ) : (
                    <tr>
                      <td colSpan={17} align="center">
                        {props.classId ? "Attendance does not exist. Please Mark Attendance" : "Please select class to display attendance"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) 
          :
          (
            <div className="attendance-listing-table">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Student Name</th>
                    <th>Total</th>
                    <th>Present</th>
                    <th>Absent</th>
                    <th>Holiday</th>
                    <th>Sunday</th>
                  </tr>
                </thead>
                <tbody>
                  {(studentAttendanceList || []).length ? (
                    studentAttendanceList.map((e, j) => {
                      return (
                        <tr key={`summary` + j}>
                          <td>{j + 1}</td>
                          <td>
                            <div className="d-flex mr10">
                              <span className="user-icon">
                                {getInitialsFromString(e.studentName)}
                              </span>
                              <span>
                                <Tooltip
                                  placement="top"
                                  title={e.studentName}
                                  trigger="hover"
                                >
                                  <div className="line-view">
                                    {extractFirstAndLastName(e.studentName)}
                                  </div>
                                </Tooltip>
                                <div className="light-txt">
                                  {e.className || "-"}
                                </div>
                              </span>
                            </div>
                          </td>
                          <td>
                            {e.presentCount + e.absentCount + e.holidayCount}
                          </td>
                          <td>{e.presentCount}</td>
                          <td>{e.absentCount}</td>
                          <td>{e.holidayCount}</td>
                          <td>{e.sundayCount}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={17} align="center">
                        Attendance does not exist. Please Mark Attendance
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </Skeleton>
        
        {/* <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={activeStudentCount}
              showTotal={showTotal}
              defaultCurrent={pageNumber || 1}
              defaultPageSize={props.pageSize || 25}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber, pageSize, props);
              }}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default injectIntl(School);
