import React, { Fragment } from "react";
import { Row, Col, Button, Input, Popover,Skeleton } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import ReactHtmlParser from 'react-html-parser';

import { Dropdown } from "../../../../general/Dropdown";
import { CustomAgGrid } from "../../../../general/CustomAgGrid";
import { AddAndRemoveColumn } from "../../../../general/AddAndRemoveColumn";
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../../static/agGridConstants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import {
    MODAL_TYPE, MODULE_CODED_VALUES, PERMISSION_VALUES, TRANSACTION_COLUMN_INDEX
} from '../../../../../static/constants'
import { getLabelName } from '../../../../modal/modalBody/customerInfo';
import { showToasterMessage, fixedDecimalAndFormateNumber } from "../../../../../utils";
import { CustomTableUpload } from "../../../../general/UploadTable";
import { CUSTOM_CONFIGURATION } from "../../../../../static/ckEditorConfigConstants";
import CKEditor from "../../../../general/CustomCKEditor/ckeditor";
import { AWSFileTable } from "../../../../general/AWSFileTable";


class ProductDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            sectionVisible: {
                discount: false,
                tax: false,
                expense: false
            },
            total: 0,
            subtotal: 0,
            subtotalWithoutDiscount: 0,
            totalDiscount: 0,
            expenseAmount: 0,
            totalTax: 0,
            itemLevelTaxes: [],
            rootDiscountPercent: 0,
            rootDiscountAmount: 0
        }
    }

    calculateSubtotal = () => {
        let total = 0;
        let subtotal = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let itemLevelTaxes = []
        let subtotalWithoutDiscount = 0;
        let rootDiscountAmount = 0;
        let self = this;

        this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
            let stringifiedRowData = JSON.stringify(rowNode.data);
            let totalAmountOfColumn = 0;
            const rowdata = JSON.parse(stringifiedRowData);
            if (rowdata.qty && rowdata.rate) {
                let taxObj = {
                    subtotal: 0,
                    taxPercent: 0,
                    taxAmount: 0
                }
                totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
                subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
                if (rowdata.discount) {
                    let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
                    totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                    totalDiscount = totalDiscount + discountAmount;
                }

                if (self.state.rootDiscountPercent) {
                    let itemLevelDiscountAmount = (Number(self.state.rootDiscountPercent) * 0.01) * totalAmountOfColumn;
                    totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
                    totalDiscount = totalDiscount + itemLevelDiscountAmount
                    rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
                }

                if (rowdata.tax) {
                    taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
                    taxObj.taxName = rowdata.taxName;
                    let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
                    totalTax = totalTax + taxAmount;
                }
                if (taxObj.taxPercent) {
                    taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
                    const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                    if (availableTaxObj) {
                        itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                            if (ele.taxPercent === taxObj.taxPercent) {
                                ele.subtotal = Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                                ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
                            }
                            return true;
                        });
                    } else {
                        taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
                        itemLevelTaxes.push(taxObj);
                    }
                }
                subtotal = Number(subtotal) + totalAmountOfColumn;
            }
        });


        total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;

        this.setState({
            total: Number(total || 0).toFixed(2),
            subtotal: Number(subtotal || 0).toFixed(2),
            totalDiscount: Number(totalDiscount || 0).toFixed(2),
            totalTax: Number(totalTax || 0).toFixed(2),
            subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
            itemLevelTaxes,
            rootDiscountAmount
        });
    }

    handleNewTaxAddition = (props, payload) => {
        const formData = payload.formData;
        const { showModal, companyInfo } = props;
        this.gridApi && this.gridApi.stopEditing();
        formData.relationshipId = companyInfo.relationshipId;
        const data = {
            title: <FormattedMessage id='addItem.text.newTax' defaultMessage='' />,
            formData,
            hideFooter: true,
            modalData: {
                modalType: MODAL_TYPE.NEW_TAX,
                data: payload,

            },
            handleSubmit: (formData = {}) => {
                data.formData.submittedOnce = true;
                showModal(data);
            }
        };
        showModal(data);
    }

    addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage={``}
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                addFunc = props.addUOM;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                addFunc = props.addExpenseTypes;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId });
                this.gridApi && this.gridApi.stopEditing();
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                title = <FormattedMessage id='addItem.text.expenseType' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: this.getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        this.gridApi && this.gridApi.stopEditing();
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage={``}
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                deleteFunc = props.deleteUOM;
                break;
            }
            case MODAL_TYPE.NEW_TAX: {
                deleteFunc = props.deleteTax;
                break;
            }
            case MODAL_TYPE.EXPENSE_TYPE: {
                deleteFunc = props.deleteExpenseTypes;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }

    render() {
        //const { txColumnSetting } = this.props;
        const self = this;
        const { sectionVisible, itemLevelTaxes } = this.state;
        const isPlaceOfSupplySameAsRelationship = (this.props.relationshipBillingAddress || {}).stateName === this.props.placeOfSupply;
        const onGridReady = (params) => {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            onDataRendered(params);
        }

        const _isColumnToHide = (columnName) => {
            // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
            //     return true;
            // }
            return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
        }

        const content = <AddAndRemoveColumn {...this.props} />
        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return "#";
                },
                cellRendererFramework: (params) => {
                    return params.node.rowIndex + 1
                },
                field: "sno",
                rowDrag: true,
                minWidth: 70,
                maxWidth: 70,
                suppressMovable: true,
                lockPosition: true,
                colId: "sno_1",
                sortable: true,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.debitMemo.form.gridHeader.product' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
                colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
                resizable: true,
                minWidth: 120,
                suppressMovable: true,
                editable: true,
                sortable: true,
                permissions: this.props.permissions,
                moduleName: MODULE_CODED_VALUES.PRODUCTS,
                operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                hide: _isColumnToHide(AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD),
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight >= (params.node.data.maxHeight || 30)) {
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        } else {
                            params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.product)
                },
                cellEditor: 'customTreeDropDown', suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        options: this.props.products,
                        onSearch: this.props.onSearch,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.PRODUCTS,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        onAddNewClick: () => {
                            self.props.updateHeaderState({
                                inventory: {
                                    inventoryDrawerVisible: true
                                }
                            });
                        },
                        canAddNew: true,
                        value: obj.node.data.selectedProductValue || [],
                        selectedLabel: obj.node.data.product || "",
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.selectedProductValue = obj.node.selectedProductValue;
                            obj.node.data.productObj = null;
                            obj.node.data.variant = null;
                            const itemId = obj.node.data.selectedProductValue && obj.node.data.selectedProductValue[0];
                            if (itemId) {
                                const variantId = obj.data.selectedProductValue && obj.data.selectedProductValue.length === 2 ? obj.data.selectedProductValue[1] : 0;
                                obj.node.data.productObj = find(this.props.products, { itemId: Number(itemId) }) || {};
                                obj.node.data.variant = variantId ? find(obj.node.data.productObj.inventoryItemVariantList, { variantId: Number(variantId) }) : obj.node.data.productObj.inventoryItemVariantList[0];
                                if (obj.node.data.productObj.shortDescription) {
                                    obj.node.data.description = obj.node.data.productObj.shortDescription;
                                }
                            }
                            if (obj.node.data.variant && !this.props.isDataToUpdate) {
                                const rate = Number(obj.node.data.variant.purchasedPrice || 0).toFixed(2);
                                obj.node.data.baseRate = Number(rate);
                                obj.node.data.rate = Number(rate);
                                //obj.node.setDataValue('specialDiscount', 0);
                                //obj.node.setDataValue('baseRate', Number(rate));
                                //obj.node.setDataValue('rate', Number(rate));
                                //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                                obj.node.data.uom = obj.node.data.variant.uomName;
                                if (obj.node.data.productObj.isPopulateTax && !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)) {
                                    const defaultItemHsnAndTax = obj.node.data.productObj.inventoryItemDefaultTaxList[0] || {};
                                    obj.node.data.hsnCode = ((defaultItemHsnAndTax || {}).hsnCode);
                                    let selectedTax = {};
                                    if (defaultItemHsnAndTax.taxSingleRateId) {
                                        selectedTax = find(this.props.taxes, { taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId }) || {};
                                    } else {
                                        selectedTax = find(this.props.taxes, { taxPercent: ((defaultItemHsnAndTax || {}).igstRate || (defaultItemHsnAndTax || {}).cgstRate || (defaultItemHsnAndTax || {}).globleTaxRate) }) || {};
                                    }
                                    obj.node.data.taxName = selectedTax.taxName;
                                    obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                                    obj.node.data.taxId = selectedTax.taxSingleRateId;
                                    obj.node.data.tax = selectedTax.taxPercent;
                                }
                            }
                            let isDuplicateItem = false;
                            this.gridApi && this.gridApi.forEachNode((rowNode, index) => {
                                const { data } = rowNode;
                                if (data.product === selectedValue && index !== obj.node.rowIndex) {
                                    isDuplicateItem = true;
                                }
                            });
                            if (isDuplicateItem) {
                                showToasterMessage({
                                    messageType: 'warning', description: 'Duplicate product is selected'
                                });
                            }

                            obj.node.data.isDuplicateItem = isDuplicateItem;
                            setTimeout(() => {
                                this.gridApi && this.gridApi.stopEditing();
                              }, 100);
                        }
                    }
                },
                cellClassRules: {
                    'ag-grid-cell-warning-boundary': function (obj) {
                        let isDuplicateItem = false;
                        self.gridApi && self.gridApi.forEachNode((rowNode, index) => {
                            const { data } = rowNode;
                            if (data.product && data.product === obj.node.data.product && obj.node.rowIndex !== index) {
                                isDuplicateItem = true;
                            }
                        });
                        return isDuplicateItem;
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.debitMemo.form.gridHeader.description' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
                cellClass: " ag-cell-description",
                colId: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD + "_1",
                resizable: true,
                editable: true,
                sortable: true,
                minWidth: 120,
                suppressMovable: true,
                hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0,
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.description || "",
                        onChange: (value) => {
                            params.node.data[params.colDef.field] = value;

                        }
                    }
                },
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        }
                    }, 200)
                    return ReactHtmlParser(params.data.description)
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > (params.data.minHeight || 30)) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${(params.node.data.minHeight || 30)}px`;
                        }
                        params.node.setRowHeight((params.node.data.minHeight || 30));
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.debitMemo.form.gridHeader.qty' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
                colId: AG_GRID_CONSTANTS.COMMON.QTY_FIELD + "_1",
                hide: (this.props.txColumns.indexOf(AG_GRID_CONSTANTS.COMMON.QTY_FIELD) < 0 && this.props.txMandatoryColumns.indexOf(AG_GRID_CONSTANTS.COMMON.QTY_FIELD) < 0),
                editable: true,
                minWidth: 110,
                maxWidth: 140,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                cellEditorParams: (obj) => {
                    return {
                      decimalDigitsLength: (this.props.companyInfo || {})
                        .decimalDigitsLengthQty,
                      onChange: (value) => {
                        obj.node.data.qty = value;
                        obj.api.refreshCells();
                      },
                    };
                  },
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.debitMemo.form.gridHeader.uom' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
                colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
                editable: true,
                minWidth: 120,
                maxWidth: 140,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.uomList,
                        optionKeyName: 'uomId',
                        valueKeyName: 'uomName',
                        canAddNew: true,
                        canDelete: true,
                        permissions: this.props.permissions,
                        moduleName: MODULE_CODED_VALUES.UOM,
                        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                textBoxValue: payload.textEntered,
                                submittedOnce: false,
                            }
                            this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM)
                        },
                        onSelect: (selectedValue) => {
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                        },
                        allowClear: true,
                        onClear: () => {
                            //obj.node.setDataValue(obj.colDef.field, undefined);
                        },
                    }
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.debitMemo.form.gridHeader.hsn' defaultMessage='' />;
                },
                field: 'hsnCode',
                colId: "hsnCode_1",
                minWidth: 120,
                maxWidth: 140,
                suppressMovable: true,
                resizable: true,
                editable: true,
                sortable: true,
                hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 || (this.props.companyInfo || {}).countryName !== 'India',
                cellEditor: 'customTextEditor',
                cellEditorParams: (obj) => {
                    return {
                        onChange: (value) => {
                            obj.node.data.hsnCode = value;
                            obj.api.refreshCells();
                        }
                    }
                },
            },
            // {
            //     headerComponentFramework: () => {
            //         return "MRP";
            //     },
            //     field: "baseRate"
            // },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.debitMemo.form.gridHeader.rate' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
                colId: AG_GRID_CONSTANTS.COMMON.RATE_FIELD + "_1",
                resizable: true,
                minWidth: 120,
                maxWidth: 140,
                suppressMovable: true,
                editable: true,
                sortable: true,
                cellEditor: 'customNumberEditor',
                cellEditorParams: (obj) => {
                    return {
                        decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
                        onChange: (value) => {
                            obj.node.data.baseRate = value;
                            obj.node.data.rate = value;
                            obj.api.refreshCells();

                        }
                    }
                },
                valueGetter: (params) => {
                    if (params.data.specialDiscount && params.data.rate && Number(params.data.rate) !== 0) {
                        params.data.rate = params.data.baseRate - ((params.data.specialDiscount * .01) * params.data.baseRate);
                    }
                    if (!params.data.specialDiscount && params.data.baseRate) {
                        params.data.rate = params.data.baseRate;
                    }
                    return params.data.rate && Number(params.data.rate || 0).toFixed((this.props.companyInfo || {}).decimalDigitsLengthPrice || 0);
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            // {
            //     headerComponentFramework: () => {
            //         return <FormattedMessage id='supplier.debitMemo.form.gridHeader.specialDiscount' defaultMessage='' />;
            //     },
            //     field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
            //     editable: true,
            //     sortable: true,
            //     hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0,
            //     cellEditor: 'customNumberEditor',
            //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            // },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.debitMemo.form.gridHeader.discount' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
                colId: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD + "_1",
                editable: true,
                minWidth: 120,
                maxWidth: 140,
                suppressMovable: true,
                resizable: true,
                sortable: true,
                hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0,
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                cellEditorParams: (obj) => {
                    return { 
                        decimalDigitsLength: (this.props.companyInfo || {}).decimalDigitsLengthPrice,
                        maxValue: 100,
                        onChange: (value) => {
                            obj.node.data.discount = value;
                            obj.api.refreshCells();
                        }
                    }
                  }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.tax' defaultMessage='' />;
                },
                field: 'taxApplied',
                colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
                resizable: true,
                minWidth: 120,
                suppressMovable: true,
                editable: true,
                sortable: true,
                hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: true,
                        items: this.props.taxes,
                        optionKeyName: 'taxSingleRateId',
                        canAddNew: true,
                        canDelete: true,
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                taxName: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewTaxAddition(this.props, payload, MODAL_TYPE.NEW_TAX)
                        },
                        valueKeyName: 'taxNameDisplay',
                        onSelect: (selectedValue, option) => {
                            const selectedTax = find(this.props.taxes, { taxSingleRateId: Number(option.key) });
                            obj.node.data.tax = selectedTax.taxPercent;
                            obj.node.data.taxName = selectedTax.taxName;
                            //obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.taxId = selectedTax.taxSingleRateId;
                        },
                        allowClear: true,
                        onClear: () => {
                            obj.node.data.tax = null;
                            obj.node.data.taxId = null;
                            obj.node.data.taxApplied = null;
                            obj.node.data.taxName = null;
                            this.calculateSubtotal();
                        },
                    }
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                  return (
                   "Taxable Amount"
                  );
                },
                field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX,
                colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD_WITHOUTTAX + "_1",
                resizable: true,
                // pinned: "right",
                // lockPosition: true,
                suppressNavigable: true,
                valueGetter: (params) => {
                    let totalAmountOfColumn = 0
                    if (params.data.qty && params.data.rate) {
                        totalAmountOfColumn = params.data.qty * params.data.rate;
                        if (params.data.discount) {
                            let discountAmount = ((params.data.discount * 0.01) * totalAmountOfColumn);
                            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                        }
                       
                        this.calculateSubtotal();
                    }
                    return totalAmountOfColumn ? fixedDecimalAndFormateNumber(totalAmountOfColumn) : "";
                },
                // cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
              },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='supplier.debitMemo.form.gridHeader.amount' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
                colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
                minWidth: 120,
                suppressMovable: true,
                resizable: true,
                pinned: 'right',
                lockPosition: true,
                suppressNavigable: true,
                valueGetter: (params) => {
                    let totalAmountOfColumn = 0
                    if (params.data.qty && params.data.rate) {
                        totalAmountOfColumn = params.data.qty * params.data.rate;
                        if (params.data.discount) {
                            let discountAmount = ((params.data.discount * 0.01) * totalAmountOfColumn);
                            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                        }
                        if (params.data.tax) {
                            totalAmountOfColumn = ((params.data.tax * 0.01) * totalAmountOfColumn) + totalAmountOfColumn;
                        }
                        this.calculateSubtotal();
                    }
                    return totalAmountOfColumn ? fixedDecimalAndFormateNumber(totalAmountOfColumn) : "";
                },
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN

            },
            {
                headerComponentFramework: () => {
                    return <div className="cursor-pointer">
                        <Popover
                            content={content}
                            title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /> </div>}
                            placement="left"
                            trigger="click"
                        >
                            <i className='fa fa-bars' />
                        </Popover>
                    </div>;
                },
                minWidth: 50,
                maxWidth: 50,
                suppressMovable: true,
                lockPosition: true,
                field: "action",
                colId: "action_1",
                pinned: 'right',
                cellRenderer:
                    'customActionEditor',
                suppressNavigable: true,
                cellRendererParams: (params) => {
                    return {
                        onClick: () => {
                            this.gridApi.updateRowData({ remove: [this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data] });
                            if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                            this.calculateSubtotal();
                        },
                    }
                }
            }
        ];

        const createInvoice = (isDraft) => {
            // let isRequiredFiledMissing = false;
            // this.gridApi.forEachNode((rowNode, index) => {
            //     let { data } = rowNode;
            //     if (index === 0 && ((!data.product && !data.description) || !(Number(data.rate || 0 ))|| !(Number(data.qty || 0)))) {
            //         isRequiredFiledMissing = true;
            //     } else if (!data.product && !(Number(data.rate || 0)) && !(Number(data.qty || 0))) {
            //         data = {};
            //     } else if ((!data.product && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0))) {
            //         isRequiredFiledMissing = true;
            //     }
            // });

            // if ((isRequiredFiledMissing && !isDraft) || !(this.props.supplier && this.props.supplier.supplierId)) {
            //     return showToasterMessage({
            //         messageType: 'error', description: this.props.intl.formatMessage({id:'description.Please.select.Supplier'})
            //     });
            // }

            // let isRequiredFieldMissing = false;
            let errorKey = '';

            if(!(this.props.supplier && this.props.supplier.supplierId)){
                return showToasterMessage({
                  messageType: 'error',
                  description: this.props.intl.formatMessage({id : 'missingSupplier.errorMsg', defaultMessage : ''})
                });
            }
            
            this.gridApi.forEachNode((rowNode, index) => {
                let { data } = rowNode;
                if(index === 0 && (!data.product && !data.description)){
                    errorKey = 'missingProduct.errorMsg';
                  }else if(index === 0 && !(Number(data.qty || 0))){
                    errorKey = 'missingQty.errorMsg';
                  }else if(index === 0 && !(Number(data.rate || 0))){
                    errorKey = 'missingRate.errorMsg';
                  }
            });

            if (errorKey && !isDraft) {
                return showToasterMessage({
                    messageType: 'error', description: this.props.intl.formatMessage({ id: errorKey, defaultMessage: '' })
                });
            }

            const modalData = {
                modalBody: <FormattedMessage
                    id='supplier.debitMemo.form.confirmation'
                    defaultMessage={``}
                />,
                handleSubmit: () => {
                    const payload = {
                        props: this.props,
                        gridObject: this.gridApi,
                        isPlaceOfSupplySameAsRelationship: isPlaceOfSupplySameAsRelationship,
                        state: this.state,
                        isDraft: isDraft
                    }
                    if (isDraft) {
                        this.props.createDebitMemoDraft(payload);
                    } else {
                        this.props.createDebitMemo(payload);
                    }
                    this.props.hideModal();
                }
            };
            this.props.showModal(modalData);
        }

        const saveColumnIndex = (columnState) => {
            let data = this.props.txPreferences || {};
            let gridPreference = {};
            data.relationshipId = (this.props.companyInfo || {}).relationshipId;
            data.clientUserAccountId = (this.props.userInfo || {}).userId;
            if (data.tablePreference) {
                gridPreference = JSON.parse(data.tablePreference);
            }
            gridPreference.columnSetting = columnState;
            gridPreference.columnList = this.props.txColumns;
            data.gridPreference = JSON.stringify(gridPreference);
            data.preferenceId = this.props.preferenceId;
            data.tablePreference = this.props.tablePreference;
            this.props.saveUserPrefrences(data);
        }

        const saveColumnWidth = (columnState, width) => {
            let data = this.props.txPreferences || {};
            let gridPreference = {};
            data.relationshipId = (this.props.companyInfo || {}).relationshipId;
            data.clientUserAccountId = (this.props.userInfo || {}).userId;
            if (data.gridPreference) {
                gridPreference = JSON.parse(data.gridPreference);
            }
            gridPreference.columnSetting = columnState;
            gridPreference.columnList = this.props.txColumns;
            data.gridPreference = JSON.stringify(gridPreference);
            data.preferenceId = this.props.preferenceId;
            data.tablePreference = this.props.tablePreference;
            this.props.saveUserPrefrences(data, this.props);
        }

        let moveEvent = {};
        const onColumnMoved = (event, isDragStopped) => {
            if (event.column) {
                moveEvent = event;
            }
            if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
                saveColumnIndex(moveEvent.columnApi.getColumnState());
            }
        }

        const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
            const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
            //const gridApi = tablegridApi || this.gridApi;
            event.api.resetRowHeights();
            if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
                //event.api.resetRowHeights();
                if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
                    this.gridApi.sizeColumnsToFit();
                    // const columnArray = event.columnApi.getColumnState();
                    // for (let i = (columnArray.length - 1); i >= 0; i--) {
                    //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
                    //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
                    //         break;
                    //     }
                    // }
                    // saveColumnWidth(columnArray);
                    setTimeout(() => {
                        const columnArray = event.columnApi.getColumnState();
                        saveColumnWidth(columnArray);
                    }, 1000);
                } else {
                    saveColumnWidth(event.columnApi.getColumnState());
                }
            }
        }

        const onModelUpdated = (params) => {
            if (this.props.tableDataReRendered) {
                setTimeout(() => {
                    this.calculateSubtotal();
                }, 1500)
               // params.api.resetRowHeights();
                this.props.updateState({
                    tableDataReRendered: false
                })
                let actionIndex = 0;
                let amountIndex = 0;
                let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
                const columnToBeSet = [];
                const indexObjArr = TRANSACTION_COLUMN_INDEX.DEBIT_MEMO_FORM

                indexObjArr.forEach((e) => {
                    let colObj = find(txColumnSetting, { colId: e + '_1' });
                    if (colObj) {
                        columnToBeSet.push(colObj);
                    }
                });

                txColumnSetting = txColumnSetting.filter((ele) => {
                    let columnName = ele.colId.split("_")[0];
                    return indexObjArr.indexOf(columnName) === -1;
                })
                txColumnSetting = columnToBeSet.concat(txColumnSetting);
                txColumnSetting.forEach((e, index) => {
                    let columnName = e.colId.split("_")[0];
                    if (columnName !== 'action' && columnName !== 'amount') {
                        if (columnName === 'isChecked' || columnName === 'sno') {
                            e.pinned = 'left';
                            e.lockPosition = true;
                            e.hide = false;
                        } else {
                            e.hide = _isColumnToHide(columnName);
                        }
                    }
                    if (columnName === 'amount') {
                        e.hide = false;
                        amountIndex = index;
                        e.lockPosition = true;
                        e.pinned = 'right';
                    }
                    if (columnName === 'action') {
                        actionIndex = index;
                        e.width = 52;
                        e.hide = false;
                        e.pinned = 'right';
                    }
                });
                const actionObj = txColumnSetting[actionIndex];
                const amountObj = txColumnSetting[amountIndex];
                txColumnSetting = txColumnSetting.filter((e) => {
                    let columnName = e.colId.split("_")[0];
                    return columnName !== 'action' && columnName !== 'amount';
                });
                amountObj.lockPosition = true;
                actionObj.lockPosition = true;
                txColumnSetting.push(amountObj);
                txColumnSetting.push(actionObj);
                params.columnApi.setColumnState(txColumnSetting);
                setTimeout(() => {
                    if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                        params.api.sizeColumnsToFit();
                    }
                }, 500);

            }
        }

        const onDataRendered = (params) => {
            let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
            let actionIndex = 0;
            let amountIndex = 0;
            if (Object.keys(txColumnSetting).length) {
                const columnToBeSet = [];
                const indexObjArr = TRANSACTION_COLUMN_INDEX.DEBIT_MEMO_FORM

                indexObjArr.forEach((e) => {
                    let colObj = find(txColumnSetting, { colId: e + '_1' });
                    if (colObj) {
                        columnToBeSet.push(colObj);
                    }
                });

                txColumnSetting = txColumnSetting.filter((ele) => {
                    let columnName = ele.colId.split("_")[0];
                    return indexObjArr.indexOf(columnName) === -1;
                })
                txColumnSetting = columnToBeSet.concat(txColumnSetting);
                txColumnSetting.forEach((e, index) => {
                    let columnName = e.colId.split("_")[0];
                    if (columnName !== 'action' && columnName !== 'amount') {
                        if (columnName === 'isChecked' || columnName === 'sno') {
                            e.pinned = 'left';
                            e.hide = false;
                        } else {
                            e.hide = _isColumnToHide(columnName);
                        }
                    }
                    if (columnName === 'action') {
                        e.width = 52;
                        actionIndex = index;
                        e.hide = false;
                        e.pinned = 'right';
                    }
                    if (columnName === 'amount') {
                        e.hide = false;
                        e.lockPosition = true;
                        amountIndex = index;
                        e.pinned = 'right';
                    }

                });
                const actionObj = txColumnSetting[actionIndex];
                const amountObj = txColumnSetting[amountIndex];
                txColumnSetting = txColumnSetting.filter((e) => {
                    let columnName = e.colId.split("_")[0];
                    return columnName !== 'action' && columnName !== 'amount';
                });
                amountObj.lockPosition = true;
                actionObj.lockPosition = true;
                txColumnSetting.push(amountObj);
                txColumnSetting.push(actionObj);
                this.gridColumnApi.setColumnState(txColumnSetting);
                setTimeout(() => {
                    if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                        params.api.sizeColumnsToFit();
                    }
                }, 500);
            } else {
                this.gridApi.sizeColumnsToFit();
            }
        }


        return (
            <Fragment>
                <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 7 }}>

                <div className="transaction-table">
                    {this.props.txColumns.length ? <CustomAgGrid
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        onModelUpdated={onModelUpdated}
                        onColumnResized={onColumnResized}
                        onDragStopped={() => {
                            onColumnMoved({}, true);
                        }}
                        //onColumnMoved={onColumnMoved}
                        rowData={this.props.supplierDebitMemoDetailsList || [{}]}
                        gridStyle={{
                            width: '100%',
                            height: '100%',
                            marginBottom: '10px'
                        }} /> : null}
                </div>

                   <div style={{display:'flex', gap:'10px'}}>

                        <Button className="grey-button" onClick={
                            () => {
                                this.gridApi.updateRowData({ add: [{}] });
                            }
                        }>{<FormattedMessage id='supplier.debitMemo.form.button.addLine' defaultMessage='' />}</Button>
                        <Button className="grey-button" onClick={
                            () => {
                                this.props.showModal({
                                    modalBody: "Are you sure you want to clear?",
                                    handleSubmit: () => {
                                        let gridApi = this.gridApi;
                                        let self = this;
                                        this.gridApi.forEachNode(function (rowNode, index) {
                                            gridApi.updateRowData({ remove: [rowNode.data] });
                                            if (gridApi.rowRenderer.lastRenderedRow < 0) {
                                                gridApi.updateRowData({ add: [{}] });
                                            }
                                            self.calculateSubtotal();
                                        })
                                    }
                                })
                            }
                        }><FormattedMessage id='supplier.debitMemo.form.button.clearAllLines' defaultMessage='' /></Button>
                   </div>
                   <div className="upload-btn-so">

                        <CustomTableUpload fileList={this.props.fileList} onChange={
                            (fileList) => {
                                this.props.updateState({
                                    fileList
                                })
                            }
                        } />
                   </div>
                   {this.props.debitMemoEditData?.docDetailList?.length ?
                    <>


                        Previous Data

                        <AWSFileTable
                        {...this.props}
                        docDetailList={this.props.debitMemoEditData.docDetailList}
                        fetchTxDetail={(data) => {
                            const val = this.props.debitMemoEditData
                            val.docDetailList = []
                            this.props.updateState({
                                debitMemoEditData: val
                            })
                          this.props.fetchInboundryDetailDetail({
                            supplierId: this.props.debitMemoEditData.supplierId,
                            inboundDeliveryMasterId:
                              this.props.debitMemoEditData.inboundDeliveryMasterId,
                            relationshipId: this.props.debitMemoEditData.relationshipId,
                            poMasterId: this.props.debitMemoEditData.poMasterId,
                          });
                        }}
                      />
                    </>
                    : ""}

                  <Row>
                    <Col span={12}>
                        <div>
                            <div className="remarks-title">
                                <FormattedMessage id='supplier.debitMemo.form.internalRemarks.label' defaultMessage='' />
                            </div>
                            <CKEditor
                                type="inline"
                                className="description-textarea"
                                key={`${"internalRemarks"}`}
                                data={this.props.internalRemarks}
                                onInit={editor => {
                                    this.editor = editor;
                                }}
                                onChange={(event) => {
                                    const data = event.editor.getData();
                                    this.props.updateState({ internalRemarks: data });
                                }}
                                config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter internal remarks' }}
                            />

                        </div>
                        <div className="remarks-title">
                            <FormattedMessage id='supplier.debitMemo.form.supplierRemarks.label' defaultMessage='' />
                        </div>

                        {/* <TextArea value={this.props.supplierRemarks} maxLength={500} className="description-textarea" rows={4} onChange={(e) => {
                            this.props.updateState({
                                supplierRemarks: e.target.value
                            });
                        }} placeholder={intl.formatMessage(
                            { id: 'supplier.debitMemo.form.supplierRemarks.placeholder', defaultMessage: '' }
                        )}></TextArea> */}
                        {<CKEditor
                            type="inline"
                            className="description-textarea"
                            key={`${"supplierRemarks"}`}
                            data={this.props.supplierRemarks}
                            onInit={editor => {
                                this.editor = editor;
                            }}
                            onChange={(event) => {
                                const data = event.editor.getData();
                                this.props.updateState({ supplierRemarks: data });
                            }}
                            config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter supplier remarks' }}
                        />}
                    </Col>

                    <Col span={11}>
                        {/* <div className="checkbox-control-group">
                            {<Checkbox onChange={() => {
                                this.setState({ sectionVisible: { ...sectionVisible, discount: !sectionVisible.discount } })
                            }}> <FormattedMessage id='supplier.debitMemo.form.checkbox.discount' defaultMessage='' /></Checkbox>}
                            {false && <Checkbox onChange={() => {
                                this.setState({ sectionVisible: { ...sectionVisible, tax: !sectionVisible.tax } })
                            }}><FormattedMessage id='supplier.debitMemo.form.checkbox.tax' defaultMessage='' /></Checkbox>}
                            <Checkbox onChange={() => {
                                this.setState({ sectionVisible: { ...sectionVisible, expense: !sectionVisible.expense } })
                            }}><FormattedMessage id='supplier.debitMemo.form.checkbox.expense' defaultMessage='' /></Checkbox>
                        </div> */}

                        <div className="product-amount-details">
                            <Row type="flex" justify="end" className="mb20">
                                {
                                    sectionVisible.discount
                                        ?
                                        <Fragment>
                                            <Col span={8}>
                                                <span className="label"><FormattedMessage id='supplier.debitMemo.form.totalDiscount.label' defaultMessage='' /></span>
                                            </Col>

                                            <Col span={16}>
                                                <div className="input-group">
                                                    <div className="input-label-control">
                                                        <span className="mark">%</span>
                                                        <Input
                                                            type='number'
                                                            placeholder="0.00"
                                                            value={this.state.rootDiscountPercent || ""}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    rootDiscountPercent: e.target.value
                                                                });
                                                            }}
                                                            onBlur={(e) => {
                                                                if (this.state.subtotal) {
                                                                    this.calculateSubtotal();
                                                                }
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                    <div className="input-label-control">
                                                        <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                        <Input
                                                            disabled
                                                            placeholder="0.00"
                                                            type='number'
                                                            value={this.state.rootDiscountAmount || ""}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    rootDiscountAmount: Number(e.target.value)
                                                                });
                                                            }}
                                                            onBlur={(e) => {
                                                                if (this.state.subtotal) {
                                                                    this.calculateSubtotal();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Fragment>
                                        : ''
                                }
                            </Row>

                            {
                                sectionVisible.tax
                                    ? <Fragment>
                                        <Row type="flex" justify="end">
                                            <Col span={8}>
                                                <FormattedMessage id='supplier.debitMemo.form.totalTax.label' defaultMessage='' />
                                            </Col>

                                            <Col span={16}>
                                                <Dropdown
                                                    items={this.props.taxes}
                                                    value={this.props.taxName}
                                                    onSelect={(selectedTax) => {
                                                        this.props.updateState({
                                                            taxName: selectedTax
                                                        })
                                                    }}
                                                    valueKeyName='description'
                                                    optionKeyName='taxSingleRateId'
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({
                                                            taxName: undefined,
                                                        });
                                                        this.calculateSubtotal();
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row type="flex" justify="end">
                                            <Col span={8}>
                                                <span className="label"><FormattedMessage id='supplier.debitMemo.form.totalTax.label' defaultMessage='' /></span>
                                            </Col>

                                            <Col span={16}>
                                                <div className="input-group">
                                                    <div className="input-label-control">
                                                        <span className="mark">%</span>
                                                        <Input placeholder="0.00" />
                                                    </div>
                                                    <div className="input-label-control">
                                                        <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                        <Input placeholder="0.00" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    : ''
                            }

                            {
                                sectionVisible.expense
                                    ? <Fragment>
                                        <Row type="flex" className='amount-summary mb20' justify="end">
                                            <Col span={8}>
                                                <FormattedMessage id='supplier.debitMemo.form.totalExpense.label' defaultMessage='' />
                                            </Col>

                                            <Col span={16}>
                                                <Dropdown
                                                    style={{ width: '100%' }}
                                                    items={this.props.expenseTypes}
                                                    valueKeyName='expenseType'
                                                    value={this.props.expenseId}
                                                    canAddNew={true}
                                                    canDelete={true}
                                                    deleteClickHandler={(payload) => {
                                                        this.deleteClickHandler(this.props, MODAL_TYPE.EXPENSE_TYPE, payload);
                                                    }}
                                                    onAddNewClick={(payload) => {
                                                        payload.formData = {
                                                            textBoxValue: payload.textEntered,
                                                            submittedOnce: false
                                                        }
                                                        this.handleNewItemAddition(this.props, payload, MODAL_TYPE.EXPENSE_TYPE)
                                                    }}
                                                    onSelect={(expenseId) => {
                                                        const selectedExpense = find(this.props.expenseTypes, { lkExpenseTypeId: Number(expenseId) })
                                                        this.props.updateState({
                                                            expenseId,
                                                            expensName: selectedExpense.expenseType

                                                        })
                                                    }}
                                                    optionKeyName='lkExpenseTypeId'
                                                    allowClear={true}
                                                    onClear={() => {
                                                        this.props.updateState({
                                                            expenseId: undefined,
                                                            expensName: undefined,
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row type="flex" justify="end" className="mb20">
                                            <Col span={8}>
                                                <span className="label"><FormattedMessage id='supplier.debitMemo.form.totalExpenseAmount.label' defaultMessage='' /> </span>
                                            </Col>

                                            <Col span={16}>
                                                <div className="input-group">
                                                    <div className="input-label-control">
                                                        <span className="mark">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                                        <Input
                                                            style={{ width: 'calc(100% + 8px)' }}
                                                            placeholder="0.00"
                                                            type='number'
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    expenseAmount: Number(e.target.value)
                                                                }, () => {
                                                                    if (this.state.subtotal) {
                                                                        this.calculateSubtotal();
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                    : ''
                            }

                            <div className="amount-summary">
                                <Row>
                                    <Col span={8}>
                                        <span className="title"><FormattedMessage id='supplier.debitMemo.form.subTotal.label' defaultMessage='' /></span>
                                    </Col>
                                    <Col span={16}>
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {fixedDecimalAndFormateNumber(this.state.subtotalWithoutDiscount)}
                                    </Col>
                                </Row>
                            </div>

                            {this.state.totalDiscount === '0.00' || this.state.totalDiscount === 0 ? null : <div className="amount-summary">
                                <Row>
                                    <Col span={8}>
                                        <span className="title"><FormattedMessage id='supplier.debitMemo.form.totalDiscount.label' defaultMessage='' /></span>
                                    </Col>
                                    <Col span={16}>
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {fixedDecimalAndFormateNumber(this.state.totalDiscount)}
                                    </Col>
                                </Row>
                            </div>}

                            {/* {
                                false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                                    <div className="w-50">
                                        <span className="title">Tax</span>
                                    </div>
                                    <div className="w-50-default">
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {this.state.totalTax}
                                    </div>
                                </div>
                            } */}

                            {
                                itemLevelTaxes.length === 0 ? null : itemLevelTaxes.map((taxObj, i) => {
                                    if (isPlaceOfSupplySameAsRelationship) {

                                    }
                                    return (
                                        (this.props.companyInfo || {}).countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                            <div className="amount-summary">
                                                <Row>
                                                    <Col span={8}>
                                                        <span
                                                            className="title"> <FormattedMessage id='common.CGST' defaultMessage='' />{taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                    </Col>
                                                    <Col span={16}>
                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                        {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="amount-summary">
                                                <Row>
                                                    <Col span={8}>
                                                        <span
                                                            className="title"><FormattedMessage id='common.SGST' defaultMessage='' /> {taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                    </Col>
                                                    <Col span={16}>
                                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                        {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div> : <div key={i}>
                                                <div className="amount-summary">
                                                    <Row>
                                                        <Col span={8}>
                                                            <span
                                                                className="title"><FormattedMessage id='common.IGST' defaultMessage='' />  {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                        </Col>
                                                        <Col span={16}>
                                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                            {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>) :
                                            <div key={i}>
                                                <div className="amount-summary">
                                                    <Row>
                                                        <Col span={8}>
                                                            <span
                                                                className="title">{taxObj.taxName} @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                        </Col>
                                                        <Col span={16}>
                                                            {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                            {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                    )
                                })
                            }

                            <div className="amount-summary">
                                <Row>
                                    <Col span={8}>
                                        <span className="title"><FormattedMessage id='supplier.debitMemo.form.totalAmount.label' defaultMessage='' /></span>
                                    </Col>
                                    <Col span={16}>
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {fixedDecimalAndFormateNumber(this.state.total)}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* <TextArea value={this.props.footer} maxLength={500} className="add-footer-textarea" onChange={(e) => {
                    this.props.updateState({
                        footer: e.target.value
                    });
                }}
                    rows={1} placeholder="Add Footer Here"></TextArea> */}
                <CKEditor
                    type="inline"
                    className="add-footer-textarea"
                    key={`${"footer"}`}
                    data={this.props.footer}
                    onInit={editor => {
                        this.editor = editor;
                    }}
                    onChange={(event) => {
                        const data = event.editor.getData();
                        this.props.updateState({ footer: data });
                    }}
                    config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Add Footer Here' }}
                />
                <div className="pb40"></div>
                <div className="footer">
                    <div className="left-fItems">
                    <Button className="grey-button" 
                     onClick={() => {
                        this.props.showModal({
                          modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                          footer:{submit:{intlId:"button.close.label"}},
                          handleSubmit: () => {
                            this.props.popModalFromStack();
                          }
                        })
                      }}
                    ><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                   
                       </div>
                    {/* <Col span={2}>
                        <Button className="ant-btn-primary"
                            onClick={() => {
                                createInvoice(true);
                            }}
                        ><FormattedMessage id='button.draft.label' defaultMessage='' /></Button>
                    </Col> */}
                    <div className="right-fItems">
                    <Button className="ant-btn-primary"
                    loading={this.props.saveLoading}
                    disabled={this.props.saveLoading}
                    onClick={() => {
                            createInvoice();
                        }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                    
                        </div>
                </div>
            </Skeleton>
            </Fragment>
        )
    }
};

export default injectIntl(ProductDetails);
