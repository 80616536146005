import React from "react";
// import { AgGridReact } from 'ag-grid-react';
import { Tooltip, Pagination, Skeleton } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";

import PODetail from "../../../../../containers/modal/modalBody/supplier/PurchaseOrderDetail";
import { CONSTANTS } from "../../../../../static/constants";
// import { AG_GRID_CLASS_CONSTANTS } from "../../../../../static/cssClassConstants";
import {
  fetchPaginationDataIfNeeded,
  fixedDecimalNumber,
  getMomentDateForUIReadOnly,
} from "../../../../../utils";

const OpenOrdersComponent = (props) => {
 
  const {
    openOrderList,
    openOrderListCount,
    companyInfo,
    updateState,
    pageNumber,
  } = props;

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // // const getRowHeight = () => {
  // //     return 28;
  // // }

  // const onFirstDataRendered = (params) => {
  //     params.api.sizeColumnsToFit();
  // }

  const openSoDetails = (data) => {
    const poPayload = {
      supplierId: data.supplierId,
      poMasterId: data.poMasterId,
    };
    props.pushModalToStack({
      modalBody: <PODetail {...props} poPayload={poPayload} />,
      width: "100%",
      hideFooter: true,
      wrapClassName: "modal-custom-detail",
    });
  };

  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.listing.openOrder.header.poNumber' defaultMessage='' />;
  //         },
  //         field: "poNumber",
  //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
  //             openSoDetails(link.data)
  //         }}>{link.value}</div>,
  //         rowDrag: true,
  //         resizable: true,
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='common.status' defaultMessage='' />;
  //         },
  //         field: "status",
  //         cellRendererFramework: (params) => {
  //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span> </div>
  //         },
  //         resizable: true,
  //     },
  //     {

  //         headerComponentFramework: () => {
  //             return <div><FormattedMessage id='supplier.listing.openOrder.header.poAmount' defaultMessage='' /> </div>;
  //         },
  //         field: "totalPOAmount",
  //         valueFormatter: (data) => {
  //             data.value = data.value ? (fixedDecimalNumber(data.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
  //             return data.value;
  //         },
  //         cellRendererFramework: (params) => {
  //             return <div> <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span> </div>
  //         },
  //         resizable: true,
  //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
  //     },
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='supplier.listing.openOrder.header.poDate' defaultMessage='' />;
  //         },
  //         field: "poDate",
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}>
  //                     {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
  //                 </Tooltip>
  //             </div>
  //         },
  //         resizable: true,
  //     }
  // ];

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
   
    props.fetchOpenOrders(payload,props)
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    props.resetOpenOrderPaginatedData();
    props.fetchOpenOrders(payload,props);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  return (
    // <div className='agGridWrapper'>
    //     <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
    //         <AgGridReact
    //             columnDefs={columnDefs}
    //             rowData={openOrderList[pageNumber] || []}
    //             rowDragManaged={true}
    //             domLayout={"autoHeight"}
    //             animateRows={true}
    //             onColumnResized ={(params)=>{
    //                 params.api.resetRowHeights();
    //             }}
    //             defaultColDef={{
    //                 flex:1,
    //                 autoHeight: true,
    //                 wrapText: true,
    //             }}
    //             //getRowHeight={getRowHeight}
    //             onGridSizeChanged={onFirstDataRendered}
    //         >
    //         </AgGridReact>

    //         </div>
    // </div>
    <>
      
      <Skeleton loading={props.modalLoading} active paragraph={{ rows: 9 }}>
        <div className="table-container" style={{height: '500px'}}>
          <table id="supplier-table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage
                    id="supplier.listing.openOrder.header.poNumber"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="supplier.listing.openOrder.header.poAmount"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="supplier.listing.openOrder.header.poDate"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage id="common.status" defaultMessage="" />
                </th>
              </tr>
            </thead>
            <tbody>
              {(openOrderList[pageNumber] || []).length
                ? (openOrderList[pageNumber] || []).map((rowData, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              openSoDetails(rowData);
                            }}
                          >
                            {rowData.poNumber}
                          </div>
                        </td>

                        <td>
                          {props.companyInfo.currencyIcon ? (
                            <i className={props.companyInfo.currencyIcon}></i>
                          ) : (
                            props.companyInfo.currencyCode + " "
                          )}
                          {fixedDecimalNumber(rowData.totalPOAmount)}
                        </td>
                        <td>
                          {rowData.poDate &&
                            getMomentDateForUIReadOnly({
                              date: rowData.poDate,
                              format: CONSTANTS.DISPLAY_DATE_FORMAT,
                            })}
                        </td>
                        <td>
                          {rowData.status ? (
                            <span
                              className="status-code"
                              style={{ background: rowData.userStatusColor }}
                            >
                              <FormattedMessage id={rowData.status} defaultMessage="" />
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })
                : "No data"}
            </tbody>
          </table>
        </div>
        {/* {!props.supplierId && !props.supplierData?.supplierId? ( */}

        <Pagination
          size="small"
          total={openOrderListCount}
          showTotal={showTotal}
          defaultPageSize={props.pageSize}
          showSizeChanger
          pageSizeOptions={[10, 25, 50, 100, 200]}
          onChange={(pageNumber, pageSize) => {
            loadPageData(pageNumber, pageSize);
            updateState({ pageSize: pageSize, pageNumber: pageNumber });
          }}
          onShowSizeChange={(pageNumber, pageSize) => {
            handlePageSizeChange(pageNumber, pageSize, props);
          }}
        />
        {/* ) : (
          null 
        )} */}
      </Skeleton>
    </>
  );
};

export default injectIntl(OpenOrdersComponent);
