import React from 'react';
import { Row, Col, Button } from 'antd'
import { FormattedMessage } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';
import { CUSTOM_CONFIGURATION_FULL_PAGE } from '../../../../../static/ckEditorConfigConstants';
import CKEditor from '../../../../general/CustomCKEditor/ckeditor';
CUSTOM_CONFIGURATION_FULL_PAGE.wordcount.maxCharCount = 10000;
CUSTOM_CONFIGURATION_FULL_PAGE.wordcount.maxWordCount = 10000;

const CertificateOfConformity = (props) => {
    const { shipmentData, certificateData, companyInfo,} = props;

    // deleteClickHandler = (props, modalType, payload) => {
    //     const { showModal, companyInfo } = props;
    //     const modalBody =
    //       <FormattedMessage
    //         id='deleteItem.confirmation'
    //         defaultMessage=
    //         values={{ value: payload.text }}
    //       />;



    //   }
    const saveCertificateData = () => {
        const modalData = {
            modalBody: "Are you sure to update certificate content?",
            handleSubmit: () => {
                let payload = {
                    certificateOfConformity: certificateData,
                    relationshipId: companyInfo.relationshipId,
                    shipmentMasterId: shipmentData.shipmentMasterId
                }
                props.updateCertificateOfConformity(payload, props);
                props.updateState({ isShowCkEditor: false });
               // props.popModalFromStack();
            }
        }
        props.pushModalToStack(modalData);
    }



const generatePDF = () => {
    let payload = {
        fileName : 'Certificate of Conformity.pdf',
        transactionName: 'certificateOfConformity',
        certificateData: certificateData,
    }
    props.generatePDF(payload);
}
   return (
        <div className={"custom-modal show"}>
            <i className="close-icon">
                <svg width="17" height="17" onClick={() => { props.popModalFromStack(); }}>
                    <title>Close</title>
                    <path fill="#67757d" fillRule="evenodd"
                        d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                </svg>
            </i>
            <div className="modal-dialog">
                <div className="modal-content full-width">
                    <div className="modal-header">
                    <FormattedMessage id='modal.certificateOfConformity.title' defaultMessage='' />
                    </div>
                   <div className="inner-content">
                       <Row className="certificate-editor-container">
                           {props.isShowCkEditor ?
                               <Col span={24}>
                                   <CKEditor
                                       // editor={ClassicEditor}
                                       // config={FULL_CONFIGURATION}
                                       key={`${"certificateData"}`}
                                       data={certificateData}
                                       onChange={(event) => {
                                           const data = event.editor.getData();
                                           props.updateState({
                                            certificateData: data
                                           });
                                       }}
                                       config={CUSTOM_CONFIGURATION_FULL_PAGE}
                                   />
                               </Col> :
                               <Col span={24}>
                                   {ReactHtmlParser(certificateData || shipmentData.certificateOfConformity || '')}
                               </Col>
                           }
                       </Row>

                   </div>
                   {props.isShowCkEditor ?
                       <div className="button-group-action">
                           <Button type="primary" onClick={() => { saveCertificateData();}} > <FormattedMessage id='button.save.label' defaultMessage='' /></Button>
                           <Button type="primary" onClick={() => { props.updateState({ isShowCkEditor: false }); }}> <FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                           <Button onClick={() => { props.popModalFromStack(); }} type="default" > <FormattedMessage id='button.close.label' defaultMessage='' />  </Button>
                       </div> :
                       <div className="button-group-action">
                           <Button type="primary" onClick={() => { props.updateState({ isShowCkEditor: true }); }}> <FormattedMessage id='update' defaultMessage='' /></Button>
                           <Button type="primary" onClick={() => { generatePDF(); }} icon={<i className='fa fa-file-pdf-o'></i>}><FormattedMessage id='download' defaultMessage='' /> </Button>
                           <Button onClick={() => { props.popModalFromStack(); }} type="default" > <FormattedMessage id='button.close.label' defaultMessage='' />  </Button>
                       </div>
                   }
                </div>
            </div>
        </div>
    );
}

export default CertificateOfConformity;
