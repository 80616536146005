import React from "react";
import { Drawer, Row, Col, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
import {
  getMomentDateForUI,
  getCurrentDateForBackend,
  showToasterMessage,
} from "../../../../utils";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { Dropdown } from "../../../general/Dropdown";
import { CONSTANTS } from "../../../../static/constants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;

const PackageFilterComp = (props) => {
  const { updateState, companyInfo } = props;

  const closeDrawer = () => {
    props.updateState({
      packageFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
    // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
        if(!props.selectedCustomerId && !props.packageNum && !props.soNum && !props.startDate && !props.endDate && !props.selectedUserStatus && !props.selectedStatus && !props.refNum && !props.selectedQcName && !props.selectedProjectMasterId ){
            return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
        }
        const payload = {
            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
            filters: {
                customerId: props.selectedCustomerId || 0,
                packageNumber: props.packageNum || null,
                soNumber: props.soNum || null,
                startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                userStatus: props.selectedUserStatusName || null,
                status: props.selectedStatus || null,
                reference: props.refNum || null,
                qcId: props.selectedQcId || 0,
                qcName: props.selectedQcName || null,
                projectMasterId: props.selectedProjectMasterId || 0,
            }
        };
        props.fetchAllPackageByFilter(payload);
        props.getAllPackageCountByFilter(payload);
        props.updateState({
          packageFilterDrawerVisible: false,
        });
      };

  const clearFilter = () => {
    props.updateState({
        selectedCustomerId: null, selectedCustomerName: null,
        packageNum: null,
        soNum: null,
        startDate:null, endDate:null,
        selectedUserStatusName:null,
        selectedStatus:null, selectedStatusName: null,
        refNum:null,
        selectedQcName:null, selectedQcId: null,
        projectMasterId: null, selectedProjectName: null,
    });
    const payload = {
        companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
        filters: {}
    };
    props.fetchAllPackage(payload);
    props.getSOPackageCount(payload);
    props.updateState({
      packageFilterDrawerVisible: false,
    });
  };

  const rowGutter = [24, 16];

  return (
    <>
      <Drawer
        title={<div className="drawer-head-txt">Filters</div>}
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.packageFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
        <Col span={12}>
            <div className="i-label">Package #</div>
            <TextBox 
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                             
                            placeholder='Package #'
                            type='text'
                            countStyle={{ top: "43px"}}
                            maxLength={15}
                            value={props.packageNum}
                            onChange={(e) => {
                                props.updateState({ packageNum: e.target.value });
                            }}
                        />
          </Col>
          <Col span={12}>
            <div className="i-label">Customer</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}

              items={(props.customers[props.pageNumber] || []).sort((a, b) => (a.companyName||'').localeCompare(b.companyName||''))}
              valueKeyName="companyName"
              optionKeyName="customerId"
              value={props.selectedCustomerName}
              onSearch={(searchedText) => {
                props.fetchCustomers({
                  isCompact: true,
                  searchedText: searchedText,
                  pageNumber: 1,
                  hideSpinner: true,
                  pageSize: 100,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
              }}
              placeholder={props.intl.formatMessage({
                id: "customer.salesInvoice.form.customer.placeholder",
                defaultMessage: "",
              })}
              onSelect={(customerId, option) => {
                updateState({
                  selectedCustomerId: option.value,
                  selectedCustomerName: option.name,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedCustomerId: undefined,
                  selectedCustomerName: undefined,
                });
              }}
            />
          </Col>
          
        </Row>
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">SO #</div>
            <TextBox 
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                            
                            placeholder='SO #'
                            type='text'
                            maxLength={15}
                            countStyle={{ top: "43px"}}
                            value={props.soNum}
                            onChange={(e) => {
                                props.updateState({ soNum: e.target.value });
                            }}
                        />
          </Col>
          <Col span={12}>
            
            
          
                <div className="i-label">Package Date</div>

                <RangePicker
                            // style={{ marginTop: '-20px', width: '300px' }}
                     
                    allowEmpty={true}
                    format={format}
                    placeholder={['Start date', 'End date']}
                    value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                    onChange={(dates) => {
                        if (dates) {
                            props.updateState({ startDate: dates[0], endDate: dates[1] });
                        } else {
                            props.updateState({ startDate: null, endDate: null });
                        }
                    }}
                />
              </Col>
        
      
        </Row>
        <Row gutter={rowGutter}>
       
        {/* <Col span={12}>
            <div className="i-label">User Status</div>
            <Dropdown
                items={props.userStatus || []}
                valueKeyName='statusName'
                optionKeyName={'packageStatusId'}
                statusColorKey='statusColor'
                // style={{ marginTop: '-25px', width: '160px' }}
                
                value={props.selectedUserStatusName}
                placeholder={props.intl.formatMessage({ id: 'common.status' })}
                onSelect={(selectedValue, option) => {
                    updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                }}
                allowClear={true}
                onClear={() => {
                    updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                }}
            />
          </Col> */}
          <Col span={12}>
            <div className="i-label">
              <FormattedMessage id="common.systemStatus" />
            </div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '140px', marginRight: '5px',float:'left' }}
              className=""
              items={[
                { name: "Open", value: "open" },
                { name: "Partially Fullfilled", value: "partially.fulfilled" },
                { name: "Fullfilled", value: "fulfilled" },
              ].sort((a, b) => a.value.localeCompare(b.value))}
              valueKeyName="name"
              optionKeyName="value"
              value={props.selectedStatusName}
              placeholder={props.intl.formatMessage({
                id: "common.systemStatus",
              })}
              onSelect={(status, option) => {
                updateState({
                  selectedStatusName: option.name,
                  selectedStatus: option.value,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedStatusName: undefined,
                  selectedStatus: undefined,
                });
              }}
            />
          </Col>
        
          <Col span={12}>
            <div className="i-label">Ref #</div>
            <TextBox 
                        // style={{ marginTop: '', width: '140px', marginRight: '5px', float:'left' }}
                        className=''
                        placeholder='Ref #'
                        type='text'
                        countStyle={{ top: "43px"}}
                        maxLength={15}
                        value={props.refNum}
                        onChange={(e) => {
                            props.updateState({ refNum: e.target.value });
                        }}
                    />
          </Col>

          {/* <Col span={12}>
            <div className="i-label">Quality Check</div>
            <Dropdown
                            // style={{ marginTop: '-20px', width: '140px' }}
                            
                            items={props.allQualityChecksName}
                            valueKeyName='checklistName'
                            optionKeyName='qualityChecklistId'
                            value={props.selectedQcName}
                            placeholder='Quality Check'
                            onSelect={(status, option) => {
                                updateState({ selectedQcName: option.name, selectedQcId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedQcId: undefined, selectedQcName: undefined })
                            }}
                        />
          </Col> */}
     
         <Col span={12}>
            <div className="i-label">Projects</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '160px', marginRight: '5px', float:'left' }}
            //   className="1"
              items={(props.projectList||[]).sort((a, b) => a.projectName.localeCompare(b.projectName))}
              valueKeyName="projectName"
              optionKeyName="projectMasterId"
              // projectIdKey="projectMasterId"
              value={props.selectedProjectName}
              placeholder={props.intl.formatMessage({
                id: "salesInvoice.project.placeholder",
              })}
              onSelect={(status, option) => {
                updateState({
                  selectedProjectName: option.name,
                  selectedProjectMasterId: option.value,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedProjectName: undefined,
                  selectedProjectMasterId: undefined,
                });
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default PackageFilterComp;
