import { CREDIT_MEMO_ACTION_LIST } from '../static/constants';
const initialState = {
  creditMemoList: {
    1: []
  },
  creditMemoCount: 0,
  costCenters: [],
  creditMemoDetail: {}
}

const CreditMemoReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREDIT_MEMO_ACTION_LIST.CREDIT_MEMO_LIST:
      return {
        ...state,
        creditMemoList: {
          ...state.creditMemoList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case CREDIT_MEMO_ACTION_LIST.CREDIT_MEMO_COUNT:
      return { ...state, creditMemoCount: action.data };
    case CREDIT_MEMO_ACTION_LIST.CREDIT_MEMO_LIST_PAGE_RESET:
      return { ...state, creditMemoList: initialState.creditMemoList };
    case CREDIT_MEMO_ACTION_LIST.COST_CENTER_LIST:
      return { ...state, costCenters: action.data || [] };
    case CREDIT_MEMO_ACTION_LIST.CREDIT_MEMO_DETAIL:
      return { ...state, creditMemoDetail: action.data };
    case CREDIT_MEMO_ACTION_LIST.CREDIT_MEMO_DETAIL_RESET:
      return { ...state, creditMemoDetail: initialState.creditMemoDetail };
    case CREDIT_MEMO_ACTION_LIST.NEXT_CREDIT_MEMO_NUMBER:
      return { ...state, nextTxNumber: action.data };
    case CREDIT_MEMO_ACTION_LIST.NEXT_CREDIT_MEMO_NUMBER_RESET:
      return { ...state, nextTxNumber: '' };
    default:
      return state;
  }
};

export default CreditMemoReducer;