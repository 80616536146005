import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllCoupons } from '../../../modal/modalBody/eStore/Coupon/action';
import { fetchCategory } from "../../inventory/action";
import { fetchProducts } from "../../../product/Listing/action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import CreateCoupon from '../../../../components/modal/modalBody/eStore/Coupon/createCoupon';
import { capitalizeFirstLetter } from '../../../../utils';

class CreateCouponDrawer extends Component {
    constructor(props){
        super(props);

        this.state={
            couponId: undefined,
            relationshipId: undefined,
            couponCode: undefined,
            couponType: undefined,
            currencyCode: undefined,
            discountAmount: undefined,
            minPurchaseAmount: undefined,
            usageLimitPerCustomer: undefined,
            newCustomerOnly: undefined,
            autoApply: undefined,
            active: undefined,
            startDate: undefined,
            endDate: undefined,
            createdDate: undefined,
            updatedDate: undefined
        }
    }

    componentDidMount() {
        const {couponData={}} = this.props;
        const payload ={
            relationshipId: this.props?.companyInfo?.relationshipId,
            pageNumber: 1,
            pageSize: 500
        }

        // if category/product item is selected,call category/product list
        if((couponData.couponInvItemRef || []).length){
            switch(couponData.couponInvItemRef[0]?.inventoryItemType){
                case 'CATEGORY': 
                    this.props.fetchCategory(payload);
                    break;
                case 'PRODUCT':
                    this.props.fetchProducts(payload);
                    break;
                default: break;
            }
        }

        if(Object.keys(couponData)?.length){
            this.setState({
                ...couponData,
                applicableTo: couponData.applyToAllCustomers ? 'ALL_CUSTOMERS' : couponData.newCustomerOnly ? 'NEW_CUSTOMERS' : couponData.validOnlinePayment ? 'ONLINE_PAYMENTS' : '',
                orderType: couponData.minPurchaseAmount ? 'Amount' : 'Quantity',
                orderValue: couponData.minPurchaseAmount || couponData.minOrderQuantity,
                applyOn: (couponData.couponInvItemRef || []).length ? capitalizeFirstLetter(couponData.couponInvItemRef[0]?.inventoryItemType.toLowerCase()) : '',
                inventoryItemCategoryRefs: (couponData.couponInvItemRef || []).map(obj=>({inventoryItemCategoryId: obj.invItemId})),
                inventoryItems: (couponData.couponInvItemRef || []).map(obj=>({itemId: obj.invItemId})),
                maxDiscount: couponData.couponType === "FLAT" ? couponData.discountAmount : couponData.maxDiscount
            });
        }
    }
    
    render() {
        return (
            <CreateCoupon
                {...this.props}
                {...this.state}
                updateState={(data) => { this.setState(data); }}
            />
        )
    }

}


const mapStateToProps = (state) => {
    return {
        couponList: state.eStore.couponList,
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        storeValues: state.common.store_values,
        productCategories: state.inventory.productCategories,
        products: state.product.products[1],
        listLoading: state.common.listLoading
    };
  };
  
const mapDispatchToProps = (dispatch) => bindActionCreators({
    getAllCoupons,
    showModal,
    hideModal, 
    pushModalToStack, 
    popModalFromStack,
    fetchCategory,
    fetchProducts
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CreateCouponDrawer);