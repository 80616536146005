import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportSubjectComp from '../../../../../components/modal/modalBody/schoolManagement/ImportSubjects';
import {
    fetchPaymentTerms, addPaymentTerm, deletePaymentTerm,
    fetchBusinessTypes, addBusinessType, deleteBusinessType,
    fetchIndustries, addIndustry, deleteIndustry,
    fetchSources, addCustomerSource, deleteCustomerSource, hideModal
} from '../../../../../actions/commonActions';
import { downloadExelFile, getSubjectImportHeader, importSubjectsCsv } from './action';


class ImportSubjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            isuploded: false,
            headerMandatoryValues:[],
            headerValues:[]
        };

    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        this.props.getSubjectImportHeader(companyInfo.relationshipId)
    }

    componentWillReceiveProps(props){
        const { subjectHeaderList=[] } = props;
        
        if(subjectHeaderList.length ){
            
            let headerValues = subjectHeaderList.map((item)=>item.columnName);
            let headerMandatoryValues = subjectHeaderList.map((item)=>item.mandatory);
            
            this.setState({
                headerValues:headerValues,
                headerMandatoryValues:headerMandatoryValues
            });
        }
    }

    render() {
        return <ImportSubjectComp {...this.props} {...this.state} updateModalState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        sources: state.common.sources,
        businessTypes: state.common.businessTypes,
        paymentTerms: state.common.paymentTerms,
        industries: state.common.industries,
        companyInfo: state.common.companyInfo,
        subjectHeaderList: state.school.subjectHeaderList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSources,
    addCustomerSource,
    deleteCustomerSource,
    fetchPaymentTerms,
    addPaymentTerm,
    deletePaymentTerm,
    fetchIndustries,
    addIndustry,
    deleteIndustry,
    fetchBusinessTypes,
    addBusinessType,
    deleteBusinessType,
    hideModal,
    getSubjectImportHeader,
    importSubjectsCsv,
    downloadExelFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportSubjects);
