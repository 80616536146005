import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import "./index.scss";
import {
  Row,
  Col,
} from "antd";
import { CONSTANTS } from "../../../static/constants";
import { LogoComponent } from "../../general/LogoComponent";
import config from "../../../config/environmentConfig";


const FormHeader = (props) => {
  const {
    formSetting = {},
    companyInfo,
  } = props;

  const getAddressInfoFragment = (obj={}, additionInfo) => {
    const fragmentObj = (
      <>
        {obj.streetAddress1 ? (
          <span className="billing-address">{obj.streetAddress1}</span>
        ) : (
          ""
        )}
        {obj.streetAddress2 ? (
          <span className="billing-address"> {obj.streetAddress2}</span>
        ) : (
          ""
        )}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""}${
              obj.stateName ? ", " + obj.stateName : ""
            }${obj.zipCode ? ", " + obj.zipCode : ""}`}{`, ${obj.countryName || ""}`}
          </div>
        ) : (
          ""
        )}
        {/* {obj.countryName ? (
          <div className="billing-address">{`, ${obj.countryName || ""}`}</div>
        ) : (
          ""
        )} */}
        {additionInfo.workPhone ? (
          <div className="billing-address">
            <FormattedMessage id="phone" defaultMessage="Ph" /> -{" "}
            {additionInfo.workPhone}
          </div>
        ) : (
          ""
        )}
        {additionInfo.email ? (
          <div className="billing-address">
            <FormattedMessage id="email" defaultMessage="Email" /> -{" "}
            {additionInfo.email}
          </div>
        ) : (
          ""
        )}
      </>
    );
    return fragmentObj;
  };


  return (
    <Row
      gutter={24}
      className="reg-header"
      style={{
        background: formSetting.bgColor || "#31c0be",
        color: formSetting.textColor || "#ffffff",
        padding: "5px",
      }}
    >
      <Col span={6}>
        <div className="brand-logo">
          {(props.companyInfo || {}).logoAwsObjectKey ? (
            <LogoComponent
              islogoImage={true}
              // shape={'circle'}
              style={{background: 'transparent'}}
              src={
                CONSTANTS.BASE_URL_S3 +
                "/" +
                config.BUCKET_NAME.BO_RELATIONSHIP +
                "/" +
                (props.companyInfo || {}).logoAwsObjectKey +
                "?" +
                Date.now()
              }
            />
          ) : (
            ""
          )}
        </div>
      </Col>

      <Col span={18} className="address-part">
        {getAddressInfoFragment(
          props.relationshipBillingAddress,
          companyInfo
        )}
      </Col>
    </Row>
  );
};

export default injectIntl(FormHeader);
