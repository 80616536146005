import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col,  Button, Popover } from 'antd'
import * as find from 'lodash.find';
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS, TRANSACTION_COLUMN_INDEX, MODAL_TYPE } from '../../../../../static/constants';
import config from '../../../../../config/environmentConfig';
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../../static/agGridConstants';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { AddAndRemoveColumn } from '../../../../general/AddAndRemoveColumn';
import filter from 'lodash.filter';
import { fixedDecimalAndFormateNumber, getMomentDateForUIReadOnly } from '../../../../../utils';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import CKEditor from '../../../../general/CustomCKEditor/ckeditor';
import { CUSTOM_CONFIGURATION, CUSTOM_CONFIGURATION_FOOTER } from '../../../../../static/ckEditorConfigConstants';
import { LogoComponent } from '../../../../general/LogoComponent';


class ShipmentDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sectionVisible: {
                discount: false,
                tax: false,
                expense: false
            },
            total: 0,
            subtotal: 0,
            subtotalWithoutDiscount: 0,
            totalDiscount: 0,
            totalTax: 0,
            itemLevelTaxes: [],
            rootDiscountPercent: 0,
            rootDiscountAmount: 0,
        }
    }
    

    getGrandTotal = () => {
        let grandTotal = 0;
        if (this.props.selectedSalesOrder.length) {
            this.props.selectedSalesOrder.forEach((soData) => {
                grandTotal = grandTotal + Number(this.state["total" + soData.salesOrderMasterId] || 0)
            });
            return Number(grandTotal || 0).toFixed(2)
        } else return 0.00;
    }

    getTotalDiscount = () => {
        let totalDiscount = 0;
        if (this.props.selectedSalesOrder.length) {
            this.props.selectedSalesOrder.forEach((soData) => {
                totalDiscount = totalDiscount + Number(this.state["totalDiscount" + soData.salesOrderMasterId] || 0)
            });
            return Number(totalDiscount || 0).toFixed(2)
        } else return 0.00;
    }

    getGrandSubTotal = () => {
        let grandSubTotal = 0;
        if (this.props.selectedSalesOrder.length) {
            this.props.selectedSalesOrder.forEach((soData) => {
                grandSubTotal = grandSubTotal + Number(this.state["subtotalWithoutDiscount" + soData.salesOrderMasterId] || 0)
            });
            return Number(grandSubTotal || 0).toFixed(2)
        } else return 0.00;
    }

    getItemLevelTaxes = () => {
        let itemLevelTaxes = [];
        this.props.selectedSalesOrder.forEach((soData) => {
            if (this.state["itemLevelTaxes" + soData.salesOrderMasterId]) {
                itemLevelTaxes = itemLevelTaxes.concat(this.state["itemLevelTaxes" + soData.salesOrderMasterId]);
            }
        });
        return itemLevelTaxes;
    }

    calculateSubtotal = () => {
        let total = 0;
        let subtotal = 0;
        let totalDiscount = 0;
        let totalTax = 0;
        let itemLevelTaxes = [];
        let subtotalWithoutDiscount = 0;
        let rootDiscountAmount = 0;
        let self = this;

        if (!this.state.rootDiscountPercent && this.props.rootDiscountPercent) {
            this.setState({
                rootDiscountPercent: this.props.rootDiscountPercent,
                rootDiscountAmount: this.props.rootDiscountAmount,
                expenseAmount: this.props.expenseAmount
            });
        }

        this.gridApi && this.gridApi.forEachNode(function (rowNode, index) {
            let stringifiedRowData = JSON.stringify(rowNode.data);
            let totalAmountOfColumn = 0;
            const rowdata = JSON.parse(stringifiedRowData);
            if (rowdata.quantity && rowdata.rate) {
                let taxObj = {
                    subtotal: 0,
                    taxPercent: 0,
                    taxAmount: 0
                }
                totalAmountOfColumn = Number(rowdata.quantity) * Number(rowdata.rate);
                subtotalWithoutDiscount = Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
                if (rowdata.discount) {
                    let discountAmount = ((Number(rowdata.discount || 0) * 0.01) * totalAmountOfColumn);
                    totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                    totalDiscount = totalDiscount + discountAmount;
                }


                if (self.state.rootDiscountPercent || self.props.rootDiscountPercent) {
                    const rootDiscountPercent = self.state.rootDiscountPercent || self.props.rootDiscountPercent;
                    let itemLevelDiscountAmount = (Number(rootDiscountPercent) * 0.01) * totalAmountOfColumn;
                    totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
                    totalDiscount = totalDiscount + itemLevelDiscountAmount
                    rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
                }

                if (rowdata.tax) {
                    taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
                    taxObj.taxName = rowdata.taxName;
                    let taxAmount = ((Number(rowdata.tax || 0) * 0.01) * totalAmountOfColumn);
                    totalTax = totalTax + taxAmount;
                }
                if (taxObj.taxPercent) {
                    taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
                    const availableTaxObj = find(itemLevelTaxes, { taxPercent: taxObj.taxPercent });
                    if (availableTaxObj) {
                        itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                            if (ele.taxPercent === taxObj.taxPercent) {
                                ele.subtotal = (Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0)).toFixed(2);
                                ele.taxAmount = (Number(ele.taxPercent || 0) * 0.01) * Number(ele.subtotal || 0);
                            }
                            return true;
                        });
                    } else {
                        taxObj.taxAmount = ((Number(taxObj.taxPercent || 0) * 0.01) * Number(taxObj.subtotal || 0)).toFixed(2);
                        itemLevelTaxes.push(taxObj);
                    }
                }
                subtotal = Number(subtotal) + totalAmountOfColumn;
            }
        });


        total = Number(subtotal || 0) + totalTax + Number(this.state.expenseAmount || this.props.expenseAmount || 0);

        this.setState({
            total: Number(total || 0).toFixed(2),
            subtotal: Number(subtotal || 0).toFixed(2),
            totalDiscount: Number(totalDiscount || 0).toFixed(2),
            totalTax: Number(totalTax || 0).toFixed(2),
            subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
            itemLevelTaxes,
            rootDiscountAmount: Number(rootDiscountAmount || 0).toFixed(2)
        });

        this.props.updateShippingState({
            rootDiscountPercent: 0,
            rootDiscountAmount: 0,
            expenseAmount: 0,
            itemLevelTaxes,
            totalDiscount: Number(totalDiscount || 0).toFixed(2),
            subtotal: Number(subtotal || 0).toFixed(2),
            total: Number(total || 0).toFixed(2),
        });
    }

    componentDidMount(){
        setTimeout(()=>{
            this.calculateSubtotal();
        },5000);
    }


    render() {
        const { itemLevelTaxes } = this.state;
        const isPlaceOfSupplySameAsRelationship = (this.props.relationshipBillingAddress || {}).stateName === this.props.placeOfSupply;
        const { shipmentData, companyInfo, shippingInvoiceData } = this.props;
        // const relationshipAddress = find(companyInfo.boLocationList, { locationId: parseInt(shipmentData.billingLocationId) }) || {};
        // const shippingAddress = find(customerData.boLocationList, {locationId: parseInt(shipmentData.shippingLocationId)}) || {};

        const getVariant = (row) => {
            let title = '('
            title += row.attributeValue1 ? row.attributeValue1 : '';
            title += row.attributeValue2 ? '|'  + row.attributeValue2 : '';
            title += row.attributeValue3 ? '|' + row.attributeValue3 : '';
            return title + ")"
        };

        const relationshipAddress = find((shippingInvoiceData || {}).boLocationShipmentList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {};
        const billingAddress = find((shippingInvoiceData || {}).boLocationShipmentList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {};
        const shippingAddress = find((shippingInvoiceData || {}).boLocationShipmentList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {};
        const additionalInfo = ((shippingInvoiceData || {}).additionalInfoList || [])[0] || {}
        let customerSOPackageDetailsList = (shippingInvoiceData || {}).customerSOShipmentDetailsList || [];


        const content = <AddAndRemoveColumn {...this.props} />

        const _isColumnToHide = (columnName) => {
            // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
            //     return true;
            // }
            return (this.props.txColumns.indexOf(columnName) < 0 && this.props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
        }


        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return "#";
                },
                cellRendererFramework: (params) => {
                    return params.node.rowIndex + 1
                },
                field: "sno",

                colId: "sno_1",
                minWidth: 75,
                maxWidth: 75,
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.common.product' defaultMessage='' />;
                },

                field: "itemName",
                colId: "itemName_1",
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30) {
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            params.node.data.minHeight = params.reactContainer.scrollHeight > 30 ? params.reactContainer.scrollHeight : 30;
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        }
                    }, 500)
                    return <div>{params.data.itemName || ""}{ params.node.data.itemVariantId?  getVariant(params.data) : null} </div>
                },
                resizable: true,
                hide: _isColumnToHide('itemName')
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.common.description' defaultMessage='' />;
                },
                field: 'description',
                colId: "description_1",
                editable: true,
                resizable: true,
                hide: _isColumnToHide('description'),
                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight(params.reactContainer.scrollHeight);
                            this.gridApi && this.gridApi.onRowHeightChanged();
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.description)
                },
                cellEditor: 'CKEditorGrid',
                cellEditorParams: (params) => {
                    return {
                        value: params.node.data.description || "",
                        isEditorLoaded: this.props.isEditorLoaded,
                        onChange: (value) => {
                            //params.node.data[params.colDef.field] = value;
                            params.node.data.isEditorLoaded = true;
                            this.props.updateShippingState({
                                isEditorLoaded: true
                            })
                        }
                    }
                },
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.event.target.value && params.editing && keyCode === KEY_ENTER;
                    params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
                    if (params.event.target.scrollHeight > (params.data.minHeight || 30)) {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
                        }
                        params.node.setRowHeight(params.event.target.scrollHeight);
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    } else {
                        if (params.event.path && params.event.path[2]) {
                            params.event.path[2].style.height = 'inherit';
                            params.event.path[2].style.height = `${(params.node.data.minHeight || 30)}px`;
                        }
                        params.node.setRowHeight((params.node.data.minHeight || 30));
                        this.gridApi && this.gridApi.onRowHeightChanged();
                    }
                    return gridShouldDoNothing;
                }

            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.common.qty' defaultMessage='' />;
                },
                field: "quantity",
                resizable: true,
                colId: "quantity_1",
                hide: _isColumnToHide('quantity'),
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.common.uom' defaultMessage='' />;
                },
                field: 'uomName',
                resizable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
                colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.common.rate' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                editable: true,
                valueGetter: (params) => {
                    return params.data.rate && Number(params.data.rate || 0).toFixed(2);;
                },
                cellEditor: 'customNumberEditor',
                cellEditorParams: (params) => {
                    return {
                        onChange: (value) => {
                            params.node.data.baseRate = value;
                            params.node.data.rate = value;
                            params.api.refreshCells();
                        }
                    }
                },
                resizable: true,
                colId: AG_GRID_CONSTANTS.COMMON.RATE_FIELD + "_1",
                hide: _isColumnToHide(AG_GRID_CONSTANTS.COMMON.RATE_FIELD)
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.shipment.packageNumber' defaultMessage='' />;
                },

                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight((params.reactContainer.scrollHeight));
                            params && params.api.onRowHeightChanged();
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.packageNumber)
                },
                colId: "packageNumber_1",
                resizable: true,
                hide: _isColumnToHide('packageNumber'),
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.shipment.soNumber' defaultMessage='' />;
                },

                cellRendererFramework: (params) => {
                    setTimeout(() => {
                        if (params.reactContainer && params.reactContainer.scrollHeight > 30 && params.reactContainer.scrollHeight >= (params.node.data.minHeight || 0)) {
                            params.eParentOfValue.style.height = 'inherit';
                            params.eParentOfValue.style.height = params.reactContainer.scrollHeight;
                            params.node.data.maxHeight = params.reactContainer.scrollHeight;
                            params.node.setRowHeight((params.reactContainer.scrollHeight));
                            params && params.api.onRowHeightChanged();
                        }
                    }, 500)
                    return ReactHtmlParser(params.data.salesOrderNumber)
                },
                field: "salesOrderNumber",
                colId: "salesOrderNumber_1",
                resizable: true,
                hide: _isColumnToHide('salesOrderNumber'),
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.shipment.materialNumber' defaultMessage='' />;
                },
                field: "materialNumber",
                editable: true,
                colId: "materialNumber_1",
                resizable: true,
                hide: _isColumnToHide('materialNumber'),

            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.shipment.warehouse' defaultMessage='' />;
                },
                field: "warehouse",
                colId: "warehouseName_1",
                editable: true,
                resizable: true,
                hide: _isColumnToHide('warehouseName')
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.shipment.stockNumber' defaultMessage='' />;
                },
                field: "stockNumber",
                colId: "stockNumber_1",
                editable: true,
                resizable: true,
                hide: _isColumnToHide('stockNumber'),
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='modal.txDetails.shipment.partNumber' defaultMessage='' />;
                },
                field: "partNumber",
                colId: "partNumber_1",
                editable: true,
                resizable: true,
                hide: _isColumnToHide('partNumber'),
            },
            {
                headerComponentFramework: () => {
                    return <div><FormattedMessage id='customer.salesOrder.form.gridHeader.origin' defaultMessage='' />
                    </div>
                },
                field: AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD,
                resizable: true,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.ORIGIN),
                colId: GRID_PREFERENCE_CONSTANTS.ORIGIN + "_1",
                suppressMovable: true,
                editable: true,
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: false,
                        items: this.props.countries,
                        optionKeyName: 'countryId',
                        permissions: this.props.permissions,
                        valueKeyName: 'countryName',
                        onSelect: (selectedValue) => {
                            ////obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.origin = selectedValue;
                        },
                        allowClear: true,
                        onClear: () => {
                            ////obj.node.setDataValue(obj.colDef.field, undefined);
                            obj.node.data.origin = undefined;
                        },
                    }
                },
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesOrder.form.gridHeader.hsCode' defaultMessage='' />;
                },
                field: "hsCode",
                colId: "hsCode_1",
                editable: true,
                resizable: true,
                hide: _isColumnToHide('hsCode')
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesOrder.form.gridHeader.specialDiscount' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
                editable: true,
                resizable: true,
                minWidth: 115,
                maxWidth: 140,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT),
                colId: GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT + "_1",
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesOrder.form.gridHeader.discount' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
                editable: true,
                resizable: true,
                minWidth: 115,
                maxWidth: 140,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.DISCOUNT),
                colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT + "_1",
                cellEditor: 'customNumberEditor',
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesQuote.form.gridHeader.tax' defaultMessage='' />;
                },
                field: 'taxApplied',
                editable: true,
                resizable: true,
                suppressMovable: true,
                minWidth: 100,
                //maxWidth: 300,
                sortable: true,
                hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX),
                colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
                cellEditor: 'customDropDownEditor',
                suppressKeyboardEvent: (params) => {
                    const KEY_ENTER = 13;
                    const keyCode = params.event.keyCode;
                    const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
                    return gridShouldDoNothing;
                },
                cellEditorParams: (obj) => {
                    return {
                        lastColumnCell: true,
                        items: this.props.taxes,
                        optionKeyName: 'taxSingleRateId',
                        canAddNew: true,
                        canDelete: true,
                        deleteClickHandler: (payload) => {
                            this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
                        },
                        onAddNewClick: (payload) => {
                            payload.formData = {
                                taxName: payload.textEntered,
                                submittedOnce: false
                            }
                            this.handleNewTaxAddition(this.props, payload, MODAL_TYPE.NEW_TAX)
                        },
                        valueKeyName: 'taxNameDisplay',
                        onSelect: (selectedValue, option) => {
                            const selectedTax = find(this.props.taxes, { taxSingleRateId: Number(option.key) });
                            obj.node.data.tax = selectedTax.taxPercent;
                            obj.node.data.taxName = selectedTax.taxName;
                            ////obj.node.setDataValue(obj.colDef.field, selectedValue);
                            obj.node.data.taxId = selectedTax.taxSingleRateId;
                        },
                        allowClear: true,
                        onClear: () => {
                            obj.node.data.tax = null;
                            obj.node.data.taxId = null;
                            obj.node.data.taxApplied = null;
                            obj.node.data.taxName = null;
                          },
                    }
                }
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='customer.salesOrder.form.gridHeader.amount' defaultMessage='' />;
                },
                field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
                colId: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD + "_1",
                cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
                resizable: true,
                maxWidth: 200,
                suppressMovable: true,
                suppressNavigable: true,
                valueGetter: (params) => {
                    let totalAmountOfColumn = 0;
                    if (params.data.quantity && params.data.rate) {
                        totalAmountOfColumn = params.data.quantity * params.data.rate;
                        if (params.data.discount) {
                            let discountAmount = ((params.data.discount * 0.01) * totalAmountOfColumn);
                            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
                        }
                        if (params.data.tax) {
                            totalAmountOfColumn = ((params.data.tax * 0.01) * totalAmountOfColumn) + totalAmountOfColumn;
                        }
                        //params.data.amount = totalAmountOfColumn ? totalAmountOfColumn.toFixed(2) : "";
                        this.calculateSubtotal();
                    }
                    return totalAmountOfColumn ? totalAmountOfColumn.toFixed(2) : "";
                }

            },
            {
                headerComponentFramework: () => {
                    return <div className="cursor-pointer">
                        <Popover
                            content={content}
                            title={<div className="text-center">Add/Remove </div>}
                            placement="left"
                            trigger="click"
                        >
                            <i className='fa fa-bars' />
                        </Popover>
                    </div>;
                },
                field: 'action',
                colId: "action_1",

                minWidth: 52,
                maxWidth: 52,
                pinned: 'right',
            }

        ];

        const saveColumnWidth = (columnState, width) => {
            let data = this.props.txPreferences || {};
            let gridPreference = {};
            data.relationshipId = (this.props.companyInfo || {}).relationshipId;
            data.clientUserAccountId = (this.props.userInfo || {}).userId;
            if (data.gridPreference) {
                gridPreference = JSON.parse(data.gridPreference);
            }
            gridPreference.columnSetting = columnState;
            gridPreference.columnList = this.props.txColumns;
            data.gridPreference = JSON.stringify(gridPreference);
            data.preferenceId = this.props.preferenceId;
            data.tablePreference = this.props.tablePreference;
            this.props.saveUserPrefrences(data, this.props);
        }

        const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
            const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
            event.api.resetRowHeights();
            //const gridApi = tablegridApi || this.gridApi;
            if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
                //event.api.resetRowHeights();
                if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
                    this.gridApi.sizeColumnsToFit();
                    // const columnArray = event.columnApi.getColumnState();
                    // for (let i = (columnArray.length - 1); i >= 0; i--) {
                    //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
                    //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
                    //         break;
                    //     }
                    // }
                    // saveColumnWidth(columnArray);
                    setTimeout(() => {
                        const columnArray = event.columnApi.getColumnState();
                        saveColumnWidth(columnArray);
                    }, 1000);
                } else {
                    saveColumnWidth(event.columnApi.getColumnState());
                }
            }
        }

        const onModelUpdated = (params) => {
            if (this.props.tableDataReRendered) {
                setTimeout(() => {
                    this.calculateSubtotal();
                }, 1500)
               // params.api.resetRowHeights();
                this.props.updateShippingState({
                    tableDataReRendered: false
                })
                let actionIndex = 0;
                let amountIndex = 0;
                let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
                const columnToBeSet = [];
                const indexObjArr = TRANSACTION_COLUMN_INDEX.SHIPMENT_INVOICE_FORM

                indexObjArr.forEach((e) => {
                    let colObj = find(txColumnSetting, { colId: e + '_1' });
                    if (colObj) {
                        columnToBeSet.push(colObj);
                    }
                });

                txColumnSetting = txColumnSetting.filter((ele) => {
                    let columnName = ele.colId.split("_")[0];
                    return indexObjArr.indexOf(columnName) === -1;
                })
                txColumnSetting = columnToBeSet.concat(txColumnSetting);
                txColumnSetting.forEach((e, index) => {
                    let columnName = e.colId.split("_")[0];
                    if (columnName !== 'action' && columnName !== 'amount') {
                        if (columnName === 'isChecked' || columnName === 'sno') {
                            e.pinned = 'left';
                            e.lockPosition = true;
                            e.hide = false;
                        } else {
                            e.hide = _isColumnToHide(columnName);
                        }
                    }
                    if (columnName === 'amount') {
                        e.hide = false;
                        amountIndex = index;
                        e.lockPosition = true;
                        e.pinned = 'right';
                    }
                    if (columnName === 'action') {
                        actionIndex = index;
                        e.width = 52;
                        e.hide = false;
                        e.pinned = 'right';
                    }
                });
                const actionObj = txColumnSetting[actionIndex];
                const amountObj = txColumnSetting[amountIndex];
                txColumnSetting = txColumnSetting.filter((e) => {
                    let columnName = e.colId.split("_")[0];
                    return columnName !== 'action' && columnName !== 'amount';
                });
                amountObj.lockPosition = true;
                actionObj.lockPosition = true;
                txColumnSetting.push(amountObj);
                txColumnSetting.push(actionObj);
                params.columnApi.setColumnState(txColumnSetting);
                setTimeout(() => {
                    if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                        params.api.sizeColumnsToFit();
                    }
                }, 500);

            }
        }


        const onDataRendered = (params) => {
            let txColumnSetting = this.props.txColumnSetting && this.props.txColumnSetting.length ? this.props.txColumnSetting : params.columnApi.getColumnState();
            let actionIndex = 0;
            let amountIndex = 0;
            if (Object.keys(txColumnSetting).length) {
                const columnToBeSet = [];
                const indexObjArr = TRANSACTION_COLUMN_INDEX.SHIPMENT_INVOICE_FORM

                indexObjArr.forEach((e) => {
                    let colObj = find(txColumnSetting, { colId: e + '_1' });
                    if (colObj) {
                        columnToBeSet.push(colObj);
                    }
                });

                txColumnSetting = txColumnSetting.filter((ele) => {
                    let columnName = ele.colId.split("_")[0];
                    return indexObjArr.indexOf(columnName) === -1;
                })
                txColumnSetting = columnToBeSet.concat(txColumnSetting);
                txColumnSetting.forEach((e, index) => {
                    let columnName = e.colId.split("_")[0];
                    if (columnName !== 'action' && columnName !== 'amount') {
                        if (columnName === 'isChecked' || columnName === 'sno') {
                            e.pinned = 'left';
                            e.hide = false;
                        } else {
                            e.hide = _isColumnToHide(columnName);
                        }
                    }
                    if (columnName === 'action') {
                        e.width = 52;
                        actionIndex = index;
                        e.hide = false;
                        e.pinned = 'right';
                    }
                    if (columnName === 'amount') {
                        e.hide = false;
                        e.lockPosition = true;
                        amountIndex = index;
                        e.pinned = 'right';
                    }

                });
                const actionObj = txColumnSetting[actionIndex];
                const amountObj = txColumnSetting[amountIndex];
                txColumnSetting = txColumnSetting.filter((e) => {
                    let columnName = e.colId.split("_")[0];
                    return columnName !== 'action' && columnName !== 'amount';
                });
                amountObj.lockPosition = true;
                actionObj.lockPosition = true;
                txColumnSetting.push(amountObj);
                txColumnSetting.push(actionObj);
                this.gridColumnApi.setColumnState(txColumnSetting);
                setTimeout(() => {
                    if (this.gridColumnApi && this.gridColumnApi.columnController.bodyWidth < this.gridColumnApi.columnController.scrollWidth) {
                        params.api.sizeColumnsToFit();
                    }
                }, 500);
            } else {
                this.gridApi.sizeColumnsToFit();
            }
        }

        return (
            <div className={"custom-modal show"}>
                <i className="close-icon">
                    <svg width="17" height="17" onClick={() => { this.props.popModalFromStack(); }}>
                        <title><FormattedMessage id='button.close.label' defaultMessage='' /></title>
                        <path fill="#67757d" fillRule="evenodd"
                            d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z" />
                    </svg>
                </i>
                <div className="modal-dialog">
                    <div className="modal-content full-width">
                        <div className="modal-header align-left">
                        <FormattedMessage id='modal.shippingInvoice.title' defaultMessage='' />
                    </div>
                        <div className="inner-content">
                            <Row>
                                <Col span={5}>
                                    <div className="d-inline-flex w-100">
                                    <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/>
                                        {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (this.props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()} /> */}
                                        <div className="read-only-content prl10">
                                            <div className="title lh-adjust pb5">
                                                {this.props.companyInfo.storeName}
                                            </div>
                                            {getAddressInfoFragment(relationshipAddress, companyInfo)}
                                        </div>
                                    </div>
                                </Col>

                                <Col span={4} className="read-only-content pr20">
                                    <div className="title">
                                        <div className=""><FormattedMessage id='modal.txDetails.common.billTo' defaultMessage='' /></div>
                                        {getContactNameFragment(additionalInfo)}
                                        {shipmentData.companyName}
                                    </div>
                                    <div>
                                        {getAddressInfoFragment(billingAddress, additionalInfo)}
                                    </div>
                                </Col>

                                <Col span={4}>
                                    {shippingAddress.streetAddress1 ? <div className="read-only-content pr20">
                                        <div className="title"><FormattedMessage id='modal.txDetails.common.shipTo' defaultMessage='' /></div>
                                        <div className="title">
                                            {shipmentData.companyName}
                                        </div>
                                        {getAddressInfoFragment(shippingAddress, {})}

                                    </div> : '' }
                                </Col>

                                <Col span={5} className="read-only-content">
                                    <Row>
                                        <Col span={10} className="title text-right pr10">
                                        <FormattedMessage id='modal.txDetails.shipment.shipmentNumber' defaultMessage='' />
                                    </Col>
                                        <Col span={14}>
                                            {shipmentData.shipmentNumber || '-'}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={10} className="title text-right pr10">
                                        <FormattedMessage id='modal.txDetails.shipment.carrier' defaultMessage='' />
                                    </Col>
                                        <Col span={14}>
                                            {shipmentData.carrierName || '-'}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={10} className="title text-right pr10">
                                        <FormattedMessage id='modal.txDetails.shipment.trackingNumber' defaultMessage='' />
                                    </Col>
                                        <Col span={14}>
                                            {shipmentData.trackingNumber || '-'}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={10} className="title text-right pr10">
                                        <FormattedMessage id='modal.txDetails.shipment.freightType' defaultMessage='' /> 
                                    </Col>
                                        <Col span={14}>
                                            {shipmentData.freightType || '-'}
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={6} className="read-only-content">
                                    <Row>
                                        <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.consignee' defaultMessage='' /> </Col>
                                        <Col span={10}>{shipmentData.consigneeName || '-'}</Col>
                                    </Row>

                                    <Row>
                                        <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.shipmentDate' defaultMessage='' /> </Col>
                                        <Col span={10}>
                                            {(shipmentData.shipmentDate && getMomentDateForUIReadOnly({date: shipmentData.shipmentDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.expectedDelivery' defaultMessage='' /></Col>
                                        <Col span={10}>
                                            {(shipmentData.expectedDeliveryDate && getMomentDateForUIReadOnly({date: shipmentData.expectedDeliveryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
                                        </Col>
                                    </Row>
                                    {/* {((customerSOShippedPackages || []).length === 1) &&
                                        <div>
                                            {customerSOShippedPackages[0].packageNumber &&
                                                <Row>
                                                    <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.packageNumber' defaultMessage='' /></Col>
                                                    <Col span={10}>
                                                        {customerSOShippedPackages[0].packageNumber}
                                                    </Col>
                                                </Row>
                                            }
                                            {customerSOShippedPackages[0].salesOrderNumber &&
                                                <Row>
                                                    <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.soNumber' defaultMessage='' /></Col>
                                                    <Col span={10}>
                                                        {customerSOShippedPackages[0].salesOrderNumber}
                                                    </Col>
                                                </Row>
                                            }
                                            {customerSOShippedPackages[0].customerPONumber &&
                                                <Row>
                                                    <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.shipment.poNumber' defaultMessage='' /></Col>
                                                    <Col span={10}>
                                                        {customerSOShippedPackages[0].customerPONumber}
                                                    </Col>
                                                </Row>
                                            }
                                            {soData.projectNumber &&
                                                <Row>
                                                    <Col span={14} className="title text-right"><FormattedMessage id='modal.txDetails.common.project' defaultMessage='' /></Col>
                                                    <Col span={10}>
                                                        {soData.projectNumber + ' (' + soData.projectName + ')'}
                                                    </Col>
                                                </Row>
                                            }
                                            {customerSOShippedPackages[0].incotermName &&
                                                <Row>
                                                    <Col span={14} className="title text-right"><FormattedMessage id='package.packageDetail.incoterm' defaultMessage='' /></Col>
                                                    <Col span={10}>
                                                        {customerSOShippedPackages[0].incotermName}
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    } */}
                                </Col>
                            </Row>
                            <div>
                                {this.props.txColumns.length ? <CustomAgGrid
                                    columnDefs={columnDefs}
                                    onGridReady={(params) => {
                                        this.gridApi = params.api;
                                        this.gridColumnApi = params.columnApi;
                                        onDataRendered(params);
                                    }}

                                    defaultColDef={{
                                        flex:1,
                                        autoHeight: true,
                                        wrapText: true,
                                    }}
                                    onModelUpdated={onModelUpdated}
                                    onColumnResized={onColumnResized}
                                    rowData={customerSOPackageDetailsList || [{}]}
                                    gridStyle={{
                                        //width: '100%',
                                        height: '100%',
                                        marginBottom: '10px'
                                    }} /> : null}
                            </div>
                            <Row>
                                <Col span={12}>
                                    {shipmentData.remarksInternal &&
                                        <Col span={15} className="read-only-content pt10">
                                            <Row>
                                                <Col span={8} className="title">
                                                <FormattedMessage id='modal.txDetails.common.internalRemarks' defaultMessage='' />
                                        </Col>
                                                <Col span={16}>
                                                    {<CKEditor
                                                        type="inline"
                                                        className="description-textarea"
                                                        key={`${"shipmentData.remarksInternal"}`}
                                                        data={shippingInvoiceData.remarksInternal}
                                                        onInit={editor => {
                                                            this.editor = editor;
                                                        }}
                                                        onChange={(event) => {
                                                            const data = event.editor.getData();
                                                            this.props.updateShippingState({ shippingInvoiceData: { ...shippingInvoiceData, remarksInternal: data } });
                                                        }}
                                                        config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter internal remarks' }}
                                                    />}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                    {shipmentData.remarksCustomer &&
                                        <Col span={15} className="read-only-content">
                                            <Row>
                                                <Col span={8} className="title">
                                                <FormattedMessage id='modal.txDetails.common.customerRemarks' defaultMessage='' />
                                        </Col>
                                                <Col span={16}>
                                                    {<CKEditor
                                                        type="inline"
                                                        className="description-textarea"
                                                        key={`${"shipmentData.remarksCustomer"}`}
                                                        data={shippingInvoiceData.remarksCustomer}
                                                        onInit={editor => {
                                                            this.editor = editor;
                                                        }}
                                                        onChange={(event) => {
                                                            const data = event.editor.getData();
                                                            this.props.updateShippingState({ shippingInvoiceData: { ...shippingInvoiceData, remarksCustomer: data } });
                                                        }}
                                                        config={{ ...CUSTOM_CONFIGURATION, placeholder: 'Enter internal remarks' }}
                                                    />}
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Col>
                                <Col span={12}>
                                    <div className="amount-summary">
                                        <Row>
                                            <Col span={8}>
                                                <span className="title"><FormattedMessage id='customer.salesOrder.form.subTotal.label' defaultMessage='' /></span>
                                            </Col>
                                            <Col span={16}>
                                                {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                {fixedDecimalAndFormateNumber(this.state.subtotalWithoutDiscount)}
                                            </Col>
                                        </Row>
                                    </div>

                                    {/* {
                                false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                                    <div className="w-50">
                                        <span className="title">Tax</span>
                                    </div>
                                    <div className="w-50-default">
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {this.state.totalTax}
                                    </div>
                                </div>
                            } */}

                                    {this.state.totalDiscount === '0.00' || this.state.totalDiscount === 0 ? null : <div className="amount-summary">
                                        <Row>
                                            <Col span={8}>
                                                <span className="title"><FormattedMessage id='customer.salesOrder.form.totalDiscount.label' defaultMessage='' /></span>
                                            </Col>
                                            <Col span={16}>
                                                -{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                {fixedDecimalAndFormateNumber(this.state.totalDiscount)}
                                            </Col>
                                        </Row>
                                    </div>}

                                    {/* {
                                false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                                    <div className="w-50">
                                        <span className="title">Tax</span>
                                    </div>
                                    <div className="w-50-default">
                                        {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                        {this.state.totalTax}
                                    </div>
                                </div>
                            } */}

                                    {
                                        itemLevelTaxes.length === 0 ? null : itemLevelTaxes.map((taxObj, i) => {
                                            if (isPlaceOfSupplySameAsRelationship) {

                                            }
                                            return (
                                                (this.props.companyInfo || {}).countryName === 'India' ? (isPlaceOfSupplySameAsRelationship ? <div key={i}>
                                                    <div className="amount-summary">
                                                        <Row>
                                                            <Col span={8}>
                                                                <span
                                                                    className="title">CGST @ {taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                            </Col>
                                                            <Col span={16}>
                                                                {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="amount-summary">
                                                        <Row>
                                                            <Col span={8}>
                                                                <span
                                                                    className="title">SGST @ {taxObj.taxPercent / 2}% on {taxObj.subtotal} </span>
                                                            </Col>
                                                            <Col span={16}>
                                                                {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div> : <div key={i}>
                                                        <div className="amount-summary">
                                                            <Row>
                                                                <Col span={8}>
                                                                    <span
                                                                        className="title">IGST @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                                </Col>
                                                                <Col span={16}>
                                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                    {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>) :
                                                    <div key={i}>
                                                        <div className="amount-summary">
                                                            <Row>
                                                                <Col span={8}>
                                                                    <span
                                                                        className="title">{taxObj.taxName} @ {taxObj.taxPercent}% on {taxObj.subtotal} </span>
                                                                </Col>
                                                                <Col span={16}>
                                                                    {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                                    {(Number(taxObj.taxAmount || 0)).toFixed(2)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    }

                                    <div className="amount-summary">
                                        <Row>
                                            <Col span={8}>
                                                <span className="title"><FormattedMessage id='customer.salesOrder.form.totalAmount.label' defaultMessage='' /></span>
                                            </Col>
                                            <Col span={16}>
                                                {(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}
                                                {fixedDecimalAndFormateNumber(this.state.total)}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            {<CKEditor
                                type="inline"
                                className="add-footer-textarea"
                                key={"footer"}
                                data={shippingInvoiceData.footer}
                                onInit={editor => {
                                    this.editor = editor;
                                }}
                                onChange={(event) => {
                                    const data = event.editor.getData();
                                    this.props.updateShippingState({ shippingInvoiceData: { ...shippingInvoiceData, footer: data } });
                                }}
                                config={{ ...CUSTOM_CONFIGURATION_FOOTER, placeholder: 'Add Footer Here' }}
                            />}
                            <div className="button-group-action d-flex justify-space-between prl40">
                                <Button className="ant-btn-primary"
                                    onClick={() => {
                                        const modalData = {
                                            modalBody: 'Are you sure you want to save shipping invoice ?',
                                            handleSubmit: () => {
                                                const payload = shippingInvoiceData;
                                                payload.customerSOPackageDetailsList = customerSOPackageDetailsList;
                                                this.props.createShippingInvoice(payload, this.props);
                                                this.props.hideModal();
                                            }
                                        };
                                        this.props.showModal(modalData);

                                    }}
                                ><FormattedMessage id='button.save.label' defaultMessage='' /></Button>

                                <Button className="grey-button" onClick={() => {
                                    this.props.popModalFromStack();
                                }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );



    }

}

const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = <Fragment>
        {
            obj.streetAddress1
                ? <div className='billing-address'>{obj.streetAddress1}</div>
                : ''
        }
        {
            obj.streetAddress2
                ? <div className='billing-address'>{obj.streetAddress2}
                </div>
                : ''
        }
        {
            obj.cityName
                ? <div className='billing-address'>
                    {
                        `${obj.cityName || ''} ${obj.stateName ? ',' + obj.stateName : ''} ${obj.zipCode ? ',' + obj.zipCode : ''} ${obj.countryName ? ', ' + obj.countryName : ''}`
                    }
                </div>
                : ''
        }
        {
            additionInfo.workPhone
                ? <div className='billing-address'>
                    <FormattedMessage id='phone' defaultMessage='' /> - {additionInfo.workPhone}
                </div> :
                ''
        }
        {
            additionInfo.email
                ? <div className='billing-address'>
                    <FormattedMessage id='email' defaultMessage='' /> - {additionInfo.email}
                </div> :
                ''
        }
        {
            additionInfo.gstNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='gstIn' defaultMessage='' /> - {additionInfo.gstNumber}
                </div> :
                ''
        }
        {
            additionInfo.panNumber
                ? <div className='billing-address'>
                    <FormattedMessage id='pan' defaultMessage='' /> - {additionInfo.panNumber}
                </div> :
                ''
        }

    </Fragment>

    return fragmentObj;
}

const getContactNameFragment = (additionInfo) => {
    if (!additionInfo) return <Fragment />;
    let contactName = "";
    if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
    if (additionInfo.firstName) contactName += additionInfo.firstName;
    if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
    if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
    const fragmentObj = <Fragment>
        {
            (contactName && contactName.length)
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        }
    </Fragment>
    return fragmentObj;
}

export default ShipmentDetails;
