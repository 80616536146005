import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReportsComponent from '../../components/Reports';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../actions/commonActions';

class ShowReports extends Component {

    componentWillMount() {
        this.props.updateHeaderState({
            collapsed: false
        });
    }

    // componentDidMount() {
    //     const companyInfo = this.props.companyInfo || {};
    //     // const payload = {
    //     //     relationshipId: companyInfo.relationshipId
    //     // };
    // }

    render() {
        return <ReportsComponent {...this.props} />
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        isLoading: state.common.isLoading,
        permissions: state.auth.permissions
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowReports);
