import { Button, Checkbox, Input } from 'antd';
import React, {useState} from 'react';
import ImagePlaceholder from "../../../../../assets/images/img-placeholder-alpide.jpg";
import { FormattedMessage } from "react-intl";
import { getAWSFileURL } from '../../../../../utils';
import config from '../../../../../config/environmentConfig';
const { Search } = Input;

const ProductSelectModal = (props) => {
    const [searchText, setSearchText] = useState("");
    const [selectCategory, setSelectCategory] = useState((props.inventoryItems || []).length ? props.inventoryItems.map(item => item.itemId) : []);

    const handleCategorySelect = () => {
        props.updateDrawerState({
            itemName: props.itemName,
            itemId: props.itemId,
            inventoryItems: selectCategory.map((value) => ({
                itemId: value,
                relationshipId: props.relationshipId,
            }))
        });
        props.popModalFromStack();    
    };

    const searchProducts = () => {
        
    }

return (
  <>
    <div className="cat-search-wrapper">
        <Search
            placeholder="Search categories or subcategories..."
            value={props.searchCategories}
            onChange={(e) => {
                setSearchText(e.target.value);         
            }}  
        />
    </div>
    <div className="parent-category-list">
        <Checkbox.Group
            onChange={(e) => setSelectCategory(e)}
            value={selectCategory || []}
            width="100%"
        >
            {(searchText ? props.products?.filter((product) => {
                return product.itemName?.toLowerCase().includes(searchText?.toLowerCase());
            }) : (props.products || []))
            .filter((rowData) => rowData.itemId)
            .map((category) => (
                <>
                    <div key={category.itemId} className="radio-button-Wrapper">
                        <Checkbox 
                            key={category.itemId}
                            value={category.itemId}>
                            <div className="parent-category-sub-wrapper">
                                <div className="parent-category-details">
                                    <h2>{category.itemName}</h2>
                                </div>
                            </div>
                        </Checkbox >
                    </div>
                </>
            ))}
        </Checkbox.Group>
        <div className="custom-footer-parent-category">
            <Button type="primary"
                onClick={() => {
                    handleCategorySelect();
                }}  >
                <FormattedMessage id="button.select.label" defaultMessage="" />
            </Button>
        </div>
    </div>
  </>
);  
}

export default ProductSelectModal