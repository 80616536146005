import React, { useState } from 'react';
import { Button, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import './general.scss';
import { GRID_PREFERENCE_CONSTANTS } from '../../static/agGridConstants';

export function AddAndRemoveColumn(props) {
    const { txAvailableColumn = {}, txColumns = [], txMandatoryColumns = [] } = props;
    const [product, updateProduct] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) > -1);
    const [quantity, updateQuantity] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.QUANTITY) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.QUANTITY) > -1);
    const [quantityPacked] = useState(txColumns.indexOf('quantityPacked') > -1 || txMandatoryColumns.indexOf('quantityPacked') > -1);
    const [qtyToPack] = useState(txColumns.indexOf('qtyToPack') > -1 || txMandatoryColumns.indexOf('qtyToPack') > -1);
    const [amount, updateAmount] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.AMOUNT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.AMOUNT) > -1);
    const [rate, updateRate] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.RATE) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.RATE) > -1);
    const [description, updateDescription] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1);
    const [tax, updateTax] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) > -1);
    const [uom, updateUom] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) > -1);
    const [basePrice, updateBasePrice] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) > -1);
    const [specialDiscount, updateSpecialDiscount] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) > -1);
    const [discount, updateDiscount] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) > -1);
    const [hsn, updateHsn] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) > -1);
    const [comment, updateComment] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) > -1);
    const [stockNumber, updateStockNumber] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) > -1);
    const [partNumber, updatePartNumber] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) > -1);
    const [origin, updateOrigin] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) > -1);
    const [hsCode, updateHSCode] = useState(txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) > -1 || txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) > -1);
    const [itemName, updateItemName] = useState(txColumns.indexOf('itemName') > -1 || txMandatoryColumns.indexOf('itemName') > -1);
    const [variants, updateVariants] = useState(txColumns.indexOf('variants') > -1 || txMandatoryColumns.indexOf('variants') > -1);
    const [valuation] = useState(txColumns.indexOf('valuation') > -1 || txMandatoryColumns.indexOf('valuation') > -1);
    const [sku, updateSku] = useState(txColumns.indexOf('sku') > -1 || txMandatoryColumns.indexOf('sku') > -1);
    const [categoryName, updateCategoryName] = useState(txColumns.indexOf('categoryName') > -1 || txMandatoryColumns.indexOf('categoryName') > -1);
    const [hsnCodeRate, updateHsnCodeRate] = useState(txColumns.indexOf('hsnCodeRate') > -1 || txMandatoryColumns.indexOf('hsnCodeRate') > -1);
    const [qtyCurrentStock, updateqQtyCurrentStock] = useState(txColumns.indexOf('qtyCurrentStock') > -1 || txMandatoryColumns.indexOf('qtyCurrentStock') > -1);
    const [warehouseName, updateWarehouseName] = useState(txColumns.indexOf('warehouseName') > -1 || txMandatoryColumns.indexOf('warehouseName') > -1);
    const [firstName, updateFirstName] = useState(txColumns.indexOf('firstName') > -1 || txMandatoryColumns.indexOf('firstName') > -1);
    const [lastName, updateLastName] = useState(txColumns.indexOf('lastName') > -1 || txMandatoryColumns.indexOf('lastName') > -1);
    const [jobTitleName, updateJobTitleName] = useState(txColumns.indexOf('jobTitleName') > -1 || txMandatoryColumns.indexOf('jobTitleName') > -1);
    const [managerName, updateManagerName] = useState(txColumns.indexOf('managerName') > -1 || txMandatoryColumns.indexOf('managerName') > -1);
    const [hierarchyName, updateHierarchyName] = useState(txColumns.indexOf('hierarchyName') > -1 || txMandatoryColumns.indexOf('hierarchyName') > -1);
    const [hierarchyLevel, updateHierarchyLevel] = useState(txColumns.indexOf('hierarchyLevel') > -1 || txMandatoryColumns.indexOf('hierarchyLevel') > -1);
    const [workPhone, updateWorkPhone] = useState(txColumns.indexOf('workPhone') > -1 || txMandatoryColumns.indexOf('workPhone') > -1);
    const [departmentName, updateDepartmentName] = useState(txColumns.indexOf('departmentName') > -1 || txMandatoryColumns.indexOf('departmentName') > -1);
    const [empType, updateEmpType] = useState(txColumns.indexOf('empType') > -1 || txMandatoryColumns.indexOf('empType') > -1);
    const [jobRoleName, updateJobRoleName] = useState(txColumns.indexOf('jobRoleName') > -1 || txMandatoryColumns.indexOf('jobRoleName') > -1);
    const [employeeId, updateEmployeeId] = useState(txColumns.indexOf('employeeId') > -1 || txMandatoryColumns.indexOf('employeeId') > -1);
    const [employeeName, updateEmployeeName] = useState(txColumns.indexOf('employeeName') > -1 || txMandatoryColumns.indexOf('employeeName') > -1);
    const [contact, updateContact] = useState(txColumns.indexOf('contact') > -1 || txMandatoryColumns.indexOf('contact') > -1);
    const [department, updateDepartment] = useState(txColumns.indexOf('department') > -1 || txMandatoryColumns.indexOf('department') > -1);
    const [designation, updateDesignation] = useState(txColumns.indexOf('designation') > -1 || txMandatoryColumns.indexOf('designation') > -1);
    const [jobRole, updateJobRole] = useState(txColumns.indexOf('jobRole') > -1 || txMandatoryColumns.indexOf('jobRole') > -1);
    const [team, updateTeam] = useState(txColumns.indexOf('team') > -1 || txMandatoryColumns.indexOf('team') > -1);
    const [employeeType, updateEmployeeType] = useState(txColumns.indexOf('employeeType') > -1 || txMandatoryColumns.indexOf('employeeType') > -1);
    const [hierarchy, updateHierarchy] = useState(txColumns.indexOf('hierarchy') > -1 || txMandatoryColumns.indexOf('hierarchy') > -1);



    const savePreferences = () => {
        let columnList = [];
        if (product) columnList.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        if (quantity) columnList.push(GRID_PREFERENCE_CONSTANTS.QUANTITY);
        if (rate) columnList.push(GRID_PREFERENCE_CONSTANTS.RATE);
        if (amount) columnList.push(GRID_PREFERENCE_CONSTANTS.AMOUNT);
        if (basePrice) columnList.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        if (description) columnList.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        if (uom) columnList.push(GRID_PREFERENCE_CONSTANTS.UOM);
        if (tax) columnList.push(GRID_PREFERENCE_CONSTANTS.TAX);
        if (specialDiscount) columnList.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        if (discount) columnList.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        if (hsn) columnList.push(GRID_PREFERENCE_CONSTANTS.HSN);
        if (comment) columnList.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        if (stockNumber) columnList.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        if (partNumber) columnList.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        if (origin) columnList.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        if (hsCode) columnList.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        if (warehouseName) columnList.push('warehouseName');
        if (itemName) columnList.push('itemName');
        if (variants) columnList.push('variants');
        if (valuation) columnList.push('valuation');
        if (sku) columnList.push('sku');
        if (categoryName) columnList.push('categoryName');
        if (qtyCurrentStock) columnList.push('qtyCurrentStock');

        if (firstName) columnList.push('firstName');
        if (lastName) columnList.push('lastName');
        if (jobTitleName) columnList.push('jobTitleName');
        if (managerName) columnList.push('managerName');
        if (hierarchyName) columnList.push('hierarchyName');
        if (hierarchyLevel) columnList.push('hierarchyLevel');
        if (workPhone) columnList.push('workPhone');
        if (departmentName) columnList.push('departmentName');
        if (empType) columnList.push('empType');
        if (jobRoleName) columnList.push('jobRoleName');
        if (employeeId) columnList.push('employeeId');
        if (employeeName) columnList.push('employeeName');
        if (contact) columnList.push('contact');
        if (department) columnList.push('department');
        if (designation) columnList.push('designation');
        if (jobRole) columnList.push('jobRole');
        if (team) columnList.push('team');
        if (employeeType) columnList.push('employeeType');
        if (hierarchy) columnList.push('hierarchy');
      

        let data = props.txPreferences || {};
        data.relationshipId = (props.companyInfo || {}).relationshipId;
        data.clientUserAccountId = (props.userInfo || {}).userId;
        if (data.tablePreference) {
            let obj = JSON.parse(data.tablePreference);
            obj.columnList = columnList;
            data.tablePreference = JSON.stringify(obj);
        } else {
            data.tablePreference = JSON.stringify({ columnList: columnList, columnSetting: props.txColumnSetting });
        }
        props.updateState({ txColumns: columnList, tableDataReRendered: true });
        data.preferenceId = props.preferenceId;
        data.gridPreference = props.gridPreference;
        props.saveUserPrefrences(data);


    }
    return (
        <>
        <div className="add-remove-column">
            {txAvailableColumn.itemName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('itemName') > -1}
                    onChange={(e)=>{
                        updateItemName(e.target.checked);
                    }}
                        checked={itemName} > Product</Checkbox>
                </div>
            }
            {txAvailableColumn.product && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) > -1}
                    checked={product} onChange={(e) => {
                        updateProduct(e.target.checked);
                    }}> Product</Checkbox>
            </div>
            }
            {txAvailableColumn.quantity && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.QUANTITY) > -1}
                    checked={quantity} onChange={(e) => {
                        updateQuantity(e.target.checked);
                    }}> Quantity</Checkbox>
            </div>
            }
            {txAvailableColumn.rate && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.RATE) > -1}
                    checked={rate} onChange={(e) => {
                        updateRate(e.target.checked);
                    }}> Rate</Checkbox>
            </div>
            }
            {txAvailableColumn.amount && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.AMOUNT) > -1}
                    checked={amount} onChange={(e) => {
                        updateAmount(e.target.checked);
                    }}> Amount</Checkbox>
            </div>
            }
            {txAvailableColumn.description && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) > -1}
                    checked={description} onChange={(e) => {
                        updateDescription(e.target.checked);
                    }}> Description</Checkbox>
            </div>
            }
            {txAvailableColumn.uom &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) > -1}
                        checked={uom} onChange={(e) => {
                            updateUom(e.target.checked)
                        }}> UOM</Checkbox>
                </div>
            }
            {txAvailableColumn.hsn  &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) > -1}
                        checked={hsn} onChange={(e) => {
                            updateHsn(e.target.checked);
                        }}> HSN/SAC</Checkbox>
                </div>
            }
            {txAvailableColumn.specialDiscount &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) > -1}
                        checked={specialDiscount} onChange={(e) => {
                            updateSpecialDiscount(e.target.checked)
                        }}> Trade Discount (%)</Checkbox>
                </div>
            }
            {txAvailableColumn.basePrice && <div className="mb5">
                <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) > -1}
                    checked={basePrice} onChange={(e) => {
                        updateBasePrice(e.target.checked);
                    }}> MRP</Checkbox>
            </div>
            }
            {txAvailableColumn.discount &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) > -1}
                        checked={discount} onChange={(e) => {
                            updateDiscount(e.target.checked)
                        }}> Discount (%)</Checkbox>
                </div>
            }
            {txAvailableColumn.tax &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) > -1}
                        checked={tax} onChange={(e) => {
                            updateTax(e.target.checked);
                        }}> Tax (%)</Checkbox>
                </div>
            }
            {txAvailableColumn.comment &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) > -1}
                        checked={comment} onChange={(e) => {
                            updateComment(e.target.checked);
                        }}> Warehouse</Checkbox>
                </div>
            }
            {txAvailableColumn.stockNumber &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) > -1}
                        checked={stockNumber} onChange={(e) => {
                            updateStockNumber(e.target.checked);
                        }}> Stock #</Checkbox>
                </div>
            }
            {txAvailableColumn.partNumber &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) > -1}
                        checked={partNumber} onChange={(e) => {
                            updatePartNumber(e.target.checked);
                        }}> Part #</Checkbox>
                </div>
            }
            {txAvailableColumn.origin &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) > -1}
                        checked={origin} onChange={(e) => {
                            updateOrigin(e.target.checked);
                        }}> Origin</Checkbox>
                </div>
            }
            {txAvailableColumn.hsCode &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) > -1}
                        checked={hsCode} onChange={(e) => {
                            updateHSCode(e.target.checked);
                        }}> HS Code</Checkbox>
                </div>
            }

            {/* {txAvailableColumn.itemName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('itemName') > -1}
                        checked={itemName} onChange={(e) => {
                            updateItemName(e.target.checked);
                        }}> Item Name</Checkbox>
                </div>
            } */}

            {txAvailableColumn.variants &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('variants') > -1}
                        checked={variants} onChange={(e) => {
                            updateVariants(e.target.checked);
                        }}> Variants</Checkbox>
                </div>
            }

            {txAvailableColumn.valuation &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('valuation') > -1}
                        checked={valuation} onChange={(e) => {

                        }}> Valuation</Checkbox>
                </div>
            }

            {txAvailableColumn.sku &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('sku') > -1}
                        checked={sku} onChange={(e) => {
                            updateSku(e.target.checked)
                        }}> SKU</Checkbox>
                </div>
            }

            {txAvailableColumn.categoryName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('categoryName') > -1}
                        checked={categoryName} onChange={(e) => {
                            updateCategoryName(e.target.checked)
                        }}> Category Name</Checkbox>
                </div>
            }

            {txAvailableColumn.hsnCodeRate &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('hsnCodeRate') > -1}
                        checked={hsnCodeRate} onChange={(e) => {
                            updateHsnCodeRate(e.target.checked)
                        }}> HSN</Checkbox>
                </div>
            }

            {txAvailableColumn.qtyCurrentStock &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('qtyCurrentStock') > -1}
                        checked={qtyCurrentStock} onChange={(e) => {
                            updateqQtyCurrentStock(e.target.checked)
                        }}> QTY Current Stock</Checkbox>
                </div>
            }

            {txAvailableColumn.warehouseName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('warehouseName') > -1}
                        checked={warehouseName} onChange={(e) => {
                            updateWarehouseName(e.target.checked)
                        }}> Warehouse</Checkbox>
                </div>
            }

            {txAvailableColumn.firstName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('firstName') > -1}
                        checked={firstName} onChange={(e) => {
                            updateFirstName(e.target.checked)
                        }}> First Name</Checkbox>
                </div>
            }

            {txAvailableColumn.lastName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('lastName') > -1}
                        checked={lastName} onChange={(e) => {
                            updateLastName(e.target.checked)
                        }}> Last Name</Checkbox>
                </div>
            }

            {txAvailableColumn.jobTitleName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('jobTitleName') > -1}
                        checked={jobTitleName} onChange={(e) => {
                            updateJobTitleName(e.target.checked)
                        }}> Job Title Name</Checkbox>
                </div>
            }

            {txAvailableColumn.managerName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('managerName') > -1}
                        checked={managerName} onChange={(e) => {
                            updateManagerName(e.target.checked)
                        }}> Manager Name</Checkbox>
                </div>
            }

            {txAvailableColumn.hierarchyName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('hierarchyName') > -1}
                        checked={hierarchyName} onChange={(e) => {
                            updateHierarchyName(e.target.checked)
                        }}> Hierarchy Name</Checkbox>
                </div>
            }

            {txAvailableColumn.hierarchyLevel &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('hierarchyLevel') > -1}
                        checked={hierarchyLevel} onChange={(e) => {
                            updateHierarchyLevel(e.target.checked)
                        }}> Hierarchy Level</Checkbox>
                </div>
            }

            {txAvailableColumn.workPhone &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('workPhone') > -1}
                        checked={workPhone} onChange={(e) => {
                            updateWorkPhone(e.target.checked)
                        }}> Work Phone</Checkbox>
                </div>
            }
            {txAvailableColumn.departmentName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('departmentName') > -1}
                        checked={departmentName} onChange={(e) => {
                            updateDepartmentName(e.target.checked)
                        }}> Department Name</Checkbox>
                </div>
            }
             {txAvailableColumn.empType &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('empType') > -1}
                        checked={empType} onChange={(e) => {
                            updateEmpType(e.target.checked)
                        }}> Employee Type</Checkbox>
                </div>
            }
             {txAvailableColumn.quantityPacked &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('quantityPacked') > -1}
                        checked={quantityPacked} > Quantity Packed</Checkbox>
                </div>
            }
             {txAvailableColumn.qtyToPack &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('qtyToPack') > -1}
                        checked={qtyToPack} >QTY To Pack</Checkbox>
                </div>
            }
            {txAvailableColumn.jobRoleName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('jobRoleName') > -1}
                        checked={jobRoleName}  onChange={(e) => {
                            updateJobRoleName(e.target.checked)
                        }}>Job Role</Checkbox>
                </div>
            }
             {txAvailableColumn.employeeId &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('employeeId') > -1}
                        checked={employeeId}  onChange={(e) => {
                            updateEmployeeId(e.target.checked)
                        }}>Employee Id</Checkbox>
                </div>
            }
             {txAvailableColumn.employeeName &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('employeeName') > -1}
                        checked={employeeName}  onChange={(e) => {
                            updateEmployeeName(e.target.checked)
                        }}>Employee Name</Checkbox>
                </div>
            }
             {txAvailableColumn.contact &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('contact') > -1}
                        checked={contact}  onChange={(e) => {
                            updateContact(e.target.checked)
                        }}>Contact</Checkbox>
                </div>
            }
             {txAvailableColumn.department &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('department') > -1}
                        checked={department}  onChange={(e) => {
                            updateDepartment(e.target.checked)
                        }}>Department</Checkbox>
                </div>
            }
             {txAvailableColumn.designation &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('designation') > -1}
                        checked={designation}  onChange={(e) => {
                            updateDesignation(e.target.checked)
                        }}>Job Role</Checkbox>
                </div>
            }
             {txAvailableColumn.jobRole &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('jobRole') > -1}
                        checked={jobRole}  onChange={(e) => {
                            updateJobRole(e.target.checked)
                        }}>Job Role</Checkbox>
                </div>
            }
             {txAvailableColumn.team &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('team') > -1}
                        checked={team}  onChange={(e) => {
                            updateTeam(e.target.checked)
                        }}>Team</Checkbox>
                </div>
            }
               {txAvailableColumn.employeeType &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('employeeType') > -1}
                        checked={employeeType}  onChange={(e) => {
                            updateEmployeeType(e.target.checked)
                        }}>Employee Type</Checkbox>
                </div>
            }
               {txAvailableColumn.hierarchy &&
                <div className="mb5">
                    <Checkbox disabled={txMandatoryColumns.indexOf('hierarchy') > -1}
                        checked={hierarchy}  onChange={(e) => {
                            updateHierarchy(e.target.checked)
                        }}>Hierarchy</Checkbox>
                </div>
            }
             
            {/* <div className="button-action">
                <Button type="primary" onClick={() => {
                    savePreferences();
                }}><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
            </div> */}

        </div>
        <div className="button-action">
                <Button type="primary" onClick={() => {
                    savePreferences();
                    props.updateState({ showColPop: false });
                } }><FormattedMessage id='button.save.label' defaultMessage='' /></Button>
            </div>
        </>
    );

}

