import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManufacturerListingComp from '../../../../../components/inventory/extras/manufacturer/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../../utils';
import { showModal, pushModalToStack, hideModal, popModalFromStack } from '../../../../../actions/commonActions';
import { fetchManufacturer, addManufacturer, deleteManufacturer } from '../../../../drawer/inventory/action';
import { updateManufacturer } from './action';

class ManufacturerListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 100,
      pageNumber: 1,
      isDataRendered: false
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1,
      pageSize: 100
    };
    fetchDataIfNeeded('fetchManufacturer', 'manufacturers', this.props, payload);
    // fetchPaginationDataIfNeeded(
    //   "fetchManufacture",
    //   "manufactureList",
    //   this.props,
    //   payload
    // );
  }

  render() {
    return (
      <ManufacturerListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    manufacturers: state.inventory.manufacturers,
    isLoading: state.common.isLoading,
    permissions: state.auth.permissions
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchManufacturer,
  updateManufacturer,
  addManufacturer,
  deleteManufacturer,
  showModal,
  popModalFromStack,
  pushModalToStack,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturerListing);
