/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
//import { AgGridReact } from 'ag-grid-react';
import { Checkbox, Input, Menu, Dropdown, Pagination, Skeleton, Button } from "antd";
import { MoreOutlined, DeleteOutlined, CloseCircleOutlined, CheckCircleOutlined, CaretDownOutlined , DownloadOutlined, FileExcelOutlined, FilePdfOutlined, } from '@ant-design/icons';
import * as find from 'lodash.find';
//import Tile from '../../tiles/tiles';
import { fetchPaginationDataIfNeeded, exportExcel, sortTable } from '../../../utils'
//import ImportCustomer from '../../../containers/modal/modalBody/customer/Import';
//import OpenOrder from '../../../containers/modal/modalBody/customer/OpenOrders';
//import LedgerList from '../../../containers/modal/modalBody/common/ledger';
//import TotalOrders from '../../../containers/modal/modalBody/customer/TotalOrders'
//import PaidInvoices from '../../../containers/modal/modalBody/customer/PaidInvoices';
//import UnpaidInvoices from '../../../containers/modal/modalBody/customer/UnpaidInvoices';
import './index.scss';
// import ShowContact from './../../../containers/customer/Listing/ShowConact';
// import ShowAddress from './../../../containers/customer/Listing/ShowAddress';
// import LogActivity from './../../../containers/customer/Listing/LogActivity';
// import LogCommunication from './../../../containers/customer/Listing/LogCommunication';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import * as debounce from 'lodash.debounce';
//import { AG_GRID_CLASS_CONSTANTS } from '../../../static/cssClassConstants';
// import {  MODULE_CODED_VALUES, PERMISSION_VALUES } from '../../../static/constants';
//import { AG_GRID_CONSTANTS } from '../../../static/agGridConstants';
//import { AddAndRemoveCustomerListingColumn } from '../../general/AddAndRemoveCustomerListingColumn';
import UnpaidInvoices from './../../../containers/modal/modalBody/schoolManagement/UnpaidInvoices';
import CustomerRegistration from './../../../containers/modal/modalBody/schoolManagement/CustomerRegistration';
import PageBreadcrumb from "../../PageBreadcrumb";
// import download from 'downloadjs';


const { Search } = Input;

// const getRowHeight = (params) => {
//     let height = 35;
//     let calcHeight = 1;
//     if (params.data.boLocationList) {
//         const address = find(params.data.boLocationList, { isDefault: 1, locationType: "ShippingAddress" });
//         if (address) {
//             calcHeight = (address.streetAddress1 || address.streetAddress2) ? calcHeight + 1 : calcHeight;
//             calcHeight = (address.stateName || address.cityName || address.zipCode) ? calcHeight + 1 : calcHeight;
//         } else {
//             calcHeight = 2;
//         }
//     }
//     return height * calcHeight;
// }



// const agGridStyle = {
//     height: '100%',
//     width: '100%'
// };

const FamilyListingComp = (props) => {
    // const [isAllChecked, toggleAllChecked] = useState(false);
    const { pageNumber, customers, customerCount, companyInfo, updateState, searchedText, selectedItems=[], pageSize, dir=0  } = props;


    // const handleNewAddressAddition = (customerData) => {
    //     let payload = {};
    //     let formData = {
    //         locationType: CONSTANTS.BILLING_ADDRESS,
    //         customerId: (customerData || {}).customerId,
    //         pageNumber: pageNumber || 1,
    //     }
    //     payload.formData = formData;
    //     const { showModal, companyInfo } = props;
    //     formData.relationshipId = companyInfo.relationshipId;
    //     const data = {
    //         title: <FormattedMessage id='addItem.text.billingAddress' defaultMessage='' />,
    //         formData,
    //         hideFooter: true,
    //         modalData: {
    //             modalType: MODAL_TYPE.BILLING_ADDRESS,
    //             data: payload,
    //         },
    //         handleSubmit: (formData = {}) => {
    //             data.formData.submittedOnce = true;
    //             showModal(data);
    //         }
    //     };
    //     props.pushModalToStack(data);
    // }

    // const handleNewContactAddition = (customerData) => {
    //     const { showModal, hideModal, companyInfo } = props;
    //     let payload = {};
    //     let formData = {
    //         customerId: (customerData || {}).customerId,
    //         pageNumber: pageNumber || 1,
    //         relationshipId: companyInfo.relationshipId
    //     }
    //     payload.formData = formData;
    //     const data = {
    //         title: <FormattedMessage id='addItem.text.contact' defaultMessage='' />,
    //         formData,
    //         hideFooter: true,
    //         modalData: {
    //             modalType: MODAL_TYPE.CONTACT,
    //             data: payload,

    //         },
    //         handleSubmit: (formData = {}) => {
    //             data.formData.submittedOnce = true;
    //             setTimeout(() => {
    //                 hideModal(data);
    //                 props.fetchCustomers({ relationshipId: companyInfo.relationshipId })
    //             }, 2000);
    //         }
    //     };
    //     showModal(data);
    // }

    // // const handleNewEmployeeAddition = (customerData) => {
    // //     let payload = {};
    // //     let formData = {
    // //         firstName: '',
    // //         customerId: (customerData || {}).customerId
    // //     }
    // //     payload.formData = formData;
    // //     const { showModal, hideModal, companyInfo } = props;
    // //     formData.relationshipId = companyInfo.relationshipId;
    // //     const data = {
    // //         title: <FormattedMessage id='addItem.text.employee' defaultMessage='' />,
    // //         formData,
    // //         hideFooter: true,
    // //         modalData: {
    // //             modalType: MODAL_TYPE.EMPLOYEE,
    // //             data: payload,

    // //         },
    // //         handleSubmit: (formData = {}) => {
    // //             data.formData.submittedOnce = true;
    // //             showModal(data);
    // //         }
    // //     };
    // //     showModal(data);
    // // }

    // // const addContact = (customerData) => {
    // //     const modalData = {
    // //         title: <React.Fragment>
    // //             <div className='ant-modal-title'>Contact</div>
    // //         </React.Fragment>,
    // //         modalBody: <ShowContact customerId={customerData.customerId} />,
    // //         width: 1100,
    // //         hideFooter: true
    // //     };
    // //     props.pushModalToStack(modalData);
    // // }

    // const getCustomerDetailsCell = (data) => {
    //     // let companyInfo = "<Link to='details' class='company-name'>" + data.companyName + "</Link><br>";
    //     // if (data.boLocationList && data.boLocationList.length) {
    //     //     const address = find(data.boLocationList, { isDefault: 1, locationType: "ShippingAddress" }) || {};
    //     //     if (address.streetAddress1) {
    //     //         companyInfo = companyInfo + (address.streetAddress1 || "")
    //     //             + "   <span>" + (address.streetAddress2 || "")
    //     //             + "</span><br><span>"
    //     //             + (address.cityName || "")
    //     //             + "</span> <span>" + (address.stateName || "") + "</span><br>"
    //     //             + " <span>" + (address.zipCode || "") + "</span>"
    //     //     }
    //     // }
    //     const address = find(data.boLocationList, { isDefault: 1, locationType: "BillingAddress" }) || {};
    //     return address.streetAddress1 ? <div>
    //         <Link
    //             to={
    //                 {
    //                     pathname: '/admin/customers/details',
    //                     state: {
    //                         customerId: data.customerId,
    //                         customerName: data.companyName,
    //                         customer: data,
    //                     }
    //                 }}
    //             className='company-name'
    //         >{data.companyName}</Link>
          
    //         <div className="light-font">{address.streetAddress1}</div>
    //         <div className="light-font">{address.streetAddress2}</div>
    //         <div className="light-font">
    //             <span className="pr5">{address.cityName || ""} </span>
    //             <span>{address.stateName || ""} - </span>
    //             <span>{address.zipCode || ""}</span>
    //         </div>
    //     </div> : <div>
    //     <Link
    //             to={
    //                 {
    //                     pathName: '/admin/customers/details',
    //                     state: {
    //                         customerId: data.customerId,
    //                         customerName: data.companyName,
    //                         customer: data,
    //                     }
    //                 }}
    //             className='company-name'
    //         >{data.companyName}</Link>
    //             <div>

    //             </div>
    //             <div>
    //                 {/* <Link onClick={() => handleNewAddressAddition(data)}> Add Address</Link>  */}
    //                 <div className="cursor-pointer" onClick={() => handleNewAddressAddition(data)}><FormattedMessage id='addAddress.text' defaultMessage='' /></div>
    //             </div>
    //         </div>;
    // };


    // const getCustomerContactCell = (data) => {
    //     const contact = (data.boContactList && find(data.boContactList, { isPrimaryContact: 1 }));
    //     return contact ? <div>
    //         <div>{(contact.salutationName || "")
    //             + " " + (contact.firstName || "")
    //             + " " + (contact.lastName || "")} </div>
    //         <div>{(contact.cellPhone || '') && "Ph: " + contact.cellPhone} </div>
    //     </div>
    //         :
    //         <div>
    //             <Link onClick={() => handleNewContactAddition(data)}> <FormattedMessage id='addContact.text' defaultMessage='' /></Link>
    //         </div>

    // }

    // const tilesData = [{
    //     footer: <FormattedMessage id='customer.listing.tiles.tatalOrders.label' defaultMessage='' />,
    //     count: customerOpenOrderCount,
    //     type: CUSTOMER_LISTING_PAGE.TOTAL_ORDERS,
    //     icon: 'fa fa-bullseye',
    //     color: 'darkGray-tile'
    // },
    // {
    //     footer: <FormattedMessage id='customer.listing.tiles.openSalesOrder.label' defaultMessage='' />,
    //     count: customerOpenSOCount,
    //     icon: 'fa fa-file-text-o',
    //     type: CUSTOMER_LISTING_PAGE.OPEN_SO,
    //     color: 'green-tile'
    // },
    // {
    //     footer: <FormattedMessage id='customer.listing.tiles.unpaidBalance.label' defaultMessage='' />,
    //     count: customerUnpaidBalance,
    //     icon: companyInfo.currencyIcon,
    //     type: CUSTOMER_LISTING_PAGE.UNPAID_BALANCE,
    //     color: 'blue-tile'
    // },
    // {
    //     footer: <FormattedMessage id='customer.listing.tiles.salesRevenue.label' defaultMessage='' />,
    //     count: customerSalesRevenue,
    //     icon: companyInfo.currencyIcon,
    //     type: CUSTOMER_LISTING_PAGE.SALES_REVENUE,
    //     color: 'magenta-tile'
    // }];

    // const newCustomer = (customerData) => {
    //     props.updateHeaderState({
    //         customer: {
    //             ...props.customer,
    //             customerDrawerVisible: true,
    //         }
    //     })
    // }

    // const editCustomer = (customerData) => {
    //     props.updateHeaderState({
    //         customer: {
    //             ...props.customer,
    //             customerDrawerVisible: true,
    //             isUpdate: true,
    //             customerData: customerData
    //         }
    //     })
    // }

    // const cloneCustomer = (customerData) => {
    //     props.updateHeaderState({
    //         customer: {
    //             ...props.customer,
    //             customerDrawerVisible: true,
    //             isClone: true,
    //             customerData: customerData
    //         }
    //     })
    // }

    // const getActionMenuPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedModules = {
    //         firstSection: {
    //             salesInquiryCreate: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_QUOTE, PERMISSION_VALUES.CREATE),
    //             salesOrderCreate: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER, PERMISSION_VALUES.CREATE),
    //             salesInvoiceCreate: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_INVOICE, PERMISSION_VALUES.CREATE),
    //             creditMemoCreate: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CREDIT_MEMO, PERMISSION_VALUES.CREATE),
    //             customerPayment: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_PAYMENT, PERMISSION_VALUES.CREATE),
    //             rfqCreate: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.RFQ, PERMISSION_VALUES.CREATE),
    //             salesQuoteCreate: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_QUOTE, PERMISSION_VALUES.CREATE),
    //         },
    //         secondSection: {
    //             createCustomer: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMERS, PERMISSION_VALUES.CREATE),
    //             editCustomer: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMERS, PERMISSION_VALUES.UPDATE),
    //             cloneCustomer: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMERS, PERMISSION_VALUES.CREATE),
    //             deleteCustomer: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMERS, PERMISSION_VALUES.DELETE)
    //         },
    //         thirdSection: {
    //             showAddress: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_ADDRESS, PERMISSION_VALUES.READ),
    //             showContact: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_CONTACTS, PERMISSION_VALUES.READ)
    //         },
    //         fourthSection: {
    //             logActivity: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_ACTIVITY, PERMISSION_VALUES.CREATE),
    //             logCommunication: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.CUSTOMER_COMMUNICATION, PERMISSION_VALUES.CREATE)
    //         }
    //     };
    //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
    //         if (permittedModules.firstSection[moduleName] === false) {
    //             delete permittedModules.firstSection[moduleName];
    //         }
    //     })
    //     Object.keys(permittedModules.secondSection).forEach((moduleName) => {
    //         if (permittedModules.secondSection[moduleName] === false) {
    //             delete permittedModules.secondSection[moduleName];
    //         }
    //     })
    //     Object.keys(permittedModules.thirdSection).forEach((moduleName) => {
    //         if (permittedModules.thirdSection[moduleName] === false) {
    //             delete permittedModules.thirdSection[moduleName];
    //         }
    //     })
    //     Object.keys(permittedModules.fourthSection).forEach((moduleName) => {
    //         if (permittedModules.fourthSection[moduleName] === false) {
    //             delete permittedModules.fourthSection[moduleName];
    //         }
    //     })
    //     return permittedModules;
    // }

    // const actionMenu = (item, permittedModules) => {
    //     return (
    //         <Menu className="cell-action-dropdown">
    //             {/* {
    //                 permittedModules.firstSection.salesInquiryCreate
    //                     ? (
    //                         <Menu.Item key="0">
    //                             <i className="fa fa-file-text-o" />
    //                             <Link to={
    //                                 {
    //                                     pathname: 'sales-inquiry-create',
    //                                     state: {
    //                                         customerId: param.data.customerId,
    //                                         customer: param.data,
    //                                         customerName: param.data.companyName,
    //                                         pageNumber: props.pageNumber
    //                                     }
    //                                 }}><FormattedMessage id='createInquiry.text' defaultMessage='' /></Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             } */}
    //             {/* {
    //                 permittedModules.firstSection.rfqCreate
    //                     ? (
    //                         <Menu.Item key="1">
    //                             <i className="fa fa-file-text-o" />
    //                             <Link to={
    //                                 {
    //                                     pathname: 'rfq-create',
    //                                     state: {
    //                                         customerId: param.data.customerId,
    //                                         customer: param.data,
    //                                         customerName: param.data.companyName,
    //                                         pageNumber: props.pageNumber
    //                                     }
    //                                 }}>Create RFQ</Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             } */}
    //             {/* {
    //                 permittedModules.firstSection.salesQuoteCreate
    //                     ? (
    //                         <Menu.Item key="2">
    //                             <i className="fa fa-file-text-o" />
    //                             <Link to={
    //                                 {
    //                                     pathname: 'create-sales-quote',
    //                                     state: {
    //                                         customerId: param.data.customerId,
    //                                         customerName: param.data.companyName,
    //                                         pageNumber: props.pageNumber
    //                                     }
    //                                 }}><FormattedMessage id='createInquiry.text' defaultMessage='' /></Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             } */}
    //             {/* {
    //                 permittedModules.firstSection.salesOrderCreate
    //                     ? (
    //                         <Menu.Item key="3">
    //                             <i className="fa fa-file-text-o" />
    //                             <Link to={
    //                                 {
    //                                     pathname: 'so-create',
    //                                     state: {
    //                                         customer: param.data,
    //                                         customerId: param.data.customerId,
    //                                         customerName: param.data.companyName,
    //                                         pageNumber: props.pageNumber
    //                                     }
    //                                 }}><FormattedMessage id='createSalesOrder.text' defaultMessage='' /></Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             } */}
    //             {/* {
    //                 permittedModules.firstSection.salesInvoiceCreate
    //                     ? (
    //                         <Menu.Item key="4">
    //                             <i className="fa fa-file-o" />
    //                             <Link to={
    //                                 {
    //                                     pathname: 'sales-invoice',
    //                                     state: {
    //                                         customer: param.data,
    //                                         customerId: param.data.customerId,
    //                                         customerName: param.data.companyName,
    //                                         pageNumber: props.pageNumber
    //                                     }
    //                                 }}><FormattedMessage id='createInvoice.text' defaultMessage='' /></Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }
    //             {
    //                 permittedModules.firstSection.creditMemoCreate
    //                     ? (
    //                         <Menu.Item key="5">
    //                             <i className="fa fa-file-text-o" />
    //                             <Link
    //                                 to={
    //                                     {
    //                                         pathname: 'create-credit-memo',
    //                                         state: {
    //                                             customer: param.data,
    //                                             customerId: param.data.customerId,
    //                                             customerName: param.data.companyName,
    //                                             pageNumber: props.pageNumber
    //                                         }
    //                                     }}
    //                             >
    //                                 <FormattedMessage id='createCreditMemo.text' defaultMessage='' />
    //                             </Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             } */}
    //             {/* {
    //                 permittedModules.firstSection.customerPayment
    //                     ? (
    //                         <Menu.Item key="6">
    //                             <i className="fa fa-money" />
    //                             <Link>Accept Payment</Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             } */}

    //             {/* {
    //                 Object.values(permittedModules.firstSection).length
    //                     ? <Menu.Divider />
    //                     : <Fragment />
    //             }

    //             {
    //                 permittedModules.secondSection.editCustomer
    //                     ? (
    //                         <Menu.Item key="7" value="edit" onClick={() => editCustomer(item)}>
    //                             <i className="fa fa-pencil-square-o" />
    //                             Edit Customer
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //             {
    //                 permittedModules.secondSection.cloneCustomer
    //                     ? (
    //                         <Menu.Item onClick={() => cloneCustomer(item)} key="8">
    //                             <i className="fa fa-copy" />
    //                             Clone Customer
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             } */}

    //             {
    //                 permittedModules.secondSection.deleteCustomer
    //                     ? (
    //                         <Menu.Item key="9">
    //                             <i className="fa fa-trash-o" />
    //                             <Link onClick={() => {
    //                                 const ids = [];
    //                                 if (item && item.customerId) {
    //                                     ids.push(item.customerId);
    //                                 }
    //                                 if (ids.length) {
    //                                     const modalData = {
    //                                         modalBody: 'Are you sure you want to delete parent?',
    //                                         handleSubmit: () => {
    //                                             props.deleteCustomers({
    //                                                 relationshipId: props.companyInfo.relationshipId,
    //                                                 groupCustomerIds: ids,
    //                                                 pageNumber: props.pageNumber,
    //                                                 pageSize: props.pageSize
    //                                             })
    //                                         }
    //                                     };
    //                                     props.pushModalToStack(modalData);
    //                                 } else {
    //                                     const modalData = {
    //                                         title:
    //                                             <div className="title">
    //                                                 <i className="fa fa-ban mr15" />
    //                                                 Validation Failed
    //                                         </div>,
    //                                         modalBody:
    //                                             <Fragment>
    //                                                 <div className="warning-message">
    //                                                     No parent selected. Please select customerTransaction(s) before deleting.
    //                                             </div>
    //                                                 <div className="button-action">
    //                                                     <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
    //                                                 </div>
    //                                             </Fragment>,
    //                                         hideFooter: true,
    //                                         wrapClassName: 'validation-failed-modal'
    //                                     };
    //                                     props.pushModalToStack(modalData);
    //                                 }
    //                             }}>Delete Parent</Link>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //             {/* {
    //                 Object.values(permittedModules.secondSection).length
    //                     ? <Menu.Divider />
    //                     : <Fragment />
    //             }

    //             {
    //                 permittedModules.thirdSection.showAddress
    //                     ? (
    //                         <Menu.Item key="10">
    //                             <i className="fa fa-home " />
    //                             <a onClick={() => {
    //                                 const modalData = {
    //                                     title: <React.Fragment>
    //                                         <div className='ant-modal-title'>Address</div>
    //                                         <div style={{ 'font-size': '12px' }}> {item.companyName}</div>
    //                                     </React.Fragment>,
    //                                     modalBody: <ShowAddress {...props} customerId={item.customerId} />,
    //                                     width: 1100,
    //                                     hideFooter: true
    //                                 };
    //                                 props.pushModalToStack(modalData);
    //                             }}
    //                             >Show Address</a>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //             {
    //                 permittedModules.thirdSection.showAddress
    //                     ? (
    //                         <Menu.Item key="11">
    //                             <i className="fa fa-user" />
    //                             <a onClick={() => {
    //                                 const modalData = {
    //                                     title: <React.Fragment>
    //                                         <div className='ant-modal-title'>Contact</div>
    //                                         <div style={{ 'font-size': '12px' }}> {item.companyName}</div>
    //                                     </React.Fragment>,
    //                                     modalBody: <ShowContact {...props} customerId={item.customerId} />,
    //                                     width: 1100,
    //                                     hideFooter: true
    //                                 };
    //                                 props.pushModalToStack(modalData);
    //                             }}
    //                             >Show Contact</a>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //             {
    //                 Object.values(permittedModules.thirdSection).length
    //                     ? <Menu.Divider />
    //                     : <Fragment />
    //             } */}

    //             {/* {
    //                 permittedModules.fourthSection.logActivity
    //                     ? (
    //                         <Menu.Item key="12">
    //                             <i className="fa fa-pencil-square-o" />
    //                             <a onClick={() => {
    //                                 const modalData = {
    //                                     title: <React.Fragment>
    //                                         <div className='modal-title'>
    //                                             New Activity
    //                         </div>
    //                                         <div className="modal-sub-title">
    //                                             {param.data.companyName}
    //                                         </div>
    //                                     </React.Fragment>,
    //                                     modalBody: <LogActivity
    //                                         customerId={param.data.customerId}
    //                                         customerName={param.data.companyName}
    //                                     />,
    //                                     wrapClassName: 'log-activity-modal customer-listing',
    //                                     width: 500,
    //                                     hideFooter: true
    //                                 };
    //                                 props.pushModalToStack(modalData);
    //                             }}
    //                             >Log Activity</a>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             }

    //             {
    //                 permittedModules.fourthSection.logCommunication
    //                     ? (
    //                         <Menu.Item key="13">
    //                             <i className="fa fa-bolt" />
    //                             <a onClick={() => {
    //                                 const modalData = {
    //                                     title: <React.Fragment>
    //                                         <div className='modal-title'>
    //                                             New Communication
    //                         </div>
    //                                         <div className="modal-sub-title">
    //                                             {param.data.companyName}
    //                                         </div>
    //                                     </React.Fragment>,
    //                                     modalBody: <LogCommunication
    //                                         customerId={param.data.customerId}
    //                                         customerName={param.data.companyName}
    //                                     />,
    //                                     width: 500,
    //                                     hideFooter: true,
    //                                     wrapClassName: 'log-communication-modal',
    //                                 };
    //                                 props.pushModalToStack(modalData);
    //                             }}
    //                             >Log Communication</a>
    //                         </Menu.Item>
    //                     )
    //                     : <Fragment />
    //             } */}

    //             {/* <Menu.Item key="14">
    //                 <i className="fa fa-file-pdf-o" />
    //                 <a onClick={() => {
    //                     const relationshipAddress = (props.companyInfo.boLocationList || []).find(x => x.isDefault && x.locationType === "RelationshipBillingAddress") || {};
    //                     props.showModal({
    //                         title: <Fragment>
    //                             <div className='ant-modal-title'>PARTY LEDGER - {param.data.companyName}</div>
    //                         </Fragment>,
    //                         modalBody: <LedgerList customerObj={param.data} relationshipAddress={relationshipAddress} {...props} />,
    //                         width: 1000,
    //                         hideFooter: true,
    //                         wrapClassName: 'tiles-modal'
    //                     })
    //                     //props.exportLedgerPdf({ customerId: param.data.customerId, customerObj: param.data, relationshipAddress: relationshipAddress }, props)
    //                 }}
    //                 >Download Ledger Statement</a>
    //             </Menu.Item> */}
    //         </Menu >
    //     )
    // };


    // const openModalOfSelectedTile = (data) => {
    //     switch (data.type) {
    //         case CUSTOMER_LISTING_PAGE.TOTAL_ORDERS:
    //             props.showModal(
    //                 {
    //                     title: <Fragment>
    //                         <div className='ant-modal-title'>Total Orders</div>
    //                     </Fragment>,
    //                     modalBody: <TotalOrders {...props} />,
    //                     width: 1000,
    //                     hideFooter: true,
    //                     wrapClassName: 'tiles-modal'
    //                 }
    //             )
    //             break;
    //         case CUSTOMER_LISTING_PAGE.OPEN_SO:
    //             props.showModal(
    //                 {
    //                     title: <Fragment>
    //                         <div className='ant-modal-title'>Open Sales Orders</div>
    //                     </Fragment>,
    //                     modalBody: <OpenOrder {...props} />,
    //                     width: 1000,
    //                     hideFooter: true,
    //                     wrapClassName: 'tiles-modal'
    //                 }
    //             )
    //             break;
    //         case CUSTOMER_LISTING_PAGE.UNPAID_BALANCE:
    //             props.showModal(
    //                 {
    //                     title: <Fragment>
    //                         <div className='ant-modal-title'>Unpaid Balance</div>
    //                     </Fragment>,
    //                     modalBody: <UnpaidInvoices  {...props} />,
    //                     width: 1000,
    //                     hideFooter: true,
    //                     wrapClassName: 'tiles-modal'
    //                 }
    //             )
    //             break;
    //         case CUSTOMER_LISTING_PAGE.SALES_REVENUE:
    //             props.showModal(
    //                 {
    //                     title: <Fragment>
    //                         <div className='ant-modal-title'>Sales Revenue</div>
    //                     </Fragment>,
    //                     modalBody: <PaidInvoices {...props} />,
    //                     width: 1000,
    //                     hideFooter: true,
    //                     wrapClassName: 'tiles-modal'
    //                 }
    //             )
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // const _isColumnToHide = (columnName) => {
    //     return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    // }

    // const permittedModules = getActionMenuPermissionData();
    // const columnDefs = [
    //     {
    //         field: 'isChecked',
    //         colId: 'isChecked_1',
    //         headerComponentFramework: () => <Fragment />,
    //         cellRendererFramework: (params) => {
    //             return <Checkbox
    //                 onClick={() => {
    //                     params.node.setDataValue('isChecked_1', !params.data.isChecked)
    //                 }}
    //                 checked={params.data.isChecked} />
    //         },
    //         minWidth: 70,
    //         maxWidth: 70,
    //         pinned: 'left',
    //         suppressMovable: true,
    //         lockPosition: true,
    //         width: 60,
    //         resizable: false
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.header.customerDetails' defaultMessage='' />;
    //         },
    //         field: "companyName",
    //         hide: _isColumnToHide('companyName'),
    //         colId: "companyName_1",
    //         cellRendererFramework: function (param) {
    //             return <div>{getCustomerDetailsCell(param.data)}</div>;
    //         },
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.header.contact' defaultMessage='' />;
    //         },
    //         field: "contact",
    //         hide: _isColumnToHide('contact'),
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         colId: "contact_1",
    //         cellRendererFramework: function (param) {
    //             return getCustomerContactCell(param.data);
    //         },
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <div><FormattedMessage id='customer.listing.header.dueAmount' defaultMessage='' />   ({props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "})</div>;
    //         },
    //         field: "totalAmountDue",
    //         hide: _isColumnToHide('totalAmountDue'),
    //         colId: "totalAmountDue_1",
    //         valueFormatter: (data) => {
    //             data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    //             return data.value;
    //         },
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.header.openOrder' defaultMessage='' />;
    //         },
    //         field: "openedOrdersCount",
    //         hide: _isColumnToHide('openedOrdersCount'),
    //         colId: "openedOrdersCount_1",
    //         cellRendererFramework: function (param) {
    //             return <div> <p onClick={() => {
    //                 props.showModal(
    //                     {
    //                         title: <Fragment>
    //                             <div className='ant-modal-title'>Open Orders</div>
    //                             <div className="ant-modal-subtitle extra-sm">{param.data.companyName}</div>
    //                         </Fragment>,
    //                         modalBody: <OpenOrder {...props} customerData={param.data} />,
    //                         width: 1000,
    //                         hideFooter: true,
    //                         wrapClassName: 'tiles-modal'
    //                     }
    //                 )
    //             }} className={param.data.openedOrdersCount ? 'cursor-pointer' : ''}> {param.data.openedOrdersCount}</p>
    //                 <p> {param.data.openedOrdersTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.openedOrdersTotalAmount || '') && param.data.openedOrdersTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
    //             </div>;
    //         },
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.header.unpaidInvoices' defaultMessage='' />;
    //         },
    //         field: "unpaidInvoicesCount",
    //         hide: _isColumnToHide('unpaidInvoicesCount'),
    //         colId: "unpaidInvoicesCount_1",
    //         cellRendererFramework: function (param) {
    //             return <div> <p onClick={() => {
    //                 props.showModal(
    //                     {
    //                         title: <Fragment>
    //                             <div className='ant-modal-title'>Unpaid Invoices</div>
    //                             <div className="ant-modal-subtitle extra-sm">{param.data.companyName}</div>
    //                         </Fragment>,
    //                         modalBody: <UnpaidInvoices customerData={param.data} {...props} />,
    //                         width: 1000,
    //                         hideFooter: true,
    //                         wrapClassName: 'tiles-modal'
    //                     }
    //                 )
    //             }} className={param.data.unpaidInvoicesCount ? 'cursor-pointer' : ''} > {param.data.unpaidInvoicesCount}</p>
    //                 <p> {param.data.unpaidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.unpaidInvoicesTotalAmount || '') && param.data.unpaidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
    //             </div>;
    //         },
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.header.paidInvoices' defaultMessage='' />;
    //         },
    //         field: "paidInvoicesCount",
    //         hide: _isColumnToHide('paidInvoicesCount'),
    //         colId: "paidInvoicesCount_1",
    //         cellRendererFramework: function (param) {
    //             return <div> <p onClick={() => {
    //                 props.showModal(
    //                     {
    //                         title: <Fragment>
    //                             <div className='ant-modal-title'>Paid Invoices</div>
    //                             <div className="ant-modal-subtitle extra-sm">{param.data.companyName}</div>
    //                         </Fragment>,
    //                         modalBody: <PaidInvoices customerData={param.data} {...props} />,
    //                         width: 900,
    //                         hideFooter: true,
    //                         wrapClassName: 'tiles-modal paid-invoice'
    //                     }
    //                 )
    //             }} className={param.data.paidInvoicesCount ? 'cursor-pointer' : ''}> {param.data.paidInvoicesCount}</p>
    //                 <p> {param.data.paidInvoicesTotalAmount ? (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ") : ''}{(param.data.paidInvoicesTotalAmount || '') && param.data.paidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
    //             </div>;
    //         },
    //         resizable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         suppressMovable: true,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.listing.header.paymentTerm' defaultMessage='' />;
    //         },
    //         field: "paymentTermName",
    //         hide: _isColumnToHide('paymentTermName'),
    //         colId: "paymentTermName_1",
    //         resizable: true
    //     }
    // ];

    // if (Object.keys(permittedModules.firstSection).length || Object.keys(permittedModules.firstSection).length
    //     || Object.keys(permittedModules.firstSection).length || Object.keys(permittedModules.firstSection).length) {
    //     columnDefs.push({
    //         headerComponentFramework: () => {
    //             return <div className="cursor-pointer">
    //                 <Popover
    //                     content={<AddAndRemoveCustomerListingColumn {...props} />}
    //                     title={<div className="text-center">Add/Remove</div>}
    //                     placement="left"
    //                     trigger="click"
    //                 >
    //                     <i className='fa fa-bars' />
    //                 </Popover>
    //             </div>;
    //         },
    //         cellRendererFramework: (param) => {
    //             return <Dropdown overlay={actionMenu(param, permittedModules)} trigger={['click']}>
    //                 <Button className="action-btn small">
    //                     <i className="fa fa-ellipsis-v" />
    //                 </Button>
    //             </Dropdown>
    //         },
    //         resizable: true,
    //         pinned: 'right',
    //         field: 'action',
    //         colId: "action_1",
    //         minWidth: 52,
    //         maxWidth: 52,
    //         suppressMovable: true,
    //         cellClass: 'lastActionCell'
    //     });
    // }

    // const onFirstDataRendered = (params) => {
    //     //params.api.sizeColumnsToFit();
    // }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        fetchPaginationDataIfNeeded('fetchCustomers', 'customers', props, payload, true);
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchCustomers(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }

    // const handleMenuClick = (e) => {
    //     switch (e.key) {
    //         case 'upload_customer':
    //             const modalData = {
    //                 title: <div className='ant-modal-title'><FormattedMessage id='customer.listing.importCustomer.modal.title' defaultMessage='' /></div>,
    //                 modalBody: <ImportCustomer {...props} />,
    //                 hideFooter: true,
    //                 width: 900,
    //                 handleSubmit: () => {
    //                     //props.createOrUpdateCustomer(payload);
    //                     //props.hideModal();
    //                 },
    //                 wrapClassName: 'import-details-modal'
    //             };
    //             props.pushModalToStack(modalData);
    //             break;

    //         default:
    //             break;
    //     }
    // }

    // const menu = (
    //     <Menu onClick={handleMenuClick} className="combined-dropdown">
    //         <Menu.Item key="upload_customer">
    //             <i className="fa fa-upload mr5" />
    //             <FormattedMessage id='customer.listing.buttonMenu.importCustomer' defaultMessage='' />
    //         </Menu.Item>
    //     </Menu>
    // );

    // const onGridReady = (params) => {
    //     if (props.isColumnFitTable) {
    //         params.api.sizeColumnsToFit();
    //     }
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi,
    //     });
    //     onDataRendered(params);
    // }



    // const saveColumnIndex = (columnState) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     data.tablePreference = JSON.stringify(tablePreference);

    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data);
    // }

    // const saveColumnWidth = (columnState, width) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data, props);
    // }

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
    //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
    //     event.api.resetRowHeights();
    //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
    //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
    //             props.gridApi.sizeColumnsToFit();
    //             setTimeout(() => {
    //                 const columnArray = event.columnApi.getColumnState();
    //                 saveColumnWidth(columnArray);
    //             }, 1000);
    //         } else {
    //             saveColumnWidth(event.columnApi.getColumnState());
    //         }
    //     }
    // }

    // const onModelUpdated = (params) => {
    //     if((customers[pageNumber] || []).length &&  !props.isRowHeightSet){
    //         setTimeout(()=>{
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //         //params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         const txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     }
    // }

    // const onDataRendered = (params) => {
    //     const txColumnSetting = props.txColumnSetting;
    //     if (Object.keys(txColumnSetting).length) {
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.width = 52;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     } else {
    //         params.api.sizeColumnsToFit();
    //     }
    // }

    const itemSelection = (item) => {
        let selectItem = find(selectedItems, { invoiceMasterId: Number(item.invoiceMasterId) });
        let updatedItems = [];
        if (selectItem) {
            updatedItems = selectedItems.filter(obj => obj.invoiceMasterId !== item.invoiceMasterId);
        } else {
            updatedItems = JSON.parse(JSON.stringify(selectedItems));
            updatedItems.push(item);
        }
        props.updateState({ selectedItems: updatedItems });
    }

    const 
    
    selectAll = (context) => {
        if (!context) {
            selectedItems.splice(0, selectedItems.length);
            props.updateState({ selectedItems: selectedItems });
            return;
        }
        for (let i = 0; i < customers[pageNumber].length; i++) {
            let item = customers[pageNumber][i];
            let selectItem = find(selectedItems, { customerId: Number(item.customerId) });
            if (!selectItem) {
                selectedItems.push(item);
            }
        }
    }

    const isCheckedAll = () => {
        if (!customers || !customers[pageNumber] || customers[pageNumber].length === 0) {
            return false;
        }
        for (let i = 0; i < customers[pageNumber].length; i++) {
            let usr = customers[pageNumber][i];
            let selectItem = find(selectedItems, { customerId: Number(usr.customerId) });
            if (!selectItem) {
                return false;
            }
        }
        return true;
    }

    const openUnpaidInvoicesModal = (context, customer , title) => {
        props.showModal(
            {
                title: <Fragment>
                    <div className='ant-modal-title'>{title || 'Total Invoices'}</div>
                </Fragment>,
                modalBody: <UnpaidInvoices {...props} context={context} customerData={customer}/>,
                width: 1100,
                hideFooter: true,
                wrapClassName: 'tiles-modal'
            }
        )
    }

    const openCustomerRegistrationModal = (data) => {
        props.showModal(
            {
                title: <Fragment>
                    <div className='ant-modal-title'>Registrations</div>
                </Fragment>,
                modalBody: <CustomerRegistration {...props} customerData={data}/>,
                width: 900,
                hideFooter: true,
                wrapClassName: 'tiles-modal'
            }
        )
    }

    // function sortTable(table, col, reverse) {
    //     var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
    //         tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
    //         i;
    //     reverse = -((+reverse) || -1);
    //     tr = tr.sort(function (a, b) { // sort rows
    //         return reverse // `-1 *` if want opposite order
    //             * (a.cells[col].textContent.trim() // using `.textContent.trim()` for test
    //                 .localeCompare(b.cells[col].textContent.trim())
    //                );
    //     });
    //     for(i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
    // }
    
    const sortColumn = (e) => {
        //sortTable(document.getElementById('invoiceTable'), e.target.parentElement.cellIndex, 1 - dir);
        let cellIndex = e.target.cellIndex;
        if (!cellIndex) { cellIndex = e.target.parentElement.cellIndex };
        if (!cellIndex) { cellIndex = e.target.parentElement.parentElement.cellIndex };
        sortTable(document.getElementById('familyTable'), cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }

    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.school' defaultMessage='' />
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.parent' defaultMessage='' />,
        }
    ];

    const downloadMenu = () => {
        return (
            <Menu className="row-action-dropdown">
    
                <Menu.Item key="0" value="title">
                  Download as
                </Menu.Item>
    
                <Menu.Divider />
    
                <Menu.Item key="1" value="excel" onClick={()=>{
                    if (selectedItems.length) {
                        exportExcelData(selectedItems)
                    } else {
                        const modalData = {
                            title:
                                <div className="title">
                                    <i className="fa fa-ban mr15" />
                                    Validation Failed
                                </div>,
                            modalBody:
                                <Fragment>
                                    <div className="warning-message">
                                        No parent selected. Please select parent before downloading.
                                    </div>
                                    <div className="button-action">
                                        <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
                                    </div>
                                </Fragment>,
                            hideFooter: true,
                            wrapClassName: 'validation-failed-modal'
                        };
                        props.pushModalToStack(modalData);
                    }
                  }}
                >
                  <FileExcelOutlined />
                  Excel File
                </Menu.Item>
    
                <Menu.Item key="2" value="pdf" onClick={()=>{
                    let customerList = [];
                    selectedItems.forEach(data => {
                        // let data = obj.data;
                        let address = find(data.boLocationList, { isDefault: 1, locationType: "BillingAddress" }) || {};
                        let contact = (data.boContactList && find(data.boContactList, { isPrimaryContact: 1 }));
                        customerList.push({ ...data, defaultAddress: address, defaultContact: contact });
                    })
                    if (customerList.length) {
                        props.generatePDF({
                            companyInfo: props.companyInfo,
                            fileName: 'Customers.pdf',
                            transactionName: 'customerListing',
                            customerList: customerList
                        })
                    } else {
                        const modalData = {
                            title:
                                <div className="title">
                                    <i className="fa fa-ban mr15" />
                                    Validation Failed
                                </div>,
                            modalBody:
                                <Fragment>
                                    <div className="warning-message">
                                        No parent selected. Please select parent before downloading.
                                    </div>
                                    <div className="button-action">
                                        <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
                                    </div>
                                </Fragment>,
                            hideFooter: true,
                            wrapClassName: 'validation-failed-modal'
                        };
                        props.pushModalToStack(modalData);
                    }
                    }}
                >
                  <FilePdfOutlined />
                  PDF File
                </Menu.Item>
    
            </Menu >
        )
      };

      const actionMenu2 = (e) => {
        return (
            <Menu className="row-action-dropdown">
    
              <Menu.Item key="0" value="title">
                Actions
              </Menu.Item>
    
              <Menu.Divider />
    
              <Menu.Item key="2" onClick={()=>
                {
                    const modalData = {
                        modalBody: 'Are you sure you want to delete parent?',
                        handleSubmit: () => {
                            props.deleteCustomers({
                                relationshipId: props.companyInfo.relationshipId,
                                groupCustomerIds: [e.customerId],
                                pageNumber: props.pageNumber,
                                pageSize: props.pageSize
                            })
                        }
                    };
                    props.pushModalToStack(modalData);
                }
              }>
                <DeleteOutlined />
                Delete
              </Menu.Item>
    
            </Menu >
        )
      };

      const checkMenu = (e) => {
        return (
            <Menu className="row-action-dropdown">
    
              <Menu.Item key="0" value="title">
                Actions
              </Menu.Item>
    
              <Menu.Divider />

              <Menu.Item key="2" onClick={()=>
                {
                    selectAll(true);
                }
              }>
                <CheckCircleOutlined />
                Select all
              </Menu.Item>

              <Menu.Item key="3" onClick={()=>
                {
                    selectAll(false);
                }
              }>
                <CloseCircleOutlined />
                Unselect all
              </Menu.Item>
    
              <Menu.Item key="4" onClick={()=>
                {
                    const ids = [];
                    selectedItems.forEach(obj => {
                        ids.push(obj.customerId);
                    })
                    if (ids.length) {
                        const modalData = {
                            modalBody: 'Are you sure you want to delete parent?',
                            handleSubmit: () => {
                                props.resetPaginatedData();
                                props.deleteCustomers({
                                    relationshipId: props.companyInfo.relationshipId,
                                    groupCustomerIds: ids
                                })
                                props.fetchCustomers({
                                    relationshipId: props.companyInfo.relationshipId,
                                    pageNumber: props.pageNumber,
                                    pageSize: props.pageSize
                                })
                            }
                        };
                        props.pushModalToStack(modalData);
                    } else {
                        const modalData = {
                            title:
                                <div className="title">
                                    <i className="fa fa-ban mr15" />
                                    Validation Failed
                                </div>,
                            modalBody:
                                <Fragment>
                                    <div className="warning-message">
                                        No parent selected. Please select parentTransaction(s) before deleting.
                                    </div>
                                    <div className="button-action">
                                        <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
                                    </div>
                                </Fragment>,
                            hideFooter: true,
                            wrapClassName: 'validation-failed-modal'
                        };
                        props.pushModalToStack(modalData);
                    }
                }
              }>
                <DeleteOutlined />
                Delete selected
              </Menu.Item>
    
            </Menu >
        )
      };
    

    return (
        <Fragment>
            <div className="view-top-bar">
                <div className="top-view-left">
                    <div className="page-heading">
                        <FormattedMessage id='heading.schoolManagement.families' defaultMessage='' />
                    </div>
                    <div className="vertical-sep" />
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div>
                <div className="top-view-right">
                </div>
            </div>
            <div className='view-container'>
                <Skeleton loading={props.isLoading}
                    active
                    paragraph={{ rows: 12 }}
                >
                    <div className="view-container-actions">
                        <div className="left-actions">
                            <div className="table-heading">
                                <FormattedMessage id='schoolManagement.familyList.table.heading' defaultMessage='' />
                            </div>
                            {
                                pageSize ? <>
                                <div className="vertical-sep" />
                                <div>{pageSize}</div>
                                </>:''
                            }
                        </div>
                        <div className="right-actions">
                            <Search
                                placeholder="Enter 3 characters customer name"
                                value={searchedText}
                                onChange={e => {
                                    updateState({ searchedText: e.target.value, pageNumber: 1 });
                                    onSearchedInputChange(e.target.value, props);
                                }}
                                onSearch={(searchedText) => {
                                    updateState({ searchedText, pageNumber: 1 });
                                    onSearchedInputChange(searchedText, props);
                                    handleSearchedText(searchedText, props);
                                }}
                            />
                            <Dropdown overlay={downloadMenu()} trigger={['click']}>
                                <span><DownloadOutlined /> &nbsp; Download as</span>
                            </Dropdown>
                        </div>  
                    </div>
                    {/* <div className="table-action-container">
                        <div className="d-inline-flex">
                            <button className="all-checkbox-control">
                                <Checkbox
                                    checked={isAllChecked}
                                    onChange={(e) => {
                                        toggleAllChecked(e.target.checked);
                                        props.gridApi.forEachNode(obj => {
                                            obj.setDataValue('isChecked_1', e.target.checked);
                                        })
                                    }}
                                />
                            </button>

                            {
                                permittedModules.secondSection.deleteCustomer && (
                                    <button
                                        className="action-icon-btn"
                                        onClick={() => {
                                            const ids = [];
                                            // props.gridApi.forEachNode(obj => {
                                            //     if (obj.data.isChecked) {
                                            //         ids.push(obj.data.customerId);
                                            //     }
                                            // })
                                            selectedItems.forEach(obj => {
                                                ids.push(obj.customerId);
                                            })
                                            if (ids.length) {
                                                const modalData = {
                                                    modalBody: 'Are you sure you want to delete parent?',
                                                    handleSubmit: () => {
                                                        props.resetPaginatedData();
                                                        props.deleteCustomers({
                                                            relationshipId: props.companyInfo.relationshipId,
                                                            groupCustomerIds: ids
                                                        })
                                                        props.fetchCustomers({
                                                            relationshipId: props.companyInfo.relationshipId,
                                                            pageNumber: props.pageNumber,
                                                            pageSize: props.pageSize
                                                        })
                                                    }
                                                };
                                                props.pushModalToStack(modalData);
                                            } else {
                                                const modalData = {
                                                    title:
                                                        <div className="title">
                                                            <i className="fa fa-ban mr15" />
                                                            Validation Failed
                                                        </div>,
                                                    modalBody:
                                                        <Fragment>
                                                            <div className="warning-message">
                                                                No parent selected. Please select parentTransaction(s) before deleting.
                                                            </div>
                                                            <div className="button-action">
                                                                <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
                                                            </div>
                                                        </Fragment>,
                                                    hideFooter: true,
                                                    wrapClassName: 'validation-failed-modal'
                                                };
                                                props.pushModalToStack(modalData);
                                            }
                                        }}
                                        title="Delete Parent"
                                    >
                                        <DeleteOutlined />
                                        <i className="fa fa-trash-o" />
                                    </button>
                                )
                            }

                            <button
                                className="action-icon-btn"
                                onClick={() => {
                                    // const ids = [];
                                    // props.gridApi.forEachNode(obj => {
                                    //     if (obj.data.isChecked) {
                                    //         ids.push(obj.data.customerId);
                                    //     }
                                    // })
                                    // if (ids.length) {
                                    //     props.exportPdf({
                                    //         relationshipId: props.companyInfo.relationshipId,
                                    //         customerIds: ids.toString()
                                    //     })
                                    // }
                                    // props.gridApi.forEachNode(obj => {
                                    //     if (obj.data.isChecked) {
                                    //         let data = obj.data;
                                    //         let address = find(data.boLocationList, { isDefault: 1, locationType: "BillingAddress" }) || {};
                                    //         let contact = (data.boContactList && find(data.boContactList, { isPrimaryContact: 1 }));
                                    //         customerList.push({ ...data, defaultAddress: address, defaultContact: contact });
                                    //     }
                                    // })
                                    let customerList = [];

                                    selectedItems.forEach(data => {
                                        // let data = obj.data;
                                        let address = find(data.boLocationList, { isDefault: 1, locationType: "BillingAddress" }) || {};
                                        let contact = (data.boContactList && find(data.boContactList, { isPrimaryContact: 1 }));
                                        customerList.push({ ...data, defaultAddress: address, defaultContact: contact });
                                    })
                                    if (customerList.length) {
                                        props.generatePDF({
                                            companyInfo: props.companyInfo,
                                            fileName: 'Customers.pdf',
                                            transactionName: 'customerListing',
                                            customerList: customerList
                                        })
                                    } else {
                                        const modalData = {
                                            title:
                                                <div className="title">
                                                    <i className="fa fa-ban mr15" />
                                                    Validation Failed
                                                </div>,
                                            modalBody:
                                                <Fragment>
                                                    <div className="warning-message">
                                                        No parent selected. Please select parent before downloading.
                                                    </div>
                                                    <div className="button-action">
                                                        <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
                                                    </div>
                                                </Fragment>,
                                            hideFooter: true,
                                            wrapClassName: 'validation-failed-modal'
                                        };
                                        props.pushModalToStack(modalData);
                                    }
                                }}
                                title="Download PDF"
                            >
                                <i className="fa fa-file-pdf-o" />
                            </button>

                            <button
                                className="action-icon-btn"
                                onClick={() => {
                                    // const selectedustomerList = [];
                                    // props.gridApi.forEachNode(obj => {
                                    //     if (obj.data.isChecked) {
                                    //         selectedustomerList.push(obj.data);
                                    //     }
                                    // })

                                    if (selectedItems.length) {
                                        exportExcelData(selectedItems)
                                    } else {
                                        const modalData = {
                                            title:
                                                <div className="title">
                                                    <i className="fa fa-ban mr15" />
                                                    Validation Failed
                                                </div>,
                                            modalBody:
                                                <Fragment>
                                                    <div className="warning-message">
                                                        No parent selected. Please select parent before downloading.
                                                    </div>
                                                    <div className="button-action">
                                                        <Button className="ant-btn-default" onClick={() => props.popModalFromStack()}>OK</Button>
                                                    </div>
                                                </Fragment>,
                                            hideFooter: true,
                                            wrapClassName: 'validation-failed-modal'
                                        };
                                        props.pushModalToStack(modalData);
                                    }
                                }}
                                title="Download Excel"
                            >
                                <i className="fa fa-file-excel-o" />
                            </button>

                            <Search
                                placeholder="Enter 3 characters customer name"
                                value={searchedText}
                                onChange={e => {
                                    updateState({ searchedText: e.target.value, pageNumber: 1 });
                                    onSearchedInputChange(e.target.value, props);
                                }}
                                onSearch={(searchedText) => {
                                    updateState({ searchedText, pageNumber: 1 });
                                    onSearchedInputChange(searchedText, props);
                                    handleSearchedText(searchedText, props);
                                }}
                            />
                        </div>

                        <div>
                            {
                                permittedModules.secondSection.createCustomer && (
                                    <div className="action-dropdown-btn">
                                        <button onClick={newCustomer}>
                                            <i className="fa fa-user pr5" />
                                            <FormattedMessage id='customer.listing.button.newCustomer.level' defaultMessage='' />
                                        </button>
                                        <Dropdown overlay={menu}>
                                            <span className="dropdown active">
                                                <i className="fa fa-angle-down ml5" />
                                            </span>
                                        </Dropdown>
                                    </div>
                                )
                            }
                        </div>
                    </div> */}

                    <div className='table-container'>
                        <table id='familyTable'>
                            <thead>
                                <tr>
                                    <th>
                                        <Checkbox
                                            onChange={(e) => {
                                                selectAll(e.target.checked);
                                            }}
                                            checked={isCheckedAll()}
                                        /> &nbsp;
                                        <Dropdown overlay={checkMenu()} trigger={['click']}>
                                            <CaretDownOutlined />
                                        </Dropdown>
                                    </th>
                                    <th onClick={sortColumn}>Father Info</th>
                                    <th onClick={sortColumn}>Mother Info</th>
                                    <th onClick={sortColumn}>Registration</th>
                                    <th onClick={sortColumn}>Total Invoices</th>
                                    <th onClick={sortColumn}>Unpaid Invoices</th>
                                    <th onClick={sortColumn}>Paid Invoices</th>
                                    {/* <th>Program</th>
                                    <th>Project</th> */}
                                    <th width='6%'>
                                        {/* <div className="cursor-pointer">
                                            <Popover
                                                content={<AddAndRemoveCustomerListingColumn {...props} />}
                                                title={<div className="text-center">Add/Remove</div>}
                                                placement="left"
                                                trigger="click"
                                            >
                                                <i className='fa fa-bars' />
                                            </Popover>
                                        </div> */}
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {customers && customers[pageNumber] ?
                                    customers[pageNumber].map((item, i) => {
                                        return (<tr key={i}>
                                            <td>
                                                <Checkbox
                                                    onClick={() => {
                                                        itemSelection(item);
                                                    }}
                                                    checked={find(selectedItems, { customerId: Number(item.customerId) }) ? true : false}
                                                />
                                            </td>
                                            <td>
                                                <div>{`${item.fatherFirstName || ''}${item.fatherLastName ? ' ' + item.fatherLastName : ''}`}</div>
                                                <div>{item.fatherMobile}</div>
                                                <div>{item.fatherEmail}</div>
                                            </td>
                                            <td>
                                                <div>{`${item.motherFirstName || ''}${item.motherLastName ? ' ' + item.motherLastName : ''}`}</div>
                                                <div>{item.motherMobile}</div>
                                                <div>{item.motherEmail}</div>
                                            </td>
                                            <td> {item.registrationCount ? <span onClick={() => { openCustomerRegistrationModal(item); }} className='cursor-pointer'>{item.registrationCount}</span> : ''}</td>

                                            <td><div> {item.totalInvoicesRaisedCount ? <p><span onClick={() => { openUnpaidInvoicesModal('totalInvoices', item, 'Total Invoices'); }} className='cursor-pointer'>{item.totalInvoicesRaisedCount}</span> invoice(s)</p> : <p>0</p>}
                                                <p> {item.totalInvoicesRaisedAmount ? <span> raised for {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{(item.totalInvoicesRaisedAmount || '') && item.totalInvoicesRaisedAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : ''}</p>
                                            </div>
                                            </td>
                                            <td><div>
                                                {item.unpaidInvoicesCount ? <p><span onClick={() => { openUnpaidInvoicesModal('unpaidInvoices', item, 'Unpaid Invoices'); }} className='cursor-pointer'>{item.unpaidInvoicesCount}</span> unpaid invoices(s)</p> : <p>0</p>}
                                                <p> {item.unpaidInvoicesTotalAmount ? <span> of {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{(item.unpaidInvoicesTotalAmount || '') && item.unpaidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : ''}</p>
                                            </div>
                                            </td>
                                            <td><div>
                                                {item.paidInvoicesCount ? <p><span onClick={() => { openUnpaidInvoicesModal('paidInvoices', item, 'Paid Invoices'); }} className='cursor-pointer'>{item.paidInvoicesCount}</span> paid invoices(s) </p> : <p>0</p>}
                                                <p> {item.paidInvoicesTotalAmount ? <span> of {(props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " ")}{(item.paidInvoicesTotalAmount || '') && item.paidInvoicesTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> : ''}</p>
                                            </div>
                                            </td>
                                                {/* <td>{item.ProgramName || ''}</td> 
                                                    <td>{item.ProjectName || ''}</td>  */}
                                            <td style={{paddingLeft: '50px'}}>
                                                {/* <Dropdown overlay={actionMenu(item, permittedModules)} trigger={['click']}>
                                                        <Button className="action-btn small">
                                                            <i className="fa fa-ellipsis-v" />
                                                        </Button>
                                                    </Dropdown> 
                                                */}
                                                {/* 
                                                    <span title='Delete Parent' className='cursor-pointer' style={{ fontSize: '20px' }} onClick={() => {
                                                        const modalData = {
                                                            modalBody: 'Are you sure you want to delete parent?',
                                                            handleSubmit: () => {
                                                                props.deleteCustomers({
                                                                    relationshipId: props.companyInfo.relationshipId,
                                                                    groupCustomerIds: [item.customerId],
                                                                    pageNumber: props.pageNumber,
                                                                    pageSize: props.pageSize
                                                                })
                                                            }
                                                        };
                                                        props.pushModalToStack(modalData);
                                                    }}><i className="fa fa-trash-o" /></span> 
                                                */}

                                                <div className="action-icon">
                                                    <Dropdown overlay={actionMenu2(item)} trigger={['click']}>
                                                        <MoreOutlined />
                                                    </Dropdown>
                                                </div>
                                            </td>

                                        </tr>)
                                    })

                                    : 'No Data'}
                            </tbody>
                        </table>
                    </div>
                </Skeleton>
                <div className="footer">
                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={customerCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(1, pageSize, props)
                            }}
                        />
                    </div>
                </div>

                {/* <Tile tilesData={tilesData} {...props} getSelectedTile={(data) => {
                openModalOfSelectedTile(data);
            }} /> */}
            </div >
        </Fragment>
    );
};

const onSearchedInputChange = debounce((searchedText, props) => {
    handleSearchedText(searchedText, props)
}, 500);

const handleSearchedText = (searchedText, props) => {
    const payload = {
        relationshipId: props.companyInfo.relationshipId,
        pageNumber: 1,
        pageSize: props.pageSize,
        searchedText: searchedText,
        companyInfo: props.companyInfo,
    };
    if (searchedText.length) {
        if (searchedText.length >= 3 && !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())) {
            props.resetPaginatedData();
            props.getCustomerCount(payload);
            props.fetchCustomers(payload);
        }
    } else {
        props.resetPaginatedData();
        props.getCustomerCount(payload);
        props.fetchCustomers(payload);
    }
}

const getAddressText = (data) => {
    let address = find(data.boLocationList, { isDefault: 1, locationType: "BillingAddress" });
    if (address) {
        return (address.streetAddress1 || '') + ' ' + (address.streetAddress2 || '') +
            (address.cityName || '') + ' ' + (address.streetAddress2 || '') + ' ' + (address.zipCode || '');
    } else {
        return '';
    }
}

const getContactText = (data) => {
    let contact = (data.boContactList && find(data.boContactList, { isPrimaryContact: 1 }));
    if (contact) {
        return (contact.salutationName ? contact.salutationName + ' ' : '') + (contact.firstName || '') + ' ' + (contact.lastName || '') + ' ' +
            (contact.cellPhone ? 'Ph: ' + contact.cellPhone : '');
    } else {
        return '';
    }
}

const exportExcelData = (selectedustomerList, props) => {
    const dataList = (selectedustomerList || []).map(item => {
        return {
          'Customer Name': item.companyName || '',
          'Address': getAddressText(item),
          'Contact': getContactText(item),
          'Due Amount': Number(item.totalAmountDue || 0).toFixed(2),
          'Open Order': Number(item.openedOrdersTotalAmount || 0).toFixed(2),
          'Unpaid Invoice': Number(item.unpaidInvoicesTotalAmount || 0).toFixed(2),
          'Paid Invoice': Number(item.paidInvoicesTotalAmount || 0).toFixed(2),
          'Payment Term': item.paymentTermName || '',
        };
      })
      exportExcel(dataList, 'Customers')
}

export default injectIntl(FamilyListingComp);
