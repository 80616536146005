import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SalesInvoicePaymentsListComp from '../../../../../components/modal/modalBody/customer/SalesInvoicePaymentsList';
import { fetchSalesInvoiceDetail } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import { fetchSalesInvoices, getSalesInvoiceCount, deleteSalesInvoice, updateUserStatus, updateSystemStatus } from '../../../../customer/invoice/Listing/action';
import { fetchAllLedgerAccounts } from '../../../../finance/LedgerAccounts/action';
import { deleteAttectedFile } from '../../../../../actions/commonActions';

class SalesInvoicePaymentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.invoicePayload.customerId, invoiceMasterId: this.props.invoicePayload.invoiceMasterId };
        fetchDataIfNeeded('fetchSalesInvoiceDetail', 'salesInvoiceDetail', this.props, payload, true);
        //   this.props.getAvailableBalance(payload);
        //  this.props.fetchAllLedgerAccounts(payload);
    }
    // componentWillReceiveProps(props) {

    // }

    render() {
        return <div>
            <SalesInvoicePaymentsListComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,
        userInfo: state.common.userInfo,
        permissions: state.auth.permissions,
        availableCredits: state.salesInvoice.availableCredits,
        allLedgerAccounts: state.ledger.allLedgerAccounts,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSalesInvoices,
    fetchSalesInvoiceDetail,
    getSalesInvoiceCount,
    downloadFileFromBucket,
    generatePDF, printPDF,
    deleteSalesInvoice,
    updateUserStatus,
    fetchAllLedgerAccounts,
    updateSystemStatus,
    deleteAttectedFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoicePaymentList);