import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchProducts, getProductCount, resetPaginatedData } from '../../../product/Listing/action';
import { updateInventoryItem, approveQtyChange, rejectQtyChange } from './Listing/action';
import StockAdjustmentEdit from '../../../../components/inventory/warehouse/stockAdjustment/Listing/stockAdjustmentEdit';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
class StockAdjustmentDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStock:props.childData?props.childData.currentStock:props.data.currentStock,
      salesCommitted:props.childData?props.childData.salesCommitted:props.data.salesCommitted,
      qtyOnPO:props.childData?props.childData.qtyOnPO:props.data.qtyOnPO,
      itemId:props.childData?props.childData.itemId:props.data.itemId,
      itemName:props.childData?props.childData.itemName:props.data.itemName,
      purchasedPrice:props.childData?props.childData.purchasedPrice:props.data.purchasedPrice,
      sku:props.data.sku,
      brandName:props.data.brandName,
      
      inventoryItemCategoryRefs:props.data.inventoryItemCategoryRefs,
      variantId:props.childData?props.childData?.variantId:null
    };
  }

  componentDidMount() {
    // const companyInfo = this.props.companyInfo || {};
    // const payload = {
    //   relationshipId: companyInfo.relationshipId,
    //   pageNumber: 1,
    //   pageSize: 100,
    //   isDetails: true
    // };
  }

  render() {
    return (<>
          <StockAdjustmentEdit
            {...this.state}
            {...this.props}
            updateDrawerState={(data) => {
              this.setState(data);
            }}
          />
    </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    products: state.product.products,
    productCount: state.product.productCount,
    permissions: state.auth.permissions
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchProducts,
  getProductCount,
  resetPaginatedData,
  updateInventoryItem,
  approveQtyChange,
  rejectQtyChange,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StockAdjustmentDrawer);
