import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmailDrawer from '../../../components/drawer/recurringInvoiceEmail/EmailDrawer';
import { fetchEmailAddress, loadEmailData, sendBulkEmail, sendEmailByStatus, sendAssignmentEmail, getStudentByClassId } from './action';
import { generatePDF } from './../../../actions/downloadFileAction';
import { CONSTANTS_TRANSACTIONS } from '../../../static/constants';
import {  fetchAllStatus } from '../../modal/modalBody/common/UserStatus/action';
import {getAllDG } from '../../communication/distribution-group/action';
import {brodcastTextEmail } from '../../drawer/communication/account/brodcast-email/action';
// import { tuple } from 'antd/lib/_util/type';

class recurringInvoiceEmail extends Component {

  constructor(props){
    super(props);
    let data = {}
    
    if(props.isFormSetting && props.emailContext === 'recurringInvoice' && props.recurringInvoiceSettings && props.recurringInvoiceSettings.emailDetails){
      data = props.emailDetails || {}
    }else if(props.isFormSetting && props.emailDetails && props.emailContext === 'registrationEmail') {
      data = props.emailDetails || {}
    } else if(!props.emailContext) {
      data = props.emailDetails ? JSON.parse(props.emailDetails) : {};
    }
    this.state = {
      isEmailContentLoaded: false,
      sender: data.sender,
      sendMeACopy: data.sendMeACopy,
      ccRecievers: data.cc || [],
      bccRecievers: data.bcc || [],
      emailBody: data.message,
      subject: data.subject,
      receiverItems: [],
      receivers: data.to || [],
      ccRecieversList: [],
      bccRecieversList: [],
      ccVisible: (data.cc && data.cc.length) ? true: false,
      bccVisible: (data.bcc && data.bcc.length) ? true: false,
      isSendInvoicePdf: data.isSendInvoicePdf || 0,
      updateReminder: props.updateReminder,
      isBulkInvoice: props.isBulkInvoice || false,
      docName: props.docName || 'Sales Invoice',
      isSendBulkInvoice: props.isSendBulkInvoice || false,
      isNewCommunication: props.isNewCommunication || false,
      isSendAssignment: props.isSendAssignment || false,
      classId: props.classId || 0,
      assignmentData: props.assignmentData || {},
      asmStudentList: []
    }
  }
  
  componentDidMount() {
    !this.props.emailDetails ? this.props.loadEmailData(this) : this.setState({isEmailContentLoaded: true});
    
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId,
      transactionName:  CONSTANTS_TRANSACTIONS.STUDENT,
      classId: this.state.classId || 0
    };
    if(this.state.isNewCommunication){
    this.props.fetchAllStatus(payload);
    this.props.getAllDG(payload);
    }
    if(this.props.isSendAssignment){
      this.props.getStudentByClassId(payload, this);
    }
  }

  initCalls() {
    this.setState({
      receiverItems: [],
      ccRecieversList: [],
      bccRecieversList: [],
    })
    this.props.fetchEmailAddress({
      customerId: (this.props || {}).customerId,
      relationshipId: (this.props.companyInfo || {}).relationshipId
    })
    
  }

  componentWillReceiveProps(props) {
    if(props.emailAddressList && props.emailAddressList.length){
      this.setState({
        receiverItems:  props.emailAddressList,
        ccRecieversList:  props.emailAddressList,
        bccRecieversList:  props.emailAddressList
      })
    }
  }


  render() {
    return <div>
      {this.state.isEmailContentLoaded &&
      <EmailDrawer initCalls={() => {
        this.initCalls();
      }} {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
    </div>
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    isEmailSpinner: state.common.isEmailSpinner,
    emailAddressList: state.common.emailAddressList,
    userStatus: state.common.userStatus,
    dgList: state.communicationReducer.dgList,
    defaultSchoolForm: state.common.defaultSchoolForm,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchEmailAddress,
  generatePDF,
  loadEmailData,
  sendBulkEmail,
  fetchAllStatus,
  getAllDG,
  sendEmailByStatus,
  brodcastTextEmail,
  sendAssignmentEmail,
  getStudentByClassId
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(recurringInvoiceEmail);
