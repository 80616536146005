import React, { Fragment } from "react";
import { Row, Col, Button, Popover,Skeleton } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as filter from "lodash.filter";
import ReactHtmlParser from "react-html-parser";
import { CustomTableUpload } from "../../../general/UploadTable";
import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";
import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { AddAndRemoveColumn } from "../../../general/AddAndRemoveColumn";
import {
  GRID_PREFERENCE_CONSTANTS,
  AG_GRID_CONSTANTS,
} from "../../../../static/agGridConstants";
import { TRANSACTION_COLUMN_INDEX } from "../../../../static/constants";
import find from "lodash.find";
import { showToasterMessage } from "../../../../utils";

class ProductDetail extends React.Component {
  render() {
    const _isColumnToHide = (columnName) => {
      // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
      //     return true;
      // }
      return this.props.txColumns.indexOf(columnName) < 0 &&
        this.props.txMandatoryColumns.indexOf(columnName) < 0
        ? true
        : false;
    };

    const packageList = filter(this.props.soUnShippedList, (element) => {
      return this.props.selectedPackages.indexOf(element.packageNumber) !== -1;
    });
    const content = <AddAndRemoveColumn {...this.props} />;

    let customerSOPackageDetailsList = this.props.shipmentDetailList || [];
        
    packageList.forEach((e) => {
      e.customerSOPackageDetailsList.forEach((ele) => {
        ele.salesOrderNumber = e.salesOrderNumber;
        ele.itemVariantStockId= e.itemVariantStockId;
        ele.packageNumber = e.packageNumber;
      });
      customerSOPackageDetailsList = this.props.update
        ? this.props.shipmentDetailList
        : customerSOPackageDetailsList.concat(e.customerSOPackageDetailsList);
    });

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "#";
        },
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1;
        },
        field: "sno",
        lockPosition: true,
        colId: "sno_1",
        minWidth: 75,
        maxWidth: 75,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="package.productDetail.product"
              defaultMessage=""
            />
          );
        },
        lockPosition: true,
        field: "itemName",
        resizable: true,
        colId: "itemName_1",
        hide: _isColumnToHide("itemName"),
        cellRendererFramework: (params) => {
          return (
            <div>
              {(params.data.itemName || "") +
                (params.data.itemVariantName
                  ? " (" + params.data.itemVariantName + " ) "
                  : "")}
            </div>
            
          );
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="package.productDetail.description"
              defaultMessage=""
            />
          );
        },
        field: "description",
        colId: "description_1",
        resizable: true,
        hide: _isColumnToHide("description"),
        lockPosition: true,
        cellEditor: "CKEditorGrid",
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight > 30 &&
              params.reactContainer.scrollHeight >=
                (params.node.data.minHeight || 0)
            ) {
              params.eParentOfValue.style.height = "inherit";
              params.eParentOfValue.style.height =
                params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500);
          return ReactHtmlParser(params.data.description);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (
            params.event.target.scrollHeight > (params.data.minHeight || 30)
          ) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${params.event.target.scrollHeight}px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${
                params.node.data.minHeight || 30
              }px`;
            }
            params.node.setRowHeight(params.node.data.minHeight || 30);
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="package.productDetail.uom"
              defaultMessage=""
            />
          );
        },
        lockPosition: true,
        resizable: true,
        field: "uomName",
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.UOM),
        colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="package.productDetail.qtyPacked"
              defaultMessage=""
            />
          );
        },
        lockPosition: true,
        field: "quantity",
        resizable: true,
        colId: "quantity_1",
        hide: _isColumnToHide("quantity"),
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="modal.txDetails.shipment.packageNumber"
              defaultMessage=""
            />
          );
        },
        lockPosition: true,
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight > 30 &&
              params.reactContainer.scrollHeight >=
                (params.node.data.minHeight || 0)
            ) {
              params.eParentOfValue.style.height = "inherit";
              params.eParentOfValue.style.height =
                params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500);
          return ReactHtmlParser(params.data.packageNumber);
        },
        field: "packageNumber",
        colId: "packageNumber_1",
        resizable: true,
        hide: _isColumnToHide("packageNumber"),
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="modal.txDetails.shipment.soNumber"
              defaultMessage=""
            />
          );
        },
        lockPosition: true,
        field: "salesOrderNumber",
        colId: "salesOrderNumber_1",
        resizable: true,
        hide: _isColumnToHide("salesOrderNumber"),
        cellRendererFramework: (params) => {
          setTimeout(() => {
            if (
              params.reactContainer &&
              params.reactContainer.scrollHeight > 30 &&
              params.reactContainer.scrollHeight >=
                (params.node.data.minHeight || 0)
            ) {
              params.eParentOfValue.style.height = "inherit";
              params.eParentOfValue.style.height =
                params.reactContainer.scrollHeight;
              params.node.data.maxHeight = params.reactContainer.scrollHeight;
              params.node.setRowHeight(params.reactContainer.scrollHeight);
              this.gridApi && this.gridApi.onRowHeightChanged();
            }
          }, 500);
          return ReactHtmlParser(params.data.salesOrderNumber);
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage id="common.materialNumber" defaultMessage="" />
          );
        },
        field: "materialNumber",
        lockPosition: true,
        colId: "materialNumber_1",
        resizable: true,
        hide: _isColumnToHide("materialNumber"),
        editable: true,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.comment"
              defaultMessage=""
            />
          );
        },
        field: "warehouse",
        colId: "warehouseName_1",
        lockPosition: true,
        resizable: true,
        hide: _isColumnToHide("warehouseName"),
        editable: true,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.stockNumber"
              defaultMessage=""
            />
          );
        },
        field: "stockNumber",
        colId: "stockNumber_1",
        lockPosition: true,
        resizable: true,
        hide: _isColumnToHide("stockNumber"),
        editable: true,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.partNumber"
              defaultMessage=""
            />
          );
        },
        field: "partNumber",
        colId: "partNumber_1",
        lockPosition: true,
        resizable: true,
        hide: _isColumnToHide("partNumber"),
        editable: true,
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              <FormattedMessage
                id="customer.salesOrder.form.gridHeader.origin"
                defaultMessage=""
              />
            </div>
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD,
        editable: true,
        resizable: true,
        sortable: true,
        //minWidth: 115,
        //maxWidth: 130,
        suppressMovable: true,
        //width: (txColumnSetting[AG_GRID_CONSTANTS.COMMON.ORIGIN_FIELD] || {}).width || 200,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.ORIGIN),
        colId: GRID_PREFERENCE_CONSTANTS.ORIGIN + "_1",
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.countries,
            optionKeyName: "countryId",
            valueKeyName: "countryName",
            onSelect: (selectedValue) => {
              ////obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.origin = selectedValue;
            },
            allowClear: true,
            onClear: () => {
              ////obj.node.setDataValue(obj.colDef.field, undefined);
              obj.node.data.origin = undefined;
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesOrder.form.gridHeader.hsCode"
              defaultMessage=""
            />
          );
        },
        field: "hsCode",
        colId: "hsCode_1",
        lockPosition: true,
        resizable: true,
        hide: _isColumnToHide("hsCode"),
        editable: true,
      },
      {
        headerComponentFramework: () => {
          return (
            <div className="cursor-pointer">
              <Popover
                content={content}
                title={
                  <div className="text-center">
                    <FormattedMessage id="common.addRemove" defaultMessage="" />{" "}
                  </div>
                }
                placement="left"
                trigger="click"
              >
                <i className="fa fa-bars" />
              </Popover>
            </div>
          );
        },
        field: "action",
        colId: "action_1",
        lockPosition: true,
        minWidth: 52,
        maxWidth: 52,
        pinned: "right",
        cellRenderer: "ActionLock",
      },
    ];

    const saveColumnWidth = (columnState, width) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data, this.props);
    };

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //   if (event.column) {
    //     moveEvent = event;
    //   }
    //   if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //     saveColumnIndex(moveEvent.columnApi.getColumnState());
    //   }
    // }

    const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
      const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
      event.api.resetRowHeights();
      //const gridApi = tablegridApi || this.gridApi;
      if (
        event.type === "columnResized" &&
        event.source !== "sizeColumnsToFit" &&
        event.source !== "api" &&
        event.finished === true
      ) {
        //event.api.resetRowHeights();
        if (
          gridColumnApi &&
          gridColumnApi.columnController.bodyWidth <
            gridColumnApi.columnController.scrollWidth
        ) {
          this.gridApi.sizeColumnsToFit();
          // const columnArray = event.columnApi.getColumnState();
          // for (let i = (columnArray.length - 1); i >= 0; i--) {
          //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
          //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
          //         break;
          //     }
          // }
          // saveColumnWidth(columnArray);
          setTimeout(() => {
            const columnArray = event.columnApi.getColumnState();
            saveColumnWidth(columnArray);
          }, 1000);
        } else {
          saveColumnWidth(event.columnApi.getColumnState());
        }
      }
    };

    const onModelUpdated = (params) => {
      if (this.props.tableDataReRendered) {
        //params.api.resetRowHeights();
        this.props.updateState({
          tableDataReRendered: false,
        });
        let actionIndex = 0;
        let amountIndex = 0;
        let txColumnSetting =
          this.props.txColumnSetting && this.props.txColumnSetting.length
            ? this.props.txColumnSetting
            : params.columnApi.getColumnState();
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.SHIPMENT_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.lockPosition = true;
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "amount") {
            e.hide = false;
            amountIndex = index;
            e.lockPosition = true;
            e.pinned = "right";
          }
          if (columnName === "action") {
            actionIndex = index;
            e.width = 52;
            e.hide = false;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        params.columnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
              this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      }
    };

    const onDataRendered = (params) => {
      let txColumnSetting =
        this.props.txColumnSetting && this.props.txColumnSetting.length
          ? this.props.txColumnSetting
          : params.columnApi.getColumnState();
      let actionIndex = 0;
      let amountIndex = 0;
      if (Object.keys(txColumnSetting).length) {
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.SHIPMENT_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);
        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "action") {
            e.width = 52;
            actionIndex = index;
            e.hide = false;
            e.pinned = "right";
          }
          if (columnName === "amount") {
            e.hide = false;
            e.lockPosition = true;
            amountIndex = index;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        this.gridColumnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
              this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      } else {
        this.gridApi.sizeColumnsToFit();
      }
    };

    return (
      <Fragment>
          <Skeleton loading={this.props.detailLoading} paragraph={{ rows: 5 }} active>

        {this.props.selectedPackages.length ? (
          <>
            <div className="transaction-table">
              {this.props.txColumns.length ? (
                <CustomAgGrid
                  columnDefs={columnDefs}
                  onGridReady={(params) => {
                    this.gridApi = params.api;
                    this.gridColumnApi = params.columnApi;
                    onDataRendered(params);
                  }}
                  defaultColDef={{
                    flex: 1,
                    autoHeight: true,
                    wrapText: true,
                  }}
                  onModelUpdated={onModelUpdated}
                  onColumnResized={onColumnResized}
                  rowData={(customerSOPackageDetailsList).sort((a, b)=> Number(a.sNo) > (b.sNo) ? 1 : -1) || [{}]}
                  gridStyle={{
                    //width: '100%',
                    height: "100%",
                    marginBottom: "10px",
                  }}
                />
              ) : null}
            </div>
            <div className="upload-btn-so">
              <CustomTableUpload
                fileList={this.props.fileList}
                onChange={(fileList) => {
                  this.props.updateState({
                    fileList,
                  });
                }}
              />
            </div>
            <Row>
              <Col span={12} style={{ paddingBottom: "50px" }}>
                <div className="remarks-title">
                  <FormattedMessage
                    id="customer.salesOrder.form.internalRemarks.label"
                    defaultMessage=""
                  />
                </div>

                <CKEditor
                  type="inline"
                  className="description-textarea"
                  key={"remarksInternal"}
                  // data={this.props.remarksInternal}
                  data={
                    this.props.remarksInternal
                      ? this.props.remarksInternal
                      : packageList.length === 1
                      ? packageList[0].remarksInternal
                      : ""
                  }
                  onInit={(editor) => {
                    this.editor = editor;
                  }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    this.props.updateState({ remarksInternal: data });
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION,
                    placeholder: "Enter internal remarks",
                  }}
                />

                <div className="remarks-title">
                  <FormattedMessage
                    id="customer.salesOrder.form.customerRemarks.label"
                    defaultMessage=""
                  />
                </div>

                <CKEditor
                  type="inline"
                  className="description-textarea"
                  key={`${"remarksCustomer"}`}
                  data={
                    this.props.remarksCustomer
                      ? this.props.remarksCustomer
                      : packageList.length === 1
                      ? packageList[0].remarksCustomer
                      : ""
                  }
                  onInit={(editor) => {
                    this.editor = editor;
                  }}
                  onChange={(event) => {
                    const data = event.editor.getData();
                    this.props.updateState({ remarksCustomer: data });
                  }}
                  config={{
                    ...CUSTOM_CONFIGURATION,
                    placeholder: "Enter customer remarks",
                  }}
                />
              </Col>
              <Col span={12}></Col>
            </Row>
          </>
        ):""}

        {
          // packageList.map((packageData, rootIndex) => {
          //     return <div className="package-type-details">
          //         <div className="modal-table-container" key={rootIndex}>
          //             <Row>
          //                 <Col span={6}>
          //                     <Row className="package-info pb5">
          //                         <Col span={8}>
          //                             <strong>Package #</strong>
          //                         </Col>
          //                         <Col span={16}>
          //                             {packageData.packageNumber || ''}
          //                         </Col>
          //                     </Row>
          //                 </Col>
          //                 <Col span={6} offset={2}>
          //                     {
          //                         packageData.customerPONumber ?
          //                             <Row className="package-info pb10">
          //                                 <Col span={8}>
          //                                     <strong>Customer PO #</strong>
          //                                 </Col>
          //                                 <Col span={16}>
          //                                     {packageData.customerPONumber || ''}
          //                                 </Col>
          //                             </Row> : ''
          //                     }
          //                 </Col>
          //             </Row>
          //             <Row>
          //                 <Col span={6}>
          //                     <Row className="package-info pb5">
          //                         <Col span={8}>
          //                             <strong>Sales Order #</strong>
          //                         </Col>
          //                         <Col span={16}>
          //                             {packageData.salesOrderNumber || ''}
          //                         </Col>
          //                     </Row>
          //                 </Col>
          //                 <Col span={6} offset={2}>
          //                     {
          //                         (this.props.soData || {}).projectNumber ?
          //                             <Row className="package-info pb10">
          //                                 <Col span={8}>
          //                                     <strong>Project</strong>
          //                                 </Col>
          //                                 <Col span={16}>
          //                                     {(this.props.soData || {}).projectNumber + ' (' + (this.props.soData || {}).projectName + ')'}
          //                                 </Col>
          //                             </Row> : ''
          //                     }
          //                 </Col>
          //                 <Col span={6} >
          //                     {
          //                         packageData.incotermName ?
          //                             <Row className="package-info pb10">
          //                                 <Col span={8}>
          //                                     <strong>Incoterm</strong>
          //                                 </Col>
          //                                 <Col span={16}>
          //                                     {packageData.incotermName || ''}
          //                                 </Col>
          //                             </Row> : ''
          //                     }
          //                 </Col>
          //             </Row>
          //             {/* <table>
          //                 <thead>
          //                 <tr>
          //                     <th><FormattedMessage id='serial.no' defaultMessage=''/></th>
          //                     <th className="text-left">Product</th>
          //                     <th className="description-cell">Description</th>
          //                     <th>UOM</th>
          //                     <th>Qty</th>
          //                 </tr>
          //                 </thead>
          //                 <tbody>
          //                 {packageData.customerSOPackageDetailsList.map((ele, index) => {
          //                     return <tr key={index}>
          //                         <td>{index + 1}</td>
          //                         <td>{ele.itemName}</td>
          //                         <td>{getDescriptionFragment((this.props.soData || {}), ele, (index + 1))}</td>
          //                         <td>{ele.uomName}</td>
          //                         <td>{ele.quantityPacked}</td>
          //                     </tr>
          //                 })}
          //                 </tbody>
          //             </table> */}
          //             <CustomTableUpload fileList={this.props.fileList} onChange={
          //                 (fileList) => {
          //                     this.props.updateState({
          //                         fileList
          //                     })
          //                 }
          //             } />
          //         </div>
          //     </div>
          // })}
        }
        {/* <div className="package-type-details">
                    <div className="modal-table-container">
                        
                    </div>
                </div>  */}

        <div className="footer">
          <div className="left-fItems">
            <Button
              className="grey-button"
              onClick={() => {
                this.props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    this.props.popModalFromStack();
                  }
                })
              }}
            >
              <FormattedMessage id="common.cancel" defaultMessage="" />{" "}
            </Button>
          </div>

          <div className="right-fItems">
            <Button
              className="ant-btn-primary"
              loading={this.props.saveLoading}
              disabled={this.props.saveLoading}
              onClick={() => {
                createShipment(this.props);
              }}
            >
              Submit
            </Button>
          </div>
        </div>
          </Skeleton>
      </Fragment>
    );
  }
}

const createShipment = (props) => {
  const { length, width, height, weight } = props;
   
  if(!(length && width && height)){
    
      return showToasterMessage({
      messageType: "error",
      description: props.intl.formatMessage({
        id: "customer.shipment.shipmentDetails.missingDimension",
      }),
    });

  }
  if(!(weight)){
    
    return showToasterMessage({
    messageType: "error",
    description: props.intl.formatMessage({
      id: "customer.shipment.shipmentDetails.missingDimension",
    }),
  });

     }
  
  // if ((length || width || height) && !(length && width && height)) {
  //   return showToasterMessage({
  //     messageType: "error",
  //     description: props.intl.formatMessage({
  //       id: "customer.shipment.shipmentDetails.missingDimension",
  //     }),
  //   });
  // }
  // if ((length || width || height) && !weight) {
  //   return showToasterMessage({
  //     messageType: "error",
  //     description: props.intl.formatMessage({
  //       id: "customer.shipment.shipmentDetails.missingWeight",
  //     }),
  //   });
  // }
  // if (weight && (!length || !width || !height)) {
  //   return showToasterMessage({
  //     messageType: "error",
  //     description: props.intl.formatMessage({
  //       id: "customer.shipment.shipmentDetails.missingDimensions",
  //     }),
  //   });
  // }
  const modalData = {
    modalBody: "Are you sure you want to create shipment ?",
    handleSubmit: () => {
      const payload = {
        props,
      };
      // if(props.height && props.weight && props.width && props.length){

        props.createShipment(payload);
      // }else{
      //   showToasterMessage({description:"Enter the Dimensions"})
      // }
      props.hideModal();
    },
  };
  props.showModal(modalData);
};

export default injectIntl(ProductDetail);
