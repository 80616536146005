import React, { Component } from 'react';
// import config from '../../../../config/environmentConfig';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Input, Button, Row, Col } from 'antd';
import { showToasterMessage } from '../../../../utils';
import { Dropdown } from '../../../general/Dropdown';
import QRCode from 'qrcode.react';
import download from 'downloadjs';
import { ErrorMsg } from '../../../general/ErrorMessage';
import * as find from 'lodash.find';

class ShareTvURL extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //  previewUrl: (props.subdomainName || window.location.origin) + '/app/payment-setup?t=' + props.projectData.relationshipId + '_' + (props.projectData.alpidePaymentGatewayId || 0) + '_' + 4 + '_' + props.projectData.projectMasterId + '_' + (props.projectData.chartOfAccountDetailsId || 0) + '_' + '0',
      allLedgerAccounts: props.allLedgerAccounts
    }
  }

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
    showToasterMessage({
      messageType: 'success', description: this.props.intl.formatMessage(
        {
          id: 'copyToClipboard.text',
          defaultMessage: ''
        }
      )
    });
  }

  componentWillReceiveProps(props) {

    if (props.allLedgerAccounts) {
      this.setState({
        allLedgerAccounts: props.allLedgerAccounts
      });
    }
  }

  render() {
    return (
      <div>
        {!this.state.previewUrl ? <div>
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <FormattedMessage id='header.menuItem.ledgerAccount.label' /><span className="required">*</span>
            </Col>
            <Col span={12} >
              <Dropdown
                items={this.state.allLedgerAccounts}
                className={(this.props.submittedOnce && !this.props.chartOfAccountDetailsId) ? 'input-text-error' : ''}
                valueKeyName='ledgerAccountName'
                optionKeyName='chartOfAccountDetailsId'
                value={this.state.chartOfAccountDetailsId}
                placeholder={this.props.intl.formatMessage({ id: 'header.menuItem.ledgerAccount.label' })}
                onSelect={(optionValue, option) => {
                  let selectedLedger = find(this.state.allLedgerAccounts || [], { chartOfAccountDetailsId: option.value }) || {};
                  this.setState({
                    selectedLedger: selectedLedger,
                    chartOfAccountDetailsId: option.value
                  });
                }}
              />
              <ErrorMsg
                validator={() => { return !this.props.submittedOnce || this.props.chartOfAccountDetailsId }}
                message={this.props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col span={3}>
            </Col>
            <Col className="text-right" span={6}>
              <FormattedMessage id='paymentGateway.available' /><span className="required">*</span>
            </Col>
            <Col span={12} >
              <Dropdown
                items={this.props.availablePaymentGateways}
                className={(this.props.submittedOnce && !this.props.alpidePaymentGatewayId) ? 'input-text-error' : ''}
                valueKeyName='userDefinedGatewayName'
                optionKeyName='alpidePaymentGatewayId'
                value={this.state.alpidePaymentGatewayId}
                placeholder={this.props.intl.formatMessage({ id: 'paymentGateway.available' })}
                onSelect={(optionValue, option) => {
                  let selectedGateway = find(this.props.availablePaymentGateways || [], { alpidePaymentGatewayId: option.value }) || {};
                  this.setState({
                    selectedGateway: selectedGateway,
                    alpidePaymentGatewayId: option.value
                  });
                }}
              />
              <ErrorMsg
                validator={() => { return !this.props.submittedOnce || this.props.alpidePaymentGatewayId }}
                message={this.props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <br />
          <div className="align-footer" style={{ borderTop: '1px solid #e5e5e5', textAlign: 'center' }}>
            <Button style={{ marginTop: '10px' }} type="primary" onClick={() => {
              if (!this.state.chartOfAccountDetailsId) {
                return showToasterMessage({ messageType: 'error', description: this.props.intl.formatMessage({ id: 'ledgerAccount.notSelected.message' }) });
              }
              if (!this.state.alpidePaymentGatewayId) {
                return showToasterMessage({ messageType: 'error', description: this.props.intl.formatMessage({ id: 'paymentGateway.notAvailable.message' }) });
              }
              this.setState({
                previewUrl: 'https://alpide.com/app/payment-setup?t=' + this.props.projectData.relationshipId + '_' + (this.state.alpidePaymentGatewayId || 0) + '_' + 4 + '_' + this.props.projectData.projectMasterId + '_' + (this.state.chartOfAccountDetailsId || 0) ,
              });

            }}>Generate URL</Button>
          </div>
        </div> :


          <div>
            <div> <FormattedMessage id='shareProjectPaymentUrl.link.description' /></div>
            <br />
            <div>
              <Input style={{ width: '90%' }}
                ref={(textarea) => this.textArea = textarea}
                value={this.state.previewUrl}
              />
              <Button title='Copy Link' type="default" style={{ paddingLeft: '5px', paddingRight: '5px' }} onClick={() => this.copyCodeToClipboard()}>
                <i className="fa fa-clone"></i>
              </Button>
            </div>
            {/* <br />
        <br /> */}
            {/* <div className="align-footer" style={{ borderBottom: '1px solid #e5e5e5', textAlign: 'center' }}>
          <Button style={{ marginBottom: '10px' }} type="primary" onClick={() => {
            //window.location = this.state.previewUrl;
            // window.open(this.state.previewUrl, '_blank');
            Object.assign(document.createElement('a'), {
              target: '_blank',
              href: this.state.previewUrl,
            }).click();
          }}>
            <FormattedMessage id='preview.text' />
          </Button>
        </div> */}
            <br />
            <br />
            <div className="align-footer" style={{ textAlign: 'center' }}>
              <div className="HpQrcode">
                <QRCode value={this.state.previewUrl} renderAs="canvas" />
              </div>
              <br />
              <br />
              <div className="align-footer" style={{ borderTop: '1px solid #e5e5e5', textAlign: 'center' }}>
                <Button style={{ marginTop: '10px' }} type="primary" onClick={() => {
                  const canvas = document.querySelector('.HpQrcode > canvas');
                  download(canvas.toDataURL(), this.props.projectData.projectName + ' Payment Link.png');
                }}>Download QR Code</Button>
              </div>
            </div>

          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    availablePaymentGateways: state.studentRegistration.availablePaymentGateways,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
  }
}
export default connect(mapStateToProps)(ShareTvURL);