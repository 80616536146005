/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
//import { Skeleton, Tooltip, Button, Menu, Switch } from "antd";
import PageBreadcrumb from "../../../PageBreadcrumb";
import { AgGridReact } from "ag-grid-react";
import AddMarketplaceState from '../../../../containers/modal/modalBody/settings/taxes/AddMarketplaceState';
import checkIcon from '../../../../assets/images/check.png';
import crossIcon from '../../../../assets/images/cross.png';

const MarketplaceStatesTaxComponent = (props) => {
    const { marketplaceStates, marketplaceTaxCountryId, isTaxAutomated } = props;

    const breadCrumbList = [
        // {
        //     name: 'Dashboard',
        //     link: 'dashboard'
        // },
        {
            name: 'Settings',
            link: '/admin/show-settings'
        },
        {
            name: 'Taxes',
            link: '/admin/settings/taxes'

        },
        {
            name: 'StateTaxes'
        }
    ];

    const agGridStyle = {
        height: "100%",
        width: "100%",
    };

    const deleteHandle = (marketplaceTaxState) => {
        const modalBody = <FormattedMessage id='commons.delete.popup.confimation.msg' defaultMessage='' />;
        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.deleteMarketplaceStateTaxRate(marketplaceTaxState, props);
            },
        };
        props.showModal(modalData);
    };

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return (
                    <FormattedMessage
                        id="setting.marketplaceConfiguration.taxes.stateTaxes.listing.header.state"
                        defaultMessage=""
                    />
                );
            },
            field: "stateName",
            resizable: true,
            minWidth: 110,
            maxWidth: 400,
        },
        {
            headerComponentFramework: () => {
                return (
                    <FormattedMessage
                        id="setting.marketplaceConfiguration.taxes.stateTaxes.listing.header.taxRate"
                        defaultMessage=""
                    />
                );
            },
            cellRendererFramework: (link) => {
                return isTaxAutomated ? 'Automated' : <span>{link.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}% </span>;

            },
            type: 'rightAligned',
            field: "taxRate",
            resizable: true,
            minWidth: 70,
            maxWidth: 300,
        },
        {
            headerComponentFramework: () => {
                return (
                    <FormattedMessage
                        id="setting.marketplaceConfiguration.taxes.stateTaxes.listing.header.taxOnShipping"
                        defaultMessage=""
                    />
                );
            },
            cellRendererFramework: (link) => {
                return link.value ? <img src={checkIcon} style={{ 'height': '15px', 'width': '15px' }} alt="Invoice" /> : <img src={crossIcon} style={{ 'height': '10px', 'width': '10px' }} alt="Invoice" />

            },
            field: "isTaxOnShipping",
            resizable: true,
            minWidth: 110,
            maxWidth: 300,
        },
        {
            field: "action",
            headerComponentFramework: () => {
                return <div className="cursor-pointer">

                </div>;
            },
            colId: "action_1",
            pinned: "right",
            lockPosition: true,
            cellRendererFramework: (params) => {
                return <div>
                    <span className="cursor-pointer" title={props.intl.formatMessage(
                        {
                            id: 'commons.listing.edit.tooltip.msg',
                            defaultMessage: ''
                        })
                    } onClick={
                        () => {
                            props.showModal({
                                title: <FormattedMessage id='setting.marketplaceConfiguration.taxes.editMarketplaceState.model.name' defaultMessage='' />,
                                modalBody: <AddMarketplaceState {...props} rowData={params.data} />,
                                width: 600,
                                hideFooter: true,
                                // wrapClassName: "manage-user-modal"
                            })
                        }}>
                        <i className='fa fa-pencil' />
                    </span>

                    <span style={{ marginLeft: '10px' }} className="cursor-pointer" title={props.intl.formatMessage(
                        {
                            id: 'commons.listing.delete.tooltip.msg',
                            defaultMessage: ''
                        })
                    } onClick={
                        () => {
                            deleteHandle(params.data);
                        }}>
                        <i className='fa fa-trash' />
                    </span>

                </div>;
            },
            minWidth: 110,
            maxWidth: 300,
            suppressMovable: true,
        }
    ];

    return (
        <Fragment>
              <div className="view-top-bar">
                <div className="top-view-left">
                    <div className="page-heading">
                        Account Settings
                    </div>
                    <div className="vertical-sep" />
                    <PageBreadcrumb breadCrumbList={breadCrumbList} />
                </div>
            </div>
            <div className="ledger-details-container">

                <div className="view-container sales-order-details mt0">
                    <div className='team-and-free'>
                        <div className="right-align pb10">
                            <div className="action-dropdown-btn">
                                <button onClick={() => {
                                    props.showModal({
                                        title: <FormattedMessage id='setting.marketplaceConfiguration.taxes.addMarketplaceState.model.name' defaultMessage='' />,
                                        modalBody: <AddMarketplaceState {...props} configuredStates={marketplaceStates[marketplaceTaxCountryId]}/>,
                                        width: 600,
                                        hideFooter: true,
                                        // wrapClassName: "manage-user-modal"
                                    })
                                }}>
                                    <i className='fa fa-plus' />
                                    <FormattedMessage id="setting.marketplaceConfiguration.taxes.stateTaxes.listing.header.state" defaultMessage="" />
                                </button>
                            </div>
                        </div>
                        {
                            marketplaceStates[marketplaceTaxCountryId] && marketplaceStates[marketplaceTaxCountryId].length > 0 ?
                                <span>You are collecting Tax in {marketplaceStates[marketplaceTaxCountryId].length} States of {props.countryName}. </span>
                                : ''
                        }
                        <div className="agGridWrapper">
                            <div className="ag-theme-balham" style={agGridStyle}>
                                <AgGridReact
                                    // onGridReady={onGridReady}
                                    columnDefs={columnDefs}
                                    defaultColDef={{
                                        flex: 1,
                                        autoHeight: true,
                                        wrapText: true,
                                    }}
                                    rowData={marketplaceStates[marketplaceTaxCountryId] || []}
                                    rowDragManaged={true}
                                    domLayout={"autoHeight"}
                                    //getRowHeight={getRowHeight}
                                    suppressDragLeaveHidesColumns={true}
                                    animateRows={true}

                                ></AgGridReact>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};


export default injectIntl(MarketplaceStatesTaxComponent);
