import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmployeeDrawerComp from '../../../../../components/drawer/company/EmployeeMeta/Employee';
import { showModal,hideModal } from '../../../../../actions/commonActions';
import { createEmployeeType } from '../../../../company/EmployeeMeta/EmployeeType/action';

class EmployeeDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...(this.props.empTypeItem || {})

        };
      }

    render() {
        return <EmployeeDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
    }
}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        drawerLoading: state.common.drawerLoading,

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal, 
    createEmployeeType,
    // pushModalToStack,
    // popModalFromStack,

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDrawer);