import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddUpdateBiddingSupplierComponent from '../../../../../components/modal/modalBody/supplier/AddUpdateBiddingSupplier';
import { fetchAllRFQStatus, addRFQStatus, deleteRFQStatus, fetchAllPriceCodes, addRFQPriceCodes, deleteRFQPriceCodes, updateRFQSupplier, updateRFQSupplierAddress } from './action';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../../utils';
import { fetchSupplierDetailsBySupplierId, deleteContact, pushModalToStack, popModalFromStack, resetSupplierDetail, fetchSupplierSummary } from '../../../../../actions/commonActions';
import { generatePDF, printPDF } from '../../../../../actions/downloadFileAction';

class AddUpdateBiddingSupplier extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            editingKey: '',
            isUpdate: (props.biddingSupplierData || {}).supplierId ? true: false,
            supplierData: props.biddingSupplierData,
            rfqData: props.rfqData
        }
    }
    componentWillMount() {
        this.props.resetSupplierDetail();
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: 100 };
        fetchDataIfNeeded('fetchAllRFQStatus', 'rfqStatusList', this.props, payload, true);
        fetchDataIfNeeded('fetchAllPriceCodes', 'rfqPriceCodes', this.props, payload, true);
        
        if (this.props.biddingSupplierData && this.props.biddingSupplierData.supplierId) {
            payload.supplierId = this.props.biddingSupplierData.supplierId;
            fetchDataIfNeeded('fetchSupplierDetailsBySupplierId', 'selectedSupplier', this.props, payload, true);
        } else {
            fetchPaginationDataIfNeeded('fetchSupplierSummary', 'supplierSummary', this.props, payload, true);
        }

    }

    componentWillReceiveProps(props) {        
        if (props.selectedSupplier && props.selectedSupplier.supplierId) {
            this.setState({
                selectedSupplier: props.selectedSupplier,
                selectedAddress: props.selectedSupplier?.boLocationList?.find(
                    (obj) => obj.isDefault === 1),
                selectedContact: props.selectedSupplier?.boContactList?.find(
                      (obj) => obj.isPrimaryContact === 1
                    )

            });
        }
    }

    render() {
        return <div>
            <AddUpdateBiddingSupplierComponent {...this.props} {...this.state} updatePopupState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        rfqStatusList: state.rfq.rfqStatusList,
        rfqPriceCodes: state.rfq.rfqPriceCodes,
        supplierSummary: state.supplier.supplierSummary,
        rfqList:state.rfq.rfqList,
        selectedSupplier: state.common.supplierDetail
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllRFQStatus,
    addRFQStatus,
    deleteRFQStatus,
    fetchAllPriceCodes,
    addRFQPriceCodes,
    deleteRFQPriceCodes,
    generatePDF, printPDF,
    updateRFQSupplier,
    fetchSupplierDetailsBySupplierId,
    deleteContact,
    resetSupplierDetail,
    updateRFQSupplierAddress,
    fetchSupplierSummary,
    pushModalToStack, 
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddUpdateBiddingSupplier);