import React from "react";
import './details.scss';
import { Tabs } from 'antd';
import Communication from "./communications";
import Contact from "./contacts";
import Address from "./address";
import Tasks from "./tasks";
import TaxIdentification from "./taxIdentification";
import SalesLedger from "./ledgerTransactions/salesLedger";
import Invoice from "./ledgerTransactions/invoice";
import Organization from './organization';
import Product from './productSummary';
import Activities from "./activities";
// import Sales from "./transactions/invoice";
import { FormattedMessage } from "react-intl";
// import Contact from "../../../containers/customer/detail/contacts";

// import Tile from '../../tiles/tiles';
// import PageBreadcrumb from "../../PageBreadcrumb";
// import OpenOrder from '../../../containers/modal/modalBody/customer/OpenOrders'
// import TotalOrders from '../../../containers/modal/modalBody/customer/TotalOrders'
// import PaidInvoices from '../../../containers/modal/modalBody/customer/PaidInvoices';
// import UnpaidInvoices from '../../../containers/modal/modalBody/customer/UnpaidInvoices';
// import Transactions from "./transactions";
// import LedgerTransactions from "./ledgerTransactions";
//import TodoList from "./todoList";

const { TabPane } = Tabs;


function callTabApi(key, props) {
    let payload = props.payload;
    payload.filters = {
        customerId: props.customerId
    }
    switch(key){
        case '1':
            if(props?.customer?.leadId){
                props.fetchLeadActivityTrack({...payload, pageSize: 1000, crmLeadId: props.customer.leadId || ''});
            }
            props.getCustomerActivitiesTrack(payload);
            break;

        case '2': //address
            props.fetchCustomerAddresses(payload);
            break;
        case '3': //Contacts
            props.fetchAllContacts(payload);
            break;

        case '4': //Communications
            props.fetchCustomerCommunications(payload);
            break;

        case '5': //Tax
            props.fetchCustomerDetailsByCustomerId(payload);
            break;

        case '6': //Tasks  -- activities renamed as tasks 
            props.fetchCustomerActivities(payload);
            break;

        case '7': //Ledger Summary
            props.fetchCustomerLedgerTxSummaryList(payload);
            break;

        case '8': //Sales
            props.fetchCustomerInvoiceList(payload);
            break;

        // case '9': // Sales
        //     props.fetchCustomerInvoiceList(payload);
        //     break;

        case '10': // Product
            props.getCustomerPurchaseItems(payload);
            props.fetchCustomerPurchaseItemsCount(payload);
            break;

        case '11': // Oranization
            break;

        default:
            break;
    }


}

const Details = (props) => {
    // const openModalOfSelectedTile = (data) => {
    //     switch (data.type) {
    //         case CUSTOMER_LISTING_PAGE.TOTAL_ORDERS:
    //             props.showModal(
    //                 {
    //                     title: <Fragment>
    //                         <div className='ant-modal-title'><FormattedMessage id='totalOrder.text' defaultMessage="" /> </div>
    //                     </Fragment>,
    //                     modalBody: <TotalOrders {...props} />,
    //                     width: 1000,
    //                     hideFooter: true,
    //                     wrapClassName: 'tiles-modal'
    //                 }
    //             );
    //             break;
    //         case CUSTOMER_LISTING_PAGE.OPEN_SO:
    //             props.showModal(
    //                 {
    //                     title: <Fragment>
    //                         <div className='ant-modal-title'>
    //                             <FormattedMessage id='openSalesOrder.text' defaultMessage="" />
    //                         </div>
    //                     </Fragment>,
    //                     modalBody: <OpenOrder {...props} />,
    //                     width: 1000,
    //                     hideFooter: true,
    //                     wrapClassName: 'tiles-modal'
    //                 }
    //             );
    //             break;
    //         case CUSTOMER_LISTING_PAGE.UNPAID_BALANCE:
    //             props.showModal(
    //                 {
    //                     title: <Fragment>
    //                         <div className='ant-modal-title'>
    //                             <FormattedMessage id='salesRevenue.text' defaultMessage="" />
    //                         </div>
    //                     </Fragment>,
    //                     modalBody: <UnpaidInvoices  {...props} />,
    //                     width: 1000,
    //                     hideFooter: true,
    //                     wrapClassName: 'tiles-modal'
    //                 }
    //             );
    //             break;
    //         case CUSTOMER_LISTING_PAGE.SALES_REVENUE:
    //             props.showModal(
    //                 {
    //                     title: <Fragment>
    //                         <div className='ant-modal-title'>
    //                             <FormattedMessage id='paidInvoices.text' defaultMessage="" />
    //                         </div>
    //                     </Fragment>,
    //                     modalBody: <PaidInvoices {...props} />,
    //                     width: 1000,
    //                     hideFooter: true,
    //                     wrapClassName: 'tiles-modal'
    //                 }
    //             );
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // const tilesData = [{
    //     footer: <FormattedMessage id='customer.listing.tiles.tatalOrders.label' defaultMessage='' />,
    //     count: 0,
    //     type: CUSTOMER_LISTING_PAGE.TOTAL_ORDERS,
    //     icon: 'fa fa-bullseye',
    //     antIcon: <BookTwoTone />,
    //     color: 'darkGray-tile'
    // },
    // {
    //     footer: <FormattedMessage id='customer.listing.tiles.openSalesOrder.label' defaultMessage='' />,
    //     count: 0,
    //     icon: 'fa fa-file-text-o',
    //     antIcon: <ContainerTwoTone />,
    //     type: CUSTOMER_LISTING_PAGE.OPEN_SO,
    //     color: 'green-tile'
    // },
    // {
    //     footer: <FormattedMessage id='customer.listing.tiles.unpaidBalance.label' defaultMessage='' />,
    //     count: 0,
    //     currencyIcon: props.companyInfo.currencyIcon,
    //     antIcon: <EuroCircleTwoTone />,
    //     type: CUSTOMER_LISTING_PAGE.UNPAID_BALANCE,
    //     color: 'blue-tile'
    // },
    // {
    //     footer: <FormattedMessage id='customer.listing.tiles.salesRevenue.label' defaultMessage='' />,
    //     count: 0,
    //     currencyIcon: props.companyInfo.currencyIcon,
    //     antIcon: <DollarCircleTwoTone />,
    //     type: CUSTOMER_LISTING_PAGE.SALES_REVENUE,
    //     color: 'magenta-tile'
    // }];
    // const customerObj = props.customer || {};
    // const customerAddress = (customerObj.boLocationList || []).find(x => x.isDefault) || {};

    // const breadCrumbList = [
    //     {
    //         name: <FormattedMessage id='header.menuItem.salesHead.label' defaultMessage="" />,
    //     }, 
    //     {
    //         name: <FormattedMessage id='expense.customers' defaultMessage="" />
    //     }, 
    //     {
    //         name: <FormattedMessage id='header.menuItem.customer.label' defaultMessage="" />,
    //     }
    // ];
    return (<>
    
     {/* <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='header.menuItem.customer.label' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
       
      </div> */}

           
            
            {/* <div className="mb15" /> */}
            {/* <Tile tilesData={tilesData} getSelectedTile={(data) => {
                openModalOfSelectedTile(data);
            }} /> */}
            <div className="view-container" style={{margin: '10px 0px'}}>
                {/* <Collapse accordion>
                    <Panel header={props.customerName} key="1">
                        <Row>
                            <Col span={6}>
                                <div className="address-info">
                                    <div>{customerAddress.streetAddress1}</div>
                                    <div>{customerAddress.streetAddress2}</div>
                                    <div>{customerAddress.stateName}</div>
                                </div>
                            </Col>
                            <Col span={10}>
                                <div className="customer-identity-number">

                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='drawer.customer.customerType' />
                                        </Col>

                                        <Col span={14}>
                                            {customerObj.customerType ? <FormattedMessage id={customerObj.customerType} /> : '-'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='businessType.text' />
                                        </Col>

                                        <Col span={14}>
                                            {customerObj.businessType ? <FormattedMessage id={customerObj.businessType} /> : '-'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='drawer.customer.customerSource' />
                                        </Col>

                                        <Col span={14}>
                                            {customerObj.sourceName ? <FormattedMessage id={customerObj.sourceName} /> : '-'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='drawer.customer.industry' />
                                        </Col>
                                        <Col span={14}>
                                            {customerObj.industryName ? <FormattedMessage id={customerObj.industryName} /> : '-'}
                                        </Col>
                                    </Row>
                                    {customerObj.gstMechanism ? <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='common.gst.mechanism' defaultMessage='' />
                                        </Col>

                                        <Col span={14}>
                                            {customerObj.gstMechanism || '-'}
                                        </Col>
                                    </Row> : ''
                                    }
                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='common.Located.in.SEZ' defaultMessage='' />
                                        </Col>

                                        <Col span={14}>
                                            {customerObj.locatedInSez === 1 ? 'true' : 'false'}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="customer-identity-number">

                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='drawer.customer.paymentTerm' />
                                        </Col>

                                        <Col span={14}>
                                            {customerObj.paymentTermName ? <FormattedMessage id={customerObj.paymentTermName} /> : '-'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='drawer.customer.websiteUrl' />
                                        </Col>

                                        <Col span={14}>
                                            {customerObj.webSite ? <FormattedMessage id={customerObj.webSite} /> : '-'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='drawer.customer.rating' />
                                        </Col>

                                        <Col span={14}>
                                            {customerObj.starRating ? <FormattedMessage id={customerObj.starRating} /> : '-'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='common.Relationship.since' defaultMessage='' />
                                        </Col>

                                        <Col span={14}>
                                            {customerObj.relationshipSince ? getMomentDateForUIReadOnly({ date: new Date(customerObj.relationshipSince), format }) : '-'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='common.Total.balance.due' defaultMessage='' />
                                        </Col>

                                        <Col span={14}>
                                            <strong>{props.companyInfo.currencyIcon ?
                                                <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</strong>{(customerObj.totalAmountDue || 0).toFixed(2)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={10}>
                                            <FormattedMessage id='common.available.credit' defaultMessage='' />
                                        </Col>

                                        <Col span={14}>
                                            <strong>{props.companyInfo.currencyIcon ?
                                                <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}</strong>{(Number(props.availableCredits || 0)).toFixed(2)}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse> */}
                <Tabs 
                    type="line"
                    defaultActiveKey= '1'
                    tabBarGutter={32}
                    onChange={(key) => {
                        callTabApi(key, props);
                    }} 
                    animated={false}
                >
                 
                    <TabPane tab="Activities" key="1">
                        <Activities {...props} />
                    </TabPane>

                    <TabPane tab="Tasks" key="6">
                        <Tasks {...props} />
                    </TabPane>

                    {/* <TabPane tab="Transactions" key="22">
                        <Transactions {...props} />
                    </TabPane> */}

                    <TabPane tab="Address" key="2">
                        <Address {...props} />
                    </TabPane>

                    <TabPane tab="Contacts" key="3">
                        <Contact {...props} />
                    </TabPane>

                    <TabPane tab="Communications" key="4">
                        <Communication {...props} />
                    </TabPane>

                    <TabPane tab={<FormattedMessage id='addItem.text.taxIdentification' defaultMessage='' />} key="5">
                        <TaxIdentification {...props} />
                    </TabPane>

                    {/* <TabPane tab={<FormattedMessage id='salesTransactions.text' defaultMessage='' />} key="7">
                        <LedgerTransactions {...props} />
                    </TabPane> */}

                    <TabPane tab={'Ledger Summary'} key="7">
                        <SalesLedger {...props} />
                    </TabPane>

                    <TabPane tab={'Sales'} key="8">
                        <Invoice {...props} />
                    </TabPane>

                    {/* <TabPane tab="Sales" key="9">
                        <Sales {...props} />
                    </TabPane> */}
                   
                    <TabPane tab={'Product'} key="10">
                        <Product {...props} />
                    </TabPane>
                    
                    {props.customerDetails?.customerType === 'business' ? 
                    
                        <TabPane tab={'Organization'} key="11">
                            <Organization {...props} />
                        </TabPane>
                        :
                        ''
                    }

                    {/* <TabPane tab="To-Do List" key="7">
                        <TodoList {...props} />
                    </TabPane> */}
                </Tabs>
               
            </div>
        </>
    )
};

export default Details;
