import React from 'react';
import { FormattedMessage } from 'react-intl';
// import { MailOutlined } from '@ant-design/icons';
import { DatePicker, Row, Col, Radio, Select, InputNumber } from 'antd';
import moment from 'moment-timezone';
import { CONSTANTS } from '../../../../../static/constants';
import { getCurrentDateForBackend, isValidDateRange, showToasterMessage } from '../../../../../utils';
const { Option } = Select;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const RecurringInvoiceSetupForForm = (props) => {
    function invalidStartDate(current) {
        return current && current < moment().endOf('day');
    }
    function invalidEndDate(current) {
        return current && current < (props.startDate ? moment(props.startDate).endOf('day') : moment().endOf('day') );
    }
const { updateState } = props;

    return (
        <div className="modal-dialog ">
            <div className="modal-content full-width">
               {/*  <Row>
                    <Col span={24}>
                        <Row type='flex' justify='center' className=" pt10 text-right ant-legacy-form-item-control">
                            <Col span={12} justify='center' className=" pr10 "> <FormattedMessage id='makeInstallment.text' defaultMessage='' />: </Col>
                            <Col span={12} style={{textAlign:'left'}}>
                                <Checkbox checked={props.isMakeInstallment} onChange={(e) => {
                                    updateState({ isMakeInstallment: e.target.checked ? 1 : 0 })
                                }} />
                            </Col>
                        </Row>
                    </Col>
                    {props.isMakeInstallment ?
                    <Col span={12}>
                    <Row type='flex' justify='center' className=" pt10 text-right read-only-content ant-legacy-form-item-control">
                                <Col span={12} justify='center' className=" pr10 "><FormattedMessage id='totalInstallment.text' defaultMessage='' />: </Col>
                                <Col span={12}>
                                <InputNumber style={{width: '100%'}} value={props.totalInstallment} placeholder="" min={1} onChange={(value) => {
                                    updateState({ totalInstallment: value })
                                }} />
                            </Col>
                        </Row>
                    </Col> : ''} 
                </Row>*/}

                <Row>
                    <Col span={6}></Col>
                    <Col span={12}>
                        <Row type='flex' justify='center' className=" pt10 text-right ant-legacy-form-item-control">
                            <Col span={12} justify='center' className=" pr10 "> <FormattedMessage id={'registration.form.installment.startDate.label'}/>: </Col>
                            <Col span={12}>
                                <DatePicker
                                    allowClear={false}
                                    format={format}
                                    key={new Date()}
                                    disabledDate={invalidStartDate}
                                    defaultValue={moment(props.startDate ? new Date(props.startDate) : new Date(), format)}
                                    onChange={(selectedDate) => {
                                        props.updateState({
                                            startDate: selectedDate
                                        })
                                    }} />
                            </Col>
                        </Row>
                    </Col>
                    <div style={{clear:'both'}}></div>
                    <Col span={6}></Col>
                    <Col span={6}></Col>
                    <Col span={12}>
                            <Row type='flex' justify='center' className=" pt10 text-right read-only-content ant-legacy-form-item-control">
                                <Col span={12} justify='center' className=" pr10 "><FormattedMessage id={'registration.form.installment.endDate.label'} />: </Col>
                                <Col span={12}>
                                    <DatePicker
                                        allowClear={false}
                                        format={format}
                                        disabledDate={invalidEndDate}
                                        key={props.endDate ? props.endDate : moment(new Date(), format)}
                                        defaultValue={moment(props.endDate ? new Date(props.endDate) : new Date(), format)}
                                        onChange={(selectedDate) => {
                                            updateState({
                                                endDate: selectedDate
                                            })
                                        }} />
                                </Col>
                            </Row> 
                    </Col>
                </Row>

                {/* <Row type='flex' justify='center' className=" pt0 text-right ant-legacy-form-item-control">
                    <Col span={5} justify='center' className=" pr10 "><FormattedMessage id='recurringInvoice.setup.neverExpires' defaultMessage='' />: </Col>
                    <Col span={7} className="  text-left">
                        <Checkbox checked={(props.isExpire === 1)} onChange={(e) => {
                            props.updateState({
                                isExpire: e.target.checked ? 1 : 0,
                            })
                        }} />
                    </Col>
                </Row> */}
                <br></br>
                <hr className=" pr20 "></hr>
                <Row type='flex' justify='center' className=" pt10 ant-legacy-form-item-control">
                    <Col span={4} style={{ borderRight: "1px solid #444" }} className=" borderRight pt5 pb0 ">
                        <Radio.Group onChange={(e) => {
                            updateState({
                                period: e.target.value,
                            })
                        }}  value={props.period}>
                            <Radio value='daily' className=" pt10 ">
                                <FormattedMessage id='recurringInvoice.setup.daily' defaultMessage='' />
                            </Radio>
                            <br></br>
                            <Radio value='weekly' className=" pt10 ">
                                <FormattedMessage id='recurringInvoice.setup.weekly' defaultMessage='' />
                            </Radio>
                            <br></br>
                            <Radio value='monthly' className=" pt10 ">
                                <FormattedMessage id='recurringInvoice.setup.monthly' defaultMessage='' />
                            </Radio>
                            <br></br>
                            <Radio value='yearly' className=" pt10 ">
                                <FormattedMessage id='recurringInvoice.setup.yearly' defaultMessage='' />
                            </Radio>
                        </Radio.Group>
                    </Col>
                    <Col span={20} className=" pl20 ">
                    {props.period === 'daily' ?
                            <Row type='flex' className=" pt50 mt20 ant-legacy-form-item-control"  >
                                <Col span={4} className="text-center pt30"></Col>
                                <Col span={16} >
                                <FormattedMessage id='recurringInvoice.setup.daily.message' defaultMessage='' />
                                </Col>
                            </Row> : ''
                        }
                        {/* <Row style={{ borderBottom: "1px solid #444" }} className="pt30"></Row> */}
                        {props.period === 'weekly' ?
                            <Row className="pt50 mt20 ant-legacy-form-item-control">
                                <Col span={9}><FormattedMessage id='recurringInvoice.setup.generatedOn.message' defaultMessage='' /></Col> 
                                <Col span={7} > 
                                    <Select onChange={(value) => {
                                        updateState({
                                            dayOfWeek: value,
                                        })
                                    }} 
                                    style={{ width: 150 }}
                                    placeholder="Days"
                                    optionFilterProp="children" 
                                    value={props.dayOfWeek}>
                                    <Option value="1"><FormattedMessage id='sunday' defaultMessage='' /></Option>
                                    <Option value="2"><FormattedMessage id='monday' defaultMessage='' /></Option>
                                    <Option value="3"><FormattedMessage id='tuesday' defaultMessage='' /></Option>
                                    <Option value="4"><FormattedMessage id='wednusday' defaultMessage='' /></Option>
                                    <Option value="5"><FormattedMessage id='thursday' defaultMessage='' /> </Option>
                                    <Option value="6"><FormattedMessage id='friday' defaultMessage='' /></Option>
                                    <Option value="7"><FormattedMessage id='saturday' defaultMessage='' /></Option>
                                </Select>
                                </Col>
                                <Col span={6} style={{paddingLeft:'10px'}}><FormattedMessage id='recurringInvoice.setup.weekly.message' defaultMessage='' /></Col>
                            </Row>
                        : ''
                        }
                         {props.period === 'monthly' ?
                        <Row className="pt50 mt20 ant-legacy-form-item-control">
                            <Col span={9}><FormattedMessage id='recurringInvoice.setup.generatedOn.message' defaultMessage='' /></Col>
                            <Col span={4} ><InputNumber value={props.dayOfMonth} placeholder="" min={1} max={31} onChange={(value) => {
                            updateState({dayOfMonth: value })
                        }}/></Col>
                            <Col span={7} style={{paddingLeft:'20px'}}><FormattedMessage id='recurringInvoice.setup.monthly.message' defaultMessage='' /></Col>
                        </Row>
                        : ''
                        }
                        {props.period === 'yearly' ?
                        <Row className="pt50 mt20 ant-legacy-form-item-control">
                            <Col span={9}><FormattedMessage id='recurringInvoice.setup.generatedOn.message' defaultMessage='' /></Col>
                            <Col span={6}>
                                <Select showSearch
                                    style={{ width: 130 }}
                                    placeholder="January"
                                    optionFilterProp="children" 
                                    value={props.monthOfYear}
                                    onChange={(value) => {
                                        updateState({
                                            monthOfYear: value,
                                        })
                                    }} >
                                         <Option value="1"><FormattedMessage id='january' defaultMessage='' /></Option>
                                         <Option value="2"><FormattedMessage id='february' defaultMessage='' /> </Option>
                                         <Option value="3"><FormattedMessage id='march' defaultMessage='' /></Option>
                                         <Option value="4"><FormattedMessage id='april' defaultMessage='' /> </Option>
                                         <Option value="5"><FormattedMessage id='may' defaultMessage='' /> </Option>
                                         <Option value="6"><FormattedMessage id='june' defaultMessage='' /></Option>
                                         <Option value="7"><FormattedMessage id='july' defaultMessage='' /></Option>
                                         <Option value="8"><FormattedMessage id='august' defaultMessage='' /></Option>
                                         <Option value="9"><FormattedMessage id='september' defaultMessage='' /></Option>
                                         <Option value="10"><FormattedMessage id='october' defaultMessage='' /></Option>
                                         <Option value="11"><FormattedMessage id='november' defaultMessage='' /></Option>
                                         <Option value="12"><FormattedMessage id='december' defaultMessage='' /></Option>

                                </Select>
                            </Col> <Col span={4}><InputNumber min={1} value={props.dayOfYear} max={31} style={{width:'70px'}}  defaultValue={1} onChange={(value) => {
                            updateState({dayOfYear: value })
                        }}/>
                            </Col>
                            <Col span={5}><FormattedMessage id='recurringInvoice.setup.yearly.message' defaultMessage='' /></Col>
                        </Row>
                        : ''
                    }
                    </Col>
                </Row>
                <hr className="mt20 "></hr>

                {/* <Row type='flex' justify='center' className="pt10 ant-legacy-form-item-control ">
                    <Col span={7} style={{textAlign: 'right', paddingRight: '10px'}}> <FormattedMessage id='recurringInvoice.setup.copyReminder' />
                    <Tooltip placement="top" title={props.intl.formatMessage({ id: 'recurringInvoice.setup.copyReminder.helpMessage' })} trigger="click">
                            <i className="fa fa-question-circle-o pl5 cursor-pointer" />
                        </Tooltip>
                        </Col>
                    <Col span={1}><Checkbox checked={props.isCopyReminder} onChange={(e) => {
                            updateState({ isCopyReminder: e.target.checked})
                        }} /></Col>
                        <Col span={2}></Col>
                </Row> */}

                {/* <Row type='flex' justify='center' className="pt10 ant-legacy-form-item-control ">
                    <Col span={5} style={{textAlign: 'right', paddingRight: '10px'}}> <FormattedMessage id='recurringInvoice.setup.emailCustomer' defaultMessage='' /> </Col>
                    <Col span={1}><Checkbox checked={props.notifyCustomer} onChange={(e) => {
                            updateState({
                                notifyCustomer: e.target.checked,
                                emailDetailDrawerVisible: e.target.checked,
                                emailContext: 'recurringInvoice'
                            })
                        }} /></Col>

                    <Col span={5} >
                        {props.emailDetails ? <Button icon={<MailOutlined />} onClick={() => {
                            updateState({
                                emailDetailDrawerVisible: true,
                                emailContext: 'recurringInvoice'
                            })
                        }}>
                        </Button>
                            : ''
                        }</Col> 
                </Row> */}
                {/* <hr className="mt10 "></hr> */}
                <Row justify='center'>
                    <button type="button" className="ant-btn ant-btn-primary mt20" onClick={() => {
                        if(!props.isMakeInstallment && !isValidDateRange(props.startDate, props.endDate)){
                           return showToasterMessage({
                                description: props.intl.formatMessage({ id: 'invalid.dateRange.message', defaultMessage: '' }),
                                messageType: 'error'
                            })
                        }
                        if((props.period === 'weekly' && !props.dayOfWeek) || (props.period === 'monthly' && !props.dayOfMonth) || (props.period === 'yearly' && !props.dayOfYear)){
                            return showToasterMessage({
                                 description: props.intl.formatMessage({ id: 'validation.day.requiredMessage', defaultMessage: '' }),
                                 messageType: 'error'
                             })
                         }
                         if((props.period === 'yearly' && !props.monthOfYear)){
                            return showToasterMessage({
                                 description: props.intl.formatMessage({ id: 'validation.month.requiredMessage', defaultMessage: '' }),
                                 messageType: 'error'
                             })
                         }
                        //  if(props.isMakeInstallment && !props.totalInstallment){
                        //     return showToasterMessage({
                        //          description: props.intl.formatMessage({ id: 'totalInstallment.validation.message', defaultMessage: '' }),
                        //          messageType: 'error'
                        //      })
                        //  }
                         
                        
                        const payload = {
                            recurringInvoiceId: props.recurringInvoiceId || 0,
                            period: props.period,
                            relationshipId: props.relationshipId,
                            startDate: getCurrentDateForBackend(props.startDate || new Date()), // props.startDate ? new Date(props.startDate): null,
                            endDate: props.isMakeInstallment ? null : getCurrentDateForBackend(props.endDate || new Date()), //props.endDate ? new Date(props.endDate): null,
                            dateCreated: getCurrentDateForBackend(new Date()),
                            isExpire: props.isExpire,
                            createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                            isSendEmail: props.notifyCustomer ? 1 : 0,
                            emailDetails: props.notifyCustomer ? props.emailDetails || '' : '',
                            isActive: 1,
                            //  isCopyReminder: props.isCopyReminder? 1: 0,
                            isMakeInstallment: props.isMakeInstallment,
                           // totalInstallment: props.totalInstallment
                        }
                        switch (props.period) {
                            // case 'daily': {
                            //     payload
                            // }
                            case 'weekly': {
                                payload.dayOfWeek = props.dayOfWeek;
                                break;
                            }
                            case 'monthly': {
                                payload.dayOfMonth = props.dayOfMonth;
                                break;
                            }
                            case 'yearly': {
                                payload.dayOfYear = props.dayOfYear;
                                payload.monthOfYear = props.monthOfYear;
                                break;
                            }
                            default: {

                            }
                        }
                        props.addRecurringInvoiceSettings(payload);
                        props.popModalFromStack();
                       
                    }}><FormattedMessage id='forgot.btn.save' defaultMessage='' /></button>
                </Row>
            </div>
        </div>
    );

}

export default RecurringInvoiceSetupForForm;