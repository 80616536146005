import { TAXES_SETTINGS_ACTION_LIST } from "../static/constants";

const initialState = {
    marketplaceCountries: [],
    countries: [],
    countryStates: {},
    marketplaceStates: {},
};

const TaxSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case TAXES_SETTINGS_ACTION_LIST.MARKETPLACE_COUNTRIES_LIST:
            return {
                ...state,
                marketplaceCountries: action.data,
            };
        case TAXES_SETTINGS_ACTION_LIST.COUNTRIES_LIST:
            return {
                ...state,
                countries: action.data,
            };
        case TAXES_SETTINGS_ACTION_LIST.MARKETPLACE_COUNTRY_STATES:
            return {
                ...state,
                marketplaceStates: {
                    ...state.marketplaceStates,
                    [action.data.marketplaceTaxCountryId]: action.data.list,
                },
            };
        case TAXES_SETTINGS_ACTION_LIST.COUNTRY_STATES:
            return {
                ...state,
                countryStates: {
                    ...state.countryStates,
                    [action.data.countryId]: action.data.list,
                },
            };
        default:
            return state;
    }
};

export default TaxSettingReducer;
