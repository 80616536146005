/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, DatePicker, TimePicker, Row, Col, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { CustomButton } from '../../../general/CustomButton';
import { CustomUploader } from '../../../general/CustomUploader';
import '../../../../assets/stylesheets/common/_email_template.scss';
import { getCurrentDateForBackend, showToasterMessage, bytesToSize, getMomentDateForUI } from './../../../../utils';
import { EMAIL_BODY_CONFIGURATION } from '../../../../static/ckEditorConfigConstants';
//import { PLACEHOLDER_CONSTANTS } from '../../../../static/placeholderConstants';
import { CONSTANTS } from '../../../../static/constants';
import CKEditor from '../../../general/CustomCKEditor/ckeditor';
//import filter from 'lodash.filter';
import { Loading3QuartersOutlined } from '@ant-design/icons';
//import uniq from 'lodash.uniq';
//import { max } from 'moment-timezone';
import moment from 'moment-timezone';
const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
//const { Option } = Select;

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

class EmailBody extends Component {

  constructor(props) {
    super(props);
    // let fileList = [{
    //     uid: '-1',
    //     name: (props.txData || {}).fileName || "Document.pdf",
    //     status: 'done',
    //     url: this.props.txFileUrl
    //   }];
    //   if(props.txData2){
    //     fileList.push({
    //       uid: '-2',
    //       name: (props.txData2 || {}).fileName || "Document.pdf",
    //       status: 'done',
    //       url: this.props.txFileUrl2
    //     })
    //   }
    this.state = {
      sender: props.userInfo.email || '',
      receivers: props.receivers || [],
      ccRecievers: props.ccRecievers || [],
      bccRecievers: props.bccRecievers || [],
      subject: props.subject,
      broadcastMessageId : props.broadcastMessageId,
      body: props.body || props.emailBody,
      emailBody: props.body || props.emailBody,
      ccVisible: props.ccVisible || false,
      bccVisible: props.bccVisible || false,
      isSendInvoicePdf: props.isSendInvoicePdf || 0,
      updateReminder: props.updateReminder,
      docName: props.docName,
      attachments: {
      },
    };

    this.uploadButtonRef = React.createRef();

    this.formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 4 },
      },
      wrappercol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };

    this.tailFormItemLayout = {
      wrappercol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 22,
          offset: 2,
        },
      },
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...(this.props.emailPayloadProps || {}),
          sender: values.sender,
          to: Array.isArray(values.receivers) ? values.receivers : [values.receivers],
          // cc: Array.isArray(values.ccRecievers) ? values.ccRecievers: [values.ccRecievers],
          // bcc: Array.isArray(values.bccRecievers) ? values.bccRecievers: [values.bccRecievers],
          subject: values.subject,
          message: this.state.body,
          sendMeACopy: values.sendMeACopy ? 1 : 0
        };
        if (values.ccRecievers) {
          payload.cc = Array.isArray(values.ccRecievers) ? values.ccRecievers : [values.ccRecievers];
        }
        if (values.bccRecievers) {
          payload.bcc = Array.isArray(values.bccRecievers) ? values.bccRecievers : [values.bccRecievers];
        }
        this.props.updateState({ emailDetails: JSON.stringify(payload), emailDetailDrawerVisible: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    // const receiverItems = [];
    // const ccRecieversItems = [];
    // const bccRecieversItems = [];
    // const invoiceStatusList = [];
    // const studentStatusList = [];
    // const dgOptionList = [];

    // uniq(this.props.receiverItems).forEach((data, i) => {
    //   receiverItems.push(<Option value={data}>{data}</Option>);
    // });

    // uniq(this.props.ccRecieversList).forEach((data, i) => {
    //   ccRecieversItems.push(<Option key={data}>{data}</Option>);
    // });

    // uniq(this.props.bccRecieversList).forEach((data, i) => {
    //   bccRecieversItems.push(<Option key={data}>{data}</Option>);
    // });

    
    this.defaultFileList = [];
    // this.defaultFileList = [
    //   {
    //     uid: '-1',
    //     name: (this.props.txData || {}).fileName || 'Document.pdf',
    //     status: 'done',
    //     url: this.props.txFileUrl,
    //     blobData: this.props.txFile
    //   }
    // ]
    // if (this.props.txData2) {
    //   this.defaultFileList.push({
    //     uid: '-2',
    //     name: (this.props.txData2 || {}).fileName || 'Document2.pdf',
    //     status: 'done',
    //     url: this.props.txFileUrl2,
    //     blobData: this.props.txFile2
    //   })
    // }
    // const insertTextInSubject = (props, textToInsert) => {
    //   if (this.state.subject) {
    //     let subject = this.state.subject.slice(0, this.state.subjectCursor) + textToInsert + this.state.subject.slice(this.state.subjectCursor + 1);
    //     this.props.form.setFieldsValue({ subject });
    //     this.setState({ subject: subject, subjectCursor: this.state.subjectCursor + textToInsert.length })
    //   } else {
    //     this.setState({ subject: textToInsert, subjectCursor: this.state.subjectCursor + textToInsert.length })
    //   }
    // }
    // const getSubjectPlaceHolders = (props, editorName) => {
    //   return <div style={{ width: '400px' }}>
    //     <Row type='flex'>
    //     {( props.docName === 'students') ?
    //         <Col span={8}>
    //           <div style={{ fontWeight: '500' }}>Student</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
    //         </Col> : ''
    //       }
    //       {(props.docName !== 'registration' && props.docName !== 'students') ?
    //         <Col span={8}>
    //           <div style={{ fontWeight: '500' }}>Invoice</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
    //           {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
    //         </Col> : ''
    //       }
    //       <Col span={8}>
    //         <div style={{ fontWeight: '500' }}>Customer</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
    //         {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_BALANCE);}}>Customer Amount</div> */}
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
    //         {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DEPARTMENT);}}>Department</div>
    //               <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DESIGNATION);}}>Designation</div> */}
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
    //       </Col>
    //       <Col span={8}>
    //         <div style={{ fontWeight: '500' }}>Organization</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STORE_NAME); }}>Name</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.USER); }}>User</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_EMAIL); }}>Email</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_PHONE); }}>Phone #</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_FAX); }}>Fax #</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
    //       </Col>
    //     </Row>
    //   </div>
    // }

    // const getPlaceHolders = (props, editorName) => {
    //   return <div style={{ width: '400px' }}>
    //     <Row type='flex'>
    //     {(props.docName === 'students') ?
    //         <Col span={8}>
    //           <div style={{ fontWeight: '500' }}>Student</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
    //         </Col> : ''}
    //       {(props.docName !== 'registration' && props.docName !== 'students') ?
    //         <Col span={8}>
    //           <div style={{ fontWeight: '500' }}>Invoice</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
    //           <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
    //           {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
    //         </Col> : ''}
    //       <Col span={8}>
    //         <div style={{ fontWeight: '500' }}>Customer</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div>
    //         {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_BALANCE);}}>Customer Amount</div> */}
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div>
    //         {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DEPARTMENT);}}>Department</div>
    //               <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DESIGNATION);}}>Designation</div> */}
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div>
    //       </Col>
    //       <Col span={8}>
    //         <div style={{ fontWeight: '500' }}>Organization</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.STORE_NAME); }}>Name</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.USER); }}>User</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ORG_EMAIL); }}>Email</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ORG_PHONE); }}>Phone #</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ORG_FAX); }}>Fax #</div>
    //         <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
    //       </Col>
    //     </Row>
    //   </div>
    // }


    return (
      <>
        <div className="modal-content" style={{height: '495px', overflowY: 'auto'}}>
          <Spin
            indicator={antIcon}
            className="spinner"
            tip="Loading..."
            spinning={this.props.isEmailSpinner}
          >
            <Form
              className="email-form-group"
              {...this.formItemLayout}
              onSubmit={this.handleSubmit}
              hideRequiredMark={true}
            >
              <div className="scrollable-content">
                {/* <div className="top-button" style={{ cursor: 'pointer', marginTop: '4px', padding: '4px' }}>
      <Popover content={getSubjectPlaceHolders(this.state, 'editorSubject')} placement="bottom" trigger="click" onClick={() => { this.setState({ isSubjectPopoverVisible: !this.state.isSubjectPopoverVisible, isPopoverVisible: false }); }}>
        Placeholder <i className='fa fa-caret-square-o-down' />
      </Popover>
    </div> */}
                <label>Subject</label>
                <Form.Item className="subject-form-control">
                  {getFieldDecorator("subject", {
                    initialValue: this.state.subject || this.props.subject,
                    rules: [
                      {
                        required: true,
                        message: "Subject is required.",
                      },
                      {
                        max: 500,
                        message:
                          "Subject cannot be greater than 500 characters",
                      },
                    ],
                  })(
                    <Input
                      onChange={(e) => {
                        this.setState({
                          subject: e.target.value,
                          subjectCursor: e.target.selectionStart,
                        });
                      }}
                      onClick={(e) => {
                        this.setState({
                          subjectCursor: e.target.selectionStart,
                          isPopoverVisible: false,
                          isSubjectPopoverVisible: false,
                        });
                      }}
                    />
                  )}
                </Form.Item>

                {/* <div className="top-button" style={{ cursor: 'pointer', position: 'absolute', marginTop: '30px', right: '10px', zIndex: '91' }}>
      <Popover content={getPlaceHolders(this.state, 'editor')} placement="bottom" trigger="click" onClick={() => { this.setState({ isPopoverVisible: !this.state.isPopoverVisible, isSubjectPopoverVisible: false }); }}>
        Placeholder <i className='fa fa-caret-square-o-down' />
      </Popover>
    </div> */}
                <label>Message</label>
                <Form.Item colon={false} className="email-editor">
                  {getFieldDecorator("body", {
                    rules: [
                      {
                        required: true,
                        message: "Message is required.",
                      },
                    ],
                    initialValue: this.state.emailBody || this.props.emailBody,
                  })(
                    <CKEditor
                      // editor={ClassicEditor}
                      // config={FULL_CONFIGURATION}
                      key={`${"emailBody"}`}
                      data={this.state.data}
                      onInit={(editor) => {
                        this.editor = editor;
                      }}
                      onChange={(event) => {
                        const data = event.editor.getData();
                        this.setState({ body: data });
                      }}
                      onAttachmentClick={() => {
                        this.uploadButtonRef &&
                          this.uploadButtonRef.current &&
                          ReactDOM.findDOMNode(
                            this.uploadButtonRef.current
                          ).click();
                      }}
                      config={EMAIL_BODY_CONFIGURATION}
                    />
                  )}
                </Form.Item>

                {/* <Form.Item className="copy-checkbox">
      {getFieldDecorator('sendMeACopy', {
      })(
          <Fragment>
            <Checkbox id="sendCopy" checked={this.props.isSendCopy} onChange={(e) =>{
              this.props.updateState({
                isSendCopy: e.target.checked
              })
            }} />
            <label className="pl10" htmlFor="sendCopy">Send me a copy</label>
          </Fragment>
        )}
    </Form.Item> */}
                {/* {!this.props.isFormSetting &&
      <Form.Item className="copy-checkbox">
        {getFieldDecorator('isSendInvoicePdf', {
        })(
          <Fragment>
            <Checkbox id="isSendInvoicePdf" checked={this.state.isSendInvoicePdf} onChange={(e) => {
              this.setState({
                isSendInvoicePdf: e.target.checked
              })
            }} />
            <label className="pl10" htmlFor="isSendInvoicePdf"><FormattedMessage id='attachInvoicePdf.text' defaultMessage='' /></label>
          </Fragment>
        )}
      </Form.Item>
    } */}
                <div className={"file-uploader-container"}>
                  <CustomUploader
                    className={"upload-files-container"}
                    defaultFileList={this.defaultFileList}
                    fileLimit={5}
                    hideDownloadIcon={!this.props.isFileLoaded}
                    onDownload={(data) => {
                      let file = null;
                      if (data.originFileObj) {
                        file = new Blob([data.originFileObj]);
                      }
                      // const file = data.originFileObj ?  new Blob([data.originFileObj], {type: 'application/pdf'}) : this.props.txFile;
                      if (!file) return;
                      const fileURL = URL.createObjectURL(file);
                      const link = document.createElement("a");
                      document.body.appendChild(link);
                      link.download = data.name;
                      link.href = fileURL;
                      setTimeout(function () {
                        link.click();
                      }, 100);
                    }}
                    reference={this.uploadButtonRef}
                    hideButton={false}
                    onChange={(obj) => {
                      if (obj && obj.file) {
                        obj.file.name = `${obj.file.name} (${bytesToSize(
                          obj.file.size
                        )})`;
                      }
                      this.setState({ attachments: obj });
                    }}
                  />
                </div>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item className="copy-checkbox">
                      {getFieldDecorator(
                        "startDate",
                        {}
                      )(
                        <Fragment>
                          <label htmlFor="sendCopy">Start Date</label>
                          <br />
                          {/* <Checkbox id="sendCopy" checked={this.props.isSendCopy} onChange={(e) =>{
            this.props.updateState({
              isSendCopy: e.target.checked
            })
          }} /> */}
                          <DatePicker
                            // className="ml10"
                            // style={{ width: '30%' }}
                            allowClear={false}
                            format={format}
                            key="startDate"
                            defaultValue={getMomentDateForUI({
                              date: this.props.startDate,
                              format,
                            })}
                            onChange={(selectedDate) => {
                              this.props.updateState({
                                startDate: selectedDate,
                              });
                              // this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                            }}
                            placeholder={this.props.intl.formatMessage({
                              id: "fundraising.campaign.addDrawer.field.startDate",
                              defaultMessage: "",
                            })}
                          />

                          <TimePicker
                            // className="ml10"
                            //   style={{ width: '30%' }}
                            use12Hours
                            defaultValue={moment(
                              this.props.eventTime || "7:00 am",
                              "h:mm a"
                            )}
                            format="h:mm a"
                            onChange={(selectedTime, timeString) => {
                              this.props.updateState({
                                startTime: timeString,
                              });
                            }}
                          />
                        </Fragment>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item className="copy-checkbox">
                      {getFieldDecorator(
                        "startDate",
                        {}
                      )(
                        <Fragment>
                          <label htmlFor="sendCopy">End Date</label>
                          <br />
                          {/* <Checkbox id="sendCopy" checked={this.props.isSendCopy} onChange={(e) =>{
            this.props.updateState({
              isSendCopy: e.target.checked
            })
          }} /> */}
                          <DatePicker
                            // style={{ width: '30%' }}
                            allowClear={false}
                            format={format}
                            key="endDate"
                            defaultValue={getMomentDateForUI({
                              date: this.props.endDate,
                              format,
                            })}
                            onChange={(selectedDate) => {
                              this.props.updateState({
                                endDate: selectedDate,
                              });
                              // this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                            }}
                            placeholder={this.props.intl.formatMessage({
                              id: "fundraising.campaign.addDrawer.field.endDate",
                              defaultMessage: "",
                            })}
                          />

                          <TimePicker
                            // className="ml10"
                            //   style={{ width: '30%' }}
                            use12Hours
                            defaultValue={moment(
                              this.props.eventTime || "7:00 am",
                              "h:mm a"
                            )}
                            format="h:mm a"
                            onChange={(selectedTime, timeString) => {
                              this.props.updateState({
                                endTime: timeString,
                              });
                            }}
                          />
                        </Fragment>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </Spin>
        </div>
        <div {...this.tailFormItemLayout}>
          <CustomButton
            type="primary"
            style={{
              width: "100%",
              marginTop: "10px",
              backgroundColor: "#0075FF",
              border: "1px solid #0075FF",
            }}
            intlId={
              this.props.isNewCommunication || this.props.isSendBulkInvoice
                ? "forgot.btn.submit"
                : "Send Broadcast"
            }
            defaultMessage=""
            onClick={() => {
              this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                  if (!this.state.subject && !this.props.subject) {
                    showToasterMessage({
                      messageType: "error",
                      description: "Subject is required.",
                    });
                    return;
                  }
                  if (!this.state.body) {
                    showToasterMessage({
                      messageType: "error",
                      description: "Message is required.",
                    });
                    return;
                  }
                  const payload = {
                    subject: this.state.subject || this.props.subject,
                    message: this.state.body,
                    broadcastMessageId: this.state.broadcastMessageId,
                    relationshipId: (this.props.companyInfo || {})
                      .relationshipId,
                    createdByEmpId: (this.props.userInfo || {})
                      .relationshipEmployeeId,
                    employeeName:
                      ((this.props.userInfo || {}).firstName || "") +
                      " " +
                      ((this.props.userInfo || {}).lastName || ""),
                    startDate: getCurrentDateForBackend(
                      this.props.startDate || new Date()
                    ),
                    endDate: getCurrentDateForBackend(
                      this.props.endDate || new Date()
                    ),
                  };
                  let formData = new FormData();
                  formData.append("payload", JSON.stringify(payload));

                  if (
                    this.state.attachments &&
                    Array.isArray(this.state.attachments.fileList) &&
                    this.state.attachments.fileList.length
                  ) {
                    this.state.attachments.fileList.forEach((fileObj) => {
                      const file = new Blob([fileObj.originFileObj], {
                        type: fileObj.type,
                      });
                      formData.append("files", file, fileObj.name);
                    });
                  }

                  this.props.createBroadcastMessage(
                    formData,
                    this.props,
                    this.state
                  );
                }
              });
            }}
          />
        </div>
      </>
    );
  }
}

export default Form.create({ name: 'Email_Template' })(injectIntl(EmailBody));
