import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { CONSTANTS } from "../../../../../static/constants";
import {
  capitalizeFirstLetter,
  getMomentDateForUIReadOnly,
  getMonthsBetweenDates,
  getUserDefinedName,
} from "../../../../../utils";
import StudentInvoice from "../../../../../containers/customer/invoice/StudentInvoice";
import { Row, Col, Radio, Select, Switch, Button } from "antd";

import config from "../../../../../config/environmentConfig";
import { LogoComponent } from "../../../../general/LogoComponent";
import { TextBox } from "../../../../general/TextBox";
import { TextArea } from "../../../../general/TextAreaComponent";

const { Option } = Select;

const FormDetails = (props) => {

  const { formDetailsData } = props;
  const fatherContact = (props.fullCustomerDetails.boContactList || []).filter(
    (obj) => obj.familyRelationship === "father"
  );
  const motherContact = (props.fullCustomerDetails.boContactList || []).filter(
    (obj) => obj.familyRelationship === "mother"
  );
  const displayContact = (fatherContact || []).length
    ? fatherContact[0] || {}
    : motherContact[0] || {};

  // const getOneTimeCharges = (items) => {
  //   let totalOneTimeCharge = 0;
  //   if (items && items.length) {
  //     items.forEach((item) => {
  //       totalOneTimeCharge += Number(item.amount);
  //     });
  //   }
  //   return totalOneTimeCharge.toFixed(2);
  // };

  const getTotalFee = (data) => {
    let total = 0;
    if (data.courseFee) {
      total =
        Number(data.courseFee) *
        (getMonthsBetweenDates(
          props.formSetting.sessionStart,
          props.formSetting.sessionEnd
        ) || 12);
    }
    return total;
  };
  const selectedPlan = JSON.parse(props.formDetailsData.selectedPlan || "[]");
  const questionList = JSON.parse(props.formDetailsData.questionList);
  // const getStudentAddress = (obj) => {
  //   return (
  //     <>
  //       <Col span={24}>{obj.streetAddress1 ? obj.streetAddress1 : ""}</Col>
  //       <Col span={24}>{obj.streetAddress2 ? obj.streetAddress2 : ""}</Col>
  //       <Col span={24}>
  //         {obj.cityName ? obj.cityName : ""}
  //         {obj.cityName ? "," : ""} {obj.stateName ? obj.stateName : ""}
  //         {obj.stateName ? "," : ""} {obj.zipCode ? obj.zipCode : ""}
  //       </Col>
  //       <Col span={24}>{obj.countryName ? obj.countryName : ""}</Col>
  //     </>
  //   );
  // };

  const getAddressInfoFragment = (obj, additionInfo) => {
    const fragmentObj = (
      <Fragment>
        {obj.streetAddress1 ? (
          <span className="billing-address">{obj.streetAddress1}</span>
        ) : (
          "-"
        )}
        {obj.streetAddress2 ? (
          <span className="billing-address"> {obj.streetAddress2}</span>
        ) : (
          "-"
        )}
        {obj.cityName ? (
          <div className="billing-address">
            {`${obj.cityName || ""}${
              obj.stateName ? ", " + obj.stateName : ""
            }${obj.zipCode ? ", " + obj.zipCode : ""}`}
          </div>
        ) : (
          "-"
        )}
        {obj.countryName ? (
          <div className="billing-address">{`${obj.countryName || ""}`}</div>
        ) : (
          "-"
        )}
        {additionInfo.workPhone ? (
          <div className="billing-address">
            <FormattedMessage id="phone" defaultMessage="Ph" /> -{" "}
            {additionInfo.workPhone}
          </div>
        ) : (
          "-"
        )}
        {additionInfo.email ? (
          <div className="billing-address">
            <FormattedMessage id="email" defaultMessage="Email" /> -{" "}
            {additionInfo.email}
          </div>
        ) : (
          "-"
        )}
      </Fragment>
    );
    return fragmentObj;
  };

  const formSetting = props?.formSetting || {};

  return (
    <div className="register-container">
      <Row
        gutter={24}
        className="reg-header"
        style={{
          background: formSetting.bgColor || "#31c0be",
          color: formSetting.textColor || "#ffffff",
        }}
      >
        <Col span={6} style={{ padding: "0px" }}>
          {(props.companyInfo || {}).logoAwsObjectKey ? (
            <LogoComponent
              style={{
                width: "200px !important",
                height: "120px !important",
              }}
              src={
                CONSTANTS.BASE_URL_S3 +
                "/" +
                config.BUCKET_NAME.BO_RELATIONSHIP +
                "/" +
                (props.companyInfo || {}).logoAwsObjectKey +
                "?" +
                Date.now()
              }
              islogoImage={true}
            />
          ) : (
            ""
          )}
        </Col>
        <Col span={10}></Col>
        <Col span={8} className="address-part" style={{ paddingRight: "0px" }}>
          {props.companyInfo?.boLocationList[0] ? getAddressInfoFragment((props.companyInfo.boLocationList[0] || {}), props.companyInfo) : ""}
        </Col>
      </Row>

      <div className="form-container">
        <div className="form-header">
          <div className="form-heading">{props.formDetailsData.formName}</div>
          {formSetting.sessionStart && formSetting.sessionEnd ? (
            <div className="from-subheading">
              Session ({formSetting.sessionStart.split(" ")[0]} -{" "}
              {formSetting.sessionEnd.split(" ")[0]})
            </div>
          ) : (
            ""
          )}
          {formSetting.subHeading ? (
            <div className="from-subheading">{formSetting.subHeading}</div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="info-box">
        <div className="parents-info">
          
          <Row>
            <Col span={22} className="info-head">
              Parents Contact &nbsp;
              <span className="primary-label"> Primary </span>
            </Col>

            <Col span={8}>
              <b>Full Name</b>
            </Col>
            <Col span={16}>
              {/* {Object.keys(displayContact).length
                ? `${displayContact.firstName} ${displayContact.lastName}`
                : "-"} */}
                {formDetailsData.primaryContact === 'father'? 
                  `${formDetailsData.studentList[0]?.fatherFirstName || ''}  ${formDetailsData.studentList[0]?.fatherLastName || ''}`  :
                  `${formDetailsData.studentList[0]?.motherFirstName || ''}  ${formDetailsData.studentList[0]?.motherLastName || ''}`
                }
            </Col>

            <Col span={8}>
              <b>Relationship</b>
            </Col>
            <Col span={16}>
              {/* {Object.keys(displayContact).length
                ? displayContact.familyRelationship
                : "-"} */}
                {capitalizeFirstLetter(formDetailsData.primaryContact)}
            </Col>

            <Col span={8}>
              <b>Email</b>
            </Col>
            <Col span={16}>
              {/* {Object.keys(displayContact).length
                ? displayContact.emailAddress
                : "-"}{" "} */}
                {formDetailsData.primaryContact === 'father'? 
                  formDetailsData.studentList[0]?.fatherEmail || "-" :
                  formDetailsData.studentList[0]?.motherEmail 
                }
            </Col>
            <Col span={8}>
              <b>Mobile</b>
            </Col>
            <Col span={16}>
              {/* {Object.keys(displayContact).length
                ? `+${displayContact.countryCallingCode}-${displayContact.cellPhone}`
                : "-"} */}
                 {formDetailsData.primaryContact === 'father'? 
                  formDetailsData.studentList[0]?.fatherMobile || "-" :
                  formDetailsData.studentList[0]?.motherMobile 
                }
            </Col>
          </Row>
        </div>
        <div
          style={formSetting.addressDisplay ? {
            width: "1px",
            background: "#D1D0D6",
            margin: "25px 0px 15px 0px",
          }:{}}
        />
        <div className="parents-info">
          {formSetting.addressDisplay ? (
            <Row>
              <Col span={22} className="info-head">
                Student Address
              </Col>
              {/* {getStudentAddress(
                  props.familyListing[1][0].boLocationList[0]
                    ? props.familyListing[1][0].boLocationList[0]
                    : {}
                )} */}
            </Row>
          ):''}
        </div>
      </div>

      <div className="form-box">
        <div className="bold-title">Student Information</div>

        <div className="table-container mb10">
          <table>
            <thead>
              <tr>
                <th>
                  Student Name<span className="required">*</span>
                </th>
                <th>Gender</th>
                <th>
                  {getUserDefinedName("Class", props)}
                  <span className="required">*</span>
                </th>
                <th>
                  Fees<span className="required">*</span>
                </th>
                <th>{formSetting?.dobType === 2 ? "Age" : "DOB"}</th>
                <th>
                  Allergies<span className="required">*</span>
                </th>
                <th>
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              {(formDetailsData.studentList || []).map((e, i) => {
                return (
                  <tr key={"s" + i}>
                    <td>
                      {e.firstName} {e.lastName}
                    </td>
                    <td>{e.gender || "-"}</td>
                    <td>{e.className}</td>
                    <td>
                      {/* {props.formSetting &&
                      ((props.formSetting.onetimeInvoiceItem || "[]").length ||
                        e.courseFee) ? (
                        <span>
                          {props.companyInfo?.currencyIcon ? (
                            <i className={props.companyInfo?.currencyIcon} />
                          ) : (
                            ""
                          )}
                          {getTotalFee(e)}
                        </span>
                      ) : (
                        ""
                      )} */}
                      {e.courseFee || "-"}
                    </td>

                    <td>
                      {e.dob ? getMomentDateForUIReadOnly(e.dob) : e.age || "-"}
                    </td>
                    <td>
                      {e.isAllergy === 0? "No":  "Yes"}
                    </td>
                    <td>{
                      e.isAllergy === 1 ?
                      e.allergies
                      :"-"
              }</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* {formSetting.educationHistory ? (
            <Row>
              <Col span={24}>
                <div className="bold-title">
                  <FormattedMessage id="educationHistory.text" />
                </div>
              </Col>
              <Col span={24} className="table-container mb20">
                <Tabs>
                  {(props.formDetailsData.studentList || []).map(
                    (student, i) => {
                      if (student.isPrimaryContact !== 1) {
                        return (
                          <TabPane
                            tab={
                              student.firstName +
                              (student.lastName ? " " + student.lastName : "")
                            }
                            key={student.contactId}
                          >
                            <div className="student-info">
                              <div className="table-container ">
                                {(
                                  props.educationList.filter(
                                    (e) => e.contactId === student.contactId
                                  ) || []
                                ).length ? (
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>S.No.</th>
                                        <th>Institution Name</th>
                                        <th>
                                          {" "}
                                          Degree Type/
                                          {getUserDefinedName("Class", props)}
                                        </th>
                                        <th>Major</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {(
                                        props.educationList.filter(
                                          (e) =>
                                            e.contactId === student.contactId
                                        ) || []
                                      ).map((e, i) => {
                                        return (
                                          <tr key={"std" + i}>
                                            <td>{i + 1}</td>
                                            <td>{e.institutionName || "-"}</td>
                                            <td>
                                              {e.schoolType === "college" ? (
                                                e.degreeType || "-"
                                              ) : e.schoolType === "school" ? (
                                                <>
                                                  {getUserDefinedName(
                                                    "Class",
                                                    props
                                                  )}{" "}
                                                  {e.grade || "-"}
                                                </>
                                              ) : (
                                                "NA"
                                              )}
                                            </td>
                                            <td>
                                              {e.schoolType === "college"
                                                ? e.major || "-"
                                                : "NA"}
                                            </td>
                                            <td>
                                              <span>
                                                {e.startDate
                                                  ? getMomentDateForUIReadOnly({
                                                      date: e.startDate,
                                                      format:
                                                        CONSTANTS.TABLE_DATE_FORMAT,
                                                    })
                                                  : "-"}
                                              </span>
                                            </td>
                                            <td>
                                              <span>
                                                {e.endDate
                                                  ? getMomentDateForUIReadOnly({
                                                      date: e.endDate,
                                                      format:
                                                        CONSTANTS.TABLE_DATE_FORMAT,
                                                    })
                                                  : "-"}
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                ) : (
                                  "No education history"
                                )}
                              </div>
                            </div>
                          </TabPane>
                        );
                      }
                    }
                  )}
                </Tabs>
              </Col>
            </Row>
          ) : (
            ""
          )} */}

        <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
          {formSetting.familyDoctorNameDisplay ? (
            <>
              <Col span={12}>
                <div>
                  <FormattedMessage id="familyDoctorName.text" />
                  {formSetting.familyDoctorNameRequired ? (
                    <span className="required">*</span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <TextBox
                    placeholder="Family Doctor Name"
                    style={{ color: "rgba(0, 0, 0, 0.65)" }}
                    type="text"
                    disabled={true}
                    maxLength={45}
                    value={props.formDetailsData.familyDoctorName}
                    onChange={(e) => {
                      props.updateState({ familyDoctorName: e.target.value });
                    }}
                    required={formSetting.familyDoctorNameRequired}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <FormattedMessage id="familyDoctorPhone.text" />{" "}
                  {formSetting.familyDoctorNameRequired ? (
                    <span className="required">*</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="input-currency-control">
                  <span className="currency-tag2" style={{ marginTop: "0px" }}>
                    {props.companyInfo.countryCallingCode
                      ? "+" + props.companyInfo.countryCallingCode
                      : " "}
                  </span>
                  <TextBox
                    style={{
                      paddingLeft: "45px",
                      color: "rgba(0, 0, 0, 0.65)",
                    }}
                    placeholder="Family Doctor Phone"
                    type="text"
                    disabled={true}
                    maxLength={10}
                    value={props.formDetailsData.familyDoctorPhNumber}
                    onChange={(e) => {
                      props.updateState({
                        familyDoctorPhone: e.target.value,
                      });
                    }}
                    required={formSetting.familyDoctorPhoneRequired}
                  />
                </div>
              </Col>
            </>
          ) : (
            ""
          )}

          {formSetting.emergencyContactDisplay ? (
            <>
              <Col span={12}>
                <div>
                  <FormattedMessage id="setting.student.registration.form.emergencyContactName" />{" "}
                  {formSetting.emergencyContactRequired ? (
                    <span className="required">*</span>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <TextBox
                    placeholder="Enter Name"
                    type="text"
                    style={{ color: "rgba(0, 0, 0, 0.65)" }}
                    maxLength={45}
                    disabled={true}
                    value={props.formDetailsData.emergencyContactName}
                    onChange={(e) => {
                      props.updateState({
                        emergencyContactName: e.target.value,
                      });
                    }}
                    required={formSetting.emergencyContactRequired}
                  />
                </div>
              </Col>

              <Col span={12}>
                <div>
                  <FormattedMessage id="setting.student.registration.form.emergencyPhone" />{" "}
                  {formSetting.emergencyContactRequired ? (
                    <span className="required">*</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="input-currency-control">
                  <span className="currency-tag2" style={{ marginTop: "0px" }}>
                    {props.companyInfo.countryCallingCode
                      ? "+" + props.companyInfo.countryCallingCode
                      : " "}
                  </span>
                  <TextBox
                    style={{
                      paddingLeft: "45px",
                      color: "rgba(0, 0, 0, 0.65)",
                    }}
                    placeholder="Enter Number"
                    disabled={true}
                    type="text"
                    maxLength={10}
                    value={props.formDetailsData.emergencyPhone}
                    onChange={(e) => {
                      props.updateState({ emergencyPhone: e.target.value });
                    }}
                    required={formSetting.emergencyPhoneRequired}
                  />
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
          {formSetting.askAddTeacher ? (
            <>
              <Col span={12}>
                <div>
                  Are you Teacher?{" "}
                  {formSetting.askAddTeacherRequired ? (
                    <span className="required">*</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt5">
                  <Switch
                    checked={props.formDetailsData.isTeacher === 1}
                    onClick={(checked, e) => {
                      // props.updateState({ isTeacher: checked ? 1 : 0 })
                      return;
                    }}
                  />
                </div>
              </Col>
              <Col span={12}>
                {formSetting.askAddVolunteer ? (
                  <>
                    <div>
                      Do you volunteer?{" "}
                      {formSetting.askAddVolunteerRequired ? (
                        <span className="required">*</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mt5">
                      <Switch
                        checked={props.formDetailsData.isVolunteer === 1}
                        onClick={(checked, e) => {
                          // props.updateState({ isVolunteer: checked ? 1 : 0 })
                          return;
                        }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </>
          ) : (
            ""
          )}

          {formSetting.askFinanacialAssistance ? (
            <Col span={12}>
              <div>
                {" "}
                Need financial assistance?
                {props.formDetailsData.isFinancialAssistanceNeeded ? (
                  <span className="required">*</span>
                ) : (
                  ""
                )}
              </div>
              <div className="mt5">
                <Switch
                  checked={
                    props.formDetailsData.isFinancialAssistanceNeeded === 1
                  }
                  onClick={(checked, e) => {
                    // props.updateState({ isFinancialAssistanceNeeded: checked ? 1 : 0 })
                    return;
                  }}
                />
              </div>
            </Col>
          ) : (
            ""
          )}

          {props.formDetailsData.isFinancialAssistanceNeeded ? (
            <Col span={12}>
              <div>Reason for financial assistance</div>
              <textarea
                className="mt5"
                disabled={true}
                style={{
                  color: "rbga(0, 0, 0, 0.65)",
                  padding: "5px 10px",
                  width: "100%",
                }}
                value={props.formDetailsData.financialAssistanceReason}
              />
            </Col>
          ) : (
            ""
          )}
          {formSetting.askOrgMember ? (
            <Col span={12}>
              <div>
                Are you a member of {props.companyInfo.storeName}?
                {formSetting.askOrgMemberRequired ? (
                  <span className="required">*</span>
                ) : (
                  ""
                )}
              </div>
              <div className="mt5">
                <Select
                  style={{ width: "100%" }}
                  value={props.formDetailsData.orgMember || "Family Membership"}
                  placeholder="Membership Type"
                  disabled={true}
                  onChange={(val) => {
                    props.updateState({ orgMember: val });
                  }}
                >
                  <Option key={"No"} value={"No"}>
                    No
                  </Option>
                  <Option
                    key={"Individual Membership"}
                    value={"Individual Membership"}
                  >
                    Individual Membership
                  </Option>
                  <Option key={"Family Membership"} value={"Family Membership"}>
                    Family Membership
                  </Option>
                  <Option key={"Yes-Other"} value={"Yes-Other"}>
                    Yes-Other
                  </Option>
                </Select>
                {formSetting.orgMember ? formSetting.orgMember : ""}
              </div>
            </Col>
          ) : (
            ""
          )}

          {(selectedPlan.length || []) && Object.keys(selectedPlan).length ? (
            <Col span={12}>
              <div className="mt5">
                <FormattedMessage id="select.paymentPlan" />{" "}
                <span className="required">*</span>
              </div>
              <Select
                value={
                  selectedPlan.period
                    ? selectedPlan.period === "completeSession"
                      ? "One Time Pay"
                      : selectedPlan.period
                    : ""
                }
                disabled={true}
                style={{ color: "rgba(0, 0, 0, 0.65)", width: "100%" }}
                onChange={(val) => {
                  let selectedPlan = props.paymentPlan[val];
                  props.updateState({
                    selectedPlanIndex: val,
                    selectedPlan: selectedPlan,
                  });
                }}
              >
                
              </Select>
            </Col>
          ) : (
            ""
          )}




          {Object.keys(questionList).length &&
          Object.keys(questionList).length > 0 &&
          questionList[0].questionText ? (
            <>
              <Col span={24}>
                {(questionList || []).map((queObj, i) => {
                  return (
                    <>
                      <Row gutter={[0, 16]}>
                        <Col span={24}>
                          <span className="bold-title">{i + 1}. </span>
                          {queObj.questionText}{" "}
                          {queObj.isRequired ? (
                            <span className="required">*</span>
                          ) : (
                            ""
                          )}
                        </Col>

                        <Col span={24} style={{ paddingLeft: "10px" }}>
                          {queObj.optionList && queObj.optionList.length ? (
                            <>
                              {queObj.answerType === "multiChoice" ? (
                                (queObj.answers || []).map((optObj, j) => {
                                  return (
                                    <div key={"opt" + j}>
                                      {" "}
                                      <i
                                        style={{ paddingTop: "1px" }}
                                        className="fi fi-rr-checkbox"
                                      ></i>{" "}
                                      {optObj}{" "}
                                    </div>
                                  );
                                })
                              ) : (
                                <Radio.Group
                                  onChange={(e) => {
                                    queObj.answers = e.target.value;
                                    let qasList = props.questionList;
                                    qasList.splice(i, 1, queObj);
                                    // formSetting.questionList = questionList;
                                    // updateFormSetting(formSetting);
                                    props.updateState({
                                      questionList: qasList,
                                      isAnswerSubmitted: true,
                                    });
                                  }}
                                  value={queObj.answers}
                                >
                                  {queObj.answers ? (
                                    <div>
                                      <Radio
                                        value={queObj.answers}
                                        disabled={true}
                                        checked={true}
                                      >
                                        {" "}
                                        {queObj.answers}{" "}
                                      </Radio>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Radio.Group>
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          {queObj.answerType === "shortAnswer" ? (
                            <TextArea
                              style={{
                                width: "50%",
                                padding: "2px 10px",
                                color: "rgba(0, 0, 0, 0.65)",
                              }}
                              type="text"
                              disabled={true}
                              value={queObj.answers}
                              onChange={(e) => {
                                e.preventDefault();
                                queObj.answers = e.target.value;
                                let qashList = props.questionList;
                                qashList.splice(i, 1, queObj);
                                // formSetting.questionList = questionList;
                                // updateFormSetting(formSetting);
                                props.updateState({
                                  questionList: qashList,
                                  isAnswerSubmitted: true,
                                });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        {formDetailsData.isFinancialAssistanceNeeded ? (
          <Row justify="center">
            <Button
              type="primary"
              onClick={() => {
                const payload = {
                  // customerId: 0,
                  registrationFormSettingId:
                    props.registrationFormSetting.registrationFormSettingId,
                  invoiceForFinancialAssitance: true,
                };
                props.pushModalToStack({
                  modalBody: <StudentInvoice {...props} {...payload} />,
                  width: "100%",
                  hideTitle: true,
                  hideFooter: true,
                  wrapClassName: "new-transaction-wrapper",
                });
              }}
            >
              Generate Invoice
            </Button>
          </Row>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FormDetails;
