import React, { Component } from 'react';
import config from '../../../../../config/environmentConfig';
import { FormattedMessage } from 'react-intl';
import { Input, Button} from 'antd';
import { showToasterMessage } from '../../../../../utils';
import QRCode from 'qrcode.react';
import download from 'downloadjs';
import CryptoJS from "crypto-js";

export default class ShareOrder extends Component {

  constructor(props){
    super(props);
    // let tokenInfo={
    //   relationshipId: props.soDetail.relationshipId,
    //   customerId: props.soDetail.customerId,
    //   salesOrderMasterId:  props.soDetail.salesOrderMasterId
    // };
    let cardConnectObj = (props.cardConnectConfigData || [])[0] || {}
   let tokenInfo = props.soDetail.relationshipId+"_"+props.soDetail.customerId+"_"+props.soDetail.salesOrderMasterId+"_"+cardConnectObj.alpidePaymentGatewayId;
    let encrypted = CryptoJS.AES.encrypt(tokenInfo, config.ENCRYPT_SECRET, CryptoJS.enc.Utf8).toString();
    this.state= {
      previewUrl: config.UI_BASE_URL + 'order-preview?t=' + encrypted,

    }
}

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.select();
    document.execCommand("copy");
    showToasterMessage({
      messageType: 'success', description: this.props.intl.formatMessage(
        {
          id: 'copyToClipboard.text',
          defaultMessage: ''
        }
      )
    });
  }

  render() {
    return (
      <div>
        <div> <FormattedMessage id='shareInvoice.link.description' /></div>
        <br/>
        <div>
          <Input style={{width: '90%'}}
            ref={(textarea) => this.textArea = textarea}
            value={this.state.previewUrl}
          />
          <Button title='Copy Link' type="default" style={{paddingLeft: '5px', paddingRight: '5px'}} onClick={() => this.copyCodeToClipboard()}>
          <i class="fa fa-clone"></i>
          </Button>
        </div>
        

        <br />
        <br />
        <div className="align-footer" style={{ textAlign: 'center' }}>
          <div className="HpQrcode">
            <QRCode value={this.state.previewUrl} renderAs="canvas" />
          </div>
          <br/>
          <br/>
          <div className="align-footer" style={{ borderTop: '1px solid #e5e5e5', textAlign: 'center' }}>
            <Button style={{ marginTop: '10px' }} type="primary" onClick={() => {
              const canvas = document.querySelector('.HpQrcode > canvas');
              download(canvas.toDataURL(), this.props.soDetail.invoiceNumber +'.png');
            }}>Download QR Code</Button>
          </div>
        </div>
      </div>
    )
  }
}
