import React, { useState } from "react";
import config from "../../../config/environmentConfig";
import './index.scss';
import { Spin } from "antd";


const CardPointe = (props) =>{

  const {studentList = [], classFees =[], responseData={}} = props;


  const [iFrameLoading, setIframeLoader] = useState(true);
  const student = studentList.length ? studentList[0] : {};
  const feeStructList = (classFees.find(obj=>obj.classId = student.classId || 26) || {}).feeStructList || [];
  
  if (!props.isListenerAdded) {
      window.addEventListener('message', function (event) {
          if (!event.data) return '';
          if (event.data && typeof event.data === 'string') {
              var token = JSON.parse(event.data);
              var mytoken = document.getElementById('mytoken') || {};
              mytoken.value = token?.message;
              props.updateState({
                  account: token.message,
                  expiry: token.expiry,
                  validationError: token.validationError,
              })
          }
      }, false);
      props.updateState({isListenerAdded: true});
  }


  const submitPay = () =>{
      let arr = (props.primaryContactName || '').split(" ");
      const nameObj = {
        firstName: arr[0],
        lastName: (arr || []).length > 0 ? arr[1] : ''
      }
      
      let payload = {
          relationshipId: props.relationshipId,
          customerId: props.customerId,
          amount: student.amount || (feeStructList || []).reduce((amt, feeObj) => amt + feeObj.amount, 0),
          customerPreInvoiceMasterId: responseData.preInvoiceMasterId,
          preInvoiceId: responseData.preInvoiceMasterId,
          invoiceId: {}.invoiceMasterId,
          invoiceMasterId: {}.invoiceMasterId,
          formId: props.registrationFormSettingId,
          account: props.account,
          expiry: props.expiry,
          paymentSource: 'Online',
          alpidePaymentGatewayId: props.formSetting?.paymentGatewayId,
          firstName: nameObj.firstName, 
          lastName: nameObj.lastName, 
          email: props.primaryEmail,
      }

      props.updateState({ paymentBtnClicked: true });
      props.processCCPayment(props, payload);
  }

  return <> 
      {iFrameLoading ? 
        <div className="loader"><Spin size="large" spinning={iFrameLoading} tip={'Please Wait...'} /></div>
         : 
        <form name="tokenform" id="tokenform" method="POST" target="tokenframe">
            <iframe 
                title=" " 
                onLoad={()=>setIframeLoader(false)}
                id="tokenframe" 
                name="tokenframe" 
                src={`${config.CC_TOKENIZER_URL}?useexpiry=true&usecvv=true&cardlabel=Card Number*&expirylabel=Expiration Date*&cvvlabel=CVV*
                    &placeholder=${encodeURIComponent("16 Digit Card Number")}&placeholdermonth=MM&placeholderyear=YYYY&placeholdercvv=${encodeURIComponent("3 Digit CVV Code")}
                    &cardnumbernumericonly=true&cardinputmaxlength=16&cvvnumericonly=true&cvvnumbermaxlength=3&fullmobilekeyboard=true
                    &usemonthnames=true
                    &invalidcreditcardevent=true&invalidcvvevent=true&tokenizewheninactive=true
                    &formatinput=true
                    &autofocus=true
                    &css=${encodeURIComponent(`.error{color:%20red;}label{font-family:SegoeUI,DroidSans,Tahoma,Arial,sans-serif;font-size:12px;float:left;display:flex;text-align:left;margin-top:5px;}input{border:1pxsolid#e7e7e7;height:38px;display:inline-block;color:rgba(0,0,0,0.65);}input:focus,input:active{border:1pxsolid#0075FF;border-color:#0075FF;}select{height:38px;margin:5px0px5px;border:solid1px#e7e7e7;border-radius:2px;background-color:transparent;color:rgba(0,0,0,0.65);}select:focus,select:active{border:1pxsolid#0075FF;}#cccardlabel,#ccexpirylabel,#cccvvlabel{color:rgba(0,0,0,0.65);}#ccnumfield{width:100%;padding:8px;margin:5px05px0;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}#ccexpirymonth{width:30%;}#ccexpiryyear{width:30%;}#cccvvfield{width:120px;padding:8px;margin:5px05px0;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}#tokenformbr{display:none;}`)}`}
                scrolling="no"
                width="100%" 
                height="260px" 
                frameBorder="0"
            />
            <input type="hidden" name="mytoken" id="mytoken" />
        </form>
      }
    </>
}

export default CardPointe;