import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';
import { CONSTANTS } from '../../../../../static/constants';
import { fixedDecimalAndFormateNumber, getMomentDateForUIReadOnly } from '../../../../../utils';
import SupplierDetails from '../../../../../containers/supplier/detail';
import PurchaseInvoiceDetail from "../../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail";
import BusinessExpenseDetail from "../../../../../containers/modal/modalBody/finance/BusinessExpense";
import SupplierPaymentDetails from "../../../../../containers/modal/modalBody/supplier/PaymentDetail";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const SalesInvoicePaymentList = (props) => {
    const { invoiceData } = props;

  const openInvoiceDetails = (data) => {
    if (data.expenseMasterId) {
      const expensePayload = {
        expenseMasterId: data.expenseMasterId,
      };
      props.pushModalToStack({
        modalBody: (
          <BusinessExpenseDetail {...props} expensePayload={expensePayload} />
        ),
        width: "100%",
        hideFooter: true,
        wrapClassName: "modal-custom-detail",
      });
    } else {
      const invoicePayload = {
        supplierId: data.supplierId,
        invoiceMasterId: data.invoiceMasterId,
      };
      props.pushModalToStack({
        modalBody: (
          <PurchaseInvoiceDetail {...props} invoicePayload={invoicePayload} />
        ),
        width: "100%",
        hideFooter: true,
        wrapClassName: "modal-custom-detail",
      });
    }
  };
  const openPaymentDetails = (data) => {
  const supplierPaymentPayload = {
    supplierId: data.supplierId,
    supplierPaymentId: data.supplierPaymentId,
  };
  const modalDataSupplier = {
      title: <div className='ant-modal-title'>Purchase Payment</div>,
      modalBody: <SupplierPaymentDetails paymentPayload={supplierPaymentPayload} />,
      width: 500,
      hideFooter: true
  };
  props.showModal(modalDataSupplier);
}
    return (

                    <div className="inner-content"> 
                        <div className="table-container" style={{minHeight: 400}}>
                            <table id="payments-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage
                                                id="customer.customerPayment.listing.header.paymentNo"
                                                defaultMessage=""
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage
                                                id="Supplier"
                                                defaultMessage=""
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage
                                                id="customer.customerPayment.listing.header.invoiceNo"
                                                defaultMessage=""
                                            />
                                        </th>
                                       
                                        <th>
                                            <FormattedMessage
                                                id="customer.customerPayment.listing.header.paymentAmount"
                                                defaultMessage=""
                                            />{" "}
                                        </th>

                                        <th>
                                            <FormattedMessage
                                                id="customer.customerPayment.listing.header.paymentDate"
                                                defaultMessage=""
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage id="modal.customer.payInvoice.paymentMode" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="paymentSource.text" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="transactionId.text" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceData.supplierPaymentsList && invoiceData.supplierPaymentsList.length
                                        ? invoiceData.supplierPaymentsList.map((rowData, index) => (
                                            <tr key={index}>


                                                <td>
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            openPaymentDetails(rowData);
                                                        }}
                                                    >
                                                        {rowData.paymentNumber}
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="cursor-pointer">
                                                        <div
                                                            // onClick={() => {
                                                            //   props.history.push("customers/details", {
                                                            //     customerId: rowData.customerId,
                                                            //     customerName: rowData.customerName,
                                                            //   });
                                                            onClick={() => {
                                                                props.pushModalToStack({
                                                                    modalBody: <SupplierDetails {...props}
                                                                    supplierId= {rowData.supplierId}
                                                                    supplierName={rowData.supplierStoreName}
                                                                    />,
                                                                    width: '100%',
                                                                    hideTitle: true,
                                                                    hideFooter: true,
                                                                    wrapClassName: "new-transaction-wrapper",
                                                                    onCancel: () => {
                                                                        const payload={
                                                                          relationshipId:props.companyInfo.relationshipId,
                                                                          pageSize:props.pageSize,
                                                                          pageNumber:props.pageNumber
                                                                        }                           
                                                                          props.popModalFromStack();
                                                                          props.fetchPurchaseInvoices(payload);
                                                                   
                                                                      }
                                                                })
                                                            }}

                                                        >
                                                            {rowData.supplierName}
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() => {
                                                            openInvoiceDetails(rowData);
                                                        }}
                                                    >
                                                        {rowData.txNumber}
                                                    </div>
                                                </td>
                                               

                                                <td>
                                                    {rowData.paymentAmount &&
                                                        rowData.paymentAmount !== "-" ? (
                                                        <span>
                                                            {" "}
                                                            {rowData.foreignCurrencyIcon ? (
                                                                <i className={rowData.foreignCurrencyIcon}></i>
                                                            ) : rowData.foreignCurrency ? (
                                                                <span>{rowData.foreignCurrency}</span>
                                                            ) : props.companyInfo.currencyIcon ? (
                                                                <i
                                                                    className={props.companyInfo.currencyIcon}
                                                                ></i>
                                                            ) : (
                                                                props.companyInfo.currencyCode + " "
                                                            )}
                                                            {fixedDecimalAndFormateNumber(
                                                                rowData.paymentAmount
                                                            )}{" "}
                                                        </span>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </td>

                                                <td>
                                                    <div>
                                                        <Tooltip
                                                            placement="topLeft"
                                                            title={
                                                                rowData.paymentDate &&
                                                                getMomentDateForUIReadOnly({
                                                                    date: rowData.paymentDate,
                                                                    format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT,
                                                                })
                                                            }
                                                        >
                                                            {getMomentDateForUIReadOnly({
                                                                date: new Date(rowData.paymentDate),
                                                                format,
                                                            })}
                                                        </Tooltip>
                                                    </div>
                                                </td>

                                                <td>{rowData.paymentModeName || ""}</td>

                                                <td>
                                                    {rowData.paymentSource ? (
                                                        <FormattedMessage id={rowData.paymentSource} />
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>

                                                <td>{rowData.transactionId || ""}</td>


                                            </tr>
                                        ))
                                        : ""}
                                </tbody>
                            </table>
                        </div>
                    </div>

            
    );

}





export default SalesInvoicePaymentList;
