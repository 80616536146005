import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import "./index.scss";
import {
  Button,
  Divider,
} from "antd";
import { CustomButton } from "../../general/CustomButton";
import {
  showToasterMessage,
  formatDateForBackend,
  getCurrencySymbol,
  getLocalDateFromUtcStr,
} from "../../../utils";
import { CONSTANTS } from "../../../static/constants";
import ReactHtmlParser from "react-html-parser";
import OtpPopup from "./otpPopup";
import FeeDetailByPlan from "./feeDetailByPlan";
import RegisterAddress from "./registerAddress";
import StudentInfo from "./studentInfo";
import QuestionListComp from "./questionListComp";
import AdditionalFormDetails from "./additionalFormDetails";
import PrimaryDetails from "./primaryDetails";
import RegistrationResult from "./RegistrationResult";
import FormHeader from "./formHeader";
import AlreadyRegisteredPopup from "./AlreadyRegisteredPopup";
import ParentInfo from "./parentInfo";


const Register = (props) => {
  const {
    formSetting = {},
    studentList = [{}],
  } = props;
  const getSelectedPlan = (plan) => {
    if (!plan) {
      return {};
    } else {
      let startDate = new Date(plan.startDate);
      let endDate = new Date(plan.endDate);
      if (plan.period === "monthly") {
        startDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
        endDate = new Date(
          endDate.setMonth(endDate.getMonth() + plan.totalInstallment)
        );
      } else if (plan.period === "quarterly") {
        startDate = new Date(startDate.setMonth(startDate.getMonth() + 3));
        endDate = new Date(
          endDate.setMonth(endDate.getMonth() + plan.totalInstallment * 3)
        );
      }
      plan.startDate = formatDateForBackend(startDate);
      plan.endDate = formatDateForBackend(endDate);
      return plan;
    }
  };

  const splitFullName = (fullName) =>{
    let arr = (fullName || '').split(" ");
    const nameObj = {
      firstName: arr[0],
      lastName: (arr || []).length > 0 ? arr[1] : ''
    }
    return nameObj
  }

  const save = () => {
    props.updateState({ submitClicked: true });

    
    // if (!(props.primaryContactName && props.primaryContact && props.primaryEmail && props.mobileNumber)) {
    //   return;
    // }

    // if (!(props.primaryContact && (props.fatherEmail || props.motherEmail))) {
    //   return;
    // }

// Validate primary contact
    // if (props.primaryContact && !(props.fatherName && props.primaryEmail)) {
    //   return;
    // }
     
    // Validate parent info
    if (
      ((props.formSetting.fatherInfoRequired === 1 || props.primaryContact === 'father') && 
       !(props.fatherName && props.fatherEmail && props.fatherMobileNumber)) ||
      ((props.formSetting.motherInfoRequired === 1 || props.primaryContact === 'mother') && 
       !(props.motherName && props.motherEmail && props.motherMobileNumber))
    ) {
      return;
    }
    
    // Validate student address
    if (props.formSetting.addressDisplay && !(props.address?.streetAddress1 && props.address?.cityName && props.address?.stateName && props.address?.countryName)) {
      return;
    }

    // validate no student
    if (!(props.studentList || []).length) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({ id: "no.student.message" }),
      });
    }

    // validate if any data is absent in student
    for (let data of props.studentList || []) {
      if (
        !data.name ||
        !data.gender ||
        !data.className ||
        (formSetting.collectOnlinePayment && !data.classFeeAmount) ||
        (props.formSetting.dobType === 2 && !data.age) ||
        (props.formSetting.dobType === 1 && !data.dob) ||
        !data.isAllergy === undefined ||
        (!data.allergies && data.isAllergy === 1)
      ) {
        
        return showToasterMessage({
          messageType: "error",
          description: "Some student details missing",
        });
      }
    }

    // family details validations
    if (
      (formSetting.familyDoctorNameDisplay &&
        formSetting.familyDoctorNameRequired &&
        !(props.familyDoctorName || props.familyDoctorPhNumber)) ||

      (formSetting.emergencyContactDisplay &&
        formSetting.emergencyContactRequired &&
        !(props.emergencyContactName || props.emergencyPhone)) ||

      //   || (formSetting.askOrgMember && !props.orgMember)
      (props.isAllowParentPaymentPlan && formSetting.collectOnlinePayment && !(props.selectedPlanIndex + 1)) ||

      (props.isFinancialAssistanceNeeded && !props.financialAssistanceReason)
    ) {
      return;
    }

    if (
      (props.emergencyPhone &&
        props.emergencyPhone.length > 0 &&
        props.emergencyPhone.length < 10) ||

      (props.familyDoctorPhNumber &&
        props.familyDoctorPhNumber.length > 0 &&
        props.familyDoctorPhNumber.length < 10)

    ) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "phoneNumber.incorrect.message",
        }),
      });
    }

    if (
      (props.isAllowParentPaymentPlan && formSetting.collectOnlinePayment) &&
      (!props.selectedPlan || !Object.keys(props.selectedPlan).length)
    ) {
      return showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "registration.parentPaymentPlan.required",
        }),
      });
    }

    // validate if any required question not answered
 
    for (const ques of props.formSetting.questionList || []) {
      // (props.questionList || []).forEach((ques) => {
      if (ques.isRequired && (!ques.answers || !ques.answers.length)) {
        return showToasterMessage({
          messageType: "error",
          description: props.intl.formatMessage({
            id: "required.question.missing",
          }),
        });
      }
    }

    // :::::::All validation passed, time to submit:::::::::

    if(formSetting.collectOnlinePayment && (props.oneTimeCharges || []).length) {
      handleSubmitForSave();
    } 
    else {
      const modalData = {
        modalBody: props.isFinancialAssistanceNeeded ? (
            <>
              <div>This form will be reviewed by school and applicable assistance will be provided.</div>
              <div><FormattedMessage id="save.confirmation" /></div>
            </>
          ) 
          : 
          <FormattedMessage id="save.confirmation" />,

        handleSubmit: () => {
          handleSubmitForSave();
        },
      };
      props.pushModalToStack(modalData);
    }
  };

  const handleSubmitForSave = () => { 

    // preparing student data for payload
    let studentListLocal = [];

    for (let data of props.studentList || []) {
      const studentName = (props.primaryContact === "self" ? props.primaryContactName : data.name)

      studentListLocal.push({
        schoolStudentId: data.schoolStudentId || 0,
        relationshipId: props.relationshipId,
        firstName: splitFullName(studentName).firstName || "",
        lastName: splitFullName(studentName).lastName || "",
        gender: data.gender,
        isAllergy: data.isAllergy,
        allergies: data.isAllergy ? data.allergies : "",
        dob: data.dob ? formatDateForBackend(data.dob) : "",
        courseFee: data.classFeeAmount ? Number(data.classFeeAmount.toFixed(2)) : 0,
        customerId: props.customerId || props.userData?.customerId || 0,
        classId: Number(data.classId || 0),
        className: data.className,
        courseId: ((props.classList[1] || []).find(obj=>Number(obj.classId) === Number(data.classId)) || {}).courseId || 0,
        // createdByEmpId: data.createdByEmpId || 0,
        contactId: data.contactId,
        studentStatus: data.studentId ? data.studentStatus : "Pending",
        studentStatusColor: data.studentId ? data.studentStatusColor : "#277bcf",
        age: data.age || 0,
        cellPhone: props.mobileNumber,
      });
    }

    // preparing registration data for payload
    let registrationData = {
      // orgRegistrationId: props.orgRegistrationId || 0,
      orgRegistrationId: 0,
      customerId: props.customerId || props.userData?.customerId || 0,
      contactId: props.contactId,
      registrationFormSettingId: props.registrationFormSettingId,
      courseId: props.courseId,
      relationshipId: props.relationshipId,
      primaryContact: props.primaryContact,

      studentList: studentListLocal,

      familyDoctorName: props.familyDoctorName,
      familyDoctorPhNumber: props.familyDoctorPhNumber,

      emergencyContactName: props.emergencyContactName,
      emergencyPhone: props.emergencyPhone,

      amount: calculateTotalFeeAmt(),
      questionList: JSON.stringify(props.questionList),
      isOrgMember: props.isOrgMember,

      selectedPlan: (props.isAllowParentPaymentPlan && formSetting.collectOnlinePayment)
        ? JSON.stringify(getSelectedPlan(props.selectedPlan || {}))
        : "{}",
      isInvoiced: props.isInvoiced || 0,

      // orgMember: props.orgMember,
      isFinancialAssistanceNeeded: props.isFinancialAssistanceNeeded,
      financialAssistanceReason: props.isFinancialAssistanceNeeded
        ? props.financialAssistanceReason
        : "",
      isTeacher: props.isTeacher,
      isVolunteer: props.isVolunteer,
    };

    // preparing location data for customer payload
    const {streetAddress1, streetAddress2, cityName, countryId, countryName, stateName, zipCode, locationId=0} = props.address;
    let boLocationList = formSetting.addressDisplay && streetAddress1 ? 
      [{
        streetAddress1: streetAddress1,
        streetAddress2: streetAddress2,
        cityName: cityName,
        countryId: countryId,
        countryName: countryName,
        stateName: stateName,
        zipCode: zipCode,
        locationType: CONSTANTS.BILLING_ADDRESS,
        relationshipId: props.relationshipId,
        isDefault: 1,
        locationId: locationId || 0,
        version: props.locationVersion,
        customerId: props.customerId || props.userData?.customerId || 0,
      }]
      :
      [];
    // preparing contact list for customer payload
    // student contact
    let boContactStudent = {
      relationshipId: props.relationshipId,
      customerId: props.customerId || props.userData?.customerId || 0,
      contactId: 0,
      // emailAddress: props.primaryEmail,
      firstName: studentListLocal[0]?.firstName || "",
      lastName: studentListLocal[0]?.lastName || "",
      // cellPhone: props.mobileNumber,
      contactType: 'Customer',
      isPrimaryContact: 0,
      // role: 'student',
      familyRelationship: 'student'
    }

    // father contact
    let boContactUiFather = {
      relationshipId: props.relationshipId,
      customerId: props.customerId || props.userData?.customerId || 0,
      contactId: props.fatherContactId || 0,
      emailAddress: props.fatherEmail,
      firstName: splitFullName(props.fatherName).firstName,
      lastName: splitFullName(props.fatherName).lastName,
      gender: 'Male',
      cellPhone: props.fatherMobileNumber,
      contactType: 'Customer',
      isPrimaryContact: props.primaryContact === 'father' ? 1 : 0,
      // role: 'father',
      familyRelationship: 'father'
    }

    // mother contact
    let boContactUiMother = {
      relationshipId: props.relationshipId,
      customerId: props.customerId || props.userData?.customerId || 0,
      contactId: props.motherContactId || 0,
      emailAddress: props.motherEmail,
      firstName: splitFullName(props.motherName).firstName,
      lastName: splitFullName(props.motherName).lastName,
      gender: 'Female',
      cellPhone: props.motherMobileNumber,
      contactType: 'Customer',
      isPrimaryContact: props.primaryContact === 'mother' ? 1 : 0,
      // role: 'mother',
      familyRelationship: 'mother'
    }

    // final customer data to create customer on submit
    let customerPayload = {
      clientOnlineUserAccountId:
        props.customerInfo?.clientOnlineUserAccountId ||
        props.userData?.clientOnlineUserAccountId ||
        props.clientOnlineUserAccountId,
      companyName: props.primaryContact === 'father' ? props.fatherName : props.motherName,
      boContactList: [boContactStudent, boContactUiFather, boContactUiMother],
      boLocationList: boLocationList,
      customerId: props.customerId || props.userData?.customerId || 0,
      relationshipId: props.relationshipId,
      customerType: "individual",
      orgRegistrationList: [registrationData],
      paymentTermId: formSetting.paymentTermId,

      registrationType: formSetting.registrationType,
      primaryContact: props.primaryContact,
      countryCallingCode: props.companyInfo?.countryCallingCode,
    };
    props.saveRegistrationFound(customerPayload, props);
  };

  // const addStudentModal = (props, e) =>{
  //     props.pushModalToStack({
  //         title: 'Add Student',
  //         modalBody: <AddStudentModal {...props} updateModalState = {(data)=>{props.updateState(data)}}
  //             studentObj= {e || {}}
  //             addStudent = {(data)=>{
  //                 if(data.isEdit){
  //                     const temp = studentList;
  //                     temp[data.isEdit - 1] = data;
  //                     props.updateState({studentList : temp})
  //                 }
  //                 else{
  //                     props.updateState({studentList : [...studentList, data]})
  //                 }
  //             }}  />,
  //         hideFooter: true,
  //       });
  // }

  // const getOneTimeCharges = (items) =>{
  //     let totalOneTimeCharge = 0;
  //     if(items && items.length){

  //       items.forEach((item)=>{
  //         totalOneTimeCharge += Number(item.amount)
  //       })
  //     }
  //     return totalOneTimeCharge.toFixed(2);
  // }
  
  const calculateTotalFeeAmt = () => {
    return (
      studentList.reduce((sum, obj) => sum + obj.classFeeAmount, 0) || 0
    ).toFixed(2);
  };


  const calculateFeesByPlan = () =>{

    let totalFeeObj = {};
    if(props.selectedPlan){
      let studentclassId = props.studentList[0]?.classId || 0;
      let selectedClass = (props.classFees || []).find(obj=>obj.classId = studentclassId) || props.classFees[0];
      let totalInstallment = props.selectedPlan?.totalInstallment || 1;
  
      const {feeStructList=[]} = selectedClass;
      const oneTimeFeeTotal = feeStructList
        .filter(fee => fee.oneTimeFee)
        .reduce((sum, fee) => sum + fee.amount, 0);
  
      const recurringFeeTotal = feeStructList
        .filter(fee => !fee.oneTimeFee)
        .reduce((sum, fee) => sum + fee.amount, 0);
  
      
      totalFeeObj.firstInstallment = oneTimeFeeTotal + recurringFeeTotal/totalInstallment;
      totalFeeObj.recurringInstallment = recurringFeeTotal/totalInstallment;
    }

    return totalFeeObj;
  }

  const isStudentLimitReached = () => {
    const cc = props.classList[1].find(obj=>(obj.classId || 0)===studentList[0].classId)?.classCapacity;
    return cc <= props.studentLimitCount ? true : false;
  }


  return (
    <>
      {props.flashVisible && props.registrationFormSetting?.isFormActive ? <OtpPopup {...props} /> : ""}
      {(props.isFeesPending && props.registrationFormSetting?.isFormActive) ? <AlreadyRegisteredPopup {...props} /> : ""}

      <div className="register-containerr">
        {/* top header */}
        <FormHeader {...props} />

        {/* forms fields */}
        <div className="main-containerr">
          <div className="form-container">
            <div className="form-header">
              {isStudentLimitReached() && formSetting.acceptRegistrationAfterMaxStudent && formSetting.warningMessage ? <div className="reg_warning">{ReactHtmlParser(formSetting.warningMessage || '')}</div> : ''}
              {isStudentLimitReached() && formSetting.isStopRegistrationAfterMaxStudent && formSetting.registrationStopMessage ? <div className="reg_stop">{ReactHtmlParser(formSetting.registrationStopMessage || '')}</div> : ''}
              <div className="form-heading">
                {props.programName || "Form"}
              </div>
              {formSetting.sessionStart && formSetting.sessionEnd ? (
                <div className="from-subheading">
                  Session (
                    {getLocalDateFromUtcStr({
                      date: formSetting.sessionStart.split(" ")[0],
                    })}{" "}
                    -{" "}
                    {getLocalDateFromUtcStr({
                      date: formSetting.sessionEnd.split(" ")[0],
                    })}
                  )
                </div>
              ) : (
                ""
              )}
              {formSetting.subHeading ? 
                <div className="from-subheading">{formSetting.subHeading}</div>
               :
                ""
              }
            </div>
          </div>

          {/* after form submit components */}
          {
            props.status ? <RegistrationResult {...props} /> 
            :  
            <>
              <PrimaryDetails {...props} />

              <ParentInfo {...props} />

              <RegisterAddress {...props} />

              <StudentInfo {...props} />

              <Divider />

              <AdditionalFormDetails {...props} />

              <QuestionListComp {...props} />

              {props.selectedPlan && !(props.selectedPlan === "{}") ? <FeeDetailByPlan {...props} /> :''}

              {props.formSetting?.footerMessage ? 
                <div>
                  <Divider className="mb10" />

                  <div style={{ textAlign: "center" }}>
                    {ReactHtmlParser(props.formSetting?.footerMessage) || ""}
                  </div>
                </div>
               :
                ""
              }

              <div className="regist-form-foot">
                <Button
                  disabled = {props.saveLoading}
                  onClick={() => {
                    // closeForm();
                    window.close();
                  }}
                  key={1}
                  id="cancel"
                >
                  Cancel
                </Button>
                {formSetting.collectOnlinePayment && formSetting.isCollectPaymentAtRegistration && 
                  (studentList || []).length && calculateFeesByPlan().firstInstallment ? 
                    <Button
                      disabled = {props.saveLoading || (isStudentLimitReached() && formSetting.isStopRegistrationAfterMaxStudent && formSetting.registrationStopMessage)}
                      loading = {props.saveLoading}
                      key={2}
                      type="primary" 
                      htmlType="submit" 
                      onClick={()=>{
                        save()
                      }}
                    >
                      <FormattedMessage
                        id="proceedToPay.text"
                        values={{
                          value:
                            (getCurrencySymbol(props.companyInfo?.currencyCode || '') +
                              " " || "") 
                              + 
                              // calculateTotalFeeAmt(),
                              props.selectedPlan ? (calculateFeesByPlan().firstInstallment || 0).toFixed(2) : 0,
                        }}
                      />
                    </Button>
                    : 
                    <CustomButton
                      intlId={"confirmation.submit"}
                      disabled = {props.saveLoading || (isStudentLimitReached() && formSetting.isStopRegistrationAfterMaxStudent && formSetting.registrationStopMessage)}
                      loading = {props.saveLoading}
                      defaultMessage={""}
                      htmlType="submit"
                      key={2}
                      onClick={()=>{
                        save()
                      }}
                    />
                }
              </div>
            
            </>
          }
        </div>
      </div>
    </>
  );
};

export default injectIntl(Register);