/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Input, Button, Row, Col, Popover } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CustomButton } from '../../general/CustomButton';
// import { CustomUploader } from '../../general/CustomUploader';
import '../../../assets/stylesheets/common/_email_template.scss';
import { validateEmail, showToasterMessage } from './../../../utils';
import { EMAIL_BODY_CONFIGURATION } from '../../../static/ckEditorConfigConstants';
import { PLACEHOLDER_CONSTANTS } from '../../../static/placeholderConstants';
import CKEditor from '../../general/CustomCKEditor/ckeditor';
import filter from 'lodash.filter';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import uniq from 'lodash.uniq';
import UnpaidInvoices from '../../../containers/modal/modalBody/schoolManagement/UnpaidInvoices';
//import { max } from 'moment-timezone';
const antIcon = <Loading3QuartersOutlined className="spinnerIcon" spin />;
const { Option } = Select;


class EmailBody extends Component {

  constructor(props) {
    super(props);
    // let fileList = [{
    //     uid: '-1',
    //     name: (props.txData || {}).fileName || "Document.pdf",
    //     status: 'done',
    //     url: this.props.txFileUrl
    //   }];
    //   if(props.txData2){
    //     fileList.push({
    //       uid: '-2',
    //       name: (props.txData2 || {}).fileName || "Document.pdf",
    //       status: 'done',
    //       url: this.props.txFileUrl2
    //     })
    //   }
    this.state = {
      sender: props.userInfo.email || '',
      receivers: props.receivers || [],
      ccRecievers: props.ccRecievers || [],
      bccRecievers: props.bccRecievers || [],
      subject: props.subject,
      body: props.body || props.emailBody,
      emailBody: props.body || props.emailBody,
      ccVisible: props.ccVisible || false,
      bccVisible: props.bccVisible || false,
      isSendInvoicePdf: props.isSendInvoicePdf || 0,
      updateReminder: props.updateReminder,
      docName: props.docName,
      isSendAssignment: props.isSendAssignment || false,
      assignmentId: props.assignmentId,
      attachments: {
        fileList: []
      },
    };

    this.uploadButtonRef = React.createRef();

    this.formItemLayout = {
      labelCol: {
        xs: { span: 20 },
        sm: { span: 4 },
      },
      wrappercol: {
        xs: { span: 24 },
        sm: { span: 22 },
      },
    };

    this.tailFormItemLayout = {
      wrappercol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 22,
          offset: 2,
        },
      },
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...(this.props.emailPayloadProps || {}),
          sender: values.sender,
          to: Array.isArray(values.receivers) ? values.receivers : [values.receivers],
          // cc: Array.isArray(values.ccRecievers) ? values.ccRecievers: [values.ccRecievers],
          // bcc: Array.isArray(values.bccRecievers) ? values.bccRecievers: [values.bccRecievers],
          subject: values.subject,
          message: this.state.body,
          sendMeACopy: values.sendMeACopy ? 1 : 0
        };
        if (values.ccRecievers) {
          payload.cc = Array.isArray(values.ccRecievers) ? values.ccRecievers : [values.ccRecievers];
        }
        if (values.bccRecievers) {
          payload.bcc = Array.isArray(values.bccRecievers) ? values.bccRecievers : [values.bccRecievers];
        }
        this.props.updateState({ emailDetails: JSON.stringify(payload), emailDetailDrawerVisible: false });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const receiverItems = [];
    const ccRecieversItems = [];
    const bccRecieversItems = [];
    const invoiceStatusList = [];
    const studentStatusList = [];
    const dgOptionList = [];

    uniq(this.props.receiverItems).forEach((data, i) => {
      receiverItems.push(<Option value={data}>{data}</Option>);
    });

    uniq(this.props.ccRecieversList).forEach((data, i) => {
      ccRecieversItems.push(<Option key={data}>{data}</Option>);
    });

    uniq(this.props.bccRecieversList).forEach((data, i) => {
      bccRecieversItems.push(<Option key={data}>{data}</Option>);
    });

    uniq([{ name: 'New', value: 'new' }, { name: 'Open', value: 'open' }, { name: 'Paid', value: 'paid' }, { name: 'Unpaid', value: 'unpaid' }, { name: 'Void', value: 'void' }]).forEach((data, i) => {
      invoiceStatusList.push(<Option key={data.value}>{data.name}</Option>);
    });

    uniq(this.props.userStatus).forEach((data, i) => {
      studentStatusList.push(<Option key={data.registrationStatusId}>{data.statusName}</Option>);
    });

    uniq(this.props.dgList).forEach((data, i) => {
      dgOptionList.push(<Option key={data.distributionGroupId}>{data.dgName}</Option>);
    });
    this.defaultFileList = [];
    // this.defaultFileList = [
    //   {
    //     uid: '-1',
    //     name: (this.props.txData || {}).fileName || 'Document.pdf',
    //     status: 'done',
    //     url: this.props.txFileUrl,
    //     blobData: this.props.txFile
    //   }
    // ]
    // if (this.props.txData2) {
    //   this.defaultFileList.push({
    //     uid: '-2',
    //     name: (this.props.txData2 || {}).fileName || 'Document2.pdf',
    //     status: 'done',
    //     url: this.props.txFileUrl2,
    //     blobData: this.props.txFile2
    //   })
    // }
    const insertTextInSubject = (props, textToInsert) => {
      if (this.state.subject) {
        let subject = this.state.subject.slice(0, this.state.subjectCursor) + textToInsert + this.state.subject.slice(this.state.subjectCursor + 1);
        this.props.form.setFieldsValue({ subject });
        this.setState({ subject: subject, subjectCursor: this.state.subjectCursor + textToInsert.length })
      } else {
        this.setState({ subject: textToInsert, subjectCursor: this.state.subjectCursor + textToInsert.length })
      }
    }
    const getSubjectPlaceHolders = (props, editorName) => {
      return <div style={{ width: '400px' }}>
        <Row type='flex'>
          {(props.docName === 'paymentReminder') ?
            <Col span={20}>
              <div style={{ fontWeight: '500' }}>Reminder</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father/Mother Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DUE_AMOUNT); }}>Due Amount</div>
              {/* <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PAYMENT_URL); }}>Payment URL</div> */}
            </Col> : ''
          }
          {(props.docName === 'students' || props.docName === 'Class Assignment') ?
            <Col span={14}>
              <div style={{ fontWeight: '500' }}>Student</div>
              
            {props.isSendAssignment ? <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father and Mother Name</div> : ''}
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CLASS_NAME); }}>Class Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
            {props.isSendAssignment ? <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ASSIGNMENT_NAME); }}>Assignment Name</div> : ''}
            {props.isSendAssignment ? <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ASSIGNMENT_DUE_DATE); }}>Assignment Due Date</div> : ''}
            </Col> : ''
          }
          {(props.docName !== 'registration' && props.docName !== 'students' && props.docName !== 'paymentReminder' && props.docName !== 'Class Assignment') ?
            <Col span={8}>
              <div style={{ fontWeight: '500' }}>Invoice</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
            </Col> : ''
          }
          {(props.docName !== 'paymentReminder' && !props.isSendAssignment) ?
            <Col span={8}>
              <div style={{ fontWeight: '500' }}>Customer</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
              {/* <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div> */}
              {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.CUSTOMER_BALANCE);}}>Customer Amount</div> */}
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
              {/* <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
            <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div> */}
              {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DEPARTMENT);}}>Department</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.DESIGNATION);}}>Designation</div> */}
              {/* <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div> */}
            </Col>
            : ''
          }
          {(props.docName !== 'paymentReminder' && !props.isSendAssignment) ?
            <Col span={8}>
              <div style={{ fontWeight: '500' }}>Organization</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.STORE_NAME); }}>Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.USER); }}>User</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_EMAIL); }}>Email</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_PHONE); }}>Phone #</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.ORG_FAX); }}>Fax #</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { insertTextInSubject(props, PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
            </Col>
            : ''
          }
        </Row>
      </div>
    }

    const getPlaceHolders = (props, editorName) => {
      return <div style={{ width: '400px' }}>
        <Row type='flex'>
          {(props.docName === 'paymentReminder') ?
            <Col span={20}>
              <div style={{ fontWeight: '500' }}>Reminder</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father/Mother Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DUE_AMOUNT); }}>Due Amount</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.PAYMENT_URL); }}>Payment URL</div>
            </Col> : ''
          }
          {(props.docName === 'students'|| props.docName === 'Class Assignment') ?
            <Col span={14}>
              <div style={{ fontWeight: '500' }}>Student</div>
              {props.isSendAssignment ?  <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.PARENT_NAME); }}>Father and Mother Name</div>: ''}
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.TEACHER_NAME); }}>Teacher Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.CLASS_NAME); }}>Class Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.CLASS_SCHEDULE); }}>Class Schedule</div>
              {props.isSendAssignment ?  <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ASSIGNMENT_NAME); }}>Assignment Name</div>: ''}
              {props.isSendAssignment ?  <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ASSIGNMENT_DUE_DATE); }}>Assignment Due Date</div>: ''}
            </Col> : ''}
          {(props.docName !== 'registration' && props.docName !== 'students' && props.docName !== 'paymentReminder' && props.docName !== 'Class Assignment') ?
            <Col span={8}>
              <div style={{ fontWeight: '500' }}>Invoice</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.STUDENT_NAME); }}>Student Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.BALANCE); }}>Amount</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DUE_DATE); }}>Due Date</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.INVOICE_DATE); }}>Invoice Date</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.INVOICE_NUMBER); }}>Invoice #</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.INVOICE_URL); }}>Invoice URL</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.PROJECT_NAME); }}>Project Name</div>
              {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.PAYMENT_LINK);}}>Payment Link</div> */}
            </Col> : ''}
          {(props.docName !== 'paymentReminder' && !props.isSendAssignment) ?
            <Col span={8}>
              <div style={{ fontWeight: '500' }}>Customer</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_NAME); }}>Customer Name</div>
              {/* <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.SALUTATION); }}>Salutation</div> */}
              {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.CUSTOMER_BALANCE);}}>Customer Amount</div> */}
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
              {/* <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.FIRST_NAME); }}>First Name</div>
            <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.LAST_NAME); }}>Last Name</div> */}
              {/* <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DEPARTMENT);}}>Department</div>
                  <div className="ml10" style={{cursor: 'pointer'}} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.DESIGNATION);}}>Designation</div> */}
              {/* <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.EMAIL); }}>Email</div> */}
            </Col> : ''
          }
          {(props.docName !== 'paymentReminder' && !props.isSendAssignment) ?
            <Col span={8}>
              <div style={{ fontWeight: '500' }}>Organization</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.STORE_NAME); }}>Name</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.USER); }}>User</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ORG_EMAIL); }}>Email</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ORG_PHONE); }}>Phone #</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.ORG_FAX); }}>Fax #</div>
              <div className="ml10" style={{ cursor: 'pointer' }} onClick={() => { this.editor.insertText(PLACEHOLDER_CONSTANTS.WEBSITE); }}>Website</div>
            </Col> : ''
          }
        </Row>
      </div>
    }


    return (
      <div className="modal-content" >
        <Spin indicator={antIcon} className="spinner" tip="Loading..." spinning={this.props.isEmailSpinner} >
          <Form className="email-form-group" {...this.formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
            <div className="scrollable-content">
              {(!this.props.isFormSetting && !this.props.isBulkInvoice) ?
                <Form.Item label='From' colon={false} >
                  {getFieldDecorator('sender', {
                    initialValue: this.props.userInfo.email || '',
                    rules: [
                      {
                        required: true,
                        message: 'From is required.',
                      }
                    ],
                  })(<Input />)}
                </Form.Item> :''}

              {(this.props.isBulkInvoice && !this.props.isNewCommunication && !this.props.isSendBulkInvoice && !this.props.isSendAssignment) ?
                <div className="email-to-control">
                  <Form.Item label='To' colon={false} >
                    {getFieldDecorator('receivers', { initialValue: this.props.intl.formatMessage({ id: 'send.email.to.default.contact.message' }) })(
                      <Input disabled
                      />
                    )}
                  </Form.Item>
                </div> :''
              }
              {(this.props.docName === 'paymentReminder') ?
                <div className="email-to-control">
                  <div className='mb15'>You are sending emails to all contracts of <span className='cursor-pointer' onClick={() => {
                    this.props.showModal(
                      {
                        title: <Fragment>
                          <div className='ant-modal-title'><FormattedMessage id='customer.listing.header.unpaidInvoices' /></div>
                        </Fragment>,
                        modalBody: <UnpaidInvoices {...this.props} />,
                        width: 1100,
                        hideFooter: true,
                        wrapClassName: 'tiles-modal'
                      }
                    )
                  }}>{` ${this.props.unpaidInvoiceCount || 0} ${this.props.unpaidInvoiceCount > 1 ? 'Invoices' : 'Invoice'}`}</span>.
                  </div>
                  {/* <Form.Item label='To' colon={false} >
                    {getFieldDecorator('receivers', { initialValue: `You are sending emails to all contracts of ${this.props.unpaidInvoiceCount||0} unpaid invoices. Have a link on ${this.props.unpaidInvoiceCount||0} Unpiad Invoices (we already have it on Payment Reminder Card)` })(
                      <Input disabled
                      />
                    )}
                  </Form.Item> */}
                </div> :''
              }


              {(!this.props.isFormSetting && !this.props.isBulkInvoice) ?
                <div className="email-to-control">
                  <Form.Item label='To' colon={false} >
                    {getFieldDecorator('receivers', {
                      rules: [
                        {
                          required: true,
                          message: 'Enter valid email address',
                          validator: (rule, value, cb) => {
                            let isInValidEmail = false;
                            if (!this.state.receivers || !this.state.receivers.length) {
                              return cb(rule.message);
                            }
                            (this.state.receivers || []).forEach((v) => {
                              if (!validateEmail(v)) {
                                isInValidEmail = true
                              }
                            });
                            if (isInValidEmail) {
                              return false;
                            }

                            if (rule.required) {
                              if (value.length) {
                                return cb();
                              } else {
                                return cb(rule.message);
                              }
                            }
                            return cb(rule.message);
                          },

                        }
                      ],
                      initialValue: this.state.receivers || []
                    })(
                      <div className="to-input-control">
                        <Select
                          tokenSeparators={[',']}
                          bordered={false}
                          onDeSelect={
                            (value) => {
                              let receivers = Object.assign([], this.state.receivers);
                              receivers = filter(receivers, (obj) => {
                                return obj !== value;
                              });
                              this.props.form.setFieldsValue({ receivers });
                              this.setState({ receivers });
                            }
                          }
                          onSelect={(selectedValue) => {
                            const receivers = Object.assign([], this.state.receivers);
                            receivers.push(selectedValue);
                            this.props.form.setFieldsValue({ receivers });
                            this.setState({ receivers });
                          }}
                          onChange={(e) => {
                            this.setState({ receivers: e });
                            this.props.form.setFieldsValue({ receivers: e });
                          }}
                          mode="tags"
                          defaultValue={this.state.receivers}
                        >
                          {receiverItems}
                        </Select>

                        <div className="cc-bcc-button">
                          <a onClick={
                            () => {
                              this.setState({ ccVisible: !this.state.ccVisible });
                              if (this.state.ccVisible) {
                                this.setState({ ccRecievers: [] });
                              }
                            }
                          }>
                            {this.state.ccVisible ? 'Hide CC' : 'Show CC'}
                          </a>
                          <span className="prl5">{' | '}</span>
                          <a onClick={
                            () => {
                              this.setState({ bccVisible: !this.state.bccVisible });
                              if (this.state.bccVisible) {
                                this.setState({ bccRecievers: [] });
                              }
                            }
                          }>
                            {this.state.bccVisible ? 'Hide BCC' : 'Show BCC'}
                          </a>
                        </div>
                      </div>
                    )}
                  </Form.Item>

                  {
                    this.state.ccVisible
                      ? (
                        <Form.Item label='CC' colon={false} >
                          {getFieldDecorator('ccRecievers', {})(
                            <div className="to-input-control">
                              <Select
                                bordered={false}
                                onChange={(val) => {
                                  this.setState({
                                    ccRecievers: val
                                  });
                                  this.props.form.setFieldsValue({ ccRecievers: val });
                                }}
                                onSelect={(selectedValue) => {
                                  const ccRecievers = Object.assign([], this.state.ccRecievers);
                                  ccRecievers.push(selectedValue);
                                  this.props.form.setFieldsValue({ ccRecievers });
                                  this.setState({ ccRecievers });
                                }}
                                mode="tags"
                                defaultValue={this.state.ccRecievers}
                              >
                                {ccRecieversItems}
                              </Select>
                            </div>
                          )}
                        </Form.Item>
                      )
                      : ''
                  }

                  {
                    this.state.bccVisible
                      ? (
                        <Form.Item label='BCC' colon={false} >
                          {getFieldDecorator('bccRecievers', {})(
                            <div className="to-input-control">
                              <Select
                                bordered={false}
                                onChange={(val) => {
                                  this.setState({
                                    bccRecievers: val
                                  });
                                  this.props.form.setFieldsValue({ bccRecievers: val });
                                }}
                                onSelect={(selectedValue) => {
                                  const bccRecievers = Object.assign([], this.state.bccRecievers);
                                  bccRecievers.push(selectedValue);
                                  this.props.form.setFieldsValue({ bccRecievers });
                                  this.setState({ bccRecievers });
                                }}
                                mode="tags"
                                defaultValue={this.state.bccRecievers}
                              >
                                {bccRecieversItems}
                              </Select>
                            </div>
                          )}
                        </Form.Item>
                      )
                      : ''
                  }
                </div> :''
              }
              {((this.props.isSendBulkInvoice || this.props.isSendAssignment) && ((this.props.selectedStudentList && this.props.selectedStudentList.length) || (this.props.selectedInvoiceList && this.props.selectedInvoiceList.length) || (this.props.asmStudentList && this.props.asmStudentList.length))) ?
                <div className="email-to-control">
                  {/* <Form.Item label='To' colon={false} >
                    {/* {getFieldDecorator('receivers', { initialValue: this.props.selectedStudentList.length + (this.props.selectedStudentList.length === 1 ? ' student' : ' students') })( 
                    {getFieldDecorator('receivers', { initialValue: (this.props.selectedStudentList || []).map(usrObj => `${usrObj.firstName} ${usrObj.lastName || ''}`).join(', ') })(
                      <Input disabled
                      />
                    )}
                  </Form.Item> */}
                  <div className='com-ds-box mb10' style={{padding: '0px'}}>
                    {this.props.selectedStudentList && this.props.selectedStudentList.length > 0 ?
                      this.props.selectedStudentList.map((user, i) => {
                        return (
                          <span key={i}>
                            <h3 className='dg-buts' >
                              {`${user.firstName || ''}${user.lastName ? ' ' + user.lastName : ''}`}
                              <i onClick={() => {
                                let remainungUsers = this.props.selectedStudentList.filter(usr => usr.schoolStudentId !== user.schoolStudentId);
                                remainungUsers.length > 0 && this.props.updateState({ selectedStudentList: remainungUsers });
                              }} className='fa fa-close' style={{ marginLeft: '6px', fontWeight: '300', fontSize: '12px' }} />
                            </h3>
                          </span>
                        );
                      }) : ''} 

                    {this.props.asmStudentList && this.props.asmStudentList.length > 0 ?
                      this.props.asmStudentList.map((user, i) => {
                        return (
                          <span key={i}>
                            <h3 className='dg-buts' >
                              {`${user.firstName || ''}${user.lastName ? ' ' + user.lastName : ''}`}
                              <i onClick={() => {
                                let remainungUsers = this.props.asmStudentList.filter(usr => usr.schoolStudentId !== user.schoolStudentId);
                                remainungUsers.length > 0 && this.props.updateDrawerState({ asmStudentList: remainungUsers });
                              }} className='fa fa-close' style={{ marginLeft: '6px', fontWeight: '300', fontSize: '12px' }} />
                            </h3>
                          </span>
                        );
                      }) : ''} 

                    {this.props.selectedInvoiceList && this.props.selectedInvoiceList.length > 0 ?
                      this.props.selectedInvoiceList.map((invObj, i) => {
                        return (
                          <span key={i}>
                            <h3 className='dg-buts' >
                              {`${invObj.invoiceNumber || ''} (${invObj.customerName || ''})`}
                              <i onClick={() => {
                                let remainungInvoices = this.props.selectedInvoiceList.filter(inv => inv.invoiceMasterId !== invObj.invoiceMasterId);
                                remainungInvoices.length > 0 && this.props.updateState({ selectedInvoiceList: remainungInvoices });
                              }} className='fa fa-close' style={{ marginLeft: '6px', fontWeight: '300', fontSize: '12px' }} />
                            </h3>
                          </span>
                        );
                      }) : ''} </div>


                  <Form.Item label='CC' colon={false} >
                    {getFieldDecorator('ccRecievers', {})(
                      <div className="to-input-control">
                        <Select
                          bordered={false}
                          onChange={(val) => {
                            this.setState({
                              ccRecievers: val
                            });
                            this.props.form.setFieldsValue({ ccRecievers: val });
                          }}
                          onSelect={(selectedValue) => {
                            const ccRecievers = Object.assign([], this.state.ccRecievers);
                            ccRecievers.push(selectedValue);
                            this.props.form.setFieldsValue({ ccRecievers });
                            this.setState({ ccRecievers });
                          }}
                          mode="tags"
                          defaultValue={this.state.ccRecievers}
                        >
                          {[]}
                        </Select>
                      </div>
                    )}
                  </Form.Item>

                  <Form.Item label='BCC' colon={false} >
                    {getFieldDecorator('bccRecievers', {})(
                      <div className="to-input-control">
                        <Select
                          bordered={false}
                          onChange={(val) => {
                            this.setState({
                              bccRecievers: val
                            });
                            this.props.form.setFieldsValue({ bccRecievers: val });
                          }}
                          onSelect={(selectedValue) => {
                            const bccRecievers = Object.assign([], this.state.bccRecievers);
                            bccRecievers.push(selectedValue);
                            this.props.form.setFieldsValue({ bccRecievers });
                            this.setState({ bccRecievers });
                          }}
                          mode="tags"
                          defaultValue={this.state.bccRecievers}
                        >
                          {[]}
                        </Select>
                      </div>
                    )}
                  </Form.Item>

                </div>:''
              }
              {(this.props.isNewCommunication) ?
                <div className="email-to-control">
                  <Form.Item label='DG' colon={false} >
                    {getFieldDecorator('dgIdList', {
                      initialValue: this.state.dgIdList || []
                    })(
                      <div className="to-input-control">
                        <Select
                          tokenSeparators={[',']}
                          bordered={false}
                          onDeSelect={
                            (value) => {
                              let dgIdList = Object.assign([], this.state.dgIdList);
                              dgIdList = filter(dgIdList, (obj) => {
                                return obj !== value;
                              });
                              this.props.form.setFieldsValue({ dgIdList });
                              this.setState({ dgIdList });
                            }
                          }
                          onSelect={(selectedValue) => {
                            const dgIdList = Object.assign([], this.state.dgIdList);
                            dgIdList.push(selectedValue);
                            this.props.form.setFieldsValue({ dgIdList, studentStatus: [], invoiceStatus: [] });
                            this.setState({ dgIdList, studentStatus: [], invoiceStatus: [] });
                          }}
                          onChange={(e) => {
                            this.setState({ dgIdList: e, studentStatus: [], invoiceStatus: [] });
                            this.props.form.setFieldsValue({ dgIdList: e, studentStatus: [], invoiceStatus: [] });
                          }}
                          mode="tags"
                          defaultValue={this.state.dgIdList}
                          value={this.state.dgIdList}
                        >
                          {dgOptionList}
                        </Select>

                      </div>
                    )}
                  </Form.Item>
                </div>: ''
              }
              {(this.props.isNewCommunication) ?
                <div className="email-to-control">
                  <Form.Item label='Invoice Status' colon={false} >
                    {getFieldDecorator('invoiceStatus', {
                      initialValue: this.state.invoiceStatus || []
                    })(
                      <div className="to-input-control nmelt">
                        <Select
                          tokenSeparators={[',']}
                          bordered={false}
                          onDeSelect={
                            (value) => {
                              let invoiceStatus = Object.assign([], this.state.invoiceStatus);
                              invoiceStatus = filter(invoiceStatus, (obj) => {
                                return obj !== value;
                              });
                              this.props.form.setFieldsValue({ invoiceStatus });
                              this.setState({ invoiceStatus });
                            }
                          }
                          onSelect={(selectedValue) => {
                            const invoiceStatus = Object.assign([], this.state.invoiceStatus);
                            invoiceStatus.push(selectedValue);
                            this.props.form.setFieldsValue({ invoiceStatus, studentStatus: [], dgIdList: [] });
                            this.setState({ invoiceStatus, studentStatus: [], dgIdList: [] });
                          }}
                          onChange={(e) => {
                            this.setState({ invoiceStatus: e, studentStatus: [], dgIdList: [] });
                            this.props.form.setFieldsValue({ invoiceStatus: e, studentStatus: [], dgIdList: [] });
                          }}
                          mode="tags"
                          defaultValue={this.state.invoiceStatus}
                          value={this.state.invoiceStatus}
                        >
                          {invoiceStatusList}
                        </Select>

                      </div>
                    )}
                  </Form.Item>
                </div>: ''
              }
              {(this.props.isNewCommunication) ?
                <div className="email-to-control">
                  <Form.Item label='Student Status' colon={false} >
                    {getFieldDecorator('studentStatus', {
                      initialValue: this.state.studentStatus || []
                    })(
                      <div className="to-input-control nmelt">
                        <Select
                          tokenSeparators={[',']}
                          bordered={false}
                          onDeSelect={
                            (value) => {
                              let studentStatus = Object.assign([], this.state.studentStatus);
                              studentStatus = filter(studentStatus, (obj) => {
                                return obj !== value;
                              });
                              this.props.form.setFieldsValue({ studentStatus });
                              this.setState({ studentStatus });
                            }
                          }
                          onSelect={(selectedValue) => {
                            const studentStatus = Object.assign([], this.state.studentStatus);
                            studentStatus.push(selectedValue);
                            this.props.form.setFieldsValue({ studentStatus, invoiceStatus: [], dgIdList: [] });
                            this.setState({ studentStatus, invoiceStatus: [], dgIdList: [] });
                          }}
                          onChange={(e) => {
                            this.setState({ studentStatus: e, invoiceStatus: [], dgIdList: [] });
                            this.props.form.setFieldsValue({ studentStatus: e, invoiceStatus: [], dgIdList: [] });
                          }}
                          mode="tags"
                          defaultValue={this.state.studentStatus}
                          value={this.state.studentStatus}
                        >
                          {studentStatusList}
                        </Select>

                      </div>
                    )}
                  </Form.Item>
                </div> : ''
              }

              <div className="placeholder-button" style={{height: '32px', marginTop: '3px'}}>
                <Popover content={getSubjectPlaceHolders(this.state, 'editorSubject')} placement="bottom" trigger="click" onClick={() => { this.setState({ isSubjectPopoverVisible: !this.state.isSubjectPopoverVisible, isPopoverVisible: false }); }}>
                  Placeholder <i className='fa fa-caret-square-o-down' />
                </Popover>
              </div>
              <Form.Item label={<div>
                Subject
              </div>} colon={false} className="subject-form-control">
                {getFieldDecorator('subject', {
                  initialValue: this.state.subject || this.props.subject,
                  rules: [
                    {
                      required: true,
                      message: 'Subject is required.',
                    },
                    { max: 500, message: 'Subject cannot be greater than 500 characters' },
                  ],
                })(<Input onChange={(e) => {
                  this.setState({ subject: e.target.value, subjectCursor: e.target.selectionStart });
                }}
                  onClick={(e) => {
                    this.setState({
                      subjectCursor: e.target.selectionStart,
                      isPopoverVisible: false, isSubjectPopoverVisible: false
                    });
                  }}
                />)}
              </Form.Item>
              {/* {(this.props.isNewCommunication || this.props.isSendBulkInvoice) ?
                <div className={"file-uploader-container"}>
                  <CustomUploader
                    className={"upload-files-container"}
                    defaultFileList={this.defaultFileList}
                    fileLimit={5}
                    hideDownloadIcon={!this.props.isFileLoaded}
                    onDownload={(data) => {
                      let file = null;
                      if (data.originFileObj) {
                        file = new Blob([data.originFileObj]);
                      } else if (data.uid === "-1") {
                        file = this.props.txFile;
                      } else if (data.uid === "-2") {
                        file = this.props.txFile2;
                      }
                      // const file = data.originFileObj ?  new Blob([data.originFileObj], {type: 'application/pdf'}) : this.props.txFile;
                      if (!file) return;
                      const fileURL = URL.createObjectURL(file);
                      const link = document.createElement("a");
                      document.body.appendChild(link);
                      link.download = data.name;
                      link.href = fileURL;
                      setTimeout(function () {
                        link.click();
                      }, 100);
                    }}
                    reference={this.uploadButtonRef}
                    hideButton={false}
                    onChange={(obj) => {
                      if (obj && obj.file) {
                        obj.file.name = `${obj.file.name} (${bytesToSize(obj.file.size)})`;
                      }
                      this.setState({ attachments: obj });
                    }}
                  />
                </div> : ''
              } */}
              <div style={{ clear: 'both' }}></div>
              <div className="placeholder-button" style={{ position: 'absolute', marginTop: '30px', right: '10px', zIndex: '91' }}>
                <Popover content={getPlaceHolders(this.state, 'editor')} placement="bottom" trigger="click" onClick={() => { this.setState({ isPopoverVisible: !this.state.isPopoverVisible, isSubjectPopoverVisible: false }); }}>
                  Placeholder <i className='fa fa-caret-square-o-down' />
                </Popover>
              </div>
              <Form.Item colon={false} className="email-editor">
                {getFieldDecorator('body', {
                  rules: [
                    {
                      required: true,
                      message: 'Message is required.',

                    }
                  ],
                  initialValue: this.state.emailBody || this.props.emailBody,
                })(
                  <CKEditor
                    // editor={ClassicEditor}
                    // config={FULL_CONFIGURATION}
                    key={`${"emailBody"}`}
                    data={this.state.body}
                    onInit={editor => {
                      this.editor = editor;
                    }}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      this.setState({ body: data });
                    }}
                    onAttachmentClick={() => {
                      this.uploadButtonRef && this.uploadButtonRef.current
                        && ReactDOM.findDOMNode(this.uploadButtonRef.current).click()
                    }}
                    config={EMAIL_BODY_CONFIGURATION}
                  />
                )}
              </Form.Item>

              {/* <Form.Item className="copy-checkbox">
                {getFieldDecorator('sendMeACopy', {
                })(
                    <Fragment>
                      <Checkbox id="sendCopy" checked={this.props.isSendCopy} onChange={(e) =>{
                        this.props.updateState({
                          isSendCopy: e.target.checked
                        })
                      }} />
                      <label className="pl10" htmlFor="sendCopy">Send me a copy</label>
                    </Fragment>
                  )}
              </Form.Item> */}
              {/* {!this.props.isFormSetting &&
                <Form.Item className="copy-checkbox">
                  {getFieldDecorator('isSendInvoicePdf', {
                  })(
                    <Fragment>
                      <Checkbox id="isSendInvoicePdf" checked={this.state.isSendInvoicePdf} onChange={(e) => {
                        this.setState({
                          isSendInvoicePdf: e.target.checked
                        })
                      }} />
                      <label className="pl10" htmlFor="isSendInvoicePdf"><FormattedMessage id='attachInvoicePdf.text' defaultMessage='' /></label>
                    </Fragment>
                  )}
                </Form.Item>
              } */}

            </div>
            <div {...this.tailFormItemLayout} className="footer-btn-group mt15">
              <CustomButton
                type='primary'
                intlId={this.props.isNewCommunication || this.props.isSendBulkInvoice ? 'forgot.btn.submit' : 'button.save.label'}
                defaultMessage=''
                onClick={() => {
                  this.props.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                      if (!this.props.isFormSetting && !this.props.isBulkInvoice && (!this.state.receivers || !this.state.receivers.length)) {
                        showToasterMessage({ messageType: 'error', description: 'To email id is required.' });
                        return;
                      }
                      if (!this.state.subject && !this.props.subject) {
                        showToasterMessage({ messageType: 'error', description: 'Subject is required.' });
                        return;
                      }
                      if (!this.state.body) {
                        showToasterMessage({ messageType: 'error', description: 'Message is required.' });
                        return;
                      }
                      const payload = {
                        sender: this.state.sender,
                        to: Array.isArray(this.state.receivers) ? this.state.receivers : [this.state.receivers],
                        subject: this.state.subject || this.props.subject,
                        message: this.state.body,
                        // sendMeACopy: this.state.sendMeACopy ? 1 : 0
                        isSendInvoicePdf: this.state.isSendInvoicePdf ? 1 : 0
                      };
                      if (this.state.ccRecievers) {
                        payload.cc = Array.isArray(this.state.ccRecievers) ? this.state.ccRecievers : [this.state.ccRecievers];
                      }
                      if (this.state.bccRecievers) {
                        payload.bcc = Array.isArray(this.state.bccRecievers) ? this.state.bccRecievers : [this.state.bccRecievers];
                      }

                      if (this.state.updateReminder) {
                        this.state.updateReminder(JSON.stringify(payload));
                      } else if (this.props.isSendBulkInvoice) {
                        this.props.showModal({
                          title: this.props.intl.formatMessage({ id: 'invoice.student.bulkEmail.title' }),
                          modalBody: <div>
                            <div>{this.props.intl.formatMessage({ id: 'invoice.student.bulkEmail.msg' })}</div>
                            <br />
                            <div className="button-action" style={{ display: 'flex',justifyContent: 'center', borderTop: '1px solid #e5e5e5',paddingTop: '15px'}}>
                              <Button className="ant-btn-primary"
                                onClick={() => {
                                  this.props.hideModal();
                                }}>  <FormattedMessage id='ok' defaultMessage='' /> </Button>
                            </div>
                          </div>,
                          hideFooter: true,
                        });
                        this.props.sendBulkEmail({
                          ...payload,

                          invoiceList: this.props.selectedInvoiceList,
                          studentList: this.props.selectedStudentList,
                          customerList: this.props.selectedCustomerList,
                          relationshipId: (this.props.companyInfo || {}).relationshipId,
                          createdByEmpId: (this.props.userInfo || {}).relationshipEmployeeId,
                          employeeName: ((this.props.userInfo || {}).firstName || '') + ' ' + ((this.props.userInfo || {}).lastName || ''),
                          isPaymentReminder: this.props.docName === 'paymentReminder' ? 1 : 0,
                          formId: this.props.defaultSchoolForm || 0,
                          module: 'school'
                        }, this.props, this.state);
                        this.props.updateState({
                          emailDetailDrawerVisible: false,
                          selectedInvoiceList: [],
                          selectedStudentList: [],
                        })
                      } else if (this.props.isNewCommunication) {
                        let payloadData = { ...payload, cc: [], bcc: [], relationshipId: (this.props.companyInfo || {}).relationshipId, createdByEmpId: (this.props.userInfo || {}).relationshipEmployeeId, employeeName: ((this.props.userInfo || {}).firstName || '') + ' ' + ((this.props.userInfo || {}).lastName || '') }
                        if (this.state.invoiceStatus && this.state.invoiceStatus.length) {
                          payloadData.status = this.state.invoiceStatus;
                          payloadData.module = 'invoice';
                          this.props.sendEmailByStatus(payloadData, this.props, this.state);
                        }
                        else if (this.state.studentStatus && this.state.studentStatus.length) {
                          payloadData.status = this.state.studentStatus;
                          payloadData.module = 'student';
                          this.props.sendEmailByStatus(payloadData, this.props, this.state);
                        }
                        else if (this.state.dgIdList && this.state.dgIdList.length) {
                          payloadData.dgIdList = this.state.dgIdList;
                          this.props.brodcastTextEmail(payloadData, this.props, this.state);
                        }

                      } else if (this.props.isSendAssignment) {
                        let stdList = [];
                        uniq(this.props.asmStudentList).forEach((std, i) => {
                          stdList.push(
                            {
                              assignmentId: this.state.assignmentId,
                              isAssignmentEmail: 1,
                              customerId: std.customerId,
                              relEmpId: (this.props.userInfo || {}).relationshipEmployeeId,
                              className: std.className,
                              classSchedule: std.classDayTime,
                              studentFirstName: std.firstName,
                              studentLastName: std.lastName,
                              fatherFirstName: std.fatherFirstName,
                              fatherLastName: std.fatherLastName,
                              motherFirstName: std.motherFirstName,
                              motherLastName: std.motherLastName,
                              fatherEmail: std.fatherEmail,
                              motherEmail: std.motherEmail,
                              companyName: (this.props.companyInfo || {}).storeName,
                              assesssmentName: this.props.assignmentData.assignmentName,
                              teacherFirstName: std.teacherFirstName,
                              teacherLastName: std.teacherLastName,
                              assignmentDueDate: this.props.assignmentData.dueDate
                            } 
                          );
                        });
                        let payloadData = { ...payload, cc: [], bcc: [], relationshipId: (this.props.companyInfo || {}).relationshipId, createdByEmpId: (this.props.userInfo || {}).relationshipEmployeeId, employeeName: ((this.props.userInfo || {}).firstName || '') + ' ' + ((this.props.userInfo || {}).lastName || '') }
                        payloadData.assignmentId = this.state.assignmentId;
                        payloadData.module = 'invoice';
                        payloadData.isAssignmentEmail = 1;
                        payloadData.assignmentList = stdList;
                        this.props.sendAssignmentEmail(payloadData, this.props, this.state);
                      }
                      else if (this.props.isFormSetting) {
                        this.props.updateState({ emailDetails: payload, emailDetailDrawerVisible: false });
                      } else {
                        this.props.updateState({ emailDetails: JSON.stringify(payload), emailDetailDrawerVisible: false });
                      }
                    }
                    //added showing bulk email notification only when validations are passed for email fields.
                 
                  });
                }}
              />
            </div>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create({ name: 'Email_Template' })(injectIntl(EmailBody));
