import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Col, Row } from 'antd';

import { Dropdown } from '../../../general/Dropdown';

function CreditMemoLedger(props) {

  const {
    allLedgerAccounts,
  } = props;
  

  const closeDrawer = () => {
    props.updateState({
      ledgerDrawerVisible: false
    })
  }

  return (
    <div>
      <Drawer
        title={
          <div className="drawer-title">
            <FormattedMessage id='drawer.ledger.title' defaultMessage='' />
          </div>
        }
        width={400}
        zIndex={1}
        destroyOnClose={true}
        keyboard={false}
        maskClosable={false}
        onClose={closeDrawer}
        visible={props.ledgerDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              props.initCalls();
            }
          }
        }
      >

        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.sales' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  value={ allLedgerAccounts.length ? props.salesLedgerId : ''}
                  optionKeyName='chartOfAccountDetailsId'
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        salesLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.debtors' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.salesDebtorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        salesDebtorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.salesDiscount' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.salesDiscountLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        salesDiscountLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.debtors' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.discountDebtorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        discountDebtorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
           <Row gutter={16}>
          <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.salesOutput' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  value={ allLedgerAccounts.length ? props.salesOutputTaxLedgerId : ''}
                  optionKeyName='chartOfAccountDetailsId'
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        salesOutputTaxLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

             <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.debtors' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.taxDebtorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        taxDebtorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          
          IGST
         
          <Row gutter={16}>
          <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.salesOutput' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  value={ allLedgerAccounts.length ? props.igstSalesOutputTaxLedgerId : ''}
                  optionKeyName='chartOfAccountDetailsId'
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        igstSalesOutputTaxLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

             <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.debtors' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.igstTaxDebtorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        igstTaxDebtorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
         

          SGST
          
          <Row gutter={16}>
          <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.salesOutput' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  value={ allLedgerAccounts.length ? props.sgstSalesOutputTaxLedgerId : ''}
                  optionKeyName='chartOfAccountDetailsId'
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        sgstSalesOutputTaxLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

             <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.debtors' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.sgstTaxDebtorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        sgstTaxDebtorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          
         

          CGST
          
          <Row gutter={16}>
          <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.salesOutput' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  value={ allLedgerAccounts.length ? props.cgstSalesOutputTaxLedgerId : ''}
                  optionKeyName='chartOfAccountDetailsId'
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        cgstSalesOutputTaxLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

             <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.debtors' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.cgstTaxDebtorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        cgstTaxDebtorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
          <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.generalIncome' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.expenseLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        expenseLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.debtors' defaultMessage='' />
                }
              >
                <Dropdown
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.expenseDebtorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        expenseDebtorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
            
            </Row>
            <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                "Coupon"
                }
              >
                <Dropdown
                canAddNew={true}
                // onAddNewClick={(props) => {
                //   newLedgerAccounts(props);
                // }}
                  items={allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={allLedgerAccounts.length ? props.couponLedgerId : ''}


                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        couponCreditorDebtorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.debtors' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                // onAddNewClick={(props) => {
                //   newLedgerAccounts(props);
                // }}
                  items={allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={allLedgerAccounts.length ? props.couponDebtorLedgerId : ''}


                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        couponDebtorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

          </Row>

         
        </Form>

      </Drawer>
    </div>
  );
}

export default injectIntl(CreditMemoLedger);
