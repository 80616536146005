import axios from "axios";
import config from "../../../../config/environmentConfig";
//import { MEMBERSHIP_ACTION_LIST } from "../../../../static/constants";
import { showToasterMessage } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const saveFees = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/fees/saveOrUpdate`,
        payload
      )
      .then((response) => {
        if (props) {
          props.updateHeaderState({
            membership: {
              addTermAndFeeDrawerVisible: false
            }
          });
          props.openTermAndFeeDrawerFromListing && props.getFees && props.getFees(props);
          props.openTermAndFeeDrawerFromListing && props.getFeeCount && props.getFeeCount(props);
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


