import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants';

export const fetchEmailAddress = (payload, ) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/boContact/getAllEmailAddresses?relationshipId=${payload.relationshipId}&customerId=${payload.customerId || 0}&supplierId=${payload.supplierId || 0}&targetId=0&leadId=0`)
        .then(res => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
          dispatch({ type: COMMON_ACTIONS.EMAIL_ADDRESS_LIST, data: res.data });
        })
        .catch(err => {
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        })
  }
}

export const loadEmailData = (payload) => {
    let dataPayload = { relationshipId: (payload.props.companyInfo || {}).relationshipId };
    dataPayload.docName = payload.state.docName || 'Sales Invoice';

    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER_EMAIL });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/emailTemplate/getEmailTemplate?docName=${dataPayload.docName}&relationshipId=${dataPayload.relationshipId}`)
        .then(res => {
          if (res.data) {
            payload.setState({ subject: res.data.subject, emailBody: res.data.content });
          }
          // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL });
        })
        .catch(err => {
          // dispatch({ type: COMMON_ACTIONS.HIDE_LOADER_EMAIL});
        })
    }
}