import React, { Fragment } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Button, Input, Popover, Tooltip,Skeleton } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import * as find from "lodash.find";
import * as filter from "lodash.filter";
import ReactHtmlParser from "react-html-parser";

import { Dropdown } from "../../../general/Dropdown";
import { CustomAgGrid } from "../../../general/CustomAgGrid";
import { AddAndRemoveColumn } from "../../../general/AddAndRemoveColumn";

import {
  AG_GRID_CONSTANTS,
  GRID_PREFERENCE_CONSTANTS,
} from "../../../../static/agGridConstants";
import { AG_GRID_CLASS_CONSTANTS } from "../../../../static/cssClassConstants";
import {
  MODAL_TYPE,
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  TRANSACTION_COLUMN_INDEX,
} from "../../../../static/constants";
import { getLabelName } from "../../../modal/modalBody/customerInfo";
import {
  showToasterMessage,
  fixedDecimalAndFormateNumber,
} from "../../../../utils";
import { CustomTableUpload } from "../../../general/UploadTable";
import { CUSTOM_CONFIGURATION } from "../../../../static/ckEditorConfigConstants";

import CKEditor from "../../../general/CustomCKEditor/ckeditor";
import { AWSFileTable } from "../../../general/AWSFileTable";

class CreditMemoDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      sectionVisible: {
        costCenter: false,
      },
      total: 0,
      subtotal: 0,
      subtotalWithoutDiscount: 0,
      totalDiscount: 0,
      expenseAmount: 0,
      totalTax: 0,
      itemLevelTaxes: [],
      rootDiscountPercent: 0,
      rootDiscountAmount: 0,
    };
  }

  calculateSubtotal = () => {
    let total = 0;
    let subtotal = 0;
    let totalDiscount = 0;
    let totalTax = 0;
    let itemLevelTaxes = [];
    let subtotalWithoutDiscount = 0;
    let rootDiscountAmount = 0;
    let self = this;

    this.gridApi &&
      this.gridApi.forEachNode(function (rowNode, index) {
        let stringifiedRowData = JSON.stringify(rowNode.data);
        let totalAmountOfColumn = 0;
        const rowdata = JSON.parse(stringifiedRowData);
        if (rowdata.qty && rowdata.rate) {
          let taxObj = {
            subtotal: 0,
            taxPercent: 0,
            taxAmount: 0,
          };
          totalAmountOfColumn = Number(rowdata.qty) * Number(rowdata.rate);
          subtotalWithoutDiscount =
            Number(totalAmountOfColumn) + Number(subtotalWithoutDiscount);
          if (rowdata.discount) {
            let discountAmount =
              Number(rowdata.discount || 0) * 0.01 * totalAmountOfColumn;
            totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            totalDiscount = totalDiscount + discountAmount;
          }

          if (self.state.rootDiscountPercent) {
            let itemLevelDiscountAmount =
              Number(self.state.rootDiscountPercent) *
              0.01 *
              totalAmountOfColumn;
            totalAmountOfColumn = totalAmountOfColumn - itemLevelDiscountAmount;
            totalDiscount = totalDiscount + itemLevelDiscountAmount;
            rootDiscountAmount = rootDiscountAmount + itemLevelDiscountAmount;
          }

          if (rowdata.tax) {
            taxObj.taxPercent = Number(rowdata.tax || 0).toFixed(2);
            taxObj.taxName = rowdata.taxName;
            let taxAmount =
              Number(rowdata.tax || 0) * 0.01 * totalAmountOfColumn;
            totalTax = totalTax + taxAmount;
          }
          if (taxObj.taxPercent) {
            taxObj.subtotal = Number(totalAmountOfColumn || 0).toFixed(2);
            const availableTaxObj = find(itemLevelTaxes, {
              taxPercent: taxObj.taxPercent,
            });
            if (availableTaxObj) {
              itemLevelTaxes = filter(itemLevelTaxes, (ele) => {
                if (ele.taxPercent === taxObj.taxPercent) {
                  ele.subtotal =
                    Number(ele.subtotal || 0) + Number(taxObj.subtotal || 0);
                  ele.taxAmount =
                    Number(ele.taxPercent || 0) *
                    0.01 *
                    Number(ele.subtotal || 0);
                }
                return true;
              });
            } else {
              taxObj.taxAmount = (
                Number(taxObj.taxPercent || 0) *
                0.01 *
                Number(taxObj.subtotal || 0)
              ).toFixed(2);
              taxObj.subtotal = Number(taxObj.subtotal || 0).toFixed(2);
              itemLevelTaxes.push(taxObj);
            }
          }
          subtotal = Number(subtotal) + totalAmountOfColumn;
        }
      });

    total = Number(subtotal || 0) + totalTax + this.state.expenseAmount;

    this.setState({
      total: Number(total || 0).toFixed(2),
      subtotal: Number(subtotal || 0).toFixed(2),
      totalDiscount: Number(totalDiscount || 0).toFixed(2),
      totalTax: Number(totalTax || 0).toFixed(2),
      subtotalWithoutDiscount: Number(subtotalWithoutDiscount || 0).toFixed(2),
      itemLevelTaxes,
      rootDiscountAmount,
    });
  };

  handleNewTaxAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    this.gridApi && this.gridApi.stopEditing();
    const data = {
      title: <FormattedMessage id="addItem.text.newTax" defaultMessage="" />,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.NEW_TAX,
        data: payload,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        showModal(data);
      },
    };
    showModal(data);
  };

  addItemConfirmationModal = (props, text, modalType) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        addFunc = props.addUOM;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        this.gridApi && this.gridApi.stopEditing();
        props.hideModal();
      },
    };
    showModal(modalData);
  };

  getModalTitle = (newTextObj, modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        title = <FormattedMessage id="addItem.text.uom" defaultMessage="" />;
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(payload, modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    this.gridApi && this.gridApi.stopEditing();
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.UOM: {
        deleteFunc = props.deleteUOM;
        break;
      }
      case MODAL_TYPE.NEW_TAX: {
        deleteFunc = props.deleteTax;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    showModal(modalData);
  };

  render() {
    // const { txColumnSetting } = this.props;
    const self = this;
    const { sectionVisible, itemLevelTaxes } = this.state;
    const onGridReady = (params) => {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      if (this.props.isColumnFitTable) {
        params.api.sizeColumnsToFit();
        this.props.updateState({
          isColumnFitTable: false,
        });
      }
      onDataRendered(params);
    };

    const _isColumnToHide = (columnName) => {
      // if (this.props.forceHideTxColumn && this.props.forceHideTxColumn.indexOf(columnName) > -1) {
      //   return true;
      // }
      return (this.props.txColumns || []).indexOf(columnName) < 0 &&
        (this.props.txMandatoryColumns || []).indexOf(columnName) < 0
        ? true
        : false;
    };

    const saveColumnIndex = (columnState) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.tablePreference) {
        gridPreference = JSON.parse(data.tablePreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data);
    };

    const saveColumnWidth = (columnState, width) => {
      let data = this.props.txPreferences || {};
      let gridPreference = {};
      data.relationshipId = (this.props.companyInfo || {}).relationshipId;
      data.clientUserAccountId = (this.props.userInfo || {}).userId;
      if (data.gridPreference) {
        gridPreference = JSON.parse(data.gridPreference);
      }
      gridPreference.columnSetting = columnState;
      gridPreference.columnList = this.props.txColumns;
      data.gridPreference = JSON.stringify(gridPreference);
      data.preferenceId = this.props.preferenceId;
      data.tablePreference = this.props.tablePreference;
      this.props.saveUserPrefrences(data, this.props);
    };

    let moveEvent = {};
    const onColumnMoved = (event, isDragStopped) => {
      if (event.column) {
        moveEvent = event;
      }
      if (
        isDragStopped &&
        moveEvent.column &&
        moveEvent.column.colDef &&
        moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API
      ) {
        saveColumnIndex(moveEvent.columnApi.getColumnState());
      }
    };

    const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
      const gridColumnApi = tableGridColumnApi || this.gridColumnApi;
      event.api.resetRowHeights();
      //const gridApi = tablegridApi || this.gridApi;
      if (
        event.type === "columnResized" &&
        event.source !== "sizeColumnsToFit" &&
        event.source !== "api" &&
        event.finished === true
      ) {
        event.api.resetRowHeights();
        if (
          gridColumnApi &&
          gridColumnApi.columnController.bodyWidth <
            gridColumnApi.columnController.scrollWidth
        ) {
          this.gridApi.sizeColumnsToFit();
          // const columnArray = event.columnApi.getColumnState();
          // for (let i = (columnArray.length - 1); i >= 0; i--) {
          //     if (columnArray[i] && !columnArray[i].hide && columnArray[i].colId !== 'action_1') {
          //         columnArray[i].width = columnArray[i].width + (gridColumnApi.columnController.scrollWidth - gridColumnApi.columnController.bodyWidth);
          //         break;
          //     }
          // }
          // saveColumnWidth(columnArray);
          setTimeout(() => {
            const columnArray = event.columnApi.getColumnState();
            saveColumnWidth(columnArray);
          }, 1000);
        } else {
          saveColumnWidth(event.columnApi.getColumnState());
        }
      }
    };

    const columnDefs = [
      {
        headerComponentFramework: () => {
          return "#";
        },
        cellRendererFramework: (params) => {
          return params.node.rowIndex + 1;
        },
        field: "sno",
        rowDrag: true,
        colId: "sno_1",
        minWidth: 70,
        maxWidth: 70,
        suppressMovable: true,
        lockPosition: true,
        sortable: true,
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.creditMemo.form.gridHeader.product"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD,
        colId: AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD + "_1",
        resizable: true,
        minWidth: 100,
        suppressMovable: true,
        editable: true,
        permissions: this.props.permissions,
        moduleName: MODULE_CODED_VALUES.PRODUCTS,
        operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
        sortable: true,
        hide:
          this.props.txColumns.indexOf(AG_GRID_CONSTANTS.COMMON.PRODUCT_FIELD) <
          0,
        cellEditor: "customTreeDropDown",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            options: this.props.products,
            onSearch: this.props.onSearch,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.PRODUCTS,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            onAddNewClick: () => {
              self.props.updateHeaderState({
                inventory: {
                  inventoryDrawerVisible: true,
                },
              });
            },
            canAddNew: true,
            value: obj.node.data.selectedProductValue || [],
            selectedLabel: obj.node.data.product || "",
            allowClear: true,
            onSelect: (selectedValue) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.selectedProductValue =
                obj.node.selectedProductValue;
              obj.node.data.productObj = null;
              obj.node.data.variant = null;
              const itemId =
                obj.node.data.selectedProductValue &&
                obj.node.data.selectedProductValue[0];
              if (itemId) {
                const variantId =
                  obj.data.selectedProductValue &&
                  obj.data.selectedProductValue.length === 2
                    ? obj.data.selectedProductValue[1]
                    : 0;
                obj.node.data.productObj =
                  find(this.props.products, { itemId: Number(itemId) }) || {};
                obj.node.data.variant = variantId
                  ? find(obj.node.data.productObj.inventoryItemVariantList, {
                      variantId: Number(variantId),
                    })
                  : obj.node.data.productObj.inventoryItemVariantList[0];
                if (obj.node.data.productObj.shortDescription) {
                  obj.node.data.description =
                    obj.node.data.productObj.shortDescription;
                }
              }
              if (obj.node.data.variant && !this.props.isDataToUpdate) {
                const rate = Number(
                  obj.node.data.variant.retailPrice || 0
                ).toFixed(2);
                obj.node.data.baseRate = Number(rate);
                obj.node.data.rate = Number(rate);
                //obj.node.setDataValue('specialDiscount', 0);
                //obj.node.setDataValue('baseRate', Number(rate));
                //obj.node.setDataValue('rate', Number(rate));
                //obj.node.setDataValue('uom', obj.node.data.variant.uomName);
                obj.node.data.uom = obj.node.data.variant.uomName;
                if (
                  obj.node.data.productObj.isPopulateTax &&
                  !_isColumnToHide(GRID_PREFERENCE_CONSTANTS.TAX)
                ) {
                  const defaultItemHsnAndTax =
                    obj.node.data.productObj.inventoryItemDefaultTaxList[0] ||
                    {};
                  obj.node.data.hsnCode = (defaultItemHsnAndTax || {}).hsnCode;
                  let selectedTax = {};
                  if (defaultItemHsnAndTax.taxSingleRateId) {
                    selectedTax =
                      find(this.props.taxes, {
                        taxSingleRateId: defaultItemHsnAndTax.taxSingleRateId,
                      }) || {};
                  } else {
                    selectedTax =
                      find(this.props.taxes, {
                        taxPercent:
                          (defaultItemHsnAndTax || {}).igstRate ||
                          (defaultItemHsnAndTax || {}).cgstRate ||
                          (defaultItemHsnAndTax || {}).globleTaxRate,
                      }) || {};
                  }
                  obj.node.data.taxName = selectedTax.taxName;
                  obj.node.data.taxApplied = selectedTax.taxNameDisplay;
                  obj.node.data.taxId = selectedTax.taxSingleRateId;
                  obj.node.data.tax = selectedTax.taxPercent;
                }
              }
              let isDuplicateItem = false;
              this.gridApi &&
                this.gridApi.forEachNode((rowNode, index) => {
                  const { data } = rowNode;
                  if (
                    data.product === selectedValue &&
                    index !== obj.node.rowIndex
                  ) {
                    isDuplicateItem = true;
                  }
                });
              if (isDuplicateItem) {
                showToasterMessage({
                  messageType: "warning",
                  description: (
                    <FormattedMessage
                      id="common.duplicateProduct.message"
                      defaultMessage=""
                    />
                  ),
                });
              }

              obj.node.data.isDuplicateItem = isDuplicateItem;
              setTimeout(() => {
                this.gridApi && this.gridApi.stopEditing();
              }, 100);
            },
          };
        },
        cellClassRules: {
          "ag-grid-cell-warning-boundary": function (obj) {
            let isDuplicateItem = false;
            self.gridApi &&
              self.gridApi.forEachNode((rowNode, index) => {
                const { data } = rowNode;
                if (
                  data.product &&
                  data.product === obj.node.data.product &&
                  obj.node.rowIndex !== index
                ) {
                  isDuplicateItem = true;
                }
              });
            return isDuplicateItem;
          },
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.creditMemo.form.gridHeader.description"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD,
        cellClass: " ag-cell-description",
        colId: AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD + "_1",
        resizable: true,
        editable: true,
        sortable: true,
        hide:
          this.props.txColumns.indexOf(
            AG_GRID_CONSTANTS.COMMON.DESCRIPTION_FIELD
          ) < 0,
        minWidth: 120,
        suppressMovable: true,
        cellEditor: "CKEditorGrid",
        cellEditorParams: (params) => {
          return {
            value: params.node.data.description || "",
            onChange: (value) => {
              params.node.data[params.colDef.field] = value;
            },
          };
        },
        cellRendererFramework: (params) => {
          return ReactHtmlParser(params.data.description);
        },
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing =
            params.event.target.value &&
            params.editing &&
            keyCode === KEY_ENTER;
          params.node.data.scrollHeight = `${params.event.target.scrollHeight}px`;
          if (params.event.target.scrollHeight > 30) {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${
                params.event.target.scrollHeight + 20
              }px`;
            }
            params.node.setRowHeight(params.event.target.scrollHeight + 20);
            this.gridApi && this.gridApi.onRowHeightChanged();
          } else {
            if (params.event.path && params.event.path[2]) {
              params.event.path[2].style.height = "inherit";
              params.event.path[2].style.height = `${30}px`;
            }
            params.node.setRowHeight(30);
            this.gridApi && this.gridApi.onRowHeightChanged();
          }
          return gridShouldDoNothing;
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.creditMemo.form.gridHeader.qty"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.QTY_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.QUANTITY + "_1",
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        editable: true,
        resizable: true,
        sortable: true,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthQty,
            onChange: (value) => {
              obj.node.data.qty = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.creditMemo.form.gridHeader.uom"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.UOM_FIELD,
        editable: true,
        sortable: true,
        resizable: true,
        hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0,
        colId: GRID_PREFERENCE_CONSTANTS.UOM + "_1",
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: false,
            items: this.props.uomList,
            optionKeyName: "uomId",
            valueKeyName: "uomName",
            canAddNew: true,
            canDelete: true,
            permissions: this.props.permissions,
            moduleName: MODULE_CODED_VALUES.UOM,
            operations: [PERMISSION_VALUES.CREATE, PERMISSION_VALUES.DELETE],
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.UOM, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                textBoxValue: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewItemAddition(this.props, payload, MODAL_TYPE.UOM);
            },
            onSelect: (selectedValue, option) => {
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.uomName = selectedValue;
              obj.node.data.uomId = option.key;
            },
            allowClear: true,
            onClear: () => {
              //obj.node.setDataValue(obj.colDef.field, undefined);
              obj.node.data.uomName = undefined;
              obj.node.data.uomId = undefined;
            },
          };
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <div>
              {" "}
              <span>
                <FormattedMessage id="Base-Rate" defaultMessage=" " />{" "}
              </span>
              <Tooltip
                placement="right"
                title={this.props.intl.formatMessage({
                  id: "tooltip.originalRate",
                  defaultMessage: "",
                })}
                trigger="click"
              >
                <i className="fa fa-question-circle-o pl5 cursor-pointer" />
              </Tooltip>
            </div>
          );
        },
        field: "baseRate",
        maxWidth: 150,
        minWidth: 100,
        suppressMovable: true,
        resizable: true,
        hide: _isColumnToHide(GRID_PREFERENCE_CONSTANTS.BASE_PRICE),
        colId: GRID_PREFERENCE_CONSTANTS.BASE_PRICE + "_1",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        valueGetter: (params) => {
          return (
            params.data.baseRate && Number(params.data.baseRate || 0).toFixed(2)
          );
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.creditMemo.form.gridHeader.rate"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.RATE_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.RATE + "_1",
        editable: true,
        minWidth: 100,
        maxWidth: 140,
        suppressMovable: true,
        sortable: true,
        resizable: true,
        cellEditor: "customNumberEditor",
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            onChange: (value) => {
              obj.node.data.baseRate = value;
              obj.node.data.rate = value;
              obj.api.refreshCells();
            },
          };
        },
        valueGetter: (params) => {
          if (params.data.specialDiscount && params.data.rate) {
            params.data.rate =
              params.data.baseRate -
              params.data.specialDiscount * 0.01 * params.data.baseRate;
          }
          if (!params.data.specialDiscount && params.data.baseRate) {
            params.data.rate = params.data.baseRate;
          }
          return (
            params.data.rate &&
            Number(params.data.rate || 0).toFixed(
              (this.props.companyInfo || {}).decimalDigitsLengthPrice || 0
            )
          );
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.creditMemo.form.gridHeader.hsn"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.HSN,
        editable: true,
        minWidth: 100,
        maxWidth: 130,
        suppressMovable: true,
        sortable: true,
        resizable: true,
        hide:
          this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 ||
          (this.props.companyInfo || {}).countryName !== "India",
        colId: GRID_PREFERENCE_CONSTANTS.HSN + "_1",
        cellEditor: "customTextEditor",
        cellEditorParams: (obj) => {
          return {
            onChange: (value) => {
              obj.node.data.hsnCode = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.creditMemo.form.gridHeader.specialDiscount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.SPL_DISCOUNT_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT + "_1",
        editable: true,
        minWidth: 100,
        maxWidth: 130,
        suppressMovable: true,
        sortable: true,
        hide:
          this.props.txColumns.indexOf(
            GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT
          ) < 0,
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.creditMemo.form.gridHeader.discount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.DISCOUNT_FIELD,
        editable: true,
        sortable: true,
        minWidth: 100,
        maxWidth: 130,
        suppressMovable: true,
        resizable: true,
        hide:
          this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0,
        colId: GRID_PREFERENCE_CONSTANTS.DISCOUNT + "_1",
        cellEditor: "customNumberEditor",
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
        cellEditorParams: (obj) => {
          return {
            decimalDigitsLength: (this.props.companyInfo || {})
              .decimalDigitsLengthPrice,
            maxValue: 100,
            onChange: (value) => {
              obj.node.data.discount = value;
              obj.api.refreshCells();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.salesQuote.form.gridHeader.tax"
              defaultMessage=""
            />
          );
        },
        field: "taxApplied",
        editable: true,
        minWidth: 120,
        suppressMovable: true,
        resizable: true,
        sortable: true,
        hide: this.props.txColumns.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0,
        colId: GRID_PREFERENCE_CONSTANTS.TAX + "_1",
        cellEditor: "customDropDownEditor",
        suppressKeyboardEvent: (params) => {
          const KEY_ENTER = 13;
          const keyCode = params.event.keyCode;
          const gridShouldDoNothing = params.editing && keyCode === KEY_ENTER;
          return gridShouldDoNothing;
        },
        cellEditorParams: (obj) => {
          return {
            lastColumnCell: true,
            items: this.props.taxes,
            optionKeyName: "taxSingleRateId",
            canAddNew: true,
            canDelete: true,
            deleteClickHandler: (payload) => {
              this.deleteClickHandler(this.props, MODAL_TYPE.NEW_TAX, payload);
            },
            onAddNewClick: (payload) => {
              payload.formData = {
                taxName: payload.textEntered,
                submittedOnce: false,
              };
              this.handleNewTaxAddition(
                this.props,
                payload,
                MODAL_TYPE.NEW_TAX
              );
            },
            valueKeyName: "taxNameDisplay",
            onSelect: (selectedValue, option) => {
              const selectedTax = find(this.props.taxes, {
                taxSingleRateId: Number(option.key),
              });
              obj.node.data.tax = selectedTax.taxPercent;
              obj.node.data.taxName = selectedTax.taxName;
              //obj.node.setDataValue(obj.colDef.field, selectedValue);
              obj.node.data.taxId = selectedTax.taxSingleRateId;
            },
            allowClear: true,
            onClear: () => {
              obj.node.data.tax = null;
              obj.node.data.taxId = null;
              obj.node.data.taxApplied = null;
              obj.node.data.taxName = null;
              this.calculateSubtotal();
            },
          };
        },
      },
      {
        headerComponentFramework: () => {
          return (
            <FormattedMessage
              id="customer.creditMemo.form.gridHeader.amount"
              defaultMessage=""
            />
          );
        },
        field: AG_GRID_CONSTANTS.COMMON.AMOUNT_FIELD,
        colId: GRID_PREFERENCE_CONSTANTS.AMOUNT + "_1",
        resizable: true,
        minWidth: 120,
        pinned: "right",
        suppressMovable: true,
        lockPosition: true,
        suppressNavigable: true,
        valueGetter: (params) => {
          let totalAmountOfColumn = 0;
          if (params.data.qty && params.data.rate) {
            totalAmountOfColumn = params.data.qty * params.data.rate;
            if (params.data.discount) {
              let discountAmount =
                params.data.discount * 0.01 * totalAmountOfColumn;
              totalAmountOfColumn = totalAmountOfColumn - discountAmount;
            }
            if (params.data.tax) {
              totalAmountOfColumn =
                params.data.tax * 0.01 * totalAmountOfColumn +
                totalAmountOfColumn;
            }
            params.data.amount = totalAmountOfColumn
              ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
              : "";
            this.calculateSubtotal();
          }
          return totalAmountOfColumn
            ? fixedDecimalAndFormateNumber(totalAmountOfColumn)
            : "";
        },
        cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
      },
      {
        headerComponentFramework: () => {
          return (
            <div className="cursor-pointer">
              <Popover
                content={<AddAndRemoveColumn {...this.props} />}
                title={
                  <div className="text-center">
                    <FormattedMessage id="common.addRemove" defaultMessage="" />{" "}
                  </div>
                }
                placement="left"
                trigger="click"
              >
                <i className="fa fa-bars" />
              </Popover>
            </div>
          );
        },
        field: "action",
        lockPosition: true,
        colId: "action_1",
        pinned: "right",
        minWidth: 50,
        maxWidth: 50,
        suppressMovable: true,
        cellRenderer: "customActionEditor",
        suppressNavigable: true,
        cellRendererParams: (params) => {
          return {
            onClick: () => {
              this.gridApi.updateRowData({
                remove: [
                  this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex)
                    .data,
                ],
              });
              if (this.gridApi.rowRenderer.lastRenderedRow < 0) {
                this.gridApi.updateRowData({ add: [{}] });
              }
              this.calculateSubtotal();
            },
          };
        },
      },
    ];

    const onModelUpdated = (params) => {
      if (this.props.tableDataReRendered) {
        setTimeout(() => {
          this.calculateSubtotal();
        }, 1500);
        params.api.resetRowHeights();
        this.props.updateState({
          tableDataReRendered: false,
        });
        let actionIndex = 0;
        let amountIndex = 0;
        let txColumnSetting =
          this.props.txColumnSetting && this.props.txColumnSetting.length
            ? this.props.txColumnSetting
            : params.columnApi.getColumnState();
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.CREDIT_MEMO_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);

        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.lockPosition = true;
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "amount") {
            e.hide = false;
            amountIndex = index;
            e.lockPosition = true;
            e.pinned = "right";
          }
          if (columnName === "action") {
            actionIndex = index;
            e.width = 52;
            e.hide = false;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        params.columnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
              this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      }
    };

    const onDataRendered = (params) => {
      let txColumnSetting =
        this.props.txColumnSetting && this.props.txColumnSetting.length
          ? this.props.txColumnSetting
          : params.columnApi.getColumnState();
      let actionIndex = 0;
      let amountIndex = 0;
      if (Object.keys(txColumnSetting).length) {
        const columnToBeSet = [];
        const indexObjArr = TRANSACTION_COLUMN_INDEX.CREDIT_MEMO_FORM;

        indexObjArr.forEach((e) => {
          let colObj = find(txColumnSetting, { colId: e + "_1" });
          if (colObj) {
            columnToBeSet.push(colObj);
          }
        });

        txColumnSetting = txColumnSetting.filter((ele) => {
          let columnName = ele.colId.split("_")[0];
          return indexObjArr.indexOf(columnName) === -1;
        });
        txColumnSetting = columnToBeSet.concat(txColumnSetting);

        txColumnSetting.forEach((e, index) => {
          let columnName = e.colId.split("_")[0];
          if (columnName !== "action" && columnName !== "amount") {
            if (columnName === "isChecked" || columnName === "sno") {
              e.pinned = "left";
              e.hide = false;
            } else {
              e.hide = _isColumnToHide(columnName);
            }
          }
          if (columnName === "action") {
            e.width = 52;
            actionIndex = index;
            e.hide = false;
            e.pinned = "right";
          }
          if (columnName === "amount") {
            e.hide = false;
            e.lockPosition = true;
            amountIndex = index;
            e.pinned = "right";
          }
        });
        const actionObj = txColumnSetting[actionIndex];
        const amountObj = txColumnSetting[amountIndex];
        txColumnSetting = txColumnSetting.filter((e) => {
          let columnName = e.colId.split("_")[0];
          return columnName !== "action" && columnName !== "amount";
        });
        amountObj.lockPosition = true;
        actionObj.lockPosition = true;
        txColumnSetting.push(amountObj);
        txColumnSetting.push(actionObj);
        this.gridColumnApi.setColumnState(txColumnSetting);
        setTimeout(() => {
          if (
            this.gridColumnApi &&
            this.gridColumnApi.columnController.bodyWidth <
              this.gridColumnApi.columnController.scrollWidth
          ) {
            params.api.sizeColumnsToFit();
          }
        }, 500);
      } else {
        this.gridApi.sizeColumnsToFit();
      }
    };

    return (
      <Fragment>
        <Skeleton loading={this.props.detailLoading} active paragraph={{ rows: 7 }}>

        <div className="transaction-table">
          {this.props.txColumns.length ? (
            <CustomAgGrid
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              onModelUpdated={onModelUpdated}
              onColumnResized={onColumnResized}
              onDragStopped={() => {
                onColumnMoved({}, true);
              }}
              //onColumnMoved={onColumnMoved}
              rowData={this.props.customerCreditMemoDetailsList || [{}]}
              gridStyle={{
                width: "100%",
                height: "100%",
                marginBottom: "10px",
              }}
            />
          ) : null}
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            className="grey-button"
            onClick={() => {
              this.gridApi.updateRowData({ add: [{}] });
            }}
          >
            <FormattedMessage
              id="customer.creditMemo.form.button.addLine"
              defaultMessage=""
            />
          </Button>
          <Button
            className="grey-button"
            onClick={() => {
              this.props.showModal({
                modalBody: (
                  <FormattedMessage
                    id="common.confirmation.clear"
                    defaultMessage=""
                  />
                ),
                handleSubmit: () => {
                  let gridApi = this.gridApi;
                  let self = this;
                  this.gridApi.forEachNode(function (rowNode, index) {
                    gridApi.updateRowData({ remove: [rowNode.data] });
                    if (gridApi.rowRenderer.lastRenderedRow < 0) {
                      gridApi.updateRowData({ add: [{}] });
                    }
                    self.calculateSubtotal();
                  });
                },
              });
            }}
          >
            <FormattedMessage
              id="customer.creditMemo.form.button.clearAllLines"
              defaultMessage=""
            />
          </Button>
        </div>
        <div className="upload-btn-so">
          <CustomTableUpload
            fileList={this.props.fileList}
            onChange={(fileList) => {
              this.props.updateState({
                fileList,
              });
            }}
          />
        </div>
        {this.props.creditMemoEditDetail?.docDetailList?.length ?
                    <>


                        Previous Data
                      
                        <AWSFileTable
                        {...this.props}
                        docDetailList={this.props.creditMemoEditDetail.docDetailList}
                        fetchTxDetail={(data) => {
                            const val = this.props.creditMemoEditDetail
                            val.docDetailList = []
                            this.props.updateState({
                                creditMemoEditDetail: val
                            })
                            this.props.fetchCreditMemoDetail({
                              customerId: this.props.creditMemoEditDetail.customerId,
                              creditMemoMasterId: this.props.creditMemoEditDetail.creditMemoMasterId,
                              relationshipId: this.props.creditMemoEditDetail.relationshipId
                          })
                        }}
                      />
                    </>
                    : ""}

        <Row>
          <Col span={12}>
            <div className="remarks-title">
              <FormattedMessage
                id="customer.creditMemo.form.internalRemarks.label"
                defaultMessage=""
              />
            </div>

            <CKEditor
              type="inline"
              className="description-textarea"
              key={`${"remarksInternal"}`}
              data={this.props.remarksInternal}
              onInit={(editor) => {
                this.editor = editor;
              }}
              onChange={(event) => {
                const data = event.editor.getData();
                this.props.updateState({ remarksInternal: data });
              }}
              config={{
                ...CUSTOM_CONFIGURATION,
                placeholder: "Enter internal remarks",
              }}
            />

            <div className="remarks-title">
              <FormattedMessage
                id="customer.creditMemo.form.customerRemarks.label"
                defaultMessage=""
              />
            </div>

            {/* <TextArea
              maxLength={500}
              className="description-textarea"
              rows={4}
              placeholder={intl.formatMessage(
                { id: 'customer.creditMemo.form.customerRemarks.placeholder', defaultMessage: '' }
              )}
              value={this.props.remarksCustomer}
              onChange={(e) => { this.props.updateState({ remarksCustomer: e.target.value }); }}
            /> */}
            <CKEditor
              type="inline"
              className="description-textarea"
              key={`${"remarksCustomer"}`}
              data={this.props.remarksCustomer}
              onInit={(editor) => {
                this.editor = editor;
              }}
              onChange={(event) => {
                const data = event.editor.getData();
                this.props.updateState({ remarksCustomer: data });
              }}
              config={{
                ...CUSTOM_CONFIGURATION,
                placeholder: "Enter customer remarks",
              }}
            />
          </Col>

          <Col span={12}>
            {/* <div className="checkbox-control-group">
              <Checkbox onChange={() => {
                this.setState({ sectionVisible: { ...sectionVisible, costCenter: !sectionVisible.costCenter } })
              }}><FormattedMessage id='customer.creditMemo.form.checkbox.costCenter' defaultMessage='' /></Checkbox>
            </div> */}

            <div className="product-amount-details top-space">
              <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title">
                      <FormattedMessage
                        id="customer.creditMemo.form.subTotal.label"
                        defaultMessage=""
                      />
                    </span>
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {fixedDecimalAndFormateNumber(
                      this.state.subtotalWithoutDiscount
                    )}
                  </Col>
                </Row>
              </div>

              {this.state.totalDiscount === "0.00" ||
              this.state.totalDiscount === 0 ? null : (
                <div className="amount-summary">
                  <Row>
                    <Col span={15}>
                      <span className="title">
                        <FormattedMessage
                          id="customer.creditMemo.form.totalDiscount.label"
                          defaultMessage=""
                        />
                      </span>
                    </Col>
                    <Col span={9}>
                      {(this.props.companyInfo || {}).currencyIcon ? (
                        <i
                          className={
                            (this.props.companyInfo || {}).currencyIcon
                          }
                        ></i>
                      ) : (
                        (this.props.companyInfo || {}).currencyCode + " "
                      )}
                      {fixedDecimalAndFormateNumber(this.state.totalDiscount)}
                    </Col>
                  </Row>
                </div>
              )}

              {/* {
                                false && this.state.totalTax == 0 ? null : <div className="amount-summary">
                                    <div className="w-50">
                                        <span className="title">Tax</span>
                                    </div>
                                    <div className="w-50-default">
                                        <span className="rupee">{(this.props.companyInfo || {}).currencyIcon ? <i className={(this.props.companyInfo || {}).currencyIcon}></i> : (this.props.companyInfo || {}).currencyCode + " "}</span>
                                        {this.state.totalTax}
                                    </div>
                                </div>
                            } */}

              {itemLevelTaxes.length === 0
                ? null
                : itemLevelTaxes.map((taxObj, i) => {
                    return (this.props.companyInfo || {}).countryName ===
                      "w" ? (
                      <div key={i}>
                        <div className="amount-summary">
                          <Row>
                            <Col span={15}>
                              <span className="title">
                                <FormattedMessage
                                  id="common.CGST"
                                  defaultMessage=""
                                />{" "}
                                {taxObj.taxPercent / 2}% on {taxObj.subtotal}{" "}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                            </Col>
                          </Row>
                        </div>

                        <div className="amount-summary">
                          <Row>
                            <Col span={15}>
                              <span className="title">
                                <FormattedMessage
                                  id="common.SGST"
                                  defaultMessage=""
                                />{" "}
                                {taxObj.taxPercent / 2}% on {taxObj.subtotal}{" "}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {(Number(taxObj.taxAmount || 0) / 2).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ) : (
                      <div key={i}>
                        <div className="amount-summary">
                          <Row>
                            <Col span={15}>
                              <span className="title">
                                {taxObj.taxName} @ {taxObj.taxPercent}% on{" "}
                                {taxObj.subtotal}{" "}
                              </span>
                            </Col>
                            <Col span={9}>
                              {(this.props.companyInfo || {}).currencyIcon ? (
                                <i
                                  className={
                                    (this.props.companyInfo || {}).currencyIcon
                                  }
                                ></i>
                              ) : (
                                (this.props.companyInfo || {}).currencyCode +
                                " "
                              )}
                              {Number(taxObj.taxAmount || 0).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })}

              <div className="amount-summary">
                <Row>
                  <Col span={15}>
                    <span className="title">
                      <FormattedMessage
                        id="customer.creditMemo.form.totalAmount.label"
                        defaultMessage=""
                      />
                    </span>
                  </Col>
                  <Col span={9}>
                    {(this.props.companyInfo || {}).currencyIcon ? (
                      <i
                        className={(this.props.companyInfo || {}).currencyIcon}
                      ></i>
                    ) : (
                      (this.props.companyInfo || {}).currencyCode + " "
                    )}
                    {fixedDecimalAndFormateNumber(this.state.total)}
                  </Col>
                </Row>
              </div>

              <div>
                {sectionVisible.costCenter ? (
                  this.props.costCenters.length === 0 ? (
                    <Fragment>
                      <Row type="flex" className="amount-summary" justify="end">
                        <Col span={24}>
                          <FormattedMessage
                            id="customer.creditMemo.form.costCenter.label"
                            defaultMessage=""
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  ) : (
                    (this.props.costCentersData || []).map(
                      (costCenterObj, index) => {
                        return (
                          <Fragment key={index}>
                            <Row
                              type="flex"
                              className="amount-summary"
                              justify="end"
                            >
                              <Col span={6}>
                                <FormattedMessage
                                  id="customer.creditMemo.form.costCenter.label"
                                  defaultMessage=""
                                />
                              </Col>

                              <Col span={10}>
                                <Dropdown
                                  items={this.props.costCenters}
                                  style={{ width: "50%" }}
                                  valueKeyName="costCenterName"
                                  optionKeyName="costCenterId"
                                  value={costCenterObj.type}
                                  canAddNew={true}
                                  onSelect={(type, optionObj) => {
                                    const costCentersData = Object.assign(
                                      [],
                                      this.props.costCentersData
                                    );
                                    costCentersData[index].type =
                                      optionObj.props.name;
                                    this.props.updateState({ costCentersData });
                                  }}
                                  canDelete={true}
                                  allowClear={true}
                                  onClear={() => {
                                    this.props.updateState({
                                      costCentersData: undefined,
                                    });
                                  }}
                                />
                              </Col>

                              <Col span={6}>
                                <span className="mark">
                                  {(this.props.companyInfo || {})
                                    .currencyIcon ? (
                                    <i
                                      className={
                                        (this.props.companyInfo || {})
                                          .currencyIcon
                                      }
                                    ></i>
                                  ) : (
                                    (this.props.companyInfo || {})
                                      .currencyCode + " "
                                  )}
                                </span>
                                <Input
                                  placeholder="0.00"
                                  type="number"
                                  value={costCenterObj.value}
                                  onChange={(e) => {
                                    const costCentersData = Object.assign(
                                      [],
                                      this.props.costCentersData
                                    );
                                    costCentersData[index].value =
                                      e.target.value;
                                    this.props.updateState({ costCentersData });
                                  }}
                                />
                              </Col>

                              <Col span={2}>
                                <DeleteOutlined
                                  className="delete-icon"
                                  onClick={(e) => {
                                    const costCentersData = Object.assign(
                                      [],
                                      this.props.costCentersData
                                    );
                                    costCentersData.splice(index, 1);
                                    this.props.updateState({ costCentersData });
                                  }}
                                  style={{ float: "right" }}
                                />
                              </Col>
                            </Row>
                          </Fragment>
                        );
                      }
                    )
                  )
                ) : (
                  ""
                )}
                {sectionVisible.costCenter ? (
                  <Fragment>
                    <Row type="flex" className="amount-summary" justify="end">
                      <Col span={10}>
                        <Button
                          className="grey-button"
                          onClick={() => {
                            const costCentersData = Object.assign(
                              [],
                              this.props.costCentersData
                            );
                            costCentersData.push({});
                            this.props.updateState({ costCentersData });
                          }}
                        >
                          <FormattedMessage
                            id="customer.creditMemo.form.addCostCenter.label"
                            defaultMessage=""
                          />
                        </Button>
                      </Col>
                    </Row>
                  </Fragment>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
        </Row>

        <CKEditor
          type="inline"
          className="add-footer-textarea2"
          key={`${this.props.footer ? "footer" : "footer"}`}
          data={this.props.footer}
          onInit={(editor) => {
            this.editor = editor;
          }}
          onChange={(event) => {
            const data = event.editor.getData();
            this.props.updateState({ footer: data });
          }}
          config={{ ...CUSTOM_CONFIGURATION, placeholder: "Add Footer Here" }}
        />
<div className="pb40"></div>
        <div className="footer">
          <div className="left-fItems">
            <Button
              className="grey-button"
              onClick={() => {
                this.props.showModal({
                  modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
                  footer:{submit:{intlId:"button.close.label"}},
                  handleSubmit: () => {
                    this.props.popModalFromStack();
                  }
                })
              }}
            >
              <FormattedMessage id="button.cancel.label" defaultMessage="" />
            </Button>
          </div>

          <div className="right-fItems">
            <Button
              className="ant-btn-primary"
              loading={this.props.saveLoading}
              disabled={this.props.saveLoading}
              onClick={() => {
                saveSalesOrder(this.props, this.state, this.gridApi);
              }}
            >
              <FormattedMessage id="button.save.label" defaultMessage="" />
            </Button>
          </div>
        </div>
        </Skeleton>
      </Fragment>
    );
  }
}

// const saveSalesOrder = (props, state, agGridObj) => {
//   let isRequiredFiledMissing = false;
//   agGridObj.forEachNode((rowNode, index) => {
//     let { data } = rowNode;
//     if (index === 0 && ((!data.product && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0)))) {
//       isRequiredFiledMissing = true;
//     } else if (!data.product && !(Number(data.rate || 0)) && !(Number(data.qty || 0))) {
//       data = {};
//     } else if ((!data.product && !data.description) || !(Number(data.rate || 0)) || !(Number(data.qty || 0))) {
//       isRequiredFiledMissing = true;
//     }
//   });

//   if (isRequiredFiledMissing || !(props.customer && props.customer.customerId)) {
//     return showToasterMessage({
//       messageType: 'error', description: 'Please select Customer, Product/Description and Quantity'
//     });
//   }

const saveSalesOrder = (props, state, agGridObj) => {
  // let isRequiredFieldMissing = false;
  let errorKey = "";
  if (!props.customer || !props.customer.customerId) {
    return showToasterMessage({
      messageType: "error",
      description: props.intl.formatMessage({
        id: "missingCustomer.errorMsg",
        defaultMessage: "",
      }),
    });
  }
  agGridObj.forEachNode((rowNode, index) => {
    let { data } = rowNode;
    if (!data.product && !Number(data.rate || 0) && !Number(data.qty || 0)) {
      data = {};
    }
    if (index === 0 && !data.product && !data.description) {
      errorKey = "missingProduct.errorMsg";
    } else if (index === 0 && !Number(data.qty || 0)) {
      errorKey = "missingQty.errorMsg";
    } else if (index === 0 && !Number(data.rate || 0)) {
      errorKey = "missingRate.errorMsg";
    }
  });

  if (errorKey) {
    return showToasterMessage({
      messageType: "error",
      description: props.intl.formatMessage({
        id: errorKey,
        defaultMessage: "",
      }),
    });
  }
  const modalData = {
    modalBody: (
      <FormattedMessage
        id="customer.creditMemo.form.confirmation"
        defaultMessage=""
      />
    ),
    handleSubmit: () => {
      const payload = {
        props,
        state,
        agGridObj,
      };
      props.createCreditMemo(payload, props);
      // props.hideModal();
      props.popModalFromStack();
    },
  };
  props.showModal(modalData);
};

export default injectIntl(CreditMemoDetails);
