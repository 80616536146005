import React, { Fragment, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Skeleton, Pagination, Input, Popconfirm, Menu, Checkbox } from "antd";
import {
  fetchPaginationDataIfNeeded,
  checkACLPermission,
  checkSecondaryPermission,
  sortTable,
  showToasterMessage,
} from "../../../../utils";
import {
  MODULE_CODED_VALUES,
  PERMISSION_VALUES,
  SECONDARY_PERM_KEYS,
  TRANSACTION_TYPES,
  MODAL_TYPE,
  ICONS,
} from "../../../../static/constants";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import PageBreadcrumb from "../../../PageBreadcrumb";
import "./index.scss";
import cloneDeep from "lodash/cloneDeep";
import * as debounce from "lodash.debounce";
import DropdownAction from "antd/lib/dropdown";
import CategoryDetailsModel from "../../../../containers/modal/modalBody/product/CategoryDetailsModel";
import PlusIcon from './../../../../assets/images/plus.png';
import MinusIcon from './../../../../assets/images/minus.png';
import PageHeader from "../../../Common/pageHeader";
import find from "lodash.find";

const { Search } = Input;

const EditableContext = React.createContext();

// class EditableCell extends React.Component {
//     getInput = () => {
//         if (this.props.inputType === 'number') {
//             return <Input type="number" />;
//         }
//         return <Input />;
//     };

//     renderCell = ({ getFieldDecorator }) => {
//         const {
//             editing,
//             dataIndex,
//             title,
//             inputType,
//             record,
//             index,
//             children,
//             ...restProps
//         } = this.props;
//         return (
//             <td {...restProps}>
//                 {editing ? (
//                     <Form.Item style={{ margin: 0 }}>
//                         {getFieldDecorator(dataIndex, {
//                             rules: [{ required: false }],
//                             initialValue: record[dataIndex],
//                             valuePropName: 'value',
//                         })(this.getInput())}
//                     </Form.Item>
//                 ) : (
//                         children
//                     )}
//             </td>
//         );
//     };

//     render() {
//         return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
//     }
// }

const onSearchedInputChange = debounce((searchedText, props) => {
  handleSearchedText(searchedText, props);
}, 500);

const handleSearchedText = (searchedText, props) => {
  const payload = {
    relationshipId: props.companyInfo.relationshipId,
    pageNumber: 1,
    pageSize: props.pageSize,
    searchedText: searchedText,
  };
  if (searchedText.length) {
    if (
      searchedText.length >= 3 &&
      !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())
    ) {
      props.resetPaginatedData();
      props.getProductCount(payload);
      props.fetchProducts(payload);
    }
  } else {
    props.resetPaginatedData();
    props.getProductCount(payload);
    props.fetchProducts(payload);
  }
};

const ProductPriceListingComp = (props) => {
  const {
    permissions,
    updateState,
    searchedText,
    companyInfo,
    warehouseMasterId,
    dir = 0,
    pageNumber, pageSize,
    selectedItems,
    updatedPriceItems,
    // editablePrices,
  } = props;
  const secondaryPermissions = permissions.secondary;
  // const [editablePrices, setEditablePrices] = useState({});

  const handleInputChange = (e, variantId, field) => {

    const value = Number(e.target.value);


    let selectItem = find(selectedItems, selected =>
      selected.variantId === variantId
    );
    let updatedItems = [];
    const arr = selectedItems?.filter((item) => item?.variantId !== variantId)
    selectItem[field] = value;
    updatedItems = [...arr, selectItem];



    props.updateState({ updatedPriceItems: updatedItems });


  };
  const [childOnList, setChildOn] = useState([]);
  const getVariantName = (data) => {
    return (
      <div>
        {data.attributeValue1 || ""}{" "}
        {data.attributeValue2 ? " | " + data.attributeValue2 : ""}{" "}
        {data.attributeValue3 ? " | " + data.attributeValue3 : ""}
      </div>
    );
  };
  if (!updatedPriceItems) {
    props.updateState({ updatedPriceItems: [] });
  }
  if (!selectedItems) {
    props.updateState({ selectedItems: [] });
  }
  const _getDataList = (dataList) => {
    dataList &&
      dataList.forEach((dt, dtIndex) => {
        if (
          dt.hasVariant &&
          dt.inventoryItemVariantList &&
          dt.inventoryItemVariantList.length
        )
          dt.children = dt.inventoryItemVariantList;
        dt.itemNameWithVariant = dt.itemName;

        dt.children &&
          dt.children.forEach((dt2, dt2Index) => {
            dt2.itemName = getVariantName(dt2);
            dt2.itemNameWithVariant =
              dt.itemName +
              "(" +
              (dt2.attributeValue1 || "") +
              (dt2.attributeValue2 ? " | " + dt2.attributeValue2 : "") +
              (dt2.attributeValue3 ? " | " + dt2.attributeValue3 : "") +
              ")";
            dt2.key = dtIndex + "" + dt2Index;
          });

        if (
          !dt.hasVariant &&
          dt.inventoryItemVariantList &&
          dt.inventoryItemVariantList.length
        ) {
          dt.purchasedPrice = dt.inventoryItemVariantList[0].purchasedPrice;
          dt.wholesalePrice = dt.inventoryItemVariantList[0].wholesalePrice;
          dt.retailPrice = dt.inventoryItemVariantList[0].retailPrice;
          dt.variantId = dt.inventoryItemVariantList[0].variantId;
          dt.isPriceUpdatePendingApproval =
            dt.inventoryItemVariantList[0].isPriceUpdatePendingApproval;
          dt.priceUpdateApproverEmpId =
            dt.inventoryItemVariantList[0].priceUpdateApproverEmpId;
        }
      });

    return dataList || [];
  };
  let products = _getDataList(cloneDeep(props.products[props.pageNumber]));
  products = products.map((contactObj, index) => {
    contactObj.key = index + 1;
    return contactObj;
  });
  const variantItems = () => {
    let allItems = [];
    for (let i = 0; i < products.length; i++) {

      let item = products[i].inventoryItemVariantList;


      allItems = allItems.concat(item);
    }
    return allItems;
  }
  const itemSelection = (item) => {

    let selectItem = find(selectedItems, selected =>
      selected.variantId === item?.variantId
    );
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.variantId !== item.variantId
      );
    } else {
      updatedItems = selectedItems;
      updatedItems.push(item);
    }


    props.updateState({ selectedItems: updatedItems });
  };
  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }



    const allItems = variantItems();


    for (let i = 0; i < allItems.length; i++) {
      let item = allItems[i];


      let selectItem = find(selectedItems, selected =>
        selected.variantId === item?.variantId
      );

      if (!selectItem) {
        selectedItems.push(item);
      }
    }

  };
  const isCheckedAll = () => {
    if (
      !products || products.length === 0
    ) {
      return false;
    }
    const allItems = variantItems()
    for (let i = 0; i < allItems.length; i++) {
      let item = allItems[i];


      let selectItem = find(selectedItems, selected =>
        selected.variantId === item?.variantId
      );
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const productCount = props.productCount;

  const getActionPermissionData = () => {
    const primaryPerm = permissions.primary || [];
    const permittedAction = {
      update: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.PRODUCT_PRICE,
        PERMISSION_VALUES.UPDATE
      ),
      create: checkACLPermission(
        primaryPerm,
        MODULE_CODED_VALUES.PRODUCT_PRICE,
        PERMISSION_VALUES.CREATE
      ),
    };
    Object.keys(permittedAction).forEach((operation) => {
      if (permittedAction[operation] === false) {
        delete permittedAction[operation];
      }
    });
    return permittedAction;
  };

  const permittedAction = getActionPermissionData();
  let columnDefs = [
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.product"
          defaultMessage=""
        />
      ),
      dataIndex: "itemName",
      key: "itemName",
      width: 300,
      className: "leftAlignCell",
    },
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.sku"
          defaultMessage=""
        />
      ),
      dataIndex: "sku",
      key: "sku",
      width: 115,
      className: "leftAlignCell",
    },
    // {
    //     title: <FormattedMessage id='inventory.warehouse.productPrice.listing.header.itemCode' defaultMessage='' />,
    //     dataIndex: "sitemCodeku",
    //     key: "itemCode"
    // },
    // {
    //     title: <FormattedMessage id='inventory.warehouse.productPrice.listing.header.description' defaultMessage='' />,
    //     dataIndex: "description",
    //     key: "description"
    // },
    {
      title: (
        <FormattedMessage
          id="inventory.warehouse.stockAdjustment.listing.header.category"
          defaultMessage=""
        />
      ),
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: (
        <FormattedMessage
          id="drawer.inventory.purchasedPrice"
          defaultMessage=""
        />
      ),
      dataIndex: "purchasedPrice",
      key: "purchasedPrice",
      secondaryPermKey: SECONDARY_PERM_KEYS.PRODUCT_PRICE_LIST_PURCHASE_PRICE,
      editable: true,
      render: (purchasedPrice, row) => (
        <div className="price-input pull-right">
          {purchasedPrice ? purchasedPrice.toFixed(2) : "0.00"}
        </div>
      ),
      className: "editable-cell",
    },
    {
      title: <FormattedMessage id="Wholesale Price" defaultMessage="" />,
      dataIndex: "wholesalePrice",
      key: "wholesalePrice",
      secondaryPermKey: SECONDARY_PERM_KEYS.PRODUCT_PRICE_LIST_WHOLESALE_PRICE,
      editable: true,
      render: (wholesalePrice, row) => (
        <div className="price-input pull-right">
          {wholesalePrice ? wholesalePrice.toFixed(2) : "0.00"}
        </div>
      ),
      className: "editable-cell",
    },
    {
      title: (
        <FormattedMessage id="drawer.inventory.retailPrice" defaultMessage="" />
      ),
      dataIndex: "retailPrice",
      key: "retailPrice",
      secondaryPermKey: SECONDARY_PERM_KEYS.PRODUCT_PRICE_LIST_RETAIL_PRICE,
      editable: true,
      render: (retailPrice, row) => (
        <div className="price-input pull-right">
          {retailPrice ? retailPrice.toFixed(2) : "0.00"}
        </div>
      ),
      className: "editable-cell",
    },
  ];

  columnDefs = columnDefs.filter((obj) => {
    return (
      !obj.secondaryPermKey ||
      checkSecondaryPermission(secondaryPermissions, obj.secondaryPermKey)
    );
  });

  if (permittedAction.update) {
    columnDefs.push({
      title: "",
      render: (text, record) => {
        const { editingKey } = props;
        const editable = isEditing(record);
        return editable ? (
          <span className="table-cell-action">
            <EditableContext.Consumer>
              {(form) => (
                <Link
                  onClick={() => save(form, record)}
                  style={{ marginRight: 8 }}
                >
                  <i className="fa fa-save" />
                </Link>
              )}
            </EditableContext.Consumer>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => cancel(record.key)}
            >
              <Link>
                <i className="fa fa-ban" />
              </Link>
            </Popconfirm>
          </span>
        ) : record.hasVariant ? (
          <div></div>
        ) : (
          <div className="table-cell-action">
            {record.isPriceUpdatePendingApproval ? (
              <div>
                {(props.userInfo || {}).relationshipEmployeeId !==
                  record.priceUpdateApproverEmpId ? (
                  <div>
                    <Link
                      disabled={editingKey !== ""}
                      onClick={() => {
                        approve(record);
                      }}
                    >
                      <i title="Approved" className="fa fa-thumbs-up" />
                    </Link>
                    <Link
                      disabled={editingKey !== ""}
                      onClick={() => {
                        handleDelegateApprover(record);
                      }}
                    >
                      <i title="Delegate Approver" className="fa fa-share" />
                    </Link>
                    <Link
                      disabled={editingKey !== ""}
                      onClick={() => {
                        handleRejectApproval(record);
                      }}
                    >
                      <i
                        title="Reject Approval"
                        className="fa fa-thumbs-down"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <span
                disabled={editingKey !== ""}
                onClick={() => {
                  edit(record.key || record.sNo);
                }}
              >
                <i title="Edit" className="fa fa-edit" />
              </span>
            )}
          </div>
        );
      },
      width: 150,
    });
  }

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      warehouseMasterId: warehouseMasterId,
    };
    fetchPaginationDataIfNeeded("fetchProducts", "products", props, payload);
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
      warehouseMasterId: warehouseMasterId,
    };
    props.resetPaginatedData();
    props.fetchProducts(payload);
    props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
  };

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };
  const isEditing = (record) => record.key === props.editingKey;

  const cancel = () => {
    updateState({ editingKey: "" });
  };
  const createPayload = (updatedPriceItems) => {

    const arr = updatedPriceItems.map((item, i) => {
      return {
        "variantId": item?.variantId,
        "relationshipId": props.companyInfo?.relationshipId,
        "retailPrice": item?.retailPrice,
        "purchasedPrice": item?.purchasedPrice,
        "wholesalePrice": item?.wholesalePrice
      }
    })

    props.approvePriceChange(arr, props)
  }
  const updateItemPrice = () => {

    if (selectedItems.length) {
      createPayload(updatedPriceItems);
    }
    else {
      showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "common.record.notSelected.proceeding",
        }),
      });
    }

  }
  const save = (form, data) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      updateProduct(props, data, row, cancel);
    });
  };

  const edit = (key) => {
    updateState({ editingKey: key });
  };

  const approve = (record) => {
    const modalBody = "Are you sure you want to approve?";
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.approvePriceChange(
          {
            relationshipId: record.relationshipId,
            variantId: record.variantId,
            itemId: record.itemId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
          },
          props
        );
      },
    };
    props.showModal(modalData);
  };

  const handleDelegateApprover = (record) => {
    const formData = {
      relationshipId: record.relationshipId,
      variantId: record.variantId,
      itemId: record.itemId,
      approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
      txType: TRANSACTION_TYPES.PRICE_CHANGE,
    };
    const { showModal } = props;
    const data = {
      title: "Delegate Approver",
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.DELEGATE_APPROVER,
        data: formData,
      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
        props.popModalFromStack();
      },
    };
    showModal(data);
  };

  const handleRejectApproval = (record) => {
    const modalBody = "Are you sure you want to reject?";
    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.rejectPriceChange(
          {
            relationshipId: record.relationshipId,
            variantId: record.variantId,
            itemId: record.itemId,
            approvedByEmpId: (props.userInfo || {}).relationshipEmployeeId,
          },
          props
        );
      },
    };
    props.showModal(modalData);
  };

  // const components = {
  //     body: {
  //         cell: EditableCell,
  //     },
  // };

  // const columns = columnDefs.map((col, index) => {
  //     col.key = index;
  //     if (!col.editable) {
  //         return col;
  //     }
  //     let inputType;
  //     if (col.dataIndex === 'purchasedPrice' || col.dataIndex === 'wholesalePrice' || col.dataIndex === 'retailPrice') {
  //         inputType = 'number'
  //     } else {
  //         inputType = 'text';
  //     }
  //     return {
  //         ...col,
  //         onCell: record => {
  //             const cellProps = {
  //                 record,
  //                 inputType,
  //                 dataIndex: col.dataIndex,
  //                 title: col.title,
  //                 editing: isEditing(record),
  //             }
  //             return cellProps;
  //         },
  //     };
  // });

  const breadCrumbList = [
    {
      name: "Products",
    },
    {
      name: "Product Price",
    },
  ];
  const sortColumn = (e) => {
    sortTable(
      document.getElementById("stock-adjustment-table"),
      e.target.cellIndex,
      1 - dir
    );
    updateState({ dir: 1 - dir });
  };
  const isChildOn = (id) => {
    if (childOnList.includes(id)) {
      return true;
    }
    return false;
  };
  const setChildOff = (data) => {
    const itemIndex = childOnList.indexOf(data);
    const newArr = childOnList.splice(itemIndex, 1);
  };
  const actionMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              productPrizeDrawerVisible: true,
              data: { ...e },
            });
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>
      </Menu>
    );
  };
  const actionMenuChild = (e, f) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="edit"
          onClick={() => {
            props.updateState({
              productPrizeDrawerVisible: true,
              data: { ...e },
              subData: { ...f },
            });
          }}
        >
          <i className={ICONS.EDIT} />
          Edit
        </Menu.Item>
      </Menu>
    );
  };


  const openClassDetailPopup = (item) => {
    const modalData = {
      title: <FormattedMessage id='inventory.invValuation.listing.model.title' defaultMessage='' />,
      modalBody: <CategoryDetailsModel {...props} CategoryObj={item} />,
      width: '50%',
      hideFooter: true,
    };
    props.pushModalToStack(modalData);
  }




  return (
    <>
      <PageHeader {...props}
        pageName="inventory.productPrice.listing.header.productPrice"
        breadCrumbList={breadCrumbList}
      />

      <div className="view-container">
        <div className="view-container-actions">
          <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>

            <div className="left-actions">
              <div className="table-heading">
                <FormattedMessage
                  id="inventory.productPrice.listing.header.productPrice.table.heading"
                  defaultMessage=""
                />
              </div>
              {props.pageSize ? (
                <>
                  <div className="vertical-sep" />
                  <div>{productCount}</div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="right-actions">

              <Search
                placeholder="Enter 3 Characters of SKUID or Product Name"
                value={searchedText}
                onChange={(e) => {
                  updateState({ searchedText: e.target.value, pageNumber: 1 });
                  onSearchedInputChange(e.target.value, props);
                }}
                onSearch={(searchedText) => {
                  updateState({ searchedText, pageNumber: 1 });
                  onSearchedInputChange(searchedText, props);
                  handleSearchedText(searchedText, props);
                }}
                className="search-col"
                
              />
                 <button
                className="upload-button"
                onClick={() => {
                  updateItemPrice();
                }}

              >
                <i className={ICONS.UPLOAD} />{" "}
                <FormattedMessage
                  id="Update"
                  defaultMessage=""
                />
              </button>
            </div>
          </Skeleton>
        </div>
        <div className="table-container">
          <Skeleton loading={props.listLoading} active paragraph={{ rows: 15 }}>
            <table>
              <thead>

                <tr>

                  <th>
                    <Checkbox
                      className="mr10"
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }
                      
                    }
                      checked= {isCheckedAll()}
                    />
                    <FormattedMessage id="serial.no" />
                    
                  </th>
                  {/* <th></th> */}
                  <th onClick={sortColumn}>
                    <FormattedMessage id="inventory.warehouse.stockAdjustment.listing.header.sku" /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="drawer.inventory.productName" /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn} className="text-center">
                    <FormattedMessage id="inventory.warehouse.stockAdjustment.listing.header.category" /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="drawer.inventory.purchasedPrice" /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="Wholesale Price" /> <i className={ICONS.SORT} />
                  </th>
                  <th onClick={sortColumn}>
                    <FormattedMessage id="drawer.inventory.retailPrice" /> <i className={ICONS.SORT} />
                  </th>
                  {/* <th>
                    <FormattedMessage id="common.listing.header.action" />
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {(products || []).length &&
                  products
                    // .sort((a, b) => (a.itemId < b.itemId ? 1 : -1))
                    .map((rowData, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              <span className="ml35">

                              {(pageNumber - 1) * pageSize + (index + 1)}
                              </span>
                              &nbsp;
                            </td>
                            {/* {(rowData.inventoryItemVariantList || [])
                                .length && rowData.inventoryItemVariantList ? (
                                isChildOn(rowData.itemId) ? (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setChildOff(rowData.itemId);
                                    }}
                                  >
                                  <img width={"15px"} height={"15px"} src={MinusIcon} alt="-"/>

                                  </span>
                                ) : (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setChildOn([
                                        ...childOnList,
                                        rowData.itemId,
                                      ]);
                                    }}
                                  >
                                  <img width={"15px"} height={"15px"} src={PlusIcon} alt="+"/>

                                  </span>
                                )
                              ) : (
                                ""
                              )} */}

                            {/* <td style={{width:"35%"}}>
                            <div style={{ display: "flex", alignItems: "center" }}>
             
                              {rowData.itemName}
                              </div>
                               </td> */}
                           
                           <td>{rowData.sku} </td>
                            <td>
                              {rowData.itemName}
                            </td>
                           
                            <td className="text-center">
                              {(rowData.inventoryItemCategoryRefs || [])
                                .length ? (
                                <div
                                  className="cursor-pointer"
                                  onClick={() => openClassDetailPopup(rowData)}
                                >
                                  {rowData.inventoryItemCategoryRefs.length}
                                </div>
                              ) : (
                                "0"
                              )}
                            </td>
                            <td>

                            </td>
                            <td></td>
                            <td></td>

                            {/* <td style={{ paddingLeft: "50px" }}>
                              {(!rowData.inventoryItemVariantList || [])
                                .length ? (
                                <DropdownAction
                                  overlay={actionMenu(rowData)}
                                  trigger={["click"]}
                                >
                                  <i className={ICONS.MORE} />
                                </DropdownAction>
                              ) : (
                                ""
                              )}
                            </td> */}
                          </tr>
                          {(rowData.inventoryItemVariantList || []) &&
                            (rowData.inventoryItemVariantList || []).length
                            ? (rowData.inventoryItemVariantList).map(
                              (childRowData, i) => {
                                const isChecked = find(selectedItems, selected => selected.variantId === childRowData?.variantId);

                                const isEditable = find(updatedPriceItems, selected => selected.variantId === childRowData?.variantId);
                                return (
                                  <tr key={childRowData.rfqChildMasterId} style={{height:"60px"}}>

                                    <td>

                                      <span>
                                        <Checkbox
                                          onChange={(e) => {
                                            itemSelection(childRowData);
                                          }}
                                      className="mr40"
                                          checked={isChecked ? true : false}
                                        />

                                      </span>{" "}
                                      <span >
                                        {/* {index + 1 + "." + Number(i + 1)} */}
                                        {(pageNumber - 1) * pageSize + (index + 1)}.{i + 1}

                                      </span>
                                      &nbsp;
                                    </td>
                                    <td>{childRowData.sku} </td>
                                    <td>
                                      {`${childRowData.attributeValue1 || "-"}
                                          ${childRowData.attributeValue2
                                          ? " | "
                                          : ""
                                        } 
                                          ${childRowData.attributeValue2 || ""
                                        }`}
                                    </td>
                                    <td className="text-center">
                                      {(
                                        childRowData.inventoryItemCategoryRefs ||
                                        []
                                      ).length || "0"}{" "}
                                    </td>
                                    <td className="input-box">
                                      {isChecked ? (
                                        <Input
                                          type="number"
                                          
                                          value={isEditable?.purchasedPrice !== undefined ? Number(isEditable.purchasedPrice || "0.00") : (String(childRowData.purchasedPrice.toFixed(2) || ""))}
                                          onChange={(e) => handleInputChange(e, childRowData?.variantId, 'purchasedPrice')}
                                        />
                                      ) : (
                                        childRowData.purchasedPrice ? childRowData.purchasedPrice.toFixed(2) : "0.00"
                                      )}

                                    </td>
                                    <td className="input-box">
                                      {isChecked ? (
                                        <Input
                                          type="number"
                                          value={isEditable?.wholesalePrice !== undefined ? Number(isEditable.wholesalePrice || "0.00") : (String(childRowData.wholesalePrice.toFixed(2) || ""))}
                                          
                                          onChange={(e) => handleInputChange(e, childRowData?.variantId, 'wholesalePrice')}
                                        />
                                      ) : (
                                        childRowData.wholesalePrice ? childRowData.wholesalePrice.toFixed(2) : "0.00"
                                      )}

                                    </td>
                                    <td className="input-box">
                                      {isChecked ? (
                                        <Input
                                          type="number"
                                          
                                          value={isEditable?.retailPrice !== undefined ? Number(isEditable.retailPrice || "0.00") : (String(childRowData.retailPrice.toFixed(2) || ""))}

                                          onChange={(e) => handleInputChange(e, childRowData?.variantId, 'retailPrice')}
                                        />
                                      ) : (
                                        childRowData.retailPrice ? childRowData.retailPrice.toFixed(2) : "0.00"
                                      )}

                                    </td>
                                    {/* <td className="pl10">
                                        <DropdownAction
                                          overlay={actionMenuChild(
                                            rowData,
                                            childRowData
                                          )}
                                          trigger={["click"]}
                                        >
                                          <i className={ICONS.MORE} />
                                        </DropdownAction>
                                      </td> */}
                                  </tr>
                                );
                              }
                            )
                            : ""}
                        </>
                      );
                    })}
              </tbody>
            </table>
          </Skeleton>

        </div>

        {/* <div className='agGridWrapper d-flex w-100 pt15'>
                        <div className="ag-theme-balham nested-table product-price-table" style={agGridStyle} >
                            <EditableContext.Provider value={props.form}>
                                <Table
                                    components={components}
                                    bordered
                                    columns={columns}
                                    rowClassName="editable-row custom-design"
                                    dataSource={products || []}
                                    pagination={false} />
                            </EditableContext.Provider>
                        </div>
                    </div> */}
        <Skeleton loading={props.headerLoading} paragraph={false} rows={1} active>
          <div className="footer">
            <div className="f-left"></div>
            <div className="f-right">
              <Pagination
                size="small"
                total={productCount}
                // current={props.pageNumber || 1}
                showTotal={showTotal}
                defaultPageSize={props.pageSize}
                showSizeChanger
                pageSizeOptions={[10, 25, 50, 100, 200]}
                // showQuickJumper
                onChange={(pageNumber, pageSize) => {
                  loadPageData(pageNumber, pageSize);
                  updateState({ pageSize: pageSize, pageNumber: pageNumber });
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                  handlePageSizeChange(pageNumber || 1, pageSize, props);
                }}
              />
            </div>
          </div>
        </Skeleton>
      </div>
    </>
  );
};

// const updateProduct = (props, data, record, cancelEditing) => {
//     let product = props.products[props.pageNumber].find(item => (item.itemId === data.itemId)) || {};
//     let variantList = [];
//     if (product.hasVariant) {
//         variantList = product.inventoryItemVariantList.map((variant, index) => {
//             if (variant.variantId === data.variantId) {
//                 variant.retailPrice = Number(record.retailPrice || 0);
//                 variant.purchasedPrice = Number(record.purchasedPrice || 0);
//                 variant.wholesalePrice = Number(record.wholesalePrice || 0);
//             }
//             return variant;
//         });
//     } else {
//         let variant = product.inventoryItemVariantList[0] || {};
//         variant.retailPrice = Number(record.retailPrice || 0);
//         variant.purchasedPrice = Number(record.purchasedPrice || 0);
//         variant.wholesalePrice = Number(record.wholesalePrice || 0);
//         variantList.push(variant);
//     }
//     product.inventoryItemVariantList = variantList || [];

//     const modalData = {
//         modalBody: <FormattedMessage
//             id='updateProduct'
//             defaultMessage=''
//         />,
//         handleSubmit: () => {
//             props.updateInventoryItem(product);
//             props.hideModal();
//             cancelEditing();
//         }
//     };
//     props.showModal(modalData);
// }

const updateProduct = (props, data, record, cancelEditing) => {
  let product =
    props.products[props.pageNumber].find(
      (item) => item.itemId === data.itemId
    ) || {};
  let payload = {
    retailPrice: Number(record.retailPrice || 0),
    purchasedPrice: Number(record.purchasedPrice || 0),
    wholesalePrice: Number(record.wholesalePrice || 0),
    itemId: data.itemId,
    itemName: data.itemNameWithVariant,
    updatedByEmpId: ((props.companyInfo || {}).relationshipEmployees || {})
      .relationshipEmployeeId,
    relationshipId: data.relationshipId,
  };
  if (data.variantId) {
    payload.variantId = data.variantId;
  } else {
    let variant = product.inventoryItemVariantList[0] || {};
    payload.variantId = variant.variantId;
  }

  const modalData = {
    modalBody: <FormattedMessage id="addUpdateData" defaultMessage="" />,
    handleSubmit: () => {
      props.updateInventoryItem(payload, props);
      props.hideModal();
      cancelEditing();
    },
  };
  props.showModal(modalData);
};
export default Form.create()(injectIntl(ProductPriceListingComp));
