import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, ENQUIRY_TYPE, TX_STATUS, INQUIRY_ACTION_LIST } from '../../../../static/constants';
import { getCurrentDateForBackend, formatDateForBackend, showToasterMessage, getFinancialYear } from '../../../../utils';
import { fetchSalesEnquiryCount, getSalesInquiryDraftCount } from '../Listing/action';
import * as filter from 'lodash.filter';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const createEnquiry = (payloadObject) => {
  
  const props = payloadObject.props;
  const saleEnquiry = _getEnquiryData(payloadObject);
  let formData = new FormData();
  if (props.fileList && props.fileList.length) {
    props.fileList.forEach((file)=>{
      formData.append('files', file, file.name);
    })
  }
  formData.append('saleEnquiry', JSON.stringify(saleEnquiry, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inquiry/createInquiry`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        dispatch({ type: INQUIRY_ACTION_LIST.NEXT_ENQUIRY_NUMBER_RESET });
        dispatch({ type: INQUIRY_ACTION_LIST.ENQUIRY_LIST_PAGE_RESET });
        dispatch({ type: INQUIRY_ACTION_LIST.INQUIRY_DRAFT_LIST_PAGE_RESET });
        fetchSalesEnquiryCount({ relationshipId: props.companyInfo.relationshipId,isActive:1 })(dispatch);
        getSalesInquiryDraftCount({ relationshipId: props.companyInfo.relationshipId,isActive:1 })(dispatch);
        props.fetchSalesEnquiry({...props,isActive:1});
        props.fetchSalesInquiryDrafts({...props,isActive:1});

        props.popModalFromStack();
        if(props?.inquiryMasterId){
          showToasterMessage({ messageType: 'success', description: 'Sales Inquiry  Edited Successfully' });
        }
        else{
          showToasterMessage({ messageType: 'success', description: 'Sales inquiry created successfully' });
        }
       
        props.openInquiryDetails(res.data);
        props.history.push('/admin/sales-inquiry-list');
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })

      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

const _getEnquiryData = (payload) => {
  
  const props = payload.props;
  const gridObject = payload.gridObject;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(props.companyInfo);
  const oldPayload = props.update ? props.oldPayload || {} : {};
  let enquiryMaster = {
    ...oldPayload,
    version: props.update ? props.version : 0,
    inquiryDraftId: props.inquiryDraftId,
    inquiryMasterId: props.inquiryMasterId,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    relationshipName: companyInfo && companyInfo.storeName,
    relationshipId: companyInfo.relationshipId,
    inquiryDate: props.enquiryDate && formatDateForBackend(props.enquiryDate),
    inquiryExpirationDate: props.expiryDate && formatDateForBackend(props.expiryDate),
    status: TX_STATUS.OPEN,
    docDetailList: props.inquiryData?.docDetailList,
    txType: ENQUIRY_TYPE.STANDARD,
    remarksInternal: props.internalRemarks,
    remarksCustomer: props.customerRemarks,
    footer: props.footer,
    //requestingDepartment: props.departmentName,
    //prRequestedBy: props.prRequestedBy,
    //purchaseType: TX_TYPE.PO,
    projectNumber: props.projectNumber,
    projectName: props.projectName,
    projectMasterId: props.projectMasterId,
    priority: props.priority,
    boLocationInquiryList: [],
    customerId: (props.customer || {}).customerId,
    customerName: props.customerName,
    createdByUserId: userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId, 
    inquiryDetailsList: [],
    documentName: props.docName,
    customerInquiryNumber: props.customerInquiryNumber || '',
    refNumber: props.refNumber,
    salesPersonId: props.salesPersonId,
    salesPersonFullName: props.salesPersonName,
    currencyCode: companyInfo.currencyCode,
    isActive:1
  };

  if (props.relationshipAddress && props.relationshipAddress.streetAddress1) {
    if(!props.update){ delete props.relationshipAddress.locationInquiryId;
      //props.relationshipAddress.dateCreated = getCurrentDateForBackend(new Date());
      //props.relationshipAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
     // props.relationshipAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    enquiryMaster.boLocationInquiryList.push(props.relationshipAddress);
  }

  if (props.customerBillingAddress && props.customerBillingAddress.streetAddress1) {
    if(!props.update) {delete props.customerBillingAddress.locationInquiryId;
     // props.customerBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
     // props.customerBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      //props.customerBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    enquiryMaster.boLocationInquiryList.push(props.customerBillingAddress);
  }

  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
   // if (!itemId) return;
   let variantId = data.selectedProductValue && data.selectedProductValue.length === 2 ? data.selectedProductValue[1] : 0;
   if(data.productObj && !data.productObj.hasVariant && !variantId){
     variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId: data.inventoryItemVariantId;
   }
   
    let product = {};
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }
    if(!data.itemId && !data.description && !data.qty && !data.rate){
      return
    }
    enquiryMaster.inquiryDetailsList.push({
      rfqDetailsId: data.rfqDetailsId,
      version: props.update ? data.version : 0,
      relationshipId: companyInfo && companyInfo.relationshipId,
      quantity: Number(data.qty),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      txDate: formatDateForBackend(props.invoiceDate),
      itemName: product.itemName,
      itemId: product.itemId,
      description: data.description,
      rowHeight: data.scrollHeight,
      hsnCode: data.hsnCode,
      itemVariantId: variantId,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      inquiryDetailsId: data.inquiryDetailsId
    });
  });

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    // additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.customer) {
    additionalInfo.customerId = props.customer && props.customer.customerId;
    additionalInfo.customerPhone = props.customer && props.customer.customerPhone;
    additionalInfo.customerEmail = props.customer && props.customer.customerEmail;
    // additionalInfo.gstNumber = props.customer.gstNumber;
    // additionalInfo.panNumber = props.customer.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
  }
  if (props.selectedCustomerTaxIdentifications && props.selectedCustomerTaxIdentifications.length) {
    let selectedCustomerTaxIdentifications = filter(props.customer.customerTaxIdentificationsList, (taxObj) => {
      return props.selectedCustomerTaxIdentifications.indexOf(taxObj.customerTaxIdentificationId) > -1;
    });
    additionalInfo.taxIdentifications = JSON.stringify(selectedCustomerTaxIdentifications);
  }else{
     additionalInfo.taxIdentifications = '[]'
  }
  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }else {
     additionalInfo.relationshipTaxIdentifications = '[]'
    
  }
  if(!props.update) delete additionalInfo.additionalInfoId;
  enquiryMaster.additionalInfoList = [additionalInfo];
  return enquiryMaster;
}



export const getNextTxNumber = (payload) => {
    let date = payload.enquiryDate ? getCurrentDateForBackend(payload.enquiryDate) : getCurrentDateForBackend(new Date());
    return dispatch => {
      dispatch({ type: INQUIRY_ACTION_LIST.NEXT_ENQUIRY_NUMBER});
      dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inquiry/getNextInquiryNumber?inquiryDate=${date}&relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: INQUIRY_ACTION_LIST.NEXT_ENQUIRY_NUMBER, data: res.data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          } else {
            err.actionToCall = INQUIRY_ACTION_LIST.NEXT_ENQUIRY_NUMBER;
          }
        }).finally(() => {
          dispatch({
            type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
          });
        })
    }
  }

  export const fetchInquiryDetail = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
      let url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inquiry/getInquiryByInquiryId?inquiryMasterId=${payload.inquiryMasterId}&relationshipId=${payload.relationshipId}`;
        return axios
          .get(url)
          .then(res => {
            const { data } = res
            dispatch({ type: INQUIRY_ACTION_LIST.ENQUIRY_DETAIL, data });
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          })
          .catch((err) => {
            ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            if(!err.__isRetryRequest){
              showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
            }else{
              err.actionToCall = INQUIRY_ACTION_LIST.ENQUIRY_DETAIL;
            }
          }).finally(() => {
            dispatch({
              type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
            });
          })
      }
  }
  
  export const resetEnquiryDetail = () => {
    return dispatch => {
      dispatch({ type: INQUIRY_ACTION_LIST.RESET_ENQUIRY_DETAIL });
    }
  }


export const createEnquiryDraft = (payloadObject) => {
  const props = payloadObject.props;
  return dispatch => {
    const inquiryData = _getEnquiryData(payloadObject);
    const data = {
      inquiryDraftId: props.inquiryDraftId || 0,
      inquiryData: JSON.stringify(inquiryData),
      relationshipId: inquiryData.relationshipId,
      customerId: inquiryData.customerId,
      version: inquiryData.version || 0,
    }

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inquiry/saveOrUpdateDraft`, data)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Inquiry saved in draft' })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.popModalFromStack();
        props.fetchSalesInquiryDrafts(payloadObject);
        props.history.push({
          pathname: '/admin/sales-inquiry-list',
          state: { activeTab: 'Draft' }
        })
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }
      })
  }
}