import React, { Component, Fragment } from "react";
// import { Tooltip } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
// import { getMomentDateForUIReadOnly } from "../../../utils";
// import { CONSTANTS } from '../../../static/constants';
import PageBreadcrumb from "../../PageBreadcrumb";
// import RegistrationFormSetting from '../../../containers/modal/modalBody/membership/RegistrationFormSetting';
// import ShareRegistrationFormCompo from '../../../components/modal/modalBody/settings/ShareRegistrationForm';
import { PhoneTwoTone, MailTwoTone } from '@ant-design/icons';
import './index.scss';

class OrgMembershipComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }
  render() {    

    const deleteMembership = (payload) => {
      const modalBody =
        <FormattedMessage id='common.delete.confirmation' />;
      const modalData = {
        modalBody,
        handleSubmit: () => {
          this.props.deleteOrgMembership({
            orgMembershipId: payload.orgMembershipId,
            relationshipId: payload.relationshipId,
            customerId: payload.customerId
          }, this.props);
          this.props.hideModal();
        },
      };
      this.props.showModal(modalData);
    }
    const breadCrumbList = [
      {
        name: <FormattedMessage id='sidebar.menuItem.membership' defaultMessage='' />
      }, {
        name: <FormattedMessage id='sidebar.menuItem.orgMembership' defaultMessage='' />,
      }
    ];
    return (
      <Fragment>
        <div className="pb10">
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="view-container sales-order-details mt0">
          <div className='team-and-free'>
            <div className="members-list">
              <table style={{ width: "100%" }}>
                <thead>
                    <tr>
                      <th><FormattedMessage id='membership.orgMembership.listing.name' defaultMessage='' /></th>
                      <th><FormattedMessage id='membership.orgMembership.listing.address' defaultMessage='' /></th>
                      <th><FormattedMessage id='membership.orgMembership.listing.members' defaultMessage='' /></th>
                      <th><FormattedMessage id='membership.orgMembership.listing.membershipLevel' defaultMessage='' /></th>
                      <th><FormattedMessage id='membership.orgMembership.listing.action' defaultMessage='' /></th>
                    </tr>
                </thead>
                <tbody>
                  {this.props.orgMembershipList && this.props.orgMembershipList[this.props.pageNumber] ? 
                  this.props.orgMembershipList[this.props.pageNumber].map((member, i)=>{
                    return <tr>
                        <td key={i}>
                          <p>{member.firstName} {member.lastName} </p>
                          <p><PhoneTwoTone/> {member.mobile || '-'} </p>
                          <p><MailTwoTone/> {member.email || '-'}</p>
                        </td>
                        <td>
                          <p> {member.streetAddress1 || ''}, {member.streetAddress2 || ''} </p>
                          <p> {member.cityName || ''}, {member.zipCode || ''} </p>
                          <p> {member.stateName || ''}, {member.countryName || ''} </p>
                        </td>
                        <td style={{width : '20%'}}>
                          {(member.orgMembershipDetailsList || []).map(member=> {return `${member.firstName} ${member.lastName}`}).join(',')}
                        </td>
                        <td>{member.membershipLevel || ''}</td>
                        <td>
                          {/* <span style={{ fontSize: '15px', marginLeft: '10px', cursor: 'pointer'}} onClick= {()=>} >
                            <i className='fa fa-pencil'  title={ this.props.intl.formatMessage({ id: 'commons.listing.edit.tooltip.msg' })}/>
                          </span> */}
                          <span style={{ fontSize: '15px', marginLeft: '10px', cursor: 'pointer' }} onClick= {()=>deleteMembership(member)} >
                            <i className="fa fa-trash" title={this.props.intl.formatMessage({ id: 'delete.text' })} />
                          </span>
                        </td>
                      </tr>
                    })
                     : ''
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
};

export default injectIntl(OrgMembershipComp);
