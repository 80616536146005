import React, { Fragment,  useState } from "react";
import {  FormattedMessage } from "react-intl";

import { TextBox } from "../../general/TextBox";

import { ErrorMsg } from "../../general/ErrorMessage";




import "@ant-design/compatible/assets/index.css";


import {
  Drawer,
  Button,
  Col,
  Row,
 

  DatePicker
} from "antd";
import {
 
 
  getMomentDateForUI,
 
} from "../../../utils";
import { Dropdown } from "../../general/Dropdown";
import find from "lodash.find";







const EwayBill = (props) => {
    const closeDrawer = () => {
        props.updateDrawerState({
            eWayBillDrawerVisible: false
        })
      }

      const [vechileVal ,setVechileVal] = useState(false)
      const vechRegex =  /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/;
       const format = "DD-MM-YYYY";;
       const vechileType = [{vehType :"Regular" ,referCode:"R"},{vehType:"Over Dimensional Cargo" ,referCode:"O"}]
      const getCreateConfirmationModalBody = (payload) => {
        return (
          <Fragment>
            <p>
              <FormattedMessage
                id="Are you sure you want to create an e-Way Bill?"
                defaultMessage=""
              />
            </p>
          </Fragment>
        );
      };

      const validateTab = (payload) =>{
        let isOK = true;
          if(props.transportName && props.transportDist && props.vechileNo && props.transportMode && props.transportdocNo && props.transporterdocDate && props.vechileNo && props.irnNo && props.disAddress1 && props.disLocation && props.disStateCode && props.disPincode && props.pAddress1 && props.placeLocation && props.stateCode && props.pincode){
            isOK = false;
          }

          return isOK;
      }
      const handleSubmit = (payload)=>{
       
        if (!payload.submittedOnce) {
            payload.updateState({ submittedOnce: true });
          }
          
          if(!validateTab(payload)){
            const modalData = {
              title: (
                <FormattedMessage
                  id="drawer.inventory.confirmation.title"
                  defaultMessage=""
                />
              ),
              modalBody: getCreateConfirmationModalBody(payload),
              handleSubmit: () => {
                props.generateEWayBillDeatils(payload)
                payload.hideModal();
              },
            };
            payload.showModal(modalData);
          }
          else{
                                        
          }
          
          
        
        
      }
     
      return (
        <Fragment>
           
          <Drawer
             title={
                
              <FormattedMessage id='modal.txDetails.common.eWayBill' defaultMessage='' /> 
                
              }
              width={720}
              destroyOnClose={true}
              onClose={closeDrawer}
              keyboard={false}
              maskClosable={false}
              className="custom-drawer"
              afterVisibleChange={() => {
                // props.initCalls();
              }}
              visible={props.eWayBillDrawerVisible}
              footer={
                <>
        
        
        
                 
                    <Button
        
                      onClick={() => {
                        const modalData = {
                          modalBody: props.intl.formatMessage({
                            id: "close.confirmation",
                          }),
                          footer: {
                            cancel: { intlId: "no" },
                            submit: { intlId: "yes" },
                          },
                          handleSubmit: () => {
                            // props.popModalFromStack();
                            closeDrawer();
                          },
                        };
                        props.pushModalToStack(modalData);
                      }}
                    >
                      Cancel
                    </Button>
                 
        
               
                    <Button
                      type="primary"
                      onClick={() => {
                        handleSubmit(props);
                      }}
                    >
                      <FormattedMessage
                        id="button.save.label"
                        defaultMessage=""
                      />
                    </Button>
                 
        
        
        
                </>
              }
          >
           {/* <Skeleton loading={props.drawerLoading} paragraph={{ rows: 18 }} active> */}
           <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id={`My GSTIN (${props.companyInfo?.storeName})`} defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Mode of Transportation"
                      defaultMessage=""
                    />
                     <span className="required">*</span>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                      maxLength={20}
                      placeholder={"User GSTIN"}
                      type="text"
                      value={props.userGstin}
                      onChange={(e) => {
                        props.updateState({
                            userGstin: e.target.value,
                        });
                      }}
                    />
                    <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.userGstin;
                }}
                message={"User Gstin is required"}
              />
                  </Col>

                  <Col span={12}>
                  <Dropdown
                // style={{ width: "210px" }}
                items={ ["Road" ,"Rail" ,"Air" ,"Ship"]}
                // valueKeyName="docType"
                // optionKeyName="referCode"
                value={props.transportMode}
                placeholder={"Transport Mode"}
                onSelect={(val) => {
                 
                  props.updateState({
                    transportMode: val,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
                  </Col>
                  <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.transportMode;
                }}
                message={"Mode of Transportation is required"}
              />
                </Row>
              </div>
          
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Vechile Type" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Distance of transportation (in Kms)"
                      defaultMessage=""
                    />
                     <span className="required">*</span>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                  <Dropdown
                // style={{ width: "210px" }}
                items={ vechileType ||[]}
                valueKeyName="vehType"  
                optionKeyName="referCode"
                value={(props.vechileType || {}).vehType}
                placeholder={"Vechile Type"}
                onSelect={(referCode) => {
                  const doc = find(vechileType, { referCode: referCode }) || {};
                  props.updateState({
                    vechileType: doc,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.vechileType;
                }}
                message={"Vechile Type is required"}
              />
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"Transport Distance"}
                      type="text"
                      maxLength={20}
                      value={props.transportDist}
                      onChange={(e) => {
                        props.updateState({
                            transportDist: e.target.value,
                        });
                      }}
                    />
                    <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.transportDist;
                }}
                message={"Distance of transportation is required"}
              />
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Transporter Name" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Transporter Docuemnt No."
                      defaultMessage=""
                    />
                     <span className="required">*</span>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                      maxLength={20}
                      placeholder={"Transporter Name"}
                      type="text"
                      value={props.transportName}
                      onChange={(e) => {
                        props.updateState({
                            transportName: e.target.value,
                        });
                      }}
                    />
                    <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.transportName;
                }}
                message={"Transporter Name is required"}
              />
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"Document Number"}
                      type="text"
                      maxLength={15}
                      value={props.transportdocNo}
                      onChange={(e) => {
                        props.updateState({
                            transportdocNo: e.target.value,
                        });
                      }}
                    />
                    <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.transportdocNo;
                }}
                message={"Document Number is required"}
              />
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Transporter Document Date" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Vechile No."
                      defaultMessage=""
                    />
                     <span className="required">*</span>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                  <DatePicker
                getPopupContainer={(trigger) => trigger.parentNode}
                format={format}
                key={`${
                  props.transporterdocDate
                    ? getMomentDateForUI({
                        date: new Date(props.transporterdocDate),
                        format,
                      })
                    : null
                }`}
                defaultValue={
                  props.transporterdocDate
                    ? getMomentDateForUI({
                        date: new Date(props.transporterdocDate),
                        format,
                      })
                    : null
                }
               
                onChange={(selectedDate) => {
               
                  props.updateState({
                    transporterdocDate: getMomentDateForUI({date: selectedDate , format:format}),
                  });
                }}
                placeholder={"Select Document Date"}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.transporterdocDate;
                }}
                message={"Document Date is required"}
              />
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"Vechile No."}
                      type="text"
                      maxLength={10}
                      value={props.vechileNo}
                      onChange={(e) => {
                        setVechileVal(vechRegex.test(e.target.value));
                       
                        props.updateState({
                            vechileNo: e.target.value,
                        });
                      }}
                    />
                    {/* {
                     (props.vechileNo && vechileVal)  ?"" : <span style={{color:"red"}}>Invalid Vechile No.</span>
                    } */}
                    <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.vechileNo ;
                }}
                message={"Vechile Number is required"}
              />
                {/* <ErrorMsg
                validator={() => {
                  return (!!props.vechileNo && vechileVal);
                }}
                message={"Invalid Vechile No."}
              /> */}
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Transporter ID" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Place of Business"
                      defaultMessage=""
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                      maxLength={20}
                      placeholder={"Transporter ID"}
                      type="text"
                      value={props.transportId}
                      onChange={(e) => {
                        props.updateState({
                            transportId: e.target.value,
                        });
                      }}
                    />
                    <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.transportId;
                }}
                message={"Transporter ID is required"}
              />
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"Place of Business EWB"}
                      type="text"
                      maxLength={20}
                      value={props.placeofBusinessEWB}
                      onChange={(e) => {
                        props.updateState({
                            placeofBusinessEWB: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Sub types of Supply" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Other Sub Supply Description"
                      defaultMessage=""
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                  <Dropdown
                // style={{ width: "210px" }}
                items={ ["Supply","Import","Export","Job Work","For Own Use","Job work Returns","Sales Return","SKD/CKD","Line Sales","Recipient Not Known","Exhibition or Fair"]}
                // valueKeyName="vehType"  
                // optionKeyName="referCode"
                value={props.subSupplyType}
                placeholder={"Supply Type"}
                onSelect={(val) => {
                  
                  props.updateState({
                    vechileType: val,
                  });
                }}
                // allowClear={true}
                // onClear={() => {
                //     this.props.updateState({
                //         supplierObj: undefined,
                //         supplierName: undefined,
                //     });
                // }}
              />
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"Other Sub Supply Description"}
                      type="text"
                      maxLength={20}
                      value={props.subSupplyDes}
                      onChange={(e) => {
                        props.updateState({
                          subSupplyDes: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Invoice Reference Number" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                    
                      placeholder={"Enter IRN No."}
                      type="text"
                      value={props.irnNo}
                      onChange={(e) => {
                        props.updateState({
                          irnNo: e.target.value,
                        });
                      }}
                    />
                    <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.irnNo;
                }}
                message={"Invoice Refrence No. is required"}
              />
                  </Col>

               
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Dispatch Trade Name" defaultMessage="" />
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Dispatch Address 1"
                      defaultMessage=""
                    />
                     <span className="required">*</span>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                      maxLength={20}
                      placeholder={"Trader Name"}
                      type="text"
                      value={props.dispatchTraderName}
                      onChange={(e) => {
                        props.updateState({
                          dispatchTraderName: e.target.value,
                        });
                      }}
                    />
                    
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"Dispatch Address 1"}
                      type="text"
                      maxLength={20}
                      value={props.disAddress1}
                      onChange={(e) => {
                        props.updateState({
                          disAddress1: e.target.value,
                        });
                      }}
                    />
                     <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.disAddress1;
                }}
                message={"Dispatch Address is required"}
              />
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Dispatch Address 2" defaultMessage="" />
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Dispatch Location"
                      defaultMessage=""
                    />
                     <span className="required">*</span>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                      maxLength={20}
                      placeholder={"Dispatch Address 2"}
                      type="text"
                      value={props.disAddress2}
                      onChange={(e) => {
                        props.updateState({
                          disAddress2: e.target.value,
                        });
                      }}
                    />
                   
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"Dispatch Location"}
                      type="text"
                      maxLength={20}
                      value={props.disLocation}
                      onChange={(e) => {
                        props.updateState({
                          disLocation: e.target.value,
                        });
                      }}
                    />
                     <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.disLocation;
                }}
                message={"Location is required"}
              />
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Dispatch State Code" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Dispatch Pincode"
                      defaultMessage=""
                    />
                     <span className="required">*</span>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                      maxLength={20}
                      placeholder={"Dispatch State"}
                      type="text"
                      value={props.disStateCode}
                      onChange={(e) => {
                        props.updateState({
                          disStateCode: e.target.value,
                        });
                      }}
                    />
                     <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.disStateCode;
                }}
                message={"State Name is required"}
              />
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"Dispatch Pincode"}
                      type="text"
                      maxLength={20}
                      value={props.disPincode}
                      onChange={(e) => {
                        props.updateState({
                          disPincode: e.target.value,
                        });
                      }}
                    />
                     <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.disPincode;
                }}
                message={"Pincode is required"}
              />
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Shipping Address" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Shipping Address 2"
                      defaultMessage=""
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                      maxLength={20}
                      placeholder={"Shipping Address"}
                      type="text"
                      value={props.pAddress1}
                      onChange={(e) => {
                        props.updateState({
                          pAddress1: e.target.value,
                        });
                      }}
                    />
                     <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.pAddress1;
                }}
                message={"Shipping Address is required"}
              />
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"Shipping Address 2"}
                      type="text"
                      maxLength={20}
                      value={props.pAddress2}
                      onChange={(e) => {
                        props.updateState({
                          pAddress2: e.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Shipping Location" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  <Col span={12} className="drawer-label">
                    <FormattedMessage
                      id="Shipping stateCode"
                      defaultMessage=""
                    />
                     <span className="required">*</span>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                      maxLength={20}
                      placeholder={"location"}
                      type="text"
                      value={props.placeLocation}
                      onChange={(e) => {
                        props.updateState({
                          placeLocation: e.target.value,
                        });
                      }}
                    />
 <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.placeLocation;
                }}
                message={"Shipping Location is required"}
              />
                  </Col>

                  <Col span={12}>
                    <TextBox
                      placeholder={"State Code"}
                      type="text"
                      maxLength={20}
                      value={props.stateCode}
                      onChange={(e) => {
                        props.updateState({
                          stateCode: e.target.value,
                        });
                      }}
                    />
                     <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.stateCode;
                }}
                message={"State Code is required"}
              />
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[16, 8]}>
                  <Col span={12} className="drawer-label">
                    <FormattedMessage id="Shipping Pincode" defaultMessage="" />
                    <span className="required">*</span>
                  </Col>

                  
                </Row>

                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <TextBox
                      maxLength={20}
                      placeholder={"Pincode"}
                      type="text"
                      value={props.pincode}
                      onChange={(e) => {
                        props.updateState({
                          pincode: e.target.value,
                        });
                      }}
                    />
                  </Col>
                  <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.pincode;
                }}
                message={"Pincode is required"}
              />
                 
                </Row>
              </div>
             
           {/* </Skeleton> */}
         
    
          </Drawer>
          
        </Fragment>
      );
}

export default EwayBill