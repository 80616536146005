import React, { Component } from 'react';
import { connect } from 'react-redux';
import PreferencesComp from '../../../../../components/modal/modalBody/eStore/Preferences';
import { bindActionCreators } from 'redux';
import { fetchE_StoreData ,getAllPaymentGatewayConfig} from '../../../../../actions/commonActions';

import { updateAdditionalValue } from '../Checkout/action';
import { setOnlineOrder ,setMakeAnEnquiry,setCODorder } from './action';
class Preferences extends Component {
    constructor(props){
        super(props);
        this.state= {
            
            additionalValue :{},
                isallowCancelOrder: false,
                loading:false,
                preferences:false,
                checkoutBillingFormValues :[],
                checkoutShippingFormValues: [],
                customerSupport:{},
        }
    }
   

    
       
    
     
    componentWillMount() {
       
        const companyInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            
        };

        
        
        this.props.fetchE_StoreData(payload);
       this.props.getAllPaymentGatewayConfig(payload);
    }
    

    componentWillReceiveProps(props){
        if(!this.state.loading && Object.keys(JSON.parse(props.storeData.additionalValues || "{}")) ){
          
         
           
          const val = JSON.parse(props.storeData.additionalValues || "{}")
                
          this.setState({
            additionalValue: val,
            isallowCancelOrder: val?.isallowCancelOrder,
        })
              
          
  
            
         
        }
    
      }
    
    render() {
        return <div>
            <PreferencesComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        remarksData: state.settings.remarksData,
        userInfo: state.common.userInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        storeData:state.common.store_values,
        paymentGatewayName: state.common.allPaymentGatewayConfig,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
   setCODorder,
   setMakeAnEnquiry,
   updateAdditionalValue,
   setOnlineOrder,
   fetchE_StoreData,
   getAllPaymentGatewayConfig,
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Preferences);