import React, { Component } from 'react';
import { CustomAgGrid } from '../../../../general/CustomAgGrid';
import { FormattedMessage } from 'react-intl';
import RegistrationFormSetting from '../../../../../containers/modal/modalBody/settings/RegistrationFormSetting';
import { CONSTANTS } from '../../../../../static/constants';
import { Row, Col, Button } from 'antd';
import { getMomentDateForUIReadOnly } from '../../../../../utils';
import ShareRegistrationFormCompo from '../ShareRegistrationForm';

class RegistrationFormSettingComponent extends Component {
    render() {
        const format = CONSTANTS.DISPLAY_DATE_FORMAT;
        const columnDefs = [
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='setting.student.registration.list.formName' defaultMessage='' />;
                },
                field: 'programName'
            },
            {
                headerComponentFramework: () => {
                    return <FormattedMessage id='setting.student.registration.list.date' defaultMessage='' />;
                },
                cellRendererFramework: (link) => {
                    let d = JSON.parse(link.data.formSetting)
                    return <div>{d?.programDate ? getMomentDateForUIReadOnly({ date: d.programDate, format }) : ''}</div>
                },
            },
            {
                headerComponentFramework: () => {
                    return <div>
                        Action
                </div>;
                },
                field: 'action',
                cellRendererFramework: (params) => {

                    return <div>
                        <span className="cursor-pointer" title='Edit' onClick={
                            () => {
                                const modalData = {
                                     title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                                    modalBody: <RegistrationFormSetting {...this.props} registrationFormSettingId={params.data.registrationFormSettingId} style={{ overflow: 'auto' }} />,
                                    width: '100%',
                                    hideFooter: true,
                                    maskClosable: true,
                                    hideCloseIcon: true,
                                    hideTitle: true,
                                    wrapClassName: "modal-custom-registration"
                                };
                                this.props.pushModalToStack(modalData);
                            }}>
                            <i className='fa fa-pencil' />
                        </span>
                        {!params.data.isAccountHolder ?
                            <span style={{ marginLeft: '10px' }} title='Delete' className="cursor-pointer" onClick={
                                () => {
                                    const modalData = {
                                        modalBody: <FormattedMessage
                                            id='registration.form.delete.confirmation'
                                            defaultMessage=''
                                        />,
                                        handleSubmit: () => {
                                            this.props.deleteRegistrationForm({
                                                relationshipId: this.props.relationshipId,
                                                registrationFormSettingId: params.data.registrationFormSettingId

                                            }, this.props);
                                            //deleteContact(this.props, this.gridApi.getDisplayedRowAtIndex(params.node.rowIndex).data)
                                        }
                                };
                                    this.props.pushModalToStack(modalData);
                                }}>
                                <i className='fa fa-trash' />
                            </span> : ''
                }
                
                            <span style={{ marginLeft: '10px' }} className="cursor-pointer" title='Share' onClick={
                                () => {
                                    this.props.pushModalToStack({
                                        title: <div>
                                            <FormattedMessage id='shareRegistrationForm.text' defaultMessage='' />
                                            <div style={{ 'fontSize': '12px' }}> {params.data.programName || ''}</div>
                                        </div>,
                                        modalBody: <ShareRegistrationFormCompo {...this.props} formSettingData={params.data}/>,
                                        width: '50%',
                                        hideFooter: true,
                                    })
                                }}>
                                <i className='fa fa-share' />
                            </span>
                    </div >
                },
    width: 52
}
        ];

const agGridStyle = {
    height: '100%',
    width: '100%'
};
return (
    <div className="ag-theme-balham" style={agGridStyle} >
        <div>
            <CustomAgGrid
                columnDefs={columnDefs}
                rowData={this.props.registrationFormList || []}
                rowDragManaged={true}
                onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                }}
                domLayout={"autoHeight"}
                animateRows={true}
            >
            </CustomAgGrid>
        </div>
        <br />
        <div className="">
            <Row type="flex" className="action-button-group">
                <Col span={3}>
                    <Button className="ant-btn-primary" onClick={() => {
                        const modalData = {
                            // title: <div className='ant-modal-title'><FormattedMessage id='setting.student.registration.form.label' defaultMessage='' /></div>,
                            modalBody: <RegistrationFormSetting style={{ overflow: 'auto' }} />,
                            width: '100%',
                            hideFooter: true,
                            maskClosable: true,
                            hideCloseIcon: true,
                            hideTitle: true,
                            wrapClassName: "modal-custom-registration"
                        };
                        this.props.pushModalToStack(modalData);
                    }}><FormattedMessage id='button.registerForm.createNewForm' defaultMessage='' /></Button>
                </Col>

                <Col span={2} offset={19}>
                    <Button className="grey-button" onClick={() => {
                        this.props.hideModal()
                    }}><FormattedMessage id='button.cancel.label' defaultMessage='' /></Button>
                </Col>
            </Row>
        </div>
    </div>
)
    }
}

export default RegistrationFormSettingComponent;
