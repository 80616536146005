import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "antd";
import * as find from "lodash.find";
import ReactHtmlParser from "react-html-parser";
import config from "../../../../../config/environmentConfig";
import { CONSTANTS, MODAL_TYPE } from "../../../../../static/constants";
import {
  base64Toblob,
  getAWSFileURL,
  getMomentDateForUIReadOnly,
  fixedDecimalNumber,
} from "../../../../../utils";
import { ImageCropper } from "../../../../../components/general/ImageCropper";
import { CustomUploader } from "../../../../../components/general/CustomUploader";
import { getLabelName } from "../../../../../components/modal/modalBody/customerInfo";
import { LogoComponent } from "../../../../general/LogoComponent";
import { AWSFileTable } from "../../../../general/AWSFileTable";

const RFQDetails = (props) => {
  const { rfqData, companyInfo } = props;
  const { rfqDetailsList } = rfqData;
  const relationshipAddress =
    find(rfqData.boLocationRFQList, {
      locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
    }) || {};
  let isColumnEmpty = {
    product: true,
    service: true,
    variant: true,
    description: true,
    qty: true,
    uomName: true,
    rate: true,
    hsn: true,
    discount: true,
    tax: true,
    specialDiscount: true,
    documentDetails: true,
  };
  if (rfqData) {
    isColumnEmpty = updateColumnEmptyCheck(isColumnEmpty, rfqData);
  }

  const customProps = {
    type: CONSTANTS.TYPE_IMAGE,
    imgUrl:
      getAWSFileURL((rfqData || {}).stampAwsKey, config.BUCKET_NAME.BO_RFQ) ||
      "",
    isSignature: true,
    height: 60,
    width: 60,
    name: "file",
    action: null,
    buttonName: "Upload Customer PO",
    onChange: (file) => {
      if (file[0]?.originFileObj) {
        var reader = new FileReader();

        reader.onload = function (e) {
          props.showModal({
            title: (
              <Fragment>
                <div className="ant-modal-title">Crop Image</div>
              </Fragment>
            ),
            //modalBody: <ImageCropper cropperRef={cropperRef} onClick={(e) => {
            modalBody: (
              <ImageCropper
                onClick={(e) => {}}
                handleSubmit={(newUrl) => {
                  props.uploadStamp({
                    supplierId: rfqData.supplierId,
                    rfqMasterId: rfqData.rfqMasterId,
                    rid: rfqData.relationshipId,
                    objectKey: rfqData.stampAwsKey,
                    bucketName: config.BUCKET_NAME.BO_RELATIONSHIP,
                    file: base64Toblob(newUrl),
                  });
                }}
                {...props}
                url={e.target.result}
              />
            ),
            width: 800,
            hideFooter: true,
            wrapClassName: "Upload-profile-modal",
          });
        };

        reader.readAsDataURL(file[0]?.originFileObj); // convert to base64 string
      }
    },
  };

  const updateStatusConfirmationModal = (props, payload, modalType) => {
    const labelName = "New Status";
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${payload.status} to ${payload.text}` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    const modalData = {
      modalBody,
      handleSubmit: (formData) => {
        const statusObj =
          find(props.rfqStatusList, { rfqStatusId: payload.statusId }) || {};
        props.updateRfqStatus(
          {
            status: formData.textBoxValue,
            relationshipId: props.companyInfo.relationshipId,
            customerId: payload.customerId,
            rfqChildMasterId: payload.rfqChildMasterId,
            rfqMasterId: payload.rfqMasterId,
            statusId: payload.statusId,
            statusColor: statusObj.statusColor,
          },
          props
        );
        props.hideModal();
      },
    };
    props.showModal(modalData);
  };

  const addItemConfirmationModal = (props, text, modalType, payloadObj) => {
    const { showModal, companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage={``}
        />
        {labelName} ?
      </span>
    );

    let addFunc;
    let payload = { text: text, relationshipId: companyInfo.relationshipId };

    switch (modalType) {
      case MODAL_TYPE.RFQ_STATUS: {
        addFunc = props.addRFQStatus;
        payload.statusColor = " new";
        break;
      }
      case MODAL_TYPE.RFQ_PRICE_CODES: {
        addFunc = props.addRFQPriceCodes;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: (data) => {
        addFunc(payload, props);
        props.hideModal();
        payloadObj.formData.status = payload.text;
        handleUpdateStatus(
          props,
          payloadObj.formData,
          MODAL_TYPE.UPDATE_STATUS
        );
      },
    };
    showModal(modalData);
  };

  const handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    const { showModal, hideModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(
        props,
        formData.textBoxValue,
        modalType,
        payload
      );
    } else {
      const data = {
        title: (
          <FormattedMessage id="addItem.text.rfqStatus" defaultMessage="" />
        ),
        formData,
        isStack: true,
        onCancel: (type) => {
          if (type !== "update_status") {
            handleUpdateStatus(
              props,
              payload.formData,
              MODAL_TYPE.UPDATE_STATUS
            );
          } else {
            hideModal();
          }
        },
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType,
              payload
            );
          } else {
            data.formData.submittedOnce = true;
            showModal(data);
          }
        },
      };
      showModal(data);
    }
  };

  const deleteClickHandler = (props, modalType, payload) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage={``}
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.RFQ_STATUS: {
        deleteFunc = props.deleteRFQStatus;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc(
          { id: payload.id, relationshipId: companyInfo.relationshipId },
          props
        );
        props.hideModal();
        payload.formData = {};
        handleUpdateStatus(props, payload.formData, MODAL_TYPE.UPDATE_STATUS);
      },
    };
    showModal(modalData);
  };

  const handleUpdateStatus = (props, payload, modalType) => {
    const data = {
      title: (
        <FormattedMessage
          id="modal.txDetails.common.updateStatus"
          defaultMessage=""
        />
      ),
      modalData: {
        modalType,
        data: payload,
        isDropDown: true,
        list: props.rfqStatusList,
        valueKeyName: "statusName",
        optionKeyName: "rfqStatusId",
        selectedValue: payload.status,
        canAddNew: true,
        canDelete: true,
        deleteClickHandler: (payloadObj) => {
          deleteClickHandler(props, MODAL_TYPE.RFQ_STATUS, payloadObj);
        },
        onAddNewClick: (payloadObj) => {
          payloadObj.formData = {
            textBoxValue: payloadObj.textEntered,
            submittedOnce: false,
            itemPayload: payload,
          };
          handleNewItemAddition(props, payloadObj, MODAL_TYPE.RFQ_STATUS);
        },
      },
      handleSubmit: (formData = {}) => {
        if (formData.textBoxValue && formData.textBoxValue.length) {
          updateStatusConfirmationModal(
            props,
            {
              rfqMasterId: payload.rfqMasterId,
              customerId: payload.customerId,
              text: formData.textBoxValue,
              rfqChildMasterId: payload.rfqChildMasterId,
              statusId: Number(formData.optionId),
              statusColor: formData.statusColor,
              status: payload.status,
            },
            modalType
          );
        }
      },
    };
    props.showModal(data);
  };

  return (
    <div className={"custom-modal show"}>
      <i className="close-icon">
        <svg
          width="17"
          height="17"
          onClick={() => {
            props.popModalFromStack();
          }}
        >
          <title>
            <FormattedMessage id="button.close.label" defaultMessage="" />
          </title>
          <path
            fill="#67757d"
            fillRule="evenodd"
            d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"
          />
        </svg>
      </i>
      <div className="modal-dialog">
        <div className="modal-content full-width vertical-view-data">
          {/* <div className="modal-header">
                        {rfqData.documentName || 'RFQ'}
                    </div>
                    <div className="inner-content">
                        <Row>
                            <Col span={8}>
                                <div className="d-inline-flex w-100">
                                    <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3 + '/bo-relationship/' + (props.companyInfo || {}).logoAwsObjectKey} />
                                    <div className="read-only-content pl10">
                                        <div className="title lh-adjust pb5">
                                            {props.companyInfo.storeName}
                                        </div>
                                        {getAddressInfoFragment(relationshipAddress, companyInfo)}
                                        {getTaxIdentificationsFragment( ((rfqData.additionalInfoList || [])[0] || {}).relationshipTaxIdentifications ) }
                                    </div>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="read-only-content">
                                   
                                </div>
                            </Col>

                            <Col span={5} offset={3}>
                                <div className="read-only-content">
                                    <Row>
                                        <Col span={12} className="title text-right">
                                            RFQ No.
                                        </Col>
                                        <Col span={12}>
                                            {rfqData.rfqNumber}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12} className="title text-right">
                                            RFQ Date
                                        </Col>
                                        <Col span={12}>
                                            {(rfqData.rfqDate && moment(rfqData.rfqDate).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
                                        </Col>
                                    </Row>

                                    {
                                        rfqData.rfqExpirationDate &&
                                        <Row>
                                            <Col span={12} className="title text-right">
                                                Bid Closing Date
                                            </Col>
                                            <Col span={12}>
                                                {(rfqData.rfqExpirationDate && moment(rfqData.rfqExpirationDate).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
                                            </Col>
                                        </Row>
                                    }

                                </div>
                            </Col>
                        </Row> */}
          <div className="modal-header"></div>
          <div id="so-readonly" className="inner-content">
            <div className="brand-logo">
              {/* <LogoComponent src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + (props.companyInfo || {}).logoAwsObjectKey + "?" + Date.now()}/> */}
              {/* <Avatar shape='square' size={64} icon={<UserOutlined />} src={CONSTANTS.BASE_URL_S3+'/'+ config.BUCKET_NAME.BO_RELATIONSHIP +'/'+(props.companyInfo || {}).logoAwsObjectKey + "?"+Date.now()}/> */}
            </div>
            <div className="doc-title">{rfqData.documentName || "RFQ"}</div>
            <Row>
              <Col span={16}></Col>
              <Col span={4}>
                <div className="read-only-content pt20">
                  <div className="pb5">
                    <div className="title">
                      <FormattedMessage
                        id="common.rfqNumber"
                        defaultMessage=""
                      />
                    </div>

                    <div>{rfqData.rfqNumber}</div>
                  </div>

                  <div className="pb5">
                    <div className="title">
                      <FormattedMessage
                        id="modal.txDetails.rfq.rfqDate"
                        defaultMessage=""
                      />
                    </div>

                    <div>
                      {rfqData.rfqDate &&
                        getMomentDateForUIReadOnly({
                          date: rfqData.rfqDate,
                          format: CONSTANTS.DISPLAY_DATE_FORMAT,
                        })}
                    </div>
                  </div>
                  {rfqData.rfqExpirationDate && (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.rfq.bidClosingDate"
                          defaultMessage=""
                        />
                      </div>

                      <div>
                        {rfqData.rfqExpirationDate &&
                          getMomentDateForUIReadOnly({
                            date: rfqData.rfqExpirationDate,
                            format: CONSTANTS.DISPLAY_DATE_FORMAT,
                          })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="read-only-content ">
                  {rfqData.customerRFQNumber &&
                  Number(rfqData.customerRFQNumber) !== 0 ? (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.rfq.customerRFQNumber"
                          defaultMessage=""
                        />
                      </div>

                      <div>{rfqData.customerRFQNumber}</div>
                    </div>
                  ) : null}
                  {rfqData.refNumber && (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.refNumber"
                          defaultMessage=""
                        />
                      </div>

                      <div>{rfqData.refNumber}</div>
                    </div>
                  )}
                  {rfqData.salesPersonFullName && (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="common.salesPerson"
                          defaultMessage=""
                        />
                      </div>

                      <div>{rfqData.salesPersonFullName}</div>
                    </div>
                  )}

                  {rfqData.orderPriority && (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.priority"
                          defaultMessage=""
                        />
                      </div>

                      <div>{rfqData.orderPriority}</div>
                    </div>
                  )}
                  {rfqData.projectMasterId ? (
                    <div className="pb5">
                      <div className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.project"
                          defaultMessage=""
                        />
                      </div>
                   
                      <div>
                        {rfqData.projectMasterId ? rfqData.projectName : ""}
                      </div>
                   
                    </div>
                  )
                :""
                }
                  {/* {
                    rfqData.requestingDepartment &&
                        <div className="pb5">
                            <div className="title">
                            Requesting Department
                            </div>

                        <div>
                            {rfqData.requestingDepartment}
                        </div>
                    </div>
                } */}
                </div>
              </Col>
              <Col span={4}>
                <div className="d-inline-flex w-100">
                  <div className="read-only-content pt20 pl10">
                    <div className="title lh-adjust pb5">
                      {props.companyInfo.storeName}
                    </div>
                    <div>
                      {getAddressInfoFragment(relationshipAddress, companyInfo)}
                      <div>
                        {getTaxIdentificationsFragment(
                          ((rfqData.additionalInfoList || [])[0] || {})
                            .relationshipTaxIdentifications
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {/* <div className="modal-table-container vertical-space">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Customer RFQ #</th>
                                        <th>Ref #</th>
                                        <th>Sales Person</th>
                                        <th>Priority</th>
                                        <th>Project</th>
                                        <th>Requesting Department</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{rfqData.customerRFQNumber || '-'}</td>
                                        <td>{rfqData.refNumber || '-'}</td>
                                        <td>{rfqData.salesPersonFullName || '-'}</td>
                                        <td>{rfqData.orderPriority || '-'}</td>
                                        <td>{rfqData.projectNumber ? (rfqData.projectNumber + ' (' + rfqData.projectName + ')') : '-'}</td>
                                        <td>{rfqData.requestingDepartment || '-'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}

            <div className="modal-table-container mb15">
              <table className="transaction-details-table">
                <thead>
                  <tr>
                    <th className="cell-size-50">#</th>
                    {(!isColumnEmpty.product || !isColumnEmpty.description) && (
                      <th>
                        {!isColumnEmpty.product && (
                          <span>
                            {" "}
                            <FormattedMessage
                              id="modal.txDetails.common.product"
                              defaultMessage=""
                            />
                          </span>
                        )}
                        {!isColumnEmpty.product &&
                          !isColumnEmpty.description && <span> & </span>}
                        {!isColumnEmpty.description && (
                          <span>
                            {" "}
                            <FormattedMessage
                              id="modal.txDetails.common.description"
                              defaultMessage=""
                            />
                          </span>
                        )}
                      </th>
                    )}
                    {/* {!isColumnEmpty.variant && <th>Variant</th>} */}
                    {!isColumnEmpty.qty && (
                      <th className="cell-size-100">
                        <FormattedMessage
                          id="modal.txDetails.common.qty"
                          defaultMessage=""
                        />
                      </th>
                    )}
                    {!isColumnEmpty.rate && (
                      <th className="cell-size-100">
                        <FormattedMessage id="modal.txDetails.common.rate" />
                      </th>
                    )}
                    {!isColumnEmpty.discount && (
                      <th className="cell-size-100">
                        <FormattedMessage id="modal.txDetails.common.discount" />
                      </th>
                    )}
                    {!isColumnEmpty.tax && (
                      <th className="cell-size-100">
                        <FormattedMessage id="modal.txDetails.common.tax" />
                      </th>
                    )}
                    <th className="cell-size-100">
                      <FormattedMessage id="modal.txDetails.common.netAmount" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(rfqDetailsList || []).map((item, i) => {
                    return (
                      <tr key={i}>
                        <td className="cell-size-50">{i + 1}</td>
                        {(!isColumnEmpty.product ||
                          !isColumnEmpty.description) && (
                          <td>
                            <strong>{getVariantFragment(item)}</strong>
                            {!isColumnEmpty.description && (
                              <div className="description-cell pr100">
                                {ReactHtmlParser(item.description || "-")}
                              </div>
                            )}
                          </td>
                        )}
                        {/* {!isColumnEmpty.variant && <td>{item.itemVariantName || '-'}</td>} */}
                        {!isColumnEmpty.qty && (
                          <td className="cell-size-100">
                            {item.quantity +
                              (item.uomName ? " " + item.uomName : "")}
                          </td>
                        )}
                        {!isColumnEmpty.rate && (
                          <td className="cell-size-100">
                            {item.anItemPurchasePrice || "-"}
                          </td>
                        )}
                        {!isColumnEmpty.discount && (
                          <td className="cell-size-100">
                            {getTxTypeData(item, "discountAmount", "amount")}{" "}
                            {getTxTypeData(
                              item,
                              "discountPercent",
                              "amountPercent"
                            )}
                          </td>
                        )}
                        {!isColumnEmpty.tax && (
                          <td className="cell-size-100">
                            {getTotalTaxAmount(item)}{" "}
                            {getTotalTaxPercent(item, "amountPercent")}
                          </td>
                        )}
                        <td className="right-text cell-size-100">
                          {getNetAmount(item) || "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Row>
              <Col span={9}>
                <div className="read-only-content">
                  {rfqData.docDetailList && rfqData.docDetailList.length ? (
                    <Row>
                      <Col span={24} className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.attachments"
                          defaultMessage=""
                        />
                      </Col>
                      <Col span={12}>
                        <AWSFileTable
                          {...props}
                          docDetailList={rfqData.docDetailList}
                          fetchTxDetail={(data) => {
                            props.fetchInquiryDetail({
                              supplierId: rfqData.supplierId,
                              rfqMasterId: rfqData.rfqMasterId,
                              relationshipId: rfqData.relationshipId,
                            });
                          }}
                        />
                        {/* {
                                                rfqData.docDetailList.map((doc) => {
                                                    return <span className={"cursor-pointer"} onClick={() => {
                                                        props.downloadFileFromBucket({
                                                            bucketName: doc.bucketName,
                                                            awsKeyName: doc.awsKeyName,
                                                            fileName: doc.fileName
                                                        })
                                                    }}>{doc.fileName} &nbsp;</span>
                                                })
                                            } */}
                      </Col>
                    </Row>
                  ) : null}
                  {rfqData.remarksInternal && (
                    <Row>
                      <Col span={7} className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.internalRemarks"
                          defaultMessage=""
                        />
                      </Col>
                      <Col span={17}>
                        {ReactHtmlParser(rfqData.remarksInternal)}
                      </Col>
                    </Row>
                  )}
                  {rfqData.remarksSupplier && (
                    <Row>
                      <Col span={7} className="title">
                        <FormattedMessage
                          id="modal.txDetails.common.supplierRemarks"
                          defaultMessage=""
                        />
                      </Col>
                      <Col span={17}>
                        {ReactHtmlParser(rfqData.remarksSupplier)}
                      </Col>
                    </Row>
                  )}
                  {props.supplierResponse?.supplierComment && (
                    <><Row>
                      <Col span={7} className="title">
                        <FormattedMessage id="supplier.rfq.biddingSuppliers.gridHeader.comments" />
                      </Col>
                    </Row><Col span={17}>
                        {ReactHtmlParser(
                          props.supplierResponse?.supplierComment
                        )}
                      </Col></>
                  )}
                </div>
              </Col>
              <Col offset={7} span={8}>
                <div
                  className="upload-link new-pic"
                  title={props.intl.formatMessage({
                    id: "uploadSignature.tooltip",
                  })}
                  style={{ width: "50px", height: "50px" }}
                >
                  <CustomUploader {...customProps} />
                  {rfqData.stampAwsKey && (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        props.deleteStamp(
                          {
                            supplierId: rfqData.supplierId,
                            rfqMasterId: rfqData.rfqMasterId,
                            relationshipId: rfqData.relationshipId,
                            objectKey: rfqData.stampAwsKey,
                          },
                          props
                        );
                      }}
                    >
                      {" "}
                      <FormattedMessage
                        id="modal.txDetails.common.deleteStamp"
                        defaultMessage=""
                      />{" "}
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <div className="contact-details">
              {rfqData.footer && (
                <Fragment>{ReactHtmlParser(rfqData.footer)}</Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const updateColumnEmptyCheck = (isColumnEmpty, rfqData) => {
  if (!rfqData) return isColumnEmpty;
  for (let i in rfqData.rfqDetailsList) {
    let row = rfqData.rfqDetailsList[i];
    if (row.itemName) {
      isColumnEmpty.product = false;
    }
    if (row.serviceName) {
      isColumnEmpty.service = false;
    }
    if (row.itemVariantName) {
      isColumnEmpty.variant = false;
    }
    if (row.description) {
      isColumnEmpty.description = false;
    }
    if (row.quantity) {
      isColumnEmpty.qty = false;
    }
    if (row.uomName) {
      isColumnEmpty.uomName = false;
    }
    if (row.anItemPurchasePrice) {
      isColumnEmpty.rate = false;
    }
    if (row.discountPercent) {
      isColumnEmpty.discount = false;
    }
    if (row.tax) {
      isColumnEmpty.tax = false;
    }
  }
  return isColumnEmpty;
};

const getAddressInfoFragment = (obj, additionInfo) => {
  const fragmentObj = (
    <Fragment>
      {obj.streetAddress1 ? (
        <div className="billing-address">{obj.streetAddress1}</div>
      ) : (
        ""
      )}
      {obj.streetAddress2 ? (
        <div className="billing-address">{obj.streetAddress2}</div>
      ) : (
        ""
      )}
      {obj.cityName ? (
        <div className="billing-address">
          {`${obj.cityName || ""} ${obj.stateName ? "," + obj.stateName : ""} ${
            obj.zipCode ? "," + obj.zipCode : ""
          }`}
        </div>
      ) : (
        ""
      )}
      {/* {
            additionInfo.firstName
                ? <div className='billing-address'>
                    {contactName}
                </div> :
                ''
        } */}
      {additionInfo.workPhone ? (
        <div className="billing-address">
          <FormattedMessage id="phone" defaultMessage="" /> -{" "}
          {additionInfo.workPhone}
        </div>
      ) : (
        ""
      )}
      {additionInfo.email ? (
        <div className="billing-address">
          <FormattedMessage id="email" defaultMessage="" /> -{" "}
          {additionInfo.email}
        </div>
      ) : (
        ""
      )}
      {additionInfo.gstNumber ? (
        <div className="billing-address">
          <FormattedMessage id="gstIn" defaultMessage="" /> -{" "}
          {additionInfo.gstNumber}
        </div>
      ) : (
        ""
      )}
      {additionInfo.panNumber ? (
        <div className="billing-address">
          <FormattedMessage id="pan" defaultMessage="" /> -{" "}
          {additionInfo.panNumber}
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );

  return fragmentObj;
};

const getTaxIdentificationsFragment = (taxIdentifications) => {
  if (!taxIdentifications) return <Fragment />;
  let taxIdentificationsList = [];
  if (taxIdentifications) {
    taxIdentificationsList = JSON.parse(taxIdentifications);
  }
  const fragmentObj = (
    <Fragment>
      {taxIdentificationsList.map((obj, i) => {
        return (
          <div className="billing-address">
            {obj.taxIdentificationType || ""} - {obj.taxIdentificationNumber}
          </div>
        );
      })}
    </Fragment>
  );

  return fragmentObj;
};

// const getContactNameFragment = (additionInfo) => {
//     if (!additionInfo) return <Fragment />;
//     let contactName = "";
//     if (additionInfo.salutationName) contactName += additionInfo.salutationName + ' ';
//     if (additionInfo.firstName) contactName += additionInfo.firstName;
//     if (additionInfo.middleName) contactName += ' ' + additionInfo.middleName;
//     if (additionInfo.lastName) contactName += ' ' + additionInfo.lastName;
//     const fragmentObj = <Fragment>
//         {
//             (contactName && contactName.length)
//                 ? <div className='billing-address'>
//                     {contactName}
//                 </div> :
//                 ''
//         }
//     </Fragment>
//     return fragmentObj;
// }
const getVariantFragment = (item) => {
  let itemName = null;
  if (item.itemName) {
    itemName = item.itemName;
  }
  if (item.itemVariantName) {
    itemName = itemName + " (" + item.itemVariantName + ")";
  }
  return itemName ? itemName : "";
};

const getTxTypeData = (data, type, toReturn) => {
  if (data) {
    if (data[type] && toReturn === "amountPercent") {
      if (type === "VAT") {
        return "VAT(" + data[type] + "%)";
      }
      return "(" + data[type] + "%)";
    } else if (data[type] && toReturn === "amount") {
      return fixedDecimalNumber(data[type]);
    } else {
      return "";
    }
  } else if (!data && toReturn === "amount") {
    return "";
  } else {
    return "-";
  }
};
const getTotalTaxAmount = (row) => {
  let tax1 = row.taxAmount !== "NAN" ? row.taxAmount || 0 : 0;
  let totalTax = Number(tax1);
  return totalTax;
};

const getTotalTaxPercent = (row, toReturn) => {
  let totalPercent = Number(row.tax || 0);
  if (toReturn === "amountPercent") {
    return totalPercent ? "(" + totalPercent + "%)" : "";
  } else {
    return totalPercent;
  }
};
const getNetAmount = (row) => {
  let tax = getTotalTaxAmount(row) || 0;
  let discount = getTxTypeData(row, "discountAmount", "amount", true) || 0;
  let netAmountItem =
    Number(row.quantity * row.anItemPurchasePrice) +
    Number(tax) -
    Number(discount);
  return fixedDecimalNumber(netAmountItem);
};
export default RFQDetails;
