import React from "react";
import { Row, Col, Tabs, Button, Checkbox, Radio, Divider } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import "./index.scss";
import ReactHtmlParser from "react-html-parser";
import { CUSTOM_CONFIGURATION_FORM } from "./../../../static/ckEditorConfigConstants";
import {
  showToasterMessage,
  getMomentLoclDateForUIReadOnly,
} from "../../../utils";
import CKEditor from "../../general/CustomCKEditor/ckeditor";
import { Dropdown } from "../../general/Dropdown";
import { TextBox } from "../../general/TextBox";
import { TextArea } from "../../general/TextAreaComponent";
import HtmlParser from "react-html-parser";

const { TabPane } = Tabs;

function ImportCustomer(props) {
  const { companyInfo, assignmentData = {} } = props;
  const questionList = JSON.parse(props?.assignmentResponseObj?.questionAnswer || "[]");
  const studentResponse = props?.assignmentResponseObj?.docDetailList
  
  const toggleDescriptionText = () => {
    props.updateState({ isSeeMore: !props.isSeeMore });
  };
  const toggleResponseText = () => {
    props.updateState({ isSeeMoreResponse: !props.isSeeMoreResponse });
  };
  function removeTags(html) {
    return html?.replace(/<[^>]+>/g, '');
}
  return (
    <div className="view-container" style={{height: 'calc(100vh - 68px)'}}>
      <Tabs type="line">
        <TabPane tab="Form" key="1" className="assignment-tab">
          <Row>
            <Col span={11}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <b>Assignment Name:</b>
                </Col>
                <Col span={16}>
                  {assignmentData.assignmentName || ""}
                </Col>

                <Col span={8}>
                  <b>Student Name:</b>
                </Col>
                <Col span={16}>
                  {`${
                    (props.assignmentResponseObj || {})
                      .studentFirstName || ""
                  } ${
                    (props.assignmentResponseObj || {})
                      .studentLastName || ""
                  }`}
                </Col>

                <Col span={8} >
                  <b>Class:</b>
                </Col>
                <Col span={16}>{assignmentData.className || ""}</Col>

                {assignmentData.sectionName ? <><Col span={8} >
                  <b>Section:</b>
                </Col>
                <Col span={16}>{assignmentData.sectionName || ""}</Col></> :""}

                {/* <Col span={8}>
                  <b>Group:</b>
                </Col>
                <Col span={16}>
                  {assignmentData.assignmentGroupName}
                </Col> */}

                {assignmentData.dueDate ? (
                  <>
                    <Col span={8}>
                      <b>
                        <FormattedMessage id="common.dueDate" />:
                      </b>
                    </Col>
                    <Col span={16}>
                      {assignmentData.dueDate
                        ? getMomentLoclDateForUIReadOnly({
                            date: assignmentData.dueDate,
                            format: "YYYY-MM-DD",
                          })
                        : ""}
                    </Col>
                  </>
                ) : (
                  ""
                )}

                <Col span={8}>
                  <b>Grading System:</b>
                </Col>
                <Col span={16}>{assignmentData.gradingSystem}</Col>

                <Col span={8}>
                  <b>Number of Attempts:</b>
                </Col>
                <Col span={16}>
                  {assignmentData.numberOfAttempts}
                  <span>
                    {`(${
                      (props.assignmentResponseObj || {})
                        .totalAttempts || 0
                    }/${assignmentData.maxAttempts || 1})`}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={11} offset={1}>
              <Row>
                <Col span={24}>
                  <b>Assignment Description - </b>
                </Col>
                {assignmentData.assignmentDescription ? 
                <Col span={24}>
                  <div
                    style={{
                      height: props.isSeeMore ? "auto" : "12.0em",
                      overflow: "hidden",
                    }}
                  >
                    {ReactHtmlParser(assignmentData.assignmentDescription)}
                  </div>
                  <span
                    className="more-details-btn"
                    onClick={() => {
                      toggleDescriptionText();
                    }}
                  >
                    {" "}
                    {props.isSeeMore ? "Show Less" : "Show More"}{" "}
                  </span>
                </Col>
                :
                ''
                }
              </Row>
            </Col>
          </Row>

          <Divider />
          
          <Row>
            <Col span={11}>
              <Row>
                <b>Student Attachments</b>
              </Row>
              {/* <Row>
                <Col span={24}>
                  <div
                    style={{
                      height: props.isSeeMoreResponse
                        ? "auto"
                        : "4.4em",
                      overflow: "hidden",
                      textAlign: "left",
                    }}
                  >
                    {ReactHtmlParser((props.assignmentResponseObj || {}).response || "")}
                  </div>
                  <span
                    className="more-details-btn text-left"
                    style={{ float: "left" }}
                    onClick={() => {
                      toggleResponseText();
                    }}
                  >
                    {" "}
                    {props.isSeeMoreResponse
                      ? "Show Less"
                      : "Show More"}{" "}
                  </span>
                </Col>
              </Row> */}
              {(studentResponse || []).length ? (
                <Row>
                  {/* <Col span={24} className="title">
                    <FormattedMessage
                      id="modal.txDetails.common.attachments"
                      defaultMessage=""
                    />
                  </Col> */}
                  <Col span={22}>
                    <table id="studentTable" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage
                              id="serial.no"
                              defaultMessage=""
                            />
                          </th>
                          <th>File Name</th>
                          <th>Type</th>
                          <th>Size</th>
                        </tr>
                      </thead>
                      {(studentResponse || []).map((doc, i) => {
                        return (
                          <tr key={"std" + i}>
                            <td>{i + 1}</td>
                            <td>
                              <span
                                className={"cursor-pointer"}
                                onClick={() => {
                                  props.downloadFileFromBucket({
                                    bucketName: doc.bucketName,
                                    awsKeyName: doc.awsKeyName,
                                    fileName: doc.fileName,
                                  });
                                }}
                              >
                                {doc.fileName}
                              </span>
                            </td>
                            <td>{doc.fileType}</td>
                            <td>{doc.fileSize}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col span={1}>
              <div
                style={{
                  background: "#e4e4e4",
                  width: "2px",
                  height: "490px",
                  float: "right",
                }}
              />
            </Col>
            <Col span={11} offset={1}>
              <div
                style={{
                  textAlign: "center",
                  height: "initial",
                  background: "initial",
                }}
                className="rem-bgs"
              >
                  <div className="pt10">
                    <Row>
                      <Col span={8} style={{ textAlign: "left" }}>
                        Grade:
                      </Col>
                      <Col span={16}>

                        {assignmentData.gradingSystem === "Letter Grade(A,B,C,D)" ? 
                          <Dropdown
                            style={{ width: "100%", marginRight: "5px" }}
                            items={[
                              {
                                grade: "A+",
                                gradeName: "A+",
                                range: "100% to 96.67%",
                              },
                              {
                                grade: "A",
                                gradeName: "A",
                                range: "< 96.67% to 93.33%",
                              },
                              {
                                grade: "A-",
                                gradeName: "A-",
                                range: "< 93.33% to 90.0%",
                              },
                              {
                                grade: "B+",
                                gradeName: "B+",
                                range: "< 90.0% to 86.67%",
                              },
                              {
                                grade: "B",
                                gradeName: "B",
                                range: "< 86.67% to 83.33%",
                              },
                              {
                                grade: "B-",
                                gradeName: "B-",
                                range: "< 83.33% to 80.0%",
                              },
                              {
                                grade: "C+",
                                gradeName: "C+",
                                range: "< 80.0% to 76.67%",
                              },
                              {
                                grade: "C",
                                gradeName: "C",
                                range: "< 76.67% to 70.0%",
                              },
                              {
                                grade: "D",
                                gradeName: "D",
                                range: "< 70.0% to 60.0%",
                              },
                              {
                                grade: "F",
                                gradeName: "F",
                                range: "< 6.0% to 0.0%",
                              },
                            ]}
                            optionKeyName="grade"
                            valueKeyName="gradeName"
                            valueKeyName2="range"
                            value={props.assignedGrade}
                            placeholder="Grade"
                            onSelect={(optionValue) => {
                              props.updateState({
                                assignedGrade: optionValue,
                              });
                            }}
                          />
                         : 
                          ""
                        }

                        {assignmentData.gradingSystem === "Pass/Fail" ? 
                          <Dropdown
                            style={{ width: "100%", marginRight: "5px" }}
                            items={["Pass", "Fail"]}
                            value={props.assignedGrade}
                            placeholder="Grade"
                            onSelect={(optionValue) => {
                              props.updateState({
                                assignedGrade: optionValue,
                              });
                            }}
                          />
                          :
                          ""
                        }

                        {assignmentData.gradingSystem === "Percentage" ? 
                          <TextBox
                            style={{ width: "100%" }}
                            // type='number'
                            value={props.assignedGrade}
                            placeholder="Grade"
                            onChange={(e) => {
                              const re = /^[0-9.\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                props.updateState({
                                  assignedGrade: e.target.value,
                                });
                              } else if (!props.assignmentPoints) {
                                props.updateState({
                                  assignedGrade: "",
                                });
                              }
                            }}
                            suffix={assignmentData.gradingSystem === "Percentage"
                            ? "%"
                            : ""}
                          />
                          : 
                          ""
                        }

                        {assignmentData.gradingSystem === "Points" ? 

                          <TextBox
                            value={props.assignedGrade}
                            placeholder="Grade"
                            onChange={(e) => {
                              const re = /^[0-9.\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                props.updateState({
                                  assignedGrade: e.target.value,
                                });
                              } else if (!props.assignmentPoints) {
                                props.updateState({ assignedGrade: "" });
                              }
                              //  props.updateState({ assignedGrade: e.target.value });
                            }}
                          />
                          : 
                          ""
                        }
                      </Col>
                    </Row>
                  </div>
                <div style={{ paddingTop: "10px " }}>
                  <Row>
                    <Col span={24}>
                      {props.isLoadData && (
                        <CKEditor
                          // editor={ClassicEditor}
                          data={props.gradingRemark || ""}
                          key={`${"gradingRemark"}`}
                          // onInit={editor => {
                          //     this.editor = editor;
                          // }}
                          onChange={(event) => {
                            const data = event.editor.getData();
                            props.updateState({ gradingRemark: data });
                          }}
                          config={{
                            ...CUSTOM_CONFIGURATION_FORM,
                            placeholder: "Grading Remark",
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </TabPane>

        <TabPane tab="Q&A" key="2" className="assignment-tab">
          {(questionList || []).map((queObj, i) => {
            return (
              <div
                style={{
                  marginBottom: "20px",
                  padding: "20px 10px 30px 10px",
                  borderBottom: "solid 1px #e4e4e4",
                }}
                key={"que" + i}
              >
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={20}>
                    <b>
                      {queObj.questionText}{" "}
                      {queObj.isRequired ? (
                        <span className="required">*</span>
                      ) : (
                        ""
                      )}
                    </b>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    {(queObj.optionList || []).length ? (
                      <div className="nertr">
                        {queObj.answerType === "multiChoice" ? (
                          <Checkbox.Group value={queObj.answers}>
                            {(queObj.optionList || []).map(
                              (optObj, j) => {
                                return (
                                  <div key={"opt" + j}>
                                    <Checkbox
                                      disabled
                                      value={optObj.optText}
                                    >
                                      {optObj.optText}
                                    </Checkbox>
                                  </div>
                                );
                              }
                            )}
                          </Checkbox.Group>
                        ) : (
                          <Radio.Group value={queObj.answers}>
                            {(queObj.optionList || []).map(
                              (optObj, k) => {
                                return optObj.optText ? (
                                  <div key={"optA" + k}>
                                    <Radio
                                      disabled
                                      value={optObj.optText}
                                    >
                                      {optObj.optText}
                                    </Radio>
                                  </div>
                                ) : (
                                  ""
                                );
                              }
                            )}
                          </Radio.Group>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>

                {queObj.answerType === "shortAnswer" ? (
                  <Row>
                    <Col span={20}>
                      <TextArea
                        disabled
                        style={{ width: "100%" }}
                        type="text"
                        value={removeTags(queObj.answers)}
                      />
                      {/* <CKEditor
                                                          type="inline"
                                                          className="description-input"
                                                          key={`${"queAns_" + i}`}
                                                          data={queObj.answers}
                                                          editor={props.editor}
                                                          onInit={editor => {
                                                              props.updateState({
                                                                  editor
                                                              })
                                                          }}
                                                          onChange={(event) => {
                                                              const data = event.editor.getData();
                                                              queObj.answers = data;
                                                              let questionList = props.questionList;
                                                              questionList.splice(i, 1, queObj);
                                                              props.updateState(questionList);
                                                              // updateState({
                                                              //     remarks: data
                                                              // });
                                                          }}
                                                          config={CUSTOM_CONFIGURATION}
                                                      /> */}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </TabPane>
      </Tabs>

      <div
        className="a-footer"
      >
        <Button
          type="default"
          onClick={() => {
            props.history.replace({
              pathname: "/admin/school-management/assignment-response",
              state: {
                assignmentId: assignmentData.assignmentId,
                classId: assignmentData.classId,
              },
            });
            props.popModalFromStack();
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (
              assignmentData.gradingSystem !== "Ungraded" &&
              !props.assignedGrade
            ) {
              return showToasterMessage({
                messageType: "error",
                description: "Grade is required",
              });
            }

            const modalData = {
              modalBody: "Are you sure you want to submit?",
              handleSubmit: () => {
                let payload = {
                  studentId: props.studentId,
                  relationshipId: companyInfo.relationshipId,
                  assignmentId: assignmentData.assignmentId,
                  assignmentName: assignmentData.assignmentName,
                  grade: props.assignedGrade,
                  teacherComment: props.gradingRemark,
                  classId: assignmentData.classId,
                  assignmentGroupId: assignmentData.assignmentGroupId,
                  assignmentResponseId: props.assignmentResponseId,
                };
                props.assignGrade(payload, props);
              },
            };
            props.pushModalToStack(modalData);
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default injectIntl(ImportCustomer);
