import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDataIfNeeded } from '../../../utils';
import TransportDataDrawerComp from '../../../components/drawer/transportData';
import { showModal, hideModal } from './../../../actions/commonActions';
import { fetchAllFreightType, addFreightType, deleteFreightType } from '../../customer/salesOrder/shipment/action';
import { } from './action';

class TransportDataDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.customerId, soMasterId: this.props.soMasterId };
    fetchDataIfNeeded('fetchAllFreightType', 'carrierList', this.props, payload);
  }

  render() {
    return <TransportDataDrawerComp {...this.props} {...this.state} updateDrawerState={(data) => { this.setState(data) }} />
  }

}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    freightList: state.so.freightList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  showModal,
  hideModal,
  fetchAllFreightType,
  addFreightType,
  deleteFreightType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransportDataDrawer);
