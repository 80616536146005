import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProjectCompo from '../../../../../components/modal/modalBody/common/Project';
import { fetchAllDepartments, fetchAllEmployees, showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { createOrUpdateProject } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { getAllProject } from '../../../../company/Project/action';

class ProjectContainer extends Component {
  constructor(props) {
    super(props);
    let data = props.data || {}
    this.state = {
      projectName: data.projectName,
      projectMasterId: data.projectMasterId,
      isNeverExpired: data.isNeverExpired,
      description: data.description,
      projectStartDate: data.startDate,
      projectEndDate: data.endDate,
      departmentName: data.departmentName,
      projectOwner: data.projectOwner,
      version: data.version
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId
    };
    fetchDataIfNeeded('fetchAllDepartments', 'departments', this.props, payload);
    fetchDataIfNeeded('fetchAllEmployees', 'employees', this.props, payload);
  }

  render() {
    return (
      <ProjectCompo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    departments: state.common.departmentList,
    employees: state.common.employees
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createOrUpdateProject,
  fetchAllDepartments, 
  fetchAllEmployees,
  pushModalToStack,
  popModalFromStack,
  getAllProject,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer);
