import React from "react";
import { Drawer, Row, Col, DatePicker } from "antd";
import {
  getMomentDateForUI,
  getCurrentDateForBackend,
  showToasterMessage,
} from "../../../../utils";
import { CustomButton } from "../../../general/CustomButton";
import { TextBox } from "../../../general/TextBox";
import { Dropdown } from "../../../general/Dropdown";
import { CONSTANTS } from "../../../../static/constants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker } = DatePicker;

const SOShipmentFilterComp = (props) => {
  const { updateState, companyInfo } = props;

  const closeDrawer = () => {
    props.updateState({
      shipmentFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
    // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
        if(!props.selectedCustomerId && !props.shipmentNum && !props.startDate && !props.endDate && !props.selectedUserStatus && !props.selectedStatus && !props.refNum && !props.trackingNum){
            return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
        }
        const payload = {
            companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
            filters: {
                customerId: props.selectedCustomerId || 0,
                shipmentNumber: props.shipmentNum || null,
                startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                userStatus: props.selectedUserStatusName || null,
                status: props.selectedStatus || null,
                reference: props.refNum || null,
                trackingNumber: props.trackingNum || null,
            }
        };
        props.fetchAllShipmentByFilter(payload);
        props.getShipmentCountByFilter(payload);
        props.updateState({
          shipmentFilterDrawerVisible: false,
        });
      };

  const clearFilter = () => {
    props.updateState({
        selectedCustomerId: null, selectedCustomerName: null,
        shipmentNum: null,
        startDate:null, endDate:null,
        selectedUserStatusName: null, selectedUserStatus: null,
        selectedStatus:null, selectedStatusName: null,
        refNum: null,
        trackingNum: null,
    });
    const payload = {
        companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
        filters: {}
    };
    props.fetchAllShipment(payload);
    props.getShipmentCount(payload);
    props.updateState({
      shipmentFilterDrawerVisible: false,
    });
  };

  const rowGutter = [24, 16];

  return (
    <>
      <Drawer
        title={<div className="drawer-head-txt">Filters</div>}
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.shipmentFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
        <Col span={12}>
            <div className="i-label">Shipment #</div>
            <TextBox 
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                placeholder='Shipment #'
                type='text'
                maxLength={15}
                countStyle={{ top: "43px"}}
                value={props.shipmentNum}
                onChange={(e) => {
                    props.updateState({ shipmentNum: e.target.value });
                }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">Customer</div>
            <Dropdown
              // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}

              items={(props.customers[props.pageNumber] || []).sort((a, b) => (a.companyName||'').localeCompare(b.companyName||''))}
              valueKeyName="companyName"
              optionKeyName="customerId"
              value={props.selectedCustomerName}
              onSearch={(searchedText) => {
                props.fetchCustomers({
                  isCompact: true,
                  searchedText: searchedText,
                  pageNumber: 1,
                  hideSpinner: true,
                  pageSize: 100,
                  relationshipId: (props.companyInfo || {}).relationshipId,
                });
              }}
              placeholder={props.intl.formatMessage({
                id: "customer.salesInvoice.form.customer.placeholder",
                defaultMessage: "",
              })}
              onSelect={(customerId, option) => {
                updateState({
                  selectedCustomerId: option.value,
                  selectedCustomerName: option.name,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({
                  selectedCustomerId: undefined,
                  selectedCustomerName: undefined,
                });
              }}
            />
          </Col>
         
        </Row>
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">Shipment Date</div>
            <RangePicker
                                // style={{ marginTop: '-20px', width: '300px', marginRight: '5px' }}
                allowEmpty={true}
                format={format}
                placeholder={['Start Date', 'End Date']}
                value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                onChange={(dates) => {
                    if (dates) {
                        props.updateState({ startDate: dates[0], endDate: dates[1] });
                    } else {
                        props.updateState({ startDate: null, endDate: null });
                    }
                }}
            />
          </Col>
          {/* <Col span={12}>
            
            
          
                <div className="i-label">Shipment Status</div>

                <Dropdown
                    items={props.userStatus || []}
                    valueKeyName='statusName'
                    optionKeyName={'shipmentStatusId'}
                    statusColorKey='statusColor'
                    // style={{ marginTop: '-25px', width: '160px', marginRight: '5px' }}
                    value={props.selectedUserStatusName}
                    placeholder={props.intl.formatMessage({ id: 'common.status' })}
                    onSelect={(selectedValue, option) => {
                        updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                    }}
                    allowClear={true}
                    onClear={() => {
                        updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                    }}
                />
              </Col> */}
        
      
      
       
        <Col span={12}>
            <div className="i-label">System Status</div>
            <Dropdown
                                // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}
                items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partiallyFullfilled' }, { name: 'Fullfilled', value: 'fullFilled' }].sort((a, b) => a.value.localeCompare(b.value))}
                valueKeyName='name'
                optionKeyName='value'
                value={props.selectedStatusName}
                placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                onSelect={(status, option) => {
                    updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                }}
                allowClear={true}
                onClear={() => {
                    updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                }}
            />
          </Col>
          <Col span={12}>
            <div className="i-label">
            Ref #
            </div>
            <TextBox 
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                placeholder='Ref #'
                type='text'
                maxLength={15}
                countStyle={{ top: "43px"}}
                value={props.refNum}
                onChange={(e) => {
                    props.updateState({ refNum: e.target.value });
                }}
            />
          </Col>
       
          <Col span={12}>
            <div className="i-label">Tracking #</div>
            <TextBox 
                                // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                                countStyle={{ top: "43px"}}
                placeholder={props.intl.formatMessage({ id: 'filter.placeholder.trackingNumber' })}
                type='text'
                maxLength={15}
                value={props.trackingNum}
                onChange={(e) => {
                    props.updateState({ trackingNum: e.target.value });
                }}
            />
          </Col>

         
        </Row>

       
      </Drawer>
    </>
  );
};

export default SOShipmentFilterComp;
