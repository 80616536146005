import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, CUSTOMER_ACTION_LIST } from '../../../static/constants';
import {fetchCustomers, getCustomerCount, resetPaginatedData } from '../../customer/Listing/action'
import cloneDeep from 'lodash/cloneDeep';
import {  showToasterMessage, formatDateForBackend } from '../../../utils';


export const createOrUpdateCustomer = (payload) => {
    const requestObj = getCustomerRequestObj(payload);
  
    let formData = new FormData();
    if(payload.fileToUpload){
      formData.append("file", payload.fileToUpload);
    }
    formData.append('customer', JSON.stringify(requestObj, true));
    formData.append('bucketName' ,payload.companyInfo?.bucketName)

    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/saveOrUpdateCustomer`,
        formData,{
          headers: {
            'content-type': 'multipart/form-data'
        }
        }
        )
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          let data = {
            relationshipId: payload.companyInfo.relationshipId || 0,
            pageSize: 100,
            pageNumber: 1,
               
          }
          resetPaginatedData()(dispatch);
          fetchCustomers(data)(dispatch);
          getCustomerCount(data)(dispatch);
          payload.updateState({ customer: {customerDrawerVisible: false, isclone: true, customerData : {}} });
          showToasterMessage({ messageType: "success", description: "Customer created successfully" });
          // payload.updateState({ customer: {customerDrawerVisible: false} });

        })
        .catch(err => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          payload.updateState({ customer: {customerDrawerVisible: true} });
         showToasterMessage({ description: ((err.response || {}).data || {}).message || 'Some error occurred' })
        })
    }
  }

const getCustomerRequestObj = (payload) => {
  let relationshipId = payload.companyInfo.relationshipId || 0;
  let industry = (payload.industries.find(ind => (ind.businessIndustryId === payload.businessIndustryId))) || {};
  let paymentTerm = (payload.paymentTerms.find(ind => (ind.paymentTermId === payload.paymentTermId))) || {};
  let customerSource = (payload.sources.find(ind => (ind.customerSourceId === payload.customerSourceId))) || {};
  let businessType = (payload.businessTypes.find(businessType => (businessType.businessTypeId === Number(payload.businessTypeId || 0)))) || {};
  let contactCopy = cloneDeep(payload.contact);
  contactCopy.relationshipId = relationshipId;
  contactCopy.isPrimaryContact = 1;
  contactCopy.contactType = "Customer";
  let taxIdentificationsList = [];
  payload.gridApi && payload.gridApi.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    if (data && data.taxIdentificationType && data.taxIdentificationNumber) {
      taxIdentificationsList.push({
        relationshipId: relationshipId,
        customerId: payload.isUpdate ? payload.customerId : undefined,
        taxIdentificationType: data.taxIdentificationType,
        taxIdentificationNumber: data.taxIdentificationNumber,
        populateOnTransaction: data.populateOnTransaction ? 1 : 0
      })
    }
  })
  let locationList = [];
  if(payload.isUpdate){
    locationList = payload.customerData.boLocationList;
  }else{
    let billingCountry = (payload.countries.find(country => (country.countryName === payload.billingAddress.countryName))) || {};
    let shippingCountry = (payload.countries.find(country => (country.countryName === payload.shippingAddress.countryName))) || {};
    let billingAddress = cloneDeep(payload.billingAddress);
    let shippingAddress = cloneDeep(payload.shippingAddress);
    billingAddress.relationshipId = relationshipId;
    billingAddress.countryId = billingCountry.countryId;
    billingAddress.isDefault = 1;
    billingAddress.locationType = 'BillingAddress';
    shippingAddress.relationshipId = relationshipId;
    shippingAddress.countryId = shippingCountry.countryId;
    shippingAddress.isDefault = 1;
    shippingAddress.locationType = 'ShippingAddress';
    billingAddress.streetAddress1 && locationList.push(billingAddress);
    shippingAddress.streetAddress1 && locationList.push(shippingAddress);
  }
 
  return {
    customerId: payload.isUpdate ? payload.customerId : undefined,
    relationshipSince: payload.relationshipSince && formatDateForBackend(payload.relationshipSince),
    companyName: payload.companyName,
    industryId: industry.businessIndustryId,
    customerPhone:payload.customerType === 'business'? payload.customerPhone : contactCopy.cellPhone,
    customerEmail: payload.customerType === 'business'? payload.customerEmail : contactCopy.emailAddress,
    businessTypeId: businessType.businessTypeId,
    businessType: businessType.businessType,
    sourceId: customerSource.customerSourceId,
    sourceName: customerSource.customerSourceName,
    // customerSourceId: customerSource.customerSourceId,
    // sourceName: customerSource.customerSource,
    paymentTermId: paymentTerm.paymentTermId,
    shippingAddressSameAsBilling: payload.shippingAddressSameAsBilling,
    gstMechanism: payload.gstMechanism,
    gstNumber: payload.gstNumber,
    panNumber: payload.panNumber,
    locatedInSez: payload.locatedInSez ? 1 : 0,
    boContactList: payload.isUpdate ? payload.customerData.boContactList : (contactCopy.firstName ? [contactCopy] : []),
    boLocationList: locationList,
    dateCreated: payload.isUpdate ? payload.customerData.dateCreated : undefined,
    // createdByUserId: 37,
    // dateUpdated: payload.isUpdate ? getCurrentDateForBackend(new Date()) : undefined,
    relationshipId: relationshipId,
    starRating:payload.starRating,
    webSite: payload.webSite,
    customerTaxIdentificationsList: payload.customerTaxIdentificationsList,
    customerType: payload.customerType || 'business',
    leadId: payload.crmLeadId,
    leadStatusId: payload.leadStatusId,
    leadStatusName: payload.leadStatusName,
    orgMembershipList:payload.isUpdate ? payload.customerData.boContactList || []:null,
    version: payload.version,
    isActive: 1,
    countryCallingCode:payload.countryCallingCode
  }
}


export const isDuplicateCustomer = (payload) => {
  return dispatch => {
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/isDuplicateCustomer?relationshipId=${payload.relationshipId}&customerName=${payload.customerName}`)
        .then(res => {
          const { data } = res
          if(data){
            showToasterMessage({messageType: 'error',  description: 'Duplicate Customer Exists!' })
          }
        })
        .catch((err) => {
          if(!err.__isRetryRequest){
            showToasterMessage({messageType: 'error',  description: 'Some error occurred' })
          }
        })
    }
}

export const fetchAllGSTINDetails = (payload,callBack) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/gstinDetails/search?gstin=${payload?.gstin}&relationshipId=${payload?.relationshipId}`)
      .then(res => {

        if(callBack){
          callBack(res.data);
        }
        // dispatch({ type: CUSTOMER_ACTION_LIST.FETCH_USERS, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        
       
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description:   'Some error occurred' })
        } else {
          // err.actionToCall = CUSTOMER_ACTION_LIST.FETCH_USERS;
        }
      })
  }
}