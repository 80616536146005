import axios from "axios";
import config from "../../../config/environmentConfig";
import { COMPANY_ACTION_LIST } from "../../../static/constants";
import { showToasterMessage } from "../../../utils";
const { lang } = require("../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const getEmpAttendances = (payload) => {
  return (dispatch) => {
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/empAttendance/getAttendancesByEmployeeIds?relationshipId=${payload.relationshipId}&startDate=${payload.startDate}&endDate=${payload.endDate}&empId=${payload.relationshipEmployeeId}`)
      .then((res) => {
        dispatch({
          type: COMPANY_ACTION_LIST.EMPLOYEE_ATTENDANCE_LIST,
          data: res.data,
        });
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};



