import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchRelationshipTaxIdentifications  } from '../../../../../actions/commonActions';
import { fetchCustomerDetailsByCustomerId } from '../../../../../actions/commonActions';
import TaxIdentificationCompo from '../../../../../components/modal/modalBody/common/TaxIdentification';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchTaxIdentifications, addTaxIdentification, deleteTaxIdentifications } from '../../../../../actions/commonActions';
import { createTaxIdentification,checktaxIdentificationNumber} from './action';

class TaxIdentificationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
     preTypeValue : props.data?.formData?.taxIdentificationNumber,
      ...props.data?.formData,

    };
  }
  componentDidMount() {
    const payload = {
      relationshipId: (this.props.companyInfo || {}).relationshipId
    }
    this.props.fetchTaxIdentifications(payload);
  }
  render() {
    return (
      <TaxIdentificationCompo {...this.props} {...this.state} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    allTaxIdentificatinType: state.common.allTaxIdentificatinType,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchTaxIdentifications,
  createTaxIdentification,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
  addTaxIdentification, 
  deleteTaxIdentifications,
  checktaxIdentificationNumber,
  fetchCustomerDetailsByCustomerId,
  fetchRelationshipTaxIdentifications,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaxIdentificationContainer);
