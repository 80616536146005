import axios from 'axios';
import config from '../../../../../config/environmentConfig';
import { COMMON_ACTIONS, SALES_INVOICE_ACTION_LIST } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchSalesOrderInvoice = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getInvoicesBySOId?relationshipId=${payload.relationshipId}&salesOrderMasterId=${payload.salesOrderMasterId}&customerId=${payload.customerId}`)
      .then(res => {
        dispatch({ type: SALES_INVOICE_ACTION_LIST.SALES_INVOICE_BY_SO_ID, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(!err.__isRetryRequest){
          showToasterMessage({messageType: 'error',  description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        }else{
          err.actionToCall = SALES_INVOICE_ACTION_LIST.SALES_INVOICE_BY_SO_ID;
        }
      })
  }
}