import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ErrorMsg } from '../../../../general/ErrorMessage';
import { TextBox } from '../../../../general/TextBox';
import { CustomButton } from '../../../../general/CustomButton';
import { MODAL_ACTIONS } from '../../../../../static/constants';

import ColorPicker from 'material-ui-rc-color-picker';
import 'material-ui-rc-color-picker/assets/index.css';
import { Panel as ColorPickerPanel } from "material-ui-rc-color-picker";


export const NewRegistrationStatus = (props) => {
  const { formData, handleSubmit, updateFormData, data = {} } = props;
  //const labelName = getLabelName(modalType);
  formData.textBoxValue = props.selectedValue ? props.selectedValue : formData.textBoxValue;
  const [formDataObj, setFormDataObj] = useState(formData);
  const dispatch = useDispatch();
  return (
    <Form
      layout='vertical'
      className='login-form'
      onSubmit={(e) => {
        e.preventDefault();
        if (formDataObj.textBoxValue && formDataObj.textBoxValue.length) {
          handleSubmit({ textBoxValue: formDataObj.textBoxValue, statusColor: formDataObj.statusColor });
        } else {
          setFormDataObj({ submittedOnce: true, textBoxValue: formDataObj.textBoxValue, statusColor: formDataObj.statusColor });
        }
      }}
    >
      <Row>
        <Col span={9} className='padding-top-3'>
          <span className="text-input-label">
            Status Name
            <span className="required">*</span>
          </span>
        </Col>
        <Col span={12}>
          <TextBox
          style={{height:'38px',paddingRight:'0px'}}
            type='text'
            countStyle = {{right: '5px', top: '11px' }}
            value={formDataObj.textBoxValue}
            maxLength={formDataObj.maxLength || (data.formData || {}).maxLength || 20}
            onChange={e => {
              updateFormData && updateFormData({ ...formDataObj, textBoxValue: e.target.value, submittedOnce: true });
              setFormDataObj({ ...formDataObj, textBoxValue: e.target.value, submittedOnce: true });
              dispatch({
                type: MODAL_ACTIONS.MODAL_FORM_DATA_UPDATE,
                data: { formData: { textBoxValue: e.target.value } }
              })
            }}
            className={
              formDataObj.submittedOnce && (!formDataObj.textBoxValue || !formDataObj.textBoxValue.length) ? 'input-text-error add-new-input-text' : 'add-new-input-text'
            }
          />
          <ErrorMsg
            validator={() => { return !formDataObj.submittedOnce || !!formDataObj.textBoxValue }}
            message={<FormattedMessage
              id='addItem.validation'
              defaultMessage=''
              values={{ value: 'Status Name' }}
            />}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={9} className='padding-top-3'>
          <span className="text-input-label">
            Status Color
          </span>
        </Col>
        {/* <Col span={12} className='user-status'>
          <ColorPicker
            style={{ width: '240px', height: '35px'}}
            placement='bottomLeft'
            enableAlpha={false}
            color={formDataObj.statusColor || '#78C1ED'}
            onChange={(obj) => {
              updateFormData && updateFormData({ ...formDataObj, statusColor: obj.color, submittedOnce: true });
              setFormDataObj({ ...formDataObj, statusColor: obj.color, submittedOnce: true });
            }}
            mode="RGB"
          />
        </Col> */}
         <Col span={12} style={{height:"292px"}}>
          <ColorPickerPanel
            // style={{ width: '240px', height: '35px'}}
            placement='bottomLeft'
            enableAlpha={false}
            color={formDataObj.statusColor || '#78C1ED'}
            onChange={(obj) => {
              updateFormData && updateFormData({ ...formDataObj, statusColor: obj.color, submittedOnce: true });
              setFormDataObj({ ...formDataObj, statusColor: obj.color, submittedOnce: true });
            }}
            mode="RGB"
          />
        </Col>
      </Row>
      {/* <CustomButton
        htmlType='submit'
        style={{ display: 'none' }}
      /> */}

      <br></br>
      <Row>
        <Col span={21}>
          <CustomButton
            intlId={'modal.contact.button.cancel'}
            defaultMessage={''}
            type={'default'}
            key={1}
            onClick={() => { dispatch({ type: MODAL_ACTIONS.POP_STACK_MODAL }); }}
          /></Col>
        <Col span={3}>
          <CustomButton className="right-pull"
            intlId={'button.save.label'}
            defaultMessage={''}
            htmlType='submit'
            key={2}
          />
        </Col>
      </Row>

    </Form>
  )
}

