import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EcomDetailComponent from '../../../../../components/modal/modalBody/customer/EcomDetail';
import { fetchEcomDetail,isPreOrderConvertable } from './action';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import { fetchSalesOrders, getSalesOrderCount, deleteSalesOrder, updateQualityCheckList, updateUserStatus } from '../../../../customer/salesOrder/Listing/action';
import { getQualityChecklistSettings, deleteAttectedFile } from '../../../../../actions/commonActions';
import { fetchDataIfNeeded } from '../../../../../utils';

class EcomDetail extends Component {

    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
            isConvertable:false,
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.soPayload.customerId, salesOrderMasterId: this.props.soPayload.salesOrderMasterId ,customerPreOrderMasterId: this.props.soPayload.customerPreOrderMasterId};
           
        // this.props.resetSODetails();
        // this.props.fetchSODetail(payload);
   

        this.props.fetchEcomDetail(payload)
        const callback = (value)=>{
            this.setState({
                isConvertable:value,
            })
        }
        this.props.isPreOrderConvertable(payload,callback)
        fetchDataIfNeeded('getQualityChecklistSettings', 'qualityChecklistSetting', this.props, payload);
    }

    render() {
        return <div>
            <EcomDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        ecomDetail: state.so.ecomDetail,
        userInfo: state.common.userInfo,
        permissions: state.auth.permissions,
        qualityChecklistSetting: state.settings.qualityChecklistSetting,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchEcomDetail,
    isPreOrderConvertable,
    downloadFileFromBucket,
    generatePDF, 
    printPDF, 
    fetchSalesOrders,
    getSalesOrderCount,
    deleteSalesOrder,
    updateQualityCheckList,
    getQualityChecklistSettings,
    updateUserStatus,
    deleteAttectedFile
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EcomDetail);