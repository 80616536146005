import React from 'react';
import { AgGridReact } from 'ag-grid-react';
//import { Tooltip, Pagination } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import DetailInfo from './detailsInfo';
import { exportExcel, showToasterMessage } from '../../../../../utils';
import { Menu } from 'antd';
import {FileExcelOutlined, DownloadOutlined} from '@ant-design/icons';
import DropdownAction from "antd/lib/dropdown";

const AgedCreditorsComponent = (props) => {
    //const { companyInfo } = props;
    const downloadMenu = (data) => {
        return <Menu className="row-action-dropdown">
          <Menu.Item key="0" value="title">
            Download as
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="1" value="excel" onClick={()=>{
            const ledgerList = [];
            props.agedCreditorsList.forEach((obj) => {
              ledgerList.push({
                "Party Name": obj.companyName,
                "Total Receivable": Number(obj.pastDueTotalAmount).toFixed(2),
                "Current Due": Number(obj.currentDueTotalAmount).toFixed(2),
                "Last 30 Days": Number(obj.pastDue1To30DaysAmount).toFixed(2),
                "30 To 60 Days": Number(obj.pastDue31To60DaysAmount).toFixed(2),
                "60 To 90 Days": Number(obj.pastDue61To90DaysOverAmount).toFixed(2),
                "Above 90 Days": Number(obj.pastDue91DaysOverAmount).toFixed(2),
              });
            });
            if (ledgerList.length) {
              exportExcel(ledgerList, "Aging-of-Account-Payable");
            } else {
              showToasterMessage({
                messageType: "error",
                description:
                  "No record selected. Please select the record(s) before proceeding.",
              });
            }
          }}>
            <FileExcelOutlined />
            Excel File
          </Menu.Item>
        </Menu>;
      };

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    // const getRowHeight = () => {
    //     return 35;
    // }

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    const openDetailsInfo = (list, params) => {
        if (!params.value) return;
        props.pushModalToStack({
            modalBody: <DetailInfo {...props} detailsList={list} />,
            title: 'Supplier',
            width: '75%',
            hideFooter: true,
           // wrapClassName: 'modal-custom-detail'
        })
    }

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='reports.agedDebtor.header.companyName' defaultMessage='' />;
            },
            field: "supplierStoreName",
            //cellRendererFramework: (link) => <div>{link.value}</div>,
            rowDrag: true,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='reports.agedDebtor.header.totalReceivable' defaultMessage='' />;
            },
            field: "pastDueTotalAmount",
            valueFormatter: (data) => {
                data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                return data.value;
            },
            cellRendererFramework: (params) => {
                return <div> <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} {params.value.toFixed(2)}</span> </div>
            },
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {

            headerComponentFramework: () => {
                return <div><FormattedMessage id='reports.partyLedger.currentDue' defaultMessage='' /> </div>;
            },
            field: "currentDueTotalAmount",
            valueFormatter: (data) => {
                data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                return data.value;
            },
            cellRendererFramework: (params) => {
                return <div className={params.value ? "cursor-pointer" : ""} onClick={() => {
                    openDetailsInfo(params.data.currentDueTotalInvoiceIdList, params);
                }}> <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} {params.value.toFixed(2)}</span> </div>
            },
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='reports.agedDebtor.header.pastDue.Last30Days' defaultMessage='' />;
            },
            field: "pastDue1To30DaysAmount",
            valueFormatter: (data) => {
                data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                return data.value;
            },
            cellRendererFramework: (params) => {
                return <div className={params.value ? "cursor-pointer" : ""} onClick={() => {
                    openDetailsInfo(params.data.pastDue1To30DaysInvoiceIdList, params);
                }}> <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} {params.value.toFixed(2)}</span> </div>
            },
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {

            headerComponentFramework: () => {
                return <div><FormattedMessage id='reports.agedDebtor.header.pastDue.30To60Days' defaultMessage='' /> </div>;
            },
            field: "pastDue31To60DaysAmount",
            valueFormatter: (data) => {
                data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                return data.value;
            },
            cellRendererFramework: (params) => {
                return <div className={params.value ? "cursor-pointer" : ""} onClick={() => {
                    openDetailsInfo(params.data.pastDue31To60DaysInvoiceIdList, params);
                }}> <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} {params.value.toFixed(2)}</span> </div>
            },
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {

            headerComponentFramework: () => {
                return <div><FormattedMessage id='reports.agedDebtor.header.pastDue.60To90Days' defaultMessage='' /> </div>;
            },
            field: "pastDue61To90DaysOverAmount",
            valueFormatter: (data) => {
                data.value = data.value ? (data.value || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                return data.value;
            },
            cellRendererFramework: (params) => {
                return <div className={params.value ? "cursor-pointer" : ""} onClick={() => {
                    openDetailsInfo(params.data.pastDue61To90DaysOverInvoiceIdList, params);
                }}> <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} {(params.value || 0).toFixed(2)}</span> </div>
            },
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {

            headerComponentFramework: () => {
                return <div><FormattedMessage id='reports.agedDebtor.header.pastDue.above90Days' defaultMessage='' /> </div>;
            },
            field: "pastDue91DaysOverAmount",
            valueFormatter: (data) => {
                data.value = data.value ? data.value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                return data.value;
            },
            cellRendererFramework: (params) => {
                return <div className={params.value ? "cursor-pointer" : ""} onClick={() => {
                    openDetailsInfo(params.data.pastDue91DaysOverInvoiceIdList, params);
                }}> <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "} {(params.value || 0).toFixed(2)}</span> </div>
            },
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
    ];

    // const loadPageData = (pageNumber, pageSize) => {
    //     const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
    //     fetchPaginationDataIfNeeded('fetchOpenOrders', 'openOrderList', props, payload);
    // }

    // const handlePageSizeChange = (pageNumber, pageSize, props) => {
    //     const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
    //     props.resetOpenOrderPaginatedData();
    //     props.fetchOpenOrders(payload);
    //     props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    // }

    // const showTotal = (total) => {
    //     return props.intl.formatMessage({id: 'pagination.text.total', defaultMessage: 'Total'}) + ` ${ total } `+ props.intl.formatMessage({id: 'pagination.text.items', defaultMessage: 'items'});
    // };

    return (
        <div className='agGridWrapper'>
             <div className="right-actions download-btn">
                <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
                    <span>
                        <DownloadOutlined />&nbsp;
                        <FormattedMessage
                            id="common.action.dropdown.downloadAs"
                            defaultMessage=""
                        />
                    </span>
                </DropdownAction>
            </div>
            <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={props.agedCreditorsList || []}
                    rowDragManaged={true}
                    domLayout={"autoHeight"}
                    animateRows={true}
                    onColumnResized={(params) => {
                        params.api.resetRowHeights();
                    }}
                    defaultColDef={{
                        flex: 1,
                        autoHeight: true,
                        wrapText: true,
                    }}
                    //getRowHeight={getRowHeight}
                    onGridSizeChanged={onFirstDataRendered}
                >
                </AgGridReact>
                {/* <Pagination
                    size="small"
                    total={openOrderListCount}
                    showTotal={showTotal}
                    defaultPageSize={100}  showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                    showQuickJumper
                    onChange={(pageNumber, pageSize) => {
                        loadPageData(pageNumber, pageSize);
                        updateState({ pageSize: pageSize, pageNumber: pageNumber })
                    }}
                    onShowSizeChange={(pageNumber, pageSize) => {
                        handlePageSizeChange(pageNumber, pageSize, props)
                    }}
                /> */}
            </div>
        </div>
    );
};

export default injectIntl(AgedCreditorsComponent);
