import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, SO_ACTION_LIST, SQ_ACTION_LIST } from '../../../../static/constants';
import { showToasterMessage, getFinancialYear } from '../../../../utils';
import { getCurrentDateForBackend, formatDateForBackend } from '../../../../utils';
import { LEDGER_TYPE, ACCOUNTING_ENTRY_TYPE, TX_STATUS } from "../../../../static/constants";
import { resetPaginatedData, getSalesOrderCount, getSalesOrderDraftCount, resetDraftPaginatedData } from '../Listing/action';
import * as filter from 'lodash.filter';
import salesOrder from '../../../../components/customer/salesOrder/Listing/salesOrder';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const fetchAllSalesOrderSources = (payload, history) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/soSource/getAllSOSources?relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SO_SOURCES, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = SO_ACTION_LIST.SO_SOURCES;
        }
      })
  }
}

export const deleteSalesOrderSources = (payload) => {
  const requestObj = {
    soSourceId: payload.id,
    relationshipId: payload.relationshipId,
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/soSource/deleteSOSource`, requestObj)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SO_SOURCES_DELETION, data: { id: payload.id } })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = SO_ACTION_LIST.SO_SOURCES_DELETION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const addSalesOrderSources = (payload) => {
  const requestObj = {
    relationshipId: payload.relationshipId,
    soSourceName: payload.text,
    //dateCreated: getCurrentDateForBackend(new Date()),
    userCreated: undefined
  }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/soSource/saveSOSource`, requestObj)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({ type: SO_ACTION_LIST.SO_SOURCES_ADDITION, data: res.data })
        showToasterMessage({
          description: (res || {}).message || 'Created successfully.',
          messageType: 'success'
        })
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (err.__isRetryRequest) {
          err.actionToCall = SO_ACTION_LIST.SO_SOURCES_ADDITION;
        } else {
          showToasterMessage({
            description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
          })
        }
      })
  }
}

export const createSalesOrder = (payload, history) => {
  return dispatch => {
    const props = payload.props;
   
    const saleOrder = _getSoData(payload);
    let formData = new FormData();
    if (props.fileList && props.fileList.length) {
      props.fileList.forEach((file) => {
        formData.append('files', file, file.name);
      })
    }
    if (props.qualityCheckList && props.qualityCheckList.length) {
      props.qualityCheckList.forEach((quality, qualityIndex) => {
        const documentsToUpdate = [];
        if (quality.documentDetails && quality.documentDetails.length) {
          quality.documentDetails.forEach((document) => {
            if (document.docDetailsId) {
              documentsToUpdate.push(document);
            } else {
              formData.append('documents', document, qualityIndex + "," + document.name);
            }
          });
        }
        quality.documentDetails = documentsToUpdate;
      });
    }
    formData.append('saleOrder', JSON.stringify(saleOrder, true));

    formData.append('bucketName' ,props.companyInfo?.bucketName)
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });

    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/createSalesOrder`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Sales order created successfully' })
        resetPaginatedData()(dispatch);
        props.popModalFromStack();
        getSalesOrderCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        resetDraftPaginatedData()(dispatch);
        getSalesOrderDraftCount({ relationshipId: props.companyInfo.relationshipId })(dispatch);
        props.openSoDetails(res.data);
        props.fetchSalesOrders(props);
        props.history.push('/admin/so-list');
    
        if (props.isSQConvertions) {
          dispatch({ type: SQ_ACTION_LIST.SQ_LIST_PAGE_RESET });
          props.history.push('/admin/sq-list')
        }
      })
      .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}


const _getSoData = (payloadObj) => {
 
  const props = payloadObj.props;
  const state = payloadObj.state;
  const agGridObj = payloadObj.agGridObj;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  const isPlaceOfSupplySameAsRelationship = payloadObj.isPlaceOfSupplySameAsRelationship;
  const oldPayload = props.update ? props.oldPayload || {} : {};
  const payload = {
    ...oldPayload,
    version: props.update ? props.version : 0,
    salesOrderDraftId: props.salesOrderDraftId || 0,
    customerRFQNumber: props.customerRFQNumber || '',
    customerInquiryNumber: props.customerInquiryNumber || '',
    salesOrderMasterId: props.salesOrderMasterId || 0,
    customerName: props.customer && props.customer.companyName,
   
    soSourceId: props.soSourceId,
    salesQuotationMasterId: props.salesQuotationMasterId,
    quotationNumber: props.quotationNumber,
    rfqNumber: props.rfqNumber,
    rfqMasterId: props.rfqMasterId,
    soSourceName: props.soSourceName,
    projectName: props.projectName,
    projectMasterId: props.projectMasterId,
    projectNumber: props.projectNumber,
    relationshipId: props.companyInfo.relationshipId,
    relationshipName: props.companyInfo.storeName,
    salesPersonId: props.salesPersonId,
    soPersonFullName: props.salesPersonName,
    customerId: props.customer && props.customer.customerId,
    orderPriority: props.priority,
    expenseId: props.expenseId,
    isQuoteConversion: (props.isSQConvertions && !props.isClone) ? 1 : 0,
    salesOrderDate: formatDateForBackend(props.salesOrderDate),
    deliveryDate: props.deliveryDate && formatDateForBackend(props.deliveryDate),
    referenceNumber: props.referenceNumber,
    placeOfSupply: props.placeOfSupply,
    customerPONumber: props.customerPO,
    paymentTermId: props.paymentTermId,
    paymentTermName: props.paymentTermName,
    footer: props.footer,
    remarksInternal: props.remarksInternal,
    docDetailList: props.soEditDetail?.docDetailList,
    remarksCustomer: props.remarksCustomer,
    status: TX_STATUS.OPEN,
    salesOrderTotalAmount: Number(state.total),
    subTotal: Number(state.subtotalWithoutDiscount),
    boLocationSOList: [],
    customerTxSalesOrderList: [],
    customerSalesOrderDetailsList: [],
    customerSalesOrderCOATxList: [],
    documentName: props.docName,
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    currencyCode: companyInfo.currencyCode,
    inctermId: props.inctermId,
    incotermName: props.incotermName,
    customerPreOrderMasterId: props.customerPreOrderMasterId,
  }
  payload.customerSalesOrderCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    customerId: props.customer && props.customer.customerId,
    createdBy: userInfo.userId,
    amount: Number(state.subtotalWithoutDiscount || 0),
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_SALES,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
    ledgerAccountId: props.salesLedgerId,
    customerName: props.customer && props.customer.companyName
  });
  payload.customerSalesOrderCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
    customerId: props.customer && props.customer.customerId,
    createdBy: userInfo.userId,
    amount: Number(state.subtotalWithoutDiscount || 0),
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_SALES,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
    ledgerAccountId: props.salesDebtorLedgerId,
    customerName: props.customer && props.customer.companyName
  });

  if(state.totalCouponDiscount > 0){
    payload.customerSalesOrderCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      customerId: props.customer && props.customer.customerId,
      createdBy: userInfo.userId,
      amount: Number(state.totalCouponDiscount || 0),
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_COUPON,
      taxName:props.coupanValue,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
      ledgerAccountId: props?.couponLedgerId || props.expenseLedgerId,
      amountPercent:props.coupondata?.discountPercentage,
      customerName: props.customer && props.customer.companyName
    });
    payload.customerSalesOrderCOATxList.push({
      relationshipId: companyInfo.relationshipId,
      relationshipName: companyInfo.storeName,
      fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
      fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
      customerId: props.customer && props.customer.customerId,
      createdBy: userInfo.userId,
      amount: Number(state.totalCouponDiscount || 0),
      txDate: formatDateForBackend(new Date()),
      txType: LEDGER_TYPE.TYPE_SALES,
      taxName:props.coupanValue,
      accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
      amountPercent:props.coupondata?.discountPercentage,
      ledgerAccountId: props?.couponDebtorLedgerId || -1,
      customerName: props.customer && props.customer.companyName,
    });
  }
  state.rootDiscountList.forEach((discountObj, i) => {
    if (discountObj.rootDiscountAmount) {
      payload.customerSalesOrderCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        isTaxable:discountObj.isTaxable,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:financialYear.fyEndDate &&getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        createdBy: userInfo.userId,
        amount: discountObj.rootDiscountAmount,
        amountPercent: discountObj.rootDiscountPercent,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: discountObj.chartOfAccountDetailsId ?discountObj.chartOfAccountDetailsId:props.salesDiscountLedgerId|| -1,
        supplierName: props.supplier && props.supplier.name,
        taxPercent: discountObj.taxPercent,
        taxAmount: discountObj.taxAmount,
        taxName: discountObj.taxName,
        taxId: discountObj.key,
      });

      payload.customerSalesOrderCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        createdBy: userInfo.userId,
        isTaxable:discountObj.isTaxable,
        amount: discountObj.rootDiscountAmount,
        amountPercent: discountObj.rootDiscountPercent,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.discountDebtorLedgerId|| -1,
        supplierName: props.supplier && props.supplier.name,
      });
    }
  });
  state.rootExpenseList.forEach((expenseObj, i) => {
    if (expenseObj.expenseAmount) {
      payload.customerSalesOrderCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        createdBy: userInfo.userId,
        amount: expenseObj.expenseAmount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_EXPENSE,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        supplierName: props.supplier && props.supplier.name,
        ledgerAccountId: expenseObj.chartOfAccountDetailsId?expenseObj.chartOfAccountDetailsId:props.expenseLedgerId,
        ledgerAccountName: expenseObj.ledgerAccountName,
        taxId: (expenseObj.selectedExpenseLedger || {}).taxSingleRatePercent,
        taxRate: (expenseObj.selectedExpenseLedger || {}).taxSingleRateId,
      });

      payload.customerSalesOrderCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        createdBy: userInfo.userId,
        amount: expenseObj.expenseAmount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_EXPENSE,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        supplierName: props.supplier && props.supplier.name,

        ledgerAccountId:props.expenseDebtorLedgerId,
        ledgerAccountName: expenseObj.ledgerAccountName,
        taxId: (expenseObj.selectedExpenseLedger || {}).taxSingleRatePercent,
        taxRate: (expenseObj.selectedExpenseLedger || {}).taxSingleRateId,
      });
    }
  });
  // if (state.rootDiscountAmount) {
  //   payload.customerSalesOrderCOATxList.push({
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipName: companyInfo.storeName,
  //     fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //     fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //     customerId: props.customer && props.customer.customerId,
  //     //dateCreated: getCurrentDateForBackend(new Date()),
  //     createdBy: userInfo.userId,
  //     amount: state.rootDiscountAmount,
  //     amountPercent: state.rootDiscountPercent,
  //     txDate: formatDateForBackend(new Date()),
  //     txType: LEDGER_TYPE.TYPE_DISCOUNT,
  //     ledgerAccountId: props.discountDebtorLedgerId,
  //     accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
  //     customerName: props.customer && props.customer.companyName
  //   });

  //   payload.customerSalesOrderCOATxList.push({
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipName: companyInfo.storeName,
  //     fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //     fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //     customerId: props.customer && props.customer.customerId,
  //     //dateCreated: getCurrentDateForBackend(new Date()),
  //     createdBy: userInfo.userId,
  //     amount: state.rootDiscountAmount,
  //     amountPercent: state.rootDiscountPercent,
  //     txDate: formatDateForBackend(new Date()),
  //     txType: LEDGER_TYPE.TYPE_DISCOUNT,
  //     accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
  //     ledgerAccountId: props.salesDiscountLedgerId,
  //     customerName: props.customer && props.customer.companyName
  //   });
  // }

  // if (state.expenseAmount) {
  //   payload.customerSalesOrderCOATxList.push({
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipName: companyInfo.storeName,
  //     fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //     fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //     customerId: props.customer && props.customer.customerId,
  //     //dateCreated: getCurrentDateForBackend(new Date()),
  //     createdBy: userInfo.userId,
  //     amount: state.expenseAmount,
  //     txDate: formatDateForBackend(new Date()),
  //     txType: LEDGER_TYPE.TYPE_EXPENSE,
  //     accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
  //     ledgerAccountId: props.expenseLedgerId,
  //     customerName: props.customer && props.customer.companyName
  //   });

  //   payload.customerSalesOrderCOATxList.push({
  //     relationshipId: companyInfo.relationshipId,
  //     relationshipName: companyInfo.storeName,
  //     fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
  //     fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
  //     customerId: props.customer && props.customer.customerId,
  //     //dateCreated: getCurrentDateForBackend(new Date()),
  //     createdBy: userInfo.userId,
  //     amount: state.expenseAmount,
  //     txDate: formatDateForBackend(new Date()),
  //     txType: LEDGER_TYPE.TYPE_EXPENSE,
  //     accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
  //     ledgerAccountId: props.expenseDebtorLedgerId,
  //     customerName: props.customer && props.customer.companyName
  //   });
  // }

  if (props.billingAddress && props.billingAddress.streetAddress1) {
    if (!props.update) {
      delete props.billingAddress.locationSOId;
      delete props.billingAddress.version;
      //props.billingAddress.dateCreated = getCurrentDateForBackend(new Date());
      // props.billingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      //props.billingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    payload.boLocationSOList.push(props.billingAddress);
  } if (props.shippingAddress && props.shippingAddress.streetAddress1) {
    if (!props.update) {
      delete props.shippingAddress.locationSOId;
      delete props.shippingAddress.version;
      // props.shippingAddress.dateCreated = getCurrentDateForBackend(new Date());
      //props.shippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      // props.shippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    payload.boLocationSOList.push(props.shippingAddress);
  } if (props.relationshipBillingAddress && props.relationshipBillingAddress.streetAddress1) {
    if (!props.update) {
      delete props.relationshipBillingAddress.locationSOId;
      delete props.relationshipBillingAddress.version;
      // props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
      // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      // props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    payload.boLocationSOList.push(props.relationshipBillingAddress);
  }

  agGridObj.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    if (!data.itemId && !data.description && !data.qty && !data.rate) {
      return
    }
    //if (!itemId) return;
    let variantId = data.selectedProductValue && data.selectedProductValue.length > 1 ? data.selectedProductValue[1] : 0;
    let variantStockId = data.selectedProductValue && data.selectedProductValue.length === 3 ? data.selectedProductValue[2] : 0;
    if (data.productObj && !data.productObj.hasVariant && !variantId) {
      variantId = data.productObj.inventoryItemVariantList ? data.productObj.inventoryItemVariantList[0].variantId : data.inventoryItemVariantId;
    }
    let product = {};
    let itemLevelCoaTxList = [];
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }

    let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);

    
    if (data.discount) {
      const discountOnItem = itemLevelAmount ? ((data.discount * 0.01) * itemLevelAmount) : 0;
      itemLevelAmount = itemLevelAmount - discountOnItem;
      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.salesDiscountLedgerId,
        customerName: props.customer && props.customer.companyName
      });

      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
        customerId: props.customer && props.customer.customerId,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.discountDebtorLedgerId,
        customerName: props.customer && props.customer.companyName
      })
    }

    if (data.tax) {

      let coupDiff = 0;
      if(Object.keys(state.coupondata || {})?.length && props?.couponSetting?.isCoupanAppliedBeforeTax){
        if(state.coupondata.couponType === "PERCENTAGE"){
          let discountPercent = state.coupondata.discountPercentage;
           coupDiff = Number(discountPercent) * 0.01 * itemLevelAmount;

          // taxObj.amountAfterCoupan = itemLevelCoupAmount;
          // totalCouponDiscount = totalCouponDiscount + itemLevelCoupAmount;
        }
      }
      if (companyInfo.countryName !== 'India') {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
         
          amount: (itemLevelAmount ? ((data.tax * 0.01) * (coupDiff>0 ?coupDiff: itemLevelAmount)).toFixed(2) : 0),
          // amount: (coupDiff > 0 ?   coupDiff.toFixed(2) :   itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount).toFixed(2) : 0),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: data.taxName,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.salesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          // amount: (coupDiff > 0 ?coupDiff.toFixed(2) :   itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount).toFixed(2) : 0),
          amount: (itemLevelAmount ? ((data.tax * 0.01) * (coupDiff>0 ?coupDiff: itemLevelAmount)).toFixed(2) : 0),

          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: data.taxName,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.taxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });
      }
      else if (isPlaceOfSupplySameAsRelationship) {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) / 2 : 0).toFixed(2),
          amountPercent: (data.tax) / 2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.cgstSalesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) / 2 : 0).toFixed(2),
          amountPercent: (data.tax) / 2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.cgstTaxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });


        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) / 2 : 0).toFixed(2),
          amountPercent: (data.tax) / 2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.sgstSalesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? (((data.tax) * 0.01) * itemLevelAmount) / 2 : 0).toFixed(2),
          amountPercent: (data.tax) / 2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.sgstTaxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });

      } else {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * (coupDiff>0 ?coupDiff: itemLevelAmount)).toFixed(2) : 0),
        
          // amount: (coupDiff > 0 ?coupDiff.toFixed(2) :   itemLevelAmount ? ((data.tax * 0.01) * itemLevelAmount).toFixed(2) : 0),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.igstSalesOutputTaxLedgerId,
          customerName: props.customer && props.customer.companyName
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate: financialYear.fyStartDate && getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate: financialYear.fyEndDate && getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount ? ((data.tax * 0.01) * (coupDiff>0 ?coupDiff: itemLevelAmount)).toFixed(2) : 0),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          ledgerAccountId: props.igstTaxDebtorLedgerId,
          customerName: props.customer && props.customer.companyName
        });
      }
    }

    payload.customerSalesOrderDetailsList.push({
      parentDetailsId: data.parentDetailsId,
      version: props.update ? data.version : 0,
      relationshipId: companyInfo && companyInfo.relationshipId,
      customerId: props.customer && props.customer.customerId,
      quantityToInvoice: Number(data.qty),
      quantity: Number(data.qty),
      quantityToPack: Number(data.qty),
      qtyBeforeUpdate: Number(data.qtyBeforeUpdate || 0),
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      basePrice: Number(data.baseRate),
      specialDiscount: data.specialDiscount,
      txDate: formatDateForBackend(props.salesOrderDate),
      salesOrderDate: formatDateForBackend(props.salesOrderDate),
      discountPercentage: data.discount,
      itemName: product.itemName,
      itemId: product.itemId,
      anItemSalePrice: Number(data.rate),
      rowHeight: data.scrollHeight,
      description: data.description,
      hsnCode: data.hsnCode,
      inventoryItemVariantId: variantId,
      customerSalesOrderCOATxList: itemLevelCoaTxList,
      warehouse: data.comment,
      stockNumber: data.stockNumber,
      partNumber: data.partNumber,
      materialNumber: data.materialNumber,
      origin: data.origin,
      hsCode: data.hsCode,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      salesQuotationMasterId: props.salesQuotationMasterId,
      quotationNumber: props.quotationNumber,
      salesOrderDetailsId: data.salesOrderDetailsId,
      salesOrderMasterId: data.salesOrderMasterId,
      itemVariantStockId: variantStockId
    });
  });
  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact }
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    // additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    // additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.customer) {
    additionalInfo.customerId = props.customer && props.customer.customerId;
    additionalInfo.customerPhone = props.customer && props.customer.customerPhone;
    additionalInfo.customerEmail = props.customer && props.customer.customerEmail;
    // additionalInfo.gstNumber = props.customer.gstNumber;
    // additionalInfo.panNumber = props.customer.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
    additionalInfo.additionalInfoId = props.isSQConvertions ? 0 : additionalInfo.additionalInfoId;
  }

  if (props.selectedCustomerTaxIdentifications && props.selectedCustomerTaxIdentifications.length) {
    let selectedCustomerTaxIdentifications = filter(props.customer.customerTaxIdentificationsList, (taxObj) => {
      return props.selectedCustomerTaxIdentifications.indexOf(taxObj.customerTaxIdentificationId) > -1;
    });
    additionalInfo.taxIdentifications = JSON.stringify(selectedCustomerTaxIdentifications);
  }

  if (props.selectedRelationshipTaxIdentifications && props.selectedRelationshipTaxIdentifications.length) {
    let selectedRelTaxIdentifications = filter(props.allRelationshipTaxIdentificatins, (taxObj) => {
      return props.selectedRelationshipTaxIdentifications.indexOf(taxObj.relationshipTaxIdentificationId) > -1;
    });
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(selectedRelTaxIdentifications);
  }
  if (!props.update) delete additionalInfo.additionalInfoId;
  payload.additionalInfoList = [additionalInfo];


  if (props.qualityCheckList && props.qualityCheckList.length) {
    let checkList = [];
    props.qualityCheckList.forEach((obj, index) => {
      obj.customerId = props.customer && props.customer.customerId;
      obj.sno = index;
      obj.relationshipId = companyInfo && companyInfo.relationshipId;
      if (!props.update) delete obj.qualityChecklistSOId;
      checkList.push(obj);
    });
    payload.qualityCheckList = checkList;
  }
  return payload;
}


export const fetchSQDataForSO = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesQuotation/getQuotationForSO?customerId=${payload.customerId}&relationshipId=${payload.relationshipId}&salesQuotationMasterId=${payload.salesQuotationMasterId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.SQ_SO_DATA, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.SQ_SO_DATA;
        }
      })
  }
}

export const resetSalesQuoteSOData = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.RESET_SQ_SO_DATA });
  }
}

export const fetchSOData = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/getSalesOrderData?salesOrderMasterId=${payload.salesOrderMasterId}&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SO_ACTION_LIST.SO_DETAIL, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.SO_DETAIL;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}


export const resetSODetails = () => {
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.RESET_SO_DATA });
  }
}

export const getNextTxNumber = (payload) => {
  let date = payload.date ? getCurrentDateForBackend(payload.date) : getCurrentDateForBackend(new Date());
  return dispatch => {
    dispatch({ type: SO_ACTION_LIST.NEXT_SO_NUMBER_RESET });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/getNextSONumber?date=${date}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        dispatch({ type: SO_ACTION_LIST.NEXT_SO_NUMBER, data: res.data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.NEXT_SO_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  }
}

export const createSalesOrderDraft = (payload, history) => {
  const props = payload.props;
  return dispatch => {
    const saleOrder = _getSoData(payload);
    const data = {
      salesOrderDraftId: props.salesOrderDraftId || 0,
      salesOrderData: JSON.stringify(saleOrder),
      relationshipId: saleOrder.relationshipId,
      customerId: saleOrder.customerId
    }

    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/saveOrUpdateSODraft`, data)
      .then(res => {
        showToasterMessage({ messageType: 'success', description: 'Sales order saved in draft' })
       
        props.history.push({
          pathname: '/admin/so-list',
          state: { activeTab: 'Draft' }
        })
        props.popModalFromStack()
      })
      .catch((err) => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' }) 
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const deleteDocument = (payload,props) => {
  return dispatch => {
    let formData = new FormData();
    formData.append("relationshipId", payload.relationshipId);
    formData.append('bucketName', props.companyInfo.bucketName);
    formData.append('objectKey', payload.awsKeyName);
    formData.append('folderName', payload.folderName);
    formData.append('salesOrderId', payload.soMasterId);
   
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/deleteSalesOrderDocument`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchSOData(props);
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}