import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip, Popover, Switch,Empty } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, sortTable } from '../../../../utils';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
//import PageBreadcrumb from '../../../PageBreadcrumb';
// import { AG_GRID_CLASS_CONSTANTS } from '../../../../static/cssClassConstants'
import {
    CONSTANTS,
    CONSTANTS_TRANSACTIONS,
    MODAL_TYPE
} from '../../../../static/constants';
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
// import { AG_GRID_CONSTANTS } from '../../../../static/agGridConstants';
import InquiryDetail from '../../../../containers/modal/modalBody/customer/InquiryDetail';
// import find from 'lodash.find';
import { FilterOutlined, InsertRowRightOutlined } from '@ant-design/icons';
// import { Dropdown } from '../../../general/Dropdown';
// import { TextBox } from '../../../general/TextBox';

// const { RangePicker } = DatePicker;
// const { Panel } = Collapse;
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'

const SalesEnquiryListingComp = (props) => {
    const { enquiryList, updateState, companyInfo, enquiryCount, pageNumber, pageSize, dir = 0 } = props;
    // const [collapseActiveKey, setCollapseActiveKey] = useState('');
    // const getRowHeight = (params) => {
    //     let height = 35;
    //     return height;
    // };



    // const getActionMenuPermissionData = () => {
    //     const primaryPerm = permissions.primary || [];
    //     const permittedModules = {
    //         firstSection: {
    //             updateSalesOrder: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER, PERMISSION_VALUES.UPDATE),
    //             cloneSalesOrder: checkACLPermission(primaryPerm, MODULE_CODED_VALUES.SALES_ORDER, PERMISSION_VALUES.CREATE)
    //         }
    //     };
    //     Object.keys(permittedModules.firstSection).forEach((moduleName) => {
    //         if (permittedModules.firstSection[moduleName] === false) {
    //             delete permittedModules.firstSection[moduleName];
    //         }
    //     })
    //     return permittedModules;
    // }

    // const openAuditDrawer = (props, data) => {
    //     props.updateState({
    //         title: <div className="drawer-title">
    //             Audit Trail
    //         </div>,
    //         auditDrawerVisible: true,
    //         deletApiUrl: 'deleteAuditTrailInquiry',
    //         masterKey: 'inquiryMasterId',
    //         masterKeyValue: data.inquiryMasterId,
    //         apiUrl: 'saveAuditTrailInquiry',
    //         customerId: data.customerId,
    //         trailList: data.auditTrailInquiryList,
    //         updateTrailListInTransaction: (list) =>{
    //             data.auditTrailInquiryList = list;
    //         } 
    //     });
    // }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        // fetchPaginationDataIfNeeded('fetchSalesEnquiry', 'enquiryList', props, {...payload,isActive:1});
        props.fetchSalesEnquiry({ ...payload, isActive: 1 });
    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchSalesEnquiry({ ...payload, isActive: 1 });
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }



    // const saveColumnIndex = (columnState) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data);
    // }

    // const saveColumnWidth = (columnState, width) => {
    //     let data = props.txPreferences || {};
    //     let tablePreference = {};
    //     data.relationshipId = (props.companyInfo || {}).relationshipId;
    //     data.clientUserAccountId = (props.userInfo || {}).userId;
    //     if (data.tablePreference) {
    //         tablePreference = JSON.parse(data.tablePreference);
    //     }
    //     tablePreference.columnSetting = columnState;
    //     tablePreference.columnList = props.txColumns;
    //     data.tablePreference = JSON.stringify(tablePreference);
    //     data.preferenceId = props.preferenceId;
    //     data.gridPreference = props.gridPreference;
    //     props.saveUserPrefrences(data, props);
    // }

    // let moveEvent = {};
    // const onColumnMoved = (event, isDragStopped) => {
    //     if (event.column) {
    //         moveEvent = event;
    //     }
    //     if (isDragStopped && moveEvent.column && moveEvent.column.colDef && moveEvent.source !== AG_GRID_CONSTANTS.EVENT_SOURCE.API) {
    //         saveColumnIndex(moveEvent.columnApi.getColumnState());
    //     }
    // }

    // const onColumnResized = (event, tablegridApi, tableGridColumnApi) => {
    //     const gridColumnApi = tableGridColumnApi || props.gridColumnApi;
    //     event.api.resetRowHeights();
    //     if (event.type === 'columnResized' && event.source !== 'sizeColumnsToFit' && event.source !== 'api' && event.finished === true) {
    //         //event.api.resetRowHeights();
    //         if (gridColumnApi && gridColumnApi.columnController.bodyWidth < gridColumnApi.columnController.scrollWidth) {
    //             props.gridApi.sizeColumnsToFit();
    //             setTimeout(() => {
    //                 const columnArray = event.columnApi.getColumnState();
    //                 saveColumnWidth(columnArray);
    //             }, 1000);
    //         } else {
    //             saveColumnWidth(event.columnApi.getColumnState());
    //         }
    //     }
    // }

    const _isColumnToHide = (columnName) => {
        return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }

    //const permittedModules = getActionMenuPermissionData();

    const getQuantityOnPR = (data) => {
        return <div className='circles-ling'>
            {data.statusPR === 'converted' ? <i className="fa fa-circle"></i> : (data.statusPR === 'converted.partially' ? <i className="fa fa-adjust"></i> : <i className="fa fa-circle-o" ></i>)}
        </div>;
    }

    const getQuantityOnQuote = (data) => {
        return <div className='circles-ling'>
            {data.statusQuote === 'converted' ? <i className="fa fa-circle"></i> : (data.statusQuote === 'converted.partially' ? <i className="fa fa-adjust"></i> : <i className="fa fa-circle-o" ></i>)}
        </div>;
    }

    const openInquiryDetails = (data) => {
        const inquiryPayload = {
            customerId: data.customerId,
            inquiryMasterId: data.inquiryMasterId,
        }
        props.pushModalToStack({
            modalBody: <InquiryDetail {...props} inquiryPayload={inquiryPayload} />,
            width: '100%',
            hideFooter: true,
            hideTitle: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    // const openCustomerProfile = (rowData, props) => {
    //     const modalData = {
    //         title: <div style={{display: "flex", justifyContent: "space-between"}}>
    //             <div>Customer profile</div>
    //         </div>,
    //         modalBody: <CustomerProfile {...{...props, customerDetails: rowData, customerId: rowData.customerId}}
    //                         customerDetails={rowData} 
    //                         customerOpenOrderCount={props.customerOpenOrderCount} 
    //                         customerOpenSOCount={props.customerOpenSOCount} 
    //                         customerSalesRevenue={props.customerSalesRevenue}
    //                         customerUnpaidBalance={props.customerUnpaidBalance}
    //                     />,
    //         // modalBody: <CustomerProfile {...props}
    //         // customerId={rowData.customerId}
    //         // customerName={rowData.customerName}
    //         // customerDetails={rowData}
    //         //             />,
    //         alertCancel: true,
    //         onCancel: () => {
    //             props.popModalFromStack();
    //             const payload = {
    //                 relationshipId: props.companyInfo.relationshipId,
    //                 userId: (props.userInfo || {}).userId,
    //                 pageNumber: 1,
    //                 pageSize: 25
    //               };
    //             props.fetchCustomers(payload);
    //             props.getCustomerCount(payload);
    //             return
    //         },
    //         hideFooter: true,
    //         hideTitle: true,
    //         width: '100%',
    //         height: '100%',
    //         maskClosable: true,
    //         keyboard: false,
    //         wrapClassName: "new-transaction-wrapper",       
    //     };
    //     props.pushModalToStack(modalData);

    //   };


    // const columnDefs = [
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='inquiryNumber.text' defaultMessage='Inquiry #' />;
    //         },
    //         field: "inquiryNumber",
    //         colId: "inquiryNumber_1",
    //         hide: _isColumnToHide('inquiryNumber'),
    //         suppressMovable: true,
    //         minWidth: 100,
    //         maxWidth: 300,
    //         rowDrag: true,
    //         resizable: true,
    //         cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
    //             openInquiryDetails(link.data)
    //         }}>{link.value}</div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.customer.text' defaultMessage='Customer' />;
    //         },
    //         field: "customerName",
    //         resizable: true,
    //         hide: _isColumnToHide('customerName'),
    //         colId: "customerName_1",
    //         suppressMovable: true,
    //         minWidth: 130,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) =><div className="cursor-pointer" >
    //         <div onClick={() => {
    //             props.history.push("customers/details", {
    //                 customerId: link.data.customerId,
    //                 customerName: link.data.customerName,
    //             })
    //         }}>{link.data.customerName}</div>
    //     </div>,
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='expirationDate.text' defaultMessage='Expiration Date' />;
    //         },
    //         field: "inquiryExpirationDate",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         hide: _isColumnToHide('expirationDate'),
    //         colId: "expirationDate_1",
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
    //                     {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='inquiryDate.text' defaultMessage='Inquiry Date' />;
    //         },
    //         field: "inquiryDate",
    //         hide: _isColumnToHide('inquiryDate'),
    //         colId: "inquiryDate_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT}))}>
    //                     {(link.value && getMomentDateForUIReadOnly({date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT}))}
    //                 </Tooltip>
    //             </div>
    //         },
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.priority' defaultMessage='Priority' />;
    //         },
    //         cellRendererFramework: (link) => {
    //             return link.value;
    //         },
    //         field: "priority",
    //         resizable: true,
    //         hide: _isColumnToHide('priority'),
    //         colId: "priority_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     // {
    //     //     headerComponentFramework: () => {
    //     //         return <FormattedMessage id='common.ref' defaultMessage='' />;
    //     //     },
    //     //     cellRendererFramework: (link) => {
    //     //         return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  {(link.value || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
    //     //     },
    //     //     field: "refNumber",
    //     //     resizable: true,
    //     //     hide: _isColumnToHide('refNumber'),
    //     //     colId: "refNumber_1",
    //     //     suppressMovable: true,
    //     //     minWidth: 70,
    //     //     maxWidth: 300,
    //     //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     // },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='quoteConverted.text' defaultMessage='Quote Converted' />;
    //         },
    //         cellRendererFramework: (link) => {
    //             return link.value && link.value !== '-' ? <span> {link.data.foreignCurrencyIcon ? <i className={link.data.foreignCurrencyIcon}></i> : (link.data.foreignCurrency ? <span>{link.data.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  {(link.value || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
    //         },
    //         field: "isQuoteConverted",
    //         resizable: true,
    //         hide: _isColumnToHide('isQuoteConverted'),
    //         colId: "isQuoteConverted_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='salesPerson.text' defaultMessage='Sales Person' />;
    //         },
    //         cellRendererFramework: (link) => {
    //             return link.value;
    //         },
    //         field: "salesPersonFullName",
    //         resizable: true,
    //         hide: _isColumnToHide('salesPersonFullName'),
    //         colId: "salesPersonFullName_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.systemStatus' defaultMessage='' />;
    //         },
    //         field: "status",
    //         colId: "status_1",
    //         resizable: true,
    //         hide: _isColumnToHide('status'),
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         cellRendererFramework: (params) => {
    //             return <div> <span className={["status-code", params.value.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage={params.value} /></span> </div>
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.status' defaultMessage='' />;
    //         },
    //         field: "userStatus",
    //         hide: _isColumnToHide('userStatus'),
    //         colId: "userStatus_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 400,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 {link.value? <span className="status-code" style={{background: link.data.userStatusColor}}>{link.value || ''}</span>: ''}
    //             </div>
    //         },
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.refNumber' defaultMessage='' />;
    //         },
    //         field: "refNumber",
    //         hide: _isColumnToHide('referenceNumber'),
    //         colId: "referenceNumber_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.customer.inquiryNumber' defaultMessage='' />;
    //         },
    //         field: "customerInquiryNumber",
    //         hide: _isColumnToHide('customerInquiryNumber'),
    //         colId: "customerInquiryNumber_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 300,
    //         resizable: true
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.qtyOnPR' defaultMessage='' />;
    //         },
    //         field: "totalQuantityOnPR",
    //         hide: _isColumnToHide('totalQuantityOnPR'),
    //         colId: "totalQuantityOnPR_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 100,
    //         cellRendererFramework: function (param) {
    //             return getQuantityOnPR(param.data);
    //         },
    //         resizable: true,
    //         cellClass: 'sales-order-progressbar'
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='customer.salesOrder.listing.header.qtyOnQuote' defaultMessage='' />;
    //         },
    //         field: "totalQuantityOnQuote",
    //         hide: _isColumnToHide('totalQuantityOnQuote'),
    //         colId: "totalQuantityOnQuote_1",
    //         suppressMovable: true,
    //         minWidth: 70,
    //         maxWidth: 100,
    //         cellRendererFramework: function (param) {
    //             return getQuantityOnQuote(param.data);
    //         },
    //         resizable: true,
    //         cellClass: 'sales-order-progressbar'
    //     },

    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='prStatus.text' defaultMessage='' />;
    //         },
    //         field: "statusPRMessage",
    //         resizable: true,
    //         minWidth: 124,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         hide: _isColumnToHide('statusPRMessage'),
    //         colId: "statusPRMessage_1",
    //         cellRendererFramework: (link) => {
    //             return <div>{link.value || ''}</div>
    //         },
    //     },
    //     {
    //         headerComponentFramework: () => {
    //             return <FormattedMessage id='common.project' defaultMessage='' />;
    //         },
    //         field: "project",
    //         hide: _isColumnToHide('project'),
    //         colId: "project_1",
    //         minWidth: 70,
    //         maxWidth: 300,
    //         suppressMovable: true,
    //         cellRendererFramework: (link) => {
    //             return <div>
    //                 {link.data.projectMasterId ? <span>{link.data.projectMasterId + '-' +link.data.projectName }</span> : ''}

    //             </div>
    //         },
    //         resizable: true
    //     }
    // ]

    // if (Object.keys(permittedModules.firstSection).length) {
    //     columnDefs.push({
    //         headerComponentFramework: () => {
    //             return <div className="cursor-pointer">
    //                 <Popover
    //                     content={<AddAndRemoveCustomerListingColumn {...props} />}
    //                     title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /></div>}
    //                     placement="left"
    //                     trigger="click"
    //                 >
    //                     <i className='fa fa-bars' />
    //                 </Popover>
    //             </div>;
    //         },
    //         cellRendererFramework: (param) => {
    //             return <Dropdown overlay={actionMenu(param, permittedModules)} trigger={['click']}>
    //                 <Button className="action-btn small">
    //                     <i className="fa fa-ellipsis-v" />
    //                 </Button>
    //             </Dropdown>
    //         },
    //         colId: "action_1",
    //         lockPosition: true,
    //         field: "action",
    //         pinned: 'right',
    //         suppressMovable: true,
    //         minWidth: 60,
    //         maxWidth: 60,
    //     });
    // }

    // const onFirstDataRendered = (params) => {
    //     //params.api.sizeColumnsToFit();
    // }

    // const agGridStyle = {
    //     height: '100%',
    //     width: '100%'
    // };

    // const breadCrumbList = [
    //     {
    //         name: 'Dashboard',
    //         link: 'dashboard'
    //     },
    //     {
    //         name: 'Sales',
    //     }, {
    //         name: 'Inquiry',
    //     }, {
    //         name: 'List'
    //     }
    // ];
    // const onGridReady = (params) => {
    //     props.updateState({
    //         gridApi: params.api,
    //         gridColumnApi: params.columnApi
    //     });
    //     onDataRendered(params);
    // }


    // const onModelUpdated = (params) => {
    //     if((enquiryList[pageNumber] || []).length &&  !props.isRowHeightSet){
    //         setTimeout(()=>{
    //             params.api.resetRowHeights();
    //         }, 1000)
    //         props.updateState({
    //             isRowHeightSet: true
    //         })
    //     }
    //     if (props.tableDataReRendered) {
    //         //params.api.resetRowHeights();
    //         props.updateState({
    //             tableDataReRendered: false
    //         })
    //         let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length ? props.txColumnSetting : params.columnApi.getColumnState();
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.INQUIRY_LISTING;

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (props.gridColumnApi && props.gridColumnApi.columnController.bodyWidth < props.gridColumnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     }
    // }

    // const onDataRendered = (params) => {
    //     let txColumnSetting = props.txColumnSetting && props.txColumnSetting.length? props.txColumnSetting: params.columnApi.getColumnState();
    //     if (Object.keys(txColumnSetting).length) {
    //         const columnToBeSet = [];
    //         const indexObjArr = TRANSACTION_COLUMN_INDEX.INQUIRY_LISTING;

    //         indexObjArr.forEach((e) => {
    //             let colObj = find(txColumnSetting, { colId: e + '_1' });
    //             if (colObj) {
    //                 columnToBeSet.push(colObj);
    //             }
    //         });

    //         txColumnSetting = txColumnSetting.filter((ele) => {
    //             let columnName = ele.colId.split("_")[0];
    //             return indexObjArr.indexOf(columnName) === -1;
    //         })
    //         txColumnSetting = columnToBeSet.concat(txColumnSetting);
    //         txColumnSetting.forEach((e) => {
    //             let columnName = e.colId.split("_")[0];
    //             if (columnName !== 'action') {
    //                 if (columnName === 'isChecked') {
    //                     e.hide = false;
    //                 } else {
    //                     e.hide = _isColumnToHide(columnName);
    //                 }
    //             } if (columnName === 'action') {
    //                 e.hide = false;
    //                 e.width = 52;
    //                 e.pinned = 'right';
    //             }
    //         });
    //         params.columnApi.setColumnState(txColumnSetting);
    //         setTimeout(() => {
    //             if (params.columnApi && params.columnApi.columnController.bodyWidth < params.columnApi.columnController.scrollWidth) {
    //                 params.api.sizeColumnsToFit();
    //             }
    //         }, 500);
    //     } else {
    //         params.api.sizeColumnsToFit();
    //     }
    // }

    const sortColumn = (e) => {
        sortTable(document.getElementById('inquiry-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }
    const updateUserStatus = (props, txData) => {
        let payload = { formData: {} };
        const formData = payload.formData;
        payload.txData = txData;
        const { pushModalToStack } = props;

        if (
            formData.textBoxValue &&
            formData.textBoxValue.length &&
            !payload.existsInData
        ) {
            updateUserStatusConfirmationModal(props, formData.textBoxValue, txData);
        } else {
            const data = {
                title: (
                    <FormattedMessage id="addItem.text.userStatus" defaultMessage="" />
                ),
                formData,
                hideFooter: true,
                modalData: {
                    modalType: MODAL_TYPE.USER_STATUS,
                    data: payload,
                    transactionName: CONSTANTS_TRANSACTIONS.INQUIRY,
                },

                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        updateUserStatusConfirmationModal(
                            props,
                            formData.textBoxValue,
                            formData.statusColor,
                            txData
                        );
                    } else {
                        data.formData.submittedOnce = true;
                        pushModalToStack(data);
                    }
                },
            };
            pushModalToStack(data);
        }
    };
    const updateUserStatusConfirmationModal = (props, text, color, txData) => {
        const { showModal, companyInfo } = props;

        const modalBody = (
            <span>
                <FormattedMessage
                    id="setItem.confirmation"
                    defaultMessage={``}
                />

            </span>
        );

        let payload = {
            userStatus: text,
            userStatusColor: color,
            relationshipId: companyInfo.relationshipId,
            inquiryMasterId: txData.inquiryMasterId,
            customerId: txData.customerId,
        };

        const modalData = {
            modalBody,
            handleSubmit: () => {
                props.updateUserStatus(payload, props);
                props.hideModal();
                props.popModalFromStack();
            },
        };
        showModal(modalData);
    };
    return (
        <>

            <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="left-actions">
                        <div className="table-heading">
                            <FormattedMessage id='sales.inquiry.table.heading' defaultMessage='' />
                        </div>
                        {
                            enquiryCount ? <>
                                <div className="vertical-sep" />
                                <div>{enquiryCount}</div>
                            </> : ''
                        }
                    </div>
                    <div className="right-actions">
                        <button onClick={() => {
                            props.updateState({
                                salesInquiryFilterDrawerVisible: true
                            })
                        }}>
                            <FilterOutlined /> &nbsp; <FormattedMessage id='filter.text' defaultMessage='' />
                        </button>
                        <Popover
                            content={<AddAndRemoveCustomerListingColumn {...props} />}
                            title={<div className="text-center">Add/Remove</div>}
                            placement="leftTop"
                            trigger="click"
                            arrow="right"
                            visible={props.showColPop}
                        >
                            <button onClick={() => { props.updateState({ showColPop: !props.showColPop }) }}>
                                <InsertRowRightOutlined className='mr5' /> Columns
                            </button>
                        </Popover>

                    </div>
                </Skeleton>
            </div>

            {/* <Collapse activeKey={collapseActiveKey} onChange  = {()=>{
                        setCollapseActiveKey(collapseActiveKey ? '' : '1')
                    }}>
                    <Panel header="Apply Filter" key="1"  style={{ width : '100%' }}>
                        <div className='qute-sect1'>
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}
                            className='drop-fileds1'
                            items={props.customers[pageNumber] || []}
                            valueKeyName='companyName'
                            optionKeyName='customerId'
                            value={props.selectedCustomerName}
                            onSearch={(searchedText) => {
                                props.fetchCustomers({
                                    isCompact: true,
                                    searchedText: searchedText,
                                    pageNumber: 1, 
                                    hideSpinner: true,
                                    pageSize: 100,
                                    relationshipId: (props.companyInfo || {}).relationshipId
                                })
                            }}
                            placeholder={props.intl.formatMessage({
                                id: 'customer.salesInvoice.form.customer.placeholder', defaultMessage: ''
                            })}

                            onSelect={
                                (customerId, option) => {
                                    updateState({
                                        selectedCustomerId: option.value,
                                        selectedCustomerName: option.name,
                                    })
                                }
                            }
                            allowClear={true}
                            onClear={() => {
                                updateState({
                                    selectedCustomerId: undefined,
                                    selectedCustomerName: undefined,
                                })
                            }}
                        />
                        <TextBox 
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                            className='drop-fileds'
                            placeholder='Inq #'
                            type='text'
                            maxLength={15}
                            value={props.inqNum}
                            onChange={(e) => {
                                props.updateState({ inqNum: e.target.value });
                            }}
                        />
                        <RangePicker
                            // style={{ marginTop: '-20px', width: '300px', marginRight: '5px' }}
                            className='drop-fileds2'
                            allowEmpty={true}
                            format={format}
                            placeholder={['Inq start date', 'Inq end date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }
                            }}
                        />

                        <RangePicker
                            // style={{ marginTop: '-20px', width: '300px', marginRight: '5px' }}
                            className='drop-fileds2'
                            allowEmpty={true}
                            format={format}
                            placeholder={['Exp start date', 'Exp end date']}
                            value={[props.expStartDate ? getMomentDateForUI({ date: new Date(props.expStartDate), format }) : null, props.expEndDate ? getMomentDateForUI({ date: new Date(props.expEndDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ expStartDate: dates[0], expEndDate: dates[1] });
                                } else {
                                    props.updateState({ expStartDate: null, expEndDate: null });
                                }
                            }}
                        />
                        <div className='cler'></div>
    </div>
    <div className='cler'></div>
    <div className='qute-sect1'>
                        <Dropdown
                            items={props.userStatus || []}
                            valueKeyName='statusName'
                            optionKeyName='purchaseInvoiceStatusId'
                            statusColorKey='statusColor'
                            className='drop-fileds'
                            // style={{ marginTop: '-25px', width: '140px', marginRight: '5px' }}
                            value={props.selectedUserStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.status' })}
                            onSelect={(selectedValue, option) => {
                                updateState({ selectedUserStatusName: option.name, selectedUserStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedUserStatusName: undefined, selectedUserStatus: undefined })
                            }}
                        />
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' }}
                            className='drop-fileds'
                            items={[{ name: 'Open', value: 'open' }, { name: 'Partially Fullfilled', value: 'partially.fulfilled' }, { name: 'Fullfilled', value: 'fulfilled' }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedStatusName}
                            placeholder={props.intl.formatMessage({ id: 'common.systemStatus' })}
                            onSelect={(status, option) => {
                                updateState({ selectedStatusName: option.name, selectedStatus: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedStatusName: undefined, selectedStatus: undefined })
                            }}
                        />

                        <Dropdown
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' }}
                            className='drop-fileds'
                            items={[{ name: 'Low', value: 'low' }, { name: 'Medium', value: 'medium' }, { name: 'High', value: 'high' }, { name: 'Urgent', value: 'urgent' }]}
                            valueKeyName='name'
                            optionKeyName='value'
                            value={props.selectedPriority}
                            placeholder={props.intl.formatMessage({ id: 'customer.filter.priority.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedPriority: option.name })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedPriority: undefined })
                            }}
                        />
                        <TextBox 
                            // className='new-fed1'
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '0px'}}
                            className='drop-fileds'
                            placeholder='Ref #'
                            type='text'
                            maxLength={15}
                            value={props.refNum}
                            onChange={(e) => {
                                props.updateState({ refNum: e.target.value });
                            }}
                        />
                        <Dropdown
                            // style={{ marginTop: '-20px', width: '140px', marginRight: '5px' }}
                            className='drop-fileds'
                            items={props.salesPersonList}
                            valueKeyName='fullName'
                            optionKeyName='relationshipEmployeeId'
                            value={props.selectedSalesPersonName}
                            placeholder={props.intl.formatMessage({ id: 'filter.salesPerson.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedSalesPersonName: option.name, selectedSalesPersonId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedSalesPersonName: undefined, selectedSalesPersonId: undefined })
                            }}
                        />

                        <Dropdown
                            // style={{ marginTop: '-20px', width: '160px', marginRight: '5px' }}
                            className='drop-fileds1'
                            items={props.projectList}
                            valueKeyName='projectName'
                            optionKeyName='projectMasterId'
                            projectIdKey='projectMasterId'
                            value={props.selectedProjectName}
                            placeholder={props.intl.formatMessage({ id: 'salesInvoice.project.placeholder' })}
                            onSelect={(status, option) => {
                                updateState({ selectedProjectName: option.name, selectedProjectMasterId: option.value })
                            }}
                            allowClear={true}
                            onClear={() => {
                                updateState({ selectedProjectName: undefined, selectedProjectMasterId: undefined })
                            }}
                        />
                        <div className='cler'></div>
                        </div>
                        <div className='cler'></div>
    <div className='qute-sect1'>
                        <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            if(!props.selectedCustomerId && !props.inqNum && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatusName && !props.selectedStatus && !props.selectedPriority && !props.refNum && !props.selectedSalesPersonId && !props.selectedProjectMasterId ){
                                return showToasterMessage({ messageType: 'error', description: 'Please select at least one filter' });
                            }
                            const payload = {
                                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                filters: {
                                    customerId: props.selectedCustomerId,
                                    inquiryNumber: props.inqNum,
                                    startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
                                    endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
                                    expStartDate: props.expStartDate ? getCurrentDateForBackend(props.expStartDate) : null,
                                    expEndDate: props.expEndDate ? getCurrentDateForBackend(props.expEndDate) : null,
                                    userStatus: props.selectedUserStatusName,
                                    status: props.selectedStatus,
                                    priority: props.selectedPriority,
                                    reference: props.refNum,
                                    salesPersonId: props.selectedSalesPersonId,
                                    salesPersonName: props.selectedSalesPersonName,
                                    projectMasterId: props.selectedProjectMasterId,
                                }
                            };
                            props.fetchSalesEnquiryByFilter(payload);
                            props.getSalesEnquiryCountByFilter(payload);

                        }}>
                            {<FilterOutlined />} Apply Filter
                        </button>
                        <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            props.updateState({
                                selectedCustomerId: null, selectedCustomerName: null,
                                inqNum: null, 
                                startDate:null,endDate:null,
                                expStartDate:null, expEndDate:null,
                                selectedUserStatusName: null, selectedUserStatus: null,
                                selectedStatus:null, selectedStatusName: null,
                                selectedPriority: null,
                                refNum:null,
                                selectedSalesPersonId: null, selectedSalesPersonName:null, 
                                selectedProjectName:null, selectedProjectMasterId: null 
                            });
                            const payload = {
                                companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 0, pageSize: props.pageSize,
                                filters: {}
                            };
                            props.fetchSalesEnquiry(payload);
                            props.fetchSalesEnquiryCount(payload);
                        }}>
                            {<UndoOutlined />} Clear Filter
                        </button>
                        </div>
                    </Panel>
                </Collapse> */}

            {/* {props.txColumns.length ? <div>
                    <div className="cursor-pointer">
                        <Tooltip placement='left' title={props.intl.formatMessage({ id: 'tooltip.addRemoveColumn.msg' })} trigger='hover'>
                            <span className="top-button">
                                <Popover
                                    content={<AddAndRemoveCustomerListingColumn {...props} />}
                                    title={<div className="text-center"><FormattedMessage id='common.addRemove' defaultMessage='' /></div>}
                                    placement="left"
                                    trigger="click"
                                >
                                    <i className='fa fa-bars' />
                                </Popover>
                            </span>
                        </Tooltip>
                    </div>
                    <AgGridReact
                        onGridReady={onGridReady}
                        defaultColDef={{
                            flex: 1,
                            autoHeight: true,
                            wrapText: true,
                        }}
                        columnDefs={columnDefs}
                        rowData={enquiryList[pageNumber] || []}
                        rowDragManaged={true}
                        domLayout={"autoHeight"}
                        suppressDragLeaveHidesColumns={true}
                        animateRows={true}
                        onModelUpdated={onModelUpdated}
                        onGridSizeChanged={onFirstDataRendered}
                        onColumnResized={onColumnResized}
                        onDragStopped={() => {
                            onColumnMoved({}, true);
                        }}
                        //onColumnMoved={onColumnMoved}
                    >
                    </AgGridReact>
                </div> : null} */}

            <div className='table-container'>
                <Skeleton loading={props.listLoading} paragraph={{ rows: 13 }} active>

                    <table id="inquiry-table">
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                {!_isColumnToHide('inquiryNumber') ? <th onClick={sortColumn}><FormattedMessage id='inquiryNumber.text' defaultMessage='Inquiry #' /></th> : ''}
                                {!_isColumnToHide('customerName') ? <th onClick={sortColumn}><FormattedMessage id='common.customer.text' defaultMessage='Customer' /></th> : ''}
                                {!_isColumnToHide('expirationDate') ? <th onClick={sortColumn}><FormattedMessage id='expirationDate.text' defaultMessage='Expiration Date' /></th> : ''}
                                {!_isColumnToHide('inquiryDate') ? <th onClick={sortColumn}><FormattedMessage id='common.dateCreated' defaultMessage='Inquiry Date' /></th> : ''}
                                {!_isColumnToHide('priority') ? <th onClick={sortColumn}><FormattedMessage id='common.priority' defaultMessage='Priority' /></th> : ''}
                                {!_isColumnToHide('isQuoteConverted') ? <th onClick={sortColumn}><FormattedMessage id='quoteConverted.text' defaultMessage='Quote Converted' /></th> : ''}
                                {!_isColumnToHide('salesPersonFullName') ? <th onClick={sortColumn}><FormattedMessage id='salesPerson.text' defaultMessage='Sales Person' /></th> : ''}
                                {!_isColumnToHide('systemStatus') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.systemStatus' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('userStatus') ? <th onClick={sortColumn}><FormattedMessage id='userStatus.txt' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('referenceNumber') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.refNumber' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('customerInquiryNumber') ? <th onClick={sortColumn}><FormattedMessage id='common.customer.inquiryNumber' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('totalQuantityOnPR') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.qtyOnPR' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('totalQuantityOnQuote') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesOrder.listing.header.qtyOnQuote' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('statusPRMessage') ? <th onClick={sortColumn}><FormattedMessage id='prStatus.text' defaultMessage='' /></th> : ''}

                                {!_isColumnToHide('project') ? <th onClick={sortColumn}><FormattedMessage id='common.project' defaultMessage='' /></th> : ''}
                                {<th onClick={sortColumn}><FormattedMessage id='common.status' defaultMessage='' /></th>}

                            </tr>
                        </thead>
                        <tbody>
                            {enquiryList && enquiryList[pageNumber] ?
                                enquiryList[pageNumber].map((rowData, index) => (
                                    <tr key={index}>
                                        <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>
                                        {!_isColumnToHide('inquiryNumber') ? <td><div className="table-link-text" onClick={() => { openInquiryDetails(rowData) }}>{rowData.inquiryNumber}</div></td> : ''}

                                        {!_isColumnToHide('customerName') ? <td className='one-line-view'>
                                            <div className="table-link-text" >
                                                <div className="link"
                                                    //    onClick={() => {
                                                    //   openCustomerProfile(rowData, props);
                                                    // }}
                                                    onClick={() => {
                                                        props.pushModalToStack({

                                                            modalBody: <CustomerProfile {...props}
                                                                customerId={rowData.customerId}
                                                            //   customerName={rowData.customerName}
                                                            //   customerDetails={rowData}
                                                            />,
                                                            width: '100%',
                                                            hideTitle: true,
                                                            hideFooter: true,
                                                            wrapClassName: "new-transaction-wrapper",
                                                        })
                                                    }}
                                                >
                                                    {rowData.customerName}
                                                </div>
                                            </div>
                                            {/* <div onClick={() => {
                                                          props.pushModalToStack({  
                                                           
                                                            modalBody: <CustomerDetails {...props}
                                                            customerId= {rowData.customerId}
                                                            customerName={rowData.customerName}
                                                        />,
                                                        width: '100%',
                                                        hideTitle: true,
                                                        hideFooter: true,
                                                        wrapClassName: "new-transaction-wrapper",
                                                        })
                                                       }}>{rowData.customerName}</div>
                                                    */}
                                        </td> : ''
                                        }

                                        {!_isColumnToHide('expirationDate') ? <td>
                                            <div>
                                                <Tooltip placement="topLeft" title={(rowData.inquiryExpirationDate && getMomentDateForUIReadOnly({ date: rowData.inquiryExpirationDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                    {(rowData.inquiryExpirationDate ? getMomentDateForUIReadOnly({ date: rowData.inquiryExpirationDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : <div className="empty-data"></div>)}
                                                </Tooltip>
                                            </div>
                                        </td> :
                                            ""
                                        }

                                        {!_isColumnToHide('inquiryDate') ? <td>
                                            <div>
                                                <Tooltip placement="topLeft" title={(rowData.inquiryDate && getMomentDateForUIReadOnly({ date: rowData.inquiryDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                    {(rowData.inquiryDate && getMomentDateForUIReadOnly({ date: rowData.inquiryDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                                </Tooltip>
                                            </div>
                                        </td> : ''
                                        }

                                        {!_isColumnToHide('priority') ? <td>{rowData.priority ? rowData.priority : <div className="empty-data"></div>}</td> : ""

                                        }

                                        {!_isColumnToHide('isQuoteConverted') ? <td>
                                            {rowData.isQuoteConverted && rowData.isQuoteConverted !== '-' ? <span> {rowData.foreignCurrencyIcon ? <i className={rowData.foreignCurrencyIcon}></i> : (rowData.foreignCurrency ? <span>{rowData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  {(rowData.isQuoteConverted || 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : <div className="empty-data"></div>}</td> : ''
                                        }
                                        {!_isColumnToHide('salesPersonFullName') ? <td>{rowData.salesPersonFullName ? rowData.salesPersonFullName : <div className="empty-data"></div>}</td> : ""}
                                        {!_isColumnToHide('systemStatus') ? <td className='line-view'>
                                            <div>
                                                <span className={["status-code", rowData.status.toLowerCase()].join(' ')}>
                                                    {rowData.status ? <FormattedMessage id={rowData.status} defaultMessage={rowData.status} />
                                                        : <div className="empty-data"></div>}</span>
                                            </div></td> : ""
                                        }
                                        {!_isColumnToHide('userStatus') ? <td className='line-view'>
                                            {/* <div>
                                                {rowData.userStatus ? <span className="status-code cursor-pointer" style={{ background: rowData.userStatusColor }}
                                                    onClick={() => {
                                                        updateUserStatus(props, rowData);
                                                    }}
                                                >{rowData.userStatus || <div className="empty-data"></div>}</span> : <div className="empty-data"></div>}
                                            </div> */}


                                            <div>
                                                {rowData.userStatus ? (
                                                    <span
                                                        className="status-code cursor-pointer"
                                                        style={{ background: rowData.userStatusColor }}
                                                        onClick={() => {
                                                            updateUserStatus(props, rowData);
                                                        }}
                                                    >
                                                        {rowData.userStatus}
                                                    </span>
                                                ) : (
                                                    <div className="empty-data">
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                updateUserStatus(props, rowData);
                                                            }}
                                                        >
                                                            <div className="empty-data"></div>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>



                                        </td> : ""}
                                        {!_isColumnToHide('referenceNumber') ? <td>{rowData.refNumber ? rowData.refNumber : <div className="empty-data"></div>}</td> : ""}
                                        {!_isColumnToHide('customerInquiryNumber') ? <td>{rowData.customerInquiryNumber ? rowData.customerInquiryNumber : <div className="empty-data"></div>}</td> : ""}
                                        {!_isColumnToHide('totalQuantityOnPR') ? <td>{getQuantityOnPR(rowData)}</td> : ""}
                                        {!_isColumnToHide('totalQuantityOnQuote') ? <td>{getQuantityOnQuote(rowData)}</td> : ""}
                                        {!_isColumnToHide('statusPRMessage') ? <td>{rowData.statusPRMessage ? rowData.statusPRMessage : <div className="empty-data"></div>}</td> : ""}
                                        {!_isColumnToHide('project') ? <td>
                                            {rowData.projectName ? <span>{rowData.projectName}</span> : <div className="empty-data"></div>}
                                        </td> : ""}
                                        <td>
                                            <div className="status-toggle">

                                                <Switch
                                                    checked={rowData.isActive === 1 ? 1 : 0}

                                                    onClick={(checked, e) => {
                                                        props.updateStatus({
                                                            inquiryMasterId: rowData.inquiryMasterId,
                                                            relationshipId: rowData.relationshipId,
                                                            isActive: checked ? 1 : 0
                                                        }, props)

                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )) :  <tr key="empty-data-box">
                                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                    <Empty />
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </Skeleton>
            </div>
            <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={enquiryCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props)
                            }}
                        />
                    </div>
                </Skeleton>
            </div>
        </>

    );
}

export default injectIntl(SalesEnquiryListingComp);
