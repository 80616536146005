import axios from "axios";
import config from "../../../../config/environmentConfig";
//import { MEMBERSHIP_ACTION_LIST } from "../../../../static/constants";
import { showToasterMessage, formatDateForBackend } from "../../../../utils";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const saveAttendance = (payload, props) => {
  return (dispatch) => {
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/empAttendance/saveCol`,
        payload
      )
      .then((response) => {
    
        showToasterMessage({
          messageType: "success",
          description: "Saved Sucessfully!"
        });
        
        if (props) {
          props.updateHeaderState({
            employeeAttendance: {
              addEmployeeAttendenceDrawerVisible: false
            }
          });
          let date = props.selectedDate ? new Date(props.selectedDate) : new Date();
          let y = date.getFullYear();
          let m = date.getMonth();
          const payloadObj = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: 1000,
            classId: payload.classId,
            startDate: formatDateForBackend(new Date(y, m, 1)),
            endDate: formatDateForBackend(new Date(y, m + 1, 0)),
            relationshipEmployeeId: payload.createdByEmpId,
          };
       
          props.getEmpAttendances && props.getEmpAttendances(payloadObj);
        }

      })
      .catch((err) => {
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      });
  };
};


