import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Tooltip, Pagination, Button, DatePicker } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import PurchaseInvoceDetail from '../../../../../containers/modal/modalBody/supplier/PurchaseInvoiceDetail';

import { CONSTANTS } from '../../../../../static/constants';
import { AG_GRID_CLASS_CONSTANTS } from '../../../../../static/cssClassConstants';
import { capitalizeFirstLetter, fetchPaginationDataIfNeeded, fixedDecimalAndFormateNumber, fixedDecimalNumber, getCurrentDateForBackend, getMomentDateForUI, getMomentDateForUIReadOnly, showToasterMessage } from '../../../../../utils';
import find from "lodash.find";
import { FilePdfOutlined, PrinterOutlined, FilterOutlined} from '@ant-design/icons';
const { RangePicker } = DatePicker;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const AllInvoicesByLedgerComponent = (props) => {
    const { purchaseInvoiceList, purchaseInvoiceCount, companyInfo, updateState, pageNumber } = props;

    const agGridStyle = {
        height: '100%',
        width: '100%'
    };

    // const getRowHeight = () => {
    //     return 28;
    // }

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    }

    const openInvoiceDetails = (data) => {
        const invoicePayload = {
            supplierId: data.supplierId,
            invoiceMasterId: data.invoiceMasterId
        }
        props.pushModalToStack({
            modalBody: <PurchaseInvoceDetail {...props} invoicePayload={invoicePayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const columnDefs = [
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceNumber' defaultMessage='' />;
            },
            field: "invoiceNumber",
            cellRendererFramework: (link) => <div className="cursor-pointer" onClick={() => {
                openInvoiceDetails(link.data)
            }}>{link.value}</div>,
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <div><FormattedMessage id='supplier.listing.unPaidInvoice.header.amount' defaultMessage='' /></div>;
            },
            field: "invoiceTotalAmount",
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
            resizable: true,
            cellRendererFramework: (params) => {
                return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.value)}</span>;
            },
            valueFormatter: (data) => {
                data.value = data.value ? fixedDecimalNumber(data.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
                return data.value;
            }
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceDate' defaultMessage='' />;
            },
            field: "invoiceDate",
            cellRendererFramework: (link) => {
                return <div>
                    <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}>
                        {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                    </Tooltip>
                </div>
            },
            resizable: true,
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='supplier.listing.unPaidInvoice.header.invoiceDueDate' defaultMessage='' />;
            },
            field: "invoiceDueDate",
            cellRendererFramework: (link) => {
                return <div>
                    <Tooltip placement="topLeft" title={(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}>
                        {(link.value && getMomentDateForUIReadOnly({ date: link.value, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                    </Tooltip>
                </div>
            },
            resizable: true,
        },

        {
            headerComponentFramework: () => {
                return <div><FormattedMessage id='supplier.listing.unPaidInvoice.header.paymentAmount' defaultMessage='' /> </div>;
            },
            field: "paymentAmount",
            cellRendererFramework: (params) => {

                return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(params.data.totalPaymentMade).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>;
            },
            resizable: true,
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {
            headerComponentFramework: () => {
                return <div><FormattedMessage id='supplier.listing.unPaidInvoice.header.dueAmount' defaultMessage='' /></div>;
            },
            field: "invoiceDueAmount",
            resizable: true,
            cellRendererFramework: (params) => {
                return <span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber((params.data.invoiceTotalAmount || 0) - (params.data.totalPaymentMade || 0))}</span>;
            },
            valueFormatter: (data) => {
                data.value = data.value ? fixedDecimalNumber(data.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00';
                return data.value;
            },
            cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN
        },
        {
            headerComponentFramework: () => {
                return <FormattedMessage id='common.status' defaultMessage='' />;
            },
            field: "status",
            cellRendererFramework: (params) => {
                return <div> <span className={["status-code", params.value?.toLowerCase()].join(' ')}><FormattedMessage id={params.value} defaultMessage='' /></span> </div>
            },
            resizable: true,
        }
    ];

    const loadPageData = (pageNumber, pageSize) => {
        const payload = {
            relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize,
            filters: {
                supplierId: this.props.supplierId,
                ledgerAccountId: this.props.ledgerAccountId
            }
        };
        fetchPaginationDataIfNeeded('fetchPurchaseInvoicesByFilter', 'purchaseInvoiceList', props, payload);
    }
    const getAddressInfoFragment = (obj) => {
        return (
            <>
                {obj?.streetAddress1 ? (
                    <span className="billing-address">{obj?.streetAddress1}</span>
                ) : (
                    ""
                )}
                {obj?.streetAddress2 ? (
                    <span className="billing-address"> {obj?.streetAddress2}</span>
                ) : (
                    ""
                )}
                {obj?.cityName ? (
                    <div className="billing-address">
                        {`${obj?.cityName || ""}${obj?.stateName ? ", " + obj?.stateName : ""
                            }${obj?.zipCode ? ", " + obj?.zipCode : ""}`}
                    </div>
                ) : (
                    ""
                )}
                {obj?.countryName ? (
                    <div
                        className="billing-address"
                        style={{ textTransform: "uppercase" }}
                    >
                        {`${capitalizeFirstLetter(obj?.countryName) || ""}`}
                    </div>
                ) : (
                    ""
                )}
            </>
        );
    };
    const billingAddress =
        find(props.boLocationList, {
            locationType: CONSTANTS.BILLING_ADDRESS,
        }) || {};
    const relationshipAddress =
        find(props.companyInfo.boLocationList, {
            locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS,
        }) || {};
    const getContactInfoFragment = (obj) => {
        return (
            <>
                {/* <div >{obj?.firstName || "" + " " + obj?.lastName || ""}</div>
             <div >{obj?.emailAddress || ""}</div>
             <div >{obj?.cellPhone || ""}</div> */}
                {obj?.firstName ? (
                    <div >{obj.firstName + " " + obj.lastName}</div>
                ) : (
                    ""
                )}
                {obj?.emailAddress ? (
                    <div > {obj.emailAddress}</div>
                ) : (
                    ""
                )}
                {obj?.cellPhone ? (
                    <div > {obj.cellPhone}</div>
                ) : (
                    ""
                )}

            </>
        );
    };

    const invoiceTotalAmount = purchaseInvoiceList[1].reduce((total, invoice) => {
        return total + invoice.invoiceTotalAmount;
    }, 0);

    const invoiceDueAmount = purchaseInvoiceList[1].reduce((total, invoice) => {
        return total + (invoice.invoiceTotalAmount - invoice.totalPaymentMade);
    }, 0);

    const invoicePayAmount = purchaseInvoiceList[1].reduce((total, invoice) => {
        return total + invoice.totalPaymentMade;
    }, 0);
    const getPayloadForLedgerSummaryPdf = () => {
        return {
            fileName: (props.supplierName) + " " + 'invoice.pdf',
            transactionName: 'supplierInvoiceList',
            companyInfo: props.companyInfo,
            billingAddress: billingAddress,
            primaryContact: props.primaryContact,
            primaryAddress: props.primaryAddress,
            purchaseInvoiceList: JSON.stringify(purchaseInvoiceList),
            relationshipAddress: relationshipAddress,
            invoicesTotalAmount: fixedDecimalAndFormateNumber(invoiceTotalAmount),
            supplierStoreName: props.supplier.supplierName,
            // invoicesTotalAmount:2
            documentName: "Invoice",
            invoicePayAmount: fixedDecimalAndFormateNumber(invoicePayAmount),
            invoiceDueAmount: fixedDecimalAndFormateNumber(invoiceDueAmount),
            fyStartDate:props.pLStartDate,
            fyEndDate:props.pLEndDate,
            ledgerAccountName:props.ledgerAccountName
        }
    }



    const generateLedgerSummaryPDF = () => {
        let payload = getPayloadForLedgerSummaryPdf();
        props.generatePDF(payload);
    }
    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {
            relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize,
            filters: {
                supplierId: this.props.supplierId,
                ledgerAccountId: this.props.ledgerAccountId
            }
        };
        props.resetPaginatedData();
        props.fetchPurchaseInvoicesByFilter(payload);
        //props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };
    const printPdf = () => {
        let payload = getPayloadForLedgerSummaryPdf();
         props.printPDF(payload)
       }
    return (
        <>
            <div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                
                    <div className="ant-modal-subtitle extra-sm">
                        {props.supplierName}
                    </div>
                    <div className="modal-info">

                        <div className="w-200">{getAddressInfoFragment(props.primaryAddress)}</div>
                        <div className="w-200">{getContactInfoFragment(props.primaryContact)}</div>
                        <div className="modal-btn">
                            <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.downloadPDF', defaultMessage: '' })}
                                icon={<FilePdfOutlined />}
                                onClick={() => {
                                    generateLedgerSummaryPDF();
                                }}
                            >
                            </Button>
                            <Button
                                title={props.intl.formatMessage({ id: 'modal.txDetails.common.print', defaultMessage: '' })}
                                icon={<PrinterOutlined />}
                                onClick={() => {
                                    printPdf();
                                }}
                            >
                            </Button>
                        </div>
                    </div>
                    <div className='modal-info'>
                    <RangePicker
                className="drop-fileds2"
                allowEmpty={true}
                format={format}
                placeholder={["Start date", "End date"]}
                value={[
                  props.pLStartDate
                    ? getMomentDateForUI({
                        date: new Date(props.pLStartDate),
                        format,
                      })
                    : null,
                  props.pLEndDate
                    ? getMomentDateForUI({
                        date: new Date(props.pLEndDate),
                        format,
                      })
                    : null,
                ]}
                onChange={(dates) => {
                  if (dates) {
                    props.updateState({
                      pLStartDate: dates[0],
                      pLEndDate: dates[1],
                    });
                  } else {
                    props.updateState({ pLStartDate: null, pLEndDate: null });
                  }
                }}
              /> 
               <button
                className="ant-btn ant-btn-default vab ml5"
                onClick={() => {
                  if (!props.pLStartDate && !props.pLEndDate) {
                    return showToasterMessage({
                      messageType: "error",
                      description: "Please select date range",
                    });
                  }
                  const payload = {
                    supplierId: props.supplierId,
                    relationshipId: companyInfo.relationshipId,
                    pageNumber: 0,
                    pageSize: props.pageSize,
                    filters: {
                      startDate: props.pLStartDate
                        ? getCurrentDateForBackend(props.pLStartDate)
                        : null,
                      endDate: props.pLEndDate
                        ? getCurrentDateForBackend(props.pLEndDate)
                        : null,
                                 supplierId:props.supplierId,
                       ledgerAccountId:props.ledgerAccountId
                    },
                  };
                //   {
                //     relationshipId: companyInfo.relationshipId,
                //     supplierId: this.props.supplierId,
                //     pageNumber: 1,
                //     pageSize: 100,
                //     filters: {
                //         supplierId: this.props.supplierId,
                //         ledgerAccountId: this.props.ledgerAccountId
                //     }
                // }
                  props.fetchPurchaseInvoicesByFilter(payload);
                }}
              >
                {<FilterOutlined />} Apply Filter
              </button>
                    </div>
                </div>
          
            </div>
            <div className='agGridWrapper' style={{height:"auto"}}>
                <div className="ag-theme-balham customer-details-table" style={agGridStyle} >
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowData={purchaseInvoiceList[pageNumber] || []}
                        rowDragManaged={true}
                        domLayout={"autoHeight"}
                        animateRows={true}
                        onColumnResized={(params) => {
                            params.api.resetRowHeights();
                        }}
                        defaultColDef={{
                            flex: 1,
                            autoHeight: true,
                            wrapText: true,
                        }}
                        //getRowHeight={getRowHeight}
                        onGridSizeChanged={onFirstDataRendered}
                    >
                    </AgGridReact>
                    <Pagination
                        size="small"
                        total={purchaseInvoiceCount}
                        showTotal={showTotal}
                        defaultPageSize={100} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                        // showQuickJumper
                        onChange={(pageNumber, pageSize) => {
                            loadPageData(pageNumber, pageSize);
                            updateState({ pageSize: pageSize, pageNumber: pageNumber })
                        }}
                        onShowSizeChange={(pageNumber, pageSize) => {
                            handlePageSizeChange(pageNumber, pageSize, props)
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default injectIntl(AllInvoicesByLedgerComponent);
