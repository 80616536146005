import React from 'react'
import { FormattedMessage } from 'react-intl';
import { ICONS } from '../../../../../static/constants';

const CategoryDetailsModel = (props) => {

  const {productDetails={}} = props;
  return (
     <div className="modal-content full-width" style={{height:'500px', overflow: 'scroll'}} >
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>
                 Product Name
                </th>
                <th>
                Category
                </th>
                {/* <Col <b><FormattedMessage id='schoolManagement.subject.name' /></b></Col> */}
                <th>
                  <FormattedMessage
                    id="common.listing.header.action"
                    defaultMessage=""
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {(productDetails.inventoryItemCategoryRefs || []).length ? (productDetails.inventoryItemCategoryRefs).map(
                (item, i) => {
                  return (
                    <tr key={'ques'+i}>
                      <td> {i + 1}</td>
                      <td>
                      {item?.inventoryItemName || `-`}
                      </td>
                      <td>{item?.inventoryItemCategoryName || '-'}</td>
                      <td>
                        <span
                          style={{
                            fontSize: "20px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            props.showModal({
                              modalBody: "Are you sure you want to delete?",
                              handleSubmit: () => {
                                props.deleteAssignedClass(item, props);
                                props.popModalFromStack();
                              },
                            });
                          }}
                        >
                          <i className={ICONS.DELETE} />
                        </span>
                      </td>
                    </tr>
                  );
                }
              ):''}
            </tbody>
          </table>
        </div>
      </div>
  )
}

export default CategoryDetailsModel