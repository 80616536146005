import React from "react";
import { Skeleton, DatePicker,Empty } from 'antd';
import { fixedDecimalAndFormateNumber, getMomentDateForUI, getCurrentDateForBackend  } from '../../../../utils';
import { injectIntl, FormattedMessage } from 'react-intl';
import InvoicesListing from '../../../../containers/modal/modalBody/customer/AllInvoicesByLedger';
import { CONSTANTS } from '../../../../static/constants';

const { RangePicker } = DatePicker;
const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const salesOrder = (props) => {
   const { companyInfo } = props;

    return (
        <div className="view-container">
         
                <div className="view-container-actions">
                    <div className="left-actions">
                        <div className="table-heading">Ledger Summary</div>
                        {(props.customerInvoiceLedgerSummaryList || []).length ? <>
                            <div className="vertical-sep" />
                            <div>{props.customerInvoiceLedgerSummaryList.length}</div>
                            </>
                            :''
                        }
                    </div>
                    <div className="right-actions">
                        {/* <RangePicker
                            allowEmpty={true}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            value={[props.legderStartDate ? getMomentDateForUI({ date: new Date(props.legderStartDate), format }) : null, props.legderEndDate ? getMomentDateForUI({ date: new Date(props.legderEndDate), format }) : null]}
                            onChange={(dates) => {
                                if ((dates || []).length === 2) {
                                    props.updateState({ legderStartDate: dates[0], legderEndDate: dates[1] });
                                    const payload = {
                                        customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                        filters: {
                                            startDate: getCurrentDateForBackend(dates[0]),
                                            endDate: getCurrentDateForBackend(dates[1]),
                                        }
                                    };
                                    props.fetchCustomerLedgerTxSummaryList(payload);
                                } else {
                                    props.updateState({ legderStartDate: null, legderEndDate: null });
                                    const payload = {
                                        customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                        filters: {}
                                    };
                                    props.fetchCustomerLedgerTxSummaryList(payload);
                                }
                            }}
                            allowClear={true}
                        /> */}
                        {/* <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            if( !props.legderStartDate && !props.legderEndDate ){
                                return showToasterMessage({ messageType: 'error', description: 'Please select date range' });
                            }
                            const payload = {
                                customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                filters: {
                                    startDate: props.legderStartDate ? getCurrentDateForBackend(props.legderStartDate) : null,
                                    endDate: props.legderEndDate ? getCurrentDateForBackend(props.legderEndDate) : null,
                                }
                            };
                            props.fetchCustomerLedgerTxSummaryList(payload);
                        }}>
                            {<FilterOutlined />} Apply Filter
                        </button>
                        <button className="ant-btn ant-btn-default vab ml5" onClick={() => {
                            props.updateState({
                                legderStartDate: null, legderEndDate: null,
                            });
                            const payload = {
                                customerId: props.customerId, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize || 25,
                                filters: {}
                            };
                            props.fetchCustomerLedgerTxSummaryList(payload);
                            }}>
                                {<UndoOutlined />} Clear Filter
                        </button> */}
                    </div>
                </div>
                <Skeleton loading={props.listLoading} active paragraph={{ rows: 16 }}>

                <div className='table-container table-h-profile'>
                    {
                        <table>
                            <thead>
                                <tr>
                                    <th><FormattedMessage id="serial.no" defaultMessage=""/></th>
                                    <th>Ledger</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.customerInvoiceLedgerSummaryList.length > 0 ?
                                        (props.customerInvoiceLedgerSummaryList || []).map((e, i) => {
                                        return <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>
                                               {e.ledgerAccountName                                             
                                               ? 
                                               <span className="cursor-pointer" 
                                               onClick={
                                                    () => {
                                                        const invoicePayload = {
                                                            customerId: e.customerId,
                                                            ledgerAccountId: e.ledgerAccountId
                                                        }
                                                        props.pushModalToStack({
                                                            title: <div className='ant-modal-title'> <FormattedMessage id='sidebar.menuItem.invoice' defaultMessage='' /></div>,
                                                            modalBody: <InvoicesListing {...props} {...invoicePayload} invoicePayload={invoicePayload} />,
                                                            width: 1000,
                                                            hideFooter: true,
                                                            wrapClassName: 'modal-custom-detail'
                                                        })
                                                    }}>{e.ledgerAccountName} </span>
                                                    
                                                    
                                                    :<div className="empty-data"></div>}
                                                    
                                                    
                                                    
                                                    
                                                    </td>
                                            <td style={{ width: '15%' }}>
                                                    {
                                                        e.totalAmount

                                                        ?
                                                        <span>{fixedDecimalAndFormateNumber(e.totalAmount)}</span>

                                                        :
                                                        <div className="empty-data"></div>
                                                    }
                                                
                                            </td>
                                        </tr>
                                    })
                                    :
                                    <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </Skeleton>
            <div className="footer">
                <div className="f-left"></div>
                <div className="f-right"></div>
            </div>

        </div>
    )
};

export default injectIntl(salesOrder);