import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UnpaidInvoicesComponent from '../../../../../components/modal/modalBody/customer/UnpaidInvoices';
import { fetchUnpaidInvoices, fetchUnpaidInvoicesCount, resetPaginatedData } from './action';
import { fetchPaginationDataIfNeeded, fetchDataIfNeeded } from  '../../../../../utils';

class UnpaidInvoices extends Component {

    constructor(props) {
        super(props);
        this.state = {
          pageSize: 100,
          pageNumber: 1,
          searchedText: ''
        };
      }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            customerId: this.props.customerData && this.props.customerData.customerId,
            pageNumber: 1,
            pageSize: 100
        };
        fetchPaginationDataIfNeeded('fetchUnpaidInvoices', 'unpaidInvoiceList', this.props, payload, true);
        fetchDataIfNeeded('fetchUnpaidInvoicesCount', 'unpaidInvoiceListCount', this.props, payload, true);
        
    }

    render() {
        return <UnpaidInvoicesComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        unpaidInvoiceList: state.customer.unpaidInvoiceList,
        unpaidInvoiceListCount: state.customer.unpaidInvoiceListCount,
        permissions: state.auth.permissions,
        modalLoading:state.common.modalLoading

    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUnpaidInvoices,
    fetchUnpaidInvoicesCount,
    resetPaginatedData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnpaidInvoices);