import React from 'react';
import { injectIntl } from 'react-intl';
import * as moment from 'moment';
import { CONSTANTS } from '../../../../../../static/constants';
import { fixedDecimalAndFormateNumber, getFinancialYear } from '../../../../../../utils';

const BalanceSheetNotes = (props) => {
    const { companyInfo, allLedgerAccounts } = props;
    const notesLedger = [];
    let totalAmount = 0;
    const financialYear = getFinancialYear(companyInfo);

    // const getAmountByLedgerGroup = (groupName, category, type, toReduceType) => {
    //     let randomTotalAmount = 0;

    //     for (let i = 0; i < allLedgerAccounts.length; i++) {

    //         if (allLedgerAccounts[i].ledgerAccountGroupName === groupName && allLedgerAccounts[i].categoryName === category && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) !== toReduceType) {
    //             randomTotalAmount = (randomTotalAmount + (allLedgerAccounts[i].amountDifference || 0));

    //         } else if (allLedgerAccounts[i].ledgerAccountGroupName === groupName && allLedgerAccounts[i].categoryName === category && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === toReduceType) {
    //             randomTotalAmount = (randomTotalAmount - (allLedgerAccounts[i].amountDifference || 0));
    //         }
    //     }

    //     return parseFloat((randomTotalAmount || 0).toFixed(2))

    // }

    // const getTotalForLedger = (type) => {
    //     let randomTotalAmount = 0;
    //     switch (type) {
    //         case "Expenses": {
    //             for (var i = 0; i < allLedgerAccounts.length; i++) {
    //                 if ((allLedgerAccounts[i].categoryName === "Cost of Materials" || allLedgerAccounts[i].categoryName === "Employee Benefit Expense" || allLedgerAccounts[i].categoryName === "Other Expenses" || allLedgerAccounts[i].categoryName === "Taxes" || allLedgerAccounts[i].categoryName === "Finance Cost") && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
    //                     randomTotalAmount = (randomTotalAmount + (allLedgerAccounts[i].amountDifference || 0));
    //                 } else if ((allLedgerAccounts[i].categoryName === "Cost of Materials" || allLedgerAccounts[i].categoryName === "Employee Benefit Expense" || allLedgerAccounts[i].categoryName === "Other Expenses" || allLedgerAccounts[i].categoryName === "Taxes" || allLedgerAccounts[i].categoryName === "Finance Cost") && ((allLedgerAccounts[i].nature && allLedgerAccounts[i].nature.toUpperCase()) || allLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
    //                     randomTotalAmount = (randomTotalAmount - (allLedgerAccounts[i].amountDifference || 0));
    //                 }
    //             }
    //             break;
    //         }
    //         case "Income": {
    //             for (var j = 0; j < allLedgerAccounts.length; j++) {
    //                 if ((allLedgerAccounts[j].categoryName === "Revenue from Operations" || allLedgerAccounts[j].categoryName === "Other Income") && ((allLedgerAccounts[j].nature && allLedgerAccounts[j].nature.toUpperCase()) || allLedgerAccounts[j].accountingEntry.toUpperCase()) === "CREDIT") {
    //                     randomTotalAmount = (randomTotalAmount + (allLedgerAccounts[j].amountDifference || 0));

    //                 } else if ((allLedgerAccounts[j].categoryName === "Revenue from Operations" || allLedgerAccounts[j].categoryName === "Other Income") && ((allLedgerAccounts[j].nature && allLedgerAccounts[j].nature.toUpperCase()) || allLedgerAccounts[j].accountingEntry.toUpperCase()) === "DEBIT") {
    //                     randomTotalAmount = (randomTotalAmount - (allLedgerAccounts[j].amountDifference || 0));
    //                 }
    //             }
    //             break;
    //         }
    //         default: {
    //             break;
    //         }
    //     }
    //     return parseFloat((randomTotalAmount || 0).toFixed(2))
    // }

    const getAmountByLedgerCategoryGroup = (groupName, category, type, toReduceType) =>{
        let randomTotalAmount = 0;
        
        for(var i=0;i < allLedgerAccounts.length;i++){
            if( allLedgerAccounts[i].categoryGroupName === groupName &&   allLedgerAccounts[i].categoryName === category && (( allLedgerAccounts[i].nature &&  allLedgerAccounts[i].nature.toUpperCase()) ||  allLedgerAccounts[i].accountingEntry.toUpperCase()) !== toReduceType){
                randomTotalAmount = randomTotalAmount+ allLedgerAccounts[i].amountDifference;
                
            }else if( allLedgerAccounts[i].categoryGroupName === groupName &&   allLedgerAccounts[i].categoryName === category && (( allLedgerAccounts[i].nature &&  allLedgerAccounts[i].nature.toUpperCase()) ||  allLedgerAccounts[i].accountingEntry.toUpperCase()) === toReduceType){
                randomTotalAmount = randomTotalAmount- allLedgerAccounts[i].amountDifference;
            }
        }

         return parseFloat((randomTotalAmount || 0).toFixed(2));
        
    }

    const setAppropriateAccountData = () => {
        let amount = Number(((getAmountByLedgerCategoryGroup("Income", "Revenue from Operations", "amount", 'DEBIT') + getAmountByLedgerCategoryGroup("Income", "Other Income", "amount", 'DEBIT'))-(getAmountByLedgerCategoryGroup("Expenses", "Cost of Materials", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Purchases", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Employee Benefit Expense", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Finance Cost", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Depreciation & Amortisation", "amount", 'CREDIT') + getAmountByLedgerCategoryGroup("Expenses", "Other Expenses", "amount", 'CREDIT'))) || 0);;
        allLedgerAccounts.forEach(function (data) {
            if (data.ledgerAccountName === 'Profit & Loss Appropriation') {
                if (amount < 0) {
                    data.debitAmount = amount;
                } else {
                    data.creditAmount = amount
                }
                data.amountDifference = amount;
            }
        });
    }

    setAppropriateAccountData();



    allLedgerAccounts.forEach(function (elem) {
        if (elem[props.keyName] === props.tabType && (elem.nature && elem.nature.toUpperCase() !== props.toReduceType)) {
            notesLedger.push(elem);
            totalAmount = totalAmount + (elem.amountDifference || 0);
        } else if (elem[props.keyName] === props.tabType && (elem.nature && elem.nature.toUpperCase() === props.toReduceType)) {
            notesLedger.push(elem);
            totalAmount = totalAmount - (elem.amountDifference || 0);
        } else if (elem[props.keyName] === props.tabType) {
            if(elem.ledgerAccountName === "Profit & Loss Appropriation"){
                totalAmount = totalAmount + (elem.amountDifference || 0);
            }
            notesLedger.push(elem);
        }
        
    });


    return (
        <div className="balance-sheet-popup-container">
            <table className="balance-sheet-table">
               <tbody>
               <tr>
                    <td></td>
                    <td><strong>{(financialYear.fyEndDate && moment(financialYear.fyEndDate).format(CONSTANTS.DISPLAY_DATE_FORMAT_FY))}</strong></td>
                    <td></td>
                </tr>

                {
                    notesLedger.map((elem) => {
                        return <tr>
                            <td>{elem.ledgerAccountName}</td>
                            {elem.nature && props.toReduceType !== elem.nature.toUpperCase() ? <td> {elem.amountDifference.toFixed(2)}</td> : null}
                            {!elem.nature ? <td> {elem.amountDifference.toFixed(2)}</td> : null}
                            {elem.nature && props.toReduceType === elem.nature.toUpperCase() ? <td> {elem.amountDifference.toFixed(2)}</td> : null }
                            <td>&nbsp;</td>
                        </tr>
                    })
                }

                <tr>
                    <td><strong>Total</strong></td>
                    <td> {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalAndFormateNumber(totalAmount)}</td>
                    <td></td>
                </tr>
               </tbody>
            </table>
        </div>
    );
};

export default injectIntl(BalanceSheetNotes);
