import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PurchaseInvoiceDetailsComponent from '../../../../../components/modal/modalBody/supplier/PurchaseInvoiceDetail';
import { fetchPurchasedInvoiceDetail, approveInvoice, uploadStamp, deleteStamp, getAvailableBalance } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchPurchaseInvoices, getPurchaseInvoicesCount, updateUserStatus } from '../../../../supplier/invoice/Listing/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';
import { deleteAttectedFile } from '../../../../../actions/commonActions';
import { getAllGstRates } from '../../../../drawer/inventory/action';
import { deleteDocument } from '../../../../supplier/invoice/StandardPurchaseInvoice/action';
class PurchaseInvoiceDetail extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, supplierId: this.props.invoicePayload.supplierId, invoiceMasterId: this.props.invoicePayload.invoiceMasterId };
        fetchDataIfNeeded('fetchPurchasedInvoiceDetail', 'invoiceData', this.props, payload, true);
        fetchDataIfNeeded('getAllGstRates', 'allGstRates', this.props, { ...payload, type: 'GOODS', pageNumber: 0, pageSize: 50 });

        this.props.getAvailableBalance(payload);
    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
             <PurchaseInvoiceDetailsComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
             </div>
    }

}


const mapStateToProps = (state) => {
    return {
        invoiceData: state.purchaseInvoice.purchaseInvoiceDetail,
        userInfo: state.common.userInfo,
        availableDebits: state.purchaseInvoice.availableDebits,
        permissions: state.auth.permissions,
        allGstRates: state.inventory.allGstRates,
        detailLoading: state.common.detailLoading
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPurchaseInvoices,
    fetchPurchasedInvoiceDetail,
    getPurchaseInvoicesCount,
    downloadFileFromBucket,
    generatePDF, printPDF,
    approveInvoice, 
    uploadStamp, 
    deleteStamp,
    getAvailableBalance,
    updateUserStatus,
    deleteAttectedFile,
    getAllGstRates,
    deleteDocument
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceDetail);