import axios from 'axios';
import React from 'react';
import config from '../../../../../config/environmentConfig';
import { SO_ACTION_LIST, COMMON_ACTIONS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';

const { lang } = require('../../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const fetchEcomDetail = (payload) => {
 
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecom_order/getCustomerPreOrdersBycustomerPreorderMasterId?relationshipId=${payload.relationshipId}&customerPreOrderMasterId=${payload.customerPreOrderMasterId}`)
      .then(res => {
        const { data } = res
 
        dispatch({ type: SO_ACTION_LIST.ECOM_DETAILS, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.ECOM_DETAILS;
        }
      })
  }
}

export const isPreOrderConvertable = (payload,callback) => {
 
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/salesOrder/isPreOrderConvertable?relationshipId=${payload.relationshipId}&customerPreOrderMasterId=${payload.customerPreOrderMasterId}`)
      .then(res => {
        const { data } = res
           callback(data);
      })
      .catch((err) => {
        if (!err.__isRetryRequest) {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SO_ACTION_LIST.ECOM_DETAILS;
        }
      })
  }
}