import axios from 'axios';
import config from '../../../../config/environmentConfig';
import { COMMON_ACTIONS, PRODUCT_ACTION_LIST } from '../../../../static/constants';
import {  showToasterMessage } from '../../../../utils';
const { lang } = require('../../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

export const updateInventoryItem = (payload, props) => {
    // var formData = new FormData();
    // formData.append('inventory', JSON.stringify(payload, true));
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/eCommerce/updateOnlineStorePrice`,
        [payload]
        )
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({description: 'Updated successfully.',  messageType: 'success' });
          props.fetchProducts({relationshipId: payload.relationshipId, pageNumber: props.pageNumber || 1, pageSize: props.pageSize || 100});
        })
        .catch(err => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
    }
  }

  export const approvePriceChange = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/approvePriceChange`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchProducts({relationshipId: payload.relationshipId, pageNumber: props.pageNumber || 1, pageSize: props.pageSize || 100});
          showToasterMessage({
            description: (res || {}).message || 'Approved successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }

  export const rejectPriceChange = (payload, props) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inventoryItem/rejectPriceChange`, payload)
        .then(res => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          props.fetchProducts({relationshipId: payload.relationshipId, pageNumber: props.pageNumber || 1, pageSize: props.pageSize || 100});
          showToasterMessage({
            description: (res || {}).message || 'Approved successfully.',
            messageType: 'success'
          })
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }

  export const fetchProducts = (payload, history) => {
    const searchedTextQuery = payload.searchedText && payload.searchedText.length ? `&searchString=${payload.searchedText}` : '';
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      let url = '/inventoryItem/getAllInventoryItems';
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}${url}?relationshipId=${payload.relationshipId}&warehouseMasterId=${payload.warehouseMasterId || 0}&pageSize=${payload.pageSize || 10}&pageNumber=${payload.pageNumber - 1}${searchedTextQuery}`
        )
        .then(res => {
          const data = {
            pageNo: payload.pageNumber,
            list: res.data
          }
          dispatch({ type: PRODUCT_ACTION_LIST.PRODUCT_LIST, data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(!err.__isRetryRequest){
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
          }else{
            err.actionToCall = PRODUCT_ACTION_LIST.PRODUCT_LIST;
            err.data = {
              pageNo: payload.pageNumber,
              list: []
            };
          }
        })
    }
  }