import React from "react";
import "./price.scss";
import {Button, Skeleton} from "antd";
import alpideLogo from "../../assets/images/logoAlpide.svg";
import erpLogo from "../../assets/images/erpIcon.svg";
import commerceLogo from "../../assets/images/commerceIcon.svg";
import educationLogo from "../../assets/images/educationIcon.svg";
import crmLogo from "../../assets/images/crmIcon.svg";
import hrmsLogo from "../../assets/images/hrmsIcon.svg";
import SelectedPlanInfo from "./selectPlanModal";


function PricingSetup(props){

    const { alpideProducts } = props;

    function getIconFromName(name){
        switch(name){
            case 'Cloud ERP':
                return erpLogo;
            case 'E-Commerce':
                return commerceLogo;
            case 'Education ERP':
                return educationLogo;
            case 'CRM':
                return crmLogo;
            case 'HRMS':
                return hrmsLogo;
            default: 
                return erpLogo;
        }
    }

    return(
        <div className="chooseProductPage">
            <div className="headerContainer">
                <div className="contaniner">
                    <img src={alpideLogo} />
                </div>
            </div>
            <div className="chooseProductContainer">
                <div className="contaniner">
                    <Skeleton loading={props.planLoading} paragraph={{ rows: 2}} active>
                    <div className="headingContent">
                        <h2>
                        Choose the Alpide Product to Kickstart Your Journey
                        </h2>
                        <p>
                        Automate business operations, access real-time insights, boost control, ensure data precision, cut costs, and elevate customer service with Alpide.
                        </p>
                    </div>
                    </Skeleton>

                    {/* module tiles */}
                    <Skeleton loading={props.planLoading} paragraph={{ rows: 5}} active>
                    <div className="chooseProduct">
                        {alpideProducts?.length ? alpideProducts.map((product, ind) => {
                            return(
                                <div className="product" key={`plan`+ind}>
                                    <div>
                                        <img src={getIconFromName(product.alpideProductName)} />
                                    </div>
                                    <div className="productName">
                                        <h3>
                                            {product.alpideProductName}
                                        </h3>
                                        <p>
                                            {product.description}
                                        </p>
                                        <Button  
                                            className="getStartButton" 
                                            type="button"
                                            onClick= {()=>{
                                                props.pushModalToStack({
                                                    hideTitle:true,
                                                    modalBody: <SelectedPlanInfo {...props} index={ind} productName={product.alpideProductName} alpideProductId={product.alpideProductId}  />,
                                                    width: 500,
                                                    hideFooter: true,
                                                    wrapClassName: "choose-product-modal back-blur",
                                                })
                                            }}>
                                                Get Started
                                            </Button>
                                    </div>
                                </div>
                            )
                            })
                            :
                            ''
                        }
                    </div>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}
export default PricingSetup