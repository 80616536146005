import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, INTEGRATION, STUDENT_REGISTRATION_ACTION_LIST } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');

// export const fetchXecheckroAuthURL = (history) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}alpide-xero/xeroRelationshipConfig/generateOauthUrl`)
//       .then(res => {
//         window.location = res.data;
//       })
//       .catch((err = {}) => {
//         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         showToasterMessage({
//           description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
//         })
//       })
//   }
// }


export const fetchAvailablePaymentGateway =  (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getAllPaymentGateways?relationshipId=${payload.relationshipId}`)
      .then(res => {
        const { data } = res;
        dispatch({type: STUDENT_REGISTRATION_ACTION_LIST.AVAILABLE_PAYMENT_GATEWAY, data: data})
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const getPaypalConfiguration = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PAYMENT_GATEWAY_BASE_URL}${config.rootContextPaymentGateway}/paymentGateway/getConfigData?relationshipId=${payload.relationshipId}&gatewayName=${payload.gatewayName}`)
      .then(res => {
        if(payload.gatewayName === 'paypal'){
          dispatch({
            type: COMMON_ACTIONS.PAYPAL_CONFIG_RELATIONSHIP_DATA,
            data: res.data
          });  
        } else if(payload.gatewayName === 'stripe'){
          dispatch({
            type: COMMON_ACTIONS.STRIPE_CONFIG_RELATIONSHIP_DATA,
            data: res.data
          });  
        }else{
          dispatch({
            type: COMMON_ACTIONS.PAYPAL_DIRECT_CONFIG_RELATIONSHIP_DATA,
            data: res.data
          });
        }
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const getStripeConfiguration = (payload) =>{
  // return dispatch => {
  //   dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
  //   return axios
  //     .get(`${config.PAYMENT_GATEWAY_BASE_URL}${config.rootContextPaymentGateway}/paymentGateway/getConfigData?relationshipId=${payload.relationshipId}&gatewayName=${payload.gatewayName}`)
  //     .then(res => {
  //         dispatch({
  //           type: COMMON_ACTIONS.STRIPE_CONFIG_RELATIONSHIP_DATA,
  //           data: res.data
  //         }); 
  //       ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
  //     })
  //     .catch((err = {}) => {
  //       ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
  //       showToasterMessage({
  //         description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
  //       })
  //     })
  // }
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getPaymentGatewayByName?relationshipId=${payload.relationshipId}&paymentGatewayName=${payload.gatewayName}`)
      .then(res => {
          dispatch({
            type: COMMON_ACTIONS.STRIPE_CONFIG_RELATIONSHIP_DATA,
            data: (res.data || [])[0] || {}
          }); 
          
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}



export const generatePlaidLinkToken = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`http://localhost:8080/alpide-plaid/plaid/create_link_token`)
      .then(res => {
        dispatch({
          type: INTEGRATION.PLAID_LINK_TOKEN,
          data: res.data
        });
         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}


export const setAccessToken = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`http://localhost:8080/alpide-plaid/plaid/set_access_token?public_token=${payload.publicToken}`)
      .then(res => {
         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}


export const disconnectConfiguration = (payload, props) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/delete`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType:"success", description: ((res || {}).data || {}).message || 'Disconnected Successfully'});
        fetchAvailablePaymentGateway(payload)(dispatch);
       // getCardConnectConfiguration({...payload, gatewayName: 'CardPointe'})(dispatch);
        payload.paymentGatewayName ==='Stripe' && getStripeConfiguration({...payload, gatewayName: 'Stripe'})(dispatch);
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}


export const saveConfiguration = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.PAYMENT_GATEWAY_BASE_URL}${config.rootContextPaymentGateway}/paymentGateway/saveGatewayConfig`, payload)
      .then(res => {
        showToasterMessage({
          messageType:"success",
          description: 'Now you can collect payments online'
        })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        dispatch({
          type: COMMON_ACTIONS.PAYPAL_CONFIG_RELATIONSHIP_DATA,
          data: res.data
        });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
         
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const getStripeAccountOnboardingLink = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PAYMENT_GATEWAY_BASE_URL}${config.rootContextPaymentGateway}/stripe/getStripeAuthorizationUrl`)
      .then(res => {
        window.open(res.data);
         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const savePaypalToken = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PAYMENT_GATEWAY_BASE_URL}${config.rootContextPaymentGateway}/paymentGateway/save_token?code=${payload.code}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        props.history.push({
          pathname: '/admin/payment-integration',
          search: '',
          state: { }
        });
        window.location.reload();
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const generatePaypalAuthLink = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PAYMENT_GATEWAY_BASE_URL}${config.rootContextPaymentGateway}/paymentGateway/getAuthorizationUrl`)
      .then(res => {
        window.open(res.data);
         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const saveStripeAccount = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PAYMENT_GATEWAY_BASE_URL}${config.rootContextPaymentGateway}/stripe/save_stripe_acc?accountId=${payload.accountId}&relationshipId=${payload.relationshipId}`)
      .then(res => {
        props.history.push({
          pathname: '/admin/payment-integration',
          search: '',
          state: { }
        });
        window.location.reload();
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}


export const updateName = (payload, props) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/updateName`, payload)
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({messageType:"success", description: ((res || {}).data || {}).message || 'Saved Successfully'});
        fetchAvailablePaymentGateway(payload)(dispatch);
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}
export const getCardConnectConfiguration = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/getPaymentGatewayByName?relationshipId=${payload.relationshipId}&paymentGatewayName=${payload.gatewayName}`)
      .then(res => {
          dispatch({
            type: COMMON_ACTIONS.CARD_CONNECT_CONFIG_RELATIONSHIP_DATA,
            data: res.data
          }); 
          
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const saveCardPointConfiguration = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/saveAuthNet`, payload)
      .then(res => {
        showToasterMessage({
          messageType:"success",
          description: 'Now you can collect payments online'
        })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        fetchAvailablePaymentGateway({...payload, gatewayName: 'CardPointe'})(dispatch);
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
         
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}


export const saveStripeConfiguration = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/payment/saveAuthNet`, payload)
      .then(res => {
        showToasterMessage({
          messageType:"success",
          description: 'Now you can collect payments online'
        })
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        getStripeConfiguration({...payload, gatewayName: 'Stripe'})(dispatch);
        
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
         
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const generateRazorpayAuthLink = (payload) =>{
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.PAYMENT_GATEWAY_BASE_URL}/${config.rootContextRazorpay}/razorpay/getAuthUrl?relationshipId=${payload.relationshipId}`)
      .then(res => {
        window.open(res.data, "_self");
         ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}

export const saveRazorpayConfiguration = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextRazorpay}/razorpay/saveToken`, payload)
      .then(res => {
        showToasterMessage({
          messageType:"success",
          description: 'Now you can collect payments online'
        })
        props.history.push({
          pathname: '/admin/payment-integration',
          search: '',
          state: { }
        });
        window.location.reload();
        
      })
      .catch((err = {}) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
  }
}


export const createConnectedAccount = (props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextStripe}/payment/createConnectedAccount?relationshipId=${props.companyInfo?.relationshipId || 0}`)
      .then(res => {
        props.createAccountLink(res.data, props);
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

export const createAccountLink = (payload, props) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextStripe}/payment/createAccountLink`, payload)
      .then(res => {
        showToasterMessage({
          messageType:"success",
          description: 'Now you can collect payments online'
        })
        if(res.data){
          const url = res.data;
          const popup = window.open(url, '_blank');

          if (!popup || popup.closed || typeof popup.closed === 'undefined') {
            props.updateState({StripeOnboardingUrl: url})
          }
        }else{
          throw new Error();
        }
      })
      .catch((err = {}) => {
        showToasterMessage({
          description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
        })
      })
      .finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  }
}

