import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import KioskComp from "../../components/kiosk";
import { fetchDataIfNeeded } from "../../utils";
import { getAllKiosk } from "./action";

class Kiosk extends Component {
  
  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
    };
    fetchDataIfNeeded("getAllKiosk", "kioskList", this.props, payload);
  }

  render() {
    return (
      <KioskComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></KioskComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    kioskList: state.kioskReducer.kioskList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllKiosk,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Kiosk);
