import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, Button, DatePicker, Tooltip } from 'antd';
import { TextBox } from '../../../general/TextBox';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { Dropdown } from '../../../general/Dropdown';
import { getCurrentDateForBackend, getMomentDateForUI, getMomentDateForUIReadOnly, getUserDefinedName } from '../../../../utils';
import { CONSTANTS, ICONS } from '../../../../static/constants';


function EducationDrawerComp(props) {

  const [educationData, setEducationData] = useState(props.educationData ||  {});

  const closeDrawer = () => {
    props.updateModalState({
      educationDrawerVisible: false
    })
  }

  const handleSubmit = () =>{

    const {institutionName, institutionType, courseName, score, startDate} = educationData;

    if(!institutionType || !institutionName || !courseName || !score || !startDate){
      props.updateDrawerState({submittedOnce: true});
      return;
    }
    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      contactId: props.contact?.contactId || 0,
      customerId: props.contact?.customerId || 0,

      ...educationData,
      startDate: getCurrentDateForBackend(educationData.startDate),
      endDate: educationData.endDate ? getCurrentDateForBackend(educationData.endDate) : null,
      version: educationData.version || 0,
    }
    props.saveContactEducation(payload, props);
    return
  }

  return (
    <Drawer
      title={<>Add Education<div className='light-txt'>{`${props.contact?.firstName} ${props.contact?.lastName}`}</div></>}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.educationDrawerVisible || true}
      footer={
        <>
          <Button onClick={closeDrawer}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div className="i-label">Education/Institution Type  <span className="required">*</span> </div>
          <Dropdown
            items={[
              { name: "School", value: "school" },
              { name: "College/University", value: "college" },
            ]}
            optionKeyName="value"
            valueKeyName="name"
            value={educationData.institutionType}
            placeholder="Choose Education Type"
            onSelect={(selectedValue, option) => {
              setEducationData({...educationData, institutionType: selectedValue})
            }}
          />
          <ErrorMsg validator={() => { return !props.submittedOnce || educationData.institutionType }} />
        </Col>

        <Col span={24}>
          <div className="i-label">{educationData.institutionType === "college"  ? "College/University Name"  : "School Name" } <span className="required">*</span></div>
          <TextBox
            placeholder={educationData.institutionType === "college"  ? "College/University"  : "School"}
            value={educationData.institutionName}
            onChange={(e) => {
              setEducationData({...educationData, institutionName  : e.target.value})
            }}
          />
          <ErrorMsg validator={() => { return !props.submittedOnce || educationData.institutionName }} />
        </Col>

        <Col span="12">
          <div className="i-label">{getUserDefinedName("Course", props)}<span className="required">*</span></div>
          <TextBox  
            placeholder={getUserDefinedName("Course Name", props)} 
            value={educationData.courseName}
            onChange={(e) => {
              setEducationData({...educationData, courseName: e.target.value})
            }} 
          />
        </Col>

        <Col span="12">
          <div className="i-label">Field of Study/Major</div>
          <TextBox 
            placeholder={"Field of Study/Major"} 
            value={educationData.major}
            onChange={(e) => {
              setEducationData({...educationData, major: e.target.value})
            }} 
          />
        </Col>

        <Col span="12">
          <div className="i-label">
            Score/GPA<span className="required mr5">*</span>
            <Tooltip placement="top" title={'The grade point average or final grade achieved, if applicable'} trigger="click">
              <i className={`${ICONS.HELP_P} cursor-pointer`} />
            </Tooltip>
          </div>
          <TextBox 
            placeholder={"Score/GPA"}
            value={educationData.score}
            onChange={(e) => {
              setEducationData({...educationData, score: e.target.value})
            }}
          />
          <ErrorMsg validator={() => { return !props.submittedOnce || educationData.score }} />
        </Col>

        <Col span="12">
          <div className="i-label">
            Honors/Awards
            <Tooltip placement="top" title={'Any academic honors or awards received during the course of study'} trigger="click">
              <i className={`${ICONS.HELP_P} cursor-pointer ml5`} />
            </Tooltip>
          </div>
          <TextBox 
            placeholder={"Honors/Awards"}
            value={educationData.awards}
            onChange={(e) => {
              setEducationData({...educationData, awards: e.target.value})
            }}
          />
        </Col>
        {
          educationData.institutionType === 'college' ? <Col span="12">
          <div className="i-label">
            Thesis/Dissertation Title
            <Tooltip placement="top" title={'For graduate-level education, the title of any thesis or dissertation completed'} trigger="click">
              <i className={`${ICONS.HELP_P} cursor-pointer ml5`} />
            </Tooltip>
          </div>
          <TextBox 
            placeholder={"Thesis/Dissertation Title"}
            value={educationData.thesis}
            onChange={(e) => {
              setEducationData({...educationData, thesis: e.target.value})
            }}
          />
        </Col>
        :
        ''
        }

        <Col span="12">
          <div className="i-label">Start Date <span className="required">*</span> </div>
          <DatePicker
            placeholder="Start Date"
            allowClear={false}
            format={CONSTANTS.DISPLAY_DATE_FORMAT}
            value={educationData.startDate ? getMomentDateForUI({ date: educationData.startDate }) : ''}
            onChange={(selectedDate) => {
              setEducationData((preData)=>{
                return {...preData, startDate: selectedDate}
              })
            }}
          />
          <ErrorMsg validator={() => { return !props.submittedOnce || educationData.startDate }} />
        </Col>

        <Col span="12">
          <div className="i-label">End Date</div>
          <DatePicker
            placeholder="End Date"
            allowClear={false}
            disabled={!educationData.startDate}
            disabledDate={(current)=>{
              return current < educationData.startDate
            }}
            format={CONSTANTS.DISPLAY_DATE_FORMAT}
            value={educationData.endDate ? getMomentDateForUI({ date: educationData.endDate }) : ''}
            onChange={(selectedDate) => {
              setEducationData((preData)=>{
                return {...preData, endDate: selectedDate}
              })
            }}
          />
        </Col>

      </Row>
    </Drawer>
  );
}

export default injectIntl(EducationDrawerComp);
