import axios from 'axios';
import config from '../../../config/environmentConfig';
import { SALES_INVOICE_ACTION_LIST, COMMON_ACTIONS } from '../../../static/constants';
import { getMomentDateForUIReadOnly, showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const generateEWayBillDeatils = (payload) => {
  
    const obj =   generateEWayBillData(payload)
   
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .put(`${config.API_BASE_URL}${config.API_VERSION}/alpide-gst/api/v1/ewb/generatedEwb`, obj)
        .then(res => {
     
          if(res.data?.data.status === "FAILURE"){

            (res.data?.data.errors||[]).map((item)=>{
               return showToasterMessage({
                 description: item?.msg,
                 messageType: 'error'
               })
            })
          }
          else{
           showToasterMessage({
             description: (res || {}).message || 'E-WayBill Generated Successfully',
             messageType: 'success'
           })
           payload.updateDrawerState({
            eWayBillDrawerVisible: false
        })
          }
         
        })
        .catch((err = {}) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (err.__isRetryRequest) {
          } else {
            showToasterMessage({
              description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
            })
          }
        })
    }
  }


  const generateEWayBillData = (payload)=>{
     return {
        "userGstin": payload.userGstin,
        "transMode": payload.transportMode,
        "vehTyp": payload.vechileType.referCode,
        "transDist": payload.transportDist,
        "subSplyTyp": payload.subSupplyType,
        "subSplyDes": payload.subSupplyDes,
        "transName": payload.transportName,
        "transDocNo": payload.transportdocNo,
        "transDocDate":  getMomentDateForUIReadOnly({date: payload.transporterdocDate , format:"DD-MM-YYYY"}) ,
        "vehNo": payload.vechileNo,
        "transId": payload.transportId,
        "pobewb": payload.placeofBusinessEWB,
        "paddr1": payload.pAddress1,
        "paddr2": payload.pAddress2,
        "ploc": payload.placeLocation,
        "pstcd": payload.stateCode,
        "ppin": payload.pincode,
        "dNm": payload.dispatchTraderName,
        "daddr1": payload.disAddress1,
        "daddr2": payload.disAddress2,
        "disloc": payload.disLocation,
        "disstcd": payload.disStateCode,
        "dispin": payload.disPincode,
        "irn": payload.irnNo,
    }
  }