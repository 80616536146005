import React, { Fragment, useEffect, useState } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import "./inventoryDrawer.scss";
import { TextBox } from "../../general/TextBox";

import { ErrorMsg } from "../../general/ErrorMessage";
// import ProductDetailsTab from "./ProductDetailsTab";

import { CONSTANTS } from "../../../static/constants";

import "@ant-design/compatible/assets/index.css";

import { Col, Row, Input, Tabs, Select, DatePicker } from "antd";
import { getMomentDateForUI } from "../../../utils";

const ValueDetails = (props) => {
  return (
    <div>
      <div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage id="Final Invoice Value" defaultMessage="" />
              <span className="required">*</span>
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="Total Assessable/ Taxable value of all items"
                defaultMessage=""
              />
              <span className="required">*</span>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Final Invoice Value"}
                type="text"
                value={props.finalInvoiceValue}
                onChange={(e) => {
                  props.updateState({
                    finalInvoiceValue: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.finalInvoiceValue;
                }}
                message={"Invoice Value is required"}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"Taxable Value"}
                type="text"
                maxLength={20}
                value={props.totalTaxableValue}
                onChange={(e) => {
                  props.updateState({
                    totalTaxableValue: e.target.value,
                  });
                }}
              />
              <ErrorMsg
                validator={() => {
                  return !props.submittedOnce || !!props.totalTaxableValue;
                }}
                message={"Taxable Value is required"}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="Total IGST value of all items"
                defaultMessage=""
              />
            </Col>

            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="Total CGST value of all items"
                defaultMessage=""
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Total IGST"}
                type="text"
                value={props.totalIGST}
                onChange={(e) => {
                  props.updateState({
                    totalIGST: e.target.value,
                  });
                }}
              />
            </Col>

            <Col span={12}>
              <TextBox
                placeholder={"Total CGST"}
                type="text"
                maxLength={20}
                value={props.totalCGST}
                onChange={(e) => {
                  props.updateState({
                    totalCGST: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 8]}>
            <Col span={12} className="drawer-label">
              <FormattedMessage
                id="Total SGST value of all items"
                defaultMessage=""
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                maxLength={20}
                placeholder={"Total SGST"}
                type="text"
                value={props.totalSGST}
                onChange={(e) => {
                  props.updateState({
                    totalSGST: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <div>
        {props.optionalFields ? (
          <div className="mt20">
            <div className="drawer-heading">Optional Details</div>
            <div>
              <Row gutter={[16, 8]}>
                <Col span={12} className="drawer-label">
                  <FormattedMessage
                    id="Discount on invoice value if any"
                    defaultMessage=""
                  />
                </Col>

                <Col span={12} className="drawer-label">
                  <FormattedMessage id="Freight" defaultMessage="" />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <TextBox
                    maxLength={20}
                    placeholder={"Discount on Invoice Value"}
                    type="text"
                    value={props.discountInvoiceValue}
                    onChange={(e) => {
                      props.updateState({
                        discountInvoiceValue: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col span={12}>
                  <TextBox
                    placeholder={"Freight"}
                    type="text"
                    maxLength={20}
                    value={props.freight}
                    onChange={(e) => {
                      props.updateState({
                        freight: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row gutter={[16, 8]}>
                <Col span={12} className="drawer-label">
                  <FormattedMessage id="Insurance" defaultMessage="" />
                </Col>

                <Col span={12} className="drawer-label">
                  <FormattedMessage
                    id="Packaging & Forwarding"
                    defaultMessage=""
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <TextBox
                    placeholder={"Insurance"}
                    type="text"
                    maxLength={20}
                    value={props.insurance}
                    onChange={(e) => {
                      props.updateState({
                        insurance: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col span={12}>
                  <TextBox
                    placeholder={"Packaging & Forwarding"}
                    type="text"
                    maxLength={20}
                    value={props.pacakaging}
                    onChange={(e) => {
                      props.updateState({
                        pacakaging: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row gutter={[16, 8]}>
                <Col span={12} className="drawer-label">
                  <FormattedMessage
                    id="Other Charges if Any"
                    defaultMessage=""
                  />
                </Col>

                <Col span={12} className="drawer-label">
                  <FormattedMessage
                    id="Total CESS value of all items"
                    defaultMessage=""
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <TextBox
                    placeholder={"Other Charges"}
                    type="text"
                    maxLength={20}
                    value={props.otherCharges}
                    onChange={(e) => {
                      props.updateState({
                        otherCharges: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col span={12}>
                  <TextBox
                    placeholder={"Total CESS value of all items"}
                    type="text"
                    maxLength={20}
                    value={props.totalCESS}
                    onChange={(e) => {
                      props.updateState({
                        totalCESS: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row gutter={[16, 8]}>
                <Col span={12} className="drawer-label">
                  <FormattedMessage
                    id="Total State CESS value of all items"
                    defaultMessage=""
                  />
                </Col>

                <Col span={12} className="drawer-label">
                  <FormattedMessage id="Rounded off amount" defaultMessage="" />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <TextBox
                    placeholder={"Total State CESS"}
                    type="text"
                    maxLength={20}
                    value={props.totalStateCESS}
                    onChange={(e) => {
                      props.updateState({
                        totalStateCESS: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col span={12}>
                  <TextBox
                    placeholder={"Round Off Amount"}
                    type="text"
                    maxLength={20}
                    value={props.roundOffAmount}
                    onChange={(e) => {
                      props.updateState({
                        roundOffAmount: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row gutter={[16, 8]}>
                <Col span={12} className="drawer-label">
                  <FormattedMessage id="TCS Rate" defaultMessage="" />
                </Col>

                <Col span={12} className="drawer-label">
                  <FormattedMessage id="TCS Value" defaultMessage="" />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <TextBox
                    placeholder={"TCS Rate"}
                    type="text"
                    maxLength={20}
                    value={props.TCSRate}
                    onChange={(e) => {
                      props.updateState({
                        TCSRate: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col span={12}>
                  <TextBox
                    placeholder={"TCS Value"}
                    type="text"
                    maxLength={20}
                    value={props.TCSValue}
                    onChange={(e) => {
                      props.updateState({
                        TCSValue: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row gutter={[16, 8]}>
                <Col span={12} className="drawer-label">
                  <FormattedMessage
                    id="Invoice Value before TCS"
                    defaultMessage=""
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <TextBox
                    placeholder={"Invoice Value"}
                    type="text"
                    maxLength={20}
                    value={props.InvoiceValueBeforeTCS}
                    onChange={(e) => {
                      props.updateState({
                        InvoiceValueBeforeTCS: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default injectIntl(ValueDetails);
