import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
// import { Link } from 'react-router-dom';
// import { AgGridReact } from 'ag-grid-react';
import { Skeleton, Pagination, Tooltip, Empty } from "antd";
import moment from "moment-timezone";
import { fetchPaginationDataIfNeeded } from "../../../../utils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { CONSTANTS } from "../../../../static/constants";
import StandardRFQ from "../../../../containers/supplier/rfq/StandardRFQ";

const RfqDraft = (props) => {
  const { rfqDraftList, updateState, companyInfo, sqDraftCount, pageNumber } =
    props;
  // const getRowHeight = (params) => {
  //     let height = 35;
  //     return height;
  // };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    fetchPaginationDataIfNeeded(
      "fetchAllRFQDrafts",
      "rfqDraftList",
      props,
      payload
    );
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    // props.resetDraftPaginatedData();
    props.fetchAllRFQDrafts(payload);
    // props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
  };

  //const permittedModules = getActionMenuPermissionData();
  // const columnDefs = [
  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.draftNo' defaultMessage='' />;
  //         },
  //         field: "rfqParentDraftId",

  //         cellRendererFramework: (param) => <div className="cursor-pointer" >
  //             <Link
  //                 to={
  //                     {
  //                         pathname: '/admin/rfq-create',
  //                         state: {
  //                             rfqData: param.data.rfqData,
  //                             rfqParentDraftId: param.data.rfqParentDraftId,
  //                             rfqDataVersion: param.data.version,
  //                             clone: true
  //                         }
  //                     }}
  //             >Draft-{param.value}</Link>
  //         </div>,
  //         rowDrag: true,
  //         resizable: true,
  //         width: 100,
  //     },
  //     // {
  //     //     headerComponentFramework: () => {
  //     //         return <div><FormattedMessage id='customer.salesOrder.listing.header.amount' default='Amount' />  </div>;
  //     //     },
  //     //     // cellRendererFramework: (link) => {
  //     //     //     return link.data.salesQuotationeData.salesQuoteTotalAmount && link.data.salesQuotationeData.salesQuoteTotalAmount !== '-' ? <span> {link.data.salesQuotationeData.foreignCurrencyIcon ? <i className={link.data.salesQuotationeData.foreignCurrencyIcon}></i> : (link.data.salesQuotationeData.foreignCurrency ? <span>{link.data.salesQuotationeData.foreignCurrency}</span> : (props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "))}  {link.data.salesQuotationeData.salesQuoteTotalAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </span> : "-"
  //     //     // },
  //     //     field: "salesOrderTotalAmount",
  //     //     resizable: true,
  //     //     cellClass: AG_GRID_CLASS_CONSTANTS.CELL_RIGHT_ALIGN,
  //     //     width: 100,
  //     // },

  //     {
  //         headerComponentFramework: () => {
  //             return <FormattedMessage id='customer.salesOrder.listing.header.date' defaultMessage='' />;
  //         },
  //         field: "dateCreated",
  //         cellRendererFramework: (link) => {
  //             return <div>
  //                 <Tooltip placement="topLeft" title={(link.value && moment(link.value).format(CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT))}>
  //                     {(link.value && moment(link.value).format(CONSTANTS.DISPLAY_DATE_FORMAT))}
  //                 </Tooltip>
  //             </div>
  //         },
  //         resizable: true,
  //         width: 100,
  //     },

  // ]

  // const agGridStyle = {
  //     height: '100%',
  //     width: '100%'
  // };

  // const onGridReady = (params) => {
  //     props.updateState({
  //         gridApi: params.api,
  //         gridColumnApi: params.columnApi
  //     });
  //     params.api.sizeColumnsToFit();
  // }
  return (
    <Fragment>
      
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="purchase.RFQDraftList.table.heading"
                defaultMessage=""
              />
            </div>
            {/* {props.rfqDraftCount ? (
              <> */}
                <div className="vertical-sep" />
                <div>{props.rfqDraftCount}</div>
              {/* </>
            ) : (
              ""
            )} */}
          </div>
          <div className="right-actions"></div>
          </Skeleton>
        </div>
        {/* <div className='agGridWrapper'>
                        <div className="ag-theme-balham" style={agGridStyle} >
                            { <AgGridReact
                                onGridReady={onGridReady}
                                columnDefs={columnDefs}
                                rowData={rfqDraftList[pageNumber] || []}
                                rowDragManaged={true}
                                domLayout={"autoHeight"}
                                suppressDragLeaveHidesColumns={true} 
                                animateRows={true}
                                onColumnResized ={(params)=>{
                                    params.api.resetRowHeights();
                                }}
                                defaultColDef={{
                                    flex:1,
                                    autoHeight: true,
                                    wrapText: true,
                                }}
                                //getRowHeight={getRowHeight}
                            >
                            </AgGridReact>}
                        </div>
                    </div> */}
        <Skeleton loading={props.listLoading} active paragraph={{ rows: 13 }}>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="serial.no" defaultMessage="" />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.draftNo"
                    defaultMessage=""
                  />
                </th>
                <th>
                  <FormattedMessage
                    id="customer.salesOrder.listing.header.date"
                    defaultMessage=""
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {rfqDraftList[pageNumber] && rfqDraftList[pageNumber].length ?
                rfqDraftList[pageNumber]
                  .sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1))
                  .map((rowData, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="cursor-pointer">
                            <div
                              onClick={() => {
                                props.pushModalToStack({
                                  modalBody: (
                                    <StandardRFQ
                                      {...props}
                                      rfqData={rowData.rfqData}
                                      rfqParentDraftId={
                                        rowData.rfqParentDraftId
                                      }
                                      rfqDataVersion={rowData.version}
                                      clone={true}
                                    />
                                  ),
                                  width: "100%",
                                  hideTitle: true,
                                  hideFooter: true,
                                  wrapClassName: "new-transaction-wrapper",
                                });
                              }}
                              // to={
                              //     {
                              //         pathname: '/admin/rfq-create',
                              //         state: {
                              //             rfqData: rowData.rfqData,
                              //             rfqParentDraftId: rowData.rfqParentDraftId,
                              //             rfqDataVersion: rowData.version,
                              //             clone: true
                              //         }
                              //     }}
                            >
                              Draft-{rowData.rfqParentDraftId}
                            </div>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <Tooltip
                            placement="topLeft"
                            title={
                              rowData.dateCreated &&
                              moment(rowData.dateCreated).format(
                                CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT
                              )
                            }
                          >
                            {rowData.dateCreated &&
                              moment(rowData.dateCreated).format(
                                CONSTANTS.DISPLAY_DATE_FORMAT
                              )}
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })
               :
               <tr key="empty-data-box">
                <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                    <Empty />
                </td>
            </tr> 
                }
            </tbody>
          </table>
        </div>
      </Skeleton>
      <div className="footer">
      <Skeleton loading={props.headerLoading} paragraph={false} active>

        <div className="f-left"></div>
        <div className="f-right">
          <Pagination
            size="small"
            total={sqDraftCount}
            showTotal={showTotal}
            defaultPageSize={props.pageSize}
            showSizeChanger
            pageSizeOptions={[10, 25, 50, 100, 200]}
            // showQuickJumper
            onChange={(pageNumber, pageSize) => {
              loadPageData(pageNumber, pageSize);
              updateState({ pageSize: pageSize, pageNumber: pageNumber });
            }}
            onShowSizeChange={(pageNumber, pageSize) => {
              handlePageSizeChange(pageNumber || 1, pageSize, props);
            }}
          />
        </div>
        </Skeleton>
      </div>
    </Fragment>
  );
};

export default injectIntl(RfqDraft);
