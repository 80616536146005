import React from "react";
import { injectIntl } from "react-intl";
import { ICONS } from "../../../../../static/constants";
// import { getMomentDateForUIReadOnly, sortTable } from "../../../../../utils";
// import DropdownAction from "antd/lib/dropdown";
import CreateLead from "../../../../../containers/modal/modalBody/leads/createLead";


const LeadDetails = (props) => {


  return (
    
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">Lead Details</div>
          </div>
          <div className="right-actions">
            <button
              style={{
                backgroundColor: "#1cb961",
                color: "#ffffff",
                fontSize: "14px",
                width: '80px'
              }}
              onClick={() => {
              }}
            >
              <i className={`${ICONS.EDIT} mr5 mt5`} /> Edit
            </button>
          </div>
        </div>

        <CreateLead {...props} />


      </div>
   
  );
};

export default injectIntl(LeadDetails);
