import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import SalesOrderCreationComponent from '../../../../components/customer/salesOrder/StandardSalesOrder';
import { AG_GRID_CONSTANTS, GRID_PREFERENCE_CONSTANTS } from '../../../../static/agGridConstants';
import { fetchCustomers ,resetPaginatedData} from '../../Listing/action';
import { fetchWarehouseNames } from '../../../drawer/inventory/action';
import {
  fetchAllSalesOrderSources, addSalesOrderSources, deleteSalesOrderSources, createSalesOrder,
  fetchSQDataForSO, resetSalesQuoteSOData, fetchSOData, resetSODetails, getNextTxNumber, createSalesOrderDraft ,deleteDocument
} from './action';
import {
  fetchPriceList, deletePriceList, fetchPaymentTerms, fetchProjects, fetchStates, fetchAllEmployees, deleteEmployee, deleteContact,
  fetchTaxes, deleteTax, fetchUOM, addUOM, deleteUOM, fetchExpenseTypes, addExpenseTypes, deleteExpenseTypes, fetchProducts,
  fetchDocuments, addDocumentName, deleteDocumentName,
  showModal, hideModal, pushModalToStack, popModalFromStack,
  fetchCustomerDetailsByCustomerId, addPaymentTerm, deletePaymentTerm, deleteProject, deleteAddress,
  fetchUserPreferences, saveUserPrefrences, fetchCountries, fetchRelationshipTaxIdentifications, getQualityChecklistSettings, deleteCustomerTaxIdentifications, deleteRelationshipTaxIdentifications,
  fetchIncoterms, addIncoterm, deleteIncoterm, resetCustomerDetail, fetchAddresses, getRelationshipAddress, resetAddresses
} from '../../../../actions/commonActions';
import LedgerDrawer from '../../../../containers/drawer/ledgers/SalesOrderLedger';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded, addDaysToDate } from '../../../../utils';
import SODetail from '../../../modal/modalBody/customer/SODetail';
import { CONSTANTS, LEDGER_TYPE, LEDGER_ACTION_LIST, CONSTANTS_TRANSACTIONS, CONSTANTS_TRANSACTIONS_KEY } from '../../../../static/constants';
import { deleteCustomers } from '../../Listing/action';
import { fetchRemarksTemplate, resetRemarksData } from '../../../modal/modalBody/settings/RemarksTemplate/action';
import QualityCheckDrawer from '../../../../containers/drawer/qualityCheck/index';
import { fetchAllContacts } from '../../Listing/ShowConact/action';
import { fetchSalesOrders } from '../Listing/action';
import { fetchAllLedgerAccounts } from '../../../finance/LedgerAccounts/action';
import LedgerAccountsDrawer from "../../../drawer/finance/ledgers"
import { getItemByUPC } from '../../../../actions/commonActions';
import { getAllCoupons,applieCoupon } from '../../../modal/modalBody/eStore/Coupon/action';
import SettingSalesDrawer from '../../../drawer/customer/settingSalesDrawer';

class SalesOrderCreation extends Component {
  constructor(props) {
    super(props);
    const defaultSalesLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES }) || {};
    const defaultExpenseLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.CUSTOMER_EXPENSE }) || {};
    const defaultSalesOutputLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_OUTPUT_TAX }) || {};
    const defaultSalesDiscountLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.SALES_DISCOUNT }) || {};
    const defaultDebtorsLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_ACTION_LIST.DEBTORS }) || {};
    const defaultCouponLedger = find(props.companyInfo.defaultCoaLedgerAccountsList, { txType: LEDGER_TYPE.TYPE_COUPON }) || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
    const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
    this.state = {
      displayName:"drawer.settingDrawer.headingNameOrder",
      viewName:"Order",
      docName:"Sales Order",
      tabDocumentOptions:{ txType: CONSTANTS_TRANSACTIONS.SALES_ORDER, label: 'Sales Order', numberExample: 'SO-1', numberInfo: 'SO-CustomerNumber-DDMMYY' },

      hideAddNew:true,
      valueSet:false,
      rootExpenseList:[{}],
      salesOrderDate: new Date(),
      customer: props.customer ||linkProps.customer,
      salesOrderDraftId: props.salesOrderDraftId|| linkProps.salesOrderDraftId,
      soDraftData: props.soDraftData|| linkProps.soDraftData,
      allRelationshipBillingAddress,
      customerName: (props.customer || linkProps.customer || {}).companyName,
      stateCustomerId: props.customerId ||linkProps.customerId,
      isClone: props.clone,
      salesOrderMasterId: props.salesOrderMasterId ||linkProps.salesOrderMasterId,
      pageNumber: props.pageNumber || linkProps.pageNumber || 1,
      priceListName: undefined,
      pageSize: 100,
      couponSetting: JSON.parse(props.companyInfo?.couponSetting || "{}"),
      salesLedgerId: defaultSalesLedger.ledgerAccountId,
      salesDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      expenseLedgerId: defaultExpenseLedger.ledgerAccountId,
      expenseDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      taxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      salesDiscountLedgerId: defaultSalesDiscountLedger.ledgerAccountId,
      discountDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      igstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      igstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      cgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      cgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId,
      sgstSalesOutputTaxLedgerId: defaultSalesOutputLedger.ledgerAccountId,
      sgstTaxDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId, 
      couponLedgerId: defaultCouponLedger.ledgerAccountId, 
      couponDebtorLedgerId: defaultDebtorsLedger.ledgerAccountId, 
      searchedText: '',
      salesQuotationMasterId: this.props.salesQuotationMasterId || linkProps.salesQuotationMasterId,
      customerId: this.props.customerId || linkProps.customerId,
      placeOfSupply: undefined,
      soSourceId: 0,
      soSourceName: '',
      customerPO: undefined,
      referenceNumber: undefined,
      salesPersonId: undefined,
      salesPersonName: undefined,
      priority: undefined,
      billingAddress: undefined,
      shippingAddress: undefined,
      relationshipBillingAddress,
      paymentTermId: undefined,
      paymentTermName: undefined,
      footer: undefined,
      remarksCustomer: undefined,
      remarksInternal: undefined,
      priceTypeToApply: AG_GRID_CONSTANTS.STANDARD_SALES_INVOICE_FIELD.PRICE_TYPE_LIST[0],
      inheritedTxColumn: [],
      forceHideTxColumn: [],
      deliveryDate: new Date(),
      txPreferences: { txType: "salesOrder", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: [
        GRID_PREFERENCE_CONSTANTS.PRODUCT,
        GRID_PREFERENCE_CONSTANTS.DESCRIPTION,
        GRID_PREFERENCE_CONSTANTS.QUANTITY,
        GRID_PREFERENCE_CONSTANTS.RATE,
        GRID_PREFERENCE_CONSTANTS.AMOUNT,
        GRID_PREFERENCE_CONSTANTS.AMOUNT_WITHOUT_TAX,
        "sno"
      ],
      txAvailableColumn: {
        product: true,
        quantity: true,
        rate: true,
        amount: true,
        description: true,
        uom: true,
        hsn: (props.companyInfo || {}).countryName === 'India',
        basePrice: true,
        specialDiscount: true,
        discount: true,
        tax: true,
        comment: true,
        stockNumber: true,
        partNumber: true,
        origin: true,
        hsCode: true,
        taxableAmount:true,
        materialNumber: true
      },
    }
  }

  componentDidMount() {
    // this.props.updateHeaderState({
    //   collapsed: true
    // });
    const companyInfo = this.props.companyInfo || {};
    const linkProps = (this.props.location && this.props.location.state) || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      salesOrderMasterId: this.props.salesOrderMasterId || linkProps.salesOrderMasterId,
      salesQuotationMasterId: this.props.salesQuotationMasterId || linkProps.salesQuotationMasterId,
      customerId: this.props.customerId || linkProps.customerId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 100
    };
    this.props.resetSalesQuoteSOData();
    this.props.resetSODetails();
    this.props.resetAddresses();
    this.props.resetPaginatedData();
    this.props.fetchAllLedgerAccounts(payload)
    this.props.resetCustomerDetail();     
    this.props.getAllCoupons(payload);

    payload.salesQuotationMasterId && fetchDataIfNeeded('fetchSQDataForSO', 'sqSoData', this.props, payload, true);
    payload.salesOrderMasterId && fetchDataIfNeeded('fetchSOData', 'soDetail', this.props, payload, true);
    payload.customerId && this.props.fetchAddresses({...payload, locationType: CONSTANTS.SHIPPING_ADDRESS});
    payload.customerId && this.props.fetchAddresses({...payload, locationType: CONSTANTS.BILLING_ADDRESS});
    // payload.customerId && fetchDataIfNeeded('fetchCustomerDetailsByCustomerIdForUpdate', 'customerDetailUpdate', this.props, payload, true);
    fetchDataIfNeeded('fetchPriceList', 'priceList', this.props, payload);
    // fetchPaginationDataIfNeeded('fetchCustomers', 'customers', this.props, {...payload, isCompact: true}, true);
    fetchDataIfNeeded('fetchProjects', 'projectList', this.props, payload);
    fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
    fetchDataIfNeeded('fetchStates', 'states', this.props, 1);
    fetchDataIfNeeded('fetchProducts', 'products', this.props, payload);
    fetchDataIfNeeded('fetchTaxes', 'taxes', this.props, payload);
    fetchDataIfNeeded('fetchUOM', 'uomList', this.props, payload);
    fetchDataIfNeeded('fetchExpenseTypes', 'expenseTypes', this.props, payload);
    fetchDataIfNeeded('fetchWarehouseNames', 'allWarehouses', this.props, payload);
    fetchDataIfNeeded('fetchDocuments', 'documentList', this.props, payload);
    fetchDataIfNeeded('fetchAllSalesOrderSources', 'sources', this.props, payload);
    fetchDataIfNeeded('fetchAllEmployees', 'employees', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    fetchDataIfNeeded('fetchCountries', 'countries', this.props, payload);
    this.props.fetchRemarksTemplate({ relationshipId: companyInfo.relationshipId, docName: 'Sales Order' });
    fetchDataIfNeeded('fetchRelationshipTaxIdentifications', 'allRelationshipTaxIdentificatins', this.props, payload);
    fetchDataIfNeeded('getQualityChecklistSettings', 'qualityChecklistSetting', this.props, payload);
    if (!this.props.update) {
      fetchDataIfNeeded('getNextTxNumber', 'nextTxNumber', this.props, payload);
    }

    fetchDataIfNeeded('fetchIncoterms', 'incoterms', this.props, payload);
    payload.customerId && fetchDataIfNeeded('fetchCustomerDetailsByCustomerId', 'customerDetail', this.props, payload, true);
    payload.customerId &&  this.props.fetchAllContacts(payload);
  }


  componentWillReceiveProps(props) {

      if(props.companyInfo){
        this.setState({
          coupanSetting:props.companyInfo?.coupanSetting,
        })
      }
    if(props.addresses){
      if(props.addresses.BillingAddress && props.addresses.BillingAddress.length){
        this.setState({
          allBillingAddress: props.addresses.BillingAddress
        });
      }
      if(props.addresses.ShippingAddress && props.addresses.ShippingAddress.length){
        this.setState({
          allShippingAddress: props.addresses.ShippingAddress
        });
      }
    }
    if (!this.state.selectedRelationshipTaxIdentifications && props.allRelationshipTaxIdentificatins && props.allRelationshipTaxIdentificatins.length) {
      const selectedList = (props.allRelationshipTaxIdentificatins && filter(props.allRelationshipTaxIdentificatins, { populateOnTransaction: 1 })) || [];
      let selectedRelationshipTaxIdentifications = selectedList.map(obj => {
        return obj.relationshipTaxIdentificationId;
      })
      this.setState({ selectedRelationshipTaxIdentifications: selectedRelationshipTaxIdentifications });
    }

    if (props.documentList && props.documentList.length && !this.state.docName) {
      props.documentList.forEach((document) => {

        if (document.txType === CONSTANTS_TRANSACTIONS_KEY[CONSTANTS_TRANSACTIONS.SALES_ORDER] && document.isDefault) {
          this.setState({
            documentNameId: document.documentNameId,
            docName: document.docName
          })
        }
      })

    }
    if (!this.state.remarksCustomer && props.remarksData && props.remarksData.isFetched && !this.state.isRemarksSet) {
      this.setState({ remarksCustomer: props.remarksData.content || '', isRemarksSet: true, footer: props.remarksData.footer || '' });
      setTimeout(() => {
        props.resetRemarksData();
      }, 500)
    }

    if(props.customerDetailUpdate && props.customerDetailUpdate.customerId){
      this.setState({
        customer: props.customerDetailUpdate,
        customerName: props.customerDetailUpdate.companyName,
        customerId: props.customerDetailUpdate.customerId
      });
      props.resetCustomerDetailUpdate();
    }
    

    if (props.customerDetail && props.customerDetail.customerId) {
      const customer = props.customerDetail || {};
      const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
      const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
      const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
      const customerPaymentTerm = props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: ((customer && customer.paymentTermId) || 0) }) || {} : {};
      let numberOfDays = customerPaymentTerm.numberOfDays;
      const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
     const  relationshipBillingAddress= find(props.sqSoData.boLocationSQList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}

      const selectedCustomerTaxIdentifications = (this.state.selectedCustomerTaxIdentifications && this.state.selectedCustomerTaxIdentifications.length) ? this.state.selectedCustomerTaxIdentifications: ((customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || []).map(obj => {
        return obj.customerTaxIdentificationId;
      });
      this.setState({
        customer,
        customerName: customer.companyName,
        paymentTermId: customerPaymentTerm.paymentTermId,
        paymentTermName: customerPaymentTerm.termName,
        shippingAddress: defaultShippingAddress,
        billingAddress: defaultBillingAddress,
        placeOfSupply: props.placeOfSupply?.length ?props.placeOfSupply:  relationshipBillingAddress.stateName || defaultBillingAddress.stateName,
        invoiceDueDate: numberOfDays ? addDaysToDate((this.state.invoiceDate || new Date()), numberOfDays) : null,
        deliveryDate: numberOfDays ? addDaysToDate((this.state.invoiceDate || new Date()), numberOfDays) : null,
        selectedContact: selectedContact,
        selectedCustomerTaxIdentifications,
        allBillingAddress,
        allShippingAddress
      })
      props.resetCustomerDetail();
    }

    //if (props.taxes && props.taxes.length && props.sqSoData && props.sqSoData.salesQuotationMasterId && this.state.customer && this.state.customer.customerId) {
      if (props.isTaxesLoaded && props.sqSoData && props.sqSoData.salesQuotationMasterId && this.state.customer && this.state.customer.customerId) {
      const detailList = [];
      const inheritedTxColumn = [];
      const additionalInfoList = props.sqSoData.additionalInfoList[0] || {};
      const _emptyColumnCheck = (item, taxPercent) => {
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.comment) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      }

      props.sqSoData.customerSalesQuotationDetailsList.map((ele) => {
        const tax = (find(ele.customerSalesQuotationCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX;
        }) || {});

        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName ? ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + " ) ") || "") : '',
          productObj: ele.itemName ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku } : '',
          itemVariantName: ele.itemVariantName,
          inventoryItemVariantId: ele.inventoryItemVariantId,
          parentDetailsId: ele.salesQuotationDetailsId,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          discount: (find(ele.customerSalesQuotationCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxName: appliedTax.taxName,
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.warehouse,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          materialNumber: ele.materialNumber,
        })
      });

      const forceHideTxColumn = [];
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
      }
      if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0) {
        forceHideTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
      }

      let selectedContact = props.sqSoData.additionalInfoList?.length ? props.sqSoData.additionalInfoList[0]:{};

      this.setState({
        relationshipBillingAddress: find(props.sqSoData.boLocationSQList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {},
        billingAddress: find(props.sqSoData.boLocationSQList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {},
        inctermId: props.sqSoData.inctermId,
        salesPersonName: props.sqSoData.salesPersonFullName,
        incotermName: props.sqSoData.incotermName,
        projectName: props.sqSoData.projectName,
        referenceNumber: props.sqSoData.referenceNumber,
        priority: props.sqSoData.orderPriority,
        customerPO: props.sqSoData.customerPONumber,
        remarksCustomer: props.sqSoData.remarksCustomer,
        remarksInternal: props.sqSoData.remarksInternal,
        salesQuotationMasterId: props.sqSoData.salesQuotationMasterId,
        customerInquiryNumber: props.sqSoData.customerInquiryNumber,
        quotationNumber: props.sqSoData.quotationNumber,
        footer: props.sqSoData.footer,
        customerSalesOrderDetailsList: detailList,
        inheritedTxColumn: inheritedTxColumn,
        forceHideTxColumn: forceHideTxColumn,
        isSQConvertions: true,
        selectedContact,
        rfqNumber: props.sqSoData.rfqNumber,
        customerRFQNumber: props.sqSoData.customerRFQNumber,
        rfqMasterId: props.sqSoData.rfqMasterId,
        tableDataReRendered: true,
        selectedCustomerTaxIdentifications : additionalInfoList.taxIdentifications? JSON.parse(additionalInfoList.taxIdentifications).map(obj => { return obj.customerTaxIdentificationId;}) : this.state.selectedCustomerTaxIdentifications,
        selectedRelationshipTaxIdentifications : additionalInfoList.relationshipTaxIdentifications? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(obj => { return obj.relationshipTaxIdentificationId;}) : this.state.selectedRelationshipTaxIdentifications,

      });
      props.resetSalesQuoteSOData();
    }
    if(props.ecomdata && props.ecomDetail  ){
      const detailList = [];
      const inheritedTxColumn = [];
      const _emptyColumnCheck = (item, taxPercent) => {
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.comment) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      }
      JSON.parse(props.ecomDetail.customerSalesOrderDetailsList||'[]').map((ele) => {
        const tax = (find(ele.customerSalesQuotationCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX;
        }) || {});

        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          product: ele.itemName ? ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + " ) ") || "") : '',
          productObj: ele.itemName ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku } : '',
          itemVariantName: ele.itemVariantName,
          inventoryItemVariantId: ele.inventoryItemVariantId,
          parentDetailsId: ele.salesQuotationDetailsId,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          discount: (find(ele.customerSalesQuotationCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxName: appliedTax.taxName,
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.warehouse,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          materialNumber: ele.materialNumber,
        })
      });

      this.setState({
        customerSalesOrderDetailsList: detailList,
        customerName: props.ecomDetail?.customerName,
        salesOrderDate: props.ecomDetail?.dateCreated,
        customerPreOrderMasterId: props.ecomDetail?.customerPreOrderMasterId
      })
    }
   // if (props.taxes && props.taxes.length && props.soDetail && props.soDetail.salesOrderMasterId && this.state.customer && this.state.customer.customerId && props.allLedgerAccounts?.length) {
    if (props.isTaxesLoaded && props.soDetail && props.soDetail.salesOrderMasterId && this.state.customer && this.state.customer.customerId && props.allLedgerAccounts?.length) {  
   const detailList = [];
      const relationshipBillingAddress= find(props.soDetail.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      const taxForLedger = props.soDetail.customerSalesOrderDetailsList?props.soDetail.customerSalesOrderDetailsList[0]?.customerSalesOrderCOATxList?.filter(item=>item.txType ===LEDGER_TYPE.TYPE_TAX):""
      const isPlaceOfSupplySameAsRelationship = relationshipBillingAddress.stateName === props.soDetail?.placeOfSupply
      
      taxForLedger.map((item)=>{
           if(props.companyInfo.countryName === "India" && !isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              this.setState({
                igstTaxDebtorLedgerId:item.ledgerAccountId 
              })

            }else{
              this.setState({
                igstSalesOutputTaxLedgerId:item.ledgerAccountId
              })
            }

           }else if(props.companyInfo.countryName === "India" && isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstTaxDebtorLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                sgstTaxDebtorLedgerId:item.ledgerAccountId
                })

              }

            }else{
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstSalesOutputTaxLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                  sgstSalesOutputTaxLedgerId:item.ledgerAccountId
                })

              }
              
            }
            
           } 
      })
      const inheritedTxColumn = [];

      const _emptyColumnCheck = (item, taxPercent) => {
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PRODUCT) < 0 && item.itemName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PRODUCT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DESCRIPTION) < 0 && item.description) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DESCRIPTION);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.UOM) < 0 && item.uomName) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.UOM);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HSN) < 0 && item.hsnCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HSN);
        }
        // if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.BASE_PRICE) < 0 && item.basePrice) {
        //   inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.BASE_PRICE);
        // }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT) < 0 && item.specialDiscount) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.SPECIAL_DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.DISCOUNT) < 0 && (find(item.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.DISCOUNT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.TAX) < 0 && taxPercent) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.TAX);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.COMMENT) < 0 && item.comment) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.COMMENT);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER) < 0 && item.stocknumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.STOCK_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.PART_NUMBER) < 0 && item.partNumber) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.PART_NUMBER);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.ORIGIN) < 0 && item.origin) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.ORIGIN);
        }
        if (inheritedTxColumn.indexOf(GRID_PREFERENCE_CONSTANTS.HS_CODE) < 0 && item.hsCode) {
          inheritedTxColumn.push(GRID_PREFERENCE_CONSTANTS.HS_CODE);
        }
      }
      
      props.soDetail.customerSalesOrderDetailsList.map((ele) => {

        const tax = (find(ele.customerSalesOrderCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX;
        }) || {});
        
       
        
        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        _emptyColumnCheck(ele, tax.amountPercent);
        return detailList.push({
          version: ele.version,
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          inventoryItemVariantId: ele.inventoryItemVariantId,
          product: ele.itemName ? ele.itemName + ((ele.itemVariantName && " (" + ele.itemVariantName + " ) ") || "") : '',
          productObj: ele.itemName ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku } : '',
          itemVariantName: ele.itemVariantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          qtyBeforeUpdate: ele.quantity,
          discount: (find(ele.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax:tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxId: appliedTax.taxSingleRateId,
          taxName: appliedTax.taxName,
          taxApplied: appliedTax.taxNameDisplay,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          parentDetailsId: props.update ? ele.parentDetailsId : 0,
          comment: ele.comment,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          materialNumber: ele.materialNumber,
          salesOrderDetailsId: props.update ? ele.salesOrderDetailsId : 0,
          salesOrderMasterId: props.update ? ele.salesOrderMasterId : 0,
        })
      });
      const rootDiscount = (find((props.soDetail.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent || 0;
      const expenseAmount = (find((props.soDetail.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_EXPENSE }) || {}).amount || 0;
      const additionalInfoList = props.soDetail.additionalInfoList[0] || {};
      let rootExpenseList = [];
      let  rootDiscountList = [];
      for (let ledgerObj of props.soDetail?.customerSalesOrderCOATxList || []) {
        if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_EXPENSE &&
          ledgerObj.accountingEntry === "credit"
        ) {
           let ledger = 
            find(props.allLedgerAccounts || [], {
              chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            }) || {}
          
          rootExpenseList.push({
            // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            ledgerAccountName: ledger.ledgerAccountName,
            expenseLedgerAccountTaxRate:ledger.taxSingleRatePercent,
            expenseAmount: ledgerObj.amount,
            selectedExpenseLedger: {
              ...ledger
            },
          });
        }
      }

      for (let ledgerObj of props.soDetail?.customerSalesOrderCOATxList || []) {
        if (
          ledgerObj.txType === LEDGER_TYPE.TYPE_DISCOUNT &&
          ledgerObj.accountingEntry === "credit"
        ) {
          let ledger = 
          find(props.allLedgerAccounts || [], {
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
          }) || {}
          rootDiscountList.push({
            // chartOfAccountDetailsId: ledgerObj.expenseLedgerAccountId,
            chartOfAccountDetailsId: ledgerObj.ledgerAccountId,
            ledgerAccountName: ledger.ledgerAccountName,
            expenseAmount: ledgerObj.amount,
            isTaxable:ledgerObj.isTaxable,
            rootDiscountPercent:ledgerObj.amountPercent,
            rootDiscountAmount:ledgerObj.amount,
            selectedExpenseLedger: {
              ...ledger
            },
          });
        }
      }
      const couponAmount = (find((props.soDetail.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_COUPON }) || {}).amount || 0;
      const coupon = (find((props.soDetail.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_COUPON }) || {});
      this.setState({
        rootExpenseList:rootExpenseList,
        rootDiscountList:rootDiscountList,
        valueSet:true,
        couponAmount,
        coupanValue:coupon.taxName,
        coupondata:couponAmount ?{isvalid:couponAmount?true:false,maxDiscount:coupon.amountPercent?couponAmount:coupon?.amount,discountPercentage:coupon.amountPercent,couponType:coupon.amountPercent?"PERCENTAGE":"FLAT"}:{},
        viewCoupon:couponAmount?false:true,
        relationshipBillingAddress: find(props.soDetail.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {},
        version: props.soDetail.version,
        shippingAddress: find(props.soDetail.boLocationSOList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {},
        billingAddress: find(props.soDetail.boLocationSOList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {},
        paymentTermName: props.soDetail.paymentTermName,
        paymentTermId: props.soDetail.paymentTermId,
        inctermId: props.soDetail.inctermId,
        incotermName: props.soDetail.incotermName,
        isRootDiscount: rootDiscount ? true : false,
        rootDiscountPercent: rootDiscount,
        placeOfSupply: props.soDetail?.placeOfSupply,
        expenseId: props.soDetail.expenseId,
        isRootExpense: expenseAmount ? true : false,
        expenseAmount: expenseAmount,
        rootDiscountAmount: (find((props.soDetail.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amount || 0,
        projectName: props.soDetail.projectName,
        referenceNumber: props.soDetail.referenceNumber,
        priority: props.soDetail.orderPriority,
        salesPersonId: props.soDetail.salesPersonId,
        salesPersonName: props.soDetail.soPersonFullName,
        customerPO: props.soDetail.customerPONumber,
        //customerName: props.soDetail.customerName,
        soSourceName: props.soDetail.soSourceName,
        docDetailList: props.soDetail.docDetailList,
        remarksCustomer: props.soDetail.remarksCustomer,
        remarksInternal: props.soDetail.remarksInternal,
        salesOrderDate: props.update ? new Date(props.soDetail.salesOrderDate): new Date(),
        footer: props.soDetail.footer,
        isRemarksSet: true,
        salesQuotationMasterId: props.soDetail.salesQuotationMasterId,
        quotationNumber: props.soDetail.quotationNumber,
        //customer: props.customerDetail,
        customerSalesOrderDetailsList: detailList,
        rfqNumber: props.soDetail.rfqNumber,
        rfqMasterId: props.soDetail.rfqMasterId,
        inheritedTxColumn: inheritedTxColumn,
        tableDataReRendered: true,
        deliveryDate: props.soDetail.deliveryDate,
        update: props.update,
        salesOrderMasterId: props.update ? props.soDetail.salesOrderMasterId : 0,
        qualityCheckList: props.soDetail.qualityCheckList || [],
        oldPayload: props.update ? props.soDetail : {},
        isSQConvertions: props.update ? (props.soDetail.salesQuotationMasterId ? true : false) : false,
        selectedRelationshipTaxIdentifications : additionalInfoList.relationshipTaxIdentifications? JSON.parse(additionalInfoList.relationshipTaxIdentifications).map(obj => { return obj.relationshipTaxIdentificationId;}) : this.state.selectedRelationshipTaxIdentifications,
        customerPreOrderMasterId: props.update ? props.soDetail?.customerPreOrderMasterId : 0
      });
      if (props.update) {
        this.setState({ nextTxNumber: props.soDetail.soNumber });
      }
      setTimeout(() => {
        props.resetSODetails();
      }, 500)
      //////////////////////////////////////////////////////////
    }
    if (props.isTaxesLoaded && this.state.soDraftData && this.state.soDraftData.customerId && props.customers[this.state.pageNumber] && props.customers[this.state.pageNumber].length) {
      const detailList = [];
      const relationshipBillingAddress= find( this.state.soDraftData.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {}
      const taxForLedger =  this.state.soDraftData.customerSalesOrderDetailsList? this.state.soDraftData.customerSalesOrderDetailsList[0]?.customerSalesOrderCOATxList?.filter(item=>item.txType ===LEDGER_TYPE.TYPE_TAX):""
      const isPlaceOfSupplySameAsRelationship = relationshipBillingAddress.stateName ===  this.state.soDraftData?.placeOfSupply;
      (taxForLedger || []).map((item)=>{
           if(props.companyInfo.countryName === "India" && !isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              this.setState({
                igstTaxDebtorLedgerId:item.ledgerAccountId 
              })

            }else{
              this.setState({
                igstSalesOutputTaxLedgerId:item.ledgerAccountId
              })
            }

           }else if(props.companyInfo.countryName === "India" && isPlaceOfSupplySameAsRelationship){
            if(item.accountingEntry === "debit"){
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstTaxDebtorLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                sgstTaxDebtorLedgerId:item.ledgerAccountId
                })

              }

            }else{
              if(item.taxName ==="CGST"){
                this.setState({
                  cgstSalesOutputTaxLedgerId:item.ledgerAccountId,
                  })  
              }else{
                this.setState({
                  sgstSalesOutputTaxLedgerId:item.ledgerAccountId
                })

              }
              
            }
            
           } 
      })
      this.state.soDraftData.customerSalesOrderDetailsList.map((ele) => {
        //bellow row only for draft
        let variantName = (ele.attributeValue1 || '') + (ele.attributeValue2 ? ' | ' : '') + (ele.attributeValue2 || '') + '' + (ele.attributeValue3 ? ' | ' + ele.attributeValue3 : '');
        const tax = (find(ele.customerSalesOrderCOATxList, (ele) => {
          return ele.txType === LEDGER_TYPE.TYPE_TAX;
        }) || {});

        const appliedTax = find(props.taxes, { taxSingleRateId: Number(tax.taxId) }) || {};
        return detailList.push({
          selectedProductValue: [ele.itemId, ele.inventoryItemVariantId],
          inventoryItemVariantId: ele.inventoryItemVariantId,
          product: ele.itemName ? ele.itemName + ((variantName && " (" + variantName + " ) ") || "") : '',
          productObj: ele.itemName ? { itemName: ele.itemName, itemId: ele.itemId, sku: ele.sku } : '',
          itemVariantName: variantName,
          variant: {
            attributeId1: ele.attributeId1,
            attributeId2: ele.attributeId2,
            attributeId3: ele.attributeId3,
            attributeName1: ele.attributeName1,
            attributeName2: ele.attributeName2,
            attributeName3: ele.attributeName3,
            attributeValue1: ele.attributeValue1,
            attributeValue2: ele.attributeValue2,
            attributeValue3: ele.attributeValue3
          },
          description: ele.description,
          scrollHeight: ele.rowHeight,
          qty: ele.quantity,
          discount: (find(ele.customerSalesOrderCOATxList, { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent,
          tax: tax.taxName === "CGST" || tax.taxName === "SGST" ?tax.amountPercent*2 || 0:tax.amountPercent,
          taxApplied: appliedTax.taxNameDisplay,
          taxId: appliedTax.taxSingleRateId,
          taxName: appliedTax.taxName,
          rate: ele.anItemSalePrice,
          baseRate: ele.basePrice,
          uom: ele.uomName,
          hsnCode: ele.hsnCode,
          specialDiscount: ele.basePrice && ele.specialDiscount,
          comment: ele.comment,
          stockNumber: ele.stockNumber,
          partNumber: ele.partNumber,
          origin: ele.origin,
          hsCode: ele.hsCode,
          materialNumber: ele.materialNumber
        })
      });

      const rootDiscount = (find((this.state.soDraftData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amountPercent || 0;
      const expenseAmount = (find((this.state.soDraftData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_EXPENSE }) || {}).amount || 0;
      const customer = {
    "companyName": this.state.soDraftData.customerName,
    "customerId": this.state.soDraftData.customerId,
    "relationshipId": props.companyInfo.relationshipId
}

      setTimeout(() => {
        if (this.state.soDraftData) {
          this.setState({
            relationshipBillingAddress: find(this.state.soDraftData.boLocationSOList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || {},
            shippingAddress: find(this.state.soDraftData.boLocationSOList, { locationType: CONSTANTS.SHIPPING_ADDRESS }) || {},
            billingAddress: find(this.state.soDraftData.boLocationSOList, { locationType: CONSTANTS.BILLING_ADDRESS }) || {},
            inctermId: this.state.soDraftData.inctermId,
            isRootDiscount: rootDiscount ? true : false,
            rootDiscountPercent: rootDiscount,
            expenseId: this.state.soDraftData.expenseId,
            isRootExpense: expenseAmount ? true : false,
            expenseAmount: expenseAmount,
            rootDiscountAmount: (find((this.state.soDraftData.customerSalesOrderCOATxList || []), { txType: LEDGER_TYPE.TYPE_DISCOUNT }) || {}).amount || 0,
            incotermName: this.state.soDraftData.incotermName,
            salesOrderDate: this.state.soDraftData.salesOrderDate,
            paymentTermName: this.state.soDraftData.paymentTermName,
            paymentTermId: this.state.soDraftData.paymentTermId,
            salesPersonName: this.state.soDraftData.soPersonFullName,
            projectName: this.state.soDraftData.projectName,
            priority: this.state.soDraftData.orderPriority,
            referenceNumber: this.state.soDraftData.referenceNumber,
            customerPO: this.state.soDraftData.customerPONumber,
            soSourceName: this.state.soDraftData.soSourceName,
            customerName: this.state.soDraftData.customerName,
            remarksCustomer: this.state.soDraftData.remarksCustomer,
            remarksInternal: this.state.soDraftData.remarksInternal,
            salesQuotationMasterId: this.state.soDraftData.salesQuotationMasterId,
            quotationNumber: this.state.soDraftData.quotationNumber,
            internalRemarks: this.state.soDraftData.remarksInternal,
            footer: this.state.soDraftData.footer,
            customer: customer,
            customerSalesOrderDetailsList: detailList,
            rfqNumber: this.state.soDraftData.rfqNumber,
            rfqMasterId: this.state.soDraftData.rfqMasterId,
            deliveryDate: this.state.soDraftData.deliveryDate,
            qualityCheckList: this.state.soDraftData.qualityCheckList || [],
            soDraftData: null,
            customerRFQNumber: this.state.soDraftData.customerRFQNumber || ""
          });
        }
      }, 1000)
    }
    if (this.state.stateCustomerId && props.customers[this.state.pageNumber] && props.customers[this.state.pageNumber].length && props.paymentTerms && props.paymentTerms.length) {
      const customer = find(props.customers[this.state.pageNumber], { customerId: Number(this.state.stateCustomerId || 0) }) || {};
      const allShippingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.SHIPPING_ADDRESS })) || [];
      const defaultShippingAddress = find(allShippingAddress, { isDefault: 1 }) || {};
      const allBillingAddress = (customer && filter(customer.boLocationList, { locationType: CONSTANTS.BILLING_ADDRESS })) || [];
      const defaultBillingAddress = find(allBillingAddress, { isDefault: 1 }) || {};
      const customerPaymentTerm = props.paymentTerms.length ? find(props.paymentTerms, { paymentTermId: ((customer && customer.paymentTermId) || 0) }) || {} : {};
      let numberOfDays = customerPaymentTerm.numberOfDays;
      const selectedContact = (customer.boContactList || []).find(x => x.isPrimaryContact) || {};
      const selectedList = (customer && filter(customer.customerTaxIdentificationsList, { populateOnTransaction: 1 })) || [];
      let selectedCustomerTaxIdentifications = selectedList.map(obj => {
        return obj.customerTaxIdentificationId;
      })

      this.setState({
        stateCustomerId: null,
        customer,
        customerName: customer.companyName,
        paymentTermId: this.state.paymentTermId || customerPaymentTerm.paymentTermId,
        paymentTermName: this.state.paymentTermName || customerPaymentTerm.termName,
        shippingAddress: this.state.shippingAddress || defaultShippingAddress,
        billingAddress: this.state.billingAddress || defaultBillingAddress,
        placeOfSupply:  props.placeOfSupply?.length ?props.placeOfSupply: defaultBillingAddress && defaultBillingAddress.stateName,
        invoiceDueDate: numberOfDays ? addDaysToDate((props.invoiceDate || new Date()), numberOfDays) : null,
        selectedContact,
        selectedCustomerTaxIdentifications,
        boContactList: customer.boContactList || [],
      });
    }

    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "salesOrder", templateName: "standard" }) || {};
      if (txPreferences) {
        let txColumns = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length ? txColumns : (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = txPreferences.gridPreference ? ((JSON.parse(txPreferences.gridPreference)) || {}).columnSetting || {} : [];
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let tablePreference = txPreferences.tablePreference;
        this.setState({
          tablePreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      } else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        })
      }
    }

    if(props.boRelationshipAddress && props.boRelationshipAddress.length){
      const allRelationshipBillingAddress = [];
      props.boRelationshipAddress.forEach((e)=>{
        if(e.locationType === 'RelationshipBillingAddress'){
          allRelationshipBillingAddress.push(e);
        }
      });
      this.setState({
        allRelationshipBillingAddress
      })
    }else{
      const allRelationshipBillingAddress = filter(props.companyInfo.boLocationList, { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
      this.setState({
        allRelationshipBillingAddress,
      });
    }
    
    if (props.customerContactList && props.customerContactList.length) {
      this.setState({
        boContactList: props.customerContactList
      });
    }
  }

  openSoDetails = (data) => {
    const soPayload = {
      customerId: data.customerId,
      salesOrderMasterId: data.salesOrderMasterId
    }

    this.props.pushModalToStack({
      modalBody: <SODetail  {...this.props} soPayload={soPayload} />,
      width: '100%',
      hideFooter: true,
      wrapClassName: 'modal-custom-detail'
    })
  }

  onSearch(text, updateAllOptions, updateFetching) {
    const payload = {
      relationshipId: (this.props || {}).companyInfo.relationshipId,
      pageNumber: 1,
      searchString: text,
      pageSize: 100,
      updateAllOptions,
      updateFetching
    };
    this.props.fetchProducts(payload)
  }

  render() {
    return (
      <Fragment>
        {this.state.settingSalesDrawerVisible && 
        <SettingSalesDrawer
        {...this.state}
        {...this.props}
        updateState={(data) => {
          this.setState(data);
        }}
        updateDrawerState={(data) => { this.setState(data) }}
        />
        }

        {this.props.ledgerAccountsDrawerVisible || this.state.ledgerAccountsDrawerVisible && (
          <LedgerAccountsDrawer
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
            updateDrawerState={(data) => { this.setState(data) }}
          />
        )}
        <LedgerDrawer {...this.state} updateState={(data) => { this.setState(data) }} updateDrawerState={(data) => { this.setState(data) }} />
        {this.state.qualityCheckDrawerVisible && <QualityCheckDrawer {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        <SalesOrderCreationComponent
          onSearch={(text, updateAllOptions, updateFetching) => {
            this.onSearch(text, updateAllOptions, updateFetching)
          }}
          {...this.props}
          {...this.state}
            updateDrawerState={(data) => { this.setState(data) }}
            openSoDetails={this.openSoDetails}
          updateState={(data) => { this.setState(data) }}
        />
      </Fragment>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    customers: state.customer.customers,
    priceList: state.common.priceList,
    documentList: state.common.documentList,
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    supplierAddresses: state.supplier.supplierAddresses,
    paymentTerms: state.common.paymentTerms,
    states: state.common.states,
    sqSoData: state.so.sqSoData,
    projectList: state.common.projectList,
    uomList: state.common.uomList,
    taxes: state.common.taxes,
    expenseTypes: state.common.expenseTypes,
    products: state.salesInvoice.products,
    allWarehouses: state.inventory.allWarehouses,
    sources: state.so.sources,
    employees: state.common.employees,
    soDetail: state.so.soDetail,
    customerDetail: state.common.customerDetail,
    nextTxNumber: state.so.nextTxNumber,
    allPreferences: state.common.allPreferences,
    remarksData: state.settings.remarksData,
    permissions: state.auth.permissions,
    countries: state.common.countries,
    allRelationshipTaxIdentificatins: state.common.allRelationshipTaxIdentificatins,
    qualityChecklistSetting: state.settings.qualityChecklistSetting,
    incoterms: state.common.incoterms,
    addresses: state.customer.addresses,
    boRelationshipAddress: state.common.boRelationshipAddress,
    //customerDetailUpdate: state.common.customerDetailUpdate,
    customerContactList: state.customer.contacts,
    allLedgerAccounts: state.ledger.allLedgerAccounts,
    saveLoading: state.common.saveLoading,
    detailLoading: state.common.detailLoading,
    isTaxesLoaded: state.common.isTaxesLoaded,
    couponList: state.eStore.couponList,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSQDataForSO,
  fetchSOData,
  resetSODetails,
  resetSalesQuoteSOData,
  createSalesOrder,
  fetchCustomerDetailsByCustomerId,
  fetchCustomers,
  fetchPriceList,
  fetchProjects,
  fetchPaymentTerms,
  fetchStates,
  fetchProducts,
  fetchTaxes,
  deleteTax,
  fetchUOM,
  addUOM,
  deleteUOM,
  fetchExpenseTypes,
  addExpenseTypes,
  deleteExpenseTypes,
  fetchWarehouseNames,
  fetchDocuments,
  addDocumentName,
  deleteDocumentName,
  fetchAllEmployees,
  deleteEmployee,
  fetchAllSalesOrderSources,
  showModal,
  hideModal,
  pushModalToStack,
  popModalFromStack,
  deleteCustomers,
  addPaymentTerm,
  deletePaymentTerm,
  addSalesOrderSources,
  deleteSalesOrderSources,
  getItemByUPC,
  deleteProject,
  deleteAddress,
  deletePriceList,
  getNextTxNumber,
  resetRemarksData,
  deleteContact,
  fetchUserPreferences,
  saveUserPrefrences,
  fetchRemarksTemplate,
  fetchCountries,
  createSalesOrderDraft,
  fetchRelationshipTaxIdentifications,
  getQualityChecklistSettings,
  deleteCustomerTaxIdentifications,
  deleteRelationshipTaxIdentifications,
  fetchIncoterms,
  addIncoterm,
  deleteIncoterm,
  resetCustomerDetail,
  fetchAddresses,
  
  getRelationshipAddress,
  //fetchCustomerDetailsByCustomerIdForUpdate, 
  //resetCustomerDetailUpdate,
  resetAddresses,
  fetchAllContacts,
  fetchSalesOrders,
  fetchAllLedgerAccounts,resetPaginatedData,getAllCoupons,applieCoupon,
  deleteDocument
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderCreation);
