import React, { useState } from "react";
import { Tooltip } from 'antd';
import { injectIntl, FormattedMessage } from "react-intl";
import "./sidebar.scss";
import sideMenuList from "./menuList";
import { getInitialsFromString, checkACLPermission, getUserDefinedName } from "../../utils";




const SidebarMenu = React.memo((props) => {
  const { permissions } = props;
  const primaryPerm = (permissions || {}).primary || [];

  const [activeMenuChild, setMenuChild] = useState(false);

  const getPermissionedMenuItem = (sideMenuList) => {
    let filteredSideMenu = [];
    if (Array.isArray(sideMenuList) && sideMenuList.length) {
      filteredSideMenu = sideMenuList.filter((menuObj) => {
        // if (menuObj.hasAdditionIcon) {
        //   menuObj.hasAdditionIcon = checkACLPermission(
        //     primaryPerm,
        //     menuObj.moduleCode,
        //     PERMISSION_VALUES.CREATE
        //   );
        // }
        if (menuObj.childList) {
          if (Array.isArray(menuObj.childList) && menuObj.childList.length) {
            menuObj.childList = getPermissionedMenuItem(menuObj.childList);
            if (menuObj.moduleCode) {
              return checkACLPermission(
                primaryPerm,
                menuObj.moduleCode,
                menuObj.operation
              );
            }
            if (!menuObj.childList.length && !menuObj.moduleCode) {
              return false;
            }
            return true;
          } else {
            return checkACLPermission(
              primaryPerm,
              menuObj.moduleCode,
              menuObj.operation
            );
          }
        } else {
          return checkACLPermission(
            primaryPerm,
            menuObj.moduleCode,
            menuObj.operation
          );
        }
      });
    }
    return filteredSideMenu;
  };

    let filteredSideMenuList = getPermissionedMenuItem(sideMenuList);

    // if((props.companyInfo?.industryName || '').toLowerCase() === 'education'){
    //     const ind = filteredSideMenuList.findIndex(obj => obj.path=== '/admin/dashboard');
    //     filteredSideMenuList[ind] = {...filteredSideMenuList[ind], path:'/admin/school-management/dashboard'}
    // }



  return (
    <>
        <div className="master-menu" key={'menudiv1'}>
            <ul key={'menuul1'}>
                <li key={'mmenu'}>
                    <div className="name-initial">
                        {getInitialsFromString(props.companyInfo?.storeName || 'Alpide Tech')}
                    </div>
                </li>
                
                {(filteredSideMenuList || []).length ? filteredSideMenuList.map((item, i) => {
                    return <li 
                                key={i+`sidemenu`}
                                // onMouseEnter={()=>{
                                //     if(item.rightIconClass){
                                //         props.updateState({
                                //             activeMasterMenu: item.menuName,
                                //             activeMenuList : item.childList
                                //         });
                                //     } else{
                                //         props.updateState({activeMasterMenu: false, activeMenuList: []});
                                //     }
                                // }}

                                onClick={()=>{
                                    if(item.rightIconClass){
                                        // if again clicked on opend menu, side popup will close
                                        if(props.activeMasterMenu === item.menuName){
                                            props.updateState({activeMasterMenu: false});
                                            return
                                        }
                                        props.updateState({
                                            activeMasterMenu: item.menuName,
                                            activeMenuList : item.childList
                                        });
                                    }
                                    else{
                                        props.updateState({activeMasterMenu:item.menuName, activeMenuList:false});
                                        props.history.push(item.path);
                                        return
                                    }
                                }
                            }>
                                <span className={`master-menu-icon ${props.activeMasterMenu === item.menuName ? ' active':''}`}>
                                    {item.iconClassName ? <i className={`${item.iconClassName}`}></i>:'XX'}
                                    
                                <span>{<FormattedMessage id={item.menuName} defaultMessage='' />}</span>
                                
                                </span>
                                {/* <span className="dot-menu-icon"><i className={item.rightIconClass}></i></span> */}
                            </li>

                        //      <Tooltip 
                        //     key={i+`tooltip`}
                        //     // title={<FormattedMessage id={item.menuName} />} 
                        //     placement="right"
                        //     overlayClassName='sidebar-tooltip'
                        // ></Tooltip>
                      
                  
                })
                :''
                }
            </ul>
        </div>
        
        <div key={'menudiv2'} className={`active-menu ${props.activeMasterMenu && props.activeMenuList ? 'visible' : ''}`}
        //    onMouseLeave={()=>{
        //                             props.updateState({activeMasterMenu: false});
        //                             return
                                                                
        //                         }}
                                
                                >
            {   props.activeMasterMenu && props.activeMenuList ? <>
                    {/* <div className="org-name-box">
                        {props.companyInfo.storeName}
                    </div> */}
                    <div className="menu-header">
                        <FormattedMessage id={props.activeMasterMenu} />
                        <div className="menu-closure" onClick={()=>{
                            props.updateState({activeMasterMenu: false}
                                
                                );
                        }}
                      
                        >
                            <i className="fi fi-bs-angle-left"></i> 
                        </div>
                    </div>
                    <ul key={'menuul2'}>
                        {
                            props.activeMenuList && props.activeMenuList.length ? props.activeMenuList.map((menuItem, index)=>{
                            return <li key={`activeMenu`+index}>
                                    <div
                                        key={'amenudiv1'+index}
                                        className={`menu-item ${activeMenuChild && activeMenuChild ===  menuItem.menuName ? 'active' : ''}`}
                                        onClick={()=>{
                                            if(menuItem.rightIconClass){
                                                setMenuChild(activeMenuChild && activeMenuChild === menuItem.menuName ? false : menuItem.menuName);
                                            }
                                            else{
                                                props.history.push(menuItem.path || '/admin/not-found');
                                                setMenuChild(menuItem.menuName);
                                                props.updateState({activeMasterMenu: false})
                                                return
                                            }
                                        }
                                    }>
                                        <span className="menu-icon">{<i className={menuItem.iconClassName}></i>}</span>
                                        <span className="menu-name">
                                            {/* <FormattedMessage id= {menuItem.menuName} /> */}
                                            {getUserDefinedName(props.intl.formatMessage({ id: menuItem.menuName, defaultMessage: '' }), props)}
                                        </span>
                                        

                                        <span className={`right-icon ${activeMenuChild && activeMenuChild ===  menuItem.menuName ? 'trans' : ''}`}>{<i className={menuItem.rightIconClass} ></i>}</span>
                                    </div>

                                    {/* menu child */}
                                    <div key={'amenudiv2'+index} className={`menu-childs ${activeMenuChild && activeMenuChild ===  menuItem.menuName ? 'open' : ''}`}>
                                        <ul key={'menuul3'}>
                                            {
                                                menuItem.childList && menuItem.childList.length ? menuItem.childList.map((menuChildItem, j)=>{
                                                    return <li key={'mc'+j} className={`menu-child-item ${props.activeChild && props.activeChild ===  menuChildItem.menuName ? 'active' : ''}`} onClick={()=>{
                                                        props.updateState({activeChild: menuChildItem.menuName});
                                                        props.history.push(menuChildItem.path || '/admin/not-found');
                                                        props.updateState({activeMasterMenu: false})

                                                    }}>
                                                        {/* <FormattedMessage id={menuChildItem.menuName} /> */}
                                                        {getUserDefinedName(props.intl.formatMessage({ id: menuChildItem.menuName, defaultMessage: '' }), props)}
                                                    </li>
                                                }):''
                                            }  
                                        </ul>
                                    </div>
                                </li>
                            
                            })
                            :
                            '' 
                        }
                    </ul>
                </>
            :
            ''
            }
        </div>
    </>
  )
  
});

export default injectIntl(SidebarMenu);