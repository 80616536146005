import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Col, Row } from 'antd';

import { Dropdown } from '../../../general/Dropdown';

function PurchaseInvoiceLedger(props) {
  const {
     allLedgerAccounts
  } = props;
  
  const closeDrawer = () => {
    props.updateState({
      ledgerDrawerVisible: false
    })
  }
 const newLedgerAccounts = () => {
    props.updateDrawerState({
     ledgerAccountsDrawerVisible:true
    })
   };
  return (
    <div>
      <Drawer
        title={
          <div className="drawer-title">
            <FormattedMessage id='drawer.ledger.title' defaultMessage='' />
        </div>
          
        }
        width={400}
        zIndex={1}
        destroyOnClose={true}
        maskClosable={false}
        keyboard={false}
        onClose={closeDrawer}
        visible={props.ledgerDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              props.initCalls();
            }
          }
        }
      >

        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.purchase' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                onAddNewClick={(props) => {
                  newLedgerAccounts(props);
                }}
                
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  value={ allLedgerAccounts.length ? props.purchaseLedgerId : ''}
                  optionKeyName='chartOfAccountDetailsId'
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        purchaseLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.creditorsCr' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.purchaseCreditorsLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        purchaseCreditorsLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.purchaseDiscount' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.purchaseDiscountLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        purchaseDiscountLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.creditorsCr' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.discountCreditorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        discountCreditorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
          <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.purchaseOutput' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  value={ allLedgerAccounts.length ? props.purchaseTaxLedgerId : ''}
                  optionKeyName='chartOfAccountDetailsId'
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        purchaseTaxLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

             <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.creditorsCr' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.taxCreditorsLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        taxCreditorsLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          IGST
            
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                  label={
                    <FormattedMessage id='drawer.ledger.purchaseOutput' defaultMessage='' />
                  }
                >
                  <Dropdown
                  canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                  
                    items={ allLedgerAccounts || []}
                    valueKeyName='ledgerAccountName'
                    value={ allLedgerAccounts.length ? props.igstPurchaseTaxLedgerId : ''}
                    optionKeyName='chartOfAccountDetailsId'
                    
                    
                    onSelect={
                      (optionValue) => {
                        props.updateState({
                          igstPurchaseTaxLedgerId: optionValue
                        })
                      }
                    }
                  />
                </Form.Item>
              </Col>
  
               <Col span={12}>
                <Form.Item
                  label={
                    <FormattedMessage id='drawer.ledger.creditorsCr' defaultMessage='' />
                  }
                >
                  <Dropdown
                  canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                  
                    items={ allLedgerAccounts || []}
                    valueKeyName='ledgerAccountName'
                    optionKeyName='chartOfAccountDetailsId'
                    value={ allLedgerAccounts.length ? props.igstTaxCreditorsLedgerId : ''}
                    
                    
                    onSelect={
                      (optionValue) => {
                        props.updateState({
                          igstTaxCreditorsLedgerId: optionValue
                        })
                      }
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          
   
  
              CGST
            
            <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                  label={
                    <FormattedMessage id='drawer.ledger.purchaseOutput' defaultMessage='' />
                  }
                >
                  <Dropdown
                  canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                  
                    items={ allLedgerAccounts || []}
                    valueKeyName='ledgerAccountName'
                    value={ allLedgerAccounts.length ? props.cgstPurchaseTaxLedgerId : ''}
                    optionKeyName='chartOfAccountDetailsId'
                    
                    
                    onSelect={
                      (optionValue) => {
                        props.updateState({
                          cgstPurchaseTaxLedgerId: optionValue
                        })
                      }
                    }
                  />
                </Form.Item>
              </Col>
  
               <Col span={12}>
                <Form.Item
                  label={
                    <FormattedMessage id='drawer.ledger.creditorsCr' defaultMessage='' />
                  }
                >
                  <Dropdown
                  canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                  
                    items={ allLedgerAccounts || []}
                    valueKeyName='ledgerAccountName'
                    optionKeyName='chartOfAccountDetailsId'
                    value={ allLedgerAccounts.length ? props.cgstTaxCreditorsLedgerId : ''}
                    
                    
                    onSelect={
                      (optionValue) => {
                        props.updateState({
                          cgstTaxCreditorsLedgerId: optionValue
                        })
                      }
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
         
  
  
              SGST
            
             <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                  label={
                    <FormattedMessage id='drawer.ledger.purchaseOutput' defaultMessage='' />
                  }
                >
                  <Dropdown
                  canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                  
                    items={ allLedgerAccounts || []}
                    valueKeyName='ledgerAccountName'
                    value={ allLedgerAccounts.length ? props.sgstPurchaseTaxLedgerId : ''}
                    optionKeyName='chartOfAccountDetailsId'
                    
                    
                    onSelect={
                      (optionValue) => {
                        props.updateState({
                          sgstPurchaseTaxLedgerId: optionValue
                        })
                      }
                    }
                  />
                </Form.Item>
              </Col>
  
               <Col span={12}>
                <Form.Item
                  label={
                    <FormattedMessage id='drawer.ledger.creditorsCr' defaultMessage='' />
                  }
                >
                  <Dropdown
                  canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                  
                    items={ allLedgerAccounts || []}
                    valueKeyName='ledgerAccountName'
                    optionKeyName='chartOfAccountDetailsId'
                    value={ allLedgerAccounts.length ? props.sgstTaxCreditorsLedgerId : ''}
                    
                    
                    onSelect={
                      (optionValue) => {
                        props.updateState({
                          sgstTaxCreditorsLedgerId: optionValue
                        })
                      }
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
           
          <Row gutter={16}>
          <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.generalExpense' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.purchaseExpenseLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        purchaseExpenseLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.creditorsCr' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.purchaseExpenseCreditorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        purchaseExpenseCreditorLedgerId: optionValue
                      })
                    }
                  }
                />
              </Form.Item>
            </Col>
            
            </Row>
            {(props.roundingOffSettings && props.roundingOffSettings.isRoundingOffTotalAmtOnPurchaseInvoice) ?
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <FormattedMessage id='drawer.ledger.purchaseRoundingOff' defaultMessage='' />
                  }
                >
                  <Dropdown
                    canAddNew={true}
                    onAddNewClick={(props) => {
                      newLedgerAccounts(props);
                    }}
                    items={allLedgerAccounts || []}
                    valueKeyName='ledgerAccountName'
                    optionKeyName='chartOfAccountDetailsId'
                    value={allLedgerAccounts.length ? props.roundingOffLedgerId : ''}


                    onSelect={
                      (optionValue) => {
                        props.updateState({
                          roundingOffLedgerId: optionValue
                        })
                      }
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
              <Form.Item
                label={
                  <FormattedMessage id='drawer.ledger.creditorsCr' defaultMessage='' />
                }
              >
                <Dropdown
                canAddNew={true}
                                      onAddNewClick={() => {
                                        newLedgerAccounts();
                                      }}
                
                  items={ allLedgerAccounts || []}
                  valueKeyName='ledgerAccountName'
                  optionKeyName='chartOfAccountDetailsId'
                  value={ allLedgerAccounts.length ? props.roundingOffCreditorLedgerId : ''}
                  
                  
                  onSelect={
                    (optionValue) => {
                      props.updateState({
                        roundingOffCreditorLedgerId: optionValue
                      })
                    }
                  }
                />
                </Form.Item>
              </Col>

            </Row>
            : ''}

        </Form>

      </Drawer>
    </div>
  );
}

export default injectIntl(PurchaseInvoiceLedger);
