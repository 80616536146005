import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreateLeadComp from '../../../../../components/modal/modalBody/leads/createLead';
import { fetchCrmLeadFormList } from '../../../../leads/LeadForm/action';
import { saveOrUpdateLeads, getAllLeadSources, getAllLeadStatus ,getTeamMembersByTeamName} from './action';
import { fetchCrmLeadCampaign } from '../../../../leads/LeadCampaigns/action';
import { getTeam } from '../../../../company/EmployeeMeta/Team/action';
class AllLeadsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.itemData,
            pageNumber: 1,
            pageSize: 1000,
            isWalkInLead:false,stage:1
        }
    }

    componentDidMount() {
        const companyInfo = this.props.companyInfo || {};

        const payload = {
          relationshipId: companyInfo.relationshipId,
          pageNumber: 1,
          pageSize: 1000,
        }
        this.props.fetchCrmLeadFormList(payload);
        this.props.getAllLeadSources(payload);
        this.props.getAllLeadStatus(payload);
        this.props.fetchCrmLeadCampaign(payload);
        this.props.getTeam(payload);
    }

    render() {
        return  <CreateLeadComp {...this.state} {...this.props} updateState={(data) => { this.setState(data); }} />
    }   
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        userInfo: state.common.userInfo,
        crmLeadFormList: state.crm.crmLeadFormList[1],
        crmLeadStatusList: state.crm.crmLeadStatusList,
        crmLeadSourceList: state.crm.crmLeadSourceList,
        crmCampaignList: state.crm.crmCampaignList,
        teamList: state.employee.teamList,
        employeeByTeamName:state.crm.employeeByTeamName
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCrmLeadFormList,
    saveOrUpdateLeads,
    getAllLeadSources, 
    getAllLeadStatus,
    fetchCrmLeadCampaign,getTeam,getTeamMembersByTeamName
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AllLeadsModal);
