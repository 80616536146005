import { COMPANY_ACTION_LIST } from '../static/constants';
const initialState = {
  empAttendanceList: []
}

const CompanyReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case COMPANY_ACTION_LIST.EMPLOYEE_ATTENDANCE_LIST:
      return { ...state, empAttendanceList: action.data };
    
    default:
      return state;
  }
};

export default CompanyReducer;