import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BrodcastSMSDrawer from '../../../../../components/drawer/communication/account/brodcast-sms';
import { brodcastTextSMS, brodcastWhatsappSMS } from "./action";
import { getAllDG } from "../../../../communication/distribution-group/action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../../../../actions/commonActions';
import { fetchDataIfNeeded } from "../../../../../utils";
import { Select } from 'antd';
import { getSubAccount } from '../../../../communication/account/action';
const { Option } = Select;

class BrodcastSMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charCount: 0,
      message: props.previewUrl || '',
      to: [],
      dgNameOptionList: [],
      dgIds: [],
      totalCost: 0,
      totalSelectedRecipients: 0,
      selectedDGIds: [],
      selectedContacts: [],
      invalidContacts: [],
    }
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true,
    });
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
    };
    this.props.getSubAccount(payload);
   fetchDataIfNeeded("getAllDG", "dgList", this.props, payload);
  }

  componentWillReceiveProps(props) {
    if (props.dgList && props.dgList.length > 0 && this.state.dgNameOptionList.length === 0) {
      let name = [];
      let ids = [];
      for (let i = 0; i < props.dgList.length; i++) {
        ids.push(props.dgList[i].distributionGroupId);
        name.push(<Option value={props.dgList[i].distributionGroupId}>{props.dgList[i].dgName}</Option>);
      }
      this.setState({ dgNameOptionList: name, dgIds: ids });
    }
  }

  render() {
    return <BrodcastSMSDrawer
      {...this.props}
      {...this.state}
      updateState={(data) => {
        this.setState(data);
      }}
    >
    </BrodcastSMSDrawer>
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    dgList: state.communicationReducer.dgList,
    subAccount: state.communicationReducer.subAccount,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllDG,
      brodcastTextSMS,
      brodcastWhatsappSMS,
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getSubAccount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BrodcastSMS);
