import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmployeeCompo from '../../../../../components/modal/modalBody/common/Employee';
import { fetchSalutation, addSalutation, deleteSalutation, showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { createEmployee } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';

class EmployeeContainer extends Component {
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId
    };
    fetchDataIfNeeded('fetchSalutation', 'salutations', this.props, payload);
  }

  render() {
    return (
      <EmployeeCompo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    salutations: state.common.salutations,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createEmployee,
  fetchSalutation,
  addSalutation, 
  deleteSalutation,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeContainer);
