import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportComponent from '../../../../../components/modal/modalBody/schoolManagement/ImportDocuments';
import {
    fetchPaymentTerms, addPaymentTerm, deletePaymentTerm,
    fetchBusinessTypes, addBusinessType, deleteBusinessType,
    fetchIndustries, addIndustry, deleteIndustry,
    fetchSources, addCustomerSource, deleteCustomerSource, hideModal
} from '../../../../../actions/commonActions';

import { getAllStudentForm } from '../../../../Student/studentFormList/action';
import { getClass } from '../../../../schoolManagement/class/action';
import { getSchoolCourse } from '../../../../schoolManagement/course/action';
// import {
//     fetchCustomers
// } from '../../../../../containers/customer/Listing/action';
import { uploadSchoolDoc } from './action';


class ImportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            pageNumber: 1,
            pageSize: 1000
        };
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const userInfo = this.props.userInfo || {};
        const payload = {
            relationshipId: companyInfo.relationshipId,
            relationshipEmployeeId: userInfo.relationshipEmployeeId,
            pageNumber: 1,
            pageSize: 1000
        };
        // fetchDataIfNeeded('fetchSources', 'sources', this.props, payload);
        // fetchDataIfNeeded('fetchBusinessTypes', 'businessTypes', this.props, payload);
        // fetchDataIfNeeded('fetchPaymentTerms', 'paymentTerms', this.props, payload);
        // fetchDataIfNeeded('fetchIndustries', 'industries', this.props, payload); 
        this.props.getAllStudentForm(payload);
        this.props.getSchoolCourse(payload);
        this.props.getClass(payload);
    }

    render() {
        return <ImportComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
    }

}


const mapStateToProps = (state) => {
    return {
        userInfo: state.common.userInfo,
        sources: state.common.sources,
        businessTypes: state.common.businessTypes,
        paymentTerms: state.common.paymentTerms,
        industries: state.common.industries,
        companyInfo: state.common.companyInfo,
        studentFormList: state.membershipReducer.studentFormList,
        courseList: state.school.courseList,
        classList: state.school.classList,
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSources,
    addCustomerSource,
    deleteCustomerSource,
    fetchPaymentTerms,
    addPaymentTerm,
    deletePaymentTerm,
    fetchIndustries,
    addIndustry,
    deleteIndustry,
    fetchBusinessTypes,
    addBusinessType,
    uploadSchoolDoc,
    deleteBusinessType,
    hideModal,
    getAllStudentForm,
    getSchoolCourse,
    getClass
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportModal);
