import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PurchaseInvoiceListingComponent from '../../../../components/supplier/invoice/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchPurchaseInvoices, getPurchaseInvoicesCount, resetPaginatedData, postPurchaseInvoiceToXero, fetchPurchaseInvoiceDrafts, getPurchaseInvoiceDraftCount, resetDraftPaginatedData, deleteInvoice, updateUserStatus, fetchPurchaseInvoicesByFilter, getPurchaseInvoicesCountByFilter } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchUserPreferences, saveUserPrefrences, deleteAuditTrail, createAuditTrail, fetchProjects } from '../../../../actions/commonActions';
import { fetchSuppliers } from '../../Listing/action';
import { fetchAllStatus } from '../../../modal/modalBody/common/UserStatus/action';
import * as find from 'lodash.find';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
import { FormattedMessage } from 'react-intl';
import PurchaseInvoiceFilters from '../../../../components/supplier/invoice/Listing/purchaseInvoiceFilters';

class PurchaseInvoiceListing extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      activeTab: linkProps.activeTab,
      activeTabKey: linkProps.activeTab === "Draft" ? '2' : '1', 
      txPreferences: { txType: "purchaseInvoice", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['invoiceNumber', 'supplierName', 'invoiceTotalAmount', 'invoiceDate'],
      txAvailableColumn: {
        isXeroUploaded: true,
        invoiceNumber: true,
        supplierPoNumber: true,
        supplierName: true,
        invoiceTotalAmount: true,
        totalPaymentMade: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        invoiceDueAmount: true,
        invoiceDate: true,
        invoiceDueDate: true,
        paymentTermName: true,
        status: true,
        isCashInvoice: true,
        rfqNumber: true,
        userStatus: true,
        project: true,
        reference: true,
        dueDays: true,
        debitApplied: true,
        supplierInvoiceNumber: true,
      },
      selectedAmountTerm: '=',
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.purchase.purchaseInvoice' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
        {
            name: <FormattedMessage id='sidebar.menuItem.purchase' defaultMessage='' />,
        }, 
        {
            name: <FormattedMessage id='sidebar.menuItem.purchaseInvoice' defaultMessage='' />,
        }
    ]})
    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
      transactionName: 'Purchase Invoice'
    };
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    fetchPaginationDataIfNeeded('fetchPurchaseInvoices', 'purchaseInvoiceList', this.props, payload);
    fetchDataIfNeeded('getPurchaseInvoicesCount', 'purchaseInvoiceCount', this.props, payload);
    if (linkProps.activeTab === "Draft") {
      fetchDataIfNeeded('getPurchaseInvoiceDraftCount', 'purchaseInvoiceDraftCount', this.props, payload);
      fetchPaginationDataIfNeeded('fetchPurchaseInvoiceDrafts', 'purchaseInvoiceDraftList', this.props, payload);
    }
    this.props.fetchSuppliers(payload);
    this.props.fetchProjects(payload);
    this.props.fetchAllStatus(payload);
    this.props.resetPaginatedData();
  }
  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "purchaseInvoice", templateName: "standard" }) || this.state.txPreferences;
      if (txPreferences) {
        let txColumns = txPreferences.tablePreference ? ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [] : [];
        txColumns = txColumns.length? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = txPreferences.tablePreference? ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {}: {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let gridPreference = txPreferences.gridPreference
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.purchaseInvoiceFilterDrawerVisible && <PurchaseInvoiceFilters {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />}

          <PurchaseInvoiceListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
      
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    purchaseInvoiceList: state.purchaseInvoice.purchaseInvoiceList,
    purchaseInvoiceCount: state.purchaseInvoice.purchaseInvoiceCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    permissions: state.auth.permissions,
    purchaseInvoiceDraftList: state.purchaseInvoice.purchaseInvoiceDraftList,
    purchaseInvoiceDraftCount: state.purchaseInvoice.purchaseInvoiceDraftCount,
    suppliers: state.supplier.suppliers,
    projectList: state.common.projectList,
    userStatus: state.common.userStatus,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading

  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPurchaseInvoices,
  getPurchaseInvoicesCount,
  resetPaginatedData,
  showModal,
  hideModal,
  createAuditTrail,
  deleteAuditTrail,
  pushModalToStack,
  postPurchaseInvoiceToXero,
  popModalFromStack,
  fetchUserPreferences,
  deleteInvoice,
  saveUserPrefrences,
  fetchPurchaseInvoiceDrafts, 
  getPurchaseInvoiceDraftCount, 
  resetDraftPaginatedData,
  updateUserStatus,
  fetchSuppliers,
  fetchProjects,
  fetchAllStatus,
  fetchPurchaseInvoicesByFilter,
  getPurchaseInvoicesCountByFilter,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceListing);
