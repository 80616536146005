import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { ICONS, MODAL_TYPE } from '../../../static/constants';
import { Dropdown } from '../../general/Dropdown';
import { Col, Row, Button } from 'antd';
import DropdownAction from "antd/lib/dropdown";
import { Form } from '@ant-design/compatible';
import { ErrorMsg } from '../../general/ErrorMessage';
import { showToasterMessage } from '../../../utils';
import cloneDeep from 'lodash/cloneDeep';
import { getLabelName } from '../../modal/modalBody/customerInfo';

const VariantModel = (props) => {
    const { intl, variantObj, variantAttributes, updateDrawerState, itemName, inventoryItemVariantList,sku ,useSystemSku} = props;
 
      const addItemConfirmationModal = (props, text, modalType) => {
        const { showModal, companyInfo } = props;
        const labelName = getLabelName(modalType);
        const modalBody = <span>
            <FormattedMessage
                id='addItem.confirmation'
                values={{ value: `${text} to ` }}
                defaultMessage=''
            />
            {labelName} ?
        </span>;

        let addFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                addFunc = props.addUOM;
                break;
            }
            case MODAL_TYPE.ATTRIBUTE: {
                addFunc = props.addAttribute;
                break;
            }
            default: {
                addFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => {
                addFunc({ text: text, relationshipId: companyInfo.relationshipId })
                props.hideModal();
            },
        };
        showModal(modalData);
    }

    const getModalTitle = (newTextObj, modalType) => {
        let title;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                title = <FormattedMessage id='addItem.text.uom' defaultMessage='' />;
                break;
            }
            case MODAL_TYPE.ATTRIBUTE: {
                title = <FormattedMessage id='addItem.text.attribute' defaultMessage='' />;
                break;
            }
            default:
                title = <FormattedMessage id='signup.confirmation.title' defaultMessage='' />;
        }
        return title;
    }

    const handleNewItemAddition = (props, payload, modalType) => {
        const formData = payload.formData;
        const { showModal } = props;

        if (formData.textBoxValue && formData.textBoxValue.length && !payload.existsInData) {
            addItemConfirmationModal(props, formData.textBoxValue, modalType);
        } else {
            const data = {
                title: getModalTitle(payload, modalType),
                formData,
                modalData: { modalType, data: payload },
                handleSubmit: (formData = {}) => {
                    if (formData.textBoxValue && formData.textBoxValue.length) {
                        addItemConfirmationModal(props, formData.textBoxValue, modalType);
                    } else {
                        data.formData.submittedOnce = true;
                        showModal(data);
                    }
                }
            };
            showModal(data);
        }
    }

    const deleteClickHandler = (props, modalType, payload) => {
        const { showModal, companyInfo } = props;
        const modalBody =
            <FormattedMessage
                id='deleteItem.confirmation'
                defaultMessage=''
                values={{ value: payload.text }}
            />;

        let deleteFunc;

        switch (modalType) {
            case MODAL_TYPE.UOM: {
                deleteFunc = props.deleteUOM;
                break;
            }
            case MODAL_TYPE.ATTRIBUTE: {
                deleteFunc = props.deleteAttribute;
                break;
            }
            default: {
                deleteFunc = () => { }
            }
        }

        const modalData = {
            modalBody,
            handleSubmit: () => { deleteFunc({ id: payload.id, relationshipId: companyInfo.relationshipId }) },
        };
        showModal(modalData);
    }
      var getUniqueChar = function (field, val) {
        var count = 0;
        for (var i = 0; i < inventoryItemVariantList.length; i++) {
            if (inventoryItemVariantList[i][field] && inventoryItemVariantList[i][field].trim()) {
                if (inventoryItemVariantList[i][field].charAt(0) === val) {
                    count = count + 1;
                }
            }
        }
        if (count) {
            return val + count;
        } else {
            return val;
        }
    }

    function generateAttributeSKU(val, type) {
        var attributeValue1 = variantObj.attributeValue1;
        var attributeValue2 = variantObj.attributeValue2;
        var attributeValue3 = variantObj.attributeValue3;
        var newVariantSKU = sku || '';

        var charToSet = "";
        switch (type) {
            case "attributeValue1": {
                attributeValue1 = val;
                break;
            }
            case "attributeValue2": {
                attributeValue2 = val;
                break;
            }
            case "attributeValue3": {
                attributeValue3 = val;
                break;
            }
            default:
                break;
        }
        if (useSystemSku === 1) {
            if (attributeValue1) {
                charToSet = getUniqueChar("attributeValue1", attributeValue1[0].charAt(0));
                newVariantSKU += ("-" + charToSet).toUpperCase();
            }
            if (attributeValue2) {
                charToSet = getUniqueChar("attributeValue2", attributeValue2[0].charAt(0));
                newVariantSKU += ("-" + charToSet).toUpperCase();
            }
            if (attributeValue3) {
                charToSet = getUniqueChar("attributeValue3", attributeValue3[0].charAt(0));
                newVariantSKU += ("-" + charToSet).toUpperCase();
            }
            updateDrawerState({ variantObj: { ...variantObj, sku: newVariantSKU, attributeValue1: attributeValue1, attributeValue2: attributeValue2, attributeValue3: attributeValue3 } });
        }
        else {
            updateDrawerState({ variantObj: { ...variantObj, attributeValue1: attributeValue1, attributeValue2: attributeValue2, attributeValue3: attributeValue3 } });
        }

    }
    function addVariantToProduct() {
        let isInValidVariant = false;
        if (!itemName) {
            return showToasterMessage({ messageType: "error", description: "Please enter product name" });
        }
        if (!variantObj.attributeValue1 && !variantObj.attributeValue2 && !variantObj.attributeValue3) {
            return;
        }
        let combinations = []
        variantObj.attributeValue1.forEach(value1 => {
            variantObj.attributeValue2.forEach(value2 => {
              variantObj.attributeValue3.forEach(value3 => {
                combinations.push({
                  attributeValue1: value1,
                  attributeValue2: value2,
                  attributeValue3: value3,
                });
              });
            });
          });
          props.updateDrawerState({
            inventoryItemVariantList:combinations
          })
      
    }
    return (
        <div>
            
            <Row gutter={[16, 16]}>
                    <Col span={12}>Attribute Options
                        <span className="required">*</span>

                    </Col>
                    <Col span={12}>Option Value
                        <span className="required">*</span>

                    </Col>
                    
                </Row>
            
              <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item>
                        <Dropdown
                                
                                items={variantAttributes}
                                valueKeyName='attributeName'
                                optionKeyName='itemVariantAttributeId'
                                value={variantObj.attributeName1}
                                canAddNew={true}
                                canDelete={true}
                                deleteClickHandler={(payload) => {
                                    deleteClickHandler(props, MODAL_TYPE.ATTRIBUTE, payload);
                                }}
                                onAddNewClick={(payload) => {
                                    payload.formData = {
                                        textBoxValue: payload.textEntered,
                                        submittedOnce: false
                                    }
                                    handleNewItemAddition(props, payload, MODAL_TYPE.ATTRIBUTE)
                                }}
                                placeholder={intl.formatMessage({
                                    id: 'drawer.inventory.attribute.placeholder', defaultMessage: ''
                                })}
                                onSelect={(optionValue, elem) => {
                                    let obj = variantAttributes.find(variant => (variant.itemVariantAttributeId === optionValue));
                                    updateDrawerState({ variantObj: { ...variantObj, attributeId1: obj.itemVariantAttributeId, attributeName1: obj.attributeName } });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateDrawerState({ variantObj: { ...variantObj, attributeId1: undefined, attributeName1: undefined } });
                                }}
                            />
                        </Form.Item>
                        <ErrorMsg
                            validator={() => { return !(variantObj.attributeName1 && (variantObj.attributeName1 === variantObj.attributeName2 || variantObj.attributeName1 === variantObj.attributeName3)) }}
                            message={intl.formatMessage(
                                { id: 'drawer.inventory.validation.duplicateAttribute', defaultMessage: '' }
                            )} />
                    </Col>
                    
                    <Col span={12}>
                        <Form.Item>
                            <Dropdown
                                mode="tags"

                                showArrow={false}

                                placeholder={intl.formatMessage({ id: 'drawer.inventory.attributeValue.placeholder', defaultMessage: '' })}
                                value={props.attributeValue1}

                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    // generateVariant(e, 'attributeValue1')
                                    props.updateDrawerState({
                                        attributeId1:e
                                    })
                                    generateAttributeSKU(e, 'attributeValue1');

                                }}
                            />
                        </Form.Item>

                    </Col>
                 
                   
                </Row>
                <Row gutter={8} >
                    <Col span={12}>
                        <Form.Item>
                            <Dropdown
                               
                                items={variantAttributes}
                                disabled={!variantObj.attributeName1 || !variantObj.attributeValue1}
                                valueKeyName='attributeName'
                                optionKeyName='itemVariantAttributeId'
                                value={variantObj.attributeName2}
                                canAddNew={true}
                                canDelete={true}
                                deleteClickHandler={(payload) => {
                                    deleteClickHandler(props, MODAL_TYPE.ATTRIBUTE, payload);
                                }}
                                onAddNewClick={(payload) => {
                                    payload.formData = {
                                        textBoxValue: payload.textEntered,
                                        submittedOnce: false
                                    }
                                    handleNewItemAddition(props, payload, MODAL_TYPE.ATTRIBUTE)
                                }}
                                placeholder={intl.formatMessage({
                                    id: 'drawer.inventory.attribute.placeholder', defaultMessage: ''
                                })}
                                onSelect={(optionValue, elem) => {
                                    let obj = variantAttributes.find(variant => (variant.itemVariantAttributeId === optionValue));
                                    updateDrawerState({ variantObj: { ...variantObj, attributeId2: obj.itemVariantAttributeId, attributeName2: obj.attributeName } });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateDrawerState({ variantObj: { ...variantObj, attributeId2: undefined, attributeName2: undefined } });
                                }}
                            />
                        </Form.Item>
                        <ErrorMsg
                            validator={() => { return !(variantObj.attributeName2 && (variantObj.attributeName2 === variantObj.attributeName1 || variantObj.attributeName2 === variantObj.attributeName3)) }}
                            message={intl.formatMessage(
                                { id: 'drawer.inventory.validation.duplicateAttribute', defaultMessage: '' }
                            )} />
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                          
                            <Dropdown
                                mode="tags"

                                showArrow={false}

                                placeholder={intl.formatMessage({ id: 'drawer.inventory.attributeValue.placeholder', defaultMessage: '' })}
                                value={variantObj.attributeValue2}

                                style={{ width: '100%' }}
                                onChange={(e) => {
                                  
                                    generateAttributeSKU(e, 'attributeValue2');

                                }}
                            />
                        </Form.Item>
                    </Col>

                   

                </Row>
                <Row gutter={8} >
                    <Col span={12}>
                        <Form.Item>
                            <Dropdown

                              
                                items={variantAttributes}
                                disabled={!variantObj.attributeName2 || !variantObj.attributeValue2}
                                valueKeyName='attributeName'
                                optionKeyName='itemVariantAttributeId'
                                value={variantObj.attributeName3}
                                canAddNew={true}
                                canDelete={true}
                                deleteClickHandler={(payload) => {
                                    deleteClickHandler(props, MODAL_TYPE.ATTRIBUTE, payload);
                                }}
                                onAddNewClick={(payload) => {
                                    payload.formData = {
                                        textBoxValue: payload.textEntered,
                                        submittedOnce: false
                                    }
                                    handleNewItemAddition(props, payload, MODAL_TYPE.ATTRIBUTE)
                                }}
                                placeholder={intl.formatMessage({
                                    id: 'drawer.inventory.attribute.placeholder', defaultMessage: ''
                                })}
                                onSelect={(optionValue, elem) => {
                                    let obj = variantAttributes.find(variant => (variant.itemVariantAttributeId === optionValue));
                                    updateDrawerState({ variantObj: { ...variantObj, attributeId3: obj.itemVariantAttributeId, attributeName3: obj.attributeName } });
                                }}
                                allowClear={true}
                                onClear={() => {
                                    updateDrawerState({ variantObj: { ...variantObj, attributeId3: undefined, attributeName3: undefined } });
                                }}
                            />
                        </Form.Item>
                        <ErrorMsg
                            validator={() => { return !(variantObj.attributeName3 && (variantObj.attributeName3 === variantObj.attributeName1 || variantObj.attributeName3 === variantObj.attributeName2)) }}
                            message={intl.formatMessage(
                                { id: 'drawer.inventory.validation.duplicateAttribute', defaultMessage: '' }
                            )} />
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                          
                            <Dropdown
                                mode="tags"

                                showArrow={false}

                                placeholder={intl.formatMessage({ id: 'drawer.inventory.attributeValue.placeholder', defaultMessage: '' })}
                                value={variantObj.attributeValue3}

                                style={{ width: '100%' }}
                                onChange={(e) => {
                               
                                    generateAttributeSKU(e, 'attributeValue3');

                                }}
                            />
                        </Form.Item>
                    </Col>
                  
                </Row> 

                <div className="pt20 pb10">
                    <Button onClick={addVariantToProduct} >
                        <FormattedMessage id='drawer.inventory.productDetails.addVariant.button' defaultMessage='' />
                        <i className={ICONS.ADD} style={{ marginLeft: "10px", fontSize: "x-small", color: "#000000" }} />
                    </Button>
                   
                </div>
</div>
)
}
export default VariantModel