import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DropdownRef } from '../../../../../general/Dropdown';
import { CustomButton } from '../../../../../general/CustomButton';

class AddShippingCountryComponent extends Component {
    // constructor(props) {
    //     super(props);
    //     //props.updateState({ ...this.props.data.formData })
    // }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 7 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 24,
                    offset: 0,
                },
            },
        };

        return (
            <Fragment>
                <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
                    <div className="modal-content billing-address-control">
                    <FormattedMessage  id='setting.marketplaceConfiguration.shipping.model.field.country' defaultMessage='' />
                        <Form.Item style={{ padding: '13px 0px' }} label={ <FormattedMessage  id='supplier.listing.showAddress.gridHeader.country' defaultMessage='' />} colon={false} >
                            {getFieldDecorator('countryName', {
                                initialValue: ((this.props.data || {}).formData || {}).countryName || this.props.countryName,
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id='signup.validation.country' defaultMessage='' />,
                                    }]
                            })
                                (<DropdownRef
                                    items={this.props.countries || []}
                                    optionKeyName='countryId'
                                    valueKeyName='countryName'
                                    placeholder={this.props.intl.formatMessage({
                                        id:"common.select.placeholder" , defaultMessage:""
                                    })}
                                    onSelect={(selectedValue, option) => {
                                        this.props.updateState({ 'countryId': selectedValue });
                                        this.props.form.setFieldsValue({ 'countryName': option.props.name });
                                        //this.props.getCountryStates({ countryId: selectedValue });
                                    }}
                                />)}
                        </Form.Item>

                    </div>
                    <Form.Item {...tailFormItemLayout} className="modal-footer">
                        <div key={0} className='customModal-button-group'>
                            <CustomButton
                                intlId={'modal.address.button.cancel'}
                                defaultMessage={''}
                                type={'default'}
                                key={1}
                                onClick={() => {
                                    if (((this.props.data || {}).formData || {}).onCancel) {
                                        this.props.data.formData.onCancel();
                                    }
                                    this.props.hideModal();
                                }}
                            />
                            <CustomButton
                                intlId={'button.save.label'}
                                defaultMessage={''}
                                htmlType='submit'
                                key={2}
                            />
                        </div>
                    </Form.Item>
                </Form>
            </Fragment >
        )
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const selectedCountry = this.props.countries.find((element) => {
                    return element.countryId === this.props.countryId;
                });
                const marketplaceShippingCountryPayload = {
                    countryId: this.props.countryId,
                    //countryCode: values.streetAddress1,
                    countryName: selectedCountry.countryName,
                    relationshipId: this.props.relationshipId,

                }

                const modalData = {
                    modalBody: 'Are you sure you want to Add Country?',
                    handleSubmit: () => {
                        this.props.saveUpdateShippingMarketplaceCountry(marketplaceShippingCountryPayload, this.props);
                        this.props.popModalFromStack();
                        this.props.hideModal();
                    }
                };
                this.props.pushModalToStack(modalData);
            }
        });
    };
}

export default Form.create({ name: 'add_shipping_country_component' })(injectIntl(AddShippingCountryComponent));
