import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MailOutlined, DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Select, InputNumber, Button, Checkbox } from 'antd';
//import moment from 'moment-timezone';
//import { CONSTANTS } from '../../../../../static/constants';
import { showToasterMessage } from '../../../../../utils';
const { Option } = Select;
//const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const ReminderSetup = (props) => {
   
const { updateState } = props;

    return (
        <div className="modal-dialog ">
            <div className="modal-content full-width">
                <Row type='flex' justify='center' className=" pt10 text-right ant-legacy-form-item-control">
                    <Col span={6} justify='center' className=" pr10 "> <FormattedMessage id='invoiceIs.text' />  </Col>
                    <Col span={4} style={{ textAlign: 'initial'}}>
                        <Select onChange={(value) => {
                            updateState({
                                remindBeforeOrAfterDueDate: value,
                            })
                        }}
                            style={{ width: 150 }}
                            value={props.remindBeforeOrAfterDueDate}>
                            <Option value="before"><FormattedMessage id='dueIn.text' /></Option>
                            <Option value="after"><FormattedMessage id='pastDueIn.text' /></Option>
                        </Select>
                    </Col>
                    <Col span={2} style={{ marginLeft: "40px"}}><InputNumber value={props.days} placeholder=""  onChange={(value) => {
                            updateState({days: value })
                        }}/></Col>
                    <Col span={3}>
                    <FormattedMessage id='drawer.customer.days' />
                    </Col>
                </Row>
                <Row type='flex' justify='center' className="pt10 text-right ant-legacy-form-item-control ">
                    <Col span={5} className="pr10"> <FormattedMessage id='recurringInvoice.setup.emailCustomer' defaultMessage='' /></Col>
                    <Col span={1} style={{ textAlign: 'initial'}}><Checkbox checked={props.notifyCustomer} onChange={(e) => {
                            props.updateState({
                                notifyCustomer: e.target.checked,
                                emailDetailDrawerVisible: e.target.checked,
                            })
                        }} /></Col>
                    {props.emailDetails ? <Col span={9} style={{ textAlign: 'initial'}} > <Button icon={<MailOutlined />} onClick={() => {
                        updateState({emailDetailDrawerVisible: true})
                    }}>
                    </Button></Col> : <Col span={9}> </Col>
                    }
                </Row>
               
                <Row type='flex' justify='center' className=" pt10 text-right ant-legacy-form-item-control">
                    <Col span={6} >
                    <button type="button" className="ant-btn ant-btn-primary mt20" onClick={() => {
                        if(props.reminderList && props.reminderList.length > 3){
                            return showToasterMessage({
                                 description: props.intl.formatMessage({ id: 'reminder.validation.maxActive', defaultMessage: '' }),
                                 messageType: 'error'
                             })
                         }
                         if(!props.remindBeforeOrAfterDueDate || !props.days){
                            return showToasterMessage({
                                 description: props.intl.formatMessage({ id: 'reminder.validation.requiredMessage', defaultMessage: '' }),
                                 messageType: 'error'
                             })
                         }
                         if(!props.notifyCustomer || !props.emailDetails || props.emailDetails === ''){
                            return showToasterMessage({
                                 description: props.intl.formatMessage({ id: 'reminder.notifyCustomer.validation.message', defaultMessage: '' }),
                                 messageType: 'error'
                             })
                         }
                         const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                         const modalData = {
                             modalBody,
                             handleSubmit: () => {
                                 let payload = {
                                    reminderSalesInvoiceId: props.reminderSalesInvoiceId || 0,
                                    relationshipId: props.relationshipId,
                                    customerId: props.salesInvoiceDetail.customerId,
                                    invoiceMasterId: props.salesInvoiceDetail.invoiceMasterId,
                                    customerName: props.salesInvoiceDetail.customerName,
                                    invoiceNumber: props.salesInvoiceDetail.invoiceNumber,
                                    isBeforeDueDate: props.remindBeforeOrAfterDueDate === 'before' ? 1 : 0,
                                    isAfterDueDate: props.remindBeforeOrAfterDueDate === 'after' ? 1 : 0,
                                    days: props.days,
                                    isActive: 1,
                                    createdByEmpId: (props.userInfo || {}).relationshipEmployeeId,
                                    isSendEmail: props.notifyCustomer ? 1 : 0,
                                    email: props.notifyCustomer ? props.emailDetails || '' : '',
                                    customerInvoiceMasterList: props.invoiceList
                                 }
                                 if (props.isFormSetting) {
                                    props.addReminderSetting(payload);
                                    props.popModalFromStack();
                                 } else {
                                     props.saveInvoiceReminder(payload, props);
                                 }
                                 //props.popModalFromStack();
                                 updateState({ remindBeforeOrAfterDueDate: 'before', days: 0, notifyCustomer: false, emailDetails: ''
                                 })
                             },
                         };
                         props.showModal(modalData);

                       
                    }}><FormattedMessage id='addReminder.text' /></button>
                    </Col>
                    </Row>
                
                <br></br>
                <hr className=" pr20 "></hr>
                <Row type='flex' justify='center' className=" pt10 ant-legacy-form-item-control">
                    {(props.reminderList && props.reminderList.length) ?
                        <Col span={23} className=" pl20 ">
                            <b>Active Reminder{props.reminderList.length > 1 ? 's' : ''}</b>
                            {(props.reminderList || []).map((item, i) => {
                                return (
                                    <div key={i}>
                                    <Row>
                                       <Col span="20"> {item.days || "0"} days {item.isBeforeDueDate ? "before" : "after"} the due date </Col>
                                       {item.email ?
                                                <Col span={2} > <Button icon={<MailOutlined />} onClick={() => {
                                                    updateState({
                                                        emailDetailDrawerVisible: true,
                                                        emailDetails: item.email,
                                                        updateReminder: function (newEmailDetails) {
                                                            const modalBody = <FormattedMessage id='save.confirmation' defaultMessage='' />;
                                                            const modalData = {
                                                                modalBody,
                                                                handleSubmit: () => {
                                                                    let payload = { ...item, email: newEmailDetails };
                                                                    props.saveInvoiceReminder(payload, props);
                                                                   // props.popModalFromStack();
                                                                    updateState({ remindBeforeOrAfterDueDate: 'before', days: 0, updateReminder: undefined, emailDetailDrawerVisible: false })
                                                                },
                                                            };
                                                            props.showModal(modalData);
                                                        }
                                                    })
                                                }}>
                                                </Button></Col> :
                                                <Col span={2} ></Col>
                                            }
                                       <Col span={2} > <Button icon={<DeleteOutlined />} onClick={() => {
                                                const modalBody = <FormattedMessage id='common.delete.confirmation' />;
                                                const modalData = {
                                                    modalBody,
                                                    handleSubmit: () => {
                                                        props.deleteInvoiceReminder({
                                                            relationshipId: props.companyInfo.relationshipId,
                                                            reminderSalesInvoiceList: [item],
                                                            pageNumber: props.pageNumber || 0,
                                                            pageSize: props.pageSize || 100,
                                                            invoiceMasterId: item.invoiceMasterId,
                                                        }, props);
                                                        //props.popModalFromStack();
                                                    },
                                                };
                                                props.showModal(modalData);
                                            }}>
                                            </Button></Col> 
                                       
                                    </Row>
                                    <hr></hr>
                                    </div>
                                )
                            })
                            }
                        </Col> :
                        <Col>
                            <b><FormattedMessage id='noActive.reminder' /></b>
                        </Col>
                    }
                </Row>
                <br/><br/><br/>
            </div>
        </div>
    );

}

export default ReminderSetup;