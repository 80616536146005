import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
//import { MailOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import { Dropdown } from "../../../../general/Dropdown";
//import moment from 'moment-timezone';
// import { MODAL_TYPE } from '../../../../../static/constants';
import { showToasterMessage, getCurrencySymbol } from '../../../../../utils';
// import { TextBox } from '../../../../general/TextBox';
import {LabelBox} from '../../../../general/LabelBox';
// const { Option } = Select;

const AddLandedCost = (props) => {
    const { lcSetupList=[{}] } = props;
// for re-calculating values on change  of exchange rate
    useEffect(() => {
        for (let i = 0; i < lcSetupList.length; i++) {
            reCalculateRowValues(i);
        }
    }, [props.exchangeRate]);


    const addToList = (idx, itemObj, reCalc) =>{
        let tempList = lcSetupList;
        tempList[idx] = {...tempList[idx], ...itemObj};
        props.updatePopupState({lcSetupList: tempList});

        if(reCalc){
            reCalculateRowValues(idx);
        }
    }

    const reCalculateRowValues = (idx) =>{
        let tempList = lcSetupList;
        const amountHome = ((tempList[idx].foreignCurrencyAmount || 0) * (props.exchangeRate || 1));
        const taxAmount = ((amountHome || 0) * ( (tempList[idx].taxPercent || 0)/100 ));
        const totalAmount = ((taxAmount || 0) + (amountHome || 0));

        tempList[idx] = {...tempList[idx], amountHome, taxAmount, totalAmount};
        props.updatePopupState({lcSetupList: tempList});
    }

    const saveLC = () => {
        if(lcSetupList.length === 0){
            return showToasterMessage({
                description: 'Please add at least one data!',
                messageType: 'error'
            })
        }
        props.updatePopupState({submittedLC: true});
        // if(!props.selectedCountryName){
        //     return showToasterMessage({
        //         description: 'Country name is missing!',
        //         messageType: 'error'
        //     })
        // }
        // if(!props.exchangeRate){
        //     return showToasterMessage({
        //         description: 'Exchange rate is missing!',
        //         messageType: 'error'
        //     })
        // }
        let itemList = [];
        for(let i=0; i<lcSetupList.length; i++){
            const itemObj = lcSetupList[i]
            // if(Object.keys(itemObj).length === 0){
            //     let tempList = lcSetupList;
            //     tempList.splice(i, 1);
            //     props.updatePopupState({lcSetupList: tempList})
            // }
            if(!itemObj.expenseTypeName || !itemObj.ledgerAccountIdCredit || !itemObj.ledgerAccountIdDebit || !itemObj.supplierId || !itemObj.foreignCurrencyAmount || !itemObj.amountHome || !itemObj.totalAmount || !itemObj.distributionBy){
                return showToasterMessage({
                    description: 'Some field values are missing!',
                    messageType: 'error'
                })
            }
        
            itemList.push(
                {
                    supplierId: itemObj.supplierId,
                    supplierName: itemObj.supplierName,
                    foreignCurrencyIcon: props.countryObj?.currencyIcon,
                    foreignCurrency: props.countryObj?.currencyName,
                    exchangeRate: Number(props.exchangeRate),
                    foreignCurrencyAmount: Number(itemObj.foreignCurrencyAmount),
                    currencyCode: props.countryObj?.currencyCode,
                    countryName: props.countryObj?.countryName,
                    isMultiCurrency: !props.exchangeRate || props.exchangeRate === 1 ? 1 : 0,
                    amount: itemObj.totalAmount,
                    taxId: itemObj.taxId,
                    taxName: itemObj.taxName,
                    taxRate: itemObj.taxPercent,
                    taxAmount: itemObj.taxAmount,
                    distributionBy: itemObj.distributionBy,
                    distributeId: itemObj.distributeId,
                    ledgerAccountIdCredit: itemObj.ledgerAccountIdCredit,
                    ledgerAccountNameCredit: itemObj.ledgerAccountNameCredit,
                    ledgerAccountIdDebit: itemObj.ledgerAccountIdDebit,
                    ledgerAccountNameDebit: itemObj.ledgerAccountNameDebit,
                    expenseTypeName: itemObj.expenseTypeName,
                    expenseTypeId: itemObj.expenseTypeId,
                }
            )
            
        }

        // props.showModal({
        //     modalBody: 'Are you sure want to submit?',
        //     handleSubmit: ()=>{
                // props.updateState({lcSetupList: itemList});
                // props.popModalFromStack();
        //     }
        // })
        props.updateState({lcSetupList: itemList});
        props.popModalFromStack();
    }

    const getTotalAmountHome = () =>{
        let totalSum=0;
        for(let i=0; i<lcSetupList.length; i++){
            totalSum = totalSum + (lcSetupList[i].amountHome || 0);
        }
        return totalSum;
    }

    const getTotalTaxAmount = () =>{
        let totalSum=0;
        for(let i=0; i<lcSetupList.length; i++){
            totalSum = totalSum + (lcSetupList[i].taxAmount || 0);
        }
        return totalSum;
    }

    const getLcSetupListFiltered = (pList)=>{
        const tempList = [];
        for (let obj1 of pList) {
            if (!lcSetupList.some(obj2 => Number(obj1.lcExpenseTypeId) === Number(obj2.expenseTypeId))) {
                tempList.push(obj1);
            }
        }
        return tempList;
    }

    return (
        <div style={{height: '90%'}}>
            <div className="modal-heading">
                <div className='modal-heading-left'>
                    Landed Cost Setup
                </div>
                <div className='modal-heading-right'>
                    <Dropdown
                        style={{width: '180px'}}
                        items={props.countries}
                        optionKeyName='countryId'
                        valueKeyName='countryName'
                        value={props.countryObj?.countryName}
                        placeholder={"Select Country"}
                        onSelect={
                            (countryId, obj) => {
                                let countryObj = props.countries.find((objj)=>objj.countryId === countryId)
                             
                                props.updatePopupState({ countryObj: countryObj, exchangeRate: null})
                            }
                        }
                    />

                    <LabelBox 
                        labelValue={getCurrencySymbol(props.countryObj?.currencyCode) || '-'}
                        labelStyle={{marginRight: '-41px'}}
                        style={{width: '140px'}}
                        placeholder={'0.00'}
                        type={'number'}
                        value={props.exchangeRate}
                        onChange={(e) => {
                            props.updatePopupState({exchangeRate: e.target.value});
                            for (let i = 0; i < lcSetupList.length; i++) {
                                reCalculateRowValues(i);
                            }
                        }}
                    />
                </div>
            </div>
            <div className='lc_main'>
                <div className='product-tableV2' style={{height: '83%'}}>
                    <table>
                        <thead>
                            <tr>
                                <th width='0%'></th>
                                <th width={'10%'}><FormattedMessage id='purchase.landindCost.table.heading.expenseName' /></th>
                                <th width={'10%'}><FormattedMessage id='purchase.landindCost.drawer.ledgerCredit' /></th>
                                <th width={'10%'}><FormattedMessage id='purchase.landindCost.drawer.ledgerDebit' /></th>
                                <th width={'10%'}><FormattedMessage id='landed.cost.popup.hading.expenseVender' /></th>
                                <th width={'9%'}><FormattedMessage id='landed.cost.popup.hading.amountCurrency' values={{value: props.countryObj?.currencyCode || ' - '}} /></th>
                                <th width={'9%'}><FormattedMessage id='landed.cost.popup.hading.amountCurrency' values={{value: props.companyInfo.currencyCode || ' - '}} /></th>
                                <th width={'10%'}><FormattedMessage id='landed.cost.popup.hading.tax' /></th>
                                <th width={'9%'}><FormattedMessage id='landed.cost.popup.hading.taxableAmount' /></th>
                                <th width={'9%'}><FormattedMessage id='landed.cost.popup.hading.total' /></th>
                                <th width={'10%'}><FormattedMessage id='landed.cost.popup.hading.distribute' /></th>
                                <th width={'4%'}>
                                    <FormattedMessage id='common.listing.header.action' />
                                    <span>
                                        {
                                            (props.lcSetupList || []).length===0 ?  <i className="fi fi-br-plus pointer ml5" 
                                            onClick={()=>{
                                                props.updatePopupState({ lcSetupList: [...lcSetupList, {}] });
                                            }}
                                            ></i> : ''
                                        }
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {lcSetupList && lcSetupList.length ? lcSetupList.map((item, i)=>{
                                return <tr key={i+'sd'}>
                                    <td></td>
                                    <td>
                                        <Dropdown
                                            items={getLcSetupListFiltered(props.landedCostSetupList[1])}
                                            optionKeyName='lcExpenseTypeId'
                                            valueKeyName='expenseType'
                                            style={{width: '120px'}}
                                            value={item.expenseTypeName}
                                            placeholder='Select'
                                            className={props.submittedLC && !item.expenseTypeName ? 'input-text-error' : ''}
                                            canAddNew= {true}
                                            onAddNewClick= {()=>{
                                                props.updateHeaderState({
                                                    landedCost: {
                                                    ...props,
                                                    landedCostDrawer: true,
                                                    },
                                                })
                                            }}
                                            onSelect={
                                                (lcExpenseTypeId, obj) => {
                                                    const selectedObj = props.landedCostSetupList[1].find((objj) => objj.lcExpenseTypeId === Number(obj.key))
                                                    addToList(i, { 
                                                        expenseTypeId: Number(obj.key),
                                                        expenseTypeName: obj.name,
                                                        ledgerAccountIdCredit: selectedObj.ledgerAccountIdCredit,
                                                        ledgerAccountNameCredit: selectedObj.ledgerNameCredit,
                                                        ledgerAccountIdDebit: selectedObj.ledgerAccountIdDebit,
                                                        ledgerAccountNameDebit: selectedObj.ledgerNameDebit,
                                                    })
                                                }
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Dropdown
                                            items={props.allLedgerAccounts}
                                            optionKeyName='chartOfAccountDetailsId'
                                            valueKeyName='ledgerAccountName'
                                            value={item.ledgerAccountNameCredit}
                                            style={{width: '120px'}}
                                            placeholder='Select'
                                            className={props.submittedLC && !item.ledgerAccountNameCredit ? 'input-text-error' : ''}
                                            onSelect={
                                                (expenseTypeId, obj) => {
                                                    addToList(i, {ledgerAccountIdCredit: Number(obj.key), ledgerAccountNameCredit: obj.name})
                                                }
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Dropdown
                                            items={props.allLedgerAccounts}
                                            optionKeyName='chartOfAccountDetailsId'
                                            valueKeyName='ledgerAccountName'
                                            value={item.ledgerAccountNameDebit}
                                            style={{width: '120px'}}
                                            placeholder='Select'
                                            className={props.submittedLC && !item.ledgerAccountIdDebit ? 'input-text-error' : ''}
                                            onSelect={
                                                (expenseTypeId, obj) => {
                                                    addToList(i, {ledgerAccountIdDebit: Number(obj.key), ledgerAccountNameDebit: obj.name})
                                                }
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Dropdown
                                            items={props.suppliersList[1]}
                                            optionKeyName='supplierId'
                                            valueKeyName='supplierName'
                                            value={item.supplierName}
                                            placeholder='Select'
                                            className={props.submittedLC && !item.supplierId ? 'input-text-error' : ''}
                                            onSelect={(supplierId, obj)=>{
                                                addToList(i, {supplierId: Number(obj.key), supplierName: obj.name})
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <LabelBox 
                                            labelValue={getCurrencySymbol(props.countryObj?.currencyCode || '-')}
                                            type='number'
                                            placeholder='0.00'
                                            value={item.foreignCurrencyAmount}
                                            className={props.submittedLC && !item.foreignCurrencyAmount ? 'input-text-error' : ''}
                                            onChange={(e)=>{
                                                addToList(i, {foreignCurrencyAmount: e.target.value}, true)
                                                // let tempList = lcSetupList;
                                                // tempList[i] = {...tempList[i], foreignCurrencyAmount: e.target.value, amountHome: (e.target.value) * (props.exchangeRate || 1) };
                                                // props.updatePopupState({lcSetupList: tempList});
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <LabelBox 
                                            labelValue={getCurrencySymbol(props.companyInfo.currencyCode) || '-'}
                                            type='number'
                                            placeholder='0.00'
                                            value={item.amountHome}
                                            className={props.submittedLC && !item.amountHome ? 'input-text-error' : ''}
                                            onChange={(e)=>{
                                                let tempList = lcSetupList;
                                                let amountHome= Number(e.target.value || 0);
                                                let foreignCurrencyAmount= amountHome / (props.exchangeRate || 1);
                                                let taxAmount = amountHome * ((item.taxPercent || 0)/100);
                                                let totalAmount = amountHome;
                                                tempList[i] = {
                                                    ...tempList[i],
                                                    amountHome,
                                                    foreignCurrencyAmount,
                                                    taxAmount,
                                                    totalAmount,
                                                    };
                                                props.updatePopupState({lcSetupList: tempList});
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Dropdown
                                            items={props.taxes}
                                            optionKeyName='taxSingleRateId'
                                            valueKeyName='taxNameDisplay'
                                            value={item.taxName}
                                            disabled={!props.exchangeRate}
                                            placeholder='Select'
                                            onSelect={(taxSingleRateId, obj)=>{
                                                const taxObj = (props.taxes).find((objj) => objj.taxSingleRateId === taxSingleRateId)
                                                const taxAmount = item.amountHome * (taxObj.taxPercent/100)
                                                addToList(i, {taxId: Number(obj.key), taxName: obj.name, taxPercent: taxObj.taxPercent, taxAmount: taxAmount}, true)
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <LabelBox 
                                            labelValue={getCurrencySymbol(props.companyInfo.currencyCode) || '-'}
                                            type='number'
                                            disabled={true}
                                            placeholder='0.00'
                                            value={item.taxAmount}
                                            // onChange={(e)=>{
                                            //     addToList(i, {taxAmount: e.target.value})
                                            // }}
                                        />
                                    </td>
                                    <td>
                                        <LabelBox 
                                            labelValue={getCurrencySymbol(props.companyInfo.currencyCode) || '-'}
                                            type='number'
                                            placeholder='0.00'
                                            value={item.totalAmount || 0}
                                            disabled={true}
                                            // onChange={(e)=>{
                                            //     addToList(i, {totalAmount: e.target.value})
                                            // }}
                                        />
                                    </td>
                                    <td>
                                        <Dropdown
                                            items={[{distributeName: 'Quantity', key: 'quantity'}, {distributeName: 'Rate', key: 'rate'}, {distributeName: 'Weight', key: 'weight'}, {distributeName: 'Volume', key: 'volume'}, {distributeName: 'Equal', key: 'equal'}, ]}
                                            optionKeyName='key'
                                            valueKeyName='distributeName'
                                            value={item.distributionBy}
                                            placeholder='Select'
                                            className={props.submittedLC && !item.distributionBy ? 'input-text-error' : ''}
                                            onSelect={(objId, obj)=>{
                                                addToList(i, {distributeId: obj.key, distributionBy: obj.key})
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <i className="fi fi-rr-trash pointer mr5 ml10"
                                            onClick={()=>{
                                                let tempList = lcSetupList;
                                                tempList.splice(i, 1);
                                                props.updatePopupState({
                                                    lcSetupList: tempList
                                                })
                                            }}
                                        ></i>
                                        {
                                            i === (lcSetupList.length-1) ?  <i className="fi fi-br-plus pointer"
                                            onClick={()=>{
                                                props.updatePopupState({ lcSetupList: [...lcSetupList, {}] });
                                            }}
                                            ></i> : ''
                                        }
                                    </td>
                                </tr>
                                }) 
                                :
                                ''
                            }
                        </tbody>
                    </table>
                </div>
                <Row justify='end' className='mt20'>
                    <Col span={4} style={{backgroundColor: '#e7e7e7', height:'60px', padding: '0px 10px'}}>
                        <Row>
                            <Col span={16}>Amount</Col>
                            <Col span={8}>{getCurrencySymbol(props.companyInfo.currencyCode) || '-'}{getTotalAmountHome().toFixed(2)}</Col>
                            <Col span={16}>Tax Amount</Col>
                            <Col span={8}>{getCurrencySymbol(props.companyInfo.currencyCode) || '-'}{getTotalTaxAmount().toFixed(2)}</Col>
                            <Col span={16}>Total Amount</Col>
                            <Col span={8}>{getCurrencySymbol(props.companyInfo.currencyCode) || '-'}{(getTotalAmountHome() + getTotalTaxAmount()).toFixed(2)}</Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className='lc_footer'>
                <Button
                    onClick={()=>{
                        props.showModal({
                            modalBody: 'All changes will be erased. Are you sure want to close?',
                            handleSubmit: ()=>{
                                props.popModalFromStack();
                            }
                        })
                    }}
                >
                    Close
                </Button>
                <Button 
                    type='primary'
                    onClick={()=>{
                        saveLC();
                    }}
                >
                    <FormattedMessage id='forgot.btn.save' defaultMessage='' />
                </Button>
            </div>
        </div>
    );

}

export default AddLandedCost;