import React from "react";
//import { FilePdfOutlined, MailOutlined } from '@ant-design/icons';
import { Row, Col, Button,Skeleton } from "antd";
import ReactHtmlParser from "react-html-parser";
import { CONSTANTS, TRANSACTION_TYPES } from "../../../../../static/constants";
import {
  fixedDecimalNumber,
  getMomentDateForUIReadOnly,
} from "../../../../../utils";
import { FormattedMessage } from "react-intl";

const CustomerPaymentDetails = (props) => {
  const { paymentDetail } = props;
  
  const getPayloadForPdf = () => {
    return {
      fileName: (paymentDetail.paymentNumber || "Customer Payment") + ".pdf",
      transactionName: "customerPayment",
      paymentDetail: paymentDetail,
      companyInfo: props.companyInfo,
      relationshipAddress: props.relationshipAddress,
    };
  };

  const generatePDF = () => {
    let payload = getPayloadForPdf();
    props.generatePDF(payload);
  };

  const openEmailDrawer = (props) => {
    let payload = getPayloadForPdf();
    payload.fileDataReqeired = true;
    payload.customerId = paymentDetail.customerId;
    payload.customerPaymentId = paymentDetail.customerPaymentId;
    props.updateState({
      emailDrawerVisible: true,
      txData: payload,
      type: TRANSACTION_TYPES.CUSTOMER_PAYMENT,
    });
  };

  return (
    <div className={"show"}>
      <div className="modal-dialog">
      <Skeleton loading={props.detailLoading} active paragraph={{ rows: 5 }}>

        <div className="modal-content payment-details-modal">
          <div className="prl40 read-only-content">
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={12} className="text-right title">
                    <FormattedMessage
                      id="modal.customer.payInvoice.customerName"
                      defaultMessage=""
                    />
                  </Col>

                  <Col span={12}>{paymentDetail.customerName}</Col>
                </Row>

                <Row className="pt10">
                  <Col span={12} className="text-right title">
                    <FormattedMessage
                      id="modal.txDetails.payment.paymentNumber"
                      defaultMessage=""
                    />
                  </Col>

                  <Col span={12}>{paymentDetail.paymentNumber}</Col>
                </Row>

                <Row className="pt10">
                  <Col span={12} className="text-right title">
                    <FormattedMessage
                      id="modal.txDetails.payment.invoiceNumber"
                      defaultMessage=""
                    />
                  </Col>

                  <Col span={12}>
                    <span className="link">{paymentDetail.invoiceNumber}</span>
                  </Col>
                </Row>

                <Row className="pt10">
                  <Col span={12} className="text-right title">
                    <FormattedMessage
                      id="modal.txDetails.payment.paymentDate"
                      defaultMessage=""
                    />
                  </Col>

                  <Col span={12}>
                    {paymentDetail.paymentDate &&
                      getMomentDateForUIReadOnly({
                        date: paymentDetail.paymentDate,
                        format: CONSTANTS.DISPLAY_DATE_FORMAT,
                      })}
                  </Col>
                </Row>

                {paymentDetail.paymentModeName ? (
                  <Row className="pt10">
                    <Col span={12} className="text-right title">
                      <FormattedMessage
                        id="modal.txDetails.payment.paymentMode"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={12}>{paymentDetail.paymentModeName}</Col>
                  </Row>
                ) : (
                  ""
                )}

                <Row className="pt10">
                  <Col span={12} className="text-right title">
                    <FormattedMessage
                      id="modal.txDetails.payment.paymentAmount"
                      defaultMessage=""
                    />
                  </Col>

                  <Col span={12}>
                    {props.companyInfo.currencyIcon ? (
                      <i className={props.companyInfo.currencyIcon}></i>
                    ) : (
                      props.companyInfo.currencyCode + " "
                    )}
                    {fixedDecimalNumber(paymentDetail.paymentAmount || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Col>
                </Row>

                <Row className="pt10">
                  <Col span={12} className="text-right title">
                    <FormattedMessage
                      id="modal.txDetails.payment.invoiceAmount"
                      defaultMessage=""
                    />
                  </Col>

                  <Col span={12}>
                    {props.companyInfo.currencyIcon ? (
                      <i className={props.companyInfo.currencyIcon} />
                    ) : (
                      props.companyInfo.currencyCode + " "
                    )}
                    {fixedDecimalNumber(paymentDetail.invoiceAmount || 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Col>
                </Row>

                {paymentDetail.reference && (
                  <Row className="pt10">
                    <Col span={12} className="text-right title">
                      <FormattedMessage
                        id="modal.txDetails.payment.refNumber"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={12}>{paymentDetail.reference}</Col>
                  </Row>
                )}

                {paymentDetail.remarks ? (
                  <Row className="pt10">
                    <Col span={12} className="text-right title">
                      <FormattedMessage
                        id="modal.txDetails.payment.remarks"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={12}>
                      {ReactHtmlParser(paymentDetail.remarks)}
                    </Col>
                  </Row>
                ):''}
                {paymentDetail.projectMasterId ? (
                  <Row className="pt10">
                    <Col span={12} className="text-right title">
                      <FormattedMessage
                        id="modal.txDetails.payment.project"
                        defaultMessage=""
                      />
                    </Col>

                    <Col span={12}>
                      {paymentDetail.projectMasterId +
                        "-" +
                        paymentDetail.projectName}
                    </Col>
                  </Row>
                ):''}
              </Col>
            </Row>
          </div>
          <div className="button-group-action payment-details-action">
            <Button
              className={"mr5 ml45"}
              type="primary"
              onClick={() => {
                openEmailDrawer(props);
              }}
            >
              <FormattedMessage
                id="modal.txDetails.common.email"
                defaultMessage=""
              />
            </Button>

            <Button
              className={"mr5"}
              type="primary"
              onClick={() => {
                generatePDF();
              }}
            >
              <FormattedMessage
                id="modal.txDetails.common.download"
                defaultMessage=""
              />
            </Button>
            {/* <Button
                        className={'mr5'}
                            type="primary"
                            icon="printer"
                        >
                            Print
                        </Button> */}

            <Button
              onClick={() => {
                // props.pushModalToStack({
                //     modalBody: "Delete payment is not allowed, to fix the problem create the journal entry",
                //     handleSubmit: () => { }
                // })
                props.pushModalToStack({
                  modalBody: (
                    <FormattedMessage id="common.delete.confirmation" />
                  ),
                  handleSubmit: () => {
                    props.deletePayment(paymentDetail, props);
                    props.hideModal();
                  },
                });
              }}
              type="default"
            >
              <FormattedMessage id="delete.text" defaultMessage="" />
            </Button>
          </div>
        </div>
        </Skeleton>
      </div>
    </div>
  );
};

export default CustomerPaymentDetails;
