import React, { useState } from "react";
import "../../../../eStore/storeSettings/store-settings.scss";
import { Button, Col, Row, Tabs, Radio, Switch } from "antd";
import { CONSTANTS, ICONS } from "../../../../../static/constants";
import { TextBox } from "../../../../general/TextBox";

import { Dropdown } from "../../../../general/Dropdown";
import Import from "../../supplier/Import";
import { getCurrencySymbol } from "../../../../../utils";

const rowGutter = [4, 4];

const FixedCharge = (props) => {

  const priceRange = props.deliveryValue.chargesInfo;

  const icon = props.companyInfo;

  const handleStartChange = (id, value) => {
    props.updateState({ fixedError: "" });
    const val = props.deliveryValue;
    let tempFilter = val;
    const arr = tempFilter.chargesInfo;
    const value1 = arr.map((item) =>
      item.id === id ? { ...item, starting: value } : item
    );
    tempFilter.chargesInfo = value1;
    props.updateState({ deliveryValue: tempFilter });
  };

  let weightSymbol;

  switch (props.deliveryValue?.weightUnit) {
    case "Kilograms":
      weightSymbol = "Kg";
      break;
    case "Grams":
      weightSymbol = "gm";
      break;
    case "Pounds":
      weightSymbol = "lb";
      break;
    case "Ounce":
      weightSymbol = "Oz";
      break;
    default:
  }

  return (
    <div>
      <Row className="" style={{ padding: "10px 0px" }}>
        <Col span={6}>
          {!props.deliveryValue?.byCurrency ? (
            <Dropdown
              items={["Kilograms", "Grams", "Pounds", "Ounce"]}
              placeholder="Select Weight"
              disabled={props.deliveryValue?.isDeliveryCharge ? false : true}
              value={props.deliveryValue?.weightUnit}
              onSelect={(selectedValue, option) => {
                const val = props.deliveryValue;
                let tempFilter = val;
                tempFilter.weightUnit = selectedValue;
                props.updateState({ deliveryValue: tempFilter });
              }}
              allowClear={true}
              // onClear={() => {
              //   this.props.updateState({
              //     priority: undefined,
              //   });
              // }}
            ></Dropdown>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "5px" }}>
        <Col span={24}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <div className="i-label">
                Delivery charge per order
                <span className="required">*</span>
              </div>

              <TextBox
                placeholder="Enter Delivery Charge"
                type="number"
                required={true}
                disabled={props.deliveryValue?.isDeliveryCharge ? false : true}
                prefix={
                  !props.deliveryValue?.byCurrency
                    ? weightSymbol
                    : getCurrencySymbol(icon?.currencyCode)
                }
                value={priceRange[0]?.starting ? priceRange[0]?.starting : ""}
                onChange={(e) =>
                  handleStartChange(priceRange[0]?.id, parseInt(e.target.value))
                }
              />

              {(props.deliveryValue?.isFixed && props.fixedError) ? (
                <div style={{ color: "red" }}>{props.fixedError}</div>
              ):<div>
                  {
                    (priceRange[0]?.starting>0) && <span>{getCurrencySymbol(icon?.currencyCode)}{priceRange[0]?.starting}  will be charged on all orders {(priceRange[1]?.starting>0) && (<span>below {getCurrencySymbol(icon?.currencyCode)}{priceRange[1]?.starting}</span>)} </span>
                  }
                </div>}
            </Col>

            <Col span={12}>
              <div className="i-label">Free delivery above</div>
              <TextBox
                placeholder="Enter Free Delivery Price"
                type="number"
                disabled={props.deliveryValue?.isDeliveryCharge ? false : true}
                prefix={
                  !props.deliveryValue?.byCurrency
                    ? weightSymbol
                    : getCurrencySymbol(icon?.currencyCode)
                }
                value={priceRange[1]?.starting ? priceRange[1]?.starting : ""}
                onChange={(e) =>
                  handleStartChange(priceRange[1]?.id, parseInt(e.target.value))
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FixedCharge;
