import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MarketplaceStatesTaxComponent from '../../../../components/ShowSettings/taxes/marketplaceStates';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../actions/commonActions';
import { getAllMarketplaceStateTaxRate, saveUpdateMarketplaceStateTax, deleteMarketplaceStateTaxRate, getCountryStates } from "./action"
import { fetchDataIfNeeded } from "../../../../utils";

class MarketplaceStatesTax extends Component {

    constructor(props) {
        super(props);
        const linkProps = (this.props.location && this.props.location.state) || {};
        this.state = {
            marketplaceTaxCountryId: linkProps.marketplaceTaxCountryId,
            countryId: linkProps.countryId,
            countryName: linkProps.countryName,
            isTaxAutomated: linkProps.isTaxAutomated
        }
    }

    componentDidMount() {
        this.props.updateHeaderState({
            collapsed: true
        });
        const companyInfo = this.props.companyInfo || {};
        const payload = {
            companyInfo,
            relationshipId: companyInfo.relationshipId,
            marketplaceTaxCountryId: this.state.marketplaceTaxCountryId,
        };
        fetchDataIfNeeded("getAllMarketplaceStateTaxRate", "marketplaceStates", this.props, payload);
        //fetchDataIfNeeded("getCountryStates", "countryStates", this.props, payload);
    }

    render() {
        return <MarketplaceStatesTaxComponent {...this.props}  {...this.state} updateState={(data) => {
            this.setState(data);
        }} />
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        marketplaceStates: state.taxSettingReducer.marketplaceStates,
        countryStates: state.taxSettingReducer.countryStates
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getAllMarketplaceStateTaxRate,
    saveUpdateMarketplaceStateTax,
    deleteMarketplaceStateTaxRate,
    getCountryStates,
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceStatesTax);
