import { STUDENT_REGISTRATION_ACTION_LIST } from '../static/constants';
const initialState = {
  studentRegistrationList: {
    1: []
  },
  studentRegistrationCount: 0,
  availablePaymentGateways: [],
  totalStudentCount: 0,
  totalStudentCountByGrade: {},
  totalStudentCountByStatus: {},
  totalParentsCount: 0,
  allForms: [],
  studentDetailsList: [],
  registerParentsData: {
    1: []
  },
  studentRegistrationDetail: {},
  totalRevenueForecast: 0,
  totalInvoiceGenerated: 0,
  totalAmountReceived: 0,
  totalInvoiceDue: 0,
  totalExpenseDue: 0,
  totalFeesDue: 0,
  totalFeesPaid: 0,
  recentSchoolPayments: [],
  totalFinancialAssisatance: {},
  paymentReminder: {},
  
  unpaidInvoices: {
    1: []
  },
  unpaidInvoiceCount: 0,
  unpaidCustomers: {
    1: []
  },
  unpaidCustomersCount: 0,
  customerRegistration: [],
  otpSentRes: {},
  otpVerifyRes: {},
  orgRegistrationData: {},
  registrationInvoiceProforma: [],
  existingRegInvoiceProforma: [],
  studentLimitCount: 0,
}

const StudentRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_LIST:
      return {
        ...state,
        studentRegistrationList: {
          ...state.studentRegistrationList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_COUNT:
      return { ...state, studentRegistrationCount: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_LIST_PAGE_RESET:
      return { ...state, studentRegistrationList: initialState.studentRegistrationList };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT:
      return { ...state, totalStudentCount: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE:
      return { ...state, totalStudentCountByGrade: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_STATUS:
      return { ...state, totalStudentCountByStatus: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.AVAILABLE_PAYMENT_GATEWAY:
      return { ...state, availablePaymentGateways: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_PARENTS_COUNT:
      return { ...state, totalParentsCount: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.All_FORM_NAME:
      return { ...state, allForms: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.STUDENT_DETAIL:
      return { ...state, studentDetailsList: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.RESET_STUDENT_DETAIL:
      return { ...state, studentDetailsList: initialState.studentDetailsList };
    case STUDENT_REGISTRATION_ACTION_LIST.REGISTER_PARENTS_DATA:
      return {
        ...state,
        registerParentsData: {
          ...state.registerParentsData,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case STUDENT_REGISTRATION_ACTION_LIST.RESET_REGISTER_PARENTS_DATA:
      return { ...state, registerParentsData: initialState.registerParentsData };
    case STUDENT_REGISTRATION_ACTION_LIST.STUDENT_REGISTRATION_DETAIL:
      return { ...state, studentRegistrationDetail: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.RESET_STUDENT_REGISTRATION_DETAIL:
      return { ...state, studentRegistrationDetail: initialState.studentRegistrationDetail };
    
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_REVENUE_FORECAST:
      return { ...state, totalRevenueForecast: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_INVOICE_GENERATED:
      return { ...state, totalInvoiceGenerated: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_AMOUNT_RECEIVED:
      return { ...state, totalAmountReceived: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_INVOICE_DUE:
      return { ...state, totalInvoiceDue: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_EXPENSE_DUE:
      return { ...state, totalExpenseDue: action.data };

    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_FEES_DUE:
      return { ...state, totalFeesDue: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_FEES_PAID:
      return { ...state, totalFeesPaid: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.RECENT_SCHOOL_PAYMENT:
      return { ...state, recentSchoolPayments: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.TOTAL_FINANCIAL_ASSISATANCE:
      return { ...state, totalFinancialAssisatance: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.PAYMENT_REMINDER:
      return { ...state, paymentReminder: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.UNPAID_INVOICES_BY_FORM_ID:
      return {
        ...state,
        unpaidInvoices: {
          ...state.unpaidInvoices,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case STUDENT_REGISTRATION_ACTION_LIST.UNPAID_INVOICES_COUNT_BY_FORM_ID:
      return { ...state, unpaidInvoiceCount: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.UNPAID_INVOICES_BY_FORM_ID_RESET:
      return { ...state, unpaidInvoices: initialState.unpaidInvoices };

    case STUDENT_REGISTRATION_ACTION_LIST.UNPAID_CUSTOMERS_BY_FORM_ID:
      return {
        ...state,
        unpaidCustomers: {
          ...state.unpaidCustomers,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case STUDENT_REGISTRATION_ACTION_LIST.UNPAID_CUSTOMERS_COUNT_BY_FORM_ID:
      return { ...state, unpaidCustomersCount: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.UNPAID_CUSTOMERS_BY_FORM_ID_RESET:
      return { ...state, unpaidCustomers: initialState.unpaidCustomers };

    case STUDENT_REGISTRATION_ACTION_LIST.CUSTOMER_REGISTRATION_LIST:
      return { ...state, customerRegistration: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.OTP_SENT_RES:
      return { ...state, otpSentRes: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.OTP_VERIFY_RES:
      return { ...state, otpVerifyRes: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.ORG_REGISTRATION_DATA:
      return { ...state, orgRegistrationData: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.ORG_REGISTRATION_INVOICE_PROFORMA:
      return { ...state, registrationInvoiceProforma: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.EXISTING_ORG_REGISTRATION_INVOICE_PROFORMA:
      return { ...state, existingRegInvoiceProforma: action.data };
    case STUDENT_REGISTRATION_ACTION_LIST.STUDENT_LIMIT_COUNT:
      return { ...state, studentLimitCount: action.data };
    default:
      return state;
  }
};

export default StudentRegistrationReducer;