import axios from "axios";
import config from "../../../../../config/environmentConfig"

import { showToasterMessage } from "../../../../../utils";
import { COMMON_ACTIONS } from "../../../../../static/constants";
import { E_STORE } from "../../../../../static/constants";
export const saveEcomWebsiteUrl = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecomWebsiteUrl/saveWebsiteUrl`,payload
        )
        .then((res) => {
          // dispatch({ type: COMMON_ACTIONS.STORE_SETTING_VALUES, data: res.data });
        })
        .catch((err) => {
        });
    };
  };
  export const fetchEcomWebsiteUrl = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/ecomWebsiteUrl/getSubdomain?relationshipId=${payload.relationshipId}`
        )
        .then((res) => {

       
          dispatch({ type: E_STORE.ECOMSUBDOMAIN_ID, data: res.data });
        })
        .catch((err) => {
        });
    };
  };