import React from 'react'
import LoadingIcon from "../../../../assets/images/loading.gif"
import { injectIntl } from 'react-intl';
import { Button } from 'antd';


const Header = (props) => {
  const getNextLcId =()=>{
    if(props.lcNumber){
      return props.lcNumber;
    }
    if(props.landedCostList[props.pageNumber || 1].length){
      const num = props.landedCostList[props.pageNumber].sort((a, b)=> a.dateCreated < b.dateCreated ? 1 : -1)[0].lcNumber.split('-')[1]
      return `LC-${Number(num)+1}`;
    }
    else{
      return false;
    }
  }
  return <>
    <div className='left-itms'>
      <ul>
        <li className='title'>
          Landed Cost
        </li>
        <li className='sep' />
        <li>
          Cost # &nbsp; - &nbsp;
            {getNextLcId() ? <div>{getNextLcId()}</div> : <img src={LoadingIcon} alt="Invoice" style={{ 'height': '50px' }} />}
        </li>
      </ul>
    </div>
    <div className="right-itms">
        
    <Button onClick={() => {
              props.updateDrawerState({
                  settingPurchaseDrawerVisible: true
              })
          }} >
              <i class="fa fa-cog" aria-hidden="true"></i>
          </Button>
    </div>
  </>
}

export default injectIntl(Header)
