import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col } from 'antd';
import ReactHtmlParser from 'react-html-parser';

function CommunicationDrawer(props) {
  
  const closeDrawer = () => {
    props.updateState({
      communicationReviewDrawerVisible: false
    })
  }
  
  return (
    <Drawer
      title={<FormattedMessage id='communication.text' defaultMessage='' />}
      className="email-template-modal"
      keyboard={true}
      width={720}
      maskClosable={false}
      zIndex={99999}
      destroyOnClose={true}
      onClose={closeDrawer}
      visible={props.communicationReviewDrawerVisible}
      afterVisibleChange={
        (visible) => {
          if (visible) { }
        }
      }>

        <Row gutter={[16, 16]} style={{padding: "20px"}}>
          <Col span={4} className='b-text'><FormattedMessage id='to.text' /> : </Col>
          <Col span={20}>{props.communicationDetail.communiatedTo || ''}</Col>
          {props.communicationDetail.emailCC ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='cc.text' /> : </Col>
              <Col span={20}>{props.communicationDetail.emailCC}</Col>
            </> : ''
          }

          {props.communicationDetail.emailBCC ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='bcc.text' /> : </Col>
              <Col span={20}> {props.communicationDetail.emailBCC}</Col>
            </> : ''
          }
      
          {props.communicationDetail.emailSubject ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='subject.text' /> : </Col>
              <Col span={20}>{props.communicationDetail.emailSubject}</Col>
            </> : ''
          }
          {props.communicationDetail.transactionType ? <>
            <Col span={4} className='b-text'><FormattedMessage id='modal.settings.emailTemplate.transaction' /> : </Col>
            <Col span={20}><FormattedMessage id={props.communicationDetail.transactionType} /></Col>
          </> : ''
          }


          {props.communicationDetail.emailMessage ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='message.text' /> : </Col>
              <Col span={20}>{ReactHtmlParser(props.communicationDetail?.emailMessage || '')}</Col>
            </> : ''
          }

          {props.communicationDetail.refDocId ?
            <>
              <Col span={4} className='b-text'><FormattedMessage id='modal.txDetails.common.attachments' /> : </Col>
              <Col span={20}>
                <div onClick={() => {
                  props.downloadFileFromBucket({awsKeyName: props.communicationDetail.refDocId, bucketName: props.communicationDetail.bucketName})
                }}>
                  <i className="fi fi-rr-file-download pointer" style={{fontSize: '30px'}}/>
                </div>
              </Col>
            </> : ''
          }
        </Row>

    </Drawer>
  );
}

export default injectIntl(CommunicationDrawer);
