import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');


export const applyUserPlans = (loginData) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/userPlan/applyUserPlans?relationshipId=${loginData.relationshipId}&clientUserAccountId=${loginData.clientUserAccountId}&planCode=${loginData.planCode}&addons=${loginData.addons}`)
        .then(res => {
          showToasterMessage({
            description: (res || {}).message || 'Plan Applied successfully.',
            messageType: 'success'
          });
        })
        .catch(err => {
          showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
        })
    }
}

export const getAllPricingPlans = (props) => {
    return dispatch => {
      return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSupport}/userPlan/getAllPricingPlans`)
        .then(response => {
          dispatch({ type: COMMON_ACTIONS.PRICING_PLANS_LIST, data:response.data });
        })
        .catch(err => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred, please contact support team for upgrade' })
        })
    }
  }


  export const getSubcriptionPlanCost = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.CMS_BASE_URL}/${config.rootContextCMS}/${config.API_VERSION_CMS}/subscription/getSubscriptionPlans?alpideProductId=${payload.alpideProductId}&countryName=${payload.countryName}`)
          .then(response => {
            dispatch({ type: COMMON_ACTIONS.SUBCRIPTION_PLAN_LIST, data:response.data?.data });
          })
          .catch(err => {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred, please contact support team for upgrade' })
          })
          .finally(()=>{
            dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
          })
    }
  }
  export const resetSubcriptionPlanCost = (payload) => {
    return dispatch => {
    
      return   dispatch({ type: COMMON_ACTIONS.SUBCRIPTION_PLAN_LIST, data:[] });
    }
  }

  export const getSubcriptionAddonCost = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.CMS_BASE_URL}/${config.rootContextCMS}/${config.API_VERSION_CMS}/subscriptionAddons/getSubscriptionAddons?alpideProductId=${payload.alpideProductId}&countryName=${payload.countryName}`)
          .then(response => {
            dispatch({ type: COMMON_ACTIONS.SUBCRIPTION_ADDON_LIST, data:response.data?.data });
          })
          .catch(err => {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred, please contact support team for upgrade' })
          })
          .finally(()=>{
            dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
          })
    }
  }

  export const subscribedPlan = (payload, cb) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      
      return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSupport}/subscription/buySubscription`,
        payload
      ) .then(res => {
        cb && cb(res.data);
          showToasterMessage({
            description: (res || {}).message || 'Plan Applied successfully.',
            messageType: 'success'
          });
        })
        .catch(err => {
          showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' });
        }) .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        })
    }
}

export const getSubscribedProduct = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSupport}/subscription/getSubcribedProducts?relationshipId=${payload.relationshipId}`)
        .then(response => {
          dispatch({ type: COMMON_ACTIONS.SUBSCRIBED_PRODUCT, data:response.data });
        })
        .catch(err => {
          showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred, please contact support team for upgrade' })
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
  }
}
