import React from "react";
import { Drawer, Row, Col, DatePicker } from "antd";
import { FormattedMessage } from "react-intl";
import { CustomButton } from "../../general/CustomButton";
import { TextBox } from "../../general/TextBox";

const ProductPrizeEdit = (props) => {

  const { data, subData={} } = props;

  const closeDrawer = () => {
    props.updateState({
      retailPrice: null,
      wholesalePrice: null,
      purchasedPrice: null,
      productPrizeDrawerVisible: false,
    });
  };

  const updateProduct = (props) => {
    let payload = {
      retailPrice: Number(props.retailPrice) || Number(subData.retailPrice) || 0,
      purchasedPrice: Number(props.purchasedPrice) || Number(subData.purchasedPrice) || 0,
      wholesalePrice: Number(props.wholesalePrice) || Number(subData.wholesalePrice) || 0,
      itemId: data.itemId,
      itemName: data.itemName,
      // variantId: Number(subData.variantId) || 0,
      updatedByEmpId: ((props.companyInfo || {}).relationshipEmployees || {})
        .relationshipEmployeeId,
      relationshipId: data.relationshipId,
    };
    if (subData.variantId) {
      payload.variantId = subData.variantId;
    }

    const modalData = {
      modalBody: <FormattedMessage id="addUpdateData" defaultMessage="" />,
      handleSubmit: () => {
        props.updateInventoryItem(payload, props);
        props.hideModal();
        closeDrawer();
      },
    };
    props.showModal(modalData);
  };

  const rowGutter = [24, 16];
  return (
    <Drawer
      title={"Edit Price"}
      width={720}
      zIndex={1}
      maskClosable={false}
      destroyOnClose={true}
      onClose={closeDrawer}
      className="custom-drawer"
      visible={props.productPrizeDrawerVisible}
      footer={
        <>
          <CustomButton
            intlId="confirmation.cancel"
            defaultMessage=""
            type="default"
            key={0}
            onClick={closeDrawer}
          />
          <div>
            <CustomButton
              intlId="button.save.label"
              defaultMessage=""
              htmlType="submit"
              onClick={()=>updateProduct(props)}
              key={1}
            />
          </div>
        </>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={12}>
          <div className="i-label">Brand Name</div>
          <TextBox
            value={subData.brandName || data.brandName}
            disabled={true}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">SKU #</div>
          <TextBox
            value={subData.sku || data.sku}
            disabled={true}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Category</div>
          <TextBox
            value={(data.inventoryItemCategoryRefs || []).map(obj=>obj.inventoryItemCategoryName).join(', ')}
            disabled={true}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Purchased Price</div>

          <TextBox
            placeholder="Enter Amount"
            type="text"
            maxLength={20}
            value={props.purchasedPrice || subData.purchasedPrice || data.purchasedPrice}
            onChange={(e) => {
              props.updateState({ purchasedPrice: e.target.value });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Wholesale Price</div>

          <TextBox
            placeholder="Enter Amount"
            type="text"
            maxLength={20}
            value={props.wholesalePrice || subData.wholesalePrice || data.wholesalePrice}
            onChange={(e) => {
              props.updateState({ wholesalePrice: e.target.value });
            }}
          />
        </Col>
        <Col span={12}>
          <div className="i-label">Retail Price</div>

          <TextBox
            placeholder="Enter Amount"
            type="text"
            maxLength={20}
            value={props.retailPrice || subData.retailPrice || data.retailPrice}
            onChange={(e) => {
              props.updateState({ retailPrice: e.target.value });
            }}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default ProductPrizeEdit;
