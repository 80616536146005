import React from 'react';
import { Card, Row, Button } from 'antd';


// const gridStyle = {
//     width: '25%',
//     textAlign: 'center',
//   };

const TxnTypes = (props) => {

  let itemsList = ['Shipment', 'GRN'];
  // const handleSOClick= () =>{
  //   const endpointURL = '/salesOrder/getAllSalesOrders';
  //   props.fetchTransactionDataByRID(props ,endpointURL)
  //   props.updateState({activePage : 'transactionList', txnType: 'salesOrder'})
  // }

  // const handlePOClick= () =>{
  //   const endpointURL = '/purchaseOrder/getAllPurchaseOrders';
  //   props.fetchTransactionDataByRID(props, endpointURL)
  //   props.updateState({activePage : 'transactionList', txnType: 'purchaseOrder'})
  // }

  const handleShipmentClick= () =>{

    const endpointUrl = {
      fetchUrl : '/shipment/getAllShipments',
      countUrl : '/shipment/countAllShipments'
    }
    props.fetchTransactionDataByRID(props, endpointUrl);
    props.getTransactionCountByRID(props, endpointUrl);
    props.fetchCustomers(props);
    props.updateState({activePage : 'transactionList', txnType: 'shipment'})
 
  }

  const handleInboundDeliveryClick= () =>{
    
    const endpointUrl = {
      fetchUrl : '/inboundDelivery/getAllInboundDeliveries',
      countUrl : '/inboundDelivery/countAllInboundDeliveries'
    }

    props.fetchTransactionDataByRID(props, endpointUrl);
    props.getTransactionCountByRID(props, endpointUrl);
    props.fetchSuppliers(props);
    props.updateState({activePage : 'transactionList', txnType: 'inboundDelivery'})
  }
  const backClick = () =>{
    props.updateState({activePage : 'login'})
  }
  return (
    <div className='transactions-box'>
        <Card title="Transactions Types" headStyle = {{textAlign:'center', fontSize:30, boxShadow: `2px 2px 10px 2px #9E9E9E`}}>
            {
              itemsList.map((item)=>{
                  return(
                    <Row type='flex' justify='center'>
                      <Card.Grid className='links'
                        onClick = {
                        ()=>{
                          // if(item === 'Sales Order'){
                          //   handleSOClick();
                          // }

                          // if(item === 'Purchase Order'){
                          //   handlePOClick();
                          // }

                          if(item === 'Shipment'){
                            props.updateState({selectedItem : 'Shipment'});
                            handleShipmentClick();
                          }
                          
                          if(item === 'GRN'){
                            props.updateState({selectedItem : 'Inbound Delivery'});
                            handleInboundDeliveryClick();
                          }
                        }
                        }>
                        {item} {item === 'GRN' ? '(Goods Received Note)' :''}
                      </Card.Grid>
                    </Row>
                  )
              })
            }
            <Row type='flex' justify='center'>
              <Button  style = {{width : '30%', marginTop: '30px', borderRadius: '40px'}} onClick={()=>{
                backClick()
                }}
              >
                Back
              </Button>
            </Row>
        </Card>
       
      
    </div>
  )
}

export default TxnTypes