import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Row, Col, DatePicker } from 'antd';
import { CustomButton } from '../../../general/CustomButton';
import { TextBox } from '../../../general/TextBox';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { getMomentDateForUI, formatDateForBackend } from '../../../../utils';
import { CONSTANTS, TX_STATUS, MODAL_TYPE } from '../../../../static/constants';
import * as find from 'lodash.find';
import { useHistory } from 'react-router-dom';


function FundraisingCampaignDrawer(props) {
  const history = useHistory();
  const { campaignName, goal, startDate, endDate, isActive,} = props;

  const format = CONSTANTS.DISPLAY_DATE_FORMAT;

  const closeDrawer = () => {
    props.updateHeaderState({
      fundraising: {
        addCampaignDrawerVisible: false
      }
    })
  }

 const handleNewProjectAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
        title: <FormattedMessage id='addItem.text.project' defaultMessage='' />,
        formData,
        hideFooter: true,
        modalData: {
            modalType: MODAL_TYPE.PROJECT,
            data: payload,

        },
        handleSubmit: (formData = {}) => {
            data.formData.submittedOnce = true;
            props.hideModal(data);
        }
    };
    showModal(data);
}

  const handleSubmit = (props) => {
    // if (!props.projectMasterId) {
    //   return showToasterMessage({
    //     messageType: 'error', description: props.intl.formatMessage({ id: 'project.name.require' })
    //   });
    // }
    // if (!props.chartOfAccountDetailsId) {
    //   return showToasterMessage({
    //     messageType: 'error', description: props.intl.formatMessage({ id: 'drawer.inventory.finance.salesLedgerAccount.placeholder' })
    //   });
    // }
    // if (!props.alpidePaymentGatewayId) {
    //   return showToasterMessage({
    //     messageType: 'error', description: props.intl.formatMessage({ id: 'paymentGateway.notAvailable.message' })
    //   });
    // }
    if (props.campaignName && props.goal && props.startDate && props.endDate && props.projectMasterId && props.chartOfAccountDetailsId && props.alpidePaymentGatewayId && props.campaignCode) {
      const payload = {
        campaignName: props.campaignName,
        goal: props.goal,
        startDate: formatDateForBackend(props.startDate),
        endDate: formatDateForBackend(props.endDate),
        registrationFormSettingId: props.registrationFormSettingId,
        isActive: props.isActive === 'Active' ? 1 : 0,
        relationshipId: props.relationshipId,
        fundraisingCampaignId: props.fundraisingCampaignId,
        version: props.version,
        dateCreated: props.dateCreated,
        projectMasterId: props.projectMasterId,
        chartOfAccountDetailsId: props.chartOfAccountDetailsId,
        alpidePaymentGatewayId: props.alpidePaymentGatewayId,
        campaignCode: props.campaignCode || '',
        teamMasterList: props.teamMasterList || [],
        status: props.fundraisingCampaignId ? props.status : TX_STATUS.DRAFT
      };

      if (props.fundraisingCampaignId) {
        props.pushModalToStack({
          modalBody: props.fundraisingCampaignId ? <FormattedMessage id='commons.edit.popup.confimation.msg' defaultMessage='' /> : <FormattedMessage id='fundraising.campaign.addDrawer.confitmation' defaultMessage='' />,
          handleSubmit: () => {
            props.saveFundraisingCampaign(payload, props, history);
          }
        });
      } else {
        props.saveFundraisingCampaign(payload, props, history);
      }

    } else {
      props.updateState({
        submittedOnce: true,
      })
    }
  }

  return (
    <Fragment>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage id='fundraising.campaign.addDrawer.drawerName' defaultMessage=' ' />
          </div>
        }
        width={720}
        keyboard={false}
        zIndex={1}
        destroyOnClose={true}
        onClose={closeDrawer}
        maskClosable={false}
        className="custom-drawer"
        visible={props.addCampaignDrawerVisible}
        afterVisibleChange={
          (visible) => {
            if (visible) {
              //props.initCalls();
            }
          }
        }
        footer={<>
        <CustomButton
            intlId='confirmation.cancel'
            defaultMessage=''
            type='default'
            key={0}
            style={{ marginRight: 8 }}
            onClick={closeDrawer}
          />
          <CustomButton
            intlId= {props.fundraisingCampaignId ? 'confirmation.submit' : 'company.companySetup.button.next'}
            defaultMessage=''
            htmlType='submit'
            onClick={() => handleSubmit(props)}
            key={1}
          />
        </>}
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <span className="">
                <FormattedMessage id='fundraising.campaign.addDrawer.field.campaignName' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12}>
              <span className="">
                <FormattedMessage id='fundraising.campaign.addDrawer.field.campaignCode' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <TextBox
                type='text'
                className={(props.submittedOnce && !props.campaignName) ? 'input-text-error' : ''}
                value={campaignName}
                onChange={(e) => {
                  props.updateState({
                    campaignName: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'fundraising.campaign.addDrawer.field.campaignName', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.campaignName }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
            <Col span={12}>
              {/* <span className="currency-tag" style={{left:'10px', paddingTop: '4px', fontWeight: 600}}>
                C
              </span> */}
              <TextBox
                type='number'
                className={(props.submittedOnce && !props.campaignCode) ? 'input-text-error' : ''}
                value={props.campaignCode || null}
                onChange={(e) => {
                  props.updateState({
                    campaignCode: e.target.value,
                  });
                }}
                onBlur={(e) => {
                  props.doesCampaignCodeExist(props);
                }}
                placeholder={props.intl.formatMessage({ id: 'fundraising.campaign.addDrawer.field.campaignCode', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.campaignCode }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <span className="">
                <FormattedMessage id='fundraising.campaign.addDrawer.field.goal' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={6}>
              <label>
                <FormattedMessage id='fundraising.campaign.addDrawer.field.startDate' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12} className="input-currency-control">
              <span className="currency-tag">
                {props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}
              </span>
              <TextBox
                type='number'
                className={(props.submittedOnce && (props.goal <= 0 || !props.goal)) ? 'input-text-error' : ''}
                value={goal || null}
                onChange={(e) => {
                  props.updateState({
                    goal: e.target.value,
                  });
                }}
                placeholder={props.intl.formatMessage({ id: 'fundraising.campaign.addDrawer.field.goal', defaultMessage: '' })}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.goal }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
            <Col span={12}>
              <DatePicker
                allowClear={false}
                format={format}
                key='startDate'
                defaultValue={getMomentDateForUI({ date: startDate, format })}
                onChange={(selectedDate) => {
                  props.updateState({
                    startDate: selectedDate
                  })
                  // this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                }}
                placeholder={props.intl.formatMessage({ id: 'fundraising.campaign.addDrawer.field.startDate', defaultMessage: '' })}
              // size={size}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.startDate }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <span className="">
                <FormattedMessage id='fundraising.campaign.addDrawer.field.endDate' defaultMessage='' />
              </span><span className="required">*</span>
            </Col>
            <Col span={12}>
              <label>
                <FormattedMessage id='fundraising.campaign.addDrawer.field.status' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <DatePicker
                allowClear={false}
                format={format}
                key='endDate'
                defaultValue={getMomentDateForUI({ date: endDate, format })}
                onChange={(selectedDate) => {
                  props.updateState({
                    endDate: selectedDate
                  })
                  // this.props.getNextTxNumber({ date: selectedDate, relationshipId: (this.props.companyInfo || {}).relationshipId });
                }}
                placeholder={props.intl.formatMessage({ id: 'fundraising.campaign.addDrawer.field.endDate', defaultMessage: '' })}
              //size={size}
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.endDate }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
            <Col span={12}>
              <Dropdown
                //className={(props.submittedOnce && !props.status) ? 'input-text-error' : ''}
                items={[{ val: 'active', displayName: 'Active' }, { val: 'inActive', displayName: 'Inactive' }]}
                valueKeyName='displayName'
                optionKeyName='val'
                value={isActive}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'fundraising.campaign.addDrawer.field.status',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      isActive: option.name
                    })
                  }
                }
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || props.status }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>
          </Row>
          {/* <Row gutter={16}>
            <Col span={6}>
              <label>
                <FormattedMessage id='fundraising.campaign.addDrawer.field.form' defaultMessage='' /></label>
              <span className="required">*</span>
            </Col>
            <Col span={12}>
              <Dropdown
                className={(props.submittedOnce && !props.registrationFormSettingId) ? 'input-text-error' : ''}
                items={registrationFormList}
                valueKeyName='programName'
                optionKeyName='registrationFormSettingId'
                value={registrationFormSettingId}
                placeholder={props.intl.formatMessage(
                  {
                    id: 'fundraising.campaign.addDrawer.field.form',
                    defaultMessage: ''
                  })
                }
                onSelect={
                  (optionValue, option) => {
                    props.updateState({
                      registrationFormSettingId: option.value
                    })
                  }
                }
              />
              <ErrorMsg
                validator={() => { return !props.submittedOnce || props.registrationFormSettingId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>

          </Row> *

          <br /> */}
          <Row gutter={[16, 4]}>
            <Col span={12}>
              <FormattedMessage id='project.label' /><span className="required">*</span>
            </Col>
            <Col span={12}>
              <FormattedMessage id='header.menuItem.ledgerAccount.label' /><span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={12} >
              <Dropdown
                items={props.projectList}
                valueKeyName='projectName'
                optionKeyName='projectMasterId'
                className={(props.submittedOnce && !props.projectMasterId) ? 'input-text-error' : ''}
                value={props.projectMasterId}
                placeholder={props.intl.formatMessage({ id: 'project.label' })}
                onSelect={(optionValue, option) => {
                  let selectedProject = find(props.projectList || [], { projectMasterId: option.value }) || {};
                  props.updateState({
                    selectedProject: selectedProject,
                    projectMasterId: option.value
                  })
                }}
                canAddNew={true}
                onAddNewClick={(payload) => {
                  payload.formData = {
                    projectName: payload.projectName,
                  }
                  handleNewProjectAddition(props, payload, MODAL_TYPE.PROJECT)
                }}
              />
               <ErrorMsg
                validator={() => { return !props.submittedOnce || props.projectMasterId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
            <Col span={12} >
              <Dropdown
                items={props.allLedgerAccounts}
                className={(props.submittedOnce && !props.chartOfAccountDetailsId) ? 'input-text-error' : ''}
                valueKeyName='ledgerAccountName'
                optionKeyName='chartOfAccountDetailsId'
                value={props.chartOfAccountDetailsId}
                placeholder={props.intl.formatMessage({ id: 'header.menuItem.ledgerAccount.label' })}
                onSelect={(optionValue, option) => {
                  let selectedLedger = find(props.allLedgerAccounts || [], { chartOfAccountDetailsId: option.value }) || {};
                  props.updateState({
                    selectedLedger: selectedLedger,
                    chartOfAccountDetailsId: option.value
                  });
                }}
              />
               <ErrorMsg
                validator={() => { return !props.submittedOnce || props.chartOfAccountDetailsId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } />
            </Col>
          </Row>
      <Row gutter={[16, 4]}>
            <Col span={12}>
              <FormattedMessage id='paymentGateway.available' /><span className="required">*</span>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12} >
              <Dropdown
                items={props.availablePaymentGateways}
                className={(props.submittedOnce && !props.alpidePaymentGatewayId) ? 'input-text-error' : ''}
                valueKeyName='userDefinedGatewayName'
                optionKeyName='alpidePaymentGatewayId'
                value={props.alpidePaymentGatewayId}
                placeholder={props.intl.formatMessage({ id: 'paymentGateway.available' })}
                onSelect={(optionValue, option) => {
                  let selectedGateway = find(props.availablePaymentGateways || [], { alpidePaymentGatewayId: option.value }) || {};
                  props.updateState({
                    selectedGateway: selectedGateway,
                    alpidePaymentGatewayId: option.value
                  });
                }}
              />
              {/* <ErrorMsg
                validator={() => { return !props.submittedOnce || props.alpidePaymentGatewayId }}
                message={props.intl.formatMessage(
                  {
                    id: 'requiredField.missing.msg',
                    defaultMessage: ''
                  })
                } /> */}
            </Col>
          </Row>
        </Form>

     
      </Drawer>
    </Fragment>
  );
}

export default injectIntl(FundraisingCampaignDrawer);
