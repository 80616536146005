import React from "react";
import { Drawer, Row, Col, DatePicker } from "antd";
import {
  getMomentDateForUI,
  getCurrentDateForBackend,
  showToasterMessage,
  fetchPaginationDataIfNeeded,
  getMomentDateForUIReadOnly,

} from "../../../utils";
import { CustomButton } from "../../general/CustomButton";
import { TextBox } from "../../general/TextBox";

import { CONSTANTS } from "../../../static/constants";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { Dropdown } from "../../general/Dropdown";
import moment, { isMoment } from "moment";


const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { RangePicker,MonthPicker } = DatePicker;

const disabledDate = (current) => {
  // Get the current date
  const now = new Date();
  
  // Calculate the start and end dates for the allowed range
  const start = new Date(now.getFullYear() - 7, now.getMonth(), 1); // Same month, 7 years ago
  const end = new Date(now.getFullYear(), now.getMonth() + 1, 1); // First day of the next month
  
  return current && (current < start || current >= end);
};

const EInvoiceFilterComp = (props) => {
  const {  companyInfo } = props;
  const closeDrawer = () => {
    props.updateState({
      salesEInvoiceFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
   
    if ((props.startDate && !props.endDate) || (!props.startDate && props.endDate)) {
        return showToasterMessage({ messageType: "error", description: props.intl.formatMessage({ id: 'invalid.dateRange.message' }) })
    }

    let filterPeriod = "";
    if(props.startDate && props?.endDate){
      // props.updateState({filterPeriod: "Date Range"})
      filterPeriod = "Date Range";
    }


    props.updateState({
      showTaxPeriod:  filterPeriod ? <span> <b>Date Range : </b> {getMomentDateForUIReadOnly({ date: props.startDate, format })}-{getMomentDateForUIReadOnly({ date: new Date(props.endDate),format})}
      </span> :
      (
        
       <span>Tax Period : {props.quarterPeriod}-{props?.quarterYear}</span>
      ) 

    })
    const payload = {
        companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize,
        filters: {
            
            startDate: props.startDate ? getCurrentDateForBackend(props.startDate) : null,
            endDate: props.endDate ? getCurrentDateForBackend(props.endDate) : null,
            monthly: props.selectedDate ? props.selectedDate : null,
            quarter: props?.quarterPeriod ? props?.quarterPeriod: null,
            quarterYear: props?.quarterYear ? props?.quarterYear : null,
            invoiceNumber:props.invoiceNumber,
           
        }
    };
    props.updateState({
      salesEInvoiceFilterDrawerVisible: false,
    });
    props.fetchGSTFilingDeatils(payload)
    fetchPaginationDataIfNeeded('fetchGSTFilingDeatils', 'eInvoiceListing', props, payload);
    

  };

  const clearFilter = () => {
    props.updateState({
    
      startDate: null,
      endDate:  null,
     
      invoiceNumber:null
    });
    const payload = {
        companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: 1, pageSize: props.pageSize,
        filters: {}
    };
    props.fetchGSTFilingDeatils(payload)
    fetchPaginationDataIfNeeded('fetchGSTFilingDeatils', 'eInvoiceListing', props, payload);
    

  };

  const rowGutter = [24, 16];
  return (
    <>
      <Drawer
        title={<div className="drawer-head-txt">Filters</div>}
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.salesEInvoiceFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
        <Col span={12}>
            <div className="i-label">Invoice Type</div>
           <TextBox

           onChange={(event)=>{
                     props.updateState({
                      invoiceNumber:event.target.value
                     })
           }}
           placeholder="Invoice #"/>
          </Col>
         
          
      
        
         
            
        <Col span={12}>
            <div className="i-label">Invoice Date</div>
            <RangePicker
                            allowEmpty={true}
                            disabledDate={disabledDate}
                            format={format}
                            placeholder={['Start date', 'End date']}
                            value={[props.startDate ? getMomentDateForUI({ date: new Date(props.startDate), format }) : null, props.endDate ? getMomentDateForUI({ date: new Date(props.endDate), format }) : null]}
                            onChange={(dates) => {
                                if (dates) {
                                    props.updateState({ startDate: dates[0], endDate: dates[1] });
                                } else {
                                    props.updateState({ startDate: null, endDate: null });
                                }

                               props.updateState({
                                quarterPeriod: null,
                                quarterYear: null,
                                selectedMonth: null,
                               })
                            }}
                        /> 
          </Col>
         
        </Row>

   <Row gutter={rowGutter}>

    

    <Col span={12}>
    <div className="i-label">Quarterly</div>
    
    
      <DatePicker 
        
        disabledDate={disabledDate}
        onChange={(dateString)=>{
        let quarter;
        let year;
        if(dateString){
           quarter = moment(dateString).quarter();
       year = moment(dateString).year();
        }
        props.updateState({
          quarterPeriod: `Q${quarter}`,
          quarterYear: year,

        })
        // props.updateState({
      
        //   selectedMonth: getMomentDateForUI({date: dateString , format: "MMMM-YYYY"}),

        // })
          
      }} picker="quarter" />
    
      
    </Col>
   </Row>

      
      </Drawer>
    </>
  );
};

export default EInvoiceFilterComp;
