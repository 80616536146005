import React, { Fragment, Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CustomButton } from '../../general/CustomButton';
import { Input } from 'antd';

class CardConnectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };


    return (
      <Fragment>
        <Form  {...formItemLayout} onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <div className="modal-content employee-modal-control">

          <Form.Item label={(<span><FormattedMessage id='merchantId.text' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('merchantId', {
                rules: [
                  {
                    required: true,
                    message: 'Merchant Id is required.',
                    initialValue: this.props.merchantId || ''
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'merchantId.text', defaultMessage: '' })} maxLength={150}
                onChange={e => {
                  this.setState({ merchantId: e.target.value });
                }}
              />)}
            </Form.Item>

            <Form.Item label={(<span><FormattedMessage id='cardConnect.userName.text' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: 'User Name is required.',
                    initialValue: this.props.username || ''
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'cardConnect.userName.text', defaultMessage: '' })} maxLength={150}
                onChange={e => {
                  this.setState({ username: e.target.value });
                }}
              />)}
            </Form.Item>

            <Form.Item label={(<span><FormattedMessage id='cardConnect.password.text' defaultMessage='' /></span>)} colon={false}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Password is required.',
                    initialValue: this.props.password || '',
                  }
                ],
              })(<Input placeholder={this.props.intl.formatMessage({ id: 'cardConnect.password.text', defaultMessage: '' })} maxLength={150}
                onChange={e => {
                  this.setState({ password: e.target.value });
                }} />)}
            </Form.Item>
            <div className='mb10' style={{ textAlign: 'right' }}>
              <a rel="noopener noreferrer" href="https://support.cardpointe.com/cardpointe/credential-generator?utm_campaign=Agent%20Communication&utm_medium=email&_hsmi=142798555&_hsenc=p2ANqtz-_ATrh2t79qbr39Jrge5qoDLRRTGNJ62QiBJzE_u0M9FCNK6Ejuv8fbqVpNgfWdAuZ3n57rve1VIr4KMxyZFUNsEdKOtg&utm_content=142798555&utm_source=hs_email" target="_blank" >How to get credentials?</a>
            </div>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className='customModal-button-group'>
              <CustomButton
                intlId={'modal.contact.button.cancel'}
                defaultMessage={''}
                type={'default'}
                key={1}
                onClick={() => { this.props.popModalFromStack(); }}
              />
              <CustomButton
                intlId={'button.save.label'}
                defaultMessage={''}
                htmlType='submit'
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment >
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const payload = {
          ...this.props.data,
          username: values.username,
          password: values.password,
          merchantId: values.merchantId,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          paymentGatewayName: 'CardPointe'
        }

        const modalData = {
          modalBody: <FormattedMessage id='save.confirmation' defaultMessage='' />,
          handleSubmit: () => {
            this.props.saveCardPointConfiguration(payload, (this.props.data || {}).formData);
            this.props.handleSubmit && this.props.handleSubmit();
            this.props.popModalFromStack();
          }
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: 'Card_Connect_Component' })(injectIntl(CardConnectComponent));
