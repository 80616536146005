import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreatePaySlip from '../../../../../components/modal/modalBody/company/CreatePaySlip';


class CreatePaySlipCon extends Component {

    constructor(props){
        super(props);
        this.state= {
         
        }
    }

    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
      
    }

    render() {
        return <div>

            <CreatePaySlip {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
      
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({

  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreatePaySlipCon);