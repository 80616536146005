import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SupplierResponseComponent from '../../../../../components/modal/modalBody/supplier/SupplierResponse';
import { fetchSupplierRFQDetail, approveRFQ, uploadStamp, deleteStamp } from './action';
//import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';
import { fetchAllRFQ, getRFQCount, updateRfqStatus } from '../../../../supplier/rfq/Listing/action';
import { fetchAllRFQStatus, addRFQStatus, deleteRFQStatus } from '../AddUpdateBiddingSupplier/action';
import AuditTimeLine from '../../../../../containers/drawer/AuditTimeLine';

class SupplierResponse extends Component {
    constructor(props){
        super();
        let supplierResponse = JSON.parse(props.biddingSupplierData?.supplierResponse || '{}');
        this.state= {
            rfqData: {...props.rfqData, rfqDetailsList: supplierResponse.rfqDetailsList},
            emailDrawerVisible: false,
            supplierResponse,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        // const companyInfo = this.props.companyInfo || {};
        // const payload = { relationshipId: companyInfo.relationshipId, supplierId: this.props.rfqPayload.supplierId, rfqMasterId: this.props.rfqPayload.rfqMasterId };
        // fetchDataIfNeeded('fetchSupplierRFQDetail', 'rfqData', this.props, payload, true);

    }

    render() {
        return <div>
            {this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <SupplierResponseComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        userInfo: state.common.userInfo,
        permissions: state.auth.permissions,
        rfqStatusList: state.rfq.rfqStatusList,
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSupplierRFQDetail,
    downloadFileFromBucket,
    generatePDF, printPDF,
    approveRFQ,
    fetchAllRFQ,
    getRFQCount, 
    uploadStamp, 
    deleteStamp,
    updateRfqStatus,
    fetchAllRFQStatus, 
    addRFQStatus, 
    deleteRFQStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SupplierResponse);