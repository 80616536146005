import React from 'react';
import HeaderAction from './HeaderAction'
import './../../../../assets/stylesheets/common/invoice.scss';
import PurchaseOrderDetails from "./PurchaseOrderDetails";
import ProductDetails from "./ProductDetails";
// import { FormattedMessage } from 'react-intl';
import { Divider } from 'antd';
const PurchaseOrderComponent = (props) => {

  // const breadCrumbList = [
  //   {
  //     name:<FormattedMessage id='sidebar.menuItem.purchase' defaultMessage='' /> ,
  //   }, {
  //     name: <FormattedMessage id='sidebar.menuItem.purchaseOrder' defaultMessage='' />
  //   }
  // ];

  return (
    <>
     <div className="txn-header">
            <HeaderAction {...props} />
        </div>

       <div className='txn-body'>

        <div className="txn-details-container">
        <PurchaseOrderDetails {...props} />
        </div>

        <Divider style={{margin: '0 0 10px 0px'}}/>

        <div className="product-details-container">
          <ProductDetails {...props} />
        </div>
       </div>
    
    {/* <div className="new-invoice-modal">
      <div className="invoice-header">
        <PageBreadcrumb breadCrumbList={breadCrumbList} />
        <HeaderAction {...props} />
      </div>

      <div className="invoice-details-container">
        
      </div>

      <div className="invoice-details-container">
        <ProductDetails {...props} />
      </div>
    </div> */}
    </>
  );
}


export default PurchaseOrderComponent;