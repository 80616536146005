import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PurchaseInvoicePaymentsListComp from '../../../../../components/modal/modalBody/supplier/PurchaseInvoicePaymentsList';
import { fetchPurchasedInvoiceDetail } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { fetchPurchaseInvoices, getPurchaseInvoicesCount } from '../../../../supplier/invoice/Listing/action';

class PurchaseInvoicePaymentList extends Component {
    constructor(props){
        super(props);
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, supplierId: this.props.invoicePayload.supplierId, invoiceMasterId: this.props.invoicePayload.invoiceMasterId };
        fetchDataIfNeeded('fetchPurchasedInvoiceDetail', 'invoiceData', this.props, payload, true);
    }

    render() {
        return <div>
            <PurchaseInvoicePaymentsListComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        invoiceData: state.purchaseInvoice.purchaseInvoiceDetail,
        userInfo: state.common.userInfo,
        availableDebits: state.purchaseInvoice.availableDebits,
        permissions: state.auth.permissions
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchPurchaseInvoices,
    fetchPurchasedInvoiceDetail,
    getPurchaseInvoicesCount,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoicePaymentList);