import React, { useState } from "react";
import { Upload, message, List } from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";

const FileAttachment = () => {
  const [fileList, setFileList] = useState([]);

  const handleFileUpload = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setFileList([info.file]);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleFileRemove = (file) => {
    setFileList([]);
    message.success(`${file.name} file removed.`);
  };

  return (
    <div>
      <Upload.Dragger
        name="file"
        multiple={false}
        action="/upload" // Replace with your API endpoint for file upload
        fileList={fileList}
        beforeUpload={(file) => {
          // You can add validation logic here if needed
          return true;
        }}
        onChange={handleFileUpload}
        onRemove={() => handleFileRemove(fileList[0])}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single file upload.</p>
      </Upload.Dragger>

      {fileList.length > 0 && (
        <div>
          <div className="i-label">Attached Files</div>
          <List
            size="small"
            bordered
            dataSource={fileList}
            renderItem={(file) => (
              <List.Item>
                {file.name}{" "}
                <DeleteOutlined
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => handleFileRemove(file)}
                />
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default FileAttachment;