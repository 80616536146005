import { Button, Col, Menu, Row } from "antd";
import React, { useEffect } from "react";
import "../../../../eStore/storeSettings/store-settings.scss";
import { TextBox } from "../../../../general/TextBox";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import { FormattedMessage } from "react-intl";
import { exportExcel, fixedDecimalAndFormateNumber, getMomentDateForUIReadOnly, showToasterMessage } from "../../../../../utils";
import DropdownAction from "antd/lib/dropdown";
import {
  FilterOutlined,
  
  DownOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  MailOutlined,
  FileExcelOutlined,

} from "@ant-design/icons";
import { CONSTANTS } from "../../../../../static/constants";

const ErrorComp = (props)=>{
 const  {warnings ,errors} = props;
  return(
    <div className="mt20">
      <div>
          Errors: 
          {
            ( (errors||[]).length) ? (<div>

              {
                (errors||[])?.map((item,i)=>{
                  return(
                    <div className="error">
                      {item?.defaultMessage} {`of invoice ${i}`}
                    </div>
                  )
                }) 
              }
            </div>) :<div className="success">There is no Error in the Invoices!!</div>
          }
      </div>
      <div className="mt20">
        Warnings:
        {
          ( (warnings||[])?.length) ? (<div>

            {
              (warnings||[])?.map((item,i)=>{
                return(
                  <div className="error">
                    {item?.description}
                  </div>
                )
              }) 
            }
          </div>):<div className="success">There is no Warnings in the invoices</div>
        }

      </div>
      </div>
  )
  
}



const GstrFillingComp = (props) => {

  // useEffect(() => {
  //   if ((props.uploadResponse || []).length) {
  //      return <ErrorComp data = {props.uploadResponse||[]}/>
  //   }
  // }, [props.uploadResponse]);
   const format = "DD-MM-YYYY"
    const HandleSave =()=>{
      
          props.uploadGSTR1FilingDetails(props)
       
      
        
    }
     const exportExcelData = (selectedInvoiceList, props) => {
    const dataList = (selectedInvoiceList || []).map((item) => {
      return {
        "Invoice #": item.invoiceNumber || "",
        "Invoice Date": getMomentDateForUIReadOnly({
          date: new Date(item.invoiceDate),
          format,
        }),
        "Invoice Due Date": getMomentDateForUIReadOnly({
          date: new Date(item.invoiceDueDate),
          format,
        }),
        "Customer Name": item.customerName || "",
        "Invoice Amount": Number(item.invoiceTotalAmount || 0).toFixed(2),
        "Paid Amount": Number(item.totalPaymentReceived || 0).toFixed(2),
        "Due Amount": Number(
          item.invoiceTotalAmount -
            (item.totalPaymentReceived + item.creditApplied)
        ).toFixed(2),
        "Credit Applied": Number(item.creditApplied).toFixed(2),
        "System Status": item.status,
        "Payment Term": item.paymentTermName || "",
        "Project Name": item.projectName || "",
        "Past Due":
          item.status === "void" || item.status === "paid"
            ? "-"
            : item.dueDays < 0
            ? Math.abs(item.dueDays || 0) + " Days"
            : "Not yet due",
      };
    });
    exportExcel(dataList, "e-Invoice List");
  };
  const downloadMenu = () => {
    const invoiceList = [];
    // props.gridApi.forEachNode(obj => {
    //     if (obj.data.isChecked) {
    //         invoiceList.push(obj.data);
    //     }
    // });
    (props.selectedItems||[]).forEach((obj) => {
      invoiceList.push(obj);
    });

    if ((invoiceList||[])?.length) {
      exportExcelData(invoiceList);
    } else {
      showToasterMessage({
        messageType: "error",
        description: props.intl.formatMessage({
          id: "common.record.notSelected.proceeding",
        }),
      });
    }
  };
  return (
    <div className="" style={{padding: "0px"}}>
    <div>
    <div>
    {/* <Row className=''>
          <Col span={21}>
            <Button onClick={()=>downloadMenu()}><FileExcelOutlined />
           Download as Excel File</Button>
          
            </Col>
              
        </Row> */}
    </div>
    <div>
    <div className="table-container mt20">
          <table id="invoiceTable">
            <thead className="balance-sheet-table" style={{backgroundColor:"#f3f3f3"}}>
              <tr>
               
               

                
                  <th >
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceNo"
                      defaultMessage=""
                    />{" "}
                   
                  </th>
              
              
                  <th >
                    <FormattedMessage id="IRN #" defaultMessage="" />{" "}

                  </th>
                  <th >
                    <FormattedMessage id="Invoice Type" defaultMessage="" />{" "}
                    
                  </th>
                  {/* <th >
                    <FormattedMessage id="Supplier Gstin" defaultMessage="" />{" "}
                    
                  </th> */}
                  <th >
                    <FormattedMessage id="Buyer Gstin" defaultMessage="" />{" "}
                    
                  </th>
                  <th >
                    <FormattedMessage id="e-Way Bill #" defaultMessage="" />{" "}
                    
                  </th>
               

               
                  <th >
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.customer"
                      defaultMessage=""
                    />{" "}
                  
                  </th>
               

              
                  <th className="text-end">
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceAmount"
                      defaultMessage=""
                    />{" "}
                  
                  </th>
               
                  <th className="text-end">
                <FormattedMessage
                      id="IGST"
                      defaultMessage=""
                    />{" "}
                </th>
                <th className="text-end">
                <FormattedMessage
                      id="CGST"
                      defaultMessage=""
                    />{" "}
                </th>
                <th className="text-end">
                <FormattedMessage
                      id="SGST"
                      defaultMessage=""
                    />{" "}
                </th>
               
                  <th className="text-end">
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.dueAmount"
                      defaultMessage=""
                    />{" "}
                   
                  </th>
               

              
                  {/* <th >
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.paymentTerm"
                      defaultMessage=""
                    />{" "}
                 
                  </th> */}
               

               
                  <th>
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceDate"
                      defaultMessage=""
                    />{" "}
                   
                  </th>
              

              
                  {/* <th >
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.invoiceDueDate"
                      defaultMessage=""
                    />{" "}
                  
                  </th>
                

               
                  <th >
                    <FormattedMessage
                      id="customer.salesInvoice.listing.header.systemStatus"
                      defaultMessage=""
                    />{" "}
                 
                  </th> */}
               
              </tr>
            </thead>
            <tbody>
            
                {(props.selectedItems||[]).length ? (
                  (props.selectedItems || []).map((item, i) => {
                    return (
                      <tr key={i}>
                      
                       

                      
                          <td>
                            <div
                              className=" one-line-view"
                              onClick={() => {
                                // openSalesInvoiceDetails(item);
                              }}
                            >
                              {item.invoiceNumber}
                            </div>
                          </td>
                       

                      
                          <td>
                            <div className="one-line-view">{item.irn||<div className="empty-data-box"/>}</div>
                          </td>
                          <td>
                            <div className="one-line-view">{item.eInvoiceType||<div className="empty-data-box"/>}</div>
                          </td> 
                          {/* <td>
                            <div className="one-line-view">{item.supplierGstin||<div className="empty-data-box"/>}</div>
                          </td> */}
                          <td>
                            <div className="one-line-view">{item.buyerGstin||<div className="empty-data-box"/>}</div>
                          </td>

                       
                          <td>
                            <div>{item.ewayBillNo||<div className="empty-data-box"/>}</div>
                          </td>
                       
                      
                          <td>
                            <div className="one-line-view" >{item.customerName||<div className="empty-data-box"/>}</div>
                          </td>
                       
                      
                          
                          <td className="text-end">
                              <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                  item.invoiceTotalAmount
                                )}{" "}
                              </span>
                           
                          </td>
                       
                        <td className="text-end">
                        <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                 item?.igst||0
                                )}{" "}
                              </span>
                         
                        </td>
                        <td className="text-end">
                        <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                 item?.cgst||0
                                )}{" "}
                              </span>
                        </td>
                        <td className="text-end">
                        <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                 item?.sgst||0
                                )}{" "}
                              </span>
                        </td>
                        
                          <td className="text-end">
                            <span className="one-line-view">
                                {" "}
                                {item.foreignCurrencyIcon ? (
                                  <i className={item.foreignCurrencyIcon}></i>
                                ) : item.foreignCurrency ? (
                                  <span>{item.foreignCurrency}</span>
                                ) : props.companyInfo.currencyIcon ? (
                                  <i
                                    className={props.companyInfo.currencyIcon}
                                  ></i>
                                ) : (
                                  props.companyInfo.currencyCode + " "
                                )}
                                {fixedDecimalAndFormateNumber(
                                 item?.invoiceDueAmount||0
                                )}{" "}
                              </span>
                           
                          </td>
                       
                      
                    
                          {/* <td>
                            <div>{item.paymentTermName||<div className="empty-data-box"/>}</div>
                          </td> */}
                      
                      
                          <td>
                            <div>{item.invoiceDate? (  getMomentDateForUIReadOnly({
                                    date: item.invoiceDate,
                                    format: CONSTANTS.TABLE_DATE_FORMAT,
                                  })):""}</div>
                          </td>
                       
                     
                          {/* <td>
                            <div>{item.invoiceDueDate? (  getMomentDateForUIReadOnly({
                                    date: item.invoiceDueDate,
                                    format: CONSTANTS.TABLE_DATE_FORMAT,
                                  })):""}</div>
                          </td>
                        
                       
                          <td>
                            <div>{item.status||<div className="empty-data-box"/>}</div>
                          </td> */}
                       
                      </tr>
                    );
                  })
                ) : (
                  <tr>No Data</tr>
                )}
            
            </tbody>
          </table>
        </div>
    </div>

    <div>
{
  Object.keys(props.uploadResponse||{})?.length ? <ErrorComp warnings={props.uploadResponse?.warningList} errors={props.uploadResponse?.errorList}/>:""
}
      
    </div>
      {/* <div>
      <Row gutter={[16,16]}>
          

          <Col span={12} >
            <div className="i-label">My Gstin <span className="required">*</span></div>
            <TextBox
              placeholder="Enter GSTIN Number"
              type="text"
              maxLength={15}
              value={props.supplierGstin}
              onChange={(e) => {
                props.updateState({ supplierGstin: e.target.value });
              }}
            />
            <ErrorMsg
                validator={() => {
                  return  !props.submittedOnce||!!props.supplierGstin || props.supplierGstin === 15;
                }}
                message={"This is a required Field"}
              />
          </Col>

          <Col span={12}>
            <div className="i-label">Counter Party Gstin <span className="required">*</span></div>
            <TextBox
              placeholder="Enter GSTIN Number"
              type="text"
              maxLength={15}
              value={props.buyerGstin}
              onChange={(e) => {
                props.updateState({ buyerGstin: e.target.value });
              }}
            />
             <ErrorMsg
                validator={() => {
                  return  !props.submittedOnce||!!props.buyerGstin|| props.buyerGstin === 15;
                }}
                message={"This is a required Field"}
              />
          </Col>
        </Row>
       
        
      </div> */}
     
      <Row className='modal-footer mt20'>
          <Col span={22}>
            
            <Button
             
              key={1}
              onClick={()=>{
                props.popModalFromStack()
                }}
            >
              Cancel
              </Button>
            </Col>
              <Col span={2}>
              <Button type="primary" onClick={()=>HandleSave()}>Validate</Button>
          </Col>
        </Row>
     
      </div>
     
    </div>
  );
};

export default GstrFillingComp;
