import React from "react";
import { Tabs, Row, Col, } from 'antd';
import { FormattedMessage } from 'react-intl';
import SelectTemplate from "../EmailCampaign/SelectTemplate";
import CampaignDetails from "../EmailCampaign/CampaignDetails";

const { TabPane } = Tabs;

function EmailCampaign(props){
    return <>
            <div className="emailCampaignsDetails">
                <div className="view-top-bar">
                    <div className="top-view-left">
                        <div className="page-heading">
                            <FormattedMessage id='emailMarketing.emailCampaign.heading' defaultMessage='' />
                        </div>
                        
                    </div>
                    <div className="top-view-right">
                        
                    </div>
                </div>
                <div className="view-container">
                    <Tabs onChange={(e)=>{
                        props.updateState({activeTab:e,isDisable:e === "1"})
                    }} type="line" centered activeKey={props.activeTab}>
                        <TabPane tab="Select Template" key="1">
                            <SelectTemplate {...props} />
                        </TabPane>
                        <TabPane disabled={props.isDisable} tab="Campaign Details" key="2">
                            <CampaignDetails {...props} />
                        </TabPane>
                    </Tabs>
                </div>

            </div>
           
        </>
}

export default  EmailCampaign;