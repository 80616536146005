import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DonationComponent from "../../../components/B2B/Donation";
import { fetchDonationSetting, fetchRelationshipDataByRID, fetchRelationshipByDomainName, getAuthNetPaymentUrl } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchCountries, fetchStates, fetchPaymentTerms } from '../../../actions/commonActions';
import queryString from 'query-string';
import * as find from 'lodash.find';
import * as filter from 'lodash.filter';
import { CONSTANTS } from '../../../static/constants';

class Register extends Component {
    constructor(props) {
        super(props);
        const linkProps = queryString.parse(this.props.location.search) || {};
        this.state = {
            formSetting: {},
            status: linkProps.status,
            relationshipId: linkProps.relationshipId,
            relationshipBillingAddress: {}
        }
    }
    componentDidMount() {
        const linkProps = queryString.parse(this.props.location.search) || {};
        this.props.fetchRelationshipByDomainName({ domainName: window.location.host, registrationFormSettingId: linkProps.formId }, this.props);
    }

    componentWillReceiveProps(props) {
        if (props.donationSetting && props.donationSetting.settingsDonationId) {
            let donationContent = JSON.parse(props.donationSetting.donationContent);
            this.setState({
                relationshipId: props.donationSetting.relationshipId,
                settingsDonationId: props.donationSetting.settingsDonationId,
                donationSettings: donationContent,
                formHeading: donationContent.formHeading
            })
        }
        if (props.companyInfo && props.companyInfo.relationshipId) {
            const allRelationshipBillingAddress = filter((props.companyInfo || {}).boLocationList || [], { locationType: CONSTANTS.RELATIONSHIP_BILLING_ADDRESS }) || [];
            const relationshipBillingAddress = find(allRelationshipBillingAddress, { isDefault: 1 });
            this.setState({
                relationshipBillingAddress,
            })
        }
    }
    render() {
        window.document.title= this.props?.companyInfo?.storeName || '';
        return <div>
            {Object.keys(this.props.donationSetting).length ? <DonationComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} /> : <div></div>}
        </div>
    }
}
const mapStateToProps = (state) => {
    return {
        countries: state.common.countries,
        states: state.common.states,
        companyInfo: state.settings.relationshipData,
        donationSetting: state.donation.donationSetting,
    }
}
const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    fetchCountries,
    fetchStates,
    fetchPaymentTerms,
    fetchRelationshipDataByRID,
    fetchRelationshipByDomainName,
    fetchDonationSetting,
    getAuthNetPaymentUrl
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Register);
