import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";

import { Modal } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { ICONS, MODAL_ACTIONS, MODAL_TYPE } from "../../static/constants";
import { CustomButton } from "../general/CustomButton";
import {
  AddItemBody,
  AddPaymentTermBody,
  AddDateBody,
  AddPhoneNumberBody,
} from "./../modal/modalBody/customerInfo";
import WarehouseBody from "../../containers/modal/modalBody/common/Warehouse";
import ProjectBody from "../../containers/modal/modalBody/common/Project";
import AddressBody from "../../containers/modal/modalBody/common/Address";
import EmployeeBody from "../../containers/modal/modalBody/common/Employee";
import ContactBody from "../../containers/modal/modalBody/common/Contact";
import PriceListBody from "../../containers/modal/modalBody/common/PriceList";
import NewTaxBody from "../../containers/modal/modalBody/common/NewTax";
import RejectApprovalBody from "../../containers/modal/modalBody/common/RejectApproval";
import DelegateApproverBody from "../../containers/modal/modalBody/common/DelegateApprover";
import TaxIdentificationBody from "../../containers/modal/modalBody/common/TaxIdentification";
import UserStatusBody from "../../containers/modal/modalBody/common/UserStatus";
import SelectVariantBody from "./modalBody/common/SelectVariant";
import AddCountry from "../../containers/modal/modalBody/settings/taxes/AddCountry";
import AddMarketplaceStateTax from "../../containers/modal/modalBody/settings/taxes/AddMarketplaceState";
import AddSubAccount from "../../containers/modal/modalBody/communication/AddSubAccount";
import ExpenseBody from "../../containers/modal/modalBody/common/Expense";
import CreateCategoryBody from "../../containers/modal/modalBody/product/CreateCategory";
import { NewRegistrationStatus } from "../../components/modal/modalBody/common/NewRegistrationStatus";
import SchoolSessionBody from "../../containers/modal/modalBody/common/SchoolSession";
import ParentInfoBody from "../../containers/modal/modalBody/common/ParentInfoUpdate";
import SystemStatusBody from "../../containers/modal/modalBody/common/SystemStatus";
import ContactParentBody from "../../containers/modal/modalBody/common/ContactParent";
import CreateUserStatusBody from "./modalBody/common/CreateUserStatus";
import NewHsnBody from "../../containers/modal/modalBody/common/NewHsn";
import FinancialYearBody from "../../containers/modal/modalBody/common/FinancialYear";
import "./modal.scss";

function CustomModal(props) {
  const cancelBtnProps = (props.footer || {}).cancel || {};
  const submitBtnProps = (props.footer || {}).submit || {};
  const {formData:dataValue} = props
  const [formData, updateFormData] = useState(dataValue);
 

  const dispatch = useDispatch();
  const bodyHtml = getBody(props, dataValue||formData, updateFormData);
  
  const defaultTitle = (
    <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
  );

  return (
    <Fragment>
      {props.visible ? (
        <Modal
          // title={props.title || defaultTitle}
          title={props.hideTitle ? null : props.title || defaultTitle}
          visible={props.visible}
          closable={props.isClosable}
          centered={props.centered}
          maskClosable={props.maskClosable === true ? false : true}
          width={props.width}
        
          keyboard={props.keyboard}
          wrapClassName={props.wrapClassName} //custom-modal-content, pass this classname for fixed height modal
          onCancel={ props.onCancel ?  props.onCancel : () => { dispatch({ type: MODAL_ACTIONS.HIDE_MODAL }) }}
          footer={
            props.hideFooter
              ? null
              : [
                  <>
                    <CustomButton
                      intlId={cancelBtnProps.intlId || "confirmation.cancel"}
                      defaultMessage={cancelBtnProps.defaultMessage || ""}
                      type={cancelBtnProps.type || "default"}
                      key={1}
                      onClick={() => {
                        updateFormData({
                          ...formData,
                          textBoxValue: "",
                          submittedOnce: false,
                        });
                        props.isStack
                          ? props.onCancel((props.modalData || {}).modalType)
                          : dispatch({ type: MODAL_ACTIONS.HIDE_MODAL });
                      }}
                    />
                    <CustomButton
                      intlId={submitBtnProps.intlId || "confirmation.submit"}
                      defaultMessage={submitBtnProps.defaultMessage || ""}
                      htmlType="submit"
                      key={2}
                      onClick={() => {
                        document.getElementsByClassName("add-new-input-text")
                          .length &&
                          document
                            .getElementsByClassName("add-new-input-text")[0]
                            .classList.add("input-text-error");
                        if (
                          props.formData &&
                          Object.keys(props.formData).length
                        ) {
                          props.handleSubmit({ ...props.formData });
                          updateFormData({
                            ...formData,
                            textBoxValue: "",
                            submittedOnce: true,
                          });
                        } else {
                          updateFormData({
                            ...formData,
                            textBoxValue: "",
                            submittedOnce: true,
                          });
                          props.handleSubmit();
                          dispatch({ type: MODAL_ACTIONS.HIDE_MODAL });
                        }
                      }}
                    />
                  </>,
                ]
          }
        >
          {bodyHtml}
        </Modal>
      ) : (
        ""
      )}
    </Fragment>
  );
}

const getBody = (props, formData, updateFormData) => {
  const { modalBody, modalData = {}, handleSubmit } = props;

  let body = <Fragment />;
  if (modalBody) {
    body = modalBody;
  } else {
    const { modalType } = modalData;
    switch (modalType) {
      case MODAL_TYPE.BUSINESS_TYPE:
      case MODAL_TYPE.CUSTOMER_TYPE:
      case MODAL_TYPE.CUSTOMER_SOURCE:
      case MODAL_TYPE.INDUSTRY:
      case MODAL_TYPE.SUPPLIER_SOURCE:
      case MODAL_TYPE.SUPPLIER_TYPE:
      case MODAL_TYPE.SO_SOURCE:
      case MODAL_TYPE.UOM:
      case MODAL_TYPE.PRODUCT_BRAND:
      case MODAL_TYPE.PRODUCT_MANUFACTURER:
      case MODAL_TYPE.DEPARTMENT:
      case MODAL_TYPE.DOCUMENT_NAME:
      case MODAL_TYPE.ATTRIBUTE:
      case MODAL_TYPE.RFQ_PRICE_CODES:
      case MODAL_TYPE.RFQ_STATUS:
      case MODAL_TYPE.SALUTATION:
      case MODAL_TYPE.ACTIVITY_PRIORITY:
      case MODAL_TYPE.ACTIVITY_STATUS:
      case MODAL_TYPE.UPDATE_STATUS:
      case MODAL_TYPE.ACTIVITY_TYPE:
      case MODAL_TYPE.CARRIER_NAME:
      case MODAL_TYPE.FREIGHT_TYPE:
      case MODAL_TYPE.SHIPPING_INSTRUCTION:
      case MODAL_TYPE.JOB_TITLE:
      case MODAL_TYPE.TAX_IDENTIFICATION:
      case MODAL_TYPE.QUALITY_CHECK:
      case MODAL_TYPE.INCOTERM:
      case MODAL_TYPE.SALES_COMPLAINT_TYPE:
      case MODAL_TYPE.CUSTOM_NAME:
      case MODAL_TYPE.COURSE_TERM:
      case MODAL_TYPE.JOB_ROLE:
      case MODAL_TYPE.SUBCOURSE:
      case MODAL_TYPE.CLASS_SCHEDULE:
      case MODAL_TYPE.EMPLOYEE_TYPE:
      case MODAL_TYPE.FOOTER:
      case MODAL_TYPE.CRM_STATUS:
      case MODAL_TYPE.ASSIGNMENTS_GROUP: 
      case MODAL_TYPE.CRM_CAMPAIGN_TYPE:
      case MODAL_TYPE.CRM_CAMPAIGN_STATUS:
      case MODAL_TYPE.RETURN_REASON:
      case MODAL_TYPE.ASSIGNMENTS_GROUP: {
        body = (
          <AddItemBody
            {...modalData}
            {...props}
            formData={formData}
            updateFormData={updateFormData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.SUBACCOUNT_PHONE_NUMBER: {
        body = (
          <AddPhoneNumberBody
            {...modalData}
            {...props}
            formData={formData}
            updateFormData={updateFormData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.ACTUAL_DELIVERY_DATE: {
        body = (
          <AddDateBody
            {...modalData}
            {...props}
            formData={formData}
            updateFormData={updateFormData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.PAYMENT_TERM: {
        body = (
          <AddPaymentTermBody
            {...props}
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.WAREHOUSE: {
        body = (
          <WarehouseBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.PROJECT: {
        body = (
          <ProjectBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.BILLING_ADDRESS:
      case MODAL_TYPE.SHIPPING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_BILLING_ADDRESS:
      case MODAL_TYPE.RELATIONSHIP_SHIPPING_ADDRESS: {
        body = (
          <AddressBody
            {...modalData}
            formData={formData}          
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.EMPLOYEE: {
        body = (
          <EmployeeBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.CONTACT: {
        body = (
          <ContactBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.CONTACT_PARENT: {
        body = (
          <ContactParentBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.PRICE_LIST: {
        body = (
          <PriceListBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.NEW_TAX: {
        body = (
          <NewTaxBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.REJECT_APPROVAL: {
        body = (
          <RejectApprovalBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.DELEGATE_APPROVER: {
        body = (
          <DelegateApproverBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.NEW_TAX_IDENTIFICATION: {
        body = (
          <TaxIdentificationBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.USER_STATUS: {
        body = (
          <UserStatusBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.SELECT_VARIANT: {
        body = (
          <SelectVariantBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.ADD_MARKETPLACE_COUNTRY: {
        body = (
          <AddCountry
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.ADD_MARKETPLACE_STATE_TAX: {
        body = (
          <AddMarketplaceStateTax
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.ADD_SUB_ACCOUNT: {
        body = (
          <AddSubAccount
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.EXPENSE_TYPE: {
        body = (
          <ExpenseBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }

      case MODAL_TYPE.PRODUCT_CATEGORY: {
        body = (
          <CreateCategoryBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }

      case MODAL_TYPE.NEW_STATUS: {
        body = (
          <NewRegistrationStatus
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.SCHOOL_SESSION: {
        body = (
          <SchoolSessionBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.PARENT_INFO: {
        body = (
          <ParentInfoBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.SYSTEM_STATUS: {
        body = (
          <SystemStatusBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.STATUS_NAME: {
        body = (
          <CreateUserStatusBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.NEW_HSN: {
        body = (
          <NewHsnBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      case MODAL_TYPE.FINANCIAL_YEAR: {
        body = (
          <FinancialYearBody
            {...modalData}
            formData={formData}
            handleSubmit={handleSubmit}
          />
        );
        break;
      }
      default:
        break;
    }
  }
  return body;
};

export default injectIntl(CustomModal);
