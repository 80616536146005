import axios from "axios";
import config from "../../../../config/environmentConfig";
import {
  COMMON_ACTIONS,
  PURCHASE_INVOICE_ACTION_LIST,
  PURCHASE_ORDER_ACTION_LIST,
  INBOUND_DELIVERY_ACTION_LIST,
} from "../../../../static/constants";
import {
  getCurrentDateForBackend,
  formatDateForBackend,
  showToasterMessage,
  getFinancialYear,
} from "../../../../utils";
import {
  LEDGER_TYPE,
  ACCOUNTING_ENTRY_TYPE,
  PURCHASE_INVOICE_TYPE,
  TX_TYPE,
  TX_STATUS,
} from "../../../../static/constants";
import {
  resetPaginatedData,
  getPurchaseInvoicesCount,
  resetDraftPaginatedData,
  getPurchaseInvoiceDraftCount,
} from "../Listing/action";
import * as filter from "lodash.filter";
import * as find from "lodash.find";
const { lang } = require("../../../../translations/" +
  (sessionStorage.getItem("language") || "en") +
  ".js");

export const createPurchaseInvoice = (payloadObject) => {
  const props = payloadObject.props;
  const invoiceData = _getInvoiceData(payloadObject);
  let formData = new FormData();
  if (props.fileList && props.fileList.length) {
    props.fileList.forEach((file) => {
      formData.append("files", file, file.name);
    });
  }
  formData.append("invoiceData", JSON.stringify(invoiceData, true));
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/createSupplierInvoice`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if(res.data){

          dispatch({
            type: PURCHASE_ORDER_ACTION_LIST.PURCHASE_ORDER_LIST_PAGE_RESET,
          });
          dispatch({
            type: INBOUND_DELIVERY_ACTION_LIST.INBOUND_DELIVERY_LIST_PAGE_RESET,
          });
          props.popModalFromStack()
          resetPaginatedData()(dispatch);
          getPurchaseInvoicesCount({
            relationshipId: props.companyInfo.relationshipId,
          })(dispatch);
          resetDraftPaginatedData()(dispatch);
          getPurchaseInvoiceDraftCount({
            relationshipId: props.companyInfo.relationshipId,
          })(dispatch);
          showToasterMessage({
            messageType: "success",
            description: "Purchase invoice created successfully",
          });
          props.history.replace({
            pathname: "/admin/purchase-invoice",
            state: {},
          });
          
          props.openInvoiceDetails(res.data);
  
          props.poMasterId
            ? props.history.push("/admin/purchase-order-list")
            : props.history.push("/admin/purchase-invoice-list");
        }
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({
          messageType: "error",
          description:
            lang[((err.response || {}).data || {}).message] ||
            "Some error occurred",
        });
      }).finally(()=>{
        dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
      })
  };

};
const _getTotalAmtForLedgerItems = (gridObject) => {
  if (!gridObject) {
    return 0;
  }
  let total = 0;
  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    let totalAmountOfColumn = 0;
    if (data.ledgerId && data.qty && data.rate) {
      totalAmountOfColumn = data.qty * data.rate;
      if (data.discount) {
        let discountAmount = data.discount * 0.01 * totalAmountOfColumn;
        totalAmountOfColumn = totalAmountOfColumn - discountAmount;
      }
      if (data.tax) {
        totalAmountOfColumn =
          data.tax * 0.01 * totalAmountOfColumn + totalAmountOfColumn;
      }
    }
    total = total + totalAmountOfColumn;
  });
  return total;
};
const _getPaymentData = (payloadData, props) => {
  console.log(props,payloadData,"ijfbvhfbvdhfvbdfhvbdfhvbdhbdhjdv");
  let payload = {};
  let supplierName = payloadData.supplierName;
  let supplierId = payloadData.supplierId;
  let createdByUserId = props.userInfo.userId || 0;
  let relationshipId = props.companyInfo.relationshipId || 0;
  let finYear = getFinancialYear(props.companyInfo);

  payload.paymentDate = formatDateForBackend(new Date());
  //payload.reference = formData.reference;
  payload.currencyCode =
    payloadData.currencyCode || props.companyInfo.currencyCode;
  payload.createdByEmpId =
    props.userInfo && props.userInfo.relationshipEmployeeId;
  payload.createdByUserId = createdByUserId;

  let txList = {
    fyStartDate:
      finYear &&
      finYear.fyStartDate &&
      getCurrentDateForBackend(finYear.fyStartDate),
    fyEndDate:
      finYear &&
      finYear.fyEndDate &&
      getCurrentDateForBackend(finYear.fyEndDate),
    relationshipId: relationshipId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    txType: "Payment",
    txDate: formatDateForBackend(new Date()),
    accountingEntry: "CREDIT",
    remarks: payloadData.remarks,
    amount: Number(payloadData.total),
    supplierId:  supplierId,
    supplierName: supplierName,
  };

  var txList1 = {
    yStartDate:
      finYear &&
      finYear.fyStartDate &&
      getCurrentDateForBackend(finYear.fyStartDate),
    fyEndDate:
      finYear &&
      finYear.fyEndDate &&
      getCurrentDateForBackend(finYear.fyEndDate),
    relationshipId: relationshipId,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    txType: "Payment",
    txDate: formatDateForBackend(new Date()),
    accountingEntry: "DEBIT",
    remarks: payloadData.remarks,
    amount: Number(payloadData.total),
    supplierId: supplierId,
    supplierName: supplierName,
  };

  var coaTxPaymentList = {
    relationshipId: relationshipId,
    txAmount: Number(payloadData.total),
    paymentModeDetail: payloadData.paymentModeDetail,
    fyStartDate:
      finYear &&
      finYear.fyStartDate &&
      getCurrentDateForBackend(finYear.fyStartDate),
    fyEndDate:
      finYear &&
      finYear.fyEndDate &&
      getCurrentDateForBackend(finYear.fyEndDate),
    status: "Processed",
    statusColor: " success",
    txDate: formatDateForBackend(new Date()),
    txType: "Payment",
    isMultiCurrency: payloadData.isMultiCurrency,
    exchangeRate: payloadData.exchangeRate,
    remarks: payloadData.remarks,
    invoiceDueDate: payloadData.invoiceDueDate,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: createdByUserId,
    supplierId:supplierId,
  };
  payload.supplierId = supplierId;
  payload.fyStartDate =
    finYear &&
    finYear.fyStartDate &&
    getCurrentDateForBackend(finYear.fyStartDate);
  payload.fyEndDate =
    finYear && finYear.fyEndDate && getCurrentDateForBackend(finYear.fyEndDate);
  payload.remarks = payloadData.remarks;
  payload.poNumber = payloadData.poNumber;

  payload.coaTxPaymentsList = [txList, txList1];
  payload.txDate = formatDateForBackend(new Date());
  payload.coaTxPaymentList = [coaTxPaymentList];

  payload.invoiceAmount = Number(payloadData.total);
  payload.relationshipId = relationshipId;
  payload.chartOfAccountDetailsId = props.chartOfAccountDetailsIdOfPayment || payloadData.chartOfAccountDetailsId;
  payload.txType = "Payment";
  payload.remarks = payloadData.remarks;
  payload.invoiceMasterId = payloadData.invoiceMasterId;
  payload.status = "Processed";
  payload.statusColor = " success";
  payload.invoiceNumber = payloadData.invoiceNumber;
  payload.paymentAmount = Number(payloadData.total);
  payload.isMultiCurrency = payloadData.isMultiCurrency;
  payload.currencyCode = payloadData.isMultiCurrency
    ? payloadData.foreignCurrency
    : props.companyInfo.currencyCode;
  payload.exchangeRate = payloadData.exchangeRate;
  payload.templateCode = payloadData.templateCode;
  payload.relationshipName = props.companyInfo.storeName;
  payload.projectNumber = props.projectNumber;
  payload.projectName = props.projectName;
  payload.projectMasterId = props.projectMasterId;
  payload.supplierName = supplierName;
  payload.rfqNumber = payloadData.rfqNumber;
  payload.rfqMasterId = payloadData.rfqMasterId;
  payload.quotationNumber = payloadData.quotationNumber;
  payload.transactionId = (props.bankTxData || {}).transactionId;
  payload.institutionName = (props.bankTxData || {}).institutionName;
  payload.paymentChannel = (props.bankTxData || {}).paymentChannel;
  payload.merchantName = (props.bankTxData || {}).merchantName;

  payload.paymentModeId= props.paymentModeId;
  payload.paymentModeName= props.paymentModeName;

  return [payload];
};
const _getInvoiceData = (payload) => {
  const props = payload.props;
  
  const state = payload.state;
  const gridObject = payload.gridObject;
  const isPlaceOfSupplySameAsRelationship =
    payload.isPlaceOfSupplySameAsRelationship;
  const companyInfo = props.companyInfo || {};
  const userInfo = props.userInfo || {};
  const financialYear = getFinancialYear(companyInfo);
  const oldPayload = props.update ? props.oldPayload || {} : {};
  const paymentTermObj =
    find(props.paymentTerms || [], {
      paymentTermId: Number(props.supplierPaymentTerm?.paymentTermId),
    }) || {};
  let invoiceMaster = {
    ...oldPayload,
    version: props.update ? props.version : 0,
    supplierInvoiceDraftId: props.supplierInvoiceDraftId || 0,
    invoiceMasterId: props.update ? props.invoiceMasterId : 0,
    invoiceNumber: props.update ? props.invoiceNumber : "",
    customerInquiryNumber: props.customerInquiryNumber,
    customerPONumber: props.customerPONumber,
    customerRFQNumber: props.customerRFQNumber,
    supplierPONumber: props.supplierPONumber,
    supplierId: props.supplier && props.supplier.id,
    fyStartDate:
      financialYear.fyStartDate &&
      getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate:
      financialYear.fyEndDate &&
      getCurrentDateForBackend(financialYear.fyEndDate),
    projectName: props.projectName,
    isItemDiscountAplicable:state.isItemDiscountAplicable,
    itemDiscountAmount:props.itemDiscountAmount,
    projectMasterId: props.projectMasterId,
    projectNumber: props.projectNumber,
    isCashInvoice: props.isCashPurchase ? 1 : 0,
    invoiceTotalAmount: state.total,
    invoiceType: PURCHASE_INVOICE_TYPE.STANDARD,
    supplierName: props.supplier && props.supplier.name,
    relationshipName: companyInfo && companyInfo.storeName,
    docDetailList: props.invoiceEditData?.docDetailList,
    supplierGstNumber: props.supplier && props.supplier.gstNumber,
    supplierPanNumber: props.supplier && props.supplier.panNumber,
    placeOfSupply:
      props.placeOfSupply || props.supplierBillingAddress?.stateName,
    status: props.isbankReco ? TX_STATUS.PAID : TX_STATUS.UNPAID,
    invoiceDate: props.invoiceDate && formatDateForBackend(props.invoiceDate),
    invoiceDueDate: props.invoiceDueDate
      ? formatDateForBackend(props.invoiceDueDate)
      : formatDateForBackend(new Date()),
    relationshipId: companyInfo.relationshipId,
    supplierInvoiceNumber: props.supplierInvoiceNumber,
    poMasterId: props.poMasterId,
    purchaseOrderId: props.purchaseOrderId,
    supplierPoNumber: props.supplierPo,
    referenceNumber: props.reference,
    remarksSupplier: props.supplierRemarks,
    remarksInternal: props.internalRemarks,
    paymentTermId: paymentTermObj.paymentTermId,
    paymentTermName: paymentTermObj.termName,
    paymentTermDays: paymentTermObj.numberOfDays,
    ////dateCreated: getCurrentDateForBackend(new Date()),
    createdByUserId: userInfo && userInfo.userId,
    createdByEmpId: userInfo && userInfo.relationshipEmployeeId,
    footer: props.footer,
    totalDiscountGiven: state.totalDiscount,
    txType: TX_TYPE.INVOICE,
    expenseId: props.expenseId,
    expenseName: props.expenseName,
    supplierInvoiceCOATxList: [],
    supplierInvoiceDetailsList: [],
    boLocationPurchaseInvoiceList: [],
    documentName: props.docName,
    rfqMasterId: props.rfqMasterId,
    rfqNumber: props.rfqNumber,
    inboundDeliveryNumber: props.inboundDeliveryNumber,
    inboundDeliveryMasterId: props.inboundDeliveryMasterId,
    isPOConversion: props.poMasterId ? 1 : 0,
    isIDConversion: props.isIDConversion && !props.isClone ? 1 : 0,
    currencyCode: companyInfo.currencyCode,
    conversionIDToPIRefList: props.update
      ? props.conversionIDToPIRefList || []
      : [],
    conversionRefList: props.update ? props.conversionRefList || [] : [],
    supplierPaymentsList: props.update ? props.supplierPaymentsList || [] : [],
    supplierQuoteNumber: props.supplierQuoteNumber,
    isbankReco: props.isbankReco,
    customerId: props.customerId,
  
    nameOfTransport: props.nameOfTransport,
    vehicleNumber: props.vehicleNumber,
    roadPermitNumber: props.roadPermitNumber,
    freightType: props.freightType,
    consignee: props.consignee,
    ewayBillNo: props.ewayBillNo,
    station: props.station,
    gstinNo: props.gstinNo,
    supplierPaymentsList:props.isCashPurchase ?[..._getPaymentData(state, props)] : [],
    eBayBillDate: props.eBayBillDate
      ? formatDateForBackend(props.eBayBillDate)
      : formatDateForBackend(new Date()),
  };

  invoiceMaster.supplierInvoiceCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    fyStartDate:
      financialYear.fyStartDate &&
      getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate:
      financialYear.fyEndDate &&
      getCurrentDateForBackend(financialYear.fyEndDate),
    supplierId: props.supplier && props.supplier.id,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdBy: userInfo.userId,
    amount:
      state.subtotalWithoutDiscount - _getTotalAmtForLedgerItems(gridObject),
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_PURCHASE,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
    ledgerAccountId: props.purchaseCreditorsLedgerId,
    supplierName: props.supplier && props.supplier.name,
  });

  invoiceMaster.supplierInvoiceCOATxList.push({
    relationshipId: companyInfo.relationshipId,
    relationshipName: companyInfo.storeName,
    fyStartDate:
      financialYear.fyStartDate &&
      getCurrentDateForBackend(financialYear.fyStartDate),
    fyEndDate:
      financialYear.fyEndDate &&
      getCurrentDateForBackend(financialYear.fyEndDate),
    supplierId: props.supplier && props.supplier.id,
    //dateCreated: getCurrentDateForBackend(new Date()),
    createdBy: userInfo.userId,
    amount:
      state.subtotalWithoutDiscount - _getTotalAmtForLedgerItems(gridObject),
    txDate: formatDateForBackend(new Date()),
    txType: LEDGER_TYPE.TYPE_PURCHASE,
    accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
    ledgerAccountId: props.purchaseLedgerId,
    supplierName: props.supplier && props.supplier.name,
  });

  state.rootDiscountList.forEach((discountObj, i) => {
    if (discountObj.rootDiscountAmount) {
      invoiceMaster.supplierInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:financialYear.fyStartDate &&getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:financialYear.fyEndDate &&getCurrentDateForBackend(financialYear.fyEndDate),
        isTaxable:discountObj.isTaxable,
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountObj.rootDiscountAmount,
        amountPercent: discountObj.rootDiscountPercent,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.discountCreditorLedgerId|| -1,
        supplierName: props.supplier && props.supplier.name,
        taxPercent: discountObj.taxPercent,
        taxAmount: discountObj.taxAmount,
        taxName: discountObj.taxName,
        taxId: discountObj.key,
      });

      invoiceMaster.supplierInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        isTaxable:discountObj.isTaxable,
        amount: discountObj.rootDiscountAmount,
        amountPercent: discountObj.rootDiscountPercent,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,        
        ledgerAccountId: discountObj.chartOfAccountDetailsId ||  props.purchaseDiscountLedgerId|| -1,
        supplierName: props.supplier && props.supplier.name,
      });
    }
  });
  state.rootExpenseList.forEach((expenseObj, i) => {
    if (expenseObj.expenseAmount) {
      invoiceMaster.supplierInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: expenseObj.expenseAmount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_EXPENSE,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        // ledgerAccountId: discountObj.chartOfAccountDetailsId || -1,
        supplierName: props.supplier && props.supplier.name,
        ledgerAccountId:  props.purchaseExpenseCreditorLedgerId,
        taxName:companyInfo.countryName == "India" ? isPlaceOfSupplySameAsRelationship ? LEDGER_TYPE.TYPE_TAX_CGST: LEDGER_TYPE.TYPE_TAX_IGST:"",
        // taxId: (props.selectedExpenseLedger || {}).taxSingleRatePercent,
        // taxRate: (props.selectedExpenseLedger || {}).taxSingleRateId,

        ledgerAccountName: expenseObj.ledgerAccountName ||props.expenseLedgerAccountName,
        taxId: (expenseObj.selectedExpenseLedger || {}).taxSingleRatePercent,
        taxRate: (expenseObj.selectedExpenseLedger || {}).taxSingleRateId,
        taxSingleRate: (expenseObj.selectedExpenseLedger || {}).taxSingleRateId,
      });

      invoiceMaster.supplierInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: expenseObj.expenseAmount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_EXPENSE,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        // ledgerAccountId: props.purchaseExpenseLedgerId,
        supplierName: props.supplier && props.supplier.name,
        ledgerAccountId:expenseObj.chartOfAccountDetailsId ?expenseObj.chartOfAccountDetailsId:props.purchaseExpenseLedgerId,
        // taxId: (props.selectedExpenseLedger || {}).taxSingleRatePercent,
        // taxRate: (props.selectedExpenseLedger || {}).taxSingleRateId,
        ledgerAccountName: expenseObj.ledgerAccountName || props.expenseLedgerAccountName,
        taxId: (expenseObj.selectedExpenseLedger || {}).taxSingleRateId,
        taxSingleRate: (expenseObj.selectedExpenseLedger || {}).taxSingleRatePercent,
      });
    }
  });

  if (props.roundingOffSettings && props.roundingOffSettings.isRoundingOffTotalAmtOnPurchaseInvoice && state.roundingOffAmt) {
 
      invoiceMaster.supplierInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        createdBy: userInfo.userId,
        amount: state.roundingOffAmt,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_ROUNDING_OFF,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        supplierName: props.supplier && props.supplier.name,

        ledgerAccountId: props.roundingOffCreditorLedgerId,
        ledgerAccountName: props.roundingOffLedgerName,
       
      });

      invoiceMaster.supplierInvoiceCOATxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        createdBy: userInfo.userId,
        amount: state.roundingOffAmt,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_ROUNDING_OFF,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        supplierName: props.supplier && props.supplier.name,

        ledgerAccountId: props.roundingOffLedgerId,
        ledgerAccountName: props.roundingOffCreditorLedgerName,
      });
    }
  

  if (
    props.relationshipBillingAddress &&
    props.relationshipBillingAddress.streetAddress1
  ) {
    if (!props.update) {
      delete props.relationshipBillingAddress.locationPurchaseInvoiceId;
      //props.relationshipBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
      //props.relationshipBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      //props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    invoiceMaster.boLocationPurchaseInvoiceList.push(
      props.relationshipBillingAddress
    );
  }

  if (
    props.relationshipShippingAddress &&
    props.relationshipShippingAddress.streetAddress1
  ) {
    if (!props.update) {
      delete props.relationshipShippingAddress.locationPurchaseInvoiceId;
      // props.relationshipShippingAddress.dateCreated = getCurrentDateForBackend(new Date());
      // props.relationshipShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      // props.relationshipShippingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    invoiceMaster.boLocationPurchaseInvoiceList.push(
      props.relationshipShippingAddress
    );
  }

  if (
    props.supplierBillingAddress &&
    props.supplierBillingAddress.streetAddress1
  ) {
    if (!props.update) {
      delete props.supplierBillingAddress.locationPurchaseInvoiceId;
      // props.supplierBillingAddress.dateCreated = getCurrentDateForBackend(new Date());
      // props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    } else {
      // props.supplierBillingAddress.dateUpdated = getCurrentDateForBackend(new Date());
    }
    invoiceMaster.boLocationPurchaseInvoiceList.push(
      props.supplierBillingAddress
    );
  }

  gridObject.forEachNode((rowNode, index) => {
    const { data } = rowNode;
    if (!data.itemId && !data.description && !data.qty && !data.rate) {
      return;
    }
    const itemId = data.selectedProductValue && data.selectedProductValue[0];
    //if(!itemId) return;
    let variantId =
      data.selectedProductValue && data.selectedProductValue.length === 2
        ? data.selectedProductValue[1]
        : 0;
    if (data.productObj && !data.productObj.hasVariant && !variantId) {
      variantId = data.productObj.inventoryItemVariantList
        ? data.productObj.inventoryItemVariantList[0]?.variantId
        : data.inventoryItemVariantId;
    }
    let product = {};
    let itemLevelCoaTxList = [];
    if (itemId) {
      product = data.productObj || {};
    }
    let variant = {};
    if (variantId) {
      variant = data.variant || {};
    }
    let discountOnItem = 0;
    let itemLevelAmount = ((data || {}).rate || 0) * ((data || {}).qty || 0);
    if(data.discount){
       discountOnItem = itemLevelAmount
        ? data.discount * 0.01 * itemLevelAmount
        : 0;
      itemLevelAmount = itemLevelAmount - discountOnItem;
    }
    if (data.discount && state.isItemDiscountAplicable) {
      
      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.discountCreditorLedgerId,
        supplierName: props.supplier && props.supplier.name,
      });

      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: discountOnItem,
        amountPercent: data.discount,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_DISCOUNT,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.purchaseDiscountLedgerId,
        supplierName: props.supplier && props.supplier.name,
      });
    }

    if (data.ledgerId) {
      let totalAmountOfColumn = 0;
      if (data.ledgerId && data.qty && data.rate) {
        totalAmountOfColumn = data.qty * data.rate;
        if (data.discount) {
          let discountAmount = data.discount * 0.01 * totalAmountOfColumn;
          totalAmountOfColumn = totalAmountOfColumn - discountAmount;
        }
        if (data.tax) {
          totalAmountOfColumn =
            data.tax * 0.01 * totalAmountOfColumn + totalAmountOfColumn;
        }
      }
      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: totalAmountOfColumn,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_PURCHASE,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
        ledgerAccountId: props.ledgerId,
        supplierName: props.supplier && props.supplier.name,
      });

      itemLevelCoaTxList.push({
        relationshipId: companyInfo.relationshipId,
        relationshipName: companyInfo.storeName,
        fyStartDate:
          financialYear.fyStartDate &&
          getCurrentDateForBackend(financialYear.fyStartDate),
        fyEndDate:
          financialYear.fyEndDate &&
          getCurrentDateForBackend(financialYear.fyEndDate),
        supplierId: props.supplier && props.supplier.id,
        //dateCreated: getCurrentDateForBackend(new Date()),
        createdBy: userInfo.userId,
        amount: totalAmountOfColumn,
        txDate: formatDateForBackend(new Date()),
        txType: LEDGER_TYPE.TYPE_PURCHASE,
        accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
        ledgerAccountId: props.purchaseCreditorsLedgerId,
        supplierName: props.supplier && props.supplier.name,
      });
    }

    if (data.tax) {
      if (companyInfo.countryName !== "India") {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: data.taxName,
          taxId: data.taxId,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          ledgerAccountId: props.taxCreditorsLedgerId,
          supplierName: props.customer && props.customer.companyName,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          customerId: props.customer && props.customer.customerId,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: Number(data.tax || 0),
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: data.taxName,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          taxId: data.taxId,
          ledgerAccountId: props.purchaseTaxLedgerId,
          supplierName: props.customer && props.customer.companyName,
        });
      } else if (isPlaceOfSupplySameAsRelationship) {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? (data.tax * 0.01 * itemLevelAmount)/2
            : 0 
          ).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          taxId: data.taxId,
          ledgerAccountId: props.cgstTaxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? (data.tax * 0.01 * itemLevelAmount)/2
            : 0
          ).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_CGST,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          taxId: data.taxId,
          ledgerAccountId: props.cgstPurchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? (data.tax * 0.01 * itemLevelAmount)/2
            : 0
          ).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          taxId: data.taxId,
          ledgerAccountId: props.sgstTaxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? (data.tax * 0.01 * itemLevelAmount)/2
            : 0
          ).toFixed(2),
          amountPercent: (data.tax)/2,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_SGST,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          taxId: data.taxId,
          ledgerAccountId: props.sgstPurchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });
      } else {
        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.CREDIT,
          taxId: data.taxId,
          ledgerAccountId: props.igstTaxCreditorsLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });

        itemLevelCoaTxList.push({
          relationshipId: companyInfo.relationshipId,
          relationshipName: companyInfo.storeName,
          fyStartDate:
            financialYear.fyStartDate &&
            getCurrentDateForBackend(financialYear.fyStartDate),
          fyEndDate:
            financialYear.fyEndDate &&
            getCurrentDateForBackend(financialYear.fyEndDate),
          supplierId: props.supplier && props.supplier.id,
          //dateCreated: getCurrentDateForBackend(new Date()),
          createdBy: userInfo.userId,
          amount: (itemLevelAmount
            ? data.tax * 0.01 * itemLevelAmount
            : 0
          ).toFixed(2),
          amountPercent: data.tax,
          txDate: formatDateForBackend(new Date()),
          txType: LEDGER_TYPE.TYPE_TAX,
          taxName: LEDGER_TYPE.TYPE_TAX_IGST,
          accountingEntry: ACCOUNTING_ENTRY_TYPE.DEBIT,
          taxId: data.taxId,
          ledgerAccountId: props.igstPurchaseTaxLedgerId,
          supplierName: props.supplier && props.supplier.name,
        });
      }
    }
    let itemLevelAmountTotal = ((data || {}).rate || 0) * ((data || {}).qty || 0);

    invoiceMaster.supplierInvoiceDetailsList.push({
      relationshipId: companyInfo && companyInfo.relationshipId,
      supplierId: props.supplier && props.supplier.id,
      version: props.update ? data.version : 0,
      quantity: Number(data.qty),
      qtyOrderedFromPO: data.quantityOrdered,
      sNo: index + 1,
      sku: product.sku,
      uomName: data.uom,
      basePrice: Number(data.baseRate),
      specialDiscount: Number(data.specialDiscount),
      txDate: formatDateForBackend(props.invoiceDate),
      invoiceDate: formatDateForBackend(props.invoiceDate),
      discountPercentage: Number(data.discount),
      discountAmount:
      itemLevelAmountTotal && data.discount
          ? Number(data.discount * 0.01 * itemLevelAmountTotal)
          : 0,
      itemName: product.itemName,
      itemId: product.itemId,
      itemPurchasedPrice: Number(data.rate),
      rowHeight: data.scrollHeight,
      description: data.description,
      hsnCode: data.hsnCode,
      inventoryItemVariantId: variantId,
      supplierInvoiceCOATxList: itemLevelCoaTxList,
      attributeId1: variant.attributeId1,
      attributeId2: variant.attributeId2,
      attributeId3: variant.attributeId3,
      attributeName1: variant.attributeName1,
      attributeName2: variant.attributeName2,
      attributeName3: variant.attributeName3,
      attributeValue1: variant.attributeValue1,
      attributeValue2: variant.attributeValue2,
      attributeValue3: variant.attributeValue3,
      itemVariantName: data.itemVariantName,
      poMasterId: props.poMasterId,
      poNumber: props.poNumber,
      inboundDeliveryNumber: props.inboundDeliveryNumber,
      inboundDeliveryMasterId: props.inboundDeliveryMasterId,
      parentDetailsId: data.parentDetailsId,
      qtyBeforeUpdate: Number(data.qtyBeforeUpdate || 0),
      invoiceDetailsId: data.invoiceDetailsId,
      invoiceMasterId: props.update ? props.invoiceMasterId : 0,
    });
  });

  let additionalInfo = {};

  if (props.selectedContact && props.selectedContact.firstName) {
    additionalInfo = { ...props.selectedContact };
    // additionalInfo.relationshipVatNumber = companyInfo.vatNumber;
    //  additionalInfo.relationshipGstNumber = companyInfo.gstNumber;
    //  additionalInfo.relationshipPanNumber = companyInfo.panNumber;
    additionalInfo.relationshipFaxNumber = companyInfo.faxNumber;
    additionalInfo.relationshipPhoneNumber = companyInfo.phoneNumber;
    additionalInfo.relationshipWebsite = companyInfo.website;
  }
  if (props.supplier||props.supplierDetail) {
    additionalInfo.supplierId = props.supplier.id;
    additionalInfo.supplierPhone = props.supplierDetail && props.supplierDetail.supplierPhone;
    additionalInfo.supplierEmail = props.supplierDetail && props.supplierDetail.supplierEmail;
    //  additionalInfo.gstNumber = props.supplier.gstNumber;
    // additionalInfo.panNumber = props.supplier.panNumber;
  }
  if (Object.keys(additionalInfo).length) {
    additionalInfo.relationshipId = companyInfo && companyInfo.relationshipId;
    additionalInfo.additionalInfoId = props.poMasterId
      ? 0
      : additionalInfo.additionalInfoId;
  } else {
    additionalInfo = {};
  }
  if (!props.update) additionalInfo && delete additionalInfo.additionalInfoId;

  if (
    props.selectedSupplierTaxIdentifications &&
    props.selectedSupplierTaxIdentifications.length &&
    props.supplier
  ) {
    let selectedSupplierTaxIdentifications = filter(
      props.supplier.supplierTaxIdentificationsList || [],
      (taxObj) => {
        return (
          props.selectedSupplierTaxIdentifications.indexOf(
            taxObj.supplierTaxIdentificationId
          ) > -1
        );
      }
    );
    additionalInfo.taxIdentifications = JSON.stringify(
      selectedSupplierTaxIdentifications
    );
  }
  if (
    props.selectedRelationshipTaxIdentifications &&
    props.selectedRelationshipTaxIdentifications.length
  ) {
    let selectedRelTaxIdentifications = filter(
      props.allRelationshipTaxIdentificatins,
      (taxObj) => {
        return (
          props.selectedRelationshipTaxIdentifications.indexOf(
            taxObj.relationshipTaxIdentificationId
          ) > -1
        );
      }
    );
    additionalInfo.relationshipTaxIdentifications = JSON.stringify(
      selectedRelTaxIdentifications
    );
  }

  invoiceMaster.additionalInfoList = [additionalInfo];
  return invoiceMaster;
};

export const fetchPurchasedOrderForInvoice = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/purchaseOrder/getPODataForInvoice?poMasterId=${payload.poMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`
      )
      .then((res) => {
        const { data } = res;
        dispatch({
          type: PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_PO_DETAIL,
          data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall =
            PURCHASE_INVOICE_ACTION_LIST.PURCHASE_INVOICE_PO_DETAIL;
        }
      });
  };
};

export const resetPoInvoiceData = () => {
  return (dispatch) => {
    dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.RESET_PO_INVOICE_DATA });
  };
};

export const getNextTxNumber = (payload) => {
  let date = payload.date
    ? getCurrentDateForBackend(payload.date)
    : getCurrentDateForBackend(new Date());
  return (dispatch) => {
    dispatch({
      type: PURCHASE_INVOICE_ACTION_LIST.NEXT_PURCHASE_INVOICE_NUMBER_RESET,
    });
    dispatch({ type: COMMON_ACTIONS.SHOW_DETAIL_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/getNextInvoiceNumber?date=${date}&relationshipId=${payload.relationshipId}`
      )
      .then((res) => {
        dispatch({
          type: PURCHASE_INVOICE_ACTION_LIST.NEXT_PURCHASE_INVOICE_NUMBER,
          data: res.data,
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall =
            PURCHASE_INVOICE_ACTION_LIST.NEXT_PURCHASE_INVOICE_NUMBER;
        }
      }).finally(() => {
        dispatch({
          type: COMMON_ACTIONS.HIDE_DETAIL_LOADER,
        });
      })
  };
};

export const fetchIDForInvoice = (payload) => {
  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/inboundDelivery/getInboundDeliveryDataForInvoice?inboundDeliveryMasterId=${payload.inboundDeliveryMasterId}&relationshipId=${payload.relationshipId}&supplierId=${payload.supplierId}`
      )
      .then((res) => {
        const { data } = res;
        dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.ID_INVOICE_DATA, data });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        } else {
          err.actionToCall = PURCHASE_INVOICE_ACTION_LIST.ID_INVOICE_DATA;
        }
      });
  };
};

export const resetIDInvoiceData = () => {
  return (dispatch) => {
    dispatch({ type: PURCHASE_INVOICE_ACTION_LIST.RESET_ID_INVOICE_DATA });
  };
};

export const createPurchaseInvoiceDraft = (payloadObject) => {
  const props = payloadObject.props;
  const invoiceData = _getInvoiceData(payloadObject);
  return (dispatch) => {
    const data = {
      supplierInvoiceDraftId: props.supplierInvoiceDraftId || 0,
      supplierInvoiceData: JSON.stringify(invoiceData),
      relationshipId: invoiceData.relationshipId,
      supplierId: invoiceData.supplierId,
    };

    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/saveOrUpdateDraft`,
        data
      )
      .then((res) => {
        showToasterMessage({
          messageType: "success",
          description: "Purchase Invoice saved in draft",
        });
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.history.push({
          pathname: "/admin/purchase-invoice-list",
          state: { activeTab: "Draft" },
        });
      })
      .catch((err) => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        }
      });
  };
};


export const checkSupplierInvoiceNumber = (props,supplierInvoiceNumber,supplierId)=>{

  return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(
        `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/checkSupplierInvoiceNumber?relationshipId=${props.companyInfo.relationshipId}&supplierInvoiceNumber=${supplierInvoiceNumber}&supplierId=${Number(supplierId)}`
      )
      .then((res) => {
        const { data } = res;
        if(data){

           props.updateState({
            validSupplierInvNumber:false
           })
           showToasterMessage({
            messageType: "",
            description: "Duplicate Supplier Inv # ",
          });
        }else {
          props.updateState({
            validSupplierInvNumber:true
           })
        }
      })
      .catch((err) => {
     
      });
  };
}

export const deleteDocument = (payload,props) => {
  return dispatch => {
    let formData = new FormData();
    formData.append("relationshipId", payload.relationshipId);
    formData.append('bucketName', props.companyInfo.bucketName);
    formData.append('objectKey', payload.awsKeyName);
    formData.append('folderName', payload.folderName);
    formData.append('invoiceMasterId', payload.supplierInvoiceMasterId);
   
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/supplierInvoice/deletePurchaseInvoiceDocument`,
        formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(res => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        props.fetchPurchasedInvoiceDetail({...payload , ...props?.invoicePayload});
        showToasterMessage({
          description: (res || {}).message || 'Deleted successfully.',
          messageType: 'success'
        })
      })
      .catch(err => {
        ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}