import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Drawer, DatePicker } from 'antd';
import { TextBox } from '../../../general/TextBox';
import { CustomButton } from '../../../general/CustomButton';
import { Dropdown } from '../../../general/Dropdown';
import { ErrorMsg } from '../../../general/ErrorMessage';
import { MODAL_TYPE } from '../../../../static/constants';
import { TextArea } from '../../../general/TextAreaComponent';
import { CONSTANTS } from '../../../../static/constants';
import { getLocalDateFromUtcStr, getCurrentDateForBackend } from '../../../../utils';


const LeadTaskDrawer = (props) => {

    const { leadTask={} } = props;

    const rowgutter =[24,16];
    const format = CONSTANTS.DISPLAY_DATE_FORMAT;

    const handleSubmit = () =>{
        props.updateDrawerState({
            submittedOnce: true
        });

        if(!leadTask.refNumber || !leadTask.assignedUserId || !leadTask.taskDescription){
            return
        }
        const payload = {
            ...leadTask,
            relationshipId: (props.companyInfo || {}).relationshipId,
            dateCreated: getCurrentDateForBackend(new Date()),
            userCreated: props.userInfo.relationshipEmployeeId,
            version: (leadTask || {}).version || 0,
            taskDueDate: getCurrentDateForBackend(leadTask.taskDueDate),
            taskClosedDate: getCurrentDateForBackend(leadTask.taskClosedDate),
            leadId: props.leadData.crmLeadId,
        }
        const modalData = {
            modalBody: 'Do you want to create task?' ,
            handleSubmit: () => {
              props.createLeadTask(payload);
              props.updateModalState({
                leadTaskDrawerVisible: false
              })
            }
        };
        props.pushModalToStack(modalData);
    } 

    return (
        <Drawer
            title={'Create Task'}
            keyboard={false}
            className='custom-drawer'
            width={720}
            maskClosable={false}
            zIndex={99999}
            destroyOnClose={true}
            onClose={()=>{
                props.updateModalState({
                    leadTaskDrawerVisible: false
                })
            }}
            visible={props.leadTaskDrawerVisible}
            footer = {
                <>
                    <CustomButton
                        intlId={'confirmation.cancel'}
                        defaultMessage={''}
                        type={ 'default'}
                        key={1}
                        onClick={() => {
                            props.updateState({
                                leadTask:{},
                                leadTaskDrawerVisible: false
                            });
                        }}
                    />
                    <CustomButton
                        intlId={'button.create.label'}
                        defaultMessage={ ''}
                        htmlType='submit'
                        key={2}
                        onClick={() => {
                            handleSubmit();
                        }}
                    />
                </>
            }
        >
            <Row gutter={rowgutter}>

                <Col span={12}>
                    <div className='i-label'>
                        <FormattedMessage id='supplier.listing.logActivity.refNumber.label' defaultMessage='' /><span className='required'>*</span>
                    </div>
                    <TextBox 
                        value={leadTask.refNumber}
                        placeholder={'Ref Number'}
                        onChange={(e)=>{
                            props.updateDrawerState({
                                leadTask: {
                                    ...leadTask, 
                                    refNumber: e.target.value,
                                }
                            })
                        }}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submittedOnce || leadTask.refNumber }}
                        message={props.intl.formatMessage(
                            {
                                id: 'requiredField.missing.msg',
                                defaultMessage: ''
                            })
                        } 
                    />
                </Col>

                <Col span={12}>
                    <div className='i-label'>
                        Priority
                    </div>
                    <Dropdown
                        items={['Urgent', 'High', 'Medium', 'Low']}
                        value={leadTask.priority}
                        onSelect={(selectedValue, option) => {
                            props.updateDrawerState({
                                leadTask: {
                                    ...leadTask, 
                                    priority: selectedValue,
                                }
                            })
                        }}
                    />
                </Col>

                <Col span={12}>
                    <div className='i-label'>
                        Task Due Date
                    </div>
                    <DatePicker
                        allowClear={false}
                        format={format}
                        style={{ width: '100%' }}
                        key={'duedate'}
                        defaultValue={leadTask.taskDueDate ? getLocalDateFromUtcStr({date : leadTask.taskDueDate}) : null}
                        onChange={(selectedDate) => {
                            props.updateDrawerState({
                                leadTask: {
                                    ...leadTask,
                                    taskDueDate: selectedDate,
                                }
                            })
                        }} 
                    />
                </Col>

                <Col span={12}>
                    <div className='i-label'>
                        Task Closed Date
                    </div>
                    <DatePicker
                        allowClear={false}
                        format={format}
                        style={{ width: '100%' }}
                        key={'closedDate'}
                        defaultValue={leadTask.taskClosedDate ? getLocalDateFromUtcStr({date : leadTask.taskClosedDate}) : null}
                        onChange={(selectedDate) => {
                            props.updateDrawerState({
                                leadTask: {
                                    ...leadTask,
                                    taskClosedDate: selectedDate,
                                }
                            })
                        }} 
                    />
                </Col>

                <Col span={12}>
                    <div className='i-label'>
                        Assign To<span className='required'>*</span>
                    </div>
                    <Dropdown
                        items={props.allEmployee || []}
                        optionKeyName='relationshipEmployeeId'
                        valueKeyName='fullName'
                        value={leadTask.assignedUserId}
                        onSelect={(selectedValue, option) => {
                            props.updateDrawerState({
                                leadTask: {
                                    ...leadTask, 
                                    assignedUserId: selectedValue,
                                    assignedUserName: option.name,
                                }
                            })
                        }}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submittedOnce || leadTask.assignedUserId }}
                        message={props.intl.formatMessage(
                            {
                                id: 'requiredField.missing.msg',
                                defaultMessage: ''
                            })
                        } 
                    />
                </Col>

                <Col span={24}>
                    <div className='i-label'>
                        Task Description<span className='required'>*</span>
                    </div>
                    <TextArea 
                        placeholder='Brief description about the task'
                        value={leadTask.taskDescription}
                        style={{width: '100%', maxWidth: '100%', padding: '5px 8px'}}
                        onChange={(e)=>{
                            props.updateDrawerState({
                                leadTask: {
                                    ...leadTask, 
                                    taskDescription: e.target.value,
                                }
                            })
                        }}
                    />
                    <ErrorMsg
                        validator={() => { return !props.submittedOnce || leadTask.taskDescription }}
                        message={props.intl.formatMessage(
                            {
                                id: 'requiredField.missing.msg',
                                defaultMessage: ''
                            })
                        } 
                    />
                </Col>

            </Row>

        </Drawer>
    );
};

export default injectIntl(LeadTaskDrawer);
