import React from 'react';
import { injectIntl } from 'react-intl';
import { CustomAgGrid } from '../../../../../general/CustomAgGrid';


const CategoryComponent = (props) => {
const columnDefs = [
    {
        headerName: "Category Name",
        field: "categoryName",
        resizable: true
    },
    {
        headerName: "Ending Account No",
        field: "minAccountNumber",
        resizable: true
    },{
        headerName: "Starting Account No",
        field: "maxAccountNumber",
        resizable: true
    }
];
    return (
        <div className="reserved-ledger">
            <CustomAgGrid
                columnDefs={columnDefs}
                rowData={props.reservedAccounts || []}
                rowDragManaged={true}
                domLayout={"autoHeight"}
                suppressDragLeaveHidesColumns={true}
                animateRows={true}
            >
            </CustomAgGrid>
        </div>
    );
};

export default injectIntl(CategoryComponent);
