import React from "react";
import { Drawer, Row, Col } from "antd";
import { FormattedMessage } from "react-intl";
import {
  showToasterMessage,
} from "../../../utils";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import { FilterOutlined, UndoOutlined } from "@ant-design/icons";
import { CustomButton } from "../../general/CustomButton";
// import * as find from "lodash.find";
// const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const ParentListFilterComp = (props) => {
  const { updateState, companyInfo, userInfo } = props;

  const closeDrawer = () => {
    props.updateState({
      parentListFilterDrawerVisible: false,
    });
  };

  const applyFilter = () => {
    // if(!props.prNum && !props.selectedRequesterName && !props.startDate && !props.endDate && !props.expStartDate && !props.expEndDate  && !props.selectedUserStatus && !props.selectedStatus && !props.selectedPriority && !props.selectedRefNum && !props.selectedProjectMasterId ){
    if (
      !props.selectedForm &&
      !props.fatherName &&
      !props.motherName &&
      !props.status
    ) {
      return showToasterMessage({
        messageType: "error",
        description: "Please select at least one filter",
      });
    }
    const payload = {
      companyInfo,
      // relationshipId: teacherInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {
        selectedForm: props.selectedForm || null,
        fatherName: props.fatherName || null,
        motherName: props.motherName || null,
        status: props.status,
      },
    };
    const payloadforform = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: props.pageNumber || 1,
      pageSize: props.pageSize || 200,
      formId: props.formId
    };
    props.getRegisteredMemberByFormId(payloadforform);
    props.getRegisteredMemberCount(payloadforform);
    // props.fetchPurchaseOrdersByFilter(payload);
    // props.getPurchaseOrdersCountByFilter(payload);
    props.fetchParentsListByFilter(payload);
    props.fetchParentsListByFilter(payload);

    
  };

  const clearFilter = () => {
    props.updateState({
      selectedForm: null,
      fatherName: null,
      motherName: null,
      status: null,
    });
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 0,
      pageSize: props.pageSize,
      filters: {},
    };
    props.fetchParentsListByFilter(payload);
    props.getParentslistCountByFilter(payload);
  };

  const rowGutter = [24, 16];

  return (
    <>
      <Drawer
        title={
          <div className="drawer-head-txt">
            <FormattedMessage id="parents.parentsList.filter.drawer.title" />
          </div>
        }
        width={720}
        zIndex={1}
        maskClosable={false}
        destroyOnClose={true}
        onClose={closeDrawer}
        className="custom-drawer"
        visible={props.parentListFilterDrawerVisible}
        footer={
          <>
            <CustomButton
              intlId="confirmation.cancel"
              defaultMessage=""
              type="default"
              key={0}
              onClick={closeDrawer}
            />
            <div>
              <CustomButton
                intlId="common.clearFilter.txt"
                defaultMessage=""
                type="default"
                key={0}
                onClick={clearFilter}
                btnIcon={
                  <>
                    <UndoOutlined /> &nbsp;
                  </>
                }
                style={{ marginRight: "10px" }}
              />
              <CustomButton
                intlId="common.applyFilter.txt"
                defaultMessage=""
                htmlType="submit"
                onClick={applyFilter}
                key={1}
                btnIcon={
                  <>
                    <FilterOutlined /> &nbsp;
                  </>
                }
              />
            </div>
          </>
        }
      >
        <Row gutter={rowGutter}>
          <Col span={12}>
            <div className="i-label">Filter by form</div>
            <Dropdown
                items={props.studentFormList || []}
                valueKeyName='formShortName'
                optionKeyName='registrationFormSettingId'
                className={(props.submittedOnce && !props.registrationFormSettingId) ? 'input-text-error' : ''}
                value={(props.selectedForm || {}).formShortName}
                placeholder='Filter by form'
                onSelect={(optionValue, option) => {
                  // let selectedForm = find(props.studentFormList || [], { registrationFormSettingId: option.value }) || {};
                  props.updateState({
                    formId: optionValue,
                  })
                }}
              />
          </Col>

          <Col span={12}>
            <div className="i-label">Father Name</div>
            <TextBox
              placeholder="Father Name"
              type="text"
              maxLength={20}
              value={props.fatherName}
              onChange={(e) => {
                props.updateState({ fatherName: e.target.value });
              }}
            />
          </Col>

          <Col span={12}>
            <div className="i-label">Mother Name</div>
            <TextBox
              placeholder="Mother Name"
              type="text"
              maxLength={20}
              value={props.motherName}
              onChange={(e) => {
                props.updateState({ motherName: e.target.value });
              }}
            />
          </Col>

          <Col span={12}>
            <div className="i-label">Status</div>
            <Dropdown
              items={[
                {
                  label: "Active",
                  key: "1",
                },
                {
                  label: "Inactive",
                  key: "2",
                },
              ]}
              valueKeyName="label"
              optionKeyName="key"
              value={props.status}
              placeholder="Status"
              onSelect={(optionValue, option) => {
                props.updateState({
                  status: option.name,
                });
              }}
              allowClear={true}
              onClear={() => {
                updateState({ status: undefined });
              }}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default ParentListFilterComp;
