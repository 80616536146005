import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SchoolComp from "../../../../components/drawer/schoolManagement/school";
import { pushModalToStack, popModalFromStack, getRelationshipAddress, fetchAllEmployees } from './../../../../actions/commonActions';
import { fetchDataIfNeeded } from "../../../../utils";

class SchoolDrawer extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {

    const companyInfo = this.props.companyInfo || {};
    const payload = { relationshipId: companyInfo.relationshipId };
    fetchDataIfNeeded('getRelationshipAddress', 'boRelationshipAddress', this.props, payload);
    fetchDataIfNeeded('fetchAllEmployees', 'allEmployee', this.props, payload);
  }

  render() {
    return (
      <SchoolComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      ></SchoolComp>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    relationshipAddress: state.common.boRelationshipAddress,
    allEmployee : state.common.employees,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRelationshipAddress,
      fetchAllEmployees,
      pushModalToStack,
      popModalFromStack
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDrawer);
