import axios from 'axios';
import config from '../../../../../../config/environmentConfig';
import { LEDGER_ACTION_LIST, COMMON_ACTIONS } from '../../../../../../static/constants';

export const fetchAllReservedLedgerAccounts = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/coaCategory/getAllCOACategories?relationshipId=${payload.relationshipId}`)
        .then(res => {
          dispatch({ type: LEDGER_ACTION_LIST.RESERVED_LEDGER_LIST, data: res.data });
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
        .catch((err) => {
          ////dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    }
  }