import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { DatePicker, Menu, Tooltip, Input, Pagination, Popover, Skeleton, Empty} from "antd";
import {
  formatDateForBackend,
  getMomentDateForUI,
  exportExcel,
  getInitialsFromString,
  getTimeFromDate,
  exportCSV,
} from "../../../utils";
import PageBreadcrumb from "../../PageBreadcrumb";
import DropdownAction from "antd/lib/dropdown";
import { DownloadOutlined } from "@ant-design/icons";
import {
  ICONS,
  MONTH_NAME_CONSTANT,
} from "../../../static/constants";
import EditAttendanceComponent from "./EditAttendancePop";
import './index.scss';

const { Search } = Input;

const EmployeeAttendance = (props) => {
  const { pageSize } = props;

  const [viewMode, setViewMode] = useState(0);
  const [visibleEdit, setVisibleEdit] = useState([0, 0]);
  const [editPop, setEditPop] = useState(false);

  const getDateList = (dateObj) => {
    const date = dateObj ? new Date(dateObj) : new Date();
    let dateList = [];
    let maxDate = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();

    for (let i = 1; i <= maxDate; i++) {
      dateList.push(i);
    }

    return [dateList, date.getMonth() + 1];
  };

  const breadCrumbList = [
    {
      name: (
        <FormattedMessage
          id="company.employee.attendance-team"
          defaultMessage=""
        />
      ),
    },
    {
      name: (
        <FormattedMessage
          id="company.employee.attendance-heading"
          defaultMessage=""
        />
      ),
    },
  ];

  const newAttendance = () => {
    props.updateHeaderState({
      employeeAttendance: {
        addEmployeeAttendenceDrawerVisible: true,
        employeeName: props.employeeName,
        employeeId: props.employeeId,
      },
    });
  };

  const exportDataDownload = (empList, format) => {
    const dataList = (empList || []).map((item, i) => {
      let rowData = {
        0: `${item.employeeFullName}(${item.jobRoleName || ""})` || "",
      };
      dateList.forEach((dateKey) => {
        if (viewMode)
          rowData[MONTH_NAME_CONSTANT[currentMonth] + " " + dateKey] = item
            .attendance[dateKey]?.checkinDate
            ? `${getTimeFromDate(
                item.attendance[dateKey]?.checkinDate
              )} - ${getTimeFromDate(item.attendance[dateKey]?.checkoutDate)}`
            : "";
        else
          rowData[MONTH_NAME_CONSTANT[currentMonth] + " " + dateKey] =
            item.attendance[dateKey]?.status?.split("")[0] || "";
      });
      return rowData;
    });
    const year = new Date().getFullYear();
    if (format === "Excel") {
      exportExcel(
        dataList,
        `Emp_Attendance_${viewMode ? "Time" : "Std"}_${
          MONTH_NAME_CONSTANT[currentMonth]
        }_${year}`
      );
    }
    if (format === "Csv") {
      exportCSV(
        dataList,
        `Emp_Attendance_${viewMode ? "Time" : "Std"}_${
          MONTH_NAME_CONSTANT[currentMonth]
        }_${year}`
      );
    }
  };

  const viewMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          View as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="Standard View"
          onClick={() => {
            setViewMode(0);
          }}
        >
          <i className="fi fi-rs-standard-definition" /> Standard View
        </Menu.Item>

        <Menu.Item
          key="2"
          value="Time View"
          onClick={() => {
            setViewMode(1);
          }}
        >
          <i className="fi fi-rs-notebook" /> Time View
        </Menu.Item>
      </Menu>
    );
  };

  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>
        <Menu.Divider />

        <Menu.Item
          key="1"
          value="excel"
          onClick={() => {
            if (props.empAttendanceList.length) {
              exportDataDownload(props.empAttendanceList, "Excel");
            }
          }}
        >
          <i className={ICONS.EXCEL} /> Excel File
        </Menu.Item>

        <Menu.Item
          key="2"
          value="csv"
          onClick={() => {
            if (props.empAttendanceList.length) {
              exportDataDownload(props.empAttendanceList, "Csv");
            }
          }}
        >
          <i className={ICONS.CSV} /> CSV File
        </Menu.Item>
      </Menu>
    );
  };
  const [dateList, currentMonth] = getDateList(props.selectedDate);

  const countAbsentPresent = (attendance = {}) => {
    const counts = Object.values(attendance).reduce(
      (acc, value) => {
        acc[
          value.status === "Absent"
            ? "absentCount"
            : value.status === "Present"
            ? "presentCount"
            : "others"
        ]++;
        return acc;
      },
      { absentCount: 0, presentCount: 0, others: "others" }
    );
    return (
      <>
        <div className="essential">P - {counts.presentCount}</div>
        <div className="required">A - {counts.absentCount}</div>
        {viewMode === 1 ? (
          <div>{calulcateTotalTimeForAttendance(attendance)}</div>
        ) : (
          ""
        )}
      </>
    );
  };

  function extractFirstAndLastName(fullName) {
    const namesArray = fullName.split(" ");
    const firstName = namesArray[0];
    const lastName = namesArray[namesArray.length - 1];
    const formattedName = `${firstName} ${lastName}`;

    return formattedName;
  }

  const calulcateTotalTimeForAttendance = (data) => {
    let total = 0;
    if (!Object.keys(data || {}).length) {
      return total;
    }

    for (const day in data) {
      // just to pass prevous data
      if (typeof data[day] !== "object") {
        continue;
      }
      const checkinDate = new Date(data[day].checkinDate);
      const checkoutDate = new Date(data[day].checkoutDate);

      if (checkoutDate < checkinDate) {
        continue;
      }

      // Calculate the time difference in milliseconds
      const timeDiff = checkoutDate - checkinDate;
      // Convert milliseconds to Minutes
      const timeInMin = timeDiff / (1000 * 60);
      total += timeInMin;
    }
    return `${String(Math.floor(total / 60)).padStart(2, "0") || "00"}:${
      String((total % 60).toFixed(0)).padStart(2, "0") || "00"
    }`;
  };

  const stickyStyle = {
    position: "sticky",
    left: "0px",
    backgroundColor: "#ffffff",
    zIndex: 1,
  };


  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="company.employee.attendance-heading"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
          <button
            className="create-action-btn"
            onClick={() => {
              newAttendance();
            }}
          >
            <FormattedMessage id="markAttendance.text" defaultMessage="" />
          </button>
        </div>
      </div>
      <div className="view-container">
        <div className="view-container-actions">
          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="schoolManagement.attendance.table.heading"
                defaultMessage=""
              />
            </div>
            {pageSize ? (
              <>
                <div className="vertical-sep" />
                <div>{pageSize}</div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="right-actions">
            <Search
              placeholder="Search by Name/Designation"
              value={props.searchedText}
              onChange={(e) => {
                props.updateState({
                  searchedText: e.target.value,
                  pageNumber: 1,
                });
                // onSearchedInputChange(e.target.value, props);
              }}
              onSearch={(searchedText) => {
                props.updateState({ searchedText, pageNumber: 1 });
                // onSearchedInputChange(searchedText, props);
                // handleSearchedText(searchedText, props);
              }}
              style={{ width: 200, height: 38 }}
            />

            <DatePicker
              style={{ maxWidth: "130px" }}
              defaultValue={getMomentDateForUI({
                date: props.selectedDate || new Date(),
                format: "MMM-YYYY",
              })}
              onChange={(selectedDate) => {
                const companyInfo = props.companyInfo || {};
                let date = new Date(selectedDate);
                props.updateState({ selectedDate });
                let y = date.getFullYear();
                let m = date.getMonth();
                const payload = {
                  relationshipId: companyInfo.relationshipId,
                  // employeeId: props.employeeId || (props.employeList[1][0] || {}).employeeId,
                  relationshipEmployeeId: (props.userInfo || {})
                    .relationshipEmployeeId,
                  startDate: formatDateForBackend(new Date(y, m, 1)),
                  endDate: formatDateForBackend(new Date(y, m + 1, 0)),
                };
                props.getEmpAttendances(payload);
              }}
              picker="month"
            />

            <DropdownAction overlay={viewMenu()} trigger={["click"]}>
              <span>
                <i className={`${ICONS.VIEW} mr5`} />{" "}
                {viewMode ? "Time View" : "Standard View"}
              </span>
            </DropdownAction>
            {/* <Dropdown
              style={{maxWidth: '130px'}}
              items={["Standard View", "Time View"]}
              defaultValue="Standard View"
              value={viewMode ? "Time View": "Standard View"}
              placeholder='Select View'
              onSelect = {(val)=>{
                if(val === "Time View")
                  setViewMode(1);
                else
                  setViewMode(0);
              }}
            /> */}

            <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined /> &nbsp;{" "}
                <FormattedMessage
                  id="common.action.dropdown.downloadAs"
                  defaultMessage=""
                />
              </span>
            </DropdownAction>
          </div>
        </div>
        <Skeleton loading={props.isLoading}>
          <div className="attendance-listing-table">
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      ...stickyStyle,
                      width: "60px",
                      top: "0px",
                      zIndex: 9,
                    }}
                  >
                    S.No
                  </th>
                  <th
                    style={{
                      ...stickyStyle,
                      width: "200px",
                      left: "60px",
                      top: "0px",
                      zIndex: 9,
                    }}
                  >
                    Profile
                  </th>
                  <th style={{ ...stickyStyle, left: "260px", zIndex: 9 }}>
                    Total
                  </th>
                  {(dateList || []).map((date, i) => {
                    return (
                      <th key={date} >
                        {MONTH_NAME_CONSTANT[currentMonth]} {date}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody>
                {props.empAttendanceList && props.empAttendanceList.length ? (
                  (props.empAttendanceList || []).map((e, j) => {
                    return (
                      <tr key={j}>
                        <td style={{ ...stickyStyle, width: "60px" }}>{j + 1}</td>
                        <td
                          style={{ ...stickyStyle, width: "200px", left: "60px" }}
                        >
                          <div className="d-flex mr10">
                            <span className="user-icon">
                              {getInitialsFromString(e.employeeFullName)}
                            </span>
                            <span>
                              <Tooltip
                                placement="top"
                                title={e.employeeFullName}
                                trigger="hover"
                              >
                                <div className="line-view">
                                  {extractFirstAndLastName(e.employeeFullName)}
                                </div>
                              </Tooltip>

                              <div className="light-txt">
                                {e.jobRoleName || "-"}
                              </div>
                            </span>
                          </div>
                        </td>
                        <td style={{ ...stickyStyle, left: "260px" }}>
                          {countAbsentPresent(e.attendance)}
                        </td>

                        {(dateList || []).map((dateKey, k) => {
                          return (
                            <td
                              key={"att" + k}
                              title={e.attendance[dateKey]}
                              onMouseEnter={() => {
                                if(e.attendance[dateKey] && !editPop){
                                  setEditPop(false);
                                  setVisibleEdit([j, dateKey]);  //[row_number, col_number]
                                }}
                              } 
                              // onMouseLeave={() => {
                              //   setVisibleEdit([0, 0]);
                              // }}
                            >
                              <>
                                {e.attendance[dateKey]?.status === "Present" ? (
                                  viewMode ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        {getTimeFromDate(
                                          e.attendance[dateKey]?.checkinDate
                                        )}
                                      </div>
                                      <div>
                                        {getTimeFromDate(
                                          e.attendance[dateKey]?.checkoutDate
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <span className="essential">P</span>
                                  )
                                ) : (
                                  ""
                                )}

                                {e.attendance[dateKey]?.status === "Absent" ? (
                                  <span className="required">A</span>
                                ) : (
                                  ""
                                )}
                              </>

                                {/* edit functionality for each day attendance for each cell */}
                                {(visibleEdit || []).length && visibleEdit[0] === j && visibleEdit[1] === dateKey && e.attendance[dateKey] ? (
                                  <Popover
                                    content={<EditAttendanceComponent {...props} empData={e} attendanceData={e.attendance[dateKey]} setEditPop={setEditPop}/>}
                                    title={<div className="b-text">Edit Attendance</div>}
                                    trigger="click"
                                    visible={editPop}
                                    placement='left'
                                    style={{height: '500px'}}
                                    overlayClassName={'edit-attendance-pop'}
                                  >
                                    <i className={`${ICONS.EDIT_P} ml5`} onClick={()=>setEditPop(true)}/>
                                  </Popover>
                                )
                                :
                                ''
                                }
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr key="empty-data">
                    <td colSpan={"20%"} style={{borderBottom:"none"}}>
                      <Empty />
                    </td>
                </tr>
                )}
              </tbody>
            </table>
          </div>
        </Skeleton>

        <div className="footer">
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={pageSize}
              // showTotal={pageSize}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                // loadPageData(pageNumber, pageSize);
                props.updateState({
                  pageSize: pageSize,
                  pageNumber: pageNumber,
                });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                // handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(EmployeeAttendance);
