import React, { Fragment, Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Tooltip, Row, Col } from "antd";
import { validateEmail, getCurrentDateForBackend } from "../../../../../utils";
import { DropdownRef } from "../../../../general/Dropdown";
import { MODAL_TYPE, CONSTANTS } from "../../../../../static/constants";
import { CustomButton } from "../../../../general/CustomButton";
import { TextBox } from "../../../../general/TextBox";
import { getLabelName } from "../../../../modal/modalBody/customerInfo";
import { ErrorMsg } from "../../../../general/ErrorMessage";
import * as find from "lodash.find";
import { QuestionCircleTwoTone } from "@ant-design/icons";
class ContactComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleName: (((props.data || {}).formData || {}).contactData || {})
        .salutationName,
      salutationId: (((props.data || {}).formData || {}).contactData || {})
        .salutationId,
      firstName: (((props.data || {}).formData || {}).contactData || {})
        .firstName,
      contactId: (((props.data || {}).formData || {}).contactData || {})
        .contactId,
      middleName: (((props.data || {}).formData || {}).contactData || {})
        .middleName,
      lastName: (((props.data || {}).formData || {}).contactData || {})
        .lastName,
      preferCallName: (((props.data || {}).formData || {}).contactData || {})
        .preferCallName,
      workPhone: (((props.data || {}).formData || {}).contactData || {})
        .workPhone,
      cellPhone: (((props.data || {}).formData || {}).contactData || {})
        .cellPhone,
      faxNumber: (((props.data || {}).formData || {}).contactData || {})
        .faxNumber,
      email: (((props.data || {}).formData || {}).contactData || {})
        .emailAddress,
      jobTitle: (((props.data || {}).formData || {}).contactData || {})
        .jobTitle,
      department: (((props.data || {}).formData || {}).contactData || {})
        .department,
      isPrimaryContact: (((props.data || {}).formData || {}).contactData || {})
        .isPrimaryContact
        ? true
        : false,
      dateCreated: (((props.data || {}).formData || {}).contactData || {})
        .dateCreated,
      contactType: (((props.data || {}).formData || {}).contactData || {})
        .contactType,
      isDGContact: ((props.data || {}).formData || {}).isDGContact || false,
      countryCallingCode: (props.companyInfo || {}).countryCallingCode
        ? "+" + (props.companyInfo || {}).countryCallingCode
        : "",
      isTeacher: ((props.data || {}).formData || {}).isTeacher,
      primaryContact:
        (((props.data || {}).formData || {}).contactData || {})
          .primaryContact || "father",
    };
  }

  addItemConfirmationModal = (props, text, modalType) => {
    const { companyInfo } = props;
    const labelName = getLabelName(modalType);
    const modalBody = (
      <span>
        <FormattedMessage
          id="addItem.confirmation"
          values={{ value: `${text} to ` }}
          defaultMessage=""
        />
        {labelName} ?
      </span>
    );

    let addFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        addFunc = props.addSalutation;
        break;
      }
      default: {
        addFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        addFunc({ text: text, relationshipId: companyInfo.relationshipId });
        props.popModalFromStack();
      },
    };
    props.pushModalToStack(modalData);
  };

  getModalTitle = (modalType) => {
    let title;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        title = (
          <FormattedMessage id="addItem.text.salutation" defaultMessage="" />
        );
        break;
      }
      default:
        title = (
          <FormattedMessage id="signup.confirmation.title" defaultMessage="" />
        );
    }
    return title;
  };

  handleNewItemAddition = (props, payload, modalType) => {
    const formData = payload.formData;
    // const { showModal } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      this.addItemConfirmationModal(props, formData.textBoxValue, modalType);
    } else {
      const data = {
        title: this.getModalTitle(modalType),
        formData,
        modalData: { modalType, data: payload },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            this.addItemConfirmationModal(
              props,
              formData.textBoxValue,
              modalType
            );
          } else {
            data.formData.submittedOnce = true;
            props.pushModalToStack(data);
          }
        },
      };
      props.pushModalToStack(data);
    }
  };

  deleteClickHandler = (props, modalType, payload) => {
    const { companyInfo } = props;
    const modalBody = (
      <FormattedMessage
        id="deleteItem.confirmation"
        defaultMessage=""
        values={{ value: payload.text }}
      />
    );

    let deleteFunc;

    switch (modalType) {
      case MODAL_TYPE.SALUTATION: {
        deleteFunc = props.deleteSalutation;
        break;
      }
      default: {
        deleteFunc = () => {};
      }
    }

    const modalData = {
      modalBody,
      handleSubmit: () => {
        deleteFunc({
          id: payload.id,
          relationshipId: companyInfo.relationshipId,
        });
      },
    };
    props.pushModalToStack(modalData);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { intl } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 24,
          offset: 0,
        },
      },
    };
    return (
      <Fragment>
        <Form
          labelAlign="left"
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          hideRequiredMark={true}
        >
          <div className="modal-content employee-modal-control contflt">
            <Row gutter={20}>
              <Col span={14}>
                <Form.Item
                  label={
                    <span>
                      <FormattedMessage id="father.name" defaultMessage="" />
                      <span className="required">*</span>
                    </span>
                  }
                >
                  {getFieldDecorator("fatherFirstName", {
                    initialValue: this.state.fatherFirstName || "",
                  })(
                    <TextBox
                      className={(this.state || {}).invalidFatherName}
                      maxLength={25}
                      placeholder={this.props.intl.formatMessage({
                        id: "contact.firstName.placeholder",
                        defaultMessage: "",
                      })}
                      onChange={(e) => {
                        this.setState({
                          fatherFirstName: e.target.value,
                          invalidFatherName: e.target.value
                            ? ""
                            : "input-text-error",
                        });
                      }}
                    />
                  )}
                  <ErrorMsg
                    validator={() => {
                      return (
                        !(this.state || {}).submittedOnce ||
                        !!(this.state || {}).fatherFirstName
                      );
                    }}
                    message="Enter Name"
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item>
                  {getFieldDecorator("fatherLastName", {
                    initialValue: this.state.fatherLastName || "",
                  })(
                    <TextBox
                      maxLength={25}
                      placeholder={this.props.intl.formatMessage({
                        id: "contact.lastName.placeholder",
                        defaultMessage: "",
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span={14}>
                <Form.Item
                  label={
                    <span>
                      <FormattedMessage id="mother.name" defaultMessage="" />
                    </span>
                  }
                >
                  {getFieldDecorator("motherFirstName", {
                    initialValue: this.state.motherFirstName || "",
                  })(
                    <TextBox
                      className={(this.state || {}).invalidMotherName}
                      maxLength={25}
                      placeholder={this.props.intl.formatMessage({
                        id: "contact.firstName.placeholder",
                        defaultMessage: "",
                      })}
                      onChange={(e) => {
                        this.setState({
                          motherFirstName: e.target.value,
                          invalidMotherName: e.target.value
                            ? ""
                            : "input-text-error",
                        });
                      }}
                    />
                  )}
                  <ErrorMsg
                    validator={() => {
                      return (
                        !(this.state || {}).submittedOnce ||
                        !!(this.state || {}).motherFirstName
                      );
                    }}
                    message="Enter Name"
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item>
                  {getFieldDecorator("motherLastName", {
                    initialValue: this.state.motherFirstName || "",
                  })(
                    <TextBox
                      maxLength={25}
                      placeholder={this.props.intl.formatMessage({
                        id: "contact.lastName.placeholder",
                        defaultMessage: "",
                      })}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span>
                      <FormattedMessage
                        id="contact.primary"
                        defaultMessage=""
                      />
                      <Tooltip
                        placement="right"
                        title={
                          <FormattedMessage
                            id="contact.primary.message"
                            defaultMessage=""
                          />
                        }
                      >
                        <QuestionCircleTwoTone />
                      </Tooltip>
                    </span>
                  }
                  colon={false}
                >
                  {getFieldDecorator("primaryContact", {
                    initialValue: (this.state || {}).primaryContact || 0,
                  })(
                    <DropdownRef
                      items={[
                        { name: "Father", id: "father" },
                        { name: "Mother", id: "mother" },
                      ]}
                      placeholder={this.props.intl.formatMessage({
                        id: "primaryContactForInvoicing.text",
                      })}
                      valueKeyName="name"
                      optionKeyName="id"
                      value={this.state.primaryContact}
                      onSelect={(optionValue) => {
                        this.setState({
                          primaryContact: optionValue,
                        });
                        this.props.form.setFieldsValue({
                          primaryContact: optionValue,
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={20}>
              <Col span={24}>
                <Form.Item label={<FormattedMessage id='contact.title' defaultMessage='' />} >

                  <DropdownRef
                    items={this.props.salutations || []}
                    optionKeyName='salutationId'
                    valueKeyName='titleName'
                    canAddNew={true}
                    canDelete={true}
                    deleteClickHandler={(payload) => {
                      this.deleteClickHandler(this.props, MODAL_TYPE.SALUTATION, payload);
                    }}
                    onAddNewClick={(payload) => {
                      payload.formData = {
                        textBoxValue: payload.textEntered,
                        submittedOnce: false
                      }
                      this.handleNewItemAddition(this.props, payload, MODAL_TYPE.SALUTATION)
                    }}
                    onSelect={(selectedValue, option) => {
                      this.setState({
                        titleName: option.name
                      });
                      this.props.form.setFieldsValue({ 'salutationId': selectedValue });
                      this.props.form.setFieldsValue({ 'titleName': option.name });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            */}

            <Row gutter={20}>
              <Col span={24}>
                {" "}
                <Form.Item
                  label={
                    <FormattedMessage
                      id="countryCallingCode.text"
                      defaultMessage=""
                    />
                  }
                  colon={false}
                >
                  {getFieldDecorator("countryCallingCode", {
                    initialValue: this.state.countryCallingCode || "",
                    rules: [
                      {
                        required: true,
                        message: (
                          <FormattedMessage id="countryCallingCode.validationMessage" />
                        ),
                      },
                    ],
                  })(
                    <DropdownRef
                      items={
                        (this.props.countries || []).filter((el) => {
                          return el.countryCallingCode != null;
                        }) || []
                      }
                      optionKeyName="countryId"
                      valueKeyName="countryCallingCode"
                      valueKeyName2="countryName"
                      className="currency-tags"
                      onSelect={(selectedValue, option) => {
                        const selectedCountry = find(this.props.countries, {
                          countryId: selectedValue,
                        });
                        this.setState({
                          countryCallingCode:
                            selectedCountry.countryCallingCode,
                        });
                        this.props.form.setFieldsValue({
                          countryId: selectedValue,
                        });
                        this.props.form.setFieldsValue({
                          countryCallingCode:
                            selectedCountry.countryCallingCode,
                        });
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={
                <span>
                  <FormattedMessage id="contact.mobile" defaultMessage="" />
                  <span className="input-currency-control ltiy">
                    {" "}
                    <span
                      className="currency-tag"
                      style={{
                        left: "119px",
                        top: "-13px",
                        height: "38px",
                        lineHeight: "30px",
                      }}
                    >
                      {this.state.countryCallingCode
                        ? this.state.countryCallingCode
                        : " "}
                    </span>
                  </span>
                </span>
              }
              colon={false}
            >
              {getFieldDecorator("cellPhone", {
                initialValue: this.state.cellPhone || "",
                rules: [
                  {
                    pattern: /^[0-9\b]+$/,
                    message: "Please enter number only",
                  },
                ],
              })(
                <TextBox
                  className={(this.state || {}).invalidCellPhoneClass}
                  style={{ height: "38px", paddingLeft: "40px" }}
                  maxLength={10}
                  placeholder={this.props.intl.formatMessage({
                    id: "contact.mobile.placeholder",
                    defaultMessage: "",
                  })}
                  onChange={(e) => {
                    this.setState({
                      cellPhone: e.target.value,
                      invalidCellPhoneClass:
                        e.target.value?.length === 10 ? "" : "input-text-error",
                    });
                  }}
                />
              )}
              <ErrorMsg
                validator={() => {
                  return (
                    !(this.state || {}).submittedOnce ||
                    !!(this.state || {}).cellPhone
                  );
                }}
                message="invalid contact no"
              />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  <FormattedMessage id="contact.email" defaultMessage="" />
                  <span className="required">*</span>{" "}
                </span>
              }
              colon={false}
            >
              {getFieldDecorator("emailAddress", {
                initialValue: this.state.email || "",
              })(
                <TextBox
                  maxLength={254}
                  className={(this.state || {}).invalidEmailClass}
                  placeholder={this.props.intl.formatMessage({
                    id: "contact.email",
                    defaultMessage: "",
                  })}
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                      invalidEmailClass: validateEmail(e.target.value)
                        ? ""
                        : "input-text-error",
                    });
                  }}
                />
              )}
              <ErrorMsg
                validator={() => {
                  return (
                    !(this.state || {}).submittedOnce ||
                    !!(this.state || {}).email
                  );
                }}
                message={intl.formatMessage({
                  id: "signup.validation.email",
                  defaultMessage: "",
                })}
              />
            </Form.Item>
          </div>
          <Form.Item {...tailFormItemLayout} className="modal-footer">
            <div key={0} className="customModal-button-group">
              <CustomButton
                intlId={"modal.contact.button.cancel"}
                defaultMessage={""}
                type={"default"}
                key={1}
                onClick={() => {
                  this.props.popModalFromStack();
                  this.props.hideModal();
                }}
              />
              <CustomButton
                intlId={"confirmation.submit"}
                defaultMessage={""}
                htmlType="submit"
                key={2}
              />
            </div>
          </Form.Item>
        </Form>
      </Fragment>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.setState({
        submittedOnce: true,
        invalidEmailClass: validateEmail(
          values.emailAddress || this.state.emailAddress
        )
          ? ""
          : "input-text-error",
        invalidFatherName: this.state.fatherFirstName ? "" : "input-text-error",
        invalidMotherName: this.state.motherFirstName ? "" : "input-text-error",
        invalidCellPhoneClass:
          this.state.cellPhone?.length === 10 ? "" : "input-text-error",
      });
      if (
        !err &&
        validateEmail(values.emailAddress) &&
        values.fatherFirstName &&
        values.motherFirstName &&
        values.cellPhone
      ) {
        // const payload = {
        //   salutationName: values.titleName,
        //   salutationId: values.salutationId,
        //   firstName: values.firstName,
        //   middleName: values.middleName,
        //   lastName: values.lastName,
        //   preferCallName: values.preferCallName,
        //   workPhone: values.workPhone ? values.workPhone : null,
        //   contactId: this.state.contactId,
        //   cellPhone: values.cellPhone ? values.cellPhone : null,
        //   faxNumber: values.faxNumber,
        //   emailAddress: values.emailAddress,
        //   jobTitle: values.jobTitle,
        //   department: values.department,
        //   relationshipId: (this.props.companyInfo || {}).relationshipId,
        //   dateCreated: this.state.dateCreated || getCurrentDateForBackend(new Date()),
        //   isPrimaryContact: (values.isPrimaryContact || this.state.isPrimaryContact) ? 1 : 0,
        //   countryCallingCode: values.countryCallingCode,
        //   contactType: this.state.contactType
        // }
        // if(this.props.data && ((this.props.data.formData && this.props.data.formData.customerId) || this.props.data.isDGContact)){
        //   payload.customerId = (this.props.data.formData || {}).customerId;
        //   payload.contactType = CONSTANTS.CONTACT_TYPE_CUSTOMER;
        // }
        // if(this.props.data && this.props.data.formData && this.props.data.formData.supplierId){
        //   payload.supplierId = this.props.data.formData.supplierId;
        //   payload.contactType = CONSTANTS.CONTACT_TYPE_SUPPLIER;
        // }
        // if(this.props.data && this.props.data.formData && this.props.data.formData.isFundraiser){
        //   payload.contactType = CONSTANTS.CONTACT_TYPE_FUNDRAISER;
        //   payload.teamMasterId = this.props.data.formData.teamMasterId
        // }
        // if(this.props.data && this.props.data.formData && this.props.data.formData.isTeacher){
        //   payload.role = 'teacher';
        // }
        let contactList = [];
        let boLocationList = [];
        let contactObj = {};
        contactObj = {
          firstName: values.fatherFirstName,
          lastName: values.fatherLastName,
          cellPhone:
            values.primaryContact === "father" ? values.cellPhone : null,
          emailAddress:
            values.primaryContact === "father" ? values.emailAddress : null,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          dateCreated: getCurrentDateForBackend(new Date()),
          gender: "Male",
          contactType: "Customer",
          motherFirstName: values.motherFirstName,
          motherLastName: values.motherLastName,
          motherMobile:
            values.primaryContact === "mother" ? values.cellPhone : null,
          motherEmail:
            values.primaryContact === "mother" ? values.emailAddress : null,
          // dateOfBirth: props.fatherDOB? formatDateForBackend(props.fatherDOB) : '',
          // motherDateOfBirth: props.motherDOB? formatDateForBackend(props.motherDOB) : '',
          isPrimaryContact: 1,
          primaryContactForInvoicing: values.primaryContact,
          // boContactChildList : boContactChildList,
          contactId: this.state.contactId,
          version: this.state.contactVersion,
          customerId: this.state.customerId,
          countryCallingCode: this.props.companyInfo.countryCallingCode,
        };
        if (
          this.props.data &&
          ((this.props.data.formData && this.props.data.formData.customerId) ||
            this.props.data.isDGContact)
        ) {
          contactObj.customerId = (this.props.data.formData || {}).customerId;
          contactObj.contactType = CONSTANTS.CONTACT_TYPE_CUSTOMER;
        }
        if (
          this.props.data &&
          this.props.data.formData &&
          this.props.data.formData.supplierId
        ) {
          contactObj.supplierId = this.props.data.formData.supplierId;
          contactObj.contactType = CONSTANTS.CONTACT_TYPE_SUPPLIER;
        }
        if (
          this.props.data &&
          this.props.data.formData &&
          this.props.data.formData.isFundraiser
        ) {
          contactObj.contactType = CONSTANTS.CONTACT_TYPE_FUNDRAISER;
          contactObj.teamMasterId = this.props.data.formData.teamMasterId;
        }
        if (
          this.props.data &&
          this.props.data.formData &&
          this.props.data.formData.isTeacher
        ) {
          contactObj.role = "teacher";
        }
        let companyName = "";
        if (values.primaryContact === "father") {
          companyName =
            values.fatherFirstName +
            (values.fatherLastName ? " " + values.fatherLastName : "");
        } else {
          companyName =
            values.motherFirstName +
            (values.motherLastName ? " " + values.motherLastName : "");
        }

        let customerPayload = {
          companyName: companyName,
          boContactList: contactList,
          boLocationList: boLocationList,
          customerId: this.props.customerId || 0,
          relationshipId: (this.props.companyInfo || {}).relationshipId,
          customerType: "individual",
          paymentTermId: 255,
          //isOrgMember: 1,
          membershipOptionId: this.props.membershipOption,
          primaryContact: values.primaryContact,

          fatherFirstName: values.fatherFirstName,
          fatherLastName: values.fatherLastName,
          fatherMobile:
            values.primaryContact === "father" ? values.cellPhone : null,
          fatherEmail:
            values.primaryContact === "father" ? values.emailAddress : null,

          motherFirstName: values.motherFirstName,
          motherLastName: values.motherLastName,
          motherMobile:
            values.primaryContact === "mother" ? values.cellPhone : null,
          motherEmail:
            values.primaryContact === "mother" ? values.emailAddress : null,
        };

        const modalData = {
          modalBody: "Are you sure you want to submit ?",
          handleSubmit: () => {
            this.props.saveOrUpdateCustomer(customerPayload, this.props);
            this.props.handleSubmit && this.props.handleSubmit();
            this.props.popModalFromStack();
          },
        };
        this.props.pushModalToStack(modalData);
      }
    });
  };
}

export default Form.create({ name: "Contact_component" })(
  injectIntl(ContactComponent)
);
