import React from "react";
import { injectIntl } from "react-intl";
import "./index.scss";
import {
  Row,
  Col,
  Divider,
} from "antd";
import { TextBox } from "../../general/TextBox";
import { Dropdown } from "../../general/Dropdown";
import { ErrorMsg } from "../../general/ErrorMessage";


const RegisterAddress = (props) => {
  const {
    formSetting = {},
    address = {},
  } = props;

  const gridColStyle = {
    xs: {
      span: 24,
    },
    lg: {
      span: 12,
    },
  };

  return (
    <>
      {/* address inputs */}
      <Row gutter={[16, 8]}>
        {formSetting.addressDisplay ? (
          <>
            <div className="i-label">
              <span className="bold-title" style={{paddingLeft: '8px'}}>Address</span>
              <span className="required">*</span>
            </div>
            <Col span={24}>
              <Row gutter={[16, 8]}>
                <Col {...gridColStyle}>
                  <div className="i-label">
                    Street Address 1
                    <span className="required">*</span>
                  </div>
                  <TextBox
                    placeholder={props.intl.formatMessage({
                      id: "drawer.customer.address.streetName.placeholder",
                    })}
                    type="text"
                    maxLength={45}
                    value={address.streetAddress1 || ""}
                    onChange={(e) => {
                      props.updateState({
                        address: {
                          ...address,
                          streetAddress1: e.target.value,
                        },
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return (
                        !props.submitClicked || address.streetAddress1
                      );
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>
                <Col {...gridColStyle}>
                  <div className="i-label">Street Address 2</div>
                  <TextBox
                    placeholder={props.intl.formatMessage({
                      id: "drawer.customer.address.streetContd.placeholder",
                    })}
                    type="text"
                    maxLength={45}
                    value={address.streetAddress2 || ""}
                    onChange={(e) => {
                      props.updateState({
                        address: {
                          ...address,
                          streetAddress2: e.target.value,
                        },
                      });
                    }}
                  />
                </Col>

                <Col {...gridColStyle}>
                  <div className="i-label">
                    City<span className="required">*</span>
                  </div>
                  <TextBox
                    placeholder={props.intl.formatMessage({
                      id: "drawer.customer.address.city.placeholder",
                    })}
                    type="text"
                    maxLength={45}
                    value={address.cityName || ""}
                    onChange={(e) => {
                      props.updateState({
                        address: {
                          ...address,
                          cityName: e.target.value,
                        },
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submitClicked || address.cityName;
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>
                <Col {...gridColStyle}>
                  <div className="i-label">
                    Country<span className="required">*</span>
                  </div>
                  <Dropdown
                    items={props.countries || []}
                    optionKeyName="countryId"
                    placeholder={props.intl.formatMessage({
                      id: "drawer.customer.address.country.placeholder",
                    })}
                    valueKeyName="countryName"
                    value={address.countryName || ""}
                    onSelect={(selectedValue, option) => {
                      props.updateState({
                        address: {
                          ...address,
                          countryId: selectedValue,
                          countryName: option.name,
                        },
                      });
                      props.fetchStates(selectedValue);
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return (
                        !props.submitClicked || address.countryName
                      );
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>

                <Col {...gridColStyle}>
                  <div className="i-label">
                    State<span className="required">*</span>
                  </div>
                  {address.countryName === "India" ||
                  address.countryName === "United States" ||
                  address.countryName === "United States (USA)" ? (
                    <Dropdown
                      items={props.states || []}
                      optionKeyName="stateId"
                      placeholder={props.intl.formatMessage({
                        id: "drawer.customer.address.state.placeholder",
                      })}
                      valueKeyName="stateName"
                      value={address.stateName || ""}
                      onSelect={(selectedValue, option) => {
                        // props.updateState({ stateName: option.name });
                        props.updateState({
                          address: {
                            ...address,
                            stateName: option.name,
                          },
                        });
                      }}
                    />
                  ) : (
                    <TextBox
                      placeholder={props.intl.formatMessage({
                        id: "drawer.customer.address.state.placeholder",
                      })}
                      type="text"
                      maxLength={45}
                      value={address.stateName || ""}
                      onChange={(e) => {
                        // props.updateState({ stateName: e.target.value });
                        props.updateState({
                          address: {
                            ...address,
                            stateName: e.target.value,
                          },
                        });
                      }}
                    />
                  )}
                  <ErrorMsg
                    validator={() => {
                      return (
                        !props.submitClicked || address.stateName
                      );
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>
                <Col {...gridColStyle}>
                  <div className="i-label">
                    Zip Code<span className="required">*</span>
                  </div>
                  <TextBox
                    placeholder={props.intl.formatMessage({
                      id: "drawer.customer.address.zipCode.placeholder",
                    })}
                    type="text"
                    maxLength={15}
                    value={address.zipCode || ""}
                    onChange={(e) => {
                      props.updateState({
                        address: {
                          ...address,
                          zipCode: e.target.value,
                        },
                      });
                    }}
                  />
                  <ErrorMsg
                    validator={() => {
                      return !props.submitClicked || address.zipCode;
                    }}
                    message={props.intl.formatMessage({
                      id: "requiredField.missing.msg",
                      defaultMessage: "",
                    })}
                  />
                </Col>
              </Row>
            </Col>
            <Divider />
          </>
        ) : (
          ""
        )}
      </Row>
    </>
  );
};

export default injectIntl(RegisterAddress);
