import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddInventoryItemLisitngComponent from "../../../../../components/modal/modalBody/salesChannel/amazon";

class AddInventoryItemLisitng extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.itemData,
        }
    }

    componentDidMount() {
        // const payload = {
        //     relationshipId: (this.props.companyInfo || {}).relationshipId,
        //     countryId: this.props.countryId
        // };
        //fetchDataIfNeeded("getCountryStates", "countryStates", this.props, payload);
    }

    render() {
        return (
            <AddInventoryItemLisitngComponent {...this.state} {...this.props} updateState={(data) => {
                this.setState(data);
            }} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
       // countryStates: state.taxSettingReducer.countryStates,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryItemLisitng);
