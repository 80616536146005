import axios from 'axios';
import config from '../../../config/environmentConfig';
import { COMMON_ACTIONS, SETTING_ACTION_LIST, SALES_INVOICE_ACTION_LIST } from '../../../static/constants'
import { showToasterMessage } from '../../../utils';
import payment from '../../../components/customer/payment/Listing/payment';
const { lang } = require('../../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');



export const fetchCustomerProformaList = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getAllProformaByCustomerId?&relationshipId=${payload.relationshipId}&customerId=${payload.customerId}`)
        .then(res => {
            dispatch({ type: SALES_INVOICE_ACTION_LIST.CUSTOMER_PROFORMA_INVOICE_LIST, data: res.data });
        })
        .catch((err) => {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
        .finally(() => {
            dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
}

export const fetchProformaDetails = (payload) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LIST_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customerInvoice/getProformaDetailsById?&relationshipId=${payload.relationshipId}&proformaId=${payload.proformaId}`)
        .then(res => {
            dispatch({ type: SALES_INVOICE_ACTION_LIST.INVOICE_PROFORMA_DETAILS, data: res.data });
        })
        .catch((err) => {
            showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        })
        .finally(() => {
            dispatch({ type: COMMON_ACTIONS.HIDE_LIST_LOADER });
        })
    }
}


export const processRazorpayPayment = (payload, props={}) => {
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_SAVE_LOADER });
      return axios
        .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/postPayment/processRazorpayPayment`, payload)
        .then((res) => {
          dispatch({ type: SALES_INVOICE_ACTION_LIST.POST_PAYMENT_DATA, data: res.data });

          if(res.data){
            props.history.push({
              
              pathname: `/payment-status/razorpay`,
              search: `?paymentId=${payload.razorpayPaymentId}`,
              state: {status: 'success', paymentId: payload.razorpayPaymentId ,
                invoiceDetail: JSON.stringify(props.paymentData),
              },
             
              
            });
          } else{
            throw new Error("Something went wrong in after payment process");
          }
        })
        .catch((err) => {
          props.history.push({
            pathname: `/payment-status/razorpay`,
            state: { status: 'error' }
          });
          showToasterMessage({ description: "Some error occurred" });
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_SAVE_LOADER });
        })
  }};
