import React, { Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Pagination, Menu, Checkbox, Skeleton, Tabs, Empty, Avatar, Popover, Divider } from "antd";
import {
  getMomentDateForUIReadOnly,
  showToasterMessage,
  sortTable,
  getUserDefinedName,
  capitalizeFirstLetter,
  getInitialsFromString,
  getAWSFileURL,
} from "../../../utils";
import {
  CONSTANTS,
  MODAL_TYPE,
  CONSTANTS_TRANSACTIONS,
  TEXT_CONSTANTS,
} from "../../../static/constants";
import PageBreadcrumb from "../../PageBreadcrumb";
import AssignSectionToStudent from "../../../containers/modal/modalBody/schoolManagement/AssignSectionToStudent";
import AssignRollNoToStudent from "../../../containers/modal/modalBody/schoolManagement/AssignRollNoToStudent";
import * as find from "lodash.find";
import ClassDetails from "./ClassDetails";
import "./index.scss";
import DropdownAction from "antd/lib/dropdown";
import {
  FilePdfOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MailOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { ICONS } from "../../../static/constants";
import StudentProfile from "../../../containers/modal/modalBody/schoolManagement/StudentProfile";
import UserPic from "../../../assets/images/user.png";
import config from "../../../config/environmentConfig";
import StudentIdCard from "../../modal/modalBody/schoolManagement/StudentProfile/MainComponent/Idcard";
import ImportStudents from "../../../containers/modal/modalBody/schoolManagement/ImportStudents";

const { TabPane } = Tabs;

const Student = (props) => {
  let {
    selectedItems = [],
    updateState,
    companyInfo,
    userInfo,
    schoolStudentsCount,
    schoolStudentList,
    pageNumber,
    xStudentList=[],
    dir = 0,
  } = props;


  if(!props.onTabs){
    xStudentList = schoolStudentList && (schoolStudentList[pageNumber] || []).length ? schoolStudentList[pageNumber] : []
  }

  const breadCrumbList = [
    {
      name: <FormattedMessage id="sidebar.menuItem.school" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.students" defaultMessage="" />,
    },
    {
      name: <FormattedMessage id="sidebar.menuItem.studentsProfile" defaultMessage=""/>,
    },
  ];

  const getPrimaryContact = (rowData) => {
    let primaryContact =
      (rowData.boContacts || []).find((obj) => obj.isPrimaryContact) || {};
    return (
      <>
      <div style={{ display: "flex" }}>
          {(primaryContact.firstName) ? (
            <div className="line-view b-text">
            
               {capitalizeFirstLetter(primaryContact.firstName)}{" "}{primaryContact.lastName || null}
            </div>
          ) : (
            <div className="empty-data-box mb5"></div>
          )}
        </div>
        <div style={{ display: "flex" }}>
          {(primaryContact.cellPhone) ? (
            <>
              <i className={`${ICONS.CALL} mr5`} />{" "}
               {primaryContact.cellPhone}
            </>
          ) : (
            <div className="empty-data-box mb5"></div>
          )}
        </div>

        <div style={{ display: "flex" }}>
          {primaryContact.emailAddress ? (
            <>
              <i className={`${ICONS.MAIL} mr5`} />{" "}
              {primaryContact.emailAddress}
            </>
          ) : (
            <div className="empty-data-box"></div>
          )}

        </div>
      </>
    );
  };

  const showTotal = (total) => {
    return (
      props.intl.formatMessage({
        id: "pagination.text.total",
        defaultMessage: "",
      }) +
      ` ${total || 0} ` +
      props.intl.formatMessage({
        id: "pagination.text.items",
        defaultMessage: "",
      })
    );
  };

  const loadPageData = (pageNumber, pageSize) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      formId: (props.selectedForm || {}).registrationFormSettingId || 0,
    };
    props.getSchoolStudents(payload);
   
  };

  const handlePageSizeChange = (pageNumber, pageSize, props) => {
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: userInfo.relationshipEmployeeId,
      pageNumber: pageNumber || 1,
      pageSize: pageSize || 25,
      formId: (props.selectedForm || {}).registrationFormSettingId || 0,
    };

    props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    props.getSchoolStudents(payload);

  };

  const openClassDetailPopup = (studentObj) => {
    const modalData = {
      title: (
        <FormattedMessage
          id="sidebar.menuItem.classDetails"
          defaultMessage=""
        />
      ),
      modalBody: <ClassDetails {...props} studentObj={studentObj} />,
      width: "50%",
      hideFooter: true,
    };

    props.pushModalToStack(modalData);
  };

  const openAssignSection = (data) => {
    props.pushModalToStack({
      title: (
        <div>
          {getUserDefinedName("Assign Class", props)}
          <div className="light-txt">{`${data.studentName}`}</div>
        </div>
      ),
      modalBody: <AssignSectionToStudent {...props} studentDetail={data} />,
      width: "50%",
      hideFooter: true,
      keyboard: true,
    });
  };

  const openRollNoAssign = (data=[]) => {
    props.pushModalToStack({
      title: "Assign Roll No.",
      modalBody: <AssignRollNoToStudent {...props} studentList={data} />,
      width: "50%",
      hideFooter: true,
      keyboard: true,
    });
  };

  const enrollStudent = (studentList) => {
    const studentIds = studentList
                        .filter(obj=>obj.studentStatus === 'Under Review')
                        .map(obj => obj.schoolStudentId);
    if(studentList?.length === studentIds?.length){
      props.approveAndEnrollStudent(studentIds, props);
    }else{
      showToasterMessage({
        messageType: "warning",
        description: "Some selected students are already enrolled, please verify selected students",
      });
    }
  }

  const addItemConfirmationModal = (props, text, studentData, statusColor) => {
    const { showModal, companyInfo } = props;
    const modalBody = (
      <span>
        <FormattedMessage id="schoolManagement.school.confirmation.create" />
      </span>
    );

    let payload = {
      studentStatus: text,
      studentStatusColor: statusColor || "",
      relationshipId: companyInfo.relationshipId,
      studentRegistrationId: studentData.orgRegistrationId,
      schoolStudentId: studentData.schoolStudentId,
    };

    const modalData = {
      modalBody,
      handleSubmit: () => {
        props.updateStudentUserStatus(payload, props);
        props.hideModal();
        props.popModalFromStack();
      },
    };
    showModal(modalData);
  };

  const updateUserStatus = (props, studentData) => {
    let payload = { formData: {}, txData: studentData };
    const formData = payload.formData;
    const { pushModalToStack } = props;

    if (
      formData.textBoxValue &&
      formData.textBoxValue.length &&
      !payload.existsInData
    ) {
      addItemConfirmationModal(props, formData.textBoxValue, studentData);
    } else {
      const data = {
        title: (
          <FormattedMessage id="addItem.text.userStatus" defaultMessage="" />
        ),
        formData,
        hideFooter: true,
        modalData: {
          modalType: MODAL_TYPE.USER_STATUS,
          data: payload,
          transactionName: CONSTANTS_TRANSACTIONS.STUDENT,
        },
        handleSubmit: (formData = {}) => {
          if (formData.textBoxValue && formData.textBoxValue.length) {
            addItemConfirmationModal(
              props,
              formData.textBoxValue,
              studentData,
              formData.statusColor
            );
          } else {
            data.formData.submittedOnce = true;
            pushModalToStack(data);
          }
        },
      };
      pushModalToStack(data);
    }
  };

  const approveAndGenerateInvoice = (item) => {
    const payload = {
      relationshipId: item.relationshipId,
      createdByEmpId: props.userInfo.relationshipEmployeeId,
      orgRegistrationId: item.orgRegistrationId,
      registrationFormSettingId: item.registrationFormSettingId,
      customerId: item.customerId,
    };

    props.approveAndGenerateInvoice(payload, props);
  };

  const actionMenu = (item) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Action
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="11"
          value="assignSection"
          disabled={item.studentStatus === 'Under Review' || item.studentStatus === 'Pending'}
          onClick={() => openAssignSection(item)}
        >
          <i className={ICONS.ASSIGN} />{" "}
          {getUserDefinedName("Assign Class", props)}
        </Menu.Item>
        {item.isOneTimeFeePaid && (!item.courseFeeStatus || item.courseFeeStatus === null) &&
          userInfo.jobRoleName !== "Teacher" ? (
            <Menu.Item
              key="22"
              value="edit"
              onClick={() => approveAndGenerateInvoice(item)}
            >
              <i className="fi fi-rr-checkbox" />{" "}
              <FormattedMessage id="Approve & Generate Invoice" />
            </Menu.Item>
          ) : (
          ""
        )}

        {/* <Menu.Item
          key="2"
          value="edit"
          onClick={() => updateUserStatus(props, item)}
        >
          <i className={ICONS.EDIT} />{" "}
          <FormattedMessage id="modal.txDetails.common.updateStatus" />
        </Menu.Item> */}

        <Menu.Item
          key="3"
          value="delete"
          onClick={() => {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteStudent(item, props);
              },
            });
          }}
        >
          <i className={ICONS.DELETE} /> <FormattedMessage id="delete.text" />
        </Menu.Item>

        <Menu.Item
          key={"22"}
          disabled={item.studentStatus === 'Under Review' || item.studentStatus === 'Pending'}
          onClick={()=>{
            openRollNoAssign([item]);
          }}
        >
          <i className={ICONS.ASSIGN} /> Assign Roll No.
        </Menu.Item>

        <Menu.Item
          key={"Enroll"}
          disabled={item.studentStatus !== 'Under Review'}
          onClick={()=>{
            props.showModal({
             
              modalBody: <>
               Are you sure you want to enroll <b>{item.studentName}</b> of class <b>{item.className}</b> ?
              </>,
 
              handleSubmit: () => {
                enrollStudent([item]);
              },
            });
          }}
        >
          <i className={ICONS.ASSIGN} /> Enroll Student
        </Menu.Item>

      </Menu>
    );
  };

  const itemSelection = (item) => {
    let selectItem = find(selectedItems, {
      schoolStudentId: Number(item.schoolStudentId),
    });
    let updatedItems = [];
    if (selectItem) {
      updatedItems = selectedItems.filter(
        (obj) => obj.schoolStudentId !== item.schoolStudentId
      );
    } else {
      updatedItems = JSON.parse(JSON.stringify(selectedItems));
      updatedItems.push(item);
    }
    props.updateState({ selectedItems: updatedItems });
  };

  const selectAll = (context) => {
    if (!context) {
      selectedItems.splice(0, selectedItems.length);
      props.updateState({ selectedItems: selectedItems });
      return;
    }
    for (let i = 0; i < schoolStudentList[pageNumber].length; i++) {
      let item = schoolStudentList[pageNumber][i];
      let selectItem = find(selectedItems, {
        schoolStudentId: Number(item.schoolStudentId),
      });
      if (!selectItem) {
        selectedItems.push(item);
      }
    }
    props.updateState({ selectedItems: selectedItems });
  };

  const isCheckedAll = () => {
    if (
      !props.schoolStudentList ||
      !props.schoolStudentList[pageNumber] ||
      props.schoolStudentList[pageNumber].length === 0
    ) {
      return false;
    }
    for (let i = 0; i < props.schoolStudentList[pageNumber].length; i++) {
      let usr = props.schoolStudentList[pageNumber][i];
      let selectItem = find(selectedItems, {
        schoolStudentId: Number(usr.schoolStudentId),
      });
      if (!selectItem) {
        return false;
      }
    }
    return true;
  };

  const sortColumn = (e) => {
    let cellIndex = e.target.cellIndex;
    if (!cellIndex) {
      cellIndex = e.target.parentElement.cellIndex;
    }
    if (!cellIndex) {
      cellIndex = e.target.parentElement.parentElement.cellIndex;
    }
    sortTable(document.getElementById("student-table"), cellIndex, 1 - dir);
    updateState({ dir: 1 - dir });
  };

  const downloadMenu = () => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Download as
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          value="pdf"
          onClick={() => {
            const payload = {
              companyInfo,
              relationshipId: companyInfo.relationshipId,
              formId: (props.selectedForm || {}).registrationFormSettingId || 0,
              relationshipEmployeeId: userInfo.relationshipEmployeeId,
            };
            props.exportStudentExcelData(payload, props);
          }}
        >
          <i className={ICONS.EXCEL} />Excel File
        </Menu.Item>
      </Menu>
    );
  };

  const checkMenu = (e) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item
          key="1"
          onClick={() => {
            selectAll(true);
          }}
        >
          <CheckCircleOutlined />
          Select all
        </Menu.Item>

        <Menu.Item
          key="2"
          onClick={() => {
            selectAll(false);
          }}
        >
          <CloseCircleOutlined />
          Unselect all
        </Menu.Item>

        <Menu.Item
          key={"22"}
          disabled={!(props.selectedItems || []).length}
          onClick={()=>{
            openRollNoAssign(props.selectedItems);
          }}
        >
          <i className={ICONS.ASSIGN} /> Assign Roll No.
        </Menu.Item>

        <Menu.Item
          key={"22"}
          disabled={!(props.selectedItems || []).length}
          onClick={()=>{
            props.showModal({
              
              modalBody: <>
              <div>Are you sure you want to enroll?</div>
              <Divider/>
                <div className="table-container">
                  <table id="student-table">
                    <thead>
                      <tr>
                        <th>Student Name</th>
                        <th>Class</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.selectedItems.map((rowData) => (
                        <tr key={rowData.studentName}>
                          <td>{rowData.studentName}</td>
                          <td>{rowData.className}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </>,
 
                handleSubmit: () => {
                enrollStudent(props.selectedItems)

              },
            });
          }}
         
        >
          <i className={ICONS.ASSIGN} /> Enroll Students
        </Menu.Item>

        <Menu.Item
          key="3"
          onClick={() => {
            const studentList = [];
            selectedItems.forEach((obj) => {
              studentList.push({
                schoolStudentId: obj.schoolStudentId,
                relationshipId: obj.relationshipId,
                subcourseId: obj.subcourseId,
                courseId: obj.courseId,
                orgRegistrationId: obj.orgRegistrationId,
                customerId: obj.customerId,
                contactId: obj.contactId || 0,
                firstName: obj.firstName,
                lastName: obj.lastName,
              });
            });
            if (studentList.length) {
              props.updateState({
                emailDetailDrawerVisible: true,
                isSendBulkInvoice: true,
                isBulkInvoice: true,
                selectedStudentList: studentList,
                docName: "students",
              });
              // props.pushModalToStack(modalData);
            } else {
              showToasterMessage({
                messageType: "error",
                description: props.intl.formatMessage({
                  id: "common.record.notSelected.proceeding",
                }),
              });
            }
          }}
        >
          <MailOutlined />
          Send Email
        </Menu.Item>
      </Menu>
    );
  };

  function onTabChange(key, props) {
    props.updateState({onTabs: true});
    let xStudentList = schoolStudentList && (schoolStudentList[pageNumber] || []).length ? schoolStudentList[pageNumber] : [];
    switch (key) {
      case "all":
        props.updateState({xStudentList: xStudentList});
        break;
      case "financial_aid":
        props.updateState({xStudentList: xStudentList.filter((student => student.isFinancialAssistance === 1))});
        break;
      case "registered":
        props.updateState({xStudentList: xStudentList.filter((student => student.studentStatus === TEXT_CONSTANTS.REGISTERED))});
        break;
      case "enrolled":
        props.updateState({xStudentList: xStudentList.filter((student => student.studentStatus === TEXT_CONSTANTS.ENROLLED))});
        break;
      default:
        // Handle default case
        break;
    }
  }

  const openStudentProfile = (studentData) => {
    const modalData = {
      title: (
        <div>
          <div className="ant-modal-title">Student profile</div>
          <div className="modal-sub-title">
            {(props.selectedForm || {}).formShortName}
          </div>
        </div>
      ),
      modalBody: (
        <StudentProfile
          {...props}
          studentData= {studentData}
          primaryContact ={(studentData.boContacts || []).find((obj) => obj.isPrimaryContact) || {}}
        />
      ),
      hideFooter: true,
      width: "100%",
      height: "100%",
      maskClosable: true,
      keyboard: false,
      wrapClassName: "new-transaction-wrapper",
    };
    props.pushModalToStack(modalData);
  };

  const stickyStyle = {
    position: 'sticky',
    left: '0px',
    backgroundColor: '#ffffff',
    zIndex: 1
  }

  const getBackgroundColor = (name) => {
    const nameLength = name.length;
    const hue = (nameLength * 30 * getAsciiSum(name)) % 360;
    return `hsl(${hue}, 50%, 90%)`;
  };

  function getAsciiSum(str) {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
      sum += str.charCodeAt(i);
    }
    return sum;
  }

  const handleImportClick = (e) => {
    const modalData = {
      title: "Import Students",
      modalBody: <ImportStudents {...props} />,
      hideFooter: true,
      width: "100%",
      handleSubmit: () => {
      },
      wrapClassName: "import-details-modal",
    };
    props.pushModalToStack(modalData);

  };


  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage
              id="sidebar.menuItem.students"
              defaultMessage=""
            />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
        </div>
      </div>
      <div className="view-container">
        <Tabs
          onChange={(key) => {
            onTabChange(key, props);
          }}
          type="line"
          style={{height: '35px'}}
        >
          <TabPane tab="All" key="all" />
          <TabPane tab="Financial Aid" key="financial_aid"/>
          <TabPane tab="Registered" key="registered"/>
          <TabPane tab="Enrolled" key="enrolled"/>
        </Tabs>
    
        <div className="view-container-actions">
        <Skeleton loading={props.headerLoading} paragraph={false} active>

          <div className="left-actions">
            <div className="table-heading">
              <FormattedMessage
                id="schoolManagement.families.student.table.heading"
                defaultMessage=""
              />
            </div>
            {schoolStudentsCount ? (
              <>
                <div className="vertical-sep" />
                <div>{schoolStudentsCount}</div>
              </>
            ) : (
              0
            )}
          </div>
          <div className="right-actions">
            <button
              className="action-btn"
              onClick={() => {
                props.updateState({
                  studentListFilterDrawerVisible: true,
                });
              }}
            >
              <FilterOutlined /> &nbsp;
              <FormattedMessage id="filter.text" defaultMessage="" />
            </button>

            <DropdownAction overlay={downloadMenu()} trigger={["click"]}>
              <span>
                <DownloadOutlined /> &nbsp; Download as
              </span>
            </DropdownAction>

            <button onClick={handleImportClick}><i className={`${ICONS.DOWNLOAD} mr5`} /> Import</button>
          </div>
        </Skeleton>
        </div>

        <Skeleton loading={props.listLoading} paragraph={{ rows: 16 }} active>
          <div className="table-container" style={{height: 'calc(100% - 143px)'}}>
            <table id="student-table">
              <thead>
                <tr>
                  <th style={{...stickyStyle, width: '50px', top:'0px', zIndex:9, paddingRight: '0px'}}>
                    <Checkbox
                      onChange={(e) => {
                        selectAll(e.target.checked);
                      }}
                      checked={isCheckedAll()}
                    />
                    <DropdownAction
                      overlay={checkMenu()}
                      trigger={["click"]}
                    >
                      <i className={`${ICONS.DOWN_ARROW} ml5`} />
                    </DropdownAction>
                  </th>

                  {/* <th width="5%">S.No.</th> */}

                  <th onClick={sortColumn} style={{...stickyStyle, width: '50px', top:'0px', left:'50px', zIndex:9, padding: '10px 10px'}}>
                    ID <i className={ICONS.SORT} />
                  </th>

                  <th onClick={sortColumn} style={{...stickyStyle, width: '180px', left:'100px', top:'0px', zIndex:9, padding: '10px 15px'}}>
                    Student <i className={ICONS.SORT} />
                  </th>

                  <th onClick={sortColumn}>
                    Roll No. <i className={ICONS.SORT} />
                  </th>

                  {/* {userInfo.jobRoleName !== "Teacher" && (
                    <th onClick={sortColumn}>
                      {getUserDefinedName('Department', props)} <i className={ICONS.SORT} />
                    </th>
                  )} */}

                  <th onClick={sortColumn}>
                    {getUserDefinedName('Course', props)} <i className={ICONS.SORT} />
                  </th>

                  <th onClick={sortColumn}>
                    {getUserDefinedName('Class', props)} <i className={ICONS.SORT} />
                  </th>

                  
                  <th onClick={sortColumn}>
                    Contact <i className={ICONS.SORT} />
                  </th>

                  <th onClick={sortColumn}>
                    Allergies <i className={ICONS.SORT} />
                  </th>
            
                  {userInfo.jobRoleName !== "Teacher" && (
                    <th onClick={sortColumn}>
                      Date Created <i className={ICONS.SORT} />
                    </th>
                  )}
  
                  {userInfo.jobRoleName !== "Teacher" && (
                    <th onClick={sortColumn}>
                      Status <i className={ICONS.SORT} />
                    </th>
                  )}

                  {userInfo.jobRoleName !== "Teacher" && (
                    <th style={{ ...stickyStyle, width: '50px', right:'0px', top:'0px', zIndex:9, padding: "10px 10px" }}>
                      <FormattedMessage
                        id="common.listing.header.action"
                        defaultMessage=""
                      />
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                {(xStudentList || []).length ? xStudentList.map((e, i) => {
                  return (
                    <tr key={"std" + i}>                            

                      <td style={{...stickyStyle, width: '50px', paddingRight: '0px'}}>
                        <Checkbox
                          onClick={() => {
                            itemSelection(e);
                          }}
                          checked={
                            find(selectedItems, {
                              schoolStudentId: Number(e.schoolStudentId),
                            })
                              ? true
                              : false
                          }
                        />
                      </td>
                      {/* <td>{(pageNumber - 1) * pageSize + (i + 1)}</td> */}
                  
                      <td style={{...stickyStyle, width: '50px', left:'50px', padding: '10px 10px'}}>{e.schoolStudentId || <div className="empty-data-box"/>}</td>

                      <td style={{...stickyStyle, width: '180px', left:'100px', padding: '10px 15px',height:"78px"}}>
                        <div className="d-flex">

                        <Popover 
                          placement="right" 
                          content={<StudentIdCard {...props} studentData= {e} />}
                          overlayClassName="id_card_pop"
                        >
                          <div className="user-icon mr5" style={{backgroundColor: e.logoAwsObjectKey ? 'transparent' : getBackgroundColor(e.studentName), fontWeight: 400}}>
                            {
                              e.logoAwsObjectKey ? 
                              // <img 
                              //   src={CONSTANTS.BASE_URL_S3 + '/' + config.BUCKET_NAME.BO_RELATIONSHIP + '/' + e.logoAwsObjectKey + "?" + Date.now()}
                              
                              //   alt="pic" height={'36px'} width={'36px'} onError={(e)=>{e.target.src =  UserPic}}
                              // />
                              <img src={
                                getAWSFileURL(e.logoAwsObjectKey ,props.companyInfo.bucketName , "student")
                              } height={'36px'} width={'36px'} onError={(e)=>{e.target.src =  UserPic}}/>
                              :
                                getInitialsFromString(e.studentName || '')
                            }
                          </div>
                        </Popover>
                        <div>
                          <div 
                            className="cursor-pointer b-text"
                            onClick={() => {
                              openStudentProfile(e);
                            }}
                          >
                            {capitalizeFirstLetter(e.studentName || '')}
                          </div>
                          <div className="line-view">
                            {e.age
                              ? ` Age-${e.age}`
                              : e.dateOfBirth
                              ? `DOB - ${getMomentDateForUIReadOnly({
                                  date: new Date(e.dateOfBirth),
                                  format: CONSTANTS.TABLE_DATE_FORMAT,
                                })}`
                              : <div className="empty-data-box"/>}
                          </div>
                          {/* <div className="line-view">DOB - {getMomentDateForUIReadOnly({ date: new Date(e.dob), format:CONSTANTS.TABLE_DATE_FORMAT })}</div> */}
                          <div>{e.gender ? e.gender : <div className="empty-data-box"/>}</div>
                        </div>
                        </div>

                      </td>

                      <td>{e.rollNo || <div className="empty-data"/>}</td>

                      {/* {userInfo.jobRoleName !== "Teacher" && (
                        <td>
                          {e.formId
                            ? (props.studentFormList || []).find(obj=>obj.registrationFormSettingId === e.formId).formName
                            : <div className="empty-data-box"/>}
                        </td>
                      )} */}

                      <td>{e.courseName || <div className="empty-data-box"/>}</td>

                      <td>
                      {e.className
                        ? `${e.className}${e.classSectionId
                            ? ` ${(props.sectionList[1] || []).find(obj => obj.classSectionId === e.classSectionId)?.sectionName || ''}`
                            : ''}`
                        : <div className="empty-data-box"/>}
                      </td>


                      <td>
                        {/* <div className="line-view b-text">{e.primaryName || <div className="empty-data-box"/>}</div>
                        <div>{e.primaryEmail ||""}</div>
                        <div style={{marginTop:"2px"}}>{e.primaryMobileNo || <div className="empty-data-box"/>}</div> */}
                        {getPrimaryContact(e)}
                      </td>
                      <td>
                        {!e.isAllergy ? (
                          "No"
                        ) : (
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              props.showModal({
                                title: "Allergies",
                                modalBody: (
                                  <div
                                    style={{
                                      maxHeight: "550px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {e.allergies || ""}
                                  </div>
                                ),
                                hideFooter: true,
                                width: "40%",
                              });
                            }}
                          >
                            Yes
                          </span>
                          
                        )}
                      </td>
                      {userInfo.jobRoleName !== "Teacher" && (
                        <td className="one-line-view">
                          {e.dateCreated
                            ? getMomentDateForUIReadOnly({
                                date: new Date(e.dateCreated),
                                format: CONSTANTS.TABLE_DATE_FORMAT,
                              })
                            : <div className="empty-data-box"/>}
                        </td>
                      )}
                      <td className="line-view">
                        <span
                          className="status-label"
                          style={{
                            backgroundColor:
                              e.studentStatusColor || "",
                          }}
                        >
                          {e.studentStatus||<div className="empty-data-box"/>}
                        </span>
                      </td>

                      {userInfo.jobRoleName !== "Teacher" && (
                        <td style={{ paddingLeft: "25px", ...stickyStyle, right:'0px' }}>
                          <DropdownAction
                            overlay={actionMenu(e)}
                            trigger={["click"]}
                          >
                            <i className={ICONS.MORE} />
                          </DropdownAction>
                        </td>
                      )}
                    </tr>
                  );
                  // })
                })
                :
                <tr key="empty-data">
                  <td colSpan={"100%"} style={{borderBottom:"none"}}>
                    <Empty />
                  </td>
              </tr>
              }
              </tbody>
            </table>
          </div>
        </Skeleton>
        <div className="footer">
        <Skeleton loading={props.headerLoading} paragraph={false} active>
          <div className="f-left"></div>
          <div className="f-right">
            <Pagination
              size="small"
              total={schoolStudentsCount}
              showTotal={showTotal}
              defaultPageSize={props.pageSize}
              showSizeChanger
              pageSizeOptions={[10, 25, 50, 100, 200]}
              // showQuickJumper
              onChange={(pageNumber, pageSize) => {
                loadPageData(pageNumber, pageSize);
                updateState({ pageSize: pageSize, pageNumber: pageNumber });
              }}
              onShowSizeChange={(pageNumber, pageSize) => {
                handlePageSizeChange(pageNumber || 1, pageSize, props);
              }}
            />
          </div>
        </Skeleton>
        </div>
      </div>
    </>
  );
};

export default injectIntl(Student);
