import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SubjectComp from "../../../components/schoolManagement/subject";
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from "../../../utils";
import {
  getSubject,
  getSubjectCount,
  saveSubject,
  deleteSubject,
  resetPaginatedData,
  fetchSubjectListByFilter,
} from "./action";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from "./../../../actions/commonActions";
import SubjectListFilterComp from "../../../components/schoolManagement/subject/SubjectListFilter";

class Subject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 25,
    };
  }

  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      relationshipEmployeeId: (this.props.userInfo || {})
        .relationshipEmployeeId,
      pageNumber: 1,
      pageSize: 25,
    };
    fetchPaginationDataIfNeeded(
      "getSubject",
      "subjectList",
      this.props,
      payload
    );
    fetchDataIfNeeded("getSubjectCount", "subjectCount", this.props, payload);
  }

  render() {
    return (
      <>
        <SubjectComp
          {...this.props}
          {...this.state}
          updateState={(data) => {
            this.setState(data);
          }}
        />
        {this.state.subjectListFilterDrawerVisible ? 
          <SubjectListFilterComp
            {...this.state}
            {...this.props}
            updateState={(data) => {
              this.setState(data);
            }}
          />
          :
          ''
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    subjectList: state.school.subjectList,
    subjectCount: state.school.subjectCount,
    showSkeleton: state.common.showSkeleton,
    listLoading: state.common.listLoading,
    headerLoading: state.common.headerLoading
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSubject,
      getSubjectCount,
      deleteSubject,
      saveSubject,
      resetPaginatedData,
      showModal,
      hideModal,
      fetchSubjectListByFilter,
      pushModalToStack, 
      popModalFromStack,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Subject);
