import React, { useEffect } from "react";
import '../index.scss';
import { Button, Result } from "antd";
import queryString from 'query-string';
import StripePaymentStatus from "../Stripe/PaymentStatus";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { generatePDF, printPDF } from "../../../../actions/downloadFileAction";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { fetchProformaDetails } from "../../../../containers/B2B/ProformaPayment/action";
import { fetchRelationshipDataByRID } from "../../../../containers/B2B/Register/action";


const PaymentStatus = (props) =>{

  

    useEffect(() => {
        // const payload = {
        //     relationshipId: props.companyInfo?.relationshipId || props.customerDetail?.relationshipId,
        //     proformaId: props.proformaDetails?.invoiceProformaId || 0,
        //     // customerId: this.state.customerId
        //   }
        // props.fetchProformaDetails(payload);
          },[]);
    const linkProps = queryString.parse(decodeURIComponent(props.location.search)) || {};
    const { paymentGateway } = useParams();
    const location = useLocation();
    
    const stateData = location.state || {};
    const getPayloadForInvoicePdf = () => {
        return {
          fileName: ((props.registrationFormData || {})?.companyName) +" " + 'Invoice.pdf',
          templateName: "Receipt", 
          transactionName: 'Receipt',
          paymentData:props.paymentData,
          registrationFormData:props.registrationFormData,
        //   companyInfo: props.companyInfo,
        //   billingAddress: billingAddress,
        //   primaryContact: primaryContact,
        //   paidInvoiceList: JSON.stringify(paidInvoiceList),
        //   relationshipAddress: relationshipAddress,
        //   paidInvoicesTotalAmount: props.paidInvoicesTotalAmount,
        //   companyName:props.companyName
        }
      }
      const generateInvoicePDF = () => {
        let payload = getPayloadForInvoicePdf();

        props.printPDF(payload);
      }

    const getStripePaymentStatus = (status)=>{
        switch (status) {
            case 'succeeded':
                return <Result
                    status="success"
                    title="Payment done successfully"
                    // extra={[
                    //     <Button key="close" onClick={()=>{window.close()}}>Close</Button>,
                    // ]}
                />
  
            case 'processing':
                return <Result
                    title="Payment processing. We'll update you when payment is received."
                    extra={[
                        // <Button key="close">Close</Button>,
                    ]}
                />
  
            case 'requires_payment_method':
                return <Result
                    status="500"
                    title="Payment failed."
                    subTitle={"Please try another payment method."}
                    extra={[
                        <Button key="close">Retry</Button>,
                    ]}
                />
  
            default:
                return <Result
                    status="error"
                    title="Payment failed."
                    subTitle={"Please try again for payment"}
                    extra={[
                        <Button key="close">Retry</Button>,
                    ]}
                />
          }
    }


    return (
        paymentGateway === 'razorpay' && stateData?.status === 'success' && linkProps.paymentId ? 
            <div className="pay_container">
                <Result
                    status="success"
                    title="Payment done successfully"
                    subTitle={`Payment Id: ${linkProps.paymentId || '-'}`}
                    // extra={[
                    //     <Button type="primary" key="dowload_invoice" onClick={() => {
                    //         generateInvoicePDF();
                    //       }}> 
                    //         Download Invoice
                    //     </Button>,
                    //     // <Button key="close">Close</Button>,
                    // ]}
                />
            </div>
            :
        paymentGateway === 'stripe' && linkProps.redirect_status ? 
            <div className="pay_container">
                {getStripePaymentStatus(linkProps.redirect_status)}
            </div>

            :
            <Result
                status="500"
                title="500"
                subTitle={<>
                    <div>Sorry, something went wrong.</div>
                </>
                }
                extra={<Button type="primary">Back Home</Button>}
            /> 


    ) 
    
}
const mapStateToProps = (state) => {
    return {
      companyInfo: state.settings.relationshipData,
      customerProformaList: state.salesInvoice.customerProformaList,
      proformaDetails: state.salesInvoice.proformaDetails,
      customerDetail: state.common.customerDetail,
      razorpayPaymentOrderDetail: state.common.razorpayPaymentOrderDetail,
      paymentData:state.salesInvoice.paymentData,
      registrationFormData: state.settings.registrationFormData
    }
  }
  const mapDispatchToProps = (dispatch) => bindActionCreators({
  
    generatePDF,
    printPDF,
    fetchProformaDetails

  }, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(PaymentStatus);