import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserStatusCompo from '../../../../../components/modal/modalBody/common/UserStatus';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../../../../actions/commonActions';
import { createStatus, fetchAllStatus, deleteStatus, resetUserStatusList } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';

class UserStatusContainer extends Component {
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      transactionName: this.props.transactionName,
    };
    this.props.resetUserStatusList();
    fetchDataIfNeeded('fetchAllStatus', 'userStatus', this.props, payload);
  }

  render() {
    return (
      <UserStatusCompo {...this.props} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    userStatus: state.common.userStatus,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createStatus, 
  fetchAllStatus, 
  deleteStatus,
  resetUserStatusList,
  pushModalToStack,
  popModalFromStack,
  showModal,
  hideModal,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserStatusContainer);
