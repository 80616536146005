import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShipmentListingComp from '../../../../components/customer/shipment/Listing';
import ShipmentFilterComp from '../../../../components/customer/shipment/Listing/shipmentFilter';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchAllShipment, getShipmentCount, resetPaginatedData, deleteShipment, updateUserStatus, fetchAllShipmentByFilter, getShipmentCountByFilter } from './action';
import { showModal, pushModalToStack, hideModal, popModalFromStack, fetchUserPreferences, saveUserPrefrences } from '../../../../actions/commonActions';
import { markAsDelivered, updateActualDeliveryDate } from '../../../modal/modalBody/customer/ShipmentInformation/action';
import { fetchCustomers } from '../../Listing/action';
import { fetchAllStatus } from '../../../modal/modalBody/common/UserStatus/action'
import { FormattedMessage } from 'react-intl';
import * as find from 'lodash.find';

class shipmentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {    
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      txPreferences: { txType: "shipment", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['shipmentNumber', 'customerName', 'shipmentDate', 'totalQtyShipped', 'expectedDeliveryDate', 'status', 'isStockUpdated'],
      txAvailableColumn: {
        shipmentNumber: true,
        customerName: true,
        carrierName: true,
        trackingNumber: true,
        expectedDeliveryDate: true,
        shipmentDate: true,
        totalQtyShipped: true, 
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
       // totalPackages: true,
        status: true, 
        isStockUpdated: true,
        comments: true,
        consigneeName: true,
        actualDeliveryDate: true,
        freightType: true,
        remarksCustomer: true,
        userStatus: true,
        referenceNumber: true,
        shippingLabel: true,
        trackShipment: true
      },
    };
  }

  componentDidMount() {
    this.props.updateHeaderState({
      collapsed: true
    });
    this.props.updateHeaderState({pageHeading: <FormattedMessage id='heading.sales.shipment' defaultMessage='' />})
    this.props.updateHeaderState({breadCrumbList : [
      {
          name: <FormattedMessage id='sidebar.menuItem.sales' defaultMessage='' />
      },
      {
          name: <FormattedMessage id='sidebar.menuItem.shipment' defaultMessage='' />,
      }
    ]})
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
      transactionName: 'Shipment',
      isCompact: 1
    };
    fetchPaginationDataIfNeeded('fetchAllShipment', 'shipmentList', this.props, payload);
    fetchDataIfNeeded('getShipmentCount', 'shipmentCount', this.props, payload);
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    this.props.fetchCustomers(payload);
    this.props.fetchAllStatus(payload);
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "shipment", templateName: "standard" });
      if (txPreferences && txPreferences.tablePreference) {
        let txColumns = ((JSON.parse(txPreferences.tablePreference)) || {}).columnList;
        txColumns = txColumns.length ? txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description');
        const txColumnSetting = ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false: true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        

        {this.state.shipmentFilterDrawerVisible && <ShipmentFilterComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
  
        <ShipmentListingComp {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>
      )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    allPreferences: state.common.allPreferences,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    shipmentList: state.shipment.shipmentList,
    shipmentCount: state.shipment.shipmentCount,
    customers: state.customer.customers,
    userStatus: state.common.userStatus,
    listLoading:state.common.listLoading,
    headerLoading: state.common.headerLoading
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchAllShipment, 
  getShipmentCount,
  resetPaginatedData,
  showModal,
  popModalFromStack,
  pushModalToStack,
  deleteShipment,
  hideModal,
  fetchUserPreferences,
  saveUserPrefrences,
  markAsDelivered,
  updateActualDeliveryDate,
  updateUserStatus,
  fetchCustomers,
  fetchAllShipmentByFilter,
  getShipmentCountByFilter,
  fetchAllStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(shipmentListing);
