import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Skeleton, Pagination, Popover, Tooltip, Empty, Input } from 'antd';
import { fetchPaginationDataIfNeeded, getMomentDateForUIReadOnly, sortTable } from '../../../../utils'
import ReactHtmlParser from 'react-html-parser';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import './index.scss';
import { CONSTANTS } from '../../../../static/constants';
import { AddAndRemoveCustomerListingColumn } from "../../../general/AddAndRemoveCustomerListingColumn";
import SODetail from '../../../../containers/modal/modalBody/customer/SODetail';
import { InsertRowRightOutlined } from '@ant-design/icons';
import SalesComplaintStatus from './status';
import * as debounce from "lodash.debounce";
import ProductList from '../../../modal/modalBody/customer/SalesComplaint/product'
import CustomerProfile from '../../../../containers/modal/modalBody/customer/Profile'
import SalesInvoiceDetails from "../../../../containers/modal/modalBody/customer/SalesInvoiceDetail";
const { Search } = Input;

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const ClosedComplaintsListingComp = (props) => {
    const { salesComplaintData, updateState, companyInfo, complaintCount, pageNumber, searchedText, pageSize, dir = 0 } = props;
    const _isColumnToHide = (columnName) => {
        return (props.txColumns.indexOf(columnName) < 0 && props.txMandatoryColumns.indexOf(columnName) < 0) ? true : false;
    }
    const sortColumn = (e) => {
        sortTable(document.getElementById('sales-support-table'), e.target.cellIndex, 1 - dir);
        updateState({ dir: 1 - dir })
    }
    const openSoDetails = (data) => {
        const soPayload = {
            customerId: data.customerId,
            salesOrderMasterId: data.soMasterId
        }
        props.pushModalToStack({
            modalBody: <SODetail {...props} soPayload={soPayload} />,
            width: '100%',
            hideFooter: true,
            wrapClassName: 'modal-custom-detail'
        })
    }

    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        // fetchPaginationDataIfNeeded('fetchCreditMemos', 'salesComplaintList', props, payload);
        props.fetchComplaints(payload, props);

    }

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = { companyInfo, relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize };
        props.resetPaginatedData();
        props.fetchComplaints(payload, props);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber })
    }
    const openSalesInvoiceDetails = (data) => {
        const invoicePayload = {
            customerId: data.customerId,
            invoiceMasterId: data.invoiceMasterId,
        };
        props.pushModalToStack({
            modalBody: (
                <SalesInvoiceDetails {...props} invoicePayload={invoicePayload} />
            ),
            width: "100%",
            hideFooter: true,
            keyboard: true,
            wrapClassName: "modal-custom-detail",
        });
    };
    const onSearchedInputChange = debounce((searchedText, props) => {
        handleSearchedText(searchedText, props);
    }, 500);

    const handleSearchedText = (searchedText, props) => {
        const payload = {
            relationshipId: props.companyInfo.relationshipId,
            pageNumber: 1,
            pageSize: props.pageSize,
            searchedText: searchedText,
        };
        if (searchedText.length) {
            if (
                searchedText.length >= 3 &&
                !props.searchedText.toLowerCase().startsWith(searchedText.toLowerCase())
            ) {
                props.resetPaginatedData();
                props.fetchComplaints(payload, props);
            }
        } else {
            props.resetPaginatedData();
            props.fetchComplaints(payload, props);
        }
    };
    const openLongtText = (title, Text) => {
        props.showModal({
            title: title,
            modalBody: (
                <div style={{ maxHeight: "550px", overflow: "auto" }}>
                    {ReactHtmlParser(Text)}
                </div>
            ),
            hideFooter: true,
            width: "70%",
        });
    }; function removeTags(html) {
        return html.replace(/<[^>]+>/g, '');
    }
    return (
        <>
            <div className="view-container-actions">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="left-actions">
                        <div className="table-heading">
                            Closed Complaints List
                        </div>
                        {
                            pageSize ? <>
                                <div className="vertical-sep" />
                                <div>{complaintCount}</div>
                            </> : ''
                        }
                    </div>
                    <div className="right-actions">
                        <Search
                            placeholder="Enter 3 Characters complaint# and customer name"
                            value={searchedText}
                            onChange={(e) => {
                                updateState({ searchedText: e.target.value, pageNumber: 1 });
                                onSearchedInputChange(e.target.value, props);
                            }}
                            onSearch={(searchedText) => {
                                updateState({ searchedText, pageNumber: 1 });
                                onSearchedInputChange(searchedText, props);
                                handleSearchedText(searchedText, props);
                            }}
                            className="search-col"
                        />
                        <Popover
                            content={<AddAndRemoveCustomerListingColumn {...props} />}
                            title={<div className="text-center">Add/Remove</div>}
                            placement="leftTop"
                            trigger="click"
                            arrow="right"
                            visible={props.showColPop}
                        >
                            <button onClick={() => { props.updateState({ showColPop: !props.showColPop }) }}>
                                <InsertRowRightOutlined className='mr5' /> Columns
                            </button>
                        </Popover>

                    </div>
                </Skeleton>
            </div>
            <div className='table-container'>
                <Skeleton loading={props.listLoading} paragraph={{ rows: 16 }} active>

                    <table id='sales-support-table'>
                        <thead>
                            <tr>
                                <th>S. No.</th>
                                {!_isColumnToHide('salesComplaintNumber') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesComplaint.listing.header.salesComplaintNumber' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('customerName') ? <th onClick={sortColumn}><FormattedMessage id='common.customer' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('soNumber') ? <th onClick={sortColumn}><FormattedMessage id='common.soNumber' defaultMessage='' />/Invoice #</th> : ''}
                                <th><FormattedMessage id='common.item' defaultMessage='' /></th>
                                {!_isColumnToHide('complaintType') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesComplaint.listing.header.complaintType' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('status') ? <th onClick={sortColumn}><FormattedMessage id='common.status' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('complaintDetails') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesComplaint.form.gridHeader.complaintDetails' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('assignedTo') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesComplaint.listing.header.assignedTo' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('referenceNumber') ? <th onClick={sortColumn}><FormattedMessage id='common.ref' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('priority') ? <th onClick={sortColumn}><FormattedMessage id='common.priority' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('dateCreated') ? <th onClick={sortColumn}><FormattedMessage id='common.dateCreated' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('dueDate') ? <th onClick={sortColumn}><FormattedMessage id='common.dueDate' defaultMessage='' /></th> : ''}
                                {!_isColumnToHide('complaintClosedDate') ? <th onClick={sortColumn}><FormattedMessage id='customer.salesComplaint.listing.header.complaintClosedDate' defaultMessage='' /></th> : ''}

                            </tr>
                        </thead>
                        <tbody>

                            {(salesComplaintData[pageNumber] || []).length ?
                                salesComplaintData[pageNumber].map((rowData, index) => (

                                    <tr key={index}>
                                        <td>{(pageNumber - 1) * pageSize + (index + 1)}</td>

                                        {!_isColumnToHide('salesComplaintNumber') ? <td>
                                            {rowData.salesComplaintNumber || <div className="empty-data"></div>}
                                        </td> : ''}
                                        {!_isColumnToHide('customerName') ? <td>
                                            <div className="cursor-pointer" >
                                                <div onClick={() => {
                                                    props.pushModalToStack({

                                                        modalBody: <CustomerProfile {...props}
                                                            customerId={rowData.customerId}
                                                        //   customerName={rowData.customerName}
                                                        //   customerDetails={rowData}
                                                        />,
                                                        width: '100%',
                                                        hideTitle: true,
                                                        hideFooter: true,
                                                        wrapClassName: "new-transaction-wrapper",
                                                    })
                                                }}>{rowData.customerName}</div>
                                            </div>
                                        </td> : ''}
                                        {!_isColumnToHide('soNumber') ? <td>
                                            {rowData.salesOrderNumber ?
                                                <div className="cursor-pointer" onClick={() => {

                                                    openSoDetails(rowData)
                                                }}>{rowData.salesOrderNumber}</div>
                                                :
                                                <div className="cursor-pointer" onClick={() => {

                                                    openSalesInvoiceDetails(rowData);
                                                }}>{rowData.invoiceNumber}</div>}
                                        </td> : ''}
                                        <td className='text-center'>
                                            <span
                                                onClick={() => {
                                                    if ((rowData.customerSalesComplainItemRef || []).length !== 0) {

                                                        props.showModal({
                                                            title: "Product",
                                                            modalBody: (
                                                                <ProductList
                                                                    productData={rowData}
                                                                    {...props}
                                                                />
                                                            ),
                                                            width: 500,
                                                            hideFooter: true,
                                                            wrapClassName: "tiles-modal",
                                                        });
                                                    }
                                                }}
                                                className={
                                                    (rowData.customerSalesComplainItemRef || []).length
                                                        ? "link-text"
                                                        : <div className="empty-data"></div>

                                                }
                                            >

                                                {(rowData.customerSalesComplainItemRef || []).length}
                                            </span>
                                        </td>
                                        {!_isColumnToHide('complaintType') ? <td>
                                            {rowData.salesComplaintType || <div className="empty-data"></div>}
                                        </td> : ''}
                                        {!_isColumnToHide('status') && (
                                            <td className='line-view'>
                                                <div
                                                    className=
                                                    "status-code cursor-pointer"

                                                    onClick={() => {
                                                        props.pushModalToStack({
                                                            modalBody: <SalesComplaintStatus {...props} rowData={rowData} />,
                                                            title:
                                                                " Status"
                                                            ,
                                                            width: 500,
                                                            hideFooter: true,
                                                            // wrapClassName: "brevo-modal",
                                                        });


                                                    }}
                                                >
                                                    <span>
                                                        <FormattedMessage id={rowData.statusName || 'open'} />
                                                    </span>
                                                </div>
                                            </td>
                                        )}
                                        {!_isColumnToHide('complaintDetails') ? <td>
                                            {
                                                rowData.complaintDetails ? (<div>
                                                    {
                                                        (removeTags(rowData.complaintDetails) || '')?.length < 20 ?
                                                            (<span

                                                            >{ReactHtmlParser(rowData.complaintDetails)}</span>) : (<div><span

                                                            >{removeTags(rowData.complaintDetails).slice(0, 20)}</span><span className="more-details-btn" onClick={() => { openLongtText('Complaint Details', rowData.complaintDetails) }}>Show More</span></div>)
                                                    }


                                                </div>) : <div className="empty-data"></div>
                                            }
                                        </td> : ''}
                                        {!_isColumnToHide('assignedTo') ? <td>
                                            {rowData.assignedUserName || <div className="empty-data"></div>}
                                        </td> : ''}



                                        {!_isColumnToHide('referenceNumber') ? <td>
                                            {rowData.refNumber || <div className="empty-data"></div>}
                                        </td> : ''}
                                        {!_isColumnToHide('priority') ? <td>
                                            {rowData.priority || <div className="empty-data"></div>}
                                        </td> : ''}
                                        {!_isColumnToHide('dateCreated') ? <td>
                                            <div>
                                                <Tooltip placement="topLeft" title={(rowData.dateCreated && getMomentDateForUIReadOnly({ date: rowData.dateCreated, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                    {getMomentDateForUIReadOnly({ date: new Date(rowData.dateCreated), format })}
                                                </Tooltip>
                                            </div>
                                        </td> : ''}
                                        {!_isColumnToHide('dueDate') ? <td>
                                            <div>
                                                <Tooltip placement="topLeft" title={(rowData.dueDate && getMomentDateForUIReadOnly({ date: rowData.dueDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                    {getMomentDateForUIReadOnly({ date: new Date(rowData.dueDate), format })}
                                                </Tooltip>
                                            </div>
                                        </td> : ''}

                                        {!_isColumnToHide('complaintClosedDate') ? <td>
                                            <div>
                                                <Tooltip placement="topLeft" title={(rowData.complaintClosedDate && getMomentDateForUIReadOnly({ date: rowData.complaintClosedDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                                    {rowData.complaintClosedDate ? getMomentDateForUIReadOnly({ date: new Date(rowData.complaintClosedDate), format }) : <div className="empty-data"></div>}
                                                </Tooltip>
                                            </div>
                                        </td> : ''}

                                    </tr>

                                )) : <tr key="empty-data-box">
                                    <td colSpan={"100%"} style={{ borderBottom: "none" }}>
                                        <Empty />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </Skeleton>
            </div>
            <div className="footer">
                <Skeleton loading={props.headerLoading} paragraph={false} active>

                    <div className="f-left"></div>
                    <div className="f-right">
                        <Pagination
                            size="small"
                            total={complaintCount}
                            showTotal={showTotal}
                            defaultPageSize={props.pageSize} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                            // showQuickJumper
                            onChange={(pageNumber, pageSize) => {
                                loadPageData(pageNumber, pageSize);
                                updateState({ pageSize: pageSize, pageNumber: pageNumber })
                            }}
                            onShowSizeChange={(pageNumber, pageSize) => {
                                handlePageSizeChange(pageNumber || 1, pageSize, props)
                            }}
                        />
                    </div>
                </Skeleton>
            </div>

        </>
    )
}
export default injectIntl(ClosedComplaintsListingComp);