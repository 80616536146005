import { INQUIRY_ACTION_LIST } from '../static/constants';
const initialState = {
  enquiryList: {
    1: []
  },
  enquiryCount: 0,
  enquiryData: {},
  enquiryDetail: {},
  enquiryStatusList: [],
  enquiryPriceCodes: [],
  nextEnquiryNumber: '',
  inquiryDraftList: {
    1: []
  },
  inquiryDraftCount: 0,
}

const SalesInquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INQUIRY_ACTION_LIST.ENQUIRY_LIST:
      return {
        ...state,
        enquiryList: {
          ...state.enquiryList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case INQUIRY_ACTION_LIST.ENQUIRY_COUNT:
      return { ...state, enquiryCount: action.data };
    case INQUIRY_ACTION_LIST.ENQUIRY_LIST_PAGE_RESET:
      return { ...state, enquiryList: initialState.enquiryList };
    case INQUIRY_ACTION_LIST.ENQUIRY_DETAIL:
      return { ...state, enquiryDetail: action.data };
    case INQUIRY_ACTION_LIST.ENQUIRY_Data:
      return { ...state, enquiryData: action.data };
    case INQUIRY_ACTION_LIST.RESET_ENQUIRY_DETAIL:
      return { ...state, enquiryDetail: initialState.enquiryDetail,enquiryData: initialState.enquiryData };
    case INQUIRY_ACTION_LIST.ENQUIRY_STATUS_LIST:
      return { ...state, enquiryStatusList: action.data };
    case INQUIRY_ACTION_LIST.ENQUIRY_STATUS_LIST_ADDITION:
      return { ...state, enquiryStatusList: [action.data, ...state.enquiryStatusList] }
    case INQUIRY_ACTION_LIST.ENQUIRY_STATUS_LIST_DELETION:
      return { ...state, enquiryStatusList: state.enquiryStatusList.filter(x => x.enquiryStatusId !== action.data.id) }
    case INQUIRY_ACTION_LIST.ENQUIRY_PRICE_CODES:
      return { ...state, enquiryPriceCodes: action.data };
    case INQUIRY_ACTION_LIST.ENQUIRY_PRICE_CODES_ADDITION:
      return { ...state, enquiryPriceCodes: [action.data, ...state.enquiryPriceCodes] }
    case INQUIRY_ACTION_LIST.NEXT_ENQUIRY_NUMBER:
      return { ...state, nextEnquiryNumber: action.data };
    case INQUIRY_ACTION_LIST.NEXT_ENQUIRY_NUMBER_RESET:
      return { ...state, nextEnquiryNumber: '' };
    case INQUIRY_ACTION_LIST.INQUIRY_DRAFT_LIST:
      return {
        ...state,
        inquiryDraftList: {
          ...state.inquiryDraftList,
          [action.data.pageNo || 1]: action.data.list
        }
      };
    case INQUIRY_ACTION_LIST.INQUIRY_DRAFT_COUNT:
      return { ...state, inquiryDraftCount: action.data };
    case INQUIRY_ACTION_LIST.INQUIRY_DRAFT_LIST_PAGE_RESET:
      return { ...state, inquiryDraftList: initialState.inquiryDraftList }; 
    default:
      return state;
  }
};

export default SalesInquiryReducer;