import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReminderSetupComponent from '../../../../../components/modal/modalBody/customer/ReminderSetup';
import EmailComponent from '../../../../../containers/drawer/recurringInvoiceEmail';
import { saveInvoiceReminder, getInvoiceReminderSetupByInvoiceMasterId, resetInvoiceReminderSetup} from './action';
import { deleteInvoiceReminder } from '../../../../customer/invoice/Listing/action';

class ReminderSetup extends Component {

    constructor(props){
        super(props);
        this.state= {
            emaildrawerVisible: false,
            subject: '',
            emailBody: '',
            remindDuration: 'before',
            customerId: props.salesInvoiceDetail?.customerId,
            isBulkInvoice: props.isBulkInvoice || false
        }
    }
    componentDidMount() {
         const companyInfo = this.props.companyInfo || {};
         const payload = { relationshipId: companyInfo.relationshipId, customerId: this.props.salesInvoiceDetail?.customerId, invoiceMasterId: this.props.salesInvoiceDetail?.invoiceMasterId };
         (!this.state.isBulkInvoice && this.props.salesInvoiceDetail && this.props.salesInvoiceDetail.invoiceMasterId) && this.props.getInvoiceReminderSetupByInvoiceMasterId(payload);
    }

    componentWillReceiveProps(props) {
    }
    render() {
        return <div>
            {this.state.emailDetailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <ReminderSetupComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        companyInfo: state.common.companyInfo,
        relationshipId: state.common.companyInfo.relationshipId,
        salesInvoiceDetail: state.salesInvoice.salesInvoiceDetail,
        userInfo: state.common.userInfo,
        reminderList: state.salesInvoice.invoiceReminderSetup
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    saveInvoiceReminder, 
    getInvoiceReminderSetupByInvoiceMasterId, 
    resetInvoiceReminderSetup,
    deleteInvoiceReminder
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReminderSetup);