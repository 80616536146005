import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PurchaseRequestListingComponent from '../../../../components/supplier/purchaseRequest/Listing';
import { fetchDataIfNeeded, fetchPaginationDataIfNeeded } from '../../../../utils';
import { fetchPurchaseRequests, getPurchaseRequestsCount, resetPaginatedData, fetchPurchaseRequestDrafts, getPurchaseRequestDraftCount, resetDraftPaginatedData, deletePurchaseRequest, updateUserStatus, fetchPurchaseRequestsByFilter, getPurchaseRequestsCountByFilter } from './action';
import { showModal, hideModal, pushModalToStack, popModalFromStack, fetchUserPreferences, saveUserPrefrences, deleteAuditTrail,  createAuditTrail, fetchProjects, fetchAllEmployees } from '../../../../actions/commonActions';
import { fetchSuppliers } from '../../Listing/action';
import { fetchAllStatus } from '../../../modal/modalBody/common/UserStatus/action';
import * as find from 'lodash.find';
import AuditTimeLine from '../../../../components/drawer/AuditTimeLine';
import { FormattedMessage } from 'react-intl';
import PurchaseRequestFiltersComp from '../../../../components/supplier/purchaseRequest/Listing/purchaseRequestFilters';

class PurchaseRequestListing extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      pageSize: 25,
      pageNumber: 1,
      isDataRendered: false,
      activeTab: linkProps.activeTab,
      activeTabKey: linkProps.activeTab === "Draft" ? '2' : '1', 
      txPreferences: { txType: "purchaseRequest", templateName: "standard" },
      txColumns: [],
      txColumnSetting: {},
      txMandatoryColumns: ['purchaseRequestNumber', 'prRequestedBy', 'purchaseRequestDate', 'expirationDate'],
      txAvailableColumn: {
        purchaseRequestNumber: true,
        prRequestedBy: true,
        orderPriority: true,
       // departmentName: true,
        customerPONumber: true,
        customerRFQNumber: true,
        customerInquiryNumber: true,
        purchaseRequestDate: true,
        expirationDate: true,
        reason: true,
        prOwnerName: true,
        status: true,
        totalQuantityOnPO: true,
        totalQuantityOnRFQ: true,
        userStatus: true,
        project: true,
        reference: true,
      },
    };
  }

  componentDidMount() {
    const linkProps = (this.props.location && this.props.location.state) || {};
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      userId: (this.props.userInfo || {}).userId,
      pageNumber: 1,
      pageSize: 25,
      transactionName: 'Purchase Request',
      filter: {}
    };
    fetchDataIfNeeded('fetchUserPreferences', 'allPreferences', this.props, payload);
    fetchPaginationDataIfNeeded('fetchPurchaseRequests', 'purchaseRequestList', this.props, payload);
    fetchDataIfNeeded('getPurchaseRequestsCountByFilter', 'purchaseRequestCount', this.props, payload);
    if (linkProps.activeTab === "Draft") {
      fetchDataIfNeeded('getPurchaseRequestDraftCount', 'purchaseRequestDraftCount', this.props, payload);
      fetchPaginationDataIfNeeded('fetchPurchaseRequestDrafts', 'purchaseRequestDraftList', this.props, payload);
    }
    this.props.fetchAllEmployees(payload);
    this.props.fetchSuppliers(payload);
    this.props.fetchProjects(payload);
    this.props.fetchAllStatus(payload);
    
  }

  componentWillReceiveProps(props) {
    if (props.allPreferences && props.allPreferences.length) {
      let txPreferences = find(props.allPreferences, { txType: "purchaseRequest", templateName: "standard" });
      if (txPreferences && txPreferences.tablePreference) {
        let txColumns = ((JSON.parse(txPreferences.tablePreference)) || {}).columnList || [];
        txColumns = txColumns.length ? txColumns: this.state.txMandatoryColumns;
        const txColumnSetting = ((JSON.parse(txPreferences.tablePreference)) || {}).columnSetting || {};
        const isColumnFitTable = (Object.keys(txColumnSetting).length || this.state.isColumnFitTable === false) ? false : true;
        let gridPreference = txPreferences.gridPreference;
        this.setState({
          gridPreference,
          isColumnFitTable,
          preferenceId: txPreferences.preferenceId,
          txColumns,
          txColumnSetting,
          txPreferences
        });
      }
      else {
        this.setState({
          txColumns: (this.state.txMandatoryColumns || []).concat('product', 'description')
        });
      }
    }

  }

  render() {
    return (
      <div>
        {this.state.auditDrawerVisible && <AuditTimeLine {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
        {this.state.purchaseFilterDrawerVisible && <PurchaseRequestFiltersComp {...this.state} {...this.props} updateState={(data) => { this.setState(data) }}  />}
          <PurchaseRequestListingComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    userInfo: state.common.userInfo,
    purchaseRequestList: state.purchaseRequest.purchaseRequestList,
    purchaseRequestCount: state.purchaseRequest.purchaseRequestCount,
    isLoading: state.common.isLoading,
    allPreferences: state.common.allPreferences,
    purchaseRequestDraftList: state.purchaseRequest.purchaseRequestDraftList,
    permissions: state.auth.permissions,
    purchaseRequestDraftCount: state.purchaseRequest.purchaseRequestDraftCount,
    suppliers: state.supplier.suppliers,
    projectList: state.common.projectList,
    requesterList: state.common.employees,
    userStatus: state.common.userStatus,
    headerLoading: state.common.headerLoading,
    listLoading: state.common.listLoading,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchPurchaseRequests,
  getPurchaseRequestsCount,
  resetPaginatedData,
  showModal,
  hideModal,
  createAuditTrail,
  deleteAuditTrail, 
  pushModalToStack,
  popModalFromStack,
  fetchUserPreferences,
  deletePurchaseRequest,
  saveUserPrefrences,
  fetchPurchaseRequestDrafts, 
  getPurchaseRequestDraftCount, 
  resetDraftPaginatedData,
  updateUserStatus,
  fetchSuppliers,
  fetchProjects,
  fetchAllEmployees,
  fetchAllStatus,
  fetchPurchaseRequestsByFilter,
  getPurchaseRequestsCountByFilter,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseRequestListing);
