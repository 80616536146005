import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import JournalDetailComponent from '../../../../../components/modal/modalBody/finance/JournalDetail';
import { fetchJournalDetail } from './action';
import { fetchDataIfNeeded } from '../../../../../utils';
import { generatePDF, printPDF, downloadFileFromBucket } from '../../../../../actions/downloadFileAction';
import EmailComponent from '../../../../../containers/drawer/email';

class JournalDetails extends Component {

    constructor(props){
        super();
        this.state= {
            emailDrawerVisible: false,
            subject: '',
            emailBody: '',
        }
    }
    componentWillMount() {
        const companyInfo = this.props.companyInfo || {};
        const payload = { relationshipId: companyInfo.relationshipId, journalId: this.props.journalPayload.journalId };
        fetchDataIfNeeded('fetchJournalDetail', 'JournalDetailData', this.props, payload, true);

    }

    render() {
        return <div>
            { this.state.emailDrawerVisible && <EmailComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />}
            <JournalDetailComponent {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
        </div>
    }

}


const mapStateToProps = (state) => {
    return {
        JournalDetailData: state.journal.journalDetailsData
    }
  }

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchJournalDetail,
    generatePDF, printPDF, 
    downloadFileFromBucket
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(JournalDetails);