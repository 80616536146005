import React from "react";
import { Tabs } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import PaymentList from "./payment";
import SubscriptionList from "./subscription";
import { fetchPaginationDataIfNeeded, } from '../../../../utils';
import ReceivePayment from '../../../../containers/customer/invoice/PayInvoice'
import PageHeader from "../../../Common/pageHeader";

const { TabPane } = Tabs;

function callback(key, props) {
    const payload = { companyInfo: props.companyInfo, relationshipId: props.companyInfo.relationshipId, pageNumber: 1, pageSize: 25 };
    if (key === '1') {
        props.updateState({activeTab: 'Payments', activeTabKey: "1", pageSize: 25, pageNumber: 1});
    } else if (key === '2') {
        props.updateState({activeTab: 'Recurring Payments', activeTabKey: "2", pageSize: 25, pageNumber: 1});
        fetchPaginationDataIfNeeded('fetchCustomerSubscriptions', 'customerSubscriptionList', props, payload);
    }
}


const PaymentListingComp = (props) => {
    const breadCrumbList = [
        {
            name: <FormattedMessage id='sidebar.menuItem.sales' defaultMessage='' />,
        },
        {
            name: <FormattedMessage id='sidebar.menuItem.payments' defaultMessage='' />,
        }
    ];

    const paymentReceive = () => {
        let modalData = {
          title: (
            <FormattedMessage
              id="sidebar.salesPayment.popup.title"
              defaultMessage=""
            />
          ),
          modalBody: <ReceivePayment {...props} invoiceData={{}} />,
          wrapClassName: "pay-invoice-modal",
          width: 500,
          hideFooter: true,
          keyboard:false,
          onCancel: () => {
            props.pushModalToStack({
              modalBody: <FormattedMessage id="modal.cancel.confirmation" defaultMessage=""/>,
              footer:{submit:{intlId:"button.close.label"}},
              handleSubmit: () => {
                props.hideModal();
              }
            })
          }
        };
        props.showModal(modalData);
        
      };
    return (
        <>  
        <PageHeader {...props}
        pageName="heading.sales.salesPayment"
        breadCrumbList={breadCrumbList}
        canCreate={true}
        onCreate={paymentReceive}
        />
            <div className="view-container">
                <Tabs activeKey={props.activeTabKey || "1"} onChange={(key) => { callback(key, props); }} type="line">
                    <TabPane tab="Payments" key="1">
                        <PaymentList {...props} />
                    </TabPane>
                    {props.customerSubscriptionCount ?
                        <TabPane tab="Recurring Payments" key="2">
                            <SubscriptionList {...props} />
                        </TabPane> : ''
                    }
                </Tabs>
            </div>
        </>
    )
};

export default injectIntl(PaymentListingComp);
